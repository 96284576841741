import { Divider, makeStyles } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import { BigNumber } from 'bignumber.js';
import * as React from 'react';
import { useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { changeCommandSectionsApi } from 'src/api/letseatmanager/restaurant/changeCommandSectionsApi';
import { Alert } from 'src/components/Alert';
import { Form } from 'src/components/form/Form';
import { FormCheckbox } from 'src/components/form/FormCheckbox';
import { FormNumberField } from 'src/components/form/FormNumberField';
import { UpdatingContentProgress } from 'src/components/UpdatingContentProgress';
import { CommandSections } from 'src/constants/CommandSection';
import { translate } from 'src/i18n/translate';
import { CampaignIcon } from 'src/icons/CampaignIcon';
import { CommandGeneralInfoSettings } from 'src/scenes/letseatmanager/customizePrinters/command/settings/CommandGeneralInfoSettings';
import { CommandSalesInfo } from 'src/scenes/letseatmanager/customizePrinters/command/settings/CommandSalesInfo';
import { PrintCommandTestButton } from 'src/scenes/letseatmanager/customizePrinters/command/settings/PrintCommandTestButton';
import { useNotification } from 'src/services/notification/useNotification';
import { ReactComponentPrinter } from 'src/services/printer/printers/ReactComponentPrinter';
import { createOrderCommandPrint } from 'src/services/printer/prints/createOrderCommandPrint';
import { useReloadRestaurant } from 'src/services/restaurant/useReloadRestaurant';
import { alertKnownErrorOrSomethingWentWrong } from 'src/utils/alert/alertKnownErrorOrSomethingWentWrong';
import { getOrderDemo } from 'src/utils/order/getOrderDemo';
import { useSelector } from 'src/utils/react/useSelector';
import { getActiveCommandSections } from 'src/utils/ticketAndCommand/getActiveCommandSections';
import { mapCommandSectionsToFormCommandSections } from 'src/utils/ticketAndCommand/mapCommandSectionsToFormCommandSections';

export function CommandSettings(): React.ReactElement {
    const classes = useStyles();

    const [loading, setLoading] = useState(false);

    const notification = useNotification();
    const { reloadRestaurant } = useReloadRestaurant();

    const restaurantId = useSelector((state) => state.app.restaurantId);
    const restaurant = useSelector((state) => state.app.restaurant);
    const order = useMemo(() => getOrderDemo(restaurant), [restaurant]);

    const form = useForm({ defaultValues: { ...mapCommandSectionsToFormCommandSections(restaurant?.commandSections), numberOfCommandPrints: restaurant?.numberOfCommandPrints ?? 1 } });

    const {
        watch,
        formState: { isDirty },
        reset,
    } = form;

    const formCommandSections = watch(Object.keys(CommandSections));
    const numberOfCommandPrints = watch('numberOfCommandPrints');
    const increaseCommandFontSizeEnabled: boolean = watch('increaseCommandFontSizeEnabled');

    const activeCommandSections = getActiveCommandSections(formCommandSections);

    useEffect(() => {
        reset({
            ...mapCommandSectionsToFormCommandSections(restaurant?.commandSections),
            numberOfCommandPrints: restaurant?.numberOfCommandPrints ?? 1,
            increaseCommandFontSizeEnabled: restaurant?.increaseCommandFontSizeEnabled,
        } as any);
    }, [restaurant]);

    const handleSubmit = async () => {
        if (numberOfCommandPrintsHasError(numberOfCommandPrints)) {
            notification({ message: numberOfCommandPrintsHasError(numberOfCommandPrints) ?? '' });
            return;
        }

        setLoading(true);
        const response = await changeCommandSectionsApi({
            restaurantId,
            commandSections: activeCommandSections,
            increaseCommandFontSizeEnabled,
            numberOfCommandPrints: BigNumber(numberOfCommandPrints ?? 1).toNumber(),
        });
        await reloadRestaurant(restaurantId);
        setLoading(false);
        if (!response.ok) {
            return alertKnownErrorOrSomethingWentWrong(response);
        }
    };

    const handleOnClose = () => {
        reset(mapCommandSectionsToFormCommandSections(restaurant?.commandSections));
    };

    const numberOfCommandPrintsHasError = (numberOfCommandPrints: any) => {
        if (BigNumber(numberOfCommandPrints).isLessThanOrEqualTo(0)) {
            return translate('Must be a number greater than zero');
        }

        if (!BigNumber(numberOfCommandPrints).isInteger()) {
            return translate('Must be an integer');
        }
    };

    return (
        <div className={classes.commandSettingsTab}>
            {isDirty && (
                <Alert
                    icon={<CampaignIcon title={'campaign'} />}
                    onClose={handleOnClose}
                    title={translate('You have changes without updating')}
                    actionText={translate('Update')}
                    onActionClick={handleSubmit}
                />
            )}
            <section className={classes.header}>
                {loading && <UpdatingContentProgress loading={loading} />}
                <div>
                    <h1 className={classes.title}>{translate('Customize command')}</h1>
                    <div className={classes.subtitle}>{translate('Adds or removes information displayed when printing')}</div>
                </div>
                <PrintCommandTestButton disabled={loading || isDirty} />
            </section>
            <section className={classes.main}>
                <Form className={classes.form} form={form}>
                    <Grid container xs={12}>
                        <Grid item xs={12} md={6}>
                            <FormNumberField
                                disabled={loading}
                                name={'numberOfCommandPrints'}
                                label={translate('Number of prints')}
                                tooltip={translate('Number of prints of the ticket for each connected printer')}
                                min={1}
                                max={5}
                            />
                        </Grid>
                    </Grid>
                    <FormCheckbox
                        disabled={loading}
                        name='increaseCommandFontSizeEnabled'
                        label={translate('Use large font')}
                        tooltipText={translate('If you activate this option, the item prices will not be visible on the command .This feature is not available for STAR printers')}
                    />
                    <CommandGeneralInfoSettings disabled={loading} />
                    <CommandSalesInfo disabled={loading} />
                </Form>
                <Divider variant='middle' classes={{ vertical: classes.divider }} orientation={'vertical'} />
                <div className={classes.commandPreview}>
                    {ReactComponentPrinter.print(
                        createOrderCommandPrint({
                            order,
                            restaurant: { ...restaurant, commandSections: activeCommandSections },
                            internalUser: false,
                            increaseCommandFontSizeEnabled,
                            commandSections: activeCommandSections,
                        }),
                    )}
                </div>
            </section>
        </div>
    );
}

const useStyles = makeStyles((theme) => ({
    commandPreview: {
        display: 'flex',
        width: '100%',
        justifyContent: 'center',
        maxWidth: 420,
    },
    command: {
        maxWidth: 400,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        padding: '40px 25px',
        width: '90%',
        background: theme.palette.text.invert,
        boxShadow: '-2px 4px 4px rgba(0,0,0,0.15), -4px 0px 20px rgba(0, 0, 0, 0.05)',
        color: 'black',
        fontSize: 12,
        marginTop: 40,
        fontFamily: theme.typography.regular,
        [theme.breakpoints.up('md')]: {
            fontSize: 14,
        },
    },
    leftBoldText: {
        alignSelf: 'start',
        fontFamily: theme.typography.bold,
    },
    commandSettingsTab: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        paddingBottom: 80,
        [theme.breakpoints.down('md')]: {
            padding: '0 20px',
        },
    },
    title: {
        fontFamily: theme.typography.bold,
        color: theme.palette.text.secondary,
        fontSize: 18,
        [theme.breakpoints.up('sm')]: {
            fontSize: 20,
        },
    },
    subtitle: {
        fontFamily: theme.typography.regular,
        color: theme.palette.text.secondary,
        fontSize: 14,
        [theme.breakpoints.up('sm')]: {
            fontSize: 16,
        },
    },
    divider: {
        backgroundColor: theme.palette.surface.brand,
        width: 2,
        height: 'auto',
        display: 'none',
        [theme.breakpoints.up('sm')]: {
            display: 'block',
        },
    },
    header: {
        display: 'flex',
        flexDirection: 'column',
        [theme.breakpoints.up('sm')]: {
            flexDirection: 'row',
            justifyContent: 'space-between',
            marginBottom: 20,
        },
    },
    main: {
        display: 'flex',
        width: '100%',
        flexDirection: 'column',
        [theme.breakpoints.up('sm')]: {
            flexDirection: 'row',
            justifyContent: 'space-between',
        },
    },
    form: {
        width: '100%',
    },
}));
