import { makeStyles } from '@material-ui/core';
import * as React from 'react';
import { PosAddMenuItem } from 'src/scenes/letseatmanager/pos/posAddMenuItem/PosAddMenuItem';
import { PosCart } from 'src/scenes/letseatmanager/pos/posMenu/PosCart';
import { PosMenuRouter } from 'src/scenes/letseatmanager/pos/posMenu/PosMenuRouter';
import { PosMenus } from 'src/scenes/letseatmanager/pos/posMenu/PosMenus';

export function PosMenu(): React.ReactElement {
    const classes = useStyles();

    return (
        <div className={classes.container}>
            <PosMenuRouter />
            <PosAddMenuItem />
            <PosMenus />
            <PosCart />
        </div>
    );
}

const useStyles = makeStyles((theme) => ({
    container: {
        width: '100%',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
    },
}));
