import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import { useState } from 'react';
import * as React from 'react';
import { removeSupplyCategoryApi } from 'src/api/letseatmanager/supply/removeSupplyCategoryApi';
import { Button } from 'src/components/Button';
import { Dialog } from 'src/components/Dialog';
import { DialogActions } from 'src/components/DialogActions';
import { translate } from 'src/i18n/translate';
import { useNotification } from 'src/services/notification/useNotification';
import type { SupplyCategoryVm } from 'src/types/SupplyCategoryVm';
import { alertKnownErrorOrSomethingWentWrong } from 'src/utils/alert/alertKnownErrorOrSomethingWentWrong';
import { getSupplyCategoryName } from 'src/utils/supplyCategory/getSupplyCategoryName';

export function RemoveSupplyCategoryDialog({ open, supplyCategory, onClose, onSuccess }: Props): React.ReactElement | null {
    const classes = useStyles();
    const notification = useNotification();

    const [loading, setLoading] = useState(false);

    const handleSubmit = async () => {
        if (!supplyCategory) return;

        setLoading(true);
        const response = await removeSupplyCategoryApi({ supplyCategoryId: supplyCategory.supplyCategoryId });
        setLoading(false);

        if (!response.ok) {
            alertKnownErrorOrSomethingWentWrong(response);
            notification({ message: translate('Something went wrong') });
            return;
        }

        onSuccess();
        onClose();
    };

    const handleClose = () => {
        if (loading) return;

        onClose();
    };

    if (!supplyCategory) return null;

    return (
        <Dialog open={open} onClose={handleClose} title={translate('Remove Category')}>
            <div className={classes.container}>
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <h1 className={classes.bodyText}>
                            {translate('Are you sure you want to remove supply category "@supplyCategoryName"?', { supplyCategoryName: getSupplyCategoryName(supplyCategory.name) })}
                        </h1>
                    </Grid>
                </Grid>
            </div>
            <DialogActions className={classes.buttonsContainer}>
                <Button secondary onClick={handleClose} disabled={loading}>
                    {translate('Cancel')}
                </Button>
                <Button onClick={handleSubmit} disabled={loading}>
                    {loading ? translate('Removing') : translate('Remove')}
                </Button>
            </DialogActions>
        </Dialog>
    );
}

const useStyles = makeStyles((theme) => ({
    bodyText: {
        fontFamily: theme.typography.medium,
        fontSize: 18,
        margin: 0,
    },
    container: {
        width: 260,
        height: 110,

        [theme.breakpoints.up('sm')]: {
            width: 500,
        },
    },
    buttonsContainer: {
        marginTop: 20,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        [theme.breakpoints.up('sm')]: {
            flexDirection: 'row',
            marginBottom: 20,
        },
    },
}));
type Props = {
    open: boolean;
    supplyCategory?: SupplyCategoryVm;
    onClose: any;
    onSuccess: any;
};
