import { letseatmanagerApiMethod } from 'src/api/letseatmanagerApi';
import type { ApiSauceResponse } from 'src/api/types/ApiSauceResponse';
import type { CustomProductCancellationReason, RestaurantId } from 'src/types/Id';

export async function addProductCancellationReasonApi(request: AddProductCancellationReasonApiRequest): ApiSauceResponse<void> {
    return letseatmanagerApiMethod('restaurant/addProductCancellationReasonApi', request);
}

export type AddProductCancellationReasonApiRequest = {
    restaurantId: RestaurantId;
    reason: CustomProductCancellationReason;
};
