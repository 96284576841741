import { makeStyles } from '@material-ui/core';
import { BigNumber } from 'bignumber.js';
import * as React from 'react';
import { PieChartCanvas } from 'src/components/charts/PieChartCanvas';
import { Text } from 'src/components/Text';
import { translate } from 'src/i18n/translate';

export function CustomerOriginReport({ customerOriginReport }: Props): React.ReactElement {
    const classes = useStyles();

    const data = customerOriginReport.map((report) => ({
        id: `${translate(`CustomerOriginTypes.${report.customerOriginType}`)}`,
        label: `${translate(`CustomerOriginTypes.${report.customerOriginType}`)} - ${report.sessionsCustomerOriginPercentage}%`,
        value: BigNumber(report.sessions).toNumber(),
    }));

    return (
        <div className={classes.container}>
            <Text style={{ fontSize: 16 }} variant='semiBold'>
                {translate('Sessions per customer origin')}
            </Text>
            <div className={classes.chartContainer}>
                <PieChartCanvas
                    data={data}
                    margin={{
                        top: 12,
                        right: 0,
                        bottom: 12,
                        left: 0,
                    }}
                    legends
                />
            </div>
        </div>
    );
}

const useStyles = makeStyles((theme) => ({
    container: {
        display: 'flex',
        flexDirection: 'column',
        gap: 20,
        backgroundColor: 'white',
        borderRadius: 12,
        padding: 24,
        border: `1px solid ${theme.palette.border.primary}`,
        width: '100%',
    },
    chartContainer: {
        height: 450,
    },
    barChart: {
        height: '100%',
    },
}));

type Props = {
    customerOriginReport: Array<{
        customerOriginType: string;
        sessions: string;
        sessionsCustomerOriginPercentage: string;
    }>;
};
