import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import { useState } from 'react';
import * as React from 'react';
import { useForm } from 'react-hook-form';
import { withdrawCashRegisterApi, WithdrawCashRegisterApiRequest } from 'src/api/letseatmanager/pos/withdrawCashRegisterApi';
import { Button } from 'src/components/Button';
import { Dialog } from 'src/components/Dialog';
import { DialogActions } from 'src/components/DialogActions';
import { Form } from 'src/components/form/Form';
import { FormCashRegisterWithdrawReasonSelect } from 'src/components/form/FormCashRegisterWithdrawReasonSelect';
import { FormCurrencyNumberStringField } from 'src/components/form/FormCurrencyNumberStringField';
import { FormTextField } from 'src/components/form/FormTextField';
import { translate } from 'src/i18n/translate';
import { getPosWithdrawsOfflineInLocalStorage } from 'src/localStorage/getPosWithdrawsOfflineInLocalStorage';
import { setPosWithdrawsOfflineInLocalStorage } from 'src/localStorage/setPosWithdrawsOfflineInLocalStorage';
import { useFetchCashRegisterTransactions } from 'src/services/cashRegister/useFetchCashRegisterTransactions';
import { usePrintCashRegisterTransaction } from 'src/services/printer/usePrintCashRegisterTransaction';
import { useOpenCashRegister } from 'src/services/printer/utils/useOpenCashRegister';
import type { CashRegisterId } from 'src/types/Id';
import { alertKnownErrorOrSomethingWentWrong } from 'src/utils/alert/alertKnownErrorOrSomethingWentWrong';
import { classNames } from 'src/utils/react/classNames';
import { useSelector } from 'src/utils/react/useSelector';
import { newUuid } from 'src/utils/uuid/newUuid';

export function WithdrawCashRegisterDialog({ open, cashRegisterId, handleClose, onSuccess }: Props): React.ReactElement {
    const classes = useStyles();
    const form = useForm();

    const { fetchCashRegisterTransactions } = useFetchCashRegisterTransactions();
    const printCashRegisterTransaction = usePrintCashRegisterTransaction();

    const openCashRegister = useOpenCashRegister();

    const [loading, setLoading] = useState(false);

    const pinCodeUserSignedIn = useSelector((state) => state.authentication.pinCodeUserSignedIn);
    const printCashRegisterTransactionsEnabled = useSelector((state) => state.app.restaurant?.printCashRegisterTransactionsEnabled);
    const openCashRegisterForTransactionsEnabled = useSelector((state) => state.app.restaurant?.openCashRegisterForTransactionsEnabled);

    const handleSubmit = async (form: any) => {
        setLoading(true);
        const request = {
            cashRegisterId,
            managerUserId: pinCodeUserSignedIn?.managerUserId,

            amount: form.amount,
            reason: form.reason,
            comment: form.comment,
        } as const;
        const response = await withdrawCashRegisterApi(request);
        if (!response.ok) {
            setLoading(false);
            if (response.problem === 'NETWORK_ERROR') return savePosWithdrawOffline(request);
            return alertKnownErrorOrSomethingWentWrong(response);
        }

        await fetchCashRegisterTransactions();
        setLoading(false);
        onSuccess?.();
        handleClose?.();

        if (openCashRegisterForTransactionsEnabled && !printCashRegisterTransactionsEnabled) await openCashRegister();
        if (printCashRegisterTransactionsEnabled) {
            await printCashRegisterTransaction(response.data, { openCashBox: openCashRegisterForTransactionsEnabled, forceToPrint: true });
        }
    };

    const savePosWithdrawOffline = (request: WithdrawCashRegisterApiRequest) => {
        const posWithdrawsOffline = getPosWithdrawsOfflineInLocalStorage();
        setPosWithdrawsOfflineInLocalStorage([...posWithdrawsOffline, { ...request, temporalCashRegisterTransactionId: newUuid(), createdAt: new Date() }]);
    };

    return (
        <Dialog open={open} onClose={handleClose} title={translate('Withdraw')} classes={{ dialog: classes.dialog }}>
            <Form onSubmit={handleSubmit} form={form} className={classes.form}>
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <FormCurrencyNumberStringField name='amount' disabled={loading} label={translate('Amount')} required />
                    </Grid>
                </Grid>
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <FormCashRegisterWithdrawReasonSelect name='reason' label={translate('Withdraw Reason')} required disabled={loading} />
                    </Grid>
                </Grid>
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <FormTextField name='comment' label={translate('Comment')} disabled={loading} />
                    </Grid>
                </Grid>
                <DialogActions className={classNames(classes.buttonsContainer, { [classes.singleButtonContainer]: !handleClose })}>
                    {handleClose && (
                        <Button secondary onClick={handleClose} disabled={loading} classes={{ button: classes.button }}>
                            {translate('Cancel')}
                        </Button>
                    )}
                    <Button color='primary' type={'submit'} disabled={loading} classes={{ button: classes.button }}>
                        {loading ? translate('Withdrawing') : translate('Withdraw')}
                    </Button>
                </DialogActions>
            </Form>
        </Dialog>
    );
}

const useStyles = makeStyles((theme) => ({
    dialog: {
        minWidth: 540,
        padding: '24px 48px',
        [theme.breakpoints.down('sm')]: {
            minWidth: 250,
            width: '80%',
        },
    },
    form: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        gap: 18,
    },
    buttonsContainer: {
        marginTop: 20,
        marginBottom: 20,
        [theme.breakpoints.down('xs')]: {
            flexDirection: 'column',
            justifyContent: 'center',
            alignContent: 'center',
        },
    },
    singleButtonContainer: {
        justifyContent: 'center',
        alignContent: 'center',
    },
    button: {
        width: '50%',
        [theme.breakpoints.up('xs')]: {
            width: '100%',
        },
    },
}));

type Props = {
    open: boolean;
    cashRegisterId?: CashRegisterId;
    handleClose?: any;
    onSuccess?: any;
};
