import { BigNumber } from 'bignumber.js';
import moment from 'moment-timezone';
import { PaymentMethod } from 'src/api/appsyncApi';
import type { ReportOrderVm } from 'src/api/letseatmanager/types/ReportOrderVm';
import { ExternalDeliveryProviders } from 'src/constants/ExternalDeliveryProviders';
import { MexicanTax } from 'src/constants/MexicanTaxes';
import { PaymentMethods } from 'src/constants/PaymentMethod';
import { UruguayanTax } from 'src/constants/UruguayanTaxes';
import { translate } from 'src/i18n/translate';
import type { OrdersAndReportsVm } from 'src/types/OrdersAndReportsVm';
import { objectsToCsv } from 'src/utils/csv/objectsToCsv';
import { downloadTextInFile } from 'src/utils/html/downloadTextInFile';
import { formatAsCsvNumberCurrency } from 'src/utils/number/formatAsNumberCsv';
import { useSelector } from 'src/utils/react/useSelector';
import { sum } from 'src/utils/reduce/sum';

export function useDownloadOrdersSalesCsv(): (arg1: OrdersAndReportsVm | undefined) => void {
    const internalUser = useSelector((state) => state.authentication.internalUser);
    const consecutiveOrderIdEnabled = useSelector((state) => state.app.restaurant.consecutiveOrderIdEnabled);

    const downloadOrdersSalesCsv = (ordersReport: undefined | OrdersAndReportsVm) => {
        if (!ordersReport) return;
        if (ordersReport?.reportOrders.length <= 0) {
            alert(translate('There are no orders to download'));
            return;
        }
        const rows = ordersReport?.reportOrders.map((order: ReportOrderVm) => {
            const tip = order.tips
                ?.map((tip) => tip.tipAmount)
                .reduce(sum, BigNumber(0))
                .toString();
            return {
                ...getOrderIdsForReport(order),
                [translate('External Order')]: order.rappiOrderId ?? order.uberEatsOrderId ?? order.didiFoodOrderId,
                [translate('App')]: order.app ? translate(`Apps.${order.app}`) : '',
                [translate('Customer Name')]: order.customerName,
                [translate('Mobile Number')]: order.mobileNumber,
                [translate('External Delivery Id')]: getExternalDeliveryId(order),
                [translate('External Delivery Provider')]: order.externalDeliveryProvider,
                [translate('Ordering Date')]: moment.tz(order.createdAt, order.timeZone).format('MM/DD/YYYY'),
                [translate('Ordering Time')]: moment.tz(order.createdAt, order.timeZone).format('HH:mm'),
                [translate('Order Type')]: order.orderType,
                [translate('Cash Register')]: order.cashRegisterPosBusinessDayNumber,
                [translate('Order Items')]: order.orderItems.map((item) => item.name).join(', '),
                [translate('Order Modifiers')]: order.orderItems.flatMap((item) => item.modifierGroups.flatMap((modifierGroup) => modifierGroup.modifiers.map((modifier) => modifier.name))).join(', '),
                [translate('Subtotal')]: formatAsCsvNumberCurrency(order.subtotal),
                [translate('Product Discount')]: formatAsCsvNumberCurrency(order.productDiscount),
                [translate('Promo code')]: order.promoCode,
                [translate('Promo Code Discount')]: formatAsCsvNumberCurrency(order.promoCodeDiscount),
                [translate('POS Discount')]: formatAsCsvNumberCurrency(order.posDiscount),
                [translate('Total Discount')]: BigNumber(order.discount ?? 0).isZero() ? undefined : formatAsCsvNumberCurrency(order.discount),
                [translate('Promo Code Discount Paid By PideDirecto')]: formatAsCsvNumberCurrency(order.promoCodeDiscountPaidByPideDirecto),
                [translate('Credits')]: formatAsCsvNumberCurrency(order.usedCredits),
                [translate('Tip')]: formatAsCsvNumberCurrency(tip),
                [translate('Total')]: formatAsCsvNumberCurrency(order.restaurantTotal),
                [translate('Restaurant Gross Total')]: formatAsCsvNumberCurrency(order.restaurantGrossTotal),
                [translate('Restaurant Net Total')]: formatAsCsvNumberCurrency(order.restaurantNetTotal),
                [translate('Payment Method')]: order.hasPaymentMethodChangedToCard ? translate('changed to card') : order.paymentMethod,
                [translate('Payment Status')]: order.paymentStatus,
                [translate('Payments')]: order.payments
                    ?.map((payment: { amount: string; customerNumber?: number; paymentMethod: string }) =>
                        PaymentMethods[payment.paymentMethod as PaymentMethod] ? PaymentMethods[payment.paymentMethod as PaymentMethod] : payment.paymentMethod + '*',
                    )
                    .join(', '),
                [translate('Order Taxes')]: order.orderTaxes
                    ?.map((tax: { taxAmount: string; taxType: string }) => ((tax.taxType as MexicanTax) ? (tax.taxType as UruguayanTax) : tax.taxType))
                    .join(', '),
                [translate('Total Tax')]: order.orderTaxes
                    ?.map((tax) => tax.taxAmount ?? '0')
                    .reduce(sum, BigNumber(0))
                    .toString(),
                [translate('Order Status')]: order.orderStatus,
                [translate('Order Reject Reason')]: order.orderRejectReason ? translate(`OrderRejectReasons.${order.orderRejectReason}`) : undefined,
                [translate('Driver Name')]: order.driverName,
                [translate('Delivery Cost')]: formatAsCsvNumberCurrency(order.deliveryCost),
                [translate('Invoice Delivery Cost')]: order.invoiceRestaurantDeliveryCost ? translate('Invoiced') : undefined,
                [translate('Delivery Cost Paid By')]: order.restaurantDeliveryCost ? translate('Restaurant') : translate('Customer'),
                [translate('Restaurant Name')]: order.restaurantName,
            };
        });
        const csv = objectsToCsv(rows);
        downloadTextInFile(csv, 'exported-order-sales.csv');
    };

    const getOrderIdsForReport = (order: ReportOrderVm) => {
        if (consecutiveOrderIdEnabled && !internalUser) {
            return {
                [translate('Order Id')]: order.consecutiveOrderId ?? order.orderId,
                [translate('Short order Id')]: order.shortOrderId,
            };
        }
        if (consecutiveOrderIdEnabled && internalUser) {
            return {
                [translate('Order Id')]: order.orderId,
                [translate('Consecutive Order Id')]: order.consecutiveOrderId ?? order.orderId,
                [translate('Short order Id')]: order.shortOrderId,
            };
        }
        return {
            [translate('Order Id')]: order.orderId,
            [translate('Short order Id')]: order.shortOrderId,
        };
    };

    const getExternalDeliveryId = (order: ReportOrderVm) => {
        if (order.externalDeliveryProvider === ExternalDeliveryProviders.RAPPI_CARGO) {
            return order.rappiCargoDeliveryId;
        } else if (order.externalDeliveryProvider === ExternalDeliveryProviders.UBER_DAAS) {
            return order.uberDaasDeliveryOrderId;
        } else if (order.externalDeliveryProvider === ExternalDeliveryProviders.UBER_EATS) {
            return order.uberDirectDeliveryOrderId;
        }
    };

    return downloadOrdersSalesCsv;
}
