import { APP_VERSION } from 'src/config/appVersion';
import { PrinterPaperSizes } from 'src/constants/PrinterPaperSize';
import { getDeviceId } from 'src/services/device/getDeviceId';
import { printerInstructionsBuilder } from 'src/services/printer/prints/utils/printerInstructionsBuilder';
import type { PrinterInstruction } from 'src/services/printer/types/PrinterInstruction';
import { printerDeprecatedToPrinter } from 'src/services/printer/utils/printerDeprecatedToPrinter';
import type { PrinterDeprecatedVm } from 'src/types/PrinterDeprecatedVm';
import type { PrinterVm } from 'src/types/PrinterVm';
import { formatDateTimeString } from 'src/utils/date/formatDateTimeString';
import { booleanToString } from 'src/utils/string/booleanToString';
import { padAround } from 'src/utils/string/padAround';
import { toShortId } from 'src/utils/uuid/toShortId';

export function createPrinterInfoPrint({ printer: printerParam, pideDirectoAdminAppVersion, developerMode }: Params): Array<PrinterInstruction> {
    let printer: PrinterVm;
    if ((printerParam as PrinterDeprecatedVm).type) {
        printer = printerDeprecatedToPrinter(printerParam as PrinterDeprecatedVm);
    } else {
        printer = printerParam;
    }
    const builder = printerInstructionsBuilder();

    builder.addLineSeparator().addCenteredBoldText('PRINTER INFO').addLineSeparator().addSeparatedTexts('Printed At', formatDateTimeString(new Date()));
    if (printer.printerId) builder.addSeparatedTexts('Printer Id', toShortId(printer.printerId));
    if (printer.restaurantId) builder.addSeparatedTexts('Restaurant Id', toShortId(printer.restaurantId));
    if (printer.printerType) builder.addSeparatedTexts('Printer Type', printer.printerType);
    if (printer.printerContent) builder.addSeparatedTexts('Printer Content', printer.printerContent);
    if (printer.deviceName) builder.addSeparatedTexts('Device Name', printer.deviceName);
    if (printer.serialNumber) builder.addSeparatedTexts('Serial Number', printer.serialNumber);
    if (printer.externalPrinterId) builder.addSeparatedTexts('External Printer Id', printer.externalPrinterId);
    if (printer.ipAddress) builder.addSeparatedTexts('Ip Address', printer.ipAddress);
    if (printer.port) builder.addSeparatedTexts('Port', printer.port);
    if (printer.printerBrand) builder.addSeparatedTexts('Printer Brand', printer.printerBrand);
    if (printer.channels) builder.addSeparatedTexts('Channels', printer.channels?.join(', '));
    if (printer.printerPaperSize) builder.addSeparatedTexts('Printer Paper Size', printer.printerPaperSize);
    builder.addSeparatedTexts('Disabled', booleanToString(printer.disabled) ?? 'false');
    if (printer.createdAt) builder.addSeparatedTexts('Created At', formatDateTimeString(printer.createdAt));
    if (printer.modifiedAt) builder.addSeparatedTexts('Modified At', formatDateTimeString(printer.modifiedAt));

    builder
        .addLineSeparator()
        .addCenteredBoldText('SYSTEM INFO')
        .addLineSeparator()
        .addSeparatedTexts('PideDirecto Admin Version', APP_VERSION)
        .addSeparatedTexts('PideDirecto Admin App Version', pideDirectoAdminAppVersion ?? 'N/A')
        .addSeparatedTexts('Device Id', toShortId(getDeviceId()));

    if (!developerMode) return builder.build();

    if (printer.printerPaperSize === PrinterPaperSizes['58_MM']) {
        builder
            .addLineSeparator()
            .addCenteredBoldText('CHARACTER PER ROW TEST')
            .addLineSeparator()
            .addText(padAround('30', 30, '*'))
            .addText(padAround('31', 31, '*'))
            .addText(padAround('32', 32, '*'))
            .addText(padAround('33', 33, '*'))
            .addText(padAround('34', 34, '*'))
            .addText(padAround('35', 35, '*'))
            .addText(padAround('36', 36, '*'));
    } else {
        builder
            .addLineSeparator()
            .addCenteredBoldText('CHARACTER PER ROW TEST')
            .addLineSeparator()
            .addText(padAround('40', 40, '*'))
            .addText(padAround('41', 41, '*'))
            .addText(padAround('42', 42, '*'))
            .addText(padAround('43', 43, '*'))
            .addText(padAround('44', 44, '*'))
            .addText(padAround('45', 45, '*'))
            .addText(padAround('46', 46, '*'))
            .addText(padAround('47', 47, '*'))
            .addText(padAround('48', 48, '*'))
            .addText(padAround('49', 49, '*'))
            .addText(padAround('50', 50, '*'));
    }

    builder
        .addLineSeparator()
        .addCenteredBoldText('CHARACTER TEST')
        .addLineSeparator()
        .addText('ABCDEFGHIÍJKLMNÑOPQRSTUVWXYZ')
        .addText('abcdefghiíjklmnñopqrstuvwxyz')
        .addText('$€ÜüÁáÉéÍíÓóÚúÑñÅÆØåæø')
        .addText('1234567890+-.,:;´`"”\'')
        .addText('!¡?¿_-=*@#$€%&/|\\(){}[]<>≤≥') // skip [] and <> since they are not supported for AndroidPrinter currently
        .addLineSeparator()
        .addCenteredBoldText('RENDERING TEST')
        .addLineSeparator()
        .addText('Add Text')
        .addBoldText('Bold Text')
        .addCenteredText('Add Centered Text')
        .addCenteredBoldText('Centered Bold Text')
        .addRightText('Right Text')
        .addBoldRightText('Bold Right Text')
        .addSeparatedTexts('Separated', 'text')
        .addSeparatedBoldLeftTexts('Separated Bold', 'Left Texts')
        .addBoldSeparatedTexts('Add Bold', ' Separated Texts')
        .addColumns([
            { text: 'left', percentageWidth: 0.3, textAlign: 'left' },
            { text: 'middle', percentageWidth: 0.3, textAlign: 'left' },
            { text: 'right', percentageWidth: 0.4, textAlign: 'right' },
        ])
        .addBoldColumns([
            { text: 'left', percentageWidth: 0.3, textAlign: 'left' },
            { text: 'middle', percentageWidth: 0.3, textAlign: 'left' },
            { text: 'right', percentageWidth: 0.4, textAlign: 'right' },
        ])
        .addLogoImage('https://images.letseat.mx/cf3de3fd8e47b9a0e7db2f0b9c5d37b1.png')
        .addQrCode('http://store.pidedirecto.mx/')
        .addText('New Line')
        .addNewLine()
        .addText('Centered Under Line')
        .addCenteredUnderLine()
        .addText('Open Cashbox')
        .openCashbox();

    return builder.build();
}

type Params = {
    printer: PrinterVm | PrinterDeprecatedVm;
    pideDirectoAdminAppVersion?: string;
    developerMode?: boolean;
};
