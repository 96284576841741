import { makeStyles } from '@material-ui/core';
import * as React from 'react';
import { Tabs } from 'src/components/Tabs';
import { RolePermissions } from 'src/constants/RolePermission';
import { translate } from 'src/i18n/translate';
import { PosBusinessDayItemsTable } from 'src/scenes/letseatmanager/restaurantDashboard/posBusinessDay/PosBusinessDayItemsTable';
import { PosBusinessDayOrdersTable } from 'src/scenes/letseatmanager/restaurantDashboard/posBusinessDay/PosBusinessDayOrdersTable';
import { PosBusinessDayPromoCodesTable } from 'src/scenes/letseatmanager/restaurantDashboard/posBusinessDay/PosBusinessDayPromoCodesTable';
import type { CashRegisterPosBusinessDayReportVm } from 'src/types/CashRegisterPosBusinessDayReportVm';
import type { PosBusinessDayReportVm } from 'src/types/PosBusinessDayReportVm';

export function PosBusinessSales({ posBusinessDayReport }: Props): React.ReactElement {
    const classes = useStyles();

    const tabs = Object.keys(POS_SALES_TABS).map((tab) => {
        return {
            label: translate(`PosBusinessSales.${tab}`),
            value: tab,
            rolePermission: RolePermissions.REPORTS_PAGE,
        };
    });

    return (
        <div className={classes.container}>
            <Tabs tabs={tabs}>
                <PosBusinessDayOrdersTable posBusinessDayReport={posBusinessDayReport} />
                <PosBusinessDayItemsTable posBusinessDayReport={posBusinessDayReport} />
                <PosBusinessDayPromoCodesTable posBusinessDayReport={posBusinessDayReport} />
            </Tabs>
        </div>
    );
}

const POS_SALES_TABS = {
    ORDERS: 'ORDERS',
    PRODUCTS: 'PRODUCTS',
    PROMO_CODES: 'PROMO_CODES',
} as const;

const useStyles = makeStyles((theme) => ({
    container: {
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        paddingBottom: 42,
        [theme.breakpoints.down('sm')]: {
            padding: 12,
        },
    },
}));

type Props = {
    posBusinessDayReport: PosBusinessDayReportVm | CashRegisterPosBusinessDayReportVm;
};
