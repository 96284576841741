import * as React from 'react';
import { useEffect, useState } from 'react';
import { findRestaurantCustomerOrdersApi } from 'src/api/letseatmanager/customerOrder/findRestaurantCustomerOrdersApi';
import { app2 } from 'src/app2';
import { translate } from 'src/i18n/translate';
import { OrdersTable } from 'src/scenes/letseatadmin/order/OrdersTable';
import type { RestaurantId } from 'src/types/Id';
import { OrderVm } from 'src/types/OrderVm';
import { alertKnownErrorOrSomethingWentWrong } from 'src/utils/alert/alertKnownErrorOrSomethingWentWrong';
import { useAction } from 'src/utils/react/useAction';
import { useSelector } from 'src/utils/react/useSelector';

export function RestaurantCustomerOrders({ mobileNumber, restaurantId }: Props): React.ReactElement {
    const [loading, setLoading] = useState(false);
    const [orders, setOrders] = useState<Array<OrderVm>>([]);

    const cities = useSelector((state) => state.app2.cities);
    const setTitle = useAction(app2.actions.setTitle);

    useEffect(() => {
        setTitle(translate('Orders'));
        load();
    }, []);

    const load = async () => {
        setLoading(true);
        const response = await findRestaurantCustomerOrdersApi({ mobileNumber: mobileNumber, restaurantId: restaurantId });
        setLoading(false);
        if (!response.ok) return alertKnownErrorOrSomethingWentWrong(response);
        setOrders(response.data.orders);
    };

    return <OrdersTable orders={orders} loading={loading} onClickRefresh={load} />;
}

type Props = {
    mobileNumber: string;
    restaurantId: RestaurantId;
};
