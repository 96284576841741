import { makeStyles } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';
import CalendarTodayIcon from '@material-ui/icons/CalendarToday';
import CloseIcon from '@material-ui/icons/Close';
import { DatePicker } from '@material-ui/pickers';
import { useContext } from 'react';
import * as React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { FormContext } from 'src/components/form/Form';
import { getError } from 'src/components/form/utils/getError';
import { translate } from 'src/i18n/translate';
import { classNames } from 'src/utils/react/classNames';

// TODO: Create a TransformedDatePicker and make sure the value type is String instead of Date
export function FormDatePicker({ name, label, placeholder, helperText, defaultValue = null, disabled, required, variant = 'inline', classes: classesProp }: Props): React.ReactElement {
    const classes = useStyles();
    const {
        errors,
        control,
        formState: { isSubmitting },
    } = useFormContext();
    const formContext = useContext(FormContext);
    // const inputRef = useRef<HTMLInputElement>();

    return (
        <Controller
            render={({ onChange, value, ref, ...props }) => (
                <DatePicker
                    label={label}
                    placeholder={placeholder}
                    /* @ts-ignore */
                    name={name}
                    helperText={getError(errors, name)?.message ?? helperText}
                    disabled={isSubmitting || disabled || formContext.disabled}
                    error={!!getError(errors, name)}
                    className={classNames(classes.inputContainer, classesProp?.inputContainer)}
                    // inputProps={restInput}
                    onChange={onChange}
                    // onBlur={onBlur}
                    value={value === '' ? null : value}
                    format='ddd ll'
                    /* @ts-ignore */
                    ampm={false}
                    autoOk
                    fullWidth
                    variant={variant}
                    required={required as any}
                    {...props}
                    InputProps={{
                        // inputRef: inputRef,
                        startAdornment: (
                            <InputAdornment position='start'>
                                <CalendarTodayIcon fontSize='small' className={classes.iconColor} />
                            </InputAdornment>
                        ),
                        endAdornment: (
                            <>
                                {!disabled && (
                                    <InputAdornment position='end'>
                                        <IconButton
                                            onClick={(event) => {
                                                event.stopPropagation();
                                                onChange(null);
                                            }}
                                            size='small'
                                        >
                                            <CloseIcon fontSize='small' />
                                        </IconButton>
                                    </InputAdornment>
                                )}
                            </>
                        ),
                    }}
                />
            )}
            control={control}
            name={name}
            defaultValue={defaultValue}
            rules={{
                required: {
                    value: (required as any)?.value ?? required,
                    message: (required as any)?.message ?? translate('This field is required'),
                },
            }}
            // onFocus={() => {
            //     // make focus on error work when disabled={isSubmitting || disabled}
            //     if (inputRef.current) {
            //         inputRef.current.disabled = false;
            //         inputRef.current.focus();
            //     }
            // }}
        />
    );
}

const useStyles = makeStyles((theme) => ({
    iconColor: {
        color: theme.palette.text.secondary,
    },
    inputContainer: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        marginBottom: 20,
        '& .MuiInputBase-root': {
            fontFamily: `${theme.typography.regular} !important`,
            fontSize: 14,
            backgroundColor: theme.palette.secondary.light,
            borderRadius: 5,
            padding: `${theme.spacing(0.3)}px ${theme.spacing(1)}px`,
            border: `1px solid #d9d9d9`,
            marginTop: 20,
        },
        '& .MuiInputBase-root::before': {
            display: 'none',
        },
        '& .MuiInputBase-root::after': {
            display: 'none',
        },
        '& .MuiFormControl-root:first-child': {
            marginRight: 10,
        },
        '& label': {
            fontFamily: theme.typography.regular,
            fontSize: 14,
            color: '#40464d',
            transform: 'scale(1)',
        },
        [theme.breakpoints.up('sm')]: {
            flexDirection: 'row',
        },
    },
}));

export type Props = {
    name: string;
    label?: string;
    placeholder?: string;
    helperText?: string;
    defaultValue?: Date | null | undefined;
    disabled?: boolean;
    required?:
        | boolean
        | {
              value: number;
              message: string;
          };
    variant?: 'dialog' | 'inline' | 'static';
    classes?: {
        inputContainer?: string;
    };
};
