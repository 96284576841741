import { DeliveryProvidersInfoVm, type OpeningHoursVm, type PromoPricesVm, type TheRestaurantVm } from 'src/api/letseatadmin/theRestaurant/getTheRestaurantApi';
import { letseatadminApiMethod } from 'src/api/letseatadminApi';
import type { ApiSauceResponse } from 'src/api/types/ApiSauceResponse';
import type { App } from 'src/constants/App';
import type { Bank } from 'src/constants/Bank';
import type { BannerImageType } from 'src/constants/BannerImageType';
import type { City } from 'src/constants/City';
import type { ConsecutiveOrderIdRange } from 'src/constants/ConsecutiveOrderIdRange';
import type { Country } from 'src/constants/Country';
import { DeliveryManagerType } from 'src/constants/DeliveryManagerType';
import type { ProductLayout } from 'src/constants/ProductLayouts';
import type { RegimeFiscal } from 'src/constants/RegimeFiscal';
import type { RestaurantPsp } from 'src/constants/RestaurantPsp';
import type { RestaurantType } from 'src/constants/RestaurantType';
import type { ServiceType } from 'src/constants/ServiceType';
import type { SmsServiceProvider } from 'src/constants/SmsServiceProvider';
import type { Sound } from 'src/constants/Sound';
import type { TimeZone } from 'src/constants/TimeZone';
import type { GeoJsonPoint } from 'src/types/GeoJsonPoint';
import type { CompanyId, DeliveryCategory, EmailAddress, EmailTemplateId, NubefactStoreId, PideDirectoApiKey, PlexoStoreId, QpayTerminalDeviceId, RestaurantId } from 'src/types/Id';
import type { RestaurantAddress } from 'src/types/RestaurantAddress';

export async function changeTheRestaurantApi(request: ChangeTheRestaurantApiRequest): ApiSauceResponse<TheRestaurantVm> {
    return letseatadminApiMethod('theRestaurant/changeTheRestaurantApi', request);
}

export type ChangeTheRestaurantApiRequest = {
    restaurantId: RestaurantId;
    restaurantType: RestaurantType;
    deliveryCategories?: Array<DeliveryCategory>;
    statisticsReportEmailRecipients?: Array<EmailAddress>;
    name: string;
    street?: string;
    address?: RestaurantAddress;
    location?: GeoJsonPoint | null | undefined;
    phoneNumber: string;
    contactInformationEnabled: boolean;
    contactPhoneNumber: string;
    contactWhatsAppPhoneNumber?: string;
    secondaryPhoneNumber?: string;
    imageUrl: string;
    imageUrlMobile?: string;
    kioskBackgroundImageUrl: string;
    backgroundImageUrl: string;
    logoImageUrl: string;
    menuAnalyticsUrl?: string;
    customersAnalyticsUrl?: string;
    promoCodesAnalyticsUrl?: string;
    // hours: string, // TODO: add support in manager and app
    info?: string;
    infoUrl?: string;
    ownFleetEnabled?: boolean;
    hideDeliveryEarningsFromDriverEnabled?: boolean;
    externalOwnFleetEnabled?: boolean;
    requestDeliveryToPideDirectoDriversForOwnFleetEnabled?: boolean;
    hideOrderItemNotesEnabled?: boolean;
    hideFollowUpBannerEnabled?: boolean;
    pickupStationClosingTime?: string;
    pickupStationDeliveryTime?: string;
    openingHours?: OpeningHoursVm;
    promoPrices: PromoPricesVm;
    activated: boolean;
    comingSoon: boolean;
    thanksForOrderingEmail: boolean;
    sendThanksForOrderingEmailMinutesAfter?: number;
    thanksForOrderingEmailTemplateId?: EmailTemplateId;
    welcomeEmail: boolean;
    sendWelcomeEmailMinutesAfter?: number;
    welcomeEmailTemplateId?: EmailTemplateId;
    menuHighlightEmail: boolean;
    sendMenuHighlightEmailDaysAfter?: number;
    menuHighlightEmailTemplateId?: EmailTemplateId;
    promoCodeEmail: boolean;
    sendPromoCodeEmailDaysAfter?: number;
    promoCodeEmailTemplateId?: EmailTemplateId;
    customerAuthenticationSmsServiceProvider?: SmsServiceProvider;
    temporarilyOffline: boolean;
    temporarilyWithoutDelivery: boolean;
    companyNames?: Array<string>;
    companyIds?: Array<CompanyId>;
    public?: boolean;
    country: Country;
    city?: City;
    timeZone: TimeZone;
    eatHereEnabled: boolean;
    serviceType: ServiceType;
    takeAwayEnabled: boolean;
    deliveryEnabled: boolean;
    autoCallRestaurantEnabled?: boolean;
    ordersWithoutIvaEnabled?: boolean;
    restaurantTaxManagementEnabled?: boolean;
    sendOrderCreatedWebhookAtPlannedPickupTimeEnabled?: boolean;
    sendWebhookEventsForPosOrdersEnabled?: boolean;
    includeBreakdownIntegrationsInPosBusinessDayEnabled?: boolean;
    closeOrdersEnabled?: boolean;
    newOrdersPageEnabled?: boolean;
    heatMapDeliveryOrdersReportEnabled?: boolean;
    deliverySecurityCodeEnabled?: boolean;
    pideDirectoDeliveryEnabled?: boolean;
    uberDaasDeliveryEnabled: boolean;
    deliveryManagerType?: DeliveryManagerType;
    uberDaasOrdersWithCashEnabled: boolean;
    returnCancelledOrdersToRestaurantEnabled: boolean;
    uberEatsDeliveryEnabled: boolean;
    rappiCargoDeliveryEnabled: boolean;
    rappiCargoOrdersWithCreditCardEnabled: boolean;
    deliveryManagerMaxRuntime?: number;
    uberEatsIntegrationEnabled?: boolean;
    uberEatsStoreOnline?: boolean;
    uberEatsMenuShouldUseMenuHoursEnabled?: boolean;
    autoOpenClosedUberEatsStoreEnabled?: boolean;
    didiFoodStoreOnline?: boolean;
    wansoftIntegrationEnabled?: boolean;
    wansoftStoreId?: string;
    wansoftPassword?: string;
    rappiStoreCheckInCodeEnabled?: boolean;
    plannedOrdersEnabled: boolean;
    cashPaymentsEnabled: boolean;
    restaurantPaymentMethodsEnabled?: boolean;
    ecommerceCashPaymentsToEatHereEnabled?: boolean;
    ecommerceCashPaymentsToTakeAwayEnabled?: boolean;
    mandatoryCashAmountEnabled: boolean;
    creditCardPaymentsEnabled: boolean;
    cardOnDeliveryPaymentsEnabled: boolean;
    maximumOrderAmountWithoutCardValidation?: string;
    automaticInvoiceEnabled?: boolean;
    paymentLinksEnabled: boolean;
    acceptManuallyAllOrdersEnabled?: boolean;
    cashPaymentsEnabledIn?: Array<App>;
    creditCartPaymentsEnabledIn?: Array<App>;
    paymentLinkPaymentsEnabledIn?: Array<App>;
    restaurantPromoCodesEnabled: boolean;
    promoCodeBannerEnabled: boolean;
    showDriverArriveAtStoreStatusOnEcommerceEnabled?: boolean;
    hideEcommerceDriverTipEnabled: boolean;
    hideEcommerceRestaurantPhoneNumberEnabled?: boolean;
    autoInvoiceOrdersEnabled: boolean;
    preparationTimeInMinutes?: number;
    addTemporaryPreparationTimeEnabled?: boolean;
    hidePlannedOrders: boolean;
    alertSound?: Sound;
    cancelledOrdersAlertSound?: Sound;
    cancelledOrdersAlertSoundEnabled?: boolean;
    addManuallyOrderPreparationTimeEnabled?: boolean;
    zone?: string;
    restaurantPsps?: Array<RestaurantPsp>;
    restaurantPspsEnabled?: boolean;
    plexoIntegrationEnabled?: boolean;
    plexoStoreId?: PlexoStoreId;
    mercadoPagoPublicKey?: string;
    mercadoPagoSecretKey?: string;
    contact?: string;
    contactNumber?: string;
    establishmentsPhoneNumber?: string;
    fiscalAddress?: string;
    addressOfEstablishment?: string;
    email?: EmailAddress;
    bank?: Bank;
    account?: string;
    clabe?: string;
    rfc?: string;
    regimeFiscal?: RegimeFiscal;
    businessName?: string;
    contactName?: string;
    emailInvoices?: string;
    paymentInEstablishment?: string;
    commissionWebCard?: string;
    commissionWebCash?: string;
    commissionWebCardOnDelivery?: string;
    commissionAppCard?: string;
    commissionAppCash?: string;
    commissionAppCardOnDelivery?: string;
    commissionManualOrders?: string;
    commissionRappiCargoWebCard?: string;
    commissionRappiCargoWebCash?: string;
    commissionRappiCargoAppCard?: string;
    commissionRappiCargoAppCash?: string;
    commissionRappiCargoManualOrder?: string;
    commissionRappiCargoPosCard?: string;
    commissionRappiCargoPosCash?: string;
    commissionUberDaasWebCard?: string;
    commissionUberDaasWebCash?: string;
    commissionUberDaasAppCard?: string;
    commissionUberDaasAppCash?: string;
    commissionUberDaasManualOrder?: string;
    commissionUberDaasPosCard?: string;
    commissionUberDaasPosCash?: string;
    commissionOfficeDeliveryFixed?: string;
    device?: string;
    responsible?: string;
    bill?: string;
    sam?: string;
    externalDelivery?: boolean;
    externalDeliveryEstimatedTime?: string;
    useNonGeoLocatedDeliveryAddressesEnabled?: boolean;
    manualOrdersEnabled?: boolean;
    externalCardPaymentsForManualOrdersEnabled?: boolean;
    externalCardPaymentsForManualOrdersAsDefaultEnabled?: boolean;
    orderPriceLimitForManualOrder?: string;
    pideDirectoProtectionForManualOrderAmount?: string;
    deliveryRadiusInKm?: number;
    customerDeliveryCostFixed?: number;
    customerDeliveryCostPerKm?: number;
    dynamicEarningsMinimumMinutes?: number;
    dynamicEarningsAmountToIncrementByMinute?: number;
    dynamicEarningsRoof?: number;
    longDistanceExtraDeliveryCostStartsFromKm?: number;
    longDistanceExtraDeliveryCostFixed?: number;
    longDistanceExtraDeliveryCostPerKm?: number;
    rappiCargoDefaultTip?: number;
    deliveryPaidByAndInvoicedToRestaurant?: boolean;
    invoiceOnlyDeliveryCostEnabled?: boolean;
    dynamicDeliveryManager: boolean;
    deliveryOrchestratorEnabled: boolean;
    cashDeliveryProviders: Array<string>;
    waitTimePerDeliveryCashProviders?: {
        uberDaasWaitTime?: number;
        rappiCargoWaitTime?: number;
        pidedirectoWaitTime?: number;
    };
    cardDeliveryProviders: Array<string>;
    waitTimePerDeliveryCardProviders?: {
        uberDaasWaitTime?: number;
        rappiCargoWaitTime?: number;
        pidedirectoWaitTime?: number;
    };
    deliveryProvidersInfo?: Array<DeliveryProvidersInfoVm>;
    deliveryZonesEnabled?: boolean;
    webOrdersEnabled?: boolean;
    appOrdersEnabled?: boolean;
    whiteLabelOrdersEnabled?: boolean;
    brandMenuEnabled?: boolean;
    menuCategoryFamiliesEnabled?: boolean;
    limitProductsInOrder?: boolean;
    pideDirectoCustomTipPercentage?: number;
    pideDirectoDefaultTipPercentage?: boolean;
    pideDirectoTipAsPercentageEnabled?: boolean;
    pideDirectoTipEnabled?: number;
    restaurantServiceFeeEnabled?: boolean;
    restaurantServiceFeeRate?: number;
    connectWithMasterMenu?: RestaurantId;
    marketingEnabled?: boolean;
    addCreditsToCustomersEnabled?: boolean;
    posEnabled?: boolean;
    posSimpleView?: boolean; //,
    posPendingOrdersEnabled?: boolean;
    requestCustomerNameOnCreateOrderEnabled?: boolean;
    posUseMenuItemColorEnabled?: boolean;
    posRestaurantZonesEnabled?: boolean;
    posShowTablesEnabled?: boolean;
    posTableRequiredEnabled?: boolean;
    posMultipleCashRegistersEnabled?: boolean;
    assignCashRegisterForExternalMissingOrdersEnabled?: boolean;
    maximumCashAllowedInCashRegisterEnabled?: boolean;
    maximumCashAllowedInCashRegister?: string;
    avoidOpenCashBoxOnReprintTicketEnabled?: boolean;
    openCashRegisterForTransactionsEnabled?: boolean;
    openCashRegisterButtonEnabled?: boolean;
    printCashRegisterTransactionsEnabled?: boolean;
    posOnlyCashInCashRegisterReportEnabled?: boolean;
    showOngoingOrdersInOrdersSalesReportEnabled?: boolean;
    posCustomersRequiredEnabled?: boolean;
    posCustomDiscountCommentsEnabled?: boolean;
    customerDisplayScreenEnabled?: boolean;
    customerDisplayScreenTouchEnabled?: boolean;
    customerDisplayScreenCustomTipEnabled?: boolean;
    customerDisplayScreenCustomTipPercentage?: number;
    customerDisplayScreenPromotionBannerImageUrl?: string;
    customerDisplayScreenSecondaryPromotionBannerImageUrl?: string;
    posPassword?: string;
    authorizationToReprintCompletedOrderEnabled?: boolean;
    requestPasswordForClosePosBusinessDayEnabled?: boolean;
    preventClosePosBusinessDayWithOrdersNotInvoicedEnabled?: boolean;
    newDeviceManagementEnabled?: boolean;
    useOcaPaymentTerminalEnabled?: boolean;
    useQpayTerminalEnabled?: boolean;
    qpayTerminalCommission?: string;
    qpayTerminalDeviceId?: QpayTerminalDeviceId;
    kioskQpayTerminalDeviceId?: QpayTerminalDeviceId;
    inventoryEnabled?: boolean;
    updateInventoryForCountsEnabled?: boolean;
    buyOrdersEnabled?: boolean;
    moneyBillsPadEnabled?: boolean;
    demoModeEnabled?: boolean;
    userManagementEnabled?: boolean;
    clockInEmployeeDayShiftEnabled?: boolean;
    clockInMessage?: string;
    clockOutMessage?: string;
    inventoryShowNegative?: boolean;
    supplyLimitAlertEnabled?: boolean;
    appOrdersCashbackEnabled?: boolean;
    appOrdersCashback?: string;
    kioskEnabled?: boolean;
    kioskPayWithCreditCardInCounterEnabled?: boolean;
    kitchenDisplayScreenEnabled?: boolean;
    analyticsEnabled?: boolean;
    hideCartEnabled?: boolean;
    // addTaxToTotalEnabled?: boolean,
    // taxRate?: string,
    idsInMenuEnabled?: boolean;
    hideMenuEditionEnabled?: boolean;
    fakedProductDiscountPercentage?: string;
    maximumOrderAmount?: string;
    maximumCashOrderAmount?: string;
    subModifiersEnabled?: boolean;
    promotionsEnabled?: boolean;
    refundOngoingOrdersEnabled?: boolean;
    markReadyForPickupButtonEnabled?: boolean;
    hideOpeningHoursEditionEnabled?: boolean;
    assignDriverEnabled?: boolean;
    urlSubdomain?: string;
    urlPathname?: string;
    whatsAppOrdersNumber?: string;
    facebookPixelId?: string;
    googleAnalyticsId?: string;
    pwa?: {
        name?: string;
        short_name?: string;
        favicon?: string;
        appicon?: string;
        splashicon?: string;
        theme_color?: string;
        background_color?: string;
    };
    restaurantColors?: Array<string>;
    autoAcceptOrdersTime?: string;
    autoAcceptRappiOrdersTime?: number;
    autoAcceptUberEatsOrdersTime?: number;
    autoAcceptDidiFoodsOrdersTime?: number;
    autoAcceptPedidosYaOrdersTime: number | null | undefined;
    printAutoAcceptedOrders?: boolean;
    hideDeliveryCostPaidByRestaurantOnTicketEnabled?: boolean;
    hideDeliveryEstimatedTimeEnabled?: boolean;
    pideDirectoApiKey?: PideDirectoApiKey;
    sendAppTrackingUrl?: boolean;
    askToTrackInApp?: boolean;
    bannerImageType?: BannerImageType;
    consecutiveOrderIdEnabled?: boolean;
    consecutiveOrderIdRange?: ConsecutiveOrderIdRange;
    showOrderItemsToDriverEnabled: boolean;
    startDeliveryUntilPaymentLinkIsPaidEnabled?: boolean;
    lokiEnabled?: boolean;
    zipCode?: string;
    productLayout?: ProductLayout;
    kioskOrdersCashbackEnabled?: boolean;
    kioskOrdersCashbackPercentage?: number;
    ecommerceOrdersCashbackEnabled?: boolean;
    ecommerceOrdersCashbackPercentage?: number;
    displayPeriodByDateAndTimeEnabled?: boolean;
    deliveryProviderSupportEnabled?: boolean;
    posTablesTabAsDefaultEnabled?: boolean;
    useRappiQrCodeEnabled?: boolean;
    nubefactStoreId?: NubefactStoreId;
    nubefactToken?: string;
    printRappiQrCodeOnTicketEnabled?: boolean;
};
