import { letseatadminApiMethod } from 'src/api/letseatadminApi';
import type { ApiSauceResponse } from 'src/api/types/ApiSauceResponse';
import type { QuickActionId } from 'src/types/Id';
import type { QuickActionVm } from 'src/types/QuickActionVm';

export async function getQuickActionApi(request: GetQuickActionApiRequest): ApiSauceResponse<QuickActionVm> {
    return letseatadminApiMethod('quickAction/getQuickActionApi', request);
}

export type GetQuickActionApiRequest = {
    quickActionId: QuickActionId;
};
