import IconButton from '@material-ui/core/IconButton';
import { withStyles } from '@material-ui/core/styles';
import TableCell from '@material-ui/core/TableCell';
import TableFooter from '@material-ui/core/TableFooter';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import Tooltip from '@material-ui/core/Tooltip';
import RefreshIcon from '@material-ui/icons/Refresh';
import moment from 'moment';
import MUIDataTable from 'mui-datatables';
import * as React from 'react';
import { tribalExportKitchensBillingApi } from 'src/api/letseatadmin/kitchenBilling/tribalExportKitchensBillingApi';
import { letseatmanagerApiDeprecated } from 'src/api/letseatmanagerApiDeprecated';
import type { FilterState } from 'src/components/input/FromToPaymentDateFilter';
import { CustomToolbarSelect } from 'src/components/mui-datatables/CustomToolbarSelect';
import { ExportToolbarButton } from 'src/components/mui-datatables/ExportToolbarButton';
import { UpdatingContentProgress } from 'src/components/UpdatingContentProgress';
import { history } from 'src/config/history';
import { Countries, Country } from 'src/constants/Country';
import { RoutePaths } from 'src/constants/RoutePath';
import { translate } from 'src/i18n/translate';
import type { KitchenId } from 'src/types/Id';
import { alertKnownErrorOrSomethingWentWrong } from 'src/utils/alert/alertKnownErrorOrSomethingWentWrong';
import { downloadTextInFile } from 'src/utils/html/downloadTextInFile';
import { getSelectedRowsData } from 'src/utils/mui-datatables/getSelectedRowsData';
import { deepEqual } from 'src/utils/object/deepEqual';
import { RegExps } from 'src/utils/RegExps';

export default withStyles((theme) => ({
    table: {
        whiteSpace: 'nowrap',
        '& tr': { cursor: 'pointer' },
        '& tr:hover': { backgroundColor: 'rgba(0, 0, 0, 0.07) !important' },
    },
    toolbar: {
        paddingRight: theme.spacing(3),
    },
}))(
    class KitchenBillingData extends React.Component<Props, State> {
        state = {
            filter: this.props.filter,
            loading: false,
            rows: [],
            total: {
                cash: '',
                app: '',
                totalSold: '',
                commission: '',
                iva: '',
                toDeposit: '',
                orders: 0,
                notAccepted: 0,
            },
        };

        async componentDidMount() {
            await this.load();
        }

        async componentDidUpdate(prevProps: Props, prevState: State) {
            if (!deepEqual(prevProps.filter, this.props.filter)) {
                await this.load();
            }
        }

        // shouldComponentUpdate(nextProps: Props, nextState: State): boolean {
        //     return !deepEqual(this.props, nextProps) || !deepEqual(this.state, nextState);
        // }

        load = async () => {
            this.setState({ loading: true });
            const request: Request = {
                from: moment(this.props.filter.from).format('YYYY-MM-DD'),
                to: moment(this.props.filter.to).format('YYYY-MM-DD'),
                country: Countries.MX,
            };
            const response = await letseatmanagerApiDeprecated.admin.getKitchenBillingData(request);
            this.setState({ loading: false });
            if (!response.ok) {
                alertKnownErrorOrSomethingWentWrong(response);
                return;
            }
            this.setState({
                rows: response.data.rows,
                total: response.data.total,
            });
        };

        render() {
            const { classes } = this.props;
            return (
                <div style={{ position: 'relative' }}>
                    <UpdatingContentProgress loading={this.state.loading} />
                    <MUIDataTable
                        /* @ts-ignore */
                        className={classes.table}
                        title={translate('Kitchens')}
                        data={this.state.rows.map((row: any) => ({
                            kitchenId: row.kitchenId,
                            kitchenName: row.kitchenName,
                            kitchenZone: row.kitchenZone,
                            claveId: row.claveId ?? '',
                            cash: parseFloat(row.cash),
                            app: parseFloat(row.app),
                            totalSold: parseFloat(row.totalSold),
                            fixedCommission: parseFloat(row.fixedCommission),
                            commission: parseFloat(row.commission),
                            iva: parseFloat(row.iva),
                            toDeposit: parseFloat(row.toDeposit),
                            orders: row.orders,
                        }))}
                        columns={[
                            {
                                name: 'kitchenId',
                                label: ' ',
                                options: {
                                    display: 'excluded',
                                    filter: false,
                                },
                            },
                            {
                                name: 'kitchenName',
                                label: translate('Kitchen'),
                                options: {},
                            },
                            {
                                name: 'kitchenZone',
                                label: translate('Zone'),
                                options: {},
                            },
                            {
                                name: 'claveId',
                                label: translate('Clave Id'),
                                options: {
                                    filter: false,
                                },
                            },
                            {
                                name: 'cash',
                                label: translate('Cash'),
                                options: {
                                    filter: false,
                                    searchable: false,
                                },
                            },
                            {
                                name: 'app',
                                label: translate('App'),
                                options: {
                                    filter: false,
                                    searchable: false,
                                },
                            },
                            {
                                name: 'totalSold',
                                label: translate('Total Sold'),
                                options: {
                                    filter: false,
                                    searchable: false,
                                },
                            },
                            {
                                name: 'fixedCommission',
                                label: translate('Fixed Commission'),
                                options: {
                                    filter: false,
                                    searchable: false,
                                },
                            },
                            {
                                name: 'commission',
                                label: translate('Commission'),
                                options: {
                                    filter: false,
                                    searchable: false,
                                },
                            },
                            {
                                name: 'iva',
                                label: translate('IVA'),
                                options: {
                                    filter: false,
                                    searchable: false,
                                },
                            },
                            {
                                name: 'toDeposit',
                                label: translate('To Deposit'),
                                options: {
                                    filter: false,
                                    searchable: false,
                                },
                            },
                            {
                                name: 'orders',
                                label: translate('Orders'),
                                options: {
                                    filter: false,
                                    searchable: false,
                                },
                            },
                        ]}
                        options={{
                            responsive: 'standard',
                            tableBodyMaxHeight: '500px',
                            selectableRows: 'multiple',
                            customToolbarSelect: (selectedRows, displayData) => {
                                const selectedRowsData = getSelectedRowsData(selectedRows, displayData);
                                const selectedKitchenIds = selectedRowsData.map((d: any) => d[0]);
                                const handleClickExportBankAccounts = async () => {
                                    const from = moment(this.props.filter.from).format('YYYY-MM-DD');
                                    const to = moment(this.props.filter.to).format('YYYY-MM-DD');
                                    const paymentDate = moment(this.props.filter.paymentDate).format('YYYY-MM-DD');
                                    const response = await letseatmanagerApiDeprecated.admin.exportKitchenBillingData({
                                        from,
                                        to,
                                        paymentDate,
                                        kitchenIds: selectedKitchenIds,
                                    });
                                    if (!response.ok) {
                                        alertKnownErrorOrSomethingWentWrong(response);
                                        return;
                                    }
                                    const result = response.data;
                                    downloadTextInFile(result.transactionExport, `kitchen-billing-export-${from}--${to}.txt`);
                                    alert(
                                        [
                                            `exported: ${result.exported}`,
                                            `removed: ${result.removed}`,
                                            `accountNotExported: ${result.accountNotExported}`,
                                            `nothingToTransfer: ${result.nothingToTransfer}`,
                                        ].join('\n'),
                                    );
                                };

                                const handleClickExportTribalFormat = async () => {
                                    const selectedKitchenIds = getSelectedRowsData(selectedRows, displayData).map((d: any) => d[0]);
                                    const response = await tribalExportKitchensBillingApi({
                                        from: moment(this.props.filter.from).format('YYYY-MM-DD'),
                                        to: moment(this.props.filter.to).format('YYYY-MM-DD'),
                                        paymentDate: moment(this.props.filter.paymentDate).format('YYYY-MM-DD'),
                                        kitchenIds: selectedKitchenIds,
                                    });
                                    if (!response.ok) {
                                        alertKnownErrorOrSomethingWentWrong(response);
                                        return;
                                    }
                                    downloadTextInFile(
                                        response.data.tribalExportFormat,
                                        `driver-billing-tribal-export-format-${moment(this.props.filter.from).format('YYYY-MM-DD')}--${moment(this.props.filter.to).format('YYYY-MM-DD')}.txt`,
                                    );
                                    alert(
                                        [
                                            `exported: ${response.data.exported}`,
                                            `banned: ${response.data.banned}`,
                                            `removed: ${response.data.removed}`,
                                            `nothingToTransfer: ${response.data.nothingToTransfer}`,
                                        ].join('\n'),
                                    );
                                };

                                return (
                                    <CustomToolbarSelect>
                                        {/*<DownloadCsvToolbarButton onClick={createHandleClickDownloadCsv(this, getSelectedRows(selectedRows, displayData))} />*/}
                                        <ExportToolbarButton onClick={handleClickExportBankAccounts} tooltip={translate('Export As Bank Transactions')} />
                                        <ExportToolbarButton onClick={handleClickExportTribalFormat} tooltip={translate('Export As Tribal Export Format')} />
                                    </CustomToolbarSelect>
                                );
                            },
                            // setRowProps: (row) => {
                            //     return {
                            //         style: { backgroundColor: getCompaniestatusColor(row[30], row[32]) },
                            //     };
                            // },
                            onRowClick: (
                                rowData: Array<string>,
                                rowMeta: {
                                    dataIndex: number;
                                    rowIndex: number;
                                },
                            ) => {
                                history.push({
                                    pathname: RoutePaths.ADMIN_KITCHEN_BILLING_FOR_KITCHEN.replace(`:kitchenId(${RegExps.uuid})`, rowData[0]),
                                    search: history.location.search,
                                });
                            },
                            filterType: 'multiselect',
                            rowsPerPage: 100,
                            customToolbar: () => {
                                return (
                                    <>
                                        <Tooltip title='Refresh'>
                                            <IconButton onClick={this.load}>
                                                <RefreshIcon />
                                            </IconButton>
                                        </Tooltip>
                                    </>
                                );
                            },
                            customFooter: (count, page, rowsPerPage, changeRowsPerPage, changePage) => (
                                <TableFooter>
                                    <TableRow>
                                        <TablePagination
                                            count={count}
                                            rowsPerPage={rowsPerPage}
                                            page={page}
                                            onChangePage={(_, page) => changePage(page)}
                                            onChangeRowsPerPage={(event) => changeRowsPerPage(event.target.value)}
                                            rowsPerPageOptions={[10, 15, 100]}
                                        />
                                    </TableRow>
                                    <TableRow>
                                        <TableCell size='small'>
                                            {translate('Cash')}: {this.state.total.cash}
                                        </TableCell>
                                        <TableCell size='small'>
                                            {translate('App')}: {this.state.total.app}
                                        </TableCell>
                                        <TableCell size='small'>
                                            {translate('Total Sold')}: {this.state.total.totalSold}
                                        </TableCell>
                                        <TableCell size='small'>
                                            {translate('Commission')}: {this.state.total.commission}
                                        </TableCell>
                                        <TableCell size='small'>
                                            {translate('IVA')}: {this.state.total.iva}
                                        </TableCell>
                                        <TableCell size='small'>
                                            {translate('To Deposit')}: {this.state.total.toDeposit}
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell size='small'>
                                            {translate('Orders')}: {this.state.total.orders} COMPLETED
                                        </TableCell>
                                        <TableCell size='small'>
                                            {translate('Ignored')}: {this.state.total.notAccepted} NEW/ACCEPTED
                                        </TableCell>
                                    </TableRow>
                                </TableFooter>
                            ),
                            downloadOptions: { filename: 'billing data.csv' },
                            // filterList: [[], [], [], [], ['MEXICO']],
                        }}
                    />
                </div>
            );
        }
    },
) as React.ComponentType<any>;

type Props = {
    filter: FilterState;
    classes: any;
};

type State = {
    filter: FilterState;
    loading: boolean;
} & KitchenBillingDataVm;

type Request = {
    from: string;
    to: string;
    country: Country;
};

type KitchenBillingDataVm = {
    rows: Array<RowVm>;
    total: TotalVm;
};

type RowVm = {
    kitchenId: KitchenId;
    kitchenName: string;
    kitchenZone: string;
    claveId?: string;
    cash: string;
    app: string;
    totalSold: string;
    fixedCommission: string;
    commission: string;
    iva: string;
    toDeposit: string;
    orders: number;
    notAccepted: number;
};

type TotalVm = {
    cash: string;
    app: string;
    totalSold: string;
    commission: string;
    iva: string;
    toDeposit: string;
    orders: number;
    notAccepted: number;
};
