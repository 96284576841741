import { useState } from 'react';
import * as React from 'react';
import { removePaymentMethodApi } from 'src/api/letseatmanager/restaurant/removePaymentMethodApi';
import { RemoveDialog } from 'src/components/dialog/RemoveDialog';
import { translate } from 'src/i18n/translate';
import { alertKnownErrorOrSomethingWentWrong } from 'src/utils/alert/alertKnownErrorOrSomethingWentWrong';
import { useSelector } from 'src/utils/react/useSelector';
import { requireValue } from 'src/utils/require/requireValue';

export function RemoveCustomPaymentMethodDialog({ open, paymentMethodName, onClose, onSuccess }: Props): React.ReactElement {
    const [loading, setLoading] = useState(false);

    const restaurantId = useSelector((state) => state.app.restaurantId);

    const handleSubmit = async () => {
        setLoading(true);
        const response = await removePaymentMethodApi({
            restaurantId,
            name: requireValue(paymentMethodName),
        });
        setLoading(false);
        if (!response.ok) {
            alertKnownErrorOrSomethingWentWrong(response);
            return;
        }
        onSuccess();
    };

    return (
        <RemoveDialog
            open={open}
            onClose={onClose}
            onSubmit={handleSubmit}
            title={translate('Remove Payment Method')}
            subtitle={translate('Are you sure you want to remove the payment method @paymentMethod?', { paymentMethod: paymentMethodName })}
            disabled={loading}
        />
    );
}

type Props = {
    open: boolean;
    paymentMethodName: string | undefined;
    onClose: any;
    onSuccess: any;
};
