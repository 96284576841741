import { Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Button, Dialog, DialogActions, DialogContent } from '@pidedirecto/ui';
import { Form, FormPercentNumberField, FormSwitch, FormTextField } from '@pidedirecto/ui/form';
import { useConfirmDialog, useForm, useWatch } from '@pidedirecto/ui/hooks';
import { CrossIcon } from '@pidedirecto/ui/icons';
import { useEffect, useState } from 'react';
import * as React from 'react';
import { changeRestaurantPaymentMethodApi } from 'src/api/letseatmanager/restaurantPaymentMethod/changeRestaurantPaymentMethodApi';
import { removeRestaurantPaymentMethodApi } from 'src/api/letseatmanager/restaurantPaymentMethod/removeRestaurantPaymentMethodApi';
import { FormChannelsMultiselectableAutocomplete } from 'src/components/form/FormChannelsMultiselectableAutocomplete';
import { FormMexicanPaymentMethodCodeSelect } from 'src/components/form/FormMexicanPaymentMethodCodeSelect';
import { FormOrderTypesMultiselectableAutocomplete } from 'src/components/form/FormOrderTypesMultiselectableAutocomplete';
import { FormPaymentMethodTypeSelect } from 'src/components/form/FormPaymentMethodTypeSelect';
import { FormPspSelect } from 'src/components/form/FormPspSelect';
import { FormTerminalRestaurantPaymentMethodAppsMultiselectableAutocomplete } from 'src/components/form/FormTerminalRestaurantPaymentMethodAppsMultiselectableAutocomplete';
import { Text } from 'src/components/Text';
import { Apps } from 'src/constants/App';
import { CustomPaymentMethodIcon, CustomPaymentMethodIcons } from 'src/constants/CustomPaymentMethodIcons';
import { MexicanPaymentMethodCode, MexicanPaymentMethodCodes } from 'src/constants/MexicanPaymentMethodCode';
import { OrderType, OrderTypes } from 'src/constants/OrderType';
import { PaymentMethod, PaymentMethods } from 'src/constants/PaymentMethod';
import { PaymentTerminalProviders } from 'src/constants/PaymentTerminalProvider';
import { RestaurantPaymentMethodApp, RestaurantPaymentMethodApps } from 'src/constants/RestaurantPaymentMethodApps';
import { RestaurantPsp, RestaurantPsps } from 'src/constants/RestaurantPsp';
import { translate } from 'src/i18n/translate';
import { PaymentMethodIcon } from 'src/scenes/letseatmanager/theRestaurant/PaymentMethodIcon';
import { PlexoStoreId } from 'src/types/Id';
import type { RestaurantPaymentMethodVm } from 'src/types/RestaurantPaymentMethodVm';
import { alertKnownErrorOrSomethingWentWrong } from 'src/utils/alert/alertKnownErrorOrSomethingWentWrong';
import { isMercadoPagoRestaurantPsp } from 'src/utils/cardPsp/isMercadoPagoRestaurantPsp';
import { isPlexoRestaurantPsp } from 'src/utils/cardPsp/isPlexoRestaurantPsp';
import { isArgentina } from 'src/utils/country/isArgentina';
import { isMexico } from 'src/utils/country/isMexico';
import { isParaguay } from 'src/utils/country/isParaguay';
import { isPeru } from 'src/utils/country/isPeru';
import { isUruguay } from 'src/utils/country/isUruguay';
import { isCashMexicanPaymentMethodCode } from 'src/utils/mexicanPaymentMethodCode/isCashMexicanPaymentMethodCode';
import { isCardOnDeliveryPayment } from 'src/utils/paymentMethod/isCardOnDeliveryPayment';
import { isCardPayment } from 'src/utils/paymentMethod/isCardPayment';
import { isPaymentLinkPayment } from 'src/utils/paymentMethod/isPaymentLinkPayment';
import { isTerminalPayment } from 'src/utils/paymentMethod/isTerminalPayment';
import { classNames } from 'src/utils/react/classNames';
import { useSelector } from 'src/utils/react/useSelector';
import { isPidedirectoPayment } from 'src/utils/restaurantPaymentMethod/isPidedirectoPayment';

export function ChangeRestaurantPaymentMethodDialog({ open, onClose, restaurantPaymentMethod, onSuccess }: Props): React.ReactElement | null {
    const classes = useStyles();
    const form = useForm();
    const confirmDialog = useConfirmDialog();

    const { watch, reset, control } = form;

    const [loading, setLoading] = useState(false);
    const [editMercadoPagoCredentials, setEditMercadoPagoCredentials] = useState(false);
    const [iconSelected, setIconSelected] = useState<CustomPaymentMethodIcon | undefined>(restaurantPaymentMethod?.icon);

    const restaurantPsp = watch('restaurantPsp', restaurantPaymentMethod?.psps?.[0]);
    const paymentReferenceEnabled = useWatch({ name: 'paymentReferenceEnabled', control });

    const restaurantCountry = useSelector((state) => state.app.restaurant.country);

    const showMercadoPagoCredentialsForm = editMercadoPagoCredentials || !restaurantPaymentMethod?.mercadoPagoPublicKey;

    useEffect(() => {
        reset({
            name: isPidedirectoPayment(restaurantPaymentMethod?.restaurantPaymentMethodType) ? translate(`PaymentMethods.${restaurantPaymentMethod?.paymentMethod}`) : restaurantPaymentMethod?.name,
            paymentMethod: restaurantPaymentMethod?.paymentMethod,
            mexicanPaymentMethodCode: restaurantPaymentMethod?.mexicanPaymentMethodCode ?? MexicanPaymentMethodCodes.CASH,
            restaurantPsp: restaurantPaymentMethod?.psps?.[0] ?? null,
            plexoIntegrationEnabled: restaurantPaymentMethod?.plexoIntegrationEnabled ?? null,
            plexoStoreId: restaurantPaymentMethod?.plexoStoreId ?? null,
            paymentReferenceEnabled: restaurantPaymentMethod?.paymentReferenceEnabled ?? null,
            mercadoPagoPublicKey: restaurantPaymentMethod?.mercadoPagoPublicKey ?? null,
            mercadoPagoSecretKey: restaurantPaymentMethod?.mercadoPagoSecretKey ?? null,
            channels: restaurantPaymentMethod?.channels,
            orderTypes: restaurantPaymentMethod?.orderTypes,
            commission: restaurantPaymentMethod?.commission ?? 0,
        });
    }, [restaurantPaymentMethod]);

    const saveRestaurantPaymentMethod = async (form: FormType) => {
        if (!restaurantPaymentMethod?.restaurantPaymentMethodId) return;
        setLoading(true);
        const response = await changeRestaurantPaymentMethodApi({
            restaurantPaymentMethodId: restaurantPaymentMethod.restaurantPaymentMethodId,
            restaurantPaymentMethodType: restaurantPaymentMethod.restaurantPaymentMethodType,
            paymentMethod: isMexico(restaurantCountry) ? getPaymentMethodByMexicanPaymentMethod(form) : form.paymentMethod,
            mexicanPaymentMethodCode: isMexico(restaurantCountry) ? form.mexicanPaymentMethodCode : undefined,
            paymentTerminalProvider: isTerminalPayment(restaurantPaymentMethod.paymentMethod) ? getTerminalProvider() : undefined,
            mercadoPagoPublicKey: form?.mercadoPagoPublicKey,
            mercadoPagoSecretKey: form?.mercadoPagoSecretKey,
            name: form.name,
            psps: form.restaurantPsp,
            commission: form.commission ?? 0,
            channels: getRestaurantPaymentChannels(form),
            orderTypes: getRestaurantOrderTypeChannels(form),
            icon: iconSelected,
            enabled: restaurantPaymentMethod.enabled,
            paymentReferenceEnabled: form.paymentReferenceEnabled,
        });
        setLoading(false);
        if (!response.ok) {
            alertKnownErrorOrSomethingWentWrong(response);
        }
        onSuccess();
        onClose();
    };

    const getPaymentMethodByMexicanPaymentMethod = (form: FormType) => {
        if (isCashMexicanPaymentMethodCode(form.mexicanPaymentMethodCode)) {
            return PaymentMethods.CASH;
        }
        return PaymentMethods.CREDIT_CARD;
    };

    const getRestaurantPaymentChannels = (form: FormType) => {
        if (paymentReferenceEnabled) return [RestaurantPaymentMethodApps.PIDEDIRECTOPOS];

        if (form.channels && form.channels.length > 0) return form.channels;

        if (isTerminalPayment(restaurantPaymentMethod?.paymentMethod)) {
            return [Apps.PIDEDIRECTOPOS, Apps.PIDEDIRECTOKIOSK];
        }

        if (!isPidedirectoPayment(restaurantPaymentMethod?.restaurantPaymentMethodType)) {
            return Object.values(RestaurantPaymentMethodApps).filter((restaurantChannel: RestaurantPaymentMethodApp) => restaurantChannel !== RestaurantPaymentMethodApps.PIDEDIRECTOKIOSK);
        }
        return Object.values(RestaurantPaymentMethodApps);
    };

    const getRestaurantOrderTypeChannels = (form: FormType) => {
        if (form.orderTypes && form.orderTypes.length > 0) return form.orderTypes;

        if (restaurantPaymentMethod?.paymentMethod && isCardOnDeliveryPayment(restaurantPaymentMethod?.paymentMethod)) {
            return [OrderTypes.DELIVERY_ORDER];
        }
        return Object.values(OrderTypes);
    };

    const getTerminalProvider = () => {
        if (isMexico(restaurantCountry)) {
            return PaymentTerminalProviders.QPAY;
        }
        if (isArgentina(restaurantCountry) || isUruguay(restaurantCountry) || isPeru(restaurantCountry) || isParaguay(restaurantCountry)) {
            return PaymentTerminalProviders.OCA;
        }
        return undefined;
    };

    const getPaymentTitle = (restaurantPaymentMethod: RestaurantPaymentMethodVm) => {
        if (isTerminalPayment(restaurantPaymentMethod.paymentMethod)) {
            if (isMexico(restaurantCountry)) {
                return PaymentTerminalProviders.QPAY;
            }
            if (isArgentina(restaurantCountry) || isUruguay(restaurantCountry)) {
                return PaymentTerminalProviders.OCA;
            }
        }
        if (!isPidedirectoPayment(restaurantPaymentMethod.restaurantPaymentMethodType)) return restaurantPaymentMethod.name;

        return restaurantPaymentMethod.paymentMethod;
    };

    const getExcludedChannels = (restaurantPaymentMethod: RestaurantPaymentMethodVm) => {
        if (!isPidedirectoPayment(restaurantPaymentMethod.restaurantPaymentMethodType)) {
            return [RestaurantPaymentMethodApps.PIDEDIRECTOKIOSK];
        }
        if (isPaymentLinkPayment(restaurantPaymentMethod.paymentMethod)) {
            return [RestaurantPaymentMethodApps.PIDEDIRECTOKIOSK, RestaurantPaymentMethodApps.PIDEDIRECTO];
        }
        return undefined;
    };

    const removeRestaurantPaymentMethod = async () => {
        const rejectOrder = await confirmDialog({
            content: translate('You are about to delete the payment method “@restaurantPaymentMethod”, are you sure you want to continue?', { restaurantPaymentMethod: restaurantPaymentMethod?.name }),
            acceptButtonText: translate('Yes I want to delete it'),
            cancelButtonText: translate('Cancel'),
            variant: 'warning',
        });

        if (!restaurantPaymentMethod?.restaurantPaymentMethodId || !rejectOrder) return;

        setLoading(true);
        const response = await removeRestaurantPaymentMethodApi({
            restaurantPaymentMethodId: restaurantPaymentMethod.restaurantPaymentMethodId,
        });
        setLoading(false);
        if (!response.ok) {
            alertKnownErrorOrSomethingWentWrong(response);
        }
        onSuccess();
        onClose();
    };

    const onSelectIcon = (customIcon: CustomPaymentMethodIcon | undefined) => {
        if (iconSelected === customIcon) {
            setIconSelected(undefined);
            return;
        }
        setIconSelected(customIcon);
    };

    if (!restaurantPaymentMethod) return null;

    return (
        <Dialog
            loading={loading}
            open={open}
            onClose={onClose}
            title={
                isPidedirectoPayment(restaurantPaymentMethod.restaurantPaymentMethodType)
                    ? translate('@paymentMethod payment', { paymentMethod: translate(getPaymentTitle(restaurantPaymentMethod)) })
                    : translate('Edit @paymentMethod', { paymentMethod: translate(getPaymentTitle(restaurantPaymentMethod)) })
            }
        >
            <Form form={form} onSubmit={saveRestaurantPaymentMethod} classes={{ form: classes.formContainer }}>
                <DialogContent className={classes.formContent}>
                    <FormTextField
                        name='name'
                        label={isPidedirectoPayment(restaurantPaymentMethod.restaurantPaymentMethodType) ? translate('Payment type') : translate('Name')}
                        disabled={isPidedirectoPayment(restaurantPaymentMethod.restaurantPaymentMethodType)}
                        required={!isPidedirectoPayment(restaurantPaymentMethod.restaurantPaymentMethodType)}
                    />
                    {!isPidedirectoPayment(restaurantPaymentMethod.restaurantPaymentMethodType) && isMexico(restaurantCountry) && (
                        <FormMexicanPaymentMethodCodeSelect
                            name={'mexicanPaymentMethodCode'}
                            label={translate('Payment method for billing')}
                            disabled={loading || isPidedirectoPayment(restaurantPaymentMethod.restaurantPaymentMethodType)}
                            required
                        />
                    )}
                    {!isPidedirectoPayment(restaurantPaymentMethod.restaurantPaymentMethodType) && !isMexico(restaurantCountry) && (
                        <FormPaymentMethodTypeSelect
                            name={'paymentMethod'}
                            label={translate('Payment type')}
                            filter={!isPidedirectoPayment(restaurantPaymentMethod.restaurantPaymentMethodType) ? [PaymentMethods.CREDIT_CARD, PaymentMethods.CASH] : undefined}
                            disabled={loading || isPidedirectoPayment(restaurantPaymentMethod.restaurantPaymentMethodType)}
                            required
                        />
                    )}
                    {(isCardPayment(restaurantPaymentMethod.paymentMethod) || isPaymentLinkPayment(restaurantPaymentMethod.paymentMethod)) && (
                        <FormPercentNumberField name='commission' label={translate('Commission')} max={100} />
                    )}
                    {(isPaymentLinkPayment(restaurantPaymentMethod.paymentMethod) || isCardPayment(restaurantPaymentMethod.paymentMethod)) &&
                        isPidedirectoPayment(restaurantPaymentMethod.restaurantPaymentMethodType) && (
                            <FormPspSelect
                                name={'restaurantPsp'}
                                label={translate('Psp')}
                                helperText={translate('Psp to be used for ecommerce payments')}
                                filter={!isUruguay(restaurantCountry) ? [RestaurantPsps.PLEXO] : undefined}
                                disabled={loading}
                                required
                            />
                        )}
                    {isPlexoRestaurantPsp(restaurantPsp) && (
                        <div>
                            <FormTextField name='plexoStoreId' label={translate('Plexo store id')} tooltip={translate('The plexo store id is required for the plexo integration')} />
                        </div>
                    )}
                    {isMercadoPagoRestaurantPsp(restaurantPsp) && (
                        <>
                            {showMercadoPagoCredentialsForm && (
                                <div>
                                    <FormTextField name='mercadoPagoPublicKey' label={translate('Mercado pago public key')} />
                                    <FormTextField name='mercadoPagoSecretKey' label={translate('Mercado pago secret key')} />
                                </div>
                            )}
                            {!showMercadoPagoCredentialsForm && (
                                <Grid item xs={12}>
                                    <div className={classes.credentialContainer}>
                                        <Text>{translate('MercadoPago public key @mercadoPagoPublicKey', { mercadoPagoPublicKey: restaurantPaymentMethod?.mercadoPagoPublicKey })}</Text>
                                        <Button variant={'icon'} onClick={() => setEditMercadoPagoCredentials(true)}>
                                            <CrossIcon title={translate('Remove mercado pago key')} />
                                        </Button>
                                    </div>
                                </Grid>
                            )}
                        </>
                    )}
                    {!isPidedirectoPayment(restaurantPaymentMethod.restaurantPaymentMethodType) && (
                        <FormSwitch disabled={loading} name='paymentReferenceEnabled' label={translate('Request payment reference')} />
                    )}
                    {paymentReferenceEnabled && (
                        <div className={classes.uniqueChannelContainer}>
                            <h5 className={classes.titleOrderTypeSection}>{translate('Type of channels in which they accept payments with reference')}</h5>
                            <span className={classes.titleOrderTypeOption}>{translate(RestaurantPaymentMethodApps.PIDEDIRECTOPOS)}</span>
                        </div>
                    )}
                    {!isTerminalPayment(restaurantPaymentMethod.paymentMethod) && !paymentReferenceEnabled && (
                        <FormChannelsMultiselectableAutocomplete
                            name={'channels'}
                            label={translate('Channels that accept @payment payments', { payment: translate(getPaymentTitle(restaurantPaymentMethod)) })}
                            exclude={getExcludedChannels(restaurantPaymentMethod)}
                            disabled={loading}
                        />
                    )}
                    {isTerminalPayment(restaurantPaymentMethod.paymentMethod) && (
                        <FormTerminalRestaurantPaymentMethodAppsMultiselectableAutocomplete
                            name={'channels'}
                            label={translate('Channels that accept @payment payments', { payment: translate(getPaymentTitle(restaurantPaymentMethod)) })}
                        />
                    )}
                    {!isCardOnDeliveryPayment(restaurantPaymentMethod.paymentMethod) && (
                        <FormOrderTypesMultiselectableAutocomplete
                            name={'orderTypes'}
                            label={translate('Order types that accept @payment payments', { payment: translate(getPaymentTitle(restaurantPaymentMethod)) })}
                        />
                    )}
                    {isCardOnDeliveryPayment(restaurantPaymentMethod.paymentMethod) && (
                        <div>
                            <h5 className={classes.titleOrderTypeSection}>{translate('Type of order in which you accept card on delivery payments')}</h5>
                            <span className={classes.titleOrderTypeOption}>{translate(OrderTypes.DELIVERY_ORDER)}</span>
                        </div>
                    )}
                    {!isPidedirectoPayment(restaurantPaymentMethod.restaurantPaymentMethodType) && (
                        <div className={classes.selectIconContainer}>
                            <p className={classes.label}>{translate('Icon')}</p>
                            <div className={classes.iconsContainer}>
                                {Object.keys(CustomPaymentMethodIcons).map((customPaymentMethodIcon) => (
                                    <Button
                                        variant={'secondary'}
                                        classes={{
                                            button: classNames(
                                                iconSelected === CustomPaymentMethodIcons[customPaymentMethodIcon as keyof typeof CustomPaymentMethodIcons]
                                                    ? classes.iconButtonSelected
                                                    : classes.restaurantIconButton,
                                            ),
                                        }}
                                        onClick={() => onSelectIcon(CustomPaymentMethodIcons[customPaymentMethodIcon as keyof typeof CustomPaymentMethodIcons])}
                                        key={`${CustomPaymentMethodIcons[customPaymentMethodIcon as keyof typeof CustomPaymentMethodIcons]}-key`}
                                    >
                                        <PaymentMethodIcon icon={CustomPaymentMethodIcons[customPaymentMethodIcon as keyof typeof CustomPaymentMethodIcons]} />
                                    </Button>
                                ))}
                            </div>
                        </div>
                    )}
                </DialogContent>
                {!isPidedirectoPayment(restaurantPaymentMethod.restaurantPaymentMethodType) && (
                    <Button classes={{ button: classes.removeButton }} variant='outline' onClick={removeRestaurantPaymentMethod} disabled={loading}>
                        {translate('Remove Method')}
                    </Button>
                )}
                <DialogActions className={classes.buttonsContainer}>
                    <Button variant='outline' onClick={() => onClose?.()} disabled={loading}>
                        {translate('Cancel')}
                    </Button>
                    <Button type='submit' disabled={loading}>
                        {translate('Update')}
                    </Button>
                </DialogActions>
            </Form>
        </Dialog>
    );
}

const useStyles = makeStyles((theme) => ({
    formContainer: {
        height: '100%',
    },
    formContent: {
        display: 'flex',
        flexDirection: 'column',
        gap: 12,
        height: '45vh',
        padding: 10,
        [theme.breakpoints.up('sm')]: {
            height: '60vh',
        },
    },
    updateButton: {
        color: theme.palette.primary.main,
        border: `1px solid ${theme.palette.primary.main}`,
        fontFamily: theme.typography.semiBold,
        fontSize: 15,
        borderRadius: 5,
        height: 40,
        width: 150,
        textTransform: 'none',
    },
    cancelButton: {
        fontFamily: theme.typography.semiBold,
        fontSize: 15,
        borderRadius: 5,
        height: 40,
        width: 150,
        textTransform: 'none',
    },
    containerOptionDisabled: {
        backgroundColor: '#F5F5F5',
        color: '#9BA1AA',
    },
    bannedText: {
        color: '#E32F45',
    },
    optionContainer: {
        backgroundColor: 'transparent !important',
        '&:active': {
            backgroundColor: `${theme.palette.primary.light} !important`,
        },
        '&:hover': {
            backgroundColor: `${theme.palette.primary.light} !important`,
        },
    },
    helperText: {
        color: '#87888B',
        marginLeft: 4,
    },
    chipsContainer: {
        marginTop: 12,
        width: '100%',
        display: 'flex',
        flexWrap: 'wrap',
        gap: 12,
        height: 128,
        border: '1px solid #D9D9D9',
        borderRadius: 8,
        padding: 10,
        overflowY: 'scroll',
        '&::-webkit-scrollbar': {
            display: 'none',
        },
    },
    chipRemoveIcon: {
        backgroundColor: 'transparent',
        color: 'black',
        border: 'none',
        padding: '0px 0px',
        marginLeft: 8,
        '&:hover': {
            backgroundColor: 'transparent',
        },
        '&:focus': {
            outline: 'none',
        },
    },
    chipContainer: {
        fontFamily: theme.typography.medium,
        fontSize: 15,
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        border: '1px solid #676060B2',
        borderRadius: 6,
        gap: 5,
        backgroundColor: '#D9D9D933',
        height: 26,
    },
    chip: {
        display: 'flex',
        flexDirection: 'column',
        marginLeft: 6,
        fontSize: 12,
        color: theme.palette.text.secondary,
    },
    iconButton: {
        width: 25,
        height: 25,
        borderRadius: 28,
        minHeight: 25,
        border: 'none',
        '&:focus': {
            outline: 'none',
        },
    },
    selectIconContainer: {
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        marginTop: 12,
    },
    label: {
        fontFamily: theme.typography.regular,
        color: '#2E3748',
        fontSize: 14,
    },
    iconsContainer: {
        width: '100%',
        display: 'flex',
        flexDirection: 'row',
        gap: 12,
        padding: 4,
        overflowY: 'scroll',
        '&::-webkit-scrollbar': {
            display: 'none',
        },
    },
    iconButtonSelected: {
        borderStyle: 'solid',
        borderWidth: '2px',
        borderColor: '#06B7A2',
        borderRadius: '100%',
        width: 50,
        maxHeight: 50,
        '&:hover': {
            borderStyle: 'solid',
            borderWidth: '2px',
            borderColor: '#06B7A2',
        },
    },
    restaurantIconButton: {
        display: 'flex',
        borderRadius: '100%',
        width: 50,
        maxHeight: 50,
        color: '#616B79',
    },
    sectionTitle: {
        fontFamily: theme.typography.semiBold,
        fontSize: 14,
    },
    credentialContainer: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        gap: 10,
    },
    dialogActionContainer: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
    },
    removeButton: {
        borderColor: theme.palette.border.warning,
        color: theme.palette.text.danger,
        width: 'fit-content',
    },
    buttonsContainer: {
        marginTop: 20,
        marginBottom: 20,
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
    },
    titleOrderTypeSection: {
        fontFamily: theme.typography.regular,
        fontWeight: 400,
        fontSize: 14,
        color: theme.palette.text.secondary,
    },
    titleOrderTypeOption: {
        fontFamily: theme.typography.regular,
        fontWeight: 400,
        fontSize: 12,
        color: theme.palette.text.secondary,
        border: `1px solid ${theme.palette.surface.secondary}`,
        backgroundColor: theme.palette.surface.tertiary,
        padding: '6px 12px',
        borderRadius: 4,
    },
    uniqueChannelContainer: {
        marginBottom: 16,
    },
}));

type Props = {
    open: boolean;
    restaurantPaymentMethod?: RestaurantPaymentMethodVm;
    onClose: () => void;
    onSuccess: () => void;
};

type FormType = {
    name: string;
    paymentMethod: PaymentMethod;
    mexicanPaymentMethodCode: MexicanPaymentMethodCode;
    commission?: number;
    restaurantPsp?: Array<RestaurantPsp>;
    restaurantPspsEnabled?: boolean;
    plexoIntegrationEnabled?: boolean;
    paymentReferenceEnabled?: boolean;
    plexoStoreId?: PlexoStoreId;
    mercadoPagoPublicKey?: string;
    mercadoPagoSecretKey?: string;
    channels?: Array<RestaurantPaymentMethodApp>;
    orderTypes?: Array<OrderType>;
};
