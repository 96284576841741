import { createStore } from '@pidedirecto/ui/hooks';
import { RolePermission } from 'src/constants/RolePermission';

export const [useRolePermissionStore, useRolePermissionActions] = createStore<RolePermissionState, RolePermissionActions>({
    initialState: {
        askForRolePermissionAuthorizationDialogState: {
            open: false,
            title: '',
            subtitle: '',
            rolePermission: undefined,
            onAuthorized: () => {},
        },
    },
    actions: {
        openAskForRolePermissionAuthorizationDialog: (state: RolePermissionState, { onAuthorized, onUnauthorized, title, subtitle, rolePermission }) => {
            state.askForRolePermissionAuthorizationDialogState.open = true;
            state.askForRolePermissionAuthorizationDialogState.onAuthorized = onAuthorized;
            state.askForRolePermissionAuthorizationDialogState.onUnauthorized = onUnauthorized;
            state.askForRolePermissionAuthorizationDialogState.title = title;
            state.askForRolePermissionAuthorizationDialogState.subtitle = subtitle ?? '';
            state.askForRolePermissionAuthorizationDialogState.rolePermission = rolePermission;
        },
        closeAskForRolePermissionAuthorizationDialog: (state: RolePermissionState) => {
            state.askForRolePermissionAuthorizationDialogState.open = false;
            state.askForRolePermissionAuthorizationDialogState.onAuthorized = undefined;
            state.askForRolePermissionAuthorizationDialogState.onUnauthorized = undefined;
            state.askForRolePermissionAuthorizationDialogState.title = '';
            state.askForRolePermissionAuthorizationDialogState.subtitle = '';
            state.askForRolePermissionAuthorizationDialogState.rolePermission = undefined;
        },
    },
});

export type RolePermissionState = {
    askForRolePermissionAuthorizationDialogState: {
        open: boolean;
        title: string;
        subtitle: string;
        rolePermission: RolePermission | undefined;
        onAuthorized: (() => void) | undefined;
        onUnauthorized?: (() => void) | undefined;
    };
};

export type RolePermissionActions = {
    openAskForRolePermissionAuthorizationDialog: ({ onAuthorized, onUnauthorized, title, subtitle, rolePermission }: OpenAskForRolePermissionAuthorizationDialogParams) => void;
    closeAskForRolePermissionAuthorizationDialog: () => void;
};

type OpenAskForRolePermissionAuthorizationDialogParams = {
    rolePermission: RolePermission;
    title: string;
    subtitle?: string;
    onAuthorized: () => void;
    onUnauthorized: () => void;
};
