import { letseatmanagerApiMethod } from 'src/api/letseatmanagerApi';
import type { ApiSauceResponse } from 'src/api/types/ApiSauceResponse';
import type { InvoicePeriodicity } from 'src/constants/InvoicePeriodicity';
import type { PaymentMethod } from 'src/constants/PaymentMethod';
import type { OrderId, RestaurantId } from 'src/types/Id';

export async function createMexicanOrdersGlobalInvoiceApi(request: CreateMexicanOrdersGlobalInvoiceApiRequest): ApiSauceResponse<void> {
    return letseatmanagerApiMethod('invoice/createMexicanOrdersGlobalInvoiceApi', request);
}

export type CreateMexicanOrdersGlobalInvoiceApiRequest = {
    restaurantId: RestaurantId;
    orderIds: Array<OrderId>;
    totalToInvoice: string;
    firstOrderAt: Date;
    lastOrderAt: Date;
    periodicity: InvoicePeriodicity;
    paymentMethod: PaymentMethod;
};
