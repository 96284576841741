import { ConfirmDialog, Notifications } from '@pidedirecto/ui';
import * as React from 'react';
import { useEffect } from 'react';
import { Route, Switch, useLocation } from 'react-router-dom';
import { AppRouteOperations } from 'src/AppRoutesOperations';
import { AppRouteSupport } from 'src/AppRoutesSupport';
import { MinimumInventoryReachedAlert } from 'src/components/alertDialog/inventory/MinimumInventoryReachedAlert';
import { ConnectDeviceToWebSocket } from 'src/components/app/ConnectDeviceToWebSocket';
import { FetchKitchenOrdersUpdates } from 'src/components/app/FetchKitchenOrdersUpdates';
import { FetchRealtimeUpdates } from 'src/components/app/FetchRealtimeUpdates';
import { MenuSubscriptionUpdates } from 'src/components/app/MenuSubscriptionUpdates';
import { NewKitchenOrdersSoundPlayer } from 'src/components/app/NewKitchenOrdersSoundPlayer';
import { NewOrdersSoundPlayer } from 'src/components/app/NewOrdersSoundPlayer';
import { PosPaymentsSubscriptionUpdates } from 'src/components/app/PosPaymentsSubscriptionUpdates';
import { PosPaymentsSubscriptionUpdatesDeprecated } from 'src/components/app/PosPaymentsSubscriptionUpdatesDeprecated';
import { PrintAutoAcceptedOrder } from 'src/components/app/PrintAutoAcceptedOrder';
import { SyncOfflineUiLogEvents } from 'src/components/app/SyncOfflineUiLogEvents';
import { CustomAlertDialogHandler } from 'src/components/dialog/CustomAlertDialogHandler';
import { MockPaymentTerminalResponseDialog } from 'src/components/dialog/deprecated/MockPaymentTerminalResponseDialog';
import { IntegrationMenuChangesApprovalDialog } from 'src/components/dialog/menu/IntegrationMenuChangesApprovalDialog';
import { NoInternetConnectionDialog } from 'src/components/dialog/NoInternetConnectionDialog';
import { CreateManualOrderDeliveryDialog } from 'src/components/dialog/order/CreateManualOrderDeliveryDialog';
import RejectOrderDialog from 'src/components/dialog/order/RejectOrderDialog';
import { PinCodeUserSignInDialog } from 'src/components/dialog/PinCodeUserSignInDialog';
import { CustomerDisplayScreenOrderUpdater } from 'src/components/pos/CustomerDisplayScreenOrderUpdater';
import { PosBusinessDaySubscriptionUpdates } from 'src/components/pos/posPayment/PosBusinessDaySubscriptionUpdates';
import { history } from 'src/config/history';
import { PublicRoutePaths } from 'src/constants/PublicRoutePath';
import { RoutePaths } from 'src/constants/RoutePath';
import { AskForRolePermissionAuthorizationDialog } from 'src/modules/order/components/AskForRolePermissionAuthorizationDialog';
import { AppRouteAdmin } from 'src/routers/AppRoutesAdmin';
import { RestaurantManualEarningsPage } from 'src/scenes/letseatadmin/RestaurantManualEarningsPage';
import { TheRestaurantPage as TheRestaurantAdminPage } from 'src/scenes/letseatadmin/TheRestaurantPage';
import { DeliveryItemsPage } from 'src/scenes/letseatdriver/DeliveryItemsPage';
import { AdvancedReportsPage } from 'src/scenes/letseatmanager/AdvancedReportsPage';
import { ChangeBuyOrderPage } from 'src/scenes/letseatmanager/ChangeBuyOrderPage';
import { ChangeUserRolePage } from 'src/scenes/letseatmanager/ChangeUserRolePage';
import { CreateBuyOrderPage } from 'src/scenes/letseatmanager/CreateBuyOrderPage';
import { CreateSupplyMassiveProductionPage } from 'src/scenes/letseatmanager/CreateSupplyMassiveProductionPage';
import { CreateUserRolePage } from 'src/scenes/letseatmanager/CreateUserRolePage';
import { CurrentOrdersDeprecated } from 'src/scenes/letseatmanager/currentOrders/CurrentOrdersDeprecated';
import { CustomerPage } from 'src/scenes/letseatmanager/CustomerPage';
import { CustomerReturnsPage } from 'src/scenes/letseatmanager/CustomerReturnsPage';
import { CustomersPage } from 'src/scenes/letseatmanager/CustomersPage';
import { CustomizePrintsPage } from 'src/scenes/letseatmanager/CustomizePrintsPage';
import { DeliveryZonesPage } from 'src/scenes/letseatmanager/DeliveryZonesPage';
import { ChangeMenuCategoryDialog } from 'src/scenes/letseatmanager/deprecatedMenu/ChangeMenuCategoryDialog';
import { ChangeMenuDialog } from 'src/scenes/letseatmanager/deprecatedMenu/ChangeMenuDialog';
import { ChangeMenuItemDialog } from 'src/scenes/letseatmanager/deprecatedMenu/ChangeMenuItemDialog';
import { CreateMenuCategoryDialog } from 'src/scenes/letseatmanager/deprecatedMenu/CreateMenuCategoryDialog';
import { CreateMenuDialog } from 'src/scenes/letseatmanager/deprecatedMenu/CreateMenuDialog';
import { CreateMenuItemDialog } from 'src/scenes/letseatmanager/deprecatedMenu/CreateMenuItemDialog';
import { HideMenuItemDialog } from 'src/scenes/letseatmanager/deprecatedMenu/HideMenuItemDialog';
import { MoveMenuItemToMenuDialog } from 'src/scenes/letseatmanager/deprecatedMenu/MoveMenuItemToMenuDialog';
import { RemoveMenuCategoryDialog } from 'src/scenes/letseatmanager/deprecatedMenu/RemoveMenuCategoryDialog';
import { RemoveMenuDialog } from 'src/scenes/letseatmanager/deprecatedMenu/RemoveMenuDialog';
import { RemoveMenuItemDialog } from 'src/scenes/letseatmanager/deprecatedMenu/RemoveMenuItemDialog';
import { DevicesPage } from 'src/scenes/letseatmanager/DevicesPage';
import { FreeSubscriptionPlanEndPage } from 'src/scenes/letseatmanager/FreeSubscriptionPlanEndPage';
import { GlobalOrderInvoicesPage } from 'src/scenes/letseatmanager/GlobalOrderInvoicesPage';
import { HowDoesItWorkPage } from 'src/scenes/letseatmanager/HowDoesItWorkPage';
import { IntegrationsPage } from 'src/scenes/letseatmanager/IntegrationsPage';
import { InventoryBuyOrdersPage } from 'src/scenes/letseatmanager/InventoryBuyOrdersPage';
import { InventoryConsumptionsReport } from 'src/scenes/letseatmanager/inventoryConsumptionsReport/InventoryConsumptionsReport';
import { InventoryKardexReport } from 'src/scenes/letseatmanager/inventoryKardexReport/InventoryKardexReport';
import { InventoryPage } from 'src/scenes/letseatmanager/InventoryPage';
import { InventoryProductionPage } from 'src/scenes/letseatmanager/InventoryProductionPage';
import { InventoryRecipesPage } from 'src/scenes/letseatmanager/InventoryRecipesPage';
import { InventoryReportPage } from 'src/scenes/letseatmanager/InventoryReportPage';
import { InventorySuppliersPage } from 'src/scenes/letseatmanager/InventorySuppliersPage';
import { InventorySuppliersReportPage } from 'src/scenes/letseatmanager/InventorySuppliersReportPage';
import { InventorySuppliesPage } from 'src/scenes/letseatmanager/InventorySuppliesPage';
import { InventorySupplyHistoryReport } from 'src/scenes/letseatmanager/inventorySupplyHistoryReport/InventorySupplyHistoryReport';
import { KitchenDisplayScreenPage } from 'src/scenes/letseatmanager/KitchenDisplayScreenPage';
import { MarketingPage } from 'src/scenes/letseatmanager/MarketingPage';
import { OpeningHoursPage } from 'src/scenes/letseatmanager/OpeningHoursPage';
import { OrderInvoicesPage } from 'src/scenes/letseatmanager/OrderInvoicesPage';
import { OwnFleetDriversPage } from 'src/scenes/letseatmanager/OwnFleetDriversPage';
import { OwnFleetDriversReportPage } from 'src/scenes/letseatmanager/OwnFleetDriversReportPage';
import { PaymentLinksPage } from 'src/scenes/letseatmanager/paymentLinks/PaymentLinksPage';
import { SelectPaymentTerminalPaymentResultMockDialog } from 'src/scenes/letseatmanager/paymentTerminalPayment/SelectPaymentTerminalPaymentResultMockDialog';
import { PinCodeUserResumeDayPage } from 'src/scenes/letseatmanager/pos/employeeDayShift/PinCodeUserResumeDayPage';
import { SelectOpenedCashRegisterDialog } from 'src/scenes/letseatmanager/pos/SelectOpenedCashRegisterDialog';
import { VerifyPosPasswordDialog } from 'src/scenes/letseatmanager/pos/VerifyPosPasswordDialog';
import { VerifyPosPinCodeUserDialog } from 'src/scenes/letseatmanager/pos/VerifyPosPinCodeUserDialog';
import { PosBusinessDayReportPage } from 'src/scenes/letseatmanager/PosBusinessDayReportPage';
import { OpenCashRegisterDialog } from 'src/scenes/letseatmanager/posCashRegister/OpenCashRegisterDialog';
import { OpenPosBusinessDayDialog } from 'src/scenes/letseatmanager/posCashRegister/OpenPosBusinessDayDialog';
import { PosCashRegistersPage } from 'src/scenes/letseatmanager/PosCashRegistersPage';
import { PosPage } from 'src/scenes/letseatmanager/PosPage';
import { PosEmployeeLogEventsPage } from 'src/scenes/letseatmanager/posReports/PosEmployeeLogEventsPage';
import { PosRestaurantZonesPage } from 'src/scenes/letseatmanager/PosRestaurantZonesPage';
import { PosUserLockscreenPage } from 'src/scenes/letseatmanager/PosUserLockscreenPage';
import { PromoCodesPage } from 'src/scenes/letseatmanager/PromoCodesPage';
import { PromotionsPage } from 'src/scenes/letseatmanager/PromotionsPage';
import { RestaurantGeneralReportPage } from 'src/scenes/letseatmanager/RestaurantGeneralReportPage';
import { RestaurantPosReportPage } from 'src/scenes/letseatmanager/RestaurantPosReportPage';
import { RestaurantSalesReportPage } from 'src/scenes/letseatmanager/RestaurantSalesReportPage';
import { RestaurantTaxesPage } from 'src/scenes/letseatmanager/RestaurantTaxesPage';
import { SelectBuyOrderCreationMethodPage } from 'src/scenes/letseatmanager/SelectBuyOrderCreationMethodPage';
import { SubscriptionPage } from 'src/scenes/letseatmanager/SubscriptionPage';
import { SurveysPage } from 'src/scenes/letseatmanager/SurveysPage';
import { TemporalMenuPage } from 'src/scenes/letseatmanager/TemporalMenuPage';
import { TemporalOrdersPage } from 'src/scenes/letseatmanager/TemporalOrdersPage';
import { TemporalPrintersPage } from 'src/scenes/letseatmanager/TemporalPrintersPage';
import { TheRestaurantPage } from 'src/scenes/letseatmanager/TheRestaurantPage';
import { UploadBuyOrderPage } from 'src/scenes/letseatmanager/UploadBuyOrderPage';
import { UserManagementPage } from 'src/scenes/letseatmanager/UserManagementPage';
import { useRemotePrinter } from 'src/utils/react/useRemotePrinter';
import { useSelector } from 'src/utils/react/useSelector';
import { useZendeskChat } from 'src/utils/react/useZendeskChat';

export function AppRoutes(): React.ReactElement {
    const location = useLocation();

    useZendeskChat();
    useRemotePrinter();

    const restaurantId = useSelector((state) => state.app.restaurantId);
    const adminUser = useSelector((state) => state.authentication.adminUser);
    const viewUser = useSelector((state) => state.authentication.viewUser);
    const supportUser = useSelector((state) => state.authentication.supportUser);
    const operationsUser = useSelector((state) => state.authentication.operationsUser);
    const kitchensUser = useSelector((state) => state.authentication.kitchensUser);

    useEffect(() => {
        if (location.pathname === PublicRoutePaths.RESTAURANT_SELF_REGISTRATION) {
            history.push({
                pathname: RoutePaths.ONGOING_ORDERS,
                search: history.location.search,
            });
        }
    }, [location.pathname]);

    return (
        <>
            <PrintAutoAcceptedOrder />
            <FetchRealtimeUpdates />
            <MenuSubscriptionUpdates />
            <PosBusinessDaySubscriptionUpdates />
            <NewOrdersSoundPlayer />
            <PosPaymentsSubscriptionUpdates />
            <PosPaymentsSubscriptionUpdatesDeprecated />
            <CustomerDisplayScreenOrderUpdater />
            <NewKitchenOrdersSoundPlayer />
            <ConnectDeviceToWebSocket />
            <FetchKitchenOrdersUpdates />
            <SyncOfflineUiLogEvents />
            <RejectOrderDialog />
            <MinimumInventoryReachedAlert />
            <IntegrationMenuChangesApprovalDialog />
            <Notifications />
            <ConfirmDialog />
            {restaurantId && (
                <>
                    <CustomAlertDialogHandler />
                    <CreateMenuDialog />
                    <ChangeMenuDialog />
                    <RemoveMenuDialog />
                    <CreateMenuCategoryDialog />
                    <ChangeMenuCategoryDialog />
                    <RemoveMenuCategoryDialog />
                    <CreateMenuItemDialog />
                    <ChangeMenuItemDialog />
                    <RemoveMenuItemDialog />
                    <HideMenuItemDialog />
                    <MoveMenuItemToMenuDialog />
                    <CreateManualOrderDeliveryDialog />
                    <NoInternetConnectionDialog />
                    <VerifyPosPasswordDialog />
                    <VerifyPosPinCodeUserDialog />
                    <PinCodeUserSignInDialog />
                    <OpenPosBusinessDayDialog />
                    <SelectOpenedCashRegisterDialog />
                    <OpenCashRegisterDialog />
                    <AskForRolePermissionAuthorizationDialog />
                    <MockPaymentTerminalResponseDialog />
                    <SelectPaymentTerminalPaymentResultMockDialog />
                    <Route path={RoutePaths.FREE_SUBSCRIPTION_PLAN_END} component={FreeSubscriptionPlanEndPage} />
                    <Route path={RoutePaths.ONGOING_ORDERS} component={CurrentOrdersDeprecated} />
                    <Route path={RoutePaths.RESTAURANT_GENERAL_REPORT} component={RestaurantGeneralReportPage} />
                    <Route path={RoutePaths.RESTAURANT_SALES_REPORT} component={RestaurantSalesReportPage} />
                    <Route path={RoutePaths.RESTAURANT_POS_REPORT} component={RestaurantPosReportPage} />
                    <Route path={RoutePaths.ORDERS} component={TemporalOrdersPage} />
                    <Route path={RoutePaths.CUSTOMERS} component={CustomerPage} />
                    <Route path={RoutePaths.SURVEYS} component={SurveysPage} />
                    <Route path={RoutePaths.PROMO_CODES} component={PromoCodesPage} />
                    <Route path={RoutePaths.THE_RESTAURANT} component={adminUser || supportUser || operationsUser || viewUser ? TheRestaurantAdminPage : TheRestaurantPage} />
                    <Route path={RoutePaths.CUSTOMER_RETURNS} component={CustomerReturnsPage} />
                    <Route path={RoutePaths.THE_MENU} component={TemporalMenuPage} />
                    <Route path={RoutePaths.ADMIN_RESTAURANT_MANUAL_EARNING} component={RestaurantManualEarningsPage} />
                    <Route path={RoutePaths.SUBSCRIPTION} component={SubscriptionPage} />
                    <Route path={RoutePaths.PRINTERS} component={TemporalPrintersPage} />
                    <Route path={RoutePaths.DEVICES} component={DevicesPage} />
                    <Route path={RoutePaths.DELIVERY_ZONES} component={DeliveryZonesPage} />
                    <Route path={RoutePaths.HOW_DOES_IT_WORK} component={HowDoesItWorkPage} />
                    <Route path={RoutePaths.INVENTORY} component={InventoryPage} />
                    <Route path={RoutePaths.INVENTORY_SUPPLIER} component={InventorySuppliersPage} />
                    <Route path={RoutePaths.INVENTORY_SUPPLY} component={InventorySuppliesPage} />
                    <Route path={RoutePaths.INVENTORY_RECIPE} component={InventoryRecipesPage} />
                    <Route path={RoutePaths.INVENTORY_BUY_ORDER} component={InventoryBuyOrdersPage} />
                    <Route path={RoutePaths.INVENTORY_CREATE_BUY_ORDER} component={CreateBuyOrderPage} />
                    <Route path={RoutePaths.INVENTORY_CHANGE_BUY_ORDER} component={ChangeBuyOrderPage} />
                    <Route path={RoutePaths.INVENTORY_CREATE_SUPPLY_MASSIVE_PRODUCTION} component={CreateSupplyMassiveProductionPage} />
                    <Route path={RoutePaths.BUY_ORDER_REGISTER_METHOD} component={SelectBuyOrderCreationMethodPage} />
                    <Route path={RoutePaths.INVENTORY_PRODUCTION} component={InventoryProductionPage} />
                    <Route path={RoutePaths.USER_MANAGEMENT} component={UserManagementPage} />
                    <Route path={RoutePaths.KARDEX_REPORT} component={InventoryKardexReport} />
                    <Route path={RoutePaths.INVENTORY_CONSUMPTION_REPORT} component={InventoryConsumptionsReport} />
                    <Route path={RoutePaths.INVENTORY_REPORT} component={InventoryReportPage} />
                    <Route path={RoutePaths.OWN_FLEET_DRIVERS_REPORT} component={OwnFleetDriversReportPage} />

                    <Route path={RoutePaths.CUSTOMIZE_PRINTS} component={CustomizePrintsPage} />
                    <Switch>
                        <Route path={RoutePaths.CHANGE_USER_ROLE} component={ChangeUserRolePage} />
                        <Route exact path={RoutePaths.CREATE_USER_ROLE} component={CreateUserRolePage} />
                    </Switch>
                    <Route path={RoutePaths.INTEGRATIONS} component={IntegrationsPage} />
                    <Route path={RoutePaths.TAXES} component={RestaurantTaxesPage} />
                    <Route path={RoutePaths.PAYMENT_LINKS} component={PaymentLinksPage} />
                    <Route path={RoutePaths.MARKETING} component={MarketingPage} />
                    <Route path={RoutePaths.MARKETING_CUSTOMERS} component={CustomersPage} />
                    <Route path={RoutePaths.POS} exact component={PosPage} />
                    <Route path={RoutePaths.PIN_CODE_USER_LOGIN} exact component={PosUserLockscreenPage} />
                    <Route path={RoutePaths.EMPLOYEE_RESUME_DAY} exact component={PinCodeUserResumeDayPage} />
                    <Route path={RoutePaths.POS_EMPLOYEE_EVENTS} component={PosEmployeeLogEventsPage} />
                    <Route path={RoutePaths.POS_CASH_REGISTERS} component={PosCashRegistersPage} />
                    <Route path={RoutePaths.POS_TABLES} component={PosRestaurantZonesPage} />
                    <Route path={RoutePaths.POS_BUSINESS_DAY} component={PosBusinessDayReportPage} />
                    <Route path={RoutePaths.ORDER_INVOICES} component={OrderInvoicesPage} />
                    <Route path={RoutePaths.GLOBAL_ORDER_INVOICES} component={GlobalOrderInvoicesPage} />
                    <Route path={RoutePaths.OWN_FLEET_DRIVERS} component={OwnFleetDriversPage} />
                    <Route path={RoutePaths.ADVANCED_REPORTS} component={AdvancedReportsPage} />
                    <Route path={RoutePaths.KITCHEN_DISPLAY_SCREEN} component={KitchenDisplayScreenPage} />
                    <Route path={RoutePaths.UPLOAD_BUY_ORDER} component={UploadBuyOrderPage} />
                    <Route path={RoutePaths.OPENING_HOURS} component={OpeningHoursPage} />
                    <Route path={RoutePaths.SUPPLY_HISTORY_REPORT} component={InventorySupplyHistoryReport} />
                    <Route path={RoutePaths.INVENTORY_SUPPLIERS_REPORT} component={InventorySuppliersReportPage} />
                    <Route path={RoutePaths.PROMOTIONS} component={PromotionsPage} />
                </>
            )}
            {(adminUser || supportUser || operationsUser || viewUser || kitchensUser) && <Route path={RoutePaths.DRIVER_DELIVERY_ITEMS_PAGE} component={DeliveryItemsPage} />}
            {(adminUser || viewUser) && <AppRouteAdmin />}
            {supportUser && <AppRouteSupport />}
            {operationsUser && <AppRouteOperations />}
        </>
    );
}
