import { makeStyles } from '@material-ui/core';
import * as React from 'react';
import { classNames } from 'src/utils/react/classNames';

/**
 * @callback onClick
 */

/**
 * Renders a chip
 * @param {React.Node} children - Content inside the chip
 * @param {onClick} [onClick] - Function to be called when the chip is clicked
 * @param {boolean} [disabled] - Disables chip
 * @param {Object} [classes] - Classes object to override chip style
 * @param {string} [classes.chip] - Class to override the chip style
 * @returns {JSX.Element}
 * @constructor
 */
export function Chip({ children, onClick, disabled, classes: classesProp, variant }: Props): React.ReactElement {
    const classes = useStyles();

    const handleClick = (e: any) => {
        if (disabled) return;
        onClick?.(e);
    };

    const getChipClasses = (variant: undefined | 'ERROR' | 'PENDING' | 'SUCCESS' | 'WARNING') => {
        let chipClasses = classNames(classes.baseChip, classesProp?.chip ? classesProp.chip : classes.chip);

        if (variant === AvailableStates.ERROR) chipClasses = classNames(chipClasses, classes.errorChip);
        if (variant === AvailableStates.WARNING) chipClasses = classNames(chipClasses, classes.warningChip);
        if (variant === AvailableStates.SUCCESS) chipClasses = classNames(chipClasses, classes.successChip);
        if (variant === AvailableStates.PENDING) chipClasses = classNames(chipClasses, classes.pendingChip);

        return chipClasses;
    };

    return (
        <div className={classNames(getChipClasses(variant), { [classes.chipDisabled]: !!disabled, [classes.clickableChip]: !!onClick })} onClick={handleClick}>
            {children}
        </div>
    );
}

const AvailableStates = {
    SUCCESS: 'SUCCESS',
    ERROR: 'ERROR',
    WARNING: 'WARNING',
    PENDING: 'PENDING',
} as const;

const useStyles = makeStyles((theme) => ({
    baseChip: {
        borderRadius: 8,
        height: 20,
        padding: '2px 8px',
        fontFamily: theme.typography.medium,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        fontSize: 10,
        width: 'fit-content',
    },
    chip: {
        backgroundColor: theme.palette.surface.brandContrast,
        color: theme.palette.text.invert,
    },
    warningChip: {
        backgroundColor: theme.palette.surface.warning,
        color: theme.palette.text.warning,
    },
    errorChip: {
        backgroundColor: theme.palette.surface.danger,
        color: theme.palette.text.danger,
    },
    pendingChip: {
        backgroundColor: theme.palette.surface.info,
        color: theme.palette.text.info,
    },
    successChip: {
        backgroundColor: theme.palette.surface.success,
        color: theme.palette.text.success,
    },
    clickableChip: {
        cursor: 'pointer',
    },
    chipDisabled: {
        backgroundColor: theme.palette.surface.secondary,
        color: theme.palette.text.contrast,
    },
}));

type Props = {
    disabled?: boolean;
    children: React.ReactNode;
    onClick?: any;
    classes?: {
        chip: string;
    };
    variant?: 'ERROR' | 'WARNING' | 'SUCCESS' | 'PENDING';
};
