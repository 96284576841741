export const Banks = {
    'BANAMEX': 'BANAMEX',
    'BANCOMEXT': 'BANCOMEXT',
    'BANOBRAS': 'BANOBRAS',
    'BBVA BANCOMER': 'BBVA BANCOMER',
    'SANTANDER': 'SANTANDER',
    'BANJERCITO': 'BANJERCITO',
    'HSBC': 'HSBC',
    'BAJIO': 'BAJIO',
    'IXE': 'IXE',
    'INBURSA': 'INBURSA',
    'INTERACCIONES': 'INTERACCIONES',
    'MIFEL': 'MIFEL',
    'SCOTIABANK': 'SCOTIABANK',
    'BANREGIO': 'BANREGIO',
    'INVEX': 'INVEX',
    'BANSI': 'BANSI',
    'AFIRME': 'AFIRME',
    'BANORTE': 'BANORTE',
    'THE ROYAL BANK': 'THE ROYAL BANK',
    'AMERICAN EXPRESS': 'AMERICAN EXPRESS',
    'BAMSA': 'BAMSA',
    'TOKYO': 'TOKYO',
    'JP MORGAN': 'JP MORGAN',
    'BMONEX': 'BMONEX',
    'VE POR MAS': 'VE POR MAS',
    'ING': 'ING',
    'DEUTSCHE': 'DEUTSCHE',
    'CREDIT SUISSE': 'CREDIT SUISSE',
    'AUTOFIN': 'AUTOFIN',
    'BARCLAYS': 'BARCLAYS',
    'COMPARTAMOS': 'COMPARTAMOS',
    'BANCO FAMSA': 'BANCO FAMSA',
    'BMULTIVA': 'BMULTIVA',
    'ACTINVER': 'ACTINVER',
    'WAL-MART': 'WAL-MART',
    'NAFIN': 'NAFIN',
    'INTERBANCO': 'INTERBANCO',
    'BANCOPPEL': 'BANCOPPEL',
    'ABC CAPITAL': 'ABC CAPITAL',
    'UBS BANK': 'UBS BANK',
    'CONSUBANCO': 'CONSUBANCO',
    'VOLKSWAGEN': 'VOLKSWAGEN',
    'CIBANCO': 'CIBANCO',
    'BBASE': 'BBASE',
    'BANSEFI': 'BANSEFI',
    'HIPOTECARIA FEDERAL': 'HIPOTECARIA FEDERAL',
    'MONEXCB': 'MONEXCB',
    'GBM': 'GBM',
    'MASARI': 'MASARI',
    'VALUE': 'VALUE',
    'ESTRUCTURADORES': 'ESTRUCTURADORES',
    'TIBER': 'TIBER',
    'VECTOR': 'VECTOR',
    'B&B': 'B&B',
    'ACCIVAL': 'ACCIVAL',
    'MERRILL LYNCH': 'MERRILL LYNCH',
    'FINAMEX': 'FINAMEX',
    'VALMEX': 'VALMEX',
    'UNICA': 'UNICA',
    'MAPFRE': 'MAPFRE',
    'PROFUTURO': 'PROFUTURO',
    'CB ACTINVER': 'CB ACTINVER',
    'OACTIN': 'OACTIN',
    'SKANDIA VIDA': 'SKANDIA VIDA',
    'CBDEUTSCHE': 'CBDEUTSCHE',
    'ZURICH': 'ZURICH',
    'ZURICHVI': 'ZURICHVI',
    'SU CASITA': 'SU CASITA',
    'CB INTERCAM': 'CB INTERCAM',
    'CI BOLSA': 'CI BOLSA',
    'BULLTICK CB': 'BULLTICK CB',
    'STERLING': 'STERLING',
    'FINCOMUN': 'FINCOMUN',
    'HDI SEGUROS': 'HDI SEGUROS',
    'ORDER': 'ORDER',
    'AKALA': 'AKALA',
    'CB JPMORGAN': 'CB JPMORGAN',
    'REFORMA': 'REFORMA',
    'STP': 'STP',
    'TELECOMM': 'TELECOMM',
    'EVERCORE': 'EVERCORE',
    'SKANDIA OPERADORA DE FONDOS': 'SKANDIA OPERADORA DE FONDOS',
    'SEGMTY': 'SEGMTY',
    'ASEA': 'ASEA',
    'KUSPIT': 'KUSPIT',
    'SOFIEXPRESS': 'SOFIEXPRESS',
    'UNAGRA': 'UNAGRA',
    'OPCIONES EMPRESARIALES DEL NOROESTE': 'OPCIONES EMPRESARIALES DEL NOROESTE',
    'CLS': 'CLS',
    'INDEVAL': 'INDEVAL',
    'LIBERTAD': 'LIBERTAD',
    'MERCADO PAGO': 'MERCADO PAGO',
    'N/A': 'N/A',
} as const;

export type Bank = (typeof Banks)[keyof typeof Banks];
