const UNTRANSFORMED_LETS_EAT_IMAGE = 'https://images.letseat.mx/[0-9a-f]{32}';
export function transformImageUrl(imageUrl: string | null | undefined, width: number, height?: number): string {
    if (!imageUrl || !imageUrl.match(UNTRANSFORMED_LETS_EAT_IMAGE)) {
        return imageUrl as any;
    }
    return `https://images.letseat.mx/${createTransformationHash(imageUrl, width, height)}`;
}

// transform to aws serverless image handler image transfrom request
// see https://docs.aws.amazon.com/solutions/latest/serverless-image-handler/deployment.html#step2
function createTransformationHash(imageUrl: string, width: number, height?: number) {
    if (!width && !height) {
        const imageRequest = JSON.stringify({
            bucket: 'letseat.images',
            key: imageUrl.replace('https://images.letseat.mx/', ''),
        });
        return btoa(imageRequest);
    }
    const imageRequest = JSON.stringify({
        bucket: 'letseat.images',
        key: imageUrl.replace('https://images.letseat.mx/', ''),
        edits: {
            resize: {
                width,
                // height, // this is default in sharp so no need to provide
                fit: 'cover',
                withoutEnlargement: true,
            },
            // jpeg: {
            //     quality: 80, // this is default in sharp so no need to provide
            // },
        },
    });
    return btoa(imageRequest);
}
