import { makeStyles } from '@material-ui/core';
import * as React from 'react';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { changeDidiIntegrationCommissionPercentageApi } from 'src/api/letseatmanager/restaurant/integrations/changeDidiIntegrationCommissionPercentageApi';
import { Button } from 'src/components/Button';
import { Dialog } from 'src/components/Dialog';
import { DialogActions } from 'src/components/DialogActions';
import { Form } from 'src/components/form/Form';
import { FormPercentNumberField } from 'src/components/form/FormPercentNumberField';
import { Apps } from 'src/constants/App';
import { translate } from 'src/i18n/translate';
import didiLogo from 'src/images/didifood-logo.png';
import { useNotification } from 'src/services/notification/useNotification';
import { alertKnownErrorOrSomethingWentWrong } from 'src/utils/alert/alertKnownErrorOrSomethingWentWrong';
import { formatAsPercentage } from 'src/utils/number/formatAsPercentage';
import { useSelector } from 'src/utils/react/useSelector';

export function ChangeDidiIntegrationPercentageDialog({ open, onClose, onSuccess, didiCommissionPercentage }: Props): React.ReactElement {
    const classes = useStyles();
    const form = useForm();
    const notification = useNotification();

    const restaurantId = useSelector((state) => state.app.restaurantId);

    const [loading, setLoading] = useState(false);

    const onSubmit = async (form: any) => {
        setLoading(true);
        const response = await changeDidiIntegrationCommissionPercentageApi({
            restaurantId,
            didiFoodSalesCommissionPercentage: form.percentage,
        });
        if (!response.ok) {
            setLoading(false);
            alertKnownErrorOrSomethingWentWrong(response);
            return;
        }
        setLoading(false);
        notification({ message: translate('The percentage was changed successfully') });
        onSuccess();
    };

    return (
        <Dialog open={open} onClose={onClose} classes={{ dialog: classes.dialog }}>
            <div className={classes.logoContainer}>
                <img src={didiLogo} alt='Didi' />
            </div>
            <h1 className={classes.title}>{translate('Service fee')}</h1>
            <h2 className={classes.subtitle}>
                {translate('Assign the percentage for your sales in @integrationName. It will be reflected in your reports', { integrationName: translate(Apps.DIDI_FOOD) })}
            </h2>

            <Form form={form} onSubmit={onSubmit}>
                <div className={classes.inputContainer}>
                    <FormPercentNumberField name='percentage' placeholder={formatAsPercentage(didiCommissionPercentage)} min={0} max={100} />
                </div>

                <DialogActions className={classes.actions}>
                    <Button type='submit' primary disabled={loading}>
                        {translate('Save')}
                    </Button>
                </DialogActions>
            </Form>
        </Dialog>
    );
}

const useStyles = makeStyles((theme) => ({
    title: {
        color: '#313333',
        textAlign: 'center',
        fontFamily: theme.typography.semiBold,
        fontSize: '18px',
    },
    subtitle: {
        color: '#313333',
        textAlign: 'center',
        fontFamily: theme.typography.light,
        fontSize: '14px',
    },
    inputContainer: {
        marginTop: '20px',
        alignSelf: 'center',
        padding: '0 7rem',
    },
    logoContainer: {
        display: 'flex',
        justifyContent: 'center',
        marginTop: '2rem',
        marginBottom: '2rem',
    },
    actions: {
        marginTop: '2rem',
        marginBottom: '2rem',
        justifyContent: 'center',
    },
    dialog: {
        width: '26%',
    },
}));

type Props = {
    open: boolean;
    onClose: any;
    onSuccess: any;
    didiCommissionPercentage?: number;
};
