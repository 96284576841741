import { makeStyles, useTheme } from '@material-ui/core/styles';
import * as React from 'react';
import { memo } from 'react';
import { classNames } from 'src/utils/react/classNames';

export const Text: React.ComponentType<Props> = memo<Props>(function Text({ children, className, title, style, size, variant, error, link, onClick }: Props): React.ReactElement {
    const classes = useStyles();
    const theme = useTheme();

    const getClassNames = () => {
        let textClasses = classNames(classes.text, className);

        if (size === 'small') textClasses = classNames(textClasses, classes.smallText);
        if (size === 'large') textClasses = classNames(textClasses, classes.largeText);

        if (title) textClasses = classNames(textClasses, classes.title);

        if (error) textClasses = classNames(textClasses, classes.errorText);

        if (link && onClick) textClasses = classNames(textClasses, classes.linkText);

        return textClasses;
    };

    return (
        <div
            tabIndex={!!onClick ? ('0' as any) : undefined} // TODO: replace ('0' as any) with 0 to see if it works as intended with tabIndex
            role={onClick ? 'button' : undefined}
            onClick={onClick}
            className={getClassNames()}
            style={{ fontFamily: (title && theme.typography.semiBold) || theme.typography[variant || 'regular'], ...style }}
        >
            {children}
        </div>
    );
});

const useStyles = makeStyles((theme) => ({
    text: {
        fontFamily: theme.typography.regular,
        fontSize: 14,
        color: theme.palette.text.primary,
    },
    title: {
        color: theme.palette.text.primary,
        fontSize: 24,
        [theme.breakpoints.down('sm')]: {
            fontSize: 20,
        },
    },
    smallText: {
        fontSize: 12,
    },
    largeText: {
        fontSize: 16,
    },
    errorText: {
        color: theme.palette.text.danger,
    },
    linkText: {
        textDecoration: 'underline',
        color: theme.palette.text.success,
        '&:hover': {
            cursor: 'grab',
        },
    },
}));

type Props = {
    size?: 'small' | 'large';
    variant?: 'bold' | 'semiBold' | 'medium' | 'light';
    error?: boolean;
    link?: boolean;
    className?: string;
    style?: any;
    children?: React.ReactNode;
    title?: boolean;
    onClick?: any;
};
