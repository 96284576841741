export function createFilteredOnDownloadFunctional(ref: { current: any }): any {
    return (buildHead: any, buildBody: any, columns: any) => {
        if (!ref?.current) {
            return;
        }
        if (!ref.current.state.selectedRows.data.length) {
            return '\uFEFF' + buildHead(columns) + buildBody(ref.current.state.displayData);
        }
        const selectedRowsDataIndexes = ref.current.state.selectedRows.data.map((d: any) => d.dataIndex);
        const selectedData = ref.current.state.displayData.filter((d: any) => selectedRowsDataIndexes.includes(d.dataIndex));
        return '\uFEFF' + buildHead(columns) + buildBody(selectedData);
    };
}
