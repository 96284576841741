import { letseatadminApiMethod } from 'src/api/letseatadminApi';
import type { ApiSauceResponse } from 'src/api/types/ApiSauceResponse';
import type { Bank } from 'src/constants/Bank';
import type { City } from 'src/constants/City';
import type { GeoJsonPoint } from 'src/types/GeoJsonPoint';
import type { DriverId, EmailAddress, ExternalDeviceId } from 'src/types/Id';

export async function findDriversApi(request: FindDriversApiRequest): ApiSauceResponse<FindDriversApiResponse> {
    return letseatadminApiMethod('driver/findDriversApi', request);
}

export type FindDriversApiRequest = {
    query?: string;
    cities?: Array<City>;
    batch: number;
    size: number;
};

export type FindDriversApiResponse = {
    drivers: Array<DriverVm>;
};

export type DriverVm = {
    driverId: DriverId;
    signedIn: boolean;
    online: boolean;
    delivered: number;
    cancelled: number;
    mobileNumber?: string;
    email?: EmailAddress;
    firstName?: string;
    lastName?: string;
    businessName?: string;
    claveId?: string;
    curp?: string;
    bank?: Bank;
    clabe?: string;
    rfc?: string;
    position?: GeoJsonPoint;
    positionedAt?: Date;
    city?: City;
    fcmPermission?: boolean;
    locationPermission?: string;
    comments?: string;
    verified?: boolean;
    appPaymentsEnabled?: boolean;
    cashPaymentsEnabled?: boolean;
    prio: number;
    removed?: boolean;
    banned?: boolean;
    bannedDevice?: boolean;
    deviceId?: ExternalDeviceId;
    appVersion?: string;
    exportError?: string;
    downloadedAppAt: Date;
    signedUpAt?: Date;
    firstDeliveryAt?: Date;
    lastDeliveryAt?: Date;
    exportedAt?: Date;
};
