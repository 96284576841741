import { letseatmanagerApiMethod } from 'src/api/letseatmanagerApi';
import type { ApiSauceResponse } from 'src/api/types/ApiSauceResponse';
import type { RestaurantId } from 'src/types/Id';
import type { OrderVm } from 'src/types/OrderVm';

export async function findRestaurantCustomerOrdersApi(request: FindRestaurantCustomerOrdersApiRequest): ApiSauceResponse<FindRestaurantCustomerOrdersApiResponse> {
    return letseatmanagerApiMethod('customerOrder/findRestaurantCustomerOrdersApi', request);
}

export type FindRestaurantCustomerOrdersApiRequest = {
    mobileNumber: string;
    restaurantId: RestaurantId;
};

type FindRestaurantCustomerOrdersApiResponse = {
    orders: Array<OrderVm>;
};
