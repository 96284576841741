import * as React from 'react';
import { useState } from 'react';
import { removeMenuCategoryFromMenuApi } from 'src/api/letseatmanager/menuCategory/removeMenuCategoryFromMenuApi';
import { RemoveDialog } from 'src/components/dialog/RemoveDialog';
import { translate } from 'src/i18n/translate';
import { useMenus } from 'src/services/menu/useMenus';
import { useMenuCategories } from 'src/services/menuCategory/useMenuCategories';
import type { MenuCategoryId, MenuId } from 'src/types/Id';
import { MenuCategoryVm } from 'src/types/MenuCategoryVm';
import { MenuVm } from 'src/types/MenuVm';
import { alertKnownErrorOrSomethingWentWrong } from 'src/utils/alert/alertKnownErrorOrSomethingWentWrong';

export function RemoveMenuCategoryFromMenu({ open, menuId, menuCategoryId, onClose, onSuccess }: Props): React.ReactElement | null {
    const [loading, setLoading] = useState(false);

    const { menus } = useMenus();
    const { menuCategories } = useMenuCategories();

    const menu = menus?.find((menu: MenuVm) => menu.menuId === menuId);
    const menuCategory = menuCategories?.find((menuCategory: MenuCategoryVm) => menuCategory.menuCategoryId === menuCategoryId);

    const handleClose = () => {
        if (loading) return;
        onClose();
    };

    const onSubmit = async () => {
        setLoading(true);
        const response = await removeMenuCategoryFromMenuApi({
            menuId,
            menuCategoryId,
        });
        setLoading(false);
        if (!response.ok) {
            alertKnownErrorOrSomethingWentWrong(response);
            return;
        }
        onSuccess?.();
        handleClose();
    };

    if (!menuCategory || !menu) return null;

    return (
        <RemoveDialog
            open={open}
            title={translate('Remove menu category')}
            subtitle={translate('You are about to remove the category @menuCategoryName from @menuName, are you sure you want to continue?', {
                menuCategoryName: menuCategory?.name,
                menuName: menu?.name,
            })}
            onSubmit={onSubmit}
            onClose={handleClose}
        />
    );
}

type Props = {
    open: boolean;
    menuId: MenuId;
    menuCategoryId: MenuCategoryId;
    onClose: any;
    onSuccess?: any;
};
