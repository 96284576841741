import { letseatmanagerApiMethod } from 'src/api/letseatmanagerApi';
import type { ApiSauceResponse } from 'src/api/types/ApiSauceResponse';
import type { RestaurantId, SubscriptionId } from 'src/types/Id';

export async function addConektaSpeiReferenceApi(request: AddSubscriptionCardApiRequest): ApiSauceResponse<string | undefined> {
    return letseatmanagerApiMethod('subscription/addConektaSpeiReferenceApi', request);
}

export type AddSubscriptionCardApiRequest = {
    subscriptionId: SubscriptionId;
    restaurantId: RestaurantId;
};
