export const PosBusinessDaySections = {
    RESTAURANT_NAME: 'RESTAURANT_NAME',
    BUSINESS_NAME: 'BUSINESS_NAME',
    RFC: 'RFC',
    RESTAURANT_STREET: 'RESTAURANT_STREET',
    DATE_OF_PRINT: 'DATE_OF_PRINT',
    OPENING_DATE: 'OPENING_DATE',
    CLOSING_DATE: 'CLOSING_DATE',
    SALES: 'SALES',
    TIP: 'TIP',
    ORDERS: 'ORDERS',
    DINNERS: 'DINNERS',
    CASH_REGISTER_MOVEMENTS: 'CASH_REGISTER_MOVEMENTS',
    SALES_BY_CHANNEL: 'SALES_BY_CHANNEL',
    SALES_BY_PAYMENT: 'SALES_BY_PAYMENT',
    SALES_BY_ORDER_TYPE: 'SALES_BY_ORDER_TYPE',
    TOP_SALES_PRODUCT: 'TOP_SALES_PRODUCT',
    /** @deprecated */
    TOP_SALES_CATEGORY: 'TOP_SALES_CATEGORY',
    /** @deprecated */
    TOP_SALES_BY_HOUR: 'TOP_SALES_BY_HOUR',
    PROMO_CODES_SUMMARY: 'PROMO_CODES_SUMMARY',
    PAYMENTS_RECEIVED: 'PAYMENTS_RECEIVED',
    CASH_REGISTER_COUNTING: 'CASH_REGISTER_COUNTING',
    SALES_BY_MANAGER_USER: 'SALES_BY_MANAGER_USER',
    CANCELLED_ORDERS: 'CANCELLED_ORDERS',
    CARD_BREAKDOWN: 'CARD_BREAKDOWN',
    INTEGRATION_BREAKDOWN: 'INTEGRATION_BREAKDOWN',
} as const;

export type PosBusinessDaySection = (typeof PosBusinessDaySections)[keyof typeof PosBusinessDaySections];
