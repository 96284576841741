import { letseatmanagerApiMethod } from 'src/api/letseatmanagerApi';
import type { ApiSauceResponse } from 'src/api/types/ApiSauceResponse';
import type { MenuId, RestaurantId } from 'src/types/Id';

export async function changeRestaurantMenuApi(request: ChangeRestaurantMenuApiRequest): ApiSauceResponse<void> {
    return letseatmanagerApiMethod('restaurant/changeRestaurantMenuApi', request);
}

export type ChangeRestaurantMenuApiRequest = {
    restaurantId: RestaurantId;
    menuIds: Array<MenuId>;
};
