import { IconButton, makeStyles } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import * as React from 'react';
import { useState } from 'react';
import { translate } from 'src/i18n/translate';
import { liveDrivers } from 'src/reducers/liveDriversReducer';
import { AssignDriverDialog } from 'src/scenes/letseatadmin/driver/AssignDriverDialog';
import { LiveOrderInfo } from 'src/scenes/letseatadmin/liveOrders/LiveOrderInfo';
import { OrderId } from 'src/types/Id';
import { useAction } from 'src/utils/react/useAction';
import { useSelector } from 'src/utils/react/useSelector';

export function RestaurantInfo(): React.ReactElement | null {
    const styles = useStyles();

    const [assignDriverDialogState, setAssignDriverDialogState] = useState({ open: false });
    const [orderId, setOrderId] = useState<OrderId | undefined>(undefined);

    const showRestaurantInfo = useSelector((state) => state.liveDrivers.showRestaurantInfo);
    const restaurantInfo = useSelector((state) => state.liveDrivers.restaurantInfo);

    const clearRestaurantInfo = useAction(liveDrivers.actions.clearRestaurantInfo);

    const closeAssignDriverDialog = () => setAssignDriverDialogState({ open: false });

    const openAssignDriverDialog = (orderId: OrderId) => {
        setOrderId(orderId);
        setAssignDriverDialogState({ open: true });
    };

    if (!showRestaurantInfo) return null;

    return (
        <div className={styles.container}>
            <IconButton onClick={clearRestaurantInfo} className={styles.closeButton}>
                <CloseIcon className={styles.icon}></CloseIcon>
            </IconButton>
            <div className={styles.restaurantContainer}>
                <h1 className={styles.restaurantName}>{translate('Restaurant: @restaurantName', { restaurantName: restaurantInfo.restaurantName })}</h1>
            </div>
            <p className={styles.text} style={{ marginBottom: 2 }}>
                {translate('Number of orders: @numOrders', { numOrders: restaurantInfo.orders.length })}
            </p>
            <div className={styles.ordersContainer}>
                {restaurantInfo.orders.map((order) => (
                    <LiveOrderInfo order={order} openAssignDriverDialog={openAssignDriverDialog} />
                ))}
            </div>
            <AssignDriverDialog open={assignDriverDialogState.open} onClose={closeAssignDriverDialog} orderId={orderId} />
        </div>
    );
}

const useStyles = makeStyles((theme) => ({
    container: {
        position: 'absolute',
        backgroundColor: 'white',
        borderRadius: 10,
        bottom: '5vh',
        right: '5vw',
        width: '35vw',
        minHeight: '20vh',
        display: 'flex',
        flexDirection: 'column',
        padding: 20,
        paddingTop: 10,
    },
    restaurantContainer: {
        flexDirection: 'row',
        alignItems: 'center',
        width: 'fit-content',
    },
    restaurantName: {
        fontFamily: theme.typography.medium,
        fontSize: 15,
        margin: 0,
    },
    closeButton: {
        position: 'absolute',
        top: 0,
        right: 0,
    },
    text: {
        fontFamily: theme.typography.regular,
        fontSize: 15,
    },
    icon: {
        marginLeft: 10,
        cursor: 'pointer',
    },
    ordersContainer: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'first',
        width: '100%',
    },
}));
