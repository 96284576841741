import { makeStyles } from '@material-ui/core';
import Chip from '@material-ui/core/Chip';
import { DeviceIcon, ScreenIcon } from '@pidedirecto/ui/icons';
import { useState } from 'react';
import * as React from 'react';
import { Button } from 'src/components/Button';
import { translate } from 'src/i18n/translate';
import { BlockIcon } from 'src/icons/BlockIcon';
import { EditIcon } from 'src/icons/EditIcon';
import { BlockDeviceDialog } from 'src/scenes/letseatmanager/device/devices/BlockDeviceDialog';
import { ChangeDeviceDialog } from 'src/scenes/letseatmanager/device/devices/ChangeDeviceDialog';
import { getDeviceId } from 'src/services/device/getDeviceId';
import type { DeviceVm } from 'src/types/DeviceVm';
import { DeviceId } from 'src/types/Id';
import { formatDateTimeString } from 'src/utils/date/formatDateTimeString';
import { isCustomerDisplayScreenDevice } from 'src/utils/device/isCustomerDisplayScreenDevice';
import { translateDeviceBrand } from 'src/utils/translate/translateDeviceBrand';
import { translateDeviceModel } from 'src/utils/translate/translateDeviceModel';
import { translateDeviceOs } from 'src/utils/translate/translateDeviceOs';
import { translateDeviceType } from 'src/utils/translate/translateDeviceType';
import { toShortId } from 'src/utils/uuid/toShortId';

export function DeviceListItem({ device, onChange }: Props): React.ReactElement {
    const classes = useStyles();

    const [changeDeviceDialogState, setChangeDeviceDialogState] = useState<{ open: boolean; deviceId?: DeviceId }>({ open: false, deviceId: undefined });
    const [blockDeviceDialogState, setBlockDeviceDialogState] = useState<{ open: boolean; deviceId?: DeviceId }>({ open: false });

    const openChangeDeviceDialog = () => setChangeDeviceDialogState({ open: true, deviceId: device.deviceId });

    const closeChangeDeviceDialog = () => setChangeDeviceDialogState({ open: false, deviceId: undefined });

    const openBlockDeviceDialog = () => setBlockDeviceDialogState({ open: true, deviceId: device.deviceId });

    const closeBlockDeviceDialog = () => setBlockDeviceDialogState({ open: false, deviceId: undefined });

    return (
        <div className={classes.container}>
            <ChangeDeviceDialog open={changeDeviceDialogState.open} deviceId={changeDeviceDialogState.deviceId} onClose={closeChangeDeviceDialog} onSuccess={onChange} />
            <BlockDeviceDialog open={blockDeviceDialogState.open} device={device} onClose={closeBlockDeviceDialog} onSuccess={onChange} />
            <div className={classes.deviceInfo}>
                <div className={classes.deviceNameContainer}>
                    {isCustomerDisplayScreenDevice(device.deviceType) ? <ScreenIcon /> : <DeviceIcon />}
                    <span className={classes.deviceName}>{device.deviceName || toShortId(device.deviceId)}</span>
                    {getDeviceId() === device.deviceId ? (
                        <Chip label={translate('This device')} color='primary' size='small' />
                    ) : (
                        <>
                            <span className={classes.deviceLabel}>{translateDeviceOs(device.deviceOs)}</span>
                            {device.deviceBrand && <span className={classes.deviceLabel}>{translateDeviceBrand(device.deviceBrand)}</span>}
                            <span className={classes.deviceLabel}>{translateDeviceModel(device.deviceModel) || translateDeviceType(device.deviceType)}</span>
                        </>
                    )}
                </div>
                <span className={classes.lastConnection}>{translate('Last connection @date', { date: formatDateTimeString(device.onlineAt) })}</span>
            </div>
            <div className={classes.actions}>
                <Button icon classes={{ button: classes.button }} onClick={openChangeDeviceDialog}>
                    <EditIcon title={translate('Change name')} />
                </Button>
                <Button icon onClick={openBlockDeviceDialog}>
                    <BlockIcon title={translate('Block device')} />
                </Button>
            </div>
        </div>
    );
}

const useStyles = makeStyles((theme) => ({
    container: {
        display: 'flex',
        flexDirection: 'row',
        width: '100%',
        alignItems: 'center',
        justifyContent: 'space-between',
    },
    deviceInfo: {
        display: 'flex',
        flexDirection: 'column',
        gap: 10,
    },
    deviceName: {
        fontFamily: theme.typography.regular,
        color: theme.palette.text.primary,
        fontSize: 16,
    },
    deviceLabel: {
        fontFamily: theme.typography.regular,
        color: theme.palette.text.contrast,
        fontSize: 12,
    },
    lastConnection: {
        color: theme.palette.text.contrast,
        fontFamily: theme.typography.regular,
        fontSize: 12,
    },
    actions: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        gap: 10,
    },
    button: {
        color: '#616B79',
    },
    deviceNameContainer: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        gap: 10,
    },
}));

type Props = {
    device: DeviceVm;
    onChange?: any;
};
