import { BigNumber } from 'bignumber.js';
import { ModifierVm, type ModifierGroupVm } from 'src/types/CartItemVm';

export function mapFreeModifiersByModifierGroups(modifierGroup: ModifierGroupVm): Array<FreeModifier> {
    if (!modifierGroup.freeModifiersQuantity) return modifierGroup.modifiers;
    let freeModifiersQuantity = BigNumber(modifierGroup.freeModifiersQuantity ?? 0).toNumber();

    const sortedModifiers = modifierGroup.modifiers.filter((modifier) => modifier.quantity > 0).sort((previousModifier, nextModifier) => Number(previousModifier.price) - Number(nextModifier.price));

    const updatedModifiers: Array<FreeModifier> = sortedModifiers.map((modifier) => {
        const freeQuantity = Math.min(modifier.quantity, freeModifiersQuantity!);
        freeModifiersQuantity = BigNumber(freeModifiersQuantity!).minus(freeQuantity).toNumber();

        return {
            ...modifier,
            nonFreeQuantity: BigNumber(modifier.quantity!).minus(freeQuantity).toNumber(),
        };
    });

    return updatedModifiers;
}

export type FreeModifier = {
    nonFreeQuantity?: number;
} & ModifierVm;
