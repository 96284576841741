import { makeStyles } from '@material-ui/core';
import { PlusIcon } from '@pidedirecto/ui/icons';
import * as React from 'react';
import { memo } from 'react';
import { app2 } from 'src/app2';
import { Button } from 'src/components/Button';
import { MenuItemType, MenuItemTypes } from 'src/constants/DeprecatedMenuItemType';
import { translate } from 'src/i18n/translate';
import type { MenuCategoryId } from 'src/types/Id';
import { useAction } from 'src/utils/react/useAction';

export const AddMenuItem: React.ComponentType<Props> = memo<Props>(function AddMenuItem({ menuCategoryId, loading, onSuccess, menuItemType }: Props): React.ReactElement {
    const classes = useStyles();
    const openCreateMenuItemDialog = useAction(app2.actions.openCreateMenuItemDialog);

    return (
        <div className={classes.container}>
            <h2 className={classes.title}>{translate(menuItemType === MenuItemTypes.MODIFIER ? 'No modifiers items' : 'No menu items')}</h2>
            <Button
                onClick={(event: any) => {
                    if (loading) {
                        return;
                    }
                    openCreateMenuItemDialog({ menuCategoryId, position: 1, onSuccess, menuItemType });
                    event.stopPropagation();
                }}
            >
                <PlusIcon /> {translate(menuItemType === MenuItemTypes.MODIFIER ? 'Add Modifier Item' : 'Add Menu Item')}
            </Button>
        </div>
    );
});

const useStyles = makeStyles((theme) => ({
    container: {
        width: '100%',
        height: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
        [theme.breakpoints.down('sm')]: {
            height: '50vh',
        },
    },
    title: {
        fontFamily: theme.typography.medium,
        fontStyle: 'italic',
        fontSize: 40,
        marginBottom: 40,
        [theme.breakpoints.down('sm')]: {
            fontSize: 25,
            marginBottom: 20,
        },
    },
    button: {
        fontSize: 18,
        borderRadius: 5,
        padding: '10px 15px',
    },
}));

export type Props = {
    menuCategoryId: MenuCategoryId;
    menuItemType: MenuItemType;
    loading: boolean;
    onSuccess: any;
};
