import { letseatadminApiMethod } from 'src/api/letseatadminApi';
import type { ApiSauceResponse } from 'src/api/types/ApiSauceResponse';
import type { DriverManualEarningId } from 'src/types/Id';

export async function removeDriverManualEarningApi(request: RemoveDriverManualEarningApiRequest): ApiSauceResponse<void> {
    return letseatadminApiMethod('driverManualEarning/removeDriverManualEarningApi', request);
}

export type RemoveDriverManualEarningApiRequest = {
    driverManualEarningId: DriverManualEarningId;
};
