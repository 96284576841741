import { makeStyles } from '@material-ui/core/styles';
import * as React from 'react';
import { translate } from 'src/i18n/translate';
import { UserFilledIcon } from 'src/icons/UserFilledIcon';
import { useFormatAsRestaurantCurrencyNumber } from 'src/services/restaurant/useFormatAsRestaurantCurrencyNumber';
import type { CashRegisterPosBusinessDaySummaryReportVm } from 'src/types/CashRegisterPosBusinessDaySummaryReportVm';

export function CashRegisterPosBusinessDayReportCard({ cashRegisterPosBusinessDayReportSummary }: Props): React.ReactElement | null {
    const classes = useStyles();
    const formatAsCurrencyNumber = useFormatAsRestaurantCurrencyNumber();

    if (!cashRegisterPosBusinessDayReportSummary) return null;

    return (
        <div className={classes.cardContainer}>
            <h1 className={classes.cashRegisterName}>
                {translate('Cash register @cashRegisterNumber', { cashRegisterNumber: cashRegisterPosBusinessDayReportSummary?.cashRegisterPosBusinessDayNumber || '' })}
            </h1>
            <div className={classes.contentText}>
                <UserFilledIcon height={18} width={18} />
                <div className={classes.subText}>{cashRegisterPosBusinessDayReportSummary?.openedBy}</div>
            </div>
            <div className={classes.separatedText}>
                <div>{translate('Deposits')}</div>
                <div className={classes.summaryText}>{formatAsCurrencyNumber(cashRegisterPosBusinessDayReportSummary?.deposits)}</div>
            </div>
            <div className={classes.separatedText}>
                <div>{translate('Withdraws')}</div>
                <div className={classes.summaryText}>{formatAsCurrencyNumber(cashRegisterPosBusinessDayReportSummary?.withdraws)}</div>
            </div>
            <div className={classes.separatedText}>
                <div>{translate('Orders')}</div>
                <div className={classes.summaryText}>{cashRegisterPosBusinessDayReportSummary?.totalSales ?? 0}</div>
            </div>
            <div className={classes.separatedText}>
                <div>{translate('Total Sales')}</div>
                <div className={classes.summaryText}>{formatAsCurrencyNumber(cashRegisterPosBusinessDayReportSummary?.totalSold)}</div>
            </div>
        </div>
    );
}

const useStyles = makeStyles((theme) => ({
    cardContainer: {
        display: 'flex',
        flexDirection: 'column',
        padding: '12px 24px',
        fontFamily: theme.typography.regular,
        fontWeight: 400,
        fontSize: 14,
        color: '#232933',
        borderRadius: 8,
        border: '1px solid #D9D9D9',
    },
    separatedText: {
        padding: '5px 0',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
    },

    contentText: {
        display: 'flex',
        alignItems: 'center',
        margin: '10px 0',
    },
    cashRegisterName: {
        fontSize: 16,
        fontWeight: 600,
    },
    summaryText: {
        fontWeight: 600,
    },
    subText: {
        marginLeft: 10,
        fontSize: 12,
    },
    closeCashRegisterChip: {
        backgroundColor: '#FBE1E9',
        color: '#DB3956',
    },
}));

type Props = {
    cashRegisterPosBusinessDayReportSummary?: CashRegisterPosBusinessDaySummaryReportVm;
};
