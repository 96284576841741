import { useState } from 'react';
import * as React from 'react';
import { useForm } from 'react-hook-form';
import { addCustomDiscountCommentApi } from 'src/api/letseatmanager/restaurant/addCustomDiscountCommentApi';
import { Button } from 'src/components/Button';
import { Dialog } from 'src/components/Dialog';
import { DialogActions } from 'src/components/DialogActions';
import { Form } from 'src/components/form/Form';
import { FormTextField } from 'src/components/form/FormTextField';
import { translate } from 'src/i18n/translate';
import { alertKnownErrorOrSomethingWentWrong } from 'src/utils/alert/alertKnownErrorOrSomethingWentWrong';
import { useSelector } from 'src/utils/react/useSelector';

export function AddCustomDiscountCommentDialog({ open, onClose, onSuccess }: Props): React.ReactElement {
    const form = useForm();

    const [loading, setLoading] = useState(false);

    const { watch, setError } = form;
    const comment = watch('comment');

    const restaurantId = useSelector((state) => state.app.restaurantId);

    const handleSubmit = async () => {
        if (!comment) return setError('comment', translate('This field is required') as any);
        setLoading(true);
        const response = await addCustomDiscountCommentApi({ restaurantId, comment });
        setLoading(false);
        if (!response.ok) {
            alertKnownErrorOrSomethingWentWrong(response);
            return;
        }
        onSuccess();
        onClose();
    };

    return (
        <Dialog open={open} onClose={onClose} title={translate('Add Discount Comment')}>
            <Form form={form}>
                <FormTextField disabled={loading} name='comment' label={translate('Discount Comment')} required />
                <DialogActions>
                    <Button onClick={onClose} disabled={loading} secondary>
                        {translate('Cancel')}
                    </Button>
                    <Button onClick={handleSubmit} disabled={loading}>
                        {loading ? translate('Creating') : translate('Create')}
                    </Button>
                </DialogActions>
            </Form>
        </Dialog>
    );
}

type Props = {
    open: boolean;
    onClose: any;
    onSuccess: any;
};
