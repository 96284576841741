import { pidedirectoReportsMethod } from 'src/api/pidedirectoReportsApi';
import type { ApiSauceResponse } from 'src/api/types/ApiSauceResponse';
import type { SalesType } from 'src/constants/SalesTypes';
import type { RestaurantId } from 'src/types/Id';

export async function getAbcRestaurantStatisticsApi(request: GetAbcRestaurantStatisticsRequest): ApiSauceResponse<GetAbcStatisticsApiResponse> {
    return pidedirectoReportsMethod('/abcTablePageReportApi', request);
}

export type GetAbcRestaurantStatisticsRequest = {
    report: string;
    reportPage?: string;
    abcTableFilter?: string;
    restaurantIds: Array<RestaurantId>;
    fromDate: string;
    toDate: string;
    salesType: SalesType;
};

export type GetAbcStatisticsApiResponse = Array<{
    name: string;
    restaurantId: RestaurantId;
    abcTable: Array<AbcTable>;
}>;

export type AbcTable = {
    abc: string;
    itemName: string;
    itemPrice: string;
    orderVolume: number;
    percentageOfSales: string;
    total: string;
};
