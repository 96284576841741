import { letseatmanagerApiMethod } from 'src/api/letseatmanagerApi';
import type { ApiSauceResponse } from 'src/api/types/ApiSauceResponse';
import type { OrderType } from 'src/constants/OrderType';
import { PromotionApp } from 'src/constants/PromotionApp';
import { PromotionType } from 'src/constants/PromotionType';
import type { MenuItemId, RestaurantId } from 'src/types/Id';
import { PromotionVm } from 'src/types/PromotionVm';

export async function createPromotionApi(request: CreatePromotionApiRequest): ApiSauceResponse<PromotionVm> {
    return letseatmanagerApiMethod('promotion/createPromotionApi', request);
}

export type CreatePromotionApiRequest = {
    menuItemIds: Array<MenuItemId>;
    restaurantIds: Array<RestaurantId>;
    promotionType: PromotionType;
    menuItemsQuantityToGet?: number;
    menuItemsQuantityToPay?: number;
    name: string;
    hours: string;
    description?: string;
    orderTypes: Array<OrderType>;
    channels: Array<PromotionApp>;
    startsAt: Date;
    endsAt: Date;
};
