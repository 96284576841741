import { makeStyles } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import Rotate90DegreesCcwIcon from '@material-ui/icons/Rotate90DegreesCcw';
import * as React from 'react';
import { useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { ContextualMenu } from 'src/components/ContextualMenu';
import { ContextualMenuItem } from 'src/components/ContextualMenuItem';
import { ChangeOrderTypeContextualMenuItem } from 'src/components/orderCard/ChangeOrderTypeContextualMenuItem';
import { SecuredContent } from 'src/components/SecuredContent';
import { Text } from 'src/components/Text';
import { Tooltip } from 'src/components/Tooltip';
import { RolePermissions } from 'src/constants/RolePermission';
import { RoutePaths } from 'src/constants/RoutePath';
import { TableShape, TableShapes } from 'src/constants/TableShape';
import { TableSize, TableSizes } from 'src/constants/TableSize';
import { translate } from 'src/i18n/translate';
import { CalendarClockIcon } from 'src/icons/CalendarClockIcon';
import { ClockOutlineIcon } from 'src/icons/ClockOutlineIcon';
import { PeopleOutlineIcon } from 'src/icons/PeopleOutlineIcon';
import { CloseOrderContextualMenuItem } from 'src/modules/order/components/CloseOrderContextualMenuItem';
import { posReducer } from 'src/reducers/posReducer';
import { ChangeNumberOfCustomersDialog } from 'src/scenes/letseatmanager/posRestaurantZones/ChangeNumberOfCustomersDialog';
import { ChangeTableOfOrderDialog } from 'src/scenes/letseatmanager/posRestaurantZones/ChangeTableOfOrderDialog';
import { LocalizationService } from 'src/services/LocalizationService';
import { useLoadPosMenus } from 'src/services/menu/useLoadPosMenus';
import { useChargeOrder } from 'src/services/pos/useChargeOrder';
import { useIsOrderVisibleForUser } from 'src/services/pos/useIsOrderVisibleForUser';
import { usePrintOrderTicket } from 'src/services/printer/usePrintOrderTicket';
import { OrderPaymentVm } from 'src/types/OrderPaymentVm';
import type { OrderVm } from 'src/types/OrderVm';
import { TableVm } from 'src/types/RestaurantZoneVm';
import { isClosedOrder } from 'src/utils/order/isClosedOrder';
import { isEatHereOrder } from 'src/utils/order/isEatHereOrder';
import { isPendingOrder } from 'src/utils/order/isPendingOrder';
import { useHasPrintersToPrint } from 'src/utils/printer/useHasPrintersToPrint';
import { classNames } from 'src/utils/react/classNames';
import { useAction } from 'src/utils/react/useAction';
import { useSelector } from 'src/utils/react/useSelector';
import { useUserHasRolePermission } from 'src/utils/react/useUserHasRolePermissions';
import { useIsSmallScreen } from 'src/utils/react/window/useIsSmallScreen';
import { isMobileApp } from 'src/utils/reactNative/isMobileApp';

export function PosTable({ table, editing, onMouseDown, onMouseUp, onEdit, onRemove, onJoinTables, onClick, refreshRestaurantZones, onRotate }: Props): React.ReactElement {
    const classes = useStyles({ hasOrder: !!table.order?.orderId, isSmallTable: table.tableSize === TableSizes.SMALL });
    const tableRef = useRef<HTMLElement>(null);
    const contextMenuOpenState = useRef(false);
    const isMobile = isMobileApp();
    const isSmallScreen = useIsSmallScreen();
    const [printOrderTicket] = usePrintOrderTicket();
    const { hasPrintersToPrint } = useHasPrintersToPrint();
    const isOrderVisibleForUser = useIsOrderVisibleForUser();
    const [userHasRolePermission] = useUserHasRolePermission();
    const { handlePay } = useChargeOrder();
    const loadPosMenus = useLoadPosMenus();
    const history = useHistory();

    const posMenu = useSelector((state) => state.pos.posMenu);

    const [rotation, setRotation] = useState(0);
    const [contextualMenuState, setContextualMenuState] = useState({ open: false });
    const [openChangeTableOfOrderDialog, setOpenChangeTableOfOrderDialog] = useState(false);
    const [openChangeNumberOfCustomersDialog, setOpenChangeNumberOfCustomersDialog] = useState(false);

    const closeOrdersEnabled = useSelector((state) => state.app.restaurant?.closeOrdersEnabled);

    const setPendingOrder = useAction(posReducer.actions.setPendingOrder);

    const showPrintTicketOption = hasPrintersToPrint && !!table.order?.orderId;
    const shouldRenderChargeButton = table.order && isPendingOrder(table.order.orderStatus);
    const showChangeOrderTypeOption = table.order && !table.order?.customers?.length;
    const time = LocalizationService.formatDateTimeAsTime(table.order?.modifiedAt, table.order?.timeZone);
    const ticketRequestedTime = LocalizationService.formatDateTimeAsTime(table.order?.ticketRequestedAt, table.order?.timeZone);
    const hasPendingOrder = Boolean(table.order);
    const showCreatedAtTime = hasPendingOrder && !table.order?.ticketRequestedAt;
    const showTicketRequestedAtTime = !!table.order && !!table.order?.ticketRequestedAt;
    const shouldRenderCloseOrderButton = table?.order && closeOrdersEnabled && isEatHereOrder(table?.order?.orderType) && isPendingOrder(table?.order.orderStatus);
    const timeOrderAgo = LocalizationService.formatDateTimeAsCalendarDate(table.order?.createdAt)
        .replace('a few seconds ago', 'few seconds')
        .replace('ago', '')
        .replace('minutes', 'min')
        .replace('minutos', 'min');

    useEffect(() => {
        if (table && tableRef.current) {
            tableRef.current.style.top = `${table.top}px`;
            tableRef.current.style.left = `${table.left}px`;
            if (table.tableShape === TableShapes.RECTANGLE) {
                setRotation(table.rotation);
            }
        }
    }, [table]);

    const getWidth = () => {
        if (table.tableShape === TableShapes.RECTANGLE) {
            if (table.tableSize === TableSizes.SMALL) return tableSizes[TableSizes.MEDIUM];
            if (table.tableSize === TableSizes.MEDIUM) return tableSizes[TableSizes.LARGE];
            if (table.tableSize === TableSizes.LARGE) return tableSizes.EXTRA_LARGE;
        }

        return tableSizes[table.tableSize];
    };

    const getHeight = () => {
        if (table.tableShape === TableShapes.RECTANGLE) return tableSizes[TableSizes.SMALL];

        return tableSizes[table.tableSize];
    };

    const getClassNames = () => {
        let className = classNames(classes.container);
        if (table.tableShape === TableShapes.CIRCLE) className = classNames(className, (classes as any).circleTable);
        if (editing) className = classNames(className, classes.containerEditing);

        if (table.tableShape === TableShapes.CIRCLE) {
            if (table.tableSize === TableSizes.SMALL) className = classNames(className, classes.smallCircle);
            else if (table.tableSize === TableSizes.MEDIUM) className = classNames(className, classes.circle);
            else className = classNames(className, classes.largeCircle);
        }

        if (table.order) {
            className = classNames(className, classes.tableWithOrder);
        }

        if (table.order && (table.order?.ticketRequestedAt || isClosedOrder(table.order?.orderStatus))) {
            className = classNames(className, classes.pendingPaymentOrder);
        }
        return className;
    };

    const getTopEditIcon = () => {
        if (rotation === 0 || rotation === 90) return -15;
        if (rotation === 180 || rotation === 270) return (tableRef.current?.offsetHeight ?? 0) - 25;
    };

    const getRightEditIcon = () => {
        if (rotation === 0 || rotation === 270) return -15;
        if (rotation === 90 || rotation === 180) return (tableRef.current?.offsetWidth ?? 0) - 25;
    };

    const getTopRotateIcon = () => {
        if (rotation === 0 || rotation === 270) return -15;
        if (rotation === 180 || rotation === 90) return (tableRef.current?.offsetHeight ?? 0) - 25;
    };

    const getLeftRotateIcon = () => {
        if (rotation === 0 || rotation === 90) return -15;
        if (rotation === 270 || rotation === 180) return (tableRef.current?.offsetWidth ?? 0) - 25;
    };

    const getBottomDeleteIcon = () => {
        if (rotation === 0 || rotation === 270) return -15;
        if (rotation === 180 || rotation === 90) return (tableRef.current?.offsetHeight ?? 0) - 25;
    };

    const getRightBottomIcon = () => {
        if (rotation === 0 || rotation === 90) return -15;
        if (rotation === 270 || rotation === 180) return (tableRef.current?.offsetWidth ?? 0) - 25;
    };

    const handleMouseDown = (e: any) => {
        if (!editing) return;
        onMouseDown({ e: isMobile ? e.targetTouches[0] : e, tableNumber: table?.tableNumber });
    };

    const handleMouseUp = (e: any) => {
        if (!editing) return;
        onMouseUp(isMobile ? e.targetTouches[0] : e);
    };

    const handleEditOrder = async () => {
        onClick(table);
    };

    const handleChangeNumberOfCustomers = () => {
        setPendingOrder(table.order);
        setOpenChangeNumberOfCustomersDialog(true);
    };

    const handleEditTableOfOrder = () => setOpenChangeTableOfOrderDialog(true);

    const handlePrintTicket = async () => {
        if (!table?.order) return;
        await printOrderTicket(table?.order);
    };

    const handleOnClick = (e: any) => {
        if (editing || contextualMenuState.open || contextMenuOpenState.current) return;

        onClick(table);
    };

    const handleCloseTableContextualMenu = () => {
        setContextualMenuState({ open: false });
        contextMenuOpenState.current = false;
    };

    const handleOpenTableContextualMenu = () => {
        setContextualMenuState({ open: true });
        contextMenuOpenState.current = true;
    };

    const handleJoinTables = () => {
        if (!canJoinTable()) {
            return true;
        }
        onJoinTables(table.tableNumber);
    };

    const onHandlePay = async (table: TableVm) => {
        if (!(table.order && userHasRolePermission(RolePermissions.CHARGE_ORDERS))) return;
        if (!posMenu) await loadPosMenus();
        history.push({
            pathname: RoutePaths.POS,
            search: history.location.search,
        });
        handlePay(table.order);
    };

    const canJoinTable = () => !table.order?.promoCodeId && !table.order?.usedCredits && !table.order?.payments?.some((payment: OrderPaymentVm) => !!payment.restaurantCurrency);

    const rotateTable = () => {
        if (table.tableShape === TableShapes.CIRCLE || table.tableShape === TableShapes.SQUARE) return;
        const newRotation = rotation + 90 === 360 ? 0 : rotation + 90;
        setRotation(newRotation);
        onRotate(table, newRotation);
    };

    const getTableInfoStyles = () => {
        switch (rotation) {
            case 90:
                return classes.tableInfoRotated90Degrees;
            case 180:
                return classes.tableInfoRotated180Degrees;
            case 270:
                return classes.tableInfoRotated270Degrees;
            default:
                return classes.tableInfo;
        }
    };

    const getContextualMenuStyles = () => {
        switch (rotation) {
            case 90:
                return classes.menuRotated90Degrees;
            case 180:
                return classes.menuRotated180Degrees;
            case 270:
                return classes.menuRotated270Degrees;
            default:
                return classes.contextualMenu;
        }
    };

    return (
        <div>
            <ChangeTableOfOrderDialog open={openChangeTableOfOrderDialog} orderId={table.order?.orderId} onClose={() => setOpenChangeTableOfOrderDialog(false)} />
            <ChangeNumberOfCustomersDialog open={openChangeNumberOfCustomersDialog} handleClose={() => setOpenChangeNumberOfCustomersDialog(false)} order={table.order} />
            <div
                id={table?.tableNumber}
                className={getClassNames()}
                style={{ width: getWidth(), maxHeight: getHeight(), minHeight: getHeight(), transform: `rotate(${isSmallScreen ? '0' : rotation}deg)` }}
                /* @ts-ignore */
                ref={tableRef}
                onMouseDown={handleMouseDown}
                onMouseUp={handleMouseUp}
                onTouchStart={handleMouseDown}
                onTouchEnd={handleMouseUp}
                onClick={handleOnClick}
                data-testid={`posTable.tableNumber-${table?.tableNumber}`}
            >
                {hasPendingOrder && !!table.order && isOrderVisibleForUser(table.order) && !editing && (
                    <ContextualMenu
                        classes={{ button: classNames(classes.contextualMenu, getContextualMenuStyles()), list: classes.list }}
                        onOpen={handleOpenTableContextualMenu}
                        onClose={handleCloseTableContextualMenu}
                    >
                        <div className={classes.timeContainer}>
                            <CalendarClockIcon color={'#FFF'} />
                            <Text style={{ color: 'white' }}>{time}</Text>
                        </div>
                        <div className={classes.optionsContainer}>
                            {showChangeOrderTypeOption && table.order && <ChangeOrderTypeContextualMenuItem order={table.order} onChangeOrderType={refreshRestaurantZones} />}
                            <ContextualMenuItem className={classes.item} onClick={handleEditOrder} label={translate('Edit Order')} />
                            {!isClosedOrder(table.order?.orderStatus) && (
                                <ContextualMenuItem className={classes.item} onClick={handleJoinTables} disabled={!canJoinTable()}>
                                    <div className={classes.contextualMenuItem}>
                                        <span>{translate('Join tables')}</span>
                                        {!canJoinTable() && <span className={classes.error}>{translate('This order has discounts, is not possible join it')}</span>}
                                    </div>
                                </ContextualMenuItem>
                            )}
                            <ContextualMenuItem className={classes.item} onClick={handleEditTableOfOrder} label={translate('Change Table of Order')} />
                            <ContextualMenuItem className={classes.item} onClick={handleChangeNumberOfCustomers} label={translate('Change Number of Customers')} />
                            {shouldRenderChargeButton && (
                                <SecuredContent rolePermission={RolePermissions.CHARGE_ORDERS}>
                                    <ContextualMenuItem className={classes.item} onClick={() => onHandlePay(table)} label={translate('Charge')} />
                                </SecuredContent>
                            )}
                            {showPrintTicketOption && !shouldRenderCloseOrderButton && <ContextualMenuItem className={classes.item} onClick={handlePrintTicket} label={translate('Print Bill')} />}
                            <CloseOrderContextualMenuItem order={table.order} onSuccess={refreshRestaurantZones} />
                        </div>
                    </ContextualMenu>
                )}
                {editing && !hasPendingOrder && (
                    <>
                        {!isSmallScreen && (
                            <div
                                className={classes.iconContainer}
                                style={{ top: getTopRotateIcon(), left: getLeftRotateIcon(), transform: `rotate(${-rotation}deg)` }}
                                onClick={rotateTable}
                                role={'button'}
                                aria-label={translate('Rotate')}
                            >
                                {/* @ts-ignore */}
                                <Rotate90DegreesCcwIcon title={translate('Rotate')} className={classes.icon} />
                            </div>
                        )}
                        <div
                            className={classes.iconContainer}
                            style={{ top: isSmallScreen ? -15 : getTopEditIcon(), right: isSmallScreen ? -15 : getRightEditIcon(), transform: `rotate(${isSmallScreen ? '0' : -rotation}deg)` }}
                            onClick={() => onEdit(table)}
                        >
                            {/* @ts-ignore */}
                            <EditIcon title={translate('Edit')} className={classes.icon} />
                        </div>
                        <div
                            className={classes.iconContainer}
                            style={{
                                bottom: isSmallScreen ? -15 : getBottomDeleteIcon(),
                                right: isSmallScreen ? -15 : getRightBottomIcon(),
                                transform: isSmallScreen ? '' : `rotate(${-rotation}deg)`,
                            }}
                            onClick={() => onRemove(table)}
                        >
                            {/* @ts-ignore */}
                            <DeleteIcon title={translate('Delete')} className={classes.icon} />
                        </div>
                    </>
                )}
                <div className={classes.textContainer} style={{ transform: `rotate(${isSmallScreen ? '0' : -rotation}deg)` }}>
                    <div className={classes.tableNumber}>{isSmallScreen ? translate('Table @table', { table: table.tableNumber }) : table.tableNumber}</div>
                    <span className={classes.waiterName} style={{ maxWidth: getWidth() - 20 }}>
                        {table.order?.waiterName}
                    </span>
                </div>
                {table.order && (
                    <div style={{ width: getWidth(), transform: `rotate(${isSmallScreen ? '0' : -rotation}deg)` }} className={classNames(classes.tableInfo, getTableInfoStyles())}>
                        {showCreatedAtTime && (
                            <div className={classNames(classes.lineInfo, (classes as any).timeOpened)}>
                                <Text>{timeOrderAgo}</Text>
                            </div>
                        )}
                        {showTicketRequestedAtTime && (
                            <Tooltip text={translate('Ticket requested at @ticketRequestedTime', { ticketRequestedTime })}>
                                <div className={classes.lineInfo}>
                                    |
                                    <ClockOutlineIcon color={isSmallScreen ? '#FFF' : 'currentColor'} />
                                    <Text>{ticketRequestedTime}</Text>
                                </div>
                            </Tooltip>
                        )}
                        {hasPendingOrder && !!table.order?.numberOfCustomers && (
                            <Tooltip text={translate('Number of Customers')}>
                                <div className={classes.lineInfo}>
                                    |
                                    <PeopleOutlineIcon color={isSmallScreen ? '#FFF' : 'currentColor'} />
                                    <div data-testid='number-of-customers'>
                                        <Text>{table.order?.numberOfCustomers}</Text>
                                    </div>
                                </div>
                            </Tooltip>
                        )}
                    </div>
                )}
            </div>
        </div>
    );
}

const tableSizes = {
    SMALL: 85,
    MEDIUM: 110,
    LARGE: 135,
    EXTRA_LARGE: 160,
} as const;

const useStyles = makeStyles((theme) => ({
    container: {
        borderRadius: 5,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: theme.palette.surface.successSecondary,
        cursor: 'pointer',
        flexShrink: 0,
        position: 'absolute',
        zIndex: 11,
        top: 0,
        left: 0,
        [theme.breakpoints.down('md')]: {
            maxHeight: '120px !important',
        },
        [theme.breakpoints.down('sm')]: {
            borderRadius: '8px !important',
            padding: '12px 12px',
            marginBottom: 18,
            position: 'static',
            width: '100% !important',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-start',
            height: 'fit-content',
        },
    },
    lineInfo: ({ isSmallTable }: any) => ({
        display: 'flex',
        alignItems: 'center',
        gap: 2,
        color: isSmallTable ? 'white' : 'currentColor',
        [theme.breakpoints.up('sm')]: {
            fontSize: isSmallTable ? 10 : 12,
        },
    }),
    tableInfo: {
        position: 'absolute',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        gap: 4,
        bottom: -30,
        height: 20,
        width: '100%',
        color: '#2E3748',
        backgroundColor: '#F5F5F5',
        borderRadius: 2,
        '& .MuiSvgIcon-root': {
            color: 'white',
        },
        [theme.breakpoints.down('sm')]: {
            position: 'relative',
            bottom: 0,
            color: 'white',
            backgroundColor: 'transparent',
            width: 'fit-content',
            justifyContent: 'flex-start',
        },
    },
    tableWithOrder: {
        backgroundColor: theme.palette.text.danger,
        '& ::before': {
            backgroundColor: '#dc354503',
        },
    },
    pendingPaymentOrder: {
        backgroundColor: theme.palette.text.info,
        '& ::before': {
            backgroundColor: '#2C59AB03',
        },
    },
    containerEditing: {
        cursor: 'move',
    },
    smallCircle: ({ hasOrder }: any) => ({
        borderRadius: hasOrder ? '50px 10px 50px 50px' : 50,
    }),
    circle: ({ hasOrder }: any) => ({
        borderRadius: hasOrder ? '75px 10px 75px 75px' : 75,
    }),
    largeCircle: ({ hasOrder }: any) => ({
        borderRadius: hasOrder ? '100px 10px 100px 100px' : 100,
    }),
    textContainer: {
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        fontSize: 12,
        color: 'white',
        userSelect: 'none',
        fontFamily: theme.typography.regular,
        [theme.breakpoints.down('sm')]: {
            fontSize: 14,
            gap: 4,
            alignItems: 'flex-start',
        },
    },
    tableNumber: {
        fontSize: 14,
        textAlign: 'center',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        width: '100%',
        overflow: 'hidden',
        padding: '0 4px',
        [theme.breakpoints.up('sm')]: {
            fontSize: 16,
        },
    },
    iconContainer: {
        backgroundColor: 'white',
        padding: 5,
        borderRadius: 20,
        position: 'absolute',
        right: -15,
        border: `1px solid ${theme.palette.secondary.dark}`,
        height: 40,
        width: 40,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        cursor: 'pointer',
    },
    icon: {
        color: theme.palette.secondary.contrastText,
    },
    contextualMenu: {
        zIndex: 1,
        position: 'absolute',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        right: 8,
        top: 8,
        height: 20,
        width: 20,
        padding: 0,
        '& .MuiSvgIcon-root': {
            color: 'white',
        },
    },
    contextualMenuItem: {
        display: 'flex',
        flexDirection: 'column',
    },
    error: {
        color: '#F64F4F',
        maxWidth: 230,
        whiteSpace: 'normal',
    },
    item: {
        fontSize: 14,
        padding: '8px 12px',
        '&:hover': {
            backgroundColor: '#EBFFF9',
        },
    },
    list: {
        padding: 0,
    },
    waiterName: {
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        maxWidth: '200px',
    },
    optionsContainer: {
        padding: 0,
    },
    timeContainer: {
        display: 'flex',
        gap: 8,
        width: '100%',
        alignItems: 'center',
        justifyContent: 'center',
        padding: '12px 0',
        backgroundColor: theme.palette.primary.main,
    },
    tableInfoRotated90Degrees: {
        top: '45%',
        left: '65%',
    },
    tableInfoRotated180Degrees: {
        top: '-35%',
    },
    tableInfoRotated270Degrees: {
        top: '40%',
        left: '-60%',
    },
    menuRotated90Degrees: {
        top: 8,
        left: 8,
        transform: 'rotate(90deg)',
    },
    menuRotated180Degrees: {
        top: '70%',
        right: '80%',
        transform: 'rotate(180deg)',
    },
    menuRotated270Degrees: {
        transform: 'rotate(90deg)',
        top: '70%',
    },
}));

type Props = {
    table: {
        tableNumber: string;
        tableSize: TableSize;
        tableShape: TableShape;
        top: number;
        left: number;
        rotation: number;
        order?: OrderVm;
    };
    editing: boolean;
    onMouseDown: any;
    onMouseUp: any;
    onEdit: any;
    onRotate: any;
    onRemove: any;
    onJoinTables: any;
    onClick: any;
    refreshRestaurantZones: any;
};
