import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import Grid from '@material-ui/core/Grid';
import { DatePicker } from '@material-ui/pickers';
import moment from 'moment-timezone';
import { useEffect, useState } from 'react';
import * as React from 'react';
import { findSuspiciousOrdersApi } from 'src/api/letseatadmin/order/findSuspiciousOrdersApi';
import { app2 } from 'src/app2';
import { translate } from 'src/i18n/translate';
import { OrdersTable } from 'src/scenes/letseatadmin/order/OrdersTable';
import { OrderVm } from 'src/types/OrderVm';
import { alertKnownErrorOrSomethingWentWrong } from 'src/utils/alert/alertKnownErrorOrSomethingWentWrong';
import { useAction } from 'src/utils/react/useAction';

export function SuspiciousOrdersPage(): React.ReactElement {
    const [from, setFrom] = useState(moment().subtract(0, 'days').format('YYYY-MM-DD'));
    const [to, setTo] = useState(moment().format('YYYY-MM-DD'));
    const [loading, setLoading] = useState(false);
    const [orders, setOrders] = useState<Array<OrderVm>>([]);

    const setTitle = useAction(app2.actions.setTitle);

    useEffect(() => {
        setTitle(translate('Suspicious Orders'));
        load();
    }, []);

    const handleChangeFromDate = (date: any) => setFrom(date.format('YYYY-MM-DD'));

    const handleChangeToDate = (date: any) => setTo(date.format('YYYY-MM-DD'));

    const handleSubmit = async () => await load();

    const load = async () => {
        setLoading(true);
        const response = await findSuspiciousOrdersApi({ from: moment(from).toDate(), to: moment(to).add(1, 'day').toDate() });
        setLoading(false);
        if (!response.ok) return alertKnownErrorOrSomethingWentWrong(response);
        setOrders(response.data);
    };

    return (
        <Grid container spacing={5}>
            <Grid item xs={12}>
                <Card style={{ padding: 24 }}>
                    <Grid container direction='row' spacing={3} alignItems='center'>
                        <Grid item>
                            <DatePicker variant='inline' label={translate('From')} value={from} onChange={handleChangeFromDate} format='ll' autoOk />
                        </Grid>
                        <Grid item>
                            <DatePicker variant='inline' label={translate('To')} value={to} onChange={handleChangeToDate} format='ll' autoOk />
                        </Grid>
                        <Grid item>
                            <Button color='primary' type='submit' onClick={handleSubmit} disabled={loading}>
                                {loading ? translate('Searching').toUpperCase() : translate('Search').toUpperCase()}
                            </Button>
                        </Grid>
                    </Grid>
                </Card>
            </Grid>
            <Grid item xs={12}>
                <OrdersTable orders={orders} loading={loading} onClickRefresh={load} />
            </Grid>
        </Grid>
    );
}
