import { makeStyles, useTheme } from '@material-ui/core';
import { PrinterIcon } from '@pidedirecto/ui/icons';
import * as React from 'react';
import { useContext } from 'react';
import { Box } from 'src/components/Box';
import { Button } from 'src/components/Button';
import { CreatePrinterSteps } from 'src/constants/CreatePrinterStep';
import { PrinterTypes } from 'src/constants/PrinterType';
import { translate } from 'src/i18n/translate';
import { BluetoothIcon } from 'src/icons/BluetoothIcon';
import { SimpleTerminalIcon } from 'src/icons/SimpleTerminalIcon';
import { UsbIcon } from 'src/icons/UsbIcon';
import { WifiIcon } from 'src/icons/WifiIcon';
import { PrinterWizardContext, PrinterWizardProviderValues } from 'src/providers/PrinterWizardProvider';
import { isBluetoothPrinter } from 'src/utils/printer/isBluetoothPrinter';
import { isPaymentTerminalPrinter } from 'src/utils/printer/isPaymentTerminalPrinter';
import { isSdkPrinter } from 'src/utils/printer/isSdkPrinter';
import { isUsbPrinter } from 'src/utils/printer/isUsbPrinter';
import { isWifiPrinter } from 'src/utils/printer/isWifiPrinter';
import { classNames } from 'src/utils/react/classNames';
import { isMobileApp } from 'src/utils/reactNative/isMobileApp';

export function PrinterTypeSelection(): React.ReactElement | null {
    const classes = useStyles();
    const theme = useTheme();

    const { formValues, goNextWizardStep, goBackWizardStep, setCreatePrinterWizardFormValue, activeCreatePrinterStep } = useContext<PrinterWizardProviderValues>(PrinterWizardContext);

    const handleChangePrinterType = (printerType: string) => {
        setCreatePrinterWizardFormValue('printerType', printerType);
    };

    if (activeCreatePrinterStep !== CreatePrinterSteps.PRINTER_TYPE) return null;

    return (
        <div className={classes.stepContent}>
            <p className={classes.label}>{translate('How are you going to connect your printer?')}</p>
            <div className={classes.printerContentContainer}>
                <Box className={classNames(classes.box, isWifiPrinter(formValues.printerType) && classes.isSelected)} onClick={() => handleChangePrinterType(PrinterTypes.WIFI)}>
                    <WifiIcon color={theme.palette.primary.main} />
                    <p className={classes.boxText}>{translate('PrinterTypes.WIFI')}</p>
                </Box>
                <Box className={classNames(classes.box, isBluetoothPrinter(formValues.printerType) && classes.isSelected)} onClick={() => handleChangePrinterType(PrinterTypes.BLUETOOTH)}>
                    <BluetoothIcon color={theme.palette.primary.main} />
                    <p className={classes.boxText}>{translate('PrinterTypes.BLUETOOTH')}</p>
                </Box>
                <Box className={classNames(classes.box, isUsbPrinter(formValues.printerType) && classes.isSelected)} onClick={() => handleChangePrinterType(PrinterTypes.USB)}>
                    <UsbIcon color={theme.palette.primary.main} />
                    <p className={classes.boxText}>{translate('PrinterTypes.USB')}</p>
                </Box>
                {isMobileApp() && (
                    <Box
                        className={classNames(classes.box, isPaymentTerminalPrinter(formValues.printerType) && classes.isSelected)}
                        onClick={() => handleChangePrinterType(PrinterTypes.PAYMENT_TERMINAL_PRINTER)}
                    >
                        <SimpleTerminalIcon color={theme.palette.primary.main} />
                        <p className={classes.boxText}>{translate('PrinterTypes.PAYMENT_TERMINAL_PRINTER')}</p>
                    </Box>
                )}
                <Box className={classNames(classes.box, isSdkPrinter(formValues.printerType) && classes.isSelected)} onClick={() => handleChangePrinterType(PrinterTypes.SDK)}>
                    <PrinterIcon color={theme.palette.primary.main} />
                    <p className={classes.boxText}>{translate('PrinterTypes.SDK')}</p>
                </Box>
            </div>
            <div className={classes.actionsContainer}>
                <Button secondary onClick={goBackWizardStep} type={'button'}>
                    {translate('Back')}
                </Button>
                <Button primary onClick={goNextWizardStep}>
                    {translate('Next')}
                </Button>
                <p className={classes.enterText}>{translate('hit Enter ↩')}</p>
            </div>
        </div>
    );
}

const useStyles = makeStyles((theme) => ({
    box: {
        width: '100%',
        height: 120,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        textAlign: 'center',
        justifyContent: 'center',
        '&:hover': {
            cursor: 'pointer',
        },
    },
    isSelected: {
        outline: `2px solid ${theme.palette.primary.main}`,
    },
    boxText: {
        marginBottom: 0,
    },
    label: {
        fontSize: 16,
        fontFamily: theme.typography.regular,
        color: '#0D3037',
        padding: '12px 0',
    },
    stepContent: {
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        animation: '$appear 700ms ease',
    },
    printerContentContainer: {
        display: 'grid',
        width: '100%',
        gridTemplateColumns: 'repeat(auto-fit, minmax(100px, 1fr))',
        gap: 20,
        marginBottom: 24,
    },
    actionsContainer: {
        display: 'flex',
        gap: 12,
        marginTop: 24,
        width: '100%',
        justifyContent: 'flex-end',
    },
    enterText: {
        fontFamily: theme.typography.regular,
        color: '#0D3037',
    },
    '@keyframes appear': {
        '0%': {
            opacity: 0,
            transform: 'translateY(100px)',
        },
        '100%': {
            opacity: 1,
            transform: 'translateY(0px)',
        },
    },
}));
