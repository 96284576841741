import { letseatadminApiMethod } from 'src/api/letseatadminApi';
import type { ApiSauceResponse } from 'src/api/types/ApiSauceResponse';
import type { RestaurantId } from 'src/types/Id';

export async function importRestaurantMenuFromExternalUrlApi(request: importRestaurantMenuFromExternalUrlApiRequest): ApiSauceResponse<void> {
    return letseatadminApiMethod('menu/importRestaurantMenuFromExternalUrlApi', request);
}

type importRestaurantMenuFromExternalUrlApiRequest = {
    url: string;
    restaurantId: RestaurantId;
    importImages?: boolean;
};
