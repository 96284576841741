import { countAnsiEscapeSequenceStylingCharacters } from 'src/utils/string/countAnsiEscapeSequenceStylingCharacters';

export function padBetween<T extends string | undefined | null, U extends string | undefined | null>(left: T, right: U, maxLength: number, fillString: string = ' '): T {
    if ((left === undefined || left === null) && (right === undefined || right === null)) {
        return left;
    }

    // Solve issue with ansi styling messing padding up
    maxLength = maxLength + countAnsiEscapeSequenceStylingCharacters(left) + countAnsiEscapeSequenceStylingCharacters(right);

    return `${(left ?? '').padEnd(maxLength - (right ?? '').length)}${right ?? ''}` as any;
}
