import { makeStyles } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Grid from '@material-ui/core/Grid';
import LinearProgress from '@material-ui/core/LinearProgress';
import { Loader } from '@pidedirecto/ui';
import moment from 'moment';
import { useEffect, useState } from 'react';
import * as React from 'react';
import { useForm, useWatch } from 'react-hook-form';
import { useLocation } from 'react-router-dom';
import { getDriverApi } from 'src/api/letseatadmin/driver/getDriverApi';
import { letseatmanagerApiDeprecated } from 'src/api/letseatmanagerApiDeprecated';
import { Form } from 'src/components/form/Form';
import { FormBankSelect } from 'src/components/form/FormBankSelect';
import { FormCitySelect } from 'src/components/form/FormCitySelect';
import { FormNumberField } from 'src/components/form/FormNumberField';
import { FormRadio } from 'src/components/form/FormRadio';
import { FormRadioGroup } from 'src/components/form/FormRadioGroup';
import { FormRfcField } from 'src/components/form/FormRfcField';
import { FormTextField } from 'src/components/form/FormTextField';
import { FormVehicleTypeSelect } from 'src/components/form/FormVehicleTypeSelect';
import { history } from 'src/config/history';
import { Bank } from 'src/constants/Bank';
import { translate } from 'src/i18n/translate';
import type { DriverId } from 'src/types/Id';
import { alertKnownErrorOrSomethingWentWrong } from 'src/utils/alert/alertKnownErrorOrSomethingWentWrong';
import { minutesFromNow } from 'src/utils/date/minutesFromNow';
import { deleteUrlQueryParameter } from 'src/utils/history/deleteUrlQueryParameter';
import { getUrlQueryParameter } from 'src/utils/history/getUrlQueryParameter';
import { useSelector } from 'src/utils/react/useSelector';
import { validBusinessName, validClabe, validCurp } from 'src/utils/Validator';

export function ChangeDriverDialog({ onDriverChanged }: Props): React.ReactElement {
    const classes = useStyles();
    const location = useLocation();
    const form = useForm({ defaultValues: INITIAL_STATE_DRIVER });
    const { control, reset } = form;

    const adminUser = useSelector((state) => state.authentication.adminUser);

    const bank = useWatch<Bank>({ name: 'driver.bank', control });
    const banned = useWatch<string>({ name: 'driver.banned', control });
    const bannedUntilSelect = useWatch<string>({ name: 'driver.bannedUntilSelect', control });
    const mobileNumber = useWatch({ name: 'driver.mobileNumber', control });

    const [open, setOpen] = useState(false);
    const [loading, setLoading] = useState(false);
    const [bannedBlock, setBannedBlock] = useState(true);
    const [verificationControlsEnabled, setVerificationControlsEnabled] = useState(true);
    const [enableChangeClabe, setEnableChangeClabe] = useState(false);

    const handleClose = () => {
        if (loading) return;
        if (history.length <= 2) {
            history.replace({
                path: location.pathname,
                search: deleteUrlQueryParameter(CHANGE_DRIVER),
            });
            return;
        }
        history.goBack();
    };

    const handleExited = () => {
        setOpen(false);
        setLoading(false);
        reset(INITIAL_STATE_DRIVER);
    };

    const handleEnableBanControls = () => {
        const password = prompt(translate('Password'));
        if (password !== '1212') return alert(translate('Wrong Password'));
        setBannedBlock(false);
    };

    const handleEnableVerifiedControls = () => {
        const password = prompt(translate('Password'));
        if (password !== '7470') return alert(translate('Wrong Password'));
        setVerificationControlsEnabled(false);
    };

    const handleChangeCLABE = () => {
        const password = prompt(translate('Password to change CLABE'));
        if (password !== '2900') {
            return alert(translate('Wrong Password'));
        }
        setEnableChangeClabe(true);
    };

    const getBannedUntilDate = (bannedUntil: number) => {
        return moment().add(bannedUntil, 'minutes').toDate();
    };

    const openDialog = async (driverId: DriverId) => {
        setOpen(true);
        await load(driverId);
    };

    const closeDialog = () => {
        setBannedBlock(true);
        setVerificationControlsEnabled(true);
        setOpen(false);
    };

    const load = async (driverId: DriverId) => {
        setLoading(true);
        const response = await getDriverApi({ driverId });
        setLoading(false);
        if (!response.ok) {
            alertKnownErrorOrSomethingWentWrong(response);
            setOpen(false);
            return;
        }
        let banTimeleft;
        const driver = response.data;
        if (driver.bannedUntil) banTimeleft = minutesFromNow(driver.bannedUntil);
        reset({
            'driver.driverId': driver.driverId,
            'driver.mobileNumber': driver.mobileNumber,
            'driver.email': driver.email ?? '',
            'driver.firstName': driver.firstName ?? '',
            'driver.lastName': driver.lastName ?? '',
            'driver.businessName': driver.businessName ?? '',
            'driver.curp': driver.curp ?? '',
            'driver.vehicleType': driver.vehicleType ?? null,
            'driver.bank': driver.bank ?? undefined,
            'driver.clabe': driver.clabe ?? '',
            'driver.rfc': driver.rfc ?? '',
            'driver.city': driver.city,
            'driver.comments': driver.comments ?? '',
            'driver.verified': driver.verified ? 'true' : 'false',
            'driver.appPaymentsEnabled': driver.appPaymentsEnabled ? 'true' : 'false',
            'driver.cashPaymentsEnabled': driver.cashPaymentsEnabled ? 'true' : 'false',
            'driver.prio': driver.prio,
            'driver.removed': driver.removed ? 'true' : 'false',
            'driver.banned': driver.banned ? 'true' : 'false',
            'driver.bannedUntil': banTimeleft ?? 1,
            'driver.bannedUntilSelect': driver.bannedUntil !== undefined ? 'true' : 'false',
        } as any);
    };

    const onSubmit = async (form: any) => {
        setLoading(true);
        let bannedUntil;
        if (form.driver.bannedUntil > 0 && form.driver.banned === 'true') bannedUntil = getBannedUntilDate(form.driver.bannedUntil);
        const response = await letseatmanagerApiDeprecated.admin.changeDriver({
            driverId: form.driver.driverId,
            city: form.driver.city,
            email: form.driver.email,
            firstName: form.driver.firstName,
            lastName: form.driver.lastName,
            businessName: form.driver.businessName,
            curp: form.driver.curp,
            bank: form.driver.bank,
            clabe: form.driver.clabe,
            rfc: form.driver.rfc,
            comments: form.driver.comments,
            verified: form.driver.verified === 'true',
            appPaymentsEnabled: form.driver.appPaymentsEnabled === 'true',
            cashPaymentsEnabled: form.driver.cashPaymentsEnabled === 'true',
            prio: parseInt(form.driver.prio),
            removed: form.driver.removed === 'true',
            banned: form.driver.banned === 'true',
            bannedUntil: form.driver.bannedUntilSelect === 'true' ? bannedUntil : '',
            vehicleType: form.driver.vehicleType,
        });
        setLoading(false);
        if (!response.ok) {
            alertKnownErrorOrSomethingWentWrong(response);
            return;
        }
        setEnableChangeClabe(false);
        onDriverChanged?.();
        handleClose();
    };

    useEffect(() => {
        const driverId: DriverId = getUrlQueryParameter(CHANGE_DRIVER, location);
        if (driverId && !open) {
            openDialog(driverId);
        } else if (!driverId && open) {
            closeDialog();
        }
    }, [location]);

    return (
        <Dialog aria-labelledby='change-driver-dialog-title' fullWidth scroll='paper' open={open} onClose={handleClose} onExited={handleExited}>
            <DialogTitle id='change-driver-dialog-title'>{translate('Change Driver').toUpperCase()}</DialogTitle>
            <Form onSubmit={onSubmit} form={form}>
                <DialogContent>
                    {loading && <Loader size={40} loading={true} />}
                    {!loading && (
                        <>
                            <Grid className={classes.gridSpacing} item xs={12}>
                                <FormTextField name='driver.driverId' label={translate('Driver Id')} disabled />
                            </Grid>

                            {mobileNumber && (
                                <Grid className={classes.gridSpacing} item xs={12}>
                                    <FormTextField name='driver.email' label={translate('Email')} required disabled={loading} />
                                </Grid>
                            )}

                            {mobileNumber && (
                                <Grid className={classes.gridSpacing} item xs={6}>
                                    <FormTextField name='driver.firstName' label={translate('First Name')} required disabled={loading} inputProps={{ autoComplete: 'no' }} />
                                </Grid>
                            )}

                            {mobileNumber && (
                                <Grid className={classes.gridSpacing} item xs={6}>
                                    <FormTextField name='driver.lastName' label={translate('Last Name')} required disabled={loading} inputProps={{ autoComplete: 'no' }} />
                                </Grid>
                            )}

                            {mobileNumber && (
                                <Grid className={classes.gridSpacing} item xs={12}>
                                    <FormTextField name='driver.businessName' label={translate('Business Name/Name')} validate={validBusinessName} disabled={loading} />
                                </Grid>
                            )}

                            {mobileNumber && (
                                <Grid className={classes.gridSpacing} item xs={12}>
                                    <FormTextField name='driver.curp' label={translate('CURP')} validate={validCurp} disabled={loading} />
                                </Grid>
                            )}

                            {mobileNumber && (
                                <Grid className={classes.gridSpacing} item xs={12}>
                                    <FormVehicleTypeSelect name='driver.vehicleType' label={translate('Select a Vehicle')} disabled={loading} />
                                </Grid>
                            )}

                            {mobileNumber && (
                                <Grid className={classes.gridSpacing} item xs={12}>
                                    <FormBankSelect name='driver.bank' label={translate('Bank')} disabled={loading} />
                                </Grid>
                            )}
                            {mobileNumber && adminUser && (
                                <Grid className={classes.gridSpacing} item xs={12}>
                                    <Button onClick={handleChangeCLABE} disabled={loading}>
                                        {translate('Enable Change CLABE').toUpperCase()}
                                    </Button>
                                </Grid>
                            )}
                            {mobileNumber && (
                                <Grid className={classes.gridSpacing} item xs={12}>
                                    <FormTextField name='driver.clabe' label={translate('CLABE')} validate={validClabe(bank)} disabled={loading || !enableChangeClabe} />
                                </Grid>
                            )}

                            {mobileNumber && (
                                <Grid className={classes.gridSpacing} item xs={12}>
                                    <FormRfcField name='driver.rfc' label={translate('RFC')} disabled={loading} />
                                </Grid>
                            )}

                            <Grid className={classes.gridSpacing} item xs={12}>
                                <FormCitySelect name='driver.city' disabled={loading} label={translate('City')} />
                            </Grid>

                            <Grid className={classes.gridSpacing} item xs={12}>
                                <Button onClick={handleEnableVerifiedControls} disabled={loading}>
                                    {translate('Enable Verified Controls').toUpperCase()}
                                </Button>
                            </Grid>

                            <Grid className={classes.gridSpacing} item xs={12}>
                                <FormRadioGroup row name='driver.verified'>
                                    <FormRadio label={translate('Not Verified')} value='false' disabled={loading || verificationControlsEnabled} />
                                    <FormRadio label={translate('Verified')} value='true' disabled={loading || verificationControlsEnabled} />
                                </FormRadioGroup>
                            </Grid>

                            <Grid className={classes.gridSpacing} item xs={12}>
                                <FormRadioGroup row name='driver.appPaymentsEnabled'>
                                    <FormRadio label={translate('No App Payments')} value='false' disabled={loading} />
                                    <FormRadio label={translate('App Payments')} value='true' disabled={loading} />
                                </FormRadioGroup>
                            </Grid>

                            <Grid className={classes.gridSpacing} item xs={12}>
                                <FormRadioGroup row name='driver.cashPaymentsEnabled'>
                                    <FormRadio label={translate('No Cash Payments')} value='false' disabled={loading} />
                                    <FormRadio label={translate('Cash Payments')} value='true' disabled={loading} />
                                </FormRadioGroup>
                            </Grid>

                            <Grid className={classes.gridSpacing} item xs={12}>
                                <FormNumberField name='driver.prio' label={translate('Prio')} disabled={loading} required min={0} />
                            </Grid>

                            <Grid className={classes.gridSpacing} item xs={12}>
                                <FormRadioGroup row name='driver.removed'>
                                    <FormRadio label={translate('Not Removed')} value='false' disabled={loading} />
                                    <FormRadio label={translate('Removed')} value='true' disabled={loading} />
                                </FormRadioGroup>
                            </Grid>

                            <Grid className={classes.gridSpacing} item xs={12}>
                                <Button onClick={handleEnableBanControls} disabled={loading}>
                                    {translate('Enable Ban Controls').toUpperCase()}
                                </Button>
                            </Grid>

                            <Grid className={classes.gridSpacing} item xs={12}>
                                <FormRadioGroup row name='driver.banned'>
                                    <FormRadio label={translate('Not Banned')} value='false' disabled={loading || bannedBlock} />
                                    <FormRadio label={translate('Banned')} value='true' disabled={loading || bannedBlock} />
                                </FormRadioGroup>
                            </Grid>
                            {banned === 'true' && (
                                <Grid className={classes.gridSpacing} item xs={12}>
                                    <FormRadioGroup row name='driver.bannedUntilSelect'>
                                        <FormRadio label='Permanent' value='false' disabled={loading || bannedBlock} />
                                        <FormRadio label='For X Time' value='true' disabled={loading || bannedBlock} />
                                    </FormRadioGroup>
                                </Grid>
                            )}
                            {banned === 'true' && bannedUntilSelect === 'true' && (
                                <Grid className={classes.gridSpacing} item xs={12}>
                                    <FormNumberField name='driver.bannedUntil' label={translate('Ban For X Minutes')} disabled={loading} min={0} />
                                </Grid>
                            )}
                        </>
                    )}
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} disabled={loading}>
                        {translate('Cancel').toUpperCase()}
                    </Button>
                    <Button color='primary' type='submit' disabled={loading}>
                        {loading ? translate('Changing').toUpperCase() : translate('Change').toUpperCase()}
                    </Button>
                </DialogActions>
                {loading && <LinearProgress className={classes.linearProgress} />}
            </Form>
        </Dialog>
    );
}

const useStyles = makeStyles((theme) => ({
    linearProgress: {
        position: 'absolute',
        width: '100%',
        bottom: 0,
    },
    gridSpacing: {
        marginBottom: '20px',
    },
}));

export const CHANGE_DRIVER = 'change-driver';
const INITIAL_STATE_DRIVER = {
    driverId: '' as any,
    city: undefined,
    email: '',
    firstName: '',
    lastName: '',
    businessName: '',
    curp: '',
    bank: undefined,
    clabe: '',
    rfc: '',
    comments: '',
    verified: 'false',
    appPaymentsEnabled: 'false',
    cashPaymentsEnabled: 'false',
    prio: 0,
    removed: 'false',
    banned: 'false',
    bannedUntil: 0,
    bannedUntilSelect: 'false',
} as const;

type Props = {
    onDriverChanged?: any;
};
