import { makeStyles } from '@material-ui/core/styles';
import * as React from 'react';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { deprecatedRemoveMenuCategoryApi } from 'src/api/letseatmanager/menuCategory/deprecatedRemoveMenuCategoryApi';
import { app2 } from 'src/app2';
import { Button } from 'src/components/Button';
import { Dialog } from 'src/components/Dialog';
import { DialogActions } from 'src/components/DialogActions';
import { Form } from 'src/components/form/Form';
import { Text } from 'src/components/Text';
import { translate } from 'src/i18n/translate';
import { alertKnownErrorOrSomethingWentWrong } from 'src/utils/alert/alertKnownErrorOrSomethingWentWrong';
import { useAction } from 'src/utils/react/useAction';
import { useSelector } from 'src/utils/react/useSelector';

export function RemoveMenuCategoryDialog(): React.ReactElement {
    const form = useForm();
    const classes = useStyles();

    const [loading, setLoading] = useState(false);

    const open = useSelector((state) => state.app2.removeMenuCategoryDialog.open);
    const category = useSelector((state) => state.app2.removeMenuCategoryDialog.category);
    const onSuccess = useSelector((state) => state.app2.removeMenuCategoryDialog.onSuccess);

    const closeRemoveMenuCategoryDialog = useAction(app2.actions.closeRemoveMenuCategoryDialog);

    const handleClose = () => {
        if (loading) return;
        closeRemoveMenuCategoryDialog();
    };

    const handleSubmit = async () => {
        setLoading(true);
        const response = await deprecatedRemoveMenuCategoryApi({
            menuCategoryId: category.menuCategoryId,
        });
        if (!response.ok) {
            setLoading(false);
            alertKnownErrorOrSomethingWentWrong(response);
            return;
        }
        setLoading(false);
        onSuccess();
        closeRemoveMenuCategoryDialog();
    };

    return (
        <Dialog title={translate('Are you sure you want to remove category?')} open={open} onClose={handleClose}>
            <Form onSubmit={handleSubmit} form={form} className={classes.form}>
                <Text className={classes.name} variant={'semiBold'} size={'large'}>
                    {category?.name}
                </Text>
                <DialogActions>
                    <Button secondary onClick={handleClose} disabled={loading}>
                        {translate('Cancel')}
                    </Button>
                    <Button type='submit' disabled={loading}>
                        {loading ? translate('REMOVING') : translate('REMOVE')}
                    </Button>
                </DialogActions>
            </Form>
        </Dialog>
    );
}

const useStyles = makeStyles((theme) => ({
    form: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    menu: {
        fontFamily: theme.typography.medium,
    },
    name: {
        color: theme.palette.text.brand,
        fontSize: 18,
    },
}));
