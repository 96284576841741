export const PaymentTerminalPaymentFailedReasons = {
    UNKNOWN: 'UNKNOWN',
    UNCATEGORIZED: 'UNCATEGORIZED',
    TERMINAL_NOT_FOUND: 'TERMINAL_NOT_FOUND',
    TERMINAL_BUSY: 'TERMINAL_BUSY',
    PAYMENT_CANCELLED: 'PAYMENT_CANCELLED',
    PAYMENT_TIMED_OUT: 'PAYMENT_TIMED_OUT',
} as const;

export type PaymentTerminalPaymentFailedReason = (typeof PaymentTerminalPaymentFailedReasons)[keyof typeof PaymentTerminalPaymentFailedReasons];
