import { letseatadminApiMethod } from 'src/api/letseatadminApi';
import type { ApiSauceResponse } from 'src/api/types/ApiSauceResponse';
import { CustomAlertDialogType } from 'src/constants/CustomAlertDialogType';

export async function createCustomAlertDialogApi(request: CreateCustomAlertDialogApiRequest): ApiSauceResponse<void> {
    return letseatadminApiMethod('customAlertDialogs/createCustomAlertDialogApi', request);
}

type CreateCustomAlertDialogApiRequest = {
    title: string;
    text: string;
    secondsToAccept: string;
    customAlertDialogType: CustomAlertDialogType;
};
