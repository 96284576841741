import { makeStyles } from '@material-ui/core';
import * as React from 'react';
import { Day } from 'src/constants/Day';
import { OpeningHoursForDay } from 'src/scenes/letseatmanager/openingHours/OpeningHoursForDay';

export function OpeningHoursForDays(): React.ReactElement {
    const classes = useStyles();

    return (
        <div className={classes.container}>
            {days.map((day) => (
                <OpeningHoursForDay key={day} day={day} />
            ))}
        </div>
    );
}

const days: Array<Day> = ['Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa', 'Su'];

const useStyles = makeStyles((theme) => ({
    container: {
        display: 'flex',
        flexDirection: 'column',
        marginBottom: 20,
        width: '100%',
        gap: 20,
        marginTop: 20,
    },
}));
