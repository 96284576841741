import { InitialRoutePath, InitialRoutePaths } from 'src/constants/InitialRoutePaths';
import { RolePermission, RolePermissions } from 'src/constants/RolePermission';
import { translate } from 'src/i18n/translate';

export function validateRole(rolePermissions: Array<RolePermission>, initialPage: InitialRoutePath): ValidatedRole {
    const isInitialPageValid = validateInitialPageRolePermissions(rolePermissions, initialPage);
    if (!isInitialPageValid) {
        return {
            errorMessage: translate('The initial page @initialPage needs the @rolePermission permission', {
                initialPage: translate(`InitialRoutePaths.${initialPage}`),
                rolePermission: translate(`RolePermissions.${ROLE_PERMISSIONS_BY_INITIAL_PAGE[initialPage]}`),
            }),
            valid: false,
        };
    }

    const roleHasPermissions = validateRoleHasPermissions(rolePermissions);
    if (!roleHasPermissions) {
        return {
            errorMessage: translate('The role has to have at least one permission'),
            valid: false,
        };
    }

    return {
        valid: true,
    };
}

export function validateInitialPageRolePermissions(rolePermissions: Array<RolePermission>, initialPage: InitialRoutePath): boolean {
    return rolePermissions.includes(ROLE_PERMISSIONS_BY_INITIAL_PAGE[initialPage]);
}

export function validateRoleHasPermissions(rolePermissions: Array<RolePermission>): boolean {
    return rolePermissions.length > 0;
}

const ROLE_PERMISSIONS_BY_INITIAL_PAGE = {
    [InitialRoutePaths.RESTAURANT_DASHBOARD]: RolePermissions.REPORTS_PAGE,
    [InitialRoutePaths.ONGOING_ORDERS]: RolePermissions.PENDING_ORDERS_PAGE,
    [InitialRoutePaths.ORDERS]: RolePermissions.ALL_ORDERS_PAGE,
    [InitialRoutePaths.POS]: RolePermissions.POS_PAGE,
    [InitialRoutePaths.POS_TABLES]: RolePermissions.RESTAURANT_ZONES_PAGE,
    [InitialRoutePaths.POS_CASH_REGISTERS]: RolePermissions.CASH_REGISTER_PAGE,
    [InitialRoutePaths.THE_RESTAURANT]: RolePermissions.RESTAURANT_PAGE,
    [InitialRoutePaths.THE_MENU]: RolePermissions.MENU_EDIT_PAGE,
    [InitialRoutePaths.INVENTORY]: RolePermissions.INVENTORY_PAGE,
    [InitialRoutePaths.DELIVERY_ZONES]: RolePermissions.DELIVERY_ZONES_PAGE,
    [InitialRoutePaths.ORDER_INVOICES]: RolePermissions.ORDER_INVOICES_PAGE,
    [InitialRoutePaths.PROMO_CODES]: RolePermissions.PROMO_CODES_PAGE,
    [InitialRoutePaths.SURVEYS]: RolePermissions.SURVEYS_PAGE,
    [InitialRoutePaths.PAYMENT_LINKS]: RolePermissions.PAYMENT_LINKS_PAGE,
    [InitialRoutePaths.MARKETING]: RolePermissions.MARKETING_PAGE,
    [InitialRoutePaths.ADVANCED_REPORTS]: RolePermissions.ADVANCED_REPORTS_PAGE,
    [InitialRoutePaths.PROMOTIONS]: RolePermissions.PROMOTIONS_PAGE,
} as const;

type ValidatedRole = {
    errorMessage?: string;
    valid: boolean;
};
