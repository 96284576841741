import * as React from 'react';

export function KioskCircleIcon({ title, width, height }: Props): React.ReactElement {
    return (
        <svg xmlns='http://www.w3.org/2000/svg' width={width || '40'} height={height || '40'} viewBox={`0 0 41 41`} fill='none'>
            <circle cx='20' cy='20' r='19.25' fill='#133547' stroke='white' strokeWidth='1.5' />
            {title && <title>{title}</title>}
            <path
                fillRule='evenodd'
                clipRule='evenodd'
                d='M15.5635 9.51883C15.8957 9.18663 16.3463 9 16.8161 9H23.016C23.4858 9 23.9364 9.18663 24.2686 9.51883C24.6008 9.85103 24.7874 10.3016 24.7874 10.7714V19.6284C24.7874 20.0982 24.6008 20.5488 24.2686 20.881C23.9364 21.2132 23.4858 21.3998 23.016 21.3998H16.8161C16.3463 21.3998 15.8957 21.2132 15.5635 20.881C15.2313 20.5488 15.0447 20.0982 15.0447 19.6284V10.7714C15.0447 10.3016 15.2313 9.85103 15.5635 9.51883ZM16.8161 9.8857C16.5812 9.8857 16.3559 9.97902 16.1898 10.1451C16.0237 10.3112 15.9304 10.5365 15.9304 10.7714V19.6284C15.9304 19.8633 16.0237 20.0886 16.1898 20.2547C16.3559 20.4208 16.5812 20.5141 16.8161 20.5141H23.016C23.2509 20.5141 23.4762 20.4208 23.6423 20.2547C23.8084 20.0886 23.9017 19.8633 23.9017 19.6284V10.7714C23.9017 10.5365 23.8084 10.3112 23.6423 10.1451C23.4762 9.97902 23.2509 9.8857 23.016 9.8857H16.8161ZM18.5875 19.6284C18.5875 19.3838 18.7858 19.1856 19.0304 19.1856H20.8018C21.0463 19.1856 21.2446 19.3838 21.2446 19.6284C21.2446 19.873 21.0463 20.0713 20.8018 20.0713H19.0304C18.7858 20.0713 18.5875 19.873 18.5875 19.6284Z'
                fill='white'
            />
            <path d='M13.7138 29.9616L11.8078 27.758V29.9616H11V25.0371H11.8078V27.2832L13.7209 25.0371H14.7341L12.5943 27.5029L14.7695 29.9616H13.7138Z' fill='white' />
            <path
                d='M15.8948 25.5402C15.7484 25.5402 15.6256 25.4906 15.5264 25.3914C15.4272 25.2922 15.3776 25.1694 15.3776 25.0229C15.3776 24.8765 15.4272 24.7537 15.5264 24.6545C15.6256 24.5553 15.7484 24.5057 15.8948 24.5057C16.0365 24.5057 16.157 24.5553 16.2562 24.6545C16.3554 24.7537 16.405 24.8765 16.405 25.0229C16.405 25.1694 16.3554 25.2922 16.2562 25.3914C16.157 25.4906 16.0365 25.5402 15.8948 25.5402ZM16.2916 26.0574V29.9616H15.4839V26.0574H16.2916Z'
                fill='white'
            />
            <path
                d='M19.0456 30.0254C18.6771 30.0254 18.3441 29.9427 18.0465 29.7774C17.7489 29.6073 17.5151 29.3711 17.3451 29.0688C17.175 28.7618 17.09 28.4075 17.09 28.006C17.09 27.6092 17.1774 27.2573 17.3521 26.9502C17.5269 26.6432 17.7655 26.407 18.0678 26.2417C18.3701 26.0763 18.7079 25.9937 19.081 25.9937C19.4542 25.9937 19.792 26.0763 20.0943 26.2417C20.3966 26.407 20.6351 26.6432 20.8099 26.9502C20.9847 27.2573 21.0721 27.6092 21.0721 28.006C21.0721 28.4028 20.9823 28.7547 20.8028 29.0617C20.6233 29.3688 20.3777 29.6073 20.0659 29.7774C19.7589 29.9427 19.4188 30.0254 19.0456 30.0254ZM19.0456 29.3239C19.2534 29.3239 19.4471 29.2743 19.6266 29.1751C19.8108 29.0759 19.9596 28.9271 20.073 28.7287C20.1864 28.5303 20.2431 28.2894 20.2431 28.006C20.2431 27.7225 20.1887 27.484 20.0801 27.2903C19.9715 27.0919 19.8274 26.9431 19.6479 26.8439C19.4684 26.7447 19.2747 26.6951 19.0669 26.6951C18.859 26.6951 18.6653 26.7447 18.4858 26.8439C18.3111 26.9431 18.1717 27.0919 18.0678 27.2903C17.9639 27.484 17.9119 27.7225 17.9119 28.006C17.9119 28.4264 18.0182 28.7523 18.2308 28.9838C18.448 29.2105 18.7197 29.3239 19.0456 29.3239Z'
                fill='white'
            />
            <path
                d='M23.2877 30.0254C22.9807 30.0254 22.7043 29.971 22.4587 29.8624C22.2178 29.749 22.0265 29.5979 21.8848 29.4089C21.7431 29.2152 21.6675 29.0003 21.658 28.7641H22.4941C22.5083 28.9295 22.5862 29.0688 22.728 29.1822C22.8744 29.2908 23.0563 29.3452 23.2736 29.3452C23.5003 29.3452 23.6751 29.3026 23.7979 29.2176C23.9254 29.1279 23.9892 29.0145 23.9892 28.8775C23.9892 28.7311 23.9183 28.6224 23.7766 28.5516C23.6396 28.4807 23.42 28.4028 23.1177 28.3177C22.8248 28.2374 22.5862 28.1595 22.402 28.0839C22.2178 28.0083 22.0572 27.8926 21.9202 27.7367C21.7879 27.5808 21.7218 27.3754 21.7218 27.1203C21.7218 26.9124 21.7832 26.7235 21.906 26.5534C22.0288 26.3786 22.2036 26.2417 22.4304 26.1425C22.6618 26.0433 22.9264 25.9937 23.224 25.9937C23.668 25.9937 24.0246 26.107 24.2939 26.3338C24.5679 26.5558 24.7143 26.8605 24.7332 27.2478H23.9254C23.9113 27.073 23.8404 26.9337 23.7129 26.8298C23.5853 26.7258 23.4129 26.6739 23.1956 26.6739C22.983 26.6739 22.8201 26.714 22.7067 26.7943C22.5933 26.8746 22.5366 26.9809 22.5366 27.1132C22.5366 27.2171 22.5744 27.3045 22.65 27.3754C22.7256 27.4462 22.8177 27.5029 22.9264 27.5454C23.035 27.5832 23.1956 27.6328 23.4082 27.6942C23.6916 27.7698 23.9231 27.8477 24.1026 27.928C24.2868 28.0036 24.445 28.117 24.5773 28.2681C24.7096 28.4193 24.7781 28.6201 24.7828 28.8704C24.7828 29.0924 24.7214 29.2908 24.5986 29.4656C24.4757 29.6404 24.301 29.7774 24.0742 29.8766C23.8522 29.9758 23.59 30.0254 23.2877 30.0254Z'
                fill='white'
            />
            <path d='M27.2003 28.0131L29 29.9616H27.9088L26.4634 28.2823V29.9616H25.6556V24.7182H26.4634V27.7651L27.8805 26.0574H29L27.2003 28.0131Z' fill='white' />
        </svg>
    );
}

type Props = {
    title?: string;
    width?: number;
    height?: number;
};
