import { letseatadminApiMethod } from 'src/api/letseatadminApi';
import type { ApiSauceResponse } from 'src/api/types/ApiSauceResponse';
import type { RestaurantId } from 'src/types/Id';

export async function tribalExportFormatUyApi(request: TribalExportFormatUyApiRequest): ApiSauceResponse<TribalExportFormatResultUyVm> {
    return letseatadminApiMethod('restaurantBillingUy/tribalExportFormatUyApi', request);
}

type TribalExportFormatUyApiRequest = {
    from: Date;
    to: Date;
    restaurantIds: Array<RestaurantId>;
};

export type TribalExportFormatResultUyVm = {
    tribalExportFormat: string;
    exported: number;
    removed: number;
    nothingToTransfer: number;
};
