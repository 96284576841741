import { useEffect, useState } from 'react';
import * as React from 'react';
import { useLocation } from 'react-router-dom';
import { AlertDialog } from 'src/components/AlertDialog';
import { Button } from 'src/components/Button';
import { RolePermissions } from 'src/constants/RolePermission';
import { RoutePaths } from 'src/constants/RoutePath';
import { translate } from 'src/i18n/translate';
import { useFindOpenedUserCashRegisterPosBusinessDay } from 'src/services/cashRegister/useFindOpenedUserCashRegisterPosBusinessDay';
import { useHasOpenedCashRegister } from 'src/services/cashRegister/useHasOpenedCashRegister';
import { useManageOpenCashRegister } from 'src/services/cashRegister/useManageOpenCashRegister';
import { isWithinHours } from 'src/utils/isWithInHours';
import { useSelector } from 'src/utils/react/useSelector';
import { useUserHasRolePermission } from 'src/utils/react/useUserHasRolePermissions';

export function CashRegisterClosedAlert(): React.ReactElement | null {
    const location = useLocation();

    const [userHasRolePermission] = useUserHasRolePermission();
    const { findOpenedUserCashRegisterPosBusinessDay } = useFindOpenedUserCashRegisterPosBusinessDay();
    const [shouldRenderClosedCashRegisterAlert, setShouldRenderClosedCashRegisterAlert] = useState(false);
    const hasOpenedCashRegister = useHasOpenedCashRegister();
    const { manageOpenCashRegister } = useManageOpenCashRegister();

    const restaurant = useSelector((state) => state.app.restaurant);
    const restaurantId = useSelector((state) => state.app.restaurantId);
    const posEnabled = useSelector((state) => state.app.restaurant?.posEnabled);
    const posMultipleCashRegistersEnabled = useSelector((state) => state.app.restaurant?.posMultipleCashRegistersEnabled);
    const pinCodeUserSignedIn = useSelector((state) => state.authentication.pinCodeUserSignedIn);
    const userManagementEnabled = useSelector((state) => state.app.restaurant.userManagementEnabled);
    const openedPosBusinessDay = useSelector((state) => state.pos.openedPosBusinessDay);

    const isStoreOpened = isWithinHours(restaurant.hours, restaurant.timeZone);
    const isRouteToHideAlert = (location.pathname === RoutePaths?.POS && !pinCodeUserSignedIn && userManagementEnabled) || location.pathname === RoutePaths.THE_RESTAURANT;
    const hideAlert = isRouteToHideAlert || (posMultipleCashRegistersEnabled && !shouldRenderClosedCashRegisterAlert);
    const showCashRegisterClosedAlert = !!restaurantId && posEnabled && isStoreOpened && !hasOpenedCashRegister && !hideAlert;
    const hasManageCashRegisterPermission = userHasRolePermission(RolePermissions.MANAGE_CASH_REGISTERS);
    const hasOpenPersonalCashRegisterPermission = userHasRolePermission(RolePermissions.OPEN_PERSONAL_CASH_REGISTER);
    const hasReadOnlyCashRegisterPermission = !hasOpenPersonalCashRegisterPermission && !hasManageCashRegisterPermission && posMultipleCashRegistersEnabled;

    useEffect(() => {
        if (!pinCodeUserSignedIn) setShouldRenderClosedCashRegisterAlert(false);

        if (pinCodeUserSignedIn) {
            setShouldRenderClosedCashRegisterAlert(
                (hasOpenPersonalCashRegisterPermission && !findOpenedUserCashRegisterPosBusinessDay()) || (hasReadOnlyCashRegisterPermission && !openedPosBusinessDay),
            );
        }
    }, [pinCodeUserSignedIn]);

    const handleOpenCashRegister = async () => {
        manageOpenCashRegister({ forceOpenCashRegister: false });
    };

    if (!showCashRegisterClosedAlert) return null;

    return (
        <div>
            <AlertDialog open={showCashRegisterClosedAlert} title={translate('The cash register is not opened')} severity={'warning'}>
                <Button onClick={handleOpenCashRegister} secondary>
                    {translate('Open Cash Register')}
                </Button>
            </AlertDialog>
        </div>
    );
}
