import { InputLabel, makeStyles } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import DeleteIcon from '@material-ui/icons/Delete';
import { BigNumber } from 'bignumber.js';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { useForm, useWatch } from 'react-hook-form';
import { changeRecipeApi } from 'src/api/letseatmanager/recipe/changeRecipeApi';
import { getRecipeApi } from 'src/api/letseatmanager/recipe/getRecipeApi';
import { Button } from 'src/components/Button';
import { Dialog } from 'src/components/Dialog';
import { DialogActions } from 'src/components/DialogActions';
import { Form } from 'src/components/form/Form';
import { FormCheckbox } from 'src/components/form/FormCheckbox';
import { FormFixErrorHint } from 'src/components/form/FormFixErrorHint';
import { FormMenuItemsRecipeAutocomplete } from 'src/components/form/FormMenuItemsRecipeAutocomplete';
import { FormNumberStringField } from 'src/components/form/FormNumberStringField';
import { FormPercentNumberField } from 'src/components/form/FormPercentNumberField';
import { FormSupplyAutocomplete } from 'src/components/form/FormSupplyAutocomplete';
import { FormTextField } from 'src/components/form/FormTextField';
import { UpdatingContentProgress } from 'src/components/UpdatingContentProgress';
import { OrderType, OrderTypes } from 'src/constants/OrderType';
import { translate } from 'src/i18n/translate';
import { useFormatAsRestaurantCurrencyNumber } from 'src/services/restaurant/useFormatAsRestaurantCurrencyNumber';
import { MenuItemId, SupplyId, type RecipeId } from 'src/types/Id';
import type { SupplyVm } from 'src/types/SupplyVm';
import { alertKnownErrorOrSomethingWentWrong } from 'src/utils/alert/alertKnownErrorOrSomethingWentWrong';
import { useSelector } from 'src/utils/react/useSelector';
import { requireValue } from 'src/utils/require/requireValue';

export function ChangeRecipeDialog({ open, recipeId, onClose, onChangeRecipe }: Props): React.ReactElement {
    const form = useForm();
    const formatAsCurrencyNumber = useFormatAsRestaurantCurrencyNumber();
    const {
        formState: { isSubmitting },
        control,
    } = form;
    const classes = useStyles();

    const [currentSupply, setCurrentSupply] = useState<SupplyVm>();
    const [recipeSupplies, setRecipeSupplies] = useState<
        Array<{
            supply: SupplyVm;
            quantity: string;
            excludedOrderTypes?: Array<OrderType>;
            waste?: number;
        }>
    >([]);
    const [loading, setLoading] = useState(false);
    const [unitOfMeasurement, setUnitOfMeasurement] = useState('KILOGRAM');

    const supplyQuantity = useWatch<string>({ name: 'supplyQuantity', control });
    const waste = useWatch<number>({ name: 'waste', control });
    const menuItemIds = useWatch<Array<MenuItemId>>({ name: 'menuItemIds', control });
    const excludeSupplyOnTakeAway = useWatch({ name: 'excludeSupplyOnTakeAway', control });
    const excludeSupplyOnTableOrder = useWatch({ name: 'excludeSupplyOnTableOrder', control });
    const excludeSupplyOnDeliveryOrder = useWatch({ name: 'excludeSupplyOnDeliveryOrder', control });

    const restaurantId = useSelector((state) => state.app.restaurantId);
    const currencyFormat = useSelector((state) => state.app.restaurant?.currencyFormat);

    useEffect(() => {
        if (open) load();
    }, [open]);

    useEffect(() => {
        if (recipeSupplies.length) {
            let estimatedPrice = BigNumber(0);
            for (const recipeSupply of recipeSupplies) {
                const { buyUnits, fixedCost } = recipeSupply.supply;

                const mainUnit = buyUnits.find((buyUnit) => buyUnit.isMainUnit);
                const priceUnit = BigNumber(fixedCost).dividedBy(mainUnit?.conversionFactor ?? 1);

                const totalUsed = BigNumber(recipeSupply.quantity);

                const estimatedPriceOfSupply = BigNumber(priceUnit).multipliedBy(totalUsed).toFixed(2);
                estimatedPrice = estimatedPrice.plus(estimatedPriceOfSupply);
            }
            form.setValue('estimatedPrice', estimatedPrice.toString());
        } else {
            form.setValue('estimatedPrice', '0');
        }
    }, [recipeSupplies]);

    const handleClose = () => {
        if (isSubmitting) return;
        setCurrentSupply(undefined);
        setRecipeSupplies([]);
        onClose();
    };

    const load = async () => {
        setLoading(true);
        const response = await getRecipeApi({ restaurantId, recipeId: requireValue(recipeId) });
        if (!response.ok) {
            setLoading(false);
            alertKnownErrorOrSomethingWentWrong(response);
            return;
        }
        const recipe = response.data;

        form.reset(recipe);
        setRecipeSupplies(recipe.ingredients);
        setLoading(false);
    };

    const onSubmit = async (form: any) => {
        setLoading(true);
        const response = await changeRecipeApi({
            recipeId: requireValue(recipeId),
            menuItemIds: form.menuItemIds,
            name: form.name,
            ingredients: recipeSupplies.map((recipeSupply) => ({
                supplyId: recipeSupply.supply.supplyId,
                quantity: recipeSupply.quantity,
                excludedOrderTypes: recipeSupply.excludedOrderTypes,
                waste: recipeSupply.waste,
            })),
            estimatedPrice: form.estimatedPrice,
        });
        setLoading(false);
        if (!response.ok) {
            alertKnownErrorOrSomethingWentWrong(response);
            return;
        }
        onClose();
        onChangeRecipe();
    };

    const addRecipeSupply = () => {
        if (!currentSupply || !supplyQuantity) return;
        if (recipeSupplies.some((recipeSupply) => recipeSupply.supply.supplyId === currentSupply.supplyId)) return;

        const excludedOrderTypes: Array<OrderType> = [
            ...(excludeSupplyOnTakeAway ? [OrderTypes.TAKE_AWAY_ORDER] : []),
            ...(excludeSupplyOnTableOrder ? [OrderTypes.TABLE_ORDER] : []),
            ...(excludeSupplyOnDeliveryOrder ? [OrderTypes.DELIVERY_ORDER] : []),
        ];

        setRecipeSupplies([...recipeSupplies, { supply: currentSupply, quantity: supplyQuantity, excludedOrderTypes: excludedOrderTypes.length > 0 ? excludedOrderTypes : undefined, waste }]);
        form.setValue('supply', undefined);
        form.setValue('supplyQuantity', undefined);
        form.setValue('waste', undefined);
    };

    const removeRecipeSupply = (supplyId: SupplyId) => {
        setRecipeSupplies(recipeSupplies.filter((recipeSupply) => recipeSupply.supply.supplyId !== supplyId));
    };

    const onSupplyChange = (supply: any) => {
        setCurrentSupply(supply);
        setUnitOfMeasurement(supply.sellUnit);
    };

    const getWasteLabel = (waste: number) => {
        if (!waste) return;
        if (waste % 1 !== 0) return ` ${waste * 100}% ${translate('Waste')}`;
        return ` ${waste}% ${translate('Waste')}`;
    };

    return (
        <Dialog open={open} onClose={handleClose}>
            <UpdatingContentProgress loading={loading} bottom />
            <Form form={form} onSubmit={onSubmit}>
                <Grid container spacing={6}>
                    <Grid item xs={12} md={6}>
                        <h1 className={classes.title}>{translate('Recipe')}</h1>
                        <Grid container spacing={3}>
                            <Grid item xs={12}>
                                <FormTextField name='name' label={translate('Name')} />
                            </Grid>
                            {recipeId && (
                                <Grid item xs={12}>
                                    <FormMenuItemsRecipeAutocomplete name='menuItemIds' label={translate('Menu Products')} defaultValue={menuItemIds} recipeId={recipeId} />
                                </Grid>
                            )}

                            <Grid item xs={12}>
                                <FormNumberStringField name='estimatedPrice' label={translate('Estimated Cost')} disabled />
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <h1 className={classes.title}>{translate('Ingredients')}</h1>
                        <Grid container spacing={3}>
                            <Grid item xs={12}>
                                <FormSupplyAutocomplete name='supply' label={translate('Supply')} disabled={loading} onChange={onSupplyChange} />
                            </Grid>
                            <Grid item xs={12} md={4}>
                                <FormNumberStringField name='supplyQuantity' label={translate('Quantity') + ` (${translate('UnitsOfMeasurement.' + unitOfMeasurement)})`} />
                            </Grid>
                            <Grid item xs={12} md={4}>
                                <FormPercentNumberField name='waste' label={translate('Waste')} />
                            </Grid>
                            <Grid item xs={12}>
                                <InputLabel classes={{ root: classes.labelOmitSupply }}>{translate('Omit supply in orders')}</InputLabel>
                                <FormCheckbox name='excludeSupplyOnTakeAway' label={translate('Take out')} />
                                <FormCheckbox name='excludeSupplyOnTableOrder' label={translate('Eat here')} />
                                <FormCheckbox name='excludeSupplyOnDeliveryOrder' label={translate('Delivery')} />
                            </Grid>

                            <Grid item xs={12} md={2}>
                                <Button onClick={addRecipeSupply}>{translate('Add')}</Button>
                            </Grid>
                            <Grid item xs={12}>
                                {recipeSupplies.map((recipeSupply) => {
                                    const { buyUnits, fixedCost } = recipeSupply.supply;

                                    const mainUnit = buyUnits.find((buyUnit) => buyUnit.isMainUnit);
                                    const priceUnit = BigNumber(fixedCost).dividedBy(mainUnit?.conversionFactor ?? 1);

                                    const totalUsed = BigNumber(recipeSupply.quantity);

                                    const estimatedPriceOfSupply = BigNumber(priceUnit).multipliedBy(totalUsed).toFixed(2);
                                    return (
                                        <div>
                                            <div className={recipeSupply.excludedOrderTypes ? classes.supplyContainer : classes.supplyContainerWithBorderBottom} key={recipeSupply.supply.supplyId}>
                                                <span className={classes.recipeSupplyName}>
                                                    {recipeSupply.quantity} {recipeSupply.supply.sellUnit} | {recipeSupply.supply.name}
                                                </span>
                                                <span className={classes.recipeSupplyPrice}>{formatAsCurrencyNumber(estimatedPriceOfSupply)}</span>
                                                {recipeSupply.waste && <span className={classes.waste}>{getWasteLabel(recipeSupply.waste)}</span>}

                                                <div className={classes.recipeSupplyButton}>
                                                    <Button icon onClick={() => removeRecipeSupply(recipeSupply.supply.supplyId)}>
                                                        <DeleteIcon />
                                                    </Button>
                                                </div>
                                            </div>
                                            {recipeSupply.excludedOrderTypes && (
                                                <div className={classes.supplyContainerWithBorderBottom} key={recipeSupply.supply.supplyId}>
                                                    <span className={classes.recipeSupplyExcludedOrderTypes}>{translate('Omitted in order types')}</span>
                                                    &nbsp;
                                                    <span className={classes.recipeSupplyExcludedOrderTypesBold}>
                                                        {recipeSupply.excludedOrderTypes?.map((orderType: OrderType) => translate(orderType)).join(', ')}
                                                    </span>
                                                </div>
                                            )}
                                        </div>
                                    );
                                })}
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                <FormFixErrorHint />

                <DialogActions>
                    <Button secondary onClick={handleClose} disabled={isSubmitting || loading}>
                        {translate('Cancel')}
                    </Button>
                    <Button primary type='submit' disabled={isSubmitting || loading}>
                        {isSubmitting ? translate('Changing') : translate('Change')}
                    </Button>
                </DialogActions>
            </Form>
        </Dialog>
    );
}

const useStyles = makeStyles((theme) => ({
    dialogContainer: {
        width: '80%',
        maxWidth: '80%',
        maxHeight: '95%',
        height: 'fit-content',
        position: 'relative',
    },
    title: {
        fontFamily: theme.typography.medium,
        fontSize: 20,
        marginBottom: 50,
    },
    acceptButton: {
        backgroundColor: theme.palette.primary.main,
        color: 'white',
        fontFamily: theme.typography.semiBold,
        fontSize: 15,
        borderRadius: 15,
        height: 30,
        width: 150,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        textTransform: 'none',
    },
    secondaryButton: {
        backgroundColor: 'white',
        color: theme.palette.primary.main,
        border: `1px solid ${theme.palette.primary.main}`,
        fontFamily: theme.typography.semiBold,
        fontSize: 15,
        borderRadius: 15,
        height: 30,
        width: 150,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        textTransform: 'none',
    },
    addSupplyButton: {
        backgroundColor: 'white',
        color: theme.palette.primary.main,
        border: `1px solid ${theme.palette.primary.main}`,
        fontFamily: theme.typography.semiBold,
        fontSize: 15,
        borderRadius: 5,
        height: 30,
        width: '80%',
        padding: '5px 15px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        textTransform: 'none',
    },
    supplyContainer: {
        width: '100%',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        padding: '5px 15px',
    },
    supplyContainerWithBorderBottom: {
        width: '100%',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        padding: '0px 15px',
        borderBottom: `1px solid ${theme.palette.secondary.dark}`,
    },
    recipeSupplyName: {
        fontFamily: theme.typography.regular,
        fontSize: 15,
        width: '50%',
    },
    recipeSupplyExcludedOrderTypes: {
        fontFamily: theme.typography.regular,
        color: theme.palette.primary.dark,
        fontSize: 15,
    },
    recipeSupplyExcludedOrderTypesBold: {
        fontFamily: theme.typography.regular,
        color: theme.palette.primary.dark,
        fontSize: 15,
        fontWeight: 'bold',
    },
    recipeSupplyPrice: {
        fontFamily: theme.typography.regular,
        fontSize: 15,
        width: '20%',
    },
    waste: {
        fontFamily: theme.typography.regular,
        fontSize: 12,
        backgroundColor: theme.palette.secondary.dark,
        borderRadius: 30,
        padding: '8px 12px',
    },
    recipeSupplyButton: {
        fontFamily: theme.typography.regular,
        fontSize: 15,
        width: '10%',
    },
    recipeSupplyExludeOrderTypes: {
        fontFamily: theme.typography.regular,
        fontSize: 15,
        width: '100%',
    },
    labelOmitSupply: {
        fontFamily: theme.typography.regular,
        color: theme.palette.secondary.contrastText,
        fontSize: 14,
        marginBottom: 8,
    },
    absoluteRight: {
        right: 0,
        alignItems: 'right',
    },
    fullWidth: {
        width: '100%',
    },
}));

type Props = {
    open: boolean;
    recipeId: RecipeId | undefined;
    onClose: any;
    onChangeRecipe: any;
};
