import { makeStyles } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import BarChartIcon from '@material-ui/icons/BarChart';
import WhatsAppIcon from '@material-ui/icons/WhatsApp';
import MUIDataTable from 'mui-datatables';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { getRestaurantsSalesApi } from 'src/api/letseatadmin/restaurantSales/getRestaurantsSalesApi';
import { type RestaurantSalesVm } from 'src/api/types/restaurantSales/RestaurantsSales';
import { app2 } from 'src/app2';
import { RefreshToolbarButton } from 'src/components/mui-datatables/RefreshToolbarButton';
import { UpdatingContentProgress } from 'src/components/UpdatingContentProgress';
import { translate } from 'src/i18n/translate';
import { RestaurantSalesDialog } from 'src/scenes/letseatadmin/restaurantSales/RestaurantSalesDialog';
import type { RestaurantId } from 'src/types/Id';
import { alertKnownErrorOrSomethingWentWrong } from 'src/utils/alert/alertKnownErrorOrSomethingWentWrong';
import { useAction } from 'src/utils/react/useAction';
import { useSelector } from 'src/utils/react/useSelector';

export function RestaurantSalesPage(): React.ReactElement {
    const classes = useStyles();
    const viewUser = useSelector((state) => state.authentication.viewUser);

    const [loading, setLoading] = useState(false);
    const [vm, setVm] = useState<{ restaurantsSales: Array<RestaurantSalesVm> }>({ restaurantsSales: [] });
    const [restaurantSalesDialog, setRestaurantSalesDialog] = useState<{
        open: boolean;
        restaurantId?: RestaurantId;
    }>({ open: false, restaurantId: undefined });

    const setTitle = useAction(app2.actions.setTitle);

    useEffect(() => {
        setTitle(translate('Restaurant Sales'));
        load();
    }, []);

    const load = async () => {
        setLoading(true);
        const response = await getRestaurantsSalesApi();
        setLoading(false);
        if (!response.ok) {
            alertKnownErrorOrSomethingWentWrong(response);
            return;
        }
        setVm(response.data);
    };

    const handleOpenDialog = (restaurantId: RestaurantId) => {
        setRestaurantSalesDialog({ open: true, restaurantId: restaurantId });
    };

    function openWhatsappInAnotherPage(phoneNumber: string): void {
        if (!phoneNumber) return;
        window.open(`https://wa.me/${phoneNumber.toString()}?text=Hola!%20Nos%20Comunicamos%20de%20Let's%20Eat...`);
    }

    return (
        <div className={classes.container}>
            <UpdatingContentProgress loading={loading} />
            <RestaurantSalesDialog
                open={restaurantSalesDialog.open}
                restaurantId={restaurantSalesDialog.restaurantId}
                onClose={() => setRestaurantSalesDialog({ open: false, restaurantId: undefined })}
            />

            <MUIDataTable
                /* @ts-ignore */
                className={classes.table}
                data={vm.restaurantsSales?.map((restaurantSales: RestaurantSalesVm) => ({
                    restaurantId: restaurantSales.restaurantId,
                    // restaurantName: restaurantSales.restaurantName,
                    // restaurantPhoneNumber: restaurantSales.restaurantPhoneNumber,
                    // growthLastWeek: restaurantSales.growthLastWeek,
                    // lastOrderAt: formatDateStringReadable(restaurantSales.lastOrderAt, TimeZones.AMERICA_MONTERREY),
                }))}
                columns={[
                    {
                        name: 'restaurantId',
                        label: '',
                        options: {
                            display: 'excluded',
                            filter: false,
                            searchable: true,
                            sort: false,
                        },
                    },
                    {
                        name: 'restaurantName',
                        label: translate('Name'),
                        options: {
                            filter: false,
                        },
                    },
                    {
                        name: 'restaurantPhoneNumber',
                        label: translate('Phone Number'),
                        options: {
                            filter: false,
                        },
                    },
                    {
                        name: 'growthLastWeek',
                        label: translate('Growth Last Week'),
                        options: {
                            filter: false,
                        },
                    },
                    {
                        name: 'lastOrderAt',
                        label: translate('Date Last Order'),
                        options: {
                            filter: false,
                        },
                    },
                    {
                        name: 'restaurantId',
                        label: 'Sales History',
                        options: {
                            filter: false,
                            searchable: true,
                            sort: false,
                            customBodyRender: (restaurantId) => {
                                return (
                                    <IconButton color='primary' onClick={() => handleOpenDialog(restaurantId)}>
                                        <BarChartIcon fontSize='small' />
                                    </IconButton>
                                );
                            },
                            setCellProps: () => {
                                return {
                                    style: { paddingLeft: 8, paddingRight: 0 },
                                };
                            },
                        },
                    },
                    {
                        name: 'restaurantPhoneNumber',
                        label: 'Send Whatsapp',
                        options: {
                            filter: false,
                            searchable: true,
                            sort: false,
                            customBodyRender: (phoneNumber) => {
                                return (
                                    <IconButton color='primary' onClick={() => openWhatsappInAnotherPage(phoneNumber)} disabled={viewUser}>
                                        <WhatsAppIcon fontSize='small' />
                                    </IconButton>
                                );
                            },
                            setCellProps: () => {
                                return {
                                    style: { paddingLeft: 8, paddingRight: 0 },
                                };
                            },
                        },
                    },
                ]}
                options={{
                    responsive: 'standard',
                    tableBodyMaxHeight: '500px',
                    selectableRows: !viewUser ? 'multiple' : 'none',
                    filterType: 'checkbox',
                    rowsPerPage: 100,
                    customToolbar: () => (
                        <>
                            <RefreshToolbarButton onClick={load} />
                        </>
                    ),
                    // filterList: [[], [], [], [], ['MEXICO']],
                }}
            />
        </div>
    );
}

const useStyles = makeStyles((theme) => ({
    container: {
        position: 'relative',
    },
    table: {
        whiteSpace: 'nowrap',
        '& tr': { cursor: 'pointer' },
        '& tr:hover': { backgroundColor: 'rgba(0, 0, 0, 0.07) !important' },
    },
    toolbar: {
        paddingRight: theme.spacing(3),
    },
}));
