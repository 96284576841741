import { letseatadminApiMethod } from 'src/api/letseatadminApi';
import type { ApiSauceResponse } from 'src/api/types/ApiSauceResponse';
import type { SubscriptionHistoryId } from 'src/types/Id';

export async function changeSubscriptionHistoryStartAndServiceDatesApi(request: GetSubscriptonHistoryApiRequest): ApiSauceResponse<void> {
    return letseatadminApiMethod('subscriptionHistory/changeSubscriptionHistoryStartAndServiceDatesApi', request);
}

export type GetSubscriptonHistoryApiRequest = {
    subscriptionHistoryId: SubscriptionHistoryId;
    startServiceDate: Date;
    endServiceDate: Date;
};
