import { makeStyles, Radio } from '@material-ui/core';
import * as React from 'react';
import { useFormContext } from 'react-hook-form';
import { FormSwitch } from 'src/components/form/FormSwitch';
import { InitialRoutePaths } from 'src/constants/InitialRoutePaths';
import { RolePermissions } from 'src/constants/RolePermission';
import { translate } from 'src/i18n/translate';

export function SurveysAccessConfiguration({ disabled }: Props): React.ReactElement {
    const classes = useStyles();
    const { watch, setValue } = useFormContext();

    const initialPage = watch('initialPage');

    const handleRadioButton = () => setValue('initialPage', InitialRoutePaths.SURVEYS);

    return (
        <div className={classes.container}>
            <div className={classes.moduleContainer}>{translate('Surveys')}</div>
            <div className={classes.permissionsContainer}>
                <FormSwitch name={RolePermissions.SURVEYS_PAGE} label={translate('RolePermissions.SURVEYS_PAGE')} disabled={disabled} />
            </div>
            <div className={classes.homeContainer}>
                <Radio
                    name='initialPageSurveys'
                    classes={{ checked: classes.radioButton }}
                    checked={initialPage === InitialRoutePaths.SURVEYS}
                    value={InitialRoutePaths.SURVEYS}
                    onChange={handleRadioButton}
                    disabled={disabled}
                />
            </div>
        </div>
    );
}

const useStyles = makeStyles((theme) => ({
    container: {
        width: '100%',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        padding: 10,
        borderBottom: `1px solid #c8c8c8`,
    },
    moduleContainer: {
        fontFamily: theme.typography.regular,
        fontSize: 15,
        width: '25%',
    },
    permissionsContainer: {
        fontFamily: theme.typography.regular,
        fontSize: 15,
        width: '50%',
        display: 'flex',
        flexDirection: 'column',
        paddingLeft: 10,
    },
    homeContainer: {
        fontFamily: theme.typography.regular,
        fontSize: 15,
        width: '25%',
    },
    radioButton: {
        color: `${theme.palette.primary.main} !important`,
    },
}));

type Props = {
    disabled?: boolean;
};
