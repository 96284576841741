import { useOpenCashRegister } from 'src/services/cashRegister/useOpenCashRegister';
import { useOpenPosBusinessDay } from 'src/services/cashRegister/useOpenPosBusinessDay';
import { useSelector } from 'src/utils/react/useSelector';

export function useManageOpenCashRegister(): {
    manageOpenCashRegister: (arg1: { forceOpenCashRegister: boolean }) => void;
} {
    const { openCashRegister } = useOpenCashRegister();
    const { openPosBusinessDay } = useOpenPosBusinessDay();

    const openedPosBusinessDay = useSelector((state) => state.pos.openedPosBusinessDay);
    const openedCashRegisterPosBusinessDay = useSelector((state) => state.pos.openedCashRegisterPosBusinessDay);
    const posMultipleCashRegistersEnabled = useSelector((state) => state.app.restaurant?.posMultipleCashRegistersEnabled);

    const manageOpenCashRegister = ({ forceOpenCashRegister }: { forceOpenCashRegister: boolean }) => {
        if (!openedPosBusinessDay) {
            openPosBusinessDay({ forceOpenCashRegister });
            return;
        }

        if (!posMultipleCashRegistersEnabled) {
            openCashRegister({ forceOpenCashRegister });
            return;
        }

        if (openedCashRegisterPosBusinessDay) {
            openCashRegister({ forceOpenCashRegister });
            return;
        }

        openPosBusinessDay({ forceOpenCashRegister });
    };

    return { manageOpenCashRegister };
}
