import { BigNumber } from 'bignumber.js';
import { PosState } from 'src/reducers/posReducer';
import { PaymentId } from 'src/types/Id';
import { OrderPaymentVm } from 'src/types/OrderPaymentVm';
import { OrderVm } from 'src/types/OrderVm';
import { newUuid } from 'src/utils/uuid/newUuid';

export function editPendingOrder(state: PosState, { order }: { order?: OrderVm }) {
    if (!order) return;
    state.orderType = order.orderType;
    state.pickupTimeType = order.pickupTimeType;
    state.pickupTime = order.pickupTime;
    state.customerId = order.customerId;
    state.pendingOrder = order;

    if (!!order.usedNonGiftCredits || !!order.usedLetsEatCredits || !!order.usedCredits) {
        state.useLetsEatCredits = true;
        state.usedCustomerCredits = order.usedNonGiftCredits ?? order.usedCredits ?? order.usedLetsEatCredits ?? '';
    }

    if (order.posDiscount && order.posDiscountType) {
        state.discount = {
            discount: order.posDiscount,
            discountPercentage: order.posDiscountPercentage,
            discountType: order.posDiscountType,
            notes: order.posDiscountNotes,
        };
    }

    if (order.tips && order.tips.length) {
        state.tips = order.tips?.map((tip: any) => {
            return {
                tipType: tip.tipType,
                paymentId: tip.paymentId ?? (newUuid() as PaymentId),
                tipPercentage: tip.tipPercentage,
                paymentMethod: tip.paymentMethod,
                customPaymentMethod: tip.customPaymentMethod,
                tipAmount: tip.tipAmount ?? '0',
                customerNumber: tip.customerNumber,
            };
        });
    }

    if (order.promoCodeId && order.promoCode && order.promoCodeApplicableDiscount) {
        state.promoCode = {
            promoCodeId: order.promoCodeId,
            code: order.promoCode,
            rewardType: order.promoCodeRewardType,
            discount: order.promoCodeApplicableDiscount,
            excludeDeliveryCost: order.promoCodeExcludeDeliveryCost,
            freeDelivery: order.promoCodeFreeDelivery,
            discountType: order.promoCodeDiscountType,
            maxDiscountAmount: order.promoCodeMaxDiscountAmount,
            menuItemIds: order.promoCodeMenuItemIds,
        };
    }

    if (order.payments?.length) {
        const totalPaid = order.payments?.reduce((total: BigNumber, posPayment: OrderPaymentVm) => BigNumber(total).plus(posPayment.amount), BigNumber(0)) ?? 0;
        state.totalPaid = totalPaid.toFixed(2);
    }

    state.notes = order.notes;
}
