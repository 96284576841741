import { letseatmanagerApiMethod } from 'src/api/letseatmanagerApi';
import type { ApiSauceResponse } from 'src/api/types/ApiSauceResponse';
import type { MenuId } from 'src/types/Id';

export async function acceptIntegrationMenuApprovalApi(request: AcceptIntegrationMenuApprovalApiRequest): ApiSauceResponse<void> {
    return letseatmanagerApiMethod('menu/acceptIntegrationMenuApprovalApi', request);
}

export type AcceptIntegrationMenuApprovalApiRequest = {
    menuId: MenuId;
};
