import { makeStyles } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { getUberDaasCoverageApi } from 'src/api/letseatadmin/theRestaurant/getUberDaasCoverageApi';
import { Form } from 'src/components/form/Form';
import { FormFixErrorHint } from 'src/components/form/FormFixErrorHint';
import { FormGoogleMapsAddressSearchAutocomplete } from 'src/components/form/FormGoogleMapsAddressSearchAutocomplete';
import { FormSubmitButton } from 'src/components/form/FormSubmitButton';
import { UpdatingContentProgress } from 'src/components/UpdatingContentProgress';
import { translate } from 'src/i18n/translate';
import { alertKnownErrorOrSomethingWentWrong } from 'src/utils/alert/alertKnownErrorOrSomethingWentWrong';
import { useSelector } from 'src/utils/react/useSelector';

export function ThirdPartyLogisticsCoveragePage(): React.ReactElement {
    const classes = useStyles();
    const form = useForm();
    const {
        formState: { dirtyFields },
        setValue,
    } = form;

    const [loading, setLoading] = useState(false);
    const [message, setMessage] = useState<string>();
    const [deliveryCost, setDeliveryCost] = useState<string>();
    const [deliveryDuration, setDeliveryDuration] = useState<string>();

    const appLoading = useSelector((state) => state.app.loading);

    useEffect(() => {
        if (!loading) formReset();
    }, [loading]);

    const formReset = () => {
        form.reset({
            pickupAddress: null,
            dropoffAddress: null,
        });
    };

    const onSubmit = async (form: any) => {
        setLoading(true);
        const response = await getUberDaasCoverageApi({ ...form });
        setLoading(false);

        if (!response.ok) {
            alertKnownErrorOrSomethingWentWrong(response);
            formReset();
            return;
        }
        setMessage(response.data.message);
        setDeliveryCost(response.data.deliveryCost);
        setDeliveryDuration(response.data.deliveryDuration);
    };

    return (
        <Paper className={classes.paper}>
            <UpdatingContentProgress loading={loading || appLoading} />
            <Form form={form} onSubmit={onSubmit} disabled={loading || appLoading}>
                <div className={classes.formContent}>
                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                            <FormGoogleMapsAddressSearchAutocomplete name={'pickupAddress'} label={translate('Google Street Address')} tooltip={translate('Location of pickup')} />
                        </Grid>
                    </Grid>
                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                            <FormGoogleMapsAddressSearchAutocomplete name={'dropoffAddress'} label={translate('Google Street Address')} tooltip={translate('Location of dropoff')} />
                        </Grid>
                    </Grid>
                    <FormFixErrorHint />
                </div>
                <Grid container justify='center' spacing={3}>
                    <Grid item>{deliveryCost && <p className={classes.sectionTitle}>{`${translate('DeliveryCost: ')}  $${deliveryCost}`}</p>}</Grid>
                </Grid>
                <Grid container justify='center' spacing={3}>
                    <Grid item>{deliveryDuration && <p className={classes.sectionTitle}>{`${translate('DeliveryDuration: ')}  ${deliveryDuration}min`}</p>}</Grid>
                </Grid>
                <Grid container justify='center' spacing={3}>
                    <Grid item>{message && <p className={classes.sectionTitle}>{`${message}`}</p>}</Grid>
                </Grid>
                <Grid container justify='center' spacing={3}>
                    <Grid item>
                        <FormSubmitButton text={translate('Calculate')} disabled={loading || appLoading} />
                    </Grid>
                </Grid>
            </Form>
        </Paper>
    );
}

const useStyles = makeStyles((theme) => ({
    paper: {
        position: 'relative',
    },
    formContent: {
        padding: theme.spacing(3),
    },
    sectionTitle: {
        fontFamily: theme.typography.semiBold,
        fontSize: 14,
        margin: 5,
    },
}));
