import { useCallback, useRef, useState } from 'react';
import ResizeObserver from 'resize-observer-polyfill';

// see https://github.com/souporserious/react-measure/issues/132
// see https://codesandbox.io/s/0mxr4nxmpl
export function useMeasurements(): {
    measurements: any;
    getRef: any;
} {
    const [measurements, setMeasurements] = useState({});
    const { observe, unobserve } = useResizeObserver({
        onResize: () => {
            const nextMeasurements: Record<string, any> = {};
            for (const key in refs.current) {
                nextMeasurements[key] = refs.current[key].getBoundingClientRect();
            }
            setMeasurements(nextMeasurements);
        },
    });
    const { refs, getRef } = useRefManager({
        mount: observe,
        unmount: unobserve,
    });
    return { measurements, getRef };
}

function useRefManager({ mount, unmount }: any = {}): any {
    const storedRefs = useRef<any>({});
    const refCallbacks = useRef<any>({});
    const getRef = useCallback((id: string) => {
        if (!refCallbacks.current[id]) {
            refCallbacks.current[id] = (ref: any) => {
                if (!storedRefs.current[id] && ref) {
                    if (mount) {
                        mount(ref);
                    }
                    storedRefs.current[id] = ref;
                } else if (!ref) {
                    if (unmount) {
                        unmount(storedRefs.current[id]);
                    }
                    delete storedRefs.current[id];
                }
            };
        }
        return refCallbacks.current[id];
    }, []);
    return { refs: storedRefs, getRef };
}

function useResizeObserver({ onResize }: any): any {
    const resizeObserver = useRef<any>(null);

    function getResizeObserver() {
        if (resizeObserver.current !== null) {
            return resizeObserver.current;
        } else {
            const observer = new ResizeObserver(onResize);
            resizeObserver.current = observer;
            return observer;
        }
    }

    return {
        observe: (node: any) => getResizeObserver().observe(node),
        unobserve: (node: any) => getResizeObserver().unobserve(node),
    };
}
