import { makeStyles } from '@material-ui/core';
import classNames from 'classnames';
import * as React from 'react';
import { Text } from 'src/components/Text';
import { translate } from 'src/i18n/translate';

export function PosTableStates(): React.ReactElement {
    const classes = useStyles();

    return (
        <div className={classes.container}>
            <div className={classes.label}>
                <div className={classNames(classes.dotStatus, classes.availble)}></div>
                <Text>{translate('Available')}</Text>
            </div>
            <div className={classes.label}>
                <div className={classNames(classes.dotStatus, classes.busy)}></div>
                <Text>{translate('Busy')}</Text>
            </div>
            <div className={classes.label}>
                <div className={classNames(classes.dotStatus, classes.paying)}></div>
                <Text>{translate('Paying')}</Text>
            </div>
        </div>
    );
}

const useStyles = makeStyles((theme) => ({
    container: {
        width: 'fit-content',
        fontSize: 14,
        fontFamily: theme.typography.regular,
        color: '#2E3748',
        display: 'flex',
        flexDirection: 'column',
        [theme.breakpoints.up('sm')]: {
            flexDirection: 'row',
            gap: 12,
        },
    },
    label: {
        display: 'flex',
        alignItems: 'center',
        gap: 4,
    },
    dotStatus: {
        width: 12,
        height: 12,
        borderRadius: '50%',
        backgroundColor: 'black',
    },
    availble: {
        backgroundColor: theme.palette.surface.successSecondary,
    },
    busy: {
        backgroundColor: theme.palette.text.danger,
    },
    paying: {
        backgroundColor: theme.palette.text.info,
    },
}));
