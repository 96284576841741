export const es: {
    [key: string]: string;
} = {
    'Accept': 'Aceptar',
    'Accept payments in different currencies': 'Aceptar pagos en diferentes monedas',
    'Add currency': 'Agregar moneda',
    'Accepted currencies': 'Monedas aceptadas',
    'Currency': 'Moneda',
    'Are you still here?': '¿Sigues aquí?',
    'Yes, I am': 'Sí, lo estoy',
    'Change currency': 'Cambiar moneda',
    'Remove currency': 'Eliminar moneda',
    'Exchange type (@mainCurrency)': 'Tipo de cambio (@mainCurrency)',
    'Are you sure you want to remove currency: "@currency"?': '¿Estas seguro que quieres eliminar está moneda: "@currency"?',
    'This currency already exists': 'Está moneda ya existe',
    'There are empty fields': 'Hay campos vacíos',
    'Activate the option if you want to accept payments in different currencies at POS': 'Activa está opción si deseas aceptar pagos en diferentes monedas',
    'Add Category': 'Agregar categoría',
    'Add Menu Item': 'Agregar artículo al menú',
    'Add Modifier Item': 'Agregar modificador al menú',
    'Not found information for this date range': 'No se ha encontrado información para este rango de fechas',
    'Order type': 'Tipo de orden',
    'Close eat here orders enabled': 'Bloquear órdenes de tipo comer aquí',
    'It will allow you to block the modification on eat here orders once the partial account ticket has been printed': 'Le permitirá bloquear la modificación en órdenes de tipo comer aquí una vez impreso el ticket de cuenta parcial',
    'The "Unlock closed orders" role permission is required to modify a closed order': 'Se necesita del permiso de rol "Desbloquear órdenes cerradas" para modificar una órden cerrada',
    'Select a restaurant to edit orders': 'Selecciona un restaurante para editar ordenes',
    'Partial account': 'Cuenta parcial',
    'Unlock': 'Desbloquear',
    'Printed partial account': 'Cuenta parcial impresa',
    'Unlocked order': 'Órden desbloqueada',
    'Unblock the order with an authorized PIN': 'Desbloquea la órden con un PIN autorizado',
    'To unlock enter your code': 'Para desbloquear ingresa tu código',
    'Not allowed': 'No permitido',
    'Authorization required': 'Se requiere autorización',
    'Reports': 'Reportes',
    'Included in the total': 'Incluido en el total',
    'Log Events': 'Log Events',
    'Log Levels': 'Nivel de Logs',
    'Log Types': 'Tipo de Logs',
    'Best selling category': 'Categoría más vendida',
    'Comparative with the previous period': 'Comparativa con periodo anterior',
    'Delivery Type': 'Medio de envío',
    'Channel': 'Canal',
    'By sales channel': 'Por medio de venta',
    'By method of payment': 'Por método de pago',
    'By order type': 'Por tipo de orden',
    'Total Sales by channel': 'Venta por canal',
    'Accumulated discount': 'Descuento acumulado',
    'Accumulated cost': 'Costo acumulado',
    'Total discount': 'Descuento total',
    'Promo Code Discount Paid By PideDirecto': 'Descuento pagado por Pide Directo',
    'Total income': 'Ingreso total',
    'Total Sales': 'Venta total',
    'Sales by Item': 'Venta por artículo',
    'Sales of category': 'Venta por categoría',
    'Sales by modifier': 'Venta por modificador',
    'Sales by promo code': 'Venta por código de promoción',
    'Sales details': 'Detalle de ventas',
    'Sales details per hour': 'Detalle de ventas por hora',
    'Sales details weekday': 'Detalle de ventas de la semana',
    'Promo code': 'Código promocional',
    'Per menu': 'Por menú',
    'Per category': 'Por categoría',
    'Allow only one product per day': 'Permitir solo un producto por día',
    'Activating this will restrict you to only one product per day in lets eat kiosk': 'Al activar esta opción, se restringirá a un producto por día en Lets Eat Kiosko',
    'Finance': 'Finanzas',
    'Day time': 'Por día',
    'Promo Code': 'Código promocional',
    'ADD MODIFIER GROUP': 'Agregar modificadores de grupo',
    'ADD MODIFIER': 'Agregar modificador',
    'ADD NEW': 'AGREGAR NUEVO',
    'ADD SUB MODIFIER GROUP': 'AGREGAR GRUPO DE SUBMODIFICADORES',
    'ADD SUB MODIFIER': 'AGREGAR SUBMODIFICADOR',
    'All Orders': 'Todos los pedidos',
    'There are no orders to download': 'No hay pedidos por descargar',
    'April': 'Abril',
    'August': 'Agosto',
    'Cancel': 'Cancelar',
    'Change': 'Cambiar',
    'Change Mobile Number': 'Cambiar Número de Teléfono',
    'Changing': 'Cambiando',
    'Changing...': 'Cambiando...',
    'Charges': 'Cobranza',
    'CHECKS IN': 'CUENTAS',
    'Checks': 'Cuentas',
    'Contains': 'Contiene',
    'Country': 'País',
    'City': 'Ciudad',
    'Create': 'Crear',
    'Creating': 'Creando',
    'Creating...': 'Creando...',
    'Loading...': 'Cargando...',
    'December': 'Diciembre',
    'Description': 'Descripción',
    'Desc': 'Desc',
    'February': 'Febrero',
    'Host': 'Anfitrión',
    'How Does It Work?': '¿Cómo funciona?',
    'Hiding...': 'Ocultando...',
    'Hide': 'Ocultar',
    'Hide @menuItem': 'Ocultar @menuItem',
    'Hidden until': 'Oculto hasta',
    'Image URL': 'Imagen URL',
    'Image': 'Imagen',
    'Mobile image': 'Imagen Movil',
    'or drag here the image of your product. SVG, PNG or JPG (max 400x800px)': 'o arrastra aquí la imagen de tu producto. SVG, PNG o JPG (max 400x800px)',
    'Background Image': 'Imagen de fondo',
    'POS login screen image': 'Imagen en pantalla de login POS',
    'January': 'Enero',
    'July': 'Julio',
    'June': 'Junio',
    'Just today (tomorrow will be available)': 'Solo por hoy (mañana estará disponible)',
    'Set a date': 'Definir fecha',
    'Undefined': 'Indefinidamente',
    'Location Latitude': 'Latitud de la ubicación',
    'Location Longitude': 'Longitud de la ubicación',
    'March': 'Marzo',
    'May': 'Mayo',
    'MODIFIER GROUP': 'Modificadores de grupo',
    'MODIFIERS': 'Modificadores',
    'Name': 'Nombre',
    'NO CHECKS': 'SIN CUENTAS',
    'No Ongoing Orders': 'No hay pedidos pendientes',
    'NO ORDERS': 'NO HAY PEDIDOS',
    'NO WAITER CALLS': 'SIN LLAMADAS A MESEROS',
    'NOTE': 'NOTA',
    'November': 'Noviembre',
    'October': 'Octubre',
    'Ongoing Orders': 'Pedidos pendientes',
    'Ordered By': 'Información del cliente',
    'Ordered By:': 'Información del cliente:',
    'ORDERS IN': 'PEDIDOS EN FILA ',
    'Phone Number': 'Número de teléfono',
    'Secondary Phone Number': 'Número de teléfono secundario',
    'Port': 'Puerto',
    'POS PRINTER ROUTES': 'RUTAS DE IMPRESORA DE PUNTO DE VENTA',
    'POS Printer': 'Impresora PUNTO DE VENTA',
    'POS PRINTERS': 'IMPRESORAS DE PUNTO DE VENTA',
    'Price': 'Precio',
    'Price of the product': 'Precio del producto',
    'Modifier price': 'Precio de modificador',
    'REFRESH': 'Actualizar',
    'REFRESHING': 'ACTUALIZANDO',
    'Reject': 'Rechazar',
    'REMOVE': 'Quitar',
    'REMOVING': 'Quitando',
    'Required Max': 'Max Requerido',
    'Required Min': 'Min Requerido',
    'RESET': 'RESETEAR',
    'Restaurant Videos': 'Videos de Restaurantes',
    'Restaurant Dashboard': 'Reporte por Sucursal',
    'Restaurant not selected': 'Restaurante no selecccionado',
    'Sales': 'Ventas',
    'SALES': 'VENTAS',
    'Save': 'Guardar',
    'Saving': 'Guardando',
    'Save and send': 'Guardar y enviar',
    'September': 'Septiembre',
    'Service Type': 'Tipo de Servicio',
    'Settings': 'Ajustes',
    'Sign in': 'Iniciar sesión',
    'Sign out': 'Cerrar sesión',
    'Update Changes': 'Actualizar cambios',
    'Signing In': 'Iniciando sesión',
    'Size': 'Tamaño',
    'Street': 'Calle',
    'Apto': 'Apto',
    'TABLE': 'MESA',
    'Table': 'Mesa',
    'Table:': 'Mesa:',
    'Opening Hours': 'Horas de apertura',
    'The Menu': 'El menú',
    'The Restaurant': 'El restaurante',
    'Time Zone': 'Zona Horaria',
    'Type': 'Tipo',
    'WAITER CALLS IN': 'Llamadas a meseros pendientes',
    'Waiter Calls': 'Llamadas a meseros',
    'Welcome to Let’s Eat Manager!': 'Bienvenidos al Administrador de Let’s Eat',
    'Welcome': 'Bienvenido',
    'Scan The Qr Code With Your Phone Or Press The Button': 'Escanea el código Qr con tu celular o presiona el botón',
    'Synchronizing payment methods': 'Sincronizando métodos de pago',
    'Take Away': 'Para Llevar',
    'Eat Here': 'Comer aquí',
    'Free Delivery': 'Entrega Gratis',
    'Exclude delivery cost': 'Excluir el costo de envío',
    'Free': 'Gratis',
    'Excluded': 'Excluido',
    'Not Free': 'No es Gratis',
    'Not Excluded': 'Sin Excluir',
    'Exp.': 'Esperado',
    'Diff': 'Dif.',

    'Cash limit has been reached.': 'Se ha alcanzado el límite de efectivo en la caja.',
    'Cash on cash register exceeded': 'Efectivo en caja superado',
    'Withdraw now': 'Retirar ahora',
    'Later': 'Más tarde',
    'Withdraw excess to maintain proper control.': 'Retire el excedente para mantener el control adecuado.',
    'It is essential to withdraw cash at the cash register': 'Indispensable retirar el efectivo en caja',
    'You have reached @cash in cash. Withdraw cash to maintain the limit.': 'Has llegado a @cash en caja. Retira el efectivo para mantener el límite.',

    'Location not found': 'Ubicación no encontrada',
    'Clabe and Bank not found': 'Clabe y banco no encontrados',
    'Restaurant location not found, please verify that you have set your address correctly': 'No se ha encontrado la ubicación del restaurante, por favor, verifique que ha configurado su dirección correctamente',
    'Restaurant clabe and bank not found, please verify that you have set your data correctly': 'No se ha encontrado la clabe y el banco del restaurante, por favor, verifique que ha configurado sus datos correctamente',
    'This description should describe what is included in the promo code and will be visible for the customer when they use the promo code.': 'Esta descripcion debe describir que esta incluido en el codigo promocional y sera visible para los usuarios que usen el codigo promocional',
    'Sent': 'Enviado',
    'Received': 'Recibido',
    'Cancelled': 'Cancelado',
    'Cancelled by customer': 'Cancelado por el cliente',
    'Manual Assignments': 'Asignaciones Manuales',
    'Cancellations': 'Cancelaciones',
    'Manual Earnings': 'Ganancias Manuales',
    'Rejected': 'Rechazado',
    'Returned': 'Devuelto',
    'Accepted': 'Aceptado',
    'Served': 'Servido',
    'Picked Up': 'Recogido',
    'Delivered': 'Entregado',
    'Deliveries': 'Entregas',
    'Cash': 'Efectivo',
    'Terminal': 'Terminal',
    'Credit Card': 'Tarjeta',
    'Credit Card (changed)': 'Tarjeta (cambiado)',
    'The payment method has been changed manually': 'El método de pago se ha modificado manualmente',
    'Pay with card on delivery': 'Paga con tarjeta en la entrega',
    'Automatic generate invoices on payment': 'Generacion de facturas automaticas',
    'SPEI': 'SPEI y OXXO',
    'CARD': 'Tarjeta',
    'Card': 'Tarjeta',
    'SPEI_INTERNAL': 'SPEI Interno',
    'Average Ticket': 'Ticket Promedio',
    'Average consumption': 'Consumo promedio',
    'Payment Methods': 'Métodos de pago',
    'PLANNED': 'Planeado',
    'PLANNED_MENU_ITEMS': 'Fecha de entrega',
    'For clients who include Point of Sale in his subscription, payment method must be by SPEI': 'Para clientes que incluyen el Punto de venta POS, el método de pago debe ser por SPEI',
    'Order invoiced and returned': 'Orden facturada y devuelta',
    'A credit note was generated': 'Se generó una nota de crédito',
    'Manually accept all created orders': 'Aceptar manualmente todas las órdenes creadas',
    'Activate this option if you want to be notified and forced to accept all types of orders including POS orders': 'Activa esta opción si desea que ser notificado y forzado a aceptar todo tipo de pedidos, incluidos los pedidos de POS',

    'Resume @date': 'Resumen @date',
    'Cash Register Summary': 'Resumen de caja',
    'This section includes sales of integration channels': 'Esta sección incluye las ventas de los canales de integraciones',
    "This section doesn't include sales of integration channels": 'Esta sección no incluye las ventas de los canales de integraciones',
    'Own Drivers': 'Mi Flotilla',
    'Costs': 'Costos',
    'Own Drivers Report': 'Mi Flotilla',
    'Driver OwnFleet code': 'Código para unirse a la flotilla de repartidores',
    'Delivery drivers will be able to link to the restaurant with this code': 'Los repartidores podrán unirse a la flotilla del restaurante con este código',
    'Own Fleet Enabled': 'Vincular repartidores (Mi Flotilla)',
    'Show order products for my own fleet': 'Mostrar productos de pedido (Mi Flotilla)',
    'Admin': 'Administración',
    'Restaurant Devices': 'Dispositivos de Restaurante',
    'Devices': 'Dispositivos',
    'Change device': 'Modificar Dispositivo',
    'Are you sure you want to remove the device?': '¿Estas seguro que quieres eliminar el dispositivo?',
    'Monitoring': 'Supervisión',
    'NO RESTAURANTS TO CONTACT': 'NO HAY RESTAURANTES PARA CONTACTAR',
    'RESTAURANT': 'RESTAURANTE',
    'NOT ACCEPTED': 'NO ACEPTADO',
    'TIME SINCE NO ACTION': 'TIEMPO DESDE QUE NO HAY ACCIÓN',
    'Contact Support': 'Contacta a Soporte',

    'Sign in to your account': ' ',
    'Sign In': 'Iniciar Sesión',
    'Username': 'Usuario',
    'Password': 'Contraseña',
    'Incorrect username or password.': 'Nombre de usuario o contraseña incorrecta',
    'Password attempts exceeded': 'Demasiados inicios de sesión fallidos',
    'Username cannot be empty': 'El nombre de usuario no puede estar vacío',
    'User does not exist.': 'El usuario no existe',

    'Tomorrow': 'Mañana',
    'Yesterday': 'Ayer',

    'Week': 'Semana',
    'Year': 'Año',

    'Card has insufficient funds': 'La tarjeta no cuenta con fondos suficientes',
    'Card payment not accepted': 'El pago con tarjeta no fue aceptado',

    'Sold Out': 'Agotado',

    'Preparation Time (in minutes)': 'Tiempo de preparación (En minutos)',
    'Default is 15 minutes': '15 minutos por defecto',
    'If the value is set to 0, the functionality will be disabled': 'Si el valor es 0, la funcionalidad se deshabilitará',

    'Are you sure you want to reject this order? Please note that the customer has been notified that the order has been accepted already.': '¿Está seguro de que desea rechazar el pedido? Recuerde que el cliente ya ha sido notificado sobre la aceptación de esta orden.',

    'OPENING HOURS': 'HORAS DE APERTURA',
    'Opening': 'Apertura',
    'Closing': 'Cierre',
    'Monday': 'Lunes',
    'Tuesday': 'Martes',
    'Wednesday': 'Miércoles',
    'Thursday': 'Jueves',
    'Friday': 'Viernes',
    'Saturday': 'Sábado',
    'Sunday': 'Domingo',
    'monday': 'Lunes',
    'tuesday': 'Martes',
    'wednesday': 'Miércoles',
    'thursday': 'Jueves',
    'friday': 'Viernes',
    'saturday': 'Sábado',
    'sunday': 'Domingo',

    'Pay in App': 'Pago en app',
    'Paid in App': 'Pagado en app',
    'Pay in Store': 'Pagar en tienda',
    'Order from Didi Food': 'Orden de Didi Food',

    'Select cause of the rejection': 'Seleccione la causa de rechazo',
    'Select cause of the cancellation': 'Seleccione la causa de cancelación',
    'Closing soon': 'Cierra pronto',
    'Problem in the restaurant': 'Problema en el restaurante',
    'Sold out': 'Producto agotado',
    'The price is incorrect': 'El precio es incorrecto',

    'OrderRejectReasons.CLOSING_SOON': 'Cierra pronto',
    'OrderRejectReasons.PROBLEM_IN_RESTAURANT': 'Problema en el restaurante',
    'OrderRejectReasons.SOLD_OUT': 'Producto agotado',
    'OrderRejectReasons.DRIVER_NOT_FOUND': 'Repartidor no encontrado',
    'OrderRejectReasons.DRIVER_DID_NOT_ARRIVE': 'No llegó el repartidor',
    'OrderRejectReasons.INCORRECT_PRICE': 'El precio es incorrecto',
    'OrderRejectReasons.REJECTED_BY_ADMIN': 'Rechazar como administrador',
    'OrderRejectReasons.EXTERNAL_COURIER_CANCEL': 'Comunícate con tu equipo de soporte para más información.',
    'OrderRejectReasons.UNASSIGNED_COURIER': 'Cliente canceló por tiempo de esperar',
    'OrderRejectReasons.CANCELLED_BY_CLIENT_DUE_TO_WAITING_TIME': 'Cliente canceló por tiempo de espera',
    'OrderRejectReasons.CANCELLED_DUE_TO_CLIENT_ERROR': 'Cancelado por error del cliente',
    'OrderRejectReasons.CANCELLED_BY_LACK_OF_CLIENT_CONTACT': 'Cancelado por falta de contacto del cliente',
    'OrderRejectReasons.WANT_MODIFY_ORDER': 'Quiero modificar el pedido',
    'OrderRejectReasons.WAITING_DELAY_IN_PREPARATION': 'Demora de espera en la preparación',
    'OrderRejectReasons.TECH_ERROR_WITH_THE_PLATFORM': 'Error de tech con la plataforma',
    'OrderRejectReasons.ERROR_WITH_CUSTOMER_DATA': 'Error con los datos del cliente',
    'OrderRejectReasons.NO_CONTACT_WITH_ALLY': 'Sin concato con aliado',
    'OrderRejectReasons.CHURNED_RESTAURANT': 'Restaurante Churneado',
    'OrderRejectReasons.CLOSED_STORE': 'Tienda cerrada',
    'OrderRejectReasons.POORLY_PACK_PRODUCT': 'Producto mal empacado (Dañado)',
    'OrderRejectReasons.EXCEEDED_VOLUME': 'Volumen/Peso excedido',
    'OrderRejectReasons.NO_CUSTOMER_CONTACT': 'Sin contacto con el cliente',
    'OrderRejectReasons.FRAUD': 'Fraude',
    'OrderRejectReasons.CAN_NOT_PAY_ORDER': 'No puede pagar el pedido',
    'OrderRejectReasons.COURIER_ACCIDENT': 'Accidente repartidor',
    'OrderRejectReasons.WEATHER_CONDITIONS': 'Condiciones Climáticas',
    'OrderRejectReasons.OUT_OF_COVERAGE': 'Fuera de cobertura',
    'OrderRejectReasons.DUPLICATE_ASSIGMENT': 'Asignación duplicada',
    'OrderRejectReasons.ORDER_HIGH_AMOUNT_LARGE_SIZE': 'Pedido monto alto/gran tamaño',
    'OrderRejectReasons.ORDER_THEFT': 'Proceso de Repartidor Incompleto',
    'OrderRejectReasons.RED_ZONE': 'Zona Roja (Alto riesgo)',
    'OrderRejectReasons.PROBLEMS_WITH_MANAGER': 'Problemas con Manager',
    'OrderRejectReasons.THE_ORDER_DOESNOT_APPEAR_SYSTEM': 'No aparece en sistema la orden',
    'OrderRejectReasons.DUPLICATED_ORDER': 'Pedido duplicado',
    'OrderRejectReasons.MISPLACED_PIN': 'Pin mal ubicado dirección errónea',
    'OrderRejectReasons.OTHER': 'Otro',
    'OrderRejectReasons.SK_DOESNT_ANSWER': 'RT no respondió',
    'OrderRejectReasons.PROBLEMS_WITH_CLIENT_PAYMENT': 'Problemas con el pago',
    'OrderRejectReasons.PRODUCT_NOT_AVAILABLE': 'Productos no disponibles',
    'OrderRejectReasons.SK_DOESNT_WANT_DELIVERY_ORDER_WITH_PRODUCT': 'RT tuvo problemas con la entrega',
    'OrderRejectReasons.ANOTHER_SK_TOOK_ORDER': 'Orden tomada por otro RT',
    'OrderRejectReasons.SK_COULDNT_COMPLETE_ORDER': 'RT no pudo completar la orden',
    'OrderRejectReasons.INCOMPLETE_ORDER_BAD_STATE': 'La orden estaba en mal estado',
    'OrderRejectReasons.DOES_NOT_WORK_WITH_RAPPI': 'Establecimiento no trabaja con Rappi',
    'OrderRejectReasons.DIDNT_CANCEL_BY_AUTOMATION': 'Orden no fue cancelada por automatización',
    'OrderRejectReasons.RELEASED_WITH_PRODUCT': 'Orden liberada con producto',
    'OrderRejectReasons.CLIENT_WHIM_NOT_ALLOWED_FAVOR': 'Parada de la orden no permitida',
    'OrderRejectReasons.BILLING_ERROR': 'Error de facturación',
    'OrderRejectReasons.INNER_TEST': 'Prueba interna',
    'OrderRejectReasons.REVIEW': 'En Revisión',
    'OrderRejectReasons.CANCEL_BY_USER': 'Cancelada por Aliado',
    'OrderRejectReasons.MANAGE_UBER_EATS_ORDERS_NOT_ALLOWED': 'Administrar ordenes de UberEats no permitido',

    'OrderRejectReasonExtras.REFUND': 'Reembolso',
    'OrderRejectReasonExtras.TICKET': 'Ticket',

    'OrderRejectReasonCategories.ESTABLISHMENT': 'Establecimiento',
    'OrderRejectReasonCategories.CUSTOMER': 'Cliente',
    'OrderRejectReasonCategories.OPERATION': 'Operación',
    'OrderRejectReasonCategories.TECH': 'Tech',
    'OrderRejectReasonCategories.RESTAURANT': 'Restaurante',
    'OrderRejectReasonCategories.RAPPICARGO': 'Cancelado por Rappi Cargo',
    'OrderRejectReasonCategories.WITHOUTASSIGNMENT': 'Sin Asignacion',
    'OrderRejectReasonCategories.EXTRA': '(EXTRA)',
    'OrderRejectReasonCategories.OTHER': 'Otro',

    'New Order': 'Nueva orden',
    'Edit Order': 'Editar orden',
    'Order Cancelled': 'Pedido cancelado',
    'Order Rejected': 'Pedido rechazado',
    'Order Accepted': 'Pedido aceptado',
    'Order Completed': 'Pedido completado',
    'Order Missing': 'No se Encontro la Orden',
    'Browser Refreshed': 'Buscador actualizado',
    'New Waiter Call': 'Nueva llamada a mesero',
    'Waiter Call Completed': 'Llamada a mesero completada',
    'Waiter Call Refreshed': 'Llamada a mesero actualizada',

    'From': 'Desde',
    'To': 'Hasta',
    'App': 'App',
    'Store': 'Tienda',
    'Total': 'Total',
    'Restaurant Net Total': 'Total neto',
    'Restaurant Gross Total': 'Total bruto',
    'Total Delivery Costs': 'Costos de envío total',
    'Total Restaurant Delivery Costs': 'Costos de envío por el restaurante',
    'Total Customer Delivery Costs': 'Costos de envío por el cliente',
    'Total Pidedirecto Delivery Costs': 'Costos de envío por PideDirecto',
    'Total Returned Orders Costs': 'Costo total de órdenes devueltas',
    'Total AmbitOne Orders': 'Órdenes de AmbitOne',
    'Total Uber Eats Orders': 'Órdenes de Uber Eats',
    'Total Uber Daas Orders': 'Órdenes de Uber Daas',
    'Total Didi Food Orders': 'Órdenes de Didi Food',
    'Total Rappi Orders': 'Órdenes de Rappi',
    'Total Rappi Cargo Orders': 'Órdenes de Rappi Cargo',
    'Rappi Cargo Default Tip': 'Propina en Rappi Cargo por default',
    'The value of this field is going to be added to all orders from this restaurant as a tip.': 'el valor de este campo va a ser añadido a todas las órdenes de rappicargo para este resturante como propina',
    'Total Pedidos ya  Orders': 'Órdenes de Pedidos ya',
    'Total Returned Orders': 'Órdenes devueltas',

    'Search Text': 'Texto de búsqueda',
    'Search...': 'Buscar...',
    'Searching': 'Buscando ',

    'Order Statistics': 'Estadísticas de pedidos',
    'Restaurants': 'Restaurantes',
    'Status': 'Estado',

    'Change Menu': 'Modificar menú',
    'Change Category': 'Modificar categoría',
    'Change Menu Item': 'Modificar artículo del menú',
    'Change Modifier Item': 'Modificar modificador del menú',
    'Create Menu': 'Crear menú',
    'Create category': 'Crear categoría',
    'Create Menu Item': 'Crear artículo del menú',
    'Create Modifier Item': 'Crear modificador del menú',
    'Are you sure you want to remove menu?': '¿Está seguro de que deseas eliminar el menú?',
    'Are you sure you want to remove category?': '¿Está seguro de que desea elminar la categoría?',
    'Are you sure you want to remove menu item?': '¿Está seguro de que desea eliminar el artículo del menú?',
    'Are you sure you want to remove the user @userName?': '¿Está seguro de que desea eliminar el usuario @userName?',
    'Are you sure you want to change this tab? You have unsaved changes.': '¿Estás seguro de que quieres cambiar esta pestaña? Tiene cambios sin guardar.',
    'Saved changes to menu': 'Los cambios fueron realizados en el menú',
    'Saved changes to category': 'Los cambios fueron realizados en la categoría',
    'Saved changes to menu item': 'Los cambios fueron realizados en el artículo del menú',
    'Created menu': 'Menú creado con éxito',
    'Created category': 'Categoría creada con éxito',
    'Created menu item': 'Artículo del menú creado',
    'Removed menu': 'El menú ha sido eliminado',
    'Removed category': 'La categoría ha sido eliminada',
    'Removed menu item': 'El artículo ha sido eliminado del menú',

    'Are you sure you want to remove this paymentLink?': '¿Estás seguro de que quieres eliminar este link de pago?',
    'Are you sure you want to remove these paymentLinks?': '¿Estás seguro de que quieres eliminar estos links de pago?',
    'Remove Payment Link': 'Eliminar link de pago',

    'Done': 'Su modificación ha sido realizada con éxito',

    'Register Restaurant': 'Registrar restaurante',
    'Register': 'Registrar',
    'Registering': 'Registro en proceso',
    'Restaurant Name': 'Nombre de restaurante',
    'Restaurant Colors': 'Colores del restaurante',
    'Primary color': 'Restaurante color primario',
    'Secondary color': 'Restaurante color secundario',
    'Restaurant with username @username already exists': 'El usuario de restaurante @username ya existe',

    'Yes': 'Si',
    'No': 'No',

    '@freeModifiersQuantity Modifiers free': '@freeModifiersQuantity Modificadores gratis',
    'If it exceeds the free quantity, the higher value modifiers will be added': 'Si supera la cantidad gratuita, se cobrarán los modificadores de mayor valor',
    'Live': 'En línea ',
    'Coming Soon': 'Próximamente',
    'Inactive': 'Inactivo',
    'Temporarily Offline': 'Temporalmente sin servicio',
    'Offline': 'Sin servicio',

    'Saved changes': 'Cambios guardados',
    'Mexico': 'México',
    'Sweden': 'Suecia',

    'Details': 'Detalles',

    'Restaurant': 'Restaurante',
    'Zones': 'Zonas',
    'All Zones': 'Todas las zonas',
    'The bonus will only be applied to deliveries delivered from the selected zones': 'Los bonus solo aplicarán a entregas de las zonas seleccionadas',
    'EXCLUSIVE: Once a driver has completed an Exclusive bonus, "normal" bonus become cancelled. EXTRA: Extra bonus apply ALWAYS for both "normals" and EXCLUSIVE bonus. Leave it blank for "normal" bonus.': 'EXCLUSIVE: Una vez que un repartidor complete un bono Exclusive, los bonos "normales" se cancelan. EXTRA: Los bonos extra aplican SIEMPRE tanto para bonos Exclusivos como para bonos "normales". Déjalo en blanco para que sea un bono "normal"',
    'Zone': 'Zona',
    'Contact': 'Nombre del Contacto',
    'Contact Number': 'Número de contacto',
    'Establishments Phone Number': 'Número de teléfono de establecimiento',
    'Fiscal Address': 'Domicilio Fiscal',
    'Address of Establishment': 'Dirección del Establecimiento',
    'Email': 'Email',
    'Bank': 'Banco',
    'Account': 'Cuenta',
    'Business Name': 'Razón Social',
    'Invoice Description': 'Descripcion de Factura',
    'Contact Name': 'Nombre del contacto',
    'Payment in Establishment': 'Pago en establecimiento',
    'Percentage Agreed': 'Percentage Agreed',
    'Percent of sales': '% de la venta total',

    'Device': 'Dispositivo',
    'Responsible': 'Responsable',
    'Bill': 'Factura',

    'Sign in Details': 'Detalles del inicio de sesión',
    'Other Details': 'Otros detalles',
    'Open': 'Abrir',

    'Self Service': 'Auto Servicio ',
    'Table Service': 'Servicio a la mesa',

    'Hide in kitchen (KDS)': 'Ocultar en cocina (KDS)',
    'Copy Menu from other Restaurant': 'Copiar menú de otro restaurante',
    'Copy Inventory from other Restaurant': 'Copiar inventario de otro restaurante',
    'There is inventory information to be overwritten, are you sure you want to copy this inventory?': 'Hay información del inventario que se va a sobreescribir, ¿Está seguro de que quiere copiar este inventario?',
    'Copy': 'Copiar',
    'Copying': 'Copiando',
    'Copied Restaurant Menu': 'Menú de restaurante copiado',
    'Copied Restaurant Inventory': 'Inventario de restaurante copiado',
    'Copied to clipboard': 'Se ha copiado al portapapeles',
    'When copying the inventory, recipes, suppliers and supplies of the selected restaurant will be copied': 'Al copiar el inventario, se copiarán las recetas, proveedores e insumos del restaurante seleccionado',
    'Payment Link copied to clipboard': 'El link de pago se ha copiado al portapapeles',
    'If checked the entire menu will be overwritten. Otherwise it will be added to the existing ones.': 'Si está marcada, se sobrescribirá todo el menú. Si no, se añadirá a los ya existentes.',
    'Overwrite': 'Sobrescribir',
    'The menu to be copied is very large so it will take a little longer than normal, when the copying is finished this notification will close and update the menu automatically. (Please do not close manually)': 'El menú a copiar es muy grande por lo que tardará un poco más de lo normal, cuando se termine de copiar se cerrará esta notificación y actualizará el menú automáticamente. (Por favor no cerrar manualmente)',
    'The menu to be removed is very large so it will take a little longer than normal, when the removal is finished this notification will close and update the menu automatically. (Please do not close manually)': 'El menú a eliminar es muy grande por lo que tardará un poco más de lo normal, cuando finalice la eliminación esta notificación se cerrará y actualizará el menú automáticamente. (Por favor no cerrar manualmente)',
    'Removing related items': 'Eliminando items relacionados',

    'Promo Enabled': 'Promoción Activada',
    'Promo Image URL': 'URL de Imagen de promoción',
    'Promo Text': 'Texto de promoción',
    'Promo Price': 'Precio de promoción',
    'Promo price discount': 'Descuento de precio promoción',
    'Integration discount': 'Descuento de integración',
    'Promo Code discount': 'Descuento de código de promoción',
    'Restaurant Paid Percentage': 'Porcentaje de Pago del Restaurante',

    'Customers': 'Clientes',
    'Customers: @numberOfCustomers': 'Clientes: @numberOfCustomers',
    'Customer Credits': 'Créditos del cliente',
    'Customer': 'Cliente',
    'Banners': 'Banners',
    'Banner': 'Banner',

    'Connection lost': 'Conexión perdida',
    'Reconnecting': 'Reconectando',
    'Failed to sign in': 'Error al iniciar sesión',
    'Retrying': 'Reintentando',
    'No connection': 'Sin conexión',

    'Move Left': 'Mover izquierda',
    'Move Right': 'Mover derecha',
    'Move Up': 'Mover arriba',
    'Move Down': 'Mover abajo',
    'Duplicate': 'Duplicar',
    'Remove': 'Eliminar',
    'Exit': 'Salir',

    'Mo': 'Lu',
    'Tu': 'Ma',
    'We': 'Mi',
    'Th': 'Ju',
    'Fr': 'Vi',
    'Sa': 'Sa',
    'Su': 'Do',

    'What were the reasons?': '¿Cuáles fueron los motivos?',
    'The food was cold': 'La comida estaba fría',
    'Missing or incorrect items': 'Elementos faltantes o incorrectos',
    'The food was not prepared': 'La comida no estaba preparada',
    'Confused pick-up place': 'Lugar de recogido confuso',
    'Bad customer service': 'Mala atención a cliente',
    'What do you enjoy most about Let’s Eat?': '¿Qué es lo que más disfrutas de Let’s Eat?',
    'Punctuality': 'Puntualidad',
    'The application': 'Facilidad de la aplicación',
    'The food': 'Sabor de la comida',
    'Presentation': 'Presentación',
    'Good customer service': 'Buena atención a cliente',

    '@avarage average based on @reviews reviews (@dismissed of @total dismissed)': 'Promedio de @avarage basado en @reviews comentarios (@dismissed de @total sin respuesta)',
    '1 heart': '1 corazón',
    '2 hearts': '2 corazones',
    '3 hearts': '3 corazones',
    '4 hearts': '4 corazones',
    '5 hearts': '5 corazones',
    'Answer (Good Experience)': 'Respuesta (Buena Experiencia)',
    'Answer (Bad Experience)': 'Respuesta (Mala Experiencia)',
    'Answers': 'Respuestas',
    'Other Answers (Good Experience)': 'Otras respuestas (Buena Experiencia)',
    'Other Answers (Bad Experience)': 'Otras respuestas (Mala Experiencia)',

    'changed to card': 'cambiado a tarjeta',
    'Add Credits': 'Agregar Créditos',
    'Download CSV': 'Descargar CSV',
    'Download PDF': 'Descargar PDF',
    'Refresh': 'Actualizar',
    'Send Notification': 'Enviar notificación',
    'Mark as Solved': 'Marcar como resuelto',
    '24/7': '24/7',
    'Benefit Plan Details': 'Detalles de plan de beneficios',
    'Active': 'Activo',
    'Paused': 'Pausado',
    'Credits': 'Créditos',
    'Credits:': 'Créditos:',
    'Amount of credits that the employee can order with': 'Cantidad de créditos disponibles para ordenar del empleado',
    'Restrict Credits to Delivery': 'Limita créditos al servicio de entrega',
    'Receive all weekly credits on Saturday': 'Recibe todos los créditos de la semana en sábado',
    'Meals': 'Comidas',
    'Max number of meals per week': 'Número máximo de comidas por semana',
    'Days': 'Días',
    'Days of week when the employee can order': 'Días de la semana en que los empleados pueden ordenar',
    'Hours': 'Horas',
    'Time of day when the employee can order': 'Horarios en que el empleado puede ordenar',
    'Benefit Plans': 'Planes de beneficios',
    'Created': 'Creado',
    'Create Benefit Plan': 'Crea un plan de beneficios',
    'Are you sure you want to remove this benefit plan?': '¿Estás seguro de eliminar este plan de beneficios?',
    'Are you sure you want to remove this employee?': '¿Estás seguro de eliminar a este empleado?',
    'Change Benefit Plan': 'Cambiar plan de beneficios',
    'Remove Benefit Plan': 'Eliminar plan de beneficios',
    'Time of day when the employee can order, e.g. 11:00-13.00': 'Horario del día en que se pueden realizar los pedidos, Ej. 11:00-13:00',
    'Connected Customers': 'Clientes conectados',
    'Mobile Number': 'Número de celular',
    'Payroll Payments Enabled': 'Pagos con nómina habilitados',
    'Company Details': 'Detalles de la compañía',
    'Address': 'Dirección',
    'Contact First Name': 'Primer nombre del contacto',
    'Contact Last Name': 'Apellido del contacto',
    'Contact Email': 'Correo electrónico del contacto',
    'Contact Phone Number': 'Número de contacto',
    'Create BenefitPlan': 'Crea un plan de beneficios',
    'Register Company': 'Registra una empresa',
    'Benefit Plan': 'Plan de beneficios',
    'Used Credits': 'Créditos utilizados',
    'Orders': 'Órdenes',
    'Total Used Credits': 'Total de créditos utilizados',
    'Ignored': 'Ignorado',
    'Number of orders': 'Número de órdenes',
    'Credits Payments': 'Pagos de créditos',
    'Paid At': 'Pagado en',
    'You must select at least one customer to connect.': 'Debes seleccionar por lo menos un empleado para conectar',
    'Unconnected Customers': 'Cliente no conectado',
    'Unknown': 'Desconocido',
    'Company Name': 'Nombre de la compañía',
    'Signed Up': 'Registrado',
    'Connect Customer': 'Conecta usuario',
    'Add Employee': 'Agrega empleado',
    'Adding': 'Agregando',
    'Add': 'Agregar',
    'First Name': 'Nombre',
    'Last Name': 'Apellido',
    'Change Employee': 'Cambiar empleado',
    'Remove Employee': 'Eliminar empleado',
    'Employee Details': 'Detalles del empleado',
    'Modified': 'Modificado',
    'Connected': 'Conectado',
    'Employees': 'Empleados',
    'Not connected': 'Desconectado',
    'Gifts': 'Regalos',
    'Removed': 'Eliminado',
    'Annulled': 'Anulado',
    'Day': 'Día',
    'Employee': 'Empleado',
    'Company': 'Compañía',
    'Per Employee': 'Por Empleado',
    'Paid Amount': 'Monto pagado',
    'All Payments': 'Todos los pagos',
    'Credits Billing': 'Factura de subsidio',
    'Payroll Billing': 'Factura de nómina',
    'Something went wrong': 'Ha ocurrido un error',
    'Something went wrong @error': 'Ha ocurrido un error: @error',
    'This browser does not support System Notifications': 'Este navegador no soporta el sistema de notificaciones',
    'Notifications configured!': '¡Notificaciones configuradas!',
    'is required': 'es requerido',
    'is not a number': 'no es un número',
    'is not an integer': 'no es un valor permitido',
    'is not a positive number': 'no es un número positivo',
    'is not an url': 'no es un url',
    'is not an url starting with https://': 'no es un url que comienza con https://',
    'is not an id': 'no es un número de identificación',
    'is not a comma separated list of ids': 'No es una lista de número de identificación separados por coma',
    'is not valid hours syntax, see https://wiki.openstreetmap.org/wiki/Key:opening_hours': 'Formato de horario incorrecto, ver https://wiki.openstreetmap.org/wiki/Key:opening_hours',
    'is not a valid time range, must be in format 08:00-17:00': 'No es un rango de horario válido, el formato debe ser 08:00-17:00',

    'is not a number greater than zero': 'is not a number greater than zero',
    'is not a number equal to or greater than zero': 'is not a number equal to or greater than zero',
    'is not valid rrule syntax, see https://jakubroztocil.github.io/rrule/': 'is not valid rrule syntax, see https://jakubroztocil.github.io/rrule/',
    'is not containing a start date, DTSTART, see https://jakubroztocil.github.io/rrule/': 'is not containing a start date, DTSTART, see https://jakubroztocil.github.io/rrule/',
    'maximum @number characters': 'maximum @number characters',
    'minimum @number characters': 'minimum @number characters',
    'number of characters should be @number': 'number of characters should be @number',
    'must be numeric characters': 'must be numeric characters',
    'Password must be numeric and 4 characters long': 'La contraseña debe ser numérica y de 4 caracteres',
    'must be alpha numeric characters': 'must be alpha numeric characters',
    'must be alpha characters': 'must be alpha characters',
    'must be phone number': 'debe ser el número de teléfono',
    'Keys must be matched': 'Las contraseñas deben coincidir',
    'are you sure this is a correct phone number?': '¿Estás seguro que este es un número de teléfono correcto?',
    'The provided url to the menu e.g. "https://www.rappi.com.mx/restaurantes/1923381245" or only the url id "1923381245"': 'El url al menu e.g. "https://www.rappi.com.mx/restaurantes/1923381245" o solo el id del url id "1923381245"',
    'Import images (may not import whole menu if images are to large)': 'Importar imágenes (puede que no importe todo el menú si las imágenes son muy grandes)',
    'OwnFleetDriverStatuses.PENDING': 'Pendiente',
    'OwnFleetDriverStatuses.REJECTED': 'Rechazado',
    'OwnFleetDriverStatuses.ACCEPTED': 'Aceptado',

    'Agreement': 'Acuerdo',
    'Billing': 'Facturación',
    'The food arrived late': 'La comida llegó tarde',

    'Instructions': 'Instrucciones',
    'Staircase/apartment/floor/other': 'Departamento/piso/otro',

    'Show on map': 'Mostrar en mapa',
    'Show directions': 'Ver ruta',

    'Business Name/Name': 'Razón Social/Nombre',
    'Bank Name': 'Nombre del banco',
    'Account Number': 'Numero de cuenta CLABE',
    'CLABE': 'CLABE',
    'RFC': 'RFC',

    'Street address and building number': 'Calle y número',
    'Instructions for carrier': 'Instrucciones para el repartidor',

    'Looking for driver': 'Asignando un repartidor',
    'Driver on the way to customer': 'Repartidor en camino al domicilio',

    'Driver on the way': 'Repartidor en camino',
    'Delivery Cost': 'Costo de envío',
    'Delivery Cost:': 'Costo de envío:',

    'Customer Name': 'Nombre del cliente',
    'Enable Unban Customer': 'Habilitar unban cliente',
    'Cost of dishes': 'Costo del pedido',

    'Request a Driver': 'Solicitar un repartidor',
    'Assign a Driver': 'Asignar un repartidor',
    'delivery address must be within delivery distance (@distance km)': 'La distancia del restaurante al domicilio pasa el límite permitido. (@distance km)',
    'The distance from the restaurant to the address exceeds the allowed limit': 'La distancia del restaurante al domicilio pasa el limite permitido',
    'Driving distance': 'Distancia por recorrer',

    'Payment Status': 'Estado de pago',
    'Pickup Time': 'Hora de recogida',
    'Reject Reason': 'Razón de rechazo',
    'Order Type': 'Tipo de pedido',
    'Delivery Time': 'Hora de entrega',
    'App Version': 'Versión de la app',
    'Version @version': 'Versión @version',
    'App Version @version': 'Versión de la app @version',
    'Customer Delivery Cost': 'Costo de envío del cliente',
    'Dynamic Delivery Earnings': 'Ganancias por envío dinámico',
    'Total discount from all deducted menu item promo prices': 'Descuento total de todos los precios promocionales de los productos',
    'Delivery Earnings': 'Ganancias por envío',
    'Delivery cost for customer': 'Costo de envío para el cliente',
    'Customer Delivery Cost:': 'Costo de envío para el cliente:',
    'Order Items': 'Productos del pedido',
    'Order Modifiers': 'Modificadores del pedido',
    'Pickup Time Type': 'Tipo de hora de recogida',
    'Customer Type': 'Tipo de cliente',
    'Refund Method': 'Método de reembolso',
    'Signed Up At': 'Registrado en',
    'Signed Up At:': 'Registrado en:',
    'Last Order At': 'Último pedido en',
    'Delivery Manager': 'Administrador de envíos',
    'Order Details': 'Detalles de la orden',
    'Extra Kilometers': 'Kilómetros extra',
    'Extra Driving Distance': 'Distancia extra de conducción',

    'Failed to load google maps, please refresh page.': 'Hubo un fallo al cargar el mapa, por favor recarga la página',
    'Deliver To': 'Dirección de envío',
    'Delivered By': 'Información del repartidor',
    'Delivered By:': 'Información del repartidor:',
    'Surveys': 'Encuestas',
    'Delivery': 'Envío',
    'Request': 'Solicitar',
    'Assign': 'Asignar',
    'Requesting': 'Solicitando',
    'Today': 'Hoy',
    'Planned orders': 'Pre-ordernar',
    'Enable integrations': 'Habilitar integraciones',
    'Live Support': 'Soporte en Vivo',
    'Contact support': 'Contactar a soporte',
    'Payment Links': 'Links de pago',
    'Search for delivery when completing payment link (manual orders)': 'Buscar repartidor al completar link de pago (ordenes manuales)',
    'Show private info': 'Mostrar información privada',
    'Hide Cart Enabled': 'Ocultar carrito habilitado',
    'Enable ecommerce as digital menu': 'Habilitar ecommerce como menú digital',
    'Add Tax To Total': 'Agregar impuestos al total',
    'Create Payment Link': 'Crear Link de pago',
    'Payment Link': 'Link de pago',
    'Restaurant Promo Codes': 'Restaurante Promo Codes',

    'This card has insufficient funds to complete the purchase. If this is not correct please contact the Card Payments Division of your bank.': 'Esta tarjeta no tiene fondos suficientes para completar la compra. Si esto no es correcto, comunícate con tu banco o con el proveedor de tu tarjeta.',
    'We’re sorry, we’re having difficulty communicating with your bank. At the moment we can not process your request. Please try again later.': 'Lo sentimos, estamos teniendo dificultades para comunicarnos con su banco. Por el momento no podemos procesar su solicitud. Por favor inténtalo más tarde.',
    'Your request was declined by your bank. Please contact the Card Payments Division of your bank or try with another card.': 'Su solicitud fue rechazada por su banco. Comuníquese con la División de Pagos con Tarjeta de su banco o intente con otra tarjeta.',
    'Sorry, you entered an invalid security code (the last 3 digits on the backside of your card). Please remove your card and try again.': 'Lo sentimos, código de seguridad invalido. Favor de verificarlo e intentarlo nuevamente.',

    'Fix form errors and try again.': 'Corrige los errores e intenta de nuevo',
    'Cannot remove last menu': 'No se pudo remover el último menú',

    'Value may not be empty or be equal to 0': 'El valor no puede estar vacío o ser igual a 0',

    'You will subscribe to pay @paymentAmount each month with your card': 'Te suscribiras para pagar @paymentAmount cada mes con tu tarjeta',
    'You have subscribed to pay @paymentAmount each month with your card': 'Te suscribiste para pagar @paymentAmount cada mes con tu tarjeta',
    'Your next payment is @nextPaymentAmount and will be charged: @nextPaymentDate': 'Tu siguiente pago será por @nextPaymentAmount y se realizará: @nextPaymentDate',
    'If you don’t know the security code please contact support to receive it.': 'Si no conoces el código de seguridad, por favor contacta a soporte para obtenerlo.',
    'Security Code': 'Código de seguridad',
    'Please enter the 4 digit security code to continue removing card': 'Por favor ingresa el código de seguridad de 4 dígitos para continuar eliminando la tarjeta',
    'Remove Card': 'Eliminar tarjeta',
    'Removed Card': 'Tarjeta eliminada',

    'Unpaid': 'Sin pagar',
    'Paid': 'Pagado',
    'Refunded': 'Reembolsado',
    'Failed': 'Falló',

    'Insufficient funds': 'Fondos insuficientes',
    'Communication with bank failed': 'Error con la comunicación con el banco',
    'Declined by bank': 'Declinada por el banco',
    'Invalid security code': 'Código de seguridad inválido',

    'Failed to add card': 'Error al agregar tarjeta',
    'Your last payment failed': 'Error en tu último pago',
    'Failed pay for subscription': 'Error al pagar la suscripción',
    'Saved changes and paid for subscription': 'Cambios guardados y suscripción pagada exitosamente',

    'Email Invoices': 'Correo electrónico para facturas',
    'Card Details': 'Detalles de la tarjeta',

    'Payments': 'Pagos',
    'Subscription': 'Subscripción',
    'Date': 'Fecha',
    'Resend Invoice': 'Reenviar Factura',
    'Resend': 'Reenviar',
    'Create Invoice': 'Crear Factura',
    'Create Manual Invoice': 'Crear Factura Manual',

    'Subscription Invoice': 'Factura de Subscripcion',
    ' Go to Subscription': ' Ir a tu Subscripcion',
    'Last Manual Invoice Created': 'Ultima Factura Manual Creada',
    'Last Manual Invoice Folio': 'Último Folio de Factura Manual',
    'Download Invoice PDF': 'Descargar Factura PDF',
    'Download Invoice XML': 'Descargar Factura XML',
    'Download': 'Descargar',

    'Delivery is paid by restaurant': 'El costo de envío lo cubre el restaurante',
    'Pay @amount': 'Pagar @amount',
    'Show Map': 'Mostrar mapa',
    'Hide Map': 'Ocultar mapa',
    'Divide by diner': 'Dividir por comensal',
    'Select items to be charged': 'Selecciona los productos a cobrar',
    'remaining items': 'artículos restantes',
    'Customer @customerNumber': 'Comensal @customerNumber',
    'Pay by diner': 'Dividir por comensal',
    'PromoCode cannot be applied when paying by diner.': 'No se puede aplicar el código de promoción al pagar por comensal.',
    'This product is paid, it cannot be cancelled': 'Este producto está pagado, no se puede cancelar',
    'Driver needed': 'Solicitar un repartidor:',
    'ASAP': 'Ahora',
    'Specify Time': 'Hora especifica',

    'Driver pickup time': 'Hora para recoger',
    'Has to be in the future': 'Tiene que estar en el futuro',

    'QPAY': 'Qpay',
    'CONEKTA': 'Conekta',
    'STRIPE': 'Stripe',

    'GUADALAJARA': 'Guadalajara',
    'MEXICO_CITY': 'Mexico City',
    'MONTERREY': 'Monterrey',
    'PUEBLA': 'Puebla',
    'STOCKHOLM': 'Stockholm',
    'SALTILLO': 'Saltillo',
    'TORREON': 'Torreon',
    'MERIDA': 'Merida',
    'QUERETARO': 'Queretaro',

    'Restaurant Referrals': 'Restaurantes Referidos',
    'Referral Message Title': 'Titulo del mensaje para referir restaurantes',
    'Referral Message Body': 'Cuerpo del mensaje para referir restaurantes',
    'Driver Name': 'Nombre del repartidor',
    'Referral Business Name': 'Nombre del negocio',
    'Referral Message': 'Mensaje de referido',
    'This title will appear on the drivers app on the restaurant referral page.': 'Este es el mensaje que aparecerá como titulo en la pagina de refiere restaurante en la app para repartidores.',
    'This body will appear in the drivers app on the restaurant referral page.': 'Este es el cuerpo del mensaje que aparecerá en la pagina de refiere restaurante en la app para repartidores.',

    '@driverReferralCode Code invitation applied successfully': '@driverReferralCode Código de invitación aplicado exitosamente',
    '@driverReferralCode Invitation code does not exists': '@driverReferralCode Código de invitación no existe',
    'All': 'Todos',
    'Delivered By @name': 'Entregado por @name',
    'Looking for Uber Driver': 'Buscando un repartidor de Uber',
    'Delivery is being handled by an Uber Driver': 'La entrega será hecha por un repartidor de Uber',

    'Requested At': 'Fecha de solicitud',
    'Accepted At': 'Fecha de aceptación',
    'Are you sure you want to reject this driver?': '¿Está seguro de que desea rechazar este conductor?',
    'Are you sure you want to accept this driver?': '¿Está seguro de que desea aceptar este conductor?',
    'Are you sure you want to remove this driver?': '¿Está seguro de que desea eliminar este conductor?',
    'By accepting it, he will be part of the group of your own drivers': 'Al aceptarlo, formará parte de su flotilla de repartidores',
    'By removing him, he will no longer be part of your own driver group': 'Al eliminarlo, ya no formará parte de su flotilla de repartidores',

    'Ban For X Minutes': 'Ban por X minutos',
    'Enable Ban Controls': 'Habilitar los controles de ban',
    'Verify For X Minutes': 'Verificación por X minutos',
    'Enable Verified Controls': 'Habilitar los controles de verificación',
    'Verified': 'Verificado',
    'Not Verified': 'Sin Verificar',
    'No App Payments': 'Sin pagos en aplicación',
    'App Payments': 'Pagos en aplicación',
    'No Cash Payments': 'Sin pagos en efectivo',
    'Cash Payments': 'Pagos en efectivo',
    'Cash Payments for Eat here': 'Pagos en efectivo para Comer aqui',
    'Not Removed': 'Sin eliminar',
    'Banned': 'Baneado',
    'Not Banned': 'Sin Banear',
    'Enable Change CLABE': 'Habilirar modificación en la CLABE',
    'Password to change CLABE': 'Contraseña para cambiar la CLABE',
    'Wrong Password': 'Contraseña incorrecta',
    'Read more': 'Ver más',
    'Close': 'Cerrar',
    'Select payment method': 'Selecciona el método de pago',
    'Select customer': 'Selecciona el comensal',
    'Your order receipt:': 'Orden:',
    'Order cost:': 'Costo de la orden:',
    'Promo Code @promoCode:': 'Código promocional @promoCode:',
    'Promo Code type:': 'Tipo de promoción',
    'Restaurant discount:': 'Descuento del restaurante:',
    'Dynamic Earning': 'Ganancias dinámicas',
    'Deliver By Uber': 'Entrega por Uber',
    'Customer reviews': 'Reviews de clientes',
    'No categories': 'Sin categorías',
    'Last order': 'Última orden',
    '+ Add payment method': '+ Agregar método de pago',
    'Add primary payment method': 'Agregar método de pago',
    'Primary': 'Principal',
    'Back Up': 'Secundario',
    'Add back up payment method': 'Agregar segundo método de pago',
    'When the primary payment method fails, a backup payment method pays the balance automatically': 'Cuando el método de pago principal falla, el segundo realiza el pago automáticamente',
    'Pays with: @mandatoryCashAmount': 'Paga con: @mandatoryCashAmount',
    'Pays with': 'Paga con',
    'Mandatory Cash Amount': 'Cantidad en efectivo',
    'Add Card': 'Agregar tarjeta',
    'Minimum inventory reached (@numberOfSupplies)': 'Inventario mínimo alcanzado (@numberOfSupplies)',
    'To start ordering and have a joyful experience on the restaurant, please type': 'Para empezar a ordenar y tener una gratificante experiencia, porfavor llene los siguientes datos',
    'Delivery channel': 'Servicio de entrega',
    'Auto-accept': 'Auto-aceptar',
    'Preparation Time': 'Tiempo de preparación',
    'Payment method': 'Método de pago',
    'Credit Card Payments': 'Pago con tarjeta',
    'You have a received new orders!': 'Has recibido nuevas órdenes!',
    'You have a new order': 'Tienes una nueva orden',
    'You have @numberOfOrders new orders': 'Tienes @numberOfOrders nuevas órdenes',
    'Show orders': 'Mostrar órdenes',
    'Order completed': 'Orden completada',
    'Order cancelled': 'Orden cancelada',
    'Order rejected': 'Orden rechazada',
    'Expires': 'Expira',
    'Edit': 'Editar',
    'Rotate': 'Rotar',
    'Payment ways': 'Formas de pago',
    'To remove the card, you need to add a second payment method. In case if you want to deactivate the service, please reach out to your sales agent.': 'Para remover este método de pago necesitas agregar otro. En caso de que quieras desactivar el servicio por favor contacta a tu agente de ventas.',

    'Ordering Date': 'Fecha de orden',
    'Ordering Time': 'Tiempo de orden',
    'Integrations': 'Integraciones',
    'Integrated': 'Integrado',
    'Not integrated': 'No integrado',
    'Integrate': 'Integrar',
    'Disintegrate': 'Desintegrar',
    'Integrate with Uber Eats': 'Integrar con Uber Eats',
    'Integrate with Didi Food': 'Integrar con Didi Food',
    'Integrate with Pedidos Ya': 'Integrar con Pedidos Ya',
    'Integrate with Rappi': 'Integrar con Rappi',
    'Disintegrate from Uber Eats': 'Desintegrar de Uber Eats',
    'Disintegrate from Didi Food': 'Desintegrar de Didi Food',
    'Disintegrate from Pedidos Ya': 'Desintegrar de Pedidos Ya',
    'Disintegrate from Rappi': 'Desintegrar de Rappi',

    'Reject by Admin': 'Rechazar como administrador',
    'External Courier Cancelled': 'Comunícate con tu equipo de soporte para más información.',
    'Unassigned Courier': 'Repartidor no asignado',
    'Cancelled by Client Due to Waiting Time': 'Cliente canceló por tiempo de espera',
    'In route to location': 'En ruta al destino',
    '. Reason: ': '. Razón: ',
    'By logging in you agree to': 'Al iniciar sesión aceptas',
    'Terms of use and privacy policy.': 'Términos y Condiciones y Política de Privacidad',
    'Menus': 'Menús',
    'Where is going to show the menu?': '¿Dónde se va a mostrar el menú?',
    'Select the channels available': 'Selecciona los canales de venta disponibles',
    'Channels': 'Canales',
    'Update Integrations Menus': 'Actualizar menus de integración',
    'Integration Enabled': 'Integración Habilitada',
    'Store Online': 'Tienda Online',
    'The image is not valid, please select an image with the right dimensions.': 'La imagen no es valida, por favor selecciona una imagen con las dimensiones correctas',
    'Uber Eats Store': 'Tienda en Uber Eats',
    'Select uber eats stores to integrate': 'Selecciona las tiendas de uber eats a integrar',
    'Please select at least one uber eats store': 'Por favor selecciona al menos una tienda de uber eats',

    'External Platform': 'Compania Externa',
    'External Rating': 'Calificacion Externa',
    'External Total Trips': 'Numero Total de Pedidos Extenos',
    'External Level Name': 'Nombre Del Nivel Externo',
    'External Level Number': 'Numero de Nivel Externo',
    'External Acceptance Rate': 'Radio De Aceptacion Externo',
    'External Delivery Cost:': 'Costo de Envío Externo',
    'Do not use geo location for addresses': 'No usar direcciones con geo localización',
    'External Delivery Estimated Time': 'Tiempo estimado para delivery externo',
    'Select the estimated time for your external delivery': 'Selecciona el tiempo estimado para tu delivery externo',
    'External Debt': 'Deuda Externa',
    'Debt': 'Deuda',
    'Pending Debt': 'Deuda Pendiente',
    'External Cashless': 'Sin Efectivo (Externo)',
    'Is Extenal Expert': 'Es un Experto externo',
    'Order details': 'Detalles del pedido',
    'Order price': 'Precio',
    'Delivery cost': 'Costo de envío',
    'Invoice Delivery Cost': 'Costo de envío facturado',
    'Delivery Cost Paid By': 'Costo de envío pagado por',
    'Rappi Cargo Delivery Cost': 'Costo de envío de Rappi Cargo',
    'Allow cash payments with Uber Daas': 'Permitir pagos con efectivo en Uber Daas',
    'Allow card payments with Rappicargo': 'Permitir pagos con tarjeta de credito en RappiCargo',
    'Assign Drivers By Restaurant Enabled': 'Asignar conductores por restaurante Habilitado',
    'Pide Directo Delivery Enabled': 'Entrega Pide Directo habilitada',
    'Uber Daas Delivery Enabled': 'Entrega Uber Daas habilitada',
    'Web Card Uber Daas Orders': 'Órdenes Web de Uber Daas con tarjeta',
    'Web Cash Uber Daas Orders': 'Órdenes Web de Uber Daas con efectivo',
    'POS Card Uber Daas Orders': 'Órdenes POS de Uber Daas con tarjeta',
    'POS Cash Uber Daas Orders': 'Órdenes POS de Uber Daas con efectivo',
    'Rappi Cargo Delivery Enabled': 'Entrega de Rappi Cargo habilitada',
    'Uber Eats Delivery Enabled': 'Entrega de Uber Eats habilitada',
    'Enable App Update Controls': 'Habilitar los controles de actualización de aplicaciones',
    'Has a Mandatory Update To PideDirecto App (WLA) Enabled': 'Tiene una Actualización Obligatoria para la PideDirecto App (WLA) habilitada',
    'Has a Mandatory Update To Admin App Enabled': 'Tiene una Actualización Obligatoria para la Admin App habilitada',
    'Has a Mandatory Update To Driver App Enabled': 'Tiene una Actualización Obligatoria para la Driver App habilitada',
    'WhatsApp Authentication Enabled': 'Autenticación con WhatsApp habilitada',
    'This will display a message that the application must be updated for further use and will block the use of the application.': 'Esto mostrará un mensaje que se debe actualizar la aplicación para seguir utilizando y bloqueará el uso de la misma.',
    'Order status': 'Status de orden',
    'Support': 'Soporte',
    'Ops': 'Operaciones',
    'Operations': 'Operaciones',
    'Show more': 'Ver más',
    'Open order': 'Abrir orden',
    'Delivery security code:': 'Código de seguridad de entrega:',
    'New': 'Nuevo',
    'Assigning courier': 'Asignando repartidor',
    'Courier assigned': 'Repartidor asignado',
    'Picked up': 'Recogido',
    '💵 Cash': '💵 Efectivo',
    '💳 Credit Card': '💳 Tarjeta',
    '💳 Payment Link': '💳 Link de pago',
    'Driver': 'Repartidor',
    'Vehicle': 'Vehículo',
    'Car plate': 'Placa',
    'Member since': 'Miembro desde',
    'VehicleTypes.CAR': 'Coche',
    'VehicleTypes.MOTORCYCLE': 'Motocicleta',
    'VehicleTypes.BICYCLE': 'Bicicleta',
    'VehicleTypes.PEDESTRIAN': 'Peatón',
    'Select a Vehicle': 'Selecciona un vehículo',
    'Created at': 'Creado',
    'Occurred at': 'Creado',
    'Picked up at': 'Recogido',
    'Time without driver': 'Tiempo sin repartidor',
    'External Product Id': 'Id de producto externo',

    'Deliver orders from DidiFood': 'Entregar órdenes de DidiFood',
    'Deliver orders from UberEats': 'Entregar órdenes de UberEats',
    'Agree': 'De acuerdo',
    'Do you wish to stop delivering DidiFood Orders by PideDirecto?': 'Deseas dejar de entregar las órdenes de DidiFood con PideDirecto?',
    'Do you wish to start delivering DidiFood Orders by PideDirecto?': 'Deseas empezar a entregar las órdenes de DidiFood con PideDirecto?',
    'Do you wish to stop delivering UberEats Orders by PideDirecto?': 'Deseas dejar de entregar las órdenes de UberEats con PideDirecto?',
    'Do you wish to start delivering UberEats Orders by PideDirecto?': 'Deseas empezar a entregar las órdenes de UberEats con PideDirecto?',
    'Do you wish to stop delivering Rappi Orders by PideDirecto?': 'Deseas dejar de entregar las órdenes de Rappi con PideDirecto?',
    'Do you wish to start delivering Rappi Orders by PideDirecto?': 'Deseas empezar a entregar las órdenes de Rappi con PideDirecto?',
    'Contact your vendor or support in order do desintegrate Rappi': 'Contacta a tu vendedor o a soporte para desintegarar Rappi',
    'Contact your vendor or support in order do desintegrate Pedidos Ya': 'Contacta a tu vendedor o a soporte para desintegarar Pedidos Ya',
    'Cancelled Orders': 'Órdenes Canceladas',
    'Created Orders': 'Órdenes Creadas',

    '@days Days Left to pay your service': '@days dias para pagar tu servicio',
    'Your free trial ends on @days days': 'Tu prueba gratuita termina en @days dias',
    'Pay now and avoid suspension of your service': 'Paga ahora y evita la suspension de tu servicio',
    'Suspended Service': 'Servicio Suspendido',
    'Pay your bill to continue using your service': 'Paga ahora para continuar usando tu servicio',
    'You are close to reaching the usage limit of your pay per use subscription': 'Estas apunto de alcanzar el limite de tu suscripción de pago por uso',

    'success': 'Exitosos',
    'failed': 'Fallos',
    'Title': 'Titulo',
    'Text Body': 'Mensaje',
    'Send': 'Enviar',
    'Sending': 'Enviando',
    'Subject': 'Asunto',
    '@name will be replaced with customers first name or client': '@name será reemplazado por el nombre del cliente',
    'Send Sms': 'Enviar Sms',
    'SMS Text': 'Mensaje SMS',
    '(INVOICED)': '(FACTURADOS)',
    '@amount (@invoiced TO INVOICE)': '@amount (@invoiced POR FACTURAR)',
    'Paid by Restaurant': 'Pagado por tienda',
    'Restaurant should have at least 1 enabled delivery provider': 'El Restaurante debe tener al menos 1 proveedor de delivery habilitado',
    'Pide Directo Delivery should be enabled when own fleet is enabled': 'La Entrega de Pide Directo debe estar habilitada cuando se quiere habilitar Flotilla Propia',
    'Delivery is paid by PideDirecto': 'El envío lo cubre PideDirecto',
    'Refund Amount': 'Reembolsar Cantidad',
    'Amount': 'Cantidad',
    'Refund': 'Reembolsar',
    'Refunding': 'Reembolsando',
    'The maximum amount possible to refund for this order is @maximumAmountToRefund': 'La cantidad máxima posible para reembolsar en esta orden es de @maximumAmountToRefund',
    'Rating': 'Calificación',
    'Recommendation': 'Recomendación',

    'Planned order: @pickUpTime': 'Orden planeada: @pickUpTime',

    'More Info': 'Más Información',
    'Driver arrives at store: approx @minutes minutes': 'Tiempo de llegada a la sucursal: aprox @minutes minutos',
    'TIME_30_40_MIN': 'Repartidor llega a la sucursal en 30 - 40 min',
    'TIME_40_50_MIN': 'Repartidor llega a la sucursal en 40 - 50 min',
    'TIME_50_60_MIN': 'Repartidor llega a la sucursal en 50 - 60 min',

    'Port (Leave it empty if not known)': 'Puerto (Dejarlo vacío si no se conoce)',
    'Device Name (Eg: Cocina)': 'Nombre del dispositivo (Ejemplo: Cocina)',
    'Printer Name (Eg: Cocina)': 'Nombre de la impresora (Ejemplo: Cocina)',
    'Printer Brand': 'Marca de la impresora',
    'Make sure that the bluetooth printer is paired with your device': 'Asegúrate que la impresora bluetooth está conectada con el dispositivo',
    'New Printer UI Enabled': 'Habilitar Nuevo UI de Impresoras',
    'Paper Size': 'Tamaño del papel',
    'Paper Size 58MM': 'Tamaño del papel 58MM',
    'Selected printer name:': 'Nombre de impresora seleccionada:',

    'Create Banner': 'Crear Banner',
    'Enabled': 'Habilitado',
    'Pinned': 'Prioridad',
    'Are you sure you want to remove the selected banner': '¿Estás seguro que deseas remover el banner seleccionado?',
    'Are you sure you want to remove the selected @number banners': '¿Estás seguro que deseas remover los @number banners seleccionados?',

    'App Only': 'Solo App',
    'Web Only': 'Solo Web',
    'Only For': 'Solo Para',
    'Order Ready': 'Orden Lista',
    'Order ready for pickup': 'Orden lista para retirar',

    'MX': 'México',
    'SE': 'Suecia',
    'US': 'Estados Unidos',
    'AR': 'Argentina',
    'UY': 'Uruguay',

    'Countries.MX': 'México',
    'Countries.SE': 'Suecia',
    'Countries.US': 'Estados Unidos',
    'Countries.UY': 'Uruguay',
    'Countries.AR': 'Argentina',
    'Countries.BR': 'Brasil',
    'Countries.PE': 'Perú',
    'Countries.CL': 'Chile',
    'Countries.PY': 'Paraguay',

    'AmbitOne POS': 'Punto de Venta',
    'Choose a order type': 'Elige un tipo de orden',
    'TAKE_AWAY_ORDER': 'Para Llevar',
    'DELIVERY_ORDER': 'Entrega',
    'ROOM_SERVICE_ORDER': 'Servicio de habitaciones',
    'TABLE_ORDER': 'Comer Aquí',
    'SELF_SERVICE_ORDER': 'Kiosco',
    'PICKUP_STATION_ORDER': 'Estación PickUp',
    'REQUIRED': 'REQUERIDO',
    'Articles': 'Artículos',
    'Add Note': 'Agregar Nota',
    'Edit Note': 'Editar Nota',
    'Add item note': 'Agregar nota al artículo',
    'Edit item note': 'Editar nota del artículo',
    'Note': 'Nota',
    'Any instruction that may increase the check value will be ignored': 'Cualquier instrucción que pueda aumentar el valor del producto va a ser ignorada',
    'Articles Subtotal': 'Subtotal de Artículos',
    'Pay': 'Pagar',
    'Please select a payment method.': 'Por favor selecciona un método de pago.',
    'Charge': 'Cobrar',
    'Charge and close': 'Cobrar y cerrar',
    'change': 'Cambio',
    'Payment missing': 'Pago Faltante',
    'You cannot change the payment method if you do not have full payment': 'No puede cambiar el método de pago si no tiene el pago completo',
    'There is pending balance.': 'Todavía hay pago faltante.',
    'Save Order': 'Guardar Orden',
    'Saving Order': 'Guardando Orden',
    'No internet connection!': 'Sin conexión a internet!',
    'Failed to do the payment.': 'Hubo un error al realizar el pago.',
    'Paying': 'Pagando',
    'Paying in terminal ...': 'Pagando en la terminal ...',
    'Order Id': 'Orden Id',
    'Categories': 'Categorías',
    'Filter': 'Filtro',
    'Save Without Payment': 'Guardar sin Pago',
    'Save Without Charging': 'Guardar sin cobrar',
    'Order pending': 'Orden pendiente',
    'Pending': 'Pendiente',
    'Payment Method Name': 'Nombre del método de pago',
    'Payment Method': 'Método de pago',
    'Commission': 'Comisión',
    'Total Commission': 'Comisión Total',
    'Add Payment Method': 'Agregar método de pago',
    'CREDIT_CARD': 'Tarjeta',
    'PAYMENT_TERMINAL': 'Pago por terminal',
    'CARD_ON_DELIVERY': 'Tarjeta al entregar',
    'TRANSFER': 'Transferencia',
    'CASH': 'Efectivo',
    'PAYMENT_LINK': 'Link de pago',
    'Are you sure you want to remove the payment method @paymentMethod?': '¿Estás seguro que deseas remover el método de pago @paymentMethod?',
    'Remove Payment Method': 'Eliminar método de pago',
    'Removing': 'Eliminando',
    'Custom Payment Methods': 'Métodos de pago creados',
    'Save Address Info': 'Guardar dirección',
    'Update Order': 'Actualizar orden',
    'Updating Order': 'Actualizando orden',
    'Edit Address': 'Editar dirección',
    'Start over': 'Volver a empezar',
    'Yes, start over': 'Sí, volver a empezar',
    'Are you sure you want to start over?': '¿Estás seguro de volver a empezar?',
    'You have items in your order, if you start over they will be lost': 'Tienes artículos en tu orden, si vuelves a empezar se perderán',

    'Cash registers': 'Cajas',
    'Shift Report': 'Corte de caja',
    'Shift Report @cashRegisterNumber': 'Corte de caja @cashRegisterNumber',
    'Last cash register shift report': 'Último corte de caja',
    'Close Cash Register': 'Cierre de día',
    'Close business day of cash register @cashRegisterNumber': 'Cierre de día caja @cashRegisterNumber',
    'Make closing day': 'Realizar cierre de día',
    'Cash Register': 'Caja',
    'Opened by': 'Abierto por',
    'Opened': 'Abierto',
    'Closed': 'Cerrado',
    'Closed by': 'Cerrado por',
    'Deposit': 'Depositar',
    'Withdraw': 'Retirar',
    'Deposit Reason': 'Razón de depósito',
    'Depositing': 'Depositando',
    'Initial Cash': 'Efectivo inicial',
    'Open Cash Register': 'Abrir caja',
    'CashRegisterDepositReasons.CHANGE': 'Cambio',
    'CashRegisterWithdrawReasons.DRIVERS': 'Repartidores',
    'CashRegisterWithdrawReasons.DELIVERY': 'Envío',
    'ProductCancellationReasons.CUSTOMER_DIDNT_LIKE_PRODUCT': 'No le gustó al cliente',
    'ProductCancellationReasons.DEFECTIVE_PRODUCT': 'Producto defectuoso',
    'ProductCancellationReasons.CAPTURE_ERROR': 'Error de captura',
    'Withdraw Reason': 'Razón de retiro',
    'Withdrawing': 'Retirando',
    'Custom Withdraw Reasons': 'Razones de retiro creadas',
    'Withdraw Reasons': 'Razones de retiro',
    'Custom Deposit Reasons': 'Razones de deposito creadas',
    'Deposit Reasons': 'Razones de deposito',
    'Cancellation Product Reasons': 'Razones de cancelación de producto',
    'Cancellation Product Reason': 'Razón de cancelación de producto',
    'Add Withdraw Reason': 'Agregar razón de retiro',
    'Add Deposit Reason': 'Agregar razón de deposito',
    'Add Cancellation Product Reason': 'Agregar razón de cancelación de producto',
    'Remove Withdraw Reason': 'Remover razón de retiro',
    'Remove Deposit Reason': 'Remover razón de deposito',
    'Remove Cancellation Product Reason': 'Remover razón de cancelación de producto',
    'Are you sure you want to remove the custom reason "@cashRegisterCustomReason?"': '¿Estás seguro que deseas remover la razón de "@cashRegisterCustomReason?"',
    'Are you sure you want to remove the custom reason "@customProductCancellationReason?"': '¿Estás seguro que deseas remover la razón de "@customProductCancellationReason?"',
    'The cash register is not opened. Please go to the cash register section and open it.': 'La caja no está abierta. Por favor ve a la sección de cajas y abrela.',
    'The cash register is not opened': 'La caja no está abierta',
    'In order to create a new order, you must first open the cash register': 'Para crear una nueva orden, debe abrir primero la caja registradora',
    'To accept an order you must open the cash register': 'Para aceptar una orden la caja debe estar abierta',
    "Remember once you closed the cash register you won't be able to create orders in the pos.": 'Recuerda que una vez cerrada la caja no podrás crear órdenes en el punto de venta.',
    'Orders included in the count:': 'Órdenes incluidas en el conteo:',
    'Expected Amount': 'Monto Esperado',
    'Initial Amount': 'Efectivo inicial',
    'Difference': 'Diferencia',
    'Difference Value': 'Valor de la Diferencia',
    'Cash Registers': 'Cajas',
    'Active Cash Registers': 'Cajas activas',
    'Declared Amount': 'Efectivo declarado',
    'Deposits': 'Depositos',
    'Withdraws': 'Retiros',
    'Resume': 'Resumen',
    'User': 'Usuario',
    'Reason': 'Razón',
    'Reason: @reason': 'Razón: @reason',
    'Cash Register Transactions': 'Movimientos de Caja',
    'LETSEATAPP': "Let's Eat",
    'PIDEDIRECTO': 'AmbitOne Menú Web',
    'LETSEATMANAGER': 'Manual Order',
    'PIDEDIRECTOAPP': 'AmbitOne Menú App',
    'PIDEDIRECTOPOS': 'Punto de Venta',
    'PEDIDOS_YA': 'Pedidos ya',

    'PIDEDIRECTO_API': 'PideDirecto API',
    'RAPPI': 'Rappi',
    'RAPPI_CARGO': 'Rappi Cargo',
    'UBER_DIRECT': 'Uber Direct',
    'UBER_DAAS': 'Uber Daas',
    'DIDI_FOOD': 'Didi Food',
    'UBER_EATS': 'Uber Eats',
    'PIDEDIRECTOKIOSK': 'PideDirecto Kiosko',

    'DeliveryProviders.UBER_DIRECT': 'Uber Direct',
    'DeliveryProviders.UBER_DAAS': 'Uber Daas',
    'DeliveryProviders.RAPPI_CARGO': 'Rappi Cargo',
    'DeliveryProviders.RESTAURANT': 'Restaurant',
    'DeliveryProviders.PIDEDIRECTO': 'Pide Directo',

    'IntegrationPlatforms.RAPPI': 'Rappi',
    'IntegrationPlatforms.UBER_EATS': 'Uber Eats',
    'IntegrationPlatforms.DIDI_FOOD': 'Didi Food',
    'IntegrationPlatforms.PEDIDOS_YA': 'Pedidos Ya',

    'Please upload a csv file with columns named NOMBRE, TELEFONO and EMAIL': 'Por favor sube un archivo csv con columnas llamadas NOMBRE, TELEFONO y EMAIL .',

    'Tables': 'Mesas',
    'There is no restaurant zones created': 'No hay zonas creadas',
    'Create Restaurant Zone': 'Crear Zona',
    'There is no tables created': 'No hay mesas creadas',
    'Create Table': 'Crear Mesa',
    'SMALL': 'Pequeña',
    'MEDIUM': 'Mediana',
    'LARGE': 'Grande',
    'SQUARE': 'Cuadrado',
    'CIRCLE': 'Círculo',
    'RECTANGLE': 'Rectángulo',
    'Shape': 'Forma',
    'Save Changes': 'Guardar Cambios',
    'Change Restaurant Zone': 'Cambiar Zona',
    'There is already a table with the same number, please change it.': 'Ya existe una mesa con el mismo número, por favor cambialo.',
    'Change Table': 'Cambiar Mesa',
    'Change Table of Order': 'Cambiar mesa de la orden',
    'Change Zone': 'Cambiar Zona',
    'Pos Settings': 'Configuración de POS',
    'Restaurant Zone': 'Zona',
    'Number of Customers': 'Número de comensales',
    'Change Number of Customers': 'Cambiar número de comensales',
    'How many guests are at the table?': '¿Cuántos comensales son en la mesa?',
    'customers': 'clientes',
    'Open at @time': 'Abierta a las @time',
    'Ticket requested at @ticketRequestedTime': 'Cuenta solicitada a las @ticketRequestedTime',
    'POS Restaurant Zones Enabled': 'POS zonas de restaurante habilitada',
    'POS Restaurant Areas Enabled': 'POS áreas de restaurante habilitada',
    'POS Show Tables When Order Enabled': 'POS mostrar mesas cuando se ordena habilitado',
    'POS Simple View': 'POS vista simple',
    'Link This Restaurant To Other CSD Certificate': 'Vincular este restaurante a otro certificado CSD',
    'Extra fast sale': 'Venta extra rápida',
    'Activate the simple view in the POS': 'Activa la vista simple en el POS',
    'Choose a table': 'Elige una mesa',
    'POS Table Required Enabled': 'POS selección de mesa requerido habilitado',
    'POS Number Of Customers Required Enabled': 'POS número de clientes requerido habilitado',
    'POS Customizable discount comments': 'POS comentarios personlizados para descuentos',
    'POS Cash Count Only in Cash Register': 'POS Solo conteo de efectivo en caja',
    'POS Request customer name on take away orders enabled': 'POS Requerir nombre del comensal en órdenes para llevar habilitado',
    'POS Customer name on take away orders required': 'POS Solicitar nombre de comensal en órdenes Para Llevar',
    'POS Take orders per course': 'POS Tomar órdenes por tiempo',
    'Manage courses': 'Administrar tiempos',
    'Request customer name on create order': 'Solicitar nombre de comensal al crear órden',
    'Activate this option if you want the name of the customer to be required when creating an order': 'Activa esta opción si desea que el nombre del comensal sea obligatorio al crear una orden',
    'Importing': 'Importando',

    'Digital Seal': 'Sello digital',
    'Upload Certificate .cer': 'Subir certificado .cer',
    'Upload Key .key': 'Subir llave .key',
    'Certificate Password': 'Contraseña del certificado',
    'Remove Digital Seal': 'Eliminar sello digital',
    'Are you sure you want to remove the digital seal?': '¿Estás seguro que deseas remover el sello digital?',
    'Unlink Digital Seal': 'Desvincular sello digital',
    'Are you sure you want to unlink the digital seal?': '¿Estás seguro que deseas desvincular el sello digital?',
    'Created At': 'Creado',
    'Occurred At': 'Sucedió',
    'Order Invoices': 'Facturas de Órdenes',
    'Global Order Invoice': 'Facturar',
    'Tax Free invoices for TakeAway orders': 'Facturar IVA 0% en órdenes para llevar',
    'See more': 'Ver más',
    'See less': 'Ver menos',
    'See order': 'Ver orden',
    'Tax Rate': 'Tasa de impuesto',
    'Minimum Order Amount': 'Monto mínimo de orden',
    'Maximum Order Amount': 'Monto máximo de orden',

    'Inventory': 'Inventario',
    'Suppliers': 'Proveedores',
    'Supplies': 'Insumos',
    'Recipes': 'Recetas',
    'Supply': 'Insumo',
    'Create Base Supply': 'Crear insumo base',
    'Create Supply': 'Crear insumo',
    'Supply History': 'Historial de Insumos',
    'Purchases': 'Compras',
    'Are you sure you want to remove the supply @supplyName?': '¿Estás seguro que deseas remover el insumo @supplyName?',
    'Are you sure you want to remove the supplier @businessName?': '¿Estás seguro que deseas remover el proveedor @businessName?',
    'Are you sure you want to remove the recipe @recipeName??': '¿Estás seguro que deseas remover la receta @recipeName?',
    'Remove Supply': 'Remover insumo',
    'Change Supply': 'Editar insumo',
    'Produce @name': 'Producir @name',
    'Producing': 'Produciendo',
    'Category': 'Categoría',
    'Buy Unit': 'Unidad de compra',
    'Sell Unit': 'Unidad de venta',
    'Production Unit': 'Unidad de producción',
    'Create and Produce': 'Crear y producir',
    'Create and produce this supply after saving': 'Crear y producir este insumo después de guardar',
    'Waste %': 'Merma %',
    'Minimum': 'Mínimo',
    'Maximum': 'Máximo',
    'Email alert on supply limit': 'Email de alerta de limite de inventario',
    'Re-Order Supply Alert': 'Alerta de pre-ordenar',
    'Minimum Quantity': 'Cantidad mínima',
    'Minimum inventory limit': 'Límite de inventario mínimo',
    'Estimated Cost: @totalEstimatedCost': 'Costo estimado: @totalEstimatedCost',
    'Minimum inventory alert': 'Alerta de inventario mínimo',
    'Minimum inventory warning': 'Aviso de inventario mínimo',
    'Do not show again for 1 hour': 'No mostrar de nuevo por 1 hora',
    'Supplier': 'Proveedor',
    'Pending Balance': 'Saldo pendiente',
    'Comments': 'Comentarios',
    'Create Order': 'Crear pedido',
    'Average Waste': 'Merma promedio',
    'Conversion Factor': 'Factor de conversión',
    'Do Count': 'Realizar conteo',
    'Unit of Measurement': 'Unidad',
    'UnitOfMeasurements.KILOGRAM': 'Kilogramo',
    'UnitOfMeasurements.GRAM': 'Gramo',
    'UnitOfMeasurements.LITRE': 'Litro',
    'UnitOfMeasurements.MILLILITER': 'Mililitro',
    'UnitOfMeasurements.BOX': 'Caja',
    'UnitOfMeasurements.BAG': 'Bolsa',
    'UnitOfMeasurements.PIECE': 'Pieza',
    'UnitOfMeasurements.PACKAGE': 'Paquete',
    'UnitOfMeasurements.STICK': 'Barra',
    'UnitOfMeasurements.ROLL': 'Rollo',
    'UnitOfMeasurements.CAN': 'Bote',
    'UnitOfMeasurements.SLICE': 'Porción',
    'UnitOfMeasurements.KILOGRAMS': 'Kilogramos',
    'UnitOfMeasurements.GRAMS': 'Gramos',
    'UnitOfMeasurements.LITRES': 'Litros',
    'UnitOfMeasurements.MILLILITERS': 'Mililitros',
    'UnitOfMeasurements.BOXES': 'Cajas',
    'UnitOfMeasurements.BAGS': 'Bolsas',
    'UnitOfMeasurements.PIECES': 'Piezas',
    'UnitOfMeasurements.PACKAGES': 'Paquetes',
    'UnitOfMeasurements.STICKS': 'Barras',
    'UnitOfMeasurements.ROLLS': 'Rollos',
    'UnitOfMeasurements.CANS': 'Botes',
    'UnitOfMeasurements.SLICES': 'Porciones',
    'UnitOfMeasurements.POUND': 'Libra',
    'UnitOfMeasurements.OUNCE': 'Onza',
    'UnitOfMeasurements.TABLESPOON': 'Cucharada',
    'UnitOfMeasurements.CUP': 'Taza',
    'UnitOfMeasurements.GALLON': 'Galón',
    'UnitOfMeasurements.QUART': 'Cuarto',
    'UnitOfMeasurements.POUNDS': 'Libras',
    'UnitOfMeasurements.OUNCES': 'Onzas',
    'UnitOfMeasurements.TABLESPOONS': 'Cucharadas',
    'UnitOfMeasurements.CUPS': 'Tazas',
    'UnitOfMeasurements.GALLONS': 'Galones',
    'UnitOfMeasurements.QUARTS': 'Cuartos',
    'Inventory Logs History': 'Historial de ajustes',
    'InventoryTransactionTypes.IN': 'Entrada',
    'InventoryTransactionTypes.OUT': 'Salida',
    'InventoryLogTypes.INVENTORY_RECEIPT': 'Entrada',
    'InventoryLogTypes.INVENTORY_ISSUE': 'Salida',
    'InventoryLogIssues.TRANSFERENCE': 'Transferencia',
    'InventoryLogIssues.WASTE': 'Merma',
    'InventoryLogIssues.ACCIDENT': 'Accidente',
    'InventoryLogReceipts.TRANSFERENCE': 'Transferencia',
    'InventoryLogReceipts.DELIVERY': 'Entrega',
    'InventoryLogReceipts.BUY_ORDER': 'Orden de compra',
    'InventoryTransactionReasons.TRANSFERENCE': 'Transferencia',
    'InventoryTransactionReasons.WASTE': 'Merma',
    'InventoryTransactionReasons.ACCIDENT': 'Accidente',
    'InventoryTransactionReasons.ORDER': 'Orden de compra',
    'InventoryTransactionReasons.PURCHASE': 'Compra',
    'InventoryTransactionReasons.ADJUSTMENT': 'Ajuste',
    'InventoryTransactionReasons.CANCEL': 'Cancelación',
    'InventoryTransactionReasons.COUNT_UPDATE': 'Actualización de conteo',
    'InventoryTransactionReasons.BUY_ORDER': 'Orden de compra',
    'InventoryTransactionReasons.PRODUCE_COMPOUND_SUPPLY': 'Produccion de insumo compuesto',
    'Create Inventory Log': 'Crear ajuste de inventario',
    'Unit': 'Unidad',
    'Inventory Log Type': 'Tipo de ajuste',
    'Inventory Log Issue': 'Tipo de salida',
    'Inventory Log Receipt': 'Tipo de entrada',
    'Inventory Log Movement': 'Tipo de movimiento',
    'Only Orders': 'Solo Órdenes',
    'Only Restaurants': 'Solo Restaurantes',
    'Go Back': 'Volver',
    'Day End Reports': 'Reportes de cierre de día',
    'Inventory Count': 'Conteo de inventario',
    'Actual Inventory': 'Inventario Real',
    'Theoretical Inventory': 'Inventario Teórico',
    'Real Quantity': 'Cantidad real',
    'Real Kitchen Price': 'Precio real de cocina',
    'Estimated Quantity': 'Cantidad estimada',
    'Go to Inventory': 'Ir al inventario',
    'Pieces of @supplyItem': 'Piezas de @supplyItem',
    'Recipes affected': 'Recetas afectadas',
    'Report': 'Reporte',
    'Select CSV File': 'Selecciona el archivo CSV',
    '@customersTried of @customersToImport': '@customersTried de @customersToImport',
    '@customersTried of @customersToTry': '@customersTried de @customersToTry',
    'Please dont close this window, it could take a couple of minutes.': 'Por favor no cierres esta ventana, esto podría tomar un par de minutos.',
    'Pide Directo': 'Pide Directo',
    'Unit of measurement produced for this input. Example: it is prepared per liter, per kilo, per piece, etc...': 'Unidad de medida producida para este insumo. Ejemplo: se prepara por litro, por kilo, por pieza, etc...',
    'Unit of sale for this input. Example: sold per liter, per kilo, per piece, etc...': 'Unidad de venta para este insumo. Ejemplo: se vende por litro, por kilo, por pieza, etc...',

    'Days.Mo': 'Lunes',
    'Days.Tu': 'Martes',
    'Days.We': 'Miércoles',
    'Days.Th': 'Jueves',
    'Days.Fr': 'Viernes',
    'Days.Sa': 'Sábado',
    'Days.Su': 'Domingo',
    'ScheduleTaskIntervals.EACH_MONTH': 'Cada mes',
    'ScheduleTaskIntervals.EACH_WEEK': 'Cada semana',
    'ScheduleTaskIntervals.EACH_DAY': 'Cada día',
    'ScheduleTaskIntervals.NO_REPEAT': 'Sin repetición',
    'Time Interval': 'Intervalo de tiempo',
    'Day Number Of Month': 'Número del día del mes',
    'Are you sure you want to remove this scheduled notification?': '¿Estás seguro que deseas remover está notificación programada?',
    'Create Schedule Notification': 'Crear notificación programada',
    'day': 'día',
    'Scheduled At': 'Programado',
    'Hour': 'Hora',
    'Notification Message Type': 'Tipo de notificación',
    'Message': 'Mensaje',
    'Add Promo Code': 'Agregar código de descuento',
    'Discount Type': 'Tipo de descuento',
    'Scheduled Notifications': 'Notificaciones programadas',
    'Push notifications': 'Notificaciones push',
    'SMS': 'SMS',
    'PERCENT': 'Porcentaje',
    'AMOUNT': 'Cantidad',
    'Discount': 'Descuento',
    'CustomerActivities.D1': 'No ha ordenado desde hace 1 día',
    'CustomerActivities.D3': 'No ha ordenado desde hace 3 días',
    'CustomerActivities.D5': 'No ha ordenado desde hace 5 días',
    'CustomerActivities.D7': 'No ha ordenado desde hace 7 días',
    'CustomerActivities.D10': 'No ha ordenado desde hace 10 días',
    'CustomerActivities.D15': 'No ha ordenado desde hace 15 días',
    'CustomerActivities.D30': 'No ha ordenado desde hace 30 días',
    'CustomerActivities.D60': 'No ha ordenado desde hace 60 días',
    'CustomerActivities.D90': 'No ha ordenado desde hace 90 días',
    'Customer Activity': 'Actividad el cliente',
    'Write @promoCode and it will be replaced by the promo code when the message has been sent': 'Escribe @promoCode y será remplezado por el código de promoción cuando se envíe el mensaje',
    'Delivery at': 'Entregar',
    'App Orders Cashback Enabled': 'Cashback en órdenes de app activado',
    'Kiosk Orders Cashback Enabled': 'Cashback en órdenes de Kiosko activado',
    'Ecommerce Orders Cashback Enabled': 'Cashback en órdenes de Ecommerce activado',
    'App Orders Cashback %': 'Porcentaje de cashback en órdenes de app %',
    'Kiosk Orders Cashback %': 'Porcentaje de cashback en órdenes de Kiosko %',
    'Ecommerce Orders Cashback %': 'Porcentaje de cashback en órdenes de Ecommerce %',
    'The printer was set as default': 'La impresora se estableció como la principal',
    'A testing ticket was sent': 'Se envío un ticket de prueba',
    'Units': 'Unidades',
    'DefaultSupplyCategories.DRINKS': 'Bebidas',
    'DefaultSupplyCategories.PERISHABLES': 'Perecederos',
    'DefaultSupplyCategories.FROZEN': 'Congelados',
    'DefaultSupplyCategories.PROTEINS': 'Proteínas',
    'DefaultSupplyCategories.VEGETABLES': 'Vegetales',
    'DefaultSupplyCategories.DRESSINGS': 'Aderezos',
    'DefaultSupplyCategories.PACKING': 'Empaque',
    'DefaultSupplyCategories.DAIRIES': 'Lacteos',
    'Record': 'Historial',
    'Created By': 'Creado por',
    'Inventory Reports History': 'Historial de conteos de inventario',
    'Supplies Inventoried': 'Items inventariados',
    'Menu Inventoried': 'Menú inventariado',
    'Inventory Value': 'Costo de inventario',
    'Pieces on Inventory': 'Piezas en inventario',
    'Last Inventory Log': 'Último ajuste',
    'Last Adjustment: @value por @lastCountUpdateMadeBy': 'Último ajuste: @value por @lastCountUpdateMadeBy',
    'User Management': 'Administración de usuarios',
    'UserTypes.ADMIN': 'Admin',
    'UserTypes.SUPPORT': 'Soporte',
    'UserTypes.OPERATIONS': 'Operaciones',
    'UserTypes.VIEW': 'View',
    'UserTypes.KITCHENS': 'Kitchens',
    'UserTypes.RESTAURANT': 'Restaurante',
    'UserTypes.CASHIER': 'Cajero',
    'UserTypes.WAITER': 'Mesero',
    'UserTypes.KIOSK': 'Kiosk',
    'UserTypes.KITCHEN_DISPLAY_SCREEN': 'Kitchen Display',
    'User Type': 'Tipo de usuario',
    'Skip': 'Omitir',
    'Update Inventory': 'Actualizar inventario',
    'Do you want to update the inventory with this report?': '¿Deseas actualizar el inventario con este conteo?',
    'Main Unit': 'Unidad principal',
    'Period': 'Período',
    'Multiple Payment Methods': 'Multiples métodos de pago',
    'Multiple': 'Multiple',
    'Initial Cost': 'Costo inicial',
    'Estimated Cost': 'Costo estimado',
    'Recipe': 'Receta',
    'Ingredients': 'Ingredientes',
    'Turn off products': 'Apagar productos',
    'Minimum stock reached ( @numberOfOrders )': 'Mínimo de stock alcanzado ( @numberOfOrders )',
    'Menu Products': 'Productos de tu menú',
    'Quantity @unit': 'Cantidad @unit',
    'Import Menu from External URL': 'Importar Menú de un URL externo',
    'Import Menu From CSV File': 'Importar Menú de un archivo CSV',
    'Import supplies From CSV File': 'Importar insumos de un archivo CSV',
    'Create Restaurant Menu Promotion': 'Crear una promoción en el Menú',
    'Delivery Cost Bonus:': 'Bonus del envío:',
    'Dynamic Delivery Earning:': 'Ganancias dinámicas del envío:',
    'Pos Settings Password': 'Contraseña para ajustes en el POS',
    'Print Order': 'Imprimir orden',
    'Tip': 'Propina',
    'Tip Type': 'Tipo de propina',
    'Add Tip': 'Agregar propina',
    'Add Discount': 'Agregar descuento',
    'By products': 'Por productos',
    'Product Discount': 'Descuento por producto',
    'Discount per product': 'Descuento por producto',
    'Unauthorized to access.': 'No estás autorizado.',
    'Authorize': 'Autorizar',
    'POS Discount:': 'Descuento del POS:',
    'Complete Order': 'Completar orden',
    'Charge and Invoice': 'Cobrar y facturar',
    'Order saved': 'Orden guardada',
    'Order saved and printed': 'Orden guardada e impresa',
    'Order created': 'Orden creada',
    'Paying Order': 'Pagando orden',
    'Your other stores are closed': 'Tus otras tiendas están cerradas',
    'Store paused, after hours': 'Tienda en pausa, fuera de horario',
    'Store online': 'Tienda abierta',
    'Open all stores': 'Abrir todas las tiendas',
    'Open store': 'Abrir tienda',
    'Web Menu Card': 'Tarjeta Menú web',
    'Web Menu Cash': 'Efectivo Menú web',
    'Web Menu Transfer': 'Transferencia Menú web',
    'Web Menu Card on delivery': 'Tarjeta en entrega Menú web',
    'AmbitOne App Card': 'Tarjeta AmbitOne App',
    'AmbitOne App Cash': 'Efectivo AmbitOne App',
    'AmbitOne App Transfer': 'Transferencia PideDirecto App',
    'AmbitOne Kiosk Card': 'Tarjeta AmbitOne Kiosko',
    'AmbitOne Kiosk Cash': 'Efectivo AmbitOne Kiosko',
    'AmbitOne Kiosk Card on delivery': 'Tarjeta en entrega AmbitOne Kiosko',
    'AmbitOne Marketplace Card': 'Tarjeta AmbitOne Marketplace',
    'AmbitOne Marketplace Cash': 'Efectivo AmbitOne Marketplace',
    'AmbitOne Marketplace Transfer': 'Transferencia AmbitOne Marketplace',
    'Kiosk Transfer': 'Transferencia Kiosko',
    'In-Store Card': 'Tarjeta en tienda',
    'In-Store Cash': 'Efectivo en tienda',
    'In-Store Transfer': 'Transferencia en tienda',
    'Manual Orders': 'Órdenes manuales',
    'Didi Food Orders': 'Órdenes de Didi Food',
    'Uber Eats Orders': 'Órdenes de Uber Eats',
    'Rappi Orders': 'Órdenes de Rappi',
    'Rappi Cargo Orders': 'Órdenes de Rappi Cargo',
    'Total (Included Discount): ': 'Total (descuento incluído): ',
    'Subtotal (Excluded Discount): ': 'Subtotal (sin descuento): ',
    'Tax:': 'Impuestos:',
    'Service Fee @rate%:': 'Comisión de servicio @rate%',
    'Change Dynamic Earning': 'Cambiar Ganancia Dinámica',
    'Track Uber Order': 'Rastrear pedido de Uber',
    'Print Ticket': 'Imprimir ticket',
    'Print Bill': 'Imprimir cuenta',
    'Reprint Bill': 'Reimprimir cuenta',
    'Print Command': 'Imprimir comanda',
    'Print and leave': 'Imprimir y salir',
    'Send and leave': 'Enviar y salir',
    'Send and follow command': 'Enviar y seguir comanda',
    'Select a Printer': 'Seleccionar impresora',
    'Print': 'Imprimir',
    'Rappi OrderId:': 'ID del pedido de Rappi: ',
    'Didi OrderId:': 'ID del pedido de Didi: ',
    'Uber Eats OrderId:': 'ID del pedido de Uber Eats: ',
    'Edit Printer': 'Editar impresora',
    'No printer selected': 'No se ha seleccionado una impresora',
    'Update': 'Actualizar',
    'Update selected': 'Actualizar seleccionados',
    'Update all': 'Actualizar todo',
    'Updating': 'Actualizando',
    'Printer Type': 'Tipo de impresora',
    'Your printer is configured successfully': 'Tu impresora está configurada correctamente',
    'Cancel Order': 'Cancelar orden',
    'Total Ticket': 'Total de cuenta',
    'Remaining Amount': 'Importe restante',
    'Your customers are going to miss it. How long do you want this product to be unavailable?': 'Tus clientes lo van a extrañar, ¿Hasta cuando quieres tener este producto como no disponible?',
    'Search by product...': 'Busca por artículo...',
    'CANCELLED ITEMS': 'ITEMS CANCELADOS',
    'Cancelled Items:': 'Artículos cancelados:',
    'Returned Items:': 'Artículos devueltos:',
    'Cancelled Item': 'Artículo cancelado',
    'PENDING': 'Pendiente',
    'Subtotal (Excluded Discount):': 'Subtotal (sin descuento):',
    'Total (Included Discount):': 'Total (descuento incluído):',
    'Download Delivery Proof': 'Descargar prueba de entrega',
    'Payment Data': 'Información de pago',
    'Order Data': 'Información de orden',
    'Max Discount Amount': 'Descuento máximo',
    'ClientFrequency.NEW': 'Nuevo',
    'ClientFrequency.RECURRENT': 'Recurrente',
    'ClientFrequency.LOST': 'Perdido',
    'ClientFrequency.RECOVERED': 'Recuperado',
    'ClientFrequency.HEAVY_USER': 'Uso intenso',
    'ClientFrequency.GHOST_USER': 'Fantasma',
    'ClientFrequency.UNTRACKED': 'Sin rastreo',
    'Client Frequency': 'Frecuencia del cliente',
    'Ordered In': 'Ordenado en',
    'MAC Address': 'Dirección MAC',
    'Price @unit': 'Precio @unit',
    'Payment Terminals': 'Terminales de pago',
    'Create payment terminal': 'Crear terminal de pago',
    'Estimated cost per unit of production': 'Costo estimado por unidad de producción',
    'Driver Pickup Time': 'Hora de recolección',
    'The supply was successfully updated': 'El insumo se actualizó correctamente',
    'The supply was successfully created': 'El insumo se creó correctamente',
    'No notification sound?': '¿No escuchas las notificaciones?',
    'Configure your browser so that you dont miss any order.': 'Configura tu navegador para que no se te pase ninguna orden.',
    'CONFIGURE': 'CONFIGURAR',
    'Minutes to Complete the Delivery': 'Minutos para completar la entrega',
    'X minutes which driver has to finish the delivery to earn the delivery bonus.': 'X minutos en el que el repartidor tiene que completar la entrega para ganar el bonus.',
    'Auto Accept': 'Auto aceptar',
    'Print Auto Accepted Orders': 'Imprimir órdenes auto aceptadas',
    'Link to Master menu': 'Vincular con menú Master',
    'No menu items': 'Sin artículos',
    'No modifiers items': 'Sin modificadores',
    'In this section you can manage the different modifiers of this menuItem': 'En esta sección puedes administrar los diferentes modificadores de este artículo',
    'Move To Menu': 'Mover a menú',
    'Cannot take change as tip when paying by diner': 'No se puede tomar el cambio como propina cuando se paga por comensal',
    'Take as tip': 'Tomar como propina',
    'PrinterContents.COMMAND': 'Comanda',
    'PrinterContents.TICKET': 'Ticket',
    'PrinterContents.BOTH': 'Ambos',
    'Printer Content': 'Contenido de la impresión',
    'Create Printer': 'Crear impresora',
    'Produce composite supplies': 'Producir insumos compuestos',
    'List of composite supplies': 'Lista de insumos compuestos',
    'Disabled': 'Deshabilitado',
    'Menu Categories': 'Categorías del menú',
    'Printer Status': 'Estatus',
    'Was not possible to close the cash register cause there is pending orders': 'No fue posible cerrar la caja debido a que hay órdenes pendientes',
    'Unable to close the cash register with pending orders': 'No es posible cerrar la caja con órdenes pendientes',
    'Unable to clock out because your cash register is open': 'No es posible marcar salida porque tu caja está abierta',
    'Unable to clock out because you do not have a open session': 'No es posible marcar salida por que no tienes una sesión abierta',
    "Couldn't find pos menus": 'No se encontraron menús para POS',
    "Couldn't find pos menus configured, please go to the menu section and create it.": 'No se encontraron menús para POS, por favor ve a la sección del menú y crea uno.',
    'ORDER AND PAY HERE': 'ORDENA Y PAGA AQUÍ',
    'Order': 'Orden',
    'Verb.Order': 'Ordena',
    '&': 'y',
    'Pay Here': 'Paga Aquí',
    'Tap to order': 'Toca para ordenar',
    'Where do you enjoy your meal today?': '¿Dónde disfrutarás tu comida hoy?',
    'Eat here': 'Comer aquí',
    'Take out': 'Para llevar',
    'Choose your payment method': 'Elige tu método de pago',
    'What are you craving today?': '¿De qué tienes antojo hoy?',
    'Items': 'Artículos',
    '@number Items': '@number Artículos',
    'Logo Image': 'Imagen de logo',
    'Logo Image to Print in Ticket': 'Logo para imprimir en ticket',
    'Back': 'Atrás',
    'Continue': 'Continuar',
    'My Order': 'Mi Orden',
    'Where would you like to pay?': '¿Dónde te gustaría pagar?',
    '(Only Card)': '(solo tarjeta)',
    'Pay in the counter': 'Pagar en el mostrador',
    'Base Supplies Used': 'Insumos base utilizados',
    'Items with insufficient stock': 'Artículos con stock insuficiente',
    'Pay with cash in the counter': 'Pagar en el mostrador con efectivo',
    'Pay with credit card in the counter': 'Pagar en el mostrador con tarjeta',
    'Enabled option to pay in the counter with card when restaurant has payment with credit card enabled for kiosk': 'Habilita la opción de pagar con tarjeta en el mostrador cuando el restaurante tiene pago con tarjeta habilitado para kiosko',
    'Thanks for you preference': 'Gracias por tu preferencia',
    'Show your ticket at the counter to receive your order': 'Presenta tu ticket en el mostrador para recoger tu pedido',
    'Order #@dailyOrderId': 'Orden #@dailyOrderId',
    'We are creating your order': 'Estamos creando tu orden',
    'We will notify you when the order is ready': 'Te avisaremos cuando tu orden esté lista',
    'Send ticket trough email': 'Enviar ticket a través de email',
    'This field is required': 'Este campo es requerido',
    'This field must be unique': 'Este campo debe ser único',

    'Invalid phone number': 'Número de teléfono inválido',
    'Invalid email': 'Email inválido',
    'Kiosk Id:': 'Kiosko:',
    'Something went wrong trying to access to the terminal, please try it again o pay with cash': 'Ha ocurrido un error intentado acceder a la terminal, por favor intentalo otra vez o paga en efectivo',
    "A network problem has occurred. Check your computer's connection and try again. If it happens again, please contact support": 'Se ha producido un error con la red. Revisa la conexión del equipo y vuelve a intentarlo. Si se repite, por favor contacta a soporte',
    'Follow the terminal instructions': 'Sigue las instrucciones de la terminal',
    'Customize your @itemName': 'Personaliza tu @itemName',
    'at least': 'al menos',
    'Customize': 'Personalizar',
    'Add @total': 'Agregar @total',
    'Type your special instruction': 'Escriba sus indicaciones adicionales',
    'You will receive a message when your order is ready': 'Recibirás un mensaje cuando tu orden esté lista',
    'Notes': 'Notas',
    'Notes:': 'Notas:',
    'Create User': 'Crear usuario',
    'Change User': 'Cambiar usuario',
    'Change Promo Code': 'Editar código de promoción',
    'Create Promo Code': 'Crear código de promoción',
    'Value': 'Valor',
    'Usage': 'Uso',
    'Max Usage': 'Uso máximo',
    'Promo Type': 'Tipo de promoción',
    'Reward Type': 'Tipo de recompensa',
    'Order Types': 'Tipos de orden',
    'Min Order Amount': 'Monto mínimo de orden',
    'Require at least one Credit Card': 'Requiere al menos una tarjeta de crédito',
    'Starts': 'Inicia',
    'Ends': 'Termina',
    'Both': 'Ambos',
    'Modifiers': 'Modificadores',
    'Modifier Type': 'Tipo de modificador',
    'ModifierTypes.SINGLE': 'Único',
    'ModifierTypes.MULTIPLE': 'Multiple',
    'Modifier': 'Modificador',
    'If your printer is not available to connect then is not compatible': 'Si tu impresora no está disponible para conectar no es compatible',
    'Serial Number': 'Número Serial',
    'Test printer': 'Probar impresora',
    'Ip Address': 'Dirección IP',
    'Mac Address': 'Dirección MAC',
    'Device Port': 'Puerto del dispositivo',
    'Promotion': 'Promoción',
    'Excluded Restaurants': 'Restaurantes Excluidos',
    'No Excluded Restaurants': 'No Restaurantes Excluidos',
    'The bonus will not applied to deliveries delivered from the selected restaurants': 'El bono no aplicará para las entregas de los restaurantes seleccionados',
    'Add products to the order': 'Agrega productos a la orden',
    'Customer Details': 'Datos de contacto',
    'Start of the day': 'Inicio del día',
    'Menu': 'Menú',
    'Do you want @service? Click ': '¿Quieres @service? Contáctanos ',
    'here': 'aquí',
    'HERE': 'AQUÍ',
    'Im interested in the @service': 'Estoy interesado en los @service',
    'Advanced Reports': 'Reportes Avanzados',
    'ManagerUserActions.ORDER_CANCELLATION': 'Cancelación de orden',
    'ManagerUserActions.MANUAL_ASSIGNMENT': 'Asignación manual',
    'ManagerUserActions.MANUAL_EARNING': 'Manual earning',
    'Manager User Actions': 'Acciones de usuario',
    'User Action': 'Acción del usuario',
    'If this is blank will be all the channels': 'Si se deja en blanco serán todos los canales',
    'Apps.UBER_EATS': 'Uber Eats',
    'Apps.DIDI_FOOD': 'Didi',
    'Apps.RAPPI': 'Rappi',
    'Apps.PIDEDIRECTO': 'AmbitOne Menú web',
    'Apps.PIDEDIRECTOAPP': 'AmbitOne MarketPlace',
    'Apps.LETSEATAPP': 'LetsEat',
    'Apps.PIDEDIRECTOPOS': 'Punto de Venta',
    'Apps.PEDIDOS_YA': 'Pedidos ya',
    'Apps.PIDEDIRECTOKIOSK': 'AmbitOne Kiosko',
    'Apps.LETSEATMANAGER': 'Orden Manual',
    'Apps.PIDEDIRECTO_API': 'Pide Directo API',
    'Apps.PIDEDIRECTOADMIN': 'Pide Directo Admin',
    'Apps.PIDEDIRECTOADMINAPP': 'Pide Directo Admin App',
    'Apps.UBER_DAAS': 'Uber DASS',
    'Apps.PIDEDIRECTODRIVERAPP': 'Pide Directo Driver App',
    'Apps.LETSEATDRIVERAPP': 'LetsEat Driver App',
    'Apps.PICK_UP_STATION_ORDER': 'Pick Up Station Order',

    'RestaurantPaymentMethodApps.PIDEDIRECTO': 'AmbitOne Menú web',
    'RestaurantPaymentMethodApps.PIDEDIRECTOAPP': 'AmbitOne MarketPlace',
    'RestaurantPaymentMethodApps.PIDEDIRECTOPOS': 'Punto de Venta',
    'RestaurantPaymentMethodApps.PIDEDIRECTOKIOSK': 'AmbitOne Kiosko',
    'RestaurantPaymentMethodApps.PIDEDIRECTOADMIN': 'Pide Directo Admin',
    'RestaurantPaymentMethodApps.LETSEATAPP': 'LetsEat',

    'See order resume': 'Ver resumen de pagos',
    'Table @number': 'Mesa @number',
    'Busy Table': 'Mesa Ocupada',
    'No order': 'Sin orden',
    'Table @table': 'Mesa @table',
    'Choose table': 'Elige mesa',
    'Remove Zone': 'Eliminar Zona',
    'Are you sure you want to remove this restaurant zone?': '¿Estás seguro que desas eliminar esta zona?',
    'If you leave it empty will be used the price of the modifier': 'Si lo dejas en blanco será usado el precio del modificador',
    'Buy ticket': 'Ticket de compra',
    'Sell for @orderType': 'Venta para @orderType',
    'Ticket.TABLE_ORDER': 'Comer Aqui',
    'Ticket.TAKE_AWAY_ORDER': 'Llevar',
    'Ticket.DELIVERY_ORDER': 'Envío',
    'TICKET.Description': 'Descripcion',
    'Date and time:': 'Fecha y hora:',
    'Date and time': 'Fecha y hora',
    'Order:': 'Orden:',
    'Payment link amount': 'Cantidad del link de pago',
    'Payment link is @state, it cannot be edited': 'El link de pago está @state, no puede ser editado.',
    'Payment link is linked to an order, it cannot be edited.': 'El link de pago está vinculado a una orden, no puede ser editado.',
    'Create payment link with this amount': 'Crear link de pago con esta cantidad',
    "You haven't finished creating the payment link, check the amount and copy the link to the clipboard": 'No ha terminado de crear el link de pago, confirme la cantidad y copie el enlace en el portapapeles.',
    'Quant.': 'Cant',
    'Items:': 'Articulos:',
    'PAYMENT METHODS': 'FORMAS DE PAGO',
    'Ticket.Amount': 'Monto',
    'Print date': 'Fecha de impresión',
    'We can help you': 'Estamos para ayudarte',
    'Online invoice': 'Facturación en línea',
    'Remember you have the current month': 'Recuerda que tienes el mes en curso',
    'to do your invoice': 'para solicitar tu factura',
    'Go to the next link': 'Ingresa a la siguiente liga',
    'with the order id @orderId': 'con el id de la orden @orderId',
    'Discount:': 'Descuento:',
    'Tip:': 'Propina:',
    'Tips:': 'Propinas',
    'CANCELLED ITEMS:': 'ARTICULOS CANCELADOS:',
    'TICKET.Delivery:': 'Envío:',
    'Kitchen Display Screen': 'Cocina',
    'Ready': 'Lista',
    'Kitchen': 'Cocina',
    'Order in Kitchen': 'En cocina',
    'Order Prepared': 'Orden lista',
    'Pending Orders': 'Órdenes pendientes',
    'Completed Orders': 'Órdenes listas',
    'Send back to kitchen': 'Enviar a cocina',
    'Number of orders: @numOrders': 'Número de órdenes: @numOrders',
    'Lets start from the beginning': 'Comencemos por el principio',
    'Create your account in minutes to start with your online store': 'Crea tu cuenta en unos minutos para iniciar con tu tienda en linea',
    'Accept the': 'Acepto los',
    'Terms and Conditions': 'Términos y Condiciones',
    'Business name': 'Nombre del negocio',
    'Contact name': 'Nombre de contacto',
    'Contact phone number': 'Teléfono de contacto',
    'Confirm password': 'Confirmar contraseña',
    'Next': 'Siguiente',
    'Passwords dont match': 'Las contraseñas no coinciden',
    'Please accept the terms and conditions': 'Por favor acepta los términos y condiciones',
    'About your business': 'Sobre tu negocio',
    'In order to give you better service, we would like to know the next info from you restaurant': 'Para brindarte un mejor servicio, nos gustaría saber los siguientes datos de tu restaurante',
    'Restaurant address': 'Dirección del restaurante',
    'Select a location': 'Selecciona una ubicación',
    'Location phone': 'Teléfono del local',
    'Do you have own drivers fleet?': '¿Cuenta con flotilla de repartidores propia?',
    'How many locations do you have?': '¿Cuántas sucursales tienes?',
    'Bank information': 'Datos bancarios',
    'Your details account where we are going to deposit the weekly sales': 'Tu cuenta y detalles en donde depositar tus ventas semanales',
    'Business name (optional)': 'Razón social de tu negocio (opcional)',
    'RFC (optional)': 'RFC (opcional)',
    'Finish': 'Finalizar',
    'Congratulations!': '¡Felicidades!',
    'We have created your restaurant @restaurantName in PideDirecto': 'Hemos creado tu restaurante @restaurantName en PideDirecto',
    'You have 7 trial days to know the benefits of your account.': 'Tienes 7 días de prueba para conocer los beneficios de tu cuenta.',
    'Login to ask driver': 'Inicia sesion para solicitar repartidor',
    'Continue to my account': 'Continuar a mi cuenta',
    'You have @days trial days left': 'Te quedan @days días de prueba',
    'Select plan': 'Seleccionar plan',
    'Your free 7 days trial version has ended': 'Tu periodo de 7 días de prueba ha terminado',
    'The perfect plan to grow your business': 'El plan perfecto para crecer tu negocio',
    'On this 7 days...': 'En estos 7 días...',
    'Interested in e-commerce': '¿Interesado en un e-commerce?',
    'See other plans': 'Ver otros paquetes',
    'RegistrationStatuses.BASIC_INFO': 'Información basica',
    'RegistrationStatuses.BUSINESS_INFO': 'Información del negocio',
    'RegistrationStatuses.BANK_INFO': 'Información de cuenta',
    'RegistrationStatuses.COMPLETED': 'Completado',
    'Get in to the kitchen': 'Métele hasta la cocina',
    'Your promotions everywhere': 'Tus promociones por todos lados',
    'month': 'mes',
    'Start trial': 'Empezar prueba',
    'Meet your clients': 'Conoce tus clientes',
    'Communicate through campaigns': 'Comunica a través de campañas de',
    'Know and keep your customers': 'Conoce y mantén a tus clientes',
    'Communicate through SMS, email and more': 'Comunica a través de SMS, email y más',
    'Speed up and control your operation': 'Agiliza y controla tu operación',
    'Become the owner of your operation': 'Conviértete en el dueño de tu operación',
    'Take orders': 'Toma de pedidos',
    'Take your orders': 'Toma tus pedidos',
    'Control of all your sales': 'Control de todas tus ventas',
    'Streamline your operation': 'Agiliza tu operación',
    'Print tickets and orders': 'Imprime tickets y comandas',
    'Control your sales': 'Controla tus ventas',
    'Speed up your operation': 'Agiliza tu operación',
    'Print your tickets and commands': 'Imprime tickets y comandas',
    'From your online store directly yo tour kitchen': 'De tu tienda en linea directo a tu cocina',
    'Receive orders online': 'Recibe órdenes en linea',
    'Manage your sales with smart statistics': 'Administra tus ventas con métricas inteligentes',
    'Your menu at the reach of a click': 'Tu menú al alcance de un click',
    'Receive your orders online': 'Recibe tus pedidos en linea',
    'Card and cash payments': 'Pagos con tarjeta y en efectivo',
    'Orders to go or pick up in store': 'Pedidos para llevar o para recoger en tienda',
    '“The POS agilized at 30% our operations”': '“El punto de venta agilizó nuestra operación en un 30%”',
    'Card and cash payments, to pick up o delivery': 'Pagos con tarjeta y en efectivo, para llevar o para recoger',
    'Discount your sales automatically': 'Descuenta tus ventas en automático',
    'Track your inventory': 'Da seguimiento a tu inventario',
    'Supply amount discounts with your sales in PideDirecto': 'Los insumos se descuentan con tus ventas en PideDirecto',
    'Disable products out of stock': 'Desactiva productos fuera de stock',
    'Restaurant: @restaurantName': 'Restaurante: @restaurantName',
    'Sync your inventory and your sales in one place': 'Sincroniza tu inventario y tus ventas en un solo lugar',
    'The inputs are discounted with the sales of the platforms that you manage': 'Los insumos se descuentan con las ventas de las plataformas que administres',
    'Deactivate out of stock products': 'Desactiva productos fuera de stock',
    'Unit Value': 'Valor unitario',
    'Total Value': 'Valor total',
    'Estimated Price': 'Precio estimado',

    'Finance Reference': 'Referencia de finanzas',
    'Elevate reference': 'Referencia Elevate',
    'Reference number used by finance': 'Número de referencia utilizado por finanzas',
    'Finance Reference Enabled': 'Habilitar referencia de finanzas',
    'Enable this to use the finance reference': 'Habilita esto para usar el número de referencia de finanzas',

    'DashboardReports.GENERAL': 'General',
    'DashboardReports.MENU': 'Por menú',
    'DashboardReports.CUSTOMER': 'Por clientes',
    'DashboardReports.PROMO_CODE': 'Por código promocional',
    'DashboardReports.DAY': 'Por día',
    'DashboardReports.FINANCE': 'Finanzas',
    'DashboardReports.SALES': 'Ventas',
    'DashboardReports.ABC': 'Análisis ABC',
    'DashboardReports.SMART_BI': 'Smart BI',
    'DashboardReports.ISSUES': 'Problemas con órdenes',
    'DashboardReports.DELIVERY': 'Reparto',
    'DashboardReports.POS_BUSINESS_DAY': 'Cierres de día',

    'LogEvents.ORDERS': 'Pedidos',
    'LogEvents.DELIVERY': 'Delivery',

    'FilterReportTypes.DAY': 'Hoy',
    'FilterReportTypes.YESTERDAY': 'Ayer',
    'FilterReportTypes.WEEK': 'Últimos 7 días',
    'FilterReportTypes.MONTH': 'Últimos 30 días',
    'FilterReportTypes.PERIOD': 'Periodo',
    'Select a period': 'Selecciona un periodo',
    'Promo Code most used': 'Código de promoción más usado',
    'Initial Inventory': 'Inventario inicial',
    'Final Inventory': 'Inventario final',
    'ColumnTypes.COST': 'Costo',
    'ColumnTypes.QUANTITY': 'Cantidad',

    'Insuficient Stock': 'Stock insuficiente',
    'The stock of some supplies is insuficient to produce this supply, but you can continue anyway.': 'El stock de algunos insumos es insuficiente para producir este insumo, pero puedes continuar de todas formas.',
    'ColumnTypes.COST_BY_AVERAGE_WEIGHTED_COST': 'Costo por costo promedio ponderado',
    'ColumnTypes.COST_BY_LAST_PURCHASE_COST': 'Costo por último costo de compra',
    'ColumnTypes.VALUE': 'Valor',

    'ColumnTypes.AVERAGE_COST': 'Costo promedio',
    'ColumnTypes.LAST_PURCHASE_COST': 'Costo de última compra',
    'ColumnTypes.FIXED_COST': 'Costo fijo',

    'PosBusinessSales.ORDERS': 'Órdenes del día',
    'PosBusinessSales.PRODUCTS': 'Productos',
    'PosBusinessSales.PROMO_CODES': 'Código de promoción',
    'Transactions': 'Transacciones',

    '@store Store Closed': 'Tienda de @store cerrada',
    'UberEatsStoreOfflineReasons.OUT_OF_MENU_HOURS': 'Fuera de horario',
    'UberEatsStoreOfflineReasons.INVISIBLE': 'No visible',
    'UberEatsStoreOfflineReasons.PAUSED_BY_UBER': 'Pausado por Uber',
    'UberEatsStoreOfflineReasons.PAUSED_BY_RESTAURANT': 'Pausado por el Restaurante',
    'RD-Optional Disabled': 'RD-Optional deshabilitada',
    'Close Store': 'Cerrar tienda',
    'Open Store': 'Abrir tienda',
    'Contact Uber Eats support': 'Contacta el servicio de soporte de Uber Eats',
    'Your account is paused from Uber and cannot receive orders.': 'Su cuenta está pausada desde Uber y no puede recibir órdenes.',
    'Open Uber Eats Store': 'Abrir tienda de Uber Eats',
    'Open Didi Food Store': 'Abrir tienda de Didi Food',
    'Open Pedidos Ya Store': 'Abrir tienda de Pedidos Ya',
    'Open Rappi Store': 'Abrir tienda de Rappi',
    'Close Uber Eats Store': 'Cerrar tienda de Uber Eats',
    'Close Didi Food Store': 'Cerrar tienda de Didi Food',
    'Close Rappi Store': 'Cerrar tienda de Rappi',
    'Close Pedidos Ya Store': 'Cerrar tienda de Pedidos Ya',

    'Upload Buy Order (csv)': 'Subir orden de compra (csv)',
    'Create a purchase order from a csv file': 'Crea una orden de compra desde un archivo csv',
    'Create a purchase order': 'Crear una orden de compra',
    'Buy Orders': 'Órdenes de compra',
    'Create Buy Order': 'Crear orden de compra',
    'Unit Price': 'Precio unitario',
    'Complete your order and send it': 'Completa tu orden y envíala',
    'Purchase Order #@id': 'Orden de compra # @id',
    'Buy order created successfully': 'Orden de compra creada exitosamente',
    'Buy order canceled successfully': 'Orden de compra cancelada exitosamente',
    'Buy order changed successfully': 'Orden de compra modificada exitosamente',
    'Buy order completed successfully': 'Orden de compra completada exitosamente',
    'Buy order sent': 'Orden de compra enviada',

    'No Provider': 'Sin proveedor',
    'Are you sure you want to remove the buyOrder?': '¿Está seguro de que desea eliminar la orden de compra?',
    'Select your buy Order': 'Selecciona tu orden de compra',
    'Purchase Order #@buyOrderId': 'Orden de compra # @buyOrderId',
    'View': 'Ver',
    'Send later': 'Enviar después',
    'Send Buy Order': 'Enviar orden de compra',
    'Add the inputs and confirm in your summary': 'Agrega los insumos y confirma en tu resumen',
    'Add a purchase order to update your inventory in just a few minutes': 'Agrega una orden de compra para actualizar tu inventario en solo unos minutos',
    'Upload the order via a CSV (Excel) file': 'Sube la orden a través de un archivo CSV (Excel)',
    'start from 0': 'comienza desde 0',
    'Upload with file': 'Subir con archivo',
    'Register your order manually': 'Registra tu orden manualmente',
    'Add Order': 'Agregar orden',
    'Buy Order': 'Orden de compra',
    'BUY ORDER': 'ORDEN DE COMPRA',
    'Add new buy order to your inventory': 'Ingresa nueva orden de compra a tu inventario',
    'Add Supply': 'Agregar insumo',
    'Add at least 1 supply': 'Agrega al menos 1 insumo',
    'Please check that your purchase order is correct': 'Por favor revisa que tu orden de compra sea correcta',
    'Please check that your merchandise income is correct': 'Por favor revisa que tu entrada de mercancía sea correcta',
    'Use previous order': 'Usar orden anterior',
    'Register your order': 'Registra tu orden de',
    'manual way': 'forma manual',
    'Create your order using a': 'Crea tu orden utilizando un',
    'previus file': 'archivo anterior',
    'Articles: @articles': 'Artículos: @articles',
    'Crea tu orden utilizando un archivo anterior': 'Crea tu orden utilizando un archivo anterior',
    'In order to upload your buy order, we need a csv file (use Excel or similar) following a format': 'Para subir tu orden de compra, necesitamos que esté en un archivo CSV (usando Excel o parecidos) siguendo un formato',
    'To upload your purchase order, we need it to be a CSV file (using Excel or similar), download the template': 'Para subir tu orden de compra, necesitamos que esté en un archivo CSV (usando Excel o parecidos), descarga el template',
    'Download template file': 'Descargar template de archivo',
    'Upload CSV': 'Subir CSV',
    'CSV (Excel) file': 'archivo CSV',
    'Upload the order via a': 'Sube la orden mediante un',
    'Select the purchase order': 'Selecciona la orden de compra',
    'Here you will verify the information to continue': 'Aquí verificarás los datos para continuar',
    'Please upload a csv file with columns named SKU, CANTIDAD, UNIDAD, PRECIO and PROVEEDOR': 'Por favor sube un archivo csv con columnas llamadas SKU, CANTIDAD, UNIDAD, PRECIO y PROVEEDOR.',
    '@num products found': '@num productos encontrados',
    '@num unknown products': '@num productos desconocidos',
    '@num total products': '@num productos totales',
    '*Remember the price has to be unitary and the supply unit should be one of the follow units:': 'Recuerda que el precio tiene que ser unitario y las unidades de los insumos tienen que ser una de las siguientes:',
    'Confirm your buy order': 'Verifica tu orden de compra',
    'We have found the next products in your file, please make sure that everything is correct to continue importing them, will be ignored those buy supplies with errors.': 'Hemos encontrado los siguientes productos en tu archivo, por favor asegúrate de que esté todo correcto para continuar importándolos, serán ignorados los insumos que tengan errores.',
    'Your inventory': 'Tu inventario',
    'Was not possible to find a supply with this SKU': 'No fue posible encontrar un insumo con este SKU',
    'We found a supply with this SKU but the unit is not one of the configured ones': 'Encontramos un insumo con este SKU pero la unidad no es una de las configuradas',
    'Not found': 'No encontrado',
    'Your inventory has been updated': 'Tu inventario ha sido actualizado',
    'My inventory': 'Mi inventario',
    'Operation resume': 'Resumen de mi operación',
    '@num products affected': '@num productos afectados',
    '@num products ignored': '@num productos ignorados',
    'Operation': 'Operación',
    '@totalSales sales on the day': '@totalSales ventas en el dia',
    'Total Sold': 'Total vendido',
    'Ticket.Payment Methods': 'Metodos de pago',
    'Payment Methods Sales': 'Ventas por método de pago',
    'Resume end of day @date': 'Resumen del día @date',
    'Resume day': 'Resumen del día',
    'Resume shift report': 'Corte de caja resumen',
    'to': 'al',
    'This device': 'Este dispositivo',
    'Active printers on this device': 'Impresoras activas en este dispositivo',
    'Saved Printers': 'Impresoras guardadas',
    'Printer Id': 'ID de Impresora',
    'Are you sure you want to sync the printers active on this device with the printers saved?': '¿Estás seguro que deseas sincronizar las impresoras activas en este dispositivo con las impresoras guardadas?',
    'Sync': 'Sincronizar',
    'Active printer': 'Activar impresora',
    'End Of Day Report': 'Reporte del cierre del día',
    'Average daily existence': 'Existencia promedio diaria',
    'Average daily consumption': 'Consumo promedio diario',
    'Average cost of sales in period': 'Costo promedio de ventas en el periodo',
    'Average purchase cost in period': 'Costo promedio de compra en el periodo',
    'Reported cash': 'Efectivo declarado',
    'Expected cash': 'Efectivo esperado',
    'Expected Tip': 'Propina esperada',
    'Reported Tip': 'Propina declarada',
    'Cash register movements': 'Movimientos de caja',
    'Movement': 'Movimiento',
    'Cash register shifts': 'Cortes de caja',
    'Cash register closed': 'Caja cerrada',
    'Diners': 'Comensales',
    'Reported card': 'Tarjeta declarado',
    'Expected card': 'Tarjeta esperado',
    'Reported @paymentMethod': '@paymentMethod declarado',
    'Expected @paymentMethod': '@paymentMethod esperado',
    'Cancelled orders': 'Órdenes canceladas',
    'Cancelled sales': 'Venta cancelada',
    'Cash received in store': 'Efectivo en tienda',
    'Cash change': 'Cambio en efectivo',
    'Card in store': 'Tarjeta en tienda',
    'Card online': 'Tarjeta en línea',
    'Transfer and others': 'Transfer y otros',
    'Payment methods breakdown': 'Desglose de métodos de pago',
    'Credit card orders breakdown': 'Desglose de órdenes pagadas con tarjeta',
    'Integration orders breakdown': 'Desglose de órdenes de integración',
    'Integration': 'Integración',
    'Initial Folio': 'Folio inicial',
    'Final Folio': 'Folio final',
    'Expected': 'Esperado',
    'Reported': 'Reportado',
    'Total Orders': 'Órdenes totales',
    'Closure': 'Corte',
    'by @username': 'por @username',
    'PrinterBrands.STAR': 'Star',
    'PrinterBrands.XPRINTER': 'Xprinter',
    'PrinterBrands.EPSON': 'Epson',
    'PrinterBrands.GHIA': 'Ghia',
    'PrinterBrands.PIDEDIRECTO': 'PideDirecto',
    'PrinterBrands.IMIN': 'iMin',
    'PrinterBrands.OTHER': 'Otra',
    'Print report': 'Imprimir reporte',
    'END OF DAY': 'CIERRE DE DIA',
    'TOP SALES BY HOUR': 'TOP VENTA POR HORA',
    'TOP SALES BY PRODUCT': 'TOP VENTA POR PRODUCTO',
    'TOP SALES BY CATEGORY': 'TOP VENTA POR CATEGORIA',
    'SALES BY CHANNEL': 'VENTA POR CANAL',
    'SALES BY PAYMENT TYPE': 'VENTA POR TIPO DE PAGO',
    'Top Sales By Hour': 'Top venta por hora',
    'Sales By Product': 'Venta por producto',
    'Top Sales By Category': 'Top venta por categoria',
    'Sales By Channel': 'Venta por canal',
    'Sales By Order Type': 'Venta por tipo de órden',
    'Sales By Payment Type': 'Venta por tipo de pago',
    'SHIFT REPORT': 'CORTE DE CAJA',
    'Opening date:': 'Fecha de apertura:',
    'Closing date:': 'Fecha de cierre:',
    'Sales:': 'Ventas:',
    'Orders:': 'Órdenes:',
    'Diners:': 'Comensales:',
    'CASH REGISTER MOVEMENTS': 'MOVIMIENTOS DE CAJA',
    'Cash sales': 'Venta en efectivo',
    'Card sales': 'Venta en tarjeta',
    'Total cash': 'Efectivo total',
    'Total card': 'Total en tarjeta',
    'Total deposits': 'Total depositos',
    'Total withdraws': 'Total retiros',
    'Total cash tip': 'Propina en efectivo',
    'Total card tip': 'Propina con tarjeta',
    'Total custom tip': 'Propina pagos personalizados',
    'This section includes all orders of this type, can be extensive': 'Esta sección incluye todas las órdenes de este tipo, puede ser muy extensa',
    'Total balance': 'Balance total',

    'PAYMENTS RECEIVED': 'PAGOS RECIBIDOS',
    'P. Method': 'M. de pago',
    'CASH REGISTER COUNTING': 'CONTEO DE CAJAS',
    'SHIFT CASH REGISTER DETAILS': 'DETALLES CORTE DE CAJA',
    'Was not possible to find the report': 'No fue posible encontrar el reporte',
    'Medium': 'Medio',
    'Customer:': 'Cliente:',
    'Phone:': 'Teléfono:',
    'Address:': 'Dirección:',
    'Change:': 'Cambio:',
    'Delivery:': 'Envío:',

    'Subscriptions': 'Suscripciones',
    'Edit Billing': 'Editar facturación',
    'Application': 'Aplicación',
    'Concept': 'Concepto',
    'Payment Form': 'Forma de Pago',

    'Applicable restaurants': 'Restaurantes aplicables',
    'Restaurants excluded': 'Restaurantes excluidos',

    'FacturamaPaymentMethods.PUE': 'Una sola exhibición',
    'FacturamaPaymentMethods.PPD': 'Por partes',
    'FacturamaPaymentForms.01': '01 - Efectivo / SPEI OXXO',
    'FacturamaPaymentForms.04': '04 - Tarjeta de crédito',
    'FacturamaPaymentForms.03': '03 - SPEI interno',
    'FacturamaPaymentForms.28': '28 - Tarjeta de débito',
    'FacturamaPaymentForms.17': '17 - Compensación',
    'FacturamaPaymentForms.99': '99 - Por Definir',
    'Tablet Printer': 'Impresora de la tablet',
    'Deposited': 'Deposito',
    'Withdrawed': 'Retiro',
    'Configuration': 'Configuración',
    'Printers': 'Impresoras',
    'You can only try opening and closing once a minute.': 'Solo puede intentar abrir y cerrar una vez por minuto.',
    'To see store status check it on rappi': 'Para ver el estado de la tienda revisalo en rappi',
    'Could take some time to this action being reflected on rappi': 'Podría tomar algún tiempo para que las acciones se reflejen en las integraciones.',

    'Rappi Restaurant Code': 'Codigo De Restaurante Rappi',
    'Enable Rappi Check In Code': 'Encender codigo de restaurante Rappi',
    'Activate this option for Rappi check in Code': 'Activa esta opcion para ver tu codigo de restaurante de Rappi',

    'RegistrationStatuses.STARTED': 'Incompleto',
    'RegistrationStatuses.FREE_TRIAL': 'Completo',
    'RegistrationStatuses.SUBSCRIBED': 'Subscrito',
    'Must be number equal to or larger than max required': 'El número debe ser mayor o igual al mínimo requerido',
    'Must be number equal to or less than min required': 'El número debe ser menor o igual al máximo requerido',

    'Delivery package': 'Paquete entregues',
    'Our drivers team are ready to realize your deliveres with no limits.': 'Nuestra flotilla de repartidores lista para realizar tus entregas sin límites.',
    '/ month': '/ mes',
    'to @deliveries1 to @deliveries2 deliveries': 'de @deliveries1 a @deliveries2 entregas',
    'Thousands of drivers in +25 cities': 'Miles de repartidores en +25 ciudades',
    '24/7 support': 'Soporte 24/7',
    'Refund for lost orders': 'Reembolso por órdenes robadas',
    'Tracking orders in live': 'Rastrea ordnenes en tiempo real',
    'SMS verification': 'Verificación SMS',
    'Change payment method to card': 'Cambiar método de pago a tarjeta',
    'Change payment method to cash': 'Cambiar método de pago a efectivo',
    'Card (Do not charge the delivery man, it is deposited)': 'Tarjeta (No cobrar al repartidor, se deposita)',
    '(Do not charge the delivery man, it is deposited)': '(No cobrar al repartidor, se deposita)',

    'OrderTypes.TABLE_ORDER': 'Comer aquí',
    'OrderTypes.SELF_SERVICE_ORDER': 'Comer aquí',
    'OrderTypes.PICKUP_STATION_ORDER': 'Estación de entregas',
    'OrderTypes.TAKE_AWAY_ORDER': 'Para llevar',
    'OrderTypes.DELIVERY_ORDER': 'Envío',
    'OrderTypes.ROOM_SERVICE_ORDER': 'Servicio de habitaciones',

    'PickupTimeTypes.ASAP': 'ASAP',
    'PickupTimeTypes.PLANNED': 'Planeado',
    'PickupTimeTypes.PLANNED_MENU_ITEMS': 'Fecha de entrega',

    'Must be at least @number characters': 'Debe ser mayor a @number caracteres',
    'Delete': 'Borrar',

    'referredDriverId': 'Id del referido',
    'referredByDriverId': 'Id del repartidor que refirió',
    'deliveriesDelivered': 'Pedidos Completados',
    'referredDriverEarnings': 'Ganancias del referido',
    'referredByDriverEarnings': 'Ganancias de el que refirió',
    'deliveriesToDeliver': 'Pedidos Faltantes',
    'expiresAt': 'Caduca en',
    'expiredAt': 'Caducado en',
    'startedAt': 'Empezó en',
    'completedAt': 'Completado en',
    'createdAt': 'Creado en',
    'Arrived at client': 'Llego con el cliente',

    'BannerVisibilities.EVERYWHERE': 'En todas partes',
    'BannerVisibilities.DELIVERY': 'Entrega',
    'BannerVisibilities.PICK_UP': 'PickUp',
    'BannerVisibilities.COMPANY': 'Compañia',

    'Must be number equal to or larger than @number': 'La cantidad debe ser igual o mayor que @number',
    'Must be number equal to or less than @number': 'La cantidad debe ser igual o menor que @number',

    'Cooking time: @time min': 'Tiempo de preparación: @time min',
    'Cooking time': 'Tiempo de preparación',

    'PaymentStatuses.UNPAID': 'No pagado',
    'PaymentStatuses.PAID': 'Pagado',
    'PaymentStatuses.PAYED': 'Pagado',
    'PaymentStatuses.REFUNDED': 'Reembolsado',
    'PaymentStatuses.FAILED': 'Fallado',

    'PaymentLinkStatuses.UNPAID': 'No pagado',
    'PaymentLinkStatuses.PAID': 'Pagado',
    'PaymentLinkStatuses.PAYED': 'Pagado',
    'PaymentLinkStatuses.REFUNDED': 'Reembolsado',
    'PaymentLinkStatuses.FAILED': 'Fallado',
    'PaymentLinkStatuses.CANCELLED': 'Cancelado',

    'Open New Cash Register': 'Abrir nueva caja',
    'New cash register initial cash': 'Monto inicial de la nueva caja',

    'Rejected by @user': 'Rechazado por @user',

    'Hide tax information on the ticket': 'Ocultar información fiscal en el ticket',

    'Type of banner image': 'Tipo de imagen de banner',
    'BannerImageTypes.RESTAURANT': 'Restaurante',
    'BannerImageTypes.GENERAL': 'General',
    'BannerImageTypes.CUSTOM': 'Personalizado',

    'Enter to AmbitOne': 'Entra a tu AmbitOne',
    'Enter your credentials to access your account.': 'Ingresa tus credenciales para acceder a tu cuenta',
    'Enter your username or email': 'Tu email o usuario',
    'Enter your password': 'Tu contraseña',
    'Doesnt have an account?': '¿No tienes cuenta? ',
    'Register here your restaurant': 'Registra tu negocio aquí',
    'Now': 'Ahora',

    'Completed orders': 'Órdenes completadas',
    'Minutes average per order': 'Tiempo promedio por orden',
    'Earn': 'Generados',
    'Saved in commissions': 'Ahorrado en comisiones',
    'See plans': 'Ver planes',

    'PaymentMethods.CREDIT_CARD': 'Tarjeta',
    'PaymentMethods.CARD_ON_DELIVERY': 'Tarjeta al entregar',
    'PaymentMethods.CASH': 'Efectivo',
    'PaymentMethods.PENDING': 'Pendiente',
    'PaymentMethods.PAYMENT_LINK': 'Link de pago',
    'PaymentMethods.CREDITS': 'Créditos',
    'PaymentMethods.TRANSFER': 'Transferencia',
    'PaymentMethods.PAYMENT_TERMINAL': 'Pago por terminal',

    'MexicanPaymentMethodCodes.CASH': '01 - Efectivo',
    'MexicanPaymentMethodCodes.NOMINAL_CHECK': '02 - Cheque nominativo',
    'MexicanPaymentMethodCodes.ELECTRONIC_FUNDS_TRANSFER': '03 - Transferencia electronica de fondos',
    'MexicanPaymentMethodCodes.CREDIT_CARD': '04 - Tarjeta de credito',
    'MexicanPaymentMethodCodes.ELECTRONIC_WALLET': '05 - Monedero electronico',
    'MexicanPaymentMethodCodes.ELECTRONIC_MONEY': '06 - Dinero electronico',
    'MexicanPaymentMethodCodes.DEBIT_CARD': '28 - Tarjeta de debito',
    'MexicanPaymentMethodCodes.SERVICE_CARD': '29 - Tarjeta de servicios',
    'MexicanPaymentMethodCodes.TO_DEFINE': '99 - Por definir',

    'MexicanPaymentMethodCodes.01': '01 - Efectivo',
    'MexicanPaymentMethodCodes.02': '02 - Cheque nominativo',
    'MexicanPaymentMethodCodes.03': '03 - Transferencia electronica de fondos',
    'MexicanPaymentMethodCodes.04': '04 - Tarjeta de credito',
    'MexicanPaymentMethodCodes.05': '05 - Monedero electronico',
    'MexicanPaymentMethodCodes.06': '06 - Dinero electronico',
    'MexicanPaymentMethodCodes.28': '28 - Tarjeta de debito',
    'MexicanPaymentMethodCodes.29': '29 - Tarjeta de servicios',
    'MexicanPaymentMethodCodes.99': '99 - Por definir',

    'Payment method for billing': 'Forma de pago para facturación',

    'branch offices': 'Sucursales',

    'Your subscription to PideDirecto has expired. Confirm with your business advisor to reactivate it.': 'Tu suscripción a PideDirecto ha caducado. Confirma con tu asesor comercial para reactivarlo.',
    'It is important that it is correct for the driver to arrive.': 'Es importante que este correcta para que llegue el repartidor.',
    'Personal contact phone': 'Telefono personal de contacto',
    'This is your username': 'Este sera tu usuario',
    'Choose your password': 'Elige tu contraseña de usuario',
    'Branch or store phone': 'Teléfono de la sucursal o tienda',
    'Profile': 'Perfil',
    'POS Code': 'Clave POS',
    'Verify POS Code': 'Verificar clave POS',
    'The pos codes dont coincide, please verify the information': 'Las claves de pos no coinciden, por favor verifica la información',
    'Users': 'Usuarios',
    'Roles': 'Roles',
    'Rol Management': 'Administración de Roles',
    'Role': 'Rol',
    'Create Role': 'Crear rol',
    'Role name': 'Nombre del rol',
    'Module': 'Modulo',
    'Permissions': 'Permisos',
    'Initial page': 'Página de inicio',
    'ACCESS': 'ACCESO',
    'Apply discounts': 'Aplicar descuentos',
    'Pending orders': 'Pedidos pendientes',
    'All orders': 'Todos los pedidos',
    'All order': 'Toda la orden',

    'Cancel orders': 'Cancelar órdenes',
    'Customer info': 'Información del cliente',
    'Order invoices': 'Facturas de órdenes',
    'Internal Invoice': 'Factura global',
    'Payment links': 'Links de pago',
    'Menu editor': 'Editor de menú',
    'The restaurant': 'El restaurant',
    'User management': 'Administración de usuarios',
    'Sign in type': 'Tipo de inicio de sesión',
    'Orders cancelled': 'Órdenes canceladas',
    'PIN code': 'PIN',
    'Confirm PIN code': 'Confirmar PIN',
    'Own fleet': 'Propia flotilla',
    'Inventory record': 'Historial de inventario',
    'Inventory quantity': 'Cantidad en inventario',

    'Google tag Manager account ID': 'Google tag manager Id de la cuenta',
    'The google tag manager ID connected to the restaurant': 'El Google tag manager id conectado al restaurante',

    'RolePermissions.POS_PAGE': 'ACCESO',
    'RolePermissions.ADVANCED_REPORTS_PAGE': 'ACCESO',
    'RolePermissions.REPORTS_PAGE': 'ACCESO',
    'RolePermissions.PROMOTIONS_PAGE': 'ACCESO',
    'RolePermissions.PENDING_ORDERS_PAGE': 'ACCESO',
    'RolePermissions.KITCHEN_DISPLAY_SCREEN_PAGE': 'ACCESO',
    'RolePermissions.ALL_ORDERS_PAGE': 'ACCESO',
    'RolePermissions.INVENTORY_PAGE': 'ACCESO',
    'RolePermissions.VIEW_INCOMING_ORDERS_ALERT': 'Ver alerta de órdenes entrantes',
    'RolePermissions.ORDER_INVOICES_PAGE': 'ACCESO',
    'RolePermissions.SURVEYS_PAGE': 'ACCESO',
    'RolePermissions.CONFIGURATION': 'ACCESO',
    'RolePermissions.PROMO_CODES_PAGE': 'ACCESO',
    'RolePermissions.MARKETING_PAGE': 'ACCESO',
    'RolePermissions.MENU_EDIT_PAGE': 'ACCESO',
    'RolePermissions.PAYMENT_LINKS_PAGE': 'ACCESO',
    'RolePermissions.OWN_FLEET_PAGE': 'ACCESO',
    'RolePermissions.CASH_REGISTER_PAGE': 'ACCESO',
    'RolePermissions.RESTAURANT_ZONES_PAGE': 'ACCESO',
    'RolePermissions.ADD_TIP_ON_CLOSED_ORDERS': 'Agregar propina en órdenes completadas',
    'RolePermissions.APPLY_DISCOUNT_ORDERS': 'Aplicar descuentos',
    'RolePermissions.APPLY_PROMO_CODE_ORDERS': 'Aplicar Promo Codes',
    'RolePermissions.REMOVE_POS_CART_ITEM': 'Eliminar items en cuenta de POS',
    'RolePermissions.ADVANCED_REPORTS_MENU': 'Reportes del menú',
    'RolePermissions.ADVANCED_REPORTS_PROMO_CODES': 'Reportes de promo codes',
    'RolePermissions.ADVANCED_REPORTS_CUSTOMERS': 'Reportes de clientes',
    'RolePermissions.ASK_DRIVER': 'Solicitar repartidor',
    'RolePermissions.REJECT_ORDERS': 'Rechazar',
    'RolePermissions.VIEW_CHECK_DELIVERY_PAID_BY_RESTAURANT': 'Ver opción de envío pagado por restaurante',
    'RolePermissions.ALL_ORDERS': 'Todos los pedidos',
    'RolePermissions.ALL_ORDERS_REPORTS': 'Reportes de todos los pedidos',
    'RolePermissions.EDIT_ORDERS': 'Editar órdenes',
    'RolePermissions.CANCEL_ORDERS': 'Cancelar órdenes',
    'RolePermissions.CUSTOMER_INFO': 'Información del cliente',
    'RolePermissions.COPY_INVENTORY': 'Copiar inventario',
    'RolePermissions.INVENTORY_RECORDS': 'Historial de inventario',
    'RolePermissions.INVENTORY_QUANTITY': 'Cantidad en inventario',
    'RolePermissions.CREATE_INVENTORY_LOG': 'Crear movimiento en inventario',
    'RolePermissions.UPDATE_INVENTORY': 'Actualizar inventario',
    'RolePermissions.SUPPLIERS_PAGE': 'Proveedores',
    'RolePermissions.SUPPLIES_PAGE': 'Insumos',
    'RolePermissions.RECIPES_PAGE': 'Recetas',
    'RolePermissions.BUY_ORDERS_PAGE': 'Compras',
    'RolePermissions.CREATE_BUY_ORDERS': 'Crear orden de compra',
    'RolePermissions.EDIT_BUY_ORDERS': 'Editar orden de compra',
    'RolePermissions.CREATE_MERCHANDISE_ENTRY': 'Crear entrada de mercancía',
    'RolePermissions.SEND_BUY_ORDERS': 'Enviar orden de compra',
    'RolePermissions.PRODUCE_COMPOUND_SUPPLY': 'Producir insumo compuesto',
    'RolePermissions.SUBSCRIPTION_PAGE': 'Suscripciones',
    'RolePermissions.RESTAURANT_PAGE': 'El restaurante',
    'RolePermissions.TICKET_COMMAND_PAGE': 'Impresiones',
    'RolePermissions.INTEGRATIONS_PAGE': 'Integraciones',
    'RolePermissions.TAXES_PAGE': 'Taxes',
    'RolePermissions.PRINTERS_PAGE': 'Impresoras',
    'RolePermissions.DEVICES_PAGE': 'Dispositivos',
    'RolePermissions.USER_MANAGEMENT_PAGE': 'Administración de usuarios',
    'RolePermissions.OPENING_HOURS_PAGE': 'Horas de apertura',
    'RolePermissions.CREATE_PROMO_CODES': 'Crear promo code',
    'RolePermissions.UPLOAD_MARKETING_DATA': 'Subir información',
    'RolePermissions.CREATE_PAYMENT_LINKS': 'Crear links de pago',
    'RolePermissions.OPEN_CASH_REGISTER': 'Abrir caja',
    'RolePermissions.DEPOSIT_CASH_REGISTER': 'Depositar',
    'RolePermissions.VIEW_ONLY_ORDERS_IN_PERSONAL_CASH_REGISTER': 'Solo ver las órdenes de su caja',
    'RolePermissions.WITHDRAW_CASH_REGISTER': 'Retirar',
    'RolePermissions.CASH_REGISTER_SHIFT': 'Cerrar caja',
    'RolePermissions.CASH_REGISTER_CLOSE': 'Cerrar día',
    'RolePermissions.BLIND_CASH_REGISTER_SHIFT_FOR_CASH_ONLY': 'Realizar cortes de caja ciego solo para efectivo',
    'RolePermissions.BLIND_CASH_REGISTER_SHIFT': 'Realizar cortes de caja ciego',
    'RolePermissions.OPEN_PERSONAL_CASH_REGISTER': 'Abrir caja personal',
    'RolePermissions.MANAGE_CASH_REGISTERS': 'Administrar cajas registradoras',
    'RolePermissions.EDIT_RESTAURANT_ZONES': 'Editar zonas del restaurante',
    'RolePermissions.PIN_CODE_SIGN_IN': 'Inicio de sesión con pin',
    'RolePermissions.PASSWORD_SIGN_IN': 'Inicio de sesión con contraseña',
    'RolePermissions.MENU_EDIT': 'Edit menu',
    'RolePermissions.SERVE_ORDERS': 'Registrar órdenes a nombre del usuario',
    'RolePermissions.ASSIGN_ORDERS': 'Asignar órdenes',
    'RolePermissions.OPEN_CLOSED_ORDERS': 'Desbloquear órdenes cerradas',
    'RolePermissions.GENERAL_REPORT': 'Reporte general',
    'RolePermissions.CUSTOMERS_REPORT': 'Reporte de comensales',
    'RolePermissions.PROMO_CODE_REPORT': 'Reporte de códigos de promoción',
    'RolePermissions.ORDER_ISSUES_REPORT': 'Reporte de problemas con órdenes',
    'RolePermissions.INTEGRATIONS_REPORT': 'Reporte de integraciones',
    'RolePermissions.DELIVERIES_REPORT': 'Reporte de envíos',
    'RolePermissions.WEB_ORDERS_CONVERSIONS_REPORT': 'Menú web',
    'RolePermissions.SALES_BY_DAY_REPORT': 'Reporte de ventas por día',
    'RolePermissions.SALES_BY_MENU_REPORT': 'Reporte de ventas por menú',
    'RolePermissions.ABC_REPORT': 'Reporte ABC',
    'RolePermissions.FINANCE_REPORT': 'Reporte de finanzas',
    'RolePermissions.POS_BUSINESS_DAY_REPORT': 'Reporte de cierre de días',
    'RolePermissions.CASH_REGISTER_REPORT': 'Reporte de cajas',
    'RolePermissions.TRANSACTIONS_REPORT': 'Reporte de transacciones',
    'RolePermissions.POS_USERS_REPORT': 'Reporte de usuarios',
    'RolePermissions.POS_USER_SALES_REPORT': 'Reportes de venta por usuario',
    'RolePermissions.EMPLOYEE_REPORT': 'Reporte de empleados',
    'RolePermissions.SUPPLIES_HISTORY_REPORT': 'Reporte de historial de insumos',
    'RolePermissions.KARDEX_REPORT': 'Reporte kardex',
    'RolePermissions.INVENTORY_CONSUMPTION_REPORT': 'Reporte de consumo de inventario',
    'RolePermissions.INVENTORY_ADJUSTMENTS_REPORT': 'Reporte de ajuste de inventario',
    'RolePermissions.INVENTORY_SUPPLIERS_REPORT': 'Reporte de proveedores',
    'RolePermissions.POS_REPORTS_PAGE': 'Reportes de POS',
    'RolePermissions.BRAND': 'Habilitar vista Master de sucursales',
    'RolePermissions.DELIVERY_ZONES_PAGE': 'Zonas de reparto',
    'RolePermissions.LOKI': 'ACCESO',
    'RolePermissions.SMART_BI_REPORT': 'Smart BI (predicciones)',
    'Are you sure you want to remove the role @roleName?': '¿Estás seguro que deseas eliminar el rol @roleName?',
    'Are you sure you want to remove the user @name?': '¿Estás seguro que deseas eliminar el usuario @name?',
    'Change Role': 'Cambiar rol',

    'InitialRoutePaths.HOME': 'Inicio',
    'InitialRoutePaths.RESTAURANT_DASHBOARD': 'Reportes',
    'InitialRoutePaths.ONGOING_ORDERS': 'Pedidos pendientes',
    'InitialRoutePaths.ORDERS': 'Todos los pedidos',
    'InitialRoutePaths.POS': 'POS',
    'InitialRoutePaths.POS_TABLES': 'Mesas',
    'InitialRoutePaths.POS_CASH_REGISTERS': 'Cajas',
    'InitialRoutePaths.THE_RESTAURANT': 'El restaurante',
    'InitialRoutePaths.THE_MENU': 'Menú',
    'InitialRoutePaths.INVENTORY': 'Inventario',
    'InitialRoutePaths.ORDER_INVOICES': 'Facturas de Órdenes',
    'InitialRoutePaths.PROMO_CODES': 'Promo codes',
    'InitialRoutePaths.SURVEYS': 'Encuestas',
    'InitialRoutePaths.PAYMENT_LINKS': 'Links de pago',
    'InitialRoutePaths.MARKETING': 'Marketing',
    'InitialRoutePaths.ADVANCED_REPORTS': 'Reportes avanzados',
    'The initial page @initialPage needs the @rolePermission permission': 'La página inicial @initialPage necesita el permiso de @rolePermission',
    'The role has to have at least one permission': 'El rol debe tener al menos un permiso',
    'Failed to sign in because your account is not configured for any restaurant. Please contact support!': 'Hubo un error al iniciar sesión, tu cuenta no está configurada para ningún restaurante. ¡Por favor ponte en contacto con soporte!',
    'Failed to sign in because your account does not have permission to sign in with password. Please contact your manager to sign in first.': 'Hubo un error al iniciar sesión, tu cuenta no tiene permiso para inciar sesión con contraseña. Por favor ponte en contacto con tu manager para inciar sesión.',
    'Comment': 'Comentario',
    'Select an comment': 'Selecciona un comentario',
    'Add comment in order': 'Agregar comentario a la orden',
    'Special instructions, utensils, etc.': 'instrucciones especiales, utensilios, etc.',
    'Save Comment': 'Guardar Comentario',
    'Add Comment': 'Añadir Comentario',
    'Edit Comment': 'Editar Comentario',
    'CommentToOrderDialog Description': 'Cualquier cambio que no esté en el menú incluirá un costo extra',

    'Your commission report': 'Tu reporte de comisiones',

    'RegimesFiscal.601': '601 - General de Ley Personas Morales',
    'RegimesFiscal.603': '603 - Personas Morales con Fines no Lucrativos',
    'RegimesFiscal.605': '605 - Sueldos y Salarios e Ingresos Asimilados a Salarios',
    'RegimesFiscal.606': '606 - Arrendamiento',
    'RegimesFiscal.612': '612 - Personas Físicas con Actividades Empresariales y Profesionales',
    'RegimesFiscal.620': '620 - Sociedades Cooperativas de Producción que optan por diferir sus ingresos',
    'RegimesFiscal.621': '621 - Incorporación Fiscal',
    'RegimesFiscal.622': '622 - Actividades Agrícolas, Ganaderas, Silvícolas y Pesqueras',
    'RegimesFiscal.623': '623 - Opcional para Grupos de Sociedades',
    'RegimesFiscal.624': '624 - Coordinados',
    'RegimesFiscal.625': '625 - Régimen de las Actividades Empresariales con ingresos a través de Plataformas Tecnológicas',
    'RegimesFiscal.626': '626 - Régimen Simplificado de Confianza',
    'RegimesFiscal.628': '628 - Hidrocarburos',
    'RegimesFiscal.629': '629 - De los Regímenes Fiscales Preferentes y de las Empresas Multinacionales',
    'RegimesFiscal.630': '630 - Enajenación de acciones en bolsa de valores',
    'Regime Fiscal': 'Régimen Fiscal',
    'Regime fiscal': 'Régimen fiscal',

    'Products': 'Productos',
    'Modifier Groups': 'Grupos de modificadores',
    'Modifier Group': 'Grupo de modificadores',
    'Failed to send the report by email.': 'No se pudo enviar el reporte por correo electrónico.',
    'Failed to send the report by email. Are you sure an email is already registered?': 'No se pudo enviar el reporte por correo electrónico. ¿Estás seguro de que ya hay un correo electrónico registrado?',
    'Report successfully sent out via email': 'Reporte enviado con éxito por correo electrónico',
    'Destination restaurant': 'Restaurante de destino',
    'Destination supply': 'Insumo de destino',
    'Select your recipe': 'Selecciona tu receta',
    'You have selected supplies without units on common.': 'Has seleccionado insumos sin unidades en común.',
    'There is no restaurants with inventory enabled.': 'No hay restaurantes con el inventario habilitado.',
    'No modifier groups': 'No hay grupos de modificadores',
    'Add modifier groups to this menu item': 'Añadir grupos de modificadores a este producto',

    'This menuItem will contain this modifier groups': 'Este producto contendrá estos grupos de modificadores',
    'Recipes for this product': 'Recetas para este producto',
    'Select Recipes': 'Seleccionar recetas',
    'Check our list': 'Revisa nuestra lista de ',
    'Available printers': 'Impresoras Disponibles',
    'Compatible printers': 'Impresoras Compatibles',
    'Add printer': 'Agregar Impresora',
    'PideDirecto supports standard ESC/POS printers and WiFi, USB or Bluetooth connections': 'PideDirecto soporta impresoras con estandar ESC/POS y conexiones WiFi o Blueetooth solo disponibles para la App de PideDirectoAdmin. Para la versión web, de momento solo se pueden usar printers por conexión USB.',
    'The paper sizes that are allowed are 80mm or 50mm': 'Los tamaños de papel que se permiten son de 80mm o 50mm.',
    'Model': 'Modelo',
    'Continue editing': 'Continuar Editando',

    'Reassign waiter': 'Cambiar mesero',
    'Assign waiter': 'Asignar mesero',
    'Select waiter to assign the order': 'Selecciona el mesero al que se le va a asignar la orden',
    'Waiter': 'Mesero',
    'Waiter @waiterName': 'Mesero @waiterName',
    'User authorization': 'Autorización de usuario',

    'Sale by payment method': 'Venta por metodo de pago',

    'Enter the POS Password': 'Introduce la contraseña de POS',
    'Customer notes': 'Notas cliente',
    'Customer notes:': 'Notas del cliente:',
    'Order notes': 'Notas orden',
    'Frequent client': 'Cliente frequente',
    'Extra napkins': 'Servilletas extra',
    'Leave it outside at the door': 'Déjalo afuera en la puerta',
    'Supreme Burger': 'Hamburguesa suprema',
    'No cheese': 'Sin queso',
    'Extras': 'Extras',
    'Extra meat': 'Carne extra',
    'Choose your fries': 'Elige tus papas fritas',
    'French fries': 'Papas a la francesa',
    'Choose your salsa': 'Elege tu salsa',
    'Chipotle dip': 'Salsa chipotle',
    'Red salsa': 'Salsa verde',

    'ConsecutiveOrderIdRanges.DAILY': 'Diario',
    'ConsecutiveOrderIdRanges.WEEKLY': 'Semanalmente',
    'ConsecutiveOrderIdRanges.MONTHLY': 'Mensualmente',
    'ConsecutiveOrderIdRanges.POS_BUSINESS_DAY': 'Al cierre de día',
    'ConsecutiveOrderIdRanges.NOT_RESET': 'Nunca reiniciar el conteo',

    'Consecutive Order Id': 'Identificador de órdenes consecutivo',
    'Range to reset order folio': 'Reiniciar el conteo del folio de las órdenes',
    'Add payment': 'Añadir pago ',
    'Enable money bills numeric pad': 'Habilitar el pad de billetes cuando se usa efectivo',

    'Supplies used in the order': 'Insumos utilizados en la orden',
    'Supplies used in this order can be returned to inventory or marked as lost': 'Los insumos utilizados en este pedido pueden regresar al inventario o los puede marcar como perdida',
    'Return the supplies': 'Regresar Insumos',
    'Mark as lost': 'Marcar como perdida',
    'Active orders': 'Órdenes activas',
    'Assigned orders': 'Órdenes asignadas',
    'Assigned': 'Asignadas',
    'Unassigned orders': 'Órdenes sin asignar',
    'Active delivery': 'Repartidores activos',
    'Delivery without order': 'Repartidores sin órden',
    'Status delivery': 'Estatus de delivery',
    'Driver status': 'Estatus de repartidor',

    'Filters': 'Filtros',
    'Orders resume': 'Resumen de Órdenes',
    'Drivers resume': 'Resumen de Repartidores',
    'Custom price limit for manual orders': 'Límite de precio personalizado para órdenes manuales',

    'Heatmap of sales': 'Mapa de calor para ventas',
    'ABC Table': 'Tabla ABC',
    'ABC Classification': 'Clasificación ABC',

    'Average ticket': 'Ticket promedio',
    'Average mark': 'Calificación promedio',

    'Sales per Day': 'Ventas por Día',
    '% Sale': '% Ventas',
    'Sales by Category': 'Ventas por categoría',
    'Sales by Day': 'Ventas por Día',
    'Sales of @date': 'Ventas del @date',
    'Day with most sales': 'Día con mas ventas',
    'Best Customer': 'Mejor Cliente',
    'Total purchase': 'Compra Total',
    'Customer Sales': 'Ventas a Clientes',
    'Surveys by Date': 'Encuestas por Fecha',
    'Outstanding Services': 'Servicios Destacados',
    'Best Rating': 'Mejor Calificación',
    'Sales by Payment Method': 'Ventas por método de pago',
    'Most used payment method': 'Método de pago más usado',
    'Sales with @paymentMethod': 'Ventas con @paymentMethod',
    'Sales by App': 'Ventas por app',
    'Most used app': 'App más usada',
    'Sales with @app': 'Ventas con @app',
    'Average Delivery Time per Day': 'Tiempo promedio de entrega por Día',
    'Average Delivery Time': 'Tiempo de entrega promedio',
    'Max Time': 'Tiempo max.',
    'Cancelations Per Day': 'Cancelaciones por Día',
    'Total Lost': 'Pérdida total',
    'Most common Order Problem': 'Problema de orden más común',
    'Order issues by day': 'Problemas de pedidos por Día',
    'Reason of issues by hour': 'Razón de los problemas por hora',
    'Reason of issues by Day': 'Razón de los problemas por Día',

    'REJECTED': 'Rechazado',
    'CANCELLED': 'Cancelado',

    'Status: @status': 'Estado: @status',
    'Date: @date': 'Fecha: @date',
    'Hour: @date': 'Hora: @date',
    'Day: @date': 'Dia: @date',

    'Available Zones': 'Zonas disponibles',
    'Available Tables': 'Mesas disponibles',
    'No tables available': 'No hay mesas disponibles',

    'Payment Method Channels Settings': 'Configuración de los canales de los métodos de pago',
    'Cash payments channels': 'Canales en los que aceptas pagos con efectivo',
    'Credit card payments channels': 'Canales en los que aceptas pagos con tarjeta',
    'PaymentLink channels': 'Canales en los que aceptas pagos con links de pago',
    'Enabled cash payments': 'Habilita el método de pago en efectivo',
    'Enabled credit card payments': 'Habilita el método de pago con tarjeta',
    'Enabled paymentLinks': 'Habilita el método de pago con links de pago',
    'No payment methods enabled': 'No tienes ningún método de pago habilitado',

    'Free Trial Days Left': 'Días de prueba restantes',
    'Canceled orders': 'órdenes canceladas',
    'Registration status': 'Estatus de registro',
    'Open restaurant': 'Abrir',
    'Registered At': 'Día de Registro',

    'Promotions': 'Promociones',
    'Customer not found or promo code not available for this user': 'Comensal no encontrado o promo code no disponible para este usuario',
    'Customer information incomplete, complete it please': 'Información del comensal incompleta, por favor completala',
    'No Promo Codes found': 'No se encontraron promo codes',
    'Apply Promotion': 'Aplicar promoción',
    'Enter Promo Code': 'Ingresar código de promoción',
    'Validate': 'Validar',
    'Receive @promoCodeCredits credits': 'Recibe @promoCodeCredits créditos',
    '@discount discount': '@discount descuento',
    'Apply Promo Code': 'Aplicar Promo Code',
    'Available Promo Codes': 'Promo Codes disponibles',
    '@amount of total': '@amount del total',
    '@percent% of total': '@percent% del total',
    '@amount discount of total order': '@amount de descuento del total de la orden',
    'Receive @amount credits of total order': 'Recibe @amount créditos del total de la orden',
    '@percent% discount of total order': '@percent% de descuento del total de la orden',
    'Receive @percent% credits of total order': 'Recibe @percent% créditos del total de la orden',
    'Promo Code Selected: @description': 'Promo Code seleccionado: @description',
    'You must order for at least @amount to use this promo code.': 'El código promocional es válido en compras mayores a @amount.',
    'This Promo Code is not available for order of type: @orderType': 'Este promo code no es aplicable a órdenes de tipo: @orderType',
    'This Promo Code is not available for this payment method': 'Este promo code no es aplicable a este tipo de pago',

    'Client': 'Cliente',
    'Cancel order': 'Cancelar orden',
    'Reject order': 'Rechazar pedido',
    'Track Rappi Order': 'Rastrear pedido de Rappi',
    'Table number': 'Número de mesa',
    'Assign table': 'Asignar mesa',
    'Change order to table': 'Cambiar orden a mesa',

    'OrderStatuses.NEW': 'Pedido nuevo',
    'OrderStatuses.CANCELLED': 'Orden Cancelada',
    'OrderStatuses.REJECTED': 'Orden Rechazada',
    'OrderStatuses.ACCEPTED': 'Orden Aceptada',
    'OrderStatuses.COMPLETE': 'Orden Completada',
    'OrderStatuses.COMPLETED': 'Orden Completada',
    'OrderStatuses.PENDING': 'Orden Pendiente',
    'OrderStatuses.CLOSED': 'Orden pendiente',
    'OrderStatuses.ARRIVED_AT_CLIENT': 'Conductor llegó a Dirección del cliente',
    'OrderStatuses.RETURNED': 'Orden Devuelta',

    'KitchenStatuses.PREPARING': 'En cocina',
    'KitchenStatuses.PREPARED': 'Orden lista',

    'DeliveryStatuses.REQUESTED': 'Asignando un repartidor',
    'DeliveryStatuses.ACCEPTED': 'Repartidor en camino',
    'DeliveryStatuses.REJECTED': 'Rechazado',
    'DeliveryStatuses.PICKED_UP': 'Repartidor en camino al domicilio',
    'DeliveryStatuses.DELIVERED': 'Entregado',
    'DeliveryStatuses.CANCELLED': 'Cancelado',
    'DeliveryStatuses.WITHDRAWN': 'Withdrawn',

    'DeliveryTimeCategories.LESS_THAN_30_MINUTES': 'Menos de 30 minutos',
    'DeliveryTimeCategories.BETWEEN_30_AND_45_MINUTES': 'Entre 30 y 45 minutos',
    'DeliveryTimeCategories.BETWEEN_45_AND_60_MINUTES': 'Entre 45 y 60 minutos',
    'DeliveryTimeCategories.MORE_THAN_60_MINUTES': 'Mas de 60 minutos',
    'DeliveryTimeCategories.ALL': 'Todos',

    'Table @numberTable': 'Mesa: @numberTable',
    '@numberOfCustomers customers': '@numberOfCustomers clientes',
    'Reject Reasons': 'Motivo de rechazo',
    '% Sales': '% Ventas',
    'OrderTypes.@orderType PickupTimeTypes.@pickupType': '',

    'Enable POS payment methods': 'Habilitar métodos de pago en POS',

    'Identify Customer': 'Identificar cliente',
    'Apply Credits': 'Aplicar créditos',
    'Credits are applied only to credit card payments': 'Los créditos se aplican únicamente a pagos con tarjeta',
    'Customer not has available credits': 'El cliente no tiene créditos disponibles',
    'Total Credits: @customerCredits': 'Total de créditos: @customerCredits',

    'DynamicDeliveryEarningsReasons.WEATHER': 'Clima',
    'DynamicDeliveryEarningsReasons.DISTANCE': 'Distancia',
    'DynamicDeliveryEarningsReasons.LONG_TIME_OUT_TO_ASSIGN': 'Demora en la asignación',
    'DynamicDeliveryEarningsReasons.NOT_DRIVERS_IN_THE_ZONE': 'Falta de repartidores en la zona',
    'Dynamic Delivery Earnings Reason': 'Razón de la ganancia por el envío dinámico',
    'Dynamic Delivery Earnings Reason Given By': 'Ganancia por el envío dinámico aplicada por',
    'Uruware Business Code': 'Uruware Código de Negocio',
    'Uruware Cod Terminal': 'Uruware Código de Terminal',
    'Uruware Password Web Service': 'Uruware Contraseña Web Service',
    'Uruware Address Code': 'Uruware Código de Dirección',

    'Problems with terminal?': '¿Problemas con la terminal?',
    'Cancel payment': 'Cancelar pago',
    'Are you sure you want to cancel request?': '¿Estás seguro de que quieres cancelar la petición?',

    'Client details': 'Datos del cliente',
    'Neighborhood': 'Colonia',
    'Number': 'Número del domicilio',
    'Postal code': 'Código postal',
    'Find location with Google Maps': 'Encontrar ubicación con Google Maps',
    'Confirm the delivery address': 'Confirma la dirección de entrega',
    'You are about to send an order to:': 'Estás apunto de envíar un pedido a:',
    'Confirm': 'Confirmar',
    'Change reason for cancellation': 'Cambiar razón de cancelación',
    'Cost by Average Price': 'Costo por Precio Promedio',

    'Supplies: @articles': 'Insumos: @articles',

    'ServiceType': 'Tipo de servicio',
    'ServiceTypes.TABLE_SERVICE': 'Servicio a la mesa',
    'ServiceTypes.SELF_SERVICE': 'Auto Servicio',
    'SupplyTypes.NORMAL': 'Normal',
    'SupplyTypes.COMPOUND': 'Compuesto',
    'The modifier groups help to customize your products': 'Los grupos de modificadores ayudan a personalizar tus productos',
    'On this section you will be able to create and manage your modifier groups': 'En esta sección podrás crear y gestionar tus grupos de modificadores',
    'Create modifier groups': 'Crear grupos de modificadores',
    'In this section you can manage the different modifiers of this product': 'En esta sección puedes administrar los diferentes modificadores de este producto',
    'Click': 'Da click',
    ' or drag your product image.': ' o arrastra la imagen de tu producto.',
    'Where will it be printed?': '¿Dónde se va a imprimir?',
    'Save changes': 'Guardar cambios',
    'Select groups': 'Seleccionar grupos',
    'Is it a modifier?': '¿Es un modificador?',
    'Enable commissions': 'Habilitar comisiones',
    'Fixed Commission': 'Comisión fija',
    'Office Delivery Fixed Commission': 'Entrega a oficina comisión fija',
    'Show as a menu item?': '¿Se mostrará como producto en el menú?',
    'Make sure image is 1280x854 pixels or at least similar aspect ratio and not lower resolution.': 'Asegúrese de que la imagen sea de 1280 x 854 píxeles o similar y no una resolución más baja.',
    'A menu cannot exist without products': 'Un menú no puede existir sin productos',
    'In this section you can create and manage your product catalog': 'En esta sección podrás crear y gestionar tu catálogo de productos',
    'Create modifier group': 'Crear grupo de modificadores',
    'External modifier group id': 'Id de grupo de modificadores externo',
    'Minimum required': 'Mínimo requerido',
    'Maximum required': 'Máximo requerido',
    'Add modifiers': 'Añadir modificadores',
    'Select your modifiers': 'Selecciona tus modificadores',
    'Save modifiers': 'Guardar modificadores',
    '@numberModifiers modifiers': '@numberModifiers modificadores',
    'You are about to remove the modifier group @modifierGroupName, are you sure you want to continue?': 'Estás a punto de eliminar el grupo de modificadores @modifierGroupName, ¿estás seguro de querer continuar?',
    'Modifier groups': 'Grupos de modificadores',
    'Create +': 'Crear +',
    'If you leave it empty will be used the price of the product': 'Si lo dejas en blanco será usado el precio del producto',
    'No, I want to keep it': 'No, quiero conservarlo',
    'Yes, I want to delete it': 'Si, quiero eliminarlo',
    'Remove modifier group': 'Eliminar grupo de modificadores',
    'Remove menu item': 'Eliminar producto',
    'Remove everything linked to menu': 'Eliminar todo lo vinculado al menú',
    'Welcome to AmbitOne!': '¡Bienvenido a AmbitOne!',
    'On this section you will be able to create and manage your menus. Do we start?': 'En esta sección podrás crear y administrar tus menús. ¿Comenzamos?',
    'Select your sales channels': 'Selecciona tus canales de venta',
    'Remove menu': 'Eliminar menú',
    'You are about to remove the menu @menuName, are you sure you want to continue?': 'Estás a punto de eliminar el menú @menuName, ¿estás seguro de querer continuar?',
    'You are about to remove the menu item @menuItemName, are you sure you want to continue?': 'Estás a punto de eliminar el producto @menuItemName, ¿estás seguro de querer continuar?',
    'On this section you can manage the different categories in your menus': 'En esta sección puedes administrar las diferentes categorías dentro de tus menús',
    'On this section you can manage the different products in your category': 'En esta sección puedes administrar los diferentes productos dentro de tu categoría',
    'On this section you can manage the different modifier groups in your category': 'En esta sección puedes administrar los diferentes grupos de modificadores dentro de tu categoría',
    'On this section you can manage the different modifier groups in your product': 'En esta sección puedes administrar los diferentes grupos de modificadores dentro de tu producto',
    'Click here': 'Haz click aquí',
    'You are about to remove the category @menuCategoryName from @menuName, are you sure you want to continue?': 'Estás a punto de eliminar la categoría @menuCategoryName en @menuName, ¿estás seguro de querer continuar?',
    'Remove category': 'Eliminar categoría',
    'You are about to delete the @title category, are you sure you want to continue?': 'Estás a punto de eliminar la categoría @title, ¿estás seguro de querer continuar?',
    'You are about to delete the @menuItemName product, are you sure you want to continue?': 'Estás a punto de eliminar el producto @menuItemName, ¿estás seguro de querer continuar?',
    'In this section you can manage the different products and modifiers within the categories': 'En esta sección puedes administrar los diferentes productos y modificadores dentro de las categorías',
    'Categories catalog your products in the menu': 'Las categorías catalogan tus productos en el menú',
    'On thisDelete Category section you can create and manage your categories': 'En esta sección podrás crear y gestionar tus categorías',
    'Remove product': 'Eliminar producto',
    'Add Product +': 'Añadir producto +',
    'Add modifier group +': 'Añadir grupo de modificadores +',
    'Add modifier groups +': 'Añadir grupos de modificadores +',
    'Select your modifier groups': 'Seleccionar tus grupos de modificadores',
    'Add new modifier': 'Añadir nuevo modificador',
    'Select Products': 'Seleccionar Productos',
    'Select your products': 'Seleccione sus productos',
    'New menu management enabled': 'Nueva administración de menú activada',
    'Add categories +': 'Añadir categorías +',
    'Select your categories': 'Selecciona tus categorías',
    'Change Manual Order Cash Total': 'Habilitar Cambio de Precio en Ordenes Manuales Efectivo',
    'Change Cash Total': 'Cambiar Total de Orden Efectivo',
    'New order cost': 'Nuevo costo de orden',
    'Change order cost': 'Cambiar costo de orden',
    'Old Cost:': 'Costo anterior:',

    'The shipping cost cannot be higher than the cost of the order': 'El costo de envío no puede ser mayor que el costo de la orden',

    'Delivery Categories': 'Categorías de entrega',
    'This will set how the restaurant will be categorised in app. Visible categories in app is set in Settings > Delivery Categories. If the restaurants delivery category does not exist in Settings > Delivery Categories it will be hidden in app.': 'Esto establecerá cómo se clasificará el restaurante en la aplicación. Las categorías visibles en la aplicación se establecen en Configuración > Categorías de entrega. Si la categoría de entrega de restaurantes no existe en Configuración > Categorías de entrega, se ocultará en la aplicación.',
    'Delivery Manager Max Runtime': 'Tiempo máximo de ejecución de Delivery Manager',
    'Activate Dynamic delivery manager': 'Activar Delivery Manager Dinámico',
    'External Delivery': 'Entrega externa',
    'Enable this if orders are delivered using external drivers': 'Habilite esto si los pedidos se entregan utilizando repartidores externos',
    'Enable this option to allow the restaurant to use external drivers. This option cannot be activated if you have delivery invoiced feature': 'Active esta opción para permitir que el restaurante utilice repartidores externos. Esta opción no se puede activar si tiene la función de entrega facturada',
    'Enable this if orders are NOT delivered using PideDirecto drivers': 'Habilite esto si los pedidos NO se entregan utilizando los repartidores de PideDirecto',
    'Enable this if orders are delivered externally by stores instead of using Let˚s Eat drivers': 'Habilite esto si las tiendas entregan los pedidos externamente en lugar de usar los controladores Let˚s Eat',
    'Custom delivery address enabled': 'Dirección de entrega personalizada habilitada',
    'Delivery Security Code Enabled': 'Código de seguridad de entrega habilitado',
    'Enable this to create a code for each order': 'Habilite esto para crear un código para cada pedido',
    'Manual OrdersPage': 'Página de pedidos manuales',
    'Enable this to let restaurant create their own delivery orders from manager': 'Habilite esto para permitir que el restaurante cree sus propios pedidos de entrega desde el administrador',
    'Delivery Radius in Km': 'Radio de entrega en Km',
    'If not set the default value used is configured in Settings > Delivery Radius in Km': 'Si no se establece, el valor predeterminado utilizado se configura en Ajustes > Radio de entrega en Km.',
    'Customer Delivery Cost Fixed': 'Costo fijo de entrega al cliente',
    'If not set the default value used is configured in Settings > Customer Delivery Cost Fixed': 'Si no se establece, el valor predeterminado utilizado se configura en Configuración > Costo fijo de entrega al cliente',
    'Customer Delivery Cost Per Km': 'Costo de entrega al cliente por km',
    'If not set the default value used is configured in Settings > Customer Delivery Cost Per Km': 'Si no se establece, el valor predeterminado utilizado se configura en Configuración > Costo de entrega al cliente por kilómetro.',
    'Dynamic Earnings Minimum Minutes': 'Minutos mínimos de ganancias dinámicas',
    'Dynamic Earnings Amount To Increment By Minute': 'Cantidad de ganancias dinámicas a incrementar por minuto',
    'Dynamic Earnings Roof': 'Techo de ganancias dinámicas',
    'Driver Long Distance Delivery Extra Earnings Starts From Km': 'Las ganancias adicionales de entrega de larga distancia del conductor comienzan desde el kilómetro',
    'Deliveries with drive distance longer than this distance will have additional extra earnings added for drivers. The extra earnings are set in setting "Driver Long Distance Delivery Extra Earnings Fixed" and "Driver Long Distance Delivery Extra Earnings Per Km". If not set the default value used is configured in Settings.': 'Las entregas con una distancia de conducción superior a esta distancia tendrán ganancias adicionales adicionales para los conductores. Las ganancias adicionales se establecen en la configuración "Ganancias adicionales de entrega de larga distancia del conductor fijas" y "Ganancias adicionales de entrega de larga distancia del conductor por kilómetro". Si no se establece, el valor predeterminado utilizado se configura en Configuración.',
    'Driver Long Distance Delivery Extra Earnings Fixed': 'Conductor Entrega de larga distancia Ganancias adicionales fijas',
    'Add this extra earnings for drivers when delivery distance for a delivery is longer than in setting "Driver Long Distance Delivery Extra Earnings Starts From Km". If not set the default value used is configured in Settings.': 'Agregue estas ganancias adicionales para los conductores cuando la distancia de entrega para una entrega sea más larga que en la configuración "Las ganancias adicionales de entrega de larga distancia del conductor comienzan desde el kilómetro". Si no se establece, el valor predeterminado utilizado se configura en Configuración.',
    'Driver Long Distance Delivery Extra Earnings Per Km': 'Conductor Entrega Larga Distancia Ganancia Extra Por Km',
    'Add this extra earnings per km (above setting limit) for drivers when delivery distance for a delivery is longer than in setting "Driver Long Distance Delivery Extra Earnings Starts From Km". If not set the default value used is configured in Settings.': 'Agregue estas ganancias adicionales por km (por encima del límite establecido) para los conductores cuando la distancia de entrega para una entrega sea más larga que en la configuración "Las ganancias adicionales de entrega de larga distancia del conductor comienzan desde el kilómetro". Si no se establece, el valor predeterminado utilizado se configura en Configuración.',
    'Enable Delivery Paid and Invoice Controls': 'Habilitar controles de entrega pagada y factura',
    'Deliveries Will Be Invoiced To The Client': 'Las entregas serán facturadas al cliente',
    'Enable this if deliveries should be paid by and invoiced to the restaurant. This option cannot be activated if you have external own fleet': 'Habilite esto si las entregas deben pagarse y facturarse al restaurante. Esta opción no esta disponible si se tiene activo la opcion de mi flotilla externa',
    'Restaurant Delivery Zones Enabled': 'Zonas de entrega de restaurante habilitadas',
    'Enable this to let restaurant create their own delivery zones': 'Habilite esto para permitir que el restaurante cree sus propias zonas de entrega',

    'Restaurant Type': 'Tipo de Restaurante',
    'Services': 'Servicios',
    'Remove Restaurant': 'Eliminar Restaurante',
    'Company Names': 'Nombres de empresas',
    'Contact developer to add new cities': 'Póngase en contacto con el desarrollador para agregar nuevas ciudades',
    'Public Pickup Station': 'Estación de recogida pública',
    'Google Street Address': 'Dirección de calle de Google',
    'Info visible in app below opening hours when opening a restaurant (the top of the menu page)': 'Información visible en la aplicación debajo del horario de apertura al abrir un restaurante (la parte superior de la página del menú)',
    'The url which the info box will redirect when the customer press on it': 'La URL a la que se redirigirá el cuadro de información cuando el cliente presione sobre él',
    'PideDirecto Marketplace App Orders Enabled': 'Pedidos de la aplicación PideDirecto Marketplace habilitados',
    'Add companies separated by commas, e.g. Company Name 1, Company Name 2. Adding company names will make the restaurant visible only for customers with one of the company names.': 'Agregue empresas separadas por comas, p. Nombre de la empresa 1, Nombre de la empresa 2. Agregar nombres de empresas hará que el restaurante sea visible solo para los clientes con uno de los nombres de empresas.',
    'Makes this Pickup Station visible for all customers in the select pickup station list in app (And not just for customers with company name like the ones in field Company Names above)': 'Hace que esta estación de recogida sea visible para todos los clientes en la lista de estaciones de recogida seleccionadas en la aplicación (y no solo para los clientes con el nombre de la empresa como los que se encuentran en el campo Nombres de la empresa arriba)',

    'Make sure image is 1250x250 pixels or at least similar aspect ratio. Enter an URL to an image or drag and drop your image in this field': 'Asegúrese de que la imagen tenga 1250x250 píxeles o al menos una relación de aspecto similar. Ingrese una URL a una imagen o arrastre y suelte su imagen en este campo',
    'Faked Product Discount Percentage': 'Porcentaje de descuento de producto falsificado',
    'Percentage to raise all menu item prices with and while product discount price be kept at current menu item price': 'Porcentaje para aumentar todos los precios de los elementos del menú y mientras el precio de descuento del producto se mantenga al precio actual del elemento del menú',
    'Refund Ongoing Orders Enabled': 'Reembolso de pedidos en curso habilitado',
    'Mark Ready For Pickup Button Enabled': 'Marcar listo para el botón de recogida habilitado',
    'Change the delivery address': 'Cambiar dirección de envío',
    'Delivery address cannot be changed because new estimate delivery cost is higher than the previous delivery cost and the selected payment method is credit card': 'La dirección de entrega no se puede cambiar porque el nuevo costo de envío estimado es más alto que el costo de envío anterior y el método de pago seleccionado es tarjeta de crédito',

    'Accept Order At': 'Orden aceptada en',
    'Driver Accept Duration Time': 'Tiempo de aceptacion de orden por conductor',
    'Driver Arrived At Store Minutes': 'Tiempo de llegada del conductor a la tienda',
    'Order Pick Up Time': 'Tiempo pick up de orden',
    'Driver Arrived At Client Time': 'Tiempo de llegada del conductor al cliente',
    'Driving Time': 'Tiempo de conduccion',
    'Total Order Time': 'Tiempo total de order',
    'Client name': 'Nombre del cliente',

    'RewardTypes.DISCOUNT': 'Descuento',
    'RewardTypes.CREDITS': 'Créditos en cashback',
    'RewardTypes.PRODUCTS': 'Descuento por producto',
    'Is it a product?': '¿Es un producto?',

    'Remove menu category': 'Quitar categoría de menú',
    'You have changes in the menu without updating': 'Tienes cambios en el menú sin actualizar',
    'By requesting a driver you are accepting the ': 'Al solicitar un repartidor estás aceptando los ',
    'Invoice Data': 'Información de la Factura',
    'Zip code': 'Código postal',
    'CardTypes.CREDIT': 'Crédito',
    'CardTypes.DEBIT': 'Débito',
    'Card type': 'Tipo de tarjeta',

    'External Order Id': 'ID externo',
    'Rappi Cargo Delivery Id': '',
    'Ordered At': 'Ordenado en',
    'Subtotal': 'Subtotal',
    'Promo Code Discount': 'Descuento de Promo code',
    'POS Discount': 'Descuento de POS',
    'Total Discount': 'Total de descuentos',
    'POS Payments': 'Pagos POS',
    'Order Status': 'Estatus de orden',
    'Order Reject Reason': 'Razón de rechazo',

    'Reprint Ticket': 'Reimprimir ticket',
    'Request authorization to reprint completed order': 'Pedir autorización para reimprimir una orden completada',
    'Create business': 'Crear negocio',

    'Make sure the image is 296x296 pixels or at least a similar aspect ratio that is a multiple of 8 and no larger than 300, of type png or jpg': 'Asegúrate de que la imagen tiene 296x296 píxeles o al menos una relación de aspecto similar que sea múltiplo de 8 y no mayor a 300, de tipo png o jpg',

    'Invoice order': 'Facturar orden',
    'To Invoice': 'Facturar',
    'Invoice': 'Factura',
    'Create order invoice': 'Crear factura',
    'Order Invoiced': 'Orden Facturada',

    'Inventory Settgins': 'Configuración del inventario',
    'Supply categories created': 'Categorías de insumos creadas',
    'Supply Category': 'Categoría de insumos',
    'Create new category': 'Crear nueva categoría',
    'Manage Categories': 'Administrar categorías',
    'Remove Category': 'Remover categoría',
    'There is already a category with that name': 'Ya existe una categoría con ese nombre',
    'Are you sure you want to remove supply category "@supplyCategoryName"?': '¿Está seguro de que deseas eliminar la categoría @supplyCategoryName?',

    'RestaurantSettingTabs.GENERAL': 'General',
    'RestaurantSettingTabs.ECOMMERCE': 'Ecommerce',
    'RestaurantSettingTabs.ORDERS': 'Órdenes',
    'RestaurantSettingTabs.DELIVERY': 'Envío',
    'RestaurantSettingTabs.POS': 'Punto de venta',
    'RestaurantSettingTabs.MARKETING': 'Marketing',
    'RestaurantSettingTabs.PAYMENTS': 'Pagos',
    'RestaurantSettingTabs.APPS': 'Apps',
    'Edit modifier': 'Editar modificador',
    'Change modifier': 'Cambiar modificador',
    'Modifier type': 'Tipo de modificador',
    'You can review the receipt at': 'Puedes verificar comprobante en:',
    'CFE Number': 'Número CFE',

    'Update changes to your menus': 'Actualiza los cambios en tus menús',
    'Are you sure you want to remove the business?': '¿Estás seguro que quieres eliminar el negocio?',
    'Brands': 'Marcas',
    'Change business': 'Cambiar negocio',
    'Businesses': 'Negocios',
    'Price at purchase': 'Precio al momento de la compra',
    'FiscalEntities.FISIC': 'Persona Física',
    'FiscalEntities.BUSINESS': 'Empresa',
    'Select the Fiscal Entity': 'Selecciona la Entidad Fiscal',
    'Driver tips': 'Propinas de repartidor',

    'Current delivery cost @deliveryCost': 'Costo de envío actual: @deliveryCost',
    'Delivery cost for new address @deliveryEstimate': 'Costo de envío para la nueva dirección: @deliveryEstimate',
    'Please enter the security code in order to access the content.': 'Por favor ingresa el código de seguridad para acceder al contenido.',
    'Tips': 'Propinas',
    'Discounts': 'Descuentos',
    'Change payment method': 'Cambiar método de pago',
    'Select payment': 'Seleccionar pago',
    'Select': 'Seleccionar',
    'Please select a payment method': 'Por favor selecciona un método de pago',
    'Is not possible to change the payment method for orders pending payment. Edit order to add a payment method': 'No es posible cambiar el método de pago para órdenes pendientes de pago. Editar el pedido para añadir una forma de pago',
    'Is not possible to change the payment method for ecommerce orders paid with card': 'No es posible cambiar el método de pago para órdenes de ecommerce pagadas con tarjeta',
    'Is not possible to change the payment method for orders with payment link already paid': 'No es posible cambiar el método de pago para órdenes con link de pago ya pagado',
    'Is not possible to change the payment method for kiosk orders paid with terminal': 'No es posible cambiar el método de pago para órdenes de kiosko pagadas con terminal',
    'Is not possible to change the payment method for pos orders paid with terminal': 'No es posible cambiar el método de pago para órdenes de pos pagadas con terminal',

    'Name of Customer': 'Nombre del cliente',
    'Assign name to order': 'Asigna un nombre a la orden',
    'RUC Buyer': 'RUC Comprador',
    'Business Name Buyer': 'Razón Social Comprador',
    'I.V.A to the day': 'I.V.A al día',
    'Date of document': 'Fecha de documento',

    'You have duplicated modifiers in this group.': 'Tienes modificadores duplicados en este grupo.',
    'Select a modifier': 'Selecciona un modificador',

    'Brand': 'Marca',
    'Is not possible to select this option from this page': 'No es posible seleccionar esta opción desde la página actual',
    'Business': 'Negocio',
    'Change to eat here': 'Cambiar para Comer Aqui',
    'Change to take away': 'Cambiar para Llevar',

    'POS reports': 'Reportes de POS',
    'Inventory reports': 'Reportes de inventario',
    'Adjustments Inventory Report': 'Reporte de ajustes de inventario',
    'Total discounts': 'Descuentos totales',
    'Delivery costs': 'Costos de envío',
    'Expected @expectedAmount': 'Esperado @expectedAmount',
    'Received @amount': 'Recibido @amount',
    'Total received': 'Total recibido',
    'Amounts received by payment method': 'Montos recibidos por método de pago',
    'Cash register id': 'Id de caja',
    'Historical sales': 'Ventas historicas',
    'Number of transactions': 'Cantidad de transacciones',
    'Cash flow': 'Flujo de efectivo',
    'Cash register #@shortCashRegisterId': 'Caja #@shortCashRegisterId',
    'Transaction reasons': 'Razones de transacción',
    'Transactions: @transactions': 'Transacciones: @transactions',
    'Transaction by user': 'Transacciones por usuario',
    'User @user': 'Usuario @user',
    'Cash register': 'Caja',
    'Cash register @cashRegisterNumber': 'Caja @cashRegisterNumber',
    'Total transactions by user': 'Cantidad de transacciones por usuario',
    'Transactions by day': 'Transacciones por día',

    'Take order by customer': 'Tomar orden por comensal',
    'It is not possible to reduce the number of diners when the order is divided by customers': 'No es posible reducir el número de comensales cuando la orden es dividida por comensales',
    'Select a customer to pay his order': 'Seleciona un comensal para pagar su orden',
    "It isn't possible to reduce the number of customers at the table when you are taking order per customer": 'No es posible reducir el número de comensales en la mesa cuando estás tomando una orden por comensal',
    'Served orders': 'Órdenes servidas',
    'Income received': 'Ingresos recibidos',
    'Tips received': 'Propinas recibidas',
    'Orders served by waiter': 'Órdenes recibidas por mesero',
    'Orders: @orders': 'Órdenes: @orders',
    'Tips by waiter': 'Propinas por mesero',
    'Tips: @tips': 'Propinas: @tips',
    'Sales by waiter': 'Ventas por mesero',

    'pending': 'Pendiente',
    'sent': 'Enviada',
    'completed': 'Completada',
    'cancelled': 'Cancelada',
    'Promotion price': 'Precio de promoción',
    'The prices of this product will only be changed in this menu': 'Los precios de este producto solo serán cambiados en este menú',
    'Change prices of @menuItemName': 'Cambiar precios de @menuItemName',

    'Return Order': 'Devolución',
    'Return of products': 'Devolución de productos',
    'Select return type': 'Selecciona el tipo de devolución',
    'Describe return reason': 'Describa el motivo de la devolución',
    'Select a product to return': 'Selecciona algún producto a devolver',
    'OrderReturnedTypes.PARTIAL': 'Parcial',
    'OrderReturnedTypes.TOTAL': 'Total',
    'All your platforms on a single screen': 'Todas tus plataformas en una sola pantalla',

    'Start test': 'Comenzar Prueba',
    'Integrate all platforms': 'Integra todas las plataformas',
    'Enables the new orders page. This is under develop yet so enabling this wont affect anything': 'Habilita la nueva página de órdenes. Esta feature está todavía en desarrollo habilitarlo no afectará en nada',

    'Stations': 'Estaciones',
    'Configure your printer in a few clicks and print your tickets and order easily': 'Configura tu impresora en unos cuantos clicks e imprime tus tickets y comanda fácilmente',
    'The printing stations are the different areas of your restaurant from where you can print': 'Las estaciones de impresión son las diferentes zonas de tu restaurante desde donde podrás imprimir',
    'Start': 'Comenzar',
    'Create a printer': 'Crea una impresora',
    'Create a printing station': 'Crea una estación de impresión',
    'Orders without IVA': 'Órdenes sin IVA',
    'Taxes Management': 'Gestión de impuestos',
    'General reports': 'Reportes generales',
    'Orders issues': 'Problemas con órdenes',
    'Promo code most used': 'Promo code más usado',
    'Sales by promoCode per day': 'Ventas por promo code y día',
    'Customers report': 'Clientes',
    'Delivery report': 'Envío',
    'Orders issues report': 'Problemas con órdenes',
    'Promo codes report': 'Promo Codes',
    'Sales reports': 'Reportes de ventas',
    'By day': 'Por día',
    'By menu': 'Por menú',
    'ABC analysis': 'Análisis ABC',
    'Menu sales report': 'Ventas del menú',
    'ABC analysis report': 'Análisis ABC',
    'Total value in shortages': 'Valor total en faltantes',
    'Total value in leftovers': 'Valor total en sobrantes',
    'Inventory Adjustments Report': 'Reporte de ajustes de inventario',
    'Expected Quantity': 'Cantidad esperada',
    'Daily sales report': 'Ventas diarias',
    'Financial report': 'Finanzas',
    'Pos business days': 'Cierres de día',
    'Maximum ticket': 'Ticket máximo',
    'Minimum ticket': 'Ticket mínimo',
    'There is no data for this report': 'No hay datos para este reporte',
    'Historical sales by waiter': 'Ventas historicas por mesero',
    'Cash register reports': 'Cajas',
    'Cash register transactions report': 'Transacciones',
    'Manager user reports': 'Usuarios',
    'Sales by User': 'Ventas por usuario',
    'Per Product': 'Por producto',
    'Per User': 'Por usuario',
    'Rappi Cargo Web Card': 'Tarjeta Rappi Cargo Ecommerce',
    'Rappi Cargo Web Cash': 'Efectivo Rappi Cargo Ecommerce',
    'Rappi Cargo App Card': 'Tarjeta Rappi Cargo App',
    'Rappi Cargo App Cash': 'Efectivo Rappi Cargo App',
    'Rappi Cargo Manual Orders': 'Rappi Cargo Órdenes Manuales',
    'Rappi Cargo Manual Order': 'Rappi Cargo Orden Manual',
    'Rappi Cargo Payment Link': 'Rappi Cargo Link de Pago',
    'Rappi Cargo Pos Card': 'Tarjeta Rappi Cargo POS',
    'Rappi Cargo Pos Cash': 'Efectivo Rappi Cargo POS',
    'Rappi Cargo Bringg Card': 'Tarjeta Rappi Cargo Bringg',
    'Rappi Cargo Bringg Cash': 'Efectivo Rappi Cargo Bringg',
    'Uber Daas Web Card': 'Tarjeta Uber Daas Ecommerce',
    'Uber Daas Web Cash': 'Efectivo Uber Daas Ecommerce',
    'Uber Daas App Card': 'Tarjeta Uber Daas App',
    'Uber Daas App Cash': 'Efectivo Uber Daas App',
    'Uber Daas Manual Orders': 'Uber Daas Órdenes Manuales',
    'Uber Daas Manual Order': 'Uber Daas Orden Manual',
    'Uber Daas Payment Link': 'Uber Daas Link de Pago',
    'Uber Daas Pos Card': 'Tarjeta Uber Daas POS',
    'Uber Daas Pos Cash': 'Efectivo Uber Daas POS',
    'Uber Daas Bringg Card': 'Tarjeta Uber Daas Bringg',
    'Uber Daas Bringg Cash': 'Efectivo Uber Daas Bringg',
    'Pedidos ya Cash': 'Efectivo Pedidos ya',
    'Pedidos ya Card': 'Tarjeta PedidosYa',
    'Uber Daas Marketplace Card': 'Tarjeta Uber Daas Marketplace',
    'Uber Daas Marketplace Cash': 'Efectivo Uber Daas Marketplace',
    'Search': 'Buscar',

    'Invalid font': 'Fuente inválida',
    'Invalid value': 'Valor inválida',
    'Invalid align: @align': 'Alineación inválida: @align',
    'Invalid barcode size': 'Tamaño del código de barras inválido',
    'Barcode not supported': 'Código de barras no soportado',
    'Select dates': 'Seleccionar fechas',
    'Sales report': 'Reporte de ventas',
    'Order details #@shortOrderId': 'Detalles de la orden #@shortOrderId',
    'Modified at': 'Modificado',
    'For integrations, add maximum required if the modifiers in this group are of type multiple': 'Para integraciones, agrega un máximo requerido si los modificadores de este grupo son tipo múltiple',
    'Add modifiers +': 'Agregar modificadores +',
    'Rappi Version': 'Versión de Rappi',
    'Integration disabled': 'Integración deshabilitada',

    'Ticket and Command': 'Ticket y Comanda',
    'Command': 'Comanda',
    'Custom text': 'Texto personalizado',
    'Print test': 'Imprimir prueba',
    'Customize ticket': 'Personaliza el ticket',
    'Customize command': 'Personaliza la comanda',
    'Business Information': 'Información físcal',
    'Customer Information': 'Información del comensal',
    'General Information': 'Información general',
    'Sales Information': 'Información de venta',
    'Operation Date': 'Fecha y hora de la impresión',
    'You have changes without updating': 'Tienes cambios sin actualizar',
    'Adds or removes information displayed when printing': 'Agrega o elimina la información que se muestra al imprimir',

    'Applicable for orders to eat here': 'Aplicable en órdenes  para comer aquí',
    'Only available for app': 'Disponible solo para la aplicación',

    'TicketSections.LOGO': 'Logotipo',
    'TicketSections.HEADER_ORDER_TYPE': 'Tipo de orden',
    'TicketSections.CUSTOM_HEADER_TEXT': 'Texto Personalizado',
    'TicketSections.BUSINESS_NAME': 'Razón social',
    'TicketSections.RFC': 'RFC',
    'TicketSections.FISCAL_ADDRESS': 'Dirección fiscal',
    'TicketSections.RESTAURANT_EMAIL': 'Email',
    'TicketSections.RESTAURANT_PHONE': 'Teléfono',
    'TicketSections.RESTAURANT_SECONDARY_PHONE': 'Teléfono secundario',
    'TicketSections.ORDER_ID': 'Orden',
    'TicketSections.CONSECUTIVE_ID': 'Folio',
    'TicketSections.ORDER_TYPE': 'Tipo de orden',
    'TicketSections.TABLE_NUMBER': 'Mesa',
    'TicketSections.WAITER_NAME': 'Mesero',
    'TicketSections.CREATED_AT': 'Fecha y hora',
    'TicketSections.CUSTOMER_NAME': 'Nombre',
    'TicketSections.CUSTOMER_PHONE': 'Teléfono',
    'TicketSections.CUSTOMER_ADDRESS': 'Dirección',
    'TicketSections.SALES_INFO': 'Mostrar información',
    'TicketSections.PAYMENT_METHOD': 'Método de pago',
    'TicketSections.PAYMENT_CHANGE': 'Cambio',
    'TicketSections.DATE_OF_PRINT': 'Fecha de impresión',
    'TicketSections.INVOICE_LINK': 'Link de factura en línea',
    'TicketSections.INVOICE_QR': 'Mostrar QR',
    'TicketSections.CUSTOM_FOOTER_TEXT': 'Texto personalizado',

    'CommandSections.ORDER_ID': 'Orden',
    'CommandSections.CONSECUTIVE_ID': 'Folio',
    'CommandSections.ORDER_TYPE': 'Tipo de orden',
    'CommandSections.TABLE_NUMBER': 'Mesa',
    'CommandSections.CREATED_AT': 'Fecha y hora',
    'CommandSections.CUSTOMER_NAME': 'Nombre',
    'CommandSections.SALES_INFO': 'Mostrar información',
    'CommandSections.ITEMS_COST': 'Mostrar costo de productos',
    'CommandSections.PAYMENT_METHOD': 'Método de pago',
    'CommandSections.TOTAL_COST': 'Mostrar costo total de productos',

    'If this input is empty will be copied all the menus from the restaurant, otherwise will copy just the selected menu.': 'Si este campo se deja en blanco serán copiados todos los menús del restaurante, de lo contrario solo se copiará el menú seleccionado.',
    'Switch To Uber Direct': 'Cambiar a Uber Direct',
    'Switch To Pd Drivers': 'Cambiar a Pide Directo',
    'Switch To Rappi Cargo': 'Cambiar a Rappi Cargo',
    'Switch To Uber Daas': 'Cambiar a Uber Daas',
    'Edit order': 'Editar orden',
    'Order picked up': 'Orden recogida',
    'Complete order': 'Completar orden',

    'Menu Settings': 'Configuración del menú',
    'Ids In Menu Enabled': 'Ids en el menú habilitado',
    'Menu Edition Disabled': 'Edición de menú deshabilitada',
    'Uber Eats Menu Should Use Menu Hours Enabled': 'El menú de Uber Eats debe usar las horas del menú habilitadas',
    'Sub-Modifiers Enabled': 'Submodificadores habilitados',
    'Web OrdersPage Settings': 'Configuración de la página de pedidos web',
    'Web OrdersPage (AmbitOne Web menu)': 'Página de pedidos web (AmbitOne Menú web)',
    'Url Subdomain (url friendly restaurant name)': 'Subdominio de URL (nombre de restaurante compatible con URL)',
    'Url Pathname (url friendly restaurant location name)': 'Url Pathname (nombre de ubicación de restaurante compatible con URL)',
    'Subdomain of your ecommerce': 'Subdominio de tu ecommerce',
    'Whats App OrdersPage Number': 'Número de página de pedidos de Whatsapp',
    'PWA Name': 'Nombre de PWA',
    'PWA short_name': 'Nombre corto de PWA',
    'Check icon': 'Icono de verificación',
    'PWA Favicon Image': 'Imagen de Favicon de PWA',
    'PWA App Icon Image': 'Imagen del icono de la aplicación PWA',
    'PWA Splash Icon Image': 'Imagen del icono de presentación de PWA',
    'PWA theme_color': 'Theme color de PWA',
    'PWA background_color': 'Color de fondo de PWA',
    'Facebook pixel account Id': 'ID de cuenta de píxel de Facebook',
    'Alert Sound': 'Sonido de alerta',
    'Alert sound for cancelled orders': 'Sonido de alerta para pedidos cancelados',
    'If enabled orders when cancelled will sound and display a message.': 'Si se activa, las órdenes canceladas sonarán y mostrarán un mensaje.',
    'Show planned orders at arrival preparation time': 'Mostrar pedidos planificados al llegar tiempo de preparación',
    'Allow cash payments with uber daas': 'Permitir pagos en efectivo con uber daas',
    'POS Enabled': 'POS habilitado',
    'POS Pending Orders Enabled': 'Órdenes pendientes de POS habilitadas',
    'POS Use Menu Item colors': 'Utilizar los colores de los productos en el POS',
    'Activate this option if you want to use the color of the product in the POS menu item card': 'Activa esta opción si deseas utilizar el color del producto en la tarjeta del mismo en el POS',
    'Use QPAY Terminal For POS': 'Use la terminal QPAY para POS',
    'Use OCA Terminal For POS': 'Use la terminal OCA para POS',
    'Inventory Enabled': 'Inventario habilitado',
    'Update Inventory For Counts Enabled': 'Actualizar inventario en conteo',
    'User Management Enabled': 'Administración de usuarios habilitada',
    'Enable this option if you want to be able to manage clock in and clock out or if you want to manage several cash registers': 'Habilite esta opción si desea poder gestionar la entrada y salida o si desea administrar varias cajas registradoras',
    'QPAY Terminal Commission': 'Comisión de terminal QPAY',
    'Qpay Terminal Device Id': 'Identificación del dispositivo de la terminal Qpay',
    'Inventory Settings': 'Configuración de inventario',
    'Users Management': 'Administración de usuarios',
    'Show Inventory Negative': 'Mostrar inventario negativo',
    'Sell even with negative inventory': 'Vender aún con inventario negativo',
    'Kiosk Settings': 'Configuración del Kiosko',
    'Kiosk Enabled': 'Kiosko habilitado',
    'Qpay Terminal Device Id For Kiosk': 'ID de dispositivo de terminal Qpay para Kiosko',
    'Kiosk Background Image': 'Imagen de fondo del Kiosko',
    'Kitchen Display Screen Settings': 'Configuración de la pantalla de visualización de la cocina',
    'Kitchen Display Screen Enabled': 'Pantalla de cocina habilitada',
    'Alert Sound for KDS': 'Sonido de alerta en la pantalla de cocina',
    'Select Alert sound for incoming orders when you are on kitchen display screen': 'Seleccione sonido de Alerta para órdenes entrantes que sonará en la pantalla de cocina',
    'Menu Promo Prices': 'Menú Precios Promocionales',
    'Create Separate Promotion Menu': 'Crear menú de promoción separado',
    'Thanks For Ordering Email': 'Gracias por ordenar email',
    'Welcome Email': 'Email de bienvenida',
    'Menu Highlight Email': 'Email destacado del menú',
    'Promo Code Email': 'Código de promoción email',
    'Marketing Enabled': 'Marketing habilitado',
    'Analytics Settings': 'Configuración de análisis',
    'Analytics Enabled': 'Análisis habilitado',
    'Menu Analytics url': 'URL de análisis del menú',
    'Customers Analytics url': 'URL de análisis de clientes',
    'Promo Codes Analytics url': 'Url de análisis de códigos promocionales',
    'Show Private Info': 'Mostrar información privada',
    'Pay With Card on Delivery': 'Paga con tarjeta en entrega',
    'Commission Web Card %': 'Comisión Tarjeta Web %',
    'Commission PD Web Cash %': 'Comisión PD Web Cash',
    'Commission PD App Card %': 'Comisión PD App Tarjeta %',
    'Commission PD App Cash %': 'Comisión PD App Efectivo %',
    'Manual Orders Commission % (Always Cash)': '% Comisión Órdenes Manuales (Siempre Efectivo)',
    'Commission PD Web Card on delivery %': 'Comisión PD Web Card a la entrega %',
    'Commission PD App Card on delivery %': 'Comisión PD Web Card a la entrega %',
    'Return Cancelled Orders To Restaurant Enabled': 'Devolución de pedidos cancelados al restaurante habilitado',
    'Show kiosk User': 'Mostrar usuario de Kiosco',
    'Send Email Minutes After': 'Enviar email minutos después',
    'Default is 30 minutes': 'El valor predeterminado es 30 minutos',
    'Email Template for Thanks for Ordering': 'Plantilla de correo electrónico para Gracias por ordenar',
    'Default Template': 'Plantilla predeterminada',
    'Default is Default Template': 'Por defecto es Plantilla predeterminada',
    'Send Email Days After': 'Enviar email días después',
    'Enable the option if you want a report to be sent by mail about your sales': 'Habilite la opción si desea que se envie  reporte por correo sobre sus ventas',
    'Default is 15 days': 'El valor predeterminado son 15 días',
    'Email template for Menu Highlight': 'Plantilla de correo electrónico para Destacar menú',
    'Activated (Show in App)': 'Activado (Mostrar en la aplicación)',
    'White-label App Orders Enabled': 'Pedidos de White-label App habilitados',
    'Enable Wansoft Integration': 'Habilitar la integración de Wansoft',
    'Wansoft Store Id': 'Id de la tienda de Wansoft',
    'Wansoft Password': 'Contraseña de Wansoft',
    'Track In App': 'Rastrear en la aplicación',
    'Ask To Track In App': 'Solicitar seguimiento en la aplicación',

    'Enable this to let make restaurant web menu available at https://urlsubdomain.pidedirecto.mx/urlpathname. Don´t forget to set Url Subdomain and Url Pathname below.': 'Habilite esto para que el menú web del restaurante esté disponible en https://urlsubdomain.pidedirecto.mx/urlpathname. No olvide configurar el subdominio de URL y el nombre de ruta de URL a continuación.',
    'An url friendly restaurant name (dont use spaces and special characters). If set to e.g. azucar the pidedirecto web will be available at https://azucar.pidedirecto.mx. Use same subdomain to group restaurants within the same restaurant chain.': 'Un nombre de restaurante compatible con URL (no use espacios ni caracteres especiales). Si se establece en, p. azucar la web de pidedirecto estará disponible en https://azucar.pidedirecto.mx. Use el mismo subdominio para agrupar restaurantes dentro de la misma cadena de restaurantes.',
    'An url friendly restaurant location name (dont use spaces and special characters). If set to e.g. morena-martel the pidedirecto web will be available at https://azucar.pidedirecto.mx/morena-martel. Only one name can be used per restaurant subdomain. Saving an already used name within the subdomain fails.': 'Un nombre de ubicación de restaurante compatible con URL (no use espacios ni caracteres especiales). Si se establece en, p. morena-martel la web de pidedirecto estará disponible en https://azucar.pidedirecto.mx/morena-martel. Solo se puede usar un nombre por subdominio de restaurante. Se produce un error al guardar un nombre ya utilizado dentro del subdominio.',
    'This is the whats app number the restaurant will use to receive web orders.': 'Este es el número de whats app que usará el restaurante para recibir pedidos web.',
    'An identifying name for the app. Can be used by app stores, browsers on startup screens and prompts, or by operating systems on app launchers and settings screens. see https://developer.mozilla.org/en-US/docs/Web/Manifest#Creating_a_valid_manifest': 'Un nombre de identificación para la aplicación. Puede ser utilizado por tiendas de aplicaciones, navegadores en pantallas de inicio y avisos, o por sistemas operativos en lanzadores de aplicaciones y pantallas de configuración. ver https://developer.mozilla.org/en-US/docs/Web/Manifest#Creating_a_valid_manifest',
    'A short name, used to display the name to the user where there is limited display space, such as below the icon on a homescreen or app launcher menu. see https://developer.mozilla.org/en-US/docs/Web/Manifest#Creating_a_valid_manifest': 'Un nombre corto, que se usa para mostrar el nombre al usuario cuando el espacio de visualización es limitado, como debajo del icono en una pantalla de inicio o en el menú de inicio de aplicaciones. ver https://developer.mozilla.org/en-US/docs/Web/Manifest#Creating_a_valid_manifest',
    'Make sure image is minimum 512x512 pixels. Enter an URL to an image or drag and drop your image in this field.': 'Asegúrese de que la imagen tenga un mínimo de 512x512 píxeles. Ingrese una URL a una imagen o arrastre y suelte su imagen en este campo.',
    'Header bar color': 'Color de la barra de encabezado',
    'Background color of splash screen': 'Color de fondo de la pantalla de bienvenida',
    'The facebook pixel ID connected to the restaurant': 'El ID del píxel de Facebook conectado al restaurante',
    'If enabled orders will only be visible/notified when it is time to start preparing the order (pickup time - preparation time in minutes)': 'Si los pedidos están habilitados, solo serán visibles/notificados cuando sea el momento de comenzar a preparar el pedido (hora de recogida - tiempo de preparación en minutos)',
    'Enable this to indicate to the driver to return the delivery to the restaurant in case of cancellation': 'Habilite esto para indicarle al conductor que devuelva la entrega al restaurante en caso de cancelación',
    'Enter an URL to an image or drag and drop your image in this field': 'Ingrese una URL a una imagen o arrastre y suelte su imagen en este campo',
    'Key needed by external systems (Restaurant app or POS) to access the Pidedirecto API. Contact a developer to generate this key so that the API is enabled.': 'Clave que necesitan los sistemas externos (App Restaurante o TPV) para acceder a la API de Pidedirecto. Póngase en contacto con un desarrollador para generar esta clave para que la API esté habilitada.',

    'RESTAURANT: Default, most allies would have this value, KITCHEN: For lesteat, the restaurants that are of the KITCHEN type are going to be the locations that deliver the food, PICKUP_STATION: For letseat, the restaurants that are of this type are going to be the locations that receive the food from the KITCHEN-type restaurants.': 'RESTAURANT: Default, la mayoría de los aliados tendrían este valor, KITCHEN: Para lesteat, los restaurantes que sean de tipo KITCHEN van a ser las locaciones que entregan la comida, PICKUP_STATION: Para letseat, los restaurantes que sean de este tipo van a ser las locaciones que reciban la comida de los restaurantes tipo KITCHEN.',
    'Street where the establishment is located': 'Calle donde se localiza el establecimiento',
    'Location of establishment on Google Maps': 'Ubicación de establecimiento en Google Maps',
    'Latitude information obtained by setting the exact location of Google': 'Información de latitud obtenida al establecer la ubicación exacta de Google',
    'Longitude information obtained by setting the exact location of Google': 'Información de longitud obtenida al establecer la ubicación exacta de Google',
    'Access link to the logo of the establishment, make sure the image has a transparent background with a .png extension': 'Link de acceso al logo del establecimiento,procure la imagen tenga fondo tranparente con extensión .png',
    'Upload the logo of your establishment to be able to show it on your printed tickets from the admin app': 'Cargue el logo de su establecimiento para poder mostrarlo en sus tickets impresos desde admin app',
    'Show in the white label app an information message below the restaurant information': 'Muestra en la white label app un mensaje de información debajo de la información del restaurante',
    'Show the link of your website': 'Muestra el link de su sitio web',
    'Main color for ecommerce site theme': 'Color principal para tema del sitio ecommerce',
    'Secondary color for ecommerce site theme': 'Color secundario para el tema del sitio ecommerce',
    'Temporarily disable orders from ecommerce and WLA': 'Deshabilitar temporalmente ordenes desde ecommerce y WLA',
    'Enable orders to eat inside your restaurant': 'Habilite órdenes para comer dentro de su restaurant',
    'Enable orders for home delivery': 'Habilite órdenes para entrega a domicilio',
    'Activate this option if you want to receive orders from the marketplace application to your restaurant': 'Active esta opción si desea recibir órdenes de la aplicación de marketplace a su restaurante',
    'Delete your restaurant information': 'Elimine la información de su restaurante',
    'PERSONALIZED: restaurants own banner image, GENERAL: default banner image for restaurants, RESTAURANT: Place a default banner image for restaurants, The image must be 1024x576 pixels or at least a similar aspect ratio. Enter a URL to an image or drag and drop your image into this field': 'PERSONALIZADO: imagen de banner propia del restaurante, GENERAL: imagen de banner por defecto para restaurantes, RESTAURANTE: Coloca una imagen de banner por defecto para restaurantes, La imagen debe de tener 1024x576 píxeles o al menos una relación de aspecto similar. Ingrese una URL a una imagen o arrastre y suelte su imagen en este campo',
    'Allows you to hide the cart in the ecommerce to have only a digital menu': 'Permite ocultar el carrito en el ecommerce para tener únicamente un menu digital',
    'Determine a minimum purchase amount for ecommerce. If the quantity is not reached, the order cannot be generated': 'Determina un monto mínimo de compra para ecommerce. Si no se llega a la cantidad, no se puede generar la orden',
    'Determine a maximum purchase amount for ecommerce. If the quantity is reached, the order cannot be generated': 'Determina un monto máximo de compra para el ecommerce. Si se llega a la cantidad, no se puede generar la orden',
    'Activate this option if you want to show the identifiers of the products in the menu': 'Activa esta opcion si quieres mostrar los identificadores de los productos en el menú',
    'Disable menu editing': 'Deshabilite la edición del menu',
    'Activate this option if you want Uber eats to use the menu hours and not the restaurants opening hours, this will affect the copy menu section': 'Activa esta opción si quieres que Uber eats use el horario del menú y no el horario de apertura del restaurante , esto afectará la sección de copiar menú',
    'Enable your modifiers for your products': 'Habilite sus modificadores para tus productos',
    'Enable the option to use Menu Manager 2.0': 'Habilite la opción para usar Menu Manager 2.0',
    'Enter the requested ID if you want to associate the current menu with a master menu from another restaurant': 'Coloque el Id solicitado si deseas asociar el menu actual a un menu Master de otro restaurante',
    'Enable this option if you want dropped orders to be printed, includes all platforms': 'Activa esta opcion si desea que se impriman las ordenes que caen, incluye todas las plataformas',
    'Place the estimate of preparation minutes for your orders': 'Coloque el estimado de minutos de preparacion para sus órdenes ',
    'Select Alert sound for incoming orders': 'Seleccione sonido de Alerta para órdenes entrantes',
    'Activate this option if you want your order folio numbers to be consecutive.': 'Activa esta opcion si desea que sus numeros de folio de ordenes sean consecutivos.',
    'It is a mandatory selection type input if the toggle of consecutive orders is set, it allows selecting the range to restart the consecutive folios between DAILY, WEEKLY, MONTHLY, NOT_RESET and at the close of the day': 'Es un input de tipo selección obligatorio si esta predido el toggle de ordenes consecutivo, permite seleccionar el rango para reiniciar los folios consecutivos entre DIARIO, SEMANALMENTE, MENSUAL, NUNCA REINICIAR EL CONTEO y al cierre de día',
    'So that a delivery man can return the order to the restaurant': 'Para que un repartidor pueda regresar el pedido al restaurante',
    'When the orders are pickup the restaurant can mark them as ready': 'Cuando los pedidos son pickup el restaurante puede marcarlas como listas',
    'Activate this option so that the restaurant can accept drivers from its own fleet': 'Active esta la opción para que el restaurante pueda aceptar repartidores de su propia flotilla',
    'Activate this option if you want to show the items of each order to the fleet distributors': 'Active esta opción si desea mostrar los articulos de cada a orden a los repartidores de la flotilla',
    'It is the code that the delivery men must enter in the delivery application when they want to belong to the restaurants fleet': 'Es el código que los repartidores deben ingresar en la aplicación de repartidor cuando desean pertenecer a la flotilla del restaurante',
    'Activate this option to use Uber Direct (Uber dapi) For this to work you must register with an uber direct representative directly before activating the branch and register it in manager with the uber direct store id': 'Active esta opción para usar Uber Direct (Uber dapi) Para que esto funcione debe de darse de alta con un representante de uber direct directamente antes de activar la sucursal y darla de alta en manager con el uber direct store id',
    'Activate the Uber DaaS option, you must first make sure that the Google Street Address and Telephone fields are correct': 'Active la opción Uber DaaS, debe asegurarse previamente  asegurarse que el campo Google Street Address y Teléfono estén correctos',
    'Activate this option if you want cash orders with Uber Daas': 'Activa esta opcion si desea órdenes en efectivo con Uber Daas',
    'Enable the option for Rappi Cargo. Keep in mind that you must make sure that the Google Street Address and Telephone fields are correct.': 'Habilite la opcion para Rappi Cargo tiene en cuenta que debe asegurarse que el campo Google Street Address y Teléfono estén correctos',
    'Enable the option to accept card for delivery of rappi charge as a method of payment': 'Habilite la opcion  para aceptar como método de pago   tarjeta para delivery de rappi cargo',
    'Set the time for the search for delivery people within direct order, after that it is canceled and the order is sent to 3PL (Rappi Cargo, Uber Daas etc..)': 'Establece el tiempo para la búsqueda de repartidores dentro de pidedirecto, despues de eso se cancela y  manda la orden a 3PL (Rappi Cargo, Uber Daas etc..)',
    'Set a price limit for orders that are created manually from the administrator': 'Establecer un límite de precio para los pedidos que se crean manualmente desde el administrador',
    'Define the minimum minutes for dynamic earnings': 'Defina los minutos mínimos para las ganancias dinámicas',
    'Define the Amount of money that will increase the dynamic delivery earnings per minute': 'Defina la cantidad de dinero que aumentará las ganancias de entrega dinámica por minuto',
    'Define the Limit amount for Dynamic earnings': 'Defina la cantidad Límite para las ganancias Dinámicas',
    'Define the personalized deposit reasons, (for example, cash, customer accounts receivable, etc.) these will be used later when making a deposit in boxes, you can remove and add new ones': 'Defina las las razones de deposito personalizadas,(ejemplo efectivo, cuentas por cobrar clientes etc) éstas serán utilizadas posteriormente al hacer un deposito en cajas, Puede quitar y añadir nuevas',
    'Define the personalized reasons for withdrawal (for example, employee loans, cash withdrawal, payment to couriers, etc.) These will be used later when making a cashier withdrawal. You can add new ones or remove them.': 'Defina las razones de retiro personalizadas (por ejemplo prestamos empleados, retiro en efectivo,pago a repartidores etc ) estas  serán utilizadas posteriormente al hacer un retiro en cajas. Puede añadir nuevas o quitarlas.',
    'Define the custom payment methods that can be used in conjunction with card and cash payments as long as they are enabled, for example clip, transfer, vouchers, etc. You can add new ones or remove them': 'Defina  los metodos de pago personalizados que se podran utilizar en conjuto con pagos co tarjeta y efectivo  en pos siempre que esten habilitados por ejemplo clip,transferencia,vales etc.  Puede añadir nuevas o quitarlas',
    'You enable this option if you need to be sent an email when the inventory reaches the alert limit, remember to have established the mentioned limit': 'Habilitas ésta opción si necesita que se le envie un email cuando el inventario llega al limite de alerta , recuerde tener establecido el limite mencionado',
    'Hide company data such as telephone address etc. on the printed ticket': 'Ocultar datos de la empresa como teléfono dirección etc. en el ticket impreso',
    'Enable the POS': 'Habilite el POS',
    'You enable this option if you want open orders in POS, it allows you to save orders without payment, it also allows you to add items to orders later.': 'Habilitas esta opción si desea ordenes abiertas en POS, permite guardar ordenes sin pago ,tambien permite agregar posteriormente items a las órdenes.',
    'Activate this option if you want to create or edit areas and tables in the Orders to eat here section.': 'Activa esta opción si desea crear o editar zonas y mesas en el apartado pedidos para comer aquí.',
    'Enable the display of the selection of tables in the order creation process': 'Habilitar la visualizacion de la seleccion de mesas en el proceso de creación de pedido',
    'Activate the option if you want the table selection to be required, show the table selection when creating the order': 'Active la opción si desea que sea requerida la seleccion de mesas, muestra la seleccion de mesa al crear el pedido',
    'Activate the option if you want the number of diners for the table to be required': 'Active la opción  is desea que sea requerido el numero de comensales para la mesa ',
    'Enable the option if you want the system to prompt for a name for takeout orders created at the POS': 'Habilite la opción si desea que el sistema solicite un nombre para órdenes para llevar creadas en el  POS',
    'Activate the option if you want to count only the cash in boxes, without taking card payments into account': 'Active  la opción si quiere  contabilizar unicamente el efectivo en cajas, sin tomar en cuenta pagos con tarjeta',
    'Enable the option to allow paying with the qpay terminal': 'Habilite la opción para para permitir pagar con la terminal qpay',
    'Enable the option if you want to request a POS password to reprint an order that has already been completed': 'Habilite la opción si desea  solicitar contraseña del POS para reimprimir una orden que ya ha sido completada',
    'Allows you to view inventory': 'Permite visualizar  el inventario',
    'You enable this option if you want to be able to manage roles and users for the POS': 'Habilite esta opción si desea poder administrar roles y usuarios para el POS',
    'Define the commission when paying with a Qpay terminal': 'Defina la comisión al pagar con terminal Qpay',
    'Put the ID of the QPAY terminal to be able to call the order collection instructions': 'Coloque el ID de la terminal QPAY para poder llamar las instrucciones de cobro de orden',
    'Define the password to perform actions on the POS': 'Defina   la contraseña para realizar acciones en el POS',
    'Enable to view inventory': 'Habilite poder visualizar el inventario ',
    'Enable to view buy Orders': 'Habilite poder visualizar compras',
    'Enable to update the inventory on counts': 'Habilite actualizar el inventario en los conteos',
    'Buy Orders Enabled': 'Compras habilitadas',
    'Check if you want to discount inventory even with negative values': 'Active si desea descontar del inventario incluso con valores negativos',
    'Create your categories for inventory supplies (frozen drinks, perishables, etc.)': 'Cree sus  categorias para insumos del inventario(bebidas congelados, perecederos, etc.)',
    'Enable the kiosk for self service': 'Habilite el kiosko para self service',
    'Put the qpay terminal ID to call the copper instructions in the KIOSK': 'Coloque  el ID de la terminal qpay para el llamar las instrucciones de cobre en el KIOSKO',
    'View hidden kiosk user credentials': 'Vea las credenciales ocultas del usuario de kiosco',
    'Activate the option if you want to enable the KDS kitchen monitor (instead of or in addition to the printing of orders)': 'Active la opcion si desea habilitar el monitor de cocina KDS (en lugar o adicional a la impresión de comandas)',
    'Activate this option if you want to separate the items with promotion in a submenu': 'Activa esta opcion si desea separar los items con promoción en un submenu',
    'Enable a message on the Ecommerce page to indicate that the establishment will open soon': 'Habilite un mensaje en la pagina de Ecommerce para indicar que el establecimineto abrirá pronto',
    'Activate this option if you want an email to be sent to the user thanking you for the purchase': 'Activa esta opción si desea que se envie un correo al usuario de agradecimiento por la compra',
    'Activate this option if you want a welcome email to be sent to the user': 'Activa esta opción si desea que se envie un correo al usuario de bienvenida',
    'You enable this option if you require the template to send email for promotions.': 'Habilitas esta opción si requiere la plantilla para enviar email para las promociones.',
    'Activate the option for promotional codes in the restaurant cart': 'Active la opción para los codigos promocionales  en el carrito del restaurante',
    'Enable the new marketing view to display customer information, create promotional codes and send notifications to your customers': 'Habilite  la nueva  vista de marketing para mostrar información de sus clientes, crear codigos promocionales  y enviarles notificaciones a sus clientes',
    'Activate this option to obtain an advanced reports section': 'Active está opción para obtener una sección de reportes avanzados',
    'Request PD url to place it in this field and be able to view menu reports from the manager.': 'Solicite a PD  url para colocarlo en este  campo y pueda visualizar reportes de menu  desde el manager.',
    'Request PD url to place it in this field and be able to view customer analysis reports from the manager.': 'Solicite a PD  url para colocarlo en este  campo y pueda visualizar reportes de analisis de clientes  desde el manager.',
    'Request the PD url to place it in this field and be able to view promotion reports from the manager.': 'Solicite a PD  url para colocarlo en este  campo y pueda visualizar reportes de las promociones desde el manager.',
    'Activate cash payments': 'Active los pagos en efectivo',
    'Activate cash payments for Ecommerce for the option Eat here': 'Active los pagos en efectivo para el Ecommerce para la opcion Comer aqui',
    'Activate card payments': 'Active  los pagos con tarjeta',
    'Activate the option of payment links': 'Active la opción de  links de pago ',
    'Activate the field to take the terminal in the delivery of the orders (For shipping function)': 'Active  el campo para llevar la terminal en la entrega del pedidos(Para funcion envios)',
    'Activate the field to generate the invoices when the payment is done': 'Activa este campo para generar facturas automaticamente cuando el pago sea realizado',
    'Select the channels in which the CASH payment method is received, for example, Direct Point of Sale, Request Direct Kiosk, etc. (if you do not want to select any, they will be accepted in all channels by default)': ' Seleccione los canales en los que es recibido el método de pago EFECTIVO por ejemplo Directo Punto de Venta, Pide Directo Kiosko etc. (si usted no desea seleccionar  alguno se aceptaran en todos los canales de forma predeterminada)',
    'Select the channels in which the CARD payment method is received, for example Direct Point of Sale, Order Manual Lets eat etc., (if none is selected, they will be accepted in all channels by default)': 'Seleccione  los canales en los que es recibido el método de pago TARJETA por ejemplo Directo Punto de venta, Order Manual Lets eat etc., (si no se selecciona alguno se aceptaran en todos los canales de forma predeterminada)',
    'Select the channels in which the PAYMENT LINK payment method is received (if you do not select any, they will be accepted in all channels by default)': 'Seleccione  los canales en los que es recibido el método de pago LINK DE PAGO (si no selecciona alguno se aceptaran en todos los canales de manera predeterminada)',
    'Write the telephone number of the establishment or branch': 'Escriba el teléfono del establecimiento o sucursal',
    'Write the fiscal address of your company.': 'Escriba la dirección Fiscal de su Empresa.',
    'Write the address where your establishment is located.': 'Escriba la dirección donde se ubica su establecimiento.',
    'Write your contact email': 'Escriba su Email de contacto',
    'Write the name of the bank where the deposits will be made.': 'Escriba el nombre del  banco donde se realizará los depósitos.',
    'Enter the bank account number.': 'Escriba el número  cuenta de banco.',
    'Write the 18-digit interbank code CLABE.': 'Escriba la clave interbancaria de 18 digitos CLABE.',
    'Clabe used for subscriber  of Point of Sale users': 'Clabe utilizada para el pago de subscripciones de usuarios del Punto de Venta',
    'Write the Federal Taxpayer Registration Number of the physical or moral person (RFC), it will be used for billing and/or deposits. Notes: For Uruguay write the RUC': 'Escriba el Número de registro federal de contribuyentes de la persona física o moral(RFC), se utilizará para facturación y/o depósitos. Notas: Para Uruguay escriba el  RUC ',
    'Enter the company name of the physical or legal person that will be used for billing and/or deposits.': 'Escriba la Razón social de la persona física o moral que se utilizará para facturación y/o depósitos.',
    'Write under which tax regime of the physical or moral person your company is located.': 'Escriba bajo que Régimen fiscal de la persona física o moral se encuentra su empresa.',
    'Write Local Contact Name': 'Escriba Nombre del contacto del local',
    'Type Email that will be used to make and send the billing.': 'Escriba Correo electrónico que se utilizará para realizar y enviar la facturación.',
    'Add the commission for card payments in ecommerce.': 'Añada la comisión para pagos con tarjeta en el ecommerce.',
    'Add the commission for cash payments in ecommerce': 'Añada  la comisión para pagos en efectivo en ecommerce',
    'Add the commission for card payments in the app': 'Añada  la comisión para pagos con tarjeta en la app',
    'Add the commission for cash payments in the app': 'Añada la comisión para pagos en efectivo en la app',
    'Add commission for manual orders': 'Añada la comisión para las ordenes manuales',
    'Define the commissions for the uber Daas billing restaurant': 'Defina las comisiones para el restaurante billing de uber Daas',
    'Add the business key provided by your uruware provider if you require billing': 'Agregue la key del negocio proporcionada por su proveedor uruware si  requiere facturar',
    'Add the terminal key provided by your uruware provider if you require billing': 'Agregue la key de la terminal  proporcionada por su proveedor uruware si  requiere facturar ',
    'Add the web service code provided by your uruware provider if you require billing': 'Agregue el código del web service  proporcionada por su proveedor uruware si  requiere facturar ',
    'Add the address code provided by your uruware provider if you require billing': 'Agregue el codigo de dirección  proporcionada por su proveedor uruware si  requiere facturar ',
    'Activate the restaurant in the marketplace': 'Activar el restaurante en el marketplace',
    'With this option you enable the Orders in the WLA': 'Con ésta opción habilita las Órdenes en la WLA',
    'Enable the option if you want the user to be awarded cashback points after they make a purchase.': 'Habilite la opción si desea que se le le otorgan puntos cashback al usuario  después de que realice una compra.',
    'Define the percentage of cashback points for orders placed in the app': 'Defina el porcentaje de puntos de cashback para órdenes realizadas en la app',
    'Activate this option for integration with Wansoft': 'Active esta opción para la integración con Wansoft',
    'Please provide the Store ID for Wansoft setup successfully.': 'Proporcione el  Store ID para realizar la configuración de Wansoft correctamente.',
    'Provide the password to configure Wansoft correctly.': 'Proporcione el  password para realizar la configuración de Wansoft correctamente.',
    'key required by external systems (Restaurant App or POS) to access Pidedirecto API. Contact a developer to generate this key for the API to be enabled.': 'key requerida por los sistemas externos (App de Restaurant o POS) para  acceder a Pidedirecto API. Póngase en contacto con un desarrollador para generar esta clave para que la API esté habilitada.',
    'Track the delivery man (letseat)': 'Realiza el tracking del repartidor  (letseat)',

    'Return @amount': 'Devolver @amount',
    'Select any item': 'Selecciona algún producto para devolver',

    'Printer type': 'Tipo de impresora',
    'IP Address': 'Dirección IP',
    'Printer content': 'Contenido de impresión',
    'Download Drivers': 'Descargar drivers',
    'How are you going to connect your printer?': '¿De qué manera conectarás la impresora?',
    'Write your printer name': 'Nombre de la impresora',
    'Example: Cocina caliente': 'Ejemplo: Cocina caliente',
    'Please make sure that all the information provided is correct': 'Por favor, asegúrate de que la información es correcta',
    'Printer name': 'Nombre de la impresora',
    'Select the channels in which your printer will work on': 'Selecciona los canales en los que funcionará tu impresora',
    'PrinterTypes.WIFI': 'WiFi/Ethernet',
    'PrinterTypes.BLUETOOTH': 'Bluetooth',
    'PrinterTypes.USB': 'USB',
    'PrinterTypes.PAYMENT_TERMINAL_PRINTER': 'Impresora en terminal',
    'PrinterContents.Both': 'Ticket & Comanda',
    "The serial number of a printer can typically be found on the back or bottom panel, side panels, control panel, user manual or packaging, or through the printer's online account or management console.": 'El número de serie de una impresora generalmente se puede encontrar en el panel posterior o inferior, los paneles laterales, el panel de control, el manual del usuario o el empaque, o a través de la cuenta en línea o la consola de administración de la impresora.',
    "Typically, the port can be found in the printer's network settings or in the printer's documentation.": 'Por lo general, el puerto se puede encontrar en la configuración de red de la impresora o en la documentación de la impresora.',
    "Typically, the MAC Address can be found in the printer's network settings or in the printer's documentation": 'Por lo general, la dirección MAC se puede encontrar en la configuración de red de la impresora o en la documentación de la impresora.',
    'hit Enter ↩': 'presiona Enter ↩️',
    'Check the box if this is a tablet printer': 'Marque la casilla si se trata de una impresora de tableta',
    'Disable printer in this device': 'Deshabilitar impresora en este dispositivo',
    'New orders page enabled': 'Nueva página de ordenes habilitada',
    'Clone': 'Clonar',
    'Cloning': 'Clonando',

    'The selected date cannot be greater than @limitDate': 'La fecha seleccionada no puede ser mayor a @limitDate',
    'This enables the new orders page section.': 'Habilita la nueva página de ordenes.',
    'There is no data to show': 'No hay información para mostrar',

    'PrinterPaperSizes.80_MM': '80 mm',
    'PrinterPaperSizes.58_MM': '58 mm',
    'Campaigns': 'Campañas',
    'Customer information': 'Información de clientes',
    'This is the name of the product': 'Este es el nombre del producto',
    'New order': 'Nueva orden',
    'Change view': 'Cambiar vista',
    'Take away': 'Para llevar',
    'Ongoing (@orders)': 'En curso (@orders)',
    'Completed (@orders)': 'Completados (@orders)',
    'Rejected (@orders)': 'Rechazados (@orders)',
    'Cancelled (@orders)': 'Cancelados (@orders)',
    'Returned (@orders)': 'Devueltos (@orders)',
    'Accepted (@orders)': 'Aceptados (@orders)',
    'Opened (@orders)': 'Abiertas (@orders)',
    'Ready for pickup (@orders)': 'Listos para recoger (@orders)',
    'Time': 'Tiempo',
    'Completed order': 'Orden completada',
    'Rejected order': 'Orden rechazada',
    'Returned order': 'Orden devuelta',
    'Cancelled order': 'Orden cancelada',
    'Driver assigned (@orders)': 'Repartidor asignado (@orders)',
    'TakeAwayOrderStatuses.ACCEPTED': 'Aceptado',
    'TakeAwayOrderStatuses.READY_FOR_PICK_UP': 'Listo para recoger',
    'TakeAwayOrderStatuses.COMPLETED': 'Completado',
    'The range date must be maximum one month': 'El rango de fechas debe ser máximo de un mes',
    'We are loading your orders, this could take a couple of minutes': 'Estamos cargando tus órdenes, esto podría tomar un par de minutos.',
    'Usually the IP of the printer can be found by accessing the menu or by connecting it to a computer and accessing the settings.': 'Por lo general, la IP de la impresora puede encontrarse accediendo al menú o conectándola a una computadora y accediendo a la configuración.',
    'The order was successfully updated': 'La órden fue actualizada exitosamente',
    'DriverManualEarningReasons.BACK_TO_RESTAURANT': 'Vuelta Sucursal',
    'DriverManualEarningReasons.KM_ADJUSTMENT': 'Ajuste KM',
    'DriverManualEarningReasons.REFUND': 'Rembolso',
    'DriverManualEarningReasons.RETURN_RESTAURANT': 'Regreso a Sucursal',
    'DriverManualEarningReasons.UNPAID_DELIVERY': 'Envío no Pagado',
    'DriverManualEarningReasons.WAIT_FOR_RESTAURANT': 'Espera por Sucursal',
    'DriverManualEarningReasons.DAILY_BONUS': 'Bono Diario',
    'DriverManualEarningReasons.QA_NOT_APPLIED': 'QA no Aplicado',
    'DriverManualEarningReasons.NOT_APPLIED_DYNAMICS': 'Dinámica no Aplicada',
    'DriverManualEarningReasons.ACCOUNT_UNBLOCKING': 'Desbloqueo de Cuenta',
    'DriverManualEarningReasons.EXCESSIVE_WEIGHT': 'Peso excesivo',
    'DriverManualEarningReasons.FIXED': 'Fijo',
    'DriverManualEarningReasons.NON_REFLECTED_BONUS': 'Bono no reflejado',
    'DriverManualEarningReasons.NON_COMPLIANCE_IN_BONUS': 'Inclumplimiento de Bono',
    'DriverManualEarningReasons.DRIVER_REFERRAL': 'Repartidor Referido',
    'DriverManualEarningReasons.OTHER': 'Otro',
    'Select the Reason': 'Selecciona la Razón',

    'Could take some time to the actions will be reflected on integrations.': 'Podría tomar algún tiempo para que las acciones se reflejen en las integraciones.',

    'SupplyValueTypes.FIXED': 'Fijo',
    'SupplyValueTypes.AVERAGE': 'Promedio',
    'SupplyValueTypes.LAST': 'Último',
    'Supply Value Type': 'Tipo de valor de insumo',
    'Average Price': 'Precio promedio',
    'Quantity Before Transaction': 'Cantidad antes de la transacción',
    'Last Purchase Cost': 'Costo de la última compra',
    'Average Weighted Cost': 'Costo promedio ponderado',
    'Purchase Units': 'Unidades de compra',
    'Purchase Value': 'Valor de compra',
    'Adjustment In Units': 'Ajuste en entrada insumos',
    'Adjustment In Value': 'Ajuste en valor de entrada',
    'Adjustment Out Units': 'Ajuste en salida insumos',
    'Adjustment Out Value': 'Ajuste en valor de salida',
    'Entries per adjustment': 'Entradas por ajuste',
    'Outputs per adjustment': 'Salidas por ajuste',
    'Sales Units': 'Unidades de venta',
    'Wastes': 'Mermas',
    'Sales Value': 'Valor de ventas',
    'Waste Accident Units': 'Unidades de Accidentes de Residuos',
    'Waste Accident Value': 'Valor de accidente de residuos',
    'Transference Units': 'Unidades de Transferencia',
    'Transference Value': 'Valor de transferencia',
    'Adjustment (-)': 'Ajuste (-)',
    'Adjustment (+)': 'Ajuste (+)',
    'Quantity After Transaction': 'Cantidad después de la transacción',
    'Delivery drivers configuration': 'Configuración de repartidores',
    'FAQs App Drivers': 'FAQs App Repartidores',
    'Driver Referrals': 'Repartidores referidos',
    'Drivers': 'Repartidores',
    'Driver Bonus Offers': 'Bonos de Repartidores',
    'Ongoing Driver Bonuses': 'Bonos de Repartidores en curso',
    'Driver Billing': 'Driver Billing',
    'Driver Bonus Billing': 'DriverBonus Billing',

    'News Banner in App': 'Banner noticias en App',
    'Edit Banner': 'Editar Banner',
    'Personalized design': 'Diseño personalizado',
    'Text': 'Texto',

    'Add Discount Comment': 'Agregar comentario de descuento ',
    'Remove Discount Comment': 'Remover comentario de descuento ',
    'Discount Comment': 'Comentario de descuento',
    'Other': 'Otro',
    'Custom Discount Comments': 'Comentarios personalizados de descuento',
    'Are you sure you want to remove the custom comment "@comment?"': '¿Estás seguro que deseas remover el comentario "@comment?"',

    'CustomDiscountComments.EMPLOYEE_DISCOUNT': 'Descuento de empleado',
    'CustomDiscountComments.PARTNER_DISCOUNT': 'Descuento de socio',
    'CustomDiscountComments.COURTESY': 'Cortesía',
    'CustomDiscountComments.PROMOTION': 'Promoción',

    'Upload Inventory': 'Agregar a inventario',
    'Total Price': 'Precio total',

    'Prints': 'Impresiones',
    'Customize prints': 'Personalizar impresiones',
    'PosBusinessDaySections.RESTAURANT_NAME': 'Nombre',
    'PosBusinessDaySections.BUSINESS_NAME': 'Nombre fiscal',
    'PosBusinessDaySections.RFC': 'RFC',
    'PosBusinessDaySections.RESTAURANT_STREET': 'Dirección',
    'PosBusinessDaySections.DATE_OF_PRINT': 'Fecha de impresión',
    'PosBusinessDaySections.OPENING_DATE': 'Fecha de apertura',
    'PosBusinessDaySections.CLOSING_DATE': 'Fecha de cierre',
    'PosBusinessDaySections.SALES': 'Ventas',
    'PosBusinessDaySections.TIP': 'Propinas',
    'PosBusinessDaySections.ORDERS': 'Órdenes',
    'PosBusinessDaySections.DINNERS': 'Comensales',
    'PosBusinessDaySections.CASH_REGISTER_MOVEMENTS': 'Mostrar información',
    'PosBusinessDaySections.SALES_BY_CHANNEL': 'Venta por canal',
    'PosBusinessDaySections.SALES_BY_PAYMENT': 'Venta por método de pago',
    'PosBusinessDaySections.SALES_BY_ORDER_TYPE': 'Venta por tipo de órden',
    'PosBusinessDaySections.TOP_SALES_PRODUCT': 'Ventas por producto',
    'PosBusinessDaySections.TOP_SALES_CATEGORY': 'Top ventas por categoria',
    'PosBusinessDaySections.TOP_SALES_BY_HOUR': 'Top ventas por hora',
    'PosBusinessDaySections.PAYMENTS_RECEIVED': 'Pagos recibidos',
    'PosBusinessDaySections.CASH_REGISTER_COUNTING': 'Mostrar información',
    'PosBusinessDaySections.PROMO_CODES_SUMMARY': 'Promociones',
    'PosBusinessDaySections.SALES_BY_MANAGER_USER': 'Venta por usuario',
    'PosBusinessDaySections.CANCELLED_ORDERS': 'Cancelaciones',
    'PosBusinessDaySections.CARD_BREAKDOWN': 'Desglose de órdenes de tarjeta',
    'PosBusinessDaySections.INTEGRATION_BREAKDOWN': 'Desglose de órdenes de integración',
    'PosBusinessDaySections.PAYMENTS_BREAKDOWN': 'Desglose de pagos',

    'Cash Register Movements Information': 'Información de los movimientos de la caja',
    'Sales Detail Information': 'Información del detalle de ventas',
    'Restaurant Information': 'Información del Restaurante',
    'Cash Register Counting Information': 'Información del conteo de cajas',
    'End of day': 'Cierre de día',
    'Customize Pos Business Day Report Ticket': 'Personaliza el cierre de día',

    'Create new customer': 'Crear nuevo comensal',
    'Promo code not applicable for the customer': 'PromoCode no aplicable para el comensal',

    'You have ongoing orders that are not inside the date range': 'Tienes ordenes en curso que no están dentro del rango de fechas',
    'You have ongoing orders that are not inside the date range since @date': 'Tienes ordenes en curso que no están dentro del rango de fechas desde @date',
    'Ongoing': 'En curso',
    'Completed': 'Completado',
    'Driver assigned': 'Repartidor asignado',
    'Looking for driver (@orders)': 'Asignando un repartidor (@orders)',
    'Ready for pickup': 'Listo para recoger',
    'New (@orders)': 'Nuevos (@orders)',

    'This menu is managed on the branch (@branchName)': 'Este menú se administra desde la sucursal (@branchName)',

    'Supply History Report': 'Reporte Historico de Insumos',
    'Supplies History Select': 'Seleccionar Historico de Insumo',
    'Supplies History': 'Historial de Insumos',
    'Inventory Consumption': 'Consumo de inventario',
    'Inventory Adjustments': 'Ajustes de inventario',
    'Existence': 'Existencia',
    'Consumption': 'Consumo',
    'Purchase cost': 'Precio de Compra',
    'Differential': 'Diferencial',
    'No data': 'Sin datos',

    'Is not possible to pay with terminal in Uruguay or Argentina': 'No es posible pagar con la terminal en Uruguay o Argentina',

    'New Banner': 'Nuevo Banner',
    'Inventory Consumption Report': 'Reporte de consumo de inventario',
    'Percentage Cost': 'Costo porcentual',
    'Total Cost': 'Costo total',
    'Cost Percentage': 'Porcentaje de costo',
    'Do not print on ticket delivery cost when is paid by restaurant': 'No imprimir en el ticket el costo de envío cuando es pagado por el restaurante',
    'When enabled, delivery cost is not printed if it has been paid by the restaurant': 'Cuando esta activo, los gastos de envío no se imprimen si han sido pagados por el restaurante',
    'Payment pending (@orders)': 'Pendiente de pago (@orders)',
    'Last Purchase Date': 'Fecha de última compra',
    'Supply Price': 'Precio de compra',
    'Suppliers Report': 'Reporte de proveedores',
    'Purchased amount': 'Cantidad comprada',
    'Remember to clock in and out every day': 'Recuerda marcar tu entrada y salida todos los días',
    'Return': 'Regresar',
    'Clock in': 'Marcar entrada',
    'Clock out': 'Marcar salida',
    'Clock in time: @clockedInAt': 'Hora de entrada: @clockedInAt',
    'Clock in time': 'Hora de entrada',
    'to clock out': 'marcar la salida',
    'to clock in': 'marcar la entrada',
    'Type your pin to clock in': 'Escriba su código para marcar tu entrada',
    'Type your pin to clock out': 'Escriba su código para marcar tu salida',
    'You have already registered your entry': 'Ya has registrado tu entrada',

    'Create printer': 'Crear impresora',
    'Write the IP Address of your printer': 'Escribe la dirección IP de tu impresora',
    'Write the MAC Address': 'Escribe la dirección MAC de tu impresora',
    'Write the port (leave empty if not known)': 'Escribe el puerto de tu impresora (dejar vacío si no se sabe)',
    'Printer size': 'Tamaño de la impresora',
    'Is your tablet 58mm?': '¿Tu tablet imprime en 58mm?',
    'Remove Printer': 'Eliminar impresora',
    'You are about to remove the printer @printerName, are you sure you want to continue?': 'Estás apunto de eliminar la impresora @printerName, ¿estás seguro de que quieres continuar?',
    'How to read this report?': '¿Cómo leer este reporte?',

    'Activate this option if you require the Client to indicate with what amount he will pay in cash': 'Activa esta opcion si requires que el Cliente indique con que monto va a pagar en efectivo',

    'Event type': 'Tipo de evento',
    'Employee events': 'Eventos del empleado',
    'Omit supply in orders': 'Omitir insumo en las órdenes',
    'Omitted in order types': 'Omitido en las órdenes de tipo',

    'Make sure image is 1024x576 pixels or at least similar aspect ratio. Enter an URL to an image or drag and drop your image in this field': 'Asegúrese de que la imagen tiene 1024x576 píxeles o al menos una relación de aspecto similar. Introduzca una URL a una imagen o arrastre y suelte su imagen en este campo',
    'Clock in at': 'Entrada',
    'Clock out at': 'Salida',
    'Worked hours': 'Horas trabajadas',
    'Attended tables': 'Mesas atendidas',
    'Open eat here orders': 'Cuentas abiertas',
    'Conciliate tips': 'Conciliar propinas',
    'Transfer': 'Transferir',
    'You have not open eat here orders': 'No tienes cuentas abiertas',
    'Make sure that the tips in the system match the cash and credit card with the cash and card': 'Asegúrese de que las propinas del sistema coinciden con el efectivo y la tarjeta de crédito',
    'Card on Delivery': 'Tarjeta en Entrega',

    '@day at @hours': '@day a las @hours',

    'You have employees who have not clocked out': 'Tienes empleados que no han marcado su salida',
    'All of your employees have clocked out': 'Todos tus empleados han marcado su salida',
    'Confirm their clock out time and continue with your closing day': 'Confirma su hora de salida y continúa con tu cierre de día',

    'Is it sold by weight?': '¿Es vendido por peso?',
    'Is it also a supply?': '¿También es un insumo?',
    'Minimum quantity to sell': 'Cantidad mínima de venta',

    'Unit of sale': 'Unidad de venta',
    'Check this box if you want to sell the product by weigh': 'Marca esta casilla sí deseas vender el producto por peso',

    'UnitsOfMeasurement.KILOGRAM': 'kg',
    'UnitsOfMeasurement.GRAM': 'gr',
    'UnitsOfMeasurement.LITRE': 'lt',
    'UnitsOfMeasurement.MILLILITER': 'ml',
    'UnitsOfMeasurement.BOX': 'cja',
    'UnitsOfMeasurement.BAG': 'bls',
    'UnitsOfMeasurement.PIECE': 'pz',
    'UnitsOfMeasurement.PACKAGE': 'paq',
    'UnitsOfMeasurement.STICK': 'brra',
    'UnitsOfMeasurement.ROLL': 'roll',
    'UnitsOfMeasurement.CAN': 'lta',
    'UnitsOfMeasurement.SLICE': 'reb',

    'Signature': 'Firma',

    'Quantity': 'Cantidad',
    'Cost': 'Costo',

    'Payment method changed: it will not be included in your weekly deposit': 'Método de pago cambiado: no irá incluido en tu depósito semanal',
    'Total Bought': 'Total comprado',

    'Request password to make closing day': 'Solicitar contraseña para realizar cierre de día',
    'Activate this option if you require the POS password to be requested to closing day': 'Active ésta opción si requiere que se solicite la contraseña del POS para realizar el cierre del día',

    'Increase the size of buttons and text at POS': 'Incrementar el tamaño de los botones y textos en el POS',
    'Enable option if you want increase the size of buttons and text at POS': 'Active está opción si desea incrementar el tamaño de los botones y textos en el POS para este dispositvo',

    'Resume payments': 'Resumen de pagos',
    'This field is only used for lets eat menus, writing something here if it is not for lets eat will hide the menu.': 'Este campo es usado solo para menús de lets eat, si se escribe algo aquí y el menú no es para lets eat ocultará el menú',
    'Unlinked': 'Desvinculado',
    'Link menus': 'Vincular menús',
    'Links the menus to the restaurant': 'Vincula los menús al restaurante',
    'Can not be a modifier group without modifiers': 'No puede haber un grupo de modificadores sin modificadores',
    'You have modifier groups without modifiers.': 'Tienes grupos de modificadores sin modificadores',

    'By continuing, your accepting the ': 'Al continuar, estás aceptando las ',
    ' and the ': ' y los ',
    'Privacy Policy': 'Políticas de Privacidad',
    'Terms and Conditions of Use of the “PideDirecto” Platform': 'Términos Y Condiciones De Uso De Plataforma “PideDirecto”',
    'Notice of Privacy (Privacy Policy) - Users': 'Aviso de Privacidad (Política de privacidad) - Usuarios',
    'Go back to Reports': 'Regresar a Reportes',

    'Enable clock in and clock out': 'Habilitar el registro de entrada y salida',
    'Clock in message': 'Mensaje de entrada',
    'Clock out message': 'Mensaje de salida',
    'Hours considering the current time': 'Horas considerando el tiempo actual',
    'Production History': 'Historial de producción',
    'Productions': 'Producciones',
    'Rows per page:': 'Filas por página:',
    'of': 'de',
    'Sorry, no matching records found': 'Lo sentimos, no se encontraron registros coincidentes',
    'Sort by': 'Ordenar por',
    'This is the unit that will be used to sell the supply': 'Esta es la unidad que se utilizará para vender el insumo',
    'Production Created Successfully': 'Producción creada exitosamente',
    'Quantity/Unit': 'Cantidad/Unidad',
    'Production': 'Producción',
    'Supplies Created': 'Insumos creados',
    'Produce': 'Producir',
    'Quantity/Units': 'Cantidad/Unidades',

    'Product Limit': 'Limite de producto',
    'Product limit per Menu': 'Limite de producto por menú',
    'Product limit per Category': 'Limite de producto por categoria',

    'Free modifiers quantity': 'Cantidad de modificadores gratuitos',
    'Phone number is required when external delivery is off': 'El número de teléfono es necesario cuando la entrega externa está apagada',

    'The orders that you manually changed from payment to card will not be deposited': 'Las órdenes donde cambiaste el método de pago a tarjeta manualmente no serán depositadas.',
    'Adjustments': 'Ajustes',
    'Print modifier group name on command': 'Imprimir nombre del grupo de modificadores en comanda',
    'Payment Links Without Order': 'Links de pago sin Ordenes',

    'FilterModes.PER_PRODUCT': 'Por producto',
    'FilterModes.PER_USER': 'Por usuario',

    'More filters': 'Más filtros',
    'Search by user name...': 'Buscar por nombre de usuario...',
    'User Types': 'Tipos de usuario',
    'Order IDs': 'IDs de las órdenes',
    'Customer Phone Number Required': 'Número de teléfono del cliente requerido',
    'Make customer phone number required when external delivery is on': 'El número de teléfono del cliente será obligatorio cuando la entrega externa esté activada',

    'By logging in you accept our': 'Al iniciar sesión aceptas nuestros',
    'Join tables': 'Juntar mesas',
    'Available': 'Disponible',
    'Busy': 'Ocupada',
    'Tables to join': 'Mesas a juntar',
    'This order has discounts, is not possible join it': 'Esta orden tiene descuentos, no es posible unirla',
    'Select tables you want to join with table @tableNumber': 'Selecciona las mesas que deseas juntar con la mesa @tableNumber',

    'You are about to delete the @menuCategoryName category, are you sure you want to continue?': 'Está a punto de eliminar la categoría @menuCategoryName, ¿está seguro de que desea continuar?',
    'Select an option': 'Selecciona una opción',
    'Selections (@values)': 'Selecciones (@values)',
    'Change name': 'Cambiar nombre',
    'Link device': 'Enlazar dispositivo',
    'Block device': 'Bloquear dispositivo',

    'Add email': 'Agregar email',
    'Statistics report emails recipients': 'Correos de destino para reportes de estadísticas',
    'Driver Incentive Type': 'Tipo de incentivo para repartidores',
    'Select the type of incentive for the driver': 'Seleccione el tipo de incentivo para el repartidor',
    'CHALLENGE': 'Desafío',
    'COMMERCIAL': 'Comercial',
    'SUPPLY': 'Supply',
    'FIXED': 'Fijo',
    'SUPPLIES': 'Insumos',
    'WEATHER': 'Clima',
    'FESTIVE': 'Festivo',
    'DriverIncentiveTypes.CHALLENGE': 'Desafío',
    'DriverIncentiveTypes.COMMERCIAL': 'Comercial',
    'DriverIncentiveTypes.SUPPLY': 'Supply',
    'DriverIncentiveTypes.FIXED': 'Fijo',
    'DriverIncentiveTypes.SUPPLIES': 'Insumos',
    'DriverIncentiveTypes.WEATHER': 'Clima',
    'DriverIncentiveTypes.FESTIVE': 'Festivo',
    'Create terminal': 'Crear terminal',
    'Serial number': 'Número de serie',

    'Create new group': 'Crear nuevo grupo',
    'Group name': 'Nombre del grupo',
    'Select devices from group': 'Selecciona los dispositivos del grupo',
    'Create group': 'Crear grupo',
    'Block device @deviceName': 'Bloquear dispositivo @deviceName',
    'Are you sure you want to continue?': '¿Estás seguro de querer continuar?',
    'Yes, block': 'Sí, bloquear',
    'Cannot add tip when payment is missing': 'No se puede agregar propina cuando falta el pago',
    'Product': 'Producto',
    'Sold Items': 'Productos vendidos',

    'Modify your preparation time': 'Modifica tu tiempo de preparación',
    'CostTypes.COST_BY_AVERAGE_WEIGHTED_COST': 'Costo Promedio',
    'CostTypes.COST_BY_LAST_PURCHASE_COST': 'Costo por última compra',
    'CostTypes.COST_FIXED': 'Costo fijo',
    'Close payment summary': 'Cerrar resumen de pago',

    'Restaurants linked to the inventory': 'Restaurantes vinculados a tu inventario',
    'Unlink restaurant to inventory': 'Desvincular restaurante del inventario',
    'Unlink': 'Desvincular',
    'Shared': 'Compartido',
    'Link inventory from other restaurant': 'Vincular inventario de otro restaurante',
    'Link restaurant to inventory': 'Vincular otro restaurante a tu inventario',
    'By linking the inventory with that of another restaurant, your current inventory will be lost': 'Al vincular el inventario con el de otro restaurante, se perderá tu inventario actual',
    'By linking the restaurant to your inventory, his inventory will be lost': 'Al vincular otro restaurante a tu inventario, se perderá el inventario de ese restaurante',
    'Select a restaurant to link inventory': 'Seleccionar el restaurante del que desea vincular el inventario',
    'When the restaurant is unlinked from the inventory of @inventoryLinkedToRestaurantName, the inventory of the restaurant will be left empty': 'Al desvincular el restaurante del inventario de @inventoryLinkedToRestaurantName, tu inventario quedara vacío',
    'List of restaurants linked to the inventory of this restaurant': 'Lista de los restaurantes vinculados al inventario de este restaurante',
    'Your restaurant is linked to inventory of restaurant: @restaurantName': 'Tu restaurante está vinculado al inventario del restaurante: @restaurantName',

    'Credits used': 'Creditos usados',
    'Please upload a CSV file with columns named SKU, QUANTITY, UNIT and PRICE': 'Por favor sube un archivo CSV con columnas llamadas SKU, CANITDAD, UNIDAD y PRECIO',

    'Write an address to show the map': 'Escribe una dirección para mostrar el mapa',
    'New note': 'Nueva nota',
    'Remove release notes @releaseNotesTitle': 'Eliminar release notes @releaseNotesTitle',
    'Make sure to save the link with the https protocol': 'Asegurate de guardar el link con el protocolo https',
    'opens your cash drawer': 'abre tu cajón de dinero',
    'Phone': 'Teléfono',

    'Complete': 'Completar',
    'Buy Order Complete': 'Orden de compra completada',
    'Do you want to load supplies from this supplier?': 'Deseas cargar los insumos de este proveedor?',
    'Send by Email': 'Enviar por correo electrónico',

    'Cost Type': 'Tipo de costo',
    'Currently update inventory for counts are enabled': 'Actualmente la actualización para el conteo de inventario esta habilidatada',

    'This product has modifiers different from those of the category': 'Este producto tiene modificadores diferentes a los de la categoría',

    'Show products in columns on mobile': 'Mostrar los productos en  columnas para celular',

    'Send Email': 'Enviar por correo',
    'Cannot take change as tip when paying with multiple payment methods': 'No puedes tomar cambio como propina con multiples pagos',
    'Remove @deviceGroupName': 'Eliminar @deviceGroupName',
    'RolePermissions.INVENTORY_PRODUCTION': 'Producción',

    'Product limit': 'Limite de producto',
    'Rappi Cargo Card': 'Rappi Cargo Tarjeta',
    'Commissions': 'Comisiones',
    'Rappi Cargo Paymentlink': 'Rappi Cargo Links de pago',
    'Rappi Cargo Cash': 'Rappi Cargo Efectivo',
    'Uber Daas PaymentLink': 'Uber Daas Links de pago',
    'Uber Daas Card': 'Uber Daas Tarjeta',
    'Uber Daas Cash': 'Uber Daas Efectivo',
    'App Card': 'App tarjeta',
    'App Cash': 'App efectivo',
    'Web Card': 'Web tarjeta',
    'Web Cash': 'Web efectivo',
    'PaymentLinks': 'Links de pago',
    'Total Card': 'Total tarjeta',
    'Total card commission': 'Total de comisión por tarjeta',
    'Total Cash': 'Total efectivo',
    'Total cash commission': 'Total de comisión por efectivo',
    'Other income': 'Otros ingresos',
    'Promo Codes': 'Códigos de promoción',
    'Total Others': 'Total Otros',
    'Summary': 'Resumen',
    'Refunds': 'Reembolsos',
    'Total commissions': 'Total de comisiones',
    'IVA on commissions': 'IVA sobre comisiones',
    'Amount to Deposit': 'Monto a depositar',
    'Marketplace Card': 'Marketplace tarjeta',
    'Marketplace Cash': 'Marketplace efectivo',

    'No kitchen orders': 'Sin órdenes en cocina',
    '@totalOrders orders in kitchen': '@totalOrders Órdenes en cocina',
    'Note of customer': 'Nota del comensal',
    'Enables restaurant to send credits to their customers, this credits will be 100% paid by the restaurant': 'Habilita al restaurant a enviar créditos a sus clientes, estos créditos serán 100% pagados por el restaurante',
    'Give credits to customers enabled': 'Dar créditos a clientes habilitado',
    'Result': 'Resultado',
    'This customer is not able to receive credits': 'Este cliente no puede recibir créditos',
    'Change device group': 'Editar grupo de dispositivos',
    'Linked devices': 'Dispositivos vinculados',
    'Device groups': 'Grupos de dispositivos',
    'Groups': 'Grupos',
    'Create new +': 'Crear nuevo +',
    'PrintTypes.COMMAND': 'Comanda',
    'PrintTypes.TICKET': 'Ticket',
    'PrintTypes.POS_BUSINESS_DAY_REPORT': 'Reporte de cierre de día',
    'PrintTypes.SHIFT_CASH_REGISTER_REPORT': 'Reporte de corte de caja',
    'PrintTypes.PRINTER_INFO': 'Info de la impresora',
    'Failed to print @printType on @deviceName': 'Fallo al imprimir @printType en @deviceName',
    'Select a printer to print @printType': 'Selecciona una impresora para imprimir @printType',

    'Preview': 'Vista previa',
    'ALL': 'Todos',
    'Start organizing your business': 'Empieza a organizar tu negocio',
    'Group your devices': 'Agrupa tus dispositivos',
    'Delete Product': 'Eliminar producto',

    'There is pending data or an error in the customer data': 'Hay datos pendientes o algún error con los datos del comensal',
    'Number of customers is required': 'El número de comensales es requerido',
    'Advanced options': 'Opciones avanzadas',

    'This modifier will be automatically selected': 'Este modificador será seleccionado automáticamente',
    'Create income': 'Crear ingreso',
    'Merchandise Income': 'Ingreso de mercancía',
    'Merchandise Income created successfully': 'Ingreso de mercancía creado exitosamente',
    'Create Merchandise Income': 'Crear ingreso de mercancía',

    'Opening of the day': 'Apertura del día',
    'Go back to cash registers': 'Regresar a cajas',
    'Open your cash register to start making orders': 'Abre tu caja para comenzar a tomar órdenes',
    'You do not have the permissions to open cash register': 'No tienes los permisos para abrir una caja',
    'Enabled multiple cash registers': 'Habilita múltiples cajas registradoras',
    'Define maximum cash allowed in cash register': 'Definir efectivo máximo permitido en caja',
    'Activate this option if you want to have more than one cash register open at the same time. You need to not have open cash registers to activate': 'Activa está opción si deseas tener más de una caja registradora abierta al mismo tiempo. Es necesario no tener cajas abiertas para activarlo',
    'Summary by cash registers': 'Resumen por cajas',

    'room - @room': 'Habitacion - @room',
    'Room service': '№ Cuarto',
    'OrderTypes.ROOM_SERVICE_ORDER - @roomNumber': '№ Cuarto - @roomNumber',

    'Delivery order': 'Orden de entrega',
    'Take away order': 'Pedido para llevar',
    'Room service order': 'Orden de servicio de habitaciones',
    'Eat here order': 'Comer aquí orden',
    'Didi icon': 'Icono de didi',
    'Rappi icon': 'Icono de rappi',
    'Uber icon': 'Icono de uber',
    'Uberdaas icon icon': 'Icono de uberdaas',
    'Pos icon': 'Icono de pos',
    'Kiosk icon': 'Icono de kiosko',
    'PD icon': 'Icono de pd',
    'PedidosYa icon': 'Icono de PedidosYa',
    'Manual order icon': 'Icono de ordenes manuales',
    'Must be at most @number characters': 'Debe tener como máximo @number caracteres',

    'Many orders? Adjust preparation time during peak hours': '¿Muchos pedidos? Ajusta el tiempo en hora pico',
    'Current preparation time: @preparationTimeInMinutes minutes': 'Tiempo de preparación actual: @preparationTimeInMinutes minutos',
    'How long do you want to change it?': '¿Por cuánto tiempo deseas cambiarlo?',
    'Preparation time (minutes)': 'Tiempo de preparación (minutos)',

    'Edit @menuCategoryName': 'Editar @menuCategoryName',
    'Remove @menuCategoryName': 'Eliminar @menuCategoryName',
    'Select modifier groups': 'Seleccionar grupos de modificadores',

    'Updates to your Rappi menu may take a while to appear in the app.': 'Las actualizaciones en tu menú de Rappi pueden demorar en aparecer en la aplicación.',
    'Identify': 'Identifícate',
    'Automatically selected': 'Seleccionado automáticamente',
    'Pre-selected': 'Pre-seleccionado',
    'Room Service': 'Servicio de habitaciones',

    'Web order conversions': 'Conversiones pedidos web',
    'Web menu': 'Menú web',
    'New clients': 'Clientes nuevos',
    'Conversion percentage': 'Porcentaje de conversión',
    'Conversion Funnel': 'Embudo de ventas',
    'User opened Pidedirecto Web': 'Abrieron PideDirecto',
    'User added item to cart': 'Añadieron artículos',
    'User signed in to Pidedirecto': 'Iniciaron sesión',
    'User opened cart': 'Abrieron el carrito',
    'User created order': 'Crearon la orden',
    'Total users': 'Usuarios',
    'Percentage from previous step': 'Porcentaje del paso anterior',
    'Total percentage': 'Porcentaje del total',
    'Added first item to cart': 'Añadió un artículo',

    'Average time per step': 'Tiempo promedio por paso',
    'Created order': 'Creó la orden',
    'Opened cart details': 'Abrió el carrito',
    'Signed in to Pidedirecto': 'Inició sesión',
    'Total time': 'Tiempo total',
    'Sessions per device type': 'Sesiones por tipo de dispositivo',
    'Sessions per customer origin': 'Sesiones por origen del cliente',

    'Mode': 'Modo',
    'Select a supply to see its history': 'Selecciona un insumo para ver su historial',
    'Busy (@total)': 'Ocupado (@total)',
    'Map': 'Mapa',
    'Loading map': 'Cargando Mapa',
    'My live orders': 'Mis ordenes en vivo',
    'Actives': 'Activas',
    'On the way': 'En camino',
    'At restaurant': 'En restaurante',
    'Close map': 'Cerrar mapa',
    'There are no delivery orders': 'No hay ordenes de envío',

    'Your personal pin code is required to continue': 'Se requiere tu código personal para continuar',
    'Assign cash register': 'Asignar caja registradora',
    'Assign cash register for external or auto accepted orders': 'Asignar caja para órdenes externas o auto aceptadas',

    'ProductLayouts.LARGE': 'Grande',
    'ProductLayouts.MEDIUM': 'Mediano',
    'ProductLayouts.SMALL': 'Pequeño',
    'Understood': 'Entendido',

    'Types layouts to ecommerce': 'Tipos de diseños para ecommerce',
    'Menus:': 'Menús:',
    'Categories:': 'Categorías:',

    'There are no results for your search parameters': 'No hay resultados para tus parámetros de búsqueda',
    'Is not possible to copy the menu. The selected restaurant has a different management version.': 'No es posible copiar el menú. El restaurant seleccionado cuenta con una versión diferente de administración',

    'Is consumable': 'Es consumible',

    'Enable verification photos for Uber Daas': 'Habilitar fotos de verificación para Uber Daas',
    'Obtain verification photos of the delivery process': 'Obtener fotos de verificación del proceso de entrega',
    'Wait time per delivery provider (Seconds)': 'Tiempo de espera por delivery provider (Segundos)',

    'You have already opened a cash register before, you cannot open more than one cash register in a day': 'Ya has abierto antes una caja antes, no puedes abrir más de una caja en el día',

    'What do you want to do with this inventory?': '¿Qué deseas hacer con este inventario?',
    'Update and overwrite inventory': 'Actualizar y sobrescribir inventario',
    'Save count log': 'Guardar registro del conteo',
    'Select categories': 'Selecciona categorías',
    'This item will be added to the next categories': 'Este item será agregado a las siguientes categorías',
    'Hidden': 'Oculto',

    '1 Hour (Will end at @time)': '1 Hora (Terminará @time)',
    '3 Hour (Will end at @time)': '3 Horas (Terminará @time)',
    'Rush Hour': 'Hora Pico',

    'Generated Commissions': 'Comisiones generadas',

    'Verification photos': 'Fotos de verificación',
    'Order with delivery man': 'Orden con repartidor',
    'The restaurant handed the order to the delivery man': 'El restaurante entregó la orden al repartidor',
    'Order delivered': 'Orden entregada',
    'The delivery man successfully delivered the order': 'El repartidor entregó la orden con éxito',
    'Suspended order': 'Orden suspendida',
    'The order is back to the restaurant': 'La orden esta de regreso al restaurante',
    'Image not available': 'Imagen no disponible',
    'In progress..': 'En proceso..',
    'The delivery person has not yet completed this step': 'El repartidor aun no completa este paso',
    'Invoices': 'Facturación',

    'General account': 'Cuenta general',
    'Cannot add tip with credit card payment completed': 'No se puede agregar propina con pago con tarjeta completado',
    'Cannot add tip when not paying with credit card': 'No se puede agregar propina cuando no se paga con tarjeta de crédito',
    'Cannot add tip with card when not paying with credit card': 'No se puede agregar propina con tarjeta cuando no se paga con tarjeta de crédito',
    'Identified correctly': 'Identificado correctamente',
    'In percentage, e.g. type 20 to create a 20% discount promotion. To reset all promo prices type 0': 'En porcentaje, ej. escribe 20 para crear una promoción del 20%. Para eliminar el descuento escribe 0',

    'InvoiceTypes.GLOBAL_ORDERS': 'Factura global',
    'advanced reports icon': 'reportes avanzados',
    'cash register': 'caja registradora',

    'It is a big order': 'Es una orden grande',
    'Check this option if you require a larger vehicle for this order. The time and cost could be greater.': 'Marca esta opción si requieres un vehículo mas grande para esta orden. El tiempo y costo podrían ser mayores.',

    'Driver Details': 'Detalles del conductor',
    'HARD DELETE': 'Eliminar permanente',
    'Positioned In City': 'Ubicado en la ciudad',
    'Language': 'Idioma',
    'Platform': 'Plataforma',
    'Platform Version': 'Versión de plataforma',
    'Downloaded App At': 'Aplicación descargada el',
    'First Delivery At': 'Primera entrega el',
    'Last Delivery At': 'Última entrega el',
    'Positioned At': 'Posicionado el',
    'Location Permission': 'Permiso de ubicación',
    'Exported At': 'Exportado el',

    'Image Url of BackWard Id': 'Url de imagen del Id inverso',
    'Front ID Image url': 'url de imagen del ID de frente',
    'Id backward': 'Id inverso',
    'Front Id': 'Id de frente',
    'Total tickets: @totalOrders': 'Tickets totales: @totalOrders',
    'Total sold: @totalSold': 'Ventas totales: @totalSold',
    'Invoiced sales: @totalInvoiced': 'Ventas facturadas: @totalInvoiced',
    'Sales to invoice: @totalToInvoice': 'Ventas a facturar: @totalToInvoice',
    '*When creating the invoice only uninvoiced orders are considered ': '*Al crear una factura global solo se consideran las órdenes sin facturar',
    'Uninvoiced': 'Sin facturar',
    'Invoiced': 'Facturado',
    'Issuer': 'Emisor',
    'Invoiced to': 'Facturado a',
    'General public': 'Público en general',
    'Invoice usage': 'Uso de factura',
    'Global sales': 'Venta global',
    'Data confirmation': 'Confirmación de datos',
    'Yes, invoice': 'Sí, facturar',
    'No, cancel': 'No, cancelar',
    'Invoice created': 'Factura creada',
    'The invoice has been generated successfully!': '¡La factura se ha generado exitosamente!',
    'No. invoice': 'No. Factura',
    'Expedition': 'Expedición',

    'sync opening hours': 'sincronizar horas de apertura',
    'There was a problem syncing some of your menus': 'Hubo un problema al sincronizar algunos de tus menús.',
    'There is an error with the selected menu. Please contact support': 'Hay un error con el menú seleccionado. Por favor contacta a soporte',
    'Errors to sync your menus': 'Errores al sincronizar tus menús',
    'Error while trying to clone the modifierGroup': 'Error al tratar de clonar el grupo de modificadores',

    'It is not possible to take orders as Brand. Select one location in the manager to take the order.': 'No es posible tomar órdenes como Marca. Selecciona en tu manager una sucursal para tomar la orden.',
    'It is not possible to ask for a driver as Brand. Select one location in the manager to take the order.': 'No es posible solicitar repartidor como Marca. Selecciona en tu manager una sucursal para solicitar un repartidor.',

    'Manage Courses': 'Administrar tiempos',
    '+ Add Course': '+ Agregar tiempo',
    'No courses added yet': 'Aún no se han agregado tiempos',
    'Take order by course': 'Tomar orden por tiempo',
    'Course Name': 'Nombre del tiempo',
    'Add course': 'Agregar tiempo',
    '+ Add': '+ Agregar',
    'Course removed successfully': 'Tiempo eliminado exitosamente',
    'Course added successfully': 'Tiempo creado exitosamente',

    'Channel icon': 'Icono del canal de la orden',
    'Define maximum cash on cash register': 'Definir máximo de efectivo en caja',
    'Maximum cash on cash register': 'Efectivo máximo en caja',
    'Enabled option will take the value set to notify when this maximum has been reached and should be withdrawn': 'Activa la opción para tomar el valor establecido y notificar cuando este se ha alcanzado para hacer un retiro',
    'Select a terminal': 'Selecciona una terminal',
    'Select a terminal to pay @amount': 'Selecciona la terminal para pagar @amount',
    'Select a terminal to use for the refund': 'Seleccione una terminal para el reembolso',
    'Terminal not configured': 'Terminal no configurada',
    'Error': 'Error',
    'Terminal busy, please wait a moment and try again': 'Terminal ocupada, por favor espere un momento e inténtelo de nuevo',
    'Terminal not found, check your configuration and try again': 'Terminal no encontrada, compruebe su configuración e inténtelo de nuevo',
    'Terminal not found, ask in store or try later': 'Terminal no encontrado, pregunte en tienda o inténtelo más tarde',
    'For terminal payments please verify that the terminal is turned on': 'Para pagos con terminal por favor verifica que la terminal este encendida',
    'New Name': 'Nuevo nombre',
    'Remove withdraw reason': 'Eliminar razón de retiro',
    'Remove deposit reason': 'Eliminar razón de deposito',
    'Available on @channels': 'Disponible en @channels',
    'Available on': 'Disponible en',

    'Shared with @numberOfRestaurants restaurants': 'Compartido con @numberOfRestaurants restaurantes',
    'InternalReports.GENERAL_OPERATIONS_REPORT': 'Report general de operaciones',
    'InternalReports.GENERAL_SALES_REPORT': 'Report general de ventas',
    'InternalReports.LAS_ALITAS_SALES_REPORT': 'Reporte de ventas de Las Alitas',
    'InternalReports.BONUS_BILLING_REPORT': 'Reporte de bonus billing',
    'InternalReports.OPS_ORDERS_AND_TIMES_REPORT': 'Reporte de tiempos y ordenes',
    'InternalReports.OPS_RAPPI_1000_Orders_Report': 'Reporte de ordenes de rappi mayores a $1000',
    'InternalReports.OPS_DELIVERY_PROVIDERS_REPORT': 'Reporte de proveedores de envío',

    'InternalReports.ORDERS_BREAKDOWN_BY_PAYMENTS_PROVIDER': 'Reporte de ordenes por procesador de pagos',
    'InternalReports.KFC_DELIVERY_ORDERS_REPORT': 'Reporte de ordenes de envío para KFC',

    'Request internal report': 'Solicitar reporte interno',
    'The request has been done successfully!': 'La solicitud se ha hecho correctamente!',
    'The report could take a couple of minutes depending of the time interval selected': 'El reporte puede tomar un par de minutos dependiendo del intervalo de tiempo seleccionado',

    'Use large font': 'Usar letras grandes',
    'If you have Increase font size active, the prices will not be printed on command': 'Sí tienes activo "Incrementar tamaño de fuente", los precios no se mostraran en la comanda',
    'If you activate this option, the item prices will not be visible on the command .This feature is not available for STAR printers': 'Si activa esta opción, los precios de los artículos no serán visibles en comanda. Esta función no está disponible para impresoras STAR',
    'Do not open the cash register on ticket reprints': 'No abrir la caja registradora en reimpresiones de tickets',
    'Activate this option if you do not want to open the cash register when reprinting a completed order that was paid with cash': 'Active esta opción si no desea abrir la caja registradora al reimprimir el ticket de una orden completada que fue pagada en efectivo',

    'Error to opening the cash register, check its connection': 'Error al abrir la caja registradora, verifique su conexión',
    'Open cash register for withdrawals and deposits': 'Abrir caja registradora para retiros y depósitos',
    'Activate the option if you want to open cash box when making deposits or withdrawals': 'Activa esto si deseas abrir la caja registradora al hacer depositos o retiros',

    'Enable period by date and time': 'Definir rango de reportes con detalle de horas.',
    'For businesses that work until after midnight. The client will be able to select what they want to view in their reports with day/time details': 'Para comercios que trabajan hasta después de la medianoche. El cliente podrá seleccionar lo que quiere visualizar en sus reportes con detalle de día/hora',
    'You can not open a cash register': 'No puedes abrir una caja',

    'Credits to use': 'Créditos a usar',
    '@creditsToUse applicable credits': '@creditsToUse créditos aplicables',
    'Check available credits': 'Verificar créditos disponibles',
    'Do you have ideas to improve AmbitOne?': '¿Tienes ideas para mejorar AmbitOne?',
    'We want to hear you': 'Te queremos escuchar',
    'There is pending information from the restaurant, please complete it in the settings': 'Hay información pendiente del restaurante, por favor completarla en la configuración',
    'Insert your Pedidos Ya Restaurant Id': 'Inserta tu ID de Restaurante Pedidos Ya',

    'Pedidos Ya Integration': 'Integracion con Pedidos Ya',
    'Insert your Pedidos Ya pos vendor Id': 'Inserta tu ID de Pedidos Ya pos vendor',

    'Number of prints': 'Número de impresiones',
    'Number of prints of the ticket for each connected printer': 'Número de impresiones del ticket por cada impresora conectada',
    'Number of prints of the command for each connected printer': 'Número de impresiones de la comanda por cada impresora conectada',
    'Must be a number greater than zero': 'Debe ser un número mayor a cero',
    'Must be an integer': 'Debe ser un número entero',
    'Do you want to update the base costs of these supplies with those of this buy order?': '¿Deseas actualizar los costos base de estos insumos con los de esta orden de compra?',
    'By updating the costs of these inputs, you can affect prescriptions and compounded inputs that use them': 'Al actualizar los costos de estos insumos, puede afectar a recetas e insumos compuestos que los utilicen',
    'Update supply costs with this buy order': 'Actualizar costos de los insumos con esta orden de compra',
    'Just complete the buy order': 'Sólo completar la órden de compra',

    'Always active': 'Siempre activo',
    'Every day': 'Todos los dias',
    'Week days': 'Entre semana',
    'Custom': 'Personalizado',
    'Weekends': 'Fines de semana',
    'Schedule': 'Horario',
    'Select the days': 'Selecciona los días',

    'SubscriptionStatuses.NEW': 'Nuevas',
    'SubscriptionStatuses.PAID': 'Pagadas',
    'SubscriptionStatuses.PENDING': 'Pendientes',
    'SubscriptionStatuses.CANCELLED': 'Canceladas',
    'SubscriptionStatuses.TEST': 'Prueba',
    'SubscriptionStatuses.INACTIVE': 'Inactivas',

    'This cash register is assigned for auto accepting orders, select another cash register for auto accepting orders': 'Esta caja registradora está asignada para órdenes auto aceptadas, seleccione otra caja registradora para pedidos de aceptación automática',
    'Assign cash register for auto accepted orders': 'Asignar caja para órdenes auto aceptadas',
    'You have cash register @fromCashNumber assigned for auto accepted orders, are you sure you want to assign cahs register @toCashNumber now?': 'Tienes asignada la Caja @fromCashNumber para pedidos auto aceptados, ¿seguro que deseas asignar ahora la Caja @toCashNumber?',
    'Cash registers for external or auto accepted orders': 'Caja para órdenes de envío y auto aceptadas',
    'This cash register will be used to receive external or auto accepted orders': 'Esta caja será destinada para recibir pedidos auto aceptados',
    'Activate this option to be able to have visibility and control of the designated cash register for external or  auto accepted orders': 'Activa esta función para poder tener la visibilidad y el control de la caja designada para órdenes externas o auto aceptadas',

    'Reports from @fromDate to @toDate': 'Reportes de @fromDate a @toDate',
    'For Ecommerce': 'Para Ecommerce',
    'For WLA': 'Para WLA',
    'Change Banner': 'Modificar Banner',
    'No registered promocode found': 'No se encontró ningún promocode registrado',
    'None': 'Ninguno',

    'QpayTerminalPaymentInstructionResults.TERMINAL_NOT_FOUND': 'TERMINAL NOT FOUND',
    'QpayTerminalPaymentInstructionResults.UNKNOWN': 'UNKNOWN',
    'QpayTerminalPaymentInstructionResults.INSTRUCTION_RECEIVED': 'INSTRUCTION RECEIVED',
    'QpayTerminalPaymentInstructionResults.TERMINAL_BUSY': 'TERMINAL BUSY',
    'PaymentTerminalInstructionResults.TERMINAL_NOT_FOUND': 'TERMINAL NOT FOUND',
    'PaymentTerminalInstructionResults.UNKNOWN': 'UNKNOWN',
    'PaymentTerminalInstructionResults.INSTRUCTION_RECEIVED': 'INSTRUCTION RECEIVED',
    'PaymentTerminalInstructionResults.TERMINAL_BUSY': 'TERMINAL BUSY',
    'PaymentTerminalInstructionResults.INSTRUCTION_COMPLETED': 'INSTRUCTION COMPLETED',
    'Collection Station': 'Estación de Recolección',
    'Collection station closing time': 'Hora de cierre de la estación de recolección',
    'Collection station delivery time': 'Tiempo de entrega de la estación de recolección',
    'Same hours every day': 'Mismo horario todos los días',
    'Opening hours': 'Horarios de Apertura',
    'Select days': 'Selecciona los días',
    'Add schedule': 'Agregar horario',
    'Last cash register shift': 'Último corte de caja',
    'ShortenedDays.Mo': 'L',
    'ShortenedDays.Tu': 'M',
    'ShortenedDays.We': 'M',
    'ShortenedDays.Th': 'J',
    'ShortenedDays.Fr': 'V',
    'ShortenedDays.Sa': 'S',
    'ShortenedDays.Su': 'D',
    'Selecting days is required': 'Seleccionar días es requerido',
    'You have pending integration orders': 'Tienes órdenes de integraciones pendientes',
    'You have pending deliveries': 'Tienes entregas pendientes',
    'Switch to order @orderType': 'Cambiar a órden @orderType',
    'Approved icon': 'Icono de aprobación',
    'Rejected icon': 'Icono de rechazo',
    'The menu @menuName has not been updated correctly cause has been rejected by integrator': 'El menú @menuName no ha sido actualizdo correctamente por que ha sido rechazado por el integrador',
    'Menu changes were approved': 'Los cambios en el menú fueron aprobados',
    'Menu changes were rejected': 'Los cambios en el menú fueron rechazados',
    'The menu had the following errors': 'El menú tiene los siguientes errores',
    'The menu @menuName has been updated correctly': 'El menú @menuName ha sido actualizado correctamente',
    'SmsServiceProviders.AWS': 'Aws',
    'SmsServiceProviders.VOICES': 'Voices',
    'SmsServiceProviders.INFOBIP': 'InfoBip',
    'SMS service provider': 'Proveedor de servicio de SMS',
    'For Uruguay the provider AWS is used instead of Voices': 'Para Uruguay el provedor AWS se usa en lugar de Voices',

    'Please select a course': 'Por favor selecciona un tiempo',

    'Plexo Settings': 'Configuración de Plexo',
    'Plexo enabled': 'Plexo habilitado',
    'Enables plexo integration': 'Habilita la integración de plexo',
    'Plexo store id': 'Id de la tienda en Plexo',
    'The plexo store id is required for the plexo integration': 'El id de la tienda en plexo es requerido para la integración de plexo',

    'Service fee': 'Comisión por servicio',
    'Assign the percentage for your sales in @integrationName. It will be reflected in your reports': 'Asigna el porcentaje por tus ventas en @integrationName. Se reflejará en tus reportes',
    'Commission of': 'Comisión de',
    'Maximum cash order amount for MX': 'Monto máximo de orden en efectivo para MX',
    'Maximum cash order amount for UY': 'Monto máximo de orden en efectivo para UY',
    'Maximum cash order amount for AR': 'Monto máximo de orden en efectivo para AR',
    'Maximum Cash Order Amount': 'Monto máximo de orden en efectivo',
    "If this value is higher than the maximum order amount this field won't be used": 'Si este valor es más alto que el monto máximo de orden este campo no será usado',

    'CREDITS': 'Créditos',
    'Activate Cyclical delivery manager': 'Delivery manager ciclico',

    'Estimated': 'Estimado',
    'Short order Id': 'Orden Id corto',

    'The driver pays with cash 💵': 'El repartidor pagará en Efectivo 💵',
    'It is not needed receive cash': 'No es necesario recibir efectivo',

    'Current zones': 'Zonas actuales',
    'Change Delivery Zones': 'Editar zona de envío',
    'Zone Color': 'Color de Zona',
    'Create Delivery Zone': 'Nueva zona de envío',
    'You are about to delete the “@deliveryZone” zone, are you sure you want to continue?': 'Estás a punto de eliminar la zona “@deliveryZone”, ¿estás seguro de querer continuar?',

    'Cash: @cashDeliveryProviders': 'Efectivo: @cashDeliveryProviders',
    'Card: @cardDeliveryProviders': 'Tarjeta: @cardDeliveryProviders',

    'Enter the new password': 'Ingresa la nueva contraseña',
    'Change password': 'Cambiar contraseña',
    'Sign out from all devices': 'Cerrar sesión en todos los dispositivos',

    'You can`t use Invoiced Delivery with External Delivery': 'No puedes utilizar Envíos Facturados con Entrega Externa',
    'You can`t use Exteral Delivery with Invoiced Delivery': 'No puedes utilizar Entrega Externa con Envíos Facturados',
    'NOTE : @posDiscountNotes': 'Nota: @posDiscountNotes',

    'Net Sales': 'Venta neta',
    'Gross Sales': 'Venta bruta',

    'Waste': 'Merma',

    'Add app contemplated': 'Agregar app contemplada',
    'Service': 'Servicio',
    'Apps contemplated at the end of the day': 'Apps contempladas en cierre de día',
    'Add app': 'Agregar app',
    'Are you sure you want to remove the contempled app @contemplatedApp?': '¿Estás seguro de querer eliminar la app contemplada @contemplatedApp?',
    'Remove contemplated app': 'Eliminar app contemplada',

    'Deliver orders': 'Entregar órdenes',
    'Without commission': 'Sin comisión',
    'The percentage was changed successfully': 'El porcentaje fue cambiado exitosamente',

    'Multiple payments': 'Múltiples pagos',

    'Mark as picked up': 'Marcar como recogida',

    'Average last 30 days': 'Promedio últimos 30 días',
    'Next day screening': 'Cribado del día siguiente',
    '13 day projection': 'Proyección de 13 días',
    'Last 30 days': 'Últimos 30 días',

    'Day: @day': 'Día: @day',
    'Sales: @sales': 'Ventas: @sales',
    'Channels included in the count:': 'Canales incluidos en el conteo:',

    'POS Tables view as default': 'Vista de mesas del POS por defecto',
    'Enable the option if you want to set the POS tables view instead of the default select order type view': 'Habilite la opción si desea configurar la vista de mesas del POS en lugar de la vista predeterminada de selección de tipo de orden',
    'Total in box': 'Total en caja',

    'Cash Delivery Providers': 'Proveedores con pago en efectivo',
    'Card Delivery Providers': 'Proveedores con pago en tarjeta',
    '+ Add supplier': '+ Agregar proveedor',
    'Add supplier': 'Agregar proveedor',
    'Add a supplier and assign the wait time': 'Agrega un proveedor y asigna el tiempo de espera',
    'Modify supplier': 'Modificar proveedor',
    'Suppliers with card payment': 'Proveedores con pago en tarjeta',
    'Waiting time (seconds)': 'Tiempo de espera (segundos)',
    'Delivery Provider': 'Proveedor de entrega',
    'Third Party Logistics Coverage': 'Cobertura de entrega de terceros',

    'Taxes': 'Impuestos',
    'Tax type': 'Tipo de impuesto',
    'Order types': 'Tipos de orden',
    'Create tax': 'Crear impuesto',
    'Apply to all products': 'Aplicar a todos los productos',
    'The tax was created successfully': 'El impuesto fue creado exitosamente',
    'Name (alias)': 'Nombre (alias)',
    'Percentage': 'Porcentaje',
    'Hide driver tip on ecommerce enabled': 'Ocultar propina para repartidor en ecommerce habilitado',
    'It will not show the driver tip section on ecommerce. This only apply when restaurants do their own deliveries.': 'No se mostrará la sección de agregar propina en el e-commerce. Solo aplica para cuando el cliente hace sus propias entregas (entrega externa).',
    'Temporarily without delivery': 'Temporalmente sin entrega',
    'Temporarily disable delivery orders from ecommerce and WLA': 'Deshabilitar temporalmente ordenes de delivery desde ecommerce y WLA',

    'Cancelled orders by waiter': 'Órdenes canceladas por mesero',
    'There are no supplies resume to download': 'No hay productos para descargar',

    'Original price: @price': 'Precio original: @price',
    'Loki enabled': 'Loki habilitado',
    'Enable loki edition': 'Habilitar la edición de loki',
    'To use loki tool user has to have the sign in with password permission': 'Para usar la herramienta de loki el usuario tiene que tener el permiso de iniciar sesión con contraseña',
    'Info validation': 'Validar información',

    'Conflict when saving changes': 'Conflicto al guardar cambios',

    'Icon': 'Ícono',
    'Chain': 'Cadena',
    'Cellphone': 'Teléfono móvil',
    'Arrows with opposite directions': 'Flechas con direcciones opuestas',
    'Wallet': 'Billetera',
    'Door hanger': 'Colgador de puerta',
    'Sheet': 'Hoja',
    'Select mocked payment behavior': 'Elige el comportamiento simulado de pago',
    'Terminal Payment Instruction Result': 'Resultado de la instrucción de pago terminal',
    'Response Result': 'Resultado de la respuesta',
    'Timeout to response': 'Tiempo de espera para responder',
    'Create payment behavior': 'Crear comportamiento de pago',

    'WeekSchedules.ALWAYS': 'Siempre',
    'WeekSchedules.WEEKDAY': 'Entre semana',
    'WeekSchedules.WEEKEND': 'Fin de semana',
    'WeekSchedules.CUSTOM': 'Perzonalizado',

    'Event history': 'Historial de eventos',
    'Send order created webhook at planned pick up time enabled': 'Enviar webhook de orden creada en la fecha planeada',
    'Sends the order created webhook one hour before the planned pickup time, this works only for planned orders.': 'Envía el webhook de orden creada una hora antes de la fecha planeada, esto funciona solo para órdenes planeadas.',

    'ArgentinianTaxes.IVA': 'IVA',
    'ArgentinianTaxes.IIBB': 'IIBB',

    'MexicanTaxes.IVA': 'IVA',
    'MexicanTaxes.ISR': 'ISR',
    'MexicanTaxes.IEPS': 'IEPS',

    'UruguayanTaxes.IVA_TASA_BASICA': 'IVA Tasa Básica',
    'UruguayanTaxes.IVA_TASA_MINIMA': 'IVA Tasa Mínima',

    'RestaurantPsps.PLEXO': 'Plexo',
    'RestaurantPsps.MERCADO_PAGO': 'Mercado Pago',
    'RestaurantPsps.AUTO': 'Automático',

    'Card psp settings': 'Configuración de psp',
    'Psp to be used for ecommerce payments': 'Psp ha ser usado en los pagos de ecommerce',
    'If this field is disabled the psp used will be automatic': 'Si este campo está deshabilitado el psp usado será automático',
    'Mercado pago settings': 'Configuración de mercado pago',
    'Remove mercado pago key': 'Eliminar mercado pago key',

    '@citiesList and @leftCitiesLength more': '@citiesList y @leftCitiesLength más',
    'Location': 'Ubicación',
    'Online': 'En línea',
    'Sound on': 'Activar sonido',
    'Sound off': 'Desactivar sonido',
    'Switch POS user': 'Cambiar usuario del POS',
    'Point of sales': 'Punto de venta',
    'Personalization': 'Personalización',
    'Closing of the day and cash cut': 'Cierre de día y corte de caja',
    'Cash register closing': 'Cierre de caja',
    'Emails to notify subscription changes': 'Emails para notificar cambios en subscripciones',

    'Menu name': 'Nombre del menu',

    'Menu @menuName changed': 'Menu @menuName actualizado',
    'Menu @menuName created': 'Menu @menuName creado',
    'Menu @menuName removed': 'Menu @menuName eliminado',
    'Menu copied from @restaurantName': 'Menu copiado de @restaurantName',
    'Menu imported': 'Menú importado',
    'Menu category @menuCategoryName changed': 'Categoria del menu @menuCategoryName actualizado',
    'Menu category @menuCategoryName created': 'Categoria del menu @menuCategoryName creado',
    'Menu category @menuCategoryName removed': 'Categoria del menu @menuCategoryName eliminado',
    'Menu item @menuItemName changed': 'Item del menu @menuItemName actualizado',
    'Menu item @menuItemName created': 'Item del menu @menuItemName creado',
    'Menu item @menuItemName removed': 'Item del menu @menuItemName eliminado',
    'Modifier Group @modifierGroupName changed': 'Grupo modificador @modifierGroupName actualizado',
    'Modifier Group @modifierGroupName created': 'Grupo modificador @modifierGroupName creado',
    'Modifier Group @modifierGroupName removed': 'Grupo modificador @modifierGroupName eliminado',
    'Menu item @menuItemName changed in menu @menuName changed': 'Item @menuItemName actulizado en el menu @menuName',
    'Synchronizing menu categories': 'Sincronizando categorías',
    'Synchronizing menus': 'Sincronizando menús',
    'Synchronizing menu items': 'Sincronizando items del menú',
    'Synchronizing modifier groups': 'Sincronizando grupo de modificadores',

    'Create Own Fleet Driver': 'Crear repartidor para mi flotilla',
    'Maximum order amount without card validation': 'Monto máximo de order sin validación de tarjeta',
    'Maximum order amount wihtout card validation, this only applies for MX, default is $300': 'Monto máximo de orden sin validación de tarjeta, solo aplica para MX, el default es $300',

    'Auto call restaurant': 'Llamada automática al restaurante',
    'Auto call restaurant and notify when a pending order its not accepted in time.': 'Llama automáticamente al restaurante y notifica cuando hay una orden pendiente que no ha sido aceptada en tiempo',
    'Administration': 'Administración',
    'Import Customers': 'Importar clientes',
    'Import': 'Importar',
    'Download PD Admin App': 'Descargar App PD Admin',
    'Sales by ticket': 'Ventas por ticket',
    'No date Invoices': 'Facturas sin fecha',
    '# of Branch Office': '# de oficina',
    'Last Invoice Created': 'Última Factura Creada',
    'Last Invoice Folio': 'Folio Última factura',
    'Invoice Error': 'Error de factura',
    'Invoiced Deliveries': 'Envíos facturados',
    'Web Orders': 'Ordenes web',
    'Last Payment Date': 'Última fecha de pago',
    'Last Payment Amount': 'Última cantidad de pago',
    'Next Payment Date': 'Siguiente fecha de pago',
    'Next Payment Amount': 'Siguiente cantidad de pago',
    'Cutoff Date': 'Fecha de corte de servicio',
    'Start Service Date': 'Fecha de inicio de servicio',
    'End Service Date': 'Fecha de final de servicio',
    'Countdown': 'Días para pagar',
    'Overdue': 'Días pasados de pago',
    'Payment Error': 'Error de pago',
    'Payment Reject Reason': 'Razón de Error de pago',
    'Contact Phone': 'Número de contacto',
    'Payment Date': 'Fecha de pago',
    'Select all': 'Seleccionar todo',
    '@itemsSelected Products': '@itemsSelected Productos',

    'Sales per day': 'Ventas por día',
    'Total commission': 'Comisión total',
    'Integration platform': 'Plataforma de integración',
    'Net total': 'Total neto',
    'Gross total': 'Total bruto',
    'Average sales': 'Venta promedio',
    'Please select an integration platform to display the missing reports': 'Selecciona una plataforma de integración para mostrar los reportes faltantes',

    'You are being redirected to the kiosk': 'Estás siendo redirigido al kiosco',
    'Order total should be higher than or equal to delivery cost.': 'El costo de la orden debe ser mayor o igual al costo de envío.',
    'Default tip': 'Propina determinada',
    'Pidedirecto tip as percentage enabled': 'Propina en pidedirecto como porcentaje',
    'In ecommerce shows the tip as percentage': 'En el ecommerce muestra la propina como porcentaje',
    'Select the tip percentage to set it as default': 'Seleccione el porcentaje de propina para establecerlo como predeterminado',

    'PaymentTerminalProviders.QPAY': 'QPAY',
    'PaymentTerminalProviders.OCA': 'OCA',

    'Order cancelled. Check your orders!': 'Orden cancelada. ¡Revisa tus pedidos!',

    'The percentage must be 10% or 22%': 'El porcentaje debe ser 10% o 22%',
    'CreatePrinterSteps.PRINTER_TYPE': 'Tipo de impresora',
    'CreatePrinterSteps.PRINTER_NAME': 'Nombre',
    'CreatePrinterSteps.IP_ADDRESS': 'Dirección IP',
    'CreatePrinterSteps.PORT': 'Puerto',
    'CreatePrinterSteps.SERIAL_NUMBER': 'Número Serial',
    'CreatePrinterSteps.DOWNLOAD_DRIVERS': 'Descargar drivers',
    'CreatePrinterSteps.PRINTER_BRAND': 'Marca',
    'CreatePrinterSteps.CHANNELS': 'Canal',
    'CreatePrinterSteps.PRINTER_PAPER_SIZE': 'Tamaño del papel',
    'CreatePrinterSteps.PRINTER_CONTENT': 'Contenido',
    'CreatePrinterSteps.CONFIRMATION': 'Confirmar',

    'Integration orders': 'Órdenes de integración',
    'Send order completed webhook for returned orders': 'Enviar webhook de pedido completado para pedidos devueltos',
    'Enable this to send order completed webhook for returned orders': 'Habilita esto para enviar webhook de pedido completado para pedidos devueltos',
    'Custom tip': 'Propina personalizada',
    'Horizontal promotion': 'Promoción horizontal',
    'Vertical promotion': 'Promoción vertical',
    'Drag or upload an image here. 1080x1920px SVG, PNG or JPG format': 'Arrastra o sube una imagen aquí. 1080x1920px formato SVG, PNG o JPG',
    'Drag or upload an image here. 1920x1080px SVG, PNG or JPG format': 'Arrastra o sube una imagen aquí. 1920x1080px formato SVG, PNG o JPG',
    'Set a custom tip option when tip as percentage setting is enabled': 'Configura una propina custom cuando la configuración propina como porcentaje está habilitada',
    'Change group name': 'Cambiar nombre del grupo',
    'Change device group name': 'Cambiar nombre del grupo de dispositivos',
    'Add device': 'Agregar dispositivo',
    'Add device to @deviceGroupName': 'Agregar dispositivo a @deviceGroupName',
    'Add device to this group': 'Agregar dispositivo a este grupo',
    'Remove device group': 'Eliminar grupo',
    'Select the device to add': 'Selecciona el dispositivo a agregar',
    'Are you sure you want to remove this device from the group?': '¿Estás seguro de eliminar este dispositivo del grupo?',
    'Remove @deviceName': 'Eliminar @deviceName',
    'Internet connection': 'Conexión a internet',
    'No internet connection': 'Sin conexión a internet',
    'Mute sound': 'Silenciar sonido',
    'Unmute sound': 'Activar sonido',
    'Sign out pos user': 'Cerrar sesión usuario de pos',
    'Integrations issue': 'Problema con integraciones',
    'Show new restaurant administration Payment Methods': 'Mostrar nueva administración restaurante Métodos de Pago',
    'Enable this method': 'Habilita este método',
    'Cash payment': 'Pago en efectivo',
    'Payment type': 'Tipo de pago',
    'Pencil': 'Lapiz',

    '14 day projection': 'Proyección a 14 días',
    'Average daily sales': 'Promedio órdenes diarias',
    "Tomorrow's projection": 'Proyección día siguiente',
    'Emails to notify restaurant integrations issues': 'Correos para notificar problemas en las integraciones del restaurante',
    'New group': 'Nuevo grupo',

    'Mark tip as service fee enabled': 'Marcar propina como costo de servicio habilitado',
    'In ecommerce will change the "tip" label for "service"': 'En ecommerce va a cambiar el texto "propina" por "servicio"',
    'PideDirecto tip enabled': 'Propina PideDirecto habilitada',
    'Enable changes on driver tip in ecommerce': 'Habilita cambios en propina en ecommerce',

    'Icon for method': 'Icono para método',
    'Channels where you accept payments with this method': 'Canales en los que aceptas pagos con este método',
    'Order types where you accept payments with this method': 'Tipos de pedidos en los que acepta pagos con este método',

    '15 day projection': 'Proyección de 15 días',
    'Switch enable payment method': 'Cambiar habilitar método de pago',

    'Channels that accept cash payments': 'Canales en los que aceptas pagos con efectivo',
    'Order types that accept cash payments': 'Tipos de ordenes en los que aceptas pagos con efectivo',
    'If left blank it will be all channels': 'Si se deja en blanco serán todos los canales',
    'Type of order in which you accept cash payments': 'Tipo de orden en el que aceptas pagos con efectivo',
    '@paymentMethod payment': 'Pago con @paymentMethod',

    'PromotionTypes.BUY_ONE_GET_ONE': 'Compra uno llevate uno (BOGO)',
    'Promotion type': 'Tipo de promoción',
    'Items to get': 'Artículos a recibir',
    'Items to pay': 'Artículos a pagar',
    'Select affected products': 'Seleccione productos afectados',
    'Promotions valid from': 'Promoción válida desde',
    'Promotions valid until': 'Promoción válida hasta',
    'Available days': 'Días disponibles',
    'Create promotion': 'Crea una promoción',
    'Remove @productName': 'Eliminar @productName',
    'Sales channels are required': 'Canales de venta es requerido',
    'Sales channels': 'Canales de venta',
    'Pause promotion': 'Pausar promoción',
    'Change promotion': 'Editar promoción',
    'Remove promotions': 'Elmiminar promotions',
    'Are you sure to remove the selected promotions?': '¿Estás seguro de eliminar las promociones seleccionadas?',
    'Remove selected promotions': 'Eliminar promotions seleccionadas',
    'This time is temporary, it will only be activated at the established time': 'Este tiempo es temporal, solo se activará a la hora establecida',

    'My External Own Fleet': 'Mi flotilla externa',
    'Invoice just the delivery cost, not the total order': 'Facture solo el costo de envío, no el pedido total',
    'When the delivery distance is longer than in the setting Driver is additional long-distance delivery earnings start from kilometer. If not set, the default value used is set in Settings.': 'Cuando la distancia de entrega sea más larga que en la configuración "Las ganancias adicionales de entrega de larga distancia del conductor comienzan desde el kilómetro". Si no se establece, el valor predeterminado utilizado se configura en Configuración.',
    'Rappi Cargo is not available, please contact support': 'Rappi Cargo no está disponible, por favor contacta a soporte',
    'Delivery Zones': 'Zonas de entrega',
    'Enable Rappi Turbo': 'Habilitar Rappi Turbo',
    'Rappi turbo': 'Rappi turbo',

    'Available menu': 'Menú disponible',

    'Hide notes in products': 'Ocultar notas en productos',
    'Disables field to add notes in products on ecommerce.': 'Deshabilita el campo para agregar notas a productos en ecommerce.',
    'Not authorized to charge orders': 'No autorizado para cobrar órdenes',

    'UnitOfMeasurements.CURRENCY': 'Moneda',
    'Recipe unit': 'Unidad de receta',

    'Add new address': 'Agregar nueva dirección',
    'Select address': 'Selecciona una dirección',
    'PedidosYa Order Code Id:': 'PedidosYa Codigo Orden Id:',

    'Psp enabled': 'Habilitar psp',
    '@commission% Commission': '@commission% Comisión',

    'Assign preparation time to order #@shortOrderId': 'Asigna el tiempo de preparacion para la orden #@shortOrderId',
    'Preparation time manually for orders': 'Tiempo de preparacion manual para las ordenes',
    'If enabled, orders when accepted will display a dialog to set its preparation time': 'Si esta activo, las ordenes al aceptarse van a mostrar un dialogo para asignar el tiempo de preparacion',

    'Type of order in which you accept card on delivery payments': 'Tipo de orden en el que aceptas pagos con tarjeta en la entrega',
    'Type of channels in which they accept payments with reference': 'Tipo de canales en los que aceptan pagos con referencia',
    'You are about to delete the payment method “@restaurantPaymentMethod”, are you sure you want to continue?': 'Estás a punto de eliminar el método de pago “@restaurantPaymentMethod”, ¿estás seguro de querer continuar?',
    'Yes I want to delete it': 'Sí, quiero eliminarlo',

    'Seconds to accept': 'Segundos para aceptar',
    'Show alert type': 'Tipo de alerta a mostrar',
    'One Time': 'Una vez',
    'Recurrent': 'Recurrente',

    'With technology from Ambit.la': 'Con tecnología de ambit.la',
    'Alert Dialogs': 'Alerta de Dialogos',
    'Custom Alert Dialogs': 'Alerta de Dialogos Personalizada',

    'External monitor': 'Monitor externo',
    'Banner for external monitor is required': 'El banner para el monitor externo es requerido',
    'Enable external monitor': 'Habilitar monitor externo',
    'Enable touch screen': 'Habilitar pantalla touch',
    'Enable custom tip': 'Habilitar propina personalizada',
    'Promotions banner': 'Banner de promociones',
    'Drag an image here or upload a file.  16:9 o 9:16 format: SVG, PNG or JP': 'Arrastra una imagen hasta aquí o sube un archivo.  16:9 o 9:16 formato: SVG, PNG o JP',
    'Add a customized tip, by default 10, 15 and 20 percent will be displayed': 'Agrega una propina personalizada, por defecto se mostrarán 10, 15 y 20 porciento',

    'This table is served by another waiter.': 'Esta mesa es atendida por otro mesero.',
    'Online payment': 'Pago online',

    'Show button to open cash register': 'Mostrar botón para abrir caja registradora',
    'Activate this option if you want to show a button to open the cash register': 'Activa esta opción si quieres mostrar un botón para abrir la caja registradora',
    'Open cash register': 'Abrir caja registradora',
    'Key': 'Llave',

    'Verify the printer is active in your device: Settings > Connected devices > Printer > Default printer service > Active': 'Verifica que la impresora esté activada en tu dispositivo: Ajustes > Dispositivos conectados > Impresión > Servicio de impresión predeterminado > Activo.',
    '@percent% of @menuItems': '@percent% de @menuItems',
    '@percent% discount of @menuItems': '@percent% descuento de @menuItems',
    'Promotion description': 'Descripción de promoción',

    'Hide Delivery Earnings From Driver': 'Esconder ganancias para el repartidor',

    'DeliveryManagerTypes.DELIVERY_MANAGER_LINEAL': 'Delivery manager lineal',
    'DeliveryManagerTypes.DELIVERY_MANAGER_CYCLICAL': 'Delivery manager cíclico',
    'DeliveryManagerTypes.DELIVERY_MANAGER_ORCHESTRATOR': 'Delivery manager orquestador',
    'Delivery type': 'Tipo de entrega',
    'Show ongoing orders in orders sales report': 'Contabilizar órdenes activas en reporte de ventas',
    'Enable this option to show the ongoing orders totals (not only completed) in orders sales report inside Orders and All orders page': 'Habilita esta opción para mostrar los totales de las órdenes activas (no solo las completadas) en los reportes de ventas de Órdenes y Todos Los Pedidos',
    'Pendings': 'Pendientes',
    'Totals': 'Totales',
    'Schedule order': 'Planear orden',
    'Undo schedule': 'Deshacer planeación',
    'Planned order': 'Orden planeada',
    'Please, select a date starting from today.': 'Por favor, elige una fecha a partir de hoy.',
    'Payment can not be greater than total': 'El pago no puede ser mayor al total',
    'Print withdrawal and deposit tickets': 'Imprimir ticket de retiros y depósitos',
    'Enable this option to print a ticket when making a deposit or withdrawal with the details of the transaction': 'Habilita esta opción para imprimir un ticket al realizar un depisito o un retiro con los detalles de la transacción',

    'Menu category families enabled': 'Familias del menú habilitado',
    'Manage menu families': 'Administrar familias del menú',
    'Remove @menuCategoryFamilyName': 'Eliminar @menuCategoryFamilyName',
    'Remove menu family': 'Eliminar familia del menú',
    'Are you sure you want to remove menu family @menuCategoryFamilyName?': '¿Estás seguro de querer eliminar la familia del menú @menuCategoryFamilyName?',
    'Manage families': 'Administrar familias',

    'The distance must be equal to or less than 8 km': 'La distancia debe ser igual o menor a 8km',

    'Supply not found': 'Producto no encontrado',
    'The buy order contains items that no longer exist and will be omitted': 'La orden de compra contiene elementos que ya no existen y se omitiran',
    'Error with supplies': 'Error con isumos',
    'The buy order includes items that are no longer available and will be omitted': 'La orden de compra incluye insumos no disponibles que serán omitidos',
    'Yes, continue': 'Si, continuar',
    'Send @smsNumber SMS will cost @smsCost': 'Enviar @smsNumber SMS costará @smsCost',
    'Do you want to continue?': '¿Deseas continuar?',
    'Enable quick action will send @smsNumber SMS and will cost @smsCost': 'Habilitar quick action enviará @smsNumber y costará @smsCost',
    'DeviceTypes.MOBILE': 'Móvil',
    'DeviceTypes.MACINTOSH': 'Macintosh',
    'DeviceTypes.TABLET': 'Tablet',
    'DeviceTypes.PC': 'PC',
    'DeviceTypes.UNKNOWN': 'Desconocido',

    'CustomerOriginTypes.ORGANIC': 'Orgánico',
    'CustomerOriginTypes.UNKNOWN': 'Desconocido',
    'CustomerOriginTypes.FACEBOOK': 'Facebook',
    'CustomerOriginTypes.INSTAGRAM': 'Instagram',
    'CustomerOriginTypes.TWITTER': 'Twitter',

    'Top seller': 'Más vendido',

    'SurveyAnswers.FOOD_WAS_COLD': 'La comida estaba fría',
    'SurveyAnswers.MISSING_OR_INCORRECT_ITEMS': 'Productos faltantes o incorrectos',
    'SurveyAnswers.FOOD_WAS_NOT_PREPARED': 'La comida no estaba bien preparada',
    'SurveyAnswers.FOOD_ARRIVED_LATE': 'Llegó muy tarde',
    'SurveyAnswers.CONFUSED_PICK_UP_PLACE': 'Confusión en el punto de recolección',
    'SurveyAnswers.BAD_SERVICE': 'Mal servicio a cliente',
    'SurveyAnswers.PUNCTUALITY': 'Puntualidad',
    'SurveyAnswers.APPLICATION': 'La aplicación',
    'SurveyAnswers.FOOD': 'La comida',
    'SurveyAnswers.PRESENTATION': 'Presentación',
    'SurveyAnswers.SERVICE': 'Buen servicio a cliente',

    'Most common answer': 'Respuesta más común',

    'Heat map delivery orders enabled': 'Mapa de calor de ventas de delivery habilitado',
    'By zone': 'Por zona',
    'Total orders': 'Pedidos totales',
    'Total customers': 'Clientes totales',
    'Average time': 'Tiempo promedio',

    'The name must be less than 15 characters': 'El nombre debe ser menor a 15 caracteres',

    'There are no promotion to show': 'No hay promociones para mostrar',
    'Dates': 'Fechas',
    'Manage promotions enabled': 'Administración de promociones habilitada',
    'Products are required': 'Productos son requeridos',

    'Restaurant service fee enabled': 'Costo de servicio del restaurante habilitado',
    'Allows to restaurant to charge a service fee in ecommerce and app': 'Permite al restaurant cobrar un costo por servicio en ecommerce y app',
    'Restaurant service fee rate': 'Procentaje de costo de servicio',
    'Directo service fee @rate%:': 'Costo de servicio de Directo @rate%:',
    'Service fee @rate%:': 'Costo de servicio @rate%:',
    'Hide delivery estimated time enabled': 'Ocultar tiempo de entrega estimado habilitado',
    'Estimated delivery time': 'Entrega estimada',
    'Average cost': 'Costo promedio',
    'Not calculated value': 'Valor no calculado',
    'Delivery tabulator': 'Tabulador de envíos',
    'Cash status': 'Estado de efectivo',
    'Card status': 'Estado de tarjeta',

    'Promotions discount': 'Descuento de promociones',
    'Promotions discount:': 'Descuento de promociones:',
    'Loading restaurant': 'Cargando restaurante',

    'Product added': 'Producto agregado',

    'Do not show promotion banner in order tracking': 'No mostrar banner de promoción en seguimiento de pedidos',
    'Zendesk ticket': 'Ticket de Zendesk',
    'OrderIncidentTypes.ORDER_NOT_RECEIVED': 'Orden no recibida',
    'OrderIncidentTypes.ORDER_NOT_RETURNED_TO_RESTAURANT': 'Orden no regresada a tienda',
    'OrderIncidentTypes.INCORRECT_ORDER': 'Pedido incorrecto',
    'OrderIncidentTypes.INCOMPLETE_ORDER': 'Pedido incompleto',
    'OrderIncidentTypes.ORDER_CANCELLED_AND_DELIVERED': 'Orden cancelada y entregada',
    'OrderIncidentTypes.ORDER_CHANGE_STOLEN': 'Robo de cambio',
    'OrderIncidentTypes.DAMAGED_ORDER_ITEM_RETURNED_TO_RESTAURANT': 'Producto dañado regresado a tienda',
    'OrderIncidentTypes.DAMAGED_ORDER_ITEMS': 'Productos dañados',
    'OrderIncidentTypes.COURTESY': 'Cortesía',
    'OrderIncidentTypes.RESTAURANT_WASTE': 'Merma en restaurante',
    'OrderIncidentTypes.ORDER_DELIVERY_DELAYED': 'Demora de entrega de repartidor',
    'OrderIncidentTypes.ORDER_REJECTED_BY_CUSTOMER': 'Cliente lo rechaza',
    'OrderIncidentTypes.BUG': 'Bug',
    'OrderIncidentTypes.ADMIN_APPROVAL': 'Aprobado por admin',
    'OrderIncidentTypes.MONEY_RECOVERED_FROMM_DRIVER': 'Dinero recuperado - Driver',

    'Incident reason': 'Motivo de siniestro',
    'Evidence': 'Evidencia',
    'Drag or upload an image here': 'Arrastra o sube una imagen aquí',
    'Register order with incident': 'Registrar orden con siniestro',
    'Refund amount': 'Cantidad de reembolso',
    'The incident reason is not valid for this order': 'El motivo de siniestro no es válido para esta orden',
    'Register incident': 'Registrar siniestro',
    'Order incident': 'Orden siniestrada',
    'This field should be the whole order id': 'Este campo debe ser el id completo de la orden',

    'Cash fee @amount': 'Comisión efectivo @amount',
    'Cash fee': 'Comisión efectivo',
    'Total cash fee': 'Comisión efectivo total',

    'Family': 'Familia',
    'Others': 'Otras',
    'Sales by family': 'Ventas por familia',
    'Best seller': 'Mejor vendido',

    'Enable cash for delivery provider @deliveryProvider': 'Habilitar efectivo para el proveedor de envío @deliveryProvider',
    'Enable card for delivery provider @deliveryProvider': 'Habilitar tarjeta para el proveedor de envío @deliveryProvider',
    'Order Heatmap': 'Mapa de Calor de Órdenes',

    'Manager User': 'Usuario',
    'Please select items.': 'Por favor selecciona productos.',
    'Select items': 'Selecciona los productos',
    'Orders / All orders': 'Órdenes / Todos los pedidos',
    'Edit customer': 'Editar cliente',
    'Company name': 'Nombre de la compañía',

    'RefundMethods.CREDITS': 'Créditos',
    'RefundMethods.CREDIT_CARD': 'Tarjeta',
    'RefundMethods.PAYROLL': 'Nómina',
    'Cancel subscription': 'Cancelar subscripción',
    'Cancel email and sms notifications': 'Cancela el envío de notificaciones por correo y sms',
    'Customer ban': 'Bloqueo de cliente',
    'Customer Id': 'Cliente Id',

    'Uber Eats auto Open when closed': 'Abrir automaticamente tienda de uber cuando es cerrada',
    'Activate this option to auto open uber eats store when closed by uber': 'Activa esta opción para abrir la tienda de uber eats automaticamente, cuando es cerrada por Uber',
    'Delivery cost paid by restaurant': 'Costo de envío pagado por el restaurante',
    'Request payment reference': 'Solicitar referencia de pago',
    'Card reference': 'Referencia de tarjeta',
    'Comission @paymentMethod': 'Comisión @paymentMethod',
    'Reference': 'Referencia',
    'Key reference': 'Clave',

    'Digital Seal linked to @restaurantName': 'Sello digital vinculado a @restaurantName',

    '@dateAt by @doneBy': '@dateAt por @doneBy',
    'Agent': 'Agente',
    'View image': 'Ver imagen',
    'Without file': 'Sin archivo',
    'Refresh order incidents': 'Actualizar órdenes siniestradas',
    'Order incidents': 'Órdenes siniestradas',
    'Order incident image for order @orderId': 'Imagen de orden siniestrada @orderId',
    'Order Id @orderId': 'Orden Id @orderId',
    'Cards view': 'Vista de tarjetas',
    'Table view': 'Vista de tabla',
    'Send order webhooks for pos orders enabled': 'Enviar webhooks de órdenes de pos habilitado',
    'Is not possible to use it with pending orders (POS)': 'No es posible usarse con permiso para guardar órdenes pendientes (POS)',
    'Do not allow to close day with orders not invoiced': 'No permitir cerrar el día con órdenes no facturadas',
    'Do not allow to close day meanwhile there are completed orders but not invoiced.': 'No permite cerrar el día mientras haya órdenes completadas que no han sido facturadas.',
    'Is not possible to close day due to there are orders not invoiced.': 'No es posible cerrar el día ya que tiene órdenes por facturar',
    'Promo code banner': 'Banner de promo codes',
    'Shows active promo codes banner in ecommerce': 'Muestra los promo code activos en el ecommerce',

    'Please note that the subtotal is the maximum amount that can be refunded in case of any incident or order theft': 'Recuerda que el costo del pedido es la cantidad máxima que se podra reembolsar en caso de algún siniestro o robo de orden',
    'Create promotion in menu': 'Crear promoción en el menú',

    'Should print rappi QRCode on the tickets': 'Imprimir codigo QR de rappi en el ticket de la orden',
    'Show QR': 'Mostrar QR',
    'Should use rappi QRCode on the orders': 'Usar codigo QR de rappi en las ordenes',

    'Do you want to add tip?': '¿Deseas agregar propina?',
    'Do not add': 'No agregar',
    'Yes, add': 'Sí, agregar',

    'State': 'Estado',
    'Sale': 'Venta',

    'Generate automatic invoice': 'Generar factura automática',
    'Activate this field if you want an automatic invoice to be generated when paying for your subscription': 'Activa este campo si deseas que se genere una factura automática al realizar el pago de tu suscripción',
    'Automatic invoice': 'Factura automática',
    'Activate this option to automatically invoice orders': 'Activa esta opción para facturar automáticamente las órdenes',
    'Payment Methods created': 'Métodos de pago creados',

    'No active printers to select': 'No hay impresoras activas para seleccionar',

    'Allow request drivers who do not belong to my own fleet enabled': 'Permitir solicitar conductores que no pertenezcan a mi propia flotilla',
    'Total Returned Orders Delivery Costs': 'Costos de envío por órdenes devueltas',
    'Fixed delivery cost': 'Costo de envío fijo',

    'States.QUERETARO': 'Queretaro',
    'States.AGUASCALIENTES': 'Aguascalientes',
    'States.BAJA_CALIFORNIA': 'Baja California',
    'States.BAJA_CALIFORNIA_SUR': 'Baja California sur',
    'States.CAMPECHE': 'Campeche',
    'States.CDMX': 'Ciudad de México',
    'States.CHIAPAS': 'Chiapas',
    'States.CHIHUAHUA': 'Chihuahua',
    'States.COAHUILA': 'Coahuila',
    'States.COLIMA': 'Colima',
    'States.DURANGO': 'Durango',
    'States.GUANAJUATO': 'Guanajuato',
    'States.GUERRERO': 'Guerrero',
    'States.HIDALGO': 'Hidalgo',
    'States.JALISCO': 'Jalisco',
    'States.MICHOACAN': 'Michoacán',
    'States.MORELOS': 'Morelos',
    'States.ESTADO_DE_MEXICO': 'Estado de México',
    'States.NAYARIT': 'Nayarit',
    'States.NUEVO_LEON': 'Nuevo León',
    'States.OAXACA': 'Oaxaca',
    'States.PUEBLA': 'Puebla',
    'States.QUINTANA_ROO': 'Quintana Roo',
    'States.SAN_LUIS_POTOSI': 'San Luis Potosí',
    'States.SINALOA': 'Sinaloa',
    'States.SONORA': 'Sonora',
    'States.TABASCO': 'Tabasco',
    'States.TAMAULIPAS': 'Tamaulipas',
    'States.TLAXCALA': 'Tlaxcala',
    'States.VERACRUZ': 'Veracruz',
    'States.YUCATAN': 'Yucatán',
    'States.ZACATECAS': 'Zacatecas',

    'Order is already paid': 'La orden ya ha sido pagada',
    'Set Temporarily Offline All Delivery Orders': 'Establecer temporalmente fuera de servicio todas las ordenes de envio',

    'Mark order ready': 'Marcar orden lista',
    'Mark order Delivered': 'Marcar orden Entregada',
    'Received Payments': 'Pagos recibidos',

    'Remove Method': 'Eliminar método',
    'Close day': 'Cierre de día',
    'Provider': 'Proveedor de envíos',
    'Charge and Invoice RUC': 'Cobrar y facturar RUC',

    'Reprint': 'Reimprimir',
    'Reprint Command': 'Reimprimir comanda',

    'PrinterBrands.SUNMI': 'Sunmi',

    'Ongoing orders': 'Órdenes abiertas',
    'Select a user': 'Selecciona un usuario',

    'Protection cost for manual orders': 'Costo de protección para órdenes manuales',
    'The order can only be protected for a maximum amount of @amount': 'La orden solo puede ser protegida por un monto maximo de @amount pesos',
    'Manual orders already paid by the customer enabled': 'Ordenes manuales ya pagadas por el cliente habilitado',
    'Enable this option to mark if a manual order has already been paid by the customer': 'Activa esta opción para poder marcar si una orden manual ya ha sido pagada por el cliente',
    'Order already paid by the customer checkbox as checked by default enabled': 'Checkbox en ordenes manuales ya pagadas por el cliente seleccionado por defecto habilitado',
    'Enable to mark Order already paid by the customer checkbox as checked by default in manual orders modal': 'Activa esta opción para marcar el checkbox Ordenes manuales ya pagadas por el cliente como seleccionado por defecto en el modal de órdenes manuales',

    'Sales per day and hour': 'Ventas por día y hora',

    'Frequent Survey Answers': 'Respuestas Frecuentes en Encuestas',

    'RolePermissions.REPRINT_ORDER_COMMAND': 'Reimiprimir comanda',
    'RolePermissions.PRINT_PARTIAL_SHIFT_CASH_REGISTER_REPORT': 'Imprimir corte parcial de caja',
    'RolePermissions.CHARGE_ORDERS': 'Realizar cobros',
    'RolePermissions.VIEW_ONLY_ASSIGNED_ORDERS': 'Solo ver órdenes asignadas',
    'RolePermissions.MOVE_ORDER_ITEMS_BETWEEN_ORDERS': 'Mover producto a otra órden',
    'RolePermissions.HEAT_MAP_DELIVERY_ORDERS_REPORT': 'Mapa de calor de ventas de delivery',

    'Partial cut': 'Corte parcial',

    'By Payment Method': 'Por método de pago',
    'Summary of tips': 'Resumen de propinas',

    'Add Temporary Preparation time Enable (Rush hour)': 'Habilitar tiempo de preparación temporal (Hora pico)',

    'Complete and Invoice': 'Completar y facturar',
    'Complete with RUC': 'Completar con RUC',

    'Edit @paymentMethod': 'Editar @paymentMethod',
    'Create Payment Method': 'Crear Metodo de pago',

    'Channels that accept @payment payments': 'Canales en los que aceptas pagos con @payment',
    'Order types that accept @payment payments': 'Tipos de ordenes en los que aceptas pagos con @payment',

    'Enable': 'Habilitar',
    'When adding a discount to the order, it cannot be invoiced since the products include excise taxes': 'Al agregar un descuento a la orden, no se puede facturar ya que los productos incluyen impuestos especiales',

    'The 8% VAT is only applicable for businesses in the border area. Make sure you are registered in this way with SAT to avoid billing errors': 'El IVA del 8% solo es aplicable para negocios en la zona fronteriza. Asegúrate de estar registrado de esta manera con el SAT para evitar errores de facturación.',

    'Food in general, non-basic': 'Alimentos en general, no básicos',
    'Beverages with alcohol content up to 14° G.L.': 'Bebidas con graduación alcohólica de hasta 14° G.L.',
    'Beverages with alcohol content between 14° and 20° G.L.': 'Bebidas con graduación alcohólica entre 14° y 20° G.L.',
    'Beverages with alcohol content over 20° G.L.': 'Bebidas con graduación alcohólica mayor a 20° G.L.',
    'Cigars and cigars': 'Cigarros y puros',

    'Number of Manual Orders Protected': 'Número de ordenes manuales protegidas',
    'Directo Protection Amount': 'Monto de Directo Protection',

    'The expected amounts for the integrations will be shown in the cash cut-off and closing of the day and will be displayed in the closing of the day report': 'Se mostraran en el corte de caja y cierre de día los montos esperados para las integraciones y se visualizara en el reporte de cierre de día',
    'Include integrations in cash cut  and day-end closing': 'Incluir integraciones en corte de caja y cierre de dia',

    'MexicanTaxes.IVA_RET': 'IVA Ret',
    'MexicanTaxes.IVA_EXENTO': 'IVA Exento',

    'Download and share with them this QR, with which they can immediately access the satisfaction survey.': 'Descarga y comparte con ellos este QR, con el podrán acceder de inmediato a la encuesta de satisfacción.',
    'Your customer’s opinion is very valuable': 'La opinión de tus clientes es muy valiosa',
    'Copy Url': 'Copiar Url',
    'Download QR': 'Descargar QR',

    'Show when driver arrive to store': 'Mostrar cuando el repartidor haya llegado a la tienda',
    'Enable to show when driver arrive to store on order tracking page': 'Habilitar para mostrar cuando el repartidor haya llegado a la tienda en la pagina de seguimiento de la orden',
    'Order Taxes': 'Impuestos de Orden',
    'Total Tax': 'Impuesto Total',
    'Total IVA': 'IVA Total',
    'Total ISR': 'ISR Total',
    'Total IEPS': 'IEPS Total',
    'Total IVA RET': 'IVA Ret Total',
    'Total IVA EXENTO': 'IVA Exento Total',
    'Total IVA TASA BASICA': 'IVA Tasa másica Total',
    'Total IVA TASA MINIMA': 'IVA Tasa mínima Total',

    'It will not show the restaurant phone number on the order tracking page.': 'No se mostrará el número del teléfono del restaurante en la página de seguimiento de la orden',
    'Hide the restaurant phone number on the order tracking page enabled': 'Ocultar el número del teléfono del restaurante en la página de seguimiento de la orden habilitado',

    'Waiting time must be greater than or equal to 10 seconds': 'El tiempo de espera debe ser mayor o igual a 10 segundos',

    'Demo Order': 'Orden de Prueba',

    'SmsServiceProviders.MENSAJERO_DIRECTO': 'Mensajero Directo',
    'SmsServiceProviders.TELE_QUANTUM': 'Tele Quantum',

    'SubscriptionTabName.NEW': 'Nuevas',
    'SubscriptionTabName.PAID': 'Pagadas',
    'SubscriptionTabName.PENDING': 'Pendientes',
    'SubscriptionTabName.CANCELLED': 'Canceladas',
    'SubscriptionTabName.TEST': 'Prueba',
    'SubscriptionTabName.INACTIVE': 'Inactivas',
    'SubscriptionTabName.PAY_PER_USE': 'Pay Per Use',

    'Invoice Peru': 'Facturacion Peru',
    'Upload to Nubefact': 'Subir a Nubefact',

    'Directo Protection': 'Orden protegida',
    'Check this option if you want to protect your order': 'Marca esta opción si requieres proteger esta orden',
    'Directo protection is paid by customer': 'La protección de la orden la paga el cliente',
    'Check this option if you want to customer pays the Directo Protection': 'Marca esta opción si requieres que el cliente pague la protección de la orden',
    'Directo Protection (Paid by customer):': 'Protección de la orden (Pagada por el cliente):',
    'Manual order already paid by customer': 'Pedido ya pagado por comensal',
    'Check this option if the order has already been paid by the customer': 'Marca esta opción si la orden ya ha sido pagada por el cliente',

    'This name will appear to identify the type of delivery e.g. room, classroom, living room etc.': 'Este nombre aparecerá para identificar el tipo de entrega ej. habitación, aula, salón etc.',
    'Set a fixed cost for room service deliveries': 'Establece un costo fijo para los envíos de servicio al cuarto',

    'UnitsOfMeasurement.CURRENCY': 'Moneda',

    'KILOGRAM': 'Kilogramo',
    'GRAM': 'Gramo',
    'LITRE': 'Litro',
    'MILLILITER': 'Mililitro',
    'BOX': 'Caja',
    'BAG': 'Bolsa',
    'PIECE': 'Pieza',
    'PACKAGE': 'Paquete',
    'STICK': 'Barra',
    'ROLL': 'Rollo',
    'CAN': 'Lata',
    'SLICE': 'Rebanada',

    'PrinterTypes.SDK': 'SDK',

    'Clabe used for subscriber of users with Elevate systems': 'Clabe utilizada para el pago de subscripciones de usuarios con Elevate Systems',

    'Set a protection cost for orders created manually from the admin': 'Establecer un costo de protección para los pedidos que se crean manualmente desde el administrador',
    'Your order will be protected for an amount up to @amount': 'Tu orden sera protegida por un monto de hasta @amount pesos',

    'Each item in your order will be printed individually.': 'Cada artículo de tu orden será impreso individualmente.',

    'CommandSections.SPLIT_GROUPED_ORDER_ITEMS': 'Imprimir artículos por separado',
    'TicketSections.CANCELLED_ITEMS': 'Productos cancelados',
    'TicketSections.SPLIT_GROUPED_ORDER_ITEMS': 'Imprimir artículos por separado',

    'Management': 'Gestión',
    'Orders canceled by waiter': 'Órdenes canceladas por mesero',
    'Select the products to move': 'Selecciona los productos a mover',
    'Select target table': 'Selecciona la órden destino',
    'Move': 'Mover',
    'Step @step': 'Paso @step',
    'Move product': 'Mover producto',
    'Orders updated': 'Órdenes actualizadas',
    'There are no orders to move the products': 'No hay órdenes para mover los productos',
    'A product cannot be moved more than 2 times': 'Un producto no puede ser movido más de 2 veces',

    'Enable custom delivery zones': 'Habilitar Zonas de entrega personalizadas',
    'Enable New Device Management': 'Habilitar Administración de dispositivos',
    'Show orders at preparation time': 'Mostrar pedidos al iniciar preparación',
    'Orders will be only shown when it is time to start preparing them (pickup time - preparation time). Does not apply neither for integrations nor planned manual orders.': 'Solo se mostraran los pedidos cuando sea el momento de comenzar a prepararlos (hora de recogida - tiempo de preparación en minutos). No aplica a integraciones ni órdenes planificadas manualmente.',
    'Delivery Orchestrator Fee': 'Delivery Orchestrator Cuota',
    'Validation code: @validationCode': 'Codigo de validacion: @validationCode',
    'Should print QRCode on the tickets': 'Imprimir codigo QR en el ticket de la orden',
    'Create service area': 'Crear área de servicio',
    'Edit service area': 'Editar área de servicio',
    'Service areas': 'Áreas de servicio',
    'Add service area': 'Agregar área de servicio',
    'Remove service area': 'Remover area de servicio',
    'Are you sure you want to remove are service "@restaurantAreaServiceName"': '¿Estás seguro que deseas remover el area de servicio "@restaurantAreaServiceName"?',

    'Supplies imported successfully': 'Insumos importados correctamente',
    'Import recipes From CSV File': 'Importar recetas desde archivo CSV',
    'There was a problem with some of the recipes imported': 'Hubo un problema con algunas de las recetas importadas',
    'There was a problem with some of the supplies imported': 'Hubo un problema con algunas de los insumos importados',
    'the sample template': 'el template de ejemplo',
    'Import Error': 'Error en la Importación',
    '@validQuantityData supplies were imported, @invalidQuantityData failed. Download the failed supplies to retry': '@validQuantityData insumos fueron importados, @invalidQuantityData fallaron. Descarga los insumos fallidos para reintentarlo',
    '@validQuantityData supplies were imported, @invalidQuantityData failed. Download the failed recipes to retry': '@validQuantityData insumos fueron importados, @invalidQuantityData fallaron. Descarga las recetas fallidas para reintentarlo',
    'Get file': 'Descargar archivo',
    'Try again': 'Volver a intentar',
    'Import successful! @quantity supplies loaded': '¡Importación exitosa! Se cargaron @quantity insumos ',
    'Import successful! @quantity recipes loaded': '¡Importación exitosa! Se cargaron @quantity recetas ',
    'Upload icon': 'Icono Subir',
    'Service areas enabled': 'Áreas de serivicio habilitadas',

    'Enable contact information': 'Habilitar información de contacto',
    'WhatsApp number': 'Número de WhatsApp',
    'At least 1 contact number is required': 'Por lo menos 1 numero de contacto es requerido',
    'Enable cash payments from ecommerce for take away orders': 'Habilita pagos en efectivo desde el ecommerce en ordenes para llevar',
    'Cash payments for take away orders': 'Pagos en efectivo en ordenes para llevar',

    'It will take some time to close the dialog': 'Esto podrías tomar tiempo no cierres el dialog',
    'Action in progress. Please wait for the menu to finish processing.': 'Acción en curso. Por favor, espera a que el menú termine de procesarse.',
    'Menu copy failed': 'Copia de menu fallida',
    'Average delivery time': 'Tiempo de envio promedio',
    'Average delivery cost': 'Costo de envio promedio',
    'By Product': 'Por producto',
    'Deliveries by Category': 'Entregas por categoria',
    'Daily deliveries by Category': 'Entregas diarias por categoria',
    'Time to deliver': 'Tiempo para entregar',
};
