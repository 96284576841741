import { letseatmanagerApiMethod } from 'src/api/letseatmanagerApi';
import type { ApiSauceResponse } from 'src/api/types/ApiSauceResponse';
import type { OrderId } from 'src/types/Id';

export async function updateOrderTicketRequestedApi(request: ChangeOrderTicketRequestedRequest): ApiSauceResponse<void> {
    return letseatmanagerApiMethod('order/updateOrderTicketRequestedApi', request);
}

type ChangeOrderTicketRequestedRequest = {
    orderId: OrderId;
};
