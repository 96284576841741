import { makeStyles } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import ClearIcon from '@material-ui/icons/Clear';
import * as React from 'react';
import { FormCurrencyNumberStringField } from 'src/components/form/FormCurrencyNumberStringField';
import { FormSelect } from 'src/components/form/FormSelect';
import { FormSwitch } from 'src/components/form/FormSwitch';
import { FormTextField } from 'src/components/form/FormTextField';
import { ModifierTypes } from 'src/constants/ModifierType';
import { translate } from 'src/i18n/translate';
import type { DeprecatedSubModifierVm } from 'src/types/DeprecatedManagerMenuItemVm';

export function SubModifier({
    showHiddenSwitch,
    subModifier,
    loading,
    subModifierIndex,
    subModifierGroupIndex,
    modifierGroupIndex,
    modifierIndex,
    showRemoveButton,
    removeSubModifier,
}: Props): React.ReactElement {
    const classes = useStyles();
    const theme = useTheme();
    const matchMediaQuery = useMediaQuery(theme.breakpoints.down('sm'));

    return (
        <Grid container alignItems='flex-end' spacing={3} style={{ marginBottom: 10 }}>
            <Grid item xs={8} md={showHiddenSwitch ? 4 : 5}>
                <FormTextField
                    name={`modifierGroups.${modifierGroupIndex}.modifiers.${modifierIndex}.subModifierGroups.${subModifierGroupIndex}.subModifiers.${subModifierIndex}.name`}
                    label={translate('Name')}
                    required
                    disabled={loading}
                    defaultValue={subModifier.name}
                />
            </Grid>
            {matchMediaQuery && showRemoveButton && (
                <Grid item xs={4}>
                    <IconButton disabled={loading} aria-label='Remove' onClick={removeSubModifier}>
                        <ClearIcon />
                    </IconButton>
                </Grid>
            )}
            <Grid item xs={12} md={showHiddenSwitch ? 4 : 5}>
                <FormCurrencyNumberStringField
                    name={`modifierGroups.${modifierGroupIndex}.modifiers.${modifierIndex}.subModifierGroups.${subModifierGroupIndex}.subModifiers.${subModifierIndex}.price`}
                    label={translate('Price')}
                    required
                    disabled={loading}
                    defaultValue={subModifier.price}
                />
            </Grid>
            <Grid item xs={8} md={showHiddenSwitch ? 4 : 5}>
                <FormSelect
                    name={`modifierGroups.${modifierGroupIndex}.modifiers.${modifierIndex}.subModifierGroups.${subModifierGroupIndex}.subModifiers.${subModifierIndex}.type`}
                    label={translate('Type')}
                    required
                    defaultValue={subModifier.type ?? ModifierTypes.SINGLE}
                    options={Object.values(ModifierTypes).map((notificationMessageType: any) => ({ label: translate(notificationMessageType), value: notificationMessageType }))}
                    disabled={loading}
                />
            </Grid>
            {showHiddenSwitch && (
                <Grid item xs={4} md={2}>
                    <FormSwitch
                        name={`modifierGroups.${modifierGroupIndex}.modifiers.${modifierIndex}.subModifierGroups.${subModifierGroupIndex}.subModifiers.${subModifierIndex}.hidden`}
                        label={translate('Hidden')}
                        disabled={loading}
                        defaultValue={subModifier.hidden}
                    />
                </Grid>
            )}
            {!matchMediaQuery && (
                <Grid item xs={6} md={2} className={(classes as any).buttonContainer} style={{ padding: 0, paddingBottom: 5 }}>
                    {showRemoveButton && (
                        <IconButton disabled={loading} aria-label='Remove' onClick={removeSubModifier}>
                            <ClearIcon />
                        </IconButton>
                    )}
                </Grid>
            )}
        </Grid>
    );
}

const useStyles = makeStyles((theme) => ({
    container: {
        [theme.breakpoints.down('sm')]: {
            flexDirection: 'column',
        },
    },
}));

type Props = {
    modifierGroupIndex: number;
    modifierIndex: number;
    subModifierGroupIndex: number;
    subModifierIndex: number;
    subModifier: DeprecatedSubModifierVm;
    loading: boolean;
    showRemoveButton?: boolean;
    removeSubModifier: any;
    showHiddenSwitch?: boolean;
};
