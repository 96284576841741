import { letseatadminApiMethod } from 'src/api/letseatadminApi';
import type { ApiSauceResponse } from 'src/api/types/ApiSauceResponse';

export async function exportRestaurantDetailsUberDirectApi(): ApiSauceResponse<ExportUberDirectRestaurantDetailsVm> {
    return letseatadminApiMethod('restaurantDetails/exportRestaurantDetailsUberDirectApi');
}

export type ExportUberDirectRestaurantDetailsVm = {
    uberDirectExport: string;
    exported: number;
    removed: number;
    errors: number;
};
