import { Dialog, DialogActions, DialogContent, Grid, makeStyles } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import * as React from 'react';
import { translate } from 'src/i18n/translate';
import { useFormatAsRestaurantCurrencyNumber } from 'src/services/restaurant/useFormatAsRestaurantCurrencyNumber';
import { FinanceReference } from 'src/types/Id';
import { useSelector } from 'src/utils/react/useSelector';

export function ConektaSpeiPaymentDialog({ open, subscriptionPrice, conektaSpeiReference, financeReference, onClose }: Props): React.ReactElement {
    const classes = useStyles();
    const formatAsCurrencyNumber = useFormatAsRestaurantCurrencyNumber();

    const posEnabled = useSelector((state) => state.app.restaurant.posEnabled);
    const financeReferenceEnabled = useSelector((state) => state.app.restaurant.financeReferenceEnabled);

    const handleClose = () => onClose();

    return (
        <Dialog open={open} classes={{ paper: classes.paper }} onClose={handleClose}>
            <DialogContent className={classes.dialogContent}>
                <Grid container>
                    <Grid item xs={12} style={{ maxWidth: 700 }}>
                        <div className={classes.opps}>
                            <div>
                                <div className={classes.oppsReminder}>Ficha digital. No es necesario imprimir.</div>
                                <div className={classes.oppsInfo}>
                                    <div className={classes.oppsBrand}>
                                        <img className={classes.oppsBrandImg} src='https://milito.mx/wp-content/uploads/2020/04/spei.png' alt='SPEI' />
                                    </div>
                                    <div className={classes.oppsAmmount}>
                                        {/* @ts-ignore */}
                                        <h>Monto a pagar</h>
                                        <h2 className={classes.oppsAmmountH2}>{formatAsCurrencyNumber(subscriptionPrice)}</h2>
                                        <p className={classes.oppsAmmountP}>Pagar esta cantidad EXACTA.</p>
                                    </div>
                                </div>
                                <div className={classes.oppsReference}>
                                    <h3>CLABE</h3>
                                    <h1 className={classes.oppsReferenceH}>{conektaSpeiReference}</h1>
                                    {financeReferenceEnabled && <h4 className={classes.oppsReferenceH}>{`${translate('Reference')}: ${financeReference}`}</h4>}
                                </div>
                            </div>
                            <div className={classes.oppsInstructions}>
                                <h3>Instrucciones</h3>
                                <ol>
                                    <li>Accede a tu banca en línea.</li>
                                    <li>
                                        Da de alta la CLABE en esta ficha. <strong>{`El banco deberá de ser ${posEnabled || financeReferenceEnabled ? 'BBVA BANCOMER' : 'STP'}`}</strong>.
                                    </li>
                                    <li>
                                        Realiza la transferencia correspondiente por la cantidad exacta en esta ficha, <strong>de lo contrario se rechazará el cargo</strong>.
                                    </li>
                                    {!financeReferenceEnabled && (
                                        <li>
                                            Al confirmar tu pago, el portal de tu banco generará un comprobante digital. <strong>En el podrás verificar que se haya realizado correctamente.</strong>
                                            Conserva este comprobante de pago.
                                        </li>
                                    )}
                                    {financeReferenceEnabled && <li>Ingresar numero de referencia para el seguimiento correcto.</li>}
                                    {financeReferenceEnabled && (
                                        <li>
                                            Envíe su comprobante al correo electrónico <strong>control@pidedirecto.mx</strong> o a través de WhatsApp al número <strong>+52 56 4108 3229</strong>.
                                        </li>
                                    )}
                                </ol>
                                <div className={classes.oppsFootnote}>
                                    Al completar estos pasos recibirás un correo de{' '}
                                    <strong>{financeReferenceEnabled ? 'Elevate Systems' : posEnabled ? 'Grupo Ambit Technology' : 'PideDirecto'}</strong> confirmando tu pago.
                                </div>
                            </div>
                        </div>
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions className={classes.buttonsContainer}>
                <Button onClick={handleClose}>
                    <div className={classes.cancelButton}>{translate('Close')}</div>
                </Button>
            </DialogActions>
        </Dialog>
    );
}

const useStyles = makeStyles((theme) => ({
    paper: {
        width: '40%',
        height: '90%',
        maxWidth: '100%',
        overflow: 'hidden',
        [theme.breakpoints.down('md')]: {
            height: '80%',
            marginBottom: 20,
        },
    },
    form: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        height: '100%',
    },
    dialogTitle: {
        textAlign: 'center',
        color: theme.palette.secondary.contrastText,
        paddingTop: theme.spacing(4),
        paddingBottom: 0,
        marginBottom: 10,
        '& h2': {
            fontFamily: theme.typography.medium,
            fontSize: 25,
        },
    },
    dialogContent: {
        display: 'flex',
        alignItems: 'center',
        [theme.breakpoints.down('md')]: {
            padding: '0 20px',
        },
        maxWidth: '100%',
    },
    subTitle: {
        fontFamily: theme.typography.regular,
        fontSize: 15,
        textTransform: 'none',
        textAlign: 'center',
        width: '70%',
        margin: '0 auto',
        [theme.breakpoints.down('md')]: {
            width: '80%',
            marginBottom: 20,
        },
    },
    buttonsContainer: {
        marginTop: 5,
        marginBottom: 10,
        [theme.breakpoints.down('sm')]: {
            flexDirection: 'column',
        },
    },
    noMargin: {
        margin: '0 !important',
    },
    cancelButton: {
        backgroundColor: theme.palette.secondary.dark,
        color: theme.palette.secondary.contrastText,
        fontFamily: theme.typography.semiBold,
        fontSize: 15,
        borderRadius: 5,
        height: 40,
        width: 200,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        textTransform: 'none',
    },
    opps: {
        width: '100%',
        height: '87%',
        borderRadius: '4px',
        boxSizing: 'border-box',
        padding: '0 45px',
        margin: '40px auto',
        overflow: 'hidden',
        border: '1px solid #b0afb5',
        fontFamily: 'Open Sans, sans-serif',
        color: '#4f5365',
    },

    oppsReminder: {
        position: 'relative',
        top: '-1px',
        padding: '9px 0 10px',
        fontSize: '11px',
        textTransform: 'uppercase',
        textAlign: 'center',
        color: '#ffffff',
        background: '#000000',
    },

    oppsInfo: {
        marginTop: '26px',
        position: 'relative',
    },

    oppsBrand: {
        width: '45%',
        float: 'left',
    },

    oppsBrandImg: {
        maxWidth: '150px',
        marginTop: '2px',
    },

    oppsAmmount: {
        width: '55%',
        float: 'right',
    },

    oppsAmmountH2: {
        fontSize: '36px',
        color: '#000000',
        lineHeight: '24px',
        marginBottom: '15px',
    },

    oppsAmmountP: {
        fontSize: '10px',
        lineHeight: '14px',
    },

    oppsReference: {
        marginTop: '14px',
    },

    oppsReferenceH: {
        marginTop: '0',
        marginBottom: '0',
    },

    oppsInstructions: {
        margin: '32px -45px 0',
        padding: '32px 45px 45px',
        borderTop: '1px solid #b0afb5',
        background: '#f8f9fa',
    },

    oppsFootnote: {
        marginTop: '22px',
        padding: '22px 20 24px',
        color: '#108f30',
        textAlign: 'center',
        border: '1px solid #108f30',
        borderRadius: '4px',
        background: '#ffffff',
    },
}));

type Props = {
    open: boolean;
    subscriptionPrice?: string;
    conektaSpeiReference: string;
    financeReference?: FinanceReference;
    onClose: any;
};
