import * as React from 'react';

export function PeopleIcon({ color }: Props): React.ReactElement {
    return (
        <svg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'>
            <path
                fillRule='evenodd'
                clipRule='evenodd'
                d='M6.875 5.625C6.875 4.7962 7.20424 4.00134 7.79029 3.41529C8.37634 2.82924 9.1712 2.5 10 2.5C10.8288 2.5 11.6237 2.82924 12.2097 3.41529C12.7958 4.00134 13.125 4.7962 13.125 5.625C13.125 6.4538 12.7958 7.24866 12.2097 7.83471C11.6237 8.42076 10.8288 8.75 10 8.75C9.1712 8.75 8.37634 8.42076 7.79029 7.83471C7.20424 7.24866 6.875 6.4538 6.875 5.625ZM13.125 8.125C13.125 7.46196 13.3884 6.82607 13.8572 6.35723C14.3261 5.88839 14.962 5.625 15.625 5.625C16.288 5.625 16.9239 5.88839 17.3928 6.35723C17.8616 6.82607 18.125 7.46196 18.125 8.125C18.125 8.78804 17.8616 9.42393 17.3928 9.89277C16.9239 10.3616 16.288 10.625 15.625 10.625C14.962 10.625 14.3261 10.3616 13.8572 9.89277C13.3884 9.42393 13.125 8.78804 13.125 8.125V8.125ZM1.875 8.125C1.875 7.46196 2.13839 6.82607 2.60723 6.35723C3.07607 5.88839 3.71196 5.625 4.375 5.625C5.03804 5.625 5.67393 5.88839 6.14277 6.35723C6.61161 6.82607 6.875 7.46196 6.875 8.125C6.875 8.78804 6.61161 9.42393 6.14277 9.89277C5.67393 10.3616 5.03804 10.625 4.375 10.625C3.71196 10.625 3.07607 10.3616 2.60723 9.89277C2.13839 9.42393 1.875 8.78804 1.875 8.125ZM5.25833 12.5975C5.76663 11.8009 6.46757 11.1452 7.29635 10.6912C8.12513 10.2372 9.05501 9.99947 10 10C10.7915 9.99928 11.5743 10.1657 12.297 10.4885C13.0197 10.8112 13.6661 11.2829 14.1939 11.8728C14.7217 12.4627 15.119 13.1574 15.3597 13.9114C15.6004 14.6654 15.6792 15.4618 15.5908 16.2483C15.58 16.3461 15.5463 16.4398 15.4925 16.5221C15.4386 16.6043 15.3661 16.6727 15.2808 16.7217C13.6738 17.6438 11.8528 18.1277 10 18.125C8.07917 18.125 6.275 17.615 4.71917 16.7217C4.63391 16.6727 4.5614 16.6043 4.50754 16.5221C4.45367 16.4398 4.41997 16.3461 4.40917 16.2483C4.26921 14.9705 4.56872 13.6831 5.25833 12.5983V12.5975Z'
                fill={color ?? '#63D6B3'}
            />
            <path
                d='M4.23501 11.8783C3.41314 13.147 3.03022 14.6503 3.14501 16.1575C2.6446 16.0816 2.15243 15.9589 1.67501 15.7908L1.57917 15.7575C1.49367 15.7271 1.4188 15.6727 1.36363 15.6006C1.30846 15.5286 1.27536 15.4421 1.26834 15.3517L1.26001 15.2508C1.22634 14.8325 1.2773 14.4116 1.40984 14.0134C1.54238 13.6151 1.75379 13.2477 2.03144 12.9329C2.3091 12.6182 2.64731 12.3626 3.02591 12.1814C3.4045 12.0002 3.81571 11.8971 4.23501 11.8783ZM16.855 16.1575C16.9698 14.6503 16.5869 13.147 15.765 11.8783C16.1843 11.8971 16.5955 12.0002 16.9741 12.1814C17.3527 12.3626 17.6909 12.6182 17.9686 12.9329C18.2462 13.2477 18.4576 13.6151 18.5902 14.0134C18.7227 14.4116 18.7737 14.8325 18.74 15.2508L18.7317 15.3517C18.7245 15.442 18.6913 15.5283 18.6362 15.6001C18.581 15.672 18.5062 15.7264 18.4208 15.7567L18.325 15.79C17.8525 15.9567 17.3617 16.0808 16.855 16.1575Z'
                fill={color ?? '#63D6B3'}
            />
        </svg>
    );
}

type Props = {
    color?: string;
};
