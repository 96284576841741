import { BigNumber } from 'bignumber.js';
import * as React from 'react';
import type { TotalCommissionsReport } from 'src/api/letseatmanager/restaurantDashboard/getIntegrationsPaymentReportApi';
import { BarChartCanvas } from 'src/components/charts/BarChartCanvas';
import { IntegrationPlatforms, type IntegrationPlatform } from 'src/constants/IntegrationPlatforms';
import { translate } from 'src/i18n/translate';
import { ReportSection } from 'src/scenes/letseatmanager/restaurantDashboard/ReportSection';
import { useFormatAsRestaurantCurrencyNumber } from 'src/services/restaurant/useFormatAsRestaurantCurrencyNumber';

export function IntegrationsPaymentReportTotalCommissionsGraph({ totalCommissionsReport, selectedIntegrationPlatforms, loading }: Props): React.ReactElement | null {
    const formatAsCurrencyNumber = useFormatAsRestaurantCurrencyNumber();

    const {
        uberEatsTotalSales,
        uberEatsTotalCommission,
        uberEatsTotalSalesWithoutComission,
        rappiTotalSales,
        rappiTotalCommission,
        rappiTotalSalesWithoutComission,
        didiFoodTotalSales,
        didiFoodTotalCommission,
        didiFoodTotalSalesWithoutComission,
        pedidosYaTotalSales,
        pedidosYaTotalCommission,
        pedidosYaTotalSalesWithoutComission,
    } = totalCommissionsReport;

    const integrationPlatformDetails: Record<IntegrationPlatform, IntegrationPlatformDetails> = {
        [IntegrationPlatforms.UBER_EATS]: {
            totalSales: uberEatsTotalSales,
            totalCommission: uberEatsTotalCommission,
            totalSalesWithoutCommission: uberEatsTotalSalesWithoutComission,
        },
        [IntegrationPlatforms.RAPPI]: {
            totalSales: rappiTotalSales,
            totalCommission: rappiTotalCommission,
            totalSalesWithoutCommission: rappiTotalSalesWithoutComission,
        },
        [IntegrationPlatforms.DIDI_FOOD]: {
            totalSales: didiFoodTotalSales,
            totalCommission: didiFoodTotalCommission,
            totalSalesWithoutCommission: didiFoodTotalSalesWithoutComission,
        },
        [IntegrationPlatforms.PEDIDOS_YA]: {
            totalSales: pedidosYaTotalSales,
            totalCommission: pedidosYaTotalCommission,
            totalSalesWithoutCommission: pedidosYaTotalSalesWithoutComission,
        },
    };

    const data = selectedIntegrationPlatforms.map((integrationPlatform) => {
        const details = integrationPlatformDetails[integrationPlatform];
        return {
            id: translate(`IntegrationPlatforms.${integrationPlatform}`),
            [translate('Total Sales')]: formatAsCurrencyNumber(BigNumber(details.totalSales).toNumber()),
            [translate('Commission')]: formatAsCurrencyNumber(BigNumber(details.totalCommission).toNumber()),
            [translate('Expected')]: formatAsCurrencyNumber(BigNumber(details.totalSalesWithoutCommission).toNumber()),
        };
    });

    if (!selectedIntegrationPlatforms.length) return null;

    return (
        <ReportSection loading={loading} title={translate('Total commission')}>
            <BarChartCanvas
                data={data}
                indexBy={'id'}
                groupMode={'grouped'}
                keys={[translate('Total Sales'), translate('Commission'), translate('Expected')]}
                margin={{ top: 20, left: 50, bottom: 95 }}
                tickRotation={0}
                labelTextColor={'#FFFFFF'}
            />
        </ReportSection>
    );
}

type Props = {
    totalCommissionsReport: TotalCommissionsReport;
    selectedIntegrationPlatforms: Array<IntegrationPlatform>;
    loading: boolean;
};

type IntegrationPlatformDetails = {
    totalSales: string;
    totalCommission: string;
    totalSalesWithoutCommission: string;
};
