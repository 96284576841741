import moment from 'moment-timezone';

export function getDontShowMinimumInventoryReachedAlertAgainInLocalStorage(): DontShowMinimumInventoryReachedAlertAgain {
    const dontShowMinimumInventoryReachedAlertAgain = JSON.parse(window.localStorage.getItem('dontShowMinimumInventoryReachedAlertAgain') as any);
    const lastTimeOpened = (moment(window.localStorage.getItem('lastTimeOpened')) || '') as any;
    return { dontShowMinimumInventoryReachedAlertAgain, lastTimeOpened };
}

export type DontShowMinimumInventoryReachedAlertAgain = {
    dontShowMinimumInventoryReachedAlertAgain: boolean;
    lastTimeOpened: Date;
};
