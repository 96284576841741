import type { OrderPaymentVm } from 'src/types/OrderPaymentVm';
import { PosPayment } from 'src/types/PosPayment';
import { useSelector } from 'src/utils/react/useSelector';

export function useMappedOrderPaymentsVm(): Array<OrderPaymentVm> {
    const payments = useSelector((state) => state.pos.payments);
    const currency = useSelector((state) => state.app.restaurant?.currencyFormat?.currency);

    const mappedPayments = payments?.map((payment: PosPayment) => {
        const isMainCurrency = payment.restaurantCurrency?.currency === currency;

        return {
            paymentTerminalPaymentId: payment.paymentTerminalPaymentId,
            paymentTerminalId: payment.paymentTerminalId,
            paymentTerminalProvider: payment.paymentTerminalProvider,
            amount: payment.amount,
            paymentMethod: payment.paymentMethod,
            mexicanPaymentMethodCode: payment.mexicanPaymentMethodCode,
            customPaymentMethod: payment.customPaymentMethod,
            paymentReference: payment.paymentReference,
            customerNumber: payment.customerNumber,
            isTipPayment: payment.isTipPayment,
            isTipFromChange: payment.isTipFromChange,
            restaurantCurrencyAmount: !isMainCurrency ? payment.restaurantCurrencyAmount : undefined,
            restaurantCurrency: !isMainCurrency ? payment.restaurantCurrency : undefined,
        };
    });

    return mappedPayments;
}
