import { makeStyles } from '@material-ui/core/styles';
import { Button, Dialog, DialogActions, DialogContent } from '@pidedirecto/ui';
import { Form, FormCurrencyNumberStringField, FormPercentNumberField } from '@pidedirecto/ui/form';
import { useForm, useNotification } from '@pidedirecto/ui/hooks';
import { BigNumber } from 'bignumber.js';
import * as React from 'react';
import { useState } from 'react';
import { FormCustomerNumberSelect } from 'src/components/form/FormCustomerNumberSelect';
import { FormTipTypeSelect } from 'src/components/form/FormTipTypeSelect';
import { PosPaymentMethodSelect } from 'src/components/pos/PosPaymentMethodSelect';
import { PaymentMethod } from 'src/constants/PaymentMethod';
import { TipTypes } from 'src/constants/TipType';
import { translate } from 'src/i18n/translate';
import { getPosInterfaceLargerEnabledRestaurantSettingInLocalStorage } from 'src/localStorage/getPosInterfaceLargerEnabledRestaurantSettingInLocalStorage';
import { PosCurrencies } from 'src/scenes/letseatmanager/pos/posPayment/PosCurrencies';
import { useHasMultiplePosCurrencies } from 'src/services/pos/useHasMultiplePosCurrencies';
import { usePosChange } from 'src/services/pos/usePosChange';
import { CustomPaymentMethod } from 'src/types/Id';
import { useSelector } from 'src/utils/react/useSelector';
import { sum } from 'src/utils/reduce/sum';

export function AddTipDialog({ open, onClose, onSuccess }: Props): React.ReactElement {
    const classes = useStyles();
    const notification = useNotification();
    const hasMultiplePosCurrencies = useHasMultiplePosCurrencies();
    const change = usePosChange();
    const form = useForm();
    const {
        formState: { isSubmitting },
        control,
        watch,
    } = form;

    const posInterfaceLargerEnabled = getPosInterfaceLargerEnabledRestaurantSettingInLocalStorage();

    const customers = useSelector((state) => state.pos.customers);
    const payTotalTicketSelected = useSelector((state) => state.pos.payTotalTicketSelected);
    const total = useSelector((state) => state.pos.payment?.total);
    const selectedCurrency = useSelector((state) => state.pos.selectedCurrency);
    const tips = useSelector((state) => state.pos.tips) ?? [];
    const payments = useSelector((state) => state.pos.payments);
    const country = useSelector((state) => state.app.restaurant.country);

    const tipsAmount = tips
        .map((tip) => tip.tipAmount)
        .reduce(sum, BigNumber(0))
        .toString();

    const tipType = watch('tipType');

    const [selectedTipPaymentMethod, setSelectedTipPaymentMethod] = useState<PosPaymentMethod>({ customPaymentMethod: undefined, paymentMethod: undefined });

    const showCustomerNumberSelect = !!customers?.length && !payTotalTicketSelected;

    const handleClose = () => {
        if (isSubmitting) return;
        onClose();
    };

    const handleSubmit = (form: any) => {
        if (!selectedTipPaymentMethod?.paymentMethod) return notification({ message: translate('Please select a payment method.') });

        const customerPayments = payments.filter((payment) => !payment.isTipPayment);
        const lastPayment = customerPayments[customerPayments.length - 1];

        const orderTotal = BigNumber(total).minus(tipsAmount).toString();
        const tipAmount = form.tipAmount ?? BigNumber(form.tipPercentage).dividedBy(100).multipliedBy(orderTotal).toString();

        const tipHasSamePaymentMethodThanLastPayment =
            lastPayment?.paymentMethod === selectedTipPaymentMethod?.paymentMethod && lastPayment?.customPaymentMethod === selectedTipPaymentMethod?.customPaymentMethod;

        return onSuccess({
            tipType: form.tipType,
            tipPercentage: BigNumber(form.tipPercentage).multipliedBy(100).toString(),
            tipAmount: tipAmount,
            isTipFromChange: BigNumber(change).isGreaterThanOrEqualTo(tipAmount) && tipHasSamePaymentMethodThanLastPayment,
            paymentMethod: selectedTipPaymentMethod?.paymentMethod,
            customPaymentMethod: selectedTipPaymentMethod?.customPaymentMethod,
            customerNumber: form.customerNumber !== '0' ? form.customerNumber : null,
            restaurantCurrency: hasMultiplePosCurrencies ? selectedCurrency : undefined,
        });
    };

    const handleSelectPaymentMethod = ({ paymentMethod, customPaymentMethod }: PosPaymentMethod) => setSelectedTipPaymentMethod({ customPaymentMethod, paymentMethod });

    return (
        <Dialog open={open} onClose={handleClose} title={translate('Add Tip')}>
            <Form onSubmit={handleSubmit} form={form}>
                <DialogContent>
                    {showCustomerNumberSelect && <FormCustomerNumberSelect name='customerNumber' label={translate('Select customer')} required={false} />}
                    <FormTipTypeSelect name='tipType' label={translate('Tip Type')} required />
                    {(tipType === TipTypes.AMOUNT || !tipType) && (
                        <FormCurrencyNumberStringField
                            name='tipAmount'
                            label={hasMultiplePosCurrencies ? `${translate('Tip')} ${selectedCurrency?.currency}` : translate('Tip')}
                            required
                            country={country}
                        />
                    )}
                    {tipType === TipTypes.PERCENT && (
                        <FormPercentNumberField name='tipPercentage' label={hasMultiplePosCurrencies ? `${translate('Tip')} ${selectedCurrency?.currency}` : translate('Tip')} required />
                    )}
                    <PosCurrencies />
                    <PosPaymentMethodSelect selectedPaymentMethod={selectedTipPaymentMethod?.customPaymentMethod ?? selectedTipPaymentMethod?.paymentMethod} onSelect={handleSelectPaymentMethod} />
                </DialogContent>
                <DialogActions>
                    <Button classes={{ button: classes.button }} size={posInterfaceLargerEnabled ? 'large' : undefined} variant='secondary' onClick={handleClose}>
                        {translate('Cancel')}
                    </Button>
                    <Button
                        classes={{ button: classes.button }}
                        size={posInterfaceLargerEnabled ? 'large' : undefined}
                        type='submit'
                        disabled={!selectedTipPaymentMethod || form.formState.isSubmitting}
                    >
                        {translate('Add')}
                    </Button>
                </DialogActions>
            </Form>
        </Dialog>
    );
}

const useStyles = makeStyles((theme) => ({
    dialog: {
        [theme.breakpoints.up('sm')]: {
            maxWidth: '50vw',
        },
    },
    form: {
        display: 'flex',
        overflowY: 'scroll',
        flexDirection: 'column',
    },
    button: {
        width: '100%',
        [theme.breakpoints.up('sm')]: {
            width: '30%',
        },
    },
}));

type PosPaymentMethod = {
    paymentMethod: PaymentMethod | undefined;
    customPaymentMethod?: CustomPaymentMethod;
};

type Props = {
    open: boolean;
    onClose: any;
    onSuccess: any;
};
