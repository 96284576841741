import { makeStyles, Tooltip } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import { BugReport } from '@material-ui/icons';
import { MUIDataTableColumn } from 'mui-datatables';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { deletePrinterApi } from 'src/api/letseatmanager/printerDeprecated/deletePrinterApi';
import { findRestaurantPrintersApi } from 'src/api/letseatmanager/printerDeprecated/findRestaurantPrintersApi';
import { getPrinterApi } from 'src/api/letseatmanager/printerDeprecated/getPrinterApi';
import { ChangeToolbarButton } from 'src/components/mui-datatables/ChangeToolbarButton';
import { CreateToolbarButton } from 'src/components/mui-datatables/CreateToolbarButton';
import { RefreshToolbarButton } from 'src/components/mui-datatables/RefreshToolbarButton';
import { RemoveToolbarButton } from 'src/components/mui-datatables/RemoveToolbarButton';
import { SyncToolbarButton } from 'src/components/mui-datatables/SyncToolbarButton';
import { SystemDownloadToolbarButton } from 'src/components/mui-datatables/SystemDownloadToolbarButton';
import { SecuredAndSubscribedPage } from 'src/components/page/SecuredAndSubscribedPage';
import { Table } from 'src/components/Table';
import { RolePermissions } from 'src/constants/RolePermission';
import { translate } from 'src/i18n/translate';
import { getDeprecatedPrintersInLocalStorage } from 'src/localStorage/getDeprecatedPrintersInLocalStorage';
import { setDeprecatedPrintersInLocalStorage } from 'src/localStorage/setDeprecatedPrintersInLocalStorage';
import { ChangePrinterDialog } from 'src/scenes/letseatmanager/deprecatedPrinters/ChangePrinterDialog';
import { CreatePrinterDialog } from 'src/scenes/letseatmanager/deprecatedPrinters/CreatePrinterDialog';
import { usePrintPrinterInfo } from 'src/services/printer/usePrintPrinterInfo';
import { printerDeprecatedToPrinter } from 'src/services/printer/utils/printerDeprecatedToPrinter';
import type { PrinterId } from 'src/types/Id';
import { PrinterDeprecatedVm } from 'src/types/PrinterDeprecatedVm';
import { alertKnownErrorOrSomethingWentWrong } from 'src/utils/alert/alertKnownErrorOrSomethingWentWrong';
import { formatDateTimeString } from 'src/utils/date/formatDateTimeString';
import { getSelectedRowsData } from 'src/utils/mui-datatables/getSelectedRowsData';
import { useLoadApi } from 'src/utils/react/useLoadApi';
import { useSelector } from 'src/utils/react/useSelector';

export function DeprecatedPrintersPage(): React.ReactElement {
    const classes = useStyles();

    const [loading, setLoading] = useState(false);
    const [activePrinters, setActivePrinters] = useState<Array<PrinterDeprecatedVm>>([]);
    const [createPrinterDialogState, setCreatePrinterDialogState] = useState({ open: false });
    const [changePrinterDialogState, setChangePrinterDialogState] = useState({ open: false, printerId: undefined });

    const restaurantId = useSelector((state) => state.app.restaurantId);

    const [printPrinterInfo] = usePrintPrinterInfo();

    const [loadingPrinters, { printers }, refreshPrinters] = useLoadApi(findRestaurantPrintersApi, { restaurantId: restaurantId }, { initialValue: { printers: [] } });

    useEffect(() => {
        loadActivePrinters();
    }, []);

    const loadActivePrinters = () => {
        setActivePrinters(getDeprecatedPrintersInLocalStorage());
    };

    const removeMultiple = async (printerIds: Array<PrinterId>) => {
        setLoading(true);
        for (const printerId of printerIds) {
            const response = await deletePrinterApi({ printerId });
            if (!response.ok) {
                setLoading(false);
                alertKnownErrorOrSomethingWentWrong(response);
                return;
            }
            removePrinterInLocalStorage(printerId);
        }
        setLoading(false);
        await refreshPrinters();
    };

    const removePrinterInLocalStorage = (printerId: PrinterId) => {
        const printers = getDeprecatedPrintersInLocalStorage();
        setDeprecatedPrintersInLocalStorage(printers.filter((printer) => printer.printerId !== printerId));
    };

    const handleClickNew = () => {
        setCreatePrinterDialogState({ open: true });
    };

    const handleClickSyncPrinters = () => {
        const syncPrinters = window.confirm(translate('Are you sure you want to sync the printers active on this device with the printers saved?'));
        if (!syncPrinters) return;

        const activePrinters = printers.filter((printer) => !printer.disabled);
        setDeprecatedPrintersInLocalStorage(activePrinters);
        setActivePrinters(activePrinters);
    };

    const columns: Array<MUIDataTableColumn> = [
        {
            name: 'printerId',
            label: '',
            options: {
                display: 'excluded',
                filter: false,
            },
        },
        {
            name: 'restaurantId',
            label: '',
            options: {
                display: 'excluded',

                filter: false,
            },
        },
        {
            name: 'type',
            label: translate('Type'),
            options: {
                filter: false,
            },
        },
        {
            name: 'deviceName',
            label: translate('Name'),
            options: {
                filter: false,
                searchable: false,
            },
        },
        {
            name: 'externalPrinterId',
            label: translate('Printer Id'),
            options: {
                filter: false,
                searchable: false,
            },
        },
        {
            name: 'printerBrand',
            label: translate('Brand'),
            options: {
                filter: false,
                searchable: false,
            },
        },
        {
            name: 'ipAddress',
            label: translate('Ip Address'),
            options: {
                filter: false,
                searchable: false,
            },
        },
        {
            name: 'port',
            label: translate('Port'),
            options: {
                searchable: false,
            },
        },
        {
            name: 'serialNumber',
            label: translate('Serial Number'),
            options: {
                filter: false,
            },
        },
    ];

    return (
        <SecuredAndSubscribedPage rolePermission={RolePermissions.PRINTERS_PAGE} title={translate('Printers')}>
            <CreatePrinterDialog open={createPrinterDialogState.open} onClose={() => setCreatePrinterDialogState({ open: false })} onPrinterCreated={refreshPrinters} />
            <ChangePrinterDialog
                open={changePrinterDialogState.open}
                onClose={() => setChangePrinterDialogState({ open: false, printerId: undefined })}
                printerId={changePrinterDialogState.printerId}
                onPrinterChanged={refreshPrinters}
            />

            <Table
                loading={loadingPrinters || loading}
                title={translate('Active printers on this device')}
                data={activePrinters.map((printer) => {
                    return {
                        printerId: printer.printerId,
                        restaurantId: printer.restaurantId,
                        type: printer.type,
                        externalPrinterId: printer.externalPrinterId,
                        deviceName: printer.deviceName,
                        ipAddress: printer.ipAddress,
                        port: printer.port,
                        printerBrand: printer.printerBrand,
                        printerStatus: !printer.disabled && !!printers.find((_printer) => _printer.printerId === printer.printerId),
                        serialNumber: printer.serialNumber,
                        createdAt: formatDateTimeString(printer.createdAt),
                    };
                })}
                columns={columns}
                options={{
                    responsive: 'standard',
                    tableBodyMaxHeight: '500px',
                    selectableRows: 'multiple',
                    filterType: 'checkbox',
                    rowsPerPage: 100,
                    filter: false,
                    print: false,
                    viewColumns: false,
                    customToolbar: () => (
                        <>
                            <SyncToolbarButton onClick={handleClickSyncPrinters} />
                        </>
                    ),
                    customToolbarSelect: (selectedRows, displayData) => {
                        const selectedRowsData = getSelectedRowsData(selectedRows, displayData);

                        const handleClickRemove = () => {
                            const selectedPrintersIds = selectedRowsData.map((d: PrinterId) => d[0]);
                            const printers = getDeprecatedPrintersInLocalStorage();
                            const activePrinters = printers.filter((printer) => !selectedPrintersIds.includes(printer.printerId));
                            setDeprecatedPrintersInLocalStorage(activePrinters);
                            setActivePrinters(activePrinters);
                        };

                        const handleTest = async () => {
                            const printerId = selectedRowsData[0][0];
                            const response = await getPrinterApi({ restaurantId, printerId });
                            if (!response.ok) {
                                alertKnownErrorOrSomethingWentWrong(response);
                                return;
                            }

                            await printPrinterInfo(printerDeprecatedToPrinter(response.data));

                            alert(translate('A testing ticket was sent'));
                        };

                        return (
                            <div className={classes.toolbar}>
                                <RemoveToolbarButton onClick={handleClickRemove} />
                                {selectedRowsData.length === 1 && (
                                    <Tooltip title={translate('Test printer')}>
                                        <IconButton onClick={handleTest}>
                                            <BugReport />
                                        </IconButton>
                                    </Tooltip>
                                )}
                            </div>
                        );
                    },
                }}
            />

            <Table
                className={classes.table}
                loading={loadingPrinters || loading}
                title={translate('Saved Printers')}
                data={printers.map((printer) => {
                    const printers = getDeprecatedPrintersInLocalStorage();
                    return {
                        printerId: printer.printerId,
                        restaurantId: printer.restaurantId,
                        type: printer.type,
                        externalPrinterId: printer.externalPrinterId,
                        deviceName: printer.deviceName,
                        ipAddress: printer.ipAddress,
                        port: printer.port,
                        printerBrand: printer.printerBrand,
                        printerStatus: !printer.disabled && !!printers.find((_printer) => _printer.printerId === printer.printerId),
                        serialNumber: printer.serialNumber,
                        createdAt: formatDateTimeString(printer.createdAt),
                    };
                })}
                columns={[
                    ...columns,
                    {
                        name: 'printerStatus',
                        label: translate('Printer Status'),
                        options: {
                            filter: false,
                            searchable: false,
                            customBodyRender: (printerStatus) => <div>{printerStatus ? translate('Enabled') : translate('Disabled')}</div>,
                        },
                    },
                    {
                        name: 'createdAt',
                        label: translate('Created At'),
                        options: {
                            filter: false,
                        },
                    },
                ]}
                options={{
                    responsive: 'standard',
                    tableBodyMaxHeight: '500px',
                    selectableRows: 'multiple',
                    filterType: 'checkbox',
                    rowsPerPage: 100,
                    filter: false,
                    print: false,
                    viewColumns: false,
                    customToolbar: () => (
                        <>
                            <RefreshToolbarButton onClick={refreshPrinters} />
                            <CreateToolbarButton onClick={handleClickNew} />
                        </>
                    ),
                    customToolbarSelect: (selectedRows, displayData) => {
                        const selectedRowsData = getSelectedRowsData(selectedRows, displayData);
                        const selectedPrintersIds = selectedRowsData.map((d: PrinterId) => d[0]);

                        const printerId = selectedRowsData[0][0];

                        const handleClickRemove = async () => {
                            const remove = window.confirm(
                                selectedRowsData.length === 1
                                    ? 'Are you sure you want to remove the selected printer'
                                    : `Are you sure you want to remove the selected ${selectedPrintersIds.length} printer`,
                            );
                            if (remove) {
                                await removeMultiple(selectedPrintersIds);
                            }
                        };

                        const activePrinterOnThisDevice = () => {
                            const newPrinter = printers.find((printer) => printer.printerId === printerId);
                            if (!newPrinter) return;

                            const activePrinters = getDeprecatedPrintersInLocalStorage();
                            activePrinters.push(newPrinter);
                            setDeprecatedPrintersInLocalStorage(activePrinters);
                            setActivePrinters(activePrinters);
                        };

                        return (
                            <div className={classes.toolbar}>
                                <RemoveToolbarButton onClick={handleClickRemove} />
                                {selectedRowsData.length === 1 && <ChangeToolbarButton onClick={() => setChangePrinterDialogState({ open: true, printerId })} />}
                                {selectedRowsData.length === 1 && <SystemDownloadToolbarButton onClick={activePrinterOnThisDevice} tooltip={translate('Active printer')} />}
                            </div>
                        );
                    },
                }}
            />
        </SecuredAndSubscribedPage>
    );
}

const useStyles = makeStyles((theme) => ({
    toolbar: {
        paddingRight: theme.spacing(3),
    },
    title: {
        fontFamily: theme.typography.medium,
        fontSize: 22,
    },
    table: {
        marginTop: 50,
    },
}));
