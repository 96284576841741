import type { DriverId } from 'src/types/Id';

export const KEY_NAME = 'driverOptions';

export function getDriverOptionsInLocalStorage(): Array<DriverOption> {
    const item = window.localStorage.getItem(KEY_NAME);
    return item ? JSON.parse(item) : [];
}

export type DriverOption = {
    label: string;
    value: DriverId;
};
