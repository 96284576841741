import { envSTRIPE_PUBLISHABLE_KEY } from 'src/env/envSTRIPE_PUBLISHABLE_KEY';

export async function createStripeCardToken(request: CreateStripeCardTokenRequest): Promise<CreateStripeCardTokenResponse> {
    if (!window.Stripe.key) {
        window.Stripe.setPublishableKey(envSTRIPE_PUBLISHABLE_KEY());
    }
    return new Promise((resolve, reject: (error?: any) => void) => {
        window.Stripe.card.createToken(
            {
                number: request.cardNumber,
                exp_month: request.expiry.substring(0, 2),
                exp_year: request.expiry.substring(2, 4),
                cvc: request.cvc,
            },
            (status, response) => {
                if (response.error) {
                    console.error(response.error.message);
                    resolve({
                        ok: false,
                        data: {
                            errorMessage: response.error.message,
                        },
                    });
                    return;
                }
                resolve({
                    ok: true,
                    data: {
                        stripeCardToken: response.id,
                    },
                });
            },
        );
    });
}

export type CreateStripeCardTokenRequest = {
    cardNumber: string;
    expiry: string;
    name: string;
    cvc: string;
};

export type CreateStripeCardTokenResponse = {
    ok: boolean;
    data: {
        stripeCardToken?: string;
        errorMessage?: string;
    };
};
