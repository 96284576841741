import Grid from '@material-ui/core/Grid';
import * as React from 'react';
import { FormSwitch } from 'src/components/form/FormSwitch';
import { SectionTitle } from 'src/components/title/SectionTitle';
import { TicketSections } from 'src/constants/TicketSection';
import { translate } from 'src/i18n/translate';

export function TicketPrintedDateSettings({ disabled }: Props): React.ReactElement {
    return (
        <section>
            <SectionTitle title={translate('Operation Date')} />
            {/* @ts-ignore */}
            <Grid item xs={12} columnSpacing={2}>
                <FormSwitch disabled={disabled} name={TicketSections.DATE_OF_PRINT} label={translate(`TicketSections.${TicketSections.DATE_OF_PRINT}`)} />
            </Grid>
        </section>
    );
}

type Props = {
    disabled: boolean;
};
