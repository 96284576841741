import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import { BigNumber } from 'bignumber.js';
import { useState } from 'react';
import * as React from 'react';
import { useForm } from 'react-hook-form';
import { partialRefundOngoingOrderApi } from 'src/api/letseatmanager/order/partialRefundOngoingOrderApi';
import { Form } from 'src/components/form/Form';
import { FormCurrencyNumberStringField } from 'src/components/form/FormCurrencyNumberStringField';
import { translate } from 'src/i18n/translate';
import type { OrderId } from 'src/types/Id';
import { alertKnownErrorOrSomethingWentWrong } from 'src/utils/alert/alertKnownErrorOrSomethingWentWrong';
import { useSelector } from 'src/utils/react/useSelector';

export function PartialRefundOngoingOrderDialog({ open, orderId, maximumAmountToRefund, handleClose, onSuccess }: Props): React.ReactElement {
    const form = useForm();
    const classes = useStyles();

    const [loading, setLoading] = useState(false);

    const restaurant = useSelector((state) => state.app.restaurant);

    const handleSubmit = async (form: any) => {
        if (BigNumber(form.amount).isGreaterThan(maximumAmountToRefund)) {
            alert(translate('The maximum amount possible to refund for this order is @maximumAmountToRefund', { maximumAmountToRefund }));
            return;
        }
        setLoading(true);
        const response = await partialRefundOngoingOrderApi({
            restaurantId: restaurant.restaurantId,
            orderId,
            amount: form.amount,
        });
        setLoading(false);
        if (!response.ok) {
            alertKnownErrorOrSomethingWentWrong(response);
            return;
        }
        handleClose();
        onSuccess?.();
    };

    return (
        <Dialog aria-label='refundOngoingOrdersDialog' role='dialog' fullWidth scroll='paper' open={open} onClose={handleClose}>
            <Form onSubmit={handleSubmit} form={form} className={classes.form}>
                <DialogTitle id='change-menu-dialog-title' classes={{ root: classes.dialogTitle }}>
                    {translate('Refund')}
                </DialogTitle>
                <DialogContent style={{ overflow: 'hidden', width: '80%' }}>
                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                            <FormCurrencyNumberStringField name='amount' label={translate('Amount')} required disabled={loading} />
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions style={{ marginTop: 20, marginBottom: 20 }} className={classes.buttonsContainer}>
                    <Button onClick={handleClose} disabled={loading}>
                        <div className={classes.cancelButton}>{translate('Cancel')}</div>
                    </Button>
                    <Button color='primary' type='submit' disabled={loading} classes={{ root: classes.noMargin }}>
                        <div className={classes.acceptButton}>{loading ? translate('Refunding') : translate('Refund')}</div>
                    </Button>
                </DialogActions>
            </Form>
        </Dialog>
    );
}

const useStyles = makeStyles((theme) => ({
    dialogTitle: {
        textAlign: 'center',
        fontSize: 41,
        color: theme.palette.secondary.contrastText,
        paddingTop: theme.spacing(4),
        paddingBottom: theme.spacing(3),
        '& h2': {
            fontFamily: theme.typography.medium,
        },
    },
    form: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    buttonsContainer: {
        marginTop: 20,
        marginBottom: 20,
        [theme.breakpoints.down('sm')]: {
            flexDirection: 'column',
        },
    },
    noMargin: {
        margin: '0 !important',
    },
    acceptButton: {
        backgroundColor: theme.palette.primary.main,
        color: 'white',
        fontFamily: theme.typography.semiBold,
        fontSize: 15,
        borderRadius: 5,
        height: 40,
        width: 200,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        textTransform: 'none',
        boxShadow: `3px 3px 10px ${theme.palette.primary.main}aa`,
    },
    cancelButton: {
        backgroundColor: theme.palette.secondary.dark,
        color: theme.palette.secondary.contrastText,
        fontFamily: theme.typography.semiBold,
        fontSize: 15,
        borderRadius: 5,
        height: 40,
        width: 200,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        textTransform: 'none',
    },
}));

type Props = {
    open: boolean;
    orderId: OrderId;
    maximumAmountToRefund: string;
    handleClose: any;
    onSuccess?: any;
};
