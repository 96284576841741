import { makeStyles } from '@material-ui/core';
import * as React from 'react';
import { OrderItem } from 'src/components/orderCard/orderItems/OrderItem';
import { translate } from 'src/i18n/translate';
import type { OrderItemVm } from 'src/types/OrderVm';
import { formatTimeString } from 'src/utils/date/formatTimeString';
import { groupOrderItemsAndCancelledItemsByAddedDate } from 'src/utils/pos/groupOrderItemsAndCancelledItemsByAddedDate';

export function GroupedOrderItems({ orderItems, cancelledItems }: Props): any {
    const classes = useStyles();

    const orderItemsGrouped = groupOrderItemsAndCancelledItemsByAddedDate<OrderItemVm>(orderItems ?? [], []);
    const cancelledItemsGrouped = groupOrderItemsAndCancelledItemsByAddedDate<OrderItemVm>([], cancelledItems ?? []);

    const orderItemsLength = orderItems?.length ?? 0;
    const cancelledItemsItemsLength = cancelledItems?.length ?? 0;

    const thereIsItemsToGroup = orderItemsLength > 0 || cancelledItemsItemsLength > 0;

    if (!thereIsItemsToGroup) return null;

    return (
        <>
            {orderItemsGrouped.map((orderItems, idx) => {
                const orderItemsInThisGroup = orderItems.filter((orderItem) => !orderItem.removedAt);
                const groupDate = orderItems[0].removedAt ?? orderItems[0].addedAt;

                return (
                    <article className={classes.groupContainer} key={idx}>
                        <p className={classes.dateText}>{formatTimeString(groupDate)}</p>
                        <div className={classes.orderItemsContainer}>
                            {orderItemsInThisGroup.map((orderItem, idx) => (
                                <OrderItem key={`${orderItem.menuItemId}-${idx}`} orderItem={orderItem} />
                            ))}
                        </div>
                    </article>
                );
            })}
            {!!cancelledItemsGrouped?.length && <p className={classes.cancelledItemsTitle}>{translate('Cancelled Items:')}</p>}
            {cancelledItemsGrouped?.map((cancelledItems: Array<OrderItemVm>, idx: number) => {
                const cancelledItemsInThisGroup = cancelledItems.filter((orderItem) => !!orderItem.removedAt);
                const groupDate = cancelledItems[0].removedAt ?? cancelledItems[0].addedAt;

                return (
                    <article className={classes.groupContainer} key={idx}>
                        <p className={classes.dateText}>{formatTimeString(groupDate)}</p>
                        <>
                            {cancelledItemsInThisGroup.map((cancelledItem, idx) => (
                                <OrderItem key={`${cancelledItem.menuItemId}-${idx}`} orderItem={cancelledItem} />
                            ))}
                        </>
                    </article>
                );
            })}
        </>
    );
}

const useStyles = makeStyles((theme) => ({
    groupContainer: {
        backgroundColor: '#F1F6F9',
        borderRadius: 8,
        padding: '6px 12px',
    },
    groupTitle: {
        fontFamily: theme.typography.regular,
        fontSize: 15,
        margin: 0,
    },
    orderItemsContainer: {
        display: 'flex',
        flexDirection: 'column',
        gap: 12,
    },
    cancelledItemsTitle: {
        fontFamily: theme.typography.medium,
        fontSize: 15,
        margin: 0,
        marginBottom: 5,
        marginTop: 15,
        color: '#e1383d',
    },
    dateText: {
        fontFamily: theme.typography.regular,
        fontSize: 12,
        textAlign: 'right',
        margin: 0,
    },
}));

type Props = {
    orderItems?: Array<OrderItemVm>;
    cancelledItems?: Array<OrderItemVm>;
};
