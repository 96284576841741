import { makeStyles } from '@material-ui/core/styles';
import moment from 'moment-timezone';
import { useState } from 'react';
import * as React from 'react';
import { findRestaurantEmployeeLogEventsApi } from 'src/api/letseatmanager/employeeDayShift/findRestaurantEmployeeLogEventsApi';
import { Button } from 'src/components/Button';
import { GoBackButton } from 'src/components/button/GoBackButton';
import { translateLogEventType } from 'src/components/form/utils/translateLogEventType';
import { Input } from 'src/components/Input';
import { SubscribedPage } from 'src/components/page/SubscribedPage';
import { SimpleTable } from 'src/components/SimpleTable';
import { UpdatingContentProgress } from 'src/components/UpdatingContentProgress';
import { translate } from 'src/i18n/translate';
import { UserFilledIcon } from 'src/icons/UserFilledIcon';
import type { EmployeeLogEventVm } from 'src/types/EmployeeLogEventVm';
import type { ManagerUserId } from 'src/types/Id';
import { formatDateString } from 'src/utils/date/formatDateString';
import { useLoadApi } from 'src/utils/react/useLoadApi';
import { useParams } from 'src/utils/react/useParams';
import { useSelector } from 'src/utils/react/useSelector';
import { toShortId } from 'src/utils/uuid/toShortId';

export function PosEmployeeLogEventsPage(): React.ReactElement {
    const classes = useStyles();

    const { managerUserId } = useParams<{
        managerUserId: ManagerUserId;
    }>();

    const [from, setFrom] = useState(moment().startOf('day').toDate());
    const [to, setTo] = useState(moment().endOf('day').toDate());

    const restaurantId = useSelector((state) => state.app.restaurantId);

    const [loading, employeeLogEventsReport, refreshEmployeeLogEvents] = useLoadApi(
        findRestaurantEmployeeLogEventsApi,
        {
            restaurantId: restaurantId,
            managerUserId,
            from: new Date(from).toISOString(),
            to: new Date(to).toISOString(),
        },
        { dependencies: [restaurantId], initialValue: [] },
    );

    const handleFromChange = (from: any) => {
        setFrom(from);
    };

    const handleToChange = (to: any) => {
        setTo(to);
    };

    return (
        <SubscribedPage title={translate('Employee events')}>
            <section className={classes.container}>
                <UpdatingContentProgress loading={loading} top />
                <GoBackButton classes={{ button: classes.goBackButton }} label={translate('Return')} />
                <section className={classes.headerSection}>
                    <div className={classes.name}>
                        <UserFilledIcon title={'user-icon'} height={24} width={24} />
                        <div className={classes.posUserName}>
                            <div>{employeeLogEventsReport[0]?.name}</div>
                            <div className={classes.caption}>{employeeLogEventsReport[0]?.roleName}</div>
                        </div>
                    </div>
                    <section className={classes.filters}>
                        <Input type='date' name='from' label={translate('From')} onChange={handleFromChange} defaultValue={from} />
                        <Input type='date' name='to' label={translate('To')} onChange={handleToChange} defaultValue={to} />
                        <Button classes={{ button: classes.button }} onClick={refreshEmployeeLogEvents}>
                            {translate('Update')}
                        </Button>
                    </section>
                </section>
                <div className={classes.tableContainer}>
                    <SimpleTable
                        columns={[
                            {
                                id: 'eventType',
                                content: translate('Event type'),
                            },
                            {
                                id: 'order',
                                content: translate('Order Id'),
                            },
                            {
                                id: 'occurredAt',
                                content: translate('Date'),
                            },
                            {
                                id: 'ipAddress',
                                content: translate('Ip Address'),
                            },
                        ]}
                        rows={employeeLogEventsReport?.map((employeeLogEvent: EmployeeLogEventVm) => ({
                            eventType: translateLogEventType(employeeLogEvent.logEventType),
                            order: toShortId(employeeLogEvent.orderId!) ?? '',
                            occurredAt: formatDateString(employeeLogEvent.occurredAt),
                            ipAddress: employeeLogEvent.ipAddress ?? '',
                        }))}
                    />
                </div>
            </section>
        </SubscribedPage>
    );
}

const useStyles = makeStyles((theme) => ({
    tableContainer: {
        width: '90%',
        margin: '50px auto',
        [theme.breakpoints.up('sm')]: {
            width: '100%',
        },
    },
    filters: {
        display: 'flex',
        flexDirection: 'column',
        gap: 10,
        [theme.breakpoints.up('sm')]: {
            flexDirection: 'row',
        },
    },
    button: {
        width: 150,
        height: 32,
        [theme.breakpoints.up('sm')]: {
            alignSelf: 'end',
        },
    },
    goBackButton: {
        top: 100,
        left: 50,
    },
    container: {
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        margin: '40px 0',
    },
    name: {
        display: 'flex',
        gap: 20,
    },
    caption: {
        fontSize: 12,
        color: '#6D7B7F',
    },
    posUserName: {
        fontFamily: theme.typography.semiBold,
        fontSize: 18,
        display: 'flex',
        flexDirection: 'column',
        alignContent: 'center',
        gap: 10,
        color: '#0D3037',
    },
    headerSection: {
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        gap: 50,
        [theme.breakpoints.up('sm')]: {
            alignItems: 'start',
        },
        [theme.breakpoints.up('md')]: {
            flexDirection: 'row',
            justifyContent: 'space-between',
        },
    },
}));
