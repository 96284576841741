import * as React from 'react';
import type { DeviceGroupDetailsVm } from 'src/api/letseatmanager/deviceGroup/findDeviceGroupsApi';
import { removeDeviceGroupApi } from 'src/api/letseatmanager/deviceGroup/removeDeviceGroupApi';
import { RemoveDialog } from 'src/components/dialog/RemoveDialog';
import { translate } from 'src/i18n/translate';
import { useReloadRestaurant } from 'src/services/restaurant/useReloadRestaurant';
import { alertKnownErrorOrSomethingWentWrong } from 'src/utils/alert/alertKnownErrorOrSomethingWentWrong';
import { useSelector } from 'src/utils/react/useSelector';

export function RemoveDeviceGroupDialog({ open, deviceGroup, onClose, onSuccess }: Props): React.ReactElement {
    const { reloadRestaurant } = useReloadRestaurant();

    const restaurantId = useSelector((state) => state.app.restaurantId);

    const removeDeviceGroup = async () => {
        if (!deviceGroup) return;

        const response = await removeDeviceGroupApi({ deviceGroupId: deviceGroup?.deviceGroupId });
        if (!response.ok) {
            return alertKnownErrorOrSomethingWentWrong(response);
        }
        reloadRestaurant(restaurantId);
        onSuccess?.();
        onClose?.();
    };

    return (
        <RemoveDialog
            open={open}
            title={translate('Remove @deviceGroupName', { deviceGroupName: deviceGroup?.deviceGroupName })}
            subtitle={translate('Are you sure you want to continue?')}
            onSubmit={removeDeviceGroup}
            onClose={onClose}
        />
    );
}

type Props = {
    open: boolean;
    deviceGroup: DeviceGroupDetailsVm | undefined;
    onClose: any;
    onSuccess?: any;
};
