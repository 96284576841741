import { Grid, makeStyles } from '@material-ui/core';
import { CrossIcon } from '@pidedirecto/ui/icons';
import * as React from 'react';
import { useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { Button } from 'src/components/Button';
import { FormRestaurantPspSelect } from 'src/components/form/FormRestaurantPspSelect';
import { FormSwitch } from 'src/components/form/FormSwitch';
import { FormTextField } from 'src/components/form/FormTextField';
import { Text } from 'src/components/Text';
import { RestaurantPsps } from 'src/constants/RestaurantPsp';
import { translate } from 'src/i18n/translate';
import { isMercadoPagoRestaurantPsp } from 'src/utils/cardPsp/isMercadoPagoRestaurantPsp';
import { isPlexoRestaurantPsp } from 'src/utils/cardPsp/isPlexoRestaurantPsp';
import { isUruguay } from 'src/utils/country/isUruguay';
import { useSelector } from 'src/utils/react/useSelector';

export function RestaurantPspSettings({ restaurant }: Props): React.ReactElement {
    const { watch } = useFormContext();

    const classes = useStyles();

    const [editMercadoPagoCredentials, setEditMercadoPagoCredentials] = useState(false);

    const restaurantCountry = useSelector((state) => state.app.restaurant.country);

    const restaurantPsp = watch('restaurantPsp');
    const restaurantPspsEnabled = watch('restaurantPspsEnabled');

    const showMercadoPagoCredentialsForm = editMercadoPagoCredentials || !restaurant?.mercadoPagoPublicKey;

    return (
        <Grid container spacing={3}>
            <Grid item xs={12}>
                <h2 className={classes.sectionTitle}>{translate('Psp settings')}</h2>
            </Grid>
            <Grid item xs={12} sm={6}>
                <FormRestaurantPspSelect
                    name='restaurantPsp'
                    label={translate('Psp')}
                    helperText={translate('Psp to be used for ecommerce payments')}
                    filter={!isUruguay(restaurantCountry) ? [RestaurantPsps.PLEXO] : undefined}
                    required
                />
            </Grid>
            <Grid item xs={12} sm={6}>
                <FormSwitch name='restaurantPspsEnabled' label={translate('Psp enabled')} tooltip={translate('If this field is disabled the psp used will be automatic')} />
            </Grid>

            {isPlexoRestaurantPsp(restaurantPsp) && restaurantPspsEnabled && (
                <>
                    <Grid item xs={12}>
                        <h2 className={classes.sectionTitle}>{translate('Plexo Settings')}</h2>
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <FormSwitch name='plexoIntegrationEnabled' label={translate('Plexo enabled')} helperText={translate('Enables plexo integration')} />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <FormTextField name='plexoStoreId' label={translate('Plexo store id')} tooltip={translate('The plexo store id is required for the plexo integration')} />
                    </Grid>
                </>
            )}

            {isMercadoPagoRestaurantPsp(restaurantPsp) && restaurantPspsEnabled && (
                <>
                    <Grid item xs={12}>
                        <h2 className={classes.sectionTitle}>{translate('Mercado pago settings')}</h2>
                    </Grid>
                    {showMercadoPagoCredentialsForm && (
                        <>
                            <Grid item xs={12} sm={4}>
                                <FormTextField name='mercadoPagoPublicKey' label={translate('Mercado pago public key')} />
                            </Grid>
                            <Grid item xs={12} sm={4}>
                                <FormTextField name='mercadoPagoSecretKey' label={translate('Mercado pago secret key')} />
                            </Grid>
                        </>
                    )}
                    {!showMercadoPagoCredentialsForm && (
                        <Grid item xs={12}>
                            <div className={classes.credentialContainer}>
                                <Text>{translate('MercadoPago public key @mercadoPagoPublicKey', { mercadoPagoPublicKey: restaurant?.mercadoPagoPublicKey })}</Text>
                                <Button icon onClick={() => setEditMercadoPagoCredentials(true)}>
                                    <CrossIcon title={translate('Remove mercado pago key')} />
                                </Button>
                            </div>
                        </Grid>
                    )}
                </>
            )}
        </Grid>
    );
}

type Props = {
    restaurant: any;
};

const useStyles = makeStyles((theme) => ({
    sectionTitle: {
        fontFamily: theme.typography.semiBold,
        fontSize: 14,
    },
    credentialContainer: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        gap: 10,
    },
}));
