import type { CashRegisterDepositReason } from 'src/constants/CashRegisterDepositReason';
import { CashRegisterWithdrawReason, CashRegisterWithdrawReasons } from 'src/constants/CashRegisterWithdrawReason';
import { translate } from 'src/i18n/translate';
import type { CashRegisterCustomReason } from 'src/types/Id';

export function translateCashRegisterWithdrawReasons(cashRegisterWithdrawReason: CashRegisterDepositReason | CashRegisterWithdrawReason | CashRegisterCustomReason): string {
    const CashRegisterWithdrawReasonsValues = Object.values(CashRegisterWithdrawReasons);
    if (!CashRegisterWithdrawReasonsValues.includes(cashRegisterWithdrawReason as any)) {
        return cashRegisterWithdrawReason;
    }

    return translate(`CashRegisterWithdrawReasons.${cashRegisterWithdrawReason}`);
}
