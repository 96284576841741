import * as React from 'react';
import { findCashRegisterWithdrawReasonsApi } from 'src/api/letseatmanager/pos/findCashRegisterWithdrawReasonsApi';
import { FormSelect } from 'src/components/form/FormSelect';
import type { Variant } from 'src/components/form/FormTypes';
import { CashRegisterWithdrawReasons } from 'src/constants/CashRegisterWithdrawReason';
import { useLoadApi } from 'src/utils/react/useLoadApi';
import { useSelector } from 'src/utils/react/useSelector';
import { translateCashRegisterWithdrawReasons } from 'src/utils/translate/translateCashRegisterWithdrawReasons';

export function FormCashRegisterWithdrawReasonSelect({ name, label, placeholder, helperText, defaultValue, disabled, required, variant }: Props): React.ReactElement {
    const restaurantId = useSelector((state) => state.app.restaurantId);

    const [loading, cashRegisterWithdrawReasons, getWithdrawReasons] = useLoadApi(findCashRegisterWithdrawReasonsApi, { restaurantId }, { initialValue: [] });

    const customCashRegisterWithdrawReasons = cashRegisterWithdrawReasons
        ? cashRegisterWithdrawReasons.map((withdrawReason) => withdrawReason.reason).concat(Object.values(CashRegisterWithdrawReasons) as any)
        : Object.values(CashRegisterWithdrawReasons);

    const options = required
        ? customCashRegisterWithdrawReasons.map((cashRegisterWithdrawReason: any) => ({ label: translateCashRegisterWithdrawReasons(cashRegisterWithdrawReason), value: cashRegisterWithdrawReason }))
        : [
              { label: '', value: null },
              ...customCashRegisterWithdrawReasons.map((cashRegisterWithdrawReason: any) => ({
                  label: translateCashRegisterWithdrawReasons(cashRegisterWithdrawReason),
                  value: cashRegisterWithdrawReason,
              })),
          ];

    return (
        <FormSelect
            name={name}
            label={label}
            placeholder={placeholder}
            helperText={helperText}
            defaultValue={defaultValue}
            options={options}
            disabled={disabled || loading}
            required={required}
            variant={variant}
        />
    );
}

type Props = {
    name: string;
    label?: string;
    placeholder?: string;
    helperText?: string;
    defaultValue?: string | null | undefined;
    disabled?: boolean;
    required?:
        | boolean
        | {
              value: number;
              message: string;
          };
    variant?: Variant;
};
