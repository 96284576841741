import * as React from 'react';

export function WalletIcon({ title, color }: Props): React.ReactElement {
    return (
        <svg id='Iconly_Broken_Wallet' data-name='Iconly/Broken/Wallet' width='30' height='30' viewBox='0 0 30 30' fill='none' xmlns='http://www.w3.org/2000/svg'>
            {title && <title>{title}</title>}
            <path
                id='Wallet-2'
                data-name='Wallet'
                d='M12.19 7.688C12.8399 7.99825 13.4057 8.45993 13.8401 9.03424C14.2745 9.60854 14.5648 10.2787 14.6865 10.9885C14.8081 11.6982 14.7577 12.4268 14.5394 13.113C14.3212 13.7992 13.9414 14.423 13.432 14.932L8.93203 19.432C8.08811 20.2759 6.94351 20.75 5.75003 20.75C4.55655 20.75 3.41195 20.2759 2.56803 19.432C1.72411 18.5881 1.25 17.4435 1.25 16.25C1.25 15.0565 1.72411 13.9119 2.56803 13.068L4.32503 11.311M17.675 10.689L19.432 8.932C20.2759 8.08808 20.7501 6.94348 20.7501 5.75C20.7501 4.55652 20.2759 3.41192 19.432 2.568C18.5881 1.72408 17.4435 1.24997 16.25 1.24997C15.0565 1.24997 13.9119 1.72408 13.068 2.568L8.56803 7.068C8.05866 7.577 7.67889 8.20081 7.46061 8.88702C7.24233 9.57323 7.19191 10.3018 7.3136 11.0115C7.43528 11.7213 7.72552 12.3915 8.15992 12.9658C8.59432 13.5401 9.1602 14.0017 9.81003 14.312'
                stroke={color || 'currentColor'}
                strokeWidth='1.5'
            />
        </svg>
    );
}

type Props = {
    title?: string;
    color?: string;
};
