import { Box, makeStyles } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import DownloadIcon from '@material-ui/icons/SaveAltRounded';
import moment from 'moment';
import * as React from 'react';
import { useForm } from 'react-hook-form';
import { getRestaurantReportBillingDataApi } from 'src/api/letseatmanager/restaurant/getRestaurantReportBillingDataApi';
import { Button } from 'src/components/Button';
import { Form } from 'src/components/form/Form';
import { FormDatePicker } from 'src/components/form/FormDatePicker';
import { translate } from 'src/i18n/translate';
import { alertKnownErrorOrSomethingWentWrong } from 'src/utils/alert/alertKnownErrorOrSomethingWentWrong';
import { useSelector } from 'src/utils/react/useSelector';
import { createAndSaveBilling } from 'src/utils/restaurant/createAndSaveBilling';

export function DownloadCommissionReportForm(): React.ReactElement {
    const classes = useStyles();
    const form = useForm();

    const defaultFrom = moment().startOf('isoWeek').subtract(1, 'week').toDate();
    const defaultTo = moment().endOf('isoWeek').subtract(1, 'week').toDate();

    const restaurantId = useSelector((state) => state.app.restaurantId);

    const getRestaurantData = async (from: any, to: any) => {
        const response = await getRestaurantReportBillingDataApi({ restaurantId, from: moment(from).toDate(), to: moment(to).toDate() });
        if (!response.ok) {
            alertKnownErrorOrSomethingWentWrong(response);
            return;
        }
        const { addressOfEstablishment, businessName, rfc } = response.data;
        const restaurantBilling = response.data;
        createAndSaveBilling({
            businessName,
            addressOfEstablishment,
            rfc,
            restaurantBilling,
            from: moment(from).toDate(),
            to: moment(to).toDate(),
        });
    };

    const handleClickDownloadPdf = async (form: any) => {
        try {
            await getRestaurantData(form.from, form.to);
        } catch (error: any) {
            alertKnownErrorOrSomethingWentWrong(error.message);
        }
    };

    return (
        <Form className={classes.container} form={form} onSubmit={handleClickDownloadPdf}>
            <Box className={classes.subContainer}>
                <Typography className={classes.title}>{translate('Your commission report')}</Typography>
                <Box className={classes.flexContainer}>
                    <Box className={classes.datesContainer}>
                        <Box>
                            <FormDatePicker name='from' label={translate('From')} defaultValue={defaultFrom} />
                        </Box>
                        <Box className={classes.dates}>
                            <FormDatePicker name='to' label={translate('To')} defaultValue={defaultTo} />
                        </Box>
                    </Box>
                    <Button type={'submit'} text classes={{ button: classes.button }}>
                        <DownloadIcon />
                        {translate('Download PDF')}
                    </Button>
                </Box>
            </Box>
        </Form>
    );
}

const useStyles = makeStyles((theme) => ({
    container: {
        margin: 10,
        padding: 14,
        borderRadius: 10,
        borderStyle: 'solid',
        borderColor: '#DDDDDD',
    },
    subContainer: {
        margin: 10,
    },
    title: {
        fontWeight: 'bold',
        marginBottom: 5,
    },
    flexContainer: {
        display: 'flex',
        justifyContent: 'space-between',
    },
    datesContainer: {
        display: 'flex',
        flexDirection: 'row',
    },
    dates: {
        marginLeft: 15,
    },
    textDownload: {
        [theme.breakpoints.down('xs')]: {
            display: 'none',
        },
    },
    button: {
        color: theme.palette.text.success,
        maxHeight: 52,
    },
}));
