import { letseatmanagerApiMethod } from 'src/api/letseatmanagerApi';
import type { ApiSauceResponse } from 'src/api/types/ApiSauceResponse';
import type { CustomerActivity } from 'src/constants/CustomerActivity';
import type { Day } from 'src/constants/Day';
import type { DiscountType } from 'src/constants/DiscountType';
import type { NotificationMessageType } from 'src/constants/NotificationMessageType';
import type { ScheduleTaskInterval } from 'src/constants/ScheduleTaskInterval';
import { type CustomerId, type RestaurantCustomerId, type RestaurantId } from 'src/types/Id';

export async function scheduleNotificationToCustomerApi(request: ScheduleNotificationToCustomerApiRequest): ApiSauceResponse<void> {
    return letseatmanagerApiMethod('marketing/scheduleNotificationToCustomerApi', request);
}

type ScheduleNotificationToCustomerApiRequest = {
    restaurantIds: Array<RestaurantId>;
    interval: ScheduleTaskInterval;
    name: string;
    day?: Day;
    dayNumber?: number;
    hour: string;
    customerActivity: CustomerActivity;
    restaurantCustomerMobileNumbers?: Array<{ restaurantCustomerId: RestaurantCustomerId; mobileNumber: string }>;
    customerIds?: Array<CustomerId>;
    notificationMessageType: Array<NotificationMessageType>;
    message: string;
    title?: string;
    addPromoCode?: boolean;
    discountType?: DiscountType;
    discount?: string;
    maxDiscountAmount?: string;
    date?: Date;
};
