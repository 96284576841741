import { BigNumber } from 'bignumber.js';
import type { Country } from 'src/constants/Country';
import { getIvaByCountry } from 'src/utils/order/getIvaByCountry';

export function calculateIva(total: string, country: Country): string {
    const subTotalDivisor = BigNumber(getIvaByCountry(country)).plus(1).toNumber();

    const subtotal = BigNumber(total).dividedBy(subTotalDivisor).decimalPlaces(3, BigNumber.ROUND_DOWN).toNumber();

    const iva = BigNumber(subtotal).multipliedBy(getIvaByCountry(country)).decimalPlaces(3, BigNumber.ROUND_DOWN).toNumber();

    const roundedIva = Number(iva).toFixed(2);

    return roundedIva.toString();
}
