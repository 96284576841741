import * as React from 'react';
import { CreateMexicanOrderInvoiceDialog } from 'src/components/dialog/order/createOrderInvoiceDialog/CreateMexicanOrderInvoiceDialog';
import { CreatePeruvianOrderInvoiceDialog } from 'src/components/dialog/order/createOrderInvoiceDialog/CreatePeruvianOrderInvoiceDialog';
import { CreateUruguayanOrderInvoiceDialog } from 'src/components/dialog/order/createOrderInvoiceDialog/CreateUruguayanOrderInvoiceDialog';
import { FiscalEntity } from 'src/constants/FiscalEntity';
import type { OrderVm } from 'src/types/OrderVm';
import { isMexico } from 'src/utils/country/isMexico';
import { isPeru } from 'src/utils/country/isPeru';
import { isUruguay } from 'src/utils/country/isUruguay';
import { isAcceptedOrder } from 'src/utils/order/isAcceptedOrder';
import { isCompletedOrder } from 'src/utils/order/isCompletedOrder';

export function CreateOrderInvoiceDialog({ open, order, onClose, onSuccess, defaultFiscalEntity }: Props): React.ReactElement | null {
    if (!order) return null;

    if ((!isCompletedOrder(order.orderStatus) && isMexico(order.country)) || order.invoiced) return null;

    const orderStatusForInvoiceUy = isCompletedOrder(order.orderStatus) || isAcceptedOrder(order.orderStatus);
    if (isUruguay(order.country) && orderStatusForInvoiceUy) {
        return <CreateUruguayanOrderInvoiceDialog open={open} order={order} onClose={onClose} onSuccess={onSuccess} defaultFiscalEntity={defaultFiscalEntity} />;
    }
    if (isPeru(order.country)) {
        return <CreatePeruvianOrderInvoiceDialog open={open} order={order} onClose={onClose} onSuccess={onSuccess} defaultFiscalEntity={defaultFiscalEntity} />;
    }

    return <CreateMexicanOrderInvoiceDialog open={open} order={order} onClose={onClose} onSuccess={onSuccess} />;
}

type Props = {
    open: boolean;
    order?: OrderVm;
    defaultFiscalEntity?: FiscalEntity;
    onClose: any;
    onSuccess?: any;
};
