import * as React from 'react';
import { removeDeliveryZoneApi } from 'src/api/letseatmanager/deliveryZones/removeDeliveryZoneApi';
import { RemoveDialog } from 'src/components/dialog/RemoveDialog';
import { translate } from 'src/i18n/translate';
import { useReloadRestaurant } from 'src/services/restaurant/useReloadRestaurant';
import type { DeliveryZoneVm } from 'src/types/RestaurantVm';
import { alertKnownErrorOrSomethingWentWrong } from 'src/utils/alert/alertKnownErrorOrSomethingWentWrong';
import { useSelector } from 'src/utils/react/useSelector';
import { requireValue } from 'src/utils/require/requireValue';

export function RemoveDeliveryZoneDialog({ open, onClose, deliveryZone }: Props): React.ReactElement {
    const restaurantId = useSelector((state) => state.app.restaurantId);

    const { reloadRestaurant } = useReloadRestaurant();

    const removeZone = async () => {
        const response = await removeDeliveryZoneApi({
            restaurantId: restaurantId,
            deliveryZoneId: requireValue(deliveryZone).deliveryZoneId,
        });
        if (!response.ok) {
            alertKnownErrorOrSomethingWentWrong(response);
            return;
        }
        await reloadRestaurant(restaurantId);
        onClose();
    };

    return (
        <RemoveDialog
            open={open}
            subtitle={translate('You are about to delete the “@deliveryZone” zone, are you sure you want to continue?', { deliveryZone: deliveryZone?.name })}
            cancelButtonText={translate('Cancel')}
            removeButtonText={translate('Remove')}
            onSubmit={removeZone}
            onClose={onClose}
        />
    );
}

type Props = {
    open: boolean;
    onClose: any;
    deliveryZone?: DeliveryZoneVm;
};
