import * as React from 'react';

export function DriverIcon({ stroke, color, title, width, height }: Props): React.ReactElement {
    return (
        <svg
            xmlns='http://www.w3.org/2000/svg'
            className='icon icon-tabler icon-tabler-moped'
            width={width || '28'}
            height={height || '28'}
            viewBox='0 0 24 24'
            strokeWidth={stroke || '1.5'}
            stroke={color || '#06B7A2'}
            fill='none'
            strokeLinecap='round'
            strokeLinejoin='round'
        >
            {title && <title>{title}</title>}
            <path stroke='none' d='M0 0h24v24H0z' fill='none' />
            <path d='M18 17m-2 0a2 2 0 1 0 4 0a2 2 0 1 0 -4 0' />
            <path d='M5 16v1a2 2 0 0 0 4 0v-5h-3a3 3 0 0 0 -3 3v1h10a6 6 0 0 1 5 -4v-5a2 2 0 0 0 -2 -2h-1' />
            <path d='M6 9l3 0' />
        </svg>
    );
}

type Props = {
    color?: string;
    title?: string;
    width?: number;
    height?: number;
    stroke?: string;
};
