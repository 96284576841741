import { withStyles } from '@material-ui/core/styles';
import * as React from 'react';
import { letseatmanagerApiDeprecated } from 'src/api/letseatmanagerApiDeprecated';
import { OrdersTable } from 'src/scenes/letseatadmin/order/OrdersTable';
import type { CustomerId } from 'src/types/Id';
import type { OrderVm } from 'src/types/OrderVm';
import { alertKnownErrorOrSomethingWentWrong } from 'src/utils/alert/alertKnownErrorOrSomethingWentWrong';

export default withStyles((theme) => ({
    table: {
        whiteSpace: 'nowrap',
        '& tr': { cursor: 'pointer' },
        '& tr:hover': { backgroundColor: 'rgba(0, 0, 0, 0.07) !important' },
    },
    toolbar: {
        paddingRight: theme.spacing(3),
    },
}))(
    class CustomerOrders extends React.PureComponent<Props, State> {
        state = {
            loading: false,
            orders: [],
        };

        async componentDidMount() {
            await this.load();
        }

        load = async () => {
            this.setState({ loading: true });
            const response = await letseatmanagerApiDeprecated.admin.getCustomerOrders({ customerId: this.props.customerId });
            this.setState({ loading: false });
            if (!response.ok) {
                alertKnownErrorOrSomethingWentWrong(response);
                return;
            }
            this.setState({ orders: response.data.orders });
        };

        render() {
            return <OrdersTable orders={this.state.orders} loading={this.state.loading} onClickRefresh={this.load} />;
        }
    },
) as React.ComponentType<any>;

type Props = {
    customerId: CustomerId;
    classes: any;
};

type State = {
    loading: boolean;
    orders: Array<OrderVm>;
};
