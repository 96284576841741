import { useEffect } from 'react';
import * as React from 'react';

export function LoadStripeScript(): React.ReactElement | null {
    useEffect(() => {
        if (document.getElementById('stripejs')) {
            return;
        }
        const script = document.createElement('script');
        script.src = 'https://js.stripe.com/v2/';
        script.async = true;
        script.id = 'stripejs';
        /*eslint no-unused-expressions:0*/
        document?.body?.appendChild(script);
    }, []);

    return null;
}
