import { makeStyles } from '@material-ui/core';
import { Select } from '@pidedirecto/ui';
import { BigNumber } from 'bignumber.js';
import moment from 'moment';
import * as React from 'react';
import { useEffect, useMemo, useState } from 'react';
import { getIntegrationsPaymentReportApi } from 'src/api/letseatmanager/restaurantDashboard/getIntegrationsPaymentReportApi';
import { findRestaurantIntegrationsApi } from 'src/api/letseatmanager/restaurantIntegration/findRestaurantIntegrationsApi';
import { Text } from 'src/components/Text';
import { IntegrationPlatform, IntegrationPlatforms } from 'src/constants/IntegrationPlatforms';
import { translate } from 'src/i18n/translate';
import { FilterMasterReports } from 'src/scenes/letseatmanager/restaurantDashboard/FilterMasterReports';
import { FilterReports } from 'src/scenes/letseatmanager/restaurantDashboard/FilterReports';
import { IntegrationsPaymentReportHeatMap } from 'src/scenes/letseatmanager/restaurantDashboard/integrationsPaymentReport/IntegrationsPaymentReportHeatMap';
import { IntegrationsReportKpis } from 'src/scenes/letseatmanager/restaurantDashboard/integrationsPaymentReport/IntegrationsPaymentReportKpis';
import { IntegrationsPaymentReportSalesPerDayGraph } from 'src/scenes/letseatmanager/restaurantDashboard/integrationsPaymentReport/IntegrationsPaymentReportSalesPerDayGraph';
import { IntegrationsPaymentReportSalesPerDayTable } from 'src/scenes/letseatmanager/restaurantDashboard/integrationsPaymentReport/IntegrationsPaymentReportSalesPerDayTable';
import { IntegrationsPaymentReportTotalCommissionsGraph } from 'src/scenes/letseatmanager/restaurantDashboard/integrationsPaymentReport/IntegrationsPaymentReportTotalCommissionsGraph';
import { ReportSectionEmptyState } from 'src/scenes/letseatmanager/restaurantDashboard/ReportSectionEmptyState';
import { useFormatDateToRestaurantTimeZone } from 'src/utils/react/useFormatDateToRestaurantTimeZone';
import { useLoadApi } from 'src/utils/react/useLoadApi';
import { useSelector } from 'src/utils/react/useSelector';

export function IntegrationsReport(): React.ReactElement {
    const classes = useStyles();

    const [showAsMaster, setShowAsMaster] = useState(false);
    const [selectedIntegrationPlatforms, setSelectedIntegrationPlatforms] = useState<IntegrationPlatform[]>([]);

    const formatDateToRestaurantTimeZone = useFormatDateToRestaurantTimeZone();

    const period = useSelector((state) => state.app2.filterReportsState.period);
    const restaurantIds = useSelector((state) => state.app.restaurantIds);
    const restaurantId = useSelector((state) => state.app.restaurantId);

    const [, restaurantIntegrationsStatus] = useLoadApi(
        findRestaurantIntegrationsApi,
        { restaurantId },
        {
            initialValue: {
                uberEatsStoreId: undefined,
                rappiStoreId: undefined,
                rappiIntegrationStatus: undefined,
                pedidosYaRestaurantId: undefined,
                didiFoodStoreId: undefined,
                deliverOrdersFromDidiFood: false,
                deliverOrdersFromUberEats: false,
                deliverOrdersFromRappi: false,
                pedidosYaIntegrationStatus: undefined,
                pedidosYaRestaurantOnline: false,
                pedidosYaSalesCommissionPercentage: undefined,
                uberEatsSalesCommissionPercentage: undefined,
                rappiSalesCommissionPercentage: undefined,
                didiFoodSalesCommissionPercentage: undefined,
            },
            dependencies: [restaurantId],
        },
    );

    const [loading, reports] = useLoadApi(
        getIntegrationsPaymentReportApi,
        {
            restaurantIds,
            fromDate: moment(formatDateToRestaurantTimeZone(period?.from)).toISOString(),
            toDate: moment(formatDateToRestaurantTimeZone(period?.to)).toISOString(),
        },
        { dependencies: [period, restaurantIds] },
    );

    const handleShowAsMaster = (value: boolean) => {
        setShowAsMaster(value);
    };

    const integrationPlatformsOptions = useMemo(() => {
        const options = [];
        if (restaurantIntegrationsStatus.didiFoodStoreId) {
            options.push({ value: IntegrationPlatforms.DIDI_FOOD, label: translate('IntegrationPlatforms.DIDI_FOOD') });
        }
        if (restaurantIntegrationsStatus.uberEatsStoreId) {
            options.push({ value: IntegrationPlatforms.UBER_EATS, label: translate('IntegrationPlatforms.UBER_EATS') });
        }
        if (restaurantIntegrationsStatus.rappiStoreId) {
            options.push({ value: IntegrationPlatforms.RAPPI, label: translate('IntegrationPlatforms.RAPPI') });
        }
        if (restaurantIntegrationsStatus.pedidosYaRestaurantId) {
            options.push({ value: IntegrationPlatforms.PEDIDOS_YA, label: translate('IntegrationPlatforms.PEDIDOS_YA') });
        }
        return options;
    }, [restaurantIntegrationsStatus]);

    useEffect(() => {
        const activeIntegrationPlatforms = integrationPlatformsOptions.map((integrationPlatform) => integrationPlatform.value);
        setSelectedIntegrationPlatforms(activeIntegrationPlatforms);
    }, [integrationPlatformsOptions]);

    return (
        <div className={classes.container}>
            <div className={classes.filtersContainer}>
                <FilterReports />
                <Select
                    name='integrationPlatforms'
                    label={translate('Integrations')}
                    onChange={(options: IntegrationPlatform[]) => setSelectedIntegrationPlatforms(options)}
                    options={integrationPlatformsOptions}
                    multiselectable
                    value={selectedIntegrationPlatforms}
                    classes={{ container: classes.selectContainer, selectContainer: classes.select }}
                />
            </div>
            <FilterMasterReports onChangeMaster={handleShowAsMaster} showAsMaster={showAsMaster} />
            {reports && (
                <>
                    {!selectedIntegrationPlatforms.length && <Text>{translate('Please select an integration platform to display the missing reports')}</Text>}
                    <IntegrationsReportKpis integrationsPaymentReportKpis={reports.integrationsPaymentReportKpis} selectedIntegrationPlatforms={selectedIntegrationPlatforms} />
                    <IntegrationsPaymentReportSalesPerDayGraph
                        dailySalesAndOrdersReport={reports.dailySalesAndOrdersReport}
                        selectedIntegrationPlatforms={selectedIntegrationPlatforms}
                        loading={loading}
                    />
                    <IntegrationsPaymentReportTotalCommissionsGraph
                        totalCommissionsReport={reports.totalCommissionsReport}
                        selectedIntegrationPlatforms={selectedIntegrationPlatforms}
                        loading={loading}
                    />
                    <IntegrationsPaymentReportHeatMap heatmapReport={reports.heatmapReport} maxValue={new BigNumber(reports.maxValueForHeatmap).toNumber()} loading={loading} />
                    <IntegrationsPaymentReportSalesPerDayTable
                        integrationOrdersSummaryReport={reports.integrationOrdersSummaryReport}
                        selectedIntegrationPlatforms={selectedIntegrationPlatforms}
                        loading={loading}
                    />
                </>
            )}
            {!reports && !loading && <ReportSectionEmptyState />}
        </div>
    );
}

const useStyles = makeStyles((theme) => ({
    container: {
        width: '100%',
    },
    filtersContainer: {
        display: 'flex',
        width: '100%',
        alignItems: 'center',
        justifyContent: 'space-between',
    },
    selectContainer: {
        maxWidth: 250,
    },
    select: {
        position: 'relative',
    },
}));
