import { Button } from '@pidedirecto/ui';
import * as React from 'react';
import { useState } from 'react';
import { closeOrderApi } from 'src/api/letseatmanager/order/closeOrderApi';
import { translate } from 'src/i18n/translate';
import { usePrintOrderTicket } from 'src/services/printer/usePrintOrderTicket';
import type { OrderVm } from 'src/types/OrderVm';
import { alertKnownErrorOrSomethingWentWrong } from 'src/utils/alert/alertKnownErrorOrSomethingWentWrong';
import { isPosApp } from 'src/utils/app/isPosApp';
import { isEatHereOrder } from 'src/utils/order/isEatHereOrder';
import { isPendingOrder } from 'src/utils/order/isPendingOrder';
import { useSelector } from 'src/utils/react/useSelector';

export function CloseOrderButton({ order, onSuccess, classes }: Props): React.ReactElement | null {
    const [printOrderTicket] = usePrintOrderTicket();

    const [loading, setLoading] = useState(false);

    const restaurantId = useSelector((state) => state.app.restaurantId);
    const closeOrdersEnabled = useSelector((state) => state.app.restaurant?.closeOrdersEnabled);

    const shouldRenderCloseOrderButton = closeOrdersEnabled && isEatHereOrder(order.orderType) && isPosApp(order.app) && isPendingOrder(order.orderStatus);

    const handleCloseOrder = async () => {
        setLoading(true);
        const response = await closeOrderApi({ orderId: order.orderId, restaurantId });
        setLoading(false);
        if (!response.ok) return alertKnownErrorOrSomethingWentWrong(response);

        onSuccess?.(order);
        printOrderTicket(order);
    };

    if (!shouldRenderCloseOrderButton) return null;

    return (
        <Button variant={'secondary'} classes={{ button: classes?.button }} onClick={handleCloseOrder} disabled={loading}>
            {translate('Partial account')}
        </Button>
    );
}

type Props = {
    order: OrderVm;
    onSuccess?: (order: OrderVm) => void;
    classes?: { button: string };
};
