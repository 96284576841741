export function mapCfeTypeToInvoiceType(cfeType?: string): string {
    switch (cfeType) {
        case '101':
            return 'E-Ticket';
        case '111':
            return 'E-Factura';
        default:
            return 'E-Ticket';
    }
}
