/* eslint-disable react-hooks/rules-of-hooks */
import { BigNumber } from 'bignumber.js';
import type { PromoCodeVm } from 'src/api/letseatmanager/types/PromoCodeVm';
import { Country } from 'src/constants/Country';
import { DiscountTypes, type DiscountType } from 'src/constants/DiscountType';
import { OrderTypes, type OrderType } from 'src/constants/OrderType';
import { PaymentMethod } from 'src/constants/PaymentMethod';
import { RewardTypes } from 'src/constants/RewardType';
import { TipTypes } from 'src/constants/TipType';
import type { PosDriverRequest } from 'src/reducers/posReducer';
import type { CartItemVm } from 'src/types/CartItemVm';
import { CustomPaymentMethod, MenuItemId, PromotionId } from 'src/types/Id';
import { PosPayment } from 'src/types/PosPayment';
import type { PosTip } from 'src/types/PosTip';
import { PromotionVm } from 'src/types/PromotionVm';
import { isMexico } from 'src/utils/country/isMexico';
import { roundDigits } from 'src/utils/number/roundDigits';
import { isDeliveryOrder } from 'src/utils/order/isDeliveryOrder';
import { isCashPayment } from 'src/utils/paymentMethod/isCashPayment';
import { calculateOrderItemProductDiscount } from 'src/utils/pos/calculateOrderItemProductDiscount';
import { calculateOrderItemSubtotalPrice } from 'src/utils/pos/calculateOrderItemSubtotalPrice';
import { calculateBuyOneGetOnePromotionDiscount } from 'src/utils/pos/promotion/calculateBuyOneGetOnePromotionDiscount';
import { calculatePromoCodeDiscount } from 'src/utils/promoCode/calculatePromoCodeDiscount';
import { isBuyOneGetOnePromotion } from 'src/utils/promotion/isBuyOneGetOnePromotion';
import { zeroStringToUndefined } from 'src/utils/string/zeroStringToUndefined';

export function createPaymentDistribution(request: CreatePaymentDistributionRequest): Payment {
    const payment = createEmptyPayment();
    calculateSubtotal(payment, request);
    calculateDeliveryCost(payment, request);
    calculateProductDiscount(payment, request);
    calculatePromotionsDiscount(payment, request);
    calculateDiscount(payment, request);
    calculateTax(payment, request);
    calculateTips(payment, request);
    usePromoCode(payment, request);
    useCustomerCredits(payment, request);

    cleanPayment(payment);
    return payment;
}

function createEmptyPayment(): Payment {
    return {
        subtotal: '0',
        subtotalAfterDiscount: '0',
        total: '0',
        tips: [],
        usedPromotions: [],
    };
}

function calculateSubtotal(payment: Payment, request: CreatePaymentDistributionRequest): void {
    const { orderItems } = request;
    orderItems.forEach((orderItem) => {
        const orderItemSubtotal = roundDigits(calculateOrderItemSubtotalPrice(orderItem));
        payment.subtotal = BigNumber(payment.subtotal).plus(orderItemSubtotal).toString();
        payment.subtotalAfterDiscount = BigNumber(payment.subtotalAfterDiscount).plus(orderItemSubtotal).toString();
        payment.total = payment.subtotal;
    });
}

function calculateProductDiscount(payment: Payment, request: CreatePaymentDistributionRequest): void {
    const { orderItems } = request;
    orderItems.forEach((orderItem) => {
        const orderItemProductDiscount = calculateOrderItemProductDiscount(orderItem);
        payment.productDiscount = BigNumber(payment.productDiscount ?? 0)
            .plus(orderItemProductDiscount)
            .toString();
        payment.subtotalAfterDiscount = BigNumber(payment.subtotalAfterDiscount ?? 0)
            .plus(orderItemProductDiscount)
            .toString();
        payment.total = BigNumber(payment.total)
            .minus(orderItemProductDiscount ?? 0)
            .toString();
    });
}

function calculatePromotionsDiscount(payment: Payment, request: CreatePaymentDistributionRequest): void {
    if (!request.promotions?.length) return;

    const validPromotions = request.promotions.filter((promotion) => promotion.orderTypes.includes(request.orderType!));

    for (const promotion of validPromotions) {
        if (isBuyOneGetOnePromotion(promotion.promotionType)) calculateBuyOneGetOnePromotionDiscount(payment, request, promotion);
    }
}

function calculateDiscount(payment: Payment, request: CreatePaymentDistributionRequest): void {
    const { discount } = request;
    if (!discount) return;

    let discountAmount = BigNumber(0);

    const isDiscountForOrderItems = request.orderItems.some((orderItem) => !!orderItem.posDiscountPercentage);

    if (discount.discountType === DiscountTypes.AMOUNT) discountAmount = discountAmount.plus(discount.discount);

    if (discount.discountType === DiscountTypes.PERCENT) {
        discountAmount = BigNumber(discount.discountPercentage ?? '0')
            .dividedBy(100)
            .multipliedBy(payment.total);
    }

    if (isDiscountForOrderItems) {
        discountAmount = BigNumber(0);
        request.orderItems.forEach((orderItem) => {
            if (!orderItem.posDiscountPercentage) return orderItem;
            discountAmount = discountAmount.plus(BigNumber(orderItem.posDiscount ?? 0));
        });
    }

    payment.posDiscount = discountAmount.toFixed(2);
    payment.posDiscountPercentage = discount.discountPercentage;
    payment.subtotalAfterDiscount = BigNumber(payment.subtotalAfterDiscount).minus(discountAmount).toString();
    payment.total = BigNumber(payment.total).minus(discountAmount).toString();
}

function calculateDeliveryCost(payment: Payment, request: CreatePaymentDistributionRequest): void {
    const { orderType, driverRequest } = request;

    if (orderType === OrderTypes.DELIVERY_ORDER) {
        payment.total = BigNumber(payment.total)
            .plus(driverRequest?.deliveryPaidByRestaurant ? 0 : (driverRequest?.deliveryCost ?? 0))
            .toString();
    }
}

function usePromoCode(payment: Payment, request: CreatePaymentDistributionRequest): void {
    const { promoCode, orderType, driverRequest, orderItems } = request;
    if (!promoCode) return;

    payment.promoCodeSubtotal = payment.total;
    const promoCodeDiscount = calculatePromoCodeDiscount(promoCode, orderType, payment.total, driverRequest?.deliveryCost, orderItems);
    if (promoCode.rewardType === RewardTypes.CREDITS) {
        payment.promoCodeCredits = promoCodeDiscount;
    } else {
        payment.promoCodeDiscount = promoCodeDiscount;
        payment.subtotalAfterDiscount = BigNumber(payment.subtotalAfterDiscount)
            .minus(payment.promoCodeDiscount ?? 0)
            .toString();
        payment.total = BigNumber(payment.total)
            .minus(payment.promoCodeDiscount ?? 0)
            .toString();
    }
    payment.promoCode = promoCode.code;
}

function calculateTax(payment: Payment, request: CreatePaymentDistributionRequest): void {
    const { taxRate } = request;
    if (!taxRate) return;

    payment.tax = BigNumber(payment.total)
        .multipliedBy(taxRate ?? 0)
        .dividedBy(100)
        .decimalPlaces(2)
        .toString();
    payment.total = BigNumber(payment.total)
        .plus(payment.tax ?? 0)
        .toString();
}

function calculateTips(payment: Payment, request: CreatePaymentDistributionRequest): void {
    const { tips } = request;

    if (!tips) return;

    for (const tip of tips) {
        let tipAmount = BigNumber(0);

        if (tip.tipType === TipTypes.AMOUNT) tipAmount = tipAmount.plus(BigNumber(tip.tipAmount ?? 0));

        if (tip.tipType === TipTypes.PERCENT) {
            tipAmount = tipAmount.plus(BigNumber(tip.tipAmount ?? 0));
        }

        const posTip = {
            tipType: tip.tipType,
            tipPercentage: tip.tipPercentage,
            paymentMethod: tip.paymentMethod,
            customPaymentMethod: tip.customPaymentMethod,
            tipAmount: tipAmount.toFixed(2),
            paymentId: tip.paymentId,
        } as const;

        const existingTip = payment?.tips?.find((existingTip: PosTip) => existingTip.paymentMethod === tip.paymentMethod && existingTip.customPaymentMethod === tip.customPaymentMethod);

        if (existingTip) {
            existingTip.tipAmount = BigNumber(existingTip?.tipAmount ?? 0)
                .plus(tipAmount.toFixed(2))
                .toString();
            continue;
        }
        payment.tips?.push(posTip);
    }
    const totalTips = payment.tips
        ?.map((posTip: PosTip) => BigNumber(posTip.tipAmount ?? 0))
        .reduce((totalTip: BigNumber, currentTip: BigNumber) => BigNumber(totalTip).plus(BigNumber(currentTip)), BigNumber(0));

    payment.total = BigNumber(payment.total)
        .plus(totalTips ?? 0)
        .toString();
}

function useCustomerCredits(payment: Payment, request: CreatePaymentDistributionRequest): void {
    const { usedCustomerCredits } = request;

    if (!usedCustomerCredits) return;

    let creditsToUse = BigNumber(usedCustomerCredits ?? 0)
        .minus(payment.usedNonGiftCredits ?? 0)
        .toString();

    if (BigNumber(creditsToUse).isZero()) return;
    if (BigNumber(payment.total).isZero() && !BigNumber(creditsToUse).isNegative()) return;
    if (BigNumber(creditsToUse).isGreaterThanOrEqualTo(payment.total)) {
        creditsToUse = payment.total;
    }

    payment.usedNonGiftCredits = BigNumber(payment.usedNonGiftCredits ?? 0)
        .plus(creditsToUse)
        .toString();
    payment.usedLetsEatCredits = BigNumber(payment.usedLetsEatCredits ?? 0)
        .plus(creditsToUse)
        .toString();
    payment.usedCredits = BigNumber(payment.usedCredits ?? 0)
        .plus(creditsToUse)
        .toString();

    payment.total = BigNumber(payment.total).minus(creditsToUse).toString();
}

function calculateDeliveryCashHandlingFee(payment: Payment, request: CreatePaymentDistributionRequest): void {
    const isOrderPaidInCash = !!request.payments?.some((payment) => isCashPayment(payment.paymentMethod) && !payment.customPaymentMethod);
    const isCashPaymentMethodSelected = isCashPayment(request.paymentMethod) && !request.customPaymentMethod;
    const isCashOrder = isCashPaymentMethodSelected || isOrderPaidInCash;

    if (!isCashOrder || !isMexico(request.country) || !isDeliveryOrder(request.orderType) || request.driverRequest?.externalDelivery) return;

    const deliveryCashHandlingFee = roundDigits(BigNumber(payment.subtotalAfterDiscount).multipliedBy(0.025).toString());

    payment.total = BigNumber(payment.total).plus(deliveryCashHandlingFee).toString();
    payment.deliveryCashHandlingFee = deliveryCashHandlingFee;
}

function cleanPayment(payment: Payment): void {
    payment.productDiscount = zeroStringToUndefined(payment.productDiscount);
    payment.promoCodeSubtotal = zeroStringToUndefined(payment.promoCodeSubtotal);
    payment.promoCodeDiscount = zeroStringToUndefined(payment.promoCodeDiscount);
    payment.promoCodeCredits = zeroStringToUndefined(payment.promoCodeCredits);
    payment.promoCode = zeroStringToUndefined(payment.promoCode);
    payment.usedNonGiftCredits = zeroStringToUndefined(payment.usedNonGiftCredits);
    payment.usedLetsEatCredits = zeroStringToUndefined(payment.usedLetsEatCredits);
    payment.usedCredits = zeroStringToUndefined(payment.usedCredits);
}

export type CreatePaymentDistributionRequest = {
    orderItems: Array<CartItemVm>;
    promotions?: Array<PromotionVm>;
    payments?: Array<PosPayment>;
    country?: Country;
    paymentMethod?: PaymentMethod;
    customPaymentMethod?: CustomPaymentMethod;
    discount?: {
        discountType: DiscountType;
        discount: string;
        discountPercentage?: string;
        notes?: string;
    };
    tips?: Array<PosTip>;
    taxRate?: string;
    promoCode?: PromoCodeVm;
    driverRequest?: PosDriverRequest;
    orderType?: OrderType;
    usedCustomerCredits?: string;
};

export type Payment = {
    subtotal: string;
    subtotalAfterDiscount: string;
    total: string;
    tax?: string;
    productDiscount?: string;
    promotionsDiscount?: string;
    posDiscount?: string;
    posDiscountPercentage?: string;
    posTip?: PosTip;
    tips?: Array<PosTip>;
    usedPromotions: Array<{
        promotionId: PromotionId;
        menuItemId: MenuItemId;
        cartItemKey?: string;
    }>;
    deliveryCashHandlingFee?: string;
    promoCodeSubtotal?: string;
    promoCodeDiscount?: string;
    promoCodeCredits?: string;
    promoCode?: string;
    usedNonGiftCredits?: string;
    usedLetsEatCredits?: string;
    usedCredits?: string;
};
