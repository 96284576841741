import { letseatadminApiMethod } from 'src/api/letseatadminApi';
import type { ApiSauceResponse } from 'src/api/types/ApiSauceResponse';
import type { DriverManualEarningReason } from 'src/constants/DriverManualEarningReason';
import type { DriverManualEarningVm } from 'src/types/DriverManualEarningVm';
import type { DriverId } from 'src/types/Id';

export async function createDriverManualEarningApi(request: CreateDriverManualEarningApiRequest): ApiSauceResponse<DriverManualEarningVm> {
    return letseatadminApiMethod('driverManualEarning/createDriverManualEarningApi', request);
}

export type CreateDriverManualEarningApiRequest = {
    driverId: DriverId;
    title: string;
    body?: string;
    comment?: string;
    amount: string;
    reason?: DriverManualEarningReason;
};
