import type { CartItemVm } from 'src/types/CartItemVm';
import type { OrderItemVm as OrderItems } from 'src/types/OrderVm';
import type { MenuItemVm } from 'src/types/PosMenuVm';
import { mapOrderItemToCartItem } from 'src/utils/pos/mapOrderItemToCartItem';

export function isOrderItemPrinted(currentItem: CartItemVm, items: Array<OrderItems>, menuItems: Array<MenuItemVm>): boolean {
    return items.some((item) => {
        const menuItem = menuItems?.find((menuItem: MenuItemVm) => menuItem.menuItemId === item.menuItemId);
        if (!menuItem) return false;

        const savedItem = mapOrderItemToCartItem(menuItem, item);
        return savedItem.key === currentItem.key;
    });
}
