import Button from '@material-ui/core/Button';
import Tooltip from '@material-ui/core/Tooltip';
import * as React from 'react';
import { useSelector } from 'src/utils/react/useSelector';

export function HardDeleteToolbarButton({ text, tooltip, onClick, enabledForUserTypeView = false }: Props): React.ReactElement {
    const viewUser = useSelector((state) => state.authentication.viewUser);

    return (
        <Tooltip title={tooltip!}>
            <Button onClick={onClick} variant='outlined' style={{ backgroundColor: 'red', color: 'white' }} disabled={viewUser && !enabledForUserTypeView}>
                {text}
            </Button>
        </Tooltip>
    );
}

type Props = {
    text: string;
    tooltip?: string;
    onClick: any;
    enabledForUserTypeView?: boolean;
};
