import * as React from 'react';
import { removeReleaseNotesApi } from 'src/api/letseatadmin/releaseNotes/removeReleaseNotesApi';
import { RemoveDialog } from 'src/components/dialog/RemoveDialog';
import { translate } from 'src/i18n/translate';
import type { ReleaseNotesVm } from 'src/types/ReleaseNotesVm';
import { alertKnownErrorOrSomethingWentWrong } from 'src/utils/alert/alertKnownErrorOrSomethingWentWrong';

export function RemoveReleaseNotesDialog({ open, releaseNotes, onClose, onSuccess }: Props): React.ReactElement {
    const handleSubmit = async () => {
        if (!releaseNotes) return;
        const response = await removeReleaseNotesApi({
            releaseNotesId: releaseNotes?.releaseNotesId,
        });
        if (!response.ok) {
            alertKnownErrorOrSomethingWentWrong(response);
            return;
        }
        onSuccess?.();
        onClose?.();
    };

    return <RemoveDialog open={open} title={translate('Remove release notes @releaseNotesTitle', { releaseNotesTitle: releaseNotes?.title })} onSubmit={handleSubmit} onClose={onClose} />;
}

type Props = {
    open: boolean;
    releaseNotes?: ReleaseNotesVm;
    onClose: any;
    onSuccess?: any;
};
