import { makeStyles } from '@material-ui/core';
import * as React from 'react';
import { TotalOrdersInDeliveryTimeCategoryGroupedByApp } from 'src/api/letseatmanager/restaurantDashboard/getDeliveryRestaurantStatisticsApi';
import { PieChartCanvas } from 'src/components/charts/PieChartCanvas';
import { translate } from 'src/i18n/translate';
import { ReportSection } from 'src/scenes/letseatmanager/restaurantDashboard/ReportSection';

export function DeliveryByProductReport({ totalOrdersInDeliveryTimeCategoryGroupedByApp }: Props): React.ReactElement {
    const classes = useStyles();

    return (
        <ReportSection title={translate('By Product')} classes={{ container: classes.resumeContainer }}>
            <div className={classes.pieChartContainer}>
                <PieChartCanvas
                    data={totalOrdersInDeliveryTimeCategoryGroupedByApp?.map((order) => ({ id: order.app, label: translate(`Apps.${order.app}`), value: order.orders }))}
                    sortByValue
                    tooltip={(e: any) => {
                        const { id, value } = e.datum;
                        return (
                            <div className={classes.tooltip}>
                                <span className={classes.tooltipTitle}>{translate(`Apps.${id}`)}</span>
                                <span className={classes.tooltipText}>{translate('Orders: @orders', { orders: value })}</span>
                            </div>
                        );
                    }}
                    margin={{
                        top: 12,
                        right: 12,
                        bottom: 12,
                        left: 12,
                    }}
                />
            </div>
        </ReportSection>
    );
}

const useStyles = makeStyles((theme) => ({
    resumeContainer: {
        width: 'fit-content',
        marginBottom: '10%',
        [theme.breakpoints.up('lg')]: {
            marginBottom: 0,
        },
    },
    pieChartContainer: {
        width: 300,
        height: 300,
        display: 'flex',
        displayFlex: 'column',
        [theme.breakpoints.up('lg')]: {
            width: 350,
            height: 350,
        },
    },
    tooltip: {
        padding: 10,
        borderRadius: 3,
        backgroundColor: 'white',
        boxShadow: '1px 1px 1px rgb(0,0,0,0.2)',
        display: 'flex',
        flexDirection: 'column',
    },
    tooltipTitle: {
        fontFamily: theme.typography.bold,
    },
    tooltipText: {
        fontFamily: theme.typography.regular,
    },
}));

type Props = {
    totalOrdersInDeliveryTimeCategoryGroupedByApp: Array<TotalOrdersInDeliveryTimeCategoryGroupedByApp>;
};
