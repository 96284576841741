import { InputBase, InputLabel, makeStyles } from '@material-ui/core';
import FormHelperText from '@material-ui/core/FormHelperText';
import useAutocomplete from '@material-ui/lab/useAutocomplete';
import * as React from 'react';
import { useEffect, useRef, useState } from 'react';
import { createPortal } from 'react-dom';
import type { Variant } from 'src/components/form/FormTypes';
import { debounce } from 'src/utils/function/debounce';
import { classNames } from 'src/utils/react/classNames';

export function CustomizableAutocomplete({
    label,
    name,
    placeholder,
    helperText,
    defaultValue = null,
    data,
    renderOption,
    getOptionValue,
    getOptionDisabled,
    getOptionLabel,
    onChange,
    disabled,
    error,
    classes: classesProp,
}: Props): React.ReactElement {
    const classes = useStyles();
    const listboxContainerRef = useRef<HTMLDivElement>(null);

    const [inputValue, setInputValue] = useState('');
    const [showOptions, setShowOptions] = useState(false);

    const { getRootProps, getInputProps, getListboxProps, getOptionProps, groupedOptions } = useAutocomplete({
        id: name,
        options: data,
        getOptionLabel,
        getOptionDisabled,
    });

    useEffect(() => {
        if (defaultValue) {
            setInputValue(defaultValue);
        }
    }, [defaultValue]);

    const handleItem = (item: any) => {
        if (getOptionDisabled?.(item)) return;

        setInputValue(getOptionLabel(item));
        onChange(getOptionValue(item));
    };

    const getListboxTopPosition = () => {
        if (!listboxContainerRef.current) return 0;

        const clientRect = listboxContainerRef.current.getBoundingClientRect();
        return clientRect.top + 10;
    };

    const getListboxLeftPosition = () => {
        if (!listboxContainerRef.current) return 0;

        const clientRect = listboxContainerRef.current.getBoundingClientRect();
        return clientRect.left;
    };

    const debouncedOnChange = debounce((getInputProps() as any).onChange, 700);

    return (
        <div>
            <div {...getRootProps()}>
                <InputLabel htmlFor={`use-autocomplete-customer`} classes={{ root: classes.label, error: classes.labelError }} error={!!error}>
                    {label}
                </InputLabel>
                <InputBase
                    {...getInputProps()}
                    type='search'
                    classes={{ root: classes.input, error: classes.inputError }}
                    value={inputValue}
                    onChange={(e) => {
                        setInputValue(e.target.value);
                        debouncedOnChange(e);
                    }}
                    onBlur={(e) => {
                        setShowOptions(false);
                        (getInputProps() as any).onBlur(e);
                    }}
                    onFocus={(e) => {
                        setShowOptions(true);
                        (getInputProps() as any).onFocus(e);
                    }}
                    placeholder={placeholder}
                    inputProps={{ autoComplete: 'off' }}
                    disabled={disabled}
                />
                {!!helperText && <FormHelperText classes={{ root: classes.helperText }}>{helperText}</FormHelperText>}
            </div>
            <div ref={listboxContainerRef} style={{ width: '100%' }}>
                {groupedOptions.length > 0 &&
                    showOptions &&
                    createPortal(
                        <ul
                            className={classNames(classes.listbox, classesProp?.optionsContainer)}
                            {...getListboxProps()}
                            style={{ top: getListboxTopPosition(), left: getListboxLeftPosition(), width: listboxContainerRef.current?.offsetWidth }}
                        >
                            {groupedOptions.map((option: any, index: number) => (
                                <li {...getOptionProps({ option, index })} onClick={() => handleItem(option)} className={classesProp?.optionContainer}>
                                    {renderOption(option)}
                                </li>
                            ))}
                        </ul>,
                        document.body,
                    )}
            </div>
        </div>
    );
}

const useStyles = makeStyles((theme) => ({
    label: {
        fontFamily: theme.typography.regular,
        color: theme.palette.secondary.contrastText,
        fontSize: 12,
        marginBottom: 8,
        [theme.breakpoints.up('sm')]: {
            fontSize: 14,
        },
    },
    labelError: {
        color: 'red',
    },
    input: {
        width: '100%',
        backgroundColor: 'transparent',
        borderRadius: '4px',
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
        fontFamily: theme.typography.regular,
        border: `1px solid ${theme.palette.secondary.dark}`,
        height: '42px',
        alignItems: 'center',
        display: 'flex',
        [theme.breakpoints.down('sm')]: {
            width: '100%',
        },
    },
    inputError: {
        border: '2px solid red',
    },
    helperText: {
        fontFamily: theme.typography.light,
        color: 'red',
    },
    listbox: {
        width: 250,
        margin: 0,
        padding: 0,
        zIndex: 10000,
        position: 'absolute',
        listStyle: 'none',
        backgroundColor: theme.palette.background.paper,
        overflow: 'auto',
        maxHeight: '40vh',
        border: '1px solid rgba(0,0,0,.25)',
        '& li[data-focus="true"]': {
            backgroundColor: '#4a8df6',
            color: 'white',
            cursor: 'pointer',
        },
        '& li:active': {
            backgroundColor: '#2977f5',
            color: 'white',
        },
    },
    itemContainer: {
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        minHeight: 60,
        padding: '5px 10px',
    },
    mobileNumberContainer: {
        display: 'flex',
        flexDirection: 'row',
        width: '100%',
        alignItems: 'center',
        justifyContent: 'space-between',
    },
    infoContainer: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
    },
    numberOfOrders: {
        color: theme.palette.primary.main,
        fontSize: 12,
        marginLeft: 5,
        fontFamily: theme.typography.regular,
    },
    itemText: {
        fontFamily: theme.typography.regular,
        fontSize: 15,
    },
}));

export type Props = {
    label?: string;
    name: string;
    placeholder?: string;
    helperText?: string;
    defaultValue?: string | null | undefined;
    data: Array<any>;
    getOptionValue: (arg1?: any) => any;
    getOptionLabel: (arg1?: any) => any;
    getOptionDisabled?: (arg1?: any) => boolean;
    renderOption: (arg1?: any) => React.ReactElement;
    disabled?: boolean;
    error?: boolean;
    required?:
        | boolean
        | {
              value: number;
              message: string;
          };
    variant?: Variant;
    InputProps?: any;
    onChange: any;
    tooltip?: string;
    classes?: {
        optionsContainer?: string;
        optionContainer?: string;
    };
};
