import { usePosCartModifiersStore } from 'src/services/pos/posModifiersCart/posCartModifiersStore';
import type { CartModifierVm } from 'src/types/CartModifierVm';

export function usePosCartModifiers(): PosCartModifiers {
    const posCartModifiers = usePosCartModifiersStore((state) => state.posCartModifiers);

    return posCartModifiers;
}

export type PosCartModifiers = {
    [key: string]: Array<CartModifierVm>;
};
