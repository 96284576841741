import { makeStyles } from '@material-ui/core';
import * as React from 'react';
import { useState } from 'react';
import { SecuredAndSubscribedPage } from 'src/components/page/SecuredAndSubscribedPage';
import { Tabs } from 'src/components/Tabs';
import { RolePermissions } from 'src/constants/RolePermission';
import { translate } from 'src/i18n/translate';
import { KitchenPreparedOrders } from 'src/scenes/letseatmanager/kitchenDisplayScreen/KitchenPreparedOrders';
import { KitchenPreparingOrders } from 'src/scenes/letseatmanager/kitchenDisplayScreen/KitchenPreparingOrders';
import { useSelector } from 'src/utils/react/useSelector';

export function KitchenDisplayScreenPage(): React.ReactElement {
    const classes = useStyles();

    const [isPendingOrderTabSelected, setIsPendingOrderSelected] = useState(true);
    const preparingKitchenOrders = useSelector((state) => state.app2.preparingKitchenOrders);

    const tabs = [
        {
            label: translate('Pending Orders'),
            value: '0',
            onClick: () => setIsPendingOrderSelected(true),
        },

        {
            label: translate('Completed Orders'),
            value: '1',
            onClick: () => setIsPendingOrderSelected(false),
        },
    ];

    return (
        <SecuredAndSubscribedPage title={translate('Kitchen Display Screen')} rolePermission={RolePermissions.KITCHEN_DISPLAY_SCREEN_PAGE}>
            <Tabs classes={{ tab: classes.tab, headerTabs: isPendingOrderTabSelected && preparingKitchenOrders.length ? classes.headerTabs : '', tabs: classes.tabs }} tabs={tabs}>
                <KitchenPreparingOrders />
                <KitchenPreparedOrders />
            </Tabs>
        </SecuredAndSubscribedPage>
    );
}

const useStyles = makeStyles((theme) => ({
    headerTabs: {
        width: 'calc(100% - 310px)',
    },
    tabs: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    tab: {
        width: 'fit-content',
    },
}));
