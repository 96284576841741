import Card from '@material-ui/core/Card';
import Grid from '@material-ui/core/Grid';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { DriverVm, findDriversApi } from 'src/api/letseatadmin/driver/findDriversApi';
import { findRestaurantLocationsApi, RestaurantVm } from 'src/api/letseatadmin/restaurant/findRestaurantLocationsApi';
import { app2 } from 'src/app2';
import { Form } from 'src/components/form/Form';
import { FormCheckbox } from 'src/components/form/FormCheckbox';
import { FormNumberField } from 'src/components/form/FormNumberField';
import { FormSubmitButton } from 'src/components/form/FormSubmitButton';
import { FormTextField } from 'src/components/form/FormTextField';
import { GoogleMapsDriverPositions } from 'src/components/googlemaps/GoogleMapsDriverPositions';
import { DriverAvailabilities } from 'src/constants/DriverAvailability';
import { RoutePaths } from 'src/constants/RoutePath';
import { TimeZones } from 'src/constants/TimeZone';
import { translate } from 'src/i18n/translate';
import { ChangeDriverDialog } from 'src/scenes/letseatadmin/driver/ChangeDriverDialog';
import { DriversChangeDialog } from 'src/scenes/letseatadmin/driver/DriversChangeDialog';
import { DriverSendDialog } from 'src/scenes/letseatadmin/driver/DriverSendDialog';
import { DriverSendEmailDialog } from 'src/scenes/letseatadmin/driver/DriverSendEmailDialog';
import { DriversSendDialog } from 'src/scenes/letseatadmin/driver/DriversSendDialog';
import { DriversSendEmailDialog } from 'src/scenes/letseatadmin/driver/DriversSendEmailDialog';
import { DriversTable } from 'src/scenes/letseatadmin/driver/DriversTable';
import { alertKnownErrorOrSomethingWentWrong } from 'src/utils/alert/alertKnownErrorOrSomethingWentWrong';
import { isToday } from 'src/utils/date/isToday';
import { getDriverAvailability } from 'src/utils/driver/getDriverAvailability';
import { useAction } from 'src/utils/react/useAction';
import { useSelector } from 'src/utils/react/useSelector';

export function DriversPage(): React.ReactElement {
    const setTitle = useAction(app2.actions.setTitle);
    const [drivers, setDrivers] = useState<Array<DriverVm>>([]);
    const [restaurants, setRestaurants] = useState<Array<RestaurantVm>>([]);
    const [reload, setReload] = useState(0);
    const [showMap, setShowMap] = useState();
    const cities = useSelector((state) => state.app2.cities);
    const form = useForm();
    const {
        formState: { isSubmitting },
    } = form;

    const onSubmit = async (form: any) => {
        setShowMap(form.showMap);
        const response = await findDriversApi({
            query: form.query,
            cities,
            batch: form.batch,
            size: form.size,
        });
        if (!response.ok) {
            alertKnownErrorOrSomethingWentWrong(response);
            return;
        }
        setDrivers(response.data.drivers);

        if (!form.showMap) {
            setRestaurants([]);
            setReload((reload) => reload + 1);
            return;
        }

        const response2 = await findRestaurantLocationsApi({
            cities,
        });
        if (response2.ok) {
            setRestaurants(response2.data.restaurants);
        }
        setReload((reload) => reload + 1);
    };

    const submit = () => form.handleSubmit((form) => onSubmit(form))();

    useEffect(() => {
        setTitle(translate('Drivers'));
    }, []);

    return (
        <>
            <ChangeDriverDialog onDriverChanged={submit} />
            <DriversChangeDialog onDriversChanged={submit} path={RoutePaths.ADMIN_DRIVERS_CHANGE} />
            <DriverSendDialog />
            <DriversSendDialog path={RoutePaths.ADMIN_DRIVERS_SEND_NOTIFICATIONS} />
            <DriverSendEmailDialog />
            <DriversSendEmailDialog path={RoutePaths.ADMIN_DRIVERS_SEND_EMAILS} />
            <Grid container spacing={5}>
                <Grid item xs={12}>
                    <Card style={{ padding: 24 }}>
                        <Form form={form} onSubmit={onSubmit}>
                            <Grid container spacing={5}>
                                <Grid item xs={12} md={3}>
                                    <FormTextField name='query' label={translate('Search ')} />
                                </Grid>
                                <Grid item xs={12} md={3}>
                                    <FormCheckbox name='showMap' label={translate('Show map with online drivers located today')} helperText={translate('Colored are located within 30 minutes')} />
                                </Grid>
                                <Grid item xs={12} md={3}>
                                    <FormNumberField name='batch' label={translate('Batch')} min={1} />
                                    <FormNumberField name='size' label={translate('Batch Size')} />
                                </Grid>
                                <Grid item xs={12} md={2} container justify='flex-start'>
                                    <FormSubmitButton text={isSubmitting ? translate('Searching').toUpperCase() : translate('Search').toUpperCase()} />
                                </Grid>
                            </Grid>
                        </Form>
                    </Card>
                </Grid>
                <Grid item xs={12}>
                    {showMap && !!drivers.length && (
                        <GoogleMapsDriverPositions
                            drivers={drivers.filter(
                                (driver) =>
                                    driver.position &&
                                    getDriverAvailability(driver) === DriverAvailabilities.ONLINE &&
                                    driver.positionedAt &&
                                    isToday(driver.positionedAt, TimeZones.AMERICA_MONTERREY),
                            )}
                            restaurants={restaurants}
                            reload={reload}
                        />
                    )}
                    {!!drivers.length && <DriversTable drivers={drivers} loading={isSubmitting} onClickRefresh={submit} title=' ' />}
                </Grid>
            </Grid>
        </>
    );
}
