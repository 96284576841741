import CryptoJS from 'crypto-js';
import type { Md5Hash } from 'src/types/Id';
import { pretty } from 'src/utils/string/pretty';

export function hashMd5FromArrayBuffer(value?: string | ArrayBuffer | null): Md5Hash {
    if (!value) {
        throw Error(`Cannot create hash md5 from ${pretty(value)}`);
    }
    return CryptoJS.MD5(CryptoJS.lib.WordArray.create(value as any)).toString() as Md5Hash;
}
