import { makeStyles } from '@material-ui/core';
import { UiLogEventTracker } from '@pidedirecto/ui';
import { createContext, useEffect, useRef, useState } from 'react';
import * as React from 'react';
import { app2 } from 'src/app2';
import { createUiInteractionLogEvent } from 'src/services/logEvent/createUiInteractionLogEvent';
import { createUserOpenedPageLogEvent } from 'src/services/logEvent/createUserOpenedPageLogEvent';
import { normalizeUiStackTrace } from 'src/services/logEvent/normalizeUiStackTrace';
import { classNames } from 'src/utils/react/classNames';
import { useAction } from 'src/utils/react/useAction';
import { newUuid } from 'src/utils/uuid/newUuid';

export function Page({ title, children, className }: Props): React.ReactElement {
    const classes = useStyles();

    const stackTraceRef = useRef(normalizeUiStackTrace(`page_${title}`));
    const [stackTrace, setStackTrace] = useState(normalizeUiStackTrace(`page_${title}`));
    const [pageSessionId, setPageSessionId] = useState<string>(newUuid() as string);

    const setTitle = useAction(app2.actions.setTitle);

    useEffect(() => {
        setTitle(title);
    }, [title]);

    useEffect(() => {
        createUserOpenedPageLogEvent({ title, stackTrace });
    }, []);

    const addElementToStackTrace = (element: any) => {
        let previousStackTrace = stackTrace;
        if (previousStackTrace !== stackTraceRef.current) previousStackTrace = stackTraceRef.current;

        if (previousStackTrace.includes(element)) return;

        setStackTrace(`${previousStackTrace}/${element}`);
        stackTraceRef.current = `${previousStackTrace}/${element}`;
    };

    const clearStackTrace = () => {
        stackTraceRef.current = normalizeUiStackTrace(`page_${title}`);
        setStackTrace(normalizeUiStackTrace(`page_${title}`));
    };

    return (
        // TODO: The UiLogEventTracker is the current implementation of @pidedirecto/ui library for log events
        //  so remove all this logic inside the component, functions and hooks from the project until all components have been migrated to the library
        <UiLogEventTracker id={normalizeUiStackTrace(`page_${title}`)} onInteract={createUiInteractionLogEvent}>
            <PageContext.Provider
                value={{
                    stackTrace,
                    addElementToStackTrace,
                    clearStackTrace,
                    pageSessionId,
                    setPageSessionId,
                }}
            >
                <div className={classNames(classes.container, className ?? '')} onClick={clearStackTrace}>
                    {children}
                </div>
            </PageContext.Provider>
        </UiLogEventTracker>
    );
}

export const PageContext = createContext<PageContextType>({
    stackTrace: '',
    addElementToStackTrace: () => {},
    clearStackTrace: () => {},
    pageSessionId: '',
    setPageSessionId: () => {},
});

const useStyles = makeStyles((theme) => ({
    container: {
        width: '100%',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
    },
}));

type Props = {
    title: string;
    children: React.ReactNode;
    className?: string;
};

export type PageContextType = {
    stackTrace: string;
    addElementToStackTrace: any;
    clearStackTrace: any;
    pageSessionId: string;
    setPageSessionId: any;
};
