import Grid from '@material-ui/core/Grid';
import moment from 'moment';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { Route, Switch } from 'react-router-dom';
import { app2 } from 'src/app2';
import { FilterState, FromToFilter } from 'src/components/input/FromToFilter';
import { RoutePaths } from 'src/constants/RoutePath';
import { translate } from 'src/i18n/translate';
import CompanyPayrollBillingData from 'src/scenes/letseatadmin/companyPayrollBilling/CompanyPayrollBillingData';
import CompanyPayrollBillingDataForCompany from 'src/scenes/letseatadmin/companyPayrollBilling/CompanyPayrollBillingDataForCompany';
import { useAction } from 'src/utils/react/useAction';

export function CompanyPayrollBillingPage(): React.ReactElement {
    const [filter, setFilter] = useState<FilterState>({
        from: moment().subtract(1, 'week').startOf('isoWeek').toDate(),
        to: moment().subtract(1, 'week').endOf('isoWeek').toDate(),
    });

    const setTitle = useAction(app2.actions.setTitle);

    useEffect(() => {
        setTitle(translate('Company Credits Billing'));
    }, []);

    const handleLoadCompany = (companyName: any) => setTitle(`${translate('Company Payroll Billing')} > ${companyName}`);

    const handleChangeFilter = (filter: { from: Date; to: Date }) => setFilter(filter);

    return (
        <Grid container spacing={5}>
            <Grid item xs={12}>
                <FromToFilter filter={filter} onChangeFilter={handleChangeFilter} />
            </Grid>
            <Grid item xs={12}>
                <Switch>
                    <Route
                        path={RoutePaths.ADMIN_COMPANY_PAYROLL_BILLING_FOR_COMPANY}
                        render={(props) => <CompanyPayrollBillingDataForCompany filter={filter} companyId={props.match.params.companyId} onLoad={handleLoadCompany} />}
                    />
                    <Route
                        path={RoutePaths.ADMIN_COMPANY_PAYROLL_BILLING}
                        render={() => {
                            setTitle(translate('Company Payroll Billing'));
                            return <CompanyPayrollBillingData filter={filter} />;
                        }}
                    />
                </Switch>
            </Grid>
        </Grid>
    );
}
