import { letseatmanagerApiMethod } from 'src/api/letseatmanagerApi';
import type { ApiSauceResponse } from 'src/api/types/ApiSauceResponse';
import type { CustomerVm } from 'src/scenes/letseatadmin/customer/CustomerDetails';
import type { RestaurantId } from 'src/types/Id';

export async function findCustomersApi(request: FindCustomersApiRequest): ApiSauceResponse<Array<CustomerVm>> {
    return letseatmanagerApiMethod('customer/findCustomersAndRestaurantCustomersApi', request);
}

type FindCustomersApiRequest = {
    restaurantId: RestaurantId;
};
