import { BigNumber } from 'bignumber.js';
import type { ModifierGroupVm } from 'src/types/CartItemVm';
import { calculateOrderItemSubModifierGroupsPrice } from 'src/utils/pos/calculateOrderItemSubModifierGroupsPrice';
import { sum } from 'src/utils/reduce/sum';

export function calculateOrderItemModifierGroupsPrice(modifierGroups: Array<ModifierGroupVm>): BigNumber {
    const modifierGroupsUpdated = modifierGroups.map((modifierGroup) => {
        if (!modifierGroup.freeModifiersQuantity) return modifierGroup;
        let freeModifiersQuantity = BigNumber(modifierGroup.freeModifiersQuantity ?? 0).toNumber();

        const sortedModifiers = modifierGroup.modifiers
            .filter((modifier) => modifier.quantity > 0)
            .sort((previousModifier, nextModifier) => Number(previousModifier.price) - Number(nextModifier.price));

        const updatedModifiers = sortedModifiers.map((modifier) => {
            const freeQuantity = Math.min(modifier.quantity, freeModifiersQuantity!);
            freeModifiersQuantity = BigNumber(freeModifiersQuantity!).minus(freeQuantity).toNumber();

            return {
                ...modifier,
                quantity: BigNumber(modifier.quantity!).minus(freeQuantity).toNumber(),
            };
        });

        return {
            ...modifierGroup,
            modifiers: updatedModifiers,
        };
    });

    return modifierGroupsUpdated
        .flatMap((modifierGroup) => modifierGroup.modifiers)
        .map((modifier) => {
            const subModifierGroupsPrice = calculateOrderItemSubModifierGroupsPrice(modifier.subModifierGroups);
            return BigNumber(modifier.price).plus(subModifierGroupsPrice).multipliedBy(modifier.quantity);
        })
        .reduce(sum, BigNumber(0));
}
