import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import * as React from 'react';
import { Table } from 'src/components/Table';
import { translate } from 'src/i18n/translate';
import { useFormatAsRestaurantCurrencyNumber } from 'src/services/restaurant/useFormatAsRestaurantCurrencyNumber';

export function SalesByAppTable({ salesByApp, title, loading }: Props): React.ReactElement {
    const formatAsCurrencyNumber = useFormatAsRestaurantCurrencyNumber();

    const channelColumns = [
        {
            name: 'day',
            label: translate('Day'),
            options: {
                filter: false,
            },
        },
        {
            name: 'total',
            label: translate('Total Sales by channel'),
            options: {
                filter: false,
            },
        },
        {
            name: 'channel',
            label: translate('Channel'),
            options: {
                filter: false,
            },
        },
        {
            name: 'creditCard',
            label: translate('CREDIT_CARD'),
            options: {
                filter: false,
            },
        },
        {
            name: 'cash',
            label: translate('CASH'),
            options: {
                filter: false,
            },
        },
    ];

    return (
        <Card>
            <CardContent>
                <Table
                    title={title}
                    data={salesByApp.map((salesByApp) => ({
                        day: salesByApp.createdAt,
                        total: formatAsCurrencyNumber(salesByApp.total),
                        channel: translate(salesByApp.channel),
                        creditCard: formatAsCurrencyNumber(salesByApp.creditCard),
                        cash: formatAsCurrencyNumber(salesByApp.cash),
                    }))}
                    loading={loading}
                    columns={channelColumns}
                    options={{
                        download: false,
                        tableBodyMaxHeight: '350px',
                        search: false,
                        responsive: 'standard',
                        selectableRows: 'none',
                        filter: false,
                        rowsPerPage: 100,
                        viewColumns: false,
                        print: false,
                    }}
                />
            </CardContent>
        </Card>
    );
}

type Props = {
    salesByApp: Array<SaleByApp>;
    loading?: boolean;
    title?: string;
};

type SaleByApp = {
    createdAt: string;
    total: number;
    channel: string;
    creditCard: string | number;
    cash: string | number;
};
