import { letseatmanagerApiMethod } from 'src/api/letseatmanagerApi';
import type { ApiSauceResponse } from 'src/api/types/ApiSauceResponse';
import { OrderType } from 'src/constants/OrderType';
import type { MenuItemId, RestaurantId, SupplyId } from 'src/types/Id';
import type { RecipeVm } from 'src/types/RecipeVm';

export async function createRecipeApi(request: CreateRecipeApiRequest): ApiSauceResponse<RecipeVm> {
    return letseatmanagerApiMethod('recipe/createRecipeApi', request);
}

export type CreateRecipeApiRequest = {
    menuItemIds: Array<MenuItemId>;
    restaurantId: RestaurantId;
    name: string;
    ingredients: Array<Ingredient>;
    averageWaste?: string;
    estimatedPrice?: string;
};

type Ingredient = {
    supplyId: SupplyId;
    quantity: string;
    excludedOrderTypes?: Array<OrderType>;
    waste?: number;
};
