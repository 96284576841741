import { makeStyles } from '@material-ui/core';
import * as React from 'react';
import { Page } from 'src/components/Page';
import { translate } from 'src/i18n/translate';
import CustomAlertDialogTable from 'src/scenes/letseatadmin/customAlertDialogs/CustomAlertDialogTable';

export function AlertDialogsPage(): React.ReactElement {
    const classes = useStyles();

    return (
        <div className={classes.container}>
            <Page title={translate('Alert Dialogs')}>
                <CustomAlertDialogTable />
            </Page>
        </div>
    );
}

const useStyles = makeStyles((theme) => ({
    container: {
        position: 'relative',
    },
}));
