import { RolePermission } from 'src/constants/RolePermission';
import { useRolePermissionActions } from 'src/modules/order/rolePermissionStore';

export function useAskForRolePermissionAuthorization() {
    const openAskForRolePermissionAuthorizationDialog = useRolePermissionActions((actions) => actions.openAskForRolePermissionAuthorizationDialog);

    const askForRolePermissionAuthorization = ({ rolePermission, subtitle, title }: AskForRolePermissionAuthorizationParams) => {
        return new Promise<boolean>((resolve) => {
            openAskForRolePermissionAuthorizationDialog({
                rolePermission,
                title,
                subtitle,
                onAuthorized: () => resolve(true),
                onUnauthorized: () => resolve(false),
            });
        });
    };

    return askForRolePermissionAuthorization;
}

type AskForRolePermissionAuthorizationParams = {
    rolePermission: RolePermission;
    title: string;
    subtitle?: string;
};
