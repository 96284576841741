import { makeStyles } from '@material-ui/core';
import moment from 'moment';
import * as React from 'react';
import { Stepper } from 'src/components/Stepper';
import { translate } from 'src/i18n/translate';
import type { RestaurantEmployeeDayShiftVm } from 'src/types/RestaurantEmployeeDayShiftVm';

export function PinCodeUserTimeLine({ employeeDayShift }: Props): React.ReactElement {
    const classes = useStyles();

    const pinCodeUserShiftDayTimeLine = [
        {
            label: `${translate('Clock in at')} ${moment(employeeDayShift?.clockedInAt).format('LLL')}`,
            value: 0,
        },
        {
            label: `${translate('Clock out at')} ${moment(employeeDayShift?.clockedOutAt).format('LLL')}`,
            value: 1,
        },
    ];

    return (
        <div className={classes.container}>
            <Stepper steps={pinCodeUserShiftDayTimeLine} activeStep={1} classes={{ stepIcon: classes.stepIcon, stepActive: classes.stepIconActive }} />
        </div>
    );
}

const useStyles = makeStyles((theme) => ({
    container: {
        borderTop: `1px solid ${theme.palette.border.primary}`,
        borderBottom: `1px solid ${theme.palette.border.primary}`,
        padding: '12px 0',
    },
    stepIcon: {
        width: 25,
        height: 25,
        borderRadius: '50%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        border: `1px solid #BAC0C2`,
        color: '#BAC0C2',
        '& > div': {
            width: 15,
            height: 15,
            borderRadius: 10,
            backgroundColor: '#BAC0C2',
        },
    },
    stepIconActive: {
        border: `1px solid ${theme.palette.primary.main}`,
        '& > div': {
            backgroundColor: theme.palette.primary.main,
        },
    },
    text: {
        fontFamily: theme.typography.regular,
        fontSize: 14,
        color: '#6D7B7F',
    },
    textBold: {
        fontFamily: theme.typography.medium,
        fontSize: 14,
    },
}));

type Props = {
    employeeDayShift: RestaurantEmployeeDayShiftVm;
};
