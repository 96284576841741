import * as React from 'react';
import { FormSelect } from 'src/components/form/FormSelect';
import type { Variant } from 'src/components/form/FormTypes';
import type { ArgentinianTax } from 'src/constants/ArgentinianTaxes';
import { MexicanIEPSTaxesPercentages } from 'src/constants/MexicanIEPSTaxesPercentage';
import { MexicanIVATaxesPercentages } from 'src/constants/MexicanIVATaxesPercentage';
import { MexicanTaxes, type MexicanTax } from 'src/constants/MexicanTaxes';
import { UruguayanIVATasaBasicaTaxesPercentages } from 'src/constants/UruguayanIVATasaBasicaTaxesPercentage';
import { UruguayanIVATasaMinimaTaxesPercentages } from 'src/constants/UruguayanIVATasaMinimaTaxesPercentage';
import { UruguayanTaxes, type UruguayanTax } from 'src/constants/UruguayanTaxes';
import { isUruguay } from 'src/utils/country/isUruguay';
import { useSelector } from 'src/utils/react/useSelector';

export function FormRestaurantTaxPercentSelect({ name, label, placeholder, helperText, defaultValue = null, disabled, required, variant, taxType }: Props): React.ReactElement {
    const restaurantCountry = useSelector((state) => state.app.restaurant.country);

    const mapMexicanTaxes = () => {
        if (taxType === MexicanTaxes.IVA) {
            return Object.keys(MexicanIVATaxesPercentages).map((key) => ({
                value: MexicanIVATaxesPercentages[key as keyof typeof MexicanIVATaxesPercentages],
                label: key,
            }));
        }

        return Object.keys(MexicanIEPSTaxesPercentages).map((key) => ({
            value: MexicanIEPSTaxesPercentages[key as keyof typeof MexicanIEPSTaxesPercentages],
            label: key,
        }));
    };

    const mapUruguayanTaxes = () => {
        if (taxType === UruguayanTaxes.IVA_TASA_BASICA) {
            return Object.keys(UruguayanIVATasaBasicaTaxesPercentages).map((key) => ({
                value: UruguayanIVATasaBasicaTaxesPercentages[key as keyof typeof UruguayanIVATasaBasicaTaxesPercentages],
                label: key,
            }));
        }

        return Object.keys(UruguayanIVATasaMinimaTaxesPercentages).map((key) => ({
            value: UruguayanIVATasaMinimaTaxesPercentages[key as keyof typeof UruguayanIVATasaMinimaTaxesPercentages],
            label: key,
        }));
    };

    return (
        <FormSelect
            name={name}
            label={label}
            placeholder={placeholder}
            helperText={helperText}
            options={isUruguay(restaurantCountry) ? mapUruguayanTaxes() : mapMexicanTaxes()}
            disabled={disabled}
            required={required}
            variant={variant}
            defaultValue={defaultValue}
        />
    );
}

type Props = {
    name: string;
    label?: string;
    placeholder?: string;
    helperText?: string;
    defaultValue?: string | number | null | undefined;
    disabled?: boolean;
    required?: boolean | { value: number; message: string };
    variant?: Variant;
    taxType: ArgentinianTax | MexicanTax | UruguayanTax;
};
