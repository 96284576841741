import { letseatmanagerApiMethod } from 'src/api/letseatmanagerApi';
import type { ApiSauceResponse } from 'src/api/types/ApiSauceResponse';
import { TerminalPaymentStatus } from 'src/constants/TerminalPaymentStatus';
import type { QpayTerminalInstructionId } from 'src/types/Id';

export async function deprecatedPollQpayPaymentTerminalInstructionResultApi(request: PollTerminalPaymentInstructionResultApiRequest): ApiSauceResponse<PollTerminalPaymentInstructionResultApiResult> {
    return letseatmanagerApiMethod('pos/pollTerminalPaymentInstructionResultApi', request);
}

export type PollTerminalPaymentInstructionResultApiRequest = {
    qpayTerminalInstructionId: QpayTerminalInstructionId;
};

export type PollTerminalPaymentInstructionResultApiResult = {
    terminalPaymentStatus: TerminalPaymentStatus;
    message?: string;
    total?: string;
};
