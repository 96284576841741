export const TicketSections = {
    LOGO: 'LOGO',
    HEADER_ORDER_TYPE: 'HEADER_ORDER_TYPE',
    CUSTOM_HEADER_TEXT: 'CUSTOM_HEADER_TEXT',
    BUSINESS_NAME: 'BUSINESS_NAME',
    RFC: 'RFC',
    FISCAL_ADDRESS: 'FISCAL_ADDRESS',
    RESTAURANT_EMAIL: 'RESTAURANT_EMAIL',
    RESTAURANT_PHONE: 'RESTAURANT_PHONE',
    RESTAURANT_SECONDARY_PHONE: 'RESTAURANT_SECONDARY_PHONE',
    ORDER_ID: 'ORDER_ID',
    CONSECUTIVE_ID: 'CONSECUTIVE_ID',
    ORDER_TYPE: 'ORDER_TYPE',
    CREATED_AT: 'CREATED_AT',
    CUSTOMER_NAME: 'CUSTOMER_NAME',
    CUSTOMER_PHONE: 'CUSTOMER_PHONE',
    CUSTOMER_ADDRESS: 'CUSTOMER_ADDRESS',
    TABLE_NUMBER: 'TABLE_NUMBER',
    WAITER_NAME: 'WAITER_NAME',
    CANCELLED_ITEMS: 'CANCELLED_ITEMS',
    SPLIT_GROUPED_ORDER_ITEMS: 'SPLIT_GROUPED_ORDER_ITEMS',
    SALES_INFO: 'SALES_INFO',
    PAYMENT_METHOD: 'PAYMENT_METHOD',
    PAYMENT_CHANGE: 'PAYMENT_CHANGE',
    DATE_OF_PRINT: 'DATE_OF_PRINT',
    INVOICE_LINK: 'INVOICE_LINK',
    INVOICE_QR: 'INVOICE_QR',
    CUSTOM_FOOTER_TEXT: 'CUSTOM_FOOTER_TEXT',
} as const;

export type TicketSection = (typeof TicketSections)[keyof typeof TicketSections];
