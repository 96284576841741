import { makeStyles } from '@material-ui/core';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { getRestaurantBillingApi, RestaurantBillingVm } from 'src/api/letseatadmin/restaurantBilling/getRestaurantBillingApi';
import { app2 } from 'src/app2';
import { Chip } from 'src/components/Chip';
import { OpenInNewTabToolbarButton } from 'src/components/mui-datatables/OpenInNewTabToolbarButton';
import { RefreshToolbarButton } from 'src/components/mui-datatables/RefreshToolbarButton';
import { Text } from 'src/components/Text';
import { UpdatingContentProgress } from 'src/components/UpdatingContentProgress';
import { RoutePaths } from 'src/constants/RoutePath';
import { translate } from 'src/i18n/translate';
import { OrdersTable } from 'src/scenes/letseatadmin/order/OrdersTable';
import { CommissionCalculation } from 'src/scenes/letseatadmin/restaurantBilling/CommissionCalculation';
import { ManualEarningDebtCalculation } from 'src/scenes/letseatadmin/restaurantBilling/ManualEarningDebtCalculation';
import { SubscriptionPayPerUseDebtCalculation } from 'src/scenes/letseatadmin/restaurantBilling/SubscriptionPayPerUseDebtCalculation';
import { ToDepositCalculation } from 'src/scenes/letseatadmin/restaurantBilling/ToDepositCalculation';
import { RestaurantManualEarnings } from 'src/scenes/letseatadmin/restaurantManualEarning/RestaurantManualEarnings';
import type { RestaurantId } from 'src/types/Id';
import { alertKnownErrorOrSomethingWentWrong } from 'src/utils/alert/alertKnownErrorOrSomethingWentWrong';
import { useAction } from 'src/utils/react/useAction';

export function RestaurantBilling({ filter, refresh, restaurantId }: Props): React.ReactElement {
    const classes = useStyles();

    const [loading, setLoading] = useState(false);
    const [restaurantBillingVm, setRestaurantBillingVm] = useState<RestaurantBillingVm | undefined>();

    const setTitle = useAction(app2.actions.setTitle);

    useEffect(() => {
        setTitle(translate('Restaurant Billing'));
        load();
    }, [refresh]);

    const load = async () => {
        setLoading(true);
        const response = await getRestaurantBillingApi({
            restaurantId,
            from: filter.from,
            to: filter.to,
        });
        setLoading(false);
        if (!response.ok) {
            alertKnownErrorOrSomethingWentWrong(response);
            return;
        }
        setRestaurantBillingVm(response.data);
    };

    return (
        <Grid container spacing={5}>
            <Grid item xs={12}>
                <UpdatingContentProgress loading={loading} />

                <Card classes={{ root: classes.rootTransparent }} variant='outlined'>
                    <CardHeader
                        title={
                            <div>
                                <div style={{ display: 'flex', gap: 8, alignItems: 'center' }}>
                                    <Text title>{restaurantBillingVm?.restaurantName}</Text>
                                    {restaurantBillingVm?.salesforceRestaurantType && <Chip>{restaurantBillingVm?.salesforceRestaurantType}</Chip>}
                                </div>
                                <Text>{restaurantBillingVm?.restaurantZone}</Text>
                            </div>
                        }
                        action={
                            <div style={{ display: 'flex', gap: 8, alignItems: 'center' }}>
                                {restaurantId && (
                                    <>
                                        <OpenInNewTabToolbarButton onClick={() => window.open(`${RoutePaths.THE_RESTAURANT}?restaurant=${restaurantId}`, '_blank')} />
                                    </>
                                )}
                                <RefreshToolbarButton onClick={load} />
                            </div>
                        }
                    />

                    <CardContent>
                        {restaurantBillingVm && (
                            <Grid container spacing={3}>
                                <Grid item md={3} xs={0 as any} />
                                <Grid item md={6} xs={12}>
                                    <CommissionCalculation restaurantBillingVm={restaurantBillingVm} />
                                </Grid>
                                <Grid item md={3} xs={0 as any} />
                                <Grid item md={3} xs={0 as any} />
                                <Grid item md={6} xs={12}>
                                    <SubscriptionPayPerUseDebtCalculation restaurantBillingVm={restaurantBillingVm} />
                                </Grid>
                                <Grid item md={3} xs={0 as any} />
                                <Grid item md={3} xs={0 as any} />
                                <Grid item md={6} xs={12}>
                                    <ManualEarningDebtCalculation restaurantBillingVm={restaurantBillingVm} />
                                </Grid>
                                <Grid item md={3} xs={0 as any} />
                                <Grid item md={3} xs={0 as any} />
                                <Grid item md={6} xs={12}>
                                    <ToDepositCalculation restaurantBillingVm={restaurantBillingVm} />
                                </Grid>
                                <Grid item md={3} xs={0 as any} />
                                <Grid item md={12} xs={0 as any} />
                            </Grid>
                        )}
                    </CardContent>
                </Card>

                <Card classes={{ root: classes.root }}>
                    <CardHeader
                        title={
                            <div>
                                <Text>
                                    {restaurantBillingVm?.restaurantName} {restaurantBillingVm?.restaurantZone}
                                </Text>
                            </div>
                        }
                        action={
                            <div>
                                {restaurantId && (
                                    <>
                                        <OpenInNewTabToolbarButton onClick={() => window.open(`${RoutePaths.THE_RESTAURANT}?restaurant=${restaurantId}`, '_blank')} />
                                    </>
                                )}
                                <RefreshToolbarButton onClick={load} />
                            </div>
                        }
                    />

                    <CardContent>
                        <Grid container spacing={3}>
                            <Grid item xs={6} sm={3}>
                                <Typography variant='caption' display='block' gutterBottom color='textSecondary'>
                                    Web Card Orders
                                </Typography>
                                <Typography variant='body1' gutterBottom>
                                    {restaurantBillingVm?.webCard}
                                </Typography>
                            </Grid>
                            <Grid item xs={6} sm={3}>
                                <Typography variant='caption' display='block' gutterBottom color='textSecondary'>
                                    {translate('Web Card Uber Daas Orders')}
                                </Typography>
                                <Typography variant='body1' gutterBottom>
                                    {restaurantBillingVm?.uberDaasWebCard}
                                </Typography>
                            </Grid>
                            <Grid item xs={6} sm={3}>
                                <Typography variant='caption' display='block' gutterBottom color='textSecondary'>
                                    Web Card Rappi Cargo Orders
                                </Typography>
                                <Typography variant='body1' gutterBottom>
                                    {restaurantBillingVm?.rappiCargoWebCard}
                                </Typography>
                            </Grid>
                            <Grid item xs={6} sm={3}>
                                <Typography variant='caption' display='block' gutterBottom color='textSecondary'>
                                    Web Cash Orders
                                </Typography>
                                <Typography variant='body1' gutterBottom>
                                    {restaurantBillingVm?.webCash}
                                </Typography>
                            </Grid>
                            <Grid item xs={6} sm={3}>
                                <Typography variant='caption' display='block' gutterBottom color='textSecondary'>
                                    {translate('Web Cash Uber Daas Orders')}
                                </Typography>
                                <Typography variant='body1' gutterBottom>
                                    {restaurantBillingVm?.uberDaasWebCash}
                                </Typography>
                            </Grid>
                            <Grid item xs={6} sm={3}>
                                <Typography variant='caption' display='block' gutterBottom color='textSecondary'>
                                    Web Rappi Cargo Cash Orders
                                </Typography>
                                <Typography variant='body1' gutterBottom>
                                    {restaurantBillingVm?.rappiCargoWebCash}
                                </Typography>
                            </Grid>
                            <Grid item xs={6} sm={3}>
                                <Typography variant='caption' display='block' gutterBottom color='textSecondary'>
                                    Web Cash Orders
                                </Typography>
                                <Typography variant='body1' gutterBottom>
                                    {restaurantBillingVm?.webCardOnDelivery}
                                </Typography>
                            </Grid>
                            <Grid item xs={6} sm={3}>
                                <Typography variant='caption' display='block' gutterBottom color='textSecondary'>
                                    App Card Orders
                                </Typography>
                                <Typography variant='body1' gutterBottom>
                                    {restaurantBillingVm?.whiteLabelAppCard}
                                </Typography>
                            </Grid>
                            <Grid item xs={6} sm={3}>
                                <Typography variant='caption' display='block' gutterBottom color='textSecondary'>
                                    App Cash Orders
                                </Typography>
                                <Typography variant='body1' gutterBottom>
                                    {restaurantBillingVm?.whiteLabelAppCash}
                                </Typography>
                            </Grid>
                            <Grid item xs={6} sm={3}>
                                <Typography variant='caption' display='block' gutterBottom color='textSecondary'>
                                    App Cash Orders
                                </Typography>
                                <Typography variant='body1' gutterBottom>
                                    {restaurantBillingVm?.whiteLabelAppCardOnDelivery}
                                </Typography>
                            </Grid>
                            <Grid item xs={6} sm={3}>
                                <Typography variant='caption' display='block' gutterBottom color='textSecondary'>
                                    POS Card Orders
                                </Typography>
                                <Typography variant='body1' gutterBottom>
                                    {restaurantBillingVm?.posCard}
                                </Typography>
                            </Grid>
                            <Grid item xs={6} sm={3}>
                                <Typography variant='caption' display='block' gutterBottom color='textSecondary'>
                                    {translate('POS Card Uber Daas Orders')}
                                </Typography>
                                <Typography variant='body1' gutterBottom>
                                    {restaurantBillingVm?.uberDaasPosCard}
                                </Typography>
                            </Grid>
                            <Grid item xs={6} sm={3}>
                                <Typography variant='caption' display='block' gutterBottom color='textSecondary'>
                                    POS Rappi Cargo Card Orders
                                </Typography>
                                <Typography variant='body1' gutterBottom>
                                    {restaurantBillingVm?.rappiCargoPosCard}
                                </Typography>
                            </Grid>
                            <Grid item xs={6} sm={3}>
                                <Typography variant='caption' display='block' gutterBottom color='textSecondary'>
                                    POS Cash Orders
                                </Typography>
                                <Typography variant='body1' gutterBottom>
                                    {restaurantBillingVm?.posCash}
                                </Typography>
                            </Grid>
                            <Grid item xs={6} sm={3}>
                                <Typography variant='caption' display='block' gutterBottom color='textSecondary'>
                                    {translate('POS Cash Uber Daas Orders')}
                                </Typography>
                                <Typography variant='body1' gutterBottom>
                                    {restaurantBillingVm?.uberDaasPosCash}
                                </Typography>
                            </Grid>
                            <Grid item xs={6} sm={3}>
                                <Typography variant='caption' display='block' gutterBottom color='textSecondary'>
                                    POS Cash Rappi Cargo Orders
                                </Typography>
                                <Typography variant='body1' gutterBottom>
                                    {restaurantBillingVm?.rappiCargoPosCash}
                                </Typography>
                            </Grid>
                            <Grid item xs={6} sm={3}>
                                <Typography variant='caption' display='block' gutterBottom color='textSecondary'>
                                    POS Cash Orders
                                </Typography>
                                <Typography variant='body1' gutterBottom>
                                    {restaurantBillingVm?.posCardOnDelivery}
                                </Typography>
                            </Grid>
                            <Grid item xs={6} sm={3}>
                                <Typography variant='caption' display='block' gutterBottom color='textSecondary'>
                                    Manual Orders
                                </Typography>
                                <Typography variant='body1' gutterBottom>
                                    {restaurantBillingVm?.manualOrders}
                                </Typography>
                            </Grid>
                            <Grid item xs={6} sm={3}>
                                <Typography variant='caption' display='block' gutterBottom color='textSecondary'>
                                    {translate('Number of Manual Orders Protected')}
                                </Typography>
                                <Typography variant='body1' gutterBottom>
                                    {restaurantBillingVm?.manualOrdersProtected}
                                </Typography>
                            </Grid>
                            <Grid item xs={6} sm={3}>
                                <Typography variant='caption' display='block' gutterBottom color='textSecondary'>
                                    {translate('Directo Protection Amount')}
                                </Typography>
                                <Typography variant='body1' gutterBottom>
                                    {restaurantBillingVm?.directoProtectionAmount}
                                </Typography>
                            </Grid>
                            <Grid item xs={6} sm={3}>
                                <Typography variant='caption' display='block' gutterBottom color='textSecondary'>
                                    Invoiced Deliveries
                                </Typography>
                                <Typography variant='body1' gutterBottom>
                                    {restaurantBillingVm?.invoicedRestaurantDeliveryCost}
                                </Typography>
                            </Grid>
                            <Grid item xs={6} sm={3}>
                                <Typography variant='caption' display='block' gutterBottom color='textSecondary'>
                                    Delivery cost paid by restaurant
                                </Typography>
                                <Typography variant='body1' gutterBottom>
                                    {restaurantBillingVm?.deliveryCostPaidByRestaurant}
                                </Typography>
                            </Grid>
                            <Grid item xs={6} sm={3}>
                                <Typography variant='caption' display='block' gutterBottom color='textSecondary'>
                                    Payment Links
                                </Typography>
                                <Typography variant='body1' gutterBottom>
                                    {restaurantBillingVm?.paymentLink}
                                </Typography>
                            </Grid>
                            <Grid item xs={6} sm={3}>
                                <Typography variant='caption' display='block' gutterBottom color='textSecondary'>
                                    Rappi Cargo Payment Links
                                </Typography>
                                <Typography variant='body1' gutterBottom>
                                    {restaurantBillingVm?.rappiCargoPaymentLink}
                                </Typography>
                            </Grid>
                            <Grid item xs={6} sm={3}>
                                <Typography variant='caption' display='block' gutterBottom color='textSecondary'>
                                    Uber Daas Payment Links
                                </Typography>
                                <Typography variant='body1' gutterBottom>
                                    {restaurantBillingVm?.uberDaasPaymentLink}
                                </Typography>
                            </Grid>
                            <Grid item xs={6} sm={3}>
                                <Typography variant='caption' display='block' gutterBottom color='textSecondary'>
                                    Rappi Cargo Bringg Cash
                                </Typography>
                                <Typography variant='body1' gutterBottom>
                                    {restaurantBillingVm?.rappiCargoBringgCash}
                                </Typography>
                            </Grid>
                            <Grid item xs={6} sm={3}>
                                <Typography variant='caption' display='block' gutterBottom color='textSecondary'>
                                    Rappi Cargo Bringg Card
                                </Typography>
                                <Typography variant='body1' gutterBottom>
                                    {restaurantBillingVm?.rappiCargoBringgCard}
                                </Typography>
                            </Grid>
                            <Grid item xs={6} sm={3}>
                                <Typography variant='caption' display='block' gutterBottom color='textSecondary'>
                                    Uber Daas Bringg Cash
                                </Typography>
                                <Typography variant='body1' gutterBottom>
                                    {restaurantBillingVm?.uberDaasBringgCash}
                                </Typography>
                            </Grid>
                            <Grid item xs={6} sm={3}>
                                <Typography variant='caption' display='block' gutterBottom color='textSecondary'>
                                    Uber Daas Bringg Card
                                </Typography>
                                <Typography variant='body1' gutterBottom>
                                    {restaurantBillingVm?.uberDaasBringgCard}
                                </Typography>
                            </Grid>
                            <Grid item xs={6} sm={3}>
                                <Typography variant='caption' display='block' gutterBottom color='textSecondary'>
                                    Total Sold
                                </Typography>
                                <Typography variant='body1' gutterBottom>
                                    {restaurantBillingVm?.total}
                                </Typography>
                            </Grid>
                            <Grid item xs={6} sm={3}>
                                <Typography variant='caption' display='block' gutterBottom color='textSecondary'>
                                    Commission
                                </Typography>
                                <Typography variant='body1' gutterBottom>
                                    {restaurantBillingVm?.commission}
                                </Typography>
                            </Grid>
                            <Grid item xs={6} sm={3}>
                                <Typography variant='caption' display='block' gutterBottom color='textSecondary'>
                                    IVA
                                </Typography>
                                <Typography variant='body1' gutterBottom>
                                    {restaurantBillingVm?.iva}
                                </Typography>
                            </Grid>
                            <Grid item xs={6} sm={3}>
                                <Typography variant='caption' display='block' gutterBottom color='textSecondary'>
                                    {translate('Restaurant Promo Code Cost App')}
                                </Typography>
                                <Typography variant='body1' gutterBottom>
                                    {restaurantBillingVm?.restaurantPromoCodeCostApp}
                                </Typography>
                            </Grid>
                            <Grid item xs={6} sm={3}>
                                <Typography variant='caption' display='block' gutterBottom color='textSecondary'>
                                    {translate('Restaurant Promo Code Balance')}
                                </Typography>
                                <Typography variant='body1' gutterBottom>
                                    {restaurantBillingVm?.restaurantPromoCodeBalance}
                                </Typography>
                            </Grid>
                            <Grid item xs={6} sm={3}>
                                <Typography variant='caption' display='block' gutterBottom color='textSecondary'>
                                    Subscription Pay Per Use
                                </Typography>
                                <Typography variant='body1' gutterBottom>
                                    {restaurantBillingVm?.subscriptionPayPerUsePayment}
                                </Typography>
                            </Grid>
                            <Grid item xs={6} sm={3}>
                                <Typography variant='caption' display='block' gutterBottom color='textSecondary'>
                                    Manual Earning
                                </Typography>
                                <Typography variant='body1' gutterBottom>
                                    {restaurantBillingVm?.manualEarning}
                                </Typography>
                            </Grid>
                            <Grid item xs={6} sm={3}>
                                <Typography variant='caption' display='block' gutterBottom color='textSecondary'>
                                    Debt Subscription Pay Per Use
                                </Typography>
                                <Typography variant='body1' gutterBottom>
                                    {restaurantBillingVm?.debtSubscriptionPayPerUsePayment}
                                </Typography>
                            </Grid>
                            <Grid item xs={6} sm={3}>
                                <Typography variant='caption' display='block' gutterBottom color='textSecondary'>
                                    Debt Manual Earning
                                </Typography>
                                <Typography variant='body1' gutterBottom>
                                    {restaurantBillingVm?.debtManualEarning}
                                </Typography>
                            </Grid>
                            <Grid item xs={6} sm={3}>
                                <Typography variant='caption' display='block' gutterBottom color='textSecondary'>
                                    Subscription Pay Per Use Invoice
                                </Typography>
                                <Typography variant='body1' gutterBottom>
                                    {restaurantBillingVm?.subscriptionPayPerUsePaymentInvoice}
                                </Typography>
                            </Grid>
                            <Grid item xs={6} sm={3}>
                                <Typography variant='caption' display='block' gutterBottom color='textSecondary'>
                                    Manual Earning Invoice
                                </Typography>
                                <Typography variant='body1' gutterBottom>
                                    {restaurantBillingVm?.manualEarningInvoice}
                                </Typography>
                            </Grid>
                            <Grid item xs={6} sm={3}>
                                <Typography variant='caption' display='block' gutterBottom color='textSecondary'>
                                    To Deposit
                                </Typography>
                                <Typography variant='body1' gutterBottom>
                                    {restaurantBillingVm?.deposit}
                                </Typography>
                            </Grid>
                            <Grid item xs={6} sm={3}>
                                <Typography variant='caption' display='block' gutterBottom color='textSecondary'>
                                    OrdersPage
                                </Typography>
                                <Typography variant='body1' gutterBottom>
                                    {restaurantBillingVm?.orders?.length}
                                </Typography>
                            </Grid>
                        </Grid>
                    </CardContent>
                </Card>
            </Grid>

            {restaurantBillingVm?.orders && (
                <Grid item xs={12}>
                    <OrdersTable title='OrdersPage' orders={restaurantBillingVm?.orders} loading={loading} onClickRefresh={load} />
                </Grid>
            )}
            <Grid item xs={12}>
                <RestaurantManualEarnings
                    restaurantId={restaurantId}
                    filter={{
                        from: filter.from,
                        to: filter.to,
                    }}
                />
            </Grid>
            {/*            {restaurantBillingVm?.notAccepted && (
                <Grid item xs={12}>
                    <OrdersTable title='WARNING ORDERS NOT ACCEPTED YET' orders={restaurantBillingVm?.notAccepted} loading={restaurantBillingVm?.loading} onClickRefresh={load} />
                </Grid>
            )}*/}
        </Grid>
    );
}

const useStyles = makeStyles((theme) => ({
    cardContainer: {
        position: 'relative',
    },
    root: {
        paddingLeft: 8,
        paddingRight: 8,
    },
    rootTransparent: {
        paddingLeft: 8,
        paddingRight: 8,
        backgroundColor: 'transparent',
        border: 0,
    },
}));

type Props = {
    filter: {
        from: Date;
        to: Date;
    };
    refresh: number;
    restaurantId: RestaurantId;
};
