import { letseatmanagerApiMethod } from 'src/api/letseatmanagerApi';
import type { ApiSauceResponse } from 'src/api/types/ApiSauceResponse';
import type { SupplyId } from 'src/types/Id';
import type { SupplyVm } from 'src/types/SupplyVm';

export async function getSupplyApi(request: GetSupplyApiRequest): ApiSauceResponse<SupplyVm> {
    return letseatmanagerApiMethod('supply/getSupplyApi', request);
}

export type GetSupplyApiRequest = {
    supplyId: SupplyId;
};
