import { letseatmanagerApiMethod } from 'src/api/letseatmanagerApi';
import type { ApiSauceResponse } from 'src/api/types/ApiSauceResponse';
import { DeprecatedRestaurantPaymentMethodVm } from 'src/types/DeprecatedRestaurantPaymentMethodVm';
import type { RestaurantId } from 'src/types/Id';

export async function getPosPaymentMethodsApi(request: GetPosPaymentMethodsApiRequest): ApiSauceResponse<Array<DeprecatedRestaurantPaymentMethodVm>> {
    return letseatmanagerApiMethod('pos/getPosPaymentMethodsApi', request);
}

export type GetPosPaymentMethodsApiRequest = {
    restaurantId: RestaurantId;
};
