import { FormSelect } from '@pidedirecto/ui/form';
import * as React from 'react';
import { OrderIncidentType, OrderIncidentTypes } from 'src/constants/OrderIncidentType';
import { translate } from 'src/i18n/translate';

export function FormOrderIncidentTypesSelect({ name, label, placeholder, helperText, defaultValue, disabled, required }: Props): React.ReactElement {
    const options = required
        ? Object.values(OrderIncidentTypes).map((orderIncidentType: OrderIncidentType) => ({ label: translate(`OrderIncidentTypes.${orderIncidentType}`), value: orderIncidentType }))
        : [
              { label: '', value: null },
              ...Object.values(OrderIncidentTypes).map((orderIncidentType: OrderIncidentType) => ({ label: translate(`OrderIncidentTypes.${orderIncidentType}`), value: orderIncidentType })),
          ];
    return (
        <FormSelect
            name={name}
            label={label}
            placeholder={placeholder}
            helperText={helperText}
            defaultValue={defaultValue}
            options={options}
            disabled={disabled}
            required={{
                value: !!required,
                message: translate('This field is required'),
            }}
        />
    );
}

type Props = {
    name: string;
    label?: string;
    placeholder?: string;
    helperText?: string;
    defaultValue?: string | null | undefined;
    disabled?: boolean;
    required?: boolean;
};
