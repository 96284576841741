import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import { makeStyles } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';
import { Alert, AlertTitle } from '@material-ui/lab';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { RoutePaths } from 'src/constants/RoutePath';
import { translate } from 'src/i18n/translate';
import { getSubscriptionAlertClosedDaysInLocalStorage } from 'src/localStorage/getSubscriptionAlertClosedDaysInLocalStorage';
import { setSubscriptionAlertClosedDaysInLocalStorage } from 'src/localStorage/setSubscriptionAlertClosedDaysInLocalStorage';
import { useSelector } from 'src/utils/react/useSelector';
import { isFreeTrialSubscription } from 'src/utils/subscriptionPlan/isFreeTrialSubscription';

export function SubscriptionBanner(): React.ReactElement | null {
    const classes = useStyles();
    const history = useHistory();

    const [showReminderSubscription, setShowReminderSubscription] = useState(false);

    const remainingDays = useSelector((state) => state.app2.remainingDays);
    const subscriptionPlan = useSelector((state) => state.app2.subscriptionPlan);
    const userRolePermissions = useSelector((state) => state.authentication.userRolePermissions);

    const subscriptionAlertClosedDays = getSubscriptionAlertClosedDaysInLocalStorage();
    const hasAlertClosedAtDayFifteen = subscriptionAlertClosedDays.includes(remainingDays);
    const hasAlertClosedAtDayTen = subscriptionAlertClosedDays.includes(remainingDays);

    const reminderDays = [10, 15];
    const isNearToFinishFreeTrial = remainingDays > 2;

    useEffect(() => {
        if (((remainingDays === 15 && !hasAlertClosedAtDayFifteen) || (remainingDays === 10 && !hasAlertClosedAtDayTen)) && userRolePermissions) {
            setShowReminderSubscription(true);
        }
    }, [remainingDays]);

    useEffect(() => {
        if (remainingDays <= 5 && userRolePermissions) {
            setShowReminderSubscription(true);
        }

        if (remainingDays <= 1) {
            setShowReminderSubscription(true);
        }
    }, [remainingDays, userRolePermissions, history.location.pathname]);

    const handleRedirectToSubscription = () => {
        history.push(RoutePaths.SUBSCRIPTION);
    };

    const handleRedirectToSeePlans = () => {
        history.push(RoutePaths.FREE_SUBSCRIPTION_PLAN_END);
    };

    const handleCloseAlert = () => {
        setShowReminderSubscription(false);
        if (reminderDays.includes(remainingDays)) {
            setSubscriptionAlertClosedDaysInLocalStorage([...subscriptionAlertClosedDays, remainingDays]);
        }
    };

    if (!showReminderSubscription) return null;

    return (
        <>
            <Grid item xs={12} className={classes.alert}>
                <Alert
                    severity={isNearToFinishFreeTrial ? 'warning' : 'error'}
                    classes={{ message: classes.alertMessage }}
                    action={
                        <IconButton aria-label='close' color='inherit' size='small' onClick={handleCloseAlert}>
                            <CloseIcon fontSize='inherit' />
                        </IconButton>
                    }
                    // @ts-ignore
                    sx={{ mb: 2 }}
                >
                    <div className={classes.alertMessageContainer}>
                        <div>
                            <AlertTitle classes={{ root: classes.alertTitle }}>
                                {isFreeTrialSubscription(subscriptionPlan)
                                    ? translate('Your free trial ends on @days days', { days: remainingDays })
                                    : translate('@days Days Left to pay your service', { days: remainingDays })}
                            </AlertTitle>
                            {!isFreeTrialSubscription(subscriptionPlan) && translate('Pay now and avoid suspension of your service')}
                        </div>
                        {isFreeTrialSubscription(subscriptionPlan) ? (
                            <Button variant='text' size='small' onClick={handleRedirectToSeePlans}>
                                {translate('See plans')}
                            </Button>
                        ) : (
                            <Button variant='text' size='small' onClick={handleRedirectToSubscription}>
                                {translate(' Go to Subscription')}
                            </Button>
                        )}
                    </div>
                </Alert>
            </Grid>
        </>
    );
}

const useStyles = makeStyles((theme) => ({
    alert: {
        marginBottom: theme.spacing(3),
        borderColor: '#ddd',
        borderWidth: '1px',
        borderStyle: 'solid',
    },
    alertTitle: {
        fontFamily: theme.typography.semiBold,
    },
    alertMessage: {
        fontFamily: theme.typography.medium,
        width: '100%',
    },
    alertMessageContainer: {
        width: '100%',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
    },
}));
