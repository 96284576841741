import { makeStyles } from '@material-ui/core';
import { Button } from '@pidedirecto/ui';
import { CopyIcon, EditIcon, TrashCanIcon } from '@pidedirecto/ui/icons';
import * as React from 'react';
import { translate } from 'src/i18n/translate';
import { useIsMenuEditionDisabled } from 'src/services/menu/useIsMenuEditionDisabled';
import type { ModifierGroupVm } from 'src/types/ModifierGroupVm';
import { classNames } from 'src/utils/react/classNames';

export function ModifierGroupListItem({ modifierGroup, changeModifierGroup, openRemoveModifierGroupDialog, cloneModifierGroup }: Props): React.ReactElement {
    const classes = useStyles();
    const isMenuEditionDisabled = useIsMenuEditionDisabled();

    const handleEdit = (e: any) => {
        e.stopPropagation();
        changeModifierGroup(modifierGroup);
    };

    const handleRemove = (e: any) => {
        e.stopPropagation();
        openRemoveModifierGroupDialog(modifierGroup);
    };

    const handleClone = (e: any) => {
        e.stopPropagation();
        cloneModifierGroup(modifierGroup);
    };

    return (
        <div className={classNames(classes.container)}>
            <div className={classes.infoContainer}>
                <span className={classes.name}>{modifierGroup.name}</span>
                <span className={classes.modifiers}>{translate('@numberModifiers modifiers', { numberModifiers: modifierGroup.modifiers.length })}</span>
            </div>
            <div className={classes.buttonsContainer}>
                <Button variant='icon' onClick={handleEdit} disabled={isMenuEditionDisabled}>
                    <EditIcon title={'edit'} color={'#6C7076'} size={20} />
                </Button>
                <Button variant='icon' onClick={handleRemove} disabled={isMenuEditionDisabled}>
                    <TrashCanIcon title={'remove'} color={'#E37878'} size={20} />
                </Button>
                <Button variant='icon' onClick={handleClone} disabled={isMenuEditionDisabled}>
                    <CopyIcon title={'copy'} color={'#6C7076'} size={20} />
                </Button>
            </div>
        </div>
    );
}

const useStyles = makeStyles((theme) => ({
    container: {
        width: '100%',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
    },
    infoContainer: {
        display: 'flex',
        flexDirection: 'column',
    },
    name: {
        fontFamily: theme.typography.regular,
        fontSize: 15,
    },
    modifiers: {
        fontFamily: theme.typography.regular,
        fontSize: 13,
        color: '#9a9b9b',
    },
    buttonsContainer: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-around',
        gap: 5,
    },
}));

type Props = {
    modifierGroup: ModifierGroupVm;
    changeModifierGroup: any;
    openRemoveModifierGroupDialog: any;
    cloneModifierGroup: any;
};
