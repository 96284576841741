import Grid from '@material-ui/core/Grid';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { findSubscriptionPaymentsApi, SubscriptionPaymentsVm } from 'src/api/letseatadmin/subscription/findSubscriptionPaymentsApi';
import { SubscriptionVm } from 'src/api/letseatadmin/subscription/getSubscriptionApi';
import { findSubscriptionHistoriesApi, SubscriptionHistoriesVm } from 'src/api/letseatadmin/subscriptionHistory/findSubscriptionHistoryBySubscriptionApi';
import { app2 } from 'src/app2';
import { PasswordRequiredContent } from 'src/components/PasswordRequiredContent';
import { translate } from 'src/i18n/translate';
import { SubscriptionLogEvents } from 'src/scenes/letseatadmin/logEvents/SubscriptionLogEvents';
import { PaymentsTable } from 'src/scenes/letseatadmin/payment/PaymentsTable';
import { SubscriptionDetails } from 'src/scenes/letseatadmin/subscription/SubscriptionDetails';
import { SubscriptionHistoryTable } from 'src/scenes/letseatadmin/subscriptionHistory/SubscriptionHistoryTable';
import type { SubscriptionId } from 'src/types/Id';
import { alertKnownErrorOrSomethingWentWrong } from 'src/utils/alert/alertKnownErrorOrSomethingWentWrong';
import { useAction } from 'src/utils/react/useAction';
import { useParams } from 'src/utils/react/useParams';

export function SubscriptionPage(): React.ReactElement {
    const setTitle = useAction(app2.actions.setTitle);
    const { subscriptionId } = useParams<{
        subscriptionId: SubscriptionId;
    }>();
    const [paymentsVm, setPaymentsVm] = useState({ payments: [] } as SubscriptionPaymentsVm);
    const [subscriptionHistoryVm, setSubscriptionHistoryVm] = useState({ subscriptionHistories: [] } as SubscriptionHistoriesVm);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        handleChangeSubscription();
    }, []);

    const handleChangeSubscription = (subscription?: undefined | SubscriptionVm) => {
        load();
        if (!subscription) {
            setTitle(`${translate('Subscriptions')} > ${subscriptionId.substring(0, 8)}`);
            return;
        }
        const businessNameSuffix = subscription.businessName ? `- ${subscription.businessName}` : '';
        setTitle(`${translate('Subscriptions')} > ${subscription.restaurantNames[0]}${businessNameSuffix}`);
    };

    const load = async () => {
        setLoading(true);
        const response = await findSubscriptionPaymentsApi({ subscriptionId });
        if (!response.ok) {
            setLoading(false);
            alertKnownErrorOrSomethingWentWrong(response);
            return;
        }
        setPaymentsVm(response.data);
        const subscriptionHistoryResponse = await findSubscriptionHistoriesApi({ subscriptionId });
        if (!subscriptionHistoryResponse.ok) {
            setLoading(false);
            alertKnownErrorOrSomethingWentWrong(subscriptionHistoryResponse);
            return;
        }
        setSubscriptionHistoryVm(subscriptionHistoryResponse.data);
        setLoading(false);
    };

    return (
        <PasswordRequiredContent>
            <Grid container spacing={5} style={{ paddingBottom: 40 }}>
                <Grid item xs={12}>
                    <SubscriptionDetails subscriptionId={subscriptionId} onChangeSubscription={handleChangeSubscription} />
                </Grid>
                <Grid item xs={12}>
                    <SubscriptionHistoryTable subscriptionHistories={subscriptionHistoryVm.subscriptionHistories} loading={loading} onClickRefresh={load} />
                </Grid>
                <Grid item xs={12}>
                    <PaymentsTable payments={paymentsVm.payments} loading={loading} onClickRefresh={load} />
                </Grid>
                <Grid item xs={12}>
                    <SubscriptionLogEvents subscriptionId={subscriptionId} />
                </Grid>
            </Grid>
        </PasswordRequiredContent>
    );
}
