import { makeStyles } from '@material-ui/core';
import * as React from 'react';
import { useEffect } from 'react';
import { Button } from 'src/components/Button';
import { posReducer } from 'src/reducers/posReducer';
import { MenuCategoryId } from 'src/types/Id';
import { MenuCategoryVm, MenuVm } from 'src/types/PosMenuVm';
import { classNames } from 'src/utils/react/classNames';
import { useAction } from 'src/utils/react/useAction';
import { useSelector } from 'src/utils/react/useSelector';
import { removeNulls } from 'src/utils/removeNulls';

export function PosMenuCategories(): React.ReactElement | null {
    const classes = useStyles();

    const menuCategories = useSelector((state) => state.pos.posMenu?.menuCategories) ?? [];
    const newMenuManagementEnabled = useSelector((state) => state.app.restaurant.newMenuManagementEnabled);
    const menus = useSelector((state) => state.pos.posMenu?.menus);
    const restaurantMenu = useSelector((state) => state.pos.posMenu?.restaurantMenu);
    const selectedMenuCategory = useSelector((state) => state.pos.selectedMenuCategory);
    const selectedMenuCategoryFamilyId = useSelector((state) => state.pos.selectedMenuCategoryFamilyId);
    const shouldShowMenuCategoriesWithoutMenuCategoryFamily = useSelector((state) => state.pos.shouldShowMenuCategoriesWithoutMenuCategoryFamily);

    const setSelectedMenuCategory = useAction(posReducer.actions.setSelectedMenuCategory);
    const setSelectedMenu = useAction(posReducer.actions.setSelectedMenu);

    const sortedMenuCategoryIds = menus.flatMap((menu) => menu.menuCategoryIds);
    const sortedMenuCategories = removeNulls(sortedMenuCategoryIds.map((menuCategoryId) => menuCategories.find((menuCategory) => menuCategory.menuCategoryId === menuCategoryId)!));
    const usedMenuCategories = sortedMenuCategories.filter((menuCategory) => menus.some((menu) => menu.menuCategoryIds.includes(menuCategory.menuCategoryId)));

    useEffect(() => {
        if (menuCategories.length && menus.length) {
            selectFirstMenuElements();
        }
    }, [menuCategories, newMenuManagementEnabled, restaurantMenu, selectedMenuCategoryFamilyId, shouldShowMenuCategoriesWithoutMenuCategoryFamily]);

    const handleClickMenuCategory = (menuCategory: MenuCategoryVm, menu: MenuVm) => {
        setSelectedMenuCategory(menuCategory);
        setSelectedMenu(menu);
    };

    const selectFirstMenuElements = () => {
        if (newMenuManagementEnabled) {
            const filteredMenuCategories = usedMenuCategories.filter(shouldShowMenuCategory);
            setSelectedMenuCategory(filteredMenuCategories[0]);
            setSelectedMenu(menus[0]);
            return;
        }

        if (!restaurantMenu) return;

        const firstMenuCategory = menuCategories.find((menuCategory) => menuCategory.menuCategoryId === restaurantMenu.menus?.[0]?.menuCategories?.[0]?.menuCategoryId);
        const firstMenu = menus.find((menu) => menu.menuId === restaurantMenu.menus?.[0]?.menuId);

        if (firstMenuCategory) setSelectedMenuCategory(firstMenuCategory);
        if (firstMenu) setSelectedMenu(firstMenu);
    };

    const shouldShowMenuCategory = (menuCategory: MenuCategoryVm | undefined) => {
        if (!menuCategory) return false;
        if (!menuCategory.menuCategoryFamilyId && !!selectedMenuCategoryFamilyId) return false;
        if (!!menuCategory.menuCategoryFamilyId && shouldShowMenuCategoriesWithoutMenuCategoryFamily) return false;
        if (!!selectedMenuCategoryFamilyId && !!menuCategory.menuCategoryFamilyId && menuCategory.menuCategoryFamilyId !== selectedMenuCategoryFamilyId) {
            return false;
        }

        return true;
    };

    if (!menus || !menuCategories) {
        return null;
    }

    if (newMenuManagementEnabled) {
        return (
            <>
                {menus.map((menu) => {
                    return menu.menuCategoryIds?.map((menuCategoryId: MenuCategoryId) => {
                        const menuCategory = menuCategories.find((menuCategory) => menuCategory.menuCategoryId === menuCategoryId);
                        if (!menuCategory || !shouldShowMenuCategory(menuCategory)) return;

                        return (
                            <Button
                                classes={{ button: classNames(classes.category, { [classes.categorySelected]: menuCategory.menuCategoryId === selectedMenuCategory?.menuCategoryId }) }}
                                onClick={() => handleClickMenuCategory(menuCategory, menu)}
                                key={menuCategory.menuCategoryId}
                            >
                                {menuCategory.name}
                            </Button>
                        );
                    });
                })}
            </>
        );
    }

    return (
        <>
            {restaurantMenu?.menus.flatMap((menuStructure) => {
                const menu = menus.find((menu) => menu.menuId === menuStructure.menuId);
                if (!menu) return null;

                return menuStructure.menuCategories.map((menuCategoryStructure) => {
                    const menuCategory = menuCategories.find((menuCategory) => menuCategory.menuCategoryId === menuCategoryStructure.menuCategoryId);
                    if (!menuCategory) return null;

                    return (
                        <Button
                            classes={{ button: classNames(classes.category, { [classes.categorySelected]: menuCategory.menuCategoryId === selectedMenuCategory?.menuCategoryId }) }}
                            onClick={() => handleClickMenuCategory(menuCategory, menu)}
                            key={menuCategory.menuCategoryId}
                        >
                            {menuCategory.name}
                        </Button>
                    );
                });
            })}
        </>
    );
}

const useStyles = makeStyles((theme) => ({
    category: {
        borderRadius: 8,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        fontFamily: theme.typography.regular,
        textAlign: 'center',
        color: theme.palette.text.primary,
        cursor: 'pointer',
        backgroundColor: theme.palette.surface.secondary,
        fontSize: 12,
        padding: '12px 24px',
        '&:hover': {
            backgroundColor: theme.palette.surface.tertiary,
        },
        [theme.breakpoints.down('sm')]: {
            height: 40,
            width: 'fit-content',
            fontSize: 12,
            borderRadius: 8,
            padding: '4px 8px',
        },
    },
    categorySelected: {
        color: 'white',
        backgroundColor: theme.palette.surface.brandContrast,
        fontFamily: theme.typography.bold,
        '&:hover': {
            backgroundColor: theme.palette.surface.brandContrast,
        },
    },
}));
