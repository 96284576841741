import { letseatadminApiMethod } from 'src/api/letseatadminApi';
import type { ApiSauceResponse } from 'src/api/types/ApiSauceResponse';
import type { City } from 'src/constants/City';
import type { DriverStatus } from 'src/constants/DriverStatus';
import type { LiveDriversOverviewVm } from 'src/types/LiveDriversOverviewVm';

export async function findDriverLivePositionsByCityApi(request: FindDriverLivePositionsByCityApiRequest): ApiSauceResponse<LiveDriversOverviewVm> {
    return letseatadminApiMethod('driverLivePosition/findDriverLivePositionsByCityApi', request);
}

type FindDriverLivePositionsByCityApiRequest = {
    city?: City;
    filter?: DriverStatus;
};
