import { makeStyles } from '@material-ui/core';
import * as React from 'react';
import { Dialog } from 'src/components/Dialog';
import { CloneMenu } from 'src/scenes/letseatmanager/menu/menu/CloneMenu';
import type { MenuId } from 'src/types/Id';
import { useIsSmallScreen } from 'src/utils/react/window/useIsSmallScreen';

export function CloneMenuSection({ menuId, onClose, onSuccess }: Props): React.ReactElement {
    const classes = useStyles();

    const isSmallScreen = useIsSmallScreen();

    if (isSmallScreen) {
        return (
            <Dialog open={true} onClose={onClose}>
                <CloneMenu menuId={menuId} onClose={onClose} onSuccess={onSuccess} />
            </Dialog>
        );
    }

    return (
        <div className={classes.container}>
            <CloneMenu menuId={menuId} onClose={onClose} onSuccess={onSuccess} />
        </div>
    );
}

const useStyles = makeStyles((theme) => ({
    container: {
        width: '40%',
    },
}));

type Props = {
    menuId?: MenuId;
    onClose?: any;
    onSuccess?: any;
};
