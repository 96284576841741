import { letseatadminApiMethod } from 'src/api/letseatadminApi';
import type { ApiSauceResponse } from 'src/api/types/ApiSauceResponse';
import type { PaymentId } from 'src/types/Id';

export async function refundPaymentApi(request: RefundPaymentApiRequest): ApiSauceResponse<void> {
    return letseatadminApiMethod('subscription/refundPaymentApi', request);
}

export type RefundPaymentApiRequest = {
    paymentId: PaymentId;
};
