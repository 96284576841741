import { makeStyles } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import AddIcon from '@material-ui/icons/Add';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import RefreshIcon from '@material-ui/icons/Refresh';
import * as React from 'react';
import { useState } from 'react';
import { findBusinessesApi } from 'src/api/letseatadmin/business/findBusinessesApi';
import { removeBusinessApi } from 'src/api/letseatadmin/business/removeBusinessApi';
import { Page } from 'src/components/Page';
import { PasswordProtectedCell } from 'src/components/PasswordProtectedCell';
import { Table } from 'src/components/Table';
import { translate } from 'src/i18n/translate';
import { ChangeBusinessDialog } from 'src/scenes/letseatadmin/business/ChangeBusinessDialog';
import { CreateBusinessDialog } from 'src/scenes/letseatadmin/business/CreateBusinessDialog';
import { alertKnownErrorOrSomethingWentWrong } from 'src/utils/alert/alertKnownErrorOrSomethingWentWrong';
import { getSelectedRowsData } from 'src/utils/mui-datatables/getSelectedRowsData';
import { useLoadApi } from 'src/utils/react/useLoadApi';
import { openRestaurantPageInNewTab } from 'src/utils/window/openRestaurantPageInNewTab';

export function BusinessesPage(): React.ReactElement {
    const classes = useStyles();

    const [createBusinessDialogState, setCreateBusinessDialogState] = useState({ open: false });
    const [changeBusinessDialogState, setChangeBusinessDialogState] = useState({ open: false, businessId: undefined });
    const [loading, setLoading] = useState(false);

    const [loadingBusinesses, businesses, refreshBusinesses] = useLoadApi(findBusinessesApi, undefined, { initialValue: [] });

    const openCreateBusinessDialog = () => setCreateBusinessDialogState({ open: true });

    const closeCreateBusinessDialog = () => setCreateBusinessDialogState({ open: false });

    const openChangeBusinessDialog = (businessId: any) => setChangeBusinessDialogState({ open: true, businessId });

    const closeChangeBusinessDialog = () => setChangeBusinessDialogState({ open: false, businessId: undefined });

    const removeBusiness = async (businessId: any) => {
        const shouldRemoveBusiness = window.confirm(translate('Are you sure you want to remove the business?'));
        if (!shouldRemoveBusiness) return;

        setLoading(true);
        const response = await removeBusinessApi({ businessId });
        setLoading(false);
        if (!response.ok) {
            alertKnownErrorOrSomethingWentWrong(response);
            return;
        }
        await refreshBusinesses();
    };

    return (
        <Page title={translate('Business')}>
            <CreateBusinessDialog open={createBusinessDialogState.open} onClose={closeCreateBusinessDialog} onSuccess={refreshBusinesses} />
            <ChangeBusinessDialog open={changeBusinessDialogState.open} businessId={changeBusinessDialogState.businessId} onClose={closeChangeBusinessDialog} onSuccess={refreshBusinesses} />
            <Table
                loading={loading || loadingBusinesses}
                data={businesses.map((business) => ({
                    businessId: business.businessId,
                    name: business.name,
                    brands: business.brands,
                    restaurants: business.restaurants,
                    street: business.street,
                    phoneNumber: business.phoneNumber,
                    email: business.email,
                    username: business.username,
                    password: business.password,
                }))}
                columns={[
                    {
                        name: 'businessId',
                        label: '',
                        options: {
                            display: 'excluded',
                            filter: false,
                        },
                    },
                    {
                        name: 'name',
                        label: translate('Name'),
                        options: {
                            filter: false,
                        },
                    },
                    {
                        name: 'brands',
                        label: translate('Brands'),
                        options: {
                            filter: false,
                            customBodyRender: (brands) => (
                                <div className={classes.restaurantsContainer}>
                                    {brands.map((brand: any) => (
                                        <span key={brand.brandId} className={classes.text}>
                                            {brand.name}
                                        </span>
                                    ))}
                                </div>
                            ),
                        },
                    },
                    {
                        name: 'restaurants',
                        label: translate('Restaurants'),
                        options: {
                            filter: false,
                            customBodyRender: (restaurants) => (
                                <div className={classes.restaurantsContainer}>
                                    {restaurants.map((restaurant: any) => (
                                        <span key={restaurant.restaurantId} className={classes.link} onClick={() => openRestaurantPageInNewTab(restaurant.restaurantId)}>
                                            {restaurant.name}
                                        </span>
                                    ))}
                                </div>
                            ),
                        },
                    },
                    {
                        name: 'street',
                        label: translate('Address'),
                        options: {
                            filter: false,
                        },
                    },
                    {
                        name: 'phoneNumber',
                        label: translate('Phone'),
                        options: {
                            filter: false,
                        },
                    },
                    {
                        name: 'email',
                        label: translate('Email'),
                        options: {
                            filter: false,
                        },
                    },
                    {
                        name: 'username',
                        label: translate('Username'),
                        options: {
                            filter: false,
                        },
                    },
                    {
                        name: 'password',
                        label: translate('Password'),
                        options: {
                            filter: false,
                            customBodyRender: (password) => (
                                <PasswordProtectedCell password={'5265'}>
                                    <span className={classes.text}>{password}</span>
                                </PasswordProtectedCell>
                            ),
                        },
                    },
                ]}
                options={{
                    responsive: 'standard',
                    tableBodyMaxHeight: '500px',
                    selectableRows: 'single',
                    filterType: 'checkbox',
                    rowsPerPage: 100,
                    customToolbar: () => {
                        return (
                            <>
                                <Tooltip title={translate('Refresh')}>
                                    <IconButton onClick={() => refreshBusinesses()}>
                                        <RefreshIcon />
                                    </IconButton>
                                </Tooltip>
                                <Tooltip title={translate('Create')}>
                                    <IconButton onClick={openCreateBusinessDialog}>
                                        <AddIcon />
                                    </IconButton>
                                </Tooltip>
                            </>
                        );
                    },
                    customToolbarSelect: (selectedRows, displayData) => {
                        const selectedRowsData = getSelectedRowsData(selectedRows, displayData);
                        const selectedBusinessId = selectedRowsData.map((d: any) => d[0])[0];

                        return (
                            <div>
                                <Tooltip title={translate('Change')}>
                                    <IconButton onClick={() => openChangeBusinessDialog(selectedBusinessId)}>
                                        <EditIcon />
                                    </IconButton>
                                </Tooltip>
                                <Tooltip title={translate('Remove')}>
                                    <IconButton onClick={() => removeBusiness(selectedBusinessId)}>
                                        <DeleteIcon />
                                    </IconButton>
                                </Tooltip>
                            </div>
                        );
                    },
                }}
            />
        </Page>
    );
}

const useStyles = makeStyles((theme) => ({
    text: {
        fontFamily: theme.typography.regular,
        fontSize: 14,
    },
    link: {
        fontFamily: theme.typography.regular,
        fontSize: 14,
        cursor: 'pointer',
        textDecoration: 'underline',
    },
    restaurantsContainer: {
        display: 'flex',
        flexDirection: 'column',
    },
}));
