import * as React from 'react';
import { useState } from 'react';
import { Button } from 'src/components/Button';
import { OrderVerificationPhotosDialog } from 'src/components/dialog/order/OrderVerificationPhotosDialog';
import { translate } from 'src/i18n/translate';
import type { OrderVm } from 'src/types/OrderVm';
import { isUberDaasDelivery } from 'src/utils/order/isUberDaasDelivery';

export function OrderVerificationPhotosButton({ order }: Props): React.ReactElement | null {
    const [orderVerificationPhotosDialogState, setOrderVerificationPhotosDialog] = useState(false);

    const handleOpenOrderVerificationPhotosDialogState = () => {
        setOrderVerificationPhotosDialog(true);
    };

    const handleCloseOrderVerificationPhotosDialogState = () => {
        setOrderVerificationPhotosDialog(false);
    };

    if (!isUberDaasDelivery(order) && !order.restaurant?.uberDaasDeliveryVerificationPictureEnabled) return null;

    return (
        <>
            <OrderVerificationPhotosDialog order={order} open={orderVerificationPhotosDialogState} onClose={handleCloseOrderVerificationPhotosDialogState} />
            <Button secondary onClick={handleOpenOrderVerificationPhotosDialogState}>
                {translate('Verification photos')}
            </Button>
        </>
    );
}

type Props = {
    order: OrderVm;
};
