import { Grid, makeStyles } from '@material-ui/core';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { useForm, useWatch } from 'react-hook-form';
import { changePaymentTerminalApi } from 'src/api/letseatmanager/paymentTerminal/changePaymentTerminalApi';
import { getPaymentTerminalApi } from 'src/api/letseatmanager/paymentTerminal/getPaymentTerminalApi';
import { Button } from 'src/components/Button';
import { Dialog } from 'src/components/Dialog';
import { DialogActions } from 'src/components/DialogActions';
import { Form } from 'src/components/form/Form';
import { FormTextField } from 'src/components/form/FormTextField';
import { FormPaymentTerminalProvidersSelect } from 'src/components/select/paymentTerminalProvider/FormPaymentTerminalProvidersSelect';
import { translate } from 'src/i18n/translate';
import { useReloadRestaurant } from 'src/services/restaurant/useReloadRestaurant';
import { appLayout } from 'src/theme/AppLayout';
import type { PaymentTerminalId } from 'src/types/Id';
import { alertKnownErrorOrSomethingWentWrong } from 'src/utils/alert/alertKnownErrorOrSomethingWentWrong';
import { isOcaPaymentTerminalProvider } from 'src/utils/paymentTerminalProvider/isOcaPaymentTerminalProvider';
import { isQpayPaymentTerminalProvider } from 'src/utils/paymentTerminalProvider/isQpayPaymentTerminalProvider';
import { useLoadApi } from 'src/utils/react/useLoadApi';
import { useSelector } from 'src/utils/react/useSelector';

export function ChangePaymentTerminalDialog({ open, paymentTerminalId, onClose, onSuccess }: Props): React.ReactElement {
    const form = useForm();
    const { control, setValue } = form;

    const classes = useStyles();

    const [loading, setLoading] = useState(false);
    const paymentTerminalProvider = useWatch({ name: 'paymentTerminalProvider', control, defaultValue: undefined });

    const restaurantId = useSelector((state) => state.app.restaurantId);

    const [loadingPaymentTerminal, paymentTerminal] = useLoadApi(getPaymentTerminalApi, { paymentTerminalId: paymentTerminalId! }, { requiredValues: [paymentTerminalId] });

    const { reloadRestaurant } = useReloadRestaurant();

    useEffect(() => {
        if (paymentTerminal) {
            form.reset({
                deviceName: paymentTerminal.deviceName,
                paymentTerminalProvider: paymentTerminal.paymentTerminalProvider,
                qpayTerminalDeviceId: paymentTerminal.qpayTerminalDeviceId,
                ocaPaymentTerminalPosId: paymentTerminal.ocaPaymentTerminalPosId,
                ocaPaymentTerminalSystemId: paymentTerminal.ocaPaymentTerminalSystemId,
                ocaPaymentTerminalTerminalGroup: paymentTerminal.ocaPaymentTerminalTerminalGroup,
            });
        }
    }, [paymentTerminal]);

    const handleClose = () => {
        onClose();
    };

    const onSubmit = async (form: any) => {
        if (!paymentTerminalId) return;

        setLoading(true);
        const response = await changePaymentTerminalApi({
            paymentTerminalId: paymentTerminalId,
            deviceName: form.deviceName,
            paymentTerminalProvider: form.paymentTerminalProvider,
            qpayTerminalDeviceId: form.qpayTerminalDeviceId,
            ocaPaymentTerminalPosId: form.ocaPaymentTerminalPosId,
            ocaPaymentTerminalSystemId: form.ocaPaymentTerminalSystemId,
            ocaPaymentTerminalTerminalGroup: form.ocaPaymentTerminalTerminalGroup,
        });
        setLoading(false);
        if (!response.ok) {
            return alertKnownErrorOrSomethingWentWrong(response);
        }
        reloadRestaurant(restaurantId);
        handleClose();
        onSuccess?.();
    };

    return (
        <Dialog title={translate('Change payment terminal')} open={open} onClose={handleClose}>
            <Form form={form} onSubmit={onSubmit} style={appLayout.dialog.form}>
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <FormTextField name='deviceName' label={translate('Device Name')} disabled={loading || loadingPaymentTerminal} />
                    </Grid>
                    <Grid item xs={12}>
                        <FormPaymentTerminalProvidersSelect name='paymentTerminalProvider' required />
                    </Grid>
                    {isQpayPaymentTerminalProvider(paymentTerminalProvider) && (
                        <Grid item xs={12}>
                            <FormTextField name='qpayTerminalDeviceId' label={translate('Serial number')} required disabled={loading} />
                        </Grid>
                    )}
                    {isOcaPaymentTerminalProvider(paymentTerminalProvider) && (
                        <>
                            <Grid item xs={12}>
                                <FormTextField name='ocaPaymentTerminalPosId' label={translate('Terminal Pos Id')} required disabled={loading} />
                            </Grid>
                            <Grid item xs={12}>
                                <FormTextField name='ocaPaymentTerminalSystemId' label={translate('Terminal System Id')} required disabled={loading} />
                            </Grid>
                            <Grid item xs={12}>
                                <FormTextField name='ocaPaymentTerminalTerminalGroup' label={translate('Terminal Group')} required disabled={loading} />
                            </Grid>
                        </>
                    )}
                </Grid>
                <DialogActions className={classes.buttonsContainer}>
                    <Button secondary onClick={handleClose} disabled={loading || loadingPaymentTerminal} classes={{ button: classes.button }}>
                        {translate('Cancel')}
                    </Button>
                    <Button type='submit' disabled={loading || loadingPaymentTerminal} classes={{ button: classes.button }}>
                        {loading ? translate('Changing') : translate('Change')}
                    </Button>
                </DialogActions>
            </Form>
        </Dialog>
    );
}

const useStyles = makeStyles((theme) => ({
    dialogTitle: {
        textAlign: 'center',
        fontSize: 41,
        color: theme.palette.secondary.contrastText,
        paddingTop: theme.spacing(4),
        paddingBottom: theme.spacing(3),
        '& h2': {
            fontFamily: theme.typography.medium,
        },
    },
    dialogContent: {
        overflowY: 'hidden',
    },
    buttonsContainer: {
        marginTop: 20,
    },
    button: {
        width: 200,
    },
}));

type Props = {
    open: boolean;
    paymentTerminalId?: PaymentTerminalId;
    onClose: any;
    onSuccess?: any;
};
