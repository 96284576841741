import { makeStyles } from '@material-ui/core';
import * as React from 'react';
import { useEffect } from 'react';
import { SecuredAndSubscribedPage } from 'src/components/page/SecuredAndSubscribedPage';
import { RestaurantTypes } from 'src/constants/RestaurantType';
import { RolePermissions } from 'src/constants/RolePermission';
import { translate } from 'src/i18n/translate';
import { CampaignIcon } from 'src/icons/CampaignIcon';
import { MenuLogEvents } from 'src/scenes/letseatadmin/logEvents/MenuLogEvents';
import { MenuAlerts } from 'src/scenes/letseatmanager/menu/MenuAlerts';
import { MenuCategoriesSection } from 'src/scenes/letseatmanager/menu/MenuCategoriesSection';
import { MenuEmptyState } from 'src/scenes/letseatmanager/menu/MenuEmptyState';
import { MenuItemsSection } from 'src/scenes/letseatmanager/menu/MenuItemsSection';
import { MenusSection } from 'src/scenes/letseatmanager/menu/MenusSection';
import { MenuTabs } from 'src/scenes/letseatmanager/menu/MenuTabs';
import { ModifierGroupsSection } from 'src/scenes/letseatmanager/menu/ModifierGroupsSection';
import { useLoadMenus } from 'src/services/menu/useLoadMenus';
import { useLoadMenuCategories } from 'src/services/menuCategory/useLoadMenuCategories';
import { useLoadMenuItems } from 'src/services/menuItem/useLoadMenuItems';
import { useLoadModifierGroups } from 'src/services/modifierGroup/useLoadModifierGroups';
import { useSelector } from 'src/utils/react/useSelector';

export function MenuPage(): React.ReactElement {
    const classes = useStyles();

    const loadMenus = useLoadMenus();
    const loadMenuCategories = useLoadMenuCategories();
    const loadMenuItems = useLoadMenuItems();
    const loadModifierGroups = useLoadModifierGroups();

    const restaurantType = useSelector((state) => state.menu.restaurantType);
    const restaurantId = useSelector((state) => state.app.restaurantId);
    const posEnabled = useSelector((state) => state.app.restaurant?.posEnabled);
    const webOrdersEnabled = useSelector((state) => state.app.restaurant.webOrdersEnabled);
    const appOrdersEnabled = useSelector((state) => state.app.restaurant.appOrdersEnabled);
    const uberEatsStoreId = useSelector((state) => state.app.restaurant.uberEatsStoreId);
    const rappiStoreId = useSelector((state) => state.app.restaurant.rappiStoreId);
    const didiFoodStoreId = useSelector((state) => state.app.restaurant.didiFoodStoreId);
    const restaurant = useSelector((state) => state.app.restaurant);

    const isKitchen = restaurantType === RestaurantTypes.KITCHEN;
    const menuPageEnabled = isKitchen || posEnabled || webOrdersEnabled || appOrdersEnabled || rappiStoreId || uberEatsStoreId || didiFoodStoreId;

    const showMasterMenuRestaurantName = !!restaurant.masterMenuRestaurantName && !!rappiStoreId;

    useEffect(() => {
        loadMenus();
        loadMenuCategories();
        loadMenuItems();
        loadModifierGroups();
    }, [restaurantId]);

    if (!menuPageEnabled) {
        return (
            <SecuredAndSubscribedPage title={translate('The Menu')} rolePermission={RolePermissions.MENU_EDIT_PAGE}>
                <MenuEmptyState />
            </SecuredAndSubscribedPage>
        );
    }
    return (
        <SecuredAndSubscribedPage title={translate('Menu')} rolePermission={RolePermissions.MENU_EDIT_PAGE}>
            <MenuAlerts />
            {showMasterMenuRestaurantName && (
                <div className={classes.alertContainer}>
                    <div className={classes.alertTitle}>
                        <div className={classes.icon}>
                            <CampaignIcon title={'campaign'} />
                        </div>
                        {translate(`This menu is managed on the branch @branchName`, { branchName: restaurant.masterMenuRestaurantName })}
                    </div>
                </div>
            )}
            <SecuredAndSubscribedPage title={translate('The Menu')} rolePermission={RolePermissions.MENU_EDIT_PAGE} className={classes.container}>
                <MenuTabs>
                    <MenusSection />
                    <MenuCategoriesSection />
                    <MenuItemsSection />
                    <ModifierGroupsSection />
                    <MenuLogEvents />
                </MenuTabs>
            </SecuredAndSubscribedPage>
        </SecuredAndSubscribedPage>
    );
}

const useStyles = makeStyles((theme) => ({
    container: {
        position: 'relative',
    },
    alertContainer: {
        display: 'flex',
        width: '100%',
        justifyContent: 'space-between',
        alignItems: 'center',
        backgroundColor: '#EAF7FD',
        borderStyle: 'solid',
        borderColor: '#B4CFE8',
        borderRadius: 5,
        padding: '10px',
    },
    alertTitle: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        color: '#154A61',
        fontWeight: 600,
    },
    icon: {
        width: '20px',
        height: '20px',
        marginRight: 10,
        marginLeft: 10,
    },
}));
