import { letseatadminApiMethod } from 'src/api/letseatadminApi';
import type { ApiSauceResponse } from 'src/api/types/ApiSauceResponse';
import type { RestaurantVideoId } from 'src/types/Id';

export async function getRestaurantVideoApi(request: GetRestaurantVideoApiRequest): ApiSauceResponse<RestaurantVideoVm> {
    return letseatadminApiMethod('restaurantVideo/getRestaurantVideoApi', request);
}

export type GetRestaurantVideoApiRequest = {
    restaurantVideoId: RestaurantVideoId;
};

export type RestaurantVideoVm = {
    restaurantVideoId: RestaurantVideoId;
    name: string;
    videoUrl: string;
    createdAt: Date;
    modifiedAt: Date;
};
