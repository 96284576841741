import { letseatmanagerApiMethod } from 'src/api/letseatmanagerApi';
import type { ApiSauceResponse } from 'src/api/types/ApiSauceResponse';
import type { SupplyType } from 'src/constants/SupplyType';
import type { UnitOfMeasurement } from 'src/constants/UnitOfMeasurement';
import type { SupplierId, SupplyCategoryId, SupplyId } from 'src/types/Id';
import type { SupplyVm } from 'src/types/SupplyVm';

export async function changeSupplyApi(request: ChangeSupplyApiRequest): ApiSauceResponse<SupplyVm> {
    return letseatmanagerApiMethod('supply/changeSupplyApi', request);
}

export type ChangeSupplyApiRequest = {
    supplyId: SupplyId;
    supplyCategoryId: SupplyCategoryId;
    supplierIds: Array<SupplierId>;
    name: string;
    type: SupplyType;
    quantity: string;
    sellUnit: UnitOfMeasurement;
    buyUnits: Array<{
        unit: UnitOfMeasurement;
        conversionFactor: number;
        isMainUnit?: boolean;
    }>;
    sku?: string;
    fixedCost?: string;
    minimumQuantity?: string;
    ingredients?: Array<Ingredient>;
};

export type Ingredient = {
    supplyId: SupplyId;
    quantity: string;
};
