import { makeStyles } from '@material-ui/core';
import FormHelperText from '@material-ui/core/FormHelperText';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import { BigNumber } from 'bignumber.js';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { useForm, useWatch } from 'react-hook-form';
import { changeTheRestaurantApi } from 'src/api/letseatadmin/theRestaurant/changeTheRestaurantApi';
import { pidedirectouploadApi } from 'src/api/pidedirectouploadApi';
import { DeprecatedFormPercentNumberField } from 'src/components/form/DeprecatedFormPercentNumberField';
import { Form } from 'src/components/form/Form';
import { FormBannerImageTypeSelect } from 'src/components/form/FormBannerImageTypeSelect';
import { FormBrandRestaurantSelect } from 'src/components/form/FormBrandRestaurantSelect';
import { FormCurrencyNumberStringField } from 'src/components/form/FormCurrencyNumberStringField';
import { FormFixErrorHint } from 'src/components/form/FormFixErrorHint';
import { FormImageUpload } from 'src/components/form/FormImageUpload';
import { FormPercentNumberField } from 'src/components/form/FormPercentNumberField';
import { FormPrefixPhoneNumberField } from 'src/components/form/FormPrefixPhoneNumberField';
import { FormProductLayoutSelect } from 'src/components/form/FormProductLayoutSelect';
import { FormRestaurantColors } from 'src/components/form/FormRestaurantColors';
import { FormSubheading } from 'src/components/form/FormSubheading';
import { FormSubmitButton } from 'src/components/form/FormSubmitButton';
import { FormSwitch } from 'src/components/form/FormSwitch';
import { FormTextField } from 'src/components/form/FormTextField';
import { FormTipSelect } from 'src/components/form/FormTipSelect';
import { FormUrlField } from 'src/components/form/FormUrlField';
import { getDirtyFormValues } from 'src/components/form/utils/getDirtyFormValues';
import ImageUploader from 'src/components/input/ImageUploader';
import { Spacing } from 'src/components/spacing/Spacing';
import { UpdatingContentProgress } from 'src/components/UpdatingContentProgress';
import { BannerImageTypes } from 'src/constants/BannerImageType';
import { ImageTypes } from 'src/constants/ImageType';
import { ProductLayouts } from 'src/constants/ProductLayouts';
import { translate } from 'src/i18n/translate';
import { appReducer } from 'src/reducers/appReducer';
import { RestaurantCard } from 'src/scenes/letseatadmin/theRestaurant/RestaurantCard';
import { useReloadRestaurant } from 'src/services/restaurant/useReloadRestaurant';
import { appColors } from 'src/theme/AppColors';
import { alertKnownErrorOrSomethingWentWrong } from 'src/utils/alert/alertKnownErrorOrSomethingWentWrong';
import { getErrorDescriptionFromResponse } from 'src/utils/error/getErrorDescriptionFromResponse';
import { isExternalImageUrl } from 'src/utils/image/isExternalImageUrl';
import { formatAsPercentage } from 'src/utils/number/formatAsPercentage';
import { useAction } from 'src/utils/react/useAction';
import { useSelector } from 'src/utils/react/useSelector';

export function RestaurantEcommerceSettings({ loading, restaurant, onChangeRestaurant, setIsDirtyForm }: Props): React.ReactElement {
    const classes = useStyles();
    const form = useForm();
    const {
        formState: { dirtyFields },
        watch,
        setValue,
        formState,
        control,
    } = form;

    const name = watch('name');
    const street = watch('street');
    const imageUrl = watch('imageUrl');
    const imageUrlMobile = watch('imageUrlMobile');
    const bannerImageType = watch('bannerImageType');
    const pwaDefaults = watch('pwaDefaults');
    const ecommerceOrdersCashbackEnabled = watch('ecommerceOrdersCashbackEnabled');
    const webOrdersEnabled = watch('webOrdersEnabled');
    const restaurantServiceFeeEnabled = watch('restaurantServiceFeeEnabled');
    const pideDirectoTipAsPercentageEnabled = watch('pideDirectoTipAsPercentageEnabled');
    const contactPhoneNumber = useWatch<number>({ name: 'contactPhoneNumber', control });
    const pideDirectoCustomTipPercentage = useWatch<number>({ name: 'pideDirectoCustomTipPercentage', control });
    const contactInformationEnabled = useWatch<number>({ name: 'contactInformationEnabled', control });

    const [kioskEnabled, setKioskEnabled] = useState(false);
    const [whiteLabelOrdersEnabled, setWhiteLabelOrdersEnabled] = useState(false);
    const [uploadingImage, setUploadingImage] = useState(false);

    const appLoading = useSelector((state) => state.app.loading);
    const restaurantId = useSelector((state) => state.app.restaurantId);

    const showSnackbar = useAction(appReducer.actions.showSnackbar);
    const { reloadRestaurant } = useReloadRestaurant();

    const isBrandMenuVisible = whiteLabelOrdersEnabled || kioskEnabled;

    useEffect(() => {
        if (!loading) {
            form.reset({
                restaurantId: restaurant.restaurantId ?? null,
                bannerImageType: (restaurant.imageUrl || restaurant.imageUrlMobile) && !restaurant.bannerImageType ? BannerImageTypes.CUSTOM : restaurant.bannerImageType,
                street: restaurant.street ?? null,
                name: restaurant.name ?? null,
                imageUrl: restaurant.imageUrl,
                imageUrlMobile: restaurant.imageUrlMobile,
                uberEatsMenuShouldUseMenuHoursEnabled: restaurant.uberEatsMenuShouldUseMenuHoursEnabled ?? null,
                mandatoryCashAmountEnabled: restaurant.mandatoryCashAmountEnabled ?? null,
                minimumOrderAmount: restaurant.minimumOrderAmount ?? null,
                maximumOrderAmount: restaurant.maximumOrderAmount ?? null,
                maximumCashOrderAmount: restaurant.maximumCashOrderAmount ?? null,
                maximumOrderAmountWithoutCardValidation: restaurant.maximumOrderAmountWithoutCardValidation ?? null,
                creditCardPaymentsEnabled: restaurant.creditCardPaymentsEnabled ?? null,
                paymentLinksEnabled: restaurant.paymentLinksEnabled ?? null,
                restaurantPromoCodesEnabled: restaurant.restaurantPromoCodesEnabled ?? null,
                promoCodeBannerEnabled: restaurant.promoCodeBannerEnabled ?? null,
                showDriverArriveAtStoreStatusOnEcommerceEnabled: restaurant.showDriverArriveAtStoreStatusOnEcommerceEnabled ?? null,
                hideEcommerceDriverTipEnabled: restaurant.hideEcommerceDriverTipEnabled ?? null,
                hideEcommerceRestaurantPhoneNumberEnabled: restaurant.hideEcommerceRestaurantPhoneNumberEnabled ?? null,
                hideCartEnabled: restaurant.hideCartEnabled ?? null,
                idsInMenuEnabled: restaurant.idsInMenuEnabled ?? null,
                hideMenuEditionEnabled: restaurant.hideMenuEditionEnabled ?? null,
                fakedProductDiscountPercentage: restaurant.fakedProductDiscountPercentage ?? null,
                subModifiersEnabled: restaurant.subModifiersEnabled ?? null,
                promotionsEnabled: restaurant.promotionsEnabled ?? null,
                newMenuManagementEnabled: restaurant.newMenuManagementEnabled ?? null,
                webOrdersEnabled: restaurant.webOrdersEnabled ?? null,
                brandMenuEnabled: restaurant.brandMenuEnabled ?? null,
                menuCategoryFamiliesEnabled: restaurant.menuCategoryFamiliesEnabled ?? null,
                limitProductsInOrder: restaurant.limitProductsInOrder ?? null,
                pideDirectoTipAsPercentageEnabled: restaurant.pideDirectoTipAsPercentageEnabled ?? null,
                pideDirectoDefaultTipPercentage: restaurant.pideDirectoDefaultTipPercentage ?? null,
                pideDirectoCustomTipPercentage: restaurant.pideDirectoCustomTipPercentage ?? null,
                pideDirectoTipEnabled: restaurant.pideDirectoTipEnabled ?? null,
                restaurantServiceFeeEnabled: restaurant.restaurantServiceFeeEnabled ?? null,
                restaurantServiceFeeRate: restaurant.restaurantServiceFeeRate ?? null,
                temporarilyOffline: restaurant.temporarilyOffline ?? null,
                temporarilyWithoutDelivery: restaurant.temporarilyWithoutDelivery ?? null,
                connectWithMasterMenu: restaurant.connectWithMasterMenu ?? null,
                restaurantColors: restaurant.restaurantColors ?? [],
                urlSubdomain: restaurant.urlSubdomain ?? null,
                urlPathname: restaurant.urlPathname ?? null,
                facebookPixelId: restaurant.facebookPixelId ?? null,
                googleAnalyticsId: restaurant.googleAnalyticsId ?? null,
                pwaDefaults: restaurant.pwaDefaults ?? null,
                productLayout: restaurant.productLayout ?? ProductLayouts.LARGE,
                pwa: restaurant.pwa ?? null,
                ecommerceOrdersCashbackPercentage: restaurant.ecommerceOrdersCashbackPercentage ?? null,
                ecommerceOrdersCashbackEnabled: restaurant.ecommerceOrdersCashbackEnabled ?? null,
                hideDeliveryEstimatedTimeEnabled: restaurant.hideDeliveryEstimatedTimeEnabled ?? null,
                hideOrderItemNotesEnabled: restaurant.hideOrderItemNotesEnabled ?? null,
                hideFollowUpBannerEnabled: restaurant.hideFollowUpBannerEnabled ?? null,
                contactInformationEnabled: restaurant.contactInformationEnabled ?? null,
                contactPhoneNumber: restaurant.contactPhoneNumber ?? null,
                contactWhatsAppPhoneNumber: restaurant.contactWhatsAppPhoneNumber ?? null,
            });
            setKioskEnabled(restaurant.kioskEnabled);
            setWhiteLabelOrdersEnabled(restaurant.whiteLabelOrdersEnabled);
        }
    }, [loading]);

    useEffect(() => {
        setIsDirtyForm(Object.keys(dirtyFields).length !== 0);
    }, [formState]);

    const onSubmit = async (form: any) => {
        let ecommerceOrdersCashbackPercentage = undefined;
        let imageUrl = undefined;
        const dirtyFormValues = getDirtyFormValues({ form, dirtyFields });

        if (dirtyFormValues.ecommerceOrdersCashbackPercentage) ecommerceOrdersCashbackPercentage = BigNumber(dirtyFormValues.ecommerceOrdersCashbackPercentage ?? 0).toNumber();
        if (dirtyFormValues.imageUrl) {
            imageUrl = form.imageUrl;
            if (isExternalImageUrl(imageUrl)) {
                const migrateResponse = await pidedirectouploadApi.images.migrate({
                    imageUrl: imageUrl,
                    imageType: ImageTypes.RESTAURANT,
                });
                if (!migrateResponse.ok) {
                    const errorMessage = `Failed to save cause of ${getErrorDescriptionFromResponse(migrateResponse)}`;
                    alert(errorMessage);
                    return;
                }
                dirtyFormValues.imageUrl = migrateResponse.data;
            }
        }

        const response = await changeTheRestaurantApi({
            restaurantId,
            ...dirtyFormValues,
            ecommerceOrdersCashbackPercentage: dirtyFormValues.ecommerceOrdersCashbackPercentage ? ecommerceOrdersCashbackPercentage : undefined,
        });
        if (!response.ok) {
            alertKnownErrorOrSomethingWentWrong(response);
            return;
        }
        await reloadRestaurant(restaurantId);
        await onChangeRestaurant();
        showSnackbar({ message: translate('Saved changes') });
    };

    return (
        <Paper className={classes.paper}>
            <UpdatingContentProgress loading={loading || appLoading} />
            <Form form={form} onSubmit={onSubmit} disabled={loading || appLoading}>
                <div className={classes.formContent}>
                    <Grid container justify='space-around'>
                        <Grid item>
                            <RestaurantCard name={name} street={street} imageUrl={imageUrl} bannerImageType={bannerImageType} />
                        </Grid>
                        {bannerImageType === BannerImageTypes.CUSTOM && (
                            <Grid item>
                                <RestaurantCard name={name} street={street} imageUrl={imageUrlMobile} bannerImageType={bannerImageType} />
                            </Grid>
                        )}
                    </Grid>
                    <Spacing units={3} />
                    <Grid container spacing={3}>
                        {bannerImageType === BannerImageTypes.CUSTOM && (
                            <>
                                <Grid item xs={6}>
                                    <FormImageUpload
                                        name='imageUrl'
                                        label={translate('Image')}
                                        classes={{ imagePreview: classes.imageBannerPreview }}
                                        message={translate('Make sure image is 1250x250 pixels or at least similar aspect ratio. Enter an URL to an image or drag and drop your image in this field')}
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <FormImageUpload
                                        name='imageUrlMobile'
                                        label={translate('Mobile image')}
                                        classes={{ imagePreview: classes.imageBannerPreview }}
                                        message={translate('Make sure image is 1024x576 pixels or at least similar aspect ratio. Enter an URL to an image or drag and drop your image in this field')}
                                    />
                                </Grid>
                            </>
                        )}
                        <Grid item xs={12} sm={6}>
                            <FormBannerImageTypeSelect
                                name='bannerImageType'
                                label={translate('Type of banner image')}
                                tooltip={translate(
                                    'PERSONALIZED: restaurants own banner image, GENERAL: default banner image for restaurants, RESTAURANT: Place a default banner image for restaurants, The image must be 1024x576 pixels or at least a similar aspect ratio. Enter a URL to an image or drag and drop your image into this field',
                                )}
                            />
                        </Grid>
                    </Grid>
                    <Grid container spacing={3}>
                        <Grid item xs={4} md={12}>
                            <FormRestaurantColors name='restaurantColors' />
                        </Grid>
                        <Spacing units={3} />
                        <Grid item xs={12} md={6}>
                            <FormSwitch name='paymentLinksEnabled' label={translate('Payment Links')} />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <FormSwitch name='restaurantPromoCodesEnabled' label={translate('Restaurant Promo Codes')} />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <FormSwitch name='promoCodeBannerEnabled' label={translate('Promo code banner')} tooltip={translate('Shows active promo codes banner in ecommerce')} />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <FormSwitch name='hideCartEnabled' label={translate('Hide Cart Enabled')} tooltip={translate('Allows you to hide the cart in the ecommerce to have only a digital menu')} />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <FormSwitch name='hideOrderItemNotesEnabled' label={translate('Hide notes in products')} tooltip={translate('Disables field to add notes in products on ecommerce.')} />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <FormSwitch
                                name='hideEcommerceDriverTipEnabled'
                                label={translate('Hide driver tip on ecommerce enabled')}
                                tooltip={translate('It will not show the driver tip section on ecommerce. This only apply when restaurants do their own deliveries.')}
                                disabled={!restaurant.ownFleetEnabled && !restaurant.externalDelivery && !restaurant.externalOwnFleetEnabled}
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <FormSwitch
                                name='hideEcommerceRestaurantPhoneNumberEnabled'
                                label={translate('Hide the restaurant phone number on the order tracking page enabled')}
                                tooltip={translate('It will not show the restaurant phone number on the order tracking page.')}
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <FormSwitch
                                name='hideCartEnabled'
                                label={translate('Enable ecommerce as digital menu')}
                                tooltip={translate('Allows you to hide the cart in the ecommerce to have only a digital menu')}
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <FormSwitch
                                name='mandatoryCashAmountEnabled'
                                label={translate('Mandatory Cash Amount')}
                                tooltip={translate('Activate this option if you require the Client to indicate with what amount he will pay in cash')}
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <FormSwitch
                                name='ecommerceOrdersCashbackEnabled'
                                label={translate('Ecommerce Orders Cashback Enabled')}
                                tooltip={translate('Enable the option if you want the user to be awarded cashback points after they make a purchase.')}
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <FormSwitch name='hideDeliveryEstimatedTimeEnabled' label={translate('Hide delivery estimated time enabled')} />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <DeprecatedFormPercentNumberField
                                name='ecommerceOrdersCashbackPercentage'
                                label={translate('Ecommerce Orders Cashback %')}
                                disabled={!ecommerceOrdersCashbackEnabled}
                                tooltip={translate('Define the percentage of cashback points for orders placed in the app')}
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <FormCurrencyNumberStringField
                                name='minimumOrderAmount'
                                label={translate('Minimum Order Amount')}
                                tooltipText={translate('Determine a minimum purchase amount for ecommerce. If the quantity is not reached, the order cannot be generated')}
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <DeprecatedFormPercentNumberField
                                name='fakedProductDiscountPercentage'
                                label={translate('Faked Product Discount Percentage')}
                                helperText={translate('Percentage to raise all menu item prices with and while product discount price be kept at current menu item price')}
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <FormCurrencyNumberStringField
                                name='maximumOrderAmount'
                                label={translate('Maximum Order Amount')}
                                tooltipText={translate('Determine a maximum purchase amount for ecommerce. If the quantity is reached, the order cannot be generated')}
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <FormCurrencyNumberStringField
                                name='maximumCashOrderAmount'
                                label={translate('Maximum Cash Order Amount')}
                                tooltipText={translate("If this value is higher than the maximum order amount this field won't be used")}
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <FormCurrencyNumberStringField
                                name='maximumOrderAmountWithoutCardValidation'
                                label={translate('Maximum order amount without card validation')}
                                tooltipText={translate('Maximum order amount wihtout card validation, this only applies for MX, default is $300')}
                            />
                        </Grid>
                    </Grid>
                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                            <FormSubheading align={'start' as any}>{translate('Menu Settings')}</FormSubheading>
                        </Grid>
                    </Grid>
                    <Grid container spacing={3}>
                        <Grid item xs={12} md={6}>
                            <FormSwitch
                                name='idsInMenuEnabled'
                                label={translate('Ids In Menu Enabled')}
                                tooltip={translate('Activate this option if you want to show the identifiers of the products in the menu')}
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <FormSwitch name='hideMenuEditionEnabled' label={translate('Menu Edition Disabled')} tooltip={translate('Disable menu editing')} />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <FormSwitch
                                name='uberEatsMenuShouldUseMenuHoursEnabled'
                                label={translate('Uber Eats Menu Should Use Menu Hours Enabled')}
                                tooltip={translate('Activate this option if you want Uber eats to use the menu hours and not the restaurants opening hours, this will affect the copy menu section')}
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <FormSwitch name='subModifiersEnabled' label={translate('Sub-Modifiers Enabled')} tooltip={translate('Enable submodifiers for your products')} />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <FormSwitch name='promotionsEnabled' label={translate('Manage promotions enabled')} />
                        </Grid>
                        {!restaurant?.newMenuManagementEnabled && (
                            <Grid item xs={12} md={6}>
                                <FormSwitch name='newMenuManagementEnabled' label={translate('New menu management enabled')} tooltip={translate('Enable the option to use Menu Manager 2.0')} />
                            </Grid>
                        )}
                        <Grid item xs={12} md={6}>
                            <FormSwitch name='menuCategoryFamiliesEnabled' label={translate('Menu category families enabled')} />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <FormBrandRestaurantSelect
                                name='connectWithMasterMenu'
                                label={translate('Link to Master menu')}
                                tooltip={translate('Enter the requested ID if you want to associate the master menu from another restaurant')}
                            />
                        </Grid>
                        {isBrandMenuVisible && (
                            <Grid item xs={12} md={6}>
                                <FormSwitch name='brandMenuEnabled' label={translate('Brand Menu Enabled')} />
                            </Grid>
                        )}
                        <Grid item xs={12} md={6}>
                            <FormSwitch
                                name='limitProductsInOrder'
                                label={translate('Limit Products')}
                                tooltip={translate('Turning on, will limit the amount of products that each delivery order can take')}
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <FormSwitch
                                name='pideDirectoTipAsPercentageEnabled'
                                label={translate('Pidedirecto tip as percentage enabled')}
                                tooltip={translate('In ecommerce shows the tip as percentage')}
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <FormSwitch
                                name='restaurantServiceFeeEnabled'
                                label={translate('Restaurant service fee enabled')}
                                tooltip={translate('Allows to restaurant to charge a service fee in ecommerce and app')}
                            />
                        </Grid>
                        {!!restaurantServiceFeeEnabled && (
                            <Grid item xs={12} md={6}>
                                <FormPercentNumberField name='restaurantServiceFeeRate' label={translate('Restaurant service fee rate')} required={restaurantServiceFeeEnabled} />
                            </Grid>
                        )}
                        <Grid item xs={12} md={6}>
                            <FormSwitch name='pideDirectoTipEnabled' label={translate('PideDirecto tip enabled')} tooltip={translate('Enable changes on driver tip in ecommerce')} />
                        </Grid>

                        {!!pideDirectoTipAsPercentageEnabled && (
                            <>
                                <Grid item xs={12} md={6}>
                                    <FormPercentNumberField
                                        name='pideDirectoCustomTipPercentage'
                                        label={translate('Custom tip')}
                                        helperText={translate('Set a custom tip option when tip as percentage setting is enabled')}
                                    />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <FormTipSelect
                                        name='pideDirectoDefaultTipPercentage'
                                        label={translate('Default tip')}
                                        helperText={translate('Select the tip percentage to set it as default')}
                                        additionalOptions={pideDirectoCustomTipPercentage ? [{ value: pideDirectoCustomTipPercentage, label: formatAsPercentage(pideDirectoCustomTipPercentage) }] : []}
                                    />
                                </Grid>
                            </>
                        )}
                    </Grid>
                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                            <FormSubheading align={'start' as any}>{translate('Web OrdersPage Settings')}</FormSubheading>
                        </Grid>
                    </Grid>
                    <Grid container spacing={3}>
                        <Grid item xs={12} sm={6}>
                            <FormSwitch
                                name='temporarilyOffline'
                                label={translate('Temporarily Offline')}
                                switchStyle='danger'
                                tooltip={translate('Temporarily disable orders from ecommerce and WLA')}
                            />
                        </Grid>
                    </Grid>

                    <Grid container spacing={3}>
                        <Grid item xs={12} sm={6}>
                            <FormSwitch
                                name='temporarilyWithoutDelivery'
                                label={translate('Temporarily without delivery')}
                                switchStyle='danger'
                                tooltip={translate('Temporarily disable delivery orders from ecommerce and WLA')}
                            />
                        </Grid>
                    </Grid>
                    <Grid container spacing={3}>
                        <Grid item xs={12} sm={6}>
                            <FormSwitch
                                name='showDriverArriveAtStoreStatusOnEcommerceEnabled'
                                label={translate('Show when driver arrive to store')}
                                tooltip={translate('Enable to show when driver arrive to store on order tracking page')}
                            />
                        </Grid>
                    </Grid>
                    <Grid container spacing={3}>
                        <Grid item xs={12} sm={6}>
                            <FormSwitch name='hideFollowUpBannerEnabled' label={translate('Do not show promotion banner in order tracking')} switchStyle='danger' />
                        </Grid>
                    </Grid>
                    <Grid container spacing={3}>
                        <Grid item xs={6} md={4}>
                            <FormSwitch name='contactInformationEnabled' label={translate('Enable contact information')} />
                        </Grid>
                        {contactInformationEnabled && (
                            <>
                                <Grid item xs={12} sm={3} md={4}>
                                    <span className={(classes as any).label}>{translate('Phone Number')}</span>
                                    <FormPrefixPhoneNumberField name='contactPhoneNumber' required />
                                </Grid>
                                <Grid item xs={12} sm={3} md={4}>
                                    <span className={(classes as any).label}>{translate('WhatsApp number')}</span>
                                    <FormPrefixPhoneNumberField name='contactWhatsAppPhoneNumber' />
                                </Grid>
                            </>
                        )}
                    </Grid>
                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                            <FormSwitch name='webOrdersEnabled' label={translate('Web OrdersPage (AmbitOne Web menu)')} />
                            <FormHelperText>
                                {translate(
                                    'Enable this to let make restaurant web menu available at https://urlsubdomain.pidedirecto.mx/urlpathname. Don´t forget to set Url Subdomain and Url Pathname below.',
                                )}
                            </FormHelperText>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <FormProductLayoutSelect name={'productLayout'} label={translate('Types layouts to ecommerce')} />
                        </Grid>
                    </Grid>
                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                            <FormTextField
                                name='urlSubdomain'
                                label={translate('Url Subdomain (url friendly restaurant name)')}
                                helperText={translate(
                                    'An url friendly restaurant name (dont use spaces and special characters). If set to e.g. azucar the pidedirecto web will be available at https://azucar.pidedirecto.mx. Use same subdomain to group restaurants within the same restaurant chain.',
                                )}
                                // required={this.props.formValues.webOrdersEnabled}
                                // validate={this.props.formValues.webOrdersEnabled ? required : undefined}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <FormTextField
                                name='urlPathname'
                                label={translate('Url Pathname (url friendly restaurant location name)')}
                                helperText={translate(
                                    'An url friendly restaurant location name (dont use spaces and special characters). If set to e.g. morena-martel the pidedirecto web will be available at https://azucar.pidedirecto.mx/morena-martel. Only one name can be used per restaurant subdomain. Saving an already used name within the subdomain fails.',
                                )}
                                // required={this.props.formValues.webOrdersEnabled}
                                // validate={this.props.formValues.webOrdersEnabled ? required : undefined}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <FormTextField
                                name='pwa.name'
                                label={translate('PWA Name')}
                                helperText={translate(
                                    'An identifying name for the app. Can be used by app stores, browsers on startup screens and prompts, or by operating systems on app launchers and settings screens. see https://developer.mozilla.org/en-US/docs/Web/Manifest#Creating_a_valid_manifest',
                                )}
                                placeholder={pwaDefaults?.name}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <FormTextField
                                name='pwa.short_name'
                                label={translate('PWA short_name')}
                                helperText={translate(
                                    'A short name, used to display the name to the user where there is limited display space, such as below the icon on a homescreen or app launcher menu. see https://developer.mozilla.org/en-US/docs/Web/Manifest#Creating_a_valid_manifest',
                                )}
                                placeholder={pwaDefaults?.short_name}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <FormUrlField
                                name='pwa.favicon'
                                label={uploadingImage ? translate('Uploading Image...') : translate('PWA Favicon Image')}
                                helperText={
                                    uploadingImage ? undefined : translate('Make sure the image is a minimum of 512x512 pixels. Enter a URL to an image or drag and drop an image into this field.')
                                }
                                placeholder={pwaDefaults?.favicon}
                            />
                            <ImageUploader
                                imageType={ImageTypes.RESTAURANT_FAVICON}
                                targetName='pwa.favicon'
                                onUploadingChanged={(uploading) => setUploadingImage(uploading)}
                                onUploadSuccess={(imageUrl) => setValue('pwa.favicon', imageUrl)}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <FormUrlField
                                name='pwa.appicon'
                                label={uploadingImage ? translate('Uploading Image...') : translate('PWA App Icon Image')}
                                helperText={
                                    uploadingImage ? undefined : translate('Make sure the image is a minimum of 512x512 pixels. Enter a URL to an image or drag and drop an image into this field.')
                                }
                                placeholder={pwaDefaults?.appicon}
                            />
                            <ImageUploader
                                imageType={ImageTypes.RESTAURANT_APPICON}
                                targetName='pwa.appicon'
                                onUploadingChanged={(uploading) => setUploadingImage(uploading)}
                                onUploadSuccess={(imageUrl) => setValue('pwa.appicon', imageUrl)}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <FormUrlField
                                name='pwa.splashicon'
                                label={uploadingImage ? translate('Uploading Image...') : translate('PWA Splash Icon Image')}
                                helperText={
                                    uploadingImage ? undefined : translate('Make sure the image is a minimum of 512x512 pixels. Enter a URL to an image or drag and drop an image into this field.')
                                }
                                placeholder={pwaDefaults?.splashicon}
                            />
                            <ImageUploader
                                imageType={ImageTypes.RESTAURANT_SPLASHICON}
                                targetName='pwa.splashicon'
                                onUploadingChanged={(uploading) => setUploadingImage(uploading)}
                                onUploadSuccess={(imageUrl) => setValue('pwa.splashicon', imageUrl)}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <FormTextField name='pwa.theme_color' label={translate('PWA theme_color')} helperText={translate('Header bar color')} placeholder={pwaDefaults?.theme_color} />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <FormTextField
                                name='pwa.background_color'
                                label={translate('PWA background_color')}
                                helperText={translate('Background color of splash screen')}
                                placeholder={pwaDefaults?.background_color}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <FormTextField
                                name='facebookPixelId'
                                label={translate('Facebook pixel account Id')}
                                placeholder={'GTM-****'}
                                helperText={translate('The facebook pixel ID connected to the restaurant')}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <FormTextField
                                name='googleAnalyticsId'
                                label={translate('Google tag Manager account ID')}
                                placeholder={'309489009416843'}
                                helperText={translate('The google tag manager ID connected to the restaurant')}
                            />
                        </Grid>
                    </Grid>
                    <FormFixErrorHint />
                </div>

                <Grid container justify='center' spacing={3}>
                    <Grid item>
                        <FormSubmitButton text={translate('Change')} disabled={loading || appLoading} classes={{ container: (classes as any).submitButton }} />
                    </Grid>
                </Grid>
            </Form>
        </Paper>
    );
}

const useStyles = makeStyles((theme) => ({
    paper: {
        position: 'relative',
    },
    formContent: {
        padding: theme.spacing(3),
    },
    deleteButton: {
        color: appColors.text.dangerLight,
    },
    section: {
        width: '100%',
        borderRadius: 5,
        margin: '20px auto',
        border: `1px solid ${theme.palette.secondary.dark}`,
    },
    sectionTitleContainer: {
        backgroundColor: theme.palette.secondary.light,
        marginTop: 20,
    },
    sectionTitle: {
        fontFamily: theme.typography.semiBold,
        fontSize: 14,
    },
    subtitlesubtitle: {
        fontFamily: theme.typography.medium,
        fontSize: 18,
        color: theme.palette.secondary.contrastText,
        margin: 0,
    },
    cashRegisterCustomReasonItem: {
        fontFamily: theme.typography.medium,
        fontSize: 15,
        minWidth: 140,
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        border: '1px solid #676060B2',
        borderRadius: 12,
        padding: '0 10px',
    },
    title: {
        fontFamily: theme.typography.semiBold,
        fontSize: 20,
        color: theme.palette.secondary.contrastText,
        marginLeft: 10,
    },
    subtitle: {
        fontFamily: theme.typography.medium,
        fontSize: 18,
        color: theme.palette.secondary.contrastText,
        margin: 0,
    },
    paymentMethod: {
        fontFamily: theme.typography.medium,
        fontSize: 15,
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
    },
    secondaryButton: {
        backgroundColor: 'white',
        color: theme.palette.primary.main,
        border: `1px solid ${theme.palette.primary.main}`,
        fontFamily: theme.typography.semiBold,
        fontSize: 15,
        borderRadius: 5,
        width: 200,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        textTransform: 'none',
        padding: '5px 10px',
    },
    kioskUserContainer: {
        width: '100%',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
    },
    text: {
        fontFamily: theme.typography.regular,
        fontSize: 15,
    },
    kioskInfo: {
        display: 'flex',
        flexDirection: 'column',
    },
    integrationStoreStatusClosed: {
        fontFamily: theme.typography.semiBold,
        color: '#fa6684',
        fontSize: 13,
    },
    integrationStoreStatusOpen: {
        fontFamily: theme.typography.semiBold,
        color: theme.palette.primary.main,
        fontSize: 13,
    },
    labelError: {
        color: 'red',
    },
    helperText: {
        fontFamily: theme.typography.light,
        color: theme.palette.secondary.contrastText,
    },
    helperTextError: {
        fontFamily: theme.typography.light,
        color: 'red',
    },
    zIndex: {
        zIndex: 1000,
    },
    imageBannerPreview: {
        display: 'none',
    },
    label: {
        color: '#2E3748',
        fontSize: '14px',
        fontFamily: 'PoppinsLight',
        paddingBottom: '5px',
    },
}));

type Props = {
    loading: boolean;
    restaurant: any;
    onChangeRestaurant: any;
    setIsDirtyForm: any;
};
