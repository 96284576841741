import { useEffect } from 'react';
import * as React from 'react';
import { FormAutocompleteMultiple } from 'src/components/form/FormAutocompleteMultiple';
import type { Variant } from 'src/components/form/FormTypes';
import TheMenuActions from 'src/scenes/letseatmanager/deprecatedMenu/TheMenuReducer';
import { useLoadMenuItems } from 'src/services/menuItem/useLoadMenuItems';
import { useMenuItems } from 'src/services/menuItem/useMenuItems';
import { useAction } from 'src/utils/react/useAction';
import { useSelector } from 'src/utils/react/useSelector';

export function FormMenuItemsAutocomplete({ name, label, placeholder, helperText, defaultValue, disabled, required, variant }: Props): React.ReactElement {
    const menus = useSelector((state) => state.theMenu.menus);
    const restaurantId = useSelector((state) => state.app.restaurantId);

    const refreshMenuItems = useLoadMenuItems();
    const { deprecatedMenuItems, menuItems } = useMenuItems();

    const fetch = useAction(TheMenuActions.fetch);

    const optionsMenuItemsOptions = [...deprecatedMenuItems, ...menuItems].map((item) => ({ value: item.menuItemId, label: item.name }));

    useEffect(() => {
        if (!menus?.length) fetch(restaurantId);
    }, [menus]);

    useEffect(() => {
        refreshMenuItems();
    }, []);

    return (
        <FormAutocompleteMultiple
            name={name}
            label={label}
            placeholder={placeholder}
            helperText={helperText}
            defaultValue={defaultValue}
            options={optionsMenuItemsOptions}
            disabled={disabled || !optionsMenuItemsOptions.length}
            required={required}
            variant={variant}
        />
    );
}

type Props = {
    name: string;
    label?: string;
    placeholder?: string;
    helperText?: string;
    defaultValue?: Array<string>;
    disabled?: boolean;
    required?:
        | boolean
        | {
              value: number;
              message: string;
          };
    variant?: Variant;
};
