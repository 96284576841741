import type { CustomerId } from 'src/types/Id';

export const KEY_NAME = 'restaurantCustomers';

export function getRestaurantCustomersInLocalStorage(): Array<RestaurantCustomer> {
    const item = window.localStorage.getItem('restaurantCustomers');
    return item ? JSON.parse(item) : [];
}

export type RestaurantCustomer = {
    label: string;
    value: CustomerId;
};
