import { makeStyles } from '@material-ui/core/styles';
import * as React from 'react';
import { classNames } from 'src/utils/react/classNames';

/**
 * Renders a box component with customizable styles.
 * @param {React.Node} children - The content to be rendered inside the box.
 * @param {Function} [onClick] - Function to be called when the box is clicked.
 * @param {string} [className] - Additional CSS class(es) to be applied to the box.
 * @returns {React.Node}
 * @constructor
 */

export function Box({ children, onClick, className }: Props): React.ReactElement {
    const classes = useStyles();

    return (
        <div className={classNames(className, classes.container)} onClick={onClick}>
            {children}
        </div>
    );
}

const useStyles = makeStyles((theme) => ({
    container: {
        backgroundColor: 'white',
        boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25), 0px 8px 20px -4px rgba(23, 24, 24, 0.12)',
        borderRadius: 12,
        padding: '8px 12px',
        width: 'fit-content',
        fontFamily: theme.typography.regular,
    },
}));

type Props = {
    children: React.ReactNode;
    onClick?: any;
    className?: string;
};
