import { makeStyles } from '@material-ui/core';
import { ClosedEyeIcon, EyeIcon } from '@pidedirecto/ui/icons';
import moment from 'moment';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { unHideMenuItemApi } from 'src/api/letseatmanager/menuItem/unHideMenuItemApi';
import { app2 } from 'src/app2';
import { Button } from 'src/components/Button';
import { SecuredContent } from 'src/components/SecuredContent';
import { RolePermissions } from 'src/constants/RolePermission';
import { translate } from 'src/i18n/translate';
import noImageIcon from 'src/images/icon-no-photo.png';
import { ManageMenu } from 'src/scenes/letseatmanager/deprecatedMenu/ManageMenu';
import TheMenuActions from 'src/scenes/letseatmanager/deprecatedMenu/TheMenuReducer';
import { useFormatAsRestaurantCurrencyNumber } from 'src/services/restaurant/useFormatAsRestaurantCurrencyNumber';
import type { ManagerMenuItemVm } from 'src/types/DeprecatedManagerMenuItemVm';
import type { MenuCategoryId } from 'src/types/Id';
import { alertKnownErrorOrSomethingWentWrong } from 'src/utils/alert/alertKnownErrorOrSomethingWentWrong';
import { transformImageUrlMenuItem } from 'src/utils/image/transformImageUrlMenuItem';
import { useAction } from 'src/utils/react/useAction';

export function MenuItem({ loading, menuItem, menuCategoryId, position, disableMenuEditing, idsInMenuEnabled, isExternalMenu, onSuccess }: Props): React.ReactElement {
    const classes = useStyles();
    const formatAsCurrencyNumber = useFormatAsRestaurantCurrencyNumber();

    const moveMenuItemUp = useAction(TheMenuActions.moveMenuItemUp);
    const moveMenuItemDown = useAction(TheMenuActions.moveMenuItemDown);
    const openCreateMenuItemDialog = useAction(app2.actions.openCreateMenuItemDialog);
    const openChangeMenuItemDialog = useAction(app2.actions.openChangeMenuItemDialog);
    const openRemoveMenuItemDialog = useAction(app2.actions.openRemoveMenuItemDialog);
    const openMoveMenuItemToMenuDialog = useAction(app2.actions.openMoveMenuItemToMenuDialog);
    const openHideMenuItemDialog = useAction(app2.actions.openHideMenuItemDialog);

    const [hidden, setHidden] = useState(menuItem.hidden);

    useEffect(() => {
        setHidden(menuItem.hidden);
    }, [menuItem.hidden]);

    const handleMoveUp = () => {
        if (loading) return;
        moveMenuItemUp({
            menuItemId: menuItem.menuItemId,
        });
    };

    const handleMoveDown = () => {
        if (loading) return;
        moveMenuItemDown({
            menuItemId: menuItem.menuItemId,
        });
    };

    const handleClickChange = () => {
        if (loading) return;
        openChangeMenuItemDialog({ menuItem, isExternalMenu, onSuccess });
    };

    const handleClickDuplicate = () => {
        if (loading) return;
        openCreateMenuItemDialog({ menuCategoryId, position, onSuccess, menuItem, menuItemType: menuItem.menuItemType });
    };

    const handleClickCreate = () => {
        if (loading) return;
        openCreateMenuItemDialog({ menuCategoryId, position, isExternalMenu, onSuccess, menuItemType: menuItem.menuItemType });
    };

    const handleClickRemove = () => {
        if (loading) return;
        openRemoveMenuItemDialog({ menuItem, onSuccess });
    };

    const handleClickMoveToMenu = () => {
        if (loading) return;
        openMoveMenuItemToMenuDialog({ menuItem, onSuccess });
    };

    const handleToggleHide = async () => {
        if (loading) return;
        if (menuItem.hidden) {
            const response = await unHideMenuItemApi({ menuItemId: menuItem.menuItemId });
            if (!response.ok) {
                alertKnownErrorOrSomethingWentWrong(response);
                return;
            }
            setHidden(false);
            onSuccess();
        } else {
            openHideMenuItemDialog({ menuItem, onSuccess });
        }
    };

    return (
        <div className={classes.menuItemContainer}>
            <div className={classes.optionIconContainer} style={{ width: 40 }}>
                {!disableMenuEditing && (
                    <SecuredContent rolePermission={RolePermissions.MENU_EDIT}>
                        <ManageMenu
                            onClickMoveUp={handleMoveUp}
                            onClickMoveDown={handleMoveDown}
                            onClickMoveToMenu={handleClickMoveToMenu}
                            onClickChange={handleClickChange}
                            onClickDuplicate={handleClickDuplicate}
                            onClickCreate={handleClickCreate}
                            onClickRemove={handleClickRemove}
                        />
                    </SecuredContent>
                )}
            </div>

            <div className={classes.menuItem}>
                {menuItem.imageUrl && <img src={transformImageUrlMenuItem(menuItem.imageUrl)} alt={`image for ${menuItem.name}`} className={classes.image} />}
                {!menuItem.imageUrl && (
                    <div className={classes.noImageContainer}>
                        <img src={noImageIcon} alt='no image icon' className={classes.noImage} />
                    </div>
                )}
                <div className={classes.menuItemInfoContainer}>
                    <h4 className={classes.name}>
                        {menuItem.name} {formatAsCurrencyNumber(menuItem.price)}
                    </h4>
                    {idsInMenuEnabled && <h5 className={classes.id}>ID: {menuItem.menuItemId} </h5>}
                    <p className={classes.description}>{menuItem.description}</p>
                </div>
            </div>
            <div className={classes.optionIconContainer}>
                <Button icon classes={{ button: classes.hiddenButton }} onClick={handleToggleHide}>
                    {hidden && <ClosedEyeIcon />}
                    {!hidden && <EyeIcon />}
                </Button>
                {menuItem.hiddenUntil && hidden && (
                    <div className={classes.hiddenUntil}>
                        {translate('Hidden until')} {moment(menuItem.hiddenUntil).format('lll')}
                    </div>
                )}
            </div>
        </div>
    );
}

const useStyles = makeStyles((theme) => ({
    menuItemContainer: {
        display: 'flex',
        flexDirection: 'row',
        marginBottom: 10,
    },
    menuItem: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'flex-start',
        width: '85%',
        [theme.breakpoints.down('sm')]: {
            width: '70%',
        },
    },
    image: {
        borderRadius: 8,
        width: 70,
        height: 70,
        objectFit: 'cover',
        flexShrink: 0,
    },
    noImageContainer: {
        borderRadius: 8,
        width: 70,
        height: 70,
        backgroundColor: theme.palette.secondary.dark,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexShrink: 0,
    },
    noImage: {
        width: 30,
        height: 30,
        objectFit: 'cover',
    },
    menuItemInfoContainer: {
        display: 'flex',
        flexDirection: 'column',
        marginLeft: 20,
    },
    name: {
        fontFamily: theme.typography.semiBold,
        fontSize: 17,
        margin: 0,
    },
    id: {
        fontFamily: theme.typography.medium,
        fontSize: 15,
        margin: 0,
    },
    description: {
        fontFamily: theme.typography.regular,
        fontSize: 15,
        margin: 0,
        marginTop: 5,
    },
    optionIconContainer: {
        height: '100%',
        width: '35%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        color: theme.palette.text.brand,
    },
    hiddenButton: {
        color: theme.palette.text.brand,
    },
    visibilityOffIcon: {
        color: 'disabled',
        marginRight: 12,
        '&:hover': {
            cursor: 'pointer',
        },
    },
    visibilityOnIcon: {
        color: 'primary',
        marginRight: 12,
        '&:hover': {
            cursor: 'pointer',
        },
    },
    hiddenUntil: {
        [theme.breakpoints.down('sm')]: {
            display: 'none',
        },
    },
}));

type Props = {
    menuItem: ManagerMenuItemVm;
    menuCategoryId: MenuCategoryId;
    position: number;
    loading: boolean;
    disableMenuEditing?: boolean;
    idsInMenuEnabled?: boolean;
    isExternalMenu?: boolean;
    onSuccess: any;
};
