import { letseatmanagerApiMethod } from 'src/api/letseatmanagerApi';
import type { ApiSauceResponse } from 'src/api/types/ApiSauceResponse';
import type { BuyOrderVm } from 'src/types/BuyOrderVm';
import type { BuyOrderId } from 'src/types/Id';

export async function getBuyOrderApi(request: GetBuyOrderApiRequest): ApiSauceResponse<GetBuyOrderApiResponse> {
    return letseatmanagerApiMethod('buyOrder/getBuyOrderApi', request);
}

type GetBuyOrderApiRequest = {
    buyOrderId: BuyOrderId;
};

type GetBuyOrderApiResponse = BuyOrderVm;
