import { letseatmanagerApiMethod } from 'src/api/letseatmanagerApi';
import type { ApiSauceResponse } from 'src/api/types/ApiSauceResponse';
import type { PaymentTerminalPaymentFailedReason } from 'src/constants/PaymentTerminalPaymentFailedReason';
import type { PaymentTerminalPaymentStatus } from 'src/constants/PaymentTerminalPaymentStatus';
import type { PaymentTerminalId, PaymentTerminalPaymentId } from 'src/types/Id';

export async function pushPaymentTerminalPaymentApi(request: Request): ApiSauceResponse<Response> {
    return letseatmanagerApiMethod('paymentTerminalPayment/pushPaymentTerminalPaymentApi', request);
}

type Request = {
    paymentTerminalId: PaymentTerminalId;
    amount: string;
    tipAmount?: string;
    apphook?: string;
    mock?: PaymentTerminalPaymentResultMock;
};

export type PaymentTerminalPaymentResultMock = {
    paymentTerminalPaymentStatus: PaymentTerminalPaymentStatus; // only PAID and FAILED,
    paymentTerminalPaymentFailedReason?: PaymentTerminalPaymentFailedReason;
    respondAt?: Date; // undefined means N/A and directly
};

type Response = {
    paymentTerminalPaymentId: PaymentTerminalPaymentId;
    paymentTerminalPaymentStatus: PaymentTerminalPaymentStatus;
    paymentTerminalPaymentFailedReason?: PaymentTerminalPaymentFailedReason;
    message?: string;
};
