import { letseatmanagerApiMethod } from 'src/api/letseatmanagerApi';
import type { ApiSauceResponse } from 'src/api/types/ApiSauceResponse';
import type { MenuCategoryId, MenuItemId } from 'src/types/Id';

export async function removeMenuItemFromMenuCategoryApi(request: RemoveMenuItemFromMenuCategoryApiRequest): ApiSauceResponse<void> {
    return letseatmanagerApiMethod('menuItem/removeMenuItemFromMenuCategoryApi', request);
}

type RemoveMenuItemFromMenuCategoryApiRequest = {
    menuItemId: MenuItemId;
    menuCategoryId: MenuCategoryId;
};
