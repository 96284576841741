import { makeStyles } from '@material-ui/core';
import orderBy from 'lodash/orderBy';
import moment from 'moment';
import { useState } from 'react';
import * as React from 'react';
import { useHistory } from 'react-router-dom';
import { findBuyOrdersByRestaurantIdApi } from 'src/api/letseatmanager/buyOrder/findBuyOrdersByRestaurantIdApi';
import { removeBuyOrderApi } from 'src/api/letseatmanager/buyOrder/removeBuyOrderApi';
import { findSuppliersByRestaurantIdApi } from 'src/api/letseatmanager/supplier/findSuppliersByRestaurantIdApi';
import { RefreshToolbarButton } from 'src/components/mui-datatables/RefreshToolbarButton';
import { SecuredContent } from 'src/components/SecuredContent';
import { Table } from 'src/components/Table';
import { UpdatingContentProgress } from 'src/components/UpdatingContentProgress';
import { BuyOrderStatuses } from 'src/constants/BuyOrderStatus';
import { RolePermissions } from 'src/constants/RolePermission';
import { RoutePaths } from 'src/constants/RoutePath';
import { translate } from 'src/i18n/translate';
import { appReducer } from 'src/reducers/appReducer';
import { BuyOrdersTableContextualMenu } from 'src/scenes/letseatmanager/inventory/buyOrder/BuyOrdersTableContextualMenu';
import { CreateBuyOrderToolbarContextMenu } from 'src/scenes/letseatmanager/inventory/buyOrder/CreateBuyOrderToolbarContextMenu';
import { useFormatAsRestaurantCurrencyNumber } from 'src/services/restaurant/useFormatAsRestaurantCurrencyNumber';
import { alertKnownErrorOrSomethingWentWrong } from 'src/utils/alert/alertKnownErrorOrSomethingWentWrong';
import { useAction } from 'src/utils/react/useAction';
import { useLoadApi } from 'src/utils/react/useLoadApi';
import { useSelector } from 'src/utils/react/useSelector';
import { RegExps } from 'src/utils/RegExps';

export function BuyOrdersTable(): React.ReactElement {
    const classes = useStyles();
    const history = useHistory();
    const formatAsCurrencyNumber = useFormatAsRestaurantCurrencyNumber();

    const [loading, setLoading] = useState(false);

    const pinCodeUserManagerUserId = useSelector((state) => state.authentication.pinCodeUserSignedIn?.managerUserId);
    const restaurantId = useSelector((state) => state.app.restaurantId);
    const inventoryLinkedToRestaurantId = useSelector((state) => state.app.restaurant?.inventoryLinkedToRestaurantId);

    const showSnackbar = useAction(appReducer.actions.showSnackbar);

    const [, buyOrders, load] = useLoadApi(findBuyOrdersByRestaurantIdApi, { restaurantId }, { requiredValues: [restaurantId], dependencies: [restaurantId] });
    const [loadingSupplier, { suppliers }] = useLoadApi(findSuppliersByRestaurantIdApi, { restaurantId }, { initialValue: { suppliers: [] } });

    const removeBuyOrder = async (buyOrderId: any) => {
        const remove = window.confirm(translate('Are you sure you want to remove the buyOrder?'));

        if (!remove) return;

        setLoading(true);
        const response = await removeBuyOrderApi({
            buyOrderId: buyOrderId,
            managerUserId: pinCodeUserManagerUserId,
        });
        setLoading(false);
        if (!response.ok) {
            alertKnownErrorOrSomethingWentWrong(response);
            return;
        }
        showSnackbar({ message: translate('Buy order canceled successfully') });
        load();
    };

    const onEdit = (buyOrderId: any) => {
        history.push({
            pathname: RoutePaths.INVENTORY_CHANGE_BUY_ORDER.replace(`:buyOrderId(${RegExps.uuid})`, buyOrderId),
            search: history.location.search,
        });
    };

    const getSortedBuyOrders = () => {
        if (!buyOrders) return;

        const buyOrdersOrderedPending = orderBy(
            buyOrders.filter((buyOrder) => buyOrder.buyOrderStatus === BuyOrderStatuses.PENDING),
            ['modifiedAt'],
            ['desc'],
        );

        const buyOrdersOrderedSent = orderBy(
            buyOrders.filter((buyOrder) => buyOrder.buyOrderStatus === BuyOrderStatuses.SENT),
            ['modifiedAt'],
            ['desc'],
        );

        const buyOrdersOrderedCompleted = orderBy(
            buyOrders.filter((buyOrder) => buyOrder.buyOrderStatus === BuyOrderStatuses.COMPLETED),
            ['modifiedAt'],
            ['desc'],
        );

        const buyOrdersOrderedAll = [
            ...buyOrdersOrderedPending,
            ...buyOrdersOrderedSent,
            ...buyOrdersOrderedCompleted,
            ...buyOrders.filter((buyOrder) => buyOrder.buyOrderStatus === BuyOrderStatuses.CANCELLED),
        ];
        return buyOrdersOrderedAll;
    };

    const sortedBuyOrders = getSortedBuyOrders();

    return (
        <div className={classes.container}>
            <UpdatingContentProgress loading={loading} bottom />

            <Table
                data={
                    sortedBuyOrders?.map((buyOrder: any) => {
                        return {
                            buyOrderId: buyOrder.buyOrderId,
                            shortBuyOrderId: buyOrder.shortBuyOrderId,
                            buyOrderStatus: buyOrder.buyOrderStatus,
                            modifiedAt: moment(buyOrder.modifiedAt).format('YYYY/MM/DD HH:mm'),
                            supplierBusinessName: buyOrder.supplierBusinessName,
                            managerUserName: buyOrder.managerUserName,
                            total: formatAsCurrencyNumber(buyOrder.total),
                            menu: buyOrder,
                        };
                    }) ?? []
                }
                columns={[
                    {
                        name: 'buyOrderId',
                        label: '',
                        options: {
                            display: 'excluded',
                            filter: false,
                        },
                    },
                    {
                        name: 'shortBuyOrderId',
                        label: translate('ID'),
                        options: {
                            filter: false,
                            customBodyRender: (value) => {
                                return <span>#{value}</span>;
                            },
                        },
                    },
                    {
                        name: 'buyOrderStatus',
                        label: translate('Status'),
                        options: {
                            filter: true,
                            filterList: [],
                            customFilterListOptions: {
                                render: (v) => `${translate('Status')}: ${v}`,
                            },
                            filterOptions: {
                                names: Object.values(BuyOrderStatuses).map((status: any) => translate(status.toLowerCase())),
                                logic: (status, filters) => {
                                    const index = filters.findIndex((filter) => filter === translate(status.toLowerCase()));
                                    return index === -1;
                                },
                            },
                            customBodyRender: (value) => {
                                const status = value.toLowerCase();
                                const translatedStatus = translate(status);
                                const statusClassName = `${classes.status} ${classes[status as keyof typeof classes]}`;

                                return <span className={statusClassName}>{translatedStatus}</span>;
                            },
                        },
                    },

                    {
                        name: 'modifiedAt',
                        label: translate('Date'),
                        options: {
                            filter: true,
                        },
                    },
                    {
                        name: 'supplierBusinessName',
                        label: translate('Supplier'),
                        options: {
                            filter: true,
                        },
                    },
                    {
                        name: 'managerUserName',
                        label: translate('User'),
                        options: {
                            filter: false,
                            customBodyRender: (value, tableMeta) => {
                                return <span>{value || translate('No Provider')}</span>;
                            },
                        },
                    },
                    {
                        name: 'total',
                        label: translate('Total Price'),
                        options: {
                            filter: false,
                        },
                    },
                    {
                        name: 'menu',
                        label: ' ',
                        options: {
                            filter: false,
                            customBodyRender: (value, tableMeta) => {
                                return <BuyOrdersTableContextualMenu buyOrder={value} onEdit={onEdit} onRemove={removeBuyOrder} disabled={loading} onSuccess={load} suppliers={suppliers} />;
                            },
                        },
                    },
                ]}
                options={{
                    responsive: 'standard',
                    tableBodyMaxHeight: '500px',
                    selectableRows: 'none',
                    rowsPerPage: 100,
                    filter: true,
                    print: false,
                    viewColumns: false,
                    customToolbar: () => (
                        <>
                            <RefreshToolbarButton onClick={load} />
                            {!inventoryLinkedToRestaurantId && (
                                <SecuredContent rolePermission={RolePermissions.CREATE_BUY_ORDERS}>
                                    <CreateBuyOrderToolbarContextMenu />
                                </SecuredContent>
                            )}
                        </>
                    ),
                }}
            />
        </div>
    );
}

const useStyles = makeStyles((theme) => ({
    container: {
        position: 'relative',
    },
    title: {
        fontFamily: theme.typography.medium,
        fontSize: 20,
        margin: 0,
        marginTop: 50,
        position: 'relative',
        zIndex: 5,
        width: 'fit-content',
    },
    text: {
        fontFamily: theme.typography.regular,
        fontSize: 13,
    },
    status: {
        fontFamily: theme.typography.regular,
        fontSize: 13,
        padding: '7px 17px',
        borderRadius: 5,
    },
    pending: {
        backgroundColor: '#F6E3A7',
        color: '#5E4400',
    },
    completed: {
        backgroundColor: '#CBF7E9',
        color: '#024B49',
    },
    rejected: {
        backgroundColor: '#EB5757',
        color: '#5E0000',
    },
    sent: {
        backgroundColor: '#C9E2FF',
        color: '#124289',
    },
    cancelled: {
        backgroundColor: '#EB5757',
        color: '#5E0000',
    },
    contextualMenu: {
        '& .MuiPaper-root': {
            backgroundColor: '#FFFFFF',
            boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.10  )',
            borderRadius: 5,
            padding: 0,
        },
        '& .MuiMenuItem-root': {
            fontFamily: theme.typography.regular,
            fontSize: 15,
            color: '#6C7076',
            padding: '10px 20px',
            '&:hover': {
                backgroundColor: '#F2F2F2',
            },
        },
    },
}));
