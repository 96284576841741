import { letseatmanagerApiMethod } from 'src/api/letseatmanagerApi';
import type { ApiSauceResponse } from 'src/api/types/ApiSauceResponse';
import type { CustomProductCancellationReason, RestaurantId } from 'src/types/Id';

export async function removeProductCancellationReasonApi(request: RemoveProductCancellationReasonApiRequest): ApiSauceResponse<void> {
    return letseatmanagerApiMethod('restaurant/removeProductCancellationReasonApi', request);
}

export type RemoveProductCancellationReasonApiRequest = {
    restaurantId: RestaurantId;
    reason: CustomProductCancellationReason;
};
