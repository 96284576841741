import { letseatmanagerApiMethod } from 'src/api/letseatmanagerApi';
import type { ApiSauceResponse } from 'src/api/types/ApiSauceResponse';
import type { App } from 'src/constants/App';
import type { MenuCategoryId, MenuId } from 'src/types/Id';

export async function cloneMenuApi(request: CloneMenuApiRequest): ApiSauceResponse<void> {
    return letseatmanagerApiMethod('menu/cloneMenuApi', request);
}

export type CloneMenuApiRequest = {
    menuId: MenuId;
    menuCategoryIds: Array<MenuCategoryId>;
    name: string;
    hidden?: boolean;
    hours?: string;
    companyName?: string;
    channels?: Array<App>;
};
