import MUIDataTable from 'mui-datatables';
import * as React from 'react';
import { getSubscriptionLogEventsApi } from 'src/api/letseatadmin/logEvent/getSubscriptionLogEventsApi';
import { translate } from 'src/i18n/translate';
import type { SubscriptionId } from 'src/types/Id';
import { formatDateTimeString } from 'src/utils/date/formatDateTimeString';
import { useLoadApi } from 'src/utils/react/useLoadApi';
import { toShortId } from 'src/utils/uuid/toShortId';

export function SubscriptionLogEvents({ subscriptionId }: Props): React.ReactElement {
    const [loadingEventLogs, eventLogs, refreshEventLogs] = useLoadApi(getSubscriptionLogEventsApi, { subscriptionId }, { initialValue: [] });

    const columns = [
        {
            name: 'logEventId',
            label: translate('Event Id'),
            options: {
                filter: false,
            },
        },
        {
            name: 'logEventLevel',
            label: translate('Log Level'),
            options: {
                filter: false,
            },
        },
        {
            name: 'logEventType',
            label: translate('Log Type'),
            options: {
                filter: false,
            },
        },
        {
            name: 'details',
            label: translate('details'),
            options: {
                filter: false,
            },
        },
        {
            name: 'message',
            label: translate('Message'),
            options: {
                filter: false,
            },
        },
        {
            name: 'createdAt',
            label: translate('Created At'),
            options: {
                filter: false,
            },
        },
    ];

    return (
        <div style={{ position: 'relative' }}>
            <MUIDataTable
                // @ts-ignore
                loading={loadingEventLogs}
                options={{ selectableRows: 'none' }}
                data={eventLogs?.map((logEvent: any) => ({
                    logEventId: toShortId(logEvent.logEventId),
                    logEventLevel: logEvent.logEventLevel,
                    logEventType: logEvent.logEventType,
                    details: logEvent.details,
                    message: logEvent.message,
                    createdAt: formatDateTimeString(logEvent.createdAt),
                }))}
                columns={columns}
            />
        </div>
    );
}

type Props = {
    subscriptionId: SubscriptionId;
};
