import { makeStyles } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Grid from '@material-ui/core/Grid';
import LinearProgress from '@material-ui/core/LinearProgress';
import gql from 'graphql-tag';
import moment from 'moment';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { cancelDelivery } from 'src/api/appsync/mutations';
import { getTotalCancelledDeliveriesForDriverApi } from 'src/api/letseatadmin/delivery/getTotalCancelledDeliveriesForDriverApi';
import { banDriverUntilApi } from 'src/api/letseatadmin/driver/banDriverUntilApi';
import { app2 } from 'src/app2';
import { Field, Form } from 'src/components/deprecated/react-final-form/Form';
import TextField from 'src/components/deprecated/react-final-form/TextField';
import { appsyncClient } from 'src/config/appsyncClient';
import { translate } from 'src/i18n/translate';
import type { DeliveryVm } from 'src/scenes/letseatadmin/deliveryManager/Delivery';
import { appLayout } from 'src/theme/AppLayout';
import { alertKnownErrorOrSomethingWentWrong } from 'src/utils/alert/alertKnownErrorOrSomethingWentWrong';
import { alertKnownErrorOrSomethingWentWrongError } from 'src/utils/alert/alertKnownErrorOrSomethingWentWrongError';
import { useAction } from 'src/utils/react/useAction';
import { useSelector } from 'src/utils/react/useSelector';
import { requireValue } from 'src/utils/require/requireValue';
import { combine, greaterThanOrEqualToZero, required } from 'src/utils/Validator';

export function CancelDeliveryDialog(): React.ReactElement {
    const classes = useStyles();

    const [loading, setLoading] = useState(false);
    const open = useSelector((state) => state.app2.cancelDeliveryDialog.open);
    const deliveryId = useSelector((state) => state.app2.cancelDeliveryDialog.deliveryId);
    const driverId = useSelector((state) => state.app2.cancelDeliveryDialog.driverId);
    const onSuccess = useSelector((state) => state.app2.cancelDeliveryDialog.onSuccess);
    const [cancelledDeliveries, setCancelledDeliveries] = useState(0);
    const close = useAction(app2.actions.closeCancelDeliveryDialog);

    useEffect(() => {
        if (!open || !driverId) return;
        // Create an scoped async function in the hook
        async function getCancelledDeliveries() {
            const response = await getTotalCancelledDeliveriesForDriverApi({ driverId });
            if (!response.ok) {
                alertKnownErrorOrSomethingWentWrong(response);
                return;
            }
            setCancelledDeliveries(response.data);
        }
        getCancelledDeliveries();
    }, [open, driverId]);

    const getBannedUntilDate = (bannedUntil: number) => {
        return moment().add(bannedUntil, 'minutes').toDate();
    };

    return (
        <Dialog fullWidth aria-labelledby='CancelDeliveryDialog-title' scroll='paper' open={open} onClose={close}>
            <Form
                initialValues={{
                    deliveryEarnings: '0',
                    bannedUntil: '0',
                    cancelledDeliverier: cancelledDeliveries,
                }}
                onSubmit={async (form: any) => {
                    if (loading) return;
                    setLoading(true);
                    try {
                        const response = await appsyncClient.mutate<any>({
                            mutation: gql(cancelDelivery),
                            variables: {
                                deliveryId: deliveryId,
                                deliveryEarnings: form.deliveryEarnings,
                            },
                        });
                        var delivery: DeliveryVm = requireValue(response.data.cancelDelivery);
                        if (form.bannedUntil > 0) {
                            const repsponse = await banDriverUntilApi({
                                driverId: driverId,
                                bannedUntil: getBannedUntilDate(form.bannedUntil),
                            });
                            if (!repsponse.ok) {
                                alertKnownErrorOrSomethingWentWrong(response);
                                return;
                            }
                        }
                    } catch (e: any) {
                        setLoading(false);
                        alertKnownErrorOrSomethingWentWrongError(e);
                        return;
                    }
                    setLoading(false);
                    close();
                    onSuccess && onSuccess(delivery);
                }}
                render={({ handleSubmit, values }: { handleSubmit: any; values: any }) => {
                    return (
                        <form onSubmit={handleSubmit} noValidate style={appLayout.dialog.form}>
                            <DialogTitle id='CancelDeliveryDialog-title'>{translate('Cancel Delivery').toUpperCase()}</DialogTitle>
                            <DialogContent>
                                <Grid container spacing={3}>
                                    <Grid item xs={12}>
                                        <Field
                                            name='deliveryEarnings'
                                            label={translate('Driver Earnings')}
                                            component={TextField}
                                            disabled={loading}
                                            required
                                            validate={combine(required, greaterThanOrEqualToZero)}
                                        />
                                        <Field name='cancelledDeliverier' label={translate('Cancelled Deliveries')} component={TextField} disabled={true} />
                                        <Field name='bannedUntil' label={translate('Ban For X Minutes')} component={TextField} type='number' validate={combine(greaterThanOrEqualToZero)} />
                                    </Grid>
                                </Grid>
                            </DialogContent>
                            <DialogActions>
                                <Button onClick={close} disabled={loading}>
                                    {translate('Cancel').toUpperCase()}
                                </Button>
                                <Button color='primary' type='submit' disabled={loading}>
                                    {loading ? translate('Cancelling Delivery').toUpperCase() : translate('Cancel Delivery').toUpperCase()}
                                </Button>
                            </DialogActions>
                            {loading && <LinearProgress className={classes.linearProgress} />}
                        </form>
                    );
                }}
            />
        </Dialog>
    );
}

const useStyles = makeStyles((theme) => ({
    linearProgress: {
        position: 'absolute',
        width: '100%',
        bottom: 0,
    },
}));
