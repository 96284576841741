import type { CashRegisterDepositReason } from 'src/constants/CashRegisterDepositReason';
import type { CashRegisterCustomReason, CashRegisterId, CashRegisterTransactionId, ManagerUserId } from 'src/types/Id';

export const KEY_NAME = 'posDepositsOffline';

export function getPosDepositsOfflineInLocalStorage(): Array<PosDepositOffline> {
    const item = window.localStorage.getItem('posDepositsOffline');
    return item ? JSON.parse(item) : [];
}

export type PosDepositOffline = {
    temporalCashRegisterTransactionId: CashRegisterTransactionId;
    cashRegisterId?: CashRegisterId;
    managerUserId?: ManagerUserId;
    amount: string;
    reason: CashRegisterDepositReason | CashRegisterCustomReason;
    comment?: string;
    createdAt?: Date;
};
