import { letseatmanagerApiMethod } from 'src/api/letseatmanagerApi';
import type { ApiSauceResponse } from 'src/api/types/ApiSauceResponse';
import type { SubscriptionId } from 'src/types/Id';
import type { SubscriptionVm } from 'src/types/SubscriptionVm';

export async function getSubscriptionApi(request: GetSubscriptionApiRequest): ApiSauceResponse<SubscriptionVm> {
    return letseatmanagerApiMethod('subscription/getSubscriptionApi', request);
}

export type GetSubscriptionApiRequest = {
    subscriptionId: SubscriptionId;
};
