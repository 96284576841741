import { letseatmanagerApiMethod } from 'src/api/letseatmanagerApi';
import type { ApiSauceResponse } from 'src/api/types/ApiSauceResponse';
import type { App } from 'src/constants/App';
import type { BannerId, RestaurantId } from 'src/types/Id';

export async function findBannersApi(request: FindBannersApiRequest): ApiSauceResponse<Array<BannerVm>> {
    return letseatmanagerApiMethod('banner/findBannersApi', request);
}

type FindBannersApiRequest = {
    restaurantIds: Array<RestaurantId>;
};

export type BannerVm = {
    bannerId: BannerId;
    enabled: boolean;
    hours?: string;
    imageUrl: string;
    urlSubdomain?: string;
    promoCode?: string;
    pinned?: boolean;
    channels?: Array<App>;
};
