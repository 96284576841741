import { letseatmanagerApiMethod } from 'src/api/letseatmanagerApi';
import type { ApiSauceResponse } from 'src/api/types/ApiSauceResponse';
import type { MenuId } from 'src/types/Id';
import type { MenuVm } from 'src/types/MenuVm';

export async function getMenuApi(request: GetMenuApiRequest): ApiSauceResponse<MenuVm> {
    return letseatmanagerApiMethod('menu/getMenuApi', request);
}

export type GetMenuApiRequest = {
    menuId: MenuId;
};
