import { makeStyles } from '@material-ui/core';
import moment from 'moment';
import * as React from 'react';
import { Chip } from 'src/components/Chip';
import { SimpleTable } from 'src/components/SimpleTable';
import { translate } from 'src/i18n/translate';
import { useFormatAsRestaurantCurrencyNumber } from 'src/services/restaurant/useFormatAsRestaurantCurrencyNumber';
import { OrderInvoiceSummary, OrdersGlobalInvoiceVm } from 'src/types/OrdersGlobalInvoiceVm';
import { classNames } from 'src/utils/react/classNames';
import { useSelector } from 'src/utils/react/useSelector';

export function OrdersToInvoiceTable({ ordersToInvoiceSummary, classes: classesProp }: Props): React.ReactElement {
    const classes = useStyles();
    const formatAsCurrencyNumber = useFormatAsRestaurantCurrencyNumber();

    const restaurant = useSelector((state) => state.app.restaurant);

    const columns = [
        {
            id: 'shortOrderId',
            content: translate('Order Id'),
        },
        {
            id: 'completedAt',
            content: translate('Date'),
        },
        {
            id: 'hour',
            content: translate('Hour'),
        },
        {
            id: 'total',
            content: translate('Amount'),
        },
        {
            id: 'paymentMethod',
            content: translate('Payment method'),
        },
        {
            id: 'status',
            content: translate('Status'),
        },
    ];

    if (restaurant?.consecutiveOrderIdEnabled) {
        columns.unshift({
            id: 'consecutiveOrderId',
            content: translate('Folio'),
        });
    }

    const rows = ordersToInvoiceSummary?.ordersToInvoice
        ?.map((order: OrderInvoiceSummary) => ({
            ...order,
            shortOrderId: `#${order.shortOrderId}`,
            hour: moment(order.completedAt).format('LT'),
            completedAt: moment(order.completedAt).format('L'),
            total: formatAsCurrencyNumber(order.total ?? 0),
            paymentMethod: translate(order.paymentMethod),
            status: <Chip classes={{ chip: classNames(classes.disabledChip, classes.chip) }}>{translate('Uninvoiced')}</Chip>,
        }))
        .concat(
            ordersToInvoiceSummary?.invoicedOrders?.map((invoicedOrder: OrderInvoiceSummary) => ({
                ...invoicedOrder,
                shortOrderId: `#${invoicedOrder.shortOrderId}`,
                hour: moment(invoicedOrder.completedAt).format('LT'),
                completedAt: moment(invoicedOrder.completedAt).format('L'),
                total: formatAsCurrencyNumber(invoicedOrder.total ?? 0),
                paymentMethod: translate(invoicedOrder.paymentMethod),
                status: <Chip classes={{ chip: classes.chip }}>{translate('Invoiced')}</Chip>,
            })),
        );

    return (
        <div className={classes.tableContainer}>
            <SimpleTable classes={{ table: classNames(classes.table, classesProp?.table), header: classesProp?.header }} columns={columns} rows={rows ?? []} />
        </div>
    );
}

const useStyles = makeStyles((theme) => ({
    tableContainer: {
        width: '100%',
        overflowX: 'scroll',
    },
    table: {
        margin: '24px 0',
        paddingBottom: 24,
        maxHeight: '35vh',
        overflowY: 'scroll',
        fontSize: 12,
        color: '#232933',
        minWidth: 600,
    },
    chip: {
        backgroundColor: '#06B7A2',
        color: '#024B49',
        fontSize: 12,
        padding: '8px 12px',
        height: 22,
        borderRadius: 8,
    },
    disabledChip: {
        color: '#313333',
        backgroundColor: '#D9D9D9',
    },
}));

type Props = {
    ordersToInvoiceSummary: OrdersGlobalInvoiceVm;
    classes?: {
        table: string;
        header?: string;
    };
};
