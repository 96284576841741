import { useState } from 'react';
import * as React from 'react';
import { unlinkCsdCertificateApi } from 'src/api/letseatmanager/restaurant/unlinkCsdCertificateApi';
import { RemoveDialog } from 'src/components/dialog/RemoveDialog';
import { translate } from 'src/i18n/translate';
import { alertKnownErrorOrSomethingWentWrong } from 'src/utils/alert/alertKnownErrorOrSomethingWentWrong';
import { useSelector } from 'src/utils/react/useSelector';

export function UnlinkCsdCertificateDialog({ open, onClose, onSuccess }: Props): React.ReactElement {
    const [loading, setLoading] = useState(false);

    const restaurantId = useSelector((state) => state.app.restaurantId);

    const handleSubmit = async () => {
        setLoading(true);
        const response = await unlinkCsdCertificateApi({
            restaurantId,
        });
        setLoading(false);
        if (!response.ok) {
            alertKnownErrorOrSomethingWentWrong(response);
            return;
        }
        onSuccess();
    };

    return (
        <RemoveDialog
            open={open}
            title={translate('Unlink Digital Seal')}
            subtitle={translate('Are you sure you want to unlink the digital seal?')}
            onSubmit={handleSubmit}
            onClose={onClose}
            disabled={loading}
        />
    );
}

type Props = {
    open: boolean;
    onClose: any;
    onSuccess: any;
};
