import * as React from 'react';
import { SubscribedPage } from 'src/components/page/SubscribedPage';
import { translate } from 'src/i18n/translate';
import { CustomersInfo } from 'src/scenes/letseatmanager/orders/CustomersInfo';

export function CustomersPage(): React.ReactElement {
    return (
        <SubscribedPage title={translate('Customers')}>
            <CustomersInfo />
        </SubscribedPage>
    );
}
