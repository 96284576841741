import * as React from 'react';

export function LightingIcon({ color }: Props): React.ReactElement {
    return (
        <svg width='18' height='20' viewBox='0 0 18 20' fill='none' xmlns='http://www.w3.org/2000/svg'>
            <path
                fillRule='evenodd'
                clipRule='evenodd'
                d='M11.0615 0.0903381C11.2009 0.167951 11.3104 0.290027 11.3723 0.437092C11.4343 0.584156 11.4452 0.747737 11.4034 0.901739L9.5063 7.85674H16.428C16.5672 7.85675 16.7033 7.89743 16.8197 7.97378C16.9361 8.05014 17.0276 8.15883 17.0831 8.28651C17.1385 8.41419 17.1555 8.55529 17.1318 8.69246C17.1081 8.82963 17.0449 8.95689 16.9499 9.05861L6.9502 19.7725C6.84128 19.8895 6.6963 19.9665 6.53843 19.9913C6.38056 20.0162 6.21893 19.9873 6.07938 19.9094C5.93984 19.8315 5.83045 19.7091 5.76871 19.5617C5.70697 19.4143 5.69644 19.2505 5.73881 19.0964L7.63589 12.1423H0.714217C0.57502 12.1423 0.43886 12.1016 0.322472 12.0253C0.206085 11.9489 0.114544 11.8402 0.0591021 11.7125C0.00366003 11.5849 -0.013267 11.4438 0.0104021 11.3066C0.0340712 11.1694 0.0973047 11.0422 0.19233 10.9404L10.192 0.226524C10.3009 0.11 10.4457 0.0332854 10.6033 0.0086042C10.7609 -0.0160769 10.9222 0.0126961 11.0615 0.0903381Z'
                fill={color ?? '#63D6B3'}
            />
        </svg>
    );
}

type Props = {
    color?: string;
};
