import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import * as React from 'react';
import { useFormContext } from 'react-hook-form';
import { Spacing } from 'src/components/spacing/Spacing';
import { translate } from 'src/i18n/translate';

export function FormFixErrorHint(): React.ReactElement | null {
    const { errors } = useFormContext();
    const classes = useStyles();
    return Object.keys(errors).length ? (
        <Grid container justify='space-around'>
            <Grid item>
                <Spacing units={3} />
                <Typography display='block' color='error' className={classes.text}>
                    {translate('Fix form errors and try again.')}
                </Typography>
            </Grid>
        </Grid>
    ) : null;
}

const useStyles = makeStyles((theme) => ({
    text: {
        fontFamily: theme.typography.medium,
    },
}));
