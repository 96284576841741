import { makeStyles } from '@material-ui/core/styles';
import * as React from 'react';
import { memo } from 'react';
import { usePosUserLockScreenIsWrongPassword } from 'src/services/pos/PosUserLockScreen/usePosUserLockScreenIsWrongPassword';
import { classNames } from 'src/utils/react/classNames';

export const PosUserLockScreenPinCodeDisplay: React.ComponentType<Props> = memo<Props>(function PosUserLockScreenPinCodeDisplay({ password }: Props): React.ReactElement {
    const classes = useStyles();
    const characters = ['1', '2', '3', '4', '5', '6'];

    const isWrongPassword = usePosUserLockScreenIsWrongPassword();

    return (
        <div className={classNames(classes.characters, isWrongPassword ? classes.isAnimated : '')}>
            {characters.map((character, index) => {
                return <div key={character} className={classNames(isWrongPassword ? classes.pinCodeError : '', password?.length > index ? classes.filled : classes.empty)}></div>;
            })}
        </div>
    );
});

const useStyles = makeStyles((theme) => ({
    filled: {
        width: 12,
        height: 12,
        border: `1px solid ${theme.palette.surface.brand}`,
        backgroundColor: theme.palette.surface.brand,
        borderRadius: '50%',
    },
    empty: {
        width: 12,
        height: 12,
        border: `1px solid ${theme.palette.surface.brand}`,
        backgroundColor: 'transparent',
        borderRadius: '50%',
    },
    pinCodeError: {
        border: `1px solid #EA5146`,
    },
    characters: {
        display: 'flex',
        gap: 12,
        marginTop: 24,
        marginBottom: 50,
    },
    isAnimated: {
        animation: '$vibration 400ms ease-out',
    },
    '@keyframes vibration': {
        '0%': {
            transform: 'translateX(0px)',
        },
        '25%': {
            transform: 'translateX(15px)',
        },
        '50%': {
            transform: 'translateX(0px)',
        },
        '75%': {
            transform: 'translateX(15px)',
        },
        '100%': {
            transform: 'translateX(0px)',
        },
    },
}));

type Props = {
    password: string;
};
