import { makeStyles } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import { useNotification } from '@pidedirecto/ui/hooks';
import * as React from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import { SecuredContent } from 'src/components/SecuredContent';
import { OrderStatuses } from 'src/constants/OrderStatus';
import { RolePermissions } from 'src/constants/RolePermission';
import { RoutePaths } from 'src/constants/RoutePath';
import { translate } from 'src/i18n/translate';
import type { OrderVm } from 'src/types/OrderVm';
import { useEditPendingOrder } from 'src/utils/react/useEditPendingOrder';
import { useSelector } from 'src/utils/react/useSelector';

export function OrderCardEditOrderButton({ order, disabled }: Props): React.ReactElement | null {
    const classes = useStyles();
    const location = useLocation();
    const editPendingOrder = useEditPendingOrder();
    const notification = useNotification();

    const brandOpened = useSelector((state) => state.app.brandOpened);

    const handleEditPendingOrder = () => {
        if (brandOpened) {
            notification({ message: translate('Select a restaurant to edit orders') });
            return;
        }
        editPendingOrder({ order });
    };

    if (order.orderStatus !== OrderStatuses.PENDING) return null;

    return (
        <SecuredContent rolePermission={RolePermissions.EDIT_ORDERS}>
            <Button disabled={disabled} component={ForwardRefNavLink} onClick={handleEditPendingOrder} to={{ pathname: RoutePaths.POS, search: location.search }} className={classes.buttonAccept}>
                {translate('Edit')}
            </Button>
        </SecuredContent>
    );
}

const ForwardRefNavLink = React.forwardRef<HTMLAnchorElement, React.ComponentProps<NavLink>>((props, ref) => <NavLink innerRef={ref} {...props} />);

const useStyles = makeStyles((theme) => ({
    buttonAccept: {
        position: 'relative',
        width: '100%',
        borderRadius: 6,
        fontFamily: theme.typography.regular,
        cursor: 'pointer',
        transition: 'background-color 0.2s linear',
        boxSizing: 'border-box',
        transformOrigin: 'center',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        gap: 10,
        minHeight: 42,
        justifyContent: 'center',
        textTransform: 'none',
        border: `1px solid ${theme.palette.border.brandContrast}`,
        backgroundColor: 'transparent',
        color: theme.palette.text.brand,
        padding: '9px 13px',
        '&:hover': {
            backgroundColor: theme.palette.surface.brand,
        },
        '&:focus': {
            outline: `2px solid ${theme.palette.surface.brandSecondary}`,
        },
    },
}));

type Props = {
    order: OrderVm;
    disabled: boolean;
};
