import { makeStyles } from '@material-ui/core';
import * as React from 'react';
import { useEffect } from 'react';
import { app2 } from 'src/app2';
import ScheduledTasksTable from 'src/scenes/letseatadmin/scheduledTasks/ScheduledTasksTable';
import type { RRuleString, ScheduledTaskId } from 'src/types/Id';
import { useAction } from 'src/utils/react/useAction';

export function ScheduledTasksPage(): React.ReactElement {
    const classes = useStyles();

    const setTitle = useAction(app2.actions.setTitle);

    useEffect(() => {
        setTitle('Scheduled Tasks');
    }, []);

    return (
        <div className={classes.container}>
            <ScheduledTasksTable />
        </div>
    );
}

const useStyles = makeStyles((theme) => ({
    container: {
        position: 'relative',
    },
}));

export type ScheduledTasksVm = {
    scheduledTasks: Array<ScheduledTaskVm>;
};

export type ScheduledTaskVm = {
    scheduledTaskId: ScheduledTaskId;
    name?: string;
    // actions: Actions,
    // targets: Array<Target>,
    schedule: RRuleString;
    nextScheduledRunAt?: Date;
    running?: boolean;
    paused?: boolean;
    createdAt: Date;
    startedAt?: Date;
    endedAt?: Date;
    modifiedAt: Date;
};
