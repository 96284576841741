import { makeStyles } from '@material-ui/core/styles';
import { Button, Dialog, DialogActions } from '@pidedirecto/ui';
import { useEffect, useState } from 'react';
import * as React from 'react';
import { useForm, useWatch } from 'react-hook-form';
import { createCustomerAndRestaurantCustomerAndBrandCustomerApi } from 'src/api/letseatmanager/customer/createCustomerAndRestaurantCustomerAndBrandCustomerApi';
import { createCustomerFromRestaurantCustomerApi } from 'src/api/letseatmanager/customer/createCustomerFromRestaurantCustomerApi';
import { findPosValidPromoCodeApi } from 'src/api/letseatmanager/promoCode/findPosValidPromoCodeApi';
import { getPosPromoCodeApi } from 'src/api/letseatmanager/promoCode/getPosPromoCodeApi';
import { Form } from 'src/components/form/Form';
import { FormEmailField } from 'src/components/form/FormEmailField';
import { FormHiddenField } from 'src/components/form/FormHiddenField';
import { FormPhoneNumberCustomerAutocomplete } from 'src/components/form/FormPhoneNumberCustomerAutocomplete';
import { FormPhoneNumberField } from 'src/components/form/FormPhoneNumberField';
import { FormTextField } from 'src/components/form/FormTextField';
import { RewardTypes } from 'src/constants/RewardType';
import { translate } from 'src/i18n/translate';
import { getPosInterfaceLargerEnabledRestaurantSettingInLocalStorage } from 'src/localStorage/getPosInterfaceLargerEnabledRestaurantSettingInLocalStorage';
import { useSetCustomerEmail } from 'src/modules/pos/customer/useSetCustomerEmail';
import { useSetCustomerFirstName } from 'src/modules/pos/customer/useSetCustomerFirstName';
import { useSetCustomerLastName } from 'src/modules/pos/customer/useSetCustomerLastName';
import { useSetCustomerMobileNumber } from 'src/modules/pos/customer/useSetCustomerMobileNumber';
import { useSetCustomerNote } from 'src/modules/pos/customer/useSetCustomerNote';
import { posReducer } from 'src/reducers/posReducer';
import { getCountryCallingCode } from 'src/scenes/letseatmanager/phoneNumber/getCountryCallingCode';
import { PromoCodeDescription } from 'src/scenes/letseatmanager/pos/posPayment/posApplyPromoCodeDialog/PromoCodeDescription';
import { PromoCodeList } from 'src/scenes/letseatmanager/pos/posPayment/posApplyPromoCodeDialog/PromoCodeList';
import type { RestaurantCustomerVm } from 'src/types/RestaurantCustomerVm';
import { alertKnownErrorOrSomethingWentWrong } from 'src/utils/alert/alertKnownErrorOrSomethingWentWrong';
import { alertKnownErrorOrSomethingWentWrongError } from 'src/utils/alert/alertKnownErrorOrSomethingWentWrongError';
import { useAction } from 'src/utils/react/useAction';
import { useSelector } from 'src/utils/react/useSelector';
import { useValidatePromoCodeApplicable } from 'src/utils/react/useValidatePromoCodeApplicable';

export function PosApplyPromoCodeDialog({ open, handleClose }: Props): React.ReactElement {
    const classes = useStyles();

    const posInterfaceLargerEnabled = getPosInterfaceLargerEnabledRestaurantSettingInLocalStorage();
    const setCustomerFirstName = useSetCustomerFirstName();
    const setCustomerLastName = useSetCustomerLastName();
    const setCustomerEmail = useSetCustomerEmail();
    const setCustomerNote = useSetCustomerNote();
    const setCustomerMobileNumber = useSetCustomerMobileNumber();

    const form = useForm();
    const { control, setValue, setError } = form;

    const [loading, setLoading] = useState(false);
    const [validatedMessage, setValidatedMessage] = useState<string | undefined>(undefined);
    const [restaurantCustomer, setRestaurantCustomer] = useState<RestaurantCustomerVm | undefined>(undefined);
    const [customersNotFound, setCustomersNotFound] = useState(false);
    const [customerWithMissingCustomerId, setCustomerWithMissingCustomerId] = useState<RestaurantCustomerVm | undefined>(undefined);

    const code = useWatch({ name: 'code', control });

    const restaurantId = useSelector((state) => state.app.restaurantId);
    const promoCodeSelected = useSelector((state) => state.pos.promoCode);
    const customerId = useSelector((state) => state.pos.customerId);
    const promoCodeId = useSelector((state) => state.pos.pendingOrder?.promoCodeId);
    const pendingOrder = useSelector((state) => state.pos.pendingOrder);
    const country = useSelector((state) => state.app.restaurant?.country);

    const clearPromoCodeSelected = useAction(posReducer.actions.clearPromoCodeSelected);
    const setPromoCodeSelected = useAction(posReducer.actions.setPromoCodeSelected);
    const addPromoCode = useAction(posReducer.actions.addPromoCode);
    const setCustomerId = useAction(posReducer.actions.setCustomerId);

    const { isValid } = useValidatePromoCodeApplicable();
    const promoCodeRequireCustomer = promoCodeSelected?.customerIds || promoCodeSelected?.rewardType === RewardTypes.CREDITS || promoCodeSelected?.requireAtLeastOneCreditCard;
    const requireCustomer = promoCodeSelected && promoCodeRequireCustomer && !customerId;
    const disabledApplyPromoCode = !isValid || !promoCodeSelected || loading;
    const shouldShowCustomerForm = (customersNotFound || !!customerWithMissingCustomerId) && requireCustomer;

    useEffect(() => {
        if (!!customerWithMissingCustomerId) {
            setValue('restaurantCustomerId', customerWithMissingCustomerId.restaurantCustomerId);
            setValue('firstName', customerWithMissingCustomerId.name);
            setValue('mobileNumber', customerWithMissingCustomerId.mobileNumber, { shouldValidate: true });
            setValue('email', customerWithMissingCustomerId.email, { shouldValidate: true });
        }
    }, [customerWithMissingCustomerId]);

    const onClose = () => {
        if (loading) return;

        if (!promoCodeId) {
            clearPromoCodeSelected();
        }
        if (!pendingOrder?.customerId) setCustomerId(undefined);
        setCustomerWithMissingCustomerId(undefined);
        setCustomersNotFound(false);
        handleClose();
    };

    const validatePromoCode = async () => {
        if (!code) return;

        setLoading(true);
        const response = await getPosPromoCodeApi({ code: code.toString().trim().toUpperCase(), restaurantId });
        setLoading(false);

        if (!response.ok) {
            alertKnownErrorOrSomethingWentWrongError(translate('Promo Code not available for this order'));
            return;
        }

        setPromoCodeSelected({ promoCode: response.data });
    };

    const applyPromoCode = () => {
        if (promoCodeSelected.rewardType === RewardTypes.CREDITS && !customerId) return;

        if (customerId && restaurantCustomer) {
            setCustomerFirstName(restaurantCustomer.firstName || restaurantCustomer.name);
            setCustomerLastName(restaurantCustomer.lastName);
            setCustomerEmail(restaurantCustomer.email);
            setCustomerNote(restaurantCustomer.note);
            setCustomerMobileNumber(restaurantCustomer.mobileNumber);
        }
        addPromoCode(promoCodeSelected);
        setCustomerWithMissingCustomerId(undefined);
        handleClose();
    };

    const handleSelectCustomerMobileNumber = async (restaurantCustomer: RestaurantCustomerVm) => {
        setValue('restaurantCustomerPhoneNumber', restaurantCustomer.mobileNumber, { shouldValidate: true });

        if (!restaurantCustomer?.customerId) {
            setError('restaurantCustomerPhoneNumber', { type: 'custom', message: translate('Customer information incomplete, complete it please') });
            setCustomerWithMissingCustomerId(restaurantCustomer);
            return;
        }

        if (restaurantCustomer.customerId) {
            setLoading(true);
            const validPromoCode = await findPosValidPromoCodeApi({
                code: promoCodeSelected.code?.toString().trim().toUpperCase(),
                restaurantId,
                customerId: restaurantCustomer.customerId as any,
            });
            setLoading(false);

            if (!validPromoCode.data) return setValidatedMessage(translate('Promo Code Selected: @description', { description: translate('Promo code not applicable for the customer') }));

            setCustomerId(restaurantCustomer.customerId);
            setRestaurantCustomer(restaurantCustomer);
        }
    };

    const handleCreateCustomer = async (form: any) => {
        setLoading(true);
        const response = await createCustomerFromRestaurantCustomerApi({
            restaurantCustomerId: form.restaurantCustomerId,
            restaurantId: restaurantId,
            mobileNumber: form.mobileNumber,
            email: form.email,
            name: form.firstName,
            lastName: form.lastName,
        });
        setLoading(false);

        if (!response.ok) {
            return setError('restaurantCustomerPhoneNumber', { type: 'custom', message: translate('User not found or promo code not available for this user') });
        }

        if (!response.data.customerId) return;

        setCustomerId(response.data.customerId);
        setRestaurantCustomer(restaurantCustomer);
    };

    const handleCreateNewCustomerAndRestaurantCustomer = async (form: any) => {
        setLoading(true);
        const response = await createCustomerAndRestaurantCustomerAndBrandCustomerApi({
            restaurantId,
            firstName: form.firstName,
            lastName: form.lastName,
            mobileNumber: form.mobileNumber,
            email: form.email,
        });
        setLoading(false);

        if (!response.ok) {
            setError('restaurantCustomerPhoneNumber', { type: 'custom', message: translate('User not found or promo code not available for this user') });

            alertKnownErrorOrSomethingWentWrong(response);
            return;
        }

        if (!response.data.customerId) return;

        const customer = response.data;

        setCustomerId(customer.customerId);

        setCustomerFirstName(form.firstName);
        setCustomerLastName(form.lastName);
        setCustomerEmail(form.email);
        setCustomerMobileNumber(form.mobileNumber);
    };

    const handleCustomersChange = (customers: any) => {
        if (customers.length > 1) {
            setCustomerWithMissingCustomerId(undefined);
            setCustomersNotFound(false);
            return;
        }

        if (validatedMessage) setValidatedMessage(undefined);

        setCustomersNotFound(!customers.length);
    };

    return (
        <Dialog title={translate('Apply Promotion')} open={open} onClose={onClose} classes={{ dialog: classes.dialog }}>
            <Form form={form} onSubmit={!!customerWithMissingCustomerId ? handleCreateCustomer : handleCreateNewCustomerAndRestaurantCustomer}>
                <div className={classes.form}>
                    <FormTextField name='code' placeholder={translate('Enter Promo Code')} upperCase={true} />
                    <Button variant='text' onClick={validatePromoCode} disabled={loading}>
                        {translate('Validate')}
                    </Button>
                </div>
                {requireCustomer && (
                    <FormPhoneNumberCustomerAutocomplete
                        required={requireCustomer}
                        onCustomersChange={handleCustomersChange}
                        onSelect={handleSelectCustomerMobileNumber}
                        name='restaurantCustomerPhoneNumber'
                        label={translate('Phone Number')}
                    />
                )}
                {shouldShowCustomerForm && (
                    <div>
                        <h3 className={classes.subTitle}>{translate('Create new customer')}</h3>
                        <div className={classes.newCustomerForm}>
                            <FormHiddenField name='restaurantCustomerId' />
                            <FormTextField required name='firstName' label={translate('First Name')} />
                            <FormTextField required name='lastName' label={translate('Last Name')} />
                            <FormPhoneNumberField required={customersNotFound && requireCustomer} name='mobileNumber' label={translate('Phone Number')} defaultValue={getCountryCallingCode(country)} />
                            <FormEmailField required name='email' label={translate('Email')} />
                        </div>
                        <Button type={'submit'} classes={{ button: classes.basicButton }} disabled={loading}>
                            {translate('Create')}
                        </Button>
                    </div>
                )}
            </Form>
            <PromoCodeList />
            <PromoCodeDescription validatedMessage={validatedMessage} />
            <DialogActions className={classes.dialogActions}>
                <Button size={posInterfaceLargerEnabled ? 'large' : undefined} onClick={onClose} variant='secondary'>
                    {translate('Cancel')}
                </Button>
                <Button size={posInterfaceLargerEnabled ? 'large' : undefined} onClick={applyPromoCode} disabled={disabledApplyPromoCode}>
                    {translate('Apply Promotion')}
                </Button>
            </DialogActions>
        </Dialog>
    );
}

const useStyles = makeStyles((theme) => ({
    dialog: {
        [theme.breakpoints.up('sm')]: {
            width: 700,
            maxWidth: 700,
        },
    },
    contentDialog: {
        '&::-webkit-scrollbar': {
            display: 'none',
        },
        margin: 0,
        padding: 0,
        display: 'flex',
        flexDirection: 'column',
        gap: 20,
    },
    subTitle: {
        fontFamily: theme.typography.regular,
        color: theme.palette.text.primary,
        fontSize: 13,
    },
    form: {
        marginBottom: 15,
        display: 'grid',
        gridTemplateColumns: '70% 30%',
        gap: 5,
        [theme.breakpoints.up('sm')]: {
            gridTemplateColumns: '80% 20%',
            gap: 10,
        },
    },
    newCustomerForm: {
        margin: '15px 0',
        gridTemplateColumns: 'repeat(1, 1fr)',
        [theme.breakpoints.up('sm')]: {
            gridTemplateColumns: 'repeat(2, 2fr)',
        },
        display: 'grid',
        gap: 15,
    },
    dialogActions: {
        gap: 10,
        flexWrap: 'wrap',
    },
    basicButton: {
        width: '100%',
        [theme.breakpoints.up('sm')]: {
            width: '134px',
        },
    },
    validateButton: {
        border: 'none',
        color: theme.palette.text.secondary,
        height: 34,
        width: 34,
        [theme.breakpoints.up('sm')]: {
            width: 100,
        },
    },
    button: {
        width: '45%',
        [theme.breakpoints.down('sm')]: {
            width: '100%',
        },
    },
}));

type Props = {
    open: boolean;
    handleClose: any;
};
