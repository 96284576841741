import { letseatmanagerApiMethod } from 'src/api/letseatmanagerApi';
import type { ApiSauceResponse } from 'src/api/types/ApiSauceResponse';
import type { BrandId, RestaurantId } from 'src/types/Id';
import type { UserRoleVm } from 'src/types/UserRoleVm';

export async function findUserRolesApi(request: FindRestaurantUserRolesApiRequest): ApiSauceResponse<Array<UserRoleVm>> {
    return letseatmanagerApiMethod('userRole/findUserRolesApi', request);
}

type FindRestaurantUserRolesApiRequest = {
    restaurantIds: Array<RestaurantId>;
    brandId?: BrandId;
};
