import { makeStyles } from '@material-ui/core';
import { Button } from '@pidedirecto/ui';
import { EditIcon, TrashCanIcon } from '@pidedirecto/ui/icons';
import { MUIDataTableColumn } from 'mui-datatables';
import { useEffect, useState } from 'react';
import * as React from 'react';
import { findSuppliersByRestaurantIdApi } from 'src/api/letseatmanager/supplier/findSuppliersByRestaurantIdApi';
import { removeSupplierApi } from 'src/api/letseatmanager/supplier/removeSupplierApi';
import { CreateToolbarButton } from 'src/components/mui-datatables/CreateToolbarButton';
import { RefreshToolbarButton } from 'src/components/mui-datatables/RefreshToolbarButton';
import { Table } from 'src/components/Table';
import { UpdatingContentProgress } from 'src/components/UpdatingContentProgress';
import { translate } from 'src/i18n/translate';
import { ChangeSupplierDialog } from 'src/scenes/letseatmanager/inventory/suppliers/ChangeSupplierDialog';
import { CreateSupplierDialog } from 'src/scenes/letseatmanager/inventory/suppliers/CreateSupplierDialog';
import { SupplierId } from 'src/types/Id';
import { SupplierVm } from 'src/types/SupplierVm';
import { alertKnownErrorOrSomethingWentWrong } from 'src/utils/alert/alertKnownErrorOrSomethingWentWrong';
import { useSelector } from 'src/utils/react/useSelector';

export function SuppliersTable(): React.ReactElement {
    const classes = useStyles();

    const [suppliers, setSuppliers] = useState<Array<SupplierVm>>([]);
    const [loading, setLoading] = useState(false);
    const [createSupplierDialogState, setCreateSupplierDialogState] = useState({ open: false });
    const [changeSupplierDialogState, setChangeSupplierDialogState] = useState<{ open: boolean; supplierId: SupplierId | undefined }>({ open: false, supplierId: undefined });

    const restaurantId = useSelector((state) => state.app.restaurantId);
    const inventoryLinkedToRestaurantId = useSelector((state) => state.app.restaurant?.inventoryLinkedToRestaurantId);

    useEffect(() => {
        load();
    }, []);

    const load = async () => {
        setLoading(true);
        const response = await findSuppliersByRestaurantIdApi({ restaurantId });
        setLoading(false);
        if (!response.ok) {
            alertKnownErrorOrSomethingWentWrong(response);
            return;
        }
        setSuppliers(response.data.suppliers);
    };

    const removeSupplier = async (supplierId: any) => {
        const supplier = suppliers.find((supplier) => supplier.supplierId === supplierId);
        const remove = window.confirm(translate('Are you sure you want to remove the supplier @businessName?', { businessName: supplier?.businessName }));

        if (!remove) return;

        setLoading(true);
        const response = await removeSupplierApi({
            supplierId,
        });
        setLoading(false);
        if (!response.ok) {
            alertKnownErrorOrSomethingWentWrong(response);
            return;
        }
        load();
    };

    const columns: Array<MUIDataTableColumn> = [
        {
            name: 'supplierId',
            label: '',
            options: {
                display: 'excluded',
                filter: false,
            },
        },
        {
            name: 'businessName',
            label: translate('Business Name'),
            options: {
                filter: false,
            },
        },
        {
            name: 'rfc',
            label: translate('RFC'),
            options: {
                filter: false,
            },
        },
        {
            name: 'phoneNumber',
            label: translate('Phone Number'),
            options: {
                filter: false,
            },
        },
    ];

    if (!inventoryLinkedToRestaurantId) {
        columns.push(
            {
                name: 'edit',
                label: ' ',
                options: {
                    filter: false,
                    customBodyRender: (supplierId: SupplierId) => (
                        <Button variant='icon' onClick={() => setChangeSupplierDialogState({ open: true, supplierId: supplierId as SupplierId })}>
                            <EditIcon size={20} />
                        </Button>
                    ),
                },
            },
            {
                name: 'delete',
                label: ' ',
                options: {
                    filter: false,
                    customBodyRender: (supplierId: SupplierId) => (
                        <Button variant='icon' onClick={() => removeSupplier(supplierId as SupplierId)}>
                            <TrashCanIcon size={20} />
                        </Button>
                    ),
                },
            },
        );
    }

    return (
        <div className={classes.container}>
            <UpdatingContentProgress loading={loading} bottom />
            <CreateSupplierDialog open={createSupplierDialogState.open} handleClose={() => setCreateSupplierDialogState({ open: false })} onSupplierCreated={load} />
            <ChangeSupplierDialog
                open={changeSupplierDialogState.open}
                supplierId={changeSupplierDialogState.supplierId}
                handleClose={() => setChangeSupplierDialogState({ open: false, supplierId: undefined })}
                onSupplierChanged={load}
            />
            <Table
                data={suppliers.map((supplier) => {
                    return {
                        supplierId: supplier.supplierId,
                        businessName: supplier.businessName,
                        rfc: supplier.rfc,
                        phoneNumber: supplier.phoneNumber,
                        edit: supplier.supplierId,
                        delete: supplier.supplierId,
                    };
                })}
                columns={columns}
                options={{
                    responsive: 'standard',
                    tableBodyMaxHeight: '500px',
                    selectableRows: 'none',
                    filterType: 'checkbox',
                    rowsPerPage: 100,
                    filter: false,
                    print: false,
                    viewColumns: false,
                    customToolbar: () => (
                        <>
                            {!inventoryLinkedToRestaurantId && <CreateToolbarButton onClick={() => setCreateSupplierDialogState({ open: true })} titleAccess='Create supplier' />}
                            <RefreshToolbarButton onClick={load} />
                        </>
                    ),
                }}
            />
        </div>
    );
}

const useStyles = makeStyles((theme) => ({
    container: {
        position: 'relative',
    },
    title: {
        fontFamily: theme.typography.medium,
        fontSize: 20,
        margin: 0,
        marginBottom: -50,
        marginTop: 50,
        position: 'relative',
        zIndex: 5,
        width: 'fit-content',
    },
}));
