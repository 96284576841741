import { letseatmanagerApiMethod } from 'src/api/letseatmanagerApi';
import type { ApiSauceResponse } from 'src/api/types/ApiSauceResponse';
import type { RegimeFiscal } from 'src/constants/RegimeFiscal';
import type { SubscriptionId } from 'src/types/Id';
import type { SubscriptionVm } from 'src/types/SubscriptionVm';

export async function changeSubscriptionApi(request: ChangeSubscriptionApiRequest): ApiSauceResponse<SubscriptionVm> {
    return letseatmanagerApiMethod('subscription/changeSubscriptionApi', request);
}

export type ChangeSubscriptionApiRequest = {
    subscriptionId: SubscriptionId;
    businessName?: string;
    rfc?: string;
    emailInvoices?: string;
    zipCode?: string;
    regimeFiscal?: RegimeFiscal;
};
