import { FormSelect } from '@pidedirecto/ui/form';
import * as React from 'react';
import { PaymentMethods, type PaymentMethod } from 'src/constants/PaymentMethod';
import { translate } from 'src/i18n/translate';

export function FormPaymentMethodTypeSelect({ name, disabled, label, required, defaultValue, filter }: Props): React.ReactElement {
    const paymentMethods: Array<PaymentMethod> = Object.values(PaymentMethods);
    const filteredPaymentMethods = paymentMethods.filter((paymentMethod) => filter?.includes(paymentMethod));

    const options = required
        ? filteredPaymentMethods.map((paymentMethod: PaymentMethod) => ({ label: translate(paymentMethod), value: paymentMethod }))
        : [{ label: '', value: null }, ...filteredPaymentMethods.map((paymentMethod: PaymentMethod) => ({ label: translate(paymentMethod), value: paymentMethod }))];

    return <FormSelect label={label} name={name} disabled={disabled} options={options} required={required} defaultValue={defaultValue} />;
}

type Props = {
    name: string;
    label?: string;
    disabled?: boolean;
    required?: boolean;
    defaultValue?: PaymentMethod;
    filter?: Array<PaymentMethod>;
};
