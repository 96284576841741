/* eslint-disable no-control-regex */

import deburr from 'lodash/deburr';
import { isString } from 'src/utils/string/isString';

export function normalizeToAsciiCharacters(text: string): string {
    if (text === undefined || text === null || !isString(text)) {
        return text;
    }

    // Convert accented and other characters to normal ASCII equivalents
    const normalized = deburr(text);

    const ascii = normalized
        .normalize('NFD')
        .replace(/[\u0300-\u036f]/g, '')
        .replace(/[“”„‟«»❝❞〝〞〟＂]/g, '"')
        .replace(/[‘’‚‛‹›`´]/g, "'") // Add support for keeping more characters if needed
        .replace(/[^\x00-\x7F]/g, '');

    return ascii;
}
