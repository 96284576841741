import { makeStyles } from '@material-ui/core';
import * as React from 'react';
import { Tooltip } from 'src/components/Tooltip';
import { translate } from 'src/i18n/translate';
import { useSelector } from 'src/utils/react/useSelector';

export function RestaurantsLinkedToInventorySection(): React.ReactElement | null {
    const classes = useStyles();

    const restaurantsLinkedToInventory = useSelector((state) => state.app.restaurant?.restaurantsLinkedToInventory);

    if (!restaurantsLinkedToInventory) return null;

    return (
        <div>
            <div className={classes.section}>
                <div className={classes.header}>
                    <h2 className={classes.subtitle}>{translate('Restaurants linked to the inventory')}</h2>
                    <Tooltip text={translate('List of restaurants linked to the inventory of this restaurant')} />
                </div>
                {!!restaurantsLinkedToInventory && (
                    <div className={classes.container}>
                        {restaurantsLinkedToInventory?.map((restaurantLinkedToInventory: any, idx: any) => (
                            <div className={classes.item} key={`${restaurantLinkedToInventory.restaurantName}-${idx}`}>
                                {restaurantLinkedToInventory.restaurantName}
                            </div>
                        ))}
                    </div>
                )}
            </div>
        </div>
    );
}

const useStyles = makeStyles((theme) => ({
    title: {
        fontFamily: theme.typography.semiBold,
        fontSize: 20,
        color: theme.palette.secondary.contrastText,
        marginLeft: 10,
    },
    subtitle: {
        fontFamily: theme.typography.medium,
        fontSize: 18,
        color: theme.palette.secondary.contrastText,
        margin: 0,
    },
    container: {
        width: '100%',
        display: 'grid',
        gridTemplateColumns: 'repeat(auto-fill, minmax(200px, 1fr))',
        gap: 12,
    },
    header: {
        display: 'flex',
        alignItems: 'center',
        gap: 12,
    },
    section: {
        width: '100%',
        marginTop: 24,
    },
    item: {
        fontFamily: theme.typography.medium,
        fontSize: 15,
        width: '100%',
        border: '1px solid #676060B2',
        borderRadius: 12,
        padding: 10,
    },
}));
