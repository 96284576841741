import { RolePermissions } from 'src/constants/RolePermission';
import type { ManagerUserId } from 'src/types/Id';
import { useSelector } from 'src/utils/react/useSelector';

export function useUserIsAnAvailableWaiter(): [(arg1: ManagerUserId | undefined) => boolean] {
    const pinCodeUsers = useSelector((state) => state.authentication.pinCodeUsers);

    const userIsAnAvailableWaiter = (managerUserId: undefined | ManagerUserId) => {
        if (!managerUserId) return false;

        const waiters = pinCodeUsers?.filter((user) => user.userRolePermissions?.includes(RolePermissions.SERVE_ORDERS)) ?? [];

        return waiters.some((user) => user.managerUserId === managerUserId);
    };

    return [userIsAnAvailableWaiter];
}
