import { makeStyles } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Grid from '@material-ui/core/Grid';
import { Loader } from '@pidedirecto/ui';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { changeFixedInvoiceValuesApi } from 'src/api/letseatadmin/subscription/changeFixedInvoiceValuesApi';
import { FixedSubscriptionValues, getFixedSubscriptionValuesApi } from 'src/api/letseatadmin/subscription/getFixedInvoiceValuesApi';
import { Form } from 'src/components/form/Form';
import { FormCurrencyNumberStringField } from 'src/components/form/FormCurrencyNumberStringField';
import { FormFacturamaPaymentFormSelect } from 'src/components/form/FormFacturamaPaymentFormSelect';
import { FormFacturamaPaymenMethodSelect } from 'src/components/form/FormFacturamaPaymentMethodSelect';
import { FormFixErrorHint } from 'src/components/form/FormFixErrorHint';
import { FormTextField } from 'src/components/form/FormTextField';
import { translate } from 'src/i18n/translate';
import { appLayout } from 'src/theme/AppLayout';
import type { SubscriptionId } from 'src/types/Id';
import { alertKnownErrorOrSomethingWentWrong } from 'src/utils/alert/alertKnownErrorOrSomethingWentWrong';
import { requireValue } from 'src/utils/require/requireValue';

export function EditBillingDialog({ open, subscriptionId, onClose, onChangeSubscription }: Props): React.ReactElement {
    const classes = useStyles();
    const form = useForm();
    const {
        formState: { isSubmitting },
    } = form;

    const [loading, setLoading] = useState(false);
    const [fixedInvoiceValues, setFixedInvoiceValues] = useState<FixedSubscriptionValues | undefined>(undefined);

    useEffect(() => {
        if (open) load();
    }, [open]);

    const handleClose = () => {
        if (isSubmitting) return;
        setFixedInvoiceValues(undefined);
        onClose();
    };

    const handleExited = () => {
        setLoading(false);
        setFixedInvoiceValues(undefined);
    };

    const load = async () => {
        setLoading(true);
        const response = await getFixedSubscriptionValuesApi({ subscriptionId: requireValue(subscriptionId) });
        setLoading(false);
        if (!response.ok) {
            alertKnownErrorOrSomethingWentWrong(response);
            onClose();
            return;
        }
        setFixedInvoiceValues(response.data);
    };

    const onSubmit = async (form: any) => {
        if (!subscriptionId) return;

        const response = await changeFixedInvoiceValuesApi({
            subscriptionId: subscriptionId,
            fixedInvoiceValuesSubscription: {
                fixedPaymentAmount: form.subscriptionFixedPaymentAmount,
                fixedInvoiceDescription: form.subscriptionFixedInvoiceDescription,
                fixedPaymentMethod: form.subscriptionPaymentMethod,
                fixedPaymentForm: form.subscriptionPaymentForm,
            },
            fixedInvoiceValuesCommission: {
                fixedPaymentAmount: form.commissionFixedPaymentAmount,
                fixedInvoiceDescription: form.commissionFixedInvoiceDescription,
                fixedPaymentMethod: form.commissionPaymentMethod,
                fixedPaymentForm: form.commissionPaymentForm,
            },
            fixedInvoiceValuesApplication: {
                fixedPaymentAmount: form.applicationFixedPaymentAmount,
                fixedInvoiceDescription: form.applicationFixedInvoiceDescription,
                fixedPaymentMethod: form.applicationPaymentMethod,
                fixedPaymentForm: form.applicationPaymentForm,
            },
            fixedInvoiceValuesDeliveries: {
                fixedPaymentAmount: form.deliveriesFixedPaymentAmount,
                fixedInvoiceDescription: form.deliveriesFixedInvoiceDescription,
                fixedPaymentMethod: form.deliveriesPaymentMethod,
                fixedPaymentForm: form.deliveriesPaymentForm,
            },
        });
        if (!response.ok) {
            alertKnownErrorOrSomethingWentWrong(response);
            return;
        }
        onChangeSubscription();
        onClose();
    };

    return (
        <Dialog fullWidth scroll='paper' open={open} onClose={handleClose} onExited={handleExited}>
            <Form form={form} onSubmit={onSubmit} style={appLayout.dialog.form}>
                <DialogTitle>{translate('Edit Billing')}</DialogTitle>
                <DialogContent>
                    {!loading && fixedInvoiceValues && (
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <p className={classes.sectionTitle}>{translate('Application')}</p>
                                <div className={classes.sectionContent}>
                                    <FormFacturamaPaymenMethodSelect
                                        defaultValue={fixedInvoiceValues?.fixedInvoiceValuesApplication?.fixedPaymentMethod}
                                        name='applicationPaymentMethod'
                                        label={translate('Payment Method')}
                                    />
                                    <FormFacturamaPaymentFormSelect
                                        defaultValue={fixedInvoiceValues?.fixedInvoiceValuesApplication?.fixedPaymentForm}
                                        name='applicationPaymentForm'
                                        label={translate('Payment Form')}
                                    />
                                    <FormTextField
                                        defaultValue={fixedInvoiceValues?.fixedInvoiceValuesApplication?.fixedInvoiceDescription}
                                        name='applicationFixedInvoiceDescription'
                                        label={translate('Concept')}
                                    />
                                    <FormCurrencyNumberStringField
                                        defaultValue={fixedInvoiceValues?.fixedInvoiceValuesApplication?.fixedPaymentAmount}
                                        name='applicationFixedPaymentAmount'
                                        label={translate('Amount')}
                                    />
                                </div>
                            </Grid>
                            <Grid item xs={12}>
                                <p className={classes.sectionTitle}>{translate('Commission')}</p>
                                <div className={classes.sectionContent}>
                                    <FormFacturamaPaymenMethodSelect
                                        defaultValue={fixedInvoiceValues?.fixedInvoiceValuesCommission?.fixedPaymentMethod}
                                        name='commissionPaymentMethod'
                                        label={translate('Payment Method')}
                                    />
                                    <FormFacturamaPaymentFormSelect
                                        defaultValue={fixedInvoiceValues?.fixedInvoiceValuesCommission?.fixedPaymentForm}
                                        name='commissionPaymentForm'
                                        label={translate('Payment Form')}
                                    />
                                    <FormTextField
                                        defaultValue={fixedInvoiceValues?.fixedInvoiceValuesCommission?.fixedInvoiceDescription}
                                        name='commissionFixedInvoiceDescription'
                                        label={translate('Concept')}
                                    />
                                    <FormCurrencyNumberStringField
                                        defaultValue={fixedInvoiceValues?.fixedInvoiceValuesCommission?.fixedPaymentAmount}
                                        name='commissionFixedPaymentAmount'
                                        label={translate('Amount')}
                                    />
                                </div>
                            </Grid>
                            <Grid item xs={12}>
                                <p className={classes.sectionTitle}>{translate('Deliveries')}</p>
                                <div className={classes.sectionContent}>
                                    <FormFacturamaPaymenMethodSelect
                                        defaultValue={fixedInvoiceValues?.fixedInvoiceValuesDeliveries?.fixedPaymentMethod}
                                        name='deliveriesPaymentMethod'
                                        label={translate('Payment Method')}
                                    />
                                    <FormFacturamaPaymentFormSelect
                                        defaultValue={fixedInvoiceValues?.fixedInvoiceValuesDeliveries?.fixedPaymentForm}
                                        name='deliveriesPaymentForm'
                                        label={translate('Payment Form')}
                                    />
                                    <FormTextField
                                        defaultValue={fixedInvoiceValues?.fixedInvoiceValuesDeliveries?.fixedInvoiceDescription}
                                        name='deliveriesFixedInvoiceDescription'
                                        label={translate('Concept')}
                                    />
                                    <FormCurrencyNumberStringField
                                        defaultValue={fixedInvoiceValues?.fixedInvoiceValuesDeliveries?.fixedPaymentAmount}
                                        name='deliveriesFixedPaymentAmount'
                                        label={translate('Amount')}
                                    />
                                </div>
                            </Grid>
                            <Grid item xs={12}>
                                <p className={classes.sectionTitle}>{translate('Subscription')}</p>
                                <div className={classes.sectionContent}>
                                    <FormFacturamaPaymenMethodSelect
                                        defaultValue={fixedInvoiceValues?.fixedInvoiceValuesSubscription?.fixedPaymentMethod}
                                        name='subscriptionPaymentMethod'
                                        label={translate('Payment Method')}
                                    />
                                    <FormFacturamaPaymentFormSelect
                                        defaultValue={fixedInvoiceValues?.fixedInvoiceValuesSubscription?.fixedPaymentForm}
                                        name='subscriptionPaymentForm'
                                        label={translate('Payment Form')}
                                    />
                                    <FormTextField
                                        defaultValue={fixedInvoiceValues?.fixedInvoiceValuesSubscription?.fixedInvoiceDescription}
                                        name='subscriptionFixedInvoiceDescription'
                                        label={translate('Concept')}
                                    />
                                    <FormCurrencyNumberStringField
                                        defaultValue={fixedInvoiceValues?.fixedInvoiceValuesSubscription?.fixedPaymentAmount}
                                        name='subscriptionFixedPaymentAmount'
                                        label={translate('Amount')}
                                    />
                                </div>
                            </Grid>
                        </Grid>
                    )}
                    {loading && <Loader size={40} loading={true} />}
                    <FormFixErrorHint />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} disabled={isSubmitting}>
                        {translate('Cancel').toUpperCase()}
                    </Button>
                    <Button color='primary' type='submit' disabled={loading || isSubmitting}>
                        {isSubmitting ? translate('Changing').toUpperCase() : translate('Change').toUpperCase()}
                    </Button>
                </DialogActions>
            </Form>
        </Dialog>
    );
}

const useStyles = makeStyles((theme) => ({
    sectionTitle: {
        fontSize: 18,
        fontWeight: 500,
    },
    sectionContent: {
        display: 'flex',
        flexDirection: 'column',
        gap: 12,
    },
}));

type Props = {
    open: boolean;
    subscriptionId: SubscriptionId | undefined;
    onClose: any;
    onChangeSubscription: any;
};
