import { makeStyles } from '@material-ui/core/styles';
import { useState } from 'react';
import * as React from 'react';
import { Button } from 'src/components/Button';
import { Dialog } from 'src/components/Dialog';
import { DialogActions } from 'src/components/DialogActions';
import { translate } from 'src/i18n/translate';
import { CircularCheckedIcon } from 'src/icons/CircularCheckedIcon';
import { CircularUnCheckedIcon } from 'src/icons/CircularUnCheckedIcon';
import { classNames } from 'src/utils/react/classNames';

export function ConfirmUpdateSupplyCostDialog({ open, onSuccess, onClose }: Props): React.ReactElement {
    const classes = useStyles();

    const [updateSupplyPrices, setUpdateSupplyPrices] = useState(false);

    const onSubmit = () => {
        onSuccess?.(updateSupplyPrices);
    };

    return (
        <Dialog open={open} onClose={onClose} classes={{ dialogContainer: classes.dialog }} title={translate('Do you want to update the base costs of these supplies with those of this buy order?')}>
            <div className={classes.bodyText}>{translate('By updating the costs of these inputs, you can affect prescriptions and compounded inputs that use them')}</div>
            <section className={classes.checkboxContainer}>
                <Button text classes={{ button: classNames(classes.item, { [classes.itemActive]: !updateSupplyPrices }) }} onClick={() => setUpdateSupplyPrices(false)}>
                    {updateSupplyPrices && <CircularUnCheckedIcon width={'18'} height={'18'} />}
                    {!updateSupplyPrices && <CircularCheckedIcon width={'18'} height={'18'} />}
                    <span>{translate('Just complete the buy order')}</span>
                </Button>
                <Button text classes={{ button: classNames(classes.item, { [classes.itemActive]: updateSupplyPrices }) }} onClick={() => setUpdateSupplyPrices(true)}>
                    {updateSupplyPrices && <CircularCheckedIcon width={'18'} height={'18'} />}
                    {!updateSupplyPrices && <CircularUnCheckedIcon width={'18'} height={'18'} />}
                    <span>{translate('Update supply costs with this buy order')}</span>
                </Button>
            </section>
            <DialogActions>
                <Button secondary onClick={onClose}>
                    {translate('Cancel')}
                </Button>
                <Button onClick={onSubmit}>{translate('Accept')} </Button>
            </DialogActions>
        </Dialog>
    );
}

const useStyles = makeStyles((theme) => ({
    bodyText: {
        color: '#2E3748',
        fontSize: 12,
        fontFamily: theme.typography.medium,
        margin: '8px 0',
    },
    dialog: {
        zIndex: 1100,
    },
    buttonsContainer: {
        marginTop: 20,
        marginBottom: 20,
        flexDirection: 'column',
        justifyContent: 'center',
        [theme.breakpoints.up('md')]: {
            flexDirection: 'row',
        },
    },
    item: {
        fontSize: 12,
        borderRadius: 0,
        justifyContent: 'start',
        width: '100%',
        padding: '16px 10px',
        display: 'flex',
        gap: 10,
        borderBottom: '0.5px solid #D9D9D9',
        alignItems: 'center',
        [theme.breakpoints.up('sm')]: {
            fontSize: 16,
        },
    },
    itemActive: {
        backgroundColor: '#EBFFF9',
    },
    checkboxContainer: {
        color: '#2E3748',
        overflow: 'hidden',
        fontFamily: theme.typography.regular,
        fontSize: 16,
        fontWeight: 400,
        borderRadius: 8,
        border: '1px solid #D9D9D9',
    },
}));

type Props = {
    open: boolean;
    onSuccess?: any;
    onClose: any;
};
