import type { RestaurantId } from 'src/types/Id';
import { isMobileApp } from 'src/utils/reactNative/isMobileApp';

export function setRestaurantIdsInMobileApp(restaurantIds: Array<RestaurantId>) {
    if (isMobileApp()) {
        window.ReactNativeWebView.postMessage(
            JSON.stringify({
                type: 'SET_RESTAURANT_IDS',
                data: restaurantIds,
            }),
        );
    }
}
