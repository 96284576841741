import { makeStyles } from '@material-ui/core/styles';
import { DropDown, DropDownItem } from '@pidedirecto/ui';
import { EditIcon, EllipsisVerticalIcon, EnvelopeIcon, PrinterIcon, TrashCanIcon, UploadIcon } from '@pidedirecto/ui/icons';
import { useState } from 'react';
import * as React from 'react';
import { completeBuyOrderApi } from 'src/api/letseatmanager/buyOrder/completeBuyOrderApi';
import { ConfirmUpdateSupplyCostDialog } from 'src/components/dialog/inventory/buyOrder/ConfirmUpdateSupplyCostDialog'; // TODO REMOVE AFTER RELEASE (PRINTERS REFACTOR)
import { SecuredContent } from 'src/components/SecuredContent';
import { RolePermissions } from 'src/constants/RolePermission';
import { translate } from 'src/i18n/translate';
import { SendBuyOrderDialog } from 'src/scenes/letseatmanager/inventory/buyOrder/SendBuyOrderDialog';
import { useNotification } from 'src/services/notification/useNotification';
import { usePrintBuyOrderTicket } from 'src/services/printer/usePrintBuyOrderTicket';
import type { BuyOrderVm } from 'src/types/BuyOrderVm';
import type { SupplierVm } from 'src/types/SupplyVm';
import { alertKnownErrorOrSomethingWentWrong } from 'src/utils/alert/alertKnownErrorOrSomethingWentWrong';
import { isBuyOrderCancelled } from 'src/utils/buyOrder/isBuyOrderCancelled';
import { isBuyOrderCompleted } from 'src/utils/buyOrder/isBuyOrderCompleted';
import { useSelector } from 'src/utils/react/useSelector';

export function BuyOrdersTableContextualMenu({ buyOrder, disabled, onEdit, onRemove, onSuccess, suppliers }: Props): React.ReactElement {
    const classes = useStyles();

    const [printBuyOrderTicket] = usePrintBuyOrderTicket();
    const notification = useNotification();

    const [sendBuyOrderDialogState, setSendBuyOrderDialogState] = useState<{ open: boolean; supplierEmail: string | undefined }>({ open: false, supplierEmail: undefined });
    const [loading, setLoading] = useState(false);
    const [confirmUpdateSupplyCostsDialogState, setConfirmUpdateSupplyCostsDialogState] = useState({ open: false });

    const pinCodeUserManagerUserId = useSelector((state) => state.authentication.pinCodeUserSignedIn?.managerUserId);

    const confirmCompleteBuyOrder = () => {
        if (disabledSendButton) return;
        setConfirmUpdateSupplyCostsDialogState({ open: true });
    };

    const handleEditBuyOrder = () => {
        onEdit(buyOrder.buyOrderId);
    };

    const handleRemoveBuyOrder = () => {
        if (isBuyOrderCompleted(buyOrder) || isBuyOrderCancelled(buyOrder)) return;
        onRemove(buyOrder.buyOrderId);
    };

    const disabledSendButton = loading || isBuyOrderCancelled(buyOrder) || isBuyOrderCompleted(buyOrder);

    const handleCloseSendBuyOrderDialog = () => {
        setSendBuyOrderDialogState({ open: false, supplierEmail: undefined });
        onSuccess();
    };

    const handleCompleteBuyOrder = async (updateSupplyPrices: any) => {
        setLoading(true);
        const response = await completeBuyOrderApi({
            buyOrderId: buyOrder.buyOrderId,
            managerUserId: pinCodeUserManagerUserId,
            updateSupplyPrices,
        });

        setLoading(false);
        if (!response.ok) {
            alertKnownErrorOrSomethingWentWrong(response);
            return;
        }

        onSuccess();
        notification({ message: translate('Buy order completed') });
        closeConfirmUpdateSupplyCostsDialog();
    };

    const closeConfirmUpdateSupplyCostsDialog = () => setConfirmUpdateSupplyCostsDialogState({ open: false });

    const handleOpenSendBuyOrderDialog = () => {
        const supplierEmail = suppliers.find((supplier) => supplier.supplierId === buyOrder.supplierId)?.email;
        setSendBuyOrderDialogState({ open: true, supplierEmail });
    };

    return (
        <>
            {buyOrder && (
                <SendBuyOrderDialog
                    open={sendBuyOrderDialogState.open}
                    supplierEmail={sendBuyOrderDialogState.supplierEmail}
                    onClose={handleCloseSendBuyOrderDialog}
                    buyOrderId={buyOrder.buyOrderId}
                />
            )}
            <ConfirmUpdateSupplyCostDialog open={confirmUpdateSupplyCostsDialogState.open} onClose={closeConfirmUpdateSupplyCostsDialog} onSuccess={handleCompleteBuyOrder} />
            <DropDown variant='icon' content={<EllipsisVerticalIcon size={20} />} position='right' classes={{ dropdown: classes.dropdown }}>
                <SecuredContent rolePermission={RolePermissions.EDIT_BUY_ORDERS}>
                    <DropDownItem onClick={() => handleEditBuyOrder()} classes={{ container: classes.menuItem }}>
                        <EditIcon color={'#6C7076'} size={14} />
                        {translate('Edit')}
                    </DropDownItem>
                </SecuredContent>

                <SecuredContent rolePermission={RolePermissions.SEND_BUY_ORDERS}>
                    <DropDownItem onClick={() => handleOpenSendBuyOrderDialog()} classes={{ container: classes.menuItem }}>
                        <EnvelopeIcon color={'#6C7076'} size={14} />
                        {translate('Send')}
                    </DropDownItem>
                </SecuredContent>

                <SecuredContent rolePermission={RolePermissions.CREATE_MERCHANDISE_ENTRY}>
                    <DropDownItem onClick={confirmCompleteBuyOrder} classes={{ container: classes.menuItem }}>
                        <UploadIcon color={'#6C7076'} size={14} />
                        {translate('Complete')}
                    </DropDownItem>
                </SecuredContent>

                <DropDownItem onClick={() => printBuyOrderTicket(buyOrder)} classes={{ container: classes.menuItem }}>
                    <PrinterIcon color={'#6C7076'} size={14} />
                    {translate('Print')}
                </DropDownItem>

                <DropDownItem onClick={() => handleRemoveBuyOrder()} classes={{ container: classes.menuItem }}>
                    <TrashCanIcon color={'#E37878'} size={14} />
                    {translate('Cancel')}
                </DropDownItem>
            </DropDown>
        </>
    );
}

const useStyles = makeStyles((theme) => ({
    menuItem: {
        display: 'flex',
        alignItems: 'center',
        gap: 6,
    },
    dropdown: {
        maxHeight: 'unset',
    },
}));
type Props = {
    buyOrder: BuyOrderVm;
    suppliers: Array<SupplierVm>;
    disabled: boolean;
    onEdit: any;
    onRemove: any;
    onSuccess: any;
};
