import { useEffect } from 'react';
import { WebSocketEventTypes } from 'src/constants/WebSocketEventType';
import { translate } from 'src/i18n/translate';
import { useLoadMenus } from 'src/services/menu/useLoadMenus';
import { useNotification } from 'src/services/notification/useNotification';
import { useLoadRestaurantMenu } from 'src/services/restaurant/useLoadRestaurantMenu';
import type { MenuId, RestaurantId } from 'src/types/Id';
import type { WebSocketEvent } from 'src/types/WebSocketEvent';
import { useSelector } from 'src/utils/react/useSelector';
import { WebSocketEvents } from 'src/utils/webSocket/WebSocketEvents';

export function useMenuSubscriptionUpdates() {
    const notification = useNotification();
    const refreshMenus = useLoadRestaurantMenu();
    const loadMenus = useLoadMenus();

    const restaurantId = useSelector((state) => state.app.restaurantId);

    useEffect(() => {
        const menuCreatedSyncWebSocketEvent = WebSocketEvents.addEventListener(WebSocketEventTypes.MENU_CREATED, handleMenuWebSocketEvents);
        const menuChangedSyncWebSocketEvent = WebSocketEvents.addEventListener(WebSocketEventTypes.MENU_CHANGED, handleMenuWebSocketEvents);
        const menuRemovedSyncWebSocketEvent = WebSocketEvents.addEventListener(WebSocketEventTypes.MENU_REMOVED, handleMenuWebSocketEvents);
        const menuCopiedSyncWebSocketEvent = WebSocketEvents.addEventListener(WebSocketEventTypes.MENU_COPIED, handleMenuWebSocketEvents);
        const menuImportedSyncWebSocketEvent = WebSocketEvents.addEventListener(WebSocketEventTypes.MENU_CREATED_FROM_IMPORTED_MENU, handleMenuWebSocketEvents);
        const menuImportedFromExternalUrlSyncWebSocketEvent = WebSocketEvents.addEventListener(WebSocketEventTypes.RESTAURANT_MENU_IMPORTED_FROM_EXTERNAL_URL, handleMenuWebSocketEvents);

        return () => {
            menuCreatedSyncWebSocketEvent.remove();
            menuChangedSyncWebSocketEvent.remove();
            menuRemovedSyncWebSocketEvent.remove();
            menuCopiedSyncWebSocketEvent.remove();
            menuImportedSyncWebSocketEvent.remove();
            menuImportedFromExternalUrlSyncWebSocketEvent.remove();
        };
    }, [restaurantId]);

    const handleMenuWebSocketEvents = async (event: WebSocketEvent<MenuWebSocketEventResponse>) => {
        let message;
        const defaultMessage = translate('Synchronizing menus');

        if (restaurantId !== event.data?.restaurantId) return;
        const isMenuImportedEvent =
            event.webSocketEventType === WebSocketEventTypes.MENU_CREATED_FROM_IMPORTED_MENU || event.webSocketEventType === WebSocketEventTypes.RESTAURANT_MENU_IMPORTED_FROM_EXTERNAL_URL;

        switch (event.webSocketEventType) {
            case WebSocketEventTypes.MENU_CREATED:
                message = translate('Menu @menuName created', { menuName: event.data?.menuName });
                break;
            case WebSocketEventTypes.MENU_CHANGED:
                message = translate(`Menu @menuName changed`, { menuName: event.data?.menuName });
                break;
            case WebSocketEventTypes.MENU_REMOVED:
                message = translate(`Menu @menuName removed`, { menuName: event.data?.menuName });
                break;
            case WebSocketEventTypes.MENU_COPIED:
                message = translate(`Menu copied from @restaurantName`, { restaurantName: event.data?.restaurantName });
                break;
            case WebSocketEventTypes.MENU_CREATED_FROM_IMPORTED_MENU:
            case WebSocketEventTypes.RESTAURANT_MENU_IMPORTED_FROM_EXTERNAL_URL:
                message = translate(`Menu imported`);
                break;
            default:
                message = defaultMessage;
                break;
        }

        notification({ message });
        if (isMenuImportedEvent || WebSocketEventTypes.MENU_COPIED === event.webSocketEventType) {
            await refreshMenus();
            return;
        }
        await loadMenus();
    };
}

type MenuWebSocketEventResponse = {
    restaurantId: RestaurantId;
    menuId: MenuId;
    menuName?: string;
    restaurantName?: string;
};
