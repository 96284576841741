import { pidedirectoReportsMethod } from 'src/api/pidedirectoReportsApi';
import type { ApiSauceResponse } from 'src/api/types/ApiSauceResponse';
import type { RestaurantId } from 'src/types/Id';

export async function getIntegrationsPaymentReportApi(request: GetIntegrationsPaymentReportApiRequest): ApiSauceResponse<IntegrationsPaymentReport> {
    return pidedirectoReportsMethod(`/getIntegrationsPaymentReportApi`, request);
}

export type GetIntegrationsPaymentReportApiRequest = {
    restaurantIds: Array<RestaurantId>;
    fromDate: string;
    toDate: string;
};

export type IntegrationsPaymentReport = {
    integrationsPaymentReportKpis: IntegrationsPaymentKpiReport;
    dailySalesAndOrdersReport: Array<DailySalesAndOrdersReport>;
    totalCommissionsReport: TotalCommissionsReport;
    integrationOrdersSummaryReport: Array<IntegrationOrdersSummaryReport>;
    heatmapReport: Array<HourlyOrdersReport>;
    maxValueForHeatmap: string;
};

export type IntegrationsPaymentKpiReport = {
    totalCommissionUberEats: string;
    totalCommissionRappi: string;
    totalCommissionDidiFood: string;
    totalCommissionPedidosYa: string;
    totalCommission: string;
    uberEatsCompleteOrders: string;
    rappiCompleteOrders: string;
    didiFoodCompleteOrders: string;
    pedidosYaCompleteOrders: string;
    totalOrders: string;
    uberEatsSales: string;
    rappiSales: string;
    didiFoodSales: string;
    pedidosYaSales: string;
    totalSales: string;
    uberEatsSalesWithoutCommission: string;
    rappiSalesWithoutCommission: string;
    didiFoodSalesWithoutCommission: string;
    pedidosYaSalesWithoutCommission: string;
    totalSalesWithoutCommission: string;
    dailyAverageGrossSalesUberEats: string;
    dailyAverageGrossSalesRappi: string;
    dailyAverageGrossSalesDidiFood: string;
    dailyAverageGrossSalesPedidosYa: string;
    dailyAverageGrossSales: string;
    uberEatsCancelled: string;
    rappiCancelled: string;
    didiFoodCancelled: string;
    pedidosYaCancelled: string;
    totalCancelled: string;
};

export type DailySalesAndOrdersReport = {
    createdAt: string;
    restaurantNetTotal: string;
    restaurantGrossTotal: string;
    uberEatsIntegrationOrderCommission: string;
    rappiIntegrationOrderCommission: string;
    didiFoodIntegrationOrderCommission: string;
    pedidosYaIntegrationOrderCommission: string;
    uberEatsSales: string;
    rappiSales: string;
    didiFoodSales: string;
    pedidosYaSales: string;
    uberEatsSalesWithoutCommission: string;
    rappiSalesWithoutCommission: string;
    didiFoodSalesWithoutCommission: string;
    pedidosYaSalesWithoutCommission: string;
};

export type TotalCommissionsReport = {
    uberEatsTotalSales: string;
    rappiTotalSales: string;
    didiFoodTotalSales: string;
    pedidosYaTotalSales: string;
    uberEatsTotalCommission: string;
    rappiTotalCommission: string;
    didiFoodTotalCommission: string;
    pedidosYaTotalCommission: string;
    uberEatsTotalSalesWithoutComission: string;
    rappiTotalSalesWithoutComission: string;
    didiFoodTotalSalesWithoutComission: string;
    pedidosYaTotalSalesWithoutComission: string;
};

export type IntegrationOrdersSummaryReport = {
    shortOrderId: string;
    restaurantNetTotal: string;
    restaurantGrossTotal: string;
    uberEatsIntegrationOrderCommission: string;
    rappiIntegrationOrderCommission: string;
    didiFoodIntegrationOrderCommission: string;
    pedidosYaIntegrationOrderCommission: string;
    createdAt: string;
};

export type HourlyOrdersReport = {
    hour: string;
    monday: string;
    tuesday: string;
    wednesday: string;
    thursday: string;
    friday: string;
    saturday: string;
    sunday: string;
};
