import { letseatmanagerApiMethod } from 'src/api/letseatmanagerApi';
import type { ApiSauceResponse } from 'src/api/types/ApiSauceResponse';
import type { SubscriptionId } from 'src/types/Id';

export async function createManualInvoicesForSubscriptionRestaurantBillingCommissionsApi(
    request: CreateManualInvoiceForSubscriptionRestaurantBillingCommissionsApiRequest,
): ApiSauceResponse<CreateManualInvoiceForSubscriptionRestaurantBillingCommissionsApiResponse> {
    return letseatmanagerApiMethod('subscription/CreateManualInvoiceForSubscriptionRestaurantBillingCommissionsApi', request);
}

export type CreateManualInvoiceForSubscriptionRestaurantBillingCommissionsApiRequest = {
    subscriptionId: SubscriptionId;
    from: string; // YYYY-MM-DD,
    to: string; // YYYY-MM-DD,
    noDateInvoiceConcept?: boolean;
};

export type CreateManualInvoiceForSubscriptionRestaurantBillingCommissionsApiResponse =
    | undefined
    | {
          errorMessage: string;
      };
