import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { useForm, useFormContext } from 'react-hook-form';
import { getSubscriptonHistoryApi } from 'src/api/letseatadmin/subscriptionHistory/getSubscriptionHistoryApi';
import { Form } from 'src/components/form/Form';
import { FormCurrencyNumberStringField } from 'src/components/form/FormCurrencyNumberStringField';
import { FormDatePicker } from 'src/components/form/FormDatePicker';
import { FormDateTimePicker } from 'src/components/form/FormDateTimePicker';
import { FormSubscriptionPaymentMethodSelect } from 'src/components/form/FormSubscriptionPaymentMethodSelect';
import { FormSubscriptionPlansSelect } from 'src/components/form/FormSubscriptionPlansSelect';
import { FormSubscriptionStatusesSelect } from 'src/components/form/FormSubscriptionStatusesSelect';
import { FormTextField } from 'src/components/form/FormTextField';
import { OpenInNewTabToolbarButton } from 'src/components/mui-datatables/OpenInNewTabToolbarButton';
import { RefreshToolbarButton } from 'src/components/mui-datatables/RefreshToolbarButton';
import { UpdatingContentProgress } from 'src/components/UpdatingContentProgress';
import { CardPriorities } from 'src/constants/CardPriority';
import type { PaymentRejectReason } from 'src/constants/PaymentRejectReason';
import type { SubscriptionPaymentErrorType } from 'src/constants/SubscriptionPaymentErrorType';
import { TimeZones } from 'src/constants/TimeZone';
import { translate } from 'src/i18n/translate';
import { SubscriptionCard } from 'src/scenes/letseatmanager/subscription/SubscriptionCard';
import type { RestaurantId, SubscriptionHistoryId } from 'src/types/Id';
import { alertKnownErrorOrSomethingWentWrong } from 'src/utils/alert/alertKnownErrorOrSomethingWentWrong';
import { overdueDays } from 'src/utils/date/overdueDays';
import { requireValue } from 'src/utils/require/requireValue';
import { translatePaymentRejectReason } from 'src/utils/translate/translatePaymentRejectReason';
import { translateSubscriptionPaymentErrorType } from 'src/utils/translate/translateSubscriptionPaymentErrorType';
import { openRestaurantPageInNewTab } from 'src/utils/window/openRestaurantPageInNewTab';

export function SubscriptionHistoryDetails({ subscriptionHistoryId }: Props): React.ReactElement {
    const classes = useStyles();
    const form = useForm();
    const { watch } = form;
    const restaurantId: RestaurantId | undefined = watch('restaurantId');
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        if (subscriptionHistoryId) load();
    }, [subscriptionHistoryId]);

    const load = async () => {
        setLoading(true);
        const response = await getSubscriptonHistoryApi({ subscriptionHistoryId: requireValue(subscriptionHistoryId) });
        setLoading(false);
        if (!response.ok) {
            alertKnownErrorOrSomethingWentWrong(response);
            return;
        }
        const subscriptionHistory = response.data;

        form.reset(subscriptionHistory);
    };

    return (
        <div className={classes.container}>
            <UpdatingContentProgress loading={loading} />
            <Card classes={{ root: classes.root }}>
                <CardHeader
                    title={
                        <Typography display='block' gutterBottom variant='h6'>
                            {translate('Subscription History Details')}
                        </Typography>
                    }
                    action={
                        <div>
                            <OpenInNewTabToolbarButton onClick={() => openRestaurantPageInNewTab(restaurantId)} tooltip={translate('Open Restaurant')} />
                            {/*<RemoveToolbarButton onClick={async () => {*/}
                            {/*    const remove = window.confirm('Are you sure you want to remove the subscription');*/}
                            {/*    if (remove) await removeSubscription(subscriptionId);*/}
                            {/*}} />*/}
                            <RefreshToolbarButton onClick={load} />
                        </div>
                    }
                />
                <CardContent>
                    <Form form={form} onSubmit={() => {}}>
                        <FormContent />
                    </Form>
                </CardContent>
            </Card>
        </div>
    );
}

function FormContent() {
    const { watch } = useFormContext();
    const cards = watch('cards');
    const previousPaymentDate: Date | undefined = watch('previousPaymentDate');
    const previousPaymentPaidAt: Date | undefined = watch('previousPaymentPaidAt');
    const nextPaymentDate: Date | undefined = watch('nextPaymentDate');
    const previousPaymentOverdue = overdueDays(previousPaymentDate, previousPaymentPaidAt, TimeZones.AMERICA_MONTERREY);
    const nextPaymentOverdue = overdueDays(nextPaymentDate, new Date(), TimeZones.AMERICA_MONTERREY);
    const paymentErrorType: SubscriptionPaymentErrorType | undefined = watch('paymentErrorType');
    const paymentRejectReason: PaymentRejectReason | undefined = watch('paymentRejectReason');

    const primaryCard = cards?.find((card: any) => card.cardPriority === CardPriorities.PRIMARY);
    const backupCard = cards?.find((card: any) => card.cardPriority === CardPriorities.BACKUP);
    return (
        <Grid container spacing={3}>
            <Grid item xs={12} sm={6}>
                <FormTextField name='paymentId' label={translate('Restaurant Name')} disabled />
            </Grid>
            <Grid item xs={12} sm={6}>
                <FormTextField name='restaurantNames' label={translate('Restaurant Name')} disabled />
            </Grid>
            <Grid item xs={12} sm={6}>
                <FormTextField name='businessName' label={translate('Business Name')} disabled />
            </Grid>
            <Grid item xs={12} sm={6}>
                <FormTextField name='managerMessage' label={translate('Manager Message')} disabled />
            </Grid>
            <Grid item xs={12} sm={6}>
                <FormTextField name='comment' label={translate('Comment')} disabled />
            </Grid>
            <Grid item xs={12} sm={6}>
                <FormTextField name='invoiceDescription' label={translate('Invoice Description')} disabled />
            </Grid>
            <Grid item xs={12} sm={6}>
                <FormSubscriptionStatusesSelect name={'status'} label={translate('Status')} disabled />
            </Grid>
            <Grid item xs={12} sm={6}>
                <FormSubscriptionPlansSelect name={'plan'} label={translate('Plan')} disabled />
            </Grid>
            <Grid item xs={12} sm={4}>
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <FormCurrencyNumberStringField name='paymentAmount' label={translate('Amount')} disabled />
                    </Grid>
                    <Grid item xs={12}>
                        <FormSubscriptionPaymentMethodSelect name='subscriptionPaymentMethod' label={translate('Payment Method')} disabled />
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs={12} sm={4}>
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <FormCurrencyNumberStringField name='nextPaymentAmount' label={translate('Next Payment Amount')} disabled />
                    </Grid>
                    <Grid item xs={12}>
                        <FormDatePicker
                            name='nextPaymentDate'
                            label={translate('Next Payment Date')}
                            helperText={
                                nextPaymentOverdue !== undefined
                                    ? translate(`@overdue days late`, {
                                          overdue: nextPaymentOverdue,
                                      })
                                    : nextPaymentDate
                                      ? ''
                                      : '?'
                            }
                            disabled
                        />
                    </Grid>
                </Grid>
            </Grid>
            {!cards?.length && (
                <>
                    <Grid item xs={12}>
                        <Typography>{translate('No card added')}</Typography>
                    </Grid>
                </>
            )}
            {!!cards?.length && (
                <Grid item xs={12}>
                    <Grid container spacing={3}>
                        {primaryCard && (
                            <Grid item xs={12} md={6}>
                                <SubscriptionCard card={primaryCard} cardPriority={primaryCard.cardPriority} admin />
                            </Grid>
                        )}
                        {backupCard && (
                            <Grid item xs={12} md={6}>
                                <SubscriptionCard card={backupCard} cardPriority={backupCard.cardPriority} admin />
                            </Grid>
                        )}
                    </Grid>
                </Grid>
            )}
            <Grid item xs={12} sm={6}>
                <FormDateTimePicker name='createdAt' label={translate('Created')} disabled />
            </Grid>
            <Grid item xs={12} sm={6}>
                <FormDateTimePicker name='modifiedAt' label={translate('Modified')} disabled />
            </Grid>
            <Grid item xs={12}>
                <FormTextField name='paymentErrorType' label={translate('Payment Error')} helperText={translateSubscriptionPaymentErrorType(paymentErrorType)} disabled />
            </Grid>
            <Grid item xs={12}>
                <FormTextField name='paymentRejectReason' label={translate('Payment Reject Reason')} helperText={translatePaymentRejectReason(paymentRejectReason)} disabled />
            </Grid>
        </Grid>
    );
}

const useStyles = makeStyles((theme) => ({
    container: {
        position: 'relative',
    },
    root: {
        paddingLeft: 8,
        paddingRight: 8,
    },
}));

type Props = {
    subscriptionHistoryId: SubscriptionHistoryId;
};
