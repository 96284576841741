import { letseatadminApiMethod } from 'src/api/letseatadminApi';
import type { ApiSauceResponse } from 'src/api/types/ApiSauceResponse';
import type { DriverManualEarningVm } from 'src/types/DriverManualEarningVm';
import type { DriverManualEarningId } from 'src/types/Id';

export async function getDriverManualEarningApi(request: GetDriverManualEarningApiRequest): ApiSauceResponse<DriverManualEarningVm> {
    return letseatadminApiMethod('driverManualEarning/getDriverManualEarningApi', request);
}

export type GetDriverManualEarningApiRequest = {
    driverManualEarningId: DriverManualEarningId;
};
