import { csvToObjects } from 'src/utils/csv/csvToObjects';
import { isEmpty } from 'src/utils/object/isEmpty';
import { removeNulls } from 'src/utils/removeNulls';
import { requireNumberString } from 'src/utils/require/requireNumberString';
import { requireStringNotBlank } from 'src/utils/require/requireStringNotBlank';
import { requireValue } from 'src/utils/require/requireValue';
import { emptyStringToUndefined } from 'src/utils/string/emptyStringToUndefined';

export async function convertCsvToImportedRecipes(file: string): Promise<{ importedRecipes: Array<ImportedRecipe>; invalidRecipes: Array<CsvRow> }> {
    const rows = await csvToObjects<CsvRow>(file);

    const importedRecipesMap = new Map<string, ImportedRecipe>();
    const invalidRows = [];

    for (const row of rows) {
        try {
            if (isEmptyRow(row)) {
                continue;
            }
            if (importedRecipesMap.has(row['NOMBRE_RECETA'])) {
                const importedRecipe = requireValue(importedRecipesMap.get(row['NOMBRE_RECETA']));

                importedRecipesMap.set(row['NOMBRE_RECETA'], {
                    ...importedRecipe,
                    ingredients: [
                        ...importedRecipe.ingredients,
                        {
                            sku: requireStringNotBlank(row['SKU_INGREDIENTE']),
                            quantity: requireNumberString(row['CANTIDAD']),
                        },
                    ],
                });

                continue;
            }

            importedRecipesMap.set(row['NOMBRE_RECETA'], {
                name: requireStringNotBlank(row['NOMBRE_RECETA']),
                ingredients: [
                    {
                        sku: requireStringNotBlank(row['SKU_INGREDIENTE']),
                        quantity: requireNumberString(row['CANTIDAD']),
                    },
                ],
            });
        } catch (e) {
            invalidRows.push(row);
        }
    }

    return { importedRecipes: Array.from(importedRecipesMap.values()), invalidRecipes: invalidRows };
}

function isEmptyRow(row: CsvRow): boolean {
    const cleanedRow = removeNulls({
        name: emptyStringToUndefined(row['NOMBRE_RECETA']),
        ingredients: emptyStringToUndefined(row['SKU_INGREDIENTE']),
        estimatedPrice: emptyStringToUndefined(row['CANTIDAD']),
    });

    return isEmpty(cleanedRow);
}

type CsvRow = {
    ['NOMBRE_RECETA']: string;
    ['SKU_INGREDIENTE']: string;
    ['CANTIDAD']: string;
};

export type ImportedRecipe = {
    name: string;
    ingredients: Array<ImportedIngredient>;
    estimatedPrice?: string;
};

export type ImportedIngredient = {
    sku: string;
    quantity: string;
};
