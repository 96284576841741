import { Button, Table } from '@pidedirecto/ui';
import { RefreshIcon } from '@pidedirecto/ui/icons';
import { formatAsCurrencyNumber } from '@pidedirecto/ui/utils';
import { BigNumber } from 'bignumber.js';
import * as React from 'react';
import { DeliveryOrders } from 'src/api/letseatmanager/restaurantDashboard/getDeliveryRestaurantStatisticsApi';
import { translate } from 'src/i18n/translate';
import { ReportSection } from 'src/scenes/letseatmanager/restaurantDashboard/ReportSection';

export function DeliveryOrdersTable({ deliveryOrders }: Props): React.ReactElement {
    const deliveryOrdersColumns = [
        {
            content: translate('Order ID'),
            id: 'orderId',
        },
        {
            content: translate('Driving distance'),
            id: 'drivingDistance',
        },
        {
            content: translate('Time to deliver'),
            id: 'deliveryTime',
        },
        {
            content: translate('Delivery Cost'),
            id: 'deliveryCost',
        },
        {
            content: translate('Total'),
            id: 'total',
        },
    ];

    const searchDeliveryOrder = (search: string, deliveryOrder: DeliveryOrders) => deliveryOrder.shortOrderId.toLowerCase().includes(search.toLowerCase());

    return (
        <ReportSection title={translate('Orders')}>
            <div>
                <Table
                    columns={deliveryOrdersColumns}
                    options={{
                        downloadFileName: 'delivery-orders-file',
                        downloadable: true,
                        searchable: true,
                    }}
                    rows={deliveryOrders?.map((order) => ({
                        orderId: order.shortOrderId,
                        drivingDistance: `${BigNumber(order.drivingDistance).toFixed(3)}km`,
                        deliveryTime: translate('@minutes min', { minutes: BigNumber(order.deliveryTime).toFixed(2) }),
                        deliveryCost: formatAsCurrencyNumber(BigNumber(order.deliveryCost).toFixed(2)),
                        total: formatAsCurrencyNumber(BigNumber(order.total).toFixed(2)),
                        onSearch: (search: string) => searchDeliveryOrder(search, order),
                    }))}
                    rowsPerPage={10}
                    toolbar={
                        <>
                            <Button variant='secondary'>
                                <RefreshIcon size={20} />
                            </Button>
                        </>
                    }
                />
            </div>
        </ReportSection>
    );
}

type Props = {
    deliveryOrders: Array<DeliveryOrders>;
};
