import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Grid from '@material-ui/core/Grid';
import * as React from 'react';
import { useForm } from 'react-hook-form';
import { sendSmsToDriverApi } from 'src/api/letseatmanager/driver/sendSmsToDriverApi';
import { Form } from 'src/components/form/Form';
import { FormFixErrorHint } from 'src/components/form/FormFixErrorHint';
import { FormTextField } from 'src/components/form/FormTextField';
import { translate } from 'src/i18n/translate';
import { appReducer } from 'src/reducers/appReducer';
import { appLayout } from 'src/theme/AppLayout';
import type { DriverId } from 'src/types/Id';
import { useAction } from 'src/utils/react/useAction';

export function SendSmsToDriversDialog({ open, driverIds, onClose }: Props): React.ReactElement {
    const form = useForm();
    const {
        formState: { isSubmitting },
    } = form;

    const showSnackbar = useAction(appReducer.actions.showSnackbar);

    const handleClose = () => {
        if (isSubmitting) return;
        onClose();
    };

    const onSubmit = async (form: any) => {
        if (!driverIds) return;

        const result: Record<string, number> = { Success: 0, Failed: 0 } as const;
        let sendingCount = 0;
        for (const driverId of driverIds) {
            sendingCount++;
            showSnackbar({ message: `Sending ${sendingCount} of ${driverIds.length} (DONT CLOSE OR LEAVE PAGE)` });
            const response = await sendSmsToDriverApi({ driverId, smsText: form.smsText });
            if (response.ok) {
                result.Success = result.Success + 1;
            } else {
                result.Failed = result.Failed + 1;
            }
        }

        alert(
            'Result:\n' +
                Object.entries(result)
                    .map(([result, count]: [any, any]) => `${result as any}: ${String(count)}`)
                    .join('\n'),
        );
        onClose();
    };

    return (
        <Dialog fullWidth scroll='paper' open={open} onClose={handleClose}>
            <Form form={form} onSubmit={onSubmit} style={appLayout.dialog.form}>
                <DialogTitle>{translate('Bulk Send SMS To Drivers').toUpperCase()}</DialogTitle>
                <DialogContent>
                    <FormContent driverIds={driverIds} />
                    <FormFixErrorHint />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} disabled={isSubmitting}>
                        {translate('Cancel').toUpperCase()}
                    </Button>
                    <Button color='primary' type='submit' disabled={isSubmitting}>
                        {isSubmitting ? translate('Sending').toUpperCase() : translate('Send').toUpperCase()}
                    </Button>
                </DialogActions>
            </Form>
        </Dialog>
    );
}

function FormContent({ driverIds }: { driverIds?: Array<DriverId> }) {
    if (!driverIds) return null;
    return (
        <Grid container spacing={3}>
            <Grid item xs={12}>
                <FormTextField
                    name='smsText'
                    label={translate('SMS Text')}
                    helperText={`${translate('@name will be replaced with drivers first name or client, and this message only accept max 120 characters')}`}
                    multiline
                    maxLength={120}
                    required
                />
            </Grid>
            <Grid item xs={12}>
                {`This message will be sent to ${driverIds.length} drivers`}
            </Grid>
        </Grid>
    );
}

type Props = SendSmsToDriversDialogState & {
    onClose: any;
};

export type SendSmsToDriversDialogState = {
    open: boolean;
    driverIds?: Array<DriverId>;
};
