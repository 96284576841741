import { makeStyles } from '@material-ui/core';
import { useMemo } from 'react';
import * as React from 'react';
import { Button } from 'src/components/Button';
import { translate } from 'src/i18n/translate';
import { PrinterOutlineIcon } from 'src/icons/PrinterOutlineIcon';
import { usePrintOrderCommand } from 'src/services/printer/usePrintOrderCommand';
import { getOrderDemo } from 'src/utils/order/getOrderDemo';
import { useSelector } from 'src/utils/react/useSelector';

export function PrintCommandTestButton({ disabled }: Props): React.ReactElement {
    const classes = useStyles();

    const restaurant = useSelector((state) => state.app.restaurant);

    const [printOrderCommand] = usePrintOrderCommand();

    const order = useMemo(() => getOrderDemo(restaurant), [restaurant]);

    return (
        <Button onClick={() => printOrderCommand(order, true)} disabled={disabled} classes={{ button: classes.printButton }}>
            <PrinterOutlineIcon color={'#FFF'} title={'print-command-test'} />
            {translate('Print test')}
        </Button>
    );
}

const useStyles = makeStyles((theme) => ({
    printButton: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        height: 50,
        width: 160,
        [theme.breakpoints.up('md')]: {
            alignSelf: 'center',
        },
        marginTop: 10,
    },
}));

type Props = {
    disabled: boolean;
};
