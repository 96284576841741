import { makeStyles } from '@material-ui/core/styles';
import { Autocomplete, Button, Select, Text } from '@pidedirecto/ui';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { VirtualizedList } from 'src/components/VirtualizedList';
import { translate } from 'src/i18n/translate';
import { SearchableListItem } from 'src/scenes/letseatmanager/menu/components/SearchableListItem';
import { debounce } from 'src/utils/function/debounce';
import { lowerCase } from 'src/utils/string/lowerCase';
import { normalizeToAsciiCharacters } from 'src/utils/string/normalizeToAsciiCharacters';

export function SearchableList({
    title,
    disabled,
    items,
    itemSize,
    height,
    onItemClick,
    onCreate,
    defaultListItemSelected,
    getItemKey,
    getItemSearchableBy,
    renderItem,
    filter,
    onFilterClick,
}: Props): React.ReactElement {
    const classes = useStyles();

    const [selectedItem, setSelectedItem] = useState(defaultListItemSelected);
    const [selectedFilterOption, setSelectedFilterOption] = useState('');
    const [searchValue, setSearchValue] = useState('');

    useEffect(() => {
        if (!selectedItem && defaultListItemSelected) setSelectedItem(defaultListItemSelected);
    }, [defaultListItemSelected, selectedItem]);

    const handleClickItem = (item: any) => {
        setSelectedItem(item);
        onItemClick?.(item);
    };

    const shouldShowItem = (item: any) => {
        try {
            if (!searchValue) return true;
            return normalizeToAsciiCharacters(lowerCase(getItemSearchableBy(item))).includes(normalizeToAsciiCharacters(lowerCase(searchValue)));
        } catch (e: any) {
            return false;
        }
    };

    const onSearchByFilter = (filterSelected: any) => {
        onFilterClick?.(filterSelected);
    };

    const itemsVisible = items?.filter(shouldShowItem);

    const options: Array<{
        value: any;
        label: string;
    }> = searchValue ? (itemsVisible?.map((item: any) => ({ value: item, label: getItemSearchableBy(item) }))?.slice(0, 5) ?? []) : [];

    const handleInputChange = (searchValue: any) => setSearchValue(searchValue);
    const debouncedHandleInputChange = debounce(handleInputChange, 300);

    return (
        <div className={classes.container}>
            <div className={classes.titleContainer}>
                <h1 className={classes.title}>{title}</h1>
                <div className={classes.searchInputContainer}>
                    <div className={classes.selectInput}>
                        {!!filter && (
                            <Select
                                classes={{ container: classes.select, button: classes.selectButton }}
                                name={filter.name}
                                options={filter.options}
                                value={selectedFilterOption}
                                placeholder={filter.label}
                                onChange={(e: any) => onSearchByFilter(e)}
                            />
                        )}
                    </div>
                    <div className={classes.searchInput}>
                        <Autocomplete
                            placeholder={translate('Search...')}
                            name={'search'}
                            onInputChange={debouncedHandleInputChange}
                            onChange={handleClickItem}
                            data={options}
                            getOptionLabel={(option) => option.label}
                            getOptionValue={(option) => option.value}
                            renderOption={(option) => <Text className={classes.option}>{option.label}</Text>}
                            classes={{ container: classes.autocomplete }}
                        />
                        {onCreate && (
                            <Button onClick={onCreate} disabled={disabled}>
                                {translate('Create')}
                            </Button>
                        )}
                    </div>
                </div>
            </div>
            <ul className={classes.list}>
                <VirtualizedList itemSize={itemSize} height={height}>
                    {itemsVisible?.map((item: any) => (
                        <SearchableListItem key={getItemKey(item)} value={item} onItemClick={handleClickItem} selected={item === selectedItem}>
                            {renderItem(item)}
                        </SearchableListItem>
                    ))}
                </VirtualizedList>
            </ul>
        </div>
    );
}

const useStyles = makeStyles((theme) => ({
    container: {
        width: '100%',
        height: '150vh',
        overflowY: 'scroll',
        '&::-webkit-scrollbar': {
            display: 'none',
        },
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        padding: '16px 8px',
    },
    titleContainer: {
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
    },
    searchInput: {
        display: 'flex',
        alignItems: 'center',
        gap: 10,
    },
    selectInput: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
    },
    title: {
        fontFamily: theme.typography.medium,
        color: theme.palette.text.primary,
        fontSize: 20,
        margin: 0,
    },
    list: {
        listStyle: 'none',
        width: '100%',
        padding: 0,
    },
    button: {
        minWidth: 'max-content',
    },
    searchInputContainer: {
        display: 'flex',
        alignItems: 'center',
        gap: 10,
    },
    select: {
        width: 90,
    },
    selectButton: {
        minWidth: 90,
    },
    autocomplete: {
        minWidth: 150,
    },
    option: {
        padding: 6,
    },
}));

type Props = {
    items: Array<any>;
    itemSize: number;
    height: number;
    title: string;
    disabled?: boolean;
    defaultListItemSelected?: any;
    onItemClick?: any;
    onCreate?: any;
    getItemKey: any;
    getItemSearchableBy: (item?: any) => string;
    renderItem: any;
    filter?: {
        name: string;
        label: string;
        options: Array<{
            value: string | null;
            label: string;
        }>;
    };
    onFilterClick?: any;
};
