import { letseatadminApiMethod } from 'src/api/letseatadminApi';
import type { ApiSauceResponse } from 'src/api/types/ApiSauceResponse';
import type { OrderId } from 'src/types/Id';

export async function switchOrderDeliveryProviderToUberDirectApi(request: switchOrderDeliveryProviderToUberDirectApiRequest): ApiSauceResponse<string> {
    return letseatadminApiMethod('delivery/switchOrderDeliveryProviderToUberDirectApi', request);
}

export type switchOrderDeliveryProviderToUberDirectApiRequest = {
    orderId: OrderId;
};
