import { makeStyles } from '@material-ui/core/styles';
import { Loader } from '@pidedirecto/ui';
import moment from 'moment';
import * as React from 'react';
import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { getPosBusinessDayReportApi } from 'src/api/letseatmanager/pos/getPosBusinessDayReportApi';
import { Button } from 'src/components/Button';
import { GoBackButton } from 'src/components/button/GoBackButton';
import { SecuredAndSubscribedPage } from 'src/components/page/SecuredAndSubscribedPage';
import { Switch } from 'src/components/Switch';
import { Tab, Tabs } from 'src/components/Tabs';
import { Text } from 'src/components/Text';
import { RolePermissions } from 'src/constants/RolePermission';
import { RoutePaths } from 'src/constants/RoutePath';
import { translate } from 'src/i18n/translate';
import { CashRegisterPosBusinessDayReportSummary } from 'src/scenes/letseatmanager/posBusinessDayReport/CashRegisterPosBusinessDayReportSummary';
import { PosBusinessDayReportSummary } from 'src/scenes/letseatmanager/posBusinessDayReport/PosBusinessDayReportSummary';
import type { PosBusinessDayId } from 'src/types/Id';
import { PosBusinessDayCashRegisterPosBusinessDaysSummary } from 'src/types/PosBusinessDayReportVm';
import { useLoadApi } from 'src/utils/react/useLoadApi';
import { useParams } from 'src/utils/react/useParams';
import { useSelector } from 'src/utils/react/useSelector';

export function PosBusinessDayReportPage(): React.ReactElement | null {
    const classes = useStyles();
    const history = useHistory();

    const posMultipleCashRegistersEnabled = useSelector((state) => state.app.restaurant?.posMultipleCashRegistersEnabled);
    const salesType = useSelector((state) => state.app.salesTypeFilter);

    const { posBusinessDayId } = useParams<{
        posBusinessDayId: PosBusinessDayId;
    }>();

    const [showCashRegisterPosBusinessDayTabs, setShowCashRegisterPosBusinessDayTabs] = useState(false);

    const [loading, posBusinessDayReport] = useLoadApi(
        getPosBusinessDayReportApi,
        {
            posBusinessDayReportId: posBusinessDayId,
            salesType,
        },
        { requiredValues: [posBusinessDayId], dependencies: [salesType] },
    );

    const goBack = () => {
        history.replace({
            pathname: RoutePaths.RESTAURANT_POS_REPORT,
            search: history.location.search,
        });
    };

    const openHowToReadPosBusinessDayReport = () => window.open('https://s3.amazonaws.com/www.pidedirecto.mx/how_to_understand_eod_report.pdf');

    const tabs: Array<Tab> = [];
    posBusinessDayReport?.cashRegisterPosBusinessDaysSummary?.forEach((cashRegisterPosBusinessDay: PosBusinessDayCashRegisterPosBusinessDaysSummary) => {
        tabs.push({
            label: translate('Cash register @cashRegisterNumber', { cashRegisterNumber: cashRegisterPosBusinessDay?.cashRegisterPosBusinessDayNumber || '' }),
            value: cashRegisterPosBusinessDay?.cashRegisterPosBusinessDayId,
        });
    });

    if (loading) {
        return (
            <div className={classes.loadingContainer}>
                <Loader loading={true} size={40} />
            </div>
        );
    }

    if (!posBusinessDayReport) {
        return (
            <SecuredAndSubscribedPage rolePermission={RolePermissions.REPORTS_PAGE} title={translate('End Of Day Report')}>
                <h1 className={classes.title} style={{ textAlign: 'center', color: 'black' }}>
                    {translate('Was not possible to find the report')}
                </h1>
            </SecuredAndSubscribedPage>
        );
    }

    return (
        <SecuredAndSubscribedPage rolePermission={RolePermissions.REPORTS_PAGE} title={translate('End Of Day Report')} className={classes.pageContainer}>
            <section className={classes.topSection}>
                <GoBackButton goBack={goBack} classes={{ button: classes.goBackButton }} label={translate('Go back to Reports')} />
                {posMultipleCashRegistersEnabled && !!tabs?.length && (
                    <div className={classes.switchContainer}>
                        <Switch
                            onChange={setShowCashRegisterPosBusinessDayTabs}
                            name={'showCashRegisterPosBusinessDayTabs'}
                            value={showCashRegisterPosBusinessDayTabs}
                            label={translate('Summary by cash registers')}
                        ></Switch>
                    </div>
                )}
            </section>
            <section className={classes.titleContainer}>
                <Text title>{translate('Resume @date', { date: moment(posBusinessDayReport.businessDaySummary.closedAt).format('LL') })}</Text>
                <Button classes={{ button: classes.button }} onClick={openHowToReadPosBusinessDayReport} text>
                    {translate('How to read this report?')}
                </Button>
            </section>
            {!showCashRegisterPosBusinessDayTabs && <PosBusinessDayReportSummary posBusinessDayReport={posBusinessDayReport} />}
            {showCashRegisterPosBusinessDayTabs && !!tabs?.length && (
                <Tabs tabs={tabs}>
                    {tabs?.map((tab) => {
                        const cashRegisterPosBusinessDay = posBusinessDayReport?.cashRegisterPosBusinessDaysSummary?.find(
                            (cashRegisterPosBusinessDay: PosBusinessDayCashRegisterPosBusinessDaysSummary) => cashRegisterPosBusinessDay.cashRegisterPosBusinessDayId === tab.value,
                        );
                        if (!cashRegisterPosBusinessDay) return null;
                        return <CashRegisterPosBusinessDayReportSummary cashRegisterPosBusinessDayId={cashRegisterPosBusinessDay?.cashRegisterPosBusinessDayId} />;
                    })}
                </Tabs>
            )}
        </SecuredAndSubscribedPage>
    );
}

const useStyles = makeStyles((theme) => ({
    pageContainer: {
        width: '100%',
        height: '100%',
        [theme.breakpoints.down('sm')]: {
            paddingLeft: 24,
            paddingRight: 24,
        },
    },
    container: {
        width: '100%',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        boxSizing: 'border-box',
    },
    title: {
        fontFamily: theme.typography.semiBold,
        color: theme.palette.primary.main,
        fontSize: 20,
        margin: 0,
    },
    text: {
        fontFamily: theme.typography.regular,
        fontSize: 15,
    },
    row: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        width: '100%',
        justifyContent: 'space-between',
        [theme.breakpoints.down('sm')]: {
            flexDirection: 'column',
            alignItems: 'flex-start',
        },
    },
    topSection: {
        width: '100%',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        marginBottom: 18,
    },
    infoContainer: {
        width: '100%',
        display: 'grid',
        gridTemplateColumns: 'repeat(4, 1fr)',
    },
    article: {
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
    },
    cashRegisterSummary: {
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        [theme.breakpoints.down('sm')]: {
            width: '100%',
        },
    },
    goBackButton: {
        position: 'relative',
        whiteSpace: 'nowrap',
        [theme.breakpoints.down('sm')]: {
            fontSize: 12,
        },
    },
    summaryContainer: {
        display: 'flex',
        flexDirection: 'column',
        width: '70%',
        [theme.breakpoints.down('md')]: {
            width: '100%',
        },
    },
    switchContainer: {
        display: 'flex',
        width: '100%',
        justifyContent: 'end',
    },
    button: {
        marginLeft: 0,
        paddingLeft: 0,
        width: 'fit-content',
        textDecoration: 'underline',
        cursor: 'pointer',
        '&:hover': {
            backgroundColor: 'transparent',
            color: theme.palette.text.primary,
            textDecoration: 'none',
        },
        '&:focus': {
            border: 'unset',
        },
    },
    titleContainer: {
        marginBottom: 18,
    },
    loadingContainer: {
        width: '100',
        height: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
}));
