import { letseatmanagerApiMethodPublic } from 'src/api/letseatmanagerApi';
import type { ApiSauceResponse } from 'src/api/types/ApiSauceResponse';

export async function getServerVersionApi(): ApiSauceResponse<GetServerVersionApiResponse> {
    return letseatmanagerApiMethodPublic('getServerVersionApi');
}

export type GetServerVersionApiResponse = {
    APP_VERSION: string;
    BUILD_TIME: string;
    BRANCH_NAME: string;
    COMMIT_HASH: string;
    COMMIT_AUTHOR_NAME: string;
    COMMIT_AUTHOR_EMAIL: string;
};
