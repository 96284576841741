import { letseatadminApiMethod } from 'src/api/letseatadminApi';
import type { ApiSauceResponse } from 'src/api/types/ApiSauceResponse';
import type { ManagerUserId } from 'src/types/Id';

export async function removeManagerUserApi(request: RemoveManagerUserApiRequest): ApiSauceResponse<void> {
    return letseatadminApiMethod('managerUser/removeManagerUserApi', request);
}

export type RemoveManagerUserApiRequest = {
    managerUserId: ManagerUserId;
};
