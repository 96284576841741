import { letseatmanagerApiMethod } from 'src/api/letseatmanagerApi';
import type { ApiSauceResponse } from 'src/api/types/ApiSauceResponse';
import type { UnitOfMeasurement } from 'src/constants/UnitOfMeasurement';
import type { BuyOrderVm } from 'src/types/BuyOrderVm';
import type { BuyOrderId, ManagerUserId, SupplierId, SupplyId } from 'src/types/Id';

export async function changeBuyOrderApi(request: ChangeBuyOrderApiRequest): ApiSauceResponse<BuyOrderVm> {
    return letseatmanagerApiMethod('buyOrder/changeBuyOrderApi', request);
}

export type ChangeBuyOrderApiRequest = {
    buyOrderId: BuyOrderId;
    supplierId: SupplierId;
    supplies: Array<{
        supplyId: SupplyId;
        unitOfMeasurement: UnitOfMeasurement;
        quantity: string;
        price: string;
    }>;
    managerUserId?: ManagerUserId;
};
