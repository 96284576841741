import { letseatmanagerApiMethod } from 'src/api/letseatmanagerApi';
import type { ApiSauceResponse } from 'src/api/types/ApiSauceResponse';
import { RolePermission } from 'src/constants/RolePermission';
import type { AwsCognitoUsername, EmailAddress, ManagerUserId, PinCode, RestaurantId, UserRoleId } from 'src/types/Id';

export async function getRestaurantUserApi(request: GetRestaurantUserApiRequest): ApiSauceResponse<UserVm> {
    return letseatmanagerApiMethod('userManagement/getRestaurantUserApi', request);
}

type GetRestaurantUserApiRequest = {
    restaurantId: RestaurantId;
    managerUserId: ManagerUserId;
};

type UserVm = {
    managerUserId: ManagerUserId;
    username: AwsCognitoUsername;
    name?: string;
    pinCode?: PinCode;
    email?: EmailAddress;
    userRoleId?: UserRoleId;
    userRolePermissions?: Array<RolePermission>;
    restaurantIds?: Array<RestaurantId>;
};
