import { letseatmanagerApiMethod } from 'src/api/letseatmanagerApi';
import type { ApiSauceResponse } from 'src/api/types/ApiSauceResponse';
import type { RestaurantId } from 'src/types/Id';

export async function getPrinterNameOptionsApi(request: GetPrinterNameOptionsApiRequest): ApiSauceResponse<GetPrinterNameOptionsApiResponse> {
    return letseatmanagerApiMethod('options/getPrinterNameOptionsApi', request);
}

type GetPrinterNameOptionsApiRequest = {
    restaurantId: RestaurantId;
};

export type GetPrinterNameOptionsApiResponse = Array<PrinterNameOptionVm>;

export type PrinterNameOptionVm = {
    label: string;
    value: string;
};
