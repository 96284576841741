import type { PageContextType } from 'src/components/Page';
import { LogEventTypes } from 'src/constants/LogEventType';
import { createUiInteractionLogEvent } from 'src/services/logEvent/createUiInteractionLogEvent';
import { normalizeUiStackTrace } from 'src/services/logEvent/normalizeUiStackTrace';

export function createUserClickedCheckBoxLogEvent({ pageContext, label }: Params): void {
    createUiInteractionLogEvent({
        logEventType: LogEventTypes.USER_CLICKED_CHECKBOX,
        message: `checkbox ${label} clicked`,
        details: `${pageContext.stackTrace}/${normalizeUiStackTrace(`checkbox_${label}`)}`,
        data: {
            label,
            pathId: `${pageContext.stackTrace}/${normalizeUiStackTrace(`checkbox_${label}`)}`,
            checkBoxId: normalizeUiStackTrace(`checkbox_${label}`),
        },
    });
}

type Params = {
    label: string;
    pageContext: PageContextType;
};
