import * as React from 'react';

export function WarningIcon({ title, color }: Props): React.ReactElement {
    return (
        <svg width='21' height='19' viewBox='0 0 21 19' fill='none' xmlns='http://www.w3.org/2000/svg'>
            <title>{title}</title>
            <path
                d='M10.5 7.50001V11.25M1.197 14.626C0.331004 16.126 1.414 18 3.145 18H17.855C19.585 18 20.668 16.126 19.803 14.626L12.449 1.87801C11.583 0.378006 9.417 0.378006 8.551 1.87801L1.197 14.626ZM10.5 14.25H10.507V14.258H10.5V14.25Z'
                stroke={color || '#59491D'}
                strokeWidth='1.5'
                strokeLinecap='round'
                strokeLinejoin='round'
            />
        </svg>
    );
}
type Props = {
    title?: string;
    color?: string;
};
