import { letseatmanagerApiMethod } from 'src/api/letseatmanagerApi';
import type { ApiSauceResponse } from 'src/api/types/ApiSauceResponse';
import type { TicketSection } from 'src/constants/TicketSection';
import type { RestaurantId } from 'src/types/Id';

export async function changeTicketSectionsApi(request: ChangeTicketSectionsRequest): ApiSauceResponse<void> {
    return letseatmanagerApiMethod('restaurant/changeTicketSectionsApi', request);
}

type ChangeTicketSectionsRequest = {
    restaurantId: RestaurantId;
    ticketSections: Array<TicketSection>;
    ticketLogoImage?: string;
    numberOfTicketPrints?: number;
    ticketCustomHeader?: string;
    ticketCustomFooter?: string;
};
