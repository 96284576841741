import MUIDataTable from 'mui-datatables';
import * as React from 'react';
import { createFilteredOnDownload } from 'src/utils/mui-datatables/createFilteredOnDownload';

type Props = {
    data?: Array<{
        id: any;
        name: string;
        value: number;
    }>;
    title?: string;
    headerName: string;
    headerValue: string;
};

export default function DataTable({ data, title, headerName, headerValue }: Props): React.ReactElement | null {
    if (!data) {
        return null;
    }

    return (
        <div style={{ marginTop: -60 }}>
            <MUIDataTable
                title={title ?? ''}
                data={data.map((d) => ({
                    id: d.id,
                    name: d.name,
                    value: d.value,
                }))}
                columns={[
                    {
                        name: 'id',
                        label: ' ',
                        options: {
                            display: 'excluded',
                            filter: false,
                        },
                    },
                    {
                        name: 'name',
                        label: headerName,
                        options: {
                            filter: false,
                            searchable: false,
                        },
                    },
                    {
                        name: 'value',
                        label: headerValue,
                        options: {
                            filter: false,
                            searchable: false,
                        },
                    },
                ]}
                options={{
                    responsive: 'standard',
                    tableBodyMaxHeight: '500px',
                    selectableRows: 'none',
                    filterType: 'multiselect',
                    rowsPerPage: 100,
                    filter: false,
                    // search: false,
                    // print: false,
                    // download: false,
                    viewColumns: false,
                    // customToolbar: null,
                    elevation: 0,
                    // @ts-ignore
                    onDownload: createFilteredOnDownload(this),
                }}
            />
        </div>
    );
}
