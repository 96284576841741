import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import { Button, Dialog, DialogActions, Text } from '@pidedirecto/ui';
import { Form } from '@pidedirecto/ui/form';
import { useConfirmDialog, useForm, useNotification } from '@pidedirecto/ui/hooks';
import { UploadIcon } from '@pidedirecto/ui/icons';
import * as React from 'react';
import { useRef, useState } from 'react';
import { importRecipesApi } from 'src/api/letseatmanager/inventory/importRecipesApi';
import { translate } from 'src/i18n/translate';
import { alertKnownErrorOrSomethingWentWrong } from 'src/utils/alert/alertKnownErrorOrSomethingWentWrong';
import { convertCsvToImportedRecipes, ImportedRecipe } from 'src/utils/csv/convertCsvToImportedRecipes';
import { objectsToCsv } from 'src/utils/csv/objectsToCsv';
import { downloadTextInFile } from 'src/utils/html/downloadTextInFile';
import { useSelector } from 'src/utils/react/useSelector';

export function ImportRecipesFromCsvDialog({ open, onClose, onSuccess }: Props): React.ReactElement {
    const classes = useStyles();
    const form = useForm();
    const inputRef = useRef<HTMLInputElement | null>(null);
    const notification = useNotification();
    const confirmDialog = useConfirmDialog();
    const {
        formState: { isSubmitting },
    } = form;

    const [importing, setImporting] = useState(false);
    const [importedRecipes, setImportedRecipes] = useState<Array<ImportedRecipe>>([]);
    const [fileName, setFileName] = useState<null | string>(null);

    const restaurantId = useSelector((state) => state.app.restaurantId);

    const importCsv = () => {
        if (importing) return;
        inputRef.current?.click();
    };

    const handleUploadCsv = async (event: React.ChangeEvent<HTMLInputElement>) => {
        if (!event?.target?.files || event?.target?.files.length === 0) return;

        setImporting(true);
        setFileName(event.target.files[0]?.name);
        const { importedRecipes, invalidRecipes } = await convertCsvToImportedRecipes(event.target.files[0] as any);
        if (invalidRecipes.length > 0) {
            const confirm = await confirmDialog({
                title: translate('Import Error'),
                content: translate('@validQuantityData supplies were imported, @invalidQuantityData failed. Download the failed recipes to retry', {
                    validQuantityData: importedRecipes.length,
                    invalidQuantityData: invalidRecipes.length,
                }),
                acceptButtonText: translate('Get file'),
                cancelButtonText: translate('Try again'),
                variant: 'warning',
            });
            if (confirm) downloadTextInFile(objectsToCsv(invalidRecipes), 'invalidRecipes.csv');
        }
        if (importedRecipes?.length > 0) setImportedRecipes(importedRecipes);
        setImporting(false);
    };

    const handleClickExampleFile = async () => {
        const data = [{ NOMBRE_RECETA: 'Tarta de manzana', SKU_INGREDIENTE: '1234', CANTIDAD: '2' }];
        downloadTextInFile(objectsToCsv(data), 'recipesTemplate.csv');
    };

    const handleClose = () => {
        if (isSubmitting) return;
        setImportedRecipes([]);
        setFileName(null);
        onClose();
    };

    const onSubmit = async () => {
        if (!importedRecipes) return;
        const response = await importRecipesApi({ restaurantId, importedRecipes });

        if (!response.ok) {
            alertKnownErrorOrSomethingWentWrong(response);
            return;
        }
        notification({ message: translate('Import successful! @quantity recipes loaded', { quantity: importedRecipes.length }) });
        onSuccess?.();
        handleClose();
    };

    return (
        <Dialog open={open} onClose={handleClose} title={translate('Import recipes From CSV File')}>
            <Form onSubmit={onSubmit} form={form} classes={{ form: classes.form }}>
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <Button variant={'secondary'} classes={{ button: classes.importMenuButton }} onClick={importCsv}>
                            <UploadIcon size={50} title={translate('Upload icon')} />
                            <span className={classes.text}>{translate('Select CSV File')}</span>
                        </Button>
                        <input style={{ display: 'none' }} type='file' ref={inputRef} onChange={handleUploadCsv} accept='.csv' data-testid='csvFileInput' />
                        {fileName && <Text> {fileName}</Text>}
                    </Grid>
                    <Grid item xs={12}>
                        <div className={classes.infoText}>
                            <a onClick={handleClickExampleFile} className={classes.urlContainer}>
                                {translate('Download')}
                            </a>
                            <Text>{translate('the sample template')}</Text>
                        </div>
                    </Grid>
                </Grid>
                <DialogActions className={classes.buttonsContainer}>
                    <Button variant={'secondary'} onClick={handleClose} disabled={isSubmitting}>
                        {translate('Cancel')}
                    </Button>
                    <Button type='submit' disabled={isSubmitting || !fileName}>
                        {isSubmitting ? translate('Importing') : translate('Import')}
                    </Button>
                </DialogActions>
            </Form>
        </Dialog>
    );
}

const useStyles = makeStyles((theme) => ({
    form: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    buttonsContainer: {
        display: 'flex',
        justifyContent: 'center',
        [theme.breakpoints.down('sm')]: {
            flexDirection: 'column',
        },
    },
    importMenuButton: {
        width: '100%',
        height: 150,
        backgroundColor: theme.palette.secondary.light,
        border: `1px solid ${theme.palette.secondary.dark}`,
        margin: '0 auto',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        borderRadius: 15,
        cursor: 'pointer',
        flexDirection: 'column',
    },
    text: {
        fontFamily: theme.typography.regular,
        fontSize: 15,
        margin: 10,
    },
    infoText: {
        display: 'flex',
        gap: 5,
        justifyContent: 'center',
        alignItems: 'center',
        fontFamily: theme.typography.regular,
    },
    urlContainer: {
        textDecoration: 'underline',
        cursor: 'pointer',
        color: theme.palette.primary.main,
    },
}));

type Props = {
    open: boolean;
    onClose: any;
    onSuccess?: any;
};

type CsvRow = {
    ['NOMBRE_RECETA']: string;
    ['SKU_INGREDIENTE']: string;
    ['CANTIDAD']: string;
};
