import { IconButton, makeStyles, Menu } from '@material-ui/core';
import { MoreVert } from '@material-ui/icons';
import { createContext, useContext } from 'react';
import * as React from 'react';
import { PageContext } from 'src/components/Page';
import { createUserOpenedContextualMenuLogEvent } from 'src/services/logEvent/createUserOpenedContextualMenuLogEvent';
import { classNames } from 'src/utils/react/classNames';

export function ContextualMenu({ disableButton, variant, title, children, onOpen, onClose, customButton, classes: classesProp, closeOnClick }: Props): React.ReactElement {
    const classes = useStyles();
    const pageContext = useContext(PageContext);

    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

    const open = Boolean(anchorEl);

    const handleClick = (event: any) => {
        createUserOpenedContextualMenuLogEvent({ pageContext, title });
        pageContext.clearStackTrace();
        setAnchorEl(event.currentTarget);
        onOpen?.();
        event.preventDefault();
        event.stopPropagation();
    };

    const handleClose = () => {
        setAnchorEl(null);
        onClose?.();
    };

    const childrenWithClose = React.Children.map(children, (child) => {
        return child && React.isValidElement(child) ? React.cloneElement<any>(child, { onClick: handleClose }) : child;
    });

    return (
        <ContextualMenuContext.Provider value={{ closeContextMenu: handleClose }}>
            {customButton ? (
                <div className={classes.customButton} onClick={handleClick}>
                    {customButton}
                </div>
            ) : (
                <IconButton
                    disabled={disableButton}
                    aria-label='menuButton'
                    id='basic-button'
                    aria-controls={open ? 'basic-menu' : undefined}
                    aria-haspopup='true'
                    aria-expanded={open ? 'true' : undefined}
                    onClick={handleClick}
                    role='button'
                    className={classNames({ [classes.squareButton]: variant === 'square' }, classesProp?.button)}
                >
                    <MoreVert titleAccess={title ?? 'options'} />
                </IconButton>
            )}
            <Menu
                id='basic-menu'
                anchorEl={anchorEl}
                open={open}
                elevation={0}
                onClose={handleClose}
                role='menu'
                MenuListProps={{
                    'aria-labelledby': 'basic-button',
                }}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                transformOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                classes={{ paper: classes.menuOptions, list: classNames((classes as any).list, classesProp?.list) }}
            >
                {closeOnClick ? childrenWithClose : children}
            </Menu>
        </ContextualMenuContext.Provider>
    );
}

export const ContextualMenuContext = createContext<{ closeContextMenu: () => void }>({ closeContextMenu: () => {} });

const useStyles = makeStyles((theme) => ({
    squareButton: {
        height: 50,
        width: 30,
        padding: 0,
        borderRadius: 5,
        border: `1px solid ${theme.palette.surface.secondary}`,
        [theme.breakpoints.up('sm')]: {
            width: 38,
        },
    },
    menuOptions: {
        boxShadow: '0px 8px 20px -4px rgba(23, 24, 24, 0.12), 0px 4px 4px 0px rgba(0, 0, 0, 0.25)',
    },
    customButton: {
        cursor: 'pointer',
        display: 'inline',
    },
}));

type Props = {
    disableButton?: boolean;
    closeOnClick?: boolean;
    variant?: 'square';
    title?: string;
    children: React.ReactNode;
    onOpen?: any;
    onClose?: any;
    customButton?: React.ReactNode;
    classes?: {
        list?: string;
        button?: string;
    };
};
