import { letseatmanagerApiMethod } from 'src/api/letseatmanagerApi';
import type { ApiSauceResponse } from 'src/api/types/ApiSauceResponse';
import type { BuyOrderStatus } from 'src/constants/BuyOrderStatus';
import type { UnitOfMeasurement } from 'src/constants/UnitOfMeasurement';
import type { BuyOrderVm } from 'src/types/BuyOrderVm';
import type { ManagerUserId, RestaurantId, SupplierId, SupplyId } from 'src/types/Id';

export async function createBuyOrderApi(request: CreateBuyOrderApiRequest): ApiSauceResponse<BuyOrderVm> {
    return letseatmanagerApiMethod('buyOrder/createBuyOrderApi', request);
}

export type CreateBuyOrderApiRequest = {
    restaurantId: RestaurantId;
    buyOrderStatus: BuyOrderStatus;
    supplierId: SupplierId;
    supplies: Array<{
        supplyId: SupplyId;
        unitOfMeasurement: UnitOfMeasurement;
        quantity: string;
        price: string;
    }>;
    managerUserId?: ManagerUserId;
    email?: string;
};
