import { DialogContent, makeStyles } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import SignalWifiOffIcon from '@material-ui/icons/SignalWifiOff';
import { useEffect } from 'react';
import * as React from 'react';
import { app2 } from 'src/app2';
import { Sounds } from 'src/constants/Sound';
import { translate } from 'src/i18n/translate';
import { useNotification } from 'src/services/notification/useNotification';
import { useAction } from 'src/utils/react/useAction';
import { useSelector } from 'src/utils/react/useSelector';
import { playAlertSound } from 'src/utils/sound/playAlertSound';

export function NoInternetConnectionDialog(): React.ReactElement {
    const classes = useStyles();

    const open = useSelector((state) => state.app2.noInternetConnectionDialog.open);
    const notification = useNotification();

    const closeNoInternetConnectionDialog = useAction(app2.actions.closeNoInternetConnectionDialog);

    useEffect(() => {
        if (open) playAlertSound(Sounds.DEFAULT_SOUND);
        if (open) notification({ message: translate("A network problem has occurred. Check your computer's connection and try again. If it happens again, please contact support") });
    }, [open]);

    const handleClose = () => closeNoInternetConnectionDialog();

    return (
        <Dialog fullWidth open={open} onClose={handleClose} classes={{ paper: classes.paper }}>
            <DialogContent className={classes.container}>
                <div className={classes.iconContainer}>
                    <SignalWifiOffIcon className={classes.icon} />
                </div>
                <p className={classes.text}>{translate('No internet connection!')}</p>
            </DialogContent>
        </Dialog>
    );
}

const useStyles = makeStyles((theme) => ({
    paper: {
        borderRadius: 12,
        width: '80vh',
        height: '80vh',
        maxWidth: '100vw',
    },
    container: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100%',
    },
    iconContainer: {
        width: 200,
        height: 200,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        borderRadius: 300,
        backgroundColor: theme.palette.secondary.light,
    },
    icon: {
        fontSize: 100,
        color: theme.palette.secondary.contrastText,
    },
    text: {
        fontFamily: theme.typography.semiBold,
        fontSize: 25,
        textAlign: 'center',
        width: '60%',
        margin: '40px 0',
        [theme.breakpoints.down('sm')]: {
            width: '80%',
        },
    },
}));
