import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Grid from '@material-ui/core/Grid';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { changeDriverManualEarningApi } from 'src/api/letseatadmin/driverManualEarning/changeDriverManualEarningApi';
import { getDriverManualEarningApi } from 'src/api/letseatadmin/driverManualEarning/getDriverManualEarningApi';
import { Form } from 'src/components/form/Form';
import { FormDriverManualEarningReasonSelect } from 'src/components/form/FormDriverManualEarningReasonSelect';
import { FormFixErrorHint } from 'src/components/form/FormFixErrorHint';
import { FormTextField } from 'src/components/form/FormTextField';
import { translate } from 'src/i18n/translate';
import { appLayout } from 'src/theme/AppLayout';
import type { DriverManualEarningId } from 'src/types/Id';
import { alertKnownErrorOrSomethingWentWrong } from 'src/utils/alert/alertKnownErrorOrSomethingWentWrong';
import { requireValue } from 'src/utils/require/requireValue';

export function ChangeDriverManualEarningDialog({ open, driverManualEarningId, onClose, onChangeDriverManualEarning }: Props): React.ReactElement {
    const form = useForm();
    const {
        formState: { isSubmitting },
    } = form;
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        if (open) load();
    }, [open]);

    const handleClose = () => {
        if (isSubmitting) return;
        onClose();
    };

    const handleExited = () => {
        setLoading(false);
    };

    const load = async () => {
        setLoading(true);
        const response = await getDriverManualEarningApi({ driverManualEarningId: requireValue(driverManualEarningId) });
        if (!response.ok) {
            setLoading(false);
            alertKnownErrorOrSomethingWentWrong(response);
            onClose();
            return;
        }
        const driverManualEarning = response.data;
        form.reset(driverManualEarning);
        setLoading(false);
    };

    const onSubmit = async (form: any) => {
        const response = await changeDriverManualEarningApi({
            driverManualEarningId: requireValue(driverManualEarningId),
            title: form.title,
            body: form.body,
            comment: form.comment,
            amount: form.amount,
            reason: form.reason,
        });
        if (!response.ok) {
            alertKnownErrorOrSomethingWentWrong(response);
            return;
        }
        onChangeDriverManualEarning();
        onClose();
    };

    return (
        <Dialog fullWidth scroll='paper' open={open} onClose={handleClose} onExited={handleExited}>
            <Form form={form} onSubmit={onSubmit} style={appLayout.dialog.form}>
                <DialogTitle>{translate('Change DriverManualEarning').toUpperCase()}</DialogTitle>
                <DialogContent>
                    <FormContent loading={loading} />
                    <FormFixErrorHint />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} disabled={isSubmitting}>
                        {translate('Cancel').toUpperCase()}
                    </Button>
                    <Button color='primary' type='submit' disabled={loading || isSubmitting}>
                        {isSubmitting ? translate('Changing').toUpperCase() : translate('Change').toUpperCase()}
                    </Button>
                </DialogActions>
            </Form>
        </Dialog>
    );
}

function FormContent({ loading }: { loading: boolean }) {
    return (
        <Grid container spacing={3}>
            <Grid item xs={12}>
                <FormTextField name='title' label={translate('Title')} required />
            </Grid>
            <Grid item xs={12}>
                <FormTextField name='body' label={translate('Body')} />
            </Grid>
            <Grid item xs={12}>
                <FormTextField name='comment' label={translate('Comment')} />
            </Grid>
            <Grid item xs={12}>
                <FormTextField name='amount' label={translate('Amount')} required />
            </Grid>
            <Grid item xs={12}>
                <FormDriverManualEarningReasonSelect name='reason' label={translate('Select the Reason')} disabled={false} />
            </Grid>
        </Grid>
    );
}

type Props = {
    open: boolean;
    driverManualEarningId: DriverManualEarningId | undefined;
    onClose: any;
    onChangeDriverManualEarning: any;
};
