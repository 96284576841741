import type { FixedInvoiceValues } from 'src/api/letseatadmin/subscription/getSubscriptionApi';
import { letseatadminApiMethod } from 'src/api/letseatadminApi';
import type { ApiSauceResponse } from 'src/api/types/ApiSauceResponse';
import type { SubscriptionId } from 'src/types/Id';

export async function changeFixedInvoiceValuesApi(request: ChangeFixedInvoiceValuesApiRequest): ApiSauceResponse<void> {
    return letseatadminApiMethod('subscription/changeFixedInvoiceValuesApi', request);
}

export type ChangeFixedInvoiceValuesApiRequest = {
    subscriptionId: SubscriptionId;
    fixedInvoiceValuesSubscription?: FixedInvoiceValues;
    fixedInvoiceValuesCommission?: FixedInvoiceValues;
    fixedInvoiceValuesApplication?: FixedInvoiceValues;
    fixedInvoiceValuesDeliveries?: FixedInvoiceValues;
};
