import type { Sound } from 'src/constants/Sound';
import { isMobileApp } from 'src/utils/reactNative/isMobileApp';

export function setShouldPlayAlertSoundMobileApp(shouldPlayAlertSound: boolean, alertSoundToPlay?: Sound) {
    if (isMobileApp()) {
        window.ReactNativeWebView.postMessage(
            JSON.stringify({
                type: 'SET_SHOULD_PLAY_ALERT_SOUND',
                data: { shouldPlayAlertSound, alertSoundToPlay },
            }),
        );
    }
}
