import { Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Button } from '@pidedirecto/ui';
import { DownloadIcon } from '@pidedirecto/ui/icons';
import * as React from 'react';
import { getAppBuildsApi } from 'src/api/letseatmanager/appBuild/getAppBuildsApi';
import type { AppBuildVm } from 'src/api/letseatmanager/appBuild/types/AppBuildVm';
import { Dialog } from 'src/components/Dialog';
import { DialogActions } from 'src/components/DialogActions';
import { Apps } from 'src/constants/App';
import { translate } from 'src/i18n/translate';
import { formatDateStringReadable } from 'src/utils/date/formatDateStringReadable';
import { useLoadApi } from 'src/utils/react/useLoadApi';
import { useSelector } from 'src/utils/react/useSelector';

export function DownloadPideDirectoAdminAppApkDialog({ open, onClose }: Props): React.ReactElement | null {
    const classes = useStyles();

    const timeZone = useSelector((state) => state.app.restaurant.timeZone);

    const [loadingAppBuilds, appBuilds] = useLoadApi(getAppBuildsApi, { pideDirectoApp: Apps.PIDEDIRECTOADMINAPP }, { initialValue: [] });

    const openAppBuild = (appBuild: AppBuildVm) => {
        window.open(appBuild.appBuildUrl, '_blank');
    };

    return (
        <Dialog open={open} onClose={() => onClose()} title={translate('Download PD Admin App')}>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    {appBuilds.length === 0 && translate('No app builds available')}
                    {appBuilds.length > 0 &&
                        appBuilds.map((appBuild, index) => (
                            <Button key={index} onClick={() => openAppBuild(appBuild)} classes={{ button: classes.button }} variant='outline'>
                                <DownloadIcon />
                                <div className={classes.appBuild}>
                                    <span className={classes.appBuildName}>{appBuild.appBuildName}</span>
                                    <span className={classes.appBuildDate}>{formatDateStringReadable(appBuild.appBuildAt, timeZone)}</span>
                                </div>
                            </Button>
                        ))}
                </Grid>
            </Grid>

            <DialogActions>
                <Button onClick={() => onClose(false)} color='primary'>
                    {translate('Close')}
                </Button>
            </DialogActions>
        </Dialog>
    );
}

const useStyles = makeStyles((theme) => ({
    container: {
        position: 'fixed',
        left: 0,
        bottom: 0,
        paddingLeft: 2,
        paddingRight: 2,
        borderTopRightRadius: 4,
    },
    button: {
        display: 'flex',
        alignItems: 'center',
        marginBottom: 8,
        width: '100%',
    },
    appBuild: {
        display: 'flex',
        flexDirection: 'column',
        marginLeft: 8,
    },
    appBuildName: {
        fontFamily: theme.typography.medium,
        fontSize: 16,
    },
    appBuildDate: {
        fontFamily: theme.typography.regular,
        fontSize: 14,
        color: theme.palette.text.secondary,
    },
}));

type Props = {
    open: boolean;
    onClose: any;
};
