import { pidedirectoReportsMethod } from 'src/api/pidedirectoReportsApi';
import type { ApiSauceResponse } from 'src/api/types/ApiSauceResponse';
import { DeliveryTimeCategory } from 'src/constants/DeliveryTimeCategory';
import type { SalesType } from 'src/constants/SalesTypes';
import { type RestaurantId } from 'src/types/Id';

export async function getDeliveryRestaurantStatisticsApi(request: GetDeliveryRestaurantStatisticsRequest): ApiSauceResponse<GetDeliveryStatisticsApiResponse | undefined> {
    return pidedirectoReportsMethod('/deliveryPageReportApi', request);
}

export type GetDeliveryRestaurantStatisticsRequest = {
    report: string;
    reportPage?: string;
    abcTableFilter?: string;
    restaurantIds: Array<RestaurantId>;
    fromDate: string;
    toDate: string;
    salesType: SalesType;
};

export type GetDeliveryStatisticsApiResponse = Array<{
    restaurantId: RestaurantId;
    restaurantName: string;
    kpisByDeliveryTimeCategory: Array<KpisByDeliveryTimeCategory> | undefined;
    totalOrdersByDeliveryTimeCategory: Array<TotalOrdersByDeliveryTimeCategory> | undefined;
    totalOrdersInDeliveryTimeCategoryGroupedByApp: Array<TotalOrdersInDeliveryTimeCategoryGroupedByApp> | undefined;
    totalOrdersInDeliveryTimeCategoryGroupedByDay: Array<TotalOrdersInDeliveryTimeCategoryGroupedByDay> | undefined;
    deliveryOrders: Array<DeliveryOrders> | undefined;
}>;

export type KpisByDeliveryTimeCategory = {
    deliveryTimeCategory: DeliveryTimeCategory;
    averageTime: number;
    orders: number;
    averageDeliveryCost: number;
    percentageOfDeliveryOrders: number;
};

export type TotalOrdersByDeliveryTimeCategory = {
    deliveryTimeCategory: DeliveryTimeCategory;
    orders: number;
};

export type TotalOrdersInDeliveryTimeCategoryGroupedByApp = {
    deliveryTimeCategory: DeliveryTimeCategory;
    orders: number;
    app: string;
};

export type TotalOrdersInDeliveryTimeCategoryGroupedByDay = {
    deliveryTimeCategory: DeliveryTimeCategory;
    orders: number;
    date: string;
};

export type DeliveryOrders = {
    shortOrderId: string;
    drivingDistance: string;
    deliveryTime: number;
    deliveryCost: number;
    total: number;
};

export type AverageDeliveryTimeByDeliveryMethodAndDay = {
    createdAt: string;
    timeAverage: number;
    deliveryMethod: string;
};
