import { makeStyles } from '@material-ui/core';
import * as React from 'react';
import { useLocation } from 'react-router-dom';
import { RoutePaths } from 'src/constants/RoutePath';
import { classNames } from 'src/utils/react/classNames';
import { useSelector } from 'src/utils/react/useSelector';

export function AppContentContainer({ children }: Props): React.ReactElement {
    const classes = useStyles();
    const location = useLocation();

    const signedIn = useSelector((state) => state.authentication.signedIn);
    const kioskUser = useSelector((state) => state.authentication.kioskUser);

    const posUserLockscreen = location.pathname === RoutePaths.PIN_CODE_USER_LOGIN;

    const getAppContainerClassnames = () => {
        let containerClasses = classes.content;

        if (posUserLockscreen) containerClasses = classNames(containerClasses, classes.lockScreen);
        if (location.pathname === RoutePaths.KITCHEN_DISPLAY_SCREEN) containerClasses = classes.kitchenDisplayScreenContainer;

        return containerClasses;
    };

    return (
        <main className={getAppContainerClassnames()} style={!signedIn || !!kioskUser ? { marginTop: 0, padding: 0 } : {}}>
            {children}
        </main>
    );
}

const posLocations = [RoutePaths.POS, RoutePaths.POS_CASH_REGISTERS];

const useStyles = makeStyles((theme) => ({
    content: {
        backgroundColor: (theme.palette.background as any).light,
        width: '100%',
        overflowX: 'hidden',
        overflowY: 'auto',
        paddingTop: 20,
        paddingLeft: theme.spacing(5),
        paddingRight: theme.spacing(5),
        marginTop: 72.5,
        [theme.breakpoints.down('sm')]: {
            paddingTop: theme.spacing(1),
            paddingBottom: theme.spacing(8),
            paddingRight: 0,
            paddingLeft: 0,
            marginTop: 70,
        },
    },
    lockScreen: {
        padding: 0,
        overflow: 'auto',
        marginTop: '0px !important',
    },
    kitchenDisplayScreenContainer: {
        backgroundColor: (theme.palette.background as any).light,
        width: '100%',
        overflowX: 'hidden',
        overflowY: 'auto',
        padding: 26,
    },
}));

type Props = {
    children: React.ReactNode;
};
