import { makeStyles } from '@material-ui/core/styles';
import { useEffect, useState } from 'react';
import * as React from 'react';
import { unlinkInventoryBetweenRestaurantsApi } from 'src/api/letseatmanager/inventory/unlinkInventoryBetweenRestaurantsApi';
import { Button } from 'src/components/Button';
import { Dialog } from 'src/components/Dialog';
import { DialogActions } from 'src/components/DialogActions';
import { Text } from 'src/components/Text';
import { WebSocketEventTypes } from 'src/constants/WebSocketEventType';
import { translate } from 'src/i18n/translate';
import { alertKnownErrorOrSomethingWentWrong } from 'src/utils/alert/alertKnownErrorOrSomethingWentWrong';
import { useSelector } from 'src/utils/react/useSelector';
import { WebSocketEvents } from 'src/utils/webSocket/WebSocketEvents';

export function UnlinkRestaurantToInventoryDialog({ open, onSuccess, onClose }: Props): React.ReactElement {
    const classes = useStyles();

    const [loading, setLoading] = useState(false);

    const restaurantId = useSelector((state) => state.app.restaurantId);
    const inventoryLinkedToRestaurantName = useSelector((state) => state.app.restaurant?.inventoryLinkedToRestaurantName);

    useEffect(() => {
        const unlinkedInventoryBetweenRestaurantsWebSocketEvent = WebSocketEvents.addEventListener(WebSocketEventTypes.RESTAURANT_INVENTORY_UNLINKED, onClose);

        return () => {
            unlinkedInventoryBetweenRestaurantsWebSocketEvent.remove();
        };
    }, []);

    const handleClose = () => {
        if (loading) return;
        onClose();
    };

    const onUnlinkRestaurantToInventory = async () => {
        setLoading(true);
        const response = await unlinkInventoryBetweenRestaurantsApi({
            restaurantId,
        });
        setLoading(false);
        if (!response.ok) {
            alertKnownErrorOrSomethingWentWrong(response);
            onClose();
            return;
        }
        onSuccess();
        onClose();
    };

    return (
        <Dialog open={open} onClose={handleClose} loading={loading} title={translate('Unlink restaurant to inventory')}>
            <div className={classes.dialogContent}>
                <Text>
                    {translate('When the restaurant is unlinked from the inventory of @inventoryLinkedToRestaurantName, the inventory of the restaurant will be left empty', {
                        inventoryLinkedToRestaurantName,
                    })}
                </Text>
            </div>
            <DialogActions className={classes.buttonsContainer}>
                <Button secondary onClick={handleClose} disabled={loading}>
                    {translate('Cancel')}
                </Button>
                <Button onClick={onUnlinkRestaurantToInventory} disabled={loading}>
                    {translate('Unlink')}
                </Button>
            </DialogActions>
        </Dialog>
    );
}

const useStyles = makeStyles((theme) => ({
    dialogContent: {
        textAlign: 'center',
        paddingLeft: 64,
        paddingRight: 64,
    },
    buttonsContainer: {
        marginTop: 20,
        marginBottom: 20,
        width: '100%',
        justifyContent: 'center',
        gap: 20,
        [theme.breakpoints.down('sm')]: {
            flexDirection: 'column',
            alignItems: 'center',
        },
    },
    button: {
        width: '100%',
        [theme.breakpoints.up('sm')]: {
            width: '30%',
        },
    },
}));

type Props = {
    open: boolean;
    onSuccess: any;
    onClose: any;
};
