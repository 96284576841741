import * as React from 'react';

export function CircularCheckedIcon({ color, width, height, title }: Props): React.ReactElement {
    return (
        <svg xmlns='http://www.w3.org/2000/svg' width={width ?? '30'} height={height ?? '30'} viewBox='0 0 30 30' fill='none'>
            {title && <title>{title}</title>}
            <path
                fillRule='evenodd'
                clipRule='evenodd'
                d='M0 15C0 6.71538 6.71538 0 15 0C23.2846 0 30 6.71538 30 15C30 23.2846 23.2846 30 15 30C6.71538 30 0 23.2846 0 15ZM20.5538 12.2092C20.6462 12.0862 20.7129 11.946 20.7503 11.7968C20.7876 11.6477 20.7948 11.4925 20.7713 11.3405C20.7478 11.1886 20.6942 11.0428 20.6136 10.9118C20.533 10.7809 20.427 10.6673 20.3019 10.5779C20.1768 10.4885 20.0351 10.425 19.8851 10.3911C19.7351 10.3573 19.5798 10.3537 19.4284 10.3807C19.277 10.4077 19.1326 10.4647 19.0035 10.5483C18.8745 10.632 18.7634 10.7405 18.6769 10.8677L13.6985 17.8369L11.2 15.3385C10.9813 15.1346 10.692 15.0237 10.393 15.029C10.0941 15.0342 9.80891 15.1553 9.59751 15.3667C9.3861 15.5781 9.265 15.8633 9.25973 16.1623C9.25446 16.4612 9.36542 16.7505 9.56923 16.9692L13.0308 20.4308C13.1492 20.5491 13.292 20.6403 13.4492 20.6979C13.6064 20.7555 13.7743 20.7783 13.9412 20.7645C14.1081 20.7508 14.2699 20.7008 14.4156 20.6182C14.5612 20.5356 14.6872 20.4223 14.7846 20.2862L20.5538 12.2092Z'
                fill={color ?? '#06B7A2'}
            />
        </svg>
    );
}

type Props = {
    width?: string;
    height?: string;
    color?: string;
    title?: string;
    onClick?: any;
};
