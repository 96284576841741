import MomentUtils from '@date-io/moment';
import { makeStyles } from '@material-ui/core/styles';
import { DatePicker, DateTimePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import { SearchIcon } from '@pidedirecto/ui/icons';
import moment from 'moment-timezone';
import { useContext, useEffect, useState } from 'react';
import * as React from 'react';
import { Button } from 'src/components/Button';
import { PageContext } from 'src/components/Page';
import { Tooltip } from 'src/components/Tooltip';
import { translate } from 'src/i18n/translate';
import { XIcon } from 'src/icons/XIcon';
import { createUserTypedInputLogEvent } from 'src/services/logEvent/createUserTypedInputLogEvent';
import { classNames } from 'src/utils/react/classNames';

export function Input({
    onChange,
    disabled,
    type,
    classes: classesProp,
    placeholder,
    label,
    name,
    tooltip,
    defaultValue,
    value: valueProp,
    maxDate,
    minDate,
    validation,
    required,
    minValue,
    step,
    dataTestId,
    autoComplete,
}: Props): React.ReactElement {
    const classes = useStyles();
    const pageContext = useContext(PageContext);

    const [value, setValue] = useState('');
    const [date, setDate] = useState<string | number | Date>(moment().startOf('day').format('YYYY-MM-DD HH:mm:ss'));
    const [dateTime, setDateTime] = useState<string | number | Date>(moment().startOf('day').format('YYYY-MM-DD HH:mm:ss'));
    const [isInputFocused, setIsInputFocused] = useState(false);
    const [error, setError] = useState({ showError: false, errorMessage: translate('There is a format error') });

    const isSearchType = type === 'search';
    const isDateType = type === 'date';
    const isDateTimeType = type === 'datetime-local';

    useEffect(() => {
        if (defaultValue && isDateType) setDate(defaultValue);
    }, []);

    useEffect(() => {
        if (defaultValue && isDateTimeType) setDateTime(defaultValue);
    }, []);

    const handleChange = (event: any) => {
        const value = event.target.value;
        setValue(value);
        onChange?.(value, event);
        setError(validation?.(value) ?? { ...error, showError: false });
    };

    const handleClearInput = () => {
        setValue('');
        onChange?.('');
    };

    const handleChangeDate = (date: any) => {
        setError(validation?.(date) ?? { ...error, showError: false });
        setDate(date);
        onChange?.(date.toDate());
    };

    const handleChangeDateTime = (dateTime: any) => {
        setDateTime(dateTime);
        onChange?.(dateTime.toDate());
    };

    const handleInputBlur = () => {
        let valueMessage = valueProp || value;
        if (valueMessage instanceof Date) valueMessage = valueMessage.toString();
        createUserTypedInputLogEvent({ pageContext, label, name, valueMessage: `${valueMessage}` });
        pageContext.clearStackTrace();
        setIsInputFocused(false);
    };

    return (
        <div className={classNames(classes.inputWrapper, classesProp?.container)}>
            {!!label && (
                <label htmlFor={`${name}-input`} className={classNames(classes.label, classesProp?.label)}>
                    {`${label}${required ? ` *` : ''}`}
                </label>
            )}
            {!isDateType && !isDateTimeType && (
                <div className={classNames(classes.inputContainer, isInputFocused && classes.inputContainerFocused, classesProp?.inputContainer)}>
                    {isSearchType && (
                        <div className={classes.icon}>
                            <SearchIcon color={'#616B79'} />
                        </div>
                    )}
                    <input
                        step={step}
                        id={`${name}-input`}
                        type={type || 'text'}
                        min={minValue}
                        className={classNames(classes.input, classesProp?.input)}
                        name={name}
                        onChange={handleChange}
                        placeholder={placeholder}
                        aria-describedby={`${name}-tooltip`}
                        defaultValue={defaultValue as any}
                        value={valueProp || (value as any)}
                        required={required}
                        disabled={disabled}
                        onFocus={() => setIsInputFocused(true)}
                        onBlur={handleInputBlur}
                        autoComplete={autoComplete || 'off'}
                        data-testid={dataTestId}
                    />
                    {isSearchType && (
                        <Button icon onClick={handleClearInput} classes={{ button: classes.iconButton }}>
                            <XIcon width={16} height={16} />
                        </Button>
                    )}
                </div>
            )}
            {isDateType && (
                <MuiPickersUtilsProvider utils={MomentUtils}>
                    <div className={classNames(classes.datePicker, error.showError && classes.datePickerError, classesProp?.input)}>
                        <DatePicker
                            id={`${name}-input`}
                            variant='inline'
                            name={name}
                            format='ll'
                            autoOk
                            onChange={handleChangeDate}
                            value={valueProp ?? date}
                            maxDate={maxDate}
                            minDate={minDate}
                            fullWidth
                        />
                    </div>
                </MuiPickersUtilsProvider>
            )}
            {isDateTimeType && (
                <MuiPickersUtilsProvider utils={MomentUtils}>
                    <div className={classNames(classes.datePicker, error.showError && classes.datePickerError, classesProp?.input)}>
                        <DateTimePicker
                            id={`${name}-input`}
                            variant='inline'
                            name={name}
                            format='lll'
                            autoOk
                            onChange={handleChangeDateTime}
                            value={valueProp ?? dateTime}
                            maxDate={maxDate}
                            minDate={minDate}
                            fullWidth
                        />
                    </div>
                </MuiPickersUtilsProvider>
            )}
            {tooltip && !error.showError && (
                <div className={classes.tooltip}>
                    <Tooltip text={tooltip} classes={{ text: classes.tooltipText }} id={`${name}-tooltip`} />
                </div>
            )}
            {error.showError && (
                <div className={classes.tooltipWarning}>
                    <Tooltip text={error.errorMessage} classes={{ text: classes.tooltipErrorText }} id={`${name}-tooltip`} type={'error'} />
                </div>
            )}
        </div>
    );
}

const useStyles = makeStyles((theme) => ({
    inputWrapper: {
        position: 'relative',
        width: '250px',
        display: 'flex',
        flexDirection: 'column',
    },
    label: {
        fontFamily: theme.typography.light,
        color: theme.palette.text.primary,
        fontSize: 14,
        paddingBottom: 5,
    },
    inputContainer: {
        height: '42px',
        padding: 15,
        width: '100%',
        outline: `1px solid ${theme.palette.border.primary}`,
        backgroundColor: 'transparent',
        borderRadius: '4px',
        position: 'relative',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        gap: 10,
        boxSizing: 'border-box',
    },
    inputContainerFocused: {
        outline: `2px solid ${theme.palette.surface.brandSecondary}`,
    },
    input: {
        boxSizing: 'border-box',
        color: theme.palette.text.primary,
        width: '100%',
        fontFamily: theme.typography.light,
        border: 0,
        backgroundColor: 'transparent',
        outline: 0,
    },
    iconButton: {
        height: 'fit-content',
    },
    icon: {
        color: theme.palette.icons.primary,
        width: 20,
        height: 20,
    },
    tooltip: {
        position: 'absolute',
        width: '20px',
        height: '20px',
        left: '90%',
        top: '40%',
        color: theme.palette.text.contrast,
        zIndex: 100000,
    },
    tooltipWarning: {
        position: 'absolute',
        width: '20px',
        height: '20px',
        left: '88%',
        top: '50%',
        color: theme.palette.surface.contrast,
        zIndex: 500,
    },
    tooltipText: {
        backgroundColor: theme.palette.surface.heavyContrast,
        fontWeight: 'bold',
    },

    tooltipErrorText: {
        backgroundColor: theme.palette.border.danger,
        fontWeight: 'bold',
    },
    datePicker: {
        backgroundColor: 'transparent',
        borderRadius: '4px',
        padding: '5px 0 5px 15px',
        border: '1px solid #ccc',

        '& .MuiInputBase-root': {
            fontFamily: `${theme.typography.regular} !important`,
            fontSize: 14,
            borderRadius: '4px',
            backgroundColor: 'transparent',
        },
        '& .MuiInputBase-root::before': {
            display: 'none',
        },
        '& .MuiInputBase-root::after': {
            display: 'none',
        },
    },
    datePickerError: {
        border: `1px solid ${theme.palette.border.danger}`,
    },
}));

type Props = {
    name: string;
    type?: string;
    value?: number | Date | string;
    placeholder?: string;
    label?: string;
    autoComplete?: string;
    tooltip?: string;
    required?: boolean;
    disabled?: boolean;
    defaultValue?: number | Date | string;
    minValue?: number;
    step?: number;
    maxDate?: Date;
    minDate?: Date;
    errorMessage?: string;
    classes?: {
        inputContainer?: string;
        container?: string;
        label?: string;
        input?: string;
        tooltipText?: string;
    };
    onChange?: any;
    validation?: any;
    dataTestId?: string;
};
