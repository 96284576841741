import { makeStyles } from '@material-ui/core';
import { useState } from 'react';
import * as React from 'react';
import { useForm } from 'react-hook-form';
import { updateTableOrderApi } from 'src/api/letseatmanager/order/updateTableOrderApi';
import { Button } from 'src/components/Button';
import { Dialog } from 'src/components/Dialog';
import { DialogActions } from 'src/components/DialogActions';
import { Form } from 'src/components/form/Form';
import { FormAvailableTablesSelect } from 'src/components/form/FormAvailableTablesSelect';
import { translate } from 'src/i18n/translate';
import type { OrderId } from 'src/types/Id';
import { alertKnownErrorOrSomethingWentWrong } from 'src/utils/alert/alertKnownErrorOrSomethingWentWrong';
import { useSelector } from 'src/utils/react/useSelector';

export function ChangeTableOfOrderDialog({ open, onClose, orderId }: Props): React.ReactElement {
    const classes = useStyles();
    const form = useForm();

    const [loading, setLoading] = useState(false);
    const refreshRestaurantZones = useSelector((state) => state.pos.refreshRestaurantZones);

    const handleClose = () => {
        if (loading) return;
        onClose();
    };

    const handleSubmit = async (form: any) => {
        if (!orderId) return;

        setLoading(true);
        const response = await updateTableOrderApi({
            orderId,
            restaurantZoneId: form.restaurantZoneId,
            table: form.table,
        });
        setLoading(false);

        if (!response.ok) {
            return alertKnownErrorOrSomethingWentWrong(response);
        }

        refreshRestaurantZones();
        onClose();
    };

    return (
        <Dialog classes={{ dialog: classes.dialog }} open={open} onClose={handleClose} title={translate('Change Table of Order')}>
            <Form onSubmit={handleSubmit} form={form}>
                <FormAvailableTablesSelect />
                <DialogActions className={classes.dialogActions}>
                    <Button classes={{ button: classes.button }} secondary onClick={handleClose} disabled={loading}>
                        {translate('Cancel')}
                    </Button>
                    <Button classes={{ button: classes.button }} type={'submit'} disabled={loading}>
                        {translate('Change')}
                    </Button>
                </DialogActions>
            </Form>
        </Dialog>
    );
}

const useStyles = makeStyles((theme) => ({
    dialog: {
        minWidth: 400,
        [theme.breakpoints.down('sm')]: {
            minWidth: '90vw',
        },
    },
    dialogActions: {
        marginTop: 20,
    },
    button: {
        width: '100%',
    },
}));

type Props = {
    open: boolean;
    onClose: any;
    orderId?: OrderId;
};
