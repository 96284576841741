export const PaymentLinkStatuses = {
    UNPAID: 'UNPAID',
    PAID: 'PAID',
    PAYED: 'PAYED', // TODO: Migrate to PAID
    REFUNDED: 'REFUNDED',
    FAILED: 'FAILED',
    CANCELLED: 'CANCELLED',
} as const;

export type PaymentLinkStatus = (typeof PaymentLinkStatuses)[keyof typeof PaymentLinkStatuses];
