import { PaymentMethod, PaymentMethods } from 'src/constants/PaymentMethod';
import { isString } from 'src/utils/string/isString';

export function requirePaymentMethod<T extends string>(value: T | undefined | null): PaymentMethod {
    if (value === undefined) {
        throw new Error('Required value is undefined when it should be a PaymentMethod');
    }
    if (value === null) {
        throw new Error('Required value is null when it should be a PaymentMethod');
    }
    if (!isString(value)) {
        throw new Error(`Required value is ${value} when it should be a PaymentMethod`);
    }
    if (!Object.values(PaymentMethods).includes(value as PaymentMethod)) {
        throw new Error(`Required value is "${value as any}" when it should be a PaymentMethod`);
    }
    return value as any;
}
