import { letseatadminApiMethod } from 'src/api/letseatadminApi';
import type { ApiSauceResponse } from 'src/api/types/ApiSauceResponse';
import { OrderIncidentType } from 'src/constants/OrderIncidentType';
import { ManagerUserId, OrderId, ZendeskId } from 'src/types/Id';

export async function createOrderIncidentApi(request: CreateOrderIncidentApiRequest): ApiSauceResponse<void> {
    return letseatadminApiMethod('orderIncident/createOrderIncidentApi', request);
}

export type CreateOrderIncidentApiRequest = {
    orderId: OrderId;
    managerUserId: ManagerUserId;
    zendeskId: ZendeskId;
    orderIncidentType: OrderIncidentType;
    refundAmount: string;
    imageUrl: string;
};
