import { makeStyles } from '@material-ui/core';
import { Select } from '@pidedirecto/ui';
import * as React from 'react';
import { app2 } from 'src/app2';
import { CostType, CostTypes } from 'src/constants/CostType';
import { translate } from 'src/i18n/translate';
import { useAction } from 'src/utils/react/useAction';
import { useSelector } from 'src/utils/react/useSelector';

export function FilterCostType({ disabled, onChange, position }: Props): React.ReactElement {
    const classes = useStyles();

    const costType = useSelector((state) => state.app2.filterReportsState.costType);

    const setReportsCostType = useAction(app2.actions.setReportsCostType);

    const handleChangeColumns = (costType: CostType) => {
        setReportsCostType(costType);
        onChange?.(costType);
    };

    const options = Object.values(CostTypes).map((costType) => ({
        label: translate(`CostTypes.${String(costType)}`),
        value: String(costType),
    }));

    return (
        <Select
            value={costType}
            placeholder={translate('Cost Type')}
            name='costType'
            options={options}
            onChange={handleChangeColumns}
            disabled={disabled}
            position={position}
            classes={{ button: classes.button, container: classes.container, selectContainer: classes.container, item: classes.option }}
        />
    );
}

const useStyles = makeStyles((theme) => ({
    container: {
        width: 'max-content',
    },
    button: {
        width: 'max-content',
    },
    option: {
        width: '100%',
        minWidth: 'max-content',
    },
}));

type Props = {
    disabled?: boolean;
    position?: 'right' | 'left';
    onChange?: (costType: CostType) => void;
};
