import { pidedirectoReportsMethod } from 'src/api/pidedirectoReportsApi';
import type { ApiSauceResponse } from 'src/api/types/ApiSauceResponse';
import type { RestaurantId } from 'src/types/Id';
import type { SupplyHistoryReportVm } from 'src/types/SupplyHistoryReportVm';

export async function getSupplyHistoryReportApi(request: GetSupplyHistoryReportApiRequest): ApiSauceResponse<SupplyHistoryReportVm> {
    return pidedirectoReportsMethod('/getSupplyHistoryReportApi', request);
}

type GetSupplyHistoryReportApiRequest = {
    restaurantIds: Array<RestaurantId>;
    fromDate: string;
    toDate: string;
};
