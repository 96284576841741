import { makeStyles } from '@material-ui/core/styles';
import VisibilityOnIcon from '@material-ui/icons/Visibility';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import { Button } from '@pidedirecto/ui';
import { BackspaceIcon, CircleFilledCheckIcon } from '@pidedirecto/ui/icons';
import { BigNumber } from 'bignumber.js';
import { useEffect, useState } from 'react';
import * as React from 'react';
import { NumericKey } from 'src/components/NumericKey';
import { classNames } from 'src/utils/react/classNames';

export function NumericPad({ onSubmit, onChange, isPassword }: Props): React.ReactElement {
    const classes = useStyles();

    const [numericPadValue, setNumericPadValue] = useState<string>();
    const [visibility, setVisibility] = useState(!isPassword);

    const displayInput = isPassword ? visibility : true;

    useEffect(() => {
        document.addEventListener('keypress', handleKeyboard);
        return () => {
            document.removeEventListener('keypress', handleKeyboard);
        };
    }, [numericPadValue]);

    const handleKeyboard = (e: KeyboardEvent) => {
        const { key } = e;

        if (key === 'Enter') return handleContinue();
        if (BigNumber(key).isNaN()) return;

        onClickNumber(key);
    };

    const onClickNumber = (number: string) => {
        setNumericPadValue((previousNumericPadValue) => {
            return previousNumericPadValue ? previousNumericPadValue + number : number;
        });

        onChange?.();
    };

    const onDelete = () => {
        setNumericPadValue((previousNumericPadValue) => {
            return previousNumericPadValue?.toString().slice(0, -1);
        });
    };

    const handleContinue = () => {
        onSubmit(numericPadValue);
    };

    const maskPassword = (password: string | undefined) => password?.replace(/[^\s]/g, '*');

    return (
        <div className={classes.container}>
            <div className={classNames(classes.display, isPassword ? classes.passwordDisplay : classes.numericDisplay)}>
                <div aria-label={'display'}>{displayInput ? numericPadValue : maskPassword(numericPadValue)}</div>
                {isPassword && !visibility && <VisibilityOnIcon titleAccess={'visibilityOnIcon'} style={{ marginLeft: 'auto', marginRight: 10 }} onClick={() => setVisibility(!visibility)} />}
                {isPassword && visibility && <VisibilityOffIcon titleAccess={'visibilityOffIcon'} style={{ marginLeft: 'auto', marginRight: 10 }} onClick={() => setVisibility(!visibility)} />}
            </div>
            <div className={classes.numpad}>
                <NumericKey value={'1'} onPress={onClickNumber} />
                <NumericKey value={'2'} onPress={onClickNumber} />
                <NumericKey value={'3'} onPress={onClickNumber} />
                <NumericKey value={'4'} onPress={onClickNumber} />
                <NumericKey value={'5'} onPress={onClickNumber} />
                <NumericKey value={'6'} onPress={onClickNumber} />
                <NumericKey value={'7'} onPress={onClickNumber} />
                <NumericKey value={'8'} onPress={onClickNumber} />
                <NumericKey value={'9'} onPress={onClickNumber} />
                <Button title={'delete'} variant={'icon'} onClick={onDelete}>
                    <BackspaceIcon size={20} />
                </Button>
                <NumericKey value={'0'} onPress={onClickNumber} />
                <Button title={'continue'} variant={'icon'} onClick={handleContinue}>
                    <CircleFilledCheckIcon size={20} />
                </Button>
            </div>
        </div>
    );
}

const useStyles = makeStyles((theme) => ({
    container: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
    },
    numpad: {
        minWidth: 230,
        display: 'grid',
        gridTemplateColumns: 'repeat(3, 1fr)',
        rowGap: 10,
        justifyItems: 'center',
        [theme.breakpoints.up('sm')]: {
            columnGap: 10,
        },
    },
    display: {
        width: '100%',
        minWidth: 230,
        height: 60,
        paddingInline: 15,
        borderRadius: 10,
        margin: '5px 0',
        border: `1px solid ${theme.palette.secondary.contrastText}30`,
        fontFamily: theme.typography.regular,
        fontSize: 25,
        color: `${theme.palette.secondary.contrastText}90`,
        [theme.breakpoints.up('sm')]: {
            margin: '15px 0',
        },
    },
    numericDisplay: {
        display: 'flex',
        alignItems: 'center',
    },
    passwordDisplay: {
        display: 'grid',
        gridTemplateColumns: '75% 30%',
        alignItems: 'center',
    },
}));

type Props = {
    onSubmit: any;
    onChange?: any;
    isPassword?: boolean;
};
