import { makeStyles } from '@material-ui/core';
import * as React from 'react';
import { Button } from 'src/components/Button';
import { DiscountTypes } from 'src/constants/DiscountType';
import { translate } from 'src/i18n/translate';
import { CircleCloseIcon } from 'src/icons/CircleCloseIcon';
import { posReducer } from 'src/reducers/posReducer';
import { useFormatAsRestaurantCurrencyNumber } from 'src/services/restaurant/useFormatAsRestaurantCurrencyNumber';
import { CartItemVm } from 'src/types/CartItemVm';
import { useAction } from 'src/utils/react/useAction';
import { useSelector } from 'src/utils/react/useSelector';

export function PosPaymentPromoCodeItem(): React.ReactElement | null {
    const classes = useStyles();
    const formatAsCurrencyNumber = useFormatAsRestaurantCurrencyNumber();

    const { promoCode, promoCodeCredits, promoCodeDiscount } = useSelector((state) => state.pos.payment);
    const promoCodeApplied = useSelector((state) => state.pos.promoCode);
    const items = useSelector((state) => state.pos.items);
    const acceptMultipleCurrenciesPaymentEnabled = useSelector((state) => state.pos.context?.acceptMultipleCurrenciesPaymentEnabled);
    const mainCurrency = useSelector((state) => state.app.restaurant?.currencyFormat?.currency);

    const removePosPromoCode = useAction(posReducer.actions.removePosPromoCode);

    const applicableItems = promoCodeApplied ? items?.filter((item: CartItemVm) => promoCodeApplied.menuItemIds?.includes(item.menuItemId)) : [];

    const getPromoCodeText = () => {
        if (!promoCode) return '';

        if (promoCodeCredits) return `${promoCode}: ${promoCodeCredits}`;

        return `${promoCode} - ${formatAsCurrencyNumber(promoCodeDiscount)} ${acceptMultipleCurrenciesPaymentEnabled ? `(${mainCurrency})` : ''}`;
    };

    const getPromoCodeSubText = () => {
        if (!promoCode && !promoCodeApplied) return '';

        const subText = translate('Promo Code:');

        if (promoCodeCredits) return `${subText} ${translate('Receive @promoCodeCredits credits', { promoCodeCredits })}`;

        if (promoCodeApplied.discountType === DiscountTypes.PERCENT) {
            return `${subText} - ${translate('@discount discount', { discount: `${promoCodeApplied.discount}%` })} `;
        }

        return `${subText} - ${translate('@discount discount', { discount: formatAsCurrencyNumber(promoCodeApplied.discount) })} ${acceptMultipleCurrenciesPaymentEnabled ? `(${mainCurrency})` : ''} `;
    };

    if (!promoCode || !promoCodeApplied) return null;

    return (
        <div className={classes.posPaymentCard}>
            <div className={classes.firstLine}>
                <span className={classes.orderResumeText}>{getPromoCodeText()}</span>
                <Button icon classes={{ button: classes.backButton }} onClick={removePosPromoCode}>
                    <CircleCloseIcon color={'#87888B'} size={32} />
                </Button>
            </div>
            <div className={classes.paymentMethodLabel}>{getPromoCodeSubText()}</div>
            {!!applicableItems.length && applicableItems.map((applicableItem) => <div className={classes.paymentMethodLabel}>{applicableItem.name}</div>)}
        </div>
    );
}

const useStyles = makeStyles((theme) => ({
    posPaymentCard: {
        fontFamily: theme.typography.regular,
        width: '100%',
        marginBottom: 12,
        borderRadius: 8,
        backgroundColor: '#F6F6F6',
        padding: '10px 20px',
    },
    backButton: {
        padding: 0,
        width: 'fit-content',
        height: 'fit-content',
        '&:hover &:active': {
            cursor: 'pointer',
            backgroundColor: 'transparent',
        },
    },
    firstLine: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
    },
    orderResumeText: {
        fontFamily: theme.typography.regular,
        fontSize: 14,
        color: '#2E3748',
    },
    paymentMethodLabel: {
        color: '#87888B',
        fontSize: 12,
    },
    iconButtonStyle: {
        border: '1px solid #87888B',
    },
}));
