import { makeStyles } from '@material-ui/core';
import FormHelperText from '@material-ui/core/FormHelperText';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { useForm, useWatch } from 'react-hook-form';
import { changeTheRestaurantApi } from 'src/api/letseatadmin/theRestaurant/changeTheRestaurantApi';
import { Form } from 'src/components/form/Form';
import { FormConsecutiveOrderIdRangeSelect } from 'src/components/form/FormConsecutiveOrderIdRangeSelect';
import { FormFixErrorHint } from 'src/components/form/FormFixErrorHint';
import { FormIntegerField } from 'src/components/form/FormIntegerField';
import { FormNumberFieldWithIncrementalButtons } from 'src/components/form/FormNumberFieldWithIncrementalButtons';
import { FormSoundSelect } from 'src/components/form/FormSoundSelect';
import { FormSubmitButton } from 'src/components/form/FormSubmitButton';
import { FormSwitch } from 'src/components/form/FormSwitch';
import { getDirtyFormValues } from 'src/components/form/utils/getDirtyFormValues';
import { UpdatingContentProgress } from 'src/components/UpdatingContentProgress';
import { translate } from 'src/i18n/translate';
import { getIsPrintAutoAcceptedOrdersDeviceInLocalStorage } from 'src/localStorage/getIsPrintAutoAcceptedOrdersDeviceInLocalStorage';
import { setIsPrintAutoAcceptedOrdersDeviceInLocalStorage } from 'src/localStorage/setIsPrintAutoAcceptedOrdersDeviceInLocalStorage';
import { appReducer } from 'src/reducers/appReducer';
import { LokiSetting } from 'src/scenes/letseatadmin/theRestaurant/restaurantOrdersSettings/LokiSetting';
import { useReloadRestaurant } from 'src/services/restaurant/useReloadRestaurant';
import { appColors } from 'src/theme/AppColors';
import { alertKnownErrorOrSomethingWentWrong } from 'src/utils/alert/alertKnownErrorOrSomethingWentWrong';
import { useAction } from 'src/utils/react/useAction';
import { useSelector } from 'src/utils/react/useSelector';
import { playAlertSound } from 'src/utils/sound/playAlertSound';

export function RestaurantOrdersSettings({ loading, restaurant, onChangeRestaurant, setIsDirtyForm }: Props): React.ReactElement {
    const classes = useStyles();
    const form = useForm();
    const {
        formState: { dirtyFields },
        watch,
        control,
        formState,
    } = form;

    const rappiStoreId = watch('rappiStoreId');
    const uberEatsStoreId = watch('uberEatsStoreId');
    const didiFoodStoreId = watch('didiFoodStoreId');
    const pedidosYaRestaurantOnline = watch('pedidosYaRestaurantOnline');
    const defaultPreparationTimeInMinutes = watch('defaultPreparationTimeInMinutes');
    const consecutiveOrderIdEnabled = useWatch<boolean>({ name: 'consecutiveOrderIdEnabled', control });

    const appLoading = useSelector((state) => state.app.loading);
    const restaurantId = useSelector((state) => state.app.restaurantId);

    const [printAutoAcceptedOrders, setPrintAutoAcceptedOrders] = useState(getIsPrintAutoAcceptedOrdersDeviceInLocalStorage());

    const showSnackbar = useAction(appReducer.actions.showSnackbar);
    const { reloadRestaurant } = useReloadRestaurant();

    useEffect(() => {
        if (!loading)
            form.reset({
                restaurantId: restaurant.restaurantId ?? null,
                preparationTimeInMinutes: restaurant.preparationTimeInMinutes ?? null,
                defaultPreparationTimeInMinutes: restaurant.defaultPreparationTimeInMinutes ?? null,
                addTemporaryPreparationTimeEnabled: restaurant.addTemporaryPreparationTimeEnabled ?? null,
                autoCallRestaurantEnabled: restaurant.autoCallRestaurantEnabled ?? null,
                alertSound: restaurant.alertSound ?? null,
                cancelledOrdersAlertSound: restaurant.cancelledOrdersAlertSound ?? null,
                cancelledOrdersAlertSoundEnabled: restaurant.cancelledOrdersAlertSoundEnabled ?? null,
                addManuallyOrderPreparationTimeEnabled: restaurant.addManuallyOrderPreparationTimeEnabled ?? null,
                hidePlannedOrders: restaurant.hidePlannedOrders ?? null,
                consecutiveOrderIdEnabled: restaurant.consecutiveOrderIdEnabled ?? null,
                consecutiveOrderIdRange: restaurant.consecutiveOrderIdRange ?? null,
                refundOngoingOrdersEnabled: restaurant.refundOngoingOrdersEnabled ?? null,
                markReadyForPickupButtonEnabled: restaurant.markReadyForPickupButtonEnabled ?? null,
                changeManualOrderCashCostEnabled: restaurant.changeManualOrderCashCostEnabled ?? null,
                autoAcceptOrdersTime: restaurant.autoAcceptOrdersTime ?? 0,
                uberEatsStoreId: restaurant.uberEatsStoreId ?? null,
                rappiStoreId: restaurant.rappiStoreId ?? null,
                didiFoodStoreId: restaurant.didiFoodStoreId ?? null,
                pedidosYaRestaurantOnline: restaurant.pedidosYaRestaurantOnline ?? null,
                autoAcceptRappiOrdersTime: restaurant.autoAcceptRappiOrdersTime ?? 0,
                autoAcceptUberEatsOrdersTime: restaurant.autoAcceptUberEatsOrdersTime ?? 0,
                autoAcceptPedidosYaOrdersTime: restaurant.autoAcceptPedidosYaOrdersTime ?? 0,
                autoAcceptDidiFoodsOrdersTime: restaurant.autoAcceptDidiFoodsOrdersTime ?? 0,
                printAutoAcceptedOrders: restaurant.printAutoAcceptedOrders ?? printAutoAcceptedOrders ?? null,
                ordersWithoutIvaEnabled: restaurant.ordersWithoutIvaEnabled ?? null,
                sendOrderCreatedWebhookAtPlannedPickupTimeEnabled: restaurant.sendOrderCreatedWebhookAtPlannedPickupTimeEnabled ?? null,
                sendWebhookEventsForPosOrdersEnabled: restaurant.sendWebhookEventsForPosOrdersEnabled ?? null,
                newOrdersPageEnabled: restaurant.newOrdersPageEnabled ?? null,
                heatMapDeliveryOrdersReportEnabled: restaurant.heatMapDeliveryOrdersReportEnabled ?? null,
                hideDeliveryCostPaidByRestaurantOnTicketEnabled: restaurant.hideDeliveryCostPaidByRestaurantOnTicketEnabled ?? null,
                printRappiQrCodeOnTicketEnabled: restaurant.printRappiQrCodeOnTicketEnabled ?? null,
                useRappiQrCodeEnabled: restaurant.useRappiQrCodeEnabled ?? null,
                lokiEnabled: restaurant.lokiEnabled ?? null,
            });
    }, [loading]);

    useEffect(() => {
        setIsDirtyForm(Object.keys(dirtyFields).length !== 0);
    }, [formState]);

    const onSubmit = async (form: any) => {
        const dirtyFormValues = getDirtyFormValues({ form, dirtyFields });
        setIsPrintAutoAcceptedOrdersDeviceInLocalStorage(form.printAutoAcceptedOrders);
        setPrintAutoAcceptedOrders(form.printAutoAcceptedOrders);

        const response = await changeTheRestaurantApi({
            restaurantId: restaurantId,
            ...dirtyFormValues,
        });

        if (!response.ok) {
            alertKnownErrorOrSomethingWentWrong(response);
            return;
        }
        await reloadRestaurant(restaurantId);
        await onChangeRestaurant();
        showSnackbar({ message: translate('Saved changes') });
    };

    return (
        <Paper className={classes.paper}>
            <UpdatingContentProgress loading={loading || appLoading} />
            <Form form={form} onSubmit={onSubmit} disabled={loading || appLoading}>
                <div className={classes.formContent}>
                    <Grid container spacing={8}>
                        <Grid item xs={12} sm={6}>
                            <FormNumberFieldWithIncrementalButtons
                                helperText={`${translate('If the value is set to 0, the functionality will be disabled')}`}
                                label={translate('PideDirecto Auto Accept')}
                                text='min'
                                name='autoAcceptOrdersTime'
                                min={0}
                                max={10}
                            />
                        </Grid>
                    </Grid>
                    {rappiStoreId && (
                        <Grid container spacing={8}>
                            <Grid item xs={12} sm={6}>
                                <FormNumberFieldWithIncrementalButtons
                                    label={translate('Rappi Auto Accept')}
                                    helperText={`${translate('If the value is set to 0, the functionality will be disabled')}`}
                                    text='min'
                                    name='autoAcceptRappiOrdersTime'
                                    min={0}
                                    max={2.5}
                                />
                            </Grid>
                        </Grid>
                    )}
                    {uberEatsStoreId && (
                        <Grid container spacing={8}>
                            <Grid item xs={12} sm={6}>
                                <FormNumberFieldWithIncrementalButtons
                                    label={translate('Uber Eats Auto Accept')}
                                    helperText={`${translate('If the value is set to 0, the functionality will be disabled')}`}
                                    text='min'
                                    name='autoAcceptUberEatsOrdersTime'
                                    min={0}
                                    max={10}
                                />
                            </Grid>
                        </Grid>
                    )}
                    {didiFoodStoreId && (
                        <Grid container spacing={8}>
                            <Grid item xs={12} sm={6}>
                                <FormNumberFieldWithIncrementalButtons
                                    label={translate('Didi Foods Auto Accept')}
                                    helperText={`${translate('If the value is set to 0, the functionality will be disabled')}`}
                                    text='min'
                                    name='autoAcceptDidiFoodsOrdersTime'
                                    min={0}
                                    max={4}
                                />
                            </Grid>
                        </Grid>
                    )}
                    {pedidosYaRestaurantOnline && (
                        <Grid container spacing={8}>
                            <Grid item xs={12} sm={6}>
                                <FormNumberFieldWithIncrementalButtons
                                    label={translate('Pedidos Ya Auto Accept')}
                                    helperText={`${translate('If the value is set to 0, the functionality will be disabled')}`}
                                    text='min'
                                    name='autoAcceptPedidosYaOrdersTime'
                                    min={0}
                                    max={4}
                                />
                            </Grid>
                        </Grid>
                    )}
                    <Grid container spacing={8}>
                        <Grid item xs={12} md={6}>
                            <FormSwitch
                                label={translate('Print Auto Accepted Orders')}
                                name='printAutoAcceptedOrders'
                                tooltip={translate('Enable this option if you want dropped orders to be printed, includes all platforms')}
                            />
                        </Grid>
                    </Grid>
                    <Grid container spacing={3}>
                        <Grid item xs={12} sm={6}>
                            <FormIntegerField
                                name='preparationTimeInMinutes'
                                label={translate('Preparation Time (in minutes)')}
                                helperText={`${translate('Default is 15 minutes')}`}
                                placeholder={`${defaultPreparationTimeInMinutes}`}
                                min={0}
                                tooltip={translate('Place the estimate of preparation minutes for your orders')}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <FormSwitch name='addTemporaryPreparationTimeEnabled' label={translate('Add Temporary Preparation time Enable (Rush hour)')} />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <FormSwitch
                                name='addManuallyOrderPreparationTimeEnabled'
                                label={translate('Preparation time manually for orders')}
                                helperText={translate('If enabled orders when accepted will display a dialog to set its preparation time')}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <FormSoundSelect
                                name='alertSound'
                                label={translate('Alert Sound')}
                                onChange={(sound) => playAlertSound(sound)}
                                tooltip={translate('Select Alert sound for incoming orders')}
                            />
                        </Grid>
                    </Grid>
                    <Grid container spacing={3}>
                        <Grid item xs={12} sm={6}>
                            <FormSwitch
                                name='cancelledOrdersAlertSoundEnabled'
                                label={translate('Alert sound for cancelled orders')}
                                helperText={translate('If enabled orders when cancelled will sound and display a message.')}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <FormSoundSelect name='cancelledOrdersAlertSound' label={translate('Alert sound for cancelled orders')} onChange={(sound) => playAlertSound(sound)} />
                        </Grid>
                    </Grid>
                    <Grid container spacing={3}>
                        <Grid item xs={12} sm={6}>
                            <FormSwitch name='hidePlannedOrders' label={translate('Show orders at preparation time')} />
                            <FormHelperText>
                                {translate(
                                    'Orders will be only shown when it is time to start preparing them (pickup time - preparation time). Does not apply neither for integrations nor planned manual orders.',
                                )}
                            </FormHelperText>
                        </Grid>
                    </Grid>
                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                            <FormSwitch
                                name='consecutiveOrderIdEnabled'
                                label={translate('Consecutive Order Id')}
                                tooltip={translate('Activate this option if you want your order folio numbers to be consecutive.')}
                            />
                        </Grid>
                        {consecutiveOrderIdEnabled && (
                            <Grid item xs={12}>
                                <FormConsecutiveOrderIdRangeSelect
                                    name='consecutiveOrderIdRange'
                                    label={translate('Range to reset order folio')}
                                    required={consecutiveOrderIdEnabled}
                                    tooltip={translate(
                                        'It is a mandatory selection type input if the toggle of consecutive orders is set, it allows selecting the range to restart the consecutive folios between DAILY, WEEKLY, MONTHLY, NOT_RESET and at the close of the day',
                                    )}
                                />
                            </Grid>
                        )}
                        <Grid item xs={12}>
                            <FormSwitch
                                name='autoCallRestaurantEnabled'
                                label={translate('Auto call restaurant')}
                                tooltip={translate('Auto call restaurant and notify when a pending order its not accepted in time.')}
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <FormSwitch
                                name='refundOngoingOrdersEnabled'
                                label={translate('Refund Ongoing Orders Enabled')}
                                tooltip={translate('So that a delivery man can return the order to the restaurant')}
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <FormSwitch
                                name='markReadyForPickupButtonEnabled'
                                label={translate('Mark Ready For Pickup Button Enabled')}
                                tooltip={translate('When the orders are pickup the restaurant can mark them as ready')}
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <FormSwitch label={translate('Change Manual Order Cash Total')} name='changeManualOrderCashCostEnabled' />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <FormSwitch name='ordersWithoutIvaEnabled' label={translate('Orders without IVA')} />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <FormSwitch
                                name='sendOrderCreatedWebhookAtPlannedPickupTimeEnabled'
                                label={translate('Send order created webhook at planned pick up time enabled')}
                                tooltip={translate('Sends the order created webhook one hour before the planned pickup time, this works only for planned orders.')}
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <FormSwitch
                                name='sendWebhookEventsForPosOrdersEnabled'
                                label={translate('Send order webhooks for pos orders enabled')}
                                disabled={restaurant.posPendingOrdersEnabled}
                                helperText={restaurant.posPendingOrdersEnabled ? translate('Is not possible to use it with pending orders (POS)') : ''}
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <FormSwitch name='newOrdersPageEnabled' label={translate('New orders page enabled')} helperText={translate('This enables the new orders page section.')} />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <FormSwitch name='heatMapDeliveryOrdersReportEnabled' label={translate('Heat map delivery orders enabled')} />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <FormSwitch
                                name='hideDeliveryCostPaidByRestaurantOnTicketEnabled'
                                label={translate('Do not print on ticket delivery cost when is paid by restaurant')}
                                helperText={translate('When enabled, delivery cost is not printed if it has been paid by the restaurant')}
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <FormSwitch name='useRappiQrCodeEnabled' label={translate('Should use rappi QRCode on the orders')} />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <FormSwitch name='printRappiQrCodeOnTicketEnabled' label={translate('Should print rappi QRCode on the tickets')} />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <LokiSetting />
                        </Grid>
                    </Grid>
                    <FormFixErrorHint />
                </div>

                <Grid container justify='center' spacing={3}>
                    <Grid item>
                        <FormSubmitButton text={translate('Change')} disabled={loading || appLoading} classes={{ container: classes.submitButton }} />
                    </Grid>
                </Grid>
            </Form>
        </Paper>
    );
}

const useStyles = makeStyles((theme) => ({
    paper: {
        position: 'relative',
    },
    formContent: {
        padding: theme.spacing(3),
    },
    deleteButton: {
        color: appColors.text.dangerLight,
    },
    section: {
        width: '100%',
        borderRadius: 5,
        margin: '20px auto',
        border: `1px solid ${theme.palette.secondary.dark}`,
    },
    sectionTitleContainer: {
        backgroundColor: theme.palette.secondary.light,
        marginTop: 20,
    },
    sectionTitle: {
        fontFamily: theme.typography.semiBold,
        fontSize: 14,
    },
    subtitlesubtitle: {
        fontFamily: theme.typography.medium,
        fontSize: 18,
        color: theme.palette.secondary.contrastText,
        margin: 0,
    },
    cashRegisterCustomReasonItem: {
        fontFamily: theme.typography.medium,
        fontSize: 15,
        minWidth: 140,
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        border: '1px solid #676060B2',
        borderRadius: 12,
        padding: '0 10px',
    },
    title: {
        fontFamily: theme.typography.semiBold,
        fontSize: 20,
        color: theme.palette.secondary.contrastText,
        marginLeft: 10,
    },
    subtitle: {
        fontFamily: theme.typography.medium,
        fontSize: 18,
        color: theme.palette.secondary.contrastText,
        margin: 0,
    },
    paymentMethod: {
        fontFamily: theme.typography.medium,
        fontSize: 15,
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
    },
    secondaryButton: {
        backgroundColor: 'white',
        color: theme.palette.primary.main,
        border: `1px solid ${theme.palette.primary.main}`,
        fontFamily: theme.typography.semiBold,
        fontSize: 15,
        borderRadius: 5,
        width: 200,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        textTransform: 'none',
        padding: '5px 10px',
    },
    kioskUserContainer: {
        width: '100%',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
    },
    text: {
        fontFamily: theme.typography.regular,
        fontSize: 15,
    },
    kioskInfo: {
        display: 'flex',
        flexDirection: 'column',
    },
    integrationStoreStatusClosed: {
        fontFamily: theme.typography.semiBold,
        color: '#fa6684',
        fontSize: 13,
    },
    integrationStoreStatusOpen: {
        fontFamily: theme.typography.semiBold,
        color: theme.palette.primary.main,
        fontSize: 13,
    },
    submitButton: {
        zIndex: 1000,
    },
}));

type Props = {
    loading: boolean;
    restaurant: any;
    onChangeRestaurant: any;
    setIsDirtyForm: any;
};
