import { makeStyles } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import * as React from 'react';
import { Button } from 'src/components/Button';
import { Dialog } from 'src/components/Dialog';
import { DialogActions } from 'src/components/DialogActions';
import { translate } from 'src/i18n/translate';
import { RestaurantSupplyCategoriesSection } from 'src/scenes/letseatmanager/inventory/supply/RestaurantSupplyCategoriesSection';

export function ManagementSupplyCategoryDialog({ open, onClose }: Props): React.ReactElement {
    const classes = useStyles();

    const handleClose = () => {
        onClose();
    };

    return (
        <Dialog open={open} onClose={handleClose}>
            {/* @ts-ignore */}
            <Grid spacing={12}>
                <RestaurantSupplyCategoriesSection />
            </Grid>

            <DialogActions className={classes.dialogActions}>
                <Button secondary onClick={handleClose}>
                    {translate('Close')}
                </Button>
            </DialogActions>
        </Dialog>
    );
}

const useStyles = makeStyles((theme) => ({
    dialogActions: {
        justifyContent: 'flex-end',
    },
}));

type Props = {
    open: boolean;
    onClose: any;
};
