import Grid from '@material-ui/core/Grid';
import { useState } from 'react';
import * as React from 'react';
import { useForm } from 'react-hook-form';
import { addCurrencyApi } from 'src/api/letseatmanager/restaurant/addCurrencyApi';
import { Button } from 'src/components/Button';
import { Dialog } from 'src/components/Dialog';
import { DialogActions } from 'src/components/DialogActions';
import { Form } from 'src/components/form/Form';
import { FormCurrencySelect } from 'src/components/form/FormCurrencySelect';
import { FormNumberStringField } from 'src/components/form/FormNumberStringField';
import { Currencies, Currency } from 'src/constants/Currency';
import { translate } from 'src/i18n/translate';
import { useNotification } from 'src/services/notification/useNotification';
import { alertKnownErrorOrSomethingWentWrong } from 'src/utils/alert/alertKnownErrorOrSomethingWentWrong';
import { useSelector } from 'src/utils/react/useSelector';

export function AddCurrencyDialog({ open, onClose, onSuccess, currencies }: Props): React.ReactElement {
    const form = useForm();
    const notification = useNotification();

    const [loading, setLoading] = useState(false);

    const restaurantId = useSelector((state) => state.app.restaurantId);
    const mainCurrency = useSelector((state) => state.app.restaurant?.currencyFormat?.currency);

    const { watch } = form;

    const currency = watch('currency');
    const exchangeRate = watch('exchangeRate');

    const handleSubmit = async () => {
        if (!currency || !exchangeRate) return notification({ message: translate('There are empty fields') });

        if (currencies?.includes(currency)) return notification({ message: translate('This currency already exists') });

        setLoading(true);
        const response = await addCurrencyApi({ restaurantId, restaurantCurrency: { currency, exchangeRate } });
        setLoading(false);

        if (!response.ok) {
            alertKnownErrorOrSomethingWentWrong(response);
            return;
        }

        onSuccess();
        onClose();
    };

    return (
        <Dialog open={open} onClose={onClose} title={translate('Add currency')}>
            <Form form={form}>
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <FormCurrencySelect disabled={loading} name='currency' label={translate('Currency')} required excludedCurrencies={[mainCurrency]} />
                    </Grid>
                    <Grid item xs={12}>
                        <FormNumberStringField disabled={loading} name='exchangeRate' label={translate('Exchange type (@mainCurrency)', { mainCurrency: mainCurrency ?? Currencies.MXN })} required />
                    </Grid>
                </Grid>
                <DialogActions>
                    <Button onClick={onClose} disabled={loading} secondary>
                        {translate('Cancel')}
                    </Button>
                    <Button onClick={handleSubmit} disabled={loading}>
                        {translate('Add')}
                    </Button>
                </DialogActions>
            </Form>
        </Dialog>
    );
}

type Props = {
    open: boolean;
    onClose: any;
    onSuccess: any;
    currencies?: Array<Currency>;
};
