import { letseatmanagerApiMethod } from 'src/api/letseatmanagerApi';
import type { ApiSauceResponse } from 'src/api/types/ApiSauceResponse';
import type { CustomerId, RestaurantId } from 'src/types/Id';

export async function sendNotificationToCustomerApi(request: SendNotificationToCustomersApiRequest): ApiSauceResponse<void> {
    return letseatmanagerApiMethod('marketing/sendNotificationToCustomerApi', request);
}

type SendNotificationToCustomersApiRequest = {
    restaurantId: RestaurantId;
    customerId?: CustomerId;
    title: string;
    body: string;
};
