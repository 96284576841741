import Grid from '@material-ui/core/Grid';
import * as React from 'react';
import { FormNumberField } from 'src/components/form/FormNumberField';
import { Cities } from 'src/constants/City';
import { CitiesNamesForSettings } from 'src/constants/CityNameForSetting';
import { translate } from 'src/i18n/translate';

export function DynamicDeliveryEarningsToNewRestaurantSettings(): React.ReactElement {
    const cities = [Cities.GUADALAJARA, Cities.MEXICO_CITY, Cities.MONTERREY, Cities.PUEBLA, Cities.STOCKHOLM, Cities.SALTILLO, Cities.TORREON, Cities.MERIDA, Cities.QUERETARO, Cities.BOGOTA];

    return (
        <>
            {cities.map((city) => (
                <Grid item xs={12} key={city}>
                    <FormNumberField
                        name={`dynamicDeliveryEarningsToNewRestaurant${CitiesNamesForSettings[city]}`}
                        label={translate(`Dynamic Delivery Earnings To New Restaurant in ${CitiesNamesForSettings[city]}`)}
                        required
                        min={0}
                    />
                </Grid>
            ))}
        </>
    );
}
