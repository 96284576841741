import { Grid } from '@material-ui/core';
import moment from 'moment-timezone';
import * as React from 'react';
import { useState } from 'react';
import { FilterState, FromToFilter } from 'src/components/input/FromToFilter';
import { SubscribedPage } from 'src/components/page/SubscribedPage';
import { translate } from 'src/i18n/translate';
import { OrderIncidentsTable } from 'src/scenes/letseatadmin/restaurantManualEarning/OrderIncidentsTable';
import { RestaurantManualEarnings } from 'src/scenes/letseatadmin/restaurantManualEarning/RestaurantManualEarnings';
import { useSelector } from 'src/utils/react/useSelector';

export function RestaurantManualEarningsPage(): React.ReactElement {
    const [filter, setFilter] = useState<FilterState>({
        from: moment().subtract(2, 'week').startOf('year').toDate(),
        to: moment().add(1, 'day').toDate(),
    });
    const restaurantId = useSelector((state) => state.app.restaurantId);

    const handleChangeFilter = (filter: { from: Date; to: Date }) => setFilter(filter);

    return (
        <SubscribedPage title={translate('Restaurant Manual Earnings')}>
            <Grid container spacing={5}>
                <Grid item xs={12}>
                    <FromToFilter filter={filter} onChangeFilter={handleChangeFilter} />
                </Grid>
                <Grid item xs={12}>
                    <RestaurantManualEarnings
                        restaurantId={restaurantId}
                        filter={{
                            ...filter,
                        }}
                    />
                </Grid>
                <Grid item xs={12}>
                    <OrderIncidentsTable filter={filter} />
                </Grid>
            </Grid>
        </SubscribedPage>
    );
}
