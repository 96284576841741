import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableContainer from '@material-ui/core/TableContainer';
import { BigNumber } from 'bignumber.js';
import * as React from 'react';
import type { RestaurantBillingUyVm } from 'src/api/letseatadmin/restaurantBillingUy/getRestaurantBillingUyApi';
import { TableHeaderCalculationUy } from 'src/scenes/letseatadmin/restaurantBillingUy/TableHeaderCalculationUy';
import { TableRowCalculationUy } from 'src/scenes/letseatadmin/restaurantBillingUy/TableRowCalculationUy';
import { formatAsNumberNew } from 'src/utils/number/formatAsNumberNew';

export function CommissionCalculationUy({ restaurantBillingUyVm }: Props): React.ReactElement {
    const classes = useStyles();

    return (
        <TableContainer component={Paper}>
            <Table className={classes.table} size='small'>
                <TableHeaderCalculationUy name={`Commission Calculation`} />
                <TableBody>
                    <TableRowCalculationUy
                        name={`Web Card Orders no cardType + Payment Link - Restaurant Promo Code Cost App`}
                        calculation={`(${[
                            [formatAsNumberNew(restaurantBillingUyVm.webCard, { fractionDigits: 2 } || 0), formatAsNumberNew(restaurantBillingUyVm.paymentLink, { fractionDigits: 2 } || 0)].join(
                                ' + ',
                            ),
                            formatAsNumberNew(restaurantBillingUyVm.restaurantPromoCodeCostApp, { fractionDigits: 2 } || '0'),
                        ].join(' - ')}) * ${formatAsNumberNew(restaurantBillingUyVm.commissionWebCard)}%`}
                        amount={restaurantBillingUyVm.commissionForWebCardOrders}
                    />
                    <TableRowCalculationUy
                        name={`Web Card Orders Debit + Payment Link Debit`}
                        calculation={`(${[
                            formatAsNumberNew(restaurantBillingUyVm.webCardDebit, { fractionDigits: 2 } || 0),
                            formatAsNumberNew(restaurantBillingUyVm.paymentLinkDebit, { fractionDigits: 2 } || 0),
                        ].join(' + ')}) * ${formatAsNumberNew(restaurantBillingUyVm.commissionWebCardDebit)}%`}
                        amount={restaurantBillingUyVm.commissionForWebCardDebitOrders}
                    />
                    <TableRowCalculationUy
                        name={`Web Card Orders Credit + Payment Link Credit`}
                        calculation={`(${[
                            formatAsNumberNew(restaurantBillingUyVm.webCardCredit, { fractionDigits: 2 } || 0),
                            formatAsNumberNew(restaurantBillingUyVm.paymentLinkCredit, { fractionDigits: 2 } || 0),
                        ].join(' + ')}) * ${formatAsNumberNew(restaurantBillingUyVm.commissionWebCardCredit)}%`}
                        amount={restaurantBillingUyVm.commissionForWebCardCreditOrders}
                    />
                    <TableRowCalculationUy
                        name={`Web Cash Orders`}
                        calculation={`${formatAsNumberNew(restaurantBillingUyVm.webCash, { fractionDigits: 2 })} * ${formatAsNumberNew(restaurantBillingUyVm.commissionWebCash)}%`}
                        amount={restaurantBillingUyVm.commissionForWebCashOrders}
                    />
                    <TableRowCalculationUy
                        name={`Web Card on delivery Orders`}
                        calculation={`${formatAsNumberNew(restaurantBillingUyVm.webCardOnDelivery, { fractionDigits: 2 })} * ${formatAsNumberNew(restaurantBillingUyVm.commissionWebCardOnDelivery)}%`}
                        amount={restaurantBillingUyVm.commissionForWebCardOnDeliveryOrders}
                    />
                    <TableRowCalculationUy
                        name={`White Label App Card Orders`}
                        calculation={`${formatAsNumberNew(restaurantBillingUyVm.whiteLabelAppCard, { fractionDigits: 2 })} * ${formatAsNumberNew(restaurantBillingUyVm.commissionAppCard)}%`}
                        amount={restaurantBillingUyVm.commissionForWhiteLabelAppCardOrders}
                    />
                    <TableRowCalculationUy
                        name={`White Label App Card Debit Orders`}
                        calculation={`${formatAsNumberNew(restaurantBillingUyVm.whiteLabelAppCardDebit, { fractionDigits: 2 })} * ${formatAsNumberNew(restaurantBillingUyVm.commissionAppCardDebit)}%`}
                        amount={restaurantBillingUyVm.commissionForWhiteLabelAppCardDebitOrders}
                    />
                    <TableRowCalculationUy
                        name={`White Label App Card Credit Orders`}
                        calculation={`${formatAsNumberNew(restaurantBillingUyVm.whiteLabelAppCardCredit, { fractionDigits: 2 })} * ${formatAsNumberNew(
                            restaurantBillingUyVm.commissionAppCardCredit,
                        )}%`}
                        amount={restaurantBillingUyVm.commissionForWhiteLabelAppCardCreditOrders}
                    />
                    <TableRowCalculationUy
                        name={`White Label App Cash Orders`}
                        calculation={`${formatAsNumberNew(restaurantBillingUyVm.whiteLabelAppCash, { fractionDigits: 2 })} * ${formatAsNumberNew(restaurantBillingUyVm.commissionAppCash)}%`}
                        amount={restaurantBillingUyVm.commissionForWhiteLabelAppCashOrders}
                    />
                    <TableRowCalculationUy
                        name={`White Label App Card on delivery Orders`}
                        calculation={`${formatAsNumberNew(restaurantBillingUyVm.whiteLabelAppCardOnDelivery, { fractionDigits: 2 })} * ${formatAsNumberNew(
                            restaurantBillingUyVm.commissionAppCardOnDelivery,
                        )}%`}
                        amount={restaurantBillingUyVm.commissionForWhiteLabelAppCardOnDeliveryOrders}
                    />
                    <TableRowCalculationUy
                        name={`Market Place App Card Orders`}
                        calculation={`${formatAsNumberNew(restaurantBillingUyVm.marketplaceAppCard, { fractionDigits: 2 })} * ${formatAsNumberNew(restaurantBillingUyVm.commissionAppCard)}%`}
                        amount={restaurantBillingUyVm.commissionForMarketPlaceAppCardOrders}
                    />
                    <TableRowCalculationUy
                        name={`Market Place App Card Debit Orders`}
                        calculation={`${formatAsNumberNew(restaurantBillingUyVm.marketplaceAppCardDebit, { fractionDigits: 2 })} * ${formatAsNumberNew(restaurantBillingUyVm.commissionAppCardDebit)}%`}
                        amount={restaurantBillingUyVm.commissionForMarketPlaceAppCardDebitOrders}
                    />
                    <TableRowCalculationUy
                        name={`Market Place App Card Credit Orders`}
                        calculation={`${formatAsNumberNew(restaurantBillingUyVm.marketplaceAppCardCredit, { fractionDigits: 2 })} * ${formatAsNumberNew(
                            restaurantBillingUyVm.commissionAppCardCredit,
                        )}%`}
                        amount={restaurantBillingUyVm.commissionForMarketPlaceAppCardCreditOrders}
                    />
                    <TableRowCalculationUy
                        name={`Market Place App Cash Orders`}
                        calculation={`${formatAsNumberNew(restaurantBillingUyVm.marketplaceAppCash, { fractionDigits: 2 })} * ${formatAsNumberNew(restaurantBillingUyVm.commissionAppCash)}%`}
                        amount={restaurantBillingUyVm.commissionForMarketPlaceAppCashOrders}
                    />
                    <TableRowCalculationUy
                        name={`Market Place App Card on delivery Orders`}
                        calculation={`${formatAsNumberNew(restaurantBillingUyVm.marketplaceAppCardOnDelivery, { fractionDigits: 2 })} * ${formatAsNumberNew(
                            restaurantBillingUyVm.commissionAppCardOnDelivery,
                        )}%`}
                        amount={restaurantBillingUyVm.commissionForMarketPlaceAppCardOnDeliveryOrders}
                    />
                    <TableRowCalculationUy
                        name={`Manual Orders`}
                        calculation={`${formatAsNumberNew(restaurantBillingUyVm.manualOrders, { fractionDigits: 2 })} * ${formatAsNumberNew(restaurantBillingUyVm.commissionManualOrders)}%`}
                        amount={restaurantBillingUyVm.commissionForManualOrders}
                    />
                    <TableRowCalculationUy
                        name={`Rappi Cargo Web Card`}
                        calculation={`${formatAsNumberNew(restaurantBillingUyVm.rappiCargoWebCard, { fractionDigits: 2 })} * ${formatAsNumberNew(restaurantBillingUyVm.commissionRappiCargoWebCard)}%`}
                        amount={restaurantBillingUyVm.commissionForRappiCargoWebCard}
                    />
                    <TableRowCalculationUy
                        name={`Rappi Cargo Web Card Debit`}
                        calculation={`${formatAsNumberNew(restaurantBillingUyVm.rappiCargoWebCardDebit, { fractionDigits: 2 })} * ${formatAsNumberNew(
                            restaurantBillingUyVm.commissionRappiCargoWebCardDebit,
                        )}%`}
                        amount={restaurantBillingUyVm.commissionForRappiCargoWebCardDebit}
                    />
                    <TableRowCalculationUy
                        name={`Rappi Cargo Web Card Credit`}
                        calculation={`${formatAsNumberNew(restaurantBillingUyVm.rappiCargoWebCardCredit, { fractionDigits: 2 })} * ${formatAsNumberNew(
                            restaurantBillingUyVm.commissionRappiCargoWebCardCredit,
                        )}%`}
                        amount={restaurantBillingUyVm.commissionForRappiCargoWebCardCredit}
                    />
                    <TableRowCalculationUy
                        name={`Rappi Cargo Web Cash`}
                        calculation={`${formatAsNumberNew(restaurantBillingUyVm.rappiCargoWebCash, { fractionDigits: 2 })} * ${formatAsNumberNew(restaurantBillingUyVm.commissionRappiCargoWebCash)}%`}
                        amount={restaurantBillingUyVm.commissionForRappiCargoWebCash}
                    />
                    <TableRowCalculationUy
                        name={`Rappi Cargo App Card`}
                        calculation={`${formatAsNumberNew(restaurantBillingUyVm.rappiCargoAppCard, { fractionDigits: 2 })} * ${formatAsNumberNew(restaurantBillingUyVm.commissionRappiCargoAppCard)}%`}
                        amount={restaurantBillingUyVm.commissionForRappiCargoAppCard}
                    />
                    <TableRowCalculationUy
                        name={`Rappi Cargo App Card Debit`}
                        calculation={`${formatAsNumberNew(restaurantBillingUyVm.rappiCargoAppCardDebit, { fractionDigits: 2 })} * ${formatAsNumberNew(
                            restaurantBillingUyVm.commissionRappiCargoAppCardDebit,
                        )}%`}
                        amount={restaurantBillingUyVm.commissionForRappiCargoAppCardDebit}
                    />
                    <TableRowCalculationUy
                        name={`Rappi Cargo App Card Credit`}
                        calculation={`${formatAsNumberNew(restaurantBillingUyVm.rappiCargoAppCardCredit, { fractionDigits: 2 })} * ${formatAsNumberNew(
                            restaurantBillingUyVm.commissionRappiCargoAppCardCredit,
                        )}%`}
                        amount={restaurantBillingUyVm.commissionForRappiCargoAppCardCredit}
                    />
                    <TableRowCalculationUy
                        name={`Rappi Cargo App Cash`}
                        calculation={`${formatAsNumberNew(restaurantBillingUyVm.rappiCargoAppCash, { fractionDigits: 2 })} * ${formatAsNumberNew(restaurantBillingUyVm.commissionRappiCargoAppCash)}%`}
                        amount={restaurantBillingUyVm.commissionForRappiCargoAppCash}
                    />
                    <TableRowCalculationUy
                        name={`Rappi Cargo Manual Order`}
                        calculation={`${formatAsNumberNew(restaurantBillingUyVm.rappiCargoManualOrder, { fractionDigits: 2 })} * ${formatAsNumberNew(
                            restaurantBillingUyVm.commissionRappiCargoManualOrder,
                        )}%`}
                        amount={restaurantBillingUyVm.commissionForRappiCargoManualOrder}
                    />
                    <TableRowCalculationUy
                        name={`Rappi Cargo Pos Cash`}
                        calculation={`${formatAsNumberNew(restaurantBillingUyVm.rappiCargoPosCash, { fractionDigits: 2 })} * ${formatAsNumberNew(restaurantBillingUyVm.commissionRappiCargoPosCash)}%`}
                        amount={restaurantBillingUyVm.commissionForRappiCargoPosCash}
                    />
                    <TableRowCalculationUy
                        name={`Rappi Cargo Pos Card`}
                        calculation={`${formatAsNumberNew(restaurantBillingUyVm.rappiCargoPosCard, { fractionDigits: 2 })} * ${formatAsNumberNew(restaurantBillingUyVm.commissionRappiCargoPosCard)}%`}
                        amount={restaurantBillingUyVm.commissionForRappiCargoPosCard}
                    />
                    <TableRowCalculationUy
                        name={`Uber Daas Web Card`}
                        calculation={`${formatAsNumberNew(restaurantBillingUyVm.uberDaasWebCard, { fractionDigits: 2 })} * ${formatAsNumberNew(restaurantBillingUyVm.commissionUberDaasWebCard)}%`}
                        amount={restaurantBillingUyVm.commissionForUberDaasWebCard}
                    />
                    <TableRowCalculationUy
                        name={`Uber Daas Web Card Debit`}
                        calculation={`${formatAsNumberNew(restaurantBillingUyVm.uberDaasWebCardDebit, { fractionDigits: 2 })} * ${formatAsNumberNew(
                            restaurantBillingUyVm.commissionUberDaasWebCardDebit,
                        )}%`}
                        amount={restaurantBillingUyVm.commissionForUberDaasWebCardDebit}
                    />
                    <TableRowCalculationUy
                        name={`Uber Daas Web Card Credit`}
                        calculation={`${formatAsNumberNew(restaurantBillingUyVm.uberDaasWebCardCredit, { fractionDigits: 2 })} * ${formatAsNumberNew(
                            restaurantBillingUyVm.commissionUberDaasWebCardCredit,
                        )}%`}
                        amount={restaurantBillingUyVm.commissionForUberDaasWebCardCredit}
                    />
                    <TableRowCalculationUy
                        name={`Uber Daas Web Cash`}
                        calculation={`${formatAsNumberNew(restaurantBillingUyVm.uberDaasWebCash, { fractionDigits: 2 })} * ${formatAsNumberNew(restaurantBillingUyVm.commissionUberDaasWebCash)}%`}
                        amount={restaurantBillingUyVm.commissionForUberDaasWebCash}
                    />
                    <TableRowCalculationUy
                        name={`Uber Daas App Card`}
                        calculation={`${formatAsNumberNew(restaurantBillingUyVm.uberDaasAppCard, { fractionDigits: 2 })} * ${formatAsNumberNew(restaurantBillingUyVm.commissionUberDaasAppCard)}%`}
                        amount={restaurantBillingUyVm.commissionForUberDaasAppCard}
                    />
                    <TableRowCalculationUy
                        name={`Uber Daas App Card Debit`}
                        calculation={`${formatAsNumberNew(restaurantBillingUyVm.uberDaasAppCardDebit, { fractionDigits: 2 })} * ${formatAsNumberNew(
                            restaurantBillingUyVm.commissionUberDaasAppCardDebit,
                        )}%`}
                        amount={restaurantBillingUyVm.commissionForUberDaasAppCardDebit}
                    />
                    <TableRowCalculationUy
                        name={`Uber Daas App Card Credit`}
                        calculation={`${formatAsNumberNew(restaurantBillingUyVm.uberDaasAppCardCredit, { fractionDigits: 2 })} * ${formatAsNumberNew(
                            restaurantBillingUyVm.commissionUberDaasAppCardCredit,
                        )}%`}
                        amount={restaurantBillingUyVm.commissionForUberDaasAppCardCredit}
                    />
                    <TableRowCalculationUy
                        name={`Uber Daas App Cash`}
                        calculation={`${formatAsNumberNew(restaurantBillingUyVm.uberDaasAppCash, { fractionDigits: 2 })} * ${formatAsNumberNew(restaurantBillingUyVm.commissionUberDaasAppCash)}%`}
                        amount={restaurantBillingUyVm.commissionForUberDaasAppCash}
                    />
                    <TableRowCalculationUy
                        name={`Uber Daas Manual Order`}
                        calculation={`${formatAsNumberNew(restaurantBillingUyVm.uberDaasManualOrder, { fractionDigits: 2 })} * ${formatAsNumberNew(
                            restaurantBillingUyVm.commissionUberDaasManualOrder,
                        )}%`}
                        amount={restaurantBillingUyVm.commissionForUberDaasManualOrder}
                    />
                    <TableRowCalculationUy
                        name={`Uber Daas Pos Cash`}
                        calculation={`${formatAsNumberNew(restaurantBillingUyVm.uberDaasPosCash, { fractionDigits: 2 })} * ${formatAsNumberNew(restaurantBillingUyVm.commissionUberDaasPosCash)}%`}
                        amount={restaurantBillingUyVm.commissionForUberDaasPosCash}
                    />
                    <TableRowCalculationUy
                        name={`Uber Daas Pos Card`}
                        calculation={`${formatAsNumberNew(restaurantBillingUyVm.uberDaasPosCard, { fractionDigits: 2 })} * ${formatAsNumberNew(restaurantBillingUyVm.commissionUberDaasPosCard)}%`}
                        amount={restaurantBillingUyVm.commissionForUberDaasPosCard}
                    />
                    <TableRowCalculationUy name={`Commission`} amount={restaurantBillingUyVm.commission} />
                    <TableRowCalculationUy
                        name={`IVA`}
                        calculation={`${formatAsNumberNew(restaurantBillingUyVm.commission, { fractionDigits: 2 })} * ${formatAsNumberNew(
                            BigNumber(restaurantBillingUyVm.ivaPercentage).times(100).toString(),
                        )}%`}
                        amount={restaurantBillingUyVm.iva}
                    />
                </TableBody>
            </Table>
        </TableContainer>
    );
}

type Props = {
    restaurantBillingUyVm: RestaurantBillingUyVm;
};

const useStyles = makeStyles({
    table: {},
});
