import { makeStyles } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import * as React from 'react';
import { useForm } from 'react-hook-form';
import { Button } from 'src/components/Button';
import { Dialog } from 'src/components/Dialog';
import { DialogActions } from 'src/components/DialogActions';
import { Form } from 'src/components/form/Form';
import { FormPasswordField } from 'src/components/form/FormPasswordField';
import { UserType } from 'src/constants/UserType';
import { translate } from 'src/i18n/translate';
import { appLayout } from 'src/theme/AppLayout';
import { isDevelopmentEnvironment } from 'src/utils/environment/isDevelopmentEnvironment';
import { isProductionEnvironment } from 'src/utils/environment/isProductionEnvironment';
import { removeSpaceCharacters } from 'src/utils/string/removeSpaceCharacters';

export function PasswordDialog({ open, customPassword, onClose, onSuccess, onFailed }: Props): React.ReactElement {
    const classes = useStyles();
    const form = useForm();
    const {
        formState: { isSubmitting },
        watch,
    } = form;

    const securityCode = watch('securityCode');
    const correctSecurityCodeEntered = removeSpaceCharacters(securityCode) === (customPassword ?? '9999') || (isDevelopmentEnvironment() && removeSpaceCharacters(securityCode) === '1111');

    const handleClose = () => {
        if (isSubmitting) return;
        onClose();
        onFailed();
    };

    const onSubmit = async (form: any) => {
        if (!correctSecurityCodeEntered) return;
        onClose();
        onSuccess?.();
    };

    return (
        <Dialog classes={{ dialog: classes.dialog }} title={translate('Password')} open={open} onClose={handleClose}>
            <Form form={form} onSubmit={onSubmit} enabledForUserTypeView={true} style={appLayout.dialog.form}>
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <FormPasswordField
                            name='securityCode'
                            label={translate('Security Code')}
                            placeholder='****'
                            helperText={translate('Please enter the security code in order to access the content.')}
                        />
                    </Grid>
                    <Grid item xs={12} container justify='center'>
                        {!isProductionEnvironment() && (
                            <Typography variant='subtitle2' align='center' color='error'>
                                {translate('Your in a dev environment. Use can use 1111 as password or the real password.')}
                            </Typography>
                        )}
                    </Grid>
                </Grid>
                <DialogActions className={classes.dialogActions}>
                    <Button classes={{ button: classes.button }} secondary onClick={handleClose} disabled={isSubmitting}>
                        {translate('Cancel')}
                    </Button>
                    <Button classes={{ button: classes.button }} type='submit' disabled={!correctSecurityCodeEntered || isSubmitting}>
                        {translate('Continue')}
                    </Button>
                </DialogActions>
            </Form>
        </Dialog>
    );
}

const useStyles = makeStyles((theme) => ({
    dialog: {
        width: '50vw',
    },
    dialogActions: {
        marginTop: 20,
    },
    button: {
        width: 200,
    },
}));

type Props = {
    open: boolean;
    customPassword?: string;
    allowedUserTypes?: Array<UserType>;
    onSuccess?: any;
    onFailed: any;
    onClose: any;
};
