import { Dialog, DialogActions, DialogContent, DialogTitle, makeStyles } from '@material-ui/core';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Button } from 'src/components/Button';
import { Form } from 'src/components/form/Form';
import { FormRadio } from 'src/components/form/FormRadio';
import { FormRadioGroup } from 'src/components/form/FormRadioGroup';
import { FormTextField } from 'src/components/form/FormTextField';
import { translate } from 'src/i18n/translate';
import { isArray } from 'src/utils/array/isArray';

export function SelectItemDialog({ open, title, items, defaultSelectedItem, renderItem, onClose, onSuccess }: Props): React.ReactElement {
    const classes = useStyles();
    const form = useForm();
    const { watch } = form;

    const filter = watch('filter');

    const [selectedItem, setSelectedItem] = useState(defaultSelectedItem ?? []);

    const itemsFiltered = items.filter((item) => {
        if (!filter) return true;

        const regExp = new RegExp(filter, 'gi');
        return item.searchableBy.match(regExp);
    });

    useEffect(() => {
        if (defaultSelectedItem && isArray(defaultSelectedItem)) {
            setSelectedItem(defaultSelectedItem);
        }
    }, [defaultSelectedItem]);

    const handleSelect = (itemIndex: number) => {
        const itemValue = itemsFiltered[itemIndex].item;
        setSelectedItem(itemValue);
    };

    const handleClose = () => {
        setSelectedItem(defaultSelectedItem ?? []);
        onClose();
    };

    const handleSubmit = () => {
        onSuccess?.(selectedItem);
        handleClose();
    };

    return (
        <Dialog open={open} classes={{ paper: classes.container }}>
            <Form form={form} onSubmit={handleSubmit}>
                <DialogTitle classes={{ root: classes.title }}>{title}</DialogTitle>
                <DialogContent>
                    <FormTextField name='filter' placeholder={translate('Search...')} />
                    <div className={classes.itemsContainer}>
                        <FormRadioGroup name='item' row>
                            {itemsFiltered.map((item, idx) => {
                                return (
                                    <div className={classes.item} key={item.value}>
                                        <FormRadio value={item.value} label={renderItem(item)} onClick={() => handleSelect(idx)} />
                                    </div>
                                );
                            })}
                        </FormRadioGroup>
                    </div>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} secondary>
                        {translate('Cancel')}
                    </Button>
                    <Button type='submit'>{translate('Continue')}</Button>
                </DialogActions>
            </Form>
        </Dialog>
    );
}

const useStyles = makeStyles((theme) => ({
    container: {
        display: 'flex',
        flexDirection: 'column',
        width: 600,
        padding: 30,
        boxSizing: 'border-box',
        borderRadius: 12,
    },
    title: {
        color: theme.palette.primary.main,
        textAlign: 'center',
        '& h2': {
            fontFamily: theme.typography.bold,
        },
    },
    itemsContainer: {
        marginTop: 20,
        maxHeight: '50vh',
    },
    item: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        padding: '10px 5px',
        width: '100%',
        borderBottom: `1px solid ${theme.palette.secondary.dark}`,
        '&:hover': {
            backgroundColor: theme.palette.secondary.light,
        },
    },
    secondaryButton: {
        border: `1px solid ${theme.palette.primary.main}`,
        color: theme.palette.primary.main,
        fontFamily: theme.typography.semiBold,
        fontSize: 16,
        borderRadius: 8,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        textTransform: 'none',
        padding: 10,
    },
    cancelButton: {
        border: '1px solid #d9d9d9',
        color: '#9a9b9b',
        fontFamily: theme.typography.semiBold,
        fontSize: 16,
        borderRadius: 8,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        textTransform: 'none',
        padding: '10px 20px',
    },
    primaryButton: {
        backgroundColor: theme.palette.primary.main,
        color: 'white',
        fontFamily: theme.typography.semiBold,
        fontSize: 16,
        borderRadius: 8,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        textTransform: 'none',
        padding: '10px 20px',
    },
}));

type Props = {
    open: boolean;
    title: string;
    items: Array<{
        value: any;
        item: any;
        searchableBy: string;
    }>;
    defaultSelectedItem?: any;
    renderItem: any;
    onClose: any;
    onSuccess?: any;
};
