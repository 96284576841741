import { letseatmanagerApiMethod } from 'src/api/letseatmanagerApi';
import type { ApiSauceResponse } from 'src/api/types/ApiSauceResponse';
import type { RestaurantId } from 'src/types/Id';
import type { RestaurantTaxVm } from 'src/types/RestaurantTaxVm';

export function findRestaurantTaxesApi(request: FindRestaurantTaxesApiRequest): ApiSauceResponse<Array<RestaurantTaxVm>> {
    return letseatmanagerApiMethod('restaurantTax/findRestaurantTaxesApi', request);
}

export type FindRestaurantTaxesApiRequest = {
    restaurantId: RestaurantId;
};
