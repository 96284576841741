import { letseatmanagerApiMethod } from 'src/api/letseatmanagerApi';
import type { ApiSauceResponse } from 'src/api/types/ApiSauceResponse';
import type { RestaurantTaxId } from 'src/types/Id';
import type { RestaurantTaxVm } from 'src/types/RestaurantTaxVm';

export async function findRestaurantTaxApi(request: FindTaxApiRequest): ApiSauceResponse<RestaurantTaxVm> {
    return letseatmanagerApiMethod('restaurantTax/getRestaurantTaxApi', request);
}

export type FindTaxApiRequest = {
    restaurantTaxId: RestaurantTaxId;
};
