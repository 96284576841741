import * as React from 'react';
import { translateLogEventType } from 'src/components/form/utils/translateLogEventType';
import { Select } from 'src/components/Select';
import { LogEventType, LogEventTypes } from 'src/constants/LogEventType';
import { translate } from 'src/i18n/translate';

export function LogEventTypesSelect({ labelAsPlaceholder, multiselectable, onChange }: Props): React.ReactElement {
    const options = Object.values(LogEventTypes).map((logEventType: any) => ({ label: translateLogEventType(logEventType), value: logEventType }));

    return (
        <Select
            label={translate('Log Types')}
            name={'logEventTypes'}
            labelAsPlaceholder={labelAsPlaceholder}
            multiselectable={multiselectable}
            searchable
            options={options}
            onChange={(logEventTypes: Array<LogEventType>) => onChange(logEventTypes)}
        />
    );
}

type Props = {
    labelAsPlaceholder?: boolean;
    multiselectable?: boolean;
    onChange: (logEventTypes: Array<LogEventType>) => any;
};
