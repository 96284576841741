import { letseatmanagerApiMethod } from 'src/api/letseatmanagerApi';
import type { ApiSauceResponse } from 'src/api/types/ApiSauceResponse';
import { ManagerUserId, type RestaurantId } from 'src/types/Id';
import type { PosBusinessDayVm } from 'src/types/PosBusinessDayVm';

export async function openPosBusinessDayApi(request: OpenPosBusinessDayApiRequest): ApiSauceResponse<PosBusinessDayVm> {
    return letseatmanagerApiMethod('pos/openPosBusinessDayApi', request);
}

export type OpenPosBusinessDayApiRequest = {
    restaurantId: RestaurantId;
    managerUserId?: ManagerUserId;
    initialAmount: string;
};
