import { formatAsCurrencyNumber } from '@pidedirecto/ui/utils';
import { BigNumber } from 'bignumber.js';
import { translate } from 'src/i18n/translate';
import { addRestaurantInfoToReport } from 'src/services/printer/prints/utils/addRestaurantInfoToReport';
import { BuilderPrintColumn, printerInstructionsBuilder } from 'src/services/printer/prints/utils/printerInstructionsBuilder';
import type { PrinterInstruction } from 'src/services/printer/types/PrinterInstruction';
import type { BuyOrderSupplyVm, BuyOrderVm } from 'src/types/BuyOrderVm';
import type { RestaurantVm } from 'src/types/RestaurantVm';
import { formatDateTimeString } from 'src/utils/date/formatDateTimeString';

export function createBuyOrderTicketPrint({ buyOrder, restaurant }: Params): Array<PrinterInstruction> {
    const builder = printerInstructionsBuilder();

    addRestaurantInfoToReport(restaurant, builder);
    addHeaderBuyTickerData();
    addSuppliesBuyOrderData();
    addFooterBuyOrderData();

    return builder.build();

    function addHeaderBuyTickerData(): void {
        builder.addLineSeparator();
        builder.addCenteredBoldText(translate('BUY ORDER'));
        builder.addLineSeparator();
        builder.addCenteredBoldText(formatDateTimeString(buyOrder.createdAt));
        if (buyOrder.supplierBusinessName) builder.addSeparatedBoldLeftTexts(translate('Supplier'), buyOrder.supplierBusinessName ?? '');
    }

    function addSuppliesBuyOrderData(): void {
        const buyOrderColumnHeaders: Array<BuilderPrintColumn> = [
            { text: translate('Quant.'), percentageWidth: 0.15, textAlign: 'left' },
            { text: translate('Name'), percentageWidth: 0.5, textAlign: 'left' },
            { text: translate('Cost'), percentageWidth: 0.17, textAlign: 'right' },
            { text: translate('Total'), percentageWidth: 0.18, textAlign: 'right' },
        ];

        builder.addBoldColumns(buyOrderColumnHeaders);

        buyOrder.supplies?.forEach((supply: any) => {
            const supplyColumns = formattedSupplyItem(supply);
            builder.addColumns(supplyColumns);
        });
        builder.addBoldRightText(`${translate('Total')} ${formatAsCurrencyNumber(buyOrder.total, { country: restaurant.country as any })}`);
    }

    function addFooterBuyOrderData(): void {
        builder.addNewLine();
        builder.addNewLine();
        builder.addCenteredUnderLine();
        builder.addCenteredText(translate('Signature'));
        builder.addNewLine();
        builder.addLineSeparator();
        builder.addCenteredText(translate('With technology from Ambit.la'));
    }

    function formattedSupplyItem(supply: BuyOrderSupplyVm): Array<BuilderPrintColumn> {
        const total = BigNumber(supply.price).multipliedBy(supply.quantity).toString();
        const quantityLabel = `${supply.quantity}${supply.unitOfMeasurement ? translate(`UnitsOfMeasurement.${supply.unitOfMeasurement}`) : ''}`;

        return [
            { text: quantityLabel, percentageWidth: 0.15, textAlign: 'left' },
            { text: supply.supplyName, percentageWidth: 0.5, textAlign: 'left' },
            { text: formatAsCurrencyNumber(supply.price ?? 0, { country: restaurant.country as any }), percentageWidth: 0.17, textAlign: 'right' },
            { text: formatAsCurrencyNumber(total ?? 0, { country: restaurant.country as any }), percentageWidth: 0.18, textAlign: 'right' },
        ];
    }
}

type Params = {
    buyOrder: BuyOrderVm;
    restaurant: RestaurantVm;
};
