import { makeStyles } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Grid from '@material-ui/core/Grid';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { changeRappiIntegrationToPendingApi } from 'src/api/letseatadmin/rappiIntegration/changeRappiIntegrationToPendingApi';
import { integrateRestaurantWithRappiApi } from 'src/api/letseatadmin/rappiIntegration/integrateRestaurantWithRappiApi';
import { Form } from 'src/components/form/Form';
import { FormFixErrorHint } from 'src/components/form/FormFixErrorHint';
import { FormRadio } from 'src/components/form/FormRadio';
import { FormRadioGroup } from 'src/components/form/FormRadioGroup';
import { RappiIntegrationStatus, RappiIntegrationStatuses } from 'src/constants/RappiIntegrationStatus';
import { translate } from 'src/i18n/translate';
import { appLayout } from 'src/theme/AppLayout';
import type { RestaurantId } from 'src/types/Id';
import { alertKnownErrorOrSomethingWentWrong } from 'src/utils/alert/alertKnownErrorOrSomethingWentWrong';

export function ChangeRappiIntegrationStatusDialog({ open, restaurantId, rappiIntegrationStatus, onClose }: Props): React.ReactElement {
    const form = useForm();
    const classes = useStyles();
    const {
        formState: { isSubmitting },
        control,
    } = form;
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        if (open) load();
    }, [open]);

    const handleClose = () => {
        if (isSubmitting) return;
        onClose();
    };

    const handleExited = () => {
        setLoading(false);
    };

    const load = async () => {
        setLoading(true);
        console.log(rappiIntegrationStatus);
        form.reset({ rappiIntegrationStatus: rappiIntegrationStatus });
        setLoading(false);
    };

    const onSubmit = async (form: any) => {
        if (form.rappiIntegrationStatus === RappiIntegrationStatuses.ACTIVE && restaurantId) {
            const response = await integrateRestaurantWithRappiApi({
                restaurantId: restaurantId,
            });
            if (!response.ok) {
                alertKnownErrorOrSomethingWentWrong(response);
                return;
            }
        } else if (form.rappiIntegrationStatus === RappiIntegrationStatuses.PENDING && restaurantId) {
            const response = await changeRappiIntegrationToPendingApi({
                restaurantId: restaurantId,
            });
            if (!response.ok) {
                alertKnownErrorOrSomethingWentWrong(response);
                return;
            }
        }
        onClose();
    };

    return (
        <Dialog fullWidth scroll='paper' open={open} onClose={handleClose} onExited={handleExited}>
            <Form form={form} onSubmit={onSubmit} style={appLayout.dialog.form}>
                <DialogTitle>{translate('Rappi Integration Status').toUpperCase()}</DialogTitle>
                <DialogContent classes={{ root: classes.dialogContent }}>
                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                            <Grid item xs={12} style={{ textAlign: 'center' }}>
                                <FormRadioGroup name='rappiIntegrationStatus' label={translate('Rappi Integration Status')} row>
                                    <FormRadio label={translate('PENDING')} value={RappiIntegrationStatuses.PENDING} />
                                    <FormRadio label={translate('ACTIVE')} value={RappiIntegrationStatuses.ACTIVE} />
                                </FormRadioGroup>
                            </Grid>
                        </Grid>
                    </Grid>
                    <FormFixErrorHint />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} disabled={isSubmitting}>
                        {translate('Cancel').toUpperCase()}
                    </Button>
                    <Button color='primary' type='submit' disabled={loading || isSubmitting}>
                        {isSubmitting ? translate('Changing').toUpperCase() : translate('Change').toUpperCase()}
                    </Button>
                </DialogActions>
            </Form>
        </Dialog>
    );
}

const useStyles = makeStyles((theme) => ({
    dialogContent: {
        overflow: 'hidden',
    },
}));

type Props = {
    open: boolean;
    restaurantId?: RestaurantId;
    rappiIntegrationStatus?: RappiIntegrationStatus;
    onClose: any;
};
