import type { UnitOfMeasurement } from 'src/constants/UnitOfMeasurement';
import { UnitsOfMeasurementTranslated } from 'src/constants/UnitOfMeasurementTranslated';
import { translate } from 'src/i18n/translate';
import type { BuyOrder } from 'src/reducers/inventoryReducer';
import { csvToObjects } from 'src/utils/csv/csvToObjects';

export async function convertCsvToBuyOrder(files: Array<any>): Promise<Array<Array<BuyOrder>> | undefined> {
    const result: Array<Array<BuyOrder>> = [];

    for (const file of files) {
        const rows = await csvToObjects<CsvBuyOrder>(file);
        const requiredColumns = ['SKU', 'CANTIDAD', 'UNIDAD', 'PRECIO'];

        const validRows = rows.filter((row) => areRequiredColumnsPresent(row, requiredColumns));

        if (validRows.length === 0) {
            alert(translate('Please upload a CSV file with columns named SKU, QUANTITY, UNIT and PRICE'));
            return;
        }

        result.push(rows.map(transformRow));
    }

    return result;
}

function areRequiredColumnsPresent(row: CsvBuyOrder, requiredColumns: string[]): boolean {
    const cleanedRow = cleanRow(row);
    return requiredColumns.every((column) => !!cleanedRow[column]);
}

function cleanRow(row: CsvBuyOrder) {
    const cleanedRow: Record<string, any> = {};
    for (const key in row) {
        const cleanedKey = key.trim();
        cleanedRow[cleanedKey] = row[key as keyof typeof row];
    }
    return cleanedRow;
}

function transformRow(row: CsvBuyOrder): BuyOrder {
    return {
        sku: row['SKU'],
        quantity: row['CANTIDAD'],
        unit: UnitsOfMeasurementTranslated[row['UNIDAD'] as keyof typeof UnitsOfMeasurementTranslated],
        price: row['PRECIO'],
    };
}

type CsvBuyOrder = {
    SKU: string;
    CANTIDAD: string;
    UNIDAD: UnitOfMeasurement;
    PRECIO: string;
};
