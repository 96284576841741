import { letseatmanagerApiMethod } from 'src/api/letseatmanagerApi';
import type { ApiSauceResponse } from 'src/api/types/ApiSauceResponse';
import type { RestaurantTaxId } from 'src/types/Id';

export function removeRestaurantTaxApi(request: RemoveTaxApiRequest): ApiSauceResponse<void> {
    return letseatmanagerApiMethod('restaurantTax/removeRestaurantTaxApi', request);
}

export type RemoveTaxApiRequest = {
    restaurantTaxId: RestaurantTaxId;
};
