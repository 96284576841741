import Chip from '@material-ui/core/Chip';
import IconButton from '@material-ui/core/IconButton';
import { withStyles } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';
import LabelIcon from '@material-ui/icons/Label';
import RefreshIcon from '@material-ui/icons/Refresh';
import SendIcon from '@material-ui/icons/Send';
import max from 'lodash/max';
import MUIDataTable from 'mui-datatables';
import * as React from 'react';
import { letseatmanagerApiDeprecated } from 'src/api/letseatmanagerApiDeprecated';
import { CustomToolbarSelect } from 'src/components/mui-datatables/CustomToolbarSelect';
import { DownloadCsvToolbarButton } from 'src/components/mui-datatables/DownloadCsvToolbarButton';
import { translate } from 'src/i18n/translate';
import type { OfficeDeliveryItemVm } from 'src/scenes/letseatadmin/OfficeDeliveryPage';
import { formatDateTimeString } from 'src/utils/date/formatDateTimeString';
import { createFilteredOnDownload } from 'src/utils/mui-datatables/createFilteredOnDownload';
import { createHandleClickDownloadCsv } from 'src/utils/mui-datatables/createHandleClickDownloadCsv';
import { getSelectedRows } from 'src/utils/mui-datatables/getSelectedRows';
import { getSelectedRowsData } from 'src/utils/mui-datatables/getSelectedRowsData';
import { deepEqual } from 'src/utils/object/deepEqual';
import { createAndSaveLabels } from 'src/utils/office-delivery/createAndSaveLabels';
import { isMobileApp } from 'src/utils/reactNative/isMobileApp';
import { sendDeliveryItemLabelsMessageToMobileApp } from 'src/utils/reactNative/sendDeliveryItemLabelsMessageToMobileApp';
import { duplicates } from 'src/utils/reduce/duplicates';

export default withStyles((theme) => ({
    table: {
        whiteSpace: 'nowrap',
        '& tr': { cursor: 'pointer' },
        '& tr:hover': { backgroundColor: 'rgba(0, 0, 0, 0.07) !important' },
    },
    toolbar: {
        paddingRight: theme.spacing(3),
    },
}))(
    class OfficeDeliveryItemsTable extends React.Component<Props, State> {
        table: any; // TODO: fix correct type

        state: any = {
            displayData: [],
            searchText: '',
            filterList: [],
            columns: [],
            rowsSelected: false,
        };

        shouldComponentUpdate(nextProps: Readonly<Props>, nextState: Readonly<State>, nextContext: any): boolean {
            if (nextState.rowsSelected) {
                return false;
            }
            return !deepEqual(this.props.officeDeliveryItems, nextProps.officeDeliveryItems);
        }

        handleChange = (action: any, tableState: any) => {
            if (action !== 'propsUpdate') {
                //Store new tableStatePersist only if not updating props
                this.setState({
                    searchText: tableState.searchText,
                    filterList: tableState.filterList, //An array of filters for all columns
                    columns: tableState.columns, //We can pull column-specific options from this array, like display and sortDirection
                });
            }
        };

        getColumns = () => {
            //Define all of the alert table's columns and their default props and options as per the mui-datatables documentation
            const maxNumberOfModifiers = max(this.props.officeDeliveryItems.map((item) => item.modifiers.length));
            const columns = [
                {
                    name: 'orderId',
                    label: ' ',
                    options: {
                        display: 'excluded',
                        filter: false,
                        searchable: true,
                    },
                },
                {
                    name: 'customerId',
                    label: ' ',
                    options: {
                        display: 'excluded',
                        filter: false,
                        searchable: true,
                    },
                },
                {
                    name: 'customerName',
                    label: translate('Customer'),
                    options: {
                        filter: false,
                        searchable: true,
                    },
                },
                {
                    name: 'customerMobileNumber',
                    label: translate('Mobile'),
                    options: {
                        filter: false,
                        searchable: true,
                    },
                },
                {
                    name: 'pickupStation',
                    label: translate('Pickup Station'),
                    options: {
                        filter: true,
                        searchable: true,
                    },
                },
                {
                    name: 'name',
                    label: translate('Dish'),
                    options: {
                        filter: false,
                        searchable: true,
                    },
                },
                ...[...Array(maxNumberOfModifiers).keys()].map((i) => ({
                    name: `modifier${i + 1}`,
                    label: `${translate('Modifier')} ${i + 1}`,
                    options: {
                        filter: false,
                        searchable: true,
                    },
                })),
                {
                    name: 'note',
                    label: translate('Note'),
                    options: {
                        filter: false,
                        searchable: true,
                    },
                },
                {
                    name: 'promoCode',
                    label: translate('Promo Code'),
                    options: {
                        filter: true,
                        searchable: false,
                    },
                },
                {
                    name: 'createdAt',
                    label: translate('Created'),
                    options: {
                        filter: false,
                        searchable: false,
                    },
                },
                {
                    name: 'pickupTime',
                    label: translate('Pickup Time'),
                    options: {
                        filter: true,
                        searchable: false,
                    },
                },
                {
                    name: 'paymentMethod',
                    label: translate('Payment Method'),
                    options: {
                        filter: true,
                        searchable: false,
                    },
                },
                {
                    name: 'orderStatus',
                    label: translate('Order Status'),
                    options: {
                        filter: false,
                        searchable: false,
                    },
                },
            ];

            //Loop thru columns and assign all column-specific settings that need to persist thru a data refresh
            for (let i = 0; i < columns.length; i++) {
                //Assign the filter list to persist
                (columns[i].options as any).filterList = this.state.filterList[i];
                if (this.state.columns[i] !== undefined) {
                    //If 'display' has a value in tableStatePersist, assign that, or else leave it alone
                    if (this.state.columns[i].hasOwnProperty('display')) (columns[i].options as any).display = this.state.columns[i].display;
                    //If 'sortDirection' has a value in tableStatePersist, assign that, or else leave it alone
                    if (this.state.columns[i].hasOwnProperty('sortDirection')) {
                        //The sortDirection prop only permits sortDirection for one column at a time
                        if (this.state.columns[i].sortDirection != 'none') (columns[i].options as any).sortDirection = this.state.columns[i].sortDirection;
                    }
                }
            }

            return columns;
        };

        getSearchText = () => {
            return this.state.searchText;
        };

        handleClickLabel = () => {
            const displayedOrderIds = this.table.state.displayData.map((row: any) => row.data[0]);
            const officeDeliveryItems = this.props.officeDeliveryItems.filter((item) => displayedOrderIds.includes(item.orderId));

            if (!isMobileApp()) {
                createAndSaveLabels(officeDeliveryItems, 'delivery-item-labels');
                return;
            }

            sendDeliveryItemLabelsMessageToMobileApp(officeDeliveryItems, 'delivery-item-labels');
        };

        render() {
            const { classes } = this.props;
            if (!this.props.officeDeliveryItems) {
                return null;
            }
            const maxNumberOfModifiers = max(this.props.officeDeliveryItems.map((item) => item.modifiers.length));
            return (
                <MUIDataTable
                    /* @ts-ignore */
                    ref={(ref) => (this.table = ref)}
                    className={classes.table}
                    title={this.props.title || translate('Office Delivery Items')}
                    data={this.props.officeDeliveryItems.map((officeDeliveryItem) => {
                        const row = {
                            orderId: officeDeliveryItem.orderId,
                            customerId: officeDeliveryItem.customerId,
                            customerName: officeDeliveryItem.customerName,
                            customerMobileNumber: officeDeliveryItem.customerMobileNumber,
                            pickupStation: officeDeliveryItem.pickupStation,
                            name: officeDeliveryItem.name,
                            note: officeDeliveryItem.note || '',
                            promoCode: officeDeliveryItem.promoCode || '',
                            createdAt: formatDateTimeString(officeDeliveryItem.createdAt, officeDeliveryItem.timeZone),
                            pickupTime: formatDateTimeString(officeDeliveryItem.pickupTime, officeDeliveryItem.timeZone),
                            paymentMethod: officeDeliveryItem.paymentMethod || '',
                            orderStatus: officeDeliveryItem.orderStatus,
                        } as const;
                        /* @ts-ignore */
                        [...Array(maxNumberOfModifiers).keys()].forEach((i: any) => (row[`modifier${i + 1}`] = officeDeliveryItem.modifiers[i]));
                        return row;
                    })}
                    /* @ts-ignore */
                    columns={this.getColumns()}
                    options={{
                        searchText: this.getSearchText(),
                        onTableChange: (action, tableState) => this.handleChange(action, tableState),
                        responsive: 'standard',
                        tableBodyMaxHeight: '500px',
                        selectableRows: 'multiple',
                        customToolbarSelect: (selectedRows, displayData) => {
                            const selectedRowsData = getSelectedRowsData(selectedRows, displayData);
                            const selectedOrderIds = selectedRowsData.map((d: any) => d[0]);
                            const handleClickLabel = () => {
                                const officeDeliveryItemsFiltered = this.props.officeDeliveryItems.filter((item) => selectedOrderIds.includes(item.orderId));

                                if (!isMobileApp()) createAndSaveLabels(officeDeliveryItemsFiltered);
                                sendDeliveryItemLabelsMessageToMobileApp(officeDeliveryItemsFiltered, 'labels');
                            };
                            const handleClickSendPushNotification = () => {
                                if (
                                    window.confirm(
                                        translate(
                                            `You have selected ${selectedOrderIds.length} delivery items. Are you sure you want to send order delivered notification to the customers ordering them?`,
                                        ),
                                    )
                                ) {
                                    letseatmanagerApiDeprecated.driver.completeDeliveryOrders({ orderIds: selectedOrderIds.reduce(duplicates, [] as any) });
                                }
                            };
                            return (
                                <CustomToolbarSelect>
                                    <div style={{ position: 'absolute', top: -36, right: 0 }}>
                                        <Chip label={translate('WARNING Auto updating is paused when rows are selected')} color='primary' />
                                    </div>
                                    <DownloadCsvToolbarButton onClick={createHandleClickDownloadCsv(this, getSelectedRows(selectedRows, displayData))} />
                                    <Tooltip title='Labels'>
                                        <IconButton onClick={handleClickLabel}>
                                            <LabelIcon />
                                        </IconButton>
                                    </Tooltip>
                                    <Tooltip title='Send Notifications'>
                                        <IconButton onClick={handleClickSendPushNotification}>
                                            <SendIcon />
                                        </IconButton>
                                    </Tooltip>
                                </CustomToolbarSelect>
                            );
                        },
                        // setRowProps: (row) => {
                        //     return {
                        //         style: { backgroundColor: getOfficeDeliveryItemStatusColor(row[32], row[34]) },
                        //     };
                        // },
                        // onRowClick: (rowData: Array<string>, rowMeta: { dataIndex: number, rowIndex: number }) => {
                        //     history.push({
                        //         pathname: RoutePath.ADMIN_CUSTOMER.replace(`:customerId(${RegExps.uuid})`, rowData[0]),
                        //         search: history.location.search,
                        //     });
                        // },
                        onRowSelectionChange: (currentRowsSelected, allRowsSelected) => {
                            if (allRowsSelected.length) {
                                this.setState({ rowsSelected: true });
                            } else {
                                this.setState({ rowsSelected: false });
                            }
                        },
                        filterType: 'multiselect',
                        rowsPerPage: 100,
                        customToolbar: () => {
                            return (
                                <>
                                    <Tooltip title='Refresh'>
                                        <IconButton onClick={this.props.onClickRefresh}>
                                            <RefreshIcon />
                                        </IconButton>
                                    </Tooltip>
                                    <Tooltip title='Labels'>
                                        <IconButton onClick={this.handleClickLabel}>
                                            <LabelIcon />
                                        </IconButton>
                                    </Tooltip>
                                </>
                            );
                        },
                        onDownload: createFilteredOnDownload(this),
                        // filterList: [[], [], [], [], ['MEXICO']],
                    }}
                />
            );
        }
    },
) as React.ComponentType<any>;

type Props = {
    title?: string;
    officeDeliveryItems: Array<OfficeDeliveryItemVm>;
    onClickRefresh: any;
    classes: any;
};

type State = {
    displayData: Array<{
        data: Array<any>;
        dataIndex: number;
    }>;
    searchText: string;
    filterList: Array<any>;
    columns: Array<any>;
    rowsSelected: boolean;
};
