import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import Grid from '@material-ui/core/Grid';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';
import FavoriteIcon from '@material-ui/icons/Favorite';
import Rating from '@material-ui/lab/Rating';
import { formatAsCurrencyNumber } from '@pidedirecto/ui/utils';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { getSurveyApi, SurveyVm } from 'src/api/letseatadmin/survey/getSurveyApi';
import { solveSurveysApi } from 'src/api/letseatadmin/survey/solveSurveysApi';
import { Field, Form } from 'src/components/deprecated/react-final-form/Form';
import TextField from 'src/components/deprecated/react-final-form/TextField';
import { RefreshToolbarButton } from 'src/components/mui-datatables/RefreshToolbarButton';
import { SolveSurveysToolbarButton } from 'src/components/mui-datatables/SolveSurveysToolbarButton';
import { UpdatingContentProgress } from 'src/components/UpdatingContentProgress';
import { NegativeSurveyAnswers } from 'src/constants/NegativeSurveyAnswer';
import { PositiveSurveyAnswers } from 'src/constants/PositiveSurveyAnswer';
import { SurveyAnswer } from 'src/constants/SurveyAnswer';
import { TimeZones } from 'src/constants/TimeZone';
import { translate } from 'src/i18n/translate';
import type { SurveyId } from 'src/types/Id';
import { alertKnownErrorOrSomethingWentWrong } from 'src/utils/alert/alertKnownErrorOrSomethingWentWrong';
import { formatDateTimeStringReadable } from 'src/utils/date/formatDateTimeStringReadable';
import { translateSurveyAnswer } from 'src/utils/translate/translateSurveyAnswer';

export default function SurveyDetails({ surveyId, onChangeSurvey }: Props): React.ReactElement {
    const classes = useStyles();
    const [loading, setLoading] = useState(false);
    const [survey, setSurvey] = useState<SurveyVm>();

    useEffect(() => {
        load();
    }, [surveyId]);

    const load = async () => {
        if (!surveyId) {
            return;
        }
        setLoading(true);
        const response = await getSurveyApi({ surveyId });
        if (!response.ok) {
            setLoading(false);
            alertKnownErrorOrSomethingWentWrong(response);
            return;
        }
        const survey: SurveyVm = response.data;
        setSurvey(response.data);
        setLoading(false);
        onChangeSurvey && onChangeSurvey(survey);
    };

    const positiveAnswers = survey?.surveyAnswers?.filter((surveyAnswer: SurveyAnswer) => Object.values(PositiveSurveyAnswers).includes(surveyAnswer as any));
    const negativeAnswers = survey?.surveyAnswers?.filter((surveyAnswer: SurveyAnswer) => Object.values(NegativeSurveyAnswers).includes(surveyAnswer as any));

    return (
        <div style={{ position: 'relative' }}>
            <UpdatingContentProgress loading={loading} />
            <Card classes={{ root: classes.root }}>
                <CardHeader
                    title={
                        <Typography display='block' gutterBottom variant='h6'>
                            {translate('Survey Details')}
                        </Typography>
                    }
                    action={
                        <div>
                            {survey?.unsolved && (
                                <SolveSurveysToolbarButton
                                    onClick={async () => {
                                        setLoading(true);
                                        const solveResponse = await solveSurveysApi({ surveyIds: [survey?.surveyId] });
                                        if (!solveResponse.ok) {
                                            setLoading(false);
                                            alertKnownErrorOrSomethingWentWrong(solveResponse);
                                            return;
                                        }
                                        const surveyResponse = await getSurveyApi({ surveyId: survey?.surveyId });
                                        if (!surveyResponse.ok) {
                                            setLoading(false);
                                            alertKnownErrorOrSomethingWentWrong(surveyResponse);
                                            return;
                                        }
                                        const update = surveyResponse.data;
                                        setSurvey(update);
                                        setLoading(false);
                                        onChangeSurvey && onChangeSurvey(update);
                                    }}
                                />
                            )}
                            <RefreshToolbarButton onClick={load} />
                        </div>
                    }
                />
                <CardContent>
                    <Form
                        onSubmit={() => {}}
                        initialValues={
                            survey
                                ? {
                                      survey: {
                                          ...survey,
                                          tip: formatAsCurrencyNumber(survey.tip ?? '0'),
                                          surveyAnswers: survey.surveyAnswers ? survey.surveyAnswers.map(translateSurveyAnswer).join(', ') : '',
                                          createdAt: formatDateTimeStringReadable(survey.createdAt, TimeZones.AMERICA_MONTERREY),
                                          answeredAt: formatDateTimeStringReadable(survey.answeredAt, TimeZones.AMERICA_MONTERREY),
                                          solvedAt: formatDateTimeStringReadable(survey.solvedAt, TimeZones.AMERICA_MONTERREY),
                                      },
                                  }
                                : {}
                        }
                        render={({ handleSubmit, submitting, pristine, values }) => (
                            <Grid container spacing={3}>
                                <Grid item xs={12} sm={6}>
                                    {survey?.unsolved && (
                                        <Grid container spacing={3}>
                                            <Grid item xs={6} sm={4}>
                                                <Field name='survey.unsolved' component={TextField} label={translate('Unresolved')} fullWidth disabled />
                                            </Grid>
                                        </Grid>
                                    )}

                                    <Grid container spacing={3}>
                                        <Grid item xs={6} sm={4}>
                                            <br />
                                        </Grid>
                                    </Grid>

                                    <Grid container spacing={3}>
                                        <Grid item xs={6} sm={4}>
                                            <Field name='survey.rating' component={TextField} label={translate('Rating')} fullWidth disabled />
                                            <HeartRating
                                                name='customized-color'
                                                value={survey?.rating}
                                                getLabelText={(value) => `${value} Heart${value !== 1 ? 's' : ''}`}
                                                precision={0.01}
                                                readOnly={true}
                                                icon={<FavoriteIcon fontSize='inherit' />}
                                            />
                                        </Grid>
                                    </Grid>

                                    <Grid container spacing={3}>
                                        <Grid item xs={6} sm={4}>
                                            <Field name='survey.tip' component={TextField} label={translate('Tip')} fullWidth disabled />
                                        </Grid>
                                    </Grid>
                                </Grid>

                                <Grid item xs={12} sm={6}>
                                    {!!positiveAnswers?.length && (
                                        <Grid container direction='row' justify='flex-start' alignItems='flex-end'>
                                            <Table size='small' aria-label='a dense table'>
                                                <TableHead>
                                                    <TableRow>
                                                        <TableCell>Answer (Good Experience)</TableCell>
                                                    </TableRow>
                                                </TableHead>
                                                <TableBody>
                                                    {positiveAnswers.map((surveyAnswer) => (
                                                        <TableRow key={surveyAnswer}>
                                                            <TableCell component='th' scope='row'>
                                                                {translateSurveyAnswer(surveyAnswer as any)}
                                                            </TableCell>
                                                        </TableRow>
                                                    ))}
                                                </TableBody>
                                            </Table>
                                        </Grid>
                                    )}

                                    {!!negativeAnswers?.length && (
                                        <Grid container direction='row' justify='flex-start' alignItems='flex-end'>
                                            <Table size='small' aria-label='a dense table'>
                                                <TableHead>
                                                    <TableRow>
                                                        <TableCell>Answer (Bad Experience)</TableCell>
                                                    </TableRow>
                                                </TableHead>
                                                <TableBody>
                                                    {negativeAnswers.map((surveyAnswer) => (
                                                        <TableRow key={surveyAnswer}>
                                                            <TableCell component='th' scope='row'>
                                                                {translateSurveyAnswer(surveyAnswer as any)}
                                                            </TableCell>
                                                        </TableRow>
                                                    ))}
                                                </TableBody>
                                            </Table>
                                        </Grid>
                                    )}

                                    {survey?.otherAnswer && (
                                        <Grid container direction='row' justify='flex-start' alignItems='flex-end'>
                                            <Table size='small' aria-label='a dense table'>
                                                <TableHead>
                                                    <TableRow>
                                                        <TableCell>Other Answer</TableCell>
                                                    </TableRow>
                                                </TableHead>
                                                <TableBody>
                                                    <TableRow>
                                                        <TableCell component='th' scope='row'>
                                                            {survey.otherAnswer}
                                                        </TableCell>
                                                    </TableRow>
                                                </TableBody>
                                            </Table>
                                        </Grid>
                                    )}
                                </Grid>
                            </Grid>
                        )}
                    />
                </CardContent>
            </Card>
        </div>
    );
}

const HeartRating = withStyles({
    iconFilled: {
        color: '#ff6d75',
    },
    iconHover: {
        color: '#ff3d47',
    },
})(Rating);

const useStyles = makeStyles((theme) => ({
    linearProgress: {
        position: 'absolute',
        width: '100%',
        bottom: 0,
    },
    root: {
        paddingLeft: 8,
        paddingRight: 8,
    },
}));

type Props = {
    surveyId: SurveyId;
    onChangeSurvey?: (arg1: SurveyVm) => any;
};
