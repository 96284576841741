import * as React from 'react';

export function PaymentCashIcon({ title, color }: Props): React.ReactElement {
    return (
        <svg width='22' height='19' viewBox='0 0 22 19' fill='none' xmlns='http://www.w3.org/2000/svg'>
            {title && <title>{title}</title>}
            <path
                d='M1 15.7091C6.47242 15.7047 11.921 16.4293 17.2021 17.864C17.9477 18.0671 18.6923 17.5132 18.6923 16.7399V15.7091M2.53846 1.09375V1.86298C2.53846 2.06699 2.45742 2.26265 2.31316 2.40691C2.1689 2.55117 1.97324 2.63221 1.76923 2.63221H1M1 2.63221V2.2476C1 1.61067 1.51692 1.09375 2.15385 1.09375H19.4615M1 2.63221V11.863M19.4615 1.09375V1.86298C19.4615 2.2876 19.8062 2.63221 20.2308 2.63221H21M19.4615 1.09375H19.8462C20.4831 1.09375 21 1.61067 21 2.2476V12.2476C21 12.8845 20.4831 13.4014 19.8462 13.4014H19.4615M1 11.863V12.2476C1 12.5536 1.12157 12.8471 1.33795 13.0635C1.55434 13.2799 1.84783 13.4014 2.15385 13.4014H2.53846M1 11.863H1.76923C1.97324 11.863 2.1689 11.944 2.31316 12.0883C2.45742 12.2325 2.53846 12.4282 2.53846 12.6322V13.4014M19.4615 13.4014V12.6322C19.4615 12.4282 19.5426 12.2325 19.6868 12.0883C19.8311 11.944 20.0268 11.863 20.2308 11.863H21M19.4615 13.4014H2.53846M14.0769 7.2476C14.0769 8.06365 13.7527 8.84627 13.1757 9.42331C12.5987 10.0003 11.8161 10.3245 11 10.3245C10.1839 10.3245 9.40132 10.0003 8.82429 9.42331C8.24725 8.84627 7.92308 8.06365 7.92308 7.2476C7.92308 6.43155 8.24725 5.64892 8.82429 5.07188C9.40132 4.49485 10.1839 4.17067 11 4.17067C11.8161 4.17067 12.5987 4.49485 13.1757 5.07188C13.7527 5.64892 14.0769 6.43155 14.0769 7.2476ZM17.1538 7.2476H17.1621V7.2558H17.1538V7.2476ZM4.84615 7.2476H4.85436V7.2558H4.84615V7.2476Z'
                stroke={color ?? 'white'}
                strokeWidth='1.5'
                strokeLinecap='round'
                strokeLinejoin='round'
            />
        </svg>
    );
}

type Props = {
    title?: string;
    color?: string;
};
