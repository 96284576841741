import type { TheRestaurantVm } from 'src/api/letseatmanager/theRestaurant/getTheRestaurantApi';
import { letseatmanagerApiMethod } from 'src/api/letseatmanagerApi';
import type { ApiSauceResponse } from 'src/api/types/ApiSauceResponse';
import type { ServiceType } from 'src/constants/ServiceType';
import type { RestaurantId } from 'src/types/Id';

export async function changeTheRestaurantApi(request: ChangeTheRestaurantApiRequest): ApiSauceResponse<TheRestaurantVm> {
    return letseatmanagerApiMethod('theRestaurant/changeTheRestaurantApi', request);
}

export type ChangeTheRestaurantApiRequest = {
    restaurantId: RestaurantId;
    phoneNumber?: string;
    secondaryPhoneNumber?: string;
    imageUrl?: string;
    imageUrlMobile?: string;
    kioskBackgroundImageUrl?: string;
    backgroundImageUrl?: string;
    logoImageUrl?: string;
    ticketLogoImage?: string;
    eatHereEnabled: boolean;
    serviceType: ServiceType;
    takeAwayEnabled: boolean;
    deliveryEnabled: boolean;
    autoCallRestaurantEnabled?: boolean;
    plannedOrdersEnabled: boolean;
    posRestaurantZonesEnabled?: boolean;
    posShowTablesEnabled?: boolean;
    posTableRequiredEnabled?: boolean;
    posCustomersRequiredEnabled?: boolean;
    posCustomerNameRequiredOnTakeAwayOrdersEnabled?: boolean;
    includeBreakdownIntegrationsInPosBusinessDayEnabled?: boolean;
    posOnlyCashInCashRegisterReportEnabled?: boolean;
    posPassword?: string;
    maximumCashAllowedInCashRegisterEnabled?: boolean;
    openCashRegisterButtonEnabled?: boolean;
    maximumCashAllowedInCashRegister?: string;
    appOrdersCashbackEnabled?: boolean;
    appOrdersCashback?: string;
    restaurantColors?: Array<string>;
    autoAcceptOrdersTime?: number;
    autoAcceptRappiOrdersTime?: number;
    autoAcceptUberEatsOrdersTime?: number;
    autoAcceptDidiFoodsOrdersTime?: number;
    preparationTimeInMinutes?: number;
    temporarilyOffline?: boolean;
    temporarilyWithoutDelivery?: boolean;
    externalDeliveryEstimatedTime?: string;
    printAutoAcceptedOrders?: boolean;
    ordersWithoutIvaEnabled?: boolean;
    restaurantTaxManagementEnabled?: boolean;
    hideCartEnabled?: boolean;
    minimumOrderAmount?: string;
    maximumOrderAmount?: string;
    fakedProductDiscountPercentage?: string;
    subModifiersEnabled?: boolean;
    newMenuManagementEnabled?: boolean;
    facebookPixelId?: string;
    googleAnalyticsId?: string;
    kioskPayWithCreditCardInCounterEnabled?: boolean;
    clockInEmployeeDayShiftEnabled?: boolean;
    clockInMessage?: string;
    clockOutMessage?: string;
};
