import * as React from 'react';
import { findSuppliesByRestaurantIdApi } from 'src/api/letseatmanager/supply/findSuppliesByRestaurantIdApi';
import { FormAutocomplete } from 'src/components/form/FormAutocomplete';
import type { Variant } from 'src/components/form/FormTypes';
import { SupplyId, type RestaurantId } from 'src/types/Id';
import type { SupplyVm } from 'src/types/SupplyVm';
import { useLoadApi } from 'src/utils/react/useLoadApi';
import { useSelector } from 'src/utils/react/useSelector';

export function FormSupplyAutocomplete({
    name,
    label,
    placeholder,
    helperText,
    defaultValue,
    disabled,
    required,
    variant,
    onChange,
    restaurantIdToRequest,
    filterOptions,
    classes: classesProp,
}: Props): React.ReactElement {
    const restaurantId = useSelector((state) => state.app.restaurantId);

    const [loading, { supplies }] = useLoadApi(findSuppliesByRestaurantIdApi, { restaurantId: restaurantIdToRequest ?? restaurantId }, { initialValue: { supplies: [] } });

    const handleChange = (supplyId: SupplyId) => {
        const supply = supplies.find((supply) => supply.supplyId === supplyId);
        onChange?.(supply!);
    };

    const excludedSupplies = filterOptions ? supplies?.filter(filterOptions) : supplies;

    const options = required
        ? excludedSupplies.map((supply) => ({ label: supply.name + `(${supply.sku || ''})`, value: supply.supplyId }))
        : [{ label: '', value: null }, ...excludedSupplies.map((supply) => ({ label: supply.name + ` (${supply.sku || ''})`, value: supply.supplyId }))];

    return (
        <FormAutocomplete
            name={name}
            label={label}
            placeholder={placeholder}
            helperText={helperText}
            defaultValue={defaultValue}
            options={options}
            disabled={disabled || loading}
            required={required}
            variant={variant}
            onChange={handleChange}
        />
    );
}

type Props = {
    name: string;
    label?: string;
    placeholder?: string;
    helperText?: string;
    defaultValue?: string | null | undefined;
    disabled?: boolean;
    required?:
        | boolean
        | {
              value: number;
              message: string;
          };
    variant?: Variant;
    onChange?: (supply: SupplyVm) => any;
    restaurantIdToRequest?: RestaurantId;
    filterOptions?: (supply: SupplyVm, idx: number) => boolean;
    classes?: {
        input?: string;
    };
};
