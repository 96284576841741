import { makeStyles } from '@material-ui/core';
import * as React from 'react';
import { DateSelectDialog } from 'src/components/dialog/DateSelectDialog';
import { SecuredAndSubscribedPage } from 'src/components/page/SecuredAndSubscribedPage';
import { RolePermissions } from 'src/constants/RolePermission';
import { translate } from 'src/i18n/translate';
import { InventorySuppliersReport } from 'src/scenes/letseatmanager/inventorySuppliersReport/InventorySuppliersReport';
import { FilterReports } from 'src/scenes/letseatmanager/restaurantDashboard/FilterReports';

export function InventorySuppliersReportPage(): React.ReactElement {
    const classes = useStyles();

    return (
        <SecuredAndSubscribedPage rolePermission={RolePermissions.REPORTS_PAGE} title={translate('Inventory Suppliers Report')} className={classes.container}>
            <DateSelectDialog />
            <FilterReports />
            <InventorySuppliersReport />
        </SecuredAndSubscribedPage>
    );
}

const useStyles = makeStyles((theme) => ({
    container: {
        width: '100%',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        [theme.breakpoints.down('sm')]: {
            padding: 12,
        },
    },
    filterContainer: {
        paddingTop: 20,
    },
    tables: { gap: '200px' },
    kpis: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-evenly',
    },
    filterChip: {
        fontFamily: theme.typography.regular,
    },
    label: {
        fontFamily: theme.typography.regular,
    },
    selected: {
        border: '1px solid #5DE3BC',
        color: 'white',
        backgroundColor: '#5DE3BC',
    },
}));
