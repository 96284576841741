import { isMobileApp } from 'src/utils/reactNative/isMobileApp';

export function setRestaurantHasBluetoothPrintersInMobileApp(restaurantHasBluetoothPrinters: boolean) {
    if (!isMobileApp()) return;

    window.ReactNativeWebView.postMessage(
        JSON.stringify({
            type: 'SET_RESTAURANT_HAS_BLUETOOTH_PRINTERS',
            data: restaurantHasBluetoothPrinters,
        }),
    );
}
