import { pidedirectoReportsMethod } from 'src/api/pidedirectoReportsApi';
import type { ApiSauceResponse } from 'src/api/types/ApiSauceResponse';
import type { SalesType } from 'src/constants/SalesTypes';
import type { RestaurantId } from 'src/types/Id';
import { SalesByItemByManagerUserReportVm } from 'src/types/SalesByItemByManagerUserReportVm';

export async function getSalesByItemByManagerUserReportApi(request: GetKardexReportApiRequest): ApiSauceResponse<SalesByItemByManagerUserReportVm> {
    return pidedirectoReportsMethod('/getSalesByItemByManagerUserReportApi', request);
}

type GetKardexReportApiRequest = {
    restaurantIds: Array<RestaurantId>;
    fromDate: string;
    toDate: string;
    salesType: SalesType;
};
