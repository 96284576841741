import { letseatmanagerApiMethod } from 'src/api/letseatmanagerApi';
import type { ApiSauceResponse } from 'src/api/types/ApiSauceResponse';
import type { DriverId, RestaurantId } from 'src/types/Id';

export async function getRestaurantDriverMobileNumberOptionsApi(request: GetRestaurantDriverMobileNumberOptionsApiRequest): ApiSauceResponse<Array<DriverMobileNumberOptionVm>> {
    return letseatmanagerApiMethod('options/getRestaurantDriverMobileNumberOptionsApi', request);
}

export type GetRestaurantDriverMobileNumberOptionsApiRequest = {
    restaurantId: RestaurantId;
};

type DriverMobileNumberOptionVm = {
    label: string;
    value: DriverId;
};
