import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Grid from '@material-ui/core/Grid';
import LinearProgress from '@material-ui/core/LinearProgress';
import RadioGroup from '@material-ui/core/RadioGroup';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { Loader } from '@pidedirecto/ui';
import * as React from 'react';
import { withRouter } from 'react-router-dom';
import { letseatmanagerApiDeprecated } from 'src/api/letseatmanagerApiDeprecated';
import { Field, Form } from 'src/components/deprecated/react-final-form/Form';
import Radio from 'src/components/deprecated/react-final-form/Radio';
import TextField from 'src/components/deprecated/react-final-form/TextField';
import { PasswordRequiredContent } from 'src/components/PasswordRequiredContent';
import { Spacing } from 'src/components/spacing/Spacing';
import { history } from 'src/config/history';
import type { CustomerType } from 'src/constants/CustomerType';
import { translate } from 'src/i18n/translate';
import { appLayout } from 'src/theme/AppLayout';
import type { CustomerId, LanguageTag } from 'src/types/Id';
import { alertKnownErrorOrSomethingWentWrong } from 'src/utils/alert/alertKnownErrorOrSomethingWentWrong';
import { fullName } from 'src/utils/customer/fullName';
import { deleteUrlQueryParameter } from 'src/utils/history/deleteUrlQueryParameter';
import { getUrlQueryParameter } from 'src/utils/history/getUrlQueryParameter';
import { required } from 'src/utils/Validator';

export default withRouter<any, any>(
    withStyles((theme) => ({
        linearProgress: {
            position: 'absolute',
            width: '100%',
            bottom: 0,
        },
    }))(
        class CustomerCreditsDialog extends React.PureComponent<Props, State> {
            state: any = INITIAL_STATE;

            async componentDidMount(): Promise<void> {
                const customerId: CustomerId = getUrlQueryParameter(ADD_CREDITS, this.props.location);
                if (customerId) {
                    await this.openDialog(customerId);
                }
            }

            async componentDidUpdate(prevProps: Props) {
                const prevCustomerId: CustomerId = getUrlQueryParameter(ADD_CREDITS, prevProps.location);
                const customerId: CustomerId = getUrlQueryParameter(ADD_CREDITS, this.props.location);
                if (customerId && !prevCustomerId && !this.state.open) {
                    await this.openDialog(customerId);
                } else if (!customerId && prevCustomerId && this.state.open) {
                    this.closeDialog();
                }
            }

            handleClose = () => {
                if (this.state.submitting) {
                    return;
                }
                if (history.length <= 2) {
                    history.replace({
                        path: this.props.location.pathname,
                        search: deleteUrlQueryParameter(ADD_CREDITS),
                    });
                    return;
                }
                history.goBack();
            };

            handleExited = () => {
                this.setState(INITIAL_STATE as any);
            };

            openDialog = async (customerId: CustomerId) => {
                this.setState({ open: true });
                await this.load(customerId);
            };

            closeDialog = () => {
                this.setState({ open: false });
            };

            load = async (customerId: CustomerId) => {
                this.setState({ loading: true });
                const response = await letseatmanagerApiDeprecated.admin.getCustomer({ customerId });
                this.setState({ loading: false });
                if (!response.ok) {
                    alertKnownErrorOrSomethingWentWrong(response);
                    this.setState({ open: false });
                    return;
                }
                const customer: CustomerVm = response.data;
                this.setState({
                    customer: {
                        customerId: customer.customerId,
                        name: fullName(customer.firstName, customer.lastName),
                        credits: customer.credits || '0',
                    },
                });
            };

            onSubmit = async (form: any) => {
                this.setState({ submitting: true });
                const addOrRemoveCredits = form.addCredits === 'true' ? letseatmanagerApiDeprecated.admin.addCredits : letseatmanagerApiDeprecated.admin.removeCredits;
                const response = await addOrRemoveCredits({
                    customerId: form.customer.customerId,
                    amount: form.amount,
                });
                this.setState({ submitting: false });
                if (!response.ok) {
                    alertKnownErrorOrSomethingWentWrong(response);
                    return;
                }
                this.props.onCreditsAddedOrRemoved();
                this.handleClose();
            };

            getSubmitButtonText = (values: { addCredits: string }) => {
                if (values.addCredits === 'true') {
                    return this.state.submitting ? translate('Adding Credits').toUpperCase() : translate('Add Credits').toUpperCase();
                } else {
                    return this.state.submitting ? translate('Removing Credits').toUpperCase() : translate('Remove Credits').toUpperCase();
                }
            };

            render() {
                const { classes } = this.props;
                return (
                    <Dialog aria-labelledby='credits-dialog-title' fullWidth scroll='paper' open={this.state.open} onClose={this.handleClose} onExited={this.handleExited}>
                        <PasswordRequiredContent customPassword={'1248'}>
                            <Form
                                onSubmit={this.onSubmit}
                                initialValues={{
                                    customer: this.state.customer,
                                    addCredits: 'true',
                                }}
                                render={({ handleSubmit, submitting, pristine, values }) => (
                                    <form onSubmit={handleSubmit} noValidate style={appLayout.dialog.form}>
                                        <DialogTitle id='credits-dialog-title'>{translate('Manage Customer Credits').toUpperCase()}</DialogTitle>
                                        <DialogContent>{this.renderDialogContent(values as any)}</DialogContent>
                                        <DialogActions>
                                            <Button onClick={this.handleClose} disabled={this.state.submitting}>
                                                {translate('Cancel').toUpperCase()}
                                            </Button>
                                            <Button color='primary' type='submit' disabled={this.state.submitting}>
                                                {this.getSubmitButtonText(values as any)}
                                            </Button>
                                        </DialogActions>
                                        {this.state.submitting && <LinearProgress className={classes.linearProgress} />}
                                    </form>
                                )}
                            />
                        </PasswordRequiredContent>
                    </Dialog>
                );
            }

            renderDialogContent(values: { addCredits: string }) {
                if (this.state.loading) {
                    return <Loader size={40} loading={true} />;
                }
                return (
                    <>
                        <Grid container spacing={3}>
                            <Grid item xs={12}>
                                <Field name='customer.customerId' component={TextField} label={translate('Customer Id')} fullWidth disabled />
                            </Grid>
                            <Grid item xs={8}>
                                <Field name='customer.name' component={TextField} label={translate('Name')} fullWidth disabled />
                            </Grid>
                            <Grid item xs={4}>
                                <Field name='customer.credits' component={TextField} label={translate('Current Credits')} InputLabelProps={{ shrink: true }} fullWidth disabled />
                            </Grid>
                            <Grid item xs={12}>
                                <RadioGroup row>
                                    <FormControlLabel
                                        label='Add Credits'
                                        control={<Field name='addCredits' component={Radio} type='radio' color='primary' value='true' disabled={this.state.submitting} />}
                                    />
                                    <FormControlLabel
                                        label='Remove Credits'
                                        control={<Field name='addCredits' component={Radio} type='radio' color='primary' value='false' disabled={this.state.submitting} />}
                                    />
                                </RadioGroup>
                            </Grid>
                            <Grid item xs={12}>
                                <Field
                                    name='amount'
                                    component={TextField}
                                    label={values.addCredits === 'true' ? translate('Credits To Add') : translate('Credits To Remove')}
                                    fullWidth
                                    validate={required}
                                    disabled={this.state.submitting}
                                />
                            </Grid>
                        </Grid>

                        <Grid container justify='space-around'>
                            <Grid item>
                                <Spacing units={8} />
                                {this.state.errorMessage && (
                                    <Typography display='block' color='error'>
                                        {this.state.errorMessage}
                                    </Typography>
                                )}
                            </Grid>
                        </Grid>
                    </>
                );
            }
        },
    ),
) as React.ComponentType<any>;

export const ADD_CREDITS = 'add-credits';
const INITIAL_STATE = {
    open: false,
    customer: {
        customerId: '' as any,
        name: '',
        credits: '0',
    },
    loading: false,
    submitting: false,
} as const;

type Props = {
    classes: any;
    location: {
        pathname: string;
    };
    onCreditsAddedOrRemoved: any;
};

type State = {
    open: boolean;
    loading: boolean;
    submitting: boolean;
    customer: {
        customerId: CustomerId;
        name: string;
        credits: string;
    };
    errorMessage?: string;
};

type CustomerVm = {
    customerId: CustomerId;
    customerType: CustomerType;
    mobileNumber?: string;
    email?: string;
    firstName?: string;
    lastName?: string;
    creditCards?: number;
    credits?: string;
    language?: LanguageTag;
    fcmPermission?: boolean;
    downloadedAppAt?: Date;
    signedUpAt?: Date;
    addedFirstCreditCardAt?: Date;
};
