import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import * as React from 'react';
import { useContext, useRef } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { FormContext } from 'src/components/form/Form';
import type { Variant } from 'src/components/form/FormTypes';
import { getError } from 'src/components/form/utils/getError';
import { translate } from 'src/i18n/translate';
import { removeBlanks } from 'src/utils/array/removeBlanks';
import { duplicates } from 'src/utils/reduce/duplicates';
import { trim as _trim } from 'src/utils/string/trim';
import { upperCase as _upperCase } from 'src/utils/string/upperCase';

export function FormAutocompleteMultipleString({
    name,
    label,
    placeholder,
    helperText,
    defaultValue = [],
    trim = true,
    upperCase,
    options,
    freeSolo,
    selectOnCommaKey,
    disabled,
    required,
    variant,
    InputProps,
}: Props): React.ReactElement {
    const {
        errors,
        control,
        formState: { isSubmitting },
    } = useFormContext();
    const formContext = useContext(FormContext);
    const inputRef = useRef<HTMLInputElement>();

    return (
        <Controller
            render={({ value, onChange, ...props }) => (
                <Autocomplete
                    renderInput={({ InputProps: ParamsInputProps, InputLabelProps: ParamsInputLabelProps, inputProps, ...params }) => (
                        <TextField
                            {...params}
                            label={label}
                            placeholder={!value?.length ? placeholder : undefined}
                            helperText={getError(errors, name)?.message ?? helperText}
                            variant={variant}
                            inputRef={inputRef}
                            InputProps={{
                                ...ParamsInputProps,
                                ...InputProps,
                            }}
                            inputProps={{ ...inputProps, style: { textTransform: upperCase ? 'uppercase' : 'none', ...(inputProps as any)?.style } }}
                            InputLabelProps={{
                                ...ParamsInputLabelProps,
                                ...{ shrink: placeholder ? true : undefined },
                            }}
                            error={!!getError(errors, name)}
                            onKeyDown={(e) => {
                                // if ((e.key || e.code) === 'Enter') e.preventDefault(); // disable submit on enter
                                if (selectOnCommaKey && e.code === 'Comma') {
                                    e.preventDefault();
                                    if (!(e.target as any)?.value) return;
                                    const newValue = transformToOutput((e.target as any).value, trim, upperCase);
                                    if (value.includes(newValue)) {
                                        onChange(value);
                                        return;
                                    }
                                    onChange([...value, newValue]);
                                }
                            }}
                        />
                    )}
                    freeSolo={freeSolo}
                    autoSelect={true}
                    multiple
                    options={options}
                    getOptionLabel={(data) => data}
                    getOptionSelected={(option, value) => {
                        // console.log('getOptionSelected option = ', option);
                        // console.log('getOptionSelected value = ', value);
                        return transformToOutput(option, trim, upperCase) === transformToOutput(value, trim, upperCase);
                    }}
                    value={removeBlanks(value)?.map((value: any) => transformToOutput(value, trim, upperCase)) ?? []}
                    onChange={(e, data) => {
                        // console.log('onChange data = ', data);
                        if (selectOnCommaKey) {
                            onChange(
                                removeBlanks(data)
                                    .flatMap((value) => transformToOutput(value, trim, upperCase)?.split(','))
                                    .reduce<Array<any>>(duplicates, []),
                            );
                        } else {
                            onChange(removeBlanks(data).map((value) => transformToOutput(value, trim, upperCase)));
                        }
                    }}
                    /* @ts-ignore */
                    required={required}
                    disabled={isSubmitting || disabled || formContext.disabled}
                    fullWidth
                    {...props}
                />
            )}
            control={control}
            name={name}
            defaultValue={removeBlanks(defaultValue)?.map((value: string) => transformToOutput(value, trim, upperCase))}
            rules={
                required
                    ? {
                          validate: {
                              required: (value) => (value.length > 0 ? true : ((required as any)?.message ?? translate('This field is required'))),
                          },
                      }
                    : {}
            }
            onFocus={() => {
                // make focus on error work when disabled={isSubmitting || disabled}
                if (inputRef.current) {
                    inputRef.current.disabled = false;
                    inputRef.current.focus();
                }
            }}
        />
    );
}

function transformToInput(value?: string | null): string {
    return value ?? '';
}

export function transformToOutput(value?: string | null, trim?: boolean, upperCase?: boolean): string | null | undefined {
    let outputValue = value;
    if (trim) outputValue = _trim(outputValue);
    if (upperCase) outputValue = _upperCase(outputValue);
    return !outputValue ? null : outputValue;
}

export type Props = {
    name: string;
    label?: string;
    placeholder?: string;
    helperText?: string;
    defaultValue?: Array<string>;
    trim?: boolean;
    upperCase?: boolean;
    options: Array<string>;
    freeSolo?: boolean;
    selectOnCommaKey?: boolean;
    disabled?: boolean;
    required?:
        | boolean
        | {
              value: number;
              message: string;
          };
    variant?: Variant;
    InputProps?: any;
};
