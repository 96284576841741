import * as React from 'react';

export function DepositsIcon({ title }: Props): React.ReactElement {
    return (
        <svg width='40' height='40' viewBox='0 0 40 40' fill='none' xmlns='http://www.w3.org/2000/svg'>
            <title>{title}</title>
            <circle cx='20' cy='20' r='20' fill='#D9CAF3' />
            <path
                d='M13.2429 12.749V13.3716C13.2429 13.5367 13.1774 13.695 13.0609 13.8118C12.9443 13.9285 12.7863 13.9941 12.6215 13.9941H12M12 13.9941V13.6828C12 13.1674 12.4176 12.749 12.9322 12.749H26.9148M12 13.9941V21.4646M26.9148 12.749V13.3716C26.9148 13.7152 27.1932 13.9941 27.5363 13.9941H28.1577M26.9148 12.749H27.2255C27.7401 12.749 28.1577 13.1674 28.1577 13.6828V21.7759C28.1577 22.2914 27.7401 22.7097 27.2255 22.7097M12 21.4646V21.7759C12 22.0236 12.0982 22.2611 12.273 22.4362C12.4478 22.6113 12.6849 22.7097 12.9322 22.7097H13.2429M12 21.4646H12.6215C12.7863 21.4646 12.9443 21.5302 13.0609 21.647C13.1774 21.7637 13.2429 21.9221 13.2429 22.0872V22.7097M13.2429 22.7097H20.5M26 23.7476L24 21.2473M24 21.2473L22 23.7476M24 21.2473V27.251M22.5647 17.7294C22.5647 18.3898 22.3028 19.0232 21.8366 19.4902C21.3704 19.9572 20.7381 20.2196 20.0789 20.2196C19.4196 20.2196 18.7873 19.9572 18.3211 19.4902C17.855 19.0232 17.5931 18.3898 17.5931 17.7294C17.5931 17.0689 17.855 16.4356 18.3211 15.9686C18.7873 15.5016 19.4196 15.2392 20.0789 15.2392C20.7381 15.2392 21.3704 15.5016 21.8366 15.9686C22.3028 16.4356 22.5647 17.0689 22.5647 17.7294ZM25.0505 17.7294H25.0571V17.736H25.0505V17.7294ZM15.1073 17.7294H15.1139V17.736H15.1073V17.7294Z'
                stroke='#390099'
                strokeWidth='1.5'
                strokeLinecap='round'
                strokeLinejoin='round'
            />
        </svg>
    );
}

type Props = {
    title?: string;
};
