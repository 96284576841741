import { letseatadminApiMethod } from 'src/api/letseatadminApi';
import type { ApiSauceResponse } from 'src/api/types/ApiSauceResponse';
import type { City } from 'src/constants/City';
import type { DriverId } from 'src/types/Id';

export async function getDriversBillingApi(request: GetDriversBillingApiRequest): ApiSauceResponse<DriversBillingVm> {
    return letseatadminApiMethod('driverBilling/getDriversBillingApi', request);
}

type GetDriversBillingApiRequest = {
    from: string;
    to: string;
    cities?: Array<City>;
};

export type DriversBillingVm = {
    driversBilling: Array<DriverBillingVm>;
    cash: number;
    app: number;
    invoicedRestaurantDeliveryCost: number;
    total: number;
    rappiCargoOrders: number;
    rappiCargoDeliveryCost: number;
    rappiCargoOrderTotal: number;
    uberDaasOrders: number;
    uberDaasDeliveryCost: number;
    uberDaasOrdersTotal: number;
    deliveryCost: number;
    uberEatsOrders: number;
    uberEatsDeliveryCost: number;
    commission: number;
    deliveries: number;
    invoicedRestaurantDeliveryCostDeliveries: number;
    cancelled: number;
    delivered: number;
    bonus: number;
    bonuses: number;
    manualEarning: number;
    manualEarnings: number;
    driverReferral: number;
    driverReferrals: number;
    iva: number;
    isr: number;
    deposit: number;
};

export type DriverBillingVm = {
    driverId: DriverId;
    firstName: string;
    lastName: string;
    email: string;
    mobileNumber: string;
    curp: string;
    rfc: string;
    banned?: boolean;
    bannedUntil?: Date;
    claveId?: string;
    city?: string;
    cash: number;
    app: number;
    invoicedRestaurantDeliveryCost: number;
    total: number;
    clabe?: string;
    commission: number;
    deliveries: number;
    invoicedRestaurantDeliveryCostDeliveries: number;
    cancelled: number;
    delivered: number;
    bonus: number;
    bonuses: number;
    dynamicDeliveryEarning: number;
    deliveryCommission: number;
    deliveryCostBonusCash: number;
    deliveryCostBonusCard: number;
    manualEarning: number;
    manualEarnings: number;
    driverReferral: number;
    driverReferrals: number;
    iva: number;
    isr: number;
    deposit: number;
};
