import * as React from 'react';

export function NotesCheckIcon({ color }: Props): React.ReactElement {
    return (
        <svg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'>
            <path
                fillRule='evenodd'
                clipRule='evenodd'
                d='M6.25166 5H12.1917C12.9369 5.0011 13.6512 5.29791 14.1777 5.82523C14.7043 6.35256 15 7.0673 15 7.8125V15.625C15.663 15.625 16.2989 15.3616 16.7678 14.8928C17.2366 14.4239 17.5 13.788 17.5 13.125V5.09C17.5 3.83583 16.5625 2.7475 15.28 2.64C15.0932 2.62482 14.9062 2.61093 14.7192 2.59833C14.5082 2.19191 14.1897 1.85122 13.7984 1.61345C13.407 1.37568 12.9579 1.24996 12.5 1.25H11.25C10.7921 1.24996 10.343 1.37568 9.95163 1.61345C9.56029 1.85122 9.24177 2.19191 9.03083 2.59833C8.84333 2.61083 8.65583 2.625 8.47 2.64C7.21833 2.74583 6.295 3.785 6.25166 5ZM11.25 2.5C10.9185 2.5 10.6005 2.6317 10.3661 2.86612C10.1317 3.10054 10 3.41848 10 3.75H13.75C13.75 3.41848 13.6183 3.10054 13.3839 2.86612C13.1495 2.6317 12.8315 2.5 12.5 2.5H11.25Z'
                fill={color ?? '#63D6B3'}
            />
            <path
                fillRule='evenodd'
                clipRule='evenodd'
                d='M2.5 7.8125C2.5 6.94917 3.2 6.25 4.0625 6.25H12.1875C13.0508 6.25 13.75 6.95 13.75 7.8125V17.1875C13.75 18.05 13.05 18.75 12.1875 18.75H4.0625C3.6481 18.75 3.25067 18.5854 2.95765 18.2924C2.66462 17.9993 2.5 17.6019 2.5 17.1875V7.8125ZM10.4883 11.6408C10.592 11.5113 10.6399 11.3459 10.6217 11.1811C10.6034 11.0162 10.5203 10.8653 10.3908 10.7617C10.2613 10.658 10.0959 10.6101 9.93106 10.6283C9.76618 10.6466 9.61532 10.7297 9.51167 10.8592L7.44833 13.4392L6.69167 12.6833C6.57319 12.5729 6.41648 12.5128 6.25456 12.5157C6.09265 12.5185 5.93816 12.5841 5.82365 12.6986C5.70914 12.8132 5.64354 12.9676 5.64069 13.1296C5.63783 13.2915 5.69793 13.4482 5.80833 13.5667L7.05833 14.8167C7.1205 14.8788 7.19507 14.9271 7.27719 14.9585C7.35931 14.9899 7.44711 15.0036 7.53488 14.9987C7.62265 14.9938 7.7084 14.9705 7.78654 14.9302C7.86467 14.8899 7.93343 14.8337 7.98833 14.765L10.4883 11.64V11.6408Z'
                fill={color ?? '#63D6B3'}
            />
        </svg>
    );
}

type Props = {
    color?: string;
};
