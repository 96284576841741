import { PosTip } from 'src/types/PosTip';
import type { PosTipVm } from 'src/types/PosTipVm';
import { useSelector } from 'src/utils/react/useSelector';

export function useMapPosTips(): Array<PosTipVm> {
    const tips = useSelector((state) => state.pos.tips);
    const currency = useSelector((state) => state.app.restaurant?.currencyFormat?.currency);

    const mappedTips = tips
        ?.filter((tip: PosTip) => !tip.customerNumber)
        ?.map((tip: PosTip) => {
            const isMainCurrency = tip.restaurantCurrency?.currency === currency;

            return {
                tipType: tip.tipType,
                tipAmount: tip.tipAmount,
                tipPercentage: tip.tipPercentage,
                paymentMethod: tip.paymentMethod,
                customPaymentMethod: tip.customPaymentMethod,
                restaurantCurrencyAmount: !isMainCurrency ? tip.restaurantCurrencyAmount : undefined,
                restaurantCurrency: !isMainCurrency ? tip.restaurantCurrency : undefined,
            };
        });

    return mappedTips;
}
