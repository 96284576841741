import { makeStyles } from '@material-ui/core';
import { useEffect, useState } from 'react';
import * as React from 'react';
import { SECONDS } from 'src/constants/TimeUnit';
import { translate } from 'src/i18n/translate';
import { ConfirmCancelQPayTerminalPaymentDialog } from 'src/scenes/letseatmanager/pos/posPayment/posPaymentResumeButtons/ConfirmCancelQPayTerminalPaymentDialog';
import { useRefundPaymentTerminal } from 'src/services/paymentTerminal/useRefundWithPaymentTerminal';
import { classNames } from 'src/utils/react/classNames';

/**
 * @deprecated // TODO: replace by new component PaymentTerminalProcessProgressLightBox when payment and refund state is in a store
 */
export function RefundingLightBox({ onCancelPayment }: Props): React.ReactElement {
    const classes = useStyles();

    const [isTimeOutExceeded, setIsTimeOutExceeded] = useState(false);
    const [openConfirmCancelPayment, setOpenConfirmCancelPayment] = useState(false);

    const { refunding } = useRefundPaymentTerminal();

    const showRefundingLightBox = refunding;

    useEffect(() => {
        const timeout = setTimeOut();

        return () => clearOnUnmount(timeout);
    }, [refunding]);

    useEffect(() => {
        if (!refunding) {
            setIsTimeOutExceeded(false);
        }
    }, [refunding]);

    const clearOnUnmount = (timout: NodeJS.Timeout | undefined) => {
        setIsTimeOutExceeded(false);
        clearTimeout(timout);
    };

    const setTimeOut = () => {
        if (!refunding) return;

        return setTimeout(() => {
            if (!refunding) return;

            setIsTimeOutExceeded(true);
        }, 15 * SECONDS);
    };

    return (
        <>
            <ConfirmCancelQPayTerminalPaymentDialog open={openConfirmCancelPayment} onSuccess={onCancelPayment} onClose={() => setOpenConfirmCancelPayment(false)} />
            <section className={classNames(classes.lightBox, showRefundingLightBox ? classes.showRefundingLightBox : classes.hideRefundingLightBox)}>
                {showRefundingLightBox && (
                    <>
                        <h1 className={classes.refundingMessage}>{translate('Refunding in terminal ...')}</h1>
                        {isTimeOutExceeded && (
                            <div className={classes.troubleMessage}>
                                {translate('Problems with terminal?')}
                                <span className={classes.link} onClick={() => setOpenConfirmCancelPayment(true)}>
                                    {translate('Cancel payment')}
                                </span>
                            </div>
                        )}
                    </>
                )}
            </section>
        </>
    );
}

const useStyles = makeStyles((theme) => ({
    refundingMessage: {
        fontFamily: theme.typography.semiBold,
        fontSize: 28,
        color: '#fff',
        textAlign: 'center',
        [theme.breakpoints.up('sm')]: {
            fontSize: 32,
        },
    },

    link: {
        color: '#e85757bd',
        textDecoration: 'underline',
        cursor: 'pointer',
        marginLeft: 10,
    },
    troubleMessage: {
        fontFamily: theme.typography.regular,
        marginTop: 20,
        fontSize: 14,
        color: '#2E3748',
        [theme.breakpoints.up('sm')]: {
            fontSize: 18,
        },
    },
    lightBox: {
        display: 'flex',
        flexDirection: 'column',
        position: 'fixed',
        width: '100%',
        height: '100%',
        background: 'rgba(0, 0, 0, 0.4)',
        top: 0,
        left: 0,
        alignItems: 'center',
        justifyContent: 'center',
        zIndex: 150,
        transition: '.4s transform',
        webkitTransition: ' .4s transform',
        msTransition: '.4s transform',
    },
    hideRefundingLightBox: {
        transform: 'translate(100%)',
        webkitTransform: 'translate(100%)',
        msTransform: 'translate(100%)',
    },
    showRefundingLightBox: {
        transform: 'translate(0%)',
        webkitTransform: 'translate(0%)',
        msTransform: 'translate(0%)',
    },
}));

type Props = {
    onCancelPayment: any;
};
