import { BigNumber } from 'bignumber.js';
import parsePhoneNumber from 'libphonenumber-js';
import moment from 'moment';
import OpeningHours from 'opening_hours';
import { Bank, Banks } from 'src/constants/Bank';
import { translate } from 'src/i18n/translate';
import { RegExps } from 'src/utils/RegExps';
import { createRRule } from 'src/utils/rrule/createRRule';
import { trim } from 'src/utils/string/trim';

export const combine = (...validators: Array<(arg1: string) => string | undefined>): any => {
    return (value: any) => {
        for (const validator of validators) {
            const error = validator(value);
            if (error) {
                return error;
            }
        }
    };
};

export const required = (value: any): string | undefined => {
    const isRequired = translate('is required');
    if (typeof value === 'string' || value instanceof String) {
        return value && value.trim() ? undefined : isRequired;
    } else {
        return value ? undefined : isRequired;
    }
};

export const futureDate = (value: any): string | undefined => {
    if (!value) {
        return;
    }
    return moment(value).isAfter(new Date()) ? undefined : translate('Has to be in the future');
};

export const number = (value: any): string | undefined => {
    if (!value || !isNaN(value) || !value.trim()) {
        return;
    }
    return !isNaN(value.replace(',', '.') - parseFloat(value.replace(',', '.'))) ? undefined : translate('is not a number');
};

export const integer = (value: any): string | undefined => {
    if (!value || !trim(value)) {
        return;
    }
    if (BigNumber(value).isInteger()) {
        return;
    }
    return translate('is not an integer');
};

export const positive = (value: any): string | undefined => {
    if (!value || !trim(value)) {
        return;
    }
    if (BigNumber(value).isNaN()) {
        return;
    }
    if (BigNumber(value).isPositive()) {
        return;
    }
    return translate('is not a positive number');
};

export const greaterThanZero = (value: any): string | undefined => {
    if (!value || !trim(value)) {
        return;
    }
    if (BigNumber(value).isNaN()) {
        return;
    }
    if (BigNumber(value).isGreaterThan(0)) {
        return;
    }
    return translate('is not a number greater than zero');
};

export const greaterThanOrEqualToZero = (value: any): string | undefined => {
    if (!value || !trim(value)) {
        return;
    }
    if (BigNumber(value).isNaN()) {
        return;
    }
    if (BigNumber(value).isGreaterThanOrEqualTo(0)) {
        return;
    }
    return translate('is not a number equal to or greater than zero');
};

const REG_URL =
    /^(?:(?:(?:http|https):)?\/\/)(?:\S+(?::\S*)?@)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})).?)(?::\d{2,5})?(?:[/?#]\S*)?$/i;
export const url = (value: any): string | undefined => {
    if (!value || !value.trim()) {
        return undefined;
    }
    return REG_URL.test(value) ? undefined : translate('is not an url');
};

export const https = (value: any): string | undefined => {
    if (!value || !value.trim()) {
        return undefined;
    }
    return value.startsWith('https://') ? undefined : translate('is not an url starting with https://');
};

const REG_UUID = /^[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[0-9a-f]{4}-[0-9a-f]{12}$/i;
export const uuid = (value: any): string | undefined => {
    if (!value || !value.trim()) {
        return undefined;
    }
    return REG_UUID.test(value.trim()) ? undefined : translate('is not an id');
};

export const uuids = (value: any): string | undefined => {
    if (!value || !value.trim()) {
        return undefined;
    }
    const uuids = value.split(',');
    for (const uuid of uuids) {
        if (!REG_UUID.test(uuid.trim())) {
            return translate('is not a comma separated list of ids');
        }
    }
    return undefined;
};

export const hours = (value: any): string | undefined => {
    if (!value || !value.trim()) {
        return undefined;
    }

    try {
        new OpeningHours(value);
    } catch (e: any) {
        return translate('is not valid hours syntax, see https://wiki.openstreetmap.org/wiki/Key:opening_hours');
    }
    return undefined;
};

const REG_TIME_RANGE = new RegExp(RegExps.timeRange);
export const timeRange = (value: any): string | undefined => {
    if (!value || !value.trim()) {
        return undefined;
    }

    try {
        new OpeningHours(value);
    } catch (e: any) {
        return translate('is not a valid time range, must be in format 08:00-17:00');
    }
    if (!REG_TIME_RANGE.test(value.trim())) {
        return translate('is not a valid time range, must be in format 08:00-17:00');
    }
    return undefined;
};

export const rrule = (value: any): string | undefined => {
    if (!value || !value.trim()) {
        return undefined;
    }

    const rrule = createRRule(value);
    if (!rrule) {
        return translate('is not valid rrule syntax, see https://jakubroztocil.github.io/rrule/');
    }
    return undefined;
};

export const rruleStartDate = (value: any): string | undefined => {
    if (!value || !value.trim()) {
        return undefined;
    }

    if (!value.includes('DTSTART:')) {
        return translate('is not containing a start date, DTSTART, see https://jakubroztocil.github.io/rrule/');
    }
    return undefined;
};

export function maxCharacters(max: number): any {
    return (value: any) => {
        if (value?.length === undefined || value?.length === null) {
            return undefined;
        }
        if (value.length < max) {
            return translate('maximum @number characters', { number: `${max}` });
        }
    };
}

export function minCharacters(min: number): any {
    return (value: any) => {
        if (value?.length === undefined || value?.length === null) {
            return undefined;
        }
        if (value.length < min) {
            return translate('minimum @number characters', { number: `${min}` });
        }
    };
}

export function numberCharacters(equals: number): any {
    return (value: any) => {
        if (value?.length === undefined || value?.length === null || value === '') {
            return undefined;
        }
        if (value.length !== equals) {
            return translate('number of characters should be @number', { number: `${equals}` });
        }
    };
}

export function validBusinessName(businessName?: string | null): string | undefined {
    return minCharacters(1)(businessName);
}

export function validCurp(curp?: string | null): string | undefined {
    return combine(alphaNumericCharacters, numberCharacters(18))(trim(curp));
}

export function validClabe(bank?: Bank | null): any {
    return (clabe?: string | null) => {
        if (bank && bank !== Banks.BANORTE) {
            return combine(alphaNumericCharacters, numberCharacters(18))(trim(clabe));
        }
    };
}

export function validRfc(rfc?: string | null): string | undefined {
    return combine(alphaNumericCharacters, minCharacters(12), maxCharacters(13))(trim(rfc));
}

export function numericCharacters(value: any): string | undefined {
    if (!value || !value.trim()) {
        return undefined;
    }
    return /[0-9]/i.test(value.trim()) ? undefined : translate('must be numeric characters');
}

export function alphaNumericCharacters(value: any): string | undefined {
    if (!value || !value.trim()) {
        return undefined;
    }
    return /[0-9a-zA-Z]*/g.test(value.trim()) ? undefined : translate('must be alpha numeric characters');
}

export function alphaCharacters(value: any): string | undefined {
    if (!value || !value.trim()) {
        return undefined;
    }
    return /[a-zA-Z]*/i.test(value.trim()) ? undefined : translate('must be alpha characters');
}

export function phoneNumberLength(value: any): string | undefined {
    if (!value || !value.trim()) {
        return undefined;
    }
    const phoneNumber = parsePhoneNumber(value, 'MX');
    if (!phoneNumber) return undefined;
    return phoneNumber.isPossible() ? undefined : translate('must be phone number');
}
