import { BigNumber } from 'bignumber.js';
import { calculateOrderItemPrice } from 'src/utils/order/calculateOrderItemPrice';
import type { OrderItemToReturn } from 'src/utils/order/getOrderItemsFormattedToReturn';

export function getAmountToReturn(items: Array<OrderItemToReturn>): string {
    if (!items?.length) return '0';

    return items
        .reduce((amount, currentItem) => {
            const amountByItem = BigNumber(calculateOrderItemPrice(currentItem)).toNumber();
            return amount + amountByItem;
        }, 0)
        .toString();
}
