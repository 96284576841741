import { makeStyles } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import * as React from 'react';
import { ChargeOrderButton } from 'src/components/button/order/ChargeOrderButton';
import { OrderCardAcceptNewOrderButton } from 'src/components/orderCard/orderCardActions/OrderCardAcceptNewOrderButton';
import { OrderCardCancelOrderButton } from 'src/components/orderCard/orderCardActions/OrderCardCancelOrderButton';
import { OrderCardChangeCancelReasonButton } from 'src/components/orderCard/orderCardActions/OrderCardChangeCancelReasonButton';
import { OrderCardEditOrderButton } from 'src/components/orderCard/orderCardActions/OrderCardEditOrderButton';
import { OrderCardRejectNewOrderButton } from 'src/components/orderCard/orderCardActions/OrderCardRejectNewOrderButton';
import { SecuredContent } from 'src/components/SecuredContent';
import { RolePermissions } from 'src/constants/RolePermission';
import { OpenClosedOrderButton } from 'src/modules/order/components/OpenClosedOrderButton';
import type { OrderVm } from 'src/types/OrderVm';
import { isAcceptedOrder } from 'src/utils/order/isAcceptedOrder';
import { isCancelledOrder } from 'src/utils/order/isCancelledOrder';
import { isClosedOrder } from 'src/utils/order/isClosedOrder';
import { isNewOrder } from 'src/utils/order/isNewOrder';
import { isPendingOrder } from 'src/utils/order/isPendingOrder';
import { isRappiDelivery } from 'src/utils/order/isRappiDelivery';

export function OrderCardActions({ order, disabled, onChange, onError, updateLoadingOrder }: Props): React.ReactElement {
    const classes = useStyles();

    return (
        <div>
            <Grid container spacing={5}>
                <Grid item xs={12}>
                    {isNewOrder(order.orderStatus) && (
                        <div className={classes.buttonContainer}>
                            <OrderCardRejectNewOrderButton order={order} disabled={disabled} onChange={onChange} />
                            <OrderCardAcceptNewOrderButton order={order} updateLoadingOrder={updateLoadingOrder} disabled={disabled} onChange={onChange} onError={onError} />
                        </div>
                    )}
                    {isAcceptedOrder(order.orderStatus) && (
                        <SecuredContent rolePermission={RolePermissions.CANCEL_ORDERS}>
                            <div className={classes.separator}></div>
                            <OrderCardCancelOrderButton order={order} disabled={disabled} onChange={onChange} />
                        </SecuredContent>
                    )}
                    {isCancelledOrder(order.orderStatus) && isRappiDelivery(order) && (
                        <>
                            <div className={classes.separator}></div>
                            <OrderCardChangeCancelReasonButton order={order} disabled={disabled} />
                        </>
                    )}
                    {(isPendingOrder(order.orderStatus) || isClosedOrder(order.orderStatus)) && (
                        <div className={classes.buttonContainer}>
                            <SecuredContent rolePermission={RolePermissions.CANCEL_ORDERS}>
                                <OrderCardCancelOrderButton order={order} disabled={disabled} onChange={onChange} />
                            </SecuredContent>
                            <OrderCardEditOrderButton disabled={disabled} order={order} />
                            <ChargeOrderButton order={order} />
                        </div>
                    )}
                    {(isPendingOrder(order.orderStatus) || isClosedOrder(order.orderStatus)) && <OpenClosedOrderButton order={order} classes={{ button: classes.button }} />}
                </Grid>
            </Grid>
        </div>
    );
}

const useStyles = makeStyles((theme) => ({
    separator: {
        height: '0px',
        width: '100%',
        border: '1px solid #F3F3F3',
        margin: '12px 0px',
    },
    button: {
        width: '100%',
        marginTop: 10,
    },
    buttonContainer: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-around',
        alignItems: 'center',
        width: '100%',
        gap: 12,
    },
    leftIcon: {
        marginRight: theme.spacing(1),
    },
}));

type Props = {
    order: OrderVm;
    disabled: boolean;
    onChange?: any;
    onError?: any;
    updateLoadingOrder: any;
};
