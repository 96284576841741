import { FormHelperText, makeStyles } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import * as React from 'react';
import { useFormContext } from 'react-hook-form';
import { FormCardNumberField } from 'src/components/form/card-details/FormCardNumberField';
import { FormCvcField } from 'src/components/form/card-details/FormCvcField';
import { FormExpiryField } from 'src/components/form/card-details/FormExpiryField';
import { FormHiddenField } from 'src/components/form/FormHiddenField';
import { getError } from 'src/components/form/utils/getError';
import { translate } from 'src/i18n/translate';
import { removeNulls } from 'src/utils/removeNulls';

export function FormCardDetailsField({ name, required, disabled }: Props): React.ReactElement {
    const { errors } = useFormContext();
    const classes = useStyles();

    return (
        <Grid container spacing={0}>
            <Grid item xs={12} style={{ marginBottom: 20 }}>
                <FormHiddenField name={`${name}.cardId`} />
                <FormHiddenField name={`${name}.brand`} />
                <FormCardNumberField name={name} required disabled={disabled} label={translate('Card number')} />
            </Grid>
            <Grid item xs={12}>
                <div className={classes.row}>
                    <div className={classes.inputContainer}>
                        <FormExpiryField name={name} required disabled={disabled} label={translate('Expiration date')} />
                    </div>
                    <div className={classes.inputContainer}>
                        <FormCvcField name={name} required disabled={disabled} label={translate('CVC')} />
                    </div>
                </div>
            </Grid>
            <FormHelperText error>
                {removeNulls([getError(errors, name)?.['cardNumber']?.message, getError(errors, name)?.['expiry']?.message, getError(errors, name)?.['cvc']?.message]).join(', ')}
            </FormHelperText>
        </Grid>
    );
}

const useStyles = makeStyles((theme) => ({
    row: {
        display: 'flex',
        width: '100%',
        alignItems: 'center',
        justifyContent: 'space-between',
        [theme.breakpoints.down('sm')]: {
            flexDirection: 'column',
        },
    },
    inputContainer: {
        width: '48%',
        [theme.breakpoints.down('sm')]: {
            width: '100%',
            marginBottom: 20,
        },
    },
}));

type Props = {
    name: string;
    required?: boolean;
    disabled?: boolean;
    icons?: boolean;
};
