import { makeStyles } from '@material-ui/core';
import * as React from 'react';
import { useEffect } from 'react';
import { SecuredAndSubscribedPage } from 'src/components/page/SecuredAndSubscribedPage';
import { RolePermissions } from 'src/constants/RolePermission';
import { translate } from 'src/i18n/translate';
import { PosRouter } from 'src/scenes/letseatmanager/pos/PosRouter';
import { CreateRestaurantZoneDialog } from 'src/scenes/letseatmanager/posRestaurantZones/CreateRestaurantZoneDialog';
import { PosRestaurantZones } from 'src/scenes/letseatmanager/posRestaurantZones/PosRestaurantZones';
import { useLoadPosMenus } from 'src/services/menu/useLoadPosMenus';
import { useSelector } from 'src/utils/react/useSelector';

export function PosRestaurantZonesPage(): React.ReactElement {
    const classes = useStyles();
    const loadPosMenus = useLoadPosMenus();

    const posEnabled = useSelector((state) => state.app.restaurant?.posEnabled);
    const posMenu = useSelector((state) => state.pos.posMenu);

    useEffect(() => {
        if (!posMenu?.menus?.length && posEnabled) loadPosMenus();
    }, [posEnabled]);

    return (
        <SecuredAndSubscribedPage rolePermission={RolePermissions.RESTAURANT_ZONES_PAGE} title={translate('AmbitOne POS')} className={classes.container}>
            <CreateRestaurantZoneDialog />
            <PosRouter />
            <PosRestaurantZones />
        </SecuredAndSubscribedPage>
    );
}

const useStyles = makeStyles((theme) => ({
    container: {
        width: '100%',
        height: '95%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
}));
