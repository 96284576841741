import { useState } from 'react';
import * as React from 'react';
import { markOrderPickedUpByExternalDeliveryApi } from 'src/api/letseatmanager/order/markOrderPickedUpByExternalDeliveryApi';
import { Button } from 'src/components/Button';
import { translate } from 'src/i18n/translate';
import { SentryService } from 'src/services/SentryService';
import type { OrderVm } from 'src/types/OrderVm';
import { alertKnownErrorOrSomethingWentWrong } from 'src/utils/alert/alertKnownErrorOrSomethingWentWrong';
import { isAcceptedDelivery } from 'src/utils/delivery/isAcceptedDelivery';
import { isAcceptedOrder } from 'src/utils/order/isAcceptedOrder';
import { isDeliveryOrder } from 'src/utils/order/isDeliveryOrder';
import { useSelector } from 'src/utils/react/useSelector';

export function MarkOrderPickedUpButton({ order, disabled, onSuccess, classes: classesProp, primary, outlined }: Props): React.ReactElement | null {
    const [loading, setLoading] = useState(false);

    const externalDelivery = useSelector((state) => state.app.restaurant.externalDelivery);
    const externalOwnFleetEnabled = useSelector((state) => state.app.restaurant.externalOwnFleetEnabled);
    const shouldRenderButton =
        (externalDelivery && !order.deliveryStatus && isDeliveryOrder(order.orderType) && isAcceptedOrder(order.orderStatus) && !externalOwnFleetEnabled) ||
        (externalOwnFleetEnabled && order.driverId && !isAcceptedDelivery(order.deliveryStatus));

    const handleClick = async () => {
        setLoading(true);
        const response = await markOrderPickedUpByExternalDeliveryApi({
            orderId: order.orderId,
        });
        setLoading(false);
        if (!response.ok) {
            SentryService.logError('Failed to markOrderPickedUpByExternalDelivery', { markOrderPickedUpByExternalDeliveryRequest: { orderId: order.orderId }, response });
            alertKnownErrorOrSomethingWentWrong(response);
            return;
        }
        SentryService.logInfoBreadcrumb('Successfully markOrderPickedUpByExternalDelivery', { markOrderPickedUpByExternalDeliveryRequest: { orderId: order.orderId } });
        onSuccess?.();
    };

    if (!shouldRenderButton) return null;

    return (
        <Button primary={primary} outlined={outlined} onClick={handleClick} disabled={loading || disabled} classes={{ button: classesProp?.button }}>
            {translate('Mark as picked up')}
        </Button>
    );
}

type Props = {
    order: OrderVm;
    disabled?: boolean;
    onSuccess?: any;
    primary?: boolean;
    outlined?: boolean;
    classes?: {
        button?: string;
    };
};
