import List from '@material-ui/core/List';
import ListSubheader from '@material-ui/core/ListSubheader';
import * as React from 'react';
import { AppMenuLink } from 'src/components/app/appMenu/AppMenuLink';
import { DriverAppSettingsMenu } from 'src/components/app/appMenu/DriverAppSettingsMenu';
import { RoutePaths } from 'src/constants/RoutePath';
import { translate } from 'src/i18n/translate';
import { useSelector } from 'src/utils/react/useSelector';

export function AdminAppMenu(): React.ReactElement | null {
    const adminUser = useSelector((state) => state.authentication.adminUser);
    const viewUser = useSelector((state) => state.authentication.viewUser);

    if (!adminUser && !viewUser) return null;

    return (
        <List subheader={<ListSubheader disableSticky>{translate('Admin')}</ListSubheader>}>
            <AppMenuLink routePath={RoutePaths.ADMIN_MONITORING} text={translate('Monitoring')} data-testid='adminAppMenu.monitoringLink' />
            <AppMenuLink routePath={RoutePaths.ADMIN_THIRD_PARTY_LOGISTICS_COVERAGE} text={translate('Third Party Logistics Coverage')} data-testid='adminAppMenu.thirdPartyLogisticsCoverageLink' />
            <AppMenuLink routePath={RoutePaths.ADMIN_QUICK_ACTIONS} text={translate('Quick Actions')} data-testid='adminAppMenu.quickActionsLink' />
            <AppMenuLink routePath={RoutePaths.ADMIN_RAPPI_INTEGRATIONS} text={translate('Rappi Integrations')} data-testid='adminAppMenu.rappiIntegrationsLink' />
            <AppMenuLink routePath={RoutePaths.ADMIN_SUPPORT} text={translate('Support')} data-testid='adminAppMenu.supportLink' />
            <AppMenuLink routePath={RoutePaths.ADMIN_SUPPORT_PHONE_NUMBERS} text={translate('Support Phone Numbers')} data-testid='adminAppMenu.supportPhoneNumbersLink' />
            <AppMenuLink routePath={RoutePaths.ADMIN_RESTAURANT_REFERRALS} text={translate('Restaurant Referrals')} data-testid='adminAppMenu.restaurantReferralsLink' />
            <AppMenuLink routePath={RoutePaths.ADMIN_OVERALL_STATS} text={translate('Overall Stats')} data-testid='adminAppMenu.overallStatsLink' />
            <AppMenuLink routePath={RoutePaths.ADMIN_RESTAURANT_SALES} text={translate('Restaurant Sales')} data-testid='adminAppMenu.restaurantSalesLink' />
            <AppMenuLink routePath={RoutePaths.ADMIN_DELIVERY_MONITORING} text={translate('Delivery Monitoring')} data-testid='adminAppMenu.deliveryMonitoringLink' />
            <AppMenuLink routePath={RoutePaths.ADMIN_STATISTICS} text={translate('Statistics')} data-testid='adminAppMenu.statisticsLink' />
            <AppMenuLink routePath={RoutePaths.ADMIN_STATISTICS_OLD} text={translate('Statistics Old')} data-testid='adminAppMenu.statisticsOldLink' />
            <AppMenuLink routePath={RoutePaths.ADMIN_SURVEYS} text={translate('Surveys')} data-testid='adminAppMenu.surveysLink' />
            <AppMenuLink routePath={RoutePaths.ADMIN_ONGOING_ORDERS} text={translate('Ongoing Orders')} data-testid='adminAppMenu.ongoingOrdersLink' />
            <AppMenuLink routePath={RoutePaths.ADMIN_ORDERS} text={translate('Orders')} data-testid='adminAppMenu.ordersLink' />
            <AppMenuLink routePath={RoutePaths.ADMIN_ORDER_INCIDENTS} text={translate('Order incidents')} data-testid='adminAppMenu.orderIncidentssLink' />
            <AppMenuLink routePath={RoutePaths.ADMIN_SUSPICIOUS_ORDERS} text={translate('Suspicious Orders')} data-testid='adminAppMenu.suspiciousOrdersLink' />
            <AppMenuLink routePath={RoutePaths.ADMIN_OFFICE_DELIVERY} text={translate('Office Delivery')} data-testid='adminAppMenu.officeDeliveryLink' />
            <AppMenuLink routePath={RoutePaths.ADMIN_PICKUP_STATIONS} text={translate('Pickup Stations')} data-testid='adminAppMenu.pickupStationsLink' />
            <AppMenuLink routePath={RoutePaths.ADMIN_BUSINESSES} text={translate('Businesses')} data-testid='adminAppMenu.businessesLink' />
            <AppMenuLink routePath={RoutePaths.ADMIN_BRANDS} text={translate('Brands')} data-testid='adminAppMenu.brandsLink' />
            <AppMenuLink routePath={RoutePaths.ADMIN_RESTAURANTS} text={translate('Restaurants')} data-testid='adminAppMenu.restaurantsLink' />
            <AppMenuLink routePath={RoutePaths.ADMIN_RESTAURANTS_DETAILS} text={translate('Restaurants Details')} data-testid='adminAppMenu.restaurantsDetailsLink' />
            <AppMenuLink routePath={RoutePaths.ADMIN_MANAGER_USERS} text={translate('Manager User')} data-testid='adminAppMenu.managerUserLink' />
            <AppMenuLink routePath={RoutePaths.ADMIN_CUSTOMERS} text={translate('Customers')} data-testid='adminAppMenu.customersLink' />
            <DriverAppSettingsMenu />
            <AppMenuLink routePath={RoutePaths.ADMIN_LIVE_DRIVERS} text={translate('Live Drivers')} data-testid='adminAppMenu.liveDriversLink' />
            <AppMenuLink routePath={RoutePaths.ADMIN_PROMO_CODES} text={translate('Promo Codes')} data-testid='adminAppMenu.promoCodesLink' />
            <AppMenuLink routePath={RoutePaths.ADMIN_BANNERS} text={translate('Banners')} data-testid='adminAppMenu.bannersLink' />
            <AppMenuLink routePath={RoutePaths.ADMIN_SCHEDULED_TASKS} text={translate('Scheduled Tasks')} data-testid='adminAppMenu.scheduledTasksLink' />
            <AppMenuLink routePath={RoutePaths.ADMIN_EMAILS} text={translate('Sent Emails')} data-testid='adminAppMenu.sentEmailsLink' />
            <AppMenuLink routePath={RoutePaths.ADMIN_EMAIL_TEMPLATES} text={translate('Email Templates')} data-testid='adminAppMenu.emailTemplatesLink' />
            <AppMenuLink routePath={RoutePaths.ADMIN_COMPANIES} text={translate('Companies')} data-testid='adminAppMenu.companiesLink' />
            <AppMenuLink routePath={RoutePaths.ADMIN_SUBSCRIPTION_HISTORIES} text={translate('Subscriptions Histories')} data-testid='adminAppMenu.subscriptionsHistoriesLink' />
            <AppMenuLink routePath={RoutePaths.ADMIN_SUBSCRIPTION_PAYMENT_PAGE} text={translate('Subscription Payment Page')} data-testid='adminAppMenu.subscriptionPaymentPageLink' />
            <AppMenuLink routePath={RoutePaths.ADMIN_SUBSCRIPTIONS} text={translate('Subscriptions')} data-testid='adminAppMenu.subscriptionsLink' />
            <AppMenuLink routePath={RoutePaths.ADMIN_RESTAURANT_BILLING} text={translate('Restaurant Billing')} data-testid='adminAppMenu.restaurantBillingLink' />
            <AppMenuLink routePath={RoutePaths.ADMIN_RESTAURANT_BILLING_UY} text={translate('Restaurant Billing UY')} data-testid='adminAppMenu.restaurantBillingLink' />
            <AppMenuLink routePath={RoutePaths.ADMIN_KITCHEN_BILLING} text={translate('Kitchen Billing')} data-testid='adminAppMenu.kitchenBillingLink' />
            <AppMenuLink routePath={RoutePaths.ADMIN_COMPANY_CREDITS_BILLING} text={translate('Company Credits Billing')} data-testid='adminAppMenu.companyCreditsBillingLink' />
            <AppMenuLink routePath={RoutePaths.ADMIN_COMPANY_PAYROLL_BILLING} text={translate('Company Payroll Billing')} data-testid='adminAppMenu.companyPayrollBillingLink' />
            <AppMenuLink routePath={RoutePaths.RESTAURANT_VIDEOS} text={translate('Restaurant Videos')} data-testid='adminAppMenu.restaurantVideosLink' />
            <AppMenuLink routePath={RoutePaths.ADMIN_RESTAURANT_SELF_REGISTRATIONS} text={translate('Restaurant Self-Registrations')} data-testid='adminAppMenu.restaurantSelfRegistrationsLink' />
            <AppMenuLink routePath={RoutePaths.ADMIN_LOG_EVENTS} text={translate('Log Events')} data-testid='adminAppMenu.logEventsLink' />
            <AppMenuLink routePath={RoutePaths.ADMIN_RELEASES_NOTES} text={translate('Releases notes')} data-testid='adminAppMenu.releasesNotesLink' />
            <AppMenuLink routePath={RoutePaths.ADMIN_REQUEST_INTERNAL_REPORT} text={translate('Request internal report')} data-testid='adminAppMenu.requestInternalReportLink' />
            <AppMenuLink routePath={RoutePaths.ADMIN_DEMO_MODE_RESTAURANTS} text={translate('Demo Mode Restaurants')} />
            <AppMenuLink routePath={RoutePaths.ADMIN_CUSTOM_ALERT_DIALOG} text={translate('Custom Alert Dialogs')} data-testid='adminAppMenu.customAlertDialogs' />
            <AppMenuLink routePath={RoutePaths.ADMIN_DEMO_MODE_RESTAURANTS} text={translate('Demo Mode Restaurants')} />
            {!viewUser && <AppMenuLink routePath={RoutePaths.ADMIN_SETTINGS} text={translate('Settings')} data-testid='adminAppMenu.settingsLink' />}
        </List>
    );
}
