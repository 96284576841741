import { letseatmanagerApiMethod } from 'src/api/letseatmanagerApi';
import type { ApiSauceResponse } from 'src/api/types/ApiSauceResponse';
import { Currency } from 'src/constants/Currency';
import type { MenuItemSize } from 'src/constants/MenuItemSize';
import type { UnitOfMeasurement } from 'src/constants/UnitOfMeasurement';
import { MenuCategoryId, type ExternalId, type ModifierGroupId, type PrinterName, type RecipeId, type RestaurantId, type RestaurantTaxId, type SupplyCategoryId } from 'src/types/Id';

export async function createMenuItemApi(request: CreateMenuItemApiRequest): ApiSauceResponse<void> {
    return letseatmanagerApiMethod('menuItem/createMenuItemApi', request);
}

export type CreateMenuItemApiRequest = {
    restaurantId: RestaurantId;
    modifierGroupIds: Array<ModifierGroupId>;
    menuCategoryIds: Array<MenuCategoryId>;
    recipeId?: RecipeId;
    externalProductId?: ExternalId;
    name: string;
    description?: string;
    contains?: string;
    price: string;
    modifierPrice?: string;
    promoPrice?: string;
    promoText?: string;
    kitchenPrice?: string;
    isModifier?: boolean;
    isProduct?: boolean;
    isSupply?: boolean;
    supplyCategoryId?: SupplyCategoryId;
    size: MenuItemSize;
    imageUrl?: string;
    color?: string;
    salesUnit?: UnitOfMeasurement;
    currency?: Currency;
    isSoldByWeight?: boolean;
    minimumSalesQuantity?: string;
    printerNames?: Array<PrinterName>;
    commissionEnabled?: boolean;
    commissionPercentage?: number;
    restaurantTaxIds?: Array<RestaurantTaxId>;
};
