import { BigNumber } from 'bignumber.js';
import type { RestaurantCurrency } from 'src/types/RestaurantCurrency';
import { roundDigits } from 'src/utils/number/roundDigits';

export function exchangeCurrencyAmount(value: string | number, currency: RestaurantCurrency): string {
    if (!currency) return value?.toString();
    const exchange = BigNumber(1).dividedBy(currency.exchangeRate);

    return roundDigits(BigNumber(value).multipliedBy(exchange));
}
