import { makeStyles } from '@material-ui/core/styles';
import * as React from 'react';
import { translate } from 'src/i18n/translate';
import { getPromoCodeDescription } from 'src/utils/promoCode/getPromoCodeDescription';
import { classNames } from 'src/utils/react/classNames';
import { useSelector } from 'src/utils/react/useSelector';
import { useValidatePromoCodeApplicable } from 'src/utils/react/useValidatePromoCodeApplicable';

export function PromoCodeDescription({ validatedMessage }: Props): React.ReactElement | null {
    const classes = useStyles();

    const promoCodeSelected = useSelector((state) => state.pos.promoCode);
    const country = useSelector((state) => state.app.restaurant?.country);
    const menuItems = useSelector((state) => state.pos.posMenu?.menuItems) ?? [];

    const { isValid, message } = useValidatePromoCodeApplicable();

    const getDescriptionText = () => {
        if (validatedMessage) return validatedMessage;
        if (isValid && promoCodeSelected) {
            return translate('Promo Code Selected: @description', { description: getPromoCodeDescription({ promoCode: promoCodeSelected, country, menuItems }) });
        }
        return message;
    };

    if (!promoCodeSelected) return null;

    return (
        <div aria-label={translate('Promotion description')} className={classNames(classes.promoCodeDescription, !isValid ? classes.promoCodeInvalidDescription : '')}>
            {getDescriptionText()}
        </div>
    );
}

const useStyles = makeStyles((theme) => ({
    promoCodeDescription: {
        display: 'grid',
        justifyContent: 'center',
        alignContent: 'center',
        width: '100%',
        height: 70,
        margin: '20px 0',
        color: theme.palette.text.primary,
        backgroundColor: '#63d6b357',
        fontFamily: theme.typography.semiBold,
        fontSize: 12,
        [theme.breakpoints.up('sm')]: {
            height: 40,
        },
    },
    promoCodeInvalidDescription: {
        color: '#224753',
        backgroundColor: '#DC547857',
    },
}));

type Props = {
    validatedMessage?: string;
};
