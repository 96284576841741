import * as React from 'react';
import { useState } from 'react';
import { Alert } from 'src/components/Alert';
import { translate } from 'src/i18n/translate';
import { SyncMenuIntegrationsErrorDialog } from 'src/scenes/letseatmanager/menu/components/SyncMenuIntegrationsErrorDialog';

export function SyncMenuErrorAlert({ open, onClose, syncMenuErrors }: Props): React.ReactElement | null {
    const [syncMenuIntegrationsErrorDialogState, setSyncMenuIntegrationsErrorDialogState] = useState(false);

    const closeErrorToSyncMenuIntegrationsErrorDialog = () => setSyncMenuIntegrationsErrorDialogState(false);

    const openErrorToSyncMenuIntegrationsErrorDialog = () => setSyncMenuIntegrationsErrorDialogState(true);

    if (!open) return null;

    return (
        <>
            <SyncMenuIntegrationsErrorDialog open={syncMenuIntegrationsErrorDialogState} onClose={closeErrorToSyncMenuIntegrationsErrorDialog} syncMenuErrors={syncMenuErrors} />
            <Alert
                type={'error'}
                title={translate('There was a problem syncing some of your menus')}
                actionText={translate('Open')}
                onActionClick={openErrorToSyncMenuIntegrationsErrorDialog}
                onClose={onClose}
            />
        </>
    );
}

type Props = {
    open: boolean;
    onClose: any;
    syncMenuErrors: Array<string>;
};
