import { letseatmanagerApiMethod } from 'src/api/letseatmanagerApi';
import type { ApiSauceResponse } from 'src/api/types/ApiSauceResponse';
import type { RestaurantId } from 'src/types/Id';
import type { ManagerMenuItemVm } from 'src/types/ManagerMenuItemVm';

export async function findMenuItemsApi(request: FindMenuItemsApiRequest): ApiSauceResponse<Array<ManagerMenuItemVm>> {
    return letseatmanagerApiMethod('menuItem/findMenuItemsApi', request);
}

export type FindMenuItemsApiRequest = {
    restaurantId: RestaurantId;
};
