import { envGOOGLE_MAPS_API_KEY } from 'src/env/envGOOGLE_MAPS_API_KEY';

export const googleMapsConfig: GoogleMapsConfig = {
    googleMapsApiKey: envGOOGLE_MAPS_API_KEY(),
    libraries: ['places', 'drawing'],
    language: 'es',
};

type GoogleMapsConfig = {
    googleMapsApiKey: string;
    libraries: Array<'places' | 'drawing'>;
    language: 'es';
};
