import { depositCashRegisterApi } from 'src/api/letseatmanager/pos/depositCashRegisterApi';
import { getPosDepositsOfflineInLocalStorage, PosDepositOffline } from 'src/localStorage/getPosDepositsOfflineInLocalStorage';
import { setPosDepositsOfflineInLocalStorage } from 'src/localStorage/setPosDepositsOfflineInLocalStorage';

export async function tryToSavePosDepositsCreatedOffline() {
    const posDepositsOffline = getPosDepositsOfflineInLocalStorage();
    for (const posDepositOffline of posDepositsOffline) {
        await saveDeposit(posDepositOffline);
    }
}

async function saveDeposit(request: PosDepositOffline) {
    const response = await depositCashRegisterApi(request as any);
    if (!response.ok) return;
    savedPosDepositOffline(request);
}

function savedPosDepositOffline(request: PosDepositOffline) {
    const posDepositsOffline = getPosDepositsOfflineInLocalStorage();
    setPosDepositsOfflineInLocalStorage(posDepositsOffline.filter((posDepositOffline) => posDepositOffline.temporalCashRegisterTransactionId !== request.temporalCashRegisterTransactionId));
}
