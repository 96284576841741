import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Grid from '@material-ui/core/Grid';
import * as React from 'react';
import { useForm } from 'react-hook-form';
import { createBrandApi } from 'src/api/letseatadmin/brand/createBrandApi';
import { Form } from 'src/components/form/Form';
import { FormFixErrorHint } from 'src/components/form/FormFixErrorHint';
import { FormRestaurantsAutocomplete } from 'src/components/form/FormRestaurantsAutocomplete';
import { FormSwitch } from 'src/components/form/FormSwitch';
import { FormTextField } from 'src/components/form/FormTextField';
import { translate } from 'src/i18n/translate';
import { appLayout } from 'src/theme/AppLayout';
import { alertKnownErrorOrSomethingWentWrong } from 'src/utils/alert/alertKnownErrorOrSomethingWentWrong';

export function CreateBrandDialog({ open, onClose, onBrandCreated }: Props): React.ReactElement {
    const form = useForm();
    const {
        formState: { isSubmitting },
    } = form;

    const handleClose = () => {
        if (isSubmitting) return;
        onClose();
    };

    const onSubmit = async (form: any) => {
        const response = await createBrandApi({
            brandName: form.brandName,
            restaurantIds: form.restaurantIds ?? [],
            comment: form.comment,
            marketingActionsForRestaurantEnabled: form.marketingActionsForRestaurantEnabled,
        });
        if (!response.ok) {
            alertKnownErrorOrSomethingWentWrong(response);
            return;
        }
        onBrandCreated();
        onClose();
    };

    return (
        <Dialog fullWidth scroll='paper' open={open} onClose={handleClose}>
            <Form form={form} onSubmit={onSubmit} style={appLayout.dialog.form}>
                <DialogTitle>{translate('Create Brand').toUpperCase()}</DialogTitle>
                <DialogContent>
                    <FormContent />
                    <FormFixErrorHint />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} disabled={isSubmitting}>
                        {translate('Cancel').toUpperCase()}
                    </Button>
                    <Button color='primary' type='submit' disabled={isSubmitting}>
                        {isSubmitting ? translate('Creating').toUpperCase() : translate('Create').toUpperCase()}
                    </Button>
                </DialogActions>
            </Form>
        </Dialog>
    );
}

function FormContent() {
    return (
        <Grid container spacing={3}>
            <Grid item xs={12}>
                <FormTextField name='brandName' label={translate('Brand Name')} />
            </Grid>
            <Grid item xs={12}>
                <FormRestaurantsAutocomplete name='restaurantIds' label={translate('Restaurants')} />
            </Grid>
            <Grid item xs={12}>
                <FormTextField name='comment' label={translate('Comment')} />
            </Grid>
            <Grid item xs={12} md={6}>
                <FormSwitch name='marketingActionsForRestaurantEnabled' label={translate('Marketing Actions For Restaurant Enabled')} />
            </Grid>
        </Grid>
    );
}

type Props = {
    open: boolean;
    onClose: any;
    onBrandCreated: any;
};
