import { letseatmanagerApiMethod } from 'src/api/letseatmanagerApi';
import type { ApiSauceResponse } from 'src/api/types/ApiSauceResponse';
import type { App } from 'src/constants/App';
import type { MenuId, UberEatsStoreId } from 'src/types/Id';

export async function deprecatedChangeMenuApi(request: CreateMenuApiRequest): ApiSauceResponse<void> {
    return letseatmanagerApiMethod('menu/deprecatedChangeMenuApi', request);
}

type CreateMenuApiRequest = {
    menuId: MenuId;
    uberEatsStoreId?: UberEatsStoreId;
    name: string;
    hidden: boolean;
    hours?: string;
    companyName?: string;
    channels?: Array<App>;
};
