import { makeStyles } from '@material-ui/core';
import { Button } from '@pidedirecto/ui';
import { CrossIcon } from '@pidedirecto/ui/icons';
import { BigNumber } from 'bignumber.js';
import * as React from 'react';
import { useEffect } from 'react';
import { ModifierTypes } from 'src/constants/ModifierType';
import { usePosCartModifierGroups } from 'src/services/pos/posModifiersCart/usePosCartModifierGroups';
import { usePosCartModifiers } from 'src/services/pos/posModifiersCart/usePosCartModifiers';
import { usePosCartModifierSections } from 'src/services/pos/posModifiersCart/usePosCartModifierSections';
import { useSetPosCartModifiers } from 'src/services/pos/posModifiersCart/useSetPosCartModifiers';
import { useSetPosCartModifiersQuantity } from 'src/services/pos/posModifiersCart/useSetPosCartModifiersQuantity';
import { useFormatAsRestaurantCurrencyNumber } from 'src/services/restaurant/useFormatAsRestaurantCurrencyNumber';
import { DeprecatedModifierGroupVm, DeprecatedModifierVm, ModifierGroupVm, ModifierVm } from 'src/types/PosMenuVm';
import { useSelector } from 'src/utils/react/useSelector';
import { requireValue } from 'src/utils/require/requireValue';

export function PosCartModifiers(): React.ReactElement {
    const classes = useStyles();
    const formatAsCurrencyNumber = useFormatAsRestaurantCurrencyNumber();

    const setPosCartModifiers = useSetPosCartModifiers();
    const posCartModifiers = usePosCartModifiers();
    const posCartModifierSections = usePosCartModifierSections();
    const posCartModifierGroups = usePosCartModifierGroups();
    const setPosCartModifiersQuantity = useSetPosCartModifiersQuantity();

    const item = useSelector((state) => state.pos.itemSelected);
    const newMenuManagementEnabled = useSelector((state) => state.app.restaurant.newMenuManagementEnabled);
    const itemSelectedQuantity = useSelector((state) => state.pos.itemSelectedQuantity);

    useEffect(() => {
        if (item) {
            setPosCartModifiersQuantity(itemSelectedQuantity ?? 1);
        }
        return () => {
            setPosCartModifiers({});
        };
    }, [item, newMenuManagementEnabled, posCartModifierGroups]);

    const removeModifier = (modifier: DeprecatedModifierVm | ModifierVm, _modifierGroup: ModifierGroupVm | DeprecatedModifierGroupVm, _quantity?: any) => {
        const modifierGroup = requireValue(_modifierGroup);

        let updatedModifierGroup = [...(posCartModifiers?.[modifierGroup.name] ?? [])];

        if (modifier.modifierType === ModifierTypes.SINGLE || !_quantity) {
            updatedModifierGroup = updatedModifierGroup.filter((_modifier) => _modifier.name !== modifier.name);
        } else if (modifier.modifierType === ModifierTypes.MULTIPLE) {
            const updatedModifier = updatedModifierGroup.find((_modifier) => _modifier.name === modifier.name);
            if (!updatedModifier) return;

            const newQuantity = updatedModifier.quantity - (_quantity ?? 1);
            if (newQuantity === 0) {
                updatedModifierGroup = updatedModifierGroup.filter((_modifier) => _modifier.name !== modifier.name);
            } else {
                updatedModifier.quantity = newQuantity;
            }
        }

        const posCartModifiersUpdated = {
            ...posCartModifiers,
            [modifierGroup.name]: updatedModifierGroup,
        };
        setPosCartModifiers(posCartModifiersUpdated);
    };

    return (
        <div className={classes.modifierGroupNamesContainer}>
            {Object.keys(posCartModifiers)
                .filter((modifierGroupName) => modifierGroupName !== 'menuItemId')
                .filter((modifierGroupName) => posCartModifiers[modifierGroupName]?.length)
                .map((modifierGroupName) => {
                    return (
                        <div className={classes.modifierGroupContainer} key={modifierGroupName}>
                            <p className={classes.modifierGroupName}>{modifierGroupName}</p>
                            {posCartModifiers[modifierGroupName]?.map((modifier) => {
                                const modifierGroup = posCartModifierSections.find((section) => section.key === modifierGroupName)?.modifierGroup;
                                const total = BigNumber(modifier.price).multipliedBy(modifier.quantity).toString();

                                return (
                                    <div className={classes.modifierTextContainer} key={modifier.name}>
                                        <span className={classes.modifierText}>
                                            {modifier.quantity}x {modifier.name}
                                        </span>
                                        {BigNumber(modifier.price ?? 0).isGreaterThan(0) && <span className={classes.modifierText}>+{formatAsCurrencyNumber(total)}</span>}
                                        <Button classes={{ button: classes.closeIconButton }} variant={'icon'} onClick={() => removeModifier(modifier, modifierGroup!)}>
                                            <CrossIcon height={16} width={16} />
                                        </Button>
                                    </div>
                                );
                            })}
                        </div>
                    );
                })}
        </div>
    );
}

const useStyles = makeStyles((theme) => ({
    modifierGroupName: {
        fontFamily: theme.typography.medium,
        fontSize: 15,
        color: theme.palette.text.disabled,
        margin: 0,
        marginBottom: 4,
    },
    modifierTextContainer: {
        width: '100%',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        marginBottom: 5,
        justifyContent: 'space-between',
        backgroundColor: theme.palette.surface.secondary,
        padding: 8,
        borderRadius: 6,
    },
    modifierText: {
        fontFamily: theme.typography.regular,
        maxWidth: '50%',
    },
    modifierGroupContainer: {
        marginBottom: 10,
    },
    modifierGroupNamesContainer: {
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        maxHeight: '78%',
        minHeight: '65%',
        overflowY: 'auto',
    },
    closeIconButton: {
        color: theme.palette.icons.primary,
    },
}));
