import { makeStyles } from '@material-ui/core';
import * as React from 'react';
import { Text } from 'src/components/Text';
import { classNames } from 'src/utils/react/classNames';

export function ReportIndicator({ value, label, outline, Icon }: Props): React.ReactElement {
    const classes = useStyles();

    return (
        <div className={classNames(outline ? classes.containerBordered : classes.container)}>
            {Icon && <div className={classes.icon}>{Icon}</div>}
            <div className={classes.textContainer}>
                <Text variant='semiBold' className={classes.value}>
                    {value}
                </Text>
                <Text className={classes.label}>{label}</Text>
            </div>
        </div>
    );
}

const useStyles = makeStyles((theme) => ({
    container: {
        display: 'flex',
        boxShadow: '4px 4px 7px 0px rgba(0, 0, 0, 0.25)',
        backgroundColor: theme.palette.surface.primary,
        borderRadius: 8,
        padding: '12px 16px',
        gap: 12,
        width: '100%',
    },
    containerBordered: {
        gap: 12,
        padding: '12px 16px',
        borderRadius: 8,
        border: `1px solid #BAC0C2`,
    },
    value: {
        fontSize: 20,
        margin: 0,
        color: theme.palette.text.primary,
        [theme.breakpoints.down('sm')]: {
            fontSize: 14,
        },
    },
    label: {
        color: theme.palette.text.secondary,
        [theme.breakpoints.down('sm')]: {
            fontSize: 12,
            lineHeight: 1.2,
        },
    },
    textContainer: {
        display: 'flex',
        flexDirection: 'column',
        [theme.breakpoints.down('sm')]: {
            gap: 2,
        },
    },
    icon: {
        minWidth: 20,
    },
}));

type Props = {
    value: string | number;
    label: string;
    outline?: boolean;
    Icon?: React.ReactNode;
};
