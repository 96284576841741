import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Grid from '@material-ui/core/Grid';
import RadioGroup from '@material-ui/core/RadioGroup';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import * as React from 'react';
import { withRouter } from 'react-router-dom';
import { letseatmanagerApiDeprecated } from 'src/api/letseatmanagerApiDeprecated';
import { Field, Form } from 'src/components/deprecated/react-final-form/Form';
import Radio from 'src/components/deprecated/react-final-form/Radio';
import TextField from 'src/components/deprecated/react-final-form/TextField';
import { ChangeToolbarButton } from 'src/components/mui-datatables/ChangeToolbarButton';
import { OpenInNewTabToolbarButton } from 'src/components/mui-datatables/OpenInNewTabToolbarButton';
import { RefreshToolbarButton } from 'src/components/mui-datatables/RefreshToolbarButton';
import { UpdatingContentProgress } from 'src/components/UpdatingContentProgress';
import { history } from 'src/config/history';
import { RoutePaths } from 'src/constants/RoutePath';
import { translate } from 'src/i18n/translate';
import ChangeCompanyDialog, { CHANGE_COMPANY } from 'src/scenes/letseatadmin/company/ChangeCompanyDialog';
import type { AwsCognitoUsername, CompanyId, ConektaCustomerId, StripeCustomerId } from 'src/types/Id';
import { alertKnownErrorOrSomethingWentWrong } from 'src/utils/alert/alertKnownErrorOrSomethingWentWrong';
import { formatDateTimeString } from 'src/utils/date/formatDateTimeString';
import { setUrlQueryParameter } from 'src/utils/history/setUrlQueryParameter';
import { RegExps } from 'src/utils/RegExps';

export default withRouter<any, any>(
    withStyles((theme) => ({
        linearProgress: {
            position: 'absolute',
            width: '100%',
            bottom: 0,
        },
        root: {
            paddingLeft: 8,
            paddingRight: 8,
        },
    }))(
        class extends React.PureComponent<Props, State> {
            state: any = INITIAL_STATE;

            async componentDidMount(): Promise<void> {
                await this.load();
            }

            load = async () => {
                this.setState({ loading: true });
                const response = await letseatmanagerApiDeprecated.admin.getCompany({ companyId: this.props.companyId });
                this.setState({ loading: false });
                if (!response.ok) {
                    alertKnownErrorOrSomethingWentWrong(response);
                    return;
                }
                const company: CompanyVm = response.data;

                this.props.onCompanyChange && this.props.onCompanyChange(company);

                this.setState({
                    companyDetails: {
                        name: company.name,
                        address: company.address || '',
                        username: company.username,
                        password: company.password,
                        contact: {
                            email: company.contact.email || '',
                            phoneNumber: company.contact.phoneNumber || '',
                            firstName: company.contact.firstName || '',
                            lastName: company.contact.lastName || '',
                        },
                        paused: company.paused ? 'true' : 'false',
                        createdAt: formatDateTimeString(company.createdAt),
                        modifiedAt: formatDateTimeString(company.modifiedAt),
                    },
                });
            };

            handleClickEdit = () => {
                history.push({
                    search: setUrlQueryParameter(CHANGE_COMPANY, this.props.companyId),
                });
            };

            handleCompanyChanged = async (deleted: any) => {
                if (deleted) {
                    history.replace({
                        pathname: RoutePaths.ADMIN_COMPANIES,
                    });
                    return;
                }
                await this.load();
            };

            render() {
                const { classes } = this.props;
                return (
                    <div style={{ position: 'relative' }}>
                        <ChangeCompanyDialog onCompanyChanged={this.handleCompanyChanged} />
                        <UpdatingContentProgress loading={this.state.loading} />
                        <Card classes={{ root: classes.root }}>
                            <CardHeader
                                title={
                                    <Typography display='block' gutterBottom variant='h6'>
                                        {translate('Company Details')}
                                    </Typography>
                                }
                                action={
                                    <div>
                                        {this.props.companyId && (
                                            <>
                                                <OpenInNewTabToolbarButton
                                                    onClick={() => window.open(RoutePaths.ADMIN_COMPANY.replace(`:companyId(${RegExps.uuid})`, this.props.companyId), '_blank')}
                                                />
                                            </>
                                        )}
                                        <RefreshToolbarButton onClick={this.load} />
                                        <ChangeToolbarButton onClick={this.handleClickEdit} />
                                    </div>
                                }
                            />
                            <CardContent>{this.renderContent()}</CardContent>
                        </Card>
                    </div>
                );
            }

            onSubmit = (form: any) => {};

            renderContent() {
                return (
                    <>
                        <Form
                            onSubmit={this.onSubmit}
                            initialValues={{
                                company: this.state.companyDetails || {},
                            }}
                            render={({ handleSubmit, submitting, pristine, values }) => (
                                <Grid container spacing={3}>
                                    <Grid item xs={12} sm={6}>
                                        <Field name='company.name' component={TextField} label={translate('Name')} fullWidth disabled />
                                    </Grid>

                                    <Grid item xs={12} sm={6}>
                                        <Field name='company.address' component={TextField} label={translate('Address')} fullWidth disabled />
                                    </Grid>

                                    <Grid item xs={12} sm={6}>
                                        <Field name='company.username' component={TextField} label={translate('Username')} fullWidth disabled />
                                    </Grid>

                                    <Grid item xs={12} sm={6}>
                                        <Field name='company.password' component={TextField} label={translate('Password')} fullWidth disabled />
                                    </Grid>

                                    <Grid item xs={12} sm={6}>
                                        <Field name='company.contact.firstName' component={TextField} label={translate('Contact First Name')} fullWidth disabled />
                                    </Grid>

                                    <Grid item xs={12} sm={6}>
                                        <Field name='company.contact.lastName' component={TextField} label={translate('Contact Last Name')} fullWidth disabled />
                                    </Grid>

                                    <Grid item xs={12} sm={6}>
                                        <Field name='company.contact.email' component={TextField} label={translate('Contact Email')} fullWidth disabled />
                                    </Grid>

                                    <Grid item xs={12} sm={6}>
                                        <Field name='company.contact.phoneNumber' component={TextField} label={translate('Contact Phone Number')} fullWidth disabled />
                                    </Grid>

                                    <Grid item xs={12}>
                                        <RadioGroup row>
                                            <FormControlLabel label='Active' control={<Field name='company.paused' component={Radio} type='radio' color='primary' value='false' disabled />} />
                                            <FormControlLabel label='Paused' control={<Field name='company.paused' component={Radio} type='radio' color='primary' value='true' disabled />} />
                                        </RadioGroup>
                                    </Grid>

                                    <Grid item xs={12} sm={6}>
                                        <Field name='company.createdAt' component={TextField} label={translate('Created')} fullWidth disabled />
                                    </Grid>

                                    <Grid item xs={12} sm={6}>
                                        <Field name='company.modifiedAt' component={TextField} label={translate('Modified')} fullWidth disabled />
                                    </Grid>
                                </Grid>
                            )}
                        />
                    </>
                );
            }
        },
    ),
) as React.ComponentType<any>;

const INITIAL_STATE = {
    loading: false,
    companyDetails: {
        name: '',
        address: '',
        username: '',
        password: '',
        contact: {
            email: '',
            phoneNumber: '',
            firstName: '',
            lastName: '',
        },
        paused: '',
        createdAt: '',
        modifiedAt: '',
    },
} as const;

type Props = {
    companyId: CompanyId;
    onCompanyChange?: (arg1: CompanyVm) => Record<any, any>;
    classes: any;
};

type State = {
    loading: boolean;
    companyDetails: {
        name: string;
        address?: string;
        username: string;
        password: string;
        contact: {
            email?: string;
            phoneNumber?: string;
            firstName?: string;
            lastName?: string;
        };
        paused: string;
        createdAt: string;
        modifiedAt: string;
    };
};

type CompanyVm = {
    companyId: CompanyId;
    username: AwsCognitoUsername;
    password: string;
    name: string;
    address?: string;
    contact: CompanyContactVm;
    conektaCustomerId?: ConektaCustomerId;
    stripeCustomerId?: StripeCustomerId;
    creditCards: number;
    paused?: boolean;
    createdAt: Date;
    modifiedAt: Date;
};

type CompanyContactVm = {
    email?: string;
    phoneNumber?: string;
    firstName?: string;
    lastName?: string;
};
