import { isMobileApp } from 'src/utils/reactNative/isMobileApp';
import { isBlankString } from 'src/utils/string/isBlankString';

export function copyTextToClipboard(text: string): void {
    if (!text || isBlankString(text)) return;
    const _isMobileApp = isMobileApp();
    if (_isMobileApp) {
        window.ReactNativeWebView.postMessage(
            JSON.stringify({
                type: 'COPY_CLIPBOARD',
                data: text,
            }),
        );
        return;
    }
    navigator?.clipboard?.writeText?.(text);
}
