import type { DeliveryCategory } from 'src/types/Id';

export const KEY_NAME = 'deliveryCategoryOptions';

export function getDeliveryCategoryOptionsInLocalStorage(): Array<DeliveryCategoryOption> {
    const item = window.localStorage.getItem('deliveryCategoryOptions');
    return item ? JSON.parse(item) : [];
}

export type DeliveryCategoryOption = {
    label: DeliveryCategory;
    value: DeliveryCategory;
};
