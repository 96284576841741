import { makeStyles } from '@material-ui/core';
import moment from 'moment';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { DiscountBreakdownReport, getPromoCodeRestaurantStatisticsApi, SalesByPromoCode, SalesByPromoCodeByDay } from 'src/api/letseatmanager/restaurantDashboard/getPromoCodeRestaurantStatisticsApi';
import { UpdatingContentProgress } from 'src/components/UpdatingContentProgress';
import { translate } from 'src/i18n/translate';
import { FilterMasterReports } from 'src/scenes/letseatmanager/restaurantDashboard/FilterMasterReports';
import { FilterReports } from 'src/scenes/letseatmanager/restaurantDashboard/FilterReports';
import { DiscountBreakdownReportTable } from 'src/scenes/letseatmanager/restaurantDashboard/promoCodeReport/DiscountBreakdownReportTable';
import { PromoCodeMostUsedGraph } from 'src/scenes/letseatmanager/restaurantDashboard/promoCodeReport/PromoCodeMostUsedGraph';
import { SalesByPromoCodeTable } from 'src/scenes/letseatmanager/restaurantDashboard/promoCodeReport/SalesByPromoCodeTable';
import { ReportSectionEmptyState } from 'src/scenes/letseatmanager/restaurantDashboard/ReportSectionEmptyState';
import { RestaurantId } from 'src/types/Id';
import { useFormatDateToRestaurantTimeZone } from 'src/utils/react/useFormatDateToRestaurantTimeZone';
import { useLoadApi } from 'src/utils/react/useLoadApi';
import { useSelector } from 'src/utils/react/useSelector';

export function PromoCodeReport({ colors }: Props): React.ReactElement {
    const classes = useStyles();

    const [salesByPromoCodeData, setSalesByPromoCodeData] = useState<Array<SalesByPromoCode>>([]);
    const [salesByPromoCodeByDayData, setSalesByPromoCodeByDayData] = useState<Array<SalesByPromoCodeByDay>>([]);
    const [discountBreakdownData, setDiscountBreakdownData] = useState<Array<DiscountBreakdownReport>>([]);
    const [hasInfoFound, setHasInfoFound] = useState(false);
    const [showAsMaster, setShowAsMaster] = useState(false);

    const restaurantId = useSelector((state) => state.app.restaurantId);
    const restaurantIds = useSelector((state) => state.app.restaurantIds);
    const brandOpened = useSelector((state) => state.app.brandOpened);
    const filterReportType = useSelector((state) => state.app2.filterReportsState.filterReportType);
    const period = useSelector((state) => state.app2.filterReportsState.period);
    const currentRestaurantId = useSelector((state) => state.app2.filterReportsState.currentRestaurantId);
    const salesType = useSelector((state) => state.app.salesTypeFilter);

    const formatDateToRestaurantTimeZone = useFormatDateToRestaurantTimeZone();

    const salesByPromoCodePerDayCsvFileName = `${translate('Sales by promoCode per day')}${period.from ? `-${period.from}` : ''}-${period.to}.csv`;

    const [loading, reports] = useLoadApi(
        getPromoCodeRestaurantStatisticsApi,
        {
            report: showAsMaster ? 'asBrand' : 'perRestaurant',
            restaurantIds: brandOpened ? restaurantIds : [restaurantId],
            fromDate: moment(formatDateToRestaurantTimeZone(period?.from)).toISOString(),
            toDate: moment(formatDateToRestaurantTimeZone(period.to)).toISOString(),
            salesType: salesType,
        },
        {
            dependencies: [period, filterReportType, showAsMaster, restaurantId, salesType],
        },
    );

    console.log('reports', reports);

    useEffect(() => {
        if (reports) {
            if (showAsMaster || !brandOpened) return handleUpdatePromoCodeReports(reports[0]?.restaurantId);

            handleUpdatePromoCodeReports(currentRestaurantId ?? reports[0]?.restaurantId);
        }
    }, [reports, currentRestaurantId]);

    const handleUpdatePromoCodeReports = (newRestaurantId: RestaurantId) => {
        const currentRestaurantReportInfo = reports?.find((restaurant) => restaurant.restaurantId === newRestaurantId);

        if (!hasRestaurantReportInfo(currentRestaurantReportInfo)) {
            setHasInfoFound(false);
            return;
        }
        setSalesByPromoCodeData(currentRestaurantReportInfo?.salesByPromoCode ?? []);
        setDiscountBreakdownData(currentRestaurantReportInfo?.discountBreakdownReport ?? []);
        setSalesByPromoCodeByDayData(currentRestaurantReportInfo?.salesByPromoCodeByDay ?? []);

        setHasInfoFound(true);
    };

    const hasRestaurantReportInfo = (currentRestaurant: (typeof reports)[number] | undefined) => currentRestaurant && currentRestaurant?.salesByPromoCode;

    const handleShowAsMaster = (value: any) => {
        setShowAsMaster(value);
    };

    return (
        <div>
            <FilterReports />
            <div className={classes.container}>
                {loading && <UpdatingContentProgress />}
                <FilterMasterReports onChangeMaster={handleShowAsMaster} showAsMaster={showAsMaster} />
                {hasInfoFound && (
                    <div>
                        <DiscountBreakdownReportTable loading={loading} discountBreakdownData={discountBreakdownData} fileName={salesByPromoCodePerDayCsvFileName} />
                        <PromoCodeMostUsedGraph loading={loading} colors={colors} salesByPromoCodeData={salesByPromoCodeData} />
                        <SalesByPromoCodeTable loading={loading} salesByPromoCodeByDayData={salesByPromoCodeByDayData} fileName={salesByPromoCodePerDayCsvFileName} />
                    </div>
                )}
                {!hasInfoFound && !loading && <ReportSectionEmptyState />}
            </div>
        </div>
    );
}

const useStyles = makeStyles((theme) => ({
    container: {
        width: '100%',
    },
}));

type Props = {
    colors: Array<string>;
};
