import { letseatmanagerApiMethod } from 'src/api/letseatmanagerApi';
import type { ApiSauceResponse } from 'src/api/types/ApiSauceResponse';
import type { BrandId, RestaurantId } from 'src/types/Id';

export async function getBrandContextApi(request: GetBrandContextRequest): ApiSauceResponse<BrandContextVm> {
    return letseatmanagerApiMethod('getBrandContextApi', request);
}

export type GetBrandContextRequest = {
    brandId?: BrandId;
};

export type BrandContextVm = {
    brandId?: BrandId;
    restaurantIds: Array<RestaurantId>;
    restaurantPromoCodesEnabled: boolean;
    paymentLinksEnabled: boolean;
    marketingEnabled: boolean;
};
