import { letseatmanagerApiMethod } from 'src/api/letseatmanagerApi';
import { ApiSauceResponse } from 'src/api/types/ApiSauceResponse';
import { OrderRejectReasonExtra } from 'src/constants/OrderRejectReasonExtra';
import { OrderId } from 'src/types/Id';
import { OrderVm } from 'src/types/OrderVm';

export async function changeOrderRejectReasonExtrasApi(request: ChangeOrderRejectReasonExtrasApiRequest): ApiSauceResponse<OrderVm> {
    return letseatmanagerApiMethod('order/changeOrderRejectReasonExtrasApi', request);
}

export type ChangeOrderRejectReasonExtrasApiRequest = {
    orderId: OrderId;
    orderRejectReasonExtras: Array<OrderRejectReasonExtra>;
};
