import { makeStyles } from '@material-ui/core';
import { Button } from '@pidedirecto/ui';
import { ScreenSizes } from '@pidedirecto/ui/constants';
import { useIsScreenSize } from '@pidedirecto/ui/hooks';
import { TrashCanIcon } from '@pidedirecto/ui/icons';
import { memo, useContext, useMemo, useState } from 'react';
import * as React from 'react';
import { app2 } from 'src/app2';
import { CancelProductDialog } from 'src/components/dialog/pos/CancelProductDialog';
import { KitchenStatuses } from 'src/constants/KitchenStatus';
import type { ProductCancellationReason } from 'src/constants/ProductCancellationReason';
import { RolePermissions } from 'src/constants/RolePermission';
import { translate } from 'src/i18n/translate';
import { PosItemQuantitySpinner } from 'src/scenes/letseatmanager/pos/posAddMenuItem/PosItemQuantitySpinner';
import { PosContext } from 'src/scenes/letseatmanager/pos/PosProvider';
import { useNotification } from 'src/services/notification/useNotification';
import { useAddPosItem } from 'src/services/pos/useAddPosItem';
import { useRemovePosItem } from 'src/services/pos/useRemovePosItem';
import type { CartItemVm } from 'src/types/CartItemVm';
import type { CustomProductCancellationReason } from 'src/types/Id';
import { PosCustomerOrderDetails } from 'src/types/PosCustomerOrderDetails';
import { PosPayment } from 'src/types/PosPayment';
import { isCancelledOrder } from 'src/utils/order/isCancelledOrder';
import { isOrderItemPaid } from 'src/utils/order/isOrderItemPaid';
import { isOrderItemPrinted } from 'src/utils/order/isOrderItemPrinted';
import { isItemSoldByWeight } from 'src/utils/orderItem/isItemSoldByWeight';
import { useAction } from 'src/utils/react/useAction';
import { useSelector } from 'src/utils/react/useSelector';
import { useUserHasRolePermission } from 'src/utils/react/useUserHasRolePermissions';

export const PosCartItemQuantity: React.ComponentType<Props> = memo<Props>(({ item }: Props) => {
    const classes = useStyles();
    const posContext = useContext(PosContext);
    const notification = useNotification();
    const isSmallScreen = useIsScreenSize(ScreenSizes.SMALL_SCREEN);

    const kitchenDisplayScreenEnabled = useSelector((state) => state.app.restaurant?.kitchenDisplayScreenEnabled);
    const hasPendingOrder = useSelector((state) => !!state.pos.pendingOrder);

    const [userHasRolePermission] = useUserHasRolePermission();

    const { addItem } = useAddPosItem();
    const { removeItem } = useRemovePosItem();

    const [cancelProductDialogState, setCancelProductDialogState] = useState({ open: false });

    const pendingOrder = useSelector((state) => state.pos.pendingOrder);
    const menuItems = useSelector((state) => state.pos.posMenu?.menuItems);
    const payments = useSelector((state) => state.pos.payments);
    const customers = useSelector((state) => state.pos.customers);

    const openVerifyPosPasswordDialog = useAction(app2.actions.openVerifyPosPasswordDialog);

    const isSoldByWeight = isItemSoldByWeight(item);

    const customerByOrderItem = customers?.find((customer: PosCustomerOrderDetails) => {
        return !!customer.orderItems.find((orderITem) => orderITem.menuItemId === item.menuItemId);
    });
    const quantityLabel = !!item.salesUnit ? `${item.quantity}${translate(`UnitsOfMeasurement.${item.salesUnit}`)}` : item.quantity;

    const isItemSent = useMemo(() => (!!pendingOrder ? isOrderItemPrinted(item, pendingOrder?.orderItems, menuItems) : false), [pendingOrder, item]);

    const handleRemoveOneItem = () => {
        if (isOrderItemPaid(item)) return notification({ message: translate('This product is paid, it cannot be cancelled') });

        if (customerByOrderItem) {
            const hasCustomerPayment = payments?.find((payment: PosPayment) => payment.customerNumber === customerByOrderItem.customerNumber);

            if (hasCustomerPayment) return notification({ message: translate('This product is paid, it cannot be cancelled') });
        }

        if (!pendingOrder) return removeItem({ item });

        if (!userHasRolePermission(RolePermissions.REMOVE_POS_CART_ITEM) && isItemSent) {
            return openVerifyPosPasswordDialog({
                onAuthorized: () => setCancelProductDialogState({ open: true }),
                onUnauthorized: () => alert(translate('Unauthorized to access.')),
            });
        }
        if (!isItemSent) return removeItem({ item });

        setCancelProductDialogState({ open: true });
    };

    const closeCancelProductDialog = () => setCancelProductDialogState({ open: false });

    const handleAddOneItem = () => {
        addItem({ ...item, quantity: 1, addedAt: posContext.menuOpenedAt, orderItemKitchenStatus: hasPendingOrder && kitchenDisplayScreenEnabled ? KitchenStatuses.PREPARING : undefined });
        if (isSmallScreen) {
            notification({ message: translate('Product added') });
        }
    };

    const setCancellationReasonAndRemoveItem = (reason: ProductCancellationReason | CustomProductCancellationReason) => {
        removeItem({ item, cancellationReason: reason });
        closeCancelProductDialog();
    };

    const pricePerUnitOfSalesText = () => {
        if (item.currency) return `${item.currency}`;
        return isSoldByWeight ? `${translate(`UnitsOfMeasurement.${item.salesUnit ?? ''}`)}` : '';
    };

    if (!!item.removedAt) return null;

    return (
        <>
            {cancelProductDialogState.open && <CancelProductDialog open={cancelProductDialogState.open} onClose={closeCancelProductDialog} onSelect={setCancellationReasonAndRemoveItem} />}
            {!isItemSoldByWeight(item) && !isCancelledOrder() && <PosItemQuantitySpinner quantity={item.quantity} onSubtract={handleRemoveOneItem} onAdd={handleAddOneItem} />}
            {isItemSoldByWeight(item) && (
                <div className={classes.separatedLineText}>
                    <Button variant={'icon'} onClick={handleRemoveOneItem}>
                        <TrashCanIcon />
                    </Button>
                    <div className={classes.quantityItemContainer}>
                        <span>{pricePerUnitOfSalesText()}</span>
                    </div>
                </div>
            )}
        </>
    );
});

const useStyles = makeStyles((theme) => ({
    separatedLineText: {
        fontFamily: theme.typography.regular,
        fontSize: 12,
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        marginTop: 8,
    },
    quantityItemContainer: {
        display: 'flex',
        width: 56,
        height: 28,
        fontFamily: theme.typography.medium,
        fontSize: 14,
        color: '#2E3748',
        alignItems: 'center',
        justifyContent: 'center',
        padding: '0 8px',
        border: '1px solid #D9D9D9',
        borderRadius: 4,
    },
    iconButton: {
        padding: 0,
        margin: '0  10px 0',
    },
}));

type Props = {
    item: CartItemVm;
};
