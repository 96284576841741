import { letseatmanagerApiMethod } from 'src/api/letseatmanagerApi';
import type { ApiSauceResponse } from 'src/api/types/ApiSauceResponse';
import type { RestaurantId } from 'src/types/Id';
import type { PosContextVm } from 'src/types/PosContextVm';

export async function getPosContextApi(request: GetPosContextApiRequest): ApiSauceResponse<PosContextVm> {
    return letseatmanagerApiMethod('pos/getPosContextApi', request);
}

export type GetPosContextApiRequest = {
    restaurantId: RestaurantId;
};
