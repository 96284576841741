import { makeStyles } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import { formatAsCurrencyNumber } from '@pidedirecto/ui/utils';
import MUIDataTable from 'mui-datatables';
import * as React from 'react';
import { useRef, useState } from 'react';
import { getFacturamaInvoiceUri } from 'src/api/letseatmanager/subscription/getFacturamaInvoiceUri';
import { sendInvoiceForSubscriptionPaymentApi } from 'src/api/letseatmanager/subscription/sendInvoiceForSubscriptionPaymentApi';
import { CreateInvoiceButton } from 'src/components/mui-datatables/CreateInvoiceButton';
import { CustomToolbarSelect } from 'src/components/mui-datatables/CustomToolbarSelect';
import { RefreshToolbarButton } from 'src/components/mui-datatables/RefreshToolbarButton';
import { UpdatingContentProgress } from 'src/components/UpdatingContentProgress';
import type { PaymentRejectReason } from 'src/constants/PaymentRejectReason';
import type { PaymentStatus } from 'src/constants/PaymentStatus';
import { translate } from 'src/i18n/translate';
import { appReducer } from 'src/reducers/appReducer';
import type { PaymentId, RestaurantId, SubscriptionId } from 'src/types/Id';
import { alertKnownErrorOrSomethingWentWrong } from 'src/utils/alert/alertKnownErrorOrSomethingWentWrong';
import { formatDateTimeString } from 'src/utils/date/formatDateTimeString';
import { formatDateTimeStringReadable } from 'src/utils/date/formatDateTimeStringReadable';
import { createFilteredOnDownloadFunctional } from 'src/utils/mui-datatables/createFilteredOnDownloadFunctional';
import { getSelectedRowsData } from 'src/utils/mui-datatables/getSelectedRowsData';
import { useAction } from 'src/utils/react/useAction';
import { useSelector } from 'src/utils/react/useSelector';
import { openRestaurantBillingPageInNewTab } from 'src/utils/window/openRestaurantBillingPageInNewTab';
import { openSubscriptionPageInNewTab } from 'src/utils/window/openSubscriptionPageInNewTab';

export function SubscriptionPayPerUsePaymentsTable({ payments = [], loading, title, onClickRefresh }: Props): React.ReactElement {
    const classes = useStyles();
    const table = useRef(null);

    const [sendingInvoices, setSendingInvoices] = useState(false);

    const viewUser = useSelector((state) => state.authentication.viewUser);

    const showSnackbar = useAction(appReducer.actions.showSnackbar);

    const sendInvoice = async (payment: any) => {
        setSendingInvoices(true);
        const response = await sendInvoiceForSubscriptionPaymentApi({ paymentId: payment.paymentId });
        if (!response.ok) {
            setSendingInvoices(false);
            onClickRefresh();
            alertKnownErrorOrSomethingWentWrong(response);
            return;
        }
        setSendingInvoices(false);
        onClickRefresh();
    };

    const createDownload = async (payment: any, documentType: 'xml' | 'pdf') => {
        setSendingInvoices(true);
        const response = await getFacturamaInvoiceUri({ paymentId: payment.paymentId, documentType });
        if (!response.ok) {
            setSendingInvoices(false);
            onClickRefresh();
            alertKnownErrorOrSomethingWentWrong(response);
            return;
        }
        setSendingInvoices(false);
        onClickRefresh();
        const downloadAction = document.createElement('a');
        // downloadAction.href = response.data.invoiceUri;
        downloadAction.download = `${translate('Subscription')}_${payment.createdAt}.${documentType}`;
        document.body?.appendChild(downloadAction);
        downloadAction.click();
        document.body?.removeChild(downloadAction);
    };

    const sendInvoices = async (paymentIds: Array<PaymentId>) => {
        setSendingInvoices(true);
        const result: Record<string, any> = {};
        let counter = 0;
        for (const paymentId of paymentIds) {
            counter++;
            showSnackbar({ message: `Sending invoice ${counter} of ${paymentIds.length} (DONT CLOSE OR LEAVE PAGE)`, autoHideDuration: null });
            const response = await sendInvoiceForSubscriptionPaymentApi({ paymentId });
            if (!response.ok) {
                setSendingInvoices(false);
                alertKnownErrorOrSomethingWentWrong(response);
                result['Failed'] = paymentIds.length - counter;
            }
            result['Success'] = (result['Success'] ?? 0) + 1;
        }
        setSendingInvoices(false);
        onClickRefresh();
        showSnackbar({ message: `Sending invoices DONE (YOU MAY LEAVE PAGE)` });
        alert(
            'Result:\n' +
                Object.entries(result)
                    .map(([error, count]: [any, any]) => `${error as any}: ${String(count)}`)
                    .join('\n'),
        );
    };

    return (
        <div className={classes.container}>
            <UpdatingContentProgress loading={loading || sendingInvoices} />
            <MUIDataTable
                /* @ts-ignore */
                ref={table}
                className={classes.table}
                title={title || translate('Payments')}
                data={payments.map((payment) => ({
                    paymentId: payment.paymentId,
                    restaurantName: (
                        <Button size='small' onClick={() => openRestaurantBillingPageInNewTab(payment.restaurantId)}>
                            {payment.restaurantName}
                        </Button>
                    ),
                    subscriptionId: (
                        <Button size='small' onClick={() => openSubscriptionPageInNewTab(payment.subscriptionId)}>
                            {translate('Open Subscription')}
                        </Button>
                    ),
                    amount: formatAsCurrencyNumber(payment.amount),
                    createdAt: formatDateTimeStringReadable(payment.createdAt),
                    invoicedAt: formatDateTimeString(payment.invoicedAt),
                    facturamaInvoiceError: payment.facturamaInvoiceError,
                    sendInvoice: (
                        <Button
                            size='small'
                            onClick={async () => {
                                await sendInvoice(payment);
                            }}
                        >
                            {translate('Send/Resend')}
                        </Button>
                    ),
                    downloadInvoice: (
                        <>
                            <Button
                                size='small'
                                onClick={async () => {
                                    await createDownload(payment, 'pdf');
                                }}
                            >
                                {translate('PDF')}
                            </Button>
                            <Button
                                size='small'
                                onClick={async () => {
                                    await createDownload(payment, 'xml');
                                }}
                            >
                                {translate('XML')}
                            </Button>
                        </>
                    ),
                }))}
                columns={[
                    {
                        name: 'paymentId',
                        label: '',
                        options: {
                            display: 'excluded',
                            filter: false,
                        },
                    },
                    {
                        name: 'restaurantName',
                        label: translate('Restaurant'),
                        options: {
                            filter: false,
                        },
                    },
                    {
                        name: 'subscriptionId',
                        label: translate('Subscription'),
                        options: {
                            filter: false,
                        },
                    },
                    {
                        name: 'amount',
                        label: translate('Total'),
                        options: {
                            filter: false,
                        },
                    },
                    {
                        name: 'createdAt',
                        label: translate('Payment Created At'),
                        options: {
                            filter: false,
                        },
                    },
                    {
                        name: 'invoicedAt',
                        label: translate('Invoice Created At'),
                        options: {
                            filter: false,
                        },
                    },
                    {
                        name: 'facturamaInvoiceError',
                        label: translate('Invoice Error'),
                        options: {
                            filter: false,
                        },
                    },
                    {
                        name: 'sendInvoice',
                        label: translate('Send/Resend Invoice'),
                        options: {
                            filter: false,
                        },
                    },
                    {
                        name: 'downloadInvoice',
                        label: translate('Download Invoice'),
                        options: {
                            filter: false,
                        },
                    },
                ]}
                options={{
                    responsive: 'standard',
                    tableBodyMaxHeight: '500px',
                    selectableRows: !viewUser ? 'multiple' : 'none',
                    filterType: 'checkbox',
                    rowsPerPage: 100,
                    onDownload: createFilteredOnDownloadFunctional(table),
                    customToolbarSelect: (selectedRows, displayData, paymentBilling) => {
                        const handleClickCreateInvoices = async () => {
                            const selectedPaymentIds = getSelectedRowsData(selectedRows, displayData).map((d: any) => d[0]);
                            await sendInvoices(selectedPaymentIds);
                        };

                        return (
                            <CustomToolbarSelect>
                                <CreateInvoiceButton label={translate('Create Invoices')} tooltip={translate('Create Invoices')} onClick={handleClickCreateInvoices} />
                            </CustomToolbarSelect>
                        );
                    },
                    customToolbar: () => (
                        <>
                            <RefreshToolbarButton onClick={onClickRefresh} />
                        </>
                    ),
                }}
            />
        </div>
    );
}

const useStyles = makeStyles((theme) => ({
    container: {
        position: 'relative',
        width: '90%',
        margin: '0 auto',
        '& .MuiPaper-root': {
            boxShadow: '0px 0px',
            '& .MuiToolbar-root': {
                backgroundColor: 'white',
                paddingRight: 0,
                '& .MuiButtonBase-root': {
                    backgroundColor: 'white',
                    marginLeft: 5,
                    borderRadius: 5,
                    border: `1px solid ${theme.palette.secondary.dark}`,
                    '& svg': {
                        fill: theme.palette.primary.main,
                    },
                },
                '& .MuiButtonBase-root:hover': {
                    backgroundColor: theme.palette.primary.main,
                    '& svg': {
                        fill: 'white',
                    },
                },
                '& h6': {
                    fontFamily: theme.typography.medium,
                },
            },
            '& .MuiTableFooter-root': {
                borderBottomLeftRadius: 5,
                borderBottomRightRadius: 5,
                border: `1px solid ${theme.palette.secondary.dark}`,
                borderTop: 0,
                borderCollapse: 'separate',
                '& p, div': {
                    fontFamily: theme.typography.regular,
                    fontSize: 13,
                },
                '& .MuiToolbar-root': {
                    backgroundColor: 'white',
                    '& .MuiButtonBase-root': {
                        backgroundColor: 'white',
                        marginRight: 5,
                        borderRadius: 5,
                        border: `0px`,
                        '& svg': {
                            fill: 'black',
                        },
                    },
                    '& .MuiButtonBase-root:hover': {
                        backgroundColor: 'white',
                        '& svg': {
                            fill: 'black',
                        },
                    },
                },
                '& td': {
                    border: 0,
                },
                '& td:hover': {
                    backgroundColor: 'white',
                },
            },
        },
        '& .MuiPaper-root > div:nth-child(3)': {
            borderTopLeftRadius: 5,
            borderTopRightRadius: 5,
            paddingTop: 10,
            border: `1px solid ${theme.palette.secondary.dark}`,
            borderBottom: 0,
            '& th': {
                fontFamily: theme.typography.semiBold,
                backgroundColor: theme.palette.secondary.light,
                border: 0,
                fontSize: 15,
            },
            '& td, .MuiTypography-root': {
                fontFamily: theme.typography.regular,
                border: 0,
                fontSize: 13,
            },
        },
        [theme.breakpoints.down('sm')]: {
            '& .MuiPaper-root': {
                // @ts-ignore
                backgroundColor: theme.palette.background.light,
                '& .MuiToolbar-root': {
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    padding: '20px 0',
                    '& .MuiButtonBase-root': {
                        width: 'calc(85vw / 7)',
                        height: 'calc(85vw / 7)',
                    },

                    '& div:first-child': {
                        display: 'none',
                    },

                    '& div:nth-child(2)': {
                        '& > .MuiButtonBase-root:first-child': {
                            marginLeft: '0 !important',
                        },
                    },
                },
                '& .MuiTable-root': {
                    width: '90%',
                    margin: '0 auto',
                    '& .MuiTableFooter-root': {
                        backgroundColor: 'white',
                        '& .MuiToolbar-root > p:nth-child(2)': {
                            display: 'none',
                        },
                    },
                },
            },
            '& .MuiPaper-root > div:nth-child(3)': {
                width: '90%',
                margin: '0 auto',
                backgroundColor: 'white ',
            },
        },
    },
    table: {
        whiteSpace: 'nowrap',
    },
    toolbar: {
        paddingRight: theme.spacing(3),
    },
}));

type Props = {
    payments: Array<{
        paymentId: PaymentId;
        subscriptionId: SubscriptionId;
        restaurantId: RestaurantId;
        restaurantName: string;
        paymentStatus: PaymentStatus;
        paymentRejectReason?: PaymentRejectReason;
        amount: string;
        facturamaInvoiceError?: string;
        createdAt: Date;
        invoicedAt?: Date;
    }>;
    loading: boolean;
    title?: string;
    onClickRefresh: any;
};
