import type { GeoJsonPoint } from 'src/types/GeoJsonPoint';
import type { OrderVm } from 'src/types/OrderVm';

export function getRestaurantLocations(orders: Array<OrderVm>): Array<GeoJsonPoint> {
    return orders.reduce<Array<any>>((restaurantLocations: Array<GeoJsonPoint>, order) => {
        const currentRestaurantLocation = order.restaurant?.location;
        if (!currentRestaurantLocation) return restaurantLocations;

        const locationHasAlreadyBeenAdded = restaurantLocations.some(
            (restaurantLocation) => restaurantLocation.coordinates[0] === currentRestaurantLocation.coordinates[0] && restaurantLocation.coordinates[1] === currentRestaurantLocation.coordinates[1],
        );
        if (locationHasAlreadyBeenAdded) return restaurantLocations;

        return [...restaurantLocations, currentRestaurantLocation];
    }, []);
}
