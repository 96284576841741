import { makeStyles, Menu } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import MenuItem from '@material-ui/core/MenuItem';
import { ExpandMore } from '@material-ui/icons';
import { CutleryIcon, FoodBagIcon, MotorcycleIcon, OutlinedDoorbellIcon } from '@pidedirecto/ui/icons';
import * as React from 'react';
import { useState } from 'react';
import { changeOrderTypeApi } from 'src/api/letseatmanager/order/changeOrderTypeApi';
import { app2 } from 'src/app2';
import { SelectTableOfOrderDialog } from 'src/components/orderCard/SelectTableOfOrderDialog';
import { OrderStatuses } from 'src/constants/OrderStatus';
import { OrderTypes } from 'src/constants/OrderType';
import { PickupTimeTypes } from 'src/constants/PickupTimeType';
import { translate } from 'src/i18n/translate';
import { EatHereIcon } from 'src/icons/EatHereIcon';
import { TakeAwayBagIcon } from 'src/icons/TakeAwayBagIcon';
import { LocalizationService } from 'src/services/LocalizationService';
import type { OrderVm } from 'src/types/OrderVm';
import { alertKnownErrorOrSomethingWentWrong } from 'src/utils/alert/alertKnownErrorOrSomethingWentWrong';
import { isClosedOrder } from 'src/utils/order/isClosedOrder';
import { isDeliveryOrder } from 'src/utils/order/isDeliveryOrder';
import { isRoomServiceOrder } from 'src/utils/order/isRoomServiceOrder';
import { isTakeAwayOrder } from 'src/utils/order/isTakeAwayOrder';
import { classNames } from 'src/utils/react/classNames';
import { useAction } from 'src/utils/react/useAction';
import { useSelector } from 'src/utils/react/useSelector';

export function OrderTypeSelect({ order, onChangeOrderType, classes: classesProp }: Props): React.ReactElement {
    const classes = useStyles();

    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

    const [loading, setLoading] = useState(false);
    const [openSelectTableOrderDialog, setOpenSelectTableOrderDialog] = useState(false);

    const restaurantId = useSelector((state) => state.app.restaurantId);
    const roomServiceCustomLabel = useSelector((state) => state.app.restaurant?.roomServiceCustomLabel);

    const updateOrder = useAction(app2.actions.updateOrder);

    const menuOpen = Boolean(anchorEl);
    const isOpenOrder = order.orderStatus === OrderStatuses.PENDING || order.orderStatus === OrderStatuses.ACCEPTED;
    const isEatHereOrder = order.orderType === OrderTypes.TABLE_ORDER || order.orderType === OrderTypes.SELF_SERVICE_ORDER;
    const iconColor = '#C1C3C8';

    const handleTakeAwayOrder = async () => {
        setLoading(true);
        const response = await changeOrderTypeApi({
            restaurantId,
            orderId: order.orderId,
            orderType: OrderTypes.TAKE_AWAY_ORDER,
            pickupTimeType: PickupTimeTypes.ASAP,
            pickupTime: new Date(),
        });
        setLoading(false);

        if (!response.ok) {
            return alertKnownErrorOrSomethingWentWrong(response);
        }

        onChangeOrderType?.();
        updateOrder({ order: response.data });
    };

    const handleClick = (event: any) => {
        if (isDeliveryOrder(order.orderType)) return;
        if (!isOpenOrder) return;

        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const getOrderTypeLabel = () => {
        if (isDeliveryOrder(order.orderType) || isTakeAwayOrder(order.orderType)) {
            if (order.pickupTimeType === PickupTimeTypes.PLANNED)
                return `${translate(`OrderTypes.${order.orderType}`)} ${translate(`PickupTimeTypes.${order.pickupTimeType}`)} ${LocalizationService.formatDateTimeAsTime(
                    order.pickupTime,
                    order.timeZone,
                )}`;

            return `${translate(`OrderTypes.${order.orderType}`)} ASAP`;
        }

        if (isRoomServiceOrder(order.orderType)) {
            return roomServiceCustomLabel ? `${roomServiceCustomLabel} ${order?.roomServiceNumber}` : translate('room - @room', { room: order?.roomServiceNumber ?? 0 });
        }
        return translate(`OrderTypes.${order.orderType}`);
    };

    const handleCloseSelectTableOrderDialog = () => setOpenSelectTableOrderDialog(false);

    const onSuccessSelectTableOfOrder = (order: any) => {
        onChangeOrderType?.();
        updateOrder({ order });
    };

    const getOrderTypeIcon = () => {
        if (isDeliveryOrder(order.orderType)) return <MotorcycleIcon size={30} title={translate('Delivery order')} color={iconColor} />;
        if (isTakeAwayOrder(order.orderType)) return <FoodBagIcon title={translate('Take away order')} color={iconColor} />;
        if (isRoomServiceOrder(order.orderType)) return <OutlinedDoorbellIcon title={translate('Room service order')} color={iconColor} />;

        return <CutleryIcon size={15} color={iconColor} title={translate('Eat here order')} />;
    };

    return (
        <div>
            <SelectTableOfOrderDialog open={openSelectTableOrderDialog} onClose={handleCloseSelectTableOrderDialog} onSuccess={onSuccessSelectTableOfOrder} orderId={order.orderId} />
            <Button
                aria-label='orderTypeMenuButton'
                id='basic-button'
                aria-controls={menuOpen ? 'basic-menu' : undefined}
                aria-haspopup='true'
                aria-expanded={menuOpen ? 'true' : undefined}
                onClick={handleClick}
                className={classes.orderTypeContainer}
                disabled={loading || isClosedOrder(order.orderStatus)}
            >
                {getOrderTypeIcon()}
                <div className={classes.optionText}>{getOrderTypeLabel()}</div>
                <ExpandMore classes={{ root: classes.openOptionsIcon }} color={'primary'} />
            </Button>
            <Menu
                id='basic-menu'
                anchorEl={anchorEl}
                anchorReference={'anchorEl'}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                transformOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                open={menuOpen}
                elevation={0}
                onClose={handleClose}
                onClickCapture={handleClose}
                classes={{ paper: classes.menuOptions }}
            >
                {!isTakeAwayOrder(order.orderType) && (
                    <MenuItem onClick={handleTakeAwayOrder} classes={{ root: classes.orderTypeMenuOption }} disabled={loading || isClosedOrder(order.orderStatus)}>
                        <TakeAwayBagIcon width={15} height={15} color={'#C1C3C8'} title={'takeAway-orderType'} />
                        <div className={classNames(classes.optionText, classesProp?.text)}> {translate(`OrderTypes.${OrderTypes.TAKE_AWAY_ORDER}`)}</div>
                    </MenuItem>
                )}
                {!isEatHereOrder && (
                    <MenuItem onClick={() => setOpenSelectTableOrderDialog(true)} classes={{ root: classes.orderTypeMenuOption }} disabled={loading || isClosedOrder(order.orderStatus)}>
                        <EatHereIcon width={15} height={15} color={'#C1C3C8'} title={'eatHere-orderType'} />
                        <div className={classNames(classes.optionText, classesProp?.text)}> {translate(`OrderTypes.${OrderTypes.TABLE_ORDER}`)}</div>
                    </MenuItem>
                )}
            </Menu>
        </div>
    );
}

const useStyles = makeStyles((theme) => ({
    orderTypeContainer: {
        width: '100%',
        display: 'flex',
        textTransform: 'none',
        alignContent: 'center',
        justifyContent: 'space-evenly',
        fontFamily: theme.typography.regular,
        fontWeight: 600,
        fontSize: 12,
        backgroundColor: 'transparent',
    },
    openOptionsIcon: {
        '&.MuiSvgIcon-colorPrimary': {
            color: '#C1C3C8',
        },
    },
    menuOptions: {
        border: '1px solid #D9D9D9',
        boxShadow: '4px 4px 8px 0 rgba(0, 0, 0, 0.25)',
    },
    orderTypeMenuOption: {
        fontFamily: theme.typography.regular,
        fontSize: 12,
        color: '#313333',
    },
    optionText: {
        marginLeft: 10,
    },
}));

type Props = {
    order: OrderVm;
    onChangeOrderType?: any;
    classes?: {
        text?: string;
    };
};
