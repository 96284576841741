import { IconButton } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { BigNumber } from 'bignumber.js';
import { useState } from 'react';
import * as React from 'react';
import { TreeMapCanvas } from 'src/components/charts/TreeMapCanvas';
import { OrderRejectReason } from 'src/constants/OrderRejectReason';
import { translate } from 'src/i18n/translate';
import { GraphIcon } from 'src/icons/GraphIcon';
import { TableIcon } from 'src/icons/TableIcon';
import { CashRegisterCancelledOrdersTableReport } from 'src/scenes/letseatmanager/posReports/cashRegisterReport/CashRegisterCancelledOrdersTableReport';
import { GraphEmptyState } from 'src/scenes/letseatmanager/posReports/GraphEmptyState';
import type { CashRegisterCancelledOrderReport, CashRegisterReportVm } from 'src/types/CashRegisterReportVm';
import { sum } from 'src/utils/reduce/sum';

export function CashRegisterCancelledOrdersReport({ cashRegisterReport }: Props): React.ReactElement {
    const classes = useStyles();

    const [showTable, setShowTable] = useState(false);

    const getCancelledOrdersReportTreeMapData = () => {
        const cancelledOrdersReport = cashRegisterReport.cancelledOrdersByCashRegister.reduce<Array<any>>(groupUpCancelledOrdersByOrderRejectReason, []);
        return cancelledOrdersReport.map((cancelledOrderReport) => ({
            id: translate(`OrderRejectReasons.${cancelledOrderReport.orderRejectReason}`),
            value: cancelledOrderReport.orderVolume,
        }));
    };

    const groupUpCancelledOrdersByOrderRejectReason = (cancelledOrdersReport: Array<CancelledOrdersReport>, cashRegisterCancelledOrdersReport: CashRegisterCancelledOrderReport) => {
        const cancelledOrderReport = cancelledOrdersReport.find((cancelledOrderReport) => cancelledOrderReport.orderRejectReason === cashRegisterCancelledOrdersReport.orderRejectReason);
        if (cancelledOrderReport) {
            cancelledOrderReport.orderVolume = BigNumber(cancelledOrderReport.orderVolume).plus(cashRegisterCancelledOrdersReport.orderVolume).toString();
            cancelledOrderReport.amountWithDiscount = BigNumber(cancelledOrderReport.amountWithDiscount).plus(cashRegisterCancelledOrdersReport.amountWithDiscount).toString();
            return cancelledOrdersReport;
        }

        cancelledOrdersReport.push({
            orderRejectReason: cashRegisterCancelledOrdersReport.orderRejectReason,
            orderVolume: BigNumber(cashRegisterCancelledOrdersReport.orderVolume).toString(),
            amountWithDiscount: BigNumber(cashRegisterCancelledOrdersReport.amountWithDiscount).toString(),
        });
        return cancelledOrdersReport;
    };

    const isEmptyReport = () => {
        const cancelledOrders = cashRegisterReport.cancelledOrdersByCashRegister.map((cancelledOrderReport) => cancelledOrderReport.orderVolume);
        const totalCancelledOrders = cancelledOrders.reduce(sum, BigNumber(0));
        return totalCancelledOrders.isZero();
    };

    if (isEmptyReport()) {
        return <GraphEmptyState title={translate('Cancelled orders')} />;
    }

    return (
        <article>
            <div className={classes.titleContainer}>
                <h2 className={classes.title}>{translate('Cancelled orders')}</h2>
                <IconButton onClick={() => setShowTable(!showTable)} className={classes.iconButton}>
                    {showTable && <GraphIcon />}
                    {!showTable && <TableIcon />}
                </IconButton>
            </div>
            <div className={classes.chartContainer}>
                {!showTable && <TreeMapCanvas data={getCancelledOrdersReportTreeMapData()} />}
                {showTable && <CashRegisterCancelledOrdersTableReport cancelledOrdersByCashRegister={cashRegisterReport.cancelledOrdersByCashRegister} />}
            </div>
        </article>
    );
}

const useStyles = makeStyles((theme) => ({
    title: {
        fontFamily: theme.typography.medium,
        color: theme.palette.text.primary,
    },
    titleContainer: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        width: '100%',
        justifyContent: 'space-between',
    },
    iconButton: {
        color: theme.palette.icons.primary,
    },
    chartContainer: {
        height: '75vh',
        width: '100%',
        maxHeight: '75vh',
        overflowY: 'scroll',
    },
}));

type Props = {
    cashRegisterReport: CashRegisterReportVm;
};

type CancelledOrdersReport = {
    orderRejectReason: OrderRejectReason;
    orderVolume: string;
    amountWithDiscount: string;
};
