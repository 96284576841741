import Grid from '@material-ui/core/Grid';
import * as React from 'react';
import { FormSwitch } from 'src/components/form/FormSwitch';
import { SectionTitle } from 'src/components/title/SectionTitle';
import { TicketSections } from 'src/constants/TicketSection';
import { translate } from 'src/i18n/translate';

export function TicketBusinessInfoSettings({ disabled }: Props): React.ReactElement {
    return (
        <section>
            <SectionTitle title={translate('Business Information')} />
            <Grid container xs={12}>
                {/* @ts-ignore */}
                <Grid item xs={12} md={6} columnSpacing={2}>
                    <FormSwitch disabled={disabled} name={TicketSections.BUSINESS_NAME} label={translate(`TicketSections.${TicketSections.BUSINESS_NAME}`)} />
                </Grid>
                {/* @ts-ignore */}
                <Grid item xs={12} md={6} columnSpacing={2}>
                    <FormSwitch disabled={disabled} name={TicketSections.RFC} label={translate(`TicketSections.${TicketSections.RFC}`)} />
                </Grid>
                {/* @ts-ignore */}
                <Grid item xs={12} md={6} columnSpacing={2}>
                    <FormSwitch disabled={disabled} name={TicketSections.FISCAL_ADDRESS} label={translate(`TicketSections.${TicketSections.FISCAL_ADDRESS}`)} />
                </Grid>
                {/* @ts-ignore */}
                <Grid item xs={12} md={6} columnSpacing={2}>
                    <FormSwitch disabled={disabled} name={TicketSections.RESTAURANT_PHONE} label={translate(`TicketSections.${TicketSections.RESTAURANT_PHONE}`)} />
                </Grid>
                {/* @ts-ignore */}
                <Grid item xs={12} md={6} columnSpacing={2}>
                    <FormSwitch disabled={disabled} name={TicketSections.RESTAURANT_SECONDARY_PHONE} label={translate(`TicketSections.${TicketSections.RESTAURANT_SECONDARY_PHONE}`)} />
                </Grid>
                {/* @ts-ignore */}
                <Grid item xs={12} md={6} columnSpacing={2}>
                    <FormSwitch disabled={disabled} name={TicketSections.RESTAURANT_EMAIL} label={translate(`TicketSections.${TicketSections.RESTAURANT_EMAIL}`)} />
                </Grid>
            </Grid>
        </section>
    );
}

type Props = {
    disabled: boolean;
};
