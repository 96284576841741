import { Grid, InputLabel } from '@material-ui/core';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { makeStyles } from '@material-ui/core/styles';
import { useContext } from 'react';
import * as React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { FormContext } from 'src/components/form/Form';
import { ImageUploaderContainer } from 'src/components/input/ImageUploaderContainer';
import { ImageContainer } from 'src/scenes/letseatmanager/menu/components/ImageContainer';
import { classNames } from 'src/utils/react/classNames';

export function FormImageUpload({ name, label, defaultValue, disabled, classes: classesProp, message }: Props): React.ReactElement {
    const classes = useStyles();
    const {
        control,
        formState: { isSubmitting },
    } = useFormContext();
    const formContext = useContext(FormContext);

    return (
        <FormControlLabel
            label={undefined}
            style={{ marginLeft: 0 }}
            control={
                <Controller
                    control={control}
                    name={name}
                    defaultValue={defaultValue ?? null}
                    render={({ onChange, value, name }) => (
                        <Grid item xs={12}>
                            <InputLabel htmlFor={name} className={classNames(classes.label, classesProp?.label)}>
                                {label}
                            </InputLabel>
                            <input type='hidden' name={name} id={name} value={value} />
                            {value && <ImageContainer image={value} onRemoveImage={onChange} classes={{ imagePreview: classesProp?.imagePreview }} />}
                            {!value && <ImageUploaderContainer onChange={onChange} message={message} />}
                        </Grid>
                    )}
                />
            }
            disabled={isSubmitting || disabled || formContext.disabled}
        />
    );
}

const useStyles = makeStyles((theme) => ({
    label: {
        fontFamily: theme.typography.medium,
        color: theme.palette.text.primary,
        fontSize: 15,
        marginBottom: 5,
        transform: 'scale(1)',
    },
}));

type Props = {
    name: string;
    label?: string;
    defaultValue?: string;
    message?: string;
    disabled?: boolean;
    classes?: {
        imagePreview?: string;
        label?: string;
    };
};
