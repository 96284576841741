import { useNotification } from '@pidedirecto/ui/hooks';
import { RestaurantContext } from 'src/api/letseatmanager/getAppContextApi';
import { PrinterContents } from 'src/constants/PrinterContent';
import { PrinterPaperSizes } from 'src/constants/PrinterPaperSize';
import { PrintTypes } from 'src/constants/PrintType';
import { translate } from 'src/i18n/translate';
import { getDeprecatedPrintersInLocalStorage } from 'src/localStorage/getDeprecatedPrintersInLocalStorage';
import { createOrderTicketPrint } from 'src/services/printer/prints/createOrderTicketPrint';
import { printerDeprecatedToPrinter } from 'src/services/printer/utils/printerDeprecatedToPrinter';
import { usePrintContent } from 'src/services/printer/utils/usePrintContent';
import { DeviceGroupId } from 'src/types/Id';
import type { OrderVm } from 'src/types/OrderVm';
import { PrinterVm } from 'src/types/PrinterVm';
import { useSelector } from 'src/utils/react/useSelector';

export function usePrintOrderTicket(): [(order: OrderVm, options?: PrintOrderTicketOptions) => Promise<void>] {
    let allPrinters: Array<PrinterVm> = [];
    const notification = useNotification();
    const { printContent, printContents } = usePrintContent();

    const newDeviceManagementEnabled = useSelector((state) => state.app.restaurant?.newDeviceManagementEnabled);
    const deviceGroup = useSelector((state) => state.app.deviceGroup);
    const printers = useSelector((state) => state.app.printers);
    const internalUser = useSelector((state) => state.authentication.internalUser);
    const restaurant = useSelector((state) => state.app.restaurant);
    const restaurants = useSelector((state) => state.app.restaurants);

    const deprecatedPrinters = getDeprecatedPrintersInLocalStorage().map(printerDeprecatedToPrinter);

    if (newDeviceManagementEnabled) {
        allPrinters = printers?.filter((printer: PrinterVm) =>
            printer.deviceGroups?.some((deviceGroupToSearch: { deviceGroupId: DeviceGroupId; deviceGroupName: string }) => deviceGroupToSearch.deviceGroupId === deviceGroup?.deviceGroupId),
        );
    }

    if (!newDeviceManagementEnabled) {
        allPrinters = deprecatedPrinters;
    }

    const printTicket = async (order: OrderVm, options?: PrintOrderTicketOptions) => {
        const orderRestaurant = restaurants?.find((restaurant: RestaurantContext) => restaurant.restaurantId === order.restaurant?.restaurantId);

        if (!allPrinters.length && options?.forceToPrint) {
            notification({ message: translate('No active printers to select') });
            return;
        }

        if (allPrinters.length === 1 && options?.forceToPrint) {
            await printContents([
                {
                    printer: allPrinters[0],
                    print: {
                        printType: PrintTypes.TICKET,
                        printerInstructions: createOrderTicketPrint({
                            order: order,
                            restaurant,
                            printerPaperSize: allPrinters[0].printerPaperSize,
                            internalUser,
                            openCashBox: options?.openCashBox ?? true,
                            ticketSections: orderRestaurant?.ticketSections || restaurant?.ticketSections || [],
                        }),
                        numberOfPrints: orderRestaurant?.numberOfTicketPrints || restaurant.numberOfTicketPrints || 1,
                    },
                },
            ]);
            return;
        }

        const ticketPrinters = allPrinters?.filter((printer: PrinterVm) => {
            return printer.printerContent !== PrinterContents.COMMAND;
        });

        if (!ticketPrinters.length && options?.forceToPrint) {
            await printContent({
                printType: PrintTypes.TICKET,
                printerInstructions: createOrderTicketPrint({
                    order: order,
                    restaurant,
                    printerPaperSize: PrinterPaperSizes['80_MM'],
                    internalUser,
                    openCashBox: options?.openCashBox ?? true,
                    ticketSections: orderRestaurant?.ticketSections || restaurant?.ticketSections || [],
                }),
                numberOfPrints: orderRestaurant?.numberOfTicketPrints || restaurant.numberOfTicketPrints || 1,
            });

            return;
        }

        const availableTicketPrinters = ticketPrinters?.filter((printer) => {
            if (!printer.channels?.length) return true;

            return order.app && printer.channels?.includes(order.app);
        });

        const contentsToPrint = availableTicketPrinters.map((printer) => ({
            printer: printer,
            print: {
                printType: PrintTypes.TICKET,
                printerInstructions: createOrderTicketPrint({
                    order: order,
                    restaurant,
                    printerPaperSize: printer.printerPaperSize,
                    internalUser,
                    openCashBox: options?.openCashBox ?? true,
                    ticketSections: orderRestaurant?.ticketSections || restaurant?.ticketSections || [],
                }),
                numberOfPrints: orderRestaurant?.numberOfTicketPrints || restaurant.numberOfTicketPrints || 1,
            },
        }));

        await printContents(contentsToPrint);
    };

    return [printTicket];
}

type PrintOrderTicketOptions = {
    forceToPrint: boolean;
    openCashBox?: boolean;
};
