import { FormSelect } from '@pidedirecto/ui/form';
import * as React from 'react';
import { translate } from 'src/i18n/translate';
import { PosCustomerOrderDetails } from 'src/types/PosCustomerOrderDetails';
import { useSelector } from 'src/utils/react/useSelector';

export function FormCustomerNumberSelect({ name, label, placeholder, helperText, defaultValue, disabled, required }: Props): React.ReactElement {
    const customers = useSelector((state) => state.pos.customers);

    const customersWithOrderItems = customers?.filter((customer: PosCustomerOrderDetails) => !!customer.orderItems?.length);

    const options = required
        ? customersWithOrderItems?.map((customer) => ({ label: translate(`Customer @customerNumber`, { customerNumber: customer.customerNumber }), value: customer.customerNumber }))
        : [
              { label: translate('General account'), value: '0' },
              ...customersWithOrderItems?.map((customer) => ({ label: translate(`Customer @customerNumber`, { customerNumber: customer.customerNumber }), value: customer.customerNumber })),
          ];
    return (
        <FormSelect
            name={name}
            label={label}
            placeholder={placeholder}
            helperText={helperText}
            defaultValue={defaultValue}
            options={options}
            disabled={disabled}
            required={{
                value: !!required,
                message: translate('This field is required'),
            }}
        />
    );
}

type Props = {
    name: string;
    label?: string;
    placeholder?: string;
    helperText?: string;
    defaultValue?: string | null | undefined;
    disabled?: boolean;
    required?: boolean;
};
