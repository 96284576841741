import { letseatmanagerApiMethod } from 'src/api/letseatmanagerApi';
import type { ApiSauceResponse } from 'src/api/types/ApiSauceResponse';
import type { CompoundSupplyReportVm } from 'src/types/CompoundSupplyReportVm';
import type { RestaurantId } from 'src/types/Id';

export async function findCompoundSupplyReportsApi(request: FindCompoundSupplyReportsApiRequest): ApiSauceResponse<Array<CompoundSupplyReportVm>> {
    return letseatmanagerApiMethod('compoundSupplyReport/findCompoundSupplyReportsApi', request);
}

type FindCompoundSupplyReportsApiRequest = {
    restaurantId: RestaurantId;
};
