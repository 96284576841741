import { letseatmanagerApiMethod } from 'src/api/letseatmanagerApi';
import type { ApiSauceResponse } from 'src/api/types/ApiSauceResponse';
import type { BannerId, RestaurantId } from 'src/types/Id';

export async function removeBannerApi(request: RemoveBannerApiRequest): ApiSauceResponse<void> {
    return letseatmanagerApiMethod('banner/removeBannerApi', request);
}

type RemoveBannerApiRequest = {
    restaurantId: RestaurantId;
    bannerId: BannerId;
};
