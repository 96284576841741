import { pidedirectouploadApi } from 'src/api/pidedirectouploadApi';
import type { ImageType } from 'src/constants/ImageType';
import { isExternalImageUrl } from 'src/utils/image/isExternalImageUrl';

export async function migrateImage(params: MigrateImageParams): Promise<MigrateImageResponse> {
    try {
        if (!isExternalImageUrl(params.imageUrl))
            return {
                imageUrl: params.imageUrl,
                error: false,
            };

        const migrateResponse = await pidedirectouploadApi.images.migrate({
            imageUrl: params.imageUrl,
            imageType: params.imageType,
        });
        if (!migrateResponse.ok) {
            return {
                migrateResponse: migrateResponse,
                error: true,
            };
        }
        return {
            imageUrl: migrateResponse.data,
            error: false,
        };
    } catch (e: any) {
        return {
            migrateResponse: e,
            error: true,
        };
    }
}

type MigrateImageParams = {
    imageUrl: string;
    imageType: ImageType;
};

type MigrateImageResponse = {
    imageUrl?: string;
    migrateResponse?: string;
    error: boolean;
};
