import { PaymentStatus, PaymentStatuses } from 'src/constants/PaymentStatus';
import { translate } from 'src/i18n/translate';

/**
 * @deprecated
 * TODO: This is a general translation, move translations of constant values to en.js and es.js instead and calls with translate(...)
 **/
export function translatePaymentStatus(paymentStatus: PaymentStatus): string {
    if (!paymentStatus) {
        return '';
    }
    switch (paymentStatus) {
        case PaymentStatuses.UNPAID: {
            return translate('Unpaid');
        }
        case PaymentStatuses.PAID: {
            return translate('Paid');
        }
        case PaymentStatuses.PAYED: {
            return translate('Paid');
        }
        case PaymentStatuses.REFUNDED: {
            return translate('Refunded');
        }
        case PaymentStatuses.FAILED: {
            return translate('Failed');
        }
        default: {
            return translate('');
        }
    }
}
