import * as React from 'react';

export function RouteIcon({ title, color }: Props): React.ReactElement {
    return (
        <svg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'>
            {title && <title>{title}</title>}
            <path
                d='M16.25 10C16.25 10 20 6.25 20 3.75C20 1.67969 18.3203 0 16.25 0C14.1797 0 12.5 1.67969 12.5 3.75C12.5 4.89844 13.2891 6.30859 14.1445 7.5H12.5C10.4297 7.5 8.75 9.17969 8.75 11.25C8.75 13.3203 10.4297 15 12.5 15H16.25C16.9414 15 17.5 15.5586 17.5 16.25C17.5 16.9414 16.9414 17.5 16.25 17.5H7.36719C7.125 17.875 6.875 18.2344 6.625 18.5625C6.20703 19.1172 5.79297 19.6133 5.45313 20H16.25C18.3203 20 20 18.3203 20 16.25C20 14.1797 18.3203 12.5 16.25 12.5H12.5C11.8086 12.5 11.25 11.9414 11.25 11.25C11.25 10.5586 11.8086 10 12.5 10H16.25ZM16.25 5C15.5586 5 15 4.44141 15 3.75C15 3.05859 15.5586 2.5 16.25 2.5C16.9414 2.5 17.5 3.05859 17.5 3.75C17.5 4.44141 16.9414 5 16.25 5ZM5.85547 17.5C6.71094 16.3086 7.5 14.8984 7.5 13.75C7.5 11.6797 5.82031 10 3.75 10C1.67969 10 0 11.6797 0 13.75C0 16.25 3.75 20 3.75 20C3.75 20 3.88672 19.8633 4.10937 19.625C4.125 19.6094 4.13672 19.5938 4.15234 19.5781C4.28125 19.4414 4.42969 19.2734 4.59766 19.0781C4.60547 19.0703 4.61328 19.0625 4.62109 19.0547C4.98828 18.6328 5.42969 18.0937 5.85547 17.5V17.5ZM3.75 15C3.05859 15 2.5 14.4414 2.5 13.75C2.5 13.0586 3.05859 12.5 3.75 12.5C4.44141 12.5 5 13.0586 5 13.75C5 14.4414 4.44141 15 3.75 15Z'
                fill={color ?? 'currentColor'}
            />
        </svg>
    );
}

type Props = {
    title?: string;
    color?: string;
};
