import { Paper } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import { EditIcon } from '@pidedirecto/ui/icons';
import { formatAsCurrencyNumber } from '@pidedirecto/ui/utils';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { changeSettingsApi, ZoneSettings } from 'src/api/letseatadmin/settings/changeSettingsApi';
import { getSettingsApi } from 'src/api/letseatadmin/settings/getSettingsApi';
import { app2 } from 'src/app2';
import { Button } from 'src/components/Button';
import { DeprecatedFormPercentNumberField } from 'src/components/form/DeprecatedFormPercentNumberField';
import { Form } from 'src/components/form/Form';
import { FormAutocompleteMultipleString } from 'src/components/form/FormAutocompleteMultipleString';
import { FormCitiesAutocomplete } from 'src/components/form/FormCitiesAutocomplete';
import { FormCurrencyNumberField } from 'src/components/form/FormCurrencyNumberField';
import { FormCurrencyNumberStringField } from 'src/components/form/FormCurrencyNumberStringField';
import { FormEmailTemplateAutocomplete } from 'src/components/form/FormEmailTemplateAutocomplete';
import { FormIntegerField } from 'src/components/form/FormIntegerField';
import { FormMultipleEmailsInput } from 'src/components/form/FormMultipleEmailsInput';
import { FormNumberField } from 'src/components/form/FormNumberField';
import { FormPspsAutocomplete } from 'src/components/form/FormPspsAutocomplete';
import { FormSmsServiceProviderSelect } from 'src/components/form/FormSmsServiceProviderSelect';
import { FormSubheading } from 'src/components/form/FormSubheading';
import { FormSubmitButton } from 'src/components/form/FormSubmitButton';
import { FormSwitch } from 'src/components/form/FormSwitch';
import { FormTextField } from 'src/components/form/FormTextField';
import { PasswordRequiredContent } from 'src/components/PasswordRequiredContent';
import { UpdatingContentProgress } from 'src/components/UpdatingContentProgress';
import { Cities, City } from 'src/constants/City';
import { CountryCodes } from 'src/constants/CountryCode';
import { SmsPrios } from 'src/constants/SmsPrio';
import { translate } from 'src/i18n/translate';
import { DeliveriesForBonusCommissionSettings } from 'src/scenes/letseatadmin/settings/DeliveriesForBonusCommissionSettings';
import { DeliveryRadiusInKmSettings } from 'src/scenes/letseatadmin/settings/DeliveryRadiusInKmSettings';
import { DynamicDeliveryEarningsToNewRestaurantSettings } from 'src/scenes/letseatadmin/settings/DynamicDeliveryEarningsToNewRestaurantSettings';
import { LookForDriversWithingRadiusSettings } from 'src/scenes/letseatadmin/settings/LookForDriversWithingRadiusSettings';
import { LookForDriversWithinRadiusForNearbyPendingDeliverySettings } from 'src/scenes/letseatadmin/settings/LookForDriversWithinRadiusForNearbyPendingDeliverySettings';
import { alertKnownErrorOrSomethingWentWrong } from 'src/utils/alert/alertKnownErrorOrSomethingWentWrong';
import { isNumber } from 'src/utils/number/isNumber';
import { withoutIva } from 'src/utils/number/withoutIva';
import { useAction } from 'src/utils/react/useAction';
import { useSelector } from 'src/utils/react/useSelector';

export function SettingsPage(): React.ReactElement {
    const form = useForm();
    const { watch } = form;

    const zoneSettings = watch(`zoneSettings`);
    const customerDeliveryCostFixed = watch(`customerDeliveryCostFixed`);
    const customerDeliveryCostPerKm = watch(`customerDeliveryCostPerKm`);
    const dynamicEarningsMinimumMinutes = watch(`dynamicEarningsMinimumMinutes`);
    const dynamicEarningsAmountToIncrementByMinute = watch(`dynamicEarningsAmountToIncrementByMinute`);
    const dynamicEarningsRoof = watch(`dynamicEarningsRoof`);
    const payPerUseRange1OrdersLimit = watch('payPerUseRange1OrdersLimit');
    const payPerUseRange1PaymentAmount = watch('payPerUseRange1PaymentAmount');
    const payPerUseRange2OrdersLimit = watch('payPerUseRange2OrdersLimit');
    const payPerUseRange2PaymentAmount = watch('payPerUseRange2PaymentAmount');
    const payPerUseRangeMaxOrdersLimit = watch('payPerUseRangeMaxOrdersLimit');
    const payPerUseRangeMaxPaymentAmount = watch('payPerUseRangeMaxPaymentAmount');

    const [loading, setLoading] = useState(false);
    const [appUpdateControlsEnabled, setAppUpdateControlsEnabled] = useState(true);
    const [canUpdateSubscriptionPaymentClabe, setCanUpdateSubscriptionPaymentClabe] = useState(false);
    const [canUpdateSubscriptionPaymentClabeToElevate, setCanUpdateSubscriptionPaymentClabeToElevate] = useState(false);

    const cities = useSelector((state) => state.app2.cities);

    const setTitle = useAction(app2.actions.setTitle);

    const gdlIsSelected = cities?.some((city: City) => city === Cities.GUADALAJARA);
    const merIsSelected = cities?.some((city: City) => city === Cities.MERIDA);
    const cdmxIsSelected = cities?.some((city: City) => city === Cities.MEXICO_CITY);
    const mtyIsSelected = cities?.some((city: City) => city === Cities.MONTERREY);
    const pueIsSelected = cities?.some((city: City) => city === Cities.PUEBLA);
    const qroIsSelected = cities?.some((city: City) => city === Cities.QUERETARO);
    const salIsSelected = cities?.some((city: City) => city === Cities.SALTILLO);
    const trcIsSelected = cities?.some((city: City) => city === Cities.TORREON);

    useEffect(() => {
        setTitle('Settings');
    }, []);

    const load = async () => {
        setLoading(true);
        const response = await getSettingsApi();
        setLoading(false);
        if (!response.ok) {
            alertKnownErrorOrSomethingWentWrong(response);
            return;
        }
        const settings = response.data;
        form.reset(settings);
    };

    const onSubmit = async (form: any) => {
        const response = await changeSettingsApi({
            managerMessageStyle: form.managerMessageStyle,
            managerMessageTitle: form.managerMessageTitle,
            managerMessageBody: form.managerMessageBody,
            managerMessageUrl: form.managerMessageUrl,
            managerMessageStyleMty: form.managerMessageStyleMty,
            managerMessageTitleMty: form.managerMessageTitleMty,
            managerMessageBodyMty: form.managerMessageBodyMty,
            managerMessageUrlMty: form.managerMessageUrlMty,
            managerMessageStyleGdl: form.managerMessageStyleGdl,
            managerMessageTitleGdl: form.managerMessageTitleGdl,
            managerMessageBodyGdl: form.managerMessageBodyGdl,
            managerMessageUrlGdl: form.managerMessageUrlGdl,
            managerMessageStyleCdMx: form.managerMessageStyleCdMx,
            managerMessageTitleCdMx: form.managerMessageTitleCdMx,
            managerMessageBodyCdMx: form.managerMessageBodyCdMx,
            managerMessageUrlCdMx: form.managerMessageUrlCdMx,
            managerMessageStyleSal: form.managerMessageStyleSal,
            managerMessageTitleSal: form.managerMessageTitleSal,
            managerMessageBodySal: form.managerMessageBodySal,
            managerMessageUrlSal: form.managerMessageUrlSal,
            managerMessageStyleTrc: form.managerMessageStyleTrc,
            managerMessageTitleTrc: form.managerMessageTitleTrc,
            managerMessageBodyTrc: form.managerMessageBodyTrc,
            managerMessageUrlTrc: form.managerMessageUrlTrc,
            managerMessageStyleQro: form.managerMessageStyleQro,
            managerMessageTitleQro: form.managerMessageTitleQro,
            managerMessageBodyQro: form.managerMessageBodyQro,
            managerMessageUrlQro: form.managerMessageUrlQro,
            managerMessageStyleMer: form.managerMessageStyleMer,
            managerMessageTitleMer: form.managerMessageTitleMer,
            managerMessageBodyMer: form.managerMessageBodyMer,
            managerMessageUrlMer: form.managerMessageUrlMer,
            managerMessageStylePue: form.managerMessageStylePue,
            managerMessageTitlePue: form.managerMessageTitlePue,
            managerMessageBodyPue: form.managerMessageBodyPue,
            managerMessageUrlPue: form.managerMessageUrlPue,
            marketplaceServiceFeeRate: form.marketplaceServiceFeeRate,
            addMarketplaceServiceFeeToMenuItemPrice: form.addMarketplaceServiceFeeToMenuItemPrice,
            deliveryRadiusInKm: form.deliveryRadiusInKm,
            deliveryRadiusInKmAcapulco: form.deliveryRadiusInKmAcapulco,
            deliveryRadiusInKmAcuna: form.deliveryRadiusInKmAcuna,
            deliveryRadiusInKmAguascalientes: form.deliveryRadiusInKmAguascalientes,
            deliveryRadiusInKmAltamira: form.deliveryRadiusInKmAltamira,
            deliveryRadiusInKmAtlacomulco: form.deliveryRadiusInKmAtlacomulco,
            deliveryRadiusInKmAtlixco: form.deliveryRadiusInKmAtlixco,
            deliveryRadiusInKmBogota: form.deliveryRadiusInKmBogota,
            deliveryRadiusInKmCadereytaDeJimenez: form.deliveryRadiusInKmCadereytaDeJimenez,
            deliveryRadiusInKmCampeche: form.deliveryRadiusInKmCampeche,
            deliveryRadiusInKmCancun: form.deliveryRadiusInKmCancun,
            deliveryRadiusInKmCiudadVictoria: form.deliveryRadiusInKmCiudadVictoria,
            deliveryRadiusInKmCelaya: form.deliveryRadiusInKmCelaya,
            deliveryRadiusInKmChetumal: form.deliveryRadiusInKmChetumal,
            deliveryRadiusInKmChihuahua: form.deliveryRadiusInKmChihuahua,
            deliveryRadiusInKmChilpancingoDeLosBravo: form.deliveryRadiusInKmChilpancingoDeLosBravo,
            deliveryRadiusInKmChimalhuacan: form.deliveryRadiusInKmChimalhuacan,
            deliveryRadiusInKmCiudadDelCarmen: form.deliveryRadiusInKmCiudadDelCarmen,
            deliveryRadiusInKmCiudadJuarez: form.deliveryRadiusInKmCiudadJuarez,
            deliveryRadiusInKmCiudadObregon: form.deliveryRadiusInKmCiudadObregon,
            deliveryRadiusInKmCoatzacoalcos: form.deliveryRadiusInKmCoatzacoalcos,
            deliveryRadiusInKmColima: form.deliveryRadiusInKmColima,
            deliveryRadiusInKmComitanDeDominguez: form.deliveryRadiusInKmComitanDeDominguez,
            deliveryRadiusInKmCordoba: form.deliveryRadiusInKmCordoba,
            deliveryRadiusInKmCozumel: form.deliveryRadiusInKmCozumel,
            deliveryRadiusInKmCuauhtemoc: form.deliveryRadiusInKmCuauhtemoc,
            deliveryRadiusInKmCuautla: form.deliveryRadiusInKmCuautla,
            deliveryRadiusInKmCuernavaca: form.deliveryRadiusInKmCuernavaca,
            deliveryRadiusInKmCuliacan: form.deliveryRadiusInKmCuliacan,
            deliveryRadiusInKmDelicias: form.deliveryRadiusInKmDelicias,
            deliveryRadiusInKmDoloresHidalgo: form.deliveryRadiusInKmDoloresHidalgo,
            deliveryRadiusInKmDurango: form.deliveryRadiusInKmDurango,
            deliveryRadiusInKmEnsenada: form.deliveryRadiusInKmEnsenada,
            deliveryRadiusInKmFresnillo: form.deliveryRadiusInKmFresnillo,
            deliveryRadiusInKmGarcia: form.deliveryRadiusInKmGarcia,
            deliveryRadiusInKmGdl: form.deliveryRadiusInKmGdl,
            deliveryRadiusInKmGuanajuato: form.deliveryRadiusInKmGuanajuato,
            deliveryRadiusInKmGuasave: form.deliveryRadiusInKmGuasave,
            deliveryRadiusInKmGuaymas: form.deliveryRadiusInKmGuaymas,
            deliveryRadiusInKmHermosillo: form.deliveryRadiusInKmHermosillo,
            deliveryRadiusInKmIrapuato: form.deliveryRadiusInKmIrapuato,
            deliveryRadiusInKmLaPaz: form.deliveryRadiusInKmLaPaz,
            deliveryRadiusInKmLaPiedad: form.deliveryRadiusInKmLaPiedad,
            deliveryRadiusInKmLazaroCardenas: form.deliveryRadiusInKmLazaroCardenas,
            deliveryRadiusInKmLeon: form.deliveryRadiusInKmLeon,
            deliveryRadiusInKmLosCabos: form.deliveryRadiusInKmLosCabos,
            deliveryRadiusInKmLosMochis: form.deliveryRadiusInKmLosMochis,
            deliveryRadiusInKmManzanillo: form.deliveryRadiusInKmManzanillo,
            deliveryRadiusInKmMatamoros: form.deliveryRadiusInKmMatamoros,
            deliveryRadiusInKmMazatlan: form.deliveryRadiusInKmMazatlan,
            deliveryRadiusInKmMer: form.deliveryRadiusInKmMer,
            deliveryRadiusInKmMexicali: form.deliveryRadiusInKmMexicali,
            deliveryRadiusInKmCdMx: form.deliveryRadiusInKmCdMx,
            deliveryRadiusInKmMinatitlan: form.deliveryRadiusInKmMinatitlan,
            deliveryRadiusInKmMonclova: form.deliveryRadiusInKmMonclova,
            deliveryRadiusInKmMty: form.deliveryRadiusInKmMty,
            deliveryRadiusInKmMorelia: form.deliveryRadiusInKmMorelia,
            deliveryRadiusInKmNavojoa: form.deliveryRadiusInKmNavojoa,
            deliveryRadiusInKmNogales: form.deliveryRadiusInKmNogales,
            deliveryRadiusInKmNuevoLaredo: form.deliveryRadiusInKmNuevoLaredo,
            deliveryRadiusInKmOaxaca: form.deliveryRadiusInKmOaxaca,
            deliveryRadiusInKmPachuca: form.deliveryRadiusInKmPachuca,
            deliveryRadiusInKmPiedrasNegras: form.deliveryRadiusInKmPiedrasNegras,
            deliveryRadiusInKmPlayaDelCarmen: form.deliveryRadiusInKmPlayaDelCarmen,
            deliveryRadiusInKmPozaRica: form.deliveryRadiusInKmPozaRica,
            deliveryRadiusInKmProgreso: form.deliveryRadiusInKmProgreso,
            deliveryRadiusInKmPue: form.deliveryRadiusInKmPue,
            deliveryRadiusInKmPuertoVallarta: form.deliveryRadiusInKmPuertoVallarta,
            deliveryRadiusInKmQro: form.deliveryRadiusInKmQro,
            deliveryRadiusInKmReynosa: form.deliveryRadiusInKmReynosa,
            deliveryRadiusInKmRosarito: form.deliveryRadiusInKmRosarito,
            deliveryRadiusInKmSalamanca: form.deliveryRadiusInKmSalamanca,
            deliveryRadiusInKmSal: form.deliveryRadiusInKmSal,
            deliveryRadiusInKmSanCristobalDeLasCasas: form.deliveryRadiusInKmSanCristobalDeLasCasas,
            deliveryRadiusInKmSanJose: form.deliveryRadiusInKmSanJose,
            deliveryRadiusInKmSanJoseDelCabo: form.deliveryRadiusInKmSanJoseDelCabo,
            deliveryRadiusInKmSanJuanDelRio: form.deliveryRadiusInKmSanJuanDelRio,
            deliveryRadiusInKmSanLuisPotosi: form.deliveryRadiusInKmSanLuisPotosi,
            deliveryRadiusInKmSanLuisRioColorado: form.deliveryRadiusInKmSanLuisRioColorado,
            deliveryRadiusInKmSanMiguelDeAllende: form.deliveryRadiusInKmSanMiguelDeAllende,
            deliveryRadiusInKmSantiago: form.deliveryRadiusInKmSantiago,
            deliveryRadiusInKmSilao: form.deliveryRadiusInKmSilao,
            deliveryRadiusInKmSth: form.deliveryRadiusInKmSth,
            deliveryRadiusInKmTampico: form.deliveryRadiusInKmTampico,
            deliveryRadiusInKmTapachula: form.deliveryRadiusInKmTapachula,
            deliveryRadiusInKmTehuacan: form.deliveryRadiusInKmTehuacan,
            deliveryRadiusInKmTepic: form.deliveryRadiusInKmTepic,
            deliveryRadiusInKmTexcoco: form.deliveryRadiusInKmTexcoco,
            deliveryRadiusInKmTeziutlan: form.deliveryRadiusInKmTeziutlan,
            deliveryRadiusInKmTijuana: form.deliveryRadiusInKmTijuana,
            deliveryRadiusInKmTlaxcala: form.deliveryRadiusInKmTlaxcala,
            deliveryRadiusInKmToluca: form.deliveryRadiusInKmToluca,
            deliveryRadiusInKmTrc: form.deliveryRadiusInKmTrc,
            deliveryRadiusInKmTuxtlaGutierrez: form.deliveryRadiusInKmTuxtlaGutierrez,
            deliveryRadiusInKmUruapan: form.deliveryRadiusInKmUruapan,
            deliveryRadiusInKmValleDeBravo: form.deliveryRadiusInKmValleDeBravo,
            deliveryRadiusInKmValleDeChalco: form.deliveryRadiusInKmValleDeChalco,
            deliveryRadiusInKmValleDeSantiago: form.deliveryRadiusInKmValleDeSantiago,
            deliveryRadiusInKmVeracruz: form.deliveryRadiusInKmVeracruz,
            deliveryRadiusInKmVillahermosa: form.deliveryRadiusInKmVillahermosa,
            deliveryRadiusInKmXalapa: form.deliveryRadiusInKmXalapa,
            deliveryRadiusInKmZacatecas: form.deliveryRadiusInKmZacatecas,
            deliveryRadiusInKmZamora: form.deliveryRadiusInKmZamora,
            deliveriesForBonusCommission: form.deliveriesForBonusCommission,
            deliveriesForBonusCommissionAcapulco: form.deliveriesForBonusCommissionAcapulco,
            deliveriesForBonusCommissionAcuna: form.deliveriesForBonusCommissionAcuna,
            deliveriesForBonusCommissionAguascalientes: form.deliveriesForBonusCommissionAguascalientes,
            deliveriesForBonusCommissionAltamira: form.deliveriesForBonusCommissionAltamira,
            deliveriesForBonusCommissionAtlacomulco: form.deliveriesForBonusCommissionAtlacomulco,
            deliveriesForBonusCommissionAtlixco: form.deliveriesForBonusCommissionAtlixco,
            deliveriesForBonusCommissionBogota: form.deliveriesForBonusCommissionBogota,
            deliveriesForBonusCommissionCadereytaDeJimenez: form.deliveriesForBonusCommissionCadereytaDeJimenez,
            deliveriesForBonusCommissionCampeche: form.deliveriesForBonusCommissionCampeche,
            deliveriesForBonusCommissionCancun: form.deliveriesForBonusCommissionCancun,
            deliveriesForBonusCommissionCiudadVictoria: form.deliveriesForBonusCommissionCiudadVictoria,
            deliveriesForBonusCommissionCelaya: form.deliveriesForBonusCommissionCelaya,
            deliveriesForBonusCommissionChetumal: form.deliveriesForBonusCommissionChetumal,
            deliveriesForBonusCommissionChihuahua: form.deliveriesForBonusCommissionChihuahua,
            deliveriesForBonusCommissionChilpancingoDeLosBravo: form.deliveriesForBonusCommissionChilpancingoDeLosBravo,
            deliveriesForBonusCommissionChimalhuacan: form.deliveriesForBonusCommissionChimalhuacan,
            deliveriesForBonusCommissionCiudadDelCarmen: form.deliveriesForBonusCommissionCiudadDelCarmen,
            deliveriesForBonusCommissionCiudadJuarez: form.deliveriesForBonusCommissionCiudadJuarez,
            deliveriesForBonusCommissionCiudadObregon: form.deliveriesForBonusCommissionCiudadObregon,
            deliveriesForBonusCommissionCoatzacoalcos: form.deliveriesForBonusCommissionCoatzacoalcos,
            deliveriesForBonusCommissionColima: form.deliveriesForBonusCommissionColima,
            deliveriesForBonusCommissionComitanDeDominguez: form.deliveriesForBonusCommissionComitanDeDominguez,
            deliveriesForBonusCommissionCordoba: form.deliveriesForBonusCommissionCordoba,
            deliveriesForBonusCommissionCozumel: form.deliveriesForBonusCommissionCozumel,
            deliveriesForBonusCommissionCuauhtemoc: form.deliveriesForBonusCommissionCuauhtemoc,
            deliveriesForBonusCommissionCuautla: form.deliveriesForBonusCommissionCuautla,
            deliveriesForBonusCommissionCuernavaca: form.deliveriesForBonusCommissionCuernavaca,
            deliveriesForBonusCommissionCuliacan: form.deliveriesForBonusCommissionCuliacan,
            deliveriesForBonusCommissionDelicias: form.deliveriesForBonusCommissionDelicias,
            deliveriesForBonusCommissionDoloresHidalgo: form.deliveriesForBonusCommissionDoloresHidalgo,
            deliveriesForBonusCommissionDurango: form.deliveriesForBonusCommissionDurango,
            deliveriesForBonusCommissionEnsenada: form.deliveriesForBonusCommissionEnsenada,
            deliveriesForBonusCommissionFresnillo: form.deliveriesForBonusCommissionFresnillo,
            deliveriesForBonusCommissionGarcia: form.deliveriesForBonusCommissionGarcia,
            deliveriesForBonusCommissionGdl: form.deliveriesForBonusCommissionGdl,
            deliveriesForBonusCommissionGuanajuato: form.deliveriesForBonusCommissionGuanajuato,
            deliveriesForBonusCommissionGuasave: form.deliveriesForBonusCommissionGuasave,
            deliveriesForBonusCommissionGuaymas: form.deliveriesForBonusCommissionGuaymas,
            deliveriesForBonusCommissionHermosillo: form.deliveriesForBonusCommissionHermosillo,
            deliveriesForBonusCommissionIrapuato: form.deliveriesForBonusCommissionIrapuato,
            deliveriesForBonusCommissionLaPaz: form.deliveriesForBonusCommissionLaPaz,
            deliveriesForBonusCommissionLaPiedad: form.deliveriesForBonusCommissionLaPiedad,
            deliveriesForBonusCommissionLazaroCardenas: form.deliveriesForBonusCommissionLazaroCardenas,
            deliveriesForBonusCommissionLeon: form.deliveriesForBonusCommissionLeon,
            deliveriesForBonusCommissionLosCabos: form.deliveriesForBonusCommissionLosCabos,
            deliveriesForBonusCommissionLosMochis: form.deliveriesForBonusCommissionLosMochis,
            deliveriesForBonusCommissionManzanillo: form.deliveriesForBonusCommissionManzanillo,
            deliveriesForBonusCommissionMatamoros: form.deliveriesForBonusCommissionMatamoros,
            deliveriesForBonusCommissionMazatlan: form.deliveriesForBonusCommissionMazatlan,
            deliveriesForBonusCommissionMer: form.deliveriesForBonusCommissionMer,
            deliveriesForBonusCommissionMexicali: form.deliveriesForBonusCommissionMexicali,
            deliveriesForBonusCommissionCdMx: form.deliveriesForBonusCommissionCdMx,
            deliveriesForBonusCommissionMinatitlan: form.deliveriesForBonusCommissionMinatitlan,
            deliveriesForBonusCommissionMonclova: form.deliveriesForBonusCommissionMonclova,
            deliveriesForBonusCommissionMty: form.deliveriesForBonusCommissionMty,
            deliveriesForBonusCommissionMorelia: form.deliveriesForBonusCommissionMorelia,
            deliveriesForBonusCommissionNavojoa: form.deliveriesForBonusCommissionNavojoa,
            deliveriesForBonusCommissionNogales: form.deliveriesForBonusCommissionNogales,
            deliveriesForBonusCommissionNuevoLaredo: form.deliveriesForBonusCommissionNuevoLaredo,
            deliveriesForBonusCommissionOaxaca: form.deliveriesForBonusCommissionOaxaca,
            deliveriesForBonusCommissionPachuca: form.deliveriesForBonusCommissionPachuca,
            deliveriesForBonusCommissionPiedrasNegras: form.deliveriesForBonusCommissionPiedrasNegras,
            deliveriesForBonusCommissionPlayaDelCarmen: form.deliveriesForBonusCommissionPlayaDelCarmen,
            deliveriesForBonusCommissionPozaRica: form.deliveriesForBonusCommissionPozaRica,
            deliveriesForBonusCommissionProgreso: form.deliveriesForBonusCommissionProgreso,
            deliveriesForBonusCommissionPue: form.deliveriesForBonusCommissionPue,
            deliveriesForBonusCommissionPuertoVallarta: form.deliveriesForBonusCommissionPuertoVallarta,
            deliveriesForBonusCommissionQro: form.deliveriesForBonusCommissionQro,
            deliveriesForBonusCommissionReynosa: form.deliveriesForBonusCommissionReynosa,
            deliveriesForBonusCommissionRosarito: form.deliveriesForBonusCommissionRosarito,
            deliveriesForBonusCommissionSalamanca: form.deliveriesForBonusCommissionSalamanca,
            deliveriesForBonusCommissionSal: form.deliveriesForBonusCommissionSal,
            deliveriesForBonusCommissionSanCristobalDeLasCasas: form.deliveriesForBonusCommissionSanCristobalDeLasCasas,
            deliveriesForBonusCommissionSanJose: form.deliveriesForBonusCommissionSanJose,
            deliveriesForBonusCommissionSanJoseDelCabo: form.deliveriesForBonusCommissionSanJoseDelCabo,
            deliveriesForBonusCommissionSanJuanDelRio: form.deliveriesForBonusCommissionSanJuanDelRio,
            deliveriesForBonusCommissionSanLuisPotosi: form.deliveriesForBonusCommissionSanLuisPotosi,
            deliveriesForBonusCommissionSanLuisRioColorado: form.deliveriesForBonusCommissionSanLuisRioColorado,
            deliveriesForBonusCommissionSanMiguelDeAllende: form.deliveriesForBonusCommissionSanMiguelDeAllende,
            deliveriesForBonusCommissionSantiago: form.deliveriesForBonusCommissionSantiago,
            deliveriesForBonusCommissionSilao: form.deliveriesForBonusCommissionSilao,
            deliveriesForBonusCommissionSth: form.deliveriesForBonusCommissionSth,
            deliveriesForBonusCommissionTampico: form.deliveriesForBonusCommissionTampico,
            deliveriesForBonusCommissionTapachula: form.deliveriesForBonusCommissionTapachula,
            deliveriesForBonusCommissionTehuacan: form.deliveriesForBonusCommissionTehuacan,
            deliveriesForBonusCommissionTepic: form.deliveriesForBonusCommissionTepic,
            deliveriesForBonusCommissionTexcoco: form.deliveriesForBonusCommissionTexcoco,
            deliveriesForBonusCommissionTeziutlan: form.deliveriesForBonusCommissionTeziutlan,
            deliveriesForBonusCommissionTijuana: form.deliveriesForBonusCommissionTijuana,
            deliveriesForBonusCommissionTlaxcala: form.deliveriesForBonusCommissionTlaxcala,
            deliveriesForBonusCommissionToluca: form.deliveriesForBonusCommissionToluca,
            deliveriesForBonusCommissionTrc: form.deliveriesForBonusCommissionTrc,
            deliveriesForBonusCommissionTuxtlaGutierrez: form.deliveriesForBonusCommissionTuxtlaGutierrez,
            deliveriesForBonusCommissionUruapan: form.deliveriesForBonusCommissionUruapan,
            deliveriesForBonusCommissionValleDeBravo: form.deliveriesForBonusCommissionValleDeBravo,
            deliveriesForBonusCommissionValleDeChalco: form.deliveriesForBonusCommissionValleDeChalco,
            deliveriesForBonusCommissionValleDeSantiago: form.deliveriesForBonusCommissionValleDeSantiago,
            deliveriesForBonusCommissionVeracruz: form.deliveriesForBonusCommissionVeracruz,
            deliveriesForBonusCommissionVillahermosa: form.deliveriesForBonusCommissionVillahermosa,
            deliveriesForBonusCommissionXalapa: form.deliveriesForBonusCommissionXalapa,
            deliveriesForBonusCommissionZacatecas: form.deliveriesForBonusCommissionZacatecas,
            deliveriesForBonusCommissionZamora: form.deliveriesForBonusCommissionZamora,
            deliveryTimeFixed: form.deliveryTimeFixed,
            deliveryTimePerKm: form.deliveryTimePerKm,
            customerDeliveryCostFixed: form.customerDeliveryCostFixed,
            customerDeliveryCostPerKm: form.customerDeliveryCostPerKm,
            dynamicEarningsMinimumMinutes: form.dynamicEarningsMinimumMinutes,
            dynamicEarningsAmountToIncrementByMinute: form.dynamicEarningsAmountToIncrementByMinute,
            dynamicEarningsRoof: form.dynamicEarningsRoof,
            deliveryOrchestratorFee: form.deliveryOrchestratorFee,
            zoneSettings: removeEmptyZones(form.zoneSettings),
            longDistanceExtraDeliveryCostStartsFromKm: form.longDistanceExtraDeliveryCostStartsFromKm,
            longDistanceExtraDeliveryCostFixed: form.longDistanceExtraDeliveryCostFixed,
            longDistanceExtraDeliveryCostPerKm: form.longDistanceExtraDeliveryCostPerKm,
            lookForDriversWithinRadiusForNearbyPendingDelivery: form.lookForDriversWithinRadiusForNearbyPendingDelivery,
            lookForDriversWithinRadiusForNearbyPendingDeliveryAcapulco: form.lookForDriversWithinRadiusForNearbyPendingDeliveryAcapulco,
            lookForDriversWithinRadiusForNearbyPendingDeliveryAcuna: form.lookForDriversWithinRadiusForNearbyPendingDeliveryAcuna,
            lookForDriversWithinRadiusForNearbyPendingDeliveryAguascalientes: form.lookForDriversWithinRadiusForNearbyPendingDeliveryAguascalientes,
            lookForDriversWithinRadiusForNearbyPendingDeliveryAltamira: form.lookForDriversWithinRadiusForNearbyPendingDeliveryAltamira,
            lookForDriversWithinRadiusForNearbyPendingDeliveryAtlacomulco: form.lookForDriversWithinRadiusForNearbyPendingDeliveryAtlacomulco,
            lookForDriversWithinRadiusForNearbyPendingDeliveryAtlixco: form.lookForDriversWithinRadiusForNearbyPendingDeliveryAtlixco,
            lookForDriversWithinRadiusForNearbyPendingDeliveryBogota: form.lookForDriversWithinRadiusForNearbyPendingDeliveryBogota,
            lookForDriversWithinRadiusForNearbyPendingDeliveryCadereytaDeJimenez: form.lookForDriversWithinRadiusForNearbyPendingDeliveryCadereytaDeJimenez,
            lookForDriversWithinRadiusForNearbyPendingDeliveryCampeche: form.lookForDriversWithinRadiusForNearbyPendingDeliveryCampeche,
            lookForDriversWithinRadiusForNearbyPendingDeliveryCancun: form.lookForDriversWithinRadiusForNearbyPendingDeliveryCancun,
            lookForDriversWithinRadiusForNearbyPendingDeliveryCiudadVictoria: form.lookForDriversWithinRadiusForNearbyPendingDeliveryCiudadVictoria,
            lookForDriversWithinRadiusForNearbyPendingDeliveryCelaya: form.lookForDriversWithinRadiusForNearbyPendingDeliveryCelaya,
            lookForDriversWithinRadiusForNearbyPendingDeliveryChetumal: form.lookForDriversWithinRadiusForNearbyPendingDeliveryChetumal,
            lookForDriversWithinRadiusForNearbyPendingDeliveryChihuahua: form.lookForDriversWithinRadiusForNearbyPendingDeliveryChihuahua,
            lookForDriversWithinRadiusForNearbyPendingDeliveryChilpancingoDeLosBravo: form.lookForDriversWithinRadiusForNearbyPendingDeliveryChilpancingoDeLosBravo,
            lookForDriversWithinRadiusForNearbyPendingDeliveryChimalhuacan: form.lookForDriversWithinRadiusForNearbyPendingDeliveryChimalhuacan,
            lookForDriversWithinRadiusForNearbyPendingDeliveryCiudadDelCarmen: form.lookForDriversWithinRadiusForNearbyPendingDeliveryCiudadDelCarmen,
            lookForDriversWithinRadiusForNearbyPendingDeliveryCiudadJuarez: form.lookForDriversWithinRadiusForNearbyPendingDeliveryCiudadJuarez,
            lookForDriversWithinRadiusForNearbyPendingDeliveryCiudadObregon: form.lookForDriversWithinRadiusForNearbyPendingDeliveryCiudadObregon,
            lookForDriversWithinRadiusForNearbyPendingDeliveryCoatzacoalcos: form.lookForDriversWithinRadiusForNearbyPendingDeliveryCoatzacoalcos,
            lookForDriversWithinRadiusForNearbyPendingDeliveryColima: form.lookForDriversWithinRadiusForNearbyPendingDeliveryColima,
            lookForDriversWithinRadiusForNearbyPendingDeliveryComitanDeDominguez: form.lookForDriversWithinRadiusForNearbyPendingDeliveryComitanDeDominguez,
            lookForDriversWithinRadiusForNearbyPendingDeliveryCordoba: form.lookForDriversWithinRadiusForNearbyPendingDeliveryCordoba,
            lookForDriversWithinRadiusForNearbyPendingDeliveryCozumel: form.lookForDriversWithinRadiusForNearbyPendingDeliveryCozumel,
            lookForDriversWithinRadiusForNearbyPendingDeliveryCuauhtemoc: form.lookForDriversWithinRadiusForNearbyPendingDeliveryCuauhtemoc,
            lookForDriversWithinRadiusForNearbyPendingDeliveryCuautla: form.lookForDriversWithinRadiusForNearbyPendingDeliveryCuautla,
            lookForDriversWithinRadiusForNearbyPendingDeliveryCuernavaca: form.lookForDriversWithinRadiusForNearbyPendingDeliveryCuernavaca,
            lookForDriversWithinRadiusForNearbyPendingDeliveryCuliacan: form.lookForDriversWithinRadiusForNearbyPendingDeliveryCuliacan,
            lookForDriversWithinRadiusForNearbyPendingDeliveryDelicias: form.lookForDriversWithinRadiusForNearbyPendingDeliveryDelicias,
            lookForDriversWithinRadiusForNearbyPendingDeliveryDoloresHidalgo: form.lookForDriversWithinRadiusForNearbyPendingDeliveryDoloresHidalgo,
            lookForDriversWithinRadiusForNearbyPendingDeliveryDurango: form.lookForDriversWithinRadiusForNearbyPendingDeliveryDurango,
            lookForDriversWithinRadiusForNearbyPendingDeliveryEnsenada: form.lookForDriversWithinRadiusForNearbyPendingDeliveryEnsenada,
            lookForDriversWithinRadiusForNearbyPendingDeliveryFresnillo: form.lookForDriversWithinRadiusForNearbyPendingDeliveryFresnillo,
            lookForDriversWithinRadiusForNearbyPendingDeliveryGarcia: form.lookForDriversWithinRadiusForNearbyPendingDeliveryGarcia,
            lookForDriversWithinRadiusForNearbyPendingDeliveryGdl: form.lookForDriversWithinRadiusForNearbyPendingDeliveryGdl,
            lookForDriversWithinRadiusForNearbyPendingDeliveryGuanajuato: form.lookForDriversWithinRadiusForNearbyPendingDeliveryGuanajuato,
            lookForDriversWithinRadiusForNearbyPendingDeliveryGuasave: form.lookForDriversWithinRadiusForNearbyPendingDeliveryGuasave,
            lookForDriversWithinRadiusForNearbyPendingDeliveryGuaymas: form.lookForDriversWithinRadiusForNearbyPendingDeliveryGuaymas,
            lookForDriversWithinRadiusForNearbyPendingDeliveryHermosillo: form.lookForDriversWithinRadiusForNearbyPendingDeliveryHermosillo,
            lookForDriversWithinRadiusForNearbyPendingDeliveryIrapuato: form.lookForDriversWithinRadiusForNearbyPendingDeliveryIrapuato,
            lookForDriversWithinRadiusForNearbyPendingDeliveryLaPaz: form.lookForDriversWithinRadiusForNearbyPendingDeliveryLaPaz,
            lookForDriversWithinRadiusForNearbyPendingDeliveryLaPiedad: form.lookForDriversWithinRadiusForNearbyPendingDeliveryLaPiedad,
            lookForDriversWithinRadiusForNearbyPendingDeliveryLazaroCardenas: form.lookForDriversWithinRadiusForNearbyPendingDeliveryLazaroCardenas,
            lookForDriversWithinRadiusForNearbyPendingDeliveryLeon: form.lookForDriversWithinRadiusForNearbyPendingDeliveryLeon,
            lookForDriversWithinRadiusForNearbyPendingDeliveryLosCabos: form.lookForDriversWithinRadiusForNearbyPendingDeliveryLosCabos,
            lookForDriversWithinRadiusForNearbyPendingDeliveryLosMochis: form.lookForDriversWithinRadiusForNearbyPendingDeliveryLosMochis,
            lookForDriversWithinRadiusForNearbyPendingDeliveryManzanillo: form.lookForDriversWithinRadiusForNearbyPendingDeliveryManzanillo,
            lookForDriversWithinRadiusForNearbyPendingDeliveryMatamoros: form.lookForDriversWithinRadiusForNearbyPendingDeliveryMatamoros,
            lookForDriversWithinRadiusForNearbyPendingDeliveryMazatlan: form.lookForDriversWithinRadiusForNearbyPendingDeliveryMazatlan,
            lookForDriversWithinRadiusForNearbyPendingDeliveryMer: form.lookForDriversWithinRadiusForNearbyPendingDeliveryMer,
            lookForDriversWithinRadiusForNearbyPendingDeliveryMexicali: form.lookForDriversWithinRadiusForNearbyPendingDeliveryMexicali,
            lookForDriversWithinRadiusForNearbyPendingDeliveryCdMx: form.lookForDriversWithinRadiusForNearbyPendingDeliveryCdMx,
            lookForDriversWithinRadiusForNearbyPendingDeliveryMinatitlan: form.lookForDriversWithinRadiusForNearbyPendingDeliveryMinatitlan,
            lookForDriversWithinRadiusForNearbyPendingDeliveryMonclova: form.lookForDriversWithinRadiusForNearbyPendingDeliveryMonclova,
            lookForDriversWithinRadiusForNearbyPendingDeliveryMty: form.lookForDriversWithinRadiusForNearbyPendingDeliveryMty,
            lookForDriversWithinRadiusForNearbyPendingDeliveryMorelia: form.lookForDriversWithinRadiusForNearbyPendingDeliveryMorelia,
            lookForDriversWithinRadiusForNearbyPendingDeliveryNavojoa: form.lookForDriversWithinRadiusForNearbyPendingDeliveryNavojoa,
            lookForDriversWithinRadiusForNearbyPendingDeliveryNogales: form.lookForDriversWithinRadiusForNearbyPendingDeliveryNogales,
            lookForDriversWithinRadiusForNearbyPendingDeliveryNuevoLaredo: form.lookForDriversWithinRadiusForNearbyPendingDeliveryNuevoLaredo,
            lookForDriversWithinRadiusForNearbyPendingDeliveryOaxaca: form.lookForDriversWithinRadiusForNearbyPendingDeliveryOaxaca,
            lookForDriversWithinRadiusForNearbyPendingDeliveryPachuca: form.lookForDriversWithinRadiusForNearbyPendingDeliveryPachuca,
            lookForDriversWithinRadiusForNearbyPendingDeliveryPiedrasNegras: form.lookForDriversWithinRadiusForNearbyPendingDeliveryPiedrasNegras,
            lookForDriversWithinRadiusForNearbyPendingDeliveryPlayaDelCarmen: form.lookForDriversWithinRadiusForNearbyPendingDeliveryPlayaDelCarmen,
            lookForDriversWithinRadiusForNearbyPendingDeliveryPozaRica: form.lookForDriversWithinRadiusForNearbyPendingDeliveryPozaRica,
            lookForDriversWithinRadiusForNearbyPendingDeliveryProgreso: form.lookForDriversWithinRadiusForNearbyPendingDeliveryProgreso,
            lookForDriversWithinRadiusForNearbyPendingDeliveryPue: form.lookForDriversWithinRadiusForNearbyPendingDeliveryPue,
            lookForDriversWithinRadiusForNearbyPendingDeliveryPuertoVallarta: form.lookForDriversWithinRadiusForNearbyPendingDeliveryPuertoVallarta,
            lookForDriversWithinRadiusForNearbyPendingDeliveryQro: form.lookForDriversWithinRadiusForNearbyPendingDeliveryQro,
            lookForDriversWithinRadiusForNearbyPendingDeliveryReynosa: form.lookForDriversWithinRadiusForNearbyPendingDeliveryReynosa,
            lookForDriversWithinRadiusForNearbyPendingDeliveryRosarito: form.lookForDriversWithinRadiusForNearbyPendingDeliveryRosarito,
            lookForDriversWithinRadiusForNearbyPendingDeliverySalamanca: form.lookForDriversWithinRadiusForNearbyPendingDeliverySalamanca,
            lookForDriversWithinRadiusForNearbyPendingDeliverySal: form.lookForDriversWithinRadiusForNearbyPendingDeliverySal,
            lookForDriversWithinRadiusForNearbyPendingDeliverySanCristobalDeLasCasas: form.lookForDriversWithinRadiusForNearbyPendingDeliverySanCristobalDeLasCasas,
            lookForDriversWithinRadiusForNearbyPendingDeliverySanJose: form.lookForDriversWithinRadiusForNearbyPendingDeliverySanJose,
            lookForDriversWithinRadiusForNearbyPendingDeliverySanJoseDelCabo: form.lookForDriversWithinRadiusForNearbyPendingDeliverySanJoseDelCabo,
            lookForDriversWithinRadiusForNearbyPendingDeliverySanJuanDelRio: form.lookForDriversWithinRadiusForNearbyPendingDeliverySanJuanDelRio,
            lookForDriversWithinRadiusForNearbyPendingDeliverySanLuisPotosi: form.lookForDriversWithinRadiusForNearbyPendingDeliverySanLuisPotosi,
            lookForDriversWithinRadiusForNearbyPendingDeliverySanLuisRioColorado: form.lookForDriversWithinRadiusForNearbyPendingDeliverySanLuisRioColorado,
            lookForDriversWithinRadiusForNearbyPendingDeliverySanMiguelAllende: form.lookForDriversWithinRadiusForNearbyPendingDeliverySanMiguelAllende,
            lookForDriversWithinRadiusForNearbyPendingDeliverySantiago: form.lookForDriversWithinRadiusForNearbyPendingDeliverySantiago,
            lookForDriversWithinRadiusForNearbyPendingDeliverySilao: form.lookForDriversWithinRadiusForNearbyPendingDeliverySilao,
            lookForDriversWithinRadiusForNearbyPendingDeliverySth: form.lookForDriversWithinRadiusForNearbyPendingDeliverySth,
            lookForDriversWithinRadiusForNearbyPendingDeliveryTampico: form.lookForDriversWithinRadiusForNearbyPendingDeliveryTampico,
            lookForDriversWithinRadiusForNearbyPendingDeliveryTapachula: form.lookForDriversWithinRadiusForNearbyPendingDeliveryTapachula,
            lookForDriversWithinRadiusForNearbyPendingDeliveryTehuacan: form.lookForDriversWithinRadiusForNearbyPendingDeliveryTehuacan,
            lookForDriversWithinRadiusForNearbyPendingDeliveryTepic: form.lookForDriversWithinRadiusForNearbyPendingDeliveryTepic,
            lookForDriversWithinRadiusForNearbyPendingDeliveryTexcoco: form.lookForDriversWithinRadiusForNearbyPendingDeliveryTexcoco,
            lookForDriversWithinRadiusForNearbyPendingDeliveryTeziutlan: form.lookForDriversWithinRadiusForNearbyPendingDeliveryTeziutlan,
            lookForDriversWithinRadiusForNearbyPendingDeliveryTijuana: form.lookForDriversWithinRadiusForNearbyPendingDeliveryTijuana,
            lookForDriversWithinRadiusForNearbyPendingDeliveryTlaxcala: form.lookForDriversWithinRadiusForNearbyPendingDeliveryTlaxcala,
            lookForDriversWithinRadiusForNearbyPendingDeliveryToluca: form.lookForDriversWithinRadiusForNearbyPendingDeliveryToluca,
            lookForDriversWithinRadiusForNearbyPendingDeliveryTrc: form.lookForDriversWithinRadiusForNearbyPendingDeliveryTrc,
            lookForDriversWithinRadiusForNearbyPendingDeliveryTuxtlaGutierrez: form.lookForDriversWithinRadiusForNearbyPendingDeliveryTuxtlaGutierrez,
            lookForDriversWithinRadiusForNearbyPendingDeliveryUruapan: form.lookForDriversWithinRadiusForNearbyPendingDeliveryUruapan,
            lookForDriversWithinRadiusForNearbyPendingDeliveryValleDeBravo: form.lookForDriversWithinRadiusForNearbyPendingDeliveryValleDeBravo,
            lookForDriversWithinRadiusForNearbyPendingDeliveryValleDeChalco: form.lookForDriversWithinRadiusForNearbyPendingDeliveryValleDeChalco,
            lookForDriversWithinRadiusForNearbyPendingDeliveryValleDeSantiago: form.lookForDriversWithinRadiusForNearbyPendingDeliveryValleDeSantiago,
            lookForDriversWithinRadiusForNearbyPendingDeliveryVeracruz: form.lookForDriversWithinRadiusForNearbyPendingDeliveryVeracruz,
            lookForDriversWithinRadiusForNearbyPendingDeliveryVillahermosa: form.lookForDriversWithinRadiusForNearbyPendingDeliveryVillahermosa,
            lookForDriversWithinRadiusForNearbyPendingDeliveryXalapa: form.lookForDriversWithinRadiusForNearbyPendingDeliveryXalapa,
            lookForDriversWithinRadiusForNearbyPendingDeliveryZacatecas: form.lookForDriversWithinRadiusForNearbyPendingDeliveryZacatecas,
            lookForDriversWithinRadiusForNearbyPendingDeliveryZamora: form.lookForDriversWithinRadiusForNearbyPendingDeliveryZamora,
            lookForDriversWithingRadius: form.lookForDriversWithingRadius,
            lookForDriversWithingRadiusAcapulco: form.lookForDriversWithingRadiusAcapulco,
            lookForDriversWithingRadiusAcuna: form.lookForDriversWithingRadiusAcuna,
            lookForDriversWithingRadiusAguascalientes: form.lookForDriversWithingRadiusAguascalientes,
            lookForDriversWithingRadiusAltamira: form.lookForDriversWithingRadiusAltamira,
            lookForDriversWithingRadiusAtlacomulco: form.lookForDriversWithingRadiusAtlacomulco,
            lookForDriversWithingRadiusAtlixco: form.lookForDriversWithingRadiusAtlixco,
            lookForDriversWithingRadiusBogota: form.lookForDriversWithingRadiusBogota,
            lookForDriversWithingRadiusCadereytaDeJimenez: form.lookForDriversWithingRadiusCadereytaDeJimenez,
            lookForDriversWithingRadiusCampeche: form.lookForDriversWithingRadiusCampeche,
            lookForDriversWithingRadiusCancun: form.lookForDriversWithingRadiusCancun,
            lookForDriversWithingRadiusCiudadVictoria: form.lookForDriversWithingRadiusCiudadVictoria,
            lookForDriversWithingRadiusCelaya: form.lookForDriversWithingRadiusCelaya,
            lookForDriversWithingRadiusChetumal: form.lookForDriversWithingRadiusChetumal,
            lookForDriversWithingRadiusChihuahua: form.lookForDriversWithingRadiusChihuahua,
            lookForDriversWithingRadiusChilpancingoDeLosBravo: form.lookForDriversWithingRadiusChilpancingoDeLosBravo,
            lookForDriversWithingRadiusChimalhuacan: form.lookForDriversWithingRadiusChimalhuacan,
            lookForDriversWithingRadiusCiudadDelCarmen: form.lookForDriversWithingRadiusCiudadDelCarmen,
            lookForDriversWithingRadiusCiudadJuarez: form.lookForDriversWithingRadiusCiudadJuarez,
            lookForDriversWithingRadiusCiudadObregon: form.lookForDriversWithingRadiusCiudadObregon,
            lookForDriversWithingRadiusCoatzacoalcos: form.lookForDriversWithingRadiusCoatzacoalcos,
            lookForDriversWithingRadiusColima: form.lookForDriversWithingRadiusColima,
            lookForDriversWithingRadiusComitanDeDominguez: form.lookForDriversWithingRadiusComitanDeDominguez,
            lookForDriversWithingRadiusCordoba: form.lookForDriversWithingRadiusCordoba,
            lookForDriversWithingRadiusCozumel: form.lookForDriversWithingRadiusCozumel,
            lookForDriversWithingRadiusCuauhtemoc: form.lookForDriversWithingRadiusCuauhtemoc,
            lookForDriversWithingRadiusCuautla: form.lookForDriversWithingRadiusCuautla,
            lookForDriversWithingRadiusCuernavaca: form.lookForDriversWithingRadiusCuernavaca,
            lookForDriversWithingRadiusCuliacan: form.lookForDriversWithingRadiusCuliacan,
            lookForDriversWithingRadiusDelicias: form.lookForDriversWithingRadiusDelicias,
            lookForDriversWithingRadiusDoloresHidalgo: form.lookForDriversWithingRadiusDoloresHidalgo,
            lookForDriversWithingRadiusDurango: form.lookForDriversWithingRadiusDurango,
            lookForDriversWithingRadiusEnsenada: form.lookForDriversWithingRadiusEnsenada,
            lookForDriversWithingRadiusFresnillo: form.lookForDriversWithingRadiusFresnillo,
            lookForDriversWithingRadiusGarcia: form.lookForDriversWithingRadiusGarcia,
            lookForDriversWithingRadiusGdl: form.lookForDriversWithingRadiusGdl,
            lookForDriversWithingRadiusGuanajuato: form.lookForDriversWithingRadiusGuanajuato,
            lookForDriversWithingRadiusGuasave: form.lookForDriversWithingRadiusGuasave,
            lookForDriversWithingRadiusGuaymas: form.lookForDriversWithingRadiusGuaymas,
            lookForDriversWithingRadiusHermosillo: form.lookForDriversWithingRadiusHermosillo,
            lookForDriversWithingRadiusIrapuato: form.lookForDriversWithingRadiusIrapuato,
            lookForDriversWithingRadiusLaPaz: form.lookForDriversWithingRadiusLaPaz,
            lookForDriversWithingRadiusLaPiedad: form.lookForDriversWithingRadiusLaPiedad,
            lookForDriversWithingRadiusLazaroCardenas: form.lookForDriversWithingRadiusLazaroCardenas,
            lookForDriversWithingRadiusLeon: form.lookForDriversWithingRadiusLeon,
            lookForDriversWithingRadiusLosCabos: form.lookForDriversWithingRadiusLosCabos,
            lookForDriversWithingRadiusLosMochis: form.lookForDriversWithingRadiusLosMochis,
            lookForDriversWithingRadiusManzanillo: form.lookForDriversWithingRadiusManzanillo,
            lookForDriversWithingRadiusMatamoros: form.lookForDriversWithingRadiusMatamoros,
            lookForDriversWithingRadiusMazatlan: form.lookForDriversWithingRadiusMazatlan,
            lookForDriversWithingRadiusMer: form.lookForDriversWithingRadiusMer,
            lookForDriversWithingRadiusMaxicali: form.lookForDriversWithingRadiusMaxicali,
            lookForDriversWithingRadiusCdMx: form.lookForDriversWithingRadiusCdMx,
            lookForDriversWithingRadiusMinatitlan: form.lookForDriversWithingRadiusMinatitlan,
            lookForDriversWithingRadiusMonclova: form.lookForDriversWithingRadiusMonclova,
            lookForDriversWithingRadiusMty: form.lookForDriversWithingRadiusMty,
            lookForDriversWithingRadiusMorelia: form.lookForDriversWithingRadiusMorelia,
            lookForDriversWithingRadiusNavojoa: form.lookForDriversWithingRadiusNavojoa,
            lookForDriversWithingRadiusNogales: form.lookForDriversWithingRadiusNogales,
            lookForDriversWithingRadiusNuevoLaredo: form.lookForDriversWithingRadiusNuevoLaredo,
            lookForDriversWithingRadiusOaxaca: form.lookForDriversWithingRadiusOaxaca,
            lookForDriversWithingRadiusPachuca: form.lookForDriversWithingRadiusPachuca,
            lookForDriversWithingRadiusPiedrasNegras: form.lookForDriversWithingRadiusPiedrasNegras,
            lookForDriversWithingRadiusPlayaDelCarmen: form.lookForDriversWithingRadiusPlayaDelCarmen,
            lookForDriversWithingRadiusPozaRica: form.lookForDriversWithingRadiusPozaRica,
            lookForDriversWithingRadiusProgreso: form.lookForDriversWithingRadiusProgreso,
            lookForDriversWithingRadiusPue: form.lookForDriversWithingRadiusPue,
            lookForDriversWithingRadiusPuertoVallarta: form.lookForDriversWithingRadiusPuertoVallarta,
            lookForDriversWithingRadiusQro: form.lookForDriversWithingRadiusQro,
            lookForDriversWithingRadiusReynosa: form.lookForDriversWithingRadiusReynosa,
            lookForDriversWithingRadiusRosarito: form.lookForDriversWithingRadiusRosarito,
            lookForDriversWithingRadiusSalamanca: form.lookForDriversWithingRadiusSalamanca,
            lookForDriversWithingRadiusSal: form.lookForDriversWithingRadiusSal,
            lookForDriversWithingRadiusSanCristobalDeLasCasas: form.lookForDriversWithingRadiusSanCristobalDeLasCasas,
            lookForDriversWithingRadiusSanJose: form.lookForDriversWithingRadiusSanJose,
            lookForDriversWithingRadiusSanJoseDelCabo: form.lookForDriversWithingRadiusSanJoseDelCabo,
            lookForDriversWithingRadiusSanJuanDelRio: form.lookForDriversWithingRadiusSanJuanDelRio,
            lookForDriversWithingRadiusSanLuisPotosi: form.lookForDriversWithingRadiusSanLuisPotosi,
            lookForDriversWithingRadiusSanLuisRioColorado: form.lookForDriversWithingRadiusSanLuisRioColorado,
            lookForDriversWithingRadiusSanMiguelAllende: form.lookForDriversWithingRadiusSanMiguelAllende,
            lookForDriversWithingRadiusSantiago: form.lookForDriversWithingRadiusSantiago,
            lookForDriversWithingRadiusSilao: form.lookForDriversWithingRadiusSilao,
            lookForDriversWithingRadiusSth: form.lookForDriversWithingRadiusSth,
            lookForDriversWithingRadiusTampico: form.lookForDriversWithingRadiusTampico,
            lookForDriversWithingRadiusTapachula: form.lookForDriversWithingRadiusTapachula,
            lookForDriversWithingRadiusTehuacan: form.lookForDriversWithingRadiusTehuacan,
            lookForDriversWithingRadiusTepic: form.lookForDriversWithingRadiusTepic,
            lookForDriversWithingRadiusTexcoco: form.lookForDriversWithingRadiusTexcoco,
            lookForDriversWithingRadiusTeziutlan: form.lookForDriversWithingRadiusTeziutlan,
            lookForDriversWithingRadiusTijuana: form.lookForDriversWithingRadiusTijuana,
            lookForDriversWithingRadiusTlaxcala: form.lookForDriversWithingRadiusTlaxcala,
            lookForDriversWithingRadiusToluca: form.lookForDriversWithingRadiusToluca,
            lookForDriversWithingRadiusTrc: form.lookForDriversWithingRadiusTrc,
            lookForDriversWithingRadiusTuxtlaGutierrez: form.lookForDriversWithingRadiusTuxtlaGutierrez,
            lookForDriversWithingRadiusUruapan: form.lookForDriversWithingRadiusUruapan,
            lookForDriversWithingRadiusValleDeBravo: form.lookForDriversWithingRadiusValleDeBravo,
            lookForDriversWithingRadiusValleDeChalco: form.lookForDriversWithingRadiusValleDeChalco,
            lookForDriversWithingRadiusValleDeSantiago: form.lookForDriversWithingRadiusValleDeSantiago,
            lookForDriversWithingRadiusVeracruz: form.lookForDriversWithingRadiusVeracruz,
            lookForDriversWithingRadiusVillahermosa: form.lookForDriversWithingRadiusVillahermosa,
            lookForDriversWithingRadiusXalapa: form.lookForDriversWithingRadiusXalapa,
            lookForDriversWithingRadiusZacatecas: form.lookForDriversWithingRadiusZacatecas,
            lookForDriversWithingRadiusZamora: form.lookForDriversWithingRadiusZamora,
            lookForDriversWithPositionsNotOlderThan: form.lookForDriversWithPositionsNotOlderThan,
            delayTimeForNonPrioDrivers: form.delayTimeForNonPrioDrivers,
            driverDeliveryAcceptTime: form.driverDeliveryAcceptTime,
            driverDeliveryCancellationTime: form.driverDeliveryCancellationTime,
            reSendRejectedDeliveryRequestTime: form.reSendRejectedDeliveryRequestTime,
            driverDeliveryFailTime: form.driverDeliveryFailTime,
            driverAllowedToPressPickedUpRadius: form.driverAllowedToPressPickedUpRadius,
            driverAllowedToPressDeliveredRadius: form.driverAllowedToPressDeliveredRadius,
            driverLateForPickupAlarmTime: form.driverLateForPickupAlarmTime,
            timeToDistributeNearByPendingDelivery: form.timeToDistributeNearByPendingDelivery,
            timeToDistributeMultiPickup: form.timeToDistributeMultiPickup,
            maximumNumberOfOngoingDeliveries: form.maximumNumberOfOngoingDeliveries,
            multiPickupDeliveryRadius: form.multiPickupDeliveryRadius,
            driverLateForPickupAlarmDistance: form.driverLateForPickupAlarmDistance,
            maximumCashOrderAmountMx: form.maximumCashOrderAmountMx,
            maximumCashOrderAmountUy: form.maximumCashOrderAmountUy,
            maximumCashOrderAmountAr: form.maximumCashOrderAmountAr,
            expensiveOrderAmount: form.expensiveOrderAmount,
            deliveriesRequiredForExpensiveOrder: form.deliveriesRequiredForExpensiveOrder,
            expensiveOrderFilteringEnabledCities: form.expensiveOrderFilteringEnabledCities,
            deliverySecurityCodeMinimumAmount: form.deliverySecurityCodeMinimumAmount,
            sendThanksForOrderingEmailMinutesAfter: form.sendThanksForOrderingEmailMinutesAfter,
            thanksForOrderingEmailTemplateId: form.thanksForOrderingEmailTemplateId,
            sendWelcomeEmailMinutesAfter: form.sendWelcomeEmailMinutesAfter,
            welcomeEmailTemplateId: form.welcomeEmailTemplateId,
            sendMenuHighlightEmailDaysAfter: form.sendMenuHighlightEmailDaysAfter,
            menuHighlightEmailTemplateId: form.menuHighlightEmailTemplateId,
            sendPromoCodeEmailDaysAfter: form.sendPromoCodeEmailDaysAfter,
            promoCodeEmailTemplateId: form.promoCodeEmailTemplateId,
            prioritizeOldestNearbyDeliveriesEnabled: form.prioritizeOldestNearbyDeliveriesEnabled,
            hasMandatoryUpdateToPideDirectoAppEnabled: form.hasMandatoryUpdateToPideDirectoAppEnabled,
            hasMandatoryUpdateToAdminAppEnabled: form.hasMandatoryUpdateToAdminAppEnabled,
            hasMandatoryUpdateToDriverAppEnabled: form.hasMandatoryUpdateToDriverAppEnabled,
            toggleAutomaticDynamicDeliveryEarnings: form.toggleAutomaticDynamicDeliveryEarnings,
            deliveryCategories: form.deliveryCategories,
            pspPriority: form.pspPriority,
            smsServiceProviderForAuthenticationSmsesInMx: form.smsServiceProviderForAuthenticationSmsesInMx,
            smsServiceProviderForHighPrioSmsesInMx: form.smsServiceProviderForHighPrioSmsesInMx,
            smsServiceProviderForLowPrioSmsesInMx: form.smsServiceProviderForLowPrioSmsesInMx,
            smsServiceProviderForAuthenticationSmsesInSe: form.smsServiceProviderForAuthenticationSmsesInSe,
            smsServiceProviderForHighPrioSmsesInSe: form.smsServiceProviderForHighPrioSmsesInSe,
            smsServiceProviderForLowPrioSmsesInSe: form.smsServiceProviderForLowPrioSmsesInSe,
            smsServiceProviderForAuthenticationSmsesInUs: form.smsServiceProviderForAuthenticationSmsesInUs,
            smsServiceProviderForHighPrioSmsesInUs: form.smsServiceProviderForHighPrioSmsesInUs,
            smsServiceProviderForLowPrioSmsesInUs: form.smsServiceProviderForLowPrioSmsesInUs,
            smsServiceProviderForAuthenticationSmsesInUy: form.smsServiceProviderForAuthenticationSmsesInUy,
            smsServiceProviderForHighPrioSmsesInUy: form.smsServiceProviderForHighPrioSmsesInUy,
            smsServiceProviderForLowPrioSmsesInUy: form.smsServiceProviderForLowPrioSmsesInUy,
            smsServiceProviderForAuthenticationSmsesInAr: form.smsServiceProviderForAuthenticationSmsesInAr,
            smsServiceProviderForHighPrioSmsesInAr: form.smsServiceProviderForHighPrioSmsesInAr,
            smsServiceProviderForLowPrioSmsesInAr: form.smsServiceProviderForLowPrioSmsesInAr,
            smsServiceProviderForAuthenticationSmsesInBr: form.smsServiceProviderForAuthenticationSmsesInBr,
            smsServiceProviderForHighPrioSmsesInBr: form.smsServiceProviderForHighPrioSmsesInBr,
            smsServiceProviderForLowPrioSmsesInBr: form.smsServiceProviderForLowPrioSmsesInBr,
            smsServiceProviderForAuthenticationSmsesInPe: form.smsServiceProviderForAuthenticationSmsesInPe,
            smsServiceProviderForHighPrioSmsesInPe: form.smsServiceProviderForHighPrioSmsesInPe,
            smsServiceProviderForLowPrioSmsesInPe: form.smsServiceProviderForLowPrioSmsesInPe,
            smsServiceProviderForAuthenticationSmsesInCl: form.smsServiceProviderForAuthenticationSmsesInCl,
            smsServiceProviderForHighPrioSmsesInCl: form.smsServiceProviderForHighPrioSmsesInCl,
            smsServiceProviderForLowPrioSmsesInCl: form.smsServiceProviderForLowPrioSmsesInCl,
            smsServiceProviderForAuthenticationSmsesInPy: form.smsServiceProviderForAuthenticationSmsesInPy,
            smsServiceProviderForHighPrioSmsesInPy: form.smsServiceProviderForHighPrioSmsesInPy,
            smsServiceProviderForLowPrioSmsesInPy: form.smsServiceProviderForLowPrioSmsesInPy,
            smsServiceProviderForAuthenticationSmsesInCo: form.smsServiceProviderForAuthenticationSmsesInCo,
            smsServiceProviderForHighPrioSmsesInCo: form.smsServiceProviderForHighPrioSmsesInCo,
            smsServiceProviderForLowPrioSmsesInCo: form.smsServiceProviderForLowPrioSmsesInCo,
            subscriptionPaymentClabe: form.subscriptionPaymentClabe,
            subscriptionPaymentClabeToElevate: form.subscriptionPaymentClabeToElevate,
            deliveryManagerMaxRuntime: form.deliveryManagerMaxRuntime,
            deliveryManagerMaxRuntimeMty: form.deliveryManagerMaxRuntimeMty,
            deliveryManagerMaxRuntimeGdl: form.deliveryManagerMaxRuntimeGdl,
            deliveryManagerMaxRuntimeCdMx: form.deliveryManagerMaxRuntimeCdMx,
            deliveryManagerMaxRuntimeSal: form.deliveryManagerMaxRuntimeSal,
            deliveryManagerMaxRuntimeTrc: form.deliveryManagerMaxRuntimeTrc,
            deliveryManagerMaxRuntimeQro: form.deliveryManagerMaxRuntimeQro,
            deliveryManagerMaxRuntimeMer: form.deliveryManagerMaxRuntimeMer,
            deliveryManagerMaxRuntimePue: form.deliveryManagerMaxRuntimePue,
            nearbyPendingDeliveryEnabled: form.nearbyPendingDeliveryEnabled,
            multiPickupEnabled: form.multiPickupEnabled,
            uberEatsDeliveryEnabled: form.uberEatsDeliveryEnabled,
            rappiCargoDeliveryEnabled: form.rappiCargoDeliveryEnabled,
            deliveryCommissionEnabled: form.deliveryCommissionEnabled,
            driverReferralDaysToExpire: form.driverReferralDaysToExpire,
            driverReferralReferredDriverEarnings: form.driverReferralReferredDriverEarnings,
            driverReferralReferredDriverEarningsMty: form.driverReferralReferredDriverEarningsMty,
            driverReferralReferredDriverEarningsGdl: form.driverReferralReferredDriverEarningsGdl,
            driverReferralReferredDriverEarningsCdMx: form.driverReferralReferredDriverEarningsCdMx,
            driverReferralReferredDriverEarningsSal: form.driverReferralReferredDriverEarningsSal,
            driverReferralReferredDriverEarningsTrc: form.driverReferralReferredDriverEarningsTrc,
            driverReferralReferredDriverEarningsQro: form.driverReferralReferredDriverEarningsQro,
            driverReferralReferredDriverEarningsMer: form.driverReferralReferredDriverEarningsMer,
            driverReferralReferredDriverEarningsPue: form.driverReferralReferredDriverEarningsPue,
            driverReferralReferredByDriverEarnings: form.driverReferralReferredByDriverEarnings,
            driverReferralReferredByDriverEarningsMty: form.driverReferralReferredByDriverEarningsMty,
            driverReferralReferredByDriverEarningsGdl: form.driverReferralReferredByDriverEarningsGdl,
            driverReferralReferredByDriverEarningsCdMx: form.driverReferralReferredByDriverEarningsCdMx,
            driverReferralReferredByDriverEarningsSal: form.driverReferralReferredByDriverEarningsSal,
            driverReferralReferredByDriverEarningsTrc: form.driverReferralReferredByDriverEarningsTrc,
            driverReferralReferredByDriverEarningsQro: form.driverReferralReferredByDriverEarningsQro,
            driverReferralReferredByDriverEarningsMer: form.driverReferralReferredByDriverEarningsMer,
            driverReferralReferredByDriverEarningsPue: form.driverReferralReferredByDriverEarningsPue,
            driverReferralDeliveriesToDeliver: form.driverReferralDeliveriesToDeliver,
            driverReferralDeliveriesToDeliverMty: form.driverReferralDeliveriesToDeliverMty,
            driverReferralDeliveriesToDeliverGdl: form.driverReferralDeliveriesToDeliverGdl,
            driverReferralDeliveriesToDeliverCdMx: form.driverReferralDeliveriesToDeliverCdMx,
            driverReferralDeliveriesToDeliverSal: form.driverReferralDeliveriesToDeliverSal,
            driverReferralDeliveriesToDeliverTrc: form.driverReferralDeliveriesToDeliverTrc,
            driverReferralDeliveriesToDeliverQro: form.driverReferralDeliveriesToDeliverQro,
            driverReferralDeliveriesToDeliverMer: form.driverReferralDeliveriesToDeliverMer,
            driverReferralDeliveriesToDeliverPue: form.driverReferralDeliveriesToDeliverPue,
            autoCallRestaurantsEnabled: form.autoCallRestaurantsEnabled,
            whatsAppAuthenticationEnabled: form.whatsAppAuthenticationEnabled,
            timeToAutoCallRestaurants: form.timeToAutoCallRestaurants,
            deliveryCostBonus: form.deliveryCostBonus,
            dynamicDeliveryEarningsToNewRestaurant: form.dynamicDeliveryEarningsToNewRestaurant,
            dynamicDeliveryEarningsToNewRestaurantBogota: form.dynamicDeliveryEarningsToNewRestaurantBogota,
            dynamicDeliveryEarningsToNewRestaurantGdl: form.dynamicDeliveryEarningsToNewRestaurantGdl,
            dynamicDeliveryEarningsToNewRestaurantMer: form.dynamicDeliveryEarningsToNewRestaurantMer,
            dynamicDeliveryEarningsToNewRestaurantCdMx: form.dynamicDeliveryEarningsToNewRestaurantCdMx,
            dynamicDeliveryEarningsToNewRestaurantMty: form.dynamicDeliveryEarningsToNewRestaurantMty,
            dynamicDeliveryEarningsToNewRestaurantPue: form.dynamicDeliveryEarningsToNewRestaurantPue,
            dynamicDeliveryEarningsToNewRestaurantQro: form.dynamicDeliveryEarningsToNewRestaurantQro,
            dynamicDeliveryEarningsToNewRestaurantSal: form.dynamicDeliveryEarningsToNewRestaurantSal,
            dynamicDeliveryEarningsToNewRestaurantSth: form.dynamicDeliveryEarningsToNewRestaurantSth,
            dynamicDeliveryEarningsToNewRestaurantTrc: form.dynamicDeliveryEarningsToNewRestaurantTrc,
            payPerUseRange1OrdersLimit: form.payPerUseRange1OrdersLimit,
            payPerUseRange1PaymentAmount: form.payPerUseRange1PaymentAmount,
            payPerUseRange2OrdersLimit: form.payPerUseRange2OrdersLimit,
            payPerUseRange2PaymentAmount: form.payPerUseRange2PaymentAmount,
            payPerUseRangeMaxOrdersLimit: form.payPerUseRangeMaxOrdersLimit,
            payPerUseRangeMaxPaymentAmount: form.payPerUseRangeMaxPaymentAmount,
            emailsToNotifySubscriptionChanges: form.emailsToNotifySubscriptionChanges,
            emailsToNotifyRestaurantIntegrationsIssues: form.emailsToNotifyRestaurantIntegrationsIssues,
        });
        if (!response.ok) {
            alertKnownErrorOrSomethingWentWrong(response);
        }
        load();
    };

    const handleEnableAppUpdateControls = () => {
        const password = prompt(translate('Password'));
        if (password !== '9988') return alert(translate('Wrong Password'));
        setAppUpdateControlsEnabled(false);
    };

    const handleEnableUpdateSubscriptionPaymentClabe = () => {
        const password = prompt(translate('Password'));
        if (password !== '8899') return alert(translate('Wrong Password'));
        setCanUpdateSubscriptionPaymentClabe(true);
    };

    const handleEnableUpdateSubscriptionPaymentClabeToElevate = () => {
        const password = prompt(translate('Password'));
        if (password !== '8899') return alert(translate('Wrong Password'));
        setCanUpdateSubscriptionPaymentClabeToElevate(true);
    };

    return (
        <Paper style={{ padding: 20 }}>
            <PasswordRequiredContent
                customPassword={'7384'}
                onAuthorized={() => {
                    load();
                }}
            >
                <Form form={form} onSubmit={onSubmit}>
                    <Grid container spacing={3}>
                        <Grid container justify='flex-end' spacing={3}>
                            <UpdatingContentProgress loading={loading} />
                            <Grid item>
                                <FormSubmitButton text={translate('Change')} disabled={loading} />
                            </Grid>
                        </Grid>
                        {!cities && (
                            <>
                                <Grid item xs={12}>
                                    <FormTextField
                                        name='managerMessageStyle'
                                        label={translate('Manager Message Style')}
                                        helperText={translate(
                                            'Can be "error", "warning", "info", "success", "text". For looks see https://material-ui.com/components/alert . You can create multiple messages by separating them with a semicolon. E.g. "warning;info"',
                                        )}
                                        placeholder={'warning'}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <FormTextField
                                        name='managerMessageTitle'
                                        label={translate('Manager Message Title')}
                                        helperText={translate('You can create multiple messages by separating them with a semicolon. E.g. "Covid 19 Info;New Features Available!"')}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <FormTextField
                                        name='managerMessageBody'
                                        label={translate('Manager Message Body')}
                                        helperText={translate(
                                            'You can create multiple messages by separating them with a semicolon. E.g. "Make sure all personal wear masks. Minimize contact with drivers;Orders now show up in manager instead of Whatsapp! Please give us your feedback"',
                                        )}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <FormTextField
                                        name='managerMessageUrl'
                                        label={translate('Manager Message Url')}
                                        helperText={translate('You can add multiple urls by separating them with a semicolon. Just make sure the urls doesnt have a semicolon')}
                                    />
                                </Grid>
                            </>
                        )}
                        {(!cities || mtyIsSelected) && (
                            <>
                                <Grid item xs={12}>
                                    <FormTextField
                                        name='managerMessageStyleMty'
                                        label={translate('Manager Message Style Monterrey')}
                                        helperText={translate(
                                            'Can be "error", "warning", "info", "success", "text". For looks see https://material-ui.com/components/alert . You can create multiple messages by separating them with a semicolon. E.g. "warning;info"',
                                        )}
                                        placeholder={'warning'}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <FormTextField
                                        name='managerMessageTitleMty'
                                        label={translate('Manager Message Title Monterrey')}
                                        helperText={translate('You can create multiple messages by separating them with a semicolon. E.g. "Covid 19 Info;New Features Available!"')}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <FormTextField
                                        name='managerMessageBodyMty'
                                        label={translate('Manager Message Body Monterrey')}
                                        helperText={translate(
                                            'You can create multiple messages by separating them with a semicolon. E.g. "Make sure all personal wear masks. Minimize contact with drivers;Orders now show up in manager instead of Whatsapp! Please give us your feedback"',
                                        )}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <FormTextField
                                        name='managerMessageUrlMty'
                                        label={translate('Manager Message Url Monterrey')}
                                        helperText={translate('You can add multiple urls by separating them with a semicolon. Just make sure the urls doesnt have a semicolon')}
                                    />
                                </Grid>
                            </>
                        )}
                        {(!cities || gdlIsSelected) && (
                            <>
                                <Grid item xs={12}>
                                    <FormTextField
                                        name='managerMessageStyleGdl'
                                        label={translate('Manager Message Style Guadalajara')}
                                        helperText={translate(
                                            'Can be "error", "warning", "info", "success", "text". For looks see https://material-ui.com/components/alert . You can create multiple messages by separating them with a semicolon. E.g. "warning;info"',
                                        )}
                                        placeholder={'warning'}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <FormTextField
                                        name='managerMessageTitleGdl'
                                        label={translate('Manager Message Title Guadalajara')}
                                        helperText={translate('You can create multiple messages by separating them with a semicolon. E.g. "Covid 19 Info;New Features Available!"')}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <FormTextField
                                        name='managerMessageBodyGdl'
                                        label={translate('Manager Message Body Guadalajara')}
                                        helperText={translate(
                                            'You can create multiple messages by separating them with a semicolon. E.g. "Make sure all personal wear masks. Minimize contact with drivers;Orders now show up in manager instead of Whatsapp! Please give us your feedback"',
                                        )}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <FormTextField
                                        name='managerMessageUrlGdl'
                                        label={translate('Manager Message Url Guadalajara')}
                                        helperText={translate('You can add multiple urls by separating them with a semicolon. Just make sure the urls doesnt have a semicolon')}
                                    />
                                </Grid>
                            </>
                        )}
                        {(!cities || cdmxIsSelected) && (
                            <>
                                <Grid item xs={12}>
                                    <FormTextField
                                        name='managerMessageStyleCdMx'
                                        label={translate('Manager Message Style Mexico City')}
                                        helperText={translate(
                                            'Can be "error", "warning", "info", "success", "text". For looks see https://material-ui.com/components/alert . You can create multiple messages by separating them with a semicolon. E.g. "warning;info"',
                                        )}
                                        placeholder={'warning'}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <FormTextField
                                        name='managerMessageTitleCdMx'
                                        label={translate('Manager Message Title Mexico City')}
                                        helperText={translate('You can create multiple messages by separating them with a semicolon. E.g. "Covid 19 Info;New Features Available!"')}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <FormTextField
                                        name='managerMessageBodyCdMx'
                                        label={translate('Manager Message Body Mexico City')}
                                        helperText={translate(
                                            'You can create multiple messages by separating them with a semicolon. E.g. "Make sure all personal wear masks. Minimize contact with drivers;Orders now show up in manager instead of Whatsapp! Please give us your feedback"',
                                        )}
                                    />
                                </Grid>
                                <FormTextField
                                    name='managerMessageUrlCdMx'
                                    label={translate('Manager Message Url Mexico City')}
                                    helperText={translate('You can add multiple urls by separating them with a semicolon. Just make sure the urls doesnt have a semicolon')}
                                />
                            </>
                        )}
                        {(!cities || salIsSelected) && (
                            <>
                                <Grid item xs={12}>
                                    <FormTextField
                                        name='managerMessageStyleSal'
                                        label={translate('Manager Message Style Saltillo')}
                                        helperText={translate(
                                            'Can be "error", "warning", "info", "success", "text". For looks see https://material-ui.com/components/alert . You can create multiple messages by separating them with a semicolon. E.g. "warning;info"',
                                        )}
                                        placeholder={'warning'}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <FormTextField
                                        name='managerMessageTitleSal'
                                        label={translate('Manager Message Title Saltillo')}
                                        helperText={translate('You can create multiple messages by separating them with a semicolon. E.g. "Covid 19 Info;New Features Available!"')}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <FormTextField
                                        name='managerMessageBodySal'
                                        label={translate('Manager Message Body Saltillo')}
                                        helperText={translate(
                                            'You can create multiple messages by separating them with a semicolon. E.g. "Make sure all personal wear masks. Minimize contact with drivers;Orders now show up in manager instead of Whatsapp! Please give us your feedback"',
                                        )}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <FormTextField
                                        name='managerMessageUrlSal'
                                        label={translate('Manager Message Url Saltillo')}
                                        helperText={translate('You can add multiple urls by separating them with a semicolon. Just make sure the urls doesnt have a semicolon')}
                                    />
                                </Grid>
                            </>
                        )}
                        {(!cities || trcIsSelected) && (
                            <>
                                <Grid item xs={12}>
                                    <FormTextField
                                        name='managerMessageStyleTrc'
                                        label={translate('Manager Message Style Torreon')}
                                        helperText={translate(
                                            'Can be "error", "warning", "info", "success", "text". For looks see https://material-ui.com/components/alert . You can create multiple messages by separating them with a semicolon. E.g. "warning;info"',
                                        )}
                                        placeholder={'warning'}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <FormTextField
                                        name='managerMessageTitleTrc'
                                        label={translate('Manager Message Title Torreon')}
                                        helperText={translate('You can create multiple messages by separating them with a semicolon. E.g. "Covid 19 Info;New Features Available!"')}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <FormTextField
                                        name='managerMessageBodyTrc'
                                        label={translate('Manager Message Body Torreon')}
                                        helperText={translate(
                                            'You can create multiple messages by separating them with a semicolon. E.g. "Make sure all personal wear masks. Minimize contact with drivers;Orders now show up in manager instead of Whatsapp! Please give us your feedback"',
                                        )}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <FormTextField
                                        name='managerMessageUrlTrc'
                                        label={translate('Manager Message Url Torreon')}
                                        helperText={translate('You can add multiple urls by separating them with a semicolon. Just make sure the urls doesnt have a semicolon')}
                                    />
                                </Grid>
                            </>
                        )}
                        {(!cities || qroIsSelected) && (
                            <>
                                <Grid item xs={12}>
                                    <FormTextField
                                        name='managerMessageStyleQro'
                                        label={translate('Manager Message Style Queretaro')}
                                        helperText={translate(
                                            'Can be "error", "warning", "info", "success", "text". For looks see https://material-ui.com/components/alert . You can create multiple messages by separating them with a semicolon. E.g. "warning;info"',
                                        )}
                                        placeholder={'warning'}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <FormTextField
                                        name='managerMessageTitleQro'
                                        label={translate('Manager Message Title Queretaro')}
                                        helperText={translate('You can create multiple messages by separating them with a semicolon. E.g. "Covid 19 Info;New Features Available!"')}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <FormTextField
                                        name='managerMessageBodyQro'
                                        label={translate('Manager Message Body Queretaro')}
                                        helperText={translate(
                                            'You can create multiple messages by separating them with a semicolon. E.g. "Make sure all personal wear masks. Minimize contact with drivers;Orders now show up in manager instead of Whatsapp! Please give us your feedback"',
                                        )}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <FormTextField
                                        name='managerMessageUrlQro'
                                        label={translate('Manager Message Url Queretaro')}
                                        helperText={translate('You can add multiple urls by separating them with a semicolon. Just make sure the urls doesnt have a semicolon')}
                                    />
                                </Grid>
                            </>
                        )}
                        {(!cities || merIsSelected) && (
                            <>
                                <Grid item xs={12}>
                                    <FormTextField
                                        name='managerMessageStyleMer'
                                        label={translate('Manager Message Style Merida')}
                                        helperText={translate(
                                            'Can be "error", "warning", "info", "success", "text". For looks see https://material-ui.com/components/alert . You can create multiple messages by separating them with a semicolon. E.g. "warning;info"',
                                        )}
                                        placeholder={'warning'}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <FormTextField
                                        name='managerMessageTitleMer'
                                        label={translate('Manager Message Title Merida')}
                                        helperText={translate('You can create multiple messages by separating them with a semicolon. E.g. "Covid 19 Info;New Features Available!"')}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <FormTextField
                                        name='managerMessageBodyMer'
                                        label={translate('Manager Message Body Merida')}
                                        helperText={translate(
                                            'You can create multiple messages by separating them with a semicolon. E.g. "Make sure all personal wear masks. Minimize contact with drivers;Orders now show up in manager instead of Whatsapp! Please give us your feedback"',
                                        )}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <FormTextField
                                        name='managerMessageUrlMer'
                                        label={translate('Manager Message Url Merida')}
                                        helperText={translate('You can add multiple urls by separating them with a semicolon. Just make sure the urls doesnt have a semicolon')}
                                    />
                                </Grid>
                            </>
                        )}
                        {(!cities || pueIsSelected) && (
                            <>
                                <Grid item xs={12}>
                                    <FormTextField
                                        name='managerMessageStylePue'
                                        label={translate('Manager Message Style Puebla')}
                                        helperText={translate(
                                            'Can be "error", "warning", "info", "success", "text". For looks see https://material-ui.com/components/alert . You can create multiple messages by separating them with a semicolon. E.g. "warning;info"',
                                        )}
                                        placeholder={'warning'}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <FormTextField
                                        name='managerMessageTitlePue'
                                        label={translate('Manager Message Title Puebla')}
                                        helperText={translate('You can create multiple messages by separating them with a semicolon. E.g. "Covid 19 Info;New Features Available!"')}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <FormTextField
                                        name='managerMessageBodyPue'
                                        label={translate('Manager Message Body Puebla')}
                                        helperText={translate(
                                            'You can create multiple messages by separating them with a semicolon. E.g. "Make sure all personal wear masks. Minimize contact with drivers;Orders now show up in manager instead of Whatsapp! Please give us your feedback"',
                                        )}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <FormTextField
                                        name='managerMessageUrlPue'
                                        label={translate('Manager Message Url Puebla')}
                                        helperText={translate('You can add multiple urls by separating them with a semicolon. Just make sure the urls doesnt have a semicolon')}
                                    />
                                </Grid>
                            </>
                        )}
                        <Grid item xs={12}>
                            <FormPspsAutocomplete name='pspPriority' label={translate('PSPs')} helperText={translate('PSP priority')} />
                        </Grid>
                        <Grid item xs={12}>
                            <FormSmsServiceProviderSelect
                                name='smsServiceProviderForAuthenticationSmsesInMx'
                                label={translate('Authentication SMS service provider Mexico')}
                                countryCode={CountryCodes.MX}
                                smsPrio={SmsPrios.AUTHENTICATION}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <FormSmsServiceProviderSelect
                                name='smsServiceProviderForHighPrioSmsesInMx'
                                label={translate('High Prio SMS service provider Mexico')}
                                countryCode={CountryCodes.MX}
                                smsPrio={SmsPrios.HIGH_PRIO}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <FormSmsServiceProviderSelect
                                name='smsServiceProviderForLowPrioSmsesInMx'
                                label={translate('Low Prio SMS service provider Mexico')}
                                countryCode={CountryCodes.MX}
                                smsPrio={SmsPrios.LOW_PRIO}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <FormSmsServiceProviderSelect
                                name='smsServiceProviderForAuthenticationSmsesInSe'
                                label={translate('Authentication SMS service provider Sweden')}
                                countryCode={CountryCodes.SE}
                                smsPrio={SmsPrios.AUTHENTICATION}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <FormSmsServiceProviderSelect
                                name='smsServiceProviderForHighPrioSmsesInSe'
                                label={translate('High Prio SMS service provider Sweden')}
                                countryCode={CountryCodes.SE}
                                smsPrio={SmsPrios.HIGH_PRIO}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <FormSmsServiceProviderSelect
                                name='smsServiceProviderForLowPrioSmsesInSe'
                                label={translate('Low Prio SMS service provider Sweden')}
                                countryCode={CountryCodes.SE}
                                smsPrio={SmsPrios.LOW_PRIO}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <FormSmsServiceProviderSelect
                                name='smsServiceProviderForAuthenticationSmsesInUs'
                                label={translate('Authentication SMS service provider US')}
                                countryCode={CountryCodes.US}
                                smsPrio={SmsPrios.AUTHENTICATION}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <FormSmsServiceProviderSelect
                                name='smsServiceProviderForHighPrioSmsesInUs'
                                label={translate('High Prio SMS service provider US')}
                                countryCode={CountryCodes.US}
                                smsPrio={SmsPrios.HIGH_PRIO}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <FormSmsServiceProviderSelect
                                name='smsServiceProviderForLowPrioSmsesInUs'
                                label={translate('Low Prio SMS service provider US')}
                                countryCode={CountryCodes.US}
                                smsPrio={SmsPrios.LOW_PRIO}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <FormSmsServiceProviderSelect
                                name='smsServiceProviderForAuthenticationSmsesInUy'
                                label={translate('Authentication SMS service provider Uruguay')}
                                countryCode={CountryCodes.UY}
                                smsPrio={SmsPrios.AUTHENTICATION}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <FormSmsServiceProviderSelect
                                name='smsServiceProviderForHighPrioSmsesInUy'
                                label={translate('High Prio SMS service provider Uruguay')}
                                countryCode={CountryCodes.UY}
                                smsPrio={SmsPrios.HIGH_PRIO}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <FormSmsServiceProviderSelect
                                name='smsServiceProviderForLowPrioSmsesInUy'
                                label={translate('Low Prio SMS service provider Uruguay')}
                                countryCode={CountryCodes.UY}
                                smsPrio={SmsPrios.LOW_PRIO}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <FormSmsServiceProviderSelect
                                name='smsServiceProviderForAuthenticationSmsesInAr'
                                label={translate('Authentication SMS service provider Argentina')}
                                countryCode={CountryCodes.AR}
                                smsPrio={SmsPrios.AUTHENTICATION}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <FormSmsServiceProviderSelect
                                name='smsServiceProviderForHighPrioSmsesInAr'
                                label={translate('High Prio SMS service provider Argentina')}
                                countryCode={CountryCodes.AR}
                                smsPrio={SmsPrios.HIGH_PRIO}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <FormSmsServiceProviderSelect
                                name='smsServiceProviderForLowPrioSmsesInAr'
                                label={translate('Low Prio SMS service provider Argentina')}
                                countryCode={CountryCodes.AR}
                                smsPrio={SmsPrios.LOW_PRIO}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <FormSmsServiceProviderSelect
                                name='smsServiceProviderForAuthenticationSmsesInBr'
                                label={translate('Authentication SMS service provider Brazil')}
                                countryCode={CountryCodes.BR}
                                smsPrio={SmsPrios.AUTHENTICATION}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <FormSmsServiceProviderSelect
                                name='smsServiceProviderForHighPrioSmsesInBr'
                                label={translate('High Prio SMS service provider Brazil')}
                                countryCode={CountryCodes.BR}
                                smsPrio={SmsPrios.HIGH_PRIO}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <FormSmsServiceProviderSelect
                                name='smsServiceProviderForLowPrioSmsesInBr'
                                label={translate('Low Prio SMS service provider Brazil')}
                                countryCode={CountryCodes.BR}
                                smsPrio={SmsPrios.LOW_PRIO}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <FormSmsServiceProviderSelect
                                name='smsServiceProviderForAuthenticationSmsesInPe'
                                label={translate('Authentication SMS service provider Peru')}
                                countryCode={CountryCodes.PE}
                                smsPrio={SmsPrios.AUTHENTICATION}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <FormSmsServiceProviderSelect
                                name='smsServiceProviderForHighPrioSmsesInPe'
                                label={translate('High Prio SMS service provider Peru')}
                                countryCode={CountryCodes.PE}
                                smsPrio={SmsPrios.HIGH_PRIO}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <FormSmsServiceProviderSelect
                                name='smsServiceProviderForLowPrioSmsesInPe'
                                label={translate('Low Prio SMS service provider Peru')}
                                countryCode={CountryCodes.PE}
                                smsPrio={SmsPrios.LOW_PRIO}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <FormSmsServiceProviderSelect
                                name='smsServiceProviderForAuthenticationSmsesInCl'
                                label={translate('Authentication SMS service provider Chile')}
                                countryCode={CountryCodes.CL}
                                smsPrio={SmsPrios.AUTHENTICATION}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <FormSmsServiceProviderSelect
                                name='smsServiceProviderForHighPrioSmsesInCl'
                                label={translate('High Prio SMS service provider Chile')}
                                countryCode={CountryCodes.CL}
                                smsPrio={SmsPrios.HIGH_PRIO}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <FormSmsServiceProviderSelect
                                name='smsServiceProviderForLowPrioSmsesInCl'
                                label={translate('Low Prio SMS service provider Chile')}
                                countryCode={CountryCodes.CL}
                                smsPrio={SmsPrios.LOW_PRIO}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <FormSmsServiceProviderSelect
                                name='smsServiceProviderForAuthenticationSmsesInPy'
                                label={translate('Authentication SMS service provider Paraguay')}
                                countryCode={CountryCodes.PY}
                                smsPrio={SmsPrios.AUTHENTICATION}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <FormSmsServiceProviderSelect
                                name='smsServiceProviderForHighPrioSmsesInPy'
                                label={translate('High Prio SMS service provider Paraguay')}
                                countryCode={CountryCodes.PY}
                                smsPrio={SmsPrios.HIGH_PRIO}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <FormSmsServiceProviderSelect
                                name='smsServiceProviderForLowPrioSmsesInPy'
                                label={translate('Low Prio SMS service provider Paraguay')}
                                countryCode={CountryCodes.PY}
                                smsPrio={SmsPrios.LOW_PRIO}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <FormSmsServiceProviderSelect
                                name='smsServiceProviderForAuthenticationSmsesInCo'
                                label={translate('Authentication SMS service provider Colombia')}
                                countryCode={CountryCodes.CO}
                                smsPrio={SmsPrios.AUTHENTICATION}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <FormSmsServiceProviderSelect
                                name='smsServiceProviderForHighPrioSmsesInCo'
                                label={translate('High Prio SMS service provider Colombia')}
                                countryCode={CountryCodes.CO}
                                smsPrio={SmsPrios.HIGH_PRIO}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <FormSmsServiceProviderSelect
                                name='smsServiceProviderForLowPrioSmsesInCo'
                                label={translate('Low Prio SMS service provider Colombia')}
                                countryCode={CountryCodes.CO}
                                smsPrio={SmsPrios.LOW_PRIO}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <DeprecatedFormPercentNumberField
                                name='marketplaceServiceFeeRate'
                                label={translate('Marketplace Service Fee Rate')}
                                helperText={translate('Adds a service fee cost for the customer percentage based on the menu item price.')}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <FormSwitch
                                name='addMarketplaceServiceFeeToMenuItemPrice'
                                label={translate('Add Marketplace Service Fee To Menu Item Price')}
                                helperText={translate('Enable this to add/include the marketplace service fee in the menu item price on menus')}
                            />
                        </Grid>
                        {!cities && (
                            <Grid item xs={12}>
                                <FormNumberField
                                    name='deliveryManagerMaxRuntime'
                                    label={translate('Delivery Manager Max Runtime')}
                                    helperText={translate('Running Delivery Manager Time Max Before Going To Uber')}
                                />
                            </Grid>
                        )}
                        {(!cities || mtyIsSelected) && (
                            <Grid item xs={12}>
                                <FormNumberField
                                    name='deliveryManagerMaxRuntimeMty'
                                    label={translate('Delivery Manager Max Runtime Monterrey')}
                                    helperText={translate('Running Delivery Manager Time Max Before Going To Uber')}
                                />
                            </Grid>
                        )}
                        {(!cities || gdlIsSelected) && (
                            <Grid item xs={12}>
                                <FormNumberField
                                    name='deliveryManagerMaxRuntimeGdl'
                                    label={translate('Delivery Manager Max Runtime Guadalajara')}
                                    helperText={translate('Running Delivery Manager Time Max Before Going To Uber')}
                                />
                            </Grid>
                        )}
                        {(!cities || cdmxIsSelected) && (
                            <Grid item xs={12}>
                                <FormNumberField
                                    name='deliveryManagerMaxRuntimeCdMx'
                                    label={translate('Delivery Manager Max Runtime Ciudad de México')}
                                    helperText={translate('Running Delivery Manager Time Max Before Going To Uber')}
                                />
                            </Grid>
                        )}
                        {(!cities || salIsSelected) && (
                            <Grid item xs={12}>
                                <FormNumberField
                                    name='deliveryManagerMaxRuntimeSal'
                                    label={translate('Delivery Manager Max Runtime Saltillo')}
                                    helperText={translate('Running Delivery Manager Time Max Before Going To Uber')}
                                />
                            </Grid>
                        )}
                        {(!cities || trcIsSelected) && (
                            <Grid item xs={12}>
                                <FormNumberField
                                    name='deliveryManagerMaxRuntimeTrc'
                                    label={translate('Delivery Manager Max Runtime Torreón')}
                                    helperText={translate('Running Delivery Manager Time Max Before Going To Uber')}
                                />
                            </Grid>
                        )}
                        {(!cities || qroIsSelected) && (
                            <Grid item xs={12}>
                                <FormNumberField
                                    name='deliveryManagerMaxRuntimeQro'
                                    label={translate('Delivery Manager Max Runtime Querétaro')}
                                    helperText={translate('Running Delivery Manager Time Max Before Going To Uber')}
                                />
                            </Grid>
                        )}
                        {(!cities || merIsSelected) && (
                            <Grid item xs={12}>
                                <FormNumberField
                                    name='deliveryManagerMaxRuntimeMer'
                                    label={translate('Delivery Manager Max Runtime Mérida')}
                                    helperText={translate('Running Delivery Manager Time Max Before Going To Uber')}
                                />
                            </Grid>
                        )}
                        {(!cities || pueIsSelected) && (
                            <Grid item xs={12}>
                                <FormNumberField
                                    name='deliveryManagerMaxRuntimePue'
                                    label={translate('Delivery Manager Max Runtime Puebla')}
                                    helperText={translate('Running Delivery Manager Time Max Before Going To Uber')}
                                />
                            </Grid>
                        )}
                        {!cities && (
                            <Grid item xs={12}>
                                <FormNumberField name='deliveryRadiusInKm' label={translate('Delivery Radius in Km')} required min={0} max={8} />
                            </Grid>
                        )}
                        {cities?.length > 0 && <DeliveryRadiusInKmSettings />}
                        {!cities && (
                            <Grid item xs={12}>
                                <FormNumberField name='deliveriesForBonusCommission' label={translate('Deliveries necessary for bonus delivery cash commission')} required min={0} />
                            </Grid>
                        )}
                        {cities?.length > 0 && <DeliveriesForBonusCommissionSettings />}
                        <Grid item xs={12}>
                            <FormNumberField name='deliveryTimeFixed' label={translate('Delivery Time Fixed')} required min={0} />
                        </Grid>
                        <Grid item xs={12}>
                            <FormNumberField name='deliveryTimePerKm' label={translate('Delivery Time Per Km')} required min={0} />
                        </Grid>
                        <Grid item xs={12}>
                            <FormCurrencyNumberField name='customerDeliveryCostFixed' label={translate('Customer Delivery Cost Fixed')} required min={0} />
                        </Grid>
                        <Grid item xs={12}>
                            <FormCurrencyNumberField name='customerDeliveryCostPerKm' label={translate('Customer Delivery Cost Per Km')} required min={0} />
                        </Grid>
                        <Grid item xs={12}>
                            <FormNumberField name='dynamicEarningsMinimumMinutes' label={translate('Dynamic Earnings Minimum Minutes')} required min={0} />
                        </Grid>
                        <Grid item xs={12}>
                            <FormCurrencyNumberField name='dynamicEarningsAmountToIncrementByMinute' label={translate('Dynamic Earnings Amount To Increment By Minute')} required min={0} />
                        </Grid>
                        <Grid item xs={12}>
                            <FormCurrencyNumberField name='dynamicEarningsRoof' label={translate('Dynamic Earnings Roof')} required min={0} />
                        </Grid>
                        <Grid item xs={12}>
                            <FormCurrencyNumberStringField name='deliveryOrchestratorFee' label={translate('Delivery Orchestrator Fee')} required min={0} />
                        </Grid>
                        <Grid item xs={12}>
                            <FormCurrencyNumberField name='deliveryCostBonus' label={translate('Delivery Cost Bonus')} required min={0} />
                        </Grid>
                        <Grid item xs={12}>
                            <FormNumberField
                                name='longDistanceExtraDeliveryCostStartsFromKm'
                                label={translate('Driver Long Distance Delivery Extra Earnings Starts From Km')}
                                helperText={translate(
                                    'Deliveries with drive distance longer than this distance will have additional extra earnings added for drivers. The extra earnings are set in setting "Driver Long Distance Delivery Extra Earnings Fixed" and "Driver Long Distance Delivery Extra Earnings Per Km"',
                                )}
                                min={0}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <FormCurrencyNumberField
                                name='longDistanceExtraDeliveryCostFixed'
                                label={translate('Driver Long Distance Delivery Extra Earnings Fixed')}
                                helperText={translate(
                                    'Add this extra earnings for drivers when delivery distance for a delivery is longer than in setting "Driver Long Distance Delivery Extra Earnings Starts From Km"',
                                )}
                                min={0}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <FormCurrencyNumberField
                                name='longDistanceExtraDeliveryCostPerKm'
                                label={translate('Driver Long Distance Delivery Extra Earnings Per Km')}
                                helperText={translate(
                                    'Add this extra earnings per km (above setting limit) for drivers when delivery distance for a delivery is longer than in setting "Driver Long Distance Delivery Extra Earnings Starts From Km".',
                                )}
                                min={0}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <FormAutocompleteMultipleString
                                name='deliveryCategories'
                                label={translate('Delivery Categories')}
                                helperText={translate('They will be presented in the same order in app. This is also the names that will be presented in app (these are not translated).')}
                                freeSolo
                                selectOnCommaKey
                                options={[]}
                            />
                        </Grid>
                        {!cities && (
                            <Grid item xs={12}>
                                <FormNumberField
                                    name='lookForDriversWithingRadius'
                                    label={translate('Look For Drivers Withing Radius')}
                                    helperText={translate('Look for drivers within the x meters from restaurant location')}
                                    required
                                />
                            </Grid>
                        )}
                        {cities?.length > 0 && <LookForDriversWithingRadiusSettings />}
                        <Grid item xs={12}>
                            <FormNumberField
                                name='lookForDriversWithPositionsNotOlderThan'
                                label={translate('Look For Drivers With Positions Not Older Than')}
                                helperText={translate('If a drivers position has not been updated within x minutes it will not receive delivery requests')}
                                required
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <FormNumberField
                                name='delayTimeForNonPrioDrivers'
                                label={translate('Prio new drivers')}
                                helperText={translate('The prio (i.e. delay time in seconds) new drivers will receive when downloading the app.')}
                                required
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <FormNumberField
                                name='driverDeliveryAcceptTime'
                                label={translate('Driver Delivery Accept Time')}
                                helperText={translate(
                                    'Time (in seconds) to wait for driver to accept delivery before offering delivery to another driver (first will still be able to accept delivery)',
                                )}
                                required
                                min={0}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <FormNumberField
                                name='driverDeliveryCancellationTime'
                                label={translate('Driver Delivery Cancellation Time')}
                                helperText={translate('Time (in seconds) that driver is able to cancel a delivery after accepting a delivery')}
                                required
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <FormNumberField
                                name='reSendRejectedDeliveryRequestTime'
                                label={translate('Re Send Rejected Delivery Request Time')}
                                helperText={translate('Time (in seconds) that driver wont receive another delivery request after driver rejected a delivery request (for the same delivery)')}
                                required
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <FormNumberField
                                name='driverDeliveryFailTime'
                                label={translate('Driver Delivery Fail Time')}
                                helperText={translate('Time (in minutes) to look for drivers to delivery an order before order gets rejected')}
                                required
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <FormNumberField
                                name='driverAllowedToPressPickedUpRadius'
                                label={translate('Driver Allowed To Press Picked Up Radius')}
                                helperText={translate('Allow drivers to only press Picked Up button withing x meters from restaurant location')}
                                required
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <FormNumberField
                                name='driverAllowedToPressDeliveredRadius'
                                label={translate('Driver Allowed To Press Delivered Radius')}
                                helperText={translate('Allow drivers to only press Delivered button withing x meters from delivery location')}
                                required
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <FormNumberField
                                name='driverLateForPickupAlarmTime'
                                label={translate('Alarm Setting - Driver Late For Pickup Alarm Time [seconds]')}
                                helperText={translate('If distance from restaurant to driver is more than X meters, Y seconds before Pickup Time an alarm is triggered.')}
                                required
                            />
                        </Grid>
                        {!cities && (
                            <Grid item xs={12}>
                                <FormNumberField
                                    name='lookForDriversWithinRadiusForNearbyPendingDelivery'
                                    label={translate('Look For Drivers Within Radius For Nearby Pending Delivery')}
                                    helperText={translate('The look for the driver will be within X meters. Notice, the look needs to be on the basis of the delivery point of the client')}
                                    required
                                />
                            </Grid>
                        )}
                        {cities?.length > 0 && <LookForDriversWithinRadiusForNearbyPendingDeliverySettings />}
                        <Grid item xs={12}>
                            <FormNumberField
                                name='timeToDistributeNearByPendingDelivery'
                                label={translate('Time To Distribute Near By Pending Delivery [seconds]')}
                                helperText={translate('Time (in seconds) for a driver to accept a second order before searching for new drivers that dont have ongoing orders')}
                                required
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <FormNumberField
                                name='timeToDistributeMultiPickup'
                                label={translate('Time To Distribute Multi-Pickup [seconds]')}
                                helperText={translate('Time (in seconds) for a driver to accept a second order before searching for new drivers that dont have ongoing orders')}
                                required
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <FormNumberField name='maximumNumberOfOngoingDeliveries' label={translate('Maximum Number Of Orders Can Be Picked Up')} required />
                        </Grid>
                        <Grid item xs={12}>
                            <FormNumberField name='multiPickupDeliveryRadius' label={translate('Distance between delivery addresses for multi-pickup order')} required />
                        </Grid>
                        <Grid item xs={12}>
                            <FormNumberField
                                name='driverLateForPickupAlarmDistance'
                                label={translate('Alarm Setting - Driver Late For Pickup Alarm Distance [meters]')}
                                helperText={translate('see setting "Alarm Setting - Driver Late For Pickup Alarm Time')}
                                required
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <FormCurrencyNumberStringField name='maximumCashOrderAmountMx' label={translate('Maximum cash order amount for MX')} />
                        </Grid>
                        <Grid item xs={12}>
                            <FormCurrencyNumberStringField name='maximumCashOrderAmountUy' label={translate('Maximum cash order amount for UY')} />
                        </Grid>
                        <Grid item xs={12}>
                            <FormCurrencyNumberStringField name='maximumCashOrderAmountAr' label={translate('Maximum cash order amount for AR')} />
                        </Grid>
                        <Grid item xs={12}>
                            <FormNumberField name='expensiveOrderAmount' label={translate('Expensive Order Starts at')} min={0} />
                        </Grid>
                        <Grid item xs={12}>
                            <FormNumberField name='deliveriesRequiredForExpensiveOrder' label={translate('Number of Deliveries Required For Expensive Delivery')} min={0} />
                        </Grid>
                        <Grid item xs={12}>
                            <FormCitiesAutocomplete name='expensiveOrderFilteringEnabledCities' label={translate('Cities in which the filter for expensive orders applies to')} />
                        </Grid>
                        <Grid item xs={12}>
                            <FormNumberField name='deliverySecurityCodeMinimumAmount' label={translate('Delivery Security Code Minimum Amount')} min={0} />
                        </Grid>
                        <Grid item xs={12}>
                            <FormNumberField name='sendThanksForOrderingEmailMinutesAfter' label={translate('Send Email Minutes After')} />
                        </Grid>
                        <Grid item xs={12}>
                            <FormEmailTemplateAutocomplete name='thanksForOrderingEmailTemplateId' label={translate('Email Template for Thanks for Ordering')} />
                        </Grid>
                        <Grid item xs={12}>
                            <FormNumberField name='sendWelcomeEmailMinutesAfter' label={translate('Send Email Minutes After')} />
                        </Grid>
                        <Grid item xs={12}>
                            <FormEmailTemplateAutocomplete name='welcomeEmailTemplateId' label={translate('Email Template for Welcome Email')} />
                        </Grid>
                        <Grid item xs={12}>
                            <FormNumberField name='sendMenuHighlightEmailDaysAfter' label={translate('Send Email Days After')} />
                        </Grid>
                        <Grid item xs={12}>
                            <FormEmailTemplateAutocomplete name='menuHighlightEmailTemplateId' label={translate('Email template for Menu Highlight')} />
                        </Grid>
                        <Grid item xs={12}>
                            <FormNumberField name='sendPromoCodeEmailDaysAfter' label={translate('Send Email Days After')} />
                        </Grid>
                        <Grid item xs={12}>
                            <FormEmailTemplateAutocomplete name='promoCodeEmailTemplateId' label={translate('Email template for Promo Code')} />
                        </Grid>
                        <Grid item xs={12}>
                            <FormNumberField
                                name='timeToAutoCallRestaurants'
                                label={translate('Time To Auto Call Restaurants [seconds]')}
                                helperText={translate('Time (in seconds) after an order is done but not accepted to make an auto call to the restaurant')}
                                required
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <FormMultipleEmailsInput name='emailsToNotifyRestaurantIntegrationsIssues' label={translate('Emails to notify restaurant integrations issues')} />
                        </Grid>
                        <Grid item xs={12}>
                            <FormSubheading>{translate('Subscriptions').toUpperCase()}</FormSubheading>
                        </Grid>
                        <Grid item xs={12}>
                            <FormMultipleEmailsInput name='emailsToNotifySubscriptionChanges' label={translate('Emails to notify subscription changes')} />
                        </Grid>
                        <Grid item xs={11}>
                            <FormTextField
                                name='subscriptionPaymentClabe'
                                disabled={!canUpdateSubscriptionPaymentClabe}
                                label={translate('Grupo Ambit Technology Clabe')}
                                tooltip={translate('Write the 18-digit interbank code CLABE.')}
                                helperText={translate('Clabe used for subscriber  of Point of Sale users')}
                            />
                        </Grid>
                        <Grid item xs={1}>
                            <Button icon onClick={handleEnableUpdateSubscriptionPaymentClabe} disabled={loading}>
                                <EditIcon />
                            </Button>
                        </Grid>
                        <Grid item xs={11}>
                            <FormTextField
                                name='subscriptionPaymentClabeToElevate'
                                disabled={!canUpdateSubscriptionPaymentClabeToElevate}
                                label={translate('Elevate Systems Clabe')}
                                tooltip={translate('Write the 18-digit interbank code CLABE.')}
                                helperText={translate('Clabe used for subscriber of users with Elevate systems')}
                            />
                        </Grid>
                        <Grid item xs={1}>
                            <Button icon onClick={handleEnableUpdateSubscriptionPaymentClabeToElevate} disabled={loading}>
                                <EditIcon />
                            </Button>
                        </Grid>
                        <Grid item xs={12}>
                            <FormIntegerField
                                name='payPerUseRange1OrdersLimit'
                                label={translate('Pay Per Use Range 1 Orders Limit')}
                                helperText={translate(
                                    `A manual earning of -@amount (-@amountWithoutIva without IVA) will be created once any restaurant in this subscription completed @orders delivery orders (rappi, didi, ubereats orders not counted) within the month period.`,
                                    {
                                        amount: formatAsCurrencyNumber(payPerUseRange1PaymentAmount),
                                        amountWithoutIva: formatAsCurrencyNumber(withoutIva(payPerUseRange1PaymentAmount)),
                                        orders: payPerUseRange1OrdersLimit,
                                    },
                                )}
                                required
                                min={0}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <FormCurrencyNumberStringField
                                name='payPerUseRange1PaymentAmount'
                                label={translate('Pay Per Use Range 1 Payment Amount')}
                                helperText={translate(
                                    `A manual earning of -@amount (-@amountWithoutIva without IVA) will be created once any restaurant in this subscription completed @orders delivery orders (rappi, didi, ubereats orders not counted) within the month period.`,
                                    {
                                        amount: formatAsCurrencyNumber(payPerUseRange1PaymentAmount),
                                        amountWithoutIva: formatAsCurrencyNumber(withoutIva(payPerUseRange1PaymentAmount)),
                                        orders: payPerUseRange1OrdersLimit,
                                    },
                                )}
                                required
                                min={0}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <FormIntegerField
                                name='payPerUseRange2OrdersLimit'
                                label={translate('Pay Per Use Range 2 Orders Limit')}
                                helperText={translate(
                                    `A manual earning of -@amount (-@amountWithoutIva without IVA) will be created once any restaurant in this subscription completed @orders delivery orders (rappi, didi, ubereats orders not counted) within the month period.`,
                                    {
                                        amount: formatAsCurrencyNumber(payPerUseRange2PaymentAmount),
                                        amountWithoutIva: formatAsCurrencyNumber(withoutIva(payPerUseRange2PaymentAmount)),
                                        orders: payPerUseRange2OrdersLimit,
                                    },
                                )}
                                required
                                min={0}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <FormCurrencyNumberStringField
                                name='payPerUseRange2PaymentAmount'
                                label={translate('Pay Per Use Range 2 Payment Amount')}
                                helperText={translate(
                                    `A manual earning of -@amount (-@amountWithoutIva without IVA) will be created once any restaurant in this subscription completed @orders delivery orders (rappi, didi, ubereats orders not counted) within the month period.`,
                                    {
                                        amount: formatAsCurrencyNumber(payPerUseRange2PaymentAmount),
                                        amountWithoutIva: formatAsCurrencyNumber(withoutIva(payPerUseRange2PaymentAmount)),
                                        orders: payPerUseRange2OrdersLimit,
                                    },
                                )}
                                required
                                min={0}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <FormIntegerField
                                name='payPerUseRangeMaxOrdersLimit'
                                label={translate('Pay Per Use Range Max Orders Limit')}
                                helperText={translate(
                                    `A manual earning of -@amount (-@amountWithoutIva without IVA) will be created once any restaurant in this subscription completed @orders delivery orders (rappi, didi, ubereats orders not counted) within the month period.`,
                                    {
                                        amount: formatAsCurrencyNumber(payPerUseRangeMaxPaymentAmount),
                                        amountWithoutIva: formatAsCurrencyNumber(withoutIva(payPerUseRangeMaxPaymentAmount)),
                                        orders: payPerUseRangeMaxOrdersLimit,
                                    },
                                )}
                                required
                                min={0}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <FormCurrencyNumberStringField
                                name='payPerUseRangeMaxPaymentAmount'
                                label={translate('Pay Per Use Range Max Payment Amount')}
                                helperText={translate(
                                    `A manual earning of -@amount (-@amountWithoutIva without IVA) will be created once any restaurant in this subscription completed @orders delivery orders (rappi, didi, ubereats orders not counted) within the month period.`,
                                    {
                                        amount: formatAsCurrencyNumber(payPerUseRangeMaxPaymentAmount),
                                        amountWithoutIva: formatAsCurrencyNumber(withoutIva(payPerUseRangeMaxPaymentAmount)),
                                        orders: payPerUseRangeMaxOrdersLimit,
                                    },
                                )}
                                required
                                min={0}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <FormIntegerField
                                name='payPerUseRange1OrdersLimit'
                                label={translate('Pay Per Use Range 1 Orders Limit')}
                                helperText={translate(
                                    `A manual earning of -@amount (-@amountWithoutIva without IVA) will be created once any restaurant in this subscription completed @orders delivery orders (rappi, didi, ubereats orders not counted) within the month period.`,
                                    {
                                        amount: formatAsCurrencyNumber(payPerUseRange1PaymentAmount),
                                        amountWithoutIva: formatAsCurrencyNumber(withoutIva(payPerUseRange1PaymentAmount)),
                                        orders: payPerUseRange1OrdersLimit,
                                    },
                                )}
                                required
                                min={0}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <FormCurrencyNumberStringField
                                name='payPerUseRange1PaymentAmount'
                                label={translate('Pay Per Use Range 1 Payment Amount')}
                                helperText={translate(
                                    `A manual earning of -@amount (-@amountWithoutIva without IVA) will be created once any restaurant in this subscription completed @orders delivery orders (rappi, didi, ubereats orders not counted) within the month period.`,
                                    {
                                        amount: formatAsCurrencyNumber(payPerUseRange1PaymentAmount),
                                        amountWithoutIva: formatAsCurrencyNumber(withoutIva(payPerUseRange1PaymentAmount)),
                                        orders: payPerUseRange1OrdersLimit,
                                    },
                                )}
                                required
                                min={0}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <FormIntegerField
                                name='payPerUseRange2OrdersLimit'
                                label={translate('Pay Per Use Range 2 Orders Limit')}
                                helperText={translate(
                                    `A manual earning of -@amount (-@amountWithoutIva without IVA) will be created once any restaurant in this subscription completed @orders delivery orders (rappi, didi, ubereats orders not counted) within the month period.`,
                                    {
                                        amount: formatAsCurrencyNumber(payPerUseRange2PaymentAmount),
                                        amountWithoutIva: formatAsCurrencyNumber(withoutIva(payPerUseRange2PaymentAmount)),
                                        orders: payPerUseRange2OrdersLimit,
                                    },
                                )}
                                required
                                min={0}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <FormCurrencyNumberStringField
                                name='payPerUseRange2PaymentAmount'
                                label={translate('Pay Per Use Range 2 Payment Amount')}
                                helperText={translate(
                                    `A manual earning of -@amount (-@amountWithoutIva without IVA) will be created once any restaurant in this subscription completed @orders delivery orders (rappi, didi, ubereats orders not counted) within the month period.`,
                                    {
                                        amount: formatAsCurrencyNumber(payPerUseRange2PaymentAmount),
                                        amountWithoutIva: formatAsCurrencyNumber(withoutIva(payPerUseRange2PaymentAmount)),
                                        orders: payPerUseRange2OrdersLimit,
                                    },
                                )}
                                required
                                min={0}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <FormIntegerField
                                name='payPerUseRangeMaxOrdersLimit'
                                label={translate('Pay Per Use Range Max Orders Limit')}
                                helperText={translate(
                                    `A manual earning of -@amount (-@amountWithoutIva without IVA) will be created once any restaurant in this subscription completed @orders delivery orders (rappi, didi, ubereats orders not counted) within the month period.`,
                                    {
                                        amount: formatAsCurrencyNumber(payPerUseRangeMaxPaymentAmount),
                                        amountWithoutIva: formatAsCurrencyNumber(withoutIva(payPerUseRangeMaxPaymentAmount)),
                                        orders: payPerUseRangeMaxOrdersLimit,
                                    },
                                )}
                                required
                                min={0}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <FormCurrencyNumberStringField
                                name='payPerUseRangeMaxPaymentAmount'
                                label={translate('Pay Per Use Range Max Payment Amount')}
                                helperText={translate(
                                    `A manual earning of -@amount (-@amountWithoutIva without IVA) will be created once any restaurant in this subscription completed @orders delivery orders (rappi, didi, ubereats orders not counted) within the month period.`,
                                    {
                                        amount: formatAsCurrencyNumber(payPerUseRangeMaxPaymentAmount),
                                        amountWithoutIva: formatAsCurrencyNumber(withoutIva(payPerUseRangeMaxPaymentAmount)),
                                        orders: payPerUseRangeMaxOrdersLimit,
                                    },
                                )}
                                required
                                min={0}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <FormSubheading>{translate('Feature Toggles').toUpperCase()}</FormSubheading>
                        </Grid>
                        <Grid item xs={12}>
                            <FormSwitch
                                name='prioritizeOldestNearbyDeliveriesEnabled'
                                label={translate('Prioritize Oldest Nearby Deliveries Enabled')}
                                helperText={translate(
                                    'This will prioritize 10 oldest orders within "Look For Drivers Withing Radius". If there exist 10 old orders nearby, no delivery request will be sent for any new orders until any of the 10 old orders are accepted.',
                                )}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <FormSwitch name='toggleAutomaticDynamicDeliveryEarnings' label={translate('Toggle Automatic Dynamic Delivery Earnings')} />
                        </Grid>
                        <Grid item xs={12} sm={12}>
                            <FormSwitch name='nearbyPendingDeliveryEnabled' label={translate('Nearby Pending Delivery Enabled')} />
                        </Grid>
                        <Grid item xs={12} sm={12}>
                            <FormSwitch name='multiPickupEnabled' label={translate('Multi-Pickup Enabled')} />
                        </Grid>
                        <Grid item xs={12} sm={12}>
                            <FormSwitch name='uberEatsDeliveryEnabled' label={translate('Uber Eats Delivery Enabled')} />
                        </Grid>
                        <Grid item xs={12} sm={12}>
                            <FormSwitch name='rappiCargoDeliveryEnabled' label={translate('Rappi Cargo Delivery Enabled')} />
                        </Grid>
                        <Grid item xs={12} sm={12}>
                            <FormSwitch name='deliveryCommissionEnabled' label={translate('Delivery Cash Commission Enabled')} />
                        </Grid>
                        <Grid item xs={12} sm={12}>
                            <FormSwitch name='autoCallRestaurantsEnabled' label={translate('Auto Call Restaurants Enabled')} />
                        </Grid>
                        <Grid item xs={12} sm={12}>
                            <FormSwitch name='whatsAppAuthenticationEnabled' label={translate('WhatsApp Authentication Enabled')} />
                        </Grid>
                        <Grid item xs={12}>
                            <Button outlined onClick={handleEnableAppUpdateControls} disabled={loading}>
                                {translate('Enable App Update Controls').toUpperCase()}
                            </Button>
                        </Grid>
                        <Grid item xs={12}>
                            <FormSwitch
                                name='hasMandatoryUpdateToPideDirectoAppEnabled'
                                label={translate('Has a Mandatory Update To AmbitOne App (WLA) Enabled')}
                                disabled={appUpdateControlsEnabled}
                                helperText={translate('This will display a message that the application must be updated for further use and will block the use of the application.')}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <FormSwitch
                                name='hasMandatoryUpdateToAdminAppEnabled'
                                label={translate('Has a Mandatory Update To Admin App Enabled')}
                                disabled={appUpdateControlsEnabled}
                                helperText={translate('This will display a message that the application must be updated for further use and will block the use of the application.')}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <FormSwitch
                                name='hasMandatoryUpdateToDriverAppEnabled'
                                label={translate('Has a Mandatory Update To Driver App Enabled')}
                                disabled={appUpdateControlsEnabled}
                                helperText={translate('This will display a message that the application must be updated for further use and will block the use of the application.')}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <FormSubheading>{translate('Driver Referral').toUpperCase()}</FormSubheading>
                        </Grid>
                        <Grid item xs={12} sm={12}>
                            <FormNumberField name='driverReferralDaysToExpire' label={translate('Driver Referral Days To Expire')} />
                        </Grid>
                        {!cities && (
                            <Grid item xs={12}>
                                <FormNumberField name='driverReferralReferredDriverEarnings' label={translate('Referred Driver Earnings')} />
                            </Grid>
                        )}
                        {(!cities || mtyIsSelected) && (
                            <Grid item xs={12}>
                                <FormNumberField name='driverReferralReferredDriverEarningsMty' label={translate('Referred Driver Earnings Mty')} />
                            </Grid>
                        )}
                        {(!cities || gdlIsSelected) && (
                            <Grid item xs={12}>
                                <FormNumberField name='driverReferralReferredDriverEarningsGdl' label={translate('Referred Driver Earnings Gdl')} />
                            </Grid>
                        )}
                        {(!cities || cdmxIsSelected) && (
                            <Grid item xs={12}>
                                <FormNumberField name='driverReferralReferredDriverEarningsCdMx' label={translate('Referred Driver Earnings CdMx')} />
                            </Grid>
                        )}
                        {(!cities || salIsSelected) && (
                            <Grid item xs={12}>
                                <FormNumberField name='driverReferralReferredDriverEarningsSal' label={translate('Referred Driver Earnings Sal')} />
                            </Grid>
                        )}
                        {(!cities || trcIsSelected) && (
                            <Grid item xs={12}>
                                <FormNumberField name='driverReferralReferredDriverEarningsTrc' label={translate('Referred Driver Earnings Trc')} />
                            </Grid>
                        )}
                        {(!cities || qroIsSelected) && (
                            <Grid item xs={12}>
                                <FormNumberField name='driverReferralReferredDriverEarningsQro' label={translate('Referred Driver Earnings Qro')} />
                            </Grid>
                        )}
                        {(!cities || merIsSelected) && (
                            <Grid item xs={12}>
                                <FormNumberField name='driverReferralReferredDriverEarningsMer' label={translate('Referred Driver Earnings Mer')} />
                            </Grid>
                        )}
                        {(!cities || pueIsSelected) && (
                            <Grid item xs={12}>
                                <FormNumberField name='driverReferralReferredDriverEarningsPue' label={translate('Referred Driver Earnings Pue')} />
                            </Grid>
                        )}
                        {!cities && (
                            <Grid item xs={12}>
                                <FormNumberField name='driverReferralReferredByDriverEarnings' label={translate('Referred By Driver Earnings')} />
                            </Grid>
                        )}
                        {(!cities || mtyIsSelected) && (
                            <Grid item xs={12}>
                                <FormNumberField name='driverReferralReferredByDriverEarningsMty' label={translate('Referred By Driver Earnings Mty')} />
                            </Grid>
                        )}
                        {(!cities || gdlIsSelected) && (
                            <Grid item xs={12}>
                                <FormNumberField name='driverReferralReferredByDriverEarningsGdl' label={translate('Referred By Driver Earnings Gdl')} />
                            </Grid>
                        )}
                        {(!cities || cdmxIsSelected) && (
                            <Grid item xs={12}>
                                <FormNumberField name='driverReferralReferredByDriverEarningsCdMx' label={translate('Referred By Driver Earnings CdMx')} />
                            </Grid>
                        )}
                        {(!cities || salIsSelected) && (
                            <Grid item xs={12}>
                                <FormNumberField name='driverReferralReferredByDriverEarningsSal' label={translate('Referred By Driver Earnings Sal')} />
                            </Grid>
                        )}
                        {(!cities || trcIsSelected) && (
                            <Grid item xs={12}>
                                <FormNumberField name='driverReferralReferredByDriverEarningsTrc' label={translate('Referred By Driver Earnings Trc')} />
                            </Grid>
                        )}
                        {(!cities || qroIsSelected) && (
                            <Grid item xs={12}>
                                <FormNumberField name='driverReferralReferredByDriverEarningsQro' label={translate('Referred By Driver Earnings Qro')} />
                            </Grid>
                        )}
                        {(!cities || merIsSelected) && (
                            <Grid item xs={12}>
                                <FormNumberField name='driverReferralReferredByDriverEarningsMer' label={translate('Referred By Driver Earnings Mer')} />
                            </Grid>
                        )}
                        {(!cities || pueIsSelected) && (
                            <Grid item xs={12}>
                                <FormNumberField name='driverReferralReferredByDriverEarningsPue' label={translate('Referred By Driver Earnings Pue')} />
                            </Grid>
                        )}
                        {!cities && (
                            <Grid item xs={12}>
                                <FormNumberField name='driverReferralDeliveriesToDeliver' label={translate('Deliveries To Deliver Driver Referral ')} />
                            </Grid>
                        )}
                        {(!cities || mtyIsSelected) && (
                            <Grid item xs={12}>
                                <FormNumberField name='driverReferralDeliveriesToDeliverMty' label={translate('Deliveries To Deliver Driver Referral Mty')} />
                            </Grid>
                        )}
                        {(!cities || gdlIsSelected) && (
                            <Grid item xs={12}>
                                <FormNumberField name='driverReferralDeliveriesToDeliverGdl' label={translate('Deliveries To Deliver Driver Referral Gdl')} />
                            </Grid>
                        )}
                        {(!cities || cdmxIsSelected) && (
                            <Grid item xs={12}>
                                <FormNumberField name='driverReferralDeliveriesToDeliverCdMx' label={translate('Deliveries To Deliver Driver Referral CdMx')} />
                            </Grid>
                        )}
                        {(!cities || salIsSelected) && (
                            <Grid item xs={12}>
                                <FormNumberField name='driverReferralDeliveriesToDeliverSal' label={translate('Deliveries To Deliver Driver Referral Sal')} />
                            </Grid>
                        )}
                        {(!cities || trcIsSelected) && (
                            <Grid item xs={12}>
                                <FormNumberField name='driverReferralDeliveriesToDeliverTrc' label={translate('Deliveries To Deliver Driver Referral Trc')} />
                            </Grid>
                        )}
                        {(!cities || qroIsSelected) && (
                            <Grid item xs={12}>
                                <FormNumberField name='driverReferralDeliveriesToDeliverQro' label={translate('Deliveries To Deliver Driver Referral Qro')} />
                            </Grid>
                        )}
                        {(!cities || merIsSelected) && (
                            <Grid item xs={12}>
                                <FormNumberField name='driverReferralDeliveriesToDeliverMer' label={translate('Deliveries To Deliver Driver Referral Mer')} />
                            </Grid>
                        )}
                        {(!cities || pueIsSelected) && (
                            <Grid item xs={12}>
                                <FormNumberField name='driverReferralDeliveriesToDeliverPue' label={translate('Deliveries To Deliver Driver Referral Pue')} />
                            </Grid>
                        )}
                        <Grid item xs={12}>
                            <FormSubheading>{translate('Zone Delivery Earnings').toUpperCase()}</FormSubheading>
                        </Grid>
                        {Object.keys(zoneSettings ?? {}).map((zone: any) => {
                            return (
                                <React.Fragment key={zone}>
                                    <Grid item xs={12}>
                                        <FormSubheading>{zone.toUpperCase()}</FormSubheading>
                                        <FormCurrencyNumberField
                                            name={`zoneSettings.${zone}.fixed`}
                                            label={translate('@zoneName Fixed', { zoneName: zone })}
                                            placeholder={customerDeliveryCostFixed}
                                            helperText={translate(
                                                'Set zone specific Customer Delivery Cost. Overrides the general "Delivery Cost Fixed" and "Customer Delivery Cost Per Km" for zones.',
                                            )}
                                            min={0}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <FormCurrencyNumberField
                                            name={`zoneSettings.${zone}.perKm`}
                                            label={translate('@zoneName Per Km', { zoneName: zone })}
                                            placeholder={customerDeliveryCostPerKm}
                                            helperText={translate(
                                                'Set zone specific Customer Delivery Cost. Overrides the general "Delivery Cost Fixed" and "Customer Delivery Cost Per Km" for zones.',
                                            )}
                                            min={0}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <FormNumberField
                                            name={`zoneSettings.${zone}.dynamicEarningsMinimumMinutes`}
                                            label={translate('@zoneName dynamicEarningsMinimumMinutes', { zoneName: zone })}
                                            placeholder={dynamicEarningsMinimumMinutes}
                                            helperText={translate('Minimum minutes for the dynamic delivery earnings to kick in per zone.')}
                                            min={0}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <FormCurrencyNumberStringField
                                            name={`zoneSettings.${zone}.dynamicEarningsAmountToIncrementByMinute`}
                                            label={translate('@zoneName dynamicEarningsAmountToIncrementByMinute', { zoneName: zone })}
                                            placeholder={dynamicEarningsAmountToIncrementByMinute}
                                            helperText={translate('Amount to increment by minute after dynamic delivery earnings kicks in per zone.')}
                                            min={0}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <FormCurrencyNumberStringField
                                            name={`zoneSettings.${zone}.dynamicEarningsRoof`}
                                            label={translate('@zoneName dynamicEarningsRoof', { zoneName: zone })}
                                            placeholder={dynamicEarningsRoof}
                                            helperText={translate('Dynamic delivery earnings maximum earnings per zone.')}
                                            min={0}
                                        />
                                    </Grid>
                                </React.Fragment>
                            );
                        })}
                        <Grid item xs={12}>
                            <FormSubheading>{translate('Delivery Benefits To New Restaurants').toUpperCase()}</FormSubheading>
                        </Grid>
                        <DynamicDeliveryEarningsToNewRestaurantSettings />
                        <Grid container justify='flex-end' spacing={3}>
                            <Grid item>
                                <FormSubmitButton text={translate('Change')} disabled={loading} />
                            </Grid>
                        </Grid>
                    </Grid>
                </Form>
            </PasswordRequiredContent>
        </Paper>
    );
}

function removeEmptyZones(zoneSettings: ZoneSettings) {
    return Object.entries(zoneSettings).reduce<Record<string, any>>((result, entry) => {
        const [zone, data] = entry as any;
        if (
            isNumber(data.fixed) ||
            isNumber(data.perKm) ||
            isNumber(data.dynamicEarningsMinimumMinutes) ||
            isNumber(data.dynamicEarningsAmountToIncrementByMinute) ||
            isNumber(data.dynamicEarningsRoof)
        ) {
            result[zone] = data;
        }
        return result;
    }, {});
}
