export const KEY_NAME = 'companyNameOptions';

export function getCompanyNameOptionsInLocalStorage(): Array<CompanyNameOption> {
    const item = window.localStorage.getItem('companyNameOptions');
    return item ? JSON.parse(item) : [];
}

export type CompanyNameOption = {
    label: string;
    value: string;
};
