import { makeStyles } from '@material-ui/core';
import * as React from 'react';
import { Button } from 'src/components/Button';
import { translate } from 'src/i18n/translate';
import { PrinterOutlineIcon } from 'src/icons/PrinterOutlineIcon';
import { usePrintPosBusinessDayReport } from 'src/services/printer/usePrintPosBusinessDayReport';
import { getPosBusinessDayReportDemo } from 'src/utils/posBusinessDay/getPosBusinessDayReportDemo';
import { useSelector } from 'src/utils/react/useSelector';

export function PrintPosBusinessDayReportTestButton({ disabled }: Props): React.ReactElement {
    const classes = useStyles();

    const [printPosBusinessDayReport] = usePrintPosBusinessDayReport();

    const restaurant = useSelector((state) => state.app.restaurant);

    const handlePrintPosBusinessDayReport = async () => {
        await printPosBusinessDayReport(getPosBusinessDayReportDemo(restaurant), true);
    };

    return (
        <Button onClick={handlePrintPosBusinessDayReport} disabled={disabled} classes={{ button: classes.printButton }}>
            <PrinterOutlineIcon color={'#FFF'} title={'print-pos-business-day-report-test'} />
            {translate('Print test')}
        </Button>
    );
}

const useStyles = makeStyles((theme) => ({
    printButton: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        height: 50,
        width: 160,
        [theme.breakpoints.up('md')]: {
            alignSelf: 'center',
        },
        marginTop: 10,
    },
}));

type Props = {
    disabled: boolean;
};
