import { makeStyles } from '@material-ui/core';
import { BigNumber } from 'bignumber.js';
import * as React from 'react';
import { memo, useState } from 'react';
import { app2 } from 'src/app2';
import { Button } from 'src/components/Button';
import { RolePermissions } from 'src/constants/RolePermission';
import { translate } from 'src/i18n/translate';
import { getPosInterfaceLargerEnabledRestaurantSettingInLocalStorage } from 'src/localStorage/getPosInterfaceLargerEnabledRestaurantSettingInLocalStorage';
import { AddDiscountDialog } from 'src/scenes/letseatmanager/pos/AddDiscountDialog';
import { PosPayment } from 'src/types/PosPayment';
import { isPaymentLinkPayment } from 'src/utils/paymentMethod/isPaymentLinkPayment';
import { useAction } from 'src/utils/react/useAction';
import { useSelector } from 'src/utils/react/useSelector';
import { useUserHasRolePermission } from 'src/utils/react/useUserHasRolePermissions';

export const PosAddDiscountButton: React.ComponentType<any> = memo<any>(() => {
    const classes = useStyles();

    const [addDiscountDialogState, setAddDiscountDialogState] = useState({ open: false });

    const [userHasRolePermission] = useUserHasRolePermission();
    const posInterfaceLargerEnabled = getPosInterfaceLargerEnabledRestaurantSettingInLocalStorage();

    const disabledPosPaymentActions = useSelector((state) => state.pos.disabledPosPaymentActions);
    const paying = useSelector((state) => state.pos.paying);
    const payments = useSelector((state) => state.pos.payments);
    const total = useSelector((state) => state.pos.payment?.total);
    const totalPaid = useSelector((state) => state.pos.totalPaid);

    const openVerifyPosPasswordDialog = useAction(app2.actions.openVerifyPosPasswordDialog);
    const closeVerifyPosPasswordDialog = useAction(app2.actions.closeVerifyPosPasswordDialog);

    const posPaymentIsPaymentLink = payments?.some((posPayment: PosPayment) => isPaymentLinkPayment(posPayment.paymentMethod));
    const hasPaymentCompleted = BigNumber(totalPaid).isEqualTo(total);
    const disabled = disabledPosPaymentActions || paying || hasPaymentCompleted;

    const openAddDiscountDialog = () => setAddDiscountDialogState({ open: true });

    const onAuthorized = () => {
        closeVerifyPosPasswordDialog();
        openAddDiscountDialog();
    };

    const onUnauthorized = () => {
        alert(translate('Unauthorized to access.'));
    };

    const handleAddDiscountContextualMenuItem = () => {
        if (!userHasRolePermission(RolePermissions.APPLY_DISCOUNT_ORDERS)) {
            return openVerifyPosPasswordDialog({
                open: true,
                onAuthorized,
                onUnauthorized,
            });
        }

        openAddDiscountDialog();
    };

    return (
        <>
            <AddDiscountDialog open={addDiscountDialogState.open} onClose={() => setAddDiscountDialogState({ open: false })} />
            <Button
                secondary
                larger={posInterfaceLargerEnabled}
                classes={{ button: classes.basicButton }}
                onClick={handleAddDiscountContextualMenuItem}
                disabled={Boolean(disabled || posPaymentIsPaymentLink)}
            >
                {translate('Add Discount')}
            </Button>
        </>
    );
});

const useStyles = makeStyles((theme) => ({
    basicButton: {
        width: '100%',
        height: 50,
        color: theme.palette.text.secondary,
        '&:hover': {
            cursor: 'pointer',
        },
    },
}));
