import { letseatadminApiMethod } from 'src/api/letseatadminApi';
import type { ApiSauceResponse } from 'src/api/types/ApiSauceResponse';
import { RefundMethod } from 'src/constants/RefundMethod';
import { CompanyId, CustomerId, EmailAddress, RestaurantCustomerId } from 'src/types/Id';

export async function changeRestaurantCustomerApi(request: ChangeRestaurantCustomerApiRequest): ApiSauceResponse<void> {
    return letseatadminApiMethod('restaurantCustomer/changeRestaurantCustomerApi', request);
}

export type ChangeRestaurantCustomerApiRequest = {
    restaurantCustomerId: RestaurantCustomerId;
    customerId?: CustomerId;
    companyId?: CompanyId;
    email: EmailAddress;
    refundMethod?: RefundMethod;
    firstName: string;
    lastName: string;
    companyName?: string;
    employeeIdAtCompany?: string;
    employeeNumberAtCompany?: string;
    payrollPaymentsEnabled?: boolean;
    unsubscribed: boolean;
    banned?: boolean;
};
