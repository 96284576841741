import { makeStyles } from '@material-ui/core';
import { Button, DropDownItem } from '@pidedirecto/ui';
import { ExitIcon } from '@pidedirecto/ui/icons';
import * as React from 'react';
import { translate } from 'src/i18n/translate';
import { useLogoutFromCognito } from 'src/services/facade/aws/useLogoutFromCognito';

export function RightToolBarSignOutContextualMenuItem(): React.ReactElement {
    const classes = useStyles();

    const logoutFromCognito = useLogoutFromCognito();

    return (
        <>
            <div className={classes.divider} />
            <DropDownItem classes={{ container: classes.container }}>
                <Button onClick={logoutFromCognito} variant='text' classes={{ button: classes.button }}>
                    <div className={classes.iconContainer}>
                        <ExitIcon size={24} />
                    </div>
                    {translate('Sign out')}
                </Button>
            </DropDownItem>
        </>
    );
}

const useStyles = makeStyles((theme) => ({
    container: {
        paddingTop: 0,
        paddingBottom: 0,
    },
    divider: {
        borderTop: `1px solid ${theme.palette.border.primary}`,
        width: '100%',
        height: 1,
        minWidth: 250,
    },
    button: {
        width: '100%',
        justifyContent: 'flex-start',
        textDecoration: 'none !important',
    },
    iconContainer: {
        minWidth: 50,
        display: 'flex',
        alignItems: 'center',
    },
}));
