import { makeStyles } from '@material-ui/core/styles';
import * as React from 'react';
import { classNames } from 'src/utils/react/classNames';

export function SearchableListItem({ value, children, onItemClick, selected }: Props): React.ReactElement {
    const classes = useStyles();

    const onClick = (e: any) => {
        onItemClick?.(value);
    };

    return (
        <li className={classNames(classes.listItem, selected ? classes.listItemSelected : '')} onClick={onClick}>
            {children}
        </li>
    );
}

const useStyles = makeStyles((theme) => ({
    listItem: {
        height: '100%',
        borderBottom: `2px solid ${theme.palette.border.primary}`,
        transition: 'all 0.3s ease',
        padding: '16px 8px',
        '&:hover': {
            backgroundColor: `${theme.palette.surface.brand}63`,
            boxShadow: '0px 0px',
            cursor: 'pointer',
            borderBottom: `2px solid ${theme.palette.border.brandContrast}`,
        },
    },
    listItemSelected: {
        backgroundColor: `${theme.palette.surface.brand}63`,
        boxShadow: '0px 0px',
        cursor: 'pointer',
        borderBottom: `2px solid ${theme.palette.border.brandContrast}`,
        transition: 'all 0.3s ease',
    },
}));

type Props = {
    value: any;
    children: React.ReactNode;
    onItemClick: any;
    selected: boolean;
};
