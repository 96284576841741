import { makeStyles } from '@material-ui/core';
import moment from 'moment';
import * as React from 'react';
import { getGeneralEcommerceReportApi } from 'src/api/letseatmanager/restaurantDashboard/getGeneralEcommerceReportApi';
import { UpdatingContentProgress } from 'src/components/UpdatingContentProgress';
import { translate } from 'src/i18n/translate';
import { ConversionPercentageIcon } from 'src/icons/ConversionPercentageIcon';
import { DinersIcon } from 'src/icons/DinersIcon';
import { MoneyBagIcon } from 'src/icons/MoneyBagIcon';
import { OrderBagIcon } from 'src/icons/OrderBagIcon';
import { FilterReports } from 'src/scenes/letseatmanager/restaurantDashboard/FilterReports';
import { AverageTimeConversionPercentageReport } from 'src/scenes/letseatmanager/restaurantDashboard/generalEcommerceReport/AverageTimeConversionPercentageReport';
import { ConversionPercentageReport } from 'src/scenes/letseatmanager/restaurantDashboard/generalEcommerceReport/ConversionPercentageReport';
import { CustomerOriginReport } from 'src/scenes/letseatmanager/restaurantDashboard/generalEcommerceReport/CustomerOriginReport';
import { DeviceTypeReport } from 'src/scenes/letseatmanager/restaurantDashboard/generalEcommerceReport/DeviceTypeReport';
import { ReportIndicator } from 'src/scenes/letseatmanager/restaurantDashboard/ReportIndicator';
import { ReportSectionEmptyState } from 'src/scenes/letseatmanager/restaurantDashboard/ReportSectionEmptyState';
import { useFormatAsRestaurantCurrencyNumber } from 'src/services/restaurant/useFormatAsRestaurantCurrencyNumber';
import { useFormatDateToRestaurantTimeZone } from 'src/utils/react/useFormatDateToRestaurantTimeZone';
import { useLoadApi } from 'src/utils/react/useLoadApi';
import { useSelector } from 'src/utils/react/useSelector';

export function GeneralEcommerceReport(): React.ReactElement {
    const classes = useStyles();
    const formatAsCurrencyNumber = useFormatAsRestaurantCurrencyNumber();

    const restaurantIds = useSelector((state) => state.app.restaurantIds);
    const period = useSelector((state) => state.app2.filterReportsState.period);
    const salesType = useSelector((state) => state.app.salesTypeFilter);

    const formatDateToRestaurantTimeZone = useFormatDateToRestaurantTimeZone();

    const [loading, reports] = useLoadApi(
        getGeneralEcommerceReportApi,
        {
            restaurantIds,
            fromDate: moment(formatDateToRestaurantTimeZone(period?.from)).toISOString(),
            toDate: moment(formatDateToRestaurantTimeZone(period?.to)).toISOString(),
            salesType: salesType,
        },
        { dependencies: [period, restaurantIds, salesType] },
    );

    return (
        <div className={classes.reportContainer}>
            <FilterReports />
            {loading && <UpdatingContentProgress />}
            {reports && (
                <div>
                    <div className={classes.kpiSection}>
                        <ReportIndicator label={translate('Total Sales')} value={formatAsCurrencyNumber(reports?.generalEcommerceReportKpis.totalSales ?? '0')} Icon={<MoneyBagIcon />} />
                        <ReportIndicator label={translate('Orders')} value={reports?.generalEcommerceReportKpis.totalOrders ?? '0'} Icon={<OrderBagIcon />} />
                        <ReportIndicator
                            label={translate('Conversion percentage')}
                            value={`${reports?.generalEcommerceReportKpis.totalConversionPercentage ?? '0'}%`}
                            Icon={<ConversionPercentageIcon />}
                        />
                        <ReportIndicator label={translate('New clients')} value={reports?.generalEcommerceReportKpis.totalNewClients ?? '0'} Icon={<DinersIcon />} />
                    </div>
                    <div className={classes.chartsContainer}>
                        <ConversionPercentageReport conversionPercentageReport={reports?.conversionPercentageReport} />
                        <AverageTimeConversionPercentageReport averageTimeConversionPercentageReport={reports?.averageTimeConversionPercentageReport} />
                        <DeviceTypeReport deviceTypeReport={reports?.deviceTypeReport} />
                        <CustomerOriginReport customerOriginReport={reports?.customerOriginReport} />
                    </div>
                </div>
            )}
            {!reports && !loading && <ReportSectionEmptyState />}
        </div>
    );
}

const useStyles = makeStyles((theme) => ({
    reportContainer: {
        marginBottom: 80,
    },
    kpiSection: {
        width: '100%',
        display: 'grid',
        gridTemplateColumns: 'repeat(auto-fit, minmax(150px, 1fr))',
        gap: 12,
        margin: '24px 10px 24px 0px',
    },
    chartsContainer: {
        display: 'grid',
        gridTemplateColumns: 'repeat(auto-fit, minmax(45%, 1fr))',
        gap: 16,
    },
}));
