import type { PageContextType } from 'src/components/Page';
import { LogEventTypes } from 'src/constants/LogEventType';
import { createUiInteractionLogEvent } from 'src/services/logEvent/createUiInteractionLogEvent';
import { normalizeUiStackTrace } from 'src/services/logEvent/normalizeUiStackTrace';

export function createUserClickedSelectOptionLogEvent({ pageContext, label, name, selectedValue }: Params): void {
    createUiInteractionLogEvent({
        logEventType: LogEventTypes.USER_CLICKED_SELECT_OPTION,
        message: `select ${label || name} changed with value ${selectedValue}`,
        details: `${pageContext.stackTrace}/${normalizeUiStackTrace(`select_${label || name}`)}`,
        data: {
            selectedValue,
            selectId: normalizeUiStackTrace(`select_${label || name}`),
            pathId: `${pageContext.stackTrace}/${normalizeUiStackTrace(`select_${label || name}`)}`,
            stackTrace: pageContext.stackTrace,
        },
    });
}

type Params = {
    label?: string;
    name: string;
    selectedValue: string;
    pageContext: PageContextType;
};
