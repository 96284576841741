import * as React from 'react';
import { removePaymentTerminalApi } from 'src/api/letseatmanager/paymentTerminal/removePaymentTerminalApi';
import { RemoveDialog } from 'src/components/dialog/RemoveDialog';
import { translate } from 'src/i18n/translate';
import type { PaymentTerminalVm } from 'src/types/PaymentTerminalVm';
import { alertKnownErrorOrSomethingWentWrong } from 'src/utils/alert/alertKnownErrorOrSomethingWentWrong';
import { useSelector } from 'src/utils/react/useSelector';

export function RemovePaymentTerminalDialog({ open, paymentTerminal, onClose, onSuccess }: Props): React.ReactElement {
    let deviceGroups = useSelector((state) => state.app.deviceGroups);
    deviceGroups = deviceGroups.filter((device) => {
        return device.paymentTerminalIds?.includes(paymentTerminal?.paymentTerminalId!);
    });

    const removePaymentTerminal = async () => {
        if (!paymentTerminal) return;

        const response = await removePaymentTerminalApi({ paymentTerminalId: paymentTerminal?.paymentTerminalId });
        if (!response.ok) {
            return alertKnownErrorOrSomethingWentWrong(response);
        }
        onSuccess?.();
    };

    return (
        <RemoveDialog
            open={open}
            title={translate('Remove paymentTerminal @deviceName', { deviceName: paymentTerminal?.deviceName })}
            subtitle={translate('Are you sure you want to continue? The terminal is link to @deviceGroups device groups', { deviceGroups: deviceGroups.length })}
            cancelButtonText={translate('Cancel')}
            removeButtonText={translate('Yes, remove')}
            onSubmit={removePaymentTerminal}
            onClose={onClose}
        />
    );
}

type Props = {
    open: boolean;
    paymentTerminal?: PaymentTerminalVm;
    onClose: any;
    onSuccess?: any;
};
