import * as React from 'react';
import { FormAutocompleteMultiple } from 'src/components/form/FormAutocompleteMultiple';
import type { Variant } from 'src/components/form/FormTypes';
import { Psp, Psps } from 'src/constants/Psp';
import { translate } from 'src/i18n/translate';

export function FormPspsAutocomplete({ name, label, placeholder, helperText, defaultValue, disabled, required, variant, InputProps }: Props): React.ReactElement {
    return (
        <FormAutocompleteMultiple
            name={name}
            label={label}
            placeholder={placeholder}
            helperText={helperText}
            defaultValue={defaultValue as any}
            options={Object.values(Psps).map((psp) => ({ label: translate(psp), value: psp }))}
            disabled={disabled}
            required={required}
            variant={variant}
            InputProps={InputProps}
        />
    );
}

type Props = {
    name: string;
    label?: string;
    placeholder?: string;
    helperText?: string;
    defaultValue?: Array<Psp>;
    disabled?: boolean;
    required?:
        | boolean
        | {
              value: number;
              message: string;
          };
    variant?: Variant;
    InputProps?: any;
};
