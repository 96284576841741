import { makeStyles } from '@material-ui/core';
import * as React from 'react';
import { Dialog } from 'src/components/Dialog';

export function DriverIdImageDialog({ open, driverIdPhoto, onClose }: Props): React.ReactElement | null {
    const classes = useStyles();

    if (!open) return null;

    return (
        <Dialog open={open} onClose={onClose} classes={{ dialog: classes.container }}>
            <img className={classes.idImageContainer} src={driverIdPhoto} />
        </Dialog>
    );
}

const useStyles = makeStyles((theme) => ({
    container: {
        width: '75%',
        height: '65%',
        [theme.breakpoints.up('md')]: {
            width: '80%',
            height: '75%',
        },
        [theme.breakpoints.up('lg')]: {
            width: '90%',
            height: '85%',
        },
        minWidth: '600px',
        minHeight: '500px',
        maxWidth: '950px',
        maxHeight: '750px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    idImageContainer: {
        width: '100%',
        height: '100%',
        borderRadius: 10,
        margin: 5,
    },
}));

type Props = {
    open: boolean;
    driverIdPhoto?: string;
    onClose?: any;
};
