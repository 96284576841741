import moment from 'moment-timezone';
import { BuyOrderStatuses } from 'src/constants/BuyOrderStatus';
import { TimeZones } from 'src/constants/TimeZone';
import { UnitsOfMeasurement } from 'src/constants/UnitOfMeasurement';
import type { BuyOrderVm } from 'src/types/BuyOrderVm';
import { fixedUuid } from 'src/utils/uuid/fixedUuid';

export const buyOrderTicketDemo: BuyOrderVm = {
    buyOrderId: fixedUuid('buyOrderId'),
    shortBuyOrderId: '8EC101',
    consecutiveBuyOrderId: '00014',
    restaurantId: fixedUuid('restaurantId'),
    buyOrderStatus: BuyOrderStatuses.COMPLETED,
    supplierId: fixedUuid('supplierId'),
    supplierBusinessName: 'El Mejor S.A.',
    supplies: [
        {
            supplyId: fixedUuid('supplyId1'),
            supplyName: 'Jugo Jamaica 330ML',
            unitOfMeasurement: UnitsOfMeasurement.PIECE,
            quantity: '8',
            price: '150',
        },
        {
            supplyId: fixedUuid('supplyId2'),
            supplyName: 'Jugo Naranja 330ML',
            unitOfMeasurement: UnitsOfMeasurement.PIECE,
            quantity: '4',
            price: '150',
        },
        {
            supplyId: fixedUuid('supplyId3'),
            supplyName: 'Jugo Limon 330ML',
            unitOfMeasurement: UnitsOfMeasurement.PIECE,
            quantity: '6',
            price: '150',
        },
        {
            supplyId: fixedUuid('supplyId4'),
            supplyName: 'Carne de res',
            unitOfMeasurement: UnitsOfMeasurement.KILOGRAM,
            quantity: '1',
            price: '600',
        },
    ],
    total: '3300',
    managerUserName: 'manager@mitienda.mx',
    createdAt: moment.tz('2023-05-01T12:32', TimeZones.AMERICA_MONTERREY).toDate(),
    modifiedAt: moment.tz('2023-05-03T16:43', TimeZones.AMERICA_MONTERREY).toDate(),
};
