import { makeStyles } from '@material-ui/core';
import * as React from 'react';
import { CategorySummary } from 'src/api/letseatmanager/kitchen/getKitchenOrdersApi';
import { Text } from 'src/components/Text';
import { translate } from 'src/i18n/translate';
import { classNames } from 'src/utils/react/classNames';
import { useSelector } from 'src/utils/react/useSelector';

export function KitchenPreparingOrdersSummary(): React.ReactElement | null {
    const classes = useStyles();

    const kitchenOrdersSummary = useSelector((state) => state.app2.kitchenOrdersSummary);
    const preparingKitchenOrders = useSelector((state) => state.app2.preparingKitchenOrders);

    if (!kitchenOrdersSummary) return null;

    return (
        <div className={classes.container}>
            <div className={classes.title}>{translate('@totalOrders orders in kitchen', { totalOrders: preparingKitchenOrders?.length ?? 0 })}</div>
            {kitchenOrdersSummary.categories?.map((category: CategorySummary) => (
                <div key={category.categoryName}>
                    <Text variant='semiBold' className={classes.categoryName}>
                        {category.categoryName}
                    </Text>
                    {category.orderItems.map((item, idx) => (
                        <div key={`${item.name}-${idx}`} className={classNames(classes.separatedText)}>
                            <div>{item.name}</div>
                            <div>{item.quantity}</div>
                        </div>
                    ))}
                </div>
            ))}
        </div>
    );
}

const useStyles = makeStyles((theme) => ({
    container: {
        position: 'fixed',
        right: 0,
        top: 0,
        fontFamily: theme.typography.regular,
        backgroundColor: theme.palette.surface.invert,
        width: 220,
        color: theme.palette.text.invert,
        fontSize: 14,
        fontWeight: 400,
        overflowY: 'scroll',
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        gap: 10,
        [theme.breakpoints.up('md')]: {
            fontSize: 16,
            width: 310,
        },
    },
    separatedText: {
        padding: 10,
        display: 'flex',
        flexDirection: 'row',
        width: '100%',
        justifyContent: 'space-between',
    },
    categoryName: {
        display: 'flex',
        width: 220,
        color: theme.palette.text.invert,
        padding: '8px 12px',
        backgroundColor: theme.palette.surface.heavyContrast,
        [theme.breakpoints.up('md')]: {
            width: 320,
        },
    },
    title: {
        padding: '20px 28px',
        fontWeight: 700,
        fontSize: 16,
        [theme.breakpoints.up('sm')]: {
            fontSize: 18,
        },
    },
}));
