import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import { Button, Dialog, DialogActions } from '@pidedirecto/ui';
import { Form, FormCurrencyNumberStringField } from '@pidedirecto/ui/form';
import { useForm } from '@pidedirecto/ui/hooks';
import { BigNumber } from 'bignumber.js';
import { useState } from 'react';
import * as React from 'react';
import { openCashRegisterApi } from 'src/api/letseatmanager/pos/openCashRegisterApi';
import { translate } from 'src/i18n/translate';
import { posReducer } from 'src/reducers/posReducer';
import { CashRegisterPosBusinessDayVm } from 'src/types/PosBusinessDayVm';
import { alertKnownErrorOrSomethingWentWrong } from 'src/utils/alert/alertKnownErrorOrSomethingWentWrong';
import { useAction } from 'src/utils/react/useAction';
import { useSelector } from 'src/utils/react/useSelector';

export function OpenCashRegisterDialog(): React.ReactElement {
    const classes = useStyles();
    const form = useForm();

    const [loading, setLoading] = useState(false);

    const restaurantId = useSelector((state) => state.app.restaurantId);
    const openedPosBusinessDay = useSelector((state) => state.pos.openedPosBusinessDay);
    const cashRegister = useSelector((state) => state.pos.cashRegister);
    const open = useSelector((state) => state.pos.openCashRegisterDialogState.open);
    const initialAmount = useSelector((state) => state.pos.openCashRegisterDialogState.initialAmount);
    const onSuccess = useSelector((state) => state.pos.openCashRegisterDialogState.onSuccess);
    const avoidSkip = useSelector((state) => state.pos.openCashRegisterDialogState.avoidSkip);
    const pinCodeUserSignedIn = useSelector((state) => state.authentication.pinCodeUserSignedIn);
    const openedCashRegisterPosBusinessDay = useSelector((state) => state.pos.openedCashRegisterPosBusinessDay);
    const country = useSelector((state) => state.app.restaurant?.country);

    const setCashRegisterOpen = useAction(posReducer.actions.setCashRegisterOpen);
    const setOpenedCashRegister = useAction(posReducer.actions.setOpenedCashRegister);
    const setOpenCashRegisterDialogState = useAction(posReducer.actions.setOpenCashRegisterDialogState);

    const userOpenedCashRegister = openedPosBusinessDay?.cashRegisterPosBusinessDays?.find(
        (cashRegisterPosBusinessDay: CashRegisterPosBusinessDayVm) =>
            cashRegisterPosBusinessDay.assignedToManagerUserId === cashRegister?.openedByManagerUserId || (pinCodeUserSignedIn?.managerUserId && cashRegisterPosBusinessDay.opened),
    );
    const hasCashRegisterPosBusinessDay = openedCashRegisterPosBusinessDay ?? userOpenedCashRegister;

    const handleClose = () => setOpenCashRegisterDialogState({ open: false });

    const handleSubmit = async (form: any) => {
        if (loading) return;
        if (BigNumber(form.amount).isNegative()) return;

        setLoading(true);
        const response = await openCashRegisterApi({
            restaurantId,
            initialAmount: form.amount.toString(),
            cashRegisterPosBusinessDayId: hasCashRegisterPosBusinessDay?.cashRegisterPosBusinessDayId,
            managerUserId: hasCashRegisterPosBusinessDay?.assignedToManagerUserId ?? pinCodeUserSignedIn?.managerUserId,
        });
        setLoading(false);

        if (!response.ok) {
            handleClose();
            alertKnownErrorOrSomethingWentWrong(response);
            return;
        }

        setCashRegisterOpen(true);
        setOpenedCashRegister(response?.data);
        handleClose();
        onSuccess?.();
    };

    return (
        <Dialog open={open} title={translate('Open New Cash Register')}>
            <Form onSubmit={handleSubmit} form={form}>
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <FormCurrencyNumberStringField name='amount' disabled={loading} label={translate('New cash register initial cash')} defaultValue={initialAmount} required country={country} />
                    </Grid>
                </Grid>
                <DialogActions className={avoidSkip ? classes.buttonCentered : ''}>
                    {!avoidSkip && (
                        <Button variant='secondary' onClick={handleClose} disabled={loading}>
                            {translate('Cancel')}
                        </Button>
                    )}
                    <Button type='submit' disabled={loading}>
                        {translate('Open')}
                    </Button>
                </DialogActions>
            </Form>
        </Dialog>
    );
}

const useStyles = makeStyles((theme) => ({
    form: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    buttonCentered: {
        justifyContent: 'center',
        alignItems: 'center',
        minWidth: 400,
    },
}));

type Props = {
    open: boolean;
    onClose: any;
    onSuccess?: any;
    initialAmount?: string;
};
