import { makeStyles } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import { useState } from 'react';
import * as React from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import { CashRegisterCustomReasons } from 'src/api/letseatmanager/theRestaurant/getTheRestaurantApi';
import { Button } from 'src/components/Button';
import { Tooltip } from 'src/components/Tooltip';
import { translate } from 'src/i18n/translate';
import { CloseIcon } from 'src/icons/CloseIcon';
import { AddCashRegisterWithdrawCustomReasonDialog } from 'src/scenes/letseatmanager/theRestaurant/cashRegister/AddCashRegisterWithdrawCustomReasonDialog';
import { RemoveCashRegisterWithdrawCustomReasonDialog } from 'src/scenes/letseatmanager/theRestaurant/cashRegister/RemoveCashRegisterWithdrawCustomReasonDialog';
import { CashRegisterCustomReason } from 'src/types/Id';

export function CashRegisterWithdrawReasonsSettings({ onSuccess }: Props): React.ReactElement {
    const { control } = useFormContext();
    const classes = useStyles();

    const cashRegisterWithdrawReasons = useWatch<CashRegisterCustomReasons>({ name: 'cashRegisterWithdrawReasons', control });
    const [openAddCashRegisterWithdrawReason, setOpenAddCashRegisterWithdrawReason] = useState(false);
    const [openRemoveCashRegisterWithdrawReasonState, setRemoveAddCashRegisterWithdrawReasonState] = useState<{ open: boolean; reason: CashRegisterCustomReason | undefined }>({
        open: false,
        reason: undefined,
    });

    const openRemoveCashRegisterWithdrawReason = (state: { open: boolean; reason: any }) => setRemoveAddCashRegisterWithdrawReasonState(state);

    const closeAddCashRegisterWithdrawReason = () => setOpenAddCashRegisterWithdrawReason(false);
    const closeRemoveCashRegisterWithdrawReason = () => setRemoveAddCashRegisterWithdrawReasonState({ open: false, reason: undefined });

    return (
        <>
            <AddCashRegisterWithdrawCustomReasonDialog open={openAddCashRegisterWithdrawReason} onClose={closeAddCashRegisterWithdrawReason} onSuccess={onSuccess} />
            <RemoveCashRegisterWithdrawCustomReasonDialog
                open={openRemoveCashRegisterWithdrawReasonState.open}
                onSuccess={onSuccess}
                reason={openRemoveCashRegisterWithdrawReasonState.reason}
                onClose={closeRemoveCashRegisterWithdrawReason}
            />
            <div className={classes.section}>
                <h2 className={classes.subtitle}>
                    {translate('Custom Withdraw Reasons')}
                    <Tooltip
                        text={translate(
                            'Define the personalized deposit reasons, (for example, cash, customer accounts receivable, etc.) these will be used later when making a deposit in boxes, you can remove and add new ones',
                        )}
                    />
                </h2>
                <div className={classes.itemsContainer}>
                    {cashRegisterWithdrawReasons?.map((withdrawReason, index) => (
                        <div className={classes.cashRegisterCustomReasonItem} key={index}>
                            {withdrawReason.reason}
                            <Button icon onClick={() => openRemoveCashRegisterWithdrawReason({ open: true, reason: withdrawReason.reason })} classes={{ button: classes.iconButton }}>
                                <CloseIcon size={14} title={translate('Remove withdraw reason')} />
                            </Button>
                        </div>
                    ))}
                </div>
                <Button outlined onClick={() => setOpenAddCashRegisterWithdrawReason(true)}>
                    <AddIcon />
                    {translate('Add Withdraw Reason')}
                </Button>
            </div>
        </>
    );
}

const useStyles = makeStyles((theme) => ({
    subtitle: {
        fontFamily: theme.typography.medium,
        fontSize: 18,
        color: theme.palette.secondary.contrastText,
        margin: 0,
        display: 'flex',
        flexDirection: 'row',
    },
    section: {
        width: '100%',
        borderRadius: 5,
        border: `1px solid ${theme.palette.secondary.dark}`,
        display: 'flex',
        flexDirection: 'column',
        padding: 15,
        gap: 20,
    },
    itemsContainer: {
        display: 'flex',
        width: '100%',
        flexWrap: 'wrap',
        flexDirection: 'row',
        alignItems: 'center',
        gap: 10,
    },
    cashRegisterCustomReasonItem: {
        fontFamily: theme.typography.medium,
        fontSize: 15,
        minWidth: 140,
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        border: '1px solid #676060B2',
        borderRadius: 6,
        padding: '5px 10px',
        gap: 5,
        height: '100%',
    },
    iconButton: {
        width: 'fit-content',
        height: 'fit-content',
        borderRadius: '50%',
        minHeight: 26,
        padding: 0,
    },
}));

type Props = {
    onSuccess: any;
};
