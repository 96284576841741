import { makeStyles } from '@material-ui/core';
import { BigNumber } from 'bignumber.js';
import { useState } from 'react';
import * as React from 'react';
import { Accordion } from 'src/components/Accordion';
import { PaymentMethods } from 'src/constants/PaymentMethod';
import { translate } from 'src/i18n/translate';
import { PosBusinessDayReportIndicator } from 'src/scenes/letseatmanager/posBusinessDayReport/PosBusinessDayReportIndicator';
import { useFormatAsRestaurantCurrencyNumber } from 'src/services/restaurant/useFormatAsRestaurantCurrencyNumber';
import type { CashRegisterPosBusinessDayReportVm } from 'src/types/CashRegisterPosBusinessDayReportVm';
import { PaymentMethodReport, type PosBusinessDayReportVm } from 'src/types/PosBusinessDayReportVm';
import { useSelector } from 'src/utils/react/useSelector';

export function PosBusinessDayPaymentMethodsSummary({ posBusinessDay }: Props): React.ReactElement {
    const classes = useStyles();
    const formatAsCurrencyNumber = useFormatAsRestaurantCurrencyNumber();

    const posOnlyCashInCashRegisterReportEnabled = useSelector((state) => state.pos.context?.posOnlyCashInCashRegisterReportEnabled);

    const [showPaymentMethodsDetails, setShowPaymentMethodsDetails] = useState(false);

    const lastCashRegisterShiftReport =
        posBusinessDay?.cashRegistersSummaries[
            (posBusinessDay as any)?.cashRegisterPosBusinessDayPaymentMethodReports ? (posBusinessDay as any)?.cashRegisterPosBusinessDayPaymentMethodReports.length - 1 : 0
        ];

    const paymentMethodCashReported = lastCashRegisterShiftReport?.paymentMethodReports?.find((paymentMethodReport: PaymentMethodReport) => paymentMethodReport.paymentMethod === PaymentMethods.CASH);
    const paymentMethodCreditCardReported = lastCashRegisterShiftReport?.paymentMethodReports?.find(
        (paymentMethodReport: PaymentMethodReport) => paymentMethodReport.paymentMethod === PaymentMethods.CREDIT_CARD && !paymentMethodReport.app,
    );
    const paymentMethodOthersReported =
        lastCashRegisterShiftReport?.paymentMethodReports?.filter((paymentMethodReport: PaymentMethodReport) => {
            const isCardPaymentMethodReport = paymentMethodReport.paymentMethod === PaymentMethods.CREDIT_CARD && !paymentMethodReport.app;
            const isCashPaymentMethodReport = paymentMethodReport.paymentMethod === PaymentMethods.CASH && !paymentMethodReport.app;
            return !(isCardPaymentMethodReport || isCashPaymentMethodReport);
        }) ?? [];

    return (
        <div className={classes.container}>
            <div className={classes.infoContainer} style={{ marginTop: 50 }}>
                <PosBusinessDayReportIndicator value={formatAsCurrencyNumber(posBusinessDay.cashRegistersSummaries[0].initialAmount)} label={translate('Initial Amount')} outlined />
                <PosBusinessDayReportIndicator value={formatAsCurrencyNumber(paymentMethodCashReported?.amount || '0')} label={translate('Reported cash')} outlined />
                <PosBusinessDayReportIndicator value={formatAsCurrencyNumber(paymentMethodCashReported?.expectedAmount || '0')} label={translate('Expected cash')} outlined />
                <PosBusinessDayReportIndicator
                    value={formatAsCurrencyNumber(
                        BigNumber(paymentMethodCashReported?.amount ?? 0)
                            .minus(paymentMethodCashReported?.expectedAmount ?? 0)
                            .toString(),
                    )}
                    label={translate('Difference')}
                    outlined
                />
            </div>
            <Accordion open={showPaymentMethodsDetails}>
                <div className={classes.infoContainer}>
                    <div className={classes.emptySpace}></div>
                    <PosBusinessDayReportIndicator value={formatAsCurrencyNumber(paymentMethodCreditCardReported?.amount || '0')} label={translate('Reported card')} outlined />
                    <PosBusinessDayReportIndicator value={formatAsCurrencyNumber(paymentMethodCreditCardReported?.expectedAmount || '0')} label={translate('Expected card')} outlined />
                    <PosBusinessDayReportIndicator
                        value={formatAsCurrencyNumber(
                            BigNumber(paymentMethodCreditCardReported?.amount ?? 0)
                                .minus(paymentMethodCreditCardReported?.expectedAmount ?? 0)
                                .toString(),
                        )}
                        label={translate('Difference')}
                        outlined
                    />
                    <div className={classes.emptySpaceForMobile}></div>

                    {paymentMethodOthersReported.map((paymentMethodOtherReported, idx) => (
                        <React.Fragment key={idx}>
                            <div className={classes.emptySpace}></div>
                            <PosBusinessDayReportIndicator
                                value={formatAsCurrencyNumber(paymentMethodOtherReported?.amount || '0')}
                                label={translate('Reported @paymentMethod', { paymentMethod: paymentMethodOtherReported.paymentMethod })}
                                outlined
                            />
                            <PosBusinessDayReportIndicator
                                value={formatAsCurrencyNumber(paymentMethodOtherReported?.expectedAmount || '0')}
                                label={translate('Expected @paymentMethod', { paymentMethod: paymentMethodOtherReported.paymentMethod })}
                                outlined
                            />
                            <PosBusinessDayReportIndicator
                                value={formatAsCurrencyNumber(
                                    BigNumber(paymentMethodOtherReported?.amount ?? 0)
                                        .minus(paymentMethodOtherReported?.expectedAmount ?? 0)
                                        .toString(),
                                )}
                                label={translate('Difference')}
                                outlined
                            />
                            <div className={classes.emptySpaceForMobile}></div>
                        </React.Fragment>
                    ))}
                </div>
            </Accordion>
            {!posOnlyCashInCashRegisterReportEnabled && (
                <span className={classes.accordionButton} onClick={() => setShowPaymentMethodsDetails(!showPaymentMethodsDetails)}>
                    {showPaymentMethodsDetails ? translate('See less') : translate('See more')}
                </span>
            )}
        </div>
    );
}

const useStyles = makeStyles((theme) => ({
    container: {
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
    },
    infoContainer: {
        width: '100%',
        display: 'grid',
        gridTemplateColumns: 'repeat(4, 1fr)',
        rowGap: 10,
        columnGap: 20,
        marginBottom: 20,
        [theme.breakpoints.down('sm')]: {
            gridTemplateColumns: 'repeat(2, 1fr)',
        },
    },
    accordionButton: {
        color: theme.palette.primary.main,
        fontFamily: theme.typography.regular,
        fontSize: 15,
        textDecoration: 'underline',
        cursor: 'pointer',
        width: 'fit-content',
        margin: '0 0 0 auto',
    },
    emptySpace: {
        minWidth: 150,
        [theme.breakpoints.down('sm')]: {
            display: 'none',
        },
    },
    emptySpaceForMobile: {
        display: 'none',
        [theme.breakpoints.down('sm')]: {
            minWidth: 150,
            display: 'block',
            marginBottom: 20,
        },
    },
}));

type Props = {
    posBusinessDay: PosBusinessDayReportVm | CashRegisterPosBusinessDayReportVm;
};
