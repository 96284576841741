import Grid from '@material-ui/core/Grid';
import * as React from 'react';
import { FormSwitch } from 'src/components/form/FormSwitch';
import { SectionTitle } from 'src/components/title/SectionTitle';
import { PosBusinessDaySections } from 'src/constants/PosBusinessDaySections';
import { translate } from 'src/i18n/translate';

export function CashRegisterMovementsInfoSettings({ disabled }: Props): React.ReactElement {
    return (
        <section>
            <SectionTitle title={translate('Cash Register Movements Information')} />
            <Grid container xs={12}>
                {/* @ts-ignore */}
                <Grid item xs={12} md={6} columnSpacing={2}>
                    <FormSwitch
                        disabled={disabled}
                        name={PosBusinessDaySections.CASH_REGISTER_MOVEMENTS}
                        label={translate(`PosBusinessDaySections.${PosBusinessDaySections.CASH_REGISTER_MOVEMENTS}`)}
                    />
                </Grid>
            </Grid>
        </section>
    );
}

type Props = {
    disabled: boolean;
};
