export const OrderRejectReasonCategories = {
    ESTABLISHMENT: 'ESTABLISHMENT',
    CUSTOMER: 'CUSTOMER',
    OPERATION: 'OPERATION',
    TECH: 'TECH',
    OTHER: 'OTHER',
    RESTAURANT: 'RESTAURANT',
    RAPPICARGO: 'RAPPICARGO',
    WITHOUTASSIGNMENT: 'WITHOUTASSIGNMENT',
    EXTRA: 'EXTRA',
} as const;

export type OrderRejectReasonCategory = (typeof OrderRejectReasonCategories)[keyof typeof OrderRejectReasonCategories];
