import { IconButton, makeStyles } from '@material-ui/core';
import { Loader } from '@pidedirecto/ui';
import * as React from 'react';
import { useState } from 'react';
import { useContext } from 'react';
import { useForm } from 'react-hook-form';
import { createPrinterApi } from 'src/api/letseatmanager/printer/createPrinterApi';
import { Box } from 'src/components/Box';
import { Button } from 'src/components/Button';
import { Form } from 'src/components/form/Form';
import { FormCheckbox } from 'src/components/form/FormCheckbox';
import { Tooltip } from 'src/components/Tooltip';
import { CreatePrinterStep, CreatePrinterSteps } from 'src/constants/CreatePrinterStep';
import { translate } from 'src/i18n/translate';
import { BugIcon } from 'src/icons/BugIcon';
import { PrinterWizardContext, PrinterWizardProviderValues } from 'src/providers/PrinterWizardProvider';
import { usePrintPrinterInfo } from 'src/services/printer/usePrintPrinterInfo';
import { useReloadRestaurant } from 'src/services/restaurant/useReloadRestaurant';
import { alertKnownErrorOrSomethingWentWrong } from 'src/utils/alert/alertKnownErrorOrSomethingWentWrong';
import { isBluetoothPrinter } from 'src/utils/printer/isBluetoothPrinter';
import { useSelector } from 'src/utils/react/useSelector';
import { setRestaurantHasBluetoothPrintersInMobileApp } from 'src/utils/reactNative/setRestaurantHasBluetoothPrintersInMobileApp';

export function CreatePrinterWizardConfirmation({ openManagePrintersSection }: Props): React.ReactElement | null {
    const classes = useStyles();

    const { formValues, goBackWizardStep, createPrinterSteps, setActiveStep, activeCreatePrinterStep } = useContext<PrinterWizardProviderValues>(PrinterWizardContext);

    const form = useForm();

    const [loading, setLoading] = useState(false);

    const restaurantId = useSelector((state) => state.app.restaurantId);

    const [printPrinterInfo] = usePrintPrinterInfo();
    const { reloadRestaurant } = useReloadRestaurant();

    const skipStep = (step: CreatePrinterStep | string) =>
        createPrinterSteps.findIndex((currentStep) => step === currentStep) === createPrinterSteps.length - 1 || step === CreatePrinterSteps.DOWNLOAD_DRIVERS;

    const handleTest = async () => {
        await printPrinterInfo(formValues as any);
        alert(translate('A testing ticket was sent'));
    };

    const onSubmit = async (form: any) => {
        setLoading(true);
        const response = await createPrinterApi({
            restaurantId: restaurantId,
            printerType: formValues.printerType,
            printerContent: formValues.printerContent,
            deviceName: formValues.deviceName,
            externalPrinterId: formValues.externalPrinterId,
            serialNumber: formValues.serialNumber,
            ipAddress: formValues.ipAddress,
            port: formValues.port,
            printerBrand: formValues.printerBrand,
            disabled: form.disabled,
            printerPaperSize: formValues.printerPaperSize,
            channels: formValues.channels,
        });

        if (!response.ok) {
            alertKnownErrorOrSomethingWentWrong(response);
            setLoading(false);
            return;
        }

        reloadRestaurant(restaurantId);
        setLoading(false);
        openManagePrintersSection();

        if (isBluetoothPrinter(formValues.printerType)) setRestaurantHasBluetoothPrintersInMobileApp(!form.disabled);
    };

    if (activeCreatePrinterStep !== CreatePrinterSteps.CONFIRMATION) return null;

    return (
        <Form form={form} onSubmit={onSubmit} className={classes.form}>
            <p className={classes.label}>{translate('Please make sure that all the information provided is correct')}</p>
            <Box className={classes.confirmBox}>
                <p>{translate('Configuration')}</p>
                <div className={classes.confirmation}>
                    <div>
                        {createPrinterSteps.map((step) => {
                            if (skipStep(step)) return null;
                            if (step === CreatePrinterSteps.CHANNELS && !formValues.channels?.length) return null;
                            return (
                                <div key={step}>
                                    <p>{translate(`CreatePrinterSteps.${step}`)}</p>
                                </div>
                            );
                        })}
                    </div>
                    <div>
                        <p>{translate(`PrinterTypes.${formValues.printerType}`)}</p>
                        <p>{formValues.deviceName}</p>
                        {formValues.ipAddress && <p>{formValues.ipAddress}</p>}
                        {formValues.port && <p>{formValues.port}</p>}
                        {formValues.serialNumber && <p>{formValues.serialNumber}</p>}
                        {formValues.printerBrand && <p>{translate(`PrinterBrands.${formValues.printerBrand}`)}</p>}
                        {!!formValues.channels?.length &&
                            formValues.channels.map((channel) => (
                                <p key={channel} className={classes.horizontalList}>
                                    {translate(`Apps.${channel}`)}
                                </p>
                            ))}
                        {formValues.printerPaperSize && <p>{translate(`PrinterPaperSizes.${formValues.printerPaperSize}`)}</p>}
                        <p>{translate(`PrinterContents.${formValues.printerContent ?? 'BOTH'}`)}</p>
                    </div>
                    <div>
                        {createPrinterSteps.map((step) => {
                            if (skipStep(step)) return null;
                            if (step === CreatePrinterSteps.CHANNELS && !formValues.channels?.length) return null;
                            return (
                                <p className={classes.editButton} key={step} onClick={() => setActiveStep(step)}>
                                    {translate('Edit')}
                                </p>
                            );
                        })}
                    </div>
                </div>
            </Box>
            <div className={classes.disabledContainer}>
                <FormCheckbox name='disabled' label={translate('Disable printer in this device')} defaultValue={false} />
                {formValues.serialNumber !== undefined && (
                    <Tooltip text={translate('Test printer')} classes={{ text: classes.tooltipText }}>
                        <IconButton onClick={handleTest} style={{ width: 45, height: 45 }}>
                            <BugIcon color={'#6C7076'} />
                        </IconButton>
                    </Tooltip>
                )}
            </div>
            <div className={classes.actionsContainer}>
                <Button secondary onClick={goBackWizardStep} type={'button'}>
                    {translate('Back')}
                </Button>
                <Button primary type={'submit'} disabled={loading}>
                    {loading ? <Loader size={12} loading={true} /> : translate('Create printer')}
                </Button>
                <p className={classes.enterText}>{translate('hit Enter ↩')}</p>
            </div>
        </Form>
    );
}

const useStyles = makeStyles((theme) => ({
    form: {
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        animation: '$appear 700ms ease',
    },
    label: {
        fontSize: 16,
        fontFamily: theme.typography.regular,
        color: '#0D3037',
        padding: 0,
    },
    helperText: {
        color: '#565B69',
        fontSize: 12,
        fontFamily: theme.typography.regular,
    },
    secondaryButton: {
        marginTop: 12,
        alignSelf: 'end',
    },
    actionsContainer: {
        display: 'flex',
        gap: 12,
        marginTop: 24,
        width: '100%',
        justifyContent: 'flex-end',
    },
    enterText: {
        fontFamily: theme.typography.regular,
        color: '#0D3037',
    },
    confirmation: {
        display: 'flex',
        justifyContent: 'space-between',
        gap: 24,
    },
    editButton: {
        color: theme.palette.primary.dark,
        '&:hover': {
            cursor: 'pointer',
            textDecoration: 'underline',
        },
        '&:focus': {
            outline: '1px solid #1A73F9',
        },
    },
    disabledContainer: {
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        marginTop: 12,
    },
    horizontalList: {
        display: 'inline-block',
        margin: 0,
        marginRight: 8,
    },
    confirmBox: {
        width: '100%',
        padding: '12px 24px',
    },
    tooltipText: {
        backgroundColor: '#616B79',
        left: '50%',
        top: '100%',
        transform: 'translateX(-50%)',
    },
    '@keyframes appear': {
        '0%': {
            opacity: 0,
            transform: 'translateY(100px)',
        },
        '100%': {
            opacity: 1,
            transform: 'translateY(0px)',
        },
    },
}));

type Props = {
    openManagePrintersSection: any;
};
