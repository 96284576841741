import { makeStyles } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import FilterListIcon from '@material-ui/icons/FilterList';
import * as React from 'react';
import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { findDriverLivePositionsByCityApi } from 'src/api/letseatadmin/driverLivePosition/findDriverLivePositionsByCityApi';
import { findOrdersOverviewLivePositionApi } from 'src/api/letseatadmin/orderLivePosition/findOrdersOverviewLivePositionApi';
import { app2 } from 'src/app2';
import { Form } from 'src/components/form/Form';
import { FormCitySelect } from 'src/components/form/FormCitySelect';
import { FormDeliveryStatusSelect } from 'src/components/form/FormDeliveryStatusSelect';
import { FormDriverStatusSelect } from 'src/components/form/FormDriverStatusSelect';
import { Cities } from 'src/constants/City';
import { translate } from 'src/i18n/translate';
import { liveDrivers } from 'src/reducers/liveDriversReducer';
import { useAction } from 'src/utils/react/useAction';
import { useLoadApi } from 'src/utils/react/useLoadApi';
import { useSelector } from 'src/utils/react/useSelector';

export function FiltersLiveDrivers({ handleOpen, open }: Props): React.ReactElement {
    const classes = useStyles();

    const form = useForm({
        mode: 'onChange',
        defaultValues: {
            city: '',
            statusDelivery: '',
            statusDriver: '',
        },
    });

    const cities = useSelector((state) => state.app2.cities);
    const driverStatus = useSelector((state) => state.liveDrivers.driverStatus);

    const setCities = useAction(app2.actions.setCities);
    const setDeliveryStatus = useAction(liveDrivers.actions.setDeliveryStatus);
    const setDriverStatus = useAction(liveDrivers.actions.setDriverStatus);
    const setOrdersOverview = useAction(liveDrivers.actions.setOrdersOverview);
    const setDriversOverview = useAction(liveDrivers.actions.setDriversOverview);

    useEffect(() => {
        return () => {
            setCities([]);
            setDeliveryStatus([]);
            setDriverStatus(undefined);
        };
    }, []);

    const [loadingOverview, ordersOverviewApi, refreshOrdersOverview] = useLoadApi(
        findOrdersOverviewLivePositionApi,
        { city: cities?.[0] ?? Cities.MONTERREY },
        { initialValue: {}, dependencies: [cities] },
    );

    const [loadingDriversOverview, driverOverview, refreshDriverPositions] = useLoadApi(
        findDriverLivePositionsByCityApi,
        { city: cities?.[0] ?? Cities.MONTERREY, filter: driverStatus },
        { initialValue: { drivers: [] }, dependencies: [cities, driverStatus] },
    );

    ordersOverviewApi && Object.keys(ordersOverviewApi).length !== 0 && setOrdersOverview(ordersOverviewApi);
    if (driverOverview && Object.keys(driverOverview).length > 1) {
        const { drivers, ...overview } = driverOverview;
        setDriversOverview(overview);
    }

    const handleChangeCity = (city: any) => {
        if (city?.length) {
            setCities([city]);
        } else {
            setCities([]);
        }
    };

    const handleChangeDeliveryStatus = (deliveryStatus: any) => {
        setDeliveryStatus(deliveryStatus ? [deliveryStatus] : []);
    };

    const handleChangeDriverStatus = (driverStatus: any) => {
        setDriverStatus(driverStatus);
    };

    return (
        <section className={classes.container}>
            <section className={classes.subContainer}>
                <IconButton onClick={handleOpen} className={classes.openFiltersIcon}>
                    {open && <ChevronLeftIcon />}
                    {!open && <ChevronRightIcon />}
                </IconButton>
                <FilterListIcon /> <Typography>{translate('Filters')}</Typography>
            </section>
            <section>
                <Form form={form} className={classes.selectFilters}>
                    <Grid item className={classes.filter}>
                        <FormCitySelect name='city' label={translate('City')} onChange={handleChangeCity} />
                    </Grid>
                    <Grid item className={classes.filter}>
                        <FormDeliveryStatusSelect name='deliveryStatus' label={translate('Status delivery')} onChange={handleChangeDeliveryStatus} />
                    </Grid>
                    <Grid item className={classes.filter}>
                        <FormDriverStatusSelect name='driverStatus' label={translate('Driver status')} onChange={handleChangeDriverStatus} />
                    </Grid>
                </Form>
            </section>
        </section>
    );
}

const useStyles = makeStyles((theme) => ({
    container: {
        height: '100%',
        width: '100%',
        display: 'flex',
        flexWrap: 'nowrap',
        justifyContent: 'flex-start',
        alignItems: 'center',
        marginBottom: 40,
        fontFamily: theme.typography.regular,
        [theme.breakpoints.down('sm')]: {
            flexWrap: 'wrap',
            padding: '0 24px',
        },
    },
    subContainer: {
        color: '#64d6b3',
        fontFamily: 'Poppins',
        fontSize: '18px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100%',
        marginRight: 40,
        [theme.breakpoints.down('sm')]: {
            height: '10%',
            marginTop: 4,
            width: '100%',
            justifyContent: 'flex-start',
        },
    },
    openFiltersIcon: {
        [theme.breakpoints.down('sm')]: {
            display: 'none',
        },
    },
    selectFilters: {
        display: 'flex',
        [theme.breakpoints.down('sm')]: {
            marginBottom: 10,
        },
    },
    filter: {
        marginRight: 20,
    },
    selectStyles: {
        border: '2px solid rgba(99, 214, 179, 1)',
        '&:not(.Mui-disabled):hover::before': {
            border: 'none',
        },
    },
    icon: {
        fill: 'rgba(99, 214, 179, 1)',
    },
    root: {
        fontWeight: 500,
    },
}));

type Props = {
    open: boolean;
    handleOpen: any;
};
