import { pidedirectoReportsMethod } from 'src/api/pidedirectoReportsApi';
import type { ApiSauceResponse } from 'src/api/types/ApiSauceResponse';
import type { RestaurantId } from 'src/types/Id';
import type { InventoryAdjustmentsReportVm } from 'src/types/InventoryAdjustmentsReportVm';

export async function getInventoryAdjustmentsReportApi(request: GetInventoryAdjustmentReportApiRequest): ApiSauceResponse<InventoryAdjustmentsReportVm> {
    console.log('getInventoryAdjustmentsReportApi', request);
    return pidedirectoReportsMethod('/getInventoryAdjustmentsReportApi', request);
}

type GetInventoryAdjustmentReportApiRequest = {
    restaurantIds: Array<RestaurantId>;
    fromDate: string;
    toDate: string;
};
