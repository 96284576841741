import { OrderType, OrderTypes } from 'src/constants/OrderType';
import { translate } from 'src/i18n/translate';

/**
 * @deprecated
 * TODO: This is a general translation, move translations of constant values to en.js and es.js instead and calls with translate(...)
 **/
export function translateOrderType(orderType: OrderType): string {
    if (!orderType) {
        return '';
    }
    switch (orderType) {
        case OrderTypes.TABLE_ORDER: {
            return translate('Table');
        }
        case OrderTypes.SELF_SERVICE_ORDER: {
            return translate('Take Away');
        }
        case OrderTypes.TAKE_AWAY_ORDER: {
            return translate('Take Away');
        }
        case OrderTypes.DELIVERY_ORDER: {
            return translate('Delivery');
        }
        case OrderTypes.PICKUP_STATION_ORDER: {
            return translate('Company');
        }
        default: {
            return translate('');
        }
    }
}
