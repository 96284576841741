import { letseatmanagerApiMethod } from 'src/api/letseatmanagerApi';
import type { ApiSauceResponse } from 'src/api/types/ApiSauceResponse';
import type { InventoryReportId, InventoryTransactionId } from 'src/types/Id';
import type { SupplyVm } from 'src/types/SupplyVm';

export async function getSupplyInventoryTransactionApi(request: GetSupplyReportsApiRequest): ApiSauceResponse<Array<SupplyInventoryTransactionVm>> {
    return letseatmanagerApiMethod('inventory/getSupplyInventoryTransactionApi', request);
}

type GetSupplyReportsApiRequest = {
    inventoryTransactionReportId: InventoryReportId;
};

export type SupplyInventoryTransactionVm = {
    inventoryTransactionId: InventoryTransactionId;
    inventoryReportId?: InventoryReportId;
    supply: SupplyVm;
    quantityInventoried: string;
    expectedQuantity: string;
    difference: string;
    createdAt: Date;
};
