import { useState } from 'react';
import { findCashRegisterTransactionsApi } from 'src/api/letseatmanager/pos/findCashRegisterTransactionsApi';
import { findPosBusinessDayCashRegisterTransactionsApi } from 'src/api/letseatmanager/pos/restaurantPosBusinessDay/findPosBusinessDayCashRegisterTransactionsApi';
import { RolePermissions } from 'src/constants/RolePermission';
import type { CashRegisterTransactionVm } from 'src/types/CashRegisterTransactionVm';
import { useSelector } from 'src/utils/react/useSelector';
import { useUserHasRolePermission } from 'src/utils/react/useUserHasRolePermissions';

export function useFetchCashRegisterTransactions(): {
    fetchCashRegisterTransactions: () => Promise<void>;
    transactions: Array<CashRegisterTransactionVm>;
    loading: boolean;
} {
    const [userHasRolePermission] = useUserHasRolePermission();

    const [transactions, setTransactions] = useState<Array<CashRegisterTransactionVm>>([]);
    const [loading, setLoading] = useState(false);

    const openedPosBusinessDay = useSelector((state) => state.pos.openedPosBusinessDay);
    const posMultipleCashRegistersEnabled = useSelector((state) => state.app.restaurant?.posMultipleCashRegistersEnabled);
    const openedCashRegister = useSelector((state) => state.pos?.openedCashRegister);

    const fetchCashRegisterTransactions = async () => {
        if (openedCashRegister) {
            setLoading(true);
            const response = await findCashRegisterTransactionsApi({ cashRegisterId: openedCashRegister?.cashRegisterId });
            setLoading(false);

            if (!response.ok) return;
            setTransactions(response.data);
            return;
        }

        if (openedPosBusinessDay && userHasRolePermission(RolePermissions.MANAGE_CASH_REGISTERS) && posMultipleCashRegistersEnabled) {
            const response = await findPosBusinessDayCashRegisterTransactionsApi({
                posBusinessDayId: openedPosBusinessDay?.posBusinessDayId,
            });

            if (!response.ok) return;

            setTransactions(response.data);
        }
    };

    return { fetchCashRegisterTransactions, transactions, loading };
}
