import { translate } from 'src/i18n/translate';

export function alertKnownErrorOrSomethingWentWrong(response?: any, message?: string): void {
    if (!response) {
        alert(`${!!message ? `${message}\n` : ''}Something went wrong`);
        return;
    }
    if (response.problem === 'NETWORK_ERROR') {
        alert(translate("A network problem has occurred. Check your computer's connection and try again. If it happens again, please contact support"));
        return;
    }
    if (response.data?.message) {
        alert(`${!!message ? `${message}\n` : ''}${translate(response.data.message)}\n(${response.status ?? ''}: ${response.problem ?? ''})`);
        return;
    }
    alert(`${!!message ? `${message}\n` : ''}Something went wrong (${response.status ?? ''}: ${response.problem ?? ''})`);
}
