import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableContainer from '@material-ui/core/TableContainer';
import * as React from 'react';
import type { RestaurantBillingUyVm } from 'src/api/letseatadmin/restaurantBillingUy/getRestaurantBillingUyApi';
import { TableHeaderCalculationUy } from 'src/scenes/letseatadmin/restaurantBillingUy/TableHeaderCalculationUy';
import { TableRowCalculationUy } from 'src/scenes/letseatadmin/restaurantBillingUy/TableRowCalculationUy';

export function ManualEarningDebtCalculationUy({ restaurantBillingUyVm }: Props): React.ReactElement {
    const classes = useStyles();

    return (
        <TableContainer component={Paper}>
            <Table className={classes.table} size='small'>
                <TableHeaderCalculationUy name={`Manual Earning Debt Calculation`} />
                <TableBody>
                    <TableRowCalculationUy name={`Debt At Start Of This Period`} amount={restaurantBillingUyVm.debtAtBeginningOfPeriodManualEarning} />
                    <TableRowCalculationUy minus name={`New Manual Earnings Debt`} amount={restaurantBillingUyVm.manualEarning} />
                    <TableRowCalculationUy minus name={`Invoiced`} amount={restaurantBillingUyVm.manualEarningInvoice} />
                    <TableRowCalculationUy name={`Debt After This Period`} amount={restaurantBillingUyVm.debtManualEarning} />
                </TableBody>
            </Table>
        </TableContainer>
    );
}

type Props = {
    restaurantBillingUyVm: RestaurantBillingUyVm;
};

const useStyles = makeStyles({
    table: {},
});
