import { letseatmanagerApiMethod } from 'src/api/letseatmanagerApi';
import type { ApiSauceResponse } from 'src/api/types/ApiSauceResponse';
import type { MenuCategoryId } from 'src/types/Id';
import type { MenuCategoryVm } from 'src/types/MenuCategoryVm';

export async function getMenuCategoryApi(request: GetMenuCategoryApiRequest): ApiSauceResponse<MenuCategoryVm> {
    return letseatmanagerApiMethod('menuCategory/getMenuCategoryApi', request);
}

export type GetMenuCategoryApiRequest = {
    menuCategoryId: MenuCategoryId;
};
