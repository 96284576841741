import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core/styles';
import * as React from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import { BannerImageType, BannerImageTypes } from 'src/constants/BannerImageType';
import { transformImageUrl } from 'src/utils/image/transformImageUrl';
import { getRestaurantImageBannerPattern } from 'src/utils/restaurant/getRestaurantImageBannerPattern';

export function RestaurantCard({ name, street, imageUrl, onClickName, onClickStreet, onClickImage, bannerImageType = BannerImageTypes.RESTAURANT }: Props): React.ReactElement {
    const classes = useStyles();

    const { control } = useFormContext();
    const restaurantColors = useWatch<Array<string>>({ name: 'restaurantColors', control });
    const defaultRestaurantColor = restaurantColors && restaurantColors[0];

    const getBackgroundPattern = (bannerImageType: BannerImageType) => {
        if (bannerImageType === BannerImageTypes.CUSTOM) return `url("${transformImageUrl(imageUrl, 133, 400)}")`;
        if (bannerImageType === BannerImageTypes.PREVIEW) return `url("${transformImageUrl(imageUrl, 400, 133)}")`;
        if (bannerImageType === BannerImageTypes.GENERAL) return `url("${getRestaurantImageBannerPattern(bannerImageType)}")`;
        return `url("${getRestaurantImageBannerPattern(bannerImageType)}")`;
    };

    return (
        <Paper
            className={classes.paper}
            onClick={onClickImage}
            style={{
                backgroundImage: getBackgroundPattern(bannerImageType),
                backgroundColor: defaultRestaurantColor,
            }}
        >
            <div className={classes.headerContainer}>
                <div>
                    <span className={classes.name} onClick={onClickName}>
                        {name}
                    </span>
                </div>
                <div>
                    <span className={classes.location}>
                        <span onClick={onClickStreet}>{street}</span>
                    </span>
                </div>
            </div>
        </Paper>
    );
}

const useStyles = makeStyles((theme) => ({
    paper: {
        marginBottom: '2px',
        backgroundPosition: 'center',
        color: 'white',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        height: '133px',
        width: '400px',
    },
    headerContainer: {
        padding: '10px 25px',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
    },
    name: {
        color: 'white',
        // fontWeight: 'bold',
        fontSize: '2.2rem',
        textShadow: '1px 1px 4px black',
    },
    location: {
        color: 'white',
        // fontWeight: 'bold',
        fontSize: '1.4rem',
        textShadow: '1px 1px 4px black',
    },
}));

type Props = {
    name?: string;
    street?: string;
    imageUrl?: string;
    onClickName?: any;
    onClickStreet?: any;
    onClickImage?: any;
    bannerImageType: BannerImageType;
};
