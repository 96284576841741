import Raven from 'raven-js';
import { APP_VERSION } from 'src/config/appVersion';
import { envENVIRONMENT } from 'src/env/envENVIRONMENT';
import { envSENTRY_DNS } from 'src/env/envSENTRY_DNS';
import { envSENTRY_ENABLED } from 'src/env/envSENTRY_ENABLED';

export class SentryService {
    static install() {
        if (!envSENTRY_ENABLED()) return;

        Raven.config(envSENTRY_DNS(), {
            version: APP_VERSION,
            autoBreadcrumbs: {
                xhr: true,
                console: true,
                dom: true,
                location: true,
            },
        } as any).install();
        Raven.setTagsContext({
            environment: envENVIRONMENT(),
            appName: 'letseatmanager',
            appVersion: APP_VERSION,
            platform: 'BROWSER',
        });
    }

    static setUsername(username: any) {
        if (!envSENTRY_ENABLED()) return;
        Raven.setUserContext({
            email: username + '@unknown.email',
            userID: username,
            username: username,
        });
    }

    static clearUsername() {
        if (!envSENTRY_ENABLED()) return;
        Raven.setUserContext();
    }

    static setRestaurantId(restaurantId: any) {
        if (!envSENTRY_ENABLED()) return;
        Raven.setTagsContext({
            restaurantId,
        });
    }

    static logInfoBreadcrumb(message: any, data: any) {
        if (!envSENTRY_ENABLED()) return;
        Raven.captureBreadcrumb({
            message,
            data,
        });
    }

    static logDebug(message: any, data: any) {
        if (!envSENTRY_ENABLED()) return;
        Raven.captureMessage(message, SentryService.createOptionsFrom('debug', data));
    }

    static logInfo(message: any, data: any) {
        if (!envSENTRY_ENABLED()) return;
        Raven.captureMessage(message, SentryService.createOptionsFrom('info', data));
    }

    static logWarning(message: any, data: any) {
        if (!envSENTRY_ENABLED()) return;
        Raven.captureMessage(message, SentryService.createOptionsFrom('warning', data));
    }

    static logError(message: any, data: any) {
        if (!envSENTRY_ENABLED()) return;
        Raven.captureMessage(message, SentryService.createOptionsFrom('error', data));
    }

    static createOptionsFrom(level: any, data: any): any {
        const options = {
            level,
        };
        if (data) {
            (options as any).extra = data;
        }
        return options;
    }
}
