import type { LanguageTag } from 'src/types/Id';

export function formatAsNumberNew(value?: string | number | null, format?: Format): string {
    if (value === undefined || value === null) {
        return '';
    }

    return Intl.NumberFormat(format?.language ?? 'es-MX', {
        style: 'decimal',
        minimumFractionDigits: format?.minimumFractionDigits ?? format?.fractionDigits,
        maximumFractionDigits: format?.maximumFractionDigits ?? format?.fractionDigits,
    }).format(value as any); // TODO: Create a test that verifies that this it is still possible to format strings since flow seems to complain
}

type Format = {
    language?: LanguageTag;
    fractionDigits?: number;
    minimumFractionDigits?: number;
    maximumFractionDigits?: number;
};
