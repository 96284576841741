import { useNotification } from '@pidedirecto/ui/hooks';
import { RestaurantContext } from 'src/api/letseatmanager/getAppContextApi';
import { PrintTypes } from 'src/constants/PrintType';
import { translate } from 'src/i18n/translate';
import { useMenuCategories } from 'src/services/menuCategory/useMenuCategories';
import { useMenuItems } from 'src/services/menuItem/useMenuItems';
import { createOrderCommandPrint } from 'src/services/printer/prints/createOrderCommandPrint';
import { usePrintContent } from 'src/services/printer/utils/usePrintContent';
import { DeviceGroupId, MenuCategoryId, MenuItemId } from 'src/types/Id';
import { ManagerMenuItemVm } from 'src/types/ManagerMenuItemVm';
import { MenuCategoryVm } from 'src/types/MenuCategoryVm';
import { OrderItemVm, OrderVm } from 'src/types/OrderVm';
import { PrinterVm } from 'src/types/PrinterVm';
import { isCommandPrinter } from 'src/utils/printer/IsCommandPrinter';
import { useSelector } from 'src/utils/react/useSelector';

export function usePrintOrderCommandDeviceManagement(): [(order: OrderVm, forceToPrint?: boolean) => Promise<void>] {
    const notification = useNotification();

    const internalUser = useSelector((state) => state.authentication.internalUser);
    const restaurant = useSelector((state) => state.app.restaurant);
    const deviceGroup = useSelector((state) => state.app.deviceGroup);
    const restaurants = useSelector((state) => state.app.restaurants);
    const restaurantPrinters = useSelector((state) => state.app.printers);
    const printers = restaurantPrinters?.filter((printer: PrinterVm) =>
        printer.deviceGroups?.some((deviceGroupToSearch: { deviceGroupId: DeviceGroupId; deviceGroupName: string }) => deviceGroupToSearch.deviceGroupId === deviceGroup?.deviceGroupId),
    );

    const { menuCategories } = useMenuCategories();
    const { menuItems } = useMenuItems();

    const { printContents, printContent } = usePrintContent();

    const printOrderCommandNewDeviceManagement = async (order: OrderVm, forceToPrint: undefined | boolean) => {
        const orderRestaurant = restaurants?.find((restaurant: RestaurantContext) => restaurant.restaurantId === order.restaurant?.restaurantId);

        if (printers.length === 0 && forceToPrint) {
            notification({ message: translate('No active printers to select') });
            return;
        }

        if (printers.length === 1 && forceToPrint) {
            await printContents([
                {
                    printer: printers[0],
                    print: {
                        printType: PrintTypes.COMMAND,
                        printerInstructions: createOrderCommandPrint({
                            order,
                            increaseCommandFontSizeEnabled: orderRestaurant?.increaseCommandFontSizeEnabled,
                            restaurant,
                            internalUser,
                            commandSections: orderRestaurant?.commandSections ?? [],
                        }),
                        numberOfPrints: orderRestaurant?.numberOfCommandPrints || restaurant.numberOfCommandPrints || 1,
                    },
                },
            ]);
            return;
        }

        const printRoutes = createPrinterRoutes(order);

        const availablePrinters = printRoutes?.filter((printerRoute) => !!printerRoute.printer);
        const unAvailablePrinter = printRoutes.find((printerRoute) => !printerRoute.printer);
        const commandPrinters = printers.filter((printer) => isCommandPrinter(printer.printerContent));

        const contentsToPrint = availablePrinters.map((availablePrinter) => ({
            printer: availablePrinter.printer!,
            print: {
                printType: PrintTypes.COMMAND,
                printerInstructions: createOrderCommandPrint({
                    order: availablePrinter.order,
                    restaurant,
                    internalUser,
                    increaseCommandFontSizeEnabled: orderRestaurant?.increaseCommandFontSizeEnabled,
                    commandSections: orderRestaurant?.commandSections ?? [],
                }),
                numberOfPrints: orderRestaurant?.numberOfCommandPrints || restaurant.numberOfCommandPrints || 1,
            },
        }));

        await printContents(contentsToPrint);

        if (!availablePrinters?.length && unAvailablePrinter && commandPrinters.length === 1) {
            const isPrinterCommandAvailableForOrderChannel = !commandPrinters[0]?.channels?.length || commandPrinters[0]?.channels.includes(order.app!);

            if (isPrinterCommandAvailableForOrderChannel) {
                await printContents([
                    {
                        printer: commandPrinters[0],
                        print: {
                            printType: PrintTypes.COMMAND,
                            printerInstructions: createOrderCommandPrint({
                                order: unAvailablePrinter.order,
                                restaurant,
                                internalUser,
                                increaseCommandFontSizeEnabled: orderRestaurant?.increaseCommandFontSizeEnabled,
                                commandSections: orderRestaurant?.commandSections ?? [],
                            }),
                            numberOfPrints: orderRestaurant?.numberOfCommandPrints || restaurant.numberOfCommandPrints || 1,
                        },
                    },
                ]);
                return;
            }
        }

        const hasCommandPrinterAvailableForOrderChannel = commandPrinters?.some((printer) => printer.channels?.includes(order.app!));
        if ((!availablePrinters?.length && unAvailablePrinter && !commandPrinters.length) || !hasCommandPrinterAvailableForOrderChannel) {
            return;
        }

        if (unAvailablePrinter) {
            await printContent({
                printType: PrintTypes.COMMAND,
                printerInstructions: createOrderCommandPrint({
                    order: unAvailablePrinter.order,
                    restaurant,
                    internalUser,
                    increaseCommandFontSizeEnabled: orderRestaurant?.increaseCommandFontSizeEnabled,
                    commandSections: orderRestaurant?.commandSections ?? [],
                }),
                numberOfPrints: orderRestaurant?.numberOfCommandPrints || restaurant.numberOfCommandPrints || 1,
            });
        }
    };

    const mapPrintersWithMenuItemIds = (orderItems: Array<OrderItemVm>, currentAvailablePrinters?: Array<AvailablePrinter>) => {
        const availablePrinters = orderItems?.reduce<Array<AvailablePrinter>>((printers: Array<AvailablePrinter>, orderItem) => {
            const menuItem: ManagerMenuItemVm | undefined = menuItems.find((menuItem) => menuItem.menuItemId === orderItem.menuItemId);
            if (!menuItem?.printerNames) {
                return printers;
            }
            for (const deviceName of menuItem.printerNames ?? []) {
                const currentPrinter = printers.find((printer) => printer.deviceName === deviceName);
                if (currentPrinter) {
                    currentPrinter.menuItemIds = [...currentPrinter.menuItemIds!, menuItem.menuItemId];
                    continue;
                }

                const printer = getPrinterInDeviceGroupByPrinterName(deviceName);
                if (!printer) continue;
                printers?.push({
                    ...printer,
                    menuItemIds: [menuItem.menuItemId],
                });
            }
            return printers;
        }, currentAvailablePrinters ?? []);
        return availablePrinters;
    };

    const mapPrintersWithMenuCategoryIds = (orderItems: Array<OrderItemVm>, currentAvailablePrinters?: Array<AvailablePrinter>) => {
        const availablePrinters = orderItems?.reduce<Array<AvailablePrinter>>((printers: Array<AvailablePrinter>, orderItem) => {
            const menuItemCategories: Array<MenuCategoryVm> = menuCategories.filter((menuCategory) => {
                if (orderItem.menuCategoryId) {
                    return menuCategory.menuCategoryId === orderItem.menuCategoryId;
                }

                return menuCategory.menuItemIds.includes(orderItem.menuItemId);
            });

            if (!menuItemCategories.length) return printers;

            for (const menuCategory of menuItemCategories) {
                for (const deviceName of menuCategory?.printerNames ?? []) {
                    const currentPrinter = printers.find((printer) => printer.deviceName === deviceName);
                    if (currentPrinter) {
                        currentPrinter.menuCategoryIds = [...(currentPrinter.menuCategoryIds ?? []), menuCategory.menuCategoryId];
                        continue;
                    }

                    const printer = getPrinterInDeviceGroupByPrinterName(deviceName);
                    if (!printer) continue;
                    printers?.push({
                        ...printer,
                        menuCategoryIds: [menuCategory.menuCategoryId],
                    });
                }
            }
            return printers;
        }, currentAvailablePrinters ?? []);
        return availablePrinters;
    };

    const createPrinterRoutes = (order: OrderVm) => {
        let availablePrinters = mapPrintersWithMenuItemIds([...order.orderItems, ...(order.cancelledItems ?? [])]);
        availablePrinters = mapPrintersWithMenuCategoryIds([...order.orderItems, ...(order.cancelledItems ?? [])], availablePrinters);

        const printerRoutes: Array<PrinterRoute> = [];
        for (const commandPrinter of availablePrinters) {
            if (commandPrinter.channels && !commandPrinter.channels?.includes(order?.app!)) continue;

            printerRoutes.push({
                printer: commandPrinter,
                order: mapOrderToPrint(order, commandPrinter),
            });
        }

        const itemsToPrint = printerRoutes?.flatMap((printerRoute) => printerRoute.order.orderItems);
        const cancelledItems = printerRoutes?.flatMap((printerRoute) => printerRoute.order.cancelledItems ?? []);
        const hasMissingOrderItems = order.orderItems.filter((orderItem) => !itemsToPrint.some((item) => item.menuItemId === orderItem.menuItemId));
        const hasMissingCancelledOrderItems = order.cancelledItems?.filter((cancelledItem) => !cancelledItems?.some((item) => item.menuItemId === cancelledItem.menuItemId));

        if (hasMissingOrderItems?.length || hasMissingCancelledOrderItems?.length) {
            printerRoutes.push({
                printer: undefined,
                order: { ...order, orderItems: hasMissingOrderItems, cancelledItems: hasMissingCancelledOrderItems, customers: undefined, courses: undefined },
            });
        }
        return printerRoutes;
    };

    const mapOrderToPrint = (order: OrderVm, commandPrinter: AvailablePrinter): OrderVm => {
        const itemsToPrint = filterOrderItemToPrint(order.orderItems, commandPrinter);
        const cancelledItemsToPrint = filterOrderItemToPrint(order.cancelledItems ?? [], commandPrinter);
        let orderToPrint: OrderVm = { ...order, orderItems: itemsToPrint, cancelledItems: cancelledItemsToPrint };

        if (order.customers?.length) {
            const customers = order.customers?.map((customerOrder) => {
                const itemsToPrint = filterOrderItemToPrint(customerOrder.orderItems, commandPrinter);
                const cancelledItemsToPrint = filterOrderItemToPrint(customerOrder.cancelledItems ?? [], commandPrinter);
                return {
                    ...customerOrder,
                    orderItems: itemsToPrint,
                    cancelledItems: cancelledItemsToPrint,
                };
            });

            orderToPrint = { ...orderToPrint, customers };
        }

        if (order.courses?.length) {
            const courses = order.courses?.map((course) => {
                const itemsToPrint = filterOrderItemToPrint(course.orderItems ?? [], commandPrinter);
                const cancelledItemsToPrint = filterOrderItemToPrint(course.cancelledItems ?? [], commandPrinter);
                return {
                    ...course,
                    orderItems: itemsToPrint,
                    cancelledItems: cancelledItemsToPrint,
                };
            });

            orderToPrint = { ...orderToPrint, courses };
        }

        return orderToPrint;
    };

    function getPrinterInDeviceGroupByPrinterName(printerName: string): PrinterVm | undefined {
        return printers?.find((printer: PrinterVm) => printer.deviceName === printerName);
    }

    function filterOrderItemToPrint(orderItems: Array<OrderItemVm>, commandPrinter: AvailablePrinter) {
        return orderItems.filter((orderItem) => {
            return commandPrinter.menuItemIds?.includes(orderItem.menuItemId) || commandPrinter.menuCategoryIds?.includes(orderItem.menuCategoryId!);
        });
    }

    return [printOrderCommandNewDeviceManagement];
}

type PrinterRoute = {
    printer: PrinterVm | undefined;
    order: OrderVm;
};

type AvailablePrinter = {
    menuItemIds?: Array<MenuItemId>;
    menuCategoryIds?: Array<MenuCategoryId>;
} & PrinterVm;
