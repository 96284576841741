import { makeStyles } from '@material-ui/core';
import { Button } from '@pidedirecto/ui';
import { useState } from 'react';
import * as React from 'react';
import { letseatmanagerApiDeprecated } from 'src/api/letseatmanagerApiDeprecated';
import { CreateOrderInvoiceDialog } from 'src/components/dialog/order/CreateOrderInvoiceDialog';
import { translate } from 'src/i18n/translate';
import { SentryService } from 'src/services/SentryService';
import type { OrderVm } from 'src/types/OrderVm';
import { alertKnownErrorOrSomethingWentWrong } from 'src/utils/alert/alertKnownErrorOrSomethingWentWrong';
import { isPedidosYaApp } from 'src/utils/app/isPedidosYaApp';
import { isUruguay } from 'src/utils/country/isUruguay';
import { isDeliveredDelivery } from 'src/utils/delivery/isDeliveredDelivery';
import { isPickedUpDelivery } from 'src/utils/delivery/isPickedUpDelivery';
import { isAcceptedOrder } from 'src/utils/order/isAcceptedOrder';
import { isCompletedOrder } from 'src/utils/order/isCompletedOrder';
import { isDeliveryOrder } from 'src/utils/order/isDeliveryOrder';
import { isEatHereOrder } from 'src/utils/order/isEatHereOrder';
import { isRoomServiceOrder } from 'src/utils/order/isRoomServiceOrder';
import { isTakeAwayOrder } from 'src/utils/order/isTakeAwayOrder';
import { useSelector } from 'src/utils/react/useSelector';
import { canRestaurantInvoice } from 'src/utils/restaurant/canRestaurantInvoice';

export function CompleteAndInvoiceOrderButton({ order, onSuccess }: Props): React.ReactElement | null {
    const classes = useStyles();

    const [loading, setLoading] = useState(false);
    const [openOrderInvoiceDialogState, setOpenOrderInvoiceDialogState] = useState({ open: false });

    const markReadyForPickupButtonEnabled = useSelector((state) => state.app.restaurant.markReadyForPickupButtonEnabled);
    const externalDelivery = useSelector((state) => state.app.restaurant.externalDelivery);
    const restaurant = useSelector((state) => state.app.restaurant);
    const autoInvoiceOrdersEnabled = useSelector((state) => state.app.restaurant.autoInvoiceOrdersEnabled);

    const externalDeliveryOrdersHasBeenPickedUp =
        (externalDelivery && isDeliveryOrder(order.orderType) && isPickedUpDelivery(order.deliveryStatus) && !isDeliveredDelivery(order.deliveryStatus)) || isPedidosYaApp(order.app);
    const takeAwayOrderHasBeenPickedUp = (!!order.markedReadyForPickupAt && markReadyForPickupButtonEnabled) || !markReadyForPickupButtonEnabled;
    const shouldCompleteOrderForTakeAwayOrder = isTakeAwayOrder(order.orderType) && takeAwayOrderHasBeenPickedUp && isAcceptedOrder(order.orderStatus);
    const isReadyForPickup = (isEatHereOrder(order.orderType) || isRoomServiceOrder(order.orderType)) && order.markedReadyForPickupAt && isAcceptedOrder(order.orderStatus);

    const shouldRenderButton =
        (externalDeliveryOrdersHasBeenPickedUp || shouldCompleteOrderForTakeAwayOrder || isReadyForPickup) &&
        canRestaurantInvoice(restaurant) &&
        !order.invoiced &&
        isAcceptedOrder(order.orderStatus) &&
        !autoInvoiceOrdersEnabled &&
        isUruguay(order.country);

    const handleCompleteOrder = async () => {
        setLoading(true);
        const response = await letseatmanagerApiDeprecated.currentOrders.completeOrder({
            orderId: order.orderId,
        });
        setLoading(false);
        if (!response.ok) {
            SentryService.logError('Failed to complete order', { completeOrderRequest: { orderId: order.orderId }, response });
            alertKnownErrorOrSomethingWentWrong(response);
            return;
        }
        SentryService.logInfoBreadcrumb('Successfully completed order', { completeOrderRequest: { orderId: order.orderId } });
        setOpenOrderInvoiceDialogState({ open: true });
    };

    const closeOpenOrderInvoiceDialog = () => setOpenOrderInvoiceDialogState({ open: false });

    if (!shouldRenderButton || isCompletedOrder(order.orderStatus)) return null;

    return (
        <div>
            <CreateOrderInvoiceDialog open={openOrderInvoiceDialogState.open} order={order} onClose={closeOpenOrderInvoiceDialog} onSuccess={onSuccess} />
            <Button disabled={loading} classes={{ button: classes.button }} onClick={handleCompleteOrder}>
                {translate('Complete and Invoice')}
            </Button>
        </div>
    );
}

const useStyles = makeStyles((theme) => ({
    button: {
        backgroundColor: theme.palette.primary.main,
        '&:hover': {
            backgroundColor: theme.palette.primary.dark,
        },
    },
}));

type Props = {
    order: OrderVm;
    onSuccess?: Function;
};
