import * as React from 'react';
import { Select } from 'src/components/Select';
import { LogEventLevel, LogEventLevels } from 'src/constants/LogEventLevel';
import { translate } from 'src/i18n/translate';

export function LogEventLevelsSelect({ labelAsPlaceholder, multiselectable, onChange }: Props): React.ReactElement {
    const options = Object.values(LogEventLevels).map((orderLevel: any) => ({ label: translate(orderLevel), value: orderLevel }));

    return (
        <Select
            label={translate('Log Levels')}
            name={'logEventLevels'}
            labelAsPlaceholder={labelAsPlaceholder}
            multiselectable={multiselectable}
            options={options}
            onChange={(logEventLevels: Array<LogEventLevel>) => onChange(logEventLevels)}
        />
    );
}

type Props = {
    labelAsPlaceholder?: boolean;
    multiselectable?: boolean;
    onChange: (logEventLevels: Array<LogEventLevel>) => any;
};
