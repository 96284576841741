import { letseatadminApiMethod } from 'src/api/letseatadminApi';
import type { ApiSauceResponse } from 'src/api/types/ApiSauceResponse';
import type { DriverBonusVm } from 'src/api/types/driverBonus/DriverBonusVm';
import type { DriverId } from 'src/types/Id';
import type { OrderVm } from 'src/types/OrderVm';

export async function getDriverBillingApi(request: GetDriverBillingApiRequest): ApiSauceResponse<DriverBillingVm> {
    return letseatadminApiMethod('driverBilling/getDriverBillingApi', request);
}

type GetDriverBillingApiRequest = {
    from: string;
    to: string;
    driverId: DriverId;
};

export type DriverBillingVm = {
    driverId: DriverId;
    firstName: string;
    lastName: string;
    email: string;
    curp: string;
    rfc: string;
    banned?: boolean;
    claveId?: string;
    cash: number;
    app: number;
    invoicedRestaurantDeliveryCost: number;
    total: number;
    clabe?: string;
    commission: number;
    deliveries: number;
    invoicedRestaurantDeliveryCostDeliveries: number;
    cancelled: number;
    delivered: number;
    bonus: number;
    bonuses: number;
    dynamicDeliveryEarning: number;
    deliveryCommission: number;
    manualEarning: number;
    manualEarnings: number;
    driverReferral: number;
    driverReferrals: number;
    iva: number;
    isr: number;
    deposit: number;
    orders: Array<OrderVm>;
    driverBonuses: Array<DriverBonusVm>;
};
