import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableContainer from '@material-ui/core/TableContainer';
import * as React from 'react';
import type { RestaurantBillingVm } from 'src/api/letseatadmin/restaurantBilling/getRestaurantBillingApi';
import { TableHeaderCalculation } from 'src/scenes/letseatadmin/restaurantBilling/TableHeaderCalculation';
import { TableRowCalculation } from 'src/scenes/letseatadmin/restaurantBilling/TableRowCalculation';

export function SubscriptionPayPerUseDebtCalculation({ restaurantBillingVm }: Props): React.ReactElement {
    const classes = useStyles();

    return (
        <TableContainer component={Paper}>
            <Table className={classes.table} size='small'>
                <TableHeaderCalculation name={`Subscription Pay Per Use Debt Calculation`} />
                <TableBody>
                    <TableRowCalculation name={`Debt At Start Of This Period`} amount={restaurantBillingVm.debtAtBeginningOfPeriodSubscriptionPayPerUsePayment} />
                    <TableRowCalculation name={`New Subscription Pay Per Use Payments`} amount={restaurantBillingVm.subscriptionPayPerUsePayment} />
                    <TableRowCalculation minus name={`Invoiced`} amount={restaurantBillingVm.subscriptionPayPerUsePaymentInvoice} />
                    <TableRowCalculation name={`Debt After This Period`} amount={restaurantBillingVm.debtSubscriptionPayPerUsePayment} />
                </TableBody>
            </Table>
        </TableContainer>
    );
}

type Props = {
    restaurantBillingVm: RestaurantBillingVm;
};

const useStyles = makeStyles({
    table: {},
});
