import { makeStyles } from '@material-ui/core';
import * as React from 'react';
import { PosRouter } from 'src/scenes/letseatmanager/pos/PosRouter';
import { useSelector } from 'src/utils/react/useSelector';

export function PosMenuRouter(): React.ReactElement | null {
    const classes = useStyles();

    const posSimpleView = useSelector((state) => state.pos.context?.posSimpleView);
    const table = useSelector((state) => state.pos.table);

    if (!posSimpleView || table) return null;

    return (
        <div className={classes.container}>
            <PosRouter />
        </div>
    );
}

const useStyles = makeStyles((theme) => ({
    container: {
        width: '100%',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
    },
}));
