import { letseatmanagerApiMethod } from 'src/api/letseatmanagerApi';
import type { ApiSauceResponse } from 'src/api/types/ApiSauceResponse';
import type { CustomerId, RestaurantId } from 'src/types/Id';

export async function sendEmailToCustomerApi(request: SendEmailToCustomerApiRequest): ApiSauceResponse<void> {
    return letseatmanagerApiMethod('marketing/sendEmailToCustomerApi', request);
}

type SendEmailToCustomerApiRequest = {
    restaurantId: RestaurantId;
    customerId?: CustomerId;
    subject: string;
    body: string;
};
