import { makeStyles } from '@material-ui/core';
import { Button, Table, Text } from '@pidedirecto/ui';
import * as React from 'react';
import { useState } from 'react';
import { TotalOrdersInDeliveryTimeCategoryGroupedByDay } from 'src/api/letseatmanager/restaurantDashboard/getDeliveryRestaurantStatisticsApi';
import { ResponsiveLineCanvas } from 'src/components/charts/ResponsiveLineCanvas';
import { DeliveryTimeCategories } from 'src/constants/DeliveryTimeCategory';
import { translate } from 'src/i18n/translate';
import { GraphIcon } from 'src/icons/GraphIcon';
import { TableIcon } from 'src/icons/TableIcon';

export function DailyDeliveriesByCategoryReport({ totalOrdersInDeliveryTimeCategoryGroupedByDay }: Props): React.ReactElement {
    const classes = useStyles();

    const [showTable, setShowTable] = useState(false);

    const totalOrdersInDeliveryTimeCategoryGroupedByDayColumns = [
        {
            content: translate('Date'),
            id: 'date',
        },
        {
            content: '<30 min',
            id: 'timeCategoryLessThan30min',
        },
        {
            content: '30-45 min',
            id: 'timeCategoryBetween30And45min',
        },
        {
            content: '45-60 min',
            id: 'timeCategoryBetween45And60min',
        },
        {
            content: '>60 min',
            id: 'timeCategoryGreaterThan60min',
        },
        {
            content: translate('Total Orders'),
            id: 'totalOrders',
        },
    ];

    return (
        <div className={classes.resumeContainer}>
            <div className={classes.lineChartHeader}>
                <Text size={'large'}>{translate('Daily deliveries by Category')}</Text>
                <Button onClick={() => setShowTable(!showTable)}>
                    {showTable && <TableIcon />}
                    {!showTable && <GraphIcon />}
                </Button>
            </div>
            {totalOrdersInDeliveryTimeCategoryGroupedByDay && (
                <div className={classes.lineChartContainer}>
                    {showTable && (
                        <ResponsiveLineCanvas
                            data={totalOrdersInDeliveryTimeCategoryGroupedByDay.map((order) => {
                                return {
                                    id: translate(`DeliveryTimeCategories.${order.deliveryTimeCategory}`),
                                    data: [{ x: order.date, y: order.orders }],
                                };
                            })}
                            tooltip={({ point }: { point: { id: string; data: { x: string; y: string } } }) => (
                                <div className={classes.tooltip}>
                                    <span className={classes.tooltipText}>{translate(`Category: @date`, { date: point.id })}</span>
                                    <span className={classes.tooltipText}>{translate(`Date: @date`, { date: point.data.x })}</span>
                                    <span className={classes.tooltipText}>{translate(`Total: @total`, { total: point.data.y })}</span>
                                </div>
                            )}
                        />
                    )}
                    {!showTable && (
                        <Table
                            columns={totalOrdersInDeliveryTimeCategoryGroupedByDayColumns}
                            rows={totalOrdersInDeliveryTimeCategoryGroupedByDay.map((order) => {
                                const timeCategoryLessThan30min = totalOrdersInDeliveryTimeCategoryGroupedByDay?.filter(
                                    (orderPepe) => order.deliveryTimeCategory === DeliveryTimeCategories.LESS_THAN_30_MINUTES && orderPepe.date === order.date,
                                );
                                const timeCategoryBetween30And45min = totalOrdersInDeliveryTimeCategoryGroupedByDay?.filter(
                                    (orderPepe) => order.deliveryTimeCategory === DeliveryTimeCategories.BETWEEN_30_AND_45_MINUTES && orderPepe.date === order.date,
                                );
                                const timeCategoryBetween45And60min = totalOrdersInDeliveryTimeCategoryGroupedByDay?.filter(
                                    (orderPepe) => order.deliveryTimeCategory === DeliveryTimeCategories.BETWEEN_45_AND_60_MINUTES && orderPepe.date === order.date,
                                );
                                const timeCategoryGreaterThan60min = totalOrdersInDeliveryTimeCategoryGroupedByDay?.filter(
                                    (orderPepe) => order.deliveryTimeCategory === DeliveryTimeCategories.MORE_THAN_60_MINUTES && orderPepe.date === order.date,
                                );
                                const totalOrders = totalOrdersInDeliveryTimeCategoryGroupedByDay?.filter((orderPepe) => orderPepe.date === order.date);
                                return {
                                    date: order.date,
                                    timeCategoryLessThan30min: timeCategoryLessThan30min[0]?.orders || 0,
                                    timeCategoryBetween30And45min: timeCategoryBetween30And45min[0]?.orders || 0,
                                    timeCategoryBetween45And60min: timeCategoryBetween45And60min[0]?.orders || 0,
                                    timeCategoryGreaterThan60min: timeCategoryGreaterThan60min[0]?.orders || 0,
                                    totalOrders: totalOrders[0]?.orders || 0,
                                };
                            })}
                            rowsPerPage={4}
                        />
                    )}
                </div>
            )}
        </div>
    );
}

const useStyles = makeStyles((theme) => ({
    resumeContainer: {
        width: 'fit-content',
        marginBottom: '10%',
        [theme.breakpoints.up('lg')]: {
            marginBottom: 0,
        },
    },
    lineChartHeader: {
        width: '100%',
        display: 'flex',
        justifyContent: 'space-between',
    },
    tooltip: {
        padding: 10,
        borderRadius: 3,
        backgroundColor: 'white',
        boxShadow: '1px 1px 1px rgb(0,0,0,0.2)',
        display: 'flex',
        flexDirection: 'column',
    },
    tooltipText: {
        fontFamily: theme.typography.regular,
    },
    lineChartContainer: {
        width: '75vh',
        height: 250,
        [theme.breakpoints.up('lg')]: {
            width: 650,
            height: 350,
        },
    },
}));

type Props = {
    totalOrdersInDeliveryTimeCategoryGroupedByDay: Array<TotalOrdersInDeliveryTimeCategoryGroupedByDay>;
};
