import { letseatadminApiMethod } from 'src/api/letseatadminApi';
import type { ApiSauceResponse } from 'src/api/types/ApiSauceResponse';
import type { DriverId } from 'src/types/Id';

export async function banDriverUntilApi(request: banDriverUntilApiRequest): ApiSauceResponse<void> {
    return letseatadminApiMethod('driver/banDriverUntilApi', request);
}

export type banDriverUntilApiRequest = {
    driverId: DriverId;
    bannedUntil: Date;
};
