import { letseatmanagerApiMethod } from 'src/api/letseatmanagerApi';
import type { ApiSauceResponse } from 'src/api/types/ApiSauceResponse';
import type { ManagerUserId, RestaurantId } from 'src/types/Id';
import type { RestaurantEmployeeDayShiftVm } from 'src/types/RestaurantEmployeeDayShiftVm';

export async function getCurrentRestaurantEmployeeDayShiftApi(request: GetCurrentRestaurantEmployeeDayShiftApiRequest): ApiSauceResponse<RestaurantEmployeeDayShiftVm> {
    return letseatmanagerApiMethod('restaurantEmployeeDayShift/getCurrentRestaurantEmployeeDayShiftApi', request);
}

type GetCurrentRestaurantEmployeeDayShiftApiRequest = {
    restaurantId: RestaurantId;
    managerUserId: ManagerUserId;
};
