import { makeStyles } from '@material-ui/core';
import SaveIcon from '@material-ui/icons/Save';
import { Loader } from '@pidedirecto/ui';
import { useEffect, useState } from 'react';
import * as React from 'react';
import { changeRestaurantOpeningHoursApi } from 'src/api/letseatmanager/restaurantOpeningHours/changeRestaurantOpeningHoursApi';
import { getRestaurantOpeningHoursApi } from 'src/api/letseatmanager/restaurantOpeningHours/getRestaurantOpeningHoursApi';
import { IntegrationsSyncOpeningHoursAlert } from 'src/components/alertDialog/integrationOpeningHours/IntegrationsSyncOpeningHoursAlert';
import { Button } from 'src/components/Button';
import { FormTimePicker } from 'src/components/form/FormTimePicker';
import { Page } from 'src/components/Page';
import { translate } from 'src/i18n/translate';
import { appReducer } from 'src/reducers/appReducer';
import { OpeningHours } from 'src/scenes/letseatmanager/openingHours/OpeningHours';
import { OpeningHoursForm, OpeningHoursFormType } from 'src/scenes/letseatmanager/openingHours/OpeningHoursForm';
import { alertKnownErrorOrSomethingWentWrong } from 'src/utils/alert/alertKnownErrorOrSomethingWentWrong';
import { useAction } from 'src/utils/react/useAction';
import { useLoadApi } from 'src/utils/react/useLoadApi';
import { useSelector } from 'src/utils/react/useSelector';

export function OpeningHoursPage(): React.ReactElement {
    const classes = useStyles();

    const [loading, setLoading] = useState(false);
    const [rappiHoursSyncAlert, setRappiHoursSyncAlert] = useState<{ open: boolean; syncStatus: boolean; message: string | undefined }>({ open: false, syncStatus: false, message: undefined });
    const [didiFoodHoursSyncAlert, setDidiFoodHoursSyncAlert] = useState<{ open: boolean; syncStatus: boolean; message: string | undefined }>({ open: false, syncStatus: false, message: undefined });
    const [uberEatsHoursSyncAlert, setUberEatsHoursSyncAlert] = useState<{ open: boolean; syncStatus: boolean; message: string | undefined }>({ open: false, syncStatus: false, message: undefined });

    const restaurantId = useSelector((state) => state.app.restaurantId);
    const hideOpeningHoursEditionEnabled = useSelector((state) => state.app.restaurant?.hideOpeningHoursEditionEnabled);
    const internalUser = useSelector((state) => state.authentication.internalUser);

    const showSnackbar = useAction(appReducer.actions.showSnackbar);

    const editionDisabled = hideOpeningHoursEditionEnabled && !internalUser;

    const [loadingOpeningHours, openingHours, load] = useLoadApi(
        getRestaurantOpeningHoursApi,
        { restaurantId },
        {
            dependencies: [restaurantId],
        },
    );

    useEffect(() => {
        resetIntegrationsHoursSyncValues();
    }, [restaurantId]);

    const onSubmit = async (form: OpeningHoursFormType): Promise<void> => {
        const hours = formatOpeningHoursToHours(form);
        setLoading(true);
        const response = await changeRestaurantOpeningHoursApi({
            restaurantId: restaurantId,
            hours,
            pickupStationClosingTime: form.pickupStationClosingTime,
            pickupStationDeliveryTime: form.pickupStationDeliveryTime,
            sameOpeningHoursForAllDays: form.sameOpeningHoursForAllDays,
        });
        if (!response.ok) {
            alertKnownErrorOrSomethingWentWrong(response);
            setLoading(false);
            return;
        }

        if (response.data.rappiHoursSyncSucceeded)
            setRappiHoursSyncAlert({ open: true, syncStatus: response.data.rappiHoursSyncSucceeded.syncStatus, message: response.data.rappiHoursSyncSucceeded.message });
        if (response.data.didiFoodHoursSyncSucceeded)
            setDidiFoodHoursSyncAlert({ open: true, syncStatus: response.data.didiFoodHoursSyncSucceeded.syncStatus, message: response.data.didiFoodHoursSyncSucceeded.message });
        if (response.data.uberEatsHoursSyncSucceeded)
            setUberEatsHoursSyncAlert({ open: true, syncStatus: response.data.uberEatsHoursSyncSucceeded.syncStatus, message: response.data.uberEatsHoursSyncSucceeded.message });

        await load();
        showSnackbar({ message: translate('Saved changes') });
        setLoading(false);
    };

    const formatOpeningHoursToHours = (form: OpeningHoursFormType) => {
        let hours = '';
        if (form.sameOpeningHoursForAllDays) {
            const daysWithOpeningHours = Object.keys(form.openingHours).filter((day) => form.openingHours[day as keyof typeof form.openingHours].length > 0);
            hours += daysWithOpeningHours.join(', ');

            const openingHours = form.openingHours[daysWithOpeningHours[0] as keyof typeof form.openingHours];
            const formattedOpeningHours = openingHours.map((openingHours) => `${openingHours.openingTime}-${openingHours.closingTime}`).join(', ');
            hours += ` ${formattedOpeningHours}`;
            return hours;
        }
        Object.keys(form.openingHours).forEach((day) => {
            const openingHoursForDay = form.openingHours[day as keyof typeof form.openingHours];
            let isFirstOpeningHoursForDay = true;
            openingHoursForDay.forEach((openingHours) => {
                if (!openingHours.openingTime || !openingHours.closingTime) return;
                if (!hours.includes(day)) {
                    if (hours.length === 0) hours += day;
                    else hours += `, ${day}`;
                }

                if (!isFirstOpeningHoursForDay) hours += ',';
                hours += ` ${openingHours.openingTime}-${openingHours.closingTime}`;
                if (isFirstOpeningHoursForDay) isFirstOpeningHoursForDay = false;
            });
        });
        return hours;
    };

    const resetIntegrationsHoursSyncValues = () => {
        setRappiHoursSyncAlert({ open: false, syncStatus: false, message: undefined });
        setDidiFoodHoursSyncAlert({ open: false, syncStatus: false, message: undefined });
        setUberEatsHoursSyncAlert({ open: false, syncStatus: false, message: undefined });
    };

    if (loadingOpeningHours || loading) {
        return (
            <div className={classes.loadingContainer}>
                <Loader size={40} loading={true} />;
            </div>
        );
    }

    return (
        <Page title={translate('Opening Hours')} className={classes.paper}>
            <OpeningHoursForm onSubmit={onSubmit} restaurantOpeningHours={openingHours}>
                <header className={classes.titleContainer}>
                    <h2 className={classes.title}>{translate('Collection Station')}</h2>
                    <Button type={'submit'} disabled={editionDisabled}>
                        <SaveIcon />
                        {translate('Save changes')}
                    </Button>
                </header>
                <div className={classes.pickupStationInputsContainer}>
                    <FormTimePicker name={'pickupStationClosingTime'} label={translate('Collection station closing time')} disabled={editionDisabled} />
                    <FormTimePicker name={'pickupStationDeliveryTime'} label={translate('Collection station delivery time')} disabled={editionDisabled} />
                </div>
                <OpeningHours />
            </OpeningHoursForm>
            <IntegrationsSyncOpeningHoursAlert rappi={rappiHoursSyncAlert} didiFood={didiFoodHoursSyncAlert} uberEats={uberEatsHoursSyncAlert} />
        </Page>
    );
}
const days = ['Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa', 'Su'];

const useStyles = makeStyles((theme) => ({
    paper: {
        width: '100%',
        marginBottom: 20,
        position: 'relative',
        [theme.breakpoints.down('sm')]: {
            width: '90%',
            margin: '0 auto',
            marginTop: 10,
        },
    },
    loadingContainer: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100%',
    },
    container: {
        backgroundColor: 'white',
        borderRadius: 15,
        border: `1px solid ${theme.palette.secondary.dark}`,
        margin: '0 auto',
        marginTop: 20,
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column',
        position: 'relative',
        [theme.breakpoints.down('sm')]: {
            height: 'fit-content',
        },
    },
    contentContainer: {
        width: '100%',
        height: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
    },
    titleContainer: {
        display: 'flex',
        width: '100%',
        justifyContent: 'space-between',
        alignItems: 'center',
        flexDirection: 'row',
    },
    pickupStationInputsContainer: {
        display: 'flex',
        flexDirection: 'row',
        width: '100%',
        justifyContent: 'space-between',
        gap: '12px',
        margin: '24px 0px',
    },
    inputDate: {
        width: '100%',
        borderRadius: '8px',
    },
    containerDaysButtons: {
        width: '50%',
        display: 'flex',
        gap: '8px',
    },
    title: {
        color: '#0D3037',
        fontSize: '20px',
        fontFamily: theme.typography.regular,
        margin: 0,
    },
    subtitle: {
        color: '#0D3037',
        fontSize: '16px',
        fontFamily: theme.typography.regular,
    },
}));
