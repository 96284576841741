import { makeStyles } from '@material-ui/core';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import * as React from 'react';
import { memo } from 'react';
import { app2 } from 'src/app2';
import { BrandAppMenuSelect } from 'src/components/app/appMenu/BrandAppMenuSelect';
import { BusinessAppMenuSelect } from 'src/components/app/appMenu/BusinessAppMenuSelect';
import { RestaurantSelect } from 'src/components/app/appMenu/RestaurantSelect';
import { RolePermissions } from 'src/constants/RolePermission';
import { translate } from 'src/i18n/translate';
import { authReducer } from 'src/reducers/authReducer';
import { posReducer } from 'src/reducers/posReducer';
import { useCloseRestaurant } from 'src/services/restaurant/useCloseRestaurant';
import { useOpenRestaurant } from 'src/services/restaurant/useOpenRestaurant';
import { useUserType } from 'src/services/restaurant/useUserType';
import type { RestaurantId } from 'src/types/Id';
import { deepEqual } from 'src/utils/object/deepEqual';
import { useAction } from 'src/utils/react/useAction';
import { useSelector } from 'src/utils/react/useSelector';
import { useUserHasRolePermission } from 'src/utils/react/useUserHasRolePermissions';
import { isBrandUser } from 'src/utils/user/isBrandUser';
import { isBusinessUser } from 'src/utils/user/isBusinessUser';

export function AppMenuRestaurantSelect(): React.ReactElement {
    const classes = useStyles();
    const [userHasRolePermission] = useUserHasRolePermission();
    const userType = useUserType();

    const viewUser = useSelector((state) => state.authentication.viewUser);
    const internalUser = useSelector((state) => state.authentication.internalUser);
    const restaurant = useSelector((state) => state.app.restaurant);
    const restaurants = useSelector((state) => state.app.restaurants) ?? [];

    const closeRestaurantApp2 = useAction(app2.actions.closeRestaurant);
    const pinCodeUserLogout = useAction(authReducer.actions.pinCodeUserLogout);
    const clearCashRegisterBusinessDay = useAction(posReducer.actions.clearCashRegisterBusinessDay);

    const { closeRestaurant } = useCloseRestaurant();
    const { openRestaurant } = useOpenRestaurant();

    const handleRestaurantAutocomplete = (
        e: {
            label: string;
            value: RestaurantId;
        },
        value: any,
    ) => {
        pinCodeUserLogout();

        if (value) {
            clearCashRegisterBusinessDay();
            return openRestaurant(value.value);
        }

        closeRestaurant();
        closeRestaurantApp2();
    };

    if (viewUser || internalUser) {
        return (
            <div className={classes.container}>
                <OpenRestaurantAutocomplete
                    restaurantOptions={restaurants.map((restaurant) => ({ label: restaurant.name, value: restaurant.restaurantId }))}
                    selected={restaurant ? { label: restaurant.name, value: restaurant.restaurantId } : undefined}
                    onRestaurantChange={handleRestaurantAutocomplete as any}
                />
            </div>
        );
    }

    if (isBusinessUser(userType)) {
        return (
            <div className={classes.container}>
                <BusinessAppMenuSelect />
            </div>
        );
    }

    if (isBrandUser(userType) && userHasRolePermission(RolePermissions.BRAND)) {
        return (
            <div className={classes.container}>
                <BrandAppMenuSelect />
            </div>
        );
    }

    return (
        <div className={classes.container}>
            <RestaurantSelect />
        </div>
    );
}

const OpenRestaurantAutocomplete: React.ComponentType<OpenRestaurantAutocompleteProps> = memo<OpenRestaurantAutocompleteProps>(
    function OpenRestaurantAutocomplete({ restaurantOptions, selected, onRestaurantChange }: OpenRestaurantAutocompleteProps) {
        const classes = useStyles();

        return (
            <Autocomplete
                id='openRestaurantAutocomplete'
                options={[{ label: '', value: undefined }, ...restaurantOptions]}
                getOptionLabel={(data) => {
                    if (data?.label) return data.label;
                    return '';
                }}
                renderInput={(params) => <TextField {...params} variant='standard' placeholder={translate('Restaurant')} />}
                value={selected}
                getOptionSelected={(option, value) => option.value === value.value}
                size='small'
                fullWidth
                onChange={onRestaurantChange as any}
                classes={{ input: classes.autocomplete, option: classes.autocomplete, noOptions: classes.autocomplete }}
            />
        );
    },
    (props, nextProps) => deepEqual(props.restaurantOptions, nextProps.restaurantOptions) && deepEqual(props.selected, nextProps.selected),
);

const useStyles = makeStyles((theme) => ({
    autocomplete: {
        fontFamily: theme.typography.regular,
        overflowY: 'scroll',
        '&::-webkit-scrollbar': {
            display: 'none',
        },
    },
    container: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        margin: '1vh 5%',
    },
}));

type OpenRestaurantAutocompleteProps = {
    restaurantOptions: Array<{
        label: string;
        value: RestaurantId;
    }>;
    selected?: {
        label: string;
        value: RestaurantId;
    };
    onRestaurantChange: (arg1: { label: string; value: RestaurantId }) => any;
};
