import { Grid, makeStyles } from '@material-ui/core';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { useForm, useWatch } from 'react-hook-form';
import { changeMenuApi } from 'src/api/letseatmanager/menu/changeMenuApi';
import { getMenuApi } from 'src/api/letseatmanager/menu/getMenuApi';
import { Button } from 'src/components/Button';
import { Channels } from 'src/components/Channels';
import { Chip } from 'src/components/Chip';
import { Form } from 'src/components/form/Form';
import { FormNumberField } from 'src/components/form/FormNumberField';
import { FormSwitch } from 'src/components/form/FormSwitch';
import { FormTextField } from 'src/components/form/FormTextField';
import { FormTimePicker } from 'src/components/form/FormTimePicker';
import { FormWeekScheduleSelect } from 'src/components/form/FormWeekScheduleSelect';
import { App, Apps } from 'src/constants/App';
import { Days } from 'src/constants/Day';
import { WebSocketEventTypes } from 'src/constants/WebSocketEventType';
import { WeekSchedules } from 'src/constants/WeekSchedule';
import { translate } from 'src/i18n/translate';
import { useMenus } from 'src/services/menu/useMenus';
import type { MenuId } from 'src/types/Id';
import type { WebSocketEvent } from 'src/types/WebSocketEvent';
import { alertKnownErrorOrSomethingWentWrong } from 'src/utils/alert/alertKnownErrorOrSomethingWentWrong';
import { transformHoursToWeekOpeningHours } from 'src/utils/hours/transformHoursToWeekOpeningHours';
import { transformWeekOpeningHoursToWeekSchedule } from 'src/utils/hours/transformWeekOpeningHoursToWeekSchedule';
import { classNames } from 'src/utils/react/classNames';
import { useLoadApi } from 'src/utils/react/useLoadApi';
import { useSelector } from 'src/utils/react/useSelector';
import { WebSocketEvents } from 'src/utils/webSocket/WebSocketEvents';

export function ChangeMenu({ menuId, onClose, onSuccess }: Props): React.ReactElement {
    const form = useForm();
    const { control } = form;
    const classes = useStyles();

    const weekSchedule = useWatch({ name: 'weekSchedule', control });

    const [channels, setChannels] = useState<Array<App>>([]);
    const [disabledChannels, setDisabledChannels] = useState<Array<App>>([]);
    const [loading, setLoading] = useState(false);
    const [selectedDays, setSelectedDays] = useState<Array<string>>([]);

    const internalUser = useSelector((state) => state.authentication.internalUser);
    const restaurantUser = useSelector((state) => state.authentication.restaurantUser);
    const limitProductsInOrder = useSelector((state) => state.app.restaurant?.limitProductsInOrder);

    const { menus } = useMenus();

    const [loadingMenu, menu, load] = useLoadApi(getMenuApi, { menuId: menuId! }, { requiredValues: [menuId], dependencies: [menuId] });

    const hasRappiMenu = menus.some((menu) => menu.channels?.includes(Apps.RAPPI));
    const hasSelectedRappiMenu = menu?.channels?.includes(Apps.RAPPI);
    const hasDidiMenu = menus.some((menu) => menu.channels?.includes(Apps.DIDI_FOOD));
    const hasSelectedDidiMenu = menu?.channels?.includes(Apps.DIDI_FOOD);

    const shouldShowScheduleSection = internalUser || restaurantUser;
    const weekDays = Object.keys(Days);

    useEffect(() => {
        const menuChangedSyncWebSocketEvent = WebSocketEvents.addEventListener(WebSocketEventTypes.MENU_CHANGED, syncMenuItemExternalChanges);
        const menuRemovedSyncWebSocketEvent = WebSocketEvents.addEventListener(WebSocketEventTypes.MENU_REMOVED, syncMenuItemExternalChanges);

        return () => {
            menuChangedSyncWebSocketEvent.remove();
            menuRemovedSyncWebSocketEvent.remove();
        };
    }, []);

    useEffect(() => {
        const disabledChannels: Array<App> = [];
        if (hasDidiMenu && hasSelectedDidiMenu) disabledChannels.push(Apps.DIDI_FOOD);
        if (hasRappiMenu && hasSelectedRappiMenu) disabledChannels.push(Apps.RAPPI);
        setDisabledChannels(disabledChannels);
    }, [hasRappiMenu, hasDidiMenu]);

    useEffect(() => {
        if (menu) {
            const openSchedules = transformHoursToWeekOpeningHours(menu.hours);
            const hoursSchedule = openSchedules.filter((hourSchedule) => hourSchedule.openingHours.length > 0);
            const openedDays = hoursSchedule.map((openedDay) => openedDay.day);
            form.reset({
                name: menu.name,
                companyName: menu.companyName || null,
                fromHour: hoursSchedule[0].openingHours[0]?.openingTime,
                toHour: hoursSchedule[0].openingHours[0]?.closingTime,
                orderItemsMaximumQuantity: menu.orderItemsMaximumQuantity || null,
                hidden: menu.hidden || false,
                weekSchedule: transformWeekOpeningHoursToWeekSchedule(openSchedules),
            });
            setChannels(menu.channels);
            setSelectedDays(openedDays);
        }
    }, [menu]);

    const handleClose = () => {
        if (loading) return;
        onClose?.();
    };

    const getHours = (form: any) => {
        if (form.weekSchedule === WeekSchedules.ALWAYS) return;
        if (form.weekSchedule === WeekSchedules.WEEKDAY) return `${Days.MONDAY}-${Days.FRIDAY} ${form?.fromHour}-${form?.toHour}`;
        if (form.weekSchedule === WeekSchedules.WEEKEND) return `${Days.SATURDAY}-${Days.SUNDAY} ${form?.fromHour}-${form?.toHour}`;
        if (form.weekSchedule === WeekSchedules.CUSTOM) return `${selectedDays.join(',')}  ${form?.fromHour}-${form?.toHour}`;
    };

    const onSubmit = async (form: any) => {
        if (!menuId) return;
        setLoading(true);
        const response = await changeMenuApi({
            menuId,
            menuCategoryIds: menu.menuCategoryIds,
            name: form.name,
            hours: getHours(form),
            companyName: form.companyName,
            orderItemsMaximumQuantity: form.orderItemsMaximumQuantity?.toString(),
            hidden: form.hidden,
            channels,
        });
        setLoading(false);
        if (!response.ok) return alertKnownErrorOrSomethingWentWrong(response);
        onSuccess?.();
        handleClose();
    };

    const handleSelectChannels = (channels: any) => setChannels(channels);

    const handleCheckboxClick = (day: string) => {
        if (selectedDays.includes(day)) {
            setSelectedDays(selectedDays.filter((selectedDay) => selectedDay !== day));
            return;
        }
        setSelectedDays([...selectedDays, day]);
    };

    const syncMenuItemExternalChanges = (
        event: WebSocketEvent<{
            menuId: MenuId;
        }>,
    ) => {
        if (event.data?.menuId === menuId && event.webSocketEventType === WebSocketEventTypes.MENU_REMOVED) onClose?.();
        if (event.data?.menuId === menuId && event.webSocketEventType === WebSocketEventTypes.MENU_CHANGED) load();
    };

    return (
        <Form form={form} onSubmit={onSubmit}>
            <h2 className={classes.title}>{menu?.name}</h2>
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <FormTextField name='name' label={translate('Name')} required />
                </Grid>
                {internalUser && (
                    <Grid item xs={12}>
                        <FormTextField
                            name='companyName'
                            label={translate('Company Name')}
                            helperText={translate('This field is only used for lets eat menus, writing something here if it is not for lets eat will hide the menu.')}
                            disabled={loading}
                        />
                    </Grid>
                )}
                {limitProductsInOrder && (
                    <Grid item xs={12}>
                        <FormNumberField name='orderItemsMaximumQuantity' label={translate('Product limit')} disabled={loading} />
                    </Grid>
                )}
                <Grid item xs={12}>
                    <FormSwitch name='hidden' label={translate('Hide')} disabled={loading} />
                </Grid>
                {shouldShowScheduleSection && <FormWeekScheduleSelect name={'weekSchedule'} label={translate('Schedule')} />}
                <Grid item xs={12}>
                    <div className={classes.customDays}>
                        {weekSchedule === WeekSchedules.CUSTOM && <div className={classes.labelDays}>{translate('Select the days')}</div>}
                        <div className={classes.containerDays}>
                            {weekSchedule === WeekSchedules.CUSTOM &&
                                weekDays.map((weekDay) => (
                                    <Chip
                                        classes={{ chip: classNames(classes.checkbox, selectedDays.includes(Days[weekDay as keyof typeof Days]) ? classes.checkboxDaySelected : classes.checkboxDay) }}
                                        onClick={() => handleCheckboxClick(Days[weekDay as keyof typeof Days])}
                                    >
                                        {translate(`ShortenedDays.${Days[weekDay as keyof typeof Days]}`)}
                                    </Chip>
                                ))}
                            {weekSchedule !== WeekSchedules.ALWAYS && (
                                <>
                                    <FormTimePicker className={classes.hours as any} name='fromHour' amPm={false} minutesStep={1} required />
                                    <FormTimePicker className={classes.hours as any} name='toHour' amPm={false} minutesStep={1} required />
                                </>
                            )}
                        </div>
                    </div>
                </Grid>

                <Grid item xs={12}>
                    <span className={classes.text}>{translate('Select your sales channels')}</span>
                    <div className={classes.integrationChannelsContainer}>
                        <Channels onChange={handleSelectChannels} defaultSelectedChannels={menu?.channels} disabledChannels={disabledChannels} />
                    </div>
                </Grid>
            </Grid>
            <div className={classes.buttonsContainer}>
                {onClose && (
                    <Button secondary onClick={handleClose} disabled={loading}>
                        {translate('Cancel')}
                    </Button>
                )}
                <Button type='submit' disabled={loading || loadingMenu}>
                    {loading ? translate('Saving') : translate('Save')}
                </Button>
            </div>
        </Form>
    );
}

const useStyles = makeStyles((theme) => ({
    title: {
        color: theme.palette.text.brand,
        textAlign: 'center',
        fontFamily: theme.typography.bold,
    },
    text: {
        fontFamily: theme.typography.regular,
        fontSize: 15,
        margin: 0,
    },
    chipsContainer: {
        display: 'flex',
        flexWrap: 'wrap',
        flexDirection: 'row',
        gap: 5,
    },
    modifiersContainer: {
        margin: '10px 0',
        display: 'flex',
        width: '100%',
        flexDirection: 'row',
        justifyContent: 'space-between',
    },
    buttonsContainer: {
        marginTop: 30,
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'flex-end',
        gap: 20,
        '& button': {
            width: 200,
        },
    },
    integrationChannelsContainer: {
        width: '100%',
        marginTop: 20,
        display: 'flex',
        justifyContent: 'center',
    },
    scheduleContainer: {
        display: 'flex',
        gap: '10px',
        [theme.breakpoints.down('sm')]: {
            marginRight: '20rem',
        },
    },
    days: {
        width: '100%',
        border: `1px solid #d9d9d9`,
        borderRadius: '4px',
        color: 'black',
        padding: '0px 13px',
        [theme.breakpoints.down('sm')]: {
            fontSize: '10px',
        },
    },
    hours: {
        width: '20px',
        color: '#2e3748',
        alignSelf: 'stretch',
        font: '400 12px/18px Poppins, sans-serif',
        borderRadius: '4px',
        border: '1px solid #d9d9d9',
        textAlign: 'center',
    },
    labelDays: {
        margin: '0',
        fontSize: '15px',
        fontFamily: 'PoppinsRegular',
    },
    customDays: {
        justifyContent: 'center',
        alignSelf: 'stretch',
        display: 'flex',
        flexDirection: 'column',
    },
    containerDays: {
        display: 'flex',
        marginTop: '6px',
        paddingLeft: '5px',
        width: '100%',
        gap: '8px',
    },
    checkboxDay: {
        color: theme.palette.text.primary,
        backgroundColor: theme.palette.surface.secondary,
        aspectRatio: '1',
        padding: '5px 8px',
    },
    checkboxDaySelected: {
        color: theme.palette.text.invert,
        backgroundColor: theme.palette.surface.brandContrast,
        aspectRatio: '1',
        padding: '5px 8px',
    },
    checkbox: {
        width: 30,
        height: 30,
        textAlign: 'center',
        whiteSpace: 'nowrap',
        justifyContent: 'center',
        borderRadius: '50%',
        font: '400 10px/15px Poppins, -apple-system, Roboto, Helvetica, sans-serif',
        '&:hover': {
            color: theme.palette.text.invert,
            backgroundColor: theme.palette.surface.brand,
        },
    },
}));

type Props = {
    menuId?: MenuId;
    onClose?: any;
    onSuccess?: any;
};
