import { letseatadminApiMethod } from 'src/api/letseatadminApi';
import type { ApiSauceResponse } from 'src/api/types/ApiSauceResponse';
import type { PaymentRejectReason } from 'src/constants/PaymentRejectReason';
import type { SubscriptionPaymentErrorType } from 'src/constants/SubscriptionPaymentErrorType';
import type { SubscriptionPaymentMethod } from 'src/constants/SubscriptionPaymentMethod';
import type { SubscriptionPlan } from 'src/constants/SubscriptionPlan';
import type { SubscriptionStatus } from 'src/constants/SubscriptionStatus';
import type { BrandId, CardId, FacturamaInvoiceFolio, PaymentId, RestaurantId, SubscriptionHistoryId, SubscriptionId } from 'src/types/Id';

export async function findSubscriptionHistoryBetweenTwoDatesApi(request: FindSubscriptionHistoryBetweenTwoDatesApiRequest): ApiSauceResponse<SubscriptionHistoriesVm> {
    return letseatadminApiMethod('subscriptionHistory/findSubscriptionHistoryBetweenTwoDatesApi', request);
}

type FindSubscriptionHistoryBetweenTwoDatesApiRequest = {
    from: Date;
    to: Date;
};

export type SubscriptionHistoriesVm = {
    subscriptionHistories: Array<SubscriptionHistoryVm>;
};

export type SubscriptionHistoryVm = {
    subscriptionHistoryId: SubscriptionHistoryId;
    subscriptionId: SubscriptionId;
    restaurantId: RestaurantId;
    restaurantIds: Array<RestaurantId>;
    paymentId: PaymentId;
    payedSubscription?: boolean;
    cities: Array<string>;
    brandId?: BrandId;
    brandName?: string;
    restaurantNames: Array<string>;
    businessName?: string;
    rfc?: string;
    subscriptionPaymentMethod: SubscriptionPaymentMethod;
    cardIds?: Array<CardId>;
    manualInvoiceDate?: Date;
    manualInvoiceFacturamaInvoiceFolio?: FacturamaInvoiceFolio;
    manualInvoiceError?: string;
    status?: SubscriptionStatus;
    webOrders?: boolean;
    manualOrders?: boolean;
    plan?: SubscriptionPlan;
    paymentAmount: string;
    previousPaymentDate?: Date;
    previousPaymentAmount?: string;
    previousPaymentPaidAt?: Date;
    nextPaymentAmount?: string;
    nextPaymentDate?: Date;
    startServiceDate?: Date;
    endServiceDate?: Date;
    cutServiceDate?: Date;
    paymentErrorType?: SubscriptionPaymentErrorType;
    paymentRejectReason?: PaymentRejectReason;
    contactName?: string;
    contactPhone?: string;
    contactEmail?: string;
    subscriptionCreatedAt: Date;
    createdAt: Date;
};
