import moment from 'moment/moment';
import type { CartItemVm } from 'src/types/CartItemVm';
import type { OrderItemVm } from 'src/types/OrderVm';

export function findItemsChangedInOrder(previousOrderItems: Array<CartItemVm> | Array<OrderItemVm>, currentOrderItems: Array<CartItemVm> | Array<OrderItemVm>): Array<CartItemVm> | Array<OrderItemVm> {
    const addedItems: Array<any> = [];

    for (const item of currentOrderItems) {
        const itemsHasAlreadyPrinted = previousOrderItems.some(
            (orderItem) => orderItem.menuItemId === item.menuItemId && orderItem.quantity === item.quantity && moment(orderItem.addedAt).isSame(item.addedAt),
        );
        if (itemsHasAlreadyPrinted) continue;

        addedItems.push(item);
    }
    return addedItems;
}
