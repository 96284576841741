import IconButton from '@material-ui/core/IconButton';
import { withStyles } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';
import AddIcon from '@material-ui/icons/Add';
import RefreshIcon from '@material-ui/icons/Refresh';
import MUIDataTable from 'mui-datatables';
import * as React from 'react';
import { letseatmanagerApiDeprecated } from 'src/api/letseatmanagerApiDeprecated';
import { UpdatingContentProgress } from 'src/components/UpdatingContentProgress';
import { history } from 'src/config/history';
import { RoutePaths } from 'src/constants/RoutePath';
import { translate } from 'src/i18n/translate';
import { CREATE_BENEFIT_PLAN, CreateBenefitPlanDialog } from 'src/scenes/letseatadmin/company/CreateBenefitPlanDialog';
import type { BenefitPlanId, CompanyId } from 'src/types/Id';
import { alertKnownErrorOrSomethingWentWrong } from 'src/utils/alert/alertKnownErrorOrSomethingWentWrong';
import { formatDateTimeString } from 'src/utils/date/formatDateTimeString';
import { translateDaysString } from 'src/utils/day/translateDaysString';
import { setUrlQueryParameter } from 'src/utils/history/setUrlQueryParameter';
import { RegExps } from 'src/utils/RegExps';

export default withStyles((theme) => ({
    table: {
        whiteSpace: 'nowrap',
        '& tr': { cursor: 'pointer' },
        '& tr:hover': { backgroundColor: 'rgba(0, 0, 0, 0.07) !important' },
    },
    toolbar: {
        paddingRight: theme.spacing(3),
    },
}))(
    class BenefitPlans extends React.Component<Props, State> {
        state = {
            loading: false,
            benefitPlans: [],
        };

        async componentDidMount() {
            await this.load();
        }

        // shouldComponentUpdate(nextProps: Props, nextState: State): boolean {
        //     return !deepEqual(this.props, nextProps) || !deepEqual(this.state, nextState);
        // }

        load = async () => {
            this.setState({ loading: true });
            const response = await letseatmanagerApiDeprecated.admin.getBenefitPlans({ companyId: this.props.companyId });
            this.setState({ loading: false });
            if (!response.ok) {
                alertKnownErrorOrSomethingWentWrong(response);
                return;
            }
            this.setState({ benefitPlans: response.data.benefitPlans });
        };

        handleClickNew = () => {
            history.push({
                search: setUrlQueryParameter(CREATE_BENEFIT_PLAN, ''),
            });
        };

        render() {
            const { classes } = this.props;
            return (
                <div style={{ position: 'relative' }}>
                    <UpdatingContentProgress loading={this.state.loading} />
                    <CreateBenefitPlanDialog companyId={this.props.companyId} onBenefitPlanCreated={this.load} />
                    <MUIDataTable
                        /* @ts-ignore */
                        className={classes.table}
                        title={'Benefit Plans'}
                        data={this.state.benefitPlans.map((benefitPlan: any) => ({
                            benefitPlanId: benefitPlan.benefitPlanId,
                            name: benefitPlan.name,
                            credits: benefitPlan.credits,
                            meals: defaultMeals(benefitPlan),
                            days: translateDaysString(benefitPlan.days),
                            hours: benefitPlan.hours || translate('24/7'),
                            paused: benefitPlan.paused ? 'Paused' : '',
                            createdAt: formatDateTimeString(benefitPlan.createdAt) || '',
                        }))}
                        columns={[
                            {
                                name: 'benefitPlanId',
                                label: ' ',
                                options: {
                                    display: 'excluded',
                                    filter: false,
                                },
                            },
                            {
                                name: 'name',
                                label: translate('Name'),
                                options: {
                                    filter: false,
                                },
                            },
                            {
                                name: 'credits',
                                label: translate('Credits'),
                                options: {
                                    filter: false,
                                    searchable: false,
                                },
                            },
                            {
                                name: 'meals',
                                label: translate('Meals'),
                                options: {
                                    filter: false,
                                    searchable: false,
                                },
                            },
                            {
                                name: 'days',
                                label: translate('Days'),
                                options: {
                                    filter: false,
                                    searchable: false,
                                },
                            },
                            {
                                name: 'hours',
                                label: translate('Hours'),
                                options: {
                                    filter: false,
                                    searchable: false,
                                },
                            },
                            {
                                name: 'paused',
                                label: translate('Paused'),
                                options: {
                                    searchable: false,
                                },
                            },
                            {
                                name: 'createdAt',
                                label: translate('Created'),
                                options: {
                                    filter: false,
                                    searchable: false,
                                },
                            },
                        ]}
                        options={{
                            responsive: 'standard',
                            tableBodyMaxHeight: '500px',
                            selectableRows: 'none',
                            // setRowProps: (row) => {
                            //     return {
                            //         style: { backgroundColor: getBenefitPlanstatusColor(row[30], row[32]) },
                            //     };
                            // },
                            onRowClick: (
                                rowData: Array<string>,
                                rowMeta: {
                                    dataIndex: number;
                                    rowIndex: number;
                                },
                            ) => {
                                history.push({
                                    pathname: RoutePaths.ADMIN_BENEFIT_PLAN.replace(`:benefitPlanId(${RegExps.uuid})`, rowData[0]),
                                    search: history.location.search,
                                });
                            },
                            filterType: 'multiselect',
                            rowsPerPage: 100,
                            customToolbar: () => {
                                return (
                                    <>
                                        <Tooltip title='Refresh'>
                                            <IconButton onClick={this.load}>
                                                <RefreshIcon />
                                            </IconButton>
                                        </Tooltip>
                                        <Tooltip title='Create Benefit Plan'>
                                            <IconButton onClick={this.handleClickNew}>
                                                <AddIcon />
                                            </IconButton>
                                        </Tooltip>
                                    </>
                                );
                            },
                            // filterList: [[], [], [], [], ['MEXICO']],
                        }}
                    />
                </div>
            );
        }
    },
) as React.ComponentType<any>;

function defaultMeals(benefitPlan?: BenefitPlanVm | null): number {
    if (!benefitPlan) {
        return 0;
    }
    if (!benefitPlan.meals) {
        if (!benefitPlan.days) {
            return 0;
        }
        return benefitPlan.days.split(',').length;
    }
    return benefitPlan.meals;
}

type Props = {
    companyId: CompanyId;
    classes: any;
};

type State = {
    loading: boolean;
} & BenefitPlansVm;

type BenefitPlansVm = {
    benefitPlans: Array<BenefitPlanVm>;
};

type BenefitPlanVm = {
    benefitPlanId: BenefitPlanId;
    companyId: CompanyId;
    name: string;
    credits: string;
    meals?: number;
    days: string;
    hours?: string;
    paused?: boolean;
    createdAt: Date;
    modifiedAt: Date;
};
