import { applyMiddleware, compose, createStore } from 'redux';
import immutableStateInvariantMiddleware from 'redux-immutable-state-invariant';
import { persistStore } from 'redux-persist5'; // TODO: change to redux-persist workaround  see https://github.com/awslabs/aws-mobile-appsync-sdk-js/issues/410

// import { createLogger } from 'redux-logger';
import createSagaMiddleware from 'redux-saga';
import 'regenerator-runtime/runtime';
import reducers from 'src/reducers';
import rootSaga from 'src/sagas';

// import DevTools from './devtools';

const sagaMiddleware = createSagaMiddleware();

// const isDebuggingInChrome = process.env.NODE_ENV === 'development' && !!window.navigator.userAgent; // eslint-disable-line no-undef

const middlewares =
    process.env.NODE_ENV === 'development'
        ? [
              applyMiddleware(
                  immutableStateInvariantMiddleware(),
                  sagaMiddleware,
                  // createLogger({
                  //     predicate: (getState, action) => {
                  //         return isDebuggingInChrome;
                  //     },
                  //     collapsed: true,
                  //     duration: true,
                  // })
              ),
              // DevTools.instrument()
          ]
        : [applyMiddleware(sagaMiddleware)];

function configureStore(initialState = {}): any {
    return createStore(reducers, initialState, compose(...middlewares));
}

function configurePersistor(store: any) {
    const persistor = persistStore(store);
    sagaMiddleware.run(rootSaga);
    return persistor;
}
export const store: any = configureStore();
export const persistor: any = configurePersistor(store);
