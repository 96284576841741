import { letseatmanagerApiMethod } from 'src/api/letseatmanagerApi';
import type { ApiSauceResponse } from 'src/api/types/ApiSauceResponse';
import type { MenuId } from 'src/types/Id';

export async function deprecatedCreateMenuCategoryApi(request: CreateMenuCategoryApiRequest): ApiSauceResponse<void> {
    return letseatmanagerApiMethod('menu/createMenuCategoryApi', request);
}

type CreateMenuCategoryApiRequest = {
    menuId: MenuId;
    position?: number;
    name: string;
    promotion?: boolean;
    imageUrl?: string;
};
