import { makeStyles } from '@material-ui/core';
import * as React from 'react';
import { classNames } from 'src/utils/react/classNames';

export function KpiCard({ color, title, legend, classes }: Props): React.ReactElement {
    const defaultClasses = useStyles();

    return (
        <div className={classNames(defaultClasses.container, classes?.container)} style={color ? { border: `1.5px solid ${color}` } : {}}>
            <span className={classNames(defaultClasses.body, classes?.body)} style={{ color: color ?? '#104353' }}>
                {legend}
            </span>
            <div className={classNames(defaultClasses.legendContainer, classes?.legend)}>
                <div className={classNames(defaultClasses.color, classes?.color)} style={{ backgroundColor: color }}></div>
                <span className={classNames(defaultClasses.title, classes?.title)}>{title}</span>
            </div>
        </div>
    );
}

const useStyles = makeStyles((theme) => ({
    container: {
        borderRadius: 8,
        backgroundColor: 'transparent',
        padding: '10px 8px',
        width: '100%',
        textAlign: 'left',
        minWidth: 'max-content',
    },
    title: {
        fontFamily: theme.typography.regular,
        fontSize: 14,
        color: '#104353',
    },
    body: {
        fontSize: 20,
        fontFamily: theme.typography.semiBold,
    },
    color: {
        width: 12,
        height: 12,
        borderRadius: 2,
    },
    legendContainer: {
        display: 'flex',
        alignItems: 'center',
        gap: 6,
    },
}));

type Props = {
    title: string;
    legend: string | number;
    color?: string;
    classes?: ClassesType;
};

type ClassesType = {
    container?: string;
    title?: string;
    body?: string;
    color?: string;
    legend?: string;
};
