import { IconButton, makeStyles, Tooltip } from '@material-ui/core';
import TableCell from '@material-ui/core/TableCell';
import TableFooter from '@material-ui/core/TableFooter';
import TableRow from '@material-ui/core/TableRow'; //TODO: migrate from mui component
import LaunchIcon from '@material-ui/icons/Launch';
import { BigNumber } from 'bignumber.js';
import { useState } from 'react';
import * as React from 'react';
import { AppIcon } from 'src/components/icon/AppIcon';
import { Table } from 'src/components/Table';
import { App } from 'src/constants/App';
import { translate } from 'src/i18n/translate';
import { DeprecatedOrderDetailsDialog } from 'src/scenes/letseatadmin/order/DeprecatedOrderDetailsDialog';
import { useFormatAsRestaurantCurrencyNumber } from 'src/services/restaurant/useFormatAsRestaurantCurrencyNumber';
import type { CashRegisterPosBusinessDayReportVm } from 'src/types/CashRegisterPosBusinessDayReportVm';
import { OrderVm } from 'src/types/OrderVm';
import type { PosBusinessDayReportVm } from 'src/types/PosBusinessDayReportVm';
import { formatDateTimeString } from 'src/utils/date/formatDateTimeString';
import { getDeliveryProvider } from 'src/utils/order/getDeliveryProvider';
import { getOrderStatus } from 'src/utils/order/getOrderStatus';
import { isDeliveryOrder } from 'src/utils/order/isDeliveryOrder';
import { toHumanizedOrderId } from 'src/utils/order/toHumanizedOrderId';
import { useSelector } from 'src/utils/react/useSelector';
import { sum } from 'src/utils/reduce/sum';

export function PosBusinessDayOrdersTable({ posBusinessDayReport }: Props): React.ReactElement {
    const classes = useStyles();
    const formatAsCurrencyNumber = useFormatAsRestaurantCurrencyNumber();

    const [orderDialogState, setOrderDialogState] = useState({ open: false, order: undefined });

    const internalUser = useSelector((state) => state.authentication.internalUser);
    const consecutiveOrderIdEnabled = useSelector((state) => state.app.restaurant?.consecutiveOrderIdEnabled);

    const columns = [
        {
            name: 'customerName',
            label: translate('Customer Name'),
            options: {
                filter: false,
            },
        },
        {
            name: 'channel',
            label: translate('Channel'),
            options: {
                filter: false,
                searchable: false,
                customBodyRender: (channel: App) => <AppIcon app={channel} />,
            },
        },
        {
            name: 'orderType',
            label: translate('Order type'),
            options: {
                filter: true,
            },
        },
        {
            name: 'paymentMethods',
            label: translate('Payment Methods'),
            options: {
                filter: true,
            },
        },
        {
            name: 'receivedPayments',
            label: translate('Received Payments'),
            options: {
                filter: true,
            },
        },
        {
            name: 'createdAt',
            label: translate('Ordering Date'),
            options: {
                filter: false,
                searchable: true,
            },
        },
        {
            name: 'subtotal',
            label: translate('Subtotal'),
            options: {
                filter: false,
                searchable: false,
            },
        },
        {
            name: 'promoPriceDiscount',
            label: translate('Promo price discount'),
            options: {
                filter: false,
                searchable: false,
            },
        },
        {
            name: 'promoCodeDiscount',
            label: translate('Promo Code discount'),
            options: {
                filter: false,
                searchable: false,
            },
        },
        {
            name: 'promoCode',
            label: translate('Promo Code'),
            options: {
                filter: false,
                searchable: false,
            },
        },
        {
            name: 'companyCredits',
            label: translate('Company Credits'),
            options: {
                filter: false,
                searchable: false,
            },
        },
        {
            name: 'customerCredits',
            label: translate('Customer Credits'),
            options: {
                filter: false,
                searchable: false,
            },
        },
        {
            name: 'totalDiscount',
            label: translate('Total discount'),
            options: {
                filter: false,
            },
        },
        {
            name: 'total',
            label: translate('Total'),
            options: {
                filter: false,
            },
        },
        {
            name: 'delivery',
            label: translate('Delivery'),
            options: {
                filter: false,
                searchable: false,
                customBodyRender: (delivery: App) => <AppIcon app={delivery} />,
            },
        },
        {
            name: 'orderItemTotalCommission',
            label: translate('Total Commission'),
            options: {
                filter: false,
                searchable: false,
            },
        },
        {
            name: 'orderStatus',
            label: translate('Order Status'),
            options: {
                filter: false,
            },
        },
        {
            name: 'paymentStatus',
            label: translate('Payment status'),
            options: {
                filter: false,
            },
        },
        {
            name: 'restaurantName',
            label: translate('Restaurant'),
            options: {
                filter: true,
            },
        },
        {
            name: 'orderRejectReason',
            label: translate('Rejected reason'),
            options: {
                filter: false,
            },
        },
        {
            name: 'order',
            label: translate('See Order'),
            options: {
                filter: false,
                customBodyRender: (order: OrderVm) => (
                    <div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
                        <IconButton onClick={() => handleOpenOrder(order)}>
                            <Tooltip title={'Open Order'}>
                                <LaunchIcon color={'primary'} />
                            </Tooltip>
                        </IconButton>
                    </div>
                ),
            },
        },
    ];

    if (consecutiveOrderIdEnabled) {
        columns.unshift({
            name: 'consecutiveOrderId',
            label: translate('Service Id'),
            options: {
                filter: true,
            },
        });

        if (internalUser) {
            columns.unshift({
                name: 'orderId',
                label: translate('Order Id'),
                options: {
                    filter: true,
                },
            });
        }
    }

    if (!consecutiveOrderIdEnabled) {
        columns.unshift({
            name: 'orderId',
            label: translate('Order Id'),
            options: {
                filter: true,
            },
        });
    }
    const handleOpenOrder = async (order: any) => {
        setOrderDialogState({ open: true, order });
    };

    const posBusinessDayOrdersTableData = posBusinessDayReport.orders?.map((order: OrderVm) => {
        const totalDiscount = BigNumber(order.posDiscount ?? 0)
            .plus(order.promoPriceDiscount ?? 0)
            .plus(order.promoCodeDiscount ?? 0)
            .plus(order.usedCredits ?? 0)
            .toString();

        return {
            ...order,
            restaurantName: order.restaurant?.name,
            paymentStatus: order.paymentStatus ? translate(`PaymentStatuses.${order.paymentStatus}`) : '',
            delivery: isDeliveryOrder(order.orderType) ? getDeliveryProvider(order) : '',
            customerName: order.customerName,
            channel: order.app,
            paymentMethods: order.paymentMethods,
            receivedPayments: order.receivedPayments,
            createdAt: formatDateTimeString(order.createdAt),
            promoPriceDiscount: formatAsCurrencyNumber(order.promoPriceDiscount ?? '0'),
            promoCodeDiscount: formatAsCurrencyNumber(order.promoCodeDiscount ?? '0'),
            customerCredits: formatAsCurrencyNumber(order.usedCredits ?? '0'),
            promoCode: order.promoCode ?? '',
            companyCredits: order.companyCredits ?? '',
            totalDiscount: formatAsCurrencyNumber(totalDiscount ?? '0'),
            orderStatus: getOrderStatus(order),
            orderType: translate(`OrderTypes.${order.orderType}`),
            orderItemTotalCommission: formatAsCurrencyNumber(order.orderItemTotalCommission ?? '0'),
            total: formatAsCurrencyNumber(order.total ?? '0'),
            subtotal: formatAsCurrencyNumber(order.subtotal ?? '0'),
            orderRejectReason: order.orderRejectReason ? translate(`OrderRejectReasons.${order.orderRejectReason}`) : '',
            order,
            orderId: toHumanizedOrderId(order.orderId),
            consecutiveOrderId: order.consecutiveOrderId ?? toHumanizedOrderId(order.orderId),
        };
    }) as any;

    const getColumnTotal = (columnName: keyof OrderVm) => {
        if (columnName === 'totalDiscount') {
            return posBusinessDayReport.orders
                ?.map((order: OrderVm) => {
                    return BigNumber(order.posDiscount ?? 0)
                        .plus(order.promoPriceDiscount ?? 0)
                        .plus(order.promoCodeDiscount ?? 0)
                        .plus(order.usedCredits ?? 0)
                        .toString();
                })
                .reduce(sum, BigNumber(0))
                .toString();
        }
        return posBusinessDayReport.orders
            ?.map((order: OrderVm) => {
                return order[columnName] ?? '0';
            })
            .reduce(sum, BigNumber(0))
            .toString();
    };

    const columnNamesToShowTotal = ['subtotal', 'promoPriceDiscount', 'promoCodeDiscount', 'customerCredits', 'totalDiscount', 'total', 'orderItemTotalCommission'];

    return (
        <div>
            <DeprecatedOrderDetailsDialog open={orderDialogState.open} handleClose={() => setOrderDialogState({ open: false, order: undefined })} order={orderDialogState.order} hideOptions={true} />
            <Table
                data={posBusinessDayOrdersTableData}
                columns={columns}
                options={{
                    responsive: 'standard',
                    selectableRows: 'none',
                    rowsPerPage: 100,
                    viewColumns: false,
                    print: false,
                    download: true,
                    customTableBodyFooterRender: (options) => {
                        return (
                            <TableFooter className={classes.stickyFooterCell}>
                                <TableRow>
                                    {options.selectableRows !== 'none' ? <TableCell className={classes.stickyFooterCell} /> : null}
                                    {options.columns.map((column, index) => {
                                        if (columnNamesToShowTotal.includes(column.name)) {
                                            return (
                                                <TableCell key={index} className={classes.stickyFooterCell}>
                                                    {formatAsCurrencyNumber(getColumnTotal(column.name))}
                                                </TableCell>
                                            );
                                        }
                                        return <TableCell key={index} className={classes.stickyFooterCell} />;
                                    })}
                                </TableRow>
                            </TableFooter>
                        );
                    },
                    onDownload: (buildHead, buildBody, columns, data) => {
                        const filteredColumns = columns.slice(0, -1);
                        const customRowData = data.map((row: { data: Array<any> }) => {
                            const filteredData = row.data.slice(0, -1);
                            return { ...row, data: filteredData };
                        });

                        const columnFooterToDisplay = columns.map((column: any) => {
                            if (columnNamesToShowTotal.includes(column.name)) {
                                return `Total: ${formatAsCurrencyNumber(getColumnTotal(column.name))}`;
                            }
                            return '';
                        });
                        return '\uFEFF' + buildHead(filteredColumns) + buildBody(customRowData.concat({ index: customRowData.length, data: columnFooterToDisplay }));
                    },
                }}
            />
        </div>
    );
}

const useStyles = makeStyles((theme) => ({
    stickyFooterCell: {
        backgroundColor: theme.palette.surface.tertiary,
        borderBottom: 'none',
        fontWeight: 900,
        position: 'sticky',
        bottom: 0,
        zIndex: 100,
        '&:hover': {
            backgroundColor: `${theme.palette.surface.tertiary}!important`,
        },
    },
}));

type Props = {
    posBusinessDayReport: PosBusinessDayReportVm | CashRegisterPosBusinessDayReportVm;
};
