import { letseatadminApiMethod } from 'src/api/letseatadminApi';
import type { ApiSauceResponse } from 'src/api/types/ApiSauceResponse';
import type { EmailTemplateId } from 'src/types/Id';

export async function getEmailTemplateApi(request: GetEmailTemplateApiRequest): ApiSauceResponse<EmailTemplateVm> {
    return letseatadminApiMethod('emailTemplate/getEmailTemplateApi', request);
}

export type GetEmailTemplateApiRequest = {
    emailTemplateId: EmailTemplateId;
};

export type EmailTemplateVm = {
    emailTemplateId: EmailTemplateId;
    name: string;
    subject: string;
    bodyHtml?: string;
    bodyText?: string;
    createdAt: Date;
};
