import { RestaurantStatus, RestaurantStatuses } from 'src/constants/RestaurantStatus';
import { translate } from 'src/i18n/translate';

export function translateRestaurantStatus(restaurantStatus: RestaurantStatus): string {
    switch (restaurantStatus) {
        case RestaurantStatuses.LIVE: {
            return translate('Live');
        }
        case RestaurantStatuses.COMING_SOON: {
            return translate('Coming Soon');
        }
        case RestaurantStatuses.INACTIVE: {
            return translate('Inactive');
        }
        default: {
            return '';
        }
    }
}
