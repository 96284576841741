import type { DriverId } from 'src/types/Id';

export const KEY_NAME = 'driverMobileNumberOptions';

export function getDriverMobileNumberOptionsInLocalStorage(): Array<DriverMobileNumberOption> {
    const item = window.localStorage.getItem(KEY_NAME);
    return item ? JSON.parse(item) : [];
}

export type DriverMobileNumberOption = {
    label: string;
    value: DriverId;
    banned?: boolean;
};
