import { makeStyles } from '@material-ui/core';
import { useEffect, useState } from 'react';
import * as React from 'react';
import { updateOrderItemKitchenStatusApi } from 'src/api/letseatmanager/kitchen/updateOrderItemKitchenStatusApi';
import { MenuItemModifierGroups } from 'src/components/orderCard/orderItems/MenuItemModifierGroups';
import { KitchenStatus, KitchenStatuses } from 'src/constants/KitchenStatus';
import { translate } from 'src/i18n/translate';
import { MenuItemId, type OrderId } from 'src/types/Id';
import type { OrderItemVm } from 'src/types/OrderVm';
import { alertKnownErrorOrSomethingWentWrong } from 'src/utils/alert/alertKnownErrorOrSomethingWentWrong';
import { isPartiallyPreparedKitchenOrder } from 'src/utils/order/isPartiallyPreparedKitchenOrder';
import { isPreparedKitchenOrder } from 'src/utils/order/isPreparedKitchenOrder';
import { isPreparingKitchenOrder } from 'src/utils/order/isPreparingKitchenOrder';
import { classNames } from 'src/utils/react/classNames';

export function KitchenOrderItem({ orderId, orderItem }: Props): React.ReactElement {
    const classes = useStyles();

    const [loading, setLoading] = useState(false);
    const [item, setItem] = useState<OrderItemVm>(orderItem);

    const isCancelledItem = !!orderItem.removedAt;

    useEffect(() => {
        setItem(orderItem);
    }, [orderItem]);

    const toggleOrderItemKitchenStatus = async (menuItemId: MenuItemId, orderItemKitchenStatus: undefined | KitchenStatus) => {
        if (isCancelledItem) return;
        if (loading) return;

        const kitchenStatus = orderItemKitchenStatus ?? KitchenStatuses.PREPARING;

        setLoading(true);
        const response = await updateOrderItemKitchenStatusApi({
            orderId,
            menuItemId: orderItem.menuItemId,
            addedAt: orderItem.addedAt,
            kitchenStatus: isPreparingKitchenOrder(kitchenStatus) ? KitchenStatuses.PREPARED : KitchenStatuses.PREPARING,
        });
        setLoading(false);

        if (!response.ok) {
            alertKnownErrorOrSomethingWentWrong(response);
            return;
        }

        setItem((orderItem) => ({
            ...orderItem,
            orderItemKitchenStatus: kitchenStatus === KitchenStatuses.PREPARING ? KitchenStatuses.PREPARED : KitchenStatuses.PREPARING,
        }));
    };

    return (
        <div>
            <div
                className={classNames(classes.container, {
                    [classes.prepared]: isPreparedKitchenOrder(item.orderItemKitchenStatus) || isPartiallyPreparedKitchenOrder(item.orderItemKitchenStatus),
                    [classes.cancelled]: isCancelledItem,
                })}
                onClick={() => toggleOrderItemKitchenStatus(item.menuItemId, item.orderItemKitchenStatus)}
            >
                <div>{item.quantity}</div>
                <div>
                    <span>{item.name}</span>
                    <MenuItemModifierGroups modifierGroups={item.modifierGroups} />
                </div>
            </div>
            {isCancelledItem && <div className={classes.subtext}>{translate('Cancelled')}</div>}
            {item.note && (
                <>
                    <div className={classes.subtext}>{translate('Note:')}</div>
                    <div className={classes.subtext}>{item.note ?? 'nota'}</div>
                </>
            )}
            <div className={classes.divider}></div>
        </div>
    );
}

const useStyles = makeStyles((theme) => ({
    container: {
        fontFamily: theme.typography.regular,
        fontWeight: 600,
        fontSize: 12,
        display: 'grid',
        width: '100%',
        color: theme.palette.text.primary,
        gridTemplateColumns: '10% 90%',
        [theme.breakpoints.up('sm')]: {
            fontSize: 14,
        },
        '&:hover': {
            cursor: 'pointer',
        },
    },
    prepared: {
        textDecorationLine: 'line-through',
        textDecorationColor: theme.palette.text.secondary,
    },
    cancelled: {
        textDecorationLine: 'line-through',
        textDecorationColor: theme.palette.text.danger,
    },
    subtext: {
        color: '#6C7076',
        fontWeight: 400,
    },
    divider: {
        width: '90%',
        height: 1,
        backgroundColor: theme.palette.border.primary,
        margin: '10px 0',
    },
}));

type Props = {
    orderId: OrderId;
    orderItem: OrderItemVm;
};
