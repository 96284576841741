import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { makeStyles } from '@material-ui/core/styles';
import * as React from 'react';
import { translate } from 'src/i18n/translate';
import { useSetPayingOrder } from 'src/services/pos/useSetPayingOrder';

export function ConfirmCancelPaymentTerminalPaymentDialog({ open, onClose, onSuccess }: Props): React.ReactElement {
    const classes = useStyles();

    const setPaying = useSetPayingOrder();

    const handleCancelPaymentTerminalPayment = () => {
        setPaying(false);
        onSuccess();
        onClose();
    };

    return (
        <Dialog scroll='paper' className={classes.dialog} open={open} onClose={onClose} onExited={onClose}>
            <DialogTitle classes={{ root: classes.dialogTitle }}>{translate('Cancel payment')}</DialogTitle>
            <DialogContent className={classes.bodyText}>{translate('Are you sure you want to cancel request?')}</DialogContent>
            <DialogActions className={classes.buttonsContainer}>
                <Button variant={'outlined'} className={classes.cancelButton} onClick={onClose}>
                    {translate('Cancel')}
                </Button>
                <Button color='primary' variant={'contained'} className={classes.acceptButton} onClick={handleCancelPaymentTerminalPayment}>
                    {translate('Accept')}
                </Button>
            </DialogActions>
        </Dialog>
    );
}

const useStyles = makeStyles((theme) => ({
    dialogTitle: {
        textAlign: 'center',
        fontSize: 41,
        color: theme.palette.primary.main,
        paddingTop: theme.spacing(4),
        paddingBottom: theme.spacing(3),
        '& h2': {
            fontFamily: theme.typography.medium,
        },
    },
    bodyText: {
        fontFamily: theme.typography.medium,
        textAlign: 'center',
        color: theme.palette.secondary.contrastText,
    },
    buttonsContainer: {
        marginTop: 20,
        marginBottom: 20,
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
    },
    dialog: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    cancelButton: {
        color: theme.palette.primary.main,
        border: `1px solid ${theme.palette.primary.main}`,
        fontFamily: theme.typography.semiBold,
        fontSize: 12,
        borderRadius: 6,
        height: 40,
        maxWidth: 120,
        width: 120,
        textTransform: 'none',
    },
    acceptButton: {
        fontFamily: theme.typography.semiBold,
        fontSize: 12,
        borderRadius: 6,
        height: 40,
        maxWidth: 120,
        width: 120,
        textTransform: 'none',
        [theme.breakpoints.up('md')]: {},
    },
}));

type Props = {
    open: boolean;
    onClose: any;
    onSuccess: any;
};
