import { makeStyles } from '@material-ui/core';
import { Text } from '@pidedirecto/ui';
import { CrossIcon } from '@pidedirecto/ui/icons';
import * as React from 'react';
import { Button } from 'src/components/Button';
import { translate } from 'src/i18n/translate';
import { useFormatAsRestaurantCurrencyNumber } from 'src/services/restaurant/useFormatAsRestaurantCurrencyNumber';
import type { PosPayment } from 'src/types/PosPayment';
import { isCardPayment } from 'src/utils/paymentMethod/isCardPayment';
import { useSelector } from 'src/utils/react/useSelector';

export function PosPaymentResumeItem({ posPayment, onRemove }: Props): React.ReactElement {
    const classes = useStyles();
    const formatAsCurrencyNumber = useFormatAsRestaurantCurrencyNumber();

    const currencyFormat = useSelector((state) => state.app.restaurant.currencyFormat);

    const isPaidByParts = Boolean(posPayment.customerNumber);

    const getPaymentLabel = () => {
        if (isPaidByParts) return translate('Customer @customerNumber', { customerNumber: posPayment.customerNumber });

        return posPayment.customPaymentMethod ?? translate(posPayment.paymentMethod);
    };

    const avoidRemovePayment = isCardPayment(posPayment.paymentMethod) && !posPayment.customPaymentMethod && posPayment.paymentTerminalPaymentId;

    return (
        <div className={classes.posPaymentCard} key={posPayment.id}>
            <div className={classes.firstLine}>
                <span className={classes.orderResumeText}>
                    {getPaymentLabel()} -
                    {formatAsCurrencyNumber(posPayment.restaurantCurrencyAmount ?? posPayment.amount, { currency: posPayment.restaurantCurrency?.currency ?? currencyFormat?.currency })}
                </span>
                {posPayment.isTipPayment && <div className={classes.paymentMethodLabel}>{translate('Tip')}</div>}
                {!!onRemove && !avoidRemovePayment && (
                    <Button icon onClick={() => onRemove(posPayment)} classes={{ button: classes.iconButton }}>
                        <CrossIcon title={'close icon'} width={16} height={16} />
                    </Button>
                )}
            </div>
            {posPayment.paymentReference && (
                <Text variant={'paragraph'} className={classes.paymentReferenceContainer}>
                    {posPayment.paymentReference}
                </Text>
            )}

            {isPaidByParts && <div className={classes.paymentMethodLabel}>{posPayment.customPaymentMethod ?? translate(posPayment.paymentMethod)}</div>}
        </div>
    );
}

const useStyles = makeStyles((theme) => ({
    backButton: {
        padding: 0,
        width: 'fit-content',
        height: 'fit-content',
        '&:hover': {
            cursor: 'pointer',
            backgroundColor: 'transparent',
        },
    },
    posPaymentCard: {
        fontFamily: theme.typography.regular,
        width: '100%',
        marginBottom: 12,
        borderRadius: 8,
        backgroundColor: '#F6F6F6',
        padding: '10px 20px',
    },
    firstLine: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
    },
    orderResumeText: {
        fontFamily: theme.typography.regular,
        fontSize: 14,
        color: '#2E3748',
    },
    paymentMethodLabel: {
        color: '#87888B',
        fontSize: 12,
    },
    iconButton: {
        height: 'fit-content',
        width: 'fit-content',
    },
    paymentReferenceContainer: {
        color: theme.palette.text.secondary,
    },
}));

type Props = {
    posPayment: PosPayment;
    onRemove?: any;
};
