import { isMobileApp } from 'src/utils/reactNative/isMobileApp';

export function setSoundMutedInMobileApp(muted: boolean) {
    if (isMobileApp()) {
        window.ReactNativeWebView.postMessage(
            JSON.stringify({
                type: 'SOUND_MUTED',
                data: muted,
            }),
        );
    }
}
