import * as React from 'react';
import { FormSelect } from 'src/components/form/FormSelect';
import type { Variant } from 'src/components/form/FormTypes';
import { PaymentTerminalPaymentStatuses } from 'src/constants/PaymentTerminalPaymentStatus';

export function FormPaymentTerminalPaymentStatusSelect({ name, label, placeholder, helperText, defaultValue, disabled, required, variant }: Props): React.ReactElement {
    const options = Object.values([PaymentTerminalPaymentStatuses.PAID, PaymentTerminalPaymentStatuses.FAILED]).map((terminalPaymentStatus: any) => ({
        label: terminalPaymentStatus.replace(/_/g, ' '),
        value: terminalPaymentStatus,
    }));

    return (
        <FormSelect
            name={name}
            label={label}
            placeholder={placeholder}
            helperText={helperText}
            defaultValue={defaultValue}
            options={options}
            disabled={disabled}
            required={required}
            variant={variant}
        />
    );
}

type Props = {
    name: string;
    label?: string;
    placeholder?: string;
    helperText?: string;
    defaultValue?: string;
    disabled?: boolean;
    required?:
        | boolean
        | {
              value: number;
              message: string;
          };
    variant?: Variant;
};
