import { useEffect } from 'react';
import { WebSocketEventTypes } from 'src/constants/WebSocketEventType';
import { translate } from 'src/i18n/translate';
import { useLoadMenuCategories } from 'src/services/menuCategory/useLoadMenuCategories';
import { useNotification } from 'src/services/notification/useNotification';
import type { MenuCategoryId, RestaurantId } from 'src/types/Id';
import type { WebSocketEvent } from 'src/types/WebSocketEvent';
import { useSelector } from 'src/utils/react/useSelector';
import { WebSocketEvents } from 'src/utils/webSocket/WebSocketEvents';

export function useMenuCategorySubscriptionUpdates() {
    const notification = useNotification();
    const loadMenuCategories = useLoadMenuCategories();

    const restaurantId = useSelector((state) => state.app.restaurantId);

    useEffect(() => {
        const menuCategoryCreateSyncWebSocketEvent = WebSocketEvents.addEventListener(WebSocketEventTypes.MENU_CATEGORY_CREATED, handleMenuCategoryWebSocketEvents);
        const menuCategoryChangedSyncWebSocketEvent = WebSocketEvents.addEventListener(WebSocketEventTypes.MENU_CATEGORY_CHANGED, handleMenuCategoryWebSocketEvents);
        const menuCategoryRemovedSyncWebSocketEvent = WebSocketEvents.addEventListener(WebSocketEventTypes.MENU_CATEGORY_REMOVED, handleMenuCategoryWebSocketEvents);

        return () => {
            menuCategoryCreateSyncWebSocketEvent.remove();
            menuCategoryChangedSyncWebSocketEvent.remove();
            menuCategoryRemovedSyncWebSocketEvent.remove();
        };
    }, [restaurantId]);

    const handleMenuCategoryWebSocketEvents = async (event: WebSocketEvent<MenuCategoryWebSocketEventResponse>) => {
        if (restaurantId !== event.data?.restaurantId) return;
        let message;
        const defaultMessage = translate('Synchronizing menu categories');

        switch (event.webSocketEventType) {
            case WebSocketEventTypes.MENU_CATEGORY_CREATED:
                message = translate(`Menu category @menuCategoryName created`, { menuCategoryName: event.data?.menuCategoryName });
                break;
            case WebSocketEventTypes.MENU_CATEGORY_CHANGED:
                message = translate(`Menu category @menuCategoryName changed`, { menuCategoryName: event.data?.menuCategoryName });
                break;
            case WebSocketEventTypes.MENU_CATEGORY_REMOVED:
                message = translate(`Menu category @menuCategoryName removed`, { menuCategoryName: event.data?.menuCategoryName });
                break;
            default:
                message = defaultMessage;
                break;
        }

        notification({ message: message });
        await loadMenuCategories();
    };
}

type MenuCategoryWebSocketEventResponse = {
    restaurantId: RestaurantId;
    menuCategoryId: MenuCategoryId;
    menuCategoryName: string;
};
