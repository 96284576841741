import { useEffect } from 'react';
import { WebSocketEventTypes } from 'src/constants/WebSocketEventType';
import { useLoadCashRegister } from 'src/services/cashRegister/useLoadCashRegister';
import { useLoadCashRegistersPosBusinessDay } from 'src/services/cashRegisterPosBusinessDay/useLoadCashRegistersPosBusinessDay';
import { useLoadPosBusinessDay } from 'src/services/posBusinessDay/useLoadPosBusinessDay';
import type { CashRegisterId, CashRegisterPosBusinessDayId, PosBusinessDayId, RestaurantId } from 'src/types/Id';
import type { WebSocketEvent } from 'src/types/WebSocketEvent';
import { useSelector } from 'src/utils/react/useSelector';
import { EventListenerRemover, WebSocketEvents } from 'src/utils/webSocket/WebSocketEvents';

export function usePosBusinessDaySubscriptionUpdates() {
    const loadPosBusinessDay = useLoadPosBusinessDay();
    const loadCashRegister = useLoadCashRegister();
    const loadCashRegistersPosBusinessDay = useLoadCashRegistersPosBusinessDay();

    const restaurantId = useSelector((state) => state.app.restaurantId);
    const openedPosBusinessDay = useSelector((state) => state.pos.openedPosBusinessDay);
    const posEnabled = useSelector((state) => state.app.restaurant?.posEnabled);
    const posMultipleCashRegistersEnabled = useSelector((state) => state.app.restaurant.posMultipleCashRegistersEnabled);

    useEffect(() => {
        let cashRegisterOpenedSyncWebSocketEvent: EventListenerRemover | undefined;
        let cashRegisterPosBusinessDayOpenedSyncWebSocketEvent: EventListenerRemover | undefined;
        let cashRegisterPosBusinessDayClosedSyncWebSocketEvent: EventListenerRemover | undefined;
        let assignedCashRegisterForExternalOrdersSyncWebSocketEvent: EventListenerRemover | undefined;
        let posBusinessDayOpenedSyncWebSocketEvent: EventListenerRemover | undefined;
        let posBusinessDayClosedSyncWebSocketEvent: EventListenerRemover | undefined;
        let personalPosBusinessDayOpenedSyncWebSocketEvent: EventListenerRemover | undefined;

        if (posEnabled || posMultipleCashRegistersEnabled) {
            cashRegisterOpenedSyncWebSocketEvent = WebSocketEvents.addEventListener(WebSocketEventTypes.CASH_REGISTER_OPENED, handlePosBusinessDayWebSocketEvents);
            cashRegisterPosBusinessDayOpenedSyncWebSocketEvent = WebSocketEvents.addEventListener(WebSocketEventTypes.CASH_REGISTER_POS_BUSINESS_DAY_OPENED, handlePosBusinessDayWebSocketEvents);
            cashRegisterPosBusinessDayClosedSyncWebSocketEvent = WebSocketEvents.addEventListener(WebSocketEventTypes.CASH_REGISTER_POS_BUSINESS_DAY_CLOSED, handlePosBusinessDayWebSocketEvents);
            assignedCashRegisterForExternalOrdersSyncWebSocketEvent = WebSocketEvents.addEventListener(
                WebSocketEventTypes.ASSIGNED_CASH_REGISTER_POS_BUSINESS_DAY_FOR_EXTERNAL_MISSING_ORDERS,
                handlePosBusinessDayWebSocketEvents,
            );
            posBusinessDayOpenedSyncWebSocketEvent = WebSocketEvents.addEventListener(WebSocketEventTypes.POS_BUSINESS_DAY_OPENED, handlePosBusinessDayWebSocketEvents);
            posBusinessDayClosedSyncWebSocketEvent = WebSocketEvents.addEventListener(WebSocketEventTypes.POS_BUSINESS_DAY_CLOSED, handlePosBusinessDayWebSocketEvents);
            personalPosBusinessDayOpenedSyncWebSocketEvent = WebSocketEvents.addEventListener(
                WebSocketEventTypes.POS_BUSINESS_DAY_AND_CASH_REGISTER_POS_BUSINESS_DAY_OPENED,
                handlePosBusinessDayWebSocketEvents,
            );
        }

        return () => {
            cashRegisterOpenedSyncWebSocketEvent?.remove();
            cashRegisterPosBusinessDayOpenedSyncWebSocketEvent?.remove();
            cashRegisterPosBusinessDayClosedSyncWebSocketEvent?.remove();
            assignedCashRegisterForExternalOrdersSyncWebSocketEvent?.remove();
            posBusinessDayOpenedSyncWebSocketEvent?.remove();
            posBusinessDayClosedSyncWebSocketEvent?.remove();
            personalPosBusinessDayOpenedSyncWebSocketEvent?.remove();
        };
    }, [restaurantId]);

    useEffect(() => {
        if (!openedPosBusinessDay && posEnabled) {
            loadPosBusinessDay();
        }
    }, [posEnabled]);

    useEffect(() => {
        if (openedPosBusinessDay && posMultipleCashRegistersEnabled) {
            loadCashRegistersPosBusinessDay();
        }
    }, [openedPosBusinessDay]);

    const handlePosBusinessDayWebSocketEvents = async (event: WebSocketEvent<PosBusinessDayWebSocketEventResponse>) => {
        if (restaurantId !== event.data?.restaurantId) return;

        switch (event.webSocketEventType) {
            case WebSocketEventTypes.CASH_REGISTER_POS_BUSINESS_DAY_OPENED:
            case WebSocketEventTypes.CASH_REGISTER_POS_BUSINESS_DAY_CLOSED:
            case WebSocketEventTypes.ASSIGNED_CASH_REGISTER_POS_BUSINESS_DAY_FOR_EXTERNAL_MISSING_ORDERS:
            case WebSocketEventTypes.POS_BUSINESS_DAY_CLOSED:
            case WebSocketEventTypes.POS_BUSINESS_DAY_AND_CASH_REGISTER_POS_BUSINESS_DAY_OPENED:
                await loadPosBusinessDay();
                if (posMultipleCashRegistersEnabled) await loadCashRegistersPosBusinessDay();
                break;

            case WebSocketEventTypes.CASH_REGISTER_OPENED:
            case WebSocketEventTypes.POS_BUSINESS_DAY_OPENED:
                await loadPosBusinessDay();
                await loadCashRegister();
                break;

            case WebSocketEventTypes.CASH_REGISTER_CLOSED:
                await loadCashRegister();
                break;

            default:
                await loadCashRegister();
                await loadPosBusinessDay();
                break;
        }
        console.log(`Updating posBusinessDay from websocket events`);
    };
}

type PosBusinessDayWebSocketEventResponse = {
    restaurantId: RestaurantId;
    cashRegisterId: CashRegisterId;
    posBusinessDayId: PosBusinessDayId;
    cashRegisterPosBusinessDayId: CashRegisterPosBusinessDayId;
};
