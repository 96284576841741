import { letseatmanagerApiMethod } from 'src/api/letseatmanagerApi';
import type { ApiSauceResponse } from 'src/api/types/ApiSauceResponse';
import type { InitialRoutePath } from 'src/constants/InitialRoutePaths';
import type { RolePermission } from 'src/constants/RolePermission';
import type { RestaurantId, UserRoleId } from 'src/types/Id';
import type { UserRoleVm } from 'src/types/UserRoleVm';

export function changeUserRoleApi(request: ChangeUserRoleApiRequest): ApiSauceResponse<UserRoleVm> {
    return letseatmanagerApiMethod('userRole/changeUserRoleApi', request);
}

type ChangeUserRoleApiRequest = {
    restaurantId: RestaurantId;
    userRoleId: UserRoleId;
    roleName: string;
    initialPage: InitialRoutePath;
    permissions: Array<RolePermission>;
};
