import { makeStyles } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { useFieldArray, useForm } from 'react-hook-form';
import { deprecatedCreateMenuItemApi } from 'src/api/letseatmanager/menuItem/deprecatedCreateMenuItemApi';
import { pidedirectouploadApi } from 'src/api/pidedirectouploadApi';
import { app2 } from 'src/app2';
import { Button } from 'src/components/Button';
import { Dialog } from 'src/components/Dialog';
import { DialogActions } from 'src/components/DialogActions';
import { Form } from 'src/components/form/Form';
import { FormCurrencyNumberStringField } from 'src/components/form/FormCurrencyNumberStringField';
import { FormTextField } from 'src/components/form/FormTextField';
import ImageUploader from 'src/components/input/ImageUploader';
import { MenuItemTypes } from 'src/constants/DeprecatedMenuItemType';
import { ImageTypes } from 'src/constants/ImageType';
import { MenuItemSizes } from 'src/constants/MenuItemSize';
import { translate } from 'src/i18n/translate';
import { ModifierGroup } from 'src/scenes/letseatmanager/deprecatedMenu/ModifierGroup';
import { alertKnownErrorOrSomethingWentWrong } from 'src/utils/alert/alertKnownErrorOrSomethingWentWrong';
import { isExternalImageUrl } from 'src/utils/image/isExternalImageUrl';
import { useAction } from 'src/utils/react/useAction';
import { useSelector } from 'src/utils/react/useSelector';
import { https } from 'src/utils/Validator';

export function CreateMenuItemDialog(): React.ReactElement {
    const classes = useStyles();
    const form = useForm();
    const { setValue, control } = form;
    const { fields, append, remove } = useFieldArray({
        control,
        name: 'modifierGroups',
    });

    const [loading, setLoading] = useState(false);
    const [uploadingImage, setUploadingImage] = useState(false);

    const internalUser = useSelector((state) => state.authentication.internalUser);
    const open = useSelector((state) => state.app2.createMenuItemDialog.open);
    const menuItem = useSelector((state) => state.app2.createMenuItemDialog.menuItem);
    const position = useSelector((state) => state.app2.createMenuItemDialog.position);
    const isExternalMenu = useSelector((state) => state.app2.createMenuItemDialog.isExternalMenu);
    const menuCategoryId = useSelector((state) => state.app2.createMenuItemDialog.menuCategoryId);
    const onSuccess = useSelector((state) => state.app2.createMenuItemDialog.onSuccess);
    const menuItemType = useSelector((state) => state.app2.createMenuItemDialog.menuItemType);
    const uberEatsStoreId = useSelector((state) => state.app.restaurant?.uberEatsStoreId);
    const restaurantId = useSelector((state) => state.app.restaurantId);

    const closeCreateMenuItemDialog = useAction(app2.actions.closeCreateMenuItemDialog);

    useEffect(() => {
        if (menuItem && menuItem?.modifierGroups?.length && fields.length !== menuItem?.modifierGroups.length) {
            for (const modifierGroup of menuItem.modifierGroups) {
                append(modifierGroup);
            }
        }
    }, [menuItem]);

    const handleClose = () => {
        if (uploadingImage || loading) return;
        closeCreateMenuItemDialog();
        setValue('modifierGroups', []);
    };

    const renderModifierGroups = () => {
        const addNewModifierGroup = () => append({ name: '', requiredMin: null, requiredMax: null, modifiers: [{ modifierId: null, name: '', price: '', hidden: false }] });
        return (
            <div style={{ width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center', marginTop: 40 }}>
                {fields.map((modifierGroup, modifierGroupIndex) => (
                    <Grid container spacing={3} key={modifierGroup.id}>
                        <Grid item xs={12}>
                            <ModifierGroup
                                loading={loading}
                                modifierGroup={modifierGroup as any}
                                modifierGroupIndex={modifierGroupIndex}
                                removeModifierGroup={() => remove(modifierGroupIndex)}
                                showHiddenSwitch={false}
                                control={control}
                                prevModifiers={menuItem?.modifierGroups[modifierGroupIndex]?.modifiers}
                            />
                        </Grid>
                    </Grid>
                ))}
                <div style={{ marginTop: 40 }}>
                    <Button outlined onClick={addNewModifierGroup} disabled={loading}>
                        {translate('ADD MODIFIER GROUP')}
                    </Button>
                </div>
            </div>
        );
    };

    const getImageUrl = async (imageUrl: any) => {
        if (isExternalImageUrl(imageUrl)) {
            const migrateResponse = await pidedirectouploadApi.images.migrate({
                imageUrl: imageUrl,
                imageType: ImageTypes.MENU_ITEM,
            });
            if (!migrateResponse.ok) {
                setLoading(false);
                alertKnownErrorOrSomethingWentWrong(migrateResponse);
                return;
            }
            return migrateResponse.data;
        }
        return imageUrl;
    };

    const handleSubmit = async (form: any) => {
        const areModifierGroupsOk = validateModifierGroups(form.modifierGroups || []);
        if (!areModifierGroupsOk) return;

        setLoading(true);
        const imageUrl = await getImageUrl(form.imageUrl);
        const response = await deprecatedCreateMenuItemApi({
            restaurantId,
            externalProductId: form.externalProductId,
            menuItemType,
            menuCategoryId: menuItemType === MenuItemTypes.MODIFIER ? undefined : menuCategoryId,
            position,
            name: form.name,
            description: form.description,
            contains: form.contains,
            price: form.price,
            promoText: form.promoText,
            promoPrice: form.promoPrice,
            kitchenPrice: form.kitchenPrice,
            size: MenuItemSizes.MEDIUM,
            imageUrl,
            modifierGroups: form.modifierGroups ?? [],
        });
        if (!response.ok) {
            setLoading(false);
            alertKnownErrorOrSomethingWentWrong(response);
            return;
        }
        setLoading(false);
        onSuccess();
        handleClose();
    };

    const validateModifierGroups = (modifierGroups: any) => {
        let areModifierGroupsOk = true;
        modifierGroups.forEach((modifierGroup: any, index: number) => {
            if (!areModifierGroupsOk) return;
            if (!!modifierGroup.modifiers?.length) return;

            areModifierGroupsOk = false;
            form.setError(`modifierGroups.${index}.name`, { message: translate('Can not be a modifier group without modifiers') });
        });
        return areModifierGroupsOk;
    };

    return (
        <Dialog classes={{ dialog: classes.form }} title={translate(menuItemType === MenuItemTypes.MODIFIER ? 'Create Modifier Item' : 'Create Menu Item')} open={open} onClose={handleClose}>
            <Form onSubmit={handleSubmit} form={form}>
                <Grid container spacing={3}>
                    <Grid item xs={12} sm={6}>
                        <FormTextField name='name' label={translate('Name')} required disabled={loading} defaultValue={menuItem?.name} />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <FormCurrencyNumberStringField name='price' label={translate('Price')} required disabled={loading} defaultValue={menuItem?.price} />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <FormTextField name='promoText' label={translate('Promo Text')} disabled={loading} defaultValue={menuItem?.promoText} />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <FormCurrencyNumberStringField name='promoPrice' label={translate('Promo Price')} disabled={loading} defaultValue={menuItem?.promoPrice} />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <FormTextField name='externalProductId' label={translate('External Product Id')} defaultValue={menuItem?.externalProductId} />
                    </Grid>
                    {internalUser && (
                        <Grid item xs={12} sm={12}>
                            <FormCurrencyNumberStringField name='kitchenPrice' label={translate('Real Kitchen Price')} disabled={loading} defaultValue={menuItem?.kitchenPrice} />
                        </Grid>
                    )}
                    <Grid item xs={12}>
                        <FormTextField
                            name='description'
                            label={translate('Description')}
                            disabled={loading}
                            classes={{ input: classes.inputDescription, error: classes.inputDescriptionError }}
                            defaultValue={menuItem?.description}
                            multiline
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <FormTextField name='contains' label={translate('Contains')} disabled={loading} defaultValue={menuItem?.contains} />
                    </Grid>
                    <Grid item xs={12}>
                        <FormTextField
                            name='imageUrl'
                            label={uploadingImage ? translate('Uploading Image...') : translate('Image')}
                            helperText={
                                uploadingImage
                                    ? undefined
                                    : translate(
                                          'Enter an URL to an image or drag and drop your image anywhere in the browser. For best result make sure image is 1280x854 pixels or at least similar aspect ratio and not lower resolution.',
                                      )
                            }
                            disabled={loading}
                            defaultValue={menuItem?.imageUrl}
                            validate={https}
                        />
                        <ImageUploader
                            imageType={ImageTypes.MENU_ITEM}
                            onUploadingChanged={(uploadingImage) => setUploadingImage(uploadingImage)}
                            onUploadSuccess={(imageUrl) => setValue('imageUrl', imageUrl)}
                            dimensions={isExternalMenu && uberEatsStoreId ? UBER_EATS_IMAGE_DIMENSIONS : undefined}
                        />
                    </Grid>
                </Grid>
                {menuItemType !== MenuItemTypes.MODIFIER && (
                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                            {renderModifierGroups()}
                        </Grid>
                    </Grid>
                )}
                <DialogActions className={(classes as any).buttonsContainer}>
                    <Button secondary onClick={handleClose} disabled={uploadingImage || loading}>
                        {translate('Cancel')}
                    </Button>
                    <Button type='submit' disabled={uploadingImage || loading}>
                        {loading ? translate('Creating') : translate('Create')}
                    </Button>
                </DialogActions>
            </Form>
        </Dialog>
    );
}

export const UBER_EATS_IMAGE_DIMENSIONS = {
    minWidth: 320,
    maxWidth: 6000,
    minHeight: 320,
    maxHeight: 6000,
} as const;

const useStyles = makeStyles((theme) => ({
    form: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        width: '70%',
        margin: '0 auto',
        [theme.breakpoints.down('sm')]: {
            width: '100%',
        },
    },
    inputDescription: {
        height: '15vh',
        alignItems: 'flex-start',
    },
    inputDescriptionError: {
        border: '2px solid red',
    },
}));
