import { makeStyles } from '@material-ui/core';
import * as React from 'react';
import { DateSelectDialog } from 'src/components/dialog/DateSelectDialog';
import { SecuredAndSubscribedPage } from 'src/components/page/SecuredAndSubscribedPage';
import { Tabs } from 'src/components/Tabs';
import { RolePermissions } from 'src/constants/RolePermission';
import { translate } from 'src/i18n/translate';
import { CustomerReport } from 'src/scenes/letseatmanager/restaurantDashboard/customerReport/CustomerReport';
import { DeliveryReport } from 'src/scenes/letseatmanager/restaurantDashboard/deliveryReport/DeliveryReport';
import { GeneralReport } from 'src/scenes/letseatmanager/restaurantDashboard/generalReport/GeneralReport';
import { IntegrationsReport } from 'src/scenes/letseatmanager/restaurantDashboard/integrationsPaymentReport/IntegrationsPaymentReport';
import { OrderIssueReport } from 'src/scenes/letseatmanager/restaurantDashboard/issueReport/OrderIssueReport';
import { PromoCodeReport } from 'src/scenes/letseatmanager/restaurantDashboard/promoCodeReport/PromoCodeReport';
import { useSelector } from 'src/utils/react/useSelector';
import { useUserHasRolePermission } from 'src/utils/react/useUserHasRolePermissions';

export function RestaurantGeneralReportPage(): React.ReactElement {
    const classes = useStyles();

    const [userHasRolePermission] = useUserHasRolePermission();

    const colors = [
        '#2476C2',
        '#20B2AA',
        '#F1948A',
        '#FFD700',
        '#53C7D7',
        '#7B68EE',
        '#FF8C00',
        '#40E0D0',
        '#FFA07A',
        '#9370DB',
        '#1E90FF',
        '#FFB6C1',
        '#0DFFD3',
        '#A0D8F8',
        '#A7A0F8',
        '#6256E9',
        '#00FF7F',
        '#8A2BE2',
        '#5DE3BC',
        '#24A8C2',
        '#13647D',
    ];

    const promoCodesEnabled = useSelector((state) => state.app.restaurant.restaurantPromoCodesEnabled);
    const deliveryEnabled = useSelector((state) => state.app.restaurant.deliveryEnabled);
    const integrationsEnabled = useSelector((state) => state.app.restaurant.integrationsEnabled);

    const tabs = [
        {
            value: '1',
            label: translate('General'),
            rolePermission: RolePermissions.GENERAL_REPORT,
        },
        {
            value: '2',
            label: translate('Customers report'),
            rolePermission: RolePermissions.CUSTOMERS_REPORT,
        },
        {
            value: '3',
            label: translate('Discounts'),
            hidden: !promoCodesEnabled,
            rolePermission: RolePermissions.PROMO_CODE_REPORT,
        },
        {
            value: '4',
            label: translate('Delivery'),
            hidden: !deliveryEnabled,
            rolePermission: RolePermissions.DELIVERIES_REPORT,
        },
        {
            value: '5',
            label: translate('Orders issues'),
            rolePermission: RolePermissions.ORDER_ISSUES_REPORT,
        },
        {
            value: '6',
            label: translate('Integrations'),
            hidden: !integrationsEnabled,
            rolePermission: RolePermissions.INTEGRATIONS_REPORT,
        },
    ];

    return (
        <SecuredAndSubscribedPage rolePermission={RolePermissions.REPORTS_PAGE} title={translate('Reports')} className={classes.container}>
            <DateSelectDialog />
            <Tabs tabs={tabs}>
                {userHasRolePermission(RolePermissions.GENERAL_REPORT) && <GeneralReport colors={colors} />}
                {userHasRolePermission(RolePermissions.CUSTOMERS_REPORT) && <CustomerReport />}
                {promoCodesEnabled && userHasRolePermission(RolePermissions.PROMO_CODE_REPORT) && <PromoCodeReport colors={colors} />}
                {deliveryEnabled && userHasRolePermission(RolePermissions.DELIVERIES_REPORT) && <DeliveryReport />}
                {userHasRolePermission(RolePermissions.ORDER_ISSUES_REPORT) && <OrderIssueReport />}
                {userHasRolePermission(RolePermissions.INTEGRATIONS_REPORT) && <IntegrationsReport />}
            </Tabs>
        </SecuredAndSubscribedPage>
    );
}

const useStyles = makeStyles((theme) => ({
    container: {
        width: '100%',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        [theme.breakpoints.down('sm')]: {
            padding: 12,
        },
    },
}));
