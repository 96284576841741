import * as React from 'react';
import { SwipeDrawer } from 'src/components/SwipeDrawer';
import { Menu } from 'src/scenes/letseatmanager/menu/menu/Menu';
import type { MenuVm } from 'src/types/MenuVm';
import { useIsSmallScreen } from 'src/utils/react/window/useIsSmallScreen';

export function MenuSection({ menu, onClose }: Props): React.ReactElement {
    const isSmallScreen = useIsSmallScreen();

    if (isSmallScreen) {
        return (
            <SwipeDrawer open={true} onClose={onClose} avoidSwipeInDrawerContent>
                <Menu menu={menu} onClose={onClose} />
            </SwipeDrawer>
        );
    }

    return <Menu menu={menu} onClose={onClose} />;
}

type Props = {
    menu: MenuVm;
    onClose: any;
};
