import { letseatmanagerApiMethod } from 'src/api/letseatmanagerApi';
import type { ApiSauceResponse } from 'src/api/types/ApiSauceResponse';
import type { RestaurantId } from 'src/types/Id';
import type { PrinterVm } from 'src/types/PrinterVm';

export async function findPrintersApi(request: FindPrintersApiRequest): ApiSauceResponse<Array<PrinterVm>> {
    return letseatmanagerApiMethod('printer/findPrintersApi', request);
}

export type FindPrintersApiRequest = {
    restaurantId: RestaurantId;
};
