import type { OrderItemVm } from 'src/types/OrderVm';
import { createOrderItemKey } from 'src/utils/pos/createOrderItemKey';

export function getOrderItemsFormattedToReturn(orderItems: Array<OrderItemVm>): Array<OrderItemToReturn> {
    const items: Array<OrderItemToReturn> = [];

    for (const orderItem of orderItems ?? []) {
        const posItem = { ...orderItem, key: createOrderItemKey(orderItem) } as const;

        const existingPosItem = items.find((current) => current.key === posItem.key);

        if (existingPosItem) {
            existingPosItem.quantity = existingPosItem.quantity + posItem.quantity;
        } else {
            items.push(posItem);
        }
    }

    return items;
}

export type OrderItemToReturn = OrderItemVm & {
    key?: string;
};
