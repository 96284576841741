import { App } from 'src/constants/App';
import { PaymentMethod, PaymentMethods } from 'src/constants/PaymentMethod';
import { CustomPaymentMethod, RappiCargoDeliveryId, UberDaasDeliveryOrderId } from 'src/types/Id';
import { translatePaymentMethod } from 'src/utils/translate/translatePaymentMethod';

export function translatePaymentMethodWithEmoji({ paymentMethod, rappiCargoDeliveryId, uberDaasDeliveryOrderId, hasPaymentMethodChangedToCard, app }: Params): string {
    const translation = translatePaymentMethod({ paymentMethod, rappiCargoDeliveryId, uberDaasDeliveryOrderId, hasPaymentMethodChangedToCard, app });

    if (rappiCargoDeliveryId || uberDaasDeliveryOrderId) {
        return `${translation} 💳`;
    }

    switch (paymentMethod) {
        case PaymentMethods.CASH: {
            return `${translation} 💵`;
        }
        case PaymentMethods.CREDIT_CARD:
        case PaymentMethods.PAYROLL: {
            return `${translation} 💳`;
        }
        default: {
            return translation ?? '';
        }
    }
}

type Params = {
    paymentMethod?: CustomPaymentMethod | PaymentMethod;
    rappiCargoDeliveryId?: RappiCargoDeliveryId;
    uberDaasDeliveryOrderId?: UberDaasDeliveryOrderId;
    app?: App;
    hasPaymentMethodChangedToCard?: boolean;
    addEmoji?: boolean;
};
