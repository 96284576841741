import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { getTipApi, TipVm } from 'src/api/letseatadmin/tip/getTipApi';
import { Field, Form } from 'src/components/deprecated/react-final-form/Form';
import TextField from 'src/components/deprecated/react-final-form/TextField';
import { RefreshToolbarButton } from 'src/components/mui-datatables/RefreshToolbarButton';
import { UpdatingContentProgress } from 'src/components/UpdatingContentProgress';
import { translate } from 'src/i18n/translate';
import type { TipId } from 'src/types/Id';
import { alertKnownErrorOrSomethingWentWrong } from 'src/utils/alert/alertKnownErrorOrSomethingWentWrong';

export default function TipDetails({ tipId, onChangeTip }: Props): React.ReactElement {
    const classes = useStyles();
    const [loading, setLoading] = useState(false);
    const [tip, setTip] = useState(undefined as TipVm | undefined);

    useEffect(() => {
        if (!tipId) return;
        load();
    }, [tipId]);

    const load = async () => {
        if (!tipId) {
            return;
        }
        setLoading(true);
        const response = await getTipApi({ tipId });
        if (!response.ok) {
            setLoading(false);
            alertKnownErrorOrSomethingWentWrong(response);
            return;
        }
        const tip = response.data;
        setTip(response.data);
        setLoading(false);
        onChangeTip && onChangeTip(tip);
    };

    return (
        <div style={{ position: 'relative' }}>
            <UpdatingContentProgress loading={loading} />
            <Card classes={{ root: classes.root }}>
                <CardHeader
                    title={
                        <Typography display='block' gutterBottom variant='h6'>
                            {translate('Tip Details')}
                        </Typography>
                    }
                    action={
                        <div>
                            <RefreshToolbarButton onClick={load} />
                        </div>
                    }
                />
                <CardContent>
                    <Form
                        onSubmit={() => {}}
                        initialValues={
                            tip
                                ? {
                                      tip: {
                                          ...tip,
                                      },
                                  }
                                : {}
                        }
                        render={({ handleSubmit, submitting, pristine, values }) => (
                            <>
                                <Grid container spacing={3}>
                                    <Grid item xs={6} sm={4}>
                                        <Field name='tip.paymentStatus' component={TextField} label={translate('Payment Status')} fullWidth disabled />
                                    </Grid>
                                    <Grid item xs={6} sm={4}>
                                        <Field name='tip.tipAmount' component={TextField} label={translate('Amount')} fullWidth disabled />
                                    </Grid>
                                </Grid>
                            </>
                        )}
                    />
                </CardContent>
            </Card>
        </div>
    );
}

const useStyles = makeStyles((theme) => ({
    linearProgress: {
        position: 'absolute',
        width: '100%',
        bottom: 0,
    },
    root: {
        paddingLeft: 8,
        paddingRight: 8,
    },
}));

type Props = {
    tipId?: TipId;
    onChangeTip?: (arg1: TipVm) => any;
};
