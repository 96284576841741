import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import * as React from 'react';
import { isString } from 'src/utils/string/isString';

/* eslint-disable react/prop-types, react/jsx-handler-names */

export default function SelectTypeahead(props: any): React.ReactElement | null {
    const {
        input: { name, value, onChange, ...restInput },
        placeholder,
        meta,
        fullWidth,
        formControlProps,
        selectMultiple,
        creatable,
        minLength,
        label,
        helperText,
        ...rest
    } = props;
    const showError = meta && ((meta.submitError && !meta.dirtySinceLastSubmit) || meta.error) && meta.touched;

    const handleChange = (event: any, option: any) => {
        const formValue = mapFromReactSelectOption(option);
        onChange(formValue);
    };

    const mapFromReactSelectOption = (option: any) => {
        if (!option) {
            return null;
        }
        if (selectMultiple) {
            return option.map((o: any) => (isString(o) ? o : o.value));
        }
        return isString(option) ? option : option.value;
    };

    const mapToReactSelectOption = (formValue: any) => {
        if (!formValue) {
            return [];
        }
        if (selectMultiple) {
            return formValue.map((formValue: any) => {
                let option = props.options.find((option: any) => option.value === formValue);
                if (!option) {
                    option = { label: formValue, value: formValue };
                }
                return option;
            });
        }
        return props.options.find((option: any) => option.value === formValue);
    };

    return (
        <FormControl fullWidth={fullWidth} error={showError}>
            <Autocomplete
                {...rest}
                //                onChange={handleChange}
                //                inputProps={restInput}
                //                value={mapToReactSelectOption(value)}
                //                error={showError}
                //                creatable={creatable} =>                 freeSolo={creatable}
                //                minLength={minLength} 2 for customer

                multiple={selectMultiple}
                onChange={handleChange}
                freeSolo={creatable}
                options={props.options}
                getOptionLabel={(option: any) => option.label}
                value={mapToReactSelectOption(value)}
                blurOnSelect={true}
                autoHighlight
                renderInput={(params) => (
                    <TextField
                        {...params}
                        {...restInput}
                        name={name}
                        variant='standard'
                        label={label}
                        inputProps={{
                            ...params.inputProps,
                            autoComplete: 'no',
                        }}
                    />
                )}
            />

            {(showError || helperText) && <FormHelperText>{meta.error || meta.submitError || helperText}</FormHelperText>}
        </FormControl>
    );
}
