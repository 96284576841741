export const appColors = {
    text: {
        info: '#17a2b8',
        success: '#28a745',
        warning: '#ffc107',
        danger: '#dc3545',
        dangerLight: '#e9818b',
    },
    icon: {
        info: '#17a2b8',
        success: '#28a745',
        warning: '#ffc107',
        danger: '#dc3545',
        dangerLight: '#e9818b',
    },
    switch: {
        danger: '#dc3545',
    },
    table: {
        transparent: 'transparent',
        white: '#ffffff',
        blue: '#e2eef8',
        red: '#f8e2e2',
        orange: '#f8ece2',
        yellow: '#f4f8e2',
        green: '#e4f8e2',
    },
} as const;
