import { makeStyles } from '@material-ui/core/styles';
import { useContext } from 'react';
import * as React from 'react';
import { PageContext } from 'src/components/Page';
import { createUserClickedButtonLogEvent } from 'src/services/logEvent/createUserClickedButtonLogEvent';
import { classNames } from 'src/utils/react/classNames';
import { convertReactNodeToString } from 'src/utils/react/convertReactNodeToString';

/**
 * @callback onClick
 */

/**
 *
 * @param {React.Node} children - content inside the button
 * @param {boolean} [disabled] - button disabled control
 * @param {boolean} [secondary] - renders button secondary version
 * @param {boolean} [outlined] - renders button outlined version
 * @param {boolean} [text] - renders button text version
 * @param {boolean} [icon] - renders button icon version
 * @param {string} [type] - html button type [docs]{@link https://developer.mozilla.org/en-US/docs/Web/HTML/Element/input/button}
 * @param {string} [id] - html button id
 * @param {string} [color] - specifies the color variant for the secondary button
 * @param {string} [ ariaLabel] - html button aria-label [docs]{@link https://developer.mozilla.org/en-US/docs/Web/Accessibility/ARIA/Attributes/aria-label}
 * @param {Object} [classes] - classes object to override the default styles
 * @param {string} [classes.button] - class to override button style
 * @param {string} [classes.disabled] - class to override button disabled state style
 * @param {onClick} [onClick] - function to be called when the button is clicked
 * @returns {React.Node}
 */
export function Button({
    children,
    disabled,
    secondary,
    outlined,
    text,
    icon,
    type,
    id,
    name,
    color,
    classes: classesProp,
    onClick,
    larger,
    onMouseEnter,
    onMouseLeave,
    ['data-testid']: dataTestId,
    ariaLabel,
}: Props): React.ReactElement {
    const classes = useStyles();
    const pageContext = useContext(PageContext);

    const getClassNames = () => {
        let buttonClasses = classes.button;

        if (secondary) buttonClasses = classNames(buttonClasses, classes.secondaryButton);
        if (outlined) buttonClasses = classNames(buttonClasses, classes.outlinedButton);
        if (text) buttonClasses = classNames(buttonClasses, classes.textButton);
        if (icon) buttonClasses = classNames(buttonClasses, classes.iconButton);
        if (larger) buttonClasses = classNames(buttonClasses, classes.largerButton);

        if (disabled) buttonClasses = classNames(buttonClasses, classes.disabled);

        if (classesProp?.button) buttonClasses = classNames(buttonClasses, classesProp?.button);
        if (classesProp?.disabled && disabled) buttonClasses = classNames(buttonClasses, classesProp?.disabled);

        return buttonClasses;
    };

    const handleClick = (e: any) => {
        const buttonName = convertReactNodeToString(children);
        createUserClickedButtonLogEvent({ pageContext, buttonName });
        pageContext.clearStackTrace();

        e.currentTarget.blur();

        if (onClick) onClick?.(e);
    };

    return (
        <button
            type={type ?? 'button'}
            className={getClassNames()}
            onClick={handleClick}
            onMouseEnter={onMouseEnter}
            onMouseLeave={onMouseLeave}
            disabled={disabled}
            id={id}
            name={name}
            data-test-id={dataTestId}
            aria-label={ariaLabel}
        >
            {children}
        </button>
    );
}

const useStyles = makeStyles((theme) => ({
    button: {
        position: 'relative',
        border: 0,
        padding: '8px 12px',
        width: 'fit-content',
        borderRadius: 6,
        backgroundColor: theme.palette.surface.brandContrast,
        color: theme.palette.text.invert,
        fontFamily: theme.typography.regular,
        cursor: 'pointer',
        transition: 'background-color 0.2s linear',
        boxSizing: 'border-box',
        transformOrigin: 'center',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        gap: 8,
        minHeight: 42,
        justifyContent: 'center',
        '&:hover': {
            backgroundColor: theme.palette.surface.brandHeavyContrast,
        },
        '&:focus': {
            outline: `2px solid ${theme.palette.surface.brandSecondary}`,
        },
        textTransform: 'none',
    },
    largerButton: {
        height: 52,
        fontSize: 16,
    },
    secondaryButton: () => ({
        border: `1px solid ${theme.palette.border.primary}`,
        backgroundColor: 'transparent',
        color: `${theme.palette.text.secondary}`,
        padding: '8px 12px',
        '&:hover': {
            border: `1px solid ${theme.palette.border.tertiary}`,
            backgroundColor: theme.palette.surface.tertiary,
        },
        '&:focus': {
            outline: `2px solid ${theme.palette.surface.brandSecondary}`,
        },
    }),
    outlinedButton: {
        border: `1px solid ${theme.palette.border.brandContrast}`,
        backgroundColor: 'transparent',
        color: theme.palette.text.brand,
        padding: '8px 12px',
        '&:hover': {
            backgroundColor: theme.palette.surface.brand,
            border: `1px solid ${theme.palette.border.brand}`,
        },
        '&:focus': {
            outline: `2px solid ${theme.palette.surface.brandSecondary}`,
        },
    },
    textButton: {
        width: '100%',
        border: '0px',
        backgroundColor: 'transparent',
        color: theme.palette.text.brand,
        padding: 0,
        '&:hover': {
            textDecoration: 'underline',
            backgroundColor: 'transparent',
        },
        '&:focus': {
            textDecoration: 'underline',
            outline: 0,
        },
    },
    iconButton: {
        backgroundColor: 'transparent',
        width: 48,
        height: 48,
        borderRadius: 48,
        color: theme.palette.icons.primary,
        '&:hover': {
            backgroundColor: theme.palette.surface.brand,
            outline: `1px solid ${theme.palette.border.brand}`,
        },
        '&:focus': {
            border: 'none',
            outline: `2px solid ${theme.palette.surface.brandSecondary}`,
        },
    },
    disabled: {
        backgroundColor: `${theme.palette.surface.secondary} !important`,
        color: `${theme.palette.text.contrast} !important`,
        cursor: 'default',
        border: 'none !important',
        '&:hover': {
            backgroundColor: theme.palette.surface.secondary,
        },
        '&:focus': {
            outline: 'unset',
        },
    },
}));

type Props = {
    children: any;
    primary?: boolean;
    secondary?: boolean;
    outlined?: boolean;
    text?: boolean;
    icon?: boolean;
    larger?: boolean;
    type?: 'submit' | 'reset' | 'button';
    id?: string;
    name?: string;
    color?: string;
    ['data-testid']?: string;
    ariaLabel?: string;
    disabled?: boolean;
    classes?: {
        button?: string;
        disabled?: string;
    };
    onClick?: any;
    onMouseEnter?: any;
    onMouseLeave?: any;
};
