import { makeStyles } from '@material-ui/core/styles';
import * as React from 'react';
import { useForm } from 'react-hook-form';
import { Button } from 'src/components/Button';
import { Dialog } from 'src/components/Dialog';
import { DialogActions } from 'src/components/DialogActions';
import { Form } from 'src/components/form/Form';
import { FormTextField } from 'src/components/form/FormTextField';
import { translate } from 'src/i18n/translate';
import { getPosInterfaceLargerEnabledRestaurantSettingInLocalStorage } from 'src/localStorage/getPosInterfaceLargerEnabledRestaurantSettingInLocalStorage';
import { posReducer } from 'src/reducers/posReducer';
import { useAction } from 'src/utils/react/useAction';
import { useSelector } from 'src/utils/react/useSelector';

export function AddMenuItemNoteDialog(): React.ReactElement {
    const classes = useStyles();
    const form = useForm();

    const posInterfaceLargerEnabled = getPosInterfaceLargerEnabledRestaurantSettingInLocalStorage();

    const open = useSelector((state) => state.pos.addMenuItemNoteDialogState.open);
    const onSuccess = useSelector((state) => state.pos.addMenuItemNoteDialogState.onSuccess);

    const closeAddMenuItemNoteDialog = useAction(posReducer.actions.closeAddMenuItemNoteDialog);

    const onSubmit = async (form: any) => {
        await onSuccess?.(form.note);
        closeAddMenuItemNoteDialog(false);
    };

    const handleClose = () => {
        closeAddMenuItemNoteDialog(false);
    };

    return (
        <Dialog open={open} onClose={handleClose} title={translate('Add item note')} classes={{ dialog: (classes as any).dialog }}>
            <Form form={form} onSubmit={onSubmit} className={(classes as any).dialogContainer}>
                <FormTextField name='note' label={translate('Note')} required />
                <DialogActions>
                    <Button larger={posInterfaceLargerEnabled} secondary classes={{ button: classes.button }} onClick={handleClose}>
                        {translate('Cancel')}
                    </Button>
                    <Button disabled={form.formState.isSubmitting} type='submit' larger={posInterfaceLargerEnabled} classes={{ button: classes.button }}>
                        {translate('Add')}
                    </Button>
                </DialogActions>
            </Form>
        </Dialog>
    );
}

const useStyles = makeStyles((theme) => ({
    largerButton: {
        fontSize: 14,
        height: 52,
    },
    button: {
        width: '50%',
    },
    submitButton: {
        background: '#06B7A2',
    },
    secondaryButton: {
        color: '#06B7A2',
        border: `1px solid #06B7A2`,
        background: 'transparent',
    },
}));
