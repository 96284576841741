import { makeStyles, useTheme } from '@material-ui/core/styles';
import * as React from 'react';
import { Button } from 'src/components/Button';
import { Text } from 'src/components/Text';
import { translate } from 'src/i18n/translate';
import { PrinterIcon } from 'src/icons/PrinterIcon';
import { DeviceGroupId } from 'src/types/Id';
import type { PrinterVm } from 'src/types/PrinterVm';
import { classNames } from 'src/utils/react/classNames';

export function PrinterItem({ printer, onSelectPrinter, isSelected }: Props): React.ReactElement {
    const classes = useStyles();
    const theme = useTheme();

    const handleClick = () => {
        onSelectPrinter(printer);
    };

    return (
        <Button key={printer.printerId} classes={{ button: classNames(classes.printerItem, isSelected ? classes.printerItemSelected : '') }} onClick={handleClick}>
            <div className={classes.printerTitle}>
                <PrinterIcon className={classes.icon} color={theme.palette.primary.main} />
                <Text size='large' variant='semiBold'>
                    {printer?.deviceName ?? printer.printerBrand}
                </Text>
            </div>
            <div style={{ display: 'flex', gap: 4 }}>
                <Text>{printer?.printerType}</Text>
                <Text>{printer?.printerContent ?? translate('ALL')}</Text>
            </div>
            <ul className={classes.deviceGroups}>
                {printer.deviceGroups?.map((deviceGroup: { deviceGroupId: DeviceGroupId; deviceGroupName: string }) => {
                    return <li className={classes.deviceGroup}>{deviceGroup.deviceGroupName}</li>;
                })}
            </ul>
        </Button>
    );
}

const useStyles = makeStyles((theme) => ({
    printerItem: {
        backgroundColor: 'transparent',
        border: '1px solid #D9D9D9',
        borderRadius: 8,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        justifyContent: 'center',
        gap: 12,
        cursor: 'pointer',
        width: '100%',
        padding: '12px',
        height: 'fit-content',
        minHeight: 120,
        '&:hover': {
            border: `1px solid ${theme.palette.primary.main}`,
            backgroundColor: `${theme.palette.primary.main}20`,
        },
    },
    printerTitle: {
        display: 'flex',
        alignItems: 'flex-start',
        width: '100%',
        gap: 8,
    },
    icon: {
        height: 24,
        maxHeight: 24,
        width: 24,
        maxWidth: 24,
    },
    deviceGroups: {
        listStyle: 'none',
        display: 'flex',
        flexWrap: 'wrap',
        gap: 8,
        margin: 0,
        padding: 0,
    },
    deviceGroup: {
        color: '#7E8EA1',
        padding: '2px 8px',
        backgroundColor: '#F3F3F4',
        borderRadius: 8,
        fontSize: 12,
        fontFamily: theme.typography.regular,
    },
    printerItemSelected: {
        border: `1px solid ${theme.palette.primary.main}`,
        backgroundColor: `${theme.palette.primary.main}20`,
    },
}));

type Props = {
    printer: PrinterVm;
    onSelectPrinter: any;
    isSelected: boolean;
};
