import { letseatadminApiMethod } from 'src/api/letseatadminApi';
import type { ApiSauceResponse } from 'src/api/types/ApiSauceResponse';
import type { PalencaApp } from 'src/constants/PalencaApp';
import type { PalencaDriverId } from 'src/types/Id';

export async function findDriverInPalencaApi(request: FindDriverInPalencaApiRequest): ApiSauceResponse<PalencaDriverVm> {
    return letseatadminApiMethod('driver/findDriverInPalencaApi', request);
}

export type FindDriverInPalencaApiRequest = {
    palencaDriverId: PalencaDriverId;
    company: PalencaApp;
};

export type PalencaDriverVm = {
    profile: ProfileVm;
};

type ProfileVm = {
    platform: string;
    country: string;
    rating: string;
    lifetimeTrips: string;
    levelName: string;
    levelNumber: string;
    metricsInfo: {
        acceptanceRate: string;
        points: string;
    };
    listVehicleInfo: {
        transportMediaType: string;
    };
    otherInfo: {
        debtPaid: string;
        debtAccumulated: string;
        debtPending: string;
        hasUber: string;
        cashless: string;
        isActive: string;
        isExpert: string;
    };
};
