import { Apps } from 'src/constants/App';

export const IntegrationPlatforms = Object.freeze({
    UBER_EATS: Apps.UBER_EATS,
    RAPPI: Apps.RAPPI,
    DIDI_FOOD: Apps.DIDI_FOOD,
    PEDIDOS_YA: Apps.PEDIDOS_YA,
});

export type IntegrationPlatform = (typeof IntegrationPlatforms)[keyof typeof IntegrationPlatforms];
