import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import { useEffect, useState } from 'react';
import * as React from 'react';
import { removeMenuApi } from 'src/api/letseatmanager/menu/removeMenuApi';
import { removeMenuWithLinkedMenuElementsApi } from 'src/api/letseatmanager/menu/removeMenuWithLinkedMenuElementsApi';
import { Checkbox } from 'src/components/Checkbox';
import { RemoveDialog } from 'src/components/dialog/RemoveDialog';
import { Text } from 'src/components/Text';
import { ProblemResponseTypes } from 'src/constants/ProblemResponseType';
import { WebSocketEventTypes } from 'src/constants/WebSocketEventType';
import { translate } from 'src/i18n/translate';
import { useNotification } from 'src/services/notification/useNotification';
import { MenuId, RestaurantId } from 'src/types/Id';
import type { MenuVm } from 'src/types/MenuVm';
import { WebSocketEvent } from 'src/types/WebSocketEvent';
import { alertKnownErrorOrSomethingWentWrong } from 'src/utils/alert/alertKnownErrorOrSomethingWentWrong';
import { useSelector } from 'src/utils/react/useSelector';
import { WebSocketEvents } from 'src/utils/webSocket/WebSocketEvents';

export function RemoveMenuDialog({ open, menu, onClose, onSuccess }: Props): React.ReactElement {
    const notification = useNotification();
    const classes = useStyles();

    const [acceptRemoveLinkedItems, setAcceptRemoveLinkedItems] = useState(false);
    const [hasRemoveMenuApiTimedOut, setHasRemoveMenuApiTimedOut] = useState(false);
    const [isRemovingMenu, setIsRemovingMenu] = useState(false);

    const restaurantId = useSelector((state) => state.app.restaurantId);

    useEffect(() => {
        const menuRemovedSyncWebSocketEvent = WebSocketEvents.addEventListener(WebSocketEventTypes.MENU_REMOVED, handleMenuWebSocketEvents);
        return () => {
            menuRemovedSyncWebSocketEvent.remove();
        };
    }, [restaurantId]);

    useEffect(() => {
        if (isRemovingMenu || !hasRemoveMenuApiTimedOut) return;
        onSuccess?.();
        setHasRemoveMenuApiTimedOut(false);
        handleClose();
    }, [isRemovingMenu]);

    const handleClose = () => {
        if (isRemovingMenu) return;
        onClose();
    };

    const handleChange = () => {
        setAcceptRemoveLinkedItems(!acceptRemoveLinkedItems);
    };

    const handleMenuWebSocketEvents = async (event: WebSocketEvent<MenuRemovedWebSocketEventResponse>) => {
        if (restaurantId !== event.data?.restaurantId) return;
        switch (event.webSocketEventType) {
            case WebSocketEventTypes.MENU_REMOVED:
                notification({ message: translate('Menu removed successfully') });
                setIsRemovingMenu(false);
                break;
        }
    };

    const removeMenu = async () => {
        if (!menu?.menuId) return;

        setIsRemovingMenu(true);
        const response = acceptRemoveLinkedItems ? await removeMenuWithLinkedMenuElementsApi({ menuId: menu?.menuId }) : await removeMenuApi({ menuId: menu?.menuId });
        setIsRemovingMenu(false);
        if (!response.ok) {
            if (response.problem === ProblemResponseTypes.TIMEOUT_ERROR) {
                setHasRemoveMenuApiTimedOut(true);
                return true;
            }
            alertKnownErrorOrSomethingWentWrong(response);
            return;
        }
        onSuccess?.();
    };

    return (
        <RemoveDialog
            open={open}
            title={translate('Remove menu')}
            subtitle={translate('You are about to remove the menu @menuName, are you sure you want to continue?', { menuName: menu?.name })}
            onSubmit={removeMenu}
            onClose={handleClose}
            disabled={hasRemoveMenuApiTimedOut || isRemovingMenu}
        >
            <Grid className={classes.checkBox}>
                <Checkbox name={'acceptRemoveLinkedItems'} value={undefined} label={translate('Remove everything linked to menu')} checked={acceptRemoveLinkedItems} onChange={handleChange} />
            </Grid>
            {hasRemoveMenuApiTimedOut && (
                <Text className={classes.dialogMessage} error>
                    {translate(
                        'The menu to be removed is very large so it will take a little longer than normal, when the removal is finished this notification will close and update the menu automatically. (Please do not close manually)',
                    )}
                </Text>
            )}
            {isRemovingMenu && <Text className={classes.dialogMessage}>{translate('Action in progress. Please wait for the menu to finish processing.')}</Text>}
        </RemoveDialog>
    );
}

const useStyles = makeStyles((theme) => ({
    checkBox: {
        textAlign: 'center',
        paddingLeft: 60,
        marginTop: 20,
        marginBottom: 10,
    },
    dialogMessage: {
        textAlign: 'center',
        paddingLeft: 30,
        paddingRight: 30,
        width: '40vw',
        [theme.breakpoints.down('md')]: {
            width: '60vw',
        },
        [theme.breakpoints.down('sm')]: {
            width: '70vw',
        },
        marginTop: 10,
        marginInline: 'auto',
    },
}));

type Props = {
    open: boolean;
    menu?: MenuVm;
    onClose: any;
    onSuccess?: any;
};

type MenuRemovedWebSocketEventResponse = {
    restaurantId: RestaurantId;
    menuId: MenuId;
    menuName?: string;
    restaurantName?: string;
};
