import * as React from 'react';
import type { PaymentMethod } from 'src/constants/PaymentMethod';
import { CashIcon } from 'src/icons/CashIcon';
import { CashTransferIcon } from 'src/icons/CashTransferIcon';
import { CreditCardIcon } from 'src/icons/CreditCardIcon';
import { LinkIcon } from 'src/icons/LinkIcon';
import type { CustomPaymentMethod } from 'src/types/Id';
import { isCardPayment } from 'src/utils/paymentMethod/isCardPayment';
import { isCashPayment } from 'src/utils/paymentMethod/isCashPayment';
import { isPaymentLinkPayment } from 'src/utils/paymentMethod/isPaymentLinkPayment';

export function PaymentMethodIcon({ paymentMethod, isSelected }: Props): React.ReactElement {
    if (isCardPayment(paymentMethod)) {
        return <CreditCardIcon title={'card icon'} color={isSelected ? '#63D6B3' : '#6C7076'} />;
    }

    if (isCashPayment(paymentMethod)) {
        return <CashIcon title={'cash icon'} color={isSelected ? '#63D6B3' : '#6C7076'} />;
    }

    if (isPaymentLinkPayment(paymentMethod)) {
        return <LinkIcon title={'link icon'} color={isSelected ? '#63D6B3' : '#6C7076'} />;
    }

    return <CashTransferIcon title={'cash transfer icon'} color={isSelected ? '#63D6B3' : '#6C7076'} />;
}

type Props = {
    paymentMethod?: PaymentMethod | CustomPaymentMethod;
    isSelected?: boolean;
};
