import { Bank, Banks } from 'src/constants/Bank';
import { isString } from 'src/utils/string/isString';

export function requireBank<T extends string>(value: T | undefined | null): Bank {
    if (value === undefined) {
        throw new Error('Required value is undefined when it should be a Bank');
    }
    if (value === null) {
        throw new Error('Required value is null when it should be a Bank');
    }
    if (!isString(value)) {
        throw new Error(`Required value is ${value} when it should be a Bank`);
    }
    if (!Object.values(Banks).includes(value as Bank)) {
        throw new Error(`Required value is "${value as any}" when it should be a Bank`);
    }
    return value as any;
}
