import { letseatmanagerApiMethod } from 'src/api/letseatmanagerApi';
import type { ApiSauceResponse } from 'src/api/types/ApiSauceResponse';
import type { CustomDiscountComment } from 'src/constants/CustomDiscountComment';
import type { DiscountType } from 'src/constants/DiscountType';
import type { CourseOrderDetailsVm } from 'src/types/CourseOrderDetailsVm';
import type { CustomerOrderDetailsVm } from 'src/types/CustomerOrderDetailsVm';
import { PromotionId, type CustomerId, type OrderId, type PromoCodeId, type RestaurantId } from 'src/types/Id';
import type { OrderItemVm, OrderVm } from 'src/types/OrderVm';

export async function closeOrderApi(request: CloseOrderApiRequest): ApiSauceResponse<OrderVm> {
    return letseatmanagerApiMethod('pos/closeOrderApi', request);
}

export type CloseOrderApiRequest = {
    orderId: OrderId;
    restaurantId: RestaurantId;
    customerId?: CustomerId;
    promoCodeId?: PromoCodeId;
    promotionIds?: Array<PromotionId>;
    firstName?: string;
    lastName?: string;
    mobileNumber?: string;
    email?: string;
    customerNote?: string;
    notes?: string;
    numberOfCustomers?: number;
    orderItems?: Array<OrderItemVm>;
    cancelledItems?: Array<OrderItemVm>;
    posDiscountPercentage?: string;
    posDiscount?: {
        discountType: DiscountType;
        discount: string;
        discountPercentage?: string;
        notes?: string | CustomDiscountComment;
    };
    useLetsEatCredits?: boolean;
    usedCustomerCredits?: string;
    customers?: Array<CustomerOrderDetailsVm>;
    courses?: Array<CourseOrderDetailsVm>;
};
