import { makeStyles } from '@material-ui/core';
import * as React from 'react';
import { RefreshToolbarButton } from 'src/components/mui-datatables/RefreshToolbarButton';
import { Table } from 'src/components/Table';
import { translate } from 'src/i18n/translate';
import { useFormatAsRestaurantCurrencyNumber } from 'src/services/restaurant/useFormatAsRestaurantCurrencyNumber';
import type { InventorySuppliersReportVm } from 'src/types/InventorySuppliersReportVm';
import { formatDateString } from 'src/utils/date/formatDateString';

export function InventorySuppliersReportTable({ loading, inventorySuppliersReport, refresh, onRowClick }: Props): React.ReactElement {
    const classes = useStyles();
    const formatAsCurrencyNumber = useFormatAsRestaurantCurrencyNumber();

    return (
        <div className={classes.container}>
            <Table
                loading={loading}
                data={inventorySuppliersReport?.map((inventorySupplierReport: InventorySuppliersReportVm) => {
                    return {
                        businessName: inventorySupplierReport.businessName,
                        lastPurchaseDate: formatDateString(inventorySupplierReport.lastPurchaseDate),
                        totalBought: formatAsCurrencyNumber(inventorySupplierReport.totalBought),
                    };
                })}
                columns={[
                    {
                        name: 'businessName',
                        label: translate('Supplier'),
                        options: {
                            filter: false,
                            customBodyRender: (value) => <div style={{ minWidth: '100px' }}>{value}</div>,
                        },
                    },
                    {
                        name: 'lastPurchaseDate',
                        label: translate('Last Purchase Date'),
                        options: {
                            filter: false,
                            customBodyRender: (value) => <div style={{ minWidth: '100px' }}>{value}</div>,
                        },
                    },
                    {
                        name: 'totalBought',
                        label: translate('Total Bought'),
                        options: {
                            filter: false,
                            customBodyRender: (value) => <div style={{ minWidth: '100px' }}>{value}</div>,
                        },
                    },
                ]}
                options={{
                    responsive: 'standard',
                    tableBodyMaxHeight: '500px',
                    selectableRows: 'none',
                    filterType: 'checkbox',
                    rowsPerPage: 100,
                    customToolbar: () => (
                        <>
                            <RefreshToolbarButton onClick={refresh} />
                        </>
                    ),
                    onRowClick: (rowData, rowMeta) => {
                        onRowClick(inventorySuppliersReport[rowMeta.dataIndex]);
                    },
                    filter: false,
                }}
            />
        </div>
    );
}

const useStyles = makeStyles((theme) => ({
    container: {
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        [theme.breakpoints.down('sm')]: {
            padding: 12,
        },
    },
}));

export type Props = {
    loading: boolean;
    inventorySuppliersReport: Array<InventorySuppliersReportVm>;
    refresh: any;
    onRowClick: any;
};
