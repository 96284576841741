import { letseatadminApiMethod } from 'src/api/letseatadminApi';
import type { ApiSauceResponse } from 'src/api/types/ApiSauceResponse';
import type { OrderId } from 'src/types/Id';

export async function findDeliveryProofsApi(request: FindDeliveryProofsApiRequest): ApiSauceResponse<FindDeliveryProofsApiResponse> {
    return letseatadminApiMethod('order/findDeliveryProofsApi', request);
}

type FindDeliveryProofsApiRequest = {
    orderId: OrderId;
};

export type FindDeliveryProofsApiResponse = {
    paymentDataUri: string;
    orderDataUri: string;
};
