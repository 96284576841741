import { makeStyles } from '@material-ui/core';
import { Button, Text } from '@pidedirecto/ui';
import { SwitchUserIcon } from '@pidedirecto/ui/icons';
import * as React from 'react';
import { useHistory } from 'react-router-dom';
import { RoutePaths } from 'src/constants/RoutePath';
import { translate } from 'src/i18n/translate';
import { authReducer } from 'src/reducers/authReducer';
import { posReducer } from 'src/reducers/posReducer';
import { useAction } from 'src/utils/react/useAction';
import { useSelector } from 'src/utils/react/useSelector';
import { useIsSmallScreen } from 'src/utils/react/window/useIsSmallScreen';

export function SignOutPinCodeUserButton(): React.ReactElement | null {
    const classes = useStyles();
    const history = useHistory();
    const isSmallScreen = useIsSmallScreen();

    const pinCodeUserSignedIn = useSelector((state) => state.authentication.pinCodeUserSignedIn);

    const pinCodeUserLogout = useAction(authReducer.actions.pinCodeUserLogout);
    const setOpenedCashRegister = useAction(posReducer.actions.setOpenedCashRegister);
    const setOpenedCashRegisterPosBusinessDay = useAction(posReducer.actions.setOpenedCashRegisterPosBusinessDay);

    const handlePinCodeUserLogout = () => {
        pinCodeUserLogout();
        setOpenedCashRegister();
        setOpenedCashRegisterPosBusinessDay();

        history.replace({
            pathname: RoutePaths.PIN_CODE_USER_LOGIN,
            search: history.location.search,
        });
    };

    if (!pinCodeUserSignedIn) return null;

    return (
        <Button variant={isSmallScreen ? 'text' : 'secondary'} onClick={handlePinCodeUserLogout} classes={{ button: classes.button }} data-testid='appBar.signOutButton'>
            <div className={classes.iconContainer}>
                <SwitchUserIcon title={translate('Sign out pos user')} />
            </div>
            <Text className={classes.hiddenDesktop}>{translate('Switch POS user')}</Text>
        </Button>
    );
}

const useStyles = makeStyles((theme) => ({
    button: {
        color: theme.palette.icons.brand,
        height: 48,
        [theme.breakpoints.down('sm')]: {
            color: theme.palette.text.secondary,
            height: 42,
            textDecoration: 'none !important',
        },
    },
    hiddenDesktop: {
        textAlign: 'left',
        [theme.breakpoints.up('md')]: {
            display: 'none',
        },
    },
    iconContainer: {
        [theme.breakpoints.down('sm')]: {
            display: 'flex',
            alignItems: 'center',
            minWidth: 50,
        },
    },
}));
