import { makeStyles, useTheme } from '@material-ui/core';
import * as React from 'react';
import { Button } from 'src/components/Button';
import { SubscribedPage } from 'src/components/page/SubscribedPage';
import { translate } from 'src/i18n/translate';
import { ChatIcon } from 'src/icons/ChatIcon';
import { PeopleIcon } from 'src/icons/PeopleIcon';
import ambitLogo from 'src/images/ambit-logo.png';
import marketingEmptyStateImage from 'src/images/marketingEmptyStateImage.png';
import { useSelector } from 'src/utils/react/useSelector';

export function MarketingEmptyState(): React.ReactElement {
    const classes = useStyles();
    const theme = useTheme();
    const restaurantCountry = useSelector((state) => state.app.restaurant.country);

    return (
        <SubscribedPage title={translate('Your promotions everywhere')}>
            <div className={classes.container}>
                <div className={classes.infoContainer}>
                    <div className={classes.row} style={{ gap: 12 }}>
                        <img src={ambitLogo} alt='Ambit logo' className={classes.logo} />
                        <h1 className={classes.title}>{translate('Your promotions everywhere')}</h1>
                    </div>
                    <Button classes={{ button: classes.button }}>{translate('Start trial')}</Button>
                    <ul className={classes.benefitsList}>
                        <li className={classes.row}>
                            <PeopleIcon color={theme.palette.icons.brand} />
                            <span className={classes.text} style={{ marginLeft: 10 }}>
                                {translate('Know and keep your customers')}
                            </span>
                        </li>
                        <li className={classes.row}>
                            <ChatIcon color={theme.palette.icons.brand} />
                            <span className={classes.text} style={{ marginLeft: 10 }}>
                                {translate('Communicate through campaigns')}
                            </span>
                        </li>
                        <ul>
                            <li>
                                <span className={classes.text} style={{ marginLeft: 10 }}>
                                    {translate('SMS')}
                                </span>
                            </li>
                            <li>
                                <span className={classes.text} style={{ marginLeft: 10 }}>
                                    {translate('Email')}
                                </span>
                            </li>
                            <li>
                                <span className={classes.text} style={{ marginLeft: 10 }}>
                                    {translate('Push notifications')}
                                </span>
                            </li>
                        </ul>
                    </ul>
                </div>
                <div className={classes.imageContainer}>
                    <img src={marketingEmptyStateImage} className={classes.image} alt='Marketing empty state' />
                </div>
            </div>
        </SubscribedPage>
    );
}

const useStyles = makeStyles((theme) => ({
    container: {
        width: '100%',
        display: 'flex',
        flexDirection: 'row',
        boxSizing: 'border-box',
        padding: '0 10%',
        marginTop: '2vh',
        justifyContent: 'space-between',
    },
    infoContainer: {
        display: 'flex',
        flexDirection: 'column',
    },
    title: {
        fontFamily: theme.typography.medium,
        fontSize: 30,
        margin: 0,
    },
    logo: {
        width: 40,
    },
    row: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        marginBottom: 30,
    },
    text: {
        fontFamily: theme.typography.regular,
        fontSize: 18,
        margin: 0,
    },
    textBold: {
        fontFamily: theme.typography.bold,
        fontSize: 30,
    },
    button: {
        fontFamily: theme.typography.semiBold,
        fontSize: 16,
        borderRadius: 8,
        height: 60,
        width: '100%',
        maxWidth: '60%',
    },
    benefitsList: {
        listStyleType: 'none',
        marginTop: 30,
        padding: 0,
    },
    icon: {
        marginRight: 10,
    },
    image: {
        flexShrink: 0,
        height: '100%',
        marginBottom: 50,
        objectFit: 'cover',
    },
    imageContainer: {
        display: 'flex',
        flexDirection: 'column',
        maxWidth: '40%',
    },
    titleContainer: {
        display: 'flex',
        gap: 12,
        alignItems: 'center',
    },
}));
