import { history } from 'src/config/history';
import { RoutePaths } from 'src/constants/RoutePath';
import type { BrandId } from 'src/types/Id';
import { RegExps } from 'src/utils/RegExps';

export function openBrandPage(brandId?: BrandId): void {
    if (!brandId) return;
    history.push({
        pathname: RoutePaths.ADMIN_BRAND.replace(`:brandId(${RegExps.uuid})`, brandId),
        search: history.location.search,
    });
}
