import { letseatadminApiMethod } from 'src/api/letseatadminApi';
import type { ApiSauceResponse } from 'src/api/types/ApiSauceResponse';
import type { City } from 'src/constants/City';
import type { DeliveryProvider } from 'src/constants/DeliveryProviders';
import type { DriverArrivesAtStoreTime } from 'src/constants/DriverArrivesAtStoreTime';
import type { NotificationMessageType } from 'src/constants/NotificationMessageType';
import type { QuickActionId, RestaurantId, Zone } from 'src/types/Id';
import type { QuickActionVm } from 'src/types/QuickActionVm';

export async function changeQuickActionApi(request: ChangeQuickActionApiRequest): ApiSauceResponse<QuickActionVm> {
    return letseatadminApiMethod('quickAction/changeQuickActionApi', request);
}

export type ChangeQuickActionApiRequest = {
    quickActionId: QuickActionId;
    name?: string;
    city?: City;
    zones?: Array<Zone>;
    deliveryProviders?: Array<DeliveryProvider>;
    activated?: boolean;
    cashPaymentsEnabled?: boolean;
    creditCardPaymentsEnabled?: boolean;
    temporarilyOffline?: boolean;
    temporarilyWithoutDelivery?: boolean;
    pideDirectoDeliveryEnabled?: boolean;
    rappiCargoDeliveryEnabled?: boolean;
    uberDaasDeliveryEnabled?: boolean;
    uberDirectDeliveryEnabled?: boolean;
    manualOrdersEnabled?: boolean;
    longDistanceExtraDeliveryCostFixed?: number;
    dynamicEarningsAmountToIncrementByMinute?: number;
    dynamicEarningsMinimumMinutes?: number;
    dynamicEarningsRoof?: number;
    deliveryCostBonus?: number;
    deliveryRadiusInKm?: number;
    maximumNumberOfOngoingDeliveries?: number;
    customerDeliveryCostFixed?: number;
    customerDeliveryCostPerKm?: number;
    driverArrivesAtStoreTime?: DriverArrivesAtStoreTime;
    managerMessageBody?: string;
    managerMessageTitle?: string;
    managerMessageStyle?: string;
    notificationMessageBody?: string;
    notificationMessageTitle?: string;
    endUserMessage?: string;
    activeOrdersDynamicDeliveryEarnings?: string;
    notificationMessageType?: Array<NotificationMessageType>;
    excludedRestaurantIds?: Array<RestaurantId>;
    includedRestaurantIds?: Array<RestaurantId>;
};
