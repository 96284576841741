import { letseatmanagerApiMethod } from 'src/api/letseatmanagerApi';
import type { ApiSauceResponse } from 'src/api/types/ApiSauceResponse';
import type { PaymentMethod } from 'src/constants/PaymentMethod';
import type { CashRegisterId, CustomPaymentMethod, ManagerUserId, RestaurantId } from 'src/types/Id';
import type { PosBusinessDayReportVm } from 'src/types/PosBusinessDayReportVm';

export async function closePosBusinessDayApi(request: ClosePosBusinessDayApiRequest): ApiSauceResponse<PosBusinessDayReportVm> {
    return letseatmanagerApiMethod('pos/closePosBusinessDayApi', request);
}

export type ClosePosBusinessDayApiRequest = {
    restaurantId: RestaurantId;
    managerUserId?: ManagerUserId;
    cashRegisterId?: CashRegisterId;
    cashRegisterReport?: Array<{
        paymentMethod: PaymentMethod;
        customPaymentMethod?: CustomPaymentMethod;
        amount: string;
        expectedAmount: string;
    }>;
};
