import { makeStyles } from '@material-ui/core/styles';
import MUIDataTable from 'mui-datatables';
import * as React from 'react';
import { useEffect, useRef, useState } from 'react';
import { getFaqsApi } from 'src/api/letseatadmin/faq/getFaqsApi';
import { removeFaqApi } from 'src/api/letseatadmin/faq/removeFaqApi';
import { ChangeToolbarButton } from 'src/components/mui-datatables/ChangeToolbarButton';
import { CreateToolbarButton } from 'src/components/mui-datatables/CreateToolbarButton';
import { RefreshToolbarButton } from 'src/components/mui-datatables/RefreshToolbarButton';
import { RemoveToolbarButton } from 'src/components/mui-datatables/RemoveToolbarButton';
import { UpdatingContentProgress } from 'src/components/UpdatingContentProgress';
import { translate } from 'src/i18n/translate';
import ChangeFaqDialog from 'src/scenes/letseatadmin/faq/ChangeFaqDialog';
import CreateFaqDialog from 'src/scenes/letseatadmin/faq/CreateFaqDialog';
import type { FaqId } from 'src/types/Id';
import { alertKnownErrorOrSomethingWentWrong } from 'src/utils/alert/alertKnownErrorOrSomethingWentWrong';
import { getSelectedRowsData } from 'src/utils/mui-datatables/getSelectedRowsData';

export default function FaqTable({ title, onClickRefresh }: Props): React.ReactElement {
    const classes = useStyles();
    const table = useRef(null);
    const [loading, setLoading] = useState(false);
    const [vm, setVm] = useState<FaqsVm>({ faqs: [] });
    const [createFaqDialogState, setCreateFaqDialogState] = useState({ open: false });
    const [changeFaqDialogState, setChangeFaqDialogState] = useState({ open: false, faqId: undefined });

    useEffect(() => {
        load();
    }, []);

    const load = async () => {
        setLoading(true);
        const response = await getFaqsApi();
        if (!response.ok) {
            setLoading(false);
            alertKnownErrorOrSomethingWentWrong(response);
            return;
        }
        const sortedFaqs = response.data.faqs.sort(function (a, b) {
            if (a.position === undefined) return 1;
            if (b.position === undefined) return -1;
            if (a.position === b.position) return 0;
            return a.position < b.position ? -1 : 1;
        });
        setVm({ faqs: sortedFaqs });
        setLoading(false);
    };

    const removeFaq = async (faqIds: Array<FaqId>) => {
        setLoading(true);
        for (const faqId of faqIds) {
            const response = await removeFaqApi({ faqId });
            if (!response.ok) {
                setLoading(false);
                alertKnownErrorOrSomethingWentWrong(response);
                await load();
                return;
            }
        }
        await load();
    };

    const openCreateBannerDialog = () => setCreateFaqDialogState({ open: true });

    const closeCreateBannerDialog = () => setCreateFaqDialogState({ open: false });
    const closeChangeFaqDialog = () => setChangeFaqDialogState({ open: false, faqId: undefined });

    const columns = [
        {
            name: 'faqId',
            label: ' ',
            options: {
                display: 'excluded' as any,
                filter: false,
                searchable: true,
            },
        },
        {
            name: 'question',
            label: translate('Question'),
            options: {
                filter: false,
                searchable: true,
            },
        },
        {
            name: 'answer',
            label: translate('Answer'),
            options: {
                filter: false,
                searchable: false,
            },
        },
        {
            name: 'imageUrl',
            label: translate('Image'),
            options: {
                filter: false,
                customBodyRender: (imageUrl: string) => {
                    return (
                        <div style={{ width: '100%', textAlign: 'center' }}>
                            <img src={imageUrl} className={classes.bannerThumbnail} />;
                        </div>
                    );
                },
            },
        },
        {
            name: 'position',
            label: translate('Position'),
            options: {
                filter: false,
                searchable: false,
            },
        },
        {
            name: 'articleUrl',
            label: translate('Article Url'),
            options: {
                filter: false,
                searchable: false,
            },
        },
    ];

    return (
        <div style={{ position: 'relative' }}>
            <CreateFaqDialog open={createFaqDialogState.open} onClose={closeCreateBannerDialog} load={load} />
            <ChangeFaqDialog open={changeFaqDialogState.open} faqId={changeFaqDialogState.faqId} onClose={closeChangeFaqDialog} load={load} />

            <UpdatingContentProgress loading={loading} />
            <MUIDataTable
                // @ts-ignore
                ref={table}
                className={classes.table}
                title={title || translate('Faqs')}
                data={vm.faqs.map((faq) => ({
                    faqId: faq.faqId,
                    question: faq.question,
                    answer: faq.answer,
                    imageUrl: faq.imageUrl,
                    position: faq.position,
                    articleUrl: faq.articleUrl,
                }))}
                columns={columns}
                options={{
                    responsive: 'standard',
                    tableBodyMaxHeight: '500px',
                    selectableRows: 'multiple',
                    filterType: 'multiselect',
                    rowsPerPage: 100,
                    customToolbarSelect: (selectedRows, displayData) => {
                        const selectedRowsData = getSelectedRowsData(selectedRows, displayData);
                        const selectedFaqIds = selectedRowsData.map((d: any) => d[0]);
                        return (
                            <div className={classes.toolbar}>
                                {selectedFaqIds.length === 1 && <ChangeToolbarButton onClick={() => setChangeFaqDialogState({ open: true, faqId: selectedFaqIds[0] })} />}
                                <RemoveToolbarButton
                                    onClick={async () => {
                                        const remove = window.confirm(
                                            selectedFaqIds.length === 1
                                                ? 'Are you sure you want to remove the selected faq'
                                                : `Are you sure you want to remove the selected ${selectedFaqIds.length} faq`,
                                        );
                                        if (remove) {
                                            await removeFaq(selectedFaqIds);
                                        }
                                    }}
                                />
                            </div>
                        );
                    },
                    customToolbar: () => {
                        return (
                            <>
                                <RefreshToolbarButton
                                    onClick={() => {
                                        load();
                                        onClickRefresh && onClickRefresh();
                                    }}
                                />
                                <CreateToolbarButton onClick={openCreateBannerDialog} />
                            </>
                        );
                    },
                }}
            />
        </div>
    );
}

const useStyles = makeStyles((theme) => ({
    table: {
        whiteSpace: 'nowrap',
        '& tr': { cursor: 'pointer' },
        '& tr:hover': { backgroundColor: 'rgba(0, 0, 0, 0.07) !important' },
    },
    toolbar: {
        paddingRight: theme.spacing(3),
    },
    bannerThumbnail: {
        objectFit: 'cover',
        width: 200,
        height: 120,
        borderRadius: 20,
    },
}));

type Props = {
    title?: string;
    onClickRefresh?: any;
};

export type FaqsVm = {
    faqs: Array<FaqVm>;
};

export type FaqVm = {
    faqId: FaqId;
    question: string;
    answer: string;
    imageUrl?: string;
    position?: number;
    articleUrl?: string;
};
