export const MenuItemSizes = {
    XX_SMALL: 'XX_SMALL',
    X_SMALL: 'X_SMALL',
    SMALL: 'SMALL',
    MEDIUM: 'MEDIUM',
    LARGE: 'LARGE',
    X_LARGE: 'X_LARGE',
    XX_LARGE: 'XX_LARGE',
} as const;

export type MenuItemSize = (typeof MenuItemSizes)[keyof typeof MenuItemSizes];
