import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import Grid from '@material-ui/core/Grid';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import moment from 'moment/moment';
import * as React from 'react';
import { letseatmanagerApiDeprecated } from 'src/api/letseatmanagerApiDeprecated';
import type { FilterState } from 'src/components/input/FromToFilter';
import { OpenInNewTabToolbarButton } from 'src/components/mui-datatables/OpenInNewTabToolbarButton';
import { RefreshToolbarButton } from 'src/components/mui-datatables/RefreshToolbarButton';
import { UpdatingContentProgress } from 'src/components/UpdatingContentProgress';
import { RoutePaths } from 'src/constants/RoutePath';
import CreditsBillingOrdersTable from 'src/scenes/letseatadmin/companyCreditsBilling/CreditsPaymentsTable';
import CustomerCreditsPaymentsTable from 'src/scenes/letseatadmin/companyCreditsBilling/CustomerCreditsPaymentsTable';
import { OrdersTable } from 'src/scenes/letseatadmin/order/OrdersTable';
import type { BenefitPlanId, CustomerId, OrderId } from 'src/types/Id';
import type { OrderVm } from 'src/types/OrderVm';
import { alertKnownErrorOrSomethingWentWrong } from 'src/utils/alert/alertKnownErrorOrSomethingWentWrong';
import { deepEqual } from 'src/utils/object/deepEqual';
import { RegExps } from 'src/utils/RegExps';

export default withStyles((theme) => ({
    root: {
        paddingLeft: 8,
        paddingRight: 8,
    },
    table: {
        whiteSpace: 'nowrap',
        '& tr': { cursor: 'pointer' },
        '& tr:hover': { backgroundColor: 'rgba(0, 0, 0, 0.07) !important' },
    },
    toolbar: {
        paddingRight: theme.spacing(3),
    },
}))(
    class CompanyCreditsBillingDataForBenefitPlan extends React.Component<Props, State> {
        state = {
            loading: false,
            benefitPlanId: '' as any,
            companyName: '',
            benefitPlanName: '',
            customers: [],
            companyCredits: '0',
            creditsPayments: [],
            notAccepted: undefined,
        };

        async componentDidMount() {
            await this.load();
        }

        async componentDidUpdate(prevProps: Props, prevState: State) {
            if (!deepEqual(prevProps.filter, this.props.filter)) {
                await this.load();
            }
        }

        // shouldComponentUpdate(nextProps: Props, nextState: State): boolean {
        //     return !deepEqual(this.props, nextProps) || !deepEqual(this.state, nextState);
        // }

        load = async () => {
            this.setState({ loading: true });
            const request: Request = {
                benefitPlanId: this.props.benefitPlanId,
                from: moment(this.props.filter.from).format('YYYY-MM-DD'),
                to: moment(this.props.filter.to).format('YYYY-MM-DD'),
            };
            const response = await letseatmanagerApiDeprecated.admin.getCompanyCreditsBillingDataForBenefitPlan(request);
            this.setState({ loading: false });
            if (!response.ok) {
                alertKnownErrorOrSomethingWentWrong(response);
                return;
            }
            this.setState({ ...response.data });
            this.props.onLoad && this.props.onLoad(response.data.companyName, response.data.benefitPlanName);
        };

        render() {
            const { classes } = this.props;
            return (
                <Grid container spacing={5}>
                    <Grid item xs={12}>
                        <UpdatingContentProgress loading={this.state.loading} />
                        <Card classes={{ root: classes.root }}>
                            <CardHeader
                                title={<Typography display='block' gutterBottom variant='h6'>{`${this.state.companyName} - ${this.state.benefitPlanName}`}</Typography>}
                                action={
                                    <div>
                                        {this.props.benefitPlanId && (
                                            <>
                                                <OpenInNewTabToolbarButton
                                                    onClick={() => window.open(RoutePaths.ADMIN_BENEFIT_PLAN.replace(`:benefitPlanId(${RegExps.uuid})`, this.props.benefitPlanId), '_blank')}
                                                />
                                            </>
                                        )}
                                        <RefreshToolbarButton onClick={this.load} />
                                    </div>
                                }
                            />
                            <CardContent>
                                <Grid container spacing={3}>
                                    <Grid item xs={6} sm={3}>
                                        <Typography variant='caption' display='block' gutterBottom color='textSecondary'>
                                            Total Used Company Credits
                                        </Typography>
                                        <Typography variant='body1' gutterBottom>
                                            {this.state.companyCredits}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={6} sm={3}>
                                        <Typography variant='caption' display='block' gutterBottom color='textSecondary'>
                                            Number of orders
                                        </Typography>
                                        <Typography variant='body1' gutterBottom>
                                            {this.state.creditsPayments.length}
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </CardContent>
                        </Card>
                    </Grid>
                    <Grid item xs={12}>
                        <CustomerCreditsPaymentsTable title='CustomersInfo' customers={this.state.customers} loading={this.state.loading} onClickRefresh={this.load} />
                    </Grid>
                    <Grid item xs={12}>
                        <CreditsBillingOrdersTable title='Credits Payments' creditsPayments={this.state.creditsPayments} loading={this.state.loading} onClickRefresh={this.load} />
                    </Grid>
                    {this.state.notAccepted && (
                        <Grid item xs={12}>
                            <OrdersTable title='WARNING ORDERS NOT ACCEPTED YET' orders={this.state.notAccepted} loading={this.state.loading} onClickRefresh={this.load} />
                        </Grid>
                    )}
                </Grid>
            );
        }
    },
) as React.ComponentType<any>;

type Props = {
    filter: FilterState;
    benefitPlanId: BenefitPlanId;
    onLoad?: (arg1: string, arg2: string) => Record<any, any>;
    classes: any;
};

type State = {
    loading: boolean;
} & CompanyCreditsBillingDataVm;

type Request = {
    from: string;
    to: string;
    benefitPlanId: BenefitPlanId;
};

type CompanyCreditsBillingDataVm = {
    benefitPlanId: BenefitPlanId;
    companyName: string;
    benefitPlanName: string;
    companyCredits: string;
    customers: Array<{
        customerId: CustomerId;
        customerName: string;
        mobileNumber: string;
        email: string;
        employeeIdAtCompany?: string;
        employeeNumberAtCompany?: string;
        companyCredits: string;
        orders: number;
    }>;
    creditsPayments: Array<CreditsPaymentVm>;
    notAccepted?: Array<OrderVm>;
};

export type CreditsPaymentVm = {
    orderId: OrderId;
    customerId: CustomerId;
    createdAt: Date;
    companyCredits: string;
    customerName: string;
    mobileNumber: string;
    email: string;
    employeeIdAtCompany?: string;
    employeeNumberAtCompany?: string;
};
