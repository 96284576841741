import { DefaultSupplyCategories, DefaultSupplyCategory } from 'src/constants/DefaultSupplyCategories';
import { translate } from 'src/i18n/translate';
import { lowerCase } from 'src/utils/string/lowerCase';
import { upperCaseFirstLetter } from 'src/utils/string/upperCaseFirstLetter';

export function getSupplyCategoryName(supplyCategoryName?: string | DefaultSupplyCategory): string | undefined {
    if (!supplyCategoryName || supplyCategoryName === '') return '';

    const defaultSupplyCategories = Object.values(DefaultSupplyCategories);

    if (defaultSupplyCategories.includes(supplyCategoryName as DefaultSupplyCategory)) {
        return translate(`DefaultSupplyCategories.${supplyCategoryName}`);
    }
    return upperCaseFirstLetter(lowerCase(supplyCategoryName));
}
