import type { OrderStatus } from 'src/constants/OrderStatus';
import { getPosRestaurantZonesOfflineInLocalStorage } from 'src/localStorage/getPosRestaurantZonesOfflineInLocalStorage';
import { setPosRestaurantZonesOfflineInLocalStorage } from 'src/localStorage/setPosRestaurantZonesOfflineInLocalStorage';
import type { RestaurantZoneId } from 'src/types/Id';
import type { OrderItemVm } from 'src/types/OrderVm';
import { removeNulls } from 'src/utils/removeNulls';
import { newUuid } from 'src/utils/uuid/newUuid';

export function updatePosRestaurantZonesOfflineInLocalStorageByTableOrder(request: Request) {
    if (!request.table) return;
    const restaurantZonesOffline = getPosRestaurantZonesOfflineInLocalStorage();
    const restaurantZonesOfflineUpdated = restaurantZonesOffline.map((restaurantZone) => {
        if (
            restaurantZone.tables?.some((table) => table.tableNumber === request.table) &&
            (restaurantZone.name === request.restaurantZone || restaurantZone.restaurantZoneId === request.restaurantZoneId)
        ) {
            return {
                ...restaurantZone,
                tables:
                    restaurantZone.tables?.map((table) => {
                        if (table.tableNumber === request.table) {
                            return removeNulls({
                                ...table,
                                order: {
                                    ...(table.order ?? {}),
                                    orderId: table.order?.orderId ?? newUuid(),
                                    orderStatus: request.orderStatus,
                                    orderItems: request.orderItems,
                                    cancelledItems: request.cancelledItems,
                                    createdAt: table.order?.createdAt ?? new Date(),
                                    modifiedAt: new Date(),
                                } as any,
                            });
                        }
                        return table;
                    }) ?? [],
            };
        }
        return restaurantZone;
    });
    setPosRestaurantZonesOfflineInLocalStorage(restaurantZonesOfflineUpdated);
}

type Request = {
    table: string;
    restaurantZone?: string;
    restaurantZoneId?: RestaurantZoneId;
    orderStatus: OrderStatus;
    orderItems: Array<OrderItemVm>;
    cancelledItems: Array<OrderItemVm>;
};
