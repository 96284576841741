import { letseatmanagerApiMethod } from 'src/api/letseatmanagerApi';
import type { ApiSauceResponse } from 'src/api/types/ApiSauceResponse';
import type { RestaurantId } from 'src/types/Id';
import { RestaurantPaymentMethodVm } from 'src/types/RestaurantPaymentMethodVm';

export async function findRestaurantPaymentMethodsApi(request: FindRestaurantPaymentMethodsApiRequest): ApiSauceResponse<Array<RestaurantPaymentMethodVm>> {
    return letseatmanagerApiMethod('restaurantPaymentMethod/findRestaurantPaymentMethodsApi', request);
}

export type FindRestaurantPaymentMethodsApiRequest = {
    restaurantId: RestaurantId;
};
