import { makeStyles } from '@material-ui/core';
import * as React from 'react';
import { useFormContext } from 'react-hook-form';
import { FormCheckbox } from 'src/components/form/FormCheckbox';
import { RolePermission, RolePermissions } from 'src/constants/RolePermission';
import { RoutePaths } from 'src/constants/RoutePath';
import { translate } from 'src/i18n/translate';
import { useGetReportRolePermissions } from 'src/utils/react/useGetReportRolePermissions';
import { useSelector } from 'src/utils/react/useSelector';

export function InventoryReportsAccessConfiguration({ disabled }: Props): React.ReactElement | null {
    const classes = useStyles();

    const { watch } = useFormContext();
    const reportsPageEnabled = watch(RolePermissions.REPORTS_PAGE);

    const inventoryEnabled = useSelector((state) => state.app.restaurant?.inventoryEnabled);

    const checkDisabled = disabled || !reportsPageEnabled;

    const inventoryReportRolePermissions = useGetReportRolePermissions(RoutePaths.INVENTORY_REPORT);

    if (!inventoryEnabled) return null;

    return (
        <div className={classes.container}>
            <div className={classes.moduleContainer}>{translate('Inventory reports')}</div>
            <div className={classes.permissionsContainer}>
                {inventoryReportRolePermissions?.map((inventoryReportRolePermission: RolePermission) => (
                    <FormCheckbox
                        key={inventoryReportRolePermission}
                        name={inventoryReportRolePermission}
                        label={translate(`RolePermissions.${inventoryReportRolePermission}`)}
                        disabled={checkDisabled}
                    />
                ))}
            </div>
            <div className={classes.homeContainer}></div>
        </div>
    );
}

const useStyles = makeStyles((theme) => ({
    container: {
        width: '100%',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        padding: 10,
        borderBottom: `1px solid #c8c8c8`,
    },
    moduleContainer: {
        fontFamily: theme.typography.regular,
        fontSize: 15,
        width: '25%',
    },
    permissionsContainer: {
        fontFamily: theme.typography.regular,
        fontSize: 15,
        width: '50%',
        display: 'flex',
        flexDirection: 'column',
        paddingLeft: 10,
    },
    homeContainer: {
        fontFamily: theme.typography.regular,
        fontSize: 15,
        width: '25%',
    },
    radioButton: {
        color: `${theme.palette.primary.main} !important`,
    },
}));

type Props = {
    disabled?: boolean;
};
