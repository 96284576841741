import { useState } from 'react';
import * as React from 'react';
import { switchOrderDeliveryProviderToRappiCargoApi } from 'src/api/letseatadmin/delivery/switchOrderDeliveryProviderToRappiCargoApi';
import { Button } from 'src/components/Button';
import { translate } from 'src/i18n/translate';
import type { OrderVm } from 'src/types/OrderVm';
import { isAcceptedOrder } from 'src/utils/order/isAcceptedOrder';
import { isPideDirectoDelivery } from 'src/utils/order/isPideDirectoDelivery';
import { isUberDaasDelivery } from 'src/utils/order/isUberDaasDelivery';
import { isUberDelivery } from 'src/utils/order/isUberDelivery';
import { useSelector } from 'src/utils/react/useSelector';

export function SwitchOrderToRappiCargoDeliveryButton({ order, disabled, onUpdating, onSuccess }: Props): React.ReactElement | null {
    const [loading, setLoading] = useState(false);

    const internalUser = useSelector((state) => state.authentication.internalUser);
    const externalDeliveryEnabled = useSelector((state) => state.app.restaurant?.externalDelivery);

    const shouldRenderButton = internalUser && !externalDeliveryEnabled && (isPideDirectoDelivery(order) || isUberDelivery(order) || isUberDaasDelivery(order)) && isAcceptedOrder(order.orderStatus);

    const handleClick = async () => {
        onUpdating?.(true);
        setLoading(true);
        const response = await switchOrderDeliveryProviderToRappiCargoApi({ orderId: order.orderId });
        alert(response.data);
        onUpdating?.(false);
        setLoading(false);
        onSuccess?.();
    };

    if (!shouldRenderButton) return null;

    return (
        <Button secondary onClick={handleClick} disabled={loading || disabled}>
            {translate('Switch To Rappi Cargo')}
        </Button>
    );
}

type Props = {
    order: OrderVm;
    disabled?: boolean;
    onUpdating?: any;
    onSuccess?: any;
};
