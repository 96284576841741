export function applyColumns(columns: Array<string> | null | undefined, row: any): any {
    if (!columns) {
        return row;
    }
    if (Array.isArray(row)) {
        return columns.map((column) => row.find((data) => data.name === column));
    } else {
        if (!columns) {
            return row;
        }
        const newRow: Record<string, any> = {};
        for (const column of columns) {
            newRow[column] = row[column];
        }
        return newRow;
    }
}
