import * as React from 'react';
import { Field as _Field, Form as _Form } from 'react-final-form';
import { translate } from 'src/i18n/translate';
import { appReducer } from 'src/reducers/appReducer';
import { useAction } from 'src/utils/react/useAction';
import { useSelector } from 'src/utils/react/useSelector';

export function Form({ onSubmit, enabledForUserTypeView = false, ...props }: PropsForm): React.ReactElement {
    const viewUser = useSelector((state) => state.authentication.viewUser);
    const showSnackbar = useAction(appReducer.actions.showSnackbar);
    return (
        <_Form
            onSubmit={(...data) => {
                if (viewUser && !enabledForUserTypeView) {
                    showSnackbar({
                        message: translate('You dont have permission to perform this action!'),
                    });
                    return false;
                }
                onSubmit?.(...data);
            }}
            {...(props as any)}
        />
    );
}

type PropsForm = React.ComponentProps<typeof _Form> & {
    onSubmit?: any;
    enabledForUserTypeView?: boolean;
};

export function Field({ enabledForUserTypeView = false, ...props }: PropsField): React.ReactElement {
    const viewUser = useSelector((state) => state.authentication.viewUser);
    return <_Field disabled={viewUser && !enabledForUserTypeView} {...(props as any)} />;
}
type PropsField = React.ComponentProps<typeof _Field> & {
    enabledForUserTypeView?: boolean;
};
