import * as React from 'react';
import { FormSelect } from 'src/components/form/FormSelect';
import type { Variant } from 'src/components/form/FormTypes';
import { InternalReports } from 'src/constants/InternalReport';
import { translate } from 'src/i18n/translate';

export function FormInternalReportSelect({ name, label, placeholder, helperText, defaultValue, disabled, required, variant, onChange }: Props): React.ReactElement {
    const options = required
        ? Object.values(InternalReports).map((internalReport) => ({ label: translate(`InternalReports.${internalReport}`), value: internalReport }))
        : [{ label: '', value: null }, ...Object.values(InternalReports).map((internalReport) => ({ label: translate(`InternalReports.${internalReport}`), value: internalReport }))];
    return (
        <FormSelect
            name={name}
            label={label}
            placeholder={placeholder}
            helperText={helperText}
            defaultValue={defaultValue}
            options={options}
            disabled={disabled}
            required={required}
            variant={variant}
            onChange={onChange}
        />
    );
}

type Props = {
    name: string;
    label?: string;
    placeholder?: string;
    helperText?: string;
    defaultValue?: string | null | undefined;
    disabled?: boolean;
    required?:
        | boolean
        | {
              value: number;
              message: string;
          };
    variant?: Variant;
    onChange?: any;
};
