import Grid from '@material-ui/core/Grid';
import * as React from 'react';
import { FormSubheading } from 'src/components/form/FormSubheading';
import { FormSwitch } from 'src/components/form/FormSwitch';
import { translate } from 'src/i18n/translate';

export function RestaurantInventorySettings({ onChangeRestaurant }: Props): React.ReactElement {
    return (
        <div>
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <FormSubheading align={'center'}>{translate('Inventory Settings')}</FormSubheading>
                </Grid>
            </Grid>
            <Grid container spacing={3}>
                <Grid item xs={12} md={6}>
                    <FormSwitch
                        name='inventoryShowNegative'
                        label={translate('Sell even with negative inventory')}
                        tooltip={translate('Check if you want to discount inventory even with negative values')}
                    />
                </Grid>
            </Grid>
        </div>
    );
}

type Props = {
    onChangeRestaurant: any;
};
