import { PropTypes } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import * as React from 'react';

export function FormSubheading({ align, children }: Props): React.ReactElement {
    const classes = useStyles();
    return (
        <Typography variant='subtitle1' align={align || 'center'} className={classes.subheading}>
            {children}
        </Typography>
    );
}

const useStyles = makeStyles((theme) => ({
    subheading: {
        paddingTop: theme.spacing(8),
        fontFamily: theme.typography.bold,
        color: '#000',
        fontSize: 20,
    },
}));

type Props = {
    align?: PropTypes.Alignment;
    children: any;
};
