import { BigNumber } from 'bignumber.js';
import { TipTypes } from 'src/constants/TipType';
import type { PosTipVm } from 'src/types/PosTipVm';
import { convertAmountToMainCurrency } from 'src/utils/order/convertAmountToMainCurrency';

export function calculateTipAmount(totalPaid: number, tip: PosTipVm): number {
    let tipAmount = BigNumber(0);

    if (tip.tipType === TipTypes.AMOUNT) {
        tipAmount =
            !tip.restaurantCurrency || tip.isTipFromChange
                ? tipAmount.plus(BigNumber(tip.tipAmount ?? 0))
                : tipAmount.plus(BigNumber(convertAmountToMainCurrency(tip.tipAmount, tip.restaurantCurrency) ?? 0));
    }

    if (tip.tipType === TipTypes.PERCENT) {
        tipAmount = BigNumber(tip.tipPercentage ?? 0)
            .dividedBy(100)
            .multipliedBy(totalPaid);
    }

    return BigNumber(tipAmount).decimalPlaces(2).toNumber();
}
