import { useState } from 'react';
import { findOpenedCashRegisterApi } from 'src/api/letseatmanager/pos/findOpenedCashRegisterApi';
import { posReducer } from 'src/reducers/posReducer';
import { useAction } from 'src/utils/react/useAction';
import { useSelector } from 'src/utils/react/useSelector';

export function useLoadCashRegister(): () => Promise<void> {
    const [loading, setLoading] = useState(false);

    const restaurantId = useSelector((state) => state.app.restaurantId);

    const setOpenedCashRegister = useAction(posReducer.actions.setOpenedCashRegister);

    const loadCashRegister = async () => {
        if (!restaurantId || loading) {
            return;
        }

        setLoading(true);
        const response = await findOpenedCashRegisterApi({ restaurantId });
        setLoading(false);

        if (!response.ok) {
            return;
        }

        setOpenedCashRegister(response.data);
    };

    return loadCashRegister;
}
