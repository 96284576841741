import { letseatmanagerApi } from 'src/api/config/configLetseatmanagerApiSauce';
import type { ApiSauceResponse } from 'src/api/types/ApiSauceResponse';
import { getJwtToken } from 'src/api/utils/getJwtToken';
import { dateReviver } from 'src/utils/json/dateReviver';

export async function letseatmanagerApiMethod(url: string, request?: Record<string, any>): ApiSauceResponse<any> {
    const jwtTokenResult = await getJwtToken();
    if (jwtTokenResult.errorResponse) {
        return jwtTokenResult.errorResponse;
    }
    return letseatmanagerApi.post(url, request ?? {}, {
        headers: {
            'Content-Type': 'application/vnd.api.autoparsedates+json',
            accept: 'application/vnd.api.autoparsedates+json',
            authorization: jwtTokenResult.jwtToken,
        },
        transformResponse: [(data: any) => (data ? JSON.parse(data, dateReviver) : null)],
    });
}

export async function letseatmanagerApiMethodPublic(url: string, request?: Record<string, any>): ApiSauceResponse<any> {
    return letseatmanagerApi.post(`public/${url}`, request ?? {}, {
        headers: {
            'Content-Type': 'application/vnd.api.autoparsedates+json',
            accept: 'application/vnd.api.autoparsedates+json',
        },
        transformResponse: [(data: any) => (data ? JSON.parse(data, dateReviver) : null)],
    });
}
