import { IconButton, makeStyles } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import * as React from 'react';
import { Apps } from 'src/constants/App';
import { OrderTypes } from 'src/constants/OrderType';
import { translate } from 'src/i18n/translate';
import { ClipboardIcon } from 'src/icons/ClipboardIcon';
import type { OrderVm } from 'src/types/OrderVm';
import { copyTextToClipboard } from 'src/utils/html/copyTextToClipboard';
import { useSelector } from 'src/utils/react/useSelector';
import { formatAsInternationalPhoneNumber } from 'src/utils/string/formatAsInternationalPhoneNumber';
import { toShortId } from 'src/utils/uuid/toShortId';

export function OrderCardDeliveryInfo({ order }: Props): React.ReactElement | null {
    const classes = useStyles();

    const internalUser = useSelector((state) => state.authentication.internalUser);
    const isDeliveryOrder = order.orderType === OrderTypes.DELIVERY_ORDER;
    const externalApps = [Apps.DIDI_FOOD, Apps.RAPPI, Apps.UBER_EATS];
    const isExternalOrder = externalApps.includes(order.app as any) || order.externalOrderId;

    const handleCopyDriverName = () => copyTextToClipboard(order.driverName ?? '');

    const handleCopyDriverPhoneNumber = () => copyTextToClipboard(formatAsInternationalPhoneNumber(order.driverMobileNumber) ?? '');

    if (!isDeliveryOrder) return null;

    return (
        <div>
            {!!order.driverId && (
                <div className={classes.spaceBetween} style={{ fontWeight: 600, marginBottom: 12 }}>
                    <div className={classes.text}>{translate('Driver')}</div>
                    {order.driverId && <div className={classes.text}>{`ID - ${toShortId(order.driverId)}`}</div>}
                </div>
            )}
            <Grid item xs={12}>
                {order.driverId && !isExternalOrder && (
                    <>
                        <div className={classes.spaceBetween}>
                            <div className={classes.text}>{order.driverName}</div>
                            <IconButton style={{ padding: 4 }} onClick={handleCopyDriverName}>
                                <ClipboardIcon />
                            </IconButton>
                        </div>
                        <div className={classes.spaceBetween}>
                            <div className={classes.text}>{formatAsInternationalPhoneNumber(order.driverMobileNumber)}</div>
                            <IconButton style={{ padding: 4 }} onClick={handleCopyDriverPhoneNumber}>
                                <ClipboardIcon />
                            </IconButton>
                        </div>
                        {internalUser && (
                            <div className={classes.spaceBetween}>
                                <span className={classes.text}>{`${order?.driverStatsDelivered ?? 0} deliveries`}</span>
                            </div>
                        )}
                    </>
                )}
                {isExternalOrder && order.driverName && (
                    <>
                        <Typography className={classes.text} style={{ fontWeight: 600, marginBottom: 12 }}>
                            {translate('Driver')}
                        </Typography>
                        <div className={classes.text}>{order.driverName}</div>
                        {order.driverMobileNumber && <div className={classes.text}>{formatAsInternationalPhoneNumber(order.driverMobileNumber)}</div>}
                        <div className={classes.separator}></div>
                    </>
                )}
            </Grid>
        </div>
    );
}

const useStyles = makeStyles((theme) => ({
    separator: {
        height: '0px',
        width: '100%',
        border: '1px solid #F3F3F3',
        margin: '12px 0px',
    },

    text: {
        fontFamily: theme.typography.regular,
        fontSize: 14,
        color: '#454A4A',
    },

    spaceBetween: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        width: '100%',
    },
}));

type Props = {
    order: OrderVm;
};
