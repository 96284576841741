import { makeStyles } from '@material-ui/core';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { cloneModifierGroupApi } from 'src/api/letseatmanager/modifierGroup/cloneModifierGroupApi';
import { translate } from 'src/i18n/translate';
import { SearchableList } from 'src/scenes/letseatmanager/menu/components/SearchableList';
import { ChangeModifierGroupSection } from 'src/scenes/letseatmanager/menu/modifierGroup/ChangeModifierGroupSection';
import { CreateModifierGroupSection } from 'src/scenes/letseatmanager/menu/modifierGroup/CreateModifierGroupSection';
import { ModifierGroupListItem } from 'src/scenes/letseatmanager/menu/modifierGroup/ModifierGroupListItem';
import { ModifierGroupSection } from 'src/scenes/letseatmanager/menu/modifierGroup/ModifierGroupSection';
import { RemoveModifierGroupDialog } from 'src/scenes/letseatmanager/menu/modifierGroup/RemoveModifierGroupDialog';
import { useIsMenuEditionDisabled } from 'src/services/menu/useIsMenuEditionDisabled';
import { useLoadMenus } from 'src/services/menu/useLoadMenus';
import { useLoadMenuCategories } from 'src/services/menuCategory/useLoadMenuCategories';
import { useLoadMenuItems } from 'src/services/menuItem/useLoadMenuItems';
import { useLoadModifierGroups } from 'src/services/modifierGroup/useLoadModifierGroups';
import { useModifierGroups } from 'src/services/modifierGroup/useModifierGroups';
import { useNotification } from 'src/services/notification/useNotification';
import { ModifierGroupVm } from 'src/types/ModifierGroupVm';
import { deepEqual } from 'src/utils/object/deepEqual';
import { useSelector } from 'src/utils/react/useSelector';
import { useIsSmallScreen } from 'src/utils/react/window/useIsSmallScreen';

export function ModifierGroups(): React.ReactElement {
    const classes = useStyles();
    const isMenuEditionDisabled = useIsMenuEditionDisabled();
    const isSmallScreen = useIsSmallScreen();

    const [removeModifierGroupDialogState, setRemoveModifierGroupDialogState] = useState({ open: false, modifierGroup: undefined });
    const [modifierGroupManagementType, setModifierGroupManagementType] = useState<keyof typeof ModifierGroupManagementTypes>(ModifierGroupManagementTypes.SORT);
    const [selectedModifierGroup, setSelectedModifierGroup] = useState<ModifierGroupVm | undefined>(undefined);

    const { modifierGroups } = useModifierGroups();
    const refreshModifierGroups = useLoadModifierGroups();
    const refreshMenuItems = useLoadMenuItems();
    const refreshMenuCategories = useLoadMenuCategories();
    const refreshMenus = useLoadMenus();
    const notification = useNotification();

    const restaurantId = useSelector((state) => state.app.restaurantId);

    useEffect(() => {
        refreshModifierGroups();
    }, []);

    useEffect(() => {
        if (modifierGroups && !selectedModifierGroup && !isSmallScreen) {
            setSelectedModifierGroup(modifierGroups[0]);
        }
        if (modifierGroups && selectedModifierGroup) updateSelectedModifierGroup();
    }, [modifierGroups, isSmallScreen]);

    const updateSelectedModifierGroup = () => {
        if (!selectedModifierGroup) return;

        const updatedModifierGroup = modifierGroups.find((modifierGroup) => modifierGroup.modifierGroupId === selectedModifierGroup.modifierGroupId);
        if (deepEqual(updatedModifierGroup, selectedModifierGroup) || !updatedModifierGroup) return;

        setSelectedModifierGroup(updatedModifierGroup);
    };

    const showCreateModifierGroup = () => setModifierGroupManagementType(ModifierGroupManagementTypes.CREATE);

    const showChangeModifierGroup = (modifierGroup: any) => {
        setModifierGroupManagementType(ModifierGroupManagementTypes.CHANGE);
        setSelectedModifierGroup(modifierGroup);
    };

    const cloneModifierGroup = async (modifierGroup: any) => {
        const response = await cloneModifierGroupApi({
            modifierGroupId: modifierGroup.modifierGroupId,
            restaurantId: restaurantId,
        });

        if (!response.ok) {
            notification({ message: translate('Error while trying to clone the modifierGroup') });
        }

        refreshModifierGroups();
    };

    const showSortModifiers = () => {
        setModifierGroupManagementType(ModifierGroupManagementTypes.SORT);
        if (isSmallScreen) setSelectedModifierGroup(undefined);
    };

    const openRemoveModifierGroupDialog = (modifierGroup: any) => setRemoveModifierGroupDialogState({ open: true, modifierGroup });

    const closeRemoveModifierGroupDialog = () => setRemoveModifierGroupDialogState({ open: false, modifierGroup: undefined });

    const handleClickModifierGroup = (modifierGroupItem: any) => {
        const modifierGroup = modifierGroups.find((modifierGroup) => modifierGroup.modifierGroupId === modifierGroupItem.modifierGroupId);
        if (!modifierGroup) return;
        setSelectedModifierGroup(modifierGroup);
    };

    const handleUpdate = () => {
        refreshModifierGroups();
        refreshMenus();
        showCreateModifierGroup();
    };

    const refreshMenu = () => {
        refreshModifierGroups();
        refreshMenuItems();
        refreshMenuCategories();
        refreshMenus();
    };

    return (
        <div className={classes.container}>
            <RemoveModifierGroupDialog
                open={removeModifierGroupDialogState.open}
                modifierGroup={removeModifierGroupDialogState.modifierGroup}
                onClose={closeRemoveModifierGroupDialog}
                onSuccess={refreshMenu}
            />
            <section className={classes.modifierGroupsContainer}>
                <SearchableList
                    title={translate('Modifier groups')}
                    onCreate={showCreateModifierGroup}
                    onItemClick={handleClickModifierGroup}
                    defaultListItemSelected={modifierGroups?.[0]?.modifierGroupId}
                    disabled={isMenuEditionDisabled}
                    items={modifierGroups}
                    itemSize={80}
                    height={600}
                    getItemKey={(modifierGroup: ModifierGroupVm) => modifierGroup.modifierGroupId}
                    getItemSearchableBy={(modifierGroup) => modifierGroup.name}
                    renderItem={(modifierGroup: ModifierGroupVm) => (
                        <ModifierGroupListItem
                            modifierGroup={modifierGroup}
                            changeModifierGroup={showChangeModifierGroup}
                            openRemoveModifierGroupDialog={openRemoveModifierGroupDialog}
                            cloneModifierGroup={cloneModifierGroup}
                        />
                    )}
                />
            </section>

            {modifierGroupManagementType === ModifierGroupManagementTypes.CREATE && <CreateModifierGroupSection onClose={showSortModifiers} onSuccess={handleUpdate} />}
            {modifierGroupManagementType === ModifierGroupManagementTypes.CHANGE && (
                <ChangeModifierGroupSection modifierGroupId={selectedModifierGroup?.modifierGroupId} onClose={showSortModifiers} onSuccess={handleUpdate} />
            )}
            {modifierGroupManagementType === ModifierGroupManagementTypes.SORT && selectedModifierGroup && (
                <ModifierGroupSection modifierGroup={selectedModifierGroup} onClose={() => setSelectedModifierGroup(undefined)} />
            )}
        </div>
    );
}

const ModifierGroupManagementTypes = {
    CREATE: 'CREATE',
    CHANGE: 'CHANGE',
    SORT: 'SORT',
} as const;

const useStyles = makeStyles((theme) => ({
    container: {
        display: 'flex',
        flexDirection: 'row',
        width: '100%',
        justifyContent: 'space-around',
        gap: 60,
    },
    modifierGroupsContainer: {
        width: '50%',
        flexShrink: 0,
        [theme.breakpoints.down('sm')]: {
            width: '100%',
        },
    },
}));
