import { letseatmanagerApiMethod } from 'src/api/letseatmanagerApi';
import type { ApiSauceResponse } from 'src/api/types/ApiSauceResponse';
import type { RestaurantId } from 'src/types/Id';

export async function changeRappiIntegrationCommissionPercentageApi(request: ChangeRappiIntegrationCommissionPercentageApiRequest): ApiSauceResponse<void> {
    return letseatmanagerApiMethod('restaurantIntegration/changeRestaurantRappiSalesCommissionPercentageApi', request);
}

export type ChangeRappiIntegrationCommissionPercentageApiRequest = {
    restaurantId: RestaurantId;
    rappiSalesCommissionPercentage?: number;
};
