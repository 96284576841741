import { makeStyles } from '@material-ui/core';
import * as React from 'react';
import { useState } from 'react';
import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { linkInventoryBetweenRestaurantsApi } from 'src/api/letseatmanager/inventory/linkInventoryBetweenRestaurantsApi';
import { Button } from 'src/components/Button';
import { Dialog } from 'src/components/Dialog';
import { DialogActions } from 'src/components/DialogActions';
import { Form } from 'src/components/form/Form';
import { FormRestaurantWithInventoryEnabledAutocomplete } from 'src/components/form/FormRestaurantWithInventoryEnabledAutocomplete';
import { WebSocketEventTypes } from 'src/constants/WebSocketEventType';
import { translate } from 'src/i18n/translate';
import { alertKnownErrorOrSomethingWentWrong } from 'src/utils/alert/alertKnownErrorOrSomethingWentWrong';
import { useSelector } from 'src/utils/react/useSelector';
import { WebSocketEvents } from 'src/utils/webSocket/WebSocketEvents';

export function LinkInventoryFromRestaurantDialog({ open, onClose, onSuccess }: Props): React.ReactElement {
    const classes = useStyles();
    const form = useForm();

    const [isLoading, setIsLoading] = useState(false);

    const restaurantId = useSelector((state) => state.app.restaurantId);

    const restaurantToLinkInventory = form.watch('restaurantToLinkInventory');

    useEffect(() => {
        const linkedInventoryBetweenRestaurantsWebSocketEvent = WebSocketEvents.addEventListener(WebSocketEventTypes.RESTAURANT_INVENTORY_LINKED, onClose);

        return () => {
            linkedInventoryBetweenRestaurantsWebSocketEvent.remove();
        };
    }, []);

    const onSubmit = async () => {
        if (!restaurantId || !restaurantToLinkInventory) return;

        setIsLoading(true);
        const response = await linkInventoryBetweenRestaurantsApi({
            fromRestaurantId: restaurantId,
            toRestaurantId: restaurantToLinkInventory,
        });
        setIsLoading(false);

        if (!response.ok) {
            alertKnownErrorOrSomethingWentWrong(response);
            return;
        }
        onSuccess?.();
    };

    return (
        <Dialog open={open} onClose={onClose} title={translate('Link inventory from other restaurant')}>
            <Form form={form}>
                <FormRestaurantWithInventoryEnabledAutocomplete required name='restaurantToLinkInventory' label={translate('Select a restaurant to link inventory')} />
                <span className={classes.message}>{translate('By linking the inventory with that of another restaurant, your current inventory will be lost')}</span>
            </Form>
            <DialogActions>
                <Button secondary onClick={onClose} disabled={isLoading} classes={{ button: classes.button }}>
                    {translate('Cancel')}
                </Button>
                <Button onClick={onSubmit} disabled={isLoading} classes={{ button: classes.button }}>
                    {translate('Accept')}
                </Button>
            </DialogActions>
        </Dialog>
    );
}

const useStyles = makeStyles((theme) => ({
    button: {
        width: '100%',
        [theme.breakpoints.up('sm')]: {
            maxWidth: '30%',
        },
    },
    message: {
        marginTop: 20,
        fontFamily: theme.typography.regular,
        color: '#324054',
        fontSize: 12,
    },
}));

type Props = {
    open: boolean;
    onClose: any;
    onSuccess?: any;
};
