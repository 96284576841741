import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { makeStyles } from '@material-ui/core/styles';
import * as React from 'react';
import { useState } from 'react';
import { setOwnFleetDriverStatusApi } from 'src/api/letseatmanager/ownFleet/setOwnFleetDriverStatusApi';
import { OwnFleetDriverStatuses } from 'src/constants/OwnFleetDriverStatus';
import { translate } from 'src/i18n/translate';
import type { DriverId } from 'src/types/Id';
import { alertKnownErrorOrSomethingWentWrong } from 'src/utils/alert/alertKnownErrorOrSomethingWentWrong';
import { useSelector } from 'src/utils/react/useSelector';

export function AcceptOwnFleetRequestDialog({ open, onClose, driverId, onSuccess }: Props): React.ReactElement {
    const classes = useStyles();

    const [loading, setLoading] = useState(false);

    const restaurantId = useSelector((state) => state.app.restaurantId);

    const handleExited = () => {
        setLoading(false);
        onClose();
    };

    const onSubmit = async () => {
        setLoading(true);
        const response = await setOwnFleetDriverStatusApi({ driverId, restaurantId, ownFleetDriverStatus: OwnFleetDriverStatuses.ACCEPTED });
        setLoading(false);
        if (!response.ok) {
            alertKnownErrorOrSomethingWentWrong(response);
            return;
        }
        onSuccess();
        onClose();
    };

    return (
        <Dialog fullWidth scroll='paper' className={classes.form} open={open} onClose={handleExited} onExited={handleExited}>
            <DialogTitle classes={{ root: classes.dialogTitle }}>{translate('Are you sure you want to accept this driver?')}</DialogTitle>
            <DialogContent className={classes.bodyText}>{translate('By accepting it, he will be part of the group of your own drivers')}</DialogContent>
            <DialogActions className={classes.buttonsContainer}>
                <Button onClick={handleExited} disabled={loading}>
                    <div className={classes.cancelButton}>{translate('Cancel')}</div>
                </Button>
                <Button color='primary' onClick={onSubmit} disabled={loading}>
                    <div className={classes.acceptButton}>{translate('Accept')}</div>
                </Button>
            </DialogActions>
        </Dialog>
    );
}

const useStyles = makeStyles((theme) => ({
    dialogTitle: {
        textAlign: 'center',
        fontSize: 41,
        color: theme.palette.primary.main,
        paddingTop: theme.spacing(4),
        paddingBottom: theme.spacing(3),
        '& h2': {
            fontFamily: theme.typography.medium,
        },
    },
    bodyText: {
        fontFamily: theme.typography.medium,
        textAlign: 'center',
        color: theme.palette.secondary.contrastText,
    },
    buttonsContainer: {
        marginTop: 20,
        marginBottom: 20,
        flexDirection: 'row',
        justifyContent: 'center',
        [theme.breakpoints.down('sm')]: {
            flexDirection: 'column',
        },
    },
    form: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    acceptButton: {
        backgroundColor: theme.palette.primary.main,
        color: 'white',
        fontFamily: theme.typography.semiBold,
        fontSize: 15,
        borderRadius: 5,
        height: 40,
        width: 200,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        textTransform: 'none',
        boxShadow: `3px 3px 10px ${theme.palette.primary.main}aa`,
    },
    cancelButton: {
        backgroundColor: theme.palette.secondary.dark,
        color: theme.palette.secondary.contrastText,
        fontFamily: theme.typography.semiBold,
        fontSize: 15,
        borderRadius: 5,
        height: 40,
        width: 200,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        textTransform: 'none',
    },
}));

type Props = {
    open: boolean;
    onClose: any;
    driverId: DriverId;
    onSuccess: any;
};
