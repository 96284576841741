import { BigNumber } from 'bignumber.js';
import type { Ingredient } from 'src/types/RecipeVm';

export function calculateProductionFixedCost(ingredients: Array<Ingredient>): string {
    return ingredients
        ?.reduce((acc: number, ingredient: Ingredient) => {
            const mainUnit = ingredient.supply.buyUnits.find((buyUnit) => buyUnit.isMainUnit);
            const priceUnit = BigNumber(ingredient.supply.fixedCost)
                .dividedBy(mainUnit?.conversionFactor ?? 1)
                .toFixed(2);

            return acc + Number(priceUnit) * Number(ingredient.quantity);
        }, 0)
        .toString();
}
