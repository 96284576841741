import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { makeStyles } from '@material-ui/core/styles';
import * as React from 'react';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { integrateUberEatsApi } from 'src/api/letseatmanager/restaurantIntegration/integrateUberEatsApi';
import type { UberEatsStoreVm } from 'src/api/letseatmanager/restaurantIntegration/setUpUberEatsIntegrationApi';
import { Form } from 'src/components/form/Form';
import { translate } from 'src/i18n/translate';
import { UberEatsStoreId } from 'src/types/Id';
import { alertKnownErrorOrSomethingWentWrong } from 'src/utils/alert/alertKnownErrorOrSomethingWentWrong';
import { classNames } from 'src/utils/react/classNames';
import { useSelector } from 'src/utils/react/useSelector';

export function UberEatsStoreIntegrationDialog({ open, uberEatsStores, handleClose, onSuccess }: Props): React.ReactElement {
    const form = useForm();
    const classes = useStyles();

    const [loading, setLoading] = useState(false);
    const [uberEatsStoreIdSelected, setUberEatsStoreIdSelected] = useState<UberEatsStoreId | undefined>(undefined);

    const restaurantId = useSelector((state) => state.app.restaurantId);

    const handleSubmit = async () => {
        if (!uberEatsStoreIdSelected) {
            return alert(translate('Please select at least one uber eats store.'));
        }
        setLoading(true);
        const response = await integrateUberEatsApi({
            restaurantId,
            uberEatsStoreId: uberEatsStoreIdSelected,
        });
        setLoading(false);
        if (!response.ok) {
            alertKnownErrorOrSomethingWentWrong(response);
            return;
        }
        onSuccess();
    };

    return (
        <Dialog classes={{ paper: classes.paper }} scroll='paper' open={open} onClose={handleClose}>
            <Form onSubmit={handleSubmit} form={form} className={classes.form}>
                <DialogTitle classes={{ root: classes.dialogTitle }}>{translate('Select uber eats stores to integrate')}</DialogTitle>
                <DialogContent style={{ overflow: 'hidden', width: '90%' }}>
                    <div className={classes.uberEatsStoresContainer}>
                        {uberEatsStores.map((uberEatsStore) => {
                            const uberEatsStoreIsSelected = uberEatsStoreIdSelected === uberEatsStore.uberEatsStoreId;
                            return (
                                <div
                                    className={uberEatsStoreIsSelected ? classNames(classes.uberEatsStore, classes.uberEatsStoreSelected) : classes.uberEatsStore}
                                    onClick={() => setUberEatsStoreIdSelected(uberEatsStore.uberEatsStoreId)}
                                >
                                    <h3 className={classes.textBold}>{uberEatsStore.name}</h3>
                                    <span className={classes.text}>{uberEatsStore.address}</span>
                                </div>
                            );
                        })}
                    </div>
                </DialogContent>
                <DialogActions style={{ marginTop: 20, marginBottom: 20 }} className={classes.buttonsContainer}>
                    <Button onClick={handleClose} disabled={loading}>
                        <div className={classes.cancelButton}>{translate('Cancel')}</div>
                    </Button>
                    <Button color='primary' type='submit' disabled={loading} classes={{ root: classes.noMargin }}>
                        <div className={classes.acceptButton}>{loading ? translate('Integrating') : translate('Integrate')}</div>
                    </Button>
                </DialogActions>
            </Form>
        </Dialog>
    );
}

const useStyles = makeStyles((theme) => ({
    paper: {
        width: '80%',
        maxWidth: '100%',
    },
    dialogTitle: {
        textAlign: 'center',
        fontSize: 41,
        color: theme.palette.secondary.contrastText,
        paddingTop: theme.spacing(4),
        paddingBottom: theme.spacing(3),
        '& h2': {
            fontFamily: theme.typography.medium,
        },
    },
    form: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    buttonsContainer: {
        marginTop: 20,
        marginBottom: 20,
        [theme.breakpoints.down('sm')]: {
            flexDirection: 'column',
        },
    },
    noMargin: {
        margin: '0 !important',
    },
    textBold: {
        fontFamily: theme.typography.medium,
        fontSize: 18,
        margin: 0,
    },
    text: {
        fontFamily: theme.typography.regular,
        fontSize: 15,
        marginBottom: 20,
    },
    acceptButton: {
        backgroundColor: theme.palette.primary.main,
        color: 'white',
        fontFamily: theme.typography.semiBold,
        fontSize: 15,
        borderRadius: 5,
        height: 40,
        width: 200,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        textTransform: 'none',
        boxShadow: `3px 3px 10px ${theme.palette.primary.main}aa`,
    },
    cancelButton: {
        backgroundColor: theme.palette.secondary.dark,
        color: theme.palette.secondary.contrastText,
        fontFamily: theme.typography.semiBold,
        fontSize: 15,
        borderRadius: 5,
        height: 40,
        width: 200,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        textTransform: 'none',
    },
    uberEatsStoresContainer: {
        width: '100%',
        height: '100%',
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap',
        justifyContent: 'space-between',
    },
    uberEatsStore: {
        display: 'flex',
        flexDirection: 'column',
        padding: 15,
        backgroundColor: theme.palette.secondary.light,
        width: '30%',
        cursor: 'pointer',
        marginBottom: 10,
        [theme.breakpoints.down('sm')]: {
            width: '100%',
        },
    },
    uberEatsStoreSelected: {
        backgroundColor: theme.palette.primary.light,
    },
}));

type Props = {
    open: boolean;
    uberEatsStores: Array<UberEatsStoreVm>;
    handleClose: any;
    onSuccess: any;
};
