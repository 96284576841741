import { printWithRemotePrinterApi } from 'src/api/letseatmanager/printer/printWithRemotePrinterApi';
import { PrinterTypes } from 'src/constants/PrinterType';
import { getDeviceId } from 'src/services/device/getDeviceId';
import { createUserPrintFailedLogEvent } from 'src/services/logEvent/createUserPrintFailedLogEvent';
import { createUserPrintSuccessfulLogEvent } from 'src/services/logEvent/createUserPrintSuccessfulLogEvent';
import { StringPrinter } from 'src/services/printer/printers/StringPrinter';
import { WebUsbPrinter } from 'src/services/printer/printers/WebUsbPrinter';
import { WindowsPrinter } from 'src/services/printer/printers/WindowsPrinter';
import type { Print } from 'src/services/printer/types/Print';
import type { PrinterVm } from 'src/types/PrinterVm';
import { isWindows } from 'src/utils/os/isWindows';
import { isBluetoothPrinter } from 'src/utils/printer/isBluetoothPrinter';
import { isPaymentTerminalPrinter } from 'src/utils/printer/isPaymentTerminalPrinter';
import { isSdkPrinter } from 'src/utils/printer/isSdkPrinter';
import { isUsbPrinter } from 'src/utils/printer/isUsbPrinter';
import { isWifiPrinter } from 'src/utils/printer/isWifiPrinter';
import { isMobileApp } from 'src/utils/reactNative/isMobileApp';
import { isPaymentTerminalDevice } from 'src/utils/reactNative/isPaymentTerminalDevice';
import { sendPrintPrinterInstructionsMessageToMobileApp } from 'src/utils/reactNative/sendPrintPrinterInstructionsMessageToMobileApp';
import { pretty } from 'src/utils/string/pretty';

export async function print(print: Print, printer: PrinterVm): Promise<void> {
    if (isUsbPrinter(printer.printerType) || isBluetoothPrinter(printer.printerType)) {
        if (printer.connectedDeviceId && printer.connectedDeviceId !== getDeviceId()) {
            await printWithRemotePrinterApi({ print, printer });
            return;
        }
    } else if (isWifiPrinter(printer.printerType)) {
        // TODO add support to print remotely with WIFI printer
        /*
        if (isMobileApp() && !printer.ipAddress) await printWithRemotePrinterApi({ print, printer });
        if (isWindows() && !printer.externalPrinterId) await printWithRemotePrinterApi({ print, printer });
        else await printWithRemotePrinterApi({ print, printer });
        */
    }

    let stringPrint;
    try {
        try {
            stringPrint = StringPrinter.print(print.printerInstructions, printer);
            console.log(stringPrint);
        } catch (e: any) {
            console.log(`Failed to print to string, ignoring exception since its not critical. printerInstructions=${pretty(print.printerInstructions)}`, e);
        }

        await _print(print, printer);

        createUserPrintSuccessfulLogEvent({ printer, stringPrint });
    } catch (e: any) {
        createUserPrintFailedLogEvent({ printer, stringPrint });
        throw e;
    }
}

async function _print(print: Print, printer: PrinterVm): Promise<void> {
    try {
        if (isPaymentTerminalDevice() && printer.printerType && isPaymentTerminalPrinter(printer.printerType)) {
            return sendPrintPrinterInstructionsMessageToMobileApp(print.printerInstructions, printer);
        }

        if (isMobileApp()) {
            if (isBluetoothPrinter(printer.printerType) || isWifiPrinter(printer.printerType) || isSdkPrinter(printer.printerType)) {
                return sendPrintPrinterInstructionsMessageToMobileApp(print.printerInstructions, printer);
            }
            // TODO: Throw "Printer not supported on your OS error" and show some sort of snack bar warning that could not print or maybe open select printer dialog?
            console.log(`sendPrintToPrinter failed, type=${printer.printerType ?? ''} not supported for Android app`);
            return;
        }

        if (isWindows()) {
            if (printer.printerType === PrinterTypes.USB) {
                return WebUsbPrinter.print(print.printerInstructions, printer);
            }
            if (printer.printerType === PrinterTypes.WIFI || printer.printerType === PrinterTypes.BLUETOOTH) {
                return WindowsPrinter.print(print.printerInstructions, printer);
            }
            // TODO: Throw "Printer not supported on your OS error" and show some sort of snack bar warning that could not print or maybe open select printer dialog?
            console.log(`sendPrintToPrinter failed, type=${printer.printerType ?? ''} not supported for Windows`);
            return;
        }

        if (printer.printerType === PrinterTypes.USB) {
            await WebUsbPrinter.print(print.printerInstructions, printer);
        } else {
            // TODO: Throw "Printer not supported on your OS error" and show some sort of snack bar warning that could not print or maybe open select printer dialog?
            console.log(`sendPrintToPrinter failed, type=${printer.printerType ?? ''} not supported for your OS`);
        }
    } catch (e: any) {
        const error = new Error('Failed to print', { cause: e });
        throw Object.assign(error, { data: { print, printer } });
    }
}
