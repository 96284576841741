import { makeStyles } from '@material-ui/core';
import VideocamOutlinedIcon from '@material-ui/icons/VideocamOutlined';
import * as React from 'react';
import type { RestaurantVideoId } from 'src/types/Id';

export function VideosElement({ video }: Props): React.ReactElement {
    const classes = useStyles();

    return (
        <div className={classes.container}>
            <VideocamOutlinedIcon className={classes.icon} />
            <a href={video.videoUrl} target='_blank' className={classes.text}>
                {video.name}
            </a>
        </div>
    );
}

const useStyles = makeStyles((theme) => ({
    container: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
    },
    icon: {
        fontSize: 35,
    },
    text: {
        textDecoration: 'underline',
        marginLeft: 15,
        fontFamily: theme.typography.medium,
        color: 'black',
    },
}));

type Props = {
    video: {
        restaurantVideoId: RestaurantVideoId;
        name: string;
        videoUrl: string;
        createdAt: Date;
        modifiedAt: Date;
    };
};
