import * as React from 'react';
import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { SubscribedPage } from 'src/components/page/SubscribedPage';
import { RoutePaths } from 'src/constants/RoutePath';
import { translate } from 'src/i18n/translate';
import { PosUserLockscreen } from 'src/scenes/letseatmanager/pos/posUserLockscreen/PosUserLockscreen';
import { useSelector } from 'src/utils/react/useSelector';

export function PosUserLockscreenPage(): React.ReactElement {
    const history = useHistory();

    const brandOpened = useSelector((state) => state.app.brandOpened);
    const userManagementEnabled = useSelector((state) => state.app.restaurant.userManagementEnabled);

    useEffect(() => {
        if (brandOpened || !userManagementEnabled) {
            history.replace({
                pathname: RoutePaths.ORDERS,
                search: history.location.search,
            });
            return;
        }
    }, [brandOpened, userManagementEnabled]);

    return (
        <SubscribedPage title={translate('AmbitOne POS')}>
            <PosUserLockscreen />
        </SubscribedPage>
    );
}
