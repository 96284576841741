import { letseatadminApiMethod } from 'src/api/letseatadminApi';
import type { ApiSauceResponse } from 'src/api/types/ApiSauceResponse';
import type { RestaurantId } from 'src/types/Id';

export async function disintegrateRestaurantWithRappiApi(request: disintegrateRestaurantWithRappiApiRequest): ApiSauceResponse<void> {
    return letseatadminApiMethod('rappiIntegration/disintegrateRestaurantWithRappiApi', request);
}

export type disintegrateRestaurantWithRappiApiRequest = {
    restaurantId: RestaurantId;
};
