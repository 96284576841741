import { letseatmanagerApiMethod } from 'src/api/letseatmanagerApi';
import type { ApiSauceResponse } from 'src/api/types/ApiSauceResponse';
import type { RestaurantId } from 'src/types/Id';

export async function addTemporaryPreparationTimeApi(request: AddTemporaryPreparationTimeApiRequest): ApiSauceResponse<void> {
    return letseatmanagerApiMethod('restaurant/addTemporaryPreparationTimeApi', request);
}

export type AddTemporaryPreparationTimeApiRequest = {
    restaurantId: RestaurantId;
    temporaryPreparationTime: number;
    temporaryPreparationTimeUntil?: Date;
};
