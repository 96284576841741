import { letseatadminApiMethod } from 'src/api/letseatadminApi';
import type { ApiSauceResponse } from 'src/api/types/ApiSauceResponse';
import type { RestaurantId } from 'src/types/Id';

export async function findRestaurantPasswordApi(request: RestaurantPasswordApiRequest): ApiSauceResponse<string | undefined> {
    return letseatadminApiMethod('restaurant/findRestaurantPasswordApi', request);
}

export type RestaurantPasswordApiRequest = {
    restaurantId: RestaurantId;
};
