import { makeStyles } from '@material-ui/core';
import { useEffect, useState } from 'react';
import * as React from 'react';
import { Alert } from 'src/components/Alert';
import { translate } from 'src/i18n/translate';
import { formatToAlertType } from 'src/utils/alert/formatToAlertType';
import { useSelector } from 'src/utils/react/useSelector';
import { isMobileApp } from 'src/utils/reactNative/isMobileApp';
import { trim } from 'src/utils/string/trim';

export function RestaurantManagerMessages(): React.ReactElement | null {
    const classes = useStyles();

    const [showNotificationSoundAlert, setShowNotificationSoundAlert] = useState(true);
    const [managerMessages, setManagerMessages] = useState<Array<ManagerMessage>>([]);

    const managerMessageStyle = useSelector((state) => state.app2.managerMessageStyle);
    const managerMessageTitle = useSelector((state) => state.app2.managerMessageTitle);
    const managerMessageBody = useSelector((state) => state.app2.managerMessageBody);
    const managerMessageUrl = useSelector((state) => state.app2.managerMessageUrl);
    const webSoundPermissionAllowed = useSelector((state) => state.app2.webSoundPermissionAllowed);

    const renderNotificationAlert = !webSoundPermissionAllowed && !isMobileApp();

    useEffect(() => {
        const managerMessages: Array<ManagerMessage> = parseManagerMessages(managerMessageStyle, managerMessageTitle, managerMessageBody, managerMessageUrl);
        setManagerMessages(managerMessages);
    }, [managerMessageStyle, managerMessageTitle, managerMessageBody, managerMessageUrl]);

    const parseManagerMessages = (managerMessageStyle?: string, managerMessageTitle?: string, managerMessageBody?: string, managerMessageUrl?: string) => {
        const managerMessageStyles = managerMessageStyle?.split(';').map(trim);
        const managerMessageTitles = managerMessageTitle?.split(';').map(trim);
        const managerMessageBodies = managerMessageBody?.split(';').map(trim);
        const managerMessageUrls = managerMessageUrl?.split(';').map(trim);

        const numberOfManagerMessages = Math.max(managerMessageTitles?.length ?? 0, managerMessageBodies?.length ?? 0);
        return [...Array(numberOfManagerMessages).keys()].map((i) => ({
            managerMessageStyle: formatToAlertType(managerMessageStyles?.[i]),
            managerMessageTitle: managerMessageTitles?.[i],
            managerMessageBody: managerMessageBodies?.[i],
            managerMessageUrl: managerMessageUrls?.[i],
        }));
    };

    const handleConfigureSound = () => {
        openLink('https://pidedirecto.github.io/publicinfo/nosound');
    };

    const openLink = (managerMessageUrl: undefined | string) => window.open(managerMessageUrl, '_blank');

    const handleRemoveManagerMessage = (managerMessageBody: undefined | string) => {
        setManagerMessages(managerMessages.filter((managerMessage) => managerMessage.managerMessageBody !== managerMessageBody));
    };

    if (!managerMessages.length && !renderNotificationAlert) return null;

    return (
        <div className={classes.container}>
            {renderNotificationAlert && showNotificationSoundAlert && (
                <Alert
                    type={'error'}
                    title={translate('No notification sound?')}
                    text={translate('Configure your browser so that you dont miss any order.')}
                    actionText={translate('CONFIGURE')}
                    onActionClick={handleConfigureSound}
                    onClose={() => setShowNotificationSoundAlert(false)}
                />
            )}
            {managerMessages.map(({ managerMessageStyle, managerMessageTitle, managerMessageBody, managerMessageUrl }, idx) => (
                <Alert
                    key={idx}
                    type={managerMessageStyle}
                    title={managerMessageTitle}
                    text={managerMessageBody}
                    actionText={managerMessageUrl ? translate('See more') : ''}
                    onActionClick={() => openLink(managerMessageUrl)}
                    onClose={() => handleRemoveManagerMessage(managerMessageBody)}
                />
            ))}
        </div>
    );
}

const useStyles = makeStyles((theme) => ({
    container: {
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        gap: 10,
    },
}));

type ManagerMessage = {
    managerMessageStyle: 'error' | 'success' | 'warning';
    managerMessageTitle?: string;
    managerMessageBody?: string;
    managerMessageUrl?: string;
};
