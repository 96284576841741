import { BigNumber } from 'bignumber.js';
import type { OrderVm } from 'src/types/OrderVm';
import { getBaseOrderCost } from 'src/utils/order/getBaseOrderCost';
import { isIntegrationOrder } from 'src/utils/order/isIntegrationOrder';

export function getInternalOrderCost(order: OrderVm): string {
    const orderCost = getBaseOrderCost(order);
    if (isIntegrationOrder(order)) return orderCost;

    return BigNumber(orderCost)
        .plus(order.deliveryCostBonus ?? 0)
        .toString();
}
