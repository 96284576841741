import TextField from '@material-ui/core/TextField';
import Tooltip from '@material-ui/core/Tooltip';
import * as React from 'react';
import { translate } from 'src/i18n/translate';

export default (props: any): React.ReactElement => {
    const {
        input: { name, onChange, value, ...restInput },
        meta,
        tooltip,
        ...rest
    } = props;
    const showError = ((meta.submitError && !meta.dirtySinceLastSubmit) || meta.error) && meta.touched;

    if (tooltip) {
        return (
            <Tooltip title={tooltip} placement='bottom-start'>
                <TextField
                    {...rest}
                    name={name}
                    helperText={showError ? meta.error || meta.submitError : rest.helperText}
                    error={showError}
                    InputProps={restInput}
                    onChange={onChange}
                    value={translate(value)}
                />
            </Tooltip>
        );
    }

    return (
        <TextField
            {...rest}
            name={name}
            helperText={showError ? meta.error || meta.submitError : rest.helperText}
            error={showError}
            InputProps={restInput}
            onChange={onChange}
            value={translate(value)}
        />
    );
};
