import { makeStyles } from '@material-ui/core';
import { useState } from 'react';
import * as React from 'react';
import { returnOrderToKitchenApi } from 'src/api/letseatmanager/kitchen/returnOrderToKitchenApi';
import { updateOrderItemsKitchenStatusApi } from 'src/api/letseatmanager/kitchen/updateOrderItemsKitchenStatusApi';
import { Text } from 'src/components/Text';
import { KitchenStatuses } from 'src/constants/KitchenStatus';
import { translate } from 'src/i18n/translate';
import { KitchenOrderCard } from 'src/scenes/letseatmanager/kitchenDisplayScreen/kitchenOrder/KitchenOrderCard';
import { OrderVm } from 'src/types/OrderVm';
import { alertKnownErrorOrSomethingWentWrong } from 'src/utils/alert/alertKnownErrorOrSomethingWentWrong';
import { useUpdateKitchenOrder } from 'src/utils/kitchen/useUpdateKitchenOrder';
import { isPartiallyPreparedKitchenOrder } from 'src/utils/order/isPartiallyPreparedKitchenOrder';
import { isPreparedKitchenOrder } from 'src/utils/order/isPreparedKitchenOrder';
import { useSelector } from 'src/utils/react/useSelector';

export function KitchenPreparedOrders(): React.ReactElement {
    const classes = useStyles();

    const { updateKitchenOrder } = useUpdateKitchenOrder();

    const [loading, setLoading] = useState(false);

    const preparedKitchenOrders = useSelector((state) => state.app2.preparedKitchenOrders);
    const preparingKitchenOrders = useSelector((state) => state.app2.preparingKitchenOrders);

    const partiallyPreparedOrders = preparingKitchenOrders?.filter((order) => order.orderItems?.some((item) => isPartiallyPreparedKitchenOrder(item.orderItemKitchenStatus)));
    const preparedAndPartiallyPreparedOrders = [
        ...(partiallyPreparedOrders?.map((partialPreparedOrder) => ({
            ...partialPreparedOrder,
            orderItems: partialPreparedOrder?.orderItems?.filter((item) => isPartiallyPreparedKitchenOrder(item.orderItemKitchenStatus)),
        })) ?? []),
        ...(preparedKitchenOrders ?? []),
    ];

    const returnOrderToKitchen = async (order: OrderVm) => {
        if (loading) return;
        if (isPreparedKitchenOrder(order.kitchenStatus)) {
            await returnPreparedOrderToKitchen(order);
            return;
        }

        await returnPartiallyPreparedOrderToKitchen(order);
    };

    const returnPreparedOrderToKitchen = async (order: OrderVm) => {
        setLoading(true);
        const response = await returnOrderToKitchenApi({ orderId: order.orderId });

        if (!response.ok) {
            alertKnownErrorOrSomethingWentWrong(response);
            setLoading(false);
            return;
        }

        updateKitchenOrder({ ...order, kitchenStatus: KitchenStatuses.PREPARING });
        setLoading(false);
    };
    const returnPartiallyPreparedOrderToKitchen = async (order: OrderVm) => {
        setLoading(true);
        const response = await updateOrderItemsKitchenStatusApi({
            orderId: order.orderId,
            orderItems: order.orderItems,
            kitchenStatus: KitchenStatuses.PREPARING,
        });
        setLoading(false);
        if (!response.ok) {
            alertKnownErrorOrSomethingWentWrong(response);
            return;
        }
    };

    if (!preparedKitchenOrders?.length && !partiallyPreparedOrders?.length) {
        return (
            <div className={classes.emptyDescription}>
                <Text size={'large'}>{translate('No kitchen orders')}</Text>
            </div>
        );
    }

    return (
        <div className={classes.ordersContainer}>
            {preparedAndPartiallyPreparedOrders?.map((order: OrderVm) => {
                return (
                    <div key={order.orderId}>
                        <KitchenOrderCard order={order} onClick={() => returnOrderToKitchen(order)} />
                    </div>
                );
            })}
        </div>
    );
}

const useStyles = makeStyles((theme) => ({
    ordersContainer: {
        width: '100%',
        height: 'fit-content',
        display: 'grid',
        gridTemplateColumns: 'repeat(auto-fill, minmax(255px, 1fr))',
        gap: 20,
        marginBottom: '6vh',
    },
    emptyDescription: {
        width: '100%',
        height: '100vh',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
}));
