import { makeStyles } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import * as React from 'react';
import { useForm, useWatch } from 'react-hook-form';
import { createManagerUserApi } from 'src/api/letseatadmin/managerUser/createManagerUserApi';
import { Button } from 'src/components/Button';
import { Dialog } from 'src/components/Dialog';
import { DialogActions } from 'src/components/DialogActions';
import { Form } from 'src/components/form/Form';
import { FormRestaurantsAutocomplete } from 'src/components/form/FormRestaurantsAutocomplete';
import { FormTextField } from 'src/components/form/FormTextField';
import { FormUserTypeSelect } from 'src/components/form/FormUserTypeSelect';
import { UserType } from 'src/constants/UserType';
import { translate } from 'src/i18n/translate';
import { appLayout } from 'src/theme/AppLayout';
import { alertKnownErrorOrSomethingWentWrong } from 'src/utils/alert/alertKnownErrorOrSomethingWentWrong';
import { isRestaurantUser } from 'src/utils/user/isRestaurantUser';

export function CreateManagerUserDialog({ open, onClose, onManagerUserCreated }: Props): React.ReactElement {
    const classes = useStyles();

    const form = useForm();
    const {
        formState: { isSubmitting },
        control,
    } = form;

    const userType = useWatch<UserType>({ name: 'userType', control });

    const handleClose = () => {
        if (isSubmitting) return;
        onClose();
    };

    const onSubmit = async (form: any) => {
        const response = await createManagerUserApi({
            username: form.username,
            userType: form.userType,
            restaurantIds: form.restaurantIds,
        });
        if (!response.ok) {
            alertKnownErrorOrSomethingWentWrong(response);
            return;
        }
        onManagerUserCreated();
        onClose();
    };

    return (
        <Dialog open={open} onClose={handleClose} title={translate('Create Manager User')}>
            <Form form={form} onSubmit={onSubmit} style={appLayout.dialog.form}>
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <FormTextField name='username' label={translate('Username')} required />
                    </Grid>
                    <Grid item xs={12}>
                        <FormUserTypeSelect name='userType' label={translate('User Type')} required />
                    </Grid>
                    {isRestaurantUser(userType) && (
                        <Grid item xs={12}>
                            <FormRestaurantsAutocomplete name='restaurantIds' label={translate('Restaurants')} />
                        </Grid>
                    )}
                </Grid>
                <DialogActions className={classes.dialogActions}>
                    <Button classes={{ button: classes.button }} secondary onClick={handleClose} disabled={isSubmitting}>
                        {translate('Cancel')}
                    </Button>
                    <Button classes={{ button: classes.button }} type='submit' disabled={isSubmitting}>
                        {isSubmitting ? translate('Creating') : translate('Create')}
                    </Button>
                </DialogActions>
            </Form>
        </Dialog>
    );
}

const useStyles = makeStyles((theme) => ({
    dialog: {
        width: '50vw',
    },
    dialogActions: {
        marginTop: 20,
    },
    button: {
        width: 200,
    },
}));

type Props = {
    open: boolean;
    onClose: any;
    onManagerUserCreated: any;
};
