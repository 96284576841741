import { Marker } from '@react-google-maps/api';
import { useEffect } from 'react';
import * as React from 'react';
import motorcyclePinGreen from 'src/images/motorcyclePinGreen.svg';
import motorcyclePinPurple from 'src/images/motorcyclePinPurple.svg';
import motorcyclePinRed from 'src/images/motorcyclePinRed.svg';
import motorcyclePinYellow from 'src/images/motorcyclePinYellow.svg';
import { liveDrivers } from 'src/reducers/liveDriversReducer';
import type { DriverLivePositionVm } from 'src/types/DriverLivePositionVm';
import { toLatLng } from 'src/utils/googlemaps/toLatLng';
import { useAction } from 'src/utils/react/useAction';
import { useSelector } from 'src/utils/react/useSelector';

export function DriverMarker({ driverLivePosition }: Props): React.ReactElement {
    const showDriverInfo = useSelector((state) => state.liveDrivers.showDriverInfo);
    const driverInfo = useSelector((state) => state.liveDrivers.driverInfo);

    const setDriverInfo = useAction(liveDrivers.actions.setDriverInfo);

    useEffect(() => {
        if (showDriverInfo && driverInfo?.driverId === driverLivePosition.driverId) {
            setDriverInfo(driverLivePosition);
        }
    }, [showDriverInfo, driverInfo, driverLivePosition]);

    const getIcon = (driverLivePosition: DriverLivePositionVm) => {
        if (driverLivePosition.orderIds.length === 1) return motorcyclePinPurple;
        if (driverLivePosition.orderIds.length === 2) return motorcyclePinYellow;
        if (driverLivePosition.orderIds.length > 2) return motorcyclePinRed;
        return motorcyclePinGreen;
    };

    const handleClick = () => setDriverInfo(driverLivePosition);

    return <Marker onClick={handleClick} icon={getIcon(driverLivePosition)} position={toLatLng(driverLivePosition.position) as any} />;
}

type Props = {
    driverLivePosition: DriverLivePositionVm;
};
