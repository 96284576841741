import { letseatmanagerApiMethod } from 'src/api/letseatmanagerApi';
import type { ApiSauceResponse } from 'src/api/types/ApiSauceResponse';
import type { CashRegisterCustomReason, RestaurantId } from 'src/types/Id';

export async function findCashRegisterWithdrawReasonsApi(request: FindCashRegisterWithdrawReasonsApiRequest): ApiSauceResponse<FindCashRegisterWithdrawReasonsApiResponse> {
    return letseatmanagerApiMethod('pos/findCashRegisterWithdrawReasonsApi', request);
}

export type FindCashRegisterWithdrawReasonsApiRequest = {
    restaurantId: RestaurantId;
};

export type FindCashRegisterWithdrawReasonsApiResponse = Array<{
    reason: CashRegisterCustomReason;
}>;
