import { UnitOfMeasurement, UnitsOfMeasurement } from 'src/constants/UnitOfMeasurement';
import { isString } from 'src/utils/string/isString';

export function requireUnitOfMeasurement<T extends string>(value: T | undefined | null): UnitOfMeasurement {
    if (value === undefined) throw new Error('Required value is undefined when it should be a UnitOfMeasurement');

    if (value === null) throw new Error('Required value is null when it should be a UnitOfMeasurement');

    if (!isString(value)) throw new Error(`Required value is ${value} when it should be a UnitOfMeasurement`);

    if (!Object.values(UnitsOfMeasurement).includes(value as UnitOfMeasurement)) throw new Error(`Required value is "${value as any}" when it should be a UnitOfMeasurement`);

    return value as any;
}
