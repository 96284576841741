import { makeStyles } from '@material-ui/core';
import * as React from 'react';
import { useState } from 'react';
import { Button } from 'src/components/Button';
import { AddPosOrderTipDialog } from 'src/components/dialog/order/AddPosOrderTipDialog';
import { SecuredContent } from 'src/components/SecuredContent';
import { RolePermissions } from 'src/constants/RolePermission';
import { translate } from 'src/i18n/translate';
import type { OrderVm } from 'src/types/OrderVm';
import { PosPayment } from 'src/types/PosPayment';
import { isPosApp } from 'src/utils/app/isPosApp';
import { isClosedOrder } from 'src/utils/order/isClosedOrder';
import { isCompletedOrder } from 'src/utils/order/isCompletedOrder';
import { isDeliveryOrder } from 'src/utils/order/isDeliveryOrder';
import { isPendingOrder } from 'src/utils/order/isPendingOrder';
import { isPaymentLinkPayment } from 'src/utils/paymentMethod/isPaymentLinkPayment';
import { useSelector } from 'src/utils/react/useSelector';

export function AddTipToPosOrderButton({ onChange, order }: Props): React.ReactElement | null {
    const classes = useStyles();
    const [addTipDialogState, setAddTipDialogState] = useState({ open: false });

    const payments = useSelector((state) => state.pos.payments);
    const disabledPosPaymentActions = useSelector((state) => state.pos.disabledPosPaymentActions);
    const paying = useSelector((state) => state.pos.paying);

    const disabled = disabledPosPaymentActions || paying;
    const shouldRenderAddTiButton =
        (isCompletedOrder(order.orderStatus) || isPendingOrder(order.orderStatus)) && isPosApp(order.app) && !isClosedOrder(order.orderStatus) && !isDeliveryOrder(order.orderType);
    const posPaymentIsPaymentLink = payments?.some((posPayment: PosPayment) => isPaymentLinkPayment(posPayment.paymentMethod));

    const openAddOrderTipDialog = () => setAddTipDialogState({ open: true });
    const closeAddOrderTipDialog = () => setAddTipDialogState({ open: false });

    if (!shouldRenderAddTiButton) return null;

    return (
        <SecuredContent rolePermission={RolePermissions.ADD_TIP_ON_CLOSED_ORDERS}>
            <AddPosOrderTipDialog open={addTipDialogState.open} onClose={closeAddOrderTipDialog} onSuccess={onChange} order={order} />
            <Button secondary classes={{ button: classes.basicButton }} disabled={disabled || posPaymentIsPaymentLink} onClick={openAddOrderTipDialog}>
                {translate('Add Tip')}
            </Button>
        </SecuredContent>
    );
}

const useStyles = makeStyles((theme) => ({
    basicButton: {
        border: '1px solid #ABB7BE',
        backgroundColor: 'transparent',
        color: '#6C7076',
        height: 42,
        '&:hover': {
            borderColor: '#D9D9D9',
            cursor: 'pointer',
            backgroundColor: 'rgba(0, 0, 0, 0.04)',
        },
        '&:focus': {
            borderColor: '#D9D9D9',
        },
    },
}));

type Props = {
    order: OrderVm;
    onChange?: any;
};
