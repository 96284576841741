import { Button } from '@pidedirecto/ui';
import { EditIcon } from '@pidedirecto/ui/icons';
import * as React from 'react';
import { useState } from 'react';
import { translate } from 'src/i18n/translate';
import { ChangePromotionDialog } from 'src/scenes/letseatmanager/promotions/ChangePromotionDialog';
import { PromotionId } from 'src/types/Id';

export function ChangePromotionButton({ promotionId, onSuccess }: Props): React.ReactElement {
    const [changePromotionDialogState, setChangePromotionDialogState] = useState<{ open: boolean; promotionId: PromotionId | undefined }>({ open: false, promotionId: undefined });

    const openChangePromotionDialog = () => setChangePromotionDialogState({ open: true, promotionId });

    const closeChangePromotionDialog = () => setChangePromotionDialogState({ open: false, promotionId: undefined });

    return (
        <>
            <ChangePromotionDialog open={changePromotionDialogState.open} promotionId={changePromotionDialogState.promotionId} onClose={closeChangePromotionDialog} onSuccess={onSuccess} />
            <Button variant='secondary' onClick={openChangePromotionDialog}>
                <EditIcon size={20} title={translate('Change promotion')} />
            </Button>
        </>
    );
}

type Props = {
    promotionId: PromotionId;
    onSuccess?: () => void | Promise<void>;
};
