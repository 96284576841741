import { letseatadminApiMethod } from 'src/api/letseatadminApi';
import type { ApiSauceResponse } from 'src/api/types/ApiSauceResponse';
import type { CustomAlertDialogId } from 'src/types/Id';

export async function enableCustomAlertDialogApi(request: EnableCustomAlertDialogApiRequest): ApiSauceResponse<void> {
    return letseatadminApiMethod('customAlertDialogs/enableCustomAlertDialogApi', request);
}

type EnableCustomAlertDialogApiRequest = {
    customAlertDialogId: CustomAlertDialogId;
};
