import { Apps } from 'src/constants/App';
import type { OrderVm } from 'src/types/OrderVm';

export function isPideDirectoDelivery(order: OrderVm): boolean {
    if (order.app === Apps.DIDI_FOOD && !order.deliverExternalOrder) return false;
    if (order.app === Apps.RAPPI && !order.deliverExternalOrder) return false;
    if (order.app === Apps.UBER_EATS && !order.deliverExternalOrder) return false;
    if (order.externalDeliveryProvider) return false;
    return true;
}
