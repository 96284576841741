import { letseatadminApiMethod } from 'src/api/letseatadminApi';
import type { ApiSauceResponse } from 'src/api/types/ApiSauceResponse';
import type { City } from 'src/constants/City';
import type { DeliveryStatus } from 'src/constants/DeliveryStatus';
import type { OrderStatus } from 'src/constants/OrderStatus';
import type { LiveRestaurantVm } from 'src/types/LiveRestaurantVm';

export async function findOrderLivePositionsByCityApi(request: FindOrderLivePositionsByCityApiRequest): ApiSauceResponse<Array<LiveRestaurantVm>> {
    return letseatadminApiMethod('orderLivePosition/findOrderLivePositionsApi', request);
}

type FindOrderLivePositionsByCityApiRequest = {
    city: City;
    orderStatus?: Array<OrderStatus>;
    deliveryStatus?: Array<DeliveryStatus>;
};
