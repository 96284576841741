import * as React from 'react';
import { findRestaurantTaxesApi } from 'src/api/letseatmanager/taxes/findRestaurantTaxesApi';
import { FormAutocompleteMultiple } from 'src/components/form/FormAutocompleteMultiple';
import type { Variant } from 'src/components/form/FormTypes';
import type { RestaurantTaxVm } from 'src/types/RestaurantTaxVm';
import { useLoadApi } from 'src/utils/react/useLoadApi';
import { useSelector } from 'src/utils/react/useSelector';

export function FormRestaurantTaxAutocompleteMultiple({ name, label, placeholder, helperText, defaultValue, disabled, required, variant }: Props): React.ReactElement {
    const restaurantId = useSelector((state) => state.app.restaurantId);

    const [loadingRestaurantTaxes, restaurantTaxes] = useLoadApi(findRestaurantTaxesApi, { restaurantId }, { requiredValues: [restaurantId], dependencies: [restaurantId] });

    const options = restaurantTaxes?.map((tax: RestaurantTaxVm) => ({ label: tax.name, value: tax.restaurantTaxId })) || [];

    if (!required) {
        options.unshift({ label: '', value: '' as any });
    }

    return (
        <FormAutocompleteMultiple
            name={name}
            label={label}
            placeholder={placeholder}
            helperText={helperText}
            defaultValue={defaultValue}
            options={options}
            disabled={disabled || loadingRestaurantTaxes}
            required={required}
            variant={variant}
        />
    );
}

type Props = {
    name: string;
    label?: string;
    placeholder?: string;
    helperText?: string;
    defaultValue?: any;
    disabled?: boolean;
    required?:
        | boolean
        | {
              value: number;
              message: string;
          };
    variant?: Variant;
};
