import { makeStyles } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import CloseIcon from '@material-ui/icons/Close';
import { useState } from 'react';
import * as React from 'react';
import { useForm } from 'react-hook-form';
import { createSupplierApi } from 'src/api/letseatmanager/supplier/createSupplierApi';
import { Button } from 'src/components/Button';
import { Dialog } from 'src/components/Dialog';
import { DialogActions } from 'src/components/DialogActions';
import { Form } from 'src/components/form/Form';
import { FormCurrencyNumberStringField } from 'src/components/form/FormCurrencyNumberStringField';
import { FormEmailField } from 'src/components/form/FormEmailField';
import { FormPhoneNumberField } from 'src/components/form/FormPhoneNumberField';
import { FormRfcField } from 'src/components/form/FormRfcField';
import { FormSupplyAutocomplete } from 'src/components/form/FormSupplyAutocomplete';
import { FormTextField } from 'src/components/form/FormTextField';
import { translate } from 'src/i18n/translate';
import { SupplyId } from 'src/types/Id';
import type { SupplyVm } from 'src/types/SupplyVm';
import { alertKnownErrorOrSomethingWentWrong } from 'src/utils/alert/alertKnownErrorOrSomethingWentWrong';
import { useSelector } from 'src/utils/react/useSelector';

export function CreateSupplierDialog({ open, handleClose, onSupplierCreated }: Props): React.ReactElement {
    const form = useForm();
    const {
        formState: { isSubmitting },
    } = form;

    const classes = useStyles();

    const [supplies, setSupplies] = useState<Array<SupplyVm>>([]);
    const [currentSupply, setCurrentSupply] = useState<SupplyVm | undefined>(undefined);

    const restaurantId = useSelector((state) => state.app.restaurantId);

    const onClose = () => {
        if (isSubmitting) return;
        setSupplies([]);
        setCurrentSupply(undefined);
        handleClose();
    };

    const onSubmit = async (form: any) => {
        const response = await createSupplierApi({
            restaurantId,
            supplyIds: supplies.map((supply) => supply.supplyId),
            businessName: form.businessName,
            rfc: form.rfc,
            email: form.email,
            phoneNumber: form.phoneNumber,
            pendingBalance: form.pendingBalance,
            note: form.note,
        });
        if (!response.ok) {
            alertKnownErrorOrSomethingWentWrong(response);
            return;
        }
        onClose();
        onSupplierCreated();
    };

    const addSupply = () => {
        if (!currentSupply || supplies.some((supply) => supply.supplyId === currentSupply.supplyId)) return;
        setSupplies([...supplies, currentSupply]);
    };

    const removeSupply = (supplyId: SupplyId) => {
        setSupplies(supplies.filter((supply) => supply.supplyId !== supplyId));
    };

    return (
        <Dialog open={open} onClose={onClose}>
            <Form form={form} onSubmit={onSubmit}>
                <Grid container spacing={6}>
                    <Grid item xs={12} md={6}>
                        <h1 className={classes.title}>{translate('Supplier')}</h1>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <FormTextField name='businessName' label={translate('Business Name')} required />
                            </Grid>
                            <Grid item xs={12}>
                                <FormRfcField name='rfc' label={translate('RFC')} required />
                            </Grid>
                            <Grid item xs={12}>
                                <FormEmailField name='email' label={translate('Email')} required />
                            </Grid>
                            <Grid item xs={12}>
                                <FormPhoneNumberField name='phoneNumber' label={translate('Phone Number')} required />
                            </Grid>
                            <Grid item xs={12}>
                                <FormCurrencyNumberStringField name='pendingBalance' label={translate('Pending Balance')} />
                            </Grid>
                            <Grid item xs={12}>
                                <FormTextField name='note' label={translate('Note')} />
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <h1 className={classes.title}>{translate('Supplies')}</h1>
                        <Grid container spacing={2}>
                            <Grid item xs={8}>
                                <FormSupplyAutocomplete name='supplyId' label={translate('Supply')} onChange={setCurrentSupply} />
                            </Grid>
                            <Grid item xs={4}>
                                <Button onClick={addSupply} classes={{ button: classes.addSupplierButton }}>
                                    {translate('Add')}
                                </Button>
                            </Grid>
                            <Grid item xs={12}>
                                {supplies.map((supply) => {
                                    return (
                                        <div className={classes.supplyContainer} key={supply.supplyId}>
                                            <span className={classes.supply}>{supply.name}</span>
                                            <Button icon onClick={() => removeSupply(supply.supplyId)}>
                                                <CloseIcon />
                                            </Button>
                                        </div>
                                    );
                                })}
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>

                <DialogActions>
                    <Button secondary onClick={handleClose} disabled={isSubmitting}>
                        {translate('Cancel')}
                    </Button>
                    <Button primary type='submit' disabled={isSubmitting}>
                        {isSubmitting ? translate('Creating') : translate('Create')}
                    </Button>
                </DialogActions>
            </Form>
        </Dialog>
    );
}

const useStyles = makeStyles((theme) => ({
    title: {
        fontFamily: theme.typography.medium,
        fontSize: 20,
        marginBottom: 50,
    },
    acceptButton: {
        backgroundColor: theme.palette.primary.main,
        color: 'white',
        fontFamily: theme.typography.semiBold,
        fontSize: 15,
        borderRadius: 15,
        height: 30,
        width: 150,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        textTransform: 'none',
    },
    secondaryButton: {
        backgroundColor: 'white',
        color: theme.palette.primary.main,
        border: `1px solid ${theme.palette.primary.main}`,
        fontFamily: theme.typography.semiBold,
        fontSize: 15,
        borderRadius: 15,
        height: 30,
        width: 150,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        textTransform: 'none',
    },
    addSupplierButton: {
        marginTop: 20,
    },
    supplyContainer: {
        width: '100%',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        padding: '5px 15px',
        borderBottom: `1px solid ${theme.palette.secondary.dark}`,
    },
    supply: {
        fontFamily: theme.typography.regular,
        fontSize: 15,
    },
}));

type Props = {
    open: boolean;
    handleClose: any;
    onSupplierCreated: any;
};
