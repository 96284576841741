import { makeStyles } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Grid from '@material-ui/core/Grid';
import LinearProgress from '@material-ui/core/LinearProgress';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { changeEmailTemplateApi } from 'src/api/letseatadmin/emailTemplate/changeEmailTemplateApi';
import { getEmailTemplateApi } from 'src/api/letseatadmin/emailTemplate/getEmailTemplateApi';
import type { EmailTemplateVm } from 'src/api/letseatadmin/emailTemplate/getEmailTemplatesApi';
import { app2 } from 'src/app2';
import { Field, Form } from 'src/components/deprecated/react-final-form/Form';
import TextField from 'src/components/deprecated/react-final-form/TextField';
import { translate } from 'src/i18n/translate';
import { appLayout } from 'src/theme/AppLayout';
import { alertKnownErrorOrSomethingWentWrong } from 'src/utils/alert/alertKnownErrorOrSomethingWentWrong';
import { useAction } from 'src/utils/react/useAction';
import { useSelector } from 'src/utils/react/useSelector';
import { required } from 'src/utils/Validator';

export default function ChangeEmailTemplateDialog(): React.ReactElement {
    const classes = useStyles();

    const [loading, setLoading] = useState(false);
    const [emailTemplate, setEmailTemplate] = useState<EmailTemplateVm | undefined>(undefined);
    const open = useSelector((state) => state.app2.changeEmailTemplateDialog.open);
    const emailTemplateId = useSelector((state) => state.app2.changeEmailTemplateDialog.emailTemplateId);
    const onSuccess = useSelector((state) => state.app2.changeEmailTemplateDialog.onSuccess);
    const close = useAction(app2.actions.closeChangeEmailTemplateDialog);

    useEffect(() => {
        if (!emailTemplateId) {
            return;
        }
        async function load() {
            const response = await getEmailTemplateApi({ emailTemplateId });
            if (!response.ok) {
                alertKnownErrorOrSomethingWentWrong(response);
                return;
            }
            setEmailTemplate(response.data);
        }

        let cancelled = false;
        load();
        return () => {
            cancelled = true;
        };
    }, [emailTemplateId]);

    return (
        <Dialog
            fullScreen
            aria-labelledby='ChangeEmailTemplateDialog-title'
            scroll='paper'
            open={open}
            // onClose={close}
        >
            <Form
                onSubmit={async (form: any) => {
                    if (loading) return;
                    setLoading(true);
                    const response = await changeEmailTemplateApi({
                        emailTemplateId,
                        name: form.emailTemplate.name,
                        subject: form.emailTemplate.subject,
                        bodyHtml: form.emailTemplate.bodyHtml,
                        bodyText: form.emailTemplate.bodyText,
                    });
                    if (!response.ok) {
                        setLoading(false);
                        alertKnownErrorOrSomethingWentWrong(response);
                        return;
                    }
                    setLoading(false);
                    close();
                    const emailTemplate: EmailTemplateVm = response.data;
                    onSuccess && onSuccess(emailTemplate);
                }}
                initialValues={{
                    emailTemplate: emailTemplate,
                }}
                render={({ handleSubmit, values }: { handleSubmit: any; values: any }) => {
                    return (
                        <form onSubmit={handleSubmit} noValidate style={appLayout.dialog.form}>
                            <DialogTitle id='ChangeEmailTemplateDialog-title'>{translate('Change Email Template').toUpperCase()}</DialogTitle>
                            <DialogContent>
                                <Grid container spacing={3}>
                                    <Grid item xs={12}>
                                        <Field name='emailTemplate.name' label={translate('Name')} component={TextField} fullWidth disabled={loading} required validate={required} />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Field name='emailTemplate.subject' label={translate('Subject')} component={TextField} multiline fullWidth disabled={loading} required validate={required} />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Field name='emailTemplate.bodyHtml' label={translate('Body Html')} component={TextField} multiline fullWidth disabled={loading} required validate={required} />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Field name='emailTemplate.bodyText' label={translate('Body Text')} component={TextField} multiline fullWidth disabled={loading} required validate={required} />
                                    </Grid>
                                </Grid>
                            </DialogContent>
                            <DialogActions>
                                <Button onClick={close} disabled={loading}>
                                    {translate('Cancel').toUpperCase()}
                                </Button>
                                <Button color='primary' type='submit' disabled={loading}>
                                    {loading ? translate('Changing').toUpperCase() : translate('Change').toUpperCase()}
                                </Button>
                            </DialogActions>
                            {loading && <LinearProgress className={classes.linearProgress} />}
                        </form>
                    );
                }}
            />
        </Dialog>
    );
}

const useStyles = makeStyles((theme) => ({
    linearProgress: {
        position: 'absolute',
        width: '100%',
        bottom: 0,
    },
}));
