import { BigNumber } from 'bignumber.js';
import { PaymentMethods } from 'src/constants/PaymentMethod';
import type { PosPayment } from 'src/types/PosPayment';
import { sum } from 'src/utils/reduce/sum';

export function getCashAmountPaid(posPayments: Array<PosPayment>): string {
    const cashPayments = posPayments.filter((posPayment) => posPayment.paymentMethod === PaymentMethods.CASH);
    const cashPaymentsAmounts = cashPayments.map((cashPayment) => cashPayment.amount);
    return cashPaymentsAmounts.reduce(sum, BigNumber(0)).toString();
}
