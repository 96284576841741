import { makeStyles } from '@material-ui/core';
import * as React from 'react';
import { PosOrderSteps } from 'src/constants/PosOrderStep';
import { translate } from 'src/i18n/translate';
import { PosMenu } from 'src/scenes/letseatmanager/pos/posMenu/PosMenu';
import { PosMenuLoader } from 'src/scenes/letseatmanager/pos/posMenu/PosMenuLoader';
import { PosOrderTypeSelect } from 'src/scenes/letseatmanager/pos/PosOrderTypeSelect';
import { PosPayment } from 'src/scenes/letseatmanager/pos/posPayment/PosPayment';
import { PosRouter } from 'src/scenes/letseatmanager/pos/PosRouter';
import { PosTableSelect } from 'src/scenes/letseatmanager/pos/PosTableSelect';
import { PosRestaurantZones } from 'src/scenes/letseatmanager/posRestaurantZones/PosRestaurantZones';
import { useSelector } from 'src/utils/react/useSelector';

export function PosOrderStepsComponent(): React.ReactElement | null {
    const classes = useStyles();

    const orderStep = useSelector((state) => state.pos.orderStep);
    const posSimpleView = useSelector((state) => state.pos.context?.posSimpleView);
    const posShowTablesEnabled = useSelector((state) => state.pos.context?.posShowTablesEnabled);

    if (orderStep === PosOrderSteps.CREATE_ORDER) {
        return (
            <PosMenuLoader>
                <PosMenu />
            </PosMenuLoader>
        );
    }

    if (orderStep === PosOrderSteps.PAYMENT) return <PosPayment />;
    if (orderStep === PosOrderSteps.SELECT_TABLE) return <PosTableSelect />;

    if (orderStep === PosOrderSteps.SELECT_ORDER_TYPE && !posSimpleView) return <PosOrderTypeSelect />;

    if (orderStep === PosOrderSteps.SELECT_ORDER_TYPE && posSimpleView && posShowTablesEnabled) {
        return (
            <>
                <div className={classes.tabContainer}>
                    <PosRouter />
                </div>
                <div className={classes.container}>
                    <h1 className={classes.title}>{translate('Choose table')}</h1>
                    <PosRestaurantZones editDisabled />
                </div>
            </>
        );
    }

    return null;
}

const useStyles = makeStyles((theme) => ({
    tabContainer: {
        width: '100%',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
    },
    container: {
        width: '100%',
        height: '70vh',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'flex-start',
        [theme.breakpoints.down('sm')]: {
            height: '90vh',
        },
    },
    title: {
        color: '#2C374A',
        fontFamily: theme.typography.regular,
        fontSize: 30,
        marginTop: 0,
    },
}));
