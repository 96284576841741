import { letseatmanagerApiMethod } from 'src/api/letseatmanagerApi';
import type { ApiSauceResponse } from 'src/api/types/ApiSauceResponse';
import type { RestaurantId } from 'src/types/Id';
import type { ManagerMenuItemVm } from 'src/types/ManagerMenuItemVm';

export async function findRestaurantMenuItemsApi(request: FindMenuItemsByRestaurantIdsApiRequest): ApiSauceResponse<Array<FindMenuItemsByRestaurantIdsApiResponse>> {
    return letseatmanagerApiMethod('restaurant/findRestaurantsMenuItemsApi', request);
}

export type FindMenuItemsByRestaurantIdsApiRequest = {
    restaurantIds: Array<RestaurantId>;
};

type FindMenuItemsByRestaurantIdsApiResponse = {
    menuItems: Array<ManagerMenuItemVm>;
    restaurantName: string;
};
