import { makeStyles } from '@material-ui/core';
import { Button, Chip, Dialog, DialogActions, DialogContent } from '@pidedirecto/ui';
import { Form, FormDateTimePicker, FormIntegerField, FormScheduleDaySelect, FormTextField } from '@pidedirecto/ui/form';
import { useForm, useNotification } from '@pidedirecto/ui/hooks';
import { CrossIcon } from '@pidedirecto/ui/icons';
import * as React from 'react';
import { useState } from 'react';
import { useRef } from 'react';
import { useEffect } from 'react';
import { createPromotionApi } from 'src/api/letseatmanager/promotion/createPromotionApi';
import { Channels } from 'src/components/Channels';
import { FormOrderTypeSelect } from 'src/components/form/FormOrderTypeSelect';
import { FormPromotionTypeSelect } from 'src/components/form/FormPromotionTypeSelect';
import { Apps } from 'src/constants/App';
import { OrderType, OrderTypes } from 'src/constants/OrderType';
import { PromotionApp } from 'src/constants/PromotionApp';
import { PromotionType } from 'src/constants/PromotionType';
import { translate } from 'src/i18n/translate';
import { SelectProductsDialog } from 'src/scenes/letseatmanager/promotions/SelectProductsDialog';
import { useMenuItems } from 'src/services/menuItem/useMenuItems';
import { MenuItemId } from 'src/types/Id';
import { alertKnownErrorOrSomethingWentWrong } from 'src/utils/alert/alertKnownErrorOrSomethingWentWrong';
import { useSelector } from 'src/utils/react/useSelector';

export function CreatePromotionDialog({ open, onClose, onSuccess }: Props): React.ReactElement {
    const notification = useNotification();
    const classes = useStyles();
    const formValues = useRef<FormType | null>(null);
    const form = useForm();
    const { products } = useMenuItems();

    const [loading, setLoading] = useState(false);
    const [selectedMenuItemIds, setSelectedMenuItemIds] = useState<Array<MenuItemId>>([]);
    const [selectedChannels, setSelectedChannels] = useState<Array<PromotionApp>>([]);
    const [selectProductsDialogState, setSelectProductsDialogState] = useState({ open: false });

    const restaurantId = useSelector((state) => state.app.restaurantId);

    const selectedProducts = products.filter((menuItem) => selectedMenuItemIds.includes(menuItem.menuItemId));
    const hours = form.watch('hours');

    useEffect(() => {
        if (!selectProductsDialogState.open && formValues.current) {
            form.reset(formValues.current);
            formValues.current = null;
        }
    }, [selectProductsDialogState]);

    useEffect(() => {
        if (!open) {
            clearForm();
        }
    }, [open]);

    const closeDialog = () => {
        if (loading) return;
        onClose();
    };

    const createPromotion = async (form: FormType) => {
        if (!selectedChannels.length) {
            notification({ message: translate('Sales channels are required') });
            return;
        }
        if (!selectedMenuItemIds.length) {
            notification({ message: translate('Products are required') });
            return;
        }
        setLoading(true);
        const response = await createPromotionApi({
            restaurantIds: [restaurantId],
            menuItemIds: selectedMenuItemIds,
            promotionType: form.promotionType,
            menuItemsQuantityToGet: form.menuItemsQuantityToGet,
            menuItemsQuantityToPay: form.menuItemsQuantityToPay,
            name: form.name,
            hours: form.hours,
            description: form.description,
            orderTypes: formatOrderTypes(form.orderTypes),
            channels: selectedChannels,
            startsAt: form.startsAt,
            endsAt: form.endsAt,
        });
        setLoading(false);
        if (!response.ok) {
            alertKnownErrorOrSomethingWentWrong(response);
            return;
        }
        clearForm();
        onClose();
        onSuccess?.();
    };

    const formatOrderTypes = (orderTypes: Array<OrderType>) => {
        if (orderTypes.includes(OrderTypes.TABLE_ORDER)) return [...orderTypes, OrderTypes.SELF_SERVICE_ORDER];
        return orderTypes;
    };

    const openSelectProductsDialog = () => {
        formValues.current = form.getValues() as FormType;
        setSelectProductsDialogState({ open: true });
    };

    const closeSelectProductsDialog = () => {
        setSelectProductsDialogState({ open: false });
    };

    const selectMenuItems = (menuItemIds: Array<MenuItemId>) => setSelectedMenuItemIds(menuItemIds);

    const selectChannels = (channels: Array<PromotionApp>) => setSelectedChannels(channels);

    const removeProduct = (menuItemId: MenuItemId) => {
        setSelectedMenuItemIds(selectedMenuItemIds.filter((selectedMenuItemId) => selectedMenuItemId !== menuItemId));
    };

    const clearForm = () => {
        setSelectedMenuItemIds([]);
        setSelectedChannels([]);
        formValues.current = null;
        form.reset({
            promotionType: null,
            menuItemsQuantityToGet: null,
            menuItemsQuantityToPay: null,
            name: null,
            description: null,
            orderTypes: null,
            startsAt: null,
            endsAt: null,
            hours: '24/7',
        });
    };

    return (
        <>
            <SelectProductsDialog open={selectProductsDialogState.open} defaultSelectedMenuItemIds={selectedMenuItemIds} onClose={closeSelectProductsDialog} onSelect={selectMenuItems} />
            <Dialog open={open && !selectProductsDialogState.open} title={translate('Create promotion')}>
                <Form form={form} onSubmit={createPromotion}>
                    <DialogContent>
                        <FormPromotionTypeSelect name='promotionType' label={translate('Promotion type')} required />
                        <div className={classes.row}>
                            <FormIntegerField name='menuItemsQuantityToGet' label={translate('Items to get')} required inputProps={{ classes: { container: classes.input } }} />
                            <FormIntegerField name='menuItemsQuantityToPay' label={translate('Items to pay')} required inputProps={{ classes: { container: classes.input } }} />
                        </div>
                        <div className={classes.productsContainer}>
                            <Button variant='outline' onClick={openSelectProductsDialog}>
                                {translate('Select affected products')}
                            </Button>
                            {!!selectedProducts.length && (
                                <div className={classes.product}>
                                    {selectedProducts.map((selectedProduct) => (
                                        <Chip classes={{ chip: classes.productChip }} key={selectedProduct.menuItemId}>
                                            {selectedProduct.name}
                                            <Button variant='icon' onClick={() => removeProduct(selectedProduct.menuItemId)} classes={{ button: classes.productChipRemoveButton }}>
                                                <CrossIcon title={translate('Remove @productName', { productName: selectedProduct.name })} />
                                            </Button>
                                        </Chip>
                                    ))}
                                </div>
                            )}
                        </div>
                        <FormTextField name='name' label={translate('Name')} required />
                        <FormTextField name='description' label={translate('Description')} />
                        <FormOrderTypeSelect name='orderTypes' label={translate('Order type')} required multiselectable />
                        <div>
                            <span className={classes.label}>{translate('Sales channels')}</span>
                            <Channels excludedChannels={[Apps.UBER_EATS, Apps.DIDI_FOOD, Apps.RAPPI, Apps.PEDIDOS_YA]} onChange={selectChannels} />
                        </div>
                        <FormDateTimePicker name='startsAt' label={translate('Promotions valid from')} required />
                        <FormDateTimePicker name='endsAt' label={translate('Promotions valid until')} required />
                        <FormScheduleDaySelect
                            name='hours'
                            label={translate('Available days')}
                            scheduleLabel={translate('Schedule')}
                            required
                            daysTranslations={{
                                Mo: translate('ShortenedDays.Mo'),
                                Tu: translate('ShortenedDays.Tu'),
                                We: translate('ShortenedDays.We'),
                                Th: translate('ShortenedDays.Th'),
                                Fr: translate('ShortenedDays.Fr'),
                                Sa: translate('ShortenedDays.Sa'),
                                Su: translate('ShortenedDays.Su'),
                            }}
                        />
                    </DialogContent>
                    <DialogActions>
                        <Button variant='secondary' onClick={closeDialog}>
                            {translate('Cancel')}
                        </Button>
                        <Button type='submit'>{translate('Create')}</Button>
                    </DialogActions>
                </Form>
            </Dialog>
        </>
    );
}

const useStyles = makeStyles((theme) => ({
    row: {
        width: '100%',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        gap: 10,
    },
    productsContainer: {
        display: 'flex',
        flexDirection: 'column',
        gap: 6,
    },
    product: {
        display: 'flex',
        flexDirection: 'row',
        width: '100%',
        gap: 4,
    },
    productChip: {
        borderRadius: 4,
        backgroundColor: theme.palette.surface.secondary,
        color: theme.palette.text.primary,
        fontSize: 12,
        gap: 6,
        padding: 16,
    },
    productChipRemoveButton: {
        width: 14,
        height: 14,
        minHeight: 14,
        padding: 0,
        border: theme.palette.border.secondary,
    },
    label: {
        fontSize: 14,
        fontFamily: theme.typography.regular,
        color: theme.palette.text.primary,
    },
    input: {
        minWidth: 'unset',
    },
}));

type Props = {
    open: boolean;
    onClose: () => void;
    onSuccess?: () => Promise<void> | void;
};

type FormType = {
    promotionType: PromotionType;
    menuItemsQuantityToGet?: number;
    menuItemsQuantityToPay?: number;
    name: string;
    hours: string;
    description?: string;
    orderTypes: Array<OrderType>;
    startsAt: Date;
    endsAt: Date;
};
