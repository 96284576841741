import { makeStyles } from '@material-ui/core';
import * as React from 'react';
import { FormSwitch } from 'src/components/form/FormSwitch';
import { RolePermissions } from 'src/constants/RolePermission';
import { translate } from 'src/i18n/translate';

export function ReportsAccessConfiguration({ disabled }: Props): React.ReactElement {
    const classes = useStyles();

    return (
        <div className={classes.container}>
            <div className={classes.moduleContainer}>{translate('Reports')}</div>
            <div className={classes.permissionsContainer}>
                <FormSwitch name={RolePermissions.REPORTS_PAGE} label={translate('RolePermissions.REPORTS_PAGE')} disabled={disabled} />
            </div>
            <div className={classes.homeContainer}></div>
        </div>
    );
}

const useStyles = makeStyles((theme) => ({
    container: {
        width: '100%',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        padding: 10,
    },
    moduleContainer: {
        fontFamily: theme.typography.regular,
        fontSize: 15,
        width: '25%',
    },
    permissionsContainer: {
        fontFamily: theme.typography.regular,
        fontSize: 15,
        width: '50%',
        display: 'flex',
        flexDirection: 'column',
        paddingLeft: 10,
    },
    homeContainer: {
        fontFamily: theme.typography.regular,
        fontSize: 15,
        width: '25%',
    },
    radioButton: {
        color: `${theme.palette.primary.main} !important`,
    },
}));

type Props = {
    disabled?: boolean;
};
