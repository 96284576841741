import { makeStyles } from '@material-ui/core';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { useContext } from 'react';
import { useFieldArray, useFormContext } from 'react-hook-form';
import { Button } from 'src/components/Button';
import { FormError } from 'src/components/form/FormError';
import { FormTimePicker } from 'src/components/form/FormTimePicker';
import { Day } from 'src/constants/Day';
import { translate } from 'src/i18n/translate';
import { CloseIcon } from 'src/icons/CloseIcon';
import { OpeningHoursContext } from 'src/scenes/letseatmanager/openingHours/OpeningHoursForm';
import { transformHoursToWeekOpeningHours } from 'src/utils/hours/transformHoursToWeekOpeningHours';
import { classNames } from 'src/utils/react/classNames';
import { useSelector } from 'src/utils/react/useSelector';

export function OpeningHoursForAllDays(): React.ReactElement {
    const classes = useStyles();
    const context = useContext(OpeningHoursContext);
    const hours = context.hours;
    const { control, watch } = useFormContext();
    const { fields, append, remove } = useFieldArray({
        control,
        name: 'openingHours',
    });

    const [selectedDays, setSelectedDays] = useState<Array<Day>>([]);

    const hideOpeningHoursEditionEnabled = useSelector((state) => state.app.restaurant?.hideOpeningHoursEditionEnabled);
    const internalUser = useSelector((state) => state.authentication.internalUser);

    const editionDisabled = hideOpeningHoursEditionEnabled && !internalUser;

    const openingHours = watch('openingHours');

    useEffect(() => {
        setTimeout(() => resetForm(), 100);
    }, [hours]);

    useEffect(() => {
        updateRestaurantOpeningHours();
    }, [selectedDays, openingHours]);

    const resetForm = () => {
        const restaurantOpeningHours = transformHoursToWeekOpeningHours(hours);
        const openedDays = restaurantOpeningHours.filter((openingHours) => !!openingHours.openingHours.length);
        openedDays[0].openingHours.forEach((openingHours) => {
            append({ openingTime: openingHours?.openingTime ?? null, closingTime: openingHours?.closingTime ?? null });
        });

        const selectedDays = openedDays.map((openingHours) => openingHours.day);
        setSelectedDays(selectedDays);
    };

    const addOpeningHours = (openingHours: { closingTime: string; openingTime: string }) => append({ openingTime: openingHours?.openingTime ?? null, closingTime: openingHours?.closingTime ?? null });

    const updateRestaurantOpeningHours = () => {
        const currentOpeningHours = openingHours ?? [];
        for (const day of days) {
            if (!selectedDays.includes(day)) {
                context.setDayOpeningHours(day, []);
                continue;
            }
            context.setDayOpeningHours(
                day,
                currentOpeningHours.map((openingHour: OpeningHours) => ({ openingTime: openingHour.openingTime || null, closingTime: openingHour.closingTime || null })),
            );
        }
    };

    const selectDay = (day: Day) => {
        setSelectedDays((prevSelectedDays: Array<Day>) => {
            if (prevSelectedDays.includes(day)) {
                return prevSelectedDays.filter((selectedDay) => selectedDay !== day);
            }

            return [...prevSelectedDays, day];
        });
    };

    const selectedDaysValidation = (selectedDays: Array<string>) => {
        if (selectedDays.length > 0) return;
        return { message: translate('Selecting days is required') };
    };

    return (
        <div className={classes.container}>
            <span className={classes.text}>{translate('Select days')}</span>
            <div className={classes.buttonsContainer}>
                {days.map((day) => {
                    const isDaySelected = selectedDays.includes(day);
                    return (
                        <Button icon key={day} onClick={() => selectDay(day)} classes={{ button: classNames(classes.button, { [classes.selectedButton]: isDaySelected }) }} disabled={editionDisabled}>
                            {translate(`ShortenedDays.${day}`)}
                        </Button>
                    );
                })}
            </div>
            <FormError value={selectedDays} name={'daysError'} validate={selectedDaysValidation} />

            <div className={classes.inputsContainer}>
                {fields.map((field, idx) => (
                    <div className={classes.openingHours} key={field.id}>
                        <FormTimePicker name={`openingHours.${idx}.openingTime`} label={translate('Opening')} required defaultValue={field.openingTime} disabled={editionDisabled} />
                        <FormTimePicker name={`openingHours.${idx}.closingTime`} label={translate('Closing')} required defaultValue={field.closingTime} disabled={editionDisabled} />
                        {idx > 0 && (
                            <Button icon onClick={() => remove(idx)} classes={{ button: classes.iconButton }} disabled={editionDisabled}>
                                <CloseIcon />
                            </Button>
                        )}
                        {idx === 0 && <div className={classes.emptySpace}></div>}
                    </div>
                ))}
                <div className={classes.addScheduleButton}>
                    <Button outlined onClick={() => addOpeningHours({ openingTime: '10:00', closingTime: '22:00' })} disabled={editionDisabled}>
                        {translate('Add schedule')}
                    </Button>
                </div>
            </div>
        </div>
    );
}

const days: Array<Day> = ['Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa', 'Su'];

const useStyles = makeStyles((theme) => ({
    container: {
        marginTop: 20,
        marginBottom: 20,
    },
    text: {
        fontFamily: theme.typography.medium,
        fontSize: 16,
        marginBottom: 10,
    },
    buttonsContainer: {
        display: 'flex',
        flexDirection: 'row',
        gap: 10,
    },
    button: {
        backgroundColor: theme.palette.surface.secondary,
        color: theme.palette.text.primary,
    },
    selectedButton: {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.text.invert,
        '&:hover': {
            backgroundColor: `${theme.palette.surface.success} !important`,
        },
    },
    inputsContainer: {
        display: 'flex',
        flexDirection: 'column',
        gap: 10,
        width: '100%',
        marginTop: 20,
    },
    openingHours: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'flex-start',
        gap: 10,
        width: '100%',
    },
    addScheduleButton: {
        width: '100%',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-end',
        paddingRight: 36,
    },
    iconButton: {
        width: 26,
        height: 26,
        minHeight: 26,
        padding: 4,
        marginTop: 26,
        '& svg': {
            width: 22,
            height: 22,
        },
    },
    emptySpace: {
        width: 26,
        display: 'flex',
        flexShrink: 0,
    },
    hideContent: {
        display: 'none',
    },
}));

type OpeningHours = {
    openingTime: string;
    closingTime: string;
};
