import { IconButton, makeStyles } from '@material-ui/core';
import * as React from 'react';
import { useState } from 'react';
import { PasswordRequiredContent } from 'src/components/PasswordRequiredContent';
import { ClosedEyeIcon } from 'src/icons/ClosedEyeIcon';
import { OpenedEyeIcon } from 'src/icons/OpenedEyeIcon';

export function PasswordProtectedCell({ password, children }: Props): React.ReactElement {
    const classes = useStyles();

    const [showPassword, setShowPassword] = useState(false);

    if (showPassword) {
        return (
            <PasswordRequiredContent customPassword={password} onUnAuthorized={() => setShowPassword(false)}>
                <div className={classes.container}>
                    {children}
                    <IconButton onClick={() => setShowPassword(false)}>
                        <OpenedEyeIcon title={'opened eye'} />
                    </IconButton>
                </div>
            </PasswordRequiredContent>
        );
    }

    return (
        <div className={classes.container}>
            <span className={(classes as any).text}>****</span>
            <IconButton onClick={() => setShowPassword(true)}>
                <ClosedEyeIcon title={'closed eye'} />
            </IconButton>
        </div>
    );
}

const useStyles = makeStyles((theme) => ({
    container: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        gap: 10,
    },
}));

type Props = {
    password: string;
    children: React.ReactNode;
};
