import { letseatmanagerApiMethod } from 'src/api/letseatmanagerApi';
import type { ApiSauceResponse } from 'src/api/types/ApiSauceResponse';
import type { RestaurantId } from 'src/types/Id';

export async function closeDidiFoodStoreApi(request: CloseDidiFoodStoreApiRequest): ApiSauceResponse<any> {
    return letseatmanagerApiMethod('integration/closeDidiFoodStoreApi', request);
}

type CloseDidiFoodStoreApiRequest = {
    restaurantId: RestaurantId;
};
