import { makeStyles } from '@material-ui/core/styles';
import { useContext } from 'react';
import * as React from 'react';
import { SectionListContext } from 'src/components/SectionList';
import { classNames } from 'src/utils/react/classNames';

export function SectionListItem({ value, children }: Props): React.ReactElement {
    const classes = useStyles();

    const context = useContext(SectionListContext);
    const { onItemClick, selectedItem } = context;

    const isItemSelected = value === selectedItem;

    const onClick = (e: any) => {
        onItemClick?.(value);
    };

    return (
        <li className={classNames(classes.listItem, isItemSelected ? classes.listItemSelected : '')} onClick={onClick}>
            {children}
        </li>
    );
}

const useStyles = makeStyles((theme) => ({
    listItem: {
        borderBottom: `2px solid ${theme.palette.border.primary}`,
        transition: 'all 0.3s ease',
        padding: '16px 8px',
        '&:hover': {
            backgroundColor: theme.palette.surface.brand,
            boxShadow: '0px 0px',
            cursor: 'pointer',
            borderBottom: `2px solid ${theme.palette.border.brandContrast}`,
        },
    },
    listItemSelected: {
        backgroundColor: theme.palette.surface.brand,
        boxShadow: '0px 0px',
        cursor: 'pointer',
        borderBottom: `2px solid ${theme.palette.border.brandContrast}`,
        transition: 'all 0.3s ease',
    },
}));

type Props = {
    value: any;
    children: React.ReactNode;
};
