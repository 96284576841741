import { makeStyles } from '@material-ui/core';
import * as React from 'react';
import { memo, useState } from 'react';
import { app2 } from 'src/app2';
import { Button } from 'src/components/Button';
import { RolePermissions } from 'src/constants/RolePermission';
import { translate } from 'src/i18n/translate';
import { getPosInterfaceLargerEnabledRestaurantSettingInLocalStorage } from 'src/localStorage/getPosInterfaceLargerEnabledRestaurantSettingInLocalStorage';
import { PosApplyPromoCodeDialog } from 'src/scenes/letseatmanager/pos/posPayment/PosApplyPromoCodeDialog';
import { useNotification } from 'src/services/notification/useNotification';
import { PosCustomerOrderDetails } from 'src/types/PosCustomerOrderDetails';
import { PosPayment } from 'src/types/PosPayment';
import { useAction } from 'src/utils/react/useAction';
import { useSelector } from 'src/utils/react/useSelector';
import { useUserHasRolePermission } from 'src/utils/react/useUserHasRolePermissions';

export const PosAddPromoCodeButton: React.ComponentType<any> = memo<any>(() => {
    const classes = useStyles();

    const notification = useNotification();
    const [userHasRolePermission] = useUserHasRolePermission();

    const [openApplyPromoCodeDialogState, setOpenApplyPromoCodeDialogState] = useState({ open: false });

    const posInterfaceLargerEnabled = getPosInterfaceLargerEnabledRestaurantSettingInLocalStorage();

    const restaurant = useSelector((state) => state.app.restaurant);
    const promoCodeName = useSelector((state) => state.pos.payment?.promoCode);
    const payments = useSelector((state) => state.pos.payments);
    const paying = useSelector((state) => state.pos.paying);
    const customers = useSelector((state) => state.pos.customers);

    const openVerifyPosPasswordDialog = useAction(app2.actions.openVerifyPosPasswordDialog);
    const closeVerifyPosPasswordDialog = useAction(app2.actions.closeVerifyPosPasswordDialog);

    const showApplyCustomerCredits = restaurant?.restaurantPromoCodesEnabled;
    const hasPaymentByCustomer = payments?.some((payment: PosPayment) => payment.customerNumber);
    const someCustomerHasTip = customers?.some((customer: PosCustomerOrderDetails) => customer.tips);
    const someCustomerHasDiscount = customers?.some((customer: PosCustomerOrderDetails) => customer.payment?.posDiscount);
    const promoCodeAndCreditsDisabled = hasPaymentByCustomer || someCustomerHasDiscount || someCustomerHasTip;
    const disabled = paying || !!promoCodeName || promoCodeAndCreditsDisabled;

    const openAddPromotionDialog = () => setOpenApplyPromoCodeDialogState({ open: true });

    const onUnauthorized = () => {
        alert(translate('Unauthorized to access.'));
    };
    const handleApplyPromotion = () => {
        if (payments.some((payment) => !!payment.customerNumber)) return notification({ message: translate('PromoCode cannot be applied when paying by diner.') });

        if (!userHasRolePermission(RolePermissions.APPLY_PROMO_CODE_ORDERS)) {
            return openVerifyPosPasswordDialog({
                open: true,
                onAuthorized: () => {
                    closeVerifyPosPasswordDialog();
                    openAddPromotionDialog();
                },
                onUnauthorized,
            });
        }

        openAddPromotionDialog();
    };

    if (!showApplyCustomerCredits) return null;

    return (
        <>
            <PosApplyPromoCodeDialog open={openApplyPromoCodeDialogState.open} handleClose={() => setOpenApplyPromoCodeDialogState({ open: false })} />
            <Button secondary larger={posInterfaceLargerEnabled} classes={{ button: classes.basicButton }} onClick={handleApplyPromotion} disabled={disabled}>
                {translate('Promotions')}
            </Button>
        </>
    );
});
const useStyles = makeStyles((theme) => ({
    basicButton: {
        width: '100%',
        height: 50,
        color: theme.palette.text.secondary,
        '&:hover': {
            cursor: 'pointer',
        },
    },
}));
