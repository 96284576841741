import { makeStyles, useTheme } from '@material-ui/core';
import * as React from 'react';
import { useEffect } from 'react';
import { useContext } from 'react';
import { Box } from 'src/components/Box';
import { Button } from 'src/components/Button';
import { CreatePrinterSteps } from 'src/constants/CreatePrinterStep';
import { PrinterContents } from 'src/constants/PrinterContent';
import { translate } from 'src/i18n/translate';
import { CommandIcon } from 'src/icons/CommandIcon';
import { TicketAndCommandIcon } from 'src/icons/TicketAndCommandIcon';
import { TicketIcon } from 'src/icons/TicketIcon';
import { PrinterWizardContext, PrinterWizardProviderValues } from 'src/providers/PrinterWizardProvider';
import { classNames } from 'src/utils/react/classNames';

export function PrinterContentSelection(): React.ReactElement | null {
    const classes = useStyles();
    const theme = useTheme();
    const { formValues, goNextWizardStep, goBackWizardStep, setCreatePrinterWizardFormValue, activeCreatePrinterStep } = useContext<PrinterWizardProviderValues>(PrinterWizardContext);

    const isCommand = formValues.printerContent === PrinterContents.COMMAND;
    const isTicket = formValues.printerContent === PrinterContents.TICKET;
    const isBoth = !isCommand && !isTicket;

    useEffect(() => {
        document.addEventListener('keydown', handleKeyDown);
        return () => {
            document.removeEventListener('keydown', handleKeyDown);
        };
    }, []);

    const handleKeyDown = (event: KeyboardEvent) => {
        if (event.key === 'Enter') {
            goNextWizardStep();
        }
    };

    const handleChangePrinterContent = (printerContent?: undefined | string) => {
        setCreatePrinterWizardFormValue('printerContent', printerContent);
    };

    if (activeCreatePrinterStep !== CreatePrinterSteps.PRINTER_CONTENT) return null;

    return (
        <div className={classes.stepContent}>
            <p className={classes.label}>{translate('How are you going to connect your printer?')}</p>
            <div className={classes.printerContentContainer}>
                <Box className={classNames(classes.box, isTicket && classes.isSelected)} onClick={() => handleChangePrinterContent(PrinterContents.TICKET)}>
                    <TicketIcon color={theme.palette.primary.main} />
                    <p className={classes.boxText}>Ticket</p>
                </Box>
                <Box className={classNames(classes.box, isCommand && classes.isSelected)} onClick={() => handleChangePrinterContent(PrinterContents.COMMAND)}>
                    <CommandIcon color={theme.palette.primary.main} />
                    <p className={classes.boxText}>Comanda</p>
                </Box>
                <Box className={classNames(classes.box, isBoth && classes.isSelected)} onClick={() => handleChangePrinterContent()}>
                    <TicketAndCommandIcon color={theme.palette.primary.main} />
                    <p className={classes.boxText}>Ambos</p>
                </Box>
            </div>
            <div className={classes.actionsContainer}>
                <Button secondary onClick={goBackWizardStep} type={'button'}>
                    {translate('Back')}
                </Button>
                <Button primary onClick={goNextWizardStep}>
                    {translate('Next')}
                </Button>
                <p className={classes.enterText}>{translate('hit Enter ↩')}</p>
            </div>
        </div>
    );
}

const useStyles = makeStyles((theme) => ({
    box: {
        width: '100%',
        height: 120,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        '&:hover': {
            cursor: 'pointer',
        },
    },
    isSelected: {
        outline: `2px solid ${theme.palette.primary.main}`,
    },
    boxText: {
        marginBottom: 0,
    },
    label: {
        fontSize: 16,
        fontFamily: theme.typography.regular,
        color: '#0D3037',
        padding: '12px 0',
    },
    stepContent: {
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        animation: '$appear 700ms ease',
    },
    printerContentContainer: {
        display: 'flex',
        gap: 24,
        marginBottom: 24,
    },
    actionsContainer: {
        display: 'flex',
        gap: 12,
        marginTop: 24,
        width: '100%',
        justifyContent: 'flex-end',
    },
    enterText: {
        fontFamily: theme.typography.regular,
        color: '#0D3037',
    },
    '@keyframes appear': {
        '0%': {
            opacity: 0,
            transform: 'translateY(100px)',
        },
        '100%': {
            opacity: 1,
            transform: 'translateY(0px)',
        },
    },
}));
