import { pidedirectoReportsMethod } from 'src/api/pidedirectoReportsApi';
import type { ApiSauceResponse } from 'src/api/types/ApiSauceResponse';
import type { SalesType } from 'src/constants/SalesTypes';
import type { CashRegisterPosBusinessDayReportVm } from 'src/types/CashRegisterPosBusinessDayReportVm';
import type { CashRegisterPosBusinessDayId } from 'src/types/Id';

export async function getCashRegisterPosBusinessDayReportApi(request: GetCashRegisterPosBusinessDayReportApiRequest): ApiSauceResponse<CashRegisterPosBusinessDayReportVm> {
    return pidedirectoReportsMethod('/getCashRegisterPosBusinessDayReportApi', request);
}

export type GetCashRegisterPosBusinessDayReportApiRequest = {
    cashRegisterPosBusinessDayId: CashRegisterPosBusinessDayId;
    salesType: SalesType;
};
