import { makeStyles } from '@material-ui/core/styles';
import { Loader } from '@pidedirecto/ui';
import { useEffect, useState } from 'react';
import * as React from 'react';
import { closeCashRegisterApi } from 'src/api/letseatmanager/pos/closeCashRegisterApi';
import { CashRegisterExpectedAmountVm, getCashRegisterExpectedAmountsApi } from 'src/api/letseatmanager/pos/getCashRegisterExpectedAmountsApi';
import { getClosedCashRegisterReportApi } from 'src/api/letseatmanager/pos/getClosedCashRegisterReportApi';
import { translate } from 'src/i18n/translate';
import { posReducer } from 'src/reducers/posReducer';
import { CashRegisterExpectedAmountReport } from 'src/scenes/letseatmanager/posCashRegister/CashRegisterExpectedAmountReport';
import { useSetExpectedAmounts } from 'src/services/posBusinessDay/useSetExpectedAmounts';
import { usePrintShiftCashRegisterReport } from 'src/services/printer/usePrintShiftCashRegisterReport';
import { alertKnownErrorOrSomethingWentWrong } from 'src/utils/alert/alertKnownErrorOrSomethingWentWrong';
import { isCashPayment } from 'src/utils/paymentMethod/isCashPayment';
import { useAction } from 'src/utils/react/useAction';
import { useLoadApi } from 'src/utils/react/useLoadApi';
import { useSelector } from 'src/utils/react/useSelector';
import { emptyStringToUndefined } from 'src/utils/string/emptyStringToUndefined';

export function CashRegisterShiftReport(): React.ReactElement {
    const classes = useStyles();

    const [printShiftCashRegisterReport] = usePrintShiftCashRegisterReport();
    const setExpectedAmounts = useSetExpectedAmounts();

    const [loading, setLoading] = useState(false);

    const cashRegister = useSelector((state) => state.pos.cashRegister);
    const openedCashRegister = useSelector((state) => state.pos.openedCashRegister);
    const restaurantId = useSelector((state) => state.app.restaurantId);
    const posMultipleCashRegistersEnabled = useSelector((state) => state.app.restaurant?.posMultipleCashRegistersEnabled);
    const salesType = useSelector((state) => state.app.salesTypeFilter);
    const managerUserId = useSelector((state) => state.authentication.pinCodeUserSignedIn?.managerUserId);

    const closeCashRegisterReport = useAction(posReducer.actions.closeCashRegisterReport);
    const setOpenCashRegisterDialogState = useAction(posReducer.actions.setOpenCashRegisterDialogState);

    const [loadingExpectedAmounts, expectedAmounts] = useLoadApi(
        getCashRegisterExpectedAmountsApi,
        {
            cashRegisterId: cashRegister?.cashRegisterId,
        },
        {
            initialValue: [],
        },
    );

    useEffect(() => {
        if (expectedAmounts) {
            setExpectedAmounts(expectedAmounts);
        }
        return () => {
            setExpectedAmounts([]);
        };
    }, [expectedAmounts]);

    const handleSubmit = async (form: any) => {
        if (loading || loadingExpectedAmounts) return;

        const cashRegisterReport = [...(form.cashRegisterReport ?? []), ...(form.cashRegisterIntegrationsReport ?? [])];

        await closeCashRegister({ cashRegisterReport });
    };

    const closeCashRegister = async (form: any) => {
        setLoading(true);
        const cashRegisterReport = form.cashRegisterReport?.map((report: any) => ({
            paymentMethod: report.paymentMethod,
            customPaymentMethod: report.customPaymentMethod,
            amount: report.amount,
            app: report.app,
            expectedAmount: expectedAmounts?.find(
                (expectedAmount: CashRegisterExpectedAmountVm) =>
                    expectedAmount.paymentMethod === report.paymentMethod && emptyStringToUndefined(expectedAmount.customPaymentMethod) === emptyStringToUndefined(report.customPaymentMethod),
            )?.amount,
        }));

        const response = await closeCashRegisterApi({
            restaurantId,
            managerUserId,
            cashRegisterId: cashRegister?.cashRegisterId || openedCashRegister?.cashRegisterId,
            cashRegisterReport,
            salesType,
        });

        if (!response.ok) {
            setLoading(false);
            alertKnownErrorOrSomethingWentWrong(response);
            return;
        }

        setLoading(false);
        setOpenCashRegisterDialogState({ open: true, initialAmount: getCashReported(form.cashRegisterReport), onSuccess: openNewCashRegisterSucceeded, avoidSkip: true });
    };

    const getCashReported = (cashRegisterReport: any) => {
        return cashRegisterReport
            .reduce((savedAmount: number, report: any) => {
                if (!(isCashPayment(report.paymentMethod) && !report.customPaymentMethod)) return savedAmount;
                return savedAmount + parseFloat(report.amount);
            }, 0)
            .toString();
    };

    const openNewCashRegisterSucceeded = async () => {
        setOpenCashRegisterDialogState({ open: false });
        setLoading(true);
        const cashRegisterReportResponse = await getClosedCashRegisterReportApi({ cashRegisterId: cashRegister?.cashRegisterId });
        if (!cashRegisterReportResponse.ok) {
            alertKnownErrorOrSomethingWentWrong(cashRegisterReportResponse);
            closeCashRegisterReport();
            setLoading(false);
            return;
        }

        closeCashRegisterReport();
        setLoading(false);

        await printShiftCashRegisterReport(cashRegisterReportResponse.data, true);
    };

    const getTitleText = () => {
        if (posMultipleCashRegistersEnabled) {
            return translate('Shift Report @cashRegisterNumber', { cashRegisterNumber: cashRegister?.cashRegisterPosBusinessDayNumber });
        }

        return translate('Shift Report');
    };

    if (!expectedAmounts.length) {
        return (
            <div className={classes.content}>
                <Loader size={40} loading={true} />;
            </div>
        );
    }

    return <CashRegisterExpectedAmountReport title={getTitleText()} onSubmit={handleSubmit} loading={loading || loadingExpectedAmounts} />;
}

const useStyles = makeStyles((theme) => ({
    content: {
        display: 'flex',
        flexDirection: 'column',
        [theme.breakpoints.up('lg')]: {
            flexDirection: 'row',
        },
    },
}));
