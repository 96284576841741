import { Chip } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { changeMenuCategoryApi } from 'src/api/letseatmanager/menuCategory/changeMenuCategoryApi';
import { getMenuCategoryApi } from 'src/api/letseatmanager/menuCategory/getMenuCategoryApi';
import { pidedirectouploadApi } from 'src/api/pidedirectouploadApi';
import { Button } from 'src/components/Button';
import { Form } from 'src/components/form/Form';
import { FormColorButtons } from 'src/components/form/FormColorButtons';
import { FormImageUpload } from 'src/components/form/FormImageUpload';
import { FormMenuCategoryFamiliesSelect } from 'src/components/form/FormMenuCategoryFamiliesSelect';
import { FormNumberField } from 'src/components/form/FormNumberField';
import { FormPrinterNamesAutocomplete } from 'src/components/form/FormPrinterNamesAutocomplete';
import { FormSwitch } from 'src/components/form/FormSwitch';
import { FormTextField } from 'src/components/form/FormTextField';
import { ImageTypes } from 'src/constants/ImageType';
import { WebSocketEventTypes } from 'src/constants/WebSocketEventType';
import { translate } from 'src/i18n/translate';
import { SelectProductsDialog } from 'src/scenes/letseatmanager/menu/menuCategory/SelectProductsDialog';
import { useMenuItems } from 'src/services/menuItem/useMenuItems';
import { MenuItemId, ModifierGroupId, type MenuCategoryId } from 'src/types/Id';
import type { WebSocketEvent } from 'src/types/WebSocketEvent';
import { alertKnownErrorOrSomethingWentWrong } from 'src/utils/alert/alertKnownErrorOrSomethingWentWrong';
import { isExternalImageUrl } from 'src/utils/image/isExternalImageUrl';
import { useLoadApi } from 'src/utils/react/useLoadApi';
import { useSelector } from 'src/utils/react/useSelector';
import { WebSocketEvents } from 'src/utils/webSocket/WebSocketEvents';

export function ChangeMenuCategory({ menuCategoryId, onClose, onSuccess }: Props): React.ReactElement {
    const form = useForm();
    const classes = useStyles();

    const [loading, setLoading] = useState(false);
    const [selectedProductIds, setSelectedProductIds] = useState<Array<MenuItemId>>([]);
    const [selectedModifierGroupIds, setSelectedModifierGroupIds] = useState<Array<ModifierGroupId>>([]);
    const [selectProductsDialogState, setSelectProductsDialogState] = useState<{ open: boolean; defaultSelectedProducts: Array<MenuItemId> | undefined }>({ open: false, defaultSelectedProducts: [] });

    const [loadingMenuCategory, menuCategory, load] = useLoadApi(getMenuCategoryApi, { menuCategoryId: menuCategoryId! }, { requiredValues: [menuCategoryId], dependencies: [menuCategoryId] });

    const newDeviceManagementEnabled = useSelector((state) => state.app.restaurant?.newDeviceManagementEnabled);
    const menuCategoryFamiliesEnabled = useSelector((state) => state.app.restaurant?.menuCategoryFamiliesEnabled);
    const limitProductsInOrder = useSelector((state) => state.app.restaurant?.limitProductsInOrder);

    const { products } = useMenuItems();

    useEffect(() => {
        const menuCategoryChangedSyncWebSocketEvent = WebSocketEvents.addEventListener(WebSocketEventTypes.MENU_CATEGORY_CHANGED, syncMenuItemExternalChanges);
        const menuCategoryRemovedSyncWebSocketEvent = WebSocketEvents.addEventListener(WebSocketEventTypes.MENU_CATEGORY_REMOVED, syncMenuItemExternalChanges);

        return () => {
            menuCategoryChangedSyncWebSocketEvent.remove();
            menuCategoryRemovedSyncWebSocketEvent.remove();
        };
    }, []);

    useEffect(() => {
        if (menuCategory) {
            form.reset({
                name: menuCategory.name,
                imageUrl: menuCategory.imageUrl ?? null,
                menuCategoryFamilyId: menuCategory.menuCategoryFamilyId ?? null,
                color: menuCategory.color ?? null,
                printerNames: menuCategory.printerNames ?? [],
                promotion: menuCategory.promotion ?? false,
                hidden: menuCategory.hidden ?? false,
                hideInKitchenDisplayScreen: menuCategory.hideInKitchenDisplayScreen ?? false,
                orderItemsMaximumQuantity: menuCategory.orderItemsMaximumQuantity ?? '',
            });
            setSelectedProductIds(menuCategory.menuItemIds);
            setSelectedModifierGroupIds(menuCategory.modifierGroupIds ?? []);
        }
    }, [menuCategory]);

    const openSelectProductsDialog = () => {
        setSelectProductsDialogState({ open: true, defaultSelectedProducts: selectedProductIds });
    };

    const closeSelectProductsDialog = () => {
        setSelectProductsDialogState({ open: false, defaultSelectedProducts: [] });
    };

    const handleSelectProducts = (productIds: any) => setSelectedProductIds(productIds);

    const handleClose = () => {
        if (loading) return;
        onClose();
    };

    const getImageUrl = async (imageUrl: any) => {
        // TODO: capture image from window selection and upload it
        if (isExternalImageUrl(imageUrl)) {
            const migrateResponse = await pidedirectouploadApi.images.migrate({
                imageUrl: imageUrl,
                imageType: ImageTypes.MENU_CATEGORY,
            });
            if (!migrateResponse.ok) {
                setLoading(false);
                alertKnownErrorOrSomethingWentWrong(migrateResponse);
                return;
            }
            return migrateResponse.data;
        }
        return imageUrl;
    };

    const onSubmit = async (form: any) => {
        setLoading(true);
        if (!menuCategoryId || !menuCategory) return;
        const imageUrl = await getImageUrl(form.imageUrl);

        const response = await changeMenuCategoryApi({
            menuCategoryId: menuCategoryId,
            menuItemIds: selectedProductIds,
            modifierGroupIds: selectedModifierGroupIds,
            menuCategoryFamilyId: form.menuCategoryFamilyId,
            name: form.name,
            imageUrl: imageUrl,
            color: form.color,
            printerNames: form.printerNames,
            promotion: form.promotion,
            hidden: form.hidden,
            hideInKitchenDisplayScreen: form.hideInKitchenDisplayScreen,
            orderItemsMaximumQuantity: form.orderItemsMaximumQuantity?.toString(),
        });
        if (!response.ok) {
            setLoading(false);
            alertKnownErrorOrSomethingWentWrong(response);
            return;
        }
        setLoading(false);
        handleClose();
        onSuccess?.();
    };

    const handleDeleteProduct = (menuItemId: undefined | MenuItemId) => {
        const newProductIds = selectedProductIds.filter((productId) => productId !== menuItemId);
        setSelectedProductIds(newProductIds);
    };

    const syncMenuItemExternalChanges = (
        event: WebSocketEvent<{
            menuCategoryId: MenuCategoryId;
        }>,
    ) => {
        if (event.webSocketEventType === WebSocketEventTypes.MENU_CATEGORY_REMOVED && event.data?.menuCategoryId === menuCategoryId) onClose();

        load();
    };

    return (
        <>
            <SelectProductsDialog
                open={selectProductsDialogState.open}
                defaultSelectedProducts={selectProductsDialogState.defaultSelectedProducts ?? []}
                onClose={closeSelectProductsDialog}
                onSuccess={handleSelectProducts}
            />
            <Form onSubmit={onSubmit} form={form} className={classes.form}>
                <h2 className={classes.title}>{menuCategory?.name}</h2>
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <FormImageUpload name={'imageUrl'} label={translate('Image')} />
                    </Grid>

                    <Grid item xs={12}>
                        <FormColorButtons name='color' label={translate('Color')} disabled={loading} />
                    </Grid>
                    <Grid item xs={12}>
                        <FormTextField name='name' label={translate('Name')} required disabled={loading} />
                    </Grid>
                    {menuCategoryFamiliesEnabled && (
                        <Grid item xs={12}>
                            <FormMenuCategoryFamiliesSelect name='menuCategoryFamilyId' label={translate('Family')} disabled={loading} />
                        </Grid>
                    )}
                    <Grid item xs={12}>
                        <div className={classes.productsContainer}>
                            {selectedProductIds.map((selectedProductId) => {
                                const selectedProduct = products.find((menuItem) => menuItem.menuItemId === selectedProductId);
                                return <Chip key={selectedProductId} label={selectedProduct?.name} onDelete={() => handleDeleteProduct(selectedProduct?.menuItemId)} />;
                            })}
                        </div>

                        <Button onClick={openSelectProductsDialog} disabled={loading} outlined>
                            {translate('Select Products')}
                        </Button>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <FormSwitch name='promotion' label={translate('Promotion')} disabled={loading} />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <FormSwitch name='hidden' label={translate('Hidden')} disabled={loading} />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <FormSwitch name='hideInKitchenDisplayScreen' label={translate('Hide in kitchen (KDS)')} disabled={loading} />
                    </Grid>
                    {newDeviceManagementEnabled && (
                        <Grid item xs={12} md={12}>
                            <FormPrinterNamesAutocomplete name='printerNames' label={translate('Select a Printer')} />
                        </Grid>
                    )}
                    {limitProductsInOrder && (
                        <Grid item xs={12}>
                            <FormNumberField name='orderItemsMaximumQuantity' label={translate('Product limit per Category')} disabled={loading} />
                        </Grid>
                    )}
                </Grid>

                <div className={classes.buttonsContainer}>
                    <Button onClick={handleClose} disabled={loading} secondary>
                        {translate('Cancel')}
                    </Button>
                    <Button type='submit' disabled={loading || loadingMenuCategory}>
                        {loading ? translate('Saving') : translate('Save')}
                    </Button>
                </div>
            </Form>
        </>
    );
}

const useStyles = makeStyles((theme) => ({
    title: {
        textAlign: 'center',
        color: theme.palette.text.brand,
        fontFamily: theme.typography.bold,
    },
    productsContainer: {
        margin: '10px 0',
        display: 'flex',
        width: '100%',
        flexDirection: 'row',
        flexWrap: 'wrap',
    },
    form: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    buttonsContainer: {
        marginTop: 30,
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'flex-end',
        gap: 20,
        width: '100%',
        '& button': {
            width: 200,
        },
    },
    color: {
        width: '36px',
        height: '3.2rem',
        borderRadius: '4px',
    },
}));

type Props = {
    menuCategoryId?: MenuCategoryId;
    onClose: any;
    onSuccess?: any;
};
