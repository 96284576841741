import { all, takeLatest } from 'redux-saga/effects';
import { TheMenuTypes } from 'src/scenes/letseatmanager/deprecatedMenu/TheMenuReducer';
import * as TheMenuSaga from 'src/scenes/letseatmanager/deprecatedMenu/TheMenuSaga';

/* ------------- Connect Action Types To Sagas ------------- */
export default function* root(): any {
    yield all([
        takeLatest(TheMenuTypes.FETCH, TheMenuSaga.fetch),
        takeLatest(TheMenuTypes.MOVE_MENU_LEFT, TheMenuSaga.moveMenuLeft),
        takeLatest(TheMenuTypes.MOVE_MENU_RIGHT, TheMenuSaga.moveMenuRight),
        takeLatest(TheMenuTypes.MOVE_MENU_CATEGORY_UP, TheMenuSaga.moveMenuCategoryUp),
        takeLatest(TheMenuTypes.MOVE_MENU_CATEGORY_DOWN, TheMenuSaga.moveMenuCategoryDown),
        takeLatest(TheMenuTypes.MOVE_MENU_ITEM_UP, TheMenuSaga.moveMenuItemUp),
        takeLatest(TheMenuTypes.MOVE_MENU_ITEM_DOWN, TheMenuSaga.moveMenuItemDown),
        takeLatest(TheMenuTypes.HIDE_MENU_ITEM, TheMenuSaga.hideMenuItem),
        takeLatest(TheMenuTypes.UN_HIDE_MENU_ITEM, TheMenuSaga.unHideMenuItem),
    ]);
}
