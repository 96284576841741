import { makeStyles } from '@material-ui/core';
import * as React from 'react';
import { Dialog } from 'src/components/Dialog';
import { ChangeMenuItem } from 'src/scenes/letseatmanager/menu/menuItem/ChangeMenuItem';
import type { MenuItemId } from 'src/types/Id';

export function ChangeMenuItemDialog({ open, menuItemId, onClose, onSuccess }: Props): React.ReactElement {
    const classes = useStyles();

    return (
        <Dialog open={open} onClose={onClose} classes={{ dialog: classes.dialog }}>
            <ChangeMenuItem menuItemId={menuItemId} onClose={onClose} onSuccess={onSuccess} />
        </Dialog>
    );
}

const useStyles = makeStyles((theme) => ({
    dialog: {
        [theme.breakpoints.up('md')]: {
            width: '50vw',
        },
    },
}));

type Props = {
    open: boolean;
    menuItemId: MenuItemId | undefined;
    onClose: any;
    onSuccess?: any;
};
