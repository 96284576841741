import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import { useEffect, useState } from 'react';
import * as React from 'react';
import { useForm } from 'react-hook-form';
import { changeMenuProductInfoApi } from 'src/api/letseatmanager/menuItem/changeMenuProductInfoApi';
import { Button } from 'src/components/Button';
import { Dialog } from 'src/components/Dialog';
import { DialogActions } from 'src/components/DialogActions';
import { Form } from 'src/components/form/Form';
import { FormCurrencyNumberStringField } from 'src/components/form/FormCurrencyNumberStringField';
import { FormSwitch } from 'src/components/form/FormSwitch';
import { WebSocketEventTypes } from 'src/constants/WebSocketEventType';
import { translate } from 'src/i18n/translate';
import type { MenuId } from 'src/types/Id';
import type { ManagerMenuItemVm } from 'src/types/ManagerMenuItemVm';
import { alertKnownErrorOrSomethingWentWrong } from 'src/utils/alert/alertKnownErrorOrSomethingWentWrong';
import { WebSocketEvents } from 'src/utils/webSocket/WebSocketEvents';

export function ChangeMenuProductDialog({ open, onClose, menuItem, menuId, onSuccess }: Props): React.ReactElement {
    const classes = useStyles();
    const form = useForm();

    const [loading, setLoading] = useState(false);

    const menuProductInfo = menuItem?.menusProductInfo?.find((menu: { hidden?: boolean; menuId: MenuId; price: string; promoPrice?: string }) => menu.menuId === menuId);

    useEffect(() => {
        const menuItemChangedSyncWebSocketEvent = WebSocketEvents.addEventListener(WebSocketEventTypes.MENU_PRODUCT_INFO_CHANGED, onClose);
        return () => {
            menuItemChangedSyncWebSocketEvent.remove();
        };
    }, []);

    const handleClose = () => {
        if (loading) return;
        onClose();
    };

    const handleUpdatePriceItem = async (form: any) => {
        if (!menuId) return;
        setLoading(true);

        const response = await changeMenuProductInfoApi({
            menuItemId: menuItem.menuItemId,
            menuId,
            price: form.price,
            promoPrice: form?.promoPrice,
            hidden: form?.hidden,
        });
        setLoading(false);

        if (!response.ok) {
            alertKnownErrorOrSomethingWentWrong(response);
            return;
        }
        onSuccess();
        onClose();
    };

    return (
        <Dialog title={translate('Change prices of @menuItemName', { menuItemName: menuItem.name })} open={open} onClose={handleClose}>
            <Form onSubmit={handleUpdatePriceItem} form={form} className={classes.form}>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <FormCurrencyNumberStringField name='price' label={translate('Price')} required disabled={loading} defaultValue={menuProductInfo?.price ?? menuItem.price} />
                    </Grid>
                    <Grid item xs={12}>
                        <FormCurrencyNumberStringField name='promoPrice' label={translate('Promotion price')} disabled={loading} defaultValue={menuProductInfo?.promoPrice ?? menuItem.promoPrice} />
                        <p className={classes.helperText}>{translate('The prices of this product will only be changed in this menu')}</p>
                    </Grid>
                    <Grid item xs={6}>
                        <FormSwitch name='hidden' label={translate('Hidden')} disabled={loading} defaultValue={menuProductInfo?.hidden} />
                    </Grid>
                </Grid>
                <DialogActions className={classes.buttonsContainer}>
                    <Button secondary onClick={handleClose} disabled={loading} classes={{ button: classes.button }}>
                        {translate('Cancel')}
                    </Button>
                    <Button type='submit' disabled={loading} classes={{ button: classes.button }}>
                        {translate('Save Changes')}
                    </Button>
                </DialogActions>
            </Form>
        </Dialog>
    );
}

const useStyles = makeStyles((theme) => ({
    form: {
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
    },
    helperText: {
        color: '#616B79',
        fontSize: 12,
        textAlign: 'center',
    },
    buttonsContainer: {
        marginTop: 20,
    },
    button: {
        width: 200,
    },
}));

type Props = {
    open: boolean;
    onClose: any;
    menuItem: ManagerMenuItemVm;
    menuId?: MenuId;
    productPricesByMenu?: any;
    onSuccess: any;
};
