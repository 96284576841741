import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { BigNumber } from 'bignumber.js';
import orderBy from 'lodash/orderBy';
import moment from 'moment';
import * as React from 'react';
import { useEffect } from 'react';
import { app2 } from 'src/app2';
import DataTable from 'src/scenes/letseatadmin/statistics/DataTable';
import { CompanyCreditsChart } from 'src/scenes/letseatadmin/statistics/order/CompanyCreditsChart';
import { LetsEatCreditsChart } from 'src/scenes/letseatadmin/statistics/order/LetsEatCreditsChart';
import { PaidWithCardChart } from 'src/scenes/letseatadmin/statistics/order/PaidWithCardChart';
import { PaidWithPayrollChart } from 'src/scenes/letseatadmin/statistics/order/PaidWithPayrollChart';
import { PaymentsChart } from 'src/scenes/letseatadmin/statistics/order/PaymentsChart';
import { PromoCodesChart } from 'src/scenes/letseatadmin/statistics/order/PromoCodesChart';
import { RefundsChart } from 'src/scenes/letseatadmin/statistics/order/RefundsChart';
import { PeriodScale, PeriodScales, XAxisScale, YAxisScale } from 'src/scenes/letseatadmin/statistics/TimeLineBarChart';
import type { StatisticsVm } from 'src/scenes/letseatadmin/StatisticsPage';
import { useAction } from 'src/utils/react/useAction';

type Props = {
    statistics: StatisticsVm;
    period: PeriodScale;
    yAxis: YAxisScale;
    xAxis: XAxisScale;
};

export function PaymentStatsPage({ statistics, period, yAxis, xAxis }: Props): React.ReactElement {
    const setTitle = useAction(app2.actions.setTitle);

    useEffect(() => {
        setTitle('Statistics > Payments');
    }, []);

    return (
        <div style={{ padding: 24, overflow: 'visible' }}>
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <Typography display='block' variant='subtitle1' gutterBottom>
                        Payments
                    </Typography>
                </Grid>
                {/*<Grid item xs={12} lg={6}>*/}
                {/*    <InAppInStoreChart statistics={statistics} period={period} yAxis={yAxis} xAxis={xAxis} />*/}
                {/*</Grid>*/}
                {/*<Grid item xs={12} lg={6} />*/}
                <Grid item xs={12} lg={6}>
                    <PaymentsChart statistics={statistics} period={period} yAxis={yAxis} xAxis={xAxis} />
                </Grid>
                <Grid item xs={12} lg={6} />

                <Grid item xs={12}>
                    <Typography display='block' variant='subtitle1' gutterBottom>
                        Paid with Card
                    </Typography>
                </Grid>
                <Grid item xs={12} lg={6}>
                    <PaidWithCardChart statistics={statistics} period={period} yAxis={yAxis} xAxis={xAxis} />
                </Grid>
                <Grid item xs={12} lg={6} />

                <Grid item xs={12}>
                    <Typography display='block' variant='subtitle1' gutterBottom>
                        Paid with Payroll
                    </Typography>
                </Grid>
                <Grid item xs={12} lg={6}>
                    <PaidWithPayrollChart statistics={statistics} period={period} yAxis={yAxis} xAxis={xAxis} />
                </Grid>
                <Grid item xs={12} lg={6} />

                <Grid item xs={12}>
                    <Typography display='block' variant='subtitle1' gutterBottom>
                        Company Credits
                    </Typography>
                </Grid>
                <Grid item xs={12} lg={6}>
                    <CompanyCreditsChart statistics={statistics} period={period} yAxis={yAxis} xAxis={xAxis} />
                </Grid>
                <Grid item xs={12} lg={6} />

                <Grid item xs={12}>
                    <Typography display='block' variant='subtitle1' gutterBottom>
                        Credits
                    </Typography>
                </Grid>
                <Grid item xs={12} lg={6}>
                    <LetsEatCreditsChart statistics={statistics} period={period} yAxis={yAxis} xAxis={xAxis} />
                </Grid>
                <Grid item xs={12} lg={6} />

                <Grid item xs={12}>
                    <Typography display='block' variant='subtitle1' gutterBottom>
                        Promo Codes
                    </Typography>
                </Grid>
                <Grid item xs={12} lg={6}>
                    <PromoCodesChart statistics={statistics} period={period} yAxis={yAxis} xAxis={xAxis} />
                </Grid>
                <Grid item xs={12} lg={6}>
                    <DataTable data={getPromoCodesData(statistics, period)} title={getPromoCodesTableTitle(period)} headerName={'Promo Code'} headerValue={'Amount'} />
                </Grid>

                <Grid item xs={12}>
                    <Typography display='block' variant='subtitle1' gutterBottom>
                        Refunds
                    </Typography>
                </Grid>
                <Grid item xs={12} lg={6}>
                    <RefundsChart statistics={statistics} period={period} yAxis={yAxis} xAxis={xAxis} />
                </Grid>
                <Grid item xs={12} lg={6} />
            </Grid>
        </div>
    );
}

function getPromoCodesData(statistics: StatisticsVm, period: PeriodScale) {
    const statisticsForPeriod = statistics.filter((statistic) => {
        if (period === PeriodScales.TWO_WEEKS) {
            const fourteenDaysAgo = moment().startOf('day').subtract(14, 'days');
            if (moment(statistic.date).isBefore(fourteenDaysAgo)) {
                return false;
            }
        } else if (period === PeriodScales.THREE_MONTHS) {
            const threeMonthsAgo = moment().startOf('day').subtract(14, 'weeks');
            if (moment(statistic.date).isBefore(threeMonthsAgo)) {
                return false;
            }
        } else if (period === PeriodScales.A_YEAR) {
            const oneYearAgo = moment().startOf('day').subtract(14, 'months');
            if (moment(statistic.date).isBefore(oneYearAgo)) {
                return false;
            }
        }
        return true;
    });

    const dataAsObject = statisticsForPeriod.reduce<Record<string, any>>((dataAsObject, statics) => {
        const promoCodes = statics.order.new.promoCodeDiscount.promoCodes;
        Object.keys(promoCodes).forEach((key) => {
            const promoCodeId = key as any;
            const promoCode = promoCodes[promoCodeId];
            if (!dataAsObject[promoCodeId]) {
                dataAsObject[promoCodeId] = {
                    name: promoCode?.code,
                    value: promoCode?.value,
                };
            } else {
                dataAsObject[promoCodeId].value = BigNumber(dataAsObject[promoCodeId].value)
                    .plus(promoCode?.value ?? 0)
                    .toNumber();
            }
        });
        return dataAsObject;
    }, {});

    return orderBy(
        Object.keys(dataAsObject).map((key) => ({
            id: key,
            name: dataAsObject[key].name,
            value: dataAsObject[key].value,
        })),
        ['value'],
        ['desc'],
    );
}

function getPromoCodesTableTitle(period: PeriodScale) {
    if (period === PeriodScales.A_YEAR) {
        return 'A Year';
    }
    if (period === PeriodScales.THREE_MONTHS) {
        return 'Three Months';
    }
    if (period === PeriodScales.TWO_WEEKS) {
        return 'Two Weeks';
    }
}
