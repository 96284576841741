import { makeStyles } from '@material-ui/core/styles';
import { UserIcon } from '@pidedirecto/ui/icons';
import { useState } from 'react';
import * as React from 'react';
import { useHistory } from 'react-router-dom';
import { clockInRestaurantEmployeeApi } from 'src/api/letseatmanager/employeeDayShift/clockInRestaurantEmployeeApi';
import { app2 } from 'src/app2';
import { Button } from 'src/components/Button';
import { RoutePaths } from 'src/constants/RoutePath';
import { translate } from 'src/i18n/translate';
import { authReducer } from 'src/reducers/authReducer';
import type { PinCodeUser } from 'src/types/PinCodeUser';
import { alertKnownErrorOrSomethingWentWrong } from 'src/utils/alert/alertKnownErrorOrSomethingWentWrong';
import { classNames } from 'src/utils/react/classNames';
import { useAction } from 'src/utils/react/useAction';
import { useClock } from 'src/utils/react/useClock';
import { useSelector } from 'src/utils/react/useSelector';

export function PosUserClockInDetails({ onAccept, pinCodeUser }: Props): React.ReactElement {
    const classes = useStyles();

    const history = useHistory();

    const [time, date] = useClock();

    const [loading, setLoading] = useState(false);

    const restaurant = useSelector((state) => state.app.restaurant);
    const clockInMessage = useSelector((state) => state.app.restaurant?.clockInMessage);
    const restaurantId = useSelector((state) => state.app.restaurantId);
    const currentEmployeesClockedIn = useSelector((state) => state.app2.currentEmployeesClockedIn);

    const pinCodeUserSignIn = useAction(authReducer.actions.pinCodeUserSignIn);
    const setCurrentEmployeesClockedIn = useAction(app2.actions.setCurrentEmployeesClockedIn);

    const handleClockIn = async () => {
        if (!pinCodeUser) return;

        setLoading(true);
        const response = await clockInRestaurantEmployeeApi({
            restaurantId,
            managerUserId: pinCodeUser.managerUserId,
            clockInBy: pinCodeUser.managerUserId,
        });

        setLoading(false);
        if (!response.ok) {
            alertKnownErrorOrSomethingWentWrong(response);
            return;
        }

        pinCodeUserSignIn(pinCodeUser);
        setCurrentEmployeesClockedIn([...(currentEmployeesClockedIn ?? []), { managerUserId: response.data.managerUserId, clockedInAt: response.data.clockedInAt }]);

        if (!pinCodeUser?.initialPage) {
            onAccept();
            return;
        }

        history.replace({
            pathname: RoutePaths[pinCodeUser?.initialPage],
            search: history.location.search,
        });
        onAccept();
    };

    return (
        <section className={classes.container}>
            <UserIcon title={'user-details-icon'} />
            <p className={classes.secondaryText}>{translate('Welcome')}</p>
            <div className={classes.primaryText}>{pinCodeUser?.name}</div>
            <div className={classes.dividerContainer}>
                <div className={classNames(classes.secondaryInfo, classes.primaryText)}>
                    <span>{time}</span>
                    <hr className={classes.verticalSeparator} />
                    <span>{date}</span>
                </div>
                <div className={classNames(classes.secondaryInfo, classes.secondaryText)}>
                    <span>{restaurant?.name}</span>
                    <hr className={classes.verticalSeparator} />
                    <span>{pinCodeUser?.roleName}</span>
                </div>
            </div>
            <article className={classes.clockInMessage}>
                <div>{translate('Remember to clock in and out every day')}</div>
            </article>
            {!!clockInMessage && (
                <article className={classes.clockInMessage}>
                    <div>{clockInMessage}</div>
                </article>
            )}
            <Button disabled={loading} onClick={handleClockIn} classes={{ button: classes.button, disabled: classes.disabled }}>
                {translate('Accept')}
            </Button>
        </section>
    );
}

const useStyles = makeStyles((theme) => ({
    container: {
        gridArea: 'numericPad',
        margin: 'auto',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        justifySelf: 'start',
        color: theme.palette.text.invert,
        borderRadius: 8,
        backgroundColor: `${theme.palette.surface.brandContrast}15`,
        border: `2px solid ${theme.palette.border.brandContrast}`,
        padding: '20px 18px',
        fontFamily: theme.typography.regular,
        [theme.breakpoints.up('sm')]: {
            width: 'auto',
            padding: '42px 60px',
            margin: '0 10px',
        },
    },
    secondaryInfo: {
        display: 'flex',
        width: '100%',
        justifyContent: 'center',
    },
    primaryText: {
        fontFamily: theme.typography.semiBold,
        fontSize: 16,
        [theme.breakpoints.up('sm')]: {
            fontSize: 20,
        },
    },
    secondaryText: {
        fontSize: 12,
        [theme.breakpoints.up('sm')]: {
            fontSize: 16,
        },
    },
    disabled: {
        backgroundColor: 'transparent',
        color: theme.palette.text.invert,
        '&:hover': {
            backgroundColor: 'transparent',
        },
    },
    verticalSeparator: {
        border: `0.1px solid ${theme.palette.text.invert}`,
        borderLeft: `1px solid ${theme.palette.text.invert}`,
        height: 20,
        margin: '0 10px',
    },
    infoContainer: {
        width: '100%',
        margin: '15px 0',
        padding: '10px 0',
        borderTop: `2px solid  ${theme.palette.surface.brandContrast}`,
        borderBottom: `2px solid  ${theme.palette.surface.brandContrast}`,
    },
    button: {
        width: '100%',
    },
    dividerContainer: {
        width: '100%',
        borderTop: `2px solid  ${theme.palette.surface.brandContrast}`,
        borderBottom: `2px solid  ${theme.palette.surface.brandContrast}`,
        margin: '20px 0',
        padding: '20px 0',
    },
    clockInMessage: {
        width: '100%',
        minHeight: 60,
        color: theme.palette.text.invert,
        borderRadius: 8,
        backgroundColor: `${theme.palette.surface.brandContrast}26`,
        border: `2px solid  ${theme.palette.surface.brandContrast}`,
        padding: '10px 16px',
        textAlign: 'center',
        fontSize: 16,
        fontFamily: theme.typography.regular,
        marginBottom: 15,
    },
}));

type Props = {
    onAccept: any;
    pinCodeUser?: PinCodeUser;
};
