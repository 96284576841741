import { makeStyles } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { Loader } from '@pidedirecto/ui';
import * as React from 'react';
import { useFormContext } from 'react-hook-form';
import { findRestaurantMenuItemsApi } from 'src/api/letseatmanager/menuItem/findRestaurantMenuItemsApi';
import { Button } from 'src/components/Button';
import { FormCustomizableMultiselectableAutocomplete } from 'src/components/form/FormCustomizableMultiselectableAutocomplete';
import type { Variant } from 'src/components/form/FormTypes';
import { Text } from 'src/components/Text';
import { translate } from 'src/i18n/translate';
import type { RestaurantId } from 'src/types/Id';
import { useUnzipLoadApi } from 'src/utils/react/useUnzipLoadApi';

export function FormRestaurantMenuItemsAutocomplete({ name, label, placeholder, helperText, defaultValue, disabled, required, variant, restaurantIds }: Props): React.ReactElement {
    const { setValue, watch } = useFormContext();

    const menuItemIds = watch(`${name}`);

    const classes = useStyles();

    const [loadingFindMenuItems, menuItems] = useUnzipLoadApi(findRestaurantMenuItemsApi, { restaurantIds }, { requiredValues: [restaurantIds], dependencies: [restaurantIds] });

    const restaurantMenuItemsOptions = menuItems?.flatMap((restaurantMenuItem: any) => {
        const { restaurantName, menuItems } = restaurantMenuItem;
        const itemsUpdated = menuItems?.map((menuItem: any) => ({
            restaurantName,
            menuItemName: menuItem.name,
            menuItemId: menuItem.menuItemId,
        }));
        return itemsUpdated;
    });

    const removeMenuItemsByRestaurantIds = (menuItem: any) => {
        const menuItemsUpdated = menuItemIds?.filter((itemId: any) => itemId !== menuItem);
        setValue('menuItemIds', menuItemsUpdated);
    };

    if (loadingFindMenuItems) return <Loader size={18} loading={true} />;

    return (
        <>
            <FormCustomizableMultiselectableAutocomplete
                name={name}
                label={label}
                placeholder={placeholder}
                helperText={helperText}
                defaultValue={defaultValue ?? []}
                data={restaurantMenuItemsOptions}
                getOptionLabel={(option) => option.menuItemName}
                getOptionValue={(option) => option.menuItemId}
                renderOption={(option) => (
                    <div className={classes.containerOption}>
                        <Text>{option.menuItemName}</Text>
                        <Text className={classes.helperText}>({option?.restaurantName})</Text>
                    </div>
                )}
                classes={{ optionContainer: classes.optionContainer }}
                disabled={disabled && loadingFindMenuItems}
                required={required}
                variant={variant}
                selectAllOption
            />
            {menuItemIds && menuItemIds.length > 0 && (
                <div className={classes.chipsContainer}>
                    {menuItemIds?.map((menuItemId: any, idx: any) => {
                        const itemSelectedInfo = restaurantMenuItemsOptions?.find((restaurantMenuItemOption: any) => restaurantMenuItemOption.menuItemId === menuItemId);
                        return (
                            <div className={classes.chipContainer} key={idx}>
                                <div className={classes.chip}>
                                    <span>{itemSelectedInfo?.menuItemName}</span>
                                    <Text size={'small'}>{itemSelectedInfo?.restaurantName}</Text>
                                </div>
                                <Button icon onClick={() => removeMenuItemsByRestaurantIds(itemSelectedInfo.menuItemId)} classes={{ button: classes.iconButton }}>
                                    <CloseIcon titleAccess={translate('Remove')} />
                                </Button>
                            </div>
                        );
                    })}
                </div>
            )}
        </>
    );
}

const useStyles = makeStyles((theme) => ({
    containerOption: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        padding: '8px 4px',
    },
    containerOptionDisabled: {
        backgroundColor: '#F5F5F5',
        color: '#9BA1AA',
    },
    bannedText: {
        color: '#E32F45',
    },
    optionContainer: {
        backgroundColor: 'transparent !important',
        '&:active': {
            backgroundColor: `${theme.palette.primary.light} !important`,
        },
        '&:hover': {
            backgroundColor: `${theme.palette.primary.light} !important`,
        },
    },
    helperText: {
        color: '#87888B',
        marginLeft: 4,
    },
    chipsContainer: {
        marginTop: 12,
        width: '100%',
        display: 'flex',
        flexWrap: 'wrap',
        gap: 12,
        height: 100,
        border: '1px solid #D9D9D9',
        borderRadius: 8,
        padding: 10,
        overflowY: 'scroll',
        '&::-webkit-scrollbar': {
            display: 'none',
        },
    },
    chipRemoveIcon: {
        backgroundColor: 'transparent',
        color: 'black',
        border: 'none',
        padding: '0px 0px',
        marginLeft: 8,
        '&:hover': {
            backgroundColor: 'transparent',
        },
        '&:focus': {
            outline: 'none',
        },
    },
    chipContainer: {
        fontFamily: theme.typography.medium,
        fontSize: 15,
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        border: '1px solid #676060B2',
        borderRadius: 6,
        gap: 5,
        backgroundColor: '#D9D9D933',
    },
    chip: {
        display: 'flex',
        flexDirection: 'column',
        marginLeft: 6,
    },
    iconButton: {
        width: 28,
        height: 28,
        borderRadius: 28,
        minHeight: 28,
    },
}));

type Props = {
    name: string;
    label?: string;
    placeholder?: string;
    helperText?: string;
    defaultValue?: Array<string>;
    disabled?: boolean;
    required?:
        | boolean
        | {
              value: number;
              message: string;
          };
    variant?: Variant;
    restaurantIds: Array<RestaurantId>;
};
