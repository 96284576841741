import { useEffect, useRef } from 'react';
import { BuildTypes } from 'src/constants/BuildType';
import { useSelector } from 'src/utils/react/useSelector';
import { isMobileApp } from 'src/utils/reactNative/isMobileApp';

export function useErudaHtmlConsole(): void {
    const erudaConsoleLoadedRef = useRef(false);

    const developerMode = useSelector((state) => state.app.developerMode);
    const appBuildType = useSelector((state) => state.app.appBuildType);

    useEffect(() => {
        if (!isMobileApp()) return;
        if (appBuildType !== BuildTypes.RELEASE) return;

        if (developerMode) {
            enableErudaHtmlConsole();
        } else {
            disableErudaHtmlConsole();
        }
    }, [developerMode, appBuildType]);

    const enableErudaHtmlConsole = () => {
        if (!erudaConsoleLoadedRef.current) {
            loadErudaFunctionLib();
            return;
        }

        window.eruda.init();
        return;
    };

    function loadErudaFunctionLib() {
        const script = document.createElement('script');
        script.src = 'https://cdn.jsdelivr.net/npm/eruda';
        document.body?.append(script);
        script.onload = function () {
            window.eruda.init();
        };
        erudaConsoleLoadedRef.current = true;
    }

    const disableErudaHtmlConsole = () => {
        if (!erudaConsoleLoadedRef.current) {
            return;
        }
        window.eruda.destroy();
    };
}
