import { letseatmanagerApiMethod } from 'src/api/letseatmanagerApi';
import type { ApiSauceResponse } from 'src/api/types/ApiSauceResponse';
import type { BusinessId, RestaurantId } from 'src/types/Id';

export async function getBusinessContextApi(): ApiSauceResponse<BusinessContextVm> {
    return letseatmanagerApiMethod('getBusinessContextApi');
}

export type BusinessContextVm = {
    businessId: BusinessId;
    restaurantIds: Array<RestaurantId>;
    restaurantPromoCodesEnabled: boolean;
    paymentLinksEnabled: boolean;
    marketingEnabled: boolean;
};
