import { letseatmanagerApiMethod } from 'src/api/letseatmanagerApi';
import type { ApiSauceResponse } from 'src/api/types/ApiSauceResponse';
import type { MenuCategoryId } from 'src/types/Id';

export async function deprecatedChangeMenuCategoryApi(request: ChangeMenuCategoryApiRequest): ApiSauceResponse<void> {
    return letseatmanagerApiMethod('menu/changeMenuCategoryApi', request);
}

type ChangeMenuCategoryApiRequest = {
    menuCategoryId: MenuCategoryId;
    name: string;
    hidden: boolean;
    promotion?: boolean;
    imageUrl?: string;
};
