import { Collapse, List, makeStyles, useTheme } from '@material-ui/core';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import { useState } from 'react';
import * as React from 'react';
import { AppMenuLink } from 'src/components/app/appMenu/AppMenuLink';
import { SecuredContent } from 'src/components/SecuredContent';
import { RolePermissions } from 'src/constants/RolePermission';
import { RoutePaths } from 'src/constants/RoutePath';
import { translate } from 'src/i18n/translate';
import { InvoiceIcon } from 'src/icons/InvoiceIcon';

export function RestaurantInvoicesAppMenu(): React.ReactElement {
    const classes = useStyles();
    const theme = useTheme();

    const [openInvoiceAppMenuOptions, setOpenInvoiceAppMenuOptions] = useState(false);

    return (
        <SecuredContent rolePermission={RolePermissions.ORDER_INVOICES_PAGE}>
            <ListItem button onClick={() => setOpenInvoiceAppMenuOptions(!openInvoiceAppMenuOptions)} className={classes.listItem}>
                <ListItemIcon>
                    <InvoiceIcon title={'invoice icon'} color={theme.palette.icons.brand} />
                </ListItemIcon>
                <ListItemText classes={{ root: classes.listItemText }} primary={translate('Invoices')} />
                <ListItemIcon>{openInvoiceAppMenuOptions ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}</ListItemIcon>
            </ListItem>
            <Collapse in={openInvoiceAppMenuOptions} data-testid='restaurantAppMenu.invoicesLink'>
                <List>
                    <AppMenuLink className={classes.menuLinkOption} routePath={RoutePaths.ORDER_INVOICES} text={translate('Order Invoices')} data-testid='restaurantAppMenu.orderInvoicesLink' />
                    <AppMenuLink
                        className={classes.menuLinkOption}
                        routePath={RoutePaths.GLOBAL_ORDER_INVOICES}
                        text={translate('Internal Invoice')}
                        data-testid='restaurantAppMenu.globalOrderInvoicesLink'
                    />
                </List>
            </Collapse>
        </SecuredContent>
    );
}

const useStyles = makeStyles((theme) => ({
    listItem: {
        borderRadius: 6,
        '&:hover': {
            backgroundColor: theme.palette.surface.tertiary,
        },
    },
    listItemText: {
        '& span': {
            fontFamily: theme.typography.regular,
        },
    },
    menuLinkOption: {
        paddingLeft: 30,
    },
    icon: {
        color: theme.palette.primary.main,
        width: 30,
        height: 30,
    },
}));
