import Grid from '@material-ui/core/Grid';
import { useState } from 'react';
import * as React from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import { FormSwitch } from 'src/components/form/FormSwitch';
import { FormTextField } from 'src/components/form/FormTextField';
import { FormUrlField } from 'src/components/form/FormUrlField';
import TicketImageUploader from 'src/components/input/TicketImageUploader';
import { SectionTitle } from 'src/components/title/SectionTitle';
import { ImageTypes } from 'src/constants/ImageType';
import { TicketSections } from 'src/constants/TicketSection';
import { translate } from 'src/i18n/translate';

export function TicketHeaderSettings({ disabled }: Props): React.ReactElement {
    const [uploadingImage, setUploadingImage] = useState(false);

    const { control, setValue } = useFormContext();

    const printLogo = useWatch({ name: TicketSections.LOGO, control });
    const customHeaderText = useWatch({ name: TicketSections.CUSTOM_HEADER_TEXT, control });

    return (
        <>
            <SectionTitle title={translate('Header')} />
            {/* @ts-ignore */}
            <Grid item xs={12} columnSpacing={2}>
                <FormSwitch disabled={disabled} name={TicketSections.CUSTOM_HEADER_TEXT} label={translate(`TicketSections.${TicketSections.CUSTOM_HEADER_TEXT}`)} />
            </Grid>
            {customHeaderText && (
                <Grid item xs={12}>
                    <FormTextField disabled={disabled} name={'ticketCustomHeader'} label={translate('Custom text')} placeholder={translate('Custom text')} required={!!customHeaderText} />
                </Grid>
            )}
            {/* @ts-ignore */}
            <Grid item xs={12} columnSpacing={2}>
                <FormSwitch disabled={disabled} name={TicketSections.LOGO} label={translate(`TicketSections.${TicketSections.LOGO}`)} />
            </Grid>
            {printLogo && (
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <FormUrlField
                            disabled={disabled}
                            required={!!printLogo}
                            name='ticketLogoImage'
                            label={uploadingImage ? translate('Uploading Image...') : translate('Logo Image to Print in Ticket')}
                            helperText={translate('Make sure the image is 296x296 pixels or at least a similar aspect ratio that is a multiple of 8 and no larger than 300, of type png or jpg')}
                        />
                        <TicketImageUploader
                            imageType={ImageTypes.RESTAURANT}
                            targetName='ticketLogoImage'
                            onUploadingChanged={(uploading) => setUploadingImage(uploading)}
                            onUploadSuccess={(ticketLogoImage) => setValue('ticketLogoImage', ticketLogoImage, { shouldDirty: true })}
                            dimensions={{ maxWidth: 296, maxHeight: 296, minHeight: 80, minWidth: 80 }}
                        />
                    </Grid>
                </Grid>
            )}
            {/* @ts-ignore */}
            <Grid item xs={12} columnSpacing={2}>
                <FormSwitch disabled={disabled} name={TicketSections.HEADER_ORDER_TYPE} label={translate(`TicketSections.${TicketSections.HEADER_ORDER_TYPE}`)} />
            </Grid>
        </>
    );
}

type Props = {
    disabled: boolean;
};
