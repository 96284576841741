import { FormSelect } from '@pidedirecto/ui/form';
import * as React from 'react';
import { findRestaurantUsersApi } from 'src/api/letseatmanager/userManagement/findRestaurantUsersApi';
import { useLoadApi } from 'src/utils/react/useLoadApi';
import { useSelector } from 'src/utils/react/useSelector';

export function FormRestaurantManagerUserSelect({ name, label, placeholder, helperText, defaultValue, disabled, required }: Props): React.ReactElement {
    const restaurantIds = useSelector((state) => state.app.restaurantIds);

    const [loading, restaurantManagerUsers] = useLoadApi(findRestaurantUsersApi, { restaurantIds }, { initialValue: [], dependencies: [restaurantIds] });

    const options = restaurantManagerUsers?.map((user) => ({
        value: user.managerUserId,
        label: user.name,
    }));

    return <FormSelect name={name} label={label} placeholder={placeholder} helperText={helperText} defaultValue={defaultValue} options={options} disabled={disabled || loading} required={required} />;
}

type Props = {
    name: string;
    label?: string;
    placeholder?: string;
    helperText?: string;
    defaultValue?: string | null | undefined;
    disabled?: boolean;
    required?: boolean;
};
