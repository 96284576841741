import type { PageContextType } from 'src/components/Page';
import { LogEventTypes } from 'src/constants/LogEventType';
import { createUiInteractionLogEvent } from 'src/services/logEvent/createUiInteractionLogEvent';
import { normalizeUiStackTrace } from 'src/services/logEvent/normalizeUiStackTrace';

export function createUserOpenedContextualMenuLogEvent({ pageContext, title }: Params): void {
    createUiInteractionLogEvent({
        logEventType: LogEventTypes.USER_OPENED_CONTEXTUAL_MENU,
        message: `contextual menu clicked`,
        details: `${pageContext.stackTrace}/${normalizeUiStackTrace(`contextual_menu_${title ?? ''}`)}`,
        data: {
            contextualMenuId: normalizeUiStackTrace(`contextual_menu_${title ?? ''}`),
            pathId: `${pageContext.stackTrace}/${normalizeUiStackTrace(`contextual_menu_${title ?? ''}`)}`,
            stacktrace: pageContext.stackTrace,
        },
    });
}

type Params = {
    title?: string;
    pageContext: PageContextType;
};
