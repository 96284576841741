import { letseatmanagerApiMethod } from 'src/api/letseatmanagerApi';
import type { ApiSauceResponse } from 'src/api/types/ApiSauceResponse';
import type { SupplyId } from 'src/types/Id';

export async function removeSupplyApi(request: RemoveSupplyApiRequest): ApiSauceResponse<void> {
    return letseatmanagerApiMethod('supply/removeSupplyApi', request);
}

export type RemoveSupplyApiRequest = {
    supplyId: SupplyId;
};
