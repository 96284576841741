import * as React from 'react';
import { DeliveryOrdersCustomerMarker } from 'src/components/googlemaps/deliveryOrdersMap/DeliveryOrdersCustomerMarker';
import type { OrderVm } from 'src/types/OrderVm';
import { isDeliveryOrder } from 'src/utils/order/isDeliveryOrder';
import { useSelector } from 'src/utils/react/useSelector';

export function DeliveryOrdersCustomerMarkers(): React.ReactElement {
    const currentOrders = useSelector((state) => state.app2.currentOrders);

    const deliveryOrders: Array<OrderVm> = currentOrders.filter((order) => isDeliveryOrder(order.orderType) && order.address);

    return (
        <>
            {deliveryOrders.map((order) => (
                <DeliveryOrdersCustomerMarker key={order.orderId} order={order} />
            ))}
        </>
    );
}
