import * as React from 'react';
import { memo } from 'react';
import TimeLineBarChart, { Color, PeriodScale, pushDataValueChange, XAxisScale, xAxisTooltipFormat } from 'src/scenes/letseatadmin/statistics/TimeLineBarChart';
import type { StatisticsVm } from 'src/scenes/letseatadmin/StatisticsPage';
import { formatAsNumber } from 'src/utils/number/formatAsNumber';

type Props = {
    statistics?: StatisticsVm;
    period: PeriodScale;
    xAxis: XAxisScale;
};

export const NewSignUpsChart: React.ComponentType<Props> = memo<Props>(function NewSignUpsChart({ statistics, period, xAxis }: Props): React.ReactElement | null {
    if (!statistics) {
        return null;
    }

    const data = statisticsToData(statistics, period, xAxis);
    return (
        <TimeLineBarChart
            data={data}
            period={period}
            colors={[Color.GREEN_LIGHT]}
            yAxisLegend={''}
            stacked={false}
            yAxisFormat={(value) => formatAsNumber(`${value}`)}
            yAxisTooltipFormat={(value) => formatAsNumber(`${value}`)}
            xAxisTooltipFormat={xAxisTooltipFormat(xAxis)}
            // yScaleMin={period == = Period.TWO_WEEKS ? 'auto' : undefined}
        />
    );
});

function statisticsToData(statistics: StatisticsVm, period: PeriodScale, xAxis: XAxisScale) {
    let prev: any;
    return statistics.reduce(
        (chartData, statistic) => {
            let i = 0;
            pushDataValueChange(chartData[i++].data, statistic.date, statistic.customer.signedUp, prev, period, xAxis);
            prev = statistic.customer.signedUp;
            return chartData;
        },
        [{ id: 'New Sign Ups', data: [] }],
    );
}
