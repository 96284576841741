import { InputAdornment, InputBase, InputLabel, makeStyles } from '@material-ui/core';
import FormHelperText from '@material-ui/core/FormHelperText';
import * as React from 'react';
import { useContext, useRef } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import NumberFormat from 'react-number-format';
import { FormContext } from 'src/components/form/Form';
import type { AutoCompleteInputProp, Variant } from 'src/components/form/FormTypes';
import { getError } from 'src/components/form/utils/getError';
import { transformStringInput } from 'src/components/form/utils/transformStringInput';
import { transformStringOutput } from 'src/components/form/utils/transformStringOutput';
import { Tooltip } from 'src/components/Tooltip';
import { translate } from 'src/i18n/translate';

/** @deprecated */

export function DeprecatedFormPercentNumberField({
    name,
    label,
    placeholder,
    helperText,
    defaultValue = null,
    autoComplete = 'off',
    disabled,
    required,
    min,
    max,
    variant,
    inputProps,
    tooltip,
}: Props): React.ReactElement {
    const {
        errors,
        control,
        formState: { isSubmitting },
    } = useFormContext();
    const formContext = useContext(FormContext);
    const inputRef = useRef<HTMLInputElement>();
    const classes = useStyles();

    return (
        <Controller
            render={({ value, onChange, ...props }) => (
                <div>
                    <InputLabel htmlFor={`${name}-input`} classes={{ root: classes.label, error: classes.labelError }} error={!!getError(errors, name)} required={required as any}>
                        {label}
                        {tooltip && <Tooltip text={tooltip} />}
                    </InputLabel>
                    <InputBase
                        {...props}
                        id={`${name}-input`}
                        value={transformStringInput(value)}
                        onChange={(value) => onChange(transformStringOutput(value as any))}
                        placeholder={placeholder}
                        disabled={isSubmitting || disabled || formContext.disabled}
                        required={required as any}
                        error={!!getError(errors, name)}
                        /* @ts-ignore */
                        variant={variant}
                        inputRef={inputRef}
                        inputComponent={NumberFormatCustom as any}
                        inputProps={{ autoComplete, ...inputProps }}
                        fullWidth
                        classes={{ root: classes.input, error: classes.inputError }}
                        endAdornment={<InputAdornment position='end'>%</InputAdornment>}
                    />
                    {!!(getError(errors, name)?.message ?? helperText) && (
                        <FormHelperText classes={{ root: !!getError(errors, name) ? classes.helperTextError : classes.helperText }}>{getError(errors, name)?.message ?? helperText}</FormHelperText>
                    )}
                </div>
            )}
            control={control}
            name={name}
            defaultValue={defaultValue}
            rules={{
                required: {
                    value: (required as any)?.value ?? required,
                    message: (required as any)?.message ?? translate('This field is required'),
                },
                min: {
                    value: 0,
                    message: (min as any)?.message ?? translate('Must be number equal to or larger than @number', { number: 0 }),
                },
                max: {
                    value: 100,
                    message: (max as any)?.message ?? translate('Must be number equal to or less than @number', { number: 100 }),
                },
            }}
            onFocus={() => {
                // make focus on error work when disabled={isSubmitting || disabled}
                if (inputRef.current) {
                    inputRef.current.disabled = false;
                    inputRef.current.focus();
                }
            }}
        />
    );
}

export function NumberFormatCustom(props: { inputRef: any; name: string; onChange: any }): React.ReactElement {
    const { inputRef, onChange, ...other } = props;

    return <NumberFormat {...other} getInputRef={inputRef} onValueChange={(values) => onChange(values.value)} allowNegative={false} thousandSeparator isNumericString decimalScale={2} />;
}
const useStyles = makeStyles((theme) => ({
    input: {
        backgroundColor: theme.palette.secondary.light,
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
        height: 42,
        padding: '0px 12px',
        justifyContent: 'space-between',
        width: '100%',
        outline: '1px solid #D9D9D9',
        borderRadius: '4px',
        position: 'relative',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        gap: 10,
        boxSizing: 'border-box',
        color: '#191919',
        fontFamily: theme.typography.light,
        border: 0,
    },
    inputError: {
        border: '2px solid red',
    },
    label: {
        fontFamily: theme.typography.regular,
        color: theme.palette.secondary.contrastText,
        fontSize: 12,
        marginBottom: 5,
        display: 'flex',
        [theme.breakpoints.up('sm')]: {
            fontSize: 14,
        },
    },
    labelError: {
        color: 'red',
    },
    helperText: {
        fontFamily: theme.typography.light,
        color: theme.palette.secondary.contrastText,
    },
    helperTextError: {
        fontFamily: theme.typography.light,
        color: 'red',
    },
}));

type Props = {
    name: string;
    label?: string;
    placeholder?: string;
    helperText?: string;
    defaultValue?: string | null | undefined;
    autoComplete?: AutoCompleteInputProp;
    disabled?: boolean;
    required?:
        | boolean
        | {
              value: number;
              message: string;
          };
    min?:
        | number
        | {
              message: string;
              value: number;
          };
    max?:
        | number
        | {
              message: string;
              value: number;
          };
    variant?: Variant;
    InputProps?: any;
    inputProps?: any;
    tooltip?: string;
};
