import { makeStyles } from '@material-ui/core';
import moment from 'moment';
import { useEffect, useState } from 'react';
import * as React from 'react';
import { findManagerUserActionLogsApi } from 'src/api/letseatadmin/managerUser/findManagerUserActionLogsApi';
import { Button } from 'src/components/Button';
import { FilterState, FromToFilter } from 'src/components/input/FromToFilter';
import { Page } from 'src/components/Page';
import { Table } from 'src/components/Table';
import type { City } from 'src/constants/City';
import { ManagerUserAction, ManagerUserActions } from 'src/constants/ManagerUserAction';
import type { PaymentMethod } from 'src/constants/PaymentMethod';
import { translate } from 'src/i18n/translate';
import { DriverManualEarningVm } from 'src/types/DriverManualEarningVm';
import type { DriverId, ManagerUserId, OrderId, Zone } from 'src/types/Id';
import { OrderVm } from 'src/types/OrderVm';
import { alertKnownErrorOrSomethingWentWrong } from 'src/utils/alert/alertKnownErrorOrSomethingWentWrong';
import { formatDateTimeString } from 'src/utils/date/formatDateTimeString';
import { toHumanizedOrderId } from 'src/utils/order/toHumanizedOrderId';
import { useParams } from 'src/utils/react/useParams';
import { openDriverPageInNewTab } from 'src/utils/window/openDriverPageInNewTab';
import { openOrderPageInNewTab } from 'src/utils/window/openOrderPageInNewTab';

export function ManagerUserPage(): React.ReactElement | null {
    const classes = useStyles();
    const { managerUserId } = useParams<{
        managerUserId: ManagerUserId;
    }>();

    const [loading, setLoading] = useState(false);
    const [actionLogs, setActionLogs] = useState<Array<ManagerUserActionVm>>([]);
    const [username, setUsername] = useState('');
    const [filter, setFilter] = useState<FilterState>({
        from: moment().startOf('isoWeek').toDate(),
        to: moment().endOf('isoWeek').toDate(),
    });

    useEffect(() => {
        if (managerUserId) load();
    }, [managerUserId, filter]);

    const handleChangeFilter = (filter: { from: Date; to: Date }) => setFilter(filter);

    const load = async () => {
        setLoading(true);
        const response = await findManagerUserActionLogsApi({
            managerUserId,
            from: moment(filter.from).format('YYYY-MM-DD'),
            to: moment(filter.to).format('YYYY-MM-DD'),
        });
        setLoading(false);
        if (!response.ok) {
            alertKnownErrorOrSomethingWentWrong(response);
            return;
        }
        setManualUserActions(response.data);
    };

    const setManualUserActions = ({
        ordersCancelled,
        manualAssignments,
        driverManualEarnings,
        username,
    }: {
        ordersCancelled: Array<OrderVm>;
        manualAssignments: Array<OrderVm>;
        driverManualEarnings: Array<DriverManualEarningVm>;
        username: string;
    }) => {
        const managerUserActions = [
            ...ordersCancelled.map((order) => ({
                orderId: order.orderId,
                acceptedAt: order.acceptedAt ? translate('@day at @hours', { day: moment(order.createdAt).format('DD/MM/YYYY'), hours: moment(order.createdAt).format('h:mm:a') }) : undefined,
                customerName: order.customerName,
                total: order.total,
                paymentMethod: order.paymentMethod,
                restaurantName: order.restaurant?.name ?? '',
                city: order.city,
                zone: order.zone,
                userActionType: ManagerUserActions.ORDER_CANCELLATION,
                didAt: order.cancelledAt ?? order.modifiedAt,
            })),
            ...manualAssignments.map((order) => ({
                orderId: order.orderId,
                acceptedAt: order.acceptedAt ? translate('@day at @hours', { day: moment(order.createdAt).format('DD/MM/YYYY'), hours: moment(order.createdAt).format('h:mm:a') }) : undefined,
                driverAcceptDuration: order.acceptedAt && order.deliveryAcceptedAt ? moment(order.deliveryAcceptedAt ?? new Date()).diff(order.acceptedAt ?? new Date(), 'minutes') : undefined,
                driverArrivedStoreDuration:
                    order.arrivedAtStoreAt && order.deliveryAcceptedAt ? moment(order.arrivedAtStoreAt ?? new Date()).diff(order.deliveryAcceptedAt ?? new Date(), 'minutes') : undefined,
                driverPickUpDuration: order.pickedUpAt && order.arrivedAtStoreAt ? moment(order.pickedUpAt ?? new Date()).diff(order.arrivedAtStoreAt ?? new Date(), 'minutes') : undefined,
                driverArrivedAtClient: order.arrivedAtClientAt && order.pickedUpAt ? moment(order.arrivedAtClientAt ?? new Date()).diff(order.pickedUpAt ?? new Date(), 'minutes') : undefined,
                driverDrivingDuration:
                    order.arrivedAtClientAt && order.deliveryAcceptedAt ? moment(order.arrivedAtClientAt ?? new Date()).diff(order.deliveryAcceptedAt ?? new Date(), 'minutes') : undefined,
                totalTime: order.arrivedAtClientAt && order.createdAt ? moment(order.arrivedAtClientAt ?? new Date()).diff(order.createdAt ?? new Date(), 'minutes') : undefined,
                customerName: order.customerName,
                total: order.total,
                paymentMethod: order.paymentMethod,
                restaurantName: order.restaurant?.name ?? '',
                city: order.city,
                zone: order.zone,
                driver: order.driverName,
                deliveryCost: order.deliveryCost,
                dynamicDeliveryEarnings: order.dynamicDeliveryEarnings,
                userActionType: ManagerUserActions.MANUAL_ASSIGNMENT,
                didAt: order.assignedAt,
            })),
            ...driverManualEarnings.map((driverManualEarning) => ({
                driverId: driverManualEarning.driverId,
                userActionType: ManagerUserActions.MANUAL_EARNING,
                didAt: driverManualEarning.modifiedAt,
            })),
        ];
        setActionLogs(managerUserActions);
        setUsername(username);
    };

    return (
        <Page title={translate('Manager User Actions')} className={classes.container}>
            <FromToFilter filter={filter} onChangeFilter={handleChangeFilter} />
            <h1 className={classes.title}>{username}</h1>
            <Table
                loading={loading}
                data={actionLogs.map((action) => ({
                    orderId: action.orderId,
                    acceptedAt: action.acceptedAt,
                    driverAcceptDuration: action.driverAcceptDuration,
                    driverArrivedStoreDuration: action.driverArrivedStoreDuration,
                    driverPickUpDuration: action.driverPickUpDuration,
                    driverArrivedAtClient: action.driverArrivedAtClient,
                    driverDrivingDuration: action.driverDrivingDuration,
                    totalTime: action.totalTime,
                    customerName: action.customerName,
                    total: action.total,
                    paymentMethod: action.paymentMethod,
                    restaurantName: action.restaurantName,
                    city: action.city,
                    zone: action.zone,
                    driver: action.driver,
                    deliveryCost: action.deliveryCost,
                    dynamicDeliveryEarnings: action.dynamicDeliveryEarnings,
                    driverId: action.driverId,
                    userActionType: translate(`ManagerUserActions.${action.userActionType}`),
                    didAt: formatDateTimeString(action.didAt),
                }))}
                columns={[
                    {
                        name: 'orderId',
                        label: translate('Order Id'),
                        options: {
                            filter: false,
                            customBodyRender: (orderId) => {
                                if (!orderId) return null;
                                return (
                                    <Button text onClick={() => openOrderPageInNewTab(orderId)}>
                                        #{toHumanizedOrderId(orderId)}
                                    </Button>
                                );
                            },
                        },
                    },
                    {
                        name: 'acceptedAt',
                        label: translate('Accept Order At'),
                        options: {
                            filter: false,
                        },
                    },
                    {
                        name: 'driverAcceptDuration',
                        label: translate('Driver Accept Duration Time'),
                        options: {
                            filter: false,
                        },
                    },
                    {
                        name: 'driverArrivedStoreDuration',
                        label: translate('Driver Arrived At Store Minutes'),
                        options: {
                            filter: false,
                        },
                    },
                    {
                        name: 'driverPickUpDuration',
                        label: translate('Order Pick Up Time'),
                        options: {
                            filter: false,
                        },
                    },
                    {
                        name: 'driverArrivedAtClient',
                        label: translate('Driver Arrived At Client Time'),
                        options: {
                            filter: false,
                        },
                    },
                    {
                        name: 'driverDrivingDuration',
                        label: translate('Driving Time'),
                        options: {
                            filter: false,
                        },
                    },
                    {
                        name: 'totalTime',
                        label: translate('Total Order Time'),
                        options: {
                            filter: false,
                        },
                    },
                    {
                        name: 'customerName',
                        label: translate('Client name'),
                        options: {
                            filter: false,
                        },
                    },
                    {
                        name: 'total',
                        label: translate('Total'),
                        options: {
                            filter: false,
                        },
                    },
                    {
                        name: 'paymentMethod',
                        label: translate('Payment Method'),
                        options: {
                            filter: false,
                        },
                    },
                    {
                        name: 'restaurantName',
                        label: translate('Restaurant Name'),
                        options: {
                            filter: false,
                        },
                    },
                    {
                        name: 'city',
                        label: translate('City'),
                    },
                    {
                        name: 'zone',
                        label: translate('Zone'),
                    },
                    {
                        name: 'driver',
                        label: translate('Driver'),
                    },
                    {
                        name: 'deliveryCost',
                        label: translate('Delivery Cost'),
                    },
                    {
                        name: 'dynamicDeliveryEarnings',
                        label: translate('Dynamic Delivery Earnings'),
                    },
                    {
                        name: 'driverId',
                        label: translate('Driver Id'),
                        options: {
                            filter: false,
                            customBodyRender: (driverId) => {
                                if (!driverId) return null;
                                return (
                                    <Button text onClick={() => openDriverPageInNewTab(driverId)}>
                                        #{toHumanizedOrderId(driverId)}
                                    </Button>
                                );
                            },
                        },
                    },
                    {
                        name: 'userActionType',
                        label: translate('User Action'),
                        options: {
                            filter: false,
                        },
                    },
                    {
                        name: 'didAt',
                        label: translate('Date'),
                        options: {
                            filter: false,
                        },
                    },
                ]}
                options={{
                    responsive: 'standard',
                    tableBodyMaxHeight: '500px',
                    selectableRows: 'none',
                    filterType: 'checkbox',
                    rowsPerPage: 100,
                    filter: false,
                    print: false,
                    viewColumns: false,
                }}
            />
        </Page>
    );
}

const useStyles = makeStyles((theme) => ({
    container: {
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        position: 'relative',
    },
    section: {
        marginBottom: 20,
    },
    title: {
        fontFamily: theme.typography.medium,
        fontSize: 18,
        top: 30,
        position: 'relative',
        zIndex: 1,
        marginBottom: -20,
        paddingLeft: 10,
    },
}));

type ManagerUserActionVm = {
    orderId?: OrderId;
    acceptedAt?: string;
    driverAcceptDuration?: number;
    driverArrivedStoreDuration?: number;
    driverPickUpDuration?: number;
    driverArrivedAtClient?: number;
    driverDrivingDuration?: number;
    totalTime?: number;
    customerName?: string;
    total?: string;
    paymentMethod?: PaymentMethod;
    restaurantName?: string;
    city?: City;
    zone?: Zone;
    driver?: string;
    deliveryCost?: string;
    dynamicDeliveryEarnings?: string;
    driverId?: DriverId;
    userActionType: ManagerUserAction;
    didAt?: Date;
};
