import { useRef, useState } from 'react';
import { deprecatedMockQpayPaymentTerminalResponseApi } from 'src/api/letseatmanager/paymentTerminalPayment/deprecated/deprecatedMockQpayPaymentTerminalResponseApi';
import { deprecatedPollQpayPaymentTerminalInstructionResultApi } from 'src/api/letseatmanager/paymentTerminalPayment/deprecated/deprecatedPollQpayPaymentTerminalInstructionResultApi';
import { deprecatedPushQpayPaymentTerminalInstructionApi } from 'src/api/letseatmanager/paymentTerminalPayment/deprecated/deprecatedPushQpayPaymentTerminalInstructionApi';
import { app2 } from 'src/app2';
import type { PaymentTerminalProvider } from 'src/constants/PaymentTerminalProvider';
import { QpayTerminalPaymentInstructionResult, QpayTerminalPaymentInstructionResults } from 'src/constants/QpayTerminalPaymentInstructionResult';
import type { QpayTerminalPaymentResponseResult } from 'src/constants/QpayTerminalPaymentResponseResults';
import { TerminalPaymentStatuses } from 'src/constants/TerminalPaymentStatus';
import { SECONDS } from 'src/constants/TimeUnit';
import { translate } from 'src/i18n/translate';
import { posReducer } from 'src/reducers/posReducer';
import { useDeveloperMode } from 'src/services/developerMode/useDeveloperMode';
import { usePaymentTerminalsAvailable } from 'src/services/device/usePaymentTerminalAvailable';
import { useConfirmDialog } from 'src/services/dialog/useConfirmDialog';
import { useFormatAsRestaurantCurrencyNumber } from 'src/services/restaurant/useFormatAsRestaurantCurrencyNumber';
import { QpayTerminalInstructionId, type PaymentTerminalId, type PaymentTerminalPaymentId } from 'src/types/Id';
import type { PaymentTerminalVm } from 'src/types/PaymentTerminalVm';
import { isArgentina } from 'src/utils/country/isArgentina';
import { isUruguay } from 'src/utils/country/isUruguay';
import { useAction } from 'src/utils/react/useAction';
import { useSelector } from 'src/utils/react/useSelector';
import { useTimeoutInterval } from 'src/utils/react/useTimeoutInterval';
import { isPaymentTerminalDevice } from 'src/utils/reactNative/isPaymentTerminalDevice';
import { requireValue } from 'src/utils/require/requireValue';

/**
 * @deprecated
 */
export function usePayWithPaymentTerminalDeprecatedService(): Result {
    const onSuccess = useRef<(value: any) => void>(() => {});
    const formatAsCurrencyNumber = useFormatAsRestaurantCurrencyNumber();
    const confirmDialog = useConfirmDialog();
    const paymentTerminalsAvailable = usePaymentTerminalsAvailable();

    const [loading, setLoading] = useState(false);
    const [qpayTerminalInstructionId, setQpayTerminalInstructionId] = useState<QpayTerminalInstructionId>();

    const country = useSelector((state) => state.app.restaurant.country);
    const newDeviceManagementEnabled = useSelector((state) => state.app.restaurant?.newDeviceManagementEnabled);
    const paymentTerminals = useSelector((state) => state.app.paymentTerminals);
    let qpayTerminalDeviceId = useSelector((state) => state.app.restaurant.qpayTerminalDeviceId);
    const isKioskUser = useSelector((state) => state.authentication.kioskUser);
    const qpayPosTerminalDeviceId = useSelector((state) => state.app.qpayPosTerminalDeviceId);

    const openSelectPaymentTerminalDialog = useAction(app2.actions.openSelectPaymentTerminalDialog);
    const developerMode = useDeveloperMode();

    const openMockPaymentTerminalResponseDialog = useAction(posReducer.actions.openMockPaymentTerminalResponseDialog);

    useTimeoutInterval(
        async () => {
            if (!qpayTerminalInstructionId) return;

            const response = await deprecatedPollQpayPaymentTerminalInstructionResultApi({ qpayTerminalInstructionId });

            if (!response.ok) {
                console.log(`Failed silently, trying again in 5 seconds response`, response);
                return;
            }

            if (response.data.terminalPaymentStatus === TerminalPaymentStatuses.CANCELLED) {
                setLoading(false);

                await confirmDialog({
                    title: translate('Error'),
                    content: response.data?.message ? translate(response.data.message) : translate('Failed to do the payment.'),
                    buttonText: translate('Accept'),
                    variant: 'error',
                });

                onSuccess.current?.({ paid: false });
                setQpayTerminalInstructionId(undefined);
                return;
            }

            if (response.data.terminalPaymentStatus === TerminalPaymentStatuses.PAID) {
                setLoading(false);
                onSuccess.current?.({ paid: true });
                setQpayTerminalInstructionId(undefined);
            }
        },
        2 * SECONDS,
        [qpayTerminalInstructionId],
    );

    const onPaymentFailed = (response: any, resolve: any) => {
        setLoading(false);
        setQpayTerminalInstructionId(undefined);
        resolve({
            paid: false,
        });
    };

    const payInTerminal = async ({ qpayTerminalDeviceId, amount }: any): Promise<PaymentResponse> => {
        return new Promise<any>(async (resolve) => {
            if (isUruguay(country) || isArgentina(country)) {
                await confirmDialog({
                    title: translate('Error'),
                    content: translate('Is not possible to pay with terminal in Uruguay or Argentina'),
                    buttonText: translate('Accept'),
                    variant: 'error',
                });
                resolve({ paid: false });
                return;
            }

            if (!qpayTerminalDeviceId) {
                await confirmDialog({
                    title: translate('Error'),
                    content: translate('Terminal not configured'),
                    buttonText: translate('Accept'),
                    variant: 'error',
                });
                resolve({ paid: false });
                return;
            }

            setLoading(true);

            let mock:
                | {
                      qpayTerminalInstructionResult: QpayTerminalPaymentInstructionResult;
                      responseResult: QpayTerminalPaymentResponseResult;
                      timeout: number;
                  }
                | undefined;

            if (developerMode) {
                mock = await new Promise((resolve: (result: Promise<undefined> | undefined) => void) => {
                    openMockPaymentTerminalResponseDialog({
                        onSelectPaymentTerminalResponse: async (paymentTerminalResponseMock: any) => {
                            resolve(paymentTerminalResponseMock);
                        },
                        onCloseDialog: () => {
                            resolve(undefined);
                        },
                    });
                });
                if (!mock) {
                    setLoading(false);
                    resolve({ paid: false });
                    return;
                }
            }

            const response = await deprecatedPushQpayPaymentTerminalInstructionApi({
                qpayTerminalDeviceId,
                amount,
                mockQpayTerminalInstructionResult: mock?.qpayTerminalInstructionResult,
                apphook: isPaymentTerminalDevice() && qpayTerminalDeviceId === qpayPosTerminalDeviceId ? 'pidedirectoadminapp' : undefined,
            });

            if (!response.ok) {
                setLoading(false);
                await confirmDialog({
                    title: translate('Error'),
                    content: (response.data as any)?.message ? translate('Something went wrong @error', { error: (response.data as any).message }) : translate('Something went wrong'),
                    buttonText: translate('Accept'),
                    variant: 'error',
                });

                onPaymentFailed(response, resolve);
                return;
            }

            const qpayTerminalPaymentInstructionResult = response.data?.qpayTerminalPaymentInstructionResult;

            if (
                qpayTerminalPaymentInstructionResult === QpayTerminalPaymentInstructionResults.INSTRUCTION_RECEIVED ||
                qpayTerminalPaymentInstructionResult === QpayTerminalPaymentInstructionResults.UNKNOWN
            ) {
                if (mock) {
                    setTimeout(async () => {
                        await deprecatedMockQpayPaymentTerminalResponseApi({
                            terminalInstructionId: response.data.qpayTerminalInstructionId,
                            qpayResponseResult: requireValue(mock).responseResult,
                            amount,
                        });
                    }, mock.timeout * SECONDS);
                }

                onSuccess.current = resolve;
                setQpayTerminalInstructionId(response.data.qpayTerminalInstructionId);
                return;
            }

            if (qpayTerminalPaymentInstructionResult === QpayTerminalPaymentInstructionResults.TERMINAL_BUSY) {
                setLoading(false);
                await confirmDialog({
                    title: translate('Error'),
                    content: translate('Terminal busy, please wait a moment and try again'),
                    buttonText: translate('Accept'),
                    variant: 'error',
                });

                onPaymentFailed(response, resolve);
                return;
            }

            if (qpayTerminalPaymentInstructionResult === QpayTerminalPaymentInstructionResults.TERMINAL_NOT_FOUND && !isKioskUser) {
                setLoading(false);
                await confirmDialog({
                    title: translate('Error'),
                    content: translate('Terminal not found, check your configuration and try again'),
                    buttonText: translate('Accept'),
                    variant: 'error',
                });
                onPaymentFailed(response, resolve);

                return;
            }

            if (qpayTerminalPaymentInstructionResult === QpayTerminalPaymentInstructionResults.TERMINAL_NOT_FOUND && isKioskUser) {
                setLoading(false);
                await confirmDialog({
                    title: translate('Error'),
                    content: translate('Terminal not found, ask in store or try later'),
                    buttonText: translate('Accept'),
                    variant: 'error',
                });
                onPaymentFailed(response, resolve);
                return;
            }

            onSuccess.current = resolve;
            setQpayTerminalInstructionId(response.data.qpayTerminalInstructionId);
        });
    };

    const payWithPaymentTerminal = ({ amount, paymentTerminalId }: any): Promise<PaymentResponse> => {
        if (!newDeviceManagementEnabled) {
            return payInTerminal({ qpayTerminalDeviceId, amount });
        }

        if (paymentTerminalId) {
            const paymentTerminal = paymentTerminals.find((paymentTerminal) => paymentTerminal.paymentTerminalId === paymentTerminalId);
            qpayTerminalDeviceId = paymentTerminal?.qpayTerminalDeviceId;
            return payInTerminal({ qpayTerminalDeviceId, amount });
        }

        if (paymentTerminalsAvailable.length > 1) {
            return selectPaymentTerminalToPay({
                header: translate('Select a terminal to pay @amount', { amount: formatAsCurrencyNumber(amount) }),
                amount,
            });
        }

        qpayTerminalDeviceId = paymentTerminalsAvailable[0]?.qpayTerminalDeviceId;
        return payInTerminal({ qpayTerminalDeviceId, amount });
    };

    const cancelPayment = () => {
        setQpayTerminalInstructionId(undefined);
    };

    const selectPaymentTerminalToPay = async ({ header, error, amount }: SelectPaymentTerminalToPayParams): Promise<any> => {
        return await new Promise((resolve) => {
            openSelectPaymentTerminalDialog({
                header,
                error,
                onSelectPaymentTerminal: async (paymentTerminal: PaymentTerminalVm) => {
                    const paymentResponse = await payInTerminal({
                        amount,
                        qpayTerminalDeviceId: paymentTerminal.qpayTerminalDeviceId,
                    });
                    if (!paymentResponse.paid) {
                        resolve(
                            await selectPaymentTerminalToPay({
                                header: translate(`Failed to create a payment`),
                                amount: amount,
                            }),
                        );
                        return;
                    }

                    resolve(paymentResponse);
                },
                onCloseDialog: () => {
                    resolve({ paid: false });
                },
            });
        });
    };

    return { payWithPaymentTerminal, cancelPayment, loading };
}

type Result = {
    /** @deprecated */
    payWithPaymentTerminal: PayWithPaymentTerminalFunction;
    /** @deprecated */
    cancelPayment: any;
    /** @deprecated */
    loading: boolean;
};

/** @deprecated */
type PayWithPaymentTerminalFunction = (arg1: { amount: string; paymentTerminalId?: PaymentTerminalId }) => Promise<PaymentResponse>;

/** @deprecated */
type PaymentResponse = {
    paid: boolean;
    paymentTerminalPaymentId?: PaymentTerminalPaymentId;
    paymentTerminalId?: PaymentTerminalId;
    paymentTerminalProvider?: PaymentTerminalProvider;
};

type SelectPaymentTerminalToPayParams = {
    header?: string;
    error?: string;
    amount: string;
};
