import { makeStyles } from '@material-ui/core';
import moment from 'moment';
import * as React from 'react';
import { findBuyOrdersByRestaurantIdApi } from 'src/api/letseatmanager/buyOrder/findBuyOrdersByRestaurantIdApi';
import { SelectItemDialog } from 'src/components/dialog/SelectItemDialog';
import { translate } from 'src/i18n/translate';
import { useFormatAsRestaurantCurrencyNumber } from 'src/services/restaurant/useFormatAsRestaurantCurrencyNumber';
import { BuyOrderVm } from 'src/types/BuyOrderVm';
import { useLoadApi } from 'src/utils/react/useLoadApi';
import { useSelector } from 'src/utils/react/useSelector';

export function SelectBuyOrderDialog({ open, onClose, onSuccess }: Props): React.ReactElement {
    const restaurantId = useSelector((state) => state.app.restaurantId);
    const formatAsCurrencyNumber = useFormatAsRestaurantCurrencyNumber();

    const [loading, buyOrders] = useLoadApi(findBuyOrdersByRestaurantIdApi, { restaurantId }, { requiredValues: [restaurantId], dependencies: [restaurantId] });

    const sortedBuyOrders = buyOrders
        ?.sort((orderA: any, orderB: any) => moment(orderA?.modifiedAt).diff(moment(orderB?.modifiedAt)))
        .map((buyOrder: any) => ({
            value: buyOrder.buyOrderId,
            supplierBusinessName: buyOrder.supplierBusinessName,
            searchableBy: buyOrder.supplierBusinessName,
            item: buyOrder,
        }));

    return (
        <SelectItemDialog
            open={open}
            title={translate('Select your buy Order')}
            items={sortedBuyOrders ?? []}
            renderItem={({ item: buyOrder }: { item: BuyOrderVm }) =>
                `${buyOrder?.supplierBusinessName ?? translate('No Provider')} | ${formatAsCurrencyNumber(buyOrder?.total)} | ${moment(buyOrder?.createdAt).format('MMM DD, YY')}`
            }
            onClose={onClose}
            onSuccess={(buyOrder: BuyOrderVm) => {
                onSuccess(buyOrder.buyOrderId);
            }}
        />
    );
}

const useStyles = makeStyles((theme) => ({
    dialogContainer: {
        width: '80%',
        maxHeight: '95%',
        height: 'fit-content',
        position: 'relative',
    },
    title: {
        fontFamily: theme.typography.medium,
        fontSize: 20,
        marginBottom: 50,
        color: theme.palette.primary.main,
        textAlign: 'center',
    },
    acceptButton: {
        backgroundColor: theme.palette.primary.main,
        color: 'white',
        fontFamily: theme.typography.semiBold,
        fontSize: 15,
        borderRadius: 15,
        height: 30,
        width: 150,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        textTransform: 'none',
    },
    secondaryButton: {
        backgroundColor: 'white',
        color: theme.palette.primary.main,
        border: `1px solid ${theme.palette.primary.main}`,
        fontFamily: theme.typography.semiBold,
        fontSize: 15,
        borderRadius: 15,
        height: 30,
        width: 150,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        textTransform: 'none',
    },
    addSupplyButton: {
        backgroundColor: 'white',
        color: theme.palette.primary.main,
        border: `1px solid ${theme.palette.primary.main}`,
        fontFamily: theme.typography.semiBold,
        fontSize: 15,
        borderRadius: 5,
        height: 30,
        padding: '5px 15px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        textTransform: 'none',
    },
    supplyContainer: {
        width: '100%',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        padding: '5px 15px',
        borderBottom: `1px solid ${theme.palette.secondary.dark}`,
    },
    recipeSupplyName: {
        fontFamily: theme.typography.regular,
        fontSize: 15,
        width: '50%',
    },
    recipeSupplyPrice: {
        fontFamily: theme.typography.regular,
        fontSize: 15,
        width: '20%',
    },
    recipeSupplyButton: {
        fontFamily: theme.typography.regular,
        fontSize: 15,
        width: '10%',
    },
}));

type Props = {
    open: boolean;
    onClose: any;
    onSuccess: any;
};
