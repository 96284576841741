import * as React from 'react';
import { memo } from 'react';
import TimeLineBarChart, {
    Color,
    PeriodScale,
    pushData,
    XAxisScale,
    xAxisTooltipFormat,
    yAxisFormat,
    yAxisLegend,
    YAxisScale,
    yAxisTooltipFormat,
} from 'src/scenes/letseatadmin/statistics/TimeLineBarChart';
import type { StatisticsVm } from 'src/scenes/letseatadmin/StatisticsPage';

type Props = {
    statistics?: StatisticsVm;
    period: PeriodScale;
    yAxis: YAxisScale;
    xAxis: XAxisScale;
};

export const NewAcceptedCompletedChart: React.ComponentType<Props> = memo<Props>(function NewAcceptedCompletedChart({ statistics, period, yAxis, xAxis }: Props): React.ReactElement | null {
    if (!statistics) {
        return null;
    }

    return (
        <TimeLineBarChart
            data={statisticsToData(statistics, period, yAxis, xAxis)}
            period={period}
            yAxis={yAxis}
            xAxis={xAxis}
            stacked={false}
            colors={[Color.GREEN, Color.GREEN_LIGHT, Color.BLUE_LIGHT]}
            areaOpacity={0.9}
            yAxisLegend={yAxisLegend(yAxis)}
            yAxisFormat={yAxisFormat(yAxis)}
            yAxisTooltipFormat={yAxisTooltipFormat(yAxis)}
            xAxisTooltipFormat={xAxisTooltipFormat(xAxis)}
        />
    );
});

function statisticsToData(statistics: StatisticsVm, period: PeriodScale, yAxis: YAxisScale, xAxis: XAxisScale) {
    return statistics.reduce(
        (chartData, statistic) => {
            pushData(chartData[0].data, statistic.date, statistic.order.completed, period, yAxis, xAxis);
            pushData(chartData[1].data, statistic.date, statistic.order.accepted, period, yAxis, xAxis);
            pushData(chartData[2].data, statistic.date, statistic.order.new, period, yAxis, xAxis);
            return chartData;
        },
        [
            { id: 'Completed', data: [] },
            { id: 'Accepted', data: [] },
            { id: 'New', data: [] },
        ],
    );
}
