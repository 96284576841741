import * as React from 'react';
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { RoutePaths } from 'src/constants/RoutePath';
import { Sounds } from 'src/constants/Sound';
import { SECONDS } from 'src/constants/TimeUnit';
import { useInterval } from 'src/utils/react/useInterval';
import { useSelector } from 'src/utils/react/useSelector';
import { isWebApp } from 'src/utils/reactNative/isWebApp';
import { setShouldPlayAlertSoundMobileApp } from 'src/utils/reactNative/setShouldPlayAlertSoundMobileApp';
import { playAlertSound } from 'src/utils/sound/playAlertSound';

export function NewKitchenOrdersSoundPlayer(): React.ReactElement | null {
    const location = useLocation();

    const soundMuted = useSelector((state) => state.app.soundMuted);
    const restaurant = useSelector((state) => state.app.restaurant);
    const kioskUser = useSelector((state) => state.authentication.kioskUser);
    const hasNewKitchenOrders = useSelector((state) => state.app2.hasNewKitchenOrders);
    const isKitchenLocation = location.pathname === RoutePaths.KITCHEN_DISPLAY_SCREEN;

    const playOngoingOrderSound = !soundMuted && hasNewKitchenOrders;

    useEffect(() => {
        shouldPlayKitchenAlert();
    }, [isKitchenLocation, playOngoingOrderSound, restaurant]);

    useInterval(
        () => {
            shouldPlayKitchenAlert();
        },
        2 * SECONDS,
        [isKitchenLocation, playOngoingOrderSound, restaurant],
    );

    const shouldPlayKitchenAlert = () => {
        if (kioskUser) return;
        if (!isKitchenLocation) return;
        const alertSoundToPlay = restaurant.kitchenDisplayScreenAlertSound ?? Sounds.DEFAULT_SOUND;

        if (!playOngoingOrderSound) {
            setShouldPlayAlertSoundMobileApp(playOngoingOrderSound, alertSoundToPlay);
            return;
        }

        if (isWebApp()) {
            playAlertSound(alertSoundToPlay);
        }

        setShouldPlayAlertSoundMobileApp(playOngoingOrderSound, alertSoundToPlay);
    };

    return null;
}
