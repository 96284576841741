import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import RadioGroup from '@material-ui/core/RadioGroup';
import { withStyles } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import EditIcon from '@material-ui/icons/Edit';
import RefreshIcon from '@material-ui/icons/Refresh';
import * as React from 'react';
import { withRouter } from 'react-router-dom';
import { letseatmanagerApiDeprecated } from 'src/api/letseatmanagerApiDeprecated';
import { Field, Form } from 'src/components/deprecated/react-final-form/Form';
import Radio from 'src/components/deprecated/react-final-form/Radio';
import TextField from 'src/components/deprecated/react-final-form/TextField';
import { UpdatingContentProgress } from 'src/components/UpdatingContentProgress';
import { history } from 'src/config/history';
import { RoutePaths } from 'src/constants/RoutePath';
import { translate } from 'src/i18n/translate';
import ChangeEmployeeDialog, { CHANGE_EMPLOYEE } from 'src/scenes/letseatadmin/company/ChangeEmployeeDialog';
import type { BenefitPlanId, CompanyId, CustomerId, EmployeeId, EmployeeIdAtCompany, EmployeeNumberAtCompany } from 'src/types/Id';
import { alertKnownErrorOrSomethingWentWrong } from 'src/utils/alert/alertKnownErrorOrSomethingWentWrong';
import { formatDateTimeString } from 'src/utils/date/formatDateTimeString';
import { setUrlQueryParameter } from 'src/utils/history/setUrlQueryParameter';
import { RegExps } from 'src/utils/RegExps';

export default withRouter<any, any>(
    withStyles((theme) => ({
        linearProgress: {
            position: 'absolute',
            width: '100%',
            bottom: 0,
        },
        root: {
            paddingLeft: 8,
            paddingRight: 8,
        },
    }))(
        class extends React.PureComponent<Props, State> {
            state: any = INITIAL_STATE;

            async componentDidMount(): Promise<void> {
                await this.load();
            }

            load = async () => {
                this.setState({ loading: true });
                const response = await letseatmanagerApiDeprecated.admin.getEmployee({ employeeId: this.props.employeeId });
                this.setState({ loading: false });
                if (!response.ok) {
                    alertKnownErrorOrSomethingWentWrong(response);
                    return;
                }
                const employee: EmployeeVm = response.data;

                this.props.onEmployeeChange && this.props.onEmployeeChange(employee);

                this.setState({
                    employeeDetails: {
                        employeeId: employee.employeeId,
                        benefitPlanId: employee.benefitPlanId,
                        email: employee.email,
                        employeeIdAtCompany: employee.employeeIdAtCompany,
                        employeeNumberAtCompany: employee.employeeNumberAtCompany,
                        firstName: employee.firstName,
                        lastName: employee.lastName,
                        paused: employee.paused ? 'true' : 'false',
                        connectedAt: formatDateTimeString(employee.connectedAt),
                        createdAt: formatDateTimeString(employee.createdAt),
                        modifiedAt: formatDateTimeString(employee.modifiedAt),
                    },
                });
            };

            handleClickEdit = () => {
                history.push({
                    search: setUrlQueryParameter(CHANGE_EMPLOYEE, this.props.employeeId),
                });
            };

            handleEmployeeChanged = async (deleted: any) => {
                if (deleted) {
                    if (!this.state.employeeDetails) {
                        return;
                    }
                    history.replace({
                        pathname: RoutePaths.ADMIN_BENEFIT_PLAN.replace(`:companyId(${RegExps.uuid})`, this.state.employeeDetails.benefitPlanId),
                        search: history.location.search,
                    });
                    return;
                }
                await this.load();
            };

            render() {
                const { classes } = this.props;
                return (
                    <div style={{ position: 'relative' }}>
                        <ChangeEmployeeDialog onEmployeeChanged={this.handleEmployeeChanged} />
                        <UpdatingContentProgress loading={this.state.loading} />
                        <Card classes={{ root: classes.root }}>
                            <CardHeader
                                title={
                                    <Typography display='block' gutterBottom variant='h6'>
                                        {translate('Employee Details')}
                                    </Typography>
                                }
                                action={
                                    <div>
                                        <Tooltip title='Refresh'>
                                            <IconButton onClick={this.load}>
                                                <RefreshIcon />
                                            </IconButton>
                                        </Tooltip>
                                        <Tooltip title='Change'>
                                            <IconButton onClick={this.handleClickEdit}>
                                                <EditIcon />
                                            </IconButton>
                                        </Tooltip>
                                    </div>
                                }
                            />
                            <CardContent>{this.renderContent()}</CardContent>
                        </Card>
                    </div>
                );
            }

            onSubmit = (form: any) => {};

            renderContent() {
                return (
                    <>
                        <Form
                            onSubmit={this.onSubmit}
                            initialValues={{
                                employee: this.state.employeeDetails || {},
                            }}
                            render={({ handleSubmit, submitting, pristine, values }) => (
                                <Grid container spacing={3}>
                                    <Grid item xs={12} sm={6}>
                                        <Field name='employee.firstName' component={TextField} label={translate('First Name')} fullWidth disabled />
                                    </Grid>

                                    <Grid item xs={12} sm={6}>
                                        <Field name='employee.lastName' component={TextField} label={translate('Last Name')} fullWidth disabled />
                                    </Grid>

                                    <Grid item xs={12} sm={6}>
                                        <Field name='employee.email' component={TextField} label={translate('Email')} fullWidth disabled />
                                    </Grid>

                                    <Grid item xs={12} sm={6}>
                                        <Field name='employee.employeeIdAtCompany' component={TextField} label={translate('Employee Id')} fullWidth disabled />
                                    </Grid>

                                    <Grid item xs={12} sm={6}>
                                        <Field name='employee.employeeNumberAtCompany' component={TextField} label={translate('Employee Number')} fullWidth disabled />
                                    </Grid>

                                    <Grid item xs={12}>
                                        <RadioGroup row>
                                            <FormControlLabel label='Active' control={<Field name='employee.paused' component={Radio} type='radio' color='primary' value='false' disabled />} />
                                            <FormControlLabel label='Paused' control={<Field name='employee.paused' component={Radio} type='radio' color='primary' value='true' disabled />} />
                                        </RadioGroup>
                                    </Grid>

                                    <Grid item xs={12} sm={6}>
                                        <Field name='employee.createdAt' component={TextField} label={translate('Created')} fullWidth disabled />
                                    </Grid>

                                    <Grid item xs={12} sm={6}>
                                        <Field name='employee.modifiedAt' component={TextField} label={translate('Modified')} fullWidth disabled />
                                    </Grid>

                                    <Grid item xs={12}>
                                        <Field
                                            name='employee.connectedAt'
                                            component={TextField}
                                            label={translate('Connected')}
                                            placeholder={translate('No')}
                                            InputLabelProps={{ shrink: true }}
                                            fullWidth
                                            disabled
                                        />
                                    </Grid>
                                </Grid>
                            )}
                        />
                    </>
                );
            }
        },
    ),
) as React.ComponentType<any>;

const INITIAL_STATE = {
    loading: false,
} as const;

type Props = {
    employeeId: EmployeeId;
    onEmployeeChange?: (arg1: EmployeeVm) => Record<any, any>;
    classes: any;
};

type State = {
    loading: boolean;
    employeeDetails?: {
        employeeId: EmployeeId;
        benefitPlanId: BenefitPlanId;
        email: string;
        employeeIdAtCompany?: EmployeeIdAtCompany;
        employeeNumberAtCompany?: EmployeeNumberAtCompany;
        firstName: string;
        lastName: string;
        paused: string;
        connectedAt: string;
        createdAt: string;
        modifiedAt: string;
    };
};

type EmployeeVm = {
    employeeId: EmployeeId;
    companyId: CompanyId;
    benefitPlanId: BenefitPlanId;
    customerId?: CustomerId;
    email: string;
    employeeIdAtCompany?: EmployeeIdAtCompany;
    employeeNumberAtCompany?: EmployeeNumberAtCompany;
    firstName: string;
    lastName: string;
    paused?: boolean;
    connectedAt?: Date;
    createdAt: Date;
    modifiedAt: Date;
};
