import { letseatmanagerApiMethod } from 'src/api/letseatmanagerApi';
import type { ApiSauceResponse } from 'src/api/types/ApiSauceResponse';
import type { MenuItemId } from 'src/types/Id';

export async function duplicateMenuItemApi(request: DuplicateMenuItemApiRequest): ApiSauceResponse<void> {
    return letseatmanagerApiMethod('menuItem/duplicateMenuItemApi', request);
}

export type DuplicateMenuItemApiRequest = {
    menuItemId: MenuItemId;
};
