import { makeStyles } from '@material-ui/core/styles';
import { useRef } from 'react';
import * as React from 'react';
import type { SurveyVm } from 'src/api/letseatmanager/survey/getSurveysApi';
import { RefreshToolbarButton } from 'src/components/mui-datatables/RefreshToolbarButton';
import { Table } from 'src/components/Table';
import { UpdatingContentProgress } from 'src/components/UpdatingContentProgress';
import { translate } from 'src/i18n/translate';
import { formatDateTimeString } from 'src/utils/date/formatDateTimeString';
import { createFilteredOnDownload } from 'src/utils/mui-datatables/createFilteredOnDownload';
import { translateSurveyAnswer } from 'src/utils/translate/translateSurveyAnswer';
import 'src/scenes/letseatmanager/surveys/RatingDetails.css';
import { MUIDataTableColumn } from 'mui-datatables';

export default function SurveysTable({ loading, title, surveys, onClickRefresh }: Props): React.ReactElement | null {
    const table = useRef(); // TODO: fix correct type

    const classes = useStyles();

    if (!surveys?.length) {
        return null;
    }

    const columns: Array<MUIDataTableColumn> = [
        {
            name: 'surveyId',
            label: ' ',
            options: {
                display: 'excluded',
                filter: false,
                searchable: true,
            },
        },
        {
            name: 'customerId',
            label: ' ',
            options: {
                display: 'excluded',
                filter: false,
                searchable: true,
            },
        },
        {
            name: 'answeredAt',
            label: translate('Answered'),
            options: {
                filter: false,
                searchable: false,
            },
        },
        {
            name: 'customerName',
            label: translate('Customer'),
            options: {
                filter: false,
                searchable: true,
            },
        },
        {
            name: 'mobileNumber',
            label: translate('Mobile'),
            options: {
                filter: false,
                searchable: true,
            },
        },
        {
            name: 'rating',
            label: translate('Rating'),
            options: {
                filter: true,
                searchable: false,
            },
        },
        {
            name: 'recommendationRating',
            label: translate('Recommendation'),
            options: {
                filter: true,
                searchable: false,
            },
        },
        {
            name: 'surveyAnswers',
            label: translate('Answers'),
            options: {
                filter: false,
                searchable: false,
            },
        },
        {
            name: 'otherAnswer',
            label: translate('Other Answer'),
            options: {
                filter: false,
                searchable: true,
            },
        },
    ];

    return (
        <div style={{ position: 'relative' }}>
            <UpdatingContentProgress loading={loading} />
            <Table
                className={classes.table}
                title={title || translate('Surveys')}
                /* @ts-ignore */
                ref={table}
                data={surveys.map((survey) => {
                    const row = {
                        surveyId: survey.surveyId,
                        customerId: survey.customerId,
                        answeredAt: formatDateTimeString(survey.answeredAt),
                        customerName: survey.customerName,
                        mobileNumber: survey.mobileNumber,
                        rating: survey.rating,
                        recommendationRating: survey.recommendationRating,
                        surveyAnswers: survey.surveyAnswers ? survey.surveyAnswers.map(translateSurveyAnswer).join(', ') : '',
                        otherAnswer: survey.otherAnswer ?? '',
                    } as const;
                    return row;
                })}
                columns={columns}
                options={{
                    responsive: 'standard',
                    tableBodyMaxHeight: '500px',
                    selectableRows: 'none',
                    filterType: 'multiselect',
                    rowsPerPage: 100,
                    customToolbar: () => {
                        return (
                            <>
                                <RefreshToolbarButton onClick={onClickRefresh} />
                            </>
                        );
                    },
                    onDownload: createFilteredOnDownload({ table: table.current }),
                }}
            />
        </div>
    );
}

const useStyles = makeStyles((theme) => ({
    table: {
        whiteSpace: 'nowrap',
        '& tr': { cursor: 'pointer' },
        '& tr:hover': { backgroundColor: 'rgba(0, 0, 0, 0.07) !important' },
    },
    toolbar: {
        paddingRight: theme.spacing(3),
    },
}));

type Props = {
    loading?: boolean;
    title?: string;
    surveys: Array<SurveyVm>;
    onClickRefresh: any;
};
