import * as React from 'react';

export function CircularUnCheckedIcon({ color, width, height, title }: Props): React.ReactElement {
    return (
        <svg xmlns='http://www.w3.org/2000/svg' width={width ?? '30'} height={height ?? '30'} viewBox='0 0 30 30' fill='none'>
            {title && <title>{title}</title>}
            <circle cx='15' cy='15' r='14.5' stroke={color ?? '#D9D9D9'} />
        </svg>
    );
}

type Props = {
    width?: string;
    height?: string;
    color?: string;
    title?: string;
};
