import { makeStyles } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import { useConfirmDialog } from '@pidedirecto/ui/hooks';
import { formatAsCurrencyNumber } from '@pidedirecto/ui/utils';
import { BigNumber } from 'bignumber.js';
import MUIDataTable from 'mui-datatables';
import * as React from 'react';
import { useRef, useState } from 'react';
import { unBanDeviceApi } from 'src/api/letseatadmin/driver/unBanDeviceApi';
import { letseatmanagerApiDeprecated } from 'src/api/letseatmanagerApiDeprecated';
import { ChangeToolbarButton } from 'src/components/mui-datatables/ChangeToolbarButton';
import { CustomToolbarSelect } from 'src/components/mui-datatables/CustomToolbarSelect';
import { DownloadCsvToolbarButton } from 'src/components/mui-datatables/DownloadCsvToolbarButton';
import { ExportToolbarButton } from 'src/components/mui-datatables/ExportToolbarButton';
import { MobileToolbarButton } from 'src/components/mui-datatables/MobileToolbarButton';
import { RefreshToolbarButton } from 'src/components/mui-datatables/RefreshToolbarButton';
import { SendEmailToolbarButton } from 'src/components/mui-datatables/SendEmailToolbarButton';
import { SendNotificationToolbarButton } from 'src/components/mui-datatables/SendNotificationToolbarButton';
import { SendSmsToolbarButton } from 'src/components/mui-datatables/SendSmsToolbarButton';
import { UpdatingContentProgress } from 'src/components/UpdatingContentProgress';
import { history } from 'src/config/history';
import type { Bank } from 'src/constants/Bank';
import type { City } from 'src/constants/City';
import { RoutePaths } from 'src/constants/RoutePath';
import { translate } from 'src/i18n/translate';
import { CHANGE_DRIVER } from 'src/scenes/letseatadmin/driver/ChangeDriverDialog';
import { ChangeMobileNumberDriverDialog, ChangeMobileNumberDriverDialogState } from 'src/scenes/letseatadmin/driver/ChangeMobileNumberDriverDialog';
import { SEND_NOTIFICATION } from 'src/scenes/letseatadmin/driver/DriverSendDialog';
import { SEND_EMAIL } from 'src/scenes/letseatadmin/driver/DriverSendEmailDialog';
import { SendSmsToDriverDialog, SendSmsToDriverDialogState } from 'src/scenes/letseatadmin/driver/SendSmsToDriverDialog';
import { SendSmsToDriversDialog, SendSmsToDriversDialogState } from 'src/scenes/letseatadmin/driver/SendSmsToDriversDialog';
import type { GeoJsonPoint } from 'src/types/GeoJsonPoint';
import type { DriverId, EmailAddress, ExternalDeviceId } from 'src/types/Id';
import { alertKnownErrorOrSomethingWentWrong } from 'src/utils/alert/alertKnownErrorOrSomethingWentWrong';
import { toCity } from 'src/utils/city/toCity';
import { fullName } from 'src/utils/customer/fullName';
import { formatDateTimeString } from 'src/utils/date/formatDateTimeString';
import { getDriverAvailability } from 'src/utils/driver/getDriverAvailability';
import { translateDriverAvailability } from 'src/utils/driver/translateDriverAvailability';
import { setUrlQueryParameter } from 'src/utils/history/setUrlQueryParameter';
import { downloadTextInFile } from 'src/utils/html/downloadTextInFile';
import { createFilteredOnDownloadFunctional } from 'src/utils/mui-datatables/createFilteredOnDownloadFunctional';
import { createHandleClickDownloadCsvFunctional } from 'src/utils/mui-datatables/createHandleClickDownloadCsvFunctional';
import { getSelectedRows } from 'src/utils/mui-datatables/getSelectedRows';
import { getSelectedRowsData } from 'src/utils/mui-datatables/getSelectedRowsData';
import { useSelector } from 'src/utils/react/useSelector';
import { openDriverPageInNewTab } from 'src/utils/window/openDriverPageInNewTab';

export const DriversTable = function DriversTable({ loading, title, drivers, onClickRefresh }: Props): React.ReactElement {
    const classes = useStyles();
    const confirmDialog = useConfirmDialog();

    const viewUser = useSelector((state) => state.authentication.viewUser);
    const table = useRef(null);

    const [sendSmsToDriverDialogState, setSendSmsToDriverDialogState] = useState<SendSmsToDriverDialogState>({
        open: false,
        driverId: undefined,
    });
    const [sendSmsToDriversDialogState, setSendSmsToDriversDialogState] = useState<SendSmsToDriversDialogState>({
        open: false,
        driverIds: undefined,
    });
    const [changeMobileNumberDriverDialog, setChangeMobileNumberDriverDialogState] = useState<ChangeMobileNumberDriverDialogState>({
        open: false,
        driverId: undefined,
    });

    const openSendSmsToDriversDialog = async (driverIds: Array<DriverId>) => {
        if (driverIds.length > SmsQuantityLimit) {
            const smsCost = BigNumber(driverIds.length).multipliedBy(0.1).toString();
            const shouldSendSms = await confirmDialog({
                title: translate('Send @smsNumber SMS will cost @smsCost', { smsNumber: driverIds.length, smsCost: formatAsCurrencyNumber(smsCost) }),
                content: translate('Do you want to continue?'),
                acceptButtonText: translate('Yes, continue'),
                cancelButtonText: translate('No, cancel'),
                variant: 'warning',
            });
            if (!shouldSendSms) return;
        }
        setSendSmsToDriversDialogState({ open: true, driverIds: driverIds });
    };

    return (
        <div style={{ position: 'relative' }}>
            <SendSmsToDriverDialog {...sendSmsToDriverDialogState} onClose={() => setSendSmsToDriverDialogState({ open: false, driverId: undefined })} />
            <SendSmsToDriversDialog {...sendSmsToDriversDialogState} onClose={() => setSendSmsToDriversDialogState({ open: false, driverIds: undefined })} />
            <ChangeMobileNumberDriverDialog
                {...changeMobileNumberDriverDialog}
                onClose={() => {
                    setChangeMobileNumberDriverDialogState({ open: false, driverId: undefined });
                    onClickRefresh();
                }}
            />
            <UpdatingContentProgress loading={loading} />
            <MUIDataTable
                /* @ts-ignore */
                ref={table}
                className={classes.table}
                title={title || translate('Drivers')}
                data={drivers.map((driver) => ({
                    driverId: driver.driverId,
                    driverAvailability: translateDriverAvailability(getDriverAvailability(driver)),
                    online: driver.online,
                    positionedAt: formatDateTimeString(driver.positionedAt),
                    locationPermission: driver.locationPermission || '',
                    delivered: driver.delivered || '0',
                    cancelled: driver.cancelled || '0',
                    prio: driver.prio,
                    name: fullName(driver.firstName, driver.lastName),
                    mobileNumber: driver.mobileNumber || '',
                    email: driver.email || '',
                    businessName: driver.businessName || '',
                    claveId: driver.claveId || '',
                    exportError: driver.exportError || '',
                    curp: driver.curp || '',
                    bank: driver.bank || '',
                    clabe: driver.clabe || '',
                    rfc: driver.rfc || '',
                    city: translate(driver.city),
                    positionedInCity: driver.position ? translate(toCity(driver.position)) || translate('Not near city') : translate('Not yet positioned'),
                    fcmPermission: driver.fcmPermission ? 'Yes' : 'No',
                    verified: driver.verified ? 'Verified' : '',
                    appPaymentsEnabled: driver.appPaymentsEnabled ? 'App Payments' : 'No App Payments',
                    cashPaymentsEnabled: driver.cashPaymentsEnabled ? 'Cash Payments' : 'No Cash Payments',
                    removed: driver.removed ? 'Removed' : '',
                    banned: driver.banned ? 'Banned' : '',
                    bannedDevice: driver.bannedDevice ? 'Banned' : 'Not banned',
                    deviceId: driver.deviceId,
                    unBanDevice: driver.bannedDevice ? driver.deviceId : 'Not banned',
                    appVersion: driver.appVersion || '',
                    downloadedAppAt: formatDateTimeString(driver.downloadedAppAt) || '',
                    signedUpAt: formatDateTimeString(driver.signedUpAt) || '',
                    firstDeliveryAt: formatDateTimeString(driver.firstDeliveryAt) || '',
                    lastDeliveryAt: formatDateTimeString(driver.lastDeliveryAt) || '',
                    exportedAt: formatDateTimeString(driver.exportedAt) || '',
                }))}
                columns={[
                    {
                        name: 'driverId',
                        label: ' ',
                        options: {
                            display: 'excluded',
                            filter: false,
                        },
                    },
                    {
                        name: 'driverAvailability',
                        label: translate('Available'),
                        options: {
                            filter: true,
                            searchable: false,
                        },
                    },
                    {
                        name: 'positionedAt',
                        label: translate('Positioned At'),
                        options: {
                            filter: false,
                            searchable: true,
                        },
                    },
                    {
                        name: 'locationPermission',
                        label: translate('Location Permission'),
                        options: {
                            filter: true,
                            searchable: false,
                        },
                    },
                    {
                        name: 'delivered',
                        label: translate('Delivered'),
                        options: {
                            filter: false,
                            searchable: false,
                        },
                    },
                    {
                        name: 'cancelled',
                        label: translate('Cancelled'),
                        options: {
                            filter: false,
                            searchable: false,
                        },
                    },
                    {
                        name: 'prio',
                        label: translate('Prio'),
                        options: {
                            searchable: false,
                        },
                    },
                    {
                        name: 'name',
                        label: translate('Name'),
                        options: {
                            filter: false,
                        },
                    },
                    {
                        name: 'mobileNumber',
                        label: translate('Mobile Number'),
                        options: {
                            filter: false,
                        },
                    },
                    {
                        name: 'email',
                        label: translate('Email'),
                        options: {
                            filter: false,
                        },
                    },
                    {
                        name: 'businessName',
                        label: translate('Business Name/Name'),
                        options: {
                            filter: false,
                        },
                    },
                    {
                        name: 'claveId',
                        label: translate('Clave Id'),
                        options: {
                            filter: false,
                        },
                    },
                    {
                        name: 'exportError',
                        label: translate('Export Error'),
                        options: {
                            searchable: false,
                        },
                    },
                    {
                        name: 'curp',
                        label: translate('CURP'),
                        options: {
                            filter: false,
                        },
                    },
                    {
                        name: 'bank',
                        label: translate('Bank'),
                    },
                    {
                        name: 'clabe',
                        label: translate('CLABE'),
                        options: {
                            filter: false,
                        },
                    },
                    {
                        name: 'rfc',
                        label: translate('RFC'),
                        options: {
                            filter: false,
                        },
                    },
                    {
                        name: 'city',
                        label: translate('City'),
                        options: {
                            filter: true,
                            searchable: false,
                        },
                    },
                    {
                        name: 'positionedInCity',
                        label: translate('Positioned In City'),
                        options: {
                            filter: true,
                            searchable: false,
                        },
                    },
                    {
                        name: 'fcmPermission',
                        label: translate('Push Notification'),
                        options: {
                            searchable: false,
                        },
                    },
                    {
                        name: 'verified',
                        label: translate('Verified'),
                        options: {
                            searchable: false,
                        },
                    },
                    {
                        name: 'appPaymentsEnabled',
                        label: translate('App Payments'),
                        options: {
                            searchable: false,
                        },
                    },
                    {
                        name: 'cashPaymentsEnabled',
                        label: translate('Cash Payments'),
                        options: {
                            searchable: false,
                        },
                    },
                    {
                        name: 'removed',
                        label: translate('Removed'),
                        options: {
                            searchable: false,
                        },
                    },
                    {
                        name: 'banned',
                        label: translate('Banned'),
                        options: {
                            searchable: false,
                        },
                    },
                    {
                        name: 'bannedDevice',
                        label: translate('Banned Device'),
                        options: {
                            searchable: false,
                        },
                    },
                    {
                        name: 'deviceId',
                        label: 'Device Id',
                        options: {
                            filter: false,
                            searchable: true,
                        },
                    },
                    {
                        name: 'unBanDevice',
                        label: translate('Un Ban Device'),
                        options: {
                            searchable: false,
                            customBodyRender: (bannedDevice) => (
                                <Button
                                    size='small'
                                    color='primary'
                                    disabled={bannedDevice === 'Not banned'}
                                    onClick={async (event) => {
                                        event.stopPropagation();
                                        const response = await unBanDeviceApi({ deviceId: bannedDevice });
                                        if (!response.ok) {
                                            console.log('Something went wrong');
                                        } else {
                                            onClickRefresh();
                                        }
                                    }}
                                >
                                    {`${translate('Un Ban Device')}`}
                                </Button>
                            ),
                        },
                    },
                    {
                        name: 'appVersion',
                        label: translate('App Version'),
                        options: {
                            searchable: false,
                        },
                    },
                    {
                        name: 'downloadedAppAt',
                        label: translate('Downloaded'),
                        options: {
                            filter: false,
                            searchable: false,
                        },
                    },
                    {
                        name: 'signedUpAt',
                        label: translate('Signed Up'),
                        options: {
                            filter: false,
                            searchable: false,
                        },
                    },
                    {
                        name: 'firstDeliveryAt',
                        label: translate('First Delivery'),
                        options: {
                            filter: false,
                            searchable: false,
                        },
                    },
                    {
                        name: 'lastDeliveryAt',
                        label: translate('Last Delivery'),
                        options: {
                            filter: false,
                            searchable: false,
                        },
                    },
                    {
                        name: 'exportedAt',
                        label: translate('Exported'),
                        options: {
                            filter: false,
                            searchable: false,
                        },
                    },
                ]}
                options={{
                    responsive: 'standard',
                    tableBodyMaxHeight: '500px',
                    selectableRows: !viewUser ? 'multiple' : 'none',
                    customToolbarSelect: (selectedRows, displayData) => {
                        const selectedRowsData = getSelectedRowsData(selectedRows, displayData);
                        const selectedDriverIds = selectedRowsData.map((d: any) => d[0]);
                        const handleClickEdit = () => {
                            if (selectedDriverIds.length === 1) {
                                history.push({
                                    search: setUrlQueryParameter(CHANGE_DRIVER, selectedDriverIds[0]),
                                });
                            } else {
                                history.push({
                                    pathname: RoutePaths.ADMIN_DRIVERS_CHANGE,
                                    search: history.location.search,
                                    state: {
                                        drivers: selectedRowsData.map((rowData: any) => ({
                                            driverId: rowData[0],
                                            name: rowData[1],
                                        })),
                                    },
                                });
                            }
                        };
                        const handleClickChangeMobileNumber = () => {
                            if (selectedDriverIds.length === 1) {
                                setChangeMobileNumberDriverDialogState({ open: true, driverId: selectedDriverIds[0] });
                            } else {
                                alert('Please only select one driver to be able to use this function');
                            }
                        };
                        const handleClickExportBankAccounts = async () => {
                            const response = await letseatmanagerApiDeprecated.admin.exportDriverAccounts({ driverIds: selectedDriverIds });
                            if (!response.ok) {
                                alertKnownErrorOrSomethingWentWrong(response);
                                return;
                            }
                            const result = response.data;
                            downloadTextInFile(result.accountExport, 'accounts-export.txt');
                            alert(
                                [`exported: ${result.exported}`, `unregistered: ${result.unregistered}`, `banned: ${result.banned}`, `removed: ${result.removed}`, `errors: ${result.errors}`].join(
                                    '\n',
                                ),
                            );
                            onClickRefresh();
                        };
                        const handleClickSendPushNotification = () => {
                            if (selectedDriverIds.length === 1) {
                                history.push({
                                    search: setUrlQueryParameter(SEND_NOTIFICATION, selectedDriverIds[0]),
                                });
                            } else {
                                history.push({
                                    pathname: RoutePaths.ADMIN_DRIVERS_SEND_NOTIFICATIONS,
                                    search: history.location.search,
                                    state: {
                                        drivers: selectedRowsData.map((rowData: any) => ({
                                            driverId: rowData[0],
                                            name: rowData[1],
                                        })),
                                    },
                                });
                            }
                        };
                        const handleClickSendEmail = () => {
                            if (selectedDriverIds.length === 1) {
                                history.push({
                                    search: setUrlQueryParameter(SEND_EMAIL, selectedDriverIds[0]),
                                });
                            } else {
                                history.push({
                                    pathname: RoutePaths.ADMIN_DRIVERS_SEND_EMAILS,
                                    search: history.location.search,
                                    state: {
                                        drivers: selectedRowsData.map((rowData: any) => ({
                                            driverId: rowData[0],
                                            name: rowData[1],
                                        })),
                                    },
                                });
                            }
                        };
                        const handleClickSendSms = () => {
                            if (selectedDriverIds.length === 1) {
                                setSendSmsToDriverDialogState({ open: true, driverId: selectedDriverIds[0] });
                            } else {
                                openSendSmsToDriversDialog(selectedDriverIds);
                            }
                        };

                        return (
                            <CustomToolbarSelect>
                                <SendSmsToolbarButton onClick={handleClickSendSms} />
                                <DownloadCsvToolbarButton onClick={createHandleClickDownloadCsvFunctional(table, getSelectedRows(selectedRows, displayData))} />
                                <ExportToolbarButton onClick={handleClickExportBankAccounts} tooltip={translate('Export As Bank Accounts')} />
                                <ChangeToolbarButton onClick={handleClickEdit} />
                                <MobileToolbarButton onClick={handleClickChangeMobileNumber} />
                                <SendNotificationToolbarButton onClick={handleClickSendPushNotification} />
                                <SendEmailToolbarButton onClick={handleClickSendEmail} />
                            </CustomToolbarSelect>
                        );
                    },
                    onRowClick: (
                        rowData: Array<string>,
                        rowMeta: {
                            dataIndex: number;
                            rowIndex: number;
                        },
                    ) => {
                        const driverId: DriverId = rowData[0] as any;
                        openDriverPageInNewTab(driverId);
                    },
                    filterType: 'multiselect',
                    rowsPerPage: 100,
                    customToolbar: () => {
                        return (
                            <>
                                <RefreshToolbarButton onClick={onClickRefresh} />
                            </>
                        );
                    },
                    onDownload: createFilteredOnDownloadFunctional(table),
                    // filterList: [[], [], [], [], ['MEXICO']],
                }}
            />
        </div>
    );
};

const SmsQuantityLimit = 500;

const useStyles = makeStyles((theme) => ({
    table: {
        whiteSpace: 'nowrap',
        '& tr': { cursor: 'pointer' },
        '& tr:hover': { backgroundColor: 'rgba(0, 0, 0, 0.07) !important' },
    },
    toolbar: {
        paddingRight: theme.spacing(3),
    },
}));

type Props = {
    loading: boolean;
    title?: string;
    drivers: Array<DriverVm>;
    onClickRefresh: any;
};

type DriverVm = {
    driverId: DriverId;
    signedIn: boolean;
    online: boolean;
    delivered: number;
    cancelled: number;
    mobileNumber?: string;
    email?: EmailAddress;
    firstName?: string;
    lastName?: string;
    businessName?: string;
    claveId?: string;
    curp?: string;
    bank?: Bank;
    clabe?: string;
    rfc?: string;
    position?: GeoJsonPoint;
    positionedAt?: Date;
    city?: City;
    fcmPermission?: boolean;
    locationPermission?: string;
    comments?: string;
    verified?: boolean;
    appPaymentsEnabled?: boolean;
    cashPaymentsEnabled?: boolean;
    prio: number;
    removed?: boolean;
    banned?: boolean;
    bannedDevice?: boolean;
    deviceId?: ExternalDeviceId;
    appVersion?: string;
    exportError?: string;
    downloadedAppAt: Date;
    signedUpAt?: Date;
    firstDeliveryAt?: Date;
    lastDeliveryAt?: Date;
    exportedAt?: Date;
};
