import Grid from '@material-ui/core/Grid';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { app2 } from 'src/app2';
import { translate } from 'src/i18n/translate';
import { CustomerVm } from 'src/scenes/letseatadmin/customer/CustomerDetails';
import { RestaurantCustomerOrders } from 'src/scenes/letseatmanager/customer/RestaurantCustomerOrders';
import { fullName } from 'src/utils/customer/fullName';
import { useAction } from 'src/utils/react/useAction';
import { useParams } from 'src/utils/react/useParams';
import { useSelector } from 'src/utils/react/useSelector';

export function CustomerPage(): React.ReactElement {
    const { mobileNumber } = useParams<{
        mobileNumber: string;
    }>();

    const viewUser = useSelector((state) => state.authentication.viewUser);
    const restaurantId = useSelector((state) => state.app.restaurantId);

    const [customer, setCustomer] = useState<CustomerVm>();
    const setTitle = useAction(app2.actions.setTitle);

    useEffect(() => {
        setTitle(`${translate('Customer')} > ${customer ? fullName(customer.firstName, customer.lastName) : mobileNumber}`);
    }, []);

    return (
        <Grid container spacing={5} style={{ paddingBottom: 40 }}>
            <Grid item xs={12}>
                <RestaurantCustomerOrders mobileNumber={mobileNumber} restaurantId={restaurantId} />
            </Grid>
        </Grid>
    );
}
