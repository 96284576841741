import type { PageContextType } from 'src/components/Page';
import { LogEventTypes } from 'src/constants/LogEventType';
import { createUiInteractionLogEvent } from 'src/services/logEvent/createUiInteractionLogEvent';
import { normalizeUiStackTrace } from 'src/services/logEvent/normalizeUiStackTrace';

export function createUserTypedInputLogEvent({ pageContext, label, name, valueMessage }: Params): void {
    createUiInteractionLogEvent({
        logEventType: LogEventTypes.USER_TYPED_INPUT,
        message: `input ${label || name} typed with value ${valueMessage || ''}`,
        details: `${pageContext.stackTrace}/${normalizeUiStackTrace(`input_${label || name}`)}`,
        data: {
            valueMessage,
            inputId: normalizeUiStackTrace(`input_${label || name}`),
            pathId: `${pageContext.stackTrace}/${normalizeUiStackTrace(`input_${label || name}`)}`,
        },
    });
}

type Params = {
    label?: string;
    name: string;
    valueMessage: string;
    pageContext: PageContextType;
};
