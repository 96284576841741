import * as React from 'react';
import { useContext, useRef } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { AutoComplete } from 'src/components/AutoComplete';
import { FormContext } from 'src/components/form/Form';
import type { Variant } from 'src/components/form/FormTypes';
import { getError } from 'src/components/form/utils/getError';
import { translate } from 'src/i18n/translate';

export function FormAutocomplete({ name, label, placeholder, helperText, defaultValue = null, options, disabled, required, onChange: onChangeProp, tooltip }: Props): React.ReactElement {
    const {
        errors,
        control,
        formState: { isSubmitting },
    } = useFormContext();
    const formContext = useContext(FormContext);
    const inputRef = useRef<HTMLInputElement>();

    return (
        <Controller
            render={({ value, onChange, ...props }) => {
                return (
                    <AutoComplete
                        label={label}
                        placeholder={placeholder}
                        helperText={getError(errors, name)?.message ?? helperText}
                        disabled={disabled || formContext.disabled || isSubmitting}
                        tooltip={tooltip}
                        value={value}
                        name={name}
                        onChange={(value: any) => {
                            onChange(value);
                            onChangeProp?.(value);
                        }}
                        options={options}
                        error={!!getError(errors, name)}
                    />
                );
            }}
            control={control}
            name={name}
            defaultValue={defaultValue}
            rules={{
                required: {
                    value: (required as any)?.value ?? required,
                    message: (required as any)?.message ?? translate('This field is required'),
                },
            }}
            onFocus={() => {
                // make focus on error work when disabled={isSubmitting || disabled}
                if (inputRef.current) {
                    inputRef.current.disabled = false;
                    inputRef.current.focus();
                }
            }}
        />
    );
}

export type Props = {
    name: string;
    label?: string;
    placeholder?: string;
    helperText?: string;
    defaultValue?: string | null | undefined;
    options: Array<{
        label: string;
        value: any;
    }>;
    disabled?: boolean;
    required?:
        | boolean
        | {
              value: number;
              message: string;
          };
    variant?: Variant;
    InputProps?: any;
    onChange?: any;
    tooltip?: string;
};
