import { useContext } from 'react';
import { NotificationContext } from 'src/providers/NotificationsProvider';

export function useNotification(): Notification {
    const context = useContext(NotificationContext);

    return context.notification;
}

type Notification = (arg1: { message: string }) => void;
