import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Grid from '@material-ui/core/Grid';
import { Loader } from '@pidedirecto/ui';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { useForm, useFormContext, useWatch } from 'react-hook-form';
import { changePromoCodeApi } from 'src/api/letseatadmin/promoCode/changePromoCodeApi';
import { getPromoCodeApi } from 'src/api/letseatadmin/promoCode/getPromoCodeApi';
import { DeprecatedFormDiscountTypeSelect } from 'src/components/form/DeprecatedFormDiscountTypeSelect';
import { DeprecatedFormPercentNumberField } from 'src/components/form/DeprecatedFormPercentNumberField';
import { Form } from 'src/components/form/Form';
import { FormAdminCustomersAutocomplete } from 'src/components/form/FormAdminCustomersAutocomplete';
import { FormCheckbox } from 'src/components/form/FormCheckbox';
import { FormCompaniesAutocomplete } from 'src/components/form/FormCompaniesAutocomplete';
import { FormCompanyNamesAutocomplete } from 'src/components/form/FormCompanyNamesAutocomplete';
import { FormCurrencyNumberStringField } from 'src/components/form/FormCurrencyNumberStringField';
import { FormDateTimePicker } from 'src/components/form/FormDateTimePicker';
import { FormFixErrorHint } from 'src/components/form/FormFixErrorHint';
import { FormIntegerField } from 'src/components/form/FormIntegerField';
import { FormOrderTypesAutocomplete } from 'src/components/form/FormOrderTypesAutocomplete';
import { FormPaymentMethodsAutocomplete } from 'src/components/form/FormPaymentMethodsAutocomplete';
import { FormPromoTypeSelect } from 'src/components/form/FormPromoTypeSelect';
import { FormRestaurantsAutocomplete } from 'src/components/form/FormRestaurantsAutocomplete';
import { FormRewardTypeSelect } from 'src/components/form/FormRewardTypeSelect';
import { FormSwitch } from 'src/components/form/FormSwitch';
import { FormTextField } from 'src/components/form/FormTextField';
import { PasswordRequiredContent } from 'src/components/PasswordRequiredContent';
import { DiscountTypes } from 'src/constants/DiscountType';
import { translate } from 'src/i18n/translate';
import { appLayout } from 'src/theme/AppLayout';
import type { PromoCodeId } from 'src/types/Id';
import { alertKnownErrorOrSomethingWentWrong } from 'src/utils/alert/alertKnownErrorOrSomethingWentWrong';
import { requireValue } from 'src/utils/require/requireValue';

export function ChangePromoCodeDialog({ open, promoCodeId, onClose, onPromoCodeChanged }: Props): React.ReactElement {
    const form = useForm();
    const {
        formState: { isSubmitting },
    } = form;
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        if (open) load();
    }, [open]);

    const handleClose = () => {
        if (isSubmitting) return;
        onClose();
    };

    const handleExited = () => {
        setLoading(false);
    };

    const load = async () => {
        setLoading(true);
        const response = await getPromoCodeApi({ promoCodeId: requireValue(promoCodeId) });
        setLoading(false);
        if (!response.ok) {
            alertKnownErrorOrSomethingWentWrong(response);
            onClose();
            return;
        }
        const promoCode = response.data;
        form.reset(promoCode);
    };

    const onSubmit = async (form: any) => {
        const response = await changePromoCodeApi({
            promoCodeId: requireValue(promoCodeId),
            code: form.code,
            description: form.description,
            promoType: form.promoType,
            discount: form.discount,
            excludeDeliveryCost: form.excludeDeliveryCost,
            freeDelivery: form.freeDelivery,
            appOnly: form.appOnly,
            webOnly: form.webOnly,
            discountType: form.discountType,
            rewardType: form.rewardType,
            countries: form.countries,
            restaurantIds: form.restaurantIds,
            customerIds: form.customerIds,
            companyIds: form.companyIds,
            companyNames: form.companyNames,
            orderTypes: form.orderTypes,
            paymentMethods: form.paymentMethods,
            requireAtLeastOneCreditCard: form.requireAtLeastOneCreditCard,
            minOrderAmount: form.minOrderAmount,
            maxDiscountAmount: form.maxDiscountAmount,
            maxUsage: form.maxUsage,
            restaurantPaidPercentage: form.restaurantPaidPercentage,
            startsAt: form.startsAt,
            endsAt: form.endsAt,
        });
        if (!response.ok) {
            alertKnownErrorOrSomethingWentWrong(response);
            return;
        }
        onPromoCodeChanged();
        onClose();
    };

    return (
        <Dialog fullWidth scroll='paper' open={open} onClose={handleClose} onExited={handleExited}>
            <PasswordRequiredContent customPassword={'2348'}>
                <Form form={form} onSubmit={onSubmit} style={appLayout.dialog.form}>
                    <DialogTitle>{translate('Change Promo Code').toUpperCase()}</DialogTitle>
                    <DialogContent>
                        <FormContent loading={loading} />
                        <FormFixErrorHint />
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleClose} disabled={isSubmitting}>
                            {translate('Cancel').toUpperCase()}
                        </Button>
                        <Button color='primary' type='submit' disabled={loading || isSubmitting}>
                            {isSubmitting ? translate('Changing').toUpperCase() : translate('Change').toUpperCase()}
                        </Button>
                    </DialogActions>
                </Form>
            </PasswordRequiredContent>
        </Dialog>
    );
}

function FormContent({ loading }: { loading: boolean }) {
    const { watch, control } = useFormContext();
    const discountType = watch('discountType');
    const excludeDeliveryCost = watch('excludeDeliveryCost');
    const freeDelivery = watch('freeDelivery');
    const appOnly = useWatch<boolean>({ name: 'appOnly', control });
    const webOnly = useWatch<boolean>({ name: 'webOnly', control });

    if (loading) {
        return <Loader size={40} loading={true} />;
    }
    return (
        <Grid container spacing={3}>
            <Grid item xs={12}>
                <FormTextField name='code' label={translate('Code')} upperCase required />
            </Grid>
            <Grid item xs={12}>
                <FormTextField
                    name='description'
                    label={translate('Description')}
                    helperText={translate('This description should describe what is included in the promo code and will be visible for the customer when they use the promo code.')}
                />
            </Grid>
            <Grid item xs={12}>
                <FormTextField name='usage' label={translate('Usage')} upperCase disabled />
            </Grid>
            <Grid item xs={12}>
                <FormIntegerField name='maxUsage' label={translate('Max Usage')} min={0} />
            </Grid>
            <Grid item xs={12}>
                <DeprecatedFormPercentNumberField name='restaurantPaidPercentage' label={translate('Restaurant Paid Percentage')} min={0} max={100} />
            </Grid>
            <Grid item xs={12}>
                <FormPromoTypeSelect name='promoType' label={translate('Promo Type')} required />
            </Grid>
            <Grid item xs={12}>
                <FormRewardTypeSelect name='rewardType' label={translate('Reward Type')} required />
            </Grid>
            <Grid item xs={12}>
                <FormAdminCustomersAutocomplete name='customerIds' label={translate('Customers')} placeholder={translate('All')} />
            </Grid>
            <Grid item xs={12}>
                <DeprecatedFormDiscountTypeSelect name='discountType' label={translate('Discount Type')} required />
            </Grid>
            <Grid item xs={12}>
                {discountType === DiscountTypes.AMOUNT && <FormCurrencyNumberStringField name='discount' label={translate('Discount')} required />}
                {discountType === DiscountTypes.PERCENT && <DeprecatedFormPercentNumberField name='discount' label={translate('Discount')} required />}
            </Grid>
            <Grid item xs={12}>
                <FormSwitch name='excludeDeliveryCost' label={translate('Exclude delivery cost')} disabled={freeDelivery} />
            </Grid>
            <Grid item xs={12}>
                <FormSwitch name='freeDelivery' label={translate('Free Delivery')} disabled={excludeDeliveryCost} />
            </Grid>
            <Grid item xs={12} md={6}>
                <FormSwitch name='appOnly' label={translate('App Only')} disabled={webOnly} />
            </Grid>
            <Grid item xs={12} md={6}>
                <FormSwitch name='webOnly' label={translate('Web Only')} disabled={appOnly} />
            </Grid>
            <Grid item xs={12}>
                <FormCurrencyNumberStringField name='minOrderAmount' label={translate('Min Order Amount')} min={0} />
            </Grid>
            {discountType === DiscountTypes.PERCENT && (
                <Grid item xs={12}>
                    <FormCurrencyNumberStringField name='maxDiscountAmount' label={translate('Max Discount Amount')} min={0} />
                </Grid>
            )}
            <Grid item xs={12}>
                <FormRestaurantsAutocomplete name='restaurantIds' label={translate('Restaurants')} placeholder={translate('All')} />
            </Grid>
            <Grid item xs={12}>
                <FormCompaniesAutocomplete name='companyIds' label={translate('Companies')} placeholder={translate('All')} />
            </Grid>
            <Grid item xs={12}>
                <FormCompanyNamesAutocomplete name='companyNames' label={translate('Company Names')} placeholder={translate('All')} />
            </Grid>
            <Grid item xs={12}>
                <FormOrderTypesAutocomplete name='orderTypes' label={translate('Order Types')} placeholder={translate('All')} />
            </Grid>
            <Grid item xs={12}>
                <FormPaymentMethodsAutocomplete name='paymentMethods' label={translate('Payment Methods')} placeholder={translate('All')} />
            </Grid>
            <Grid item xs={12}>
                <FormCheckbox name='requireAtLeastOneCreditCard' label={translate('Require at least one Credit Card')} />
            </Grid>
            <Grid item xs={12}>
                <FormDateTimePicker name='startsAt' label={translate('Starts')} required />
            </Grid>
            <Grid item xs={12}>
                <FormDateTimePicker name='endsAt' label={translate('Ends')} required />
            </Grid>
        </Grid>
    );
}

type Props = {
    open: boolean;
    promoCodeId: PromoCodeId | undefined;
    onClose: any;
    onPromoCodeChanged: any;
};
