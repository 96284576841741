import { makeStyles } from '@material-ui/core';
import * as React from 'react';
import { useState } from 'react';
import { disableDeliveryZoneApi } from 'src/api/letseatmanager/deliveryZones/disableDeliveryZoneApi';
import { enableDeliveryZoneApi } from 'src/api/letseatmanager/deliveryZones/enableDeliveryZoneApi';
import { Button } from 'src/components/Button';
import { SecuredAndSubscribedPage } from 'src/components/page/SecuredAndSubscribedPage';
import { Switch } from 'src/components/Switch';
import { RolePermissions } from 'src/constants/RolePermission';
import { translate } from 'src/i18n/translate';
import { EditIcon } from 'src/icons/EditIcon';
import { EllipseColorIcon } from 'src/icons/EllipseColorIcon';
import { RemoveIcon } from 'src/icons/RemoveIcon';
import { ChangeDeliveryZoneDialog } from 'src/scenes/letseatmanager/deliveryZones/ChangeDeliveryZoneDialog';
import { CreateDeliveryZoneDialog } from 'src/scenes/letseatmanager/deliveryZones/CreateDeliveryZoneDialog';
import { DeliveryZonesGoogleMapDrawer } from 'src/scenes/letseatmanager/deliveryZones/DeliveryZonesGoogleMapDrawer';
import { RemoveDeliveryZoneDialog } from 'src/scenes/letseatmanager/deliveryZones/RemoveDeliveryZoneDialog';
import { useReloadRestaurant } from 'src/services/restaurant/useReloadRestaurant';
import type { GeoJsonPolygonDeprecated } from 'src/types/GeoJsonPolygonDeprecated';
import type { DeliveryZoneVm } from 'src/types/RestaurantVm';
import { toLatLng } from 'src/utils/googlemaps/toLatLng';
import { useSelector } from 'src/utils/react/useSelector';

export function DeliveryZonesPage(): React.ReactElement {
    const classes = useStyles();
    const { reloadRestaurant } = useReloadRestaurant();

    const [createDeliveryZoneDialogState, setCreateDeliveryZoneDialogState] = useState<{
        open: boolean;
        deliveryZoneArea?: GeoJsonPolygonDeprecated;
    }>({ open: false });
    const [changeDeliveryZoneDialogState, setChangeDeliveryZoneDialogState] = useState<{
        open: boolean;
        deliveryZone?: DeliveryZoneVm;
    }>({ open: false });
    const [removeDeliveryZoneDialogState, setRemoveDeliveryZoneDialogState] = useState<{
        open: boolean;
        deliveryZone?: DeliveryZoneVm;
    }>({ open: false });
    const [loading, setLoading] = useState(false);

    const restaurant = useSelector((state) => state.app.restaurant);
    const restaurantId = useSelector((state) => state.app.restaurantId);
    const deliveryZones = useSelector((state) => state.app.restaurant.deliveryZones ?? []);

    const deliveryZonesEnabled = deliveryZones?.filter((deliveryZone: any) => deliveryZone.enabled);

    const handleDrawDeliveryZoneArea = (deliveryZoneArea: any) => setCreateDeliveryZoneDialogState({ open: true, deliveryZoneArea });
    const handleClickChange = (deliveryZone: any) => setChangeDeliveryZoneDialogState({ open: true, deliveryZone });
    const handleClickRemove = (deliveryZone: any) => setRemoveDeliveryZoneDialogState({ open: true, deliveryZone });
    const handleCloseCreateDialog = () => setCreateDeliveryZoneDialogState({ open: false });
    const handleCloseChangeDialog = () => setChangeDeliveryZoneDialogState({ open: false });
    const handleCloseRemoveDialog = () => setRemoveDeliveryZoneDialogState({ open: false });
    const handleChangeDeliveryZoneStatus = async (value: any, deliveryZoneId: any) => {
        setLoading(true);
        if (value) {
            await enableDeliveryZoneApi({ restaurantId, deliveryZoneId });
            await reloadRestaurant(restaurantId);
            setLoading(false);
            return;
        }
        await disableDeliveryZoneApi({ restaurantId, deliveryZoneId });
        await reloadRestaurant(restaurantId);
        setLoading(false);
    };

    return (
        <SecuredAndSubscribedPage rolePermission={RolePermissions.DELIVERY_ZONES_PAGE} title={translate('Delivery Zones')}>
            <CreateDeliveryZoneDialog open={createDeliveryZoneDialogState.open} onClose={handleCloseCreateDialog} deliveryZoneArea={createDeliveryZoneDialogState.deliveryZoneArea} />
            <ChangeDeliveryZoneDialog open={changeDeliveryZoneDialogState.open} onClose={handleCloseChangeDialog} deliveryZone={changeDeliveryZoneDialogState.deliveryZone} />
            <RemoveDeliveryZoneDialog open={removeDeliveryZoneDialogState.open} onClose={handleCloseRemoveDialog} deliveryZone={removeDeliveryZoneDialogState.deliveryZone} />
            <div className={classes.container}>
                <div className={classes.toolbarNav}>
                    <div className={classes.title}>{translate('Current zones')}</div>
                    {!deliveryZones.length && <p>{translate('Draw in the map to start assigning your delivery zones')}</p>}
                    {deliveryZones.length > 0 &&
                        deliveryZones?.map((deliveryZone: any) => (
                            <div className={classes.flexItemZone}>
                                <div className={classes.textItemZone}>
                                    <EllipseColorIcon color={deliveryZone.color} />
                                    <p>{deliveryZone.name}</p>
                                    <p className={classes.priceText}>${deliveryZone.price}</p>
                                </div>
                                <div className={classes.textItemZone}>
                                    <Switch name={'enable'} value={deliveryZone.enabled} onChange={(e: any) => handleChangeDeliveryZoneStatus(e, deliveryZone.deliveryZoneId)} disabled={loading} />
                                    <Button icon onClick={() => handleClickChange(deliveryZone)} disabled={loading}>
                                        <EditIcon />
                                    </Button>
                                    <Button icon onClick={() => handleClickRemove(deliveryZone)} disabled={loading}>
                                        <RemoveIcon color={'#E37878'} />
                                    </Button>
                                </div>
                            </div>
                        ))}
                </div>
                <div className={classes.mapContainer}>
                    <DeliveryZonesGoogleMapDrawer polygons={deliveryZonesEnabled} onDrawDeliveryZoneArea={handleDrawDeliveryZoneArea} center={toLatLng(restaurant.location)} />
                </div>
            </div>
        </SecuredAndSubscribedPage>
    );
}

const useStyles = makeStyles((theme) => ({
    title: {
        fontFamily: theme.typography.medium,
        fontSize: 20,
        fontWeight: 600,
        color: '#000000',
        textAlign: 'center',
        [theme.breakpoints.up('sm')]: {
            textAlign: 'left',
        },
    },
    colors: {
        padding: '0px !important',
    },
    container: {
        width: '100%',
        display: 'flex',
        flexDirection: 'column-reverse',
        justifyContent: 'space-between',
        [theme.breakpoints.up('sm')]: {
            flexDirection: 'row',
        },
    },
    mapContainer: {
        width: '100%',
        height: '159px',
        [theme.breakpoints.up('sm')]: {
            width: '500px',
            height: '481px',
        },
        [theme.breakpoints.up('md')]: {
            width: '945px',
            height: '681px',
        },
    },
    toolbarNav: {
        width: '100%',
        maxWidth: '100%',
        marginTop: 12,
        [theme.breakpoints.up('sm')]: {
            maxWidth: 500,
        },
    },
    flexItemZone: {
        width: '100%',
        display: 'flex',
        justifyContent: 'space-between',
        borderBottom: '1px #d9d9d9 solid',
    },
    textItemZone: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
        fontSize: 14,
        fontFamily: theme.typography.regular,
    },
    priceText: {
        marginLeft: 12,
        fontSize: 14,
        fontWeight: 600,
    },
}));
