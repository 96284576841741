import { useRef } from 'react';
import { SECONDS } from 'src/constants/TimeUnit';
import { getUiErrorLogEventsInLocalStorage } from 'src/localStorage/logEvent/getUiErrorLogEventsInLocalStorage';
import { getUiInteractionLogEventsInLocalStorage } from 'src/localStorage/logEvent/getUiInteractionLogEventsInLocalStorage';
import { setUiErrorLogEventsInLocalStorage } from 'src/localStorage/logEvent/setUiErrorLogEventsInLocalStorage';
import { setUiInteractionLogEventsInLocalStorage } from 'src/localStorage/logEvent/setUiInteractionLogEventsInLocalStorage';
import { createOfflineUiErrorLogEvent } from 'src/services/logEvent/createOfflineUiErrorLogEvent';
import { createOfflineUiInteractionLogEvent } from 'src/services/logEvent/createOfflineUiInteractionLogEvent';
import { isTabHidden } from 'src/utils/isTabHidden';
import { wait } from 'src/utils/wait';

export function useSyncOfflineUiLogEvents(): [any] {
    const secondsToSyncOfflineUiLogEvents = 6 * SECONDS;

    const hasAlreadyStartedSyncing = useRef(false);

    const handleSyncUiLogEvents = () => {
        if (hasAlreadyStartedSyncing.current) return;
        hasAlreadyStartedSyncing.current = true;

        syncUiLogEvents();
    };

    const syncUiLogEvents = async () => {
        await wait(secondsToSyncOfflineUiLogEvents);
        if (isTabHidden()) {
            syncUiLogEvents();
            return;
        }
        await Promise.all([syncUiInteractionLogEvents(), syncUiErrorLogEvents()]);

        syncUiLogEvents();
    };

    const syncUiInteractionLogEvents = async () => {
        const uiLogEvents = getUiInteractionLogEventsInLocalStorage();
        if (!uiLogEvents.length) return;

        const response = await createOfflineUiInteractionLogEvent(uiLogEvents.pop()!);
        // In case SERVER_ERROR let's ignore, since logEvents can get stuck
        if (response.ok || response.problem === 'SERVER_ERROR') setUiInteractionLogEventsInLocalStorage(uiLogEvents);
    };
    const syncUiErrorLogEvents = async () => {
        const uiLogEvents = getUiErrorLogEventsInLocalStorage();
        if (!uiLogEvents.length) return;

        const response = await createOfflineUiErrorLogEvent(uiLogEvents.pop()!);
        // In case SERVER_ERROR let's ignore, since logEvents can get stuck
        if (response.ok || response.problem === 'SERVER_ERROR') setUiErrorLogEventsInLocalStorage(uiLogEvents);
    };

    return [handleSyncUiLogEvents];
}
