import { letseatadminApiMethod } from 'src/api/letseatadminApi';
import type { ApiSauceResponse } from 'src/api/types/ApiSauceResponse';
import type { RestaurantAddress } from 'src/types/RestaurantAddress';

export async function getUberDaasCoverageApi(request: GetUberDaasCoverageApiRequest): ApiSauceResponse<GetUberDaasCoverageApiResponse> {
    return letseatadminApiMethod('delivery/getUberDaasCoverageApi', request);
}

export type GetUberDaasCoverageApiRequest = {
    pickupAddress: RestaurantAddress;
    dropoffAddress: RestaurantAddress;
};

type GetUberDaasCoverageApiResponse = {
    deliveryAvailable: boolean;
    message: string;
    deliveryCost?: string;
    deliveryDuration?: string;
};
