import { createMuiTheme } from '@material-ui/core/styles';
import { TypeText } from '@material-ui/core/styles/createPalette';
import { ThemeType, ThemeTypes } from 'src/constants/ThemeType';

export const primitiveColors = {
    purple100: '#f9f6fd',
    purple200: '#ebd9ff',
    purple300: '#dab7ff',
    purple400: '#c48dff',
    purple500: '#b674ff',
    purple600: '#a34eff',
    purple700: '#8d25ff',
    purple800: '#7218d4',
    purple900: '#6114b4',
    purple1000: '#470f84',
    neutral100: '#ffffff',
    neutral200: '#f9f9f9',
    neutral300: '#ececec',
    neutral400: '#eef2f5',
    neutral500: '#d9d9d9',
    neutral600: '#9ba1aa',
    neutral700: '#616b79',
    neutral800: '#2B2F3A',
    neutral900: '#221f2b',
    neutral1000: '#1D1D2E',
    neutral1100: '#1F222B',
    neutral1200: '#000000',
    blue100: '#EAF7FD',
    blue200: '#B4CFE8',
    blue300: '#7D8FFF',
    blue400: '#6D84C7',
    blue500: '#458FFF',
    blue600: '#122279',
    blue700: '#0A0050',
    blue800: '#101F49',
    red100: '#f7e6e6',
    red200: '#e32f45',
    red300: '#a00d1f',
    yellow100: '#fbf3da',
    yellow200: '#f6e3a7',
    yellow300: '#a57805',
    orange100: '#ffefdc',
    orange200: '#ffcb8d',
    orange300: '#fc9b41',
    orange400: '#F57F11',
    pink500: '#ef006d',
    green100: '#ebfefe',
    green200: '#b7ecea',
    green300: '#89d5d2',
    green400: '#5eaca3',
    green500: '#63d6b3',
    green600: '#06B7A2',
    green700: '#0fa489',
    green800: '#1b5764',
} as const;

const brandMainColor = primitiveColors.purple700;

const commonTypography = {
    htmlFontSize: 10,
    fontFamily: 'Roboto, sans-serif',
    bold: 'PoppinsBold',
    semiBold: 'PoppinsSemiBold',
    regular: 'PoppinsRegular',
    medium: 'PoppinsMedium',
    light: 'PoppinsLight',
} as const;

const createSurfacePalette = (mode: ThemeType): SurfacePalette => {
    const isDarkMode = mode === ThemeTypes.DARK;

    return {
        primary: isDarkMode ? primitiveColors.neutral800 : primitiveColors.neutral100,
        secondary: isDarkMode ? primitiveColors.neutral1000 : primitiveColors.neutral300,
        tertiary: isDarkMode ? primitiveColors.neutral1100 : primitiveColors.neutral400,
        contrast: primitiveColors.neutral600,
        heavyContrast: primitiveColors.neutral700,
        invert: primitiveColors.neutral900,
        brand: primitiveColors.purple200,
        brandSecondary: primitiveColors.purple300,
        brandContrast: brandMainColor,
        brandHeavyContrast: isDarkMode ? primitiveColors.purple1000 : primitiveColors.purple1000,
        success: primitiveColors.green200,
        successSecondary: primitiveColors.green600,
        danger: primitiveColors.red100,
        info: isDarkMode ? primitiveColors.blue200 : primitiveColors.blue100,
        warning: primitiveColors.orange100,
    };
};

const createTextPalette = (mode: ThemeType): Partial<TypeText> => {
    const isDarkMode = mode === ThemeTypes.DARK;

    return {
        primary: isDarkMode ? primitiveColors.neutral100 : primitiveColors.neutral900,
        secondary: isDarkMode ? primitiveColors.neutral500 : primitiveColors.neutral700,
        contrast: isDarkMode ? primitiveColors.neutral600 : primitiveColors.neutral600,
        invert: primitiveColors.neutral100,
        brand: isDarkMode ? primitiveColors.purple300 : brandMainColor,
        success: primitiveColors.green800,
        info: isDarkMode ? primitiveColors.blue800 : primitiveColors.blue600,
        warning: primitiveColors.orange400,
        danger: primitiveColors.red200,
    };
};

const createBorderPalette = (mode: ThemeType): BorderPalette => {
    const isDarkMode = mode === ThemeTypes.DARK;

    return {
        primary: isDarkMode ? primitiveColors.neutral600 : primitiveColors.neutral500,
        secondary: isDarkMode ? primitiveColors.neutral600 : primitiveColors.neutral600,
        tertiary: isDarkMode ? primitiveColors.neutral400 : primitiveColors.neutral400,
        invert: isDarkMode ? primitiveColors.neutral800 : primitiveColors.neutral100,
        brand: primitiveColors.purple200,
        brandContrast: isDarkMode ? primitiveColors.purple200 : brandMainColor,
        success: primitiveColors.green800,
        info: primitiveColors.blue500,
        warning: primitiveColors.orange400,
        danger: primitiveColors.red200,
    };
};

const createIconPalette = (mode: ThemeType): IconPalette => {
    const isDarkMode = mode === ThemeTypes.DARK;

    return {
        primary: primitiveColors.neutral700,
        invert: primitiveColors.neutral100,
        brand: isDarkMode ? primitiveColors.purple200 : brandMainColor,
    };
};

const createTheme = (mode: ThemeType) => {
    const surfacePalette = createSurfacePalette(mode);
    const textPalette = createTextPalette(mode);
    const borderPalette = createBorderPalette(mode);
    const iconsPalette = createIconPalette(mode);

    return createMuiTheme({
        typography: commonTypography,
        palette: {
            surface: surfacePalette,
            text: textPalette,
            border: borderPalette,
            icons: iconsPalette,
            primary: {
                light: '#EBFFF9',
                main: surfacePalette.brandContrast,
                mainHover: surfacePalette.brandHeavyContrast,
                dark: surfacePalette.brandHeavyContrast,
                contrastText: '#fff',
            },
            secondary: {
                light: '#fafbfb',
                main: '#ffffff',
                dark: '#e5e5e5',
                contrastText: '#40464d',
            },
            outlined: {
                mainHover: '#EBFFF9',
            },
            background: {
                default: '#f2f2f2',
                light: '#ffffff',
                medium: '#dbffe9',
                dark: '#64d6b3',
            } as any,
            gray: {
                light: '#969DA6',
                dark: '#0D3037',
                one: '#232933',
                two: '#616B79',
                three: '#7E8EA1',
                four: '#8C9196',
                five: '#D9D9D9',
                six: '#F3F3F4',
            },
            green: {
                one: '#0D3037',
                two: '#0C8F8B',
                three: '#A6F7E4',
                four: '#E7FDF9',
            },
            blue: {
                one: '#06347A',
                two: '#458FFF',
                three: '#B4CFE8',
                four: '#EAF7FD',
            },
        },
    });
};

export const darkTheme = createTheme(ThemeTypes.DARK);
export const theme = createTheme(ThemeTypes.LIGHT);

// add TypeScript support for custom theme props by extending Mui types, see https://v4.mui.com/guides/typescript/#customization-of-theme
declare module '@material-ui/core/styles/createTypography' {
    interface Typography {
        bold: string;
        semiBold: string;
        regular: string;
        medium: string;
        light: string;
    }

    interface TypographyOptions {
        bold: string;
        semiBold: string;
        regular: string;
        medium: string;
        light: string;
    }
}

// add TypeScript support for custom theme props by extending Mui types, see https://v4.mui.com/guides/typescript/#customization-of-theme
declare module '@material-ui/core/styles/createPalette' {
    export interface Palette {
        surface: SurfacePalette;
        border: BorderPalette;
        icons: IconPalette;
        outlined: Outlined;
        gray: Gray;
        green: Green;
        blue: Blue;
    }

    interface PaletteOptions {
        surface: SurfacePalette;
        border: BorderPalette;
        icons: IconPalette;
        outlined: Outlined;
        gray: Gray;
        green: Green;
        blue: Blue;
    }

    interface PaletteColor {
        mainHover?: string;
    }

    interface SimplePaletteColorOptions {
        mainHover?: string;
    }

    interface TypeText {
        contrast: string;
        invert: string;
        brand: string;
        success: string;
        info: string;
        warning: string;
        danger: string;
    }

    type Outlined = {
        mainHover: string;
    };
}

type SurfacePalette = {
    primary: string;
    secondary: string;
    tertiary: string;
    contrast: string;
    heavyContrast: string;
    invert: string;
    brand: string;
    brandSecondary: string;
    brandContrast: string;
    brandHeavyContrast: string;
    success: string;
    successPrimary?: string;
    successSecondary: string;
    danger: string;
    info: string;
    warning: string;
};

type BorderPalette = {
    primary: string;
    secondary: string;
    tertiary: string;
    invert: string;
    brand: string;
    brandContrast: string;
    success: string;
    info: string;
    warning: string;
    danger: string;
};

type IconPalette = {
    primary: string;
    invert: string;
    brand: string;
};

type Gray = {
    light: string;
    dark: string;
    one: string;
    two: string;
    three: string;
    four: string;
    five: string;
    six: string;
};

type Green = {
    one: string;
    two: string;
    three: string;
    four: string;
};

type Blue = {
    one: string;
    two: string;
    three: string;
    four: string;
};
