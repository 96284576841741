import { useEffect } from 'react';
import { WebSocketEventTypes } from 'src/constants/WebSocketEventType';
import { translate } from 'src/i18n/translate';
import { useLoadMenuItems } from 'src/services/menuItem/useLoadMenuItems';
import { useNotification } from 'src/services/notification/useNotification';
import type { MenuId, MenuItemId, RestaurantId } from 'src/types/Id';
import type { WebSocketEvent } from 'src/types/WebSocketEvent';
import { useSelector } from 'src/utils/react/useSelector';
import { WebSocketEvents } from 'src/utils/webSocket/WebSocketEvents';

export function useMenuItemSubscriptionUpdates() {
    const notification = useNotification();
    const loadMenuItems = useLoadMenuItems();

    const restaurantId = useSelector((state) => state.app.restaurantId);

    useEffect(() => {
        const menuItemCreatedSyncWebSocketEvent = WebSocketEvents.addEventListener(WebSocketEventTypes.MENU_ITEM_CREATED, handleMenuItemsWebSocketEvents);
        const menuItemChangedSyncWebSocketEvent = WebSocketEvents.addEventListener(WebSocketEventTypes.MENU_ITEM_CHANGED, handleMenuItemsWebSocketEvents);
        const menuItemRemovedSyncWebSocketEvent = WebSocketEvents.addEventListener(WebSocketEventTypes.MENU_ITEM_REMOVED, handleMenuItemsWebSocketEvents);
        const menuProductInfoChangedSyncWebSocketEvent = WebSocketEvents.addEventListener(WebSocketEventTypes.MENU_PRODUCT_INFO_CHANGED, handleMenuItemsWebSocketEvents);

        return () => {
            menuItemCreatedSyncWebSocketEvent.remove();
            menuItemChangedSyncWebSocketEvent.remove();
            menuItemRemovedSyncWebSocketEvent.remove();
            menuProductInfoChangedSyncWebSocketEvent.remove();
        };
    }, [restaurantId]);

    const handleMenuItemsWebSocketEvents = async (event: WebSocketEvent<MenuItemWebSocketEventResponse>) => {
        if (restaurantId !== event.data?.restaurantId) return;
        let message;
        const defaultMessage = translate('Synchronizing menu items');

        switch (event.webSocketEventType) {
            case WebSocketEventTypes.MENU_ITEM_CREATED:
                message = translate(`Menu item @menuItemName created`, { menuItemName: event.data?.menuItemName });
                break;
            case WebSocketEventTypes.MENU_ITEM_CHANGED:
                message = translate(`Menu item @menuItemName changed`, { menuItemName: event.data?.menuItemName });
                break;
            case WebSocketEventTypes.MENU_ITEM_REMOVED:
                message = translate(`Menu item @menuItemName removed`, { menuItemName: event.data?.menuItemName });
                break;
            case WebSocketEventTypes.MENU_PRODUCT_INFO_CHANGED:
                message = translate(`Menu item @menuItemName changed`, { menuItemName: event.data?.menuItemName });
                break;
            default:
                message = defaultMessage;
                break;
        }

        notification({ message: message });
        await loadMenuItems();
    };
}

type MenuItemWebSocketEventResponse = {
    restaurantId: RestaurantId;
    menuItemId: MenuItemId;
    menuId?: MenuId;
    menuName?: string;
    menuItemName: string;
};
