export const PaymentMethods = {
    CREDIT_CARD: 'CREDIT_CARD',
    CARD_ON_DELIVERY: 'CARD_ON_DELIVERY',
    CASH: 'CASH',
    PAYROLL: 'PAYROLL',
    PENDING: 'PENDING',
    PAYMENT_LINK: 'PAYMENT_LINK',
    CREDITS: 'CREDITS',
    TRANSFER: 'TRANSFER',
    PAYMENT_TERMINAL: 'PAYMENT_TERMINAL',
} as const;

export type PaymentMethod = (typeof PaymentMethods)[keyof typeof PaymentMethods];
