import { CustomerDisplayScreenOrderSteps } from 'src/constants/CustomerDisplayScreenOrderStep';
import { posReducer } from 'src/reducers/posReducer';
import { useSendOrderUpdateToCustomerDisplayScreen } from 'src/services/pos/useSendOrderUpdateToCustomerDisplayScreen';
import { useAction } from 'src/utils/react/useAction';

export function useSetPayingOrder(): (paying: boolean) => void {
    const setPaying = useAction(posReducer.actions.setPaying);
    const sendOrderUpdateToCustomerDisplayScreen = useSendOrderUpdateToCustomerDisplayScreen();

    const setPayingOrder = (paying: boolean) => {
        setPaying(paying);
        if (paying) {
            sendOrderUpdateToCustomerDisplayScreen(CustomerDisplayScreenOrderSteps.PAYING);
        } else {
            sendOrderUpdateToCustomerDisplayScreen(CustomerDisplayScreenOrderSteps.ORDER_SUMMARY);
        }
    };
    return setPayingOrder;
}
