import { getCountryCallingCode } from 'libphonenumber-js';
import * as React from 'react';
import { memo } from 'react';
import { PhoneNumberCustomerAutoComplete } from 'src/components/pos/PhoneNumberCustomerAutoComplete';
import { translate } from 'src/i18n/translate';
import { usePosCustomerActions, usePosCustomerStore } from 'src/modules/pos/customer/posCustomerStore';
import { posReducer } from 'src/reducers/posReducer';
import { isValidPhoneNumber } from 'src/utils/is/isValidPhoneNumber';
import { isDeliveryOrder } from 'src/utils/order/isDeliveryOrder';
import { useAction } from 'src/utils/react/useAction';
import { useSelector } from 'src/utils/react/useSelector';

export const PosPhoneNumberCustomerAutoComplete: React.ComponentType<any> = memo<any>(function PosPhoneNumberCustomerAutoComplete(): React.ReactElement {
    const country = useSelector((state) => state.app.restaurant.country);

    const mobileNumber = usePosCustomerStore((state) => state.mobileNumber.value);

    const orderType = useSelector((state) => state.pos.orderType);

    const setError = usePosCustomerActions((actions) => actions.setError);
    const setCustomerFirstName = usePosCustomerActions((actions) => actions.setCustomerFirstName);
    const setCustomerLastName = usePosCustomerActions((actions) => actions.setCustomerLastName);
    const setCustomerEmail = usePosCustomerActions((actions) => actions.setCustomerEmail);
    const setCustomerNote = usePosCustomerActions((actions) => actions.setCustomerNote);
    const setCustomerMobileNumber = usePosCustomerActions((actions) => actions.setCustomerMobileNumber);

    const setCustomerId = useAction(posReducer.actions.setCustomerId);

    const customerPhoneNumberRequired = isDeliveryOrder(orderType);

    const handleSelectCustomerMobileNumber = (restaurantCustomer: any) => {
        setCustomerId(restaurantCustomer.customerId);
        setCustomerFirstName(restaurantCustomer?.firstName ?? restaurantCustomer?.name);
        setCustomerLastName(restaurantCustomer?.lastName);
        setCustomerEmail(restaurantCustomer?.email);
        setCustomerNote(restaurantCustomer?.note);
        setCustomerMobileNumber(restaurantCustomer?.note);
    };

    const onChangeCustomerPhoneNumber = (value: string) => {
        setCustomerMobileNumber(value);

        if (defaultPhoneNumber === value) {
            setError('mobileNumber', undefined);
            return;
        }

        setError(
            'mobileNumber',
            !isValidPhoneNumber(mobileNumber) ? translate('are you sure this is a correct phone number?') : customerPhoneNumberRequired && !value ? translate('This field is required') : undefined,
        );
    };

    const defaultPhoneNumber = mobileNumber ? mobileNumber : `+${getCountryCallingCode(country)}`;

    return (
        <PhoneNumberCustomerAutoComplete
            value={mobileNumber}
            onSelect={handleSelectCustomerMobileNumber}
            onChange={onChangeCustomerPhoneNumber}
            name='mobileNumber'
            label={translate('Phone Number')}
            defaultValue={defaultPhoneNumber}
            required={customerPhoneNumberRequired}
        />
    );
});
