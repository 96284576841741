import * as React from 'react';
import { memo } from 'react';
import TimeLineBarChart, {
    Color,
    PeriodScale,
    pushData,
    XAxisScale,
    xAxisTooltipFormat,
    yAxisFormat,
    yAxisLegend,
    YAxisScale,
    yAxisTooltipFormat,
} from 'src/scenes/letseatadmin/statistics/TimeLineBarChart';
import type { StatisticsVm } from 'src/scenes/letseatadmin/StatisticsPage';

type Props = {
    statistics?: StatisticsVm;
    period: PeriodScale;
    yAxis: YAxisScale;
    xAxis: XAxisScale;
};

export const RefundsChart: React.ComponentType<Props> = memo<Props>(function RefundsChart({ statistics, period, yAxis, xAxis }: Props): React.ReactElement | null {
    if (!statistics) {
        return null;
    }

    return (
        <TimeLineBarChart
            data={statisticsToData(statistics, period, xAxis, yAxis)}
            period={period}
            yAxis={yAxis}
            xAxis={xAxis}
            colors={[Color.LETS_EAT, Color.GREEN, Color.BLUE_LIGHT]}
            yAxisLegend={yAxisLegend(yAxis)}
            yAxisFormat={yAxisFormat(yAxis)}
            yAxisTooltipFormat={yAxisTooltipFormat(yAxis)}
            xAxisTooltipFormat={xAxisTooltipFormat(xAxis)}
        />
    );
});

function statisticsToData(statistics: StatisticsVm, period: PeriodScale, xAxis: XAxisScale, yAxis: YAxisScale) {
    return statistics.reduce(
        (chartData, statistic) => {
            pushData(chartData[0].data, statistic.date, statistic.order.refunded.credits, period, yAxis, xAxis);
            pushData(chartData[1].data, statistic.date, statistic.order.refunded.payroll, period, yAxis, xAxis);
            pushData(chartData[2].data, statistic.date, statistic.order.refunded.card, period, yAxis, xAxis);
            return chartData;
        },
        [
            { id: 'Credits', data: [] },
            { id: 'Payroll', data: [] },
            { id: 'Card', data: [] },
        ],
    );
}
