import { makeStyles } from '@material-ui/core';
import * as React from 'react';
import { getCoursesApi } from 'src/api/letseatmanager/course/getCoursesApi';
import { Button } from 'src/components/Button';
import { translate } from 'src/i18n/translate';
import { getPosInterfaceLargerEnabledRestaurantSettingInLocalStorage } from 'src/localStorage/getPosInterfaceLargerEnabledRestaurantSettingInLocalStorage';
import { posReducer } from 'src/reducers/posReducer';
import { isEatHereOrder } from 'src/utils/order/isEatHereOrder';
import { useAction } from 'src/utils/react/useAction';
import { useLoadApi } from 'src/utils/react/useLoadApi';
import { useSelector } from 'src/utils/react/useSelector';

export function PosTakeOrderByCourseButton({ disabled }: Props): React.ReactElement | null {
    const classes = useStyles();

    const posInterfaceLargerEnabled = getPosInterfaceLargerEnabledRestaurantSettingInLocalStorage();

    const orderType = useSelector((state) => state.pos.orderType);
    const posTakeOrderByCourseEnabled = useSelector((state) => state.app.restaurant.posTakeOrderByCourseEnabled);
    const restaurantId = useSelector((state) => state.app.restaurantId);
    const customers = useSelector((state) => state.pos.customers);

    const setTakeOrderByCourse = useAction(posReducer.actions.setTakeOrderByCourse);

    const [loadingCourses, courses] = useLoadApi(getCoursesApi, { restaurantId }, { initialValue: [], requiredValues: [posTakeOrderByCourseEnabled] });
    const showTakeOrderByCourseOption = isEatHereOrder(orderType) && courses?.length && posTakeOrderByCourseEnabled && !customers?.length;

    const handleTakeOrderByCourse = async () => {
        setTakeOrderByCourse({ open: true, courses });
    };

    if (!showTakeOrderByCourseOption || loadingCourses) return null;

    return (
        <Button larger={posInterfaceLargerEnabled} classes={{ button: classes.basicButton }} secondary onClick={handleTakeOrderByCourse} disabled={disabled}>
            {translate('Take order by course')}
        </Button>
    );
}

const useStyles = makeStyles((theme) => ({
    basicButton: {
        width: '100%',
        height: 50,
        color: theme.palette.text.secondary,
        '&:hover': {
            cursor: 'pointer',
        },
    },
}));

type Props = {
    disabled?: boolean;
};
