import type { SavePosOrderApiRequest } from 'src/api/letseatmanager/pos/savePosOrderApi';
import { updatePendingOrderApi, UpdatePendingOrderApiRequest } from 'src/api/letseatmanager/pos/updatePendingOrderApi';
import type { ApiResponse } from 'src/api/types/ApiSauceResponse';
import { KitchenStatuses } from 'src/constants/KitchenStatus';
import { OrderStatuses } from 'src/constants/OrderStatus';
import { PickupTimeTypes } from 'src/constants/PickupTimeType';
import { posReducer } from 'src/reducers/posReducer';
import { getCashAmountPaid } from 'src/services/pos/getCashAmountPaid';
import { useOrderPaymentMethod } from 'src/services/pos/useOrderPaymentMethod';
import { CartItemVm } from 'src/types/CartItemVm';
import type { OrderVm } from 'src/types/OrderVm';
import { PosPayment } from 'src/types/PosPayment';
import { removeDuplicates } from 'src/utils/array/removeDuplicates';
import { isClosedOrder } from 'src/utils/order/isClosedOrder';
import { mapCustomerToOrderCustomer } from 'src/utils/order/mapCustomerToOrderCustomer';
import { isCardPayment } from 'src/utils/paymentMethod/isCardPayment';
import { isCashPayment } from 'src/utils/paymentMethod/isCashPayment';
import { mapCartItemToOrderItem } from 'src/utils/pos/mapCartItemToOrderItem';
import { mapPosCourseToOrderCourse } from 'src/utils/pos/mapPosCourseToOrderCourse';
import { useAction } from 'src/utils/react/useAction';
import { useMappedOrderPaymentsVm } from 'src/utils/react/useMappedOrderPaymentsVm';
import { useMapPosPayments } from 'src/utils/react/useMapPosPayments';
import { useMapPosTips } from 'src/utils/react/useMapPosTips';
import { useSelector } from 'src/utils/react/useSelector';

export function useUpdatePosPendingOrder(): {
    updatePosPendingOrder: (arg1: UpdatePendingOrderParams) => Promise<UpdatePendingOrderResult>;
} {
    const mappedPosPayments = useMapPosPayments();
    const mappedPayments = useMappedOrderPaymentsVm();
    const mappedPosTips = useMapPosTips();
    const orderPaymentMethod = useOrderPaymentMethod();

    const restaurantId = useSelector((state) => state.app.restaurantId);
    const orderType = useSelector((state) => state.pos.orderType);
    const items = useSelector((state) => state.pos.items);
    const cancelledItems = useSelector((state) => state.pos.cancelledItems);
    const discount = useSelector((state) => state.pos.discount);
    const pickupTimeType = useSelector((state) => state.pos.pickupTimeType);
    const pickupTime = useSelector((state) => state.pos.pickupTime);
    const pendingOrder = useSelector((state) => state.pos.pendingOrder);
    const payments = useSelector((state) => state.pos.payments);
    const notes = useSelector((state) => state.pos.notes);
    const promoCode = useSelector((state) => state.pos.promoCode);
    const customerId = useSelector((state) => state.pos.customerId);
    const useLetsEatCredits = useSelector((state) => state.pos.useLetsEatCredits);
    const customers = useSelector((state) => state.pos.customers);
    const courses = useSelector((state) => state.pos.ongoingCourses);
    const numberOfCustomers = useSelector((state) => state.pos.numberOfCustomers);
    const table = useSelector((state) => state.pos.table);
    const usedPromotions = useSelector((state) => state.pos.payment?.usedPromotions);

    const setDisabledPosPaymentActions = useAction(posReducer.actions.setDisabledPosPaymentActions);

    const isOnlyPaidInCash = payments?.every((payment: PosPayment) => isCashPayment(payment.paymentMethod) && !payment.customPaymentMethod);
    const isOnlyPaidInCard = payments?.every((payment: PosPayment) => isCardPayment(payment.paymentMethod) && !payment.customPaymentMethod);

    const updatePosPendingOrder = async ({ firstName, lastName, mobileNumber, email, customerNote }: UpdatePendingOrderParams): Promise<UpdatePendingOrderResult> => {
        const now = new Date();

        const request: UpdatePendingOrderApiRequest = {
            orderId: pendingOrder?.orderId,
            restaurantId,
            customerId,
            promoCodeId: promoCode?.promoCodeId,
            orderType,
            promotionIds: removeDuplicates(usedPromotions?.map((promotion) => promotion.promotionId)),
            orderStatus: isClosedOrder(pendingOrder.orderStatus) ? pendingOrder.orderStatus : OrderStatuses.PENDING,
            kitchenStatus: pendingOrder?.kitchenStatus ?? KitchenStatuses.PREPARING,
            firstName,
            lastName,
            mobileNumber: !!mobileNumber ? mobileNumber : undefined,
            email,
            customerNote,
            numberOfCustomers: table?.numberOfCustomers ?? numberOfCustomers ?? pendingOrder?.numberOfCustomers,
            paymentMethod: orderPaymentMethod,
            posPayments: mappedPosPayments,
            payments: mappedPayments,
            pickupTimeType: pickupTimeType ?? PickupTimeTypes.ASAP,
            pickupTime: pickupTime ?? new Date(),
            orderItems: items?.map((item: CartItemVm) => ({ ...mapCartItemToOrderItem(item), addedAt: item?.addedAt ?? now })),
            cancelledItems: cancelledItems?.map((item: CartItemVm) => ({ ...mapCartItemToOrderItem(item), removedAt: item?.removedAt ?? now })),
            posDiscount: discount,
            tips: mappedPosTips,
            useLetsEatCredits,
            notes,
            customers: customers?.map(mapCustomerToOrderCustomer),
            courses: courses?.map(mapPosCourseToOrderCourse),
        };

        if (!isOnlyPaidInCash || !isOnlyPaidInCard) {
            request.partiallyPaidByCash = true;
            request.partiallyPaidByCashAmount = getCashAmountPaid(payments);
        }

        setDisabledPosPaymentActions(true);
        const response = await updatePendingOrderApi(request);
        setDisabledPosPaymentActions(false);

        return { response, request };
    };

    return { updatePosPendingOrder };
}

type UpdatePendingOrderParams = {
    firstName?: string;
    lastName?: string;
    mobileNumber?: string;
    email?: string;
    customerNote?: string;
};

type UpdatePendingOrderResult = {
    response: ApiResponse<OrderVm>;
    request: SavePosOrderApiRequest | UpdatePendingOrderApiRequest;
};
