import { makeStyles } from '@material-ui/core';
import * as React from 'react';
import { Tooltip } from 'src/components/Tooltip';
import { App, Apps } from 'src/constants/App';
import { KioskIcon } from 'src/icons/KioskIcon';
import { PedidosYaCircleIcon } from 'src/icons/PedidosYaCircleIcon';
import { PidedirectoAppIcon } from 'src/icons/PidedirectoAppIcon';
import { PidedirectoPosIcon } from 'src/icons/PidedirectoPosIcon';
import { getChannelImage } from 'src/utils/menu/getChannelImage';
import { classNames } from 'src/utils/react/classNames';

export function Channel({ channel, isSelected, disabled, size, onClick, classes: classesProp, tooltip }: Props): React.ReactElement {
    const classes = useStyles();

    const channelSize = size ?? 70;

    const handleClick = () => {
        if (disabled) return;
        onClick?.(channel);
    };

    if (channel === Apps.PIDEDIRECTOPOS) {
        return (
            <div
                className={classNames(classes.channelContainer, classesProp?.container, isSelected && classes.channelSelected, tooltip && classes.disabledChannel)}
                style={{ width: channelSize, height: channelSize }}
                onClick={handleClick}
            >
                {tooltip && (
                    <Tooltip text={tooltip}>
                        <PidedirectoPosIcon title={'posIcon'} />
                    </Tooltip>
                )}
                {!tooltip && <PidedirectoPosIcon title={'posIcon'} />}
            </div>
        );
    }

    if (channel === Apps.PIDEDIRECTOAPP) {
        return (
            <div
                className={classNames(classes.channelContainer, classesProp?.container, isSelected && classes.channelSelected, tooltip && classes.disabledChannel)}
                style={{ width: channelSize, height: channelSize }}
                onClick={handleClick}
            >
                {tooltip && (
                    <Tooltip text={tooltip}>
                        <PidedirectoAppIcon title={'APP'} />
                    </Tooltip>
                )}
                {!tooltip && <PidedirectoAppIcon title={'APP'} />}
            </div>
        );
    }

    if (channel === Apps.PIDEDIRECTOKIOSK) {
        return (
            <div
                className={classNames(classes.channelContainer, classesProp?.container, isSelected && classes.channelSelected, tooltip && classes.disabledChannel)}
                style={{ width: channelSize, height: channelSize }}
                onClick={handleClick}
            >
                {tooltip && (
                    <Tooltip text={tooltip}>
                        <KioskIcon title={'Kiosk'} />
                    </Tooltip>
                )}
                {!tooltip && <KioskIcon title={'Kiosk'} />}
            </div>
        );
    }

    if (channel === Apps.PEDIDOS_YA) {
        return (
            <div
                className={classNames(classes.channelContainer, classesProp?.container, isSelected && classes.channelSelected, tooltip && classes.disabledChannel)}
                style={{ width: channelSize, height: channelSize }}
                onClick={handleClick}
            >
                {tooltip && (
                    <Tooltip text={tooltip}>
                        <PedidosYaCircleIcon title={'Pedidos Ya'} />
                    </Tooltip>
                )}
                {!tooltip && <PedidosYaCircleIcon title={'Pedidos Ya'} />}
            </div>
        );
    }

    return (
        <div
            className={classNames(classes.channelContainer, classesProp?.container, isSelected && classes.channelSelected, tooltip && classes.disabledChannel)}
            style={{ width: channelSize, height: channelSize }}
            onClick={handleClick}
        >
            {tooltip && (
                <Tooltip text={tooltip}>
                    <img className={classNames(classes.img, tooltip && classes.imgDisable)} src={getChannelImage(channel)} alt={`Channel integration logo for ${channel}`} unselectable='off' />
                </Tooltip>
            )}
            {!tooltip && <img className={classNames(classes.img)} src={getChannelImage(channel)} alt={`Channel integration logo for ${channel}`} unselectable='off' />}
        </div>
    );
}

const useStyles = makeStyles((theme) => ({
    channelContainer: {
        borderRadius: 70,
        backgroundColor: theme.palette.secondary.light,
        border: `1px solid #D9D9D9`,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        cursor: 'pointer',
        flexShrink: 0,
        padding: 7,
    },
    channelSelected: {
        border: `2px solid ${theme.palette.primary.main}`,
    },
    text: {
        fontFamily: theme.typography.bold,
        padding: '0 2px',
    },
    disabledChannel: {
        cursor: 'default',
    },
    img: {
        objectFit: 'contain',
        width: '100%',
        maxWidth: 32,
    },
    imgDisable: {
        objectFit: 'contain',
        width: '100%',
        maxWidth: 32,
        filter: 'grayscale(100%)',
    },
}));

type Props = {
    channel: App;
    isSelected?: boolean;
    disabled?: boolean;
    size?: number;
    onClick?: any;
    classes?: {
        container?: string;
    };
    tooltip?: string;
};
