import { Box, makeStyles } from '@material-ui/core';
import moment from 'moment';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { getCustomerRestaurantStatisticsApi, SalesByCustomer, SurveyAnswersFrequency } from 'src/api/letseatmanager/restaurantDashboard/getCustomerRestaurantStatisticsApi';
import { UpdatingContentProgress } from 'src/components/UpdatingContentProgress';
import { CustomerSalesTable } from 'src/scenes/letseatmanager/restaurantDashboard/customerReport/CustomerSalesTable';
import { FrequencyAnswerCustomerGraph } from 'src/scenes/letseatmanager/restaurantDashboard/customerReport/FrequencyAnswerCustomerGraph';
import { SurveysByDateTable } from 'src/scenes/letseatmanager/restaurantDashboard/customerReport/SurveysByDateTable';
import { FilterMasterReports } from 'src/scenes/letseatmanager/restaurantDashboard/FilterMasterReports';
import { FilterReports } from 'src/scenes/letseatmanager/restaurantDashboard/FilterReports';
import { ReportSectionEmptyState } from 'src/scenes/letseatmanager/restaurantDashboard/ReportSectionEmptyState';
import { RestaurantId } from 'src/types/Id';
import { useFormatDateToRestaurantTimeZone } from 'src/utils/react/useFormatDateToRestaurantTimeZone';
import { useLoadApi } from 'src/utils/react/useLoadApi';
import { useSelector } from 'src/utils/react/useSelector';

export function CustomerReport(): React.ReactElement {
    const classes = useStyles();

    const [customerSalesData, setCustomerSalesData] = useState<Array<SalesByCustomer>>([]);
    const [surveysByDateData, setSurveysByDateData] = useState<any>({});
    const [frequencyAnswersData, setFrequencyAnswersData] = useState<Array<SurveyAnswersFrequency>>([]);
    const [hasInfoFound, setHasInfoFound] = useState(false);
    const [showAsMaster, setShowAsMaster] = useState(false);

    const restaurantId = useSelector((state) => state.app.restaurantId);
    const restaurantIds = useSelector((state) => state.app.restaurantIds);
    const brandOpened = useSelector((state) => state.app.brandOpened);
    const filterReportType = useSelector((state) => state.app2.filterReportsState.filterReportType);
    const period = useSelector((state) => state.app2.filterReportsState.period);
    const currentRestaurantId = useSelector((state) => state.app2.filterReportsState.currentRestaurantId);
    const salesType = useSelector((state) => state.app.salesTypeFilter);

    const formatDateToRestaurantTimeZone = useFormatDateToRestaurantTimeZone();

    const [loading, reports] = useLoadApi(
        getCustomerRestaurantStatisticsApi,
        {
            report: showAsMaster ? 'asBrand' : 'perRestaurant',
            restaurantIds: brandOpened ? restaurantIds : [restaurantId],
            fromDate: moment(formatDateToRestaurantTimeZone(period?.from)).toISOString(),
            toDate: moment(formatDateToRestaurantTimeZone(period.to)).toISOString(),
            salesType: salesType,
        },
        {
            dependencies: [period, filterReportType, showAsMaster, currentRestaurantId, restaurantId, salesType],
        },
    );

    useEffect(() => {
        if (reports) {
            if (showAsMaster || !brandOpened) return handleUpdateCustomerReports(reports[0]?.restaurantId);

            handleUpdateCustomerReports(currentRestaurantId ?? reports[0].restaurantId);
        }
    }, [reports, currentRestaurantId]);

    const handleUpdateCustomerReports = (newRestaurantId: RestaurantId) => {
        const currentRestaurantReportInfo = reports?.find((restaurant) => restaurant.restaurantId === newRestaurantId);

        if (!hasRestaurantReportInfo(currentRestaurantReportInfo)) {
            setHasInfoFound(false);
            return;
        }

        setCustomerSalesData(currentRestaurantReportInfo?.salesByCustomer ?? []);
        setSurveysByDateData({
            data: currentRestaurantReportInfo?.surveysByDate ?? [],
            mostCommonSurveyAnswer: currentRestaurantReportInfo?.mostCommonSurveyAnswer ?? '',
            meanSurveyRating: currentRestaurantReportInfo?.meanSurveyRating ?? 0,
        });
        setFrequencyAnswersData(currentRestaurantReportInfo?.surveyAnswersFrequency ?? []);
        setHasInfoFound(true);
    };

    const hasRestaurantReportInfo = (currentRestaurant: (typeof reports)[number] | undefined) => currentRestaurant && currentRestaurant?.salesByCustomer;

    const handleShowAsMaster = (value: any) => {
        setShowAsMaster(value);
    };

    return (
        <div>
            <FilterReports />
            <Box className={classes.container}>
                <FilterMasterReports onChangeMaster={handleShowAsMaster} showAsMaster={showAsMaster} />
                {loading && <UpdatingContentProgress />}
                {hasInfoFound && (
                    <div>
                        <CustomerSalesTable loading={loading} customerSalesData={customerSalesData} />
                        <SurveysByDateTable loading={loading} surveysByDateData={surveysByDateData} />
                        <FrequencyAnswerCustomerGraph loading={loading} frequencyAnswerData={frequencyAnswersData} />
                    </div>
                )}
                {!hasInfoFound && !loading && <ReportSectionEmptyState />}
            </Box>
        </div>
    );
}

const useStyles = makeStyles((theme) => ({
    container: {
        width: '100%',
    },
}));
