import { makeStyles } from '@material-ui/core';
import { BigNumber } from 'bignumber.js';
import * as React from 'react';
import { memo, useState } from 'react';
import { Button } from 'src/components/Button';
import { translate } from 'src/i18n/translate';
import { getPosInterfaceLargerEnabledRestaurantSettingInLocalStorage } from 'src/localStorage/getPosInterfaceLargerEnabledRestaurantSettingInLocalStorage';
import { ApplyCustomerCreditsDialog } from 'src/scenes/letseatmanager/pos/posPayment/ApplyCustomerCreditsDialog';
import { PosCustomerOrderDetails } from 'src/types/PosCustomerOrderDetails';
import { PosPayment } from 'src/types/PosPayment';
import { useSelector } from 'src/utils/react/useSelector';

export const PosApplyCustomerCreditsButton: React.ComponentType<any> = memo<any>(() => {
    const classes = useStyles();

    const [applyCustomerCreditsDialogState, setApplyCustomerCreditsDialogState] = useState({ open: false });

    const posInterfaceLargerEnabled = getPosInterfaceLargerEnabledRestaurantSettingInLocalStorage();

    const restaurant = useSelector((state) => state.app.restaurant);
    const payments = useSelector((state) => state.pos.payments);
    const customers = useSelector((state) => state.pos.customers);
    const total = useSelector((state) => state.pos.payment?.total);
    const totalPaid = useSelector((state) => state.pos.totalPaid);
    const paying = useSelector((state) => state.pos.paying);

    const hasPaymentMissing = BigNumber(totalPaid ?? '0').isLessThan(total ?? '0');
    const showApplyCustomerCredits = restaurant?.restaurantPromoCodesEnabled;
    const someCustomerHasDiscount = customers?.some((customer: PosCustomerOrderDetails) => customer.payment?.posDiscount);
    const someCustomerHasTip = customers?.some((customer: PosCustomerOrderDetails) => customer.tips);
    const hasPaymentByCustomer = payments?.some((payment: PosPayment) => payment.customerNumber);
    const promoCodeAndCreditsDisabled = hasPaymentByCustomer || someCustomerHasDiscount || someCustomerHasTip || paying;

    const handleApplyCredits = () => setApplyCustomerCreditsDialogState({ open: true });

    if (!showApplyCustomerCredits) return null;

    return (
        <>
            <ApplyCustomerCreditsDialog open={applyCustomerCreditsDialogState.open} onClose={() => setApplyCustomerCreditsDialogState({ open: false })} />
            <Button secondary larger={posInterfaceLargerEnabled} classes={{ button: classes.basicButton }} disabled={!hasPaymentMissing || promoCodeAndCreditsDisabled} onClick={handleApplyCredits}>
                {translate('Apply Credits')}
            </Button>
        </>
    );
});

const useStyles = makeStyles((theme) => ({
    basicButton: {
        width: '100%',
        height: 50,
        color: theme.palette.text.secondary,
        '&:hover': {
            cursor: 'pointer',
        },
    },
}));
