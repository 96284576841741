import { makeStyles } from '@material-ui/core';
import { Button } from '@pidedirecto/ui';
import { ScreenSizes } from '@pidedirecto/ui/constants';
import { useIsScreenSize, useNotification } from '@pidedirecto/ui/hooks';
import { CheckIcon, GramsIcon, KilogramsIcon, LitreIcon, MilliliterIcon } from '@pidedirecto/ui/icons';
import { BigNumber } from 'bignumber.js';
import { memo, useCallback, useContext, useMemo, useState } from 'react';
import * as React from 'react';
import { Input } from 'src/components/Input';
import { MenuItemTypes } from 'src/constants/DeprecatedMenuItemType';
import { UnitsOfMeasurement } from 'src/constants/UnitOfMeasurement';
import { translate } from 'src/i18n/translate';
import { MoneyIcon } from 'src/icons/MoneyIcon';
import { posReducer } from 'src/reducers/posReducer';
import { PosContext } from 'src/scenes/letseatmanager/pos/PosProvider';
import { useAddPosItem } from 'src/services/pos/useAddPosItem';
import { useFormatAsRestaurantCurrencyNumber } from 'src/services/restaurant/useFormatAsRestaurantCurrencyNumber';
import { CartItemVm } from 'src/types/CartItemVm';
import { MenuId, type MenuCategoryId } from 'src/types/Id';
import type { MenuItemVm } from 'src/types/PosMenuVm';
import { isItemSoldByWeight } from 'src/utils/orderItem/isItemSoldByWeight';
import { classNames } from 'src/utils/react/classNames';
import { useAction } from 'src/utils/react/useAction';
import { useSelector } from 'src/utils/react/useSelector';

export const PosMenuItem: React.ComponentType<Props> = memo<Props>(({ item, menuCategoryId }: Props) => {
    const [quantity, setQuantity] = useState(0);
    const notification = useNotification();
    const formatAsCurrencyNumber = useFormatAsRestaurantCurrencyNumber();
    const isSmallScreen = useIsScreenSize(ScreenSizes.SMALL_SCREEN);

    const posContext = useContext(PosContext);

    const { addItem } = useAddPosItem();

    const posUseMenuItemColorEnabled = useSelector((state) => state.app.restaurant?.posUseMenuItemColorEnabled);
    const menuCategories = useSelector((state) => state.pos.posMenu?.menuCategories) ?? [];
    const newMenuManagementEnabled = useSelector((state) => state.app.restaurant.newMenuManagementEnabled);
    const selectedMenuCategory = useSelector((state) => state.pos.selectedMenuCategory);
    const selectedMenu = useSelector((state) => state.pos.selectedMenu);

    const selectPosItem = useAction(posReducer.actions.selectPosItem);

    const classes = useStyles({
        color: posUseMenuItemColorEnabled ? item.color : undefined,
    });

    const menuCategoryItemBelongs = menuCategories.find((menuCategory) => menuCategory.menuCategoryId === menuCategoryId);
    const isSoldByWeight = isItemSoldByWeight(item);
    const minimumSalesQuantity = item.minimumSalesQuantity ? BigNumber(item.minimumSalesQuantity).toNumber() : 0;

    const handleClickPosItem = useCallback(() => {
        if (isSoldByWeight) return;
        addPosMenuItem();
    }, [item]);

    const hasModifierGroups = useMemo(() => {
        if (newMenuManagementEnabled) {
            const menuCategoryModifierGroupIds = menuCategoryItemBelongs?.modifierGroupIds ?? selectedMenuCategory?.modifierGroupIds;
            return !!item.modifierGroupIds?.length || !!menuCategoryModifierGroupIds.length;
        }
        return !!item.modifierGroups?.length;
    }, [newMenuManagementEnabled, menuCategoryItemBelongs, selectedMenuCategory, item]);

    const addPosMenuItem = () => {
        if (isSoldByWeight && BigNumber(quantity).isZero()) return;

        if (hasModifierGroups) {
            if (!!quantity) selectPosItem({ item, quantity, menuCategoryId });
            if (!quantity) selectPosItem({ item, menuCategoryId });
            return;
        }

        const posItem: CartItemVm = {
            menuItemId: item.menuItemId,
            menuCategoryId: menuCategoryId ?? selectedMenuCategory?.menuCategoryId,
            menuId: item.menuId, //TODO: change for menuSelectedId
            menuItemType: MenuItemTypes.ITEM,
            isProduct: item.isProduct,
            isModifier: item.isModifier,
            name: item.name,
            unitPrice: getMenuItemPrice,
            promoUnitPrice: getMenuItemPromoPrice,
            promoText: getMenuItemPromoText,
            size: item.size,
            isSoldByWeight: item.isSoldByWeight,
            currency: item.currency,
            imageUrl: item.imageUrl,
            salesUnit: item.salesUnit,
            quantity: BigNumber(quantity).isZero() ? 1 : Number(quantity),
            modifierGroups: [] as any,
            note: '',
            addedAt: posContext.menuOpenedAt,
        } as const;

        addItem(posItem);
        if (isSmallScreen) {
            notification({ message: translate('Product added') });
        }
        setQuantity(0);
    };

    const getWeightIcon = () => {
        if (!isSoldByWeight) return;

        if (item.salesUnit === UnitsOfMeasurement.MILLILITER) return <MilliliterIcon />;
        if (item.salesUnit === UnitsOfMeasurement.LITRE) return <LitreIcon />;
        if (item.salesUnit === UnitsOfMeasurement.GRAM) return <GramsIcon />;
        if (item.salesUnit === UnitsOfMeasurement.CURRENCY) return <MoneyIcon />;

        return <KilogramsIcon />;
    };

    const getMenuItemPrice = useMemo(() => {
        return (
            item.menusProductInfo?.find(
                (menuProductInfo: { hidden?: boolean; menuId: MenuId; price?: string; promoPrice?: string; promoText?: string }) => menuProductInfo.menuId === selectedMenu?.menuId,
            )?.price || item.price
        );
    }, [item, selectedMenu]);

    const getMenuItemPromoPrice = useMemo(() => {
        return (
            item.menusProductInfo?.find(
                (menuProductInfo: { hidden?: boolean; menuId: MenuId; price?: string; promoPrice?: string; promoText?: string }) => menuProductInfo.menuId === selectedMenu?.menuId,
            )?.promoPrice || item.promoPrice
        );
    }, [item, selectedMenu]);

    const getMenuItemPromoText = useMemo(() => {
        return (
            item.menusProductInfo?.find(
                (menuProductInfo: { hidden?: boolean; menuId: MenuId; price?: string; promoPrice?: string; promoText?: string }) => menuProductInfo.menuId === selectedMenu?.menuId,
            )?.promoText || item.promoText
        );
    }, [item, selectedMenu]);

    const pricePerUnitOfSalesText = () => {
        if (item.currency) return `/${item.currency}`;
        return isSoldByWeight ? `/${translate(`UnitsOfMeasurement.${item.salesUnit ?? ''}`)}` : '';
    };

    return (
        <Button variant={'secondary'} classes={{ button: classes.item }} onClick={handleClickPosItem}>
            <div style={{ display: 'flex', justifyContent: 'space-between', flexWrap: 'wrap', gap: 4, width: '100%' }}>
                <div>
                    <p className={classes.itemName}>{item.name}</p>
                    {!!menuCategoryItemBelongs && <span className={classes.menuCategoryName}>{menuCategoryItemBelongs?.name}</span>}
                </div>
                <div>
                    <span className={classNames(classes.itemPrice, !!getMenuItemPromoPrice ? classes.itemPriceWithDiscount : '')}>
                        {formatAsCurrencyNumber(getMenuItemPrice)}
                        {pricePerUnitOfSalesText()}
                    </span>
                    {!!getMenuItemPromoPrice && (
                        <span className={classes.itemPrice} style={{ marginLeft: 5 }}>
                            {formatAsCurrencyNumber(getMenuItemPromoPrice)}
                        </span>
                    )}
                </div>
            </div>
            {!!getMenuItemPromoText && <span className={classes.promoText}>{getMenuItemPromoText}</span>}
            {isSoldByWeight && (
                <div className={classes.weight}>
                    <div className={classes.inputContainer}>
                        <Input
                            step={0.5}
                            minValue={minimumSalesQuantity}
                            name={'quantity'}
                            label={translate('Quantity')}
                            type={'number'}
                            onChange={(value: any) => setQuantity(value)}
                            value={quantity}
                            classes={{ input: classes.input, label: classes.label }}
                        />
                        {item.salesUnit && <span className={classes.unit}>{pricePerUnitOfSalesText()}</span>}
                        <Button variant={'icon'} disabled={BigNumber(quantity).isZero()} classes={{ button: classes.iconButton }} onClick={addPosMenuItem}>
                            <CheckIcon size={14} />
                        </Button>
                    </div>
                    {getWeightIcon()}
                </div>
            )}
        </Button>
    );
});

const useStyles = makeStyles((theme) => ({
    item: {
        marginTop: 8,
        boxSizing: 'border-box',
        borderRadius: 15,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'start',
        alignItems: 'start',
        flexShrink: 0,
        minHeight: 80,
        cursor: 'pointer',
        margin: '0px 8px',
        border: (props: any) => `${props.color ? '2px' : '1px'} solid ${props.color ?? theme.palette.border.primary}`,
        '&:hover': {
            border: (props: any) => `${props.color ? '2px' : '1px'} solid ${props.color ? `${props.color}33` : `${theme.palette.border.primary}33`}`,
            backgroundColor: (props: any) => (props.color ? `${props.color}33` : `${theme.palette.border.primary}33`),
        },
    },
    inputContainer: {
        width: '60%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'start',
    },
    input: {
        width: 60,
        height: 28,
    },
    label: {
        textAlign: 'left',
    },
    iconButton: {
        padding: 10,
        paddingBottom: 5,
        alignSelf: 'end',
        backgroundColor: 'transparent !important',
        '&:hover': {
            backgroundColor: 'transparent',
        },
    },
    weight: {
        display: 'flex',
        width: '100%',
        justifyContent: 'space-between',
        alignItems: 'end',
        marginTop: 8,
    },
    icon: {
        '&.MuiSvgIcon-colorPrimary': {
            color: theme.palette.border.success,
            '&:hover': {
                color: theme.palette.border.success,
            },
        },
    },
    unit: {
        margin: 5,
        fontFamily: theme.typography.regular,
        fontSize: 12,
        color: theme.palette.text.secondary,
        alignSelf: 'end',
    },
    itemName: {
        fontSize: 14,
        fontFamily: theme.typography.semiBold,
        margin: 0,
        color: theme.palette.text.primary,
        maxWidth: '55%',
        textAlign: 'left',
        [theme.breakpoints.down('sm')]: {
            fontSize: 14,
        },
    },
    itemPrice: {
        fontSize: 14,
        fontFamily: theme.typography.regular,
        color: theme.palette.text.primary,
        [theme.breakpoints.down('sm')]: {
            fontSize: 12,
        },
    },
    itemPriceWithDiscount: {
        textDecoration: 'line-through',
        color: theme.palette.text.disabled,
    },
    promoText: {
        fontFamily: theme.typography.regular,
        fontSize: 15,
        width: 'fit-content',
        color: theme.palette.border.success,
    },
    menuCategoryName: {
        fontSize: 12,
        fontFamily: theme.typography.regular,
    },
}));

type Props = {
    item: MenuItemVm;
    menuCategoryId?: MenuCategoryId;
};
