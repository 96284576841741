import { makeStyles } from '@material-ui/core';
import * as React from 'react';
import { translate } from 'src/i18n/translate';
import { useSelector } from 'src/utils/react/useSelector';

export function RestaurantInvoiceInfo(): React.ReactElement | null {
    const classes = useStyles();

    const restaurant = useSelector((state) => state.app.restaurant);

    if (!restaurant) return null;

    return (
        <div className={classes.container}>
            <div className={classes.separatedText}>
                <span>{translate('Business Name')}</span>
                <span className={classes.data}>{restaurant?.businessName}</span>
            </div>
            <div className={classes.separatedText}>
                <span>{translate('Regime fiscal')}</span>
                <span className={classes.data}>{restaurant?.regimeFiscal}</span>
            </div>
            <div className={classes.separatedText}>
                <span>{translate('RFC')}</span>
                <span className={classes.data}>{restaurant?.rfc}</span>
            </div>
            <div className={classes.separatedText}>
                <span>{translate('Address')}</span>
                <span className={classes.data}>{restaurant?.fiscalAddress}</span>
            </div>
        </div>
    );
}

const useStyles = makeStyles((theme) => ({
    container: {
        display: 'grid',
        gap: 8,
        fontSize: 14,
        fontFamily: theme.typography.regular,
        color: '#232933',
        paddingBottom: 12,
        borderBottom: '1px solid #D9D9D9',
    },
    separatedText: {
        '& span': {
            display: 'block',
        },
        [theme.breakpoints.up('sm')]: {
            display: 'flex',
            justifyContent: 'space-between',
        },
    },
    data: {
        fontFamily: theme.typography.semiBold,
    },
}));
