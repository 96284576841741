import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Grid from '@material-ui/core/Grid';
import RadioGroup from '@material-ui/core/RadioGroup';
import { withStyles } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import * as React from 'react';
import { withRouter } from 'react-router-dom';
import { letseatmanagerApiDeprecated } from 'src/api/letseatmanagerApiDeprecated';
import { Field, Form } from 'src/components/deprecated/react-final-form/Form';
import Radio from 'src/components/deprecated/react-final-form/Radio';
import TextField from 'src/components/deprecated/react-final-form/TextField';
import { Spacing } from 'src/components/spacing/Spacing';
import { UpdatingContentProgress } from 'src/components/UpdatingContentProgress';
import { history } from 'src/config/history';
import { translate } from 'src/i18n/translate';
import { appColors } from 'src/theme/AppColors';
import { appLayout } from 'src/theme/AppLayout';
import type { AwsCognitoUsername, CompanyId, ConektaCustomerId, StripeCustomerId } from 'src/types/Id';
import { alertKnownErrorOrSomethingWentWrong } from 'src/utils/alert/alertKnownErrorOrSomethingWentWrong';
import { deleteUrlQueryParameter } from 'src/utils/history/deleteUrlQueryParameter';
import { getUrlQueryParameter } from 'src/utils/history/getUrlQueryParameter';
import { required } from 'src/utils/Validator';

export default withRouter<any, any>(
    withStyles((theme) => ({
        deleteButton: {
            color: appColors.text.dangerLight,
        },
        leftActions: {
            justifyContent: 'space-between',
        },
    }))(
        class ChangeCompanyDialog extends React.PureComponent<Props, State> {
            state: any = INITIAL_STATE;

            async componentDidMount(): Promise<void> {
                const companyId: CompanyId = getUrlQueryParameter(CHANGE_COMPANY, this.props.location);
                if (companyId) {
                    await this.openDialog(companyId);
                }
            }

            async componentDidUpdate(prevProps: Props) {
                const prevCompanyId: CompanyId = getUrlQueryParameter(CHANGE_COMPANY, prevProps.location);
                const companyId: CompanyId = getUrlQueryParameter(CHANGE_COMPANY, this.props.location);
                if (companyId && !prevCompanyId && !this.state.open) {
                    await this.openDialog(companyId);
                } else if (!companyId && prevCompanyId && this.state.open) {
                    this.closeDialog();
                }
            }

            handleClose = () => {
                if (this.state.submitting) {
                    return;
                }
                if (history.length <= 2) {
                    history.replace({
                        path: this.props.location.pathname,
                        search: deleteUrlQueryParameter(CHANGE_COMPANY),
                    });
                    return;
                }
                history.goBack();
            };

            handleExited = () => {
                this.setState(INITIAL_STATE as any);
            };

            openDialog = async (companyId: CompanyId) => {
                this.setState({ open: true });
                await this.load(companyId);
            };

            closeDialog = () => {
                this.setState({ open: false });
            };

            load = async (companyId: CompanyId) => {
                this.setState({ loading: true });
                const response = await letseatmanagerApiDeprecated.admin.getCompany({ companyId });
                this.setState({ loading: false });
                if (!response.ok) {
                    alertKnownErrorOrSomethingWentWrong(response);
                    this.setState({ open: false });
                    return;
                }
                const company: CompanyVm = response.data;
                this.setState({
                    company: {
                        companyId: company.companyId,
                        name: company.name,
                        address: company.address || '',
                        contact: {
                            email: company.contact.email || '',
                            phoneNumber: company.contact.phoneNumber || '',
                            firstName: company.contact.firstName || '',
                            lastName: company.contact.lastName || '',
                        },
                        paused: company.paused ? 'true' : 'false',
                    },
                });
            };

            onSubmit = async (form: any) => {
                this.setState({ submitting: true });
                const response = await letseatmanagerApiDeprecated.admin.changeCompany({
                    companyId: form.company.companyId,
                    name: form.company.name,
                    address: form.company.address || null,
                    contact: {
                        email: form.company.contact.email || null,
                        phoneNumber: form.company.contact.phoneNumber || null,
                        firstName: form.company.contact.firstName || null,
                        lastName: form.company.contact.lastName || null,
                    },
                    paused: form.company.paused === 'true',
                } as ChangeCompanyRequest);
                this.setState({ submitting: false });
                if (!response.ok) {
                    alertKnownErrorOrSomethingWentWrong(response);
                    return;
                }
                this.handleClose();
                this.props.onCompanyChanged();
            };

            handleClickRemove = async () => {
                const remove = window.confirm('Are you sure you want to unregister this company?');
                if (remove) {
                    await this.unregisterCompany();
                }
            };

            unregisterCompany = async () => {
                const companyId: CompanyId = getUrlQueryParameter(CHANGE_COMPANY, this.props.location);
                this.setState({ submitting: true });
                const response = await letseatmanagerApiDeprecated.admin.unregisterCompany({ companyId });
                this.setState({ submitting: false });
                if (!response.ok) {
                    alertKnownErrorOrSomethingWentWrong(response);
                    return;
                }
                this.props.onCompanyChanged(true);
                if (history.length <= 2) {
                    history.replace({
                        path: this.props.location.pathname,
                        search: deleteUrlQueryParameter(CHANGE_COMPANY),
                    });
                    return;
                }
                history.go(-2);
            };

            render() {
                const { classes } = this.props;
                return (
                    <Dialog aria-labelledby='change-company-dialog-title' fullWidth scroll='body' open={this.state.open} onClose={this.handleClose} onExited={this.handleExited}>
                        <Form
                            onSubmit={this.onSubmit}
                            initialValues={{
                                company: this.state.company,
                            }}
                            render={({ handleSubmit, submitting, pristine, values }) => (
                                <form onSubmit={handleSubmit} noValidate style={appLayout.dialog.form}>
                                    <DialogTitle id='change-company-dialog-title'>{translate('Change Company').toUpperCase()}</DialogTitle>
                                    <DialogContent>{this.renderDialogContent()}</DialogContent>
                                    <DialogActions className={classes.leftActions}>
                                        <div>
                                            <Tooltip title='Unregister Company'>
                                                <Button onClick={this.handleClickRemove} disabled={this.state.submitting} tabIndex='-1' className={classes.deleteButton}>
                                                    {translate('Unregister Company').toUpperCase()}
                                                </Button>
                                            </Tooltip>
                                        </div>
                                        <div>
                                            <Button onClick={this.handleClose} disabled={this.state.submitting}>
                                                {translate('Cancel').toUpperCase()}
                                            </Button>
                                            <Button color='primary' type='submit' disabled={this.state.submitting}>
                                                {this.state.submitting ? translate('Changing').toUpperCase() : translate('Change').toUpperCase()}
                                            </Button>
                                        </div>
                                    </DialogActions>
                                    <UpdatingContentProgress loading={this.state.loading || this.state.submitting} top />
                                </form>
                            )}
                        />
                    </Dialog>
                );
            }

            renderDialogContent() {
                return (
                    <>
                        <Grid container spacing={3}>
                            <Grid item xs={12}>
                                <Field
                                    name='company.name'
                                    component={TextField}
                                    label={translate('Name')}
                                    fullWidth
                                    disabled={this.state.submitting}
                                    required
                                    validate={required}
                                    inputProps={{ autoComplete: 'no' }}
                                />
                            </Grid>

                            <Grid item xs={12}>
                                <Field name='company.address' component={TextField} label={translate('Address')} fullWidth disabled={this.state.submitting} inputProps={{ autoComplete: 'no' }} />
                            </Grid>

                            <Grid item xs={12} sm={6}>
                                <Field
                                    name='company.contact.firstName'
                                    component={TextField}
                                    label={translate('Contact First Name')}
                                    fullWidth
                                    disabled={this.state.submitting}
                                    inputProps={{ autoComplete: 'no' }}
                                />
                            </Grid>

                            <Grid item xs={12} sm={6}>
                                <Field
                                    name='company.contact.lastName'
                                    component={TextField}
                                    label={translate('Contact Last Name')}
                                    fullWidth
                                    disabled={this.state.submitting}
                                    inputProps={{ autoComplete: 'no' }}
                                />
                            </Grid>

                            <Grid item xs={12}>
                                <Field
                                    name='company.contact.email'
                                    component={TextField}
                                    label={translate('Contact Email')}
                                    fullWidth
                                    disabled={this.state.submitting}
                                    inputProps={{ autoComplete: 'no' }}
                                />
                            </Grid>

                            <Grid item xs={12}>
                                <Field
                                    name='company.contact.phoneNumber'
                                    component={TextField}
                                    label={translate('Contact Phone Number')}
                                    fullWidth
                                    disabled={this.state.submitting}
                                    inputProps={{ autoComplete: 'no' }}
                                />
                            </Grid>

                            <Grid item xs={12}>
                                <RadioGroup row>
                                    <FormControlLabel
                                        label='Active'
                                        control={<Field name='company.paused' component={Radio} type='radio' color='primary' value='false' disabled={this.state.submitting} />}
                                    />
                                    <FormControlLabel
                                        label='Paused'
                                        control={<Field name='company.paused' component={Radio} type='radio' color='primary' value='true' disabled={this.state.submitting} />}
                                    />
                                </RadioGroup>
                            </Grid>
                        </Grid>

                        <Grid container justify='space-around'>
                            <Grid item>
                                <Spacing units={8} />
                                {this.state.errorMessage && (
                                    <Typography display='block' color='error'>
                                        {this.state.errorMessage}
                                    </Typography>
                                )}
                            </Grid>
                        </Grid>
                    </>
                );
            }
        },
    ),
) as React.ComponentType<any>;

export const CHANGE_COMPANY = 'change-company';
const INITIAL_STATE = {
    open: false,
    company: {
        companyId: '' as any,
        name: '',
        address: '',
        contact: {
            email: '',
            phoneNumber: '',
            firstName: '',
            lastName: '',
        },
        paused: 'false',
    },
    loading: false,
    submitting: false,
} as const;

type Props = {
    classes: any;
    location: {
        pathname: string;
    };
    onCompanyChanged: any;
};

type State = {
    open: boolean;
    loading: boolean;
    submitting: boolean;
    company: {
        companyId: CompanyId;
        name: string;
        address?: string;
        contact: {
            email?: string;
            phoneNumber?: string;
            firstName?: string;
            lastName?: string;
        };
        paused: string;
    };
    errorMessage?: string;
};

type CompanyVm = {
    companyId: CompanyId;
    username: AwsCognitoUsername;
    name: string;
    address?: string;
    contact: CompanyContactVm;
    conektaCustomerId?: ConektaCustomerId;
    stripeCustomerId?: StripeCustomerId;
    creditCards: number;
    paused?: boolean;
    createdAt: Date;
    modifiedAt: Date;
};

type CompanyContactVm = {
    email?: string;
    phoneNumber?: string;
    firstName?: string;
    lastName?: string;
};

type ChangeCompanyRequest = {
    companyId: CompanyId;
    name?: string | null | undefined;
    address?: string | null | undefined;
    contact: CompanyContact;
    paused?: boolean | null | undefined;
};

export type CompanyContact = {
    email?: string | null | undefined;
    phoneNumber?: string | null | undefined;
    firstName?: string | null | undefined;
    lastName?: string | null | undefined;
};
