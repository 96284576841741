import { LogEventTypes } from 'src/constants/LogEventType';
import { createUiInteractionLogEvent } from 'src/services/logEvent/createUiInteractionLogEvent';

export function createUserOpenedPageLogEvent({ title, stackTrace }: Params): void {
    createUiInteractionLogEvent({
        logEventType: LogEventTypes.USER_OPENED_PAGE,
        message: `page ${title} opened `,
        details: stackTrace,
        data: {
            title,
            pageId: stackTrace,
            pathId: stackTrace,
        },
    });
}

type Params = {
    title: string;
    stackTrace: string;
};
