import { useTheme } from '@material-ui/core/styles';
import * as React from 'react';

export function CashIcon({ color, title, width, height }: Props): React.ReactElement {
    const theme = useTheme();
    return (
        <svg width={width ?? '25'} height={height ?? '19'} viewBox='0 0 25 19' fill='none' xmlns='http://www.w3.org/2000/svg'>
            <title>{title}</title>
            <path
                d='M2.11198 1.94393C5.57292 2.84189 9.03646 2.00415 12.5 1.16517C16.276 0.250712 20.0521 -0.66416 23.8281 0.675563C24.5616 0.935835 25 1.63416 25 2.37826V15.1584C25 16.1442 23.8889 16.7959 22.8863 16.5361C19.4271 15.6369 15.9635 16.4742 12.5 17.3115C8.6849 18.2272 4.90885 19.1429 1.17057 17.8024C0.436632 17.5425 0 16.8454 0 16.0989V3.32036C0 2.33413 1.11155 1.68407 2.11198 1.94393ZM12.4609 13.1992C14.4184 13.1992 15.9722 11.4255 15.9722 9.20227C15.9722 7.01614 14.4184 5.2425 12.4609 5.2425C10.5816 5.2425 8.98872 7.01614 8.98872 9.20227C8.98872 11.4255 10.5816 13.1992 12.4609 13.1992ZM2.73872 15.839H5.51649C5.51649 14.383 4.31207 13.1992 2.73872 13.1992V15.839ZM2.73872 4.58254V7.22238C4.31207 7.22238 5.51649 6.0757 5.51649 4.58254H2.73872ZM22.2222 11.1822C20.6901 11.1822 19.4444 12.4031 19.4444 13.8591H22.2222V11.1822ZM19.4444 2.60265C19.4444 4.09581 20.6901 5.2425 22.2222 5.2425V2.60265H19.4444Z'
                fill={color || 'currentColor'}
            />
        </svg>
    );
}

type Props = {
    color?: string;
    title?: string;
    width?: string;
    height?: string;
};
