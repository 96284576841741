import { useNotification } from '@pidedirecto/ui/hooks';
import { useEffect } from 'react';
import { WebSocketEventTypes } from 'src/constants/WebSocketEventType';
import { translate } from 'src/i18n/translate';
import { useLoadPosMenus } from 'src/services/menu/useLoadPosMenus';
import { RestaurantId } from 'src/types/Id';
import { WebSocketEvent } from 'src/types/WebSocketEvent';
import { useSelector } from 'src/utils/react/useSelector';
import { EventListenerRemover, WebSocketEvents } from 'src/utils/webSocket/WebSocketEvents';

export function usePosMenuSubscriptionUpdates() {
    const notification = useNotification();
    const loadPosMenus = useLoadPosMenus();

    const restaurantId = useSelector((state) => state.app.restaurantId);
    const posEnabled = useSelector((state) => state.app.restaurant?.posEnabled);

    useEffect(() => {
        let menuCreatedSyncWebSocketEvent: EventListenerRemover | undefined;

        if (posEnabled) menuCreatedSyncWebSocketEvent = WebSocketEvents.addEventListener(WebSocketEventTypes.POS_MENU_CHANGED, handleMenuWebSocketEvents);

        return () => {
            menuCreatedSyncWebSocketEvent?.remove();
        };
    }, [restaurantId, posEnabled]);

    const handleMenuWebSocketEvents = async (event: WebSocketEvent<PosMenuWebSocketEventResponse>) => {
        if (restaurantId !== event.data?.restaurantId || !posEnabled) return;

        notification({ message: translate('Synchronizing menus') });

        await loadPosMenus();
    };
}

type PosMenuWebSocketEventResponse = {
    restaurantId: RestaurantId;
};
