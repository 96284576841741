import { Divider, makeStyles } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import { BigNumber } from 'bignumber.js';
import * as React from 'react';
import { useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { changeTicketSectionsApi } from 'src/api/letseatmanager/restaurant/changeTicketSectionsApi';
import { pidedirectouploadApi } from 'src/api/pidedirectouploadApi';
import { Alert } from 'src/components/Alert';
import { Form } from 'src/components/form/Form';
import { FormNumberField } from 'src/components/form/FormNumberField';
import { UpdatingContentProgress } from 'src/components/UpdatingContentProgress';
import { ImageTypes } from 'src/constants/ImageType';
import { PrinterPaperSizes } from 'src/constants/PrinterPaperSize';
import { TicketSections } from 'src/constants/TicketSection';
import { translate } from 'src/i18n/translate';
import { CampaignIcon } from 'src/icons/CampaignIcon';
import { PrintTicketTestButton } from 'src/scenes/letseatmanager/customizePrinters/ticket/settings/PrintTicketTestButton';
import { TicketBusinessInfoSettings } from 'src/scenes/letseatmanager/customizePrinters/ticket/settings/TicketBusinessInfoSettings';
import { TicketCustomerInfoSettings } from 'src/scenes/letseatmanager/customizePrinters/ticket/settings/TicketCustomerInfoSettings';
import { TicketFooterSettings } from 'src/scenes/letseatmanager/customizePrinters/ticket/settings/TicketFooterSettings';
import { TicketGeneralInfoSettings } from 'src/scenes/letseatmanager/customizePrinters/ticket/settings/TicketGeneralInfoSettings';
import { TicketHeaderSettings } from 'src/scenes/letseatmanager/customizePrinters/ticket/settings/TicketHeaderSettings';
import { TicketInvoiceInfoSettings } from 'src/scenes/letseatmanager/customizePrinters/ticket/settings/TicketInvoiceInfoSettings';
import { TicketPaymentMethodSettings } from 'src/scenes/letseatmanager/customizePrinters/ticket/settings/TicketPaymentMethodSettings';
import { TicketPrintedDateSettings } from 'src/scenes/letseatmanager/customizePrinters/ticket/settings/TicketPrintedDateSettings';
import { TicketSalesInfoSettings } from 'src/scenes/letseatmanager/customizePrinters/ticket/settings/TicketSalesInfoSettings';
import { useNotification } from 'src/services/notification/useNotification';
import { ReactComponentPrinter } from 'src/services/printer/printers/ReactComponentPrinter';
import { createOrderTicketPrint } from 'src/services/printer/prints/createOrderTicketPrint';
import { useReloadRestaurant } from 'src/services/restaurant/useReloadRestaurant';
import { alertKnownErrorOrSomethingWentWrong } from 'src/utils/alert/alertKnownErrorOrSomethingWentWrong';
import { getErrorDescriptionFromResponse } from 'src/utils/error/getErrorDescriptionFromResponse';
import { isExternalImageUrl } from 'src/utils/image/isExternalImageUrl';
import { getOrderDemo } from 'src/utils/order/getOrderDemo';
import { useSelector } from 'src/utils/react/useSelector';
import { getActiveTicketSections } from 'src/utils/ticketAndCommand/getActiveTicketSections';
import { mapTicketSectionsToFormTicketSections } from 'src/utils/ticketAndCommand/mapTicketSectionsToFormTicketSections';

export function TicketSettings(): React.ReactElement | null {
    const classes = useStyles();

    const notification = useNotification();

    const [loading, setLoading] = useState(false);

    const { reloadRestaurant } = useReloadRestaurant();

    const restaurantId = useSelector((state) => state.app.restaurantId);
    const restaurant = useSelector((state) => state.app.restaurant);
    const order = useMemo(() => getOrderDemo(restaurant), [restaurant]);

    const form = useForm({
        defaultValues: {
            ...mapTicketSectionsToFormTicketSections(restaurant?.ticketSections),
            ticketLogoImage: restaurant?.ticketLogoImage,
            ticketCustomHeader: restaurant?.ticketCustomHeader,
            ticketCustomFooter: restaurant?.ticketCustomFooter,
            numberOfTicketPrints: restaurant?.numberOfTicketPrints ?? 1,
        },
    });

    const {
        watch,
        formState: { isDirty },
        setError,
        reset,
    } = form;

    const formTicketSections = watch([...Object.keys(TicketSections)]);
    const ticketLogoImageForm = watch('ticketLogoImage');
    const ticketCustomHeader = watch('ticketCustomHeader');
    const ticketCustomFooter = watch('ticketCustomFooter');
    const numberOfTicketPrints = watch('numberOfTicketPrints');

    const activeTicketSections = getActiveTicketSections(formTicketSections);

    useEffect(() => {
        reset({ ...getFormValuesFromRestaurant(restaurant), numberOfTicketPrints: restaurant?.numberOfTicketPrints ?? 1 });
    }, [restaurant]);

    const getFormValuesFromRestaurant = (restaurant: any) => ({
        ...mapTicketSectionsToFormTicketSections(restaurant?.ticketSections),
        ticketLogoImage: restaurant?.ticketLogoImage ?? null,
        ticketCustomHeader: restaurant?.ticketCustomHeader ?? null,
        ticketCustomFooter: restaurant?.ticketCustomFooter ?? null,
    });

    const handleSubmit = async () => {
        if (numberOfTicketPrinterHasError(numberOfTicketPrints)) {
            notification({ message: numberOfTicketPrinterHasError(numberOfTicketPrints) ?? '' });
            return;
        }

        if (formTicketSections.LOGO && !ticketLogoImageForm) {
            setError('ticketLogoImage', { type: 'custom', message: translate('This field is required') });
            return;
        }
        if (formTicketSections.CUSTOM_HEADER_TEXT && !ticketCustomHeader) {
            setError('ticketCustomHeader', { type: 'custom', message: translate('This field is required') });
            return;
        }
        if (formTicketSections.CUSTOM_FOOTER_TEXT && !ticketCustomFooter) {
            setError('ticketCustomFooter', { type: 'custom', message: translate('This field is required') });
            return;
        }

        setLoading(true);
        let ticketLogoImage = ticketLogoImageForm;
        if (isExternalImageUrl(ticketLogoImage)) {
            const migrateResponse = await pidedirectouploadApi.images.migrate({
                imageUrl: ticketLogoImage,
                imageType: ImageTypes.LOGO,
            });
            if (!migrateResponse.ok) {
                const errorMessage = `Failed to save cause of ${getErrorDescriptionFromResponse(migrateResponse)}`;
                alert(errorMessage);
                setLoading(false);
                return;
            }
            ticketLogoImage = migrateResponse.data;
        }

        const response = await changeTicketSectionsApi({
            restaurantId,
            ticketSections: activeTicketSections,
            numberOfTicketPrints: BigNumber(numberOfTicketPrints ?? 1).toNumber(),
            ticketLogoImage: ticketLogoImage,
            ticketCustomHeader,
            ticketCustomFooter,
        });
        await reloadRestaurant(restaurantId);
        setLoading(false);
        if (!response.ok) {
            alertKnownErrorOrSomethingWentWrong(response);
        }
    };

    const handleCloseAlert = () => {
        reset({ ...getFormValuesFromRestaurant(restaurant), numberOfTicketPrints: restaurant?.numberOfTicketPrints });
    };

    const numberOfTicketPrinterHasError = (numberOfTicketPrints: any) => {
        if (BigNumber(numberOfTicketPrints).isLessThanOrEqualTo(0)) {
            return translate('Must be a number greater than zero');
        }

        if (!BigNumber(numberOfTicketPrints).isInteger()) {
            return translate('Must be an integer');
        }

        return null;
    };

    return (
        <div className={classes.ticketTab}>
            {isDirty && (
                <Alert
                    icon={<CampaignIcon title={'campaign'} />}
                    onClose={handleCloseAlert}
                    title={translate('You have changes without updating')}
                    actionText={translate('Update')}
                    onActionClick={handleSubmit}
                />
            )}
            <section className={classes.header}>
                {loading && <UpdatingContentProgress loading={loading} />}
                <div>
                    <h1 className={classes.title}>{translate('Customize ticket')}</h1>
                    <div className={classes.subtitle}>{translate('Adds or removes information displayed when printing')}</div>
                </div>
                <PrintTicketTestButton disabled={loading || isDirty} />
            </section>
            <section className={classes.main}>
                <Form className={classes.form} form={form}>
                    <Grid container xs={12}>
                        <Grid item xs={12} md={6}>
                            <FormNumberField
                                name={'numberOfTicketPrints'}
                                label={translate('Number of prints')}
                                tooltip={translate('Number of prints of the ticket for each connected printer')}
                                min={1}
                                max={5}
                                disabled={loading}
                            />
                        </Grid>
                    </Grid>
                    <TicketHeaderSettings disabled={loading} />
                    <TicketBusinessInfoSettings disabled={loading} />
                    <TicketGeneralInfoSettings disabled={loading} />
                    <TicketCustomerInfoSettings disabled={loading} />
                    <TicketSalesInfoSettings disabled={loading} />
                    <TicketPaymentMethodSettings disabled={loading} />
                    <TicketPrintedDateSettings disabled={loading} />
                    <TicketInvoiceInfoSettings disabled={loading} />
                    <TicketFooterSettings disabled={loading} />
                </Form>
                <Divider variant='middle' classes={{ vertical: classes.divider }} orientation={'vertical'} />
                <div className={classes.ticketPreview}>
                    {ReactComponentPrinter.print(
                        createOrderTicketPrint({
                            order,
                            restaurant: {
                                ...restaurant,
                                ticketLogoImage: ticketLogoImageForm,
                                ticketCustomHeader,
                                ticketCustomFooter,
                                ticketSections: activeTicketSections,
                            },
                            ticketSections: activeTicketSections,
                            printerPaperSize: PrinterPaperSizes['80_MM'], // TODO support media queries to support both printer paper sizes
                            internalUser: false,
                        }),
                    )}
                </div>
            </section>
        </div>
    );
}

const useStyles = makeStyles((theme) => ({
    ticketTab: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        paddingBottom: 80,
        [theme.breakpoints.down('md')]: {
            padding: '0 20px',
        },
    },
    ticketPreview: {
        display: 'flex',
        width: '100%',
        maxWidth: 420,
        justifyContent: 'center',
    },
    title: {
        fontFamily: theme.typography.bold,
        color: '#616B79',
        fontSize: 20,
    },
    subtitle: {
        fontFamily: theme.typography.regular,
        color: '#616B79',
        fontSize: 16,
    },
    divider: {
        backgroundColor: theme.palette.surface.brand,
        width: 2,
        height: 'auto',
        [theme.breakpoints.down('md')]: {
            display: 'none',
        },
    },
    header: {
        display: 'flex',
        flexDirection: 'column',
        marginBottom: 20,
        [theme.breakpoints.up('sm')]: {
            flexDirection: 'row',
            justifyContent: 'space-between',
        },
    },
    main: {
        display: 'flex',
        width: '100%',
        flexDirection: 'column',
        [theme.breakpoints.up('sm')]: {
            flexDirection: 'row',
            justifyContent: 'space-between',
        },
    },
    form: {
        width: '100%',
    },
}));
