import { makeStyles } from '@material-ui/core/styles';
import { Table, Text } from '@pidedirecto/ui';
import { FormCurrencyNumberStringField, FormHiddenField } from '@pidedirecto/ui/form';
import { useFieldArray, useFormContext } from '@pidedirecto/ui/hooks';
import { useEffect } from 'react';
import * as React from 'react';
import { CashRegisterExpectedAmountVm } from 'src/api/letseatmanager/pos/getCashRegisterExpectedAmountsApi';
import { RolePermissions } from 'src/constants/RolePermission';
import { translate } from 'src/i18n/translate';
import { useExpectedAmounts } from 'src/services/posBusinessDay/useExpectedAmounts';
import { useFormatAsRestaurantCurrencyNumber } from 'src/services/restaurant/useFormatAsRestaurantCurrencyNumber';
import { isCashPayment } from 'src/utils/paymentMethod/isCashPayment';
import { useSelector } from 'src/utils/react/useSelector';
import { useUserHasRolePermission } from 'src/utils/react/useUserHasRolePermissions';
import { useIsSmallScreen } from 'src/utils/react/window/useIsSmallScreen';
import { isIntegrationApp } from 'src/utils/restaurant/isIntegrationApp';
import { emptyStringToUndefined } from 'src/utils/string/emptyStringToUndefined';

export function CashRegisterIntegrationExpectedAmountsTable({ disabled }: Props): React.ReactElement | null {
    const classes = useStyles();
    const isSmallScreen = useIsSmallScreen();
    const [userHasRolePermission] = useUserHasRolePermission();
    const { control } = useFormContext();
    const expectedAmounts = useExpectedAmounts();
    const formatAsCurrencyNumber = useFormatAsRestaurantCurrencyNumber();

    const currency = useSelector((state) => state.app.restaurant?.currencyFormat?.currency);
    const country = useSelector((state) => state.app.restaurant?.country);

    const includeBreakdownIntegrationsInPosBusinessDayEnabled = useSelector((state) => state.app.restaurant?.includeBreakdownIntegrationsInPosBusinessDayEnabled);
    const posOnlyCashInCashRegisterReportEnabled = useSelector((state) => state.pos.context?.posOnlyCashInCashRegisterReportEnabled);

    const { fields: cashRegisterIntegrationsReportFields, append: appendIntegrationsReport } = useFieldArray({
        control,
        name: 'cashRegisterIntegrationsReport',
    });

    const hideExpectedAmount = userHasRolePermission(RolePermissions.BLIND_CASH_REGISTER_SHIFT);

    let columns = [
        {
            id: 'paymentMethodAmount',
            content: translate('Payment Method'),
        },
        {
            id: 'expectedAmount',
            content: translate('Expected Amount'),
        },
    ];

    useEffect(() => {
        if (expectedAmounts && !cashRegisterIntegrationsReportFields?.length) {
            expectedAmounts?.forEach((report: CashRegisterExpectedAmountVm) => {
                const isCashPaymentReport = isCashPayment(report.paymentMethod) && !report.customPaymentMethod;
                const shouldAppendToIntegrations = (!posOnlyCashInCashRegisterReportEnabled || isCashPaymentReport) && isIntegrationApp(report.app!);

                if (shouldAppendToIntegrations) {
                    appendIntegrationsReport({ paymentMethod: report.paymentMethod, customPaymentMethod: report.customPaymentMethod, amount: '', app: report.app });
                }
            });
        }
    }, [expectedAmounts]);

    if (!isSmallScreen) {
        columns = [
            ...columns,
            {
                id: 'difference',
                content: translate('Difference'),
            },
        ];
    }

    if (!includeBreakdownIntegrationsInPosBusinessDayEnabled || !cashRegisterIntegrationsReportFields?.length) return null;

    return (
        <Table
            columns={columns}
            rows={cashRegisterIntegrationsReportFields?.map((report: any, idx: any) => {
                const expectedPayment = expectedAmounts?.find((expectedAmount: CashRegisterExpectedAmountVm) => {
                    const isSamePayment =
                        expectedAmount.paymentMethod === report?.paymentMethod && emptyStringToUndefined(expectedAmount.customPaymentMethod) === emptyStringToUndefined(report?.customPaymentMethod);
                    const isSameApp = emptyStringToUndefined(expectedAmount.app) === emptyStringToUndefined(report?.app);

                    return isSamePayment && isSameApp;
                });
                const hideExpectedCashAmount =
                    userHasRolePermission(RolePermissions.BLIND_CASH_REGISTER_SHIFT_FOR_CASH_ONLY) && isCashPayment(expectedPayment?.customPaymentMethod ?? expectedPayment?.paymentMethod);

                return {
                    paymentMethodAmount: (
                        <div>
                            <FormHiddenField name={`cashRegisterIntegrationsReport.${idx}.paymentMethod`} defaultValue={report.paymentMethod} />
                            <FormHiddenField name={`cashRegisterIntegrationsReport.${idx}.customPaymentMethod`} defaultValue={report.customPaymentMethod} />
                            <FormHiddenField name={`cashRegisterIntegrationsReport.${idx}.app`} defaultValue={report.app} />
                            <Text>{translate(`Apps.${report.app}`)}</Text>
                            <FormCurrencyNumberStringField
                                name={`cashRegisterIntegrationsReport.${idx}.amount`}
                                label={translate(expectedPayment?.customPaymentMethod ?? expectedPayment?.paymentMethod)}
                                placeholder={'0'}
                                required
                                disabled={disabled}
                                country={country}
                            />
                        </div>
                    ),
                    expectedAmount:
                        hideExpectedCashAmount || hideExpectedAmount ? (
                            '***'
                        ) : (
                            <div className={classes.expectedAmount}>
                                <div>{formatAsCurrencyNumber(expectedPayment?.amount, currency)}</div>
                            </div>
                        ),
                    difference: '***',
                    key: report.id,
                };
            })}
        />
    );
}

const useStyles = makeStyles((theme) => ({
    expectedAmount: {
        fontFamily: theme.typography.regular,
        width: '100%',
        display: 'flex',
        alignItems: 'center',
    },
}));

type Props = {
    disabled: boolean;
};
