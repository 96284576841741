import { formatAsCurrencyNumber } from '@pidedirecto/ui/utils';
import { BigNumber } from 'bignumber.js';
import { PosBusinessDaySections } from 'src/constants/PosBusinessDaySections';
import { translate } from 'src/i18n/translate';
import { printerInstructionsBuilder } from 'src/services/printer/prints/utils/printerInstructionsBuilder';
import type { PrinterInstruction } from 'src/services/printer/types/PrinterInstruction';
import { CashRegisterPosBusinessDayReportVm } from 'src/types/CashRegisterPosBusinessDayReportVm';
import type { PosBusinessDayReportVm } from 'src/types/PosBusinessDayReportVm';
import type { RestaurantVm } from 'src/types/RestaurantVm';
import { formatDateTimeString } from 'src/utils/date/formatDateTimeString';
import { isCardPayment } from 'src/utils/paymentMethod/isCardPayment';
import { isIntegrationApp } from 'src/utils/restaurant/isIntegrationApp';
import { translateCashRegisterDepositReasons } from 'src/utils/translate/translateCashRegisterDepositReasons';
import { translateCashRegisterWithdrawReasons } from 'src/utils/translate/translateCashRegisterWithdrawReasons';

export function createPosBusinessDayReportPrint({ posBusinessDay, restaurant }: Params): Array<PrinterInstruction> {
    const builder = printerInstructionsBuilder();

    addRestaurantInfoToReport();
    addGeneralInfo();
    addPaymentsReceived();
    addCashRegisterTransactions();
    addPaymentMethodsBreakdown();
    addCreditCardBreakdown();
    addIntegrationsBreakDown();
    addSalesByOrderType();
    addSalesByChannel();
    addSalesByManagerUser();
    addSalesByProduct();
    addPromoCodeSummary();
    addCancellationsReport();
    addCashRegisterReport();
    builder.addCenteredText(translate('With technology from Ambit.la'));

    return builder.build();

    function addRestaurantInfoToReport(): void {
        const { restaurantInformation } = posBusinessDay;
        if (restaurant.posBusinessDaySections?.includes(PosBusinessDaySections.RESTAURANT_NAME)) builder.addCenteredBoldText(restaurantInformation.name);

        if (restaurant.posBusinessDaySections?.includes(PosBusinessDaySections.BUSINESS_NAME) && restaurantInformation.businessName) {
            builder.addCenteredText(restaurant.businessName);
        }
        if (restaurant.posBusinessDaySections?.includes(PosBusinessDaySections.RFC) && restaurantInformation.rfc) {
            builder.addCenteredText(restaurant.rfc);
        }
        if (restaurant.posBusinessDaySections?.includes(PosBusinessDaySections.RESTAURANT_STREET) && restaurantInformation.fiscalAddress) {
            builder.addCenteredText(restaurantInformation.fiscalAddress);
        }
    }

    function addGeneralInfo(): void {
        builder.addLineSeparator();
        builder.addCenteredBoldText(translate('END OF DAY'));
        builder.addLineSeparator();

        if (restaurant.posBusinessDaySections?.includes(PosBusinessDaySections.DATE_OF_PRINT)) builder.addCenteredBoldText(formatDateTimeString(new Date()));
        if (restaurant.posBusinessDaySections?.includes(PosBusinessDaySections.OPENING_DATE)) {
            builder.addSeparatedTexts(translate('Opened'), formatDateTimeString(posBusinessDay.businessDaySummary?.openedAt));
            if (posBusinessDay.businessDaySummary?.openedBy) builder.addSeparatedTexts(translate('Opened by'), posBusinessDay.businessDaySummary?.openedBy);
        }
        if (restaurant.posBusinessDaySections?.includes(PosBusinessDaySections.CLOSING_DATE)) {
            builder.addSeparatedTexts(translate('Closed'), formatDateTimeString(posBusinessDay.businessDaySummary?.closedAt));
            if (posBusinessDay.businessDaySummary?.closedBy) builder.addSeparatedTexts(translate('Closed by'), posBusinessDay.businessDaySummary?.closedBy);
        }
        if (restaurant.posBusinessDaySections?.includes(PosBusinessDaySections.SALES)) {
            builder.addSeparatedTexts(translate('Gross Sales'), formatAsCurrencyNumber(posBusinessDay.businessDaySummary?.grossSales, { country: restaurant.country as any }) ?? 0);
            builder.addSeparatedTexts(translate('Delivery costs'), formatAsCurrencyNumber(posBusinessDay.businessDaySummary?.totalDeliveryCosts, { country: restaurant.country as any }));
            builder.addSeparatedTexts(translate('Total discounts'), formatAsCurrencyNumber(posBusinessDay.businessDaySummary?.totalDiscounts, { country: restaurant.country as any }));
            builder.addSeparatedTexts(translate('Total Sales'), formatAsCurrencyNumber(posBusinessDay.businessDaySummary?.totalSales, { country: restaurant.country as any }));
            if (posBusinessDay.businessDaySummary?.initialOrderId) builder.addSeparatedTexts(translate('Initial Folio'), posBusinessDay.businessDaySummary?.initialOrderId);
            if (posBusinessDay.businessDaySummary?.finalOrderId) builder.addSeparatedTexts(translate('Final Folio'), posBusinessDay.businessDaySummary?.finalOrderId);
        }
        if (restaurant.posBusinessDaySections?.includes(PosBusinessDaySections.TIP))
            builder.addSeparatedTexts(translate('Tip'), formatAsCurrencyNumber(posBusinessDay.businessDaySummary?.totalTip ?? 0, { country: restaurant.country as any }));
        if (restaurant.posBusinessDaySections?.includes(PosBusinessDaySections.ORDERS) && posBusinessDay.businessDaySummary?.orders)
            builder.addSeparatedTexts(translate('Orders'), posBusinessDay.businessDaySummary?.orders);
        if (restaurant.posBusinessDaySections?.includes(PosBusinessDaySections.ORDERS) && posBusinessDay.businessDaySummary?.averageTicket)
            builder.addSeparatedTexts(translate('Average Ticket'), formatAsCurrencyNumber(posBusinessDay.businessDaySummary?.averageTicket, { country: restaurant.country as any }));
        if (restaurant.posBusinessDaySections?.includes(PosBusinessDaySections.DINNERS)) builder.addSeparatedTexts(translate('Diners'), posBusinessDay.businessDaySummary?.diners?.toString() ?? 0);
        if (restaurant.posBusinessDaySections?.includes(PosBusinessDaySections.DINNERS))
            builder.addSeparatedTexts(translate('Average consumption'), formatAsCurrencyNumber(posBusinessDay.businessDaySummary?.averageSalesByConsumer, { country: restaurant.country as any }));
        if (restaurant.posBusinessDaySections?.includes(PosBusinessDaySections.CANCELLED_ORDERS))
            builder.addSeparatedTexts(translate('Cancelled orders'), posBusinessDay.businessDaySummary?.totalCancelledOrders);
        if (restaurant.posBusinessDaySections?.includes(PosBusinessDaySections.CANCELLED_ORDERS))
            builder.addSeparatedTexts(translate('Cancelled sales'), formatAsCurrencyNumber(posBusinessDay.businessDaySummary?.totalCancelledSales, { country: restaurant.country as any }));
    }

    function addPaymentsReceived(): void {
        if (!restaurant.posBusinessDaySections?.includes(PosBusinessDaySections.PAYMENTS_RECEIVED)) return;
        builder.addLineSeparator();
        builder.addCenteredBoldText(translate('PAYMENTS RECEIVED'));
        builder.addLineSeparator();
        builder.addBoldSeparatedTexts(translate('Medium'), translate('Total'));

        builder.addSeparatedTexts(
            translate('Cash received in store'),
            formatAsCurrencyNumber(posBusinessDay.businessDayPaymentsSummary?.totalCashReceived?.total, { country: restaurant.country as any }),
        );
        builder.addSeparatedTexts(translate('Cash change'), formatAsCurrencyNumber(posBusinessDay.businessDayPaymentsSummary?.totalCashChange, { country: restaurant.country as any }));
        builder.addSeparatedTexts(
            translate('Card in store'),
            formatAsCurrencyNumber(posBusinessDay.businessDayPaymentsSummary?.totalPosAndKioskCreditCardReceived?.total, { country: restaurant.country as any }),
        );
        builder.addSeparatedTexts(
            translate('Card online'),
            formatAsCurrencyNumber(posBusinessDay.businessDayPaymentsSummary?.totalOnlineCreditCardReceived?.total, { country: restaurant.country as any }),
        );
        posBusinessDay.businessDayPaymentsSummary?.totalCustomPaymentMethodsReceived?.forEach((customPayment: any) => {
            builder.addSeparatedTexts(customPayment.customPaymentMethod, formatAsCurrencyNumber(customPayment?.total, { country: restaurant.country as any }));
        });
        builder.addSeparatedTexts(
            translate('Integrations'),
            formatAsCurrencyNumber(posBusinessDay.businessDayPaymentsSummary?.totalIntegrationsReceived?.total, { country: restaurant.country as any }),
        );
        builder.addBoldSeparatedTexts(translate('Total'), formatAsCurrencyNumber(posBusinessDay.businessDayPaymentsSummary?.grandTotal?.total, { country: restaurant.country as any }));
    }

    function addCashRegisterTransactions(): void {
        if (!restaurant.posBusinessDaySections?.includes(PosBusinessDaySections.CASH_REGISTER_MOVEMENTS)) return;

        builder.addLineSeparator();
        builder.addCenteredBoldText(translate('CASH REGISTER MOVEMENTS'));
        builder.addLineSeparator();
        builder.addSeparatedTexts(translate('Initial Amount'), formatAsCurrencyNumber(posBusinessDay.cashRegisterTransactionSummary?.initialAmount, { country: restaurant.country as any }));
        builder.addSeparatedTexts(translate('Cash sales'), formatAsCurrencyNumber(posBusinessDay.cashRegisterTransactionSummary?.cashSales ?? 0, { country: restaurant.country as any }));
        builder.addSeparatedTexts(translate('Total deposits'), formatAsCurrencyNumber(posBusinessDay.cashRegisterTransactionSummary?.deposits ?? 0, { country: restaurant.country as any }));
        builder.addSeparatedTexts(translate('Total withdraws'), formatAsCurrencyNumber(posBusinessDay.cashRegisterTransactionSummary?.withdraws ?? 0, { country: restaurant.country as any }));
        builder.addSeparatedTexts(translate('Total cash tip'), formatAsCurrencyNumber(posBusinessDay.cashRegisterTransactionSummary?.totalCashTips ?? 0, { country: restaurant.country as any }));
        builder.addSeparatedTexts(translate('Total card tip'), formatAsCurrencyNumber(posBusinessDay.cashRegisterTransactionSummary?.totalCreditCardTips ?? 0, { country: restaurant.country as any }));
        builder.addSeparatedTexts(
            translate('Total custom tip'),
            formatAsCurrencyNumber(posBusinessDay.cashRegisterTransactionSummary?.totalCustomPaymentMethodsTips ?? 0, { country: restaurant.country as any }),
        );
        builder.addSeparatedTexts(translate('Total Balance'), formatAsCurrencyNumber(posBusinessDay?.cashRegisterTransactionSummary.totalCash ?? 0, { country: restaurant.country as any }));
        builder.addNewLine();

        if (!!posBusinessDay.cashRegisterTransactions?.length) {
            builder.addBoldText(translate('Cash register movements'));
            posBusinessDay.cashRegisterTransactions?.forEach((cashRegisterTransaction: any) => {
                const isDeposit = BigNumber(cashRegisterTransaction?.amount ?? 0).isPositive();
                const reason = isDeposit ? translateCashRegisterDepositReasons(cashRegisterTransaction.reason) : translateCashRegisterWithdrawReasons(cashRegisterTransaction.reason);
                builder.addSeparatedTexts(reason, formatAsCurrencyNumber(cashRegisterTransaction.amount, { country: restaurant.country as any }));
            });
        }
    }

    function addPaymentMethodsBreakdown(): void {
        if (!restaurant.posBusinessDaySections?.includes(PosBusinessDaySections.SALES_BY_PAYMENT)) return;
        if (!posBusinessDay?.paymentMethodsBreakdown?.posBusinessDayPaymentMethodBreakdown?.length) return;
        const posBusinessDayPaymentMethodsBreakdown = posBusinessDay?.paymentMethodsBreakdown?.posBusinessDayPaymentMethodBreakdown;
        const integrationsBreakdown = posBusinessDay?.paymentMethodsBreakdown?.integrationsBreakdown;

        if (!posBusinessDayPaymentMethodsBreakdown && !integrationsBreakdown) return;
        builder.addLineSeparator();
        builder.addCenteredBoldText(translate('Payment methods breakdown'));
        builder.addLineSeparator();

        builder.addColumns([
            {
                percentageWidth: 0.3,
                text: translate('Medium'),
            },
            {
                percentageWidth: 0.2,
                text: translate('Orders'),
            },
            {
                percentageWidth: 0.2,
                text: translate('Tip'),
            },
            {
                percentageWidth: 0.3,
                text: translate('Total'),
            },
        ]);

        posBusinessDayPaymentMethodsBreakdown?.forEach((paymentBreakdown: any) => {
            const paymentMethodAppLabel = isCardPayment(paymentBreakdown.paymentMethod) ? translate(paymentBreakdown.app) : '';

            builder.addColumns([
                {
                    percentageWidth: 0.3,
                    text: `${translate(paymentBreakdown.paymentMethod)} ${paymentMethodAppLabel}`,
                },
                {
                    percentageWidth: 0.2,
                    text: `${parseInt(paymentBreakdown.orders)}`,
                },
                {
                    percentageWidth: 0.2,
                    text: formatAsCurrencyNumber(paymentBreakdown.totalTips, { country: restaurant.country as any }),
                },
                {
                    percentageWidth: 0.3,
                    text: formatAsCurrencyNumber(paymentBreakdown.total, { country: restaurant.country as any }),
                },
            ]);
        });

        if (integrationsBreakdown) {
            integrationsBreakdown?.forEach((integration: any) => {
                builder.addColumns([
                    {
                        percentageWidth: 0.3,
                        text: translate(integration.integration),
                    },
                    {
                        percentageWidth: 0.2,
                        text: `${parseInt(integration.orders)}`,
                    },
                    {
                        percentageWidth: 0.2,
                        text: formatAsCurrencyNumber(integration.totalTips, { country: restaurant.country as any }),
                    },
                    {
                        percentageWidth: 0.3,
                        text: formatAsCurrencyNumber(integration.total, { country: restaurant.country as any }),
                    },
                ]);
            });
        }

        builder.addColumns([
            {
                percentageWidth: 0.3,
                text: translate('Total'),
            },
            {
                percentageWidth: 0.2,
                text: `${parseInt(posBusinessDay?.paymentMethodsBreakdown?.grandTotal.orders ?? '0')}`,
            },
            {
                percentageWidth: 0.2,
                text: formatAsCurrencyNumber(posBusinessDay?.paymentMethodsBreakdown?.grandTotal.totalTips, { country: restaurant.country as any }),
            },
            {
                percentageWidth: 0.3,
                text: formatAsCurrencyNumber(posBusinessDay?.paymentMethodsBreakdown?.grandTotal.total, { country: restaurant.country as any }),
            },
        ]);
    }

    function addCreditCardBreakdown(): void {
        if (!restaurant.posBusinessDaySections?.includes(PosBusinessDaySections.CARD_BREAKDOWN)) return;
        if (!posBusinessDay.creditCardOrders?.creditCardOrders?.length) return;

        const creditCardsBreakdown = posBusinessDay.creditCardOrders?.creditCardOrders;

        builder.addLineSeparator();
        builder.addCenteredBoldText(translate('Credit card orders breakdown'));
        builder.addLineSeparator();

        builder.addColumns([
            {
                percentageWidth: 0.25,
                text: translate('Type'),
            },
            {
                percentageWidth: 0.25,
                text: translate('Order Id'),
            },
            {
                percentageWidth: 0.2,
                text: translate('Tip'),
            },
            {
                percentageWidth: 0.3,
                text: translate('Total'),
            },
        ]);

        creditCardsBreakdown?.forEach((cardReport: any) => {
            builder.addColumns([
                {
                    percentageWidth: 0.25,
                    text: translate(`CardTypes.${cardReport.cardType}`),
                },
                {
                    percentageWidth: 0.25,
                    text: `#${cardReport.orderId}`,
                },
                {
                    percentageWidth: 0.2,
                    text: formatAsCurrencyNumber(cardReport.totalTips, { country: restaurant.country as any }),
                },

                {
                    percentageWidth: 0.3,
                    text: formatAsCurrencyNumber(cardReport.total, { country: restaurant.country as any }),
                },
            ]);
        });

        builder.addColumns([
            {
                percentageWidth: 0.25,
                text: translate('Total'),
            },
            {
                percentageWidth: 0.25,
                text: posBusinessDay.creditCardOrders?.grandTotal.orders,
            },
            {
                percentageWidth: 0.2,
                text: formatAsCurrencyNumber(posBusinessDay.creditCardOrders?.grandTotal.totalTips, { country: restaurant.country as any }),
            },
            {
                percentageWidth: 0.3,
                text: formatAsCurrencyNumber(posBusinessDay.creditCardOrders?.grandTotal.total, { country: restaurant.country as any }),
            },
        ]);
    }

    function addIntegrationsBreakDown(): void {
        if (!restaurant.posBusinessDaySections?.includes(PosBusinessDaySections.INTEGRATION_BREAKDOWN)) return;
        if (!posBusinessDay.integrationOrdersReport?.integrationOrders?.length) return;

        const integrationOrdersBreakdown = posBusinessDay.integrationOrdersReport?.integrationOrders;

        if (!integrationOrdersBreakdown || !integrationOrdersBreakdown?.length) return;
        builder.addLineSeparator();
        builder.addCenteredBoldText(translate('Integration orders breakdown'));
        builder.addLineSeparator();

        builder.addColumns([
            {
                percentageWidth: 0.35,
                text: translate('Integration'),
            },
            {
                percentageWidth: 0.3,
                text: translate('Order Id'),
            },
            {
                percentageWidth: 0.35,
                text: translate('Total'),
            },
        ]);

        integrationOrdersBreakdown?.forEach((integration: any) => {
            builder.addColumns([
                {
                    percentageWidth: 0.35,
                    text: translate(integration.integration),
                },
                {
                    percentageWidth: 0.3,
                    text: `#${integration.orderId}`,
                },
                {
                    percentageWidth: 0.35,
                    text: formatAsCurrencyNumber(integration.total, { country: restaurant.country as any }),
                },
            ]);
        });

        builder.addColumns([
            {
                percentageWidth: 0.35,
                text: translate('Total'),
            },
            {
                percentageWidth: 0.3,
                text: posBusinessDay.integrationOrdersReport?.grandTotal.orders,
            },
            {
                percentageWidth: 0.35,
                text: formatAsCurrencyNumber(posBusinessDay.integrationOrdersReport?.grandTotal.total, { country: restaurant.country as any }),
            },
        ]);
    }

    function addSalesByOrderType(): void {
        if (!restaurant.posBusinessDaySections?.includes(PosBusinessDaySections.SALES_BY_ORDER_TYPE)) return;
        const salesByOrderType = posBusinessDay.salesByOrderTypeReport?.salesByOrderType;
        if (!salesByOrderType || !salesByOrderType?.length) return;

        builder.addLineSeparator();
        builder.addBoldText(translate('Sales By Order Type'));
        builder.addLineSeparator();

        builder.addColumns([
            {
                percentageWidth: 0.45,
                text: translate('Type'),
            },
            {
                percentageWidth: 0.2,
                text: translate('Orders'),
            },
            {
                percentageWidth: 0.35,
                text: translate('Total'),
            },
        ]);

        salesByOrderType?.forEach((orderTypeSales: any) => {
            builder.addColumns([
                {
                    percentageWidth: 0.45,
                    text: translate(orderTypeSales.orderType),
                },
                {
                    percentageWidth: 0.2,
                    text: `${parseInt(orderTypeSales.orders)}`,
                },
                {
                    percentageWidth: 0.35,
                    text: formatAsCurrencyNumber(orderTypeSales.total, { country: restaurant.country as any }),
                },
            ]);
        });

        builder.addColumns([
            {
                percentageWidth: 0.45,
                text: translate('Total'),
            },
            {
                percentageWidth: 0.2,
                text: `${parseInt(posBusinessDay.salesByOrderTypeReport?.grandTotal.orders ?? '0')}`,
            },
            {
                percentageWidth: 0.35,
                text: formatAsCurrencyNumber(posBusinessDay.salesByOrderTypeReport?.grandTotal.total, { country: restaurant.country as any }),
            },
        ]);
    }

    function addSalesByChannel(): void {
        if (!restaurant.posBusinessDaySections?.includes(PosBusinessDaySections.SALES_BY_CHANNEL)) return;
        const salesByChannel = posBusinessDay.salesByAppReport?.salesByApp;
        if (!salesByChannel || !salesByChannel?.length) return;

        builder.addLineSeparator();
        builder.addBoldText(translate('Sales By Channel'));
        builder.addLineSeparator();

        builder.addColumns([
            {
                percentageWidth: 0.45,
                text: translate('Channel'),
            },
            {
                percentageWidth: 0.2,
                text: translate('Orders'),
            },
            {
                percentageWidth: 0.35,
                text: translate('Total'),
            },
        ]);

        salesByChannel?.forEach((channelSales: any) => {
            builder.addColumns([
                {
                    percentageWidth: 0.45,
                    text: translate(channelSales.app),
                },
                {
                    percentageWidth: 0.2,
                    text: `${parseInt(channelSales.orders)}`,
                },
                {
                    percentageWidth: 0.35,
                    text: formatAsCurrencyNumber(channelSales.total, { country: restaurant.country as any }),
                },
            ]);
        });

        builder.addColumns([
            {
                percentageWidth: 0.45,
                text: translate('Total'),
            },
            {
                percentageWidth: 0.2,
                text: `${parseInt(posBusinessDay.salesByAppReport?.grandTotal.orders ?? '0')}`,
            },
            {
                percentageWidth: 0.35,
                text: formatAsCurrencyNumber(posBusinessDay.salesByAppReport?.grandTotal.total, { country: restaurant.country as any }),
            },
        ]);
    }

    function addSalesByManagerUser(): void {
        if (!restaurant.posBusinessDaySections?.includes(PosBusinessDaySections.SALES_BY_MANAGER_USER)) return;
        const salesByManagerUser = posBusinessDay.salesByManagerUserReport?.salesByManagerUser;
        if (!salesByManagerUser || !salesByManagerUser?.length) return;

        builder.addLineSeparator();
        builder.addBoldText(translate('Sales by User'));
        builder.addLineSeparator();

        builder.addColumns([
            {
                percentageWidth: 0.45,
                text: translate('Employee'),
            },
            {
                percentageWidth: 0.2,
                text: translate('Orders'),
            },
            {
                percentageWidth: 0.35,
                text: translate('Total'),
            },
        ]);

        salesByManagerUser?.forEach((managerSales: any) => {
            builder.addColumns([
                {
                    percentageWidth: 0.45,
                    text: managerSales.managerUser,
                },
                {
                    percentageWidth: 0.2,
                    text: `${parseInt(managerSales.orders)}`,
                },
                {
                    percentageWidth: 0.35,
                    text: formatAsCurrencyNumber(managerSales.total, { country: restaurant.country as any }),
                },
            ]);
        });

        builder.addColumns([
            {
                percentageWidth: 0.45,
                text: translate('Total'),
            },
            {
                percentageWidth: 0.2,
                text: posBusinessDay.salesByManagerUserReport?.grandTotal?.orders,
            },
            {
                percentageWidth: 0.35,
                text: formatAsCurrencyNumber(posBusinessDay.salesByManagerUserReport?.grandTotal?.total, { country: restaurant.country as any }),
            },
        ]);
    }

    function addSalesByProduct(): void {
        if (!restaurant.posBusinessDaySections?.includes(PosBusinessDaySections.TOP_SALES_PRODUCT)) return;
        const salesByItem = posBusinessDay.salesByItemReport?.salesByItem;
        if (!salesByItem || !salesByItem?.length) return;

        builder.addLineSeparator();
        builder.addBoldText(translate('Sales By Product'));
        builder.addLineSeparator();

        builder.addColumns([
            {
                percentageWidth: 0.4,
                text: translate('Name'),
            },
            {
                percentageWidth: 0.2,
                text: translate('Price'),
            },
            {
                percentageWidth: 0.2,
                text: translate('Orders'),
            },
            {
                percentageWidth: 0.2,
                text: translate('Total'),
            },
        ]);

        salesByItem?.forEach((itemSales: any) => {
            builder.addColumns([
                {
                    percentageWidth: 0.4,
                    text: itemSales.name,
                },
                {
                    percentageWidth: 0.2,
                    text: formatAsCurrencyNumber(itemSales.itemPrice, { country: restaurant.country as any }),
                },
                {
                    percentageWidth: 0.2,
                    text: `${parseInt(itemSales.orders)}`,
                },
                {
                    percentageWidth: 0.2,
                    text: formatAsCurrencyNumber(itemSales.total, { country: restaurant.country as any }),
                },
            ]);
        });
    }

    function addPromoCodeSummary(): void {
        if (!restaurant.posBusinessDaySections?.includes(PosBusinessDaySections.PROMO_CODES_SUMMARY)) return;
        const promoCodeReport = posBusinessDay.promoCodeReport;
        if (!promoCodeReport || !promoCodeReport?.length) return;

        builder.addLineSeparator();
        builder.addBoldText(translate('Promo Codes'));
        builder.addLineSeparator();

        builder.addColumns([
            {
                percentageWidth: 0.35,
                text: translate('Code'),
            },
            {
                percentageWidth: 0.3,
                text: translate('Usage'),
            },
            {
                percentageWidth: 0.35,
                text: translate('Total'),
            },
        ]);

        promoCodeReport?.forEach((managerSales: any) => {
            builder.addColumns([
                {
                    percentageWidth: 0.35,
                    text: managerSales.code,
                },
                {
                    percentageWidth: 0.3,
                    text: `${managerSales.usage}`,
                },
                {
                    percentageWidth: 0.35,
                    text: formatAsCurrencyNumber(managerSales.amount, { country: restaurant.country as any }),
                },
            ]);
        });

        builder.addColumns([
            {
                percentageWidth: 0.3,
                text: translate('Total'),
            },
            {
                percentageWidth: 0.35,
                text: posBusinessDay.salesByManagerUserReport?.grandTotal?.orders,
            },
            {
                percentageWidth: 0.35,
                text: formatAsCurrencyNumber(posBusinessDay.salesByManagerUserReport?.grandTotal?.total, { country: restaurant.country as any }),
            },
        ]);
    }

    function addCancellationsReport(): void {
        if (!restaurant.posBusinessDaySections?.includes(PosBusinessDaySections.CANCELLED_ORDERS)) return;
        const cancelledOrders = posBusinessDay.cancelledOrdersReport?.cancelledOrders;
        if (!cancelledOrders?.length) return;

        builder.addLineSeparator();
        builder.addBoldText(translate('Cancellations'));
        builder.addLineSeparator();

        builder.addSeparatedTexts(translate('Order Id'), translate('Total'));

        cancelledOrders?.forEach((cancelledOrder: any) => {
            builder.addSeparatedTexts(`#${cancelledOrder.orderId}`, cancelledOrder.total);
        });

        builder.addSeparatedTexts(translate('Total'), posBusinessDay.cancelledOrdersReport?.grandTotal?.total);
    }

    function addCashRegisterReport(): void {
        if (!restaurant.posBusinessDaySections?.includes(PosBusinessDaySections.CASH_REGISTER_COUNTING)) return;

        builder.addLineSeparator();
        builder.addCenteredBoldText(translate('CASH REGISTER COUNTING'));
        builder.addLineSeparator();

        posBusinessDay.cashRegistersSummaries?.forEach((cashRegister: any) => {
            let totalDifference = '0';
            let totalAmount = '0';

            if (cashRegister.openedBy) builder.addSeparatedTexts(translate('Opened by'), cashRegister.openedBy);
            if (cashRegister.closedBy) builder.addSeparatedTexts(translate('Closed by'), cashRegister.closedBy);

            addCashRegisterReportInfoHeader({
                paymentMethod: translate('Medium'),
                amount: translate('Amount'),
                expected: translate('Exp.'),
                difference: translate('Diff'),
            });

            cashRegister.paymentMethodReports?.forEach((paymentMethodReport: any) => {
                const difference = (parseFloat(paymentMethodReport.amount) - parseFloat(paymentMethodReport?.expectedAmount)).toString();
                totalDifference = (parseFloat(totalDifference) + parseFloat(difference)).toString();
                totalAmount = (parseFloat(totalAmount) + parseFloat(paymentMethodReport.amount)).toString();
                let paymentMethod = translate(paymentMethodReport.customPaymentMethod ?? paymentMethodReport.paymentMethod);
                const paymentMethodApp = isIntegrationApp(paymentMethodReport.app!) ? translate(paymentMethodReport.app) : '';
                paymentMethod = !!paymentMethodApp ? `${paymentMethod} ${paymentMethodApp}` : paymentMethod;

                addCashRegisterReportInfo({
                    paymentMethod,
                    amount: formatAsCurrencyNumber(paymentMethodReport.amount ?? 0, { country: restaurant.country as any }),
                    expected: formatAsCurrencyNumber(paymentMethodReport.expectedAmount ?? 0, { country: restaurant.country as any }),
                    difference: formatAsCurrencyNumber(difference ?? 0, { country: restaurant.country as any }),
                });
            });

            addCashRegisterReportInfoTotal({
                paymentMethod: translate('Total'),
                amount: formatAsCurrencyNumber(totalAmount ?? 0, { country: restaurant.country as any }),
                expected: formatAsCurrencyNumber(Number(cashRegister.totalSales) ?? 0, { country: restaurant.country as any }),
                difference: formatAsCurrencyNumber(totalDifference ?? 0, { country: restaurant.country as any }),
            });
            builder.addLineSeparator();
        });
    }

    function addCashRegisterReportInfoHeader(cashRegisterReport: CashRegisterReportRow): void {
        builder.addBoldColumns([
            {
                percentageWidth: 0.2,
                text: cashRegisterReport.paymentMethod,
                textAlign: 'left',
            },
            {
                percentageWidth: 0.26,
                text: cashRegisterReport.amount,
                textAlign: 'right',
            },
            {
                percentageWidth: 0.27,
                text: cashRegisterReport.expected,
                textAlign: 'right',
            },
            {
                percentageWidth: 0.27,
                text: cashRegisterReport.difference,
                textAlign: 'right',
            },
        ]);
    }

    function addCashRegisterReportInfo(cashRegisterReport: CashRegisterReportRow): void {
        builder.addColumns([
            {
                percentageWidth: 0.2,
                text: cashRegisterReport.paymentMethod,
                textAlign: 'left',
            },
            {
                percentageWidth: 0.26,
                text: cashRegisterReport.amount,
                textAlign: 'right',
            },
            {
                percentageWidth: 0.27,
                text: cashRegisterReport.expected,
                textAlign: 'right',
            },
            {
                percentageWidth: 0.27,
                text: cashRegisterReport.difference,
                textAlign: 'right',
            },
        ]);
    }

    function addCashRegisterReportInfoTotal(cashRegisterReport: CashRegisterReportRow): void {
        builder.addBoldColumns([
            {
                percentageWidth: 0.2,
                text: cashRegisterReport.paymentMethod,
                textAlign: 'left',
            },
            {
                percentageWidth: 0.26,
                text: cashRegisterReport.amount,
                textAlign: 'right',
            },
            {
                percentageWidth: 0.27,
                text: cashRegisterReport.expected,
                textAlign: 'right',
            },
            {
                percentageWidth: 0.27,
                text: cashRegisterReport.difference,
                textAlign: 'right',
            },
        ]);
    }
}

type Params = {
    posBusinessDay: PosBusinessDayReportVm | CashRegisterPosBusinessDayReportVm;
    restaurant: RestaurantVm;
};

type CashRegisterReportRow = {
    paymentMethod: string;
    amount: string;
    expected: string;
    difference: string;
};
