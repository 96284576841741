import type { BrandId } from 'src/types/Id';

export const KEY_NAME = 'brandOptions';

export function getBrandOptionsInLocalStorage(): Array<BrandOption> {
    const item = window.localStorage.getItem('brandOptions');
    return item ? JSON.parse(item) : [];
}

export type BrandOption = {
    label: string;
    value: BrandId;
};
