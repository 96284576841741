import { makeStyles } from '@material-ui/core';
import * as React from 'react';
import type { SalesByAppAndDay } from 'src/api/letseatmanager/restaurantDashboard/getFinanceRestaurantStatisticsApi';
import { Table } from 'src/components/Table';
import { App } from 'src/constants/App';
import { PaymentMethod } from 'src/constants/PaymentMethod';
import { translate } from 'src/i18n/translate';
import { KpiCard } from 'src/scenes/letseatmanager/restaurantDashboard/KpiCard';
import { ReportSection } from 'src/scenes/letseatmanager/restaurantDashboard/ReportSection';
import { useFormatAsRestaurantCurrencyNumber } from 'src/services/restaurant/useFormatAsRestaurantCurrencyNumber';
import { translatePaymentMethod } from 'src/utils/translate/translatePaymentMethod';

export function SalesByPaymentMethodTable({ loading, salesByPaymentMethodData }: Props): React.ReactElement | null {
    const classes = useStyles();
    const formatAsCurrencyNumber = useFormatAsRestaurantCurrencyNumber();

    const paymentMethodWithMostSales = salesByPaymentMethodData.paymentMethodWithMostSales;
    const salesFromPaymentMethodWithMostSales = salesByPaymentMethodData.salesFromPaymentMethodWithMostSales;
    const appFromPaymentMethodWithMostSales = salesByPaymentMethodData.appFromPaymentMethodWithMostSales;
    const salesByAppAndDay = salesByPaymentMethodData.salesByAppAndDay ?? [];
    const hasSalesByAppAndDayData = salesByAppAndDay?.length > 0;

    const salesByPaymentMethodColumns = [
        {
            name: 'createdAt',
            label: translate('Day'),
            options: {
                filter: false,
            },
        },
        {
            name: 'paymentMethod',
            label: translate('Payment Method'),
            options: {
                filter: false,
            },
        },
        {
            name: 'orderVolume',
            label: translate('Orders'),
            options: {
                filter: false,
            },
        },
        {
            name: 'total',
            label: translate('Sales'),
            options: {
                filter: false,
            },
        },
    ];

    if (!hasSalesByAppAndDayData) return null;

    return (
        <ReportSection title={translate('Sales by Payment Method')} loading={loading}>
            <div className={classes.splitContainer}>
                <div className={classes.sectionHeadKpi}>
                    <KpiCard
                        classes={{
                            container: classes.headingKpiContainer,
                            title: classes.headingKpiTitleKpi,
                            body: classes.headingKpiBody,
                            color: classes.headingKpiColor,
                            legend: classes.headingKpiLegendContainer,
                        }}
                        legend={translatePaymentMethod({ paymentMethod: paymentMethodWithMostSales as PaymentMethod, app: appFromPaymentMethodWithMostSales as App })}
                        title={translate('Most used payment method')}
                    />
                    <KpiCard
                        classes={{
                            container: classes.headingKpiContainer,
                            title: classes.headingKpiTitleKpi,
                            body: classes.headingKpiBody,
                            color: classes.headingKpiColor,
                            legend: classes.headingKpiLegendContainer,
                        }}
                        legend={formatAsCurrencyNumber(salesFromPaymentMethodWithMostSales)}
                        title={translate(`Sales with @paymentMethod`, {
                            paymentMethod: translatePaymentMethod({ paymentMethod: paymentMethodWithMostSales as PaymentMethod, app: appFromPaymentMethodWithMostSales as App }),
                        })}
                    />
                </div>
                <div className={classes.container}>
                    <Table
                        data={salesByAppAndDay?.map((dayFinance: SalesByAppAndDay) => ({
                            ...dayFinance,
                            paymentMethod: translatePaymentMethod({ paymentMethod: dayFinance.paymentMethod as PaymentMethod, app: dayFinance.app as App }),
                            total: formatAsCurrencyNumber(dayFinance.total || '0'),
                        }))}
                        columns={salesByPaymentMethodColumns}
                        options={{
                            tableBodyMaxHeight: '350px',
                            search: false,
                            responsive: 'standard',
                            selectableRows: 'none',
                            filter: false,
                            rowsPerPage: 100,
                            viewColumns: false,
                            print: false,
                        }}
                    />
                </div>
            </div>
        </ReportSection>
    );
}

const useStyles = makeStyles((theme) => ({
    container: {
        width: '100%',
    },
    splitContainer: {
        display: 'flex',
        flexWrap: 'wrap',
        alignItems: 'center',
        width: '100%',
        flexDirection: 'row',

        [theme.breakpoints.between('md', 'lg')]: {
            flexDirection: 'row-reverse',
            flexWrap: 'nowrap',
        },
    },
    sectionHeadKpi: {
        width: '100%',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        [theme.breakpoints.between('md', 'lg')]: {
            flexDirection: 'column',
            width: '20%',
        },
    },
    headingKpiContainer: {
        backgroundColor: 'transparent',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        paddingTop: '8px',
        margin: 5,
        textAlign: 'center',
        marginRight: '5%',
        marginBottom: '2%',
    },
    headingKpiTitleKpi: {
        fontFamily: theme.typography.regular,
        fontSize: 16,
        color: theme.palette.text.contrast,
    },
    headingKpiBody: {
        fontSize: 28,
        fontFamily: theme.typography.semiBold,
    },
    headingKpiColor: {
        width: 12,
        height: 12,
        borderRadius: 2,
    },
    headingKpiLegendContainer: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        textAlign: 'center',
        width: '100%',
        [theme.breakpoints.between('sm', 'lg')]: {
            justifyContent: 'left',
            textAlign: 'left',
            marginLeft: 40,
        },
    },
}));

type Props = {
    loading: boolean;
    salesByPaymentMethodData: SalesByPaymentMethodDataType;
};

type SalesByPaymentMethodDataType = {
    paymentMethodWithMostSales: string | undefined;
    salesFromPaymentMethodWithMostSales: string | undefined;
    appFromPaymentMethodWithMostSales: string | undefined;
    salesByAppAndDay: Array<SalesByAppAndDay>;
};
