import { InputBase, InputLabel, makeStyles, Typography, useTheme } from '@material-ui/core';
import FormHelperText from '@material-ui/core/FormHelperText';
import { Button, Loader } from '@pidedirecto/ui';
import { ListIcon, PlusIcon } from '@pidedirecto/ui/icons';
import { Autocomplete, useLoadScript } from '@react-google-maps/api';
import * as React from 'react';
import { useCallback, useEffect, useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { RestaurantCustomerAddress } from 'src/api/letseatmanager/restaurantCustomer/findRestaurantCustomersAddressOptionsApi';
import type { DeliveryEstimate } from 'src/api/types/DeliveryEstimate';
import { getError } from 'src/components/form/utils/getError';
import { RestaurantCustomerAddressRadioGroup } from 'src/components/radio/restaurantCustomer/RestaurantCustomerAddressRadioGroup';
import { RestaurantCustomerAddressSelect } from 'src/components/select/restaurantCustomer/RestaurantCustomerAddressSelect';
import { Text } from 'src/components/Text';
import { googleMapsConfig } from 'src/config/googleMapsConfig';
import { translate } from 'src/i18n/translate';
import { MapIcon } from 'src/icons/MapIcon';
import { GeoJsonPoint } from 'src/types/GeoJsonPoint';
import { toLatLng } from 'src/utils/googlemaps/toLatLng';
import { isEmpty } from 'src/utils/object/isEmpty';
import { useSelector } from 'src/utils/react/useSelector';

// TODO: Type props, also look into possibility to move state inside component and emit changes with new onChangeAddress prop instead
export function FormDeliveryAddressAutocompleteWithMap(props: any): React.ReactElement {
    const classes = useStyles();
    const { isLoaded, loadError } = useLoadScript(googleMapsConfig);
    const { errors, control } = useFormContext();

    const internalUser = useSelector((state) => state.authentication.internalUser);
    const restaurantUser = useSelector((state) => state.authentication.restaurantUser);

    const helperText = withinDeliveryDistance(props.deliveryEstimate) ? undefined : translate(`The distance from the restaurant to the address exceeds the allowed limit`);

    if (!isLoaded) {
        return (
            <div className={classes.loadingContainer}>
                <Loader size={40} loading={true} />
            </div>
        );
    }

    if (loadError) {
        return <Typography>{translate('Failed to load google maps, please refresh page.')}</Typography>;
    }

    return (
        <Controller
            render={({ value, onChange, ...controllerProps }) => (
                <>
                    <Map {...props} value={value} onChange={onChange} errors={errors} controllerProps={controllerProps} helperText={helperText} />
                    {(internalUser || restaurantUser) && props.deliveryEstimate && (
                        <div className={classes.caption}>
                            <span>{`${translate('Driving distance')} : ${props.deliveryEstimate?.drivingDistance} m.`}</span>
                        </div>
                    )}
                </>
            )}
            control={control}
            defaultValue={''}
            name={props.name}
            onFocus={() => {}}
            rules={{
                required: {
                    value: (props.required as any)?.value ?? props.required,
                    message: (props.required as any)?.message ?? translate('This field is required'),
                },
                validate: () => (withinDeliveryDistance(props.deliveryEstimate) ? true : translate(`The distance from the restaurant to the address exceeds the allowed limit`)),
            }}
        />
    );
}

export function Map({ onChange, label, restaurant, disabled, errors, name, value, controllerProps, helperText, customerAddresses, onChangeAddress }: Props): React.ReactElement {
    const classes = useStyles();
    const theme = useTheme();

    const [autocomplete, setAutocomplete] = useState<any>();
    const [customerHasAddresses, setCustomerHasAddresses] = useState<boolean>(false);
    const [isAddingNewAddress, setIsAddingNewAddress] = useState<boolean>(true);
    const [address, setAddress] = useState<string>('');

    const onLoadAutocomplete = useCallback((autocomplete: any) => {
        setAutocomplete(autocomplete);
    }, []);

    const onUnmountAutocomplete = useCallback((autocomplete: any) => {
        setAutocomplete(undefined);
    }, []);

    useEffect(() => {
        if (customerAddresses?.length) {
            setIsAddingNewAddress(false);
            setCustomerHasAddresses(true);

            if (isEmpty(customerAddresses[0])) return;

            handleChangeAddress(customerAddresses[0].street);
        }
    }, [customerAddresses]);

    const onPlaceChanged = () => {
        if (autocomplete) {
            const result = autocomplete.getPlace();
            if (!result.place_id) {
                onChange(undefined);
                return;
            }
            const customerLocation = {
                type: 'Point',
                coordinates: [result.geometry.location.lng() as number, result.geometry.location.lat() as number],
            } as GeoJsonPoint;
            onChange({
                street: result.formatted_address,
                googlePlaceId: result.place_id,
                name: result.name,
                formattedAddress: result.formatted_address,
                location: customerLocation,
            });
            onChangeAddress({
                street: result.formatted_address,
                googlePlaceId: result.place_id,
                formattedAddress: result.formatted_address,
                location: customerLocation,
                number: '',
            });
            setAddress(result.formatted_address);
        } else {
            console.log('Autocomplete is not loaded yet!');
        }
    };

    const formatUrlMap = () => {
        const url = `https://maps.googleapis.com/maps/api/staticmap`;
        const customerMapMarkersUrl = `?markers=${getCustomerMapMarkers()}`;
        const restaurantMapMarkersUrl = `&markers=${getRestaurantMapMarkers()}`;
        return url + customerMapMarkersUrl + restaurantMapMarkersUrl + `&size=600x240&key=${googleMapsConfig.googleMapsApiKey}`;
    };

    const getCustomerMapMarkers = () => {
        return `size:mid|icon:${CUSTOMER_PIN_ICON}|` + getCustomerLocation();
    };

    const getCustomerLocation = () => {
        const customerLocationCoordinates: any = toLatLng(value.location);
        if (!customerLocationCoordinates) return '';
        return `${customerLocationCoordinates.lat},${customerLocationCoordinates.lng}`;
    };

    const getRestaurantMapMarkers = () => {
        return `size:mid|icon:${RESTAURANT_PIN_ICON}|` + getRestaurantLocation();
    };

    const getRestaurantLocation = () => {
        const restaurantLocationCoordinates: any = toLatLng(restaurant.location);
        if (!restaurantLocationCoordinates) return '';
        return `${restaurantLocationCoordinates.lat},${restaurantLocationCoordinates.lng}`;
    };

    const handleChangeAddress = (street: string) => {
        if (customerAddresses?.length) {
            const customerAddress = customerAddresses.find((restaurantCustomerAddress) => restaurantCustomerAddress.street === street);

            if (!customerAddress || !customerAddress?.googlePlaceId) {
                onChange(undefined);
                return;
            }

            onChange({
                street: customerAddress.formattedAddress,
                googlePlaceId: customerAddress.googlePlaceId,
                name: customerAddress.street,
                formattedAddress: customerAddress.formattedAddress,
                location: customerAddress.location,
            });
            onChangeAddress(customerAddress);
            setAddress(street);
        }
    };

    const handeAddNewCustomerAddress = () => {
        setIsAddingNewAddress(!isAddingNewAddress);
        setAddress('');
    };

    return (
        <>
            {customerHasAddresses && (
                <Button classes={{ button: classes.button }} type={'button'} variant={'outline'} onClick={handeAddNewCustomerAddress}>
                    {!isAddingNewAddress ? (
                        <>
                            <PlusIcon title={translate('Add new address')} width={11} heigth={11} />
                            {translate('Add new address')}
                        </>
                    ) : (
                        <>
                            <ListIcon title={translate('Select address')} width={11} heigth={11} />
                            {translate('Select address')}
                        </>
                    )}
                </Button>
            )}

            {isAddingNewAddress && (
                <>
                    <Autocomplete
                        restrictions={{ country: restaurant.country }}
                        onLoad={onLoadAutocomplete}
                        onUnmount={onUnmountAutocomplete}
                        onPlaceChanged={onPlaceChanged}
                        /* @ts-ignore */
                        bounds={new window.google.maps.Circle({ center: toLatLng(restaurant.location), radius: 50000 }).getBounds()}
                        options={{ strictBounds: true }}
                        fields={['name', 'geometry.location', 'place_id', 'formatted_address']}
                    >
                        <div>
                            <InputLabel error={!!getError(errors, name) || (helperText as any)} classes={{ root: classes.label, error: classes.labelError }}>
                                {label ?? translate('Select an Address')}
                            </InputLabel>
                            <InputBase
                                error={(!!getError(errors, name) as any) || helperText}
                                /* @ts-ignore */
                                variant={'outlined'}
                                inputProps={{ autoComplete: 'no' }}
                                inputRef={controllerProps.ref}
                                classes={{ root: classes.input, error: classes.inputError }}
                                onKeyPress={(e) => {
                                    e.key === 'Enter' && e.preventDefault();
                                }}
                                disabled={disabled}
                                value={address}
                                onChange={(e: any) => setAddress(e.target.value)}
                            />
                            {(!!getError(errors, name) || helperText) && <FormHelperText classes={{ root: classes.helperText }}>{getError(errors, name)?.message ?? helperText}</FormHelperText>}
                        </div>
                    </Autocomplete>
                    {!value && (
                        <div className={classes.emptyState}>
                            <MapIcon color={theme.palette.icons.brand} height={40} width={40} />
                            <Text>{translate('Write an address to show the map')}</Text>
                        </div>
                    )}
                </>
            )}

            {!isAddingNewAddress &&
                !!customerAddresses &&
                (customerAddresses.length <= 3 ? (
                    <RestaurantCustomerAddressRadioGroup restaurantCustomerAddresses={customerAddresses} value={address} onChange={handleChangeAddress} />
                ) : (
                    <RestaurantCustomerAddressSelect restaurantCustomerAddresses={customerAddresses} value={address} onChange={handleChangeAddress} />
                ))}

            {!!value && <img className={classes.map} src={formatUrlMap()} />}
        </>
    );
}

const CUSTOMER_PIN_ICON = 'https://images.letseat.mx/b98c0288d1afc02a450f6ddc7c660209.png';
const RESTAURANT_PIN_ICON = 'https://images.letseat.mx/877ea66ba44cc1f788ecb95ec98e11ea.png';

function withinDeliveryDistance(deliveryEstimate?: DeliveryEstimate) {
    return !deliveryEstimate || deliveryEstimate.isWithinDeliveryRadius;
}

const useStyles = makeStyles((theme) => ({
    label: {
        fontFamily: theme.typography.regular,
        color: theme.palette.secondary.contrastText,
        fontSize: 12,
        marginBottom: 8,
        [theme.breakpoints.up('sm')]: {
            fontSize: 14,
        },
    },
    labelError: {
        color: 'red',
    },
    input: {
        width: '100%',
        backgroundColor: 'transparent',
        borderRadius: 4,
        height: 42,
        display: 'flex',
        alignItems: 'center',
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
        fontFamily: theme.typography.regular,
        border: `1px solid ${theme.palette.secondary.dark}`,
        marginBottom: 12,
    },
    button: {
        display: 'flex',
        alignItems: 'center',
        marginBottom: 8,
    },
    inputError: {
        border: '2px solid red',
    },
    helperText: {
        fontFamily: theme.typography.light,
        color: 'red',
    },
    caption: {
        fontFamily: theme.typography.medium,
        marginTop: 20,
    },
    emptyState: {
        height: 240,
        width: '100%',
        backgroundColor: '#F0F0F0',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        gap: 10,
    },
    map: {
        height: 240,
        width: '100%',
        objectFit: 'cover',
        marginTop: 6,
    },
    loadingContainer: {
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
}));

type Props = {
    onChange: any;
    label: string;
    restaurant: any;
    disabled?: boolean;
    errors: any;
    name: string;
    value: any;
    helperText?: string;
    controllerProps: any;
    customerAddresses?: Array<RestaurantCustomerAddress>;
    onChangeAddress: (restaurantCustomerAddress: RestaurantCustomerAddress) => void;
};
