import type { SubscriptionVm } from 'src/api/letseatadmin/subscription/getSubscriptionApi';
import { letseatadminApiMethod } from 'src/api/letseatadminApi';
import type { ApiSauceResponse } from 'src/api/types/ApiSauceResponse';
import type { SubscriptionPlan } from 'src/constants/SubscriptionPlan';
import type { BrandId, RestaurantId } from 'src/types/Id';

export async function createSubscriptionApi(request: CreateSubscriptionApiRequest): ApiSauceResponse<SubscriptionVm> {
    return letseatadminApiMethod('subscription/createSubscriptionApi', request);
}

export type CreateSubscriptionApiRequest = {
    restaurantId: RestaurantId;
    restaurantIds: Array<RestaurantId>;
    brandId?: BrandId;
    plan: SubscriptionPlan;
    managerMessage?: string;
    comment?: string;
    invoiceDescription?: string;
    payPerUseRange1PaymentAmount?: string;
    payPerUseRange2PaymentAmount?: string;
    payPerUseRangeMaxPaymentAmount?: string;
    paymentAmount: string;
};
