import { makeStyles } from '@material-ui/core';
import { BigNumber } from 'bignumber.js';
import { useEffect, useRef, useState } from 'react';
import * as React from 'react';
import { updatePaymentMethodApi } from 'src/api/letseatmanager/payment/updatePaymentMethodApi';
import { app2 } from 'src/app2';
import { Button } from 'src/components/Button';
import { GoBackButton } from 'src/components/button/GoBackButton';
import { CurrencyNumericPad } from 'src/components/CurrencyNumericPad';
import { Dialog } from 'src/components/Dialog';
import { DialogActions } from 'src/components/DialogActions';
import { PosPaymentMethodSelect } from 'src/components/pos/PosPaymentMethodSelect';
import { PosPaymentResumeItem } from 'src/components/pos/PosPaymentResumeItem';
import { Tooltip } from 'src/components/Tooltip';
import { PaymentMethod, PaymentMethods } from 'src/constants/PaymentMethod';
import { translate } from 'src/i18n/translate';
import { useHasPaymentTerminalsAvailable } from 'src/services/device/useHasPaymentTerminalsAvailable';
import { usePayWithPaymentTerminal } from 'src/services/paymentTerminal/usePayWithPaymentTerminal';
import { useFormatAsRestaurantCurrencyNumber } from 'src/services/restaurant/useFormatAsRestaurantCurrencyNumber';
import { CustomPaymentMethod } from 'src/types/Id';
import { OrderPaymentVm } from 'src/types/OrderPaymentVm';
import type { OrderVm } from 'src/types/OrderVm';
import type { PosPayment } from 'src/types/PosPayment';
import { alertKnownErrorOrSomethingWentWrong } from 'src/utils/alert/alertKnownErrorOrSomethingWentWrong';
import { isCardPayment } from 'src/utils/paymentMethod/isCardPayment';
import { useAction } from 'src/utils/react/useAction';
import { useSelector } from 'src/utils/react/useSelector';
import { sum } from 'src/utils/reduce/sum';
import { newUuid } from 'src/utils/uuid/newUuid';

export function ChangePosOrderPaymentMethodDialog({ open, order, customerNumber, onClose, onSuccess, onGoBack }: Props): React.ReactElement {
    const classes = useStyles();
    const formatAsCurrencyNumber = useFormatAsRestaurantCurrencyNumber();
    const paymentsContainerRef = useRef<HTMLDivElement | null>(null);

    const { payWithPaymentTerminal } = usePayWithPaymentTerminal();
    const hasPaymentTerminalsAvailable = useHasPaymentTerminalsAvailable();

    const [loading, setLoading] = useState(false);
    const [paying, setPaying] = useState(false);
    const [selectedPosPaymentMethod, setSelectedPosPaymentMethod] = useState<{ paymentMethod: PaymentMethod | undefined; customPaymentMethod: CustomPaymentMethod | undefined }>({
        paymentMethod: undefined,
        customPaymentMethod: undefined,
    });
    const [payments, setPayments] = useState<Array<PosPayment>>([]);

    const useQpayTerminalEnabled = useSelector((state) => state.app.restaurant.useQpayTerminalEnabled);

    const updateOrder = useAction(app2.actions.updateOrder);

    const totalPaid = payments
        .map((posPayment) => posPayment.amount)
        .reduce(sum, BigNumber(0))
        .toString();
    const hasPaidWithTerminal = payments.some((posPayment) => isCardPayment(posPayment.paymentMethod) && !posPayment.customPaymentMethod && useQpayTerminalEnabled);
    const payWithTerminalAvailable = useQpayTerminalEnabled || hasPaymentTerminalsAvailable;

    useEffect(() => {
        handleSetPosPayments();
    }, [customerNumber]);

    useEffect(() => {
        if (paymentsContainerRef.current) {
            paymentsContainerRef.current.scrollTop = paymentsContainerRef.current.scrollHeight - paymentsContainerRef.current.clientHeight;
        }
    }, [payments]);

    const getTotalToPay = () => {
        if (!customerNumber) return order.total;
        const payments = order.payments?.filter((posPayment: OrderPaymentVm) => posPayment.customerNumber === customerNumber) ?? [];
        return payments
            .map((payment) => payment.amount)
            .reduce(sum, BigNumber(0))
            .toString();
    };

    const handleSetPosPayments = () => {
        const payments = order.payments?.map((payment: OrderPaymentVm) => ({ ...payment, id: newUuid() as string })) ?? [];

        if (!customerNumber) return setPayments(payments);

        const filteredPosPayments = payments.filter((posPayment) => posPayment.customerNumber === customerNumber);
        setPayments(filteredPosPayments);
    };

    const handleSelectPaymentMethod = ({ paymentMethod, customPaymentMethod }: { paymentMethod: PaymentMethod; customPaymentMethod: CustomPaymentMethod }) =>
        setSelectedPosPaymentMethod({ paymentMethod, customPaymentMethod });

    const addPayment = async (amount: string) => {
        if (!selectedPosPaymentMethod?.paymentMethod) return true;

        if (payWithTerminalAvailable && isCardPayment(selectedPosPaymentMethod?.paymentMethod) && !selectedPosPaymentMethod?.customPaymentMethod) {
            setPaying(true);
            const paymentResponse = await payWithPaymentTerminal({ amount });
            if (!paymentResponse.paid) {
                setPaying(false);
                return;
            }

            const newPayment: PosPayment = {
                id: newUuid() as string,
                paymentTerminalPaymentId: paymentResponse?.paymentTerminalPaymentId,
                paymentTerminalId: paymentResponse?.paymentTerminalId,
                paymentTerminalProvider: paymentResponse?.paymentTerminalProvider,
                amount: order.total,
                paymentMethod: PaymentMethods.CREDIT_CARD,
                customPaymentMethod: undefined,
                customerNumber,
            };

            setPayments([...payments, newPayment]);
            setSelectedPosPaymentMethod({ paymentMethod: undefined, customPaymentMethod: undefined });
            setPaying(false);
        }

        const newPayment: PosPayment = {
            id: newUuid() as string,
            amount,
            paymentMethod: selectedPosPaymentMethod?.paymentMethod,
            customPaymentMethod: selectedPosPaymentMethod?.customPaymentMethod,
            customerNumber,
        };
        setPayments([...payments, newPayment]);
    };

    const handleRemovePayment = (removedPosPayment: any) => {
        if (isCardPayment(removedPosPayment.paymentMethod) && !removedPosPayment.customPaymentMethod && payWithTerminalAvailable) return;

        setPayments(payments.filter((posPayment) => posPayment.id !== removedPosPayment.id));
    };

    const handleClose = () => {
        if (loading || paying || hasPaidWithTerminal) return;
        onClose();
    };

    const handleGoBack = () => {
        if (hasPaidWithTerminal) return;
        onGoBack?.();
    };

    const handleUpdatePaymentMethod = async () => {
        setLoading(true);
        const response = await updatePaymentMethodApi({
            orderId: order.orderId,
            posPayments: getPosPayments().map((posPayment) => ({
                amount: posPayment.amount,
                paymentMethod: posPayment?.customPaymentMethod ?? posPayment.paymentMethod,
                customerNumber: posPayment.customerNumber,
            })),
            payments: getPosPayments().map((posPayment) => ({
                amount: posPayment.amount,
                paymentMethod: posPayment.paymentMethod,
                customPaymentMethod: posPayment.customPaymentMethod,
                customerNumber: posPayment.customerNumber,
            })),
        });
        setLoading(false);
        if (!response.ok) return alertKnownErrorOrSomethingWentWrong(response);
        onSuccess?.();
        handleClose();
        updateOrder({ order: response.data });
    };

    const getPosPayments = () => {
        if (!customerNumber) return payments;
        const filteredPosPayments = order.payments?.filter((posPayment: OrderPaymentVm) => posPayment.customerNumber !== customerNumber) ?? [];
        return [...filteredPosPayments, ...payments];
    };

    const handlePayRestAmount = () => {
        addPayment(paymentMissing);
    };

    const validateCurrencyNumber = (number: any) => {
        return BigNumber(number || 0).isZero();
    };

    const hasPaymentMissing = BigNumber(totalPaid).isLessThan(getTotalToPay());
    const paymentMissing = BigNumber(getTotalToPay()).minus(totalPaid).toString();

    return (
        <Dialog title={translate('Change payment method')} open={open} onClose={handleClose} loading={loading || paying}>
            {!!customerNumber && <GoBackButton goBack={handleGoBack} disabled={loading || paying || hasPaidWithTerminal} />}
            <div className={classes.container}>
                <div className={classes.numericPadContainer}>
                    <CurrencyNumericPad onEnter={addPayment} validation={validateCurrencyNumber} disabled={!hasPaymentMissing || !selectedPosPaymentMethod?.paymentMethod} />
                    <Button outlined onClick={handlePayRestAmount} disabled={!hasPaymentMissing || !selectedPosPaymentMethod?.paymentMethod}>
                        {!!payments.length ? translate('Remaining Amount') : translate('Total Ticket')}
                        {!selectedPosPaymentMethod?.paymentMethod && <Tooltip text={translate('Please select a payment method.')} />}
                    </Button>
                </div>
                <div className={classes.paymentsColumn}>
                    <PosPaymentMethodSelect selectedPaymentMethod={selectedPosPaymentMethod?.customPaymentMethod ?? selectedPosPaymentMethod?.paymentMethod} onSelect={handleSelectPaymentMethod} />
                    <div className={classes.paymentsInfoContainer}>
                        <div className={classes.paymentsContainer} ref={paymentsContainerRef}>
                            <h2 className={classes.subtitle}>{translate('Payments')}</h2>
                            {payments.map((posPayment) => (
                                <PosPaymentResumeItem key={posPayment.id} posPayment={posPayment} onRemove={handleRemovePayment} />
                            ))}
                        </div>
                        <div className={classes.paymentMissingContainer}>
                            <span className={classes.textBold}>{translate('Payment missing')}</span>
                            <span className={classes.textBold} style={{ color: hasPaymentMissing ? 'red' : 'black' }}>
                                {formatAsCurrencyNumber(paymentMissing)}
                            </span>
                        </div>
                    </div>
                </div>
            </div>
            <DialogActions>
                <Button secondary onClick={handleClose} disabled={loading || paying || hasPaidWithTerminal} classes={{ button: classes.button }}>
                    {translate('Cancel')}
                </Button>
                <Button onClick={handleUpdatePaymentMethod} disabled={loading || paying || hasPaymentMissing} classes={{ button: classes.button }}>
                    {loading ? translate('Changing') : translate('Change')}
                    {hasPaymentMissing && <Tooltip text={translate('You cannot change the payment method if you do not have full payment')} />}
                </Button>
            </DialogActions>
        </Dialog>
    );
}

const useStyles = makeStyles((theme) => ({
    container: {
        width: '70vw',
        height: '65vh',
        marginBottom: 20,
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        [theme.breakpoints.down('sm')]: {
            flexDirection: 'column',
            justifyContent: 'flex-start',
            overflowY: 'auto',
            alignItems: 'center',
            gap: 10,
        },
    },
    paymentMethodsColumn: {
        display: 'flex',
        flexDirection: 'column',
        gap: 10,
    },
    numericPadContainer: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'flex-start',
        height: '100%',
        width: '40%',
        margin: '0 auto',
        [theme.breakpoints.down('sm')]: {
            width: '100%',
            height: 'auto',
        },
    },
    paymentsColumn: {
        display: 'flex',
        flexDirection: 'column',
        gap: 10,
        width: '45%',
        [theme.breakpoints.down('sm')]: {
            width: '100%',
            height: 'auto',
        },
    },
    paymentsInfoContainer: {
        display: 'flex',
        flexDirection: 'column',
        height: '50%',
        maxHeight: '50%',
    },
    paymentsContainer: {
        height: '90%',
        overflowY: 'auto',
        scrollBehavior: 'smooth',
    },
    paymentMissingContainer: {
        display: 'flex',
        width: '100%',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
    },
    textBold: {
        fontFamily: theme.typography.medium,
        fontSize: 16,
        color: '#2E3748',
    },
    subtitle: {
        fontFamily: theme.typography.regular,
        fontSize: 18,
        marginBottom: 12,
        color: '#2E3748',
    },
    button: {
        width: 200,
    },
}));

type Props = {
    open: boolean;
    order: OrderVm;
    customerNumber?: number;
    onClose: any;
    onSuccess?: any;
    onGoBack?: any;
};
