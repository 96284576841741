import { makeStyles } from '@material-ui/core';
import { DropDownItem, Text } from '@pidedirecto/ui';
import { MoneyIcon } from '@pidedirecto/ui/icons';
import * as React from 'react';
import { Currencies } from 'src/constants/Currency';
import { translate } from 'src/i18n/translate';
import { useSelector } from 'src/utils/react/useSelector';

export function RightToolBarCurrencyContextualMenuItem(): React.ReactElement | null {
    const classes = useStyles();

    const currency = useSelector((state) => state.app.restaurant?.currencyFormat?.currency);
    const restaurantCountry = useSelector((state) => state.app.restaurant?.country);

    if (!restaurantCountry || !currency) return null;

    return (
        <DropDownItem classes={{ container: classes.container }}>
            <div className={classes.iconContainer}>
                <MoneyIcon size={24} />
            </div>
            <Text>{translate(`${Currencies[currency as keyof typeof Currencies]}`) + ` (${translate(restaurantCountry)})`}</Text>
        </DropDownItem>
    );
}

const useStyles = makeStyles((theme) => ({
    iconContainer: {
        minWidth: 50,
        display: 'flex',
        alignItems: 'center',
    },
    container: {
        paddingTop: 6,
        paddingBottom: 6,
        display: 'flex',
        alignItems: 'center',
        gap: 10,
        color: theme.palette.text.secondary,
        height: 42,
        minWidth: 250,
    },
}));
