import { letseatadminApiMethod } from 'src/api/letseatadminApi';
import type { ApiSauceResponse } from 'src/api/types/ApiSauceResponse';
import type { BrandId, RestaurantId, Zone } from 'src/types/Id';
import type { OrderVm } from 'src/types/OrderVm';

export async function getRestaurantBillingUyApi(request: GetRestaurantBillingUyApiRequest): ApiSauceResponse<RestaurantBillingUyVm> {
    return letseatadminApiMethod('restaurantBillingUy/getRestaurantBillingUyApi', request);
}

type GetRestaurantBillingUyApiRequest = {
    from: Date;
    to: Date;
    restaurantId: RestaurantId;
};

export type RestaurantBillingUyVm = {
    restaurantId: RestaurantId;
    brandId?: BrandId;
    brandName?: string;
    restaurantName: string;
    restaurantZone?: Zone;
    completed: number;
    webCard: number;
    webCardDebit: number;
    webCardCredit: number;
    webCash: number;
    webCardOnDelivery: number;
    whiteLabelAppCard: number;
    whiteLabelAppCardDebit: number;
    whiteLabelAppCardCredit: number;
    whiteLabelAppCash: number;
    whiteLabelAppCardOnDelivery: number;
    marketplaceAppCard: number;
    marketplaceAppCardDebit: number;
    marketplaceAppCardCredit: number;
    marketplaceAppCash: number;
    marketplaceAppCardOnDelivery: number;
    posCard: number;
    posCash: number;
    posCardOnDelivery: number;
    marketplaceServiceFeeCash: number;
    marketplaceServiceFeeCard: number;
    marketplaceServiceFeeCardOnDelivery: number;
    cashback: number;
    manualOrders: number;
    manualOrdersProtected: number;
    directoProtectionAmount: number;
    invoicedRestaurantDeliveryCost: number;
    restaurantPromoCodeCostApp: number;
    restaurantPromoCodeBalance: number;
    paymentLink: number;
    paymentLinkDebit: number;
    paymentLinkCredit: number;
    paymentLinks: number;
    commissionWebCard: number;
    commissionWebCardDebit: number;
    commissionWebCardCredit: number;
    commissionWebCash: number;
    commissionWebCardOnDelivery: number;
    commissionAppCard: number;
    commissionAppCardDebit: number;
    commissionAppCardCredit: number;
    commissionAppCash: number;
    commissionAppCardOnDelivery: number;
    commissionManualOrders: number;
    commissionRappiCargoWebCard: number;
    commissionRappiCargoWebCardDebit: number;
    commissionRappiCargoWebCardCredit: number;
    commissionRappiCargoWebCash: number;
    commissionRappiCargoAppCard: number;
    commissionRappiCargoAppCardDebit: number;
    commissionRappiCargoAppCardCredit: number;
    commissionRappiCargoAppCash: number;
    commissionRappiCargoManualOrder: number;
    commissionRappiCargoPosCard: number;
    commissionRappiCargoPosCash: number;
    commissionUberDaasWebCard: number;
    commissionUberDaasWebCardDebit: number;
    commissionUberDaasWebCardCredit: number;
    commissionUberDaasWebCash: number;
    commissionUberDaasAppCard: number;
    commissionUberDaasAppCardDebit: number;
    commissionUberDaasAppCardCredit: number;
    commissionUberDaasAppCash: number;
    commissionUberDaasManualOrder: number;
    commissionUberDaasPosCard: number;
    commissionUberDaasPosCash: number;
    commissionForWebCardOrders: number;
    commissionForWebCardDebitOrders: number;
    commissionForWebCardCreditOrders: number;
    commissionForWebCashOrders: number;
    commissionForWebCardOnDeliveryOrders: number;
    commissionForWhiteLabelAppCardOrders: number;
    commissionForWhiteLabelAppCardDebitOrders: number;
    commissionForWhiteLabelAppCardCreditOrders: number;
    commissionForWhiteLabelAppCashOrders: number;
    commissionForWhiteLabelAppCardOnDeliveryOrders: number;
    commissionForMarketPlaceAppCardOrders: number;
    commissionForMarketPlaceAppCardDebitOrders: number;
    commissionForMarketPlaceAppCardCreditOrders: number;
    commissionForMarketPlaceAppCashOrders: number;
    commissionForMarketPlaceAppCardOnDeliveryOrders: number;
    commissionForManualOrders: number;
    commissionForRappiCargoWebCard: number;
    commissionForRappiCargoWebCardDebit: number;
    commissionForRappiCargoWebCardCredit: number;
    commissionForRappiCargoWebCash: number;
    commissionForRappiCargoAppCard: number;
    commissionForRappiCargoAppCardDebit: number;
    commissionForRappiCargoAppCardCredit: number;
    commissionForRappiCargoAppCash: number;
    commissionForRappiCargoManualOrder: number;
    commissionForRappiCargoPosCard: number;
    commissionForRappiCargoPosCash: number;
    commissionForUberDaasWebCard: number;
    commissionForUberDaasWebCardDebit: number;
    commissionForUberDaasWebCardCredit: number;
    commissionForUberDaasWebCash: number;
    commissionForUberDaasAppCard: number;
    commissionForUberDaasAppCardDebit: number;
    commissionForUberDaasAppCardCredit: number;
    commissionForUberDaasAppCash: number;
    commissionForUberDaasManualOrder: number;
    commissionForUberDaasPosCard: number;
    commissionForUberDaasPosCash: number;
    rappiCargoWebCard: number;
    rappiCargoWebCardDebit: number;
    rappiCargoWebCardCredit: number;
    rappiCargoWebCash: number;
    rappiCargoAppCard: number;
    rappiCargoAppCardDebit: number;
    rappiCargoAppCardCredit: number;
    rappiCargoAppCash: number;
    rappiCargoManualOrder: number;
    rappiCargoPosCard: number;
    rappiCargoPosCash: number;
    uberDaasWebCard: number;
    uberDaasWebCardDebit: number;
    uberDaasWebCardCredit: number;
    uberDaasWebCash: number;
    uberDaasAppCard: number;
    uberDaasAppCardDebit: number;
    uberDaasAppCardCredit: number;
    uberDaasAppCash: number;
    uberDaasManualOrder: number;
    uberDaasPosCard: number;
    uberDaasPosCash: number;
    total: number;
    commission: number;
    iva: number;
    ivaPercentage: number;
    subscriptionPayPerUsePayment: number;
    subscriptionPayPerUsePayments: number;
    manualEarning: number;
    manualEarnings: number;
    debtAtBeginningOfPeriodSubscriptionPayPerUsePayment: number;
    debtAtBeginningOfPeriodManualEarning: number;
    debt: number;
    debtSubscriptionPayPerUsePayment: number;
    debtManualEarning: number;
    subscriptionPayPerUsePaymentInvoice: number;
    manualEarningInvoice: number;
    deposit: number;
    orders: Array<OrderVm>;
};
