import { useState } from 'react';
import * as React from 'react';
import { Button } from 'src/components/Button';
import { ChangeDeliveryAddressDialog } from 'src/components/dialog/delivery/ChangeDeliveryAddressDialog';
import { translate } from 'src/i18n/translate';
import type { OrderVm } from 'src/types/OrderVm';
import { isPickedUpDelivery } from 'src/utils/delivery/isPickedUpDelivery';
import { isCancelledOrder } from 'src/utils/order/isCancelledOrder';
import { isCompletedOrder } from 'src/utils/order/isCompletedOrder';
import { isDeliveryOrder } from 'src/utils/order/isDeliveryOrder';
import { isPideDirectoDelivery } from 'src/utils/order/isPideDirectoDelivery';
import { isRejectedOrder } from 'src/utils/order/isRejectedOrder';
import { isCardPayment } from 'src/utils/paymentMethod/isCardPayment';
import { isCashPayment } from 'src/utils/paymentMethod/isCashPayment';
import { isPaymentLinkPayment } from 'src/utils/paymentMethod/isPaymentLinkPayment';

export function ChangeOrderDeliveryAddressButton({ order, disabled, onSuccess }: Props): React.ReactElement | null {
    const [changeDeliveryAddressDialogState, setChangeDeliveryAddressDialogState] = useState({ open: false });

    const handleClick = () => setChangeDeliveryAddressDialogState({ open: true });

    const closeChangeDeliveryAddressDialog = () => setChangeDeliveryAddressDialogState({ open: false });

    const shouldRenderButton =
        isPideDirectoDelivery(order) &&
        isDeliveryOrder(order.orderType) &&
        (isCashPayment(order.paymentMethod) || isCardPayment(order.paymentMethod) || isPaymentLinkPayment(order.paymentMethod)) &&
        !(isCompletedOrder(order.orderStatus) || isRejectedOrder(order.orderStatus) || isCancelledOrder(order.orderStatus)) &&
        !isPickedUpDelivery(order.deliveryStatus);

    if (!shouldRenderButton) return null;

    return (
        <div>
            <ChangeDeliveryAddressDialog onClose={closeChangeDeliveryAddressDialog} onSuccess={onSuccess} open={changeDeliveryAddressDialogState.open} order={order} />
            <Button secondary onClick={handleClick} disabled={disabled}>
                {translate('Change the delivery address')}
            </Button>
        </div>
    );
}

type Props = {
    order: OrderVm;
    disabled?: boolean;
    onSuccess?: any;
};
