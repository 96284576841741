import { makeStyles, useTheme } from '@material-ui/core';
import * as React from 'react';
import { Button } from 'src/components/Button';
import { translate } from 'src/i18n/translate';
import { PrinterFilledIcon } from 'src/icons/PrinterFilledIcon';

export function PrintersEmptyState({ onClickStart }: Props): React.ReactElement {
    const classes = useStyles();
    const theme = useTheme();

    return (
        <div className={classes.container}>
            <h2 className={classes.title}>{translate('Create a printer')}</h2>
            <div className={classes.column}>
                <div className={classes.iconContainer}>
                    <PrinterFilledIcon color={theme.palette.icons.brand} />
                </div>
                <p className={classes.text}>{translate('Configure your printer in a few clicks and print your tickets and order easily')}</p>
            </div>
            <Button primary onClick={onClickStart}>
                {translate('Start')}
            </Button>
        </div>
    );
}

const useStyles = makeStyles((theme) => ({
    title: {
        fontSize: 28,
        fontFamily: theme.typography.bold,
        color: '#0D3037',
        margin: 0,
        marginTop: 40,
    },
    container: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        gap: 40,
        width: '100%',
    },
    column: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
    },
    iconContainer: {
        width: 120,
        height: 120,
        borderRadius: 120,
        backgroundColor: `${theme.palette.primary.main}20`,
        marginBottom: 24,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    text: {
        fontFamily: theme.typography.regular,
        fontSize: 15,
        maxWidth: 500,
        textAlign: 'center',
        margin: 0,
        [theme.breakpoints.down('sm')]: {
            width: '90%',
        },
    },
}));

type Props = {
    onClickStart: any;
};
