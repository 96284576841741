import { getUiErrorLogEventsInLocalStorage } from 'src/localStorage/logEvent/getUiErrorLogEventsInLocalStorage';
import { setUiErrorLogEventsInLocalStorage } from 'src/localStorage/logEvent/setUiErrorLogEventsInLocalStorage';
import type { UiLogEvent } from 'src/services/logEvent/types/UiLogEvent';

export function pushUiErrorLogEventToLocalStorage(uiLogEvent: UiLogEvent): void {
    const uiLogEvents = getUiErrorLogEventsInLocalStorage();
    uiLogEvents.push(uiLogEvent);
    if (uiLogEvents.length > 10000) {
        setUiErrorLogEventsInLocalStorage(uiLogEvents.slice(1));
        return;
    }
    setUiErrorLogEventsInLocalStorage(uiLogEvents);
}
