import * as React from 'react';
import { Menus } from 'src/scenes/letseatmanager/menu/menu/Menus';
import { MenusEmptyState } from 'src/scenes/letseatmanager/menu/menu/MenusEmptyState';
import { useMenus } from 'src/services/menu/useMenus';
import { useSelector } from 'src/utils/react/useSelector';

export function MenusSection(): React.ReactElement {
    const menusLoaded = useSelector((state) => state.menu.menusLoaded);

    const { menus } = useMenus();

    const thereIsMenus = menus && menus?.length > 0;

    if (!thereIsMenus && menusLoaded) {
        return <MenusEmptyState />;
    }

    return <Menus />;
}
