import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import * as React from 'react';
import { useForm, useWatch } from 'react-hook-form';
import { createPromoCodeApi } from 'src/api/letseatmanager/promoCode/createPromoCodeApi';
import { Button } from 'src/components/Button';
import { Dialog } from 'src/components/Dialog';
import { DialogActions } from 'src/components/DialogActions';
import { DeprecatedFormDiscountTypeSelect } from 'src/components/form/DeprecatedFormDiscountTypeSelect';
import { DeprecatedFormPercentNumberField } from 'src/components/form/DeprecatedFormPercentNumberField';
import { Form } from 'src/components/form/Form';
import { FormCheckbox } from 'src/components/form/FormCheckbox';
import { FormCurrencyNumberStringField } from 'src/components/form/FormCurrencyNumberStringField';
import { FormCustomersAutocomplete } from 'src/components/form/FormCustomersAutocomplete';
import { FormDateTimePicker } from 'src/components/form/FormDateTimePicker';
import { FormIntegerField } from 'src/components/form/FormIntegerField';
import { FormMenuItemsAutocomplete } from 'src/components/form/FormMenuItemsAutocomplete';
import { FormOrderTypesAutocomplete } from 'src/components/form/FormOrderTypesAutocomplete';
import { FormPaymentMethodsAutocomplete } from 'src/components/form/FormPaymentMethodsAutocomplete';
import { FormPromoTypeSelect } from 'src/components/form/FormPromoTypeSelect';
import { FormRestaurantMenuItemsAutocomplete } from 'src/components/form/FormRestaurantMenuItemsAutocomplete';
import { FormRewardTypeSelect } from 'src/components/form/FormRewardTypeSelect';
import { FormSwitch } from 'src/components/form/FormSwitch';
import { FormTextField } from 'src/components/form/FormTextField';
import { FormUserRestaurantsAutocompleteMultiple } from 'src/components/form/FormUserRestaurantsAutocompleteMultiple';
import { DiscountTypes } from 'src/constants/DiscountType';
import { RewardType, RewardTypes } from 'src/constants/RewardType';
import { translate } from 'src/i18n/translate';
import { alertKnownErrorOrSomethingWentWrong } from 'src/utils/alert/alertKnownErrorOrSomethingWentWrong';
import { useSelector } from 'src/utils/react/useSelector';

export function CreatePromoCodeDialog({ open, onClose, onPromoCodeCreated }: Props): React.ReactElement {
    const classes = useStyles();
    const form = useForm();
    const {
        formState: { isSubmitting },
        watch,
        control,
        setValue,
    } = form;

    const restaurantId = useSelector((state) => state.app.restaurantId);
    const brandOpened = useSelector((state) => state.app.brandOpened);
    const adminUser = useSelector((state) => state.authentication.adminUser);
    const restaurant = useSelector((state) => state.app.restaurant);

    const discountType = watch('discountType');
    const excludeDeliveryCost = watch('excludeDeliveryCost');
    const freeDelivery = watch('freeDelivery');
    const restaurantIds = watch('restaurantIds');
    const appOnly = useWatch<boolean>({ name: 'appOnly', control });
    const webOnly = useWatch<boolean>({ name: 'webOnly', control });
    const rewardType = useWatch<RewardType>({ name: 'rewardType', control });

    const showMenuItemsSelector = rewardType === RewardTypes.PRODUCTS;

    const handleClose = () => {
        if (isSubmitting) return;
        onClose();
    };

    const onSubmit = async (form: any) => {
        const response = await createPromoCodeApi({
            restaurantIds: form.restaurantIds,
            customerIds: form.customerIds,
            restaurantId,
            code: form.code,
            description: form.description,
            promoType: form.promoType,
            rewardType: form.rewardType,
            discount: form.discount,
            excludeDeliveryCost: form.excludeDeliveryCost,
            freeDelivery: form.freeDelivery,
            appOnly: form.appOnly,
            webOnly: form.webOnly,
            discountType: form.discountType,
            orderTypes: form.orderTypes,
            paymentMethods: form.paymentMethods,
            menuItemIds: form.menuItemIds,
            requireAtLeastOneCreditCard: form.requireAtLeastOneCreditCard,
            minOrderAmount: form.minOrderAmount,
            maxDiscountAmount: form.maxDiscountAmount,
            maxUsage: form.maxUsage,
            restaurantPaidPercentage: adminUser ? form.restaurantPaidPercentage : '100',
            startsAt: form.startsAt,
            endsAt: form.endsAt,
        });
        if (!response.ok) {
            alertKnownErrorOrSomethingWentWrong(response);
            return;
        }

        onPromoCodeCreated();
        onClose();
    };

    return (
        <Dialog classes={{ dialog: classes.dialog }} open={open} onClose={handleClose} title={translate('Create Promo Code')}>
            <Form form={form} onSubmit={onSubmit}>
                <Grid container spacing={3}>
                    {brandOpened && (
                        <Grid item xs={12}>
                            <FormUserRestaurantsAutocompleteMultiple name='restaurantIds' label={translate('Restaurants')} required />
                        </Grid>
                    )}
                    <Grid item xs={12}>
                        <FormTextField name='code' label={translate('Code')} upperCase required />
                    </Grid>
                    <Grid item xs={12}>
                        <FormTextField
                            name='description'
                            label={translate('Description')}
                            helperText={translate('This description should describe what is included in the promo code and will be visible for the customer when they use the promo code.')}
                            classes={{ input: classes.inputDescription, error: classes.inputDescription }}
                            multiline
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <div className={classes.formRow}>
                            <div className={classes.inputContainer}>
                                <FormIntegerField name='maxUsage' label={translate('Max Usage')} min={0} />
                            </div>
                            <div className={classes.inputContainer}>
                                <DeprecatedFormPercentNumberField
                                    name='restaurantPaidPercentage'
                                    label={translate('Restaurant Paid Percentage')}
                                    min={0}
                                    max={100}
                                    defaultValue='100'
                                    disabled={!adminUser}
                                />
                            </div>
                        </div>
                    </Grid>
                    <Grid item xs={12}>
                        <div className={classes.formRow}>
                            <div className={classes.inputContainer}>
                                <FormPromoTypeSelect name='promoType' label={translate('Promo Type')} required />
                            </div>
                            <div className={classes.inputContainer}>
                                <FormRewardTypeSelect name='rewardType' label={translate('Reward Type')} required />
                            </div>
                        </div>
                    </Grid>
                    {showMenuItemsSelector && (
                        <Grid item xs={12}>
                            {brandOpened && (
                                <FormRestaurantMenuItemsAutocomplete
                                    name='menuItemIds'
                                    label={translate('Products')}
                                    placeholder={translate('All products')}
                                    restaurantIds={restaurantIds}
                                    required={showMenuItemsSelector}
                                />
                            )}
                            {!brandOpened && <FormMenuItemsAutocomplete name='menuItemIds' label={translate('Menu Products')} required={showMenuItemsSelector} />}
                        </Grid>
                    )}
                    <Grid item xs={12}>
                        <div className={classes.formRow}>
                            <div className={classes.inputContainer}>
                                <DeprecatedFormDiscountTypeSelect name='discountType' label={translate('Discount Type')} required />
                            </div>
                            <div className={classes.inputContainer}>
                                <FormCustomersAutocomplete name='customerIds' label={translate('Customers')} />
                            </div>
                        </div>
                    </Grid>
                    <Grid item xs={12}>
                        <div className={classes.formRow}>
                            <div className={classes.inputContainer}>
                                {discountType === DiscountTypes.AMOUNT && <FormCurrencyNumberStringField name='discount' label={translate('Discount')} required />}
                                {discountType === DiscountTypes.PERCENT && <DeprecatedFormPercentNumberField name='discount' label={translate('Discount')} required />}
                            </div>
                        </div>
                    </Grid>
                    <Grid item xs={12}>
                        <div className={classes.formRow}>
                            <div className={classes.inputContainer}>
                                <FormSwitch name='excludeDeliveryCost' label={translate('Exclude delivery cost')} disabled={freeDelivery} />
                            </div>
                            <div className={classes.inputContainer}>
                                <FormSwitch name='freeDelivery' label={translate('Free Delivery')} disabled={excludeDeliveryCost} />
                            </div>
                        </div>
                        {restaurant.appOrdersEnabled && (
                            <div className={classes.formRow}>
                                <div className={classes.inputContainer}>
                                    <FormSwitch name='appOnly' label={translate('App Only')} disabled={webOnly} />
                                </div>
                                <div className={classes.inputContainer}>
                                    <FormSwitch name='webOnly' label={translate('Web Only')} disabled={appOnly} />
                                </div>
                            </div>
                        )}
                    </Grid>
                    <Grid item xs={12}>
                        <div className={classes.formRow}>
                            <div className={classes.inputContainer}>
                                <FormCurrencyNumberStringField name='minOrderAmount' label={translate('Min Order Amount')} min={0} />
                            </div>
                            <div className={classes.inputContainer}>
                                {discountType === DiscountTypes.PERCENT && <FormCurrencyNumberStringField name='maxDiscountAmount' label={translate('Max Discount Amount')} min={0} />}
                            </div>
                        </div>
                    </Grid>
                    <Grid item xs={12}>
                        <div className={classes.formRow}>
                            <div className={classes.inputContainer}>
                                <FormOrderTypesAutocomplete name='orderTypes' label={translate('Order Types')} placeholder={translate('All')} />
                            </div>
                            <div className={classes.inputContainer}>
                                <FormPaymentMethodsAutocomplete name='paymentMethods' label={translate('Payment Methods')} placeholder={translate('All')} />
                            </div>
                        </div>
                    </Grid>
                    <Grid item xs={12}>
                        <div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
                            <FormCheckbox name='requireAtLeastOneCreditCard' label={translate('Require at least one Credit Card')} />
                        </div>
                    </Grid>
                    <Grid item xs={12}>
                        <div className={classes.formRow}>
                            <div className={classes.inputContainer}>
                                <FormDateTimePicker name='startsAt' label={translate('Starts')} required />
                            </div>
                            <div className={classes.inputContainer}>
                                <FormDateTimePicker name='endsAt' label={translate('Ends')} required />
                            </div>
                        </div>
                    </Grid>
                </Grid>
                <DialogActions>
                    <Button secondary onClick={handleClose} disabled={isSubmitting}>
                        {translate('Cancel')}
                    </Button>
                    <Button type='submit' disabled={isSubmitting}>
                        {isSubmitting ? translate('Creating') : translate('Create')}
                    </Button>
                </DialogActions>
            </Form>
        </Dialog>
    );
}

const useStyles = makeStyles((theme) => ({
    dialog: {
        width: '60%',
        maxWidth: '60%',
        maxHeight: '95%',
        height: '95%',
        borderRadius: 10,
        [theme.breakpoints.down('md')]: {
            width: '80%',
            maxWidth: '80%',
        },
    },
    form: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    dialogTitle: {
        textAlign: 'center',
        fontSize: 41,
        color: theme.palette.secondary.contrastText,
        paddingTop: theme.spacing(4),
        paddingBottom: theme.spacing(3),
        '& h2': {
            fontFamily: theme.typography.medium,
        },
    },
    dialogContent: {
        width: '70%',
        overflowY: 'hidden',
        [theme.breakpoints.down('sm')]: {
            width: '100%',
        },
    },
    inputDescription: {
        height: 170,
        alignItems: 'flex-start',
    },
    formRow: {
        display: 'flex',
        width: '100%',
        justifyContent: 'space-between',
        alignItems: 'flex-end',
        [theme.breakpoints.down('sm')]: {
            flexDirection: 'column',
        },
    },
    inputContainer: {
        width: '45%',
        [theme.breakpoints.down('sm')]: {
            width: '100%',
        },
    },
}));

type Props = {
    open: boolean;
    onClose: any;
    onPromoCodeCreated: any;
};
