import BigNumber from 'bignumber.js';
import { useEffect, useState } from 'react';
import * as React from 'react';
import { findActiveCustomAlertDialogApi } from 'src/api/letseatmanager/customAlertDialog/findActiveCustomAlertDialogApi';
import { CustomAlertDialog } from 'src/components/dialog/CustomAlertDialog';
import { getIsCustomAlertBeingShownInLocalStorage } from 'src/localStorage/getIsCustomAlertBeingShownInLocalStorage';
import { setIsCustomAlertBeingShownInLocalStorage } from 'src/localStorage/setIsCustomAlertBeingShownInLocalStorage';
import { isRecurrentCustomAlertDialog } from 'src/utils/customAlertDialog/isRecurrentCustomAlertDialog';
import { useLoadApi } from 'src/utils/react/useLoadApi';
import { useSelector } from 'src/utils/react/useSelector';

export function CustomAlertDialogHandler(): React.ReactElement | null {
    const [open, setOpen] = useState(false);

    const restaurantId = useSelector((state) => state.app.restaurantId);
    const adminUser = useSelector((state) => state.authentication.adminUser);

    const [loading, customAlertDialog] = useLoadApi(findActiveCustomAlertDialogApi, { restaurantId });

    useEffect(() => {
        load();
    }, []);

    const load = async () => {
        if (loading) return;
        const alertShown = getIsCustomAlertBeingShownInLocalStorage();
        if (!alertShown) {
            setOpen(true);
            setIsCustomAlertBeingShownInLocalStorage(isRecurrentCustomAlertDialog(customAlertDialog?.customAlertDialogType));
        }
    };

    const handleClose = () => {
        setOpen(false);
    };

    if (!customAlertDialog) return null;
    if (adminUser) return null;

    return (
        <CustomAlertDialog
            open={open}
            title={customAlertDialog?.title}
            text={customAlertDialog?.text}
            secondsToAccept={BigNumber(customAlertDialog?.secondsToAccept).toNumber()}
            onClose={handleClose}
        />
    );
}
