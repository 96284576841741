import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Grid from '@material-ui/core/Grid';
import LinearProgress from '@material-ui/core/LinearProgress';
import MenuItem from '@material-ui/core/MenuItem';
import RadioGroup from '@material-ui/core/RadioGroup';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { Loader } from '@pidedirecto/ui';
import * as React from 'react';
import { withRouter } from 'react-router-dom';
import { letseatmanagerApiDeprecated } from 'src/api/letseatmanagerApiDeprecated';
import Checkbox from 'src/components/deprecated/react-final-form/Checkbox';
import { Field, Form } from 'src/components/deprecated/react-final-form/Form';
import Radio from 'src/components/deprecated/react-final-form/Radio';
import Select from 'src/components/deprecated/react-final-form/Select';
import SelectCompanyTypeahead from 'src/components/deprecated/react-final-form/SelectCompanyTypeahead';
import TextField from 'src/components/deprecated/react-final-form/TextField';
import { Spacing } from 'src/components/spacing/Spacing';
import { history } from 'src/config/history';
import type { CustomerType } from 'src/constants/CustomerType';
import { RefundMethod, RefundMethods } from 'src/constants/RefundMethod';
import { translate } from 'src/i18n/translate';
import { appLayout } from 'src/theme/AppLayout';
import type { CompanyId, CustomerId, EmployeeIdAtCompany, EmployeeNumberAtCompany, LanguageTag } from 'src/types/Id';
import { alertKnownErrorOrSomethingWentWrong } from 'src/utils/alert/alertKnownErrorOrSomethingWentWrong';
import { deleteUrlQueryParameter } from 'src/utils/history/deleteUrlQueryParameter';
import { getUrlQueryParameter } from 'src/utils/history/getUrlQueryParameter';
import { required } from 'src/utils/Validator';

export default withRouter<any, any>(
    withStyles((theme) => ({
        linearProgress: {
            position: 'absolute',
            width: '100%',
            bottom: 0,
        },
    }))(
        class ChangeCustomerDialog extends React.PureComponent<Props, State> {
            state: any = INITIAL_STATE;

            async componentDidMount(): Promise<void> {
                const customerId: CustomerId = getUrlQueryParameter(CHANGE_CUSTOMER, this.props.location);
                if (customerId) {
                    await this.openDialog(customerId);
                }
            }

            async componentDidUpdate(prevProps: Props) {
                const prevCustomerId: CustomerId = getUrlQueryParameter(CHANGE_CUSTOMER, prevProps.location);
                const customerId: CustomerId = getUrlQueryParameter(CHANGE_CUSTOMER, this.props.location);
                if (customerId && !prevCustomerId && !this.state.open) {
                    await this.openDialog(customerId);
                } else if (!customerId && prevCustomerId && this.state.open) {
                    this.closeDialog();
                }
            }

            handleClose = () => {
                if (this.state.submitting) {
                    return;
                }
                if (history.length <= 2) {
                    history.replace({
                        path: this.props.location.pathname,
                        search: deleteUrlQueryParameter(CHANGE_CUSTOMER),
                    });
                    return;
                }
                history.goBack();
            };

            handleExited = () => {
                this.setState(INITIAL_STATE as any);
            };

            openDialog = async (customerId: CustomerId) => {
                this.setState({ open: true });
                await this.load(customerId);
            };

            closeDialog = () => {
                this.setState({ open: false });
            };

            enableUnBanCustomer = () => {
                const password = prompt(translate('Password'));
                const isCorrectPassword = password === '5649';

                if (!isCorrectPassword) return alert(translate('Wrong Password'));
                this.setState({ unBanEnabled: true });
            };

            load = async (customerId: CustomerId) => {
                this.setState({ loading: true });
                const response = await letseatmanagerApiDeprecated.admin.getCustomer({ customerId });
                this.setState({ loading: false });
                if (!response.ok) {
                    alertKnownErrorOrSomethingWentWrong(response);
                    this.setState({ open: false });
                    return;
                }
                const customer: CustomerVm = response.data;
                this.setState({
                    customer: {
                        customerId: customer.customerId,
                        mobileNumber: customer.mobileNumber,
                        email: customer.email ?? '',
                        firstName: customer.firstName ?? '',
                        lastName: customer.lastName ?? '',
                        companyName: customer.companyName ?? '',
                        companyId: customer.companyId ?? ('' as any),
                        employeeIdAtCompany: customer.employeeIdAtCompany ?? ('' as any),
                        employeeNumberAtCompany: customer.employeeNumberAtCompany ?? ('' as any),
                        payrollPaymentsEnabled: customer.payrollPaymentsEnabled ?? false,
                        refundMethod: customer.refundMethod ?? '',
                        banned: customer.banned ? 'true' : 'false',
                    },
                });
            };

            onSubmit = async (form: any) => {
                this.setState({ submitting: true });
                const response = await letseatmanagerApiDeprecated.admin.changeCustomer({
                    customerId: form.customer.customerId,
                    email: form.customer.email,
                    firstName: form.customer.firstName,
                    lastName: form.customer.lastName,
                    companyName: form.customer.companyName,
                    companyId: form.customer.companyId,
                    employeeIdAtCompany: form.customer.employeeIdAtCompany,
                    employeeNumberAtCompany: form.customer.employeeNumberAtCompany,
                    payrollPaymentsEnabled: form.customer.payrollPaymentsEnabled,
                    refundMethod: form.customer.refundMethod,
                    banned: form.customer.banned === 'true',
                });
                this.setState({ submitting: false });
                if (!response.ok) {
                    alertKnownErrorOrSomethingWentWrong(response);
                    return;
                }
                this.props.onCustomerChanged && this.props.onCustomerChanged();
                this.handleClose();
            };

            render() {
                const { classes } = this.props;
                return (
                    <Dialog aria-labelledby='change-customer-dialog-title' fullWidth scroll='paper' open={this.state.open} onClose={this.handleClose} onExited={this.handleExited}>
                        <Form
                            onSubmit={this.onSubmit}
                            initialValues={{
                                customer: this.state.customer,
                            }}
                            render={({ handleSubmit, submitting, pristine, values }) => (
                                <form onSubmit={handleSubmit} noValidate style={appLayout.dialog.form}>
                                    <DialogTitle id='change-customer-dialog-title'>{translate('Change Customer').toUpperCase()}</DialogTitle>
                                    <DialogContent>{this.renderDialogContent()}</DialogContent>
                                    <DialogActions>
                                        <Button onClick={this.handleClose} disabled={this.state.submitting}>
                                            {translate('Cancel').toUpperCase()}
                                        </Button>
                                        <Button color='primary' type='submit' disabled={this.state.submitting}>
                                            {this.state.submitting ? translate('Changing').toUpperCase() : translate('Change').toUpperCase()}
                                        </Button>
                                    </DialogActions>
                                    {this.state.submitting && <LinearProgress className={classes.linearProgress} />}
                                </form>
                            )}
                        />
                    </Dialog>
                );
            }

            renderDialogContent() {
                if (this.state.loading) {
                    return <Loader size={40} loading={true} />;
                }
                return (
                    <>
                        <Grid container spacing={3}>
                            <Grid item xs={12}>
                                <Field name='customer.customerId' component={TextField} label={translate('Customer Id')} fullWidth disabled />
                            </Grid>

                            {this.state.customer.mobileNumber && (
                                <Grid item xs={12}>
                                    <Field name='customer.email' component={TextField} label={translate('Email')} fullWidth required validate={required} disabled={this.state.submitting} />
                                </Grid>
                            )}

                            {this.state.customer.mobileNumber && (
                                <Grid item xs={6}>
                                    <Field
                                        name='customer.firstName'
                                        component={TextField}
                                        label={translate('First Name')}
                                        fullWidth
                                        required
                                        validate={required}
                                        disabled={this.state.submitting}
                                        inputProps={{ autoComplete: 'no' }}
                                    />
                                </Grid>
                            )}

                            {this.state.customer.mobileNumber && (
                                <Grid item xs={6}>
                                    <Field
                                        name='customer.lastName'
                                        component={TextField}
                                        label={translate('Last Name')}
                                        fullWidth
                                        required
                                        validate={required}
                                        disabled={this.state.submitting}
                                        inputProps={{ autoComplete: 'no' }}
                                    />
                                </Grid>
                            )}

                            <Grid item xs={12}>
                                <Field name='customer.companyName' component={TextField} label={translate('Company Name')} fullWidth disabled={this.state.submitting} />
                            </Grid>

                            <Grid item xs={12}>
                                <Field name='customer.companyId' label={translate('Company')} component={SelectCompanyTypeahead} fullWidth />
                            </Grid>

                            <Grid item xs={12}>
                                <Field
                                    name='customer.employeeIdAtCompany'
                                    component={TextField}
                                    label={translate('Employee Id (At Company)')}
                                    fullWidth
                                    disabled={this.state.submitting}
                                    inputProps={{ autoComplete: 'no' }}
                                />
                            </Grid>

                            <Grid item xs={12}>
                                <Field
                                    name='customer.employeeNumberAtCompany'
                                    component={TextField}
                                    label={translate('Employee Number (At Company)')}
                                    fullWidth
                                    disabled={this.state.submitting}
                                    inputProps={{ autoComplete: 'no' }}
                                />
                            </Grid>

                            <Grid item xs={12}>
                                <FormControlLabel label='Payroll Payments Enabled' control={<Field name='customer.payrollPaymentsEnabled' component={Checkbox} type='checkbox' color='primary' />} />
                            </Grid>

                            <Grid item xs={12}>
                                <Field fullWidth name='customer.refundMethod' label={translate('Refund Method')} component={Select} formControlProps={{ fullWidth: true }}>
                                    <MenuItem value=''>&nbsp;</MenuItem>
                                    <MenuItem value={RefundMethods.CREDITS}>{RefundMethods.CREDITS}</MenuItem>
                                    <MenuItem value={RefundMethods.CREDIT_CARD}>{RefundMethods.CREDIT_CARD}</MenuItem>
                                </Field>
                            </Grid>
                            {this.state.customer.banned === 'true' && (
                                <Grid item xs={12}>
                                    <Button onClick={this.enableUnBanCustomer}>{translate('Enable Unban Customer')}</Button>
                                </Grid>
                            )}
                            <Grid item xs={12}>
                                <RadioGroup row>
                                    <FormControlLabel
                                        label='Not Banned'
                                        control={
                                            <Field
                                                name='customer.banned'
                                                component={Radio}
                                                type='radio'
                                                color='primary'
                                                value='false'
                                                disabled={this.state.submitting || (!this.state.unBanEnabled && this.state.customer.banned === 'true')}
                                            />
                                        }
                                    />
                                    <FormControlLabel
                                        label='Banned'
                                        control={<Field name='customer.banned' component={Radio} type='radio' color='primary' value='true' disabled={this.state.submitting} />}
                                    />
                                </RadioGroup>
                            </Grid>
                        </Grid>

                        <Grid container justify='space-around'>
                            <Grid item>
                                <Spacing units={8} />
                                {this.state.errorMessage && (
                                    <Typography display='block' color='error'>
                                        {this.state.errorMessage}
                                    </Typography>
                                )}
                            </Grid>
                        </Grid>
                    </>
                );
            }
        },
    ),
) as React.ComponentType<any>;

export const CHANGE_CUSTOMER = 'change-customer';
const INITIAL_STATE = {
    open: false,
    customer: {
        customerId: '' as any,
        email: '',
        firstName: '',
        lastName: '',
        companyName: '',
        companyId: '' as any,
        employeeIdAtCompany: '' as any,
        employeeNumberAtCompany: '' as any,
        payrollPaymentsEnabled: false,
        refundMethod: '',
        banned: 'false',
    },
    loading: false,
    submitting: false,
    unBanEnabled: false,
} as const;

type Props = {
    classes: any;
    location: {
        pathname: string;
    };
    onCustomerChanged?: any;
};

type State = {
    open: boolean;
    loading: boolean;
    submitting: boolean;
    customer: {
        customerId: CustomerId;
        mobileNumber?: string;
        email?: string;
        firstName: string;
        lastName: string;
        companyName: string;
        companyId: CompanyId;
        employeeIdAtCompany: EmployeeIdAtCompany;
        employeeNumberAtCompany: EmployeeNumberAtCompany;
        payrollPaymentsEnabled: boolean;
        refundMethod: string;
        banned: string;
    };
    unBanEnabled: boolean;
    errorMessage?: string;
};

type CustomerVm = {
    customerId: CustomerId;
    customerType: CustomerType;
    mobileNumber?: string;
    email?: string;
    firstName?: string;
    lastName?: string;
    companyName?: string;
    companyId?: CompanyId;
    employeeIdAtCompany?: EmployeeIdAtCompany;
    employeeNumberAtCompany?: EmployeeNumberAtCompany;
    payrollPaymentsEnabled?: boolean;
    creditCards?: number;
    refundMethod?: RefundMethod;
    language?: LanguageTag;
    fcmPermission?: boolean;
    banned?: boolean;
    downloadedAppAt?: Date;
    signedUpAt?: Date;
    addedFirstCreditCardAt?: Date;
};
