import { makeStyles, useTheme } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import { UploadIcon } from '@pidedirecto/ui/icons';
import { BigNumber } from 'bignumber.js';
import { isPossibleNumber } from 'libphonenumber-js';
import * as React from 'react';
import { useRef, useState } from 'react';
import { importRestaurantCustomerApi } from 'src/api/letseatmanager/restaurantCustomer/importRestaurantCustomerApi';
import { Button } from 'src/components/Button';
import { Dialog } from 'src/components/Dialog';
import { DialogActions } from 'src/components/DialogActions';
import { LoadingIndicator } from 'src/components/LoadingIndicator';
import { translate } from 'src/i18n/translate';
import { convertCsvToImportRestaurantCustomers } from 'src/utils/csv/convertCsvToImportRestaurantCustomers';
import { useSelector } from 'src/utils/react/useSelector';
import { isEmail } from 'src/utils/string/isEmail';
import { lowerCase } from 'src/utils/string/lowerCase';

export function ImportCustomersDialog({ open, onSuccess, onClose }: Props): React.ReactElement {
    const classes = useStyles();
    const theme = useTheme();
    const inputRef = useRef<HTMLInputElement>(null);

    let _customerTried = 0;
    let _importedCustomers = 0;

    const [importing, setImporting] = useState(false);
    const [customersToImport, setCustomersToImport] = useState(0);
    const [customersTried, setCustomersTried] = useState(0);

    const restaurantId = useSelector((state) => state.app.restaurantId);

    const handleClose = () => {
        if (importing) return;
        onClose();
    };

    const handleUploadCsv = async (event: any) => {
        if (event?.target?.files.length === 0) return;

        const restaurantCustomers = await convertCsvToImportRestaurantCustomers(event.target.files[0]);

        if (!restaurantCustomers) return;

        setCustomersToImport(restaurantCustomers.length);

        setImporting(true);
        for (const restaurantCustomer of restaurantCustomers) {
            _customerTried++;
            setCustomersTried(_customerTried);

            const response = await importRestaurantCustomerApi({
                restaurantId,
                name: restaurantCustomer.name,
                email: isEmail(restaurantCustomer.email) ? lowerCase(restaurantCustomer.email) : undefined,
                mobileNumber: isPossibleNumber(restaurantCustomer.mobileNumber, 'MX') ? restaurantCustomer.mobileNumber : undefined,
                address: restaurantCustomer?.address?.street
                    ? {
                          street: restaurantCustomer?.address.street,
                          location: {
                              type: 'Point',
                              coordinates: [
                                  BigNumber(restaurantCustomer?.address?.location.coordinates[0] ?? 0).toNumber(),
                                  BigNumber(restaurantCustomer?.address?.location.coordinates[1] ?? 0).toNumber(),
                              ],
                          },
                      }
                    : undefined,
            });

            if (response.ok) {
                _importedCustomers++;
            }
        }
        setImporting(false);
        alert([`imported: ${_importedCustomers}`].join('\n'));

        setCustomersToImport(0);
        setCustomersTried(0);

        onClose();
        onSuccess();
    };

    const importCsv = () => {
        if (importing) return;
        inputRef.current?.click();
    };

    return (
        <Dialog open={open} onClose={handleClose} title={translate('Import Customers')}>
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <div className={classes.importCustomersButton} onClick={importCsv}>
                        <UploadIcon color={theme.palette.icons.brand} size={35} />
                        <span className={classes.text}>{translate('Select CSV File')}</span>
                    </div>
                    <input style={{ display: 'none' }} type='file' ref={inputRef} onChange={handleUploadCsv} accept='.csv' />
                </Grid>
                {importing && (
                    <Grid item xs={12}>
                        <LoadingIndicator
                            text={translate('@customersTried of @customersToImport', { customersTried, customersToImport })}
                            subText={translate('Please dont close this window, it could take a couple of minutes.')}
                            total={customersToImport}
                            currentValue={customersTried}
                        />
                    </Grid>
                )}
            </Grid>
            <DialogActions>
                <Button onClick={handleClose} disabled={importing} secondary>
                    {translate('Cancel')}
                </Button>
                <Button color='primary' type='submit' disabled={importing}>
                    {importing ? translate('Importing') : translate('Import')}
                </Button>
            </DialogActions>
        </Dialog>
    );
}

const useStyles = makeStyles((theme) => ({
    form: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        width: '100%',
    },
    dialogTitle: {
        textAlign: 'center',
        fontSize: 41,
        color: theme.palette.secondary.contrastText,
        paddingTop: theme.spacing(4),
        paddingBottom: theme.spacing(3),
        '& h2': {
            fontFamily: theme.typography.medium,
        },
    },
    paper: {
        [theme.breakpoints.down('md')]: {
            width: '80%',
            maxWidth: '80%',
        },
    },
    dialogContent: {
        width: '100%',
        paddingRight: '10%',
        paddingLeft: '10%',
        overflowY: 'hidden',
    },
    buttonsContainer: {
        marginTop: 20,
        [theme.breakpoints.down('sm')]: {
            flexDirection: 'column',
            marginBottom: 20,
        },
    },
    noMargin: {
        margin: '0 !important',
    },
    acceptButton: {
        backgroundColor: theme.palette.primary.main,
        color: 'white',
        fontFamily: theme.typography.semiBold,
        fontSize: 15,
        borderRadius: 5,
        height: 40,
        width: 200,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        textTransform: 'none',
        boxShadow: `3px 3px 10px ${theme.palette.primary.main}aa`,
    },
    cancelButton: {
        backgroundColor: theme.palette.secondary.dark,
        color: theme.palette.secondary.contrastText,
        fontFamily: theme.typography.semiBold,
        fontSize: 15,
        borderRadius: 5,
        height: 40,
        width: 200,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        textTransform: 'none',
    },
    importCustomersButton: {
        width: '80%',
        height: 150,
        backgroundColor: theme.palette.secondary.light,
        border: `1px solid ${theme.palette.secondary.dark}`,
        margin: '0 auto',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        borderRadius: 15,
        cursor: 'pointer',
        flexDirection: 'column',
    },
    text: {
        fontFamily: theme.typography.regular,
        fontSize: 15,
        marginTop: 5,
    },
    icon: {
        fontSize: 40,
        color: theme.palette.primary.main,
    },
}));

type Props = {
    open: boolean;
    onClose: any;
    onSuccess: any;
};
