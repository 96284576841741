import { Button, Grid } from '@material-ui/core';
import LinearProgress from '@material-ui/core/LinearProgress';
import { makeStyles } from '@material-ui/core/styles';
import { UploadIcon } from '@pidedirecto/ui/icons';
import * as React from 'react';
import { useRef, useState } from 'react';
import { ImageTypes } from 'src/constants/ImageType';
import { translate } from 'src/i18n/translate';
import { uploadImage } from 'src/services/image/uploadImage';
import { alertKnownErrorOrSomethingWentWrong } from 'src/utils/alert/alertKnownErrorOrSomethingWentWrong';
import { hashMd5FromArrayBuffer } from 'src/utils/hashMd5FromArrayBuffer';
import { imageHasTheRightDimensions } from 'src/utils/image/imageHasTheRightDimensions';
import { useSelector } from 'src/utils/react/useSelector';
import { requireValue } from 'src/utils/require/requireValue';

export function ImageUploaderContainer({ onChange, message }: Props): React.ReactElement {
    const classes = useStyles();

    const isExternalMenu = useSelector((state) => state.app2.changeMenuItemDialog.isExternalMenu);
    const uberEatsStoreId = useSelector((state) => state.app.restaurant?.uberEatsStoreId);
    const [uploading, setUploading] = useState(false);
    const inputRef = useRef(null);

    const onUploadSuccess = (imageUrl: string) => {
        onChange(imageUrl);
    };

    const handleFileSelect = (e: any) => {
        e.stopPropagation();
        e.preventDefault();
        const files = e.target.files ? e.target.files : e.dataTransfer.files;
        const file = e.target.files ? e.target.files[0] : e.dataTransfer.files[0];

        if (!e.target || files?.length !== 1) {
            alert(translate('Cannot upload multiple images. Please try again with a single file.'));
            return;
        }
        const reader = new FileReader();

        reader.addEventListener('loadend', async (e: ProgressEvent) => {
            setUploading?.(true);
            const fileName = `${hashMd5FromArrayBuffer(reader.result)}-${file.name}`;
            const imageType = ImageTypes.MENU_ITEM;
            try {
                const _imageHasTheRightDimensions = await imageHasTheRightDimensions({
                    buffer: reader.result,
                    imageType,
                    dimensions: isExternalMenu && uberEatsStoreId ? UBER_EATS_IMAGE_DIMENSIONS : undefined,
                });
                if (!_imageHasTheRightDimensions) {
                    setUploading?.(false);
                    alert(translate('The image is not valid, please select an image with the right dimensions.'));
                    return;
                }

                const uploadResponse = await uploadImage({
                    fileName,
                    imageBlob: new Blob([reader.result as any], { type: file.type }),
                    type: file.type,
                    imageType,
                });

                if (uploadResponse.error) {
                    setUploading?.(false);
                    alertKnownErrorOrSomethingWentWrong();
                    return;
                }

                setUploading?.(false);
                onUploadSuccess(requireValue(uploadResponse.imageUrl));
            } catch (e: any) {
                setUploading?.(false);
                alertKnownErrorOrSomethingWentWrong();
            }
        });
        reader.readAsArrayBuffer(file);
        return false;
    };

    const handleDragOver = (e: any) => {
        e.stopPropagation();
        e.preventDefault();
    };

    return (
        <Grid className={classes.imageUploaderContainer} onDrop={handleFileSelect} onDragOver={handleDragOver}>
            <UploadIcon />
            <p className={classes.text}>
                <Button className={classes.buttonText} component='label'>
                    {translate('Click here')}
                    <input type='file' hidden accept='image/*' ref={inputRef} style={{ display: 'none' }} onChange={handleFileSelect} />
                </Button>
                {translate(message ?? 'or drag here the image of your product. SVG, PNG or JPG (max 400x800px)')}
            </p>
            {uploading && <LinearProgress style={{ position: 'relative', width: '100%' }} />}
        </Grid>
    );
}

const UBER_EATS_IMAGE_DIMENSIONS = {
    minWidth: 320,
    maxWidth: 6000,
    minHeight: 320,
    maxHeight: 6000,
} as const;

const useStyles = makeStyles((theme) => ({
    imageUploaderContainer: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        width: '100%',
        height: '100%',
        border: `1px dashed ${theme.palette.border.primary}`,
        borderRadius: '8px',
        padding: '24px 12px',
        gap: 12,
        cursor: 'pointer',
        marginTop: 8,
    },
    text: {
        fontFamily: theme.typography.regular,
        color: theme.palette.text.secondary,
        margin: 0,
        textAlign: 'center',
    },
    buttonText: {
        color: theme.palette.text.success,
        fontFamily: theme.typography.semiBold,
        fontSize: 15,
        textTransform: 'none',
        '&:hover': {
            textDecoration: 'underline',
            backgroundColor: 'transparent',
        },
    },
}));

type Props = {
    onChange: any;
    message?: string;
};
