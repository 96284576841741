import { makeStyles } from '@material-ui/core';
import { useContext, useMemo, useRef } from 'react';
import * as React from 'react';
import { PageContext } from 'src/components/Page';
import { createUserClickedSwitchLogEvent } from 'src/services/logEvent/createUserClickedSwitchLogEvent';
import { classNames } from 'src/utils/react/classNames';

/**
 * @callback onChange
 */

/**
 * Renders a switch component
 * @param {string} name - Name for the switch input
 * @param {string} value - Value for the switch input
 * @param {string} label - Label for the switch input
 * @param {boolean} [disabled] - Set the switch disabled
 * @param {Function} [onChange] - Function to be called when the checked stated of the switch changes
 * @param {string} [classes.toggle] - class to override toggle style
 * @param {string} helperText - text to provide more info about input
 * @returns {React.Node}
 * @constructor
 */
export function Switch({ name, value, label, disabled, onChange, classes: classesProp, helperText }: Props): React.ReactElement {
    const classes = useStyles();
    const inputRef = useRef<HTMLInputElement>(null);
    const pageContext = useContext(PageContext);

    const handleChange = () => {
        createUserClickedSwitchLogEvent({ pageContext, label, name });
        pageContext.clearStackTrace();
        onChange?.(!value);
    };

    return useMemo(
        () => (
            <div className={classes.container}>
                <div className={classes.switchContainer}>
                    <input
                        aria-describedby={`${name}-helperTextId`}
                        type='checkbox'
                        name={name}
                        value={value as any}
                        id={`${name}-toggle`}
                        className={classNames(classes.input, classesProp?.toggle, value ? classes.inputChecked : '', disabled ? classes.disabled : '')}
                        disabled={disabled}
                        checked={value}
                        ref={inputRef}
                        onChange={handleChange}
                    />
                    <label htmlFor={`${name}-toggle`} className={classes.label}>
                        {label}
                    </label>
                </div>
                {helperText && (
                    <span id={`${name}-helperTextId`} className={classes.helperText}>
                        {helperText}
                    </span>
                )}
            </div>
        ),
        [name, value, label, disabled, onChange, classesProp, helperText],
    );
}

const useStyles = makeStyles((theme) => ({
    switchContainer: {
        display: 'flex',
        alignItems: 'center',
        gap: 8,
    },
    container: {
        fontSize: 16,
        fontFamily: theme.typography.regular,
        color: '#000',
        display: 'flex',
        flexDirection: 'column',
        gap: 8,
    },
    input: {
        appearance: 'none',
        cursor: 'pointer',
        width: 44,
        height: 24,
        borderRadius: 100,
        position: 'relative',
        backgroundColor: '#D9D9D9',
        margin: '8px 0',
        flexShrink: 0,
        '&::before': {
            content: '""',
            width: 20,
            height: 20,
            backgroundColor: '#FFF',
            borderRadius: 50,
            position: 'absolute',
            top: 2,
            left: 2,
            transition: 'transform 0.3s ease',
        },
    },
    inputChecked: {
        backgroundColor: theme.palette.primary.main,
        '&::before': {
            transform: ' translateX(20px)',
        },
    },
    label: {
        fontSize: 14,
        fontFamily: theme.typography.regular,
        color: '#2E3748',
        cursor: 'pointer',
    },
    helperText: {
        color: '#0000008a',
        fontSize: 12,
        position: 'relative',
        bottom: 14,
    },
    disabled: {
        backgroundColor: '#D9D9D955',
    },
}));

type Props = {
    name: string;
    value: boolean;
    label?: string;
    helperText?: string;
    disabled?: boolean;
    onChange?: any;
    classes?: {
        toggle?: string;
    };
};
