import { pidedirectoReportsMethod } from 'src/api/pidedirectoReportsApi';
import type { ApiSauceResponse } from 'src/api/types/ApiSauceResponse';
import type { CashRegisterShiftReportVm } from 'src/types/CashRegisterShiftReportVm';
import type { CashRegisterId } from 'src/types/Id';

export async function getClosedCashRegisterReportApi(request: GetClosedCashRegisterReportRequest): ApiSauceResponse<CashRegisterShiftReportVm> {
    return pidedirectoReportsMethod('/getClosedCashRegisterReportApi', request);
}

type GetClosedCashRegisterReportRequest = {
    cashRegisterId: CashRegisterId;
};
