import type { PageContextType } from 'src/components/Page';
import { LogEventTypes } from 'src/constants/LogEventType';
import { createUiInteractionLogEvent } from 'src/services/logEvent/createUiInteractionLogEvent';
import { normalizeUiStackTrace } from 'src/services/logEvent/normalizeUiStackTrace';

export function createUserOpenedAlertDialogLogEvent({ title, pageContext }: Params): void {
    createUiInteractionLogEvent({
        logEventType: LogEventTypes.USER_OPENED_ALERT_DIALOG,
        message: `alert dialog ${title} opened`,
        details: `${pageContext.stackTrace}/${normalizeUiStackTrace(`alert_dialog_${title}`)}`,
        data: {
            title,
            dialogId: normalizeUiStackTrace(`alert_dialog_${title}`),
            pathId: `${pageContext.stackTrace}/${normalizeUiStackTrace(`alert_dialog_${title ?? ''}`)}`,
            stackTrace: pageContext.stackTrace,
        },
    });
}

type Params = {
    title: string;
    pageContext: PageContextType;
};
