import { pidedirectoReportsMethod } from 'src/api/pidedirectoReportsApi';
import type { ApiSauceResponse } from 'src/api/types/ApiSauceResponse';
import type { SalesType } from 'src/constants/SalesTypes';
import type { RestaurantId } from 'src/types/Id';

export async function getCustomerRestaurantStatisticsApi(request: GetCustomerRestaurantStatisticsRequest): ApiSauceResponse<GetCustomerReportsStatisticsApiResponse> {
    return pidedirectoReportsMethod('/customerPageReportApi', request);
}

export type GetCustomerRestaurantStatisticsRequest = {
    report: string;
    reportPage?: string;
    abcTableFilter?: string;
    restaurantIds: Array<RestaurantId>;
    fromDate: string;
    toDate: string;
    salesType: SalesType;
};

export type GetCustomerReportsStatisticsApiResponse = Array<{
    name: string;
    meanSurveyRating: number;
    mostCommonSurveyAnswer?: string;
    restaurantId: RestaurantId;
    salesByCustomer: Array<SalesByCustomer>;
    surveysByDate?: Array<SurveysByDate>;
    surveyAnswersFrequency?: Array<SurveyAnswersFrequency>;
}>;

export type SalesByCustomer = {
    customerName: string;
    meanSales: string;
    mobileNumber: string;
    orderVolume: number;
    total: string;
};

export type SurveyAnswersFrequency = {
    surveyAnswers: string;
    surveyVolume: number;
};

export type SurveysByDate = {
    customerName: string;
    mobileNumber: string;
    orderType: string;
    rating: number;
    recommendationRating: number;
};
