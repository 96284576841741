import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import { useState } from 'react';
import * as React from 'react';
import { useForm } from 'react-hook-form';
import { copyRestaurantMenuApi } from 'src/api/letseatadmin/menu/copyRestaurantMenuApi';
import { app2 } from 'src/app2';
import { Button } from 'src/components/Button';
import { Dialog } from 'src/components/Dialog';
import { DialogActions } from 'src/components/DialogActions';
import { Form } from 'src/components/form/Form';
import { FormCheckbox } from 'src/components/form/FormCheckbox';
import { FormMenuSelect } from 'src/components/form/FormMenuSelect';
import { FormRestaurantAutocomplete } from 'src/components/form/FormRestaurantAutocomplete';
import { Text } from 'src/components/Text';
import { translate } from 'src/i18n/translate';
import { appReducer } from 'src/reducers/appReducer';
import { alertKnownErrorOrSomethingWentWrong } from 'src/utils/alert/alertKnownErrorOrSomethingWentWrong';
import { useAction } from 'src/utils/react/useAction';
import { useSelector } from 'src/utils/react/useSelector';

export function CopyRestaurantMenuDialog(): React.ReactElement {
    const form = useForm();
    const {
        formState: { isSubmitting },
    } = form;
    const classes = useStyles();

    const [isCopyMenuCompatible, setIsCopyMenuCompatible] = useState(true);

    const open = useSelector((state) => state.app2.copyRestaurantMenuDialog.open);
    const onSuccess = useSelector((state) => state.app2.copyRestaurantMenuDialog.onSuccess);
    const restaurantId = useSelector((state) => state.app.restaurantId);

    const closeCopyRestaurantMenuDialog = useAction(app2.actions.closeCopyRestaurantMenuDialog);
    const showSnackbar = useAction(appReducer.actions.showSnackbar);

    const fromRestaurantId = form.watch('fromRestaurantId');

    const handleClose = () => {
        if (isSubmitting) return;
        setIsCopyMenuCompatible(true);
        closeCopyRestaurantMenuDialog();
    };

    const onSubmit = async (form: any) => {
        const response = await copyRestaurantMenuApi({
            fromRestaurantId: form.fromRestaurantId,
            toRestaurantId: restaurantId,
            menuId: form.menuId,
            overwrite: form.overwrite,
        });
        if (!response.ok) {
            alertKnownErrorOrSomethingWentWrong(response);
            return;
        }

        showSnackbar({ message: translate('Copied Restaurant Menu') });
        onSuccess?.();
        closeCopyRestaurantMenuDialog();
    };

    const handleChangeRestaurant = (restaurantOption: any) => setIsCopyMenuCompatible(!restaurantOption.newMenuManagementEnabled);

    return (
        <Dialog classes={{ dialog: classes.dialog }} title={translate('Copy Menu from other Restaurant')} open={open} onClose={handleClose}>
            <Form onSubmit={onSubmit} form={form} className={classes.form}>
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <FormRestaurantAutocomplete name='fromRestaurantId' label={translate('Restaurant Id')} required onChange={handleChangeRestaurant} />
                    </Grid>
                    <Grid item xs={12}>
                        <FormCheckbox
                            name='overwrite'
                            label={translate('Overwrite')}
                            helperText={translate('If checked the entire menu will be overwritten. Otherwise it will be added to the existing ones.')}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <FormMenuSelect
                            name='menuId'
                            label={translate('Menu Id')}
                            helperText={translate('If this input is empty will be copied all the menus from the restaurant, otherwise will copy just the selected menu.')}
                            restaurantId={fromRestaurantId}
                        />
                    </Grid>
                    {!isCopyMenuCompatible && (
                        <Grid item xs={12} style={{ textAlign: 'center' }}>
                            <Text error>{translate('Is not possible to copy the menu. The selected restaurant has a different management version.')}</Text>
                        </Grid>
                    )}
                </Grid>
                <DialogActions>
                    <Button secondary onClick={handleClose} disabled={isSubmitting}>
                        {translate('Cancel')}
                    </Button>
                    <Button type='submit' onClick={handleClose} disabled={isSubmitting || !isCopyMenuCompatible}>
                        {isSubmitting ? translate('Copying') : translate('Copy')}
                    </Button>
                </DialogActions>
            </Form>
        </Dialog>
    );
}

const useStyles = makeStyles((theme) => ({
    form: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    dialog: {
        width: '60vw',
        [theme.breakpoints.down('sm')]: {
            width: '90vw',
        },
    },
}));
