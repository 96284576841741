import { makeStyles } from '@material-ui/core';
import * as React from 'react';
import { GroupedOrderItems } from 'src/components/orderCard/orderItems/GroupedOrderItems';
import { translate } from 'src/i18n/translate';
import type { CustomerOrderDetailsVm } from 'src/types/CustomerOrderDetailsVm';

export function GroupedOrderItemsByCustomer({ customers }: Props): any {
    const classes = useStyles();

    if (!customers?.length) return null;

    return (
        <div className={classes.container}>
            {customers?.map((customer: CustomerOrderDetailsVm) => {
                if (!customer.orderItems?.length) return null;

                return (
                    <div>
                        <span className={classes.customerNumberLabel}>{translate('Customer @customerNumber', { customerNumber: customer.customerNumber })}</span>
                        <GroupedOrderItems orderItems={customer.orderItems} cancelledItems={customer.cancelledItems}></GroupedOrderItems>
                    </div>
                );
            })}
        </div>
    );
}

const useStyles = makeStyles((theme) => ({
    container: {
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        gap: 10,
    },
    customerNumberLabel: {
        fontFamily: theme.typography.medium,
        fontSize: 12,
    },
}));

type Props = {
    customers?: Array<CustomerOrderDetailsVm>;
};
