import { letseatmanagerApiMethod } from 'src/api/letseatmanagerApi';
import type { ApiSauceResponse } from 'src/api/types/ApiSauceResponse';
import type { MenuCategoryId } from 'src/types/Id';

export async function removeMenuCategoryApi(request: RemoveMenuCategoryApiRequest): ApiSauceResponse<void> {
    return letseatmanagerApiMethod('menuCategory/removeMenuCategoryApi', request);
}

type RemoveMenuCategoryApiRequest = {
    menuCategoryId: MenuCategoryId;
};
