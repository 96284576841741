import { letseatadminApiMethod } from 'src/api/letseatadminApi';
import type { ApiSauceResponse } from 'src/api/types/ApiSauceResponse';
import type { City } from 'src/constants/City';
import type { DriverBonusOfferId } from 'src/types/Id';

export async function getDriverBonusesBillingApi(request: GetDriverBonusesBillingApiRequest): ApiSauceResponse<DriverBonusesBillingVm> {
    return letseatadminApiMethod('driverBonusBilling/getDriverBonusesBillingApi', request);
}

type GetDriverBonusesBillingApiRequest = {
    from: string;
    to: string;
    cities?: Array<City>;
};

export type DriverBonusesBillingVm = {
    driverBonusesBilling: Array<DriverBonusBillingVm>;
};

export type DriverBonusBillingVm = {
    driverBonusOfferId: DriverBonusOfferId;
    name: string;
    usage: number;
    firstUsedAt?: Date;
    amount: string;
    drivers: number;
    city?: City;
};
