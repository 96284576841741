import apisauce from 'apisauce';
import moment from 'moment';
import { APP_VERSION } from 'src/config/appVersion';
import { store } from 'src/config/store';
import { Apps } from 'src/constants/App';
import { envPIDEDIRECTO_ADMIN_API_URL } from 'src/env/envPIDEDIRECTO_ADMIN_API_URL';
import { getDeviceId } from 'src/services/device/getDeviceId';
import { getSessionId } from 'src/services/device/getSessionId';
import { isJestTest } from 'src/utils/environment/isJestTest';
import { dateReviver } from 'src/utils/json/dateReviver';

// TODO: Rename this file to letseatmanagerApi and solve name clash
export const letseatadminApi: any = configLetseatadminApiSauce(); // TODO: return api sauce type

export function configLetseatadminApiSauce(): any {
    const baseURL = envPIDEDIRECTO_ADMIN_API_URL();
    const api = apisauce.create({
        baseURL,
        headers: {
            'Content-Type': 'application/vnd.api.autoparsedates+json',
            accept: 'application/vnd.api.autoparsedates+json',
        },
        transformResponse: [(data: any) => JSON.parse(data, dateReviver)],
        timeout: 60000, // 60 second timeout
    });

    api.addRequestTransform((request) => {
        if (isJestTest()) {
            // TODO: try to move this logic into setupTests.js, problem is that mocking code that runs inside code rendered by
            //  @testing-library/react render function is not mockable and is ignored, so currently this is the only safe way.
            throw Error(`Your test is calling api ${baseURL}/${request.url}. Make sure to mock all api calls in your tests, see usage of mockSuccessApiResponse.ts for examples.`);
        }
        if (!request.method) return;

        const state = store.getState();

        if (['get', 'head', 'delete', 'link', 'unlink'].includes(request.method)) {
            request.params.app = request.params.app ?? Apps.LETSEATMANAGER; // Remove later when app is no longer used backend and start using _app instead
            request.params.appVersion = APP_VERSION; // Remove later when appVersion is no longer used backend and start using _appVersion instead
            request.params._deviceId = getDeviceId();
            request.params._app = Apps.PIDEDIRECTOADMIN;
            request.params._pideDirectoAdminVersion = APP_VERSION;
            request.params._restaurantId = state.app.restaurantId;
            request.params._sessionId = getSessionId();
        } else {
            request.data = {
                ...request.data,
                app: request.data.app ?? Apps.LETSEATMANAGER, // Remove later when app is no longer used backend and start using _app instead
                appVersion: APP_VERSION, // Remove later when appVersion is no longer used backend and start using _appVersion instead
                _deviceId: getDeviceId(),
                _app: Apps.PIDEDIRECTOADMIN,
                _pideDirectoAdminVersion: APP_VERSION,
                _restaurantId: state.app.restaurantId,
                _sessionId: getSessionId(),
            };
        }
    });

    const isDebuggingInChrome = process.env.NODE_ENV === 'development' && !!window.navigator.userAgent; // eslint-disable-line no-undef
    if (isDebuggingInChrome) {
        api.addRequestTransform((request) => {
            console.log(`${request.method?.toUpperCase()} ${baseURL}/${request.url} @ ${moment().format('HH:mm:ss.SSS')}`, request.params ?? request.data);
        });
        api.addMonitor((response) => {
            console.log(`${response.status ?? ''} <- ${response.config?.url ?? ''} @ ${moment().format('HH:mm:ss.SSS')}`, response.data);
        });
    }

    return api;
}
