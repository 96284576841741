import { useState } from 'react';
import * as React from 'react';
import { useForm } from 'react-hook-form';
import { addProductCancellationReasonApi } from 'src/api/letseatmanager/restaurant/addProductCancellationReasonApi';
import { Button } from 'src/components/Button';
import { Dialog } from 'src/components/Dialog';
import { DialogActions } from 'src/components/DialogActions';
import { Form } from 'src/components/form/Form';
import { FormTextField } from 'src/components/form/FormTextField';
import { translate } from 'src/i18n/translate';
import { alertKnownErrorOrSomethingWentWrong } from 'src/utils/alert/alertKnownErrorOrSomethingWentWrong';
import { useSelector } from 'src/utils/react/useSelector';

export function AddProductCancellationReasonDialog({ open, onClose, onSuccess }: Props): React.ReactElement {
    const form = useForm();

    const { watch } = form;
    const reason = watch('reason');

    const [loading, setLoading] = useState(false);

    const restaurantId = useSelector((state) => state.app.restaurantId);

    const onSubmit = async () => {
        if (loading) return;
        if (!reason) return;
        setLoading(true);
        const response = await addProductCancellationReasonApi({ restaurantId, reason: reason });
        setLoading(false);
        if (!response.ok) {
            alertKnownErrorOrSomethingWentWrong(response);
            return;
        }
        onSuccess();
        onClose();
    };

    return (
        <Dialog open={open} onClose={onClose} title={translate('Add Cancellation Product Reason')}>
            <Form form={form}>
                <FormTextField disabled={loading} name='reason' label={translate('Cancellation Product Reason')} required />
                <DialogActions>
                    <Button onClick={onClose} disabled={loading} secondary>
                        {translate('Cancel')}
                    </Button>
                    <Button onClick={onSubmit} disabled={loading}>
                        {loading ? translate('Adding') : translate('Add')}
                    </Button>
                </DialogActions>
            </Form>
        </Dialog>
    );
}

type Props = {
    open: boolean;
    onClose: any;
    onSuccess: any;
};
