import type { ZoneSettings } from 'src/api/letseatadmin/settings/changeSettingsApi';
import { letseatadminApiMethod } from 'src/api/letseatadminApi';
import type { ApiSauceResponse } from 'src/api/types/ApiSauceResponse';
import type { City } from 'src/constants/City';
import type { Psp } from 'src/constants/Psp';
import type { SmsServiceProvider } from 'src/constants/SmsServiceProvider';
import type { DeliveryCategory, EmailAddress, EmailTemplateId } from 'src/types/Id';

export async function getSettingsApi(): ApiSauceResponse<SettingsVm> {
    return letseatadminApiMethod('settings/getSettingsApi');
}

export type SettingsVm = {
    managerMessageStyle?: string;
    managerMessageTitle?: string;
    managerMessageBody?: string;
    managerMessageStyleMty?: string;
    managerMessageTitleMty?: string;
    managerMessageBodyMty?: string;
    managerMessageStyleGdl?: string;
    managerMessageTitleGdl?: string;
    managerMessageBodyGdl?: string;
    managerMessageStyleCdMx?: string;
    managerMessageTitleCdMx?: string;
    managerMessageBodyCdMx?: string;
    managerMessageStyleSal?: string;
    managerMessageTitleSal?: string;
    managerMessageBodySal?: string;
    managerMessageStyleTrc?: string;
    managerMessageTitleTrc?: string;
    managerMessageBodyTrc?: string;
    managerMessageStyleQro?: string;
    managerMessageTitleQro?: string;
    managerMessageBodyQro?: string;
    managerMessageStyleMer?: string;
    managerMessageTitleMer?: string;
    managerMessageBodyMer?: string;
    managerMessageStylePue?: string;
    managerMessageTitlePue?: string;
    managerMessageBodyPue?: string;
    marketplaceServiceFeeRate?: string;
    subscriptionPaymentClabe?: string;
    subscriptionPaymentClabeToElevate?: string;
    addMarketplaceServiceFeeToMenuItemPrice: boolean;
    deliveryRadiusInKm: number;
    deliveryRadiusInKmAcapulco: number;
    deliveryRadiusInKmAcuna: number;
    deliveryRadiusInKmAguascalientes: number;
    deliveryRadiusInKmAltamira: number;
    deliveryRadiusInKmAtlacomulco: number;
    deliveryRadiusInKmAtlixco: number;
    deliveryRadiusInKmBogota: number;
    deliveryRadiusInKmCadereytaDeJimenez: number;
    deliveryRadiusInKmCampeche: number;
    deliveryRadiusInKmCancun: number;
    deliveryRadiusInKmCiudadVictoria: number;
    deliveryRadiusInKmCelaya: number;
    deliveryRadiusInKmChetumal: number;
    deliveryRadiusInKmChihuahua: number;
    deliveryRadiusInKmChilpancingoDeLosBravo: number;
    deliveryRadiusInKmChimalhuacan: number;
    deliveryRadiusInKmCiudadDelCarmen: number;
    deliveryRadiusInKmCiudadJuarez: number;
    deliveryRadiusInKmCiudadObregon: number;
    deliveryRadiusInKmCoatzacoalcos: number;
    deliveryRadiusInKmColima: number;
    deliveryRadiusInKmComitanDeDominguez: number;
    deliveryRadiusInKmCordoba: number;
    deliveryRadiusInKmCozumel: number;
    deliveryRadiusInKmCuauhtemoc: number;
    deliveryRadiusInKmCuautla: number;
    deliveryRadiusInKmCuernavaca: number;
    deliveryRadiusInKmCuliacan: number;
    deliveryRadiusInKmDelicias: number;
    deliveryRadiusInKmDoloresHidalgo: number;
    deliveryRadiusInKmDurango: number;
    deliveryRadiusInKmEnsenada: number;
    deliveryRadiusInKmFresnillo: number;
    deliveryRadiusInKmGarcia: number;
    deliveryRadiusInKmGdl: number;
    deliveryRadiusInKmGuanajuato: number;
    deliveryRadiusInKmGuasave: number;
    deliveryRadiusInKmGuaymas: number;
    deliveryRadiusInKmHermosillo: number;
    deliveryRadiusInKmIrapuato: number;
    deliveryRadiusInKmLaPaz: number;
    deliveryRadiusInKmLaPiedad: number;
    deliveryRadiusInKmLazaroCardenas: number;
    deliveryRadiusInKmLeon: number;
    deliveryRadiusInKmLosCabos: number;
    deliveryRadiusInKmLosMochis: number;
    deliveryRadiusInKmManzanillo: number;
    deliveryRadiusInKmMatamoros: number;
    deliveryRadiusInKmMazatlan: number;
    deliveryRadiusInKmMer: number;
    deliveryRadiusInKmMexicali: number;
    deliveryRadiusInKmCdMx: number;
    deliveryRadiusInKmMinatitlan: number;
    deliveryRadiusInKmMonclova: number;
    deliveryRadiusInKmMty: number;
    deliveryRadiusInKmMorelia: number;
    deliveryRadiusInKmNavojoa: number;
    deliveryRadiusInKmNogales: number;
    deliveryRadiusInKmNuevoLaredo: number;
    deliveryRadiusInKmOaxaca: number;
    deliveryRadiusInKmPachuca: number;
    deliveryRadiusInKmPiedrasNegras: number;
    deliveryRadiusInKmPlayaDelCarmen: number;
    deliveryRadiusInKmPozaRica: number;
    deliveryRadiusInKmProgreso: number;
    deliveryRadiusInKmPue: number;
    deliveryRadiusInKmPuertoVallarta: number;
    deliveryRadiusInKmQro: number;
    deliveryRadiusInKmReynosa: number;
    deliveryRadiusInKmRosarito: number;
    deliveryRadiusInKmSalamanca: number;
    deliveryRadiusInKmSal: number;
    deliveryRadiusInKmSanCristobalDeLasCasas: number;
    deliveryRadiusInKmSanJose: number;
    deliveryRadiusInKmSanJoseDelCabo: number;
    deliveryRadiusInKmSanJuanDelRio: number;
    deliveryRadiusInKmSanLuisPotosi: number;
    deliveryRadiusInKmSanLuisRioColorado: number;
    deliveryRadiusInKmSanMiguelDeAllende: number;
    deliveryRadiusInKmSantiago: number;
    deliveryRadiusInKmSilao: number;
    deliveryRadiusInKmSth: number;
    deliveryRadiusInKmTampico: number;
    deliveryRadiusInKmTapachula: number;
    deliveryRadiusInKmTehuacan: number;
    deliveryRadiusInKmTepic: number;
    deliveryRadiusInKmTexcoco: number;
    deliveryRadiusInKmTeziutlan: number;
    deliveryRadiusInKmTijuana: number;
    deliveryRadiusInKmTlaxcala: number;
    deliveryRadiusInKmToluca: number;
    deliveryRadiusInKmTrc: number;
    deliveryRadiusInKmTuxtlaGutierrez: number;
    deliveryRadiusInKmUruapan: number;
    deliveryRadiusInKmValleDeBravo: number;
    deliveryRadiusInKmValleDeChalco: number;
    deliveryRadiusInKmValleDeSantiago: number;
    deliveryRadiusInKmVeracruz: number;
    deliveryRadiusInKmVillahermosa: number;
    deliveryRadiusInKmXalapa: number;
    deliveryRadiusInKmZacatecas: number;
    deliveryRadiusInKmZamora: number;
    deliveryTimeFixed: number;
    deliveryTimePerKm: number;
    customerDeliveryCostFixed: number;
    customerDeliveryCostPerKm: number;
    dynamicEarningsMinimumMinutes: number;
    dynamicEarningsAmountToIncrementByMinute: number;
    dynamicEarningsRoof: number;
    deliveryOrchestratorFee: string;
    zoneSettings: ZoneSettings;
    longDistanceExtraDeliveryCostStartsFromKm?: number;
    longDistanceExtraDeliveryCostFixed?: number;
    longDistanceExtraDeliveryCostPerKm?: number;
    lookForDriversWithinRadiusForNearbyPendingDelivery: number;
    lookForDriversWithinRadiusForNearbyPendingDeliveryAcapulco: number;
    lookForDriversWithinRadiusForNearbyPendingDeliveryAcuna: number;
    lookForDriversWithinRadiusForNearbyPendingDeliveryAguascalientes: number;
    lookForDriversWithinRadiusForNearbyPendingDeliveryAltamira: number;
    lookForDriversWithinRadiusForNearbyPendingDeliveryAtlacomulco: number;
    lookForDriversWithinRadiusForNearbyPendingDeliveryAtlixco: number;
    lookForDriversWithinRadiusForNearbyPendingDeliveryBogota: number;
    lookForDriversWithinRadiusForNearbyPendingDeliveryCadereytaDeJimenez: number;
    lookForDriversWithinRadiusForNearbyPendingDeliveryCampeche: number;
    lookForDriversWithinRadiusForNearbyPendingDeliveryCancun: number;
    lookForDriversWithinRadiusForNearbyPendingDeliveryCiudadVictoria: number;
    lookForDriversWithinRadiusForNearbyPendingDeliveryCelaya: number;
    lookForDriversWithinRadiusForNearbyPendingDeliveryChetumal: number;
    lookForDriversWithinRadiusForNearbyPendingDeliveryChihuahua: number;
    lookForDriversWithinRadiusForNearbyPendingDeliveryChilpancingoDeLosBravo: number;
    lookForDriversWithinRadiusForNearbyPendingDeliveryChimalhuacan: number;
    lookForDriversWithinRadiusForNearbyPendingDeliveryCiudadDelCarmen: number;
    lookForDriversWithinRadiusForNearbyPendingDeliveryCiudadJuarez: number;
    lookForDriversWithinRadiusForNearbyPendingDeliveryCiudadObregon: number;
    lookForDriversWithinRadiusForNearbyPendingDeliveryCoatzacoalcos: number;
    lookForDriversWithinRadiusForNearbyPendingDeliveryColima: number;
    lookForDriversWithinRadiusForNearbyPendingDeliveryComitanDeDominguez: number;
    lookForDriversWithinRadiusForNearbyPendingDeliveryCordoba: number;
    lookForDriversWithinRadiusForNearbyPendingDeliveryCozumel: number;
    lookForDriversWithinRadiusForNearbyPendingDeliveryCuauhtemoc: number;
    lookForDriversWithinRadiusForNearbyPendingDeliveryCuautla: number;
    lookForDriversWithinRadiusForNearbyPendingDeliveryCuernavaca: number;
    lookForDriversWithinRadiusForNearbyPendingDeliveryCuliacan: number;
    lookForDriversWithinRadiusForNearbyPendingDeliveryDelicias: number;
    lookForDriversWithinRadiusForNearbyPendingDeliveryDoloresHidalgo: number;
    lookForDriversWithinRadiusForNearbyPendingDeliveryDurango: number;
    lookForDriversWithinRadiusForNearbyPendingDeliveryEnsenada: number;
    lookForDriversWithinRadiusForNearbyPendingDeliveryFresnillo: number;
    lookForDriversWithinRadiusForNearbyPendingDeliveryGarcia: number;
    lookForDriversWithinRadiusForNearbyPendingDeliveryGdl: number;
    lookForDriversWithinRadiusForNearbyPendingDeliveryGuanajuato: number;
    lookForDriversWithinRadiusForNearbyPendingDeliveryGuasave: number;
    lookForDriversWithinRadiusForNearbyPendingDeliveryGuaymas: number;
    lookForDriversWithinRadiusForNearbyPendingDeliveryHermosillo: number;
    lookForDriversWithinRadiusForNearbyPendingDeliveryIrapuato: number;
    lookForDriversWithinRadiusForNearbyPendingDeliveryLaPaz: number;
    lookForDriversWithinRadiusForNearbyPendingDeliveryLaPiedad: number;
    lookForDriversWithinRadiusForNearbyPendingDeliveryLazaroCardenas: number;
    lookForDriversWithinRadiusForNearbyPendingDeliveryLeon: number;
    lookForDriversWithinRadiusForNearbyPendingDeliveryLosCabos: number;
    lookForDriversWithinRadiusForNearbyPendingDeliveryLosMochis: number;
    lookForDriversWithinRadiusForNearbyPendingDeliveryManzanillo: number;
    lookForDriversWithinRadiusForNearbyPendingDeliveryMatamoros: number;
    lookForDriversWithinRadiusForNearbyPendingDeliveryMazatlan: number;
    lookForDriversWithinRadiusForNearbyPendingDeliveryMer: number;
    lookForDriversWithinRadiusForNearbyPendingDeliveryMexicali: number;
    lookForDriversWithinRadiusForNearbyPendingDeliveryCdMx: number;
    lookForDriversWithinRadiusForNearbyPendingDeliveryMinatitlan: number;
    lookForDriversWithinRadiusForNearbyPendingDeliveryMonclova: number;
    lookForDriversWithinRadiusForNearbyPendingDeliveryMty: number;
    lookForDriversWithinRadiusForNearbyPendingDeliveryMorelia: number;
    lookForDriversWithinRadiusForNearbyPendingDeliveryNavojoa: number;
    lookForDriversWithinRadiusForNearbyPendingDeliveryNogales: number;
    lookForDriversWithinRadiusForNearbyPendingDeliveryNuevoLaredo: number;
    lookForDriversWithinRadiusForNearbyPendingDeliveryOaxaca: number;
    lookForDriversWithinRadiusForNearbyPendingDeliveryPachuca: number;
    lookForDriversWithinRadiusForNearbyPendingDeliveryPiedrasNegras: number;
    lookForDriversWithinRadiusForNearbyPendingDeliveryPlayaDelCarmen: number;
    lookForDriversWithinRadiusForNearbyPendingDeliveryPozaRica: number;
    lookForDriversWithinRadiusForNearbyPendingDeliveryProgreso: number;
    lookForDriversWithinRadiusForNearbyPendingDeliveryPue: number;
    lookForDriversWithinRadiusForNearbyPendingDeliveryPuertoVallarta: number;
    lookForDriversWithinRadiusForNearbyPendingDeliveryQro: number;
    lookForDriversWithinRadiusForNearbyPendingDeliveryReynosa: number;
    lookForDriversWithinRadiusForNearbyPendingDeliveryRosarito: number;
    lookForDriversWithinRadiusForNearbyPendingDeliverySalamanca: number;
    lookForDriversWithinRadiusForNearbyPendingDeliverySal: number;
    lookForDriversWithinRadiusForNearbyPendingDeliverySanCristobalDeLasCasas: number;
    lookForDriversWithinRadiusForNearbyPendingDeliverySanJose: number;
    lookForDriversWithinRadiusForNearbyPendingDeliverySanJoseDelCabo: number;
    lookForDriversWithinRadiusForNearbyPendingDeliverySanJuanDelRio: number;
    lookForDriversWithinRadiusForNearbyPendingDeliverySanLuisPotosi: number;
    lookForDriversWithinRadiusForNearbyPendingDeliverySanLuisRioColorado: number;
    lookForDriversWithinRadiusForNearbyPendingDeliverySanMiguelAllende: number;
    lookForDriversWithinRadiusForNearbyPendingDeliverySantiago: number;
    lookForDriversWithinRadiusForNearbyPendingDeliverySilao: number;
    lookForDriversWithinRadiusForNearbyPendingDeliverySth: number;
    lookForDriversWithinRadiusForNearbyPendingDeliveryTampico: number;
    lookForDriversWithinRadiusForNearbyPendingDeliveryTapachula: number;
    lookForDriversWithinRadiusForNearbyPendingDeliveryTehuacan: number;
    lookForDriversWithinRadiusForNearbyPendingDeliveryTepic: number;
    lookForDriversWithinRadiusForNearbyPendingDeliveryTexcoco: number;
    lookForDriversWithinRadiusForNearbyPendingDeliveryTeziutlan: number;
    lookForDriversWithinRadiusForNearbyPendingDeliveryTijuana: number;
    lookForDriversWithinRadiusForNearbyPendingDeliveryTlaxcala: number;
    lookForDriversWithinRadiusForNearbyPendingDeliveryToluca: number;
    lookForDriversWithinRadiusForNearbyPendingDeliveryTrc: number;
    lookForDriversWithinRadiusForNearbyPendingDeliveryTuxtlaGutierrez: number;
    lookForDriversWithinRadiusForNearbyPendingDeliveryUruapan: number;
    lookForDriversWithinRadiusForNearbyPendingDeliveryValleDeBravo: number;
    lookForDriversWithinRadiusForNearbyPendingDeliveryValleDeChalco: number;
    lookForDriversWithinRadiusForNearbyPendingDeliveryValleDeSantiago: number;
    lookForDriversWithinRadiusForNearbyPendingDeliveryVeracruz: number;
    lookForDriversWithinRadiusForNearbyPendingDeliveryVillahermosa: number;
    lookForDriversWithinRadiusForNearbyPendingDeliveryXalapa: number;
    lookForDriversWithinRadiusForNearbyPendingDeliveryZacatecas: number;
    lookForDriversWithinRadiusForNearbyPendingDeliveryZamora: number;
    lookForDriversWithingRadius: number;
    lookForDriversWithingRadiusAcapulco: number;
    lookForDriversWithingRadiusAcuna: number;
    lookForDriversWithingRadiusAguascalientes: number;
    lookForDriversWithingRadiusAltamira: number;
    lookForDriversWithingRadiusAtlacomulco: number;
    lookForDriversWithingRadiusAtlixco: number;
    lookForDriversWithingRadiusBogota: number;
    lookForDriversWithingRadiusCadereytaDeJimenez: number;
    lookForDriversWithingRadiusCampeche: number;
    lookForDriversWithingRadiusCancun: number;
    lookForDriversWithingRadiusCiudadVictoria: number;
    lookForDriversWithingRadiusCelaya: number;
    lookForDriversWithingRadiusChetumal: number;
    lookForDriversWithingRadiusChihuahua: number;
    lookForDriversWithingRadiusChilpancingoDeLosBravo: number;
    lookForDriversWithingRadiusChimalhuacan: number;
    lookForDriversWithingRadiusCiudadDelCarmen: number;
    lookForDriversWithingRadiusCiudadJuarez: number;
    lookForDriversWithingRadiusCiudadObregon: number;
    lookForDriversWithingRadiusCoatzacoalcos: number;
    lookForDriversWithingRadiusColima: number;
    lookForDriversWithingRadiusComitanDeDominguez: number;
    lookForDriversWithingRadiusCordoba: number;
    lookForDriversWithingRadiusCozumel: number;
    lookForDriversWithingRadiusCuauhtemoc: number;
    lookForDriversWithingRadiusCuautla: number;
    lookForDriversWithingRadiusCuernavaca: number;
    lookForDriversWithingRadiusCuliacan: number;
    lookForDriversWithingRadiusDelicias: number;
    lookForDriversWithingRadiusDoloresHidalgo: number;
    lookForDriversWithingRadiusDurango: number;
    lookForDriversWithingRadiusEnsenada: number;
    lookForDriversWithingRadiusFresnillo: number;
    lookForDriversWithingRadiusGarcia: number;
    lookForDriversWithingRadiusGdl: number;
    lookForDriversWithingRadiusGuanajuato: number;
    lookForDriversWithingRadiusGuasave: number;
    lookForDriversWithingRadiusGuaymas: number;
    lookForDriversWithingRadiusHermosillo: number;
    lookForDriversWithingRadiusIrapuato: number;
    lookForDriversWithingRadiusLaPaz: number;
    lookForDriversWithingRadiusLaPiedad: number;
    lookForDriversWithingRadiusLazaroCardenas: number;
    lookForDriversWithingRadiusLeon: number;
    lookForDriversWithingRadiusLosCabos: number;
    lookForDriversWithingRadiusLosMochis: number;
    lookForDriversWithingRadiusManzanillo: number;
    lookForDriversWithingRadiusMatamoros: number;
    lookForDriversWithingRadiusMazatlan: number;
    lookForDriversWithingRadiusMer: number;
    lookForDriversWithingRadiusMaxicali: number;
    lookForDriversWithingRadiusCdMx: number;
    lookForDriversWithingRadiusMinatitlan: number;
    lookForDriversWithingRadiusMonclova: number;
    lookForDriversWithingRadiusMty: number;
    lookForDriversWithingRadiusMorelia: number;
    lookForDriversWithingRadiusNavojoa: number;
    lookForDriversWithingRadiusNogales: number;
    lookForDriversWithingRadiusNuevoLaredo: number;
    lookForDriversWithingRadiusOaxaca: number;
    lookForDriversWithingRadiusPachuca: number;
    lookForDriversWithingRadiusPiedrasNegras: number;
    lookForDriversWithingRadiusPlayaDelCarmen: number;
    lookForDriversWithingRadiusPozaRica: number;
    lookForDriversWithingRadiusProgreso: number;
    lookForDriversWithingRadiusPue: number;
    lookForDriversWithingRadiusPuertoVallarta: number;
    lookForDriversWithingRadiusQro: number;
    lookForDriversWithingRadiusReynosa: number;
    lookForDriversWithingRadiusRosarito: number;
    lookForDriversWithingRadiusSalamanca: number;
    lookForDriversWithingRadiusSal: number;
    lookForDriversWithingRadiusSanCristobalDeLasCasas: number;
    lookForDriversWithingRadiusSanJose: number;
    lookForDriversWithingRadiusSanJoseDelCabo: number;
    lookForDriversWithingRadiusSanJuanDelRio: number;
    lookForDriversWithingRadiusSanLuisPotosi: number;
    lookForDriversWithingRadiusSanLuisRioColorado: number;
    lookForDriversWithingRadiusSanMiguelAllende: number;
    lookForDriversWithingRadiusSantiago: number;
    lookForDriversWithingRadiusSilao: number;
    lookForDriversWithingRadiusSth: number;
    lookForDriversWithingRadiusTampico: number;
    lookForDriversWithingRadiusTapachula: number;
    lookForDriversWithingRadiusTehuacan: number;
    lookForDriversWithingRadiusTepic: number;
    lookForDriversWithingRadiusTexcoco: number;
    lookForDriversWithingRadiusTeziutlan: number;
    lookForDriversWithingRadiusTijuana: number;
    lookForDriversWithingRadiusTlaxcala: number;
    lookForDriversWithingRadiusToluca: number;
    lookForDriversWithingRadiusTrc: number;
    lookForDriversWithingRadiusTuxtlaGutierrez: number;
    lookForDriversWithingRadiusUruapan: number;
    lookForDriversWithingRadiusValleDeBravo: number;
    lookForDriversWithingRadiusValleDeChalco: number;
    lookForDriversWithingRadiusValleDeSantiago: number;
    lookForDriversWithingRadiusVeracruz: number;
    lookForDriversWithingRadiusVillahermosa: number;
    lookForDriversWithingRadiusXalapa: number;
    lookForDriversWithingRadiusZacatecas: number;
    lookForDriversWithingRadiusZamora: number;
    lookForDriversWithPositionsNotOlderThan: number;
    delayTimeForNonPrioDrivers: number;
    driverDeliveryAcceptTime: number;
    driverDeliveryCancellationTime: number;
    reSendRejectedDeliveryRequestTime: number;
    driverDeliveryFailTime: number;
    driverAllowedToPressDeliveredRadius: number;
    driverAllowedToPressPickedUpRadius: number;
    driverLateForPickupAlarmTime: number;
    timeToDistributeNearByPendingDelivery: number;
    timeToDistributeMultiPickup: number;
    maximumNumberOfOngoingDeliveries: number;
    multiPickupDeliveryRadius: number;
    driverLateForPickupAlarmDistance: number;
    maximumCashOrderAmountMx: string;
    maximumCashOrderAmountUy: string;
    maximumCashOrderAmountAr: string;
    expensiveOrderAmount?: number;
    deliveriesRequiredForExpensiveOrder?: number;
    expensiveOrderFilteringEnabledCities: Array<City>;
    deliverySecurityCodeMinimumAmount?: number;
    sendThanksForOrderingEmailMinutesAfter: number;
    thanksForOrderingEmailTemplateId: EmailTemplateId;
    sendWelcomeEmailMinutesAfter: number;
    welcomeEmailTemplateId: EmailTemplateId;
    sendMenuHighlightEmailDaysAfter: number;
    menuHighlightEmailTemplateId: EmailTemplateId;
    sendPromoCodeEmailDaysAfter: number;
    promoCodeEmailTemplateId: EmailTemplateId;
    prioritizeOldestNearbyDeliveriesEnabled: boolean;
    hasMandatoryUpdateToPideDirectoAppEnabled: boolean;
    hasMandatoryUpdateToAdminAppEnabled: boolean;
    hasMandatoryUpdateToDriverAppEnabled: boolean;
    toggleAutomaticDynamicDeliveryEarnings: boolean;
    deliveryCategories: Array<DeliveryCategory>;
    pspPriority: Array<Psp>;
    smsServiceProviderForAuthenticationSmsesInMx?: SmsServiceProvider;
    smsServiceProviderForHighPrioSmsesInMx?: SmsServiceProvider;
    smsServiceProviderForLowPrioSmsesInMx?: SmsServiceProvider;
    smsServiceProviderForAuthenticationSmsesInSe?: SmsServiceProvider;
    smsServiceProviderForHighPrioSmsesInSe?: SmsServiceProvider;
    smsServiceProviderForLowPrioSmsesInSe?: SmsServiceProvider;
    smsServiceProviderForAuthenticationSmsesInUs?: SmsServiceProvider;
    smsServiceProviderForHighPrioSmsesInUs?: SmsServiceProvider;
    smsServiceProviderForLowPrioSmsesInUs?: SmsServiceProvider;
    smsServiceProviderForAuthenticationSmsesInUy?: SmsServiceProvider;
    smsServiceProviderForHighPrioSmsesInUy?: SmsServiceProvider;
    smsServiceProviderForLowPrioSmsesInUy?: SmsServiceProvider;
    smsServiceProviderForAuthenticationSmsesInAr?: SmsServiceProvider;
    smsServiceProviderForHighPrioSmsesInAr?: SmsServiceProvider;
    smsServiceProviderForLowPrioSmsesInAr?: SmsServiceProvider;
    smsServiceProviderForAuthenticationSmsesInBr?: SmsServiceProvider;
    smsServiceProviderForHighPrioSmsesInBr?: SmsServiceProvider;
    smsServiceProviderForLowPrioSmsesInBr?: SmsServiceProvider;
    smsServiceProviderForAuthenticationSmsesInPe?: SmsServiceProvider;
    smsServiceProviderForHighPrioSmsesInPe?: SmsServiceProvider;
    smsServiceProviderForLowPrioSmsesInPe?: SmsServiceProvider;
    smsServiceProviderForAuthenticationSmsesInCl?: SmsServiceProvider;
    smsServiceProviderForHighPrioSmsesInCl?: SmsServiceProvider;
    smsServiceProviderForLowPrioSmsesInCl?: SmsServiceProvider;
    smsServiceProviderForAuthenticationSmsesInPy?: SmsServiceProvider;
    smsServiceProviderForHighPrioSmsesInPy?: SmsServiceProvider;
    smsServiceProviderForLowPrioSmsesInPy?: SmsServiceProvider;
    smsServiceProviderForAuthenticationSmsesInCo?: SmsServiceProvider;
    smsServiceProviderForHighPrioSmsesInCo?: SmsServiceProvider;
    smsServiceProviderForLowPrioSmsesInCo?: SmsServiceProvider;
    deliveryManagerMaxRuntime: number;
    deliveryManagerMaxRuntimeMty: number;
    deliveryManagerMaxRuntimeGdl: number;
    deliveryManagerMaxRuntimeCdMx: number;
    deliveryManagerMaxRuntimeSal: number;
    deliveryManagerMaxRuntimeTrc: number;
    deliveryManagerMaxRuntimeQro: number;
    deliveryManagerMaxRuntimeMer: number;
    deliveryManagerMaxRuntimePue: number;
    nearbyPendingDeliveryEnabled: boolean;
    multiPickupEnabled: boolean;
    uberEatsDeliveryEnabled: boolean;
    rappiCargoDeliveryEnabled: boolean;
    deliveryCommissionEnabled: boolean;
    driverReferralDaysToExpire: number;
    driverReferralReferredDriverEarnings: number;
    driverReferralReferredDriverEarningsMty: number;
    driverReferralReferredDriverEarningsGdl: number;
    driverReferralReferredDriverEarningsCdMx: number;
    driverReferralReferredDriverEarningsSal: number;
    driverReferralReferredDriverEarningsTrc: number;
    driverReferralReferredDriverEarningsQro: number;
    driverReferralReferredDriverEarningsMer: number;
    driverReferralReferredDriverEarningsPue: number;
    driverReferralReferredByDriverEarnings: number;
    driverReferralReferredByDriverEarningsMty: number;
    driverReferralReferredByDriverEarningsGdl: number;
    driverReferralReferredByDriverEarningsCdMx: number;
    driverReferralReferredByDriverEarningsSal: number;
    driverReferralReferredByDriverEarningsTrc: number;
    driverReferralReferredByDriverEarningsQro: number;
    driverReferralReferredByDriverEarningsMer: number;
    driverReferralReferredByDriverEarningsPue: number;
    driverReferralDeliveriesToDeliver: number;
    driverReferralDeliveriesToDeliverMty: number;
    driverReferralDeliveriesToDeliverGdl: number;
    driverReferralDeliveriesToDeliverCdMx: number;
    driverReferralDeliveriesToDeliverSal: number;
    driverReferralDeliveriesToDeliverTrc: number;
    driverReferralDeliveriesToDeliverQro: number;
    driverReferralDeliveriesToDeliverMer: number;
    driverReferralDeliveriesToDeliverPue: number;
    autoCallRestaurantsEnabled: boolean;
    whatsAppAuthenticationEnabled: boolean;
    timeToAutoCallRestaurants: number;
    deliveryCostBonus: number;
    payPerUseRange1OrdersLimit: number;
    payPerUseRange1PaymentAmount: string;
    payPerUseRange2OrdersLimit: number;
    payPerUseRange2PaymentAmount: string;
    payPerUseRangeMaxOrdersLimit: number;
    payPerUseRangeMaxPaymentAmount: string;
    emailsToNotifySubscriptionChanges: Array<EmailAddress>;
    emailsToNotifyRestaurantIntegrationsIssues: Array<EmailAddress>;
};
