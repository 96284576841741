import * as React from 'react';
import { DeprecatedFormRestaurantInvoiceFiscalIdAutocomplete } from 'src/components/form/DeprecatedFormRestaurantInvoiceFiscalIdAutocomplete';
import { translate } from 'src/i18n/translate';
import { isRfc } from 'src/utils/string/isRfc';
import { removeSpaceCharacters } from 'src/utils/string/removeSpaceCharacters';
import { trim } from 'src/utils/string/trim';

export function FormRfcAutocompleteField({ name, label, placeholder, required, onSelect, helperText, defaultValue }: Props): React.ReactElement {
    return (
        <DeprecatedFormRestaurantInvoiceFiscalIdAutocomplete
            name={name}
            label={label}
            placeholder={placeholder}
            helperText={helperText}
            defaultValue={defaultValue}
            onSelect={onSelect}
            required={required}
            validate={{
                rfc: (value: any) => ((trim(value)?.length ?? 0) < 1 || isRfc(removeSpaceCharacters(value)) ? true : translate('This field must contain 12-13 alphabetic or numeric characters')),
            }}
        />
    );
}

export type Props = {
    name: string;
    label?: string;
    placeholder?: string;
    helperText?: string;
    defaultValue?: string;
    validate?: any;
    required?: boolean;
    onSelect: any;
};
