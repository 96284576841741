import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Grid from '@material-ui/core/Grid';
import { Loader } from '@pidedirecto/ui';
import * as React from 'react';
import { useState } from 'react';
import { useForm, useFormContext } from 'react-hook-form';
import { createBannerApi } from 'src/api/letseatadmin/banner/createBannerApi';
import { pidedirectouploadApi } from 'src/api/pidedirectouploadApi';
import { Form } from 'src/components/form/Form';
import { FormBannerVisibilitySelect } from 'src/components/form/FormBannerVisibilitySelect';
import { FormCheckbox } from 'src/components/form/FormCheckbox';
import { FormCompanyNamesAutocomplete } from 'src/components/form/FormCompanyNamesAutocomplete';
import { FormCountrySelect } from 'src/components/form/FormCountrySelect';
import { FormDeliveryCategoriesAutocomplete } from 'src/components/form/FormDeliveryCategoriesAutocomplete';
import { FormFixErrorHint } from 'src/components/form/FormFixErrorHint';
import { FormHoursField } from 'src/components/form/FormHoursField';
import { FormRestaurantsAutocomplete } from 'src/components/form/FormRestaurantsAutocomplete';
import { FormUrlField } from 'src/components/form/FormUrlField';
import ImageUploader from 'src/components/input/ImageUploader';
import { ImageTypes } from 'src/constants/ImageType';
import { translate } from 'src/i18n/translate';
import { appLayout } from 'src/theme/AppLayout';
import { alertKnownErrorOrSomethingWentWrong } from 'src/utils/alert/alertKnownErrorOrSomethingWentWrong';
import { isExternalImageUrl } from 'src/utils/image/isExternalImageUrl';
import { trim } from 'src/utils/string/trim';

export function CreateBannerDialog({ open, onClose, onBannerCreated }: Props): React.ReactElement {
    const form = useForm();
    const [loading, setLoading] = useState(false);

    const {
        formState: { isSubmitting },
    } = form;

    const handleClose = () => {
        if (isSubmitting) return;
        onClose();
    };

    const onSubmit = async (form: any) => {
        setLoading(true);
        let imageUrl = form.imageUrl;
        if (isExternalImageUrl(imageUrl)) {
            const migrateResponse = await pidedirectouploadApi.images.migrate({
                imageUrl: imageUrl,
                imageType: ImageTypes.BANNER,
            });
            if (!migrateResponse.ok) {
                setLoading(false);
                alertKnownErrorOrSomethingWentWrong(migrateResponse);
                return;
            }
            imageUrl = migrateResponse.data;
        }

        const response = await createBannerApi({
            bannerVisibility: form.bannerVisibility,
            deliveryCategories: form.deliveryCategories ? form.deliveryCategories.map(trim) : [],
            enabled: form.enabled,
            hours: form.hours,
            country: form.country,
            imageUrl: imageUrl,
            restaurantIds: form.restaurantIds,
            companyNames: form.companyNames,
        });

        setLoading(false);
        if (!response.ok) {
            alertKnownErrorOrSomethingWentWrong(response);
            return;
        }
        onBannerCreated();
        onClose();
    };

    return (
        <Dialog fullWidth scroll='paper' open={open} onClose={handleClose}>
            <Form form={form} onSubmit={onSubmit} style={appLayout.dialog.form}>
                <DialogTitle>{translate('Create Banner').toUpperCase()}</DialogTitle>
                <DialogContent>
                    <FormContent loading={loading} />
                    <FormFixErrorHint />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} disabled={isSubmitting}>
                        {translate('Cancel').toUpperCase()}
                    </Button>
                    <Button color='primary' type='submit' disabled={isSubmitting}>
                        {isSubmitting ? translate('Creating').toUpperCase() : translate('Create').toUpperCase()}
                    </Button>
                </DialogActions>
            </Form>
        </Dialog>
    );
}

function FormContent({ loading }: { loading: boolean }) {
    const [uploadingImage, setUploadingImage] = useState(false);
    const { setValue } = useFormContext();
    if (loading) {
        return <Loader size={40} loading={true} />;
    }
    return (
        <Grid container spacing={3}>
            <Grid item xs={12}>
                <FormBannerVisibilitySelect name='bannerVisibility' label={translate('Visibility')} required />
            </Grid>
            <Grid item xs={12}>
                <FormDeliveryCategoriesAutocomplete
                    name='deliveryCategories'
                    label={translate('Delivery Categories')}
                    helperText={`${translate('Choose what delivery categories this banner should be visible. See Settings > Delivery Categories for categories to use.')}`}
                />
            </Grid>
            <Grid item xs={12}>
                <FormCheckbox name='enabled' label={translate('Enabled')} />
            </Grid>
            <Grid item xs={12}>
                <FormHoursField
                    name='hours'
                    label={translate('Hours')}
                    placeholder={translate('Mo-Su 00:00-24:00')}
                    helperText={translate('E.g. "Mo-Sa 07:00-22:00;Su 09:00-20:00" or "Mar Mo-Fr 11:00-14:00". see https://openingh.openstreetmap.de/evaluation_tool/#check')}
                />
            </Grid>
            <Grid item xs={12} md={6}>
                <FormUrlField
                    name='imageUrl'
                    label={uploadingImage ? translate('Uploading Image...') : translate('Image')}
                    helperText={uploadingImage ? undefined : translate('Enter an URL to an image or drag and drop your image in this field')}
                    required
                />
                <ImageUploader
                    imageType={ImageTypes.RESTAURANT}
                    targetName='imageUrl'
                    onUploadingChanged={(uploading) => setUploadingImage(uploading)}
                    onUploadSuccess={(imageUrl) => setValue('imageUrl', imageUrl)}
                />
            </Grid>
            <Grid item xs={12}>
                <FormRestaurantsAutocomplete name='restaurantIds' label={translate('Restaurants')} />
            </Grid>
            <Grid item xs={12}>
                <FormCompanyNamesAutocomplete name='companyNames' label={translate('Company Names')} />
            </Grid>
            <Grid item xs={12}>
                <FormCountrySelect name='country' label={translate('Country')} required />
            </Grid>
        </Grid>
    );
}

type Props = {
    open: boolean;
    onClose: any;
    onBannerCreated: any;
};
