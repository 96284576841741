import { letseatmanagerApiMethod } from 'src/api/letseatmanagerApi';
import type { ApiSauceResponse } from 'src/api/types/ApiSauceResponse';
import type { DeviceGroupVm } from 'src/types/DeviceGroupVm';
import type { DeviceGroupId, DeviceId, PaymentTerminalId, PrinterId } from 'src/types/Id';

export async function changeDeviceGroupApi(request: ChangeDeviceGroupApiRequest): ApiSauceResponse<DeviceGroupVm> {
    return letseatmanagerApiMethod('deviceGroup/changeDeviceGroupApi', request);
}

export type ChangeDeviceGroupApiRequest = {
    deviceGroupId: DeviceGroupId;
    deviceGroupName: string;
    deviceIds?: Array<DeviceId>;
    printerIds?: Array<PrinterId>;
    paymentTerminalIds?: Array<PaymentTerminalId>;
};
