import { BigNumber } from 'bignumber.js';
import moment from 'moment/moment';
import { useContext } from 'react';
import { PosContext } from 'src/scenes/letseatmanager/pos/PosProvider';
import { useHasPaymentTerminalsAvailable } from 'src/services/device/useHasPaymentTerminalsAvailable';
import { useShouldClosePendingOrder } from 'src/services/pos/useShouldClosePendingOrder';
import { isDeliveryOrder } from 'src/utils/order/isDeliveryOrder';
import { isCardPayment } from 'src/utils/paymentMethod/isCardPayment';
import { useSelector } from 'src/utils/react/useSelector';

export function useShouldRenderPosPaymentSecondaryButton(): boolean {
    const { menuOpenedAt } = useContext(PosContext);
    const hasPaymentTerminalsAvailable = useHasPaymentTerminalsAvailable();
    const shouldClosePendingOrder = useShouldClosePendingOrder();

    const total = useSelector((state) => state.pos.payment?.total);
    const posPendingOrdersEnabled = useSelector((state) => state.pos.context?.posPendingOrdersEnabled);
    const orderType = useSelector((state) => state.pos.orderType);
    const pendingOrder = useSelector((state) => state.pos.pendingOrder);
    const payments = useSelector((state) => state.pos.payments);

    const isOngoingPendingOrder = pendingOrder && moment(pendingOrder?.createdAt).isSame(menuOpenedAt);
    const hasSomeCustomerCreditCardTipPayment = payments?.some((payment) => payment.isTipPayment && payment.customerNumber && isCardPayment(payment.paymentMethod) && hasPaymentTerminalsAvailable);
    const shouldRenderSecondaryButton =
        ((BigNumber(total).isZero() && !pendingOrder) || (pendingOrder && !isOngoingPendingOrder)) &&
        posPendingOrdersEnabled &&
        !isDeliveryOrder(orderType) &&
        !shouldClosePendingOrder &&
        !hasSomeCustomerCreditCardTipPayment;

    return shouldRenderSecondaryButton;
}
