import { makeStyles } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import moment from 'moment';
import * as React from 'react';
import { useEffect } from 'react';
import { useForm, useWatch } from 'react-hook-form';
import { app2 } from 'src/app2';
import { Form } from 'src/components/form/Form';
import { FormBrandRestaurantSelect } from 'src/components/form/FormBrandRestaurantSelect';
import { FormSwitch } from 'src/components/form/FormSwitch';
import { translate } from 'src/i18n/translate';
import { RestaurantId } from 'src/types/Id';
import { useAction } from 'src/utils/react/useAction';
import { useSelector } from 'src/utils/react/useSelector';

export function FilterMasterReports({ onChangeMaster, showAsMaster }: Props): React.ReactElement | null {
    const classes = useStyles();
    const form = useForm();
    const { control } = form;

    const brandOpened = useSelector((state) => state.app.brandOpened);
    const restaurantIds = useSelector((state) => state.app.restaurantIds);
    const period = useSelector((state) => state.app2.filterReportsState.period);
    const currentRestaurantId = useSelector((state) => state.app2.filterReportsState.currentRestaurantId);

    const setCurrentRestaurantId = useAction(app2.actions.setCurrentRestaurantId);

    const currentRestaurant = useWatch<RestaurantId>({ name: 'fromRestaurantId', control });
    const asMaster = useWatch<boolean>({ name: 'showAsMaster', control });

    const defaultRestaurantValue = currentRestaurantId ?? restaurantIds[0];

    useEffect(() => {
        setCurrentRestaurantId(currentRestaurant ?? restaurantIds[0]);
    }, [currentRestaurant]);

    useEffect(() => {
        onChangeMaster(asMaster);
    }, [asMaster]);

    if (!brandOpened) return null;

    return (
        <div className={classes.containerKpis}>
            <Form form={form} className={classes.selectLocations}>
                <Grid container className={classes.headingContainer}>
                    <Grid item xs={6} md={4} className={classes.dates}>
                        {translate('From')} {moment(period.from).format('ll')} {translate('to')} {moment(period.to).format('ll')}
                    </Grid>
                    <Grid item xs={6} md={2}>
                        <FormSwitch name={'showAsMaster'} label={'Master'} defaultValue={showAsMaster} />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <FormBrandRestaurantSelect name='fromRestaurantId' label={translate('Restaurant')} required filter={restaurantIds} defaultValue={defaultRestaurantValue} disabled={asMaster} />
                    </Grid>
                </Grid>
            </Form>
        </div>
    );
}

const useStyles = makeStyles((theme) => ({
    containerKpis: {
        display: 'flex',
        justifyContent: 'space-between',
    },
    headingContainer: {
        width: '100%',
        display: 'flex',
        flexWrap: 'wrap',
        flexDirection: 'row',
        marginTop: 16,
        marginBottom: '10px',
    },
    dates: {
        marginTop: 10,
        fontFamily: theme.typography.regular,
        fontSize: 16,
        color: theme.palette.text.secondary,
        marginBottom: 16,
    },
    selectLocations: {
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        [theme.breakpoints.between('md', 'lg')]: {
            flexDirection: 'row',
        },
    },
}));

type Props = {
    onChangeMaster: any;
    showAsMaster: boolean;
};
