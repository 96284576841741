import { letseatadminApiMethod } from 'src/api/letseatadminApi';
import type { ApiSauceResponse } from 'src/api/types/ApiSauceResponse';
import type { ImportRestaurantDetailsUberDirect } from 'src/utils/csv/convertCsvToImportRestaurantDetailsUberDirect';

export async function importRestaurantDetailsUberDirectApi(request: ImportRestaurantDetailsUberDirect): ApiSauceResponse<ExportUberDirectRestaurantDetailsVm> {
    return letseatadminApiMethod('restaurantDetails/importRestaurantDetailsUberDirectApi', request);
}

export type ExportUberDirectRestaurantDetailsVm = {
    imported: number;
};
