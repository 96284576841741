import { makeStyles } from '@material-ui/core';
import * as React from 'react';
import { PosModifier } from 'src/scenes/letseatmanager/pos/posAddMenuItem/PosModifier';
import { PosModifierGroupHeader } from 'src/scenes/letseatmanager/pos/posAddMenuItem/PosModifierGroupHeader';
import { usePosCartModifierSections } from 'src/services/pos/posModifiersCart/usePosCartModifierSections';

export function PosModifiers(): React.ReactElement {
    const classes = useStyles();

    const posCartModifierSections = usePosCartModifierSections();

    return (
        <div className={classes.sectionsContainer}>
            {posCartModifierSections.map((section) => (
                <div className={classes.sectionContainer} key={section.key}>
                    <PosModifierGroupHeader modifierGroup={section.modifierGroup} />
                    <div className={classes.modifiersContainer}>
                        {section.data.map((modifier) => {
                            return <PosModifier key={modifier.name} modifierGroup={section.modifierGroup} modifier={modifier} />;
                        })}
                    </div>
                </div>
            ))}
        </div>
    );
}

const useStyles = makeStyles((theme) => ({
    sectionsContainer: {
        width: '60%',
        height: '100%',
        marginTop: 20,
        marginBottom: 0,
        zIndex: 98,
        overflowY: 'auto',
        backgroundColor: theme.palette.surface.primary,
        [theme.breakpoints.up('lg')]: {
            width: '65%',
            height: '100%',
        },
        [theme.breakpoints.down('sm')]: {
            width: '100%',
            height: '50vh',
            overflowY: 'scroll',
        },
    },
    sectionContainer: {
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        flexShrink: 0,
        marginBottom: 12,
    },
    sectionName: {
        fontFamily: theme.typography.regular,
        fontSize: 15,
        color: theme.palette.text.secondary,
        margin: 0,
    },
    modifiersContainer: {
        display: 'flex',
        marginTop: 10,
        overflowX: 'auto',
        '&::-webkit-scrollbar': {
            display: 'none',
        },
        [theme.breakpoints.up('sm')]: {
            flexWrap: 'wrap',
            overflowX: 'unset',
        },
    },
}));
