import { makeStyles } from '@material-ui/core';
import * as React from 'react';
import { Page } from 'src/components/Page';
import { translate } from 'src/i18n/translate';
import DemoRestaurantsTable from 'src/scenes/letseatadmin/demoRestaurants/DemoRestaurantsTable';
import { DemoRestaurantVm } from 'src/types/DemoRestaurantVm';

export function DemoRestaurantsPage(): React.ReactElement {
    const classes = useStyles();

    return (
        <Page title={translate('Demo Restaurants')}>
            <div className={classes.container}>
                <DemoRestaurantsTable />
            </div>
        </Page>
    );
}

const useStyles = makeStyles((theme) => ({
    container: {
        position: 'relative',
    },
}));

export type DemoRestaurantsVm = {
    demoRestaurants: Array<DemoRestaurantVm>;
};
