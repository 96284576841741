import { InputBase, InputLabel, makeStyles, Typography } from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';
import FormHelperText from '@material-ui/core/FormHelperText';
import InputAdornment from '@material-ui/core/InputAdornment';
import useAutocomplete from '@material-ui/lab/useAutocomplete';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { throttle } from 'throttle-debounce';
import { findRestaurantCustomersOptionsApi } from 'src/api/letseatmanager/restaurantCustomer/findRestaurantCustomersOptionsApi';
import { getError } from 'src/components/form/utils/getError';
import { translate } from 'src/i18n/translate';
import type { RestaurantCustomerVm } from 'src/types/RestaurantCustomerVm';
import { alertKnownErrorOrSomethingWentWrong } from 'src/utils/alert/alertKnownErrorOrSomethingWentWrong';
import { isPossiblePhoneNumber } from 'src/utils/is/isPossiblePhoneNumber';
import { isValidPhoneNumber } from 'src/utils/is/isValidPhoneNumber';
import { useSelector } from 'src/utils/react/useSelector';

export function FormPhoneNumberCustomerAutocomplete({ name, label, placeholder, onSelect, required, helperText, defaultValue, onCustomersChange }: Props): React.ReactElement {
    const classes = useStyles();
    const { errors, control, watch, setValue } = useFormContext();

    let currentOptionSelected: RestaurantCustomerVm | null;

    const [options, setOptions] = useState<Array<RestaurantCustomerVm>>([]);
    const [loading, setLoading] = useState(false);
    const [inputValue, setInputValue] = useState(defaultValue || '');

    const restaurantId = useSelector((state) => state.app.restaurantId);
    const country = useSelector((state) => state.app.restaurant.country);

    const { getRootProps, getInputProps, getListboxProps, getOptionProps, groupedOptions } = useAutocomplete({
        id: 'use-autocomplete-customer',
        options,
        getOptionLabel: (option) => option.mobileNumber,
        onChange: () => {
            if (currentOptionSelected) handleItem(currentOptionSelected);
        },
        onHighlightChange: (e, option) => {
            currentOptionSelected = option;
        },
    });

    const formInputValue = watch(name);

    useEffect(() => {
        if (inputValue === '' && formInputValue) setInputValue(formInputValue);
    }, [inputValue, formInputValue]);

    const autocompleteSearch = (value: any) => fetch(value);

    const fetch = async (value: any) => {
        setLoading(true);
        const response = await findRestaurantCustomersOptionsApi({ mobileNumber: value, restaurantId });
        setLoading(false);
        if (!response.ok) return alertKnownErrorOrSomethingWentWrong(response);
        setOptions(response.data);

        onCustomersChange?.(response.data);
    };

    const handleItem = (item: RestaurantCustomerVm) => {
        setValue(name, item.mobileNumber, { shouldValidate: true });
        onSelect(item);
        setInputValue(item.mobileNumber);
    };

    useEffect(() => {
        if (defaultValue) {
            setInputValue(defaultValue);
        }
    }, [defaultValue]);

    useEffect(() => {
        if (inputValue.length > 0) {
            autocompleteSearchThrottled(inputValue);
        }
    }, [inputValue]);

    const autocompleteSearchThrottled = throttle(1000, autocompleteSearch);

    return (
        <Controller
            render={({ value, onChange, ...props }) => {
                const helperTextIsValidPhoneNumber = inputValue.length < 5 || isValidPhoneNumber(inputValue) ? undefined : translate('are you sure this is a correct phone number?');
                return (
                    <div>
                        <div {...getRootProps()}>
                            <InputLabel htmlFor={(getInputProps() as any).id} classes={{ root: classes.label, error: classes.labelError }} error={!!getError(errors, name)}>
                                {label}
                            </InputLabel>
                            <InputBase
                                {...props}
                                {...getInputProps()}
                                type='search'
                                error={!!getError(errors, name)}
                                classes={{ root: classes.input, error: classes.inputError }}
                                value={inputValue}
                                onChange={(e) => {
                                    setInputValue(e.target.value);
                                    onChange(e.target.value);
                                    if (!e.target.value.length) onCustomersChange?.();
                                }}
                                placeholder={placeholder}
                                /* @ts-ignore */
                                InputProps={{
                                    endAdornment: loading && (
                                        <InputAdornment position='end'>
                                            <CircularProgress size={20} />
                                        </InputAdornment>
                                    ),
                                }}
                                inputProps={{ autoComplete: 'off' }}
                            />
                            {!!(getError(errors, name)?.message ?? helperTextIsValidPhoneNumber ?? helperText) && (
                                <FormHelperText classes={{ root: classes.helperText }}>{getError(errors, name)?.message ?? helperTextIsValidPhoneNumber ?? helperText}</FormHelperText>
                            )}
                        </div>
                        {groupedOptions.length > 0 ? (
                            <ul className={classes.listbox} {...getListboxProps()}>
                                {groupedOptions.map((option: RestaurantCustomerVm, index: number) => (
                                    <li {...getOptionProps({ option, index })} onClick={() => handleItem(option)}>
                                        <div className={classes.itemContainer}>
                                            <div className={classes.mobileNumberContainer}>
                                                <Typography className={classes.itemText}>{option.name}</Typography>
                                                <Typography className={classes.itemText}>{option.mobileNumber}</Typography>
                                            </div>
                                        </div>
                                    </li>
                                ))}
                            </ul>
                        ) : null}
                    </div>
                );
            }}
            control={control}
            defaultValue={''}
            name={name}
            rules={{
                required: {
                    value: (required as any)?.value ?? required,
                    message: (required as any)?.message ?? translate('This field is required'),
                },
                validate: {
                    phoneNumber: (value) => (isPossiblePhoneNumber(value, country) ? true : translate('are you sure this is a correct phone number?')),
                },
            }}
        />
    );
}

const useStyles = makeStyles((theme) => ({
    label: {
        fontFamily: theme.typography.regular,
        color: theme.palette.secondary.contrastText,
        fontSize: 12,
        marginBottom: 5,
        [theme.breakpoints.up('sm')]: {
            fontSize: 14,
        },
    },
    labelError: {
        color: 'red',
    },
    input: {
        width: '100%',
        backgroundColor: theme.palette.secondary.light,
        borderRadius: 5,
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
        fontFamily: theme.typography.regular,
        border: `1px solid ${theme.palette.secondary.dark}`,
        [theme.breakpoints.down('sm')]: {
            width: '100%',
        },
    },
    inputError: {
        border: '2px solid red',
    },
    helperText: {
        fontFamily: theme.typography.light,
        color: 'red',
    },
    listbox: {
        width: 250,
        margin: 0,
        zIndex: 3,
        position: 'absolute',
        padding: '8px 0',
        listStyle: 'none',
        backgroundColor: theme.palette.background.paper,
        overflow: 'auto',
        maxHeight: '40vh',
        borderRadius: 8,
        border: 'none',
        boxShadow: '4px 4px 7px 0px rgba(0, 0, 0, 0.25)',
        '& li[data-focus="true"]': {
            backgroundColor: '#f2f2f2',
            color: `${theme.palette.gray.one}`,
            cursor: 'pointer',
        },
        '& li:active': {
            backgroundColor: '#2977f5',
            color: 'white',
        },
    },
    itemContainer: {
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        minHeight: 60,
        padding: '8px 12px',
    },
    mobileNumberContainer: {
        display: 'flex',
        flexDirection: 'row',
        width: '100%',
        alignItems: 'center',
        justifyContent: 'space-between',
    },
    infoContainer: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
    },
    numberOfOrders: {
        color: theme.palette.primary.main,
        fontSize: 12,
        marginLeft: 5,
        fontFamily: theme.typography.regular,
    },
    itemText: {
        fontFamily: theme.typography.regular,
        fontSize: 15,
    },
}));

type Props = {
    name: string;
    label?: string;
    placeholder?: string;
    helperText?: string;
    defaultValue?: string;
    required?: boolean;
    onSelect: any;
    onCustomersChange?: any;
};
