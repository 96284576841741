import { makeStyles } from '@material-ui/core';
import * as React from 'react';
import { SecuredAndSubscribedPage } from 'src/components/page/SecuredAndSubscribedPage';
import { Tabs } from 'src/components/Tabs';
import { RolePermissions } from 'src/constants/RolePermission';
import { translate } from 'src/i18n/translate';
import { RolesTable } from 'src/scenes/letseatmanager/userManagement/RolesTable';
import { UsersTable } from 'src/scenes/letseatmanager/userManagement/UsersTable';

export function UserManagementPage(): React.ReactElement {
    const classes = useStyles();

    const tabs = [
        {
            label: translate('Users'),
            value: '1',
        },
        {
            label: translate('Roles'),
            value: '2',
        },
    ];

    return (
        <SecuredAndSubscribedPage rolePermission={RolePermissions.USER_MANAGEMENT_PAGE} className={classes.container} title={translate('User Management')}>
            <Tabs tabs={tabs}>
                <UsersTable />
                <RolesTable />
            </Tabs>
        </SecuredAndSubscribedPage>
    );
}

const useStyles = makeStyles((theme) => ({
    container: {
        position: 'relative',
    },
}));
