import { letseatadminApiMethod } from 'src/api/letseatadminApi';
import type { ApiSauceResponse } from 'src/api/types/ApiSauceResponse';
import type { DeliveryCategory } from 'src/types/Id';

export async function getDeliveryCategoryOptionsApi(): ApiSauceResponse<GetDeliveryCategoryOptionsApiResponse> {
    return letseatadminApiMethod('options/getDeliveryCategoryOptionsApi');
}

export type GetDeliveryCategoryOptionsApiResponse = Array<DeliveryCategoryVm>;

export type DeliveryCategoryVm = {
    label: DeliveryCategory;
    value: DeliveryCategory;
};
