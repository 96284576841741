export const SystemUserTypes = {
    ADMIN: 'ADMIN',
    SUPPORT: 'SUPPORT',
    OPERATIONS: 'OPERATIONS',
    VIEW: 'VIEW',
    KITCHENS: 'KITCHENS',
    RESTAURANT: 'RESTAURANT',
    BRAND: 'BRAND',
    BUSINESS: 'BUSINESS',
    CASHIER: 'CASHIER',
    WAITER: 'WAITER',
    KIOSK: 'KIOSK',
    KITCHEN_DISPLAY_SCREEN: 'KITCHEN_DISPLAY_SCREEN',
    CUSTOMER: 'CUSTOMER',
    DRIVER: 'DRIVER',
    CRON_JOB: 'CRON_JOB',
    PIDEDIRECTO_API: 'PIDEDIRECTO_API',
    PIDEDIRECTO_ANALYTICS_SERVER: 'PIDEDIRECTO_ANALYTICS_SERVER',
    RAPPI_CARGO: 'RAPPI_CARGO',
    RAPPI: 'RAPPI',
    DIDI_FOOD: 'DIDI_FOOD',
    UBER_EATS: 'UBER_EATS',
    UBER_DAAS: 'UBER_DAAS',
    WANSOFT: 'WANSOFT',
    MERCADO_PAGO: 'MERCADO_PAGO',
    CONEKTA: 'CONEKTA',
    QPAY: 'QPAY',
    SALESFORCE: 'SALESFORCE',
    ZENDESK: 'ZENDESK',
    VOICES: 'VOICES',
    DEVELOPER: 'DEVELOPER',
    UNKNOWN: 'UNKNOWN',
} as const;

export type SystemUserType = (typeof SystemUserTypes)[keyof typeof SystemUserTypes];
