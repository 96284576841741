import { Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Button, Dialog, DialogActions, DialogContent } from '@pidedirecto/ui';
import * as React from 'react';
import { useState } from 'react';
import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { createRestaurantTaxApi } from 'src/api/letseatmanager/taxes/createRestaurantTaxApi';
import { Form } from 'src/components/form/Form';
import { FormCheckbox } from 'src/components/form/FormCheckbox';
import { FormOrderTypesAutocomplete } from 'src/components/form/FormOrderTypesAutocomplete';
import { FormRestaurantTaxPercentSelect } from 'src/components/form/FormRestaurantTaxPercentSelect';
import { FormTaxTypeSelect } from 'src/components/form/FormTaxTypeSelect';
import { FormTextField } from 'src/components/form/FormTextField';
import { MexicanTaxes } from 'src/constants/MexicanTaxes';
import { translate } from 'src/i18n/translate';
import { useNotification } from 'src/services/notification/useNotification';
import { alertKnownErrorOrSomethingWentWrong } from 'src/utils/alert/alertKnownErrorOrSomethingWentWrong';
import { isMexico } from 'src/utils/country/isMexico';
import { useSelector } from 'src/utils/react/useSelector';

export function CreateRestaurantTaxDialog({ open, onSuccess, onClose }: Props): React.ReactElement {
    const classes = useStyles();
    const form = useForm();
    const notification = useNotification();

    const [loading, setLoading] = useState(false);

    const restaurantId = useSelector((state) => state.app.restaurantId);
    const restaurantCountry = useSelector((state) => state.app.restaurant?.country);

    const taxRate = form.watch('percentage');
    const taxType = form.watch('tax');

    useEffect(() => {
        if (taxType === MexicanTaxes.IEPS) {
            form.setValue('percentage', 0.08);
        }
    }, [taxType]);

    const onSubmit = async (form: any) => {
        setLoading(true);
        const response = await createRestaurantTaxApi({
            restaurantId,
            name: form.name,
            description: form.description,
            taxType: form.tax,
            taxRate: parseFloat(form.percentage),
            orderTypes: form.orderTypes,
            addTaxToCurrentMenuItems: form.applyToAllProducts,
        });
        if (!response.ok) {
            setLoading(false);
            alertKnownErrorOrSomethingWentWrong(response);
            return;
        }
        setLoading(false);
        notification({ message: translate('The tax was created successfully') });
        onSuccess?.();
        onClose();
    };

    const getHelperText = () => {
        if (!isMexico(restaurantCountry)) return;

        if (taxType === MexicanTaxes.IVA && taxRate === 0.08) {
            return (
                translate('The 8% VAT is only applicable for businesses in the border area. Make sure you are registered in this way with SAT to avoid billing errors') +
                translate('Food in general, non-basic')
            );
        }

        if (taxRate === 0.8) {
            return translate('Food in general, non-basic');
        }
        if (taxRate === 0.265) {
            return 'Beverages with alcohol content up to 14° G.L.';
        }
        if (taxRate === 0.3) {
            return translate('Beverages with alcohol content between 14° and 20° G.L.');
        }
        if (taxRate === 0.53) {
            return translate('Beverages with alcohol content over 20° G.L.');
        }
        if (taxRate === 1.6) {
            return translate('Cigars and cigars');
        }
        return '';
    };

    return (
        <Dialog open={open} onClose={onClose} title={translate('Create tax')}>
            <Form form={form} onSubmit={onSubmit}>
                <DialogContent>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <FormTextField name='name' label={translate('Name (alias)')} required />
                        </Grid>
                        <Grid item xs={12}>
                            <FormTaxTypeSelect name='tax' label={translate('Tax type')} required />
                        </Grid>
                        <Grid item xs={12}>
                            <FormRestaurantTaxPercentSelect name='percentage' label={translate('Percentage')} required taxType={form.watch('tax')} helperText={getHelperText()} />
                        </Grid>

                        <Grid item xs={12}>
                            <FormOrderTypesAutocomplete name='orderTypes' label={translate('Order types')} required />
                        </Grid>
                        <Grid item xs={12}>
                            <FormTextField name='description' label={translate('Description')} />
                        </Grid>
                        <Grid item xs={12}>
                            <FormCheckbox name='applyToAllProducts' label={translate('Apply to all products')} />
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button variant='secondary' onClick={onClose}>
                        {translate('Cancel')}
                    </Button>
                    <Button type='submit' disabled={loading}>
                        {loading ? translate('Creating...') : translate('Create')}
                    </Button>
                </DialogActions>
            </Form>
        </Dialog>
    );
}

const useStyles = makeStyles((theme) => ({
    form: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        width: '480px',
    },
    title: {
        textAlign: 'center',
        fontSize: 41,
        color: theme.palette.secondary.contrastText,
        paddingTop: theme.spacing(4),
        paddingBottom: theme.spacing(3),
        '& h2': {
            fontFamily: theme.typography.medium,
        },
    },
    dialogContent: {
        width: '70%',
        overflowY: 'hidden',
        [theme.breakpoints.down('sm')]: {
            width: '100%',
        },
    },

    dialogActions: {
        display: 'flex',
        gap: 12,
        marginTop: 24,
        width: '100%',
        justifyContent: 'flex-end',
        alignItems: 'flex-end',
    },
}));

type Props = {
    open: boolean;
    onClose: any;
    onSuccess?: any;
};
