import { makeStyles } from '@material-ui/core';
import { useEffect, useState } from 'react';
import * as React from 'react';
import { SECONDS } from 'src/constants/TimeUnit';
import { translate } from 'src/i18n/translate';
import { ConfirmCancelPaymentTerminalPaymentDialog } from 'src/scenes/letseatmanager/paymentTerminalPayment/ConfirmCancelPaymentTerminalPaymentDialog';
import { classNames } from 'src/utils/react/classNames';
import { useSelector } from 'src/utils/react/useSelector';

export function PaymentTerminalPaymentProgressLightBox({ onCancelPayment }: Props): React.ReactElement {
    const classes = useStyles();

    const [isTimeOutExceeded, setIsTimeOutExceeded] = useState(false);
    const [openConfirmCancelPayment, setOpenConfirmCancelPayment] = useState(false);

    const paying = useSelector((state) => state.pos.paying);

    const showPayingLightBox = paying;

    useEffect(() => {
        const paymentTimeout = setPaymentTimeOut();

        return () => clearOnUnmount(paymentTimeout);
    }, [paying]);

    useEffect(() => {
        if (!paying) {
            setIsTimeOutExceeded(false);
        }
    }, [paying]);

    const clearOnUnmount = (paymentTimeout: NodeJS.Timeout | undefined) => {
        setIsTimeOutExceeded(false);
        clearTimeout(paymentTimeout);
    };

    const setPaymentTimeOut = () => {
        if (!paying) return;

        return setTimeout(() => {
            if (!paying) return;

            setIsTimeOutExceeded(true);
        }, 15 * SECONDS);
    };

    return (
        <>
            <ConfirmCancelPaymentTerminalPaymentDialog open={openConfirmCancelPayment} onSuccess={onCancelPayment} onClose={() => setOpenConfirmCancelPayment(false)} />
            <section className={classNames(classes.lightBox, showPayingLightBox ? classes.showPayingLightBox : classes.hidePayingLightBox)}>
                {showPayingLightBox && (
                    <>
                        <h1 className={classes.payingMessage}>{translate('Paying in terminal ...')}</h1>
                        {isTimeOutExceeded && (
                            <div className={classes.troubleMessage}>
                                {translate('Problems with terminal?')}
                                <span className={classes.link} onClick={() => setOpenConfirmCancelPayment(true)}>
                                    {translate('Cancel payment')}
                                </span>
                            </div>
                        )}
                    </>
                )}
            </section>
        </>
    );
}

const useStyles = makeStyles((theme) => ({
    payingMessage: {
        fontFamily: theme.typography.semiBold,
        fontSize: 28,
        color: '#fff',
        textAlign: 'center',
        [theme.breakpoints.up('sm')]: {
            fontSize: 32,
        },
    },

    link: {
        color: '#e85757bd',
        textDecoration: 'underline',
        cursor: 'pointer',
        marginLeft: 10,
    },
    troubleMessage: {
        fontFamily: theme.typography.regular,
        marginTop: 20,
        fontSize: 14,
        color: '#2E3748',
        [theme.breakpoints.up('sm')]: {
            fontSize: 18,
        },
    },
    lightBox: {
        display: 'flex',
        flexDirection: 'column',
        position: 'fixed',
        width: '100%',
        height: '100%',
        background: 'rgba(0, 0, 0, 0.4)',
        top: 0,
        left: 0,
        alignItems: 'center',
        justifyContent: 'center',
        zIndex: 150,
        transition: '.4s transform',
        webkitTransition: ' .4s transform',
        msTransition: '.4s transform',
    },
    hidePayingLightBox: {
        transform: 'translate(100%)',
        webkitTransform: 'translate(100%)',
        msTransform: 'translate(100%)',
    },
    showPayingLightBox: {
        transform: 'translate(0%)',
        webkitTransform: 'translate(0%)',
        msTransform: 'translate(0%)',
    },
}));

type Props = {
    onCancelPayment: any;
};
