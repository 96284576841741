import { makeStyles } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Grid from '@material-ui/core/Grid';
import * as React from 'react';
import { useState } from 'react';
import { letseatmanagerApiDeprecated } from 'src/api/letseatmanagerApiDeprecated';
import { app2 } from 'src/app2';
import { CustomerOrderRejectReasons } from 'src/components/dialog/order/rejectOrderDialog/CustomerOrderRejectReasons';
import { EstablishmentOrderRejectReasons } from 'src/components/dialog/order/rejectOrderDialog/EstablishmentOrderRejectReasons';
import { OperationOrderRejectReasons } from 'src/components/dialog/order/rejectOrderDialog/OperationOrderRejectReasons';
import { OtherOrderRejectReasons } from 'src/components/dialog/order/rejectOrderDialog/OtherOrderRejectReasons';
import { TechOrderRejectReasons } from 'src/components/dialog/order/rejectOrderDialog/TechOrderRejectReasons';
import { translate } from 'src/i18n/translate';
import { OrderVm } from 'src/types/OrderVm';
import { alertKnownErrorOrSomethingWentWrong } from 'src/utils/alert/alertKnownErrorOrSomethingWentWrong';
import { useAction } from 'src/utils/react/useAction';
import { useSelector } from 'src/utils/react/useSelector';

export default function RejectOrderDialog(): React.ReactElement {
    const classes = useStyles();

    const [loading, setLoading] = useState(false);
    const open = useSelector((state) => state.app2.rejectOrderDialog.open);
    const orderId = useSelector((state) => state.app2.rejectOrderDialog.orderId);
    const onReject = useSelector((state) => state.app2.rejectOrderDialog.onReject);
    const refundSupplies = useSelector((state) => state.app2.rejectOrderDialog.refundSupplies);

    const close = useAction(app2.actions.closeRejectOrderDialog);

    const onReasonClick = async (reason: any) => {
        if (loading) return;

        setLoading(true);
        const response = await letseatmanagerApiDeprecated.currentOrders.rejectOrder({ orderId, orderRejectReason: reason, refundSupplies });
        setLoading(false);

        if (!response.ok) {
            alertKnownErrorOrSomethingWentWrong(response);
            return;
        }
        close();

        const order: OrderVm = response.data;

        if (onReject) {
            onReject(order);
        }
    };

    return (
        <Dialog aria-labelledby='reject-order-dialog-title' scroll='paper' open={open} onClose={close}>
            <DialogTitle id='reject-order-dialog-title' classes={{ root: classes.dialogTitle }}>
                {translate('Select cause of the rejection')}
            </DialogTitle>
            <DialogContent>
                <Grid container spacing={2} direction='column' justify='flex-start'>
                    <EstablishmentOrderRejectReasons disabled={loading} onReasonClick={onReasonClick} />
                    <CustomerOrderRejectReasons disabled={loading} onReasonClick={onReasonClick} />
                    <OperationOrderRejectReasons disabled={loading} onReasonClick={onReasonClick} />
                    <TechOrderRejectReasons disabled={loading} onReasonClick={onReasonClick} />
                    <OtherOrderRejectReasons disabled={loading} onReasonClick={onReasonClick} />
                </Grid>
            </DialogContent>
        </Dialog>
    );
}

const useStyles = makeStyles((theme) => ({
    dialogTitle: {
        textAlign: 'center',
        fontSize: 46,
        color: theme.palette.secondary.contrastText,
        paddingTop: theme.spacing(4),
        paddingBottom: theme.spacing(3),
        '& h2': {
            fontFamily: theme.typography.medium,
        },
    },
    title: {
        fontFamily: theme.typography.medium,
        color: theme.palette.secondary.contrastText,
        fontSize: 25,
        marginRight: 10,
        [theme.breakpoints.down('sm')]: {
            display: 'none',
        },
    },
    text: {
        fontFamily: theme.typography.semiBold,
        fontSize: 17,
        textTransform: 'none',
    },
    horizontal_vertical_center: {
        textAlign: 'center',
        margin: 'auto',
        display: 'flex',
        alignItems: 'center',
    },
}));
