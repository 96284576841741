import { letseatmanagerApiMethod } from 'src/api/letseatmanagerApi';
import type { ApiSauceResponse } from 'src/api/types/ApiSauceResponse';
import type { RestaurantId } from 'src/types/Id';

export async function openDidiFoodStoreApi(request: OpenDidiFoodStoreApiRequest): ApiSauceResponse<any> {
    return letseatmanagerApiMethod('integration/openDidiFoodStoreApi', request);
}

type OpenDidiFoodStoreApiRequest = {
    restaurantId: RestaurantId;
};
