import { FormSelect } from '@pidedirecto/ui/form';
import { useLoadApi } from '@pidedirecto/ui/hooks';
import * as React from 'react';
import { findCustomDiscountCommentsApi } from 'src/api/letseatmanager/pos/findCustomDiscountCommentsApi';
import { translate } from 'src/i18n/translate';
import { useSelector } from 'src/utils/react/useSelector';
import { translateCustomDiscountComment } from 'src/utils/translate/translateCustomDiscountComment';

export function FormCustomDiscountCommentSelect({ name, label, placeholder, helperText, defaultValue, disabled, required }: Props): React.ReactElement {
    const restaurantId = useSelector((state) => state.app.restaurantId);

    const [loading, customDiscountComments] = useLoadApi(findCustomDiscountCommentsApi, { restaurantId }, { initialValue: [] });

    const options = customDiscountComments?.map((commentOption: any) => ({
        label: translateCustomDiscountComment(commentOption.comment),
        value: commentOption.comment,
    }));

    return (
        <FormSelect
            name={name}
            label={label}
            placeholder={placeholder}
            helperText={helperText}
            defaultValue={defaultValue}
            options={options}
            disabled={disabled || loading}
            required={{
                value: !!required,
                message: translate('This field is required'),
            }}
        />
    );
}

type Props = {
    name: string;
    label?: string;
    placeholder?: string;
    helperText?: string;
    defaultValue?: string | null | undefined;
    disabled?: boolean;
    required?: boolean;
};
