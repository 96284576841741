import { makeStyles } from '@material-ui/core/styles';
import { BigNumber } from 'bignumber.js';
import * as React from 'react';
import { BarChartCanvas } from 'src/components/charts/BarChartCanvas';
import { translate } from 'src/i18n/translate';
import { GraphEmptyState } from 'src/scenes/letseatmanager/posReports/GraphEmptyState';
import { useFormatAsRestaurantCurrencyNumber } from 'src/services/restaurant/useFormatAsRestaurantCurrencyNumber';
import type { ManagerUserSalesReportVm } from 'src/types/ManagerUserSalesReportVm';
import { removeDuplicates } from 'src/utils/array/removeDuplicates';
import { sum } from 'src/utils/reduce/sum';

export function ManagerUserSalesByPaymentMethodReport({ managerUserSalesReport }: Props): React.ReactElement {
    const classes = useStyles();
    const formatAsCurrencyNumber = useFormatAsRestaurantCurrencyNumber();

    const getManagerUserSalesByPaymentMethodBarChartData = () => {
        const managerUsers = getManagerUsers();
        return managerUsers.map((managerUser) => ({
            id: managerUser,
            ...getManagerUserSalesByManagerUser(managerUser),
        }));
    };

    const getManagerUsers = () => {
        const managerUsers = managerUserSalesReport.managerUserOrdersByPaymentMethod.map((managerUserOrdersByPaymentMethod) => managerUserOrdersByPaymentMethod.servedBy);
        return removeDuplicates(managerUsers) ?? [];
    };

    const getManagerUserSalesByManagerUser = (managerUser: string) => {
        const managerUserSalesByPaymentMethod: Record<string, any> = {};

        const managerUsersSales = managerUserSalesReport.managerUserOrdersByPaymentMethod.filter((managerUserOrdersByPaymentMethod) => managerUserOrdersByPaymentMethod.servedBy === managerUser);
        managerUsersSales.forEach((managerUserSales) => {
            managerUserSalesByPaymentMethod[managerUserSales.paymentMethod as string] = BigNumber(managerUserSales.total).toString();
        });

        return managerUserSalesByPaymentMethod;
    };

    const getPaymentMethods = () => {
        const paymentMethods = managerUserSalesReport.managerUserOrdersByPaymentMethod.map((managerUserOrdersByPaymentMethod) => managerUserOrdersByPaymentMethod.paymentMethod);
        return removeDuplicates(paymentMethods) ?? [];
    };

    const isEmptyReport = () => {
        const managerUserSales = managerUserSalesReport.managerUserOrdersByPaymentMethod.map((managerUserOrdersByPaymentMethod) => managerUserOrdersByPaymentMethod.total);
        const totalSales = managerUserSales.reduce(sum, BigNumber(0));
        return totalSales.isZero();
    };

    if (isEmptyReport()) {
        return <GraphEmptyState title={translate('Sales by waiter')} />;
    }

    return (
        <article className={classes.container}>
            <h2 className={classes.title}>{translate('Sales by waiter')}</h2>
            <BarChartCanvas
                classes={{ container: classes.barCharContainer }}
                data={getManagerUserSalesByPaymentMethodBarChartData()}
                keys={getPaymentMethods()}
                tooltip={({ id, value }: any) => {
                    return (
                        <div className={classes.tooltip}>
                            <span className={classes.tooltipTitle}>{translate(id)}</span>
                            <span className={classes.tooltipText}>{translate('Total: @total', { total: formatAsCurrencyNumber(value) })}</span>
                        </div>
                    );
                }}
            />
        </article>
    );
}

const useStyles = makeStyles((theme) => ({
    container: {
        height: 'fit-content',
        display: 'flex',
        flexDirection: 'column',
        flex: '1 1 100%',
    },
    barCharContainer: {
        overflowX: 'scroll',
        '&::-webkit-scrollbar': {
            display: 'none',
        },
    },
    title: {
        fontFamily: theme.typography.medium,
        color: theme.palette.text.primary,
    },
    tooltip: {
        padding: 10,
        borderRadius: 3,
        backgroundColor: 'white',
        boxShadow: '1px 1px 1px rgb(0,0,0,0.2)',
        display: 'flex',
        flexDirection: 'column',
    },
    tooltipTitle: {
        fontFamily: theme.typography.bold,
    },
    tooltipText: {
        fontFamily: theme.typography.regular,
    },
}));

type Props = {
    managerUserSalesReport: ManagerUserSalesReportVm;
};
