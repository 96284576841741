import { letseatadminApiMethod } from 'src/api/letseatadminApi';
import type { ApiSauceResponse } from 'src/api/types/ApiSauceResponse';
import type { QuickActionId } from 'src/types/Id';

export async function removeQuickActionApi(request: RemoveQuickActionApiRequest): ApiSauceResponse<void> {
    return letseatadminApiMethod('quickAction/removeQuickActionApi', request);
}

export type RemoveQuickActionApiRequest = {
    quickActionId: QuickActionId;
};
