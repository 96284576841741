import Grid from '@material-ui/core/Grid';
import { withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import FavoriteIcon from '@material-ui/icons/Favorite';
import Rating from '@material-ui/lab/Rating';
import * as React from 'react';
import type { SurveysVm } from 'src/api/letseatadmin/survey/getSurveysApi';
import { NegativeSurveyAnswers } from 'src/constants/NegativeSurveyAnswer';
import { PositiveSurveyAnswers } from 'src/constants/PositiveSurveyAnswer';
import { translateSurveyAnswer } from 'src/utils/translate/translateSurveyAnswer';
import 'src/scenes/letseatadmin/surveys/RatingDetails.css';

type Props = {
    vm: SurveysVm;
};

const HeartRating = withStyles({
    iconFilled: {
        color: '#ff6d75',
    },
    iconHover: {
        color: '#ff3d47',
    },
})(Rating);

export default function RatingDetails({ vm }: Props): React.ReactElement {
    return (
        <Grid container spacing={3}>
            <Grid item xs={4}>
                <div className='rating-details'>
                    <HeartRating
                        name='customized-color'
                        value={vm.averageRating}
                        getLabelText={(value) => `${value} Heart${value !== 1 ? 's' : ''}`}
                        precision={0.01}
                        readOnly={true}
                        icon={<FavoriteIcon fontSize='inherit' />}
                    />
                    <p>
                        {vm.averageRating} average based on {vm.withRating} reviews ({vm.dismissed} of {vm.withRating} dismissed)
                    </p>
                    {/*<hr style="border:3px solid #f1f1f1" />*/}

                    <table>
                        <tbody>
                            <tr>
                                <td className='side'>
                                    <div>5 hearts</div>
                                </td>
                                <td className='middle'>
                                    <div className='bar-container'>
                                        <div className='bar-5' style={{ width: `${(vm.rating['5'] / vm.withRating) * 100}%` }} />
                                    </div>
                                </td>
                                <td className='side right'>
                                    <div>{vm.rating['5']}</div>
                                </td>
                            </tr>
                            <tr>
                                <td className='side'>
                                    <div>4 hearts</div>
                                </td>
                                <td className='middle'>
                                    <div className='bar-container'>
                                        <div className='bar-4' style={{ width: `${(vm.rating['4'] / vm.withRating) * 100}%` }} />
                                    </div>
                                </td>
                                <td className='side right'>
                                    <div>{vm.rating['4']}</div>
                                </td>
                            </tr>
                            <tr>
                                <td className='side'>
                                    <div>3 hearts</div>
                                </td>
                                <td className='middle'>
                                    <div className='bar-container'>
                                        <div className='bar-3' style={{ width: `${(vm.rating['3'] / vm.withRating) * 100}%` }} />
                                    </div>
                                </td>
                                <td className='side right'>
                                    <div>{vm.rating['3']}</div>
                                </td>
                            </tr>
                            <tr>
                                <td className='side'>
                                    <div>2 hearts</div>
                                </td>
                                <td className='middle'>
                                    <div className='bar-container'>
                                        <div className='bar-2' style={{ width: `${(vm.rating['2'] / vm.withRating) * 100}%` }} />
                                    </div>
                                </td>
                                <td className='side right'>
                                    <div>{vm.rating['2']}</div>
                                </td>
                            </tr>
                            <tr>
                                <td className='side'>
                                    <div>1 heart</div>
                                </td>
                                <td className='middle'>
                                    <div className='bar-container'>
                                        <div className='bar-1' style={{ width: `${(vm.rating['1'] / vm.withRating) * 100}%` }} />
                                    </div>
                                </td>
                                <td className='side right'>
                                    <div>{vm.rating['1']}</div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </Grid>
            <Grid item xs={4}>
                <Grid container direction='row' justify='flex-start' alignItems='flex-end'>
                    <Table size='small' aria-label='a dense table'>
                        <TableHead>
                            <TableRow>
                                <TableCell>Answer (Good Experience)</TableCell>
                                <TableCell align='right'>Answers</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {vm.surveyAnswers &&
                                Object.entries(vm.surveyAnswers)
                                    .filter(([surveyAnswer, answers]: [any, number]) => Object.values(PositiveSurveyAnswers).includes(surveyAnswer))
                                    .map(([surveyAnswer, count]: [any, any]) => (
                                        <TableRow key={surveyAnswer}>
                                            <TableCell component='th' scope='row'>
                                                {translateSurveyAnswer(surveyAnswer as any)}
                                            </TableCell>
                                            <TableCell align='right'>{count}</TableCell>
                                        </TableRow>
                                    ))}
                        </TableBody>
                    </Table>
                    <Table size='small' aria-label='a dense table'>
                        <TableHead>
                            <TableRow>
                                <TableCell>Answer (Bad Experience)</TableCell>
                                <TableCell align='right'>Answers</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {vm.surveyAnswers &&
                                Object.entries(vm.surveyAnswers)
                                    .filter(([surveyAnswer, answers]: [any, number]) => Object.values(NegativeSurveyAnswers).includes(surveyAnswer))
                                    .map(([surveyAnswer, count]: [any, any]) => (
                                        <TableRow key={surveyAnswer}>
                                            <TableCell component='th' scope='row'>
                                                {translateSurveyAnswer(surveyAnswer as any)}
                                            </TableCell>
                                            <TableCell align='right'>{count}</TableCell>
                                        </TableRow>
                                    ))}
                        </TableBody>
                    </Table>
                </Grid>
            </Grid>
            <Grid item xs={4}>
                <Grid container direction='row' justify='flex-start' alignItems='flex-end'>
                    <Table size='small' aria-label='a dense table'>
                        <TableHead>
                            <TableRow>
                                <TableCell>Other Answers (Good Experience)</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {vm.otherPositiveAnswers &&
                                vm.otherPositiveAnswers.map((otherAnswer) => (
                                    <TableRow key={otherAnswer}>
                                        <TableCell component='th' scope='row'>
                                            {otherAnswer}
                                        </TableCell>
                                    </TableRow>
                                ))}
                        </TableBody>
                    </Table>
                    <Table size='small' aria-label='a dense table'>
                        <TableHead>
                            <TableRow>
                                <TableCell>Other Answers (Bad Experience)</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {vm.otherNegativeAnswers &&
                                vm.otherNegativeAnswers.map((otherAnswer) => (
                                    <TableRow key={otherAnswer}>
                                        <TableCell component='th' scope='row'>
                                            {otherAnswer}
                                        </TableCell>
                                    </TableRow>
                                ))}
                        </TableBody>
                    </Table>
                </Grid>
            </Grid>
        </Grid>
    );
}
