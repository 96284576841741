import { IconButton, makeStyles } from '@material-ui/core';
import { BigNumber } from 'bignumber.js';
import * as React from 'react';
import { useState } from 'react';
import { PieChartCanvas } from 'src/components/charts/PieChartCanvas';
import { Text } from 'src/components/Text';
import { ChartDonutFilledIcon } from 'src/icons/ChartDonutFilledIcon';
import { ListIcon } from 'src/icons/ListIcon';
import { useFormatAsRestaurantCurrencyNumber } from 'src/services/restaurant/useFormatAsRestaurantCurrencyNumber';

export function PosBusinessKpisSection({ summary, title }: Props): React.ReactElement | null {
    const classes = useStyles();
    const formatAsCurrencyNumber = useFormatAsRestaurantCurrencyNumber();

    const [showGraph, setShowGraph] = useState(false);

    if (!summary) return null;

    const colors = [
        '#2476C2',
        '#20B2AA',
        '#F1948A',
        '#FFD700',
        '#53C7D7',
        '#7B68EE',
        '#FF8C00',
        '#40E0D0',
        '#FFA07A',
        '#9370DB',
        '#1E90FF',
        '#FFB6C1',
        '#0DFFD3',
        '#A0D8F8',
        '#A7A0F8',
        '#6256E9',
        '#00FF7F',
        '#8A2BE2',
        '#5DE3BC',
        '#24A8C2',
        '#13647D',
    ];

    const getPosBusinessKpiReportPieChartData = () => {
        return summary.map((kpi) => ({
            id: kpi.label,
            label: kpi.label,
            value: BigNumber(kpi.amount).toNumber(),
        }));
    };

    return (
        <div className={classes.container}>
            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <h2 className={classes.subtitle}>{title}</h2>
                <div>
                    <IconButton
                        style={{ width: 40, height: 40, padding: 8 }}
                        onClick={() => {
                            setShowGraph((prevShowGraph) => !prevShowGraph);
                        }}
                    >
                        {!showGraph ? <ChartDonutFilledIcon color={'#06B7A2'} /> : <ListIcon color={'#06B7A2'} />}
                    </IconButton>
                </div>
            </div>
            <div className={classes.divider}></div>
            <div className={classes.kpisContainer}>
                {!showGraph &&
                    summary.map((kpi) => {
                        return (
                            <div aria-label={'kpi-item'} key={kpi.label} className={classes.kpi}>
                                <div style={{ display: 'flex', gap: 6 }}>
                                    <Text className={classes.text}>{kpi.label}</Text>
                                    {!!kpi.percentage && <Text className={classes.text}>({kpi.percentage}%)</Text>}
                                </div>

                                <Text className={classes.text} variant='semiBold'>
                                    {formatAsCurrencyNumber(kpi.amount)}
                                </Text>
                            </div>
                        );
                    })}
                {showGraph && (
                    <div>
                        {summary.map((kpi, index) => (
                            <div aria-label={'kpi-item'} key={kpi.label} className={classes.kpiItems}>
                                <div className={classes.circle} style={{ backgroundColor: colors[index] }}></div>
                                <Text className={classes.text}>{kpi.label}</Text>
                            </div>
                        ))}
                        <div className={classes.chartContainer}>
                            <PieChartCanvas
                                data={getPosBusinessKpiReportPieChartData()}
                                margin={{
                                    top: 12,
                                    right: 12,
                                    bottom: 12,
                                    left: 12,
                                }}
                            />
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
}

const useStyles = makeStyles((theme) => ({
    container: {
        width: '100%',
        marginTop: 24,
    },
    kpisContainer: {
        display: 'flex',
        flexDirection: 'column',
        gap: 12,
    },
    subtitle: {
        fontFamily: theme.typography.semiBold,
        fontSize: 18,
        color: '#198074',
        margin: 0,
    },
    kpi: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
    },
    text: {
        fontSize: 16,
        color: '#232933',
    },
    divider: {
        height: 1,
        width: '100%',
        borderTop: '1px solid #d9d9d9',
        margin: '12px 0px',
    },
    chartContainer: {
        height: '400px',
        width: '100%',
    },
    circle: {
        height: 12,
        width: 12,
        borderRadius: '50%',
    },
    kpiItems: {
        display: 'flex',
        alignItems: 'center',
        gap: 12,
    },
}));

type Props = {
    title: string;
    summary?:
        | Array<{
              amount: string;
              label: string;
              percentage?: string;
          }>
        | undefined;
};
