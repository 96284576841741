import * as React from 'react';

export function TipIcon({ title }: Props): React.ReactElement {
    return (
        <svg width='40' height='41' viewBox='0 0 40 41' fill='none' xmlns='http://www.w3.org/2000/svg'>
            <title>{title}</title>
            <circle cx='20' cy='20.5' r='20' fill='#F9E1D0' />
            <path d='M10.7936 22.9921H13.872V28.5H10.7936C10.3545 28.5 10 28.1328 10 27.678V23.8142C10 23.3593 10.3545 22.9921 10.7936 22.9921Z' fill='#F9E1D0' />
            <path
                d='M26.0414 14.7174C25.8013 13.4547 24.7263 12.5 23.4364 12.5C21.9704 12.5 20.7812 13.7319 20.7812 15.2504C20.7812 16.7689 21.9704 18.0007 23.4364 18.0007C23.7017 18.0007 23.9556 17.9605 24.198 17.8847C24.166 17.7117 24.1477 17.5341 24.1477 17.3516C24.1477 16.1056 24.9482 15.0561 26.0437 14.7174H26.0414Z'
                fill='#F9E1D0'
            />
            <path
                d='M24.1957 17.887C24.4359 19.1497 25.5108 20.1044 26.8007 20.1044C28.2667 20.1044 29.456 18.8725 29.456 17.354C29.456 15.8355 28.2667 14.6036 26.8007 14.6036C26.5354 14.6036 26.2815 14.6439 26.0391 14.7197'
                fill='#F9E1D0'
            />
            <path d='M26.0414 14.7174C24.9459 15.0561 24.1454 16.1079 24.1454 17.3516C24.1454 17.5341 24.1637 17.7117 24.1957 17.8847' fill='#F9E1D0' />
            <path
                d='M13.872 28.5L19.7497 28.5L25.1586 27.1023L28.4245 25.6454C28.7859 25.4748 29.0809 25.129 29.273 24.7712C29.456 24.4301 29.5703 23.9705 29.3805 23.4209C29.1907 22.8713 28.8202 22.6013 28.4657 22.471C28.1112 22.3407 27.6995 22.3383 27.3245 22.4449L21.776 24.0084H18.9927H21.1906C22.19 24.0084 22.9973 23.1698 22.9973 22.137C22.9973 21.1017 22.1877 20.2655 21.1906 20.2655H17.7851C16.8726 20.2655 16.0012 20.6564 15.3746 21.3434L13.8697 22.9969'
                fill='#F9E1D0'
            />
            <path
                d='M13.872 28.5V22.9921H10.7936C10.3545 22.9921 10 23.3593 10 23.8142V27.678C10 28.1328 10.3545 28.5 10.7936 28.5H13.872ZM13.872 28.5L19.7497 28.5L25.1586 27.1023L28.4245 25.6454C28.7859 25.4748 29.0809 25.129 29.273 24.7712C29.456 24.4301 29.5703 23.9705 29.3805 23.4209C29.1907 22.8713 28.8202 22.6013 28.4657 22.471C28.1112 22.3407 27.6995 22.3383 27.3245 22.4449L21.776 24.0084H18.9927H21.1906C22.19 24.0084 22.9973 23.1698 22.9973 22.137C22.9973 21.1017 22.1877 20.2655 21.1906 20.2655H17.7851C16.8726 20.2655 16.0012 20.6564 15.3746 21.3434L13.8697 22.9969M26.0414 14.7174C25.8013 13.4547 24.7263 12.5 23.4364 12.5C21.9704 12.5 20.7812 13.7319 20.7812 15.2504C20.7812 16.7689 21.9704 18.0007 23.4364 18.0007C23.7017 18.0007 23.9556 17.9605 24.198 17.8847C24.166 17.7117 24.1477 17.5341 24.1477 17.3516C24.1477 16.1056 24.9482 15.0561 26.0437 14.7174H26.0414ZM26.0414 14.7174C24.9459 15.0561 24.1454 16.1079 24.1454 17.3516C24.1454 17.5341 24.1637 17.7117 24.1957 17.8847M24.1957 17.887C24.4359 19.1497 25.5108 20.1044 26.8007 20.1044C28.2667 20.1044 29.456 18.8725 29.456 17.354C29.456 15.8355 28.2667 14.6036 26.8007 14.6036C26.5354 14.6036 26.2815 14.6439 26.0391 14.7197'
                stroke='#FF5400'
                strokeWidth='1.5'
                strokeLinecap='round'
                strokeLinejoin='round'
            />
        </svg>
    );
}

type Props = {
    title?: string;
};
