import Grid from '@material-ui/core/Grid';
import * as React from 'react';
import { FormNumberField } from 'src/components/form/FormNumberField';
import { Cities } from 'src/constants/City';
import { CitiesNamesForSettings } from 'src/constants/CityNameForSetting';
import { translate } from 'src/i18n/translate';
import { useSelector } from 'src/utils/react/useSelector';

export function DeliveriesForBonusCommissionSettings(): React.ReactElement {
    const cities = useSelector((state) => state.app2.cities);
    const citiesToShow = cities ?? Object.keys(Cities);

    return (
        <>
            {citiesToShow.map((city) => (
                <Grid item xs={12} key={city}>
                    <FormNumberField
                        name={`deliveriesForBonusCommission${CitiesNamesForSettings[city]}`}
                        label={translate(`Deliveries necessary for bonus delivery cash commission in ${CitiesNamesForSettings[city]}`)}
                        required
                        min={0}
                    />
                </Grid>
            ))}
        </>
    );
}
