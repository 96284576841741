import { makeStyles } from '@material-ui/core';
import * as React from 'react';
import { useState } from 'react';
import { useLocation } from 'react-router-dom';
import { app2 } from 'src/app2';
import { Button } from 'src/components/Button';
import { InformationAlertDialog } from 'src/components/dialog/InformationAlertDialog';
import { SecuredContent } from 'src/components/SecuredContent';
import { Tooltip } from 'src/components/Tooltip';
import { RolePermissions } from 'src/constants/RolePermission';
import { RoutePaths } from 'src/constants/RoutePath';
import { SECONDS } from 'src/constants/TimeUnit';
import { translate } from 'src/i18n/translate';
import { classNames } from 'src/utils/react/classNames';
import { useAction } from 'src/utils/react/useAction';
import { useSelector } from 'src/utils/react/useSelector';
import { useIsSmallScreen } from 'src/utils/react/window/useIsSmallScreen';

// import { useIsSmallScreen } from 'src/utils/react/window/useIsSmallScreen';

/** @deprecated **/
export function DeprecatedAskForDriverButton(): React.ReactElement | null {
    const classes = useStyles();
    const location = useLocation();
    const isSmallScreen = useIsSmallScreen();

    const [informationAlertDialogState, setInformationAlertDialogState] = useState({ open: false });
    const [tooltipOpen, setTooltipOpen] = useState(false);

    const brandOpened = useSelector((state) => state.app.brandOpened);
    const kitchensUser = useSelector((state) => state.authentication.kitchensUser);
    const restaurant = useSelector((state) => state.app.restaurant);
    const restaurantLocation = useSelector((state) => state.app.restaurant.location);

    const openCreateManualOrderDeliveryDialog = useAction(app2.actions.openCreateManualOrderDeliveryDialog);

    const ongoingOrdersLocation = location.pathname === RoutePaths.ONGOING_ORDERS;
    const howDoesItWorkLocation = location.pathname === RoutePaths.HOW_DOES_IT_WORK;
    const showAskForDriverButton = !kitchensUser && restaurant?.manualOrdersEnabled && (ongoingOrdersLocation || howDoesItWorkLocation);

    const handleRequestDriver = () => {
        if (!restaurantLocation) return openInformationAlertDialog();
        if (brandOpened) {
            setTooltipOpen(true);
            setTimeout(() => setTooltipOpen(false), 5 * SECONDS);
            return;
        }
        openCreateManualOrderDeliveryDialog({});
    };

    const openInformationAlertDialog = () => setInformationAlertDialogState({ open: true });

    const closeInformationAlertDialog = () => setInformationAlertDialogState({ open: false });

    if (!showAskForDriverButton) return null;

    return (
        <>
            <InformationAlertDialog
                openInformationAlert={informationAlertDialogState.open}
                handleClose={closeInformationAlertDialog}
                title={translate('Location not found')}
                dialogContent={translate('Restaurant location not found, please verify that you have set your address correctly')}
            />
            <SecuredContent rolePermission={RolePermissions.ASK_DRIVER}>
                <Tooltip forceOpen={tooltipOpen} text={translate('It is not possible to ask for a driver as Brand. Select one location in the manager to take the order.')} position={'bottom'}>
                    <Button onClick={handleRequestDriver} data-testid='appBar.requestDriverButton' classes={{ button: classNames(classes.button, { [classes.mobileButton]: isSmallScreen }) }}>
                        {isSmallScreen ? '+' : translate('Request a Driver')}
                    </Button>
                </Tooltip>
            </SecuredContent>
        </>
    );
}

const useStyles = makeStyles((theme) => ({
    button: {
        height: 48.5,
        fontFamily: theme.typography.semiBold,
    },
    mobileButton: {
        width: 40,
        height: 40,
        fontSize: 25,
    },
}));
