import { makeStyles } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import * as React from 'react';
import { OrderRejectReasons } from 'src/constants/OrderRejectReason';
import { OrderRejectReasonCategories } from 'src/constants/OrderRejectReasonCategory';
import { UserType, UserTypes } from 'src/constants/UserType';
import { translate } from 'src/i18n/translate';
import { useSelector } from 'src/utils/react/useSelector';
import { isBrandUser } from 'src/utils/user/isBrandUser';
import { isBusinessUser } from 'src/utils/user/isBusinessUser';

export function WithOutAssignmentOrderRejectReasons({ onReasonClick, disabled }: Props): React.ReactElement {
    const classes = useStyles();

    const adminUser = useSelector((state) => state.authentication.adminUser);
    const supportUser = useSelector((state) => state.authentication.supportUser);
    const restaurantUser = useSelector((state) => state.authentication.restaurantUser);
    const userType = useSelector((state) => state.authentication.userType);

    const reasons = [
        { reason: OrderRejectReasons.LOW_SUPPLY, showTo: [UserTypes.ADMIN, UserTypes.SUPPORT] as Array<UserType> },
        { reason: OrderRejectReasons.HIGH_DEMAND, showTo: [UserTypes.ADMIN, UserTypes.SUPPORT] as Array<UserType> },
        { reason: OrderRejectReasons.SATURATION, showTo: [UserTypes.ADMIN, UserTypes.SUPPORT] as Array<UserType> },
        { reason: OrderRejectReasons.OTHER, showTo: [UserTypes.ADMIN, UserTypes.SUPPORT] as Array<UserType> },
    ];

    const orderReasonsFilteredByUserType = () => {
        if (adminUser) return reasons;
        else if (restaurantUser || isBrandUser(userType) || isBusinessUser(userType)) return reasons.filter((reason) => reason.showTo.includes(UserTypes.RESTAURANT));
        else if (supportUser) return reasons.filter((reason) => reason.showTo.includes(UserTypes.SUPPORT));
        return [];
    };

    return (
        <Grid container>
            <Grid className={classes.box_category} item alignContent='center' alignItems='center' xs={12}>
                <Typography className={classes.category_name} display='block' variant='h6' color='inherit' noWrap>
                    {translate(`OrderRejectReasonCategories.${OrderRejectReasonCategories.WITHOUTASSIGNMENT}`)}
                </Typography>
            </Grid>

            <Grid item xs={12}>
                {orderReasonsFilteredByUserType().map(({ reason }) => (
                    <Grid item key={reason}>
                        <Button onClick={() => onReasonClick(reason)} disabled={disabled} size='large' fullWidth={true}>
                            <span className={classes.text}>{translate(`OrderRejectReasons.${reason}`)}</span>
                        </Button>
                    </Grid>
                ))}
            </Grid>
        </Grid>
    );
}

const useStyles = makeStyles((theme) => ({
    text: {
        fontSize: 17,
        textTransform: 'none',
    },
    category_name: {
        fontSize: 24,
        fontWeight: 'bold',
    },
    horizontal_vertical_center: {
        textAlign: 'center',
        margin: 'auto',
        display: 'flex',
        alignItems: 'center',
    },
    box_category: {
        paddingTop: '1rem',
        borderTop: '1px solid #000;',
        textAlign: 'center',
        margin: 'auto',
    },
}));

type Props = {
    onReasonClick: any;
    disabled: boolean;
};
