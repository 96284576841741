import { letseatadminApiMethod } from 'src/api/letseatadminApi';
import type { ApiSauceResponse } from 'src/api/types/ApiSauceResponse';
import type { RestaurantId } from 'src/types/Id';
import type { ManagerUserVm } from 'src/types/ManagerUserVm';

export async function findKioskUserApi(request: FindKioskUserApiRequest): ApiSauceResponse<ManagerUserVm | undefined> {
    return letseatadminApiMethod('managerUser/findKioskUserApi', request);
}

export type FindKioskUserApiRequest = {
    restaurantId: RestaurantId;
};
