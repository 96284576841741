import { letseatmanagerApiMethod } from 'src/api/letseatmanagerApi';
import type { ApiSauceResponse } from 'src/api/types/ApiSauceResponse';
import type { RestaurantId } from 'src/types/Id';
import type { PrinterDeprecatedVm } from 'src/types/PrinterDeprecatedVm';

export async function findRestaurantPrintersApi(request: FindRestaurantPrintersApiRequest): ApiSauceResponse<PrintersVm> {
    return letseatmanagerApiMethod('printerDeprecated/findRestaurantPrintersApi', request);
}

export type PrintersVm = {
    printers: Array<PrinterDeprecatedVm>;
};

export type FindRestaurantPrintersApiRequest = {
    restaurantId: RestaurantId;
};
