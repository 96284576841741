import { makeStyles } from '@material-ui/core';
import { BasketIcon, CashIcon, FilledClockIcon } from '@pidedirecto/ui/icons';
import { formatAsCurrencyNumber } from '@pidedirecto/ui/utils';
import { BigNumber } from 'bignumber.js';
import * as React from 'react';
import { KpisByDeliveryTimeCategory } from 'src/api/letseatmanager/restaurantDashboard/getDeliveryRestaurantStatisticsApi';
import { translate } from 'src/i18n/translate';
import { ReportIndicator } from 'src/scenes/letseatmanager/restaurantDashboard/ReportIndicator';
import { classNames } from 'src/utils/react/classNames';

export function DeliveryByTimeCategoryKpis({ kpisByDeliveryTimeCategory }: Props): React.ReactElement {
    const classes = useStyles();

    return (
        <div className={classes.kpiSection}>
            <ReportIndicator
                label={translate('Orders')}
                value={kpisByDeliveryTimeCategory?.orders ?? '0'}
                Icon={
                    <div className={classNames(classes.iconContainer, classes.totalOrdersIcon)}>
                        <BasketIcon size={25} />
                    </div>
                }
            />
            <ReportIndicator
                label={translate('Average delivery time')}
                value={`${BigNumber(kpisByDeliveryTimeCategory?.averageTime).toFixed(0)} mins`}
                Icon={
                    <div className={classNames(classes.iconContainer, classes.timeIcon)}>
                        <FilledClockIcon size={20} />
                    </div>
                }
            />
            <ReportIndicator
                label={translate('Average delivery cost')}
                value={formatAsCurrencyNumber(BigNumber(kpisByDeliveryTimeCategory?.averageDeliveryCost ?? 0).toFixed(2))}
                Icon={
                    <div className={classNames(classes.iconContainer, classes.totalCustomersIcon)}>
                        <CashIcon size={25} />
                    </div>
                }
            />
        </div>
    );
}

const useStyles = makeStyles((theme) => ({
    kpiSection: {
        width: '100%',
        display: 'grid',
        gridTemplateColumns: 'repeat(auto-fit, minmax(150px, 1fr))',
        gap: 12,
        margin: '24px 10px 24px 0px',
    },
    iconContainer: {
        width: 40,
        height: 40,
        borderRadius: 40,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    totalOrdersIcon: {
        backgroundColor: theme.palette.surface.brand,
        color: theme.palette.surface.brandContrast,
    },
    timeIcon: {
        backgroundColor: '#F9E1D0',
        color: '#FF8F40',
    },
    totalCustomersIcon: {
        backgroundColor: '#DEFAE4',
        color: '#1A9F76',
    },
}));

type Props = {
    kpisByDeliveryTimeCategory: KpisByDeliveryTimeCategory;
};
