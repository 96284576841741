import * as React from 'react';
import { Button } from 'src/components/Button';
import { translate } from 'src/i18n/translate';
import type { OrderVm } from 'src/types/OrderVm';
import { openUberEatsOrderTrackingUrlInNewTab } from 'src/utils/window/openUberEatsOrderTrackingUrlInNewTab';

export function TrackRappiCargoOrderButton({ order, disabled }: Props): React.ReactElement | null {
    if (!order.rappiCargoDeliveryTrackingUrl) return null;

    return (
        <Button secondary onClick={() => openUberEatsOrderTrackingUrlInNewTab(order.rappiCargoDeliveryTrackingUrl)} disabled={disabled}>
            {translate('Track Rappi Cargo Order')}
        </Button>
    );
}

type Props = {
    order: OrderVm;
    disabled?: boolean;
};
