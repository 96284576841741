import { MenuItem } from '@material-ui/core';
import * as React from 'react';
import { useState } from 'react';
import { CreateOrderInvoiceDialog } from 'src/components/dialog/order/CreateOrderInvoiceDialog';
import { translate } from 'src/i18n/translate';
import type { OrderVm } from 'src/types/OrderVm';
import { isUruguay } from 'src/utils/country/isUruguay';
import { isAcceptedOrder } from 'src/utils/order/isAcceptedOrder';
import { isCompletedOrder } from 'src/utils/order/isCompletedOrder';
import { isPaid } from 'src/utils/payment/isPaid';
import { isString } from 'src/utils/string/isString';

export function InvoiceOrderContextualMenuItem({ order, onChange }: Props): React.ReactElement {
    const [createOrderInvoiceDialogState, setCreateOrderInvoiceDialogState] = useState({ open: false });

    const orderStatusForInvoiceUy = isUruguay(order.country) && isPaid(order.paymentStatus) && isAcceptedOrder(order.orderStatus);

    const openCreateOrderInvoiceDialog = () => {
        if ((!isCompletedOrder(order.orderStatus) || order.invoiced) && !orderStatusForInvoiceUy) return;
        setCreateOrderInvoiceDialogState({ open: true });
    };

    const closeCreateOrderInvoiceDialog = () => setCreateOrderInvoiceDialogState({ open: false });

    const handleDialogKeyDown = (event: any) => {
        if (isString(event.key) && event.key !== 'Escape') {
            event.stopPropagation();
        }
    };

    return (
        <div onKeyDown={handleDialogKeyDown}>
            <CreateOrderInvoiceDialog open={createOrderInvoiceDialogState.open} order={order} onClose={closeCreateOrderInvoiceDialog} onSuccess={onChange} />
            <MenuItem onClick={openCreateOrderInvoiceDialog} style={{ textTransform: 'none' }}>
                {translate('Invoice order')}
            </MenuItem>
        </div>
    );
}

type Props = {
    order: OrderVm;
    onChange?: any;
};
