import { Typography, useTheme } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import { Loader } from '@pidedirecto/ui';
import { GoogleMap, Marker, useLoadScript } from '@react-google-maps/api';
import * as React from 'react';
import { useCallback, useEffect, useState } from 'react';
import { googleMapsConfig } from 'src/config/googleMapsConfig';
import { TimeZones } from 'src/constants/TimeZone';
import { translate } from 'src/i18n/translate';
import type { GeoJsonPoint } from 'src/types/GeoJsonPoint';
import type { DriverId, RestaurantId } from 'src/types/Id';
import { isWithinHalfAnHour } from 'src/utils/date/isWithinHalfAnHour';
import { toLatLng } from 'src/utils/googlemaps/toLatLng';
import { requireValue } from 'src/utils/require/requireValue';
import { openDriverPageInNewTab } from 'src/utils/window/openDriverPageInNewTab';

export function GoogleMapsDriverPositions({ drivers, restaurants, reload }: Props): React.ReactElement {
    const { isLoaded, loadError } = useLoadScript(googleMapsConfig);

    if (!drivers.length) {
        return (
            <Grid container direction='column' alignItems='center'>
                <Grid item>
                    <Typography variant='h6' color='textSecondary'>
                        {translate('No drivers to show on map')}
                    </Typography>
                </Grid>
            </Grid>
        );
    }

    if (!isLoaded) {
        return <Loader size={20} loading={true} />;
    }
    if (loadError) {
        return <Typography>{translate('Failed to load google maps, please refresh page.')}</Typography>;
    }

    return (
        <div style={{ position: 'relative' }}>
            {/*<div style={{position: 'absolute', zIndex: 999999, top: 0, fontSize: 10, color: '#888'}}>{formatDateTimeStringReadable(positionedAt)}</div>*/}
            <Map drivers={drivers} restaurants={restaurants} reload={reload} />
        </div>
    );
}

function Map({ drivers, restaurants, reload }: Props): React.ReactElement {
    const [map, setMap] = useState<google.maps.Map>();
    const theme = useTheme();

    const onLoad = useCallback(
        (map: google.maps.Map) => {
            setMap(map);
            // if (city) {
            //     const bounds = new window.google.maps.Circle({ center: toLatLng(getCenterOfCity(city)), radius: 100 * KM }).getBounds();
            //     map && map.fitBounds(bounds, { top: 60, bottom: 10 });
            // } else {
            const bounds = new window.google.maps.LatLngBounds();
            drivers.forEach((driver) => {
                if (driver.position) bounds.extend(toLatLng(driver.position));
            });
            map.fitBounds(bounds);
            // }
        },
        [drivers],
    );

    useEffect(() => {
        if (!map) return;
        const bounds = new window.google.maps.LatLngBounds();
        drivers.forEach((driver) => {
            if (driver.position) bounds.extend(toLatLng(driver.position));
        });
        map.fitBounds(bounds);
    }, [reload]);

    const onUnmount = useCallback((map: google.maps.Map) => {
        setMap(undefined);
    }, []);

    return (
        <GoogleMap
            onLoad={onLoad}
            onUnmount={onUnmount}
            options={{
                fullscreenControl: false,
                mapTypeControl: false,
                streetViewControl: false,
                styles: mapStyles,
                gestureHandling: 'cooperative',
            }}
            mapContainerStyle={mapContainerStyle}
        >
            {restaurants.map((restaurant) => (
                <Marker
                    key={restaurant.restaurantId}
                    icon={{
                        path: window.google.maps.SymbolPath.CIRCLE,
                        scale: 5,
                        strokeWeight: 10,
                        strokeColor: '#884343',
                    }}
                    label={{
                        text: 's',
                        color: 'white',
                        fontFamily: 'LetsEatIcons',
                        fontSize: '20px',
                    }}
                    position={toLatLng(restaurant.location)}
                />
            ))}
            {drivers
                .filter((driver) => driver.position)
                .map((driver) => (
                    <Marker
                        key={driver.driverId}
                        icon={{
                            path: window.google.maps.SymbolPath.CIRCLE,
                            scale: 5,
                            strokeWeight: 10,
                            strokeColor: isWithinHalfAnHour(requireValue(driver.positionedAt), TimeZones.AMERICA_MONTERREY) ? theme.palette.primary.dark : 'darkgrey',
                        }}
                        label={{
                            text: '"',
                            color: 'white',
                            fontFamily: 'LetsEatIcons',
                            fontSize: '20px',
                        }}
                        position={toLatLng(driver.position!)}
                        onClick={() => openDriverPageInNewTab(driver.driverId)}
                    />
                ))}
        </GoogleMap>
    );
}

const mapContainerStyle = {
    height: '400px',
} as const;

const mapStyles = [
    {
        featureType: 'poi',
        elementType: 'labels.text',
        stylers: [
            {
                visibility: 'off',
            },
        ],
    },
    {
        featureType: 'poi.business',
        stylers: [
            {
                visibility: 'off',
            },
        ],
    },
    {
        featureType: 'road',
        elementType: 'labels.icon',
        stylers: [
            {
                visibility: 'off',
            },
        ],
    },
    {
        featureType: 'transit',
        stylers: [
            {
                visibility: 'off',
            },
        ],
    },
];

type Props = {
    drivers: ReadonlyArray<{
        driverId: DriverId;
        position?: GeoJsonPoint;
        positionedAt?: Date;
        mobileNumber?: string;
        banned?: boolean;
        removed?: boolean;
        verified?: boolean;
        signedIn: boolean;
        online: boolean;
    }>;
    restaurants: ReadonlyArray<{
        restaurantId: RestaurantId;
        location: GeoJsonPoint;
    }>;
    reload: number;
};
