import { makeStyles } from '@material-ui/core';
import { Accordion } from '@pidedirecto/ui';
import { ArrowDownIcon, ArrowUpIcon } from '@pidedirecto/ui/icons';
import { memo, useEffect, useState } from 'react';
import * as React from 'react';
import { translate } from 'src/i18n/translate';
import { PosCustomerEmailInput } from 'src/modules/pos/customer/components/PosCustomerEmailInput';
import { PosCustomerFirstNameInput } from 'src/modules/pos/customer/components/PosCustomerFirstNameInput';
import { PosCustomerLastNameInput } from 'src/modules/pos/customer/components/PosCustomerLastNameInput';
import { PosCustomerNoteInput } from 'src/modules/pos/customer/components/PosCustomerNoteInput';
import { PosNumberOfCustomersInput } from 'src/modules/pos/customer/components/PosNumberOfCustomersInput';
import { PosPhoneNumberCustomerAutoComplete } from 'src/modules/pos/customer/components/PosPhoneNumberCustomerAutoComplete';
import { usePosCustomerActions } from 'src/modules/pos/customer/posCustomerStore';
import { isDeliveryOrder } from 'src/utils/order/isDeliveryOrder';
import { isEatHereOrder } from 'src/utils/order/isEatHereOrder';
import { isTakeAwayOrder } from 'src/utils/order/isTakeAwayOrder';
import { classNames } from 'src/utils/react/classNames';
import { useSelector } from 'src/utils/react/useSelector';

export const PosCustomerInfo: React.ComponentType<any> = memo<any>(() => {
    const classes = useStyles();

    const [displayCustomerInfoInputs, setDisplayCustomerInfoInputs] = useState(false);

    const setCustomerFirstName = usePosCustomerActions((actions) => actions.setCustomerFirstName);
    const setCustomerLastName = usePosCustomerActions((actions) => actions.setCustomerLastName);
    const setCustomerEmail = usePosCustomerActions((actions) => actions.setCustomerEmail);
    const setCustomerNote = usePosCustomerActions((actions) => actions.setCustomerNote);
    const setCustomerMobileNumber = usePosCustomerActions((actions) => actions.setCustomerMobileNumber);
    const setNumberOfCustomers = usePosCustomerActions((actions) => actions.setNumberOfCustomers);

    const openMobilePaymentResume = useSelector((state) => state.pos.openMobilePaymentResume);
    const posCustomerNameRequiredOnTakeAwayOrdersEnabled = useSelector((state) => state.pos.context?.posCustomerNameRequiredOnTakeAwayOrdersEnabled);
    const table = useSelector((state) => state.pos.table);
    const pendingOrder = useSelector((state) => state.pos.pendingOrder);
    const driverRequest = useSelector((state) => state.pos.driverRequest);
    const posNumberOfCustomers = useSelector((state) => state.pos.numberOfCustomers);
    const customerName = useSelector((state) => state.pos.customerName);
    const orderType = useSelector((state) => state.pos.orderType);
    const posCustomersRequiredEnabled = useSelector((state) => state.pos.context?.posCustomersRequiredEnabled);
    const requestCustomerNameOnCreateOrderEnabled = useSelector((state) => state.pos.context?.requestCustomerNameOnCreateOrderEnabled);

    const customerNameRequired = (posCustomerNameRequiredOnTakeAwayOrdersEnabled && isTakeAwayOrder(orderType)) || requestCustomerNameOnCreateOrderEnabled;
    const numberOfCustomerRequired = isEatHereOrder(orderType) && posCustomersRequiredEnabled;

    useEffect(() => {
        if (pendingOrder) {
            setDisplayCustomerInfoInputs(true);
            setCustomerFirstName(pendingOrder.firstName ?? pendingOrder.customerName);
            setCustomerLastName(pendingOrder.lastName ?? '');
            setCustomerMobileNumber(pendingOrder.customerMobileNumber);
            setCustomerEmail(pendingOrder.email);
            if (!!pendingOrder.customerNote) setCustomerNote(pendingOrder.customerNote);
            setNumberOfCustomers(pendingOrder.numberOfCustomers ?? table?.numberOfCustomers ?? posNumberOfCustomers);
        }

        if (table?.numberOfCustomers && !pendingOrder) {
            setNumberOfCustomers(table?.numberOfCustomers ?? posNumberOfCustomers);
        }
    }, [pendingOrder]);

    useEffect(() => {
        if (driverRequest) {
            setCustomerFirstName(driverRequest.firstName ?? driverRequest?.name);
            setCustomerLastName(driverRequest.lastName ?? '');
            setCustomerMobileNumber(driverRequest.phoneNumber);
            if (driverRequest.email) setCustomerEmail(driverRequest.email);
            if (driverRequest.customerNote) setCustomerNote(driverRequest.customerNote);
        }
    }, [driverRequest]);

    useEffect(() => {
        if (customerName) {
            setCustomerFirstName(customerName.firstName);
            setCustomerLastName(customerName.lastName);
        }
    }, []);

    useEffect(() => {
        if (customerNameRequired || numberOfCustomerRequired || isDeliveryOrder(orderType)) {
            setDisplayCustomerInfoInputs(true);
        }
    }, []);

    const handleOpenCustomerInfoAccordion = () => {
        if (customerNameRequired || numberOfCustomerRequired || isDeliveryOrder(orderType)) return;

        setDisplayCustomerInfoInputs(!displayCustomerInfoInputs);
    };

    return (
        <div className={classNames(classes.customerInfoContainer, !openMobilePaymentResume ? '' : classes.hidden)}>
            <div onClick={handleOpenCustomerInfoAccordion} className={classes.accordionHeader}>
                <div>{translate('Customer Details')}</div>
                <div className={classes.icons}>
                    {!displayCustomerInfoInputs && <ArrowDownIcon title={'open customer info accordion'} />}
                    {displayCustomerInfoInputs && <ArrowUpIcon title={'close customer info accordion'} />}
                </div>
            </div>
            <Accordion open={displayCustomerInfoInputs} classes={{ accordion: classes.accordion }}>
                <PosCustomerFirstNameInput />
                <PosCustomerLastNameInput />
                <PosPhoneNumberCustomerAutoComplete />
                <PosCustomerEmailInput />
                <PosNumberOfCustomersInput />
                <PosCustomerNoteInput />
            </Accordion>
        </div>
    );
});
const useStyles = makeStyles((theme) => ({
    accordionHeader: {
        width: '100%',
        display: 'flex',
        fontFamily: theme.typography.medium,
        fontSize: 16,
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: 10,
        paddingLeft: 0,
    },
    accordion: {
        display: 'flex',
        flexDirection: 'column',
        gap: 10,
        width: '100%',
        padding: 5,
    },
    customerInfoContainer: {
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        marginTop: 20,
        [theme.breakpoints.up('sm')]: {
            gridArea: 'customerInfo',
        },
    },
    hidden: {
        display: 'none',
    },
    icons: {
        color: theme.palette.text.contrast,
    },
}));
