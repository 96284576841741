import * as React from 'react';

export function ClockIcon({ title, color }: Props): React.ReactElement {
    return (
        <svg id='Iconly_Broken_Time_Circle' data-name='Iconly/Broken/Time Circle' width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
            {title && <title>{title}</title>}
            <path
                id='Time_Circle-2'
                data-name='Time Circle'
                d='M15.4 17.2007C13.8958 18.3305 12.0261 19 10 19C5.02944 19 1 14.9706 1 10C1 5.02944 5.02944 1 10 1C12.3051 1 14.4077 1.86656 16 3.29168C17.0632 4.24331 17.56 5.08 19 7M19 7V2.8M19 7H14.8M9.4 5.8V10.6L13.6 13'
                stroke={color || 'currentColor'}
                strokeWidth='1.5'
            />
        </svg>
    );
}

type Props = {
    color?: string;
    title?: string;
};
