import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';
import { makeStyles } from '@material-ui/core/styles';
import { useState } from 'react';
import * as React from 'react';
import { removeUserRoleApi } from 'src/api/letseatmanager/userRole/removeUserRoleApi';
import { translate } from 'src/i18n/translate';
import type { UserRoleVm } from 'src/types/UserRoleVm';
import { alertKnownErrorOrSomethingWentWrong } from 'src/utils/alert/alertKnownErrorOrSomethingWentWrong';

export function RemoveUserRoleDialog({ open, userRole, onSuccess, onClose }: Props): React.ReactElement {
    const classes = useStyles();

    const [loading, setLoading] = useState(false);

    const removeRole = async () => {
        setLoading(true);
        const response = await removeUserRoleApi({
            userRoleId: userRole?.userRoleId,
        });
        setLoading(false);

        if (!response.ok) {
            alertKnownErrorOrSomethingWentWrong(response);
            return;
        }
        onSuccess();
    };

    return (
        <Dialog fullWidth scroll='paper' className={classes.form} open={open} onClose={onClose} onExited={onClose}>
            <DialogTitle classes={{ root: classes.dialogTitle }}>{translate('Are you sure you want to remove the role @roleName?', { roleName: userRole?.roleName })}</DialogTitle>
            <DialogActions className={classes.buttonsContainer}>
                <Button onClick={onClose} disabled={loading}>
                    <div className={classes.cancelButton}>{translate('Cancel')}</div>
                </Button>
                <Button color='primary' type='submit' onClick={removeRole} disabled={loading} classes={{ root: classes.noMargin }}>
                    <div className={classes.acceptButton}>{loading ? translate('Removing') : translate('Remove')}</div>
                </Button>
            </DialogActions>
        </Dialog>
    );
}

const useStyles = makeStyles((theme) => ({
    dialogTitle: {
        textAlign: 'center',
        fontSize: 41,
        color: theme.palette.secondary.contrastText,
        paddingTop: theme.spacing(4),
        paddingBottom: theme.spacing(3),
        '& h2': {
            fontFamily: theme.typography.medium,
        },
    },
    form: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    buttonsContainer: {
        marginTop: 20,
        marginBottom: 20,
        justifyContent: 'center',
        [theme.breakpoints.down('sm')]: {
            flexDirection: 'column',
        },
    },
    noMargin: {
        margin: '0 !important',
    },
    acceptButton: {
        backgroundColor: theme.palette.primary.main,
        color: 'white',
        fontFamily: theme.typography.semiBold,
        fontSize: 15,
        borderRadius: 5,
        height: 40,
        width: 200,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        textTransform: 'none',
        boxShadow: `3px 3px 10px ${theme.palette.primary.main}aa`,
    },
    cancelButton: {
        backgroundColor: theme.palette.secondary.dark,
        color: theme.palette.secondary.contrastText,
        fontFamily: theme.typography.semiBold,
        fontSize: 15,
        borderRadius: 5,
        height: 40,
        width: 200,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        textTransform: 'none',
    },
    menu: {
        fontFamily: theme.typography.medium,
        width: '100%',
    },
}));

type Props = {
    open: boolean;
    userRole?: UserRoleVm;
    onSuccess: any;
    onClose: any;
};
