export function ellipsisMiddle(value: string | undefined, length: number, right?: number): string | undefined {
    if (!value) {
        return value;
    }
    if (value.length > length) {
        const charactersToKeep = length - 3;
        const left = right !== undefined ? charactersToKeep - right : Math.ceil(charactersToKeep / 2);
        const _right = right !== undefined ? right : Math.floor(charactersToKeep / 2);
        return value.substr(0, left) + '...' + value.substr(value.length - _right, value.length);
    }
    return value;
}
