import { FormHelperText, makeStyles } from '@material-ui/core';
import * as React from 'react';

export function FormErrorMessage({ errorMessage }: Props): React.ReactElement | null {
    const classes = useStyles();

    if (!errorMessage) return null;

    return <FormHelperText className={classes.text}>{errorMessage}</FormHelperText>;
}

const useStyles = makeStyles((theme) => ({
    text: {
        fontFamily: theme.typography.light,
        color: 'red',
    },
}));

type Props = {
    errorMessage?: string;
};
