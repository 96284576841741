import { makeStyles } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Grid from '@material-ui/core/Grid';
import LinearProgress from '@material-ui/core/LinearProgress';
import gql from 'graphql-tag';
import * as React from 'react';
import { useState } from 'react';
import { restartDeliveryManager } from 'src/api/appsync/mutations';
import { app2 } from 'src/app2';
import { Form } from 'src/components/deprecated/react-final-form/Form';
import { appsyncClient } from 'src/config/appsyncClient';
import { translate } from 'src/i18n/translate';
import type { DeliveryVm } from 'src/scenes/letseatadmin/deliveryManager/Delivery';
import { appLayout } from 'src/theme/AppLayout';
import { alertKnownErrorOrSomethingWentWrongError } from 'src/utils/alert/alertKnownErrorOrSomethingWentWrongError';
import { useAction } from 'src/utils/react/useAction';
import { useSelector } from 'src/utils/react/useSelector';
import { requireValue } from 'src/utils/require/requireValue';

export function RestartDeliveryManagerDialog(): React.ReactElement {
    const classes = useStyles();

    const [loading, setLoading] = useState(false);
    const open = useSelector((state) => state.app2.restartDeliveryManagerDialog.open);
    const orderId = useSelector((state) => state.app2.restartDeliveryManagerDialog.orderId);
    const onSuccess = useSelector((state) => state.app2.restartDeliveryManagerDialog.onSuccess);
    const close = useAction(app2.actions.closeRestartDeliveryManagerDialog);

    return (
        <Dialog fullWidth aria-labelledby='RestartDeliveryManagerDialog-title' scroll='paper' open={open} onClose={close}>
            <Form
                initialValues={{
                    deliveryEarnings: '0',
                }}
                onSubmit={async (form: any) => {
                    if (loading) return;
                    setLoading(true);
                    try {
                        const response = await appsyncClient.mutate<any>({
                            mutation: gql(restartDeliveryManager),
                            variables: {
                                orderId: orderId,
                            },
                        });
                        var deliveryManager: DeliveryVm = requireValue(response.data.restartDeliveryManager);
                    } catch (e: any) {
                        setLoading(false);
                        alertKnownErrorOrSomethingWentWrongError(e);
                        return;
                    }
                    setLoading(false);
                    close();
                    onSuccess && onSuccess(deliveryManager);
                }}
                render={({ handleSubmit, values }: { handleSubmit: any; values: any }) => {
                    return (
                        <form onSubmit={handleSubmit} noValidate style={appLayout.dialog.form}>
                            <DialogTitle id='RestartDeliveryManagerDialog-title'>{translate('Restart Delivery Manager').toUpperCase()}</DialogTitle>
                            <DialogContent>
                                <Grid container spacing={3}>
                                    {/*<Grid item xs={12}>*/}
                                    {/*    <Field name="deliveryEarnings"*/}
                                    {/*           label={translate('Driver Earnings')}*/}
                                    {/*           component={TextField}*/}
                                    {/*           fullWidth*/}
                                    {/*           disabled={loading}*/}
                                    {/*           required*/}
                                    {/*           validate={combine(required, greaterThanOrEqualToZero)}*/}
                                    {/*    />*/}
                                    {/*</Grid>*/}
                                </Grid>
                            </DialogContent>
                            <DialogActions>
                                <Button onClick={close} disabled={loading}>
                                    {translate('Cancel').toUpperCase()}
                                </Button>
                                <Button color='primary' type='submit' disabled={loading}>
                                    {loading ? translate('Restarting').toUpperCase() : translate('Restart').toUpperCase()}
                                </Button>
                            </DialogActions>
                            {loading && <LinearProgress className={classes.linearProgress} />}
                        </form>
                    );
                }}
            />
        </Dialog>
    );
}

const useStyles = makeStyles((theme) => ({
    linearProgress: {
        position: 'absolute',
        width: '100%',
        bottom: 0,
    },
}));
