import { makeStyles } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import ClearIcon from '@material-ui/icons/Clear';
import * as React from 'react';
import { useEffect } from 'react';
import { useFieldArray } from 'react-hook-form';
import { FormNumberField } from 'src/components/form/FormNumberField';
import { FormSwitch } from 'src/components/form/FormSwitch';
import { FormTextField } from 'src/components/form/FormTextField';
import { translate } from 'src/i18n/translate';
import { SubModifier } from 'src/scenes/letseatmanager/deprecatedMenu/SubModifier';
import type { DeprecatedSubModifierGroupVm, DeprecatedSubModifierVm } from 'src/types/DeprecatedManagerMenuItemVm';
import { stringEqualsTrim } from 'src/utils/compare/stringEqualsTrim';

export function SubModifierGroup({
    modifierGroupIndex,
    modifierIndex,
    subModifierGroupIndex,
    removeSubModifierGroup,
    showHiddenSwitch,
    subModifierGroup,
    control,
    loading,
    prevSubModifiers,
}: Props): React.ReactElement {
    const classes = useStyles();
    const { fields, append, remove } = useFieldArray({
        control,
        name: `modifierGroups.${modifierGroupIndex}.modifiers.${modifierIndex}.subModifierGroups.${subModifierGroupIndex}.subModifiers`,
    });

    const renderSubModifiers = () => {
        const addNewSubModifier = () => append({ name: '', price: '', hidden: false });
        return (
            <div style={{ width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center', marginTop: 20 }}>
                <h3 className={classes.title}>{translate('SUB MODIFIERS')}</h3>
                {fields.map((subModifier, subModifierIndex) => (
                    <SubModifier
                        key={subModifier.id}
                        modifierGroupIndex={modifierGroupIndex}
                        modifierIndex={modifierIndex}
                        subModifierGroupIndex={subModifierGroupIndex}
                        subModifierIndex={subModifierIndex}
                        subModifier={subModifier as any}
                        loading={loading}
                        showRemoveButton={fields.length > 1}
                        removeSubModifier={() => remove(subModifierIndex)}
                        showHiddenSwitch={showHiddenSwitch}
                    />
                ))}
                <div style={{ marginTop: 10 }}>
                    <Button color='primary' onClick={addNewSubModifier} disabled={loading}>
                        <div className={classes.secondaryButton}>{translate('ADD SUB MODIFIER')}</div>
                    </Button>
                </div>
            </div>
        );
    };

    useEffect(() => {
        if (prevSubModifiers && fields.length !== prevSubModifiers?.length) {
            for (const modifier of prevSubModifiers) {
                append({
                    name: modifier.name,
                    price: modifier.price,
                    type: modifier.type,
                    hidden: modifier.hidden,
                });
            }
        }
    }, [prevSubModifiers]);

    return (
        <div>
            <Grid container alignItems='center'>
                <Grid item xs={2} />
                <Grid item xs={8}>
                    <h3 className={classes.title}>
                        {translate('SUB MODIFIER GROUP')} {subModifierGroupIndex + 1}
                    </h3>
                </Grid>
                <Grid item xs={2}>
                    <IconButton disabled={loading} aria-label='Remove' onClick={removeSubModifierGroup}>
                        <ClearIcon />
                    </IconButton>
                </Grid>
            </Grid>
            <Grid container spacing={3}>
                {showHiddenSwitch && (
                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                            <FormSwitch
                                name={`modifierGroups.${modifierGroupIndex}.modifiers.${modifierIndex}.subModifierGroups.${subModifierGroupIndex}.hidden`}
                                disabled={loading}
                                label={translate('Hidden')}
                                defaultValue={subModifierGroup.hidden}
                            />
                        </Grid>
                    </Grid>
                )}
                <Grid item xs={12}>
                    <FormTextField
                        name={`modifierGroups.${modifierGroupIndex}.modifiers.${modifierIndex}.subModifierGroups.${subModifierGroupIndex}.name`}
                        label={translate('Name')}
                        required
                        disabled={loading}
                        defaultValue={subModifierGroup.name}
                    />
                </Grid>
                <Grid item xs={12}>
                    <FormTextField
                        name={`modifierGroups.${modifierGroupIndex}.modifiers.${modifierIndex}.subModifierGroups.${subModifierGroupIndex}.externalSubModifierGroupId`}
                        label={translate('External Modifier Group Id')}
                        disabled={loading}
                        defaultValue={subModifierGroup.externalSubModifierId}
                    />
                </Grid>
            </Grid>
            <Grid container spacing={3}>
                <Grid item xs={12} sm={6} md={6}>
                    <FormNumberField
                        name={`modifierGroups.${modifierGroupIndex}.modifiers.${modifierIndex}.subModifierGroups.${subModifierGroupIndex}.requiredMin`}
                        label={translate('Required Min')}
                        disabled={loading}
                        defaultValue={subModifierGroup.requiredMin}
                    />
                </Grid>
                <Grid item xs={12} sm={6} md={6}>
                    <FormNumberField
                        name={`modifierGroups.${modifierGroupIndex}.modifiers.${modifierIndex}.subModifierGroups.${subModifierGroupIndex}.requiredMax`}
                        label={translate('Required Max')}
                        disabled={loading}
                        defaultValue={subModifierGroup.requiredMax}
                    />
                </Grid>
            </Grid>
            {renderSubModifiers()}
        </div>
    );
}

const uniqueModifierGroupName = (value: any, form: any) => {
    if (!value) {
        return;
    }
    let i = 0;
    for (const modifierGroup of form.modifierGroups) {
        if (stringEqualsTrim(value, modifierGroup.name)) {
            i++;
            if (i > 1) {
                return 'name is already used in another modifier group';
            }
        }
    }
};

const useStyles = makeStyles((theme) => ({
    title: {
        fontFamily: theme.typography.medium,
        margin: '0',
        marginBottom: 10,
        textAlign: 'center',
    },
    secondaryButton: {
        backgroundColor: 'white',
        color: theme.palette.primary.main,
        border: `1px solid ${theme.palette.primary.main}`,
        fontFamily: theme.typography.semiBold,
        fontSize: 15,
        borderRadius: 5,
        height: 40,
        width: 200,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        textTransform: 'none',
    },
}));

type Props = {
    modifierGroupIndex: number;
    modifierIndex: number;
    subModifierGroupIndex: number;
    subModifierGroup: DeprecatedSubModifierGroupVm;
    loading: boolean;
    control: any;
    removeSubModifierGroup: any;
    showHiddenSwitch: boolean;
    prevSubModifiers?: Array<DeprecatedSubModifierVm>;
};
