import { makeStyles } from '@material-ui/core';
import * as React from 'react';
import { findRestaurantIntegrationsApi } from 'src/api/letseatmanager/restaurantIntegration/findRestaurantIntegrationsApi';
import { Alert } from 'src/components/Alert';
import { RefreshToolbarButton } from 'src/components/mui-datatables/RefreshToolbarButton';
import { SecuredAndSubscribedPage } from 'src/components/page/SecuredAndSubscribedPage';
import { UpdatingContentProgress } from 'src/components/UpdatingContentProgress';
import { RolePermissions } from 'src/constants/RolePermission';
import { translate } from 'src/i18n/translate';
import { IntegrationsLogEvents } from 'src/scenes/letseatadmin/logEvents/IntegrationsLogEvents';
import { DidiIntegration } from 'src/scenes/letseatmanager/integrations/DidiIntegration';
import { PedidosYaIntegration } from 'src/scenes/letseatmanager/integrations/PedidosYaIntegration';
import { RappiIntegration } from 'src/scenes/letseatmanager/integrations/RappiIntegration';
import { UberIntegration } from 'src/scenes/letseatmanager/integrations/UberIntegration';
import { IntegrationsEmptyState } from 'src/scenes/letseatmanager/IntegrationsEmptyState';
import { isArgentina } from 'src/utils/country/isArgentina';
import { isPeru } from 'src/utils/country/isPeru';
import { isUruguay } from 'src/utils/country/isUruguay';
import { useLoadApi } from 'src/utils/react/useLoadApi';
import { useSelector } from 'src/utils/react/useSelector';

export function IntegrationsPage(): React.ReactElement {
    const classes = useStyles();

    const restaurant = useSelector((state) => state.app.restaurant);
    const restaurantId = useSelector((state) => state.app.restaurantId);

    const [loading, restaurantIntegrationsStatus, load] = useLoadApi(
        findRestaurantIntegrationsApi,
        { restaurantId },
        {
            initialValue: {
                uberEatsStoreId: undefined,
                rappiStoreId: undefined,
                rappiIntegrationStatus: undefined,
                pedidosYaRestaurantId: undefined,
                didiFoodStoreId: undefined,
                deliverOrdersFromDidiFood: false,
                deliverOrdersFromUberEats: false,
                deliverOrdersFromRappi: false,
                pedidosYaIntegrationStatus: false,
                pedidosYaRestaurantOnline: false,
            },
            dependencies: [restaurantId],
        },
    );

    if (!restaurant?.integrationsEnabled) return <IntegrationsEmptyState />;

    return (
        <SecuredAndSubscribedPage rolePermission={RolePermissions.INTEGRATIONS_PAGE} title={translate('Integrations')}>
            <div className={classes.container}>
                <Alert title={translate('Could take some time to the actions will be reflected on integrations.')} />
                <UpdatingContentProgress loading={loading} />
                <div className={classes.refreshButtonContainer}>
                    <RefreshToolbarButton onClick={load} />
                </div>
                <div className={classes.contentContainer}>
                    <>
                        <UberIntegration restaurantIntegrationsStatus={restaurantIntegrationsStatus} load={load} isIntegrationLoaded={loading} />
                        <DidiIntegration restaurantIntegrationsStatus={restaurantIntegrationsStatus} load={load} isIntegrationLoaded={loading} />
                        <RappiIntegration restaurantIntegrationsStatus={restaurantIntegrationsStatus} load={load} isIntegrationLoaded={loading} />
                        {(isUruguay(restaurant.country) || isArgentina(restaurant.country) || isPeru(restaurant.country)) && (
                            <PedidosYaIntegration
                                restaurantPedidosYaIntegrationsStatus={{
                                    pedidosYaRestaurantId: restaurantIntegrationsStatus.pedidosYaRestaurantId,
                                    pedidosYaIntegrationStatus: restaurantIntegrationsStatus.pedidosYaIntegrationStatus,
                                    pedidosYaRestaurantOnline: restaurantIntegrationsStatus.pedidosYaRestaurantOnline,
                                    pedidosYaSalesCommissionPercentage: restaurantIntegrationsStatus.pedidosYaSalesCommissionPercentage,
                                }}
                                load={load}
                                disabled={loading}
                            />
                        )}
                    </>
                </div>
            </div>

            <div className={classes.containerTable}>
                <IntegrationsLogEvents />
            </div>
        </SecuredAndSubscribedPage>
    );
}

const useStyles = makeStyles((theme) => ({
    container: {
        width: '90%',
        height: '90%',
        borderRadius: 15,
        margin: '0 auto',
        marginTop: 20,
        marginBottom: 20,
        paddingBottom: 20,
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column',
        position: 'relative',
        [theme.breakpoints.down('sm')]: {
            height: 'fit-content',
        },
    },
    containerTable: {
        width: '90%',
        height: '90%',
        margin: '0 auto',
        marginTop: 20,
        marginBottom: 20,
        paddingBottom: 20,
        position: 'relative',
        [theme.breakpoints.down('sm')]: {
            height: 'fit-content',
        },
    },
    refreshButtonContainer: {
        width: '100%',
        textAlign: 'right',
        borderRadius: 8,
        marginTop: 10,
        marginBottom: 10,
    },
    contentContainer: {
        width: '100%',
        height: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
    },
    button: {
        backgroundColor: theme.palette.primary.main,
        color: 'white',
        fontFamily: theme.typography.semiBold,
        fontSize: 15,
        borderRadius: 5,
        height: 40,
        width: 200,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        textTransform: 'none',
    },
}));
