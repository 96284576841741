import { configRestaurantStatisticsApi } from 'src/api/config/configReportGeneralApiSauce';
import type { ApiSauceResponse } from 'src/api/types/ApiSauceResponse';
import { getJwtToken } from 'src/api/utils/getJwtToken';

const restaurantStatisticsApi = configRestaurantStatisticsApi();

export async function pidedirectoReportsMethod(url: string, request?: Record<string, any>): ApiSauceResponse<any> {
    const jwtTokenResult = await getJwtToken();
    if (jwtTokenResult.errorResponse) {
        return jwtTokenResult.errorResponse;
    }

    return restaurantStatisticsApi.post(url, request ?? {}, {
        headers: {
            authorization: jwtTokenResult.jwtToken,
        },
    });
}
