import * as React from 'react';
import { useState } from 'react';
import { SecuredAndSubscribedPage } from 'src/components/page/SecuredAndSubscribedPage';
import { RolePermissions } from 'src/constants/RolePermission';
import { translate } from 'src/i18n/translate';
import { PrinterWizardProvider } from 'src/providers/PrinterWizardProvider';
import { CreatePrinterWizard } from 'src/scenes/letseatmanager/device/printers/CreatePrinterWizard';
import { Printers } from 'src/scenes/letseatmanager/device/printers/Printers';

export function PrintersPage(): React.ReactElement {
    const [printerManagementType, setPrinterManagementType] = useState<PrinterManagementType>(PrinterManagementTypes.MANAGE_PRINTERS);

    const handleOpenManagePrintersSection = () => setPrinterManagementType(PrinterManagementTypes.MANAGE_PRINTERS);
    const handleOpenCreatePrinterSection = () => setPrinterManagementType(PrinterManagementTypes.CREATE_PRINTER);

    return (
        <SecuredAndSubscribedPage rolePermission={RolePermissions.PRINTERS_PAGE} title={translate('Printers')}>
            {printerManagementType === PrinterManagementTypes.MANAGE_PRINTERS && <Printers openCreatePrinterSection={handleOpenCreatePrinterSection} />}
            {printerManagementType === PrinterManagementTypes.CREATE_PRINTER && (
                <PrinterWizardProvider openManagePrintersSection={handleOpenManagePrintersSection}>
                    <CreatePrinterWizard openManagePrintersSection={handleOpenManagePrintersSection} />
                </PrinterWizardProvider>
            )}
        </SecuredAndSubscribedPage>
    );
}

const PrinterManagementTypes = Object.freeze({
    CREATE_PRINTER: 'CREATE_PRINTER',
    MANAGE_PRINTERS: 'MANAGE_PRINTERS',
});

type PrinterManagementType = (typeof PrinterManagementTypes)[keyof typeof PrinterManagementTypes];
