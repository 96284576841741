import * as React from 'react';
import { createContext, useState } from 'react';
import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { Form } from 'src/components/form/Form';

export function OpeningHoursForm({ children, onSubmit, restaurantOpeningHours }: Props): React.ReactElement {
    const form = useForm();

    const [openingHours, setOpeningHours] = useState<WeekOpeningHours>(weekOpeningHours);

    useEffect(() => {
        if (restaurantOpeningHours) {
            form.reset({
                sameOpeningHoursForAllDays: !!restaurantOpeningHours.sameOpeningHoursForAllDays,
                pickupStationClosingTime: restaurantOpeningHours.pickupStationClosingTime,
                pickupStationDeliveryTime: restaurantOpeningHours.pickupStationDeliveryTime,
            });
        }
    }, [restaurantOpeningHours]);

    const setDayOpeningHours = (day: string, newOpeningHours: Array<OpeningHours>) => {
        if (!shouldUpdateOpeningHours(day, newOpeningHours)) return;
        setOpeningHours((prevOpeningHours) => ({ ...prevOpeningHours, [day]: newOpeningHours }));
    };

    const shouldUpdateOpeningHours = (day: string, newOpeningHours: Array<OpeningHours>) => {
        let update = false;
        if (newOpeningHours.length !== openingHours[day as keyof typeof weekOpeningHours]?.length) return true;
        newOpeningHours.forEach((newOpeningHours, idx) => {
            if (update) return;
            if (
                openingHours[day as keyof typeof weekOpeningHours][idx]?.openingTime !== newOpeningHours.openingTime ||
                openingHours[day as keyof typeof weekOpeningHours][idx]?.closingTime !== newOpeningHours.closingTime
            ) {
                update = true;
            }
        });
        return update;
    };

    const handleSubmit = (form: any) => {
        const clearedOpeningHours = {
            Mo: openingHours['Mo'].filter((openingHours) => !!openingHours.openingTime && !!openingHours.closingTime),
            Tu: openingHours['Tu'].filter((openingHours) => !!openingHours.openingTime && !!openingHours.closingTime),
            We: openingHours['We'].filter((openingHours) => !!openingHours.openingTime && !!openingHours.closingTime),
            Th: openingHours['Th'].filter((openingHours) => !!openingHours.openingTime && !!openingHours.closingTime),
            Fr: openingHours['Fr'].filter((openingHours) => !!openingHours.openingTime && !!openingHours.closingTime),
            Sa: openingHours['Sa'].filter((openingHours) => !!openingHours.openingTime && !!openingHours.closingTime),
            Su: openingHours['Su'].filter((openingHours) => !!openingHours.openingTime && !!openingHours.closingTime),
        } as const;
        onSubmit({
            pickupStationClosingTime: form.pickupStationClosingTime,
            pickupStationDeliveryTime: form.pickupStationDeliveryTime,
            sameOpeningHoursForAllDays: form.sameOpeningHoursForAllDays,
            openingHours: clearedOpeningHours,
        });
    };

    return (
        <Form form={form} onSubmit={handleSubmit}>
            <OpeningHoursContext.Provider
                value={{
                    setDayOpeningHours,
                    hours: restaurantOpeningHours?.hours ?? '',
                }}
            >
                {children}
            </OpeningHoursContext.Provider>
        </Form>
    );
}

const weekOpeningHours: WeekOpeningHours = {
    Mo: [],
    Tu: [],
    We: [],
    Th: [],
    Fr: [],
    Sa: [],
    Su: [],
};

export const OpeningHoursContext = createContext<OpeningHoursTypeContext>({
    hours: '',
    setDayOpeningHours: () => {},
});

type OpeningHoursTypeContext = {
    hours: string;
    setDayOpeningHours: (day: string, arg2: Array<OpeningHours>) => void;
};

type Props = {
    children: React.ReactNode;
    restaurantOpeningHours: RestaurantOpeningHours | undefined;
    onSubmit: (formType: OpeningHoursFormType) => Promise<void> | void;
};

type RestaurantOpeningHours = {
    hours?: string;
    sameOpeningHoursForAllDays?: boolean;
    pickupStationClosingTime?: string;
    pickupStationDeliveryTime?: string;
};

export type OpeningHoursFormType = {
    pickupStationClosingTime: string;
    pickupStationDeliveryTime: string;
    sameOpeningHoursForAllDays: boolean;
    openingHours: WeekOpeningHours;
};

type WeekOpeningHours = {
    Mo: Array<OpeningHours>;
    Tu: Array<OpeningHours>;
    We: Array<OpeningHours>;
    Th: Array<OpeningHours>;
    Fr: Array<OpeningHours>;
    Sa: Array<OpeningHours>;
    Su: Array<OpeningHours>;
};

type OpeningHours = {
    openingTime: string;
    closingTime: string;
};
