import { makeStyles, useTheme } from '@material-ui/core';
import Chip from '@material-ui/core/Chip';
import { MUIDataTableColumn } from 'mui-datatables';
import { useEffect, useState } from 'react';
import * as React from 'react';
import { getGeneralInventoryReportApi } from 'src/api/letseatmanager/inventory/getGeneralInventoryReportApi';
import { Button } from 'src/components/Button';
import { ClockToolbarButton } from 'src/components/mui-datatables/ClockToolbarButton';
import { PerformCountingToolbarIcon } from 'src/components/mui-datatables/PerformCountingToolbarIcon';
import { RefreshToolbarButton } from 'src/components/mui-datatables/RefreshToolbarButton';
import { SecuredContent } from 'src/components/SecuredContent';
import { Table } from 'src/components/Table';
import { Tooltip } from 'src/components/Tooltip';
import { UpdatingContentProgress } from 'src/components/UpdatingContentProgress';
import { CostType, CostTypes } from 'src/constants/CostType';
import { RolePermissions } from 'src/constants/RolePermission';
import { translate } from 'src/i18n/translate';
import { CopyIcon } from 'src/icons/CopyIcon';
import { InfoIcon } from 'src/icons/InfoIcon';
import { SettingsNutIcon } from 'src/icons/SettingsNutIcon';
import { CopyRestaurantInventoryDialog } from 'src/scenes/letseatmanager/inventory/CopyRestaurantInventoryDialog';
import { InventoryKpi } from 'src/scenes/letseatmanager/inventory/inventory/InventoryKpi';
import { FilterCostType } from 'src/scenes/letseatmanager/restaurantDashboard/FilterCostType';
import { useFormatAsRestaurantCurrencyNumber } from 'src/services/restaurant/useFormatAsRestaurantCurrencyNumber';
import { KpiVm } from 'src/types/GeneralInventoryReportVm';
import { InventorySupplyVm } from 'src/types/InventorySupplyVm';
import { BuyUnitVm } from 'src/types/SupplyVm';
import { useLoadApi } from 'src/utils/react/useLoadApi';
import { useSelector } from 'src/utils/react/useSelector';
import { useUserHasRolePermission } from 'src/utils/react/useUserHasRolePermissions';
import { getSupplyCategoryName } from 'src/utils/supplyCategory/getSupplyCategoryName';

export function InventoryTable({ goToInventorySettings, goToInventoryCount, goToInventoryRecord }: Props): React.ReactElement {
    const classes = useStyles();
    const theme = useTheme();
    const formatAsCurrencyNumber = useFormatAsRestaurantCurrencyNumber();

    const [userHasRolePermission] = useUserHasRolePermission();

    const [supplies, setSupplies] = useState<Array<InventorySupplyVm>>([]);
    const [kpis, setKpis] = useState<Array<KpiVm>>([]);
    const [copyInventoryDialogState, setCopyInventoryDialogState] = useState<{ open: boolean; supplies: Array<InventorySupplyVm> | undefined }>({
        open: false,
        supplies: undefined,
    });

    const restaurantIds = useSelector((state) => state.app.restaurantIds);
    const costType = useSelector((state) => state.app2.filterReportsState.costType);
    const inventoryLinkedToRestaurantId = useSelector((state) => state.app.restaurant?.inventoryLinkedToRestaurantId);
    const restaurantsLinkedToInventory = useSelector((state) => state.app.restaurant?.restaurantsLinkedToInventory);

    const [loading, inventory, load] = useLoadApi(getGeneralInventoryReportApi, { restaurantIds }, { dependencies: [restaurantIds], initialValue: { supplies: [], kpis: [] } });

    const isSharedInventory = restaurantsLinkedToInventory || inventoryLinkedToRestaurantId;

    const linkedRestaurantNames = restaurantsLinkedToInventory?.map((restaurant: any) => restaurant.restaurantName).join(', ');

    useEffect(() => {
        if (inventory) {
            setSupplies(inventory.supplies);
            setKpisDependingOnUserPermissions(inventory.kpis);
        }
    }, [inventory]);

    const setKpisDependingOnUserPermissions = (kpis: Array<KpiVm>) => {
        if (userHasRolePermission(RolePermissions.INVENTORY_QUANTITY)) return setKpis(kpis);

        const filteredInventoryKpis = kpis?.filter((kpi: any) => kpi.label !== translate('Inventory Value'));
        setKpis(filteredInventoryKpis);
    };

    const handleOpenCopyRestaurantInventoryDialog = () => {
        setCopyInventoryDialogState({
            open: true,
            supplies,
        });
    };

    const handleCloseCopyRestaurantInventoryDialog = () => {
        setCopyInventoryDialogState({
            open: false,
            supplies: undefined,
        });
    };

    const commonColumns: Array<MUIDataTableColumn> = [
        {
            name: 'sku',
            label: translate('SKU'),
            options: {
                filter: false,
            },
        },
        {
            name: 'category',
            label: translate('Category'),
            options: {
                filter: true,
            },
        },
        {
            name: 'name',
            label: translate('Supply'),
            options: {
                filter: false,
                customBodyRender: (name: string) => {
                    const supply = supplies?.find((supply: InventorySupplyVm) => supply.name === name);
                    const isConsumable = supply?.isConsumable;

                    return (
                        <div className={classes.nameContainer}>
                            <span>{getSupplyCategoryName(name)}</span>
                            {/* @ts-ignore */}
                            {isConsumable && <Chip classes={{ chip: classes.consumable }}>{translate('Is consumable')}</Chip>}
                        </div>
                    );
                },
            },
        },
        {
            name: 'buyUnits',
            label: translate('Unit'),
            options: {
                filter: false,
                customBodyRender: (value: string) => <div style={{ minWidth: '120px' }}>{translate(`UnitOfMeasurements.${getMainUnit(value)}`)}</div>,
            },
        },
        {
            name: 'quantity',
            label: translate('Quantity'),
            options: {
                filter: false,
            },
        },
    ];

    const columns: Record<CostType, Array<MUIDataTableColumn>> = {
        [CostTypes.COST_BY_AVERAGE_WEIGHTED_COST]: [
            ...commonColumns,
            {
                name: 'unitValueByAverageWeightedCost',
                label: translate('Cost'),
                options: {
                    filter: false,
                    customBodyRender: (value: string) => <div style={{ minWidth: '120px' }}>{formatAsCurrencyNumber(value)}</div>,
                },
            },
            {
                name: 'totalValueByAverageWeightedCost',
                label: translate('Total'),
                options: {
                    filter: false,
                    customBodyRender: (value: string) => <div style={{ minWidth: '120px' }}>{formatAsCurrencyNumber(value)}</div>,
                },
            },
        ],
        [CostTypes.COST_BY_LAST_PURCHASE_COST]: [
            ...commonColumns,
            {
                name: 'unitValueByLastPurchaseCost',
                label: translate('Cost'),
                options: {
                    filter: false,
                    customBodyRender: (value: string) => <div style={{ minWidth: '120px' }}>{formatAsCurrencyNumber(value)}</div>,
                },
            },
            {
                name: 'totalValueByLastPurchaseCost',
                label: translate('Total'),
                options: {
                    filter: false,
                    customBodyRender: (value: string) => <div style={{ minWidth: '120px' }}>{formatAsCurrencyNumber(value)}</div>,
                },
            },
        ],
        [CostTypes.COST_FIXED]: [
            ...commonColumns,
            {
                name: 'unitValueByFixedCost',
                label: translate('Value'),
                options: {
                    filter: false,
                    customBodyRender: (value: string) => <div style={{ minWidth: '120px' }}>{formatAsCurrencyNumber(value)}</div>,
                },
            },
            {
                name: 'totalValueByFixedCost',
                label: translate('Total'),
                options: {
                    filter: false,
                    customBodyRender: (value: string) => <div style={{ minWidth: '120px' }}>{formatAsCurrencyNumber(value)}</div>,
                },
            },
        ],
    };

    const getMainUnit = (buyUnits: any) => {
        const mainUnit = buyUnits.find((buyUnit: BuyUnitVm) => buyUnit.isMainUnit === true);
        return mainUnit?.unit;
    };

    const handleOnDownload = (buildHead: (columns: any) => string, buildBody: (data: any) => string, columns: any, data: any): string | boolean => {
        const formatCell = (cell: any, columnName: any) => {
            if (typeof cell === 'object' && cell !== null) {
                switch (columnName) {
                    case 'name':
                        return cell.name;
                    case 'buyUnits':
                        return translate(`UnitOfMeasurements.${getMainUnit(cell)}`);
                    case 'unitValueByAverageWeightedCost':
                    case 'totalValueByAverageWeightedCost':
                    case 'unitValueByLastPurchaseCost':
                    case 'totalValueByLastPurchaseCost':
                    case 'unitValueByFixedCost':
                    case 'totalValueByFixedCost':
                        return formatAsCurrencyNumber(cell[columnName]);
                    default:
                        return JSON.stringify(cell);
                }
            }
            return cell;
        };

        const newData = data.map((row: { data: Array<any> }) => ({
            ...row,
            data: row.data.map((cell, index) => formatCell(cell, columns[index].name)),
        }));

        const csvContent = '\uFEFF' + buildHead(columns) + buildBody(newData);

        return csvContent;
    };

    const getKpiByCostType = (kpi: any) => {
        if (!kpi) return translate('No Data');
        switch (costType) {
            case CostTypes.COST_FIXED:
                return kpi?.inventoryValueByFixedCost || 0;
            case CostTypes.COST_BY_AVERAGE_WEIGHTED_COST:
                return kpi?.inventoryValueByAverageWeightedCost || 0;
            case CostTypes.COST_BY_LAST_PURCHASE_COST:
                return kpi?.inventoryValueByLastPurchaseCost || 0;
            default:
                return 0;
        }
    };

    return (
        <>
            {isSharedInventory && (
                <div className={classes.header}>
                    <Tooltip text={linkedRestaurantNames}>
                        <span className={classes.statusLabel}>
                            {translate('Shared with @numberOfRestaurants restaurants', { numberOfRestaurants: restaurantsLinkedToInventory?.length ?? 0 })}
                            <InfoIcon color={theme.palette.icons.brand} width={18} />
                        </span>
                    </Tooltip>
                </div>
            )}
            <div className={classes.inventoryTableContainer}>
                <UpdatingContentProgress loading={loading} bottom />
                <CopyRestaurantInventoryDialog open={copyInventoryDialogState.open} supplies={copyInventoryDialogState.supplies} onSuccess={load} onClose={handleCloseCopyRestaurantInventoryDialog} />
                <Table
                    data={supplies?.map((supply: InventorySupplyVm) => ({ ...supply, name: supply.name }))}
                    columns={columns[costType]}
                    title={<FilterCostType />}
                    options={{
                        responsive: 'standard',
                        tableBodyMaxHeight: '500px',
                        selectableRows: 'none',
                        filterType: 'checkbox',
                        rowsPerPage: 100,
                        filter: true,
                        print: false,
                        viewColumns: false,
                        onDownload: handleOnDownload,
                        customToolbar: () => (
                            <>
                                <SecuredContent rolePermission={RolePermissions.INVENTORY_RECORDS}>
                                    <ClockToolbarButton onClick={goToInventoryRecord} />
                                </SecuredContent>
                                <Button onClick={goToInventorySettings} icon classes={{ button: classes.button }}>
                                    <Tooltip text={translate('Settings')}>
                                        <SettingsNutIcon title='Inventory settings' />
                                    </Tooltip>
                                </Button>
                                <SecuredContent rolePermission={RolePermissions.UPDATE_INVENTORY}>
                                    <PerformCountingToolbarIcon tooltip={translate('Do Count')} onClick={goToInventoryCount} />
                                </SecuredContent>
                                <RefreshToolbarButton onClick={load} />
                                <SecuredContent rolePermission={RolePermissions.COPY_INVENTORY}>
                                    <Button classes={{ button: classes.button }} onClick={handleOpenCopyRestaurantInventoryDialog} icon text larger>
                                        <CopyIcon title='Copy Inventory' />
                                        {translate(`RolePermissions.${RolePermissions.COPY_INVENTORY}`)}
                                    </Button>
                                </SecuredContent>
                            </>
                        ),
                    }}
                    className={classes.table}
                />
                <div className={classes.kpiContainer}>
                    <InventoryKpi kpi={kpis[0]?.value} label={kpis[0]?.label} />
                    <InventoryKpi kpi={getKpiByCostType(kpis[1]?.value)} label={translate('Total')} userTypes={kpis[0]?.userTypes} />
                    {kpis[2]?.value && (
                        <InventoryKpi
                            kpi={''}
                            label={translate('Last Adjustment: @value por @lastCountUpdateMadeBy', {
                                value: (kpis[2]?.value as any).lastCountUpdateDate ? (kpis[2]?.value as any).lastCountUpdateDate : '',
                                lastCountUpdateMadeBy: (kpis[2]?.value as any).lastCountUpdateMadeBy,
                            })}
                            userTypes={kpis[0]?.userTypes}
                        />
                    )}
                </div>
            </div>
        </>
    );
}

const useStyles = makeStyles((theme) => ({
    table: {
        width: '98%',
    },
    inventoryTableContainer: {
        width: '100%',
        display: 'flex',
        flexDirection: 'row',
        position: 'relative',
    },
    kpiContainer: {
        height: '100%',
        width: '20%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        gap: 10,
    },
    header: {
        display: 'flex',
        justifyContent: 'space-between',
        margin: '20px 0',
        flexWrap: 'wrap',
        width: 'fit-content',
    },
    statusLabel: {
        fontFamily: theme.typography.semiBold,
        color: theme.palette.text.brand,
        padding: '4px 12px',
        borderRadius: 4,
        height: 'fit-content',
        backgroundColor: theme.palette.surface.brand,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        gap: 8,
    },
    button: {
        width: 'fit-content !important',
        padding: '0 17px',
        height: 50,
        '&:focus': {
            border: `1px solid ${theme.palette.border.primary}`,
        },
        '& svg': {
            margin: 0,
        },
    },
    consumable: {
        backgroundColor: theme.palette.surface.brand,
        fontSize: 14,
        color: theme.palette.text.brand,
        padding: '4px 12px',
    },
    nameContainer: {
        display: 'flex',
        gap: 10,
    },
}));

type Props = {
    goToInventorySettings: any;
    goToInventoryCount: any;
    goToInventoryRecord: any;
};
