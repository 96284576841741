import Autocomplete from '@material-ui/lab/Autocomplete';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { trim as _trim } from 'src/utils/string/trim';
import { upperCase as _upperCase } from 'src/utils/string/upperCase';

// TODO: remove @ts-ignores and fix types

/* @ts-ignore */
export const TransformedAutocompleteString: any = React.forwardRef(function TransformedAutocompleteString({ value, onChange, trim, upperCase, textFieldProps, ...props }, ref): React.ReactNode {
    const [inputValue, setInputValue] = useState(transformToInput(value)); // Separate form value from input value
    useEffect(() => {
        const oldValue = transformToOutput(inputValue, trim, upperCase);
        if (value !== oldValue) setInputValue(transformToInput(value));
    }, [value, trim]);
    return (
        /* @ts-ignore */
        <Autocomplete
            {...props}
            value={inputValue}
            onChange={(e, data) => {
                setInputValue(data as any);
                onChange(transformToOutput(data, trim, upperCase));
            }}
        />
    );
});

function transformToInput(value?: string | null): string {
    return value ?? '';
}

export function transformToOutput(value?: string | null, trim?: boolean, upperCase?: boolean): string | null | undefined {
    let outputValue = value;
    if (trim) outputValue = _trim(outputValue);
    if (upperCase) outputValue = _upperCase(outputValue);
    return !outputValue ? null : outputValue;
}
