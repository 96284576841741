import { Grid, makeStyles } from '@material-ui/core';
import { MUIDataTableColumn } from 'mui-datatables';
import * as React from 'react';
import { getCompoundSupplyProductionsApi } from 'src/api/letseatmanager/production/getCompoundSupplyProductionsApi';
import { Button } from 'src/components/Button';
import { Dialog } from 'src/components/Dialog';
import { DialogActions } from 'src/components/DialogActions';
import { Table } from 'src/components/Table';
import { translate } from 'src/i18n/translate';
import { useFormatAsRestaurantCurrencyNumber } from 'src/services/restaurant/useFormatAsRestaurantCurrencyNumber';
import type { CompoundSupplyReportId } from 'src/types/Id';
import { formatDateString } from 'src/utils/date/formatDateString';
import { createCompoundSuppliesProductionPdf } from 'src/utils/pdf/createCompoundSuppliesProductionPdf';
import { useLoadApi } from 'src/utils/react/useLoadApi';
import { useSelector } from 'src/utils/react/useSelector';

export function CompoundSupplyProductionDetailsDialog({ open, onClose, compoundSupplyReportId, disabled, date }: Props): React.ReactElement | null {
    const classes = useStyles();
    const formatAsCurrencyNumber = useFormatAsRestaurantCurrencyNumber();

    const restaurant = useSelector((state) => state.app.restaurant);

    const [loadingProductions, productions, load] = useLoadApi(
        getCompoundSupplyProductionsApi,
        { compoundSupplyReportId: compoundSupplyReportId! },
        { requiredValues: [compoundSupplyReportId], dependencies: [compoundSupplyReportId] },
    );

    const formatDateAsHour = (date: any) => {
        const dateTime = new Date(date);
        const hour = dateTime.getHours();
        const minutesWithZero = dateTime.getMinutes();
        const minutes = minutesWithZero < 10 ? `0${minutesWithZero}` : minutesWithZero;
        return `${hour}:${minutes}`;
    };

    if (!productions) return null;

    const columns: Array<MUIDataTableColumn> = [
        {
            name: 'supplyName',
            label: translate('Supply'),
        },
        {
            name: 'quantity',
            label: translate('Quantity/Unit'),
        },
        {
            name: 'fixedCost',
            label: translate('Cost'),
        },
        {
            name: 'hour',
            label: translate('Hour'),
        },
        {
            name: 'prod',
            label: '',
            options: {
                display: 'excluded',
                filter: false,
            },
        },
    ];

    return (
        <Dialog open={open} onClose={onClose} classes={{ dialog: classes.dialogContainer, title: classes.title }} title={`${translate('Productions')} ${formatDateString(date)} `}>
            <Grid container spacing={2} direction='column'>
                <Grid item className={classes.recipeSuppliesContainer}>
                    <Grid container>
                        <Grid item xs={12}>
                            <Table
                                columns={columns}
                                data={productions?.map((prod: any) => ({
                                    supplyName: prod.supplyName,
                                    quantity: prod.quantity + ' ' + translate(`UnitOfMeasurements.${prod.productionUnit}`),
                                    fixedCost: formatAsCurrencyNumber(prod.fixedCost),
                                    hour: formatDateAsHour(prod.createdAt),
                                    prod: prod,
                                }))}
                                options={{
                                    pagination: false,
                                    expandableRows: true,
                                    selectableRows: 'none',
                                    download: false,
                                    print: false,
                                    search: false,
                                    filter: false,
                                    viewColumns: false,
                                    filterType: 'dropdown',
                                    responsive: 'standard',
                                    rowsExpanded: [0, 1],
                                    renderExpandableRow: (prod: any, rowMeta: any) => {
                                        const ingredients = prod[4].ingredients;
                                        return (
                                            <>
                                                {ingredients?.map((supply: any, index: number) => (
                                                    <tr key={index} className={classes.tableRow}>
                                                        <td className={classes.tableCell}></td>
                                                        <td className={classes.tableCell}>{supply.supplyName}</td>
                                                        <td className={classes.tableCell}>{`${supply.quantity} ${translate(`UnitOfMeasurements.${supply.unit}`)}`}</td>
                                                        <td className={classes.tableCell}>{formatAsCurrencyNumber(supply.fixedCost)}</td>
                                                    </tr>
                                                ))}
                                            </>
                                        );
                                    },
                                }}
                                className={classes.table}
                            />
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>

            <DialogActions className={classes.dialogActions}>
                <Button onClick={onClose} secondary disabled={disabled}>
                    {translate('Close')}
                </Button>
                <Button onClick={() => createCompoundSuppliesProductionPdf({ productions, date, restaurant })} disabled={disabled}>
                    {translate('Download')}
                </Button>
            </DialogActions>
        </Dialog>
    );
}

const useStyles = makeStyles((theme) => ({
    tableCell: {
        textAlign: 'left',
        fontFamily: theme.typography.regular,
        fontSize: 14,
        paddingLeft: 16,
        backgroundColor: '#F5F5F5',
    },
    dialogContainer: {
        width: 'auto',
        maxHeight: '95%',
        '& .MuiPaper-root': {
            width: '100%',
            height: '100%',
            maxWidth: 800,
            maxHeight: '95%',
        },
        padding: 30,
        minWidth: 800,
    },
    recipeSuppliesContainer: {
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        padding: '0px 10px 10px 10px',
        border: 0,
        borderBottom: '1px solid #D9D9D9',
        backgroundColor: 'transparent',
        cursor: 'pointer',
        gap: 10,
    },
    dialogActions: {
        width: '100%',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        padding: '20px 10px 20px 10px',
        marginTop: 50,
        justifyContent: 'flex-end',
    },
    title: {
        fontFamily: theme.typography.semiBold,
        fontSize: 18,
        color: theme.palette.primary.main,
        textAlign: 'left',
        margin: 0,
    },
    tableRow: {
        paddingLeft: 20,
    },
    table: {
        '& tbody tr:hover': {
            cursor: 'pointer',
            backgroundColor: '#F5F5F5',
        },
        '& .MuiTableCell-root': {
            border: 0,
            cursor: 'pointer',
            padding: '16px 16px',
            fontWeight: 600,
        },
        '& .MuiTableCell-head': {
            fontFamily: theme.typography.semiBold,
            fontSize: 14,
            color: '#2E3748',
        },
        '& .MuiTableCell-body': {
            fontFamily: theme.typography.regular,
            fontSize: 14,
            color: '#2E3748',
        },
        '& .MuiTableCell-body:nth-child(1)': {
            width: '1%',
        },
        '& .MuiTableCell-body:nth-child(2)': {
            width: '20%',
        },
        '& .MuiTableCell-body:nth-child(3)': {
            width: '20%',
        },
        '& .MuiTableCell-body:nth-child(4)': {
            width: '10%',
        },
    },
}));

type Props = {
    open: boolean;
    onClose: any;
    compoundSupplyReportId?: CompoundSupplyReportId;
    disabled?: boolean;
    date?: string;
};
