import { makeStyles } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { findBrandsApi } from 'src/api/letseatadmin/brand/findBrandsApi';
import { removeBrandApi } from 'src/api/letseatadmin/brand/removeBrandApi';
import { app2 } from 'src/app2';
import { ChangeToolbarButton } from 'src/components/mui-datatables/ChangeToolbarButton';
import { CreateToolbarButton } from 'src/components/mui-datatables/CreateToolbarButton';
import { RefreshToolbarButton } from 'src/components/mui-datatables/RefreshToolbarButton';
import { RemoveToolbarButton } from 'src/components/mui-datatables/RemoveToolbarButton';
import { PasswordProtectedCell } from 'src/components/PasswordProtectedCell';
import { Table } from 'src/components/Table';
import { UpdatingContentProgress } from 'src/components/UpdatingContentProgress';
import { translate } from 'src/i18n/translate';
import { ChangeBrandDialog } from 'src/scenes/letseatadmin/brand/ChangeBrandDialog';
import { CreateBrandDialog } from 'src/scenes/letseatadmin/brand/CreateBrandDialog';
import type { BrandVm } from 'src/types/BrandVm';
import type { BrandId } from 'src/types/Id';
import { alertKnownErrorOrSomethingWentWrong } from 'src/utils/alert/alertKnownErrorOrSomethingWentWrong';
import { formatDateTimeString } from 'src/utils/date/formatDateTimeString';
import { useAction } from 'src/utils/react/useAction';
import { openBrandPage } from 'src/utils/window/openBrandPage';

export function BrandsPage(): React.ReactElement {
    const classes = useStyles();

    const [loading, setLoading] = useState(false);
    const [vm, setVm] = useState({ brands: [] } as {
        brands: Array<BrandVm>;
    });
    const [createBrandDialogState, setCreateBrandDialogState] = useState({ open: false });
    const [changeBrandDialogState, setChangeBrandDialogState] = useState<{ open: boolean; brandId: BrandId | undefined }>({ open: false, brandId: undefined });

    const setTitle = useAction(app2.actions.setTitle);

    useEffect(() => {
        setTitle(translate('Brands'));
        load();
    }, []);

    const load = async () => {
        setLoading(true);
        const response = await findBrandsApi();
        setLoading(false);
        if (!response.ok) {
            alertKnownErrorOrSomethingWentWrong(response);
            return;
        }
        setVm(response.data);
    };

    const removeBrands = async (brandIds: Array<BrandId>) => {
        setLoading(true);
        for (const brandId of brandIds) {
            const response = await removeBrandApi({ brandId });
            if (!response.ok) {
                setLoading(false);
                alertKnownErrorOrSomethingWentWrong(response);
                await load();
                return;
            }
        }
        await load();
    };

    const handleClickNew = () => {
        setCreateBrandDialogState({ open: true });
    };

    return (
        <div className={classes.container}>
            <UpdatingContentProgress loading={loading} />
            <CreateBrandDialog open={createBrandDialogState.open} onClose={() => setCreateBrandDialogState({ open: false })} onBrandCreated={load} />
            <ChangeBrandDialog
                open={changeBrandDialogState.open}
                brandId={changeBrandDialogState.brandId}
                onClose={() => setChangeBrandDialogState({ open: false, brandId: undefined })}
                onChangeBrand={load}
            />
            <Grid container spacing={1}>
                <Grid item xs={12}>
                    <Table
                        className={classes.table}
                        data={vm.brands?.map((brand: BrandVm) => {
                            return {
                                brandId: brand.brandId,
                                brandName: brand.brandName,
                                restaurantIds: brand.restaurantIds,
                                restaurantNames: brand.restaurantNames,
                                comment: brand.comment,
                                username: brand.username,
                                password: brand.password,
                                createdAt: formatDateTimeString(brand.createdAt),
                                modifiedAt: formatDateTimeString(brand.modifiedAt),
                            };
                        })}
                        columns={[
                            {
                                name: 'brandId',
                                label: '',
                                options: {
                                    display: 'excluded',
                                    filter: false,
                                },
                            },
                            {
                                name: 'brandName',
                                label: translate('Brand Name'),
                                options: {
                                    filter: false,
                                },
                            },
                            {
                                name: 'restaurantNames',
                                label: translate('Restaurants'),
                                options: {
                                    filter: false,
                                    customBodyRender: (value, tableMeta, updateValue) => value.map((v: any) => <div>{v}</div>),
                                },
                            },
                            {
                                name: 'comment',
                                label: translate('Comment'),
                                options: {
                                    filter: false,
                                    searchable: true,
                                },
                            },
                            {
                                name: 'username',
                                label: translate('Username'),
                                options: {
                                    filter: false,
                                },
                            },
                            {
                                name: 'password',
                                label: translate('Password'),
                                options: {
                                    filter: false,
                                    customBodyRender: (password) => (
                                        <PasswordProtectedCell password={'5265'}>
                                            <span>{password}</span>
                                        </PasswordProtectedCell>
                                    ),
                                },
                            },
                            {
                                name: 'createdAt',
                                label: translate('Created'),
                                options: {
                                    filter: false,
                                    searchable: false,
                                },
                            },
                            {
                                name: 'modifiedAt',
                                label: translate('Modified'),
                                options: {
                                    filter: false,
                                    searchable: false,
                                },
                            },
                        ]}
                        options={{
                            responsive: 'standard',
                            tableBodyMaxHeight: '500px',
                            selectableRows: 'multiple',
                            filterType: 'checkbox',
                            rowsPerPage: 100,
                            onDownload: (buildHead, buildBody, columns, data) => {
                                return '\uFEFF' + buildHead(columns) + buildBody(data);
                            },
                            customToolbar: () => (
                                <>
                                    <RefreshToolbarButton onClick={load} />
                                    <CreateToolbarButton onClick={handleClickNew} />
                                </>
                            ),
                            customToolbarSelect: (selectedRows) => {
                                const selectedBrands: Array<BrandVm> = selectedRows.data.map((row) => vm.brands[row.dataIndex]);
                                const selectedBrandIds = selectedBrands.map((s) => s.brandId);
                                return (
                                    <div className={classes.toolbar}>
                                        {selectedBrandIds.length === 1 && (
                                            <ChangeToolbarButton
                                                onClick={() => {
                                                    if (selectedBrandIds.length === 1) setChangeBrandDialogState({ open: true, brandId: selectedBrandIds[0] });
                                                }}
                                            />
                                        )}
                                        <RemoveToolbarButton
                                            onClick={async () => {
                                                const remove = window.confirm(
                                                    selectedBrandIds.length === 1
                                                        ? 'Are you sure you want to remove the selected brands'
                                                        : `Are you sure you want to remove the selected ${selectedBrandIds.length} brands`,
                                                );
                                                if (remove) {
                                                    await removeBrands(selectedBrandIds);
                                                }
                                            }}
                                        />
                                    </div>
                                );
                            },
                            onRowClick: (
                                rowData: Array<string>,
                                rowMeta: {
                                    dataIndex: number;
                                },
                            ) => {
                                const brandId = vm.brands[rowMeta.dataIndex].brandId;
                                openBrandPage(brandId);
                            },
                            // filterList: [[], [], [], [], ['MEXICO']],
                        }}
                    />
                </Grid>
            </Grid>
        </div>
    );
}

const useStyles = makeStyles((theme) => ({
    container: {
        position: 'relative',
    },
    table: {
        whiteSpace: 'nowrap',
        '& tr': { cursor: 'pointer' },
        '& tr:hover': { backgroundColor: 'rgba(0, 0, 0, 0.07) !important' },
    },
    toolbar: {
        paddingRight: theme.spacing(3),
    },
}));
