import { letseatmanagerApiMethod } from 'src/api/letseatmanagerApi';
import type { ApiSauceResponse } from 'src/api/types/ApiSauceResponse';
import { UnitOfMeasurement } from 'src/constants/UnitOfMeasurement';
import type { RestaurantId } from 'src/types/Id';

export async function importSuppliesApi(request: ImportSuppliesApiRequest): ApiSauceResponse<void> {
    return letseatmanagerApiMethod('supply/importSuppliesApi', request);
}

type ImportSuppliesApiRequest = {
    restaurantId: RestaurantId;
    importedSupplies: Array<{
        supplyCategory: string;
        supplier?: string;
        name: string;
        sellUnit: UnitOfMeasurement;
        buyUnits: Array<{
            unit: UnitOfMeasurement;
            conversionFactor: number;
            isMainUnit?: boolean;
        }>;
        sku?: string;
        waste?: string;
        fixedCost?: string;
    }>;
};
