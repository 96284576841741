import * as React from 'react';
import { UpdatingContentProgress } from 'src/components/UpdatingContentProgress';
import type { OfficeDeliveryItemVm } from 'src/scenes/letseatadmin/OfficeDeliveryPage';
import DeliveryItemsMuiDataTable from 'src/scenes/letseatdriver/deliveryItems/DeliveryItemsMuiDataTable';

export default class OfficeDeliveryItemsTable extends React.PureComponent<Props, State> {
    render(): React.ReactElement | null {
        if (!this.props.officeDeliveryItems) {
            return null;
        }
        return (
            <div style={{ position: 'relative' }}>
                <UpdatingContentProgress loading={this.props.loading} />
                <DeliveryItemsMuiDataTable title={this.props.title} officeDeliveryItems={this.props.officeDeliveryItems} onClickRefresh={this.props.onClickRefresh} />
            </div>
        );
    }
}

type Props = {
    loading: boolean;
    title?: string;
    officeDeliveryItems: Array<OfficeDeliveryItemVm>;
    onClickRefresh: any;
};

type State = Record<any, any>;
