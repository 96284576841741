import { makeStyles } from '@material-ui/core';
import moment from 'moment';
import * as React from 'react';
import { Tooltip } from 'src/components/Tooltip';
import { translate } from 'src/i18n/translate';
import { ClockOutEmployeeButton } from 'src/scenes/letseatmanager/posCashRegister/ClockOutEmployeeButton';
import { LocalizationService } from 'src/services/LocalizationService';
import type { RestaurantEmployeeDayShiftVm } from 'src/types/RestaurantEmployeeDayShiftVm';
import { useClock } from 'src/utils/react/useClock';
import { useSelector } from 'src/utils/react/useSelector';

export function EmployeeClockedInCard({ employeeDayShift, onChange }: Props): React.ReactElement {
    const classes = useStyles();
    const [, date] = useClock();

    const timeZone = useSelector((state) => state.app.restaurant.timeZone);
    const workedHours = Math.abs(moment(employeeDayShift?.clockedInAt).diff(date, 'hours')).toString();

    return (
        <article className={classes.card}>
            <div className={classes.infoContainer}>
                <div className={classes.headerText}>{translate('Name')}</div>
                <div className={classes.contentText}>{employeeDayShift.username}</div>
            </div>
            <div className={classes.infoContainer}>
                <div className={classes.headerText}>{translate('Role')}</div>
                <div className={classes.contentText}>{employeeDayShift.roleName}</div>
            </div>
            <div className={classes.infoContainer}>
                <div className={classes.headerText}>{translate('Worked hours')}</div>
                <div className={classes.contentText}>{<Tooltip text={translate('Hours considering the current time')}>{workedHours}</Tooltip>}</div>
            </div>
            <div className={classes.infoContainer}>
                <div className={classes.headerText}>{translate('Clock in at')}</div>
                <div className={classes.contentText}>{LocalizationService.formatDateTimeAsTime(employeeDayShift.clockedInAt, timeZone)}</div>
            </div>
            <div className={classes.inputContainer}>
                <ClockOutEmployeeButton employeeDayShift={employeeDayShift} onChange={onChange} />
            </div>
        </article>
    );
}

const useStyles = makeStyles((theme) => ({
    card: {
        width: '100%',
        borderRadius: 8,
        border: '1px solid #D9D9D9',
        padding: '10px',
        fontFamily: theme.typography.regular,
        display: 'flex',
        flexDirection: 'column',
        [theme.breakpoints.up('sm')]: {
            display: 'grid',
            gridTemplateColumns: 'repeat(2,1fr)',
        },
        [theme.breakpoints.up('md')]: {
            display: 'none',
        },
    },

    contentText: {
        color: theme.palette.text.primary,
        fontSize: 16,
    },
    headerText: {
        color: theme.palette.text.secondary,
        fontSize: 12,
    },
    infoContainer: {
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        margin: 5,
    },
    inputContainer: {
        display: 'flex',
        alignItems: 'center',
    },
}));

type Props = {
    employeeDayShift: RestaurantEmployeeDayShiftVm;
    onChange?: any;
};
