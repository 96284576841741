import { useState } from 'react';
import * as React from 'react';
import { Button } from 'src/components/Button';
import { translate } from 'src/i18n/translate';
import { ChangeDynamicDeliveryEarningsDialog } from 'src/scenes/letseatadmin/dynamicDeliveryEarnings/ChangeDynamicDeliveryEarningsDialog';
import type { OrderVm } from 'src/types/OrderVm';
import { isAcceptedOrder } from 'src/utils/order/isAcceptedOrder';
import { isDeliveryOrder } from 'src/utils/order/isDeliveryOrder';
import { isPideDirectoDelivery } from 'src/utils/order/isPideDirectoDelivery';
import { useSelector } from 'src/utils/react/useSelector';

export function ChangeOrderDynamicEarningsButton({ order, disabled, onSuccess }: Props): React.ReactElement | null {
    const [changeDynamicDeliveryEarningsDialogState, setChangeDynamicDeliveryEarningsDialogState] = useState({ open: false });

    const internalUser = useSelector((state) => state.authentication.internalUser);

    const shouldRenderButton = internalUser && isDeliveryOrder(order.orderType) && isPideDirectoDelivery(order) && isAcceptedOrder(order.orderStatus);

    const closeChangeDynamicDeliveryEarningsDialog = () => setChangeDynamicDeliveryEarningsDialogState({ open: false });

    const openChangeDynamicDeliveryEarningsDialog = () => setChangeDynamicDeliveryEarningsDialogState({ open: true });

    if (!shouldRenderButton) return null;

    return (
        <div>
            <ChangeDynamicDeliveryEarningsDialog
                open={changeDynamicDeliveryEarningsDialogState.open}
                orderId={order.orderId}
                onClose={closeChangeDynamicDeliveryEarningsDialog}
                onSuccess={onSuccess}
            />
            <Button secondary onClick={openChangeDynamicDeliveryEarningsDialog} disabled={disabled}>
                {translate('Change Dynamic Earning')}
            </Button>
        </div>
    );
}

type Props = {
    order: OrderVm;
    disabled?: boolean;
    onSuccess?: any;
};
