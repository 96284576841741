import { Divider, makeStyles } from '@material-ui/core';
import QRCode from 'qrcode.react';
import * as React from 'react';
import { translate } from 'src/i18n/translate';
import type { InstructionPrinter } from 'src/services/printer/printers/interfaces/InstructionPrinter';
import { PrintColumn, type PrinterInstruction } from 'src/services/printer/types/PrinterInstruction';
import type { PrinterVm } from 'src/types/PrinterVm';
import { classNames } from 'src/utils/react/classNames';
import { normalizeToAsciiCharacters } from 'src/utils/string/normalizeToAsciiCharacters';

export class ReactComponentPrinter {
    static print(printerInstructions: Array<PrinterInstruction>, printer?: PrinterVm): React.ReactNode {
        const reactComponentPrinter = new ReactComponentPrinterImplementation(printer);

        // Add all printer instructions
        for (const printerInstruction of printerInstructions) {
            try {
                (reactComponentPrinter as any)[printerInstruction.instructionType](...printerInstruction.params);
            } catch (e: any) {
                console.log(`instructionType ${printerInstruction.instructionType} not implemented by ReactComponentPrinterImplementation`);
                alert(`instructionType ${printerInstruction.instructionType} not implemented by ReactComponentPrinterImplementation`);
            }
        }

        return reactComponentPrinter.print();
    }
}

class ReactComponentPrinterImplementation implements InstructionPrinter {
    #printer;
    #components: Array<any> = [];

    constructor(printer?: PrinterVm) {
        this.#printer = printer;
    }

    print(): React.ReactNode {
        return <Print components={this.#components} />;
    }

    addText(text: string): void {
        this.#components.push(<Text text={text} />);
    }
    setLargerFont(isLargerFont: boolean): void {
        this.#components.push(<SetLargerFont isLargerFont={isLargerFont} />);
    }
    setRegularFont(): void {
        this.#components.push(<SetRegularFont />);
    }
    addBoldText(text: string): void {
        this.#components.push(<BoldText text={text} />);
    }
    addCenteredText(text: string): void {
        this.#components.push(<CenteredText text={text} />);
    }
    addCenteredBoldText(text: string): void {
        this.#components.push(<CenteredBoldText text={text} />);
    }
    addRightText(text: string): void {
        this.#components.push(<RightText text={text} />);
    }
    addBoldRightText(text: string): void {
        this.#components.push(<BoldRightText text={text} />);
    }
    addSeparatedTexts(leftText: string, rightText: string): void {
        this.#components.push(<SeparatedTexts leftText={leftText} rightText={rightText} />);
    }
    addSeparatedBoldLeftTexts(leftText: string, rightText: string): void {
        this.#components.push(<SeparatedBoldLeftTexts leftText={leftText} rightText={rightText} />);
    }
    addBoldSeparatedTexts(leftText: string, rightText: string): void {
        this.#components.push(<BoldSeparatedTexts leftText={leftText} rightText={rightText} />);
    }
    addColumns(columns: Array<PrintColumn>): void {
        this.#components.push(<Columns columns={columns} />);
    }
    addBoldColumns(columns: Array<PrintColumn>): void {
        this.#components.push(<BoldColumns columns={columns} />);
    }
    addLogoImage(url: string): void {
        this.#components.push(<LogoImage url={url} />);
    }
    addQrCode(url: string): void {
        this.#components.push(<QrCode url={url} />);
    }
    addNewLine(): void {
        this.#components.push(<NewLine />);
    }
    addCenteredUnderLine(): void {
        this.#components.push(<CenteredUnderLine />);
    }
    addLineSeparator(): void {
        this.#components.push(<LineSeparator />);
    }
    openCashbox(): void {
        this.#components.push(<Cashbox />);
    }
}

function Print({ components }: { components: Array<React.ReactNode> }) {
    const classes = useStyles();
    return (
        <div className={classes.printPaper}>
            <div className={classes.container}>{components.map((c) => c)}</div>
        </div>
    );
}

function Text({ text }: { text: string }) {
    const classes = useStyles();
    return <div className={classes.text}>{normalizeCharacters(text)}</div>;
}

function BoldText({ text }: { text: string }) {
    const classes = useStyles();
    return <div className={classes.boldText}>{normalizeCharacters(text)}</div>;
}

function CenteredText({ text }: { text: string }) {
    const classes = useStyles();
    return <div className={classes.centeredText}>{normalizeCharacters(text)}</div>;
}

function CenteredBoldText({ text }: { text: string }) {
    const classes = useStyles();
    return <div className={classNames(classes.centeredText, classes.boldText)}>{normalizeCharacters(text)}</div>;
}

function RightText({ text }: { text: string }) {
    const classes = useStyles();
    return <div className={classes.rightText}>{normalizeCharacters(text)}</div>;
}

function BoldRightText({ text }: { text: string }) {
    const classes = useStyles();
    return <div className={classNames(classes.rightText, classes.boldText)}>{normalizeCharacters(text)}</div>;
}

function SeparatedTexts({ leftText, rightText }: { leftText: string; rightText: string }) {
    const classes = useStyles();
    return (
        <div className={classes.separatedTexts}>
            <span>{normalizeCharacters(leftText)}</span>
            <span>{normalizeCharacters(rightText)}</span>
        </div>
    );
}

function SeparatedBoldLeftTexts({ leftText, rightText }: { leftText: string; rightText: string }) {
    const classes = useStyles();
    return (
        <div className={classes.separatedTexts}>
            <span className={classes.boldText}>{normalizeCharacters(leftText)}</span>
            <span>{normalizeCharacters(rightText)}</span>
        </div>
    );
}

function BoldSeparatedTexts({ leftText, rightText }: { leftText: string; rightText: string }) {
    const classes = useStyles();
    return (
        <div className={classes.separatedTexts}>
            <span className={classes.boldText}>{normalizeCharacters(leftText)}</span>
            <span className={classes.boldText}>{normalizeCharacters(rightText)}</span>
        </div>
    );
}

function Columns({ columns }: { columns: Array<PrintColumn> }) {
    const classes = useStyles();
    return (
        <div className={classes.columns} style={{ gridTemplateColumns: columns.map((c) => `${c.percentageWidth * 100}%`).join(' ') }}>
            {columns.map((column, index) => (
                <div style={{ textAlign: column.textAlign ?? (index === 0 ? 'left' : 'right') }}>{normalizeCharacters(column.text!)}</div>
            ))}
        </div>
    );
}

function BoldColumns({ columns }: { columns: Array<PrintColumn> }) {
    const classes = useStyles();
    return (
        <div className={classes.columns} style={{ gridTemplateColumns: columns.map((c) => `${c.percentageWidth * 100}%`).join(' ') }}>
            {columns.map((column, index) => (
                <span className={classes.boldText} style={{ textAlign: column.textAlign ?? (index === 0 ? 'left' : 'right') }}>
                    {normalizeCharacters(column.text!)}
                </span>
            ))}
        </div>
    );
}

function LogoImage({ url }: { url: string }) {
    const classes = useStyles();
    return (
        <figure>
            <img className={classes.logoImage} src={url}></img>
        </figure>
    );
}

function QrCode({ url }: { url: string }) {
    const classes = useStyles();
    return (
        <div className={classes.qrCode}>
            <QRCode size={200} value={url} />
        </div>
    );
}

function NewLine() {
    const classes = useStyles();
    return <div className={classes.newLine} />;
}

function CenteredUnderLine() {
    const classes = useStyles();
    return <Divider classes={{ root: classes.centeredUnderLine }} />;
}

function LineSeparator() {
    const classes = useStyles();
    return <Divider classes={{ root: classes.lineSeparator }} />;
}

function Cashbox() {
    const classes = useStyles();
    return <div className={classNames(classes.cashbox, classes.centeredText)}>--- {translate('opens your cash drawer')} ---</div>;
}

function SetLargerFont({ isLargerFont }: { isLargerFont: boolean }) {
    const classes = useStyles();
    return <div className={classNames(classes.cashbox, classes.centeredText)}>--- {`${translate('set larger font')}: ${isLargerFont?.toString()}`} ---</div>;
}

function SetRegularFont() {
    const classes = useStyles();
    return <div className={classNames(classes.cashbox, classes.centeredText)}>--- {`${translate('set regular font')}:`} ---</div>;
}

function normalizeCharacters(text: string): string {
    // normalize spanish characters to ascii characters since it causes some issues on some printers,
    // in future try to set the character encoding on printers to add support for spanish characters
    return normalizeToAsciiCharacters(text);
}

const useStyles = makeStyles((theme) => ({
    printPaper: {
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        padding: '42px 24px',
        background: '#FFF',
        border: '1px solid #d9d9d9',
        borderRadius: 12,
        color: 'black',
        fontSize: 12,
        fontFamily: theme.typography.regular,
        [theme.breakpoints.up('md')]: {
            fontSize: 14,
        },
    },
    container: {
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
    },
    text: {
        alignSelf: 'flex-start',
    },
    centeredText: {
        alignSelf: 'center',
    },
    rightText: {
        alignSelf: 'flex-end',
    },
    boldText: {
        fontFamily: theme.typography.semiBold,
    },
    separatedTexts: {
        width: '100%',
        display: 'flex',
        justifyContent: 'space-between',
    },
    columns: {
        display: 'grid',
    },
    logoImage: {
        objectFit: 'contain',
        width: '100%',
        maxWidth: 280,
    },
    qrCode: {
        marginTop: 20,
        marginBottom: 20,
        height: 'auto',
        margin: '0 auto',
    },
    newLine: {
        marginBottom: 19,
    },
    centeredUnderLine: {
        margin: '40px',
        marginTop: '20px',
        marginBottom: '4px',
    },
    lineSeparator: {
        margin: '10px 0',
    },
    cashbox: {
        color: 'lightgrey',
    },
}));

type Props = Record<any, any>;
