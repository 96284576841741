import { makeStyles } from '@material-ui/core';
import * as React from 'react';
import { Button } from 'src/components/Button';
import { translate } from 'src/i18n/translate';
import { useChargeOrder } from 'src/services/pos/useChargeOrder';
import { isEatHereOrder } from 'src/utils/order/isEatHereOrder';
import { findItemsChangedInOrder } from 'src/utils/pos/findItemsChangedInOrder';
import { useSelector } from 'src/utils/react/useSelector';

export function PosChargeOrderButton(): React.ReactElement | null {
    const classes = useStyles();

    const { handlePay } = useChargeOrder();

    const pendingOrder = useSelector((state) => state.pos.pendingOrder);
    const orderType = useSelector((state) => state.pos.orderType);
    const hasPendingOrder = useSelector((state) => !!state.pos.pendingOrder);
    const acceptManuallyAllOrdersEnabled = useSelector((state) => state.app.restaurant?.acceptManuallyAllOrdersEnabled);

    const total = useSelector((state) => state.pos.payment?.total);
    const items = useSelector((state) => state.pos.items);
    const cancelledItems = useSelector((state) => state.pos.cancelledItems);

    const itemsToPrint = findItemsChangedInOrder(pendingOrder?.orderItems ?? ([] as any), items);
    const showChargeAndCloseOption = isEatHereOrder(orderType) && !hasPendingOrder && !acceptManuallyAllOrdersEnabled;
    const disabledButtons = !total && (!itemsToPrint?.length || !cancelledItems.length);

    if (!showChargeAndCloseOption) return null;

    return (
        <Button classes={{ button: classes.basicButton }} secondary disabled={disabledButtons} onClick={() => handlePay()}>
            {translate('Charge and close')}
        </Button>
    );
}

const useStyles = makeStyles((theme) => ({
    basicButton: {
        width: '100%',
        height: 50,
        color: theme.palette.text.secondary,
        '&:hover': {
            cursor: 'pointer',
        },
    },
}));
