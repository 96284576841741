import * as React from 'react';
import { DownloadCsvToolbarButton } from 'src/components/mui-datatables/DownloadCsvToolbarButton';
import { Table } from 'src/components/Table';
import { translate } from 'src/i18n/translate';
import type { CashRegisterCancelledOrderReport } from 'src/types/CashRegisterReportVm';
import { objectsToCsv } from 'src/utils/csv/objectsToCsv';
import { downloadTextInFile } from 'src/utils/html/downloadTextInFile';
import { toShortId } from 'src/utils/uuid/toShortId';

export function CashRegisterCancelledOrdersTableReport({ cancelledOrdersByCashRegister }: Props): React.ReactElement {
    const downloadReports = () => {
        const cashRegisterCancelledOrdersReport = cancelledOrdersByCashRegister.map((cashRegisterCancelledOrdersReport) => ({
            id: toShortId(cashRegisterCancelledOrdersReport.cashRegisterId),
            reason: translate(`OrderRejectReasons.${cashRegisterCancelledOrdersReport.orderRejectReason}`),
            orders: cashRegisterCancelledOrdersReport.orderVolume,
        }));
        const csv = objectsToCsv(cashRegisterCancelledOrdersReport);
        downloadTextInFile(csv, 'cancelled-orders-report.csv');
    };

    return (
        <Table
            data={cancelledOrdersByCashRegister.map((cashRegisterCancelledOrdersReport) => ({
                shortCashRegisterId: toShortId(cashRegisterCancelledOrdersReport.cashRegisterId),
                orderRejectReason: translate(`OrderRejectReasons.${cashRegisterCancelledOrdersReport.orderRejectReason}`),
                orderVolume: cashRegisterCancelledOrdersReport.orderVolume,
            }))}
            columns={[
                {
                    name: 'shortCashRegisterId',
                    label: translate('Cash register id'),
                    options: {
                        filter: false,
                    },
                },
                {
                    name: 'orderRejectReason',
                    label: translate('Reason'),
                    options: {
                        filter: false,
                    },
                },
                {
                    name: 'orderVolume',
                    label: translate('Orders'),
                    options: {
                        filter: false,
                    },
                },
            ]}
            options={{
                responsive: 'standard',
                selectableRows: 'none',
                filter: false,
                search: false,
                download: false,
                print: false,
                rowsPerPage: 10,
                customToolbar: () => (
                    <>
                        <DownloadCsvToolbarButton onClick={downloadReports} />
                    </>
                ),
            }}
        />
    );
}

type Props = {
    cancelledOrdersByCashRegister: Array<CashRegisterCancelledOrderReport>;
};
