import * as React from 'react';

export function ReloadIcon({ color, title, width, height, strokeWidth }: Props): React.ReactElement {
    return (
        <svg xmlns='http://www.w3.org/2000/svg' width={width || 18} height={height || 16} viewBox='0 0 18 16' fill='none'>
            {title && <title>{title}</title>}
            <path
                d='M12.3472 5.7938H16.5L13.8537 3.14586C13.0018 2.29389 11.9405 1.68121 10.7767 1.36942C9.61287 1.05764 8.38748 1.05773 7.2237 1.36969C6.05992 1.68165 4.99878 2.29448 4.14694 3.14658C3.2951 3.99868 2.6826 5.06002 2.371 6.22389M1.50083 14.359V10.2062M1.50083 10.2062H5.65368M1.50083 10.2062L4.14628 12.8541C4.99824 13.7061 6.05948 14.3188 7.2233 14.6306C8.38712 14.9424 9.61252 14.9423 10.7763 14.6303C11.9401 14.3184 13.0012 13.7055 13.8531 12.8534C14.7049 12.0013 15.3174 10.94 15.629 9.77611M16.5 1.64095V5.79214'
                stroke={color || 'currentColor'}
                strokeWidth={strokeWidth ?? '1.5'}
                strokeLinecap='round'
                strokeLinejoin='round'
            />
        </svg>
    );
}

type Props = {
    color?: string;
    title?: string;
    strokeWidth?: number;
    width?: number;
    height?: number;
};
