import { letseatmanagerApiMethod } from 'src/api/letseatmanagerApi';
import type { ApiSauceResponse } from 'src/api/types/ApiSauceResponse';
import type { CashRegisterCustomReason, RestaurantId } from 'src/types/Id';

export async function addCashRegisterWithdrawReasonApi(request: AddCashRegisterWithdrawReasonApiRequest): ApiSauceResponse<void> {
    return letseatmanagerApiMethod('restaurant/addCashRegisterWithdrawReasonApi', request);
}

export type AddCashRegisterWithdrawReasonApiRequest = {
    restaurantId: RestaurantId;
    reason: CashRegisterCustomReason;
};
