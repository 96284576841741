import { makeStyles } from '@material-ui/core';
import { useEffect, useRef, useState } from 'react';
import * as React from 'react';
import { classNames } from 'src/utils/react/classNames';

/**
 * @param {React.Node} children - content inside the button
 * @param {boolean} [hideBackdrop] - boolean to display backdrop with backgroundColor 0,0,0, 0.4 or transparent, by default is false
 * @param {boolean} [open] - boolean to set initial open state
 * @param {Object} [classes] - classes object to override the default styles
 * @param {string} [classes.puller] - class to override puller style
 * @param {boolean} [avoidSwipeInDrawerContent] - handle onMove action only when top of drawer detect event move
 * @param {string} [classes.drawer] - class to override drawer style
 * @param {string} [classes.drawerContent] - class to override drawerContent style
 * @param {onClose} [onClose] - function to be called when the drawer is opened
 * @param {onClose} [onOpen] - function to be called when the drawer is closed
 * @returns {React.Node}
 */

export function SwipeDrawer({ children, hideBackdrop, open, onOpen, onClose, classes: classesProp, avoidSwipeInDrawerContent, visibleContent }: Props): React.ReactElement {
    const pullerRef = useRef<HTMLDivElement>(null);
    const lastPositionY = useRef();
    const visibleDrawerContainerRef = useRef<HTMLDivElement>(null);

    const [isDrawerOpen, setIsDrawerOpen] = useState(open || false);
    const [visibleContentHeight, setVisibleContentHeight] = useState(40);
    const [contentDrawerHeight, setContentDrawerHeight] = useState(0);

    const classes = useStyles({ isDrawerOpen, hideBackdrop });

    const displayDivider = !!visibleContent && !!children && isDrawerOpen;

    useEffect(() => {
        if (visibleContent) {
            setVisibleContentHeight((visibleDrawerContainerRef.current?.offsetHeight ?? 0) + 40);
        }
    }, [visibleContent]);

    const handleCloseDrawer = () => {
        setIsDrawerOpen(false);
        lastPositionY.current = undefined;
        onClose?.();
    };

    const handleOpenDrawer = () => {
        setIsDrawerOpen(true);
        lastPositionY.current = undefined;
        onOpen?.();
    };

    const handleTouchMove = (event: any) => {
        if (!lastPositionY.current) return (lastPositionY.current = event.changedTouches[0]?.clientY);

        if (lastPositionY.current < event.changedTouches[0].clientY) {
            if (!avoidSwipeInDrawerContent) return handleCloseDrawer();

            if (event.target.isEqualNode(pullerRef.current)) handleCloseDrawer();
            return;
        }

        handleOpenDrawer();
    };

    const handleClick = () => {
        if (isDrawerOpen) {
            handleCloseDrawer();
            return;
        }

        handleOpenDrawer();
    };

    const handleContentDrawerRef = (contentDrawer: HTMLDivElement | null) => {
        setContentDrawerHeight(contentDrawer?.clientHeight || 0);
    };

    return (
        <div className={classes.container}>
            <div style={{ height: visibleContentHeight }}></div>
            {isDrawerOpen && <div className={classes.background} onClick={handleCloseDrawer}></div>}
            <div className={classNames(classes.drawer, classesProp?.drawer)} style={{ bottom: isDrawerOpen ? 0 : -contentDrawerHeight }} onTouchMove={handleTouchMove}>
                <div className={classes.drawerHeader} onClick={handleClick}>
                    <div className={classNames(classes.puller, classesProp?.puller)} ref={pullerRef}></div>
                </div>
                {!!visibleContent && (
                    <div ref={visibleDrawerContainerRef} className={classNames(classes.drawerContent, classesProp?.drawerContent)}>
                        {visibleContent}
                    </div>
                )}
                {displayDivider && <div className={classes.divider}></div>}
                <div ref={handleContentDrawerRef} className={classNames(classes.drawerContent, classesProp?.drawerContent)} style={{ paddingTop: 10 }}>
                    {children}
                </div>
            </div>
        </div>
    );
}

const useStyles = makeStyles((theme) => ({
    container: {
        width: '100%',
        height: '100%',
    },
    puller: {
        width: '16%',
        height: 2,
        backgroundColor: '#D9D9D9',
        borderRadius: 2,
        position: 'absolute',
        top: 16,
        left: 'calc(50% - 8%)',
    },
    divider: {
        height: '1px',
        width: '95%',
        margin: '0 auto',
        borderTop: '1px solid #D9D9D9',
    },
    drawerHeader: {
        width: '100%',
        height: 40,
        position: 'sticky',
        top: 0,
    },
    drawerContent: {
        width: '100%',
        height: '100%',
        padding: '0 16px',
        zIndex: 135,
        backgroundColor: 'white',
        display: 'flex',
        paddingBottom: 10,
    },
    drawer: {
        position: 'absolute',
        left: 0,
        width: '100%',
        backgroundColor: 'white',
        borderRadius: '8px 8px 0px 0px',
        transition: 'bottom 0.2s ease',
        minHeight: 'fit-content',
        zIndex: 130,
        border: '1px solid #D9D9D9',
        borderBottomColor: 'transparent',
    },
    background: (props) => ({
        position: 'absolute',
        top: 0,
        left: 0,
        backgroundColor: !!(props as any).hideBackdrop ? 'transparent' : 'rgba(217, 217, 217, 0.5)',
        '-webkit-tap-highlight-color': 'transparent',
        width: '100%',
        height: '100%',
        zIndex: 100,
    }),
}));

type Props = {
    children: React.ReactNode;
    hideBackdrop?: boolean;
    open?: boolean;
    visibleContent?: React.ReactNode;
    avoidSwipeInDrawerContent?: boolean;
    onClose?: any;
    onOpen?: any;
    classes?: {
        puller?: string;
        drawer?: string;
        drawerContent?: string;
    };
};
