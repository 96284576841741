import { Marker } from '@react-google-maps/api';
import * as React from 'react';
import { useEffect } from 'react';
import trianglePinBlue from 'src/images/trianglePinBlue.svg';
import trianglePinOrange from 'src/images/trianglePinOrange.svg';
import trianglePinRed from 'src/images/trianglePinRed.svg';
import trianglePinYellow from 'src/images/trianglePinYellow.svg';
import { liveDrivers } from 'src/reducers/liveDriversReducer';
import { LiveOrderVm } from 'src/types/LiveOrderVm';
import type { LiveRestaurantVm } from 'src/types/LiveRestaurantVm';
import { minutesFromNow } from 'src/utils/date/minutesFromNow';
import { toLatLng } from 'src/utils/googlemaps/toLatLng';
import { useAction } from 'src/utils/react/useAction';
import { useSelector } from 'src/utils/react/useSelector';

export function RestaurantMarker({ restaurant }: Props): React.ReactElement {
    const showRestaurantInfo = useSelector((state) => state.liveDrivers.showRestaurantInfo);
    const restaurantInfo = useSelector((state) => state.liveDrivers.restaurantInfo);

    const setRestaurantInfo = useAction(liveDrivers.actions.setRestaurantInfo);

    useEffect(() => {
        if (showRestaurantInfo && restaurantInfo?.restaurantName === restaurant.restaurantName) {
            setRestaurantInfo(restaurant);
        }
    }, [showRestaurantInfo, restaurantInfo, restaurant]);

    const getIcon = (orders: Array<LiveOrderVm>) => {
        const ordersWithoutDriver = orders.filter((order) => !order.driverId);

        if (!ordersWithoutDriver.length) return trianglePinBlue;

        const minutesWaiting = getMaxMinutesWaiting(ordersWithoutDriver);

        if (minutesWaiting >= 10) return trianglePinRed;
        if (minutesWaiting < 10 && minutesWaiting >= 5) return trianglePinOrange;

        return trianglePinYellow;
    };

    const getMaxMinutesWaiting = (orders: Array<LiveOrderVm>) => {
        const minutesWaiting = orders.map((order) => Math.abs(minutesFromNow(order.startedAt)));
        return Math.max(...minutesWaiting);
    };

    const handleClick = () => setRestaurantInfo(restaurant);

    return <Marker onClick={handleClick} icon={getIcon(restaurant.orders)} position={toLatLng(restaurant.restaurantPosition) as any} />;
}

type Props = {
    restaurant: LiveRestaurantVm;
};
