import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import * as React from 'react';
import { CashRegisterClosedAlert } from 'src/components/alertDialog/cashRegister/CashRegisterClosedAlert';
import { IntegrationAlerts } from 'src/components/alertDialog/restaurant/IntegrationAlerts';
import { useSelector } from 'src/utils/react/useSelector';

export function AppAlertDialogs(): React.ReactElement | null {
    const classes = useStyles();

    const kioskUser = useSelector((state) => state.authentication.kioskUser);
    const signedIn = useSelector((state) => state.authentication.signedIn);

    if (kioskUser || !signedIn) return null;

    return (
        <div className={classes.alertContainer}>
            <Grid container direction='column' alignItems='flex-end' spacing={1}>
                <IntegrationAlerts />
                <CashRegisterClosedAlert />
            </Grid>
        </div>
    );
}
const useStyles = makeStyles((theme) => ({
    alertContainer: {
        position: 'fixed',
        right: 70,
        bottom: 40,
        zIndex: 99,
    },
}));
