import { letseatadminApiMethod } from 'src/api/letseatadminApi';
import type { ApiSauceResponse } from 'src/api/types/ApiSauceResponse';
import { RestaurantId, type MenuId } from 'src/types/Id';

export async function copyRestaurantMenuApi(request: CopyRestaurantMenuApiRequest): ApiSauceResponse<void> {
    return letseatadminApiMethod('menu/copyRestaurantMenuApi', request);
}

export type CopyRestaurantMenuApiRequest = {
    fromRestaurantId: RestaurantId;
    toRestaurantId: RestaurantId;
    overwrite: boolean;
    menuId: MenuId;
};
