import { Grid, makeStyles } from '@material-ui/core';
import { BigNumber } from 'bignumber.js';
import moment from 'moment';
import * as React from 'react';
import { useEffect, useState } from 'react';
import {
    getGeneralReportsRestaurantStatisticsApi,
    SalesByApp,
    SalesByMenuCategory,
    SalesByOrderType,
    SalesByPaymentMethod,
    SalesPerDay,
} from 'src/api/letseatmanager/restaurantDashboard/getGeneralRestaurantStatisticsApi';
import { UpdatingContentProgress } from 'src/components/UpdatingContentProgress';
import { translate } from 'src/i18n/translate';
import { FilterMasterReports } from 'src/scenes/letseatmanager/restaurantDashboard/FilterMasterReports';
import { FilterReports } from 'src/scenes/letseatmanager/restaurantDashboard/FilterReports';
import { CategoryGeneralGraph } from 'src/scenes/letseatmanager/restaurantDashboard/generalReport/CategoryGeneralGraph';
import { ChannelGeneralGraph } from 'src/scenes/letseatmanager/restaurantDashboard/generalReport/ChannelGeneralGraph';
import { OrderTypeGeneralGraph } from 'src/scenes/letseatmanager/restaurantDashboard/generalReport/OrderTypeGeneralGraph';
import { SalesByDayGeneralGraph } from 'src/scenes/letseatmanager/restaurantDashboard/generalReport/SalesByDayGeneralGraph';
import { TotalSalesGeneralGraph } from 'src/scenes/letseatmanager/restaurantDashboard/generalReport/TotalSalesGeneralGraph';
import { KpiCard } from 'src/scenes/letseatmanager/restaurantDashboard/KpiCard';
import { ReportSectionEmptyState } from 'src/scenes/letseatmanager/restaurantDashboard/ReportSectionEmptyState';
import { useFormatAsRestaurantCurrencyNumber } from 'src/services/restaurant/useFormatAsRestaurantCurrencyNumber';
import { RestaurantId } from 'src/types/Id';
import { useFormatDateToRestaurantTimeZone } from 'src/utils/react/useFormatDateToRestaurantTimeZone';
import { useLoadApi } from 'src/utils/react/useLoadApi';
import { useSelector } from 'src/utils/react/useSelector';

export function GeneralReport({ colors }: Props): React.ReactElement {
    const classes = useStyles();
    const formatAsCurrencyNumber = useFormatAsRestaurantCurrencyNumber();

    const [summaryInfo, setSummaryInfo] = useState<Array<SummaryInfoType>>([]);
    const [salesByPaymentMethod, setSalesByPaymentMethod] = useState<Array<SalesByPaymentMethod>>([]);
    const [orderTypeData, setOrderTypeData] = useState<Array<SalesByOrderType>>([]);
    const [deliveryTypeData, setDeliveryTypeData] = useState<Array<SalesByApp>>([]);
    const [categoriesData, setCategoriesData] = useState<Array<SalesByMenuCategory>>([]);
    const [salesByDayData, setSalesByDayData] = useState<Array<SalesPerDay>>([]);
    const [hasInfoFound, setHasInfoFound] = useState(false);
    const [showAsMaster, setShowAsMaster] = useState(false);

    const restaurantId = useSelector((state) => state.app.restaurantId);
    const restaurantIds = useSelector((state) => state.app.restaurantIds);
    const brandOpened = useSelector((state) => state.app.brandOpened);
    const period = useSelector((state) => state.app2.filterReportsState.period);
    const currentRestaurantId = useSelector((state) => state.app2.filterReportsState.currentRestaurantId);
    const salesType = useSelector((state) => state.app.salesTypeFilter);

    const formatDateToRestaurantTimeZone = useFormatDateToRestaurantTimeZone();

    const [loading, reports] = useLoadApi(
        getGeneralReportsRestaurantStatisticsApi,
        {
            report: showAsMaster ? 'asBrand' : 'perRestaurant',
            restaurantIds: brandOpened ? restaurantIds : [restaurantId],
            fromDate: moment(formatDateToRestaurantTimeZone(period?.from)).toISOString(),
            toDate: moment(formatDateToRestaurantTimeZone(period.to)).toISOString(),
            salesType: salesType,
        },
        {
            dependencies: [period, brandOpened, showAsMaster, restaurantId, salesType],
        },
    );

    useEffect(() => {
        if (reports) {
            if (showAsMaster || !brandOpened) return handleUpdateGeneralReports(reports[0]?.restaurantId);

            handleUpdateGeneralReports(currentRestaurantId ?? reports[0]?.restaurantId);
        }
    }, [reports, currentRestaurantId]);

    const hasRestaurantReportInfo = (currentRestaurant: (typeof reports)[number] | undefined) => currentRestaurant && currentRestaurant?.totalOrders;

    const handleUpdateGeneralReports = (newRestaurantId: RestaurantId) => {
        const currentRestaurantReportInfo = reports?.find((restaurant) => restaurant.restaurantId === newRestaurantId);

        if (!newRestaurantId || !hasRestaurantReportInfo(currentRestaurantReportInfo)) {
            setHasInfoFound(false);
            return;
        }

        setSummaryInfo([
            { title: 'Total', legend: formatAsCurrencyNumber(currentRestaurantReportInfo?.totalSales ?? 0) },
            { title: 'Average ticket', legend: formatAsCurrencyNumber(currentRestaurantReportInfo?.averageTicket ?? 0) },
            { title: 'Orders', legend: currentRestaurantReportInfo?.totalOrders ?? 0 },
            { title: 'Average mark', legend: formatAsCurrencyNumber(currentRestaurantReportInfo?.averageRating ?? 0) },
        ]);
        setSalesByPaymentMethod(currentRestaurantReportInfo?.salesByPaymentMethod ?? []);
        setOrderTypeData(currentRestaurantReportInfo?.salesByOrderType ?? []);
        setDeliveryTypeData(currentRestaurantReportInfo?.salesByApp ?? []);
        setCategoriesData(currentRestaurantReportInfo?.salesByMenuCategory ?? []);
        setSalesByDayData(currentRestaurantReportInfo?.salesPerDay ?? []);
        setHasInfoFound(true);
    };

    const handleShowAsMaster = (value: any) => {
        setShowAsMaster(value);
    };

    const isGreaterThanZero = (item: SummaryInfoType) => {
        return BigNumber(item.legend).isGreaterThan(0) || item.legend !== '';
    };

    return (
        <div className={classes.container}>
            <FilterReports />
            <FilterMasterReports onChangeMaster={handleShowAsMaster} showAsMaster={showAsMaster} />
            {loading && <UpdatingContentProgress />}
            {hasInfoFound && (
                <div>
                    <div className={classes.kpiHeading}>
                        {summaryInfo?.map(
                            (item: SummaryInfoType) =>
                                isGreaterThanZero(item) && (
                                    <KpiCard
                                        classes={{
                                            container: classes.headingKpiContainer,
                                            title: classes.headingKpiTitleKpi,
                                            body: classes.headingKpiBody,
                                            color: classes.headingKpiColor,
                                            legend: classes.headingKpiLegendContainer,
                                        }}
                                        legend={item.legend}
                                        title={translate(item.title)}
                                    />
                                ),
                        )}
                    </div>
                    <Grid container>
                        <Grid item xs={12} className={classes.reportsContainer}>
                            <TotalSalesGeneralGraph colors={colors} loading={loading} salesByPaymentMethodData={salesByPaymentMethod} />
                            <OrderTypeGeneralGraph colors={colors} loading={loading} orderTypeData={orderTypeData} />
                            <ChannelGeneralGraph colors={colors} loading={loading} deliveryTypeData={deliveryTypeData} />
                            <CategoryGeneralGraph colors={colors} loading={loading} categoriesData={categoriesData} />
                        </Grid>
                        <SalesByDayGeneralGraph loading={loading} salesByDayData={salesByDayData} />
                    </Grid>
                </div>
            )}
            {!hasInfoFound && !loading && <ReportSectionEmptyState />}
        </div>
    );
}

const useStyles = makeStyles((theme) => ({
    container: {
        width: '100%',
    },
    kpiHeading: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'left',
        flexWrap: 'nowrap',
        [theme.breakpoints.between('xs', 'sm')]: {
            flexWrap: 'wrap',
            justifyContent: 'center',
        },
        [theme.breakpoints.between('sm', 'md')]: {
            flexWrap: 'nowrap',
        },
    },
    reportsContainer: {
        display: 'grid',
        gridTemplateColumns: 'repeat(auto-fill, minmax(35%, 1fr))',
        columnGap: 20,
        [theme.breakpoints.down('sm')]: {
            display: 'flex',
            flexDirection: 'column',
        },
    },
    headingKpiContainer: {
        backgroundColor: 'transparent',
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        paddingTop: '8px',
        margin: 5,
        marginRight: '5%',
        marginBottom: '2%',
    },
    headingKpiTitleKpi: {
        fontFamily: theme.typography.regular,
        fontSize: 16,
        color: theme.palette.text.contrast,
        width: '100%',
        textAlign: 'center',
    },
    headingKpiBody: {
        fontSize: 28,
        fontFamily: theme.typography.semiBold,
    },
    headingKpiColor: {
        width: 12,
        height: 12,
        borderRadius: 2,
    },
    headingKpiLegendContainer: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: '100%',
    },
}));

type Props = {
    colors: Array<string>;
};

type SummaryInfoType = {
    title: string;
    legend: string | number;
};
