import { makeStyles } from '@material-ui/core';
import * as React from 'react';
import { useState } from 'react';
import { createMexicanOrdersGlobalInvoiceApi } from 'src/api/letseatmanager/invoice/createMexicanOrdersGlobalInvoiceApi';
import { Button } from 'src/components/Button';
import { Dialog } from 'src/components/Dialog';
import { DialogActions } from 'src/components/DialogActions';
import { translate } from 'src/i18n/translate';
import { GlobalInvoiceReceiverInfo } from 'src/scenes/letseatmanager/invoice/GlobalInvoiceReceiverInfo';
import { OrdersToInvoiceTable } from 'src/scenes/letseatmanager/invoice/OrdersToInvoiceTable';
import { RestaurantInvoiceInfo } from 'src/scenes/letseatmanager/invoice/RestaurantInvoiceInfo';
import { useConfirmDialog } from 'src/services/dialog/useConfirmDialog';
import { useFormatAsRestaurantCurrencyNumber } from 'src/services/restaurant/useFormatAsRestaurantCurrencyNumber';
import { OrderInvoiceSummary, type OrdersGlobalInvoiceVm } from 'src/types/OrdersGlobalInvoiceVm';
import { alertKnownErrorOrSomethingWentWrong } from 'src/utils/alert/alertKnownErrorOrSomethingWentWrong';
import { classNames } from 'src/utils/react/classNames';
import { useSelector } from 'src/utils/react/useSelector';

export function CreateMexicanGlobalInvoiceSummaryDialog({ open, ordersToInvoiceSummary, from, to, onClose }: Props): React.ReactElement | null {
    const classes = useStyles();
    const formatAsCurrencyNumber = useFormatAsRestaurantCurrencyNumber();

    const confirmDialog = useConfirmDialog();

    const [loading, setLoading] = useState(false);

    const restaurantId = useSelector((state) => state.app.restaurantId);

    const orderIdsToInvoice = ordersToInvoiceSummary?.ordersToInvoice?.map((order: OrderInvoiceSummary) => order.orderId);

    const handleCreateInvoice = async () => {
        if (!ordersToInvoiceSummary) return;

        const continueInvoice = await confirmDialog({
            variant: 'warning',
            content: translate('Data confirmation'),
            buttonText: translate('Yes, invoice'),
            cancelButtonText: translate('No, cancel'),
        });

        if (!continueInvoice) return onClose();

        setLoading(true);
        const response = await createMexicanOrdersGlobalInvoiceApi({
            restaurantId,
            orderIds: orderIdsToInvoice ?? [],
            totalToInvoice: ordersToInvoiceSummary.totalToInvoice,
            periodicity: ordersToInvoiceSummary.periodicity,
            paymentMethod: ordersToInvoiceSummary.mainPaymentMethod,
            firstOrderAt: from,
            lastOrderAt: to,
        });
        setLoading(false);

        if (!response.ok) {
            alertKnownErrorOrSomethingWentWrong(response);
            onClose();
            return;
        }

        await confirmDialog({
            variant: 'success',
            title: translate('Invoice created'),
            content: translate('The invoice has been generated successfully!'),
            timeoutSeconds: 2,
        });

        onClose();
    };

    if (!ordersToInvoiceSummary) return null;

    return (
        <Dialog classes={{ dialog: classes.dialog }} open={open} onClose={onClose} title={translate('Info validation')}>
            <div className={classes.dialogContent}>
                <h3 className={classes.subTitle}>{translate('Issuer')}</h3>
                <RestaurantInvoiceInfo />
                <GlobalInvoiceReceiverInfo from={from} to={to} paymentMethod={ordersToInvoiceSummary.mainPaymentMethod} />
                <div className={classes.summaryContainer}>
                    <div className={classes.separatedText}>
                        <span>{translate('Total Sold')}</span>
                        <span className={classes.salesData}>{formatAsCurrencyNumber(ordersToInvoiceSummary.totalSold)}</span>
                    </div>
                    {ordersToInvoiceSummary.taxes?.map((tax) => (
                        <div key={tax.name} className={classes.separatedText}>
                            <span>{tax.name}</span>
                            <span className={classes.salesData}>{formatAsCurrencyNumber(tax.taxAmount)}</span>
                        </div>
                    ))}
                    <div className={classes.separatedText}>
                        <span>{translate('Total tickets')}</span>
                        <span className={classes.salesData}>{ordersToInvoiceSummary.totalOrders}</span>
                    </div>
                </div>
                <OrdersToInvoiceTable classes={{ table: classes.table, header: classNames(classes.header, classes.table) }} ordersToInvoiceSummary={ordersToInvoiceSummary} />
            </div>
            <DialogActions>
                <Button disabled={loading} onClick={onClose} secondary>
                    {translate('Cancel')}
                </Button>
                <Button disabled={loading} onClick={handleCreateInvoice}>
                    {translate('Invoice')}
                </Button>
            </DialogActions>
        </Dialog>
    );
}

const useStyles = makeStyles((theme) => ({
    dialog: {
        [theme.breakpoints.up('sm')]: {
            minWidth: 700,
        },

        [theme.breakpoints.up('md')]: {
            minWidth: 800,
        },
    },
    dialogContent: {
        fontFamily: theme.typography.regular,
        fontSize: 12,
        color: '#232933',
        [theme.breakpoints.up('sm')]: {
            fontSize: 14,
        },
    },
    summaryContainer: {
        display: 'grid',
        gap: 8,
    },
    subTitle: {
        color: '#198073',
        margin: 0,
        marginBottom: 10,
    },
    separatedText: {
        '& span': {
            display: 'block',
        },
        [theme.breakpoints.up('sm')]: {
            display: 'flex',
            justifyContent: 'space-between',
        },
    },
    salesData: {
        fontFamily: theme.typography.bold,
    },
    table: {
        border: 'none',
        minHeight: 'unset',
        fontSize: '9px !important',
        [theme.breakpoints.up('sm')]: {
            fontSize: '10px !important',
        },
    },
    header: {
        fontSize: '12px !important',
    },
}));

type Props = {
    open: boolean;
    ordersToInvoiceSummary: OrdersGlobalInvoiceVm | undefined;
    from: Date;
    to: Date;
    onClose: any;
};
