import { makeStyles } from '@material-ui/core';
import { BigNumber } from 'bignumber.js';
import * as React from 'react';

export function LoadingIndicator({ text, subText, total, currentValue }: Props): React.ReactElement {
    const classes = useStyles();

    const calculateLoadingIndicatorWidth = () => {
        const percentage = BigNumber(currentValue)
            .multipliedBy(100)
            .dividedBy(total || 1)
            .toFixed(2)
            .toString();
        return `${percentage}%`;
    };

    return (
        <div className={classes.loadingIndicatorContainer}>
            <span className={classes.text}>{text}</span>
            <div className={classes.loadingIndicatorBackground}>
                <div className={classes.loadingIndicator} style={{ width: calculateLoadingIndicatorWidth() }}></div>
            </div>
            {subText && <span className={classes.text}>{subText}</span>}
        </div>
    );
}

const useStyles = makeStyles((theme) => ({
    text: {
        fontFamily: theme.typography.regular,
        fontSize: 15,
        marginTop: 5,
    },
    loadingIndicatorContainer: {
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
    },
    loadingIndicatorBackground: {
        backgroundColor: theme.palette.surface.secondary,
        height: 10,
        borderRadius: 15,
        width: '100%',
        border: `1px solid ${theme.palette.surface.secondary}`,
    },
    loadingIndicator: {
        height: 10,
        borderRadius: 15,
        backgroundColor: theme.palette.surface.brandContrast,
    },
}));

type Props = {
    text: string;
    subText?: string;
    total: number;
    currentValue: number;
};
