import { makeStyles } from '@material-ui/core';
import * as React from 'react';
import { useEffect } from 'react';
import { app2 } from 'src/app2';
import FaqTable from 'src/scenes/letseatadmin/faq/FaqTable';
import type { FaqId } from 'src/types/Id';
import { useAction } from 'src/utils/react/useAction';

export function FaqsPage(): React.ReactElement {
    const classes = useStyles();

    const setTitle = useAction(app2.actions.setTitle);

    useEffect(() => {
        setTitle('Driver App Faqs');
    }, []);

    return (
        <div className={classes.container}>
            <FaqTable />
        </div>
    );
}

const useStyles = makeStyles((theme) => ({
    container: {
        position: 'relative',
    },
}));

export type FaqsVm = {
    faqs: Array<FaqVm>;
};

export type FaqVm = {
    faqId: FaqId;
    question: string;
    answer: string;
    imageUrl?: string;
    position?: number;
    articleUrl?: string;
};
