import { PrintTypes } from 'src/constants/PrintType';
import { useDeveloperMode } from 'src/services/developerMode/useDeveloperMode';
import { createPrinterInfoPrint } from 'src/services/printer/prints/createPrinterInfoPrint';
import { usePrintContent } from 'src/services/printer/utils/usePrintContent';
import type { PrinterVm } from 'src/types/PrinterVm';
import { useSelector } from 'src/utils/react/useSelector';

export function usePrintPrinterInfo(): [(printer: PrinterVm) => Promise<void>] {
    const pideDirectoAdminAppVersion = useSelector((state) => state.app.pideDirectoAdminAppVersion);
    const { printContents } = usePrintContent();
    const developerMode = useDeveloperMode();

    const printPrinterInfo = async (printer: PrinterVm) => {
        printContents([
            {
                printer: printer,
                print: {
                    printType: PrintTypes.PRINTER_INFO,
                    printerInstructions: createPrinterInfoPrint({ printer, pideDirectoAdminAppVersion, developerMode }),
                },
            },
        ]);
    };

    return [printPrinterInfo];
}
