import { makeStyles } from '@material-ui/core';
import * as React from 'react';
import { useState } from 'react';
import { Button } from 'src/components/Button';
import { NumberOfCustomersDialog } from 'src/components/dialog/pos/NumberOfCustomersDialog';
import { translate } from 'src/i18n/translate';
import { getPosInterfaceLargerEnabledRestaurantSettingInLocalStorage } from 'src/localStorage/getPosInterfaceLargerEnabledRestaurantSettingInLocalStorage';
import { posReducer } from 'src/reducers/posReducer';
import { isEatHereOrder } from 'src/utils/order/isEatHereOrder';
import { useAction } from 'src/utils/react/useAction';
import { useSelector } from 'src/utils/react/useSelector';

export function PosTakeOrderByCustomerButton({ disabled }: Props): React.ReactElement | null {
    const classes = useStyles();

    const posInterfaceLargerEnabled = getPosInterfaceLargerEnabledRestaurantSettingInLocalStorage();

    const [numberOfCustomerDialogState, setNumberOfCustomersDialogState] = useState<{ open: boolean; onSuccess: any }>({ open: false, onSuccess: undefined });

    const orderType = useSelector((state) => state.pos.orderType);
    const items = useSelector((state) => state.pos.items);
    const numberOfCustomers = useSelector((state) => state.pos.numberOfCustomers);
    const table = useSelector((state) => state.pos.table);
    const takeOrderByCourse = useSelector((state) => state.pos.takeOrderByCourse);

    const setNumberOfCustomers = useAction(posReducer.actions.setNumberOfCustomers);
    const setEmptyCustomers = useAction(posReducer.actions.setEmptyCustomers);

    const showTakeOrderByCustomerOption = isEatHereOrder(orderType) && !items?.length && !takeOrderByCourse;

    const handleTakeOrderByCustomer = async () => {
        if (!numberOfCustomers && !table?.numberOfCustomers) {
            setNumberOfCustomersDialogState({
                open: true,
                onSuccess: (numberOfCustomers: number) => {
                    setNumberOfCustomers(numberOfCustomers);
                    setEmptyCustomers(numberOfCustomers);
                },
            });
            return;
        }

        setEmptyCustomers(numberOfCustomers ?? table?.numberOfCustomers);
    };

    if (!showTakeOrderByCustomerOption) return null;

    return (
        <div>
            <NumberOfCustomersDialog
                open={numberOfCustomerDialogState.open}
                onSuccess={numberOfCustomerDialogState.onSuccess}
                handleClose={() => setNumberOfCustomersDialogState({ open: false, onSuccess: undefined })}
            />
            <Button larger={posInterfaceLargerEnabled} classes={{ button: classes.basicButton }} secondary onClick={handleTakeOrderByCustomer} disabled={disabled}>
                {translate('Take order by customer')}
            </Button>
        </div>
    );
}

const useStyles = makeStyles((theme) => ({
    basicButton: {
        width: '100%',
        height: 50,
        color: theme.palette.text.secondary,
        '&:hover': {
            cursor: 'pointer',
        },
    },
}));

type Props = {
    disabled?: boolean;
};
