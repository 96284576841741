import { makeStyles } from '@material-ui/core';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import { useContext } from 'react';
import * as React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { FormContext } from 'src/components/form/Form';
import type { AutoCompleteInputProp, Variant } from 'src/components/form/FormTypes';
import { getError } from 'src/components/form/utils/getError';
import { Tooltip } from 'src/components/Tooltip';
import { classNames } from 'src/utils/react/classNames';

export function FormCheckbox({ name, label, helperText, defaultValue = false, disabled, onChange: onChangeProp, idx, classes: classesProp, tooltipText }: Props): React.ReactElement {
    const {
        errors,
        control,
        formState: { isSubmitting },
    } = useFormContext();
    const formContext = useContext(FormContext);
    const classes = useStyles();

    return (
        <div className={classes.inputContainer}>
            <div className={classes.checkboxContainer}>
                <FormControlLabel
                    label={label}
                    classes={{ label: classNames(classes.label, classesProp?.label) }}
                    control={
                        <Controller
                            control={control}
                            name={name}
                            render={({ onChange, onBlur, value, name }) => (
                                <Checkbox
                                    onBlur={onBlur}
                                    onChange={(e) => {
                                        onChange(e.target.checked);
                                        onChangeProp?.(idx);
                                    }}
                                    checked={value}
                                    name={name}
                                    color='primary'
                                    disabled={isSubmitting || disabled || formContext.disabled}
                                    classes={{ root: classesProp?.input }}
                                    aria-label={label ? undefined : name}
                                />
                            )}
                            defaultValue={defaultValue}
                        />
                    }
                    disabled={isSubmitting || disabled || formContext.disabled}
                />
                {tooltipText && <Tooltip text={tooltipText} />}
            </div>
            {(!!getError(errors, name) || !!helperText) && <FormHelperText error={!!getError(errors, name)}>{helperText}</FormHelperText>}
        </div>
    );
}

const useStyles = makeStyles((theme) => ({
    inputContainer: {
        display: 'flex',
        flexDirection: 'column',
    },
    checkboxContainer: {
        display: 'flex',
        flexDirection: 'row',
    },
    label: {
        fontFamily: theme.typography.regular,
        color: theme.palette.secondary.contrastText,
        fontSize: 12,
        [theme.breakpoints.up('sm')]: {
            fontSize: 14,
        },
    },
}));

export type Props = {
    name: string;
    label?: string;
    placeholder?: string;
    helperText?: string;
    defaultValue?: boolean;
    autoComplete?: AutoCompleteInputProp;
    upperCase?: boolean;
    disabled?: boolean;
    required?:
        | boolean
        | {
              value: number;
              message: string;
          };
    minLength?:
        | number
        | {
              value: number;
              message: string;
          };
    maxLength?:
        | number
        | {
              value: number;
              message: string;
          };
    pattern?:
        | RegExp
        | {
              value: RegExp;
              message: string;
          };
    variant?: Variant;
    InputProps?: any;
    inputProps?: any;
    onChange?: any;
    idx?: number;
    classes?: any;
    tooltipText?: string;
};
