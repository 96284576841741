export const DeliveryStatuses = {
    REQUESTED: 'REQUESTED',
    ACCEPTED: 'ACCEPTED',
    REJECTED: 'REJECTED',
    PICKED_UP: 'PICKED_UP',
    DELIVERED: 'DELIVERED',
    CANCELLED: 'CANCELLED',
    WITHDRAWN: 'WITHDRAWN',
    RETURNED: 'RETURNED',
} as const;

export type DeliveryStatus = (typeof DeliveryStatuses)[keyof typeof DeliveryStatuses];
