import { TextField } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import Grid from '@material-ui/core/Grid';
import { useEffect, useState } from 'react';
import * as React from 'react';
import { letseatmanagerApiDeprecated } from 'src/api/letseatmanagerApiDeprecated';
import { app2 } from 'src/app2';
import type { CustomerActivity } from 'src/constants/CustomerActivity';
import type { CustomerType } from 'src/constants/CustomerType';
import type { Platform } from 'src/constants/Platform';
import type { RefundMethod } from 'src/constants/RefundMethod';
import { RoutePaths } from 'src/constants/RoutePath';
import { translate } from 'src/i18n/translate';
import ChangeCustomerDialog from 'src/scenes/letseatadmin/customer/ChangeCustomerDialog';
import CustomerCreditsDialog from 'src/scenes/letseatadmin/customer/CustomerCreditsDialog';
import CustomersChangeDialog from 'src/scenes/letseatadmin/customer/CustomersChangeDialog';
import CustomersCreditsDialog from 'src/scenes/letseatadmin/customer/CustomersCreditsDialog';
import { CustomerSendDialog } from 'src/scenes/letseatadmin/customer/CustomerSendDialog';
import CustomerSendEmailDialog from 'src/scenes/letseatadmin/customer/CustomerSendEmailDialog';
import CustomersSendDialog from 'src/scenes/letseatadmin/customer/CustomersSendDialog';
import CustomersSendEmailDialog from 'src/scenes/letseatadmin/customer/CustomersSendEmailDialog';
import CustomersTable from 'src/scenes/letseatadmin/customer/CustomersTable';
import type { CustomerId, LanguageTag } from 'src/types/Id';
import { alertKnownErrorOrSomethingWentWrong } from 'src/utils/alert/alertKnownErrorOrSomethingWentWrong';
import { useAction } from 'src/utils/react/useAction';
import { useSelector } from 'src/utils/react/useSelector';

export function CustomersPage(): React.ReactElement {
    const setTitle = useAction(app2.actions.setTitle);
    const viewUser = useSelector((state) => state.authentication.viewUser);

    const [loading, setLoading] = useState(false);
    const [textSearch, setTextSearch] = useState('');
    const [customers, setCustomers] = useState<Array<CustomerVm>>([]);

    useEffect(() => {
        setTitle(translate('Customers'));
    }, []);

    const load = async () => {
        setLoading(true);
        const response = await letseatmanagerApiDeprecated.admin.getCustomers({ textSearch: textSearch.trim() });
        setLoading(false);
        if (!response.ok) return alertKnownErrorOrSomethingWentWrong(response);
        setCustomers(response.data.customers);
    };

    return (
        <>
            <ChangeCustomerDialog onCustomerChanged={load} />
            <CustomersChangeDialog onCustomersChanged={load} path={RoutePaths.ADMIN_CUSTOMERS_CHANGE} />
            <CustomerSendDialog />
            <CustomersSendDialog path={RoutePaths.ADMIN_CUSTOMERS_SEND_NOTIFICATIONS} />
            <CustomerSendEmailDialog />
            <CustomersSendEmailDialog path={RoutePaths.ADMIN_CUSTOMERS_SEND_EMAILS} />
            <CustomerCreditsDialog onCreditsAddedOrRemoved={load} />
            <CustomersCreditsDialog onCreditsAddedOrRemoved={load} path={RoutePaths.ADMIN_CUSTOMERS_CREDITS} />
            <Grid container spacing={5}>
                <Grid item xs={12}>
                    <Card style={{ padding: 24 }}>
                        <Grid container spacing={5}>
                            <Grid item xs={10} md={6}>
                                <TextField
                                    label={translate('Search Text')}
                                    value={textSearch}
                                    onChange={({ target: { value } }) => {
                                        setTextSearch(value);
                                    }}
                                    fullWidth
                                    onKeyPress={({ key }) => {
                                        if (key === 'Enter') load();
                                    }}
                                />
                            </Grid>
                            <Grid item xs={2} md={2} container justify='flex-start'>
                                <Button color='primary' type='submit' onClick={load} disabled={loading}>
                                    {loading ? translate('Searching').toUpperCase() : translate('Search').toUpperCase()}
                                </Button>
                            </Grid>
                        </Grid>
                    </Card>
                </Grid>
                <Grid item xs={12}>
                    {(customers || []).length > 0 && <CustomersTable customers={customers || []} loading={loading} onClickRefresh={load} title=' ' disabled={viewUser} />}
                </Grid>
            </Grid>
        </>
    );
}

type CustomerVm = {
    customerId: CustomerId;
    activity?: CustomerActivity;
    exactActivity?: CustomerActivity;
    customerType: CustomerType;
    mobileNumber?: string;
    email?: string;
    firstName?: string;
    lastName?: string;
    companyName?: string;
    creditCards?: number;
    credits?: string;
    refundMethod?: RefundMethod;
    language?: LanguageTag;
    fcmPermission?: boolean;
    banned?: boolean;
    appVersion?: string;
    platform?: Platform;
    platformVersion?: string;
    addCreditCardAttempts?: number;
    downloadedAppAt?: Date;
    signedUpAt?: Date;
    addedFirstCreditCardAt?: Date;
    firstOrderAt?: string;
    lastOrderAt?: string;
};
