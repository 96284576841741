import { letseatmanagerApiMethod } from 'src/api/letseatmanagerApi';
import type { ApiSauceResponse } from 'src/api/types/ApiSauceResponse';
import type { RestaurantId } from 'src/types/Id';

export async function importRecipesApi(request: ImportRecipesApiRequest): ApiSauceResponse<void> {
    return letseatmanagerApiMethod('recipe/importRecipesApi', request);
}

type ImportRecipesApiRequest = {
    restaurantId: RestaurantId;
    importedRecipes: Array<{
        name: string;
        ingredients: Array<{
            sku: string;
            quantity: string;
        }>;
        estimatedPrice?: string;
    }>;
};
