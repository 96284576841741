import { translate } from 'src/i18n/translate';

export function translateDaysString(value: string): string {
    return value
        ? value
              .split(',')
              .map((day) => translate(day))
              .join(', ')
        : '';
}
