import { RolePermission, RolePermissions } from 'src/constants/RolePermission';
import { RoutePath, RoutePaths } from 'src/constants/RoutePath';

export function useGetReportRolePermissions(reportRoutePath: RoutePath): Array<RolePermission> {
    const inventoryReportPermissions = [
        RolePermissions.SUPPLIES_HISTORY_REPORT,
        RolePermissions.KARDEX_REPORT,
        RolePermissions.INVENTORY_CONSUMPTION_REPORT,
        RolePermissions.INVENTORY_ADJUSTMENTS_REPORT,
        RolePermissions.INVENTORY_SUPPLIERS_REPORT,
    ];

    const generalReportPermissions = [
        RolePermissions.GENERAL_REPORT,
        RolePermissions.CUSTOMERS_REPORT,
        RolePermissions.PROMO_CODE_REPORT,
        RolePermissions.DELIVERIES_REPORT,
        RolePermissions.ORDER_ISSUES_REPORT,
        RolePermissions.INTEGRATIONS_REPORT,
    ];

    const posReportPermissions = [
        RolePermissions.POS_BUSINESS_DAY_REPORT,
        RolePermissions.CASH_REGISTER_REPORT,
        RolePermissions.TRANSACTIONS_REPORT,
        RolePermissions.POS_USERS_REPORT,
        RolePermissions.POS_USER_SALES_REPORT,
        RolePermissions.EMPLOYEE_REPORT,
    ];

    const salesReportPermissions = [
        RolePermissions.WEB_ORDERS_CONVERSIONS_REPORT,
        RolePermissions.SALES_BY_DAY_REPORT,
        RolePermissions.SALES_BY_MENU_REPORT,
        RolePermissions.ABC_REPORT,
        RolePermissions.FINANCE_REPORT,
        RolePermissions.SMART_BI_REPORT,
        RolePermissions.HEAT_MAP_DELIVERY_ORDERS_REPORT,
    ];

    const getReportPermissions = (reportRoutePath: RoutePath) => {
        if (reportRoutePath === RoutePaths.RESTAURANT_GENERAL_REPORT) return generalReportPermissions;
        if (reportRoutePath === RoutePaths.RESTAURANT_SALES_REPORT) return salesReportPermissions;
        if (reportRoutePath === RoutePaths.RESTAURANT_POS_REPORT) return posReportPermissions;
        if (reportRoutePath === RoutePaths.INVENTORY_REPORT) return inventoryReportPermissions;

        return generalReportPermissions;
    };

    return getReportPermissions(reportRoutePath);
}
