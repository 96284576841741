import { Grid, makeStyles } from '@material-ui/core';
import moment from 'moment';
import { useEffect, useState } from 'react';
import * as React from 'react';
import type { NotCompleteOrdersByHour } from 'src/api/letseatmanager/restaurantDashboard/getIssueRestaurantStatisticsApi';
import { BarChartStackedStyleCanvas } from 'src/components/charts/BarChartStackedStyleCanvas';
import { OrderStatuses } from 'src/constants/OrderStatus';
import { translate } from 'src/i18n/translate';
import { ReportSection } from 'src/scenes/letseatmanager/restaurantDashboard/ReportSection';

export function IssueReasonsByHourGraph({ loading, issueReasonsByHourData }: Props): React.ReactElement | null {
    const classes = useStyles();

    const [issuesPerHourData, setIssuesPerHourData] = useState<Array<IssuesStatusStructureType>>([]);

    const hasIssuesPerHourData = issuesPerHourData?.length > 0;

    useEffect(() => {
        if (issueReasonsByHourData) {
            getIssuesPerHourFromReport(issueReasonsByHourData);
        }
    }, [issueReasonsByHourData]);

    const getIssuesPerHourFromReport = (issuesPerHourData: Array<NotCompleteOrdersByHour>) => {
        if (issuesPerHourData.length < 0) {
            setIssuesPerHourData([]);
            return;
        }

        const structureOfIssuePerHour: Array<IssuesStatusStructureType> = [];

        issuesPerHourData.forEach((orderIssue) => {
            const statusHasIssue = issuesPerHourData.find((issueItem) => orderIssue.createdAt === issueItem.menuId);
            if (statusHasIssue) {
                if (orderIssue.orderStatus == OrderStatuses.CANCELLED) statusHasIssue.cancelled = statusHasIssue.cancelled + orderIssue.orderVolume;
                if (orderIssue.orderStatus == OrderStatuses.REJECTED) statusHasIssue.rejected = statusHasIssue.rejected + orderIssue.orderVolume;
            }
            structureOfIssuePerHour.push({
                menuId: `${moment().hour(parseInt(orderIssue.createdAt)).minutes(0).format('HH:mm')}`,
                cancelled: orderIssue.orderStatus === OrderStatuses.CANCELLED ? orderIssue.orderVolume : 0,
                rejected: orderIssue.orderStatus === OrderStatuses.REJECTED ? orderIssue.orderVolume : 0,
            });
        });
        setIssuesPerHourData(structureOfIssuePerHour);
    };

    if (!hasIssuesPerHourData) return null;

    return (
        <ReportSection title={translate('Reason of issues by hour')} loading={loading}>
            <Grid item xs={12}>
                <div className={classes.graphContainer}>
                    <BarChartStackedStyleCanvas
                        data={issuesPerHourData}
                        keys={['rejected', 'cancelled']}
                        indexBy={'menuId'}
                        labelY={translate('Amount')}
                        classes={{ container: classes.containerBarchart }}
                        tooltip={(tooltipData: { id: string; indexValue: string }) => (
                            <div className={classes.tooltip}>
                                <span className={classes.tooltipText}>{translate('Status: @status', { status: translate(tooltipData.id.toUpperCase()) })} </span>
                                <span className={classes.tooltipText}>{translate('Hour: @date', { date: tooltipData.indexValue })} </span>
                            </div>
                        )}
                    />
                </div>
            </Grid>
        </ReportSection>
    );
}

const useStyles = makeStyles((theme) => ({
    container: {
        width: '100%',
    },
    graphContainer: {
        width: '100%',
        height: 350,
        flexShrink: 0,
    },
    tooltip: {
        color: `#0D3037`,
        padding: 5,
        textTransform: 'capitalize',
        borderRadius: '10px',
        backgroundColor: 'whitesmoke',
    },
    tooltipText: {
        textTransform: 'uppercase',
        display: 'flex',
        flexDirection: 'column',
    },
    sectionHeadKpiMobile: {
        display: 'flex',
        width: '100%',
        justifyContent: 'center',
        flexWrap: 'wrap',
        [theme.breakpoints.up('md')]: {
            display: 'none',
        },
    },
    splitContainer: {
        display: 'flex',
        alignItems: 'center',
        width: '100%',
        [theme.breakpoints.between('xs', 'sm')]: {
            flexDirection: 'column',
        },
    },
    sectionHeadKpi: {
        display: 'flex',
        flexDirection: 'column',

        [theme.breakpoints.down('sm')]: {
            display: 'none',
        },
    },
    containerBarchart: {
        width: '100%',
        height: '100%',
    },
}));

type Props = {
    loading: boolean;
    issueReasonsByHourData: Array<NotCompleteOrdersByHour>;
};

type IssuesStatusStructureType = {
    menuId: string;
    cancelled?: number;
    rejected?: number;
};
