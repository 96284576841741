import { FormControlLabel, Switch } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core/styles';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import Typography from '@material-ui/core/Typography';
import moment from 'moment';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { CSSTransition } from 'react-transition-group';
import { letseatmanagerApiDeprecated } from 'src/api/letseatmanagerApiDeprecated';
import { app2 } from 'src/app2';
import { CountrySelect } from 'src/components/select/CountrySelect';
import { ExternalDeliveryProviders } from 'src/constants/ExternalDeliveryProviders';
import { OrderTypes } from 'src/constants/OrderType';
import { PickupTimeTypes } from 'src/constants/PickupTimeType';
import { SECONDS } from 'src/constants/TimeUnit';
import { translate } from 'src/i18n/translate';
import 'src/scenes/letseatadmin/currentOrders/AdminCurrentOrdersAnimations.css';
import { Loader } from '@pidedirecto/ui';
import { OrdersCarrousel } from 'src/scenes/letseatadmin/order/OrdersCarrousel';
import { SentryService } from 'src/services/SentryService';
import type { OrderVm } from 'src/types/OrderVm';
import { getErrorDescriptionFromResponse } from 'src/utils/error/getErrorDescriptionFromResponse';
import { useAction } from 'src/utils/react/useAction';
import { useInterval } from 'src/utils/react/useInterval';
import { useSelector } from 'src/utils/react/useSelector';

export function AdminCurrentOrders(): React.ReactElement {
    const classes = useStyles();

    const [filterType, setFilterType] = useState<any>();
    const [loading, setLoading] = useState(false);
    const [filterIntegrations, setFilterIntegrations] = useState(false);
    const [currentOrders, setCurrentOrders] = useState<Array<OrderVm>>([]);
    const [errorMessage, setErrorMessage] = useState<string>();
    const [countryCode, setCountryCode] = useState<string>();

    const cities = useSelector((state) => state.app2.cities);

    const setTitle = useAction(app2.actions.setTitle);

    let filteredOrders = currentOrders;
    if (filterType) {
        if (Object.values(PickupTimeTypes).includes(filterType)) {
            filteredOrders = currentOrders.filter(
                (order) =>
                    order.pickupTimeType === filterType &&
                    order.orderType === OrderTypes.DELIVERY_ORDER &&
                    !order.externalOrder &&
                    !order.deliverExternalOrder &&
                    !order.externalDelivery &&
                    !order.externalDeliveryProvider,
            );
            if (filterType === PickupTimeTypes.ASAP) {
                filteredOrders = [
                    ...filteredOrders,
                    ...currentOrders.filter(
                        (order) =>
                            order.pickupTimeType === PickupTimeTypes.PLANNED &&
                            order.orderType === OrderTypes.DELIVERY_ORDER &&
                            !order.externalOrder &&
                            !order.externalDelivery &&
                            !order.deliverExternalOrder &&
                            !order.externalDeliveryProvider &&
                            moment(order.pickupTime).subtract(15, 'minutes').isBefore(moment()),
                    ),
                ];
            }
        }
        if (Object.values(OrderTypes).includes(filterType)) {
            if (filterType === OrderTypes.TAKE_AWAY_ORDER) {
                filteredOrders = currentOrders.filter(
                    (order) =>
                        (order.orderType === OrderTypes.TAKE_AWAY_ORDER || order.externalDeliveryProvider === ExternalDeliveryProviders.RESTAURANT) &&
                        !order.externalOrder &&
                        !order.deliverExternalOrder,
                );
            }
            if (filterType === OrderTypes.DELIVERY_ORDER) {
                filteredOrders = currentOrders.filter(
                    (order) =>
                        order.orderType === OrderTypes.DELIVERY_ORDER &&
                        (!!order.deliverExternalOrder ||
                            order.externalDeliveryProvider === ExternalDeliveryProviders.UBER_EATS ||
                            order.externalDeliveryProvider === ExternalDeliveryProviders.UBER_DAAS),
                );
            }
        }

        if (Object.values(ExternalDeliveryProviders).includes(filterType)) {
            if (filterType === ExternalDeliveryProviders.RAPPI_CARGO) {
                filteredOrders = currentOrders.filter((order) => order.externalDeliveryProvider === ExternalDeliveryProviders.RAPPI_CARGO);
            }
        }

        if (Object.values(ExternalDeliveryProviders).includes(filterType)) {
            if (filterType === ExternalDeliveryProviders.UBER_DAAS) {
                filteredOrders = currentOrders.filter((order) => order.externalDeliveryProvider === ExternalDeliveryProviders.UBER_DAAS);
            }
        }
    }

    if (!filterIntegrations) {
        filteredOrders = filteredOrders.filter((order) => !order.externalOrder || order.deliverExternalOrder);
    }

    useEffect(() => {
        setTitle(translate('Ongoing Orders'));
        fetchOrders();
    }, []);

    useInterval(
        () => {
            fetchOrders();
        },
        4 * SECONDS,
        [loading, cities, countryCode],
    );

    async function fetchOrders() {
        if (loading) return;
        const request = {
            cities,
            countryCode,
        } as const;
        setLoading(true);
        const response = await letseatmanagerApiDeprecated.admin.currentOrders.fetch(request);
        setLoading(false);
        if (!response.ok) {
            SentryService.logInfoBreadcrumb('Failed to fetch the orders', { currentOrdersVmRequest: request, response });
            setErrorMessage(`Failed to fetch orders cause of ${getErrorDescriptionFromResponse(response)}`);
            return;
        }
        setErrorMessage(undefined);
        SentryService.logInfoBreadcrumb('Successfully fetched the orders', { currentOrdersVmRequest: request });
        const vm = response.data;
        setCurrentOrders(vm.currentOrders);
    }

    const handleCountryChange = (country: any) => {
        setCountryCode(country[0]);
        fetchOrders();
    };

    return (
        <div>
            <div style={{ position: 'absolute', right: 50 }}>{loading ? <Loader size={10} loading={true} /> : <span>{errorMessage}</span>}</div>
            <Grid container direction='row' justify='center'>
                <Grid item xs={6} className={classes.orderMaxWidth}>
                    <Paper square>
                        <Tabs
                            value={filterType}
                            onChange={(event, value) => {
                                setFilterType(value);
                            }}
                            indicatorColor='primary'
                            textColor='primary'
                            centered
                        >
                            <Tab label='ALL' value={null} onClick={() => setFilterType(undefined)} />
                            <Tab label='ASAP' value={PickupTimeTypes.ASAP} onClick={() => setFilterType(PickupTimeTypes.ASAP)} />
                            <Tab label='PLANNED' value={PickupTimeTypes.PLANNED} onClick={() => setFilterType(PickupTimeTypes.PLANNED)} />
                            <Tab label='EXTERNAL' value={OrderTypes.DELIVERY_ORDER} onClick={() => setFilterType(OrderTypes.DELIVERY_ORDER)} />
                            <Tab label='PICKUP' value={OrderTypes.TAKE_AWAY_ORDER} onClick={() => setFilterType(OrderTypes.TAKE_AWAY_ORDER)} />
                            <Tab label='RAPPI CARGO' value={ExternalDeliveryProviders.RAPPI_CARGO} onClick={() => setFilterType(ExternalDeliveryProviders.RAPPI_CARGO)} />
                            <Tab label='UBER DAAS' value={ExternalDeliveryProviders.UBER_DAAS} onClick={() => setFilterType(ExternalDeliveryProviders.UBER_DAAS)} />
                        </Tabs>
                    </Paper>
                </Grid>
            </Grid>
            <Grid container direction='row' justify='flex-end'>
                <div className={classes.subContainer}>
                    <div className={classes.input}>
                        <CountrySelect onChange={handleCountryChange} />
                    </div>
                    <FormControlLabel control={<Switch onChange={() => setFilterIntegrations(!filterIntegrations)} checked={filterIntegrations} />} label='Integration Orders' />
                </div>
            </Grid>
            <Typography className={classes.padding} />
            <OrdersCarrousel orders={filteredOrders} filterType={filterType} />

            <CSSTransition in={currentOrders.length === 0} timeout={1000} classNames='current-order-animation' unmountOnExit>
                <div className={classes.emptyDescription}>
                    <Typography variant='h3' align='center'>
                        {translate('No Ongoing Orders')}
                    </Typography>
                </div>
            </CSSTransition>
        </div>
    );
}

const useStyles = makeStyles((theme) => ({
    emptyDescription: {
        paddingTop: '200px',
        paddingBottom: '100px',
    },
    orderMaxWidth: {
        maxWidth: '1100px',
    },

    orderSpacing: {
        marginBottom: 16,
    },
    padding: {
        padding: theme.spacing(2),
    },
    subContainer: {
        display: 'flex',
        width: '100%',
        justifyContent: 'space-around',
        gap: '12px',
        marginTop: '12px',
    },
    input: {
        width: '200px',
    },
}));
