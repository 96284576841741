import { makeStyles } from '@material-ui/core';
import { Button, Text } from '@pidedirecto/ui';
import { BellIcon } from '@pidedirecto/ui/icons';
import { useState } from 'react';
import * as React from 'react';
import { translate } from 'src/i18n/translate';
import { SyncMenuIntegrationsDialog } from 'src/scenes/letseatmanager/menu/components/SyncMenuIntegrationsDialog';
import { useMenus } from 'src/services/menu/useMenus';
import type { MenuVm as DeprecatedMenuVm } from 'src/types/DeprecatedMenuVm';
import type { MenuVm } from 'src/types/MenuVm';
import { useSelector } from 'src/utils/react/useSelector';

export function MenuHasChangeAlert({ onError }: Props): React.ReactElement | null {
    const classes = useStyles();

    const [syncMenuIntegrationsDialogState, setSyncMenuIntegrationsDialogState] = useState<{ open: boolean; menus: Array<MenuVm> | Array<DeprecatedMenuVm> | undefined }>({
        open: true,
        menus: undefined,
    });

    const { menus, deprecatedMenus } = useMenus();

    const newMenuManagementEnabled = useSelector((state) => state.app.restaurant.newMenuManagementEnabled);

    const menuHasPendingChanges = menus.some((menu) => menu.hasPendingChangesToSync);
    const deprecatedMenuHasPendingChanges = deprecatedMenus.some((menu) => menu.hasPendingChangesToSync);

    const openSyncMenuIntegrationsDialog = () => {
        let menusWithChanges: Array<MenuVm> | Array<DeprecatedMenuVm>;
        if (newMenuManagementEnabled) {
            menusWithChanges = menus.filter((menu) => menu.hasPendingChangesToSync);
        } else {
            menusWithChanges = deprecatedMenus.filter((menu) => menu.hasPendingChangesToSync);
        }
        setSyncMenuIntegrationsDialogState({ open: true, menus: menusWithChanges });
    };

    const closeSyncMenuIntegrationsDialog = () => setSyncMenuIntegrationsDialogState({ open: false, menus: undefined });

    if (!menuHasPendingChanges && !deprecatedMenuHasPendingChanges) return null;

    return (
        <div className={classes.alertHasChanges}>
            <SyncMenuIntegrationsDialog open={syncMenuIntegrationsDialogState.open} onClose={closeSyncMenuIntegrationsDialog} menus={syncMenuIntegrationsDialogState.menus} onError={onError} />
            <div className={classes.alertTitle}>
                <div className={classes.icon}>
                    <BellIcon title={'campaign'} />
                </div>
                <Text>{translate('You have changes in the menu without updating')}</Text>
            </div>
            <Button variant='text' color='#154A61' classes={{ button: classes.updateButton }} onClick={openSyncMenuIntegrationsDialog}>
                {translate('Update')}
            </Button>
        </div>
    );
}

const useStyles = makeStyles((theme) => ({
    alertHasChanges: {
        display: 'flex',
        width: '100%',
        justifyContent: 'space-between',
        alignItems: 'center',
        backgroundColor: '#EAF7FD',
        borderStyle: 'solid',
        borderColor: '#B4CFE8',
        borderRadius: 5,
        padding: '4px 12px',
        marginBottom: 24,
    },
    alertTitle: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        color: '#154A61',
        fontWeight: 600,
    },
    updateButton: {
        color: '#154A61',
        textDecoration: 'underline',
        width: 'fit-content',
    },
    icon: {
        width: '20px',
        height: '20px',
        marginRight: 10,
        marginLeft: 10,
    },
}));

type Props = {
    onError?: any;
};
