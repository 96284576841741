import * as React from 'react';

export function PeopleOutlineIcon({ color, title }: Props): React.ReactElement {
    return (
        <svg width='13' height='12' viewBox='0 0 13 12' fill='none' xmlns='http://www.w3.org/2000/svg'>
            {title && <title>{title}</title>}
            <path
                d='M8.16701 9.95999C8.64089 10.0976 9.1319 10.1672 9.62534 10.1667C10.4189 10.1678 11.2021 9.98687 11.9148 9.63777C11.9359 9.13983 11.794 8.64859 11.5108 8.23851C11.2275 7.82843 10.8183 7.52186 10.3452 7.36528C9.87203 7.20869 9.36073 7.21062 8.88877 7.37077C8.41681 7.53092 8.00993 7.84057 7.72979 8.25277M8.16701 9.95999V9.95833C8.16701 9.33999 8.00812 8.75833 7.72979 8.25277M8.16701 9.95999V10.0189C7.09784 10.6628 5.87291 11.0021 4.62479 11C3.32979 11 2.11813 10.6417 1.08369 10.0189L1.08313 9.95833C1.0827 9.17192 1.34403 8.40773 1.8259 7.78625C2.30777 7.16477 2.98278 6.72135 3.74451 6.52588C4.50624 6.33042 5.31136 6.39404 6.03294 6.70671C6.75452 7.01938 7.35151 7.56332 7.72979 8.25277M6.50035 2.875C6.50035 3.37228 6.3028 3.84919 5.95117 4.20082C5.59954 4.55245 5.12263 4.75 4.62535 4.75C4.12807 4.75 3.65116 4.55245 3.29953 4.20082C2.94789 3.84919 2.75035 3.37228 2.75035 2.875C2.75035 2.37772 2.94789 1.9008 3.29953 1.54917C3.65116 1.19754 4.12807 1 4.62535 1C5.12263 1 5.59954 1.19754 5.95117 1.54917C6.3028 1.9008 6.50035 2.37772 6.50035 2.875ZM11.0837 4.125C11.0837 4.51177 10.93 4.8827 10.6565 5.15619C10.3831 5.42968 10.0121 5.58333 9.62534 5.58333C9.23857 5.58333 8.86764 5.42968 8.59415 5.15619C8.32066 4.8827 8.16701 4.51177 8.16701 4.125C8.16701 3.73822 8.32066 3.36729 8.59415 3.0938C8.86764 2.82031 9.23857 2.66667 9.62534 2.66667C10.0121 2.66667 10.3831 2.82031 10.6565 3.0938C10.93 3.36729 11.0837 3.73822 11.0837 4.125Z'
                stroke={color ?? '#2E3748'}
                strokeLinecap='round'
                strokeLinejoin='round'
            />
        </svg>
    );
}

type Props = {
    title?: string;
    color?: string;
};
