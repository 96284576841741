import * as React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import type { DeliveryProvider } from 'src/constants/DeliveryProviders';
import { DeliveryProvidersDraggableList } from 'src/scenes/letseatadmin/theRestaurant/DeliveryProvidersDraggableList';

export function FormDeliveryProvidersDraggableList({
    nameOrderValues,
    nameValues,
    waitTimePerDeliveryProviders = {},
    orderDeliveryProvider,
    filterDeliveryProviders,
    title,
}: Props): React.ReactElement {
    const {
        errors,
        control,
        formState: { isSubmitting },
    } = useFormContext();

    return (
        <Controller
            name={nameOrderValues}
            render={({ value, onChange: onOrderChange, ...props }) => (
                <Controller
                    name={nameValues}
                    render={({ value, onChange: onValueChange, ...props }) => (
                        <DeliveryProvidersDraggableList
                            waitTimePerDeliveryProviders={waitTimePerDeliveryProviders}
                            orderDeliveryProvider={orderDeliveryProvider}
                            filterDeliveryProviders={filterDeliveryProviders}
                            onChange={(deliveryProviders: Array<DeliveryProvider>) => {
                                const orderValue = deliveryProviders?.map?.((val: any) => val.deliveryProvider) ?? [];
                                const waitTimeValue =
                                    deliveryProviders.reduce?.((waitTimeValue: any, valueDp: any) => {
                                        waitTimeValue[deliveryProvidersFormat[valueDp.deliveryProvider as keyof typeof deliveryProvidersFormat] as keyof typeof waitTimeValue] = valueDp.waitTime;
                                        return waitTimeValue;
                                    }, {}) ?? [];

                                onOrderChange(orderValue);
                                onValueChange(waitTimeValue);
                            }}
                            title={title}
                        />
                    )}
                    control={control}
                    defaultValue={orderDeliveryProvider ?? []}
                />
            )}
            control={control}
            defaultValue={[]}
        />
    );
}

const deliveryProvidersFormat = {
    UBER_DAAS: 'uberDaasWaitTime',
    RAPPI_CARGO: 'rappiCargoWaitTime',
    PIDEDIRECTO: 'pidedirectoWaitTime',
} as const;

type Props = {
    nameOrderValues: string;
    nameValues: string;
    title?: string;
    waitTimePerDeliveryProviders?: any;
    orderDeliveryProvider?: Array<DeliveryProvider>;
    filterDeliveryProviders?: Array<DeliveryProvider>;
};
