import { makeStyles } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import { useEffect } from 'react';
import * as React from 'react';
import { useForm } from 'react-hook-form';
import { Button } from 'src/components/Button';
import { Dialog } from 'src/components/Dialog';
import { DialogActions } from 'src/components/DialogActions';
import { Form } from 'src/components/form/Form';
import { FormTableShapeSelect } from 'src/components/form/FormTableShapeSelect';
import { FormTableSizeSelect } from 'src/components/form/FormTableSizeSelect';
import { FormTextField } from 'src/components/form/FormTextField';
import type { TableShape } from 'src/constants/TableShape';
import type { TableSize } from 'src/constants/TableSize';
import { translate } from 'src/i18n/translate';
import type { RestaurantZoneId } from 'src/types/Id';

export function ChangeTableDialog({ open, table, restaurantZoneId, handleClose, onSuccess }: Props): React.ReactElement {
    const classes = useStyles();
    const form = useForm();

    useEffect(() => {
        if (table) {
            form.reset({
                tableNumber: table.tableNumber,
                tableSize: table.tableSize,
                tableShape: table.tableShape,
            });
        }
    }, [table]);

    const handleSubmit = (form: any) => {
        onSuccess({
            restaurantZoneId,
            tableNumber: form.tableNumber.toString(),
            prevTableNumber: table?.tableNumber,
            tableSize: form.tableSize,
            tableShape: form.tableShape,
        });
    };

    return (
        <Dialog title={translate('Change Table')} open={open} onClose={handleClose}>
            <Form onSubmit={handleSubmit} form={form} className={classes.form}>
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <FormTextField name='tableNumber' label={translate('Table number')} required />
                    </Grid>
                    <Grid item xs={12}>
                        <FormTableSizeSelect name='tableSize' label={translate('Size')} required />
                    </Grid>
                    <Grid item xs={12}>
                        <FormTableShapeSelect name='tableShape' label={translate('Shape')} required />
                    </Grid>
                </Grid>
                <DialogActions className={classes.buttonsContainer}>
                    <Button secondary type='button' onClick={handleClose}>
                        {translate('Cancel')}
                    </Button>
                    <Button primary type='submit'>
                        {translate('Change')}
                    </Button>
                </DialogActions>
            </Form>
        </Dialog>
    );
}

const useStyles = makeStyles((theme) => ({
    form: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    buttonsContainer: {
        [theme.breakpoints.down('sm')]: {
            flexDirection: 'column',
        },
    },
}));

type Props = {
    open: boolean;
    table?: {
        restaurantZoneId: RestaurantZoneId;
        tableNumber: string;
        tableSize: TableSize;
        tableShape: TableShape;
    };
    restaurantZoneId?: RestaurantZoneId;
    handleClose: any;
    onSuccess: any;
};
