import moment from 'moment-timezone';
import * as React from 'react';
import { memo } from 'react';
import { MomentFormats } from 'src/constants/MomentFormat';
import { TimeZones } from 'src/constants/TimeZone';
import TimeLineBarChart, { Color, PeriodScale, PeriodScales, pushDataValue, XAxisScale, XAxisScales } from 'src/scenes/letseatadmin/statistics/TimeLineBarChart';
import type { StatisticsVm } from 'src/scenes/letseatadmin/StatisticsPage';
import { daysFromToday } from 'src/utils/date/daysFromToday';
import { formatAsNumber } from 'src/utils/number/formatAsNumber';

type Props = {
    statistics?: StatisticsVm;
    period: PeriodScale;
    xAxis: XAxisScale;
};

export const CustomerActivityChart: React.ComponentType<Props> = memo<Props>(function CustomerActivityChart({ statistics, period, xAxis }: Props): React.ReactElement | null {
    if (!statistics) {
        return null;
    }

    return (
        <TimeLineBarChart
            data={statisticsToData(statistics, period, xAxis)}
            period={period}
            colors={[Color.GREEN, Color.GREY_LIGHT]}
            yAxisLegend={''}
            yAxisFormat={(value) => formatAsNumber(`${value}`)}
            yAxisTooltipFormat={(value) => formatAsNumber(`${value}`)}
            xAxisTooltipFormat={xAxisTooltipFormat(xAxis)}
            xAxisFormat={xAxisFormat}
        />
    );
});

function statisticsToData(statistics: StatisticsVm, period: PeriodScale, xAxis: XAxisScale) {
    const latestStatistic = statistics[statistics.length - 1];
    if (!latestStatistic) {
        return [];
    }
    const customerActivityStatistics = latestStatistic.customer.customerActivity.map((count, index) => {
        return {
            date: moment.tz(latestStatistic.date, TimeZones.AMERICA_MONTERREY).subtract(index, 'days').format(MomentFormats.ISO_DATE),
            count,
        };
    });

    return customerActivityStatistics.reduce(
        (chartData, statistic) => {
            pushDataValue(chartData[0].data, statistic.date, statistic.count, period, xAxis);
            return chartData;
        },
        [{ id: 'Customers', data: [] }],
    );
}

export function xAxisTooltipFormat(xAxis: XAxisScale): (arg1: string) => string {
    return (date: string) => {
        if (xAxis === XAxisScales.DAY) {
            return `D${daysFromToday(date, TimeZones.AMERICA_MONTERREY)}`;
        } else if (xAxis === XAxisScales.WEEK) {
            const oneWeekLater = moment.tz(date, TimeZones.AMERICA_MONTERREY).add(1, 'week').toDate();
            return `D${daysFromToday(date, TimeZones.AMERICA_MONTERREY)}-${daysFromToday(oneWeekLater, TimeZones.AMERICA_MONTERREY)}`;
        }
        const oneMonthLater = moment.tz(date, TimeZones.AMERICA_MONTERREY).add(1, 'month').toDate();
        return `D${daysFromToday(date, TimeZones.AMERICA_MONTERREY)}-${daysFromToday(oneMonthLater, TimeZones.AMERICA_MONTERREY)}`;
    };
}

export function xAxisFormat(period: PeriodScale): (arg1: Date) => string {
    return (date: Date) => {
        if (period === PeriodScales.TWO_WEEKS) {
            return `D${daysFromToday(date, TimeZones.AMERICA_MONTERREY)}`;
        } else if (period === PeriodScales.THREE_MONTHS) {
            const oneWeekLater = moment.tz(date, TimeZones.AMERICA_MONTERREY).add(1, 'week').toDate();
            return `D${daysFromToday(date, TimeZones.AMERICA_MONTERREY)}-${daysFromToday(oneWeekLater, TimeZones.AMERICA_MONTERREY)}`;
        }
        const oneMonthLater = moment.tz(date, TimeZones.AMERICA_MONTERREY).add(1, 'month').toDate();
        return `D${daysFromToday(date, TimeZones.AMERICA_MONTERREY)}-${daysFromToday(oneMonthLater, TimeZones.AMERICA_MONTERREY)}`;
    };
}
