import { letseatadminApiMethod } from 'src/api/letseatadminApi';
import type { ApiSauceResponse } from 'src/api/types/ApiSauceResponse';
import type { RestaurantId } from 'src/types/Id';

export async function getRestaurantSalesApi(request: getRestaurantSalesApiRequest): ApiSauceResponse<RestaurantSalesVm> {
    return letseatadminApiMethod('restaurantSales/getRestaurantSalesApi', request);
}

export type getRestaurantSalesApiRequest = {
    restaurantId: RestaurantId;
};

export type RestaurantSalesVm = {
    restaurantName: string;
    weeksData: Array<RestaurantSalesWeekDataVm>;
};

export type RestaurantSalesWeekDataVm = {
    week: number;
    sale: number;
};
