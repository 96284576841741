import { makeStyles } from '@material-ui/core';
import moment from 'moment';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { useForm, useWatch } from 'react-hook-form';
import { AbcTable, getAbcRestaurantStatisticsApi } from 'src/api/letseatmanager/restaurantDashboard/getAbcRestaurantStatisticsApi';
import { Form } from 'src/components/form/Form';
import { FormSelect } from 'src/components/form/FormSelect';
import { Table } from 'src/components/Table';
import { translate } from 'src/i18n/translate';
import { FilterMasterReports } from 'src/scenes/letseatmanager/restaurantDashboard/FilterMasterReports';
import { FilterReports } from 'src/scenes/letseatmanager/restaurantDashboard/FilterReports';
import { ReportSection } from 'src/scenes/letseatmanager/restaurantDashboard/ReportSection';
import { ReportSectionEmptyState } from 'src/scenes/letseatmanager/restaurantDashboard/ReportSectionEmptyState';
import { useFormatAsRestaurantCurrencyNumber } from 'src/services/restaurant/useFormatAsRestaurantCurrencyNumber';
import { RestaurantId } from 'src/types/Id';
import { useFormatDateToRestaurantTimeZone } from 'src/utils/react/useFormatDateToRestaurantTimeZone';
import { useLoadApi } from 'src/utils/react/useLoadApi';
import { useSelector } from 'src/utils/react/useSelector';

export function AbcReports(): React.ReactElement {
    const classes = useStyles();
    const form = useForm();
    const { control } = form;
    const formatAsCurrencyNumber = useFormatAsRestaurantCurrencyNumber();

    const [abcData, setAbcData] = useState<Array<AbcDataType>>([]);
    const [hasInfoFound, setHasInfoFound] = useState(false);
    const [showAsMaster, setShowAsMaster] = useState(false);

    const restaurantId = useSelector((state) => state.app.restaurantId);
    const restaurantIds = useSelector((state) => state.app.restaurantIds);
    const brandOpened = useSelector((state) => state.app.brandOpened);
    const period = useSelector((state) => state.app2.filterReportsState.period);
    const currentRestaurantId = useSelector((state) => state.app2.filterReportsState.currentRestaurantId);
    const salesType = useSelector((state) => state.app.salesTypeFilter);

    const formatDateToRestaurantTimeZone = useFormatDateToRestaurantTimeZone();

    const currentAbcFilter = useWatch({ name: 'abcTypeFilters', control, defaultValue: 'percentageOfSales' });

    const abcFilter = [
        {
            label: translate('% Sale'),
            value: 'percentageOfSales',
        },
        {
            label: translate('Order'),
            value: 'orderVolume',
        },
        {
            label: translate('Total'),
            value: 'total',
        },
    ];

    const [loading, reports] = useLoadApi(
        getAbcRestaurantStatisticsApi,
        {
            report: showAsMaster ? 'asBrand' : 'perRestaurant',
            abcTableFilter: currentAbcFilter ?? abcFilter[0].value,
            restaurantIds: brandOpened ? restaurantIds : [restaurantId],
            fromDate: moment(formatDateToRestaurantTimeZone(period?.from)).toISOString(),
            toDate: moment(formatDateToRestaurantTimeZone(period?.to)).toISOString(),
            salesType,
        },
        {
            dependencies: [period, brandOpened, showAsMaster, currentAbcFilter, restaurantId, salesType],
        },
    );

    useEffect(() => {
        if (reports) {
            if (showAsMaster || !brandOpened) return handleUpdateAbcReports(reports[0]?.restaurantId);

            handleUpdateAbcReports(currentRestaurantId ?? reports[0].restaurantId);
        }
    }, [reports, showAsMaster, currentAbcFilter, currentRestaurantId]);

    const hasRestaurantReportInfo = (
        currentRestaurant:
            | undefined
            | {
                  abcTable: Array<AbcTable>;
                  name: string;
                  restaurantId: RestaurantId;
              },
    ) => currentRestaurant && currentRestaurant.abcTable;

    const handleUpdateAbcReports = (newRestaurantId: RestaurantId) => {
        const currentRestaurantReportInfo = reports?.find((restaurant: { abcTable: Array<AbcTable>; name: string; restaurantId: RestaurantId }) => restaurant.restaurantId === newRestaurantId);
        if (!hasRestaurantReportInfo(currentRestaurantReportInfo)) {
            setHasInfoFound(false);
            return;
        }

        getSalePerAbcFromReport(currentRestaurantReportInfo?.abcTable);
        setHasInfoFound(true);
    };

    const getSalePerAbcFromReport = (abcItems: undefined | Array<AbcTable>) => {
        if (!abcItems) {
            setAbcData([]);
            return;
        }
        const abcData = abcItems.map((abcItem) => ({
            name: abcItem.itemName,
            itemPrice: formatAsCurrencyNumber(abcItem.itemPrice),
            amount: abcItem.orderVolume,
            total: formatAsCurrencyNumber(abcItem.total),
            percentageOfSales: `${abcItem.percentageOfSales}%`,
            abc: abcItem.abc,
        }));

        setAbcData(abcData);
    };

    const abcColumns = [
        {
            name: 'name',
            label: translate('Name'),
            options: {
                filter: true,
            },
        },
        {
            name: 'itemPrice',
            label: translate('Price'),
            options: {
                filter: true,
            },
        },
        {
            name: 'amount',
            label: translate('Amount'),
            options: {
                filter: false,
            },
        },
        {
            name: 'total',
            label: translate('Total Sales'),
            options: {
                filter: false,
            },
        },
        {
            name: 'percentageOfSales',
            label: translate('Percent of sales'),
            options: {
                filter: false,
            },
        },
        {
            name: 'abc',
            label: translate('ABC Classification'),
            options: {
                filter: false,
            },
        },
    ];

    const handleShowAsMaster = (value: any) => {
        setShowAsMaster(value);
    };

    return (
        <div>
            <FilterReports />
            <div className={classes.container}>
                <FilterMasterReports onChangeMaster={handleShowAsMaster} showAsMaster={showAsMaster} />
                {hasInfoFound && (
                    <div>
                        <Form form={form}>
                            <div className={classes.selectContainer}>
                                <FormSelect name={'abcTypeFilters'} options={abcFilter} defaultValue={abcFilter[0].value} />
                            </div>
                        </Form>
                        <ReportSection title={translate('ABC Table')} loading={loading}>
                            <div className={classes.infoContainer}>
                                <div className={classes.tableContainer}>
                                    <Table
                                        className={(classes as any).table}
                                        data={abcData}
                                        columns={abcColumns}
                                        options={{
                                            tableBodyMaxHeight: '100%',
                                            responsive: 'standard',
                                            selectableRows: 'none',
                                            filter: false,
                                            rowsPerPage: 100,
                                            viewColumns: false,
                                            print: false,
                                        }}
                                    />
                                </div>
                            </div>
                        </ReportSection>
                    </div>
                )}

                {!hasInfoFound && !loading && <ReportSectionEmptyState />}
            </div>
        </div>
    );
}

const useStyles = makeStyles((theme) => ({
    container: {
        width: '100%',
    },
    infoContainer: {
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        gap: 24,
        [theme.breakpoints.down('sm')]: {
            flexDirection: 'column',
        },
    },
    tableContainer: {
        width: '100%',
        [theme.breakpoints.down('sm')]: {
            width: '100%',
        },
    },
    selectContainer: {
        width: '30%',
        display: 'flex',
        justifyContent: 'flex-end',
        gap: 24,
        [theme.breakpoints.down('sm')]: {
            justifyContent: 'center',
            width: '100%',
        },
    },
}));

type AbcDataType = {
    name: string;
    itemPrice: string;
    amount: number;
    total: string;
    percentageOfSales?: string;
    abc: string;
};
