import { makeStyles } from '@material-ui/core';
import * as React from 'react';
import { useState } from 'react';
import { GoBackButton } from 'src/components/button/GoBackButton';
import { translate } from 'src/i18n/translate';
import { PosUserClockInDetails } from 'src/scenes/letseatmanager/pos/posUserLockscreen/PosUserClockInDetails';
import { PosUserLockScreenMessages } from 'src/scenes/letseatmanager/pos/posUserLockscreen/PosUserLockScreenMessages';
import { PosUserLockscreenNumpad } from 'src/scenes/letseatmanager/pos/posUserLockscreen/PosUserLockscreenNumpad';
import { getBackgroundPattern } from 'src/services/posUserLockscreen/getBackgroundPattern';
import type { PinCodeUser } from 'src/types/PinCodeUser';
import { useClock } from 'src/utils/react/useClock';
import { useSelector } from 'src/utils/react/useSelector';

export function PosUserLockscreen(): React.ReactElement {
    const classes = useStyles();

    const [sections, setSections] = useState([]);
    const [pinCodeUser, setPinCodeUser] = useState<PinCodeUser | undefined>();

    const restaurant = useSelector((state) => state.app.restaurant);
    const restaurantUser = useSelector((state) => state.authentication.restaurantUser);

    const [time, date] = useClock();

    const onClockIn = (pinCodeUser: any) => setPinCodeUser(pinCodeUser);

    const resetPinCodeUserTryingClockIn = () => setPinCodeUser(undefined);

    return (
        <div className={classes.container} style={{ backgroundImage: getBackgroundPattern(), paddingTop: restaurantUser ? 0 : 90 }}>
            <div className={classes.content}>
                {pinCodeUser && <GoBackButton label={translate('Return')} classes={{ button: classes.button }} goBack={resetPinCodeUserTryingClockIn} />}
                {restaurant.logoImageUrl && <img alt={'restaurant logo'} aria-label={'restaurantLogo'} src={restaurant.logoImageUrl} className={classes.image} />}

                <div className={classes.dateAndTime}>
                    <p className={classes.time}>{time}</p>
                    <p className={classes.date}>{date}</p>
                </div>
                {!pinCodeUser && <PosUserLockscreenNumpad onClockIn={onClockIn} />}
                {pinCodeUser && <PosUserClockInDetails onAccept={resetPinCodeUserTryingClockIn} pinCodeUser={pinCodeUser} />}
                <div className={classes.section}>{sections?.map((section: any, idx: any) => <PosUserLockScreenMessages key={`${section.name}-${idx}`} section={section} />)}</div>
            </div>
        </div>
    );
}

const useStyles = makeStyles((theme) => ({
    container: {
        width: '100%',
        height: '100vh',
        padding: 0,
        backgroundColor: theme.palette.surface.invert,
        backgroundAttachment: 'fixed',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    section: {
        width: '80%',
        display: 'flex',
        margin: '30px auto',
        justifyContent: 'center',
        gridArea: 'messages',
        [theme.breakpoints.up('md')]: {
            width: '100%',
            height: 220,
            overflowY: 'scroll',
            '&::-webkit-scrollbar': {
                display: 'none',
            },
        },
    },
    image: {
        width: 'auto',
        maxWidth: 200,
        margin: 'auto',
        gridArea: 'logo',
        [theme.breakpoints.up('sm')]: {
            maxWidth: 300,
        },
        [theme.breakpoints.up('lg')]: {
            maxHeight: 350,
            maxWidth: 400,
        },
    },
    content: {
        display: 'grid',
        height: 'calc(100vh - 90px)',
        width: '100%',
        gridTemplateAreas: `'logo'
        'date'
        'numericPad'
        'messages'`,
        justifyContent: 'center',
        alignContent: 'center',
        alignItems: 'center',
        gap: 10,
        paddingTop: 100,
        overflowY: 'scroll',
        '&::-webkit-scrollbar': {
            display: 'none',
        },
        [theme.breakpoints.up('md')]: {
            paddingTop: 50,
            gap: 30,
            gridTemplateAreas: `'logo numericPad'
            'date numericPad'
            'messages numericPad'`,
        },
    },
    dateAndTime: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        marginTop: 24,
        marginBottom: 36,
        gridArea: 'date',
        [theme.breakpoints.up('md')]: {
            margin: 0,
            width: 500,
        },
    },
    time: {
        color: 'white',
        fontSize: 36,
        fontWeight: 500,
        fontFamily: theme.typography.semiBold,
        margin: 0,
        [theme.breakpoints.up('lg')]: {
            fontSize: 50,
        },
    },
    date: {
        color: 'white',
        fontSize: 14,
        fontFamily: theme.typography.medium,
        margin: 0,
        [theme.breakpoints.up('lg')]: {
            fontSize: 20,
        },
    },
    button: {
        color: '#FFF',
        top: 100,
        left: 30,
    },
}));
