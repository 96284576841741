import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import * as React from 'react';
import { useForm } from 'react-hook-form';
import { importRestaurantMenuFromExternalUrlApi } from 'src/api/letseatadmin/menu/importRestaurantMenuFromExternalUrlApi';
import { app2 } from 'src/app2';
import { Button } from 'src/components/Button';
import { Dialog } from 'src/components/Dialog';
import { DialogActions } from 'src/components/DialogActions';
import { Form } from 'src/components/form/Form';
import { FormCheckbox } from 'src/components/form/FormCheckbox';
import { FormFixErrorHint } from 'src/components/form/FormFixErrorHint';
import { FormSubmitButton } from 'src/components/form/FormSubmitButton';
import { FormUrlField } from 'src/components/form/FormUrlField';
import { translate } from 'src/i18n/translate';
import { appReducer } from 'src/reducers/appReducer';
import { alertKnownErrorOrSomethingWentWrong } from 'src/utils/alert/alertKnownErrorOrSomethingWentWrong';
import { useAction } from 'src/utils/react/useAction';
import { useSelector } from 'src/utils/react/useSelector';

export function ImportRestaurantMenuDialog(): React.ReactElement {
    const form = useForm();
    const {
        formState: { isSubmitting },
    } = form;
    const classes = useStyles();

    const open = useSelector((state) => state.app2.importRestaurantMenuDialog.open);
    const onSuccess = useSelector((state) => state.app2.importRestaurantMenuDialog.onSuccess);
    const restaurantId = useSelector((state) => state.app.restaurantId);

    const closeImportRestaurantMenuDialog = useAction(app2.actions.closeImportRestaurantMenuDialog);
    const showSnackbar = useAction(appReducer.actions.showSnackbar);

    const handleClose = () => {
        if (isSubmitting) return;
        closeImportRestaurantMenuDialog();
    };

    const onSubmit = async (form: any) => {
        let source;
        if (form.url.includes('rappi')) {
            source = 'Rappi';
        } else if (form.url.includes('ubereats')) {
            source = 'UberEats';
        } else {
            source = 'External URL';
        }

        const response = await importRestaurantMenuFromExternalUrlApi({
            restaurantId: restaurantId,
            url: form.url,
            importImages: form.importImages,
        });
        if (!response.ok) {
            if (response.problem === 'NETWORK_ERROR') {
                alert(`The menu from ${source} will be imported in the background, you should see it if you refresh the page in a couple of minutes`);
                return;
            }
            alertKnownErrorOrSomethingWentWrong(response);
            return;
        }

        showSnackbar({ message: translate(`Imported Restaurant Menu From ${source}`) });
        onSuccess?.();
        closeImportRestaurantMenuDialog();
    };

    return (
        <Dialog open={open} onClose={handleClose} title={translate('Import Menu from External URL')}>
            <Form onSubmit={onSubmit} form={form} className={classes.form}>
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <FormUrlField
                            name='url'
                            label={translate('URL to menu')}
                            helperText={translate('The provided url to the menu e.g. "https://www.rappi.com.mx/restaurantes/1923381245" or only the url id "1923381245"')}
                            required
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <FormCheckbox name='importImages' label={translate('Import images (may not import whole menu if images are to large)')} />
                    </Grid>
                </Grid>
                <FormFixErrorHint />
                <DialogActions>
                    <Button secondary onClick={handleClose} disabled={isSubmitting}>
                        {translate('Cancel')}
                    </Button>
                    <FormSubmitButton text={isSubmitting ? translate('Importing') : translate('Import')} />
                </DialogActions>
            </Form>
        </Dialog>
    );
}

const useStyles = makeStyles((theme) => ({
    form: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
}));
