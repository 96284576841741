/**
 * @prettier
 */
import { makeStyles } from '@material-ui/core';
import { Button, Dialog, DialogActions, DialogContent, Text } from '@pidedirecto/ui';
import * as React from 'react';
import { translate } from 'src/i18n/translate';

export function ShowOrderQrCodeDialog({ open, onClose, qrCode, validationCode }: Props): React.ReactElement {
    const classes = useStyles();

    const handleClose = () => {
        onClose();
    };

    return (
        <Dialog title={translate(`QR CODE`)} classes={{ dialog: classes.dialog }} open={open} onClose={handleClose}>
            <DialogContent>
                <img src={`data:image/jpeg;base64,${qrCode}`} />
                <Text size={'small'}>{translate('Validation code: @validationCode', { validationCode })}</Text>
            </DialogContent>
            <DialogActions>
                <Button variant='secondary' onClick={handleClose}>
                    {translate('Close')}
                </Button>
            </DialogActions>
        </Dialog>
    );
}

const useStyles = makeStyles((theme) => ({
    dialog: {
        overflow: 'hidden',
    },
}));

type Props = {
    open: boolean;
    onClose: () => void;
    qrCode: string;
    validationCode: string;
};
