import { pidedirectouploadApi } from 'src/api/pidedirectouploadApi';
import type { ImageType } from 'src/constants/ImageType';

export async function uploadImage(params: UploadImageParams): Promise<UploadImageResponse> {
    try {
        const uploadRequestResponse = await pidedirectouploadApi.images.requestUpload({ fileName: params.fileName });
        if (!uploadRequestResponse.ok) {
            return {
                error: true,
            };
        }

        const s3Response = await fetch(uploadRequestResponse.data, {
            method: 'PUT',
            body: params.imageBlob,
        });
        if (!s3Response.ok) {
            return {
                error: true,
            };
        }

        const uploadResponse = await pidedirectouploadApi.images.upload({ fileName: params.fileName, imageType: params.imageType });
        if (!uploadResponse.ok) {
            return {
                error: true,
            };
        }

        return {
            imageUrl: uploadResponse.data,
            error: false,
        };
    } catch (e: any) {
        return {
            error: true,
        };
    }
}

type UploadImageParams = {
    fileName: string;
    imageBlob: Blob;
    type: string;
    imageType: ImageType;
};

type UploadImageResponse = {
    imageUrl?: string;
    error: boolean;
};
