import { letseatmanagerApiMethod } from 'src/api/letseatmanagerApi';
import type { ApiSauceResponse } from 'src/api/types/ApiSauceResponse';
import type { RestaurantId } from 'src/types/Id';

export async function closeRappiStoreApi(request: CloseRappiStoreApiRequest): ApiSauceResponse<any> {
    return letseatmanagerApiMethod('integration/closeRappiStoreApi', request);
}

type CloseRappiStoreApiRequest = {
    restaurantId: RestaurantId;
};
