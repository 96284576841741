import { makeStyles } from '@material-ui/core';
import DialogContent from '@material-ui/core/DialogContent';
import Grid from '@material-ui/core/Grid';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { addSubscriptionCardApi } from 'src/api/letseatmanager/subscription/addSubscriptionCardApi';
import { app2 } from 'src/app2';
import { Button } from 'src/components/Button';
import { Dialog } from 'src/components/Dialog';
import { DialogActions } from 'src/components/DialogActions';
import { Form } from 'src/components/form/Form';
import { FormCardDetailsField } from 'src/components/form/FormCardDetailsField';
import { createConektaCardToken } from 'src/facade/conekta/createConektaCardToken';
import { createMercadoPagoCardToken } from 'src/facade/mercadoPago/createMercadoPagoCardToken';
import { createStripeCardToken } from 'src/facade/stripe/createStripeCardToken';
import { translate } from 'src/i18n/translate';
import { alertKnownErrorOrSomethingWentWrong } from 'src/utils/alert/alertKnownErrorOrSomethingWentWrong';
import { encryptDataInTransit } from 'src/utils/crypto/encryptDataInTransit';
import { useAction } from 'src/utils/react/useAction';
import { useSelector } from 'src/utils/react/useSelector';
import { requireValue } from 'src/utils/require/requireValue';
import { removeSpecialCharacters } from 'src/utils/string/removeSpecialCharacters';
import { trim } from 'src/utils/string/trim';

export function AddNewCardDialog(): React.ReactElement {
    const classes = useStyles();
    const form = useForm();

    const [loading, setLoading] = useState(false);
    const [deviceId, setDeviceId] = useState('');

    const subscriptionId = useSelector((state) => state.app.restaurant.subscriptionId);
    const restaurantName = useSelector((state) => state.app.restaurant.name);
    const open = useSelector((state) => state.app2.addNewCardDialog.open);
    const cardPriority = useSelector((state) => state.app2.addNewCardDialog.cardPriority);
    const businessName = useSelector((state) => state.app2.addNewCardDialog.businessName);
    const onSuccess = useSelector((state) => state.app2.addNewCardDialog.onSuccess);
    const country = useSelector((state) => state.app.restaurant?.country);

    const closeAddNewCardDialog = useAction(app2.actions.closeAddNewCardDialog);

    useEffect(() => {
        const script = document.createElement('script');
        script.src = 'https://www.mercadopago.com/v2/security.js';
        script.setAttribute('view', 'checkout');
        script.async = true;
        script.onload = () => {
            setDeviceId(window.MP_DEVICE_SESSION_ID);
        };
        document?.body?.appendChild(script);
    }, [open]);

    const onSubmit = async (form: any) => {
        setLoading(true);
        const conektaResponse = await createConektaCardToken({
            ...form.card,
            name: removeSpecialCharacters(trim(businessName) || trim(restaurantName)), // Conekta requires card name, use bussiness name or restaurant name instead
        });
        const stripeResponse = await createStripeCardToken(form.card);
        const mercadoPagoResponse = await createMercadoPagoCardToken({
            ...form.card,
            name: removeSpecialCharacters(trim(businessName) || trim(restaurantName)),
            country,
            deviceId: window.MP_DEVICE_SESSION_ID,
        });

        if (!conektaResponse?.ok && !stripeResponse?.ok && !mercadoPagoResponse?.mercadoPagoCardToken) {
            console.log('Failed to add card', { conektaResponse, stripeResponse, mercadoPagoResponse });
            alert(`${translate('Failed to add card')}: ${(conektaResponse?.data.errorMessage || stripeResponse?.data.errorMessage || mercadoPagoResponse?.errorMessage) ?? ''}`);
            setLoading(false);
            return;
        }
        const response = await addSubscriptionCardApi({
            subscriptionId: requireValue(subscriptionId),
            cardToken: encryptDataInTransit(form.card),
            stripeCardToken: stripeResponse.data.stripeCardToken,
            conektaCardToken: conektaResponse.data.conektaCardToken,
            cardPriority,
            mercadoPagoCardToken: mercadoPagoResponse?.mercadoPagoCardToken,
        });
        setLoading(false);
        if (!response.ok) {
            alertKnownErrorOrSomethingWentWrong(response);
            return;
        }
        onSuccess();
        handleClose();
    };

    const handleClose = () => closeAddNewCardDialog();

    return (
        <Dialog open={open} onClose={handleClose} title={translate('Add Card')}>
            <Form form={form} onSubmit={onSubmit}>
                <p className={classes.subTitle}>{translate('To start ordering and have a joyful experience on the restaurant, please type')}</p>
                <DialogContent className={classes.dialogContent}>
                    <Grid container>
                        <Grid item xs={12} style={{ maxWidth: 700 }}>
                            <FormCardDetailsField name='card' />
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button secondary onClick={handleClose} disabled={loading}>
                        {translate('Cancel')}
                    </Button>
                    <Button type='submit' disabled={loading}>
                        {loading ? translate('Adding') : translate('Add')}
                    </Button>
                </DialogActions>
            </Form>
        </Dialog>
    );
}

const useStyles = makeStyles((theme) => ({
    dialogContent: {
        padding: '0 80px',
        display: 'flex',
        alignItems: 'center',
        [theme.breakpoints.down('md')]: {
            padding: '0 20px',
        },
    },
    subTitle: {
        fontFamily: theme.typography.regular,
        fontSize: 15,
        textTransform: 'none',
        textAlign: 'center',
        width: '70%',
        margin: '0 auto',
        [theme.breakpoints.down('md')]: {
            width: '80%',
            marginBottom: 20,
        },
    },
}));
