import { makeStyles } from '@material-ui/core';
import * as React from 'react';
import { closeOrderApi, CloseOrderApiRequest } from 'src/api/letseatmanager/order/closeOrderApi';
import { app2 } from 'src/app2';
import { Button } from 'src/components/Button';
import { translate } from 'src/i18n/translate';
import { getPosInterfaceLargerEnabledRestaurantSettingInLocalStorage } from 'src/localStorage/getPosInterfaceLargerEnabledRestaurantSettingInLocalStorage';
import { useGetCustomerInfo } from 'src/modules/pos/customer/useGetCustomerInfo';
import { posReducer } from 'src/reducers/posReducer';
import { useClearPos } from 'src/services/pos/useClearPos';
import { useShouldClosePendingOrder } from 'src/services/pos/useShouldClosePendingOrder';
import { usePrintOrderCommand } from 'src/services/printer/usePrintOrderCommand';
import { usePrintOrderTicket } from 'src/services/printer/usePrintOrderTicket';
import { CartItemVm } from 'src/types/CartItemVm';
import { alertKnownErrorOrSomethingWentWrong } from 'src/utils/alert/alertKnownErrorOrSomethingWentWrong';
import { removeDuplicates } from 'src/utils/array/removeDuplicates';
import { mapCustomerToOrderCustomer } from 'src/utils/order/mapCustomerToOrderCustomer';
import { findItemsChangedInOrder } from 'src/utils/pos/findItemsChangedInOrder';
import { mapCartItemToOrderItem } from 'src/utils/pos/mapCartItemToOrderItem';
import { mapPosCourseToOrderCourse } from 'src/utils/pos/mapPosCourseToOrderCourse';
import { classNames } from 'src/utils/react/classNames';
import { useAction } from 'src/utils/react/useAction';
import { useSelector } from 'src/utils/react/useSelector';

export function PosPaymentCloseOrderButton(): React.ReactElement | null {
    const classes = useStyles();
    const shouldClosePendingOrder = useShouldClosePendingOrder();

    const getCustomerInfo = useGetCustomerInfo();

    const [printOrderCommand] = usePrintOrderCommand();
    const [printOrderTicket] = usePrintOrderTicket();
    const clearPos = useClearPos();

    const posInterfaceLargerEnabled = getPosInterfaceLargerEnabledRestaurantSettingInLocalStorage();

    const disabledPosPaymentActions = useSelector((state) => state.pos.disabledPosPaymentActions);
    const paying = useSelector((state) => state.pos.paying);
    const restaurantId = useSelector((state) => state.app.restaurantId);
    const items = useSelector((state) => state.pos.items);
    const cancelledItems = useSelector((state) => state.pos.cancelledItems);
    const discount = useSelector((state) => state.pos.discount);
    const pendingOrder = useSelector((state) => state.pos.pendingOrder);
    const notes = useSelector((state) => state.pos.notes);
    const promoCode = useSelector((state) => state.pos.promoCode);
    const customerId = useSelector((state) => state.pos.customerId);
    const useLetsEatCredits = useSelector((state) => state.pos.useLetsEatCredits);
    const customers = useSelector((state) => state.pos.customers);
    const courses = useSelector((state) => state.pos.ongoingCourses);
    const numberOfCustomers = useSelector((state) => state.pos.numberOfCustomers);
    const table = useSelector((state) => state.pos.table);
    const usedPromotions = useSelector((state) => state.pos.payment?.usedPromotions);

    const updateOrder = useAction(app2.actions.updateOrder);
    const setDisabledPosPaymentActions = useAction(posReducer.actions.setDisabledPosPaymentActions);

    const disabled = disabledPosPaymentActions || paying;

    const closeOrder = async () => {
        if (disabledPosPaymentActions || !pendingOrder) return;
        const now = new Date();
        const { firstName, lastName, mobileNumber, email, customerNote } = getCustomerInfo();

        const request: CloseOrderApiRequest = {
            orderId: pendingOrder?.orderId,
            restaurantId,
            customerId,
            promoCodeId: promoCode?.promoCodeId,
            promotionIds: removeDuplicates(usedPromotions?.map((promotion) => promotion.promotionId)),
            firstName,
            lastName,
            mobileNumber: !!mobileNumber ? mobileNumber : undefined,
            email,
            customerNote,
            numberOfCustomers: table?.numberOfCustomers ?? numberOfCustomers ?? pendingOrder?.numberOfCustomers,
            orderItems: items?.map((item: CartItemVm) => ({ ...mapCartItemToOrderItem(item), addedAt: item?.addedAt ?? now })),
            cancelledItems: cancelledItems?.map((item: CartItemVm) => ({ ...mapCartItemToOrderItem(item), removedAt: item?.removedAt ?? now })),
            posDiscount: discount,
            useLetsEatCredits,
            notes,
            customers: customers?.map(mapCustomerToOrderCustomer),
            courses: courses?.map(mapPosCourseToOrderCourse),
        };

        const itemsToPrint = findItemsChangedInOrder(pendingOrder?.orderItems ?? ([] as any), request.orderItems ?? []);
        const removedItemsToPrint = findItemsChangedInOrder(pendingOrder?.cancelledItems ?? ([] as any), request.cancelledItems ?? []);

        setDisabledPosPaymentActions(true);
        const response = await closeOrderApi(request);
        setDisabledPosPaymentActions(false);

        if (!response.ok) {
            return alertKnownErrorOrSomethingWentWrong(response);
        }

        const orderCommand = { ...response.data, orderItems: itemsToPrint, cancelledItems: removedItemsToPrint } as any;
        updateOrder({ order: response.data });
        clearPos();

        await printOrderCommand(orderCommand);
        await printOrderTicket(response.data);
    };

    if (!shouldClosePendingOrder) return null;

    return (
        <Button classes={{ button: classNames(classes.button, posInterfaceLargerEnabled ? classes.largerButton : '') }} secondary onClick={closeOrder} disabled={disabled}>
            {translate('Save and block order')}
        </Button>
    );
}

const useStyles = makeStyles((theme) => ({
    button: {
        maxHeight: 50,
        height: 50,
        width: '100%',
        [theme.breakpoints.down('xs')]: {
            order: 1,
        },
    },
    largerButton: {
        order: 1,
        width: '100%',
        height: 52,
        fontSize: 15,
    },
}));
