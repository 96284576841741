import { letseatmanagerApiMethod } from 'src/api/letseatmanagerApi';
import type { ApiSauceResponse } from 'src/api/types/ApiSauceResponse';
import type { RestaurantId } from 'src/types/Id';
import type { SupplierVm } from 'src/types/SupplierVm';

export async function findSuppliersByRestaurantIdApi(request: FindSuppliersApiRequest): ApiSauceResponse<FindSuppliersByRestaurantIdApiResponse> {
    return letseatmanagerApiMethod('supplier/findSuppliersByRestaurantIdApi', request);
}

type FindSuppliersApiRequest = {
    restaurantId: RestaurantId;
};

export type FindSuppliersByRestaurantIdApiResponse = {
    suppliers: Array<SupplierVm>;
};
