import { makeStyles } from '@material-ui/core';
import * as React from 'react';
import { useEffect } from 'react';
import { app2 } from 'src/app2';
import EmailTemplatesTable from 'src/scenes/letseatadmin/emailTemplates/EmailTemplatesTable';
import type { EmailTemplateId } from 'src/types/Id';
import { useAction } from 'src/utils/react/useAction';

export function EmailTemplatesPage(): React.ReactElement {
    const classes = useStyles();

    const setTitle = useAction(app2.actions.setTitle);

    useEffect(() => {
        setTitle('Email Templates');
    }, []);

    return (
        <div className={classes.container}>
            <EmailTemplatesTable />
        </div>
    );
}

const useStyles = makeStyles((theme) => ({
    container: {
        position: 'relative',
    },
}));

export type EmailTemplatesVm = {
    emailTemplates: Array<EmailTemplateVm>;
};

export type EmailTemplateVm = {
    emailTemplateId: EmailTemplateId;
    name: string;
    subject: string;
    bodyHtml?: string;
    bodyText?: string;
    createdAt: Date;
    modifiedAt: Date;
};
