import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { Alert, AlertTitle } from '@material-ui/lab';
import * as React from 'react';
import { useState } from 'react';
import { CSSTransition } from 'react-transition-group';
import { app2 } from 'src/app2';
import { IncomingOrdersAlert } from 'src/components/alertDialog/order/IncomingOrdersAlert';
import { InformationAlertDialog } from 'src/components/dialog/InformationAlertDialog';
import { SecuredAndSubscribedPage } from 'src/components/page/SecuredAndSubscribedPage';
import { SecuredContent } from 'src/components/SecuredContent';
import { RolePermissions } from 'src/constants/RolePermission';
import { translate } from 'src/i18n/translate';
import { OrdersCarrousel } from 'src/scenes/letseatadmin/order/OrdersCarrousel';
import 'src/scenes/letseatmanager/currentOrders/CurrentOrdersAnimations.css';
import { useStyles } from 'src/scenes/letseatmanager/currentOrders/CurrentOrdersStyles';
import { useIsOrderVisibleForUser } from 'src/services/pos/useIsOrderVisibleForUser';
import { useAction } from 'src/utils/react/useAction';
import { useSelector } from 'src/utils/react/useSelector';
import { isMobileApp } from 'src/utils/reactNative/isMobileApp';
import { trim } from 'src/utils/string/trim';

/** @deprecated */
export function CurrentOrdersDeprecated(): React.ReactElement {
    const classes = useStyles();
    const isOrderVisibleForUser = useIsOrderVisibleForUser();

    const [openInformationAlertDialog, setOpenInformationAlertDialog] = useState(false);

    const webSoundPermissionAllowed = useSelector((state) => state.app2.webSoundPermissionAllowed);
    const managerMessageStyle = useSelector((state) => state.app2.managerMessageStyle);
    const managerMessageTitle = useSelector((state) => state.app2.managerMessageTitle);
    const managerMessageBody = useSelector((state) => state.app2.managerMessageBody);
    const managerMessageUrl = useSelector((state) => state.app2.managerMessageUrl);
    const currentOrders = useSelector((state) => state.app2.currentOrders);
    const restaurant = useSelector((state) => state.app.restaurant);
    const brandOpened = useSelector((state) => state.app.brandOpened);
    const restaurantId = useSelector((state) => state.app.restaurantId);
    const internalUser = useSelector((state) => state.authentication.internalUser);
    const restaurantLocation = useSelector((state) => state.app.restaurant.location);

    const openCreateManualOrderDeliveryDialog = useAction(app2.actions.openCreateManualOrderDeliveryDialog);

    const filteredOrders = currentOrders.filter(isOrderVisibleForUser);
    const managerMessages = parseManagerMessages(managerMessageStyle, managerMessageTitle, managerMessageBody, managerMessageUrl);

    const handleRequestDriver = () => {
        if (!restaurantLocation) return setOpenInformationAlertDialog(true);
        else openCreateManualOrderDeliveryDialog({});
    };

    return (
        <SecuredAndSubscribedPage className={(classes as any).contentContainer} title={translate('Ongoing Orders')} rolePermission={RolePermissions.PENDING_ORDERS_PAGE}>
            <>
                <div className={classes.container}>
                    <InformationAlertDialog
                        openInformationAlert={openInformationAlertDialog}
                        handleClose={() => setOpenInformationAlertDialog(false)}
                        title={translate('Location not found')}
                        dialogContent={translate('Restaurant location not found, please verify that you have set your address correctly')}
                    />
                    <IncomingOrdersAlert />
                    <div className={classes.alertsContainer}>
                        {!webSoundPermissionAllowed && !isMobileApp() && (
                            <Grid item xs={12} className={classes.alert}>
                                <Alert severity={'error'} style={{ borderColor: '#ddd', borderWidth: '1px', borderStyle: 'solid' }} classes={{ message: classes.alertMessage }}>
                                    <div className={classes.managerMessageContainer}>
                                        <div>
                                            <AlertTitle classes={{ root: classes.alertTitle }}>{translate('No notification sound?')}</AlertTitle>
                                            {translate('Configure your browser so that you dont miss any order.')}
                                        </div>
                                        <a className={classes.managerMessageUrl} href='https://pidedirecto.github.io/publicinfo/nosound' target='_blank'>
                                            {translate('CONFIGURE')}
                                        </a>
                                    </div>
                                </Alert>
                            </Grid>
                        )}
                        {managerMessages.map(({ managerMessageStyle, managerMessageTitle, managerMessageBody, managerMessageUrl }, idx) => (
                            <Grid item xs={12} className={classes.alert} key={idx}>
                                <Alert
                                    severity={managerMessageStyle ?? ('warning' as any)}
                                    style={{ borderColor: '#ddd', borderWidth: '1px', borderStyle: 'solid' }}
                                    classes={{ message: classes.alertMessage }}
                                >
                                    <div className={classes.managerMessageContainer}>
                                        <div>
                                            <AlertTitle classes={{ root: classes.alertTitle }}>{managerMessageTitle ?? ''}</AlertTitle>
                                            {managerMessageBody ?? ''}
                                        </div>
                                        {!!managerMessageUrl && (
                                            <a className={classes.managerMessageUrl} href={managerMessageUrl} target='_blank'>
                                                {translate('See more')}
                                            </a>
                                        )}
                                    </div>
                                </Alert>
                            </Grid>
                        ))}
                    </div>
                    <OrdersCarrousel orders={brandOpened ? filteredOrders : filteredOrders.filter((currentOrder) => currentOrder.restaurant?.restaurantId === restaurantId)} />
                    {!internalUser && (
                        <CSSTransition
                            in={brandOpened ? filteredOrders.length === 0 : filteredOrders.filter((currentOrder) => currentOrder.restaurant?.restaurantId === restaurantId).length === 0}
                            timeout={1000}
                            classNames='current-order-animation'
                            unmountOnExit
                        >
                            <div className={classes.emptyDescriptionContainer}>
                                <Typography variant='h3' align='center' className={classes.emptyDescription}>
                                    {translate('No Ongoing Orders')}
                                </Typography>
                                {restaurant.manualOrdersEnabled && (
                                    <SecuredContent rolePermission={RolePermissions.ASK_DRIVER}>
                                        <Button key='create-delivery-button' onClick={handleRequestDriver}>
                                            <div className={classes.button}>{translate('Request a Driver')}</div>
                                        </Button>
                                    </SecuredContent>
                                )}
                            </div>
                        </CSSTransition>
                    )}
                </div>
            </>
        </SecuredAndSubscribedPage>
    );
}

function parseManagerMessages(
    managerMessageStyle?: string,
    managerMessageTitle?: string,
    managerMessageBody?: string,
    managerMessageUrl?: string,
): Array<{
    managerMessageStyle?: string;
    managerMessageTitle?: string;
    managerMessageBody?: string;
    managerMessageUrl?: string;
}> {
    const managerMessageStyles = managerMessageStyle?.split(';').map(trim);
    const managerMessageTitles = managerMessageTitle?.split(';').map(trim);
    const managerMessageBodies = managerMessageBody?.split(';').map(trim);
    const managerMessageUrls = managerMessageUrl?.split(';').map(trim);

    const numberOfManagerMessages = Math.max(managerMessageTitles?.length ?? 0, managerMessageBodies?.length ?? 0);
    return [...Array(numberOfManagerMessages).keys()].map((i) => ({
        managerMessageStyle: managerMessageStyles?.[i],
        managerMessageTitle: managerMessageTitles?.[i],
        managerMessageBody: managerMessageBodies?.[i],
        managerMessageUrl: managerMessageUrls?.[i],
    }));
}
