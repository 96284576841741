import { letseatadminApiMethod } from 'src/api/letseatadminApi';
import type { ApiSauceResponse } from 'src/api/types/ApiSauceResponse';
import type { PaymentRejectReason } from 'src/constants/PaymentRejectReason';
import type { PaymentStatus } from 'src/constants/PaymentStatus';
import type { Psp } from 'src/constants/Psp';
import type { PaymentId, SubscriptionId } from 'src/types/Id';

export async function findSubscriptionPaymentsApi(request: FindSubscriptionPaymentsApiRequest): ApiSauceResponse<SubscriptionPaymentsVm> {
    return letseatadminApiMethod('subscription/findSubscriptionPaymentsApi', request);
}

export type FindSubscriptionPaymentsApiRequest = {
    subscriptionId: SubscriptionId;
};

export type SubscriptionPaymentsVm = {
    payments: Array<{
        paymentId: PaymentId;
        amount: string;
        psp?: Psp;
        paymentStatus: PaymentStatus;
        paymentRejectReason?: PaymentRejectReason;
        createdAt: Date;
    }>;
};
