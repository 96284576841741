import { letseatmanagerApiMethod } from 'src/api/letseatmanagerApi';
import type { ApiSauceResponse } from 'src/api/types/ApiSauceResponse';
import type { UnitOfMeasurement } from 'src/constants/UnitOfMeasurement';
import type { RestaurantId, SupplyId } from 'src/types/Id';
import type { InventoryLogVm } from 'src/types/InventoryLogVm';

export async function transferSupplyBetweenRestaurantsApi(request: TransferSupplyBetweenRestaurantsApiRequest): ApiSauceResponse<InventoryLogVm> {
    return letseatmanagerApiMethod('inventory/transferSupplyBetweenRestaurantsApi', request);
}

type TransferSupplyBetweenRestaurantsApiRequest = {
    sourceRestaurantId: RestaurantId;
    destinationRestaurantId: RestaurantId;
    sourceSupplyId: SupplyId;
    destinationSupplyId: SupplyId;
    quantity: string;
    unitOfMeasurement: UnitOfMeasurement;
    notes?: string;
};
