import { letseatmanagerApiMethod } from 'src/api/letseatmanagerApi';
import type { ApiSauceResponse } from 'src/api/types/ApiSauceResponse';
import type { MenuCategoryId, MenuId, MenuItemId, RestaurantId } from 'src/types/Id';

export async function moveMenuItemToMenuApi(request: MoveToMenuApiRequest): ApiSauceResponse<void> {
    return letseatmanagerApiMethod('menu/moveMenuItemToMenuApi', request);
}

type MoveToMenuApiRequest = {
    restaurantId: RestaurantId;
    menuId: MenuId;
    menuCategoryId: MenuCategoryId;
    menuItemId: MenuItemId;
};
