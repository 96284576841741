import type { RestaurantManualEarningVm } from 'src/api/letseatadmin/restaurantManualEarning/getRestaurantManualEarningApi';
import { letseatadminApiMethod } from 'src/api/letseatadminApi';
import type { ApiSauceResponse } from 'src/api/types/ApiSauceResponse';
import type { RestaurantManualEarningId } from 'src/types/Id';

export async function changeRestaurantManualEarningApi(request: ChangeRestaurantManualEarningApiRequest): ApiSauceResponse<RestaurantManualEarningVm> {
    return letseatadminApiMethod('restaurantManualEarning/changeRestaurantManualEarningApi', request);
}

export type ChangeRestaurantManualEarningApiRequest = {
    restaurantManualEarningId: RestaurantManualEarningId;
    title: string;
    body?: string;
    comment?: string;
    amount: string;
};
