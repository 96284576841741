import { letseatadminApiMethod } from 'src/api/letseatadminApi';
import type { ApiSauceResponse } from 'src/api/types/ApiSauceResponse';
import type { City } from 'src/constants/City';
import type { DriverBonusOfferId } from 'src/types/Id';

export async function getDriverBonusBillingDetailsApi(request: GetDriverBonusBillingDetailsApiRequest): ApiSauceResponse<GetDriverBonusBillingDetailsApiResponse> {
    return letseatadminApiMethod('driverBonusBilling/getDriverBonusBillingDetailsApi', request);
}

type GetDriverBonusBillingDetailsApiRequest = {
    driverBonusOfferId: DriverBonusOfferId;
    from: string;
    to: string;
    cities?: Array<City>;
};

export type GetDriverBonusBillingDetailsApiResponse = {
    driverBonusOfferId: DriverBonusOfferId;
    name: string;
    drivers: Array<DriverBonusBillingDetailsVm>;
};

export type DriverBonusBillingDetailsVm = {
    driverName: string;
    mobileNumber: string;
    usage: string;
    amount: string;
};
