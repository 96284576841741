import { makeStyles } from '@material-ui/core';
import { useEffect, useState } from 'react';
import * as React from 'react';
import { joinTablesApi } from 'src/api/letseatmanager/restaurantZone/joinTablesApi';
import { Button } from 'src/components/Button';
import { Dialog } from 'src/components/Dialog';
import { DialogActions } from 'src/components/DialogActions';
import { SimpleTable } from 'src/components/SimpleTable';
import { translate } from 'src/i18n/translate';
import { PeopleIcon } from 'src/icons/PeopleIcon';
import { SelectableTable } from 'src/scenes/letseatmanager/posRestaurantZones/SelectableTable';
import { useMergePosOrders } from 'src/services/order/useMergePosOrders';
import { useFormatAsRestaurantCurrencyNumber } from 'src/services/restaurant/useFormatAsRestaurantCurrencyNumber';
import type { RestaurantZoneId } from 'src/types/Id';
import { OrderItemVm, type OrderVm } from 'src/types/OrderVm';
import type { TableVm } from 'src/types/RestaurantZoneVm';
import { alertKnownErrorOrSomethingWentWrong } from 'src/utils/alert/alertKnownErrorOrSomethingWentWrong';
import { calculateOrderItemPrice } from 'src/utils/order/calculateOrderItemPrice';

export function JoinTablesDialog({ open, restaurantZoneId, selectedTableNumber, tables, onClose, onSuccess }: Props): React.ReactElement {
    const classes = useStyles();
    const formatAsCurrencyNumber = useFormatAsRestaurantCurrencyNumber();

    const { loading: mergingOrders, mergeOrders } = useMergePosOrders();

    const [tableNumbersToJoin, setTableNumbersToJoin] = useState<Array<string>>([]);
    const [showSummary, setShowSummary] = useState(false);
    const [loading, setLoading] = useState(false);
    const [mergedOrder, setMergedOrder] = useState<OrderVm | undefined>(undefined);

    useEffect(() => {
        if (!open) clearTables();
    }, [open]);

    const handleClose = () => {
        if (loading) return;
        onClose();
        clearTables();
    };

    const handleGoBack = () => {
        setShowSummary(false);
        clearTables();
    };

    const handleShowSummary = async () => {
        const mainTable = tables?.find((table: TableVm) => table.tableNumber === selectedTableNumber);
        let mergedOrder = mainTable?.order;

        for (const selectedTableNumber of tableNumbersToJoin) {
            const table = tables?.find((table: TableVm) => table.tableNumber === selectedTableNumber);
            if (!table?.order) continue;

            if (!mergedOrder) {
                mergedOrder = table?.order;
                continue;
            }

            mergedOrder = await mergeOrders(mergedOrder, table?.order);
        }
        setMergedOrder(mergedOrder);
        setShowSummary(true);
    };

    const handleSelectTable = (tableNumber: any, isSelected: any) => {
        if (isSelected) return setTableNumbersToJoin([...tableNumbersToJoin, tableNumber]);
        setTableNumbersToJoin(tableNumbersToJoin.filter((selectedTable) => selectedTable !== tableNumber));
    };

    const getOrderSelected = () => {
        const table = tables?.find((table: TableVm) => table.tableNumber === selectedTableNumber);
        return table?.order;
    };

    const joinTables = async () => {
        const order = getOrderSelected();
        if (!order || !selectedTableNumber) return;

        setLoading(true);
        const response = await joinTablesApi({
            restaurantZoneId,
            orderId: order.orderId,
            selectedTableNumber,
            tableNumbersToJoin: tableNumbersToJoin,
        });
        setLoading(false);
        if (!response.ok) {
            alertKnownErrorOrSomethingWentWrong(response);
            return;
        }
        handleClose();
        onSuccess?.();
    };

    const isTableAbleToJoin = (table: TableVm) => {
        return !table.order?.promoCodeId && !table.order?.usedCredits;
    };

    const clearTables = () => setTableNumbersToJoin([]);

    const filteredTables = tables?.filter((table: TableVm) => table.tableNumber !== selectedTableNumber && isTableAbleToJoin(table));

    return (
        <Dialog open={open} onClose={handleClose} title={translate('Join tables')} loading={loading || mergingOrders} classes={{ dialog: classes.dialog }}>
            {!showSummary && (
                <div className={classes.tablesContainer}>
                    <span className={classes.text}>{translate('Select tables you want to join with table @tableNumber', { tableNumber: selectedTableNumber })}</span>
                    <SimpleTable
                        columns={[{ id: 'table', content: '' }]}
                        rows={
                            filteredTables?.map((table: TableVm) => ({
                                table: <SelectableTable table={table} onSelectTable={handleSelectTable} />,
                            })) ?? []
                        }
                        hideHeaders
                    />
                </div>
            )}
            {showSummary && (
                <div className={classes.summary}>
                    <div className={classes.tableInfo}>
                        <div className={classes.selectedTable}>
                            <span className={classes.boldText}>{translate('Table @table', { table: selectedTableNumber })}</span>
                            <div className={classes.numberOfCustomers}>
                                <PeopleIcon color={'#2E3748'} />
                                <span role={'emphasis'} className={classes.text}>
                                    {mergedOrder?.numberOfCustomers}
                                </span>
                            </div>
                        </div>
                        <div className={classes.selectedTable}>
                            <span className={classes.text}>{translate('Tables to join')}</span>
                            <span role={'emphasis'} className={classes.text}>
                                {tableNumbersToJoin.join(', ')}
                            </span>
                        </div>
                    </div>

                    {mergedOrder?.orderItems.map((orderItem: OrderItemVm, idx: number) => (
                        <div className={classes.orderItemSummary} key={idx}>
                            <div className={classes.orderItemName}>
                                <span className={classes.boldText}>{orderItem.quantity}x</span>
                                <span className={classes.text}>{orderItem.name}</span>
                            </div>
                            <span className={classes.text}>{formatAsCurrencyNumber(calculateOrderItemPrice(orderItem))}</span>
                        </div>
                    ))}
                    <div className={classes.orderItemSummary} style={{ marginBottom: 10 }}>
                        <span className={classes.boldText}>{translate('Total')}</span>
                        <span className={classes.text}>{formatAsCurrencyNumber(mergedOrder?.total || 0)}</span>
                    </div>
                </div>
            )}
            <DialogActions className={classes.dialogActions}>
                {!showSummary && (
                    <Button classes={{ button: classes.button }} secondary onClick={handleClose} disabled={loading || mergingOrders}>
                        {translate('Cancel')}
                    </Button>
                )}
                {!showSummary && (
                    <Button classes={{ button: classes.button }} onClick={handleShowSummary} disabled={loading || mergingOrders || !tableNumbersToJoin.length}>
                        {translate('Continue')}
                    </Button>
                )}
                {showSummary && (
                    <Button classes={{ button: classes.button }} secondary onClick={handleGoBack} disabled={loading || mergingOrders}>
                        {translate('Return')}
                    </Button>
                )}
                {showSummary && (
                    <Button classes={{ button: classes.button }} onClick={joinTables} disabled={loading || mergingOrders}>
                        {translate('Join tables')}
                    </Button>
                )}
            </DialogActions>
        </Dialog>
    );
}

const useStyles = makeStyles((theme) => ({
    dialog: {
        width: '50vw',
        [theme.breakpoints.down('sm')]: {
            width: '90vw',
        },
    },
    button: {
        width: 200,
    },
    dialogActions: {
        marginTop: 20,
    },
    summary: {
        borderRadius: 8,
        border: '1px solid #D9D9D9',
        padding: '10px 20px',
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        gap: 5,
    },
    tableInfo: {
        borderBottom: '1px solid #D9D9D9',
        display: 'flex',
        flexDirection: 'column',
        paddingBottom: 10,
        marginBottom: 5,
    },
    selectedTable: {
        width: '100%',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
    },
    boldText: {
        fontFamily: theme.typography.semiBold,
        fontSize: 16,
    },
    text: {
        fontFamily: theme.typography.regular,
        fontSize: 14,
        color: '#2E3748',
    },
    numberOfCustomers: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        gap: 5,
    },
    orderItemSummary: {
        width: '100%',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
    },
    orderItemName: {
        width: '100%',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        gap: 5,
    },
    tablesContainer: {
        display: 'flex',
        flexDirection: 'column',
        gap: 10,
        width: '100%',
    },
}));

type Props = {
    open: boolean;
    restaurantZoneId: RestaurantZoneId;
    selectedTableNumber?: string;
    tables?: Array<TableVm>;
    onClose: any;
    onSuccess?: any;
};
