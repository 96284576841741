import { makeStyles } from '@material-ui/core';
import MUIDataTable from 'mui-datatables';
import pako from 'pako';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { findPromoCodesApi, PromoCodesVm } from 'src/api/letseatadmin/promoCode/findPromoCodesApi';
import { removePromoCodeApi } from 'src/api/letseatadmin/promoCode/removePromoCodeApi';
import { ChangeToolbarButton } from 'src/components/mui-datatables/ChangeToolbarButton';
import { CreateToolbarButton } from 'src/components/mui-datatables/CreateToolbarButton';
import { RefreshToolbarButton } from 'src/components/mui-datatables/RefreshToolbarButton';
import { RemoveToolbarButton } from 'src/components/mui-datatables/RemoveToolbarButton';
import { Page } from 'src/components/Page';
import { UpdatingContentProgress } from 'src/components/UpdatingContentProgress';
import { translate } from 'src/i18n/translate';
import { ChangePromoCodeDialog } from 'src/scenes/letseatadmin/promoCode/ChangePromoCodeDialog';
import { CreatePromoCodeDialog } from 'src/scenes/letseatadmin/promoCode/CreatePromoCodeDialog';
import { CountryCode, type PromoCodeId } from 'src/types/Id';
import { alertKnownErrorOrSomethingWentWrong } from 'src/utils/alert/alertKnownErrorOrSomethingWentWrong';
import { formatDateTimeString } from 'src/utils/date/formatDateTimeString';
import { getSelectedRowsData } from 'src/utils/mui-datatables/getSelectedRowsData';
import { classNames } from 'src/utils/react/classNames';
import { useAskForPassword } from 'src/utils/react/useAskForPassword';
import { useLoadApi } from 'src/utils/react/useLoadApi';
import { useSelector } from 'src/utils/react/useSelector';

export function PromoCodesPage(): React.ReactElement {
    const classes = useStyles();
    const askForPassword = useAskForPassword();

    const [loading, setLoading] = useState(false);
    const [usedPromoCodes, setUsedPromoCodes] = useState({ promoCodes: [] } as PromoCodesVm);
    const [createPromoCodeDialogState, setCreatePromoCodeDialogState] = useState({ open: false });
    const [changePromoCodeDialogState, setChangePromoCodeDialogState] = useState({ open: false, promoCodeId: undefined });

    const viewUser = useSelector((state) => state.authentication.viewUser);
    const restaurantIds = useSelector((state) => state.app.restaurantIds);
    const brandOpened = useSelector((state) => state.app.brandOpened);
    const restaurantId = useSelector((state) => state.app.restaurantId);

    const [loadingPromoCodes, responsePromoCodes, refreshPromoCodes] = useLoadApi(findPromoCodesApi, { restaurantIds: brandOpened ? restaurantIds : [restaurantId] } as any, {
        initialValue: { promoCodes: [] },
        zip: true,
    });

    const filterUsedPromoCodes = (promoCodes: any) => {
        const usedPromoCodes = promoCodes.filter((promoCode: any) => (promoCode.customers && promoCode.usage && promoCode.usage !== 0) || !promoCode.customers);

        setUsedPromoCodes({ promoCodes: usedPromoCodes });
        setLoading(false);
    };

    useEffect(() => {
        if (responsePromoCodes.promoCodes) filterUsedPromoCodes(responsePromoCodes.promoCodes);
    }, [responsePromoCodes, brandOpened]);

    const remove = async (promoCodeId: PromoCodeId) => {
        setLoading(true);
        const response = await removePromoCodeApi({ promoCodeId });
        if (!response.ok) {
            setLoading(false);
            alertKnownErrorOrSomethingWentWrong(response);
            return;
        }
        await refreshPromoCodes();
    };

    const removeMultiple = async (promoCodeIds: Array<PromoCodeId>) => {
        setLoading(true);
        for (const promoCodeId of promoCodeIds) {
            const response = await removePromoCodeApi({ promoCodeId });
            if (!response.ok) {
                setLoading(false);
                alertKnownErrorOrSomethingWentWrong(response);
                await refreshPromoCodes();
                return;
            }
        }
        await refreshPromoCodes();
    };

    const handleClickNew = async () => {
        const rightPassword = await askForPassword({ password: '9843' });
        if (!rightPassword) return;
        setCreatePromoCodeDialogState({ open: true });
    };

    return (
        <Page title={translate('Promo Codes')}>
            <div className={classes.container}>
                <UpdatingContentProgress loading={loading || loadingPromoCodes} />
                <CreatePromoCodeDialog open={createPromoCodeDialogState.open} onClose={() => setCreatePromoCodeDialogState({ open: false })} onPromoCodeCreated={refreshPromoCodes} />
                <ChangePromoCodeDialog
                    open={changePromoCodeDialogState.open}
                    promoCodeId={changePromoCodeDialogState.promoCodeId}
                    onClose={() => setChangePromoCodeDialogState({ open: false, promoCodeId: undefined })}
                    onPromoCodeChanged={refreshPromoCodes}
                />

                <MUIDataTable
                    /* @ts-ignore */
                    className={classes.table}
                    data={
                        usedPromoCodes.promoCodes &&
                        usedPromoCodes.promoCodes.map((promoCode) => ({
                            promoCodeId: promoCode.promoCodeId,
                            code: promoCode.code,
                            description: promoCode.description,
                            usage: promoCode.usage,
                            maxUsage: promoCode.maxUsage,
                            promoType: promoCode.promoType,
                            rewardType: promoCode.rewardType,
                            discount: promoCode.discount,
                            excludeDeliveryCost: promoCode.excludeDeliveryCost ? translate('Excluded') : translate('Not Excluded'),
                            freeDelivery: promoCode.freeDelivery ? translate('Free') : translate('Not Free'),
                            onlyFor: promoCode.appOnly || promoCode.webOnly ? (promoCode.appOnly ? 'APP' : 'WEB') : '',
                            discountType: promoCode.discountType,
                            minOrderAmount: promoCode.minOrderAmount,
                            maxDiscountAmount: promoCode.maxDiscountAmount,
                            restaurantPaidPercentage: `${promoCode.restaurantPaidPercentage ?? 0}%`,
                            countries: promoCode.countries?.map((country: CountryCode) => translate(country))?.join(', '),
                            restaurants: promoCode.restaurants,
                            customers: promoCode.customers,
                            startsAt: formatDateTimeString(promoCode.startsAt),
                            endsAt: formatDateTimeString(promoCode.endsAt),
                            createdAt: formatDateTimeString(promoCode.createdAt),
                        }))
                    }
                    columns={[
                        {
                            name: 'promoCodeId',
                            label: '',
                            options: {
                                display: 'excluded',
                                filter: false,
                            },
                        },
                        {
                            name: 'code',
                            label: translate('Code'),
                            options: {
                                filter: false,
                            },
                        },
                        {
                            name: 'description',
                            label: translate('Description'),
                            options: {
                                filter: false,
                            },
                        },
                        {
                            name: 'usage',
                            label: translate('Usage'),
                            options: {
                                filter: false,
                                searchable: false,
                            },
                        },
                        {
                            name: 'maxUsage',
                            label: translate('Max Usage'),
                            options: {
                                filter: false,
                                searchable: false,
                            },
                        },
                        {
                            name: 'removed',
                            label: translate('Removed'),
                            options: {
                                filter: false,
                                searchable: false,
                                customBodyRender: (removed) => {
                                    return (
                                        <div className={classNames(classes.removed, removed ? classes.promoCodeRemoved : classes.promoCodeNotRemoved)}>
                                            {removed ? translate('Yes') : translate('No')}
                                        </div>
                                    );
                                },
                            },
                        },
                        {
                            name: 'promoType',
                            label: translate('Promo Type'),
                            options: {
                                searchable: false,
                            },
                        },
                        {
                            name: 'rewardType',
                            label: translate('Reward Type'),
                            options: {
                                searchable: false,
                            },
                        },
                        {
                            name: 'discount',
                            label: translate('Discount'),
                            options: {
                                filter: false,
                                searchable: false,
                            },
                        },
                        {
                            name: 'excludeDeliveryCost',
                            label: translate('Exclude delivery cost'),
                            options: {
                                filter: false,
                            },
                        },
                        {
                            name: 'freeDelivery',
                            label: translate('Free Delivery'),
                            options: {
                                filter: false,
                            },
                        },
                        {
                            name: 'onlyFor',
                            label: translate('Only For'),
                            options: {
                                filter: false,
                            },
                        },
                        {
                            name: 'discountType',
                            label: translate('Discount Type'),
                            options: {
                                searchable: false,
                            },
                        },
                        {
                            name: 'restaurantPaidPercentage',
                            label: translate('Restaurant Paid Percentage'),
                            options: {
                                searchable: false,
                            },
                        },
                        {
                            name: 'minOrderAmount',
                            label: translate('Min Order Amount'),
                            options: {
                                filter: false,
                                searchable: true,
                            },
                        },
                        {
                            name: 'maxDiscountAmount',
                            label: translate('Max Discount Amount'),
                            options: {
                                filter: false,
                            },
                        },
                        {
                            name: 'countries',
                            label: translate('Countries'),
                            options: {
                                searchable: false,
                            },
                        },
                        {
                            name: 'restaurants',
                            label: translate('Restaurants'),
                            options: {
                                filter: false,
                            },
                        },
                        {
                            name: 'customers',
                            label: translate('Customers'),
                            options: {
                                filter: false,
                            },
                        },
                        {
                            name: 'startsAt',
                            label: translate('Starts'),
                            options: {
                                filter: false,
                            },
                        },
                        {
                            name: 'endsAt',
                            label: translate('Ends'),
                            options: {
                                filter: false,
                                searchable: false,
                            },
                        },
                        {
                            name: 'createdAt',
                            label: translate('Created'),
                            options: {
                                filter: false,
                                searchable: false,
                            },
                        },
                    ]}
                    options={{
                        responsive: 'standard',
                        tableBodyMaxHeight: '500px',
                        selectableRows: !viewUser ? 'multiple' : 'none',
                        filterType: 'checkbox',
                        rowsPerPage: 100,
                        customToolbar: () => (
                            <>
                                <RefreshToolbarButton onClick={refreshPromoCodes} />
                                <CreateToolbarButton onClick={handleClickNew} />
                            </>
                        ),
                        customToolbarSelect: (selectedRows, displayData) => {
                            const selectedRowsData = getSelectedRowsData(selectedRows, displayData);
                            const selectedPromoCodeIds = selectedRowsData.map((d: any) => d[0]);
                            const handleClickEdit = () => {
                                if (selectedPromoCodeIds.length === 1) setChangePromoCodeDialogState({ open: true, promoCodeId: selectedPromoCodeIds[0] });
                            };
                            const handleClickRemove = async () => {
                                const remove = window.confirm(
                                    selectedPromoCodeIds.length === 1
                                        ? 'Are you sure you want to remove the selected promo code'
                                        : `Are you sure you want to remove the selected ${selectedPromoCodeIds.length} promo codes`,
                                );
                                if (remove) {
                                    await removeMultiple(selectedPromoCodeIds);
                                }
                            };
                            return (
                                <div className={classes.toolbar}>
                                    {selectedPromoCodeIds.length === 1 && <ChangeToolbarButton onClick={handleClickEdit} />}
                                    <RemoveToolbarButton onClick={handleClickRemove} />
                                </div>
                            );
                        },

                        // filterList: [[], [], [], [], ['MEXICO']],
                    }}
                />
            </div>
        </Page>
    );
}

function unzip(base64str: any): any {
    const strData = atob(base64str);

    // Convert binary string to character-number array
    const charData = strData.split('').map((x) => {
        return x.charCodeAt(0);
    });

    // Turn number array into byte-array
    const binData = new Uint8Array(charData);

    return JSON.parse(pako.inflate(binData, { to: 'string' }));
}

const useStyles = makeStyles((theme) => ({
    container: {
        position: 'relative',
    },
    table: {
        whiteSpace: 'nowrap',
    },
    toolbar: {
        paddingRight: theme.spacing(3),
    },
    removed: {
        padding: '5px',
        borderRadius: '5px',
        textAlign: 'center',
    },
    promoCodeRemoved: {
        backgroundColor: '#5CDB95',
        color: '#FFFFFF',
        fontWeight: 'bold',
    },
    promoCodeNotRemoved: {
        backgroundColor: '#E85757',
        color: '#FFFFFF',
        fontWeight: 'bold',
    },
}));
