import { letseatmanagerApiMethod } from 'src/api/letseatmanagerApi';
import type { ApiSauceResponse } from 'src/api/types/ApiSauceResponse';
import type { App } from 'src/constants/App';
import type { BannerImageType } from 'src/constants/BannerImageType';
import type { CustomDiscountComment } from 'src/constants/CustomDiscountComment';
import { CustomPaymentMethodIcon } from 'src/constants/CustomPaymentMethodIcons';
import type { PaymentMethod } from 'src/constants/PaymentMethod';
import type { ServiceType } from 'src/constants/ServiceType';
import type { Sound } from 'src/constants/Sound';
import type { GeoJsonPoint } from 'src/types/GeoJsonPoint';
import type {
    AwsCognitoUsername,
    CashRegisterCustomReason,
    CustomPaymentMethod,
    CustomProductCancellationReason,
    DidiFoodStoreId,
    EmailAddress,
    RappiStoreId,
    RestaurantId,
    SupplyCategoryId,
    UberEatsStoreId,
} from 'src/types/Id';
import type { RestaurantAddress } from 'src/types/RestaurantAddress';
import type { RestaurantCurrency } from 'src/types/RestaurantCurrency';

export async function getTheRestaurantApi(request: GetTheRestaurantApiRequest): ApiSauceResponse<TheRestaurantVm> {
    return letseatmanagerApiMethod('theRestaurant/getTheRestaurantApi', request);
}

export type GetTheRestaurantApiRequest = {
    restaurantId: RestaurantId;
};

export type TheRestaurantVm = {
    restaurantId: RestaurantId;
    name: string;
    street?: string;
    address?: RestaurantAddress;
    location?: GeoJsonPoint;
    phoneNumber?: string;
    secondaryPhoneNumber?: string;
    imageUrl?: string;
    imageUrlMobile?: string;
    bannerImageType?: BannerImageType;
    hideCartEnabled?: boolean;
    info?: string;
    infoUrl?: string;
    hideFiscalInformationOnTicket?: boolean;
    hidePlannedOrders?: boolean;
    csdCertificateUploaded: boolean;
    csdCertificateRestaurantName?: string;
    kioskBackgroundImageUrl?: string;
    backgroundImageUrl?: string;
    logoImageUrl?: string;
    uberEatsIntegrationEnabled?: boolean;
    didiFoodStoreOnline?: boolean;
    pedidosYaRestaurantOnline?: boolean;
    uberEatsStoreOnline?: boolean;
    ticketLogoImage?: string;
    supplyCategoryIds?: Array<SupplyCategoryId>;
    statisticsReportEmailRecipients?: Array<EmailAddress>;
    eatHereEnabled: boolean;
    serviceType: ServiceType;
    takeAwayEnabled: boolean;
    supplyLimitAlertEnabled?: boolean;
    deliveryEnabled: boolean;
    autoCallRestaurantEnabled?: boolean;
    cashRegisterWithdrawReasons?: CashRegisterCustomReasons;
    cashRegisterDepositReasons?: CashRegisterCustomReasons;
    plannedOrdersEnabled: boolean;
    posRestaurantZonesEnabled?: boolean;
    posShowTablesEnabled?: boolean;
    posTableRequiredEnabled?: boolean;
    posCustomersRequiredEnabled?: boolean;
    restaurantPaymentMethodsEnabled?: boolean;
    paymentMethods?: Array<{
        name: CustomPaymentMethod;
        paymentMethod: PaymentMethod;
        commission?: string;
        enabled?: boolean;
        channels?: Array<App>;
        icon?: CustomPaymentMethodIcon;
    }>;
    acceptMultipleCurrenciesPaymentEnabled?: boolean;
    currencies?: Array<RestaurantCurrency>;
    posCustomerNameRequiredOnTakeAwayOrdersEnabled?: boolean;
    posOnlyCashInCashRegisterReportEnabled?: boolean;
    maximumCashAllowedInCashRegisterEnabled?: boolean;
    openCashRegisterButtonEnabled?: boolean;
    maximumCashAllowedInCashRegister?: string;
    posPassword?: string;
    posTakeOrderByCourseEnabled?: boolean;
    appOrdersCashbackEnabled?: boolean;
    appOrdersCashback?: string;
    restaurantColors?: Array<string>;
    autoAcceptOrdersTime?: number;
    autoAcceptRappiOrdersTime?: number;
    autoAcceptUberEatsOrdersTime?: number;
    autoAcceptDidiFoodsOrdersTime?: number;
    printAutoAcceptedOrders?: boolean;
    ordersWithoutIvaEnabled?: boolean;
    preparationTimeInMinutes?: number;
    temporarilyOffline?: boolean;
    temporarilyWithoutDelivery?: boolean;
    externalDeliveryEstimatedTime?: string;
    alertSound?: Sound;
    kitchenDisplayScreenAlertSound?: Sound;
    cancelledOrdersAlertSound?: Sound;
    cancelledOrdersAlertSoundEnabled?: boolean;
    addManuallyOrderPreparationTimeEnabled?: boolean;
    invoicesWithZeroTaxForTakeAwayOrdersEnabled?: boolean;
    hideDeliveryCostPaidByRestaurantOnTicketEnabled?: boolean;
    minimumOrderAmount?: string;
    maximumOrderAmount?: string;
    posEnabled?: boolean;
    posSimpleView?: boolean;
    fakedProductDiscountPercentage?: string;
    subModifiersEnabled?: boolean;
    newMenuManagementEnabled?: boolean;
    urlSubdomain?: string;
    urlPathname?: string;
    facebookPixelId?: string;
    googleAnalyticsId?: string;
    ownFleetEnabled?: boolean;
    showOrderItemsToDriverEnabled?: boolean;
    pideDirectoProtectionForManualOrderAmount?: string;
    uberEatsDeliveryEnabled?: boolean;
    rappiCargoDeliveryEnabled?: boolean;
    externalDelivery?: boolean;
    dynamicDeliveryManager?: boolean;
    deliverySecurityCodeEnabled?: boolean;
    driverLinkedCode?: string;
    pideDirectoDeliveryEnabled?: boolean;
    uberDaasDeliveryEnabled?: boolean;
    customDiscountComments?: CustomDiscountComments;
    posCustomDiscountCommentsEnabled?: boolean;
    kioskPayWithCreditCardInCounterEnabled?: boolean;
    productCancellationReasons?: Array<{
        reason: CustomProductCancellationReason;
    }>;
    includeBreakdownIntegrationsInPosBusinessDayEnabled?: boolean;
    posUseMenuItemColorEnabled?: boolean;
    authorizationToReprintCompletedOrderEnabled?: boolean;
    requestPasswordForClosePosBusinessDayEnabled?: boolean;
    moneyBillsPadEnabled?: boolean;
    demoModeEnabled?: boolean;
    useOcaPaymentTerminalEnabled?: boolean;
    useQpayTerminalEnabled?: boolean;
    qpayTerminalCommission?: string;
    inventoryShowNegative?: boolean;
    clockInEmployeeDayShiftEnabled?: boolean;
    clockInMessage?: string;
    clockOutMessage?: string;
    uberEatsStoreId?: UberEatsStoreId;
    rappiStoreId?: RappiStoreId;
    didiFoodStoreId?: DidiFoodStoreId;
    username: AwsCognitoUsername;
    channelsContemplatedAtTheEndOfDayReport?: Array<App>;
    printRappiQrCodeOnTicketEnabled?: boolean;
    useRappiQrCodeEnabled?: boolean;
};

export type CashRegisterCustomReasons = Array<{
    reason: CashRegisterCustomReason;
}>;

type CustomDiscountComments = Array<{
    comment: CustomDiscountComment;
}>;
