import { makeStyles } from '@material-ui/core';
import { useEffect, useState } from 'react';
import * as React from 'react';
import { useHistory } from 'react-router-dom';
import { app2 } from 'src/app2';
import { Button } from 'src/components/Button';
import { CustomerDisplayScreenOrderSteps } from 'src/constants/CustomerDisplayScreenOrderStep';
import { OrderStatuses } from 'src/constants/OrderStatus';
import { RolePermissions } from 'src/constants/RolePermission';
import { translate } from 'src/i18n/translate';
import { getPosInterfaceLargerEnabledRestaurantSettingInLocalStorage } from 'src/localStorage/getPosInterfaceLargerEnabledRestaurantSettingInLocalStorage';
import { savePosOrderOffline } from 'src/localStorage/savePosOrderOffline';
import { posReducer } from 'src/reducers/posReducer';
import { useChargeOrder } from 'src/services/pos/useChargeOrder';
import { useClearPos } from 'src/services/pos/useClearPos';
import { useMapOrderItemsToCartItems } from 'src/services/pos/useMapOrderItemsToCartItems';
import { useSendOrderUpdateToCustomerDisplayScreen } from 'src/services/pos/useSendOrderUpdateToCustomerDisplayScreen';
import { usePrintOrderCommand } from 'src/services/printer/usePrintOrderCommand';
import { usePrintOrderTicket } from 'src/services/printer/usePrintOrderTicket';
import { alertKnownErrorOrSomethingWentWrong } from 'src/utils/alert/alertKnownErrorOrSomethingWentWrong';
import { isDeliveryOrder } from 'src/utils/order/isDeliveryOrder';
import { isEatHereOrder } from 'src/utils/order/isEatHereOrder';
import { isPendingOrder } from 'src/utils/order/isPendingOrder';
import { isTakeAwayOrder } from 'src/utils/order/isTakeAwayOrder';
import { findItemsChangedInOrder } from 'src/utils/pos/findItemsChangedInOrder';
import { classNames } from 'src/utils/react/classNames';
import { useAction } from 'src/utils/react/useAction';
import { useSaveNewPosOrder } from 'src/utils/react/useSaveNewPosOrder';
import { useSelector } from 'src/utils/react/useSelector';
import { useUpdatePosPendingOrder } from 'src/utils/react/useUpdatePosPendingOrder';
import { useUserHasRolePermission } from 'src/utils/react/useUserHasRolePermissions';

export function PosCartPrimaryButton(): React.ReactElement | null {
    const classes = useStyles();

    const posInterfaceLargerEnabled = getPosInterfaceLargerEnabledRestaurantSettingInLocalStorage();
    const mapOrderItemsToCartItems = useMapOrderItemsToCartItems();
    const [userHasRolePermission] = useUserHasRolePermission();
    const sendOrderUpdateToCustomerDisplayScreen = useSendOrderUpdateToCustomerDisplayScreen();

    const [printOrderTicket] = usePrintOrderTicket();
    const [printOrderCommand] = usePrintOrderCommand();
    const { updatePosPendingOrder } = useUpdatePosPendingOrder();
    const { saveNewPosOrder } = useSaveNewPosOrder();
    const { handlePay } = useChargeOrder();
    const history = useHistory();
    const clearPos = useClearPos();

    const [loading, setLoading] = useState(false);
    const [printCommandEnabled, setPrintCommandEnabled] = useState(false);

    const pendingOrder = useSelector((state) => state.pos.pendingOrder);
    const orderType = useSelector((state) => state.pos.orderType);
    const hasPendingOrder = useSelector((state) => !!state.pos.pendingOrder);
    const posPendingOrdersEnabled = useSelector((state) => state.app.restaurant.posPendingOrdersEnabled);
    const total = useSelector((state) => state.pos.payment?.total);
    const items = useSelector((state) => state.pos.items);
    const cancelledItems = useSelector((state) => state.pos.cancelledItems);
    const orderOpenedFromTable = useSelector((state) => state.pos.orderOpenedFromTable);
    const disabledPosPaymentActions = useSelector((state) => state.pos.disabledPosPaymentActions);
    const table = useSelector((state) => state.pos.table);
    const numberOfCustomers = useSelector((state) => state.pos.numberOfCustomers);
    const acceptManuallyAllOrdersEnabled = useSelector((state) => state.app.restaurant?.acceptManuallyAllOrdersEnabled);

    const setPendingOrder = useAction(posReducer.actions.setPendingOrder);
    const setItems = useAction(posReducer.actions.setItems);
    const updateOrder = useAction(app2.actions.updateOrder);

    const itemsToPrint = findItemsChangedInOrder(pendingOrder?.orderItems ?? ([] as any), items ?? []);
    const disabledButtons = loading || (!total && (!itemsToPrint?.length || !cancelledItems.length));
    const primaryButtonDisabled = isEatHereOrder(orderType) && !hasPendingOrder ? disabledButtons || !printCommandEnabled : disabledButtons;
    const newOrderItemsAdded = !pendingOrder && items?.length;
    const pendingOrderItemsChanged = pendingOrder && itemsToPrint.length;
    const cannotChargeTakeAwayOrders = isTakeAwayOrder(orderType) && !userHasRolePermission(RolePermissions.CHARGE_ORDERS);

    useEffect(() => {
        updatePrintCommandEnabled();
    }, [pendingOrder, items]);

    const updatePrintCommandEnabled = () => {
        const hasNewItems = newOrderItemsAdded || pendingOrderItemsChanged;
        const hasNewCancelledItems = cancelledItems?.length;

        if (hasNewItems || hasNewCancelledItems) return setPrintCommandEnabled(true);

        setPrintCommandEnabled(false);
    };

    const handleSavePendingOrder = async () => {
        await saveNewPendingOrder(OrderStatuses.PENDING);
        clearPos();

        if (orderOpenedFromTable) return history.goBack();
    };

    const updatePendingOrder = async (printTicket?: boolean) => {
        if (loading || disabledPosPaymentActions) return;
        setLoading(true);
        sendOrderUpdateToCustomerDisplayScreen(CustomerDisplayScreenOrderSteps.CREATING_ORDER);
        const { response, request } = await updatePosPendingOrder({
            firstName: pendingOrder?.firstName,
            lastName: pendingOrder?.lastName,
            mobileNumber: pendingOrder.customerMobileNumber,
            email: pendingOrder.email,
            customerNote: pendingOrder.customerNote,
        });
        setLoading(false);

        const itemsToPrint = findItemsChangedInOrder(pendingOrder?.orderItems ?? ([] as any), request.orderItems ?? []);
        const removedItemsToPrint = findItemsChangedInOrder(pendingOrder?.cancelledItems ?? ([] as any), request.cancelledItems ?? []);

        if (!response.ok) {
            sendOrderUpdateToCustomerDisplayScreen(CustomerDisplayScreenOrderSteps.UNSUCCESSFUL_ORDER);
            if (response.problem === 'NETWORK_ERROR') {
                savePosOrderOffline(request);
                await printOrderCommand({ ...request, orderItems: itemsToPrint, cancelledItems: removedItemsToPrint } as any);
                return;
            }
            return alertKnownErrorOrSomethingWentWrong(response);
        }

        sendOrderUpdateToCustomerDisplayScreen(CustomerDisplayScreenOrderSteps.SUCCESSFUL_ORDER_CREATED);
        setPendingOrder(response.data);
        setItems(mapOrderItemsToCartItems(response.data.orderItems));
        updateOrder({ order: response.data });

        if ((isEatHereOrder(orderType) && hasPendingOrder) || isTakeAwayOrder(orderType)) {
            clearPos();
        }

        if (printTicket) await printOrderTicket(response.data as any);

        await printOrderCommand({ ...response.data, orderItems: itemsToPrint, cancelledItems: removedItemsToPrint } as any);
    };

    const saveNewPendingOrder = async (savePendingOrderStatus: string) => {
        if (loading || disabledPosPaymentActions) return;

        setLoading(true);
        sendOrderUpdateToCustomerDisplayScreen(CustomerDisplayScreenOrderSteps.CREATING_ORDER);
        const { response, request } = await saveNewPosOrder({
            numberOfCustomers: table?.numberOfCustomers ?? numberOfCustomers,
            orderStatus: savePendingOrderStatus as any,
        });
        setLoading(false);

        if (!response.ok) {
            sendOrderUpdateToCustomerDisplayScreen(CustomerDisplayScreenOrderSteps.UNSUCCESSFUL_ORDER);
            if (response.problem === 'NETWORK_ERROR') {
                await printOrderCommand({ ...request, orderItems: request.orderItems, cancelledItems } as any);
                savePosOrderOffline(request as any);
                return;
            }
            return alertKnownErrorOrSomethingWentWrong(response);
        }
        sendOrderUpdateToCustomerDisplayScreen(CustomerDisplayScreenOrderSteps.SUCCESSFUL_ORDER_CREATED);
        setPendingOrder(response.data);
        setItems(mapOrderItemsToCartItems(response.data.orderItems));
        if (response.data.orderStatus !== OrderStatuses.COMPLETE) updateOrder({ order: response.data });

        if ((savePendingOrderStatus && isPendingOrder(savePendingOrderStatus as any)) || isTakeAwayOrder(orderType)) {
            clearPos();
        }

        await printOrderCommand({ ...response.data, orderItems: request.orderItems, cancelledItems } as any);
    };

    const handleUpdatePendingOrder = async () => {
        await updatePendingOrder();
        clearPos();

        if (orderOpenedFromTable) return history.goBack();
    };

    const getPrimaryButtonAction = () => {
        if ((cannotChargeTakeAwayOrders || isEatHereOrder(orderType)) && !hasPendingOrder && posPendingOrdersEnabled && !acceptManuallyAllOrdersEnabled) return handleSavePendingOrder();
        if ((cannotChargeTakeAwayOrders || isEatHereOrder(orderType)) && hasPendingOrder && posPendingOrdersEnabled && !acceptManuallyAllOrdersEnabled) return handleUpdatePendingOrder();
        if (isEatHereOrder(orderType) && !posPendingOrdersEnabled) return handlePay();
        if (isDeliveryOrder(orderType) || isTakeAwayOrder(orderType)) return handlePay();
    };

    const getPrimaryButtonLabel = () => {
        if (isEatHereOrder(orderType) && !hasPendingOrder && posPendingOrdersEnabled && !acceptManuallyAllOrdersEnabled) return translate('Send and leave');
        if (isEatHereOrder(orderType) && !hasPendingOrder && posPendingOrdersEnabled && !acceptManuallyAllOrdersEnabled) return translate('Print Command');
        if (isEatHereOrder(orderType) && !hasPendingOrder && !posPendingOrdersEnabled) return translate('Charge and close');
        if (isEatHereOrder(orderType) && hasPendingOrder) return translate('Send and leave');
        if (cannotChargeTakeAwayOrders && posPendingOrdersEnabled) return translate('Save Without Charging');
        if (isTakeAwayOrder(orderType) || isDeliveryOrder(orderType)) return translate('Charge');
        return null;
    };

    if (!getPrimaryButtonLabel()) return null;

    return (
        <Button
            onClick={getPrimaryButtonAction}
            disabled={primaryButtonDisabled}
            classes={{ button: classNames(classes.basicButton, posInterfaceLargerEnabled ? classNames(classes.largerButton, classes.largerPrimaryButton) : '') }}
        >
            {getPrimaryButtonLabel()}
        </Button>
    );
}

const useStyles = makeStyles((theme) => ({
    basicButton: {
        width: '100%',
        height: 50,
    },
    largerPrimaryButton: {
        order: -1,
    },
    largerButton: {
        fontSize: 15,
        height: 52,
    },
}));
