import { ListSubheader, makeStyles } from '@material-ui/core';
import Badge from '@material-ui/core/Badge';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { OrderStatuses } from 'src/constants/OrderStatus';
import { RoutePaths } from 'src/constants/RoutePath';
import { translate } from 'src/i18n/translate';
import { posReducer } from 'src/reducers/posReducer';
import { useOpenBrand } from 'src/services/restaurant/useOpenBrand';
import { useOpenRestaurant } from 'src/services/restaurant/useOpenRestaurant';
import { useUserRestaurantIds } from 'src/services/restaurant/useUserRestaurantIds';
import { useAction } from 'src/utils/react/useAction';
import { useSelector } from 'src/utils/react/useSelector';
import { isBrandUser } from 'src/utils/user/isBrandUser';

export function BrandAppMenuSelect(): React.ReactElement | null {
    const classes = useStyles();
    const location = useLocation();
    const history = useHistory();
    const userRestaurantIds = useUserRestaurantIds();

    const [selectIsOpened, setSelectIsOpened] = useState(false);
    const [selectValue, setSelectValue] = useState('');

    const restaurant = useSelector((state) => state.app.restaurant);
    const userType = useSelector((state) => state.authentication.userType);
    const brands = useSelector((state) => state.app.brands);
    const restaurants = useSelector((state) => state.app.restaurants) ?? [];
    const currentOrders = useSelector((state) => state.app2.currentOrders) ?? [];

    const clearCashRegisterBusinessDay = useAction(posReducer.actions.clearCashRegisterBusinessDay);

    const { openRestaurant } = useOpenRestaurant();
    const { openBrand } = useOpenBrand();

    const invalidLocations = [RoutePaths.POS, RoutePaths.PIN_CODE_USER_LOGIN, RoutePaths.THE_MENU, RoutePaths.THE_RESTAURANT, RoutePaths.PRINTERS, RoutePaths.OPENING_HOURS, RoutePaths.INTEGRATIONS];
    const isUserOnInvalidBrandLocation = invalidLocations.includes(location.pathname);
    const newOrders = currentOrders.filter((order) => order.orderStatus === OrderStatuses.NEW);
    const filteredRestaurants = restaurants.filter((restaurant) => userRestaurantIds.includes(restaurant.restaurantId));

    useEffect(() => {
        if (restaurant?.restaurantId && !selectValue && userType && brands) {
            if (isBrandUser(userType)) {
                setSelectValue('brand');
            } else {
                setSelectValue(restaurant?.restaurantId);
            }
        }
    }, [restaurant, selectValue, userType, brands]);

    const handleRestaurantsSelect = (e: any) => {
        if (e.target.value === 'brand') return handleBrandOption();
        setSelectIsOpened(false);
        setSelectValue(e.target.value);
        openRestaurant(e.target.value);
        clearCashRegisterBusinessDay();
    };

    const handleBrandOption = () => {
        if (isUserOnInvalidBrandLocation) {
            history.replace({
                pathname: RoutePaths.ORDERS,
                search: location.search,
            });
        }
        setSelectIsOpened(false);
        setSelectValue('brand');
        openBrand(brands[0]?.brandId);
    };

    const handleClickSelect = () => {
        setSelectIsOpened(!selectIsOpened);
    };

    if (!restaurant.restaurantId) return null;

    return (
        <Select open={selectIsOpened} value={selectValue} onChange={handleRestaurantsSelect} fullWidth onClick={handleClickSelect}>
            <MenuItem value={'brand'} className={classes.restaurantSelectItem}>
                <div>
                    <span className={classes.restaurantSelectItemSpan}>{translate('Brand')}</span>
                    <Badge className={classes.restaurantSelectItemBadge} badgeContent={newOrders.length} classes={{ badge: classes.badge }} />
                </div>
            </MenuItem>
            <ListSubheader classes={{ root: classes.listSubheader }}>{translate('Restaurants')}</ListSubheader>
            {filteredRestaurants.map((restaurant) => {
                const newOrdersForRestaurant = newOrders.filter((order) => order.restaurant?.restaurantId === restaurant.restaurantId).length;
                return (
                    <MenuItem value={restaurant.restaurantId} className={classes.restaurantSelectItem} key={restaurant.restaurantId}>
                        <span className={classes.restaurantSelectItemSpan}>{restaurant.name}</span>
                        <Badge className={classes.restaurantSelectItemBadge} badgeContent={newOrdersForRestaurant} classes={{ badge: classes.badge }} />
                    </MenuItem>
                );
            })}
        </Select>
    );
}

const useStyles = makeStyles((theme) => ({
    listSubheader: {
        fontFamily: theme.typography.medium,
        paddingLeft: 10,
    },
    restaurantSelectItem: {
        width: '100%',
        flexDirection: 'row',
        display: 'flex',
        alignItems: 'center',
        paddingLeft: 20,
    },
    restaurantSelectItemDisabled: {
        color: '#9a9b9b',
    },
    restaurantSelectItemSpan: {
        width: '100%',
        fontFamily: theme.typography.regular,
    },
    restaurantSelectItemBadge: {
        marginLeft: '2vw',
    },
    badge: {
        backgroundColor: theme.palette.surface.brandContrast,
        color: 'white',
    },
}));
