import { usePaymentTerminalsAvailable } from 'src/services/device/usePaymentTerminalAvailable';
import { useSelector } from 'src/utils/react/useSelector';

export function useHasPaymentTerminalsAvailable(): boolean {
    const paymentTerminalsAvailable = usePaymentTerminalsAvailable();
    const newDeviceManagementEnabled = useSelector((state) => state.app.restaurant?.newDeviceManagementEnabled);

    const hasPaymentTerminalsAvailable = newDeviceManagementEnabled && paymentTerminalsAvailable?.length > 0;

    return hasPaymentTerminalsAvailable;
}
