import { letseatmanagerApiMethod } from 'src/api/letseatmanagerApi';
import type { ApiSauceResponse } from 'src/api/types/ApiSauceResponse';
import type { RestaurantId } from 'src/types/Id';

export async function openRappiStoreApi(request: OpenRappiStoreApiRequest): ApiSauceResponse<any> {
    return letseatmanagerApiMethod('integration/openRappiStoreApi', request);
}

type OpenRappiStoreApiRequest = {
    restaurantId: RestaurantId;
};
