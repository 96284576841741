import { makeStyles } from '@material-ui/core';
import * as React from 'react';
import { useState } from 'react';
import { acceptIntegrationMenuApprovalApi } from 'src/api/letseatmanager/menu/acceptIntegrationMenuApprovalApi';
import { app2 } from 'src/app2';
import { Button } from 'src/components/Button';
import { Dialog } from 'src/components/Dialog';
import { DialogActions } from 'src/components/DialogActions';
import { Text } from 'src/components/Text';
import { translate } from 'src/i18n/translate';
import { CheckIcon } from 'src/icons/CheckIcon';
import { RemoveAlertIcon } from 'src/icons/RemoveAlertIcon';
import { alertKnownErrorOrSomethingWentWrong } from 'src/utils/alert/alertKnownErrorOrSomethingWentWrong';
import { useAction } from 'src/utils/react/useAction';
import { useSelector } from 'src/utils/react/useSelector';

export function IntegrationMenuChangesApprovalDialog(): React.ReactElement | null {
    const classes = useStyles();

    const [loading, setLoading] = useState(false);

    const open = useSelector((state) => state.app2.integrationMenuChangesApprovalDialog.open);
    const menu = useSelector((state) => state.app2.integrationMenuChangesApprovalDialog.menu);

    const closeIntegrationMenuChangesApprovalDialog = useAction(app2.actions.closeIntegrationMenuChangesApprovalDialog);

    const getTitle = () => {
        if (menu.syncIntegrationChangesSucceeded) return translate('Menu changes were approved');
        if (menu.syncIntegrationChangesFailed) return translate('Menu changes were rejected');
    };

    const handleAccept = async () => {
        setLoading(true);
        const response = await acceptIntegrationMenuApprovalApi({ menuId: menu?.menuId });
        setLoading(false);
        if (!response.ok) {
            alertKnownErrorOrSomethingWentWrong(response);
            return;
        }
        closeIntegrationMenuChangesApprovalDialog();
    };

    if (!menu) return null;

    return (
        <Dialog open={open} title={getTitle()} loading={loading} classes={{ title: !!menu?.syncIntegrationChangesFailed ? classes.dialogTitle : '' }}>
            <div className={classes.container}>
                {menu?.syncIntegrationChangesSucceeded && <CheckIcon title={translate('Approved icon')} />}
                {menu?.syncIntegrationChangesFailed && <RemoveAlertIcon title={translate('Rejected icon')} />}

                {menu?.syncIntegrationChangesSucceeded && (
                    <Text size={'large'} variant={'medium'}>
                        {translate('The menu @menuName has been updated correctly', { menuName: menu.name })}
                    </Text>
                )}
                {menu?.syncIntegrationChangesFailed && (
                    <div className={classes.messageContainer}>
                        <Text size={'large'} variant={'medium'}>
                            {translate('The menu @menuName has not been updated correctly cause has been rejected by integrator', { menuName: menu.name })}
                        </Text>
                        {!!menu?.integrationChangesMessageError && (
                            <>
                                <Text size={'small'}>{translate('The menu had the following errors')}</Text>
                                <Text size={'small'}>{menu?.integrationChangesMessageError}</Text>
                            </>
                        )}
                    </div>
                )}
                <DialogActions className={classes.buttonsContainer}>
                    <Button onClick={handleAccept} disabled={loading} classes={{ button: !!menu?.syncIntegrationChangesFailed ? classes.buttonError : '' }}>
                        {translate('Accept')}
                    </Button>
                </DialogActions>
            </div>
        </Dialog>
    );
}

const useStyles = makeStyles((theme) => ({
    container: {
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        gap: 20,
    },
    messageContainer: {
        display: 'flex',
        flexDirection: 'column',
        gap: 10,
    },
    buttonsContainer: {
        justifyContent: 'center',
    },
    buttonError: {
        backgroundColor: '#E32F45',
        '&:hover': {
            backgroundColor: '#E32F45',
        },
    },
    dialogTitle: {
        color: '#E32F45',
    },
}));
