import { Switch } from '@pidedirecto/ui';
import * as React from 'react';
import { useState } from 'react';
import { changeRappiTurboEnabledApi } from 'src/api/letseatadmin/rappiIntegration/changeRappiTurboEnabledApi';
import { translate } from 'src/i18n/translate';
import { alertKnownErrorOrSomethingWentWrong } from 'src/utils/alert/alertKnownErrorOrSomethingWentWrong';
import { useSelector } from 'src/utils/react/useSelector';

export function EnableRappiTurboSetting({ rappiTurboEnabled, onChange }: Props): React.ReactElement | null {
    const [loading, setLoading] = useState(false);

    const adminUser = useSelector((state) => state.authentication.adminUser);
    const restaurantId = useSelector((state) => state.app.restaurantId);

    const toggleRappiTurbo = async () => {
        setLoading(true);
        const response = await changeRappiTurboEnabledApi({
            restaurantId,
            rappiTurboEnabled: !rappiTurboEnabled,
        });
        setLoading(true);
        if (!response.ok) return alertKnownErrorOrSomethingWentWrong(response);
        onChange?.();
    };

    if (!adminUser) return null;

    return <Switch labelPosition='left' checked={rappiTurboEnabled} onChange={toggleRappiTurbo} name='rappiTurboEnabled' label={translate('Enable Rappi Turbo')} disabled={loading} />;
}

type Props = {
    rappiTurboEnabled: boolean;
    onChange?: Function;
};
