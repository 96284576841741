import { Grid, makeStyles } from '@material-ui/core';
import moment from 'moment';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { getIssueRestaurantStatisticsApi, NotCompleteOrdersByDay, NotCompleteOrdersByHour } from 'src/api/letseatmanager/restaurantDashboard/getIssueRestaurantStatisticsApi';
import { UpdatingContentProgress } from 'src/components/UpdatingContentProgress';
import { FilterMasterReports } from 'src/scenes/letseatmanager/restaurantDashboard/FilterMasterReports';
import { FilterReports } from 'src/scenes/letseatmanager/restaurantDashboard/FilterReports';
import { IssueReasonsByDayGraph } from 'src/scenes/letseatmanager/restaurantDashboard/issueReport/IssueReasonsByDayGraph';
import { IssueReasonsByHourGraph } from 'src/scenes/letseatmanager/restaurantDashboard/issueReport/IssueReasonsByHourGraph';
import { OrderIssuesTable } from 'src/scenes/letseatmanager/restaurantDashboard/issueReport/OrderIssuesTable';
import { ReportSectionEmptyState } from 'src/scenes/letseatmanager/restaurantDashboard/ReportSectionEmptyState';
import { RestaurantId } from 'src/types/Id';
import { useFormatDateToRestaurantTimeZone } from 'src/utils/react/useFormatDateToRestaurantTimeZone';
import { useLoadApi } from 'src/utils/react/useLoadApi';
import { useSelector } from 'src/utils/react/useSelector';

export function OrderIssueReport(): React.ReactElement {
    const classes = useStyles();

    const [hasInfoFound, setHasInfoFound] = useState(false);
    const [issueReasonsByDayData, setIssueReasonsByDayData] = useState<Array<NotCompleteOrdersByDay>>([]);
    const [issueReasonsByHourData, setIssueReasonsByHourData] = useState<Array<NotCompleteOrdersByHour>>([]);
    const [ordersIssuesWithReasonsData, setOrdersIssuesWithReasonsData] = useState<any>({});
    const [showAsMaster, setShowAsMaster] = useState(false);

    const restaurantId = useSelector((state) => state.app.restaurantId);
    const restaurantIds = useSelector((state) => state.app.restaurantIds);
    const brandOpened = useSelector((state) => state.app.brandOpened);
    const period = useSelector((state) => state.app2.filterReportsState.period);
    const currentRestaurantId = useSelector((state) => state.app2.filterReportsState.currentRestaurantId);
    const salesType = useSelector((state) => state.app.salesTypeFilter);

    const formatDateToRestaurantTimeZone = useFormatDateToRestaurantTimeZone();

    const [loading, reports] = useLoadApi(
        getIssueRestaurantStatisticsApi,
        {
            report: showAsMaster ? 'asBrand' : 'perRestaurant',
            restaurantIds: brandOpened ? restaurantIds : [restaurantId],
            fromDate: moment(formatDateToRestaurantTimeZone(period?.from)).toISOString(),
            toDate: moment(formatDateToRestaurantTimeZone(period.to)).toISOString(),
            salesType: salesType,
        },
        {
            dependencies: [period, brandOpened, showAsMaster, restaurantId, salesType],
        },
    );

    useEffect(() => {
        if (reports) {
            if (showAsMaster || !brandOpened) return handleUpdateOrderIssuesReports(reports[0]?.restaurantId);

            handleUpdateOrderIssuesReports(currentRestaurantId ?? reports[0]?.restaurantId);
        }
    }, [reports, currentRestaurantId]);

    const handleUpdateOrderIssuesReports = (newRestaurantId: RestaurantId) => {
        const currentRestaurantReportInfo = reports?.find((restaurant) => restaurant.restaurantId === newRestaurantId);
        if (!hasRestaurantReportInfo(currentRestaurantReportInfo)) {
            setHasInfoFound(false);
            return;
        }
        setIssueReasonsByDayData(currentRestaurantReportInfo?.notCompleteOrdersByDay ?? []);
        setIssueReasonsByHourData(currentRestaurantReportInfo?.notCompleteOrdersByHour ?? []);
        setOrdersIssuesWithReasonsData({
            totalLostSales: [currentRestaurantReportInfo?.totalLostSales, currentRestaurantReportInfo?.mostCommonOrderProblem] ?? [],
            ordersIssuesWithReasonsData: currentRestaurantReportInfo?.cancelledAndRejectedOrdersByDayAndRejectReasons ?? [],
        });
        setHasInfoFound(true);
    };

    const hasRestaurantReportInfo = (currentRestaurant: (typeof reports)[number] | undefined) => currentRestaurant?.notCompleteOrdersByDay;

    const handleShowAsMaster = (value: any) => {
        setShowAsMaster(value);
    };

    return (
        <div className={classes.container}>
            <FilterReports />
            <FilterMasterReports onChangeMaster={handleShowAsMaster} showAsMaster={showAsMaster} />
            {loading && <UpdatingContentProgress />}
            {hasInfoFound && (
                <div>
                    <Grid container className={classes.container}>
                        <>
                            {/* @ts-ignore */}
                            <Grid item sx={12} md={6} className={classes.container}>
                                <IssueReasonsByDayGraph issueReasonsByDayData={issueReasonsByDayData} loading={loading} />
                            </Grid>
                            {/* @ts-ignore */}
                            <Grid item sx={12} md={6} className={classes.container}>
                                <IssueReasonsByHourGraph issueReasonsByHourData={issueReasonsByHourData} loading={loading} />
                            </Grid>
                        </>
                    </Grid>
                    <OrderIssuesTable ordersIssuesWithReasonsData={ordersIssuesWithReasonsData} loading={loading} />
                </div>
            )}
            {!hasInfoFound && !loading && <ReportSectionEmptyState />}
        </div>
    );
}

const useStyles = makeStyles((theme) => ({
    container: {
        width: '100%',
    },
}));
