import { history } from 'src/config/history';
import { RoutePaths } from 'src/constants/RoutePath';
import type { DriverId } from 'src/types/Id';
import { RegExps } from 'src/utils/RegExps';

export function openDriverBillingPage(driverId?: DriverId): void {
    if (!driverId) return;
    history.push({
        pathname: RoutePaths.ADMIN_DRIVER_BILLING_FOR_DRIVER.replace(`:driverId(${RegExps.uuid})`, driverId),
        search: history.location.search,
    });
}
