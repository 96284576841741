import { makeStyles } from '@material-ui/core';
import { Button, Text } from '@pidedirecto/ui';
import { AirTableIcon, ClockIcon, IdentifierIcon, UsersIcon } from '@pidedirecto/ui/icons';
import * as React from 'react';
import { updateOrderTicketRequestedApi } from 'src/api/letseatmanager/order/updateOrderTicketRequestedApi';
import { AppIcon } from 'src/components/icon/AppIcon';
import { Apps } from 'src/constants/App';
import { RolePermissions } from 'src/constants/RolePermission';
import { translate } from 'src/i18n/translate';
import { getPosInterfaceLargerEnabledRestaurantSettingInLocalStorage } from 'src/localStorage/getPosInterfaceLargerEnabledRestaurantSettingInLocalStorage';
import { CloseOrderButton } from 'src/modules/order/components/CloseOrderButton';
import { OpenClosedOrderButton } from 'src/modules/order/components/OpenClosedOrderButton';
import { LocalizationService } from 'src/services/LocalizationService';
import { useLoadPosMenus } from 'src/services/menu/useLoadPosMenus';
import { useChargeOrder } from 'src/services/pos/useChargeOrder';
import { usePrintOrderTicket } from 'src/services/printer/usePrintOrderTicket';
import type { OrderVm } from 'src/types/OrderVm';
import { isToday } from 'src/utils/date/isToday';
import { getOrderStatus } from 'src/utils/order/getOrderStatus';
import { isClosedOrder } from 'src/utils/order/isClosedOrder';
import { isDeliveryOrder } from 'src/utils/order/isDeliveryOrder';
import { isEatHereOrder } from 'src/utils/order/isEatHereOrder';
import { isPendingOrder } from 'src/utils/order/isPendingOrder';
import { toHumanizedOrderId } from 'src/utils/order/toHumanizedOrderId';
import { useHasPrintersToPrint } from 'src/utils/printer/useHasPrintersToPrint';
import { classNames } from 'src/utils/react/classNames';
import { useEditPendingOrder } from 'src/utils/react/useEditPendingOrder';
import { useSelector } from 'src/utils/react/useSelector';
import { useUserHasRolePermission } from 'src/utils/react/useUserHasRolePermissions';

export function PosPendingOrderCard({ pendingOrder, changeOrderDetailsDialogState }: Props): React.ReactElement {
    const classes = useStyles();

    const editPendingOrder = useEditPendingOrder();
    const { hasPrintersToPrint } = useHasPrintersToPrint();
    const [printOrderTicket] = usePrintOrderTicket();
    const { handlePay } = useChargeOrder();
    const loadPosMenus = useLoadPosMenus();
    const [userHasRolePermission] = useUserHasRolePermission();

    const posInterfaceLargerEnabled = getPosInterfaceLargerEnabledRestaurantSettingInLocalStorage();

    const internalUser = useSelector((state) => state.authentication.internalUser);
    const consecutiveOrderIdEnabled = useSelector((state) => state.app.restaurant?.consecutiveOrderIdEnabled);
    const posMenu = useSelector((state) => state.pos.posMenu);
    const closeOrdersEnabled = useSelector((state) => state.app.restaurant?.closeOrdersEnabled);

    const showHumanizedOrderId = consecutiveOrderIdEnabled ? internalUser || !Boolean(pendingOrder.consecutiveOrderId) : true;
    const shouldRenderChargeButton = (isPendingOrder(pendingOrder.orderStatus) || isClosedOrder(pendingOrder.orderStatus)) && (userHasRolePermission(RolePermissions.CHARGE_ORDERS) || internalUser);
    const showEditButton = !isDeliveryOrder(pendingOrder.orderType) && isPendingOrder(pendingOrder.orderStatus) && !isClosedOrder(pendingOrder.orderStatus);
    const showConsecutiveOrderId = consecutiveOrderIdEnabled && pendingOrder.consecutiveOrderId;
    const isKioskOrder = pendingOrder.app === Apps.PIDEDIRECTOKIOSK;
    const shouldRenderCloseOrderButton = closeOrdersEnabled && isEatHereOrder(pendingOrder?.orderType) && isPendingOrder(pendingOrder.orderStatus);

    const handleEditPendingOrder = () => editPendingOrder({ order: pendingOrder });

    const handleSeeMorePendingDeliveryOrder = () => changeOrderDetailsDialogState({ open: true, pendingOrder });

    const handleClickPrintTicket = async () => {
        if (!pendingOrder?.ticketRequestedAt && isEatHereOrder(pendingOrder.orderType)) {
            updateOrderTicketRequestedApi({ orderId: pendingOrder?.orderId });
        }

        await printOrderTicket(pendingOrder, { forceToPrint: true });
    };

    const onHandlePay = async () => {
        if (!posMenu) {
            await loadPosMenus();
        }
        handlePay(pendingOrder);
    };

    return (
        <Button classes={{ button: classes.cardContainer }} variant='secondary' onClick={handleSeeMorePendingDeliveryOrder}>
            <div className={classes.cardRow}>
                <div className={classes.rightSideContainer}>
                    <AppIcon app={pendingOrder.app} />
                    <p className={classes.title}>{pendingOrder.customerName || 'POS'}</p>
                </div>
                <div className={classes.orderTypeContainer}>
                    <Text>{translate(`OrderTypes.${pendingOrder.orderType}`)}</Text>
                </div>
            </div>
            <div className={classes.cardRow}>
                <div className={classes.rightSideContainer}>
                    <IdentifierIcon size={24} />
                    {showHumanizedOrderId && <Text>#{toHumanizedOrderId(pendingOrder.orderId)}</Text>}
                    {isKioskOrder && (
                        <Text>
                            {translate('Kiosk Id:')} #{pendingOrder.dailyOrderId}
                        </Text>
                    )}
                </div>
                {showConsecutiveOrderId && <Text>#{pendingOrder.consecutiveOrderId}</Text>}
            </div>
            {pendingOrder.table && (
                <div className={classes.cardRow}>
                    <div className={classes.rightSideContainer}>
                        <AirTableIcon size={24} />
                        <Text>{`${translate('Table')} ${pendingOrder.table ?? ''}`}</Text>
                    </div>
                    <Text>{pendingOrder.restaurantZone ?? ''}</Text>
                </div>
            )}
            {pendingOrder.numberOfCustomers && (
                <div className={classes.cardRow}>
                    <div className={classes.rightSideContainer}>
                        <UsersIcon size={24} />
                        <Text>{translate('@numberOfCustomers customers', { numberOfCustomers: pendingOrder.numberOfCustomers })}</Text>
                    </div>
                </div>
            )}
            <div className={classes.cardRow}>
                <div className={classes.rightSideContainer}>
                    <ClockIcon size={24} />
                    <Text>{LocalizationService.formatDateTimeAsTime(pendingOrder.createdAt, pendingOrder.timeZone)}</Text>
                </div>
                {isToday(pendingOrder.createdAt, pendingOrder.timeZone) && LocalizationService.formatDateTimeAsCalendarDate(pendingOrder.createdAt)}
            </div>
            {isDeliveryOrder(pendingOrder.orderType) && (
                <div className={classes.cardRow}>
                    <Text>{getOrderStatus(pendingOrder)}</Text>
                </div>
            )}
            <div className={classes.buttonsContainer}>
                {!shouldRenderCloseOrderButton && (
                    <Button
                        classes={{ button: classNames(showEditButton ? classes.basicButton : classes.seeMore, posInterfaceLargerEnabled ? classes.interfaceLarger : '') }}
                        variant='secondary'
                        onClick={handleClickPrintTicket}
                        disabled={!hasPrintersToPrint}
                    >
                        {translate('Print Bill')}
                    </Button>
                )}
                <CloseOrderButton order={pendingOrder} classes={{ button: classes.basicButton }} />
                <div
                    className={classNames(
                        (showEditButton && shouldRenderChargeButton) || (isClosedOrder(pendingOrder.orderStatus) && shouldRenderChargeButton) ? classes.buttonsRowContainer : classes.basicButton,
                    )}
                >
                    {showEditButton && (
                        <Button
                            classes={{ button: classNames(classes.basicButton, posInterfaceLargerEnabled ? classNames(classes.interfaceLarger, classes.editButton) : '') }}
                            onClick={handleEditPendingOrder}
                        >
                            {translate('Edit')}
                        </Button>
                    )}
                    <OpenClosedOrderButton order={pendingOrder} classes={{ button: classes.basicButton }} />
                    {shouldRenderChargeButton && (
                        <Button classes={{ button: classNames(classes.basicButton, posInterfaceLargerEnabled ? classNames(classes.interfaceLarger, classes.editButton) : '') }} onClick={onHandlePay}>
                            {translate('Charge')}
                        </Button>
                    )}
                </div>
            </div>
        </Button>
    );
}

const useStyles = makeStyles((theme) => ({
    cardContainer: {
        minWidth: 320,
        height: 'max-content',
        display: 'flex',
        flexDirection: 'column',
        boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
        border: 0,
        alignItems: 'flex-start',
        justifyContent: 'flex-start',
        padding: '18px',
        '&:hover': {
            backgroundColor: theme.palette.surface.tertiary,
        },
    },
    rightSideContainer: {
        display: 'flex',
        alignItems: 'center',
        gap: 8,
    },
    title: {
        color: theme.palette.text.primary,
        fontSize: 20,
        margin: 0,
        fontFamily: theme.typography.semiBold,
    },
    cardRow: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        width: '100%',
    },
    buttonsContainer: {
        display: 'flex',
        flexWrap: 'wrap',
        width: '100%',
        gap: 12,
    },
    seeMore: {
        width: '100%',
    },
    interfaceLarger: {
        fontSize: 14,
        width: '100% !important',
        height: 52,
    },
    editButton: {
        order: -1,
    },
    basicButton: {
        width: '100%',
    },
    orderTypeContainer: {
        borderRadius: 4,
        backgroundColor: theme.palette.surface.tertiary,
        padding: '4px 8px',
    },
    buttonsRowContainer: {
        display: 'grid',
        gridTemplateColumns: '1fr 1fr',
        gap: 12,
        width: '100%',
    },
    '@keyframes colorized': {
        '0%': {
            backgroundColor: ' transparent',
        },
        '50%': {
            backgroundColor: ' #ffbcc1',
        },
        '100%': {
            backgroundColor: 'transparent',
        },
    },
}));

type Props = {
    pendingOrder: OrderVm;
    changeOrderDetailsDialogState: any;
};
