import { letseatmanagerApiMethod } from 'src/api/letseatmanagerApi';
import type { ApiSauceResponse } from 'src/api/types/ApiSauceResponse';
import type { Print } from 'src/services/printer/types/Print';
import type { PrinterVm } from 'src/types/PrinterVm';

export async function printWithRemotePrinterApi(request: PrintWithRemotePrinterApiRequest): ApiSauceResponse<void> {
    return letseatmanagerApiMethod('printer/printWithRemotePrinterApi', request);
}

export type PrintWithRemotePrinterApiRequest = {
    print: Print;
    printer: PrinterVm;
};
