import { makeStyles } from '@material-ui/core';
import * as React from 'react';
import { useController, useFormContext } from 'react-hook-form';
import { getError } from 'src/components/form/utils/getError';

export function FormError({ value, name, validate }: Props): React.ReactElement {
    const classes = useStyles();
    const { control, errors } = useFormContext();
    const { field } = useController({
        name: name,
        control,
        defaultValue: '',
        rules: {
            validate: {
                showError: () => validate?.(value)?.message,
            },
        },
    });

    return (
        <>
            <input name={field.name} ref={field.ref} onChange={field.onChange} style={{ display: 'none' }} />
            <span className={classes.error}>{getError(errors, name)?.message}</span>
        </>
    );
}

const useStyles = makeStyles((theme) => ({
    error: {
        fontFamily: theme.typography.light,
        color: 'red',
        wordBreak: 'break-all',
        margin: '5px 0px',
    },
}));

type Props = {
    value: any;
    name: string;
    validate: (value?: any) =>
        | {
              message: string;
          }
        | undefined;
};
