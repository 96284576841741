export const SubscriptionStatuses = {
    NEW: 'NEW',
    PAID: 'PAID',
    PENDING: 'PENDING',
    CANCELLED: 'CANCELLED',
    TEST: 'TEST',
    INACTIVE: 'INACTIVE',
} as const;

export type SubscriptionStatus = (typeof SubscriptionStatuses)[keyof typeof SubscriptionStatuses];
