import moment from 'moment-timezone';
import type { TimeZone } from 'src/constants/TimeZone';
import { upperCaseFirstLetter } from 'src/utils/string/upperCaseFirstLetter';

export function formatDateTimeStringReadable(value?: string | Date, timeZone?: TimeZone): string {
    if (!value) {
        return '';
    }
    if (!timeZone) {
        return upperCaseFirstLetter(
            moment(value).calendar(null, {
                sameElse: 'llll',
            }),
        );
    }

    return upperCaseFirstLetter(
        moment.tz(value, timeZone).calendar(null, {
            sameElse: 'llll',
        }),
    );
}
