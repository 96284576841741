import { makeStyles } from '@material-ui/core';
import FormHelperText from '@material-ui/core/FormHelperText';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import * as React from 'react';
import { useEffect } from 'react';
import { useForm, useWatch } from 'react-hook-form';
import { changeTheRestaurantApi } from 'src/api/letseatmanager/theRestaurant/changeTheRestaurantApi';
import type { TheRestaurantVm } from 'src/api/letseatmanager/theRestaurant/getTheRestaurantApi';
import { Form } from 'src/components/form/Form';
import { FormExternalDeliveryEstimatedTimeSelect } from 'src/components/form/FormExternalDeliveryEstimatedTimeSelect';
import { FormFixErrorHint } from 'src/components/form/FormFixErrorHint';
import { FormSubmitButton } from 'src/components/form/FormSubmitButton';
import { FormTextField } from 'src/components/form/FormTextField';
import { UpdatingContentProgress } from 'src/components/UpdatingContentProgress';
import { ExternalDeliveryEstimatedTimes } from 'src/constants/ExternalDeliveryEstimatedTime';
import { translate } from 'src/i18n/translate';
import { appReducer } from 'src/reducers/appReducer';
import { useReloadRestaurant } from 'src/services/restaurant/useReloadRestaurant';
import { alertKnownErrorOrSomethingWentWrong } from 'src/utils/alert/alertKnownErrorOrSomethingWentWrong';
import { useAction } from 'src/utils/react/useAction';
import { useSelector } from 'src/utils/react/useSelector';

export function RestaurantDeliverySettings({ loading, restaurant, onChangeRestaurant, setIsDirtyForm }: Props): React.ReactElement {
    const classes = useStyles();
    const form = useForm();
    const {
        formState: { dirtyFields },
        watch,
        control,
        formState,
    } = form;

    const uberEatsDeliveryEnabled = watch('uberEatsDeliveryEnabled');
    const rappiCargoDeliveryEnabled = watch('rappiCargoDeliveryEnabled');
    const pideDirectoDeliveryEnabled = watch('pideDirectoDeliveryEnabled');
    const uberDaasDeliveryEnabled = watch('uberDaasDeliveryEnabled');
    const externalDelivery = useWatch<boolean>({ name: 'externalDelivery', control });
    const dynamicDeliveryManagerEnabled = watch('dynamicDeliveryManager');
    const ownFleetEnabled = watch('ownFleetEnabled');

    const appLoading = useSelector((state) => state.app.loading);
    const restaurantId = useSelector((state) => state.app.restaurantId);

    const showSnackbar = useAction(appReducer.actions.showSnackbar);
    const { reloadRestaurant } = useReloadRestaurant();

    useEffect(() => {
        if (!loading) formReset();
    }, [loading]);

    useEffect(() => {
        setIsDirtyForm(Object.keys(dirtyFields).length !== 0);
    }, [formState]);

    const formReset = () => {
        form.reset({
            restaurantId: restaurant.restaurantId ?? null,
            ownFleetEnabled: restaurant.ownFleetEnabled ?? null,
            showOrderItemsToDriverEnabled: restaurant.showOrderItemsToDriverEnabled ?? null,
            uberEatsDeliveryEnabled: restaurant.uberEatsDeliveryEnabled ?? null,
            rappiCargoDeliveryEnabled: restaurant.rappiCargoDeliveryEnabled ?? null,
            externalDelivery: restaurant.externalDelivery ?? null,
            dynamicDeliveryManager: restaurant.dynamicDeliveryManager ?? null,
            externalDeliveryEstimatedTime: restaurant.externalDeliveryEstimatedTime ?? '45-60',
            driverLinkedCode: restaurant.driverLinkedCode ?? null,
            pideDirectoDeliveryEnabled: restaurant.pideDirectoDeliveryEnabled ?? null,
            uberDaasDeliveryEnabled: restaurant.uberDaasDeliveryEnabled ?? null,
        });
    };

    const onSubmit = async (form: any) => {
        const restaurantHasAtLeastOneDeliveryProvider =
            uberEatsDeliveryEnabled || uberDaasDeliveryEnabled || rappiCargoDeliveryEnabled || pideDirectoDeliveryEnabled || externalDelivery || dynamicDeliveryManagerEnabled;

        if (!restaurantHasAtLeastOneDeliveryProvider) {
            alertKnownErrorOrSomethingWentWrong({}, translate('Restaurant should have at least 1 enabled delivery provider'));
            formReset();
            return;
        }

        if (ownFleetEnabled && !pideDirectoDeliveryEnabled) {
            alertKnownErrorOrSomethingWentWrong({}, translate('Pide Directo Delivery should be enabled when own fleet is enabled'));
            formReset();
            return;
        }

        const response = await changeTheRestaurantApi({ restaurantId, ...form });
        if (!response.ok) {
            alertKnownErrorOrSomethingWentWrong(response);
            formReset();
            return;
        }

        await reloadRestaurant(restaurantId);
        await onChangeRestaurant();
        showSnackbar({ message: translate('Saved changes') });
    };

    return (
        <Paper className={classes.paper}>
            <UpdatingContentProgress loading={loading || appLoading} />
            <Form form={form} onSubmit={onSubmit} disabled={loading || appLoading}>
                <div className={classes.formContent}>
                    <Grid container spacing={3}>
                        {ownFleetEnabled && (
                            <Grid item xs={12} md={6}>
                                <FormTextField
                                    name='driverLinkedCode'
                                    label={translate('Driver OwnFleet code')}
                                    helperText={translate('Delivery drivers will be able to link to the restaurant with this code')}
                                    disabled={true}
                                    tooltip={translate('It is the code that the delivery men must enter in the delivery application when they want to belong to the restaurants fleet')}
                                />
                            </Grid>
                        )}
                        {externalDelivery && (
                            <Grid item xs={4}>
                                <FormExternalDeliveryEstimatedTimeSelect
                                    name='externalDeliveryEstimatedTime'
                                    label={translate('External Delivery Estimated Time')}
                                    defaultValue={ExternalDeliveryEstimatedTimes['45-60']}
                                    required
                                />
                                <FormHelperText>{translate('Select the estimated time for your external delivery')}</FormHelperText>
                            </Grid>
                        )}
                    </Grid>

                    <FormFixErrorHint />
                </div>

                <Grid container justify='center' spacing={3}>
                    <Grid item>
                        <FormSubmitButton text={translate('Change')} disabled={loading || appLoading} classes={{ container: (classes as any).submitButton }} />
                    </Grid>
                </Grid>
            </Form>
        </Paper>
    );
}

const useStyles = makeStyles((theme) => ({
    paper: {
        position: 'relative',
    },
    formContent: {
        padding: theme.spacing(3),
    },
    zIndex: {
        zIndex: 1000,
    },
}));

type Props = {
    loading: boolean;
    restaurant: TheRestaurantVm;
    onChangeRestaurant: any;
    setIsDirtyForm: any;
};
