import { makeStyles } from '@material-ui/core';
import { useState } from 'react';
import * as React from 'react';
import { findReleasesNotesApi } from 'src/api/letseatadmin/releaseNotes/findReleasesNotesApi';
import { Button } from 'src/components/Button';
import { Page } from 'src/components/Page';
import { SectionList } from 'src/components/SectionList';
import { SectionListItem } from 'src/components/SectionListItem';
import { Text } from 'src/components/Text';
import { UpdatingContentProgress } from 'src/components/UpdatingContentProgress';
import { translate } from 'src/i18n/translate';
import { LinkIcon } from 'src/icons/LinkIcon';
import { TrashcanIcon } from 'src/icons/TrashcanIcon';
import { CreateReleaseNotesDialog } from 'src/scenes/letseatadmin/releaseNotes/CreateReleaseNotesDialog';
import { RemoveReleaseNotesDialog } from 'src/scenes/letseatadmin/releaseNotes/RemoveReleaseNotesDialog';
import type { ReleaseNotesVm } from 'src/types/ReleaseNotesVm';
import { useAskForPassword } from 'src/utils/react/useAskForPassword';
import { useLoadApi } from 'src/utils/react/useLoadApi';
import { useSelector } from 'src/utils/react/useSelector';

export function ReleasesNotesPage(): React.ReactElement {
    const classes = useStyles();
    const askForPassword = useAskForPassword();

    const [createReleaseNotesDialogState, setCreateReleaseNotesDialogState] = useState({ open: false });
    const [removeReleaseNotesDialogState, setRemoveReleaseNotesDialogState] = useState<{ open: boolean; releaseNotes: ReleaseNotesVm | undefined }>({ open: false, releaseNotes: undefined });

    const isAdminUser = useSelector((state) => state.authentication.adminUser);

    const [loading, releasesNotes, refreshReleasesNotes] = useLoadApi(findReleasesNotesApi, undefined, { initialValue: [] });

    const openCreateReleaseNotesDialog = () => setCreateReleaseNotesDialogState({ open: true });

    const closeCreateReleaseNotesDialog = () => setCreateReleaseNotesDialogState({ open: false });

    const openRemoveReleaseNotesDialog = (releaseNotes: ReleaseNotesVm) => setRemoveReleaseNotesDialogState({ open: true, releaseNotes });

    const closeRemoveReleaseNotesDialog = () => setRemoveReleaseNotesDialogState({ open: false, releaseNotes: undefined });

    const openReleaseNotes = (releaseNotesLink: string) => window.open(releaseNotesLink, '_blank');

    const handleRemoveReleaseNotes = async (e: any, releaseNotes: ReleaseNotesVm) => {
        e.stopPropagation();
        const rightPassword = await askForPassword({ password: '2398' });
        if (!rightPassword) return;

        openRemoveReleaseNotesDialog(releaseNotes);
    };

    const handleCreateReleaseNotes = async () => {
        const rightPassword = await askForPassword({ password: '2398' });
        if (!rightPassword) return;

        openCreateReleaseNotesDialog();
    };

    const handleSelectReleaseNotes = (releasesNotesId: any) => {
        const releaseNotes = releasesNotes.find((releasesNotes) => releasesNotes.releaseNotesId === releasesNotesId);
        if (!releaseNotes) return;
        openReleaseNotes(releaseNotes?.releaseNotesUrl);
    };

    return (
        <Page title={translate('Release notes')} className={classes.container}>
            <UpdatingContentProgress loading={loading} top left />
            <CreateReleaseNotesDialog open={createReleaseNotesDialogState.open} onClose={closeCreateReleaseNotesDialog} onSuccess={refreshReleasesNotes} />
            <RemoveReleaseNotesDialog
                open={removeReleaseNotesDialogState.open}
                releaseNotes={removeReleaseNotesDialogState.releaseNotes}
                onClose={closeRemoveReleaseNotesDialog}
                onSuccess={refreshReleasesNotes}
            />
            <SectionList title={translate('Releases')} onItemClick={handleSelectReleaseNotes} onCreate={isAdminUser ? handleCreateReleaseNotes : undefined}>
                {releasesNotes.map((releaseNotes) => (
                    <SectionListItem key={releaseNotes.releaseNotesId} value={releaseNotes.releaseNotesId}>
                        <div className={classes.releaseNotesContainer}>
                            <div className={classes.releaseNotesName}>
                                <LinkIcon title={translate('Release notes link')} />
                                <Text className={classes.link}>{releaseNotes.title}</Text>
                            </div>
                            {isAdminUser && (
                                <Button classes={{ button: classes.removeButton }} icon onClick={(e: any) => handleRemoveReleaseNotes(e, releaseNotes)}>
                                    <TrashcanIcon title={translate('Remove release notes')} />
                                </Button>
                            )}
                        </div>
                    </SectionListItem>
                ))}
            </SectionList>
        </Page>
    );
}

const useStyles = makeStyles((theme) => ({
    container: {
        padding: '0 20vw',
        position: 'relative',
        [theme.breakpoints.down('md')]: {
            padding: '0 10vw',
        },
        [theme.breakpoints.down('sm')]: {
            padding: 0,
        },
    },
    releaseNotesContainer: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
    },
    releaseNotesName: {
        display: 'flex',
        flexDirection: 'row',
        gap: 10,
    },
    link: {
        textDecoration: 'underline',
    },
    removeButton: {
        color: '#E37878',
    },
}));
