import { FormSelect } from '@pidedirecto/ui/form';
import * as React from 'react';
import { TipTypes } from 'src/constants/TipType';
import { translate } from 'src/i18n/translate';

export function FormTipTypeSelect({ name, label, placeholder, helperText, defaultValue, disabled, required }: Props): React.ReactElement {
    const options = required
        ? Object.values(TipTypes).map((TipType) => ({ label: translate(TipType), value: TipType }))
        : [{ label: '', value: null }, ...Object.values(TipTypes).map((TipType) => ({ label: translate(TipType), value: TipType }))];
    return (
        <FormSelect
            name={name}
            label={label}
            placeholder={placeholder}
            helperText={helperText}
            defaultValue={defaultValue}
            options={options}
            disabled={disabled}
            required={{
                value: !!required,
                message: translate('This field is required'),
            }}
        />
    );
}

type Props = {
    name: string;
    label?: string;
    placeholder?: string;
    helperText?: string;
    defaultValue?: string | null | undefined;
    disabled?: boolean;
    required?: boolean;
};
