import { FormSelect } from '@pidedirecto/ui/form';
import * as React from 'react';
import { CustomAlertDialogTypes } from 'src/constants/CustomAlertDialogType';
import { translate } from 'src/i18n/translate';

export function FormShowAlertTypeCustomAlertSelect({ name, label, placeholder, helperText, defaultValue, disabled, required, multiselectable }: Props): React.ReactElement {
    const options = [
        {
            value: CustomAlertDialogTypes.ONE_TIME,
            label: translate('One Time'),
        },
        {
            value: CustomAlertDialogTypes.RECURRENT,
            label: translate('Recurrent'),
        },
    ];
    if (!required) {
        options.unshift({ label: '', value: '' as any });
    }

    return (
        <FormSelect
            name={name}
            label={label}
            placeholder={placeholder}
            helperText={helperText}
            defaultValue={defaultValue}
            options={options}
            disabled={disabled}
            required={{
                value: !!required,
                message: translate('This field is required'),
            }}
        />
    );
}

type Props = {
    name: string;
    label?: string;
    placeholder?: string;
    helperText?: string;
    defaultValue?: string | null | undefined;
    disabled?: boolean;
    required?: boolean;
    multiselectable?: boolean;
};
