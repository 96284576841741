import * as React from 'react';

export function RemoveAlertIcon({ className, title }: Props): React.ReactElement {
    return (
        <svg className={className} width='90' height='90' viewBox='0 0 90 90' fill='none' xmlns='http://www.w3.org/2000/svg'>
            <title>{title}</title>
            <circle cx='45' cy='45' r='45' fill='#F3CCD5' />
            <path
                fillRule='evenodd'
                clipRule='evenodd'
                d='M40.4894 30.8003C42.646 27.0666 48.0385 27.0666 50.1932 30.8003L63.9263 54.5991C66.081 58.3328 63.3848 63 59.0735 63H31.6091C27.2978 63 24.6035 58.3328 26.7582 54.5991L40.4876 30.8003H40.4894ZM45.3422 40.5957C45.7136 40.5957 46.0698 40.7433 46.3325 41.0058C46.5951 41.2684 46.7426 41.6245 46.7426 41.9959V48.9966C46.7426 49.368 46.5951 49.7241 46.3325 49.9867C46.0698 50.2493 45.7136 50.3968 45.3422 50.3968C44.9708 50.3968 44.6146 50.2493 44.352 49.9867C44.0894 49.7241 43.9419 49.368 43.9419 48.9966V41.9959C43.9419 41.6245 44.0894 41.2684 44.352 41.0058C44.6146 40.7433 44.9708 40.5957 45.3422 40.5957ZM45.3422 55.9974C45.7136 55.9974 46.0698 55.8499 46.3325 55.5873C46.5951 55.3247 46.7426 54.9686 46.7426 54.5972C46.7426 54.2259 46.5951 53.8698 46.3325 53.6072C46.0698 53.3446 45.7136 53.1971 45.3422 53.1971C44.9708 53.1971 44.6146 53.3446 44.352 53.6072C44.0894 53.8698 43.9419 54.2259 43.9419 54.5972C43.9419 54.9686 44.0894 55.3247 44.352 55.5873C44.6146 55.8499 44.9708 55.9974 45.3422 55.9974Z'
                fill='#E32F45'
            />
        </svg>
    );
}

type Props = {
    title?: string;
    className?: string;
};
