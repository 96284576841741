import { makeStyles } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import { useEffect, useState } from 'react';
import * as React from 'react';
import { useForm, useWatch } from 'react-hook-form';
import { createInventoryTransactionApi } from 'src/api/letseatmanager/inventory/createInventoryTransactionApi';
import { transferSupplyBetweenRestaurantsApi } from 'src/api/letseatmanager/inventory/transferSupplyBetweenRestaurantsApi';
import { Button } from 'src/components/Button';
import { Dialog } from 'src/components/Dialog';
import { DialogActions } from 'src/components/DialogActions';
import { Form } from 'src/components/form/Form';
import { FormCurrencyNumberStringField } from 'src/components/form/FormCurrencyNumberStringField';
import { FormInventoryTransactionReasonsSelect } from 'src/components/form/FormInventoryTransactionReasonsSelect';
import { FormNumberStringField } from 'src/components/form/FormNumberStringField';
import { FormRadio } from 'src/components/form/FormRadio';
import { FormRadioGroup } from 'src/components/form/FormRadioGroup';
import { FormRestaurantWithInventorySelect } from 'src/components/form/FormRestaurantWithInventorySelect';
import { FormSupplyAutocomplete } from 'src/components/form/FormSupplyAutocomplete';
import { FormTextField } from 'src/components/form/FormTextField';
import { FormUnitOfMeasurementSelect } from 'src/components/form/FormUnitOfMeasurementSelect';
import { InventoryTransactionReasons } from 'src/constants/InventoryTransactionReason';
import { InventoryTransactionType, InventoryTransactionTypes } from 'src/constants/InventoryTransactionType';
import { UnitOfMeasurement } from 'src/constants/UnitOfMeasurement';
import { translate } from 'src/i18n/translate';
import { RestaurantId } from 'src/types/Id';
import { SupplyVm } from 'src/types/SupplyVm';
import { alertKnownErrorOrSomethingWentWrong } from 'src/utils/alert/alertKnownErrorOrSomethingWentWrong';
import { useSelector } from 'src/utils/react/useSelector';

export function CreateInventoryLogDialog({ open, onClose, onSuccess }: Props): React.ReactElement {
    const form = useForm();
    const {
        formState: { isSubmitting },
        control,
        setError,
        clearErrors,
    } = form;

    const classes = useStyles();

    const [supplyAvailableUnits, setSupplyAvailableUnits] = useState<Array<UnitOfMeasurement>>();
    const [currentSupply, setCurrentSupply] = useState<SupplyVm>();
    const [currentDestinationSupply, setCurrentDestinationSupply] = useState<SupplyVm>();

    const restaurantId = useSelector((state) => state.app.restaurantId);
    const pinCodeUserManagerUserId = useSelector((state) => state.authentication.pinCodeUserSignedIn?.managerUserId);

    const type = useWatch<InventoryTransactionType>({ name: 'type', control, defaultValue: InventoryTransactionTypes.OUT });
    const unitOfMeasurement = useWatch({ name: 'unitOfMeasurement', control });
    const inventoryTransactionReason = useWatch({ name: 'inventoryTransactionReason', control });
    const destinationRestaurantId = useWatch<RestaurantId>({ name: 'destinationRestaurantId', control });

    const isTransference = inventoryTransactionReason === InventoryTransactionReasons.TRANSFERENCE && type === InventoryTransactionTypes.OUT;
    const isAdjustment = inventoryTransactionReason === InventoryTransactionReasons.ADJUSTMENT && type === InventoryTransactionTypes.IN;

    const outInventoryTransactionReasons = [
        InventoryTransactionReasons.WASTE,
        InventoryTransactionReasons.ACCIDENT,
        InventoryTransactionReasons.TRANSFERENCE,
        InventoryTransactionReasons.ADJUSTMENT,
        InventoryTransactionReasons.CANCEL,
    ];
    const inInventoryTransactionReasons = [InventoryTransactionReasons.TRANSFERENCE, InventoryTransactionReasons.ADJUSTMENT, InventoryTransactionReasons.CANCEL];

    useEffect(() => {
        updateSupplyAvailableUnits();
    }, [currentSupply, currentDestinationSupply]);

    useEffect(() => {
        if (!isTransference) {
            setSupplyAvailableUnits(undefined);
            setCurrentDestinationSupply(undefined);
        }
    }, [isTransference]);

    const handleClose = () => {
        if (isSubmitting) return;
        setSupplyAvailableUnits(undefined);
        setCurrentSupply(undefined);
        setCurrentDestinationSupply(undefined);
        onClose();
    };

    const onSubmit = async (form: any) => {
        if (isTransference) {
            await transferSupplyBetweenRestaurants(form);
            return;
        }
        await createInventoryLog(form);
    };

    const createInventoryLog = async (form: any) => {
        const response = await createInventoryTransactionApi({
            restaurantId,
            type: form.type,
            reason: form.inventoryTransactionReason,
            supplyId: form.supplyId,
            unitOfMeasurement: form.unitOfMeasurement,
            managerUserId: pinCodeUserManagerUserId,
            quantity: form.quantity,
            notes: form.notes,
            priceAtPurchase: form.priceAtPurchase,
        });

        if (!response.ok) {
            alertKnownErrorOrSomethingWentWrong(response);
            return;
        }

        handleClose();
        onSuccess();
    };

    const transferSupplyBetweenRestaurants = async (form: any) => {
        const response = await transferSupplyBetweenRestaurantsApi({
            sourceRestaurantId: restaurantId,
            destinationRestaurantId: form.destinationRestaurantId,
            sourceSupplyId: form.supplyId,
            destinationSupplyId: form.destinationSupplyId,
            quantity: form.quantity,
            unitOfMeasurement: form.unitOfMeasurement,
            notes: form.notes,
        });
        if (!response.ok) {
            alertKnownErrorOrSomethingWentWrong(response);
            return;
        }
        handleClose();
        onSuccess();
    };

    const updateSupplyAvailableUnits = () => {
        if (!currentSupply || !currentDestinationSupply) return;
        const currentSupplyBuyUnits = currentSupply.buyUnits.map((buyUnit) => buyUnit.unit);
        const currentDestinationSupplyBuyUnits = currentDestinationSupply.buyUnits.map((buyUnit) => buyUnit.unit);

        const newBuyUnits = currentSupplyBuyUnits.filter((buyUnit) => currentDestinationSupplyBuyUnits.includes(buyUnit));
        setSupplyAvailableUnits(newBuyUnits);

        if (newBuyUnits.length === 0) {
            setError('unitOfMeasurement', { message: translate('You have selected supplies without units on common.') });
            return;
        }
        clearErrors('unitOfMeasurement');
    };

    return (
        <Dialog open={open} onClose={handleClose} title={translate('Create Inventory Log')}>
            <Form form={form} onSubmit={onSubmit}>
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <FormRadioGroup name='type' label={translate('Inventory Log Type')} row defaultValue={InventoryTransactionTypes.OUT}>
                            <FormRadio label={translate('InventoryLogTypes.INVENTORY_ISSUE')} value={InventoryTransactionTypes.OUT} />
                            <FormRadio label={translate(`InventoryLogTypes.INVENTORY_RECEIPT`)} value={InventoryTransactionTypes.IN} />
                        </FormRadioGroup>
                    </Grid>
                    <Grid item xs={12}>
                        <FormInventoryTransactionReasonsSelect
                            name='inventoryTransactionReason'
                            label={translate('Inventory Log Movement')}
                            required
                            filter={type === InventoryTransactionTypes.OUT ? outInventoryTransactionReasons : inInventoryTransactionReasons}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <FormSupplyAutocomplete name='supplyId' label={translate('Supply')} required onChange={setCurrentSupply} />
                    </Grid>
                    {isAdjustment && (
                        <Grid item xs={12}>
                            <FormCurrencyNumberStringField name='priceAtPurchase' label={translate('Price at purchase')} required />
                        </Grid>
                    )}
                    {isTransference && (
                        <Grid item xs={12}>
                            <FormRestaurantWithInventorySelect name='destinationRestaurantId' label={translate('Destination restaurant')} required />
                        </Grid>
                    )}
                    {isTransference && !!destinationRestaurantId && (
                        <Grid item xs={12}>
                            <FormSupplyAutocomplete
                                name='destinationSupplyId'
                                label={translate('Destination supply')}
                                required
                                onChange={setCurrentDestinationSupply}
                                restaurantIdToRequest={destinationRestaurantId}
                            />
                        </Grid>
                    )}
                    <Grid item xs={12}>
                        <FormUnitOfMeasurementSelect
                            name='unitOfMeasurement'
                            label={translate('Unit')}
                            required
                            filterBySupply={!!supplyAvailableUnits ? undefined : currentSupply}
                            filterByUnits={supplyAvailableUnits}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <FormNumberStringField
                            min={0}
                            name='quantity'
                            label={translate('Quantity @unit', { unit: unitOfMeasurement ? translate(`UnitOfMeasurements.${unitOfMeasurement}`) : '' })}
                            required
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <FormTextField name='notes' label={translate('Notes')} />
                    </Grid>
                </Grid>
                <DialogActions>
                    <Button secondary onClick={handleClose} disabled={isSubmitting}>
                        {translate('Cancel')}
                    </Button>
                    <Button color='primary' type='submit' disabled={isSubmitting}>
                        {isSubmitting ? translate('Creating') : translate('Create')}
                    </Button>
                </DialogActions>
            </Form>
        </Dialog>
    );
}

const useStyles = makeStyles((theme) => ({
    addSupplierButton: {
        backgroundColor: 'white',
        color: theme.palette.primary.main,
        border: `1px solid ${theme.palette.primary.main}`,
        fontFamily: theme.typography.semiBold,
        fontSize: 15,
        borderRadius: 5,
        height: 30,
        padding: '5px 15px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        textTransform: 'none',
    },
    supplyContainer: {
        width: '100%',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        padding: '5px 15px',
        borderBottom: `1px solid ${theme.palette.secondary.dark}`,
    },
    supply: {
        fontFamily: theme.typography.regular,
        fontSize: 15,
    },
}));

type Props = {
    open: boolean;
    onClose: any;
    onSuccess: any;
};
