import { letseatmanagerApiMethod } from 'src/api/letseatmanagerApi';
import type { ApiSauceResponse } from 'src/api/types/ApiSauceResponse';
import type { RestaurantId, UberEatsStoreId } from 'src/types/Id';

export async function setUpUberEatsIntegrationApi(request: IntegrateUberEatsRequest): ApiSauceResponse<Array<UberEatsStoreVm>> {
    return letseatmanagerApiMethod('restaurantIntegration/setUpUberEatsIntegrationApi', request);
}

type IntegrateUberEatsRequest = {
    restaurantId: RestaurantId;
    uberAuthCode: string;
};

export type UberEatsStoreVm = {
    uberEatsStoreId: UberEatsStoreId;
    name: string;
    address: string;
};
