import { makeStyles } from '@material-ui/core';
import BackspaceIcon from '@material-ui/icons/Backspace';
import CheckIcon from '@material-ui/icons/Check';
import { useEffect, useState } from 'react';
import * as React from 'react';
import { Button } from 'src/components/Button';
import { translate } from 'src/i18n/translate';
import { useSelector } from 'src/utils/react/useSelector';

export function CurrencyNumericPad({ disabled, onEnter, validation }: Props): React.ReactElement {
    const classes = useStyles();

    const [number, setNumber] = useState('0');
    const [disabledEnter, setDisabledEnter] = useState(false);

    const numericPadValue = useSelector((state) => state.pos.numericPadValue);

    useEffect(() => {
        setNumber(numericPadValue);
    }, [numericPadValue]);

    useEffect(() => {
        if (number === undefined) setNumber('0');
        setDisabledEnter(!!validation?.(number));
    }, [number]);

    const handleDigit = (digit: string) => {
        if (number === '0') return setNumber(digit);

        setNumber(number + digit);
    };

    const handlePoint = (point: string) => {
        if (number.includes(point)) return;

        setNumber(number + point);
    };

    const handleBackspace = () => {
        if (number.length === 1) {
            setNumber('0');
            return;
        }

        setNumber((number) => number.slice(0, -1));
    };

    const handleEnter = async () => {
        const failed = await onEnter(number);
        if (!failed) setNumber('0');
    };

    return (
        <div className={classes.container}>
            <div className={classes.displayContainer}>
                <div aria-label={'display'} style={{ width: '80%', textAlign: 'center', margin: 'auto' }} className={classes.numberText}>{`$${number}`}</div>
                <Button icon classes={{ button: classes.checkButton }} onClick={handleEnter} disabled={disabledEnter || disabled}>
                    {/* @ts-ignore */}
                    <CheckIcon title={translate('Check icon')} />
                </Button>
            </div>
            <div className={classes.currencyPad}>
                <Button text disabled={disabled} onClick={() => handleDigit('1')} classes={{ button: classes.button, disabled: classes.buttonDisabled }}>
                    1
                </Button>
                <Button text disabled={disabled} onClick={() => handleDigit('2')} classes={{ button: classes.button, disabled: classes.buttonDisabled }}>
                    2
                </Button>
                <Button text disabled={disabled} onClick={() => handleDigit('3')} classes={{ button: classes.button, disabled: classes.buttonDisabled }}>
                    3
                </Button>
                <Button text disabled={disabled} onClick={() => handleDigit('4')} classes={{ button: classes.button, disabled: classes.buttonDisabled }}>
                    4
                </Button>
                <Button text disabled={disabled} onClick={() => handleDigit('5')} classes={{ button: classes.button, disabled: classes.buttonDisabled }}>
                    5
                </Button>
                <Button text disabled={disabled} onClick={() => handleDigit('6')} classes={{ button: classes.button, disabled: classes.buttonDisabled }}>
                    6
                </Button>
                <Button text disabled={disabled} onClick={() => handleDigit('7')} classes={{ button: classes.button, disabled: classes.buttonDisabled }}>
                    7
                </Button>
                <Button text disabled={disabled} onClick={() => handleDigit('8')} classes={{ button: classes.button, disabled: classes.buttonDisabled }}>
                    8
                </Button>
                <Button text disabled={disabled} onClick={() => handleDigit('9')} classes={{ button: classes.button, disabled: classes.buttonDisabled }}>
                    9
                </Button>
                <Button text disabled={disabled} onClick={handleBackspace} classes={{ button: classes.button, disabled: classes.buttonDisabled }}>
                    <BackspaceIcon />
                </Button>
                <Button text disabled={disabled} onClick={() => handleDigit('0')} classes={{ button: classes.button, disabled: classes.buttonDisabled }}>
                    0
                </Button>
                <Button text disabled={disabled} onClick={() => handlePoint('.')} classes={{ button: classes.button, disabled: classes.buttonDisabled }}>
                    .
                </Button>
            </div>
        </div>
    );
}

const useStyles = makeStyles((theme) => ({
    container: {
        display: 'flex',
        width: '100%',
        height: '100%',
        flexDirection: 'column',
        alignItems: 'center',
        flexShrink: 0,
    },
    displayContainer: {
        width: '100%',
        display: 'flex',
    },
    numberText: {
        fontFamily: theme.typography.medium,
        fontSize: 42,
        color: '#2E3748',
        margin: 0,
        marginBottom: 12,
    },
    checkButton: {
        width: 48,
        height: 48,
        margin: 'auto',
    },
    currencyPad: {
        width: '100%',
        height: '100%',
        display: 'grid',
        gridTemplateColumns: 'repeat(3, minmax(50px, 1fr))',
        gridAutoRows: 'minmax(50px, 1fr)',
        gap: 10,
    },
    button: {
        fontSize: 18,
        width: '100%',
        textAlign: 'center',
        display: 'flex',
        justifyContent: 'center',
        alignContent: 'center',
        fontWeight: 'bold',
    },
    buttonDisabled: {
        backgroundColor: 'transparent !important',
        textDecoration: 'none',
    },
}));

type Props = {
    disabled?: boolean;
    onEnter: any;
    validation?: any;
};
