import type { PromoCodeVm } from 'src/api/letseatmanager/types/PromoCodeVm';
import { letseatmanagerApiMethod } from 'src/api/letseatmanagerApi';
import type { ApiSauceResponse } from 'src/api/types/ApiSauceResponse';
import type { DiscountType } from 'src/constants/DiscountType';
import type { OrderType } from 'src/constants/OrderType';
import type { PaymentMethod } from 'src/constants/PaymentMethod';
import type { PromoType } from 'src/constants/PromoType';
import type { RewardType } from 'src/constants/RewardType';
import type { CustomerId, MenuItemId, RestaurantId } from 'src/types/Id';

export async function createPromoCodeApi(request: CreatePromoCodeApiRequest): ApiSauceResponse<PromoCodeVm> {
    return letseatmanagerApiMethod('promoCode/createPromoCodeApi', request);
}

export type CreatePromoCodeApiRequest = {
    restaurantIds?: Array<RestaurantId>;
    customerIds?: Array<CustomerId>;
    restaurantId: RestaurantId;
    code: string;
    description?: string;
    promoType: PromoType;
    rewardType: RewardType;
    discount: string;
    excludeDeliveryCost?: boolean;
    freeDelivery?: boolean;
    appOnly?: boolean;
    webOnly?: boolean;
    discountType: DiscountType;
    orderTypes?: Array<OrderType>;
    paymentMethods?: Array<PaymentMethod>;
    menuItemIds?: Array<MenuItemId>;
    requireAtLeastOneCreditCard?: boolean;
    minOrderAmount?: string;
    maxDiscountAmount?: string;
    restaurantPaidPercentage?: string;
    maxUsage?: number;
    startsAt: Date;
    endsAt: Date;
};
