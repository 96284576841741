import * as React from 'react';
import { FormSelect } from 'src/components/form/FormSelect';
import type { Variant } from 'src/components/form/FormTypes';
import { TableSizes } from 'src/constants/TableSize';
import { translate } from 'src/i18n/translate';

export function FormTableSizeSelect({ name, label, placeholder, helperText, defaultValue, disabled, required, variant }: Props): React.ReactElement {
    const options = required
        ? Object.values(TableSizes).map((tableSize) => ({ label: translate(tableSize), value: tableSize }))
        : [{ label: '', value: null }, ...Object.values(TableSizes).map((tableSize) => ({ label: translate(tableSize), value: tableSize }))];
    return (
        <FormSelect
            name={name}
            label={label}
            placeholder={placeholder}
            helperText={helperText}
            defaultValue={defaultValue}
            options={options}
            disabled={disabled}
            required={required}
            variant={variant}
        />
    );
}

type Props = {
    name: string;
    label?: string;
    placeholder?: string;
    helperText?: string;
    defaultValue?: string | null | undefined;
    disabled?: boolean;
    required?:
        | boolean
        | {
              value: number;
              message: string;
          };
    variant?: Variant;
};
