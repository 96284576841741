import { Button } from '@pidedirecto/ui';
import { OutlinedPlusIcon } from '@pidedirecto/ui/icons';
import * as React from 'react';
import { useState } from 'react';
import { CreateOrderIncidentDialog } from 'src/components/dialog/order/CreateOrderIncidentDialog';
import { translate } from 'src/i18n/translate';

export function CreateOrderIncidentToolbarButton(): React.ReactElement {
    const [createOrderIncidentDialogState, setCreateOrderIncidentDialogState] = useState({ open: false });

    const openCreateOrderIncidentDialog = () => setCreateOrderIncidentDialogState({ open: true });

    const closeCreateOrderIncidentDialog = () => setCreateOrderIncidentDialogState({ open: false });

    return (
        <>
            <CreateOrderIncidentDialog open={createOrderIncidentDialogState.open} onClose={closeCreateOrderIncidentDialog} onSuccess={closeCreateOrderIncidentDialog} />
            <Button variant='secondary' onClick={openCreateOrderIncidentDialog}>
                <OutlinedPlusIcon title={translate('Register order with incident')} size={20} />
            </Button>
        </>
    );
}
