import { letseatmanagerApiMethod } from 'src/api/letseatmanagerApi';
import type { ApiSauceResponse } from 'src/api/types/ApiSauceResponse';
import type { CustomPaymentMethodIcon } from 'src/constants/CustomPaymentMethodIcons';
import { MexicanPaymentMethodCode } from 'src/constants/MexicanPaymentMethodCode';
import type { OrderType } from 'src/constants/OrderType';
import type { PaymentMethod } from 'src/constants/PaymentMethod';
import type { PaymentTerminalProvider } from 'src/constants/PaymentTerminalProvider';
import type { RestaurantPaymentMethodApp } from 'src/constants/RestaurantPaymentMethodApps';
import type { RestaurantPaymentMethodType } from 'src/constants/RestaurantPaymentMethodType';
import { RestaurantPsp } from 'src/constants/RestaurantPsp';
import { PlexoStoreId, type RestaurantPaymentMethodId } from 'src/types/Id';

export async function changeRestaurantPaymentMethodApi(request: ChangeRestaurantPaymentMethodApiRequest): ApiSauceResponse<void> {
    return letseatmanagerApiMethod('restaurantPaymentMethod/changeRestaurantPaymentMethodApi', request);
}

export type ChangeRestaurantPaymentMethodApiRequest = {
    restaurantPaymentMethodId: RestaurantPaymentMethodId;
    restaurantPaymentMethodType: RestaurantPaymentMethodType;
    paymentMethod: PaymentMethod;
    mexicanPaymentMethodCode?: MexicanPaymentMethodCode;
    paymentTerminalProvider?: PaymentTerminalProvider;
    mercadoPagoPublicKey?: string;
    mercadoPagoSecretKey?: string;
    plexoStoreId?: PlexoStoreId;
    psps?: Array<RestaurantPsp>;
    name: string;
    commission?: number;
    enabled?: boolean;
    paymentReferenceEnabled?: boolean;
    channels?: Array<RestaurantPaymentMethodApp>;
    orderTypes?: Array<OrderType>;
    icon?: CustomPaymentMethodIcon;
};
