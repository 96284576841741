import { makeStyles, useTheme } from '@material-ui/core';
import { BagIcon, CircleCrossIcon, IncomingIcon, MoneyBagIcon, MoneyIcon } from '@pidedirecto/ui/icons';
import { BigNumber } from 'bignumber.js';
import * as React from 'react';
import type { IntegrationsPaymentKpiReport } from 'src/api/letseatmanager/restaurantDashboard/getIntegrationsPaymentReportApi';
import { IntegrationPlatforms, type IntegrationPlatform } from 'src/constants/IntegrationPlatforms';
import { translate } from 'src/i18n/translate';
import { ReportIndicator } from 'src/scenes/letseatmanager/restaurantDashboard/ReportIndicator';
import { useFormatAsRestaurantCurrencyNumber } from 'src/services/restaurant/useFormatAsRestaurantCurrencyNumber';

export function IntegrationsReportKpis({ selectedIntegrationPlatforms, integrationsPaymentReportKpis }: Props): React.ReactElement | null {
    const classes = useStyles();
    const theme = useTheme();
    const formatAsCurrencyNumber = useFormatAsRestaurantCurrencyNumber();

    const getTotalOrders = () => {
        return BigNumber(
            BigNumber(selectedIntegrationPlatforms.includes(IntegrationPlatforms.UBER_EATS) ? (integrationsPaymentReportKpis.uberEatsCompleteOrders ?? 0) : 0)
                .plus(selectedIntegrationPlatforms.includes(IntegrationPlatforms.RAPPI) ? (integrationsPaymentReportKpis.rappiCompleteOrders ?? 0) : 0)
                .plus(selectedIntegrationPlatforms.includes(IntegrationPlatforms.DIDI_FOOD) ? (integrationsPaymentReportKpis.didiFoodCompleteOrders ?? 0) : 0)
                .plus(selectedIntegrationPlatforms.includes(IntegrationPlatforms.PEDIDOS_YA) ? (integrationsPaymentReportKpis.pedidosYaCompleteOrders ?? 0) : 0),
        ).toNumber();
    };

    const getCancelledOrders = () => {
        return BigNumber(
            BigNumber(selectedIntegrationPlatforms.includes(IntegrationPlatforms.UBER_EATS) ? (integrationsPaymentReportKpis.uberEatsCancelled ?? 0) : 0)
                .plus(selectedIntegrationPlatforms.includes(IntegrationPlatforms.RAPPI) ? (integrationsPaymentReportKpis.rappiCancelled ?? 0) : 0)
                .plus(selectedIntegrationPlatforms.includes(IntegrationPlatforms.DIDI_FOOD) ? (integrationsPaymentReportKpis.didiFoodCancelled ?? 0) : 0)
                .plus(selectedIntegrationPlatforms.includes(IntegrationPlatforms.PEDIDOS_YA) ? (integrationsPaymentReportKpis.pedidosYaCancelled ?? 0) : 0),
        ).toNumber();
    };

    const getTotalSales = () => {
        return formatAsCurrencyNumber(
            BigNumber(
                BigNumber(selectedIntegrationPlatforms.includes(IntegrationPlatforms.UBER_EATS) ? (integrationsPaymentReportKpis.uberEatsSales ?? 0) : 0)
                    .plus(selectedIntegrationPlatforms.includes(IntegrationPlatforms.RAPPI) ? (integrationsPaymentReportKpis.rappiSales ?? 0) : 0)
                    .plus(selectedIntegrationPlatforms.includes(IntegrationPlatforms.DIDI_FOOD) ? (integrationsPaymentReportKpis.didiFoodSales ?? 0) : 0)
                    .plus(selectedIntegrationPlatforms.includes(IntegrationPlatforms.PEDIDOS_YA) ? (integrationsPaymentReportKpis.pedidosYaSales ?? 0) : 0),
            ).toNumber(),
        );
    };

    const getTotalCommissions = () => {
        return formatAsCurrencyNumber(
            BigNumber(
                BigNumber(selectedIntegrationPlatforms.includes(IntegrationPlatforms.UBER_EATS) ? (integrationsPaymentReportKpis.totalCommissionUberEats ?? 0) : 0)
                    .plus(selectedIntegrationPlatforms.includes(IntegrationPlatforms.RAPPI) ? (integrationsPaymentReportKpis.totalCommissionRappi ?? 0) : 0)
                    .plus(selectedIntegrationPlatforms.includes(IntegrationPlatforms.DIDI_FOOD) ? (integrationsPaymentReportKpis.totalCommissionDidiFood ?? 0) : 0)
                    .plus(selectedIntegrationPlatforms.includes(IntegrationPlatforms.PEDIDOS_YA) ? (integrationsPaymentReportKpis.totalCommissionPedidosYa ?? 0) : 0),
            ).toNumber(),
        );
    };

    const getTotalExpected = () => {
        return formatAsCurrencyNumber(
            BigNumber(
                BigNumber(selectedIntegrationPlatforms.includes(IntegrationPlatforms.UBER_EATS) ? (integrationsPaymentReportKpis.uberEatsSalesWithoutCommission ?? 0) : 0)
                    .plus(selectedIntegrationPlatforms.includes(IntegrationPlatforms.RAPPI) ? (integrationsPaymentReportKpis.rappiSalesWithoutCommission ?? 0) : 0)
                    .plus(selectedIntegrationPlatforms.includes(IntegrationPlatforms.DIDI_FOOD) ? (integrationsPaymentReportKpis.didiFoodSalesWithoutCommission ?? 0) : 0)
                    .plus(selectedIntegrationPlatforms.includes(IntegrationPlatforms.PEDIDOS_YA) ? (integrationsPaymentReportKpis.pedidosYaSalesWithoutCommission ?? 0) : 0),
            ).toNumber(),
        );
    };

    if (!selectedIntegrationPlatforms.length) return null;

    return (
        <div className={classes.kpiSection}>
            <ReportIndicator
                label={translate('Sales')}
                value={getTotalSales()}
                Icon={
                    <div className={classes.iconContainer} style={{ backgroundColor: theme.palette.surface.info }}>
                        <MoneyIcon color={'#0899EA'} title={translate('Sales')} />
                    </div>
                }
            />
            <ReportIndicator
                label={translate('Commissions')}
                value={getTotalCommissions()}
                Icon={
                    <div className={classes.iconContainer} style={{ backgroundColor: theme.palette.surface.success }}>
                        <IncomingIcon title={translate('Commissions')} color={theme.palette.text.success} size={40} />
                    </div>
                }
            />
            <ReportIndicator
                label={translate('Expected')}
                value={getTotalExpected()}
                Icon={
                    <div className={classes.iconContainer} style={{ backgroundColor: theme.palette.surface.warning }}>
                        <MoneyBagIcon title={translate('Expected')} color={theme.palette.text.warning} size={20} />
                    </div>
                }
            />
            <ReportIndicator
                label={translate('Orders')}
                value={getTotalOrders()}
                Icon={
                    <div className={classes.iconContainer} style={{ backgroundColor: theme.palette.surface.brand }}>
                        <BagIcon title={translate('Orders')} color={theme.palette.icons.brand} />
                    </div>
                }
            />
            <ReportIndicator
                label={translate('Cancelled orders')}
                value={getCancelledOrders()}
                Icon={
                    <div className={classes.iconContainer} style={{ backgroundColor: theme.palette.surface.danger }}>
                        <CircleCrossIcon title={translate('Cancelled orders')} color={theme.palette.text.danger} />
                    </div>
                }
            />
        </div>
    );
}

const useStyles = makeStyles((theme) => ({
    kpiSection: {
        width: '100%',
        display: 'grid',
        gridTemplateColumns: 'repeat(auto-fit, minmax(150px, 1fr))',
        gap: 12,
        margin: '24px 10px 24px 0px',
    },
    iconContainer: {
        width: 40,
        height: 40,
        borderRadius: '50%',
        display: 'grid',
        placeItems: 'center',
    },
}));

type Props = {
    selectedIntegrationPlatforms: Array<IntegrationPlatform>;
    integrationsPaymentReportKpis: IntegrationsPaymentKpiReport;
};
