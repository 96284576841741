import { makeStyles } from '@material-ui/core/styles';
import { useEffect, useState } from 'react';
import * as React from 'react';
import { getServerVersionApi, GetServerVersionApiResponse } from 'src/api/letseatmanager/getServerVersionApi';
import { APP_VERSION } from 'src/config/appVersion';
import { envBRANCH_NAME } from 'src/env/envBRANCH_NAME';
import { envBUILD_TIME } from 'src/env/envBUILD_TIME';
import { envCOMMIT_AUTHOR_EMAIL } from 'src/env/envCOMMIT_AUTHOR_EMAIL';
import { envCOMMIT_AUTHOR_NAME } from 'src/env/envCOMMIT_AUTHOR_NAME';
import { envCOMMIT_HASH } from 'src/env/envCOMMIT_HASH';
import { translate } from 'src/i18n/translate';
import { createAdminGitHubBranchUrl } from 'src/utils/github/createAdminGitHubBranchUrl';
import { createAdminGitHubCommitUrl } from 'src/utils/github/createAdminGitHubCommitUrl';
import { createServerGitHubBranchUrl } from 'src/utils/github/createServerGitHubBranchUrl';
import { createServerGitHubCommitUrl } from 'src/utils/github/createServerGitHubCommitUrl';
import { ellipsisMiddle } from 'src/utils/string/ellipsisMiddle';

export function AppFullVersionInfo({ onClickVersion }: Props): React.ReactElement {
    const classes = useStyles();

    const [serverVersion, setServerVersion] = useState<GetServerVersionApiResponse>();

    useEffect(() => {
        getServerVersion();
    }, []);

    const getServerVersion = async () => {
        const response = await getServerVersionApi();
        if (!response.ok) {
            console.log(`getServerVersionApi error response=`, response);
            return;
        }
        setServerVersion(response.data);
    };

    return (
        <div className={classes.version}>
            <section>
                <div className={classes.header}>{translate('Admin Info')}</div>
                {!!envBRANCH_NAME() && (
                    <div>
                        <a href={createAdminGitHubBranchUrl(envBRANCH_NAME())} target='_blank' className={classes.text}>
                            {ellipsisMiddle(envBRANCH_NAME(), 26, 0)}
                        </a>
                    </div>
                )}
                {!!envCOMMIT_HASH() && (
                    <div>
                        <a href={createAdminGitHubCommitUrl(envCOMMIT_HASH())} target='_blank' className={classes.text}>
                            {envCOMMIT_HASH()?.substring(0, 9)}
                        </a>
                    </div>
                )}
                <div>{envCOMMIT_AUTHOR_NAME()}</div>
                <div>{envCOMMIT_AUTHOR_EMAIL()}</div>
                <div onClick={() => onClickVersion()}>
                    {APP_VERSION}@{envBUILD_TIME()}
                </div>
            </section>
            <section>
                <div className={classes.header}>{translate('Server Info')}</div>
                {!!serverVersion?.BRANCH_NAME && (
                    <div>
                        <a href={createServerGitHubBranchUrl(serverVersion.BRANCH_NAME)} target='_blank' className={classes.text}>
                            {ellipsisMiddle(serverVersion.BRANCH_NAME, 26, 0)}
                        </a>
                    </div>
                )}
                {!!serverVersion?.COMMIT_HASH && (
                    <div>
                        <a href={createServerGitHubCommitUrl(serverVersion.COMMIT_HASH)} target='_blank' className={classes.text}>
                            {serverVersion.COMMIT_HASH.substring(0, 20)}
                        </a>
                    </div>
                )}
                <div>{serverVersion?.COMMIT_AUTHOR_NAME}</div>
                <div>{serverVersion?.COMMIT_AUTHOR_EMAIL}</div>
                <div onClick={() => onClickVersion()}>
                    {serverVersion?.APP_VERSION}@{serverVersion?.BUILD_TIME}
                </div>
            </section>
        </div>
    );
}

const useStyles = makeStyles((theme) => ({
    version: {
        fontSize: '12px',
        position: 'fixed',
        bottom: 24,
        left: 8,
        textAlign: 'left',
        color: 'rgba(0, 0, 0)',
    },
    header: {
        paddingTop: 8,
        fontFamily: theme.typography.medium,
        color: 'black',
    },
    text: {
        textDecoration: 'underline',
        color: 'black',
    },
}));

type Props = {
    onClickVersion: any;
};
