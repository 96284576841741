import { CustomerDisplayScreenOrderSteps } from 'src/constants/CustomerDisplayScreenOrderStep';
import { posReducer } from 'src/reducers/posReducer';
import { OrderPaymentVm } from 'src/types/OrderPaymentVm';
import type { OrderVm } from 'src/types/OrderVm';
import { PosPayment } from 'src/types/PosPayment';
import { PosTip } from 'src/types/PosTip';
import { PosTipVm } from 'src/types/PosTipVm';
import { isCardPayment } from 'src/utils/paymentMethod/isCardPayment';
import { useAction } from 'src/utils/react/useAction';
import { newUuid } from 'src/utils/uuid/newUuid';

export function useEditPendingOrder(): (arg1: { order: OrderVm }) => void {
    const editPendingOrder = useAction(posReducer.actions.editPendingOrder);
    const setPayments = useAction(posReducer.actions.setPayments);
    const setCustomerDisplayScreenOrderStep = useAction(posReducer.actions.setCustomerDisplayScreenOrderStep);

    const openPendingOrder = ({ order }: { order: OrderVm }) => {
        const payments: Array<PosPayment> = order.payments?.map((payment: OrderPaymentVm) => ({ ...payment, id: newUuid() as any })) ?? [];
        setPayments(payments);

        const hasSomeCustomerCardTipPayment =
            order.customers?.length && payments.some((payment) => !!payment.customerNumber && payment.isTipPayment && isCardPayment(payment.paymentMethod) && !payment.customPaymentMethod);

        if (!hasSomeCustomerCardTipPayment) {
            editPendingOrder({ order });
            setCustomerDisplayScreenOrderStep(CustomerDisplayScreenOrderSteps.ORDER_SUMMARY);
            return;
        }

        const tips = getMappedTips(order.tips ?? [], payments);
        editPendingOrder({ order: { ...order, tips } });
        setCustomerDisplayScreenOrderStep(CustomerDisplayScreenOrderSteps.ORDER_SUMMARY);
    };

    const getMappedTips = (orderTips: Array<PosTipVm>, payments: Array<PosPayment>): Array<PosTip | PosTipVm> => {
        const tips: Array<PosTip | PosTipVm> = orderTips?.map((tip) => {
            const shouldLinkPaymentToTip = tip.customerNumber && isCardPayment(tip.paymentMethod) && !tip.customPaymentMethod;
            if (!shouldLinkPaymentToTip) {
                return tip;
            }
            const payment = payments.find((payment) => payment.customerNumber === tip.customerNumber && payment.isTipPayment && isCardPayment(payment.paymentMethod) && !payment.customPaymentMethod);
            if (!payment) {
                return tip;
            }
            return {
                ...tip,
                paymentId: payment.id as any,
            } as PosTip;
        });

        return tips;
    };

    return openPendingOrder;
}
