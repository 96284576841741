import { makeStyles, useTheme } from '@material-ui/core';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import moment from 'moment';
import { useState } from 'react';
import * as React from 'react';
import { Accordion } from 'src/components/Accordion';
import { Channel } from 'src/components/Channel';
import { OrderDetailsDialog } from 'src/components/orderCard/OrderDetailsDialog';
import { SimplifiedOrderCard } from 'src/components/orderCard/SimplifiedOrderCard';
import { SimpleTable } from 'src/components/SimpleTable';
import { Apps } from 'src/constants/App';
import { ExternalDeliveryProvider } from 'src/constants/ExternalDeliveryProviders';
import { OrderStatus, OrderStatuses } from 'src/constants/OrderStatus';
import { OrderType } from 'src/constants/OrderType';
import { translate } from 'src/i18n/translate';
import { useOrderPageIsTableView } from 'src/modules/order/useOrderPageIsTableView';
import { useFormatAsRestaurantCurrencyNumber } from 'src/services/restaurant/useFormatAsRestaurantCurrencyNumber';
import type { OrderVm } from 'src/types/OrderVm';
import { formatDateTimeString } from 'src/utils/date/formatDateTimeString';
import { getBaseOrderCost } from 'src/utils/order/getBaseOrderCost';
import { isAcceptedOrder } from 'src/utils/order/isAcceptedOrder';
import { isCancelledOrder } from 'src/utils/order/isCancelledOrder';
import { isClosedOrder } from 'src/utils/order/isClosedOrder';
import { isCompletedOrder } from 'src/utils/order/isCompletedOrder';
import { isDeliveryOrder } from 'src/utils/order/isDeliveryOrder';
import { isEatHereOrder } from 'src/utils/order/isEatHereOrder';
import { isNewOrder } from 'src/utils/order/isNewOrder';
import { isPendingOrder } from 'src/utils/order/isPendingOrder';
import { isRappiCargoExternalDeliveryProvider } from 'src/utils/order/isRappiCargoExternalDeliveryProvider';
import { isRejectedOrder } from 'src/utils/order/isRejectedOrder';
import { isReturnedOrder } from 'src/utils/order/isReturnedOrder';
import { isRoomServiceOrder } from 'src/utils/order/isRoomServiceOrder';
import { isTakeAwayOrder } from 'src/utils/order/isTakeAwayOrder';
import { isUberDaasExternalDeliveryProvider } from 'src/utils/order/isUberDaasExternalDeliveryProvider';
import { isUberEatsExternalDeliveryProvider } from 'src/utils/order/isUberEatsExternalDeliveryProvider';
import { orderIsInternalDeliveryOrder } from 'src/utils/order/orderIsInternalDeliveryOrder';
import { toHumanizedOrderId } from 'src/utils/order/toHumanizedOrderId';
import { classNames } from 'src/utils/react/classNames';
import { useSelector } from 'src/utils/react/useSelector';
import { translateOrderStatus } from 'src/utils/translate/translateOrderStatus';

export function AllOrders({ orders, orderStatuses, onChangeOrder }: Props): React.ReactElement {
    const classes = useStyles();
    const theme = useTheme();
    const formatAsCurrencyNumber = useFormatAsRestaurantCurrencyNumber();
    const isBelowSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

    const [orderDetailsDialogState, setOrderDetailsDialogState] = useState<{ open: boolean; order: OrderVm | undefined }>({ open: false, order: undefined });

    const markReadyForPickupButtonEnabled = useSelector((state) => state.app.restaurant.markReadyForPickupButtonEnabled);
    const externalDelivery = useSelector((state) => state.app.restaurant.externalDelivery);
    const internalUser = useSelector((state) => state.authentication.internalUser);

    const ongoingOrders = orders.filter((order) => isAcceptedOrder(order.orderStatus) || isPendingOrder(order.orderStatus) || isClosedOrder(order.orderStatus));
    const completedOrders = orders.filter((order) => isCompletedOrder(order.orderStatus));
    const rejectedOrders = orders.filter((order) => isRejectedOrder(order.orderStatus));
    const cancelledOrders = orders.filter((order) => isCancelledOrder(order.orderStatus));
    const returnedOrders = orders.filter((order) => isReturnedOrder(order.orderStatus));
    const newOrders = orders.filter((order) => isNewOrder(order.orderStatus));
    const renderOngoingOrders = orderStatuses.includes(OrderStatuses.ACCEPTED);
    const renderCompletedOrders = orderStatuses.includes(OrderStatuses.COMPLETE);
    const renderRejectedOrders = orderStatuses.includes(OrderStatuses.REJECTED);
    const renderCancelledOrders = orderStatuses.includes(OrderStatuses.CANCELLED);
    const renderReturnedOrders = orderStatuses.includes(OrderStatuses.RETURNED);
    const isTableView = useOrderPageIsTableView();
    const renderTableView = !isBelowSmallScreen && isTableView;

    const openOrderDetailsDialog = (order: OrderVm) => setOrderDetailsDialogState({ open: true, order });

    const closeOrderDetailsDialog = () => setOrderDetailsDialogState({ open: false, order: undefined });

    const getOrderTypeColor = (orderType: OrderType) => {
        if (isDeliveryOrder(orderType)) return '#133547';
        if (isTakeAwayOrder(orderType)) return '#0D7084';
        if (isRoomServiceOrder(orderType)) return '#C9E2FF';
        return '#1A56AF';
    };

    const getExternalDeliveryProviderColor = (externalDeliveryProvider: ExternalDeliveryProvider | undefined) => {
        if (isUberDaasExternalDeliveryProvider(externalDeliveryProvider)) return '#1F1F1F';
        if (isUberEatsExternalDeliveryProvider(externalDeliveryProvider)) return '#08C26880';
        if (isRappiCargoExternalDeliveryProvider(externalDeliveryProvider)) return '#FF787866';
        return '#63D6D666';
    };

    const getExternalDeliveryProviderFontColor = (externalDeliveryProvider: ExternalDeliveryProvider | undefined) => {
        if (isUberDaasExternalDeliveryProvider(externalDeliveryProvider)) return '#ffffff';
        if (isUberEatsExternalDeliveryProvider(externalDeliveryProvider)) return '#ffffff';
        if (isRappiCargoExternalDeliveryProvider(externalDeliveryProvider)) return '#DB2E2E';
        return '#074F5F';
    };

    const getOrderStatusDescription = (order: OrderVm) => {
        if (isDeliveryOrder(order.orderType) && !order.deliveryStatus) return translate('Looking for driver');
        if (order.arrivedAtClientAt) return translate(`OrderStatuses.${OrderStatuses.ARRIVED_AT_CLIENT}`);
        if ((orderIsInternalDeliveryOrder(order) || externalDelivery) && order.deliveryStatus) return translate(`DeliveryStatuses.${order.deliveryStatus}`);
        if (isEatHereOrder(order.orderType) && order.markedReadyForPickupAt) return translate(`OrderStatuses.${OrderStatuses.PENDING}`);
        if (isEatHereOrder(order.orderType) && !order.markedReadyForPickupAt) return translate(`OrderStatuses.${OrderStatuses.ACCEPTED}`);
        if (isTakeAwayOrder(order.orderType) && !order.markedReadyForPickupAt) return translate(`OrderStatuses.${OrderStatuses.ACCEPTED}`);
        if (isRoomServiceOrder(order.orderType) && !order.markedReadyForPickupAt) return translateOrderStatus(OrderStatuses.NEW, order.orderType);
        if ((!orderIsInternalDeliveryOrder(order) && order.markedReadyForPickupAt) || !markReadyForPickupButtonEnabled) return translateOrderStatus(OrderStatuses.COMPLETED, order.orderType);
        return translate('Mark order ready');
    };

    const orderIsPickedUp = (order: OrderVm) => {
        const orderIsArrivedAtClient = moment(order.arrivedAtClientAt).add(5, 'minutes').isBefore(moment());
        const orderIsPickedUp = orderIsArrivedAtClient && !isCompletedOrder(order.orderStatus) && !isRejectedOrder(order.orderStatus) && !isCancelledOrder(order.orderStatus);

        return orderIsPickedUp;
    };

    const columns = [
        {
            id: 'app',
            content: translate('Channel'),
            size: 0.5,
        },
        {
            id: 'id',
            content: translate('Id'),
            size: 0.7,
        },
        {
            id: 'customerName',
            content: translate('Customer'),
        },
        {
            id: 'orderType',
            content: translate('Order type'),
            size: 0.8,
        },
        {
            id: 'orderStatus',
            content: translate('Status'),
        },
        {
            id: 'createdAt',
            content: translate('Time'),
        },
        {
            id: 'paymentMethod',
            content: translate('Payment method'),
            size: 0.8,
        },
        {
            id: 'price',
            content: translate('Price'),
            size: 0.4,
        },
    ];

    return (
        <div className={classes.container}>
            {!!orderDetailsDialogState.order && (
                <OrderDetailsDialog
                    open={orderDetailsDialogState.open}
                    order={orderDetailsDialogState.order}
                    onClose={closeOrderDetailsDialog}
                    onChange={onChangeOrder}
                    classes={{ container: classes.orderCardAlerted }}
                />
            )}
            {!!newOrders.length && (
                <Accordion title={translate('New (@orders)', { orders: newOrders.length })} defaultOpened>
                    {renderTableView && (
                        <div className={classes.tableContainer}>
                            <SimpleTable
                                classes={{ cell: classes.tableCell }}
                                columns={columns}
                                rows={newOrders.map((order) => ({
                                    className: orderIsPickedUp(order) ? classes.orderCardAlerted : '',
                                    app: <Channel channel={order.app || Apps.PIDEDIRECTO} size={35} disabled />,
                                    id: (
                                        <span className={classes.link} onClick={() => openOrderDetailsDialog(order)}>
                                            #{toHumanizedOrderId(order.orderId)}
                                        </span>
                                    ),
                                    customerName: order.customerName,
                                    orderType: (
                                        <span
                                            className={classNames(classes.orderType, {
                                                [classes.roomServiceText]: isRoomServiceOrder(order.orderType),
                                            })}
                                            style={{ backgroundColor: getOrderTypeColor(order.orderType) }}
                                        >
                                            {isRoomServiceOrder(order.orderType) ? translate('Room service') : translate(`OrderTypes.${order.orderType}`)}
                                        </span>
                                    ),
                                    orderStatus: getOrderStatusDescription(order),
                                    createdAt: formatDateTimeString(order.createdAt, order.timeZone),
                                    paymentMethod: order.paymentMethod ? (order.payments?.length === 1 ? translate(order.paymentMethod) : translate('Multiple payments')) : '',
                                    price: formatAsCurrencyNumber(getBaseOrderCost(order)),
                                }))}
                            />
                        </div>
                    )}
                    {!renderTableView && (
                        <div className={classes.ordersContainer}>
                            {newOrders.map((order) => {
                                return (
                                    <SimplifiedOrderCard
                                        key={order.orderId}
                                        order={order}
                                        onClick={() => openOrderDetailsDialog(order)}
                                        classes={{ container: orderIsPickedUp(order) ? classes.containerOrderCardAlerted : undefined }}
                                    />
                                );
                            })}
                        </div>
                    )}
                </Accordion>
            )}
            {renderOngoingOrders && (
                <Accordion title={translate('Ongoing (@orders)', { orders: ongoingOrders.length })} defaultOpened>
                    {renderTableView && (
                        <div className={classes.tableContainer}>
                            <SimpleTable
                                classes={{ cell: classes.tableCell }}
                                columns={columns}
                                rows={ongoingOrders.map((order) => ({
                                    className: orderIsPickedUp(order) ? classes.orderCardAlerted : '',
                                    app: <Channel channel={order.app || Apps.PIDEDIRECTO} size={35} disabled />,
                                    id: (
                                        <span className={classes.link} onClick={() => openOrderDetailsDialog(order)}>
                                            #{toHumanizedOrderId(order.orderId)}
                                        </span>
                                    ),
                                    customerName: order.customerName,
                                    orderType: (
                                        <div className={classes.externalDeliveryProviderContainer}>
                                            <span
                                                className={classNames(classes.orderType, {
                                                    [classes.roomServiceText]: isRoomServiceOrder(order.orderType),
                                                })}
                                                style={{ backgroundColor: getOrderTypeColor(order.orderType) }}
                                            >
                                                {translate(`OrderTypes.${order.orderType}`)}
                                            </span>
                                            {isDeliveryOrder(order.orderType) && internalUser && (
                                                <div
                                                    className={classes.orderType}
                                                    style={{
                                                        backgroundColor: getExternalDeliveryProviderColor(order.externalDeliveryProvider),
                                                        color: getExternalDeliveryProviderFontColor(order.externalDeliveryProvider),
                                                    }}
                                                >
                                                    {order.externalDeliveryProvider && translate(order.externalDeliveryProvider)}
                                                    {!order.externalDeliveryProvider && translate('Apps.PIDEDIRECTO')}
                                                </div>
                                            )}
                                        </div>
                                    ),
                                    orderStatus: getOrderStatusDescription(order),
                                    createdAt: formatDateTimeString(order.createdAt, order.timeZone),
                                    paymentMethod: order.paymentMethod ? (order.payments?.length === 1 ? translate(order.paymentMethod) : translate('Multiple payments')) : '',
                                    price: formatAsCurrencyNumber(getBaseOrderCost(order)),
                                }))}
                            />
                        </div>
                    )}
                    {!renderTableView && (
                        <div className={classes.ordersContainer}>
                            {ongoingOrders.map((order) => {
                                return (
                                    <SimplifiedOrderCard
                                        key={order.orderId}
                                        order={order}
                                        onClick={() => openOrderDetailsDialog(order)}
                                        classes={{ container: orderIsPickedUp(order) ? classes.containerOrderCardAlerted : undefined }}
                                    />
                                );
                            })}
                        </div>
                    )}
                </Accordion>
            )}
            {renderCompletedOrders && (
                <Accordion title={translate('Completed (@orders)', { orders: completedOrders.length })} defaultOpened>
                    {renderTableView && (
                        <div className={classes.tableContainer}>
                            <SimpleTable
                                classes={{ cell: classes.tableCell }}
                                columns={columns}
                                rows={completedOrders.map((order) => ({
                                    app: <Channel channel={order.app || Apps.PIDEDIRECTO} size={35} disabled />,
                                    id: (
                                        <span className={classes.link} onClick={() => openOrderDetailsDialog(order)}>
                                            #{toHumanizedOrderId(order.orderId)}
                                        </span>
                                    ),
                                    customerName: order.customerName,
                                    orderType: (
                                        <div className={classes.externalDeliveryProviderContainer}>
                                            <span
                                                className={classNames(classes.orderType, {
                                                    [classes.roomServiceText]: isRoomServiceOrder(order.orderType),
                                                })}
                                                style={{ backgroundColor: getOrderTypeColor(order.orderType) }}
                                            >
                                                {translate(`OrderTypes.${order.orderType}`)}
                                            </span>
                                            {isDeliveryOrder(order.orderType) && internalUser && (
                                                <div
                                                    className={classes.orderType}
                                                    style={{
                                                        backgroundColor: getExternalDeliveryProviderColor(order.externalDeliveryProvider),
                                                        color: getExternalDeliveryProviderFontColor(order.externalDeliveryProvider),
                                                    }}
                                                >
                                                    {order.externalDeliveryProvider && translate(order.externalDeliveryProvider)}
                                                    {!order.externalDeliveryProvider && translate('Apps.PIDEDIRECTO')}
                                                </div>
                                            )}
                                        </div>
                                    ),
                                    orderStatus: <span style={{ color: '#06C977' }}>{translate('Completed order')}</span>,
                                    createdAt: formatDateTimeString(order.createdAt, order.timeZone),
                                    paymentMethod: order.paymentMethod ? (order.payments?.length === 1 ? translate(order.paymentMethod) : translate('Multiple payments')) : '',
                                    price: formatAsCurrencyNumber(getBaseOrderCost(order)),
                                }))}
                            />
                        </div>
                    )}
                    {!renderTableView && (
                        <div className={classes.ordersContainer}>
                            {completedOrders.map((order) => (
                                <SimplifiedOrderCard key={order.orderId} order={order} onClick={() => openOrderDetailsDialog(order)} />
                            ))}
                        </div>
                    )}
                </Accordion>
            )}
            {renderRejectedOrders && (
                <Accordion title={translate('Rejected (@orders)', { orders: rejectedOrders.length })} defaultOpened>
                    {renderTableView && (
                        <div className={classes.tableContainer}>
                            <SimpleTable
                                classes={{ cell: classes.tableCell }}
                                columns={columns}
                                rows={rejectedOrders.map((order) => ({
                                    app: <Channel channel={order.app || Apps.PIDEDIRECTO} size={35} disabled />,
                                    id: (
                                        <span className={classes.link} onClick={() => openOrderDetailsDialog(order)}>
                                            #{toHumanizedOrderId(order.orderId)}
                                        </span>
                                    ),
                                    customerName: order.customerName,
                                    orderType: (
                                        <span
                                            className={classNames(classes.orderType, {
                                                [classes.roomServiceText]: isRoomServiceOrder(order.orderType),
                                            })}
                                            style={{ backgroundColor: getOrderTypeColor(order.orderType) }}
                                        >
                                            {translate(`OrderTypes.${order.orderType}`)}
                                        </span>
                                    ),
                                    orderStatus: translate('Rejected order'),
                                    createdAt: formatDateTimeString(order.createdAt, order.timeZone),
                                    paymentMethod: order.paymentMethod ? (order.payments?.length === 1 ? translate(order.paymentMethod) : translate('Multiple payments')) : '',
                                    price: formatAsCurrencyNumber(getBaseOrderCost(order)),
                                }))}
                            />
                        </div>
                    )}
                    {!renderTableView && (
                        <div className={classes.ordersContainer}>
                            {rejectedOrders.map((order) => (
                                <SimplifiedOrderCard key={order.orderId} order={order} onClick={() => openOrderDetailsDialog(order)} />
                            ))}
                        </div>
                    )}
                </Accordion>
            )}
            {renderCancelledOrders && (
                <Accordion title={translate('Cancelled (@orders)', { orders: cancelledOrders.length })} defaultOpened>
                    {renderTableView && (
                        <div className={classes.tableContainer}>
                            <SimpleTable
                                classes={{ cell: classes.tableCell }}
                                columns={columns}
                                rows={cancelledOrders.map((order) => ({
                                    app: <Channel channel={order.app || Apps.PIDEDIRECTO} size={35} disabled />,
                                    id: (
                                        <span className={classes.link} onClick={() => openOrderDetailsDialog(order)}>
                                            #{toHumanizedOrderId(order.orderId)}
                                        </span>
                                    ),
                                    customerName: order.customerName,
                                    orderType: (
                                        <span
                                            className={classNames(classes.orderType, {
                                                [classes.roomServiceText]: isRoomServiceOrder(order.orderType),
                                            })}
                                            style={{ backgroundColor: getOrderTypeColor(order.orderType) }}
                                        >
                                            {translate(`OrderTypes.${order.orderType}`)}
                                        </span>
                                    ),
                                    orderStatus: translate('Cancelled order'),
                                    createdAt: formatDateTimeString(order.createdAt, order.timeZone),
                                    paymentMethod: order.paymentMethod ? (order.payments?.length === 1 ? translate(order.paymentMethod) : translate('Multiple payments')) : '',
                                    price: formatAsCurrencyNumber(getBaseOrderCost(order)),
                                }))}
                            />
                        </div>
                    )}
                    {!renderTableView && (
                        <div className={classes.ordersContainer}>
                            {cancelledOrders.map((order) => (
                                <SimplifiedOrderCard key={order.orderId} order={order} onClick={() => openOrderDetailsDialog(order)} />
                            ))}
                        </div>
                    )}
                </Accordion>
            )}
            {renderReturnedOrders && (
                <Accordion title={translate('Returned (@orders)', { orders: returnedOrders.length })} defaultOpened>
                    {renderTableView && (
                        <div className={classes.tableContainer}>
                            <SimpleTable
                                classes={{ cell: classes.tableCell }}
                                columns={columns}
                                rows={returnedOrders.map((order) => ({
                                    app: <Channel channel={order.app || Apps.PIDEDIRECTO} size={35} disabled />,
                                    id: (
                                        <span className={classes.link} onClick={() => openOrderDetailsDialog(order)}>
                                            #{toHumanizedOrderId(order.orderId)}
                                        </span>
                                    ),
                                    customerName: order.customerName,
                                    orderType: (
                                        <span className={classes.orderType} style={{ backgroundColor: getOrderTypeColor(order.orderType) }}>
                                            {translate(`OrderTypes.${order.orderType}`)}
                                        </span>
                                    ),
                                    orderStatus: translate('Returned order'),
                                    createdAt: formatDateTimeString(order.createdAt, order.timeZone),
                                    paymentMethod: order.paymentMethod ? (order.payments?.length === 1 ? translate(order.paymentMethod) : translate('Multiple payments')) : '',
                                    price: formatAsCurrencyNumber(getBaseOrderCost(order)),
                                }))}
                            />
                        </div>
                    )}
                    {!renderTableView && (
                        <div className={classes.ordersContainer}>
                            {returnedOrders.map((order) => (
                                <SimplifiedOrderCard key={order.orderId} order={order} onClick={() => openOrderDetailsDialog(order)} />
                            ))}
                        </div>
                    )}
                </Accordion>
            )}
        </div>
    );
}

const useStyles = makeStyles((theme) => ({
    container: {
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        gap: 20,
        paddingBottom: 30,
        overflowX: 'visible',
    },
    tableContainer: {
        width: '100%',
        marginTop: 20,
        [theme.breakpoints.down('sm')]: {
            display: 'none',
        },
    },
    ordersContainer: {
        width: '100%',
        marginTop: 20,
        marginLeft: 20,
        display: 'grid',
        gap: 20,
        gridTemplateColumns: 'repeat(auto-fill, 330px)',
        [theme.breakpoints.down('sm')]: {
            marginLeft: 0,
        },
    },
    link: {
        color: theme.palette.text.brand,
        fontFamily: theme.typography.regular,
        fontSize: 16,
        textDecoration: 'underline',
        cursor: 'pointer',
    },
    tableCell: {
        alignItems: 'center',
    },
    orderType: {
        color: 'white',
        padding: '4px 12px',
        borderRadius: 4,
    },
    containerOrderCardAlerted: {
        animation: `$vibration 1s infinite`,
    },
    orderCardAlerted: {
        backgroundColor: 'red',
        animation: `$colorized 1s infinite`,
    },
    roomServiceText: {
        color: '#133547',
    },
    externalDeliveryProviderContainer: {
        width: '100%',
        display: 'flex',
        flexDirection: 'row',
        gap: 4,
    },
    '@keyframes vibration': {
        '0%': {
            transform: 'scale(1)',
        },
        '50%': {
            transform: 'scale(1.05)',
        },
        '100%': {
            transform: 'scale(1)',
        },
    },
    '@keyframes colorized': {
        '0%': {
            backgroundColor: ' transparent',
        },
        '50%': {
            backgroundColor: ' #ffbcc1',
        },
        '100%': {
            backgroundColor: 'transparent',
        },
    },
}));

type Props = {
    orders: Array<OrderVm>;
    orderStatuses: Array<OrderStatus>;
    onChangeOrder?: any;
};
