import { makeStyles } from '@material-ui/core/styles';
import MUIDataTable from 'mui-datatables';
import * as React from 'react';
import { useRef } from 'react';
import type { RestaurantSurveyVm } from 'src/api/letseatadmin/survey/getSurveysApi';
import { UpdatingContentProgress } from 'src/components/UpdatingContentProgress';
import { translate } from 'src/i18n/translate';
import { createFilteredOnDownloadFunctional } from 'src/utils/mui-datatables/createFilteredOnDownloadFunctional';
import 'src/scenes/letseatadmin/surveys/RatingDetails.css';

type Props = {
    loading?: boolean;
    title?: string;
    restaurantSurveys: Array<RestaurantSurveyVm>;
    onClickRefresh: any;
};

const useStyles = makeStyles((theme) => ({
    table: {
        whiteSpace: 'nowrap',
        '& tr': { cursor: 'pointer' },
        '& tr:hover': { backgroundColor: 'rgba(0, 0, 0, 0.07) !important' },
    },
    toolbar: {
        paddingRight: theme.spacing(3),
    },
}));

export default function RestaurantSurveysTable({ loading, title, restaurantSurveys, onClickRefresh }: Props): React.ReactElement | null {
    const classes = useStyles();
    const table = useRef(null);

    if (!restaurantSurveys?.length) {
        return null;
    }

    const columns = [
        {
            name: 'restaurantId',
            label: ' ',
            options: {
                display: 'excluded' as any,
                filter: false,
                searchable: true,
            },
        },
        {
            name: 'restaurantName',
            label: translate('Restaurant'),
            options: {
                filter: true,
                searchable: true,
            },
        },
        {
            name: 'averageRating',
            label: translate('Rating'),
            options: {
                filter: true,
                searchable: false,
            },
        },
        {
            name: 'withRating',
            label: translate('Surveys'),
            options: {
                filter: true,
                searchable: false,
            },
        },
        {
            name: 'totalTip',
            label: translate('Tip'),
            options: {
                filter: true,
                searchable: false,
            },
        },
    ];

    return (
        <div style={{ position: 'relative' }}>
            <UpdatingContentProgress loading={loading} />
            <MUIDataTable
                // @ts-ignore
                ref={table}
                className={classes.table}
                title={title || translate('Restaurant Surveys')}
                data={restaurantSurveys
                    .filter((restaurantSurveys) => restaurantSurveys.averageRating !== 0)
                    .map((restaurantSurvey) => {
                        const row = {
                            restaurantId: restaurantSurvey.restaurantId,
                            restaurantName: restaurantSurvey.restaurantName,
                            averageRating: restaurantSurvey.averageRating,
                            withRating: restaurantSurvey.withRating,
                            totalTip: restaurantSurvey.totalTip ?? '',
                        } as const;
                        return row;
                    })}
                columns={columns}
                options={{
                    responsive: 'standard',
                    tableBodyMaxHeight: '500px',
                    selectableRows: 'none',
                    filterType: 'multiselect',
                    rowsPerPage: 100,
                    onDownload: createFilteredOnDownloadFunctional(table),
                }}
            />
        </div>
    );
}
