import { makeStyles } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import AddIcon from '@material-ui/icons/Add';
import { useState } from 'react';
import * as React from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import { Button } from 'src/components/Button';
import { FormSwitch } from 'src/components/form/FormSwitch';
import { translate } from 'src/i18n/translate';
import { CloseIcon } from 'src/icons/CloseIcon';
import { EditIcon } from 'src/icons/EditIcon';
import { AddCurrencyDialog } from 'src/scenes/letseatmanager/theRestaurant/pos/AddCurrencyDialog';
import { RemoveCurrencyDialog } from 'src/scenes/letseatmanager/theRestaurant/pos/RemoveCurrencyDialog';
import { UpdateCurrencyDialog } from 'src/scenes/letseatmanager/theRestaurant/pos/UpdateCurrencyDialog';
import type { RestaurantCurrency } from 'src/types/RestaurantCurrency';
import { useSelector } from 'src/utils/react/useSelector';

export function CurrenciesSettings({ onSuccess }: Props): React.ReactElement {
    const classes = useStyles();

    const { control } = useFormContext();

    const currencies = useWatch<Array<RestaurantCurrency>>({ name: 'currencies', control });
    const acceptMultipleCurrenciesPaymentEnabled = useWatch<boolean>({ name: 'acceptMultipleCurrenciesPaymentEnabled', control });

    const [addCurrencyDialogState, setAddCurrencyDialogState] = useState({ open: false });
    const [removeCurrencyDialogState, setRemoveCurrencyDialogState] = useState({ open: false, restaurantCurrency: undefined });
    const [updateCurrencyDialogState, setUpdateCurrencyDialogState] = useState({ open: false, restaurantCurrency: undefined });

    const mainCurrency = useSelector((state) => state.app.restaurant?.currencyFormat?.currency);

    const openAddCurrencyDialog = () => setAddCurrencyDialogState({ open: true });
    const openRemoveCurrencyDialog = (restaurantCurrency: any) => setRemoveCurrencyDialogState({ open: true, restaurantCurrency });
    const openUpdateCurrencyDialog = (restaurantCurrency: any) => setUpdateCurrencyDialogState({ open: true, restaurantCurrency });

    const closeAddCurrencyDialog = () => setAddCurrencyDialogState({ open: false });
    const closeRemoveCurrencyDialog = () => setRemoveCurrencyDialogState({ open: false, restaurantCurrency: undefined });
    const closeUpdateCurrencyDialog = () => setUpdateCurrencyDialogState({ open: false, restaurantCurrency: undefined });

    return (
        <Grid container spacing={3}>
            <Grid item xs={12} md={6}>
                <FormSwitch
                    name='acceptMultipleCurrenciesPaymentEnabled'
                    label={translate('Accept payments in different currencies')}
                    tooltip={translate('Activate the option if you want to accept payments in different currencies at POS')}
                />
            </Grid>
            {acceptMultipleCurrenciesPaymentEnabled && (
                <>
                    <AddCurrencyDialog open={addCurrencyDialogState.open} onSuccess={onSuccess} onClose={closeAddCurrencyDialog} />
                    <UpdateCurrencyDialog
                        open={updateCurrencyDialogState.open}
                        onSuccess={onSuccess}
                        onClose={closeUpdateCurrencyDialog}
                        restaurantCurrency={updateCurrencyDialogState.restaurantCurrency}
                    />
                    <RemoveCurrencyDialog
                        open={removeCurrencyDialogState.open}
                        onSuccess={onSuccess}
                        onClose={closeRemoveCurrencyDialog}
                        restaurantCurrency={removeCurrencyDialogState.restaurantCurrency}
                    />
                    <div className={classes.section}>
                        <h2 className={classes.subtitle}>{translate('Accepted currencies')}</h2>
                        <div className={classes.itemsContainer}>
                            {currencies?.map((currency: any, idx: any) => (
                                <div className={classes.item} key={idx}>
                                    {`${currency.currency} (${currency.exchangeRate} ${mainCurrency})`}
                                    <Button icon onClick={() => openUpdateCurrencyDialog(currency)} classes={{ button: classes.iconButton }}>
                                        <EditIcon size={14} title={translate('Change currency')} />
                                    </Button>
                                    <Button icon onClick={() => openRemoveCurrencyDialog(currency)} classes={{ button: classes.iconButton }}>
                                        <CloseIcon size={14} title={translate('Remove currency')} />
                                    </Button>
                                </div>
                            ))}
                        </div>
                        <Button outlined onClick={openAddCurrencyDialog}>
                            <AddIcon />
                            {translate('Add currency')}
                        </Button>
                    </div>
                </>
            )}
        </Grid>
    );
}

const useStyles = makeStyles((theme) => ({
    subtitle: {
        fontFamily: theme.typography.medium,
        fontSize: 18,
        color: theme.palette.secondary.contrastText,
        margin: 0,
        display: 'flex',
        flexDirection: 'row',
    },
    section: {
        width: '100%',
        borderRadius: 5,
        border: `1px solid ${theme.palette.secondary.dark}`,
        display: 'flex',
        flexDirection: 'column',
        padding: 15,
        gap: 20,
    },
    itemsContainer: {
        display: 'flex',
        width: '100%',
        flexWrap: 'wrap',
        flexDirection: 'row',
        alignItems: 'center',
        gap: 10,
    },
    item: {
        fontFamily: theme.typography.medium,
        fontSize: 14,
        minWidth: 140,
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        border: '1px solid #BABABA',
        borderRadius: 6,
        padding: '5px 10px',
        gap: 5,
        color: '#2E3748',
        '&:hover': {
            backgroundColor: '#D9D9D933',
        },
    },
    iconButton: {
        width: 'fit-content',
        height: 'fit-content',
        borderRadius: '50%',
        minHeight: 28,
    },
}));

type Props = {
    onSuccess: any;
};
