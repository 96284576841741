import { letseatmanagerApiMethod } from 'src/api/letseatmanagerApi';
import type { ApiSauceResponse } from 'src/api/types/ApiSauceResponse';
import type { InvoiceId, RestaurantId } from 'src/types/Id';

export async function downloadOrderInvoiceApi(request: DownloadOrderInvoiceApiRequest): ApiSauceResponse<DownloadOrderInvoiceApiResponse> {
    return letseatmanagerApiMethod('invoice/downloadOrderInvoiceApi', request);
}

type DownloadOrderInvoiceApiRequest = {
    restaurantId: RestaurantId;
    invoiceId: InvoiceId;
    documentType: 'pdf' | 'xml';
};

type DownloadOrderInvoiceApiResponse = {
    uri: string;
};
