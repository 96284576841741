import { letseatadminApiMethod } from 'src/api/letseatadminApi';
import type { ApiSauceResponse } from 'src/api/types/ApiSauceResponse';
import type { PaymentRejectReason } from 'src/constants/PaymentRejectReason';
import type { SubscriptionPaymentErrorType } from 'src/constants/SubscriptionPaymentErrorType';
import type { SubscriptionId } from 'src/types/Id';

export async function createNextSubscriptionPaymentApi(request: CreateNextSubscriptionPaymentApiRequest): ApiSauceResponse<CreateNextSubscriptionPaymentApiResponse> {
    return letseatadminApiMethod('subscription/createNextSubscriptionPaymentApi', request);
}

export type CreateNextSubscriptionPaymentApiRequest = {
    subscriptionId: SubscriptionId;
};

export type CreateNextSubscriptionPaymentApiResponse = {
    paymentErrorType?: SubscriptionPaymentErrorType;
    paymentRejectReason?: PaymentRejectReason;
};
