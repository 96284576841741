import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import * as React from 'react';
import { Button } from 'src/components/Button';
import { Tooltip } from 'src/components/Tooltip';
import { translate } from 'src/i18n/translate';

type Props = {
    tooltip?: string;
    onClick: any;
};

export function DownloadCsvToolbarButton({ tooltip, onClick }: Props): React.ReactElement {
    return (
        <Button icon onClick={onClick}>
            <Tooltip text={tooltip || translate('Download CSV')}>
                <CloudDownloadIcon />
            </Tooltip>
        </Button>
    );
}
