import { PrinterContents } from 'src/constants/PrinterContent';
import { PrintTypes } from 'src/constants/PrintType';
import { getDeprecatedPrintersInLocalStorage } from 'src/localStorage/getDeprecatedPrintersInLocalStorage';
import { createPartialShiftCashRegisterReportPrint } from 'src/services/printer/prints/createPartialShiftCashRegisterReportPrint';
import { printerDeprecatedToPrinter } from 'src/services/printer/utils/printerDeprecatedToPrinter';
import { usePrintContent } from 'src/services/printer/utils/usePrintContent';
import { DeviceGroupId } from 'src/types/Id';
import { PartialCashRegisterShiftReportVm } from 'src/types/PartialCashRegisterShiftReportVm';
import { PrinterVm } from 'src/types/PrinterVm';
import { useSelector } from 'src/utils/react/useSelector';

export function usePrintPartialShiftCashRegisterReport(): (partialShiftCashRegisterReport: PartialCashRegisterShiftReportVm, options?: PrintPartialShiftCashRegisterOptions) => Promise<void> {
    const newDeviceManagementEnabled = useSelector((state) => state.app.restaurant?.newDeviceManagementEnabled);
    const restaurant = useSelector((state) => state.app.restaurant);
    const deviceGroup = useSelector((state) => state.app.deviceGroup);
    const printers = useSelector((state) => state.app.printers);

    const deprecatedPrinters = getDeprecatedPrintersInLocalStorage().map(printerDeprecatedToPrinter);
    let allPrinters: Array<PrinterVm> = [];

    const { printContent, printContents } = usePrintContent();

    if (newDeviceManagementEnabled) {
        allPrinters = printers?.filter((printer: PrinterVm) =>
            printer.deviceGroups?.some((deviceGroupToSearch: { deviceGroupId: DeviceGroupId; deviceGroupName: string }) => deviceGroupToSearch.deviceGroupId === deviceGroup?.deviceGroupId),
        );
    } else {
        allPrinters = deprecatedPrinters;
    }

    const printPartialShiftCashRegisterReport = async (partialShiftCashRegisterReport: PartialCashRegisterShiftReportVm, options?: PrintPartialShiftCashRegisterOptions) => {
        if (allPrinters?.length === 0) {
            if (options?.forceToPrint) alert('No active printers to select');
            return;
        }
        if (allPrinters.length === 1 && options?.forceToPrint) {
            printContents([
                {
                    printer: allPrinters[0],
                    print: {
                        printType: PrintTypes.SHIFT_CASH_REGISTER_REPORT,
                        printerInstructions: createPartialShiftCashRegisterReportPrint({ partialShiftCashRegisterReport, restaurant }),
                    },
                },
            ]);
            return;
        }

        const shiftCashRegisterPrinters = allPrinters?.filter((printer: PrinterVm) => {
            return printer.printerContent !== PrinterContents.COMMAND;
        });

        if (shiftCashRegisterPrinters.length === 0) {
            if (options?.forceToPrint)
                printContent({
                    printType: PrintTypes.PARTIAL_SHIFT_CASH_REGISTER_REPORT,
                    printerInstructions: createPartialShiftCashRegisterReportPrint({ partialShiftCashRegisterReport, restaurant }),
                });
            return;
        }

        const contentsToPrint = shiftCashRegisterPrinters.map((printer) => ({
            printer: printer,
            print: {
                printType: PrintTypes.PARTIAL_SHIFT_CASH_REGISTER_REPORT,
                printerInstructions: createPartialShiftCashRegisterReportPrint({ partialShiftCashRegisterReport, restaurant }),
            },
        }));

        printContents(contentsToPrint);
    };
    return printPartialShiftCashRegisterReport;
}

type PrintPartialShiftCashRegisterOptions = {
    forceToPrint: boolean;
};
