import type { FaqVm } from 'src/api/letseatadmin/faq/getFaqApi';
import { letseatadminApiMethod } from 'src/api/letseatadminApi';
import type { ApiSauceResponse } from 'src/api/types/ApiSauceResponse';

export async function createFaqApi(request: CreateFaqApiRequest): ApiSauceResponse<FaqVm> {
    return letseatadminApiMethod('faq/createFaqApi', request);
}

export type CreateFaqApiRequest = {
    question: string;
    answer: string;
    imageUrl?: string;
    position?: number;
    articleUrl?: string;
};
