import IconButton from '@material-ui/core/IconButton';
import { withStyles } from '@material-ui/core/styles';
import TableCell from '@material-ui/core/TableCell';
import TableFooter from '@material-ui/core/TableFooter';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import Tooltip from '@material-ui/core/Tooltip';
import RefreshIcon from '@material-ui/icons/Refresh';
import moment from 'moment/moment';
import MUIDataTable from 'mui-datatables';
import * as React from 'react';
import { letseatmanagerApiDeprecated } from 'src/api/letseatmanagerApiDeprecated';
import type { FilterState } from 'src/components/input/FromToFilter';
import { UpdatingContentProgress } from 'src/components/UpdatingContentProgress';
import { history } from 'src/config/history';
import { Countries, Country } from 'src/constants/Country';
import { RoutePaths } from 'src/constants/RoutePath';
import { translate } from 'src/i18n/translate';
import type { BenefitPlanId, CompanyId } from 'src/types/Id';
import { alertKnownErrorOrSomethingWentWrong } from 'src/utils/alert/alertKnownErrorOrSomethingWentWrong';
import { deepEqual } from 'src/utils/object/deepEqual';
import { RegExps } from 'src/utils/RegExps';

export default withStyles((theme) => ({
    table: {
        whiteSpace: 'nowrap',
        '& tr': { cursor: 'pointer' },
        '& tr:hover': { backgroundColor: 'rgba(0, 0, 0, 0.07) !important' },
    },
    toolbar: {
        paddingRight: theme.spacing(3),
    },
}))(
    class CompanyCreditsBillingData extends React.Component<Props, State> {
        state = {
            filter: this.props.filter,
            loading: false,
            rows: [],
            total: {
                companyCredits: '',
                // commission: '',
                // iva: '',
                // toDeposit: '',
                orders: 0,
                notAccepted: 0,
            },
        };

        async componentDidMount() {
            await this.load();
        }

        async componentDidUpdate(prevProps: Props, prevState: State) {
            if (!deepEqual(prevProps.filter, this.props.filter)) {
                await this.load();
            }
        }

        // shouldComponentUpdate(nextProps: Props, nextState: State): boolean {
        //     return !deepEqual(this.props, nextProps) || !deepEqual(this.state, nextState);
        // }

        load = async () => {
            this.setState({ loading: true });
            const request: Request = {
                from: moment(this.props.filter.from).format('YYYY-MM-DD'),
                to: moment(this.props.filter.to).format('YYYY-MM-DD'),
                country: Countries.MX,
            };
            const response = await letseatmanagerApiDeprecated.admin.getCompanyCreditsBillingData(request);
            this.setState({ loading: false });
            if (!response.ok) {
                alertKnownErrorOrSomethingWentWrong(response);
                return;
            }
            this.setState({ ...response.data });
        };

        render() {
            const { classes } = this.props;
            return (
                <div style={{ position: 'relative' }}>
                    <UpdatingContentProgress loading={this.state.loading} />
                    <MUIDataTable
                        /* @ts-ignore */
                        className={classes.table}
                        title={translate('Companies')}
                        data={this.state.rows.map((row: any) => ({
                            companyId: row.companyId,
                            benefitPlanId: row.benefitPlanId,
                            companyName: row.companyName,
                            benefitPlanName: row.benefitPlanName,
                            companyCredits: parseFloat(row.companyCredits),
                            // commission: parseFloat(row.commission),
                            // iva: parseFloat(row.iva),
                            // toDeposit: parseFloat(row.toDeposit),
                            orders: row.orders,
                        }))}
                        columns={[
                            {
                                name: 'companyId',
                                label: ' ',
                                options: {
                                    display: 'excluded',
                                    filter: false,
                                },
                            },
                            {
                                name: 'benefitPlanId',
                                label: ' ',
                                options: {
                                    display: 'excluded',
                                    filter: false,
                                },
                            },
                            {
                                name: 'companyName',
                                label: translate('Company'),
                                options: {},
                            },
                            {
                                name: 'benefitPlanName',
                                label: translate('Benefit Plan'),
                                options: {},
                            },
                            {
                                name: 'companyCredits',
                                label: translate('Used Company Credits'),
                                options: {
                                    filter: false,
                                    searchable: false,
                                },
                            },
                            // {
                            //     name: 'commission',
                            //     label: translate('Commission'),
                            //     options: {
                            //         filter: false,
                            //         searchable: false,
                            //     },
                            // },
                            // {
                            //     name: 'iva',
                            //     label: translate('IVA'),
                            //     options: {
                            //         filter: false,
                            //         searchable: false,
                            //     },
                            // },
                            // {
                            //     name: 'toDeposit',
                            //     label: translate('To Deposit'),
                            //     options: {
                            //         filter: false,
                            //         searchable: false,
                            //     },
                            // },
                            {
                                name: 'orders',
                                label: translate('Orders'),
                                options: {
                                    filter: false,
                                    searchable: false,
                                },
                            },
                        ]}
                        options={{
                            responsive: 'standard',
                            tableBodyMaxHeight: '500px',
                            selectableRows: 'none',
                            // setRowProps: (row) => {
                            //     return {
                            //         style: { backgroundColor: getCompaniestatusColor(row[30], row[32]) },
                            //     };
                            // },
                            onRowClick: (
                                rowData: Array<string>,
                                rowMeta: {
                                    dataIndex: number;
                                    rowIndex: number;
                                },
                            ) => {
                                history.push({
                                    pathname: RoutePaths.ADMIN_COMPANY_CREDITS_BILLING_FOR_BENEFIT_PLAN.replace(`:benefitPlanId(${RegExps.uuid})`, rowData[1]),
                                    search: history.location.search,
                                });
                            },
                            filterType: 'multiselect',
                            rowsPerPage: 100,
                            customToolbar: () => {
                                return (
                                    <>
                                        <Tooltip title='Refresh'>
                                            <IconButton onClick={this.load}>
                                                <RefreshIcon />
                                            </IconButton>
                                        </Tooltip>
                                    </>
                                );
                            },
                            customFooter: (count, page, rowsPerPage, changeRowsPerPage, changePage) => (
                                <TableFooter>
                                    <TableRow>
                                        <TablePagination
                                            count={count}
                                            rowsPerPage={rowsPerPage}
                                            page={page}
                                            onChangePage={(_, page) => changePage(page)}
                                            onChangeRowsPerPage={(event) => changeRowsPerPage(event.target.value)}
                                            rowsPerPageOptions={[10, 15, 100]}
                                        />
                                    </TableRow>
                                    <TableRow>
                                        {/* @ts-ignore */}
                                        <TableCell size='small' colSpan='2'>
                                            {translate('Total Used Company Credits')}: {this.state.total.companyCredits}
                                        </TableCell>
                                        {/*<TableCell size="small">{translate('Commission')}: {this.state.total.commission}</TableCell>*/}
                                        {/*<TableCell size="small">{translate('IVA')}: {this.state.total.iva}</TableCell>*/}
                                        {/*<TableCell size="small">{translate('To Deposit')}: {this.state.total.toDeposit}</TableCell>*/}
                                    </TableRow>
                                    <TableRow>
                                        <TableCell size='small'>
                                            {translate('Orders')}: {this.state.total.orders} COMPLETED
                                        </TableCell>
                                        <TableCell size='small'>
                                            {translate('Ignored')}: {this.state.total.notAccepted} NEW/ACCEPTED
                                        </TableCell>
                                    </TableRow>
                                </TableFooter>
                            ),
                            onDownload: (buildHead, buildBody, columns, data) => {
                                return '\uFEFF' + buildHead(columns) + buildBody(data);
                            },
                            downloadOptions: { filename: 'company billing data.csv' },
                        }}
                    />
                </div>
            );
        }
    },
) as React.ComponentType<any>;

type Props = {
    filter: FilterState;
    classes: any;
};

type State = {
    filter: FilterState;
    loading: boolean;
} & CompanyCreditsBillingDataVm;

type Request = {
    from: string;
    to: string;
    country: Country;
};

type CompanyCreditsBillingDataVm = {
    rows: Array<RowVm>;
    total: TotalVm;
};

type RowVm = {
    companyId: CompanyId;
    companyName: string;
    benefitPlanId: BenefitPlanId;
    benefitPlanName: string;
    companyCredits: string;
    orders: number;
    notAccepted: number;
};

type TotalVm = {
    companyCredits: string;
    orders: number;
    notAccepted: number;
};
