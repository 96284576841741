import { pidedirectoReportsMethod } from 'src/api/pidedirectoReportsApi';
import type { ApiSauceResponse } from 'src/api/types/ApiSauceResponse';
import type { InternalReport } from 'src/constants/InternalReport';

export async function getInternalReportsApi(request: GetInternalReportsApiRequest): ApiSauceResponse<void> {
    return pidedirectoReportsMethod('/getInternalReportsApi', request);
}

export type GetInternalReportsApiRequest = {
    internalReport: InternalReport;
    email: string;
    fromDate: string;
    toDate: string;
};
