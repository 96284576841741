import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Grid from '@material-ui/core/Grid';
import LinearProgress from '@material-ui/core/LinearProgress';
import RadioGroup from '@material-ui/core/RadioGroup';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import * as React from 'react';
import { withRouter } from 'react-router-dom';
import { letseatmanagerApiDeprecated } from 'src/api/letseatmanagerApiDeprecated';
import { Field, Form } from 'src/components/deprecated/react-final-form/Form';
import Radio from 'src/components/deprecated/react-final-form/Radio';
import TextField from 'src/components/deprecated/react-final-form/TextField';
import { PasswordRequiredContent } from 'src/components/PasswordRequiredContent';
import { Spacing } from 'src/components/spacing/Spacing';
import { history } from 'src/config/history';
import { RoutePaths } from 'src/constants/RoutePath';
import { translate } from 'src/i18n/translate';
import { appLayout } from 'src/theme/AppLayout';
import type { CustomerId } from 'src/types/Id';
import { alertKnownErrorOrSomethingWentWrong } from 'src/utils/alert/alertKnownErrorOrSomethingWentWrong';
import { pretty } from 'src/utils/string/pretty';
import { required } from 'src/utils/Validator';

export default withRouter<any, any>(
    withStyles((theme) => ({
        linearProgress: {
            position: 'absolute',
            width: '100%',
            bottom: 0,
        },
    }))(
        class CustomersCreditsDialog extends React.PureComponent<Props, State> {
            state: any = INITIAL_STATE;

            async componentDidMount(): Promise<void> {
                await this.openOrCloseDialog();
            }

            async componentDidUpdate(prevProps: Props) {
                if (this.props.location.pathname === prevProps.location.pathname) {
                    return;
                }
                await this.openOrCloseDialog();
            }

            handleClose = () => {
                if (this.state.submitting) {
                    return;
                }
                history.goBack();
            };

            handleExited = () => {
                this.setState(INITIAL_STATE as any);
            };

            openOrCloseDialog = async () => {
                if (new RegExp(`^${this.props.path}`).test(this.props.location.pathname)) {
                    if (!this.props.location.state) {
                        history.push({
                            pathname: RoutePaths.ADMIN_CUSTOMERS,
                            search: history.location.search,
                        });
                        return;
                    }
                    if (!this.state.open) {
                        this.setState({
                            open: true,
                            customers: this.props.location.state.customers,
                        });
                    }
                } else {
                    if (this.state.open) this.setState({ open: false });
                }
            };

            onSubmit = async (form: any) => {
                this.setState({ submitting: true });
                const bulkAddOrRemoveCredits = form.addCredits === 'true' ? letseatmanagerApiDeprecated.admin.bulkAddCredits : letseatmanagerApiDeprecated.admin.bulkRemoveCredits;
                const response = await bulkAddOrRemoveCredits({
                    customerIds: this.state.customers.map((customer: any) => customer.customerId),
                    amount: form.amount,
                });
                this.setState({ submitting: false });
                if (!response.ok) {
                    alertKnownErrorOrSomethingWentWrong(response);
                    return;
                }
                if (response.data.failed.length > 0) {
                    alert(`Failed to add credits for ${response.data.failed.length} customers (${pretty(response.data.failed)})`);
                }
                this.props.onCreditsAddedOrRemoved && this.props.onCreditsAddedOrRemoved();
                history.goBack();
            };

            getSubmitButtonText = (values: { addCredits: string }) => {
                if (values.addCredits === 'true') {
                    return this.state.submitting ? translate('Adding Credits').toUpperCase() : translate('Add Credits').toUpperCase();
                } else {
                    return this.state.submitting ? translate('Removing Credits').toUpperCase() : translate('Remove Credits').toUpperCase();
                }
            };

            render() {
                const { classes } = this.props;
                return (
                    <Dialog aria-labelledby='customers-credits-dialog-title' fullWidth scroll='paper' open={this.state.open} onClose={this.handleClose} onExited={this.handleExited}>
                        <PasswordRequiredContent customPassword={'1248'}>
                            <Form
                                onSubmit={this.onSubmit}
                                initialValues={{
                                    addCredits: 'true',
                                }}
                                render={({ handleSubmit, submitting, pristine, values }) => (
                                    <form onSubmit={handleSubmit} noValidate style={appLayout.dialog.form}>
                                        <DialogTitle id='customers-credits-dialog-title'>{translate('Bulk Manage Customer Credits').toUpperCase()}</DialogTitle>
                                        <DialogContent>{this.renderDialogContent(values as any)}</DialogContent>
                                        <DialogActions>
                                            <Button onClick={this.handleClose} disabled={this.state.submitting}>
                                                {translate('Cancel').toUpperCase()}
                                            </Button>
                                            <Button color='primary' type='submit' disabled={this.state.submitting}>
                                                {this.getSubmitButtonText(values as any)}
                                            </Button>
                                        </DialogActions>
                                        {this.state.submitting && <LinearProgress className={classes.linearProgress} />}
                                    </form>
                                )}
                            />
                        </PasswordRequiredContent>
                    </Dialog>
                );
            }

            renderDialogContent(values: { addCredits: string }) {
                return (
                    <>
                        <Grid container spacing={3}>
                            <Grid item xs={12}>
                                <RadioGroup row>
                                    <FormControlLabel
                                        label='Add Credits'
                                        control={<Field name='addCredits' component={Radio} type='radio' color='primary' value='true' disabled={this.state.submitting} />}
                                    />
                                    <FormControlLabel
                                        label='Remove Credits'
                                        control={<Field name='addCredits' component={Radio} type='radio' color='primary' value='false' disabled={this.state.submitting} />}
                                    />
                                </RadioGroup>
                            </Grid>
                            <Grid item xs={12}>
                                <Field
                                    name='amount'
                                    component={TextField}
                                    label={values.addCredits === 'true' ? translate('Credits To Add') : translate('Credits To Remove')}
                                    fullWidth
                                    validate={required}
                                    disabled={this.state.submitting}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                {this.renderReceivers()}
                            </Grid>
                        </Grid>

                        <Grid container justify='space-around'>
                            <Grid item>
                                <Spacing units={8} />
                                {this.state.errorMessage && (
                                    <Typography display='block' color='error'>
                                        {this.state.errorMessage}
                                    </Typography>
                                )}
                            </Grid>
                        </Grid>
                    </>
                );
            }

            renderReceivers() {
                if (this.state.customers.length === 0) {
                    return '';
                }
                if (this.state.customers.length <= 20) {
                    return `Bulk add/remove credits for customer ${this.state.customers.map((customer: any) => customer.name || customer.customerId.substring(0, 8) + '...').join(', ')}`;
                }
                return `This bulk change will add/remove credits for customer ${this.state.customers
                    .slice(0, 20)
                    .map((customer: any) => customer.name || customer.customerId.substring(0, 8) + '...')
                    .join(', ')} + ${this.state.customers.length - 5} more`;
            }
        },
    ),
) as React.ComponentType<any>;

const INITIAL_STATE = {
    open: false,
    customers: [],
    submitting: false,
} as const;

type Props = {
    classes: any;
    location: {
        pathname: string;
        state: {
            customers: Array<{
                customerId: CustomerId;
                name: string;
            }>;
        };
    };
    onCreditsAddedOrRemoved?: any;
    path: string;
};

type State = {
    open: boolean;
    submitting: boolean;
    customers: Array<{
        customerId: CustomerId;
        name: string;
    }>;
    errorMessage?: string;
};
