import type { Bank } from 'src/constants/Bank';
import type { BankClave } from 'src/types/Id';
import { requireValue } from 'src/utils/require/requireValue';

export function getBankClave(bank: Bank): BankClave {
    return requireValue(BANK_CLAVE[bank]);
}

const BANK_CLAVE: Partial<Record<Bank, BankClave>> = Object.freeze({
    BANAMEX: '002' as any,
    BANCOMEXT: '006' as any,
    BANOBRAS: '009' as any,
    'BBVA BANCOMER': '012' as any,
    SANTANDER: '014' as any,
    BANJERCITO: '019' as any,
    HSBC: '021' as any,
    BAJIO: '030' as any,
    IXE: '032' as any,
    INBURSA: '036' as any,
    INTERACCIONES: '037' as any,
    MIFEL: '042' as any,
    SCOTIABANK: '044' as any,
    BANREGIO: '058' as any,
    INVEX: '059' as any,
    BANSI: '060' as any,
    AFIRME: '062' as any,
    BANORTE: '072' as any,
    'THE ROYAL BANK': '102' as any,
    'AMERICAN EXPRESS': '103' as any,
    BAMSA: '106' as any,
    TOKYO: '108' as any,
    'JP MORGAN': '110' as any,
    BMONEX: '112' as any,
    'VE POR MAS': '113' as any,
    ING: '116' as any,
    DEUTSCHE: '124' as any,
    'CREDIT SUISSE': '126' as any,
    AUTOFIN: '128' as any,
    BARCLAYS: '129' as any,
    COMPARTAMOS: '130' as any,
    'BANCO FAMSA': '131' as any,
    BMULTIVA: '132' as any,
    ACTINVER: '133' as any,
    'WAL-MART': '134' as any,
    NAFIN: '135' as any,
    INTERBANCO: '136' as any,
    BANCOPPEL: '137' as any,
    'ABC CAPITAL': '138' as any,
    'UBS BANK': '139' as any,
    CONSUBANCO: '140' as any,
    VOLKSWAGEN: '141' as any,
    CIBANCO: '143' as any,
    BBASE: '145' as any,
    BANSEFI: '166' as any,
    'HIPOTECARIA FEDERAL': '168' as any,
    MONEXCB: '600' as any,
    GBM: '601' as any,
    MASARI: '602' as any,
    VALUE: '605' as any,
    ESTRUCTURADORES: '606' as any,
    TIBER: '607' as any,
    VECTOR: '608' as any,
    'B&B': '610' as any,
    ACCIVAL: '614' as any,
    'MERRILL LYNCH': '615' as any,
    FINAMEX: '616' as any,
    VALMEX: '617' as any,
    UNICA: '618' as any,
    MAPFRE: '619' as any,
    PROFUTURO: '620' as any,
    'CB ACTINVER': '621' as any,
    OACTIN: '622' as any,
    'SKANDIA VIDA': '623' as any,
    CBDEUTSCHE: '626' as any,
    ZURICH: '627' as any,
    ZURICHVI: '628' as any,
    'SU CASITA': '629' as any,
    'CB INTERCAM': '630' as any,
    'CI BOLSA': '631' as any,
    'BULLTICK CB': '632' as any,
    STERLING: '633' as any,
    FINCOMUN: '634' as any,
    'HDI SEGUROS': '636' as any,
    ORDER: '637' as any,
    AKALA: '638' as any,
    'CB JPMORGAN': '640' as any,
    REFORMA: '642' as any,
    STP: '646' as any,
    TELECOMM: '647' as any,
    EVERCORE: '648' as any,
    'SKANDIA OPERADORA DE FONDOS': '649' as any,
    SEGMTY: '651' as any,
    ASEA: '652' as any,
    KUSPIT: '653' as any,
    SOFIEXPRESS: '655' as any,
    UNAGRA: '656' as any,
    'OPCIONES EMPRESARIALES DEL NOROESTE': '659' as any,
    CLS: '901' as any,
    INDEVAL: '902' as any,
    LIBERTAD: '670' as any,
    'MERCADO PAGO': '722' as any,
    'N/A': '999' as any,
});
