import { useEffect } from 'react';
import * as React from 'react';
import { app2 } from 'src/app2';
import { Button } from 'src/components/Button';
import { Dialog } from 'src/components/Dialog';
import { CreateMexicanManualOrderDialog } from 'src/components/dialog/order/manualOrderDeliveryDialog/CreateMexicanManualOrderDialog';
import { CreateUruguayanManualOrderDialog } from 'src/components/dialog/order/manualOrderDeliveryDialog/CreateUruguayanManualOrderDialog';
import { DialogActions } from 'src/components/DialogActions';
import { Text } from 'src/components/Text';
import { Countries } from 'src/constants/Country';
import { LogEventTypes } from 'src/constants/LogEventType';
import { translate } from 'src/i18n/translate';
import { SubscriptionPage } from 'src/scenes/letseatadmin/SubscriptionPage';
import { FreeSubscriptionPlanEndPage } from 'src/scenes/letseatmanager/FreeSubscriptionPlanEndPage';
import { createUiInteractionLogEvent } from 'src/services/logEvent/createUiInteractionLogEvent';
import { useAction } from 'src/utils/react/useAction';
import { useSelector } from 'src/utils/react/useSelector';
import { isFreeTrialSubscription } from 'src/utils/subscriptionPlan/isFreeTrialSubscription';

export function CreateManualOrderDeliveryDialog(): React.ReactElement | null {
    const open = useSelector((state) => state.app2.createManualOrderDeliveryDialog.open);
    const close = useAction(app2.actions.closeCreateManualOrderDeliveryDialog);
    const subscriptionPlan = useSelector((state) => state.app2.subscriptionPlan);
    const subscriptionAccess = useSelector((state) => state.app2.subscriptionAccess);
    const remainingDays = useSelector((state) => state.app2.remainingDays);
    const restaurantCountry = useSelector((state) => state.app.restaurant?.country);

    useEffect(() => {
        if (open) {
            createUiInteractionLogEvent({
                logEventType: LogEventTypes.USER_OPENED_REQUEST_DRIVER_DIALOG,
            });
        }
    }, [open]);

    if (!open) return null;

    if (subscriptionAccess && remainingDays === 0) {
        return (
            <Dialog open={open} title={translate('Suspended Service')}>
                {isFreeTrialSubscription(subscriptionPlan) ? <FreeSubscriptionPlanEndPage /> : <SubscriptionPage />}
                <DialogActions>
                    <Button secondary onClick={close}>
                        <Text error>{translate('Cancel')}</Text>
                    </Button>
                </DialogActions>
            </Dialog>
        );
    }

    if (restaurantCountry === Countries.UY || restaurantCountry === Countries.AR) return <CreateUruguayanManualOrderDialog />;
    return <CreateMexicanManualOrderDialog />;
}
