import { makeStyles } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import CloseIcon from '@material-ui/icons/Close';
import { useEffect, useState } from 'react';
import * as React from 'react';
import { useForm } from 'react-hook-form';
import { changeSupplierApi } from 'src/api/letseatmanager/supplier/changeSupplierApi';
import { getSupplierApi } from 'src/api/letseatmanager/supplier/getSupplierApi';
import { Button } from 'src/components/Button';
import { Dialog } from 'src/components/Dialog';
import { DialogActions } from 'src/components/DialogActions';
import { Form } from 'src/components/form/Form';
import { FormCurrencyNumberStringField } from 'src/components/form/FormCurrencyNumberStringField';
import { FormEmailField } from 'src/components/form/FormEmailField';
import { FormNumberField } from 'src/components/form/FormNumberField';
import { FormPhoneNumberField } from 'src/components/form/FormPhoneNumberField';
import { FormRfcField } from 'src/components/form/FormRfcField';
import { FormSupplyAutocomplete } from 'src/components/form/FormSupplyAutocomplete';
import { FormTextField } from 'src/components/form/FormTextField';
import { translate } from 'src/i18n/translate';
import { SupplyId, type SupplierId } from 'src/types/Id';
import type { SupplyVm } from 'src/types/SupplyVm';
import { alertKnownErrorOrSomethingWentWrong } from 'src/utils/alert/alertKnownErrorOrSomethingWentWrong';
import { requireValue } from 'src/utils/require/requireValue';

export function ChangeSupplierDialog({ open, supplierId, handleClose, onSupplierChanged }: Props): React.ReactElement {
    const form = useForm();
    const {
        formState: { isSubmitting },
    } = form;

    const classes = useStyles();

    const [supplies, setSupplies] = useState<Array<SupplyVm>>([]);
    const [currentSupply, setCurrentSupply] = useState<SupplyVm | undefined>(undefined);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        if (open) load();
    }, [open]);

    const load = async () => {
        setLoading(true);
        const response = await getSupplierApi({
            supplierId: requireValue(supplierId),
        });
        setLoading(false);
        if (!response.ok) {
            alertKnownErrorOrSomethingWentWrong(response);
            return;
        }
        form.reset(response.data);
        setSupplies(response.data.supplies);
    };

    const onSubmit = async (form: any) => {
        const response = await changeSupplierApi({
            supplierId: requireValue(supplierId),
            supplyIds: supplies.map((supply) => supply.supplyId),
            businessName: form.businessName,
            rfc: form.rfc,
            email: form.email,
            phoneNumber: form.phoneNumber,
            pendingBalance: form.pendingBalance,
            note: form.note,
        });
        if (!response.ok) {
            alertKnownErrorOrSomethingWentWrong(response);
            return;
        }
        onClose();
        onSupplierChanged();
    };

    const onClose = () => {
        if (isSubmitting) return;
        setSupplies([]);
        setCurrentSupply(undefined);
        handleClose();
    };

    const addSupply = () => {
        if (!currentSupply || supplies.some((supply) => supply.supplyId === currentSupply.supplyId)) return;
        setSupplies([...supplies, currentSupply]);
    };

    const removeSupply = (supplyId: SupplyId) => {
        setSupplies(supplies.filter((supply) => supply.supplyId !== supplyId));
    };

    return (
        <Dialog open={open} onClose={onClose} classes={{ dialog: classes.dialogContainer }}>
            <Form form={form} onSubmit={onSubmit}>
                <Grid container spacing={6}>
                    <Grid item xs={12} md={6}>
                        <h1 className={classes.title}>{translate('Supplier')}</h1>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <FormTextField name='businessName' label={translate('Business Name')} required disabled={loading} />
                            </Grid>
                            <Grid item xs={12}>
                                <FormRfcField name='rfc' label={translate('RFC')} required disabled={loading} />
                            </Grid>
                            <Grid item xs={12}>
                                <FormEmailField name='email' label={translate('Email')} required disabled={loading} />
                            </Grid>
                            <Grid item xs={12}>
                                <FormPhoneNumberField name='phoneNumber' label={translate('Phone Number')} required disabled={loading} />
                            </Grid>
                            <Grid item xs={12}>
                                <FormCurrencyNumberStringField name='pendingBalance' label={translate('Pending Balance')} disabled={loading} />
                            </Grid>
                            <Grid item xs={12}>
                                <FormCurrencyNumberStringField name='purchaseVale' label={translate('Purchase Value')} disabled />
                            </Grid>
                            <Grid item xs={12}>
                                <FormNumberField name='buys' label={translate('Buys')} disabled />
                            </Grid>
                            <Grid item xs={12}>
                                <FormTextField name='note' label={translate('Note')} disabled={loading} />
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <h1 className={classes.title}>{translate('Supplies')}</h1>
                        <Grid container spacing={2}>
                            <Grid item xs={8}>
                                <FormSupplyAutocomplete name='supplyId' label={translate('Supply')} onChange={setCurrentSupply} disabled={loading} />
                            </Grid>
                            <Grid item xs={4}>
                                <Button onClick={addSupply} disabled={loading} classes={{ button: classes.addSupplierButton }}>
                                    {translate('Add')}
                                </Button>
                            </Grid>
                            <Grid item xs={12}>
                                {supplies.map((supply) => {
                                    return (
                                        <div className={classes.supplierContainer} key={supply.supplyId}>
                                            <span className={classes.supplier}>{supply.name}</span>
                                            <Button icon onClick={() => removeSupply(supply.supplyId)} disabled={loading}>
                                                <CloseIcon />
                                            </Button>
                                        </div>
                                    );
                                })}
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                <DialogActions>
                    <Button onClick={handleClose} disabled={isSubmitting || loading} secondary>
                        {translate('Cancel')}
                    </Button>
                    <Button type='submit' disabled={isSubmitting || loading} primary>
                        {isSubmitting ? translate('Saving') : translate('Save')}
                    </Button>
                </DialogActions>
            </Form>
        </Dialog>
    );
}

const useStyles = makeStyles((theme) => ({
    dialogContainer: {
        width: '80%',
        maxWidth: '80%',
        maxHeight: '95%',
        height: 'fit-content',
    },
    title: {
        fontFamily: theme.typography.medium,
        fontSize: 20,
        marginBottom: 50,
    },
    acceptButton: {
        backgroundColor: theme.palette.primary.main,
        color: 'white',
        fontFamily: theme.typography.semiBold,
        fontSize: 15,
        borderRadius: 15,
        height: 30,
        width: 150,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        textTransform: 'none',
    },
    secondaryButton: {
        backgroundColor: 'white',
        color: theme.palette.primary.main,
        border: `1px solid ${theme.palette.primary.main}`,
        fontFamily: theme.typography.semiBold,
        fontSize: 15,
        borderRadius: 15,
        height: 30,
        width: 150,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        textTransform: 'none',
    },
    addSupplierButton: {
        marginTop: 20,
    },
    supplierContainer: {
        width: '100%',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        padding: '5px 15px',
        borderBottom: `1px solid ${theme.palette.secondary.dark}`,
    },
    supplier: {
        fontFamily: theme.typography.regular,
        fontSize: 15,
    },
}));

type Props = {
    open: boolean;
    supplierId?: SupplierId;
    handleClose: any;
    onSupplierChanged: any;
};
