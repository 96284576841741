import { formatAsCurrencyNumber } from '@pidedirecto/ui/utils';
import { BigNumber } from 'bignumber.js';
import moment from 'moment';
import type { RestaurantReportBillingDataVm } from 'src/api/letseatmanager/restaurant/getRestaurantReportBillingDataApi';
import { CountryCode } from 'src/constants/CountryCode';
import { translate } from 'src/i18n/translate';
import { objectsToPdf } from 'src/utils/pdf/objectsToPdf';

export function createAndSaveBilling({ businessName = '', addressOfEstablishment = '', rfc = '', restaurantBilling, from, to, country }: Props) {
    moment.locale('es');
    const {
        restaurantName,
        commissionWebCard,
        commissionWebCash,
        commissionWebCardOnDelivery,
        commissionAppCard,
        commissionAppCash,
        commissionAppCardOnDelivery,
        commissionManualOrders,
        webCard,
        webCash,
        webCardOnDelivery,
        whiteLabelAppCard,
        whiteLabelAppCash,
        whiteLabelAppCardOnDelivery,
        marketplaceAppCard,
        marketplaceAppCash,
        marketplaceAppCardOnDelivery,
        cashback,
        manualOrders,
        manualEarning,
        restaurantPromoCodeCostApp,
        paymentLink,
        iva,
        deposit,
        commission,
        commissionForWebCashOrders,
        commissionForWhiteLabelAppCardOrders,
        commissionForWhiteLabelAppCashOrders,
        commissionForMarketPlaceAppCardOrders,
        commissionForMarketPlaceAppCashOrders,
        commissionForManualOrders,
        commissionForRappiCargoWebCard,
        commissionForRappiCargoWebCash,
        commissionForRappiCargoAppCard,
        commissionForRappiCargoAppCash,
        commissionForRappiCargoManualOrder,
        commissionForRappiCargoPaymentLink,
        commissionForRappiCargoPosCard,
        commissionForRappiCargoPosCash,
        commissionForUberDaasWebCard,
        commissionForUberDaasWebCash,
        commissionForUberDaasAppCard,
        commissionForUberDaasAppCash,
        commissionForUberDaasManualOrder,
        commissionForUberDaasPaymentLink,
        commissionForUberDaasPosCard,
        commissionForUberDaasPosCash,
        rappiCargoWebCash,
        rappiCargoAppCash,
        rappiCargoManualOrder,
        rappiCargoPaymentLink,
        rappiCargoPosCash,
        rappiCargoWebCard,
        rappiCargoAppCard,
        rappiCargoPosCard,
        uberDaasWebCard,
        uberDaasWebCash,
        uberDaasAppCard,
        uberDaasAppCash,
        uberDaasManualOrder,
        uberDaasPaymentLink,
        uberDaasPosCard,
        uberDaasPosCash,
        subscriptionPayPerUsePaymentInvoice,
        manualEarningInvoice,
        deliveryCostPaidByRestaurant,
    } = restaurantBilling;
    const totalCash = whiteLabelAppCash + webCash + marketplaceAppCash + manualOrders;
    const totalCard = whiteLabelAppCard + webCard + marketplaceAppCard + paymentLink;
    const totalCardOnDelivery = whiteLabelAppCardOnDelivery + webCardOnDelivery + marketplaceAppCardOnDelivery;
    const totalRappiCargoCash = rappiCargoAppCash + rappiCargoWebCash + rappiCargoManualOrder + rappiCargoPosCash;
    const totalRappiCargoCard = rappiCargoWebCard + rappiCargoAppCard + rappiCargoPosCard;

    const totalUberDaasCash = uberDaasAppCash + uberDaasWebCash + uberDaasManualOrder + uberDaasPosCash;
    const totalUberDaasCard = uberDaasWebCard + uberDaasAppCard + uberDaasPosCard;

    const commissionForRappiCash = commissionForRappiCargoAppCash + commissionForRappiCargoWebCash + commissionForRappiCargoManualOrder + commissionForRappiCargoPosCash;
    const commissionForRappiCard = commissionForRappiCargoWebCard + commissionForRappiCargoAppCard + commissionForRappiCargoPosCard;
    const commissionForUberCash = commissionForUberDaasAppCash + commissionForUberDaasWebCash + commissionForUberDaasManualOrder + commissionForUberDaasPosCash;
    const commissionForUberDaasCard = commissionForUberDaasWebCard + commissionForUberDaasAppCard + commissionForUberDaasPosCard;

    const totalCashCommission = commissionForWhiteLabelAppCashOrders + commissionForWebCashOrders + commissionForManualOrders + commissionForMarketPlaceAppCashOrders;
    const totalCardCommission =
        commissionForWhiteLabelAppCardOrders + commissionForMarketPlaceAppCardOrders + calculateCommission(webCard, commissionWebCard) + calculateCommission(paymentLink, commissionWebCard);

    const totalOthers = BigNumber(rappiCargoPaymentLink)
        .plus(totalRappiCargoCard)
        .plus(totalRappiCargoCash)
        .plus(uberDaasPaymentLink)
        .plus(totalUberDaasCard)
        .plus(totalUberDaasCash)
        .plus(manualEarning)
        .plus(restaurantPromoCodeCostApp)
        .plus(deliveryCostPaidByRestaurant);

    const totalCardOnDeliveryCommision = (whiteLabelAppCardOnDelivery * commissionAppCardOnDelivery + webCardOnDelivery * commissionWebCardOnDelivery) / 100;

    const rappiCardData =
        totalRappiCargoCard !== 0
            ? [
                  {
                      columns: [
                          {
                              text: translate('Rappi Cargo Card'),
                          },
                          {
                              text: `${formatAsCurrencyNumber(BigNumber(totalRappiCargoCard).toFixed(2), { country })}`,
                              style: 'info',
                          },
                      ],
                      style: 'data',
                  },
                  {
                      columns: [
                          {
                              text: translate('Commissions'),
                          },
                          {
                              text: `${formatAsCurrencyNumber(BigNumber(commissionForRappiCard ?? 0).toFixed(2), { country })}`,
                              style: 'info',
                          },
                      ],
                      style: 'subData',
                  },
              ]
            : [{}];

    const totalRappiCargoPaymentLink =
        rappiCargoPaymentLink !== 0
            ? [
                  {
                      columns: [
                          {
                              text: translate('Rappi Cargo Paymentlink'),
                          },
                          {
                              text: `${formatAsCurrencyNumber(BigNumber(rappiCargoPaymentLink).toFixed(2), { country })}`,
                              style: 'info',
                          },
                      ],
                      style: 'data',
                  },
                  {
                      columns: [
                          {
                              text: translate('Commissions'),
                          },
                          {
                              text: `${formatAsCurrencyNumber(BigNumber(commissionForRappiCargoPaymentLink ?? 0).toFixed(2), { country })}`,
                              style: 'info',
                          },
                      ],
                      style: 'subData',
                  },
              ]
            : [{}];

    const rappiCashData =
        totalRappiCargoCash !== 0
            ? [
                  {
                      columns: [
                          {
                              text: translate('Rappi Cargo Cash'),
                          },
                          {
                              text: `${formatAsCurrencyNumber(BigNumber(totalRappiCargoCash).toFixed(2), { country })}`,
                              style: 'info',
                          },
                      ],
                      style: 'data',
                  },
                  {
                      columns: [
                          {
                              text: translate('Commissions'),
                          },
                          {
                              text: `${formatAsCurrencyNumber(BigNumber(commissionForRappiCash ?? 0).toFixed(2), { country })}`,
                              style: 'info',
                          },
                      ],
                      style: 'subData',
                  },
              ]
            : [{}];

    const totalUberDaasPaymentLink =
        uberDaasPaymentLink !== 0
            ? [
                  {
                      columns: [
                          {
                              text: translate('Uber Daas PaymentLink'),
                          },
                          {
                              text: `${formatAsCurrencyNumber(BigNumber(uberDaasPaymentLink).toFixed(2), { country })}`,
                              style: 'info',
                          },
                      ],
                      style: 'data',
                  },
                  {
                      columns: [
                          {
                              text: translate('Commissions'),
                          },
                          {
                              text: `${formatAsCurrencyNumber(BigNumber(commissionForUberDaasPaymentLink ?? 0).toFixed(2), { country })}`,
                              style: 'info',
                          },
                      ],
                      style: 'subData',
                  },
              ]
            : [{}];

    const uberCardData =
        totalUberDaasCard !== 0
            ? [
                  {
                      columns: [
                          {
                              text: translate('Uber Daas Card'),
                          },
                          {
                              text: `${formatAsCurrencyNumber(BigNumber(totalUberDaasCard).toFixed(2), { country })}`,
                              style: 'info',
                          },
                      ],
                      style: 'data',
                  },
                  {
                      columns: [
                          {
                              text: translate('Commissions'),
                          },
                          {
                              text: `${formatAsCurrencyNumber(BigNumber(commissionForUberDaasCard ?? 0).toFixed(2), { country })}`,
                              style: 'info',
                          },
                      ],
                      style: 'subData',
                  },
              ]
            : [{}];

    const uberCashData =
        totalUberDaasCash !== 0
            ? [
                  {
                      columns: [
                          {
                              text: translate('Uber Daas Cash'),
                          },
                          {
                              text: `${formatAsCurrencyNumber(BigNumber(totalUberDaasCash).toFixed(2), { country })}`,
                              style: 'info',
                          },
                      ],
                      style: 'data',
                  },
                  {
                      columns: [
                          {
                              text: translate('Commissions'),
                          },
                          {
                              text: `${formatAsCurrencyNumber(BigNumber(commissionForUberCash ?? 0).toFixed(2), { country })}`,
                              style: 'info',
                          },
                      ],
                      style: 'subData',
                  },
              ]
            : [{}];

    const headerInfo = `${restaurantName} \n RFC: ${rfc} \n ${businessName} \n ${addressOfEstablishment}`;
    const dateSpan = {
        text: `Del ${moment(from).format('LL')} al ${moment(to).format('LL')}`,
        style: 'date',
    } as const;

    const cardInfo = [
        {
            text: translate('Credit Card'),
            style: 'title',
        },
        {
            columns: [
                {
                    text: translate('App Card'),
                },
                {
                    text: `${formatAsCurrencyNumber(BigNumber(whiteLabelAppCard + marketplaceAppCard ?? 0).toFixed(2), { country })}`,
                    style: 'info',
                },
            ],
            style: 'data',
        },
        {
            columns: [
                {
                    text: `${translate('Commission')} ${commissionAppCard}%`,
                    style: 'subData',
                },
                {
                    text: `${formatAsCurrencyNumber(BigNumber(commissionForWhiteLabelAppCardOrders + commissionForMarketPlaceAppCardOrders ?? 0).toFixed(2), { country })}`,
                    style: ['subData', 'info'],
                },
            ],
        },
        {
            columns: [
                {
                    text: translate('Web Card'),
                    style: 'data',
                },
                {
                    text: `${formatAsCurrencyNumber(BigNumber(webCard ?? 0).toFixed(2), { country })}`,
                    style: ['data', 'info'],
                },
            ],
        },
        {
            columns: [
                {
                    text: `${translate('Commission')} ${commissionWebCard}%`,
                },
                {
                    text: `${formatAsCurrencyNumber(BigNumber(calculateCommission(webCard, commissionWebCard) ?? 0).toFixed(2), { country })}`,
                    style: 'info',
                },
            ],
            style: 'subData',
        },
        {
            columns: [
                {
                    text: translate('PaymentLinks'),
                },
                {
                    text: `${formatAsCurrencyNumber(BigNumber(paymentLink ?? 0).toFixed(2), { country })}`,
                    style: 'info',
                },
            ],
            style: 'data',
        },
        {
            columns: [
                {
                    text: `${translate('Commission')} ${commissionWebCard}%`,
                    style: 'subData',
                },
                {
                    text: `${formatAsCurrencyNumber(BigNumber(calculateCommission(paymentLink, commissionWebCard) ?? 0).toFixed(2), { country })}`,
                    style: 'info',
                },
            ],
            style: 'subData',
        },
        {
            columns: [
                {
                    text: translate('Total Card'),
                },
                {
                    text: `${formatAsCurrencyNumber(BigNumber(totalCard ?? 0).toFixed(2), { country })}`,
                    style: ['info', { color: '#333333' }],
                },
            ],
            style: 'subtitle',
        },
        {
            columns: [
                {
                    text: translate('Total card commission'),
                },
                {
                    text: `${formatAsCurrencyNumber(BigNumber(totalCardCommission ?? 0).toFixed(2), { country })}`,
                    style: 'info',
                },
            ],
            style: ['subData', { bold: true }],
        },
    ];
    const cashInfo = [
        {
            text: translate('Cash'),
            style: 'title',
        },
        {
            columns: [
                {
                    text: translate('App Cash'),
                },
                {
                    text: `${formatAsCurrencyNumber(BigNumber(whiteLabelAppCash + marketplaceAppCash ?? 0).toFixed(2), { country })}`,
                    style: 'info',
                },
            ],
            style: 'data',
        },
        {
            columns: [
                {
                    text: `${translate('Commission')} ${commissionAppCash}%`,
                },
                {
                    text: `${formatAsCurrencyNumber(BigNumber(commissionForWhiteLabelAppCashOrders + commissionForMarketPlaceAppCashOrders ?? 0).toFixed(2), { country })}`,
                    style: 'info',
                },
            ],
            style: 'subData',
        },
        {
            columns: [
                {
                    text: translate('Web Cash'),
                },
                {
                    text: `${formatAsCurrencyNumber(BigNumber(webCash ?? 0).toFixed(2), { country })}`,
                    style: 'info',
                },
            ],
            style: 'data',
        },
        {
            columns: [
                {
                    text: `${translate('Commission')} ${commissionWebCash}%`,
                },
                {
                    text: `${formatAsCurrencyNumber(BigNumber(commissionForWebCashOrders ?? 0).toFixed(2), { country })}`,
                    style: 'info',
                },
            ],
            style: 'subData',
        },
        {
            columns: [
                {
                    text: translate('Manual Orders'),
                },
                {
                    text: `${formatAsCurrencyNumber(BigNumber(manualOrders ?? 0).toFixed(2), { country })}`,
                    style: 'info',
                },
            ],
            style: 'data',
        },
        {
            columns: [
                {
                    text: `${translate('Commission')} ${commissionManualOrders}%`,
                },
                {
                    text: `${formatAsCurrencyNumber(BigNumber(commissionForManualOrders ?? 0).toFixed(2), { country })}`,
                    style: 'info',
                },
            ],
            style: 'subData',
        },
        {
            columns: [
                {
                    text: translate('Total Cash'),
                },
                {
                    text: `${formatAsCurrencyNumber(BigNumber(totalCash ?? 0).toFixed(2), { country })}`,
                    style: ['info', { color: '#333333' }],
                },
            ],
            style: 'subtitle',
        },
        {
            columns: [
                {
                    text: translate('Total cash commission'),
                },
                {
                    text: `${formatAsCurrencyNumber(BigNumber(totalCashCommission ?? 0).toFixed(2), { country })}`,
                    style: ['info'],
                },
            ],
            style: ['subData', { bold: true }],
        },
    ];
    const otherInfo = [
        {
            text: translate('Otros'),
            style: 'title',
        },
        {
            columns: [
                {
                    text: translate('Other income'),
                },
                {
                    text: `${formatAsCurrencyNumber(BigNumber(manualEarning ?? 0).toFixed(2), { country })}`,
                    style: 'info',
                },
            ],
            style: 'data',
        },
        {
            columns: [
                {
                    text: translate('Delivery cost paid by restaurant'),
                },
                {
                    text: `${formatAsCurrencyNumber(BigNumber(deliveryCostPaidByRestaurant ?? 0).toFixed(2))}`,
                    style: 'info',
                },
            ],
            style: 'data',
        },
        {
            columns: [
                {
                    text: translate('Promo Codes'),
                },
                {
                    text: `${formatAsCurrencyNumber(BigNumber(restaurantPromoCodeCostApp ?? 0).toFixed(2), { country })}`,
                    style: 'info',
                },
            ],
            style: 'data',
        },
        ...totalRappiCargoPaymentLink,
        ...rappiCardData,
        ...rappiCashData,
        ...totalUberDaasPaymentLink,
        ...uberCardData,
        ...uberCashData,
        {
            columns: [
                {
                    text: translate('Total Others'),
                },
                {
                    text: `${formatAsCurrencyNumber(totalOthers.toFixed(2), { country })}`,
                    style: ['info', { color: '#333333' }],
                },
            ],
            style: 'subtitle',
        },
    ];
    const summary = [
        {
            text: translate('Summary'),
            style: 'title',
        },
        {
            columns: [
                {
                    text: translate('Total Card'),
                },
                {
                    text: `${formatAsCurrencyNumber(BigNumber(totalCard ?? 0).toFixed(2), { country })}`,
                    style: 'info',
                },
            ],
            style: 'data',
        },
        {
            columns: [
                {
                    text: translate('Total Others'),
                },
                {
                    text: `${formatAsCurrencyNumber(totalOthers.toFixed(2), { country })}`,
                    style: 'info',
                },
            ],
            style: 'data',
        },
        {
            columns: [
                {
                    text: translate('Refunds'),
                },
                {
                    text: `${formatAsCurrencyNumber(BigNumber(Math.abs(manualEarningInvoice)).toFixed(2), { country })}`,
                    style: 'info',
                },
            ],
            style: 'data',
        },
        {
            columns: [
                {
                    text: translate('Total commissions'),
                },
                {
                    text: `-${formatAsCurrencyNumber(BigNumber(commission ?? 0).toFixed(2), { country })}`,
                    style: ['info', 'negative'],
                },
            ],
            style: 'data',
        },
        {
            columns: [
                {
                    text: translate('IVA on commissions'),
                },
                {
                    text: `-${formatAsCurrencyNumber(BigNumber(iva ?? 0).toFixed(2), { country })}`,
                    style: ['info', 'negative'],
                },
            ],
            style: 'subData',
        },
        {
            columns: [
                {
                    text: translate('Subscription'),
                },
                {
                    text: `-${formatAsCurrencyNumber(BigNumber(subscriptionPayPerUsePaymentInvoice).toFixed(2), { country })}`,
                    style: ['info', 'negative'],
                },
            ],
            style: 'data',
        },
        {
            columns: [
                {
                    text: translate('Cashback'),
                },
                {
                    text: `-${formatAsCurrencyNumber(BigNumber(cashback ?? 0).toFixed(2), { country })}`,
                    style: ['info', 'negative'],
                },
            ],
            style: 'data',
        },
        {
            columns: [
                {
                    text: translate('Amount to Deposit'),
                },
                {
                    text: `${formatAsCurrencyNumber(BigNumber(deposit ?? 0).toFixed(2), { country })}`,
                    style: ['info', { color: '#333333' }],
                },
            ],
            style: 'subtitle',
        },
        {
            columns: [
                {
                    text: translate('Total Cash'),
                },
                {
                    text: `${formatAsCurrencyNumber(BigNumber(totalCash ?? 0).toFixed(2), { country })}`,
                    style: 'info',
                },
            ],
            style: 'data',
        },
    ];

    const content = [dateSpan, { columns: [cardInfo, cashInfo] }, { columns: [otherInfo, summary] }];
    objectsToPdf({ content, headerInfo });
}

function calculateCommission(value: number, percentage: number) {
    return (value * percentage) / 100;
}

type Props = {
    restaurantBilling: RestaurantReportBillingDataVm;
    businessName?: string;
    addressOfEstablishment?: string;
    rfc?: string;
    from: Date;
    to: Date;
    country?: CountryCode;
};
