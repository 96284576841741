import { makeStyles } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import * as React from 'react';
import { useForm, useWatch } from 'react-hook-form';
import { Button } from 'src/components/Button';
import { Dialog } from 'src/components/Dialog';
import { Form } from 'src/components/form/Form';
import { FormIntegerFieldWithIncrementalButtons } from 'src/components/form/FormIntegerFieldWithIncrementalButtons';
import { FormQpayResponseResultSelect } from 'src/components/form/FormQpayResponseResultSelect';
import { FormQpayTerminalPaymentInstructionResultSelect } from 'src/components/form/FormQpayTerminalPaymentInstructionResultSelect';
import { QpayTerminalPaymentInstructionResults } from 'src/constants/QpayTerminalPaymentInstructionResult';
import { translate } from 'src/i18n/translate';
import { posReducer } from 'src/reducers/posReducer';
import { useAction } from 'src/utils/react/useAction';
import { useSelector } from 'src/utils/react/useSelector';

/**
 * @deprecated
 */
export function MockPaymentTerminalResponseDialog(): React.ReactElement {
    const classes = useStyles();
    const form = useForm();
    const {
        formState: {},
        control,
    } = form;

    const open = useSelector((state) => state.pos.mockPaymentTerminalResponseDialog.open);
    const onSelectPaymentTerminalResponse = useSelector((state) => state.pos.mockPaymentTerminalResponseDialog.onSelectPaymentTerminalResponse);
    const onCloseDialog = useSelector((state) => state.pos.mockPaymentTerminalResponseDialog.onCloseDialog);

    const closeMockPaymentTerminalResponseDialog = useAction(posReducer.actions.closeMockPaymentTerminalResponseDialog);

    const qpayTerminalPaymentInstructionResult = useWatch({ name: 'qpayTerminalPaymentInstructionResult', control });

    const handleClose = () => {
        onCloseDialog();
        closeMockPaymentTerminalResponseDialog();
    };

    const onSubmit = async (form: any) => {
        onSelectPaymentTerminalResponse({
            qpayTerminalInstructionResult: form.qpayTerminalPaymentInstructionResult,
            responseResult: form.responseResult,
            timeout: form.timeout ?? 0,
        });
        handleClose();
    };

    return (
        <Dialog title={translate('Select mocked payment behavior')} open={open} onClose={handleClose} classes={{ dialogContainer: classes.dialogContainer }}>
            <Form form={form} onSubmit={onSubmit}>
                <div>
                    <Grid container spacing={3} style={{ padding: '20px 40px' }}>
                        <Grid item xs={12} md={12}>
                            <FormQpayTerminalPaymentInstructionResultSelect name='qpayTerminalPaymentInstructionResult' label={translate('Terminal Payment Instruction Result')} required />
                        </Grid>
                    </Grid>
                    {qpayTerminalPaymentInstructionResult === QpayTerminalPaymentInstructionResults.INSTRUCTION_RECEIVED && (
                        <>
                            <Grid container spacing={3} style={{ padding: '20px 40px' }}>
                                <Grid item xs={12} md={12}>
                                    <FormQpayResponseResultSelect label={translate('Response Result')} name='responseResult' />
                                </Grid>
                            </Grid>
                            <Grid container spacing={3} style={{ padding: '20px 40px' }}>
                                <Grid item xs={12} md={12}>
                                    <FormIntegerFieldWithIncrementalButtons label={translate('Response Time')} text='secs' name='timeout' defaultValue={0} />
                                </Grid>
                            </Grid>
                        </>
                    )}
                </div>
                <div className={classes.buttonsContainer}>
                    <Button primary type={'submit'} classes={{ button: classes.button }}>
                        {translate('Create payment behavior')}
                    </Button>
                </div>
            </Form>
        </Dialog>
    );
}

const useStyles = makeStyles((theme) => ({
    form: {
        maxWidth: 540,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'left',
        padding: '16px 8px',
    },
    dialogContainer: {
        zIndex: 1001,
    },
    buttonsContainer: {
        display: 'flex',
        gap: 12,
        marginTop: 24,
        width: '100%',
        justifyContent: 'flex-end',
        alignItems: 'flex-end',
    },
    button: {
        fontSize: 14,
        padding: '12px 20px',
        fontFamily: theme.typography.medium,
    },
}));
