import { BigNumber } from 'bignumber.js';
import { UnitOfMeasurement } from 'src/constants/UnitOfMeasurement';
import { csvToObjects } from 'src/utils/csv/csvToObjects';
import { isEmpty } from 'src/utils/object/isEmpty';
import { removeNulls } from 'src/utils/removeNulls';
import { requireNumberString } from 'src/utils/require/requireNumberString';
import { requireStringNotBlank } from 'src/utils/require/requireStringNotBlank';
import { requireUnitOfMeasurement } from 'src/utils/require/requireUnitOfMeasurement';
import { requireValue } from 'src/utils/require/requireValue';
import { emptyStringToUndefined } from 'src/utils/string/emptyStringToUndefined';

export async function convertCsvToImportedSupplies(file: string): Promise<{ importedSupplies: Array<CsvSupplyItem>; invalidSupplies: Array<CsvRow> }> {
    const rows = await csvToObjects<CsvRow>(file);

    const importedSuppliesMap = new Map<string, CsvSupplyItem>();
    const invalidRows = [];
    for (const row of rows) {
        try {
            if (isEmptyRow(row)) {
                continue;
            }
            if (importedSuppliesMap.has(row.SKU)) {
                const importedSupply = requireValue(importedSuppliesMap.get(row.SKU));

                importedSuppliesMap.set(row.SKU, {
                    ...importedSupply,
                    buyUnits: [
                        ...importedSupply.buyUnits,
                        {
                            unit: requireUnitOfMeasurement(unitsOfMeasurementTranslation[row['UNIDAD_COMPRA']]),
                            conversionFactor: BigNumber(requireNumberString(row['FACTOR_CONVERSION'])).toNumber(),
                        },
                    ],
                });

                continue;
            }
            importedSuppliesMap.set(row.SKU, {
                name: requireStringNotBlank(row['NOMBRE']),
                supplyCategory: requireStringNotBlank(row['CATEGORIA']),
                supplier: emptyStringToUndefined(row['PROVEEDOR']),
                sellUnit: requireUnitOfMeasurement(unitsOfMeasurementTranslation[row['UNIDAD_VENTA']]),
                buyUnits: [
                    {
                        unit: requireUnitOfMeasurement(unitsOfMeasurementTranslation[row['UNIDAD_COMPRA']]),
                        conversionFactor: BigNumber(requireNumberString(row['FACTOR_CONVERSION'])).toNumber(),
                        isMainUnit: true,
                    },
                ],
                sku: requireStringNotBlank(row['SKU']),
                waste: emptyStringToUndefined(row['MERMA']),
                fixedCost: requireNumberString(row['COSTO']),
            });
        } catch (e) {
            invalidRows.push({
                CATEGORIA: row.CATEGORIA,
                PROVEEDOR: row.PROVEEDOR,
                NOMBRE: row.NOMBRE,
                UNIDAD_VENTA: row.UNIDAD_VENTA,
                UNIDAD_COMPRA: row.UNIDAD_COMPRA,
                SKU: row.SKU,
                MERMA: row.MERMA,
                COSTO: row.COSTO,
                FACTOR_CONVERSION: row.FACTOR_CONVERSION,
            });
        }
    }

    return { importedSupplies: Array.from(importedSuppliesMap.values()), invalidSupplies: invalidRows };
}

function isEmptyRow(row: CsvRow): boolean {
    const cleanedRow = removeNulls({
        name: emptyStringToUndefined(row['NOMBRE']),
        supplyCategory: emptyStringToUndefined(row['CATEGORIA']),
        supplier: emptyStringToUndefined(row['PROVEEDOR']),
        sellUnit: emptyStringToUndefined(row['UNIDAD_VENTA']),
        buyUnit: emptyStringToUndefined(row['UNIDAD_COMPRA']),
        sku: emptyStringToUndefined(row['SKU']),
        waste: emptyStringToUndefined(row['MERMA']),
        fixedCost: emptyStringToUndefined(row['COSTO']),
        factorConversion: emptyStringToUndefined(row['FACTOR_CONVERSION']),
    });

    return isEmpty(cleanedRow);
}

const unitsOfMeasurementTranslation: Record<string, UnitOfMeasurement> = {
    KILOGRAMO: 'KILOGRAM',
    GRAMO: 'GRAM',
    LITRO: 'LITRE',
    MILILITRO: 'MILLILITER',
    CAJA: 'BOX',
    BOLSA: 'BAG',
    PIEZA: 'PIECE',
    PAQUETE: 'PACKAGE',
    BARRA: 'STICK',
    ROLLO: 'ROLL',
    LATA: 'CAN',
    REBANADA: 'SLICE',
    LIBRA: 'POUND',
    ONZA: 'OUNCE',
    CUCHARADA: 'TABLESPOON',
    TAZA: 'CUP',
    GALON: 'GALLON',
    CUARTO: 'QUART',
    DIVISA: 'CURRENCY',
};

type CsvRow = {
    ['CATEGORIA']: string;
    ['PROVEEDOR']: string;
    ['NOMBRE']: string;
    ['UNIDAD_VENTA']: string;
    ['UNIDAD_COMPRA']: string;
    ['SKU']: string;
    ['MERMA']: string;
    ['COSTO']: string;
    ['FACTOR_CONVERSION']: string;
};

export type CsvSupplyItem = {
    supplyCategory: string;
    supplier?: string;
    name: string;
    sellUnit: UnitOfMeasurement;
    buyUnits: Array<{
        unit: UnitOfMeasurement;
        conversionFactor: number;
        isMainUnit?: boolean;
    }>;
    sku?: string;
    waste?: string;
    fixedCost?: string;
};
