import { letseatmanagerApiMethod } from 'src/api/letseatmanagerApi';
import type { ApiSauceResponse } from 'src/api/types/ApiSauceResponse';
import type { CommandSection } from 'src/constants/CommandSection';
import type { TicketSection } from 'src/constants/TicketSection';
import type { DeviceGroupVm } from 'src/types/DeviceGroupVm';
import type { DeviceVm } from 'src/types/DeviceVm';
import type { BrandId, BusinessId, ManagerUserId, RestaurantId } from 'src/types/Id';
import type { PaymentTerminalVm } from 'src/types/PaymentTerminalVm';
import type { PinCodeUser } from 'src/types/PinCodeUser';
import type { PrinterVm } from 'src/types/PrinterVm';
import type { RestaurantVm } from 'src/types/RestaurantVm';

export async function getAppContextApi(request: GetAppContextApiRequest): ApiSauceResponse<AppContextVm> {
    return letseatmanagerApiMethod('getAppContextApi', request);
}

export type GetAppContextApiRequest = {
    restaurantId: RestaurantId;
};

export type AppContextVm = {
    restaurant: RestaurantVm;
    currentEmployeesClockedIn?: Array<{
        managerUserId: ManagerUserId;
        clockedInAt: Date;
    }>;
    restaurants: Array<RestaurantContext>;
    brands?: Array<{
        brandId: BrandId;
        name: string;
        restaurantIds: Array<RestaurantId>;
    }>;
    business?: {
        businessId: BusinessId;
        name: string;
        restaurantIds: Array<RestaurantId>;
    };
    pinCodeUsers?: Array<PinCodeUser>;
    deviceGroup?: DeviceGroupVm;
    deviceGroups?: Array<DeviceGroupVm>;
    printers?: Array<PrinterVm>;
    devices?: Array<DeviceVm>;
    paymentTerminals?: Array<PaymentTerminalVm>;
};

export type RestaurantContext = {
    restaurantId: RestaurantId;
    name: string;
    avoidOpenCashBoxOnReprintTicketEnabled?: boolean;
    ticketSections: Array<TicketSection>;
    commandSections: Array<CommandSection>;
    increaseCommandFontSizeEnabled?: boolean;
    newOrdersPageEnabled?: boolean;
    numberOfCommandPrints?: number;
    numberOfTicketPrints?: number;
};
