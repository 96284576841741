import { makeStyles } from '@material-ui/core';
import * as React from 'react';
import { Button } from 'src/components/Button';
import { Dialog } from 'src/components/Dialog';
import { DialogActions } from 'src/components/DialogActions';
import { OrderCardDeprecated } from 'src/components/orderCard/OrderCardDeprecated';
import { translate } from 'src/i18n/translate';
import type { OrderVm } from 'src/types/OrderVm';

/** @deprecated */
export function DeprecatedOrderDetailsDialog({ open, order, handleClose, hideOptions }: Props): React.ReactElement | null {
    const classes = useStyles();

    if (!order) return null;

    return (
        <Dialog classes={{ dialog: classes.dialog }} title={translate('Order details')} open={open} onClose={handleClose}>
            <div>
                <OrderCardDeprecated order={order} onError={handleClose} onChange={handleClose} hideOptions={hideOptions} />
            </div>
            <DialogActions>
                <Button onClick={handleClose} secondary>
                    {translate('Close')}
                </Button>
            </DialogActions>
        </Dialog>
    );
}

const useStyles = makeStyles((theme) => ({
    dialog: {
        maxWidth: 500,
    },
}));

type Props = {
    open: boolean;
    order?: OrderVm;
    handleClose: any;
    hideOptions?: boolean;
};
