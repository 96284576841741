export const InitialRoutePaths = {
    RESTAURANT_DASHBOARD: 'RESTAURANT_DASHBOARD',
    ONGOING_ORDERS: 'ONGOING_ORDERS',
    ORDERS: 'ORDERS',
    POS: 'POS',
    POS_TABLES: 'POS_TABLES',
    POS_CASH_REGISTERS: 'POS_CASH_REGISTERS',
    THE_RESTAURANT: 'THE_RESTAURANT',
    THE_MENU: 'THE_MENU',
    INVENTORY: 'INVENTORY',
    DELIVERY_ZONES: 'DELIVERY_ZONES',
    ORDER_INVOICES: 'ORDER_INVOICES',
    PROMO_CODES: 'PROMO_CODES',
    PROMOTIONS: 'PROMOTIONS',
    SURVEYS: 'SURVEYS',
    PAYMENT_LINKS: 'PAYMENT_LINKS',
    MARKETING: 'MARKETING',
    ADVANCED_REPORTS: 'ADVANCED_REPORTS',
} as const;

export type InitialRoutePath = (typeof InitialRoutePaths)[keyof typeof InitialRoutePaths];
