import { makeStyles } from '@material-ui/core';
import * as React from 'react';
import { Button } from 'src/components/Button';
import { Chip } from 'src/components/Chip';
import { translate } from 'src/i18n/translate';
import { UserFilledIcon } from 'src/icons/UserFilledIcon';
import { OpenedCashRegisterPosBusinessDayVm } from 'src/types/OpenedCashRegisterPosBusinessDayVm';
import { classNames } from 'src/utils/react/classNames';

export function CashRegisterPosBusinessDayOption({ isSelected, cashRegisterPosBusinessDay, onClick }: Props): React.ReactElement {
    const classes = useStyles();

    return (
        <Button text classes={{ button: classNames(classes.cardContainer, { [classes.selected]: isSelected }) }} onClick={() => onClick(cashRegisterPosBusinessDay)}>
            <div className={classes.separatedText}>
                <h1 className={classes.cashRegisterName}>
                    {translate('Cash register @cashRegisterNumber', { cashRegisterNumber: cashRegisterPosBusinessDay?.cashRegisterPosBusinessDayNumber || '' })}
                </h1>
                <Chip classes={{ chip: classes.chip }}>{translate('Opened')}</Chip>
            </div>
            <div className={classes.contentText}>
                <UserFilledIcon height={18} width={18} />
                <div className={classes.subText}>{cashRegisterPosBusinessDay?.assignedToManagerUsername}</div>
            </div>
        </Button>
    );
}

const useStyles = makeStyles((theme) => ({
    cardContainer: {
        display: 'flex',
        flexDirection: 'column',
        height: 'fit-content',
        padding: '12px 24px',
        fontFamily: theme.typography.regular,
        fontWeight: 400,
        fontSize: 14,
        color: '#232933',
        borderRadius: 8,
        border: '1px solid #D9D9D9',
        cursor: 'pointer',
        width: 'unset',
        justifyContent: 'unset',
        alignItems: 'unset',
        '&:hover': {
            border: '1px solid #D9D9D9',
        },
    },
    chip: {
        height: 30,
        fontSize: 12,
        backgroundColor: '#CBF7E9',
        padding: '10px 20px',
        borderRadius: 8,
        color: '#024B49',
        fontFamily: theme.typography.regular,
    },
    separatedText: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
    },
    contentText: {
        display: 'flex',
        alignItems: 'center',
    },
    cashRegisterName: {
        fontSize: 16,
        fontWeight: 600,
    },
    subText: {
        marginLeft: 10,
    },
    closeCashRegisterChip: {
        backgroundColor: '#FBE1E9',
        color: '#DB3956',
    },
    selected: {
        backgroundColor: '#E6FBF1',
        border: '1px solid #06B7A2',
    },
}));

type Props = {
    isSelected: boolean;
    cashRegisterPosBusinessDay: OpenedCashRegisterPosBusinessDayVm;
    onClick: any;
};
