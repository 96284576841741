import Grid from '@material-ui/core/Grid';
import * as React from 'react';
import { useState } from 'react';
import { app2 } from 'src/app2';
import { translate } from 'src/i18n/translate';
import { BenefitPlanDetails } from 'src/scenes/letseatadmin/company/BenefitPlanDetails';
import Employees from 'src/scenes/letseatadmin/company/Employees';
import UnconnectedCustomers from 'src/scenes/letseatadmin/company/UnconnectedCustomers';
import type { BenefitPlanId } from 'src/types/Id';
import { useAction } from 'src/utils/react/useAction';
import { useParams } from 'src/utils/react/useParams';

export function BenefitPlanPage(): React.ReactElement {
    const { benefitPlanId } = useParams<{
        benefitPlanId: BenefitPlanId;
    }>();

    const [refresh, setRefresh] = useState(0);

    const setTitle = useAction(app2.actions.setTitle);

    const handleBenefitPlanChange = (benefitPlan: any) => setTitle(`${translate('Benefit Plans')} > ${benefitPlan.name}`);

    const handleCustomersConnected = () => setRefresh(refresh ? refresh + 1 : 1);

    return (
        <Grid container spacing={5} style={{ paddingBottom: 40 }}>
            <Grid item xs={12}>
                <BenefitPlanDetails benefitPlanId={benefitPlanId} onBenefitPlanChange={handleBenefitPlanChange} />
            </Grid>
            <Grid item xs={12}>
                <Employees benefitPlanId={benefitPlanId} refresh={refresh} />
            </Grid>
            <Grid item xs={12}>
                <UnconnectedCustomers benefitPlanId={benefitPlanId} onCustomersConnected={handleCustomersConnected} />
            </Grid>
        </Grid>
    );
}
