import List from '@material-ui/core/List';
import ListSubheader from '@material-ui/core/ListSubheader';
import * as React from 'react';
import { AppMenuLink } from 'src/components/app/appMenu/AppMenuLink';
import { DriverAppSettingsMenu } from 'src/components/app/appMenu/DriverAppSettingsMenu';
import { RoutePaths } from 'src/constants/RoutePath';
import { translate } from 'src/i18n/translate';
import { useSelector } from 'src/utils/react/useSelector';

export function OperationsAppMenu(): React.ReactElement | null {
    const operationsUser = useSelector((state) => state.authentication.operationsUser);

    if (!operationsUser) return null;

    return (
        <List subheader={<ListSubheader disableSticky>{translate('Operations')}</ListSubheader>}>
            <AppMenuLink routePath={RoutePaths.ADMIN_THIRD_PARTY_LOGISTICS_COVERAGE} text={translate('Third Party Logistics Coverage')} data-testid='adminAppMenu.thirdPartyLogisticsCoverageLink' />
            <AppMenuLink routePath={RoutePaths.ADMIN_LIVE_DRIVERS} text={translate('Live Drivers')} data-testid='driverAppMenu.liveDriversLink' />
            <AppMenuLink routePath={RoutePaths.ADMIN_QUICK_ACTIONS} text={translate('Quick Actions')} data-testid='driverAppMenu.quickActionsLink' />
            <AppMenuLink routePath={RoutePaths.ADMIN_SUPPORT} text={translate('Support')} data-testid='driverAppMenu.supportLink' />
            <AppMenuLink routePath={RoutePaths.ADMIN_RESTAURANT_BILLING} text={translate('Restaurant Billing')} data-testid='driverAppMenu.restaurantBillingLink' />
            <AppMenuLink routePath={RoutePaths.ADMIN_RESTAURANT_BILLING_UY} text={translate('Restaurant Billing UY')} data-testid='driverAppMenu.restaurantBillingLink' />
            <AppMenuLink routePath={RoutePaths.ADMIN_ONGOING_ORDERS} text={translate('Ongoing Orders')} data-testid='driverAppMenu.ongoingOrdersLink' />
            <AppMenuLink routePath={RoutePaths.ADMIN_ORDERS} text={translate('Orders')} data-testid='driverAppMenu.ordersLink' />
            <AppMenuLink routePath={RoutePaths.ADMIN_ORDER_INCIDENTS} text={translate('Order incidents')} data-testid='adminAppMenu.orderIncidentssLink' />
            <AppMenuLink routePath={RoutePaths.ADMIN_BRANDS} text={translate('Brands')} data-testid='driverAppMenu.brandsLink' />
            <AppMenuLink routePath={RoutePaths.ADMIN_RESTAURANTS} text={translate('Restaurants')} data-testid='driverAppMenu.restaurantsLink' />
            <AppMenuLink routePath={RoutePaths.ADMIN_RESTAURANTS_DETAILS} text={translate('Restaurants Details')} data-testid='driverAppMenu.restaurantsDetailsLink' />
            <AppMenuLink routePath={RoutePaths.ADMIN_CUSTOMERS} text={translate('Customers')} data-testid='driverAppMenu.customersLink' />
            <AppMenuLink routePath={RoutePaths.ADMIN_DRIVERS} text={translate('Drivers')} data-testid='driverAppMenu.driversLink' />
            <AppMenuLink routePath={RoutePaths.ADMIN_DRIVER_REFERRALS} text={translate('Driver Referrals')} data-testid='driverAppMenu.driverReferralsLink' />
            <AppMenuLink routePath={RoutePaths.ADMIN_DRIVER_BONUS_OFFERS} text={translate('Driver Bonus Offers')} data-testid='driverAppMenu.driverBonusOffersLink' />
            <AppMenuLink routePath={RoutePaths.ADMIN_ONGOING_DRIVER_BONUSES} text={translate('Ongoing Driver Bonuses')} data-testid='driverAppMenu.ongoingDriverBonusesLink' />
            <AppMenuLink routePath={RoutePaths.ADMIN_DRIVER_BILLING} text={translate('Driver Billing')} data-testid='driverAppMenu.driverBillingLink' />
            <AppMenuLink routePath={RoutePaths.ADMIN_DRIVER_BONUS_BILLING} text={translate('Driver Bonus Billing')} data-testid='driverAppMenu.driverBonusBillingLink' />
            <AppMenuLink routePath={RoutePaths.ADMIN_REQUEST_INTERNAL_REPORT} text={translate('Request internal report')} data-testid='adminAppMenu.requestInternalReportLink' />
            <AppMenuLink routePath={RoutePaths.ADMIN_SETTINGS} text={translate('Settings')} data-testid='driverAppMenu.settingsLink' />
            <AppMenuLink routePath={RoutePaths.ADMIN_RELEASES_NOTES} text={translate('Releases notes')} data-testid='adminAppMenu.releasesNotesLink' />
            <AppMenuLink routePath={RoutePaths.ADMIN_FAQ} text={translate('Faqs')} data-testid='driverAppMenu.faqsLink' />
            <AppMenuLink routePath={RoutePaths.ADMIN_LOG_EVENTS} text={translate('Log Events')} data-testid='adminAppMenu.adminLogEventsLink' />
            <AppMenuLink routePath={RoutePaths.ADMIN_DEMO_MODE_RESTAURANTS} text={translate('Demo Mode Restaurants')} />
            <DriverAppSettingsMenu />
        </List>
    );
}
