import * as React from 'react';
import { FormSelect } from 'src/components/form/FormSelect';
import type { Variant } from 'src/components/form/FormTypes';
import { TipTypes } from 'src/constants/TipType';
import { translate } from 'src/i18n/translate';

/**
 * @deprecated this uses the old FormSelect, for new forms use the library implementation instead
 */
export function DeprecatedFormTipTypeSelect({ name, label, placeholder, helperText, defaultValue, disabled, required, variant }: Props): React.ReactElement {
    const options = required
        ? Object.values(TipTypes).map((TipType) => ({ label: translate(TipType), value: TipType }))
        : [{ label: '', value: null }, ...Object.values(TipTypes).map((TipType) => ({ label: translate(TipType), value: TipType }))];
    return (
        <FormSelect
            name={name}
            label={label}
            placeholder={placeholder}
            helperText={helperText}
            defaultValue={defaultValue}
            options={options}
            disabled={disabled}
            required={required}
            variant={variant}
        />
    );
}

type Props = {
    name: string;
    label?: string;
    placeholder?: string;
    helperText?: string;
    defaultValue?: string | null | undefined;
    disabled?: boolean;
    required?:
        | boolean
        | {
              value: number;
              message: string;
          };
    variant?: Variant;
};
