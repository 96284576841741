import * as React from 'react';
import { RemoveDialog } from 'src/components/dialog/RemoveDialog';
import { translate } from 'src/i18n/translate';

export function NewDeviceManagementConfirmDialog({ open, onClose, onSuccess }: Props): React.ReactElement {
    return (
        <RemoveDialog
            open={open}
            title={translate('Printers and Terminals are going to be migrated')}
            subtitle={translate('In case you have terminals or printer configured you need yo reconfigure again in the new page')}
            cancelButtonText={translate('Cancel')}
            removeButtonText={translate('Yes, migrate')}
            onSubmit={onSuccess}
            onClose={onClose}
        />
    );
}

type Props = {
    open: boolean;
    onClose: any;
    onSuccess?: any;
};
