export const OrderStatuses = {
    NEW: 'NEW',
    CANCELLED: 'CANCELLED',
    REJECTED: 'REJECTED',
    ACCEPTED: 'ACCEPTED',
    CLOSED: 'CLOSED',
    COMPLETE: 'COMPLETE', // TODO: Remove OrderStatus.COMPLETE when changed to COMPLETED on server and db
    COMPLETED: 'COMPLETED',
    PENDING: 'PENDING',
    RETURNED: 'RETURNED',
    ARRIVED_AT_CLIENT: 'ARRIVED_AT_CLIENT',
} as const;

export type OrderStatus = (typeof OrderStatuses)[keyof typeof OrderStatuses];
