import IconButton from '@material-ui/core/IconButton';
import { makeStyles } from '@material-ui/core/styles';
import { CheckOutlined } from '@material-ui/icons';
import moment from 'moment';
import { useState } from 'react';
import * as React from 'react';
import { clockOutRestaurantEmployeeApi } from 'src/api/letseatmanager/employeeDayShift/clockOutRestaurantEmployeeApi';
import { Input } from 'src/components/Input';
import type { RestaurantEmployeeDayShiftVm } from 'src/types/RestaurantEmployeeDayShiftVm';
import { alertKnownErrorOrSomethingWentWrong } from 'src/utils/alert/alertKnownErrorOrSomethingWentWrong';
import { useClock } from 'src/utils/react/useClock';
import { useSelector } from 'src/utils/react/useSelector';

export function ClockOutEmployeeButton({ employeeDayShift, onChange }: Props): React.ReactElement {
    const classes = useStyles();

    const [, date] = useClock();

    const [loading, setLoading] = useState(false);
    const [clockedOutAt, setClockedOutAt] = useState(moment(date).format('lll'));
    const [isClockedIn, setIsClockedIn] = useState(true);

    const restaurantId = useSelector((state) => state.app.restaurantId);
    const pinCodeUserManagerUserId = useSelector((state) => state.authentication.pinCodeUserSignedIn?.managerUserId);

    const disabled = loading || !isClockedIn;

    const handleClockOutEmployee = async () => {
        setLoading(true);
        const response = await clockOutRestaurantEmployeeApi({
            restaurantId,
            managerUserId: employeeDayShift.managerUserId,
            clockOutBy: pinCodeUserManagerUserId,
            clockedOutAt: new Date(clockedOutAt),
        });
        setLoading(false);

        if (!response.ok) {
            alertKnownErrorOrSomethingWentWrong(response);
            return;
        }
        setIsClockedIn(false);
    };

    return (
        <div className={classes.inputContainer}>
            <Input
                defaultValue={clockedOutAt}
                disabled={loading}
                name={'clockedOutAt'}
                type={'datetime-local'}
                onChange={(value: any) => {
                    setClockedOutAt(value);
                    onChange?.(value);
                }}
            />
            <IconButton disabled={disabled} className={classes.iconButton} onClick={handleClockOutEmployee}>
                <CheckOutlined color={disabled ? 'secondary' : 'primary'} classes={{ root: classes.icon }} />
            </IconButton>
        </div>
    );
}

const useStyles = makeStyles((theme) => ({
    inputContainer: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
    },
    icon: {
        '&.MuiSvgIcon-colorPrimary': {
            color: theme.palette.icons.brand,
        },
        '&.MuiSvgIcon-colorSecondary': {
            color: theme.palette.icons.primary,
        },
    },
    iconButton: {
        margin: 0,
        padding: 0,
        marginLeft: 10,
    },
}));

type Props = {
    employeeDayShift: RestaurantEmployeeDayShiftVm;
    onChange?: any;
};
