import { letseatmanagerApiMethod } from 'src/api/letseatmanagerApi';
import type { ApiSauceResponse } from 'src/api/types/ApiSauceResponse';
import type { CourseId, RestaurantId } from 'src/types/Id';

export async function changeRestaurantCoursesApi(request: ChangeRestaurantCoursesApiRequest): ApiSauceResponse<void> {
    return letseatmanagerApiMethod('course/changeRestaurantCoursesApi', request);
}

export type ChangeRestaurantCoursesApiRequest = {
    restaurantId: RestaurantId;
    coursesIds: Array<CourseId>;
};
