import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import * as React from 'react';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { deprecatedChangeMenuCategoryApi } from 'src/api/letseatmanager/menuCategory/deprecatedChangeMenuCategoryApi';
import { pidedirectouploadApi } from 'src/api/pidedirectouploadApi';
import { app2 } from 'src/app2';
import { Button } from 'src/components/Button';
import { Dialog } from 'src/components/Dialog';
import { DialogActions } from 'src/components/DialogActions';
import { Form } from 'src/components/form/Form';
import { FormSwitch } from 'src/components/form/FormSwitch';
import { FormTextField } from 'src/components/form/FormTextField';
import ImageUploader from 'src/components/input/ImageUploader';
import { ImageTypes } from 'src/constants/ImageType';
import { translate } from 'src/i18n/translate';
import { alertKnownErrorOrSomethingWentWrong } from 'src/utils/alert/alertKnownErrorOrSomethingWentWrong';
import { isExternalImageUrl } from 'src/utils/image/isExternalImageUrl';
import { useAction } from 'src/utils/react/useAction';
import { useSelector } from 'src/utils/react/useSelector';
import { https } from 'src/utils/Validator';

export function ChangeMenuCategoryDialog(): React.ReactElement {
    const form = useForm();
    const { setValue } = form;
    const classes = useStyles();

    const [loading, setLoading] = useState(false);
    const [uploadingImage, setUploadingImage] = useState(false);

    const open = useSelector((state) => state.app2.changeMenuCategoryDialog.open);
    const category = useSelector((state) => state.app2.changeMenuCategoryDialog.category);
    const onSuccess = useSelector((state) => state.app2.changeMenuCategoryDialog.onSuccess);
    const brandMenuEnabled = useSelector((state) => state.app.restaurant.brandMenuEnabled);

    const closeChangeMenuCategoryDialog = useAction(app2.actions.closeChangeMenuCategoryDialog);

    const handleClose = () => {
        if (loading) return;
        closeChangeMenuCategoryDialog();
    };

    const getImageUrl = async (imageUrl: any) => {
        if (isExternalImageUrl(imageUrl)) {
            const migrateResponse = await pidedirectouploadApi.images.migrate({
                imageUrl: imageUrl,
                imageType: ImageTypes.MENU_CATEGORY,
            });
            if (!migrateResponse.ok) {
                setLoading(false);
                alertKnownErrorOrSomethingWentWrong(migrateResponse);
                return;
            }
            return migrateResponse.data;
        }
        return imageUrl;
    };

    const handleSubmit = async (form: any) => {
        setLoading(true);
        const imageUrl = await getImageUrl(form.imageUrl);
        const response = await deprecatedChangeMenuCategoryApi({
            menuCategoryId: category.menuCategoryId,
            name: form.name,
            hidden: form.hidden,
            promotion: form.promotion,
            imageUrl: imageUrl,
        });
        if (!response.ok) {
            setLoading(false);
            alertKnownErrorOrSomethingWentWrong(response);
            return;
        }
        setLoading(false);
        onSuccess();
        closeChangeMenuCategoryDialog();
    };

    return (
        <Dialog title={translate('Change Category')} open={open} onClose={handleClose}>
            <Form onSubmit={handleSubmit} form={form} className={classes.form}>
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <FormTextField name='name' label={translate('Name')} required disabled={loading} defaultValue={category?.name} />
                    </Grid>
                    {brandMenuEnabled && (
                        <Grid item xs={12}>
                            <FormTextField
                                name='imageUrl'
                                label={uploadingImage ? translate('Uploading Image...') : translate('Image')}
                                helperText={
                                    uploadingImage
                                        ? undefined
                                        : translate(
                                              'Enter an URL to an image or drag and drop your image anywhere in the browser. For best result make sure image is 1280x854 pixels or at least similar aspect ratio and not lower resolution.',
                                          )
                                }
                                validate={https}
                                defaultValue={category?.imageUrl}
                            />
                            <ImageUploader
                                imageType={ImageTypes.MENU_CATEGORY}
                                onUploadingChanged={(uploadingImage) => setUploadingImage(uploadingImage)}
                                onUploadSuccess={(imageUrl) => setValue('imageUrl', imageUrl)}
                                dimensions={undefined}
                            />
                        </Grid>
                    )}
                    <Grid item xs={12} md={6}>
                        <FormSwitch name='hidden' disabled={loading} label={translate('Hidden')} defaultValue={category?.hidden} />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <FormSwitch name='promotion' disabled={loading} label={translate('Promotion')} defaultValue={category?.promotion} />
                    </Grid>
                </Grid>
                <DialogActions className={(classes as any).buttonsContainer}>
                    <Button secondary onClick={handleClose} disabled={loading}>
                        {translate('Cancel').toUpperCase()}
                    </Button>
                    <Button type='submit' disabled={loading}>
                        {loading ? translate('Changing').toUpperCase() : translate('Change').toUpperCase()}
                    </Button>
                </DialogActions>
            </Form>
        </Dialog>
    );
}

const useStyles = makeStyles((theme) => ({
    form: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
}));
