import { makeStyles } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { useForm, useWatch } from 'react-hook-form';
import { changeDeliveryAddressApi } from 'src/api/letseatmanager/order/changeDeliveryAddressApi';
import { getDeliveryEstimateApi } from 'src/api/letseatmanager/order/getDeliveryEstimateApi';
import { DeliveryEstimate } from 'src/api/types/DeliveryEstimate';
import { app2 } from 'src/app2';
import { Button } from 'src/components/Button';
import { Dialog } from 'src/components/Dialog';
import { ConfirmDeliveryAddressDialog } from 'src/components/dialog/delivery/ConfirmDeliveryAddressDialog';
import { DialogActions } from 'src/components/DialogActions';
import { Form } from 'src/components/form/Form';
import { FormAddress } from 'src/components/form/FormAddress';
import { FormDeliveryAddressAutocompleteWithMap } from 'src/components/form/FormDeliveryAddressAutocompleteWithMap';
import { FormTextField } from 'src/components/form/FormTextField';
import { translate } from 'src/i18n/translate';
import { useFormatAsRestaurantCurrencyNumber } from 'src/services/restaurant/useFormatAsRestaurantCurrencyNumber';
import type { GeoJsonPoint } from 'src/types/GeoJsonPoint';
import type { OrderVm } from 'src/types/OrderVm';
import { alertKnownErrorOrSomethingWentWrong } from 'src/utils/alert/alertKnownErrorOrSomethingWentWrong';
import { isCardPayment } from 'src/utils/paymentMethod/isCardPayment';
import { isPaymentLinkPayment } from 'src/utils/paymentMethod/isPaymentLinkPayment';
import { useAction } from 'src/utils/react/useAction';
import { useSelector } from 'src/utils/react/useSelector';
import { requireValue } from 'src/utils/require/requireValue';

export function ChangeDeliveryAddressDialog({ open, order, onClose, onSuccess }: Props): React.ReactElement {
    const classes = useStyles();
    const formatAsCurrencyNumber = useFormatAsRestaurantCurrencyNumber();

    const [loading, setLoading] = useState(false);
    const [address, setAddress] = useState('');
    const [deliveryLocation, setDeliveryLocation] = useState<GeoJsonPoint | undefined>(undefined);
    const [deliveryEstimate, setDeliveryEstimate] = useState<DeliveryEstimate | undefined>(undefined);
    const [confirmAddressDialogState, setConfirmAddressDialogState] = useState<{ open: boolean; onConfirmAddress: undefined | (() => Promise<void>) }>({ open: false, onConfirmAddress: undefined });

    const restaurant = useSelector((state) => state.app.restaurant);
    const updateOrder = useAction(app2.actions.updateOrder);

    const form = useForm();
    const { control } = form;

    const q = useWatch<any>({ name: 'q', control, defaultValue: '' });
    const street = useWatch({ name: 'street', control, defaultValue: '' });
    const number = useWatch({ name: 'number', control, defaultValue: '' });

    const useNonGeoLocatedDeliveryAddressesEnabled = restaurant.useNonGeoLocatedDeliveryAddressesEnabled;
    const orderIsCreditCardOrPaymentLink = isCardPayment(order.paymentMethod) || isPaymentLinkPayment(order.paymentMethod);
    const deliveryEstimateIsGreaterThanDeliveryCost = parseInt(deliveryEstimate?.deliveryCost ?? '0') > parseInt(order.deliveryCost ?? '0');
    const shouldShowErrorMessage = deliveryEstimateIsGreaterThanDeliveryCost && orderIsCreditCardOrPaymentLink;
    const disabled = loading || !deliveryEstimate?.isWithinDeliveryRadius || shouldShowErrorMessage || !street || !number;

    useEffect(() => {
        if (deliveryLocation) {
            fetchDeliveryEstimate(deliveryLocation);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [deliveryLocation]);

    useEffect(() => {
        if (address) setDeliveryLocation(q.location);
    }, [address, q]);

    const onSubmit = async (form: any) => {
        if (loading) return;
        if (!deliveryEstimate && !useNonGeoLocatedDeliveryAddressesEnabled) return;

        setConfirmAddressDialogState({ open: true, onConfirmAddress: () => handleSubmitConfirmAddress(form) });
    };

    const handleSubmitConfirmAddress = async (form: any) => {
        const request = {
            orderId: order.orderId,
            deliveryEstimateId: !useNonGeoLocatedDeliveryAddressesEnabled && deliveryEstimate ? deliveryEstimate.deliveryEstimateId : undefined,
            address: {
                location: requireValue(form.q).location,
                street: form.street,
                number: form.number,
                other: form.other,
                instructions: form.instructions,
                googlePlaceId: requireValue(form.q).googlePlaceId,
                formattedAddress: requireValue(form.q).formattedAddress,
            },
        } as const;
        setLoading(true);

        const response = await changeDeliveryAddressApi(request);

        setLoading(false);

        if (!response.ok) {
            alertKnownErrorOrSomethingWentWrong(response);
            return;
        }

        updateOrder({ order });
        setConfirmAddressDialogState({ open: false, onConfirmAddress: undefined });
        onClose();
        onSuccess?.();
    };

    async function fetchDeliveryEstimate(deliveryLocation: GeoJsonPoint) {
        setLoading(true);
        const response = await getDeliveryEstimateApi({ restaurantId: restaurant.restaurantId, deliveryLocation: deliveryLocation });
        setLoading(false);
        if (!response.ok) {
            setDeliveryEstimate(undefined);
            return;
        }
        setDeliveryEstimate(response.data);
    }

    return (
        <Dialog classes={{ dialog: classes.dialog }} open={open} title={translate('Change the delivery address')}>
            <Form onSubmit={onSubmit} form={form} className={classes.form}>
                <ConfirmDeliveryAddressDialog
                    open={confirmAddressDialogState.open}
                    onClose={() => setConfirmAddressDialogState({ open: false, onConfirmAddress: undefined })}
                    loading={loading}
                    customerAddress={{ street, number }}
                    onConfirmAddress={confirmAddressDialogState.onConfirmAddress}
                />
                <div>
                    <p className={classes.price}>{translate('Current delivery cost @deliveryCost', { deliveryCost: formatAsCurrencyNumber(order.deliveryCost) })}</p>
                    {deliveryEstimate?.deliveryCost && (
                        <p className={classes.price}>{translate('Delivery cost for new address @deliveryEstimate', { deliveryEstimate: formatAsCurrencyNumber(deliveryEstimate?.deliveryCost) })}</p>
                    )}
                </div>
                {shouldShowErrorMessage && (
                    <p className={classes.errorMessage}>
                        {translate('Delivery address cannot be changed because new estimate delivery cost is higher than the previous delivery cost and the selected payment method is credit card')}
                    </p>
                )}
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <p className={classes.sectionTitle}>{translate('Client details')}</p>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <FormTextField name='street' label={translate('Street')} disabled={loading} defaultValue={order.address?.street} required />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <FormTextField name='number' label={translate('Number')} disabled={loading} defaultValue={order.address?.number} required />
                    </Grid>
                    <Grid item xs={6} md={6}>
                        <FormTextField name='other' label={translate('Staircase/apartment/floor/other')} disabled={loading} defaultValue={order.address?.other} />
                    </Grid>
                    {useNonGeoLocatedDeliveryAddressesEnabled && (
                        <Grid item xs={12}>
                            <FormAddress name='q' label={translate('Find location with Google Maps')} disabled={loading} required fullWidth />
                        </Grid>
                    )}
                    {!useNonGeoLocatedDeliveryAddressesEnabled && (
                        <Grid item xs={12}>
                            <FormDeliveryAddressAutocompleteWithMap
                                name='q'
                                label={translate('Find location with Google Maps')}
                                restaurant={restaurant}
                                disabled={loading}
                                required
                                fullWidth
                                onChangeInput={(e: any) => setAddress(e.target.value)}
                                inputValue={address}
                                setInputValue={setAddress}
                                deliveryEstimate={deliveryEstimate}
                            />
                        </Grid>
                    )}
                </Grid>
                <DialogActions>
                    <Button onClick={onClose} secondary disabled={loading}>
                        {translate('Cancel')}
                    </Button>
                    <Button disabled={disabled} type={'submit'}>
                        {translate('Change')}
                    </Button>
                </DialogActions>
            </Form>
        </Dialog>
    );
}

const useStyles = makeStyles((theme) => ({
    form: {
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        position: 'relative',
    },
    dialog: {
        padding: 30,
        [theme.breakpoints.up('md')]: {
            maxWidth: 700,
        },
    },
    row: {
        display: 'flex',
        width: '100%',
        gap: 12,
    },
    sectionTitle: {
        color: '#0D3037',
        fontSize: 16,
        fontFamily: theme.typography.semiBold,
        margin: 0,
        marginTop: 12,
    },
    errorMessage: {
        fontFamily: theme.typography.regular,
        color: theme.palette.error.main,
        fontSize: 14,
        textAlign: 'center',
    },
    price: {
        textAlign: 'center',
        fontFamily: theme.typography.medium,
        margin: '8px 0',
        color: '#232933',
    },
}));

type Props = {
    open: boolean;
    order: OrderVm;
    onClose: any;
    onSuccess?: any;
};
