import * as React from 'react';
import { Route, Switch } from 'react-router-dom';
import { CustomAlertDialogHandler } from 'src/components/dialog/CustomAlertDialogHandler';
import { CancelDeliveryDialog } from 'src/components/dialog/delivery/CancelDeliveryDialog';
import { CancelDeliveryManagerDialog } from 'src/components/dialog/delivery/CancelDeliveryManagerDialog';
import { CompleteDeliveryManagerDialog } from 'src/components/dialog/delivery/CompleteDeliveryManagerDialog';
import { RestartDeliveryManagerDialog } from 'src/components/dialog/delivery/RestartDeliveryManagerDialog';
import ChangePickupStationsDialog from 'src/components/dialog/restaurant/ChangePickupStationsDialog';
import { RoutePaths } from 'src/constants/RoutePath';
import { AdminLogEventsPage } from 'src/scenes/letseatadmin/AdminLogEventsPage';
import { AlertDialogsPage } from 'src/scenes/letseatadmin/AlertDialogsPage';
import { BannersPage } from 'src/scenes/letseatadmin/BannersPage';
import { BenefitPlanPage } from 'src/scenes/letseatadmin/BenefitPlanPage';
import { BrandPage } from 'src/scenes/letseatadmin/BrandPage';
import { BrandsPage } from 'src/scenes/letseatadmin/BrandsPage';
import { BusinessesPage } from 'src/scenes/letseatadmin/BusinessesPage';
import { CompaniesPage } from 'src/scenes/letseatadmin/CompaniesPage';
import { CompanyCreditsBillingPage } from 'src/scenes/letseatadmin/CompanyCreditsBillingPage';
import { CompanyPage } from 'src/scenes/letseatadmin/CompanyPage';
import { CompanyPayrollBillingPage } from 'src/scenes/letseatadmin/CompanyPayrollBillingPage';
import { AdminCurrentOrders } from 'src/scenes/letseatadmin/currentOrders/AdminCurrentOrders';
import { CustomerPage } from 'src/scenes/letseatadmin/CustomerPage';
import { CustomersPage } from 'src/scenes/letseatadmin/CustomersPage';
import { DeliveryMonitoringPage } from 'src/scenes/letseatadmin/DeliveryMonitoringPage';
import { DemoRestaurantsPage } from 'src/scenes/letseatadmin/DemoRestaurantsPage';
import { DriverBannersPage } from 'src/scenes/letseatadmin/DriverBannersPage';
import { DriverBillingPage } from 'src/scenes/letseatadmin/DriverBillingPage';
import { DriverBonusBillingPage } from 'src/scenes/letseatadmin/DriverBonusBillingPage';
import { DriverBonusOffersPage } from 'src/scenes/letseatadmin/DriverBonusOffersPage';
import { DriverPage } from 'src/scenes/letseatadmin/DriverPage';
import { DriverReferralsPage } from 'src/scenes/letseatadmin/DriverReferralsPage';
import { DriversPage } from 'src/scenes/letseatadmin/DriversPage';
import { EmailsPage } from 'src/scenes/letseatadmin/EmailsPage';
import ChangeEmailTemplateDialog from 'src/scenes/letseatadmin/emailTemplates/ChangeEmailTemplateDialog';
import CreateEmailTemplateDialog from 'src/scenes/letseatadmin/emailTemplates/CreateEmailTemplateDialog';
import { EmailTemplatesPage } from 'src/scenes/letseatadmin/EmailTemplatesPage';
import { EmployeePage } from 'src/scenes/letseatadmin/EmployeePage';
import { FaqsPage } from 'src/scenes/letseatadmin/FaqsPage';
import { KitchenBillingPage } from 'src/scenes/letseatadmin/KitchenBillingPage';
import { LiveDriversPage } from 'src/scenes/letseatadmin/LiveDriversPage';
import { ManagerUserPage } from 'src/scenes/letseatadmin/ManagerUserPage';
import { ManagerUsersPage } from 'src/scenes/letseatadmin/ManagerUsersPage';
import { Monitoring } from 'src/scenes/letseatadmin/monitoring/Monitoring';
import { OfficeDeliveryPage } from 'src/scenes/letseatadmin/OfficeDeliveryPage';
import { OngoingDriverBonusesPage } from 'src/scenes/letseatadmin/OngoingDriverBonusesPage';
import { OrderIncidentsPage } from 'src/scenes/letseatadmin/OrderIncidentsPage';
import { OrderPage } from 'src/scenes/letseatadmin/OrderPage';
import { OrdersPage } from 'src/scenes/letseatadmin/OrdersPage';
import { OverallStatsPage } from 'src/scenes/letseatadmin/OverallStatsPage';
import { PickupStationsPage } from 'src/scenes/letseatadmin/PickupStationsPage';
import { PromoCodesPage } from 'src/scenes/letseatadmin/PromoCodesPage';
import { QuickActionsPage } from 'src/scenes/letseatadmin/QuickActionsPage';
import { RappiIntegrationsPage } from 'src/scenes/letseatadmin/RappiIntegrationsPage';
import { ReleasesNotesPage } from 'src/scenes/letseatadmin/ReleasesNotesPage';
import { RequestInternalReportPage } from 'src/scenes/letseatadmin/RequestInternalReportPage';
import { RestaurantBillingPage } from 'src/scenes/letseatadmin/RestaurantBillingPage';
import { RestaurantBillingPageUy } from 'src/scenes/letseatadmin/RestaurantBillingPageUy';
import { RestaurantReferralsPage } from 'src/scenes/letseatadmin/RestaurantReferralsPage';
import { RestaurantSalesPage } from 'src/scenes/letseatadmin/RestaurantSalesPage';
import { ChangeRestaurantDetailsDialog } from 'src/scenes/letseatadmin/restaurantsDetails/change-restaurant-details-dialog/ChangeRestaurantDetailsDialog';
import { RestaurantsDetails } from 'src/scenes/letseatadmin/restaurantsDetails/RestaurantsDetails';
import { RestaurantSelfRegistrationsPage } from 'src/scenes/letseatadmin/RestaurantSelfRegistrationsPage';
import { RestaurantsPage } from 'src/scenes/letseatadmin/RestaurantsPage';
import { RestaurantVideosPage } from 'src/scenes/letseatadmin/RestaurantVideosPage';
import ChangeScheduledTaskDialog from 'src/scenes/letseatadmin/scheduledTasks/ChangeScheduledTaskDialog';
import CreateScheduledTaskDialog from 'src/scenes/letseatadmin/scheduledTasks/CreateScheduledTaskDialog';
import { ScheduledTasksPage } from 'src/scenes/letseatadmin/ScheduledTasksPage';
import { SettingsPage } from 'src/scenes/letseatadmin/SettingsPage';
import { StatisticsOldPage } from 'src/scenes/letseatadmin/StatisticsOldPage';
import { StatisticsPage } from 'src/scenes/letseatadmin/StatisticsPage';
import { SubscriptionHistoriesPage } from 'src/scenes/letseatadmin/SubscriptionHistoriesPage';
import { SubscriptionHistoryPage } from 'src/scenes/letseatadmin/SubscriptionHistoryPage';
import { SubscriptionPage } from 'src/scenes/letseatadmin/SubscriptionPage';
import { SubscriptionPayPerUsePaymentsPage } from 'src/scenes/letseatadmin/SubscriptionPayPerUsePaymentsPage';
import { SubscriptionsPage } from 'src/scenes/letseatadmin/SubscriptionsPage';
import { SupportPage } from 'src/scenes/letseatadmin/SupportPage';
import { SupportPhoneNumbersPage } from 'src/scenes/letseatadmin/SupportPhoneNumbersPage';
import { SurveyPage } from 'src/scenes/letseatadmin/SurveyPage';
import { SurveysPage } from 'src/scenes/letseatadmin/SurveysPage';
import { ThirdPartyLogisticsCoveragePage } from 'src/scenes/letseatadmin/ThirdPartyLogisticsCoveragePage';
import { CopyRestaurantMenuDialog } from 'src/scenes/letseatmanager/deprecatedMenu/CopyRestaurantMenuDialog';
import { CreateRestaurantMenuPromotionDialog } from 'src/scenes/letseatmanager/deprecatedMenu/CreateRestaurantMenuPromotionDialog';
import { ImportRestaurantMenuDialog } from 'src/scenes/letseatmanager/deprecatedMenu/ImportRestaurantMenuDialog';
import { SuspiciousOrdersPage } from 'src/scenes/letseatmanager/SuspiciousOrdersPage';

export function AppRouteAdmin(): React.ReactElement {
    return (
        <>
            <CancelDeliveryDialog />
            <RestartDeliveryManagerDialog />
            <CompleteDeliveryManagerDialog />
            <CancelDeliveryManagerDialog />
            <ChangePickupStationsDialog />
            <CreateScheduledTaskDialog />
            <ChangeScheduledTaskDialog />
            <CreateEmailTemplateDialog />
            <ChangeEmailTemplateDialog />
            <ChangeRestaurantDetailsDialog />
            <CopyRestaurantMenuDialog />
            <ImportRestaurantMenuDialog />
            <CreateRestaurantMenuPromotionDialog />
            <CustomAlertDialogHandler />

            <Route path={RoutePaths.ADMIN_MONITORING} component={Monitoring} />
            <Route path={RoutePaths.ADMIN_SUPPORT} component={SupportPage} />
            <Route path={RoutePaths.ADMIN_SUPPORT_PHONE_NUMBERS} component={SupportPhoneNumbersPage} />
            <Route path={RoutePaths.ADMIN_RESTAURANT_REFERRALS} component={RestaurantReferralsPage} />
            <Route path={RoutePaths.ADMIN_DRIVER_REFERRALS} component={DriverReferralsPage} />
            <Route path={RoutePaths.ADMIN_OVERALL_STATS} component={OverallStatsPage} />
            <Route path={RoutePaths.ADMIN_RESTAURANT_SALES} component={RestaurantSalesPage} />
            <Route path={RoutePaths.ADMIN_DELIVERY_MONITORING} component={DeliveryMonitoringPage} />
            <Route path={RoutePaths.ADMIN_RAPPI_INTEGRATIONS} component={RappiIntegrationsPage} />
            <Route path={RoutePaths.ADMIN_QUICK_ACTIONS} component={QuickActionsPage} />
            <Route path={RoutePaths.ADMIN_DRIVERAPP_SETTINGS} component={DriverBannersPage} />

            {/*<Switch>*/}
            {/*<Route path={RoutePath.ADMIN_DELIVERY_MONITORING} exact component={DeliveryMonitoringPage} />*/}
            {/*<Route path={RoutePath.ADMIN_DELIVERY_MANAGER} component={DeliveryManagerPage} />*/}
            {/*</Switch>*/}
            <Route path={RoutePaths.ADMIN_STATISTICS} component={StatisticsPage} />
            <Route path={RoutePaths.ADMIN_STATISTICS_OLD} component={StatisticsOldPage} />
            <Switch>
                <Route path={[RoutePaths.ADMIN_SURVEYS, RoutePaths.ADMIN_SURVEYS_RESTAURANTS]} exact component={SurveysPage} />
                <Route path={RoutePaths.ADMIN_SURVEY} component={SurveyPage} />
            </Switch>
            <Route path={RoutePaths.ADMIN_ONGOING_ORDERS} component={AdminCurrentOrders} />
            <Switch>
                <Route path={RoutePaths.ADMIN_ORDERS} exact component={OrdersPage} />
                <Route path={RoutePaths.ADMIN_ORDER} component={OrderPage} />
            </Switch>
            <Route path={RoutePaths.ADMIN_ORDER_INCIDENTS} component={OrderIncidentsPage} />
            <Route path={RoutePaths.ADMIN_SUSPICIOUS_ORDERS} component={SuspiciousOrdersPage} />
            <Route path={RoutePaths.ADMIN_OFFICE_DELIVERY} component={OfficeDeliveryPage} />
            <Route path={RoutePaths.ADMIN_PICKUP_STATIONS} component={PickupStationsPage} />
            <Route path={RoutePaths.ADMIN_BUSINESSES} component={BusinessesPage} />
            <Switch>
                <Route path={RoutePaths.ADMIN_BRAND} component={BrandPage} />
                <Route path={RoutePaths.ADMIN_BRANDS} component={BrandsPage} />
            </Switch>
            <Route path={RoutePaths.ADMIN_RESTAURANTS} component={RestaurantsPage} />
            <Route path={RoutePaths.ADMIN_RESTAURANTS_DETAILS} component={RestaurantsDetails} />
            <Route path={RoutePaths.ADMIN_RESTAURANT_REGISTERS} component={RestaurantsDetails} />
            <Switch>
                <Route path={RoutePaths.ADMIN_MANAGER_USER} component={ManagerUserPage} />
                <Route path={RoutePaths.ADMIN_MANAGER_USERS} component={ManagerUsersPage} />
            </Switch>
            <Switch>
                <Route path={RoutePaths.ADMIN_CUSTOMER} component={CustomerPage} />
                <Route path={RoutePaths.ADMIN_CUSTOMERS} component={CustomersPage} />
            </Switch>
            <Switch>
                <Route path={RoutePaths.ADMIN_DRIVER} component={DriverPage} />
                <Route path={RoutePaths.ADMIN_DRIVERS} component={DriversPage} />
            </Switch>
            <Route path={RoutePaths.ADMIN_DRIVER_BONUS_OFFERS} component={DriverBonusOffersPage} />
            <Route path={RoutePaths.ADMIN_ONGOING_DRIVER_BONUSES} component={OngoingDriverBonusesPage} />
            <Route path={RoutePaths.ADMIN_PROMO_CODES} component={PromoCodesPage} />
            <Route path={RoutePaths.ADMIN_BANNERS} component={BannersPage} />
            <Route path={RoutePaths.ADMIN_SCHEDULED_TASKS} component={ScheduledTasksPage} />
            <Route path={RoutePaths.ADMIN_EMAIL_TEMPLATES} component={EmailTemplatesPage} />
            <Route path={RoutePaths.ADMIN_EMAILS} component={EmailsPage} />
            <Route path={RoutePaths.ADMIN_FAQ} component={FaqsPage} />
            <Route path={RoutePaths.ADMIN_CUSTOM_ALERT_DIALOG} component={AlertDialogsPage} />
            <Switch>
                <Route path={RoutePaths.ADMIN_COMPANY} component={CompanyPage} />
                <Route path={RoutePaths.ADMIN_COMPANIES} component={CompaniesPage} />
            </Switch>
            <Route path={RoutePaths.ADMIN_BENEFIT_PLAN} component={BenefitPlanPage} />
            <Route path={RoutePaths.ADMIN_EMPLOYEE} component={EmployeePage} />
            <Switch>
                <Route path={RoutePaths.ADMIN_SUBSCRIPTION} component={SubscriptionPage} />
                <Route path={RoutePaths.ADMIN_SUBSCRIPTIONS} component={SubscriptionsPage} />
            </Switch>
            <Switch>
                <Route path={RoutePaths.ADMIN_SUBSCRIPTION_HISTORIES} component={SubscriptionHistoriesPage} />
                <Route path={RoutePaths.ADMIN_SUBSCRIPTION_HISTORY} component={SubscriptionHistoryPage} />
            </Switch>
            <Route path={RoutePaths.ADMIN_SUBSCRIPTION_PAYMENT_PAGE} component={SubscriptionPayPerUsePaymentsPage} />
            <Route path={RoutePaths.ADMIN_RESTAURANT_BILLING} component={RestaurantBillingPage} />
            <Route path={RoutePaths.ADMIN_RESTAURANT_BILLING_UY} component={RestaurantBillingPageUy} />
            <Route path={RoutePaths.ADMIN_DRIVER_BILLING} component={DriverBillingPage} />
            <Route path={RoutePaths.ADMIN_DRIVER_BONUS_BILLING} component={DriverBonusBillingPage} />
            <Route path={RoutePaths.ADMIN_KITCHEN_BILLING} component={KitchenBillingPage} />
            <Route path={RoutePaths.ADMIN_COMPANY_CREDITS_BILLING} component={CompanyCreditsBillingPage} />
            <Route path={RoutePaths.ADMIN_COMPANY_PAYROLL_BILLING} component={CompanyPayrollBillingPage} />
            <Route path={RoutePaths.RESTAURANT_VIDEOS} component={RestaurantVideosPage} />
            <Route path={RoutePaths.ADMIN_SETTINGS} component={SettingsPage} />
            <Route path={RoutePaths.ADMIN_LIVE_DRIVERS} component={LiveDriversPage} />
            <Route path={RoutePaths.ADMIN_THIRD_PARTY_LOGISTICS_COVERAGE} component={ThirdPartyLogisticsCoveragePage} />
            <Route path={RoutePaths.ADMIN_DEMO_MODE_RESTAURANTS} component={DemoRestaurantsPage} />
            <Route path={RoutePaths.ADMIN_RESTAURANT_SELF_REGISTRATIONS} component={RestaurantSelfRegistrationsPage} />
            <Route path={RoutePaths.ADMIN_LOG_EVENTS} component={AdminLogEventsPage} />
            <Route path={RoutePaths.ADMIN_RELEASES_NOTES} component={ReleasesNotesPage} />
            <Route path={RoutePaths.ADMIN_REQUEST_INTERNAL_REPORT} component={RequestInternalReportPage} />
        </>
    );
}
