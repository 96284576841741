import { letseatadminApiMethod } from 'src/api/letseatadminApi';
import type { ApiSauceResponse } from 'src/api/types/ApiSauceResponse';
import type { QuickActionId } from 'src/types/Id';

export async function disableQuickActionApi(request: DisableQuickActionApiRequest): ApiSauceResponse<void> {
    return letseatadminApiMethod('quickAction/disableQuickActionApi', request);
}

type DisableQuickActionApiRequest = {
    quickActionId: QuickActionId;
};
