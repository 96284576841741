import { useEffect, useState } from 'react';
import * as React from 'react';
import { findSuppliersByRestaurantIdApi } from 'src/api/letseatmanager/supplier/findSuppliersByRestaurantIdApi';
import { FormAutocomplete } from 'src/components/form/FormAutocomplete';
import type { Variant } from 'src/components/form/FormTypes';
import type { SupplierId } from 'src/types/Id';
import { useLoadApi } from 'src/utils/react/useLoadApi';
import { useSelector } from 'src/utils/react/useSelector';

export function FormSupplierAutocomplete({ name, label, placeholder, helperText, defaultValue, disabled, required, variant, onChange, filterBySupplierIds }: Props): React.ReactElement {
    const [options, setOptions] = useState([]);

    const restaurantId = useSelector((state) => state.app.restaurantId);

    const [loadingSuppliers, { suppliers }] = useLoadApi(findSuppliersByRestaurantIdApi, { restaurantId }, { initialValue: { suppliers: [] } });

    useEffect(() => {
        if (filterBySupplierIds) {
            const suppliersFiltered = suppliers.filter((supplier) => filterBySupplierIds.includes(supplier.supplierId));
            const options = required
                ? suppliersFiltered.map((supplier) => ({ label: supplier.businessName, value: supplier.supplierId }))
                : [{ label: '', value: null }, ...suppliersFiltered.map((supplier) => ({ label: supplier.businessName, value: supplier.supplierId }))];
            setOptions(options as any);
        }
    }, [filterBySupplierIds, suppliers]);

    useEffect(() => {
        const options = required
            ? suppliers.map((supplier) => ({ label: supplier.businessName, value: supplier.supplierId }))
            : [{ label: '', value: null }, ...suppliers.map((supplier) => ({ label: supplier.businessName, value: supplier.supplierId }))];
        setOptions(options as any);
    }, [suppliers]);

    const handleChange = (supplierId: any) => {
        const supplier = suppliers.find((supplier) => supplier.supplierId === supplierId);
        onChange?.(supplier);
    };

    return (
        <FormAutocomplete
            name={name}
            label={label}
            placeholder={placeholder}
            helperText={helperText}
            defaultValue={defaultValue}
            options={options}
            disabled={disabled || loadingSuppliers}
            required={required}
            variant={variant}
            onChange={handleChange}
        />
    );
}

type Props = {
    name: string;
    label?: string;
    placeholder?: string;
    helperText?: string;
    defaultValue?: string | null | undefined;
    disabled?: boolean;
    required?:
        | boolean
        | {
              value: number;
              message: string;
          };
    variant?: Variant;
    filterBySupplierIds?: Array<SupplierId>;
    onChange?: any;
};
