import { makeStyles } from '@material-ui/core';
import { Text } from '@pidedirecto/ui';
import { FormMultiselectableAutocomplete } from '@pidedirecto/ui/form';
import * as React from 'react';
import { translate } from 'src/i18n/translate';
import { useSelector } from 'src/utils/react/useSelector';

export function FormPosOrderItemsAutocompleteMultiple({ name, label, placeholder, helperText, defaultValue, disabled, required }: Props): React.ReactElement {
    const classes = useStyles();

    const items = useSelector((state) => state.pos.items);

    return (
        <FormMultiselectableAutocomplete
            name={name}
            label={label}
            placeholder={placeholder}
            helperText={helperText}
            defaultValue={defaultValue}
            data={items}
            getOptionValue={(cartItem) => cartItem.key}
            getOptionLabel={(cartItem) => cartItem.name}
            renderOption={(cartItem) => <Text className={classes.option}>{cartItem.name}</Text>}
            disabled={disabled || !items.length}
            required={{
                value: !!required,
                message: translate('This field is required'),
            }}
        />
    );
}

const useStyles = makeStyles((theme) => ({
    option: {
        margin: '6px 0 6px 4px',
    },
}));

type Props = {
    name: string;
    label?: string;
    placeholder?: string;
    helperText?: string;
    defaultValue?: Array<string>;
    disabled?: boolean;
    required?: boolean;
};
