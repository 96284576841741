export const UserTypes = {
    ADMIN: 'ADMIN',
    SUPPORT: 'SUPPORT',
    OPERATIONS: 'OPERATIONS',
    KITCHENS: 'KITCHENS',
    VIEW: 'VIEW',
    RESTAURANT: 'RESTAURANT',
    CASHIER: 'CASHIER',
    WAITER: 'WAITER',
    KIOSK: 'KIOSK',
    KITCHEN_DISPLAY_SCREEN: 'KITCHEN_DISPLAY_SCREEN',
    BUSINESS: 'BUSINESS',
    BRAND: 'BRAND',
} as const;

export type UserType = (typeof UserTypes)[keyof typeof UserTypes];
