import { makeStyles } from '@material-ui/core';
import TableCell from '@material-ui/core/TableCell';
import TableFooter from '@material-ui/core/TableFooter';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import moment from 'moment';
import MUIDataTable from 'mui-datatables';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { exportDriversBillingApi } from 'src/api/letseatadmin/driverBilling/exportDriversBillingApi';
import { DriversBillingVm, getDriversBillingApi, type DriverBillingVm } from 'src/api/letseatadmin/driverBilling/getDriversBillingApi';
import { tribalExportDriversBillingApi } from 'src/api/letseatadmin/driverBilling/tribalExportDriversBillingApi';
import { app2 } from 'src/app2';
import { CustomToolbarSelect } from 'src/components/mui-datatables/CustomToolbarSelect';
import { ExportToolbarButton } from 'src/components/mui-datatables/ExportToolbarButton';
import { RefreshToolbarButton } from 'src/components/mui-datatables/RefreshToolbarButton';
import { UpdatingContentProgress } from 'src/components/UpdatingContentProgress';
import { translate } from 'src/i18n/translate';
import type { DriverId } from 'src/types/Id';
import { alertKnownErrorOrSomethingWentWrong } from 'src/utils/alert/alertKnownErrorOrSomethingWentWrong';
import { fullName } from 'src/utils/customer/fullName';
import { downloadTextInFile } from 'src/utils/html/downloadTextInFile';
import { getSelectedRowsData } from 'src/utils/mui-datatables/getSelectedRowsData';
import { useAction } from 'src/utils/react/useAction';
import { useSelector } from 'src/utils/react/useSelector';
import { openDriverBillingPage } from 'src/utils/window/openDriverBillingPage';

export function DriversBilling({ filter }: Props): React.ReactElement {
    const classes = useStyles();

    const [loading, setLoading] = useState(false);
    const [driversBillingVm, setDriversBillingVm] = useState<DriversBillingVm | undefined>();

    const setTitle = useAction(app2.actions.setTitle);
    const cities = useSelector((state) => state.app2.cities);

    useEffect(() => {
        setTitle(translate('Driver Billing'));
        load();
    }, [filter.from, filter.to, cities]);

    const load = async () => {
        setLoading(true);
        const response = await getDriversBillingApi({
            from: moment(filter.from).format('YYYY-MM-DD'),
            to: moment(filter.to).format('YYYY-MM-DD'),
            cities: cities,
        });
        setLoading(false);
        if (!response.ok) {
            alertKnownErrorOrSomethingWentWrong(response);
            return;
        }
        setDriversBillingVm(response.data);
    };

    return (
        <div className={classes.container}>
            <UpdatingContentProgress loading={loading} />
            <MUIDataTable
                /* @ts-ignore */
                className={classes.table}
                title={translate('Drivers')}
                data={
                    driversBillingVm?.driversBilling?.map((driverBilling: DriverBillingVm) => ({
                        driverId: driverBilling.driverId,
                        name: fullName(driverBilling.firstName, driverBilling.lastName),
                        email: driverBilling.email,
                        mobileNumber: driverBilling.mobileNumber,
                        curp: driverBilling.curp,
                        rfc: driverBilling.rfc,
                        banned: driverBilling.banned ? translate('True') : translate('False'),
                        claveId: driverBilling.claveId ?? '',
                        city: driverBilling.city,
                        clabe: driverBilling.clabe,
                        cash: parseFloat(driverBilling.cash as any),
                        app: parseFloat(driverBilling.app as any),
                        invoicedRestaurantDeliveryCost: driverBilling.invoicedRestaurantDeliveryCost,
                        total: parseFloat(driverBilling.total as any),
                        commission: parseFloat(driverBilling.commission as any),
                        bonus: parseFloat(driverBilling.bonus as any),
                        dynamicDeliveryEarning: parseFloat(driverBilling.dynamicDeliveryEarning as any),
                        deliveryCommission: parseFloat(driverBilling.deliveryCommission as any),
                        deliveryCostBonusCash: parseFloat(driverBilling.deliveryCostBonusCash as any),
                        deliveryCostBonusCard: parseFloat(driverBilling.deliveryCostBonusCard as any),
                        manualEarning: parseFloat(driverBilling.manualEarning as any),
                        manualEarnings: parseFloat(driverBilling.manualEarnings as any),
                        driverReferral: parseFloat(driverBilling.driverReferral as any),
                        driverReferrals: parseFloat(driverBilling.driverReferrals as any),
                        iva: parseFloat(driverBilling.iva as any),
                        isr: parseFloat(driverBilling.isr as any),
                        deposit: parseFloat(driverBilling.deposit as any),
                        deliveries: driverBilling.deliveries,
                        invoicedRestaurantDeliveryCostDeliveries: driverBilling.invoicedRestaurantDeliveryCostDeliveries,
                        delivered: driverBilling.delivered,
                        cancelled: driverBilling.cancelled,
                    })) ?? []
                }
                columns={[
                    {
                        name: 'driverId',
                        label: ' ',
                        options: {
                            display: 'excluded',
                            filter: false,
                        },
                    },
                    {
                        name: 'name',
                        label: translate('Driver'),
                        options: {
                            filter: false,
                        },
                    },
                    {
                        name: 'email',
                        label: 'email',
                        options: {
                            display: 'excluded',
                            filter: false,
                        },
                    },
                    {
                        name: 'mobileNumber',
                        label: translate('Mobile Number'),
                        options: {
                            filter: true,
                            searchable: true,
                        },
                    },
                    {
                        name: 'curp',
                        label: translate('Curp'),
                        options: {
                            filter: true,
                            searchable: true,
                        },
                    },
                    {
                        name: 'rfc',
                        label: translate('RFC'),
                        options: {
                            filter: true,
                            searchable: true,
                        },
                    },
                    {
                        name: 'banned',
                        label: translate('Banned'),
                        options: {
                            filter: true,
                            searchable: true,
                        },
                    },
                    {
                        name: 'clabe',
                        label: translate('CLABE'),
                        options: {
                            filter: true,
                            searchable: true,
                        },
                    },
                    {
                        name: 'claveId',
                        label: translate('Clave Id'),
                        options: {
                            filter: false,
                        },
                    },
                    {
                        name: 'city',
                        label: translate('City'),
                        options: {
                            filter: true,
                            searchable: true,
                        },
                    },
                    {
                        name: 'cash',
                        label: translate('Cash'),
                        options: {
                            filter: false,
                            searchable: false,
                        },
                    },
                    {
                        name: 'app',
                        label: translate('App'),
                        options: {
                            filter: false,
                            searchable: false,
                        },
                    },
                    {
                        name: 'invoicedRestaurantDeliveryCost',
                        label: translate('Invoiced Deliveries'),
                        options: {
                            filter: false,
                            searchable: false,
                        },
                    },
                    {
                        name: 'total',
                        label: translate('Total Sold'),
                        options: {
                            filter: false,
                            searchable: false,
                        },
                    },
                    {
                        name: 'commission',
                        label: translate('Commission'),
                        options: {
                            filter: false,
                            searchable: false,
                        },
                    },
                    {
                        name: 'bonus',
                        label: translate('Bonus'),
                        options: {
                            filter: false,
                            searchable: false,
                        },
                    },
                    {
                        name: 'dynamicDeliveryEarning',
                        label: translate('Dynamic Delivery Earning'),
                        options: {
                            filter: false,
                            searchable: false,
                        },
                    },
                    {
                        name: 'deliveryCommission',
                        label: translate('Delivery Cash Commission'),
                        options: {
                            filter: false,
                            searchable: false,
                        },
                    },
                    {
                        name: 'deliveryCostBonusCash',
                        label: translate('Delivery Cost Bonus Cash'),
                        options: {
                            filter: false,
                            searchable: false,
                        },
                    },
                    {
                        name: 'deliveryCostBonusCard',
                        label: translate('Delivery Cost Bonus Card'),
                        options: {
                            filter: false,
                            searchable: false,
                        },
                    },
                    {
                        name: 'manualEarning',
                        label: translate('Manual Earning'),
                        options: {
                            filter: false,
                            searchable: false,
                        },
                    },
                    {
                        name: 'driverReferral',
                        label: translate('Driver Referral Earning'),
                        options: {
                            filter: false,
                            searchable: false,
                        },
                    },
                    {
                        name: 'iva',
                        label: translate('IVA'),
                        options: {
                            filter: false,
                            searchable: false,
                        },
                    },
                    {
                        name: 'isr',
                        label: translate('ISR'),
                        options: {
                            filter: false,
                            searchable: false,
                        },
                    },
                    {
                        name: 'deposit',
                        label: translate('Deposit'),
                        options: {
                            filter: false,
                            searchable: false,
                        },
                    },
                    {
                        name: 'deliveries',
                        label: translate('Deliveries'),
                        options: {
                            filter: false,
                            searchable: false,
                        },
                    },
                    {
                        name: 'invoicedRestaurantDeliveryCostDeliveries',
                        label: translate('Invoiced'),
                        options: {
                            filter: false,
                            searchable: false,
                        },
                    },
                    {
                        name: 'delivered',
                        label: translate('Delivered'),
                        options: {
                            filter: false,
                            searchable: false,
                        },
                    },
                    {
                        name: 'cancelled',
                        label: translate('Cancelled'),
                        options: {
                            filter: false,
                            searchable: false,
                        },
                    },
                ]}
                options={{
                    responsive: 'standard',
                    tableBodyMaxHeight: '500px',
                    selectableRows: 'multiple',
                    customToolbarSelect: (selectedRows, displayData) => {
                        const handleClickExportBankAccounts = async () => {
                            const selectedDriverIds = getSelectedRowsData(selectedRows, displayData).map((d: any) => d[0]);
                            const response = await exportDriversBillingApi({
                                from: moment(filter.from).format('YYYY-MM-DD'),
                                to: moment(filter.to).format('YYYY-MM-DD'),
                                paymentDate: moment(filter.paymentDate).format('YYYY-MM-DD'),
                                driverIds: selectedDriverIds,
                            });
                            if (!response.ok) {
                                alertKnownErrorOrSomethingWentWrong(response);
                                return;
                            }
                            downloadTextInFile(response.data.transactionExport, `driver-billing-export-${moment(filter.from).format('YYYY-MM-DD')}--${moment(filter.to).format('YYYY-MM-DD')}.txt`);
                            alert(
                                [
                                    `exported: ${response.data.exported}`,
                                    `banned: ${response.data.banned}`,
                                    `removed: ${response.data.removed}`,
                                    `accountNotExported: ${response.data.accountNotExported}`,
                                    `nothingToTransfer: ${response.data.nothingToTransfer}`,
                                ].join('\n'),
                            );
                        };

                        const handleClickExportTribalFormat = async () => {
                            const selectedDriverIds = getSelectedRowsData(selectedRows, displayData).map((d: any) => d[0]);
                            const response = await tribalExportDriversBillingApi({
                                from: moment(filter.from).format('YYYY-MM-DD'),
                                to: moment(filter.to).format('YYYY-MM-DD'),
                                paymentDate: moment(filter.paymentDate).format('YYYY-MM-DD'),
                                driverIds: selectedDriverIds,
                            });
                            if (!response.ok) {
                                alertKnownErrorOrSomethingWentWrong(response);
                                return;
                            }
                            downloadTextInFile(
                                response.data.tribalExportFormat,
                                `driver-billing-tribal-export-format-${moment(filter.from).format('YYYY-MM-DD')}--${moment(filter.to).format('YYYY-MM-DD')}.txt`,
                            );
                            alert(
                                [
                                    `exported: ${response.data.exported}`,
                                    `banned: ${response.data.banned}`,
                                    `removed: ${response.data.removed}`,
                                    `nothingToTransfer: ${response.data.nothingToTransfer}`,
                                ].join('\n'),
                            );
                        };

                        return (
                            <CustomToolbarSelect>
                                <ExportToolbarButton onClick={handleClickExportBankAccounts} tooltip={translate('Export As Bank Transactions')} />
                                <ExportToolbarButton onClick={handleClickExportTribalFormat} tooltip={translate('Export As Tribal Export Format')} />
                            </CustomToolbarSelect>
                        );
                    },
                    onRowClick: (
                        rowData: Array<string>,
                        rowMeta: {
                            dataIndex: number;
                            rowIndex: number;
                        },
                    ) => {
                        const driverId: DriverId = rowData[0] as any;
                        openDriverBillingPage(driverId);
                    },
                    filterType: 'multiselect',
                    rowsPerPage: 100,
                    customToolbar: () => (
                        <>
                            <RefreshToolbarButton onClick={load} />
                        </>
                    ),
                    customFooter: (count, page, rowsPerPage, changeRowsPerPage, changePage) => (
                        <TableFooter>
                            <TableRow>
                                <TablePagination
                                    count={count}
                                    rowsPerPage={rowsPerPage}
                                    page={page}
                                    onChangePage={(_, page) => changePage(page)}
                                    onChangeRowsPerPage={(event) => changeRowsPerPage(event.target.value)}
                                    rowsPerPageOptions={[10, 15, 100]}
                                />
                            </TableRow>
                            <TableRow>
                                <TableCell size='small'>
                                    {translate('Cash')}: {driversBillingVm?.cash}
                                </TableCell>
                                <TableCell size='small'>
                                    {translate('App')}: {driversBillingVm?.app}
                                </TableCell>
                                <TableCell size='small'>
                                    {translate('Total Sold')}: {driversBillingVm?.total}
                                </TableCell>
                                <TableCell size='small'>
                                    {translate('Commission')}: {driversBillingVm?.commission}
                                </TableCell>
                                <TableCell size='small'>
                                    {translate('Bonus')}: {driversBillingVm?.bonus}
                                </TableCell>
                                <TableCell size='small'>
                                    {translate('Manual Earning')}: {driversBillingVm?.manualEarning}
                                </TableCell>
                            </TableRow>
                            <TableCell size='small'>
                                {translate('IVA 8%')}: {driversBillingVm?.iva}
                            </TableCell>
                            <TableCell size='small'>
                                {translate('ISR 2.1%')}: {driversBillingVm?.isr}
                            </TableCell>
                            <TableCell size='small'>
                                {translate('Deposit')}: {driversBillingVm?.deposit}
                            </TableCell>
                            <TableRow>
                                <TableCell size='small'>
                                    {translate('Bonuses')}: {driversBillingVm?.bonuses}
                                </TableCell>
                                <TableCell size='small'>
                                    {translate('Manual Earning')}: {driversBillingVm?.manualEarnings}
                                </TableCell>
                                <TableCell size='small'>
                                    {translate('Driver Referral Earning')}: {driversBillingVm?.driverReferrals}
                                </TableCell>
                                <TableCell size='small'>
                                    {translate('Deliveries')}: {driversBillingVm?.deliveries}
                                </TableCell>
                                <TableCell size='small'>
                                    {translate('Invoiced Deliveries')}: {driversBillingVm?.invoicedRestaurantDeliveryCostDeliveries}
                                </TableCell>
                                <TableCell size='small'>
                                    {translate('Delivered')}: {driversBillingVm?.delivered}
                                </TableCell>
                                <TableCell size='small'>
                                    {translate('Cancelled')}: {driversBillingVm?.cancelled}
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell size='small'>
                                    {translate('Uber Eats Orders')}: {driversBillingVm?.uberEatsOrders}
                                </TableCell>
                                <TableCell size='small'>
                                    {translate('Uber Eats Delivery Cost')}: {driversBillingVm?.uberEatsDeliveryCost}
                                </TableCell>
                                <TableCell size='small'>
                                    {translate('Delivery Income')}: {driversBillingVm?.deliveryCost}
                                </TableCell>
                                <TableCell size='small'>
                                    {translate('Rappi Cargo Orders')}: {driversBillingVm?.rappiCargoOrders}
                                </TableCell>
                                <TableCell size='small'>
                                    {translate('Rappi Cargo Order Total')}: {driversBillingVm?.rappiCargoOrderTotal}
                                </TableCell>
                                <TableCell size='small'>
                                    {translate('Rappi Cargo Delivery Cost')}: {driversBillingVm?.rappiCargoDeliveryCost}
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell size='small'>
                                    {translate('Uber Daas Orders')}: {driversBillingVm?.uberDaasOrders}
                                </TableCell>
                                <TableCell size='small'>
                                    {translate('Uber Daas Order Total')}: {driversBillingVm?.uberDaasOrdersTotal}
                                </TableCell>
                                <TableCell size='small'>
                                    {translate('Uber Daas Delivery Cost')}: {driversBillingVm?.uberDaasDeliveryCost}
                                </TableCell>
                            </TableRow>
                        </TableFooter>
                    ),
                    downloadOptions: { filename: 'billing data.csv' },
                    // filterList: [[], [], [], [], ['MEXICO']],
                }}
            />
        </div>
    );
}

const useStyles = makeStyles((theme) => ({
    container: {
        position: 'relative',
    },
    table: {
        whiteSpace: 'nowrap',
        '& tr': { cursor: 'pointer' },
        '& tr:hover': { backgroundColor: 'rgba(0, 0, 0, 0.07) !important' },
    },
    toolbar: {
        paddingRight: theme.spacing(3),
    },
}));

type Props = {
    filter: {
        from: Date;
        to: Date;
        paymentDate: Date;
    };
};
