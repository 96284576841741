import { makeStyles } from '@material-ui/core/styles';
import * as React from 'react';
import { useRef, useState } from 'react';
import { findDemoRestaurantsApi } from 'src/api/letseatadmin/demoModeRestaurants/findDemoRestaurantsApi';
import { CreateToolbarButton } from 'src/components/mui-datatables/CreateToolbarButton';
import { RefreshToolbarButton } from 'src/components/mui-datatables/RefreshToolbarButton';
import { Table } from 'src/components/Table';
import { translate } from 'src/i18n/translate';
import { CloneRestaurantToDemoRestaurantDialog } from 'src/scenes/letseatadmin/demoRestaurants/CloneRestaurantToDemoRestaurantDialog';
import { createFilteredOnDownloadFunctional } from 'src/utils/mui-datatables/createFilteredOnDownloadFunctional';
import { useLoadApi } from 'src/utils/react/useLoadApi';

export default function DemoRestaurantsTable(): React.ReactElement {
    const classes = useStyles();
    const table = useRef(null);
    const [loading, demoRestaurants, refreshDemoRestaurants] = useLoadApi(findDemoRestaurantsApi, undefined, { initialValue: [] });
    const [cloneRestaurantToDemoRestaurantDialogState, setCloneRestaurantToDemoRestaurantDialogState] = useState({ open: false });

    const handleClickClone = async () => {
        setCloneRestaurantToDemoRestaurantDialogState({ open: true });
    };

    return (
        <div style={{ position: 'relative' }}>
            <CloneRestaurantToDemoRestaurantDialog
                open={cloneRestaurantToDemoRestaurantDialogState.open}
                onClose={() => setCloneRestaurantToDemoRestaurantDialogState({ open: false })}
                loading={loading}
                onSuccess={refreshDemoRestaurants}
            />
            <Table
                loading={loading}
                ref={table}
                className={classes.table}
                title={translate('Demo Restaurants')}
                data={demoRestaurants.map((demoRestaurant) => ({
                    restaurantId: demoRestaurant.restaurantId,
                    restaurantName: demoRestaurant.restaurantName,
                }))}
                columns={[
                    {
                        name: 'restaurantId',
                        label: ' ',
                        options: {
                            display: 'excluded',
                            filter: false,
                            searchable: true,
                        },
                    },
                    {
                        name: 'restaurantName',
                        label: translate('Restaurant Name'),
                        options: {
                            filter: false,
                            searchable: true,
                        },
                    },
                ]}
                options={{
                    responsive: 'standard',
                    tableBodyMaxHeight: '500px',
                    selectableRows: 'none',
                    filterType: 'multiselect',
                    rowsPerPage: 100,
                    customToolbar: () => {
                        return (
                            <>
                                <RefreshToolbarButton
                                    onClick={() => {
                                        refreshDemoRestaurants();
                                    }}
                                />
                                <CreateToolbarButton onClick={handleClickClone} />
                            </>
                        );
                    },
                    onDownload: createFilteredOnDownloadFunctional(table),
                }}
            />
        </div>
    );
}

const useStyles = makeStyles((theme) => ({
    table: {
        whiteSpace: 'nowrap',
        '& tr': { cursor: 'pointer' },
        '& tr:hover': { backgroundColor: 'rgba(0, 0, 0, 0.07) !important' },
    },
    toolbar: {
        paddingRight: theme.spacing(3),
    },
}));
