import { letseatadminApiMethod } from 'src/api/letseatadminApi';
import type { ApiSauceResponse } from 'src/api/types/ApiSauceResponse';
import type { Zone } from 'src/types/Id';

export async function getZoneOptionsApi(): ApiSauceResponse<GetZoneOptionsApiResponse> {
    return letseatadminApiMethod('options/getZoneOptionsApi');
}

export type GetZoneOptionsApiResponse = Array<ZoneVm>;

export type ZoneVm = {
    label: Zone;
    value: Zone;
};
