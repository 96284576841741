import { makeStyles } from '@material-ui/core';
import { useContext, useMemo } from 'react';
import * as React from 'react';
import { PageContext } from 'src/components/Page';
import { Tooltip } from 'src/components/Tooltip';
import { CheckIcon } from 'src/icons/CheckIcon';
import { createUserClickedCheckBoxLogEvent } from 'src/services/logEvent/createUserClickedCheckboxLogEvent';
import { classNames } from 'src/utils/react/classNames';

/**
 * @callback onChange
 */

/**
 * Renders a checkbox component
 * @param {string} name - Name for the checkbox input
 * @param {string} value - Value for the checkbox input
 * @param {string} label - Label for the checkbox input
 * @param {boolean} [disabled] - Set the checkbox disabled
 * @param {boolean} [checked] - Controls the checked stated of the checkbox
 * @param {onChange} [onChange] - Function to be called when the checked stated of the checkbox changes
 * @param {helperText} [helperText] - Helper text to be displayed below the checkbox
 * @returns {React.Node}
 * @constructor
 */
export function Checkbox({ name, value, label, helperText, disabled, checked, onChange, classes: classesProp, tooltip }: Props): React.ReactElement {
    const classes = useStyles();
    const pageContext = useContext(PageContext);

    const handleLabelClick = (e: any) => e.preventDefault();

    const handleChange = (value: undefined | string, e: any) => {
        createUserClickedCheckBoxLogEvent({ pageContext, label });
        onChange?.(value, e);
    };

    return useMemo(
        () => (
            <div className={classNames(classes.container, classesProp?.container)}>
                <div className={classes.hidden}>
                    <CheckIcon />
                </div>

                <div className={classes.inputContainer}>
                    <input
                        aria-label={label ? '' : name}
                        type='checkbox'
                        name={name}
                        value={value}
                        onChange={(e) => handleChange(value, e)}
                        id={`${value ?? ''}-checkbox`}
                        className={classes.input}
                        disabled={disabled}
                        checked={checked}
                    />

                    {label && (
                        <label htmlFor={`${value ?? ''}-checkbox`} className={classNames(classes.label, classesProp?.label)} onClick={handleLabelClick}>
                            {label}
                        </label>
                    )}
                    {tooltip && <Tooltip text={tooltip} />}
                </div>
                {helperText && (
                    <div className={classes.helperTextContainer}>
                        <span className={classes.span}>{helperText}</span>
                    </div>
                )}
            </div>
        ),
        [name, value, label, disabled, checked],
    );
}

const useStyles = makeStyles((theme) => ({
    container: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        gap: 8,
    },
    label: {
        fontSize: 14,
        fontFamily: theme.typography.regular,
        color: theme.palette.text.primary,
        cursor: 'pointer',
    },
    input: {
        cursor: 'pointer',
        width: 16,
        minWidth: 16,
        height: 16,
        minHeight: 16,
        appearance: 'none',
        border: `1px solid ${theme.palette.border.primary}`,
        borderRadius: 2,
        display: 'grid',
        placeContent: 'center',
        color: 'white',
        '&:checked': {
            backgroundColor: theme.palette.surface.brandContrast,
            border: `1px solid ${theme.palette.surface.brandContrast}`,
        },
        '&:checked::before': {
            content: '""',
            clipPath: 'url(#check-icon-clip-path)',
            width: 10,
            height: 10,
            backgroundColor: theme.palette.surface.primary,
            marginLeft: 2,
            marginTop: 1,
        },
    },
    hidden: {
        position: 'absolute',
        width: 0,
        height: 0,
        overflow: 'hidden',
    },
    inputContainer: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        gap: 8,
        width: '100%',
    },
    helperTextContainer: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'start',
        gap: 4,
        width: '100%',
    },
    span: {
        fontSize: 10,
        fontFamily: theme.typography.regular,
        color: theme.palette.text.primary,
    },
}));

type Props = {
    name: string;
    value: string | undefined;
    label: string;
    helperText?: string;
    disabled?: boolean;
    checked?: boolean;
    onChange?: any;
    classes?: {
        container?: string;
        label?: string;
    };
    tooltip?: string;
};
