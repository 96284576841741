import * as React from 'react';
import { DeprecatedMenuPage } from 'src/scenes/letseatmanager/DeprecatedMenuPage';
import { MenuPage } from 'src/scenes/letseatmanager/MenuPage';
import { useSelector } from 'src/utils/react/useSelector';

//TODO: Remove when its safe
export function TemporalMenuPage(): React.ReactElement {
    const newMenuManagementEnabled = useSelector((state) => state.app.restaurant.newMenuManagementEnabled);

    if (newMenuManagementEnabled) return <MenuPage />;

    return <DeprecatedMenuPage />;
}
