import { BigNumber } from 'bignumber.js';
import type { PromoCodeVm } from 'src/api/letseatmanager/types/PromoCodeVm';
import type { OrderType } from 'src/constants/OrderType';
import type { CartItemVm } from 'src/types/CartItemVm';
import { calculateOrderItemSubtotalPrice } from 'src/utils/pos/calculateOrderItemSubtotalPrice';
import { isAmountDiscount } from 'src/utils/promoCode/isAmountDiscount';
import { isPercentDiscount } from 'src/utils/promoCode/isPercentDiscount';
import { isProductsReward } from 'src/utils/promoCode/isProductsReward';
import { requireValue } from 'src/utils/require/requireValue';

export function calculatePromoCodeDiscount(
    promoCode: PromoCodeVm,
    orderType: OrderType | null | undefined,
    amount: string,
    deliveryCost: string | null | undefined,
    orderItems: Array<CartItemVm>,
): string | undefined {
    if (!promoCode) {
        return;
    }

    let discountAmount = BigNumber('0');

    if (promoCode.excludeDeliveryCost && deliveryCost) {
        amount = BigNumber(amount).minus(deliveryCost).toString();
    }

    if (promoCode.freeDelivery && deliveryCost && !promoCode.excludeDeliveryCost) {
        discountAmount = BigNumber(discountAmount).plus(BigNumber(deliveryCost));
    }

    if (isAmountDiscount(promoCode.discountType)) {
        discountAmount = BigNumber(discountAmount).plus(promoCode.discount);
    }

    if (isPercentDiscount(promoCode.discountType) && !isProductsReward(promoCode.rewardType)) {
        const percentDiscount = BigNumber(BigNumber(amount).multipliedBy(promoCode.discount).dividedBy(100).toFixed(2));

        discountAmount = BigNumber(discountAmount).plus(percentDiscount);
    }

    if (isPercentDiscount(promoCode.discountType) && isProductsReward(promoCode.rewardType)) {
        const menuItems = orderItems.filter((orderItem) => promoCode.menuItemIds?.includes(orderItem.menuItemId));
        let orderItemsSubTotal = BigNumber(0);
        menuItems.forEach((menuItem) => {
            const orderItemSubtotal = calculateOrderItemSubtotalPrice(menuItem);
            orderItemsSubTotal = BigNumber(orderItemsSubTotal).plus(orderItemSubtotal);
        });

        const percentDiscount = BigNumber(BigNumber(orderItemsSubTotal).multipliedBy(promoCode.discount).dividedBy(100).toFixed(2));

        discountAmount = BigNumber(discountAmount).plus(percentDiscount);
    }

    if (!promoCode.freeDelivery && !!promoCode.maxDiscountAmount) {
        if (BigNumber(discountAmount).isGreaterThanOrEqualTo(promoCode.maxDiscountAmount ?? 0)) return promoCode.maxDiscountAmount;
    }

    if (promoCode.freeDelivery && deliveryCost && promoCode.maxDiscountAmount) {
        if (BigNumber(discountAmount).isGreaterThanOrEqualTo(promoCode.maxDiscountAmount ?? 0)) return BigNumber(deliveryCost).plus(requireValue(promoCode.maxDiscountAmount)).toString();
    }

    if (!promoCode.maxDiscountAmount && BigNumber(discountAmount).isGreaterThanOrEqualTo(amount)) return amount.toString();

    return discountAmount.toString();
}
