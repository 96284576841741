import { makeStyles } from '@material-ui/core';
import * as React from 'react';
import { useEffect } from 'react';
import { SelectItemsDialog } from 'src/components/dialog/SelectItemsDialog';
import { WebSocketEventTypes } from 'src/constants/WebSocketEventType';
import { translate } from 'src/i18n/translate';
import { useMenuItems } from 'src/services/menuItem/useMenuItems';
import { useFormatAsRestaurantCurrencyNumber } from 'src/services/restaurant/useFormatAsRestaurantCurrencyNumber';
import type { MenuItemId } from 'src/types/Id';
import { ManagerMenuItemVm } from 'src/types/ManagerMenuItemVm';
import { WebSocketEvents } from 'src/utils/webSocket/WebSocketEvents';

export function SelectProductsDialog({ open, defaultSelectedProducts, onClose, onSuccess }: Props): React.ReactElement {
    const classes = useStyles();
    const formatAsCurrencyNumber = useFormatAsRestaurantCurrencyNumber();

    const { products } = useMenuItems();

    useEffect(() => {
        const menuCategoryChangedSyncWebSocketEvent = WebSocketEvents.addEventListener(WebSocketEventTypes.MENU_CATEGORY_CHANGED, onClose);
        const menuCategoryRemovedSyncWebSocketEvent = WebSocketEvents.addEventListener(WebSocketEventTypes.MENU_CATEGORY_REMOVED, onClose);

        return () => {
            menuCategoryChangedSyncWebSocketEvent.remove();
            menuCategoryRemovedSyncWebSocketEvent.remove();
        };
    });

    const handleDialogSuccess = (menuItemIds: any) => {
        onSuccess?.(menuItemIds);
    };

    const items = products.map((menuItem) => ({
        value: menuItem.menuItemId,
        searchableBy: menuItem.name,
        item: menuItem,
    }));

    return (
        <SelectItemsDialog
            open={open}
            title={translate('Select your products')}
            items={items}
            defaultSelectedItems={defaultSelectedProducts}
            renderItem={({ item: product }: { item: ManagerMenuItemVm }) => (
                <div className={classes.menuItemContainer}>
                    <span className={classes.text}>{product.name}</span>
                    <span className={classes.text}>{formatAsCurrencyNumber(product.price)}</span>
                </div>
            )}
            onClose={onClose}
            onSuccess={handleDialogSuccess}
        />
    );
}

const useStyles = makeStyles((theme) => ({
    menuItemContainer: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        width: '100%',
    },
    text: {
        fontFamily: theme.typography.regular,
        fontSize: 15,
    },
}));

type Props = {
    open: boolean;
    defaultSelectedProducts: Array<MenuItemId>;
    onClose: any;
    onSuccess: any;
};
