import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import { Button, Dialog, DialogActions, Text } from '@pidedirecto/ui';
import { Form } from '@pidedirecto/ui/form';
import { useConfirmDialog, useForm, useNotification } from '@pidedirecto/ui/hooks';
import { UploadIcon } from '@pidedirecto/ui/icons';
import * as React from 'react';
import { useRef, useState } from 'react';
import { importSuppliesApi } from 'src/api/letseatmanager/inventory/importSuppliesApi';
import { translate } from 'src/i18n/translate';
import { alertKnownErrorOrSomethingWentWrong } from 'src/utils/alert/alertKnownErrorOrSomethingWentWrong';
import { convertCsvToImportedSupplies, CsvSupplyItem } from 'src/utils/csv/convertCsvToImportedSupplies';
import { objectsToCsv } from 'src/utils/csv/objectsToCsv';
import { downloadTextInFile } from 'src/utils/html/downloadTextInFile';
import { useSelector } from 'src/utils/react/useSelector';

export function ImportSuppliesFromCsvDialog({ open, onClose, onSuccess }: Props): React.ReactElement {
    const classes = useStyles();
    const form = useForm();
    const inputRef = useRef<HTMLInputElement | null>(null);
    const notification = useNotification();
    const confirmDialog = useConfirmDialog();

    const {
        formState: { isSubmitting },
    } = form;

    const [importing, setImporting] = useState(false);
    const [importedSupplies, setImportedSupplies] = useState<Array<CsvSupplyItem>>([]);
    const [fileName, setFileName] = useState<null | string>(null);

    const restaurantId = useSelector((state) => state.app.restaurantId);

    const importCsv = () => {
        if (importing) return;
        inputRef.current?.click();
    };

    const handleUploadCsv = async (event: React.ChangeEvent<HTMLInputElement>) => {
        if (!event?.target?.files || event?.target?.files.length === 0) return;
        setImporting(true);
        setFileName(event.target.files[0]?.name);

        const { importedSupplies, invalidSupplies } = await convertCsvToImportedSupplies(event.target.files[0] as any);

        if (invalidSupplies.length > 0) {
            const confirm = await confirmDialog({
                title: translate('Import Error'),
                content: translate('@validQuantityData supplies were imported, @invalidQuantityData failed. Download the failed supplies to retry', {
                    validQuantityData: importedSupplies.length,
                    invalidQuantityData: invalidSupplies.length,
                }),
                acceptButtonText: translate('Get file'),
                cancelButtonText: translate('Try again'),
                variant: 'warning',
            });
            if (confirm) downloadTextInFile(objectsToCsv(invalidSupplies), 'invalidSupplies.csv');
        }
        if (importedSupplies?.length > 0) setImportedSupplies(importedSupplies);

        setImporting(false);
    };

    const handleClickExampleFile = async () => {
        const data = [
            { CATEGORIA: 'ABARROTES', PROVEEDOR: '', NOMBRE: 'Manzana', UNIDAD_VENTA: 'KILOGRAMO', UNIDAD_COMPRA: 'KILOGRAMO', SKU: '1', MERMA: '1', COSTO: '1', FACTOR_CONVERSION: '1' },
            { CATEGORIA: 'ABARROTES', PROVEEDOR: '', NOMBRE: 'Manzana', UNIDAD_VENTA: 'GRAMO', UNIDAD_COMPRA: 'GRAMO', SKU: '1', MERMA: '1', COSTO: '1', FACTOR_CONVERSION: '1' },
            { CATEGORIA: 'ABARROTES', PROVEEDOR: '', NOMBRE: 'Manzana', UNIDAD_VENTA: 'LITRO', UNIDAD_COMPRA: 'LITRO', SKU: '1', MERMA: '1', COSTO: '1', FACTOR_CONVERSION: '1' },
            { CATEGORIA: 'ABARROTES', PROVEEDOR: '', NOMBRE: 'Manzana', UNIDAD_VENTA: 'MILILITRO', UNIDAD_COMPRA: 'MILILITRO', SKU: '1', MERMA: '1', COSTO: '1', FACTOR_CONVERSION: '1' },
            { CATEGORIA: 'ABARROTES', PROVEEDOR: '', NOMBRE: 'Manzana', UNIDAD_VENTA: 'CAJA', UNIDAD_COMPRA: 'CAJA', SKU: '1', MERMA: '1', COSTO: '1', FACTOR_CONVERSION: '1' },
            { CATEGORIA: 'ABARROTES', PROVEEDOR: '', NOMBRE: 'Manzana', UNIDAD_VENTA: 'BOLSA', UNIDAD_COMPRA: 'BOLSA', SKU: '1', MERMA: '1', COSTO: '1', FACTOR_CONVERSION: '1' },
            { CATEGORIA: 'ABARROTES', PROVEEDOR: '', NOMBRE: 'Manzana', UNIDAD_VENTA: 'PIEZA', UNIDAD_COMPRA: 'PIEZA', SKU: '1', MERMA: '1', COSTO: '1', FACTOR_CONVERSION: '1' },
            { CATEGORIA: 'ABARROTES', PROVEEDOR: '', NOMBRE: 'Manzana', UNIDAD_VENTA: 'PAQUETE', UNIDAD_COMPRA: 'PAQUETE', SKU: '1', MERMA: '1', COSTO: '1', FACTOR_CONVERSION: '1' },
            { CATEGORIA: 'ABARROTES', PROVEEDOR: '', NOMBRE: 'Manzana', UNIDAD_VENTA: 'BARRA', UNIDAD_COMPRA: 'BARRA', SKU: '1', MERMA: '1', COSTO: '1', FACTOR_CONVERSION: '1' },
            { CATEGORIA: 'ABARROTES', PROVEEDOR: '', NOMBRE: 'Manzana', UNIDAD_VENTA: 'ROLLO', UNIDAD_COMPRA: 'ROLLO', SKU: '1', MERMA: '1', COSTO: '1', FACTOR_CONVERSION: '1' },
            { CATEGORIA: 'ABARROTES', PROVEEDOR: '', NOMBRE: 'Manzana', UNIDAD_VENTA: 'LATA', UNIDAD_COMPRA: 'LATA', SKU: '1', MERMA: '1', COSTO: '1', FACTOR_CONVERSION: '1' },
            { CATEGORIA: 'ABARROTES', PROVEEDOR: '', NOMBRE: 'Manzana', UNIDAD_VENTA: 'REBANADA', UNIDAD_COMPRA: 'REBANADA', SKU: '1', MERMA: '1', COSTO: '1', FACTOR_CONVERSION: '1' },
            { CATEGORIA: 'ABARROTES', PROVEEDOR: '', NOMBRE: 'Manzana', UNIDAD_VENTA: 'LIBRA', UNIDAD_COMPRA: 'LIBRA', SKU: '1', MERMA: '1', COSTO: '1', FACTOR_CONVERSION: '1' },
            { CATEGORIA: 'ABARROTES', PROVEEDOR: '', NOMBRE: 'Manzana', UNIDAD_VENTA: 'ONZA', UNIDAD_COMPRA: 'ONZA', SKU: '1', MERMA: '1', COSTO: '1', FACTOR_CONVERSION: '1' },
            { CATEGORIA: 'ABARROTES', PROVEEDOR: '', NOMBRE: 'Manzana', UNIDAD_VENTA: 'CUCHARADA', UNIDAD_COMPRA: 'CUCHARADA', SKU: '1', MERMA: '1', COSTO: '1', FACTOR_CONVERSION: '1' },
            { CATEGORIA: 'ABARROTES', PROVEEDOR: '', NOMBRE: 'Manzana', UNIDAD_VENTA: 'TAZA', UNIDAD_COMPRA: 'TAZA', SKU: '1', MERMA: '1', COSTO: '1', FACTOR_CONVERSION: '1' },
            { CATEGORIA: 'ABARROTES', PROVEEDOR: '', NOMBRE: 'Manzana', UNIDAD_VENTA: 'GALON', UNIDAD_COMPRA: 'GALON', SKU: '1', MERMA: '1', COSTO: '1', FACTOR_CONVERSION: '1' },
            { CATEGORIA: 'ABARROTES', PROVEEDOR: '', NOMBRE: 'Manzana', UNIDAD_VENTA: 'CUARTO', UNIDAD_COMPRA: 'CUARTO', SKU: '1', MERMA: '1', COSTO: '1', FACTOR_CONVERSION: '1' },
            { CATEGORIA: 'ABARROTES', PROVEEDOR: '', NOMBRE: 'Manzana', UNIDAD_VENTA: 'DIVISA', UNIDAD_COMPRA: 'DIVISA', SKU: '1', MERMA: '1', COSTO: '1', FACTOR_CONVERSION: '1' },
        ];
        downloadTextInFile(objectsToCsv(data), 'suppliesTemplate.csv');
    };

    const handleClose = () => {
        if (isSubmitting) return;
        setImportedSupplies([]);
        setFileName(null);
        onClose();
    };

    const onSubmit = async () => {
        if (!importedSupplies) return;

        const response = await importSuppliesApi({ restaurantId, importedSupplies });

        if (!response.ok) {
            alertKnownErrorOrSomethingWentWrong(response);
            return;
        }

        notification({ message: translate('Import successful! @quantity supplies loaded', { quantity: importedSupplies.length }) });
        onSuccess?.();
        handleClose();
    };

    return (
        <Dialog open={open} onClose={handleClose} title={translate('Import supplies From CSV File')}>
            <Form onSubmit={onSubmit} form={form} classes={{ form: classes.form }}>
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <Button variant={'secondary'} classes={{ button: classes.importMenuButton }} onClick={importCsv}>
                            <UploadIcon size={50} title={translate('Upload icon')} />
                            <span className={classes.text}>{translate('Select CSV File')}</span>
                        </Button>
                        <input style={{ display: 'none' }} type='file' ref={inputRef} onChange={handleUploadCsv} accept='.csv' data-testid='csvFileInput' />
                        {fileName && <Text> {fileName}</Text>}
                    </Grid>
                    <Grid item xs={12}>
                        <div className={classes.infoText}>
                            <a onClick={handleClickExampleFile} className={classes.urlContainer}>
                                {translate('Download')}
                            </a>
                            <Text>{translate('the sample template')}</Text>
                        </div>
                    </Grid>
                </Grid>
                <DialogActions className={classes.buttonsContainer}>
                    <Button variant={'secondary'} onClick={handleClose} disabled={isSubmitting}>
                        {translate('Cancel')}
                    </Button>
                    <Button type='submit' disabled={isSubmitting || !fileName}>
                        {isSubmitting ? translate('Importing') : translate('Import')}
                    </Button>
                </DialogActions>
            </Form>
        </Dialog>
    );
}

const useStyles = makeStyles((theme) => ({
    form: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    buttonsContainer: {
        display: 'flex',
        justifyContent: 'center',
        [theme.breakpoints.down('sm')]: {
            flexDirection: 'column',
        },
    },
    importMenuButton: {
        width: '100%',
        height: 150,
        backgroundColor: theme.palette.secondary.light,
        border: `1px solid ${theme.palette.secondary.dark}`,
        margin: '0 auto',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        borderRadius: 15,
        cursor: 'pointer',
        flexDirection: 'column',
    },
    text: {
        fontFamily: theme.typography.regular,
        fontSize: 15,
        margin: 10,
    },
    infoText: {
        display: 'flex',
        gap: 5,
        justifyContent: 'center',
        alignItems: 'center',
        fontFamily: theme.typography.regular,
    },
    urlContainer: {
        textDecoration: 'underline',
        cursor: 'pointer',
        color: theme.palette.primary.main,
    },
}));

type Props = {
    open: boolean;
    onClose: any;
    onSuccess?: any;
};

type CsvRow = {
    ['CATEGORIA']: string;
    ['PROVEEDOR']: string;
    ['NOMBRE']: string;
    ['UNIDAD_VENTA']: string;
    ['UNIDAD_COMPRA']: string;
    ['SKU']: string;
    ['MERMA']: string;
    ['COSTO']: string;
    ['FACTOR_CONVERSION']: string;
};
