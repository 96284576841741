import { makeStyles } from '@material-ui/core';
import * as React from 'react';
import { PosPaymentResumeItem } from 'src/components/pos/PosPaymentResumeItem';
import { translate } from 'src/i18n/translate';
import { posReducer } from 'src/reducers/posReducer';
import { PosPaymentExtraItem } from 'src/scenes/letseatmanager/pos/posPayment/PosPaymentExtraItem';
import { PosPaymentPromoCodeItem } from 'src/scenes/letseatmanager/pos/posPayment/PosPaymentPromoCodeItem';
import { useRefundPaymentTerminal } from 'src/services/paymentTerminal/useRefundWithPaymentTerminal';
import { useRemovePosPayment } from 'src/services/pos/useRemovePosPayment';
import { useFormatAsRestaurantCurrencyNumber } from 'src/services/restaurant/useFormatAsRestaurantCurrencyNumber';
import { CartItemVm } from 'src/types/CartItemVm';
import type { PosPayment } from 'src/types/PosPayment';
import { useAction } from 'src/utils/react/useAction';
import { useSelector } from 'src/utils/react/useSelector';
import { translateCustomDiscountComment } from 'src/utils/translate/translateCustomDiscountComment';

export function PosPaymentMethodsSummary(): React.ReactElement {
    const classes = useStyles();
    const formatAsCurrencyNumber = useFormatAsRestaurantCurrencyNumber();

    const { removePosPayment } = useRemovePosPayment();
    const { refundWithPaymentTerminal } = useRefundPaymentTerminal();

    const posDiscount = useSelector((state) => state.pos.payment?.posDiscount);
    const deliveryCashHandlingFee = useSelector((state) => state.pos.payment?.deliveryCashHandlingFee);
    const discount = useSelector((state) => state.pos.discount);
    const items = useSelector((state) => state.pos.items);
    const currencyFormat = useSelector((state) => state.app.restaurant.currencyFormat);
    const payments = useSelector((state) => state.pos.payments);
    const usedCustomerCredits = useSelector((state) => state.pos.usedCustomerCredits);
    const useLetsEatCredits = useSelector((state) => state.pos.useLetsEatCredits);
    const acceptMultipleCurrenciesPaymentEnabled = useSelector((state) => state.pos.context?.acceptMultipleCurrenciesPaymentEnabled);
    const mainCurrency = useSelector((state) => state.app.restaurant?.currencyFormat?.currency);

    const removePosDiscount = useAction(posReducer.actions.removePosDiscount);
    const removeCustomerCredits = useAction(posReducer.actions.removeCustomerCredits);

    const itemsWithDiscount = items?.filter((item: CartItemVm) => item.posDiscountPercentage);
    const hasProductsDiscount = posDiscount && itemsWithDiscount?.length > 0;

    const handleRemovePosPayment = async (posPayment: PosPayment) => {
        if (posPayment.paymentTerminalPaymentId) {
            const { refunded } = await refundWithPaymentTerminal({ paymentTerminalPaymentId: posPayment.paymentTerminalPaymentId });
            if (refunded) {
                removePosPayment(posPayment);
            }
            return;
        }

        removePosPayment(posPayment);
    };

    return (
        <div className={classes.middleSection}>
            {payments?.map((posPayment: PosPayment) => <PosPaymentResumeItem key={posPayment.id} posPayment={posPayment} onRemove={handleRemovePosPayment} />)}
            {posDiscount && !hasProductsDiscount && (
                <PosPaymentExtraItem
                    onRemove={removePosDiscount}
                    label={`${translate('Discount:')} ${formatAsCurrencyNumber(posDiscount)} ${acceptMultipleCurrenciesPaymentEnabled ? `(${mainCurrency})` : ''}`}
                    subText={translateCustomDiscountComment(discount?.notes ?? '')}
                />
            )}
            {hasProductsDiscount && (
                <PosPaymentExtraItem
                    onRemove={removePosDiscount}
                    label={`${translate('Discount per product')} - ${discount.discountPercentage ?? 0} % - (${formatAsCurrencyNumber(posDiscount)}) ${
                        acceptMultipleCurrenciesPaymentEnabled ? `(${mainCurrency})` : ''
                    }`}
                    subText={itemsWithDiscount?.map((itemDiscount: CartItemVm) => itemDiscount?.name).join(', ')}
                />
            )}
            {!!deliveryCashHandlingFee && <PosPaymentExtraItem label={translate('Cash fee @amount', { amount: formatAsCurrencyNumber(deliveryCashHandlingFee) })} />}
            <PosPaymentPromoCodeItem />
            {useLetsEatCredits && usedCustomerCredits && (
                <PosPaymentExtraItem
                    onRemove={removeCustomerCredits}
                    label={`${translate('Credits')} - ${formatAsCurrencyNumber(usedCustomerCredits)} ${acceptMultipleCurrenciesPaymentEnabled ? `(${mainCurrency})` : ''}`}
                />
            )}
        </div>
    );
}

const useStyles = makeStyles((theme) => ({
    middleSection: {
        width: '100%',
        height: '100%',
        display: 'flex',
        flex: 1,
        overflowY: 'auto',
        marginTop: 12,
        flexDirection: 'column',
        [theme.breakpoints.down('sm')]: {
            height: '50svh',
        },
    },
    orderResumeText: {
        fontFamily: theme.typography.regular,
        fontSize: 14,
        color: '#2E3748',
    },
    backButton: {
        padding: 0,
        width: 'fit-content',
        height: 'fit-content',
        '&:hover': {
            cursor: 'pointer',
            backgroundColor: 'transparent',
        },
    },
    paymentsContainer: {
        marginBottom: 16,
        overflowY: 'auto',
        '&::-webkit-scrollbar': {
            display: 'none',
        },
    },
}));
