import type { CartItemVm } from 'src/types/CartItemVm';
import type { OrderItemVm } from 'src/types/OrderVm';
import { mapOrderItemsToCartItems } from 'src/utils/pos/mapOrderItemsToCartItems';
import { useSelector } from 'src/utils/react/useSelector';

export function useMapOrderItemsToCartItems(): (orderItems: Array<OrderItemVm> | undefined) => Array<CartItemVm> {
    const menuItems = useSelector((state) => state.pos.posMenu?.menuItems) ?? [];

    const handleMapOrderItemsToCartItems = (orderItems?: Array<OrderItemVm>) => {
        return mapOrderItemsToCartItems({ orderItems: orderItems ?? [], menuItems });
    };

    return handleMapOrderItemsToCartItems;
}
