import { useState } from 'react';
import * as React from 'react';
import { Button } from 'src/components/Button';
import { translate } from 'src/i18n/translate';
import { ManageCoursesDialog } from 'src/scenes/letseatmanager/theRestaurant/ManageCoursesDialog';

export function ManageCoursesButton(): React.ReactElement {
    const [manageCoursesDialogOpen, setManageCoursesDialogOpen] = useState(false);

    const handleOpenManageCoursesDialog = () => setManageCoursesDialogOpen(true);

    return (
        <>
            <ManageCoursesDialog open={manageCoursesDialogOpen} handleClose={() => setManageCoursesDialogOpen(false)} />
            <Button secondary onClick={handleOpenManageCoursesDialog}>
                {translate('Manage courses')}
            </Button>
        </>
    );
}
