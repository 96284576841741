import { Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import * as React from 'react';
import { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import { Button } from 'src/components/Button';
import { FormCurrencyNumberStringField } from 'src/components/form/FormCurrencyNumberStringField';
import { FormNumberStringField } from 'src/components/form/FormNumberStringField';
import { FormSupplyAutocomplete } from 'src/components/form/FormSupplyAutocomplete';
import { FormUnitOfMeasurementSelect } from 'src/components/form/FormUnitOfMeasurementSelect';
import { UnitOfMeasurement } from 'src/constants/UnitOfMeasurement';
import { translate } from 'src/i18n/translate';
import { SupplyVm } from 'src/types/SupplyVm';
import { useSelector } from 'src/utils/react/useSelector';

export function AddBuyOrderSupply({ disabled, getSupply, onSubmit }: Props): React.ReactElement {
    const classes = useStyles();
    const { watch, setValue } = useFormContext();

    const [supplyBuyUnitsState, setSupplyBuyUnitsState] = React.useState<{ buyUnits: Array<UnitOfMeasurement>; mainUnit: UnitOfMeasurement | '' | undefined }>({ buyUnits: [], mainUnit: '' });

    const inventoryLinkedToRestaurantId = useSelector((state) => state.app.restaurant?.inventoryLinkedToRestaurantId);

    const quantity = watch(`quantity`);
    const price = watch(`price`);
    const total = watch(`total`);
    const unitOfMeasurement = watch(`unitOfMeasurement`);
    const supply = watch(`supply`);

    useEffect(() => {
        const total = quantity * price;
        setValue(`total`, total);
    }, [quantity, price]);

    const handleChangeSupply = (supply: SupplyVm) => {
        const mainUnit = supply.buyUnits.find((unit) => unit.isMainUnit);

        setSupplyBuyUnitsState({ buyUnits: supply.buyUnits.map((unit) => unit.unit), mainUnit: mainUnit?.unit });
        setValue(`unitOfMeasurement`, mainUnit?.unit);
        setValue(`price`, supply.fixedCost);
    };

    const resetFields = () => {
        setValue(`supply`, '');
        setValue(`unitOfMeasurement`, '');
        setValue(`quantity`, '');
        setValue(`price`, '');
        setValue(`total`, '');
    };

    const handleAddSupply = () => {
        const data = {
            supply,
            unitOfMeasurement,
            quantity,
            price,
            total,
        } as const;

        if (!data.supply) return;

        onSubmit(data);
        resetFields();
    };

    return (
        /* @ts-ignore */
        <Grid container spacing={2} direction='row' justifyContent='flex-start' alignItems='center' className={classes.item}>
            <Grid item xs={12} sm={6} md={4}>
                <FormSupplyAutocomplete name='supply' label={translate('Supply')} onChange={handleChangeSupply} />
            </Grid>
            <Grid item xs={12} sm={6} md={2}>
                <FormUnitOfMeasurementSelect
                    name={`unitOfMeasurement`}
                    label={translate('Unit of Measurement')}
                    filterByUnits={supplyBuyUnitsState.buyUnits}
                    defaultValue={supplyBuyUnitsState.mainUnit}
                />
            </Grid>
            <Grid item xs={12} sm={6} md={1}>
                <FormNumberStringField classes={{ input: classes.input }} name={`quantity`} label={translate('Quantity')} />
            </Grid>
            <Grid item xs={12} sm={6} md={2}>
                <FormCurrencyNumberStringField classes={{ input: classes.input }} name={`price`} label={translate('Unit Price')} />
            </Grid>
            <Grid item xs={12} sm={6} md={2}>
                <FormCurrencyNumberStringField classes={{ input: classes.input }} disabled name={`total`} label={translate('Total')} />
            </Grid>
            {!inventoryLinkedToRestaurantId && (
                <Grid item xs={12} sm={6} md={1}>
                    <Button classes={{ button: classes.addSupplyButton }} onClick={handleAddSupply} outlined primary>
                        {translate('Add')}
                    </Button>
                </Grid>
            )}
        </Grid>
    );
}

const useStyles = makeStyles((theme) => ({
    addSupplyButton: {
        marginTop: 20,
    },
    item: {
        marginTop: '20px',
    },
    input: {
        height: '42px',
    },
}));

export type Props = {
    disabled?: boolean;
    getSupply: any;
    onSubmit: any;
};
