import { letseatadminApiMethod } from 'src/api/letseatadminApi';
import type { ApiSauceResponse } from 'src/api/types/ApiSauceResponse';
import type { SupportContactId } from 'src/types/Id';

export async function removeSupportContactApi(request: RemoveSupportContactApiRequest): ApiSauceResponse<void> {
    return letseatadminApiMethod('supportContact/removeSupportContactApi', request);
}

export type RemoveSupportContactApiRequest = {
    supportContactId: SupportContactId;
};
