import * as React from 'react';
import { app2 } from 'src/app2';
import { Button } from 'src/components/Button';
import { Text } from 'src/components/Text';
import { RolePermissions } from 'src/constants/RolePermission';
import { translate } from 'src/i18n/translate';
import { useAskForRolePermissionAuthorization } from 'src/modules/order/utils/useAskForRolePermissionAuthorization';
import type { OrderVm } from 'src/types/OrderVm';
import { isPosApp } from 'src/utils/app/isPosApp';
import { isAcceptedDelivery } from 'src/utils/delivery/isAcceptedDelivery';
import { isAcceptedOrder } from 'src/utils/order/isAcceptedOrder';
import { isClosedOrder } from 'src/utils/order/isClosedOrder';
import { isCompletedOrder } from 'src/utils/order/isCompletedOrder';
import { isNewOrder } from 'src/utils/order/isNewOrder';
import { isPendingOrder } from 'src/utils/order/isPendingOrder';
import { useAction } from 'src/utils/react/useAction';
import { useUserHasRolePermission } from 'src/utils/react/useUserHasRolePermissions';

export function OrderCardCancelOrderButton({ order, disabled, onChange }: Props): React.ReactElement | null {
    const [userHasRolePermission] = useUserHasRolePermission();
    const askForRolePermissionAuthorization = useAskForRolePermissionAuthorization();

    const openVerifyPosPasswordDialog = useAction(app2.actions.openVerifyPosPasswordDialog);
    const openRejectOrderDialog = useAction(app2.actions.openRejectOrderDialog);

    const hasDriver = order.driverId;
    const acceptedDeliveryOrder = hasDriver && isAcceptedDelivery(order.deliveryStatus);
    const isPosCompletedOrder = isCompletedOrder(order.orderStatus) && isPosApp(order.app);
    const shouldRenderCancelButton =
        isPendingOrder(order.orderStatus) || isClosedOrder(order.orderStatus) || (isAcceptedOrder(order.orderStatus) && (acceptedDeliveryOrder || !hasDriver)) || isPosCompletedOrder;

    const handleClickCancelOrRejectAcceptedOrder = async () => {
        if (!isClosedOrder(order.orderStatus) || userHasRolePermission(RolePermissions.OPEN_CLOSED_ORDERS)) {
            cancelOrder();
            return;
        }
        const isAuthorized = await askForRolePermissionAuthorization({
            rolePermission: RolePermissions.OPEN_CLOSED_ORDERS,
            title: translate('Printed partial account'),
            subtitle: translate('Unblock the order with an authorized PIN'),
        });
        if (!isAuthorized) return;

        cancelOrder();
    };

    const cancelOrder = () => {
        const rejectOrder = window.confirm(translate('Are you sure you want to reject this order? Please note that the customer has been notified that the order has been accepted already.'));
        if (!rejectOrder) return;

        if (!userHasRolePermission(RolePermissions.CANCEL_ORDERS)) {
            return openVerifyPosPasswordDialog({
                onAuthorized: () => {
                    openRejectOrderDialog({
                        orderId: order.orderId,
                        onReject: (order: OrderVm) => {
                            onChange?.(order);
                        },
                    });
                },
                onUnauthorized: () => alert(translate('Unauthorized to access.')),
            });
        }
        openRejectOrderDialog({
            orderId: order.orderId,
            onReject: (order: OrderVm) => {
                onChange?.(order);
            },
        });
    };

    if (!shouldRenderCancelButton) return null;

    if (isPendingOrder(order.orderStatus)) {
        return (
            <Button secondary disabled={disabled} onClick={handleClickCancelOrRejectAcceptedOrder}>
                <Text error>{translate('Cancel order')}</Text>
            </Button>
        );
    }

    return (
        <div style={{ width: '100%', display: 'flex', alignItems: 'center' }}>
            <Button secondary key='3' disabled={disabled} onClick={handleClickCancelOrRejectAcceptedOrder}>
                <Text error>{isNewOrder(order.orderStatus) ? translate('Reject order') : translate('Cancel order')}</Text>
            </Button>
        </div>
    );
}

type Props = {
    order: OrderVm;
    disabled: boolean;
    onChange?: any;
};
