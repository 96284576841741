import { Grid, makeStyles, Typography } from '@material-ui/core';
import * as React from 'react';
import { Button } from 'src/components/Button';
import { Dialog } from 'src/components/Dialog';
import { DialogActions } from 'src/components/DialogActions';
import { translate } from 'src/i18n/translate';
import { useFormatAsRestaurantCurrencyNumber } from 'src/services/restaurant/useFormatAsRestaurantCurrencyNumber';
import type { BuyOrderSummary } from 'src/types/BuyOrderSummary';

export function BuyOrderSummaryDialog({ open, onClose, buyOrder, onSubmit, disabled, title }: Props): React.ReactElement | null {
    const classes = useStyles();
    const formatAsCurrencyNumber = useFormatAsRestaurantCurrencyNumber();

    const total = buyOrder?.supplies.reduce((acc: number, supply) => acc + Number(supply.total), 0);

    if (!buyOrder) return null;

    return (
        <Dialog open={open} onClose={onClose} title={title ?? translate('Please check that your purchase order is correct')}>
            <Grid container spacing={2} direction='column'>
                <Grid item>
                    <h1 className={classes.subTitle}>{translate('Supplier')}</h1>
                    <p>{buyOrder?.supplierBusinessName}</p>
                </Grid>
                <Grid item>
                    <h1 className={classes.subTitle}>{translate('Supplies')}</h1>
                </Grid>
                <Grid item className={classes.recipeSuppliesContainer}>
                    <Grid container>
                        <Grid item xs={12}>
                            <table className={classes.table}>
                                <tbody>
                                    {buyOrder?.supplies.map((supply, index: number) => (
                                        <tr key={index}>
                                            <td>{`${supply.name} (${supply.sku ?? ''})`}</td>
                                            <td>{`${supply.quantity} ${translate(supply.unitOfMeasurement)}`}</td>
                                            <td>{formatAsCurrencyNumber(supply.total)}</td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            <Grid container spacing={2} className={classes.buyOrderTotalContainer}>
                <Grid item md={6} className={classes.buyOrderTotal}>
                    <Typography variant='h6'>{translate('Articles: @articles', { articles: buyOrder?.supplies.length })}</Typography>
                    <hr className={classes.verticalSeparator} />
                    <Typography variant='h6'>{translate('Total: @total', { total: formatAsCurrencyNumber(total) })}</Typography>
                </Grid>
            </Grid>
            <DialogActions>
                <Button secondary text onClick={onClose}>
                    {translate('Cancel')}
                </Button>
                <Button onClick={onSubmit} disabled={disabled}>
                    {translate('Confirm')}
                </Button>
            </DialogActions>
        </Dialog>
    );
}

const useStyles = makeStyles((theme) => ({
    subTitle: {
        fontFamily: theme.typography.medium,
        fontSize: 20,
        fontWeight: 400,
        textAlign: 'left',
    },
    formContainer: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'right',
    },
    buyOrderTotalContainer: {
        marginTop: 20,
        display: 'flex',
        justifyContent: 'flex-end',
    },
    buyOrderTotal: {
        display: 'flex',
        justifyContent: 'space-around',
        alignItems: 'center',
        border: `0.1px solid ${theme.palette.secondary.dark}`,
        borderRadius: 8,
        padding: '5 !important',
    },
    recipeSuppliesContainer: {
        maxHeight: 200,
        overflowY: 'scroll',
    },
    table: {
        width: '100%',
        borderCollapse: 'collapse',
        '& td': {
            border: `0.1px solid ${theme.palette.secondary.dark}`,
            padding: 10,
        },
        '& th': {
            border: `0.1px solid ${theme.palette.secondary.dark}`,
            padding: 10,
        },
    },
    verticalSeparator: {
        border: `0.1px solid ${theme.palette.secondary.dark}`,
        borderLeft: `1px solid ${theme.palette.secondary.dark}`,
        height: 20,
    },
}));

type Props = {
    open: boolean;
    onClose: any;
    buyOrder?: BuyOrderSummary;
    onSubmit: any;
    disabled?: boolean;
    title?: string;
};
