import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { makeStyles } from '@material-ui/core/styles';
import * as React from 'react';
import { translate } from 'src/i18n/translate';

export function InformationAlertDialog({ openInformationAlert, handleClose, title, dialogContent }: Props): React.ReactElement {
    const classes = useStyles();

    return (
        <Dialog fullWidth scroll='paper' className={classes.form} open={openInformationAlert} onClose={handleClose} onExited={handleClose}>
            <DialogTitle classes={{ root: classes.dialogTitle }}>{title}</DialogTitle>
            <DialogContent className={classes.bodyText}>{dialogContent}</DialogContent>
            <DialogActions className={classes.buttonsContainer}>
                <Button color='primary' onClick={handleClose}>
                    <div className={classes.acceptButton}>{translate('Close')}</div>
                </Button>
            </DialogActions>
        </Dialog>
    );
}

const useStyles = makeStyles((theme) => ({
    dialogTitle: {
        textAlign: 'center',
        fontSize: 41,
        color: theme.palette.primary.main,
        paddingTop: theme.spacing(4),
        paddingBottom: theme.spacing(3),
        '& h2': {
            fontFamily: theme.typography.medium,
        },
    },
    bodyText: {
        fontFamily: theme.typography.medium,
        textAlign: 'center',
        color: theme.palette.secondary.contrastText,
    },
    buttonsContainer: {
        marginTop: 20,
        marginBottom: 20,
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
    },
    form: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    acceptButton: {
        backgroundColor: theme.palette.primary.main,
        color: 'white',
        fontFamily: theme.typography.semiBold,
        fontSize: 15,
        borderRadius: 5,
        height: 40,
        width: 200,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        textTransform: 'none',
        boxShadow: `3px 3px 10px ${theme.palette.primary.main}aa`,
    },
}));

type Props = {
    openInformationAlert: boolean;
    handleClose: any;
    title: string;
    dialogContent: string;
};
