import { makeStyles } from '@material-ui/core';
import * as React from 'react';
import { useState } from 'react';
import { Button } from 'src/components/Button';
import { Text } from 'src/components/Text';
import { translate } from 'src/i18n/translate';
import { classNames } from 'src/utils/react/classNames';

/**
 * @callback onClick
 */

/**
 *
 * @param array [listItems] - items showed inside the slider
 * @param {string} [listItems.imageUrl] - image showed inside the slider
 * @param onClick [onChange] - function to be called when the buttons in the slider are clicked
 * @returns {React.Node}
 */
export function Slider({ listItems, onChange }: Props): React.ReactElement {
    const classes = useStyles();

    const [currentImageIndex, setCurrentImageIndex] = useState(0);

    const goToNextImage = () => {
        setCurrentImageIndex(currentImageIndex + 1);
        onChange?.(currentImageIndex + 1);
    };

    const goToPrevImage = () => {
        setCurrentImageIndex(currentImageIndex - 1);
        onChange?.(currentImageIndex - 1);
    };

    return (
        <div className={classes.imageSliderContainer}>
            {currentImageIndex > 0 && (
                <Button onClick={goToPrevImage} classes={{ button: classes.buttonLeft }}>
                    &lt;
                </Button>
            )}
            {listItems[currentImageIndex]?.imageUrl && <img className={classes.imageItem} alt={listItems[currentImageIndex]?.imageUrl} src={listItems[currentImageIndex]?.imageUrl} />}
            {!listItems[currentImageIndex]?.imageUrl && (
                <div
                    className={classes.emptyImage}
                    /* @ts-ignore */
                    src={listItems[currentImageIndex]?.imageUrl}
                >
                    <Text>{translate('Image not available')}</Text>
                </div>
            )}

            {currentImageIndex < listItems.length - 1 && (
                <Button onClick={goToNextImage} classes={{ button: classes.buttonRight }}>
                    &gt;
                </Button>
            )}
            <div className={classes.indexContainer}>
                {listItems.map((_, index) => (
                    <div className={classNames({ [classes.indexDotInactive]: index !== currentImageIndex, [classes.indexDotActive]: index === currentImageIndex })}></div>
                ))}
            </div>
        </div>
    );
}
const useStyles = makeStyles((theme) => ({
    imageSliderContainer: {
        display: 'flex',
        flexDirection: 'column',
        gap: 12,
        alignItems: 'center',
        justifyContent: 'center',
        width: '500px',
        maxWidth: ' 100%',
        height: '443px',
        position: 'relative',
    },
    imageItem: {
        width: 500,
        height: 500,
        maxWidth: '100%',
        maxHeight: '100%',
        background: '#f1f1f1',
        backgroundColor: 'transparent',
        padding: 12,
    },
    buttonLeft: {
        position: 'absolute',
        top: '50%',
        transform: 'translateY(-50%)',
        background: 'none',
        border: 'none',
        fontSize: '24px',
        cursor: 'pointer',
        left: '10px',
        borderRadius: '50%',
        height: 42,
        width: 42,
        backgroundColor: 'whitesmoke',
        color: '#06B7A2',
        '&:hover': {
            backgroundColor: 'white',
        },
    },
    buttonRight: {
        position: 'absolute',
        top: '50%',
        transform: 'translateY(-50%)',
        background: 'none',
        border: 'none',
        fontSize: '24px',
        cursor: 'pointer',
        right: '10px',
        borderRadius: '50%',
        height: 42,
        width: 42,
        backgroundColor: 'whitesmoke',
        color: '#06B7A2',
        '&:hover': {
            backgroundColor: 'white',
        },
    },
    indexContainer: {
        height: 12,
        display: 'flex',
        gap: 4,
    },
    indexDotInactive: {
        width: 8,
        height: 8,
        backgroundColor: '#D9D9D9',
        borderRadius: '50%',
    },
    indexDotActive: {
        width: 8,
        height: 8,
        backgroundColor: '#06B7A2',
        borderRadius: '50%',
    },
    emptyImage: {
        width: 500,
        height: 500,
        maxWidth: '100%',
        maxHeight: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        padding: 12,
        backgroundColor: '#b6b6be',
    },
}));

type Props = {
    listItems: Array<{
        imageUrl?: string;
    }>;
    onChange?: any;
};
