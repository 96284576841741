import { makeStyles } from '@material-ui/core/styles';
import * as React from 'react';
import { classNames } from 'src/utils/react/classNames';

/**
 * Renders a stepper component to display a sequence of steps.
 * @param {number} activeStep - The index of the currently active step.
 * @param {Object[]} steps - An array of steps to be rendered in the stepper.
 * @param {number} steps[].value - The value associated with the step.
 * @param {string} steps[].label - The label of the step.
 * @returns {React.Node}
 * @constructor
 */

export function Stepper({ steps, activeStep, classes: classesProp }: Props): React.ReactElement {
    const classes = useStyles();

    const hasBeenActiveStep = (index: number) => index <= activeStep;
    const isActiveStep = (index: number) => index === activeStep;
    const isLastStep = (index: number) => index !== steps.length - 1;

    return (
        <div className={classes.stepper}>
            <div className={classes.stepProgress}>
                {steps.map((step, index) => (
                    <div key={step.value}>
                        <div className={classes.stepContent}>
                            <div className={classes.iconContent}>
                                {!classesProp?.stepIcon && <div className={classNames(classes.stepCircle, hasBeenActiveStep(index) && classes.activeStepCircle)}>{index + 1}</div>}
                                {!!classesProp?.stepIcon && (
                                    <div className={classNames(classesProp?.stepIcon, isActiveStep(index) ? classesProp?.stepActive : '')}>
                                        <div></div>
                                    </div>
                                )}
                            </div>
                            <div className={classNames(classes.stepLabel, hasBeenActiveStep(index) && classes.activeStepLabel)}>{step.label}</div>
                        </div>
                        {isLastStep(index) && <div className={index < activeStep ? classes.activeStepLine : classes.stepLine} />}
                    </div>
                ))}
            </div>
        </div>
    );
}

const useStyles = makeStyles((theme) => ({
    stepper: {
        width: '400px',
        margin: '0 auto',
    },
    stepProgress: {
        display: 'flex',
        flexDirection: 'column',
    },
    step: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'start',
    },
    stepContent: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'start',
    },
    iconContent: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        minWidth: 30,
    },
    stepCircle: {
        width: 30,
        height: 30,
        borderRadius: '50%',
        backgroundColor: 'transparent',
        border: `1px solid #969DA6`,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        fontFamily: theme.typography.semiBold,
        color: '#969DA6',
    },
    stepLabel: {
        marginLeft: '8px',
        fontSize: 10,
        color: '#969DA6',
        fontFamily: theme.typography.medium,
        [theme.breakpoints.up('sm')]: {
            fontSize: 12,
        },
        [theme.breakpoints.up('md')]: {
            fontSize: 14,
        },
    },
    stepLine: {
        backgroundColor: '#969DA6',
        height: 20,
        width: 2,
        marginLeft: 22,
        marginBottom: 5,
    },
    activeStepCircle: {
        backgroundColor: theme.palette.primary.main,
        border: `1px solid ${theme.palette.primary.main}`,
        color: 'white',
    },
    activeStepLabel: {
        color: '#0D3037',
    },
    activeStepLine: {
        backgroundColor: theme.palette.primary.main,
        height: 20,
        width: 2,
        marginLeft: 22,
        marginBottom: 5,
    },
}));

type Props = {
    activeStep: number;
    steps: Array<{
        value: number;
        label: string;
    }>;
    classes?: {
        stepIcon: string;
        stepActive: string;
    };
};
