export const SubscriptionPaymentErrorTypes = {
    NO_CARD: 'NO_CARD',
    PAYMENT_METHOD_SPEI: 'PAYMENT_METHOD_SPEI',
    NO_NEXT_PAYMENT_DATE: 'NO_NEXT_PAYMENT_DATE',
    NO_NEXT_PAYMENT_AMOUNT: 'NO_NEXT_PAYMENT_AMOUNT',
    FUTURE_PAYMENT_DATE: 'FUTURE_PAYMENT_DATE',
    PAYMENT_FAILED: 'PAYMENT_FAILED',
    UNKNOWN: 'UNKNOWN',
} as const;

export type SubscriptionPaymentErrorType = (typeof SubscriptionPaymentErrorTypes)[keyof typeof SubscriptionPaymentErrorTypes];
