import { AWSAppSyncClient } from 'aws-appsync';
import { getJwtToken } from 'src/api/utils/getJwtToken';
import { envAWS_APPSYNC_AUTHENTICATION_TYPE } from 'src/env/envAWS_APPSYNC_AUTHENTICATION_TYPE';
import { envAWS_APPSYNC_GRAPHQL_ENDPOINT } from 'src/env/envAWS_APPSYNC_GRAPHQL_ENDPOINT';
import { envAWS_APPSYNC_REGION } from 'src/env/envAWS_APPSYNC_REGION';

export const appsyncClient = new AWSAppSyncClient(
    {
        url: envAWS_APPSYNC_GRAPHQL_ENDPOINT(),
        region: envAWS_APPSYNC_REGION(),
        auth: {
            type: envAWS_APPSYNC_AUTHENTICATION_TYPE() as any,
            jwtToken: async () => (await getJwtToken()).jwtToken!, // TODO: handle case where getJwtToken throws error because of no internet connection
        },
        disableOffline: true,
    },
    // , {
    //     defaultOptions: {
    //         query: {
    //             fetchPolicy: 'network-only',
    //             errorPolicy: 'all',
    //         },
    //     },
    // }
);
