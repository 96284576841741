import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import FormHelperText from '@material-ui/core/FormHelperText';
import Grid from '@material-ui/core/Grid';
import { formatAsCurrencyNumber } from '@pidedirecto/ui/utils';
import moment from 'moment/moment';
import * as React from 'react';
import { useEffect } from 'react';
import { useForm, useWatch } from 'react-hook-form';
import { changeSubscriptionApi } from 'src/api/letseatadmin/subscription/changeSubscriptionApi';
import { getSubscriptionApi } from 'src/api/letseatadmin/subscription/getSubscriptionApi';
import { Form } from 'src/components/form/Form';
import { FormBrandAutocomplete } from 'src/components/form/FormBrandAutocomplete';
import { FormCurrencyNumberStringField } from 'src/components/form/FormCurrencyNumberStringField';
import { FormDatePicker } from 'src/components/form/FormDatePicker';
import { FormRestaurantAutocomplete } from 'src/components/form/FormRestaurantAutocomplete';
import { FormRestaurantsAutocomplete } from 'src/components/form/FormRestaurantsAutocomplete';
import { FormSubscriptionPaymentMethodSelect } from 'src/components/form/FormSubscriptionPaymentMethodSelect';
import { FormSubscriptionPlansSelect } from 'src/components/form/FormSubscriptionPlansSelect';
import { FormSubscriptionStatusesSelect } from 'src/components/form/FormSubscriptionStatusesSelect';
import { FormSwitch } from 'src/components/form/FormSwitch';
import { FormTextField } from 'src/components/form/FormTextField';
import { MomentFormats } from 'src/constants/MomentFormat';
import { SubscriptionPaymentMethods } from 'src/constants/SubscriptionPaymentMethod';
import { SubscriptionPlans } from 'src/constants/SubscriptionPlan';
import { translate } from 'src/i18n/translate';
import { appLayout } from 'src/theme/AppLayout';
import type { SubscriptionId } from 'src/types/Id';
import { alertKnownErrorOrSomethingWentWrong } from 'src/utils/alert/alertKnownErrorOrSomethingWentWrong';
import { withoutIva } from 'src/utils/number/withoutIva';
import { useLoadApi } from 'src/utils/react/useLoadApi';
import { useSelector } from 'src/utils/react/useSelector';
import { requireValue } from 'src/utils/require/requireValue';

export function ChangeSubscriptionDialog({ open, subscriptionId, onClose, onChangeSubscription }: Props): React.ReactElement {
    const form = useForm();
    const {
        formState: { isSubmitting },
        control,
    } = form;

    const settingPayPerUseRange1OrdersLimit = useSelector((state) => state.app2.settings.payPerUseRange1OrdersLimit);
    const settingPayPerUseRange1PaymentAmount = useSelector((state) => state.app2.settings.payPerUseRange1PaymentAmount);
    const settingPayPerUseRange2OrdersLimit = useSelector((state) => state.app2.settings.payPerUseRange2OrdersLimit);
    const settingPayPerUseRange2PaymentAmount = useSelector((state) => state.app2.settings.payPerUseRange2PaymentAmount);
    const settingPayPerUseRangeMaxOrdersLimit = useSelector((state) => state.app2.settings.payPerUseRangeMaxOrdersLimit);
    const settingPayPerUseRangeMaxPaymentAmount = useSelector((state) => state.app2.settings.payPerUseRangeMaxPaymentAmount);

    const plan = useWatch({ name: 'plan', control });
    const subscriptionPaymentMethod = useWatch({ name: 'subscriptionPaymentMethod', control });
    const nextPaymentDate = useWatch({ name: 'nextPaymentDate', control });
    const payPerUseRange1PaymentAmount = useWatch<string>({ name: 'payPerUseRange1PaymentAmount', control });
    const payPerUseRange2PaymentAmount = useWatch<string>({ name: 'payPerUseRange2PaymentAmount', control });
    const payPerUseRangeMaxPaymentAmount = useWatch<string>({ name: 'payPerUseRangeMaxPaymentAmount', control });

    const isCommission = plan === SubscriptionPlans.COMMISSION;
    const isPayPerUse = plan === SubscriptionPlans.PAY_PER_USE;
    const isDebt = subscriptionPaymentMethod === SubscriptionPaymentMethods.DEBT;

    const handleClose = () => {
        if (isSubmitting) return;
        onClose();
    };

    const [loading, subscription] = useLoadApi(getSubscriptionApi, { subscriptionId: subscriptionId as any }, { requiredValues: [subscriptionId] });

    const hasPaymentDate = !!subscription?.nextPaymentDate;

    useEffect(() => {
        if (subscription) {
            form.reset({
                subscriptionId: subscription.subscriptionId ?? null,
                restaurantId: subscription.restaurantId ?? null,
                restaurantIds: subscription.restaurantIds ?? [],
                brandId: subscription.brandId ?? null,
                managerMessage: subscription.managerMessage ?? null,
                comment: subscription.comment ?? null,
                invoiceDescription: subscription.invoiceDescription ?? null,
                subscriptionPaymentMethod: subscription.subscriptionPaymentMethod ?? null,
                status: subscription.status ?? null,
                plan: subscription.plan ?? null,
                // TODO: manualOrders, webOrders, cutServiceDate are not in SubscriptionVm
                manualOrders: (subscription as any).manualOrders ?? null,
                webOrders: (subscription as any).webOrders ?? null,
                payPerUseRange1PaymentAmount: subscription.payPerUseRange1PaymentAmount ?? null,
                payPerUseRange2PaymentAmount: subscription.payPerUseRange2PaymentAmount ?? null,
                payPerUseRangeMaxPaymentAmount: subscription.payPerUseRangeMaxPaymentAmount ?? null,
                paymentAmount: subscription.paymentAmount ?? null,
                nextPaymentDate: subscription.nextPaymentDate ?? null,
                nextPaymentAmount: subscription.nextPaymentAmount ?? null,
                cutServiceDate: (subscription as any).cutServiceDate ?? null,
                startServiceDate: subscription.startServiceDate ?? null,
                endServiceDate: subscription.endServiceDate ?? null,
                financeReference: subscription?.financeReference ?? null,
                financeReferenceEnabled: subscription?.financeReferenceEnabled ?? null,
            });
        }
    }, [subscription]);
    const onSubmit = async (form: any) => {
        const subscriptionPaymentMethod = isPayPerUse ? SubscriptionPaymentMethods.DEBT : form.subscriptionPaymentMethod;
        const response = await changeSubscriptionApi({
            subscriptionId: requireValue(subscriptionId),
            restaurantId: form.restaurantId,
            restaurantIds: form.restaurantIds,
            brandId: form.brandId,
            managerMessage: form.managerMessage,
            comment: form.comment,
            invoiceDescription: form.invoiceDescription,
            subscriptionPaymentMethod: subscriptionPaymentMethod,
            status: form.status,
            plan: form.plan,
            manualOrders: form.manualOrders,
            webOrders: form.webOrders,
            payPerUseRange1PaymentAmount: form.payPerUseRange1PaymentAmount,
            payPerUseRange2PaymentAmount: form.payPerUseRange2PaymentAmount,
            payPerUseRangeMaxPaymentAmount: form.payPerUseRangeMaxPaymentAmount,
            paymentAmount: form.paymentAmount,
            nextPaymentDate: form.nextPaymentDate,
            nextPaymentAmount: form.nextPaymentAmount,
            cutServiceDate: form.cutServiceDate,
            startServiceDate: form.startServiceDate,
            endServiceDate: form.endServiceDate,
            financeReferenceEnabled: form.financeReferenceEnabled,
        });
        if (!response.ok) {
            alertKnownErrorOrSomethingWentWrong(response);
            return;
        }
        onChangeSubscription();
        onClose();
    };

    return (
        <Dialog fullWidth scroll='paper' open={open} onClose={handleClose}>
            <Form form={form} onSubmit={onSubmit} style={appLayout.dialog.form}>
                <DialogTitle>{translate('Change Subscription').toUpperCase()}</DialogTitle>
                <DialogContent>
                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                            <FormRestaurantAutocomplete name='restaurantId' label={translate('Main Restaurant')} required />
                        </Grid>
                        <Grid item xs={12}>
                            <FormRestaurantsAutocomplete name='restaurantIds' label={translate('Restaurants')} required />
                        </Grid>
                        <Grid item xs={12}>
                            <FormBrandAutocomplete name='brandId' label={translate('Brand')} helperText={translate('Create and manage brands at https://admin.pidedirecto.mx/admin/brands')} />
                        </Grid>
                        <Grid item xs={12}>
                            <FormTextField
                                name='managerMessage'
                                label={translate('Manager Message')}
                                helperText={translate('This text is will be shown right under the header in the subscriptions page for restaurants')}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <FormTextField name='comment' label={translate('Comment')} />
                        </Grid>
                        <Grid item xs={12}>
                            <FormTextField name='invoiceDescription' label={translate('Invoice Description')} />
                        </Grid>
                        <Grid item container alignItems='center' spacing={3} xs={12} sm={12}>
                            <Grid item md={6} xs={6}>
                                <FormTextField name='financeReference' label={translate('Finance Reference')} tooltip={translate('Reference number used by finance')} disabled={true} />
                            </Grid>
                            <Grid item md={6} xs={6}>
                                <FormSwitch name='financeReferenceEnabled' label={translate('Habilitar referencia y Clabe Elevate')} tooltip={translate('Enable this to use the finance reference')} />
                            </Grid>
                        </Grid>
                        <Grid item xs={12}>
                            <FormSubscriptionPaymentMethodSelect disabled={isPayPerUse} name='subscriptionPaymentMethod' label={translate('Payment Method')} required />
                        </Grid>
                        <Grid item xs={12}>
                            <FormSubscriptionStatusesSelect name='status' label={translate('Status')} />
                        </Grid>
                        <Grid item xs={12}>
                            <FormSubscriptionPlansSelect name='plan' label={translate('Plan')} />
                        </Grid>
                        <Grid item xs={6}>
                            <FormSwitch name='manualOrders' label={translate('Manual OrdersPage (Pide Directo)')} />
                            <FormHelperText>
                                <FormHelperText>{translate('Enable this to let restaurant create their own delivery orders from manager')}</FormHelperText>
                            </FormHelperText>
                        </Grid>
                        <Grid item xs={6}>
                            <FormSwitch name='webOrders' label={translate('Web OrdersPage (Pide Directo)')} />
                            <FormHelperText>
                                {translate(
                                    'Enable this to let make restaurant web menu available at https://urlsubdomain.pidedirecto.mx/urlpathname. Don´t forget to set Url Subdomain and Url Pathname below.',
                                )}
                            </FormHelperText>
                        </Grid>
                        {isPayPerUse && (
                            <>
                                <Grid item xs={12}>
                                    <FormCurrencyNumberStringField
                                        name='payPerUseRange1PaymentAmount'
                                        label={translate('Range 1 Payment Amount')}
                                        placeholder={settingPayPerUseRange1PaymentAmount}
                                        helperText={translate(
                                            `A manual earning of -@amount (-@amountWithoutIva without IVA) will be created once any restaurant in this subscription completed @orders delivery orders (rappi, didi, ubereats orders not counted) within the month period.`,
                                            {
                                                amount: formatAsCurrencyNumber(payPerUseRange1PaymentAmount || settingPayPerUseRange1PaymentAmount),
                                                amountWithoutIva: formatAsCurrencyNumber(withoutIva(payPerUseRange1PaymentAmount || settingPayPerUseRange1PaymentAmount)),
                                                orders: settingPayPerUseRange1OrdersLimit,
                                            },
                                        )}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <FormCurrencyNumberStringField
                                        name='payPerUseRange2PaymentAmount'
                                        label={translate('Range 2 Payment Amount')}
                                        placeholder={settingPayPerUseRange2PaymentAmount}
                                        helperText={translate(
                                            `A manual earning of -@amount (-@amountWithoutIva without IVA) will be created once any restaurant in this subscription completed @orders delivery orders (rappi, didi, ubereats orders not counted) within the month period.`,
                                            {
                                                amount: formatAsCurrencyNumber(payPerUseRange2PaymentAmount || settingPayPerUseRange2PaymentAmount),
                                                amountWithoutIva: formatAsCurrencyNumber(withoutIva(payPerUseRange2PaymentAmount || settingPayPerUseRange2PaymentAmount)),
                                                orders: settingPayPerUseRange2OrdersLimit,
                                            },
                                        )}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <FormCurrencyNumberStringField
                                        name='payPerUseRangeMaxPaymentAmount'
                                        label={translate('Range Max Payment Amount')}
                                        placeholder={settingPayPerUseRangeMaxPaymentAmount}
                                        helperText={translate(
                                            `A manual earning of -@amount (-@amountWithoutIva without IVA) will be created once any restaurant in this subscription completed @orders delivery orders (rappi, didi, ubereats orders not counted) within the month period.`,
                                            {
                                                amount: formatAsCurrencyNumber(payPerUseRangeMaxPaymentAmount || settingPayPerUseRangeMaxPaymentAmount),
                                                amountWithoutIva: formatAsCurrencyNumber(withoutIva(payPerUseRangeMaxPaymentAmount || settingPayPerUseRangeMaxPaymentAmount)),
                                                orders: settingPayPerUseRangeMaxOrdersLimit,
                                            },
                                        )}
                                    />
                                </Grid>
                            </>
                        )}
                        {!isPayPerUse && !isCommission && (
                            <>
                                <Grid item xs={12}>
                                    <FormCurrencyNumberStringField name='paymentAmount' label={translate('Amount')} disabled={isCommission} required />
                                </Grid>
                            </>
                        )}
                        <Grid item xs={12}>
                            <FormDatePicker
                                name='nextPaymentDate'
                                label={translate('Next Payment Date')}
                                disabled={isPayPerUse && hasPaymentDate}
                                helperText={
                                    isPayPerUse && nextPaymentDate
                                        ? translate('Current payment period @fromDate to @toDate', {
                                              fromDate: moment(nextPaymentDate).subtract(1, 'month').format(MomentFormats.ISO_DATE),
                                              toDate: moment(nextPaymentDate).format(MomentFormats.ISO_DATE),
                                          })
                                        : undefined
                                }
                            />
                        </Grid>
                        {!isPayPerUse && (
                            <>
                                <Grid item xs={12}>
                                    <FormCurrencyNumberStringField name='nextPaymentAmount' label={translate('Next Payment Amount')} />
                                </Grid>
                                <Grid item xs={12}>
                                    <FormDatePicker name='cutServiceDate' label={translate('Cut Service Date')} />
                                </Grid>
                                <Grid item xs={12}>
                                    <FormDatePicker name='startServiceDate' label={translate('Start Service Date')} />
                                </Grid>
                                <Grid item xs={12}>
                                    <FormDatePicker name='endServiceDate' label={translate('End Service Date')} />
                                </Grid>
                            </>
                        )}
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} disabled={isSubmitting}>
                        {translate('Cancel').toUpperCase()}
                    </Button>
                    <Button color='primary' type='submit' disabled={loading || isSubmitting}>
                        {isSubmitting ? translate('Changing').toUpperCase() : translate('Change').toUpperCase()}
                    </Button>
                </DialogActions>
            </Form>
        </Dialog>
    );
}

type Props = {
    open: boolean;
    subscriptionId: SubscriptionId | undefined;
    onClose: any;
    onChangeSubscription: any;
};
