import * as React from 'react';

export function PideDirectoCircleIcon({ title, width, height }: Props): React.ReactElement {
    return (
        <svg width={width || '40'} height={height || '40'} viewBox={`0 0 41 41`} fill='none' xmlns='http://www.w3.org/2000/svg'>
            {title && <title>{title}</title>}

            <circle cx='20' cy='20' r='19.25' fill='#EBD9FF' stroke='#8D25FF' strokeWidth='1.5' />
            <path
                d='M13.1162 19.9481C13.1162 17.5506 14.323 15.2697 16.2564 13.9997L16.4904 13.8442L15.3082 11.8354L15.0742 11.991C12.4882 13.6887 10.875 16.7341 10.875 19.9481C10.875 23.2528 12.5498 26.3501 15.2589 28.0218L15.4929 28.1644L16.6382 26.1298L16.4042 25.9872C14.36 24.7301 13.1162 22.4104 13.1162 19.9481Z'
                fill='#8D25FF'
            />
            <path
                d='M24.7443 11.978L24.5098 11.8354L23.3621 13.8701L23.5966 14.0126C25.6205 15.2697 26.8792 17.5765 26.8792 20.0518C26.8792 22.4493 25.6699 24.7301 23.7324 26.0002L23.4979 26.1557L24.6826 28.1644L24.9171 28.0089C27.5086 26.3112 29.1252 23.2657 29.1252 20.0518C29.1376 16.7471 27.4469 13.6498 24.7443 11.978Z'
                fill='#8D25FF'
            />
        </svg>
    );
}

type Props = {
    title?: string;
    width?: number;
    height?: number;
};
