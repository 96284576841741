import { makeStyles } from '@material-ui/core';
import LinearProgress from '@material-ui/core/LinearProgress';
import { withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import FavoriteIcon from '@material-ui/icons/Favorite';
import Rating from '@material-ui/lab/Rating';
import * as React from 'react';
import type { SurveysVm } from 'src/api/letseatmanager/survey/getSurveysApi';
import { NegativeSurveyAnswers } from 'src/constants/NegativeSurveyAnswer';
import { PositiveSurveyAnswers } from 'src/constants/PositiveSurveyAnswer';
import { translate } from 'src/i18n/translate';
import 'src/scenes/letseatmanager/surveys/RatingDetails.css';
import { classNames } from 'src/utils/react/classNames';
import { translateSurveyAnswer } from 'src/utils/translate/translateSurveyAnswer';

export default function RatingDetails({ vm }: Props): React.ReactElement {
    const classes = useStyles();

    return (
        <div className={classes.container}>
            <div className={classes.ratingDetails}>
                <h1 className={classes.title}>{translate('Customer reviews')}</h1>
                <HeartRating
                    name='customized-color'
                    value={vm.averageRating}
                    getLabelText={(value) => `${value} Heart${value !== 1 ? 's' : ''}`}
                    precision={0.01}
                    readOnly={true}
                    icon={<FavoriteIcon fontSize='inherit' />}
                />
                <div className={classes.ratingsContainer}>
                    <div className={classes.ratingSection}>
                        <h1 className={classes.title}>{translate('Rating')}</h1>
                        <div className={classes.ratingContainer}>
                            <span className={classes.text} style={{ width: '28%' }}>
                                {translate('5 hearts')}
                            </span>
                            <BorderLinearProgress value={Math.round((vm.rating['5'] / (vm.withRating || 1)) * 100)} variant='determinate' />
                            <span className={classes.text}>{vm.rating['5']}</span>
                        </div>
                        <div className={classes.ratingContainer}>
                            <span className={classes.text} style={{ width: '28%' }}>
                                {translate('4 hearts')}
                            </span>
                            <BorderLinearProgress value={Math.round((vm.rating['4'] / (vm.withRating || 1)) * 100)} variant='determinate' />
                            <span className={classes.text}>{vm.rating['4']}</span>
                        </div>
                        <div className={classes.ratingContainer}>
                            <span className={classes.text} style={{ width: '28%' }}>
                                {translate('3 hearts')}
                            </span>
                            <BorderLinearProgress value={Math.round((vm.rating['3'] / (vm.withRating || 1)) * 100)} variant='determinate' />
                            <span className={classes.text}>{vm.rating['3']}</span>
                        </div>
                        <div className={classes.ratingContainer}>
                            <span className={classes.text} style={{ width: '28%' }}>
                                {translate('2 hearts')}
                            </span>
                            <BorderLinearProgress value={Math.round((vm.rating['2'] / (vm.withRating || 1)) * 100)} variant='determinate' />
                            <span className={classes.text}>{vm.rating['2']}</span>
                        </div>
                        <div className={classes.ratingContainer}>
                            <span className={classes.text} style={{ width: '28%' }}>
                                {translate('1 heart')}
                            </span>
                            <BorderLinearProgress value={Math.round((vm.rating['1'] / (vm.withRating || 1)) * 100)} variant='determinate' />
                            <span className={classes.text}>{vm.rating['1']}</span>
                        </div>
                        <p className={classes.text}>
                            {translate('@avarage average based on @reviews reviews (@dismissed of @total dismissed)', {
                                avarage: `${vm.averageRating ?? 0}`,
                                reviews: `${vm.withRating ?? 0}`,
                                dismissed: `${vm.dismissed ?? 0}`,
                                total: `${vm.withRating ?? 0}`,
                            })}
                        </p>
                    </div>
                    <div className={classes.ratingSection}>
                        <h1 className={classes.title}>{translate('Recommendation')}</h1>
                        <div className={classes.ratingContainer}>
                            <span className={classes.text} style={{ width: '28%' }}>
                                {translate('5 hearts')}
                            </span>
                            <BorderLinearProgress value={Math.round((vm.recommendationRating['5'] / (vm.withRating || 1)) * 100)} variant='determinate' />
                            <span className={classes.text}>{vm.recommendationRating['5']}</span>
                        </div>
                        <div className={classes.ratingContainer}>
                            <span className={classes.text} style={{ width: '28%' }}>
                                {translate('4 hearts')}
                            </span>
                            <BorderLinearProgress value={Math.round((vm.recommendationRating['4'] / (vm.withRating || 1)) * 100)} variant='determinate' />
                            <span className={classes.text}>{vm.recommendationRating['4']}</span>
                        </div>
                        <div className={classes.ratingContainer}>
                            <span className={classes.text} style={{ width: '28%' }}>
                                {translate('3 hearts')}
                            </span>
                            <BorderLinearProgress value={Math.round((vm.recommendationRating['3'] / (vm.withRating || 1)) * 100)} variant='determinate' />
                            <span className={classes.text}>{vm.recommendationRating['3']}</span>
                        </div>
                        <div className={classes.ratingContainer}>
                            <span className={classes.text} style={{ width: '28%' }}>
                                {translate('2 hearts')}
                            </span>
                            <BorderLinearProgress value={Math.round((vm.recommendationRating['2'] / (vm.withRating || 1)) * 100)} variant='determinate' />
                            <span className={classes.text}>{vm.recommendationRating['2']}</span>
                        </div>
                        <div className={classes.ratingContainer}>
                            <span className={classes.text} style={{ width: '28%' }}>
                                {translate('1 heart')}
                            </span>
                            <BorderLinearProgress value={Math.round((vm.recommendationRating['1'] / (vm.withRating || 1)) * 100)} variant='determinate' />
                            <span className={classes.text}>{vm.recommendationRating['1']}</span>
                        </div>
                        <p className={classes.text}>
                            {translate('@avarage average based on @reviews reviews (@dismissed of @total dismissed)', {
                                avarage: `${vm.averageRecommendationRating ?? 0}`,
                                reviews: `${vm.withRating ?? 0}`,
                                dismissed: `${vm.dismissed ?? 0}`,
                                total: `${vm.withRating ?? 0}`,
                            })}
                        </p>
                    </div>
                </div>
            </div>

            <Table size='small' aria-label='a dense table' className={classes.topTable}>
                <TableHead>
                    <TableRow>
                        <TableCell className={classNames(classes.title, classes.tableHeader)} style={{ border: 0, fontSize: 14 }}>
                            {translate('Answer (Good Experience)')}
                        </TableCell>
                        <TableCell className={classNames(classes.title, classes.tableHeader)} style={{ border: 0, fontSize: 14 }} align='right'>
                            {translate('Answers')}
                        </TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {vm.surveyAnswers &&
                        Object.entries(vm.surveyAnswers)
                            .filter(([surveyAnswer, answers]: [any, number]) => Object.values(PositiveSurveyAnswers).includes(surveyAnswer))
                            .map(([surveyAnswer, count]: [any, any]) => (
                                <TableRow key={surveyAnswer}>
                                    <TableCell component='th' scope='row' style={{ border: 0 }} className={classes.text}>
                                        {translateSurveyAnswer(surveyAnswer as any)}
                                    </TableCell>
                                    <TableCell style={{ border: 0 }} align='right' className={classes.text}>
                                        {count}
                                    </TableCell>
                                </TableRow>
                            ))}
                </TableBody>
            </Table>
            <Table size='small' aria-label='a dense table' className={classes.middleTable}>
                <TableHead>
                    <TableRow>
                        <TableCell className={classNames(classes.title, classes.tableHeader)} style={{ border: 0, fontSize: 14 }}>
                            {translate('Answer (Bad Experience)')}
                        </TableCell>
                        <TableCell align='right' className={classNames(classes.title, classes.tableHeader)} style={{ border: 0, fontSize: 14 }}>
                            {translate('Answers')}
                        </TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {vm.surveyAnswers &&
                        Object.entries(vm.surveyAnswers)
                            .filter(([surveyAnswer, answers]: [any, number]) => Object.values(NegativeSurveyAnswers).includes(surveyAnswer))
                            .map(([surveyAnswer, count]: [any, any]) => (
                                <TableRow key={surveyAnswer}>
                                    <TableCell component='th' scope='row' style={{ border: 0 }} className={classes.text}>
                                        {translateSurveyAnswer(surveyAnswer as any)}
                                    </TableCell>
                                    <TableCell align='right' style={{ border: 0 }} className={classes.text}>
                                        {count}
                                    </TableCell>
                                </TableRow>
                            ))}
                </TableBody>
            </Table>
            <Table size='small' aria-label='a dense table' className={classes.middleTable}>
                <TableHead>
                    <TableRow>
                        <TableCell className={classNames(classes.title, classes.tableHeader)} style={{ border: 0, fontSize: 14 }}>
                            {translate('Other Answers (Good Experience)')}
                        </TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {vm.otherPositiveAnswers &&
                        vm.otherPositiveAnswers.length > 0 &&
                        vm.otherPositiveAnswers.map((otherAnswer) => (
                            <TableRow key={otherAnswer}>
                                <TableCell component='th' scope='row' style={{ border: 0 }} className={classes.text}>
                                    {otherAnswer}
                                </TableCell>
                            </TableRow>
                        ))}
                    {(!vm.otherPositiveAnswers || vm.otherPositiveAnswers.length === 0) && (
                        <TableRow>
                            <TableCell component='th' scope='row' style={{ border: 0 }} className={classes.text}>
                                -
                            </TableCell>
                        </TableRow>
                    )}
                </TableBody>
            </Table>
            <Table size='small' aria-label='a dense table' className={classes.bottomTable}>
                <TableHead>
                    <TableRow>
                        <TableCell className={classNames(classes.title, classes.tableHeader)} style={{ border: 0, fontSize: 14 }}>
                            {translate('Other Answers (Bad Experience)')}
                        </TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {vm.otherNegativeAnswers &&
                        vm.otherNegativeAnswers.length > 0 &&
                        vm.otherNegativeAnswers.map((otherAnswer) => (
                            <TableRow key={otherAnswer}>
                                <TableCell component='th' scope='row'>
                                    {otherAnswer}
                                </TableCell>
                            </TableRow>
                        ))}
                    {(!vm.otherNegativeAnswers || vm.otherNegativeAnswers.length === 0) && (
                        <TableRow>
                            <TableCell component='th' scope='row' style={{ border: 0 }} className={classes.text}>
                                -
                            </TableCell>
                        </TableRow>
                    )}
                </TableBody>
            </Table>
        </div>
    );
}

const HeartRating = withStyles((theme) => ({
    iconFilled: {
        color: theme.palette.icons.brand,
    },
    iconHover: {
        color: theme.palette.icons.brand,
    },
}))(Rating);

const BorderLinearProgress = withStyles((theme) => ({
    root: {
        height: 12,
        borderRadius: 5,
        width: '60%',
    },
    colorPrimary: {
        backgroundColor: theme.palette.surface.brand,
    },
    bar: {
        borderRadius: 5,
        backgroundColor: theme.palette.surface.brandContrast,
    },
}))(LinearProgress);

const useStyles = makeStyles((theme) => ({
    container: {
        display: 'flex',
        width: '100%',
        flexDirection: 'column',
        alignItems: 'center',
    },
    ratingDetails: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        width: '100%',
        padding: '0 40px',
        paddingTop: 20,
    },
    title: {
        fontFamily: theme.typography.semiBold,
        fontSize: 15,
        marginBottom: 15,
    },
    text: {
        fontFamily: theme.typography.regular,
        fontSize: 13,
    },
    ratingsContainer: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-around',
        flexWrap: 'wrap',
        width: '100%',
    },
    ratingSection: {
        width: '40%',
        minWidth: 300,
    },
    ratingContainer: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        width: '100%',
        justifyContent: 'space-between',
        marginBottom: 5,
    },
    topTable: {
        width: '80%',
        marginTop: 20,
        border: `1px solid ${theme.palette.border.primary}`,
        borderBottom: 0,
        borderTopLeftRadius: 12,
        borderTopRightRadius: 12,
        borderCollapse: 'separate',
        paddingTop: 10,
        [theme.breakpoints.down('sm')]: {
            width: '100%',
            border: 0,
        },
    },
    middleTable: {
        width: '80%',
        border: `1px solid ${theme.palette.border.primary}`,
        borderBottom: 0,
        borderTop: 0,
        borderCollapse: 'separate',
        [theme.breakpoints.down('sm')]: {
            width: '100%',
            border: 0,
        },
    },
    bottomTable: {
        width: '80%',
        border: `1px solid ${theme.palette.border.primary}`,
        borderTop: 0,
        borderBottomLeftRadius: 12,
        borderBottomRightRadius: 12,
        borderCollapse: 'separate',
        marginBottom: 20,
        [theme.breakpoints.down('sm')]: {
            width: '100%',
            border: 0,
        },
    },
    tableHeader: {
        backgroundColor: theme.palette.surface.tertiary,
    },
}));

type Props = {
    vm: SurveysVm;
};
