import { letseatmanagerApiMethod } from 'src/api/letseatmanagerApi';
import type { ApiSauceResponse } from 'src/api/types/ApiSauceResponse';
import type { CourseId } from 'src/types/Id';

export async function removeCourseApi(request: RemoveCourseApiRequest): ApiSauceResponse<void> {
    return letseatmanagerApiMethod('course/removeCourseApi', request);
}

export type RemoveCourseApiRequest = {
    courseId: CourseId;
};
