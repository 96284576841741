import * as React from 'react';
import { SubscribedPage } from 'src/components/page/SubscribedPage';
import type { RolePermission } from 'src/constants/RolePermission';
import { useValidatePageAccess } from 'src/utils/react/useValidatePageAccess';

export function SecuredAndSubscribedPage({ title, rolePermission, children, className }: Props): React.ReactElement | null {
    const [validatingPageAccess] = useValidatePageAccess(rolePermission);

    if (validatingPageAccess) return null;

    return (
        <SubscribedPage title={title} className={className}>
            {children}
        </SubscribedPage>
    );
}

type Props = {
    title: string;
    rolePermission: RolePermission;
    children: React.ReactNode;
    className?: string;
};
