import { FormSelect } from '@pidedirecto/ui/form';
import * as React from 'react';
import { RefundMethods } from 'src/constants/RefundMethod';
import { translate } from 'src/i18n/translate';

export function FormRefundMethodSelect({ name, label, placeholder, helperText, defaultValue, disabled, required }: Props): React.ReactElement {
    const options = required
        ? Object.values(RefundMethods).map((refundMethod) => ({ label: translate(`RefundMethods.${refundMethod}`), value: refundMethod }))
        : [{ label: '', value: null }, ...Object.values(RefundMethods).map((refundMethod: any) => ({ label: translate(`RefundMethods.${refundMethod}`), value: refundMethod }))];

    return (
        <FormSelect
            name={name}
            label={label}
            placeholder={placeholder}
            helperText={helperText}
            defaultValue={defaultValue}
            disabled={disabled}
            required={{
                value: !!required,
                message: translate('This field is required'),
            }}
            options={options}
        />
    );
}

type Props = {
    name: string;
    label?: string;
    placeholder?: string;
    helperText?: string;
    defaultValue?: string | null | undefined;
    disabled?: boolean;
    required?: boolean;
};
