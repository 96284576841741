import { Button, makeStyles } from '@material-ui/core';
import { Loader } from '@pidedirecto/ui';
import * as React from 'react';
import { translate } from 'src/i18n/translate';
import { useSelector } from 'src/utils/react/useSelector';

export function BuyOrderContainer({ children, loading, disabled, handlePressContinue }: Props): React.ReactElement {
    const classes = useStyles();

    const buyOrders = useSelector((state) => state.inventory.buyOrders);
    const supplies = useSelector((state) => state.inventory.supplies);

    const getKnownProducts = () => {
        const knownProducts = buyOrders.filter((buyOrderPerSupply) => supplies.some((supply) => supply.sku === buyOrderPerSupply.sku));
        return knownProducts.length;
    };

    const getUnknownProducts = () => {
        const unknownProducts = buyOrders.filter((buyOrderPerSupply) => !supplies.some((supply) => supply.sku === buyOrderPerSupply.sku));
        return unknownProducts.length;
    };

    return (
        <div className={classes.container}>
            <section className={classes.leftSide}>{children}</section>
            <aside className={classes.rightSide}>
                <h2 className={classes.title} style={{ marginBottom: 0 }}>
                    {translate('Resume')}
                </h2>
                {!buyOrders?.length && !loading && <p className={classes.text}>{translate('Here you will verify the information to continue')}</p>}
                {loading && (
                    <div className={classes.loaderContainer}>
                        <Loader size={30} loading={true} />;
                    </div>
                )}
                {!!buyOrders?.length && !loading && (
                    <ul className={classes.list} style={{ marginTop: 30 }}>
                        <li className={classes.text}>{translate('@num products found', { num: getKnownProducts() })}</li>
                        <li className={classes.text}>{translate('@num unknown products', { num: getUnknownProducts() })}</li>
                        <li className={classes.text}>{translate('@num total products', { num: buyOrders.length })}</li>
                    </ul>
                )}
                <Button classes={{ root: classes.button }} disabled={disabled} onClick={handlePressContinue} style={{ marginTop: 20 }}>
                    {translate('Continue')}
                </Button>
            </aside>
        </div>
    );
}

const useStyles = makeStyles((theme) => ({
    container: {
        width: '100vw',
        height: '100%',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
    },
    leftSide: {
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        padding: 40,
    },
    rightSide: {
        width: '20%',
        display: 'flex',
        flexDirection: 'column',
        backgroundColor: '#ECEFF0',
        height: '100%',
        padding: '40px 20px',
        alignItems: 'center',
        flexShrink: 0,
        minWidth: 250,
    },
    title: {
        fontFamily: theme.typography.medium,
        fontSize: 25,
        width: '100%',
    },
    text: {
        fontFamily: theme.typography.regular,
        fontSize: 15,
    },
    list: {
        marginTop: 10,
        listStyleType: 'none',
        padding: 0,
        width: '100%',
    },
    button: {
        backgroundColor: theme.palette.primary.main,
        color: 'white',
        fontFamily: theme.typography.semiBold,
        fontSize: 16,
        borderRadius: 5,
        height: 40,
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        textTransform: 'none',
        marginTop: 30,
        '&:hover': {
            backgroundColor: theme.palette.primary.dark,
        },
    },
    loaderContainer: {
        marginTop: 20,
    },
}));

type Props = {
    children: React.ReactNode;
    loading: boolean;
    disabled: boolean;
    handlePressContinue: any;
};
