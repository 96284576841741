import { letseatmanagerApiMethod } from 'src/api/letseatmanagerApi';
import type { ApiSauceResponse } from 'src/api/types/ApiSauceResponse';
import type { CompoundSupplyProductionVm } from 'src/types/CompoundSupplyProductionVm';
import { CompoundSupplyReportId } from 'src/types/Id';

export async function getCompoundSupplyProductionsApi(request: GetCompoundSupplyProductionsApiRequest): ApiSauceResponse<Array<CompoundSupplyProductionVm>> {
    return letseatmanagerApiMethod('compoundSupplyProduction/getCompoundSupplyProductionsApi', request);
}

type GetCompoundSupplyProductionsApiRequest = {
    compoundSupplyReportId: CompoundSupplyReportId;
};
