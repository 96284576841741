import { Sound, Sounds } from 'src/constants/Sound';
import { getCachedAudio } from 'src/utils/sound/playAlertSound';

let alreadyConfigured = false;

export async function configureAllSounds(): Promise<void> {
    if (alreadyConfigured) return;
    alreadyConfigured = true;
    Object.values(Sounds).forEach((sound: any) => enableSound(sound));
}

export async function enableSound(sound: Sound): Promise<void> {
    if (sound === Sounds.NONE) return;
    const audio = await getCachedAudio(sound);
    audio.muted = true;
    try {
        await audio.play();
    } catch (e: any) {
        console.log(`Playing alert sound ${sound} failed for some reason error=`, e);
    }
    audio.pause();
    audio.muted = false;
}
