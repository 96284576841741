import { createSlice } from 'redux-starter-kit';
import type { RestaurantType } from 'src/constants/RestaurantType';
import type { KitchenId, RestaurantMenuId } from 'src/types/Id';
import type { ManagerMenuItemVm } from 'src/types/ManagerMenuItemVm';
import type { MenuCategoryVm } from 'src/types/MenuCategoryVm';
import type { MenuVm } from 'src/types/MenuVm';
import type { ModifierGroupVm } from 'src/types/ModifierGroupVm';

export const menuReducer = createSlice<MenuState, Actions>({
    name: 'menuReducer',
    initialState: {
        tab: undefined,
        menus: [],
        menuCategories: [],
        modifierGroups: [],
        menuItems: [],
        menusLoaded: false,
        menuCategoriesLoaded: false,
        menuItemsLoaded: false,
        modifierGroupsLoaded: false,
        loadingMenus: false,
        loadingMenuCategories: false,
        loadingMenuItems: false,
        loadingModifierGroups: false,
        failed: false,
    },
    reducers: {
        setMenus: (state: MenuState, { payload: menus }: { payload: Array<MenuVm> }) => {
            state.menus = menus;
            state.menusLoaded = true;
        },

        setMenuCategories: (state: MenuState, { payload: menuCategories }: { payload: Array<MenuCategoryVm> }) => {
            state.menuCategories = menuCategories;
            state.menuCategoriesLoaded = true;
        },

        setMenuItems: (state: MenuState, { payload: menuItems }: { payload: Array<ManagerMenuItemVm> }) => {
            state.menuItems = menuItems;
            state.menuItemsLoaded = true;
        },

        setModifierGroups: (state: MenuState, { payload: modifierGroups }: { payload: Array<ModifierGroupVm> }) => {
            state.modifierGroups = modifierGroups;
            state.modifierGroupsLoaded = true;
        },

        setLoadingMenus: (state: MenuState, { payload: loading }: { payload: boolean }) => {
            state.loadingMenus = loading;
        },

        resetMenus: (state: MenuState) => {
            state.modifierGroups = [];
            state.modifierGroupsLoaded = false;
            state.menuItems = [];
            state.menuItemsLoaded = false;
            state.menuCategories = [];
            state.menuCategoriesLoaded = false;
            state.menus = [];
            state.menusLoaded = false;
        },

        setLoadingMenuCategories: (state: MenuState, { payload: loading }: { payload: boolean }) => {
            state.loadingMenuCategories = loading;
        },

        setLoadingMenuItems: (state: MenuState, { payload: loading }: { payload: boolean }) => {
            state.loadingMenuItems = loading;
        },

        setLoadingModifierGroups: (state: MenuState, { payload: loading }: { payload: boolean }) => {
            state.loadingModifierGroups = loading;
        },

        setMenuFailed: (state: MenuState) => {
            state.failed = true;
            state.menusLoaded = true;
        },

        setMenuCategoryFailed: (state: MenuState) => {
            state.failed = true;
            state.menuCategoriesLoaded = true;
        },

        setMenuItemFailed: (state: MenuState) => {
            state.failed = true;
            state.menuItemsLoaded = true;
        },

        setModifierGroupFailed: (state: MenuState) => {
            state.failed = true;
            state.modifierGroupsLoaded = true;
        },
    },
});

export type MenuState = {
    tab?: string;
    restaurantMenuId?: RestaurantMenuId;
    restaurantType?: RestaurantType;
    kitchenIds?: Array<KitchenId>;
    kitchenNames?: Array<string>;
    menus: Array<MenuVm>;
    menuCategories?: Array<MenuCategoryVm>;
    modifierGroups: Array<ModifierGroupVm>;
    menuItems: Array<ManagerMenuItemVm>;
    menusLoaded: boolean;
    menuCategoriesLoaded: boolean;
    menuItemsLoaded: boolean;
    modifierGroupsLoaded: boolean;
    loadingMenus: boolean;
    loadingMenuCategories: boolean;
    loadingMenuItems: boolean;
    loadingModifierGroups: boolean;
    failed: boolean;
};

type Actions = {
    setMenus: any;
    setMenuCategories: any;
    setMenuItems: any;
    setModifierGroups: any;
    setLoadingMenus: any;
    resetMenus: any;
    setLoadingMenuCategories: any;
    setLoadingMenuItems: any;
    setLoadingModifierGroups: any;
    setMenuFailed: any;
    setMenuCategoryFailed: any;
    setMenuItemFailed: any;
    setModifierGroupFailed: any;
};
