import { App, Apps } from 'src/constants/App';
import ambitLogo from 'src/images/ambit-logo.png';
import didiFoodLogo from 'src/images/didifood-logo.png';
import pedidosYaLogo from 'src/images/pedidosYa-logo.png';
import rappiLogo from 'src/images/rappi-logo.png';
import uberEatsLogo from 'src/images/uber-eats-logo.png';

export function getChannelImage(app: App): string {
    if (app === Apps.UBER_EATS) return uberEatsLogo;
    if (app === Apps.DIDI_FOOD) return didiFoodLogo;
    if (app === Apps.RAPPI) return rappiLogo;
    if (app === Apps.PEDIDOS_YA) return pedidosYaLogo;
    if (app === Apps.PIDEDIRECTO) return ambitLogo;
    return ambitLogo;
}
