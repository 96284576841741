import * as React from 'react';
import { Select } from 'src/components/Select';
import { Countries } from 'src/constants/Country';
import { translate } from 'src/i18n/translate';

export function CountrySelect({ required, onChange }: Props): React.ReactElement {
    const options = required
        ? Object.values(Countries).map((country: any) => ({ label: translate(`Countries.${country}`), value: country }))
        : [{ label: '', value: '' }, ...Object.values(Countries).map((country: any) => ({ label: translate(`Countries.${country}`), value: country }))];

    return <Select label={translate('Country')} name='Countries' labelAsPlaceholder onChange={onChange} options={options} />;
}

type Props = {
    onChange?: any;
    required?: boolean;
};
