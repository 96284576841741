import { letseatadminApiMethod } from 'src/api/letseatadminApi';
import type { ApiSauceResponse } from 'src/api/types/ApiSauceResponse';
import type { BrandVm } from 'src/types/BrandVm';
import type { BrandId } from 'src/types/Id';

export async function getBrandApi(request: GetBrandApiRequest): ApiSauceResponse<BrandVm> {
    return letseatadminApiMethod('brand/getBrandApi', request);
}

export type GetBrandApiRequest = {
    brandId: BrandId;
};
