import { PosState } from 'src/reducers/posReducer';
import { mapOrderCoursesToPosCourses } from 'src/utils/pos/mapOrderCoursesToPosCourses';
import { mapOrderCustomersToPosCustomers } from 'src/utils/pos/mapOrderCustomersToPosCustomers';
import { mapOrderItemsToCartItems } from 'src/utils/pos/mapOrderItemsToCartItems';

export function setPendingOrderInfo(state: PosState) {
    if (state.items.length) return;
    const allItems = state.posMenu?.menuItems ?? [];
    state.items = mapOrderItemsToCartItems({ orderItems: state.pendingOrder?.orderItems ?? [], menuItems: allItems });
    state.cancelledItems = mapOrderItemsToCartItems({ orderItems: state.pendingOrder?.cancelledItems ?? [], menuItems: allItems });

    if (!!state.pendingOrder?.customers?.length && !state.customers?.length) {
        const customers = mapOrderCustomersToPosCustomers(state.pendingOrder?.customers, allItems, state.payments);
        state.customers = customers;
        state.customerNumberSelected = 1;
    }

    if (!!state.pendingOrder?.courses?.length && !state.ongoingCourses?.length) {
        state.ongoingCourses = mapOrderCoursesToPosCourses(state.pendingOrder?.courses, allItems, state.payments);
        state.courseIdSelected = state.pendingOrder?.courses?.[0].courseId;
        state.takeOrderByCourse = true;
    }
}
