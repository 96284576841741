import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import * as React from 'react';
import { useEffect } from 'react';
import { useForm, useWatch } from 'react-hook-form';
import { addPrinterApi } from 'src/api/letseatmanager/printerDeprecated/addPrinterApi';
import { Form } from 'src/components/form/Form';
import { FormAppAutocompleteMultiple } from 'src/components/form/FormAppAutocompleteMultiple';
import { FormCategoryAutocompleteMultiple } from 'src/components/form/FormCategoryAutocompleteMultiple';
import { FormCheckbox } from 'src/components/form/FormCheckbox';
import { FormDriverPrintersSelect } from 'src/components/form/FormDriverPrintersSelect';
import { FormPrinterBrandSelect } from 'src/components/form/FormPrinterBrandSelect';
import { FormPrinterContentSelect } from 'src/components/form/FormPrinterContentSelect';
import { FormPrinterPaperSizeSelect } from 'src/components/form/FormPrinterPaperSizeSelect';
import { FormPrinterTypeSelect } from 'src/components/form/FormPrinterTypeSelect';
import { FormTextField } from 'src/components/form/FormTextField';
import { Apps } from 'src/constants/App';
import { PrinterBrand } from 'src/constants/PrinterBrand';
import { PrinterContent, PrinterContents } from 'src/constants/PrinterContent';
import { PrinterPaperSizes } from 'src/constants/PrinterPaperSize';
import { PrinterType, PrinterTypes } from 'src/constants/PrinterType';
import { translate } from 'src/i18n/translate';
import { WebUsbPrinter } from 'src/services/printer/printers/WebUsbPrinter';
import { useGetRestaurantChannels } from 'src/services/printer/useGetRestaurantChannels';
import { appLayout } from 'src/theme/AppLayout';
import { alertKnownErrorOrSomethingWentWrong } from 'src/utils/alert/alertKnownErrorOrSomethingWentWrong';
import { isBluetoothPrinter } from 'src/utils/printer/isBluetoothPrinter';
import { isPaymentTerminalPrinter } from 'src/utils/printer/isPaymentTerminalPrinter';
import { useSelector } from 'src/utils/react/useSelector';
import { isMobileApp } from 'src/utils/reactNative/isMobileApp';
import { isPaymentTerminalDevice } from 'src/utils/reactNative/isPaymentTerminalDevice';
import { setRestaurantHasBluetoothPrintersInMobileApp } from 'src/utils/reactNative/setRestaurantHasBluetoothPrintersInMobileApp';

export function CreatePrinterDialog({ open, onClose, onPrinterCreated }: Props): React.ReactElement {
    const classes = useStyles();

    const channels = useGetRestaurantChannels();

    const form = useForm();
    const {
        formState: { isSubmitting },
        control,
        setValue,
    } = form;

    const printerType = useWatch<PrinterType>({ name: 'type', control });
    const printerBrand = useWatch<PrinterBrand>({ name: 'printerBrand', control });
    const printerContent = useWatch<PrinterContent>({ name: 'printerContent', control });
    const restaurantId = useSelector((state) => state.app.restaurantId);
    const qpayPosTerminalDeviceId = useSelector((state) => state.app.qpayPosTerminalDeviceId);

    const serialNumber = isPaymentTerminalDevice() ? qpayPosTerminalDeviceId : undefined;

    const supportedPrinterTypes: Array<PrinterType> = [PrinterTypes.USB, PrinterTypes.BLUETOOTH, PrinterTypes.WIFI];
    if (isMobileApp()) {
        supportedPrinterTypes.push(PrinterTypes.PAYMENT_TERMINAL_PRINTER);
        supportedPrinterTypes.push(PrinterTypes.SDK);
    }

    useEffect(() => {
        if (printerType === PrinterTypes.USB && printerBrand) {
            tryToConnectWithPrinter();
        }

        if (isPaymentTerminalPrinter(printerType!)) {
            setValue('printerPaperSize', PrinterPaperSizes['58_MM']);
        }
    }, [printerType, printerBrand]);

    const tryToConnectWithPrinter = async () => {
        try {
            const serialNumber = await WebUsbPrinter.getPrinterSerialNumber(printerBrand!);
            form.setValue('serialNumber', serialNumber);
        } catch (e: any) {
            handleClose();
        }
    };

    const handleClose = () => {
        if (isSubmitting) return;
        onClose();
    };

    const handleDriverDownload = () => {
        window.open('https://habitue-img.s3.sa-east-1.amazonaws.com/driver+instalator.zip');
    };

    const onSubmit = async (form: any) => {
        const response = await addPrinterApi({
            restaurantId: restaurantId,
            type: form.type,
            menuCategoryIds: form.menuCategoryIds,
            printerContent: form.printerContent === translate('Both') ? undefined : form.printerContent,
            externalPrinterId: form.driverprinterselected,
            deviceName: form.deviceName,
            printerBrand: form.printerBrand,
            ipAddress: form.ipAddress,
            port: form.port,
            disabled: form.disabled,
            isTabletPrinter: form.isTabletPrinter,
            serialNumber: form.serialNumber,
            channels: form.channels,
            printerPaperSize: form.printerPaperSize,
        });
        if (!response.ok) {
            alertKnownErrorOrSomethingWentWrong(response);
            return;
        }
        onPrinterCreated();
        onClose();

        if (isBluetoothPrinter(form.type)) setRestaurantHasBluetoothPrintersInMobileApp(!form.disabled);
    };

    return (
        <Dialog fullWidth scroll='paper' open={open} onClose={handleClose} classes={{ paper: classes.paper }}>
            <Form form={form} onSubmit={onSubmit} style={appLayout.dialog.form} className={classes.form}>
                <DialogTitle classes={{ root: classes.dialogTitle }}>{translate('Create Printer')}</DialogTitle>
                <DialogContent classes={{ root: classes.dialogContent }}>
                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                            <FormPrinterTypeSelect name='type' label={translate('Printer Type')} required filter={supportedPrinterTypes} />
                        </Grid>
                        <Grid item xs={12}>
                            <FormPrinterBrandSelect name='printerBrand' label={translate('Printer Brand')} required />
                        </Grid>
                        <Grid item xs={12}>
                            <FormPrinterContentSelect name='printerContent' label={translate('Printer Content')} />
                        </Grid>
                        <Grid item xs={12}>
                            <FormAppAutocompleteMultiple name='channels' label={translate('Channels')} helperText={translate('If this is blank will be all the channels')} filter={channels} />
                        </Grid>
                        <Grid item xs={12}>
                            <FormTextField name='deviceName' label={translate('Device Name (Eg: Cocina)')} required />
                        </Grid>
                        {printerContent === PrinterContents.COMMAND && (
                            <Grid item xs={12}>
                                <FormCategoryAutocompleteMultiple name='menuCategoryIds' label={translate('Menu Categories')} menuChannels={[Apps.PIDEDIRECTOPOS, Apps.PIDEDIRECTO]} />
                            </Grid>
                        )}
                        {isMobileApp() && printerType === PrinterTypes.WIFI && (
                            <Grid item xs={12}>
                                <FormTextField name='ipAddress' label={translate('Ip Address (Eg: 192.168.1.105)')} />
                            </Grid>
                        )}
                        {isMobileApp() && printerType === PrinterTypes.WIFI && (
                            <Grid item xs={12}>
                                <FormTextField name='port' label={translate('Port (Leave it empty if not known)')} />
                            </Grid>
                        )}
                        {isMobileApp() && printerType === PrinterTypes.BLUETOOTH && (
                            <Grid item xs={12}>
                                <DialogTitle>{translate('Make sure that the bluetooth printer is paired with your device')}</DialogTitle>
                            </Grid>
                        )}
                        {!isMobileApp() && (printerType === PrinterTypes.BLUETOOTH || printerType === PrinterTypes.WIFI) && (
                            <Grid item xs={12}>
                                <DialogTitle classes={{ root: classes.dialogTitle }}>{translate('Select a Printer')}</DialogTitle>

                                <FormDriverPrintersSelect name='driverprinterselected' label={translate('Selected printer name:')} required />

                                <Button onClick={handleDriverDownload} classes={{ root: classes.noMargin }} disabled={isSubmitting}>
                                    <div className={classes.cancelButton}>{translate('Download Drivers')}</div>
                                </Button>
                            </Grid>
                        )}
                        {(printerType === PrinterTypes.USB || isPaymentTerminalPrinter(printerType!)) && (
                            <Grid item xs={12}>
                                <FormTextField defaultValue={serialNumber} disabled={!isPaymentTerminalPrinter(printerType!)} name='serialNumber' label={translate('Serial Number')} />
                            </Grid>
                        )}
                        <Grid item xs={12}>
                            <FormPrinterPaperSizeSelect required name='printerPaperSize' label={translate('Paper Size')} />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <FormCheckbox name='disabled' label={translate('Disabled')} />
                        </Grid>
                    </Grid>
                    {!isMobileApp() && <p className={classes.helperText}>{translate('If your printer is not available to connect then is not compatible')}</p>}
                </DialogContent>
                <DialogActions className={classes.buttonsContainer}>
                    <Button onClick={handleClose} disabled={isSubmitting}>
                        <div className={classes.cancelButton}>{translate('Cancel')}</div>
                    </Button>
                    <Button color='primary' type='submit' disabled={isSubmitting} classes={{ root: classes.noMargin }}>
                        <div className={classes.acceptButton}>{isSubmitting ? translate('Creating') : translate('Create')}</div>
                    </Button>
                </DialogActions>
            </Form>
        </Dialog>
    );
}

const useStyles = makeStyles((theme) => ({
    paper: {},
    form: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    dialogTitle: {
        textAlign: 'center',
        fontSize: 41,
        color: theme.palette.secondary.contrastText,
        paddingTop: theme.spacing(4),
        paddingBottom: theme.spacing(3),
        '& h2': {
            fontFamily: theme.typography.medium,
        },
    },
    dialogContent: {
        width: '70%',
        overflowY: 'hidden',
        [theme.breakpoints.down('sm')]: {
            width: '100%',
        },
    },
    inputDescription: {
        height: 170,
        alignItems: 'flex-start',
    },
    formRow: {
        display: 'flex',
        width: '100%',
        justifyContent: 'space-between',
        alignItems: 'flex-end',
        [theme.breakpoints.down('sm')]: {
            flexDirection: 'column',
        },
    },
    inputContainer: {
        width: '45%',
        [theme.breakpoints.down('sm')]: {
            width: '100%',
        },
    },
    buttonsContainer: {
        marginTop: 20,
        [theme.breakpoints.down('sm')]: {
            flexDirection: 'column',
            marginBottom: 20,
        },
    },
    noMargin: {
        margin: '0 !important',
    },
    acceptButton: {
        backgroundColor: theme.palette.primary.main,
        color: 'white',
        fontFamily: theme.typography.semiBold,
        fontSize: 15,
        borderRadius: 5,
        height: 40,
        width: 200,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        textTransform: 'none',
        boxShadow: `3px 3px 10px ${theme.palette.primary.main}aa`,
    },
    cancelButton: {
        backgroundColor: theme.palette.secondary.dark,
        color: theme.palette.secondary.contrastText,
        fontFamily: theme.typography.semiBold,
        fontSize: 15,
        borderRadius: 5,
        height: 40,
        width: 200,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        textTransform: 'none',
    },
    helperText: {
        fontFamily: theme.typography.regular,
        fontSize: 13,
        margin: '0 auto',
        textAlign: 'center',
    },
}));

type Props = {
    open: boolean;
    onClose: any;
    onPrinterCreated: any;
};
