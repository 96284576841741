import type { CartItemVm } from 'src/types/CartItemVm';
import { OrderItemVm } from 'src/types/OrderVm';
import type { MenuItemVm } from 'src/types/PosMenuVm';
import { mapOrderItemToCartItem } from 'src/utils/pos/mapOrderItemToCartItem';

export function mapOrderItemsToCartItems({ orderItems, menuItems }: Params): Array<CartItemVm> {
    const items: Array<CartItemVm> = [];

    for (const orderItem of orderItems ?? []) {
        const menuItem = menuItems?.find((item: MenuItemVm) => item.menuItemId === orderItem.menuItemId);
        if (menuItem) {
            const posItem = mapOrderItemToCartItem(menuItem, orderItem);

            const existingPosItem = items.find((current) => current.key === posItem.key);

            if (existingPosItem) {
                existingPosItem.quantity = existingPosItem.quantity + posItem.quantity;
            } else {
                items.push(posItem);
            }
        }
    }

    return items;
}

type Params = {
    orderItems: Array<OrderItemVm>;
    menuItems: Array<MenuItemVm>;
};
