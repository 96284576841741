import { Hub } from 'aws-amplify';
import { useEffect } from 'react';
import * as React from 'react';
import { LogEventTypes } from 'src/constants/LogEventType';
import { createUiInteractionLogEvent } from 'src/services/logEvent/createUiInteractionLogEvent';
import { useInitApp } from 'src/utils/react/useInitApp';
import { useLogin } from 'src/utils/react/useLogin';
import { useLogout } from 'src/utils/react/useLogout';

export function UserSessionListener(): React.ReactElement | null {
    const [login] = useLogin();
    const [initApp] = useInitApp();
    const logout = useLogout();

    useEffect(() => {
        Hub.listen('auth', function (data) {
            console.log(`Hub event ${data?.payload?.event} ${data?.payload?.message}`);
            // eslint-disable-next-line default-case
            switch (data.payload.event) {
                case 'signIn': {
                    const cognitoUser = data.payload.data;
                    login(cognitoUser.username);
                    createUiInteractionLogEvent({
                        logEventType: LogEventTypes.USER_SIGNED_IN_TO_PIDE_DIRECTO_ADMIN,
                    });
                    break;
                }
                case 'signIn_failure': {
                    alert(data.payload.data.message ?? 'Signing in failed');
                    break;
                }
                case 'signOut': {
                    logout();
                    break;
                }
            }
        });
        initApp();
    }, []);

    return null;
}
