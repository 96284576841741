import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import { useEffect, useState } from 'react';
import * as React from 'react';
import { useFormContext } from 'react-hook-form';
import { addCourseToRestaurantApi } from 'src/api/letseatmanager/course/addCourseToRestaurantApi';
import { changeRestaurantCoursesApi } from 'src/api/letseatmanager/course/changeRestaurantCoursesApi';
import { getCoursesApi } from 'src/api/letseatmanager/course/getCoursesApi';
import { Button } from 'src/components/Button';
import { Dialog } from 'src/components/Dialog';
import { DialogActions } from 'src/components/DialogActions';
import { DraggableList } from 'src/components/DraggableList';
import { DraggableListItem } from 'src/components/DraggableListItem';
import { FormTextField } from 'src/components/form/FormTextField';
import { WebSocketEventTypes } from 'src/constants/WebSocketEventType';
import { translate } from 'src/i18n/translate';
import { CourseItem } from 'src/scenes/letseatmanager/theRestaurant/CourseItem';
import { useNotification } from 'src/services/notification/useNotification';
import { alertKnownErrorOrSomethingWentWrong } from 'src/utils/alert/alertKnownErrorOrSomethingWentWrong';
import { useLoadApi } from 'src/utils/react/useLoadApi';
import { useSelector } from 'src/utils/react/useSelector';
import { WebSocketEvents } from 'src/utils/webSocket/WebSocketEvents';

export function ManageCoursesDialog({ open, handleClose }: Props): React.ReactElement {
    const classes = useStyles();
    const notification = useNotification();
    const { watch, setValue } = useFormContext();

    const [loading, setLoading] = useState(false);

    const restaurantId = useSelector((state) => state.app.restaurantId);

    const [loadingCourses, courses, getCourses] = useLoadApi(getCoursesApi, { restaurantId }, { initialValue: [] });

    const courseName = watch('courseName');

    useEffect(() => {
        const restaurantRemovedCourseWebSocketEvent = WebSocketEvents.addEventListener(WebSocketEventTypes.RESTAURANT_COURSE_REMOVED, handleOnSuccess);
        const restaurantAddedCourseWebSocketEvent = WebSocketEvents.addEventListener(WebSocketEventTypes.RESTAURANT_COURSE_ADDED, handleOnSuccess);

        return () => {
            restaurantRemovedCourseWebSocketEvent.remove();
            restaurantAddedCourseWebSocketEvent.remove();
        };
    }, []);

    const handleSubmit = async () => {
        setLoading(true);
        const response = await addCourseToRestaurantApi({
            restaurantId,
            name: courseName,
        });
        setLoading(false);
        if (!response.ok) {
            alertKnownErrorOrSomethingWentWrong(response);
            return;
        }
        getCourses();
        notification({ message: translate('Course added successfully') });
        resetForm();
    };

    const resetForm = () => {
        setValue('courseName', '');
    };

    const updateCourses = async (coursesIds: any) => {
        const response = await changeRestaurantCoursesApi({
            coursesIds,
            restaurantId,
        });
        if (!response.ok) {
            alertKnownErrorOrSomethingWentWrong(response);
            return;
        }
        getCourses();
    };

    const handleOnRemoveCourse = () => {
        notification({ message: translate('Course removed successfully') });
        getCourses();
    };

    const handleOnSuccess = () => {
        notification({ message: translate('Course updated successfully') });
        getCourses();
    };

    return (
        <Dialog open={open} onClose={handleClose} title={translate('Manage Courses')} classes={{ dialog: classes.dialog }}>
            <div className={classes.form}>
                {courses.length === 0 && !loadingCourses && <p className={(classes as any).text}>{translate('No courses added yet')}</p>}
                <DraggableList onDragEnd={updateCourses}>
                    {courses.map((course) => {
                        return (
                            <DraggableListItem key={course.courseId} value={course.courseId} iconColor={'#6C7076'} classes={{ container: (classes as any).draggableItemContainer }}>
                                <CourseItem course={course} onRemove={handleOnRemoveCourse} onSuccess={handleOnSuccess} />
                            </DraggableListItem>
                        );
                    })}
                </DraggableList>

                <Grid container spacing={1} className={classes.formContainer}>
                    <Grid item xs={6}>
                        <FormTextField disabled={loading} name='courseName' placeholder={translate('Course Name')} classes={{ input: classes.input }} />
                    </Grid>
                    <Grid item xs={4}>
                        <Button primary type='button' disabled={loading} classes={{ button: classes.addButton }} onClick={handleSubmit}>
                            {loading ? translate('Saving') : translate('+ Add')}
                        </Button>
                    </Grid>
                </Grid>

                <DialogActions className={classes.buttonsContainer}>
                    <Button secondary onClick={handleClose} disabled={loading}>
                        {translate('Close')}
                    </Button>
                </DialogActions>
            </div>
        </Dialog>
    );
}

const useStyles = makeStyles((theme) => ({
    dialog: {
        width: 600,
        [theme.breakpoints.down('sm')]: {
            width: '100%',
        },
    },
    form: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    formContainer: {
        marginTop: 20,
    },
    buttonsContainer: {
        marginTop: 20,
        [theme.breakpoints.down('sm')]: {
            flexDirection: 'column',
        },
    },
    addButton: {
        marginTop: 9,
        borderColor: theme.palette.primary.main,
    },
    input: {
        padding: '5px 13px',
    },
}));

type Props = {
    open: boolean;
    handleClose: any;
};
