import Grid from '@material-ui/core/Grid';
import gql from 'graphql-tag';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { findOngoingDeliveryManagers } from 'src/api/appsync/queries';
import { onChangeDeliveryManager } from 'src/api/appsync/subscriptions';
import { app2 } from 'src/app2';
import { UpdatingContentProgress } from 'src/components/UpdatingContentProgress';
import { appsyncClient } from 'src/config/appsyncClient';
import { translate } from 'src/i18n/translate';
import { DeliveryManagerComponent } from 'src/scenes/letseatadmin/deliveryManager/DeliveryManager';
import { useAction } from 'src/utils/react/useAction';

export function DeliveryMonitoringPage(): React.ReactElement {
    const [loading, setLoading] = useState(false);

    const [deliveryManagers, setDeliveryManagers] = useState([]);
    const setTitle = useAction(app2.actions.setTitle);

    useEffect(() => {
        setTitle(translate('Delivery Monitoring'));
    }, []);

    // useEffect(() => {
    //     let subscription;
    //     let loadCancelled = false;
    //
    //     async function load() {
    //         try {
    //             const response = await appsyncClient.query({
    //                 query: gql(findOngoingDelivery),
    //                 variables: { driverId },
    //             });
    //             // alert(JSON.stringify(response, null, 2));
    //             if (loadCancelled) return;
    //             setOngoingDelivery(response);
    //         } catch (e) {
    //             console.log(e);
    //             alert(e);
    //         }
    //     }
    //
    //     if (signedIn) {
    //         const subscription = appsyncClient.subscribe({
    //             query: gql(onDeliveryRequestCreated),
    //             variables: { driverId },
    //         }).subscribe({
    //             next: response => {
    //                 alert(JSON.stringify(response, null, 2));
    //                 setOngoingDelivery(response);
    //             },
    //             complete: error => {
    //                 alert(JSON.stringify(error, null, 2));
    //             },
    //             error: error => {
    //                 alert(JSON.stringify(error, null, 2));
    //             },
    //         });
    //         load();
    //         return () => {
    //             loadCancelled = true;
    //             subscription && subscription.unsubscribe();
    //         };
    //     } else {
    //         loadCancelled = true;
    //         subscription && subscription.unsubscribe();
    //     }
    //
    // }, [signedIn]);

    useEffect(() => {
        let subscription: any;
        let loadCancelled = false;

        async function load() {
            try {
                const response = await appsyncClient.query<any>({
                    query: gql(findOngoingDeliveryManagers),
                    variables: {},
                });
                // alert(JSON.stringify(response, null, 2));
                if (loadCancelled) return;

                // alert(JSON.stringify(response.data.findOngoingDeliveryManagers, null, 2));
                setDeliveryManagers(response.data.findOngoingDeliveryManagers);

                console.log(`subscribing onChangeDeliveryManager`);
                subscription = appsyncClient
                    .subscribe({
                        query: gql(onChangeDeliveryManager),
                        variables: { orderId: null },
                    })
                    .subscribe({
                        next: (response) => {
                            console.log(`next` + JSON.stringify(response));
                            setDeliveryManagers((deliveryManagers: any) => {
                                const deliveryManagerChange = response.data.onChangeDeliveryManager;
                                if (deliveryManagers.some((delivery: any) => delivery.orderId === deliveryManagerChange.orderId)) {
                                    return deliveryManagers.map((old: any) => (old.orderId === deliveryManagerChange.orderId ? deliveryManagerChange : old));
                                } else {
                                    return [deliveryManagerChange, ...deliveryManagers];
                                }
                            });
                        },
                        complete: () => {
                            alert('completed onChangeDeliveryManager subscription');
                            // alert(JSON.stringify(error, null, 2));
                        },
                        error: (error) => {
                            alert(error);
                            // alert(JSON.stringify(error, null, 2));
                        },
                    });
            } catch (e: any) {
                console.log(e);
                alert(e);
            }
        }

        load();

        // const subscription = appsyncClient.subscribe({
        //     query: gql(onChangeDeliveryManager),
        //     variables: { orderId: null },
        // }).subscribe({
        //     next: response => {
        //         const delivery = response.data.onChangeDelivery;
        //         alert(JSON.stringify(delivery, null, 2));
        //         // if (delivery.deliveryStatus === DeliveryStatus.REJECTED ||
        //         //     delivery.deliveryStatus === DeliveryStatus.DELIVERED) {
        //         //     setOngoingDelivery(undefined);
        //         //     return;
        //         // }
        //         // setOngoingDelivery(delivery);
        //     },
        //     complete: error => {
        //         // SentryService.logError('onChangeDelivery complete', { ongoingDeliveryId, error });
        //         // alert(JSON.stringify(error, null, 2));
        //     },
        //     error: error => {
        //         // SentryService.logError('onChangeDelivery error', { ongoingDeliveryId, error });
        //         // alert(JSON.stringify(error, null, 2));
        //     },
        // });

        return () => {
            subscription && subscription.unsubscribe();
            loadCancelled = false;
        };

        // }
        //
        // if (ongoingDeliveryId) {
        //     const subscription = appsyncClient.subscribe({
        //         query: gql(onChangeDelivery),
        //         variables: { deliveryId: ongoingDeliveryId },
        //     }).subscribe({
        //         next: response => {
        //             animateEaseInEaseOut();
        //             const delivery = response.data.onChangeDelivery;
        //             if (delivery.deliveryStatus === DeliveryStatus.REJECTED ||
        //                 delivery.deliveryStatus === DeliveryStatus.DELIVERED) {
        //                 setOngoingDelivery(undefined);
        //                 return;
        //             }
        //             setOngoingDelivery(delivery);
        //         },
        //         complete: error => {
        //             SentryService.logError('onChangeDelivery complete', { ongoingDeliveryId, error });
        //             // alert(JSON.stringify(error, null, 2));
        //         },
        //         error: error => {
        //             SentryService.logError('onChangeDelivery error', { ongoingDeliveryId, error });
        //             // alert(JSON.stringify(error, null, 2));
        //         },
        //     });
        //     return () => subscription.unsubscribe();
        // }
    }, []);

    return (
        <>
            <div style={{ position: 'relative' }}>
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <UpdatingContentProgress loading={loading} />
                        {deliveryManagers.map((deliveryManager: any) => (
                            <div style={{ padding: 8 }} key={deliveryManager.orderId}>
                                <DeliveryManagerComponent deliveryManager={deliveryManager} />
                            </div>
                        ))}
                    </Grid>
                </Grid>
            </div>
        </>
    );
}
