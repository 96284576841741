import { letseatmanagerApiMethod } from 'src/api/letseatmanagerApi';
import type { ApiSauceResponse } from 'src/api/types/ApiSauceResponse';
import type { RestaurantId, ScheduledTaskId } from 'src/types/Id';

export async function removeScheduledNotificationToCustomerApi(request: RemoveScheduledNotificationToCustomerApiRequest): ApiSauceResponse<Array<void>> {
    return letseatmanagerApiMethod('marketing/removeScheduledNotificationToCustomerApi', request);
}

type RemoveScheduledNotificationToCustomerApiRequest = {
    restaurantIds: Array<RestaurantId>;
    scheduledTaskId?: ScheduledTaskId;
};
