import { makeStyles } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { useFieldArray, useForm, useWatch } from 'react-hook-form';
import { deprecatedChangeMenuItemApi } from 'src/api/letseatmanager/menuItem/deprecatedChangeMenuItemApi';
import { pidedirectouploadApi } from 'src/api/pidedirectouploadApi';
import { app2 } from 'src/app2';
import { Button } from 'src/components/Button';
import { Dialog } from 'src/components/Dialog';
import { DialogActions } from 'src/components/DialogActions';
import { Form } from 'src/components/form/Form';
import { FormCurrencyNumberStringField } from 'src/components/form/FormCurrencyNumberStringField';
import { FormTextField } from 'src/components/form/FormTextField';
import ImageUploader from 'src/components/input/ImageUploader';
import { MenuItemTypes } from 'src/constants/DeprecatedMenuItemType';
import { ImageTypes } from 'src/constants/ImageType';
import { MenuItemSizes } from 'src/constants/MenuItemSize';
import { translate } from 'src/i18n/translate';
import { UBER_EATS_IMAGE_DIMENSIONS } from 'src/scenes/letseatmanager/deprecatedMenu/CreateMenuItemDialog';
import { ModifierGroup } from 'src/scenes/letseatmanager/deprecatedMenu/ModifierGroup';
import { alertKnownErrorOrSomethingWentWrong } from 'src/utils/alert/alertKnownErrorOrSomethingWentWrong';
import { isExternalImageUrl } from 'src/utils/image/isExternalImageUrl';
import { useAction } from 'src/utils/react/useAction';
import { useSelector } from 'src/utils/react/useSelector';
import { https } from 'src/utils/Validator';

export function ChangeMenuItemDialog(): React.ReactElement {
    const classes = useStyles();

    const form = useForm();
    const { setValue, control } = form;
    const { fields, append, remove } = useFieldArray({
        control,
        name: 'modifierGroups',
    });

    const [loading, setLoading] = useState(false);
    const [uploadingImage, setUploadingImage] = useState(false);
    const [hasDuplicatedModifiers, setHasDuplicatedModifiers] = useState(false);
    const [hasEmptyModifierGroups, setHasEmptyModifierGroups] = useState(false);

    const internalUser = useSelector((state) => state.authentication.internalUser);
    const open = useSelector((state) => state.app2.changeMenuItemDialog.open);
    const menuItem = useSelector((state) => state.app2.changeMenuItemDialog.menuItem);
    const isExternalMenu = useSelector((state) => state.app2.changeMenuItemDialog.isExternalMenu);
    const onSuccess = useSelector((state) => state.app2.changeMenuItemDialog.onSuccess);
    const uberEatsStoreId = useSelector((state) => state.app.restaurant?.uberEatsStoreId);

    const modifierGroups = useWatch<any>({ name: 'modifierGroups', control });

    const closeChangeMenuItemDialog = useAction(app2.actions.closeChangeMenuItemDialog);
    const disabled = uploadingImage || loading;

    useEffect(() => {
        if (menuItem && menuItem?.modifierGroups?.length && fields.length !== menuItem?.modifierGroups.length) {
            for (const modifierGroup of menuItem.modifierGroups) {
                append(modifierGroup);
            }
        }
    }, [menuItem]);

    useEffect(() => {
        showModifierGroupsErrors();
    }, [modifierGroups]);

    const showModifierGroupsErrors = () => {
        if (!modifierGroups || !modifierGroups.length) {
            setHasDuplicatedModifiers(false);
            setHasEmptyModifierGroups(false);
            return;
        }

        const hasDuplicatedModifiers = modifierGroups.some((modifierGroup: any) => {
            const modifierIds = modifierGroup.modifiers?.map((modifier: any) => modifier.modifierId) ?? [];
            return modifierIds.some((value: any, index: number) => modifierIds.indexOf(value) !== index);
        });
        const hasEmptyModifierGroups = modifierGroups.some((modifierGroup: any) => !modifierGroup.modifiers);

        setHasDuplicatedModifiers(hasDuplicatedModifiers);
        setHasEmptyModifierGroups(hasEmptyModifierGroups);
    };

    const handleClose = () => {
        if (uploadingImage || loading) return;
        closeChangeMenuItemDialog();
        setValue('modifierGroups', []);
    };

    const renderModifierGroups = () => {
        const addNewModifierGroup = () => append({ name: '', requiredMin: null, requiredMax: null, hidden: false, modifiers: [{ modifierId: null, name: '', price: '', hidden: false }] });
        return (
            <div style={{ width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center', marginTop: 40 }}>
                {fields.map((modifierGroup, modifierGroupIndex) => (
                    <Grid container spacing={3} key={modifierGroup.id}>
                        <Grid item xs={12}>
                            <ModifierGroup
                                loading={loading}
                                modifierGroup={modifierGroup as any}
                                modifierGroupIndex={modifierGroupIndex}
                                removeModifierGroup={() => remove(modifierGroupIndex)}
                                showHiddenSwitch={true}
                                control={control}
                                prevModifiers={menuItem?.modifierGroups[modifierGroupIndex]?.modifiers}
                            />
                        </Grid>
                    </Grid>
                ))}
                <div style={{ marginTop: 40 }}>
                    <Button outlined onClick={addNewModifierGroup}>
                        {translate('Add modifier group +')}
                    </Button>
                </div>
            </div>
        );
    };

    const getImageUrl = async (imageUrl: any) => {
        if (isExternalImageUrl(imageUrl)) {
            const migrateResponse = await pidedirectouploadApi.images.migrate({
                imageUrl: imageUrl,
                imageType: ImageTypes.MENU_ITEM,
            });
            if (!migrateResponse.ok) {
                setLoading(false);
                alertKnownErrorOrSomethingWentWrong(migrateResponse);
                return;
            }
            return migrateResponse.data;
        }
        return imageUrl;
    };

    const handleSubmit = async (form: any) => {
        const areModifierGroupsOk = validateModifierGroups(form.modifierGroups || []);
        if (!areModifierGroupsOk) return;

        setLoading(true);
        const imageUrl = await getImageUrl(form.imageUrl);
        const response = await deprecatedChangeMenuItemApi({
            menuItemId: menuItem.menuItemId,
            externalProductId: form.externalProductId,
            menuItemType: menuItem.menuItemType,
            modifierType: form.modifierType,
            name: form.name,
            description: form.description,
            contains: form.contains,
            price: form.price,
            promoText: form.promoText,
            promoPrice: form.promoPrice,
            kitchenPrice: form.kitchenPrice,
            size: MenuItemSizes.MEDIUM,
            imageUrl,
            hidden: menuItem.hidden,
            hiddenUntil: menuItem.hiddenUntil,
            modifierGroups: form.modifierGroups ?? [],
        });
        if (!response.ok) {
            setLoading(false);
            alertKnownErrorOrSomethingWentWrong(response);
            return;
        }
        setLoading(false);
        onSuccess();
        handleClose();
    };

    const validateModifierGroups = (modifierGroups: any) => {
        let areModifierGroupsOk = true;
        modifierGroups.forEach((modifierGroup: any, index: number) => {
            if (!areModifierGroupsOk) return;
            if (!!modifierGroup.modifiers?.length) return;

            areModifierGroupsOk = false;
            form.setError(`modifierGroups.${index}.name`, { message: translate('Can not be a modifier group without modifiers') });
        });
        return areModifierGroupsOk;
    };

    return (
        <Dialog classes={{ dialog: classes.form }} title={translate(menuItem?.menuItemType !== MenuItemTypes.MODIFIER ? 'Change Menu Item' : 'Change Modifier Item')} open={open} onClose={handleClose}>
            <Form onSubmit={handleSubmit} form={form}>
                <Grid container spacing={3}>
                    <Grid item xs={12} sm={6}>
                        <FormTextField name='name' label={translate('Name')} required defaultValue={menuItem?.name} />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <FormCurrencyNumberStringField name='price' label={translate('Price')} required defaultValue={menuItem?.price} />
                    </Grid>
                    {menuItem?.menuItemType !== MenuItemTypes.MODIFIER && (
                        <>
                            <Grid item xs={12} sm={6}>
                                <FormTextField name='promoText' label={translate('Promo Text')} defaultValue={menuItem?.promoText} />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <FormCurrencyNumberStringField name='promoPrice' label={translate('Promo Price')} defaultValue={menuItem?.promoPrice} />
                            </Grid>
                        </>
                    )}
                    <Grid item xs={12} sm={6}>
                        <FormTextField name='externalProductId' label={translate('External Product Id')} defaultValue={menuItem?.externalProductId} />
                    </Grid>
                    {internalUser && menuItem?.menuItemType !== MenuItemTypes.MODIFIER && (
                        <Grid item xs={12}>
                            <FormCurrencyNumberStringField name='kitchenPrice' label={translate('Real Kitchen Price')} defaultValue={menuItem?.kitchenPrice} />
                        </Grid>
                    )}
                    <Grid item xs={12}>
                        <FormTextField
                            name='description'
                            label={translate('Description')}
                            classes={{ input: classes.inputDescription, error: classes.inputDescriptionError }}
                            multiline
                            defaultValue={menuItem?.description}
                        />
                    </Grid>
                    {menuItem?.menuItemType !== MenuItemTypes.MODIFIER && (
                        <Grid item xs={12}>
                            <FormTextField name='contains' label={translate('Contains')} defaultValue={menuItem?.contains} />
                        </Grid>
                    )}

                    <Grid item xs={12}>
                        <FormTextField
                            name='imageUrl'
                            label={uploadingImage ? translate('Uploading Image...') : translate('Image')}
                            helperText={
                                uploadingImage
                                    ? undefined
                                    : translate(
                                          'Enter an URL to an image or drag and drop your image anywhere in the browser. For best result make sure image is 1280x854 pixels or at least similar aspect ratio and not lower resolution.',
                                      )
                            }
                            validate={https}
                            defaultValue={menuItem?.imageUrl}
                        />
                        <ImageUploader
                            imageType={ImageTypes.MENU_ITEM}
                            onUploadingChanged={(uploadingImage) => setUploadingImage(uploadingImage)}
                            onUploadSuccess={(imageUrl) => setValue('imageUrl', imageUrl)}
                            dimensions={isExternalMenu && uberEatsStoreId ? UBER_EATS_IMAGE_DIMENSIONS : undefined}
                        />
                    </Grid>
                </Grid>
                {menuItem?.menuItemType !== MenuItemTypes.MODIFIER && (
                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                            {renderModifierGroups()}
                        </Grid>
                    </Grid>
                )}
                {hasDuplicatedModifiers && <p className={classes.error}>{translate('You have duplicated modifiers in this group.')}</p>}
                {hasEmptyModifierGroups && <p className={classes.error}>{translate('You have modifier groups without modifiers.')}</p>}
                <DialogActions>
                    <Button secondary onClick={handleClose} disabled={disabled}>
                        {translate('Cancel')}
                    </Button>
                    <Button type='submit' disabled={disabled || hasDuplicatedModifiers}>
                        {loading ? translate('Changing') : translate('Change')}
                    </Button>
                </DialogActions>
            </Form>
        </Dialog>
    );
}

const useStyles = makeStyles((theme) => ({
    form: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        width: '70%',
        margin: '0 auto',
        [theme.breakpoints.down('sm')]: {
            width: '100%',
        },
    },
    inputDescription: {
        height: '15vh',
        alignItems: 'flex-start',
    },
    inputDescriptionError: {
        border: '2px solid red',
    },
    error: {
        fontFamily: theme.typography.regular,
        color: theme.palette.text.danger,
    },
}));
