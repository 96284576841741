import * as React from 'react';
import { useState } from 'react';
import { removeMenuCategoryApi } from 'src/api/letseatmanager/menuCategory/removeMenuCategoryApi';
import { RemoveDialog } from 'src/components/dialog/RemoveDialog';
import { translate } from 'src/i18n/translate';
import type { MenuCategoryVm } from 'src/types/MenuCategoryVm';
import { alertKnownErrorOrSomethingWentWrong } from 'src/utils/alert/alertKnownErrorOrSomethingWentWrong';

export function RemoveMenuCategoryDialog({ open, menuCategory, onSuccess, onClose }: Props): React.ReactElement {
    const [loading, setLoading] = useState(false);

    const handleClose = () => {
        if (loading) return;
        onClose();
    };

    const handleSubmit = async () => {
        if (!menuCategory) return;
        const response = await removeMenuCategoryApi({
            menuCategoryId: menuCategory?.menuCategoryId,
        });
        setLoading(true);
        setLoading(false);
        if (!response.ok) {
            alertKnownErrorOrSomethingWentWrong(response);
            return;
        }
        onSuccess?.();
        handleClose();
    };

    return (
        <RemoveDialog
            open={open}
            title={translate('Remove category')}
            subtitle={translate('You are about to delete the @menuCategoryName category, are you sure you want to continue?', { menuCategoryName: menuCategory?.name })}
            onSubmit={handleSubmit}
            onClose={handleClose}
            disabled={loading}
        />
    );
}

type Props = {
    open: boolean;
    menuCategory?: MenuCategoryVm;
    onSuccess?: any;
    onClose: any;
};
