import * as React from 'react';

export function ChatIcon({ color }: Props): React.ReactElement {
    return (
        <svg width='18' height='17' viewBox='0 0 18 17' fill='none' xmlns='http://www.w3.org/2000/svg'>
            <path
                fillRule='evenodd'
                clipRule='evenodd'
                d='M3.00333 16.0367C3.33225 16.0958 3.6658 16.1254 4 16.125C5.05622 16.1264 6.09132 15.8292 6.98583 15.2675C7.63083 15.4192 8.30583 15.5 9 15.5C13.435 15.5 17.125 12.1917 17.125 8C17.125 3.80833 13.435 0.5 9 0.5C4.565 0.5 0.875 3.80833 0.875 8C0.875 10.0075 1.72917 11.8225 3.10333 13.16C3.29667 13.3483 3.33417 13.5167 3.315 13.6125C3.21201 14.1309 2.97855 14.6145 2.63667 15.0175C2.56569 15.1013 2.51805 15.2023 2.49852 15.3104C2.479 15.4184 2.4883 15.5297 2.52547 15.633C2.56265 15.7364 2.62639 15.8281 2.71028 15.8989C2.79417 15.9698 2.89524 16.0173 3.00333 16.0367ZM5.875 7.0625C5.62636 7.0625 5.3879 7.16127 5.21209 7.33709C5.03627 7.5129 4.9375 7.75136 4.9375 8C4.9375 8.24864 5.03627 8.4871 5.21209 8.66291C5.3879 8.83873 5.62636 8.9375 5.875 8.9375C6.12364 8.9375 6.3621 8.83873 6.53791 8.66291C6.71373 8.4871 6.8125 8.24864 6.8125 8C6.8125 7.75136 6.71373 7.5129 6.53791 7.33709C6.3621 7.16127 6.12364 7.0625 5.875 7.0625ZM8.0625 8C8.0625 7.75136 8.16127 7.5129 8.33709 7.33709C8.5129 7.16127 8.75136 7.0625 9 7.0625C9.24864 7.0625 9.4871 7.16127 9.66291 7.33709C9.83873 7.5129 9.9375 7.75136 9.9375 8C9.9375 8.24864 9.83873 8.4871 9.66291 8.66291C9.4871 8.83873 9.24864 8.9375 9 8.9375C8.75136 8.9375 8.5129 8.83873 8.33709 8.66291C8.16127 8.4871 8.0625 8.24864 8.0625 8ZM12.125 7.0625C11.8764 7.0625 11.6379 7.16127 11.4621 7.33709C11.2863 7.5129 11.1875 7.75136 11.1875 8C11.1875 8.24864 11.2863 8.4871 11.4621 8.66291C11.6379 8.83873 11.8764 8.9375 12.125 8.9375C12.3736 8.9375 12.6121 8.83873 12.7879 8.66291C12.9637 8.4871 13.0625 8.24864 13.0625 8C13.0625 7.75136 12.9637 7.5129 12.7879 7.33709C12.6121 7.16127 12.3736 7.0625 12.125 7.0625Z'
                fill={color ?? '#63D6B3'}
            />
        </svg>
    );
}

type Props = {
    color?: string;
};
