import { Button } from '@pidedirecto/ui';
import { PlusIcon } from '@pidedirecto/ui/icons';
import * as React from 'react';
import { useState } from 'react';
import { translate } from 'src/i18n/translate';
import { CreatePromotionDialog } from 'src/scenes/letseatmanager/promotions/CreatePromotionDialog';

export function CreatePromotionButton({ onSuccess }: Props): React.ReactElement {
    const [createPromotionDialogState, setCreatePromotionDialogState] = useState({ open: false });

    const openCreatePromotionDialog = () => setCreatePromotionDialogState({ open: true });

    const closeCreatePromotionDialog = () => setCreatePromotionDialogState({ open: false });

    return (
        <>
            <CreatePromotionDialog open={createPromotionDialogState.open} onClose={closeCreatePromotionDialog} onSuccess={onSuccess} />
            <Button variant='secondary' onClick={openCreatePromotionDialog}>
                <PlusIcon size={20} title={translate('Create promotion')} />
            </Button>
        </>
    );
}

type Props = {
    onSuccess?: () => void | Promise<void>;
};
