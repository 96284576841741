import { formatAsCurrencyNumber } from '@pidedirecto/ui/utils';
import type { PromoCodeVm } from 'src/api/letseatmanager/types/PromoCodeVm';
import { Country } from 'src/constants/Country';
import { RewardTypes } from 'src/constants/RewardType';
import { translate } from 'src/i18n/translate';
import { ManagerMenuItemVm } from 'src/types/ManagerMenuItemVm';
import { MenuItemVm } from 'src/types/PosMenuVm';
import { isAmountDiscount } from 'src/utils/promoCode/isAmountDiscount';
import { isPercentDiscount } from 'src/utils/promoCode/isPercentDiscount';
import { isProductsReward } from 'src/utils/promoCode/isProductsReward';

export function getPromoCodeDescription({ promoCode, country, menuItems }: Params): string {
    if (promoCode.description) {
        return promoCode.description;
    }

    const freeDelivery = promoCode.freeDelivery ? translate('Free Delivery') : '';
    let discountDescription = '';

    if (promoCode.rewardType === RewardTypes.CREDITS) {
        if (isAmountDiscount(promoCode.discountType)) {
            discountDescription = translate('Receive @amount credits of total order', { amount: formatAsCurrencyNumber(promoCode.discount, { country: country as any }) });
        }
        if (isPercentDiscount(promoCode.discountType)) {
            discountDescription = translate('Receive @percent% credits of total order', { percent: promoCode.discount });
        }
    }

    if (promoCode.rewardType !== RewardTypes.CREDITS) {
        if (isAmountDiscount(promoCode.discountType)) {
            discountDescription = translate('@amount discount of total order', { amount: formatAsCurrencyNumber(promoCode.discount, { country: country as any }) });
        }
        if (isPercentDiscount(promoCode.discountType) && !isProductsReward(promoCode.rewardType)) {
            discountDescription = translate('@percent% discount of total order', { percent: promoCode.discount });
        }
        if (isPercentDiscount(promoCode.discountType) && isProductsReward(promoCode.rewardType)) {
            const validMenuItems = menuItems.filter((menuItem) => promoCode.menuItemIds?.includes(menuItem.menuItemId));
            const validMenuItemNames = validMenuItems.map((menuItem) => menuItem.name);
            discountDescription = translate('@percent% discount of @menuItems', { percent: promoCode.discount, menuItems: validMenuItemNames.join(', ') });
        }
    }
    return freeDelivery ? `${discountDescription}, ${freeDelivery}` : discountDescription;
}

type Params = {
    promoCode: PromoCodeVm;
    country?: Country;
    menuItems: Array<MenuItemVm | ManagerMenuItemVm>;
};
