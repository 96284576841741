import { letseatmanagerApiMethod } from 'src/api/letseatmanagerApi';
import type { ApiSauceResponse } from 'src/api/types/ApiSauceResponse';
import type { CashRegisterCustomReason, RestaurantId } from 'src/types/Id';

export async function addCashRegisterDepositReasonApi(request: AddCashRegisterDepositReasonApiRequest): ApiSauceResponse<void> {
    return letseatmanagerApiMethod('restaurant/addCashRegisterDepositReasonApi', request);
}

export type AddCashRegisterDepositReasonApiRequest = {
    restaurantId: RestaurantId;
    reason: CashRegisterCustomReason;
};
