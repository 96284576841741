import Grid from '@material-ui/core/Grid';
import * as React from 'react';
import { FormSwitch } from 'src/components/form/FormSwitch';
import { SectionTitle } from 'src/components/title/SectionTitle';
import { PosBusinessDaySections } from 'src/constants/PosBusinessDaySections';
import { translate } from 'src/i18n/translate';

export function PosBusinessDaySalesDetailInfoSettings({ disabled }: Props): React.ReactElement {
    return (
        <section>
            <SectionTitle title={translate('Sales Detail Information')} />
            <Grid container xs={12}>
                {/* @ts-ignore */}
                <Grid item xs={12} md={6} columnSpacing={2}>
                    <FormSwitch disabled={disabled} name={PosBusinessDaySections.SALES_BY_PAYMENT} label={translate(`PosBusinessDaySections.${PosBusinessDaySections.SALES_BY_PAYMENT}`)} />
                </Grid>
                {/* @ts-ignore */}
                <Grid item xs={12} md={6} columnSpacing={2}>
                    <FormSwitch
                        tooltip={translate('This section includes all orders of this type, can be extensive')}
                        disabled={disabled}
                        name={PosBusinessDaySections.CARD_BREAKDOWN}
                        label={translate(`PosBusinessDaySections.${PosBusinessDaySections.CARD_BREAKDOWN}`)}
                    />
                </Grid>
                {/* @ts-ignore */}
                <Grid item xs={12} md={6} columnSpacing={2}>
                    <FormSwitch
                        tooltip={translate('This section includes all orders of this type, can be extensive')}
                        disabled={disabled}
                        name={PosBusinessDaySections.INTEGRATION_BREAKDOWN}
                        label={translate(`PosBusinessDaySections.${PosBusinessDaySections.INTEGRATION_BREAKDOWN}`)}
                    />
                </Grid>
                {/* @ts-ignore */}
                <Grid item xs={12} md={6} columnSpacing={2}>
                    <FormSwitch disabled={disabled} name={PosBusinessDaySections.SALES_BY_ORDER_TYPE} label={translate(`PosBusinessDaySections.${PosBusinessDaySections.SALES_BY_ORDER_TYPE}`)} />
                </Grid>
                {/* @ts-ignore */}
                <Grid item xs={12} md={6} columnSpacing={2}>
                    <FormSwitch disabled={disabled} name={PosBusinessDaySections.SALES_BY_CHANNEL} label={translate(`PosBusinessDaySections.${PosBusinessDaySections.SALES_BY_CHANNEL}`)} />
                </Grid>
                {/* @ts-ignore */}
                <Grid item xs={12} md={6} columnSpacing={2}>
                    {/* @ts-ignore */}
                    <Grid item xs={12} md={6} columnSpacing={2}>
                        <FormSwitch
                            disabled={disabled}
                            name={PosBusinessDaySections.SALES_BY_MANAGER_USER}
                            label={translate(`PosBusinessDaySections.${PosBusinessDaySections.SALES_BY_MANAGER_USER}`)}
                        />
                    </Grid>
                    {/* @ts-ignore */}
                    <Grid item xs={12} md={6} columnSpacing={2}>
                        <FormSwitch disabled={disabled} name={PosBusinessDaySections.TOP_SALES_PRODUCT} label={translate(`PosBusinessDaySections.${PosBusinessDaySections.TOP_SALES_PRODUCT}`)} />
                    </Grid>
                </Grid>
                {/* @ts-ignore */}
                <Grid item xs={12} md={6} columnSpacing={2}>
                    <FormSwitch disabled={disabled} name={PosBusinessDaySections.PROMO_CODES_SUMMARY} label={translate(`PosBusinessDaySections.${PosBusinessDaySections.PROMO_CODES_SUMMARY}`)} />
                </Grid>

                {/* @ts-ignore */}
                <Grid item xs={12} md={6} columnSpacing={2}>
                    <FormSwitch disabled={disabled} name={PosBusinessDaySections.CANCELLED_ORDERS} label={translate(`PosBusinessDaySections.${PosBusinessDaySections.CANCELLED_ORDERS}`)} />
                </Grid>
            </Grid>
        </section>
    );
}

type Props = {
    disabled: boolean;
};
