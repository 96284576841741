import { ListItem } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { CheckCircle, RadioButtonUncheckedRounded } from '@material-ui/icons';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { OrderReturnedType, OrderReturnedTypes } from 'src/constants/OrderReturnedType';
import { translate } from 'src/i18n/translate';
import { PosItemQuantitySpinner } from 'src/scenes/letseatmanager/pos/posAddMenuItem/PosItemQuantitySpinner';
import type { OrderItemToReturn } from 'src/utils/order/getOrderItemsFormattedToReturn';
import { isItemSoldByWeight } from 'src/utils/orderItem/isItemSoldByWeight';
import { classNames } from 'src/utils/react/classNames';

export function SelectableReturnOrderItem({ orderItem, returnedOrderItems, orderReturnedType, setReturnedOrderItems }: Props): React.ReactElement {
    const classes = useStyles();

    const SINGLE_ITEM = 1;

    const [quantity, setQuantity] = useState(0);
    const [isSelected, setIsSelected] = useState(false);

    const isSingle = orderItem.quantity === 1;
    const remainingQuantity = Number(orderItem.quantity) - quantity;
    const quantityLabel = !!orderItem.salesUnit ? `${quantity}${translate(`UnitsOfMeasurement.${orderItem.salesUnit}`)}` : quantity;

    useEffect(() => {
        const isSelected = !!returnedOrderItems?.find((item: OrderItemToReturn) => item.key === orderItem.key);

        setIsSelected(isSelected);
    }, [returnedOrderItems]);

    useEffect(() => {
        if (orderReturnedType === OrderReturnedTypes.TOTAL) setQuantity(orderItem.quantity);
        if (orderReturnedType === OrderReturnedTypes.PARTIAL) {
            const currentReturnedItem = returnedOrderItems.find((item) => item.key === orderItem.key);
            setQuantity(currentReturnedItem?.quantity ?? 0);
        }
    }, [orderReturnedType]);

    const addReturnedOrderItem = (item: OrderItemToReturn, quantity: number): Array<OrderItemToReturn> => {
        const returnedOrderItem = returnedOrderItems?.find((current: OrderItemToReturn) => current.key === item.key);

        if (!returnedOrderItem) {
            return [...returnedOrderItems, { ...item, quantity, returned: true }];
        }

        returnedOrderItem.quantity = quantity;
        returnedOrderItem.returned = true;

        return returnedOrderItems;
    };

    const removeReturnedOrderItem = (item: OrderItemToReturn, quantity: number): Array<OrderItemToReturn> => {
        const returnedOrderItem = returnedOrderItems?.find((current: OrderItemToReturn) => current.key === item.key);

        if (!returnedOrderItem) return returnedOrderItems;

        if (!quantity) {
            return returnedOrderItems?.filter((refundedItem: OrderItemToReturn) => refundedItem.key !== item.key);
        }

        returnedOrderItem.quantity = quantity;
        return returnedOrderItems;
    };

    const handleReturnOrderItem = () => {
        if (orderReturnedType === OrderReturnedTypes.TOTAL) return;
        if (!isSelected) {
            const returnedQuantity = isItemSoldByWeight(orderItem) ? orderItem.quantity : SINGLE_ITEM;
            setQuantity(returnedQuantity);
            const newReturnedOrderItems = addReturnedOrderItem(orderItem, returnedQuantity);

            setReturnedOrderItems(newReturnedOrderItems);

            return;
        }

        setQuantity(0);
        const newReturnedOrderItems = removeReturnedOrderItem(orderItem, 0);
        setReturnedOrderItems(newReturnedOrderItems);
    };

    const handleAddReturnedOrderItem = () => {
        if (remainingQuantity <= 0) return;

        let returnedQuantity = remainingQuantity > 0 && remainingQuantity < 1 ? remainingQuantity : SINGLE_ITEM;

        if (isItemSoldByWeight(orderItem)) {
            returnedQuantity = orderItem.quantity;
        }

        if (!isSelected) {
            setQuantity(returnedQuantity);
            const newReturnedOrderItems = addReturnedOrderItem(orderItem, returnedQuantity);
            setReturnedOrderItems(newReturnedOrderItems);

            return;
        }

        const newReturnedOrderItems = addReturnedOrderItem(orderItem, quantity + returnedQuantity);
        setReturnedOrderItems(newReturnedOrderItems);

        setQuantity(quantity + returnedQuantity);
    };

    const handleRemoveReturnedOrderItem = () => {
        if (orderReturnedType === OrderReturnedTypes.TOTAL) return;
        if (quantity === 0) return;

        let returnedQuantity = quantity > 0 && quantity < 1 ? quantity : SINGLE_ITEM;

        if (isItemSoldByWeight(orderItem)) {
            returnedQuantity = quantity;
        }

        const newReturnedOrderItems = removeReturnedOrderItem(orderItem, quantity - returnedQuantity);

        setReturnedOrderItems(newReturnedOrderItems);

        setQuantity(quantity - returnedQuantity);
    };

    return (
        <ListItem key={orderItem.key} classes={{ root: classNames(classes.rootStyles), divider: isSelected ? classes.dividerSelected : '' }} selected={isSelected} divider={true}>
            <div className={classes.infoContainer}>
                <section>
                    <div onClick={handleReturnOrderItem} className={classes.infoSection}>
                        {!isSelected && <RadioButtonUncheckedRounded classes={{ root: classNames(classes.icons, classes.unCheckIconStyle) }} />}
                        {isSelected && <CheckCircle classes={{ root: classNames(classes.checkIconStyle, classes.icons) }} />}
                        <div>
                            <div>{orderItem.name}</div>
                            {orderItem.modifierGroups.map((modifierGroup) =>
                                modifierGroup.modifiers.map((modifier) => (
                                    <div key={modifier.name} className={classes.modifier}>
                                        {modifier.quantity}x {modifier.name}
                                    </div>
                                )),
                            )}
                        </div>
                    </div>
                </section>
                {!isItemSoldByWeight(orderItem) && (
                    <section className={classes.quantitySection}>
                        {!isSingle && <PosItemQuantitySpinner quantity={quantity} onSubtract={handleRemoveReturnedOrderItem} onAdd={handleAddReturnedOrderItem} />}
                    </section>
                )}
                {isItemSoldByWeight(orderItem) && (
                    <section className={classes.quantitySection}>
                        <span>{quantityLabel}</span>
                    </section>
                )}
            </div>
        </ListItem>
    );
}

const useStyles = makeStyles((theme) => ({
    unCheckIconStyle: {
        color: theme.palette.icons.primary,
    },
    checkIconStyle: {
        color: theme.palette.icons.brand,
    },
    modifier: {
        fontFamily: theme.typography.regular,
        color: theme.palette.text.secondary,
        fontSize: 11,
    },
    icons: {
        cursor: 'pointer',
    },
    infoContainer: {
        display: 'grid',
        gridTemplateColumns: 'repeat(2, 60% 40%)',
        width: '100%',
        fontFamily: theme.typography.regular,
        color: '#0D3037',
        fontSize: 10,
        gap: 10,
        [theme.breakpoints.up('sm')]: {
            fontSize: 12,
        },
    },
    rootStyles: {
        width: '100%',
        padding: '5px 10px',
        '&.Mui-selected': {
            background: theme.palette.surface.tertiary,
            '&:hover': {
                background: theme.palette.surface.tertiary,
            },
        },
    },
    quantitySection: {
        display: 'flex',
        paddingTop: 4,
        flexDirection: 'column-reverse',
        alignItems: 'flex-end',
        justifyContent: 'space-between',
        [theme.breakpoints.up('sm')]: {
            flexDirection: 'row',
            alignItems: 'flex-start',
            justifyContent: 'flex-end',
            paddingTop: 4,
            gap: 20,
        },
    },
    infoSection: {
        display: 'flex',
        alignItems: 'center',
        gap: 10,
    },
    dividerSelected: {
        borderBottom: `1px solid ${theme.palette.border.brandContrast}`,
    },
}));

type Props = {
    orderItem: OrderItemToReturn;
    returnedOrderItems: Array<OrderItemToReturn>;
    orderReturnedType?: OrderReturnedType;
    setReturnedOrderItems: any;
};
