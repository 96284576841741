import { makeStyles } from '@material-ui/core';
import { BeakerIcon } from '@pidedirecto/ui/icons';
import * as React from 'react';
import { changeMenuCategoryApi } from 'src/api/letseatmanager/menuCategory/changeMenuCategoryApi';
import { Button } from 'src/components/Button';
import { DraggableList } from 'src/components/DraggableList';
import { DraggableListItem } from 'src/components/DraggableListItem';
import { translate } from 'src/i18n/translate';
import { ModifierGroupInMenuCategory } from 'src/scenes/letseatmanager/menu/menuCategory/ModifierGroupInMenuCategory';
import { useIsMenuEditionDisabled } from 'src/services/menu/useIsMenuEditionDisabled';
import { useModifierGroups } from 'src/services/modifierGroup/useModifierGroups';
import type { ModifierGroupId } from 'src/types/Id';
import type { MenuCategoryVm } from 'src/types/MenuCategoryVm';
import { ModifierGroupVm } from 'src/types/ModifierGroupVm';
import { alertKnownErrorOrSomethingWentWrong } from 'src/utils/alert/alertKnownErrorOrSomethingWentWrong';

export function ModifierGroupsInMenuCategory({ menuCategory, modifierGroupIds, onAddModifierGroup, onSuccess }: Props): React.ReactElement | null {
    const classes = useStyles();
    const isMenuEditionDisabled = useIsMenuEditionDisabled();

    const { modifierGroups } = useModifierGroups();

    const menuCategoryHasModifierGroups = menuCategory.modifierGroupIds && menuCategory.modifierGroupIds?.length > 0;

    const updateMenuCategory = async (modifierGroupIds: any) => {
        const response = await changeMenuCategoryApi({
            menuCategoryId: menuCategory.menuCategoryId,
            menuItemIds: menuCategory.menuItemIds,
            modifierGroupIds: modifierGroupIds,
            name: menuCategory.name,
            hidden: menuCategory.hidden,
            promotion: menuCategory.promotion,
            imageUrl: menuCategory.imageUrl,
            printerNames: menuCategory.printerNames,
        });
        if (!response.ok) {
            alertKnownErrorOrSomethingWentWrong(response);
            return;
        }
        onSuccess?.();
    };

    if (!menuCategoryHasModifierGroups) {
        return (
            <div className={classes.container}>
                <div className={classes.iconContainer}>
                    <BeakerIcon height={37} width={41} />
                </div>
                <p className={classes.text}>{translate('On this section you can manage the different modifier groups in your category')}</p>
                <Button outlined onClick={onAddModifierGroup} disabled={isMenuEditionDisabled}>
                    {translate('Add modifier group +')}
                </Button>
            </div>
        );
    }

    return (
        <DraggableList onDragEnd={updateMenuCategory}>
            {modifierGroupIds.map((modifierGroupId) => {
                const modifierGroup = modifierGroups?.find((modifierGroup: ModifierGroupVm) => modifierGroup.modifierGroupId === modifierGroupId);
                if (!modifierGroup) return null;

                return (
                    <DraggableListItem key={modifierGroupId} value={modifierGroupId} iconColor={'#6C7076'} classes={{ container: classes.draggableItemContainer }}>
                        <ModifierGroupInMenuCategory modifierGroup={modifierGroup} menuCategoryId={menuCategory.menuCategoryId} onSuccess={onSuccess} />
                    </DraggableListItem>
                );
            })}
        </DraggableList>
    );
}

const useStyles = makeStyles((theme) => ({
    container: {
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    iconContainer: {
        width: 80,
        height: 80,
        borderRadius: 80,
        backgroundColor: `${theme.palette.surface.brand}63`,
        color: theme.palette.icons.brand,
        marginBottom: 50,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    text: {
        fontFamily: theme.typography.regular,
        fontSize: 15,
        width: '80%',
        textAlign: 'center',
        margin: '20px 0',
        [theme.breakpoints.down('sm')]: {
            width: '90%',
        },
    },
    draggableItemContainer: {
        alignItems: 'center',
        marginBottom: 2,
    },
}));

type Props = {
    menuCategory: MenuCategoryVm;
    modifierGroupIds: Array<ModifierGroupId>;
    onAddModifierGroup: any;
    onSuccess?: any;
};
