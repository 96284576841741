import { useEffect } from 'react';
import { useLoadMenuItems } from 'src/services/menuItem/useLoadMenuItems';
import type { ManagerMenuItemVm as DeprecatedManagerMenuItemVm } from 'src/types/DeprecatedManagerMenuItemVm';
import type { ManagerMenuItemVm } from 'src/types/ManagerMenuItemVm';
import { useSelector } from 'src/utils/react/useSelector';

export function useMenuItems(): MenuItems {
    const menuItems = useSelector((state) => state.menu.menuItems);
    const deprecatedModifiers = useSelector((state) => state.theMenu.modifiers);
    const deprecatedMenuCategories = useSelector((state) => state.theMenu.menuCategories);
    const menuItemsLoaded = useSelector((state) => state.menu.menuItemsLoaded);
    const newMenuManagementEnabled = useSelector((state) => state.app.restaurant.newMenuManagementEnabled);
    const restaurantId = useSelector((state) => state.app.restaurantId);

    const loadMenuItems = useLoadMenuItems();

    const modifiers = menuItems?.filter((menuItem: ManagerMenuItemVm) => menuItem.isModifier) ?? [];
    const products = menuItems?.filter((menuItem: ManagerMenuItemVm) => menuItem.isProduct) ?? [];
    const deprecatedMenuItems = deprecatedMenuCategories?.flatMap((category) => category.menuItems) ?? [];

    useEffect(() => {
        if (newMenuManagementEnabled && !menuItems?.length && !menuItemsLoaded && restaurantId) {
            loadMenuItems();
        }
    }, [newMenuManagementEnabled, menuItems, menuItemsLoaded, restaurantId]);

    return {
        menuItems,
        modifiers,
        products,
        deprecatedModifiers,
        deprecatedMenuItems,
    };
}

type MenuItems = {
    menuItems: Array<ManagerMenuItemVm>;
    modifiers: Array<ManagerMenuItemVm>;
    products: Array<ManagerMenuItemVm>;
    deprecatedModifiers: Array<DeprecatedManagerMenuItemVm>;
    deprecatedMenuItems: Array<DeprecatedManagerMenuItemVm>;
};
