import { makeStyles } from '@material-ui/core';
import { Button } from '@pidedirecto/ui';
import * as React from 'react';
import { useEffect } from 'react';
import { translate } from 'src/i18n/translate';
import { posReducer } from 'src/reducers/posReducer';
import { MenuCategoryFamilyId } from 'src/types/Id';
import { useAction } from 'src/utils/react/useAction';
import { useSelector } from 'src/utils/react/useSelector';

export function PosMenuCategoryFamilies(): React.ReactElement | null {
    const classes = useStyles();

    const menuCategories = useSelector((state) => state.pos.posMenu?.menuCategories) ?? [];
    const menus = useSelector((state) => state.pos.posMenu?.menus);
    const menuCategoryFamiliesEnabled = useSelector((state) => state.app.restaurant?.menuCategoryFamiliesEnabled);
    const menuCategoryFamilies = useSelector((state) => state.pos.posMenu?.menuCategoryFamilies);
    const selectedMenuCategoryFamilyId = useSelector((state) => state.pos.selectedMenuCategoryFamilyId);
    const shouldShowMenuCategoriesWithoutMenuCategoryFamily = useSelector((state) => state.pos.shouldShowMenuCategoriesWithoutMenuCategoryFamily);

    const setSelectedMenuCategoryFamilyId = useAction(posReducer.actions.setSelectedMenuCategoryFamilyId);
    const setShouldShowMenuCategoriesWithoutMenuCategoryFamily = useAction(posReducer.actions.setShouldShowMenuCategoriesWithoutMenuCategoryFamily);

    const usedMenuCategories = menuCategories.filter((menuCategory) => menus.some((menu) => menu.menuCategoryIds.includes(menuCategory.menuCategoryId)));
    const isThereMenuCategoryWithoutFamily = usedMenuCategories.some((menuCategory) => !menuCategory.menuCategoryFamilyId);
    const hasMenuCategoryFamilies = menuCategoryFamilies?.length;

    useEffect(() => {
        if (hasMenuCategoryFamilies) {
            setSelectedMenuCategoryFamilyId(menuCategoryFamilies[0].menuCategoryFamilyId);
        }
    }, [hasMenuCategoryFamilies]);

    const selectMenuCategoryFamily = (menuCategoryFamilyId: MenuCategoryFamilyId) => {
        setSelectedMenuCategoryFamilyId(menuCategoryFamilyId);
    };

    if (!menuCategoryFamiliesEnabled || !menuCategoryFamilies?.length) return null;

    return (
        <div className={classes.container}>
            {menuCategoryFamilies.map((menuCategoryFamily) => (
                <Button
                    variant={selectedMenuCategoryFamilyId === menuCategoryFamily.menuCategoryFamilyId ? 'outline' : 'secondary'}
                    key={menuCategoryFamily.menuCategoryFamilyId}
                    onClick={() => selectMenuCategoryFamily(menuCategoryFamily.menuCategoryFamilyId)}
                >
                    {menuCategoryFamily.name}
                </Button>
            ))}
            {isThereMenuCategoryWithoutFamily && (
                <Button variant={shouldShowMenuCategoriesWithoutMenuCategoryFamily ? 'outline' : 'secondary'} onClick={() => setShouldShowMenuCategoriesWithoutMenuCategoryFamily(true)}>
                    {translate('Others')}
                </Button>
            )}
        </div>
    );
}

const useStyles = makeStyles((theme) => ({
    container: {
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap',
        width: '100%',
        gap: 6,
        marginBottom: 20,
    },
}));
