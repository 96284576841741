import { letseatadminApiMethod } from 'src/api/letseatadminApi';
import type { ApiSauceResponse } from 'src/api/types/ApiSauceResponse';
import type { BannerId } from 'src/types/Id';

export async function removeBannerApi(request: RemoveBannerApiRequest): ApiSauceResponse<void> {
    return letseatadminApiMethod('banner/removeBannerApi', request);
}

type RemoveBannerApiRequest = {
    bannerId: BannerId;
};
