import * as React from 'react';
import { useState } from 'react';
import { ContextualMenuItem } from 'src/components/ContextualMenuItem';
import { NumberOfCustomersDialog } from 'src/components/dialog/pos/NumberOfCustomersDialog';
import { translate } from 'src/i18n/translate';
import { posReducer } from 'src/reducers/posReducer';
import { isEatHereOrder } from 'src/utils/order/isEatHereOrder';
import { useAction } from 'src/utils/react/useAction';
import { useSelector } from 'src/utils/react/useSelector';

export function TakeOrderByCustomerContextualMenuItem({ disabled }: Props): React.ReactElement | null {
    const [numberOfCustomerDialogState, setNumberOfCustomersDialogState] = useState<{ open: boolean; onSuccess: any }>({ open: false, onSuccess: undefined });

    const orderType = useSelector((state) => state.pos.orderType);
    const items = useSelector((state) => state.pos.items);
    const numberOfCustomers = useSelector((state) => state.pos.numberOfCustomers);
    const table = useSelector((state) => state.pos.table);

    const setNumberOfCustomers = useAction(posReducer.actions.setNumberOfCustomers);
    const setEmptyCustomers = useAction(posReducer.actions.setEmptyCustomers);

    const showTakeOrderByCustomerOption = isEatHereOrder(orderType) && !items?.length;

    const handleTakeOrderByCustomer = async () => {
        if (!numberOfCustomers && !table?.numberOfCustomers) {
            setNumberOfCustomersDialogState({
                open: true,
                onSuccess: (numberOfCustomers: number) => {
                    setNumberOfCustomers(numberOfCustomers);
                    setEmptyCustomers(numberOfCustomers);
                },
            });
        }

        setEmptyCustomers(numberOfCustomers ?? table.numberOfCustomers);

        return true;
    };

    if (!showTakeOrderByCustomerOption) return null;

    return (
        <div>
            <NumberOfCustomersDialog
                open={numberOfCustomerDialogState.open}
                onSuccess={numberOfCustomerDialogState.onSuccess}
                handleClose={() => setNumberOfCustomersDialogState({ open: false, onSuccess: undefined })}
            />
            <ContextualMenuItem disabled={disabled} label={translate('Take order by customer')} onClick={handleTakeOrderByCustomer} />
        </div>
    );
}

type Props = {
    disabled?: boolean;
};
