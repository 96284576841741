import { makeStyles } from '@material-ui/core/styles';
import { useState } from 'react';
import * as React from 'react';
import { verifyPosPasswordApi } from 'src/api/letseatmanager/pos/verifyPosPasswordApi';
import { app2 } from 'src/app2';
import { Dialog } from 'src/components/Dialog';
import { NumericPad } from 'src/components/NumericPad';
import { translate } from 'src/i18n/translate';
import { alertKnownErrorOrSomethingWentWrong } from 'src/utils/alert/alertKnownErrorOrSomethingWentWrong';
import { useAction } from 'src/utils/react/useAction';
import { useSelector } from 'src/utils/react/useSelector';

export function VerifyPosPasswordDialog(): React.ReactElement {
    const classes = useStyles();

    const [loading, setLoading] = useState(false);

    const restaurantId = useSelector((state) => state.app.restaurantId);
    const open = useSelector((state) => state.app2.verifyPosPasswordDialog.open);
    const onAuthorized = useSelector((state) => state.app2.verifyPosPasswordDialog.onAuthorized);
    const onUnauthorized = useSelector((state) => state.app2.verifyPosPasswordDialog.onUnauthorized);

    const closeVerifyPosPasswordDialog = useAction(app2.actions.closeVerifyPosPasswordDialog);

    const handleClose = () => {
        if (loading) return;
        closeVerifyPosPasswordDialog();
    };

    const handleSubmit = async (posPassword: any) => {
        setLoading(true);
        const response = await verifyPosPasswordApi({ restaurantId, posPassword });
        setLoading(false);
        if (!response.ok) {
            return alertKnownErrorOrSomethingWentWrong(response);
        }
        if (!response.data) return onUnauthorized?.();
        closeVerifyPosPasswordDialog();
        onAuthorized?.();
    };

    return (
        <Dialog classes={{ dialogContainer: classes.dialog }} open={open} onClose={handleClose} title={translate('Authorize')}>
            <p className={classes.bodyText}>{translate('Enter the POS Password')}</p>
            <NumericPad onSubmit={handleSubmit} isPassword={true} />
        </Dialog>
    );
}

const useStyles = makeStyles((theme) => ({
    bodyText: {
        fontFamily: theme.typography.medium,
        textAlign: 'center',
        color: theme.palette.secondary.contrastText,
    },
    dialog: {
        zIndex: 1100,
    },
}));
