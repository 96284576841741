import { makeStyles } from '@material-ui/core';
import * as React from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import { FormDeliveryProvidersDraggableList } from 'src/components/form/FormDeliveryProvidersDraggableList';
import { FormSwitch } from 'src/components/form/FormSwitch';
import { DeliveryProvider, DeliveryProviders } from 'src/constants/DeliveryProviders';
import { translate } from 'src/i18n/translate';

export function DeliveryManagerCyclicalSettings(): React.ReactElement {
    const classes = useStyles();

    const { control, watch } = useFormContext();

    const externalDelivery = useWatch<boolean>({ name: 'externalDelivery', control });
    const cyclicalDeliveryManagerEnabled = watch('cyclicalDeliveryManager');
    const pideDirectoDeliveryEnabled = watch('pideDirectoDeliveryEnabled');
    const uberDaasDeliveryEnabled = watch('uberDaasDeliveryEnabled');
    const rappiCargoDeliveryEnabled = watch('rappiCargoDeliveryEnabled');
    const waitTimePerDeliveryCashProviders = watch('waitTimePerDeliveryCashProviders');
    const cashDeliveryProviders = watch('cashDeliveryProviders');
    const waitTimePerDeliveryCardProviders = watch('waitTimePerDeliveryCardProviders');
    const cardDeliveryProviders = watch('cardDeliveryProviders');

    const getAvailableDeliveryProviders = () => {
        const deliveryProviders: Array<DeliveryProvider> = [];
        if (pideDirectoDeliveryEnabled) deliveryProviders.push(DeliveryProviders.PIDEDIRECTO);
        if (uberDaasDeliveryEnabled) deliveryProviders.push(DeliveryProviders.UBER_DAAS);
        if (rappiCargoDeliveryEnabled) deliveryProviders.push(DeliveryProviders.RAPPI_CARGO);
        return deliveryProviders;
    };

    return (
        <>
            <FormSwitch name='cyclicalDeliveryManager' label={translate('Activate Cyclical delivery manager')} disabled={externalDelivery} />
            <div className={classes.contentItem}>
                {cyclicalDeliveryManagerEnabled && (
                    <FormDeliveryProvidersDraggableList
                        nameOrderValues='cashDeliveryProviders'
                        nameValues='waitTimePerDeliveryCashProviders'
                        filterDeliveryProviders={getAvailableDeliveryProviders()}
                        title={translate('Cash Delivery Providers')}
                        waitTimePerDeliveryProviders={waitTimePerDeliveryCashProviders}
                        orderDeliveryProvider={cashDeliveryProviders}
                    />
                )}
                {cyclicalDeliveryManagerEnabled && (
                    <FormDeliveryProvidersDraggableList
                        nameOrderValues='cardDeliveryProviders'
                        nameValues='waitTimePerDeliveryCardProviders'
                        filterDeliveryProviders={getAvailableDeliveryProviders()}
                        title={translate('Card Delivery Providers')}
                        waitTimePerDeliveryProviders={waitTimePerDeliveryCardProviders}
                        orderDeliveryProvider={cardDeliveryProviders}
                    />
                )}
            </div>
        </>
    );
}

const useStyles = makeStyles((theme) => ({
    contentItem: {
        display: 'flex',
        width: '100%',
        alignItems: 'center',
        marginTop: 6,
        justifyContent: 'space-between',
        gap: 20,
        fontFamily: theme.typography.regular,
    },
}));
