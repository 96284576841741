import { makeStyles } from '@material-ui/core';
import { Select } from '@pidedirecto/ui';
import moment from 'moment-timezone';
import { MUIDataTableColumn } from 'mui-datatables';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { getKardexReportApi } from 'src/api/letseatmanager/inventory/getKardexReportApi';
import { RefreshToolbarButton } from 'src/components/mui-datatables/RefreshToolbarButton';
import { Table } from 'src/components/Table';
import { CostTypes } from 'src/constants/CostType';
import { translate } from 'src/i18n/translate';
import { FilterCostType } from 'src/scenes/letseatmanager/restaurantDashboard/FilterCostType';
import { FilterReports } from 'src/scenes/letseatmanager/restaurantDashboard/FilterReports';
import { useFormatAsRestaurantCurrencyNumber } from 'src/services/restaurant/useFormatAsRestaurantCurrencyNumber';
import { useFormatDateToRestaurantTimeZone } from 'src/utils/react/useFormatDateToRestaurantTimeZone';
import { useLoadApi } from 'src/utils/react/useLoadApi';
import { useSelector } from 'src/utils/react/useSelector';

export function InventoryKardexReport(): React.ReactElement {
    const classes = useStyles();
    const formatAsCurrencyNumber = useFormatAsRestaurantCurrencyNumber();

    const [mode, setMode] = useState<ColumnType>(ColumnTypes.COST);

    const restaurantId = useSelector((state) => state.app.restaurantId);
    const period = useSelector((state) => state.app2.filterReportsState.period);
    const costType = useSelector((state) => state.app2.filterReportsState.costType);

    const formatDateToRestaurantTimeZone = useFormatDateToRestaurantTimeZone();

    const [loading, kardexReports, refreshKardexReports] = useLoadApi(
        getKardexReportApi,
        {
            restaurantIds: [restaurantId],
            fromDate: moment(formatDateToRestaurantTimeZone(period?.from)).toISOString(),
            toDate: moment(formatDateToRestaurantTimeZone(period.to)).add(1, 'day').toISOString(),
        },
        {
            initialValue: [],
            dependencies: [restaurantId, period?.from, period?.to],
        },
    );

    useEffect(() => {
        refreshKardexReports();
    }, [costType]);

    const handleChangeColumns = (mode: any) => {
        setMode(mode);
        refreshKardexReports();
    };

    const handleChangeCostType = () => {
        setMode(ColumnTypes.COST);
        refreshKardexReports();
    };

    const getInitialCost = () => {
        switch (costType) {
            case CostTypes.COST_FIXED:
                return 'initialCostByFixedCost';
            case CostTypes.COST_BY_AVERAGE_WEIGHTED_COST:
                return 'initialCostByAverageWeightedCost';
            case CostTypes.COST_BY_LAST_PURCHASE_COST:
                return 'initialCostByLastPurchaseCost';
            default:
                return 'initialCostByFixedCost';
        }
    };

    const getPurchaseValue = () => {
        switch (costType) {
            case CostTypes.COST_FIXED:
                return 'purchaseValueByFixedCost';
            case CostTypes.COST_BY_AVERAGE_WEIGHTED_COST:
                return 'purchaseValueByAverageWeightedCost';
            case CostTypes.COST_BY_LAST_PURCHASE_COST:
                return 'purchaseValueByLastPurchaseCost';
            default:
                return 'purchaseValueByFixedCost';
        }
    };

    const getAdjustmentInValue = () => {
        switch (costType) {
            case CostTypes.COST_FIXED:
                return 'adjustmentInValueByFixedCost';
            case CostTypes.COST_BY_AVERAGE_WEIGHTED_COST:
                return 'adjustmentInValueByAverageWeightedCost';
            case CostTypes.COST_BY_LAST_PURCHASE_COST:
                return 'adjustmentInValueByLastPurchaseCost';
            default:
                return 'adjustmentInValueByFixedCost';
        }
    };

    const getAdjustmentOutValue = () => {
        switch (costType) {
            case CostTypes.COST_FIXED:
                return 'adjustmentOutValueByFixedCost';
            case CostTypes.COST_BY_AVERAGE_WEIGHTED_COST:
                return 'adjustmentOutValueByAverageWeightedCost';
            case CostTypes.COST_BY_LAST_PURCHASE_COST:
                return 'adjustmentOutValueByLastPurchaseCost';
            default:
                return 'adjustmentOutValueByFixedCost';
        }
    };

    const getSalesValue = () => {
        switch (costType) {
            case CostTypes.COST_FIXED:
                return 'salesValueByFixedCost';
            case CostTypes.COST_BY_AVERAGE_WEIGHTED_COST:
                return 'salesValueByAverageWeightedCost';
            case CostTypes.COST_BY_LAST_PURCHASE_COST:
                return 'salesValueByLastPurchaseCost';
            default:
                return 'salesValueByFixedCost';
        }
    };

    const getFinalCost = () => {
        switch (costType) {
            case CostTypes.COST_FIXED:
                return 'finalCostByFixedCost';
            case CostTypes.COST_BY_AVERAGE_WEIGHTED_COST:
                return 'finalCostByAverageWeightedCost';
            case CostTypes.COST_BY_LAST_PURCHASE_COST:
                return 'finalCostByLastPurchaseCost';
            default:
                return 'finalCostByFixedCost';
        }
    };

    const commonColumns: Array<MUIDataTableColumn> = [
        {
            name: 'sku',
            label: translate('SKU'),
            options: {
                filter: false,
                customBodyRender: (value: string) => <div style={{ minWidth: '100px' }}>{value}</div>,
            },
        },
        {
            name: 'category',
            label: translate('Category'),
            options: {
                filter: true,
                customBodyRender: (value: string) => <div style={{ minWidth: '100px' }}>{value}</div>,
            },
        },
        {
            name: 'name',
            label: translate('Supply'),
            options: {
                filter: true,
                customBodyRender: (value: string) => <div style={{ minWidth: '100px' }}>{value}</div>,
            },
        },
    ];

    const columns: Record<ColumnType, any> = {
        [ColumnTypes.COST]: [
            ...commonColumns,
            {
                name: getInitialCost(),
                label: translate('Initial Inventory'),
                options: {
                    filter: false,
                    customBodyRender: (value: string) => <div style={{ minWidth: '120px' }}>{formatAsCurrencyNumber(value)}</div>,
                },
            },

            {
                name: getPurchaseValue(),
                label: translate('Purchase Value'),
                options: {
                    filter: false,
                    customBodyRender: (value: string) => <div style={{ minWidth: '120px' }}>{formatAsCurrencyNumber(value)}</div>,
                },
            },

            {
                name: getAdjustmentInValue(),
                label: translate('Adjustment (+)'),
                options: {
                    filter: false,
                    customBodyRender: (value: string) => <div style={{ minWidth: '120px' }}>{formatAsCurrencyNumber(value)}</div>,
                },
            },

            {
                name: getSalesValue(),
                label: translate('Sales Value'),
                options: {
                    filter: false,
                    customBodyRender: (value: string) => <div style={{ minWidth: '120px' }}>{formatAsCurrencyNumber(value)}</div>,
                },
            },
            {
                name: getAdjustmentOutValue(),
                label: translate('Adjustment (-)'),
                options: {
                    filter: false,
                    customBodyRender: (value: string) => <div style={{ minWidth: '120px' }}>{formatAsCurrencyNumber(value)}</div>,
                },
            },
            {
                name: getFinalCost(),
                label: translate('Final Inventory'),
                options: {
                    filter: false,
                    customBodyRender: (value: string) => <div style={{ minWidth: '120px' }}>{formatAsCurrencyNumber(value)}</div>,
                },
            },
        ],
        [ColumnTypes.QUANTITY]: [
            ...commonColumns,
            {
                name: 'initialAmount',
                label: translate('Initial Inventory'),
                options: {
                    filter: false,
                    customBodyRender: (value: string) => <div style={{ minWidth: '120px' }}>{value}</div>,
                },
            },
            {
                name: 'purchaseUnits',
                label: translate('Purchase Units'),
                options: {
                    filter: false,
                    customBodyRender: (value: string) => <div style={{ minWidth: '120px' }}>{value}</div>,
                },
            },
            {
                name: 'adjustmentInUnits',
                label: translate('Adjustment (+)'),
                options: {
                    filter: false,
                    customBodyRender: (value: string) => <div style={{ minWidth: '120px' }}>{value}</div>,
                },
            },

            {
                name: 'salesUnits',
                label: translate('Sales Units'),
                options: {
                    filter: false,
                    customBodyRender: (value: string) => <div style={{ minWidth: '120px' }}>{value}</div>,
                },
            },
            {
                name: 'adjustmentOutUnits',
                label: translate('Adjustment (-)'),
                options: {
                    filter: false,
                    customBodyRender: (value: string) => <div style={{ minWidth: '120px' }}>{value}</div>,
                },
            },
            {
                name: 'finalAmount',
                label: translate('Final Inventory'),
                options: {
                    filter: false,
                    customBodyRender: (value: string) => <div style={{ minWidth: '120px' }}>{value}</div>,
                },
            },
        ],
    } as const;

    const selectOptions = Object.values(ColumnTypes).map((columnType) => ({
        label: translate(`ColumnTypes.${columnType}`),
        value: columnType,
    }));

    return (
        <div className={classes.container}>
            <div className={classes.filterContainer}>
                <FilterReports />
                <div className={classes.supplyFilters}>
                    <Select value={mode} placeholder={translate('Mode')} name='mode' options={selectOptions} onChange={handleChangeColumns} position='right' />
                    {mode !== ColumnTypes.QUANTITY && <FilterCostType onChange={handleChangeCostType} position='right' />}
                </div>
            </div>
            <Table
                loading={loading}
                data={kardexReports}
                columns={columns[mode]}
                options={{
                    responsive: 'standard',
                    tableBodyMaxHeight: '500px',
                    selectableRows: 'none',
                    filterType: 'checkbox',
                    rowsPerPage: 100,
                    customToolbar: () => (
                        <>
                            <RefreshToolbarButton onClick={refreshKardexReports} />
                        </>
                    ),
                }}
            />
        </div>
    );
}

const ColumnTypes = {
    COST: 'COST',
    QUANTITY: 'QUANTITY',
} as const;

type ColumnType = (typeof ColumnTypes)[keyof typeof ColumnTypes];

const useStyles = makeStyles((theme) => ({
    container: {
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        [theme.breakpoints.down('sm')]: {
            padding: 12,
        },
    },
    supplyFilters: {
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'nowrap',
        alignItems: 'center',
        gap: 10,
    },
    filterContainer: {
        paddingTop: 20,
        paddingBottom: 20,
        display: 'flex',
        justifyContent: 'space-between',
    },
}));
