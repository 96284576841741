import { makeStyles } from '@material-ui/core';
import { BigNumber } from 'bignumber.js';
import * as React from 'react';
import type { DailySalesAndOrdersReport } from 'src/api/letseatmanager/restaurantDashboard/getIntegrationsPaymentReportApi';
import { ResponsiveLineCanvas } from 'src/components/charts/ResponsiveLineCanvas';
import { Text } from 'src/components/Text';
import { IntegrationPlatforms, type IntegrationPlatform } from 'src/constants/IntegrationPlatforms';
import { translate } from 'src/i18n/translate';
import { KpiCard } from 'src/scenes/letseatmanager/restaurantDashboard/KpiCard';
import { ReportSection } from 'src/scenes/letseatmanager/restaurantDashboard/ReportSection';
import { useFormatAsRestaurantCurrencyNumber } from 'src/services/restaurant/useFormatAsRestaurantCurrencyNumber';

export function IntegrationsPaymentReportSalesPerDayGraph({ dailySalesAndOrdersReport, selectedIntegrationPlatforms, loading }: Props): React.ReactElement | null {
    const classes = useStyles();
    const formatAsCurrencyNumber = useFormatAsRestaurantCurrencyNumber();

    const colors = [
        '#2476C2',
        '#20B2AA',
        '#F1948A',
        '#FFD700',
        '#53C7D7',
        '#7B68EE',
        '#FF8C00',
        '#40E0D0',
        '#FFA07A',
        '#9370DB',
        '#1E90FF',
        '#FFB6C1',
        '#0DFFD3',
        '#A0D8F8',
        '#A7A0F8',
        '#6256E9',
        '#00FF7F',
        '#8A2BE2',
        '#5DE3BC',
        '#24A8C2',
        '#13647D',
    ];

    const integrationPlatformsSales: { [key in IntegrationPlatform]: keyof DailySalesAndOrdersReport } = {
        [IntegrationPlatforms.RAPPI]: 'rappiSales',
        [IntegrationPlatforms.UBER_EATS]: 'uberEatsSales',
        [IntegrationPlatforms.DIDI_FOOD]: 'didiFoodSales',
        [IntegrationPlatforms.PEDIDOS_YA]: 'pedidosYaSales',
    };

    const data = selectedIntegrationPlatforms.map((integrationPlatform) => ({
        id: translate(`IntegrationPlatforms.${integrationPlatform}`),
        data: dailySalesAndOrdersReport.map((report) => ({
            x: report.createdAt,
            y: new BigNumber(report[integrationPlatformsSales[integrationPlatform]] ?? 0).toNumber(),
        })),
    }));

    const integrationPlatformsAverageSales = selectedIntegrationPlatforms.map((integrationPlatform) => {
        const totalSales = dailySalesAndOrdersReport.reduce((total, report) => {
            return new BigNumber(total).plus(report[integrationPlatformsSales[integrationPlatform]] ?? 0).toNumber();
        }, 0);
        const averageSales = new BigNumber(totalSales).dividedBy(dailySalesAndOrdersReport.length).toNumber();
        return {
            name: integrationPlatform,
            averageSales,
        };
    });

    if (!selectedIntegrationPlatforms.length) return null;

    return (
        <ReportSection loading={loading} title={translate('Sales per day')}>
            <div style={{ width: '100%', height: '40vh', display: 'flex' }}>
                <ResponsiveLineCanvas
                    colors={colors}
                    data={data}
                    tooltip={({ point }: any) => (
                        <div className={classes.tooltip}>
                            <span className={classes.tooltipText}>{translate(`Date: @date`, { date: point.data.x })}</span>
                            <span className={classes.tooltipText}>
                                {translate('Total Sales')}: <strong>{formatAsCurrencyNumber(point.data.y)}</strong>
                            </span>
                        </div>
                    )}
                />
                <div style={{ display: 'flex', flexDirection: 'column', gap: 12 }}>
                    <Text variant='semiBold'>{translate('Average sales')}</Text>
                    {integrationPlatformsAverageSales.map((integrationPlatform, index) => (
                        <KpiCard
                            key={index}
                            color={colors[index]}
                            legend={formatAsCurrencyNumber(integrationPlatform.averageSales)}
                            title={translate(`IntegrationPlatforms.${integrationPlatform.name}`)}
                        />
                    ))}
                </div>
            </div>
        </ReportSection>
    );
}

const useStyles = makeStyles((theme) => ({
    tooltip: {
        padding: 10,
        borderRadius: 3,
        backgroundColor: theme.palette.surface.tertiary,
        boxShadow: '1px 1px 1px rgb(0,0,0,0.2)',
        display: 'flex',
        flexDirection: 'column',
    },
    tooltipText: {
        fontFamily: theme.typography.regular,
        color: theme.palette.text.secondary,
    },
}));

type Props = {
    dailySalesAndOrdersReport: Array<DailySalesAndOrdersReport>;
    loading: boolean;
    selectedIntegrationPlatforms: Array<IntegrationPlatform>;
};
