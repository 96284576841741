import type { GeoJsonPoint } from 'src/types/GeoJsonPoint';
import type { GooglePlaceId } from 'src/types/Id';

export function openDirections(origin: GeoJsonPoint | null | undefined, destination: GeoJsonPoint, destinationGooglePlaceId?: GooglePlaceId): void {
    // window.open(`https://www.google.mx/maps/search/?api=1&query=${location.coordinates[1]},${location.coordinates[0]}&query_place_id=${googlePlaceId}`, '_blank');
    window.open(
        `https://www.google.mx/maps/dir/?api=1${origin ? `&origin=${origin.coordinates[1]},${origin.coordinates[0]}` : ''}&destination=${destination.coordinates[1]},${destination.coordinates[0]}${
            destinationGooglePlaceId ? `&destination_place_id=${destinationGooglePlaceId}` : ''
        }&travelmode=driving`,
        '_blank',
    );
}
