import { makeStyles } from '@material-ui/core';
import * as React from 'react';
import { useHistory } from 'react-router-dom';
import { Button } from 'src/components/Button';
import { translate } from 'src/i18n/translate';
import { BackArrowIcon } from 'src/icons/BackArrowIcon';
import { classNames } from 'src/utils/react/classNames';

export function GoBackButton({ goBack, disabled, classes: classesProp, label }: Props): React.ReactElement {
    const classes = useStyles();
    const history = useHistory();

    const handleGoBack = () => {
        if (goBack) return goBack();
        history.goBack();
    };

    return (
        <Button disabled={disabled} onClick={handleGoBack} classes={{ button: classNames(classes.button, classesProp?.button), disabled: classesProp?.disabled }}>
            <BackArrowIcon />
            <div>{label ?? translate('Go Back')}</div>
        </Button>
    );
}

const useStyles = makeStyles((theme) => ({
    button: {
        position: 'absolute',
        fontFamily: theme.typography.regular,
        fontSize: 14,
        color: '#2E3748',
        backgroundColor: 'transparent',
        top: 0,
        cursor: 'pointer',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: 0,
        '&:focus': {
            margin: '0 -1px',
            border: '1px solid #1A73F9',
        },
        '&:hover': {
            '&:hover': {
                backgroundColor: 'rgba(0, 0, 0, 0.04)',
            },
        },
    },
}));

type Props = {
    goBack?: any;
    label?: string;
    disabled?: boolean;
    classes?: {
        button?: string;
        disabled?: string;
    };
};
