import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { makeStyles } from '@material-ui/core/styles';
import { formatAsCurrencyNumber } from '@pidedirecto/ui/utils';
import * as React from 'react';
import type { DriverBonusBillingDetailsVm, GetDriverBonusBillingDetailsApiResponse } from 'src/api/letseatadmin/driverBonusBilling/getDriverBonusBillingDetailsApi';
import { translate } from 'src/i18n/translate';

export function DriverBonusBillingDetailsDialog({ open, driverBonusBillingDetails, handleClose }: Props): React.ReactElement {
    const classes = useStyles();

    return (
        <Dialog aria-labelledby='change-menu-dialog-title' fullWidth scroll='paper' open={open} onClose={handleClose} classes={{ paper: classes.dialogContainer }}>
            <DialogTitle id='change-menu-dialog-title' classes={{ root: classes.dialogTitle }}>
                {translate('Driver Bonus Billing Details')}
            </DialogTitle>
            <DialogContent style={{ width: '100%' }}>
                <div className={classes.table}>
                    <div className={classes.tableHeader}>
                        <span className={classes.textBold} style={{ width: '30%' }}>
                            {translate('Name')}
                        </span>
                        <span className={classes.textBold} style={{ width: '30%' }}>
                            {translate('Mobile number')}
                        </span>
                        <span className={classes.textBold} style={{ width: '20%' }}>
                            {translate('Usage')}
                        </span>
                        <span className={classes.textBold} style={{ width: '20%' }}>
                            {translate('Amount')}
                        </span>
                    </div>
                    {driverBonusBillingDetails?.drivers?.map((driver: DriverBonusBillingDetailsVm) => (
                        <div className={classes.tableRow} key={driver.mobileNumber}>
                            <span className={classes.text} style={{ width: '30%' }}>
                                {driver.driverName}
                            </span>
                            <span className={classes.text} style={{ width: '30%' }}>
                                {driver.mobileNumber}
                            </span>
                            <span className={classes.text} style={{ width: '20%' }}>
                                {driver.usage}
                            </span>
                            <span className={classes.text} style={{ width: '20%' }}>
                                {formatAsCurrencyNumber(driver.amount)}
                            </span>
                        </div>
                    ))}
                </div>
            </DialogContent>
            <DialogActions style={{ marginTop: 20, marginBottom: 20 }} className={classes.buttonsContainer}>
                <Button onClick={handleClose}>
                    <div className={classes.cancelButton}>{translate('Close')}</div>
                </Button>
            </DialogActions>
        </Dialog>
    );
}

const useStyles = makeStyles((theme) => ({
    dialogContainer: {
        width: '70%',
        maxWidth: '100%',
    },
    dialogTitle: {
        textAlign: 'center',
        fontSize: 41,
        color: theme.palette.secondary.contrastText,
        paddingTop: theme.spacing(4),
        paddingBottom: theme.spacing(3),
        '& h2': {
            fontFamily: theme.typography.medium,
        },
    },
    buttonsContainer: {
        marginTop: 20,
        marginBottom: 20,
        [theme.breakpoints.down('sm')]: {
            flexDirection: 'column',
        },
    },
    textBold: {
        fontFamily: theme.typography.medium,
        fontSize: 18,
        margin: 0,
        textAlign: 'center',
    },
    text: {
        fontFamily: theme.typography.regular,
        fontSize: 15,
        marginBottom: 20,
        textAlign: 'center',
    },
    cancelButton: {
        backgroundColor: theme.palette.secondary.dark,
        color: theme.palette.secondary.contrastText,
        fontFamily: theme.typography.semiBold,
        fontSize: 15,
        borderRadius: 5,
        height: 40,
        width: 200,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        textTransform: 'none',
    },
    table: {
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
    },
    tableHeader: {
        width: '100%',
        backgroundColor: theme.palette.secondary.light,
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        paddingTop: 10,
        paddingBottom: 10,
        marginBottom: 10,
    },
    tableRow: {
        width: '100%',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        marginBottom: 20,
    },
}));

type Props = {
    open: boolean;
    driverBonusBillingDetails?: GetDriverBonusBillingDetailsApiResponse;
    handleClose: any;
};
