import { isMobileApp } from 'src/utils/reactNative/isMobileApp';

export function signedOutInMobileApp() {
    if (isMobileApp()) {
        window.ReactNativeWebView.postMessage(
            JSON.stringify({
                type: 'SIGNED_OUT',
                data: '',
            }),
        );
    }
}
