import { useState } from 'react';
import { findOpenedCashRegisterApi } from 'src/api/letseatmanager/pos/findOpenedCashRegisterApi';
import { findOpenedCashRegisterInCashRegisterPosBusinessDayApi } from 'src/api/letseatmanager/pos/restaurantPosBusinessDay/findOpenedCashRegisterInCashRegisterPosBusinessDayApi';
import { ApiErrorResponse } from 'src/api/types/ApiSauceResponse';
import { RolePermissions } from 'src/constants/RolePermission';
import { findPosBusinessDaySummaryFromLocalStorage } from 'src/localStorage/findPosBusinessDaySummaryFromLocalStorage';
import { findPosCashRegisterOfflineInLocalStorage } from 'src/localStorage/findPosCashRegisterOfflineInLocalStorage';
import { setPosCashRegisterOfflineInLocalStorage } from 'src/localStorage/setPosCashRegisterOfflineInLocalStorage';
import { posReducer } from 'src/reducers/posReducer';
import { useFindOpenedUserCashRegisterPosBusinessDay } from 'src/services/cashRegister/useFindOpenedUserCashRegisterPosBusinessDay';
import { alertKnownErrorOrSomethingWentWrong } from 'src/utils/alert/alertKnownErrorOrSomethingWentWrong';
import { useAction } from 'src/utils/react/useAction';
import { useSelector } from 'src/utils/react/useSelector';
import { useUserHasRolePermission } from 'src/utils/react/useUserHasRolePermissions';

export function useFetchCashRegister(): {
    fetchOpenedCashRegister: () => Promise<void>;
    loading: boolean;
} {
    const [userHasRolePermission] = useUserHasRolePermission();
    const { findUserCashRegisterPosBusinessDay } = useFindOpenedUserCashRegisterPosBusinessDay();

    const [loading, setLoading] = useState(false);

    const restaurantId = useSelector((state) => state.app.restaurantId);
    const posMultipleCashRegistersEnabled = useSelector((state) => state.app.restaurant?.posMultipleCashRegistersEnabled);

    const setOpenedCashRegister = useAction(posReducer.actions.setOpenedCashRegister);
    const setCashRegisterOpen = useAction(posReducer.actions.setCashRegisterOpen);
    const setOpenedCashRegisterPosBusinessDay = useAction(posReducer.actions.setOpenedCashRegisterPosBusinessDay);

    const hasOpenPersonalCashRegisterPermission = userHasRolePermission(RolePermissions.OPEN_PERSONAL_CASH_REGISTER);
    const hasManageCashRegisterPermission = userHasRolePermission(RolePermissions.MANAGE_CASH_REGISTERS);

    const openedCashRegisterPosBusinessDay = findUserCashRegisterPosBusinessDay();

    const handleApiError = (response: ApiErrorResponse) => {
        if (response.problem === 'NETWORK_ERROR') return setOpenedCashRegister(findPosCashRegisterOfflineInLocalStorage());
        return alertKnownErrorOrSomethingWentWrong(response);
    };

    const onFetchCashRegisterApiError = (response: ApiErrorResponse) => {
        if (response.problem === 'NETWORK_ERROR') return setOpenedCashRegister(findPosBusinessDaySummaryFromLocalStorage());
        return alertKnownErrorOrSomethingWentWrong(response);
    };

    const fetchOpenedCashRegister = async () => {
        if (loading) return;

        setLoading(true);
        let response;

        if (!posMultipleCashRegistersEnabled && restaurantId) {
            response = await findOpenedCashRegisterApi({ restaurantId });
            if (!response.ok) {
                handleApiError(response);
                setLoading(false);
                setCashRegisterOpen(false);
                return;
            }
            setPosCashRegisterOfflineInLocalStorage(response?.data);
            setOpenedCashRegister(response?.data);
            setCashRegisterOpen(!!response?.data);
        }

        if (posMultipleCashRegistersEnabled && !openedCashRegisterPosBusinessDay) {
            setCashRegisterOpen(false);
        }

        if (posMultipleCashRegistersEnabled && (hasOpenPersonalCashRegisterPermission || hasManageCashRegisterPermission) && openedCashRegisterPosBusinessDay) {
            setCashRegisterOpen(false);
            response = await findOpenedCashRegisterInCashRegisterPosBusinessDayApi({ cashRegisterPosBusinessDayId: openedCashRegisterPosBusinessDay?.cashRegisterPosBusinessDayId });

            if (!response.ok) {
                onFetchCashRegisterApiError(response);
                setLoading(false);
                setCashRegisterOpen(false);
                return;
            }

            if (response.data) {
                setPosCashRegisterOfflineInLocalStorage(response?.data);
                setOpenedCashRegister(response?.data);
                setCashRegisterOpen(true);
            }

            setOpenedCashRegisterPosBusinessDay(openedCashRegisterPosBusinessDay);
        }

        setLoading(false);
    };

    return { fetchOpenedCashRegister, loading };
}
