import { makeStyles } from '@material-ui/core';
import * as React from 'react';
import { Children, useContext, useState } from 'react';
import { PageContext } from 'src/components/Page';
import { SecuredContent } from 'src/components/SecuredContent';
import { UpdatingContentProgress } from 'src/components/UpdatingContentProgress';
import type { RolePermission } from 'src/constants/RolePermission';
import { createUserClickedTabLogEvent } from 'src/services/logEvent/createUserClickedTabLogEvent';
import { normalizeUiStackTrace } from 'src/services/logEvent/normalizeUiStackTrace';
import { classNames } from 'src/utils/react/classNames';
import { useSelector } from 'src/utils/react/useSelector';

export function Tabs({ tabs, loading, classes: classesProp, defaultValue, onChangeTab, tabsHeader, children }: Props): React.ReactElement | null {
    const classes = useStyles();
    const pageContext = useContext(PageContext);

    const [currentTab, setCurrentTab] = useState<string>(defaultValue ?? tabs[0].value);

    const userRolePermissions = useSelector((state) => state.authentication.userRolePermissions);
    const pinCodeUserRolePermissions = useSelector((state) => state.authentication.pinCodeUserSignedIn?.userRolePermissions);

    const currentUserRolePermissions = pinCodeUserRolePermissions || userRolePermissions;

    const handleChangeTab = (e: any, tab: any) => {
        const prevent = onChangeTab?.();
        if (prevent) return;
        setCurrentTab(tab);
    };

    const shouldHideTab = (tab: Tab) => {
        if (tab.hidden) return true;
        return !!currentUserRolePermissions && tab.rolePermission && !currentUserRolePermissions.includes(tab.rolePermission);
    };

    const existsTabForChildren = (index: number) => {
        return tabs[index];
    };

    const handleClickTab = (tab: Tab) => {
        createUserClickedTabLogEvent({ tabLabel: tab.label, pageContext });
        pageContext.clearStackTrace();
        setCurrentTab(tab.value);
        tab.onClick?.(tab.value);
    };

    const addTabToStackTrace = (tab: Tab) => {
        pageContext.addElementToStackTrace(normalizeUiStackTrace(`tab_${tab.label || ''}`));
    };

    const tabsToShow = tabs.filter((tab) => !shouldHideTab(tab));
    const childrenArray = Children.toArray(children);

    return (
        <div className={classNames(classes.container, classesProp?.container)}>
            <div className={classNames(classes.header, classesProp?.headerTabs)}>
                <div onChange={handleChangeTab as any} className={classNames(classes.tabs, classesProp?.tabs)} role='tablist'>
                    {tabsToShow.map((tab) => {
                        const isTabSelected = currentTab === tab.value;
                        return (
                            <button
                                key={tab.label}
                                className={classNames(classes.tab, classesProp?.tab, isTabSelected && classes.selectedTab, isTabSelected && classesProp?.selectedTab)}
                                onClick={() => handleClickTab(tab)}
                                role='tab'
                                aria-selected={isTabSelected}
                                aria-controls={`tabpanel-${tab.value}`}
                                id={`tab-${tab.value}`}
                            >
                                <span>{tab.label}</span>
                                {!!tab?.badge && (
                                    <span className={classNames(classes.badge, classesProp?.badge, isTabSelected && classes.selectedBadge, isTabSelected && classesProp?.selectedBadge)}>
                                        {tab?.badge}
                                    </span>
                                )}
                            </button>
                        );
                    })}
                </div>
                {loading && <UpdatingContentProgress loading={loading} bottom />}
            </div>
            {tabsHeader}
            {childrenArray.map((child, idx) => {
                const isTabSelected = currentTab === tabsToShow[idx]?.value;
                if (!existsTabForChildren(idx) || !isTabSelected) {
                    return null;
                }

                if (tabsToShow[idx].rolePermission) {
                    return (
                        <SecuredContent rolePermission={tabsToShow[idx].rolePermission!} key={tabsToShow[idx].value}>
                            <section
                                className={classNames(classes.tabContainer, classesProp?.tabContent)}
                                /* @ts-ignore */
                                value={tabsToShow[idx].value}
                                key={tabsToShow[idx].label}
                                role='tabpanel'
                                id={`tabpanel-${tabsToShow[idx].value}`}
                                aria-labelledby={`tab-${tabsToShow[idx].value}`}
                                onClickCapture={() => addTabToStackTrace(tabsToShow[idx])}
                            >
                                {child}
                            </section>
                        </SecuredContent>
                    );
                }

                return (
                    <section
                        className={classNames(classes.tabContainer, classesProp?.tabContent)}
                        /* @ts-ignore */
                        value={tabsToShow[idx].value}
                        key={tabsToShow[idx].value}
                        role='tabpanel'
                        id={`tabpanel-${tabsToShow[idx].value}`}
                        aria-labelledby={`tab-${tabsToShow[idx].value}`}
                        onClickCapture={() => addTabToStackTrace(tabsToShow[idx])}
                    >
                        {child}
                    </section>
                );
            })}
        </div>
    );
}

const useStyles = makeStyles((theme) => ({
    container: {
        width: '100%',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        [theme.breakpoints.down('sm')]: {
            padding: '0px 20px',
        },
    },
    header: {
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        position: 'relative',
    },
    tabs: {
        width: '100%',
        display: 'flex',
        flexDirection: 'row',
        listStyle: 'none',
        alignItems: 'flex-end',
        justifyContent: 'flex-start',
        padding: 0,
        margin: 0,
        overflowY: 'auto',
        [theme.breakpoints.down('sm')]: {
            overflowX: 'scroll',
            '&::-webkit-scrollbar': {
                display: 'none',
            },
        },
    },
    tab: {
        display: 'flex',
        width: '100%',
        alignItems: 'center',
        justifyContent: 'center',
        boxSizing: 'border-box',
        padding: '8px 24px',
        fontFamily: theme.typography.regular,
        fontSize: 14,
        color: theme.palette.text.primary,
        cursor: 'pointer',
        gap: 8,
        backgroundColor: 'transparent',
        border: 'none',
        transition: '300ms ease',
        borderBottom: '2px solid transparent',
        '&:hover': {
            backgroundColor: theme.palette.surface.secondary,
        },
    },
    selectedTab: {
        fontFamily: theme.typography.medium,
        textTransform: 'none',
        fontSize: 14,
        color: theme.palette.surface.brandContrast,
        borderBottom: `2px solid ${theme.palette.surface.brandContrast}`,
    },
    badge: {
        backgroundColor: theme.palette.surface.secondary,
        padding: '3px 13px',
        borderRadius: 10,
        fontFamily: theme.typography.medium,
        width: 44,
        fontSize: 12,
        textAlign: 'center',
        [theme.breakpoints.down('sm')]: {
            display: 'none',
        },
    },
    selectedBadge: {
        backgroundColor: theme.palette.surface.brand,
    },
    tabContainer: {
        paddingTop: 24,
    },
}));

type Props = {
    tabs: Array<Tab>;
    loading?: boolean;
    classes?: {
        container?: string;
        tab?: string;
        tabs?: string;
        tabContent?: string;
        selectedTab?: string;
        badge?: string;
        selectedBadge?: string;
        headerTabs?: string;
    };
    defaultValue?: string;
    tabsHeader?: React.ReactNode;
    children?: React.ReactNode;
    onChangeTab?: () => boolean | null | undefined;
};

export type Tab = {
    label: string;
    value: string;
    hidden?: boolean;
    badge?: React.ReactNode;
    rolePermission?: RolePermission;
    onClick?: any;
};
