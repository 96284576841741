import * as React from 'react';

export function CommandIcon({ color }: Props): React.ReactElement {
    return (
        <svg width='31' height='40' viewBox='0 0 31 40' fill='none' xmlns='http://www.w3.org/2000/svg'>
            <path
                d='M1.59375 0.172029C2.25781 -0.132659 3.03906 -0.0232839 3.59375 0.453279L6.75 3.1564L9.90625 0.453279C10.6094 -0.148284 11.6484 -0.148284 12.3438 0.453279L15.5 3.1564L18.6562 0.453279C19.3594 -0.148284 20.3984 -0.148284 21.0938 0.453279L24.25 3.1564L27.4062 0.453279C27.9609 -0.0232839 28.7422 -0.132659 29.4062 0.172029C30.0703 0.476716 30.5 1.14078 30.5 1.87515V38.1251C30.5 38.8595 30.0703 39.5236 29.4062 39.8283C28.7422 40.133 27.9609 40.0236 27.4062 39.547L24.25 36.8439L21.0938 39.547C20.3906 40.1486 19.3516 40.1486 18.6562 39.547L15.5 36.8439L12.3438 39.547C11.6406 40.1486 10.6016 40.1486 9.90625 39.547L6.75 36.8439L3.59375 39.547C3.03906 40.0236 2.25781 40.133 1.59375 39.8283C0.929688 39.5236 0.5 38.8595 0.5 38.1251V1.87515C0.5 1.14078 0.929688 0.476716 1.59375 0.172029ZM8 11.2502C7.3125 11.2502 6.75 11.8127 6.75 12.5002C6.75 13.1877 7.3125 13.7502 8 13.7502H23C23.6875 13.7502 24.25 13.1877 24.25 12.5002C24.25 11.8127 23.6875 11.2502 23 11.2502H8ZM6.75 27.5002C6.75 28.1877 7.3125 28.7502 8 28.7502H23C23.6875 28.7502 24.25 28.1877 24.25 27.5002C24.25 26.8127 23.6875 26.2502 23 26.2502H8C7.3125 26.2502 6.75 26.8127 6.75 27.5002ZM8 18.7502C7.3125 18.7502 6.75 19.3127 6.75 20.0002C6.75 20.6877 7.3125 21.2502 8 21.2502H23C23.6875 21.2502 24.25 20.6877 24.25 20.0002C24.25 19.3127 23.6875 18.7502 23 18.7502H8Z'
                fill={color || 'currentColor'}
            />
        </svg>
    );
}

type Props = {
    color: string;
};
