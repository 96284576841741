import { makeStyles } from '@material-ui/core';
import React from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import { DeliveryProvidersEnabledList } from 'src/components/DeliveryProvidersEnabledList';
import { FormSwitch } from 'src/components/form/FormSwitch';
import { DeliveryProvider } from 'src/constants/DeliveryProviders';
import { translate } from 'src/i18n/translate';

export function DeliveryManagerOrchestratorSettings({
    cashDeliveryProvidersEnabled,
    cardDeliveryProvidersEnabled,
    updateCashDeliveryProviders,
    updateCardDeliveryProviders,
}: Props): React.ReactElement {
    const classes = useStyles();

    const { control, watch } = useFormContext();

    const externalDelivery = useWatch<boolean>({ name: 'externalDelivery', control });
    const deliveryOrchestratorEnabled = watch('deliveryOrchestratorEnabled');
    const deliveryProvidersInfo = watch('deliveryProvidersInfo');

    return (
        <>
            <FormSwitch name='deliveryOrchestratorEnabled' label={translate('Activate Delivery Orchestrator')} disabled={externalDelivery || !deliveryProvidersInfo} />
            <div className={classes.contentItem}>
                {deliveryOrchestratorEnabled && deliveryProvidersInfo && (
                    <DeliveryProvidersEnabledList
                        deliveryProvidersInfo={deliveryProvidersInfo}
                        cashDeliveryProvidersEnabled={cashDeliveryProvidersEnabled ?? []}
                        cardDeliveryProvidersEnabled={cardDeliveryProvidersEnabled ?? []}
                        updateCashDeliveryProviders={updateCashDeliveryProviders}
                        updateCardDeliveryProviders={updateCardDeliveryProviders}
                    />
                )}
            </div>
        </>
    );
}

const useStyles = makeStyles((theme) => ({
    contentItem: {
        display: 'flex',
        width: '100%',
        alignItems: 'center',
        marginTop: 6,
        justifyContent: 'space-between',
        gap: 20,
        fontFamily: theme.typography.regular,
    },
}));

type Props = {
    cashDeliveryProvidersEnabled?: Array<DeliveryProvider>;
    cardDeliveryProvidersEnabled?: Array<DeliveryProvider>;
    updateCashDeliveryProviders: (deliveryProvider: DeliveryProvider) => void;
    updateCardDeliveryProviders: (deliveryProvider: DeliveryProvider) => void;
};
