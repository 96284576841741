import { makeStyles, useTheme } from '@material-ui/core';
import * as React from 'react';
import { Button } from 'src/components/Button';
import { SubscribedPage } from 'src/components/page/SubscribedPage';
import { translate } from 'src/i18n/translate';
import { LightingIcon } from 'src/icons/LightingIcon';
import { NotesIcon } from 'src/icons/NotesIcon';
import { PrinterIcon } from 'src/icons/PrinterIcon';
import { StoreIcon } from 'src/icons/StoreIcon';
import ambit from 'src/images/ambit-logo.png';
import posEmptyStateImage from 'src/images/posEmptyStateImage.png';
import { useSelector } from 'src/utils/react/useSelector';

export function PosEmptyState(): React.ReactElement {
    const classes = useStyles();
    const theme = useTheme();
    const restaurantCountry = useSelector((state) => state.app.restaurant.country);

    return (
        <SubscribedPage title={translate('Become the owner of your operation')}>
            <div className={classes.container}>
                <div className={classes.infoContainer}>
                    <div className={classes.title}>
                        <img src={ambit} alt='Ambit logo' className={classes.logo} />
                        {translate('Become the owner of your operation')}
                    </div>
                    <Button classes={{ button: classes.button }}>{translate('Start trial')}</Button>
                    <ul className={classes.benefitsList}>
                        <li className={classes.row}>
                            <NotesIcon color={theme.palette.icons.brand} />
                            <span className={classes.text} style={{ marginLeft: 10 }}>
                                {translate('Take orders')}
                            </span>
                        </li>
                        <li className={classes.row}>
                            <StoreIcon color={theme.palette.icons.brand} />
                            <span className={classes.text} style={{ marginLeft: 10 }}>
                                {translate('Control of all your sales')}
                            </span>
                        </li>
                        <li className={classes.row}>
                            <LightingIcon color={theme.palette.icons.brand} />
                            <span className={classes.text} style={{ marginLeft: 10 }}>
                                {translate('Streamline your operation')}
                            </span>
                        </li>
                        <li className={classes.row}>
                            <PrinterIcon color={theme.palette.icons.brand} />
                            <span className={classes.text} style={{ marginLeft: 10 }}>
                                {translate('Print tickets and orders')}
                            </span>
                        </li>
                    </ul>
                </div>
                <div className={classes.imageContainer}>
                    <img src={posEmptyStateImage} className={classes.image} alt='AmbitOne POS' />
                </div>
            </div>
        </SubscribedPage>
    );
}

const useStyles = makeStyles((theme) => ({
    container: {
        width: '100%',
        height: '100vh',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
    },
    infoContainer: {
        display: 'flex',
        flexDirection: 'column',
    },
    title: {
        fontFamily: theme.typography.medium,
        fontSize: 30,
        margin: 0,
        marginBottom: 30,
        display: 'flex',
        alignItems: 'center',
        gap: 12,
    },
    logo: {
        width: 40,
    },
    row: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        marginBottom: 30,
    },
    text: {
        fontFamily: theme.typography.regular,
        fontSize: 18,
        margin: 0,
    },
    textBold: {
        fontFamily: theme.typography.bold,
        fontSize: 30,
    },
    button: {
        fontFamily: theme.typography.semiBold,
        fontSize: 16,
        borderRadius: 8,
        height: 60,
        width: '100%',
        maxWidth: '60%',
    },
    benefitsList: {
        listStyleType: 'none',
        marginTop: 30,
        padding: 0,
    },
    icon: {
        marginRight: 10,
    },
    image: {
        flexShrink: 0,
        height: '100%',
        marginBottom: 50,
        objectFit: 'cover',
        minWidth: 350,
    },
    imageContainer: {
        display: 'flex',
        flexDirection: 'column',
        maxWidth: '40%',
    },
}));
