import { Grid, makeStyles } from '@material-ui/core';
import { BigNumber } from 'bignumber.js';
import { useEffect, useState } from 'react';
import * as React from 'react';
import type { SalesByMenuCategory } from 'src/api/letseatmanager/restaurantDashboard/getGeneralRestaurantStatisticsApi';
import { PieChartCanvas } from 'src/components/charts/PieChartCanvas';
import { translate } from 'src/i18n/translate';
import { KpiCard } from 'src/scenes/letseatmanager/restaurantDashboard/KpiCard';
import { ReportSection } from 'src/scenes/letseatmanager/restaurantDashboard/ReportSection';
import { useFormatAsRestaurantCurrencyNumber } from 'src/services/restaurant/useFormatAsRestaurantCurrencyNumber';

export function CategoryGeneralGraph({ colors, loading, categoriesData }: Props): React.ReactElement {
    const classes = useStyles();
    const formatAsCurrencyNumber = useFormatAsRestaurantCurrencyNumber();

    const [salesByMenuCategory, setSalesByMenuCategory] = useState<Array<PieData>>([]);
    const [totalCategoryTypeSale, setTotalCategoryTypeSale] = useState(0);

    useEffect(() => {
        getCategoryItemFromReports(categoriesData);
    }, [categoriesData]);

    const getCategoryItemFromReports = (salesByMenuCategory?: Array<SalesByMenuCategory>) => {
        if (!salesByMenuCategory) {
            setSalesByMenuCategory([]);
            setTotalCategoryTypeSale(0);
            return;
        }
        const totalSales = salesByMenuCategory.reduce((acc, current) => acc + current.total, 0);
        const typeOrderItems = getCategoryItemReportData(salesByMenuCategory);
        const totalCategoryTypeSaleSorted = typeOrderItems.sort((itemA, itemB) => itemB.value - itemA.value);

        setSalesByMenuCategory(totalCategoryTypeSaleSorted);
        setTotalCategoryTypeSale(BigNumber(totalSales).toNumber());
    };

    const getCategoryItemReportData = (orderTypesData: Array<SalesByMenuCategory>): Array<PieData> => {
        const typeOrderItems: Array<PieData> = [];
        orderTypesData.forEach((typeOrderItem) => {
            const isOrderItem = typeOrderItems.find((elem) => elem.id === typeOrderItem.categoryType);
            if (!isOrderItem) {
                typeOrderItems.push({
                    id: translate(typeOrderItem.categoryType),
                    label: translate(typeOrderItem.categoryType),
                    value: typeOrderItem.total,
                });
                return;
            }
            isOrderItem.value = isOrderItem.value + typeOrderItem.total;
        });

        return typeOrderItems;
    };

    return (
        <ReportSection title={translate('Categories')} loading={loading} total={formatAsCurrencyNumber(totalCategoryTypeSale)}>
            <Grid container className={(classes as any).infoContainer}>
                <Grid item md={9} className={classes.graphContainer}>
                    <PieChartCanvas data={salesByMenuCategory} valueFormat={(value) => formatAsCurrencyNumber(value)} />
                </Grid>
                <Grid item md={3} className={classes.kpisContainer}>
                    {salesByMenuCategory?.map((itemType: PieData, index: number) => (
                        <KpiCard key={`${itemType.id}-${index}`} legend={formatAsCurrencyNumber(itemType.value)} title={translate(itemType.id)} color={colors[index]} />
                    ))}
                </Grid>
            </Grid>
        </ReportSection>
    );
}

const useStyles = makeStyles((theme) => ({
    graphContainer: {
        width: '100%',
        height: '45vh',
    },
    kpisContainer: {
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap',
        width: '95%',
        height: 'auto',
        justifyContent: 'space-around',
        gap: 12,
        [theme.breakpoints.between('sm', 'md')]: {
            width: '100%',
            justifyContent: 'center',
        },
        [theme.breakpoints.up('md')]: {
            flexDirection: 'column',
            justifyContent: 'center',
            flexWrap: 'nowrap',
        },
    },
}));

type Props = {
    loading: boolean;
    colors: Array<string>;
    categoriesData: Array<SalesByMenuCategory>;
};

type PieData = {
    id: string;
    label: string;
    value: number;
};
