import { Chip, Grid, makeStyles } from '@material-ui/core';
import { OutlinedPlusIcon } from '@pidedirecto/ui/icons';
import * as React from 'react';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { createModifierGroupApi } from 'src/api/letseatmanager/modifierGroup/createModifierGroupApi';
import { Button } from 'src/components/Button';
import { Form } from 'src/components/form/Form';
import { FormCheckbox } from 'src/components/form/FormCheckbox';
import { FormNumberField } from 'src/components/form/FormNumberField';
import { FormSwitch } from 'src/components/form/FormSwitch';
import { FormTextField } from 'src/components/form/FormTextField';
import { translate } from 'src/i18n/translate';
import { SelectModifiersDialog } from 'src/scenes/letseatmanager/menu/modifierGroup/SelectModifiersDialog';
import { MenuItemId } from 'src/types/Id';
import { ModifierVm } from 'src/types/ModifierVm';
import { alertKnownErrorOrSomethingWentWrong } from 'src/utils/alert/alertKnownErrorOrSomethingWentWrong';
import { useSelector } from 'src/utils/react/useSelector';

export function CreateModifierGroup({ onClose, onSuccess }: Props): React.ReactElement {
    const form = useForm();
    const classes = useStyles();

    const [selectModifiersDialogState, setSelectModifiersDialogState] = useState({ open: false });
    const [selectedModifiers, setSelectedModifiers] = useState<Array<ModifierVm>>([]);
    const [loading, setLoading] = useState(false);

    const restaurantId = useSelector((state) => state.app.restaurantId);

    const hasSelectedModifiers = selectedModifiers.length > 0;

    const openSelectModifiersDialog = () => setSelectModifiersDialogState({ open: true });

    const closeSelectModifiersDialog = () => setSelectModifiersDialogState({ open: false });

    const handleClose = () => {
        if (loading) return;
        onClose?.();
    };

    const onSubmit = async (form: any) => {
        setLoading(true);
        const response = await createModifierGroupApi({
            restaurantId,
            name: form.name,
            externalModifierGroupId: form.externalModifierGroupId,
            requiredMin: form.requiredMin,
            requiredMax: form.requiredMax,
            freeModifiersQuantity: form.freeModifiersQuantity,
            showModifierGroupNameInCommand: form.showModifierGroupNameInCommand,
            hidden: form.hidden,
            modifiers: selectedModifiers,
        });
        setLoading(false);
        if (!response.ok) return alertKnownErrorOrSomethingWentWrong(response);
        onSuccess?.();
        handleClose();
    };

    const handleSelectModifiers = (modifiers: any) => {
        setSelectedModifiers(modifiers);
    };

    const handleDeleteModifier = (menuItemId: MenuItemId) => {
        const newModifiers = selectedModifiers.filter((modifier) => modifier.menuItemId !== menuItemId);
        setSelectedModifiers(newModifiers);
    };

    return (
        <>
            <SelectModifiersDialog open={selectModifiersDialogState.open} defaultSelectedModifiers={selectedModifiers} onClose={closeSelectModifiersDialog} onSuccess={handleSelectModifiers} />
            <Form form={form} onSubmit={onSubmit}>
                <h2 className={classes.title}>{translate('Create modifier group')}</h2>
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <FormTextField name='name' label={translate('Name')} required />
                    </Grid>
                    <Grid item xs={12}>
                        <FormTextField name='externalModifierGroupId' label={translate('External modifier group id')} />
                    </Grid>
                    <Grid item xs={12}>
                        <FormNumberField name='freeModifiersQuantity' label={translate('Free modifiers quantity')} disabled={loading} />
                    </Grid>
                    <Grid item xs={12}>
                        <div className={classes.requiredInputsContainer}>
                            <FormNumberField name='requiredMin' label={translate('Minimum required')} />
                            <FormNumberField name='requiredMax' label={translate('Maximum required')} />
                        </div>
                        <span className={classes.helperText}>{translate('For integrations, add maximum required if the modifiers in this group are of type multiple')}</span>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <FormSwitch name='hidden' label={translate('Hidden')} />
                    </Grid>
                    <Grid item xs={12}>
                        <p className={classes.text}>{translate('Select your modifiers')}</p>
                        {hasSelectedModifiers && (
                            <div className={classes.modifiersContainer}>
                                <div className={classes.chipsContainer}>
                                    {selectedModifiers.map((selectedModifier) => {
                                        return (
                                            <Chip
                                                key={selectedModifier.menuItemId}
                                                label={selectedModifier?.name}
                                                classes={{ root: classes.text }}
                                                onDelete={() => handleDeleteModifier(selectedModifier.menuItemId)}
                                            />
                                        );
                                    })}
                                </div>
                                <Button icon onClick={openSelectModifiersDialog}>
                                    <OutlinedPlusIcon title={'plus icon'} />
                                </Button>
                            </div>
                        )}
                        {!hasSelectedModifiers && (
                            <Button outlined onClick={openSelectModifiersDialog}>
                                {translate('Add modifiers')}
                            </Button>
                        )}
                    </Grid>
                    <Grid item xs={12}>
                        <FormCheckbox name={'showModifierGroupNameInCommand'} label={translate('Print modifier group name on command')} />
                    </Grid>
                </Grid>
                <div className={classes.buttonsContainer}>
                    {onClose && (
                        <Button secondary onClick={handleClose}>
                            {translate('Cancel')}
                        </Button>
                    )}
                    <Button type='submit' disabled={loading}>
                        {loading ? translate('Creating') : translate('Create')}
                    </Button>
                </div>
            </Form>
        </>
    );
}

const useStyles = makeStyles((theme) => ({
    title: {
        color: theme.palette.text.brand,
        textAlign: 'center',
        fontFamily: theme.typography.bold,
    },
    text: {
        fontFamily: theme.typography.regular,
        fontSize: 15,
        margin: 0,
    },
    chipsContainer: {
        display: 'flex',
        flexWrap: 'wrap',
        flexDirection: 'row',
        gap: 5,
    },
    modifiersContainer: {
        margin: '10px 0',
        display: 'flex',
        width: '100%',
        flexDirection: 'row',
        justifyContent: 'space-between',
    },
    buttonsContainer: {
        marginTop: 30,
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'flex-end',
        gap: 20,
        '& button': {
            width: 200,
        },
    },
    requiredInputsContainer: {
        width: '100%',
        display: 'grid',
        gridTemplateColumns: '1fr 1fr',
        gap: 20,
        [theme.breakpoints.down('md')]: {
            gridTemplateColumns: '1fr',
        },
    },
    helperText: {
        fontFamily: theme.typography.light,
        color: theme.palette.text.secondary,
        fontSize: '1.2rem',
    },
}));

type Props = {
    onClose?: any;
    onSuccess?: any;
};
