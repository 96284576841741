import { Button } from '@pidedirecto/ui';
import { PrinterIcon } from '@pidedirecto/ui/icons';
import * as React from 'react';
import { useState } from 'react';
import { Tooltip } from 'src/components/Tooltip';
import { translate } from 'src/i18n/translate';
import { SelectManagerUserReportDialog } from 'src/scenes/letseatmanager/posReports/managerUserSalesReport/SelectManagerUserReportDialog';

export function PrintManagerUserTipReportToolbarButton(): React.ReactElement {
    const [selectManagerUserDialogState, setSelectManagerUserDialogState] = useState({ open: false });

    const handlePrintManagerUserTipReport = () => {
        setSelectManagerUserDialogState({ open: true });
    };

    const closeSelectManagerUserDialog = () => setSelectManagerUserDialogState({ open: false });

    return (
        <>
            <SelectManagerUserReportDialog open={selectManagerUserDialogState.open} onClose={closeSelectManagerUserDialog} />
            <Button variant={'icon'} onClick={handlePrintManagerUserTipReport}>
                <Tooltip text={translate('Print report')}>
                    <PrinterIcon title={translate('Print report')} />
                </Tooltip>
            </Button>
        </>
    );
}
