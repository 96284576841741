import { Marker } from '@react-google-maps/api';
import * as React from 'react';
import RestaurantMarker from 'src/images/RestaurantMarker.svg';
import type { OrderVm } from 'src/types/OrderVm';
import { toLatLng } from 'src/utils/googlemaps/toLatLng';
import { getRestaurantLocations } from 'src/utils/order/getRestaurantLocations';
import { isDeliveryOrder } from 'src/utils/order/isDeliveryOrder';
import { useSelector } from 'src/utils/react/useSelector';

export function DeliveryOrdersRestaurantMarkers(): React.ReactElement {
    const currentOrders = useSelector((state) => state.app2.currentOrders);

    const deliveryOrders: Array<OrderVm> = currentOrders.filter((order) => isDeliveryOrder(order.orderType) && order.address);
    const restaurantLocations = getRestaurantLocations(deliveryOrders);

    return (
        <>
            {restaurantLocations.map((restaurantLocation, idx) => (
                <Marker key={idx} icon={RestaurantMarker} position={toLatLng(restaurantLocation)} />
            ))}
        </>
    );
}
