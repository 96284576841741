import { letseatadminApiMethod } from 'src/api/letseatadminApi';
import type { ApiSauceResponse } from 'src/api/types/ApiSauceResponse';
import type { DriverId } from 'src/types/Id';

export async function tribalExportDriversBillingApi(request: ExportDriversBillingApiRequest): ApiSauceResponse<ExportDriversBillingResultVm> {
    return letseatadminApiMethod('driverBilling/tribalExportDriversBillingApi', request);
}

type ExportDriversBillingApiRequest = {
    from: string;
    to: string;
    paymentDate: string;
    driverIds: Array<DriverId>;
};

export type ExportDriversBillingResultVm = {
    tribalExportFormat: string;
    exported: number;
    banned: number;
    removed: number;
    accountNotExported: number;
    nothingToTransfer: number;
};
