import { ScreenSizes } from '@pidedirecto/ui/constants';
import { useIsScreenSize } from '@pidedirecto/ui/hooks';
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { RoutePaths } from 'src/constants/RoutePath';
import { SECONDS } from 'src/constants/TimeUnit';
import { envZENDESK_ENABLED } from 'src/env/envZENDESK_ENABLED';
import { useSelector } from 'src/utils/react/useSelector';
import { wait } from 'src/utils/wait';

export function useZendeskChat() {
    const isExtraSmallScreen = useIsScreenSize(ScreenSizes.EXTRA_SMALL_SCREEN);
    const location = useLocation();

    const kioskUser = useSelector((state) => state.authentication.kioskUser);
    const internalUser = useSelector((state) => state.authentication.internalUser);
    const token = useSelector((state) => state.app2.zendeskJwt);

    const isPosTableRoute = location.pathname === RoutePaths.POS_TABLES || location.pathname === RoutePaths.POS;
    const hideZendeskChat = internalUser || kioskUser || (isExtraSmallScreen && isPosTableRoute);
    const body = document.getElementsByTagName('body');

    useEffect(() => {
        if (!envZENDESK_ENABLED()) return;
        addZendeskChat();
        return () => {
            hideWidget();
        };
    }, [hideZendeskChat]);

    const addZendeskChat = async () => {
        if (hideZendeskChat) return;
        if (document.getElementById('ze-snippet')) {
            await displayWidget();
        } else {
            addWidgetScript();
            await validateUserInZendesk();
        }
    };

    const displayWidget = async () => {
        const widgetDivToShow = body[0].lastChild as HTMLElement | null;
        if (!widgetDivToShow) return;
        widgetDivToShow.style.display = 'inherit';
        await validateUserInZendesk();
    };

    const addWidgetScript = () => {
        const rootDiv = document.getElementById('root');
        const script = document.createElement('script');
        script.setAttribute('id', 'ze-snippet');
        script.setAttribute('src', 'https://static.zdassets.com/ekr/snippet.js?key=28df33ba-4dce-4481-ab2b-6506c3e1c576');
        rootDiv?.insertAdjacentElement('afterend', script);
    };

    const hideWidget = () => {
        const widgetDivToRemove = body[0].lastChild as HTMLElement | null;
        if (!widgetDivToRemove) return;
        widgetDivToRemove.style.display = 'none';
        window.zE?.('messenger', 'logoutUser');
    };

    const validateUserInZendesk = async () => {
        if (!token) return;
        await wait(4 * SECONDS);
        window.zE?.('messenger', 'loginUser', function (callback: any) {
            callback(token);
        });
    };
}
