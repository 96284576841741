import { makeStyles } from '@material-ui/core';
import * as React from 'react';
import { OrderItem } from 'src/components/orderCard/orderItems/OrderItem';
import type { OrderItemVm } from 'src/types/OrderVm';

export function OrderItems({ orderItems, showDetails }: Props): any {
    const classes = useStyles();

    if (!orderItems || orderItems.length === 0) return null;

    return (
        <div className={classes.groupContainer}>
            {orderItems.map((orderItem, idx) => (
                <OrderItem key={idx} showDetails={showDetails} orderItem={orderItem} />
            ))}
        </div>
    );
}

type Props = {
    orderItems?: Array<OrderItemVm>;
    showDetails?: boolean;
};

const useStyles = makeStyles((theme) => ({
    groupContainer: {
        backgroundColor: '#F1F6F9',
        borderRadius: 8,
        padding: 12,
    },
}));
