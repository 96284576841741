import { createContext, useEffect } from 'react';
import * as React from 'react';
import { ConfirmDialog } from 'src/components/dialog/ConfirmDialog';
import { SECONDS } from 'src/constants/TimeUnit';

export const BOTTOM_DIALOG_TIMEOUT = 275;

export function ConfirmDialogProvider({ children }: any): React.ReactElement {
    const [confirmDialogState, setConfirmDialogState] = React.useState<ConfirmDialogOptions | undefined>();

    const awaitingPromiseRef = React.useRef<
        | {
              resolve: (result: Promise<boolean | undefined> | boolean | undefined) => void;
          }
        | undefined
    >();
    let timeout: NodeJS.Timeout | null = null;

    useEffect(() => {
        if (Boolean(confirmDialogState) && confirmDialogState?.timeoutSeconds) {
            if (timeout) clearTimeout(timeout);
            timeout = setTimeout(
                () => {
                    timeout = null;
                    handleClose();
                },
                (confirmDialogState?.timeoutSeconds || 5) * SECONDS,
            );
        }
    }, [confirmDialogState]);

    const openConfirmDialog = (options: ConfirmDialogOptions) => {
        setConfirmDialogState(options);
        return new Promise<boolean | undefined>((resolve: (result: Promise<boolean | undefined> | boolean | undefined) => void, reject: (error?: any) => void) => {
            awaitingPromiseRef.current = { resolve };
        });
    };

    const handleAccept = () => {
        awaitingPromiseRef.current?.resolve(true);
        setTimeout(() => {
            setConfirmDialogState(undefined);
        }, BOTTOM_DIALOG_TIMEOUT);
    };

    const handleClose = () => {
        awaitingPromiseRef.current?.resolve(false);
        setTimeout(() => {
            setConfirmDialogState(undefined);
        }, BOTTOM_DIALOG_TIMEOUT);
    };

    return (
        <ConfirmDialogContext.Provider value={{ confirmDialog: openConfirmDialog }}>
            {children}
            {confirmDialogState && (
                <ConfirmDialog
                    open={Boolean(confirmDialogState)}
                    title={confirmDialogState?.title}
                    content={confirmDialogState?.content}
                    buttonText={confirmDialogState?.buttonText}
                    cancelButtonText={confirmDialogState?.cancelButtonText}
                    variant={confirmDialogState?.variant}
                    onAccept={handleAccept}
                    onClose={handleClose}
                />
            )}
        </ConfirmDialogContext.Provider>
    );
}
export const ConfirmDialogContext = createContext<{ confirmDialog: (options: ConfirmDialogOptions) => Promise<boolean | void> | void }>({ confirmDialog: () => {} });

export type ConfirmDialogOptions = {
    title?: string;
    content?: string;
    buttonText?: string;
    cancelButtonText?: string;
    timeoutSeconds?: number;
    variant?: 'warning' | 'error' | 'success' | 'notification';
};
