export const RestaurantPaymentMethodApps = {
    PIDEDIRECTO: 'PIDEDIRECTO',
    PIDEDIRECTOAPP: 'PIDEDIRECTOAPP',
    PIDEDIRECTOPOS: 'PIDEDIRECTOPOS',
    PIDEDIRECTOKIOSK: 'PIDEDIRECTOKIOSK',
    PIDEDIRECTOADMIN: 'PIDEDIRECTOADMIN',
    LETSEATAPP: 'LETSEATAPP',
} as const;

export type RestaurantPaymentMethodApp = (typeof RestaurantPaymentMethodApps)[keyof typeof RestaurantPaymentMethodApps];
