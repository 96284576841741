import { makeStyles, Typography } from '@material-ui/core';
import * as React from 'react';
import { OrderModifier } from 'src/components/orderCard/orderItems/OrderModifier';
import { ProductCancellationReasons } from 'src/constants/ProductCancellationReason';
import { translate } from 'src/i18n/translate';
import { PinIcon } from 'src/icons/PinIcon';
import { useFormatAsRestaurantCurrencyNumber } from 'src/services/restaurant/useFormatAsRestaurantCurrencyNumber';
import { ModifierGroupVm, ModifierVm, type OrderItemVm } from 'src/types/OrderVm';
import { formatDayTimeString } from 'src/utils/date/formatDayTimeString';

export function OrderItem({ orderItem, showDetails }: Props): React.ReactElement {
    const classes = useStyle();
    const formatAsCurrencyNumber = useFormatAsRestaurantCurrencyNumber();

    const quantity = !!orderItem.salesUnit ? `${orderItem.quantity}${translate(`UnitsOfMeasurement.${orderItem.salesUnit}`)}` : orderItem.quantity.toString();

    const getCancellationReasonProductText = (reason: string) => {
        const isDefaultReason = reason in ProductCancellationReasons;
        return isDefaultReason ? translate(`ProductCancellationReasons.${reason}`) : reason;
    };

    const allModifiersHasQuantityEqualToOne = orderItem.modifierGroups?.flatMap((modifierGroup: ModifierGroupVm) => modifierGroup.modifiers).every((modifier: ModifierVm) => modifier.quantity === 1);

    const getQuantityLabel = () => {
        if (orderItem.currency) return `${orderItem.quantity}${orderItem.currency}`;
        return !!orderItem.salesUnit ? `${orderItem.quantity}${translate(`UnitsOfMeasurement.${orderItem.salesUnit}`)}` : orderItem.quantity.toString();
    };

    return (
        <div className={(classes as any).menuItemRow} aria-label={`orderItem-${orderItem.menuItemId}`} role='presentation'>
            <div className={classes.nameContainer}>
                <div>
                    <div className={classes.priceAndQuantity}>
                        <span className={classes.quantity}>{getQuantityLabel()}x</span>
                        <span className={classes.name}>{orderItem.name}</span>
                        {orderItem.posDiscount && <span className={(classes as any).discount}>-({formatAsCurrencyNumber(orderItem.posDiscount)})</span>}
                    </div>
                    {showDetails && (orderItem.pickupTime || orderItem.kitchenId) ? `[${formatDayTimeString(orderItem.pickupTime)} ${orderItem.kitchenName ?? ''}]` : ''}
                </div>
            </div>
            <div>
                {orderItem.modifierGroups?.map((modifierGroup: ModifierGroupVm) => (
                    <div className={(classes as any).modifierGroups}>
                        {modifierGroup.showModifierGroupNameInCommand && <div className={classes.modifierGroupName}>{modifierGroup.name}</div>}
                        {modifierGroup.modifiers?.map((modifier: ModifierVm) => (
                            <OrderModifier modifier={modifier} showModifierGroupNameInCommand={modifierGroup.showModifierGroupNameInCommand} key={modifier.name} />
                        ))}
                    </div>
                ))}
            </div>
            {orderItem.note && (
                <div className={classes.note}>
                    <PinIcon />
                    <div>{orderItem.note}</div>
                </div>
            )}
            {orderItem?.cancellationReason && orderItem?.removedAt && (
                <div className={classes.nameContainer}>
                    <Typography className={classes.name}>
                        <span>{translate('Reason: @reason', { reason: getCancellationReasonProductText(orderItem.cancellationReason) })}</span>
                    </Typography>
                </div>
            )}
        </div>
    );
}

const useStyle = makeStyles((theme) => ({
    topSpacing: {
        paddingTop: theme.spacing(2),
    },
    nameContainer: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        marginBottom: 4,
    },
    note: {
        fontFamily: theme.typography.regular,
        color: '#313333',
        fontSize: 12,
        display: 'flex',
        gap: 8,
        alignItems: 'center',
        marginLeft: 24,
    },
    modifier: {
        fontFamily: theme.typography.regular,
        fontStyle: 'italic',
        paddingLeft: 31,
        fontSize: 14,
    },
    positionAbsoluteLeft: {
        position: 'absolute',
        left: 0,
    },
    positionAbsoluteRight: {
        position: 'absolute',
        right: 0,
    },
    priceAndQuantity: {
        display: 'flex',
        fontFamily: theme.typography.regular,
        fontSize: 14,
        gap: 8,
        margin: 0,
    },
    quantity: {
        fontFamily: theme.typography.bold,
        margin: 0,
    },
    itemRow: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
    },
    name: {
        margin: 0,
    },
    modifierGroupName: {
        fontFamily: theme.typography.semiBold,
        fontSize: 14,
        margin: '5px 0',
    },
}));

type Props = {
    orderItem: OrderItemVm;
    showDetails?: boolean;
};
