import { letseatmanagerApiMethod } from 'src/api/letseatmanagerApi';
import type { ApiSauceResponse } from 'src/api/types/ApiSauceResponse';
import type { CustomDiscountComment } from 'src/constants/CustomDiscountComment';
import type { DiscountType } from 'src/constants/DiscountType';
import type { KitchenStatus } from 'src/constants/KitchenStatus';
import type { OrderStatus } from 'src/constants/OrderStatus';
import type { OrderType } from 'src/constants/OrderType';
import type { PaymentMethod } from 'src/constants/PaymentMethod';
import type { PickupTimeType } from 'src/constants/PickupTimeType';
import type { TipType } from 'src/constants/TipType';
import type { CourseOrderDetailsVm } from 'src/types/CourseOrderDetailsVm';
import type { CustomerOrderDetailsVm } from 'src/types/CustomerOrderDetailsVm';
import type { GeoJsonPoint } from 'src/types/GeoJsonPoint';
import { PromotionId, type CustomerId, type DeliveryEstimateId, type GooglePlaceId, type OrderId, type PromoCodeId, type RestaurantId } from 'src/types/Id';
import type { OrderPaymentVm } from 'src/types/OrderPaymentVm';
import type { OrderItemVm, OrderVm } from 'src/types/OrderVm';
import type { PosPaymentVm } from 'src/types/PosPaymentVm';
import type { PosTipVm } from 'src/types/PosTipVm';

export async function updatePendingOrderApi(request: UpdatePendingOrderApiRequest): ApiSauceResponse<OrderVm> {
    return letseatmanagerApiMethod('pos/updatePendingOrderApi', request);
}

export type UpdatePendingOrderApiRequest = {
    orderId: OrderId;
    customerId?: CustomerId;
    promoCodeId?: PromoCodeId;
    restaurantId: RestaurantId;
    orderType: OrderType;
    orderStatus: OrderStatus;
    kitchenStatus: KitchenStatus;
    promotionIds?: Array<PromotionId>;
    firstName?: string;
    lastName?: string;
    mobileNumber?: string;
    email?: string;
    customerNote?: string;
    address?: {
        location: GeoJsonPoint;
        street: string;
        number: string;
        neighborhood: string;
        postalCode?: string;
        other?: string;
        instructions?: string;
        googlePlaceId: GooglePlaceId;
        formattedAddress?: string;
        name?: string; // sent for debugging reasons
    };
    notes?: string;
    numberOfCustomers?: number;
    deliveryEstimateId?: DeliveryEstimateId;
    paymentMethod: PaymentMethod;
    posPayments: Array<PosPaymentVm>;
    payments: Array<OrderPaymentVm>;
    partiallyPaidByCash?: boolean;
    partiallyPaidByCashAmount?: string;
    pickupTimeType?: PickupTimeType;
    orderItems: Array<OrderItemVm>;
    cancelledItems: Array<OrderItemVm>;
    posDiscountPercentage?: string;
    posDiscount?: {
        discountType: DiscountType;
        discount: string;
        discountPercentage?: string;
        notes?: string | CustomDiscountComment;
    };
    posTip?: {
        tip: string;
        tipType: TipType;
    };
    tips?: Array<PosTipVm>;
    useLetsEatCredits?: boolean;
    usedCustomerCredits?: string;
    customers?: Array<CustomerOrderDetailsVm>;
    courses?: Array<CourseOrderDetailsVm>;
    pickupTime?: Date;
    modifiedAt?: Date;
};
