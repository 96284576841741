import { pidedirectoReportsMethod } from 'src/api/pidedirectoReportsApi';
import type { ApiSauceResponse } from 'src/api/types/ApiSauceResponse';
import type { SalesType } from 'src/constants/SalesTypes';
import type { OrderId, RestaurantId } from 'src/types/Id';

export async function getPromoCodeRestaurantStatisticsApi(request: GetPromoCodeRestaurantStatisticsRequest): ApiSauceResponse<GetPromoCodeStatisticsApiResponse> {
    return pidedirectoReportsMethod('/promoCodePageReportApi', request);
}

export type GetPromoCodeRestaurantStatisticsRequest = {
    report: string;
    reportPage?: string;
    abcTableFilter?: string;
    restaurantIds: Array<RestaurantId>;
    fromDate: string;
    toDate: string;
    salesType: SalesType;
};

export type GetPromoCodeStatisticsApiResponse = Array<{
    name: string;
    restaurantId: RestaurantId;
    salesByPromoCode?: Array<SalesByPromoCode>;
    discountBreakdownReport?: Array<DiscountBreakdownReport>;
    salesByPromoCodeByDay?: Array<SalesByPromoCodeByDay>;
}>;

export type SalesByPromoCode = {
    amount: number;
    discount: number;
    orderVolume: number;
    promoCode: string;
    promoCodeDiscount: number;
    total: number;
};

export type SalesByPromoCodeByDay = {
    amount: number;
    createdAt: string;
    customerName: string;
    discount: number;
    mobileNumber: string;
    promoCode: string;
    total: number;
    restaurantName: string;
    orderId: OrderId;
};

export type DiscountBreakdownReport = {
    orderId: string;
    date: string;
    clientName: string;
    subtotal: string;
    discount: string;
    subtotalAfterDiscount: string;
    total: string;
    reason: string;
    discountNote: string;
};
