import { makeStyles } from '@material-ui/core/styles';
import * as React from 'react';
import { useEffect } from 'react';
import { translate } from 'src/i18n/translate';
import { appReducer } from 'src/reducers/appReducer';
import { isProductionEnvironment } from 'src/utils/environment/isProductionEnvironment';
import { classNames } from 'src/utils/react/classNames';
import { useAction } from 'src/utils/react/useAction';
import { useSelector } from 'src/utils/react/useSelector';
import { isMobileApp } from 'src/utils/reactNative/isMobileApp';
import { setSetDeveloperModeInMobileApp } from 'src/utils/reactNative/setSetDeveloperModeInMobileApp';

export function DeveloperModeLabel(): React.ReactElement | null {
    const classes = useStyles();

    const showDeveloperModeAvailableLabel = useSelector((state) => state.app.showDeveloperModeAvailableLabel);
    const developerMode = useSelector((state) => state.app.developerMode);

    const setDeveloperMode = useAction(appReducer.actions.setDeveloperMode);

    const handleClickEnable = () => {
        setDeveloperMode(true);
    };

    const handleClickExit = () => {
        setDeveloperMode(false);
    };

    useEffect(() => {
        if (!isMobileApp()) return;
        setSetDeveloperModeInMobileApp(developerMode);
    }, [developerMode]);

    if (developerMode) {
        return (
            <div className={classes.container} onClick={handleClickExit}>
                {translate('Developer Mode Enabled - Click to EXIT')}
            </div>
        );
    }

    if (showDeveloperModeAvailableLabel) {
        if (isProductionEnvironment()) return null;
        return (
            <div className={classNames(classes.container, classes.developerModeAvailableContainerColor)} onClick={handleClickEnable}>
                {translate('Developer Mode Features Available - Click to ENABLE')}
            </div>
        );
    }

    return null;
}

const useStyles = makeStyles((theme) => ({
    container: {
        position: 'fixed',
        left: '50%',
        transform: 'translate(-50%, 0%)',
        bottom: 0,
        paddingLeft: 4,
        paddingRight: 4,
        borderTopRightRadius: 4,
        borderTopLeftRadius: 4,
        backgroundColor: '#256aa9',
        color: 'white',
        cursor: 'pointer',
        userSelect: 'none',
        zIndex: 999999,
    },

    developerModeAvailableContainerColor: {
        backgroundColor: '#4caf50',
    },
}));
