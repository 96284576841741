import type { CartItemVm, ModifierGroupVm as CartModifierGroupVm } from 'src/types/CartItemVm';
import { CustomerDisplayScreenCartItemVm } from 'src/types/CustomerDisplayScreenCartItemVm';
import type { ModifierGroupVm } from 'src/types/OrderVm';

export function mapCartItemToCustomerDisplayScreenCartItem(cartItem: CartItemVm): CustomerDisplayScreenCartItemVm {
    return {
        menuItemId: cartItem.menuItemId,
        menuCategoryId: cartItem.menuCategoryId,
        name: cartItem.name,
        unitPrice: cartItem.unitPrice,
        promoUnitPrice: cartItem.promoUnitPrice,
        promoText: cartItem.promoText,
        modifierGroups: cartItem.modifierGroups.map(mapCartModifierGroupToOrderModifierGroup),
        note: cartItem.note,
        quantity: Number(cartItem.quantity),
        addedAt: cartItem.addedAt,
        removedAt: cartItem.removedAt,
        salesUnit: cartItem.salesUnit,
        isSoldByWeight: cartItem.isSoldByWeight,
    };
}

function mapCartModifierGroupToOrderModifierGroup(modifierGroup: CartModifierGroupVm | ModifierGroupVm): ModifierGroupVm {
    return {
        name: modifierGroup.name,
        showModifierGroupNameInCommand: modifierGroup.showModifierGroupNameInCommand,
        freeModifiersQuantity: modifierGroup.freeModifiersQuantity,
        modifiers: modifierGroup.modifiers,
    };
}
