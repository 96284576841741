import { letseatadminApiMethod } from 'src/api/letseatadminApi';
import type { ApiSauceResponse } from 'src/api/types/ApiSauceResponse';
import type { City } from 'src/constants/City';
import type { GeoJsonPoint } from 'src/types/GeoJsonPoint';
import type { RestaurantId } from 'src/types/Id';

export async function findRestaurantLocationsApi(request: FindRestaurantLocationsApiRequest): ApiSauceResponse<FindRestaurantLocationsApiResponse> {
    return letseatadminApiMethod('restaurant/findRestaurantLocationsApi', request);
}

export type FindRestaurantLocationsApiRequest = {
    cities?: Array<City>;
};

export type FindRestaurantLocationsApiResponse = {
    restaurants: Array<RestaurantVm>;
};

export type RestaurantVm = {
    restaurantId: RestaurantId;
    name: string;
    location: GeoJsonPoint;
};
