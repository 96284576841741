import { makeStyles } from '@material-ui/core';
import * as React from 'react';
import { useEffect } from 'react';
import { app2 } from 'src/app2';
import { PasswordRequiredContent } from 'src/components/PasswordRequiredContent';
import type { RestaurantStatus } from 'src/constants/RestaurantStatus';
import type { RestaurantType } from 'src/constants/RestaurantType';
import PickupStationsTable from 'src/scenes/letseatadmin/pickupStation/PickupStationsTable';
import type { KitchenId, RestaurantId } from 'src/types/Id';
import { useAction } from 'src/utils/react/useAction';

export function PickupStationsPage(): React.ReactElement {
    const classes = useStyles();

    const setTitle = useAction(app2.actions.setTitle);

    useEffect(() => {
        setTitle('Pickup Stations');
    }, []);

    return (
        <PasswordRequiredContent customPassword={'4253'}>
            <div className={classes.container}>
                <PickupStationsTable />
            </div>
        </PasswordRequiredContent>
    );
}

const useStyles = makeStyles((theme) => ({
    container: {
        position: 'relative',
    },
}));

export type PickupStationsVm = {
    pickupStations: Array<PickupStationVm>;
};

export type PickupStationVm = {
    pickupStationId: RestaurantId;
    status: RestaurantStatus;
    name: string;
    closingTime?: string;
    deliveryTime?: string;
    restaurantType: RestaurantType;
    kitchenIds?: Array<KitchenId>;
    kitchenNames?: Array<string>;
    public?: boolean;
};
