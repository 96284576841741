import { makeStyles } from '@material-ui/core';
import * as React from 'react';
import { Dialog } from 'src/components/Dialog';
import { ChangeModifierGroup } from 'src/scenes/letseatmanager/menu/modifierGroup/ChangeModifierGroup';
import type { ModifierGroupId } from 'src/types/Id';
import { useIsSmallScreen } from 'src/utils/react/window/useIsSmallScreen';

export function ChangeModifierGroupSection({ modifierGroupId, onClose, onSuccess }: Props): React.ReactElement {
    const classes = useStyles();
    const isSmallScreen = useIsSmallScreen();

    if (isSmallScreen) {
        return (
            <Dialog open={true} onClose={onClose}>
                <ChangeModifierGroup modifierGroupId={modifierGroupId} onClose={onClose} onSuccess={onSuccess} />
            </Dialog>
        );
    }

    return (
        <div className={classes.container}>
            <ChangeModifierGroup modifierGroupId={modifierGroupId} onClose={onClose} onSuccess={onSuccess} />
        </div>
    );
}

const useStyles = makeStyles((theme) => ({
    container: {
        width: '100%',
    },
}));

type Props = {
    modifierGroupId: ModifierGroupId | undefined;
    onClose: any;
    onSuccess?: any;
};
