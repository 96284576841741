import { makeStyles, useTheme } from '@material-ui/core';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import moment from 'moment';
import { useState } from 'react';
import * as React from 'react';
import { Accordion } from 'src/components/Accordion';
import { Channel } from 'src/components/Channel';
import { OrderDetailsDialog } from 'src/components/orderCard/OrderDetailsDialog';
import { SimplifiedOrderCard } from 'src/components/orderCard/SimplifiedOrderCard';
import { SimpleTable } from 'src/components/SimpleTable';
import { Apps } from 'src/constants/App';
import { DeliveryStatus, DeliveryStatuses } from 'src/constants/DeliveryStatus';
import { OrderStatuses } from 'src/constants/OrderStatus';
import { translate } from 'src/i18n/translate';
import { useOrderPageIsTableView } from 'src/modules/order/useOrderPageIsTableView';
import { useFormatAsRestaurantCurrencyNumber } from 'src/services/restaurant/useFormatAsRestaurantCurrencyNumber';
import type { OrderVm } from 'src/types/OrderVm';
import { formatDateTimeString } from 'src/utils/date/formatDateTimeString';
import { isAcceptedDelivery } from 'src/utils/delivery/isAcceptedDelivery';
import { isPickedUpDelivery } from 'src/utils/delivery/isPickedUpDelivery';
import { isReturnedDelivery } from 'src/utils/delivery/isReturnedDelivery';
import { getBaseOrderCost } from 'src/utils/order/getBaseOrderCost';
import { isAcceptedOrder } from 'src/utils/order/isAcceptedOrder';
import { isCancelledOrder } from 'src/utils/order/isCancelledOrder';
import { isCompletedOrder } from 'src/utils/order/isCompletedOrder';
import { isNewOrder } from 'src/utils/order/isNewOrder';
import { isPendingOrder } from 'src/utils/order/isPendingOrder';
import { isRejectedOrder } from 'src/utils/order/isRejectedOrder';
import { toHumanizedOrderId } from 'src/utils/order/toHumanizedOrderId';

export function DeliveryOrders({ orders, deliveryStatuses, onChangeOrder }: Props): React.ReactElement {
    const classes = useStyles();
    const theme = useTheme();
    const isBelowSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
    const formatAsCurrencyNumber = useFormatAsRestaurantCurrencyNumber();

    const [orderDetailsDialogState, setOrderDetailsDialogState] = useState<{ open: boolean; order: OrderVm | undefined }>({ open: false, order: undefined });

    const returnedOrders = orders.filter((order) => isReturnedDelivery(order.deliveryStatus));
    const orderWithoutDrivers = orders.filter((order) => !order.driverId && isAcceptedOrder(order.orderStatus));
    const pendingOrders = orders.filter((order) => !order.driverId && isPendingOrder(order.orderStatus));
    const ongoingOrders = orders.filter((order) => isAcceptedDelivery(order.deliveryStatus) || isPickedUpDelivery(order.deliveryStatus));
    const completedOrders = orders.filter((order) => isCompletedOrder(order.orderStatus));
    const newOrders = orders.filter((order) => isNewOrder(order.orderStatus));
    const cancelledOrders = orders.filter((order) => isCancelledOrder(order.orderStatus));
    const rejectedOrders = orders.filter((order) => isRejectedOrder(order.orderStatus));
    const renderReturnedOrders = deliveryStatuses.includes(DeliveryStatuses.RETURNED);
    const renderOrdersWithoutDriver = deliveryStatuses.includes(DeliveryStatuses.REQUESTED);
    const renderOngoingOrders = deliveryStatuses.includes(OrderStatuses.ACCEPTED);
    const renderDeliveredOrders = deliveryStatuses.includes(DeliveryStatuses.DELIVERED);
    const renderCancelledOrders = deliveryStatuses.includes(DeliveryStatuses.CANCELLED);
    const renderRejectedOrders = deliveryStatuses.includes(DeliveryStatuses.REJECTED);
    const isTableView = useOrderPageIsTableView();
    const renderTableView = !isBelowSmallScreen && isTableView;

    const orderIsPickedUp = (order: OrderVm) => {
        const orderIsArrivedAtClient = moment(order.arrivedAtClientAt).add(5, 'minutes').isBefore(moment());
        const orderIsPickedUp = orderIsArrivedAtClient && !isCompletedOrder(order.orderStatus) && !isRejectedOrder(order.orderStatus) && !isCancelledOrder(order.orderStatus);

        return orderIsPickedUp;
    };

    const openOrderDetailsDialog = (order: OrderVm) => setOrderDetailsDialogState({ open: true, order });

    const closeOrderDetailsDialog = () => setOrderDetailsDialogState({ open: false, order: undefined });

    const columns = [
        {
            id: 'app',
            content: translate('Channel'),
            size: 0.5,
        },
        {
            id: 'id',
            content: translate('Id'),
            size: 0.7,
        },
        {
            id: 'customerName',
            content: translate('Customer'),
        },
        {
            id: 'createdAt',
            content: translate('Time'),
        },
        {
            id: 'paymentMethod',
            content: translate('Payment method'),
            size: 0.8,
        },
        {
            id: 'price',
            content: translate('Price'),
            size: 0.4,
        },
    ];

    const ongoingOrdersColumns = [
        ...columns.slice(0, 3),
        {
            id: 'deliveryStatus',
            content: translate('Status'),
        },
        ...columns.slice(3, 6),
    ];

    return (
        <div className={classes.container}>
            {!!orderDetailsDialogState.order && (
                <OrderDetailsDialog
                    open={orderDetailsDialogState.open}
                    order={orderDetailsDialogState.order}
                    onClose={closeOrderDetailsDialog}
                    onChange={onChangeOrder}
                    classes={{ container: classes.orderCardAlerted }}
                />
            )}
            {!!newOrders.length && (
                <Accordion title={translate('New (@orders)', { orders: newOrders.length })} defaultOpened>
                    {renderTableView && (
                        <div className={classes.tableContainer}>
                            <SimpleTable
                                classes={{ cell: classes.tableCell }}
                                columns={columns}
                                rows={newOrders.map((order) => ({
                                    app: <Channel channel={order.app || Apps.PIDEDIRECTO} size={35} disabled />,
                                    id: (
                                        <span className={classes.link} onClick={() => openOrderDetailsDialog(order)}>
                                            #{toHumanizedOrderId(order.orderId)}
                                        </span>
                                    ),
                                    customerName: order.customerName,
                                    createdAt: formatDateTimeString(order.createdAt, order.timeZone),
                                    paymentMethod: order.paymentMethod ? translate(`PaymentMethods.${order.paymentMethod}`) : '',
                                    price: formatAsCurrencyNumber(getBaseOrderCost(order)),
                                }))}
                            />
                        </div>
                    )}
                    {!renderTableView && (
                        <div className={classes.ordersContainer}>
                            {newOrders.map((order) => (
                                <SimplifiedOrderCard key={order.orderId} order={order} onClick={() => openOrderDetailsDialog(order)} />
                            ))}
                        </div>
                    )}
                </Accordion>
            )}
            {!!pendingOrders.length && (
                <Accordion title={translate('Payment pending (@orders)', { orders: pendingOrders.length })} defaultOpened>
                    {renderTableView && (
                        <div className={classes.tableContainer}>
                            <SimpleTable
                                classes={{ cell: classes.tableCell }}
                                columns={columns}
                                rows={pendingOrders.map((order) => ({
                                    app: <Channel channel={order.app || Apps.PIDEDIRECTO} size={35} disabled />,
                                    id: (
                                        <span className={classes.link} onClick={() => openOrderDetailsDialog(order)}>
                                            #{toHumanizedOrderId(order.orderId)}
                                        </span>
                                    ),
                                    customerName: order.customerName,
                                    createdAt: formatDateTimeString(order.createdAt, order.timeZone),
                                    paymentMethod: order.paymentMethod ? translate(`PaymentMethods.${order.paymentMethod}`) : '',
                                    price: formatAsCurrencyNumber(getBaseOrderCost(order)),
                                }))}
                            />
                        </div>
                    )}
                    {!renderTableView && (
                        <div className={classes.ordersContainer}>
                            {pendingOrders.map((order) => (
                                <SimplifiedOrderCard key={order.orderId} order={order} onClick={() => openOrderDetailsDialog(order)} />
                            ))}
                        </div>
                    )}
                </Accordion>
            )}
            {renderOrdersWithoutDriver && (
                <Accordion title={translate('Looking for driver (@orders)', { orders: orderWithoutDrivers.length })} defaultOpened>
                    {renderTableView && (
                        <div className={classes.tableContainer}>
                            <SimpleTable
                                classes={{ cell: classes.tableCell }}
                                columns={columns}
                                rows={orderWithoutDrivers.map((order) => ({
                                    app: <Channel channel={order.app || Apps.PIDEDIRECTO} size={35} disabled />,
                                    id: (
                                        <span className={classes.link} onClick={() => openOrderDetailsDialog(order)}>
                                            #{toHumanizedOrderId(order.orderId)}
                                        </span>
                                    ),
                                    customerName: order.customerName,
                                    createdAt: formatDateTimeString(order.createdAt, order.timeZone),
                                    paymentMethod: order.paymentMethod ? translate(`PaymentMethods.${order.paymentMethod}`) : '',
                                    price: formatAsCurrencyNumber(getBaseOrderCost(order)),
                                }))}
                            />
                        </div>
                    )}
                    {!renderTableView && (
                        <div className={classes.ordersContainer}>
                            {orderWithoutDrivers.map((order) => (
                                <SimplifiedOrderCard key={order.orderId} order={order} onClick={() => openOrderDetailsDialog(order)} />
                            ))}
                        </div>
                    )}
                </Accordion>
            )}
            {renderOngoingOrders && (
                <Accordion title={translate('Driver assigned (@orders)', { orders: ongoingOrders.length })} defaultOpened>
                    {renderTableView && (
                        <div className={classes.tableContainer}>
                            <SimpleTable
                                classes={{ cell: classes.tableCell }}
                                columns={ongoingOrdersColumns}
                                rows={ongoingOrders.map((order) => ({
                                    className: orderIsPickedUp(order) ? classes.orderCardAlerted : '',
                                    app: <Channel channel={order.app || Apps.PIDEDIRECTO} size={35} disabled />,
                                    id: (
                                        <span className={classes.link} onClick={() => openOrderDetailsDialog(order)}>
                                            #{toHumanizedOrderId(order.orderId)}
                                        </span>
                                    ),
                                    customerName: order.customerName,
                                    deliveryStatus: order.deliveryStatus ? translate(`DeliveryStatuses.${order.deliveryStatus}`) : '',
                                    createdAt: formatDateTimeString(order.createdAt, order.timeZone),
                                    paymentMethod: order.paymentMethod ? translate(`PaymentMethods.${order.paymentMethod}`) : '',
                                    price: formatAsCurrencyNumber(getBaseOrderCost(order)),
                                }))}
                            />
                        </div>
                    )}
                    {!renderTableView && (
                        <div className={classes.ordersContainer}>
                            {ongoingOrders.map((order) => (
                                <SimplifiedOrderCard
                                    key={order.orderId}
                                    order={order}
                                    onClick={() => openOrderDetailsDialog(order)}
                                    classes={{ container: orderIsPickedUp(order) ? classes.containerOrderCardAlerted : undefined }}
                                />
                            ))}
                        </div>
                    )}
                </Accordion>
            )}
            {renderDeliveredOrders && (
                <Accordion title={translate('Completed (@orders)', { orders: completedOrders.length })} defaultOpened>
                    {renderTableView && (
                        <div className={classes.tableContainer}>
                            <SimpleTable
                                classes={{ cell: classes.tableCell }}
                                columns={columns}
                                rows={completedOrders.map((order) => ({
                                    app: <Channel channel={order.app || Apps.PIDEDIRECTO} size={35} disabled />,
                                    id: (
                                        <span className={classes.link} onClick={() => openOrderDetailsDialog(order)}>
                                            #{toHumanizedOrderId(order.orderId)}
                                        </span>
                                    ),
                                    customerName: order.customerName,
                                    createdAt: formatDateTimeString(order.createdAt, order.timeZone),
                                    paymentMethod: order.paymentMethod ? translate(`PaymentMethods.${order.paymentMethod}`) : '',
                                    price: formatAsCurrencyNumber(getBaseOrderCost(order)),
                                }))}
                            />
                        </div>
                    )}
                    {!renderTableView && (
                        <div className={classes.ordersContainer}>
                            {completedOrders.map((order) => (
                                <SimplifiedOrderCard key={order.orderId} order={order} onClick={() => openOrderDetailsDialog(order)} />
                            ))}
                        </div>
                    )}
                </Accordion>
            )}
            {renderReturnedOrders && (
                <Accordion title={translate('Returned (@orders)', { orders: returnedOrders.length })} defaultOpened>
                    {renderTableView && (
                        <div className={classes.tableContainer}>
                            <SimpleTable
                                classes={{ cell: classes.tableCell }}
                                columns={columns}
                                rows={returnedOrders.map((order) => ({
                                    app: <Channel channel={order.app || Apps.PIDEDIRECTO} size={35} disabled />,
                                    id: (
                                        <span className={classes.link} onClick={() => openOrderDetailsDialog(order)}>
                                            #{toHumanizedOrderId(order.orderId)}
                                        </span>
                                    ),
                                    customerName: order.customerName,
                                    createdAt: formatDateTimeString(order.createdAt, order.timeZone),
                                    paymentMethod: order.paymentMethod ? translate(`PaymentMethods.${order.paymentMethod}`) : '',
                                    price: formatAsCurrencyNumber(getBaseOrderCost(order)),
                                }))}
                            />
                        </div>
                    )}
                    {!renderTableView && (
                        <div className={classes.ordersContainer}>
                            {returnedOrders.map((order) => (
                                <SimplifiedOrderCard key={order.orderId} order={order} onClick={() => openOrderDetailsDialog(order)} />
                            ))}
                        </div>
                    )}
                </Accordion>
            )}
            {renderCancelledOrders && (
                <Accordion title={translate('Cancelled (@orders)', { orders: cancelledOrders.length })} defaultOpened>
                    {renderTableView && (
                        <div className={classes.tableContainer}>
                            <SimpleTable
                                classes={{ cell: classes.tableCell }}
                                columns={columns}
                                rows={cancelledOrders.map((order) => ({
                                    app: <Channel channel={order.app || Apps.PIDEDIRECTO} size={35} disabled />,
                                    id: (
                                        <span className={classes.link} onClick={() => openOrderDetailsDialog(order)}>
                                            #{toHumanizedOrderId(order.orderId)}
                                        </span>
                                    ),
                                    customerName: order.customerName,
                                    createdAt: formatDateTimeString(order.createdAt, order.timeZone),
                                    paymentMethod: order.paymentMethod ? translate(`PaymentMethods.${order.paymentMethod}`) : '',
                                    price: formatAsCurrencyNumber(getBaseOrderCost(order)),
                                }))}
                            />
                        </div>
                    )}
                    {!renderTableView && (
                        <div className={classes.ordersContainer}>
                            {cancelledOrders.map((order) => (
                                <SimplifiedOrderCard key={order.orderId} order={order} onClick={() => openOrderDetailsDialog(order)} />
                            ))}
                        </div>
                    )}
                </Accordion>
            )}
            {renderRejectedOrders && (
                <Accordion title={translate('Rejected (@orders)', { orders: rejectedOrders.length })} defaultOpened>
                    {renderTableView && (
                        <div className={classes.tableContainer}>
                            <SimpleTable
                                classes={{ cell: classes.tableCell }}
                                columns={columns}
                                rows={rejectedOrders.map((order) => ({
                                    app: <Channel channel={order.app || Apps.PIDEDIRECTO} size={35} disabled />,
                                    id: (
                                        <span className={classes.link} onClick={() => openOrderDetailsDialog(order)}>
                                            #{toHumanizedOrderId(order.orderId)}
                                        </span>
                                    ),
                                    customerName: order.customerName,
                                    createdAt: formatDateTimeString(order.createdAt, order.timeZone),
                                    paymentMethod: order.paymentMethod ? translate(`PaymentMethods.${order.paymentMethod}`) : '',
                                    price: formatAsCurrencyNumber(getBaseOrderCost(order)),
                                }))}
                            />
                        </div>
                    )}
                    {!renderTableView && (
                        <div className={classes.ordersContainer}>
                            {rejectedOrders.map((order) => (
                                <SimplifiedOrderCard key={order.orderId} order={order} onClick={() => openOrderDetailsDialog(order)} />
                            ))}
                        </div>
                    )}
                </Accordion>
            )}
        </div>
    );
}

const useStyles = makeStyles((theme) => ({
    container: {
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        gap: 20,
        paddingBottom: 30,
    },
    tableContainer: {
        width: '100%',
        marginTop: 20,
        [theme.breakpoints.down('sm')]: {
            display: 'none',
        },
    },
    ordersContainer: {
        width: '100%',
        marginTop: 20,
        display: 'grid',
        gap: 20,
        gridTemplateColumns: 'repeat(auto-fill, 350px)',
    },
    link: {
        color: '#198074',
        fontFamily: theme.typography.regular,
        fontSize: 16,
        textDecoration: 'underline',
        cursor: 'pointer',
    },
    tableCell: {
        alignItems: 'center',
    },
    orderType: {
        color: 'white',
        padding: '4px 12px',
        borderRadius: 4,
    },
    orderCardAlerted: {
        backgroundColor: 'red',
        animation: `$colorized 1s infinite`,
    },
    containerOrderCardAlerted: {
        animation: `$vibration 1s infinite`,
    },
    '@keyframes colorized': {
        '0%': {
            backgroundColor: ' transparent',
        },
        '50%': {
            backgroundColor: ' #ffbcc1',
        },
        '100%': {
            backgroundColor: 'transparent',
        },
    },
    '@keyframes vibration': {
        '0%': {
            transform: 'scale(1)',
        },
        '50%': {
            transform: 'scale(1.05)',
        },
        '100%': {
            transform: 'scale(1)',
        },
    },
}));

type Props = {
    orders: Array<OrderVm>;
    deliveryStatuses: Array<DeliveryStatus>;
    onChangeOrder?: any;
};
