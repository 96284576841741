import { makeStyles } from '@material-ui/core';
import * as React from 'react';
import { SecuredAndSubscribedPage } from 'src/components/page/SecuredAndSubscribedPage';
import { Tabs } from 'src/components/Tabs';
import { RolePermissions } from 'src/constants/RolePermission';
import { translate } from 'src/i18n/translate';
import { BuyOrderTicketPrintExample } from 'src/scenes/letseatmanager/customizePrinters/buyOrderTicketPrint/BuyOrderTicketPrintExample';
import { CashRegisterReportPrintExample } from 'src/scenes/letseatmanager/customizePrinters/cashRegisterReportPrint/CashRegisterReportPrintExample';
import { CommandSettings } from 'src/scenes/letseatmanager/customizePrinters/command/CommandSettings';
import { PosBusinessDaySettings } from 'src/scenes/letseatmanager/customizePrinters/posBusinessDay/PosBusinessDaySettings';
import { PrinterInfoPrintExample } from 'src/scenes/letseatmanager/customizePrinters/printerInfoPrint/PrinterInfoPrintExample';
import { TicketSettings } from 'src/scenes/letseatmanager/customizePrinters/ticket/TicketSettings';
import { useDeveloperMode } from 'src/services/developerMode/useDeveloperMode';

export function CustomizePrintsPage(): React.ReactElement {
    const classes = useStyles();

    const developerMode = useDeveloperMode();

    const tabs = [
        {
            label: translate('Ticket'),
            value: '1',
        },
        {
            label: translate('Command'),
            value: '2',
        },
        {
            label: translate('End of day'),
            value: '3',
        },
        {
            label: translate('Cash register report'),
            value: '4',
            hidden: !developerMode,
        },
        {
            label: translate('Buy order ticket'),
            value: '5',
            hidden: !developerMode,
        },
        {
            label: translate('Printer Info'),
            value: '6',
            hidden: !developerMode,
        },
    ];

    return (
        <SecuredAndSubscribedPage rolePermission={RolePermissions.TICKET_COMMAND_PAGE} className={classes.container} title={translate('Customize prints')}>
            <Tabs tabs={tabs}>
                <TicketSettings />
                <CommandSettings />
                <PosBusinessDaySettings />
                {developerMode && <CashRegisterReportPrintExample />}
                {developerMode && <BuyOrderTicketPrintExample />}
                {developerMode && <PrinterInfoPrintExample />}
            </Tabs>
        </SecuredAndSubscribedPage>
    );
}

const useStyles = makeStyles((theme) => ({
    container: {
        position: 'relative',
    },
}));
