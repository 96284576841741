import { Button } from '@pidedirecto/ui';
import { useNotification } from '@pidedirecto/ui/hooks';
import * as React from 'react';
import { useHistory } from 'react-router-dom';
import { SecuredContent } from 'src/components/SecuredContent';
import { RolePermissions } from 'src/constants/RolePermission';
import { RoutePaths } from 'src/constants/RoutePath';
import { translate } from 'src/i18n/translate';
import { useAskForRolePermissionAuthorization } from 'src/modules/order/utils/useAskForRolePermissionAuthorization';
import type { OrderVm } from 'src/types/OrderVm';
import { isClosedOrder } from 'src/utils/order/isClosedOrder';
import { useEditPendingOrder } from 'src/utils/react/useEditPendingOrder';
import { useSelector } from 'src/utils/react/useSelector';
import { useUserHasRolePermission } from 'src/utils/react/useUserHasRolePermissions';

export function OpenClosedOrderButton({ order, classes }: Props): React.ReactElement | null {
    const history = useHistory();

    const [userHasRolePermission] = useUserHasRolePermission();
    const notification = useNotification();

    const editPendingOrder = useEditPendingOrder();
    const askForRolePermissionAuthorization = useAskForRolePermissionAuthorization();

    const brandOpened = useSelector((state) => state.app.brandOpened);

    const handleEditPendingOrder = () => {
        editPendingOrder({ order });
        history.push({
            pathname: RoutePaths.POS,
            search: history.location.search,
        });
    };

    const handleOpenClosedOrder = async () => {
        if (brandOpened) {
            notification({ message: translate('Select a restaurant to edit orders') });
            return;
        }

        if (userHasRolePermission(RolePermissions.OPEN_CLOSED_ORDERS)) {
            handleEditPendingOrder();
            notification({ message: translate('Unlocked order') });
            return;
        }

        const isAuthorized = await askForRolePermissionAuthorization({
            rolePermission: RolePermissions.OPEN_CLOSED_ORDERS,
            title: translate('Printed partial account'),
            subtitle: translate('Unblock the order with an authorized PIN'),
        });

        if (!isAuthorized) return;

        handleEditPendingOrder();
    };

    if (!isClosedOrder(order.orderStatus)) return null;

    return (
        <SecuredContent rolePermission={RolePermissions.EDIT_ORDERS}>
            <Button classes={{ button: classes?.button }} variant={'outline'} onClick={handleOpenClosedOrder}>
                {translate('Unlock')}
            </Button>
        </SecuredContent>
    );
}

type Props = {
    order: OrderVm;
    classes?: { button: string };
};
