import { BigNumber } from 'bignumber.js';
import { PosState } from 'src/reducers/posReducer';
import type { CartItemVm } from 'src/types/CartItemVm';
import { PosCourseOrderDetails } from 'src/types/PosCourseOrderDetails';
import { isItemSoldByWeight } from 'src/utils/orderItem/isItemSoldByWeight';

export function removeCoursePosItem(state: PosState, item: CartItemVm, isCancelledItem: boolean): void {
    const course = state.ongoingCourses?.find((course: PosCourseOrderDetails) => course.courseId === state.courseIdSelected);

    if (!course) return;
    const existingPosItem = course.orderItems?.find((current: CartItemVm) => current.key === item.key);
    if (!existingPosItem) return;

    if (existingPosItem.quantity === 1 || isItemSoldByWeight(item)) {
        course.orderItems = course.orderItems?.filter((item: CartItemVm) => item.key !== existingPosItem?.key);
    }

    if (existingPosItem.quantity > 1 && !isItemSoldByWeight(existingPosItem)) {
        existingPosItem.quantity = BigNumber(existingPosItem.quantity).minus(1).toNumber();
    }

    if (isCancelledItem) {
        course.cancelledItems = [...(course.cancelledItems ?? []), item];
    }
}
