import * as React from 'react';

export function UsbIcon({ color }: Props): React.ReactElement {
    return (
        <svg width='40' height='30' viewBox='0 0 64 39' fill='none' xmlns='http://www.w3.org/2000/svg'>
            <path
                d='M63.3965 19.5C63.3965 19.8027 63.2305 20.0957 62.957 20.2324L54.2559 25.457C54.1191 25.5352 53.9824 25.5937 53.8164 25.5937C53.6797 25.5937 53.5137 25.5645 53.377 25.4863C53.1035 25.3203 52.9375 25.0469 52.9375 24.7246V21.248H29.627C32.0977 25.1152 33.582 31.6875 36.4238 31.6875H39.0312V29.0703C39.0312 28.582 39.4121 28.2012 39.9004 28.2012H48.6016C49.0898 28.2012 49.4707 28.582 49.4707 29.0703V37.7715C49.4707 38.2598 49.0898 38.6406 48.6016 38.6406H39.9004C39.4121 38.6406 39.0312 38.2598 39.0312 37.7715V35.1641H36.4238C29.0605 35.1641 28.5039 21.248 24.2461 21.248H14.4512C13.6602 24.2363 10.9453 26.4727 7.71289 26.4727C3.875 26.4629 0.75 23.3379 0.75 19.5C0.75 15.6621 3.875 12.5371 7.71289 12.5371C10.9453 12.5371 13.6699 14.7637 14.4512 17.7617C18.2695 17.7617 18.7383 18.6895 21.7363 11.8633C25.6523 3.16211 27.4102 3.8457 32.3711 3.8457C33.1035 1.80469 35.0078 0.369141 37.293 0.369141C40.1738 0.369141 42.5176 2.70312 42.5176 5.59375C42.5176 8.48438 40.1836 10.8184 37.293 10.8184C35.0078 10.8184 33.1035 9.37305 32.3711 7.3418H29.4609C26.6191 7.3418 25.1348 13.9238 22.6641 17.7812H52.9473V14.3047C52.9473 13.9824 53.1133 13.709 53.3867 13.543C53.6602 13.377 54.0117 13.4063 54.2559 13.5723L62.957 18.7969C63.2305 18.9043 63.3965 19.1973 63.3965 19.5Z'
                fill={color || 'currentColor'}
            />
        </svg>
    );
}

type Props = {
    color: string;
};
