import { CashRegisterDepositReason, CashRegisterDepositReasons } from 'src/constants/CashRegisterDepositReason';
import type { CashRegisterWithdrawReason } from 'src/constants/CashRegisterWithdrawReason';
import { translate } from 'src/i18n/translate';
import type { CashRegisterCustomReason } from 'src/types/Id';

export function translateCashRegisterDepositReasons(cashRegisterDepositReason: CashRegisterDepositReason | CashRegisterWithdrawReason | CashRegisterCustomReason): string {
    const CashRegisterDepositReasonsValues = Object.values(CashRegisterDepositReasons);
    if (!CashRegisterDepositReasonsValues.includes(cashRegisterDepositReason as any)) {
        return cashRegisterDepositReason;
    }

    return translate(`CashRegisterDepositReasons.${cashRegisterDepositReason}`);
}
