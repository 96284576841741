import type { RestaurantVideoVm } from 'src/api/letseatadmin/restaurantVideo/findRestaurantVideosApi';
import { letseatadminApiMethod } from 'src/api/letseatadminApi';
import type { ApiSauceResponse } from 'src/api/types/ApiSauceResponse';
import type { RestaurantVideoId } from 'src/types/Id';

export async function changeRestaurantVideoApi(request: ChangeRestaurantVideoApiRequest): ApiSauceResponse<RestaurantVideoVm> {
    return letseatadminApiMethod('restaurantVideo/changeRestaurantVideoApi', request);
}

export type ChangeRestaurantVideoApiRequest = {
    restaurantVideoId: RestaurantVideoId;
    name: string;
    videoUrl: string;
};
