import * as React from 'react';

export function ExpandLess({ title, color }: Props): React.ReactElement {
    return (
        <svg width='12' height='7' viewBox='0 0 12 7' fill='none' xmlns='http://www.w3.org/2000/svg'>
            <title>{title}</title>
            <path d='M1 6L6 0.999999L11 6' stroke={color ?? 'currentColor'} strokeWidth='1.5' strokeLinecap='round' strokeLinejoin='round' />
        </svg>
    );
}

type Props = {
    title?: string;
    color?: string;
};
