import { makeStyles } from '@material-ui/core';
import { BigNumber } from 'bignumber.js';
import moment from 'moment/moment';
import { useContext } from 'react';
import * as React from 'react';
import { Button } from 'src/components/Button';
import { CustomerDisplayScreenOrderSteps } from 'src/constants/CustomerDisplayScreenOrderStep';
import { translate } from 'src/i18n/translate';
import { PosContext } from 'src/scenes/letseatmanager/pos/PosProvider';
import { useClearPos } from 'src/services/pos/useClearPos';
import { useSendOrderUpdateToCustomerDisplayScreen } from 'src/services/pos/useSendOrderUpdateToCustomerDisplayScreen';
import { usePrintOrderCommand } from 'src/services/printer/usePrintOrderCommand';
import { usePrintOrderTicket } from 'src/services/printer/usePrintOrderTicket';
import { PosPayment } from 'src/types/PosPayment';
import { isCardPayment } from 'src/utils/paymentMethod/isCardPayment';
import { isPaymentLinkPayment } from 'src/utils/paymentMethod/isPaymentLinkPayment';
import { findItemsChangedInOrder } from 'src/utils/pos/findItemsChangedInOrder';
import { useCompletePosOrder } from 'src/utils/react/useCompletePosOrder';
import { useSelector } from 'src/utils/react/useSelector';
import { canRestaurantInvoice } from 'src/utils/restaurant/canRestaurantInvoice';

export function PosPaymentCompleteAndInvoiceButton(): React.ReactElement | null {
    const classes = useStyles();
    const [printOrderCommand] = usePrintOrderCommand();
    const [printOrderTicket] = usePrintOrderTicket();

    const { completeOrder } = useCompletePosOrder();
    const clearPos = useClearPos();
    const { menuOpenedAt } = useContext(PosContext);
    const sendOrderUpdateToCustomerDisplayScreen = useSendOrderUpdateToCustomerDisplayScreen();

    const posContext = useContext(PosContext);

    const disabledPosPaymentActions = useSelector((state) => state.pos.disabledPosPaymentActions);
    const paying = useSelector((state) => state.pos.paying);
    const cancelledItems = useSelector((state) => state.pos.cancelledItems);
    const items = useSelector((state) => state.pos.items);
    const pendingOrder = useSelector((state) => state.pos.pendingOrder);
    const payments = useSelector((state) => state.pos.payments);
    const restaurant = useSelector((state) => state.app.restaurant);
    const totalPaid = useSelector((state) => state.pos.totalPaid);
    const total = useSelector((state) => state.pos.payment?.total);

    const hasPaymentMissing = BigNumber(totalPaid).isLessThan(total);
    const posPaymentIsPaymentLink = payments?.some((posPayment: PosPayment) => isPaymentLinkPayment(posPayment.paymentMethod));
    const disabled = disabledPosPaymentActions || paying || posPaymentIsPaymentLink || hasPaymentMissing;
    const shouldPrintPendingOrderCommand =
        !!pendingOrder && moment(pendingOrder?.createdAt).isSame(menuOpenedAt) && pendingOrder.payments?.some((payment) => isCardPayment(payment.paymentMethod) && !payment.customPaymentMethod);

    const handleCompleteOrder = async () => {
        sendOrderUpdateToCustomerDisplayScreen(CustomerDisplayScreenOrderSteps.CREATING_ORDER);
        const order = await completeOrder();
        if (!order) {
            sendOrderUpdateToCustomerDisplayScreen(CustomerDisplayScreenOrderSteps.UNSUCCESSFUL_ORDER);
            return;
        }

        const itemsToPrint = findItemsChangedInOrder(pendingOrder?.orderItems ?? ([] as any), items ?? ([] as any));
        const removedItemsToPrint = findItemsChangedInOrder(pendingOrder?.cancelledItems ?? ([] as any), cancelledItems ?? []);

        sendOrderUpdateToCustomerDisplayScreen(CustomerDisplayScreenOrderSteps.SUCCESSFUL_ORDER_CREATED);
        posContext.openCreateOrderInvoiceDialog(order);
        clearPos();

        if ((itemsToPrint?.length || removedItemsToPrint?.length) && !shouldPrintPendingOrderCommand) {
            await printOrderCommand({ ...order, orderItems: itemsToPrint, cancelledItems: removedItemsToPrint } as any);
        }
        if (shouldPrintPendingOrderCommand) await printOrderCommand(order);

        if (order.invoiced) {
            await printOrderTicket(order);
        }
    };

    if (!canRestaurantInvoice(restaurant)) return null;

    return (
        <Button classes={{ button: classes.basicButton }} onClick={handleCompleteOrder} disabled={disabled}>
            {translate('Charge and Invoice')}
        </Button>
    );
}

const useStyles = makeStyles((theme) => ({
    basicButton: {
        width: '100%',
        height: 50,
        '&:hover': {
            cursor: 'pointer',
        },
    },
}));
