import { DrawingManager, GoogleMap, Polygon, useLoadScript } from '@react-google-maps/api';
import * as React from 'react';
import { googleMapsConfig } from 'src/config/googleMapsConfig';

export function DeliveryZonesGoogleMapDrawer({ polygons, onDrawDeliveryZoneArea, center }: Props): React.ReactElement | null {
    const { isLoaded, loadError } = useLoadScript(googleMapsConfig);

    const parseCoordinates = (coordinates: any) =>
        coordinates.map((coord: [string, string]) => ({
            lat: Number(coord[0]),
            lng: Number(coord[1]),
        }));

    if (loadError) {
        return <div>Map cannot be loaded right now, try again.</div>;
    }

    if (!isLoaded) return null;

    const options: google.maps.drawing.DrawingManagerOptions = {
        drawingControl: true,
        drawingControlOptions: {
            position: window.google.maps.ControlPosition.TOP_CENTER,
            drawingModes: [window.google.maps.drawing.OverlayType.MARKER, window.google.maps.drawing.OverlayType.POLYGON],
        },
    };

    const onPolygonComplete = (polygon: any) => {
        let zoneCoordinates = polygon
            .getPath()
            .getArray()
            .map((coord: { lat: () => number; lng: () => number }) => {
                const newZone = [coord.lat(), coord.lng()];
                return newZone;
            });
        const first = zoneCoordinates[0];
        if (zoneCoordinates[0] !== zoneCoordinates[zoneCoordinates.length - 1]) {
            zoneCoordinates = [...zoneCoordinates, first];
        }
        onDrawDeliveryZoneArea({ type: 'Polygon', coordinates: zoneCoordinates });
        polygon.setMap(null);
    };

    return (
        <GoogleMap
            mapContainerStyle={{
                width: '100%',
                height: '100%',
            }}
            center={center}
            zoom={10}
        >
            <DrawingManager onPolygonComplete={onPolygonComplete} drawingMode={google.maps.drawing.OverlayType.POLYGON} options={options} />
            {polygons.length &&
                polygons.map((polygon: any) => (
                    <Polygon
                        key={polygon.deliveryZoneId}
                        paths={parseCoordinates(polygon.area.coordinates)}
                        options={{
                            fillColor: polygon.color,
                            fillOpacity: 0.3,
                            strokeColor: polygon.color,
                            strokeOpacity: 1,
                            strokeWeight: 2,
                            clickable: true,
                            draggable: false,
                            geodesic: false,
                            zIndex: 1,
                        }}
                        onUnmount={(polygon) => polygon.setMap(null)}
                    />
                ))}
        </GoogleMap>
    );
}

type Props = {
    polygons: any;
    onDrawDeliveryZoneArea: any;
    center: any;
};
