import { makeStyles } from '@material-ui/core';
import { Loader } from '@pidedirecto/ui';
import { GoogleMap, useLoadScript } from '@react-google-maps/api';
import { useCallback, useEffect, useState } from 'react';
import * as React from 'react';
import { DeliveryOrdersCustomerMarkers } from 'src/components/googlemaps/deliveryOrdersMap/DeliveryOrdersCustomerMarkers';
import { DeliveryOrdersDriverMarkers } from 'src/components/googlemaps/deliveryOrdersMap/DeliveryOrdersDriverMarkers';
import { DeliveryOrdersRestaurantMarkers } from 'src/components/googlemaps/deliveryOrdersMap/DeliveryOrdersRestaurantMarkers';
import { Text } from 'src/components/Text';
import { googleMapsConfig } from 'src/config/googleMapsConfig';
import { translate } from 'src/i18n/translate';
import type { OrderVm } from 'src/types/OrderVm';
import { toLatLng } from 'src/utils/googlemaps/toLatLng';
import { getRestaurantLocations } from 'src/utils/order/getRestaurantLocations';
import { isDeliveryOrder } from 'src/utils/order/isDeliveryOrder';
import { useSelector } from 'src/utils/react/useSelector';

export function DeliveryOrdersMap({ stopUpdatingPositions }: Props): React.ReactElement {
    const classes = useStyles();
    const { isLoaded, loadError } = useLoadScript(googleMapsConfig);

    const [map, setMap] = useState<google.maps.Map>();

    useUpdateMapBounds(map);

    const onLoad = useCallback((map: google.maps.Map) => {
        setMap(map);
    }, []);

    const onUnmount = useCallback(() => {
        setMap(undefined);
    }, []);

    if (!isLoaded) {
        return (
            <div className={classes.container}>
                <Loader size={40} loading={true} text={translate('Loading map')} />
            </div>
        );
    }

    if (loadError) {
        return (
            <div className={classes.container}>
                <Text size={'large'}>{translate('Failed to load google maps, please refresh page.')}</Text>;
            </div>
        );
    }

    return (
        <GoogleMap
            onLoad={onLoad}
            onUnmount={onUnmount}
            options={{
                fullscreenControl: false,
                mapTypeControl: false,
                streetViewControl: false,
                styles: mapStyles,
                gestureHandling: 'cooperative',
            }}
            mapContainerStyle={{ width: '100%', height: '100%' }}
        >
            <>
                <DeliveryOrdersRestaurantMarkers />
                <DeliveryOrdersCustomerMarkers />
                <DeliveryOrdersDriverMarkers stopUpdatingPositions={stopUpdatingPositions} />
            </>
        </GoogleMap>
    );
}

function useUpdateMapBounds(map: google.maps.Map | undefined): null {
    const currentOrders = useSelector((state) => state.app2.currentOrders);
    const deliveryOrders: Array<OrderVm> = currentOrders.filter((order) => isDeliveryOrder(order.orderType));

    useEffect(() => {
        if (!map) return;

        const bounds = new window.google.maps.LatLngBounds();

        const restaurantLocations = getRestaurantLocations(deliveryOrders);
        restaurantLocations.forEach((restaurantLocation) => bounds.extend(toLatLng(restaurantLocation)));

        deliveryOrders.forEach((order) => {
            if (!order.address?.location) return;
            bounds.extend(toLatLng(order.address?.location));
        });

        map.fitBounds(bounds);
    }, [deliveryOrders, map]);

    return null;
}

const useStyles = makeStyles((theme) => ({
    container: {
        width: '100%',
        height: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        gap: 10,
        backgroundColor: '#F0F0F0',
    },
}));

const mapStyles: google.maps.MapTypeStyle[] = [
    {
        featureType: 'poi',
        elementType: 'labels.text',
        stylers: [
            {
                visibility: 'off',
            },
        ],
    },
    {
        featureType: 'poi.business',
        stylers: [
            {
                visibility: 'off',
            },
        ],
    },
    {
        featureType: 'road',
        elementType: 'labels.icon',
        stylers: [
            {
                visibility: 'off',
            },
        ],
    },
    {
        featureType: 'transit',
        stylers: [
            {
                visibility: 'off',
            },
        ],
    },
];

type Props = {
    stopUpdatingPositions?: boolean;
};
