import { RegExps } from 'src/utils/RegExps';

export const RoutePaths = {
    RESTAURANT_DASHBOARD: '/restaurant-dashboard',
    RESTAURANT_GENERAL_REPORT: '/general-report',
    RESTAURANT_SALES_REPORT: '/sales-report',
    RESTAURANT_POS_REPORT: '/pos-report',
    ONGOING_ORDERS: '/ongoing-orders',
    ORDERS: '/orders',
    POS: '/pos',
    PIN_CODE_USER_LOGIN: '/pin_code_user_login',
    SUBSCRIPTION_PLAN: '/subscription-plan',
    KIOSK: '/kiosk',
    KITCHEN_DISPLAY_SCREEN: '/kitchen-display-screen',
    POS_CASH_REGISTERS: '/pos/cash',
    POS_TABLES: '/pos/tables',
    POS_BUSINESS_DAY: `/pos-business-day/:posBusinessDayId(${RegExps.uuid})`,
    POS_EMPLOYEE_EVENTS: `/pos-employee-events/:managerUserId(${RegExps.uuid})`,
    EMPLOYEE_RESUME_DAY: '/employee-resume-day',
    ORDER_INVOICES: '/order-invoices',
    GLOBAL_ORDER_INVOICES: '/global_order-invoices',
    OWN_FLEET_DRIVERS: '/own-fleet-drivers',
    OWN_FLEET_DRIVERS_REPORT: '/own-fleet-drivers-report',
    PROMO_CODES: '/promo-codes',
    PROMO_CODE_CREATE: '/promo-codes/new',
    PROMO_CODE_CHANGE: `/promo-codes/:promoCodeId(${RegExps.uuid})/change`,
    SURVEYS: '/surveys',
    CUSTOMER_RETURNS: '/customer-returns',
    THE_RESTAURANT: '/the-restaurant',
    CUSTOMIZE_PRINTS: '/customize-prints',
    THE_MENU: '/the-menu',
    SUBSCRIPTION: '/subscription',
    DEVICES: '/devices',
    PRINTERS: '/printers',
    FREE_SUBSCRIPTION_PLAN_END: '/free-subscription-plan-end',
    RESTAURANT_VIDEOS: '/restaurant-videos',
    HOW_DOES_IT_WORK: '/how-does-it-work',
    INTEGRATIONS: '/integrations',
    TAXES: '/taxes',
    DELIVERY_ZONES: '/delivery-zones',
    OPENING_HOURS: '/opening-hours',
    INVENTORY: '/inventory',
    BUY_ORDER_REGISTER_METHOD: '/buy-order-register-method',
    INVENTORY_SUPPLY: '/inventory-supply',
    INVENTORY_SUPPLIER: '/inventory-supplier',
    INVENTORY_RECIPE: '/inventory-recipe',
    INVENTORY_CREATE_BUY_ORDER: '/inventory-new-buy-order',
    INVENTORY_CHANGE_BUY_ORDER: `/inventory-change-buy-order/:buyOrderId(${RegExps.uuid})`,
    INVENTORY_BUY_ORDER: '/inventory-buy-order',
    INVENTORY_PRODUCTION: '/inventory-production',
    INVENTORY_CREATE_SUPPLY_MASSIVE_PRODUCTION: '/inventory-new-supply-production',
    USER_MANAGEMENT: '/user-management',
    CREATE_USER_ROLE: '/role',
    CHANGE_USER_ROLE: `/role/:userRoleId(${RegExps.uuid})`,
    PAYMENT_LINKS: '/payment-links',
    NOTIFICATION_CENTER: '/notification-center',
    MARKETING: '/marketing',
    MARKETING_CUSTOMERS: '/marketing-customers',
    CUSTOMERS: `/customers/:mobileNumber`,
    ADVANCED_REPORTS: '/advanced-reports',
    UPLOAD_BUY_ORDER: '/buy-order',
    ADMIN_RESTAURANT_MANUAL_EARNING: '/restaurant-manual-earning',
    ADMIN_MONITORING: '/admin/monitoring',
    ADMIN_QUICK_ACTIONS: '/admin/quick-actions',
    ADMIN_RAPPI_INTEGRATIONS: '/admin/rappi-integrations',
    ADMIN_DELIVERY_MONITORING: '/admin/delivery-monitoring',
    ADMIN_SUPPORT: '/admin/support',
    ADMIN_SUPPORT_PHONE_NUMBERS: '/admin/support-phone-numbers',
    ADMIN_RESTAURANT_REFERRALS: '/admin/restaurant-referrals',
    ADMIN_DRIVER_REFERRALS: '/admin/driver-referrals',
    ADMIN_MANAGER_USERS: '/admin/manager-users',
    ADMIN_STATISTICS: '/admin/statistics',
    ADMIN_STATISTICS_CUSTOMERS: '/admin/statistics/customers',
    ADMIN_STATISTICS_ORDERS: '/admin/statistics/orders',
    ADMIN_STATISTICS_PAYMENTS: '/admin/statistics/payments',
    ADMIN_STATISTICS_OLD: '/admin/statistics-old',
    ADMIN_SURVEYS: '/admin/surveys',
    ADMIN_SURVEY: `/admin/surveys/:surveyId(${RegExps.uuid})`,
    ADMIN_SURVEYS_SEND_NOTIFICATIONS: '/admin/surveys/send-notifications',
    ADMIN_SURVEYS_SEND_EMAILS: '/admin/surveys/send-emails',
    ADMIN_SURVEYS_CREDITS: '/admin/surveys/credits',
    ADMIN_SURVEYS_RESTAURANTS: '/admin/surveys/restaurants',
    ADMIN_ORDERS: '/admin/orders',
    ADMIN_SUSPICIOUS_ORDERS: '/admin/suspicious-orders',
    ADMIN_ONGOING_ORDERS: '/admin/ongoing-orders',
    ADMIN_OFFICE_DELIVERY: '/admin/office-delivery',
    ADMIN_OFFICE_DELIVERY_CHANGE_CUSTOMERS: '/admin/office-delivery/change-customers',
    ADMIN_OFFICE_DELIVERY_SEND: '/admin/office-delivery/send',
    ADMIN_OFFICE_DELIVERY_CREDITS: '/admin/office-delivery/credits',
    ADMIN_ORDER: `/admin/orders/:orderId(${RegExps.uuid})`,
    ADMIN_OVERALL_STATS: '/admin/overall-stats',
    ADMIN_RESTAURANTS: '/admin/restaurants',
    ADMIN_BRANDS: '/admin/brands',
    ADMIN_BUSINESSES: '/admin/businesses',
    ADMIN_BRAND: `/admin/brand/:brandId(${RegExps.uuid})`,
    ADMIN_PICKUP_STATIONS: '/admin/pickup-stations',
    ADMIN_RESTAURANTS_DETAILS: '/admin/restaurants-details',
    ADMIN_RESTAURANT_REGISTERS: '/admin/restaurant-registrations',
    ADMIN_CUSTOMERS: '/admin/customers',
    ADMIN_CUSTOMERS_CHANGE: '/admin/customers/change',
    ADMIN_CUSTOMERS_SEND_NOTIFICATIONS: '/admin/customers/send-notifications',
    ADMIN_CUSTOMERS_SEND_EMAILS: '/admin/customers/send-emails',
    ADMIN_CUSTOMERS_CREDITS: '/admin/customers/credits',
    ADMIN_CUSTOMER: `/admin/customers/:customerId(${RegExps.uuid})`,
    ADMIN_DRIVERS: '/admin/drivers',
    ADMIN_DRIVERAPP_SETTINGS: '/admin/driverapp-settings-page',
    ADMIN_LIVE_DRIVERS: '/admin/live-drivers',
    ADMIN_THIRD_PARTY_LOGISTICS_COVERAGE: '/admin/third-party-logistics-coverage',
    ADMIN_DRIVERS_CHANGE: '/admin/drivers/change',
    ADMIN_DRIVERS_SEND_NOTIFICATIONS: '/admin/drivers/send-notifications',
    ADMIN_DRIVERS_SEND_EMAILS: '/admin/drivers/send-emails',
    ADMIN_DRIVERS_SEND_SMS: '/admin/drivers/send-sms',
    ADMIN_DRIVER: `/admin/drivers/:driverId(${RegExps.uuid})`,
    ADMIN_DRIVER_BONUS_OFFERS: `/admin/driver-bonus-offers`,
    ADMIN_ONGOING_DRIVER_BONUSES: `/admin/ongoing-driver-bonuses`,
    ADMIN_BANNERS: '/admin/banners',
    ADMIN_SCHEDULED_TASKS: '/admin/scheduled-tasks',
    ADMIN_EMAIL_TEMPLATES: '/admin/email-templates',
    ADMIN_EMAILS: '/admin/sent-emails',
    ADMIN_FAQ: '/admin/faq',
    ADMIN_BANNER_CREATE: '/admin/banners/new',
    ADMIN_BANNER_CHANGE: `/admin/banners/:bannerId(${RegExps.uuid})/change`,
    ADMIN_PROMO_CODES: '/admin/promo-codes',
    ADMIN_PROMO_CODE_CREATE: '/admin/promo-codes/new',
    ADMIN_PROMO_CODE_CHANGE: `/admin/promo-codes/:promoCodeId(${RegExps.uuid})/change`,
    ADMIN_COMPANIES: '/admin/companies',
    ADMIN_COMPANY: `/admin/companies/:companyId(${RegExps.uuid})`,
    ADMIN_BENEFIT_PLAN: `/admin/benefit-plans/:benefitPlanId(${RegExps.uuid})`,
    ADMIN_EMPLOYEE: `/admin/employees/:employeeId(${RegExps.uuid})`,
    ADMIN_SUBSCRIPTIONS: '/admin/subscriptions',
    ADMIN_SUBSCRIPTION: `/admin/subscriptions/:subscriptionId(${RegExps.uuid})`,
    ADMIN_SUBSCRIPTION_HISTORY: `/admin/subscriptionsHistory/:subscriptionHistoryId(${RegExps.uuid})`,
    ADMIN_SUBSCRIPTION_HISTORIES: `/admin/subscriptionsHistories`,
    ADMIN_SUBSCRIPTION_PAYMENT_PAGE: `/admin/subscription-pay-per-use-payments`,
    ADMIN_RESTAURANT_BILLING: '/admin/restaurant-billing',
    ADMIN_RESTAURANT_BILLING_FOR_RESTAURANT: `/admin/restaurant-billing/:restaurantId(${RegExps.uuid})`,
    ADMIN_RESTAURANT_BILLING_UY: '/admin/restaurant-billing-uy',
    ADMIN_RESTAURANT_BILLING_FOR_RESTAURANT_UY: `/admin/restaurant-billing-uy/:restaurantId(${RegExps.uuid})`,
    ADMIN_RESTAURANT_SALES: '/admin/restaurant-sales',
    ADMIN_DRIVER_BILLING: '/admin/driver-billing',
    ADMIN_DRIVER_BONUS_BILLING: '/admin/driver-bonus-billing',
    ADMIN_DRIVER_BILLING_FOR_DRIVER: `/admin/driver-billing/:driverId(${RegExps.uuid})`,
    ADMIN_KITCHEN_BILLING: '/admin/kitchen-billing',
    ADMIN_KITCHEN_BILLING_FOR_KITCHEN: `/admin/kitchen-billing/:kitchenId(${RegExps.uuid})`,
    ADMIN_COMPANY_CREDITS_BILLING: '/admin/company-credits-billing',
    ADMIN_COMPANY_CREDITS_BILLING_FOR_BENEFIT_PLAN: `/admin/company-credits-billing/:benefitPlanId(${RegExps.uuid})`,
    ADMIN_COMPANY_PAYROLL_BILLING: '/admin/company-payroll-billing',
    ADMIN_COMPANY_PAYROLL_BILLING_FOR_COMPANY: `/admin/company-payroll-billing/:companyId(${RegExps.uuid})`,
    ADMIN_SETTINGS: '/admin/settings',
    ADMIN_MANAGER_USER: `/admin/manager-users/:managerUserId(${RegExps.uuid})`,
    DRIVER_DELIVERY_ITEMS_PAGE: '/driver/delivery-items',
    ADMIN_RESTAURANT_SELF_REGISTRATIONS: '/admin/restaurant-self-registrations',
    ADMIN_LOG_EVENTS: '/admin/log-events',
    ADMIN_RELEASES_NOTES: '/admin/releases_notes',
    ADMIN_DEMO_MODE_RESTAURANTS: '/admin/demo_mode_restaurants',
    ADMIN_REQUEST_INTERNAL_REPORT: '/admin/internal_report',
    SUPPLY_HISTORY_REPORT: '/supply-history-report',
    KARDEX_REPORT: '/kardex-report',
    INVENTORY_CONSUMPTION_REPORT: '/inventory-consumption-report',
    ADJUSTMENTS_INVENTORY_REPORT: '/adjustments-report',
    INVENTORY_SUPPLIERS_REPORT: '/inventory-suppliers-report',
    INVENTORY_REPORT: '/inventory-report',
    PROMOTIONS: '/promotions',
    ADMIN_CUSTOM_ALERT_DIALOG: '/admin/custom-alert-dialogs',
    ADMIN_ORDER_INCIDENTS: '/admin/order-incidents',
} as const;

export type RoutePath = (typeof RoutePaths)[keyof typeof RoutePaths];
