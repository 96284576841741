import { createSlice } from 'redux-starter-kit';
import type { DeliveryStatus } from 'src/constants/DeliveryStatus';
import type { DriverStatus } from 'src/constants/DriverStatus';
import type { DriverLivePositionVm } from 'src/types/DriverLivePositionVm';
import type { LiveOrdersOverviewVm } from 'src/types/LiveOrdersOverviewVm';
import type { LiveRestaurantVm } from 'src/types/LiveRestaurantVm';

export const liveDrivers = createSlice<LiveDriversState, Actions>({
    name: 'liveDrivers',
    initialState: {
        driverInfo: undefined,
        showDriverInfo: false,
        restaurantInfo: undefined,
        showRestaurantInfo: false,
        ordersOverview: undefined,
        driversOverview: undefined,
        deliveryStatus: undefined,
        driverStatus: undefined,
    },
    reducers: {
        setDriverInfo: (state: LiveDriversState, { payload: driverInfo }: { payload: DriverLivePositionVm }) => {
            state.driverInfo = driverInfo;
            state.showDriverInfo = true;
            state.showRestaurantInfo = false;
        },
        clearDriverInfo: (state: LiveDriversState) => {
            state.driverInfo = undefined;
            state.showDriverInfo = false;
        },
        setRestaurantInfo: (state: LiveDriversState, { payload: restaurantInfo }: { payload: LiveRestaurantVm }) => {
            state.restaurantInfo = restaurantInfo;
            state.showRestaurantInfo = true;
            state.showDriverInfo = false;
        },
        clearRestaurantInfo: (state: LiveDriversState) => {
            state.restaurantInfo = undefined;
            state.showRestaurantInfo = false;
        },
        setOrdersOverview: (state: LiveDriversState, { payload: overview }: { payload: LiveOrdersOverviewVm }) => {
            state.ordersOverview = overview;
        },
        setDriversOverview: (state: LiveDriversState, { payload: overview }: { payload: DriversOverview }) => {
            state.driversOverview = overview;
        },
        setDeliveryStatus: (state: LiveDriversState, { payload: status }: { payload: Array<DeliveryStatus> }) => {
            state.deliveryStatus = status;
        },
        setDriverStatus: (state: LiveDriversState, { payload: status }: { payload: DriverStatus }) => {
            state.driverStatus = status;
        },
    },
});

export type LiveDriversState = {
    driverInfo?: DriverLivePositionVm;
    showDriverInfo: boolean;
    restaurantInfo?: LiveRestaurantVm;
    showRestaurantInfo: boolean;
    ordersOverview?: LiveOrdersOverviewVm;
    driversOverview?: DriversOverview;
    deliveryStatus?: Array<DeliveryStatus>;
    driverStatus?: DriverStatus;
};

type Actions = {
    setDriverInfo: any;
    clearDriverInfo: any;
    setRestaurantInfo: any;
    clearRestaurantInfo: any;
    setOrdersOverview: any;
    setDeliveryStatus: any;
    setDriverStatus: any;
    setDriversOverview: any;
};

type DriversOverview = {
    activeDrivers: number;
    driversWithoutOrder: number;
    driversWithOrder: number;
};
