import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableContainer from '@material-ui/core/TableContainer';
import * as React from 'react';
import type { RestaurantBillingUyVm } from 'src/api/letseatadmin/restaurantBillingUy/getRestaurantBillingUyApi';
import { TableHeaderCalculationUy } from 'src/scenes/letseatadmin/restaurantBillingUy/TableHeaderCalculationUy';
import { TableRowCalculationUy } from 'src/scenes/letseatadmin/restaurantBillingUy/TableRowCalculationUy';

export function ToDepositCalculationUy({ restaurantBillingUyVm }: Props): React.ReactElement {
    const classes = useStyles();

    return (
        <TableContainer component={Paper}>
            <TableHeaderCalculationUy name={`To Deposit Calculation`} />
            <Table className={classes.table} size='small'>
                <TableBody>
                    <TableRowCalculationUy name='Web Card' amount={restaurantBillingUyVm.webCard} />
                    <TableRowCalculationUy name='Web Card Debit' amount={restaurantBillingUyVm.webCardDebit} />
                    <TableRowCalculationUy name='Web Card Credit' amount={restaurantBillingUyVm.webCardCredit} />
                    <TableRowCalculationUy name='White Label App Card' amount={restaurantBillingUyVm.whiteLabelAppCard} />
                    <TableRowCalculationUy name='White Label App Card Debit' amount={restaurantBillingUyVm.whiteLabelAppCardDebit} />
                    <TableRowCalculationUy name='White Label App Card Credit' amount={restaurantBillingUyVm.whiteLabelAppCardCredit} />
                    <TableRowCalculationUy name='Marketplace App Card' amount={restaurantBillingUyVm.marketplaceAppCard} />
                    <TableRowCalculationUy name='Marketplace App Card Debit' amount={restaurantBillingUyVm.marketplaceAppCardDebit} />
                    <TableRowCalculationUy name='Marketplace App Card Credit' amount={restaurantBillingUyVm.marketplaceAppCardCredit} />
                    <TableRowCalculationUy name='Payment Link' amount={restaurantBillingUyVm.paymentLink} />
                    <TableRowCalculationUy name='Payment Link Debit' amount={restaurantBillingUyVm.paymentLinkDebit} />
                    <TableRowCalculationUy name='Payment Link Credit' amount={restaurantBillingUyVm.paymentLinkCredit} />
                    <TableRowCalculationUy minus name='Restaurant Promo Code Cost App' amount={restaurantBillingUyVm.restaurantPromoCodeCostApp} />
                    <TableRowCalculationUy name='Restaurant Promo Code Balance' amount={restaurantBillingUyVm.restaurantPromoCodeBalance} />
                    <TableRowCalculationUy name='Rappi Cargo Web Card' amount={restaurantBillingUyVm.rappiCargoWebCard} />
                    <TableRowCalculationUy name='Rappi Cargo Web Card Debit' amount={restaurantBillingUyVm.rappiCargoWebCardDebit} />
                    <TableRowCalculationUy name='Rappi Cargo Web Card Credit' amount={restaurantBillingUyVm.rappiCargoWebCardCredit} />
                    <TableRowCalculationUy name='Rappi Cargo Web Cash' amount={restaurantBillingUyVm.rappiCargoWebCash} />
                    <TableRowCalculationUy name='Rappi Cargo App Card' amount={restaurantBillingUyVm.rappiCargoAppCard} />
                    <TableRowCalculationUy name='Rappi Cargo App Card Debit' amount={restaurantBillingUyVm.rappiCargoAppCardDebit} />
                    <TableRowCalculationUy name='Rappi Cargo App Card Credit' amount={restaurantBillingUyVm.rappiCargoAppCardCredit} />
                    <TableRowCalculationUy name='Rappi Cargo App Cash' amount={restaurantBillingUyVm.rappiCargoAppCash} />
                    <TableRowCalculationUy name='Rappi Cargo Manual Order' amount={restaurantBillingUyVm.rappiCargoManualOrder} />
                    <TableRowCalculationUy name='Rappi Cargo Pos Card' amount={restaurantBillingUyVm.rappiCargoPosCard} />
                    <TableRowCalculationUy name='Rappi Cargo Pos Cash' amount={restaurantBillingUyVm.rappiCargoPosCash} />
                    <TableRowCalculationUy name='Uber Daas Web Card' amount={restaurantBillingUyVm.uberDaasWebCard} />
                    <TableRowCalculationUy name='Uber Daas Web Card Debit' amount={restaurantBillingUyVm.uberDaasWebCardDebit} />
                    <TableRowCalculationUy name='Uber Daas Web Card Credit' amount={restaurantBillingUyVm.uberDaasWebCardCredit} />
                    <TableRowCalculationUy name='Uber Daas Web Cash' amount={restaurantBillingUyVm.uberDaasWebCash} />
                    <TableRowCalculationUy name='Uber Daas App Card' amount={restaurantBillingUyVm.uberDaasAppCard} />
                    <TableRowCalculationUy name='Uber Daas App Card Debit' amount={restaurantBillingUyVm.uberDaasAppCardDebit} />
                    <TableRowCalculationUy name='Uber Daas App Card Credit' amount={restaurantBillingUyVm.uberDaasAppCardCredit} />
                    <TableRowCalculationUy name='Uber Daas App Cash' amount={restaurantBillingUyVm.uberDaasAppCash} />
                    <TableRowCalculationUy name='Uber Daas Manual Order' amount={restaurantBillingUyVm.uberDaasManualOrder} />
                    <TableRowCalculationUy name='Uber Daas Pos Card' amount={restaurantBillingUyVm.uberDaasPosCard} />
                    <TableRowCalculationUy name='Uber Daas Pos Cash' amount={restaurantBillingUyVm.uberDaasPosCash} />
                    <TableRowCalculationUy minus name='Marketplace Service Fee Cash' amount={restaurantBillingUyVm.marketplaceServiceFeeCash} />
                    <TableRowCalculationUy minus name='Cashback' amount={restaurantBillingUyVm.cashback} />
                    <TableRowCalculationUy minus name='Iva' amount={restaurantBillingUyVm.iva} />
                    <TableRowCalculationUy minus name='Commission' amount={restaurantBillingUyVm.commission} />
                    <TableRowCalculationUy minus name='Subscription Pay Per Use Payment Invoiced' amount={restaurantBillingUyVm.subscriptionPayPerUsePaymentInvoice} />
                    <TableRowCalculationUy minus name='Manual Earnings Invoiced' amount={restaurantBillingUyVm.manualEarningInvoice} />
                    <TableRowCalculationUy name='To Deposit' amount={restaurantBillingUyVm.deposit} />
                </TableBody>
            </Table>
        </TableContainer>
    );
}

type Props = {
    restaurantBillingUyVm: RestaurantBillingUyVm;
};

const useStyles = makeStyles({
    table: {},
});
