import { useState } from 'react';
import * as React from 'react';
import { Button } from 'src/components/Button';
import { DeliveryOrdersMapDialog } from 'src/components/dialog/order/DeliveryOrdersMapDialog';
import { Tooltip } from 'src/components/Tooltip';
import { SECONDS } from 'src/constants/TimeUnit';
import { translate } from 'src/i18n/translate';
import { MapPinIcon } from 'src/icons/MapPinIcon';
import type { OrderVm } from 'src/types/OrderVm';
import { isDeliveryOrder } from 'src/utils/order/isDeliveryOrder';
import { useSelector } from 'src/utils/react/useSelector';
import { useIsLargeScreen } from 'src/utils/react/window/useIsLargeScreen';

export function DeliveryOrdersMapButton(): React.ReactElement {
    const isLargeScreen = useIsLargeScreen();

    const [deliveryOrderMapDialogState, setDeliveryOrderMapDialogState] = useState({ open: false });
    const [tooltipState, setTooltipState] = useState({ open: false, message: '' });

    const currentOrders = useSelector((state) => state.app2.currentOrders);

    const deliveryOrders: Array<OrderVm> = currentOrders.filter((order) => isDeliveryOrder(order.orderType));

    const openDeliveryOrdersMapDialog = () => {
        if (!deliveryOrders.length) {
            setTooltipState({ open: true, message: translate('There are no delivery orders') });
            setTimeout(() => {
                setTooltipState({ open: false, message: '' });
            }, 3 * SECONDS);
            return;
        }
        setDeliveryOrderMapDialogState({ open: true });
    };

    const closeDeliveryOrdersMapDialog = () => setDeliveryOrderMapDialogState({ open: false });

    return (
        <div>
            <DeliveryOrdersMapDialog open={deliveryOrderMapDialogState.open} onClose={closeDeliveryOrdersMapDialog} />
            <Tooltip forceOpen={tooltipState.open} text={tooltipState.message} position={'bottom'}>
                <Button secondary onClick={openDeliveryOrdersMapDialog}>
                    {isLargeScreen && translate('Map')}
                    <MapPinIcon title={translate('Map')} />
                </Button>
            </Tooltip>
        </div>
    );
}
