import { makeStyles } from '@material-ui/core';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import UpdateIcon from '@material-ui/icons/Update';
import { DatePicker } from '@material-ui/pickers';
import { Text } from '@pidedirecto/ui';
import moment from 'moment';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { getSurveysApi } from 'src/api/letseatmanager/survey/getSurveysApi';
import { Button } from 'src/components/Button';
import { SecuredAndSubscribedPage } from 'src/components/page/SecuredAndSubscribedPage';
import { Qr } from 'src/components/Qr';
import { UpdatingContentProgress } from 'src/components/UpdatingContentProgress';
import { MomentFormats } from 'src/constants/MomentFormat';
import { RolePermissions } from 'src/constants/RolePermission';
import { translate } from 'src/i18n/translate';
import RatingDetails from 'src/scenes/letseatmanager/surveys/RatingDetails';
import SurveysTable from 'src/scenes/letseatmanager/surveys/SurveysTable';
import { alertKnownErrorOrSomethingWentWrong } from 'src/utils/alert/alertKnownErrorOrSomethingWentWrong';
import { useSelector } from 'src/utils/react/useSelector';
import { getDirectoCountryUrl } from 'src/utils/url/getDirectoCountryUrl';

export function SurveysPage(): React.ReactElement {
    const classes = useStyles();

    const [loading, setLoading] = useState(false);
    const [from, setFrom] = useState(moment().format(MomentFormats.ISO_DATE));
    const [to, setTo] = useState(moment().format(MomentFormats.ISO_DATE));
    const [vm, setVm] = useState({ surveys: [], rating: {}, recommendationRating: {} } as any);
    const [reload, setReload] = useState(false);

    const restaurantIds = useSelector((state) => state.app.restaurantIds);
    const restaurant = useSelector((state) => state.app.restaurant);

    const restaurantSurveyUrl = `${restaurant.urlSubdomain}.${getDirectoCountryUrl(restaurant.country)}/${restaurant.urlPathname}/survey`;

    useEffect(() => {
        load();
    }, []);

    useEffect(() => {
        if (reload === true) {
            setReload(false);
            load();
        }
    }, [reload]);

    const load = async () => {
        setLoading(true);
        const response = await getSurveysApi({
            restaurantIds,
            from,
            to,
        });
        if (!response.ok) {
            setLoading(false);
            alertKnownErrorOrSomethingWentWrong(response);
            return;
        }
        setLoading(false);
        setVm(response.data);
    };

    return (
        <SecuredAndSubscribedPage title={translate('Surveys')} rolePermission={RolePermissions.SURVEYS_PAGE} className={(classes as any).container}>
            <Grid container direction='row' spacing={3}>
                <Grid item container xs={12} md={4} direction='column' spacing={6}>
                    <Grid item className={classes.cardContainer}>
                        <Card className={classes.card} elevation={0}>
                            <CardContent>
                                <div className={classes.inputContainer}>
                                    <DatePicker
                                        variant='inline'
                                        label={translate('From')}
                                        value={from}
                                        onChange={(date: any) => {
                                            setFrom(date.format('YYYY-MM-DD'));
                                            setReload(true);
                                        }}
                                        format='ll'
                                        autoOk
                                    />
                                    <DatePicker
                                        variant='inline'
                                        label={translate('To')}
                                        value={to}
                                        onChange={(date: any) => {
                                            setTo(date.format('YYYY-MM-DD'));
                                            setReload(true);
                                        }}
                                        format='ll'
                                        autoOk
                                    />
                                </div>
                                <Grid container direction='column' alignItems='center'>
                                    <Button primary classes={{ button: classes.button }} type='submit' disabled={loading} onClick={load}>
                                        <UpdateIcon />
                                        {translate('Refresh')}
                                    </Button>
                                </Grid>
                            </CardContent>
                        </Card>
                    </Grid>
                    <Grid item className={classes.cardContainer}>
                        <Grid className={`${classes.card} ${classes.qr}`}>
                            <Qr code={restaurantSurveyUrl} size={180} hasBorder>
                                <Text className={classes.textChildren} variant='subtitle'>
                                    {translate('Your customer’s opinion is very valuable')}
                                </Text>
                                <Text size={'small'} variant='paragraph'>
                                    {translate('Download and share with them this QR, with which they can immediately access the satisfaction survey.')}
                                </Text>
                            </Qr>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12} md={8}>
                    <UpdatingContentProgress loading={loading} />
                    <Paper elevation={0} className={classes.paper}>
                        <RatingDetails vm={vm} />
                    </Paper>
                </Grid>

                <Grid item xs={12}>
                    <SurveysTable surveys={vm.surveys} loading={loading} onClickRefresh={load} />
                </Grid>
            </Grid>
        </SecuredAndSubscribedPage>
    );
}

const useStyles = makeStyles((theme) => ({
    paper: {
        border: `1px solid ${theme.palette.secondary.dark}`,
        [theme.breakpoints.down('sm')]: {
            width: '90%',
            margin: '0 auto',
        },
    },
    cardContainer: {
        display: 'flex',
        justifyContent: 'center',
        [theme.breakpoints.down('sm')]: {
            marginTop: 20,
        },
    },
    card: {
        width: 420,
        height: 'fit-content',
        border: `1px solid ${theme.palette.secondary.dark}`,
        [theme.breakpoints.down('md')]: {
            width: '90%',
        },
    },
    qr: {
        padding: 16,
        borderRadius: 4,
    },
    textChildren: {
        marginBottom: 20,
    },
    button: {
        width: '100%',
    },
    inputContainer: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        marginBottom: 20,
        '& .MuiInputBase-root': {
            fontFamily: `${theme.typography.regular} !important`,
            fontSize: 14,
            backgroundColor: theme.palette.secondary.light,
            borderRadius: 5,
            padding: '5px 0 5px 15px',
        },
        '& .MuiInputBase-root::before': {
            display: 'none',
        },
        '& .MuiInputBase-root::after': {
            display: 'none',
        },
        '& .MuiFormControl-root:first-child': {
            marginRight: 10,
        },
        '& label': {
            fontFamily: theme.typography.regular,
            fontSize: 15,
            color: theme.palette.primary.main,
        },
    },
}));
