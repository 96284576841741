import { letseatmanagerApiMethod } from 'src/api/letseatmanagerApi';
import type { ApiSauceResponse } from 'src/api/types/ApiSauceResponse';
import { RestaurantId } from 'src/types/Id';
import { MenuCategoryFamilyVm } from 'src/types/MenuCategoryFamilyVm';

export async function findMenuCategoryFamiliesApi(request: FindMenuCategoryFamiliesApiRequest): ApiSauceResponse<Array<MenuCategoryFamilyVm>> {
    return letseatmanagerApiMethod('menuCategoryFamily/findMenuCategoryFamiliesApi', request);
}

export type FindMenuCategoryFamiliesApiRequest = {
    restaurantId: RestaurantId;
};
