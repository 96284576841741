import { makeStyles } from '@material-ui/core';
import * as React from 'react';
import { SelectItemsDialog } from 'src/components/dialog/SelectItemsDialog';
import { translate } from 'src/i18n/translate';
import { useModifierGroups } from 'src/services/modifierGroup/useModifierGroups';
import type { MenuItemId, ModifierGroupId } from 'src/types/Id';
import { ModifierGroupVm } from 'src/types/ModifierGroupVm';

export function SelectModifierGroupsDialog({ open, defaultSelectedModifierGroups, onClose, onSuccess, excludeModifierGroupsByMenuItemId }: Props): React.ReactElement {
    const classes = useStyles();

    const { modifierGroups } = useModifierGroups();

    const filteredModifierGroups = modifierGroups.filter((modifierGroup) => !modifierGroup.modifiers.map((modifier) => modifier.menuItemId).includes(excludeModifierGroupsByMenuItemId!));

    const handleDialogSuccess = (modifierGroupIds: any) => {
        onSuccess?.(modifierGroupIds);
    };

    const items =
        filteredModifierGroups?.map((modifierGroup: ModifierGroupVm) => ({
            value: modifierGroup.modifierGroupId,
            searchableBy: modifierGroup.name,
            item: modifierGroup,
        })) ?? [];

    return (
        <SelectItemsDialog
            open={open}
            title={translate('Select your modifier groups')}
            items={items}
            defaultSelectedItems={defaultSelectedModifierGroups}
            renderItem={({ item: modifierGroup }: { item: ModifierGroupVm }) => (
                <div className={classes.modifierGroupContainer}>
                    <span className={classes.text}>{modifierGroup.name}</span>
                </div>
            )}
            onClose={onClose}
            onSuccess={handleDialogSuccess}
        />
    );
}

const useStyles = makeStyles((theme) => ({
    modifierGroupContainer: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        width: '100%',
    },
    text: {
        fontFamily: theme.typography.regular,
        fontSize: 15,
    },
}));

type Props = {
    open: boolean;
    defaultSelectedModifierGroups?: Array<ModifierGroupId>;
    onClose: any;
    onSuccess?: any;
    excludeModifierGroupsByMenuItemId?: MenuItemId;
};
