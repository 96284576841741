import { makeStyles } from '@material-ui/core';
import { Button } from '@pidedirecto/ui';
import { ScreenSizes } from '@pidedirecto/ui/constants';
import { useIsScreenSize } from '@pidedirecto/ui/hooks';
import { CrossIcon } from '@pidedirecto/ui/icons';
import * as React from 'react';
import { translate } from 'src/i18n/translate';
import { posReducer } from 'src/reducers/posReducer';
import { AddPosCartModifiersButton } from 'src/scenes/letseatmanager/pos/posAddMenuItem/AddPosCartModifiersButton';
import { PosCartModifiers } from 'src/scenes/letseatmanager/pos/posAddMenuItem/PosCartModifiers';
import { classNames } from 'src/utils/react/classNames';
import { useAction } from 'src/utils/react/useAction';

export function PosCartModifiersCard(): React.ReactElement | null {
    const classes = useStyles();

    const isSmallScreen = useIsScreenSize(ScreenSizes.SMALL_SCREEN);
    const closeAddMenuItem = useAction(posReducer.actions.closeAddMenuItem);

    if (isSmallScreen) return null;

    return (
        <div className={classNames(classes.fixedContainer)}>
            <div className={classes.rightContainer}>
                <div className={classes.articlesTitleContainer}>
                    <h2 className={classes.articlesTitle}>{translate('Articles')}</h2>
                    <Button classes={{ button: classes.closeIconButton }} variant={'icon'} onClick={closeAddMenuItem}>
                        <CrossIcon height={16} width={16} />
                    </Button>
                </div>
                <PosCartModifiers />
                <AddPosCartModifiersButton />
            </div>
        </div>
    );
}

const useStyles = makeStyles((theme) => ({
    rightContainer: {
        width: '100%',
        height: '94%',
        display: 'flex',
        flexDirection: 'column',
        flexShrink: 0,
        borderBottom: 0,
        backgroundColor: theme.palette.surface.primary,
        borderRadius: 16,
        boxShadow: '0px 7px 8px rgba(0, 0, 0, 0.25)',
        padding: '30px 24px 30px 24px',
        [theme.breakpoints.down('sm')]: {
            boxShadow: 'none',
            width: '100%',
            height: '90vh',
            backgroundColor: 'transparent',
            display: 'none',
        },
    },
    fixedContainer: {
        position: 'fixed',
        top: 190,
        right: 70,
        width: '26%',
        height: '76%',
        [theme.breakpoints.down('sm')]: { display: 'none' },
    },
    articlesTitle: {
        fontFamily: theme.typography.medium,
        fontSize: 20,
        color: theme.palette.text.primary,
        margin: 0,
    },
    articlesTitleContainer: {
        width: '100%',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
    },
    closeIconButton: {
        color: theme.palette.icons.primary,
    },
}));
