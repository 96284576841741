import { InputBase, InputLabel, makeStyles } from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';
import InputAdornment from '@material-ui/core/InputAdornment';
import useAutocomplete from '@material-ui/lab/useAutocomplete';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { throttle } from 'throttle-debounce';
import { findRestaurantCustomersOptionsApi } from 'src/api/letseatmanager/restaurantCustomer/findRestaurantCustomersOptionsApi';
import { translate } from 'src/i18n/translate';
import type { RestaurantCustomerVm } from 'src/types/RestaurantCustomerVm';
import { alertKnownErrorOrSomethingWentWrong } from 'src/utils/alert/alertKnownErrorOrSomethingWentWrong';
import { isValidPhoneNumber } from 'src/utils/is/isValidPhoneNumber';
import { useSelector } from 'src/utils/react/useSelector';

export function PhoneNumberCustomerAutoComplete({ name, label, placeholder, onSelect, required, defaultValue, onCustomersChange, onChange, value }: Props): React.ReactElement {
    const classes = useStyles();

    let currentOptionSelected: RestaurantCustomerVm | null;

    const [options, setOptions] = useState<Array<RestaurantCustomerVm>>([]);
    const [loading, setLoading] = useState(false);
    const [inputValue, setInputValue] = useState<string>();
    const [error, setError] = useState<{ showError: boolean; errorMessage: string | undefined }>({ showError: false, errorMessage: undefined });

    const restaurantId = useSelector((state) => state.app.restaurantId);

    const { getRootProps, getInputProps, getListboxProps, getOptionProps, groupedOptions } = useAutocomplete({
        id: 'use-autocomplete-customer',
        options,
        getOptionLabel: (option) => option.mobileNumber,
        onChange: () => {
            if (currentOptionSelected) handleItem(currentOptionSelected);
        },
        onHighlightChange: (e, option) => {
            currentOptionSelected = option;
        },
    });

    const autocompleteSearch = (value: any) => fetch(value);

    const fetch = async (value: any) => {
        setLoading(true);
        const response = await findRestaurantCustomersOptionsApi({ mobileNumber: value, restaurantId });
        setLoading(false);
        if (!response.ok) return alertKnownErrorOrSomethingWentWrong(response);
        setOptions(response.data);

        onCustomersChange?.(response.data);
    };

    const handleItem = (item: RestaurantCustomerVm) => {
        onSelect(item);
        setInputValue(item.mobileNumber);
        onChange?.(item.mobileNumber);

        setError(validateNumber(item.mobileNumber) ?? { ...error, showError: false });
    };

    const validateNumber = (value: string) => (!isValidPhoneNumber(value) ? { showError: true, errorMessage: translate('are you sure this is a correct phone number?') } : undefined);

    const handleChange = (event: any) => {
        const mobileNumber = event.target.value;
        setInputValue(mobileNumber);
        onChange?.(mobileNumber);
        if (!mobileNumber?.length) onCustomersChange?.();
        setError(validateNumber(mobileNumber) ?? { ...error, showError: false });
    };

    useEffect(() => {
        if (!!inputValue?.length) {
            autocompleteSearchThrottled(inputValue);
        }
    }, [inputValue]);

    useEffect(() => {
        if (defaultValue) {
            setInputValue(defaultValue);
            onChange?.(defaultValue);
        }
    }, [defaultValue]);

    const autocompleteSearchThrottled = throttle(1000, autocompleteSearch);

    return (
        <div style={{ position: 'relative' }}>
            <div {...getRootProps()}>
                <InputLabel htmlFor={(getInputProps() as any).id} classes={{ root: classes.label, error: (classes as any).labelError }}>
                    {label}
                </InputLabel>
                <InputBase
                    {...getInputProps()}
                    type='search'
                    name={name}
                    classes={{ root: classes.input, error: (classes as any).inputError, focused: classes.inputFocused }}
                    value={value || inputValue}
                    onChange={handleChange}
                    required={required}
                    placeholder={placeholder}
                    /* @ts-ignore */
                    InputProps={{
                        endAdornment: loading && (
                            <InputAdornment position='end'>
                                <CircularProgress size={20} />
                            </InputAdornment>
                        ),
                    }}
                    inputProps={{ autoComplete: 'off' }}
                />
            </div>
            {groupedOptions.length > 0 ? (
                <ul role={'listbox'} className={classes.listbox} {...getListboxProps()}>
                    {groupedOptions.map((option: RestaurantCustomerVm, index: number) => (
                        <li {...getOptionProps({ option, index })} onClick={() => handleItem(option)}>
                            <div className={classes.optionContainer}>
                                <span>{option.name}</span>
                                <span>{option.mobileNumber}</span>
                            </div>
                        </li>
                    ))}
                </ul>
            ) : null}
            {error.showError && <span className={classes.errorMessage}>{error.errorMessage} </span>}
        </div>
    );
}

const useStyles = makeStyles((theme) => ({
    label: {
        fontFamily: theme.typography.light,
        color: theme.palette.text.primary,
        fontSize: 14,
        paddingBottom: 5,
    },
    errorMessage: {
        fontFamily: theme.typography.regular,
        fontSize: 12,
        color: theme.palette.text.danger,
    },
    input: {
        fontSize: 12,
        height: '42px',
        width: '100%',
        borderRadius: 4,
        padding: 15,
        boxSizing: 'border-box',
        color: theme.palette.text.primary,
        fontFamily: theme.typography.light,
        border: `1px solid ${theme.palette.border.primary}`,
        backgroundColor: 'transparent',
        outline: 0,
    },
    inputFocused: {
        outline: `2px solid ${theme.palette.surface.brandSecondary}`,
    },
    listbox: {
        fontFamily: theme.typography.regular,
        fontSize: 12,
        color: theme.palette.text.primary,
        boxShadow: '0px 4px 4px rgb(0,0,0,0.25)',
        width: 250,
        margin: 0,
        padding: 0,
        zIndex: 3,
        position: 'absolute',
        listStyle: 'none',
        backgroundColor: theme.palette.surface.primary,
        overflow: 'auto',
        maxHeight: '40vh',
        border: '1px solid rgba(0,0,0,.25)',
        '& li[data-focus="true"]': {
            backgroundColor: theme.palette.surface.brand,
            cursor: 'pointer',
        },
        '& li:active': {
            backgroundColor: theme.palette.surface.brand,
        },
    },
    optionContainer: {
        display: 'flex',
        flexDirection: 'row',
        width: '100%',
        minHeight: 60,
        alignItems: 'center',
        padding: '5px 10px',
        justifyContent: 'space-between',
    },
}));

type Props = {
    name: string;
    label?: string;
    value?: string;
    placeholder?: string;
    defaultValue?: string;
    required?: boolean;
    onSelect: any;
    onChange?: any;
    onCustomersChange?: any;
};
