import { useState } from 'react';
import * as React from 'react';
import { removeAppUsedAtThePosBusinessDayReportApi } from 'src/api/letseatmanager/restaurant/removeAppUsedAtThePosBusinessDayReportApi';
import { RemoveDialog } from 'src/components/dialog/RemoveDialog';
import type { App } from 'src/constants/App';
import { translate } from 'src/i18n/translate';
import { alertKnownErrorOrSomethingWentWrong } from 'src/utils/alert/alertKnownErrorOrSomethingWentWrong';
import { useSelector } from 'src/utils/react/useSelector';

export function RemoveAppUsedAtThePosBusinessDayReportDialog({ open, app, onClose, onSuccess }: Props): React.ReactElement {
    const [loading, setLoading] = useState(false);

    const restaurantId = useSelector((state) => state.app.restaurantId);

    const handleSubmit = async () => {
        if (!app) return;
        setLoading(true);
        const response = await removeAppUsedAtThePosBusinessDayReportApi({ restaurantId, channel: app });
        setLoading(false);
        if (!response.ok) {
            alertKnownErrorOrSomethingWentWrong(response);
            return;
        }
        onSuccess();
        onClose();
    };

    return (
        <RemoveDialog
            open={open}
            title={translate('Remove contemplated app')}
            subtitle={translate('Are you sure you want to remove the contempled app @contemplatedApp?', { contemplatedApp: app })}
            onSubmit={handleSubmit}
            onClose={onClose}
            disabled={loading}
        />
    );
}

type Props = {
    open: boolean;
    app?: App;
    onClose: any;
    onSuccess: any;
};
