import { makeStyles } from '@material-ui/core/styles';
import * as React from 'react';
import { memo, useCallback } from 'react';
import { Button } from 'src/components/Button';
import { translate } from 'src/i18n/translate';
import { usePosUserLockScreenHasClockedIn } from 'src/services/pos/PosUserLockScreen/usePosUserLockScreenHasClockedIn';
import { usePosUserLockScreenTryingClockIn } from 'src/services/pos/PosUserLockScreen/usePosUserLockScreenTryingClockIn';
import { usePosUserLockScreenTryingClockOut } from 'src/services/pos/PosUserLockScreen/usePosUserLockScreenTryingClockOut';
import { useSetHasClockedIn } from 'src/services/pos/PosUserLockScreen/useSetHasClockedIn';
import { useSetTryingClockIn } from 'src/services/pos/PosUserLockScreen/useSetTryingClockIn';
import { useSetTryingClockOut } from 'src/services/pos/PosUserLockScreen/useSetTryingClockOut';
import { classNames } from 'src/utils/react/classNames';
import { useSelector } from 'src/utils/react/useSelector';

export const PosUserLockscreenEmployeeClockInAndClockOut: React.ComponentType<any> = memo<any>(function PosUserLockscreenEmployeeClockInAndClockOut(): React.ReactElement | null {
    const classes = useStyles();

    const tryingClockIn = usePosUserLockScreenTryingClockIn();
    const tryingClockOut = usePosUserLockScreenTryingClockOut();
    const hasClockedIn = usePosUserLockScreenHasClockedIn();

    const clockInEmployeeDayShiftEnabled = useSelector((state) => state.app.restaurant?.clockInEmployeeDayShiftEnabled);

    const setTryingClockIn = useSetTryingClockIn();
    const setTryingClockOut = useSetTryingClockOut();
    const setHasClockedIn = useSetHasClockedIn();

    const handleTryClockIn = useCallback(() => {
        if (tryingClockOut) setTryingClockOut(false);
        if (hasClockedIn) setHasClockedIn(false);
        setTryingClockIn(!tryingClockIn);
    }, [hasClockedIn, tryingClockOut]);

    const handleTryClockOut = useCallback(() => {
        if (tryingClockIn) setTryingClockIn(false);
        if (hasClockedIn) setHasClockedIn(false);
        setTryingClockOut(!tryingClockOut);
    }, [tryingClockIn, hasClockedIn]);

    if (!clockInEmployeeDayShiftEnabled) return null;

    return (
        <>
            <div className={classes.buttons}>
                <Button classes={{ disabled: classes.disabled, button: classNames(classes.button, !tryingClockIn ? classes.unselectedButton : '') }} onClick={handleTryClockIn}>
                    {translate('Clock in')}
                </Button>
                <Button classes={{ disabled: classes.disabled, button: classNames(classes.button, !tryingClockOut ? classes.unselectedButton : '') }} onClick={handleTryClockOut}>
                    {translate('Clock out')}
                </Button>
            </div>
            {tryingClockIn && !hasClockedIn && <div className={classes.indications}>{translate('Type your pin to clock in')}</div>}
            {hasClockedIn && <div className={classes.indications}>{translate('You have already registered your entry')}</div>}
            {tryingClockOut && <div className={classes.indications}>{translate('Type your pin to clock out')}</div>}
        </>
    );
});

const useStyles = makeStyles((theme) => ({
    buttons: {
        display: 'flex',
        width: '90%',
        gap: 10,
        justifyContent: 'center',
        marginTop: 50,
        marginBottom: 30,
    },
    indications: {
        fontFamily: theme.typography.regular,
        color: '#FFF',
        fontSize: 14,
    },
    button: {
        maxWidth: 160,
        width: '100%',
        fontFamily: theme.typography.semiBold,
        [theme.breakpoints.up('md')]: {
            fontSize: 16,
            maxWidth: 'unset',
        },
    },
    disabled: {
        backgroundColor: 'transparent',
        color: '#FFF',
        '&:hover': {
            backgroundColor: 'transparent',
        },
    },
    unselectedButton: {
        backgroundColor: `${theme.palette.surface.brandContrast}15`,
        border: `1px solid ${theme.palette.surface.brandContrast}`,
    },
}));
