import { letseatmanagerApiMethod } from 'src/api/letseatmanagerApi';
import type { ApiSauceResponse } from 'src/api/types/ApiSauceResponse';
import type { QpayTerminalPaymentResponseResult } from 'src/constants/QpayTerminalPaymentResponseResults';
import type { QpayTerminalInstructionId } from 'src/types/Id';

/**
 * @deprecated
 */
export async function deprecatedMockQpayPaymentTerminalResponseApi(request: MockQpayPaymentTerminalResponseApiRequest): ApiSauceResponse<any> {
    return letseatmanagerApiMethod('mock/mockQpayPaymentTerminalResponseApi', request);
}

export type MockQpayPaymentTerminalResponseApiRequest = {
    terminalInstructionId: QpayTerminalInstructionId;
    qpayResponseResult: QpayTerminalPaymentResponseResult;
    amount: string;
};
