import { PrinterContents } from 'src/constants/PrinterContent';
import { PrintTypes } from 'src/constants/PrintType';
import { getDeprecatedPrintersInLocalStorage } from 'src/localStorage/getDeprecatedPrintersInLocalStorage';
import { createBuyOrderTicketPrint } from 'src/services/printer/prints/createBuyOrderTicketPrint';
import { printerDeprecatedToPrinter } from 'src/services/printer/utils/printerDeprecatedToPrinter';
import { usePrintContent } from 'src/services/printer/utils/usePrintContent';
import type { BuyOrderVm } from 'src/types/BuyOrderVm';
import { DeviceGroupId } from 'src/types/Id';
import { PrinterVm } from 'src/types/PrinterVm';
import { useSelector } from 'src/utils/react/useSelector';

export function usePrintBuyOrderTicket(): [(buyOrder: BuyOrderVm, forceToPrint?: boolean) => Promise<void>] {
    const newDeviceManagementEnabled = useSelector((state) => state.app.restaurant?.newDeviceManagementEnabled);
    const restaurant = useSelector((state) => state.app.restaurant);
    const deviceGroup = useSelector((state) => state.app.deviceGroup);
    const printersNewUI = useSelector((state) => state.app.printers);

    const printersOldUi = getDeprecatedPrintersInLocalStorage().map(printerDeprecatedToPrinter);

    const { printContent, printContents } = usePrintContent();

    if (newDeviceManagementEnabled) {
        var printers = printersNewUI?.filter((printer: PrinterVm) =>
            printer.deviceGroups?.some((deviceGroupToSearch: { deviceGroupId: DeviceGroupId; deviceGroupName: string }) => deviceGroupToSearch.deviceGroupId === deviceGroup?.deviceGroupId),
        );
    } else {
        var printers = printersOldUi;
    }

    const printBuyOrderTicket = async (buyOrder: BuyOrderVm, forceToPrint: undefined | boolean) => {
        if (printers.length === 0) {
            if (forceToPrint) alert('No active printers to select');
            return;
        } else if (printers.length === 1) {
            if (forceToPrint) {
                printContents([
                    {
                        printer: printers[0],
                        print: {
                            printType: PrintTypes.TICKET,
                            printerInstructions: createBuyOrderTicketPrint({ buyOrder, restaurant }),
                        },
                    },
                ]);
                return;
            }
        }

        const buyOrderTicketPrinters = printers?.filter((printer: PrinterVm) => {
            return printer.printerContent !== PrinterContents.COMMAND;
        });

        if (buyOrderTicketPrinters.length === 0) {
            if (forceToPrint)
                printContent({
                    printType: PrintTypes.TICKET,
                    printerInstructions: createBuyOrderTicketPrint({ buyOrder, restaurant }),
                });
            return;
        }

        const contentsToPrint = buyOrderTicketPrinters.map((printer) => ({
            printer: printer,
            print: {
                printType: PrintTypes.TICKET,
                printerInstructions: createBuyOrderTicketPrint({ buyOrder, restaurant }),
            },
        }));

        printContents(contentsToPrint);
    };

    return [printBuyOrderTicket];
}
