import { makeStyles } from '@material-ui/core';
import * as React from 'react';
import type { SurveysByDate } from 'src/api/letseatmanager/restaurantDashboard/getCustomerRestaurantStatisticsApi';
import { Table } from 'src/components/Table';
import { translate } from 'src/i18n/translate';
import { KpiCard } from 'src/scenes/letseatmanager/restaurantDashboard/KpiCard';
import { ReportSection } from 'src/scenes/letseatmanager/restaurantDashboard/ReportSection';

export function SurveysByDateTable({ loading, surveysByDateData }: Props): React.ReactElement | null {
    const classes = useStyles();

    const meanSurveyRating = surveysByDateData?.meanSurveyRating ?? 0;
    const mostCommonSurveyAnswer = surveysByDateData?.mostCommonSurveyAnswer ?? 0;
    const surveysByDate =
        surveysByDateData?.data?.map((itemMenu: SurveysByDate) => ({
            customerName: itemMenu.customerName,
            mobileNumber: itemMenu.mobileNumber,
            orderType: translate(itemMenu.orderType),
            rating: itemMenu.rating,
            recommendationRating: itemMenu.recommendationRating,
        })) || [];
    const hasSurveysByDate = surveysByDate.length > 0;

    const surveysByDateColumns = [
        {
            name: 'customerName',
            label: translate('Name'),
            options: {
                filter: true,
            },
        },
        {
            name: 'mobileNumber',
            label: translate('Mobile Number'),
            options: {
                filter: false,
            },
        },
        {
            name: 'orderType',
            label: translate('Order type'),
            options: {
                filter: false,
            },
        },
        {
            name: 'rating',
            label: translate('Rating'),
            options: {
                filter: false,
            },
        },
        {
            name: 'recommendationRating',
            label: translate('Recommendation'),
            options: {
                filter: false,
            },
        },
    ];

    if (!hasSurveysByDate) return null;

    return (
        <div>
            <ReportSection title={translate('Surveys by Date')} loading={loading}>
                <div className={classes.splitContainer}>
                    <div className={classes.sectionHeadKpi}>
                        <KpiCard
                            classes={{
                                container: classes.headingKpiContainer,
                                title: classes.headingKpiTitleKpi,
                                body: classes.headingKpiBody,
                                color: classes.headingKpiColor,
                                legend: classes.headingKpiLegendContainer,
                            }}
                            legend={meanSurveyRating}
                            title={translate('Best Rating')}
                        />
                        {mostCommonSurveyAnswer && (
                            <KpiCard
                                classes={{
                                    container: classes.headingKpiContainer,
                                    title: classes.headingKpiTitleKpi,
                                    body: classes.headingKpiBody,
                                    color: classes.headingKpiColor,
                                    legend: classes.headingKpiLegendContainer,
                                }}
                                legend={translate(`SurveyAnswers.${mostCommonSurveyAnswer}`)}
                                title={translate('Most common answer')}
                            />
                        )}
                    </div>
                    <div className={classes.container}>
                        <Table
                            className={(classes as any).table}
                            data={surveysByDate}
                            columns={surveysByDateColumns}
                            options={{
                                tableBodyMaxHeight: '400px',
                                responsive: 'standard',
                                selectableRows: 'none',
                                filter: false,
                                rowsPerPage: 100,
                                viewColumns: false,
                                print: false,
                            }}
                        />
                    </div>
                </div>
            </ReportSection>
        </div>
    );
}

const useStyles = makeStyles((theme) => ({
    container: {
        width: '100%',
    },
    splitContainer: {
        display: 'flex',
        flexWrap: 'wrap',
        alignItems: 'center',
        width: '100%',
        flexDirection: 'row',

        [theme.breakpoints.between('md', 'lg')]: {
            flexDirection: 'row-reverse',
            flexWrap: 'nowrap',
        },
    },
    sectionHeadKpi: {
        width: '100%',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        [theme.breakpoints.between('md', 'lg')]: {
            flexDirection: 'column',
            width: '20%',
        },
    },
    headingKpiContainer: {
        backgroundColor: 'transparent',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        paddingTop: '8px',
        margin: 5,
        textAlign: 'center',
        marginRight: '5%',
        marginBottom: '2%',
    },
    headingKpiTitleKpi: {
        fontFamily: theme.typography.regular,
        fontSize: 16,
        color: '#6D7B7F',
    },
    headingKpiBody: {
        fontSize: 28,
        fontFamily: theme.typography.semiBold,
    },
    headingKpiColor: {
        width: 12,
        height: 12,
        borderRadius: 2,
    },
    headingKpiLegendContainer: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        textAlign: 'center',
        width: '100%',
        [theme.breakpoints.between('sm', 'lg')]: {
            justifyContent: 'left',
            textAlign: 'left',
            marginLeft: 40,
        },
    },
}));

type Props = {
    loading: boolean;
    surveysByDateData: SurveysByDateDataType;
};

type SurveysByDateDataType = {
    data: Array<SurveysByDate>;
    mostCommonSurveyAnswer: string;
    meanSurveyRating: number;
};
