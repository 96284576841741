import { letseatmanagerApiMethod } from 'src/api/letseatmanagerApi';
import type { ApiSauceResponse } from 'src/api/types/ApiSauceResponse';
import { MenuCategoryFamilyId, type MenuItemId, type PrinterName, type RestaurantId } from 'src/types/Id';

export async function createMenuCategoryApi(request: CreateMenuCategoryApiRequest): ApiSauceResponse<void> {
    return letseatmanagerApiMethod('menuCategory/createMenuCategoryApi', request);
}

export type CreateMenuCategoryApiRequest = {
    restaurantId: RestaurantId;
    menuItemIds: Array<MenuItemId>;
    menuCategoryFamilyId?: MenuCategoryFamilyId;
    name: string;
    promotion?: boolean;
    imageUrl?: string;
    color?: string;
    hideInKitchenDisplayScreen?: boolean;
    printerNames?: Array<PrinterName>;
};
