import { makeStyles } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import { useState } from 'react';
import * as React from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import { Button } from 'src/components/Button';
import { Tooltip } from 'src/components/Tooltip';
import { translate } from 'src/i18n/translate';
import { CloseIcon } from 'src/icons/CloseIcon';
import { AddCustomDiscountCommentDialog } from 'src/scenes/letseatmanager/theRestaurant/pos/AddCustomDiscountCommentDialog';
import { RemoveCustomDiscountCommentDialog } from 'src/scenes/letseatmanager/theRestaurant/pos/RemoveCustomDiscountCommentDialog';
import { CustomDiscountComment } from 'src/types/Id';
import { translateCustomDiscountComment } from 'src/utils/translate/translateCustomDiscountComment';

export function DiscountCommentsSettings({ onSuccess }: Props): React.ReactElement {
    const { control } = useFormContext();
    const classes = useStyles();

    const customDiscountComments = useWatch<Array<CustomDiscountComment>>({ name: 'customDiscountComments', control });

    const [addDiscountCommentsDialogState, setAddDiscountCommentsDialogState] = useState(false);
    const [removeDiscountCommentsDialogState, setRemoveDiscountCommentsDialogState] = useState({ open: false, comment: undefined });

    const openRemoveDiscountCommentDialog = (state: { comment: any; open: boolean }) => setRemoveDiscountCommentsDialogState(state);
    const closeAddDiscountCommentDialog = () => setAddDiscountCommentsDialogState(false);
    const closeRemoveDiscountCommentDialog = () => setRemoveDiscountCommentsDialogState({ open: false, comment: undefined });

    return (
        <div>
            <AddCustomDiscountCommentDialog open={addDiscountCommentsDialogState} onClose={closeAddDiscountCommentDialog} onSuccess={onSuccess} />
            <RemoveCustomDiscountCommentDialog
                open={removeDiscountCommentsDialogState.open}
                onSuccess={onSuccess}
                comment={removeDiscountCommentsDialogState.comment}
                onClose={closeRemoveDiscountCommentDialog}
            />
            <div className={classes.section}>
                <h2 className={classes.subtitle}>
                    {translate('Custom Discount Comments')}
                    <Tooltip text={translate('Define the personalized comments for discounts in POS')} />
                </h2>
                <div className={classes.itemsContainer}>
                    {customDiscountComments?.map((discountComment: any, idx: any) => (
                        <div className={classes.item}>
                            {translateCustomDiscountComment(discountComment.comment)}
                            <Button icon onClick={() => openRemoveDiscountCommentDialog({ open: true, comment: discountComment.comment })} classes={{ button: classes.iconButton }}>
                                <CloseIcon size={14} />
                            </Button>
                        </div>
                    ))}
                </div>
                <Button outlined onClick={() => setAddDiscountCommentsDialogState(true)}>
                    <AddIcon />
                    {translate('Add Discount Comment')}
                </Button>
            </div>
        </div>
    );
}

const useStyles = makeStyles((theme) => ({
    subtitle: {
        fontFamily: theme.typography.medium,
        fontSize: 18,
        color: theme.palette.secondary.contrastText,
        margin: 0,
        display: 'flex',
        flexDirection: 'row',
    },
    section: {
        width: '100%',
        borderRadius: 5,
        border: `1px solid ${theme.palette.secondary.dark}`,
        display: 'flex',
        flexDirection: 'column',
        padding: 15,
        gap: 20,
    },
    itemsContainer: {
        display: 'flex',
        width: '100%',
        flexWrap: 'wrap',
        flexDirection: 'row',
        alignItems: 'center',
        gap: 10,
    },
    item: {
        fontFamily: theme.typography.medium,
        fontSize: 15,
        minWidth: 140,
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        border: '1px solid #676060B2',
        borderRadius: 6,
        padding: '5px 10px',
        gap: 5,
        height: '100%',
        '&:hover': {
            backgroundColor: '#D9D9D933',
        },
    },
    iconButton: {
        width: 'fit-content',
        height: 'fit-content',
        borderRadius: '50%',
        minHeight: 28,
    },
}));

type Props = {
    onSuccess: any;
};
