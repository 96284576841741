import { makeStyles } from '@material-ui/core';
import * as React from 'react';
import { useRef, useState } from 'react';
import { Button } from 'src/components/Button';
import { posReducer } from 'src/reducers/posReducer';
import { useAddCourse } from 'src/services/pos/useAddCourse';
import { CourseVm } from 'src/types/CourseVm';
import type { CourseId } from 'src/types/Id';
import { classNames } from 'src/utils/react/classNames';
import { useAction } from 'src/utils/react/useAction';
import { useSelector } from 'src/utils/react/useSelector';

export function CoursesCarrousel(): React.ReactElement | null {
    const classes = useStyles();
    const carouselRef = useRef<HTMLDivElement | null>(null);
    const [startX, setStartX] = useState(0);
    const { addCourse } = useAddCourse();

    const courseIdSelected = useSelector((state) => state.pos.courseIdSelected);
    const ongoingCourses = useSelector((state) => state.pos.ongoingCourses);
    const courses = useSelector((state) => state.pos.courses);
    const takeOrderByCourse = useSelector((state) => state.pos.takeOrderByCourse);
    const courseOrderDetailsAccordionOpened = useSelector((state) => state.pos.courseOrderDetailsAccordionOpened);

    const setCourseOrderDetailsAccordionOpened = useAction(posReducer.actions.setCourseOrderDetailsAccordionOpened);
    const setCourseIdSelected = useAction(posReducer.actions.setCourseIdSelected);

    const handleOnClick = (courseId: CourseId) => {
        if (courseIdSelected === courseId) return setCourseOrderDetailsAccordionOpened(!courseOrderDetailsAccordionOpened);
        const course = courses?.find((course: CourseVm) => course.courseId === courseId);

        if (!course) return;

        setCourseIdSelected(courseId);
        addCourse({
            courseId: course?.courseId,
            name: course?.name ?? '',
            orderItems: [],
        });
        setCourseOrderDetailsAccordionOpened(true);
    };

    const handleMouseDown = (e: any) => {
        if (carouselRef.current) {
            setStartX(e.clientX - carouselRef.current.offsetLeft);

            if (carouselRef.current) carouselRef.current.style.cursor = 'grabbing';
        }
    };

    const handleMouseMove = (e: any) => {
        if (!startX || !carouselRef.current) return;

        const scrollX = e.clientX - startX;
        carouselRef.current.scrollLeft = scrollX;
    };

    const handleMouseUp = () => {
        if (!carouselRef.current) return;

        carouselRef.current.style.cursor = 'grab';
        setStartX(0);
    };

    const getStyles = (courses: { cancelledItems: Array<never>; courseId: CourseId; name: string; orderItems: Array<never> }) => {
        if (courses.courseId === courseIdSelected) {
            return classNames(classes.carouselButton, classes.selectedCarouselButton);
        }

        return classes.carouselButton + ' ' + (classes as any).disabledCarouselButton;
    };

    const mappedCourses = courses?.map((course: CourseVm) => {
        const name = course.name?.toUpperCase();
        const shortName = name?.substring(0, 3);
        return { ...course, name: shortName, orderItems: [], cancelledItems: [] };
    });

    if (!takeOrderByCourse || !mappedCourses?.length) return null;

    return (
        <div className={classes.carouselContainer} onMouseDown={handleMouseDown} onMouseMove={handleMouseMove} onMouseUp={handleMouseUp} onMouseLeave={handleMouseUp}>
            <div ref={carouselRef} className={classes.carousel}>
                {mappedCourses?.map(
                    (
                        courses: {
                            cancelledItems: Array<never>;
                            courseId: CourseId;
                            name: string;
                            orderItems: Array<never>;
                        },
                        index: number,
                    ) => (
                        <Button key={index} secondary onClick={() => handleOnClick(courses.courseId)} classes={{ button: getStyles(courses) }}>
                            {courses?.name}
                        </Button>
                    ),
                )}
            </div>
        </div>
    );
}

const useStyles = makeStyles((theme) => ({
    carouselContainer: {
        width: '100%',
        overflow: 'hidden',
        cursor: 'grab',
        marginTop: '15px',
        marginBottom: '15px',
    },
    carousel: {
        display: 'flex',
        flexDirection: 'row',
        overflowX: 'auto',
        scrollSnapType: 'x mandatory',
        '-webkit-overflow-scrolling': 'touch',
        scrollbarWidth: 'none',
        '-ms-overflow-style': '-ms-autohiding-scrollbar',
        cursor: 'grab',
    },
    carouselButton: {
        width: '50px',
        height: '41px',
        marginRight: '10px !important',
        flexShrink: 0,
        scrollSnapAlign: 'start',
        cursor: 'pointer',
        color: theme.palette.primary.main,
        borderRadius: 4,
        border: '1.5px solid #06B7A2',
        '&:hover': {
            cursor: 'pointer',
            backgroundColor: theme.palette.primary.main,
            color: 'white',
            borderColor: theme.palette.primary.main,
        },
    },
    selectedCarouselButton: {
        backgroundColor: theme.palette.primary.main,
        color: 'white',
        borderColor: theme.palette.primary.main,
    },
}));
