import { FormSelect } from '@pidedirecto/ui/form';
import * as React from 'react';
import { translate } from 'src/i18n/translate';

export function FormSecondsToAcceptCustomAlertSelect({ name, label, placeholder, helperText, defaultValue, disabled, required, multiselectable }: Props): React.ReactElement {
    const options = [
        { value: '15', label: '15' },
        { value: '30', label: '30' },
        { value: '45', label: '45' },
        { value: '60', label: '60' },
        { value: '75', label: '75' },
        { value: '90', label: '90' },
    ];
    if (!required) {
        options.unshift({ label: '', value: '' as any });
    }

    return (
        <FormSelect
            name={name}
            label={label}
            placeholder={placeholder}
            helperText={helperText}
            defaultValue={defaultValue}
            options={options}
            disabled={disabled}
            required={{
                value: !!required,
                message: translate('This field is required'),
            }}
        />
    );
}

type Props = {
    name: string;
    label?: string;
    placeholder?: string;
    helperText?: string;
    defaultValue?: string | null | undefined;
    disabled?: boolean;
    required?: boolean;
    multiselectable?: boolean;
};
