import type { CompanyId } from 'src/types/Id';

export const KEY_NAME = 'companyOptions';

export function getCompanyOptionsInLocalStorage(): Array<CompanyOption> {
    const item = window.localStorage.getItem('companyOptions');
    return item ? JSON.parse(item) : [];
}

export type CompanyOption = {
    label: string;
    value: CompanyId;
};
