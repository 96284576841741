import { BigNumber } from 'bignumber.js';
import type { CurrencyFormatVm } from 'src/types/RestaurantVm';

export function formatAsNumber(value?: string | number | null, currencyFormat?: CurrencyFormatVm, customFractionDigits?: number): string {
    if (!value) {
        // TODO: Investigate if we should change this to number === undefined instead, would make more sense in case a number 0 is passed and we probably still want to print 0
        return '';
    }

    const fractionDigits = BigNumber(value).isInteger() ? 0 : (customFractionDigits ?? 2);
    const numberWithFormat = Intl.NumberFormat(currencyFormat?.locale ?? 'es-MX', {
        style: 'decimal',
        minimumFractionDigits: fractionDigits,
        maximumFractionDigits: fractionDigits,
    }).format(value as any); // TODO: Create a test that verifies that this it is still possible to format strings since flow seems to complain
    if (numberWithFormat.includes('.')) {
        const [ints, fracs] = numberWithFormat.split('.');
        const cleanFracs = fracs.split('').reverse().join('').replace(/^(0+)/g, '').split('').reverse().join('');
        return `${ints}.${cleanFracs}`;
    }

    return numberWithFormat;
}
