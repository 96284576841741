import { BigNumber } from 'bignumber.js';
import moment from 'moment';
import * as React from 'react';
import type { HourlyOrdersReport } from 'src/api/letseatmanager/restaurantDashboard/getIntegrationsPaymentReportApi';
import { HeatMapCanvas } from 'src/components/charts/HeatMapCanvas';
import { translate } from 'src/i18n/translate';
import { ReportSection } from 'src/scenes/letseatmanager/restaurantDashboard/ReportSection';

export function IntegrationsPaymentReportHeatMap({ heatmapReport, maxValue, loading }: Props): React.ReactElement {
    const heatMapData =
        heatmapReport.map((item, index) => ({
            id: `${moment().hour(parseInt(item.hour)).minutes(0).format('HH:mm')}`,
            data: [
                { x: translate('Monday'), y: BigNumber(item.monday).toNumber() },
                { x: translate('Tuesday'), y: BigNumber(item.tuesday).toNumber() },
                { x: translate('Wednesday'), y: BigNumber(item.wednesday).toNumber() },
                { x: translate('Thursday'), y: BigNumber(item.thursday).toNumber() },
                { x: translate('Friday'), y: BigNumber(item.friday).toNumber() },
                { x: translate('Saturday'), y: BigNumber(item.saturday).toNumber() },
                { x: translate('Sunday'), y: BigNumber(item.sunday).toNumber() },
            ],
        })) || [];

    return (
        <ReportSection title={translate('Sales per day and hour')} loading={loading}>
            <HeatMapCanvas data={heatMapData} maxValue={maxValue} />
        </ReportSection>
    );
}

type Props = {
    heatmapReport: Array<HourlyOrdersReport>;
    loading: boolean;
    maxValue: number;
};
