import { Button, Dialog, DialogActions } from '@pidedirecto/ui';
import { Form } from '@pidedirecto/ui/form';
import { useForm } from '@pidedirecto/ui/hooks';
import moment from 'moment/moment';
import * as React from 'react';
import { useState } from 'react';
import { getManagerUserTipSalesReportApi } from 'src/api/letseatmanager/cashRegister/getManagerUserTipSalesReportApi';
import { FormRestaurantManagerUserSelect } from 'src/components/form/FormRestaurantManagerUserSelect';
import { translate } from 'src/i18n/translate';
import { usePrintManagerUserTipSalesReport } from 'src/services/printer/usePrintManagerUserTipSalesReport';
import { alertKnownErrorOrSomethingWentWrong } from 'src/utils/alert/alertKnownErrorOrSomethingWentWrong';
import { useFormatDateToRestaurantTimeZone } from 'src/utils/react/useFormatDateToRestaurantTimeZone';
import { useSelector } from 'src/utils/react/useSelector';

export function SelectManagerUserReportDialog({ open, onClose }: Props): React.ReactElement {
    const printManagerUserTipSalesReport = usePrintManagerUserTipSalesReport();
    const formatDateToRestaurantTimeZone = useFormatDateToRestaurantTimeZone();
    const form = useForm();

    const [loading, setLoading] = useState(false);

    const period = useSelector((state) => state.app2.filterReportsState.period);

    const handlePrintManagerUserTipReport = async (form: any) => {
        setLoading(true);
        const response = await getManagerUserTipSalesReportApi({
            managerUserId: form.managerUserId,
            fromDate: moment(formatDateToRestaurantTimeZone(period?.from)).toISOString(),
            toDate: moment(formatDateToRestaurantTimeZone(period.to)).toISOString(),
        });
        setLoading(false);
        if (!response.ok) {
            alertKnownErrorOrSomethingWentWrong(response);
            return;
        }

        await printManagerUserTipSalesReport(response.data);
        onClose();
    };

    return (
        <Dialog open={open} title={translate('Select a user')} onClose={onClose}>
            <Form form={form} onSubmit={handlePrintManagerUserTipReport}>
                <FormRestaurantManagerUserSelect name={'managerUserId'} required label={translate('Users')} />
                <DialogActions>
                    <Button disabled={loading} variant={'secondary'} onClick={onClose}>
                        {translate('Cancel')}
                    </Button>
                    <Button disabled={loading} type={'submit'}>
                        {translate('Print')}
                    </Button>
                </DialogActions>
            </Form>
        </Dialog>
    );
}

type Props = {
    open: boolean;
    onClose: () => void;
};
