import { letseatadminApiMethod } from 'src/api/letseatadminApi';
import type { ApiSauceResponse } from 'src/api/types/ApiSauceResponse';
import type { ManagerUserId } from 'src/types/Id';
import type { ManagerUserVm } from 'src/types/ManagerUserVm';

export async function getManagerUserApi(request: GetManagerUserApiRequest): ApiSauceResponse<ManagerUserVm> {
    return letseatadminApiMethod('managerUser/getManagerUserApi', request);
}

export type GetManagerUserApiRequest = {
    managerUserId: ManagerUserId;
};
