import type { PromoCodeVm } from 'src/api/letseatmanager/types/PromoCodeVm';
import { letseatmanagerApiMethod } from 'src/api/letseatmanagerApi';
import type { ApiSauceResponse } from 'src/api/types/ApiSauceResponse';
import type { RestaurantId } from 'src/types/Id';

export async function getPosPromoCodeApi(request: GetPosPromoCodeApiRequest): ApiSauceResponse<PromoCodeVm> {
    return letseatmanagerApiMethod('promoCode/getPosPromoCodeApi', request);
}

type GetPosPromoCodeApiRequest = {
    restaurantId: RestaurantId;
    code: string;
};
