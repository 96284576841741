import { makeStyles } from '@material-ui/core/styles';
import { Button, DropDown, DropDownItem } from '@pidedirecto/ui';
import { ClosedEyeIcon, CopyIcon, EditIcon, EllipsisVerticalIcon, EyeIcon, NoPhotoIcon, TrashCanIcon } from '@pidedirecto/ui/icons';
import * as React from 'react';
import { useState } from 'react';
import { duplicateMenuItemApi } from 'src/api/letseatmanager/menuItem/duplicateMenuItemApi';
import { showMenuItemApi } from 'src/api/letseatmanager/menuItem/showMenuItemApi';
import { translate } from 'src/i18n/translate';
import { MenuCategoriesUsingMenuItemDropDown } from 'src/scenes/letseatmanager/menu/menuItem/MenuCategoriesUsingMenuItemDropDown';
import { MenusUsingMenuItemDropDown } from 'src/scenes/letseatmanager/menu/menuItem/MenusUsingMenuItemDropDown';
import { ModifierGroupsInheritedMenuItemDropDown } from 'src/scenes/letseatmanager/menu/menuItem/ModifierGroupsInheritedMenuItemDropDown';
import { useIsMenuEditionDisabled } from 'src/services/menu/useIsMenuEditionDisabled';
import { useLoadMenus } from 'src/services/menu/useLoadMenus';
import { useMenus } from 'src/services/menu/useMenus';
import { useMenuCategories } from 'src/services/menuCategory/useMenuCategories';
import { useLoadMenuItems } from 'src/services/menuItem/useLoadMenuItems';
import { useFormatAsRestaurantCurrencyNumber } from 'src/services/restaurant/useFormatAsRestaurantCurrencyNumber';
import type { ManagerMenuItemVm } from 'src/types/ManagerMenuItemVm';
import { alertKnownErrorOrSomethingWentWrong } from 'src/utils/alert/alertKnownErrorOrSomethingWentWrong';
import { transformImageUrl } from 'src/utils/image/transformImageUrl';
import { classNames } from 'src/utils/react/classNames';

export function MenuItemListItem({ menuItem, onEdit, onRemove, onHide, isProduct }: Props): React.ReactElement {
    const classes = useStyles();
    const isMenuEditionDisabled = useIsMenuEditionDisabled();
    const { menus } = useMenus();
    const { menuCategories } = useMenuCategories();
    const refreshMenuItems = useLoadMenuItems();
    const refreshMenus = useLoadMenus();
    const formatAsCurrencyNumber = useFormatAsRestaurantCurrencyNumber();

    const [loading, setLoading] = useState(false);

    const menusWithMenuItem = menus.filter((menu) => {
        return menu.menuCategoryIds.some((menuCategoryId) => {
            const menuCategory = menuCategories.find((menuCategory) => menuCategory.menuCategoryId === menuCategoryId);
            if (!menuCategory) return false;

            return menuCategory.menuItemIds.includes(menuItem.menuItemId);
        });
    });
    const menuCategoriesWithMenuItem = menuCategories.filter((menuCategory) => menuCategory.menuItemIds.includes(menuItem.menuItemId));
    const isMenuItemUsed = !!menusWithMenuItem.length || !!menuCategoriesWithMenuItem.length;

    const handleShowMenuItem = async () => {
        setLoading(true);
        const response = await showMenuItemApi({ menuItemId: menuItem.menuItemId });
        setLoading(false);
        if (!response.ok) {
            return alertKnownErrorOrSomethingWentWrong(response);
        }
        refreshMenuItems();
        refreshMenus();
    };

    const handleEditMenuItem = () => {
        onEdit?.(menuItem);
    };

    const handleDuplicateMenuItem = () => {
        duplicateMenuItem();
    };

    const handleRemoveMenuItem = () => {
        onRemove?.(menuItem);
    };

    const duplicateMenuItem = async () => {
        setLoading(true);
        const response = await duplicateMenuItemApi({ menuItemId: menuItem.menuItemId });
        setLoading(false);
        if (!response.ok) {
            return alertKnownErrorOrSomethingWentWrong(response);
        }
        refreshMenuItems();
        refreshMenus();
    };
    return (
        <div className={classNames(classes.menuItem, classes.isFullWidth)}>
            <div className={classes.menuItemInfoContainer}>
                <div style={{ width: '100%' }}>
                    <div className={classNames(classes.topContainer, classes.isFullWidth)}>
                        <div className={classes.imageContainer}>
                            {menuItem.imageUrl ? <img className={classes.image} alt='menuItem' src={transformImageUrl(menuItem.imageUrl, 320, 320)} /> : <NoPhotoIcon title={'no photo'} size={30} />}
                        </div>

                        <div className={classNames(classes.spaceBetween, classes.isFullWidth)}>
                            <p className={classes.semiBold}>{menuItem.name}</p>
                            {isProduct && <p className={classes.semiBold}>{formatAsCurrencyNumber(menuItem.price)}</p>}
                            {!isProduct && <p className={classes.semiBold}>{formatAsCurrencyNumber(menuItem.modifierPrice ?? menuItem.price)}</p>}
                        </div>
                        <div style={{ display: 'flex', gap: 4 }}>
                            {menuItem.hidden && (
                                <Button variant='icon' onClick={handleShowMenuItem} disabled={loading || isMenuEditionDisabled}>
                                    <ClosedEyeIcon title={'closed eye icon'} size={20} />
                                </Button>
                            )}
                            {!menuItem.hidden && (
                                <Button variant='icon' onClick={() => onHide?.(menuItem)} disabled={loading || isMenuEditionDisabled}>
                                    <EyeIcon title={'open eye icon'} size={20} />
                                </Button>
                            )}
                            <DropDown
                                variant='icon'
                                content={<EllipsisVerticalIcon size={20} title='options' />}
                                disabled={isMenuEditionDisabled}
                                position='right'
                                classes={{ dropdown: classes.dropdown }}
                            >
                                <DropDownItem onClick={handleEditMenuItem} classes={{ container: classes.dropdownItem }}>
                                    <EditIcon size={14} />
                                    {translate('Edit')}
                                </DropDownItem>
                                <DropDownItem onClick={handleDuplicateMenuItem} classes={{ container: classes.dropdownItem }}>
                                    <CopyIcon size={14} />
                                    {translate('Duplicate')}
                                </DropDownItem>
                                <DropDownItem onClick={handleRemoveMenuItem} classes={{ container: classes.dropdownItem }}>
                                    <TrashCanIcon color={'#E37878'} size={14} />
                                    {translate('Remove')}
                                </DropDownItem>
                            </DropDown>
                        </div>
                    </div>
                </div>
                <div className={classes.regular}>{menuItem.description}</div>
            </div>
            {isMenuItemUsed && (
                <div className={classes.chipsContainer}>
                    <MenusUsingMenuItemDropDown menuItemId={menuItem.menuItemId} />
                    <MenuCategoriesUsingMenuItemDropDown menuItemId={menuItem.menuItemId} />
                    <ModifierGroupsInheritedMenuItemDropDown menuItemId={menuItem.menuItemId} />
                </div>
            )}
        </div>
    );
}

const useStyles = makeStyles((theme) => ({
    menuItem: {
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        gap: 10,
        flexDirection: 'column',
        '&:hover': {
            cursor: 'pointer',
        },
    },
    menuItemInfoContainer: {
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        gap: 12,
        '&:hover': {
            cursor: 'pointer',
        },
    },
    chipsContainer: {
        display: 'flex',
        flexDirection: 'row',
        gap: 10,
        width: '100%',
        overflowX: 'auto',
        height: 'fit-content',
        flexShrink: 0,
    },
    imageContainer: {
        minHeight: 60,
        minWidth: 60,
        width: 60,
        borderRadius: 8,
        backgroundColor: theme.palette.surface.brandContrast,
        display: 'grid',
        placeItems: 'center',
    },
    image: {
        width: 60,
        height: 60,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        objectFit: 'cover',
        borderRadius: 8,
    },
    semiBold: {
        fontFamily: theme.typography.semiBold,
        color: theme.palette.text.primary,
        fontSize: 16,
        margin: 0,
    },
    regular: {
        fontFamily: theme.typography.regular,
        color: theme.palette.text.secondary,
        fontSize: 14,
        margin: 0,
        width: '100%',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
    },
    spaceBetween: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
    },
    topContainer: {
        display: 'flex',
        alignItems: 'center',
        gap: 24,
    },
    isFullWidth: {
        width: '100%',
    },
    dropdown: {
        minWidth: 'unset',
    },
    dropdownItem: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        gap: 4,
    },
}));

type Props = {
    menuItem: ManagerMenuItemVm;
    onEdit?: any;
    onRemove?: any;
    onHide?: any;
    isProduct?: boolean;
};
