import { makeStyles } from '@material-ui/core/styles';
import * as React from 'react';
import { useState } from 'react';
import { closeOrderApi } from 'src/api/letseatmanager/order/closeOrderApi';
import { ContextualMenuItem } from 'src/components/ContextualMenuItem';
import { translate } from 'src/i18n/translate';
import { usePrintOrderTicket } from 'src/services/printer/usePrintOrderTicket';
import type { OrderVm } from 'src/types/OrderVm';
import { alertKnownErrorOrSomethingWentWrong } from 'src/utils/alert/alertKnownErrorOrSomethingWentWrong';
import { isPosApp } from 'src/utils/app/isPosApp';
import { isEatHereOrder } from 'src/utils/order/isEatHereOrder';
import { isPendingOrder } from 'src/utils/order/isPendingOrder';
import { useSelector } from 'src/utils/react/useSelector';

export function CloseOrderContextualMenuItem({ order, onSuccess }: Props): React.ReactElement | null {
    const classes = useStyles();
    const [printOrderTicket] = usePrintOrderTicket();

    const [loading, setLoading] = useState(false);

    const restaurantId = useSelector((state) => state.app.restaurantId);
    const closeOrdersEnabled = useSelector((state) => state.app.restaurant?.closeOrdersEnabled);

    const shouldRenderCloseOrderButton = closeOrdersEnabled && isEatHereOrder(order.orderType) && isPosApp(order.app) && isPendingOrder(order.orderStatus);

    const handleCloseOrder = async () => {
        setLoading(true);
        const response = await closeOrderApi({ orderId: order.orderId, restaurantId });
        setLoading(false);
        if (!response.ok) return alertKnownErrorOrSomethingWentWrong(response);

        onSuccess?.(order);
        printOrderTicket(response?.data);
    };

    if (!shouldRenderCloseOrderButton) return null;

    return <ContextualMenuItem disabled={loading} className={classes.item} onClick={handleCloseOrder} label={translate('Partial account')} />;
}

const useStyles = makeStyles((theme) => ({
    item: {
        fontSize: 14,
        padding: '8px 12px',
        '&:hover': {
            backgroundColor: '#EBFFF9',
        },
    },
}));

type Props = {
    order: OrderVm;
    onSuccess?: (arg: any) => void;
};
