import { useEffect, useState } from 'react';
import { ApiErrorResponse, ApiSauceResponse } from 'src/api/types/ApiSauceResponse';
import { unzip } from 'src/api/utils/unzip';
import { alertKnownErrorOrSomethingWentWrong } from 'src/utils/alert/alertKnownErrorOrSomethingWentWrong';
import { isArray } from 'src/utils/array/isArray';

export function useUnzipLoadApi<S extends (request?: any) => ApiSauceResponse<any>>(
    fn: S,
    request?: Parameters<S>[0],
    options?: Options,
): [boolean, Extract<Awaited<ReturnType<S>>, { ok: true }>['data'], (params?: { request: any }) => Promise<void>] {
    const [loading, setLoading] = useState(true);
    const [data, setData] = useState(options?.initialValue as any);

    useEffect(() => {
        load();
    }, getDependencies(options));

    const load = async () => {
        if (!shouldCallApi(options?.requiredValues)) return;
        setLoading(true);
        const response = await fn(request);
        if (!response.ok) {
            setLoading(false);
            options?.onError?.(response, setData);
            return alertKnownErrorOrSomethingWentWrong(response);
        }
        const unzipData = unzip(response.data);
        setData(unzipData);
        setLoading(false);
    };

    return [loading, data, load];
}
function getDependencies(options?: Options): Array<any> {
    let dependencies: Array<any> | Array<any | undefined> = [];
    if (isArray(options?.dependencies)) dependencies = dependencies.concat(options?.dependencies);
    if (isArray(options?.requiredValues)) dependencies = dependencies.concat(options?.requiredValues);
    return dependencies;
}
function shouldCallApi(requiredValues?: Array<any>): boolean {
    return !requiredValues?.some((value: any) => value === undefined || value === null);
}

type Options = {
    initialValue?: any;
    onError?: OnErrorFunction;
    dependencies?: Array<any>;
    requiredValues?: Array<any>;
};

type OnErrorFunction = (response: ApiErrorResponse, setData: any) => void;
