import { Button, Dialog, Image } from '@pidedirecto/ui';
import * as React from 'react';
import { useState } from 'react';
import { translate } from 'src/i18n/translate';
import { OrderIncidentVm } from 'src/types/OrderIncidentVm';
import { toShortId } from 'src/utils/uuid/toShortId';

export function ViewOrderIncidentImageButton({ orderIncident }: Props): React.ReactElement {
    const [orderIncidentImageDialogState, setOrderIncidentImageDialogState] = useState({ open: false });

    const openOrderIncidentImageDialog = () => setOrderIncidentImageDialogState({ open: true });

    const closeOrderIncidentImageDialog = () => setOrderIncidentImageDialogState({ open: false });

    return (
        <>
            <Dialog open={orderIncidentImageDialogState.open} title={translate('Order Id @orderId', { orderId: toShortId(orderIncident.orderId) })} onClose={closeOrderIncidentImageDialog}>
                <Image src={orderIncident.imageUrl} width={500} alt={translate('Order incident image for order @orderId', { orderId: toShortId(orderIncident.orderId) })} />
            </Dialog>
            <Button variant='text' onClick={openOrderIncidentImageDialog}>
                {translate('View image')}
            </Button>
        </>
    );
}

type Props = {
    orderIncident: OrderIncidentVm;
};
