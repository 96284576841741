import { makeStyles } from '@material-ui/core';
import * as React from 'react';
import { Button } from 'src/components/Button';
import { DeliveryProvidersPhoneNumber } from 'src/constants/DeliveryProvidersPhoneNumber';
import { translate } from 'src/i18n/translate';
import { PhoneIcon } from 'src/icons/PhoneIcon';
import type { OrderVm } from 'src/types/OrderVm';
import { isMexico } from 'src/utils/country/isMexico';
import { openWhatsappMessage } from 'src/utils/linking/openWhatsappMessage';
import { useSelector } from 'src/utils/react/useSelector';

export function OrderCardContactSupportInfo({ order }: Props): React.ReactElement | null {
    const classes = useStyles();

    const restaurant = useSelector((state) => state.app.restaurant);

    if (restaurant.deliveryProviderSupportEnabled || !isMexico(restaurant?.country)) return null;

    const makePhoneCall = (phoneNumber: string) => {
        window.location.href = `tel:${phoneNumber}`;
    };

    return (
        <div>
            {order.uberDaasDeliveryOrderId && (
                <Button text classes={{ button: classes.button }} onClick={() => makePhoneCall(DeliveryProvidersPhoneNumber.UBER_DAAS)}>
                    <PhoneIcon color='#125DF0' />
                    <span className={classes.text}>
                        {translate('Contact support')} {DeliveryProvidersPhoneNumber.UBER_DAAS}
                    </span>
                </Button>
            )}
            {order.rappiCargoDeliveryId && (
                <Button text classes={{ button: classes.button }} onClick={() => openWhatsappMessage(DeliveryProvidersPhoneNumber.RAPPI_CARGO)}>
                    <PhoneIcon color='#125DF0' />
                    <span className={classes.text}>{translate('Contact support')}</span>
                </Button>
            )}
        </div>
    );
}

const useStyles = makeStyles((theme) => ({
    button: {
        width: '100%',
        textAlign: 'center',
        minHeight: 0,
        padding: 0,
    },
    text: {
        fontFamily: theme.typography.medium,
        fontSize: 14,
        color: '#125DF0',
    },
}));

type Props = {
    order: OrderVm;
};
