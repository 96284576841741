import * as React from 'react';
import { useEffect } from 'react';
import { AppContainer } from 'src/components/app/AppContainer';
import { APP_VERSION } from 'src/config/appVersion';
import 'src/config/configureI18n';
import 'src/config/configureMoment';
import { Environments } from 'src/constants/Environment';
import { MINUTES } from 'src/constants/TimeUnit';
import { envENVIRONMENT } from 'src/env/envENVIRONMENT';
import { AppRoutes } from 'src/routers/AppRoutes';
import { PublicRoutes } from 'src/routers/PublicRoutes';
import { getDeviceId } from 'src/services/device/getDeviceId';
import 'src/styles/Hover.css';
import { usePideDirectoAdminAppMessages } from 'src/services/app/usePideDirectoAdminAppMessages';
import { formatTimeSecondsString } from 'src/utils/date/formatTimeSecondsString';
import { useInterval } from 'src/utils/react/useInterval';
import { useSelector } from 'src/utils/react/useSelector';
import { setDeviceIdInMobileApp } from 'src/utils/reactNative/setDeviceIdInMobileApp';
import { setPideDirectoAdminVersionInMobileApp } from 'src/utils/reactNative/setPideDirectoAdminVersionInMobileApp';
import { setPinCodeUserInMobileApp } from 'src/utils/reactNative/setPinCodeUserInMobileApp';

export function App(): React.ReactElement {
    const signedIn = useSelector((state) => state.authentication.signedIn);

    usePideDirectoAdminAppMessages();

    useEffect(() => {
        // Sync data in pidedirectoadminapp
        setDeviceIdInMobileApp(getDeviceId());
        setPideDirectoAdminVersionInMobileApp(APP_VERSION);
        setPinCodeUserInMobileApp();
    }, []);

    useInterval(() => checkForUpdate(), 1 * MINUTES, []);

    const checkForUpdate = async () => {
        if (envENVIRONMENT() === Environments.LOCALHOST) {
            return console.info(`Skipping service worker update check since environment is localhost`);
        }
        try {
            console.log(`Checking for updates ${formatTimeSecondsString(new Date())}`);
            if ('serviceWorker' in navigator) {
                if (navigator.serviceWorker?.ready) {
                    const registration = await navigator.serviceWorker.ready;
                    await registration.update();
                } else {
                    console.log(`Skipped checking for updates since navigator.serviceWorker.ready is not true`);
                }
            } else {
                console.error('Service worker is not supported by the browser');
            }
        } catch (e: any) {
            console.error('Failed to check if service worker is updated', e);
        }
    };

    // TODO: Change to BrowserRouter and add supportUser for refreshing non root urls, e.g. "/orders"
    return (
        <AppContainer>
            {signedIn && <AppRoutes />}
            {!signedIn && <PublicRoutes />}
        </AppContainer>
    );
}
