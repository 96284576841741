import { makeStyles } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import * as React from 'react';
import { useEffect } from 'react';
import { useForm, useWatch } from 'react-hook-form';
import { changeTheRestaurantApi } from 'src/api/letseatmanager/theRestaurant/changeTheRestaurantApi';
import type { TheRestaurantVm } from 'src/api/letseatmanager/theRestaurant/getTheRestaurantApi';
import { pidedirectouploadApi } from 'src/api/pidedirectouploadApi';
import { DeprecatedFormPercentNumberField } from 'src/components/form/DeprecatedFormPercentNumberField';
import { Form } from 'src/components/form/Form';
import { FormCurrencyNumberStringField } from 'src/components/form/FormCurrencyNumberStringField';
import { FormImageUpload } from 'src/components/form/FormImageUpload';
import { FormRestaurantColors } from 'src/components/form/FormRestaurantColors';
import { FormSubheading } from 'src/components/form/FormSubheading';
import { FormSubmitButton } from 'src/components/form/FormSubmitButton';
import { FormSwitch } from 'src/components/form/FormSwitch';
import { FormTextField } from 'src/components/form/FormTextField';
import { getDirtyFormValues } from 'src/components/form/utils/getDirtyFormValues';
import { Spacing } from 'src/components/spacing/Spacing';
import { UpdatingContentProgress } from 'src/components/UpdatingContentProgress';
import { BannerImageType, BannerImageTypes } from 'src/constants/BannerImageType';
import { ImageTypes } from 'src/constants/ImageType';
import { translate } from 'src/i18n/translate';
import { appReducer } from 'src/reducers/appReducer';
import { RestaurantCard } from 'src/scenes/letseatadmin/theRestaurant/RestaurantCard';
import { CustomPaymentMethodSettings } from 'src/scenes/letseatmanager/theRestaurant/CustomPaymentMethodSettings';
import { useReloadRestaurant } from 'src/services/restaurant/useReloadRestaurant';
import { SentryService } from 'src/services/SentryService';
import { alertKnownErrorOrSomethingWentWrong } from 'src/utils/alert/alertKnownErrorOrSomethingWentWrong';
import { getErrorDescriptionFromResponse } from 'src/utils/error/getErrorDescriptionFromResponse';
import { isExternalImageUrl } from 'src/utils/image/isExternalImageUrl';
import { useAction } from 'src/utils/react/useAction';
import { useSelector } from 'src/utils/react/useSelector';

export function RestaurantEcommerceSettings({ loading, restaurant, onChangeRestaurant, setIsDirtyForm }: Props): React.ReactElement {
    const classes = useStyles();
    const form = useForm();

    const {
        control,
        formState,
        formState: { dirtyFields },
    } = form;

    const imageUrl = useWatch<string>({ name: 'imageUrl', control });

    const name = useWatch<string>({ name: 'name', control });
    const imageUrlMobile = useWatch<string>({ name: 'imageUrlMobile', control });
    const bannerImageType = useWatch<BannerImageType>({ name: 'bannerImageType', control });

    const restaurantId = useSelector((state) => state.app.restaurantId);
    const appLoading = useSelector((state) => state.app.loading);

    const showSnackbar = useAction(appReducer.actions.showSnackbar);

    const { reloadRestaurant } = useReloadRestaurant();

    useEffect(() => {
        if (!loading) {
            form.reset({
                restaurantId: restaurant.restaurantId ?? null,
                paymentMethods: restaurant.paymentMethods ?? [],
                imageUrl: restaurant.imageUrl ?? null,
                imageUrlMobile: restaurant.imageUrlMobile ?? null,
                bannerImageType: (restaurant.imageUrl || restaurant.imageUrlMobile) && !restaurant.bannerImageType ? BannerImageTypes.CUSTOM : restaurant.bannerImageType,
                name: restaurant.name ?? null,
                restaurantColors: restaurant.restaurantColors ?? [],
                temporarilyOffline: restaurant.temporarilyOffline ?? null,
                temporarilyWithoutDelivery: restaurant.temporarilyWithoutDelivery ?? null,
                hideCartEnabled: restaurant.hideCartEnabled ?? null,
                minimumOrderAmount: restaurant.minimumOrderAmount ?? null,
                maximumOrderAmount: restaurant.maximumOrderAmount ?? null,
                fakedProductDiscountPercentage: restaurant.fakedProductDiscountPercentage ?? null,
                subModifiersEnabled: restaurant.subModifiersEnabled ?? null,
                newMenuManagementEnabled: restaurant.newMenuManagementEnabled ?? null,
                urlSubdomain: restaurant.urlSubdomain ?? null,
                urlPathname: restaurant.urlPathname ?? null,
                facebookPixelId: restaurant.facebookPixelId ?? null,
                googleAnalyticsId: restaurant.googleAnalyticsId ?? null,
            });
        }
    }, [loading]);

    useEffect(() => {
        setIsDirtyForm(Object.keys(dirtyFields).length !== 0);
    }, [formState]);

    const onSubmit = async (form: any) => {
        let imageUrl = undefined;
        const dirtyFormValues = getDirtyFormValues({ form, dirtyFields });

        if (dirtyFormValues.imageUrl) {
            imageUrl = form.imageUrl;
            if (isExternalImageUrl(imageUrl)) {
                const migrateResponse = await pidedirectouploadApi.images.migrate({
                    imageUrl: imageUrl,
                    imageType: ImageTypes.RESTAURANT,
                });
                if (!migrateResponse.ok) {
                    const errorMessage = `Failed to save cause of ${getErrorDescriptionFromResponse(migrateResponse)}`;
                    alert(errorMessage);
                    return;
                }
                imageUrl = migrateResponse.data;
            }
        }
        const response = await changeTheRestaurantApi({
            ...dirtyFormValues,
            imageUrl: dirtyFormValues.imageUrl ? imageUrl : undefined,
            restaurantId,
        });
        if (!response.ok) {
            SentryService.logError('Failed to save the restaurant', { response });
            alertKnownErrorOrSomethingWentWrong(response);
            return;
        }

        await reloadRestaurant(restaurantId);
        await onChangeRestaurant();
        showSnackbar({ message: translate('Saved changes') });
    };

    return (
        <Paper className={classes.paper}>
            <UpdatingContentProgress loading={loading || appLoading} />
            <Form form={form} onSubmit={onSubmit}>
                <div className={classes.formContent}>
                    <Grid container justify='space-around'>
                        <Grid item>
                            <RestaurantCard name={name} imageUrl={imageUrl} bannerImageType={bannerImageType!} />
                        </Grid>
                        <Grid item>
                            <RestaurantCard name={name} imageUrl={imageUrlMobile} bannerImageType={bannerImageType!} />
                        </Grid>
                    </Grid>
                    <Spacing units={3} />
                    <Grid container spacing={3}>
                        <Grid item xs={6}>
                            <FormImageUpload
                                name='imageUrl'
                                label={translate('Image')}
                                classes={{ imagePreview: classes.imageBannerPreview }}
                                message={translate('Make sure image is 1250x250 pixels or at least similar aspect ratio. Enter an URL to an image or drag and drop your image in this field')}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <FormImageUpload
                                name='imageUrlMobile'
                                label={translate('Mobile image')}
                                classes={{ imagePreview: classes.imageBannerPreview }}
                                message={translate('Make sure image is 1024x576 pixels or at least similar aspect ratio. Enter an URL to an image or drag and drop your image in this field')}
                            />
                        </Grid>
                    </Grid>

                    <Spacing units={3} />
                    <Grid container spacing={3}>
                        <Grid item xs={12} md={6}>
                            <FormCurrencyNumberStringField
                                name='minimumOrderAmount'
                                label={translate('Minimum Order Amount')}
                                tooltipText={translate('Determine a minimum purchase amount for ecommerce. If the quantity is not reached, the order cannot be generated')}
                            />
                        </Grid>
                        <Spacing units={3} />
                        <Grid item xs={12} md={6}>
                            <FormCurrencyNumberStringField
                                name='maximumOrderAmount'
                                label={translate('Maximum Order Amount')}
                                tooltipText={translate('Determine a maximum purchase amount for ecommerce. If the quantity is reached, the order cannot be generated')}
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <DeprecatedFormPercentNumberField
                                name='fakedProductDiscountPercentage'
                                label={translate('Faked Product Discount Percentage')}
                                helperText={translate('Percentage to raise all menu item prices with and while product discount price be kept at current menu item price')}
                            />
                        </Grid>
                    </Grid>
                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                            <FormSubheading align={'start' as any}>{translate('Restaurant Colors')}</FormSubheading>
                        </Grid>
                    </Grid>
                    <Grid item xs={4} md={12}>
                        <FormRestaurantColors name='restaurantColors' />
                    </Grid>
                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                            <FormSubheading align={'start' as any}>{translate('Menu Settings')}</FormSubheading>
                        </Grid>
                    </Grid>
                    <Grid container spacing={3}>
                        <Grid item xs={12} md={6}>
                            <FormSwitch name='subModifiersEnabled' label={translate('Sub-Modifiers Enabled')} tooltip={translate('Enable submodifiers for your products')} />
                        </Grid>

                        {!restaurant?.newMenuManagementEnabled && (
                            <Grid item xs={12} md={6}>
                                <FormSwitch name='newMenuManagementEnabled' label={translate('New menu management enabled')} tooltip={translate('Enable the option to use Menu Manager 2.0')} />
                            </Grid>
                        )}
                    </Grid>

                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                            <FormSubheading align={'start' as any}>{translate('Web OrdersPage Settings')}</FormSubheading>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <FormSwitch name='temporarilyOffline' label={translate('Temporarily Offline')} switchStyle='danger' />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <FormSwitch
                                name='temporarilyWithoutDelivery'
                                label={translate('Temporarily without delivery')}
                                switchStyle='danger'
                                tooltip={translate('Temporarily disable delivery orders from ecommerce and WLA')}
                            />
                        </Grid>

                        <Spacing units={3} />
                        <Grid item xs={12} md={6}>
                            <FormSwitch
                                name='hideCartEnabled'
                                label={translate('Enable ecommerce as digital menu')}
                                tooltip={translate('Allows you to hide the cart in the ecommerce to have only a digital menu')}
                            />
                        </Grid>
                        <Spacing units={3} />
                        <Grid item xs={12}>
                            <FormTextField
                                name='urlSubdomain'
                                label={translate('Url Subdomain (url friendly restaurant name)')}
                                helperText={translate(
                                    'An url friendly restaurant name (dont use spaces and special characters). If set to e.g. azucar the pidedirecto web will be available at https://azucar.pidedirecto.mx. Use same subdomain to group restaurants within the same restaurant chain.',
                                )}
                                disabled
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <FormTextField
                                name='urlPathname'
                                label={translate('Subdomain of your ecommerce')}
                                helperText={translate(
                                    'An url friendly restaurant location name (dont use spaces and special characters). If set to e.g. morena-martel the pidedirecto web will be available at https://azucar.pidedirecto.mx/morena-martel. Only one name can be used per restaurant subdomain. Saving an already used name within the subdomain fails.',
                                )}
                                disabled
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <FormTextField
                                name='facebookPixelId'
                                label={translate('Facebook pixel account Id')}
                                placeholder={'GTM-****'}
                                helperText={translate('The facebook pixel ID connected to the restaurant')}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <FormTextField
                                name='googleAnalyticsId'
                                label={translate('Google tag Manager account ID')}
                                placeholder={'309489009416843'}
                                helperText={translate('The google tag manager ID connected to the restaurant')}
                            />
                        </Grid>
                    </Grid>
                    <CustomPaymentMethodSettings onSuccess={() => onChangeRestaurant()} paymentMethods={restaurant.paymentMethods} />
                </div>

                <Grid container justify='center' spacing={3}>
                    <Grid item>
                        <FormSubmitButton text={translate('Change')} disabled={loading || appLoading} classes={{ container: classes.submitButton }} />
                    </Grid>
                </Grid>
            </Form>
        </Paper>
    );
}

const useStyles = makeStyles((theme) => ({
    paper: {
        position: 'relative',
    },
    formContent: {
        padding: theme.spacing(3),
    },
    section: {
        width: '100%',
        borderRadius: 5,
        margin: '20px auto',
        border: `1px solid ${theme.palette.secondary.dark}`,
    },
    title: {
        fontFamily: theme.typography.semiBold,
        fontSize: 20,
        color: theme.palette.secondary.contrastText,
        marginLeft: 10,
    },
    subtitle: {
        fontFamily: theme.typography.medium,
        fontSize: 18,
        color: theme.palette.secondary.contrastText,
        margin: 0,
    },
    paymentMethod: {
        fontFamily: theme.typography.medium,
        fontSize: 15,
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
    },
    secondaryButton: {
        backgroundColor: 'white',
        color: theme.palette.primary.main,
        border: `1px solid ${theme.palette.primary.main}`,
        fontFamily: theme.typography.semiBold,
        fontSize: 15,
        borderRadius: 5,
        width: 200,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        textTransform: 'none',
        padding: '5px 10px',
    },
    text: {
        fontFamily: theme.typography.regular,
        fontSize: 15,
    },
    submitButton: {
        zIndex: 1000,
    },
    imageBannerPreview: {
        display: 'none',
    },
}));

type Props = {
    loading: boolean;
    restaurant: TheRestaurantVm;
    onChangeRestaurant: any;
    setIsDirtyForm: any;
};
