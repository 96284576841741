import { letseatmanagerApiMethod, letseatmanagerApiMethodPublic } from 'src/api/letseatmanagerApi';
import { getJwtToken } from 'src/api/utils/getJwtToken';
import { pushUiErrorLogEventToLocalStorage } from 'src/localStorage/logEvent/pushUiErrorLogEventToLocalStorage';
import type { UiLogEvent } from 'src/services/logEvent/types/UiLogEvent';

/**
 * calling the api method from the service layer is against conventions,
 * but we want to avoid asynchronous api calls for Ui logEvents
 */
export function createUiErrorLogEvent(request: UiLogEvent): void {
    createUiErrorLogEventAsynchronous({ ...request, occurredAt: new Date() });
}
async function createUiErrorLogEventAsynchronous(request: UiLogEvent): Promise<void> {
    let response;
    if ((await getJwtToken()).jwtToken) {
        response = await letseatmanagerApiMethod('logEvent/createUiErrorLogEventApi', request);
    } else {
        response = await letseatmanagerApiMethodPublic('logEvent/createUiErrorLogEventApi', request);
    }

    if (!response.ok) pushUiErrorLogEventToLocalStorage({ ...request, offline: true });
}
