import { makeStyles } from '@material-ui/core';
import * as React from 'react';

type Props = {
    children: any;
};

const useStyles = makeStyles((theme) => ({
    toolbar: {
        paddingRight: theme.spacing(3),
    },
}));

export function CustomToolbarSelect({ children }: Props): React.ReactElement {
    const classes = useStyles();

    return <div className={classes.toolbar}>{children}</div>;
}
