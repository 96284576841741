import { makeStyles } from '@material-ui/core';
import { useNotification } from '@pidedirecto/ui/hooks';
import { BigNumber } from 'bignumber.js';
import * as React from 'react';
import { memo, useEffect, useRef, useState } from 'react';
import { Button } from 'src/components/Button';
import { CurrencyNumericPad } from 'src/components/CurrencyNumericPad';
import { MoneyBillsNumericPad } from 'src/components/MoneyBillsNumericPad';
import { AddCreditCardTipDialog } from 'src/components/pos/posPayment/AddCreditCardTipDialog';
import { PaymentMethods } from 'src/constants/PaymentMethod';
import { translate } from 'src/i18n/translate';
import { MoneyIcon } from 'src/icons/MoneyIcon';
import { getPosInterfaceLargerEnabledRestaurantSettingInLocalStorage } from 'src/localStorage/getPosInterfaceLargerEnabledRestaurantSettingInLocalStorage';
import { posReducer } from 'src/reducers/posReducer';
import { AddPaymentReferenceDialog } from 'src/scenes/letseatmanager/pos/posPayment/AddPaymentReferenceDialog';
import { PosCurrencies } from 'src/scenes/letseatmanager/pos/posPayment/PosCurrencies';
import { PayingLightBox } from 'src/scenes/letseatmanager/pos/posPayment/posPaymentResumeButtons/PayingLightBox';
import { RefundingLightBox } from 'src/scenes/letseatmanager/pos/posPayment/posPaymentResumeButtons/RefundingLightBox';
import { useHasPaymentTerminalsAvailable } from 'src/services/device/useHasPaymentTerminalsAvailable';
import { PaymentResult, usePayWithPaymentTerminal } from 'src/services/paymentTerminal/usePayWithPaymentTerminal';
import { useClearPosPaymentMethod } from 'src/services/pos/posPaymentMethods/useClearPosPaymentMethod';
import { useCustomPaymentMethodSelected } from 'src/services/pos/posPaymentMethods/useCustomPaymentMethodSelected';
import { usePaymentMethodSelected } from 'src/services/pos/posPaymentMethods/usePaymentMethodSelected';
import { useSelectedMexicanPaymentMethodCode } from 'src/services/pos/posPaymentMethods/useSelectedMexicanPaymentMethodCode';
import { useSelectedPaymentMethodHasReferenceEnabled } from 'src/services/pos/posPaymentMethods/useSelectedPaymentMethodHasReferenceEnabled';
import { useAddPosPayment } from 'src/services/pos/useAddPosPayment';
import { useHasMultiplePosCurrencies } from 'src/services/pos/useHasMultiplePosCurrencies';
import { useSetPayingOrder } from 'src/services/pos/useSetPayingOrder';
import { PosCustomerOrderDetails } from 'src/types/PosCustomerOrderDetails';
import { PosPayment } from 'src/types/PosPayment';
import { PosTipVm } from 'src/types/PosTipVm';
import { isMexico } from 'src/utils/country/isMexico';
import { exchangeCurrencyAmount } from 'src/utils/order/exchangeCurrencyAmount';
import { isCardPayment } from 'src/utils/paymentMethod/isCardPayment';
import { isCashPayment } from 'src/utils/paymentMethod/isCashPayment';
import { isPaymentLinkPayment } from 'src/utils/paymentMethod/isPaymentLinkPayment';
import { useAction } from 'src/utils/react/useAction';
import { useSelector } from 'src/utils/react/useSelector';
import { useIsSmallScreen } from 'src/utils/react/window/useIsSmallScreen';
import { newUuid } from 'src/utils/uuid/newUuid';

export const PosPaymentNumericPad: React.ComponentType<Props> = memo<Props>(({ disabled }: Props) => {
    const classes = useStyles();

    const isSmallScreen = useIsSmallScreen();
    const notification = useNotification();

    const numericPadRef = useRef<HTMLDivElement | null>(null);
    const hasMultiplePosCurrencies = useHasMultiplePosCurrencies();
    const hasPaymentTerminalsAvailable = useHasPaymentTerminalsAvailable();
    const paymentMethod = usePaymentMethodSelected();
    const customPaymentMethod = useCustomPaymentMethodSelected();
    const paymentReferenceEnabled = useSelectedPaymentMethodHasReferenceEnabled();
    const clearPosPaymentMethod = useClearPosPaymentMethod();
    const mexicanPaymentMethodCode = useSelectedMexicanPaymentMethodCode();
    const setPaying = useSetPayingOrder();

    const posInterfaceLargerEnabled = getPosInterfaceLargerEnabledRestaurantSettingInLocalStorage();

    const { addPosPayment: addPayment } = useAddPosPayment();
    const { payWithPaymentTerminal, cancelPayment } = usePayWithPaymentTerminal();

    const [addCardTipDialogState, setAddCardTipDialogState] = useState<{ open: boolean; amountToPay?: string }>({ open: false, amountToPay: undefined });
    const [addPaymentReferenceDialogState, setAddPaymentReferenceDialogState] = useState<{ open: boolean; amountToPay?: string }>({ open: false, amountToPay: undefined });

    const total = useSelector((state) => state.pos.payment?.total);
    const useQpayTerminalEnabled = useSelector((state) => state.app.restaurant.useQpayTerminalEnabled);
    const useOcaTerminalEnabled = useSelector((state) => state.app.restaurant.useOcaTerminalEnabled);
    const payments = useSelector((state) => state.pos.payments);
    const totalPaid = useSelector((state) => state.pos.totalPaid);
    const moneyBillsPadEnabled = useSelector((state) => state.pos.context?.moneyBillsPadEnabled);
    const customerNumberSelected = useSelector((state) => state.pos.customerNumberSelected);
    const customers = useSelector((state) => state.pos.customers);
    const selectedCurrency = useSelector((state) => state.pos.selectedCurrency);
    const paying = useSelector((state) => state.pos.paying);
    const newDeviceManagementEnabled = useSelector((state) => state.app.restaurant?.newDeviceManagementEnabled);
    const restaurantCountry = useSelector((state) => state.app.restaurant.country);

    const setTotalPaid = useAction(posReducer.actions.setTotalPaid);
    const setPayments = useAction(posReducer.actions.setPayments);
    const addPosTip = useAction(posReducer.actions.addPosTip);
    const setCustomerNumberSelected = useAction(posReducer.actions.setCustomerNumberSelected);
    const clearCustomerNumberSelected = useAction(posReducer.actions.clearCustomerNumberSelected);
    const addPosCustomerTip = useAction(posReducer.actions.addPosCustomerTip);
    const setOpenMobilePaymentResume = useAction(posReducer.actions.setOpenMobilePaymentResume);

    const customersWithOrderItems = customers?.filter((customer: PosCustomerOrderDetails) => !!customer.orderItems?.length);
    const posPaymentIsPaymentLink = payments?.some((posPayment: PosPayment) => isPaymentLinkPayment(posPayment.paymentMethod));
    const showRegularCurrencyNumericPad = !isCashPayment(paymentMethod!) || !moneyBillsPadEnabled || hasMultiplePosCurrencies;
    const showRemainingTicket = payments?.length && !customerNumberSelected;
    const hasPaymentMissing = BigNumber(totalPaid).isLessThan(total);
    const showPayingLightBox = paying && isCardPayment(paymentMethod!) && !customPaymentMethod;
    const payWithTerminalAvailable = useQpayTerminalEnabled || hasPaymentTerminalsAvailable || useOcaTerminalEnabled;

    useEffect(() => {
        if (isPaymentLinkPayment(paymentMethod!) && hasPaymentMissing) {
            payTotalTicket();
        }
    }, [paymentMethod]);

    useEffect(() => {
        if (numericPadRef.current && paymentMethod && isSmallScreen) {
            numericPadRef.current?.scrollIntoView({ behavior: 'smooth' });
        }
    }, [paymentMethod]);

    const openAddPaymentReferenceDialogState = (amountToPay: string) => {
        setAddPaymentReferenceDialogState({ open: true, amountToPay });
    };

    const closeAddPaymentReferenceDialogState = () => {
        clearPosPaymentMethod();
        setAddPaymentReferenceDialogState({ open: false });
    };

    const handleSubmitPaymentReference = async (paymentReference: string) => {
        await addPosPayment(addPaymentReferenceDialogState?.amountToPay ?? '0', paymentReference);
        setAddPaymentReferenceDialogState({ open: false, amountToPay: undefined });
    };

    const onAddPosPayment = async (number: string) => {
        if (!shouldAddPayment(number)) return;

        if (paymentReferenceEnabled) {
            openAddPaymentReferenceDialogState(number);
            return;
        }

        await addPosPayment(number);
    };

    const addPosPayment = async (number: string, paymentReference?: string) => {
        if (isCardPayment(paymentMethod) && !customPaymentMethod && payWithTerminalAvailable) {
            await handlePayWithTerminal(number);
            return;
        }

        addPayment({
            amount: number,
            paymentMethod: paymentMethod!,
            customPaymentMethod: customPaymentMethod,
            customerNumber: customerNumberSelected,
            paymentReference: paymentReference,
            mexicanPaymentMethodCode: isMexico(restaurantCountry) ? mexicanPaymentMethodCode : undefined,
        });

        onAddCustomerPayment();
    };

    const addTotalCardPayment = async (amountToPay: string) => {
        if (!shouldAddPayment(amountToPay)) return;

        setAddCardTipDialogState({
            open: true,
            amountToPay,
        });
    };

    const shouldAddPayment = (amountToPay: string) => {
        if (BigNumber(amountToPay).isEqualTo(0)) return false;
        if (!paymentMethod) {
            notification({ message: translate('Please select a payment method.') });
            return false;
        }

        if (isCardPayment(paymentMethod) && !customPaymentMethod && BigNumber(amountToPay).isGreaterThan(total)) {
            notification({ message: translate('Payment can not be greater than total') });
            return false;
        }

        return true;
    };

    const onAddCustomerPayment = () => {
        if (!customers?.length) return;
        const hasRemainingCustomers = customersWithOrderItems?.length >= customerNumberSelected + 1;

        if (hasRemainingCustomers) {
            const currentCustomerSelected = customersWithOrderItems?.findIndex((customer: PosCustomerOrderDetails) => customer.customerNumber === customerNumberSelected);
            const nextCustomer = customersWithOrderItems[currentCustomerSelected + 1].customerNumber;
            setCustomerNumberSelected(nextCustomer);
        } else {
            clearCustomerNumberSelected();
        }
    };

    const onContinueCardPayment = async (tip?: PosTipVm | undefined) => {
        if (!addCardTipDialogState.amountToPay) return;
        const amountToPay = BigNumber(addCardTipDialogState.amountToPay).toString();
        closeAddCardTipDialog();
        await handlePayWithTerminal(amountToPay, tip);
        onAddCustomerPayment();
    };

    const createTipPayment = (tip: PosTipVm, paymentResponse: PaymentResult) => {
        const paymentId = newUuid();
        return {
            id: paymentId as any,
            amount: tip.tipAmount,
            isTipPayment: true,
            paymentMethod: PaymentMethods.CREDIT_CARD,
            customerNumber: customerNumberSelected,
            paymentTerminalPaymentId: paymentResponse.paymentTerminalPaymentId,
            paymentTerminalId: paymentResponse.paymentTerminalId,
            paymentTerminalProvider: paymentResponse.paymentTerminalProvider,
        };
    };

    const handlePayWithTerminal = async (amount: string, tip?: PosTipVm) => {
        setPaying(true);
        const amountToPay = !tip ? amount : BigNumber(amount).plus(tip.tipAmount).toString();
        const payWithPaymentTerminalRequest = { amount: amount, tipAmount: tip?.tipAmount };
        const payWithPaymentTerminalDeprecatedRequest = { amount: amountToPay };
        const paymentResponse = await payWithPaymentTerminal(newDeviceManagementEnabled ? payWithPaymentTerminalRequest : payWithPaymentTerminalDeprecatedRequest);

        if (!paymentResponse.paid) {
            setPaying(false);
            return;
        }

        const payment = {
            amount,
            paymentMethod: PaymentMethods.CREDIT_CARD,
            customerNumber: customerNumberSelected,
            paymentTerminalPaymentId: paymentResponse.paymentTerminalPaymentId,
            paymentTerminalId: paymentResponse.paymentTerminalId,
            paymentTerminalProvider: paymentResponse.paymentTerminalProvider,
        };

        if (!tip) {
            addPayment(payment);
            setPaying(false);
            return;
        }

        const tipPayment = createTipPayment(tip, paymentResponse);

        setPayments([...payments, payment, tipPayment]);

        if (customerNumberSelected) {
            addPosCustomerTip({ ...tip, paymentId: tipPayment.id, customerNumber: customerNumberSelected });
        } else {
            addPosTip({ ...tip, paymentId: tipPayment.id });
        }

        setTotalPaid(
            BigNumber(totalPaid ?? 0)
                .plus(amountToPay)
                .toString(),
        );
        setPaying(false);
    };

    const payTotalTicket = () => {
        let amountToPay = exchangeCurrencyAmount(total, selectedCurrency);

        if (customerNumberSelected) {
            const customerSelected = customersWithOrderItems?.find((customer: PosCustomerOrderDetails) => customer.customerNumber === customerNumberSelected);
            const missingCustomerPayment = BigNumber(customerSelected?.payment?.total ?? 0).minus(customerSelected?.payment?.totalPaid ?? 0);
            amountToPay = exchangeCurrencyAmount(missingCustomerPayment.toString(), selectedCurrency);
        }

        if (isCardPayment(paymentMethod) && !customPaymentMethod && payWithTerminalAvailable) {
            addTotalCardPayment(amountToPay);
            return;
        }

        onAddPosPayment(amountToPay);
    };

    const payRemainingAmountTicket = () => {
        const remainingAmountTicket = exchangeCurrencyAmount(BigNumber(total).minus(totalPaid).toString(), selectedCurrency);

        onAddPosPayment(remainingAmountTicket);
    };

    const closeAddCardTipDialog = () => setAddCardTipDialogState({ open: false, amountToPay: undefined });

    return (
        <div className={classes.numericPadContainer}>
            <AddPaymentReferenceDialog open={addPaymentReferenceDialogState.open} onClose={closeAddPaymentReferenceDialogState} onSuccess={handleSubmitPaymentReference} />
            <AddCreditCardTipDialog open={addCardTipDialogState.open} amountToPay={addCardTipDialogState.amountToPay!} onContinue={onContinueCardPayment} />
            <PayingLightBox onCancelPayment={cancelPayment} showPayingLightBox={showPayingLightBox} />
            <RefundingLightBox onCancelPayment={cancelPayment} />
            <PosCurrencies />
            <div ref={numericPadRef} className={classes.numericPad}>
                {showRegularCurrencyNumericPad && <CurrencyNumericPad disabled={disabled} onEnter={onAddPosPayment} />}
                {!showRegularCurrencyNumericPad && <MoneyBillsNumericPad disabled={disabled} onEnter={onAddPosPayment} />}
            </div>
            <Button
                classes={{ button: classes.totalTicketButton }}
                secondary
                disabled={!paymentMethod || posPaymentIsPaymentLink || disabled}
                onClick={showRemainingTicket ? payRemainingAmountTicket : payTotalTicket}
                larger={posInterfaceLargerEnabled}
            >
                {showRemainingTicket ? translate('Remaining Amount') : translate('Total Ticket')}
            </Button>
            <Button larger={posInterfaceLargerEnabled} classes={{ button: classes.seeOrderResumeButton }} onClick={() => setOpenMobilePaymentResume(true)}>
                <span>{translate('Resume payments')}</span>
                <MoneyIcon />
                <div className={classes.paymentsCounter}>{payments?.length}</div>
            </Button>
        </div>
    );
});

const useStyles = makeStyles((theme) => ({
    numericPadContainer: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'flex-start',
        height: '100%',
        maxWidth: 420,
        width: '100%',
        padding: '0 24px',
        marginTop: 20,
        gap: 20,
        [theme.breakpoints.up('sm')]: {
            gridArea: 'pad',
        },
    },
    paymentsCounter: {
        top: '-10px',
        color: 'white',
        right: '-10px',
        width: 24,
        height: 24,
        display: 'grid',
        position: 'absolute',
        fontSize: 14,
        placeItems: 'center',
        borderRadius: '50%',
        backgroundColor: '#333333',
    },
    seeOrderResumeButton: {
        position: 'relative',
        width: '100%',
        display: 'none',
        [theme.breakpoints.down('sm')]: {
            display: 'flex',
        },
    },

    numericPad: {
        width: '100%',
        height: '80%',
    },
    totalTicketButton: {
        width: '100%',
    },
}));

type Props = {
    disabled: boolean;
};
