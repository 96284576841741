import { letseatmanagerApiMethod } from 'src/api/letseatmanagerApi';
import type { ApiSauceResponse } from 'src/api/types/ApiSauceResponse';
import type { MenuItemId, ModifierGroupId } from 'src/types/Id';

export async function setPreselectedModifierInModifierGroupApi(request: SetPreselectedModifierInModifierGroupApiRequest): ApiSauceResponse<void> {
    return letseatmanagerApiMethod('modifierGroup/setPreselectedModifierInModifierGroupApi', request);
}

type SetPreselectedModifierInModifierGroupApiRequest = {
    modifierGroupId: ModifierGroupId;
    menuItemId: MenuItemId;
};
