import { IconButton } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import * as React from 'react';
import { useState } from 'react';
import { getPrinterApi } from 'src/api/letseatmanager/printer/getPrinterApi';
import { removePrinterApi } from 'src/api/letseatmanager/printer/removePrinterApi';
import { RemoveDialog } from 'src/components/dialog/RemoveDialog';
import { Tooltip } from 'src/components/Tooltip';
import { translate } from 'src/i18n/translate';
import { BugIcon } from 'src/icons/BugIcon';
import { TrashcanIcon } from 'src/icons/TrashcanIcon';
import { usePrintPrinterInfo } from 'src/services/printer/usePrintPrinterInfo';
import { PrinterId } from 'src/types/Id';
import type { PrinterVm } from 'src/types/PrinterVm';
import { alertKnownErrorOrSomethingWentWrong } from 'src/utils/alert/alertKnownErrorOrSomethingWentWrong';
import { useSelector } from 'src/utils/react/useSelector';

export function PrinterListItem({ printer, isSelected, onSelectPrinter, onRemovePrinter }: Props): React.ReactElement {
    const classes = useStyles();

    const [removePrinterDialogState, setRemovePrinterDialogState] = useState<{ open: boolean; printerId: PrinterId | undefined }>({ open: false, printerId: undefined });

    const restaurantId = useSelector((state) => state.app.restaurantId);
    let deviceGroups = useSelector((state) => state.app.deviceGroups);

    const [printPrinterInfo] = usePrintPrinterInfo();

    deviceGroups = deviceGroups.filter((device) => {
        return device.printerIds?.includes(printer?.printerId);
    });

    const handleTest = async () => {
        const response = await getPrinterApi({ restaurantId, printerId: printer.printerId });
        if (!response.ok) {
            alertKnownErrorOrSomethingWentWrong(response);
            return;
        }

        await printPrinterInfo(response.data);
        alert(translate('A testing ticket was sent'));
    };

    const removePrinter = async () => {
        if (!removePrinterDialogState.printerId) return;

        if (onRemovePrinter) {
            onRemovePrinter();
        }

        const response = await removePrinterApi({ printerId: printer.printerId });
        if (!response.ok) {
            alertKnownErrorOrSomethingWentWrong(response);
        }
    };

    return (
        <div>
            <RemoveDialog
                open={removePrinterDialogState.open}
                title={translate('Remove Printer')}
                subtitle={translate('You are about to remove the printer @deviceName, are you sure you want to continue? The printer is link to @deviceGroups device groups', {
                    deviceName: printer.deviceName,
                    deviceGroups: deviceGroups.length,
                })}
                onSubmit={removePrinter}
                onClose={() => setRemovePrinterDialogState({ open: false, printerId: undefined })}
            />
            <div className={classes.printerListItem} onClick={onSelectPrinter}>
                <div>
                    <p className={classes.deviceName}>{printer.deviceName}</p>
                </div>
                <div className={classes.iconsContainer}>
                    <Tooltip text={translate('Test printer')} classes={{ text: classes.tooltipText }}>
                        <IconButton onClick={handleTest} style={{ width: 45, height: 45 }}>
                            <BugIcon color={'#6C7076'} />
                        </IconButton>
                    </Tooltip>
                    <Tooltip text={translate('Remove printer')} classes={{ text: classes.tooltipText }}>
                        <IconButton
                            onClick={() => {
                                setRemovePrinterDialogState({ open: true, printerId: printer.printerId });
                            }}
                            style={{ width: 45, height: 45 }}
                        >
                            <TrashcanIcon title={'Trashcan Icon'} color={'#E37878'} />
                        </IconButton>
                    </Tooltip>
                </div>
            </div>
        </div>
    );
}

const useStyles = makeStyles((theme) => ({
    printerListItem: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
    },
    deviceName: {
        display: 'inline',
        marginRight: 8,
        fontFamily: theme.typography.regular,
        color: '#0D3037',
        textTransform: 'capitalize',
    },
    iconsContainer: {
        display: 'flex',
        gap: 4,
    },
    chip: {
        display: 'inline',
        fontFamily: theme.typography.regular,
        fontSize: 10,
        color: '#07BD66',
        backgroundColor: '#CBF7E9',
        padding: '5px 10px',
        borderRadius: 15,
    },
    disabledChip: {
        color: '#6C7381',
        backgroundColor: '#E2E7EB',
    },
    tooltipText: {
        backgroundColor: '#616B79',
        left: '50%',
        top: '100%',
        transform: 'translateX(-50%)',
    },
}));

type Props = {
    isSelected?: boolean;
    printer: PrinterVm;
    onSelectPrinter: any;
    onRemovePrinter?: any;
};
