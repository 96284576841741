import { letseatmanagerApiMethod } from 'src/api/letseatmanagerApi';
import type { ApiSauceResponse } from 'src/api/types/ApiSauceResponse';
import type { DeviceVm } from 'src/types/DeviceVm';
import type { DeviceId } from 'src/types/Id';

export async function blockDeviceApi(request: BlockDeviceApiRequest): ApiSauceResponse<DeviceVm> {
    return letseatmanagerApiMethod('device/blockDeviceApi', request);
}

type BlockDeviceApiRequest = {
    deviceId: DeviceId;
};
