import { formatAsCurrencyNumber } from '@pidedirecto/ui/utils';
import { BigNumber } from 'bignumber.js';
import { translate } from 'src/i18n/translate';
import { printerInstructionsBuilder } from 'src/services/printer/prints/utils/printerInstructionsBuilder';
import { type PrinterInstruction } from 'src/services/printer/types/PrinterInstruction';
import { CashRegisterTransactionVm } from 'src/types/CashRegisterTransactionVm';
import type { RestaurantVm } from 'src/types/RestaurantVm';
import { formatDateTimeString } from 'src/utils/date/formatDateTimeString';
import { translateCashRegisterDepositReasons } from 'src/utils/translate/translateCashRegisterDepositReasons';
import { translateCashRegisterWithdrawReasons } from 'src/utils/translate/translateCashRegisterWithdrawReasons';

export function createCashRegisterTransactionPrint({ cashRegisterTransaction, restaurant, openCashBox }: Params): Array<PrinterInstruction> {
    const builder = printerInstructionsBuilder();
    const isDeposit = BigNumber(cashRegisterTransaction?.amount ?? 0).isPositive();
    const movement = isDeposit ? translate('Deposited') : translate('Withdrawed');

    builder.addCenteredBoldText(movement);
    if (restaurant.businessName) builder.addCenteredText(restaurant.businessName);
    if (restaurant.name) builder.addCenteredText(restaurant.name);
    builder.addNewLine();
    builder.addSeparatedBoldLeftTexts(translate('Date and time'), formatDateTimeString(cashRegisterTransaction.createdAt, restaurant.timeZone));
    if (cashRegisterTransaction.username) builder.addSeparatedBoldLeftTexts(translate('User'), cashRegisterTransaction.username);
    if (cashRegisterTransaction.cashRegisterPosBusinessDayNumber) builder.addSeparatedBoldLeftTexts(translate('Cash register'), cashRegisterTransaction.cashRegisterPosBusinessDayNumber);
    if (cashRegisterTransaction.amount) builder.addSeparatedBoldLeftTexts(translate('Amount'), formatAsCurrencyNumber(cashRegisterTransaction.amount, { country: restaurant.country as any }));
    if (cashRegisterTransaction.reason) {
        const transactionType = isDeposit ? translate('Deposit Reason') : translate('Withdraw Reason');
        const transactionReason = isDeposit ? translateCashRegisterDepositReasons(cashRegisterTransaction.reason) : translateCashRegisterWithdrawReasons(cashRegisterTransaction.reason);
        builder.addSeparatedBoldLeftTexts(transactionType, transactionReason);
    }
    if (cashRegisterTransaction.comment) builder.addSeparatedBoldLeftTexts(translate('Comment'), cashRegisterTransaction.comment);
    builder.addNewLine();
    builder.addNewLine();
    builder.addCenteredUnderLine();
    builder.addCenteredText(translate('Signature'));
    builder.addNewLine();
    if (openCashBox) builder.openCashbox();

    return builder.build();
}

type Params = {
    cashRegisterTransaction: CashRegisterTransactionVm;
    restaurant: RestaurantVm;
    openCashBox?: boolean;
};
