import { letseatmanagerApiMethod } from 'src/api/letseatmanagerApi';
import type { ApiSauceResponse } from 'src/api/types/ApiSauceResponse';
import { type MenuCategoryFamilyId, type MenuCategoryId, type MenuItemId, type ModifierGroupId, type PrinterName } from 'src/types/Id';

export async function changeMenuCategoryApi(request: ChangeMenuCategoryApiRequest): ApiSauceResponse<void> {
    return letseatmanagerApiMethod('menuCategory/changeMenuCategoryApi', request);
}

export type ChangeMenuCategoryApiRequest = {
    menuCategoryId: MenuCategoryId;
    menuItemIds: Array<MenuItemId>;
    modifierGroupIds?: Array<ModifierGroupId>;
    menuCategoryFamilyId?: MenuCategoryFamilyId;
    name: string;
    hidden?: boolean;
    promotion?: boolean;
    imageUrl?: string;
    color?: string;
    hideInKitchenDisplayScreen?: boolean;
    printerNames?: Array<PrinterName>;
    orderItemsMaximumQuantity?: string;
};
