import type { RestaurantId } from 'src/types/Id';

export const KEY_NAME = 'kitchenOptions';

export function getKitchenOptionsInLocalStorage(): Array<KitchenOption> {
    const item = window.localStorage.getItem(KEY_NAME);
    return item ? JSON.parse(item) : [];
}

export type KitchenOption = {
    label: string;
    value: RestaurantId;
};
