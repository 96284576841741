import Button from '@material-ui/core/Button';
import Snackbar from '@material-ui/core/Snackbar';
import { makeStyles } from '@material-ui/core/styles';
import Alert from '@material-ui/lab/Alert';
import * as React from 'react';
import { appReducer } from 'src/reducers/appReducer';
import { useAction } from 'src/utils/react/useAction';
import { useSelector } from 'src/utils/react/useSelector';

export function AppSnackbar(): React.ReactElement {
    const classes = useStyles();

    const snackbar = useSelector((state) => state.app.snackbar);

    const hideSnackbar = useAction(appReducer.actions.hideSnackbar);

    const handleActionClickSnackbar = () => {
        if (snackbar.onActionClick) {
            return snackbar.onActionClick();
        }
        hideSnackbar();
    };

    const handleCloseSnackbar = (event: any, reason: any) => {
        if (snackbar.dontCloseOnClickAway && reason === 'clickaway') {
            return;
        }
        if (snackbar.onClose) {
            return snackbar.onClose(event, reason);
        }
        hideSnackbar();
    };

    return (
        <Snackbar
            anchorOrigin={{ vertical: snackbar.vertical, horizontal: snackbar.horizontal }}
            open={snackbar.isOpen}
            message={snackbar.message}
            action={
                snackbar.actionText ? (
                    <Button color='secondary' size='small' onClick={handleActionClickSnackbar}>
                        {snackbar.actionText}
                    </Button>
                ) : null
            }
            autoHideDuration={snackbar.autoHideDuration}
            onClose={handleCloseSnackbar}
        >
            {snackbar.extendedAction && (
                <>
                    {/* @ts-ignore */}
                    <Alert className={(classes as any).snackbarContent} onClose={hideSnackbar} severity='error' sx={{ width: '100%' }}>
                        <div className={(classes as any).snackbarContentInfo}>
                            <div>
                                <h4>{snackbar.title}</h4>
                                <p>{snackbar.message}</p>
                            </div>
                            {snackbar.actionText && (
                                <Button color='secondary' size='small' onClick={handleActionClickSnackbar}>
                                    {snackbar.actionText}
                                </Button>
                            )}
                        </div>
                    </Alert>
                </>
            )}
        </Snackbar>
    );
}

const useStyles = makeStyles((theme) => ({
    container: {
        padding: 2,
        paddingRight: 4,
        borderTopRightRadius: 4,
    },
    red: {
        backgroundColor: '#f44336',
        color: 'white',
    },
    yellow: {
        backgroundColor: '#d9c300',
        color: 'white',
    },
    green: {
        backgroundColor: '#4caf50',
        color: 'white',
    },
}));
