import { makeStyles } from '@material-ui/core';
import { useContext, useEffect, useRef, useState } from 'react';
import * as React from 'react';
import { PageContext } from 'src/components/Page';
import { translate } from 'src/i18n/translate';
import { ArrowDownIcon } from 'src/icons/ArrowDownIcon';
import { createUserToggledAccordionLogEvent } from 'src/services/logEvent/createUserToggledAccordionLogEvent';
import { normalizeUiStackTrace } from 'src/services/logEvent/normalizeUiStackTrace';
import { classNames } from 'src/utils/react/classNames';

/**
 * Create accordion component
 * @param {boolean} [open] - Controls the accordion open state, this is ignored if the title is passed
 * @param {string} [title] - Title of the accordion, if this is passed the accordion will be auto controlled
 * @param {string} [subText] - Sub text of the accordion
 * @param {boolean} [defaultOpened] - Set accordion default state, if true the accordion will be opened by default
 * @param {React.Node} children - Content that will be hidden in the accordion
 * @param {React.Node} [icon] - icon to be shown in left side
 * @param {string} [classes.title] - class to override title style
 * @param {string} [classes.subText] - class to override subText style
 * @param {string} [classes.container] - class to override container style
 * @param {Function} [onChange] - function to be called when clicks on button
 * @returns {React.Node}
 */
export function Accordion({ open, title, defaultOpened, children, classes: classesProp, subText, renderIcon, onChange }: Props): React.ReactElement {
    const classes = useStyles();
    const containerRef = useRef<HTMLDivElement>(null);
    const accordionId = useRef(getId());
    const pageContext = useContext(PageContext);

    const [currentHeight, setCurrentHeight] = useState('0px');
    const [accordionOpened, setAccordionOpened] = useState(!!defaultOpened || false);

    useEffect(() => {
        const height = containerRef.current?.scrollHeight ?? 0;
        setCurrentHeight(`${height}px`);
        if (accordionOpened) setTimeout(() => setCurrentHeight('max-content'), 500);
    }, [accordionOpened, open, children]);

    const handleOnClickAccordion = () => {
        createUserToggledAccordionLogEvent({ pageContext, title });

        pageContext.clearStackTrace();
        if (onChange) onChange(!accordionOpened);
        setAccordionOpened(!accordionOpened);
    };

    const addAccordionToStackTrace = () => {
        pageContext.addElementToStackTrace(normalizeUiStackTrace(`accordion_${title || ''}`));
    };

    if (title) {
        return (
            <div className={classNames(classes.container, classesProp?.container)} onClickCapture={addAccordionToStackTrace}>
                <button className={classes.titleContainer} onClick={handleOnClickAccordion} aria-expanded={accordionOpened} aria-controls={`accordion-${accordionId.current}`} type={'button'}>
                    {!renderIcon && (
                        <div className={classNames(classes.button, accordionOpened && classes.accordionOpenedButton)}>
                            <ArrowDownIcon title={translate('arrowDownIcon')} />
                        </div>
                    )}
                    {!!renderIcon && <div className={classes.icon}>{renderIcon(accordionOpened)}</div>}
                    <div className={(classes as any).textContainer}>
                        <h2 className={classNames(classes.title, classesProp?.title)}>{title}</h2>
                        {subText && <p className={classNames(classes.caption, classesProp?.subText)}>{subText}</p>}
                    </div>
                </button>
                <div
                    ref={containerRef}
                    className={classNames(classes.accordion, classesProp?.accordion)}
                    style={{ height: accordionOpened ? currentHeight : 0 }}
                    id={`accordion-${accordionId.current}`}
                    aria-hidden={!accordionOpened}
                >
                    {children}
                </div>
            </div>
        );
    }

    return (
        <div ref={containerRef} className={classNames(classes.accordion, classesProp?.accordion)} style={{ height: !!open ? currentHeight : 0 }} onClickCapture={addAccordionToStackTrace}>
            {open && children}
        </div>
    );
}

const getId = () => {
    return Math.round(Date.now() * Math.random());
};

const useStyles = makeStyles((theme) => ({
    container: {
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
    },
    titleContainer: {
        width: '100%',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        padding: '0px 10px 10px 10px',
        border: 0,
        borderBottom: '1px solid #D9D9D9',
        backgroundColor: 'transparent',
        cursor: 'pointer',
        gap: 10,
    },
    button: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        border: '1px solid #D9D9D9',
        borderRadius: 4,
        width: 24,
        height: 24,
        color: '#8A95A5',
        '& svg': {
            transition: 'transform 0.2s linear',
        },
    },
    accordionOpenedButton: {
        '& svg': {
            transform: 'rotate(-180deg)',
        },
    },
    icon: {
        alignSelf: 'start',
        paddingTop: 5,
    },
    accordion: {
        width: '100%',
        transition: 'height 0.25s linear',
        overflow: 'hidden',
        flexShrink: 0,
    },
    title: {
        fontFamily: theme.typography.semiBold,
        fontSize: 18,
        margin: 0,
        color: '#0D3037',
        textAlign: 'left',
    },
    caption: {
        fontFamily: theme.typography.regular,
        color: '#616B79',
        fontSize: 12,
    },
}));

type Props = {
    open?: boolean;
    defaultOpened?: boolean;
    title?: string;
    subText?: string;
    children: React.ReactNode;
    classes?: {
        title?: string;
        subText?: string;
        container?: string;
        accordion?: string;
    };
    onChange?: any;
    renderIcon?: any;
};
