import { makeStyles } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import { formatAsCurrencyNumber } from '@pidedirecto/ui/utils';
import moment from 'moment';
import MUIDataTable from 'mui-datatables';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { getDriverBonusBillingDetailsApi, GetDriverBonusBillingDetailsApiResponse } from 'src/api/letseatadmin/driverBonusBilling/getDriverBonusBillingDetailsApi';
import { DriverBonusBillingVm, DriverBonusesBillingVm, getDriverBonusesBillingApi } from 'src/api/letseatadmin/driverBonusBilling/getDriverBonusesBillingApi';
import { app2 } from 'src/app2';
import type { FilterState } from 'src/components/input/FromToFilter';
import { UpdatingContentProgress } from 'src/components/UpdatingContentProgress';
import { translate } from 'src/i18n/translate';
import { DriverBonusBillingDetailsDialog } from 'src/scenes/letseatadmin/driverBonusBilling/DriverBonusBillingDetailsDialog';
import { alertKnownErrorOrSomethingWentWrong } from 'src/utils/alert/alertKnownErrorOrSomethingWentWrong';
import { formatDateTimeString } from 'src/utils/date/formatDateTimeString';
import { useAction } from 'src/utils/react/useAction';
import { useSelector } from 'src/utils/react/useSelector';

export function DriverBonusesBilling({ filter }: Props): React.ReactElement {
    const classes = useStyles();

    const [loading, setLoading] = useState(false);
    const [driverBonusesBillingVm, setDriverBonusesBillingVm] = useState<DriverBonusesBillingVm | undefined>();
    const [driverBonusBillingDetailsDialogState, setDriverBonusBillingDetailsDialogState] = useState<{ open: boolean; driverBonusBillingDetails: GetDriverBonusBillingDetailsApiResponse | undefined }>(
        { open: false, driverBonusBillingDetails: undefined },
    );

    const setTitle = useAction(app2.actions.setTitle);
    const cities = useSelector((state) => state.app2.cities);

    useEffect(() => {
        setTitle(translate('Driver Bonus Billing'));
        load();
    }, [filter.from, filter.to, cities]);

    const load = async () => {
        setLoading(true);
        const response = await getDriverBonusesBillingApi({
            from: moment(filter.from).format('YYYY-MM-DD'),
            to: moment(filter.to).format('YYYY-MM-DD'),
            cities,
        });
        setLoading(false);
        if (!response.ok) {
            alertKnownErrorOrSomethingWentWrong(response);
            return;
        }
        setDriverBonusesBillingVm(response.data);
    };

    const openDriverBonusDetails = async (driverBonusOfferId: any) => {
        setLoading(true);
        const response = await getDriverBonusBillingDetailsApi({
            driverBonusOfferId,
            from: moment(filter.from).format('YYYY-MM-DD'),
            to: moment(filter.to).format('YYYY-MM-DD'),
            cities,
        });
        setLoading(false);
        if (!response.ok) {
            alertKnownErrorOrSomethingWentWrong(response);
            return;
        }
        setDriverBonusBillingDetailsDialogState({ open: true, driverBonusBillingDetails: response.data });
    };

    const handleCloseDialog = () => setDriverBonusBillingDetailsDialogState({ open: false, driverBonusBillingDetails: undefined });

    return (
        <div className={classes.container}>
            <UpdatingContentProgress loading={loading} />
            <DriverBonusBillingDetailsDialog
                open={driverBonusBillingDetailsDialogState.open}
                driverBonusBillingDetails={driverBonusBillingDetailsDialogState.driverBonusBillingDetails}
                handleClose={handleCloseDialog}
            />
            <MUIDataTable
                /* @ts-ignore */
                className={classes.table}
                title={translate('Driver Bonuses')}
                data={
                    driverBonusesBillingVm?.driverBonusesBilling?.map((driverBonusBilling: DriverBonusBillingVm) => ({
                        driverBonusOfferId: driverBonusBilling.driverBonusOfferId,
                        name: driverBonusBilling.name,
                        usage: driverBonusBilling.usage,
                        firstUsedAt: formatDateTimeString(driverBonusBilling.firstUsedAt) ?? 'Has not been used',
                        amount: formatAsCurrencyNumber(driverBonusBilling.amount),
                        drivers: driverBonusBilling.drivers,
                        city: translate(driverBonusBilling.city),
                        driverBonusDetails: driverBonusBilling.driverBonusOfferId,
                    })) ?? []
                }
                columns={[
                    {
                        name: 'driverBonusOfferId',
                        label: ' ',
                        options: {
                            display: 'excluded',
                            filter: false,
                        },
                    },
                    {
                        name: 'name',
                        label: translate('Name'),
                        options: {
                            filter: false,
                        },
                    },
                    {
                        name: 'usage',
                        label: translate('Usage'),
                        options: {
                            filter: false,
                        },
                    },
                    {
                        name: 'firstUsedAt',
                        label: translate('First Time Used'),
                        options: {
                            filter: false,
                        },
                    },
                    {
                        name: 'amount',
                        label: translate('Amount'),
                        options: {
                            filter: true,
                            searchable: true,
                        },
                    },
                    {
                        name: 'drivers',
                        label: translate('Drivers'),
                        options: {
                            filter: true,
                            searchable: true,
                        },
                    },
                    {
                        name: 'city',
                        label: translate('City'),
                        options: {
                            filter: true,
                            searchable: true,
                        },
                    },
                    {
                        name: 'driverBonusDetails',
                        label: translate('Details'),
                        options: {
                            filter: true,
                            searchable: true,
                            customBodyRender: (driverBonusOfferId) => {
                                return (
                                    <IconButton color='primary' onClick={() => openDriverBonusDetails(driverBonusOfferId)}>
                                        <OpenInNewIcon />
                                    </IconButton>
                                );
                            },
                        },
                    },
                ]}
                options={{
                    responsive: 'standard',
                    tableBodyMaxHeight: '500px',
                    selectableRows: 'none',
                    downloadOptions: { filename: 'billing data.csv' },
                    // filterList: [[], [], [], [], ['MEXICO']],
                }}
            />
        </div>
    );
}

const useStyles = makeStyles((theme) => ({
    container: {
        position: 'relative',
    },
    table: {
        whiteSpace: 'nowrap',
        '& tr': { cursor: 'pointer' },
        '& tr:hover': { backgroundColor: 'rgba(0, 0, 0, 0.07) !important' },
    },
    toolbar: {
        paddingRight: theme.spacing(3),
    },
}));

type Props = {
    filter: FilterState;
};
