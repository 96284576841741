import { useMemo } from 'react';
import { DeprecatedModifierGroupVm, type ModifierGroupVm } from 'src/types/PosMenuVm';
import { useSelector } from 'src/utils/react/useSelector';
import { removeNulls } from 'src/utils/removeNulls';

export function usePosCartModifierGroups(): Array<ModifierGroupVm | DeprecatedModifierGroupVm> {
    const item = useSelector((state) => state.pos.itemSelected);
    const menuCategories = useSelector((state) => state.pos.posMenu?.menuCategories) ?? [];
    const itemSelectedMenuCategoryId = useSelector((state) => state.pos.itemSelectedMenuCategoryId);
    const modifierGroups = useSelector((state) => state.pos.posMenu?.modifierGroups) ?? []; //ModifierGroupVm
    const selectedMenuCategory = useSelector((state) => state.pos.selectedMenuCategory);
    const newMenuManagementEnabled = useSelector((state) => state.app.restaurant.newMenuManagementEnabled);

    const menuCategoryItemBelongs = menuCategories.find((menuCategory) => menuCategory.menuCategoryId === itemSelectedMenuCategoryId);

    const getModifierGroups = (): Array<ModifierGroupVm | DeprecatedModifierGroupVm> => {
        if (!newMenuManagementEnabled) return item?.modifierGroups ?? [];

        const menuCategoryModifierGroupIds = menuCategoryItemBelongs?.modifierGroupIds ?? selectedMenuCategory?.modifierGroupIds ?? [];
        const modifierGroupIds = [...(item?.modifierGroupIds ?? []), ...menuCategoryModifierGroupIds];
        return removeNulls(
            modifierGroupIds.map((modifierGroupId) => {
                const modifierGroup = modifierGroups.find((modifierGroup) => modifierGroup.modifierGroupId === modifierGroupId);
                if (!modifierGroup) return null as any;
                return modifierGroup;
            }),
        );
    };

    const posCartModifierGroups: Array<ModifierGroupVm | DeprecatedModifierGroupVm> = useMemo(getModifierGroups, [menuCategoryItemBelongs, item, modifierGroups, selectedMenuCategory]);

    return posCartModifierGroups;
}
