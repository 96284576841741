/* eslint-disable react/prop-types, react/jsx-handler-names */
import * as React from 'react';
import { useEffect, useState } from 'react';
import { letseatmanagerApiDeprecated } from 'src/api/letseatmanagerApiDeprecated';
import SelectTypeahead from 'src/components/deprecated/react-final-form/SelectTypeahead';
import { getCompanyOptionsInLocalStorage } from 'src/localStorage/getCompanyOptionsInLocalStorage';
import { setCompanyOptionsInLocalStorage } from 'src/localStorage/setCompanyOptionsInLocalStorage';
import { alertKnownErrorOrSomethingWentWrong } from 'src/utils/alert/alertKnownErrorOrSomethingWentWrong';

export default (props: any): React.ReactElement => {
    const [options, setOptions] = useState(getCompanyOptionsInLocalStorage());

    useEffect(() => {
        async function load() {
            const response = await letseatmanagerApiDeprecated.admin.getCompanyOptions();
            if (!response.ok) {
                alertKnownErrorOrSomethingWentWrong(response);
                return;
            }
            setCompanyOptionsInLocalStorage(response.data);
            if (cancelled) return;
            setOptions(response.data);
        }

        let cancelled = false;
        load();
        return () => {
            cancelled = true;
        };
    }, []);

    return <SelectTypeahead {...props} options={options} />;
};
