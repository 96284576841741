import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Grid from '@material-ui/core/Grid';
import LinearProgress from '@material-ui/core/LinearProgress';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { Loader } from '@pidedirecto/ui';
import * as React from 'react';
import { withRouter } from 'react-router-dom';
import { letseatmanagerApiDeprecated } from 'src/api/letseatmanagerApiDeprecated';
import { Field, Form } from 'src/components/deprecated/react-final-form/Form';
import TextField from 'src/components/deprecated/react-final-form/TextField';
import { Spacing } from 'src/components/spacing/Spacing';
import { history } from 'src/config/history';
import type { CustomerType } from 'src/constants/CustomerType';
import { translate } from 'src/i18n/translate';
import { appLayout } from 'src/theme/AppLayout';
import type { CustomerId, LanguageTag } from 'src/types/Id';
import { alertKnownErrorOrSomethingWentWrong } from 'src/utils/alert/alertKnownErrorOrSomethingWentWrong';
import { fullName } from 'src/utils/customer/fullName';
import { deleteUrlQueryParameter } from 'src/utils/history/deleteUrlQueryParameter';
import { getUrlQueryParameter } from 'src/utils/history/getUrlQueryParameter';
import { required } from 'src/utils/Validator';

export default withRouter<any, any>(
    withStyles((theme) => ({
        linearProgress: {
            position: 'absolute',
            width: '100%',
            bottom: 0,
        },
    }))(
        class CustomerSendEmailDialog extends React.PureComponent<Props, State> {
            state: any = INITIAL_STATE;

            async componentDidMount(): Promise<void> {
                const customerId: CustomerId = getUrlQueryParameter(SEND_EMAIL, this.props.location);
                if (customerId) {
                    await this.openDialog(customerId);
                }
            }

            async componentDidUpdate(prevProps: Props) {
                const prevCustomerId: CustomerId = getUrlQueryParameter(SEND_EMAIL, prevProps.location);
                const customerId: CustomerId = getUrlQueryParameter(SEND_EMAIL, this.props.location);
                if (customerId && !prevCustomerId && !this.state.open) {
                    await this.openDialog(customerId);
                } else if (!customerId && prevCustomerId && this.state.open) {
                    this.closeDialog();
                }
            }

            handleClose = () => {
                if (this.state.submitting) {
                    return;
                }
                if (history.length <= 2) {
                    history.replace({
                        path: this.props.location.pathname,
                        search: deleteUrlQueryParameter(SEND_EMAIL),
                    });
                    return;
                }
                history.goBack();
            };
            handleExited = () => {
                this.setState(INITIAL_STATE as any);
            };

            openDialog = async (customerId: CustomerId) => {
                this.setState({ open: true });
                await this.load(customerId);
            };

            closeDialog = () => {
                this.setState({ open: false });
            };

            load = async (customerId: CustomerId) => {
                this.setState({ loading: true });
                const response = await letseatmanagerApiDeprecated.admin.getCustomer({ customerId });
                this.setState({ loading: false });
                if (!response.ok) {
                    alertKnownErrorOrSomethingWentWrong(response);
                    this.setState({ open: false });
                    return;
                }
                const customer: CustomerVm = response.data;
                this.setState({
                    customer: {
                        customerId: customer.customerId,
                        name: fullName(customer.firstName, customer.lastName),
                    },
                });
            };

            onSubmit = async (form: any) => {
                this.setState({ submitting: true });
                const response = await letseatmanagerApiDeprecated.admin.sendEmailToCustomer({
                    customerId: form.customer.customerId,
                    subject: form.subject,
                    bodyHtml: form.body,
                    bodyText: form.body,
                });
                this.setState({ submitting: false });
                if (!response.ok) {
                    alertKnownErrorOrSomethingWentWrong(response);
                    return;
                }
                this.handleClose();
            };

            render() {
                const { classes } = this.props;
                return (
                    <Dialog aria-labelledby='customer-send-dialog-title' fullWidth scroll='paper' open={this.state.open} onClose={this.handleClose} onExited={this.handleExited}>
                        <Form
                            onSubmit={this.onSubmit}
                            initialValues={{
                                customer: this.state.customer,
                                subject: this.props.template ? this.props.template.subject || '' : '',
                                body: this.props.template ? this.props.template.body || '' : '',
                            }}
                            render={({ handleSubmit, submitting, pristine, values }) => (
                                <form onSubmit={handleSubmit} noValidate style={appLayout.dialog.form}>
                                    <DialogTitle id='customer-send-dialog-title'>{translate('Send Email To Customer').toUpperCase()}</DialogTitle>
                                    <DialogContent>{this.renderDialogContent()}</DialogContent>
                                    <DialogActions>
                                        <Button onClick={this.handleClose} disabled={this.state.submitting}>
                                            {translate('Cancel').toUpperCase()}
                                        </Button>
                                        <Button color='primary' type='submit' disabled={this.state.submitting}>
                                            {this.state.submitting ? translate('Sending').toUpperCase() : translate('Send').toUpperCase()}
                                        </Button>
                                    </DialogActions>
                                    {this.state.submitting && <LinearProgress className={classes.linearProgress} />}
                                </form>
                            )}
                        />
                    </Dialog>
                );
            }

            renderDialogContent() {
                if (this.state.loading) {
                    return <Loader size={40} loading={true} />;
                }
                return (
                    <>
                        <Grid container spacing={3}>
                            <Grid item xs={12}>
                                <Field name='customer.customerId' component={TextField} label={translate('Customer Id')} fullWidth disabled />
                            </Grid>
                        </Grid>

                        <Grid container spacing={3}>
                            <Grid item xs={12}>
                                <Field name='customer.name' component={TextField} label={translate('Name')} fullWidth disabled />
                            </Grid>
                        </Grid>

                        <Grid container spacing={3}>
                            <Grid item xs={12}>
                                <Field
                                    name='subject'
                                    component={TextField}
                                    label={translate('Subject')}
                                    helperText={`${translate('@name will be replaced with customers first name or cliente')}`}
                                    fullWidth
                                    disabled={this.state.submitting}
                                    required
                                    validate={required}
                                />
                            </Grid>
                        </Grid>

                        <Grid container spacing={3}>
                            <Grid item xs={12}>
                                <Field
                                    name='body'
                                    component={TextField}
                                    multiline
                                    label={translate('Body')}
                                    helperText={`${translate('@name will be replaced with customers first name or cliente')}`}
                                    fullWidth
                                    disabled={this.state.submitting}
                                    required
                                    validate={required}
                                />
                            </Grid>
                        </Grid>

                        <Grid container justify='space-around'>
                            <Grid item>
                                <Spacing units={8} />
                                {this.state.errorMessage && (
                                    <Typography display='block' color='error'>
                                        {this.state.errorMessage}
                                    </Typography>
                                )}
                            </Grid>
                        </Grid>
                    </>
                );
            }
        },
    ),
) as React.ComponentType<any>;

export const SEND_EMAIL = 'send-email';
const INITIAL_STATE = {
    open: false,
    customer: {
        customerId: '' as any,
        name: '',
    },
    loading: false,
    submitting: false,
} as const;

type Props = {
    classes: any;
    location: {
        pathname: string;
    };
    template?: {
        subject: string;
        body: string;
    };
};

type State = {
    open: boolean;
    loading: boolean;
    submitting: boolean;
    customer: {
        customerId: CustomerId;
        name: string;
    };
    errorMessage?: string;
};

type CustomerVm = {
    customerId: CustomerId;
    customerType: CustomerType;
    mobileNumber?: string;
    email?: string;
    firstName?: string;
    lastName?: string;
    companyName?: string;
    creditCards?: number;
    credits?: string;
    language?: LanguageTag;
    fcmPermission?: boolean;
    downloadedAppAt?: Date;
    signedUpAt?: Date;
    addedFirstCreditCardAt?: Date;
};
