import type { RestaurantVm } from 'src/types/RestaurantVm';
import { isParaguay } from 'src/utils/country/isParaguay';
import { isPeru } from 'src/utils/country/isPeru';
import { isUruguay } from 'src/utils/country/isUruguay';
import { isBlankString } from 'src/utils/string/isBlankString';

export function canRestaurantInvoice(restaurant: RestaurantVm): boolean {
    if (isParaguay(restaurant.country)) return false;

    if (isUruguay(restaurant.country)) {
        return !isBlankString(restaurant.uruwareCodComercio) && !isBlankString(restaurant.uruwareCodTerminal) && !isBlankString(restaurant.uruwareClave);
    }

    if (isPeru(restaurant.country)) {
        return !isBlankString(restaurant.nubefactStoreId) && !isBlankString(restaurant.nubefactToken);
    }

    return restaurant.csdCertificateUploaded || !!restaurant.csdCertificateRestaurantId;
}
