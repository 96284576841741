import { makeStyles } from '@material-ui/core';
import { useEffect, useState } from 'react';
import * as React from 'react';
import { findDevicesApi } from 'src/api/letseatmanager/device/findDevicesApi';
import { SectionList } from 'src/components/SectionList';
import { SectionListItem } from 'src/components/SectionListItem';
import { UpdatingContentProgress } from 'src/components/UpdatingContentProgress';
import { translate } from 'src/i18n/translate';
import { DeviceListItem } from 'src/scenes/letseatmanager/device/devices/DeviceListItem';
import type { DeviceVm } from 'src/types/DeviceVm';
import { useLoadApi } from 'src/utils/react/useLoadApi';
import { useSelector } from 'src/utils/react/useSelector';

export function Devices(): React.ReactElement {
    const classes = useStyles();

    const [selectedDeviceId, setSelectedDeviceId] = useState<any>(undefined);

    const restaurantId = useSelector((state) => state.app.restaurantId);

    const [loadingDevices, devices, refreshDevices] = useLoadApi(findDevicesApi, { restaurantId }, { initialValue: [] });
    const [allDevices, setAllDevices] = useState<Array<DeviceVm>>([]);

    useEffect(() => {
        if (!selectedDeviceId && devices.length) setSelectedDeviceId(devices[0]);
    }, [devices, selectedDeviceId]);

    useEffect(() => {
        setAllDevices([...devices]);
    }, [devices]);

    const selectDevice = (deviceId: any) => setSelectedDeviceId(deviceId);

    return (
        <div className={classes.container}>
            <UpdatingContentProgress loading={loadingDevices} top />
            <SectionList title={translate('Devices')} onItemClick={selectDevice} defaultListItemSelected={selectedDeviceId} classes={{ container: classes.list }}>
                {allDevices.map((device) => (
                    <SectionListItem key={device.deviceId} value={device.deviceId}>
                        <DeviceListItem device={device} onChange={refreshDevices} />
                    </SectionListItem>
                ))}
            </SectionList>
        </div>
    );
}

const useStyles = makeStyles((theme) => ({
    container: {
        width: '100%',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        position: 'relative',
    },
    list: {
        width: '50vw',
        [theme.breakpoints.down('sm')]: {
            width: '90vw',
        },
    },
}));
