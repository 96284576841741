import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import { DatePicker } from '@material-ui/pickers';
import moment from 'moment';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { Link, Route, Switch, useLocation } from 'react-router-dom';
import { getSurveysApi, SurveyVm } from 'src/api/letseatadmin/survey/getSurveysApi';
import { app2 } from 'src/app2';
import { Button } from 'src/components/Button';
import { UpdatingContentProgress } from 'src/components/UpdatingContentProgress';
import { MomentFormats } from 'src/constants/MomentFormat';
import { RoutePaths } from 'src/constants/RoutePath';
import { translate } from 'src/i18n/translate';
import CustomerCreditsDialog from 'src/scenes/letseatadmin/customer/CustomerCreditsDialog';
import CustomersCreditsDialog from 'src/scenes/letseatadmin/customer/CustomersCreditsDialog';
import { CustomerSendDialog } from 'src/scenes/letseatadmin/customer/CustomerSendDialog';
import CustomerSendEmailDialog from 'src/scenes/letseatadmin/customer/CustomerSendEmailDialog';
import CustomersSendDialog from 'src/scenes/letseatadmin/customer/CustomersSendDialog';
import CustomersSendEmailDialog from 'src/scenes/letseatadmin/customer/CustomersSendEmailDialog';
import RatingDetails from 'src/scenes/letseatadmin/surveys/RatingDetails';
import RestaurantSurveysTable from 'src/scenes/letseatadmin/surveys/RestaurantSurveysTable';
import SurveysTable from 'src/scenes/letseatadmin/surveys/SurveysTable';
import { alertKnownErrorOrSomethingWentWrong } from 'src/utils/alert/alertKnownErrorOrSomethingWentWrong';
import { useAction } from 'src/utils/react/useAction';

export function SurveysPage(): React.ReactElement {
    const [loading, setLoading] = useState(false);
    const [from, setFrom] = useState(moment().format(MomentFormats.ISO_DATE));
    const [to, setTo] = useState(moment().format(MomentFormats.ISO_DATE));
    const [vm, setVm] = useState({ surveys: [], rating: {} } as any);
    const [reload, setReload] = useState(false);

    const setTitle = useAction(app2.actions.setTitle);
    const location = useLocation();

    const load = async () => {
        setLoading(true);
        const response = await getSurveysApi({
            from,
            to,
        });
        if (!response.ok) {
            setLoading(false);
            alertKnownErrorOrSomethingWentWrong(response);
            return;
        }
        setLoading(false);
        setVm(response.data);
    };

    useEffect(() => {
        setTitle(translate('Surveys'));
        load();
    }, []);

    useEffect(() => {
        if (reload === true) {
            setReload(false);
            load();
        }
    }, [reload]);

    const unsolved = vm.surveys.filter((survey: SurveyVm) => survey.unsolved === true);

    return (
        <>
            <CustomerSendDialog />
            <CustomersSendDialog path={RoutePaths.ADMIN_SURVEYS_SEND_NOTIFICATIONS} />
            <CustomerSendEmailDialog />
            <CustomersSendEmailDialog path={RoutePaths.ADMIN_SURVEYS_SEND_EMAILS} />
            <CustomerCreditsDialog />
            <CustomersCreditsDialog path={RoutePaths.ADMIN_SURVEYS_CREDITS} />
            <div style={{ position: 'relative' }}>
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <UpdatingContentProgress loading={loading} />
                        <Paper>
                            <Tabs value={location.pathname} indicatorColor='primary' textColor='primary'>
                                <Tab
                                    label='Surveys'
                                    component={Link}
                                    to={{
                                        pathname: RoutePaths.ADMIN_SURVEYS,
                                        search: location.search,
                                    }}
                                    value={SurveysTab.SURVEYS}
                                />
                                <Tab
                                    label='Restaurants Avarage'
                                    component={Link}
                                    to={{
                                        pathname: RoutePaths.ADMIN_SURVEYS_RESTAURANTS,
                                        search: location.search,
                                    }}
                                    value={SurveysTab.SURVEYS_RESTAURANTS}
                                />
                            </Tabs>

                            <Grid item xs={12} style={{ padding: 24 }}>
                                <Grid container direction='row' spacing={3} alignItems='center'>
                                    <Grid item>
                                        <DatePicker
                                            variant='inline'
                                            label={translate('From')}
                                            value={from}
                                            onChange={(date) => {
                                                setFrom(date?.format('YYYY-MM-DD') as any);
                                                setReload(true);
                                            }}
                                            format='ll'
                                            autoOk
                                        />
                                    </Grid>
                                    <Grid item>
                                        <DatePicker
                                            variant='inline'
                                            label={translate('To')}
                                            value={to}
                                            onChange={(date) => {
                                                setTo(date?.format('YYYY-MM-DD') as any);
                                                setReload(true);
                                            }}
                                            format='ll'
                                            autoOk
                                        />
                                    </Grid>
                                    <Grid item>
                                        <Button primary type='submit' onClick={load} disabled={loading}>
                                            {loading ? translate('Searching') : translate('Search')}
                                        </Button>
                                    </Grid>
                                </Grid>
                            </Grid>

                            <Switch>
                                <Route
                                    path={RoutePaths.ADMIN_SURVEYS}
                                    exact
                                    render={() => (
                                        <div>
                                            <RatingDetails vm={vm} />
                                        </div>
                                    )}
                                />
                            </Switch>
                        </Paper>
                    </Grid>

                    <Switch>
                        <Route
                            path={RoutePaths.ADMIN_SURVEYS}
                            exact
                            render={() => (
                                <>
                                    {unsolved.length > 0 && (
                                        <Grid item xs={12}>
                                            <SurveysTable title={'Needs to be solved (rating 3 and lower)'} surveys={unsolved} loading={loading} onClickRefresh={load} />
                                        </Grid>
                                    )}
                                    <Grid item xs={12}>
                                        <SurveysTable surveys={vm.surveys} loading={loading} onClickRefresh={load} />
                                    </Grid>
                                </>
                            )}
                        />
                        <Route
                            path={RoutePaths.ADMIN_SURVEYS_RESTAURANTS}
                            render={() => (
                                <Grid item xs={12}>
                                    <RestaurantSurveysTable restaurantSurveys={vm.restaurants} loading={loading} onClickRefresh={load} />
                                </Grid>
                            )}
                        />
                    </Switch>
                </Grid>
            </div>
        </>
    );
}

const SurveysTab = Object.freeze({
    SURVEYS: RoutePaths.ADMIN_SURVEYS,
    SURVEYS_RESTAURANTS: RoutePaths.ADMIN_SURVEYS_RESTAURANTS,
});
