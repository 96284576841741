import { makeStyles } from '@material-ui/core';
import * as React from 'react';
import { useState } from 'react';
import { updateOrderTicketRequestedApi } from 'src/api/letseatmanager/order/updateOrderTicketRequestedApi';
import { app2 } from 'src/app2';
import { Button } from 'src/components/Button';
import { translate } from 'src/i18n/translate';
import { getPosInterfaceLargerEnabledRestaurantSettingInLocalStorage } from 'src/localStorage/getPosInterfaceLargerEnabledRestaurantSettingInLocalStorage';
import { savePosOrderOffline } from 'src/localStorage/savePosOrderOffline';
import { posReducer } from 'src/reducers/posReducer';
import { useMapOrderItemsToCartItems } from 'src/services/pos/useMapOrderItemsToCartItems';
import { usePrintOrderTicket } from 'src/services/printer/usePrintOrderTicket';
import { alertKnownErrorOrSomethingWentWrong } from 'src/utils/alert/alertKnownErrorOrSomethingWentWrong';
import { isDeliveryOrder } from 'src/utils/order/isDeliveryOrder';
import { isEatHereOrder } from 'src/utils/order/isEatHereOrder';
import { findItemsChangedInOrder } from 'src/utils/pos/findItemsChangedInOrder';
import { useHasPrintersToPrint } from 'src/utils/printer/useHasPrintersToPrint';
import { useAction } from 'src/utils/react/useAction';
import { useSelector } from 'src/utils/react/useSelector';
import { useUpdatePosPendingOrder } from 'src/utils/react/useUpdatePosPendingOrder';

export function PosPrintBillButton(): React.ReactElement | null {
    const classes = useStyles();

    const posInterfaceLargerEnabled = getPosInterfaceLargerEnabledRestaurantSettingInLocalStorage();
    const mapOrderItemsToCartItems = useMapOrderItemsToCartItems();

    const [loading, setLoading] = useState(false);

    const { hasPrintersToPrint } = useHasPrintersToPrint();

    const [printOrderTicket] = usePrintOrderTicket();
    const { updatePosPendingOrder } = useUpdatePosPendingOrder();

    const orderType = useSelector((state) => state.pos.orderType);
    const items = useSelector((state) => state.pos.items);
    const total = useSelector((state) => state.pos.payment?.total);
    const pendingOrder = useSelector((state) => state.pos.pendingOrder);
    const disabledPosPaymentActions = useSelector((state) => state.pos.disabledPosPaymentActions);

    const setPendingOrder = useAction(posReducer.actions.setPendingOrder);
    const setItems = useAction(posReducer.actions.setItems);
    const updateOrder = useAction(app2.actions.updateOrder);

    const itemsToPrint = findItemsChangedInOrder(pendingOrder?.orderItems ?? ([] as any), items);
    const disabledButtons = !total && !itemsToPrint?.length;
    const disabledPrintTicket = disabledButtons || !hasPrintersToPrint || loading;

    const handlePrintTicket = async () => {
        if (!pendingOrder?.ticketRequestedAt && isEatHereOrder(pendingOrder.orderType)) {
            updateOrderTicketRequestedApi({ orderId: pendingOrder?.orderId });
        }
        await updatePendingOrder();
    };

    const updatePendingOrder = async () => {
        if (loading || disabledPosPaymentActions) return;
        setLoading(true);
        const { response, request } = await updatePosPendingOrder({
            firstName: pendingOrder?.firstName,
            lastName: pendingOrder?.lastName,
            mobileNumber: pendingOrder.customerMobileNumber,
            email: pendingOrder.email,
            customerNote: pendingOrder.customerNote,
        });
        setLoading(false);

        if (!response.ok) {
            if (response.problem === 'NETWORK_ERROR') {
                savePosOrderOffline(request);
                return;
            }
            return alertKnownErrorOrSomethingWentWrong(response);
        }

        setPendingOrder(response.data);
        setItems(mapOrderItemsToCartItems(response.data.orderItems));
        updateOrder({ order: response.data });

        await printOrderTicket(response.data as any);
    };

    if (!pendingOrder || isDeliveryOrder(orderType)) return null;

    return (
        <Button larger={posInterfaceLargerEnabled} classes={{ button: classes.basicButton }} secondary disabled={disabledPrintTicket} onClick={handlePrintTicket}>
            {translate('Print Bill')}
        </Button>
    );
}

const useStyles = makeStyles((theme) => ({
    basicButton: {
        width: '100%',
        height: 50,
        color: theme.palette.text.secondary,
        '&:hover': {
            cursor: 'pointer',
        },
    },
}));
