export const LogEventLevels = {
    CRITICAL: 'CRITICAL',
    ERROR: 'ERROR',
    WARNING: 'WARNING',
    INFO: 'INFO',
    UI_INTERACTION: 'UI_INTERACTION',
    UI_ERROR: 'UI_ERROR',
} as const;

export type LogEventLevel = (typeof LogEventLevels)[keyof typeof LogEventLevels];
