import * as React from 'react';
import { useMaximumCashInCashRegisterSubscriptionUpdates } from 'src/services/cashRegister/useMaximumCashInCashRegisterSubscriptionUpdates';
import { usePosBusinessDaySubscriptionUpdates } from 'src/services/posBusinessDay/usePosBusinessDaySubscriptionUpdates';

export function PosBusinessDaySubscriptionUpdates(): React.ReactElement | null {
    usePosBusinessDaySubscriptionUpdates();
    useMaximumCashInCashRegisterSubscriptionUpdates();

    return null;
}
