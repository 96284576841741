import { IconButton, makeStyles, Tooltip } from '@material-ui/core';
import LinkIcon from '@material-ui/icons/Link';
import * as React from 'react';
import { DriverIcon } from 'src/icons/DriverIcon';
import type { OrderId } from 'src/types/Id';
import type { LiveOrderVm } from 'src/types/LiveOrderVm';
import { fullName } from 'src/utils/customer/fullName';
import { minutesFromNow } from 'src/utils/date/minutesFromNow';
import { toHumanizedOrderId } from 'src/utils/order/toHumanizedOrderId';
import { openDriverPageInNewTab } from 'src/utils/window/openDriverPageInNewTab';
import { openOrderPageInNewTab } from 'src/utils/window/openOrderPageInNewTab';

export function LiveOrderInfo({ order, openAssignDriverDialog }: Props): React.ReactElement {
    const styles = useStyles();

    const bgColors = {
        red: '#DC3545',
        blue: '#4697FA',
        orange: '#FF6320',
        yellow: '#E8B749',
    } as const;

    const getColor = (order: LiveOrderVm) => {
        if (order.driverId) return bgColors.blue;

        const minutesWaiting = Math.abs(minutesFromNow(order.startedAt));

        if (minutesWaiting >= 10) return bgColors.red;
        if (minutesWaiting < 10 && minutesWaiting >= 5) return bgColors.orange;

        return bgColors.yellow;
    };

    if (order.driverId) {
        return (
            <div className={styles.orderContainer}>
                <span className={styles.orderStatus} style={{ background: getColor(order) }}>
                    #{toHumanizedOrderId(order.orderId)}
                </span>
                <Tooltip title={fullName(order.driver as any)}>
                    <IconButton className={styles.driverIcon} onClick={() => openDriverPageInNewTab(order.driverId)}>
                        <DriverIcon color='#000' />
                    </IconButton>
                </Tooltip>
                <LinkIcon className={styles.icon} onClick={() => openOrderPageInNewTab(order.orderId)}></LinkIcon>
            </div>
        );
    }

    return (
        <div className={styles.orderContainer}>
            <span className={styles.orderStatus} style={{ background: getColor(order) }} onClick={() => openAssignDriverDialog(order.orderId)}>
                #{toHumanizedOrderId(order.orderId)}
            </span>
            <LinkIcon className={styles.icon} onClick={() => openOrderPageInNewTab(order.orderId)}></LinkIcon>
        </div>
    );
}

const useStyles = makeStyles((theme) => ({
    icon: {
        marginLeft: 10,
        cursor: 'pointer',
    },
    driverIcon: {
        marginLeft: 10,
        width: 50,
    },
    orderContainer: {
        margin: 5,
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        paddingRight: theme.spacing(3),
    },
    orderStatus: {
        padding: '5px 20px',
        borderRadius: 8,
        color: 'white',
        fontFamily: theme.typography.regular,
        cursor: 'pointer',
    },
}));

type Props = {
    order: LiveOrderVm;
    openAssignDriverDialog: (orderId: OrderId) => void;
};
