import { letseatadminApiMethod } from 'src/api/letseatadminApi';
import type { ApiSauceResponse } from 'src/api/types/ApiSauceResponse';
import type { DynamicDeliveryEarningReason } from 'src/constants/DynamicDeliveryEarningReason';
import type { OrderId } from 'src/types/Id';
import type { OrderVm } from 'src/types/OrderVm';

export async function changeDynamicDeliveryEarningsApi(request: ChangeDynamicDeliveryEarningsApiRequest): ApiSauceResponse<OrderVm> {
    return letseatadminApiMethod('dynamicDeliveryEarnings/changeDynamicDeliveryEarningsApi', request);
}

export type ChangeDynamicDeliveryEarningsApiRequest = {
    orderId: OrderId;
    amount: string;
    reason?: DynamicDeliveryEarningReason;
};
