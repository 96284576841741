import { makeStyles } from '@material-ui/core';
import Divider from '@material-ui/core/Divider';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import Menu from '@material-ui/core/Menu';
import { useTheme } from '@material-ui/core/styles';
import PopupState, { bindMenu, bindTrigger } from 'material-ui-popup-state';
import * as React from 'react';
import { app2 } from 'src/app2';
import { Button } from 'src/components/Button';
import { Text } from 'src/components/Text';
import { Cities, City } from 'src/constants/City';
import { CitiesWithPideDirectoDelivery } from 'src/constants/CityWithDeliveryPd';
import { translate } from 'src/i18n/translate';
import { MapPinIcon } from 'src/icons/MapPinIcon';
import { useAction } from 'src/utils/react/useAction';
import { useSelector } from 'src/utils/react/useSelector';

export function AppBarCitySelect(): React.ReactElement | null {
    const theme = useTheme();
    const classes = useStyles();

    const cities = useSelector((state) => state.app2.cities);
    const kitchensUser = useSelector((state) => state.authentication.kitchensUser);
    const viewUser = useSelector((state) => state.authentication.viewUser);
    const internalUser = useSelector((state) => state.authentication.internalUser);

    const setCity = useAction(app2.actions.setCity);
    const setCities = useAction(app2.actions.setCities);

    const showSpecialActions = internalUser || viewUser || kitchensUser;

    const getCitiesText = () => {
        if (!cities || !cities.length) return translate('Location');

        if (cities.length > 3) {
            const citiesList = cities
                .slice(0, 3)
                .map((city) => translate(city))
                .join(', ');
            const leftCitiesLength = cities.length - 3;
            return translate('@citiesList and @leftCitiesLength more', { citiesList, leftCitiesLength });
        }

        return cities?.map((city: City) => translate(city)).join(', ');
    };

    if (!showSpecialActions) return null;

    return (
        <PopupState key='city-button' variant='popover' popupId='demo-popup-menu'>
            {(popupState) => (
                <>
                    <Button secondary key='logout-button' {...(bindTrigger(popupState) as any)} classes={{ button: classes.button }}>
                        <MapPinIcon color={theme.palette.icons.brand} />
                        {getCitiesText()}
                    </Button>
                    <Menu {...bindMenu(popupState)}>
                        <List component='nav'>
                            {Object.values(Cities).map((city: any, index) => {
                                const isCitySelected = cities?.some((_city: City) => _city === city);
                                return (
                                    <React.Fragment key={city}>
                                        <ListItem
                                            key={city}
                                            button
                                            onClick={() => {
                                                setCity(city);
                                                if (isCitySelected) setCities(cities?.filter((_city: City) => _city !== city));
                                                else setCities([...(cities ?? []), city]);
                                                popupState.close();
                                            }}
                                            style={isCitySelected ? { backgroundColor: theme.palette.surface.brand, color: theme.palette.text.invert } : {}}
                                        >
                                            <Text>{translate(city)}</Text>
                                        </ListItem>

                                        {index === Object.values(CitiesWithPideDirectoDelivery).length - 1 && <Divider />}
                                    </React.Fragment>
                                );
                            })}
                        </List>

                        <Divider />

                        <List component='nav'>
                            <ListItem
                                button
                                onClick={() => {
                                    setCity(undefined);
                                    setCities(undefined);
                                    popupState.close();
                                }}
                            >
                                <Text>{translate('Clear Filter')}</Text>
                            </ListItem>
                        </List>
                    </Menu>
                </>
            )}
        </PopupState>
    );
}

const useStyles = makeStyles((theme) => ({
    icon: {
        color: theme.palette.primary.main,
        textTransform: 'none',
    },
    posIcon: {
        color: 'white',
    },
    button: {
        height: 48.5,
    },
}));

type Props = {
    changeAppBarColor?: boolean;
};
