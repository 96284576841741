import * as React from 'react';

export function ClutteryIcon({ color, title }: Props): React.ReactElement {
    return (
        <svg width='35' height='40' viewBox='0 0 35 40' fill='none' xmlns='http://www.w3.org/2000/svg'>
            {title && <title>title</title>}
            <path
                d='M32.5 0C31.25 0 22.5 2.5 22.5 13.75V22.5C22.5 25.2578 24.7422 27.5 27.5 27.5H30V37.5C30 38.8828 31.1172 40 32.5 40C33.8828 40 35 38.8828 35 37.5V27.5V18.75V2.5C35 1.11719 33.8828 0 32.5 0ZM5 1.25C5 0.609375 4.52344 0.078125 3.88281 0.0078125C3.24219 -0.0625 2.67188 0.359375 2.53125 0.976562L0.164062 11.625C0.0546875 12.1172 0 12.6172 0 13.1172C0 16.7031 2.74219 19.6484 6.25 19.9688V37.5C6.25 38.8828 7.36719 40 8.75 40C10.1328 40 11.25 38.8828 11.25 37.5V19.9688C14.7578 19.6484 17.5 16.7031 17.5 13.1172C17.5 12.6172 17.4453 12.1172 17.3359 11.625L14.9688 0.976562C14.8281 0.351562 14.2422 -0.0625 13.6094 0.0078125C12.9766 0.078125 12.5 0.609375 12.5 1.25V11.7344C12.5 12.1562 12.1562 12.5 11.7344 12.5C11.3359 12.5 11.0078 12.1953 10.9688 11.7969L9.99219 1.14062C9.9375 0.492188 9.39844 0 8.75 0C8.10156 0 7.5625 0.492188 7.50781 1.14062L6.53906 11.7969C6.5 12.1953 6.17188 12.5 5.77344 12.5C5.35156 12.5 5.00781 12.1562 5.00781 11.7344V1.25H5ZM8.77344 13.125H8.75H8.72656L8.75 13.0703L8.77344 13.125Z'
                fill={color ?? '#64d6b3'}
            />
        </svg>
    );
}

type Props = {
    color?: string;
    title?: string;
};
