import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import Grid from '@material-ui/core/Grid';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import moment from 'moment/moment';
import * as React from 'react';
import { letseatmanagerApiDeprecated } from 'src/api/letseatmanagerApiDeprecated';
import type { FilterState } from 'src/components/input/FromToPaymentDateFilter';
import { OpenInNewTabToolbarButton } from 'src/components/mui-datatables/OpenInNewTabToolbarButton';
import { RefreshToolbarButton } from 'src/components/mui-datatables/RefreshToolbarButton';
import { UpdatingContentProgress } from 'src/components/UpdatingContentProgress';
import { RoutePaths } from 'src/constants/RoutePath';
import { translate } from 'src/i18n/translate';
import { OrdersTable } from 'src/scenes/letseatadmin/order/OrdersTable';
import type { KitchenId } from 'src/types/Id';
import type { OrderVm } from 'src/types/OrderVm';
import { alertKnownErrorOrSomethingWentWrong } from 'src/utils/alert/alertKnownErrorOrSomethingWentWrong';
import { deepEqual } from 'src/utils/object/deepEqual';

export default withStyles((theme) => ({
    root: {
        paddingLeft: 8,
        paddingRight: 8,
    },
    table: {
        whiteSpace: 'nowrap',
        '& tr': { cursor: 'pointer' },
        '& tr:hover': { backgroundColor: 'rgba(0, 0, 0, 0.07) !important' },
    },
    toolbar: {
        paddingRight: theme.spacing(3),
    },
}))(
    class KitchenBillingDataForKitchen extends React.Component<Props, State> {
        state = {
            loading: false,
            kitchenId: '' as any,
            kitchenName: '',
            kitchenZone: '',
            cash: '0',
            app: '0',
            totalSold: '0',
            fixedCommission: '0',
            commission: '0',
            iva: '0',
            toDeposit: '0',
            orders: [],
            notAccepted: undefined,
        };

        async componentDidMount() {
            await this.load();
        }

        async componentDidUpdate(prevProps: Props, prevState: State) {
            if (!deepEqual(prevProps.filter, this.props.filter)) {
                await this.load();
            }
        }

        // shouldComponentUpdate(nextProps: Props, nextState: State): boolean {
        //     return !deepEqual(this.props, nextProps) || !deepEqual(this.state, nextState);
        // }

        load = async () => {
            this.setState({ loading: true });
            const request: Request = {
                kitchenId: this.props.kitchenId,
                from: moment(this.props.filter.from).format('YYYY-MM-DD'),
                to: moment(this.props.filter.to).format('YYYY-MM-DD'),
            };
            const response = await letseatmanagerApiDeprecated.admin.getKitchenBillingDataForKitchen(request);
            this.setState({ loading: false });
            if (!response.ok) {
                alertKnownErrorOrSomethingWentWrong(response);
                return;
            }
            this.setState({
                kitchenId: response.data.kitchenId,
                kitchenName: response.data.kitchenName,
                kitchenZone: response.data.kitchenZone,
                cash: response.data.cash,
                app: response.data.app,
                totalSold: response.data.totalSold,
                fixedCommission: response.data.fixedCommission,
                commission: response.data.commission,
                iva: response.data.iva,
                toDeposit: response.data.toDeposit,
                orders: response.data.orders,
                notAccepted: response.data.notAccepted,
            });
            this.props.onLoad && this.props.onLoad(`${response.data.kitchenName} ${this.state.kitchenZone}`);
        };

        render() {
            const { classes } = this.props;
            return (
                <Grid container spacing={5}>
                    <Grid item xs={12}>
                        <UpdatingContentProgress loading={this.state.loading} />
                        <Card classes={{ root: classes.root }}>
                            <CardHeader
                                title={
                                    <Typography display='block' gutterBottom variant='h6'>
                                        {this.state.kitchenName} {this.state.kitchenZone}
                                    </Typography>
                                }
                                action={
                                    <div>
                                        {this.props.kitchenId && (
                                            <>
                                                <OpenInNewTabToolbarButton onClick={() => window.open(`${RoutePaths.THE_RESTAURANT}?restaurant=${this.props.kitchenId}`, '_blank')} />
                                            </>
                                        )}
                                        <RefreshToolbarButton onClick={this.load} />
                                    </div>
                                }
                            />
                            <CardContent>
                                <Grid container spacing={3}>
                                    <Grid item xs={6} sm={3}>
                                        <Typography variant='caption' display='block' gutterBottom color='textSecondary'>
                                            Cash
                                        </Typography>
                                        <Typography variant='body1' gutterBottom>
                                            {this.state.cash}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={6} sm={3}>
                                        <Typography variant='caption' display='block' gutterBottom color='textSecondary'>
                                            App
                                        </Typography>
                                        <Typography variant='body1' gutterBottom>
                                            {this.state.app}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={6} sm={3}>
                                        <Typography variant='caption' display='block' gutterBottom color='textSecondary'>
                                            Total Sold
                                        </Typography>
                                        <Typography variant='body1' gutterBottom>
                                            {this.state.totalSold}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={6} sm={3}>
                                        <Typography variant='caption' display='block' gutterBottom color='textSecondary'>
                                            Commission
                                        </Typography>
                                        <Typography variant='body1' gutterBottom>
                                            {this.state.commission}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={6} sm={3}>
                                        <Typography variant='caption' display='block' gutterBottom color='textSecondary'>
                                            IVA
                                        </Typography>
                                        <Typography variant='body1' gutterBottom>
                                            {this.state.iva}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={6} sm={3}>
                                        <Typography variant='caption' display='block' gutterBottom color='textSecondary'>
                                            To Deposit
                                        </Typography>
                                        <Typography variant='body1' gutterBottom>
                                            {this.state.toDeposit}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={6} sm={3}>
                                        <Typography variant='caption' display='block' gutterBottom color='textSecondary'>
                                            OrdersPage
                                        </Typography>
                                        <Typography variant='body1' gutterBottom>
                                            {this.state.orders.length}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={6} sm={3}>
                                        <Typography variant='caption' display='block' gutterBottom color='textSecondary'>
                                            {translate('Fixed Commission')}
                                        </Typography>
                                        <Typography variant='body1' gutterBottom>
                                            {this.state.fixedCommission}
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </CardContent>
                        </Card>
                    </Grid>
                    <Grid item xs={12}>
                        <OrdersTable title='OrdersPage' orders={this.state.orders} loading={this.state.loading} onClickRefresh={this.load} />
                    </Grid>
                    {this.state.notAccepted && (
                        <Grid item xs={12}>
                            <OrdersTable title='WARNING ORDERS NOT ACCEPTED YET' orders={this.state.notAccepted} loading={this.state.loading} onClickRefresh={this.load} />
                        </Grid>
                    )}
                </Grid>
            );
        }
    },
) as React.ComponentType<any>;

type Props = {
    filter: FilterState;
    kitchenId: KitchenId;
    onLoad?: (arg1: string) => Record<any, any>;
    classes: any;
};

type State = {
    loading: boolean;
} & KitchenBillingDataVm;

type Request = {
    from: string;
    to: string;
    kitchenId: KitchenId;
};

type KitchenBillingDataVm = {
    kitchenId: KitchenId;
    kitchenName: string;
    kitchenZone: string;
    cash: string;
    app: string;
    totalSold: string;
    fixedCommission: string;
    commission: string;
    iva: string;
    toDeposit: string;
    orders: Array<OrderVm>;
    notAccepted?: Array<OrderVm>;
};
