import { withStyles } from '@material-ui/core/styles';
import MUIDataTable from 'mui-datatables';
import * as React from 'react';
import { AddCreditsToolbarButton } from 'src/components/mui-datatables/AddCreditsToolbarButton';
import { ChangeToolbarButton } from 'src/components/mui-datatables/ChangeToolbarButton';
import { CustomToolbarSelect } from 'src/components/mui-datatables/CustomToolbarSelect';
import { DownloadCsvToolbarButton } from 'src/components/mui-datatables/DownloadCsvToolbarButton';
import { RefreshToolbarButton } from 'src/components/mui-datatables/RefreshToolbarButton';
import { SendEmailToolbarButton } from 'src/components/mui-datatables/SendEmailToolbarButton';
import { SendNotificationToolbarButton } from 'src/components/mui-datatables/SendNotificationToolbarButton';
import { UpdatingContentProgress } from 'src/components/UpdatingContentProgress';
import { history } from 'src/config/history';
import type { CustomerActivity } from 'src/constants/CustomerActivity';
import type { CustomerType } from 'src/constants/CustomerType';
import type { Platform } from 'src/constants/Platform';
import type { RefundMethod } from 'src/constants/RefundMethod';
import { RoutePaths } from 'src/constants/RoutePath';
import { translate } from 'src/i18n/translate';
import { CHANGE_CUSTOMER } from 'src/scenes/letseatadmin/customer/ChangeCustomerDialog';
import { ADD_CREDITS } from 'src/scenes/letseatadmin/customer/CustomerCreditsDialog';
import { SEND_NOTIFICATION } from 'src/scenes/letseatadmin/customer/CustomerSendDialog';
import { SEND_EMAIL } from 'src/scenes/letseatadmin/customer/CustomerSendEmailDialog';
import type { CustomerId, LanguageTag } from 'src/types/Id';
import { fullName } from 'src/utils/customer/fullName';
import { getCustomerTypeColor } from 'src/utils/customer/getCustomerTypeColor';
import { formatDateTimeString } from 'src/utils/date/formatDateTimeString';
import { setUrlQueryParameter } from 'src/utils/history/setUrlQueryParameter';
import { createFilteredOnDownload } from 'src/utils/mui-datatables/createFilteredOnDownload';
import { createHandleClickDownloadCsv } from 'src/utils/mui-datatables/createHandleClickDownloadCsv';
import { getSelectedRows } from 'src/utils/mui-datatables/getSelectedRows';
import { getSelectedRowsData } from 'src/utils/mui-datatables/getSelectedRowsData';
import { deepEqual } from 'src/utils/object/deepEqual';
import { RegExps } from 'src/utils/RegExps';

export default withStyles((theme) => ({
    table: {
        whiteSpace: 'nowrap',
        '& tr': { cursor: 'pointer' },
        '& tr:hover': { backgroundColor: 'rgba(0, 0, 0, 0.07) !important' },
    },
    toolbar: {
        paddingRight: theme.spacing(3),
    },
}))(
    class CustomersTable extends React.Component<Props> {
        table: any; // TODO: fix correct type

        shouldComponentUpdate(nextProps: Props): boolean {
            return !deepEqual(this.props, nextProps);
        }

        render() {
            const { classes } = this.props;
            return (
                <div style={{ position: 'relative' }}>
                    <UpdatingContentProgress loading={this.props.loading} />
                    <MUIDataTable
                        /* @ts-ignore */
                        ref={(ref) => (this.table = ref)}
                        className={classes.table}
                        title={this.props.title || translate('Customers')}
                        data={this.props.customers.map((customer) => ({
                            customerId: customer.customerId,
                            name: fullName(customer.firstName, customer.lastName),
                            activity: customer.activity || '',
                            exactActivity: customer.exactActivity || '',
                            customerType: customer.customerType || '',
                            mobileNumber: customer.mobileNumber || '',
                            email: customer.email || '',
                            companyName: customer.companyName || '',
                            creditCards: customer.creditCards,
                            credits: customer.credits || '',
                            refundMethod: customer.refundMethod || '',
                            language: customer.language || '',
                            fcmPermission: customer.fcmPermission ? 'Yes' : 'No',
                            banned: customer.banned ? 'Banned' : '',
                            appVersion: customer.appVersion || '',
                            platform: customer.platform || '',
                            platformVersion: customer.platformVersion || '',
                            addCreditCardAttempts: customer.addCreditCardAttempts || '',
                            downloadedAppAt: formatDateTimeString(customer.downloadedAppAt) || '',
                            signedUpAt: formatDateTimeString(customer.signedUpAt) || '',
                            addedFirstCreditCardAt: formatDateTimeString(customer.addedFirstCreditCardAt) || '',
                            firstOrderAt: formatDateTimeString(customer.firstOrderAt) || '',
                            lastOrderAt: formatDateTimeString(customer.lastOrderAt) || '',
                        }))}
                        columns={[
                            {
                                name: 'customerId',
                                label: ' ',
                                options: {
                                    display: 'excluded',
                                    filter: false,
                                },
                            },
                            {
                                name: 'name',
                                label: translate('Name'),
                                options: {
                                    filter: false,
                                },
                            },
                            {
                                name: 'activity',
                                label: 'Activity',
                                options: {
                                    searchable: false,
                                },
                            },
                            {
                                name: 'exactActivity',
                                label: 'Exact Activity',
                                options: {
                                    searchable: false,
                                },
                            },
                            {
                                name: 'customerType',
                                label: 'Customer Type',
                                options: {
                                    searchable: false,
                                },
                            },
                            {
                                name: 'mobileNumber',
                                label: translate('Mobile Number'),
                                options: {
                                    filter: false,
                                },
                            },
                            {
                                name: 'email',
                                label: translate('Email'),
                                options: {
                                    filter: false,
                                },
                            },
                            {
                                name: 'companyName',
                                label: translate('Company Name'),
                            },
                            {
                                name: 'creditCards',
                                label: translate('Credit Cards'),
                            },
                            {
                                name: 'credits',
                                label: translate('Credits'),
                                options: {
                                    searchable: false,
                                },
                            },
                            {
                                name: 'refundMethod',
                                label: translate('Refund Method'),
                                options: {
                                    searchable: false,
                                },
                            },
                            {
                                name: 'language',
                                label: translate('Language'),
                                options: {
                                    searchable: false,
                                },
                            },
                            {
                                name: 'fcmPermission',
                                label: translate('Push Notification'),
                                options: {
                                    searchable: false,
                                },
                            },
                            {
                                name: 'banned',
                                label: translate('Banned'),
                                options: {
                                    searchable: false,
                                },
                            },
                            {
                                name: 'appVersion',
                                label: translate('App Version'),
                                options: {
                                    searchable: false,
                                },
                            },
                            {
                                name: 'platform',
                                label: translate('Platform'),
                                options: {
                                    searchable: false,
                                },
                            },
                            {
                                name: 'platformVersion',
                                label: translate('Platform Version'),
                                options: {
                                    searchable: false,
                                },
                            },
                            {
                                name: 'addCreditCardAttempts',
                                label: translate('Add Credit Card Attempts'),
                                options: {
                                    filter: false,
                                    searchable: false,
                                },
                            },
                            {
                                name: 'downloadedAppAt',
                                label: translate('Downloaded'),
                                options: {
                                    filter: false,
                                    searchable: false,
                                },
                            },
                            {
                                name: 'signedUpAt',
                                label: translate('Signed Up'),
                                options: {
                                    filter: false,
                                    searchable: false,
                                },
                            },
                            {
                                name: 'addedFirstCreditCardAt',
                                label: translate('First Credit Card'),
                                options: {
                                    filter: false,
                                    searchable: false,
                                },
                            },
                            {
                                name: 'firstOrderAt',
                                label: translate('First Order'),
                                options: {
                                    filter: false,
                                    searchable: false,
                                },
                            },
                            {
                                name: 'lastOrderAt',
                                label: translate('Last Order'),
                                options: {
                                    filter: false,
                                    searchable: false,
                                },
                            },
                        ]}
                        options={{
                            responsive: 'standard',
                            tableBodyMaxHeight: '500px',
                            selectableRows: !this.props.disabled ? 'multiple' : 'none',
                            customToolbarSelect: (selectedRows, displayData) => {
                                const selectedRowsData = getSelectedRowsData(selectedRows, displayData);
                                const selectedCustomerIds = selectedRowsData.map((d: any) => d[0]);
                                const handleClickEdit = () => {
                                    if (selectedCustomerIds.length === 1) {
                                        history.push({
                                            search: setUrlQueryParameter(CHANGE_CUSTOMER, selectedCustomerIds[0]),
                                        });
                                    } else {
                                        history.push({
                                            pathname: RoutePaths.ADMIN_CUSTOMERS_CHANGE,
                                            search: history.location.search,
                                            state: {
                                                customers: selectedRowsData.map((rowData: any) => ({
                                                    customerId: rowData[0],
                                                    name: rowData[1],
                                                })),
                                            },
                                        });
                                    }
                                };
                                const handleClickSendPushNotification = () => {
                                    if (selectedCustomerIds.length === 1) {
                                        history.push({
                                            search: setUrlQueryParameter(SEND_NOTIFICATION, selectedCustomerIds[0]),
                                        });
                                    } else {
                                        history.push({
                                            pathname: RoutePaths.ADMIN_CUSTOMERS_SEND_NOTIFICATIONS,
                                            search: history.location.search,
                                            state: {
                                                customers: selectedRowsData.map((rowData: any) => ({
                                                    customerId: rowData[0],
                                                    name: rowData[1],
                                                })),
                                            },
                                        });
                                    }
                                };
                                const handleClickSendEmail = () => {
                                    if (selectedCustomerIds.length === 1) {
                                        history.push({
                                            search: setUrlQueryParameter(SEND_EMAIL, selectedCustomerIds[0]),
                                        });
                                    } else {
                                        history.push({
                                            pathname: RoutePaths.ADMIN_CUSTOMERS_SEND_EMAILS,
                                            search: history.location.search,
                                            state: {
                                                customers: selectedRowsData.map((rowData: any) => ({
                                                    customerId: rowData[0],
                                                    name: rowData[1],
                                                })),
                                            },
                                        });
                                    }
                                };
                                const handleClickCredits = () => {
                                    if (selectedCustomerIds.length === 1) {
                                        history.push({
                                            search: setUrlQueryParameter(ADD_CREDITS, selectedCustomerIds[0]),
                                        });
                                    } else {
                                        history.push({
                                            pathname: RoutePaths.ADMIN_CUSTOMERS_CREDITS,
                                            search: history.location.search,
                                            state: {
                                                customers: selectedRowsData.map((rowData: any) => ({
                                                    customerId: rowData[0],
                                                    name: rowData[1],
                                                })),
                                            },
                                        });
                                    }
                                };
                                return (
                                    <CustomToolbarSelect>
                                        <DownloadCsvToolbarButton onClick={createHandleClickDownloadCsv(this, getSelectedRows(selectedRows, displayData))} />
                                        <ChangeToolbarButton onClick={handleClickEdit} />
                                        <SendNotificationToolbarButton onClick={handleClickSendPushNotification} />
                                        <SendEmailToolbarButton onClick={handleClickSendEmail} />
                                        <AddCreditsToolbarButton onClick={handleClickCredits} />
                                    </CustomToolbarSelect>
                                );
                            },
                            setRowProps: (row) => {
                                return {
                                    style: { backgroundColor: getCustomerTypeColor(row[4]) },
                                };
                            },
                            onRowClick: (
                                rowData: Array<string>,
                                rowMeta: {
                                    dataIndex: number;
                                    rowIndex: number;
                                },
                            ) => {
                                history.push({
                                    pathname: RoutePaths.ADMIN_CUSTOMER.replace(`:customerId(${RegExps.uuid})`, rowData[0]),
                                    search: history.location.search,
                                });
                            },
                            filterType: 'multiselect',
                            rowsPerPage: 100,
                            customToolbar: () => {
                                return (
                                    <>
                                        <RefreshToolbarButton onClick={this.props.onClickRefresh} />
                                    </>
                                );
                            },
                            onDownload: createFilteredOnDownload(this),
                            // filterList: [[], [], [], [], ['MEXICO']],
                        }}
                    />
                </div>
            );
        }
    },
) as React.ComponentType<any>;

type Props = {
    loading: boolean;
    title?: string;
    disabled?: boolean;
} & CustomersVm & {
        onClickRefresh: any;
        classes: any;
    };

type CustomersVm = {
    customers: Array<CustomerVm>;
};

type CustomerVm = {
    customerId: CustomerId;
    activity?: CustomerActivity;
    exactActivity?: CustomerActivity;
    customerType: CustomerType;
    mobileNumber?: string;
    email?: string;
    firstName?: string;
    lastName?: string;
    companyName?: string;
    creditCards?: number;
    credits?: string;
    refundMethod?: RefundMethod;
    language?: LanguageTag;
    fcmPermission?: boolean;
    banned?: boolean;
    appVersion?: string;
    platform?: Platform;
    platformVersion?: string;
    addCreditCardAttempts?: number;
    downloadedAppAt?: Date;
    signedUpAt?: Date;
    addedFirstCreditCardAt?: Date;
    firstOrderAt?: Date;
    lastOrderAt?: Date;
};
