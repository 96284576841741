import { BigNumber } from 'bignumber.js';
import { isString } from 'src/utils/string/isString';

export function requireNumberString(value: string | undefined | null): string {
    if (value === undefined) {
        throw new Error('Required value is undefined when it should be a number string');
    }
    if (value === null) {
        throw new Error('Required value is null when it should be a number string');
    }
    if (!isString(value)) {
        throw new Error(`Required value is ${value} when it should be a number string`);
    }
    if (BigNumber(value).isNaN()) {
        throw new Error(`Required value is "${value}" when it should be a number string`);
    }
    return value;
}
