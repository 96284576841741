import Grid from '@material-ui/core/Grid';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { DriverVm } from 'src/api/letseatadmin/driver/getDriverApi';
import { app2 } from 'src/app2';
import { translate } from 'src/i18n/translate';
import { DriverDetails } from 'src/scenes/letseatadmin/driver/DriverDetails';
import { DriverOrders } from 'src/scenes/letseatadmin/driver/DriverOrders';
import { DriverManualEarnings } from 'src/scenes/letseatadmin/driverManualEarning/DriverManualEarnings';
import type { DriverId } from 'src/types/Id';
import { fullName } from 'src/utils/customer/fullName';
import { useAction } from 'src/utils/react/useAction';
import { useParams } from 'src/utils/react/useParams';

export function DriverPage(): React.ReactElement {
    const { driverId } = useParams<{
        driverId: DriverId;
    }>();

    const [driver, setDriver] = useState<DriverVm | undefined>(undefined);
    const setTitle = useAction(app2.actions.setTitle);

    useEffect(() => {
        setTitle(`${translate('Drivers')} > ${driver ? fullName(driver.firstName, driver.lastName) : driverId.substring(0, 8)}`);
    }, []);

    return (
        <Grid container spacing={5} style={{ paddingBottom: 40 }}>
            <Grid item xs={12}>
                <DriverDetails driverId={driverId} onChangeDriver={setDriver} />
            </Grid>
            <Grid item xs={12}>
                <DriverOrders driverId={driverId} />
            </Grid>
            <Grid item xs={12}>
                <DriverManualEarnings driverId={driverId} />
            </Grid>
        </Grid>
    );
}
