import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Grid from '@material-ui/core/Grid';
import * as React from 'react';
import { useForm, useWatch } from 'react-hook-form';
import { letseatmanagerApiDeprecated } from 'src/api/letseatmanagerApiDeprecated';
import { Form } from 'src/components/form/Form';
import { FormCheckbox } from 'src/components/form/FormCheckbox';
import { FormCitySelect } from 'src/components/form/FormCitySelect';
import { FormColorPicker } from 'src/components/form/FormColorPicker';
import { FormCurrencyNumberStringField } from 'src/components/form/FormCurrencyNumberStringField';
import { FormDateTimePicker } from 'src/components/form/FormDateTimePicker';
import { FormDriverBonusCategorySelect } from 'src/components/form/FormDriverBonusCategorySelect';
import { FormDriverBonusTypeSelect } from 'src/components/form/FormDriverBonusTypeSelect';
import { FormDriverIncentiveTypeSelect } from 'src/components/form/FormDriverIncentiveTypeSelect';
import { FormDriversAutocomplete } from 'src/components/form/FormDriversAutocomplete';
import { FormFixErrorHint } from 'src/components/form/FormFixErrorHint';
import { FormHoursField } from 'src/components/form/FormHoursField';
import { FormIntegerField } from 'src/components/form/FormIntegerField';
import { FormNumberField } from 'src/components/form/FormNumberField';
import { FormRestaurantsAutocomplete } from 'src/components/form/FormRestaurantsAutocomplete';
import { FormTextField } from 'src/components/form/FormTextField';
import { FormZonesAutocomplete } from 'src/components/form/FormZonesAutocomplete';
import { translate } from 'src/i18n/translate';
import { appLayout } from 'src/theme/AppLayout';
import { alertKnownErrorOrSomethingWentWrong } from 'src/utils/alert/alertKnownErrorOrSomethingWentWrong';

export function CreateDriverBonusOfferDialog({ open, onClose, onDriverBonusOfferCreated }: Props): React.ReactElement {
    const form = useForm();
    const {
        formState: { isSubmitting },
        control,
    } = form;

    const priority = useWatch<boolean>({ name: 'isPriority', control });

    const handleClose = () => {
        if (isSubmitting) return;
        onClose();
    };

    const onSubmit = async (form: any) => {
        const response = await letseatmanagerApiDeprecated.admin.createDriverBonusOffer({
            name: form.name,
            description: form.description,
            driverBonusType: form.driverBonusType,
            driverIncentiveType: form.driverIncentiveType,
            earning: form.earning,
            deliveriesRequired: form.deliveriesRequired,
            driverBonusCategory: form.driverBonusCategory,
            concurrent: form.concurrent,
            autoAcceptRequired: form.autoAcceptRequired,
            city: form.city,
            hours: form.hours,
            maxMinutesToDeliver: form.maxMinutesToDeliver,
            minDrivingDistance: form.minDrivingDistance,
            minOrderSubtotal: form.minOrderSubtotal,
            restaurantIds: form.restaurantIds,
            excludedRestaurantIds: form.excludedRestaurantIds,
            zones: form.zones,
            driverPhoneNumbers: form.driverPhoneNumbers,
            excludedDriverIds: form.excludedDriverIds,
            maxUsage: form.maxUsage,
            maxUsagePerDriver: form.maxUsagePerDriver,
            isPriority: form.isPriority,
            color: form.color,
            removed: form.removed,
            startsAt: form.startsAt,
            endsAt: form.endsAt,
        });
        if (!response.ok) {
            alertKnownErrorOrSomethingWentWrong(response);
            return;
        }
        onDriverBonusOfferCreated();
        onClose();
    };

    return (
        <Dialog fullWidth scroll='paper' open={open} onClose={handleClose}>
            <Form form={form} onSubmit={onSubmit} style={appLayout.dialog.form}>
                <DialogTitle>{translate('Create Driver Bonus Offer').toUpperCase()}</DialogTitle>
                <DialogContent style={{ overflow: 'hidden' }}>
                    <FormContent priority={!!priority} />
                    <FormFixErrorHint />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} disabled={isSubmitting}>
                        {translate('Cancel').toUpperCase()}
                    </Button>
                    <Button color='primary' type='submit' disabled={isSubmitting}>
                        {isSubmitting ? translate('Creating').toUpperCase() : translate('Create').toUpperCase()}
                    </Button>
                </DialogActions>
            </Form>
        </Dialog>
    );
}

function FormContent({ priority }: { priority: boolean }) {
    return (
        <Grid container spacing={3}>
            <Grid item xs={12}>
                <FormTextField name='name' label={translate('Name')} helperText={translate('A name of the bonus that will be shown in the drivers app')} />
            </Grid>
            <Grid item xs={12}>
                <FormTextField name='description' label={translate('Description')} helperText={translate('A description of the bonus that will be shown under the bonus name in the drivers app')} />
            </Grid>
            <Grid item xs={12}>
                <FormIntegerField
                    name='maxUsage'
                    label={translate('Max Usage')}
                    placeholder={translate('Unlimited')}
                    min={1}
                    helperText={translate('The number of times this bonus can be used by all drivers in total')}
                />
            </Grid>
            <Grid item xs={12}>
                <FormIntegerField
                    name='maxUsagePerDriver'
                    label={translate('Max Usage Per Driver')}
                    placeholder={translate('Unlimited')}
                    min={1}
                    helperText={translate('The number of times this bonus can be used by a single driver in total (no reset every week)')}
                />
            </Grid>
            <Grid item xs={12}>
                <FormDriverBonusTypeSelect
                    name='driverBonusType'
                    label={translate('Driver Bonus Type')}
                    helperText={translate(
                        'FIRST_TIME_BONUS: Bonus can be used only once by a driver and only if no other bonuses have been collected previous weeks, ONE_TIME_BONUS: Bonus can be used only once by a driver, REUSABLE_BONUS: Bonus can be used multiple times by a driver',
                    )}
                    required
                />
            </Grid>
            <Grid item xs={12}>
                <FormDriverBonusCategorySelect
                    name='driverBonusCategory'
                    label={translate('Driver Bonus Category')}
                    helperText={translate(
                        'EXCLUSIVE: Once a driver has completed an Exclusive bonus, "normal" bonus become cancelled. EXTRA: Extra bonus apply ALWAYS for both "normals" and EXCLUSIVE bonus. Leave it blank for "normal" bonus.',
                    )}
                />
            </Grid>
            <Grid item xs={12}>
                <FormDriverIncentiveTypeSelect name='driverIncentiveType' label={translate('Driver Incentive Type')} helperText={translate('Select the type of incentive for the driver')} />
            </Grid>
            <Grid item xs={12}>
                <FormCurrencyNumberStringField
                    name='earning'
                    label={translate('Earning')}
                    helperText={translate('The amount a driver will earn when the required deliveries has been made and the bonus has been finished')}
                    required
                />
            </Grid>
            <Grid item xs={12}>
                <FormIntegerField
                    name='deliveriesRequired'
                    label={translate('Deliveries Required')}
                    helperText={translate('The number of deliveries to complete before the driver finishes and gets the bonus')}
                    min={1}
                    required
                />
            </Grid>
            <Grid item xs={12}>
                <FormCheckbox name='isPriority' label={translate('Is Priority')} helperText={translate('If checked this bonus can be used together with other bonuses on one delivery')} />
            </Grid>
            <Grid item xs={12}>
                {priority && <FormColorPicker name='color' title='Color del borde'></FormColorPicker>}
            </Grid>
            <Grid item xs={12}>
                <FormCheckbox name='concurrent' label={translate('Concurrent')} helperText={translate('If checked this bonus can be used together with other bonuses on one delivery')} />
            </Grid>
            <Grid item xs={12}>
                <FormCheckbox
                    name='autoAcceptRequired'
                    label={translate('Auto-accept required')}
                    helperText={translate('If checked the auto accept function in the drivers app has to be enabled in order to complete this bonus')}
                />
            </Grid>
            <Grid item xs={12}>
                <FormCitySelect name='city' label={translate('City')} placeholder={translate('Anywhere')} helperText={translate('The bonus will only be applied to deliveries in the selected city')} />
            </Grid>
            <Grid item xs={12}>
                <FormHoursField
                    name='hours'
                    label={translate('Hours')}
                    placeholder={translate('Mo-Su 00:00-24:00')}
                    helperText={translate('E.g. "Mo-Sa 07:00-22:00;Su 09:00-20:00" or "Mar Mo-Fr 11:00-14:00". see https://openingh.openstreetmap.de/evaluation_tool/#check')}
                />
            </Grid>
            <Grid item xs={12}>
                <FormNumberField
                    name='maxMinutesToDeliver'
                    label={translate('Minutes to Complete the Delivery')}
                    helperText={translate('X minutes which driver has to finish the delivery to earn the delivery bonus.')}
                />
            </Grid>
            <Grid item xs={12}>
                <FormIntegerField
                    name='minDrivingDistance'
                    label={translate('Min Driving Distance')}
                    placeholder={'0'}
                    min={0}
                    helperText={translate('The bonus will only be applied to deliveries with longer driving distance than this value')}
                />
            </Grid>
            <Grid item xs={12}>
                <FormCurrencyNumberStringField
                    name='minOrderSubtotal'
                    label={translate('Min Order Subtotal')}
                    placeholder={'0'}
                    min={0}
                    helperText={translate('The bonus will only be applied to deliveries with order subtotals larger than this value')}
                />
            </Grid>
            <Grid item xs={12}>
                <FormZonesAutocomplete
                    name='zones'
                    label={translate('Zones')}
                    placeholder={translate('All Zones')}
                    helperText={translate('The bonus will only be applied to deliveries delivered from the selected zones')}
                />
            </Grid>
            <Grid item xs={12}>
                <FormRestaurantsAutocomplete
                    name='restaurantIds'
                    label={translate('Restaurants')}
                    placeholder={translate('All restaurants')}
                    helperText={translate('The bonus will only be applied to deliveries delivered from the selected restaurants')}
                />
            </Grid>
            <Grid item xs={12}>
                <FormRestaurantsAutocomplete
                    name='excludedRestaurantIds'
                    label={translate('Excluded Restaurants')}
                    placeholder={translate('No Excluded Restaurants')}
                    helperText={translate('The bonus will not applied to deliveries delivered from the selected restaurants')}
                />
            </Grid>
            <Grid item xs={12}>
                <FormTextField
                    name='driverPhoneNumbers'
                    label={translate('Drivers Phone Number')}
                    placeholder={translate('All drivers')}
                    helperText={translate('The bonus will only be applied to deliveries delivered by the selected drivers')}
                />
            </Grid>
            <Grid item xs={12}>
                <FormDriversAutocomplete
                    name='excludedDriverIds'
                    label={translate('Excluded Drivers')}
                    placeholder={translate('Excluded drivers')}
                    helperText={translate('The bonus will not be applied to deliveries delivered by the selected drivers')}
                />
            </Grid>
            <Grid item xs={12}>
                <FormDateTimePicker name='startsAt' label={translate('Starts')} helperText={translate('If set the bonus will not be available before this date')} />
            </Grid>
            <Grid item xs={12}>
                <FormDateTimePicker name='endsAt' label={translate('Ends')} helperText={translate('If set the bonus will not be available after this date')} />
            </Grid>
        </Grid>
    );
}

type Props = {
    open: boolean;
    onClose: any;
    onDriverBonusOfferCreated: any;
};
