import { letseatadminApiMethod } from 'src/api/letseatadminApi';
import type { ApiSauceResponse } from 'src/api/types/ApiSauceResponse';
import type { DriverBannerId } from 'src/types/Id';

export async function disableDriverBannerApi(request: disableDriverBannerRequest): ApiSauceResponse<void> {
    return letseatadminApiMethod('driverBanner/disableDriverBannerApi', request);
}

export type disableDriverBannerRequest = {
    driverBannerId: DriverBannerId;
};
