/**
 * Util to remove bold and unbold ansi escape sequence styles
 * https://developer.chrome.com/docs/devtools/console/format-style/#style-ansi
 */
export function countAnsiEscapeSequenceStylingCharacters(value?: string | null): number {
    if (!value) return 0;
    const count: number = value.match(/(\u001b\[1m|\u001b\[0m)/g ?? [])?.length ?? 0;
    return count * 4;
}

// \u001b\[1m === enables text bold after
// \u001b\[0m === disabled text bold after
