import * as React from 'react';
import { Route, Switch } from 'react-router-dom';
import { PublicRoutePaths } from 'src/constants/PublicRoutePath';
import { SignInPage } from 'src/scenes/public/SignInPage';

export function PublicRoutes(): React.ReactElement {
    return (
        <>
            <Switch>
                <Route exact path={PublicRoutePaths.HOME} component={SignInPage} />
                <Route path={PublicRoutePaths.RESTAURANT_SELF_REGISTRATION} component={SignInPage} />
                <Route path={PublicRoutePaths.NOT_FOUND} component={SignInPage} />
            </Switch>
        </>
    );
}
