import * as React from 'react';

export function ClosedEyeIcon({ color, className, title }: Props): React.ReactElement {
    return (
        <svg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg' className={className}>
            {title && <title>{title}</title>}
            <path
                d='M2.94167 2.05837C2.82319 1.94797 2.66649 1.88787 2.50457 1.89072C2.34265 1.89358 2.18816 1.95917 2.07365 2.07368C1.95914 2.18819 1.89355 2.34268 1.89069 2.5046C1.88784 2.66652 1.94794 2.82322 2.05834 2.9417L17.0583 17.9417C17.1156 18.0031 17.1846 18.0524 17.2612 18.0865C17.3379 18.1207 17.4207 18.139 17.5046 18.1405C17.5885 18.142 17.6718 18.1266 17.7497 18.0951C17.8275 18.0637 17.8982 18.0169 17.9575 17.9576C18.0169 17.8982 18.0637 17.8275 18.0951 17.7497C18.1265 17.6719 18.142 17.5885 18.1405 17.5046C18.139 17.4207 18.1206 17.3379 18.0865 17.2613C18.0523 17.1846 18.0031 17.1156 17.9417 17.0584L2.94167 2.05837ZM18.8967 10.4609C18.4475 11.8088 17.6977 13.0369 16.7042 14.0525L14.1217 11.47C14.4003 10.69 14.4517 9.84684 14.27 9.03868C14.0883 8.23053 13.6809 7.49057 13.0952 6.90486C12.5095 6.31914 11.7695 5.91173 10.9614 5.73002C10.1532 5.54831 9.31007 5.59975 8.53 5.87837L6.46584 3.8142C7.58836 3.3575 8.78897 3.12344 10.0008 3.12503C14.1425 3.12503 17.655 5.81087 18.8967 9.53337C18.9967 9.83503 18.9967 10.16 18.8967 10.4609Z'
                fill={color ?? '#63D6B3'}
            />
            <path
                d='M13.125 10C13.125 10.15 13.1142 10.2975 13.0942 10.4417L9.5575 6.90588C10.0008 6.84247 10.4526 6.87507 10.8822 7.0015C11.3118 7.12792 11.7092 7.3452 12.0475 7.63863C12.3858 7.93205 12.6571 8.29476 12.843 8.70218C13.0289 9.1096 13.1251 9.55222 13.125 10ZM10.4417 13.0942L6.90583 9.55754C6.83716 10.0379 6.88128 10.5276 7.03468 10.9879C7.18809 11.4482 7.44658 11.8665 7.78967 12.2095C8.13275 12.5526 8.55102 12.8111 9.01133 12.9645C9.47164 13.1179 9.96135 13.162 10.4417 13.0934V13.0942Z'
                fill={color ?? '#63D6B3'}
            />
            <path
                d='M5.625 9.99999C5.625 9.48415 5.71417 8.98916 5.87834 8.52999L3.295 5.94666C2.30175 6.96236 1.55233 8.19051 1.10334 9.53832C1.00334 9.83999 1.00334 10.165 1.10334 10.4667C2.34417 14.1892 5.85667 16.875 9.99917 16.875C11.2492 16.875 12.4433 16.63 13.5342 16.1858L11.47 14.1217C10.8097 14.3572 10.1025 14.4306 9.40789 14.3358C8.71329 14.2409 8.05166 13.9805 7.47873 13.5765C6.90581 13.1725 6.43835 12.6367 6.11575 12.0143C5.79315 11.3919 5.62484 10.701 5.625 9.99999Z'
                fill={color ?? '#63D6B3'}
            />
        </svg>
    );
}

type Props = {
    color?: string;
    className?: string;
    title?: string;
};
