import { makeStyles } from '@material-ui/core';
import * as React from 'react';
import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Button } from 'src/components/Button';
import { GoBackButton } from 'src/components/button/GoBackButton';
import { SecuredPage } from 'src/components/page/SecuredPage';
import { RolePermissions } from 'src/constants/RolePermission';
import { RoutePaths } from 'src/constants/RoutePath';
import { translate } from 'src/i18n/translate';
import { ClipboardIcon } from 'src/icons/ClipboardIcon';
import { EditIcon } from 'src/icons/EditIcon';
import { UploadIcon } from 'src/icons/UploadIcon';
import { SelectBuyOrderDialog } from 'src/scenes/letseatmanager/inventory/buyOrder/SelectBuyOrderDialog';
import { BuyOrderId } from 'src/types/Id';
import { classNames } from 'src/utils/react/classNames';

export function SelectBuyOrderCreationMethodPage(): React.ReactElement {
    const classes = useStyles();
    const history = useHistory();

    const [selectBuyOrderDialogState, setSelectBuyOrderDialogState] = useState({ open: false });

    const goToRegisterManualBuyOrderPage = (buyOrderId?: BuyOrderId) => {
        history.push(
            {
                pathname: RoutePaths.INVENTORY_CREATE_BUY_ORDER,
                search: history.location.search,
            },
            { buyOrderId },
        );
    };

    const goToUploadBuyOrderPage = () => {
        history.push({
            pathname: RoutePaths.UPLOAD_BUY_ORDER,
            search: history.location.search,
        });
    };

    return (
        <SecuredPage rolePermission={RolePermissions.BUY_ORDERS_PAGE} title={translate('Select Buy Order')} className={classes.container}>
            <GoBackButton />
            <div className={classes.container}>
                <h1 className={classes.title}>{translate('Create a purchase order')}</h1>
                <p className={classes.text}>{translate('Add a purchase order to update your inventory in just a few minutes')}</p>

                <div className={classes.boxContainer}>
                    <div className={classNames(classes.box, classes.purpleBox)} onClick={() => setSelectBuyOrderDialogState({ open: true })}>
                        <div className={classes.content}>
                            <div className={classNames(classes.icon, classes.clipboardIcon)}>
                                <ClipboardIcon color='#024B49' size={30} />
                            </div>
                            <p className={classes.boxText}>
                                {translate('Create your order using a')} <strong> {translate('previus file')} </strong>
                            </p>
                            <Button classes={classes.button as any} onClick={() => setSelectBuyOrderDialogState({ open: true })}>
                                {translate('Use previous order')}
                            </Button>
                        </div>
                    </div>

                    <div className={classNames(classes.box, classes.blueBox)} onClick={() => goToRegisterManualBuyOrderPage()}>
                        <div className={classes.content}>
                            <div className={classNames(classes.icon, classes.editIcon)}>
                                <EditIcon color='#124289' size={30} />
                            </div>
                            <p className={classes.boxText}>
                                {translate('Register your order')} <strong> {translate('manual way')} </strong>
                            </p>
                            <Button classes={classes.button as any} onClick={() => goToRegisterManualBuyOrderPage()}>
                                {translate('New Order')}
                            </Button>
                        </div>
                    </div>

                    <div className={classNames(classes.box, classes.greyBox)} onClick={goToUploadBuyOrderPage}>
                        <div className={classes.content}>
                            <div className={classNames(classes.icon, classes.uploadIcon)}>
                                <UploadIcon color='#2E3748' size={30} />
                            </div>
                            <p className={classes.boxText}>
                                {translate('Upload the order via a')} <strong> {translate('CSV (Excel) file')} </strong>
                            </p>
                            <Button classes={classes.button as any} onClick={goToUploadBuyOrderPage}>
                                {translate('Upload with file')}
                            </Button>
                        </div>
                    </div>
                </div>
            </div>
            <SelectBuyOrderDialog
                open={selectBuyOrderDialogState.open}
                onClose={() => setSelectBuyOrderDialogState({ open: false })}
                onSuccess={(buyOrderId: BuyOrderId) => goToRegisterManualBuyOrderPage(buyOrderId)}
            />
        </SecuredPage>
    );
}

const useStyles = makeStyles((theme) => ({
    container: {
        position: 'relative',
        paddingTop: 20,
    },
    boxContainer: {
        display: 'flex',
        justifyContent: 'center',
        height: '100vh',
    },
    box: {
        width: '50%',
        height: '50%',
        backgroundColor: 'white',
        borderRadius: 10,
        margin: 15,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        cursor: 'pointer',
        transition: 'box-shadow 0.3s ease',
        '&:hover': {
            boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.4)',
        },
    },
    purpleBox: {
        backgroundColor: theme.palette.surface.brand,
    },
    blueBox: {
        backgroundColor: '#F5F8FE',
    },
    greyBox: {
        backgroundColor: '#F6F6F6',
    },
    content: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-evenly',
        alignItems: 'center',
        height: '100%',
        padding: 20,
    },
    title: {
        fontFamily: theme.typography.medium,
        fontSize: 25,
        width: '100%',
    },
    text: {
        fontFamily: theme.typography.regular,
        fontSize: 15,
    },
    boxText: {
        fontFamily: theme.typography.regular,
        fontSize: 15,
        marginTop: 20,
        textAlign: 'center',
    },
    list: {
        marginTop: 10,
        listStyleType: 'none',
        padding: 0,
        width: '100%',
    },
    button: {
        backgroundColor: theme.palette.primary.dark,
        color: 'white',
        fontFamily: theme.typography.semiBold,
        fontSize: 16,
        borderRadius: 5,
        height: 40,
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        textTransform: 'none',
        marginTop: 30,
        transition: 'background-color 0.3s ease',
        '&:hover': {
            backgroundColor: theme.palette.primary.main,
        },
        border: 'none',
        padding: '29px 50px',
        cursor: 'pointer',
    },
    icon: {
        marginRight: 10,
        padding: 5,
        backgroundColor: '#C9F6E4',
        borderRadius: 60,
        width: 90,
        height: 90,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    clipboardIcon: {
        backgroundColor: theme.palette.surface.tertiary,
    },
    editIcon: {
        backgroundColor: '#E5F0FF',
    },
    uploadIcon: {
        backgroundColor: '#D9D9D9',
    },
}));
