import { makeStyles } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import * as React from 'react';
import { useEffect, useRef, useState } from 'react';
import { useWatch } from 'react-hook-form';
import { Button } from 'src/components/Button';
import { FormBrandRestaurantSelect } from 'src/components/form/FormBrandRestaurantSelect';
import { FormHiddenField } from 'src/components/form/FormHiddenField';
import { FormPasswordField } from 'src/components/form/FormPasswordField';
import { translate } from 'src/i18n/translate';
import { RemoveCsdCertificateDialog } from 'src/scenes/letseatmanager/theRestaurant/csdCertificate/RemoveCsdCertificateDialog';
import { UnlinkCsdCertificateDialog } from 'src/scenes/letseatmanager/theRestaurant/csdCertificate/UnlinkCsdCertificateDialog';
import { useReloadRestaurant } from 'src/services/restaurant/useReloadRestaurant';
import { isMexico } from 'src/utils/country/isMexico';
import { fileToBase64 } from 'src/utils/file/fileToBase64';
import { useSelector } from 'src/utils/react/useSelector';

export function CsdCertificateSettings({ onChangeRestaurant, form }: Props): React.ReactElement {
    const classes = useStyles();
    const certificateInputRef = useRef<HTMLInputElement>(null);
    const certificateKeyInputRef = useRef<HTMLInputElement>(null);

    const { setValue, control } = form;

    const [csdCertificateUploaded, setCsdCertificateUploaded] = useState(false);
    const [certificateFileName, setCertificateFileName] = useState('');
    const [certificateKeyFileName, setCertificateKeyFileName] = useState('');

    const { reloadRestaurant } = useReloadRestaurant();

    const csdCertificate = useWatch({ name: 'csdCertificate', control });
    const csdCertificateKey = useWatch({ name: 'csdCertificateKey', control });

    const [removeCsdCertificateDialogState, setRemoveCsdCertificateDialogState] = useState({ open: false });
    const [unlinkCsdCertificateDialogState, setUnlinkCsdCertificateDialogState] = useState({ open: false });

    const restaurantCountry = useSelector((state) => state.app.restaurant.country);
    const loading = useSelector((state) => state.app.loading);
    const restaurant = useSelector((state) => state.app.restaurant);

    useEffect(() => {
        setCertificateKeyFileName('');
        setCertificateFileName('');
        setCsdCertificateUploaded(restaurant.csdCertificateUploaded);
    }, [loading, restaurant]);

    const closeRemoveCsdCertificateDialog = () => setRemoveCsdCertificateDialogState({ open: false });
    const closeUnlinkCsdCertificateDialog = () => setUnlinkCsdCertificateDialogState({ open: false });

    const removeCsdCertificateDialogSuccess = async () => {
        await onChangeRestaurant();
        await reloadRestaurant(restaurant.restaurantId);
        closeRemoveCsdCertificateDialog();
    };

    const unlinkCsdCertificateDialogSuccess = async () => {
        await onChangeRestaurant();
        await reloadRestaurant(restaurant.restaurantId);
        closeUnlinkCsdCertificateDialog();
    };

    const handleCertificateInput = async (e: any) => {
        const file = e.target.files[0];
        let certificateBase64 = await fileToBase64(file);
        certificateBase64 = certificateBase64.split('base64,')[1];

        setCertificateFileName(file.name);
        setValue('csdCertificate', certificateBase64);
    };

    const handleCertificateKeyInput = async (e: any) => {
        const file = e.target.files[0];
        let certificateKeyBase64 = await fileToBase64(file);
        certificateKeyBase64 = certificateKeyBase64.split('base64,')[1];
        setCertificateKeyFileName(file.name);
        setValue('csdCertificateKey', certificateKeyBase64);
    };

    return (
        <>
            <RemoveCsdCertificateDialog open={removeCsdCertificateDialogState.open} onClose={closeRemoveCsdCertificateDialog} onSuccess={removeCsdCertificateDialogSuccess} />
            <UnlinkCsdCertificateDialog open={unlinkCsdCertificateDialogState.open} onClose={closeUnlinkCsdCertificateDialog} onSuccess={unlinkCsdCertificateDialogSuccess} />
            <Grid item xs={12}>
                {!restaurant.csdCertificateRestaurantName && <h2 className={classes.subtitle}>{translate('Digital Seal')}</h2>}
                {restaurant.csdCertificateRestaurantName && (
                    <h2 className={classes.subtitle}>{translate('Digital Seal linked to @restaurantName', { restaurantName: restaurant.csdCertificateRestaurantName })}</h2>
                )}
            </Grid>
            {!csdCertificateUploaded && !restaurant.csdCertificateRestaurantId && (
                <Grid item xs={12} md={12}>
                    <FormBrandRestaurantSelect name='csdCertificateRestaurantId' label={translate('Link This Restaurant To Other CSD Certificate')} />
                </Grid>
            )}
            {!csdCertificateUploaded && !restaurant.csdCertificateRestaurantId && (
                <>
                    <Grid item xs={12} md={3}>
                        <div className={classes.certificateContainer}>
                            <input ref={certificateInputRef} type='file' style={{ display: 'none' }} onChange={handleCertificateInput} accept='.cer' />
                            <FormHiddenField name='csdCertificate' />
                            <Button classes={{ button: classes.button }} outlined disabled={loading} onClick={() => certificateInputRef.current?.click()}>
                                {translate('Upload Certificate .cer')}
                            </Button>
                            <span className={(classes as any).fileName}>{certificateFileName ? `${certificateFileName.slice(0, 20)}...` : ''}</span>
                        </div>
                    </Grid>
                    <Grid item xs={12} md={3}>
                        <div className={classes.certificateContainer}>
                            <input ref={certificateKeyInputRef} type='file' style={{ display: 'none' }} onChange={handleCertificateKeyInput} accept='.key' />
                            <FormHiddenField name='csdCertificateKey' />
                            <Button classes={{ button: classes.button }} outlined disabled={loading} onClick={() => certificateKeyInputRef.current?.click()}>
                                {translate('Upload Key .key')}
                            </Button>
                            <span className={(classes as any).fileName}>{certificateKeyFileName ? `${certificateKeyFileName.slice(0, 20)}...` : ''}</span>
                        </div>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <FormPasswordField label={translate('Certificate Password')} name='csdCertificatePassword' required={!!csdCertificate || !!csdCertificateKey} />
                    </Grid>
                </>
            )}
            {csdCertificateUploaded && !restaurant.csdCertificateRestaurantId && isMexico(restaurantCountry) && (
                <Button secondary disabled={loading} onClick={() => setRemoveCsdCertificateDialogState({ open: true })}>
                    {translate('Remove Digital Seal')}
                </Button>
            )}
            {restaurant.csdCertificateRestaurantId && isMexico(restaurantCountry) && (
                <Button secondary disabled={loading} onClick={() => setUnlinkCsdCertificateDialogState({ open: true })}>
                    {translate('Unlink Digital Seal')}
                </Button>
            )}
        </>
    );
}

const useStyles = makeStyles((theme) => ({
    title: {
        fontFamily: theme.typography.semiBold,
        fontSize: 20,
        color: theme.palette.secondary.contrastText,
        marginLeft: 10,
    },
    subtitle: {
        fontFamily: theme.typography.medium,
        fontSize: 18,
        color: theme.palette.secondary.contrastText,
        margin: 0,
    },
    text: {
        fontFamily: theme.typography.regular,
        fontSize: 15,
    },
    certificateContainer: {
        width: '100%',
        display: 'flex',
        gap: 20,
        flexDirection: 'column',
        alignItems: 'center',
    },
    button: {
        width: '100%',
    },
}));

type Props = {
    onChangeRestaurant: any;
    form: any;
};
