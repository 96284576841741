import { letseatmanagerApiMethod } from 'src/api/letseatmanagerApi';
import type { ApiSauceResponse } from 'src/api/types/ApiSauceResponse';
import type { RestaurantId } from 'src/types/Id';
import type { InventoryTransactionVm } from 'src/types/InventoryTransactionVm';

export async function findInventoryTransactionsByRestaurantIdApi(request: FindInventoryTransactionsByRestaurantIdApiRequest): ApiSauceResponse<FindInventoryTransactionsResponse> {
    return letseatmanagerApiMethod('inventory/findInventoryTransactionsByRestaurantIdApi', request);
}

type FindInventoryTransactionsByRestaurantIdApiRequest = {
    restaurantId: RestaurantId;
};

type FindInventoryTransactionsResponse = {
    inventoryTransactions: Array<InventoryTransactionVm>;
};
