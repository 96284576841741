import * as React from 'react';

export function LinkIcon({ color, title, width, height }: Props): React.ReactElement {
    return (
        <svg width={width || 24} height={height || 24} xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill={color ?? 'currentColor'} className='w-6 h-6'>
            <title>{title}</title>
            <path
                fillRule='evenodd'
                d='M19.902 4.098a3.75 3.75 0 00-5.304 0l-4.5 4.5a3.75 3.75 0 001.035 6.037.75.75 0 01-.646 1.353 5.25 5.25 0 01-1.449-8.45l4.5-4.5a5.25 5.25 0 117.424 7.424l-1.757 1.757a.75.75 0 11-1.06-1.06l1.757-1.757a3.75 3.75 0 000-5.304zm-7.389 4.267a.75.75 0 011-.353 5.25 5.25 0 011.449 8.45l-4.5 4.5a5.25 5.25 0 11-7.424-7.424l1.757-1.757a.75.75 0 111.06 1.06l-1.757 1.757a3.75 3.75 0 105.304 5.304l4.5-4.5a3.75 3.75 0 00-1.035-6.037.75.75 0 01-.354-1z'
                clipRule='evenodd'
            />
        </svg>
    );
}

type Props = {
    color?: string;
    title?: string;
    width?: number;
    height?: number;
};
