import * as React from 'react';
import { findRecipesByRestaurantIdApi } from 'src/api/letseatmanager/recipe/findRecipesByRestaurantIdApi';
import { FormAutocomplete } from 'src/components/form/FormAutocomplete';
import type { Variant } from 'src/components/form/FormTypes';
import type { RestaurantId } from 'src/types/Id';
import { useLoadApi } from 'src/utils/react/useLoadApi';
import { useSelector } from 'src/utils/react/useSelector';

export function FormRecipeAutocomplete({ name, label, placeholder, helperText, defaultValue, disabled, required, variant, onChange, restaurantIdToRequest }: Props): React.ReactElement {
    const restaurantId = useSelector((state) => state.app.restaurantId);

    const [loading, { recipes }] = useLoadApi(findRecipesByRestaurantIdApi, { restaurantId: restaurantIdToRequest ?? restaurantId }, { initialValue: { recipes: [] } });

    const handleChange = (recipeId: any) => {
        const recipe = recipes.find((recipe) => recipe.recipeId === recipeId);
        onChange?.(recipe);
    };

    const options = required
        ? recipes.map((recipe) => ({ label: recipe.name, value: recipe.recipeId }))
        : [{ label: '', value: null }, ...recipes.map((recipe) => ({ label: recipe.name, value: recipe.recipeId }))];

    return (
        <FormAutocomplete
            name={name}
            label={label}
            placeholder={placeholder}
            helperText={helperText}
            defaultValue={defaultValue}
            options={options}
            disabled={disabled || loading}
            required={required}
            variant={variant}
            onChange={handleChange}
        />
    );
}

type Props = {
    name: string;
    label?: string;
    placeholder?: string;
    helperText?: string;
    defaultValue?: string | null | undefined;
    disabled?: boolean;
    required?:
        | boolean
        | {
              value: number;
              message: string;
          };
    variant?: Variant;
    onChange?: any;
    restaurantIdToRequest?: RestaurantId;
};
