import { Chip } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import * as React from 'react';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { createMenuCategoryApi } from 'src/api/letseatmanager/menuCategory/createMenuCategoryApi';
import { pidedirectouploadApi } from 'src/api/pidedirectouploadApi';
import { Button } from 'src/components/Button';
import { Form } from 'src/components/form/Form';
import { FormColorButtons } from 'src/components/form/FormColorButtons';
import { FormImageUpload } from 'src/components/form/FormImageUpload';
import { FormMenuCategoryFamiliesSelect } from 'src/components/form/FormMenuCategoryFamiliesSelect';
import { FormPrinterNamesAutocomplete } from 'src/components/form/FormPrinterNamesAutocomplete';
import { FormSwitch } from 'src/components/form/FormSwitch';
import { FormTextField } from 'src/components/form/FormTextField';
import { ImageTypes } from 'src/constants/ImageType';
import { translate } from 'src/i18n/translate';
import { SelectProductsDialog } from 'src/scenes/letseatmanager/menu/menuCategory/SelectProductsDialog';
import { useMenuItems } from 'src/services/menuItem/useMenuItems';
import { MenuItemId } from 'src/types/Id';
import { alertKnownErrorOrSomethingWentWrong } from 'src/utils/alert/alertKnownErrorOrSomethingWentWrong';
import { isExternalImageUrl } from 'src/utils/image/isExternalImageUrl';
import { useSelector } from 'src/utils/react/useSelector';

export function CreateMenuCategory({ onClose, onSuccess }: Props): React.ReactElement {
    const form = useForm();
    const classes = useStyles();

    const [loading, setLoading] = useState(false);
    const [selectProductsDialogState, setSelectProductsDialogState] = useState({ open: false, defaultSelectedProducts: [] });
    const [selectedProductIds, setSelectedProductIds] = useState([]);

    const restaurantId = useSelector((state) => state.app.restaurantId);
    const newDeviceManagementEnabled = useSelector((state) => state.app.restaurant?.newDeviceManagementEnabled);
    const menuCategoryFamiliesEnabled = useSelector((state) => state.app.restaurant?.menuCategoryFamiliesEnabled);

    const { products } = useMenuItems();

    const handleClose = () => {
        if (loading) return;
        onClose?.();
    };

    const openSelectProductsDialog = () => {
        setSelectProductsDialogState({ open: true, defaultSelectedProducts: selectedProductIds });
    };

    const closeSelectProductsDialog = () => {
        setSelectProductsDialogState({ open: false, defaultSelectedProducts: [] });
    };

    const handleSelectProducts = (productIds: any) => setSelectedProductIds(productIds);

    const handleDeleteProduct = (menuItemId: undefined | MenuItemId) => {
        const newProductIds = selectedProductIds.filter((productId) => productId !== menuItemId);
        setSelectedProductIds(newProductIds);
    };

    const getImageUrl = async (imageUrl: any) => {
        if (isExternalImageUrl(imageUrl)) {
            const migrateResponse = await pidedirectouploadApi.images.migrate({
                imageUrl: imageUrl,
                imageType: ImageTypes.MENU_CATEGORY,
            });
            if (!migrateResponse.ok) {
                setLoading(false);
                alertKnownErrorOrSomethingWentWrong(migrateResponse);
                return;
            }
            return migrateResponse.data;
        }
        return imageUrl;
    };

    const onSubmit = async (form: any) => {
        setLoading(true);
        const imageUrl = await getImageUrl(form.imageUrl);
        const response = await createMenuCategoryApi({
            restaurantId,
            menuItemIds: selectedProductIds,
            name: form.name,
            menuCategoryFamilyId: form.menuCategoryFamilyId,
            promotion: form.promotion,
            color: form.color,
            printerNames: form.printerNames,
            imageUrl: imageUrl,
            hideInKitchenDisplayScreen: form.hideInKitchenDisplayScreen,
        });
        if (!response.ok) {
            setLoading(false);
            alertKnownErrorOrSomethingWentWrong(response);
            return;
        }
        setLoading(false);
        onSuccess?.();
    };

    return (
        <>
            <Form onSubmit={onSubmit} form={form} className={classes.form}>
                <h2 className={classes.title}>{translate('Create category')}</h2>
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <FormImageUpload name={'imageUrl'} label={translate('Image')} />
                    </Grid>
                    <Grid item xs={12}>
                        <FormColorButtons name='color' label={translate('Color')} disabled={loading} />
                    </Grid>
                    <Grid item xs={12}>
                        <FormTextField name='name' label={translate('Name')} required disabled={loading} InputProps={{ autoFocus: true }} />
                    </Grid>
                    {menuCategoryFamiliesEnabled && (
                        <Grid item xs={12}>
                            <FormMenuCategoryFamiliesSelect name='menuCategoryFamilyId' label={translate('Family')} disabled={loading} />
                        </Grid>
                    )}
                    <Grid item xs={12}>
                        <div className={classes.productsContainer}>
                            {selectedProductIds.map((selectedProductId) => {
                                const selectedProduct = products.find((menuItem) => menuItem.menuItemId === selectedProductId);
                                return <Chip key={selectedProduct?.menuItemId} label={selectedProduct?.name} onDelete={() => handleDeleteProduct(selectedProduct?.menuItemId)} />;
                            })}
                        </div>

                        <Button outlined onClick={openSelectProductsDialog} disabled={loading}>
                            {translate('Select Products')}
                        </Button>
                    </Grid>
                    <Grid item xs={12}>
                        <FormSwitch name='promotion' label={translate('Promotion')} disabled={loading} />
                    </Grid>
                    <Grid item xs={12}>
                        <FormSwitch name='hideInKitchenDisplayScreen' label={translate('Hide in kitchen (KDS)')} disabled={loading} />
                    </Grid>
                    {newDeviceManagementEnabled && (
                        <Grid item xs={12} md={12}>
                            <FormPrinterNamesAutocomplete name='printerNames' label={translate('Select Printers')} />
                        </Grid>
                    )}
                </Grid>
                <div className={classes.buttonsContainer}>
                    {onClose && (
                        <Button secondary onClick={handleClose} disabled={loading}>
                            {translate('Cancel')}
                        </Button>
                    )}

                    <Button type='submit' disabled={loading}>
                        {loading ? translate('Creating') : translate('Create')}
                    </Button>
                </div>
            </Form>
            <SelectProductsDialog
                open={selectProductsDialogState.open}
                defaultSelectedProducts={selectProductsDialogState.defaultSelectedProducts}
                onClose={closeSelectProductsDialog}
                onSuccess={handleSelectProducts}
            />
        </>
    );
}

const useStyles = makeStyles((theme) => ({
    title: {
        color: theme.palette.text.brand,
        textAlign: 'center',
        fontFamily: theme.typography.bold,
    },
    productsContainer: {
        margin: '10px 0',
        display: 'flex',
        width: '100%',
        flexDirection: 'row',
        flexWrap: 'wrap',
    },
    form: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    buttonsContainer: {
        marginTop: 30,
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'flex-end',
        gap: 20,
        width: '100%',
        '& button': {
            width: 200,
        },
    },
}));

type Props = {
    onClose?: any;
    onSuccess?: any;
};
