import { pidedirectoReportsMethod } from 'src/api/pidedirectoReportsApi';
import type { ApiSauceResponse } from 'src/api/types/ApiSauceResponse';
import type { SalesType } from 'src/constants/SalesTypes';
import type { RestaurantId } from 'src/types/Id';

export async function getFinanceRestaurantStatisticsApi(request: GetFinanceRestaurantStatisticsRequest): ApiSauceResponse<GetFinanceStatisticsApiResponse> {
    return pidedirectoReportsMethod('/financePageReportApi', request);
}

export type GetFinanceRestaurantStatisticsRequest = {
    report: string;
    reportPage?: string;
    abcTableFilter?: string;
    restaurantIds: Array<RestaurantId>;
    fromDate: string;
    toDate: string;
    salesType: SalesType;
};

export type GetFinanceStatisticsApiResponse = Array<{
    appWithMostSales: string;
    dayWithMostSales: string;
    name: string;
    paymentMethodWithMostSales: string;
    restaurantId: RestaurantId;
    salesByAppAndDay: Array<SalesByAppAndDay>;
    salesByDay: Array<SalesByDay>;
    salesFromAppWithMostSales: number;
    salesFromDayWithMostSales: number;
    salesFromPaymentMethodWithMostSales: number;
    appFromPaymentMethodWithMostSales: string;
    salesGrowth: Array<SalesGrowth>;
}>;

export type SalesByAppAndDay = {
    app: string;
    createdAt: string;
    orderVolume: number;
    paymentMethod: string;
    total: number;
};

export type SalesByDay = {
    createdAt: string;
    orderVolume: number;
    total: number;
    taxes?: string;
};

export type SalesGrowth = {
    dataName: string;
    restaurantGrowthAmount: number;
    restaurantGrowthPercentage: number;
    restaurantPreviousAmount: number;
};
