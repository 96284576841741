import { makeStyles } from '@material-ui/core';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { Button } from 'src/components/Button';
import { Dialog } from 'src/components/Dialog';
import { DialogActions } from 'src/components/DialogActions';
import { translate } from 'src/i18n/translate';
import { useFormatAsRestaurantCurrencyNumber } from 'src/services/restaurant/useFormatAsRestaurantCurrencyNumber';
import { Uuid } from 'src/types/Id';
import { OrderPaymentVm } from 'src/types/OrderPaymentVm';
import type { OrderVm } from 'src/types/OrderVm';
import { removeDuplicates } from 'src/utils/array/removeDuplicates';
import { isCardPayment } from 'src/utils/paymentMethod/isCardPayment';
import { useSelector } from 'src/utils/react/useSelector';
import { newUuid } from 'src/utils/uuid/newUuid';

export function SelectCustomerDialog({ open, order, onClose, onSuccess }: Props): React.ReactElement {
    const classes = useStyles();
    const formatAsCurrencyNumber = useFormatAsRestaurantCurrencyNumber();

    const [posPayments, setPosPayments] = useState<Array<OrderPaymentVm & { id: Uuid }>>([]);

    const currencyFormat = useSelector((state) => state.app.restaurant.currencyFormat);

    const customerNumbers = removeDuplicates(posPayments.map((posPayment) => posPayment.customerNumber));

    useEffect(() => {
        setPosPayments(
            order.payments?.map((payment: OrderPaymentVm) => ({
                ...payment,
                id: newUuid<Uuid>(),
            })) ?? [],
        );
    }, []);

    const getValidPosPayments = (customerNumber: number | undefined) => {
        const customerPayments = posPayments.filter((posPayment) => posPayment.customerNumber === customerNumber) ?? [];
        if (!order.paidWithTerminal) return customerPayments;
        return customerPayments.filter((posPayment) => !isCardPayment(posPayment.paymentMethod)) ?? [];
    };

    const handleClose = () => {
        onClose();
    };

    const handleSelectCustomer = (customerNumber: number | undefined) => {
        onSuccess?.(customerNumber);
        handleClose();
    };

    return (
        <Dialog open={open} onClose={handleClose} title={translate('Select customer')}>
            <div className={classes.customersContainer}>
                {customerNumbers?.map((customerNumber: number | undefined) => (
                    <div key={customerNumber} onClick={() => handleSelectCustomer(customerNumber)} className={classes.customerInfo}>
                        <span className={classes.text}>{translate('Customer @customerNumber', { customerNumber })}</span>
                        {getValidPosPayments(customerNumber).map((posPayment, idx) => (
                            <span className={classes.text} key={idx}>
                                {translate('@amount @paymentMethod', {
                                    amount: formatAsCurrencyNumber(posPayment.amount),
                                    paymentMethod: translate(posPayment.paymentMethod),
                                })}
                            </span>
                        ))}
                    </div>
                ))}
            </div>
            <DialogActions>
                <Button secondary onClick={handleClose} classes={{ button: classes.button }}>
                    {translate('Cancel')}
                </Button>
            </DialogActions>
        </Dialog>
    );
}

const useStyles = makeStyles((theme) => ({
    button: {
        width: 200,
    },
    customerInfo: {
        display: 'flex',
        flexDirection: 'column',
        border: '1px solid #D9D9D9',
        borderRadius: 6,
        padding: '8px 12px',
        cursor: 'pointer',
    },
    customersContainer: {
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap',
        gap: 10,
        margin: '20px 10px',
    },
    text: {
        fontFamily: theme.typography.regular,
        fontSize: 15,
    },
}));

type Props = {
    open: boolean;
    order: OrderVm;
    onClose: any;
    onSuccess?: any;
};
