import { List } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useEffect, useMemo, useState } from 'react';
import * as React from 'react';
import { useForm, useWatch } from 'react-hook-form';
import { Button } from 'src/components/Button';
import { Dialog } from 'src/components/Dialog';
import { DialogActions } from 'src/components/DialogActions';
import { Form } from 'src/components/form/Form';
import { FormRadio } from 'src/components/form/FormRadio';
import { FormRadioGroup } from 'src/components/form/FormRadioGroup';
import { ReturnOrderReasonDialog } from 'src/components/orderCard/returnOrder/ReturnOrderReasonDialog';
import { SelectableReturnOrderItem } from 'src/components/orderCard/returnOrder/SelectableReturnOrderItem';
import { OrderReturnedType, OrderReturnedTypes } from 'src/constants/OrderReturnedType';
import { translate } from 'src/i18n/translate';
import type { OrderVm } from 'src/types/OrderVm';
import { alertKnownErrorOrSomethingWentWrongError } from 'src/utils/alert/alertKnownErrorOrSomethingWentWrongError';
import { getOrderItemsFormattedToReturn, OrderItemToReturn } from 'src/utils/order/getOrderItemsFormattedToReturn';

export function ReturnOrderDialog({ open, onClose, order, onChange, openReturnOrderDialog }: Props): React.ReactElement {
    const classes = useStyles();

    const form = useForm();
    const { setValue } = form;

    const orderItems = useMemo(() => getOrderItemsFormattedToReturn(order.orderItems), [order]);

    const [returnOrderReasonDialogState, setReturnOrderReasonDialogState] = useState({ open: false });
    const [returnedOrderItems, setReturnedOrderItems] = useState<Array<OrderItemToReturn>>([]);
    const [orderReturnedType, setOrderReturnedType] = useState<OrderReturnedType>();

    const orderReturnedTypeCheckValue = useWatch({ name: 'orderReturnedType', control: form.control });

    useEffect(() => {
        if (orderReturnedTypeCheckValue === OrderReturnedTypes.TOTAL) {
            setOrderReturnedType(OrderReturnedTypes.TOTAL);
            setValue('orderReturnedType', OrderReturnedTypes.TOTAL);

            setReturnedOrderItems(orderItems);
        }

        if (orderReturnedTypeCheckValue === OrderReturnedTypes.PARTIAL) {
            setOrderReturnedType(OrderReturnedTypes.PARTIAL);
            setValue('orderReturnedType', OrderReturnedTypes.PARTIAL);

            if (!returnedOrderItems?.length || returnedOrderItems.length === orderItems.length) setReturnedOrderItems([]);
        }
    }, [orderReturnedTypeCheckValue]);

    const handleContinue = () => {
        if (!returnedOrderItems?.length) return alertKnownErrorOrSomethingWentWrongError(translate('Select a product to return'));
        setReturnOrderReasonDialogState({ open: true });
        onClose();
    };
    const handleCancel = () => {
        onClose();
    };

    const closeReturnOrderReasonDialog = () => {
        setReturnOrderReasonDialogState({ open: false });
    };

    return (
        <div>
            <ReturnOrderReasonDialog
                open={returnOrderReasonDialogState.open}
                onChange={onChange}
                onClose={closeReturnOrderReasonDialog}
                openReturnOrderDialog={openReturnOrderDialog}
                orderReturnedType={orderReturnedType}
                returnedOrderItems={returnedOrderItems}
                order={order}
            />
            <Dialog open={open} title={translate('Return of products')}>
                <p className={classes.titleList}>{translate('Select return type')}</p>
                <Form form={form}>
                    <FormRadioGroup row name='orderReturnedType' defaultValue={orderReturnedType}>
                        <FormRadio label={translate(`OrderReturnedTypes.${OrderReturnedTypes.PARTIAL}`)} value={OrderReturnedTypes.PARTIAL} />
                        <FormRadio label={translate(`OrderReturnedTypes.${OrderReturnedTypes.TOTAL}`)} value={OrderReturnedTypes.TOTAL} />
                    </FormRadioGroup>
                </Form>
                <p className={classes.titleList}>{translate('Select items to be refunded')}</p>
                <List>
                    {orderItems?.map((item: OrderItemToReturn) => (
                        <SelectableReturnOrderItem
                            key={item.menuItemId}
                            orderItem={item}
                            orderReturnedType={orderReturnedType}
                            setReturnedOrderItems={setReturnedOrderItems}
                            returnedOrderItems={returnedOrderItems}
                        />
                    ))}
                </List>
                <DialogActions>
                    <Button secondary onClick={handleCancel}>
                        {translate('Cancel')}
                    </Button>
                    <Button onClick={handleContinue}>{translate('Continue')}</Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}

const useStyles = makeStyles((theme) => ({
    titleList: {
        fontFamily: theme.typography.medium,
        fontSize: 14,
        color: '#2E3748',
    },
    contentDialog: {
        '&::-webkit-scrollbar': {
            display: 'none',
        },
    },
    content: {
        width: '100%',
    },
}));

type Props = {
    open: boolean;
    onClose: any;
    order: OrderVm;
    onChange: any;
    openReturnOrderDialog: any;
};
