import { letseatadminApiMethod } from 'src/api/letseatadminApi';
import type { ApiSauceResponse } from 'src/api/types/ApiSauceResponse';
import type { City } from 'src/constants/City';
import type { DriverBannerImageType } from 'src/constants/DriverBannerImageType';
import type { DriverBannerVm } from 'src/types/DriverBannerVm';
import type { DriverBannerId } from 'src/types/Id';

export async function changeDriverBannerApi(request: changeDriverBannerRequest): ApiSauceResponse<DriverBannerVm> {
    return letseatadminApiMethod('driverBanner/changeDriverBannerApi', request);
}

export type changeDriverBannerRequest = {
    driverBannerId: DriverBannerId;
    text: string;
    imageUrl?: string;
    linkUrl: string;
    imageType: DriverBannerImageType;
    city?: City;
    enabled?: boolean;
};
