import { letseatmanagerApiMethod } from 'src/api/letseatmanagerApi';
import type { ApiSauceResponse } from 'src/api/types/ApiSauceResponse';
import { type RestaurantCustomerId, type RestaurantId } from 'src/types/Id';

export async function sendSmsToCustomerApi(request: SendSmsToCustomerApiRequest): ApiSauceResponse<void> {
    return letseatmanagerApiMethod('marketing/sendSmsToCustomerApi', request);
}

type SendSmsToCustomerApiRequest = {
    restaurantId: RestaurantId;
    restaurantCustomerId: RestaurantCustomerId;
    mobileNumber: string;
    smsText: string;
};
