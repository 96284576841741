import Grid from '@material-ui/core/Grid';
import { useEffect, useState } from 'react';
import * as React from 'react';
import { useForm, useWatch } from 'react-hook-form';
import { copyRestaurantInventoryApi } from 'src/api/letseatmanager/inventory/copyRestaurantInventoryApi';
import { findSuppliesByRestaurantIdApi } from 'src/api/letseatmanager/supply/findSuppliesByRestaurantIdApi';
import { Button } from 'src/components/Button';
import { Dialog } from 'src/components/Dialog';
import { DialogActions } from 'src/components/DialogActions';
import { Form } from 'src/components/form/Form';
import { FormBrandRestaurantSelect } from 'src/components/form/FormBrandRestaurantSelect';
import { FormFixErrorHint } from 'src/components/form/FormFixErrorHint';
import { FormRestaurantAutocomplete } from 'src/components/form/FormRestaurantAutocomplete';
import { Text } from 'src/components/Text';
import { UpdatingContentProgress } from 'src/components/UpdatingContentProgress';
import { translate } from 'src/i18n/translate';
import { appReducer } from 'src/reducers/appReducer';
import { RestaurantId } from 'src/types/Id';
import type { InventorySupplyVm } from 'src/types/InventorySupplyVm';
import { SupplyVm } from 'src/types/SupplyVm';
import { alertKnownErrorOrSomethingWentWrong } from 'src/utils/alert/alertKnownErrorOrSomethingWentWrong';
import { useAction } from 'src/utils/react/useAction';
import { useSelector } from 'src/utils/react/useSelector';

export function CopyRestaurantInventoryDialog({ open, onSuccess, supplies, onClose }: Props): React.ReactElement {
    const form = useForm();
    const {
        formState: { isSubmitting },
        control,
    } = form;

    const [duplicateSupply, setDuplicateSupply] = useState(false);
    const [loadingInventory, setLoadingInventory] = useState(false);

    const restaurantId = useSelector((state) => state.app.restaurantId);
    const adminUser = useSelector((state) => state.authentication.adminUser);

    const fromRestaurantId = useWatch<RestaurantId>({ name: 'fromRestaurantId', control });

    const showSnackbar = useAction(appReducer.actions.showSnackbar);

    useEffect(() => {
        setDuplicateSupply(false);
        getInventoryFromRestaurantIdToCopy();
    }, [fromRestaurantId]);

    const handleClose = () => {
        if (isSubmitting) return;
        setDuplicateSupply(false);
        onClose();
    };

    const validateDuplicateSupply = (suppliesTo: undefined | Array<InventorySupplyVm>, suppliesFrom: Array<SupplyVm>) => {
        const suppliesFromNames = suppliesFrom.map((supply) => supply.name.toUpperCase());

        const isDuplicate = !!suppliesTo?.some((supply: InventorySupplyVm) => suppliesFromNames.includes(supply.name.toUpperCase()));
        setDuplicateSupply(isDuplicate);
    };

    const getInventoryFromRestaurantIdToCopy = async () => {
        if (!fromRestaurantId) return;

        setLoadingInventory(true);
        const response = await findSuppliesByRestaurantIdApi({ restaurantId: fromRestaurantId });
        setLoadingInventory(false);
        if (!response.ok) {
            return alertKnownErrorOrSomethingWentWrong(response);
        }
        validateDuplicateSupply(supplies, response.data.supplies);
    };

    const onSubmit = async (form: any) => {
        const response = await copyRestaurantInventoryApi({
            fromRestaurantId: form.fromRestaurantId,
            toRestaurantIds: [restaurantId],
        });
        if (!response.ok) {
            alertKnownErrorOrSomethingWentWrong(response);
            onClose();
            return;
        }
        showSnackbar({ message: translate('Copied Restaurant Inventory') });
        onSuccess();
        onClose();
    };

    return (
        <Dialog title={translate('Copy Inventory from other Restaurant')} open={open} onClose={handleClose}>
            <Form onSubmit={onSubmit} form={form}>
                <Text>{translate('When copying the inventory, recipes, suppliers and supplies of the selected restaurant will be copied')}</Text>
                <Grid container spacing={3} style={{ margin: 10 }}>
                    {adminUser && (
                        <Grid item xs={11}>
                            <FormRestaurantAutocomplete name='fromRestaurantId' label={translate('Restaurant Id')} required disabled={loadingInventory} />
                        </Grid>
                    )}
                    {!adminUser && (
                        <Grid item xs={11}>
                            <FormBrandRestaurantSelect name='fromRestaurantId' label={translate('Restaurant Id')} required disabled={loadingInventory} />
                        </Grid>
                    )}
                </Grid>
                <FormFixErrorHint />
                {duplicateSupply && <Text error>{translate('There is inventory information to be overwritten, are you sure you want to copy this inventory?')}</Text>}
                <DialogActions>
                    <Button onClick={handleClose} disabled={isSubmitting} secondary>
                        {translate('Cancel')}
                    </Button>
                    <Button type={'submit'} disabled={Boolean(isSubmitting || loadingInventory || !fromRestaurantId)}>
                        {translate('Accept')}
                    </Button>
                    <UpdatingContentProgress bottom left loading={loadingInventory} />
                </DialogActions>
            </Form>
        </Dialog>
    );
}

type Props = {
    open: boolean;
    onSuccess: any;
    onClose: any;
    supplies?: Array<InventorySupplyVm>;
};
