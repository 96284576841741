import { DriverAvailabilities, DriverAvailability } from 'src/constants/DriverAvailability';

export function getDriverAvailability(driver: { mobileNumber?: string; banned?: boolean; removed?: boolean; verified?: boolean; signedIn: boolean; online: boolean }): DriverAvailability {
    if (!driver.mobileNumber) return DriverAvailabilities.NOT_REGISTERED;
    if (driver.banned) return DriverAvailabilities.BANNED;
    if (driver.removed) return DriverAvailabilities.REMOVED;
    if (!driver.verified) return DriverAvailabilities.NOT_VERIFIED;
    if (!driver.signedIn) return DriverAvailabilities.SIGNED_OUT;
    if (!driver.online) return DriverAvailabilities.OFFLINE;
    return DriverAvailabilities.ONLINE;
}
