import { makeStyles } from '@material-ui/core';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { findPromoCodesApi, PromoCodesVm } from 'src/api/letseatmanager/promoCode/findPromoCodesApi';
import { removePromoCodeApi } from 'src/api/letseatmanager/promoCode/removePromoCodeApi';
import { PromoCodeVm } from 'src/api/letseatmanager/types/PromoCodeVm';
import { ChangeToolbarButton } from 'src/components/mui-datatables/ChangeToolbarButton';
import { CreateToolbarButton } from 'src/components/mui-datatables/CreateToolbarButton';
import { RefreshToolbarButton } from 'src/components/mui-datatables/RefreshToolbarButton';
import { RemoveToolbarButton } from 'src/components/mui-datatables/RemoveToolbarButton';
import { SecuredAndSubscribedPage } from 'src/components/page/SecuredAndSubscribedPage';
import { Table } from 'src/components/Table';
import { RolePermissions } from 'src/constants/RolePermission';
import { translate } from 'src/i18n/translate';
import { ChangePromoCodeDialog } from 'src/scenes/letseatmanager/promoCode/ChangePromoCodeDialog';
import { CreatePromoCodeDialog } from 'src/scenes/letseatmanager/promoCode/CreatePromoCodeDialog';
import type { PromoCodeId } from 'src/types/Id';
import { alertKnownErrorOrSomethingWentWrong } from 'src/utils/alert/alertKnownErrorOrSomethingWentWrong';
import { formatDateTimeString } from 'src/utils/date/formatDateTimeString';
import { getSelectedRowsData } from 'src/utils/mui-datatables/getSelectedRowsData';
import { classNames } from 'src/utils/react/classNames';
import { useAskForPassword } from 'src/utils/react/useAskForPassword';
import { useLoadApi } from 'src/utils/react/useLoadApi';
import { useSelector } from 'src/utils/react/useSelector';

export function PromoCodesPage(): React.ReactElement {
    const classes = useStyles();
    const askForPassword = useAskForPassword();

    const [loading, setLoading] = useState(false);
    const [usedPromoCodes, setUsedPromoCodes] = useState({ promoCodes: [] } as PromoCodesVm);
    const [createPromoCodeDialogState, setCreatePromoCodeDialogState] = useState({ open: false });
    const [changePromoCodeDialogState, setChangePromoCodeDialogState] = useState({ open: false, promoCodeId: undefined });

    const restaurantIds = useSelector((state) => state.app.restaurantIds);
    const viewUser = useSelector((state) => state.authentication.viewUser);
    const restaurantUser = useSelector((state) => state.authentication.restaurantUser);

    const [loadingPromoCodes, responsePromoCodes, refreshPromoCodes] = useLoadApi(findPromoCodesApi, { restaurantIds }, { initialValue: { promoCodes: [] }, dependencies: [restaurantIds] });

    const filterUsedPromoCodes = (promoCodes: Array<PromoCodeVm>) => {
        const usedPromoCodes = promoCodes.filter((promoCode) => ((promoCode as any).customers && promoCode.usage && promoCode.usage !== 0) || !(promoCode as any).customers);

        setUsedPromoCodes({ promoCodes: usedPromoCodes });
        setLoading(false);
    };

    useEffect(() => {
        filterUsedPromoCodes(responsePromoCodes.promoCodes);
    }, [responsePromoCodes]);

    const removeMultiple = async (promoCodeIds: Array<PromoCodeId>) => {
        setLoading(true);
        for (const promoCodeId of promoCodeIds) {
            const response = await removePromoCodeApi({ promoCodeId });
            if (!response.ok) {
                setLoading(false);
                alertKnownErrorOrSomethingWentWrong(response);
                await refreshPromoCodes();
                return;
            }
        }
        await refreshPromoCodes();
    };

    const openCreatePromoCodeDialog = async () => {
        if (!restaurantUser) {
            const rightPassword = await askForPassword({ password: '9032' });
            if (!rightPassword) return;
        }
        setCreatePromoCodeDialogState({ open: true });
    };

    const openChangePromoCodeDialog = async (promoCodeId: any) => {
        if (!restaurantUser) {
            const rightPassword = await askForPassword({ password: '1738' });
            if (!rightPassword) return;
        }
        setChangePromoCodeDialogState({ open: true, promoCodeId });
    };

    return (
        <SecuredAndSubscribedPage title={translate('Promo Codes')} rolePermission={RolePermissions.PROMO_CODES_PAGE}>
            <CreatePromoCodeDialog open={createPromoCodeDialogState.open} onClose={() => setCreatePromoCodeDialogState({ open: false })} onPromoCodeCreated={refreshPromoCodes} />
            <ChangePromoCodeDialog
                open={changePromoCodeDialogState.open}
                promoCodeId={changePromoCodeDialogState.promoCodeId}
                onClose={() => setChangePromoCodeDialogState({ open: false, promoCodeId: undefined })}
                onPromoCodeChanged={refreshPromoCodes}
            />
            <Table
                loading={loading || loadingPromoCodes}
                className={classes.table}
                data={
                    responsePromoCodes.promoCodes &&
                    responsePromoCodes.promoCodes.map((promoCode) => ({
                        promoCodeId: promoCode.promoCodeId,
                        code: promoCode.code,
                        description: promoCode.description,
                        usage: promoCode.usage,
                        removed: promoCode.removed,
                        maxUsage: promoCode.maxUsage,
                        promoType: promoCode.promoType,
                        rewardType: promoCode.rewardType,
                        discount: promoCode.discount,
                        excludeDeliveryCost: promoCode.excludeDeliveryCost ? translate('Excluded') : translate('Not Excluded'),
                        freeDelivery: promoCode.freeDelivery ? translate('Free') : translate('Not Free'),
                        onlyFor: promoCode.appOnly || promoCode.webOnly ? (promoCode.appOnly ? 'APP' : 'WEB') : '',
                        discountType: promoCode.discountType,
                        minOrderAmount: promoCode.minOrderAmount,
                        maxDiscountAmount: promoCode.maxDiscountAmount,
                        restaurantPaidPercentage: `${promoCode.restaurantPaidPercentage ?? 0}%`,
                        startsAt: formatDateTimeString(promoCode.startsAt),
                        endsAt: formatDateTimeString(promoCode.endsAt),
                        createdAt: formatDateTimeString(promoCode.createdAt),
                    }))
                }
                columns={[
                    {
                        name: 'promoCodeId',
                        label: '',
                        options: {
                            display: 'excluded',
                            filter: false,
                        },
                    },
                    {
                        name: 'code',
                        label: translate('Code'),
                        options: {
                            filter: false,
                        },
                    },
                    {
                        name: 'description',
                        label: translate('Description'),
                        options: {
                            filter: false,
                        },
                    },
                    {
                        name: 'usage',
                        label: translate('Usage'),
                        options: {
                            filter: false,
                            searchable: false,
                        },
                    },
                    {
                        name: 'removed',
                        label: translate('Removed'),
                        options: {
                            filter: false,
                            searchable: false,
                            customBodyRender: (removed) => {
                                return (
                                    <div className={classNames(classes.removed, removed ? classes.promoCodeRemoved : classes.promoCodeNotRemoved)}>{removed ? translate('Yes') : translate('No')}</div>
                                );
                            },
                        },
                    },
                    {
                        name: 'maxUsage',
                        label: translate('Max Usage'),
                        options: {
                            filter: false,
                            searchable: false,
                        },
                    },
                    {
                        name: 'promoType',
                        label: translate('Promo Type'),
                        options: {
                            searchable: false,
                        },
                    },
                    {
                        name: 'rewardType',
                        label: translate('Reward Type'),
                        options: {
                            searchable: false,
                        },
                    },
                    {
                        name: 'discount',
                        label: translate('Discount'),
                        options: {
                            filter: false,
                            searchable: false,
                        },
                    },
                    {
                        name: 'excludeDeliveryCost',
                        label: translate('Exclude delivery cost'),
                        options: {
                            filter: false,
                        },
                    },
                    {
                        name: 'freeDelivery',
                        label: translate('Free Delivery'),
                        options: {
                            filter: false,
                        },
                    },
                    {
                        name: 'onlyFor',
                        label: translate('Only For'),
                        options: {
                            filter: false,
                        },
                    },
                    {
                        name: 'discountType',
                        label: translate('Discount Type'),
                        options: {
                            searchable: false,
                        },
                    },
                    {
                        name: 'restaurantPaidPercentage',
                        label: translate('Restaurant Paid Percentage'),
                        options: {
                            searchable: false,
                        },
                    },
                    {
                        name: 'minOrderAmount',
                        label: translate('Min Order Amount'),
                        options: {
                            filter: false,
                            searchable: true,
                        },
                    },
                    {
                        name: 'maxDiscountAmount',
                        label: translate('Max Discount Amount'),
                        options: {
                            filter: false,
                        },
                    },
                    {
                        name: 'startsAt',
                        label: translate('Starts'),
                        options: {
                            filter: false,
                        },
                    },
                    {
                        name: 'endsAt',
                        label: translate('Ends'),
                        options: {
                            filter: false,
                            searchable: false,
                        },
                    },
                    {
                        name: 'createdAt',
                        label: translate('Created'),
                        options: {
                            filter: false,
                            searchable: false,
                        },
                    },
                ]}
                options={{
                    responsive: 'standard',
                    tableBodyMaxHeight: '500px',
                    selectableRows: !viewUser ? 'multiple' : 'none',
                    filterType: 'checkbox',
                    rowsPerPage: 100,
                    customToolbar: () => (
                        <>
                            <RefreshToolbarButton onClick={refreshPromoCodes} />
                            <CreateToolbarButton onClick={openCreatePromoCodeDialog} />
                        </>
                    ),
                    customToolbarSelect: (selectedRows, displayData) => {
                        const selectedRowsData = getSelectedRowsData(selectedRows, displayData);
                        const selectedPromoCodeIds = selectedRowsData.map((d: Array<PromoCodeId>) => d[0]);

                        const handleClickRemove = async () => {
                            const remove = window.confirm(
                                selectedPromoCodeIds.length === 1
                                    ? 'Are you sure you want to remove the selected promo code'
                                    : `Are you sure you want to remove the selected ${selectedPromoCodeIds.length} promo codes`,
                            );
                            if (remove) {
                                await removeMultiple(selectedPromoCodeIds);
                            }
                        };

                        return (
                            <div className={classes.toolbar}>
                                {selectedPromoCodeIds.length === 1 && <ChangeToolbarButton onClick={() => openChangePromoCodeDialog(selectedPromoCodeIds[0])} />}
                                <RemoveToolbarButton onClick={handleClickRemove} />
                            </div>
                        );
                    },
                }}
            />
        </SecuredAndSubscribedPage>
    );
}

const useStyles = makeStyles((theme) => ({
    table: {
        whiteSpace: 'nowrap',
    },
    toolbar: {
        paddingRight: theme.spacing(3),
    },
    removed: {
        padding: '5px',
        borderRadius: '5px',
        textAlign: 'center',
    },
    promoCodeRemoved: {
        backgroundColor: '#5CDB95',
        color: '#FFFFFF',
        fontWeight: 'bold',
    },
    promoCodeNotRemoved: {
        backgroundColor: '#E85757',
        color: '#FFFFFF',
        fontWeight: 'bold',
    },
}));
