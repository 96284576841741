import { letseatmanagerApiMethod } from 'src/api/letseatmanagerApi';
import type { ApiSauceResponse } from 'src/api/types/ApiSauceResponse';
import type { CashRegisterVm } from 'src/types/CashRegisterVm';
import type { CashRegisterPosBusinessDayId } from 'src/types/Id';

export async function findOpenedCashRegisterInCashRegisterPosBusinessDayApi(request: FindOpenedCashRegisterInCashRegisterPosBusinessDayApiRequest): ApiSauceResponse<CashRegisterVm> {
    return letseatmanagerApiMethod('pos/findOpenedCashRegisterInCashRegisterPosBusinessDayApi', request);
}

export type FindOpenedCashRegisterInCashRegisterPosBusinessDayApiRequest = {
    cashRegisterPosBusinessDayId: CashRegisterPosBusinessDayId;
};
