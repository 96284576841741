import { Grid, IconButton } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';
import * as React from 'react';
import { transformImageUrl } from 'src/utils/image/transformImageUrl';
import { classNames } from 'src/utils/react/classNames';

export function ImageContainer({ image, onRemoveImage, classes: classesProp }: Props): React.ReactElement {
    const classes = useStyles();

    return (
        <Grid className={classes.imageContainer}>
            <img className={classNames(classes.image, classesProp?.imagePreview)} src={transformImageUrl(image, 320, 320)} alt='product or category img' />
            <div className={classes.rightContainer}>
                <p className={classes.url}>{image}</p>
                <IconButton onClick={() => onRemoveImage(null)} title='Remove image'>
                    <CloseIcon />
                </IconButton>
            </div>
        </Grid>
    );
}

const useStyles = makeStyles((theme) => ({
    imageContainer: {
        width: '100%',
        height: '100%',
        display: 'flex',
        marginTop: 8,
        gap: 12,
    },
    image: {
        height: '100%',
        aspectRatio: 1,
        maxWidth: 70,
        maxHeight: 70,
        borderRadius: 4,
    },
    rightContainer: {
        width: '100%',
        color: theme.palette.surface.primary,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        padding: 12,
        borderRadius: 8,
    },
    url: {
        fontFamily: theme.typography.regular,
        margin: 0,
        padding: 0,
        fontSize: 12,
        color: '#2E3748',
        wordBreak: 'break-all',
    },
}));

type Props = {
    image: string;
    onRemoveImage: any;
    classes?: {
        imagePreview?: string;
    };
};
