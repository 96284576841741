import DialogActions from '@material-ui/core/DialogActions';
import { Button, Dialog } from '@pidedirecto/ui';
import { useState } from 'react';
import * as React from 'react';
import { useForm } from 'react-hook-form';
import { changeOrderTypeApi } from 'src/api/letseatmanager/order/changeOrderTypeApi';
import { Form } from 'src/components/form/Form';
import { FormAvailableTablesSelect } from 'src/components/form/FormAvailableTablesSelect';
import { FormNumberField } from 'src/components/form/FormNumberField';
import { OrderTypes } from 'src/constants/OrderType';
import { translate } from 'src/i18n/translate';
import type { OrderId } from 'src/types/Id';
import { alertKnownErrorOrSomethingWentWrong } from 'src/utils/alert/alertKnownErrorOrSomethingWentWrong';
import { useSelector } from 'src/utils/react/useSelector';

export function SelectTableOfOrderDialog({ open, onClose, orderId, onSuccess }: Props): React.ReactElement {
    const [loading, setLoading] = useState(false);

    const restaurantId = useSelector((state) => state.app.restaurantId);
    const posCustomersRequiredEnabled = useSelector((state) => state.pos.context?.posCustomersRequiredEnabled);
    const posRestaurantZonesEnabled = useSelector((state) => state.pos.context?.posRestaurantZonesEnabled);

    const form = useForm();

    const handleSubmit = async (form: any) => {
        if (!orderId) return;

        setLoading(true);
        const response = await changeOrderTypeApi({
            orderId,
            restaurantId,
            orderType: OrderTypes.TABLE_ORDER,
            restaurantZoneId: form.restaurantZoneId,
            table: form.table,
            numberOfCustomers: form.numberOfCustomers,
        });
        setLoading(false);

        if (!response.ok) {
            return alertKnownErrorOrSomethingWentWrong(response);
        }

        onClose();
        onSuccess(response.data);
    };

    return (
        <Dialog open={open} onClose={onClose} title={translate('Change order to table')}>
            <Form onSubmit={handleSubmit} form={form}>
                {posRestaurantZonesEnabled && <FormAvailableTablesSelect />}
                <FormNumberField name='numberOfCustomers' label={translate('Number of Customers')} required={posCustomersRequiredEnabled} />
                <DialogActions>
                    <Button onClick={onClose} disabled={loading} variant={'secondary'}>
                        {translate('Cancel')}
                    </Button>
                    <Button type={'submit'} disabled={loading}>
                        {translate('Accept')}
                    </Button>
                </DialogActions>
            </Form>
        </Dialog>
    );
}

type Props = {
    open: boolean;
    orderId: OrderId;
    onSuccess: any;
    onClose: any;
};
