import { useLoadMenus } from 'src/services/menu/useLoadMenus';
import { useLoadMenuCategories } from 'src/services/menuCategory/useLoadMenuCategories';
import { useLoadMenuItems } from 'src/services/menuItem/useLoadMenuItems';
import { useLoadModifierGroups } from 'src/services/modifierGroup/useLoadModifierGroups';

export function useLoadRestaurantMenu(): any {
    const loadMenus = useLoadMenus();
    const loadMenuItems = useLoadMenuItems();
    const loadMenuCategories = useLoadMenuCategories();
    const loadModifierGroups = useLoadModifierGroups();

    const loadRestaurantMenu = async () => {
        loadMenus();
        loadMenuItems();
        loadMenuCategories();
        loadModifierGroups();
    };

    return loadRestaurantMenu;
}
