import { makeStyles } from '@material-ui/core';
import * as React from 'react';
import { Button } from 'src/components/Button';
import { translate } from 'src/i18n/translate';
import { PrinterOutlineIcon } from 'src/icons/PrinterOutlineIcon';
import { printerInfoDemo } from 'src/scenes/letseatmanager/customizePrinters/printerInfoPrint/data/printerInfoDemo';
import { usePrintPrinterInfo } from 'src/services/printer/usePrintPrinterInfo';

export function PrintPrinterInfoTestButton({ disabled }: Props): React.ReactElement {
    const classes = useStyles();

    const [printPrinterInfo] = usePrintPrinterInfo();

    const handlePrintPrinterInfo = async () => {
        await printPrinterInfo(printerInfoDemo);
    };

    return (
        <Button onClick={handlePrintPrinterInfo} disabled={disabled} classes={{ button: classes.printButton }}>
            <PrinterOutlineIcon color={'#FFF'} title={'print-pos-business-day-report-test'} />
            {translate('Print test')}
        </Button>
    );
}

const useStyles = makeStyles((theme) => ({
    printButton: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        height: 50,
        width: 160,
        [theme.breakpoints.up('md')]: {
            alignSelf: 'center',
        },
        marginTop: 10,
    },
}));

type Props = {
    disabled: boolean;
};
