import { InputLabel, makeStyles } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import CheckIcon from '@material-ui/icons/Check';
import { useContext } from 'react';
import * as React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { FormContext } from 'src/components/form/Form';
import { translate } from 'src/i18n/translate';
import { classNames } from 'src/utils/react/classNames';

const defaultColors: Array<string> = ['#1a9aa0', '#f2a900', '#f25c00', '#f20000', '#f200f2', '#a900f2', '#0000f2', '#00a9f2', '#00f2f2'];

export function FormColorButtons({ name, label, disabled, colors, defaultValue, required }: Props): React.ReactElement {
    const classes = useStyles();

    const {
        control,
        formState: { isSubmitting },
    } = useFormContext();
    const formContext = useContext(FormContext);

    return (
        <FormControlLabel
            label={undefined}
            style={{ marginLeft: 0 }}
            control={
                <Controller
                    control={control}
                    name={name}
                    defaultValue={defaultValue ?? null}
                    render={({ onChange, value, name }) => (
                        <div>
                            <InputLabel htmlFor={`${name}-input`} className={classNames(classes.label)}>
                                {label}
                            </InputLabel>
                            <input type='text' id={`${name}-input`} name={name} value={value} hidden />
                            {(colors || defaultColors).map((color) => {
                                const isColorSelected = color === value;
                                return (
                                    <Button
                                        key={color}
                                        className={classNames(classes.button, isColorSelected ? classes.selected : '')}
                                        style={{ backgroundColor: color }}
                                        onClick={() => {
                                            onChange(color);
                                        }}
                                        title={color}
                                        type='button'
                                    >
                                        {isColorSelected && <CheckIcon className={classes.icon} color='primary' />}
                                    </Button>
                                );
                            })}
                        </div>
                    )}
                    rules={{
                        required: {
                            value: (required as any)?.value ?? required,
                            message: (required as any)?.message ?? translate('This field is required'),
                        },
                    }}
                />
            }
            disabled={isSubmitting || disabled || formContext.disabled}
        />
    );
}

const useStyles = makeStyles((theme) => ({
    container: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        width: 'fit-content',
        marginBottom: 20,
        padding: '0 12px',
        [theme.breakpoints.down('sm')]: {
            flexDirection: 'column',
            width: '100%',
            alignItems: 'center',
        },
    },
    button: {
        margin: theme.spacing(1),
        width: 20,
        height: 20,
        padding: 0,
        minWidth: 0,
        borderRadius: '50%',
        boxSizing: 'border-box',
        '&:active': {
            backgroundColor: 'rgba(0, 0, 0, 0.12)',
        },
    },
    selected: {
        backgroundColor: 'rgba(0, 0, 0, 0.12)',
        boxShadow: 'inset 0 0 0 1px grey',
    },
    icon: {
        color: 'white',
        fontSize: 12,
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
    },
    label: {
        fontFamily: theme.typography.medium,
        color: theme.palette.secondary.contrastText,
        fontSize: 15,
        marginBottom: 5,
        transform: 'scale(1)',
    },
    labelError: {
        color: 'red',
    },
}));

export type Props = {
    name: string;
    label?: string;
    defaultValue?: string;
    disabled?: boolean;
    required?: boolean;
    colors?: Array<string>;
};
