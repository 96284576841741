import { makeStyles } from '@material-ui/core/styles';
import * as React from 'react';
import { createContext, useEffect, useState } from 'react';
import { Button } from 'src/components/Button';
import { translate } from 'src/i18n/translate';
import { AddIcon } from 'src/icons/AddIcon';
import { classNames } from 'src/utils/react/classNames';

export function SectionList({ title, disabled, children, onItemClick, onCreate, defaultListItemSelected, classes: classesProp }: Props): React.ReactElement {
    const classes = useStyles();

    const [selectedItem, setSelectedItem] = useState(defaultListItemSelected);

    useEffect(() => {
        if (!selectedItem && defaultListItemSelected) setSelectedItem(defaultListItemSelected);
    }, [defaultListItemSelected, selectedItem]);

    const handleClickItem = (itemValue: any) => {
        setSelectedItem(itemValue);
        onItemClick?.(itemValue);
    };

    return (
        <div className={classNames(classes.container, classesProp?.container)}>
            <div className={classNames(classes.titleContainer, classesProp?.titleContainer)}>
                <h1 className={classNames(classes.title, classesProp?.title)}>{title}</h1>
                {onCreate && (
                    <Button primary classes={classes.button as any} onClick={onCreate} disabled={disabled}>
                        {translate('Create')}
                        <AddIcon color='white' />
                    </Button>
                )}
            </div>
            <ul className={classNames(classes.list, classesProp?.list)}>
                <SectionListContext.Provider value={{ onItemClick: handleClickItem, selectedItem }}>{children}</SectionListContext.Provider>
            </ul>
        </div>
    );
}

export const SectionListContext = createContext<Context>({ selectedItem: undefined, onItemClick: () => {} });

const useStyles = makeStyles((theme) => ({
    container: {
        width: '100%',
        overflowY: 'scroll',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        padding: '16px 8px',
        cursor: 'pointer',
        [theme.breakpoints.down('sm')]: {
            height: 'auto',
        },
    },
    titleContainer: {
        width: '100%',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        color: theme.palette.text.primary,
    },
    title: {
        fontFamily: theme.typography.medium,
        color: theme.palette.text.primary,
        fontSize: 20,
        margin: 0,
    },
    list: {
        listStyle: 'none',
        width: '100%',
        padding: 0,
    },
    listItem: {
        borderBottom: `2px solid ${theme.palette.border.primary}`,
        transition: 'all 0.3s ease',
        padding: '16px 8px',
        '&:hover': {
            backgroundColor: theme.palette.surface.brand,
            boxShadow: '0px 0px',
            cursor: 'pointer',
            borderBottom: `2px solid ${theme.palette.border.brandContrast}`,
        },
    },
    listItemSelected: {
        backgroundColor: theme.palette.surface.brand,
        boxShadow: '0px 0px',
        cursor: 'pointer',
        borderBottom: `2px solid ${theme.palette.border.brandContrast}`,
        transition: 'all 0.3s ease',
    },
    button: {
        display: 'flex',
        flexDirection: 'row',
        fontFamily: theme.typography.bold,
    },
}));

type Props = {
    children: React.ReactNode;
    title: string;
    disabled?: boolean;
    defaultListItemSelected?: any;
    onItemClick?: any;
    onCreate?: any;
    classes?: {
        container?: string;
        titleContainer?: string;
        title?: string;
        list?: string;
    };
};

type Context = {
    onItemClick: any;
    selectedItem: any;
};
