import { letseatmanagerApiMethod } from 'src/api/letseatmanagerApi';
import type { ApiSauceResponse } from 'src/api/types/ApiSauceResponse';
import type { AwsCognitoUsername, RestaurantId } from 'src/types/Id';

export async function changeManagerUserPasswordApi(request: ChangeManagerUserPasswordApiRequest): ApiSauceResponse<void> {
    return letseatmanagerApiMethod('managerUser/changeManagerUserPasswordApi', request);
}

export type ChangeManagerUserPasswordApiRequest = {
    restaurantId: RestaurantId;
    username: AwsCognitoUsername;
    password: string;
    signOutFromDevices: boolean;
};
