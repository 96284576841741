import { FormSelect } from '@pidedirecto/ui/form';
import * as React from 'react';
import { RestaurantPsps, type RestaurantPsp } from 'src/constants/RestaurantPsp';
import { translate } from 'src/i18n/translate';

export function FormPspSelect({ name, label, required, helperText, filter, disabled }: Props): React.ReactElement {
    const restaurantPsps: Array<RestaurantPsp> = Object.values(RestaurantPsps);
    const filteredRestaurantPsps = restaurantPsps.filter((restaurantPsp) => !filter?.includes(restaurantPsp));

    const options = required
        ? filteredRestaurantPsps.map((restaurantPsp: RestaurantPsp) => ({ label: translate(`RestaurantPsps.${restaurantPsp}`), value: restaurantPsp }))
        : [{ label: '', value: null }, ...filteredRestaurantPsps.map((restaurantPsp: RestaurantPsp) => ({ label: translate(`RestaurantPsps.${restaurantPsp}`), value: restaurantPsp }))];

    return <FormSelect name={name} label={label} helperText={helperText} options={options} disabled={disabled} required={required} />;
}

type Props = {
    name: string;
    label?: string;
    helperText?: string;
    filter?: Array<RestaurantPsp>;
    required?: boolean;
    disabled?: boolean;
};
