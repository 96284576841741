import { letseatmanagerApiMethod } from 'src/api/letseatmanagerApi';
import type { ApiSauceResponse } from 'src/api/types/ApiSauceResponse';
import type { BuyOrderId, ManagerUserId } from 'src/types/Id';

export async function completeBuyOrderApi(request: CompleteBuyOrderApiRequest): ApiSauceResponse<void> {
    return letseatmanagerApiMethod('buyOrder/completeBuyOrderApi', request);
}

export type CompleteBuyOrderApiRequest = {
    buyOrderId: BuyOrderId;
    managerUserId: ManagerUserId;
    updateSupplyPrices?: boolean;
};
