import LinearProgress from '@material-ui/core/LinearProgress';
import * as React from 'react';
import { useCallback, useState } from 'react';
import type { ImageType } from 'src/constants/ImageType';
import { translate } from 'src/i18n/translate';
import { uploadImage } from 'src/services/image/uploadImage';
import { alertKnownErrorOrSomethingWentWrong } from 'src/utils/alert/alertKnownErrorOrSomethingWentWrong';
import { hashMd5FromArrayBuffer } from 'src/utils/hashMd5FromArrayBuffer';
import { imageHasTheRightDimensions } from 'src/utils/image/imageHasTheRightDimensions';
import { useEventListener } from 'src/utils/react/useEventListener';
import { requireValue } from 'src/utils/require/requireValue';

export default function ImageUploader({ imageType, targetName, onUploadingChanged, onUploadSuccess, dimensions }: Props): React.ReactElement | null {
    const [uploading, setUploading] = useState(false);

    const dragHandler = useCallback((e: DragEvent) => {
        e.preventDefault();
        return false;
    }, []);
    const dropHandler = useCallback(
        (e: DragEvent) => {
            e.preventDefault();
            if (targetName && (e.target as any)?.name !== targetName) {
                return;
            }
            if (!e.dataTransfer || e.dataTransfer.files.length !== 1) {
                alert('Cannot upload multiple images. Please try again with a single file.');
                return;
            }
            const file = e.dataTransfer.files[0];
            const reader = new FileReader();

            reader.addEventListener('loadend', async (e: ProgressEvent) => {
                setUploading(true);
                onUploadingChanged?.(true);
                const fileName = `${hashMd5FromArrayBuffer(reader.result)}-${file.name}`;
                try {
                    const _imageHasTheRightDimensions = await imageHasTheRightDimensions({
                        buffer: reader.result,
                        imageType,
                        dimensions,
                    });

                    if (!_imageHasTheRightDimensions) {
                        setUploading(false);
                        onUploadingChanged?.(false);
                        alert(translate('The image is not valid, please select an image with the right dimensions.'));
                        return;
                    }

                    const uploadResponse = await uploadImage({
                        fileName,
                        imageBlob: new Blob([reader.result as any], { type: file.type }),
                        type: file.type,
                        imageType,
                    });

                    if (uploadResponse.error) {
                        setUploading(false);
                        onUploadingChanged?.(false);
                        console.log(e);
                        alertKnownErrorOrSomethingWentWrong();
                        return;
                    }

                    setUploading(false);
                    onUploadingChanged?.(false);
                    onUploadSuccess(requireValue(uploadResponse.imageUrl));
                } catch (e: any) {
                    setUploading(false);
                    onUploadingChanged?.(false);
                    console.log(e);
                    alertKnownErrorOrSomethingWentWrong();
                }
            });
            reader.readAsArrayBuffer(file);
            return false;
        },
        [setUploading, imageType, onUploadingChanged, onUploadSuccess, dimensions],
    );

    // Add event listener using our hook
    useEventListener('dragenter', dragHandler);
    useEventListener('dragover', dragHandler);
    useEventListener('drop', dropHandler);

    if (!uploading) {
        return null;
    }

    return <LinearProgress style={{ position: 'relative', width: '100%' }} />;
}

type Props = {
    imageType: ImageType;
    targetName?: string;
    dimensions?: {
        minWidth: number;
        maxWidth: number;
        minHeight: number;
        maxHeight: number;
    };
    onUploadingChanged?: (arg1: boolean) => any;
    onUploadSuccess: (arg1: string) => any;
};
