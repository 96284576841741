import { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import type { RolePermission } from 'src/constants/RolePermission';
import { RoutePaths } from 'src/constants/RoutePath';
import { useSelector } from 'src/utils/react/useSelector';
import { useUserHasRolePermission } from 'src/utils/react/useUserHasRolePermissions';

export function useValidatePageAccess(rolePermission: RolePermission): [boolean] {
    const history = useHistory();
    const location = useLocation();
    const [userHasRolePermission] = useUserHasRolePermission();

    const [validatingPageAccess, setValidatingPageAccess] = useState(true);

    const initialPage = useSelector((state) => state.authentication.initialPage);
    const pinCodeUserSignedIn = useSelector((state) => state.authentication.pinCodeUserSignedIn);
    const pinCodeUserInitialPage = useSelector((state) => state.authentication.pinCodeUserSignedIn?.initialPage);
    const userManagementEnabled = useSelector((state) => state.app.restaurant?.userManagementEnabled);
    const brandOpened = useSelector((state) => state.app.brandOpened);
    const posEnabled = useSelector((state) => state.app.restaurant?.posEnabled);
    const internalUser = useSelector((state) => state.authentication.internalUser);

    const userInitialPage = pinCodeUserInitialPage ?? initialPage;
    const pinCodeSecuredRoutes = [RoutePaths.ONGOING_ORDERS, RoutePaths.ORDERS, RoutePaths.POS, RoutePaths.POS_CASH_REGISTERS, RoutePaths.POS_TABLES];
    const pinCodeSecuredInternalRoutes = [RoutePaths.POS, RoutePaths.POS_CASH_REGISTERS, RoutePaths.POS_TABLES];
    const isPinCodeSecuredRoute = internalUser ? pinCodeSecuredInternalRoutes.includes(location.pathname) : pinCodeSecuredRoutes.includes(location.pathname);

    useEffect(() => {
        if (posEnabled && userManagementEnabled && !brandOpened && !pinCodeUserSignedIn && isPinCodeSecuredRoute) {
            history.replace({
                pathname: RoutePaths.PIN_CODE_USER_LOGIN,
                search: history.location.search,
            });
            return;
        }
        if (!userHasRolePermission(rolePermission)) redirectToInitialPage();
        if (userHasRolePermission(rolePermission)) setValidatingPageAccess(false);
    }, [rolePermission, userManagementEnabled, brandOpened, pinCodeUserSignedIn, isPinCodeSecuredRoute, posEnabled]);

    const redirectToInitialPage = () => {
        history.replace({
            pathname: userInitialPage ? RoutePaths[userInitialPage] : RoutePaths.ONGOING_ORDERS,
            search: history.location.search,
        });
    };

    return [validatingPageAccess];
}
