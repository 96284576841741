import { letseatadminApiMethod } from 'src/api/letseatadminApi';
import type { ApiSauceResponse } from 'src/api/types/ApiSauceResponse';
import type { Bank } from 'src/constants/Bank';
import type { City } from 'src/constants/City';
import type { PalencaApp } from 'src/constants/PalencaApp';
import type { Platform } from 'src/constants/Platform';
import type { VehicleType } from 'src/constants/VehicleTypes';
import type { GeoJsonPoint } from 'src/types/GeoJsonPoint';
import type { AwsCognitoUsername, CountryCode, DriverId, LanguageTag, PalencaDriverId, TimeZoneName } from 'src/types/Id';

export async function getDriverApi(request: GetDriverApiRequest): ApiSauceResponse<DriverVm> {
    return letseatadminApiMethod('driver/getDriverApi', request);
}

export type GetDriverApiRequest = {
    driverId: DriverId;
};

export type DriverVm = {
    driverId: DriverId;
    palencaDriverId?: PalencaDriverId;
    signedUpWith?: PalencaApp;
    signedIn: boolean;
    online: boolean;
    mobileNumber?: string;
    email?: string;
    firstName?: string;
    lastName?: string;
    businessName?: string;
    claveId?: string;
    curp?: string;
    city?: City;
    bank?: Bank;
    clabe?: string;
    rfc?: string;
    position?: GeoJsonPoint;
    positionedAt?: Date;
    country?: CountryCode;
    timeZone?: TimeZoneName;
    language?: LanguageTag;
    fcmPermission?: boolean;
    locationPermission?: string;
    comments?: string;
    verified?: boolean;
    appPaymentsEnabled?: boolean;
    cashPaymentsEnabled?: boolean;
    prio: number;
    removed?: boolean;
    banned?: boolean;
    bannedUntil?: Date;
    bannedByUsername?: AwsCognitoUsername;
    bannedAt?: Date;
    appVersion?: string;
    platform?: Platform;
    platformVersion?: string;
    exportError?: string;
    downloadedAppAt: Date;
    signedUpAt?: Date;
    firstDeliveryAt?: Date;
    lastDeliveryAt?: Date;
    exportedAt?: Date;
    vehicleType?: VehicleType;
};
