import { InfoWindow, Marker } from '@react-google-maps/api';
import { useState } from 'react';
import * as React from 'react';
import { SimplifiedOrderCard } from 'src/components/orderCard/SimplifiedOrderCard';
import CustomerMarker from 'src/images/CustomerMarker.svg';
import type { OrderVm } from 'src/types/OrderVm';
import { toLatLng } from 'src/utils/googlemaps/toLatLng';

export function DeliveryOrdersCustomerMarker({ order }: Props): React.ReactElement | null {
    const [isOpenInfoWindow, setIsOpenInfoWindow] = useState(false);

    const handleClickMarker = () => {
        setIsOpenInfoWindow(true);
    };

    if (!order.address?.location) return null;

    return (
        <>
            {isOpenInfoWindow && (
                <InfoWindow position={toLatLng(order.address?.location)} onCloseClick={() => setIsOpenInfoWindow(false)}>
                    <SimplifiedOrderCard order={order} />
                </InfoWindow>
            )}
            <Marker icon={CustomerMarker} position={toLatLng(order.address?.location)} onClick={handleClickMarker} />
        </>
    );
}

type Props = {
    order: OrderVm;
};
