import { makeStyles } from '@material-ui/core';
import { EditIcon, PlusIcon, TrashCanIcon } from '@pidedirecto/ui/icons';
import * as React from 'react';
import { useContext } from 'react';
import { ContextualMenu, ContextualMenuContext } from 'src/components/ContextualMenu';
import { ContextualMenuItem } from 'src/components/ContextualMenuItem';
import { translate } from 'src/i18n/translate';
import { CloseIcon } from 'src/icons/CloseIcon';
import { FlappyDiskIcon } from 'src/icons/FlappyDiskIcon';

export function ManageMenu({ onClickCreateTable, onClickCreate, onClickSaveChanges, onClickChangeZone, onClickCancel, onClickRemoveZone }: Props): React.ReactElement {
    const classes = useStyles();
    const { closeContextMenu } = useContext(ContextualMenuContext);

    return (
        <ContextualMenu>
            <ContextualMenuItem
                className={classes.item}
                onClick={() => {
                    onClickCreate?.();
                    closeContextMenu();
                }}
            >
                <PlusIcon />
                <span>{translate('Create Restaurant Zone')}</span>
            </ContextualMenuItem>
            <ContextualMenuItem
                className={classes.item}
                onClick={() => {
                    onClickCreateTable?.();
                    closeContextMenu();
                }}
            >
                <PlusIcon />
                <span>{translate('Create Table')}</span>
            </ContextualMenuItem>
            <ContextualMenuItem
                className={classes.item}
                onClick={() => {
                    onClickChangeZone?.();
                    closeContextMenu();
                }}
            >
                <EditIcon size={14} />
                <span>{translate('Change Zone')}</span>
            </ContextualMenuItem>
            <ContextualMenuItem
                className={classes.item}
                onClick={() => {
                    onClickRemoveZone?.();
                    closeContextMenu();
                }}
            >
                <TrashCanIcon height={'14'} width={'14'} />
                <span>{translate('Remove Zone')}</span>
            </ContextualMenuItem>
            <ContextualMenuItem
                className={classes.item}
                onClick={() => {
                    onClickSaveChanges?.();
                    closeContextMenu();
                }}
            >
                <FlappyDiskIcon width={'12'} height={'12'} />
                <span>{translate('Save Changes')}</span>
            </ContextualMenuItem>
            <ContextualMenuItem
                className={classes.item}
                onClick={() => {
                    onClickCancel?.();
                    closeContextMenu();
                }}
            >
                <CloseIcon size={10} />
                <span>{translate('Cancel')}</span>
            </ContextualMenuItem>
        </ContextualMenu>
    );
}
const useStyles = makeStyles((theme) => ({
    item: {
        '&:hover': {
            backgroundColor: theme.palette.surface.brand,
        },
    },
}));

type Props = {
    onClickCreate?: any;
    onClickCreateTable?: any;
    onClickSaveChanges?: any;
    onClickChangeZone?: any;
    onClickCancel?: any;
    onClickRemoveZone?: any;
};
