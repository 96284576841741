import { useState } from 'react';
import * as React from 'react';
import { Button } from 'src/components/Button';
import { ChangeRejectOrderReasonDialog } from 'src/components/dialog/order/ChangeRejectOrderReasonDialog';
import { Text } from 'src/components/Text';
import { translate } from 'src/i18n/translate';
import type { OrderVm } from 'src/types/OrderVm';

export function OrderCardChangeCancelReasonButton({ order, disabled }: Props): React.ReactElement {
    const [open, setOpen] = useState(false);

    const handleClickCancelOrRejectAcceptedOrder = () => {
        const password = prompt(translate('Password'));
        const isCorrectPassword = password === '1212';

        if (!isCorrectPassword) return alert(translate('Wrong Password'));

        setOpen(true);
    };

    const onCloseDialog = () => {
        setOpen(false);
    };

    return (
        <div>
            <ChangeRejectOrderReasonDialog order={order} open={open} onCloseDialog={onCloseDialog} />
            <div style={{ width: '100%', display: 'flex', alignItems: 'center' }}>
                <Button secondary disabled={disabled} onClick={handleClickCancelOrRejectAcceptedOrder}>
                    <Text error>{translate('Change reason for cancellation')}</Text>
                </Button>
            </div>
        </div>
    );
}

type Props = {
    order: OrderVm;
    disabled: boolean;
};
