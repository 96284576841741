import { makeStyles } from '@material-ui/core';
import { useEffect, useState } from 'react';
import * as React from 'react';
import type { NotCompleteOrdersByDay } from 'src/api/letseatmanager/restaurantDashboard/getIssueRestaurantStatisticsApi';
import { BarChartStackedStyleCanvas } from 'src/components/charts/BarChartStackedStyleCanvas';
import { OrderStatuses } from 'src/constants/OrderStatus';
import { translate } from 'src/i18n/translate';
import { ReportSection } from 'src/scenes/letseatmanager/restaurantDashboard/ReportSection';

export function IssueReasonsByDayGraph({ issueReasonsByDayData, loading }: Props): React.ReactElement {
    const classes = useStyles();

    const [issuesByDayData, setIssuesByDayData] = useState<Array<IssuesStatusStructureType>>([]);

    useEffect(() => {
        handleIssueReasonsByDayData(issueReasonsByDayData);
    }, [issueReasonsByDayData]);

    const handleIssueReasonsByDayData = (issueReasonsByDayData: Array<NotCompleteOrdersByDay>) => {
        if (issueReasonsByDayData.length < 0) {
            setIssuesByDayData([]);
            return;
        }

        const structureOfIssueByDay: Array<IssuesStatusStructureType> = [];

        issueReasonsByDayData.forEach((orderIssue) => {
            const statusHasIssue = structureOfIssueByDay?.find((issueItem: IssuesStatusStructureType) => orderIssue.createdAt === issueItem.menuId);
            if (statusHasIssue) {
                if (orderIssue.orderStatus == OrderStatuses.CANCELLED) statusHasIssue.cancelled = statusHasIssue.cancelled + orderIssue.orderVolume;
                if (orderIssue.orderStatus == OrderStatuses.REJECTED) statusHasIssue.rejected = statusHasIssue.rejected + orderIssue.orderVolume;
            }
            structureOfIssueByDay.push({
                menuId: orderIssue.createdAt,
                cancelled: orderIssue.orderStatus === OrderStatuses.CANCELLED ? orderIssue.orderVolume : 0,
                rejected: orderIssue.orderStatus === OrderStatuses.REJECTED ? orderIssue.orderVolume : 0,
            });
        });
        setIssuesByDayData(structureOfIssueByDay);
    };

    return (
        <ReportSection title={translate('Reason of issues by Day')} loading={loading}>
            <div className={classes.container}>
                <div className={classes.graphContainer}>
                    <BarChartStackedStyleCanvas
                        data={issuesByDayData}
                        keys={['rejected', 'cancelled']}
                        indexBy={'menuId'}
                        labelY={translate('Amount')}
                        classes={{ container: classes.containerBarchart }}
                        tooltip={(tooltipData: { id: string; indexValue: string }) => (
                            <div className={classes.tooltip}>
                                <span className={classes.tooltipText}>{translate('Status: @status', { status: translate(tooltipData.id.toUpperCase()) })} </span>
                                <span className={classes.tooltipText}>{translate('Date: @date', { date: tooltipData.indexValue })} </span>
                            </div>
                        )}
                    />
                </div>
            </div>
        </ReportSection>
    );
}

const useStyles = makeStyles((theme) => ({
    container: {
        width: '100%',
    },
    graphContainer: {
        width: '100%',
        height: 350,
        flexShrink: 0,
    },
    tooltip: {
        color: `#0D3037`,
        padding: 5,
        textTransform: 'capitalize',
        borderRadius: '10px',
        backgroundColor: 'whitesmoke',
    },
    tooltipText: {
        textTransform: 'uppercase',
        display: 'flex',
        flexDirection: 'column',
    },
    sectionHeadKpiMobile: {
        display: 'flex',
        width: '100%',
        justifyContent: 'center',
        flexWrap: 'wrap',
        [theme.breakpoints.up('md')]: {
            display: 'none',
        },
    },
    splitContainer: {
        display: 'flex',
        alignItems: 'center',
        width: '100%',
        [theme.breakpoints.between('xs', 'sm')]: {
            flexDirection: 'column',
        },
    },
    sectionHeadKpi: {
        display: 'flex',
        flexDirection: 'column',

        [theme.breakpoints.down('sm')]: {
            display: 'none',
        },
    },
    containerBarchart: {
        width: '100%',
        height: '100%',
    },
}));

type Props = {
    loading: boolean;
    issueReasonsByDayData: Array<NotCompleteOrdersByDay>;
};

type IssuesStatusStructureType = {
    menuId: string;
    cancelled: number;
    rejected: number;
};
