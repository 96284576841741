import * as React from 'react';

export function WarningAlertIcon({ width, height, title }: Props): React.ReactElement {
    return (
        <svg width={width ?? '91'} height={height ?? '90'} viewBox='0 0 91 90' fill='none' xmlns='http://www.w3.org/2000/svg'>
            {!!title && <title>{title}</title>}
            <circle cx='45.5' cy='45' r='45' fill='#FFEFDC' />
            <path
                fillRule='evenodd'
                clipRule='evenodd'
                d='M38.477 24.7642C41.3759 19.7453 48.6245 19.7453 51.5209 24.7642L69.9812 56.755C72.8776 61.7739 69.2533 68.0476 63.458 68.0476H26.5399C20.7445 68.0476 17.1228 61.7739 20.0192 56.755L38.4745 24.7642H38.477ZM45.0002 37.9314C45.4994 37.9314 45.9782 38.1297 46.3313 38.4827C46.6843 38.8356 46.8826 39.3144 46.8826 39.8135V49.2241C46.8826 49.7232 46.6843 50.2019 46.3313 50.5549C45.9782 50.9079 45.4994 51.1062 45.0002 51.1062C44.5009 51.1062 44.0221 50.9079 43.6691 50.5549C43.3161 50.2019 43.1178 49.7232 43.1178 49.2241V39.8135C43.1178 39.3144 43.3161 38.8356 43.6691 38.4827C44.0221 38.1297 44.5009 37.9314 45.0002 37.9314ZM45.0002 58.6346C45.4994 58.6346 45.9782 58.4363 46.3313 58.0833C46.6843 57.7304 46.8826 57.2516 46.8826 56.7525C46.8826 56.2533 46.6843 55.7746 46.3313 55.4216C45.9782 55.0687 45.4994 54.8704 45.0002 54.8704C44.5009 54.8704 44.0221 55.0687 43.6691 55.4216C43.3161 55.7746 43.1178 56.2533 43.1178 56.7525C43.1178 57.2516 43.3161 57.7304 43.6691 58.0833C44.0221 58.4363 44.5009 58.6346 45.0002 58.6346Z'
                fill='#FC9B41'
            />
        </svg>
    );
}

type Props = {
    title?: string;
    width?: string;
    height?: string;
};
