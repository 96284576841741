import { letseatmanagerApiMethod } from 'src/api/letseatmanagerApi';
import type { ApiSauceResponse } from 'src/api/types/ApiSauceResponse';
import type { MenuItemId } from 'src/types/Id';

export async function unHideMenuItemApi(request: UnHideMenuItemApiRequest): ApiSauceResponse<void> {
    return letseatmanagerApiMethod('menuItem/unHideMenuItemApi', request);
}

type UnHideMenuItemApiRequest = {
    menuItemId: MenuItemId;
};
