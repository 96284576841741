import { letseatadminApiMethod } from 'src/api/letseatadminApi';
import type { ApiSauceResponse } from 'src/api/types/ApiSauceResponse';
import type { OrderId } from 'src/types/Id';

export async function switchOrderDeliveryProviderToRappiCargoApi(request: switchOrderDeliveryProviderToRappiCargoApiRequest): ApiSauceResponse<string> {
    return letseatadminApiMethod('delivery/switchOrderDeliveryProviderToRappiCargoApi', request);
}

export type switchOrderDeliveryProviderToRappiCargoApiRequest = {
    orderId: OrderId;
};
