export const FacturamaPaymentForms = {
    SPEI_OXXO: '01',
    CREDIT_CARD: '04',
    SPEI_INTERNAL: '03',
    DEBIT_CARD: '28',
    COMPENSATION: '17',
    TO_BE_DEFINED: '99',
} as const;

export type FacturamaPaymentForm = (typeof FacturamaPaymentForms)[keyof typeof FacturamaPaymentForms];
