import { withStyles } from '@material-ui/core/styles';
import * as React from 'react';
import { letseatmanagerApiDeprecated } from 'src/api/letseatmanagerApiDeprecated';
import CompanyGiftsTable from 'src/scenes/letseatadmin/gift/CompanyGiftsTable';
import type { GiftVm } from 'src/scenes/letseatadmin/gift/GiftsTable';
import type { CompanyId } from 'src/types/Id';
import { alertKnownErrorOrSomethingWentWrong } from 'src/utils/alert/alertKnownErrorOrSomethingWentWrong';

export default withStyles((theme) => ({
    table: {
        whiteSpace: 'nowrap',
        '& tr': { cursor: 'pointer' },
        '& tr:hover': { backgroundColor: 'rgba(0, 0, 0, 0.07) !important' },
    },
    toolbar: {
        paddingRight: theme.spacing(3),
    },
}))(
    class CompanyGifts extends React.PureComponent<Props, State> {
        state = {
            loading: false,
            gifts: [],
        };

        async componentDidMount() {
            await this.load();
        }

        load = async () => {
            this.setState({ loading: true });
            const response = await letseatmanagerApiDeprecated.admin.getCompanyGifts({ companyId: this.props.companyId });
            this.setState({ loading: false });
            if (!response.ok) {
                alertKnownErrorOrSomethingWentWrong(response);
                return;
            }
            this.setState({ gifts: response.data.gifts });
        };

        render() {
            return (
                <CompanyGiftsTable gifts={this.state.gifts} loading={this.state.loading} onClickRefresh={this.load} columns={['giftId', 'day', 'usedCredits', 'credits', 'employee', 'benefitPlan']} />
            );
        }
    },
) as React.ComponentType<any>;

type Props = {
    companyId: CompanyId;
    classes: any;
};

type State = {
    loading: boolean;
} & CompanyGiftsVm;

type CompanyGiftsVm = {
    gifts: Array<GiftVm>;
};
