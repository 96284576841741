import { letseatmanagerApiMethod } from 'src/api/letseatmanagerApi';
import type { ApiSauceResponse } from 'src/api/types/ApiSauceResponse';
import type { RestaurantId } from 'src/types/Id';
import type { PaymentTerminalVm } from 'src/types/PaymentTerminalVm';

export async function findPaymentTerminalsApi(request: FindPaymentTerminalsApiRequest): ApiSauceResponse<Array<PaymentTerminalVm>> {
    return letseatmanagerApiMethod('paymentTerminal/findPaymentTerminalsApi', request);
}

export type FindPaymentTerminalsApiRequest = {
    restaurantId: RestaurantId;
};
