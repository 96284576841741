import Grid from '@material-ui/core/Grid';
import { Button, Tooltip } from '@pidedirecto/ui';
import { useConfirmDialog, useLoadApi } from '@pidedirecto/ui/hooks';
import { CheckIcon, CrossIcon, TrashCanIcon } from '@pidedirecto/ui/icons';
import { MUIDataTableColumn } from 'mui-datatables';
import { useState } from 'react';
import * as React from 'react';
import { findOwnFleetDriversByRestaurantIdApi } from 'src/api/letseatmanager/ownFleet/findOwnFleetDriversByRestaurantIdApi';
import { setOwnFleetDriverStatusApi } from 'src/api/letseatmanager/ownFleet/setOwnFleetDriverStatusApi';
import { CreateToolbarButton } from 'src/components/mui-datatables/CreateToolbarButton';
import { RefreshToolbarButton } from 'src/components/mui-datatables/RefreshToolbarButton';
import { SecuredAndSubscribedPage } from 'src/components/page/SecuredAndSubscribedPage';
import { Table } from 'src/components/Table';
import { OwnFleetDriverStatus, OwnFleetDriverStatuses } from 'src/constants/OwnFleetDriverStatus';
import { RolePermissions } from 'src/constants/RolePermission';
import { translate } from 'src/i18n/translate';
import { CreateExternalOwnFleetDriverDialog } from 'src/scenes/letseatadmin/driver/CreateExternalOwnFleetDriverDialog';
import { AcceptOwnFleetRequestDialog } from 'src/scenes/letseatmanager/ownFleetDrivers/AcceptOwnFleetRequestDialog';
import { RejectOwnFleetRequestDialog } from 'src/scenes/letseatmanager/ownFleetDrivers/RejectOwnFleetRequestDialog';
import { useFormatAsRestaurantCurrencyNumber } from 'src/services/restaurant/useFormatAsRestaurantCurrencyNumber';
import { DriverId } from 'src/types/Id';
import { alertKnownErrorOrSomethingWentWrong } from 'src/utils/alert/alertKnownErrorOrSomethingWentWrong';
import { formatDateTimeString } from 'src/utils/date/formatDateTimeString';
import { useSelector } from 'src/utils/react/useSelector';

export function OwnFleetDriversPage(): React.ReactElement {
    const formatAsCurrencyNumber = useFormatAsRestaurantCurrencyNumber();
    const confirmDialog = useConfirmDialog();

    const [loading, setLoading] = useState(false);
    const [openAcceptOwnFleetRequestDialog, setOpenAcceptOwnFleetRequestDialog] = useState(false);
    const [openRejectOwnFleetRequestDialog, setOpenRejectOwnFleetRequestDialog] = useState(false);
    const [openCreateExternalOwnFleetDriverDialog, setOpenCreateExternalOwnFleetDriverDialog] = useState(false);
    const [ownFleetDriverStatusRequest, setOwnFleetDriverStatusRequest] = useState<{ driverId?: DriverId; OwnFleetDriverStatus?: OwnFleetDriverStatus }>({});

    const restaurantId = useSelector((state) => state.app.restaurantId);
    const externalOwnFleetEnabled = useSelector((state) => state.app.restaurant.externalOwnFleetEnabled);

    const [loadingOwnFleetDrivers, { drivers }, refreshOwnFleetDrivers] = useLoadApi(
        findOwnFleetDriversByRestaurantIdApi,
        { restaurantId },
        { initialValue: { drivers: [] }, dependencies: [restaurantId] },
    );

    const handleClickNew = () => {
        setOpenCreateExternalOwnFleetDriverDialog(true);
    };

    const removeOwnFleetDriver = async (driverId: DriverId) => {
        const shouldRemoveOwnFleetDriver = await confirmDialog({
            title: translate('Are you sure you want to remove this driver?'),
            content: translate('By removing him, he will no longer be part of your own driver group'),
            acceptButtonText: translate('Accept'),
            cancelButtonText: translate('Cancel'),
            variant: 'error',
        });

        if (!shouldRemoveOwnFleetDriver) return;

        setLoading(true);
        const response = await setOwnFleetDriverStatusApi({ driverId, restaurantId, ownFleetDriverStatus: OwnFleetDriverStatuses.REJECTED });
        setLoading(false);
        if (!response.ok) {
            alertKnownErrorOrSomethingWentWrong(response);
            return;
        }
    };

    const ownFleetColumns: Array<MUIDataTableColumn> = [
        {
            name: 'driverId',
            label: 'ID',
            options: {
                display: 'excluded',
                filter: false,
            },
        },
        {
            name: 'driverName',
            label: translate('Name'),
            options: {
                filter: true,
            },
        },
        {
            name: 'mobileNumber',
            label: translate('Mobile Number'),
            options: {
                filter: true,
            },
        },
        {
            name: 'orders',
            label: translate('Deliveries'),
            options: {
                filter: false,
            },
        },
        {
            name: 'total',
            label: translate('Total'),
            options: {
                filter: false,
            },
        },
        {
            name: 'requestedAt',
            label: translate('Requested At'),
            options: {
                filter: false,
            },
        },
        {
            name: 'acceptedAt',
            label: translate('Accepted At'),
            options: {
                filter: false,
            },
        },
        {
            name: 'status',
            label: translate('Status'),
            options: {
                filter: false,
                customBodyRender: (status: OwnFleetDriverStatus) => (status ? translate(`OwnFleetDriverStatuses.${status}`) : ''),
            },
        },
        {
            name: 'status',
            options: {
                filter: false,
                customBodyRender: (status: OwnFleetDriverStatus, data: { rowData: Array<DriverId> }) => {
                    const DRIVER_ID_POSITION = 0;
                    const driverId = data.rowData[DRIVER_ID_POSITION];

                    return (
                        <div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
                            {status === OwnFleetDriverStatuses.PENDING && (
                                <>
                                    <Tooltip text={translate('Accept')} position='bottom'>
                                        <Button
                                            variant='icon'
                                            onClick={() => {
                                                setOwnFleetDriverStatusRequest({
                                                    driverId,
                                                    OwnFleetDriverStatus: OwnFleetDriverStatuses.ACCEPTED,
                                                });
                                                setOpenAcceptOwnFleetRequestDialog(true);
                                            }}
                                        >
                                            <CheckIcon size={20} />
                                        </Button>
                                    </Tooltip>
                                    <Tooltip text={translate('Reject')} position='bottom'>
                                        <Button
                                            variant='icon'
                                            onClick={() => {
                                                setOwnFleetDriverStatusRequest({
                                                    driverId,
                                                    OwnFleetDriverStatus: OwnFleetDriverStatuses.REJECTED,
                                                });
                                                setOpenRejectOwnFleetRequestDialog(true);
                                            }}
                                        >
                                            <CrossIcon size={20} />
                                        </Button>
                                    </Tooltip>
                                </>
                            )}
                            {status === OwnFleetDriverStatuses.REJECTED && (
                                <Tooltip text={translate('Accept')} position='bottom'>
                                    <Button
                                        variant='icon'
                                        onClick={() => {
                                            setOwnFleetDriverStatusRequest({
                                                driverId,
                                                OwnFleetDriverStatus: OwnFleetDriverStatuses.ACCEPTED,
                                            });
                                            setOpenAcceptOwnFleetRequestDialog(true);
                                        }}
                                    >
                                        <CheckIcon size={20} />
                                    </Button>
                                </Tooltip>
                            )}
                            {status === OwnFleetDriverStatuses.ACCEPTED && (
                                <>
                                    <Tooltip text={translate('Remove')} position='bottom'>
                                        <Button variant='icon' onClick={() => removeOwnFleetDriver(driverId)}>
                                            <TrashCanIcon color={'#E37878'} size={20} />
                                        </Button>
                                    </Tooltip>
                                </>
                            )}
                        </div>
                    );
                },
            },
        },
    ];

    return (
        <SecuredAndSubscribedPage title={translate('Own Drivers')} rolePermission={RolePermissions.OWN_FLEET_PAGE}>
            <Grid container spacing={5}>
                <AcceptOwnFleetRequestDialog
                    open={openAcceptOwnFleetRequestDialog}
                    onClose={() => setOpenAcceptOwnFleetRequestDialog(false)}
                    onSuccess={refreshOwnFleetDrivers}
                    driverId={ownFleetDriverStatusRequest.driverId!}
                />
                <RejectOwnFleetRequestDialog
                    open={openRejectOwnFleetRequestDialog}
                    onClose={() => setOpenRejectOwnFleetRequestDialog(false)}
                    onSuccess={refreshOwnFleetDrivers}
                    driverId={ownFleetDriverStatusRequest.driverId!}
                />
                <CreateExternalOwnFleetDriverDialog
                    open={openCreateExternalOwnFleetDriverDialog}
                    onClose={() => setOpenCreateExternalOwnFleetDriverDialog(false)}
                    onSuccess={refreshOwnFleetDrivers}
                    restaurantId={restaurantId}
                />
                <Grid item xs={12}>
                    <Table
                        loading={loadingOwnFleetDrivers || loading}
                        title={undefined}
                        data={drivers.map((ownFleetDriver) => {
                            const ownRestaurant = ownFleetDriver.linkedRestaurants?.find((linkedRestaurant) => linkedRestaurant.restaurantId === restaurantId);
                            return {
                                driverId: ownFleetDriver.driverId,
                                driverName: `${ownFleetDriver.firstName ?? ''} ${ownFleetDriver?.lastName ?? ''}`,
                                mobileNumber: ownFleetDriver.mobileNumber,
                                orders: ownFleetDriver.orders,
                                total: formatAsCurrencyNumber(ownFleetDriver.total),
                                status: ownRestaurant?.ownFleetDriverStatus,
                                requestedAt: formatDateTimeString(ownRestaurant?.requestedAt),
                                acceptedAt: formatDateTimeString(ownRestaurant?.acceptedAt),
                            };
                        })}
                        columns={ownFleetColumns}
                        options={{
                            responsive: 'standard',
                            tableBodyMaxHeight: '100%',
                            selectableRows: 'none',
                            filter: false,
                            download: false,
                            rowsPerPage: 100,
                            viewColumns: false,
                            print: false,
                            customToolbar: () => (
                                <>
                                    <RefreshToolbarButton onClick={refreshOwnFleetDrivers} />
                                    {externalOwnFleetEnabled && <CreateToolbarButton onClick={handleClickNew} />}
                                </>
                            ),
                        }}
                    />
                </Grid>
            </Grid>
        </SecuredAndSubscribedPage>
    );
}
