import * as React from 'react';
import { memo } from 'react';
import TimeLineBarChart, { Color, PeriodScale, pushDataTotalValue, XAxisScale, xAxisTooltipFormat } from 'src/scenes/letseatadmin/statistics/TimeLineBarChart';
import type { StatisticsVm } from 'src/scenes/letseatadmin/StatisticsPage';
import { formatAsNumber } from 'src/utils/number/formatAsNumber';

type Props = {
    statistics?: StatisticsVm;
    period: PeriodScale;
    xAxis: XAxisScale;
};

export const CustomersChart: React.ComponentType<Props> = memo<Props>(function CustomersChart({ statistics, period, xAxis }: Props): React.ReactElement | null {
    if (!statistics) {
        return null;
    }

    return (
        <TimeLineBarChart
            data={statisticsToData(statistics, period, xAxis)}
            period={period}
            colors={[Color.GREEN, Color.GREEN_LIGHT, Color.GREY_LIGHT]}
            yAxisLegend={''}
            stacked={false}
            yAxisFormat={(value) => formatAsNumber(`${value}`)}
            yAxisTooltipFormat={(value) => formatAsNumber(`${value}`)}
            xAxisTooltipFormat={xAxisTooltipFormat(xAxis)}
            // yScaleMin={period == = Period.TWO_WEEKS ? 'auto' : undefined}
        />
    );
});

function statisticsToData(statistics: StatisticsVm, period: PeriodScale, xAxis: XAxisScale) {
    return statistics.reduce(
        (chartData, statistic) => {
            let i = 0;
            pushDataTotalValue(chartData[i++].data, statistic.date, statistic.customer.paying, period, xAxis);
            pushDataTotalValue(chartData[i++].data, statistic.date, statistic.customer.signedUp, period, xAxis);
            pushDataTotalValue(chartData[i++].data, statistic.date, statistic.customer.downloads, period, xAxis);
            return chartData;
        },
        [
            { id: 'Paying', data: [] },
            { id: 'Signed Up', data: [] },
            { id: 'Downloads', data: [] },
        ],
    );
}
