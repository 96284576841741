import { makeStyles } from '@material-ui/core';
import * as React from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import { FormNumberField } from 'src/components/form/FormNumberField';
import { FormSwitch } from 'src/components/form/FormSwitch';
import { translate } from 'src/i18n/translate';

export function DeliveryManagerLinealSettings(): React.ReactElement {
    const classes = useStyles();

    const { control, watch } = useFormContext();

    const externalDelivery = useWatch<boolean>({ name: 'externalDelivery', control });
    const uberEatsDeliveryEnabled = watch('uberEatsDeliveryEnabled');
    const rappiCargoDeliveryEnabled = watch('rappiCargoDeliveryEnabled');
    const uberDaasDeliveryEnabled = watch('uberDaasDeliveryEnabled');
    const pideDirectoDeliveryEnabled = watch('pideDirectoDeliveryEnabled');

    return (
        <div className={classes.formContainer}>
            <FormSwitch
                disabled={externalDelivery}
                name='uberDaasDeliveryEnabled'
                label={translate('Uber Daas Delivery Enabled')}
                tooltip={translate('Activate the Uber DaaS option, you must first make sure that the Google Street Address and Telephone fields are correct')}
            />
            <FormSwitch
                name='rappiCargoDeliveryEnabled'
                label={translate('Rappi Cargo Delivery Enabled')}
                tooltip={translate('Enable the option for Rappi Cargo. Keep in mind that you must make sure that the Google Street Address and Telephone fields are correct.')}
                disabled={externalDelivery}
            />
            <FormSwitch
                disabled={externalDelivery}
                name='uberEatsDeliveryEnabled'
                label={translate('Uber Eats Delivery Enabled')}
                tooltip={translate(
                    'Activate this option to use Uber Direct (Uber dapi) For this to work you must register with an uber direct representative directly before activating the branch and register it in manager with the uber direct store id',
                )}
            />
            <FormSwitch
                disabled={externalDelivery}
                name='autoOpenClosedUberEatsStoreEnabled'
                label={translate('Uber Eats auto Open when closed')}
                tooltip={translate('Activate this option to auto open uber eats store when closed by uber')}
            />
            <FormSwitch disabled={externalDelivery} name='pideDirectoDeliveryEnabled' label={translate('Pide Directo Delivery Enabled')} tooltip={translate('Activate Pide Directo Delivery')} />
            <FormSwitch
                disabled={externalDelivery}
                name='uberDaasOrdersWithCashEnabled'
                label={translate('Allow cash payments with uber daas')}
                tooltip={translate('Activate this option if you want cash orders with Uber Daas')}
            />
            <FormSwitch
                name='rappiCargoOrdersWithCreditCardEnabled'
                disabled={externalDelivery}
                label={translate('Allow card payments with Rappicargo')}
                tooltip={translate('Enable the option to accept card for delivery of rappi charge as a method of payment')}
            />
            {(uberEatsDeliveryEnabled || rappiCargoDeliveryEnabled || uberDaasDeliveryEnabled) && pideDirectoDeliveryEnabled && (
                <FormNumberField
                    name='deliveryManagerMaxRuntime'
                    label={translate('Delivery Manager Max Runtime')}
                    tooltip={translate('Set the time for the search for delivery people within direct order, after that it is canceled and the order is sent to 3PL (Rappi Cargo, Uber Daas etc..)')}
                />
            )}
            {rappiCargoDeliveryEnabled && (
                <FormNumberField
                    name='rappiCargoDefaultTip'
                    label={translate('Rappi Cargo Default Tip')}
                    helperText={translate('The value of this field is going to be added to all orders from this restaurant as a tip.')}
                    min={0}
                />
            )}
        </div>
    );
}

const useStyles = makeStyles((theme) => ({
    formContainer: {
        width: '100%',
        display: 'grid',
        marginLeft: 2,
        marginTop: 8,
        gap: 15,
        gridTemplateColumns: '1fr',
        [theme.breakpoints.up('md')]: {
            gridTemplateColumns: '1fr 1fr',
        },
    },
}));
