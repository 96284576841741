import LinearProgress from '@material-ui/core/LinearProgress';
import { withStyles } from '@material-ui/core/styles';
import * as React from 'react';

export const UpdatingContentProgress: React.ComponentType<any> = withStyles((theme) => ({
    linearProgress: {
        '& .MuiLinearProgress-barColorPrimary': {
            backgroundColor: theme.palette.surface.brandContrast,
        },
        backgroundColor: theme.palette.surface.brand,
        position: 'absolute',
        width: '100%',
        zIndex: 10,
    },
}))(
    class UpdatingContentProgress extends React.PureComponent<Props> {
        render() {
            if (this.props.loading === false) {
                return null;
            }
            const { classes } = this.props;
            return (
                <LinearProgress
                    className={classes.linearProgress}
                    style={{
                        top: this.props.top ? 0 : undefined,
                        bottom: this.props.bottom ? 0 : undefined,
                        right: this.props.right ? 0 : undefined,
                        left: this.props.left ? 0 : undefined,
                    }}
                />
            );
        }
    },
);

type Props = {
    loading?: boolean;
    top?: boolean;
    bottom?: boolean;
    right?: boolean;
    left?: boolean;
    classes: any;
};
