import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import UpdateIcon from '@material-ui/icons/Update';
import moment from 'moment/moment';
import * as React from 'react';
import { useForm } from 'react-hook-form';
import { app2 } from 'src/app2';
import { Button } from 'src/components/Button';
import { Dialog } from 'src/components/Dialog';
import { DialogActions } from 'src/components/DialogActions';
import { Form } from 'src/components/form/Form';
import { FormDatePicker } from 'src/components/form/FormDatePicker';
import { FormDateTimePicker } from 'src/components/form/FormDateTimePicker';
import { translate } from 'src/i18n/translate';
import { useAction } from 'src/utils/react/useAction';
import { useSelector } from 'src/utils/react/useSelector';

export function DateSelectDialog(): React.ReactElement {
    const classes = useStyles();

    const open = useSelector((state) => state.app2.dateSelectDialogState.open);
    const defaultPeriod = useSelector((state) => state.app2.filterReportsState.period);
    const displayPeriodByDateAndTimeEnabled = useSelector((state) => state.app.restaurant?.displayPeriodByDateAndTimeEnabled);

    const closeDateSelectDialog = useAction(app2.actions.closeDateSelectDialog);
    const setPeriodOfReports = useAction(app2.actions.setPeriodOfReports);

    const form = useForm({
        defaultValues: {
            from: moment(defaultPeriod?.from).format('YYYY-MM-DD HH:mm:ss'),
            to: moment(defaultPeriod?.to).format('YYYY-MM-DD HH:mm:ss'),
        },
    });

    const handleSubmit = (form: any) => {
        closeDateSelectDialog();
        if (displayPeriodByDateAndTimeEnabled) {
            setPeriodOfReports(form);
            return;
        }

        setPeriodOfReports({ from: moment(form.from).startOf('day').format('YYYY-MM-DD HH:mm:ss'), to: form.to });
    };

    return (
        <Dialog open={open} title={translate('Select a period')}>
            <Form form={form} onSubmit={handleSubmit}>
                <Grid container direction='row' spacing={2} alignItems='center'>
                    <Grid item sm={12} md={6}>
                        <div className={classes.inputContainer}>
                            {displayPeriodByDateAndTimeEnabled ? <FormDateTimePicker name='from' label={translate('From')} /> : <FormDatePicker name='from' label={translate('From')} />}
                        </div>
                    </Grid>
                    <Grid item sm={12} md={6}>
                        <div className={classes.inputContainer}>
                            {displayPeriodByDateAndTimeEnabled ? <FormDateTimePicker name='to' label={translate('To')} /> : <FormDatePicker name='to' label={translate('To')} />}
                        </div>
                    </Grid>
                </Grid>
                <DialogActions>
                    <Button secondary onClick={closeDateSelectDialog}>
                        {translate('Cancel')}
                    </Button>
                    <Button type='submit'>
                        <UpdateIcon />
                        {translate('Ok')}
                    </Button>
                </DialogActions>
            </Form>
        </Dialog>
    );
}

const useStyles = makeStyles((theme) => ({
    dialogTitle: {
        textAlign: 'center',
        fontSize: 41,
        color: theme.palette.text.brand,
        paddingTop: theme.spacing(4),
        paddingBottom: theme.spacing(3),
        fontFamily: theme.typography.medium,
        '& h2': {
            fontFamily: theme.typography.medium,
        },
    },
    inputContainer: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        marginBottom: 20,
        '& .MuiInputBase-root': {
            fontFamily: `${theme.typography.regular} !important`,
            fontSize: 14,
            backgroundColor: theme.palette.surface.secondary,
            borderRadius: 5,
            padding: '5px 0 5px 15px',
        },
        '& .MuiInputBase-root::before': {
            display: 'none',
        },
        '& .MuiInputBase-root::after': {
            display: 'none',
        },
        '& .MuiFormControl-root:first-child': {
            marginRight: 10,
        },
        '& label': {
            fontFamily: theme.typography.regular,
            fontSize: 15,
            color: theme.palette.text.primary,
        },
    },
    buttonsContainer: {
        marginTop: 20,
        marginBottom: 20,
        flexDirection: 'column',
        justifyContent: 'center',
        [theme.breakpoints.up('md')]: {
            flexDirection: 'row',
        },
    },
    form: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
}));
