import { letseatadminApiMethod } from 'src/api/letseatadminApi';
import type { ApiSauceResponse } from 'src/api/types/ApiSauceResponse';
import { OrderIncidentType } from 'src/constants/OrderIncidentType';
import { OrderId } from 'src/types/Id';

export async function isOrderIncidentValidApi(request: IsOrderIncidentValidApiRequest): ApiSauceResponse<boolean> {
    return letseatadminApiMethod('orderIncident/isOrderIncidentValidApi', request);
}

export type IsOrderIncidentValidApiRequest = {
    orderId: OrderId;
    orderIncidentType: OrderIncidentType;
    refundAmount: string;
};
