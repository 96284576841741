import { letseatmanagerApiMethod } from 'src/api/letseatmanagerApi';
import type { ApiSauceResponse } from 'src/api/types/ApiSauceResponse';
import type { CustomerId, EmailAddress, RestaurantId } from 'src/types/Id';

export async function createCustomerAndRestaurantCustomerAndBrandCustomerApi(
    request: CreateCustomerAndRestaurantCustomerAndBrandCustomerRequest,
): ApiSauceResponse<CreateCustomerAndRestaurantCustomerAndBrandCustomerResponse> {
    return letseatmanagerApiMethod('customer/createCustomerAndRestaurantCustomerAndBrandCustomerApi', request);
}

type CreateCustomerAndRestaurantCustomerAndBrandCustomerRequest = {
    restaurantId: RestaurantId;
    firstName: string;
    lastName: string;
    mobileNumber: string;
    email: EmailAddress;
};

type CreateCustomerAndRestaurantCustomerAndBrandCustomerResponse = {
    customerId: CustomerId;
};
