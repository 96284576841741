import * as React from 'react';
import { DownloadCsvToolbarButton } from 'src/components/mui-datatables/DownloadCsvToolbarButton';
import { Table } from 'src/components/Table';
import { translate } from 'src/i18n/translate';
import { useFormatAsRestaurantCurrencyNumber } from 'src/services/restaurant/useFormatAsRestaurantCurrencyNumber';
import { objectsToCsv } from 'src/utils/csv/objectsToCsv';
import { downloadTextInFile } from 'src/utils/html/downloadTextInFile';

export function CashRegisterTransactionsByManagerUserTableReport({ cashRegisterTransactionsByManagerUser }: Props): React.ReactElement {
    const formatAsCurrencyNumber = useFormatAsRestaurantCurrencyNumber();

    const downloadReports = () => {
        const cashRegisterCancelledOrdersReport = cashRegisterTransactionsByManagerUser.map((cashRegisterTransactionsByManagerUser) => ({
            managerUser: cashRegisterTransactionsByManagerUser.managerUser,
            amount: formatAsCurrencyNumber(cashRegisterTransactionsByManagerUser.amount),
            transactions: cashRegisterTransactionsByManagerUser.transactions,
        }));
        const csv = objectsToCsv(cashRegisterCancelledOrdersReport);
        downloadTextInFile(csv, 'transactions-by-manager-user.csv');
    };

    return (
        <Table
            data={cashRegisterTransactionsByManagerUser.map((cashRegisterTransactionsByManagerUser) => ({
                managerUser: cashRegisterTransactionsByManagerUser.managerUser,
                amount: formatAsCurrencyNumber(cashRegisterTransactionsByManagerUser.amount),
                transactions: cashRegisterTransactionsByManagerUser.transactions,
            }))}
            columns={[
                {
                    name: 'managerUser',
                    label: translate('Manager User'),
                    options: {
                        filter: false,
                    },
                },
                {
                    name: 'amount',
                    label: translate('Amount'),
                    options: {
                        filter: false,
                    },
                },
                {
                    name: 'transactions',
                    label: translate('Transactions'),
                    options: {
                        filter: false,
                    },
                },
            ]}
            options={{
                responsive: 'standard',
                selectableRows: 'none',
                filter: false,
                search: false,
                download: false,
                print: false,
                rowsPerPage: 10,
                customToolbar: () => (
                    <>
                        <DownloadCsvToolbarButton onClick={downloadReports} />
                    </>
                ),
            }}
        />
    );
}

type Props = {
    cashRegisterTransactionsByManagerUser: Array<{
        managerUser: string;
        amount: number;
        transactions: number;
    }>;
};
