import IconButton from '@material-ui/core/IconButton';
import { withStyles } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';
import RefreshIcon from '@material-ui/icons/Refresh';
import MUIDataTable from 'mui-datatables';
import * as React from 'react';
import { UpdatingContentProgress } from 'src/components/UpdatingContentProgress';
import type { TransactionCause } from 'src/constants/TransactionCause';
import { translate } from 'src/i18n/translate';
import type { OrderId, TransactionId } from 'src/types/Id';
import { formatDateTimeString } from 'src/utils/date/formatDateTimeString';
import { getTransactionColor } from 'src/utils/transaction/getTransactionColor';

export default withStyles((theme) => ({
    table: {
        whiteSpace: 'nowrap',
        '& tr': { cursor: 'pointer' },
        '& tr:hover': { backgroundColor: 'rgba(0, 0, 0, 0.07) !important' },
    },
    toolbar: {
        paddingRight: theme.spacing(3),
    },
}))(
    class TransactionsTable extends React.PureComponent<Props> {
        render() {
            const { classes } = this.props;
            return (
                <div style={{ position: 'relative' }}>
                    <UpdatingContentProgress loading={this.props.loading} />
                    <MUIDataTable
                        /* @ts-ignore */
                        className={classes.table}
                        title={this.props.title || translate('Credit Transactions')}
                        data={this.props.transactions.map((transaction) => ({
                            transactionId: transaction.transactionId,
                            amount: transaction.amount,
                            transactionCause: transaction.transactionCause,
                            orderId: transaction.orderId,
                            promoCode: transaction.promoCode,
                            createdAt: formatDateTimeString(transaction.createdAt),
                        }))}
                        columns={[
                            {
                                name: 'transactionId',
                                label: ' ',
                                options: {
                                    display: 'excluded',
                                    filter: false,
                                },
                            },
                            {
                                name: 'amount',
                                label: translate('Amount'),
                                options: {
                                    filter: false,
                                    searchable: false,
                                },
                            },
                            {
                                name: 'transactionCause',
                                label: translate('Transaction Cause'),
                                options: {
                                    filter: false,
                                    searchable: false,
                                },
                            },
                            {
                                name: 'orderId',
                                label: translate('Order Id'),
                                options: {
                                    filter: false,
                                    searchable: false,
                                },
                            },
                            {
                                name: 'promoCode',
                                label: translate('Promo Code'),
                                options: {
                                    filter: false,
                                    searchable: false,
                                },
                            },
                            {
                                name: 'createdAt',
                                label: translate('Created At'),
                                options: {
                                    filter: false,
                                    searchable: false,
                                },
                            },
                        ]}
                        options={{
                            responsive: 'standard',
                            tableBodyMaxHeight: '500px',
                            selectableRows: 'none',
                            setRowProps: (row) => {
                                return {
                                    style: { backgroundColor: getTransactionColor(row[1]) },
                                };
                            },
                            // onRowClick: (rowData: Array<string>, rowMeta: { dataIndex: number, rowIndex: number }) => {
                            //     history.push({
                            //         pathname: RoutePath.ADMIN_CUSTOMER.replace(`:customerId(${RegExps.uuid})`, rowData[0]),
                            //         search: history.location.search,
                            //     });
                            // },
                            filterType: 'multiselect',
                            rowsPerPage: 100,
                            customToolbar: () => {
                                return (
                                    <>
                                        <Tooltip title='Refresh'>
                                            <IconButton onClick={this.props.onClickRefresh}>
                                                <RefreshIcon />
                                            </IconButton>
                                        </Tooltip>
                                    </>
                                );
                            },
                            // filterList: [[], [], [], [], ['MEXICO']],
                        }}
                    />
                </div>
            );
        }
    },
) as React.ComponentType<any>;

type Props = {
    loading: boolean;
    title?: string;
} & TransactionsVm & {
        onClickRefresh: any;
        classes: any;
    };

export type TransactionsVm = {
    transactions: Array<TransactionVm>;
};

export type TransactionVm = {
    transactionId: TransactionId;
    amount: string;
    transactionCause: TransactionCause;
    orderId?: OrderId;
    promoCode?: string;
    createdAt: Date;
};
