import { DeliveryStatuses } from 'src/constants/DeliveryStatus';
import { OrderStatuses } from 'src/constants/OrderStatus';
import { PaymentMethods } from 'src/constants/PaymentMethod';
import type { OrderVm } from 'src/types/OrderVm';
import type { RestaurantVm } from 'src/types/RestaurantVm';

export function isOrderPriceChangeAvailable(restaurant: RestaurantVm, order: OrderVm): boolean {
    return (
        (restaurant.changeManualOrderCashCostEnabled ?? false) &&
        order.paymentMethod === PaymentMethods.CASH &&
        (order.manualOrder ?? false) &&
        order.orderStatus !== OrderStatuses.COMPLETE &&
        order.orderStatus !== OrderStatuses.COMPLETED &&
        order.deliveryStatus !== DeliveryStatuses.PICKED_UP &&
        order.deliveryStatus !== DeliveryStatuses.WITHDRAWN &&
        order.deliveryStatus !== DeliveryStatuses.DELIVERED
    );
}
