import { makeStyles } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Grid from '@material-ui/core/Grid';
import * as React from 'react';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { sendNotificationToCustomerApi } from 'src/api/letseatmanager/marketing/sendNotificationToCustomerApi';
import { Form } from 'src/components/form/Form';
import { FormFixErrorHint } from 'src/components/form/FormFixErrorHint';
import { FormTextField } from 'src/components/form/FormTextField';
import { LoadingIndicator } from 'src/components/LoadingIndicator';
import { translate } from 'src/i18n/translate';
import { appLayout } from 'src/theme/AppLayout';
import type { CustomerId } from 'src/types/Id';
import { useSelector } from 'src/utils/react/useSelector';

export function SendNotificationToCustomersDialog({ open, customerIds, onClose }: Props): React.ReactElement {
    const form = useForm();
    const {
        formState: { isSubmitting },
    } = form;
    const classes = useStyles();

    let _customerTried = 0;

    const [loading, setLoading] = useState(false);
    const [customersToTry, setCustomersToTry] = useState(0);
    const [customersTried, setCustomersTried] = useState(0);

    const restaurantId = useSelector((state) => state.app.restaurantId);

    const handleClose = () => {
        if (isSubmitting || loading) return;
        onClose();
    };

    const onSubmit = async (form: any) => {
        setLoading(true);
        const result = { success: 0, failed: 0 };

        setCustomersToTry(customerIds?.length ?? 0);

        for (const customerId of customerIds ?? []) {
            _customerTried++;
            setCustomersTried(_customerTried);

            const response = await sendNotificationToCustomerApi({
                restaurantId,
                customerId,
                title: form.title,
                body: form.body,
            });

            if (response.ok) {
                result.success = result.success + 1;
            } else {
                result.failed = result.failed + 1;
            }
        }

        alert(
            'Result:\n' +
                Object.entries(result)
                    .map(([result, count]: [any, any]) => `${result as any}: ${String(count)}`)
                    .join('\n'),
        );

        setCustomersToTry(0);
        setCustomersTried(0);

        setLoading(false);
        onClose();
    };

    return (
        <Dialog fullWidth scroll='paper' open={open} onClose={handleClose} classes={{ paper: classes.paper }}>
            <Form form={form} onSubmit={onSubmit} style={appLayout.dialog.form} className={classes.form}>
                <DialogTitle classes={{ root: classes.dialogTitle }}>{translate('Send Notification')}</DialogTitle>
                <DialogContent classes={{ root: classes.dialogContent }}>
                    <Grid item xs={12}>
                        <FormTextField name='title' label={translate('Title')} required InputProps={{ autoFocus: true }} disabled={loading} />
                    </Grid>
                    <Grid item xs={12}>
                        <FormTextField name='body' label={translate('Text Body')} required disabled={loading} />
                    </Grid>
                    {loading && (
                        <LoadingIndicator
                            text={translate('@customersTried of @customersToTry', { customersTried, customersToTry })}
                            subText={translate('Please dont close this window, it could take a couple of minutes.')}
                            total={customersToTry}
                            currentValue={customersTried}
                        />
                    )}
                    <FormFixErrorHint />
                </DialogContent>
                <DialogActions className={classes.buttonsContainer}>
                    <Button onClick={handleClose} disabled={isSubmitting || loading}>
                        <div className={classes.cancelButton}>{translate('Cancel')}</div>
                    </Button>
                    <Button color='primary' type='submit' disabled={isSubmitting || loading} classes={{ root: classes.noMargin }}>
                        <div className={classes.acceptButton}>{isSubmitting || loading ? translate('Sending') : translate('Send')}</div>
                    </Button>
                </DialogActions>
            </Form>
        </Dialog>
    );
}

const useStyles = makeStyles((theme) => ({
    form: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        width: '100%',
    },
    dialogTitle: {
        textAlign: 'center',
        fontSize: 41,
        color: theme.palette.secondary.contrastText,
        paddingTop: theme.spacing(4),
        paddingBottom: theme.spacing(3),
        '& h2': {
            fontFamily: theme.typography.medium,
        },
    },
    paper: {
        [theme.breakpoints.down('md')]: {
            width: '80%',
            maxWidth: '80%',
        },
    },
    dialogContent: {
        width: '80%',
        [theme.breakpoints.down('md')]: {
            width: '70%',
        },
        [theme.breakpoints.down('sm')]: {
            width: '100%',
        },
    },
    buttonsContainer: {
        marginTop: 20,
        [theme.breakpoints.down('sm')]: {
            flexDirection: 'column',
            marginBottom: 20,
        },
    },
    noMargin: {
        margin: '0 !important',
    },
    acceptButton: {
        backgroundColor: theme.palette.primary.main,
        color: 'white',
        fontFamily: theme.typography.semiBold,
        fontSize: 15,
        borderRadius: 5,
        height: 40,
        width: 200,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        textTransform: 'none',
        boxShadow: `3px 3px 10px ${theme.palette.primary.main}aa`,
    },
    cancelButton: {
        backgroundColor: theme.palette.secondary.dark,
        color: theme.palette.secondary.contrastText,
        fontFamily: theme.typography.semiBold,
        fontSize: 15,
        borderRadius: 5,
        height: 40,
        width: 200,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        textTransform: 'none',
    },
}));

type Props = {
    open: boolean;
    customerIds?: Array<CustomerId>;
    onClose: any;
};
