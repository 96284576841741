import type { EmailTemplateVm } from 'src/api/letseatadmin/emailTemplate/getEmailTemplateApi';
import { letseatadminApiMethod } from 'src/api/letseatadminApi';
import type { ApiSauceResponse } from 'src/api/types/ApiSauceResponse';

export async function createEmailTemplateApi(request: CreateEmailTemplateApiRequest): ApiSauceResponse<EmailTemplateVm> {
    return letseatadminApiMethod('emailTemplate/createEmailTemplateApi', request);
}

export type CreateEmailTemplateApiRequest = {
    name: string;
    subject: string;
    bodyHtml?: string;
    bodyText?: string;
};
