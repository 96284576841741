import type { PaymentMethod } from 'src/api/appsyncApi';
import { letseatadminApiMethod } from 'src/api/letseatadminApi';
import type { ApiSauceResponse } from 'src/api/types/ApiSauceResponse';
import type { Country } from 'src/constants/Country';
import type { DiscountType } from 'src/constants/DiscountType';
import type { OrderType } from 'src/constants/OrderType';
import type { PromoType } from 'src/constants/PromoType';
import type { RewardType } from 'src/constants/RewardType';
import type { CompanyId, CustomerId, PromoCodeId, RestaurantId } from 'src/types/Id';

export async function getPromoCodeApi(request: GetPromoCodeApiRequest): ApiSauceResponse<PromoCodeVm> {
    return letseatadminApiMethod('promoCode/getPromoCodeApi', request);
}

export type GetPromoCodeApiRequest = {
    promoCodeId: PromoCodeId;
};

export type PromoCodeVm = {
    promoCodeId: PromoCodeId;
    code: string;
    description?: string;
    promoType: PromoType;
    rewardType: RewardType;
    discount: string;
    excludeDeliveryCost?: boolean;
    freeDelivery?: boolean;
    discountType: DiscountType;
    countries?: Array<Country>;
    restaurantIds?: Array<RestaurantId>;
    customerIds?: Array<CustomerId>;
    companyIds?: Array<CompanyId>;
    companyNames?: Array<string>;
    orderTypes?: Array<OrderType>;
    paymentMethods?: Array<PaymentMethod>;
    requireAtLeastOneCreditCard?: boolean;
    minOrderAmount?: string;
    maxDiscountAmount?: string;
    restaurantPaidPercentage?: string;
    usage?: number;
    maxUsage?: number;
    removed: boolean;
    startsAt: Date;
    endsAt: Date;
    createdAt: Date;
    modifiedAt: Date;
};
