import { BigNumber } from 'bignumber.js';
import moment from 'moment-timezone';
import type { TimeZone } from 'src/constants/TimeZone';

export function getTimeElapsed(date: string | Date, clientTimezone?: TimeZone): string {
    if (!date) return '';
    const timezone = clientTimezone || moment.tz.guess();

    const localDate = moment.tz(date, timezone);
    const currentTime = moment.tz(moment(), timezone);

    const minutes = Math.abs(currentTime.diff(localDate, 'minutes'));

    if (minutes >= 60) return getHours(minutes);

    return `${minutes} min`;
}

function getHours(minutes: number) {
    const hours = BigNumber(minutes).dividedBy(60).toFixed(0);

    const remainingMinutes = minutes % BigNumber(hours).toNumber();

    return `${hours}:${remainingMinutes < 10 ? `0${remainingMinutes}` : remainingMinutes} hrs`;
}
