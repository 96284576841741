import apisauce from 'apisauce';
import moment from 'moment';
import { envPIDEDIRECTO_ANALYTICS_API_URL } from 'src/env/envPIDEDIRECTO_ANALYTICS_API_URL';
import { isJestTest } from 'src/utils/environment/isJestTest';

export function configRestaurantStatisticsApi(): any {
    const baseURL = envPIDEDIRECTO_ANALYTICS_API_URL();

    const api = apisauce.create({
        baseURL,
        timeout: 60000,
    });

    api.addRequestTransform((request) => {
        if (isJestTest()) {
            // TODO: try to move this logic into setupTests.js, problem is that mocking code that runs inside code rendered by
            //  @testing-library/react render function is not mockable and is ignored, so currently this is the only safe way.
            throw Error(`Your test is calling api ${baseURL}/${request.url}. Make sure to mock all api calls in your tests, see usage of mockSuccessApiResponse.ts for examples.`);
        }
    });

    const isDebuggingInChrome = process.env.NODE_ENV === 'development' && !!window.navigator.userAgent; // eslint-disable-line no-undef
    if (isDebuggingInChrome) {
        api.addRequestTransform((request) => {
            console.log(`${request.method?.toUpperCase()} ${baseURL}/${request.url} @ ${moment().format('HH:mm:ss.SSS')}`, request.params ?? request.data);
        });
        api.addMonitor((response) => {
            console.log(`${response.status ?? ''} <- ${response.config?.url ?? ''} @ ${moment().format('HH:mm:ss.SSS')}`, response.data);
        });
    }

    return api;
}
