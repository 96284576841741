import { makeStyles } from '@material-ui/core';
import { CircleIconContainer, Tooltip } from '@pidedirecto/ui';
import { CheckIcon, LockIcon, OutlinedDoorbellIcon, ShieldFillIcon } from '@pidedirecto/ui/icons';
import * as React from 'react';
import { AppIcon } from 'src/components/icon/AppIcon';
import { OrderCardCashInfo } from 'src/components/orderCard/OrderCardCashInfo';
import { OrderType } from 'src/constants/OrderType';
import { translate } from 'src/i18n/translate';
import { EatHereIcon } from 'src/icons/EatHereIcon';
import { FoodBagIcon } from 'src/icons/FoodBagIcon';
import { MotorcycleIcon } from 'src/icons/MotorcycleIcon';
import { PaymentCardIcon } from 'src/icons/PaymentCardIcon';
import { PaymentCashIcon } from 'src/icons/PaymentCashIcon';
import { useFormatAsRestaurantCurrencyNumber } from 'src/services/restaurant/useFormatAsRestaurantCurrencyNumber';
import type { OrderVm } from 'src/types/OrderVm';
import { isPosApp } from 'src/utils/app/isPosApp';
import { formatDateTimeString } from 'src/utils/date/formatDateTimeString';
import { getBaseOrderCost } from 'src/utils/order/getBaseOrderCost';
import { isClosedOrder } from 'src/utils/order/isClosedOrder';
import { isDeliveryOrder } from 'src/utils/order/isDeliveryOrder';
import { isDidiApp } from 'src/utils/order/isDidiApp';
import { isEatHereOrder } from 'src/utils/order/isEatHereOrder';
import { isPickUpStationOrder } from 'src/utils/order/isPickUpStationOrder';
import { isRoomServiceOrder } from 'src/utils/order/isRoomServiceOrder';
import { isTakeAwayOrder } from 'src/utils/order/isTakeAwayOrder';
import { toHumanizedOrderId } from 'src/utils/order/toHumanizedOrderId';
import { isCardPayment } from 'src/utils/paymentMethod/isCardPayment';
import { isCashPayment } from 'src/utils/paymentMethod/isCashPayment';
import { classNames } from 'src/utils/react/classNames';
import { useSelector } from 'src/utils/react/useSelector';
import { translateOrderPaymentMethod } from 'src/utils/translate/translateOrderPaymentMethod';

export function SimplifiedOrderCard({ order, onClick, classes: classesProp }: Props): React.ReactElement | null {
    const classes = useStyles();
    const formatAsCurrencyNumber = useFormatAsRestaurantCurrencyNumber();

    const internalUser = useSelector((state) => state.authentication.internalUser);
    const roomServiceCustomLabel = useSelector((state) => state.app.restaurant?.roomServiceCustomLabel);

    const getOrderTypeIcon = (orderType: OrderType, iconColor?: undefined) => {
        if (isDeliveryOrder(orderType)) return <MotorcycleIcon title={translate('Delivery order')} color={iconColor} />;
        if (isTakeAwayOrder(orderType)) return <FoodBagIcon title={translate('Take away order')} color={iconColor} />;
        if (isRoomServiceOrder(orderType)) return <OutlinedDoorbellIcon title={translate('Room service order')} color={iconColor ?? '#133547'} />;

        return <EatHereIcon width={15} height={15} color={iconColor} title={translate('Eat here order')} />;
    };

    const getPaymentMethodLabel = () => {
        const orderPayments = order.payments ?? [];
        if (orderPayments.length > 1) return translate('Multiple payments');

        const orderPayment = orderPayments[0];
        return orderPayment?.customPaymentMethod ?? translateOrderPaymentMethod(order);
    };

    if (!order) return null;

    return (
        <div
            className={classNames(classes.container, classesProp?.container, {
                [classes.deliveryContainer]: isDeliveryOrder(order.orderType),
                [classes.takeAwayContainer]: isTakeAwayOrder(order.orderType),
                [classes.eatHereContainer]: isEatHereOrder(order.orderType),
                [classes.roomServiceContainer]: isRoomServiceOrder(order.orderType),
                [classes.pickupStationOrder]: isPickUpStationOrder(order.orderType),
            })}
            onClick={() => onClick?.(order)}
        >
            <div className={classes.headCard}>
                <div className={classes.logoContainer}>
                    <AppIcon app={order.app} />
                </div>
                <h1
                    className={classNames(classes.customerName, {
                        [classes.roomServiceText]: isRoomServiceOrder(order.orderType),
                    })}
                >
                    {order.customerName || (isPosApp(order.app) ? 'POS' : '')}
                </h1>
                {order.isManualOrderProtected && (
                    <Tooltip text={translate('Directo Protection')}>
                        <ShieldFillIcon title={translate('Directo Protection')} color={'#8d25ff'} />
                    </Tooltip>
                )}
                {isClosedOrder(order.orderStatus) && <LockIcon size={22} />}
            </div>
            <div className={classes.bodyCard}>
                {isCashPayment(order.paymentMethod) && !isDidiApp(order.app) && isDeliveryOrder(order.orderType) && <OrderCardCashInfo order={order} />}
                <div
                    className={classNames(classes.rowInfo, {
                        [classes.roomServiceText]: isRoomServiceOrder(order.orderType),
                    })}
                >
                    <div>#{toHumanizedOrderId(order.orderId)}</div>
                    <div>{formatDateTimeString(order.createdAt, order.timeZone)}</div>
                </div>
                <div
                    className={classNames(classes.rowInfo, {
                        [classes.roomServiceText]: isRoomServiceOrder(order.orderType),
                    })}
                >
                    <div
                        className={classNames(classes.subRowInfo, {
                            [classes.roomServiceText]: isRoomServiceOrder(order.orderType),
                        })}
                    >
                        <div className={classes.iconSpace}>{getOrderTypeIcon(order.orderType)}</div>
                        {isRoomServiceOrder(order.orderType) ? translate('Room service') : translate(`${order.orderType}`)}
                    </div>
                    <div className={classes.shortNameContainer}>
                        {isDeliveryOrder(order.orderType) && translate(`OrderStatuses.${order.orderStatus}`)}
                        {isTakeAwayOrder(order.orderType) && order.kitchenStatus && translate(`KitchenStatuses.${order.kitchenStatus}`)}
                        {isEatHereOrder(order.orderType) && order.kitchenStatus && translate(`KitchenStatuses.${order?.kitchenStatus}`)}
                        {isRoomServiceOrder(order.orderType) && (
                            <div className={classes.shortName}>
                                {roomServiceCustomLabel ? `${roomServiceCustomLabel} ${order?.roomServiceNumber}` : translate('room - @room', { room: order?.roomServiceNumber ?? 0 })}
                            </div>
                        )}
                    </div>
                </div>
                {isDeliveryOrder(order.orderType) && internalUser && (
                    <div className={classes.externalDeliveryProvider}>
                        {order.externalDeliveryProvider && translate(order.externalDeliveryProvider)}
                        {!order.externalDeliveryProvider && translate('Apps.PIDEDIRECTO')}
                    </div>
                )}
                {!!order.rappiTurboOrder && <div className={classes.externalDeliveryProvider}>{translate('Rappi turbo')}</div>}
                <div
                    className={classNames(classes.rowInfo, {
                        [classes.roomServiceText]: isRoomServiceOrder(order.orderType),
                    })}
                >
                    <div className={classes.subRowInfo}>
                        <div className={classes.iconSpace}>
                            {isCashPayment(order.paymentMethod) && <PaymentCashIcon title={'payment-cash-icon'} color={isRoomServiceOrder(order.orderType) ? '#133547' : '#ffffff'} />}
                            {isCardPayment(order.paymentMethod) && <PaymentCardIcon title={'payment-card-icon'} color={isRoomServiceOrder(order.orderType) ? '#133547' : '#ffffff'} />}
                        </div>
                        {getPaymentMethodLabel()}
                    </div>
                    <div className={classes.priceContainer}>
                        <div>{formatAsCurrencyNumber(getBaseOrderCost(order) ?? '0')}</div>
                        {!!order.deliveryCashHandlingFee && (
                            <div className={classes.deliveryCashHandlingFeeContainer}>
                                <CircleIconContainer classes={{ container: classes.iconContainer }}>
                                    <CheckIcon
                                        size={8}
                                        className={classNames({
                                            [classes.deliveryContent]: isDeliveryOrder(order.orderType),
                                            [classes.takeAwayContent]: isTakeAwayOrder(order.orderType),
                                            [classes.eatHereContent]: isEatHereOrder(order.orderType),
                                            [classes.roomServiceContent]: isRoomServiceOrder(order.orderType),
                                            [classes.pickupStationOrderContent]: isPickUpStationOrder(order.orderType),
                                        })}
                                    />
                                </CircleIconContainer>
                                <span className={classes.text}>{translate('Cash fee')}</span>
                            </div>
                        )}
                    </div>
                </div>
                {order.demoOrder && (
                    <div
                        className={classNames(classes.rowInfo, {
                            [classes.roomServiceText]: isRoomServiceOrder(order.orderType),
                        })}
                    >
                        {translate('Demo Order')}
                    </div>
                )}
            </div>
        </div>
    );
}

const useStyles = makeStyles((theme) => ({
    container: {
        maxWidth: '340px',
        height: 'fit-content',
        borderRadius: 8,
        color: 'white',
        padding: 12,
        cursor: 'pointer',
        boxShadow: '0px 8px 20px -4px rgba(23, 24, 24, 0.12), 0px 4px 4px 0px rgba(0, 0, 0, 0.25)',
        [theme.breakpoints.down('sm')]: {
            maxWidth: 280,
        },
    },
    deliveryContainer: {
        backgroundColor: '#133547',
    },
    takeAwayContainer: {
        backgroundColor: '#0D7084',
    },
    eatHereContainer: {
        backgroundColor: '#1A56AF',
    },
    roomServiceContainer: {
        backgroundColor: '#C9E2FF',
    },
    pickupStationOrder: {
        backgroundColor: '#1A56AF',
    },
    deliveryContent: {
        color: '#133547',
    },
    takeAwayContent: {
        color: '#0D7084',
    },
    eatHereContent: {
        color: '#1A56AF',
    },
    roomServiceContent: {
        color: '#C9E2FF',
    },
    pickupStationOrderContent: {
        color: '#1A56AF',
    },
    headCard: {
        width: '100%',
        display: 'flex',
        alignItems: 'center',
    },
    bodyCard: {
        display: 'flex',
        width: '100%',
        flexDirection: 'column',
    },
    rowInfo: {
        display: 'flex',
        width: '100%',
        justifyContent: 'space-between',
        alignItems: 'center',
        marginTop: 8,
        fontFamily: theme.typography.light,
    },
    column: {
        display: 'flex',
        flexDirection: 'column',
        gap: 4,
    },
    logoContainer: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexShrink: 0,
        marginRight: 10,
    },
    customerName: {
        fontFamily: theme.typography.semiBold,
        color: 'white',
        fontSize: 22,
        width: '100%',
        padding: 0,
        margin: 0,
        lineHeight: 1.2,
    },
    text: {
        color: 'white',
    },
    subRowInfo: {
        display: 'flex',
        width: '50%',
        justifyContent: 'flex-start',
        alignItems: 'center',
    },
    rowInfoWithSpace: {
        display: 'flex',
        width: '26%',
        justifyContent: 'space-between',
        alignItems: 'center',
    },
    iconSpace: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        marginRight: 8,
    },
    roomServiceText: {
        color: '#133547',
    },
    shortNameContainer: {
        display: 'flex',
        justifyContent: 'right',
        width: '50%',
        maxWidth: 190,
        height: 20,
        overflow: 'hidden',
        textOverflow: 'ellipsis',
    },
    shortName: {
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
    },
    externalDeliveryProvider: {
        borderRadius: 8,
        height: 20,
        padding: '2px 8px',
        fontFamily: theme.typography.medium,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        fontSize: 10,
        width: 'fit-content',
        backgroundColor: 'white',
        color: '#133547',
    },
    priceContainer: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-end',
    },
    deliveryCashHandlingFeeContainer: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        gap: 6,
    },
    iconContainer: {
        backgroundColor: 'white',
        border: 0,
        width: 18,
        height: 18,
    },
}));

type Props = {
    order: OrderVm;
    onClick?: any;
    classes?: {
        container?: string;
    };
};
