import type { RestaurantId } from 'src/types/Id';

export const KEY_NAME = 'restaurantKitchensAndDeliveryStationsOptions';

export function getRestaurantKitchensAndDeliveryStationsOptionsInLocalStorage(): Array<RestaurantOption> {
    const item = window.localStorage.getItem(KEY_NAME);
    return item ? JSON.parse(item) : [];
}

export type RestaurantOption = {
    label: string;
    value: RestaurantId;
};
