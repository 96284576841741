import { envENVIRONMENT } from 'src/env/envENVIRONMENT';
import { requireStringNotBlank } from 'src/utils/require/requireStringNotBlank';

export function envPIDEDIRECTO_ADMIN_API_URL(): string {
    try {
        return requireStringNotBlank(process.env.REACT_APP_PIDEDIRECTO_ADMIN_API_URL);
    } catch (e: any) {
        throw Error(`REACT_APP_PIDEDIRECTO_ADMIN_API_URL is not configured correctly in .env.${envENVIRONMENT().toLowerCase()}`);
    }
}
