import { makeStyles } from '@material-ui/core/styles';
import { Dialog, Text } from '@pidedirecto/ui';
import { useConfirmDialog } from '@pidedirecto/ui/hooks';
import * as React from 'react';
import { NumericPad } from 'src/components/NumericPad';
import { translate } from 'src/i18n/translate';
import { useRolePermissionActions, useRolePermissionStore } from 'src/modules/order/rolePermissionStore';
import { PinCodeUser } from 'src/types/PinCodeUser';
import { useSelector } from 'src/utils/react/useSelector';

export function AskForRolePermissionAuthorizationDialog(): React.ReactElement {
    const classes = useStyles();
    const confirmDialog = useConfirmDialog();

    const open = useRolePermissionStore((state) => state.askForRolePermissionAuthorizationDialogState?.open);
    const title = useRolePermissionStore((state) => state.askForRolePermissionAuthorizationDialogState?.title);
    const subtitle = useRolePermissionStore((state) => state.askForRolePermissionAuthorizationDialogState?.subtitle);
    const rolePermission = useRolePermissionStore((state) => state.askForRolePermissionAuthorizationDialogState?.rolePermission);
    const onAuthorized = useRolePermissionStore((state) => state.askForRolePermissionAuthorizationDialogState?.onAuthorized);
    const onUnauthorized = useRolePermissionStore((state) => state.askForRolePermissionAuthorizationDialogState?.onUnauthorized);
    const pinCodeUsers = useSelector((state) => state.authentication.pinCodeUsers);

    const closeAuthorizeOpenClosedOrderDialog = useRolePermissionActions((actions) => actions.closeAskForRolePermissionAuthorizationDialog);

    const onSubmit = (pinCode: string) => {
        if (!rolePermission) return showNotAuthorizedNotification();
        const pinCodeUser = pinCodeUsers?.find((user: PinCodeUser) => user.pinCode === pinCode);

        if (!pinCodeUser || !pinCodeUser?.userRolePermissions?.includes(rolePermission)) {
            showNotAuthorizedNotification();
            return;
        }
        onAuthorized?.();
        closeAuthorizeOpenClosedOrderDialog();
    };

    const showNotAuthorizedNotification = async () => {
        onUnauthorized?.();
        closeAuthorizeOpenClosedOrderDialog();
        confirmDialog({
            title: translate('Not allowed'),
            content: translate('Authorization required'),
            acceptButtonText: translate('Accept'),
            variant: 'warning',
        });
    };

    return (
        <Dialog classes={{ title: classes.title }} open={open} onClose={closeAuthorizeOpenClosedOrderDialog} title={title}>
            <Text className={classes.bodyText}>{subtitle}</Text>
            <NumericPad onSubmit={onSubmit} isPassword={true} />
        </Dialog>
    );
}

const useStyles = makeStyles((theme) => ({
    bodyText: {
        margin: 0,
        textAlign: 'center',
    },
    title: {
        color: theme.palette.text.primary,
    },
}));
