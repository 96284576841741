import { makeStyles } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import { BigNumber } from 'bignumber.js';
import * as React from 'react';
import { useEffect } from 'react';
import { useForm, useWatch } from 'react-hook-form';
import { changePromoCodeApi } from 'src/api/letseatmanager/promoCode/changePromoCodeApi';
import { getPromoCodeApi } from 'src/api/letseatmanager/promoCode/getPromoCodeApi';
import { Button } from 'src/components/Button';
import { Dialog } from 'src/components/Dialog';
import { DialogActions } from 'src/components/DialogActions';
import { DeprecatedFormDiscountTypeSelect } from 'src/components/form/DeprecatedFormDiscountTypeSelect';
import { DeprecatedFormPercentNumberField } from 'src/components/form/DeprecatedFormPercentNumberField';
import { Form } from 'src/components/form/Form';
import { FormCheckbox } from 'src/components/form/FormCheckbox';
import { FormCurrencyNumberStringField } from 'src/components/form/FormCurrencyNumberStringField';
import { FormCustomersAutocomplete } from 'src/components/form/FormCustomersAutocomplete';
import { FormDateTimePicker } from 'src/components/form/FormDateTimePicker';
import { FormIntegerField } from 'src/components/form/FormIntegerField';
import { FormMenuItemsAutocomplete } from 'src/components/form/FormMenuItemsAutocomplete';
import { FormOrderTypesAutocomplete } from 'src/components/form/FormOrderTypesAutocomplete';
import { FormPaymentMethodsAutocomplete } from 'src/components/form/FormPaymentMethodsAutocomplete';
import { FormPromoTypeSelect } from 'src/components/form/FormPromoTypeSelect';
import { FormRestaurantMenuItemsAutocomplete } from 'src/components/form/FormRestaurantMenuItemsAutocomplete';
import { FormRewardTypeSelect } from 'src/components/form/FormRewardTypeSelect';
import { FormSwitch } from 'src/components/form/FormSwitch';
import { FormTextField } from 'src/components/form/FormTextField';
import { FormUserRestaurantsAutocompleteMultiple } from 'src/components/form/FormUserRestaurantsAutocompleteMultiple';
import { DiscountTypes } from 'src/constants/DiscountType';
import { RewardType, RewardTypes } from 'src/constants/RewardType';
import { translate } from 'src/i18n/translate';
import type { PromoCodeId } from 'src/types/Id';
import { alertKnownErrorOrSomethingWentWrong } from 'src/utils/alert/alertKnownErrorOrSomethingWentWrong';
import { useLoadApi } from 'src/utils/react/useLoadApi';
import { useSelector } from 'src/utils/react/useSelector';

export function ChangePromoCodeDialog({ open, promoCodeId, onClose, onPromoCodeChanged }: Props): React.ReactElement {
    const form = useForm();
    const classes = useStyles();
    const {
        formState: { isSubmitting },
        watch,
        control,
        reset,
    } = form;

    const adminUser = useSelector((state) => state.authentication.adminUser);
    const restaurant = useSelector((state) => state.app.restaurant);
    const brandOpened = useSelector((state) => state.app.brandOpened);

    const discountType = watch('discountType');
    const excludeDeliveryCost = watch('excludeDeliveryCost');
    const freeDelivery = watch('freeDelivery');
    const restaurantIds = watch('restaurantIds');
    const orderTypes = watch('orderTypes');
    const restaurantPaidPercentage = watch('restaurantPaidPercentage');
    const appOnly = useWatch<boolean>({ name: 'appOnly', control });
    const webOnly = useWatch<boolean>({ name: 'webOnly', control });
    const rewardType = useWatch<RewardType>({ name: 'rewardType', control });

    console.log('orderTypes - ', orderTypes);

    const showMenuItemsSelector = rewardType === RewardTypes.PRODUCTS;

    const [loading, promoCode, load] = useLoadApi(getPromoCodeApi, { promoCodeId: promoCodeId! }, { dependencies: [promoCodeId], requiredValues: [promoCodeId], initialValue: null });

    useEffect(() => {
        if (promoCode) {
            console.log('resetting');
            reset({
                customerIds: promoCode.customerIds,
                code: promoCode.code,
                description: promoCode.description,
                promoType: promoCode.promoType,
                discount: promoCode.discount,
                excludeDeliveryCost: promoCode.excludeDeliveryCost,
                freeDelivery: promoCode.freeDelivery,
                appOnly: promoCode.appOnly,
                webOnly: promoCode.webOnly,
                discountType: promoCode.discountType,
                menuItemIds: promoCode.menuItemIds,
                rewardType: promoCode.rewardType,
                orderTypes: promoCode.orderTypes ?? [],
                paymentMethods: promoCode.paymentMethods ?? [],
                requireAtLeastOneCreditCard: promoCode.requireAtLeastOneCreditCard,
                minOrderAmount: promoCode.minOrderAmount,
                maxDiscountAmount: promoCode.maxDiscountAmount,
                maxUsage: promoCode.maxUsage,
                restaurantPaidPercentage: promoCode.restaurantPaidPercentage,
                startsAt: promoCode.startsAt,
                endsAt: promoCode.endsAt,
            });
        }
    }, [promoCode]);

    const handleClose = () => {
        if (isSubmitting) return;
        onClose();
    };

    const onSubmit = async (form: any) => {
        if (!promoCodeId) return;
        if (!adminUser && BigNumber(restaurantPaidPercentage ?? 0).isLessThan(100)) {
            alert(translate('This promo code must be changed by support. Please contact support to change it.'));
            return;
        }
        console.log('form.orderTypes = ', form.orderTypes);
        console.log('form.paymentMethods = ', form.paymentMethods);

        const response = await changePromoCodeApi({
            restaurantIds: form.restaurantIds,
            customerIds: form.customerIds,
            promoCodeId: promoCodeId,
            code: form.code,
            description: form.description,
            promoType: form.promoType,
            discount: form.discount,
            excludeDeliveryCost: form.excludeDeliveryCost,
            freeDelivery: form.freeDelivery,
            appOnly: form.appOnly,
            webOnly: form.webOnly,
            discountType: form.discountType,
            menuItemIds: form.menuItemIds,
            rewardType: form.rewardType,
            orderTypes: form.orderTypes,
            paymentMethods: form.paymentMethods,
            requireAtLeastOneCreditCard: form.requireAtLeastOneCreditCard,
            minOrderAmount: form.minOrderAmount,
            maxDiscountAmount: form.maxDiscountAmount,
            maxUsage: form.maxUsage,
            restaurantPaidPercentage: adminUser ? form.restaurantPaidPercentage : '100', // Dont allow restaurant to change their paid percentage
            startsAt: form.startsAt,
            endsAt: form.endsAt,
        });
        if (!response.ok) {
            alertKnownErrorOrSomethingWentWrong(response);
            return;
        }
        onPromoCodeChanged();
        onClose();
        reset();
    };

    return (
        <Dialog open={open} onClose={handleClose} title={translate('Change Promo Code')} loading={loading} classes={{ dialog: classes.dialog }}>
            <Form form={form} onSubmit={onSubmit}>
                <Grid container spacing={3}>
                    {brandOpened && (
                        <Grid item xs={12}>
                            <FormUserRestaurantsAutocompleteMultiple name='restaurantIds' label={translate('Restaurants')} required />
                        </Grid>
                    )}
                    <Grid item xs={12}>
                        <FormTextField name='code' label={translate('Code')} upperCase required />
                    </Grid>
                    <Grid item xs={12}>
                        <FormTextField
                            name='description'
                            label={translate('Description')}
                            helperText={translate('This description should describe what is included in the promo code and will be visible for the customer when they use the promo code.')}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <FormTextField name='usage' label={translate('Usage')} upperCase disabled />
                    </Grid>
                    <Grid item xs={12}>
                        <FormIntegerField name='maxUsage' label={translate('Max Usage')} min={0} />
                    </Grid>
                    <Grid item xs={12}>
                        <DeprecatedFormPercentNumberField name='restaurantPaidPercentage' label={translate('Restaurant Paid Percentage')} min={0} max={100} disabled={!adminUser} />
                    </Grid>
                    <Grid item xs={12}>
                        <FormPromoTypeSelect name='promoType' label={translate('Promo Type')} required />
                    </Grid>
                    <Grid item xs={12}>
                        <FormRewardTypeSelect name='rewardType' label={translate('Reward Type')} required />
                    </Grid>
                    <Grid item xs={12}>
                        <FormCustomersAutocomplete name='customerIds' label={translate('Customers')} placeholder={translate('All')} />
                    </Grid>
                    {showMenuItemsSelector && (
                        <Grid item xs={12}>
                            <div className={(classes as any).formRow}>
                                {brandOpened && (
                                    <FormRestaurantMenuItemsAutocomplete
                                        name='menuItemIds'
                                        label={translate('Products')}
                                        placeholder={translate('All products')}
                                        restaurantIds={restaurantIds}
                                        required={showMenuItemsSelector}
                                    />
                                )}
                                {!brandOpened && <FormMenuItemsAutocomplete name='menuItemIds' label={translate('Menu Products')} required={showMenuItemsSelector} />}
                            </div>
                        </Grid>
                    )}
                    <Grid item xs={12}>
                        <DeprecatedFormDiscountTypeSelect name='discountType' label={translate('Discount Type')} required />
                    </Grid>
                    <Grid item xs={12}>
                        {discountType === DiscountTypes.AMOUNT && <FormCurrencyNumberStringField name='discount' label={translate('Discount')} required />}
                        {discountType === DiscountTypes.PERCENT && <DeprecatedFormPercentNumberField name='discount' label={translate('Discount')} required />}
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <FormSwitch name='excludeDeliveryCost' label={translate('Exclude delivery cost')} disabled={freeDelivery} />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <FormSwitch name='freeDelivery' label={translate('Free Delivery')} disabled={excludeDeliveryCost} />
                    </Grid>
                    {restaurant.appOrdersEnabled && (
                        <>
                            <Grid item xs={12} md={6}>
                                <FormSwitch name='appOnly' label={translate('App Only')} disabled={webOnly} />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <FormSwitch name='webOnly' label={translate('Web Only')} disabled={appOnly} />
                            </Grid>
                        </>
                    )}
                    <Grid item xs={12}>
                        <FormCurrencyNumberStringField name='minOrderAmount' label={translate('Min Order Amount')} min={0} />
                    </Grid>
                    {discountType === DiscountTypes.PERCENT && (
                        <Grid item xs={12}>
                            <FormCurrencyNumberStringField name='maxDiscountAmount' label={translate('Max Discount Amount')} min={0} />
                        </Grid>
                    )}
                    <Grid item xs={12}>
                        <FormOrderTypesAutocomplete name='orderTypes' label={translate('Order Types')} placeholder={translate('All')} />
                    </Grid>
                    <Grid item xs={12}>
                        <FormPaymentMethodsAutocomplete name='paymentMethods' label={translate('Payment Methods')} placeholder={translate('All')} />
                    </Grid>
                    <Grid item xs={12}>
                        <FormCheckbox name='requireAtLeastOneCreditCard' label={translate('Require at least one Credit Card')} />
                    </Grid>
                    <Grid item xs={12}>
                        <FormDateTimePicker name='startsAt' label={translate('Starts')} required />
                    </Grid>
                    <Grid item xs={12}>
                        <FormDateTimePicker name='endsAt' label={translate('Ends')} required />
                    </Grid>
                </Grid>
                <DialogActions>
                    <Button secondary onClick={handleClose} disabled={isSubmitting}>
                        {translate('Cancel')}
                    </Button>
                    <Button type='submit' disabled={loading || isSubmitting}>
                        {isSubmitting ? translate('Changing') : translate('Change')}
                    </Button>
                </DialogActions>
            </Form>
        </Dialog>
    );
}

const useStyles = makeStyles((theme) => ({
    dialog: {
        width: '60%',
        maxWidth: '60%',
        maxHeight: '95%',
        height: '95%',
        borderRadius: 10,
        [theme.breakpoints.down('md')]: {
            width: '80%',
            maxWidth: '80%',
        },
    },
}));

type Props = {
    open: boolean;
    promoCodeId: PromoCodeId | undefined;
    onClose: any;
    onPromoCodeChanged: any;
};
