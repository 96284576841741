import { makeStyles } from '@material-ui/core';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { changeRestaurantMenuApi } from 'src/api/letseatmanager/restaurant/changeRestaurantMenuApi';
import { Button } from 'src/components/Button';
import { DraggableList } from 'src/components/DraggableList';
import { DraggableListItem } from 'src/components/DraggableListItem';
import { Tooltip } from 'src/components/Tooltip';
import { translate } from 'src/i18n/translate';
import { AdvancedMenuOptions } from 'src/scenes/letseatmanager/menu/menu/AdvancedMenuOptions';
import { ChangeMenuSection } from 'src/scenes/letseatmanager/menu/menu/ChangeMenuSection';
import { CloneMenuSection } from 'src/scenes/letseatmanager/menu/menu/CloneMenuSection';
import { CreateMenuSection } from 'src/scenes/letseatmanager/menu/menu/CreateMenuSection';
import { CreateRestaurantMenuPromotionDialog } from 'src/scenes/letseatmanager/menu/menu/CreateRestaurantMenuPromotionDialog';
import { MenuListItem } from 'src/scenes/letseatmanager/menu/menu/MenuListItem';
import { MenuSection } from 'src/scenes/letseatmanager/menu/menu/MenuSection';
import { RemoveMenuDialog } from 'src/scenes/letseatmanager/menu/menu/RemoveMenuDialog';
import { useIsMenuEditionDisabled } from 'src/services/menu/useIsMenuEditionDisabled';
import { useLoadMenus } from 'src/services/menu/useLoadMenus';
import { useMenus } from 'src/services/menu/useMenus';
import { useLoadRestaurantMenu } from 'src/services/restaurant/useLoadRestaurantMenu';
import { MenuId } from 'src/types/Id';
import { MenuVm } from 'src/types/MenuVm';
import { alertKnownErrorOrSomethingWentWrong } from 'src/utils/alert/alertKnownErrorOrSomethingWentWrong';
import { deepEqual } from 'src/utils/object/deepEqual';
import { classNames } from 'src/utils/react/classNames';
import { useSelector } from 'src/utils/react/useSelector';
import { useIsSmallScreen } from 'src/utils/react/window/useIsSmallScreen';

export function Menus(): React.ReactElement {
    const classes = useStyles();

    const { menus } = useMenus();
    const refreshMenus = useLoadMenus();
    const regreshRestaurantMenu = useLoadRestaurantMenu();
    const isMenuEditionDisabled = useIsMenuEditionDisabled();
    const isSmallScreen = useIsSmallScreen();

    const [removeMenuDialogState, setRemoveMenuDialogState] = useState({ open: false, menu: undefined });
    const [createRestaurantMenuPromotionDialogState, setCreateRestaurantMenuPromotionDialogState] = useState({ open: false, menu: undefined });
    const [menuManagementType, setMenuManagementType] = useState<keyof typeof MenuManagementTypes>(MenuManagementTypes.SORT);
    const [selectedMenu, setSelectedMenu] = useState<MenuVm | undefined>(undefined);

    const restaurantId = useSelector((state) => state.app.restaurantId);
    const restaurantMenuIds = useSelector((state) => state.app.restaurant.menuIds) ?? [];

    const hasUnlinkedMenus = menus.some((menu) => menu.unlinked);
    const menuIds = menus.map((menu) => menu.menuId);

    useEffect(() => {
        refreshMenus();
    }, []);

    useEffect(() => {
        if (menus && !selectedMenu && !isSmallScreen) handleSelectFirstMenu();
        if (menus && selectedMenu) updateSelectedMenu();
    }, [menus, selectedMenu, isSmallScreen]);

    const updateSelectedMenu = () => {
        if (!selectedMenu) return;

        const updatedMenu = menus.find((menu) => menu.menuId === selectedMenu.menuId);
        if (deepEqual(updatedMenu, selectedMenu) || !updatedMenu) return;

        setSelectedMenu(updatedMenu);
    };

    const handleSelectFirstMenu = () => {
        const menu = menus.find((menu) => menu.menuId === restaurantMenuIds[0]);
        if (!menu) return setSelectedMenu(menus[0]);

        setSelectedMenu(menu);
    };

    const showCreateMenu = () => {
        setMenuManagementType(MenuManagementTypes.CREATE);
    };

    const showChangeMenu = (menu: any) => {
        setMenuManagementType(MenuManagementTypes.CHANGE);
        setSelectedMenu(menu);
    };

    const showMenuToClone = (menu: any) => {
        setMenuManagementType(MenuManagementTypes.CLONE);
        setSelectedMenu(menu);
    };

    const showSortMenuCategories = () => {
        setMenuManagementType(MenuManagementTypes.SORT);
    };

    const openRemoveMenuDialog = (menu: any) => setRemoveMenuDialogState({ open: true, menu });

    const closeRemoveMenuDialog = () => setRemoveMenuDialogState({ open: false, menu: undefined });

    const openCreateRestaurantMenuPromotionDialog = (menu: any) => setCreateRestaurantMenuPromotionDialogState({ open: true, menu });

    const closeCreateRestaurantMenuPromotionDialog = () => setCreateRestaurantMenuPromotionDialogState({ open: false, menu: undefined });

    const handleSelectMenu = (menu: any) => setSelectedMenu(menu);

    const changeRestaurantMenu = async (menuIds: Array<MenuId>) => {
        const response = await changeRestaurantMenuApi({
            restaurantId,
            menuIds,
        });
        if (!response.ok) {
            alertKnownErrorOrSomethingWentWrong(response);
            return;
        }
    };

    const handleUpdate = () => {
        regreshRestaurantMenu();
        showSortMenuCategories();
    };

    const linkMenus = async () => {
        await changeRestaurantMenu(menuIds);
        refreshMenus();
    };

    return (
        <div className={classes.container}>
            <RemoveMenuDialog open={removeMenuDialogState.open} menu={removeMenuDialogState.menu} onClose={closeRemoveMenuDialog} onSuccess={handleUpdate} />
            <CreateRestaurantMenuPromotionDialog
                open={createRestaurantMenuPromotionDialogState.open}
                menu={createRestaurantMenuPromotionDialogState.menu}
                onClose={closeCreateRestaurantMenuPromotionDialog}
            />
            <section className={classes.menusContainer}>
                <div className={classes.header}>
                    <h2 className={classes.title}>{translate('Menus')}</h2>
                    <div className={classes.menuOptionsContainer}>
                        {hasUnlinkedMenus && (
                            <Tooltip text={translate('Links the menus to the restaurant')}>
                                <Button secondary onClick={linkMenus}>
                                    {translate('Link menus')}
                                </Button>
                            </Tooltip>
                        )}
                        <Button onClick={showCreateMenu} disabled={isMenuEditionDisabled}>
                            {translate('Create +')}
                        </Button>
                        <AdvancedMenuOptions />
                    </div>
                </div>
                <DraggableList onDragEnd={changeRestaurantMenu}>
                    {menus?.map((menu: MenuVm) => {
                        const isMenuSelected = menu.menuId === selectedMenu?.menuId;
                        return (
                            <DraggableListItem
                                key={menu.menuId}
                                value={menu.menuId}
                                iconColor={'#6C7076'}
                                classes={{ container: classNames(classes.draggableItemContainer, { [classes.selectedDraggableItemContainer]: isMenuSelected }) }}
                            >
                                <MenuListItem
                                    menu={menu}
                                    onChangeMenu={showChangeMenu}
                                    onRemoveMenu={openRemoveMenuDialog}
                                    onClone={showMenuToClone}
                                    onCreatePromotion={openCreateRestaurantMenuPromotionDialog}
                                    onClick={handleSelectMenu}
                                />
                            </DraggableListItem>
                        );
                    })}
                </DraggableList>
            </section>
            {menuManagementType === MenuManagementTypes.CREATE && <CreateMenuSection onClose={showSortMenuCategories} onSuccess={handleUpdate} />}
            {menuManagementType === MenuManagementTypes.CHANGE && <ChangeMenuSection menuId={selectedMenu?.menuId} onClose={showSortMenuCategories} onSuccess={handleUpdate} />}
            {menuManagementType === MenuManagementTypes.SORT && selectedMenu && <MenuSection menu={selectedMenu} onClose={() => setSelectedMenu(undefined)} />}
            {menuManagementType === MenuManagementTypes.CLONE && selectedMenu && <CloneMenuSection menuId={selectedMenu?.menuId} onClose={showSortMenuCategories} onSuccess={handleUpdate} />}
        </div>
    );
}

const MenuManagementTypes = {
    CREATE: 'CREATE',
    CHANGE: 'CHANGE',
    SORT: 'SORT',
    CLONE: 'CLONE',
} as const;

const useStyles = makeStyles((theme) => ({
    container: {
        display: 'flex',
        flexDirection: 'row',
        width: '100%',
        justifyContent: 'space-around',
        gap: 60,
        [theme.breakpoints.down('sm')]: {
            flexDirection: 'column',
        },
    },
    menusContainer: {
        width: '50%',
        flexShrink: 0,
        [theme.breakpoints.down('sm')]: {
            width: '100%',
        },
    },
    header: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        width: '100%',
        marginBottom: 20,
    },
    title: {
        fontFamily: theme.typography.medium,
        color: theme.palette.text.primary,
        fontSize: 20,
        margin: 0,
    },
    draggableItemContainer: {
        alignItems: 'center',
    },
    selectedDraggableItemContainer: {
        backgroundColor: `${theme.palette.surface.brand}63`,
        boxShadow: '0px 0px',
        cursor: 'pointer',
        borderBottom: `2px solid ${theme.palette.border.brandContrast}`,
        transition: 'backgroundColor 0.3s ease',
    },
    menuOptionsContainer: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        gap: 10,
    },
}));
