import { useEffect, useState } from 'react';
import type { GeoJsonPoint } from 'src/types/GeoJsonPoint';

export function useCurrentLocation(options: any = {}): {
    location?: GeoJsonPoint;
    error?: string;
} {
    // store location in state
    const [location, setLocation] = useState<GeoJsonPoint | undefined>();
    // store error message in state
    const [error, setError] = useState<string | undefined>();

    // Success handler for geolocation's `getCurrentPosition` method
    const handleSuccess = (pos: GeolocationPosition) => {
        const { latitude, longitude } = pos.coords;

        setLocation({
            type: 'Point',
            coordinates: [longitude, latitude],
        });
    };

    // Error handler for geolocation's `getCurrentPosition` method
    const handleError = (error: GeolocationPositionError) => {
        setError(error.message);
    };

    useEffect(() => {
        const { geolocation } = navigator;

        // If the geolocation is not defined in the used browser we handle it as an error
        if (!geolocation) {
            setError('Geolocation is not supported.');
            return;
        }

        // Call Geolocation API
        geolocation.getCurrentPosition(handleSuccess, handleError, options);
    }, [options]);

    return { location, error };
}
