import * as React from 'react';

export function WithdrawalIcon({ title, width, height, color }: Props): React.ReactElement {
    return (
        <svg width={width || 64} height={height || 63} viewBox='0 0 64 63' fill='none' xmlns='http://www.w3.org/2000/svg'>
            {title && <title>{title}</title>}
            <path
                d='M19.8077 23V24.1559C19.8077 24.4624 19.6861 24.7564 19.4697 24.9732C19.2534 25.19 18.9599 25.3118 18.6538 25.3118H17.5M17.5 25.3118V24.7338C17.5 23.7767 18.2754 23 19.2308 23H45.1923M17.5 25.3118V39.1823M45.1923 23V24.1559C45.1923 24.7939 45.7092 25.3118 46.3462 25.3118H47.5M45.1923 23H45.7692C46.7246 23 47.5 23.7767 47.5 24.7338V39.7602C47.5 40.7173 46.7246 41.494 45.7692 41.494H45.1923M17.5 39.1823V39.7602C17.5 40.22 17.6823 40.661 18.0069 40.9862C18.3315 41.3114 18.7717 41.494 19.2308 41.494H19.8077M17.5 39.1823H18.6538C18.9599 39.1823 19.2534 39.304 19.4697 39.5208C19.6861 39.7376 19.8077 40.0316 19.8077 40.3381V41.494M45.1923 41.494V40.3381C45.1923 40.0316 45.3139 39.7376 45.5303 39.5208C45.7466 39.304 46.0401 39.1823 46.3462 39.1823H47.5M45.1923 41.494H42.7431M19.8077 41.494H36.1154M43.2636 45.2438L39.5136 48.9938M39.5136 48.9938L35.7636 45.2438M39.5136 48.9938V37.7438M37.1154 32.247C37.1154 33.4732 36.6291 34.6492 35.7636 35.5163C34.898 36.3834 33.7241 36.8705 32.5 36.8705C31.2759 36.8705 30.102 36.3834 29.2364 35.5163C28.3709 34.6492 27.8846 33.4732 27.8846 32.247C27.8846 31.0208 28.3709 29.8448 29.2364 28.9777C30.102 28.1106 31.2759 27.6235 32.5 27.6235C33.7241 27.6235 34.898 28.1106 35.7636 28.9777C36.6291 29.8448 37.1154 31.0208 37.1154 32.247ZM41.7308 32.247H41.7431V32.2593H41.7308V32.247ZM23.2692 32.247H23.2815V32.2593H23.2692V32.247Z'
                stroke={color ?? 'currentColor'}
                strokeWidth='1.5'
                strokeLinecap='round'
                strokeLinejoin='round'
            />
        </svg>
    );
}

type Props = {
    title?: string;
    color?: string;
    width?: number;
    height?: number;
};
