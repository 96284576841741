import type { PromoCodeVm } from 'src/api/letseatmanager/types/PromoCodeVm';
import { letseatmanagerApiMethod } from 'src/api/letseatmanagerApi';
import type { ApiSauceResponse } from 'src/api/types/ApiSauceResponse';
import type { PromoCodeId } from 'src/types/Id';

export async function getPromoCodeApi(request: GetPromoCodeApiRequest): ApiSauceResponse<PromoCodeVm> {
    return letseatmanagerApiMethod('promoCode/getPromoCodeApi', request);
}

export type GetPromoCodeApiRequest = {
    promoCodeId: PromoCodeId;
};
