export const DefaultSupplyCategories = {
    DRINKS: 'DRINKS',
    PERISHABLES: 'PERISHABLES',
    FROZEN: 'FROZEN',
    PROTEINS: 'PROTEINS',
    VEGETABLES: 'VEGETABLES',
    DRESSINGS: 'DRESSINGS',
    PACKING: 'PACKING',
    DAIRIES: 'DAIRIES',
} as const;

export type DefaultSupplyCategory = (typeof DefaultSupplyCategories)[keyof typeof DefaultSupplyCategories];
