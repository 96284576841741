import { makeStyles } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import { formatAsCurrencyNumber } from '@pidedirecto/ui/utils';
import MUIDataTable from 'mui-datatables';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { letseatmanagerApiDeprecated } from 'src/api/letseatmanagerApiDeprecated';
import type { DriverBonusOffersVm } from 'src/api/types/driverBonusOffer/DriverBonusOffersVm';
import { app2 } from 'src/app2';
import { ChangeToolbarButton } from 'src/components/mui-datatables/ChangeToolbarButton';
import { CreateToolbarButton } from 'src/components/mui-datatables/CreateToolbarButton';
import { DuplicateToolbarButton } from 'src/components/mui-datatables/DuplicateToolbarButton';
import { RefreshToolbarButton } from 'src/components/mui-datatables/RefreshToolbarButton';
import { RemoveToolbarButton } from 'src/components/mui-datatables/RemoveToolbarButton';
import { UpdatingContentProgress } from 'src/components/UpdatingContentProgress';
import { translate } from 'src/i18n/translate';
import { ChangeDriverBonusOfferDialog } from 'src/scenes/letseatadmin/driverBonusOffer/ChangeDriverBonusOfferDialog';
import { CreateDriverBonusOfferDialog } from 'src/scenes/letseatadmin/driverBonusOffer/CreateDriverBonusOfferDialog';
import { DriverId, RestaurantId, type DriverBonusOfferId } from 'src/types/Id';
import { alertKnownErrorOrSomethingWentWrong } from 'src/utils/alert/alertKnownErrorOrSomethingWentWrong';
import { formatDateTimeString } from 'src/utils/date/formatDateTimeString';
import { copyTextToClipboard } from 'src/utils/html/copyTextToClipboard';
import { getSelectedRowsData } from 'src/utils/mui-datatables/getSelectedRowsData';
import { formatAsNumber } from 'src/utils/number/formatAsNumber';
import { toHumanizedOrderId } from 'src/utils/order/toHumanizedOrderId';
import { useAction } from 'src/utils/react/useAction';
import { useSelector } from 'src/utils/react/useSelector';

export function DriverBonusOffersPage(): React.ReactElement {
    const classes = useStyles();
    const viewUser = useSelector((state) => state.authentication.viewUser);

    const [loading, setLoading] = useState(false);
    const [vm, setVm] = useState({ driverBonusOffers: [] } as DriverBonusOffersVm);
    const [createDriverBonusOfferDialogState, setCreateDriverBonusOfferDialogState] = useState({ open: false });
    const [changeDriverBonusOfferDialogState, setChangeDriverBonusOfferDialogState] = useState({ open: false, driverBonusOfferId: undefined });
    const cities = useSelector((state) => state.app2.cities);

    const setTitle = useAction(app2.actions.setTitle);

    useEffect(() => {
        setTitle(translate('Driver Bonus Offers'));
        load();
    }, [cities]);

    const load = async () => {
        setLoading(true);
        const response = await letseatmanagerApiDeprecated.admin.getDriverBonusOffers({ cities });
        setLoading(false);
        if (!response.ok) {
            alertKnownErrorOrSomethingWentWrong(response);
            return;
        }
        setVm(response.data);
    };

    const remove = async (driverBonusOfferId: DriverBonusOfferId) => {
        setLoading(true);
        const response = await letseatmanagerApiDeprecated.admin.removeDriverBonusOffer({ driverBonusOfferId });
        if (!response.ok) {
            setLoading(false);
            alertKnownErrorOrSomethingWentWrong(response);
            return;
        }
        await load();
    };

    const removeMultiple = async (driverBonusOfferIds: Array<DriverBonusOfferId>) => {
        setLoading(true);
        for (const driverBonusOfferId of driverBonusOfferIds) {
            const response = await letseatmanagerApiDeprecated.admin.removeDriverBonusOffer({ driverBonusOfferId });
            if (!response.ok) {
                setLoading(false);
                alertKnownErrorOrSomethingWentWrong(response);
                await load();
                return;
            }
        }
        await load();
    };

    const duplicateMultiple = async (driverBonusOfferIds: Array<DriverBonusOfferId>) => {
        setLoading(true);
        for (const driverBonusOfferId of driverBonusOfferIds) {
            const response = await letseatmanagerApiDeprecated.admin.getDriverBonusOffer({ driverBonusOfferId });
            if (!response.ok) {
                setLoading(false);
                alertKnownErrorOrSomethingWentWrong(response);
                await load();
                return;
            }
            const data = response.data;
            const responsee = await letseatmanagerApiDeprecated.admin.createDriverBonusOffer({
                name: data.name,
                description: data.description,
                driverBonusType: data.driverBonusType,
                driverIncentiveType: data.driverIncentiveType,
                earning: data.earning,
                deliveriesRequired: data.deliveriesRequired,
                driverBonusCategory: data.driverBonusCategory,
                concurrent: data.concurrent,
                autoAcceptRequired: data.autoAcceptRequired,
                city: data.city,
                hours: data.hours,
                minDrivingDistance: data.minDrivingDistance,
                minOrderSubtotal: data.minOrderSubtotal,
                restaurantIds: data.restaurantIds,
                excludedRestaurantIds: data.excludedRestaurantIds,
                zones: data.zones,
                driverIds: data.driverIds,
                excludedDriverIds: data.excludedDriverIds,
                maxUsage: data.maxUsage,
                maxUsagePerDriver: data.maxUsagePerDriver,
                removed: data.removed,
                startsAt: data.startsAt,
                endsAt: data.endsAt,
            });
            if (!responsee.ok) {
                alertKnownErrorOrSomethingWentWrong(responsee);
                return;
            }
        }
        await load();
    };

    const handleClickNew = () => {
        setCreateDriverBonusOfferDialogState({ open: true });
    };

    return (
        <div className={classes.container}>
            <UpdatingContentProgress loading={loading} />
            <CreateDriverBonusOfferDialog open={createDriverBonusOfferDialogState.open} onClose={() => setCreateDriverBonusOfferDialogState({ open: false })} onDriverBonusOfferCreated={load} />
            <ChangeDriverBonusOfferDialog
                open={changeDriverBonusOfferDialogState.open}
                driverBonusOfferId={changeDriverBonusOfferDialogState.driverBonusOfferId}
                onClose={() => setChangeDriverBonusOfferDialogState({ open: false, driverBonusOfferId: undefined })}
                onDriverBonusOfferChanged={load}
            />

            <MUIDataTable
                /* @ts-ignore */
                className={classes.table}
                data={
                    vm.driverBonusOffers &&
                    vm.driverBonusOffers.map((driverBonusOffer) => ({
                        driverBonusOfferId: driverBonusOffer.driverBonusOfferId ?? '',
                        driverBonusOfferIdParsed: driverBonusOffer.driverBonusOfferId ?? '',
                        name: driverBonusOffer.name,
                        description: driverBonusOffer.description,
                        usage: driverBonusOffer.usage ?? 'Not used',
                        firstUsedAt: driverBonusOffer.firstUsedAt ?? 'Has not been used',
                        maxUsage: formatAsNumber(driverBonusOffer.maxUsage),
                        maxUsagePerDriver: formatAsNumber(driverBonusOffer.maxUsagePerDriver),
                        driverBonusType: translate(driverBonusOffer.driverBonusType),
                        driverBonusCategory: driverBonusOffer.driverBonusCategory,
                        driverIncentiveType: translate(driverBonusOffer.driverIncentiveType),
                        earning: formatAsCurrencyNumber(driverBonusOffer.earning),
                        deliveriesRequired: driverBonusOffer.deliveriesRequired,
                        concurrent: driverBonusOffer.concurrent ? 'Concurrent' : '',
                        city: translate(driverBonusOffer.city),
                        hours: driverBonusOffer.hours ?? 'Mo-Sa 00:00-24:00',
                        minDrivingDistance: formatAsNumber(driverBonusOffer.minDrivingDistance),
                        minOrderSubtotal: formatAsCurrencyNumber(driverBonusOffer.minOrderSubtotal),
                        restaurants: driverBonusOffer.restaurants?.map((restaurant: { name: string; restaurantId: RestaurantId }) => restaurant.name)?.join(', '),
                        excludedRestaurants: driverBonusOffer.excludedRestaurants?.map((restaurant: { name: string; restaurantId: RestaurantId }) => restaurant.name)?.join(', '),
                        zones: driverBonusOffer.zones?.join(', '),
                        drivers: driverBonusOffer.drivers?.map((driver: { driverId: DriverId; name: string }) => driver.name)?.join(', '),
                        excludedDrivers: driverBonusOffer.excludedDrivers?.map((driver: { driverId: DriverId; name: string }) => driver.name)?.join(','),
                        startsAt: formatDateTimeString(driverBonusOffer.startsAt) ?? '',
                        endsAt: formatDateTimeString(driverBonusOffer.endsAt) ?? '',
                        createdAt: formatDateTimeString(driverBonusOffer.createdAt) ?? '',
                    }))
                }
                columns={[
                    {
                        name: 'driverBonusOfferId',
                        label: '',
                        options: {
                            filter: false,
                            display: 'excluded',
                        },
                    },
                    {
                        name: 'driverBonusOfferIdParsed',
                        label: translate('Driver Bonus Id'),
                        options: {
                            filter: false,
                            customBodyRender: (driverBonusOfferIdParsed) => {
                                return (
                                    <Button size='small' color='primary' onClick={() => copyTextToClipboard(driverBonusOfferIdParsed)}>
                                        {toHumanizedOrderId(driverBonusOfferIdParsed as any)}
                                    </Button>
                                );
                            },
                        },
                    },
                    {
                        name: 'name',
                        label: translate('Name'),
                        options: {
                            filter: false,
                        },
                    },
                    {
                        name: 'description',
                        label: translate('Description'),
                        options: {
                            filter: false,
                        },
                    },
                    {
                        name: 'usage',
                        label: translate('Usage'),
                        options: {
                            filter: false,
                            searchable: false,
                        },
                    },
                    {
                        name: 'firstUsedAt',
                        label: translate('First Time Used'),
                        options: {
                            filter: false,
                            searchable: false,
                        },
                    },
                    {
                        name: 'maxUsage',
                        label: translate('Max Usage'),
                        options: {
                            filter: false,
                            searchable: false,
                        },
                    },
                    {
                        name: 'maxUsagePerDriver',
                        label: translate('Max Usage Per Driver'),
                        options: {
                            filter: false,
                            searchable: false,
                        },
                    },
                    {
                        name: 'driverBonusType',
                        label: translate('Driver Bonus Type'),
                        options: {
                            searchable: false,
                        },
                    },
                    {
                        name: 'driverBonusCategory',
                        label: translate('Driver Bonus Category'),
                        options: {
                            searchable: false,
                        },
                    },
                    {
                        name: 'driverIncentiveType',
                        label: translate('Driver Incentive Type'),
                        options: {
                            searchable: false,
                        },
                    },
                    {
                        name: 'earning',
                        label: translate('Earning'),
                        options: {
                            filter: false,
                            searchable: false,
                        },
                    },
                    {
                        name: 'deliveriesRequired',
                        label: translate('Deliveries Required'),
                        options: {
                            filter: false,
                            searchable: false,
                        },
                    },
                    {
                        name: 'concurrent',
                        label: translate('Concurrent'),
                        options: {
                            searchable: false,
                        },
                    },
                    {
                        name: 'city',
                        label: translate('City'),
                        options: {
                            filter: true,
                            searchable: false,
                        },
                    },
                    {
                        name: 'hours',
                        label: translate('Hours'),
                        options: {
                            filter: false,
                            searchable: false,
                        },
                    },
                    {
                        name: 'minDrivingDistance',
                        label: translate('Min Driving Distance'),
                        options: {
                            filter: false,
                            searchable: false,
                        },
                    },
                    {
                        name: 'minOrderSubtotal',
                        label: translate('Min Order Subtotal'),
                        options: {
                            filter: false,
                            searchable: false,
                        },
                    },
                    {
                        name: 'restaurants',
                        label: translate('Restaurants'),
                        options: {
                            filter: false,
                            customBodyRender: (restaurants) => restaurants?.split(', ')?.map((restaurant: any, i: any) => <div key={`${restaurant}-${i}`}>{restaurant}</div>),
                        },
                    },
                    {
                        name: 'excludedRestaurants',
                        label: translate('excludedRestaurants'),
                        options: {
                            filter: false,
                            customBodyRender: (restaurants) => restaurants?.split(', ')?.map((restaurant: any, i: any) => <div key={`${restaurant}-${i}`}>{restaurant}</div>),
                        },
                    },
                    {
                        name: 'zones',
                        label: translate('Zones'),
                        options: {
                            filter: false,
                        },
                    },
                    {
                        name: 'drivers',
                        label: translate('Drivers'),
                        options: {
                            filter: false,
                            customBodyRender: (drivers) => drivers?.split(', ')?.map((driver: any, i: any) => <div key={`${driver}-${i}`}>{driver}</div>),
                        },
                    },
                    {
                        name: 'excludedDrivers',
                        label: translate('Excluded Drivers'),
                        options: {
                            filter: false,
                            customBodyRender: (drivers) => drivers?.split(', ')?.map((driver: any, i: any) => <div key={`${driver}-${i}`}>{driver}</div>),
                        },
                    },
                    {
                        name: 'startsAt',
                        label: translate('Starts'),
                        options: {
                            filter: false,
                            searchable: false,
                        },
                    },
                    {
                        name: 'endsAt',
                        label: translate('Ends'),
                        options: {
                            filter: false,
                            searchable: false,
                        },
                    },
                    {
                        name: 'createdAt',
                        label: translate('Created'),
                        options: {
                            filter: false,
                            searchable: false,
                        },
                    },
                ]}
                options={{
                    responsive: 'standard',
                    tableBodyMaxHeight: '500px',
                    selectableRows: !viewUser ? 'multiple' : 'none',
                    filterType: 'checkbox',
                    rowsPerPage: 100,
                    customToolbar: () => (
                        <>
                            <RefreshToolbarButton onClick={load} />
                            <CreateToolbarButton onClick={handleClickNew} />
                        </>
                    ),
                    customToolbarSelect: (selectedRows, displayData) => {
                        const selectedRowsData = getSelectedRowsData(selectedRows, displayData);
                        const selectedDriverBonusOfferIds = selectedRowsData.map((d: any) => d[0]);
                        const handleClickEdit = () => {
                            if (selectedDriverBonusOfferIds.length === 1) setChangeDriverBonusOfferDialogState({ open: true, driverBonusOfferId: selectedDriverBonusOfferIds[0] });
                        };
                        const handleClickRemove = async () => {
                            const remove = window.confirm(
                                selectedDriverBonusOfferIds.length === 1
                                    ? 'Are you sure you want to remove the selected driver bonus offer'
                                    : `Are you sure you want to remove the selected ${selectedDriverBonusOfferIds.length} driver bonus offers`,
                            );
                            if (remove) {
                                await removeMultiple(selectedDriverBonusOfferIds);
                            }
                        };
                        const handleClickDuplicate = async () => {
                            const duplicate = window.confirm(
                                selectedDriverBonusOfferIds.length === 1
                                    ? 'Are you sure you want to duplicate the selected driver bonus offer'
                                    : `Are you sure you want to duplicate the selected ${selectedDriverBonusOfferIds.length} driver bonus offers`,
                            );
                            if (duplicate) {
                                await duplicateMultiple(selectedDriverBonusOfferIds);
                            }
                        };
                        return (
                            <div className={classes.toolbar}>
                                {selectedDriverBonusOfferIds.length === 1 && <ChangeToolbarButton onClick={handleClickEdit} />}
                                <RemoveToolbarButton onClick={handleClickRemove} />
                                <DuplicateToolbarButton onClick={handleClickDuplicate} />
                            </div>
                        );
                    },
                    onDownload: (buildHead, buildBody, columns, data) => {
                        return '\uFEFF' + buildHead(columns) + buildBody(data);
                    },
                    downloadOptions: { filename: 'driver bonus offers data.csv' },
                }}
            />
        </div>
    );
}

const useStyles = makeStyles((theme) => ({
    container: {
        position: 'relative',
    },
    table: {
        whiteSpace: 'nowrap',
    },
    toolbar: {
        paddingRight: theme.spacing(3),
    },
}));
