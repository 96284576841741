import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import * as React from 'react';
import { useState } from 'react';
import { useForm, useWatch } from 'react-hook-form';
import { addTipToPosOrderApi } from 'src/api/letseatmanager/order/addTipToPosOrderApi';
import { Button } from 'src/components/Button';
import { Dialog } from 'src/components/Dialog';
import { DialogActions } from 'src/components/DialogActions';
import { DeprecatedFormPercentNumberField } from 'src/components/form/DeprecatedFormPercentNumberField';
import { DeprecatedFormTipTypeSelect } from 'src/components/form/DeprecatedFormTipTypeSelect';
import { Form } from 'src/components/form/Form';
import { FormCurrencyNumberStringField } from 'src/components/form/FormCurrencyNumberStringField';
import { PosPaymentMethodSelect } from 'src/components/pos/PosPaymentMethodSelect';
import { PaymentMethod } from 'src/constants/PaymentMethod';
import { TipTypes } from 'src/constants/TipType';
import { translate } from 'src/i18n/translate';
import { getPosInterfaceLargerEnabledRestaurantSettingInLocalStorage } from 'src/localStorage/getPosInterfaceLargerEnabledRestaurantSettingInLocalStorage';
import { useAddTipToPosOrder } from 'src/services/pos/useAddTipToPosOrder';
import { CustomPaymentMethod } from 'src/types/Id';
import type { OrderVm } from 'src/types/OrderVm';
import { alertKnownErrorOrSomethingWentWrong } from 'src/utils/alert/alertKnownErrorOrSomethingWentWrong';

export function AddPosOrderTipDialog({ open, onClose, onSuccess, order }: Props): React.ReactElement {
    const classes = useStyles();

    const addTipToPosOrder = useAddTipToPosOrder();

    const [loading, setLoading] = useState(false);
    const [selectedPosPaymentMethod, setSelectedPosPaymentMethod] = useState<{ paymentMethod: PaymentMethod | undefined; customPaymentMethod: CustomPaymentMethod | undefined }>({
        paymentMethod: undefined,
        customPaymentMethod: undefined,
    });

    const posInterfaceLargerEnabled = getPosInterfaceLargerEnabledRestaurantSettingInLocalStorage();

    const form = useForm();
    const {
        formState: { isSubmitting },
        control,
    } = form;

    const tipType = useWatch({ name: 'tipType', control });

    const handleSubmit = async (form: any) => {
        if (!order) return;
        if (!selectedPosPaymentMethod?.paymentMethod) return;

        setLoading(true);
        const tip = {
            tipType: form.tipType,
            tipAmount: form.tipAmount,
            tipPercentage: form.tipPercentage,
            paymentMethod: selectedPosPaymentMethod?.paymentMethod,
            customPaymentMethod: selectedPosPaymentMethod?.customPaymentMethod,
        } as const;

        const tipPayment = await addTipToPosOrder(tip, order);

        if (!tipPayment) {
            setLoading(false);
            return;
        }

        let addTipToPosOrderApiRequest = {
            orderId: order.orderId,
            tips: [...(order.tips ?? []), tipPayment.tip],
            posPayments: [
                ...(order.posPayments ?? []),
                { ...tipPayment.payment, paymentMethod: tipPayment.payment?.customPaymentMethod ?? (tipPayment.payment?.paymentMethod as any), customPaymentMethod: undefined },
            ],
            payments: [...(order.payments ?? []), tipPayment.payment],
        } as any;

        if (order.posDiscount && order.posDiscountType) {
            addTipToPosOrderApiRequest = {
                ...addTipToPosOrderApiRequest,
                posDiscount: {
                    discount: order.posDiscount,
                    discountPercentage: order.posDiscountPercentage,
                    discountType: order.posDiscountType,
                    notes: order.posDiscountNotes,
                },
            };
        }

        const response = await addTipToPosOrderApi(addTipToPosOrderApiRequest);

        setLoading(false);

        if (!response.ok) {
            alertKnownErrorOrSomethingWentWrong(response);
            return;
        }

        onSuccess?.();
        onClose();
    };

    const handleSelectPaymentMethod = ({ paymentMethod, customPaymentMethod }: { paymentMethod: PaymentMethod; customPaymentMethod: CustomPaymentMethod }) =>
        setSelectedPosPaymentMethod({ paymentMethod, customPaymentMethod });

    return (
        <Dialog open={open} onClose={onClose} title={translate('Add Tip')} classes={{ dialog: classes.dialog }}>
            <Form onSubmit={handleSubmit} form={form} className={classes.form}>
                <div style={{ overflow: 'hidden' }}>
                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                            <DeprecatedFormTipTypeSelect name='tipType' label={translate('Tip Type')} required />
                        </Grid>
                        <Grid item xs={12}>
                            {(tipType === TipTypes.AMOUNT || !tipType) && <FormCurrencyNumberStringField name='tipAmount' label={translate('Tip')} required />}
                            {tipType === TipTypes.PERCENT && <DeprecatedFormPercentNumberField name='tipPercentage' label={translate('Tip')} required />}
                        </Grid>
                        <Grid item xs={12}>
                            <PosPaymentMethodSelect
                                selectedPaymentMethod={selectedPosPaymentMethod?.customPaymentMethod ?? selectedPosPaymentMethod?.paymentMethod}
                                onSelect={handleSelectPaymentMethod}
                            />
                        </Grid>
                    </Grid>
                </div>
                <DialogActions>
                    <Button classes={{ button: classes.button }} larger={posInterfaceLargerEnabled} secondary onClick={onClose}>
                        {translate('Cancel')}
                    </Button>
                    <Button classes={{ button: classes.button }} larger={posInterfaceLargerEnabled} type='submit' disabled={!selectedPosPaymentMethod?.paymentMethod || isSubmitting || loading}>
                        {translate('Add')}
                    </Button>
                </DialogActions>
            </Form>
        </Dialog>
    );
}

const useStyles = makeStyles((theme) => ({
    dialog: {
        [theme.breakpoints.up('sm')]: {
            maxWidth: '40vw',
        },
    },
    form: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    button: {
        width: '100%',
        [theme.breakpoints.up('sm')]: {
            width: '30%',
        },
    },
}));

type Props = {
    open: boolean;
    onClose: any;
    onSuccess: any;
    order?: OrderVm;
};
