import { letseatmanagerApiMethod } from 'src/api/letseatmanagerApi';
import type { ApiSauceResponse } from 'src/api/types/ApiSauceResponse';
import type { MenuCategoryId } from 'src/types/Id';

export async function deprecatedMoveMenuCategoryUpApi(request: MoveMenuCategoryUpApiRequest): ApiSauceResponse<void> {
    return letseatmanagerApiMethod('menu/moveMenuCategoryUpApi', request);
}

type MoveMenuCategoryUpApiRequest = {
    menuCategoryId: MenuCategoryId;
};
