import { letseatadminApiMethod } from 'src/api/letseatadminApi';
import type { ApiSauceResponse } from 'src/api/types/ApiSauceResponse';
import type { City } from 'src/constants/City';
import type { NotificationMessageType } from 'src/constants/NotificationMessageType';
import type { QuickActionId, Zone } from 'src/types/Id';

export async function getQuickActionsApi(): ApiSauceResponse<QuickActionsVm> {
    return letseatadminApiMethod('quickAction/getQuickActionsApi');
}

export type QuickActionsVm = {
    quickActions: Array<QuickActionVm>;
    zonesInfo: Array<{
        zone: string;
        orders: string;
        ratio: string;
        ordersWithDriver: string;
        ordersWithoutDriver: string;
        online: string;
    }>;
};

type QuickActionVm = {
    quickActionId: QuickActionId;
    name?: string;
    city?: City;
    zones?: Array<Zone>;
    activated?: boolean;
    deliveryRadiusInKm?: number;
    maximumNumberOfOngoingDeliveries?: number;
    customerDeliveryCostFixed?: number;
    customerDeliveryCostPerKm?: number;
    managerMessageBody?: string;
    managerMessageTitle?: string;
    managerMessageStyle?: string;
    notificationMessage?: string;
    notificationMessageType?: NotificationMessageType;
};
