import { makeStyles } from '@material-ui/core';
import { BigNumber } from 'bignumber.js';
import { useState } from 'react';
import * as React from 'react';
import { Accordion } from 'src/components/Accordion';
import { translate } from 'src/i18n/translate';
import { CashRegisterReportIcon } from 'src/icons/CashRegisterReportIcon';
import { CommissionsIcon } from 'src/icons/CommissionsIcon';
import { CreditsIcon } from 'src/icons/CreditsIcon';
import { DepositsIcon } from 'src/icons/DepositsIcon';
import { DinersIcon } from 'src/icons/DinersIcon';
import { OrderBagIcon } from 'src/icons/OrderBagIcon';
import { ScissorsIcon } from 'src/icons/ScissorsIcon';
import { TicketReportIcon } from 'src/icons/TicketReportIcon';
import { TipIcon } from 'src/icons/TipIcon';
import { WithdrawalsIcon } from 'src/icons/WithdrawalsIcon';
import { PosBusinessDayReportIndicator } from 'src/scenes/letseatmanager/posBusinessDayReport/PosBusinessDayReportIndicator';
import { useFormatAsRestaurantCurrencyNumber } from 'src/services/restaurant/useFormatAsRestaurantCurrencyNumber';
import type { CashRegisterPosBusinessDayReportVm } from 'src/types/CashRegisterPosBusinessDayReportVm';
import { CashRegisterTransaction, type PosBusinessDayReportVm } from 'src/types/PosBusinessDayReportVm';

export function PosBusinessDaySummary({ posBusinessDay }: Props): React.ReactElement {
    const classes = useStyles();
    const formatAsCurrencyNumber = useFormatAsRestaurantCurrencyNumber();

    const [showSummaryDetails, setShowSummaryDetails] = useState(true);

    const deposits = posBusinessDay?.cashRegisterTransactions?.filter((cashRegisterTransaction: CashRegisterTransaction) => BigNumber(cashRegisterTransaction.amount).isPositive()) ?? [];
    const withdraws = posBusinessDay?.cashRegisterTransactions?.filter((cashRegisterTransaction: CashRegisterTransaction) => BigNumber(cashRegisterTransaction.amount).isNegative()) ?? [];

    return (
        <div className={classes.container}>
            <div className={classes.infoContainer}>
                <PosBusinessDayReportIndicator value={formatAsCurrencyNumber(posBusinessDay.businessDaySummary.totalSales ?? 0)} label={translate('Total in box')} Icon={<CashRegisterReportIcon />} />
                <PosBusinessDayReportIndicator value={posBusinessDay.businessDaySummary.orders ?? 0} label={translate('Orders')} Icon={<OrderBagIcon />} />
                <PosBusinessDayReportIndicator value={formatAsCurrencyNumber(posBusinessDay.businessDaySummary.averageTicket ?? '0')} label={translate('Average Ticket')} Icon={<TicketReportIcon />} />
                <PosBusinessDayReportIndicator value={posBusinessDay.businessDaySummary.diners} label={translate('Diners')} Icon={<DinersIcon />} />
            </div>
            <Accordion open={showSummaryDetails}>
                <div>
                    <div className={classes.infoContainer}>
                        <PosBusinessDayReportIndicator
                            value={`${formatAsCurrencyNumber(posBusinessDay.cashRegisterTransactionSummary.deposits)} (${deposits.length})`}
                            label={translate('Deposits')}
                            Icon={<DepositsIcon />}
                        />
                        <PosBusinessDayReportIndicator
                            value={`${formatAsCurrencyNumber(posBusinessDay.cashRegisterTransactionSummary.withdraws)} (${withdraws.length})`}
                            label={translate('Withdraws')}
                            Icon={<WithdrawalsIcon />}
                        />
                        <PosBusinessDayReportIndicator value={posBusinessDay.cashRegistersSummaries.length} label={translate('Cash register shifts')} Icon={<ScissorsIcon />} />
                        <PosBusinessDayReportIndicator value={formatAsCurrencyNumber(posBusinessDay.businessDaySummary.totalTip)} label={translate('Tip')} Icon={<TipIcon />} />
                        <PosBusinessDayReportIndicator
                            value={`${formatAsCurrencyNumber(posBusinessDay.businessDaySummary.totalUsedCredits ?? '0')}`}
                            label={translate('Credits used')}
                            Icon={<CreditsIcon />}
                        />
                    </div>
                    <div className={classes.infoContainer}>
                        <PosBusinessDayReportIndicator
                            classes={{ container: classes.indicatorContainer }}
                            value={formatAsCurrencyNumber(posBusinessDay.businessDaySummary.totalCommission)}
                            label={translate('Generated Commissions')}
                            Icon={<CommissionsIcon />}
                        />
                    </div>
                </div>
            </Accordion>
            <span className={classes.accordionButton} onClick={() => setShowSummaryDetails(!showSummaryDetails)}>
                {showSummaryDetails ? translate('See less') : translate('See more')}
            </span>
        </div>
    );
}

const useStyles = makeStyles((theme) => ({
    container: {
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        marginTop: 24,
    },
    infoContainer: {
        width: '100%',
        display: 'grid',
        gridTemplateColumns: 'repeat(auto-fit, minmax(150px, 1fr))',
        gap: 12,
        marginBottom: 12,
        paddingRight: 10,
    },
    accordionButton: {
        color: theme.palette.primary.main,
        fontFamily: theme.typography.regular,
        fontSize: 15,
        textDecoration: 'underline',
        cursor: 'pointer',
        width: 'fit-content',
        margin: '0 0 0 auto',
    },
    indicatorContainer: {
        width: 'fit-content',
    },
}));

type Props = {
    posBusinessDay: PosBusinessDayReportVm | CashRegisterPosBusinessDayReportVm;
};
