import { pidedirectoReportsMethod } from 'src/api/pidedirectoReportsApi';
import type { ApiSauceResponse } from 'src/api/types/ApiSauceResponse';
import type { RestaurantId } from 'src/types/Id';
import type { SmartBiReportVm } from 'src/types/SmartBiReportVm';

export async function getSmartBiReportApi(request: GetSmartBiReportApiRequest): ApiSauceResponse<SmartBiReportVm> {
    return pidedirectoReportsMethod('/getPosSalesPredictionModelApi', request);
}

export type GetSmartBiReportApiRequest = {
    restaurantIds: Array<RestaurantId>;
};
