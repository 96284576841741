import { Grid, makeStyles } from '@material-ui/core';
import * as React from 'react';
import { useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import { sendBuyOrderApi } from 'src/api/letseatmanager/buyOrder/sendBuyOrderApi';
import { Button } from 'src/components/Button';
import { Dialog } from 'src/components/Dialog';
import { DialogActions } from 'src/components/DialogActions';
import { Form } from 'src/components/form/Form';
import { FormEmailField } from 'src/components/form/FormEmailField';
import { RoutePaths } from 'src/constants/RoutePath';
import { translate } from 'src/i18n/translate';
import { EnvelopeIcon } from 'src/icons/EnvelopeIcon';
import { appReducer } from 'src/reducers/appReducer';
import type { BuyOrderId } from 'src/types/Id';
import { alertKnownErrorOrSomethingWentWrong } from 'src/utils/alert/alertKnownErrorOrSomethingWentWrong';
import { classNames } from 'src/utils/react/classNames';
import { useAction } from 'src/utils/react/useAction';
import { useSelector } from 'src/utils/react/useSelector';
import { isEmail } from 'src/utils/string/isEmail';

export function SendBuyOrderDialog({ open, onClose, buyOrderId, supplierEmail }: Props): React.ReactElement {
    const form = useForm();
    const classes = useStyles();
    const history = useHistory();

    const {
        formState: { isSubmitting },
    } = form;

    const showSnackbar = useAction(appReducer.actions.showSnackbar);

    const pinCodeUserManagerUserId = useSelector((state) => state.authentication.pinCodeUserSignedIn?.managerUserId);

    const onSubmit = async (form: any) => {
        const response = await sendBuyOrderApi({
            buyOrderId,
            email: isEmail(form.email) ? form.email : '',
            managerUserId: pinCodeUserManagerUserId,
        });
        if (!response.ok) {
            alertKnownErrorOrSomethingWentWrong(response);
            return;
        }
        showSnackbar({ message: translate('Buy order sent') });
        onClose();
        history.push({
            pathname: RoutePaths.INVENTORY_BUY_ORDER,
            search: history.location.search,
        });
    };

    return (
        <Dialog open={open} onClose={onClose} title={translate('Send Buy Order')}>
            <Form form={form} onSubmit={onSubmit}>
                <Grid container spacing={2} direction='column' className={classes.formContainer}>
                    <Grid item lg={12}>
                        <div className={classNames(classes.icon)}>
                            <EnvelopeIcon title={translate('Send Buy Order')} color='#024B49' size={30} />
                        </div>
                    </Grid>
                    <Grid item lg={12} xs={12}>
                        <FormEmailField name='email' label={translate('Email')} disabled={isSubmitting} defaultValue={supplierEmail} />
                    </Grid>
                </Grid>

                <DialogActions>
                    <Button secondary onClick={onClose}>
                        {translate('Send later')}
                    </Button>
                    <Button type='submit' disabled={isSubmitting}>
                        {translate('Send')}
                    </Button>
                </DialogActions>
            </Form>
        </Dialog>
    );
}

const useStyles = makeStyles((theme) => ({
    dialogContainer: {
        width: '80%',
        maxHeight: '95%',
        height: 'fit-content',
        position: 'relative',
    },
    title: {
        fontFamily: theme.typography.medium,
        fontSize: 26,
        fontWeight: 600,
        marginBottom: 50,
        color: '#0FA489',
        textAlign: 'center',
        marginTop: 50,
    },
    acceptButton: {
        backgroundColor: theme.palette.primary.main,
        color: 'white',
        fontFamily: theme.typography.semiBold,
        fontSize: 15,
        borderRadius: 15,
        height: 30,
        width: 150,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        textTransform: 'none',
    },
    secondaryButton: {
        backgroundColor: 'white',
        color: theme.palette.primary.main,
        border: `1px solid ${theme.palette.primary.main}`,
        fontFamily: theme.typography.semiBold,
        fontSize: 15,
        borderRadius: 15,
        height: 30,
        width: 150,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        textTransform: 'none',
    },
    addSupplyButton: {
        backgroundColor: 'white',
        color: theme.palette.primary.main,
        border: `1px solid ${theme.palette.primary.main}`,
        fontFamily: theme.typography.semiBold,
        fontSize: 15,
        borderRadius: 5,
        height: 30,
        padding: '5px 15px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        textTransform: 'none',
    },
    supplyContainer: {
        width: '100%',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        padding: '5px 15px',
        borderBottom: `1px solid ${theme.palette.secondary.dark}`,
    },
    recipeSupplyName: {
        fontFamily: theme.typography.regular,
        fontSize: 15,
        width: '50%',
    },
    recipeSupplyPrice: {
        fontFamily: theme.typography.regular,
        fontSize: 15,
        width: '20%',
    },
    recipeSupplyButton: {
        fontFamily: theme.typography.regular,
        fontSize: 15,
        width: '10%',
    },
    dialogContent: {
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
    },
    formContainer: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    dialogActions: {
        width: '100%',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        padding: '20px 50px 20px 50px',
        marginTop: 50,
        justifyContent: 'center',
    },
    icon: {
        marginRight: 10,
        padding: 5,
        backgroundColor: '#C9F6E4',
        borderRadius: 60,
        width: 90,
        height: 90,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
}));

type Props = {
    open: boolean;
    onClose: any;
    buyOrderId: BuyOrderId;
    supplierEmail?: string;
};
