import { BigNumber } from 'bignumber.js';
import { createSlice } from 'redux-starter-kit';
import type { PromoCodeVm } from 'src/api/letseatmanager/types/PromoCodeVm';
import { Country } from 'src/constants/Country';
import { CustomerDisplayScreenOrderStep } from 'src/constants/CustomerDisplayScreenOrderStep';
import { DiscountType } from 'src/constants/DiscountType';
import { OrderType, OrderTypes } from 'src/constants/OrderType';
import { PaymentMethod } from 'src/constants/PaymentMethod';
import { PickupTimeTypes, type PickupTimeType } from 'src/constants/PickupTimeType';
import { PosOrderStep, PosOrderSteps } from 'src/constants/PosOrderStep';
import { TipType } from 'src/constants/TipType';
import type { CartItemVm } from 'src/types/CartItemVm';
import type { CashRegisterVm } from 'src/types/CashRegisterVm';
import type { CourseVm } from 'src/types/CourseVm';
import type { GeoJsonPoint } from 'src/types/GeoJsonPoint';
import {
    type CourseId,
    type CustomerId,
    type CustomPaymentMethod,
    type DeliveryEstimateId,
    type EmailAddress,
    type GooglePlaceId,
    type MenuCategoryFamilyId,
    type MenuCategoryId,
    type MenuItemId,
    type PaymentId,
    type PromotionId,
    type RestaurantId,
    type RestaurantZoneId,
} from 'src/types/Id';
import type { MenuVm } from 'src/types/MenuVm';
import { OpenedCashRegisterPosBusinessDayVm } from 'src/types/OpenedCashRegisterPosBusinessDayVm';
import { OrderItemVm, type OrderVm } from 'src/types/OrderVm';
import type { CashRegisterPosBusinessDaySummary } from 'src/types/PosBusinessDaySummaryVm';
import type { PosBusinessDayVm } from 'src/types/PosBusinessDayVm';
import type { PosContextVm } from 'src/types/PosContextVm';
import type { PosCourseOrderDetails } from 'src/types/PosCourseOrderDetails';
import type { PosCustomerOrderDetails } from 'src/types/PosCustomerOrderDetails';
import type { MenuCategoryVm, MenuItemVm, PosMenuVm } from 'src/types/PosMenuVm';
import type { PosPayment } from 'src/types/PosPayment';
import type { PosTip } from 'src/types/PosTip';
import { PosTipVm } from 'src/types/PosTipVm';
import { PromotionVm } from 'src/types/PromotionVm';
import type { RestaurantCurrency } from 'src/types/RestaurantCurrency';
import type { RestaurantZoneVm } from 'src/types/RestaurantZoneVm';
import { isItemSoldByWeight } from 'src/utils/orderItem/isItemSoldByWeight';
import { calculateOrderItemPrice } from 'src/utils/pos/calculateOrderItemPrice';
import { calculateOrderItemSubtotalPrice } from 'src/utils/pos/calculateOrderItemSubtotalPrice';
import { addCoursePosItem } from 'src/utils/pos/course/addCoursePosItem';
import { removeCoursePosItem } from 'src/utils/pos/course/removeCoursePosItem';
import { createCartItemKey } from 'src/utils/pos/createCartItemKey';
import { addCustomerPosItem } from 'src/utils/pos/customer/addCustomerPosItem';
import { removeCustomerPosItem } from 'src/utils/pos/customer/removeCustomerPosItem';
import { editPendingOrder } from 'src/utils/pos/editPendingOrder';
import { getItemFormatted } from 'src/utils/pos/getItemFormatted';
import { mapOrderItemToCartItem } from 'src/utils/pos/mapOrderItemToCartItem';
import { refreshPosPaymentCustomerState } from 'src/utils/pos/refreshPosPaymentCustomerState';
import { refreshPosPaymentState } from 'src/utils/pos/refreshPosPaymentState';
import { setPendingOrderInfo } from 'src/utils/pos/setPendingOrderInfo';
import { syncPaymentFromCustomerPayment } from 'src/utils/pos/syncPaymentFromCustomerPayment';
import { isProductsReward } from 'src/utils/promoCode/isProductsReward';
import { removeNulls } from 'src/utils/removeNulls';
import { emptyStringToUndefined } from 'src/utils/string/emptyStringToUndefined';
import { newUuid } from 'src/utils/uuid/newUuid';

export const posReducer = createSlice<PosState, Actions>({
    name: 'pos',
    initialState: {
        orderStep: PosOrderSteps.SELECT_ORDER_TYPE,
        customerDisplayScreenOrderStep: undefined,
        orderType: undefined,
        posMenu: undefined,
        itemSelected: undefined,
        selectedMenuCategory: undefined,
        selectedMenuCategoryFamilyId: undefined,
        shouldShowMenuCategoriesWithoutMenuCategoryFamily: false,
        selectedMenu: undefined,
        itemSelectedQuantity: undefined,
        itemSelectedMenuCategoryId: undefined,
        payments: [],
        items: [],
        selectedCurrency: undefined,
        itemsToPayByCustomer: [],
        cancelledItems: [],
        promotions: [],
        payment: undefined,
        pendingOrder: undefined,
        driverRequest: undefined,
        cashRegister: undefined,
        isEndOfDayReport: false,
        table: undefined,
        numberOfCustomers: undefined,
        orderOpenedFromTable: false,
        disabledPosPaymentActions: false,
        cashRegisterOpened: false,
        discount: undefined,
        tips: [],
        restaurantZone: undefined,
        totalPaid: '0',
        numericPadValue: '0',
        paying: false,
        refunding: false,
        createTableDialog: { open: false },
        createRestaurantZoneDialog: { open: false },
        changeRestaurantZoneDialog: { open: false },
        removeRestaurantZoneDialog: { open: false },
        openPosBusinessDayDialogState: { open: false },
        notes: undefined,
        restaurantTablesHasChanged: false,
        refreshRestaurantZones: undefined,
        customerId: undefined,
        promoCode: undefined,
        useLetsEatCredits: false,
        mockPaymentTerminalResponseDialog: {
            open: false,
            onSelectPaymentTerminalResponse: () => {},
            onCloseDialog: () => {},
        },
        usedCustomerCredits: '',
        addMenuItemNoteDialogState: {
            open: false,
        },
        changeMenuItemNoteDialogState: {
            open: false,
            note: '',
        },
        pinCodeUserSignInDialog: {
            open: false,
        },
        customerName: undefined,
        customers: undefined,
        customerNumberSelected: undefined,
        payTotalTicketSelected: undefined,
        context: undefined,
        openedCashRegister: undefined,
        openedCashRegisterPosBusinessDay: undefined,
        openedCashRegistersPosBusinessDay: undefined,
        openedPosBusinessDay: undefined,
        takeOrderByCourse: false,
        courses: undefined,
        courseIdSelected: undefined,
        ongoingCourses: undefined,
        openCashRegisterDialogState: { open: false },
        selectOpenedCashRegisterDialogState: { open: false },
        openMobilePaymentResume: false,
        pickupTimeType: PickupTimeTypes.ASAP,
    },
    reducers: {
        changeRestaurantTablesAndZones: (state: PosState, { payload: changed }: { payload: boolean }) => {
            state.restaurantTablesHasChanged = changed;
        },

        setPosOrderStep: (state: PosState, { payload: posOrderStep }: { payload: PosOrderStep }) => {
            state.orderStep = posOrderStep;
        },

        setCustomerDisplayScreenOrderStep: (state: PosState, { payload: customerDisplayScreenOrderStep }: { payload: CustomerDisplayScreenOrderStep | undefined }) => {
            state.customerDisplayScreenOrderStep = customerDisplayScreenOrderStep;
        },

        removeItemFromItemsToPayByCustomer: (
            state: PosState,
            { payload: { item, customerNumber, itemsQuantity } }: { payload: { item: CartItemVm; customerNumber?: number; itemsQuantity?: number } },
        ) => {
            const itemFormatted = removeNulls({
                menuItemId: item.menuItemId,
                menuItemType: item.menuItemType,
                name: item.name,
                key: item.key,
                unitPrice: item.unitPrice,
                promoUnitPrice: item.promoUnitPrice,
                promoText: item.promoText,
                imageUrl: item.imageUrl,
                note: emptyStringToUndefined(item.note),
                modifierGroups: item.modifierGroups.map((modifierGroup) => ({
                    name: modifierGroup.name,
                    modifiers: modifierGroup.modifiers.map((modifier) => ({
                        name: modifier.name,
                        price: modifier.price,
                        quantity: modifier.quantity,
                    })),
                })),
                orderItemKitchenStatus: item.orderItemKitchenStatus,
                pickupTime: item.pickupTime,
                quantity: item.quantity,
                itemsByCustomer: item.itemsByCustomer,
            });

            if (!customerNumber || !itemsQuantity) {
                state.itemsToPayByCustomer = state.itemsToPayByCustomer?.filter(
                    (
                        itemToPay: {
                            key?: string;
                        } & CartItemVm,
                    ) => itemToPay.key !== item.key,
                );
                return;
            }

            const itemToPay = state.itemsToPayByCustomer?.find(
                (
                    itemToPay: {
                        key?: string;
                    } & CartItemVm,
                ) => itemToPay.key === itemFormatted.key,
            );

            if (!itemToPay) return;

            const payment = itemToPay.itemsByCustomer?.find((payment: { customerNumber: number; itemsQuantity: number }) => payment.customerNumber === customerNumber);

            if (payment) {
                if (itemsQuantity >= 1) {
                    payment.itemsQuantity = itemsQuantity;
                } else {
                    state.itemsToPayByCustomer = state.itemsToPayByCustomer?.filter(
                        (
                            itemToPay: {
                                key?: string;
                            } & CartItemVm,
                        ) => itemToPay.key !== item.key,
                    );
                }
            }
        },

        addItemToItemsToPayCustomer: (state: PosState, { payload: { item, customerNumber, itemsQuantity } }: { payload: { item: CartItemVm; customerNumber: number; itemsQuantity: number } }) => {
            const itemFormatted = removeNulls({
                menuItemId: item.menuItemId,
                menuItemType: item.menuItemType,
                name: item.name,
                key: item.key,
                unitPrice: item.unitPrice,
                promoUnitPrice: item.promoUnitPrice,
                promoText: item.promoText,
                imageUrl: item.imageUrl,
                note: emptyStringToUndefined(item.note),
                modifierGroups: item.modifierGroups.map((modifierGroup) => ({
                    name: modifierGroup.name,
                    modifiers: modifierGroup.modifiers.map((modifier) => ({
                        name: modifier.name,
                        price: modifier.price,
                        quantity: modifier.quantity,
                    })),
                })),
                orderItemKitchenStatus: item.orderItemKitchenStatus,
                isSoldByWeight: item.isSoldByWeight,
                salesUnit: item.salesUnit,
                pickupTime: item.pickupTime,
                quantity: item.quantity,
                itemsByCustomer: item.itemsByCustomer,
            });

            const currentItem = state.itemsToPayByCustomer?.find(
                (
                    itemToPay: {
                        key?: string;
                    } & CartItemVm,
                ) => itemToPay.key === itemFormatted.key,
            );

            const newPayment = {
                customerNumber,
                itemsQuantity,
            } as const;

            if (!currentItem) {
                const newPaymentsByCustomer = itemFormatted.itemsByCustomer?.length ? [...itemFormatted.itemsByCustomer, newPayment] : [newPayment];
                itemFormatted.itemsByCustomer = newPaymentsByCustomer;
                state.itemsToPayByCustomer.push(itemFormatted);
                return;
            }

            if (!currentItem.itemsByCustomer?.length) {
                currentItem.itemsByCustomer = [newPayment];
                return;
            }

            const existPayment = currentItem.itemsByCustomer?.find((payment: { customerNumber: number; itemsQuantity: number }) => payment.customerNumber === customerNumber);

            if (!existPayment) {
                currentItem.itemsByCustomer?.push(newPayment);
                return;
            }

            existPayment.itemsQuantity = itemsQuantity;
        },

        setItemsPaidByParts: (state: PosState) => {
            state.items = state.items.map((item) => {
                const isItemPaid = state.itemsToPayByCustomer.find((itemPaid) => itemPaid.key === item.key);

                if (!isItemPaid) {
                    return item;
                }
                return {
                    ...item,
                    itemsByCustomer: isItemPaid.itemsByCustomer,
                };
            });
        },

        setTotalPaid: (state: PosState, { payload: totalPaid }: { payload: string }) => {
            state.totalPaid = totalPaid;
        },

        setPendingOrder: (state: PosState, { payload: pendingOrder }: { payload: OrderVm }) => {
            state.orderType = pendingOrder.orderType;
            state.pickupTimeType = pendingOrder.pickupTimeType;
            state.pickupTime = pendingOrder.pickupTime;
            state.pendingOrder = pendingOrder;

            if (pendingOrder.posDiscount && pendingOrder.posDiscountType) {
                state.discount = {
                    discount: pendingOrder.posDiscount,
                    discountPercentage: pendingOrder.posDiscountPercentage,
                    discountType: pendingOrder.posDiscountType,
                    notes: pendingOrder.posDiscountNotes,
                };
            }
            if (pendingOrder.tips) {
                state.tips = pendingOrder.tips.map((tip) => ({
                    tipType: tip.tipType,
                    tipPercentage: tip.tipPercentage,
                    paymentMethod: tip.paymentMethod,
                    customPaymentMethod: tip.customPaymentMethod,
                    tipAmount: tip.tipAmount ?? '0',
                    customerNumber: tip.customerNumber,
                    paymentId: newUuid() as PaymentId,
                }));
            }
        },

        showPosTableSelect: (state: PosState) => {
            state.orderStep = PosOrderSteps.SELECT_TABLE;
            state.orderType = OrderTypes.TABLE_ORDER;
        },

        selectPosOrderType: (state: PosState, { payload: posOrderType }: { payload: OrderType }) => {
            state.orderType = posOrderType;
            state.orderStep = PosOrderSteps.CREATE_ORDER;
        },

        setPosMenu: (state: PosState, { payload: { promotions, ...posMenu } }: { payload: PosMenuVm }) => {
            state.posMenu = posMenu;
            state.promotions = promotions ?? [];
        },

        resetPosMenu: (state: PosState) => {
            state.posMenu = undefined;
        },

        closePosMenu: (state: PosState) => {
            state.orderStep = PosOrderSteps.SELECT_ORDER_TYPE;
            state.customerDisplayScreenOrderStep = undefined;
            state.orderType = undefined;
            state.pendingOrder = undefined;
            state.driverRequest = undefined;
            state.selectedMenuCategory = undefined;
            state.selectedMenu = undefined;
            state.table = undefined;
            state.orderOpenedFromTable = false;
            state.disabledPosPaymentActions = false;
            state.items = [];
            state.cancelledItems = [];
            state.payments = [];
            state.notes = '';
            state.promoCode = undefined;
            state.discount = undefined;
            state.tips = undefined;
            state.customerId = undefined;
            state.useLetsEatCredits = false;
            state.usedCustomerCredits = '';
            state.customerName = undefined;
            state.customers = undefined;
            state.numberOfCustomers = undefined;
            state.customerNumberSelected = undefined;
            state.totalPaid = '0';
            state.courseIdSelected = undefined;
            state.ongoingCourses = undefined;
            state.takeOrderByCourse = false;
            state.ongoingCourses = undefined;
            refreshPosPaymentState(state);
        },

        closePosPayment: (state: PosState) => {
            state.orderStep = PosOrderSteps.CREATE_ORDER;
            state.disabledPosPaymentActions = false;
            state.paying = false;
            state.refunding = false;
            state.payTotalTicketSelected = false;
            state.numericPadValue = '0';

            if (state.customers?.length) {
                state.customerNumberSelected = 1;
            }

            refreshPosPaymentState(state);
        },

        closeAddMenuItem: (state: PosState) => {
            state.itemSelected = undefined;
            state.itemSelectedQuantity = undefined;
            state.itemSelectedMenuCategoryId = undefined;
        },

        setPosItems: (state: PosState, { payload: items }: { payload: Array<CartItemVm> }) => {
            if (!items?.length) state.items = [];

            state.items = items;

            refreshPosPaymentState(state);
        },

        addPosItem: (state: PosState, { payload: item }: { payload: CartItemVm }) => {
            const existingPosItem = state.items.find((current) => current.key === item.key);

            if (existingPosItem) {
                existingPosItem.quantity = BigNumber(existingPosItem.quantity).plus(item.quantity).toNumber();
            } else {
                state.items = [...state.items, item];
            }

            refreshPosPaymentState(state);
            addCustomerPosItem(state, item);
            addCoursePosItem(state, item);
        },

        removePosItem: (state: PosState, { payload: item }: { payload: CartItemVm }) => {
            const existingPosItem = state.items.find((current) => current.key === item.key);

            if (!existingPosItem) return;
            if (existingPosItem.quantity === 1 || isItemSoldByWeight(item)) {
                state.items = state.items?.filter((item: CartItemVm) => item.key !== existingPosItem?.key);
            }

            if (existingPosItem.quantity > 1 && !isItemSoldByWeight(existingPosItem)) {
                existingPosItem.quantity = BigNumber(existingPosItem.quantity).minus(1).toNumber();
            }

            const isCancelledItem = !!state.pendingOrder?.orderItems?.find((orderItem: OrderItemVm) => {
                const menuItem = state.posMenu?.menuItems?.find((menuItem: MenuItemVm) => menuItem.menuItemId === orderItem.menuItemId);

                if (!menuItem) return false;

                const cartItem = mapOrderItemToCartItem(menuItem, orderItem);
                return cartItem.key === item.key;
            });

            if (isCancelledItem) {
                state.cancelledItems = [...(state.cancelledItems ?? []), item];
            }

            if (!!state.promoCode && !!state.promoCode?.menuItemIds && isProductsReward(state.promoCode?.rewardType)) {
                const existsItemsWithPromoCodeApplied = state.items.some((cartItem) => state.promoCode?.menuItemIds?.includes(cartItem.menuItemId));
                if (!existsItemsWithPromoCodeApplied) state.promoCode = undefined;
            }

            refreshPosPaymentState(state);
            removeCustomerPosItem(state, item, isCancelledItem);
            removeCoursePosItem(state, item, isCancelledItem);
        },

        setOngoingCourses: (state: PosState, { payload: updatedCourses }: { payload: Array<PosCourseOrderDetails> }) => {
            state.ongoingCourses = updatedCourses;
        },

        addPosMenuItemNote: (state: PosState, { payload: menuItem }: { payload: { key: string; menuItemId: MenuItemId; note: string } }) => {
            const posMenuItem = state.items.find((item) => item.menuItemId === menuItem.menuItemId && item.key === menuItem.key);

            if (!posMenuItem) return;

            posMenuItem.note = menuItem.note;

            const menuItemFormatted = getItemFormatted(posMenuItem);

            const newKeyMenuItem = createCartItemKey(menuItemFormatted);

            const customerSelected = state.customers?.find((customer: PosCustomerOrderDetails) => customer.customerNumber === state.customerNumberSelected);
            if (customerSelected) {
                const existingPosItem = customerSelected.orderItems.find((current) => current.key === posMenuItem.key && current.menuItemId === menuItem.menuItemId);

                if (existingPosItem) {
                    existingPosItem.note = menuItem.note;
                    existingPosItem.key = newKeyMenuItem;
                }
            }

            const courseSelected = state.ongoingCourses?.find((course: PosCourseOrderDetails) => course.courseId === state.courseIdSelected);
            if (courseSelected) {
                const existingPosItem = courseSelected.orderItems?.find((current: CartItemVm) => current.key === posMenuItem.key && current.menuItemId === menuItem.menuItemId);

                if (existingPosItem) {
                    existingPosItem.note = menuItem.note;
                    existingPosItem.key = newKeyMenuItem;
                }
            }

            posMenuItem.key = newKeyMenuItem;
        },
        selectPosItem: (state: PosState, { payload: { item, quantity, menuCategoryId } }: { payload: { item: MenuItemVm; quantity?: number; menuCategoryId?: MenuCategoryId } }) => {
            state.itemSelected = item;
            state.itemSelectedQuantity = quantity;
            state.itemSelectedMenuCategoryId = menuCategoryId;
        },

        payOrder: (state: PosState, { payload: order }: { payload?: OrderVm }) => {
            state.orderStep = PosOrderSteps.PAYMENT;

            if (order) {
                editPendingOrder(state, { order });
                setPendingOrderInfo(state);
                refreshPosPaymentState(state);
            }
            state.customerNumberSelected = undefined;

            if (!state.customers?.length) return;
            if (state.useLetsEatCredits || state.promoCode) return;

            if (!state.payments?.length) {
                state.customerNumberSelected = 1;
                return;
            }

            const customerWithPendingPayment = state.customers.find((customer) => {
                const customerPayment = state.payments.find((payment) => payment.customerNumber === customer.customerNumber);

                if (!customerPayment) return true;

                return BigNumber(customer.payment?.total ?? 0).isGreaterThan(customerPayment.amount ?? 0);
            });

            if (customerWithPendingPayment) {
                state.customerNumberSelected = customerWithPendingPayment.customerNumber;
                return;
            }

            state.customerNumberSelected = undefined;
        },

        editPendingOrder: (state: PosState, { payload: { order } }: { payload: { order: OrderVm } }) => {
            state.orderStep = PosOrderSteps.CREATE_ORDER;
            editPendingOrder(state, { order });
            setPendingOrderInfo(state);

            refreshPosPaymentState(state);
        },

        clearPos: (state: PosState) => {
            state.orderType = undefined;
            state.orderStep = PosOrderSteps.SELECT_ORDER_TYPE;
            state.pickupTimeType = PickupTimeTypes.ASAP;
            state.itemSelected = undefined;
            state.selectedMenuCategory = undefined;
            state.selectedMenu = undefined;
            state.itemSelectedQuantity = undefined;
            state.itemSelectedMenuCategoryId = undefined;
            state.selectedMenuCategoryFamilyId = undefined;
            state.shouldShowMenuCategoriesWithoutMenuCategoryFamily = false;
            state.items = [];
            state.cancelledItems = [];
            state.pendingOrder = undefined;
            state.driverRequest = undefined;
            state.table = undefined;
            state.orderOpenedFromTable = false;
            state.discount = undefined;
            state.tips = undefined;
            state.payments = [];
            state.disabledPosPaymentActions = false;
            state.totalPaid = '0';
            state.paying = false;
            state.refunding = false;
            state.notes = '';
            state.promoCode = undefined;
            state.customerId = undefined;
            state.useLetsEatCredits = false;
            state.usedCustomerCredits = '';
            state.customerName = undefined;
            state.customers = undefined;
            state.numberOfCustomers = undefined;
            state.numericPadValue = '0';
            state.customerNumberSelected = undefined;
            state.payTotalTicketSelected = false;
            state.courseIdSelected = undefined;
            state.ongoingCourses = undefined;
            state.takeOrderByCourse = false;
            state.ongoingCourses = undefined;

            refreshPosPaymentState(state);
        },

        setPosDriverRequest: (state: PosState, { payload: driverRequest }: { payload: PosDriverRequest }) => {
            state.driverRequest = driverRequest;
            state.pickupTimeType = driverRequest.pickupTimeType ?? PickupTimeTypes.ASAP;
            if (state.payment) state.orderStep = PosOrderSteps.PAYMENT;
            refreshPosPaymentState(state);
        },

        openCashRegisterReport: (state: PosState, { payload: { cashRegister, isEndOfDayReport } }: { payload: { cashRegister: CashRegisterVm; isEndOfDayReport: boolean } }) => {
            state.cashRegister = cashRegister;
            state.isEndOfDayReport = isEndOfDayReport;
        },

        setCashRegisterOpen: (state: PosState, { payload: isOpen }: { payload: boolean }) => {
            state.cashRegisterOpened = isOpen;
        },

        closeCashRegisterReport: (state: PosState) => {
            state.cashRegister = undefined;
            state.isEndOfDayReport = false;
        },

        setPosTable: (
            state: PosState,
            { payload: { table, orderOpenedFromTable } }: { payload: { table: { restaurantZoneId: RestaurantZoneId; table: string; numberOfCustomers?: number }; orderOpenedFromTable?: boolean } },
        ) => {
            state.table = table;
            state.numberOfCustomers = table.numberOfCustomers;
            state.orderOpenedFromTable = !!orderOpenedFromTable;
        },

        addPosDiscount: (
            state: PosState,
            {
                payload: { discount, cartItemKeys },
            }: { payload: { discount: { discountType: DiscountType; discount: string; discountPercentage?: string; notes?: string }; cartItemKeys?: Array<string> } },
        ) => {
            state.discount = discount;
            if (cartItemKeys) {
                state.items = state.items.map((item) => {
                    const isItemDiscounted = cartItemKeys.find((cartItemKey) => cartItemKey === item.key);
                    if (!isItemDiscounted) return item;

                    let discountAmountByProduct = BigNumber(0);

                    discountAmountByProduct = BigNumber(discount.discountPercentage ?? 0)
                        .dividedBy(100)
                        .multipliedBy(calculateOrderItemPrice(item));
                    discountAmountByProduct = BigNumber.min(discountAmountByProduct, calculateOrderItemSubtotalPrice(item));

                    return {
                        ...item,
                        posDiscountPercentage: discount.discountPercentage,
                        posDiscount: discountAmountByProduct.toFixed(2).toString(),
                    };
                });
            }
            refreshPosPaymentState(state);
        },

        removePosDiscount: (state: PosState) => {
            state.discount = undefined;

            const customers = state.customers;
            customers?.forEach((customer: PosCustomerOrderDetails) => {
                customer.discount = undefined;

                refreshPosPaymentCustomerState({ customer, payments: state.payments, promotions: state.promotions });
            });

            state.items = state.items.map((item) => ({
                ...item,
                posDiscountPercentage: undefined,
            }));
            refreshPosPaymentState(state);
        },

        addPosTip: (state: PosState, { payload: tip }: { payload: PosTip }) => {
            state.tips = [...(state.tips ?? []), tip];
            refreshPosPaymentState(state);
        },

        removePosTip: (
            state: PosState,
            { payload: { paymentMethod, customPaymentMethod, paymentId } }: { payload: { paymentMethod?: PaymentMethod; customPaymentMethod?: CustomPaymentMethod; paymentId?: string } },
        ) => {
            state.tips = state.tips?.filter((tip: PosTip) => tip.paymentId !== paymentId);

            const customers = state.customers;

            customers?.forEach((customer: PosCustomerOrderDetails) => {
                customer.tips?.forEach((tip: PosTip) => {
                    if (tip.paymentMethod === paymentMethod && tip.customPaymentMethod === customPaymentMethod) {
                        customer.tips = customer.tips?.filter((tip: PosTip) => tip.paymentId !== paymentId);
                    }
                });

                refreshPosPaymentCustomerState({ customer, payments: state.payments, promotions: state.promotions });
            });

            refreshPosPaymentState(state);
        },

        removePosPromoCode: (state: PosState) => {
            state.promoCode = undefined;
            refreshPosPaymentState(state);
        },

        setRestaurantZone: (state: PosState, { payload: restaurantZone }: { payload: RestaurantZoneVm }) => {
            state.restaurantZone = restaurantZone;
        },

        openCreateRestaurantZoneDialog: (state: PosState, { payload: { onSuccess } }: { payload: { onSuccess?: any } }) => {
            state.createRestaurantZoneDialog.open = true;
            state.createRestaurantZoneDialog.onSuccess = onSuccess;
        },

        closeCreateRestaurantZoneDialog: (state: PosState) => {
            state.createRestaurantZoneDialog.open = false;
        },

        openChangeRestaurantZoneDialog: (state: PosState, { payload: { restaurantZoneId, onSuccess } }: { payload: { restaurantZoneId: RestaurantZoneId; onSuccess?: any } }) => {
            state.changeRestaurantZoneDialog.open = true;
            state.changeRestaurantZoneDialog.restaurantZoneId = restaurantZoneId;
            state.changeRestaurantZoneDialog.onSuccess = onSuccess;
        },

        closeChangeRestaurantZoneDialog: (state: PosState) => {
            state.changeRestaurantZoneDialog.open = false;
            state.changeRestaurantZoneDialog.restaurantZoneId = undefined;
        },

        openCreateTableDialog: (state: PosState, { payload: { restaurantZoneId, onSuccess } }: { payload: { restaurantZoneId: RestaurantZoneId; onSuccess?: any } }) => {
            state.createTableDialog.open = true;
            state.createTableDialog.restaurantZoneId = restaurantZoneId;
            state.createTableDialog.onSuccess = onSuccess;
        },

        closeCreateTableDialog: (state: PosState) => {
            state.createTableDialog.open = false;
            state.createTableDialog.restaurantZoneId = undefined;
            state.createTableDialog.onSuccess = undefined;
        },

        closeRemoveRestaurantZoneDialog: (state: PosState) => {
            state.removeRestaurantZoneDialog.open = false;
            state.removeRestaurantZoneDialog.restaurantZoneId = undefined;
            state.removeRestaurantZoneDialog.onSuccess = undefined;
        },

        openRemoveRestaurantZoneDialog: (state: PosState, { payload: { restaurantZoneId, onSuccess } }: { payload: { restaurantZoneId: RestaurantZoneId; onSuccess?: any } }) => {
            state.removeRestaurantZoneDialog.open = true;
            state.removeRestaurantZoneDialog.restaurantZoneId = restaurantZoneId;
            state.removeRestaurantZoneDialog.onSuccess = onSuccess;
        },

        openPosBusinessDayDialog: (state: PosState, { payload: { onSuccess } }: { payload: { onSuccess?: any } }) => {
            state.openPosBusinessDayDialogState.open = true;
            state.openPosBusinessDayDialogState.onSuccess = onSuccess ?? undefined;
        },

        closePosBusinessDayDialog: (state: PosState) => {
            state.openPosBusinessDayDialogState.open = false;
            state.openPosBusinessDayDialogState.onSuccess = undefined;
        },

        setDisabledPosPaymentActions: (state: PosState, { payload: disabled }: { payload: boolean }) => {
            state.disabledPosPaymentActions = disabled;
        },

        setPayments: (state: PosState, { payload: posPayments }: { payload: Array<PosPayment> }) => {
            state.payments = posPayments;

            if (!state.customerNumberSelected && !state.customers?.length) return;

            const customerSelected = state.customers?.find((customer: PosCustomerOrderDetails) => customer.customerNumber === state.customerNumberSelected);

            if (!customerSelected) return;

            refreshPosPaymentCustomerState({ customer: customerSelected, payments: state.payments, promotions: state.promotions });
            refreshPosPaymentState(state);
        },

        setPaying: (state: PosState, { payload: paying }: { payload: boolean }) => {
            state.paying = paying;
        },

        setRefunding: (state: PosState, { payload: refunding }: { payload: boolean }) => {
            state.refunding = refunding;
        },

        refreshPosPaymentCustomer: (state: PosState, { payload: customerToUpdate }: { payload: PosCustomerOrderDetails }) => {
            const customer = state.customers?.find((customer: PosCustomerOrderDetails) => customer.customerNumber === customerToUpdate.customerNumber);
            if (!customer) return;

            refreshPosPaymentCustomerState({ customer, payments: state.payments, promotions: state.promotions });
        },

        clearPayByPartsState: (state: PosState) => {
            state.itemsToPayByCustomer = [];
        },

        setNotes: (state: PosState, { payload: notes }: { payload: string }) => {
            state.notes = notes;
        },

        setItems: (state: PosState, { payload: items }: { payload: Array<CartItemVm> }) => {
            state.items = items;
        },

        removeItemsPaidByCustomer: (state: PosState, { payload: { posPayment } }: { payload: { posPayment?: PosPayment } }) => {
            if (!posPayment) return;
            if (!posPayment.customerNumber) return;

            state.items = state.items.map((item) => {
                const customerPayment = item.itemsByCustomer?.find((payment: { customerNumber: number; itemsQuantity: number }) => payment.customerNumber === posPayment.customerNumber);

                if (!customerPayment) return item;

                return {
                    ...item,
                    itemsByCustomer: item.itemsByCustomer?.filter((payment: { customerNumber: number; itemsQuantity: number }) => payment.customerNumber !== posPayment.customerNumber),
                };
            });
        },

        setRefreshRestaurantZones: (state: PosState, { payload: refreshRestaurantZones }: { payload: any }) => {
            state.refreshRestaurantZones = refreshRestaurantZones;
        },

        setPromoCodeSelected: (state: PosState, { payload: { promoCode } }: { payload: { promoCode: PromoCodeVm } }) => {
            state.promoCode = promoCode;
        },

        clearPromoCodeSelected: (state: PosState) => {
            state.promoCode = undefined;
        },

        addPromoCode: (state: PosState, { payload: promoCode }: { payload: PromoCodeVm }) => {
            state.promoCode = promoCode;
            refreshPosPaymentState(state);
        },

        setCustomerId: (state: PosState, { payload: customerId }: { payload: CustomerId }) => {
            state.customerId = customerId;
        },

        applyCustomerCredits: (state: PosState, { payload: { useLetsEatCredits, usedCustomerCredits } }: { payload: { useLetsEatCredits: boolean; usedCustomerCredits: string } }) => {
            state.useLetsEatCredits = useLetsEatCredits;
            state.usedCustomerCredits = usedCustomerCredits;

            refreshPosPaymentState(state);
        },

        removeCustomerCredits: (state: PosState) => {
            state.useLetsEatCredits = false;
            state.usedCustomerCredits = '';

            refreshPosPaymentState(state);
        },

        openMockPaymentTerminalResponseDialog: (
            state: PosState,
            { payload: { onSelectPaymentTerminalResponse, onCloseDialog } }: { payload: { onSelectPaymentTerminalResponse: any; onCloseDialog: () => Record<any, any> } },
        ) => {
            state.mockPaymentTerminalResponseDialog.open = true;
            state.mockPaymentTerminalResponseDialog.onSelectPaymentTerminalResponse = onSelectPaymentTerminalResponse;
            state.mockPaymentTerminalResponseDialog.onCloseDialog = onCloseDialog;
        },

        closeMockPaymentTerminalResponseDialog: (state: PosState) => {
            state.mockPaymentTerminalResponseDialog.open = false;
            state.mockPaymentTerminalResponseDialog.onSelectPaymentTerminalResponse = undefined;
            state.mockPaymentTerminalResponseDialog.onCloseDialog = () => {};
        },

        openAddMenuItemNoteDialogState: (state: PosState, { payload: { onSuccess } }: { payload: { onSuccess: any } }) => {
            state.addMenuItemNoteDialogState.open = true;
            state.addMenuItemNoteDialogState.onSuccess = onSuccess;
        },

        closeAddMenuItemNoteDialog: (state: PosState) => {
            state.addMenuItemNoteDialogState.open = false;
            state.addMenuItemNoteDialogState.onSuccess = undefined;
        },

        openChangeMenuItemNoteDialogState: (state: PosState, { payload: { onSuccess, note } }: { payload: { onSuccess: any; note: string } }) => {
            state.changeMenuItemNoteDialogState.open = true;
            state.changeMenuItemNoteDialogState.note = note;
            state.changeMenuItemNoteDialogState.onSuccess = onSuccess;
        },

        closeChangeMenuItemNoteDialog: (state: PosState) => {
            state.changeMenuItemNoteDialogState.open = false;
            state.changeMenuItemNoteDialogState.note = '';
            state.changeMenuItemNoteDialogState.onSuccess = undefined;
        },

        setCustomerName: (state: PosState, { payload: customerName }: { payload: { firstName: string; lastName: string } }) => {
            state.customerName = customerName;
        },

        setEmptyCustomers: (state: PosState, { payload: numberOfCustomers }: { payload: number }) => {
            if (numberOfCustomers === 1) return;

            state.customers = Array.from({ length: numberOfCustomers }, (value, index) => ({
                customerNumber: index + 1,
                orderItems: [],
                cancelledItems: [],
                promotions: state.promotions,
            }));

            state.customerNumberSelected = 1;
        },

        setCustomerNumberSelected: (state: PosState, { payload: customerNumber }: { payload: number }) => {
            state.customerNumberSelected = customerNumber;
        },

        clearCustomerNumberSelected: (state: PosState) => {
            state.customerNumberSelected = undefined;
        },

        setNumberOfCustomers: (state: PosState, { payload: numberOfCustomers }: { payload: number }) => {
            if (state.table?.table) {
                state.table.numberOfCustomers = numberOfCustomers;
            }
            state.numberOfCustomers = numberOfCustomers;
        },

        setCourseIdSelected: (state: PosState, { payload: courseId }: { payload: CourseId }) => {
            state.courseIdSelected = courseId;
        },

        clearCourseIdSelected: (state: PosState) => {
            state.courseIdSelected = undefined;
        },

        addPosCustomerTip: (state: PosState, { payload: customerTip }: { payload: PosTip }) => {
            const { tipType, tipAmount, tipPercentage, paymentMethod, customPaymentMethod, customerNumber, paymentId, restaurantCurrency, restaurantCurrencyAmount } = customerTip;
            const customer = state.customers?.find((customer: PosCustomerOrderDetails) => customer.customerNumber === customerNumber);

            if (!customer) return;

            customer.tips = [...(customer.tips ?? []), { tipType, tipAmount, tipPercentage, paymentMethod, customPaymentMethod, paymentId, restaurantCurrency, restaurantCurrencyAmount }];

            refreshPosPaymentCustomerState({ customer, payments: state.payments, promotions: state.promotions });

            syncPaymentFromCustomerPayment(state);
        },

        removePosCustomerTip: (
            state: PosState,
            {
                payload: { tip, customerNumber },
            }: { payload: { tip: { tipType: TipType; tip: string; tipPercentage: string; paymentMethod: PaymentMethod; customPaymentMethod?: CustomPaymentMethod }; customerNumber: number } },
        ) => {
            const customer = state.customers?.find((customer: PosCustomerOrderDetails) => customer.customerNumber === customerNumber);

            if (!customer || !state.tips) return;

            const currentTip = state.tips.find((tipItem) => tipItem.paymentMethod === tip.paymentMethod && tipItem.customPaymentMethod === tip.customPaymentMethod);

            if (!currentTip) return;

            if (!currentTip.tipPercentage && state.tips?.length === 1) {
                state.tips = [];
            }

            customer.tips = customer.tips?.filter((tipItem: PosTip) => !(tipItem.paymentMethod === tip.paymentMethod && tipItem.customPaymentMethod === tip.customPaymentMethod));

            refreshPosPaymentCustomerState({ customer, payments: state.payments, promotions: state.promotions });

            syncPaymentFromCustomerPayment(state);
        },

        addPosCustomerDiscount: (
            state: PosState,
            {
                payload: { discount, discountType, notes, customerNumber, discountPercentage },
            }: { payload: { discountType: DiscountType; discount: string; notes?: string; customerNumber: number; discountPercentage?: string } },
        ) => {
            const customer = state.customers?.find((customer: PosCustomerOrderDetails) => customer.customerNumber === customerNumber);

            if (!customer) return;

            customer.discount = { discountType, discount, notes, discountPercentage };

            refreshPosPaymentCustomerState({ customer, payments: state.payments, promotions: state.promotions });

            syncPaymentFromCustomerPayment(state);
        },

        removePosCustomerDiscount: (state: PosState, { payload: { customerNumber } }: { payload: { posDiscount: string; customerNumber: number } }) => {
            const customer = state.customers?.find((customer: PosCustomerOrderDetails) => customer.customerNumber === customerNumber);

            if (!customer) return;

            if (!state.discount) return;

            customer.discount = undefined;
            refreshPosPaymentCustomerState({ customer, payments: state.payments, promotions: state.promotions });

            syncPaymentFromCustomerPayment(state);
        },

        setNumericPadValue: (state: PosState, { payload: value }: { payload: string }) => {
            state.numericPadValue = value;
        },

        setPayTotalTicketSelected: (state: PosState, { payload: value }: { payload: boolean }) => {
            state.payTotalTicketSelected = value;
        },

        setPosContext: (state: PosState, { payload: context }: { payload: PosContextVm }) => {
            state.context = context;
        },

        setOpenedPosBusinessDay: (state: PosState, { payload: openedPosBusinessDay }: { payload: PosBusinessDayVm }) => {
            state.openedPosBusinessDay = openedPosBusinessDay;
        },

        setOpenedCashRegister: (state: PosState, { payload: cashRegister }: { payload: CashRegisterVm | undefined }) => {
            state.openedCashRegister = cashRegister;
        },

        setOpenedCashRegisterPosBusinessDay: (
            state: PosState,
            { payload: openedCashRegisterPosBusinessDay }: { payload: CashRegisterPosBusinessDaySummary | OpenedCashRegisterPosBusinessDayVm | undefined },
        ) => {
            state.openedCashRegisterPosBusinessDay = openedCashRegisterPosBusinessDay;
        },

        setOpenedCashRegistersPosBusinessDay: (state: PosState, { payload: openedCashRegistersPosBusinessDay }: { payload: Array<OpenedCashRegisterPosBusinessDayVm> | undefined }) => {
            state.openedCashRegistersPosBusinessDay = openedCashRegistersPosBusinessDay;
        },

        openPinCodeUserSignInDialog: (state: PosState, { payload: { onSuccess, onFailed } }: { payload: { onSuccess: any; onFailed: any } }) => {
            state.pinCodeUserSignInDialog.open = true;
            state.pinCodeUserSignInDialog.onSuccess = onSuccess;
            state.pinCodeUserSignInDialog.onFailed = onFailed;
        },

        closePinCodeUserSignInDialog: (state: PosState) => {
            state.pinCodeUserSignInDialog.open = false;
            state.pinCodeUserSignInDialog.onSuccess = undefined;
            state.pinCodeUserSignInDialog.onFailed = undefined;
        },

        setTakeOrderByCourse: (state: PosState, { payload: { open, courses } }: { payload: { open: boolean; courses: Array<CourseVm> } }) => {
            state.takeOrderByCourse = open;
            state.courses = courses;
        },

        setCourseOrderDetailsAccordionOpened: (state: PosState, { payload: courseOrderDetailsAccordionOpened }: { payload: boolean }) => {
            state.courseOrderDetailsAccordionOpened = courseOrderDetailsAccordionOpened;
        },
        setOpenCashRegisterDialogState: (
            state: PosState,
            { payload: { open, initialAmount, onSuccess, avoidSkip } }: { payload: { open: boolean; initialAmount?: string; onSuccess?: any; avoidSkip?: boolean } },
        ) => {
            state.openCashRegisterDialogState.open = open;
            state.openCashRegisterDialogState.avoidSkip = avoidSkip;
            state.openCashRegisterDialogState.initialAmount = initialAmount;
            state.openCashRegisterDialogState.onSuccess = onSuccess;
        },

        setSelectOpenedCashRegisterDialogState: (state: PosState, { payload: { open } }: { payload: { open: boolean } }) => {
            state.selectOpenedCashRegisterDialogState.open = open;
        },

        clearCashRegisterBusinessDay: (state: PosState) => {
            state.openedPosBusinessDay = undefined;
            state.openedCashRegisterPosBusinessDay = undefined;
            state.openedCashRegister = undefined;
        },

        setSelectedMenuCategory: (state: PosState, { payload: selectedMenuCategory }: { payload: MenuCategoryVm }) => {
            state.selectedMenuCategory = selectedMenuCategory;
        },

        setSelectedMenuCategoryFamilyId: (state: PosState, { payload: selectedMenuCategoryFamilyId }: { payload: MenuCategoryFamilyId | undefined }) => {
            state.selectedMenuCategoryFamilyId = selectedMenuCategoryFamilyId;
            state.shouldShowMenuCategoriesWithoutMenuCategoryFamily = false;
        },

        setShouldShowMenuCategoriesWithoutMenuCategoryFamily: (state: PosState, { payload: shouldShowMenuCategoriesWithoutMenuCategoryFamily }: { payload: boolean }) => {
            state.selectedMenuCategoryFamilyId = undefined;
            state.shouldShowMenuCategoriesWithoutMenuCategoryFamily = shouldShowMenuCategoriesWithoutMenuCategoryFamily;
        },

        setSelectedMenu: (state: PosState, { payload: selectedMenu }: { payload: MenuVm }) => {
            state.selectedMenu = selectedMenu;
        },

        setSelectedCurrency: (state: PosState, { payload: selectedCurrency }: { payload: RestaurantCurrency }) => {
            state.selectedCurrency = selectedCurrency;
        },

        setOpenMobilePaymentResume: (state: PosState, { payload: isOpen }: { payload: boolean }) => {
            state.openMobilePaymentResume = isOpen;
        },

        setPickupTimeType: (state: PosState, { payload: pickupTimeType }: { payload: PickupTimeType }) => {
            state.pickupTimeType = pickupTimeType;
        },

        setPickupTime: (state: PosState, { payload: pickupTime }: { payload: Date }) => {
            state.pickupTime = pickupTime;
        },

        setPaymentMethod: (state: PosState, { payload: { paymentMethod, customPaymentMethod } }: { payload: { paymentMethod: PaymentMethod; customPaymentMethod: CustomPaymentMethod } }) => {
            state.paymentMethod = paymentMethod;
            state.customPaymentMethod = customPaymentMethod;
            refreshPosPaymentState(state);
        },
    },
});

export type PosState = {
    orderStep: PosOrderStep;
    customerDisplayScreenOrderStep?: CustomerDisplayScreenOrderStep;
    orderType?: OrderType;
    country?: Country;
    posMenu?: PosMenuVm;
    itemSelected: MenuItemVm | undefined;
    selectedMenuCategory: MenuCategoryVm | undefined;
    selectedMenuCategoryFamilyId: MenuCategoryFamilyId | undefined;
    selectedMenu: MenuVm | undefined;
    shouldShowMenuCategoriesWithoutMenuCategoryFamily?: boolean;
    itemSelectedQuantity?: number;
    itemSelectedMenuCategoryId?: MenuCategoryId;
    items: Array<CartItemVm>;
    cancelledItems: Array<CartItemVm>;
    promotions: Array<PromotionVm>;
    numericPadValue: string;
    itemsToPayByCustomer: Array<
        {
            key?: string;
        } & CartItemVm
    >;
    payment?: {
        subtotal: string;
        productDiscount?: string;
        promotionsDiscount?: string;
        usedPromotions?: Array<{
            promotionId: PromotionId;
            menuItemId: MenuItemId;
            cartItemKey?: string;
        }>;
        posDiscount?: string;
        tips?: Array<PosTipVm>;
        total: string;
        deliveryCashHandlingFee?: string;
        promoCodeSubtotal?: string;
        promoCodeDiscount?: string;
        promoCodeCredits?: string;
        promoCode?: string;
    };
    selectedCurrency?: RestaurantCurrency;
    openCashRegisterDialogState: {
        open: boolean;
        initialAmount?: string;
        onSuccess?: any;
        avoidSkip?: boolean;
    };
    selectOpenedCashRegisterDialogState: {
        open: boolean;
    };
    customers?: Array<PosCustomerOrderDetails>;
    customerNumberSelected?: number;
    payments: Array<PosPayment>;
    paymentMethod?: PaymentMethod;
    customPaymentMethod?: CustomPaymentMethod;
    pendingOrder?: OrderVm;
    driverRequest?: PosDriverRequest;
    cashRegister?: CashRegisterVm;
    openedCashRegister?: CashRegisterVm;
    openedCashRegisterPosBusinessDay?: CashRegisterPosBusinessDaySummary | OpenedCashRegisterPosBusinessDayVm;
    openedCashRegistersPosBusinessDay?: Array<OpenedCashRegisterPosBusinessDayVm>;
    cashRegisterOpened?: boolean;
    restaurantZone?: RestaurantZoneVm;
    isEndOfDayReport: boolean;
    /** @deprecated // TODO: Move state to paymentTerminalState */
    paying: boolean;
    /** @deprecated // TODO: Move state to paymentTerminalState */
    refunding: boolean;
    table?: {
        restaurantZoneId: RestaurantZoneId;
        table: string;
        numberOfCustomers?: number;
    };
    numberOfCustomers?: number;
    notes?: string;
    orderOpenedFromTable: boolean;
    disabledPosPaymentActions: boolean;
    discount?: {
        discountType: DiscountType;
        discount: string;
        discountPercentage?: string;
        notes?: string;
    };
    tips?: Array<PosTip>;
    totalPaid: string;
    createTableDialog: {
        open: boolean;
        restaurantZoneId?: RestaurantZoneId;
        onSuccess?: any;
    };
    createRestaurantZoneDialog: {
        open: boolean;
        onSuccess?: any;
    };
    changeRestaurantZoneDialog: {
        open: boolean;
        restaurantZoneId?: RestaurantZoneId;
        onSuccess?: any;
    };
    removeRestaurantZoneDialog: {
        open: boolean;
        restaurantZoneId?: RestaurantZoneId;
        onSuccess?: any;
    };
    openPosBusinessDayDialogState: {
        open: boolean;
        onSuccess?: any;
    };
    restaurantTablesHasChanged: boolean;
    openMobilePaymentResume: boolean;
    refreshRestaurantZones?: any;
    customerId?: CustomerId;
    promoCode?: PromoCodeVm;
    useLetsEatCredits: boolean;
    usedCustomerCredits: string;
    mockPaymentTerminalResponseDialog: {
        open: boolean;
        onSelectPaymentTerminalResponse: any;
        onCloseDialog: any;
    };
    addMenuItemNoteDialogState: {
        open: boolean;
        onSuccess?: any;
    };
    changeMenuItemNoteDialogState: {
        open: boolean;
        onSuccess?: any;
        note: string;
    };
    pinCodeUserSignInDialog: {
        open: boolean;
        onSuccess?: any;
        onFailed?: any;
    };
    customerName?: {
        firstName: string;
        lastName: string;
    };
    payTotalTicketSelected?: boolean;
    context?: PosContextVm;
    openedPosBusinessDay?: PosBusinessDayVm;
    takeOrderByCourse?: boolean;
    ongoingCourses?: Array<PosCourseOrderDetails>;
    courseIdSelected?: CourseId;
    courses?: Array<CourseVm>;
    courseOrderDetailsAccordionOpened?: boolean;
    pickupTimeType: PickupTimeType;
    pickupTime?: Date;
};

type Actions = {
    setPosOrderStep: any;
    setCustomerDisplayScreenOrderStep: any;
    selectPosOrderType: any;
    setPosMenu: any;
    resetPosMenu: any;
    closePosMenu: any;
    setPosItems: any;
    addPosItem: any;
    removePosItem: any;
    selectPosItem: any;
    closeAddMenuItem: any;
    payOrder: any;
    editPendingOrder: any;
    clearPos: any;
    setPosDriverRequest: any;
    openCashRegisterReport: any;
    closeCashRegisterReport: any;
    setPosTable: any;
    addPosDiscount: any;
    removePosDiscount: any;
    addPosTip: any;
    removePosTip: any;
    removePosPromoCode: any;
    setRestaurantZone: any;
    closePosPayment: any;
    openCreateRestaurantZoneDialog: any;
    closeCreateRestaurantZoneDialog: any;
    openChangeRestaurantZoneDialog: any;
    closeChangeRestaurantZoneDialog: any;
    openCreateTableDialog: any;
    closeCreateTableDialog: any;
    openRemoveRestaurantZoneDialog: any;
    closeRemoveRestaurantZoneDialog: any;
    showPosTableSelect: any;
    setCashRegisterOpen: any;
    openPosBusinessDayDialog: any;
    closePosBusinessDayDialog: any;
    setDisabledPosPaymentActions: any;
    setPayments: any;
    refreshPosPaymentCustomer: any;
    setTotalPaid: any;
    /** @deprecated // TODO: Move state to paymentTerminalState */
    setPaying: any;
    /** @deprecated // TODO: Move state to paymentTerminalState */
    setRefunding: any;
    setNotes: any;
    changeRestaurantTablesAndZones: any;
    setPendingOrder: any;
    removeItemFromItemsToPayByCustomer: any;
    addItemToItemsToPayCustomer: any;
    clearPayByPartsState: any;
    setItemsPaidByParts: any;
    setItems: any;
    removeItemsPaidByCustomer: any;
    setRefreshRestaurantZones: any;
    setPromoCodeSelected: any;
    clearPromoCodeSelected: any;
    addPromoCode: any;
    setCustomerId: any;
    applyCustomerCredits: any;
    removeCustomerCredits: any;
    openMockPaymentTerminalResponseDialog: any;
    closeMockPaymentTerminalResponseDialog: any;
    openAddMenuItemNoteDialogState: any;
    openChangeMenuItemNoteDialogState: any;
    closeAddMenuItemNoteDialog: any;
    closeChangeMenuItemNoteDialog: any;
    setCustomerName: any;
    addPosMenuItemNote: any;
    setEmptyCustomers: any;
    addPosCustomerTip: any;
    addPosCustomerDiscount: any;
    setCustomerNumberSelected: any;
    setCourseIdSelected: any;
    setOngoingCourses: any;
    clearCourseIdSelected: any;
    setPayTotalTicketSelected: any;
    setNumberOfCustomers: any;
    setNumericPadValue: any;
    clearCustomerNumberSelected: any;
    removePosCustomerTip: any;
    removePosCustomerDiscount: any;
    setPosContext: any;
    setOpenedPosBusinessDay: any;
    setOpenedCashRegistersPosBusinessDay: any;
    setOpenedCashRegister: any;
    setOpenedCashRegisterPosBusinessDay: any;
    openPinCodeUserSignInDialog: any;
    closePinCodeUserSignInDialog: any;
    setTakeOrderByCourse: any;
    setCourseOrderDetailsAccordionOpened: any;
    setOpenCashRegisterDialogState: any;
    setSelectOpenedCashRegisterDialogState: any;
    clearCashRegisterBusinessDay: any;
    setSelectedMenuCategory: any;
    setSelectedMenuCategoryFamilyId: any;
    setShouldShowMenuCategoriesWithoutMenuCategoryFamily: any;
    setOpenMobilePaymentResume: any;
    setSelectedMenu: any;
    setSelectedCurrency: any;
    setPickupTimeType: any;
    setPickupTime: any;
    setPaymentMethod: any;
};

export type PosDriverRequest = {
    restaurantId: RestaurantId;
    name: string;
    firstName?: string;
    lastName?: string;
    phoneNumber: string;
    customerNote?: string;
    email?: EmailAddress;
    address: {
        location: GeoJsonPoint;
        street: string;
        number: string;
        neighborhood: string;
        postalCode?: string;
        other?: string;
        instructions?: string;
        googlePlaceId: GooglePlaceId;
        formattedAddress?: string;
        name?: string; // sent for debugging reasons
    };
    price: string;
    deliveryCost?: string;
    deliveryEstimateId: DeliveryEstimateId;
    deliveryPaidByRestaurant?: boolean;
    deliveryPaidByPideDirecto?: boolean;
    posDriverRequest?: boolean;
    externalDelivery?: boolean;
    pickupTimeType?: PickupTimeType;
    pickupTime?: Date;
    appVersion?: string;
};
