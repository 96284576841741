import { useEffect, useState } from 'react';
import { ScreenSizes } from 'src/constants/ScreenSize';

export function useIsLargeScreen(): boolean {
    const [isLargeScreen, setIsLargeScreen] = useState(window.innerWidth >= ScreenSizes.LARGE_SCREEN);

    useEffect(() => {
        window.addEventListener('resize', handleResize);
        return () => {
            return window.removeEventListener('resize', handleResize);
        };
    }, []);

    const handleResize = () => {
        setIsLargeScreen(window.innerWidth >= ScreenSizes.LARGE_SCREEN);
    };

    return isLargeScreen;
}
