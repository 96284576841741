import { Button, makeStyles } from '@material-ui/core';
import * as React from 'react';
import { translate } from 'src/i18n/translate';
import checkIcon from 'src/images/checkIconGreen.png';
import pideDirectoLogo from 'src/images/pidedirecto-logo.png';
import { useFormatAsRestaurantCurrencyNumber } from 'src/services/restaurant/useFormatAsRestaurantCurrencyNumber';

export function BasicRegularPlanCard({ basicRegularPaymentAmount, onSelectPlan }: Props): React.ReactElement {
    const classes = useStyles();
    const formatAsCurrencyNumber = useFormatAsRestaurantCurrencyNumber();

    return (
        <div className={classes.container}>
            <div className={classes.row}>
                <img src={pideDirectoLogo} className={classes.logo} />
                <h1 className={classes.name}>{translate('Basic Regular')}</h1>
            </div>
            <p className={classes.description}>{translate('Our drivers team are ready to realize your deliveres with no limits.')}</p>
            <>
                <div className={classes.row} style={{ marginTop: 10 }}>
                    <span className={classes.price}>{formatAsCurrencyNumber(basicRegularPaymentAmount)}</span>
                    <span className={classes.priceSecondary}>{translate('/ month')}</span>
                </div>
                <div className={classes.featuresContainer}>
                    <div className={classes.row}>
                        <img src={checkIcon} className={classes.checkIcon} />
                        <span className={classes.description}>{translate('Thousands of drivers in +25 cities')}</span>
                    </div>
                    <div className={classes.row}>
                        <img src={checkIcon} className={classes.checkIcon} />
                        <span className={classes.description}>{translate('24/7 support')}</span>
                    </div>
                    <div className={classes.row}>
                        <img src={checkIcon} className={classes.checkIcon} />
                        <span className={classes.description}>{translate('Refund for lost orders')}</span>
                    </div>
                </div>
            </>
            <Button classes={{ root: classes.button }} onClick={onSelectPlan}>
                {translate('Select plan')}
            </Button>
        </div>
    );
}

const useStyles = makeStyles((theme) => ({
    container: {
        border: `1px solid ${theme.palette.secondary.dark}`,
        display: 'flex',
        flexDirection: 'column',
        backgroundColor: 'white',
        width: '100%',
        boxShadow: '5px 5px 5px rgba(0, 0, 0, 0.25)',
        minHeight: 200,
        borderRadius: 15,
        padding: 20,
        paddingTop: 30,
    },
    row: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
    },
    logo: {
        width: 20,
        marginRight: 10,
    },
    name: {
        fontFamily: theme.typography.medium,
        fontSize: 20,
    },
    description: {
        fontFamily: theme.typography.regular,
        marginTop: 0,
    },
    price: {
        fontFamily: theme.typography.bold,
        fontSize: 25,
        marginRight: 5,
    },
    priceSecondary: {
        fontFamily: theme.typography.regular,
        fontSize: 15,
    },
    featuresContainer: {
        width: '100%',
        display: 'grid',
        gridTemplateColumns: 'repeat(2, 1fr)',
        rowGap: 10,
        marginTop: 10,
    },
    button: {
        backgroundColor: theme.palette.primary.main,
        color: 'white',
        fontFamily: theme.typography.semiBold,
        fontSize: 16,
        borderRadius: 8,
        height: 45,
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        textTransform: 'none',
        marginTop: 30,
    },
    checkIcon: {
        margin: '0 10px',
        width: 15,
    },
}));

type Props = {
    basicRegularPaymentAmount?: string;
    onSelectPlan: any;
};
