import { letseatmanagerApiMethod } from 'src/api/letseatmanagerApi';
import type { ApiSauceResponse } from 'src/api/types/ApiSauceResponse';
import type { DeviceGroupVm } from 'src/types/DeviceGroupVm';
import type { DeviceId, PaymentTerminalId, PrinterId, RestaurantId } from 'src/types/Id';

export async function createDeviceGroupApi(request: CreateDeviceGroupApiRequest): ApiSauceResponse<DeviceGroupVm> {
    return letseatmanagerApiMethod('deviceGroup/createDeviceGroupApi', request);
}

export type CreateDeviceGroupApiRequest = {
    restaurantId: RestaurantId;
    deviceGroupName: string;
    deviceIds?: Array<DeviceId>;
    printerIds?: Array<PrinterId>;
    paymentTerminalIds?: Array<PaymentTerminalId>;
};
