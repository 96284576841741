import { makeStyles } from '@material-ui/core';
import PhoneIcon from '@material-ui/icons/Phone';
import { useState } from 'react';
import * as React from 'react';
import { AppIcon } from 'src/components/icon/AppIcon';
import { OrderCardAcceptNewOrderButton } from 'src/components/orderCard/orderCardActions/OrderCardAcceptNewOrderButton';
import { OrderCardRejectNewOrderButton } from 'src/components/orderCard/orderCardActions/OrderCardRejectNewOrderButton';
import { OrderCardAdditionalInfo } from 'src/components/orderCard/OrderCardAdditionalInfo';
import { OrderCardDetailsInfo } from 'src/components/orderCard/OrderCardDetailsInfo';
import { OrderCardGeneralInfo } from 'src/components/orderCard/OrderCardGeneralInfo';
import { OrderCardIdsInfo } from 'src/components/orderCard/OrderCardIdsInfo';
import { OrderCardPaymentInfo } from 'src/components/orderCard/OrderCardPaymentInfo';
import { OrderCardStatusInfo } from 'src/components/orderCard/OrderCardStatusInfo';
import { Apps } from 'src/constants/App';
import type { OrderVm } from 'src/types/OrderVm';
import { isNewOrder } from 'src/utils/order/isNewOrder';
import { classNames } from 'src/utils/react/classNames';

export function OrderCard({ order, disabled, onChange, onError, classes: classesProp }: Props): React.ReactElement {
    const classes = useStyles();

    const [loading, setLoading] = useState(false);

    const updateLoadingOrder = (status: any) => setLoading(status);

    return (
        <div className={classNames(classes.container, classesProp?.container)}>
            <div className={classes.customerContainer}>
                <div className={classes.logoContainer}>
                    <AppIcon app={order.app} width={50} height={50} />
                </div>
                <h1 className={classes.customerName}>
                    {order.manualOrder && <PhoneIcon style={{ fontSize: 17, marginRight: 5 }} />}
                    {order.customerName || (order.app === Apps.PIDEDIRECTOPOS ? 'POS' : '')}
                    {order.didiFoodOrderIndex && ` #${order.didiFoodOrderIndex}`}
                </h1>
            </div>
            <OrderCardGeneralInfo order={order} />
            <OrderCardStatusInfo order={order} disabled={disabled || loading} updateLoadingOrder={updateLoadingOrder} onChange={onChange} onError={onError} />
            <OrderCardIdsInfo order={order} />
            <OrderCardDetailsInfo order={order} onChange={onChange} />
            <OrderCardPaymentInfo order={order} />
            <OrderCardAdditionalInfo order={order} />
            <div className={classes.buttonsContainer}>
                {isNewOrder(order.orderStatus) && <OrderCardRejectNewOrderButton order={order} disabled={loading} onChange={onChange} />}
                <OrderCardAcceptNewOrderButton order={order} updateLoadingOrder={updateLoadingOrder} disabled={loading} onChange={onChange} />
            </div>
        </div>
    );
}

const useStyles = makeStyles((theme) => ({
    container: {
        width: '100%',
        maxWidth: '20vw',
        minWidth: 280,
        border: '1px solid #D9D9D9',
        borderRadius: 12,
        padding: 24,
        boxSizing: 'content-box',
        height: 'fit-content',
        [theme.breakpoints.down('sm')]: {
            padding: 10,
            boxSizing: 'border-box',
        },
    },
    customerContainer: {
        display: 'flex',
        width: '100%',
        flexDirection: 'row',
        alignItems: 'center',
        gap: 12,
        marginBottom: 10,
    },
    logoContainer: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexShrink: 0,
    },
    customerName: {
        fontFamily: theme.typography.semiBold,
        color: '#0D3037',
        fontSize: 22,
        width: '100%',
        padding: 0,
        margin: 0,
        lineHeight: 1.2,
        textAlign: 'left',
    },
    separator: {
        height: '0px',
        width: '100%',
        border: '1px solid #F3F3F3',
        margin: '12px 0px',
    },
    buttonsContainer: {
        width: '100%',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-around',
        marginTop: 10,
        gap: 10,
    },
}));

type Props = {
    order: OrderVm;
    classes?: {
        container?: string;
    };
    disabled?: boolean;
    onChange?: any;
    onError?: any;
};
