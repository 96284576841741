import { Grid, makeStyles } from '@material-ui/core';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { changeModifierGroupApi } from 'src/api/letseatmanager/modifierGroup/changeModifierGroupApi';
import { getModifierGroupApi } from 'src/api/letseatmanager/modifierGroup/getModifierGroupApi';
import { Button } from 'src/components/Button';
import { Form } from 'src/components/form/Form';
import { FormCheckbox } from 'src/components/form/FormCheckbox';
import { FormNumberField } from 'src/components/form/FormNumberField';
import { FormSwitch } from 'src/components/form/FormSwitch';
import { FormTextField } from 'src/components/form/FormTextField';
import { WebSocketEventTypes } from 'src/constants/WebSocketEventType';
import { translate } from 'src/i18n/translate';
import { SelectModifiersDialog } from 'src/scenes/letseatmanager/menu/modifierGroup/SelectModifiersDialog';
import type { ModifierGroupId } from 'src/types/Id';
import { ModifierVm } from 'src/types/ModifierVm';
import type { WebSocketEvent } from 'src/types/WebSocketEvent';
import { alertKnownErrorOrSomethingWentWrong } from 'src/utils/alert/alertKnownErrorOrSomethingWentWrong';
import { useLoadApi } from 'src/utils/react/useLoadApi';
import { WebSocketEvents } from 'src/utils/webSocket/WebSocketEvents';

export function ChangeModifierGroup({ modifierGroupId, onClose, onSuccess }: Props): React.ReactElement {
    const form = useForm();
    const classes = useStyles();

    const [selectModifiersDialogState, setSelectModifiersDialogState] = useState<{ open: boolean; defaultSelectedModifiers: Array<ModifierVm> | undefined }>({
        open: false,
        defaultSelectedModifiers: undefined,
    });
    const [selectedModifiers, setSelectedModifiers] = useState<Array<ModifierVm>>([]);
    const [loading, setLoading] = useState(false);

    const [loadingModifierGroup, modifierGroup, load] = useLoadApi(getModifierGroupApi, { modifierGroupId: modifierGroupId! }, { requiredValues: [modifierGroupId], dependencies: [modifierGroupId] });

    const thereIsModifiers = selectedModifiers && selectedModifiers?.length > 0;

    useEffect(() => {
        const menuModifierGroupChangedSyncWebSocketEvent = WebSocketEvents.addEventListener(WebSocketEventTypes.MODIFIER_GROUP_CHANGED, syncMenuItemExternalChanges);
        const menuModifierGroupRemovedSyncWebSocketEvent = WebSocketEvents.addEventListener(WebSocketEventTypes.MODIFIER_GROUP_REMOVED, syncMenuItemExternalChanges);

        return () => {
            menuModifierGroupChangedSyncWebSocketEvent.remove();
            menuModifierGroupRemovedSyncWebSocketEvent.remove();
        };
    }, []);

    useEffect(() => {
        if (modifierGroup) {
            form.reset({
                name: modifierGroup.name,
                externalModifierGroupId: modifierGroup.externalModifierGroupId || null,
                requiredMin: modifierGroup.requiredMin || null,
                requiredMax: modifierGroup.requiredMax || null,
                freeModifiersQuantity: modifierGroup.freeModifiersQuantity || null,
                hidden: modifierGroup.hidden,
                showModifierGroupNameInCommand: modifierGroup.showModifierGroupNameInCommand,
            });
            setSelectedModifiers(modifierGroup.modifiers);
        }
    }, [modifierGroup]);

    const openSelectModifiersDialog = () => setSelectModifiersDialogState({ open: true, defaultSelectedModifiers: modifierGroup.modifiers });

    const closeSelectModifiersDialog = () => setSelectModifiersDialogState({ open: false, defaultSelectedModifiers: undefined });

    const handleClose = () => {
        if (loading) return;
        onClose();
    };

    const onSubmit = async (form: any) => {
        if (!modifierGroupId) return;

        setLoading(true);
        const response = await changeModifierGroupApi({
            modifierGroupId: modifierGroupId,
            name: form.name,
            externalModifierGroupId: form.externalModifierGroupId,
            requiredMin: form.requiredMin,
            requiredMax: form.requiredMax,
            freeModifiersQuantity: form.freeModifiersQuantity,
            hidden: form.hidden,
            showModifierGroupNameInCommand: form.showModifierGroupNameInCommand,
            modifiers: selectedModifiers,
        });
        setLoading(false);
        if (!response.ok) return alertKnownErrorOrSomethingWentWrong(response);
        onSuccess?.();
        handleClose();
    };

    const handleSelectModifiers = (modifiers: any) => setSelectedModifiers(modifiers);

    const syncMenuItemExternalChanges = (
        event: WebSocketEvent<{
            modifierGroupId: ModifierGroupId;
        }>,
    ) => {
        if (event.data?.modifierGroupId === modifierGroupId && event.webSocketEventType === WebSocketEventTypes.MODIFIER_GROUP_REMOVED) onClose?.();
        if (event.data?.modifierGroupId === modifierGroupId && event.webSocketEventType === WebSocketEventTypes.MODIFIER_GROUP_CHANGED) load();
    };

    return (
        <>
            <SelectModifiersDialog
                open={selectModifiersDialogState.open}
                defaultSelectedModifiers={selectModifiersDialogState.defaultSelectedModifiers}
                onClose={closeSelectModifiersDialog}
                onSuccess={handleSelectModifiers}
            />
            <Form form={form} onSubmit={onSubmit}>
                <h2 className={classes.title}>{modifierGroup?.name}</h2>
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <FormTextField name='name' label={translate('Name')} required disabled={loadingModifierGroup || loading} />
                    </Grid>
                    <Grid item xs={12}>
                        <FormTextField name='externalModifierGroupId' label={translate('External modifier group id')} disabled={loadingModifierGroup || loading} />
                    </Grid>
                    <Grid item xs={12}>
                        <FormNumberField name='freeModifiersQuantity' label={translate('Free modifiers quantity')} disabled={loadingModifierGroup || loading} />
                    </Grid>
                    <Grid item xs={12}>
                        <div className={classes.requiredInputsContainer}>
                            <FormNumberField name='requiredMin' label={translate('Minimum required')} disabled={loadingModifierGroup || loading} />
                            <FormNumberField name='requiredMax' label={translate('Maximum required')} disabled={loadingModifierGroup || loading} />
                        </div>
                        <span className={classes.helperText}>{translate('For integrations, add maximum required if the modifiers in this group are of type multiple')}</span>
                    </Grid>
                    <Grid item xs={12} md={6}></Grid>
                    <Grid item xs={12}>
                        <FormCheckbox name={'showModifierGroupNameInCommand'} label={translate('Print modifier group name on command')} />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <FormSwitch name='hidden' label={translate('Hidden')} disabled={loadingModifierGroup || loading} />
                    </Grid>
                </Grid>
                <div className={classes.buttonsContainer}>
                    <Button onClick={handleClose} secondary disabled={loading || loadingModifierGroup}>
                        {translate('Cancel')}
                    </Button>
                    <Button type='submit' disabled={loading || loadingModifierGroup}>
                        {loading ? translate('Saving') : translate('Save')}
                    </Button>
                </div>
            </Form>
        </>
    );
}

const useStyles = makeStyles((theme) => ({
    title: {
        color: theme.palette.text.brand,
        textAlign: 'center',
        fontFamily: theme.typography.bold,
    },
    text: {
        fontFamily: theme.typography.regular,
        fontSize: 15,
        margin: 0,
    },
    modifiersContainer: {
        margin: '10px 0',
        display: 'flex',
        width: '100%',
        flexDirection: 'row',
        flexWrap: 'wrap',
    },
    buttonsContainer: {
        marginTop: 20,
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'flex-end',
        gap: 20,
        '& button': {
            width: 200,
        },
    },
    requiredInputsContainer: {
        width: '100%',
        display: 'grid',
        gridTemplateColumns: '1fr 1fr',
        gap: 20,
        [theme.breakpoints.down('md')]: {
            gridTemplateColumns: '1fr',
        },
    },
    helperText: {
        fontFamily: theme.typography.light,
        color: theme.palette.text.secondary,
        fontSize: '1.2rem',
    },
}));

type Props = {
    modifierGroupId?: ModifierGroupId;
    onClose: any;
    onSuccess?: any;
};
