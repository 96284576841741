import { FormSelect } from '@pidedirecto/ui/form';
import * as React from 'react';
import { MexicanPaymentMethodCodes } from 'src/constants/MexicanPaymentMethodCode';
import { translate } from 'src/i18n/translate';

export function FormMexicanPaymentMethodCodeSelect({ name, disabled, label, required, defaultValue }: Props): React.ReactElement {
    const mexicanPaymentMethodCodes = Object.keys(MexicanPaymentMethodCodes);
    const mexicanPaymentMethodsCode = Object.values(MexicanPaymentMethodCodes);

    const options = required
        ? mexicanPaymentMethodCodes.map((mexicanPaymentMethodCode: string) => ({
              label: translate(`MexicanPaymentMethodCodes.${mexicanPaymentMethodCode}`),
              value: mexicanPaymentMethodsCode[mexicanPaymentMethodCodes.indexOf(mexicanPaymentMethodCode)],
          }))
        : [
              { label: '', value: null },
              ...mexicanPaymentMethodCodes.map((mexicanPaymentMethodCode: string) => ({
                  label: translate(`MexicanPaymentMethodCodes.${mexicanPaymentMethodCode}`),
                  value: mexicanPaymentMethodsCode[mexicanPaymentMethodCodes.indexOf(mexicanPaymentMethodCode)],
              })),
          ];

    return (
        <FormSelect
            label={label}
            name={name}
            disabled={disabled}
            options={options}
            required={{
                value: !!required,
                message: translate('This field is required'),
            }}
            defaultValue={defaultValue}
        />
    );
}

type Props = {
    name: string;
    label?: string;
    disabled?: boolean;
    required?: boolean;
    defaultValue?: string;
};
