import { makeStyles } from '@material-ui/core';
import Badge from '@material-ui/core/Badge';
import { useTheme } from '@material-ui/core/styles';
import * as React from 'react';
import { useLocation } from 'react-router-dom';
import { Text } from 'src/components/Text';
import { OrderStatuses } from 'src/constants/OrderStatus';
import { RoutePaths } from 'src/constants/RoutePath';
import { translate } from 'src/i18n/translate';
import { BellIcon } from 'src/icons/BellIcon';
import { OrderVm } from 'src/types/OrderVm';
import { useSelector } from 'src/utils/react/useSelector';

export function AppBarTitle(): React.ReactElement {
    const classes = useStyles();
    const location = useLocation();
    const theme = useTheme();

    const title = useSelector((state) => state.app2.title);
    const alertStoresClosed = useSelector((state) => state.app2.alertStoresClosed);
    const userType = useSelector((state) => state.authentication.userType);
    const currentOrders = useSelector((state) => state.app2.currentOrders);
    const restaurantName = useSelector((state) => state.app.restaurant.name);
    const brandOpened = useSelector((state) => state.app.brandOpened);

    const integrationsLocation = location.pathname === RoutePaths.INTEGRATIONS;
    const ongoingOrdersLocation = location.pathname === RoutePaths.ONGOING_ORDERS;
    const newOrders = currentOrders?.filter((order: OrderVm) => order.orderStatus === OrderStatuses.NEW).length;

    if (newOrders > 0 && ongoingOrdersLocation) {
        return (
            <Badge badgeContent={newOrders} classes={{ badge: classes.badge }} data-testid='appBar.newOrdersBadge'>
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                    <div style={{ display: 'flex', gap: 8, alignItems: 'center' }}>
                        <Text style={{ fontSize: 20 }} variant='semiBold'>
                            {title}
                        </Text>
                        {alertStoresClosed && integrationsLocation && <BellIcon color={theme.palette.text.brand} title={translate('Integrations issue')} />}
                    </div>
                    <Text style={{ fontSize: 12, color: theme.palette.text.secondary }}>{brandOpened ? translate('Brand') : restaurantName}</Text>
                </div>
            </Badge>
        );
    }

    return (
        <div style={{ display: 'flex', flexDirection: 'column' }}>
            <div style={{ display: 'flex', gap: 8, alignItems: 'center' }}>
                <Text style={{ fontSize: 20 }} variant='semiBold'>
                    {title}
                </Text>
                {alertStoresClosed && integrationsLocation && <BellIcon color={theme.palette.text.brand} title={translate('Integrations issue')} />}
            </div>
            <Text style={{ fontSize: 12, color: theme.palette.text.secondary }}>{brandOpened ? translate('Brand') : restaurantName}</Text>
        </div>
    );
}

const useStyles = makeStyles((theme) => ({
    badge: {
        backgroundColor: theme.palette.primary.main,
        marginTop: 2,
        color: 'white',
    },
}));
