import { letseatmanagerApiMethod } from 'src/api/letseatmanagerApi';
import type { ApiSauceResponse } from 'src/api/types/ApiSauceResponse';
import type { PromoCodeId } from 'src/types/Id';

export async function removePromoCodeApi(request: RemovePromoCodeApiRequest): ApiSauceResponse<void> {
    return letseatmanagerApiMethod('promoCode/removePromoCodeApi', request);
}

export type RemovePromoCodeApiRequest = {
    promoCodeId: PromoCodeId;
};
