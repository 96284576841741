import IconButton from '@material-ui/core/IconButton';
import { withStyles } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';
import RefreshIcon from '@material-ui/icons/Refresh';
import MUIDataTable from 'mui-datatables';
import * as React from 'react';
import { UpdatingContentProgress } from 'src/components/UpdatingContentProgress';
import type { GiftEmitter } from 'src/constants/GiftEmitter';
import { translate } from 'src/i18n/translate';
import type { BenefitPlanId, CustomerId, GiftId, OrderId, PromoCodeId } from 'src/types/Id';
import { formatDateString } from 'src/utils/date/formatDateString';
import { formatDateTimeString } from 'src/utils/date/formatDateTimeString';
import { applyColumns } from 'src/utils/table/applyColumns';

export default withStyles((theme) => ({
    table: {
        whiteSpace: 'nowrap',
        '& tr': { cursor: 'pointer' },
        '& tr:hover': { backgroundColor: 'rgba(0, 0, 0, 0.07) !important' },
    },
    toolbar: {
        paddingRight: theme.spacing(3),
    },
}))(
    class CompanyGiftsTable extends React.PureComponent<Props> {
        render() {
            const { classes } = this.props;
            return (
                <div style={{ position: 'relative' }}>
                    <UpdatingContentProgress loading={this.props.loading} />
                    <MUIDataTable
                        /* @ts-ignore */
                        className={classes.table}
                        title={this.props.title || translate('Gifts')}
                        data={this.props.gifts.map((gift) =>
                            applyColumns(this.props.columns, {
                                giftId: gift.giftId,
                                day: formatDateString(gift.validFrom),
                                usedCredits: gift.usedCredits || '',
                                credits: gift.credits,
                                employee: gift.employeeName || '',
                                company: gift.companyName || '',
                                benefitPlan: gift.benefitPlanName,
                                removed: gift.removed ? translate('Removed') : '',
                                createdAt: formatDateTimeString(gift.createdAt),
                                modifiedAt: formatDateTimeString(gift.modifiedAt),
                            }),
                        )}
                        columns={applyColumns(this.props.columns, [
                            {
                                name: 'giftId',
                                label: ' ',
                                options: {
                                    display: 'excluded',
                                    filter: false,
                                },
                            },
                            {
                                name: 'day',
                                label: translate('Day'),
                                options: {
                                    filter: false,
                                    searchable: false,
                                },
                            },
                            {
                                name: 'usedCredits',
                                label: translate('Used Credits'),
                                options: {
                                    filter: false,
                                    searchable: false,
                                },
                            },
                            {
                                name: 'credits',
                                label: translate('Credits'),
                                options: {
                                    filter: false,
                                    searchable: false,
                                },
                            },
                            {
                                name: 'employee',
                                label: translate('Employee'),
                                options: {
                                    filter: false,
                                    searchable: true,
                                },
                            },
                            {
                                name: 'company',
                                label: translate('Company'),
                                options: {
                                    filter: true,
                                    searchable: true,
                                },
                            },
                            {
                                name: 'benefitPlan',
                                label: translate('Benefit Plan'),
                                options: {
                                    filter: true,
                                    searchable: true,
                                },
                            },
                            {
                                name: 'hours',
                                label: translate('Hours'),
                                options: {
                                    filter: false,
                                    searchable: false,
                                },
                            },
                            {
                                name: 'removed',
                                label: translate('Removed'),
                                options: {
                                    filter: true,
                                    searchable: false,
                                },
                            },
                            {
                                name: 'createdAt',
                                label: translate('Created At'),
                                options: {
                                    filter: false,
                                    searchable: false,
                                },
                            },
                            {
                                name: 'modifiedAt',
                                label: translate('Modified At'),
                                options: {
                                    filter: false,
                                    searchable: false,
                                },
                            },
                        ])}
                        options={{
                            responsive: 'standard',
                            tableBodyMaxHeight: '500px',
                            selectableRows: 'none',
                            // setRowProps: (row) => {
                            //     return {
                            //         style: { backgroundColor: getGiftStatusColor(row[1], row[12]) },
                            //     };
                            // },
                            // onRowClick: (rowData: Array<string>, rowMeta: { dataIndex: number, rowIndex: number }) => {
                            //     history.push({
                            //         pathname: RoutePath.ADMIN_CUSTOMER.replace(`:customerId(${RegExps.uuid})`, rowData[0]),
                            //         search: history.location.search,
                            //     });
                            // },
                            filterType: 'multiselect',
                            rowsPerPage: 100,
                            customToolbar: () => {
                                return (
                                    <>
                                        <Tooltip title='Refresh'>
                                            <IconButton onClick={this.props.onClickRefresh}>
                                                <RefreshIcon />
                                            </IconButton>
                                        </Tooltip>
                                    </>
                                );
                            },
                            // filterList: [[], [], [], [], ['MEXICO']],
                        }}
                    />
                </div>
            );
        }
    },
) as React.ComponentType<any>;

type Props = {
    loading: boolean;
    title?: string;
    columns?: Array<string>;
} & GiftsVm & {
        onClickRefresh: any;
        classes: any;
    };

export type GiftsVm = {
    gifts: Array<GiftVm>;
};

export type GiftVm = {
    giftId: GiftId;
    customerId: CustomerId;
    employeeName?: string;
    giftEmitter: GiftEmitter;
    credits: string;
    usedCredits?: string;
    orderIds?: Array<OrderId>;
    benefitPlanId?: BenefitPlanId;
    benefitPlanName?: string;
    companyName?: string;
    promoCodeId?: PromoCodeId;
    promoCode?: string;
    hours?: string;
    validFrom?: Date;
    validTo?: Date;
    used?: boolean;
    removed?: boolean;
    createdAt: Date;
    modifiedAt: Date;
};
