import Grid from '@material-ui/core/Grid';
import * as React from 'react';
import { useFormContext } from 'react-hook-form';
import { FormSwitch } from 'src/components/form/FormSwitch';
import { SectionTitle } from 'src/components/title/SectionTitle';
import { CommandSections } from 'src/constants/CommandSection';
import { translate } from 'src/i18n/translate';

export function CommandSalesInfo({ disabled }: Props): React.ReactElement {
    const form = useFormContext();
    const increaseCommandFontSizeEnabled = form.watch('increaseCommandFontSizeEnabled');

    return (
        <section>
            <SectionTitle title={translate('Sales Information')} />
            {/* @ts-ignore */}
            <Grid item xs={12} columnSpacing={2}>
                <FormSwitch disabled={disabled} name={CommandSections.SALES_INFO} label={translate(`CommandSections.${CommandSections.SALES_INFO}`)} />
            </Grid>
            {/* @ts-ignore */}
            <Grid item xs={12} columnSpacing={2}>
                <FormSwitch
                    disabled={disabled || increaseCommandFontSizeEnabled}
                    name={CommandSections.ITEMS_COST}
                    label={translate(`CommandSections.${CommandSections.ITEMS_COST}`)}
                    tooltip={translate('If you have Increase font size active, the prices will not be printed on command')}
                />
                <FormSwitch
                    disabled={disabled}
                    name={CommandSections.SPLIT_GROUPED_ORDER_ITEMS}
                    label={translate(`CommandSections.${CommandSections.SPLIT_GROUPED_ORDER_ITEMS}`)}
                    tooltip={translate('Each item in your order will be printed individually.')}
                />
            </Grid>
            {/* @ts-ignore */}
            <Grid item xs={12} md={6} columnSpacing={2}>
                <FormSwitch disabled={disabled} name={CommandSections.TOTAL_COST} label={translate(`CommandSections.${CommandSections.TOTAL_COST}`)} />
            </Grid>
        </section>
    );
}

type Props = {
    disabled: boolean;
};
