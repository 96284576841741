import { letseatadminApiMethod } from 'src/api/letseatadminApi';
import type { ApiSauceResponse } from 'src/api/types/ApiSauceResponse';
import type { RestaurantVideoId } from 'src/types/Id';

export async function removeRestaurantVideoApi(request: RemoveRestaurantVideoApiRequest): ApiSauceResponse<void> {
    return letseatadminApiMethod('restaurantVideo/removeRestaurantVideoApi', request);
}

export type RemoveRestaurantVideoApiRequest = {
    restaurantVideoId: RestaurantVideoId;
};
