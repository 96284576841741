import { makeStyles } from '@material-ui/core';
import * as React from 'react';
import type { UserType } from 'src/constants/UserType';
import { useSelector } from 'src/utils/react/useSelector';

export function InventoryKpi({ kpi, label, userTypes }: Props): React.ReactElement | null {
    const classes = useStyles();

    const userType = useSelector((state) => state.authentication.userType);

    if (userTypes?.every((_userType: UserType) => _userType !== userType)) return null;

    return (
        <div className={classes.container}>
            <span className={classes.kpi}>{kpi ?? ''}</span>
            <p className={classes.label}>{label}</p>
        </div>
    );
}

const useStyles = makeStyles((theme) => ({
    container: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        width: '100%',
        height: 100,
        border: `1px solid #ccc`,
        borderRadius: '8px',
        padding: '4px',
    },
    kpi: {
        fontFamily: theme.typography.medium,
        fontSize: 18,
    },
    label: {
        fontFamily: theme.typography.regular,
        fontSize: 15,
        textAlign: 'center',
        margin: 0,
    },
}));

type Props = {
    kpi: string | number | undefined;
    label: string;
    userTypes?: Array<UserType>;
};
