import { makeStyles } from '@material-ui/core';
import * as React from 'react';
import { useEffect } from 'react';
import { useContext } from 'react';
import { useForm, useWatch } from 'react-hook-form';
import { Button } from 'src/components/Button';
import { Form } from 'src/components/form/Form';
import { FormTextField } from 'src/components/form/FormTextField';
import { CreatePrinterSteps } from 'src/constants/CreatePrinterStep';
import { translate } from 'src/i18n/translate';
import { PrinterWizardContext, PrinterWizardProviderValues } from 'src/providers/PrinterWizardProvider';

export function DeviceName(): React.ReactElement | null {
    const classes = useStyles();
    const { formValues, goNextWizardStep, goBackWizardStep, setCreatePrinterWizardFormValue, activeCreatePrinterStep } = useContext<PrinterWizardProviderValues>(PrinterWizardContext);

    const form = useForm();
    const { control } = form;

    const deviceName = useWatch({ name: 'deviceName', control });

    const disabled = !deviceName || deviceName === '';

    useEffect(() => {
        document.addEventListener('keydown', handleKeyDown);
        return () => {
            document.removeEventListener('keydown', handleKeyDown);
        };
    }, []);

    const handleChangeDeviceName = (deviceName: any) => {
        setCreatePrinterWizardFormValue('deviceName', deviceName);
        goNextWizardStep();
    };

    const handleKeyDown = (event: KeyboardEvent) => {
        if (event.key === 'Enter' && !disabled) {
            handleChangeDeviceName(deviceName);
        }
    };

    const handleSubmit = async (form: any) => {
        handleChangeDeviceName(form.deviceName);
    };

    if (activeCreatePrinterStep !== CreatePrinterSteps.PRINTER_NAME) return null;

    return (
        <Form form={form} onSubmit={handleSubmit} className={classes.form}>
            <FormTextField
                name='deviceName'
                placeholder={translate('Example: Cocina caliente')}
                label={translate('Write your printer name')}
                classes={{ label: classes.label }}
                defaultValue={formValues?.deviceName}
            />
            <div className={classes.actionsContainer}>
                <Button secondary onClick={goBackWizardStep} type={'button'}>
                    {translate('Back')}
                </Button>
                <Button primary type={'submit'} disabled={disabled}>
                    {translate('Next')}
                </Button>
                <p className={classes.enterText}>{translate('hit Enter ↩')}</p>
            </div>
        </Form>
    );
}

const useStyles = makeStyles((theme) => ({
    form: {
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        animation: '$appear 700ms ease',
    },
    label: {
        fontSize: 22,
        fontFamily: theme.typography.regular,
        color: '#0D3037',
        padding: 0,
    },
    actionsContainer: {
        display: 'flex',
        gap: 12,
        marginTop: 24,
        width: '100%',
        justifyContent: 'flex-end',
    },
    enterText: {
        fontFamily: theme.typography.regular,
        color: '#0D3037',
    },
    '@keyframes appear': {
        '0%': {
            opacity: 0,
            transform: 'translateY(100px)',
        },
        '100%': {
            opacity: 1,
            transform: 'translateY(0px)',
        },
    },
}));
