import * as React from 'react';
import { Select } from 'src/components/Select';
import { App, Apps } from 'src/constants/App';
import { translate } from 'src/i18n/translate';

export function AppSelect({ labelAsPlaceholder, multiselectable, onChange }: Props): React.ReactElement {
    const options = Object.values(Apps).map((app: any) => ({ label: translate(`Apps.${app}`), value: app }));

    return <Select label={translate('App')} name={'app'} labelAsPlaceholder={labelAsPlaceholder} multiselectable={multiselectable} options={options} onChange={(apps: Array<App>) => onChange(apps)} />;
}

type Props = {
    labelAsPlaceholder?: boolean;
    multiselectable?: boolean;
    onChange: (apps: Array<App>) => any;
};
