import { makeStyles } from '@material-ui/core/styles';
import * as React from 'react';
import { CashRegisterPosBusinessDayReportCard } from 'src/scenes/letseatmanager/posBusinessDayReport/CashRegisterPosBusinessDayReportCard';
import type { CashRegisterPosBusinessDaySummaryReportVm } from 'src/types/CashRegisterPosBusinessDaySummaryReportVm';

export function CashRegisterPosBusinessDaysReport({ cashRegisterPosBusinessDayReportsSummary }: Props): React.ReactElement | null {
    const classes = useStyles();

    if (!cashRegisterPosBusinessDayReportsSummary?.length) return null;

    return (
        <div className={classes.container}>
            {cashRegisterPosBusinessDayReportsSummary.map((cashRegisterPosBusinessDayReportSummary) => (
                <CashRegisterPosBusinessDayReportCard cashRegisterPosBusinessDayReportSummary={cashRegisterPosBusinessDayReportSummary} />
            ))}
        </div>
    );
}

const useStyles = makeStyles((theme) => ({
    container: {
        display: 'grid',
        margin: '42px 0',
        gridTemplateColumns: '1fr',
        [theme.breakpoints.up('sm')]: {
            gridTemplateColumns: 'repeat(2, 1fr)',
        },
        [theme.breakpoints.up('md')]: {
            gridTemplateColumns: 'repeat(3, 1fr)',
        },
        gap: 24,
    },
}));

type Props = {
    cashRegisterPosBusinessDayReportsSummary: Array<CashRegisterPosBusinessDaySummaryReportVm> | undefined;
};
