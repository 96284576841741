import { makeStyles } from '@material-ui/core';
import * as React from 'react';
import type { DiscountBreakdownReport } from 'src/api/letseatmanager/restaurantDashboard/getPromoCodeRestaurantStatisticsApi';
import { Table } from 'src/components/Table';
import { translate } from 'src/i18n/translate';
import { ReportSection } from 'src/scenes/letseatmanager/restaurantDashboard/ReportSection';
import { useFormatAsRestaurantCurrencyNumber } from 'src/services/restaurant/useFormatAsRestaurantCurrencyNumber';
import type { OrderId } from 'src/types/Id';
import { toShortId } from 'src/utils/uuid/toShortId';

export function DiscountBreakdownReportTable({ loading, discountBreakdownData, fileName }: Props): React.ReactElement | null {
    const classes = useStyles();
    const formatAsCurrencyNumber = useFormatAsRestaurantCurrencyNumber();

    const discountBreakdownReport =
        discountBreakdownData?.map((discountBreakdown: DiscountBreakdownReport) => ({
            orderId: toShortId(discountBreakdown.orderId as OrderId),
            date: discountBreakdown.date,
            clientName: discountBreakdown.clientName,
            subtotal: formatAsCurrencyNumber(discountBreakdown.subtotal),
            discount: formatAsCurrencyNumber(discountBreakdown.discount),
            reason: discountBreakdown.reason,
            discountNote: discountBreakdown.discountNote,
        })) || [];
    const hasDiscountBreakdown = discountBreakdownReport.length > 0;

    const promoCodeColumns = [
        {
            name: 'date',
            label: translate('Date'),
            options: {
                filter: true,
            },
        },

        {
            name: 'clientName',
            label: translate('Client'),
            options: {
                filter: true,
            },
        },
        {
            name: 'orderId',
            label: translate('Ticket'),
            options: {
                filter: true,
            },
        },
        {
            name: 'subtotal',
            label: translate('Consumption'),
            options: {
                filter: true,
            },
        },
        {
            name: 'discount',
            label: translate('Discount'),
            options: {
                filter: true,
            },
        },
        {
            name: 'reason',
            label: translate('Reason'),
            options: {
                filter: true,
            },
        },
        {
            name: 'discountNote',
            label: translate('Comment'),
            options: {
                filter: true,
            },
        },
    ];

    if (!hasDiscountBreakdown) {
        return null;
    }

    return (
        <ReportSection loading={loading}>
            <div className={(classes as any).infoContainer}>
                <div className={classes.container}>
                    <Table
                        title={undefined}
                        data={discountBreakdownReport}
                        columns={promoCodeColumns}
                        options={{
                            tableBodyMaxHeight: '400px',
                            responsive: 'standard',
                            selectableRows: 'none',
                            filter: true,
                            rowsPerPage: 100,
                            viewColumns: false,
                            print: true,

                            downloadOptions: {
                                filename: fileName,
                            },
                        }}
                    />
                </div>
            </div>
        </ReportSection>
    );
}

const useStyles = makeStyles((theme) => ({
    container: {
        width: '100%',
    },
    graphContainer: {
        width: '100%',
        height: '45vh',
    },
    kpisContainer: {
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap',
        width: '95%',
        height: '30%',
        justifyContent: 'space-around',
        [theme.breakpoints.between('sm', 'md')]: {
            width: '100%',
            justifyContent: 'center',
        },
        [theme.breakpoints.up('md')]: {
            flexDirection: 'column',
            justifyContent: 'center',
            flexWrap: 'nowrap',
        },
    },
}));

type Props = {
    loading: boolean;
    discountBreakdownData: Array<DiscountBreakdownReport>;
    fileName: string;
};
