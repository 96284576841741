import { sendOrderUpdateToCustomerDisplayScreenApi } from 'src/api/letseatmanager/pos/sendOrderUpdateToCustomerDisplayScreenApi';
import { CustomerDisplayScreenOrderStep } from 'src/constants/CustomerDisplayScreenOrderStep';
import { alertKnownErrorOrSomethingWentWrong } from 'src/utils/alert/alertKnownErrorOrSomethingWentWrong';
import { mapCartItemToCustomerDisplayScreenCartItem } from 'src/utils/pos/mapCartItemToCustomerDisplayScreenCartItem';
import { useSelector } from 'src/utils/react/useSelector';

export function useSendOrderUpdateToCustomerDisplayScreen(): (orderStep?: CustomerDisplayScreenOrderStep | undefined) => Promise<void> {
    const items = useSelector((state) => state.pos.items);
    const tips = useSelector((state) => state.pos.tips);
    const usedCustomerCredits = useSelector((state) => state.pos.usedCustomerCredits);
    const useLetsEatCredits = useSelector((state) => state.pos.useLetsEatCredits);
    const payment = useSelector((state) => state.pos.payment);
    const customerDisplayScreenEnabled = useSelector((state) => state.app.restaurant?.customerDisplayScreenEnabled);
    const restaurantId = useSelector((state) => state.app.restaurantId);

    const sendOrderUpdateToCustomerDisplayScreen = async (orderStep: CustomerDisplayScreenOrderStep | undefined) => {
        if (!customerDisplayScreenEnabled) return;

        const response = await sendOrderUpdateToCustomerDisplayScreenApi({
            restaurantId,
            orderItems: items?.map(mapCartItemToCustomerDisplayScreenCartItem),
            subtotal: payment?.subtotal,
            total: payment?.total,
            productDiscount: payment?.productDiscount,
            posDiscount: payment?.posDiscount,
            promoCodeSubtotal: payment?.promoCodeSubtotal,
            promoCodeDiscount: payment?.promoCodeDiscount,
            promoCodeCredits: payment?.promoCodeCredits,
            promoCode: payment?.promoCode,
            usedCustomerCredits: useLetsEatCredits ? usedCustomerCredits : undefined,
            tips,
            orderStep,
        });

        if (!response.ok) {
            alertKnownErrorOrSomethingWentWrong(response);
            return;
        }
    };

    return sendOrderUpdateToCustomerDisplayScreen;
}
