import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Grid from '@material-ui/core/Grid';
import Hidden from '@material-ui/core/Hidden';
import IconButton from '@material-ui/core/IconButton';
import RadioGroup from '@material-ui/core/RadioGroup';
import { withStyles } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import EditIcon from '@material-ui/icons/Edit';
import RefreshIcon from '@material-ui/icons/Refresh';
import * as React from 'react';
import { withRouter } from 'react-router-dom';
import { letseatmanagerApiDeprecated } from 'src/api/letseatmanagerApiDeprecated';
import Checkbox from 'src/components/deprecated/react-final-form/Checkbox';
import { Field, Form } from 'src/components/deprecated/react-final-form/Form';
import Radio from 'src/components/deprecated/react-final-form/Radio';
import TextField from 'src/components/deprecated/react-final-form/TextField';
import { UpdatingContentProgress } from 'src/components/UpdatingContentProgress';
import { history } from 'src/config/history';
import { RoutePaths } from 'src/constants/RoutePath';
import { translate } from 'src/i18n/translate';
import { CHANGE_BENEFIT_PLAN, ChangeBenefitPlanDialog } from 'src/scenes/letseatadmin/company/ChangeBenefitPlanDialog';
import type { BenefitPlanId, CompanyId } from 'src/types/Id';
import { alertKnownErrorOrSomethingWentWrong } from 'src/utils/alert/alertKnownErrorOrSomethingWentWrong';
import { formatDateTimeString } from 'src/utils/date/formatDateTimeString';
import { translateDaysString } from 'src/utils/day/translateDaysString';
import { setUrlQueryParameter } from 'src/utils/history/setUrlQueryParameter';
import { RegExps } from 'src/utils/RegExps';

export const BenefitPlanDetails: React.ComponentType<any> = withRouter<any, any>(
    withStyles((theme) => ({
        linearProgress: {
            position: 'absolute',
            width: '100%',
            bottom: 0,
        },
        root: {
            paddingLeft: 8,
            paddingRight: 8,
        },
    }))(
        class extends React.PureComponent<Props, State> {
            state: any = INITIAL_STATE;

            async componentDidMount(): Promise<void> {
                await this.load();
            }

            load = async () => {
                this.setState({ loading: true });
                const response = await letseatmanagerApiDeprecated.admin.getBenefitPlan({ benefitPlanId: this.props.benefitPlanId });
                this.setState({ loading: false });
                if (!response.ok) {
                    alertKnownErrorOrSomethingWentWrong(response);
                    return;
                }
                const benefitPlan: BenefitPlanVm = response.data;

                if (this.state.benefitPlanDetails !== benefitPlan) {
                    this.props.onBenefitPlanChange && this.props.onBenefitPlanChange(benefitPlan);
                }

                this.setState({
                    benefitPlanDetails: {
                        benefitPlanId: benefitPlan.benefitPlanId,
                        companyId: benefitPlan.companyId,
                        name: benefitPlan.name,
                        credits: benefitPlan.credits,
                        distributeOncePerWeek: benefitPlan.distributeOncePerWeek,
                        restrictedToDelivery: benefitPlan.restrictedToDelivery,
                        oneProductPerDay: benefitPlan.oneProductPerDay,
                        meals: defaultMeals(benefitPlan),
                        days: benefitPlan.days,
                        daysTranslated: translateDaysString(benefitPlan.days),
                        hours: benefitPlan.hours || translate('24/7'),
                        paused: benefitPlan.paused ? 'true' : 'false',
                        createdAt: formatDateTimeString(benefitPlan.createdAt),
                        modifiedAt: formatDateTimeString(benefitPlan.modifiedAt),
                    },
                });
            };

            handleClickEdit = () => {
                history.push({
                    search: setUrlQueryParameter(CHANGE_BENEFIT_PLAN, this.props.benefitPlanId),
                });
            };

            handleBenefitPlanChanged = async (deleted: any) => {
                if (deleted) {
                    if (!this.state.benefitPlanDetails) {
                        return;
                    }
                    history.replace({
                        pathname: RoutePaths.ADMIN_COMPANY.replace(`:companyId(${RegExps.uuid})`, this.state.benefitPlanDetails.companyId),
                        search: history.location.search,
                    });
                    return;
                }
                await this.load();
            };

            render() {
                const { classes } = this.props;
                return (
                    <div style={{ position: 'relative' }}>
                        <ChangeBenefitPlanDialog onBenefitPlanChanged={this.handleBenefitPlanChanged} />
                        <UpdatingContentProgress loading={this.state.loading} />
                        <Card classes={{ root: classes.root }}>
                            <CardHeader
                                title={
                                    <Typography display='block' gutterBottom variant='h6'>
                                        {translate('Benefit Plan Details')}
                                    </Typography>
                                }
                                action={
                                    <div>
                                        <Tooltip title='Refresh'>
                                            <IconButton onClick={this.load}>
                                                <RefreshIcon />
                                            </IconButton>
                                        </Tooltip>
                                        <Tooltip title='Change'>
                                            <IconButton onClick={this.handleClickEdit}>
                                                <EditIcon />
                                            </IconButton>
                                        </Tooltip>
                                    </div>
                                }
                            />
                            <CardContent>{this.renderContent()}</CardContent>
                        </Card>
                    </div>
                );
            }

            onSubmit = (form: any) => {};

            renderContent() {
                return (
                    <>
                        <Form
                            onSubmit={this.onSubmit}
                            initialValues={{
                                benefitPlan: this.state.benefitPlanDetails || { days: [] },
                            }}
                            render={({ handleSubmit, submitting, pristine, values }) => (
                                <Grid container spacing={3}>
                                    <Grid item xs={12} sm={6}>
                                        <Field name='benefitPlan.name' component={TextField} label={translate('Name')} fullWidth disabled />
                                    </Grid>
                                    {/* @ts-ignore */}
                                    <Hidden xsDown>
                                        {/* @ts-ignore */}
                                        <Grid item xs={'none'} sm={6}></Grid>
                                    </Hidden>
                                    <Grid item xs={12} sm={6}>
                                        <Field
                                            name='benefitPlan.credits'
                                            component={TextField}
                                            label={translate('Credits')}
                                            helperText={`${translate('Amount of credits that the employee can order with')}`}
                                            fullWidth
                                            disabled
                                        />
                                    </Grid>
                                    {this.state.benefitPlanDetails && this.state.benefitPlanDetails.restrictedToDelivery && (
                                        <Grid item xs={12} sm={3}>
                                            <FormControlLabel
                                                label={translate('Restrict Credits to Delivery')}
                                                control={<Field name='benefitPlan.restrictedToDelivery' component={Checkbox} type='checkbox' color='primary' disabled />}
                                            />
                                        </Grid>
                                    )}
                                    {this.state.benefitPlanDetails && this.state.benefitPlanDetails.distributeOncePerWeek && (
                                        <Grid item xs={12} sm={3}>
                                            <FormControlLabel
                                                label={translate('Receive all weekly credits on Saturday')}
                                                control={<Field name='benefitPlan.distributeOncePerWeek' component={Checkbox} type='checkbox' color='primary' disabled />}
                                            />
                                        </Grid>
                                    )}
                                    {this.state.benefitPlanDetails && this.state.benefitPlanDetails.oneProductPerDay && (
                                        <Grid item xs={12} sm={3}>
                                            <FormControlLabel
                                                label={translate('Allow only one product per day')}
                                                control={<Field name='benefitPlan.oneProductPerDay' component={Checkbox} type='checkbox' color='primary' disabled />}
                                            />
                                        </Grid>
                                    )}
                                    {(!this.state.benefitPlanDetails || !this.state.benefitPlanDetails.restrictedToDelivery) && (
                                        <>
                                            {/* @ts-ignore */}
                                            <Hidden xsDown>
                                                {/* @ts-ignore */}
                                                <Grid item xs={'none'} sm={6}></Grid>
                                            </Hidden>
                                        </>
                                    )}
                                    <Grid item xs={12} sm={6}>
                                        <Field
                                            name='benefitPlan.meals'
                                            component={TextField}
                                            label={translate('Meals')}
                                            helperText={`${translate('Max number of meals per week')}`}
                                            placeholder={`${(values as any).benefitPlan.days.length}`}
                                            fullWidth
                                            disabled
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <Field
                                            name='benefitPlan.daysTranslated'
                                            component={TextField}
                                            label={translate('Days')}
                                            helperText={`${translate('Days of week when the employee can order')}`}
                                            fullWidth
                                            disabled
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <Field
                                            name='benefitPlan.hours'
                                            component={TextField}
                                            label={translate('Hours')}
                                            helperText={`${translate('Time of day when the employee can order')}`}
                                            fullWidth
                                            disabled
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <RadioGroup row>
                                            <FormControlLabel label='Active' control={<Field name='benefitPlan.paused' component={Radio} type='radio' color='primary' value='false' disabled />} />
                                            <FormControlLabel label='Paused' control={<Field name='benefitPlan.paused' component={Radio} type='radio' color='primary' value='true' disabled />} />
                                        </RadioGroup>
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <Field name='benefitPlan.createdAt' component={TextField} label={translate('Created')} fullWidth disabled />
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <Field name='benefitPlan.modifiedAt' component={TextField} label={translate('Modified')} fullWidth disabled />
                                    </Grid>
                                </Grid>
                            )}
                        />
                    </>
                );
            }
        },
    ),
);

function defaultMeals(benefitPlan?: BenefitPlanVm | null): number {
    if (!benefitPlan) {
        return 0;
    }
    if (!benefitPlan.meals) {
        if (!benefitPlan.days) {
            return 0;
        }
        return benefitPlan.days.split(',').length;
    }
    return benefitPlan.meals;
}

const INITIAL_STATE = {
    loading: false,
} as const;

type Props = {
    benefitPlanId: BenefitPlanId;
    onBenefitPlanChange?: (arg1: BenefitPlanVm) => Record<any, any>;
    classes: any;
};

type State = {
    loading: boolean;
    benefitPlanDetails?: {
        benefitPlanId: BenefitPlanId;
        companyId: CompanyId;
        name: string;
        credits: string;
        distributeOncePerWeek?: boolean;
        restrictedToDelivery?: boolean;
        oneProductPerDay?: boolean;
        meals: number;
        days: string;
        daysTranslated: string;
        hours: string;
        paused: string;
        createdAt: string;
        modifiedAt: string;
    };
};

export type BenefitPlanVm = {
    benefitPlanId: BenefitPlanId;
    companyId: CompanyId;
    name: string;
    credits: string;
    distributeOncePerWeek?: boolean;
    restrictedToDelivery?: boolean;
    oneProductPerDay?: boolean;
    meals?: number;
    days: string;
    hours?: string;
    paused?: boolean;
    createdAt: Date;
    modifiedAt: Date;
};
