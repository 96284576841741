import type { BannerVm } from 'src/api/letseatmanager/banner/findBannersApi';
import { letseatmanagerApiMethod } from 'src/api/letseatmanagerApi';
import type { ApiSauceResponse } from 'src/api/types/ApiSauceResponse';
import type { App } from 'src/constants/App';
import type { RestaurantId } from 'src/types/Id';

export async function createBannerApi(request: CreateBannerApiRequest): ApiSauceResponse<BannerVm> {
    return letseatmanagerApiMethod('banner/createBannerApi', request);
}

export type CreateBannerApiRequest = {
    restaurantId: RestaurantId;
    hours?: string;
    imageUrl: string;
    promoCode?: string;
    enabled: boolean;
    pinned?: boolean;
    channels?: Array<App>;
};
