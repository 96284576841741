import { letseatadminApiMethod } from 'src/api/letseatadminApi';
import type { ApiSauceResponse } from 'src/api/types/ApiSauceResponse';
import type { City } from 'src/constants/City';
import type { SelfRegistrationStatus } from 'src/constants/SelfRegistrationStatus';
import type { EmailAddress, RestaurantId, RestaurantSelfRegistrationId } from 'src/types/Id';

export async function findRestaurantSelfRegistrationsApi(): ApiSauceResponse<Array<RestaurantSelfRegistration>> {
    return letseatadminApiMethod('restaurantSelfRegistration/findRestaurantSelfRegistrationsApi');
}

export type RestaurantSelfRegistration = {
    restaurantSelfRegistrationId: RestaurantSelfRegistrationId;
    restaurantId?: RestaurantId;
    name?: string;
    email?: EmailAddress;
    contact?: string;
    contactNumber?: string;
    phoneNumber?: string;
    selfRegistrationStatus: SelfRegistrationStatus;
    freeTrialDaysLeft?: number;
    countCompleteOrders?: number;
    countRejectedOrders?: number;
    city?: City;
    createdAt: Date;
};
