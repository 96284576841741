import Grid from '@material-ui/core/Grid';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { app2 } from 'src/app2';
import { translate } from 'src/i18n/translate';
import CustomerDetails, { CustomerVm } from 'src/scenes/letseatadmin/customer/CustomerDetails';
import CustomerGifts from 'src/scenes/letseatadmin/customer/CustomerGifts';
import CustomerOrders from 'src/scenes/letseatadmin/customer/CustomerOrders';
import CustomerTransactions from 'src/scenes/letseatadmin/customer/CustomerTransactions';
import type { CustomerId } from 'src/types/Id';
import { fullName } from 'src/utils/customer/fullName';
import { useAction } from 'src/utils/react/useAction';
import { useParams } from 'src/utils/react/useParams';
import { useSelector } from 'src/utils/react/useSelector';

export function CustomerPage(): React.ReactElement {
    const { customerId } = useParams<{
        customerId: CustomerId;
    }>();
    const viewUser = useSelector((state) => state.authentication.viewUser);

    const [customer, setCustomer] = useState<CustomerVm | undefined>(undefined);
    const setTitle = useAction(app2.actions.setTitle);

    useEffect(() => {
        setTitle(`${translate('Customers')} > ${customer ? fullName(customer.firstName, customer.lastName) : customerId.substring(0, 8)}`);
    }, []);

    return (
        <Grid container spacing={5} style={{ paddingBottom: 40 }}>
            <Grid item xs={12}>
                <CustomerDetails customerId={customerId} onChangeCustomer={setCustomer} />
            </Grid>
            <Grid item xs={12}>
                <CustomerOrders customerId={customerId} />
            </Grid>
            <Grid item xs={12}>
                <CustomerGifts customerId={customerId} disabled={viewUser} />
            </Grid>
            <Grid item xs={12}>
                <CustomerTransactions customerId={customerId} />
            </Grid>
        </Grid>
    );
}
