import * as React from 'react';
import { FormSelect } from 'src/components/form/FormSelect';
import type { Variant } from 'src/components/form/FormTypes';
import { DeliveryManagerTypes } from 'src/constants/DeliveryManagerType';
import { translate } from 'src/i18n/translate';

export function FormDeliveryManagerTypeSelect({ name, label, placeholder, helperText, defaultValue, disabled, required, variant, onChange }: Props): React.ReactElement {
    const options = required
        ? Object.values(DeliveryManagerTypes).map((deliveryManagerType) => ({ label: translate(`DeliveryManagerTypes.${deliveryManagerType}`), value: deliveryManagerType }))
        : [
              { label: '', value: null },
              ...Object.values(DeliveryManagerTypes).map((deliveryManagerType: any) => ({ label: translate(`DeliveryManagerTypes.${deliveryManagerType}`), value: deliveryManagerType })),
          ];

    return (
        <FormSelect
            name={name}
            label={label}
            placeholder={placeholder}
            helperText={helperText}
            defaultValue={defaultValue}
            options={options}
            disabled={disabled}
            required={required}
            variant={variant}
            onChange={onChange}
        />
    );
}

type Props = {
    name: string;
    label?: string;
    placeholder?: string;
    helperText?: string;
    defaultValue?: string | null | undefined;
    disabled?: boolean;
    required?:
        | boolean
        | {
              value: number;
              message: string;
          };
    variant?: Variant;
    onChange?: any;
};
