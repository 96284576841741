import { Cities, City } from 'src/constants/City';
import type { GeoJsonPoint } from 'src/types/GeoJsonPoint';
import { getCenterOfCity } from 'src/utils/city/getCenterOfCity';
import { isWithinDistance } from 'src/utils/geojson/isWithinDistance';

const distance = 200;

export function toCity(value?: GeoJsonPoint | null): City | undefined {
    if (!value) return;
    if (isWithinDistance(value, getCenterOfCity(Cities.MONTERREY), distance)) return Cities.MONTERREY;
    if (isWithinDistance(value, getCenterOfCity(Cities.GUADALAJARA), distance)) return Cities.GUADALAJARA;
    if (isWithinDistance(value, getCenterOfCity(Cities.MEXICO_CITY), distance)) return Cities.MEXICO_CITY;
    if (isWithinDistance(value, getCenterOfCity(Cities.PUEBLA), distance)) return Cities.PUEBLA;
    if (isWithinDistance(value, getCenterOfCity(Cities.SALTILLO), distance)) return Cities.SALTILLO;
    if (isWithinDistance(value, getCenterOfCity(Cities.TORREON), distance)) return Cities.TORREON;
    if (isWithinDistance(value, getCenterOfCity(Cities.MERIDA), distance)) return Cities.MERIDA;
    if (isWithinDistance(value, getCenterOfCity(Cities.QUERETARO), distance)) return Cities.QUERETARO;
}
