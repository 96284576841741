import type { RestaurantVm } from 'src/types/RestaurantVm';
import { isMobileApp } from 'src/utils/reactNative/isMobileApp';

export function signedInInMobileApp(restaurant: RestaurantVm) {
    if (isMobileApp()) {
        window.ReactNativeWebView.postMessage(
            JSON.stringify({
                type: 'SIGNED_IN',
                data: restaurant,
            }),
        );
    }
}
