import { makeStyles } from '@material-ui/core/styles';
import * as React from 'react';
import { SecuredAndSubscribedPage } from 'src/components/page/SecuredAndSubscribedPage';
import { Tabs } from 'src/components/Tabs';
import { RolePermissions } from 'src/constants/RolePermission';
import { translate } from 'src/i18n/translate';
import { useSelector } from 'src/utils/react/useSelector';

export function AdvancedReportsPage(): React.ReactElement {
    const classes = useStyles();

    const menuAnalyticsUrl = useSelector((state) => state.app.restaurant?.menuAnalyticsUrl);
    const customersAnalyticsUrl = useSelector((state) => state.app.restaurant?.customersAnalyticsUrl);
    const promoCodesAnalyticsUrl = useSelector((state) => state.app.restaurant?.promoCodesAnalyticsUrl);

    const tabs = [
        {
            label: translate('Menu'),
            value: '1',
            rolePermission: RolePermissions.ADVANCED_REPORTS_MENU,
        },
        {
            label: translate('Customers'),
            value: '2',
            rolePermission: RolePermissions.ADVANCED_REPORTS_CUSTOMERS,
        },
        {
            label: translate('Promo Codes'),
            value: '3',
            rolePermission: RolePermissions.ADVANCED_REPORTS_PROMO_CODES,
        },
    ];

    return (
        <SecuredAndSubscribedPage rolePermission={RolePermissions.ADVANCED_REPORTS_PAGE} title={translate('Advanced Reports')} className={classes.container}>
            <Tabs tabs={tabs}>
                <iframe title={translate('Menu')} src={menuAnalyticsUrl} className={classes.report} frameBorder='0' />
                <iframe title={translate('Customers')} src={customersAnalyticsUrl} className={classes.report} frameBorder='0' />
                <iframe title={translate('Promo Codes')} src={promoCodesAnalyticsUrl} className={classes.report} frameBorder='0' />
            </Tabs>
        </SecuredAndSubscribedPage>
    );
}

const useStyles = makeStyles((theme) => ({
    container: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        width: '100%',
    },
    report: {
        width: '100%',
        height: '100vh',
    },
}));
