import { letseatmanagerApiMethod } from 'src/api/letseatmanagerApi';
import type { ApiSauceResponse } from 'src/api/types/ApiSauceResponse';
import type { SubscriptionPlan } from 'src/constants/SubscriptionPlan';
import type { SubscriptionId } from 'src/types/Id';

export async function changeSubscriptionPlanApi(request: changeSubscriptionPlanApiRequest): ApiSauceResponse<void> {
    return letseatmanagerApiMethod('subscription/changeSubscriptionPlanApi', request);
}

export type changeSubscriptionPlanApiRequest = {
    subscriptionId: SubscriptionId;
    subscriptionPlan: SubscriptionPlan;
};
