import { letseatmanagerApiMethod } from 'src/api/letseatmanagerApi';
import type { ApiSauceResponse } from 'src/api/types/ApiSauceResponse';
import type { App } from 'src/constants/App';
import type { RestaurantId } from 'src/types/Id';

export async function createMenuApi(request: CreateMenuApiRequest): ApiSauceResponse<void> {
    return letseatmanagerApiMethod('menu/createMenuApi', request);
}

export type CreateMenuApiRequest = {
    restaurantId: RestaurantId;
    name: string;
    hours?: string;
    companyName?: string;
    channels?: Array<App>;
};
