import { letseatmanagerApiMethod } from 'src/api/letseatmanagerApi';
import type { ApiSauceResponse } from 'src/api/types/ApiSauceResponse';
import type { DeviceGroupVm } from 'src/types/DeviceGroupVm';
import type { DeviceGroupId } from 'src/types/Id';

export async function getDeviceGroupApi(request: GetDeviceGroupApiRequest): ApiSauceResponse<DeviceGroupVm> {
    return letseatmanagerApiMethod('deviceGroup/getDeviceGroupApi', request);
}

export type GetDeviceGroupApiRequest = {
    deviceGroupId: DeviceGroupId;
};
