import { letseatmanagerApiMethod } from 'src/api/letseatmanagerApi';
import type { ApiSauceResponse } from 'src/api/types/ApiSauceResponse';
import type { UnitOfMeasurement } from 'src/constants/UnitOfMeasurement';
import type { InventoryReportId, RestaurantId, SupplyId } from 'src/types/Id';

export async function createInventoryTransactionReportApi(request: CreateInventoryTransactionReportApiRequest): ApiSauceResponse<InventoryReportVm> {
    return letseatmanagerApiMethod('inventory/createInventoryTransactionReportApi', request);
}

export type CreateInventoryTransactionReportApiRequest = {
    restaurantId: RestaurantId;
    suppliesInventoried: Array<{
        supplyId: SupplyId;
        unitsInventoried: Array<{
            unit: UnitOfMeasurement;
            quantity: string;
            conversionFactor: number;
        }>;
    }>;
    updateInventory?: boolean;
};

type InventoryReportVm = {
    inventoryTransactionReportId: InventoryReportId;
    restaurantId: RestaurantId;
    createdBy: string;
    createdAt: Date;
};
