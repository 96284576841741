import { Auth } from 'aws-amplify';
import { useHistory } from 'react-router-dom';
import { PublicRoutePaths } from 'src/constants/PublicRoutePath';
import { useLogin } from 'src/utils/react/useLogin';

export function useInitApp(): [() => Promise<void>] {
    const [login] = useLogin();
    const history = useHistory();

    const initializeApplication = async () => {
        try {
            const cognitoUser = await Auth.currentAuthenticatedUser();
            await login(cognitoUser.username);
        } catch (e: any) {
            handleRedirectUser();
        }
    };

    const handleRedirectUser = () => {
        history.replace(PublicRoutePaths.HOME);
    };

    return [initializeApplication];
}
