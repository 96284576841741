import { letseatmanagerApiMethod } from 'src/api/letseatmanagerApi';
import type { ApiSauceResponse } from 'src/api/types/ApiSauceResponse';
import type { CustomDiscountComment } from 'src/constants/CustomDiscountComment';
import type { RestaurantId } from 'src/types/Id';

export async function addCustomDiscountCommentApi(request: AddCustomDiscountCommentApiRequest): ApiSauceResponse<void> {
    return letseatmanagerApiMethod('restaurant/addCustomDiscountCommentApi', request);
}

export type AddCustomDiscountCommentApiRequest = {
    restaurantId: RestaurantId;
    comment: CustomDiscountComment;
};
