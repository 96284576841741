import { makeStyles } from '@material-ui/core';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import Grid from '@material-ui/core/Grid';
import { BigNumber } from 'bignumber.js';
import * as React from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { getSupplyApi } from 'src/api/letseatmanager/supply/getSupplyApi';
import { produceCompoundSuppliesApi } from 'src/api/letseatmanager/supply/produceCompoundSuppliesApi';
import { Alert } from 'src/components/Alert';
import { Button } from 'src/components/Button';
import { Dialog } from 'src/components/Dialog';
import { Form } from 'src/components/form/Form';
import { FormTextField } from 'src/components/form/FormTextField';
import { translate } from 'src/i18n/translate';
import { WarningIcon } from 'src/icons/WarningIcon';
import { useFormatAsRestaurantCurrencyNumber } from 'src/services/restaurant/useFormatAsRestaurantCurrencyNumber';
import type { SupplyId } from 'src/types/Id';
import { Ingredient } from 'src/types/RecipeVm';
import { SupplierVm } from 'src/types/SupplierVm';
import { BuyUnitVm } from 'src/types/SupplyVm';
import { alertKnownErrorOrSomethingWentWrong } from 'src/utils/alert/alertKnownErrorOrSomethingWentWrong';
import { useLoadApi } from 'src/utils/react/useLoadApi';
import { useSelector } from 'src/utils/react/useSelector';

export function CreateSupplyProductionDialog({ open, onClose, onSuccess, supplyId }: Props): React.ReactElement {
    const form = useForm();
    const formatAsCurrencyNumber = useFormatAsRestaurantCurrencyNumber();

    const {
        formState: { isSubmitting },
        control,
    } = form;

    const classes = useStyles();

    const [loadingSupply, supply, loadSupply] = useLoadApi(getSupplyApi, { supplyId: supplyId! }, { requiredValues: [supplyId], dependencies: [supplyId] });

    const currencyFormat = useSelector((state) => state.app.restaurant?.currencyFormat);
    const restaurantId = useSelector((state) => state.app.restaurantId);

    const [suppliers, setSuppliers] = useState<Array<SupplierVm>>([]);
    const [ingredients, setIngredients] = useState<Array<Ingredient>>([]);
    const [totalEstimatedCost, setTotalEstimatedCost] = useState<string>('0');
    const [insuficientStock, setInsuficientStock] = useState(false);

    const quantity = form.watch('quantity');

    useEffect(() => {
        let totalEstimated = BigNumber(0);
        ingredients.map((ingredient) => {
            const { buyUnits, fixedCost } = ingredient.supply;

            const mainUnit = buyUnits.find((buyUnit) => buyUnit.isMainUnit);
            const priceUnit = BigNumber(fixedCost).dividedBy(mainUnit?.conversionFactor ?? 1);

            const totalUsed = BigNumber(ingredient.quantity);

            const estimatedPriceOfSupply = BigNumber(priceUnit).multipliedBy(totalUsed).toFixed(2);
            totalEstimated = BigNumber(totalEstimated.plus(estimatedPriceOfSupply));
        });

        if (quantity) {
            const total = new BigNumber(quantity).multipliedBy(totalEstimated);
            setTotalEstimatedCost(total.toNumber() as any);
        }

        let insuficientStock = false;

        ingredients.forEach((ingredient) => {
            const quantityByIngredient = ingredient.quantity;
            const quantityBySupply = ingredient.supply.quantity;

            const totalStockNecessary = BigNumber(quantity).multipliedBy(quantityByIngredient);

            if (Number(quantityBySupply) < Number(totalStockNecessary)) {
                insuficientStock = true;
            }
        });

        if (insuficientStock) {
            setInsuficientStock(true);
        } else {
            setInsuficientStock(false);
        }
    }, [quantity]);

    useEffect(() => {
        if (supply) {
            setIngredients(supply.ingredients!);
            form.setValue('sellUnit', supply.sellUnit);
        }
    }, [supply]);

    const handleClose = () => {
        if (isSubmitting) return;
        setSuppliers([]);
        onClose();
    };

    const handleCloseAlert = () => {
        setInsuficientStock(false);
    };

    const handleSubmit = () => {};

    const onSubmit = async (form: any) => {
        const response = await produceCompoundSuppliesApi({
            compoundSupplies: [
                {
                    supplyId: supply.supplyId,
                    quantity: form.quantity,
                },
            ],
            restaurantId,
        });
        if (!response.ok) {
            alertKnownErrorOrSomethingWentWrong(response);
            return;
        }
        onClose();
        onSuccess();
    };

    return (
        <Dialog open={open} onClose={handleClose} classes={{ dialog: classes.dialogContent }}>
            <Form form={form} onSubmit={onSubmit}>
                <DialogContent style={{ overflowY: 'hidden' }}>
                    <Grid container spacing={6}>
                        <Grid item xs={12} md={12}>
                            <h1 className={classes.title}>{translate('Produce @name', { name: supply?.name })}</h1>
                            <Grid container spacing={2}>
                                <Grid item xs={12} className={classes.alert}>
                                    {insuficientStock && (
                                        <Alert
                                            icon={<WarningIcon title={'campaign'} />}
                                            onClose={handleCloseAlert}
                                            title={translate('Insuficient Stock')}
                                            text={translate('The stock of some supplies is insuficient to produce this supply, but you can continue anyway.')}
                                            actionText={translate('Update')}
                                            onActionClick={handleSubmit}
                                            type={'warning'}
                                        />
                                    )}
                                </Grid>
                                <Grid item xs={6}>
                                    <FormTextField name='quantity' label={translate('Quantity')} required disabled={loadingSupply} />
                                </Grid>
                                <Grid item xs={6}>
                                    <FormTextField name='sellUnit' defaultValue={supply?.sellUnit} label={translate('Unit')} disabled />
                                </Grid>
                            </Grid>
                            <h1 className={classes.subtitle} style={{ marginBottom: 20, marginTop: 50 }}>
                                {translate('Supplies')}
                            </h1>

                            <Grid item xs={12}>
                                <table className={classes.table}>
                                    <tbody>
                                        {ingredients?.map((ingredient: any, index: number) => {
                                            const { buyUnits, fixedCost } = ingredient.supply;

                                            const mainUnit = buyUnits.find((buyUnit: BuyUnitVm) => buyUnit.isMainUnit);
                                            const priceUnit = BigNumber(fixedCost).dividedBy(mainUnit?.conversionFactor ?? 1);

                                            const totalUsed = BigNumber(ingredient.quantity);

                                            const estimatedPriceOfSupply = BigNumber(priceUnit).multipliedBy(totalUsed).toFixed(2);

                                            return (
                                                <tr key={index}>
                                                    <td>
                                                        {ingredient.quantity} {ingredient.supply.sellUnit}
                                                    </td>
                                                    <td>{ingredient.supply.name}</td>
                                                    <td>{formatAsCurrencyNumber(estimatedPriceOfSupply)}</td>
                                                </tr>
                                            );
                                        })}
                                    </tbody>
                                </table>
                            </Grid>
                        </Grid>
                    </Grid>

                    <Grid item xs={12}>
                        <span className={classes.totalPrice}>{translate('Estimated Cost: @totalEstimatedCost', { totalEstimatedCost: `${formatAsCurrencyNumber(totalEstimatedCost)}` })}</span>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button secondary onClick={handleClose} disabled={isSubmitting}>
                        {translate('Cancel')}
                    </Button>
                    <Button color='primary' type='submit' disabled={isSubmitting}>
                        {isSubmitting ? translate('Producing') : translate('Produce')}
                    </Button>
                </DialogActions>
            </Form>
        </Dialog>
    );
}

const useStyles = makeStyles((theme) => ({
    dialogContainer: {
        width: '80%',
        maxWidth: '80%',
        maxHeight: '95%',
        height: 'fit-content',
        position: 'relative',
    },
    title: {
        fontFamily: theme.typography.medium,
        fontSize: 20,
        margin: 0,
    },
    subtitle: {
        fontFamily: theme.typography.medium,
        fontSize: 15,
        marginBottom: 20,
    },
    acceptButton: {
        backgroundColor: theme.palette.primary.main,
        color: 'white',
        fontFamily: theme.typography.semiBold,
        fontSize: 15,
        borderRadius: 5,
        height: 40,
        width: 150,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        textTransform: 'none',
        padding: '16px 32px 16px 32px',
    },
    secondaryButton: {
        backgroundColor: 'white',
        color: theme.palette.primary.main,
        border: `1px solid ${theme.palette.primary.main}`,
        fontFamily: theme.typography.semiBold,
        fontSize: 15,
        borderRadius: 5,
        height: 40,
        width: 150,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        textTransform: 'none',
        padding: '16px 32px 16px 32px',
    },
    addSupplierButton: {
        backgroundColor: 'white',
        color: theme.palette.primary.main,
        border: `1px solid ${theme.palette.primary.main}`,
        fontFamily: theme.typography.semiBold,
        fontSize: 15,
        borderRadius: 5,
        height: 30,
        padding: '5px 15px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        textTransform: 'none',
    },
    supplierContainer: {
        width: '100%',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        padding: '5px 15px',
        borderBottom: `1px solid ${theme.palette.secondary.dark}`,
    },
    supplier: {
        fontFamily: theme.typography.regular,
        fontSize: 15,
    },
    separator: {
        border: `0.1px solid ${theme.palette.secondary.dark}`,
        width: '100%',
        margin: '20px 0',
    },
    alert: {
        width: '100%',
        marginBottom: 20,
    },
    table: {
        width: '100%',
        borderCollapse: 'collapse',
        '& td': {
            border: `0.1px solid ${theme.palette.secondary.dark}`,
            padding: 10,
        },
        '& th': {
            border: `0.1px solid ${theme.palette.secondary.dark}`,
            padding: 10,
        },
    },
    totalPrice: {
        fontFamily: theme.typography.medium,
        fontSize: 15,
        textAlign: 'left',
        width: '100%',
        display: 'block',
        marginTop: 20,
    },
    dialogContent: {
        maxWidth: 1000,
        minWidth: 700,
    },
}));

type Props = {
    open: boolean;
    supplyId?: SupplyId;
    onClose: any;
    onSuccess: any;
};
