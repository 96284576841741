import { letseatmanagerApiMethod } from 'src/api/letseatmanagerApi';
import type { ApiSauceResponse } from 'src/api/types/ApiSauceResponse';
import type { KitchenStatus } from 'src/constants/KitchenStatus';
import type { OrderId } from 'src/types/Id';
import type { OrderItemVm, OrderVm } from 'src/types/OrderVm';

export async function updateOrderItemsKitchenStatusApi(request: updateOrderItemsKitchenStatusApiRequest): ApiSauceResponse<OrderVm> {
    return letseatmanagerApiMethod('kitchen/updateOrderItemsKitchenStatusApi', request);
}

export type updateOrderItemsKitchenStatusApiRequest = {
    orderId: OrderId;
    orderItems: Array<OrderItemVm>;
    kitchenStatus: KitchenStatus;
};
