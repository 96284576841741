import { makeStyles } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import * as React from 'react';
import { SubModifierVm, type SubModifierGroupVm } from 'src/types/OrderVm';

export function MenuItemSubModifierGroups({ subModifierGroups }: Props): React.ReactElement | null {
    const classes = useStyles();

    const subModifiers = subModifierGroups?.reduce((subModifiers: Array<SubModifierVm>, subModifierGroup: SubModifierGroupVm) => {
        if (!subModifierGroup.subModifiers || subModifierGroup.subModifiers.length === 0) {
            return subModifiers;
        }
        subModifiers.push(...subModifierGroup.subModifiers);
        return subModifiers;
    }, []);

    if (!subModifiers?.length) return null;

    const allSubModifiersHasQuantityEqualToOne = subModifiers.every((subModifier) => subModifier.quantity === 1);

    return (
        <>
            {subModifiers.map((subModifier, index) => (
                <Typography key={index} className={classes.modifier}>
                    {`·${subModifier.name} ${allSubModifiersHasQuantityEqualToOne ? '' : 'x' + subModifier.quantity}`}
                </Typography>
            ))}
        </>
    );
}

const useStyles = makeStyles((theme) => ({
    modifier: {
        fontWeight: 400,
        paddingLeft: 14,
        color: '#6C7076',
    },
}));

type Props = {
    subModifierGroups: Array<SubModifierGroupVm>;
};
