import { makeStyles } from '@material-ui/core/styles';
import { Button } from '@pidedirecto/ui';
import * as React from 'react';
import { memo } from 'react';
import { translate } from 'src/i18n/translate';
import { PosUserLockScreenNumericKey } from 'src/scenes/letseatmanager/pos/posUserLockscreen/PosUserLockScreenNumericKey';

export const PosLockScreenNumericPad: React.ComponentType<Props> = memo<Props>(function PosLockScreenNumericPad({ onClickNumber, onDeleteNumber }: Props): React.ReactElement {
    const classes = useStyles();

    return (
        <div className={classes.numpad}>
            <PosUserLockScreenNumericKey value={'1'} onClick={onClickNumber} />
            <PosUserLockScreenNumericKey value={'2'} onClick={onClickNumber} />
            <PosUserLockScreenNumericKey value={'3'} onClick={onClickNumber} />
            <PosUserLockScreenNumericKey value={'4'} onClick={onClickNumber} />
            <PosUserLockScreenNumericKey value={'5'} onClick={onClickNumber} />
            <PosUserLockScreenNumericKey value={'6'} onClick={onClickNumber} />
            <PosUserLockScreenNumericKey value={'7'} onClick={onClickNumber} />
            <PosUserLockScreenNumericKey value={'8'} onClick={onClickNumber} />
            <PosUserLockScreenNumericKey value={'9'} onClick={onClickNumber} />
            <div></div>
            <PosUserLockScreenNumericKey value={'0'} onClick={onClickNumber} />
            <Button variant={'text'} classes={{ button: classes.text }} onClick={onDeleteNumber}>
                {translate('Delete')}
            </Button>
        </div>
    );
});

const useStyles = makeStyles((theme) => ({
    numpad: {
        width: 320,
        display: 'grid',
        gridTemplateColumns: 'repeat(auto-fill, minmax(90px, 1fr))',
        columnGap: 0,
        rowGap: 20,
        justifyItems: 'center',
        [theme.breakpoints.up('md')]: {
            width: 450,
            gridTemplateColumns: 'repeat(auto-fill, minmax(120px, 1fr))',
        },
    },
    text: {
        color: 'white',
    },
}));

type Props = {
    onClickNumber: any;
    onDeleteNumber: any;
};
