import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { letseatmanagerApiDeprecated } from 'src/api/letseatmanagerApiDeprecated';
import { Field, Form } from 'src/components/deprecated/react-final-form/Form';
import TextField from 'src/components/deprecated/react-final-form/TextField';
import { OpenInNewTabToolbarButton } from 'src/components/mui-datatables/OpenInNewTabToolbarButton';
import { RefreshToolbarButton } from 'src/components/mui-datatables/RefreshToolbarButton';
import { UpdatingContentProgress } from 'src/components/UpdatingContentProgress';
import { RoutePaths } from 'src/constants/RoutePath';
import { translate } from 'src/i18n/translate';
import type { RestaurantId } from 'src/types/Id';
import { alertKnownErrorOrSomethingWentWrong } from 'src/utils/alert/alertKnownErrorOrSomethingWentWrong';

export default function RestaurantDetails({ restaurantId, onChangeRestaurant }: Props): React.ReactElement {
    const classes = useStyles();
    const [loading, setLoading] = useState(false);
    const [restaurant, setRestaurant] = useState(undefined as RestaurantVm | undefined);

    useEffect(() => {
        if (!restaurantId) return;
        load();
    }, [restaurantId]);

    const load = async () => {
        if (!restaurantId) {
            return;
        }
        setLoading(true);
        const response = await letseatmanagerApiDeprecated.admin.restaurant({ restaurantId });
        if (!response.ok) {
            setLoading(false);
            alertKnownErrorOrSomethingWentWrong(response);
            return;
        }
        const restaurant: RestaurantVm = response.data;
        setRestaurant(response.data);
        setLoading(false);
        onChangeRestaurant && onChangeRestaurant(restaurant);
    };

    return (
        <div style={{ position: 'relative' }}>
            <UpdatingContentProgress loading={loading} />
            <Card classes={{ root: classes.root }}>
                <CardHeader
                    title={
                        <Typography display='block' gutterBottom variant='h6'>
                            {translate('Restaurant Details')}
                        </Typography>
                    }
                    action={
                        <div>
                            {restaurantId && (
                                <>
                                    <OpenInNewTabToolbarButton onClick={() => window.open(`${RoutePaths.THE_RESTAURANT}?restaurant=${restaurantId}`, '_blank')} />
                                </>
                            )}
                            <RefreshToolbarButton onClick={load} />
                        </div>
                    }
                />
                <CardContent>
                    <Form
                        onSubmit={() => {}}
                        initialValues={
                            restaurant
                                ? {
                                      restaurant: {
                                          ...restaurant,
                                      },
                                  }
                                : {}
                        }
                        render={({ handleSubmit, submitting, pristine, values }) => (
                            <>
                                <Grid container spacing={3}>
                                    <Grid item xs={6} sm={4}>
                                        <Field name='restaurant.name' component={TextField} label={translate('Name')} fullWidth disabled />
                                    </Grid>
                                </Grid>
                            </>
                        )}
                    />
                </CardContent>
            </Card>
        </div>
    );
}

const useStyles = makeStyles((theme) => ({
    linearProgress: {
        position: 'absolute',
        width: '100%',
        bottom: 0,
    },
    root: {
        paddingLeft: 8,
        paddingRight: 8,
    },
}));

type Props = {
    restaurantId?: RestaurantId;
    onChangeRestaurant?: (arg1: RestaurantVm) => any;
};

export type RestaurantVm = {
    restaurantId: RestaurantId;
    name: string;
};
