import { pidedirectoReportsMethod } from 'src/api/pidedirectoReportsApi';
import type { ApiSauceResponse } from 'src/api/types/ApiSauceResponse';
import { ManagerUserId } from 'src/types/Id';
import { ManagerUserTipSalesReportVm } from 'src/types/ManagerUserTipSalesReportVm';

export async function getManagerUserTipSalesReportApi(request: GetManagerUserTipSalesReportApiRequest): ApiSauceResponse<ManagerUserTipSalesReportVm> {
    return pidedirectoReportsMethod('/getManagerUserTipSalesReportApi', request);
}

type GetManagerUserTipSalesReportApiRequest = {
    managerUserId: ManagerUserId;
    fromDate: string;
    toDate: string;
};
