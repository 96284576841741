import { makeStyles } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import { Button } from '@pidedirecto/ui';
import { EditIcon } from '@pidedirecto/ui/icons';
import { useState } from 'react';
import * as React from 'react';
import { translate } from 'src/i18n/translate';
import { posReducer } from 'src/reducers/posReducer';
import { AddNoteToOrderDialog } from 'src/scenes/letseatmanager/pos/posMenu/AddNoteToOrderDialog';
import { findItemsChangedInOrder } from 'src/utils/pos/findItemsChangedInOrder';
import { useAction } from 'src/utils/react/useAction';
import { useSelector } from 'src/utils/react/useSelector';

export function PosAddNoteButton(): React.ReactElement {
    const classes = useStyles();

    const [addNoteToOrderDialogState, setAddNoteToOrderDialogState] = useState<{ open: boolean; actualNote?: string }>({
        open: false,
        actualNote: '',
    });

    const notes = useSelector((state) => state.pos.notes);
    const total = useSelector((state) => state.pos.payment?.total);
    const pendingOrder = useSelector((state) => state.pos.pendingOrder);
    const cancelledItems = useSelector((state) => state.pos.cancelledItems);
    const items = useSelector((state) => state.pos.items);

    const setNotes = useAction(posReducer.actions.setNotes);

    const itemsToPrint = findItemsChangedInOrder(pendingOrder?.orderItems ?? ([] as any), items);

    const disabled = !total && (!itemsToPrint?.length || !cancelledItems.length);

    const openAddNoteToOrderDialog = () => setAddNoteToOrderDialogState({ open: true });

    const closeAddNoteToOrderDialog = () => setAddNoteToOrderDialogState({ open: false });
    const clearAddNoteToOrderDialog = () => setAddNoteToOrderDialogState({ open: false, actualNote: '' });

    return (
        <>
            <AddNoteToOrderDialog
                open={addNoteToOrderDialogState.open}
                actualNote={notes}
                onSuccess={(note: string) => setNotes(note)}
                onClose={notes ? closeAddNoteToOrderDialog : clearAddNoteToOrderDialog}
            />
            {notes && (
                <div className={classes.separatedLineText}>
                    <p className={classes.notesText}>{`${translate('Notes')}: ${notes}`}</p>
                    <Button variant={'icon'} onClick={openAddNoteToOrderDialog} classes={{ button: classes.editNoteButton }}>
                        <EditIcon />
                    </Button>
                </div>
            )}
            {!notes && (
                <Button classes={{ button: classes.commentButton }} variant={'outline'} disabled={disabled} onClick={openAddNoteToOrderDialog}>
                    <AddIcon />
                    <div className={classes.commentButtonText}>
                        <div>{translate('Add comment in order')}</div>
                        <div style={{ fontSize: 11 }}>{translate('Special instructions, utensils, etc.')}</div>
                    </div>
                </Button>
            )}
        </>
    );
}

const useStyles = makeStyles((theme) => ({
    commentButton: {
        width: '100%',
        borderRadius: 8,
        flexShrink: 0,
        justifyContent: 'start',
    },
    separatedLineText: {
        backgroundColor: '#f7f7f7',
        fontSize: 12,
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        borderRadius: 10,
        padding: 10,
    },
    commentButtonText: {
        fontSize: 10,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'start',
        [theme.breakpoints.up('sm')]: {
            fontSize: 12,
        },
    },
    notesText: {
        fontFamily: theme.typography.regular,
        fontSize: 12,
        margin: 0,
        color: '#87888B',
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
    },
    editNoteButton: {
        width: 'fit-content',
        height: 'fit-content',
        minHeight: 'fit-content',
        padding: 3,
    },
}));
