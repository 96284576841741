import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import * as React from 'react';
import { useState } from 'react';
import { Button } from 'src/components/Button';
import { Chip } from 'src/components/Chip';
import { ChangeDeliveryProviderDialog } from 'src/components/dialog/theRestaurant/ChangeDeliveryProviderDialog';
import { CreateDeliveryProviderDialog } from 'src/components/dialog/theRestaurant/CreateDeliveryProviderDialog';
import { DraggableList } from 'src/components/DraggableList';
import { DraggableListItem } from 'src/components/DraggableListItem';
import { DeliveryProvider, DeliveryProviders } from 'src/constants/DeliveryProviders';
import { translate } from 'src/i18n/translate';
import { EditIcon } from 'src/icons/EditIcon';
import { TrashcanIcon } from 'src/icons/TrashcanIcon';

export function DeliveryProvidersDraggableList({ waitTimePerDeliveryProviders, onChange, title, filterDeliveryProviders, orderDeliveryProvider }: Props): React.ReactElement {
    const classes = useStyles();

    const availableDeliveryProviders: Array<DeliveryProvider> = filterDeliveryProviders ?? (Object.keys(DeliveryProviders) as Array<DeliveryProvider>);

    const [createDeliveryProviderDialogState, setCreateDeliveryProviderDialogState] = useState(false);
    const [changeDeliveryProviderDialogState, setChangeDeliveryProviderDialogState] = useState<{ open: boolean; currentDeliveryProvider: undefined | DeliveryProvider; currentDeliveryTime: number }>({
        open: false,
        currentDeliveryProvider: undefined,
        currentDeliveryTime: 0,
    });
    const [deliveryProviders, setDeliveryProviders] = useState<
        Array<{
            deliveryProvider: DeliveryProvider;
            waitTime: number;
        }>
    >(
        orderDeliveryProvider?.map((deliveryProvider: DeliveryProvider) => ({
            deliveryProvider: deliveryProvider,
            waitTime: waitTimePerDeliveryProviders?.[deliveryProvidersFormat[deliveryProvider]] ?? 0,
        })) ?? [],
    );

    const onCreateDeliveryProvider = (deliveryProvider: any, deliveryTime: any) => {
        const newDeliveryProviders = [...deliveryProviders, { deliveryProvider, waitTime: deliveryTime }];
        setDeliveryProviders(newDeliveryProviders);
        onChange(newDeliveryProviders);
    };

    const onChangeDeliveryProvider = (deliveryProvider: any, deliveryTime: any) => {
        const newDeliveryProviders = deliveryProviders.map((currentDeliveryProvider) => {
            if (currentDeliveryProvider.deliveryProvider === deliveryProvider) return { deliveryProvider, waitTime: deliveryTime };
            return currentDeliveryProvider;
        });
        setDeliveryProviders(newDeliveryProviders);
        onChange(newDeliveryProviders);
    };

    const handleRemoveDeliveryProvider = (deliveryProvider: { deliveryProvider: DeliveryProvider; waitTime: number }) => {
        const newDeliveryProviders = deliveryProviders.filter((currentDeliveryProvider) => currentDeliveryProvider.deliveryProvider !== deliveryProvider.deliveryProvider);
        setDeliveryProviders(newDeliveryProviders);
        onChange(newDeliveryProviders);
    };

    const openChangeDeliveryProviderDialog = ({ deliveryProvider, deliveryTime }: { deliveryProvider: DeliveryProvider; deliveryTime: number }) => {
        setChangeDeliveryProviderDialogState({ open: true, currentDeliveryProvider: deliveryProvider, currentDeliveryTime: deliveryTime });
    };

    const openCreateDeliveryProviderDialog = () => setCreateDeliveryProviderDialogState(true);

    const closeCreateDeliveryProviderDialog = () => setCreateDeliveryProviderDialogState(false);

    const closeChangeDeliveryProviderDialog = () => setChangeDeliveryProviderDialogState({ ...changeDeliveryProviderDialogState, open: false });

    const handleDragListItems = (value: any) => {
        setDeliveryProviders(value);
        onChange(value);
    };

    return (
        <>
            <CreateDeliveryProviderDialog
                open={createDeliveryProviderDialogState}
                onClose={closeCreateDeliveryProviderDialog}
                onCreateDeliveryProvider={onCreateDeliveryProvider}
                deliveryProviders={deliveryProviders}
                filterDeliveryProviders={filterDeliveryProviders}
            />
            <ChangeDeliveryProviderDialog
                open={changeDeliveryProviderDialogState.open}
                onClose={closeChangeDeliveryProviderDialog}
                onChangeDeliveryProvider={onChangeDeliveryProvider}
                deliveryProvider={{ deliveryProvider: changeDeliveryProviderDialogState.currentDeliveryProvider, deliveryTime: changeDeliveryProviderDialogState.currentDeliveryTime }}
            />
            <Grid item xs={12} md={6}>
                <h4 className={classes.labelList}>{title}</h4>
                <DraggableList onDragEnd={handleDragListItems}>
                    {deliveryProviders?.length ? (
                        deliveryProviders?.map((deliveryProvider: { deliveryProvider: DeliveryProvider; waitTime: number }) => {
                            if (!deliveryProvider.deliveryProvider) return;
                            return (
                                <DraggableListItem key={deliveryProvider.deliveryProvider} value={deliveryProvider} iconColor={'#6C7076'} classes={{ container: classes.draggableItemContainer }}>
                                    <div className={classes.contentItem}>
                                        {translate(deliveryProvider?.deliveryProvider)}
                                        <div className={classes.iconsContainer}>
                                            <Chip variant={'SUCCESS'}>{translate('@deliveryTime seg', { deliveryTime: deliveryProvider.waitTime })}</Chip>
                                            <Button
                                                icon
                                                classes={{ button: classes.button }}
                                                onClick={() =>
                                                    openChangeDeliveryProviderDialog({
                                                        deliveryProvider: deliveryProvider.deliveryProvider,
                                                        deliveryTime: deliveryProvider.waitTime,
                                                    })
                                                }
                                            >
                                                <EditIcon title={'edit-icon'} />
                                            </Button>
                                            <Button icon classes={{ button: classes.button }} onClick={() => handleRemoveDeliveryProvider(deliveryProvider)}>
                                                <TrashcanIcon width={'21'} height={'21'} color={'#C02D19'} title={'trash-icon'} />
                                            </Button>
                                        </div>
                                    </div>
                                </DraggableListItem>
                            );
                        })
                    ) : (
                        <div className={classes.warningEmptyState}>{translate('Add a supplier and assign the wait time')}</div>
                    )}
                    {deliveryProviders?.length < availableDeliveryProviders.length && (
                        <div className={classes.alignRightContainer}>
                            <Button outlined onClick={openCreateDeliveryProviderDialog}>
                                {translate('+ Add supplier')}
                            </Button>
                        </div>
                    )}
                </DraggableList>
            </Grid>
        </>
    );
}

const inverseDeliveryProvidersFormat = {
    uberDaasWaitTime: DeliveryProviders.UBER_DAAS,
    rappiCargoWaitTime: DeliveryProviders.RAPPI_CARGO,
    pidedirectoWaitTime: DeliveryProviders.PIDEDIRECTO,
} as const;

const deliveryProvidersFormat = {
    UBER_DAAS: 'uberDaasWaitTime',
    RAPPI_CARGO: 'rappiCargoWaitTime',
    PIDEDIRECTO: 'pidedirectoWaitTime',
} as const;

const useStyles = makeStyles((theme) => ({
    draggableItemContainer: {
        alignItems: 'center',
        marginBottom: 6,
    },
    contentItem: {
        display: 'flex',
        width: '100%',
        alignItems: 'center',
        justifyContent: 'space-between',
        fontFamily: theme.typography.regular,
    },
    labelList: {
        fontFamily: theme.typography.regular,
        fontSize: 14,
        marginBottom: 8,
    },
    iconsContainer: {
        display: 'flex',
        alignItems: 'center',
        gap: 12,
    },
    timeContainer: {
        fontSize: 10,
        color: 'white',
        backgroundColor: '#0C8F8B',
        paddingLeft: 12,
        paddingRight: 12,
        paddingTop: 6,
        paddingBottom: 6,
        borderRadius: 10,
    },
    alignRightContainer: {
        display: 'flex',
        justifyContent: 'flex-end',
    },
    warningEmptyState: {
        color: '#BABABA',
        marginBottom: 12,
        textAlign: 'center',
    },
    button: {
        color: theme.palette.icons.primary,
    },
}));

type Props = {
    title?: string;
    waitTimePerDeliveryProviders?: any;
    filterDeliveryProviders?: Array<DeliveryProvider>;
    orderDeliveryProvider?: Array<DeliveryProvider>;
    onChange: any;
};
