import { makeStyles } from '@material-ui/core';
import { Loader } from '@pidedirecto/ui';
import { useState } from 'react';
import * as React from 'react';
import { syncMenuWithIntegrationsApi } from 'src/api/letseatmanager/menu/syncMenuWithIntegrationsApi';
import { Button } from 'src/components/Button';
import { Checkbox } from 'src/components/Checkbox';
import { Dialog } from 'src/components/Dialog';
import { DialogActions } from 'src/components/DialogActions';
import { App, Apps } from 'src/constants/App';
import { translate } from 'src/i18n/translate';
import { PidedirectoAppIcon } from 'src/icons/PidedirectoAppIcon';
import { PidedirectoPosIcon } from 'src/icons/PidedirectoPosIcon';
import TheMenuActions from 'src/scenes/letseatmanager/deprecatedMenu/TheMenuReducer';
import { useLoadMenus } from 'src/services/menu/useLoadMenus';
import type { MenuVm as DeprecatedMenuVm } from 'src/types/DeprecatedMenuVm';
import { MenuId } from 'src/types/Id';
import type { MenuVm } from 'src/types/MenuVm';
import { isPideDirectoApp } from 'src/utils/app/isPideDirectoApp';
import { isPosApp } from 'src/utils/app/isPosApp';
import { getChannelImage } from 'src/utils/menu/getChannelImage';
import { isRappiApp } from 'src/utils/order/isRappiApp';
import { useAction } from 'src/utils/react/useAction';
import { useSelector } from 'src/utils/react/useSelector';

export function SyncMenuIntegrationsDialog({ open, onClose, menus, onError }: Props): React.ReactElement | null {
    const classes = useStyles();

    const loadMenus = useLoadMenus();

    const [loading, setLoading] = useState<{ currentState: boolean; menuId: MenuId | null }>({ currentState: false, menuId: null });
    const [selectedMenuIds, setSelectedMenuIds] = useState<Array<MenuId>>([]);

    const loadDeprecatedMenus = useAction(TheMenuActions.fetch);

    const newMenuManagementEnabled = useSelector((state) => state.app.restaurant.newMenuManagementEnabled);

    const syncMenus = async () => {
        if (!menus) return;
        const isMoreThanOneMenu = menus.length > 1;
        for (const menu of menus) {
            setLoading({ currentState: true, menuId: menu.menuId });
            await syncMenuWithIntegrations(menu, isMoreThanOneMenu);
            setLoading({ ...loading, currentState: false });
        }
        refreshMenus();
        onClose();
    };

    const syncAllMenusSelectedWithIntegrations = async () => {
        if (!selectedMenuIds) return;

        const isMoreThanOneMenu = selectedMenuIds.length > 1;
        for (const menuId of selectedMenuIds) {
            const menu = (menus as any)?.find((menu: MenuVm | DeprecatedMenuVm) => menuId === menu.menuId);
            setLoading({ currentState: true, menuId: menuId });
            await syncMenuWithIntegrations(menu, isMoreThanOneMenu);
            setLoading({ ...loading, currentState: false });
        }
        refreshMenus();
        setSelectedMenuIds([]);
        onClose();
    };

    const syncMenuWithIntegrations = async (menu: undefined | MenuVm | DeprecatedMenuVm, isMoreThanOneMenu: boolean) => {
        const errors: Array<any | string> = [];

        if (!menu) {
            errors.push(translate('There is an error with the selected menu. Please contact support'));
            onError(errors);
            return;
        }
        const response = await syncMenuWithIntegrationsApi({ menuId: menu?.menuId, force: isMoreThanOneMenu });
        if (!response.ok) {
            errors.push(translate(`${menu.name}: ${response.data?.message ?? ''}`));
            onError(errors);
            return;
        }
        menu?.channels?.forEach((channel: App) => {
            const isUberEatsChannel = channel === Apps.UBER_EATS;
            const isRappiChannel = channel === Apps.RAPPI;
            const isDidiChannel = channel === Apps.DIDI_FOOD;
            const isPedidosYa = channel === Apps.PEDIDOS_YA;

            if (isUberEatsChannel) {
                if (response.data.uberEatsMenuSyncSucceeded.syncStatus) {
                    alert(`Uber Eats Menu Sync: ${response.data.uberEatsMenuSyncSucceeded.syncStatus ? 'Success' : response.data.uberEatsMenuSyncSucceeded.message}`);
                    return;
                }
                errors.push(`${menu.name}: ${response.data.uberEatsMenuSyncSucceeded.message}`);
            }
            if (isRappiChannel) {
                if (response.data.rappiMenuSyncSucceeded.syncStatus) {
                    alert(`Rappi Menu Sync: ${response.data.rappiMenuSyncSucceeded.syncStatus ? 'On Process' : response.data.rappiMenuSyncSucceeded.message}`);
                    return;
                }
                errors.push(`${menu.name}: ${response.data.rappiMenuSyncSucceeded.message}`);
            }
            if (isDidiChannel) {
                if (response.data.didiFoodMenuSyncSucceeded.syncStatus) {
                    alert(`Didi Food Menu Sync: ${response.data.didiFoodMenuSyncSucceeded.syncStatus ? 'Success' : response.data.didiFoodMenuSyncSucceeded.message}`);
                    return;
                }
                errors.push(`${menu.name}: ${response.data.didiFoodMenuSyncSucceeded.message}`);
            }
            if (isPedidosYa) {
                if (response.data.pedidosYaMenuSyncSucceeded.syncStatus) {
                    alert(`Pedidos Ya Menu Sync: ${response.data.pedidosYaMenuSyncSucceeded.syncStatus ? 'Success' : response.data.pedidosYaMenuSyncSucceeded.message}`);
                    return;
                }
                errors.push(`${menu.name}: ${response.data.pedidosYaMenuSyncSucceeded.message}`);
            }
        });
        onError(errors);
    };

    const handleSelect = (menuId: MenuId, e: any) => {
        if (!menuId) return;
        if (selectedMenuIds.includes(menuId) && !e.target.checked) {
            setSelectedMenuIds(selectedMenuIds.filter((item) => item !== menuId));
            return;
        }
        setSelectedMenuIds((oldSelectedMenuIds) => [...oldSelectedMenuIds, menuId]);
    };

    const refreshMenus = () => {
        if (newMenuManagementEnabled) return loadMenus();
        loadDeprecatedMenus();
    };

    if (!menus) return null;

    return (
        <Dialog onClose={onClose} open={open} classes={{ dialog: classes.dialogContainer, title: classes.title }} title={translate('Update changes to your menus')}>
            <div>
                {menus?.map((menu: MenuVm | DeprecatedMenuVm) => {
                    const isLoading = loading.currentState && loading?.menuId === menu.menuId;
                    const isRappi = menu?.channels?.some((channel: App) => isRappiApp(channel));
                    return (
                        <div className={classes.row}>
                            <div className={classes.container} key={menu.menuId}>
                                <Checkbox name={menu.name} value={menu.menuId} label={menu.name} onChange={handleSelect} />
                                <div className={classes.subContainer}>
                                    {menu.channels.map((channel, idx) => (
                                        <div className={classes.channelContainer} key={`${idx}-${channel}`}>
                                            {!isPideDirectoApp(channel) && !isPosApp(channel) && (
                                                <img className={classes.img} src={getChannelImage(channel)} alt={`Channel integration logo for ${channel}`} unselectable='off' />
                                            )}
                                            {isPideDirectoApp(channel) && <PidedirectoAppIcon title={'APP'} />}
                                            {isPosApp(channel) && <PidedirectoPosIcon title={'posIcon'} />}
                                        </div>
                                    ))}
                                </div>
                                {isLoading && <Loader size={12} loading={true} />}
                            </div>
                            {isRappi && <span className={classes.warning}>{translate('Updates to your Rappi menu may take a while to appear in the app.')}</span>}
                        </div>
                    );
                })}
                <DialogActions className={classes.dialogActionsContainer}>
                    <Button secondary classes={{ button: classes.buttonContainer }} disabled={!selectedMenuIds.length} onClick={syncAllMenusSelectedWithIntegrations}>
                        {translate('Update selected')}
                    </Button>
                    <Button classes={{ button: classes.buttonContainer }} disabled={loading.currentState} onClick={syncMenus}>
                        {translate('Update all')}
                    </Button>
                </DialogActions>
            </div>
        </Dialog>
    );
}

const useStyles = makeStyles((theme) => ({
    container: {
        display: 'flex',
        width: '100%',
        justifyContent: 'space-around',
        alignItems: 'center',
    },
    subContainer: {
        width: '100%',
        display: 'flex',
        justifyContent: 'right',
        marginBottom: 5,
        marginLeft: 18,
    },
    title: {
        color: '#198074',
    },
    text: {
        color: '#0D3037',
        fontWeight: 600,
    },
    button: {
        color: '#198074',
        textDecoration: 'underline',
        fontWeight: 400,
    },
    channelContainer: {
        borderRadius: 70,
        backgroundColor: theme.palette.secondary.light,
        border: `1px solid #D9D9D9`,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexShrink: 0,
        padding: 7,
        width: 40,
        height: 40,
        marginRight: 15,
    },
    img: {
        objectFit: 'contain',
        width: '100%',
        maxWidth: 32,
    },
    labelCheckBox: {
        fontSize: '16px',
        fontFamily: theme.typography.regular,
    },
    dialogContainer: {
        padding: '24px 48px',
        display: 'flex',
        flexDirection: 'column',
        gap: 24,
    },
    dialogActionsContainer: {
        display: 'flex',
        justifyContent: 'flex-end',
        gap: 12,
        width: '100%',
    },
    row: {
        display: 'flex',
        flexDirection: 'column',
        marginBottom: '32px',
        borderBottom: '2px solid #d9d9d9',
        width: '100%',
        justifyContent: 'center',
        alignItems: 'center',
    },
    warning: {
        color: '#616B79',
        fontSize: 12,
        textAlign: 'center',
        width: '100%',
        maxWidth: '320px',
        fontFamily: theme.typography.regular,
    },
    buttonContainer: {
        height: 42,
    },
}));

type Props = {
    open: boolean;
    onClose: any;
    menus?: Array<MenuVm> | Array<DeprecatedMenuVm>;
    onError: any;
};
