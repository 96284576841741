import type { CourseOrderDetailsVm } from 'src/types/CourseOrderDetailsVm';
import type { PosCourseOrderDetails } from 'src/types/PosCourseOrderDetails';
import { mapCartItemToOrderItem } from 'src/utils/pos/mapCartItemToOrderItem';

export function mapPosCourseToOrderCourse(posCourse: PosCourseOrderDetails): CourseOrderDetailsVm {
    return {
        courseId: posCourse.courseId,
        name: posCourse.name,
        orderItems: posCourse.orderItems.map(mapCartItemToOrderItem),
        cancelledItems: posCourse.cancelledItems?.map(mapCartItemToOrderItem),
    };
}
