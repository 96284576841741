import { makeStyles } from '@material-ui/core';
import * as React from 'react';
import { useState } from 'react';
import { useEffect } from 'react';
import { Button } from 'src/components/Button';
import { Dialog } from 'src/components/Dialog';
import { DialogActions } from 'src/components/DialogActions';
import { Slider } from 'src/components/Slider';
import { translate } from 'src/i18n/translate';
import type { OrderVm } from 'src/types/OrderVm';

export function OrderVerificationPhotosDialog({ open, order, onClose }: Props): React.ReactElement {
    const classes = useStyles();

    const [content, setContent] = useState({ title: translate('In progress..'), data: '' });

    const isSuspendedOrder = order?.uberDaasDeliveryPictureVerification?.return;
    const isPickedUpOrder = order?.uberDaasDeliveryPictureVerification?.pickup;
    const isDropOffOrder = order?.uberDaasDeliveryPictureVerification?.dropoff;

    const onChange = (currentIndex: number) => {
        if (currentIndex === 0 && isPickedUpOrder) {
            setContent({ title: translate('Order with delivery man'), data: translate('The restaurant handed the order to the delivery man') });
            return;
        }
        if (currentIndex === 1 && isSuspendedOrder) {
            setContent({ title: translate('Suspended order'), data: translate('The order is back to the restaurant') });
            return;
        }
        if (currentIndex === 1 && isDropOffOrder) {
            setContent({ title: translate('Order delivered'), data: translate('The delivery man successfully delivered the order') });
            return;
        }
        setContent({ title: translate('In progress..'), data: translate('The delivery person has not yet completed this step') });
        return;
    };

    useEffect(() => {
        onChange(0);
    }, [open]);

    return (
        <Dialog classes={{ title: isSuspendedOrder ? classes.titleSuspended : '' }} open={open} onClose={onClose} title={content.title}>
            <div className={classes.text}>{content.data}</div>
            <Slider
                listItems={[
                    { imageUrl: order?.uberDaasDeliveryPictureVerification?.pickup },
                    { imageUrl: isSuspendedOrder ? order?.uberDaasDeliveryPictureVerification?.return : order?.uberDaasDeliveryPictureVerification?.dropoff },
                ]}
                onChange={onChange}
            />
            <DialogActions>
                <Button onClick={onClose}>{translate('Return')}</Button>
            </DialogActions>
        </Dialog>
    );
}

const useStyles = makeStyles((theme) => ({
    buttonsContainer: {
        marginTop: 20,
    },
    button: {
        width: 200,
    },
    titleSuspended: {
        color: '#EC9C23',
    },
    text: {
        color: '#616B79',
        textAlign: 'center',
        marginBottom: 24,
    },
}));

type Props = {
    open: boolean;
    order: OrderVm;
    onClose: any;
};
