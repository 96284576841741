import { letseatadminApiMethod } from 'src/api/letseatadminApi';
import type { ApiSauceResponse } from 'src/api/types/ApiSauceResponse';
import type { DriverBannerId } from 'src/types/Id';

export async function enableDriverBannerApi(request: enableDriverBannerRequest): ApiSauceResponse<void> {
    return letseatadminApiMethod('driverBanner/enableDriverBannerApi', request);
}

export type enableDriverBannerRequest = {
    driverBannerId: DriverBannerId;
};
