import { useState } from 'react';
import * as React from 'react';
import { useForm } from 'react-hook-form';
import { removeRestaurantZoneApi } from 'src/api/letseatmanager/pos/removeRestaurantZoneApi';
import { Button } from 'src/components/Button';
import { Dialog } from 'src/components/Dialog';
import { DialogActions } from 'src/components/DialogActions';
import { Form } from 'src/components/form/Form';
import { translate } from 'src/i18n/translate';
import { posReducer } from 'src/reducers/posReducer';
import { alertKnownErrorOrSomethingWentWrong } from 'src/utils/alert/alertKnownErrorOrSomethingWentWrong';
import { useAction } from 'src/utils/react/useAction';
import { useSelector } from 'src/utils/react/useSelector';

export function RemoveRestaurantZoneDialog(): React.ReactElement {
    const form = useForm();

    const [loading, setLoading] = useState(false);

    const open = useSelector((state) => state.pos.removeRestaurantZoneDialog.open);
    const onSuccess = useSelector((state) => state.pos.removeRestaurantZoneDialog.onSuccess);
    const restaurantZoneId = useSelector((state) => state.pos.removeRestaurantZoneDialog.restaurantZoneId);

    const closeRemoveRestaurantZoneDialog = useAction(posReducer.actions.closeRemoveRestaurantZoneDialog);

    const handleClose = () => {
        if (loading) return;
        closeRemoveRestaurantZoneDialog();
    };

    const handleSubmit = async () => {
        setLoading(true);
        const response = await removeRestaurantZoneApi({
            restaurantZoneId,
        });
        if (!response.ok) {
            setLoading(false);
            alertKnownErrorOrSomethingWentWrong(response);
            return;
        }
        setLoading(false);
        onSuccess();
    };

    return (
        <Dialog open={open} onClose={handleClose} title={translate('Are you sure you want to remove this restaurant zone?')}>
            <Form onSubmit={handleSubmit} form={form}>
                <DialogActions>
                    <Button secondary onClick={handleClose} disabled={loading}>
                        {translate('Cancel')}
                    </Button>
                    <Button type='submit' disabled={loading}>
                        {loading ? translate('REMOVING') : translate('REMOVE')}
                    </Button>
                </DialogActions>
            </Form>
        </Dialog>
    );
}
