import { letseatadminApiMethod } from 'src/api/letseatadminApi';
import type { LogEventVm } from 'src/api/letseatmanager/types/LogEventVm';
import type { ApiSauceResponse } from 'src/api/types/ApiSauceResponse';
import type { OrderId } from 'src/types/Id';

export async function getOrderLogEventsApi(request: GetOrderLogEventsApiRequest): ApiSauceResponse<Array<LogEventVm>> {
    return letseatadminApiMethod('logEvent/getOrderLogEventsApi', request);
}

export type GetOrderLogEventsApiRequest = {
    orderId: OrderId;
};
