import { makeStyles } from '@material-ui/core';
import { Loader } from '@pidedirecto/ui';
import { useEffect } from 'react';
import * as React from 'react';
import { Chip } from 'src/components/Chip';
import { translate } from 'src/i18n/translate';
import { PosCashRegisterActions } from 'src/scenes/letseatmanager/pos/PosCashRegisterActions';
import { PosRouter } from 'src/scenes/letseatmanager/pos/PosRouter';
import { PosCashRegisterTransactions } from 'src/scenes/letseatmanager/posCashRegister/PosCashRegisterTransactions';
import { PosClosedCashRegister } from 'src/scenes/letseatmanager/posCashRegister/PosClosedCashRegister';
import { PosOpenedCashRegisterSummary } from 'src/scenes/letseatmanager/posCashRegister/PosOpenedCashRegisterSummary';
import { PrintPartialShiftCashRegisterButton } from 'src/scenes/letseatmanager/posCashRegister/PrintPartialShiftCashRegisterButton';
import { useFetchCashRegister } from 'src/services/cashRegister/useFetchCashRegister';
import { useFetchCashRegisterTransactions } from 'src/services/cashRegister/useFetchCashRegisterTransactions';
import { useSelector } from 'src/utils/react/useSelector';

export function PosCashRegister(): React.ReactElement {
    const classes = useStyles();

    const { fetchOpenedCashRegister, loading } = useFetchCashRegister();
    const { fetchCashRegisterTransactions, transactions } = useFetchCashRegisterTransactions();

    const openedCashRegister = useSelector((state) => state.pos.openedCashRegister);
    const openedPosBusinessDay = useSelector((state) => state.pos.openedPosBusinessDay);

    useEffect(() => {
        fetchOpenedCashRegister();
    }, []);

    useEffect(() => {
        fetchCashRegisterTransactions();
    }, [openedCashRegister]);

    if (loading) {
        return (
            <div className={classes.loadingContainer}>
                <Loader loading={true} size={40} />
            </div>
        );
    }

    if (!openedCashRegister || !openedPosBusinessDay) {
        return <PosClosedCashRegister />;
    }

    return (
        <div className={classes.openedCashRegister}>
            <PosRouter />
            <div className={classes.container}>
                <div className={classes.infoContainer}>
                    <div className={classes.statusContainer}>
                        <div className={classes.cashContainer}>
                            <h1 className={classes.name}>{translate('Cash Register')}</h1>
                            <Chip variant={openedCashRegister?.opened ? 'SUCCESS' : 'ERROR'}>{openedCashRegister?.opened ? translate('Opened') : translate('Closed')}</Chip>
                        </div>
                        <PrintPartialShiftCashRegisterButton cashRegisterId={openedCashRegister?.cashRegisterId} />
                    </div>
                    <PosOpenedCashRegisterSummary />
                    <PosCashRegisterActions cashRegister={openedCashRegister} refreshCashRegisterTransactions={fetchCashRegisterTransactions} />
                    <PosCashRegisterTransactions transactions={transactions} />
                </div>
            </div>
        </div>
    );
}

const useStyles = makeStyles((theme) => ({
    openedCashRegister: {
        fontFamily: theme.typography.regular,
        fontSize: 14,
        color: theme.palette.text.primary,
        width: '100%',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    loadingContainer: {
        width: '100',
        height: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    cashContainer: {
        display: 'flex',
        alignItems: 'center',
    },
    container: {
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        width: '90%',
        [theme.breakpoints.up('sm')]: {
            width: '75%',
        },
    },
    infoContainer: {
        width: '100%',
        height: 'fit-content',
        [theme.breakpoints.down('sm')]: {
            margin: '30px 0',
        },
    },
    statusContainer: {
        width: '100%',
        display: 'flex',
        justifyContent: 'space-between',
        flexDirection: 'column',
        [theme.breakpoints.up('sm')]: {
            flexDirection: 'row',
            alignItems: 'center',
        },
    },
    name: {
        fontFamily: theme.typography.semiBold,
        margin: 0,
        fontSize: 20,
        marginRight: 10,
        color: theme.palette.text.primary,
    },
}));
