import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { deprecatedChangeMenuApi } from 'src/api/letseatmanager/menu/deprecatedChangeMenuApi';
import { app2 } from 'src/app2';
import { Button } from 'src/components/Button';
import { Channels } from 'src/components/Channels';
import { Dialog } from 'src/components/Dialog';
import { DialogActions } from 'src/components/DialogActions';
import { Form } from 'src/components/form/Form';
import { FormSwitch } from 'src/components/form/FormSwitch';
import { FormTextField } from 'src/components/form/FormTextField';
import { App, Apps } from 'src/constants/App';
import { translate } from 'src/i18n/translate';
import { alertKnownErrorOrSomethingWentWrong } from 'src/utils/alert/alertKnownErrorOrSomethingWentWrong';
import { useAction } from 'src/utils/react/useAction';
import { useSelector } from 'src/utils/react/useSelector';
import { hours } from 'src/utils/Validator';

export function ChangeMenuDialog(): React.ReactElement {
    const form = useForm();
    const classes = useStyles();

    const [loading, setLoading] = useState(false);
    const [channels, setChannels] = useState<Array<App>>([]);

    const internalUser = useSelector((state) => state.authentication.internalUser);
    const open = useSelector((state) => state.app2.changeMenuDialog.open);
    const menu = useSelector((state) => state.app2.changeMenuDialog.menu);
    const onSuccess = useSelector((state) => state.app2.changeMenuDialog.onSuccess);
    const menus = useSelector((state) => state.theMenu.menus);
    const hasRappiMenu = menus.some((menu) => menu.channels?.includes(Apps.RAPPI));
    const hasDidiMenu = menus.some((menu) => menu.channels?.includes(Apps.DIDI_FOOD));
    const disabledChannels: Array<App> = [];
    if (!channels.includes(Apps.DIDI_FOOD) && hasDidiMenu) disabledChannels.push(Apps.DIDI_FOOD);
    if (!channels.includes(Apps.RAPPI) && hasRappiMenu) disabledChannels.push(Apps.RAPPI);

    const closeChangeMenuDialog = useAction(app2.actions.closeChangeMenuDialog);

    const handleClose = () => {
        if (loading) return;
        closeChangeMenuDialog();
    };
    const handleSubmit = async (form: any) => {
        setLoading(true);
        const response = await deprecatedChangeMenuApi({
            menuId: menu.menuId,
            name: form.name,
            hidden: form.hidden,
            hours: form.hours ?? '',
            companyName: form.companyName,
            channels,
        });
        if (!response.ok) {
            setLoading(false);
            alertKnownErrorOrSomethingWentWrong(response);
            return;
        }
        setLoading(false);
        onSuccess();
        closeChangeMenuDialog();
    };

    useEffect(() => {
        if (menu) setChannels(menu?.channels ?? [Apps.PIDEDIRECTO]);
    }, [menu]);

    const handleChangeChannels = (newChannels: any) => setChannels(newChannels);

    return (
        <Dialog title={translate('Change Menu')} open={open} onClose={handleClose}>
            <Form onSubmit={handleSubmit} form={form} className={classes.form}>
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <FormSwitch name='hidden' disabled={loading} label={translate('Hidden')} defaultValue={menu?.hidden} />
                    </Grid>
                </Grid>
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <FormTextField name='name' label={translate('Name')} required disabled={loading} defaultValue={menu?.name} />
                    </Grid>
                </Grid>
                {internalUser && (
                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                            <FormTextField
                                name='hours'
                                label={translate('Hours')}
                                helperText={`${translate('E.g. "Mo-Fr 08:00-09:00", "Sa-Su" or "Mar Mo-Fr 11:00-14:00". see https://openingh.openstreetmap.de/evaluation_tool/#check')}`}
                                placeholder={'24/7'}
                                disabled={loading}
                                validate={hours}
                                defaultValue={menu?.hours}
                            />
                        </Grid>
                    </Grid>
                )}
                {internalUser && (
                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                            <FormTextField name='companyName' label={translate('Company Name')} disabled={loading} />
                        </Grid>
                    </Grid>
                )}
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <p className={classes.textBold}>{translate('Where is going to show the menu?')}</p>
                        <span className={classes.text}>{translate('Select the channels available')}</span>
                        <div className={classes.integrationChannelsContainer}>
                            <Channels onChange={handleChangeChannels} disabledChannels={disabledChannels} />
                        </div>
                    </Grid>
                </Grid>
                <DialogActions>
                    <Button secondary onClick={handleClose} disabled={loading}>
                        {translate('Cancel')}
                    </Button>
                    <Button type='submit' disabled={loading}>
                        {loading ? translate('Changing') : translate('Change')}
                    </Button>
                </DialogActions>
            </Form>
        </Dialog>
    );
}

const useStyles = makeStyles((theme) => ({
    form: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },

    textBold: {
        fontFamily: theme.typography.medium,
        fontSize: 18,
        margin: 0,
    },
    text: {
        fontFamily: theme.typography.regular,
        fontSize: 15,
        marginBottom: 20,
    },
    integrationChannelsContainer: {
        width: '100%',
        marginTop: 20,
        display: 'flex',
        justifyContent: 'center',
    },
}));
