import { DialogTitle, makeStyles } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import Grid from '@material-ui/core/Grid';
import { useEffect, useState } from 'react';
import * as React from 'react';
import { useForm, useWatch } from 'react-hook-form';
import { changeRestaurantUserApi } from 'src/api/letseatmanager/userManagement/changeRestaurantUserApi';
import { getRestaurantUserApi } from 'src/api/letseatmanager/userManagement/getRestaurantUserApi';
import { Form } from 'src/components/form/Form';
import { FormBrandRestaurantsAutocomplete } from 'src/components/form/FormBrandRestaurantsAutocomplete';
import { FormEmailField } from 'src/components/form/FormEmailField';
import { FormPinCode } from 'src/components/form/FormPinCode';
import { FormTextField } from 'src/components/form/FormTextField';
import { FormUserRoleSelect } from 'src/components/form/FormUserRoleSelect';
import { RolePermissions } from 'src/constants/RolePermission';
import { translate } from 'src/i18n/translate';
import { useReloadRestaurant } from 'src/services/restaurant/useReloadRestaurant';
import type { ManagerUserId } from 'src/types/Id';
import { alertKnownErrorOrSomethingWentWrong } from 'src/utils/alert/alertKnownErrorOrSomethingWentWrong';
import { useLoadApi } from 'src/utils/react/useLoadApi';
import { useSelector } from 'src/utils/react/useSelector';

export function ChangeRestaurantUserDialog({ open, onClose, onSuccess, managerUserId }: Props): React.ReactElement {
    const classes = useStyles();
    const form = useForm();
    const { control } = form;

    const { reloadRestaurant } = useReloadRestaurant();

    const [isUserWithPinCodeSignIn, setIsUserWithPinCodeSignIn] = useState(false);
    const [loading, setLoading] = useState(false);

    const restaurantId = useSelector((state) => state.app.restaurantId);

    const pinCode = useWatch({ name: 'pinCode', control });

    const [loadingRestaurantUser, restaurantUser] = useLoadApi(
        getRestaurantUserApi,
        { managerUserId: managerUserId!, restaurantId },
        { requiredValues: [managerUserId], dependencies: [managerUserId] },
    );

    useEffect(() => {
        if (restaurantUser) {
            form.reset({ ...restaurantUser, confirmPinCode: restaurantUser.pinCode });
            const isUserWithPinCodeSignIn = !!restaurantUser.userRolePermissions?.includes(RolePermissions.PIN_CODE_SIGN_IN);
            setIsUserWithPinCodeSignIn(isUserWithPinCodeSignIn);
        }
    }, [restaurantUser]);

    const handleClose = () => {
        if (loadingRestaurantUser || loading) return;
        onClose();
    };

    const onSubmit = async (form: any) => {
        setLoading(true);
        const response = await changeRestaurantUserApi({
            managerUserId,
            name: form.name,
            email: form.email.toLowerCase(),
            userRoleId: form.userRoleId,
            pinCode: form.pinCode,
            restaurantIds: form.restaurantIds,
        });
        setLoading(false);
        if (!response.ok) {
            alertKnownErrorOrSomethingWentWrong(response);
            return;
        }

        reloadRestaurant(restaurantId);

        handleClose();
        onSuccess();
    };

    const handleUserRoleChange = (userRole: any) => {
        const isUserWithPinCodeSignIn = userRole.permissions.includes(RolePermissions.PIN_CODE_SIGN_IN);
        setIsUserWithPinCodeSignIn(isUserWithPinCodeSignIn);
    };

    return (
        <Dialog fullWidth scroll='paper' open={open} onClose={handleClose} classes={{ paper: classes.dialogContainer }}>
            <Form form={form} onSubmit={onSubmit}>
                <DialogTitle>
                    <div className={classes.title}> {translate('Change User')}</div>
                </DialogTitle>
                <DialogContent style={{ overflowY: 'hidden' }}>
                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                            <FormTextField name='name' label={translate('Name')} required disabled={loading} />
                        </Grid>
                        <Grid item xs={12}>
                            <FormEmailField name='email' label={translate('Email')} required disabled={loading} />
                        </Grid>
                        <Grid item xs={12}>
                            <FormUserRoleSelect name='userRoleId' label={translate('Role')} required onChange={handleUserRoleChange} disabled={loading} />
                        </Grid>
                        {isUserWithPinCodeSignIn && (
                            <>
                                <Grid item xs={12} md={6}>
                                    <FormPinCode name='pinCode' label={translate('PIN code')} required minLength={6} maxLength={6} disabled={loading} />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <FormPinCode
                                        name='confirmPinCode'
                                        label={translate('Confirm PIN code')}
                                        required
                                        minLength={6}
                                        maxLength={6}
                                        validate={{
                                            value: (value: string) => value === pinCode,
                                        }}
                                        disabled={loading}
                                    />
                                </Grid>
                            </>
                        )}
                        <Grid item xs={12}>
                            <FormBrandRestaurantsAutocomplete name='restaurantIds' label={translate('Restaurants')} required disabled={loading} />
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions className={classes.buttonsContainer}>
                    <Button className={classes.cancelButton} onClick={handleClose} disabled={loading} variant={'outlined'}>
                        {translate('Cancel')}
                    </Button>
                    <Button className={classes.acceptButton} color='primary' variant={'contained'} type={'submit'} disabled={loading}>
                        {translate('Change')}
                    </Button>
                </DialogActions>
            </Form>
        </Dialog>
    );
}

const useStyles = makeStyles((theme) => ({
    dialogContainer: {
        maxWidth: 500,
    },
    title: {
        fontFamily: theme.typography.medium,
        fontSize: 20,
    },
    buttonsContainer: {
        marginTop: 20,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        [theme.breakpoints.down('sm')]: {
            flexDirection: 'column',
            marginBottom: 20,
        },
    },
    credentialsContainerHidden: {
        display: 'none',
    },
    credentialsContainerShow: {
        display: 'inline',
        width: '100%',
    },
    acceptButton: {
        fontFamily: theme.typography.semiBold,
        fontSize: 15,
        borderRadius: 15,
        height: 40,
        minWidth: 150,
        textTransform: 'none',
        marginTop: 15,
        [theme.breakpoints.up('md')]: {
            margin: 0,
        },
    },
    cancelButton: {
        color: theme.palette.primary.main,
        border: `1px solid ${theme.palette.primary.main}`,
        fontFamily: theme.typography.semiBold,
        fontSize: 15,
        borderRadius: 15,
        height: 40,
        width: 150,
        textTransform: 'none',
    },
}));

type Props = {
    open: boolean;
    managerUserId?: ManagerUserId;
    onClose: any;
    onSuccess: any;
};
