import { makeStyles } from '@material-ui/core';
import { BigNumber } from 'bignumber.js';
import * as React from 'react';
import { memo } from 'react';
import { PosPaymentMethodSelect } from 'src/components/pos/PosPaymentMethodSelect';
import { PosCustomerInfo } from 'src/modules/pos/customer/components/PosCustomerInfo';
import { PosPaymentCustomersList } from 'src/scenes/letseatmanager/pos/posPayment/PosPaymentCustomersList';
import { PosPaymentNumericPad } from 'src/scenes/letseatmanager/pos/posPayment/PosPaymentNumericPad';
import { SetPosPaymentMethodParams } from 'src/services/pos/posPaymentMethods/posPaymentMethodsStore';
import { useCustomPaymentMethodSelected } from 'src/services/pos/posPaymentMethods/useCustomPaymentMethodSelected';
import { usePaymentMethodSelected } from 'src/services/pos/posPaymentMethods/usePaymentMethodSelected';
import { useSetPosPaymentMethod } from 'src/services/pos/posPaymentMethods/useSetPosPaymentMethod';
import { isPaymentLinkPayment } from 'src/utils/paymentMethod/isPaymentLinkPayment';
import { classNames } from 'src/utils/react/classNames';
import { useSelector } from 'src/utils/react/useSelector';
import { useIsSmallScreen } from 'src/utils/react/window/useIsSmallScreen';

export const PosPaymentOptions: React.ComponentType<any> = memo<any>(() => {
    const classes = useStyles();

    const isSmallScreen = useIsSmallScreen();
    const paymentMethod = usePaymentMethodSelected();
    const customPaymentMethod = useCustomPaymentMethodSelected();
    const setPosPaymentMethod = useSetPosPaymentMethod();

    const total = useSelector((state) => state.pos.payment?.total);
    const disabledPosPaymentActions = useSelector((state) => state.pos.disabledPosPaymentActions);
    const totalPaid = useSelector((state) => state.pos.totalPaid);
    const paying = useSelector((state) => state.pos.paying);
    const openMobilePaymentResume = useSelector((state) => state.pos.openMobilePaymentResume);

    const hasPaymentMissing = BigNumber(totalPaid).isLessThan(total);
    const disabled = paying || disabledPosPaymentActions || !hasPaymentMissing || isPaymentLinkPayment(paymentMethod!);

    const updateSelectedPaymentMethod = ({ paymentMethod, customPaymentMethod, paymentReferenceEnabled, mexicanPaymentMethodCode }: SetPosPaymentMethodParams) => {
        setPosPaymentMethod({ customPaymentMethod, paymentMethod, paymentReferenceEnabled, mexicanPaymentMethodCode });
    };

    return (
        <div className={classes.paymentSettings}>
            <div className={classNames(classes.paymentMethodContainer, !openMobilePaymentResume ? '' : classes.hidden)}>
                <PosPaymentCustomersList />
                {!openMobilePaymentResume && (
                    <PosPaymentMethodSelect
                        selectedPaymentMethod={customPaymentMethod ?? paymentMethod}
                        disabled={disabled || isPaymentLinkPayment(paymentMethod!)}
                        onSelect={updateSelectedPaymentMethod}
                    />
                )}
                {!isSmallScreen && <PosCustomerInfo />}
            </div>
            <PosPaymentNumericPad disabled={disabled} />
            {isSmallScreen && <PosCustomerInfo />}
        </div>
    );
});

const useStyles = makeStyles((theme) => ({
    hidden: {
        display: 'none',
    },
    paymentSettings: {
        width: '70%',
        height: '100%',
        maxHeight: 800,
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-evenly',
        [theme.breakpoints.down('sm')]: {
            alignItems: 'center',
            flexDirection: 'column',
        },
        [theme.breakpoints.up('lg')]: {
            width: '75%',
        },
    },
    paymentMethodContainer: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'flex-start',
        maxWidth: 420,
        width: '100%',
        [theme.breakpoints.down('sm')]: {
            padding: '0 24px',
            margin: 0,
            marginTop: 24,
        },
        [theme.breakpoints.up('lg')]: {
            margin: 0,
        },
    },
}));
