import { usePosCustomerStore } from 'src/modules/pos/customer/posCustomerStore';
import { EmailAddress } from 'src/types/Id';

export function useGetCustomerInfo(): () => CustomerInfo {
    const firstName = usePosCustomerStore((state) => state.firstName);
    const lastName = usePosCustomerStore((state) => state.lastName);
    const email = usePosCustomerStore((state) => state.email);
    const mobileNumber = usePosCustomerStore((state) => state.mobileNumber);
    const customerNote = usePosCustomerStore((state) => state.customerNote);
    const numberOfCustomers = usePosCustomerStore((state) => state.numberOfCustomers);

    return () => ({
        firstName: firstName.value,
        lastName: lastName.value,
        email: email.value as any,
        mobileNumber: mobileNumber.value,
        customerNote: customerNote.value,
        numberOfCustomers: numberOfCustomers.value,
    });
}

type CustomerInfo = {
    firstName: string | undefined;
    lastName: string | undefined;
    email: EmailAddress | undefined;
    mobileNumber: string | undefined;
    numberOfCustomers: number | undefined;
    customerNote: string | undefined;
};
