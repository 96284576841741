import { makeStyles } from '@material-ui/core';
import { useState } from 'react';
import * as React from 'react';
import { closePedidosYaRestaurantApi } from 'src/api/letseatmanager/restaurantIntegration/closePedidosYaRestaurantApi';
import { openPedidosYaRestaurantApi } from 'src/api/letseatmanager/restaurantIntegration/openPedidosYaRestaurantApi';
import { Button } from 'src/components/Button';
import type { PedidosYaIntegrationStatus } from 'src/constants/PedidosYaIntegrationStatus';
import { translate } from 'src/i18n/translate';
import pedidosYaLogo from 'src/images/pedidosYa-logo.png';
import { ConfirmDisintegrateFromPedidosYaDialog } from 'src/scenes/letseatadmin/PedidosYaIntegration/ConfirmDisintegrateFromPedidosYaDialog';
import { IntegratePedidosYaDialog } from 'src/scenes/letseatadmin/PedidosYaIntegration/IntegratePedidosYaDialog';
import { ChangePedidosYaIntegrationPercentageDialog } from 'src/scenes/letseatmanager/integrations/ChangePedidosYaIntegrationPercentageDialog';
import { RestaurantId, type PedidosYaRestaurantId } from 'src/types/Id';
import { alertKnownErrorOrSomethingWentWrong } from 'src/utils/alert/alertKnownErrorOrSomethingWentWrong';
import { formatAsPercentage } from 'src/utils/number/formatAsPercentage';
import { useSelector } from 'src/utils/react/useSelector';

export function PedidosYaIntegration({ restaurantPedidosYaIntegrationsStatus, load, disabled }: Props): React.ReactElement {
    const classes = useStyles();

    const restaurantId = useSelector((state) => state.app.restaurantId);
    const viewUser = useSelector((state) => state.authentication.viewUser);

    const [integratePedidosYaDialogState, setIntegratePedidosYaDialogState] = useState<{ open: boolean; restaurantId: RestaurantId | undefined }>({ open: false, restaurantId: undefined });
    const [confirmDesintegrateFromPedidosYaDialogState, setConfirmDesintegrateFromPedidosYaDialogState] = useState(false);
    const [pedidosYaChangePercentageDialogState, setPedidosYaChangePercentageDialogState] = useState({ open: false });

    const [loading, setLoading] = useState(false);

    const closePedidosYaStore = async () => {
        setLoading(true);
        const response = await closePedidosYaRestaurantApi({ restaurantId });
        if (!response.ok) {
            alertKnownErrorOrSomethingWentWrong(response, translate('You can only try opening and closing once a minute.'));
            setLoading(false);
            return;
        }
        setLoading(false);
        load();
    };

    const openPedidosYaStore = async () => {
        setLoading(true);
        const response = await openPedidosYaRestaurantApi({ restaurantId });
        if (!response.ok) {
            alertKnownErrorOrSomethingWentWrong(response, translate('You can only try opening and closing once a minute.'));
            setLoading(false);
            return;
        }
        setLoading(false);
        load();
    };

    const handleClickIntegratePedidosYa = async () => {
        if (restaurantPedidosYaIntegrationsStatus.pedidosYaRestaurantId) {
            setConfirmDesintegrateFromPedidosYaDialogState(true);
            return;
        }
        return setIntegratePedidosYaDialogState({ open: true, restaurantId: restaurantId });
    };

    const handleCloseConfirmDisintegrateFromPedidosYaDialog = () => {
        setConfirmDesintegrateFromPedidosYaDialogState(false);
    };

    const handleChangeIntegrationPercentage = async () => {
        setPedidosYaChangePercentageDialogState({ open: true });
    };

    const handleOnSuccessChangePercentage = () => {
        setPedidosYaChangePercentageDialogState({ open: false });
        load();
    };

    return (
        <>
            <ChangePedidosYaIntegrationPercentageDialog
                open={pedidosYaChangePercentageDialogState.open}
                onClose={() => setPedidosYaChangePercentageDialogState({ open: false })}
                onSuccess={handleOnSuccessChangePercentage}
            />
            <IntegratePedidosYaDialog
                open={integratePedidosYaDialogState.open}
                onClose={() => {
                    setIntegratePedidosYaDialogState({ open: false, restaurantId: undefined });
                    load();
                }}
            />
            <ConfirmDisintegrateFromPedidosYaDialog open={confirmDesintegrateFromPedidosYaDialogState} onClose={handleCloseConfirmDisintegrateFromPedidosYaDialog} disabled={disabled} />
            <div className={classes.integrationContainer}>
                <div className={classes.integrationInfoContainer}>
                    <div className={classes.integrationImageContainer}>
                        <img src={pedidosYaLogo} />
                    </div>
                    <div className={classes.integrationInfo}>
                        <div className={classes.integrationNameContainer}>
                            <span className={classes.integrationName}>PedidosYa</span>
                            <span className={restaurantPedidosYaIntegrationsStatus.pedidosYaRestaurantId ? classes.integrationStatus : classes.notIntengrated}>
                                {restaurantPedidosYaIntegrationsStatus.pedidosYaRestaurantId ? translate('Integrated') : translate('Not integrated')}
                            </span>
                        </div>

                        <div className={classes.integrationPercentageInfoContainer}>
                            <span className={classes.integrationPercentageInfo}>
                                {restaurantPedidosYaIntegrationsStatus.pedidosYaSalesCommissionPercentage ? translate('Commission of') : translate('Without commission')}
                                <strong>
                                    {restaurantPedidosYaIntegrationsStatus.pedidosYaSalesCommissionPercentage
                                        ? formatAsPercentage(restaurantPedidosYaIntegrationsStatus.pedidosYaSalesCommissionPercentage)
                                        : ''}
                                </strong>
                            </span>
                            <Button onClick={handleChangeIntegrationPercentage} disabled={loading || viewUser} text>
                                {translate('Edit')}
                            </Button>
                        </div>

                        {restaurantPedidosYaIntegrationsStatus.pedidosYaRestaurantId && restaurantPedidosYaIntegrationsStatus.pedidosYaRestaurantOnline ? (
                            <span className={classes.integrationStoreStatusOpen}>{translate('Opened')}</span>
                        ) : (
                            <span className={classes.integrationStoreStatusClosed}>{translate('Closed')}</span>
                        )}
                    </div>
                </div>
                <div className={classes.buttonGroup}>
                    <Button
                        classes={{ button: !!restaurantPedidosYaIntegrationsStatus.pedidosYaRestaurantId ? classes.buttonDisintegrate : undefined }}
                        onClick={handleClickIntegratePedidosYa}
                        disabled={loading || disabled || viewUser}
                        primary
                    >
                        {restaurantPedidosYaIntegrationsStatus.pedidosYaRestaurantId ? translate('Disintegrate') : translate('Integrate')}
                    </Button>
                    {restaurantPedidosYaIntegrationsStatus.pedidosYaRestaurantOnline && (
                        <Button classes={{ button: classes.buttonCloseStore }} onClick={closePedidosYaStore} disabled={loading} secondary>
                            {translate('Close Pedidos Ya Store')}
                        </Button>
                    )}
                    {!restaurantPedidosYaIntegrationsStatus.pedidosYaRestaurantOnline && (
                        <Button classes={{ button: classes.buttonCloseStore }} onClick={openPedidosYaStore} disabled={loading} secondary>
                            {translate('Open Pedidos Ya Store')}
                        </Button>
                    )}
                </div>
            </div>
        </>
    );
}

const useStyles = makeStyles((theme) => ({
    integrationContainer: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        width: '100%',
        backgroundColor: theme.palette.secondary.light,
        minHeight: 120,
        marginBottom: 20,
        [theme.breakpoints.down('sm')]: {
            flexDirection: 'column',
            height: 'fit-content',
            paddingTop: 20,
            paddingBottom: 20,
        },
        borderRadius: 8,
        border: '1px solid #D9D9D9',
        background: '#FAFBFB',
        padding: '12px',
    },
    integrationInfoContainer: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        [theme.breakpoints.down('sm')]: {
            width: '100%',
            marginBottom: 10,
            justifyContent: 'space-between',
        },
        gap: '1rem',
    },
    integrationImageContainer: {
        backgroundColor: 'white',
        width: 80,
        height: 80,
        borderRadius: 5,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        marginRight: 20,
    },
    integrationInfo: {
        display: 'flex',
        flexDirection: 'column',
    },
    integrationName: {
        fontFamily: theme.typography.semiBold,
        fontSize: 15,
    },
    integrationStatus: {
        fontFamily: theme.typography.medium,
        fontSize: 13,
    },
    notIntengrated: {
        fontFamily: theme.typography.regular,
        color: '#fa6684',
        fontSize: 14,
    },
    integrationStoreStatusClosed: {
        fontFamily: theme.typography.semiBold,
        color: '#fa6684',
        fontSize: 13,
    },
    integrationStoreStatusOpen: {
        fontFamily: theme.typography.semiBold,
        color: theme.palette.primary.main,
        fontSize: 13,
    },
    buttonDisintegrate: {
        backgroundColor: '#fa6684',
        color: 'white',
        fontFamily: theme.typography.semiBold,
        fontSize: 15,
        borderRadius: 5,
        height: 40,
        width: 200,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        textTransform: 'none',
    },
    buttonGroup: {
        flexDirection: 'row',
        gap: '1rem',
        display: 'flex',
    },
    buttonCloseStore: {
        padding: '4px 16px',
    },
    dialogTitle: {
        textAlign: 'center',
        fontSize: 41,
        color: theme.palette.secondary.contrastText,
        paddingTop: theme.spacing(4),
        paddingBottom: theme.spacing(3),
        '& h2': {
            fontFamily: theme.typography.medium,
        },
    },
    dialogText: {
        fontFamily: theme.typography.regular,
        fontSize: 18,
        textAlign: 'center',
    },
    buttonsContainer: {
        marginTop: 20,
        marginBottom: 20,
        justifyContent: 'center',
        [theme.breakpoints.down('sm')]: {
            flexDirection: 'column',
        },
    },
    acceptButton: {
        backgroundColor: theme.palette.primary.main,
        color: 'white',
        fontFamily: theme.typography.semiBold,
        fontSize: 15,
        borderRadius: 5,
        height: 40,
        width: 200,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        textTransform: 'none',
        boxShadow: `3px 3px 10px ${theme.palette.primary.main}aa`,
    },
    noMargin: {
        margin: '0 !important',
    },
    actionsContainer: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'end',
        [theme.breakpoints.down('sm')]: {
            width: '100%',
            marginBottom: 10,
            justifyContent: 'space-between',
        },
        gap: '1rem',
    },
    integrationLabel: {
        fontFamily: theme.typography.regular,
        color: '#2E3748',
        fontSize: 14,
        fontStyle: 'normal',
        fontWeight: 400,
        lineHeight: 'normal',
    },
    integrationNameContainer: {
        display: 'flex',
        flexDirection: 'column',
    },
    integrationPercentageInfoContainer: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        gap: '1rem',
    },
    integrationPercentageInfo: {
        fontFamily: theme.typography.regular,
        color: '#2E3748',
        fontSize: 14,
        fontStyle: 'normal',
        fontWeight: 400,
        lineHeight: 'normal',
        whiteSpace: 'nowrap',
    },
    link: {
        fontFamily: theme.typography.regular,
        color: '#06B7A2',
        fontSize: 14,
        lineHeight: '31px',
        textDecorationLine: 'underline',
        border: 'none',
        '&:hover': {
            backgroundColor: 'transparent',
            color: '#06B7A2',
            textDecorationLine: 'underline',
        },
    },
    integrated: {
        fontFamily: theme.typography.semiBold,
        color: theme.palette.primary.main,
        fontSize: 14,
    },
}));

type Props = {
    restaurantPedidosYaIntegrationsStatus: {
        pedidosYaRestaurantId?: PedidosYaRestaurantId;
        pedidosYaIntegrationStatus?: PedidosYaIntegrationStatus;
        pedidosYaRestaurantOnline?: boolean;
        pedidosYaSalesCommissionPercentage?: number;
    };
    load: any;
    disabled: boolean;
};
