import { makeStyles } from '@material-ui/core/styles';
import * as React from 'react';
import { DownloadCsvToolbarButton } from 'src/components/mui-datatables/DownloadCsvToolbarButton';
import { Table } from 'src/components/Table';
import { translate } from 'src/i18n/translate';
import { PrintManagerUserTipReportToolbarButton } from 'src/scenes/letseatmanager/posReports/PrintManagerUserTipReportToolbarButton';
import { useFormatAsRestaurantCurrencyNumber } from 'src/services/restaurant/useFormatAsRestaurantCurrencyNumber';
import type { ManagerUserOrdersAndTips } from 'src/types/ManagerUserSalesReportVm';
import { objectsToCsv } from 'src/utils/csv/objectsToCsv';
import { downloadTextInFile } from 'src/utils/html/downloadTextInFile';

export function ManagerUserTipsTableReport({ managerUsersOrdersAndTips }: Props): React.ReactElement {
    const classes = useStyles();
    const formatAsCurrencyNumber = useFormatAsRestaurantCurrencyNumber();

    const downloadReports = () => {
        const cashRegisterCancelledOrdersReport = managerUsersOrdersAndTips.map((managerUserOrdersAndTips) => ({
            consecutiveOrderId: managerUserOrdersAndTips.consecutiveOrderId,
            managerUser: managerUserOrdersAndTips.servedBy,
            date: managerUserOrdersAndTips.date,
            time: managerUserOrdersAndTips.time,
            paymentMethod: managerUserOrdersAndTips.paymentMethod,
            total: formatAsCurrencyNumber(managerUserOrdersAndTips.total),
            tip: formatAsCurrencyNumber(managerUserOrdersAndTips.posTipAmount),
            customerName: managerUserOrdersAndTips.customerName,
        }));
        const csv = objectsToCsv(cashRegisterCancelledOrdersReport);
        downloadTextInFile(csv, 'user-tips-report.csv');
    };

    return (
        <section className={classes.container}>
            <Table
                data={managerUsersOrdersAndTips.map((managerUserOrdersAndTips) => ({
                    managerUser: managerUserOrdersAndTips.servedBy,
                    tip: formatAsCurrencyNumber(managerUserOrdersAndTips.posTipAmount),
                    total: formatAsCurrencyNumber(managerUserOrdersAndTips.total),
                    consecutiveOrderId: managerUserOrdersAndTips.consecutiveOrderId,
                    date: managerUserOrdersAndTips.date,
                    time: managerUserOrdersAndTips.time,
                    paymentMethod: managerUserOrdersAndTips.paymentMethod,
                    customerName: managerUserOrdersAndTips.customerName,
                }))}
                columns={[
                    {
                        name: 'consecutiveOrderId',
                        label: translate('Order'),
                        options: {
                            filter: false,
                        },
                    },
                    {
                        name: 'date',
                        label: translate('Date'),
                        options: {
                            filter: false,
                        },
                    },
                    {
                        name: 'time',
                        label: translate('Hour'),
                        options: {
                            filter: false,
                        },
                    },
                    {
                        name: 'managerUser',
                        label: translate('User'),
                        options: {
                            filter: false,
                        },
                    },
                    {
                        name: 'paymentMethod',
                        label: translate('Payment method'),
                        options: {
                            filter: false,
                        },
                    },
                    {
                        name: 'tip',
                        label: translate('Tip'),
                        options: {
                            filter: false,
                        },
                    },
                    {
                        name: 'total',
                        label: translate('Net Sales'),
                        options: {
                            filter: false,
                        },
                    },
                    {
                        name: 'customerName',
                        label: translate('Customer Name'),
                        options: {
                            filter: false,
                        },
                    },
                ]}
                options={{
                    responsive: 'standard',
                    selectableRows: 'none',
                    filter: false,
                    search: false,
                    download: false,
                    print: true,
                    rowsPerPage: 10,
                    customToolbar: () => (
                        <>
                            <DownloadCsvToolbarButton onClick={downloadReports} />
                            <PrintManagerUserTipReportToolbarButton />
                        </>
                    ),
                }}
            />
        </section>
    );
}

type Props = {
    managerUsersOrdersAndTips: Array<ManagerUserOrdersAndTips>;
};

const useStyles = makeStyles((theme) => ({
    container: {
        maxHeight: 500,
        overflowY: 'scroll',
    },
}));
