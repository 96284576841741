import Grid from '@material-ui/core/Grid';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { app2 } from 'src/app2';
import { translate } from 'src/i18n/translate';
import CustomerDetails, { CustomerVm } from 'src/scenes/letseatadmin/customer/CustomerDetails';
import { DeliveryManager } from 'src/scenes/letseatadmin/deliveryManager/DeliveryManager';
import OrderDetails from 'src/scenes/letseatadmin/order/OrderDetails';
import { OrderLogEvents } from 'src/scenes/letseatadmin/order/OrderLogEvents';
import UberDirectDetailsCard from 'src/scenes/letseatadmin/order/UberDirectDetailsCard';
import { PaymentDetails } from 'src/scenes/letseatadmin/payment/PaymentDetails';
import RestaurantDetails, { RestaurantVm } from 'src/scenes/letseatadmin/restaurant/RestaurantDetails';
import { AdminOrderVm } from 'src/types/AdminOrderVm';
import type { OrderId } from 'src/types/Id';
import { fullName } from 'src/utils/customer/fullName';
import { formatDateTimeStringReadable } from 'src/utils/date/formatDateTimeStringReadable';
import { useAction } from 'src/utils/react/useAction';
import { useParams } from 'src/utils/react/useParams';

export function OrderPage(): React.ReactElement {
    const { orderId } = useParams<{
        orderId: OrderId;
    }>();
    const [order, setOrder] = useState<AdminOrderVm | undefined>(undefined);
    const [customer, setCustomer] = useState(undefined as CustomerVm | undefined);
    const [restaurant, setRestaurant] = useState(undefined as RestaurantVm | undefined);
    const setTitle = useAction(app2.actions.setTitle);

    useEffect(() => {
        if (!order || !restaurant || !customer) {
            setTitle(`${translate('Orders')} > ${orderId.substring(0, 8)}`);
            return;
        }
        setTitle(`${translate('Orders')} > ${restaurant.name} - ${formatDateTimeStringReadable(order.createdAt, order.timeZone)} - ${fullName(customer as any)}`);
    }, [order, customer, restaurant]);

    return (
        <Grid container spacing={5} style={{ paddingBottom: 40 }}>
            <Grid item xs={12}>
                <OrderDetails orderId={orderId} onChangeOrder={(order) => setOrder(order)} />
            </Grid>
            {order?.paidWithCard && (
                <Grid item xs={12}>
                    <PaymentDetails paymentId={order?.paymentId} />
                </Grid>
            )}
            <Grid item xs={12}>
                <RestaurantDetails restaurantId={order?.restaurantId} onChangeRestaurant={(restaurant) => setRestaurant(restaurant)} />
            </Grid>
            <Grid item xs={12}>
                <CustomerDetails customerId={order?.customerId} onChangeCustomer={(customer) => setCustomer(customer)} />
            </Grid>
            <Grid item xs={12}>
                <DeliveryManager orderId={order?.orderId} />
            </Grid>

            <Grid item xs={12}>
                <OrderLogEvents />
            </Grid>

            {order?.uberEatsOrderTrackingUrl && (
                <Grid item xs={12}>
                    <UberDirectDetailsCard uberEatsOrderTrackingUrl={order.uberEatsOrderTrackingUrl} externalOrderId={order?.externalOrderId} driver={order.driver} />
                </Grid>
            )}
        </Grid>
    );
}
