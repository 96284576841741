import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import Chip from '@material-ui/core/Chip';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import * as React from 'react';
import { useState } from 'react';
import type { DeliveryType } from 'src/api/appsyncApi';
import { app2 } from 'src/app2';
import { Field, Form } from 'src/components/deprecated/react-final-form/Form';
import TextField from 'src/components/deprecated/react-final-form/TextField';
import { SendNotificationToolbarButton } from 'src/components/mui-datatables/SendNotificationToolbarButton';
import { UpdatingContentProgress } from 'src/components/UpdatingContentProgress';
import { history } from 'src/config/history';
import { DeliveryStatus, DeliveryStatuses } from 'src/constants/DeliveryStatus';
import { DeliveryTypes } from 'src/constants/DeliveryType';
import { TimeZones } from 'src/constants/TimeZone';
import { translate } from 'src/i18n/translate';
import { SEND_NOTIFICATION } from 'src/scenes/letseatadmin/driver/DriverSendDialog';
import type { GeoJsonPoint } from 'src/types/GeoJsonPoint';
import type { AddressId, DeliveryId, DriverBonusId, DriverBonusOfferId, DriverId, GooglePlaceId, OrderId } from 'src/types/Id';
import { formatDateTimeSecondsString } from 'src/utils/date/formatDateTimeSecondsString';
import { formatDateTimeStringReadable } from 'src/utils/date/formatDateTimeStringReadable';
import { formatTimeSecondsString } from 'src/utils/date/formatTimeSecondsString';
import { setUrlQueryParameter } from 'src/utils/history/setUrlQueryParameter';
import { useAction } from 'src/utils/react/useAction';
import { openDriverPageInNewTab } from 'src/utils/window/openDriverPageInNewTab';

export function Delivery({ delivery }: Props): React.ReactElement {
    const classes = useStyles();
    const [loading, setLoading] = useState(false);
    const openCancelDeliveryDialog = useAction(app2.actions.openCancelDeliveryDialog);

    return (
        <div style={{ position: 'relative' }}>
            <UpdatingContentProgress loading={loading} />
            <Card classes={{ root: classes.root }}>
                <CardHeader
                    title={
                        <Typography display='block' gutterBottom variant='h6'>
                            {delivery.driverName} {delivery.deliveryType !== DeliveryTypes.NORMAL && <Chip label={delivery.deliveryType} variant='default' />}
                        </Typography>
                    }
                    action={
                        <div>
                            <SendNotificationToolbarButton onClick={() => history.push({ search: setUrlQueryParameter(SEND_NOTIFICATION, delivery.driverId) })} />
                            {(delivery.deliveryStatus === DeliveryStatuses.ACCEPTED || delivery.deliveryStatus === DeliveryStatuses.PICKED_UP) && (
                                <Button color='primary' disabled={loading} onClick={() => openCancelDeliveryDialog({ deliveryId: delivery.deliveryId, driverId: delivery.driverId })}>
                                    {translate('Cancel Delivery')}
                                </Button>
                            )}
                            <Button color='primary' disabled={loading} onClick={() => openDriverPageInNewTab(delivery.driverId)}>
                                {translate('Open Driver')}
                            </Button>
                        </div>
                    }
                />
                <CardContent>
                    <Form
                        onSubmit={() => {}}
                        initialValues={
                            delivery
                                ? {
                                      delivery: {
                                          deliveryId: delivery.deliveryId,
                                          orderId: delivery.orderId,
                                          driverId: delivery.driverId,
                                          deliveryStatus: delivery.deliveryStatus,
                                          positionOnRequest: delivery.positionOnRequest,
                                          positionedAtOnRequest: formatDateTimeStringReadable(delivery.positionedAtOnRequest),
                                          requestedAt: formatDateTimeStringReadable(delivery.requestedAt),
                                          rejectedAt: formatTimeSecondsString(delivery.rejectedAt),
                                          acceptedAt: formatTimeSecondsString(delivery.acceptedAt),
                                          deliveredAt: formatTimeSecondsString(delivery.deliveredAt),
                                          cancelledAt: formatTimeSecondsString(delivery.cancelledAt),
                                          withdrawnAt: formatTimeSecondsString(delivery.withdrawnAt),
                                          cancelReason: delivery.cancelReason,
                                          customerFirstName: delivery.customerFirstName,
                                          customerLastName: delivery.customerLastName,
                                          customerMobileNumber: delivery.customerMobileNumber,
                                          customerAddressId: delivery.customerAddressId,
                                          customerAddressLocation: delivery.customerAddressLocation,
                                          customerAddressStreet: delivery.customerAddressStreet,
                                          customerAddressOther: delivery.customerAddressOther,
                                          customerAddressInstructions: delivery.customerAddressInstructions,
                                          customerAddressGooglePlaceId: delivery.customerAddressGooglePlaceId,
                                          customerAddressGooglePlaceIdV2: delivery.customerAddressGooglePlaceIdV2,
                                          customerAddressFormattedAddress: delivery.customerAddressFormattedAddress,
                                          restaurantName: delivery.restaurantName,
                                          restaurantStreet: delivery.restaurantStreet,
                                          restaurantPhoneNumber: delivery.restaurantPhoneNumber,
                                          restaurantLocation: delivery.restaurantLocation,
                                          deliveryCost: delivery.deliveryCost,
                                          deliveryEarnings: delivery.deliveryEarnings,
                                      },
                                  }
                                : {}
                        }
                        render={({ handleSubmit, submitting, pristine, values }) => (
                            <>
                                <Grid container spacing={3}>
                                    {/*deliveryId*/}
                                    {/*orderId*/}
                                    {/*driverId*/}
                                    {/*deliveryStatus*/}
                                    {/*positionOnRequest*/}
                                    {/*positionedAtOnRequest*/}

                                    {/*requestedAt*/}
                                    {/*   rejectedAt*/}
                                    {/*   acceptedAt*/}
                                    {/*   deliveredAt*/}
                                    {/*   cancelledAt*/}
                                    {/*   withdrawnAt*/}

                                    {/*customerFirstName*/}
                                    {/*customerLastName*/}
                                    {/*customerMobileNumber*/}
                                    {/*customerAddressId*/}
                                    {/*customerAddressLocation*/}
                                    {/*customerAddressStreet*/}
                                    {/*customerAddressOther*/}
                                    {/*customerAddressInstructions*/}
                                    {/*customerAddressGooglePlaceId*/}
                                    {/*customerAddressFormattedAddress*/}
                                    {/*restaurantName*/}
                                    {/*restaurantStreet*/}
                                    {/*restaurantPhoneNumber*/}
                                    {/*restaurantLocation*/}
                                    {/*deliveryCost*/}
                                    {/*deliveryEarnings*/}

                                    <Grid item xs={12} sm={2}>
                                        <Field name='delivery.deliveryStatus' component={TextField} label={translate('Status')} fullWidth />
                                    </Grid>

                                    {!!delivery.requestedAt && (
                                        <Grid item xs={12} sm={2}>
                                            <Field
                                                name='delivery.requestedAt'
                                                component={TextField}
                                                label={translate('Requested At')}
                                                tooltip={formatDateTimeSecondsString(delivery.requestedAt, TimeZones.ETC_UTC)}
                                                fullWidth
                                            />
                                        </Grid>
                                    )}

                                    {!!delivery.rejectedAt && (
                                        <Grid item xs={12} sm={2}>
                                            <Field
                                                name='delivery.rejectedAt'
                                                component={TextField}
                                                label={translate('Rejected At')}
                                                tooltip={formatDateTimeSecondsString(delivery.rejectedAt, TimeZones.ETC_UTC)}
                                                fullWidth
                                            />
                                        </Grid>
                                    )}

                                    {!!delivery.acceptedAt && (
                                        <Grid item xs={12} sm={2}>
                                            <Field
                                                name='delivery.acceptedAt'
                                                component={TextField}
                                                label={translate('Accepted At')}
                                                tooltip={formatDateTimeSecondsString(delivery.acceptedAt, TimeZones.ETC_UTC)}
                                                fullWidth
                                            />
                                        </Grid>
                                    )}

                                    {!!delivery.deliveredAt && (
                                        <Grid item xs={12} sm={2}>
                                            <Field
                                                name='delivery.deliveredAt'
                                                component={TextField}
                                                label={translate('Delivered At')}
                                                tooltip={formatDateTimeSecondsString(delivery.deliveredAt, TimeZones.ETC_UTC)}
                                                fullWidth
                                            />
                                        </Grid>
                                    )}

                                    {!!delivery.cancelledAt && (
                                        <Grid item xs={12} sm={2}>
                                            <Field
                                                name='delivery.cancelledAt'
                                                component={TextField}
                                                label={translate('Cancelled At')}
                                                tooltip={formatDateTimeSecondsString(delivery.cancelledAt, TimeZones.ETC_UTC)}
                                                fullWidth
                                            />
                                            {delivery.cancelReason && <Field name='delivery.cancelReason' component={TextField} disabled={loading} label={translate('Cancel Reason')} fullWidth />}
                                        </Grid>
                                    )}

                                    {!!delivery.withdrawnAt && (
                                        <Grid item xs={12} sm={2}>
                                            <Field
                                                name='delivery.withdrawnAt'
                                                component={TextField}
                                                label={translate('Withdrawn At')}
                                                tooltip={formatDateTimeSecondsString(delivery.withdrawnAt, TimeZones.ETC_UTC)}
                                                fullWidth
                                            />
                                        </Grid>
                                    )}

                                    {(delivery.deliveryStatus === DeliveryStatuses.ACCEPTED ||
                                        delivery.deliveryStatus === DeliveryStatuses.PICKED_UP ||
                                        delivery.deliveryStatus === DeliveryStatuses.DELIVERED ||
                                        delivery.deliveryStatus === DeliveryStatuses.CANCELLED) && (
                                        <Grid item xs={12} sm={2}>
                                            <Field name='delivery.deliveryCost' component={TextField} label={translate('Delivery Cost')} fullWidth />
                                        </Grid>
                                    )}

                                    {(delivery.deliveryStatus === DeliveryStatuses.ACCEPTED ||
                                        delivery.deliveryStatus === DeliveryStatuses.PICKED_UP ||
                                        delivery.deliveryStatus === DeliveryStatuses.DELIVERED ||
                                        delivery.deliveryStatus === DeliveryStatuses.CANCELLED) && (
                                        <Grid item xs={12} sm={2}>
                                            <Field name='delivery.chargeCustomer' component={TextField} label={translate('Charge Customer')} fullWidth />
                                        </Grid>
                                    )}

                                    {(delivery.deliveryStatus === DeliveryStatuses.ACCEPTED ||
                                        delivery.deliveryStatus === DeliveryStatuses.PICKED_UP ||
                                        delivery.deliveryStatus === DeliveryStatuses.DELIVERED ||
                                        delivery.deliveryStatus === DeliveryStatuses.CANCELLED) && (
                                        <Grid item xs={12} sm={2}>
                                            <Field name='delivery.deliveryEarnings' component={TextField} label={translate('Driver Earns')} fullWidth />
                                        </Grid>
                                    )}
                                </Grid>
                            </>
                        )}
                    />
                </CardContent>
            </Card>
        </div>
    );
}

const useStyles = makeStyles((theme) => ({
    linearProgress: {
        position: 'absolute',
        width: '100%',
        bottom: 0,
    },
    root: {
        paddingLeft: 8,
        paddingRight: 8,
    },
}));

type Props = {
    delivery: DeliveryVm;
};

export type DeliveryVm = {
    deliveryId: DeliveryId;
    orderId: OrderId;
    driverId: DriverId;
    deliveryStatus: DeliveryStatus;
    deliveryType: DeliveryType;
    positionOnRequest?: GeoJsonPoint;
    positionedAtOnRequest?: Date;
    requestedAt: Date;
    rejectedAt?: Date;
    acceptedAt?: Date;
    arrivedAtStoreAt?: Date;
    pickedUpAt?: Date;
    arrivedAtClientAt?: Date;
    deliveredAt?: Date;
    cancelledAt?: Date;
    withdrawnAt?: Date;
    cancelReason?: string;
    customerFirstName: string;
    customerLastName: string;
    customerMobileNumber: string;
    customerAddressId?: AddressId;
    customerAddressLocation: GeoJsonPoint;
    customerAddressStreet: string;
    customerAddressOther?: string;
    customerAddressInstructions?: string;
    customerAddressGooglePlaceId: GooglePlaceId;
    customerAddressGooglePlaceIdV2?: GooglePlaceId;
    customerAddressFormattedAddress?: string;
    driverName: string;
    restaurantName: string;
    restaurantStreet?: string;
    restaurantPhoneNumber: string;
    restaurantLocation: GeoJsonPoint;
    deliveryCost?: string;
    deliveryEarnings?: string;
    driverBonusIds?: Array<DriverBonusId>;
    driverBonuses: Array<{
        driverBonusId: DriverBonusId;
        driverBonusOfferId: DriverBonusOfferId;
        name?: string;
        description?: string;
        deliveries: number;
        deliveriesRequired: number;
    }>;
    drivingDistance: number;
    paymentMethodCash: boolean;
    payRestaurant?: string;
    chargeCustomer?: string;
    pickupTime: Date;
    preparationTime: number;
};
