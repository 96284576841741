import { makeStyles } from '@material-ui/core';
import { BigNumber } from 'bignumber.js';
import moment from 'moment';
import * as React from 'react';
import { Chip } from 'src/components/Chip';
import { SimpleTable } from 'src/components/SimpleTable';
import { translate } from 'src/i18n/translate';
import { PosCashRegisterTransactionCard } from 'src/scenes/letseatmanager/posCashRegister/PosCashRegisterTransactionCard';
import { useFormatAsRestaurantCurrencyNumber } from 'src/services/restaurant/useFormatAsRestaurantCurrencyNumber';
import type { CashRegisterTransactionVm } from 'src/types/CashRegisterTransactionVm';
import { useSelector } from 'src/utils/react/useSelector';
import { translateCashRegisterDepositReasons } from 'src/utils/translate/translateCashRegisterDepositReasons';
import { translateCashRegisterWithdrawReasons } from 'src/utils/translate/translateCashRegisterWithdrawReasons';

export function PosCashRegisterTransactions({ transactions }: Props): React.ReactElement | null {
    const classes = useStyles();
    const formatAsCurrencyNumber = useFormatAsRestaurantCurrencyNumber();

    const openedPosBusinessDay = useSelector((state) => state.pos.openedPosBusinessDay);
    const posMultipleCashRegistersEnabled = useSelector((state) => state.app.restaurant?.posMultipleCashRegistersEnabled);
    const openedCashRegister = useSelector((state) => state.pos?.openedCashRegister);

    const columns = [
        {
            id: 'movement',
            content: translate('Movement'),
        },
        {
            id: 'responsible',
            content: translate('Responsible'),
        },
        {
            id: 'reason',
            content: translate('Reason'),
        },
        {
            id: 'amount',
            content: translate('Amount'),
        },
        {
            id: 'date',
            content: translate('Date'),
        },
        {
            id: 'comment',
            content: translate('Comment'),
            size: 1.5,
        },
    ];

    if (posMultipleCashRegistersEnabled) {
        columns.push({
            id: 'cashRegisterPosBusinessDayNumber',
            content: translate('Cash Register'),
        });
    }

    if (!transactions?.length) return null;

    return (
        <div className={classes.container}>
            <p className={classes.title}>{translate('Cash register movements')}</p>
            <p className={classes.date}>{moment(openedCashRegister?.openedAt ?? openedPosBusinessDay?.openedAt).format('LLLL')}</p>
            <section className={classes.cardContainer}>
                {transactions.map((transaction) => (
                    <PosCashRegisterTransactionCard key={transaction.cashRegisterTransactionId} cashRegisterTransaction={transaction} />
                ))}
            </section>
            <SimpleTable
                classes={{ table: classes.table }}
                columns={columns}
                rows={
                    transactions?.map((transaction: CashRegisterTransactionVm) => {
                        const isDeposit = BigNumber(transaction?.amount ?? 0).isPositive();
                        const movement = isDeposit ? translate('Deposited') : translate('Withdrawed');
                        return {
                            responsible: transaction.username ?? '',
                            cashRegisterPosBusinessDayNumber: transaction.cashRegisterPosBusinessDayNumber ?? '',
                            date: moment(transaction.createdAt).format('lll'),
                            movement: (
                                <Chip variant={isDeposit ? 'SUCCESS' : 'PENDING'} classes={{ chip: classes.chipMovement }}>
                                    {movement}
                                </Chip>
                            ),
                            reason: isDeposit ? translateCashRegisterDepositReasons(transaction.reason) : translateCashRegisterWithdrawReasons(transaction.reason),
                            amount: formatAsCurrencyNumber(transaction.amount),
                            comment: transaction.comment ?? '',
                        };
                    }) ?? []
                }
            />
        </div>
    );
}

const useStyles = makeStyles((theme) => ({
    container: {
        width: '100%',
        margin: '50px 0',
    },
    title: {
        color: theme.palette.text.primary,
        fontFamily: theme.typography.semiBold,
        fontSize: 20,
    },
    date: {
        fontFamily: theme.typography.regular,
        fontSize: 12,
        color: '#616B79',
        [theme.breakpoints.up('sm')]: {
            display: 'none',
        },
    },
    table: {
        display: 'none',
        [theme.breakpoints.up('md')]: {
            display: 'flex',
        },
    },
    chipMovement: {
        height: 30,
        width: '100%',
        borderRadius: 6,
        padding: '5px 0',
        textAlign: 'center',
        margin: '0 10px',
    },
    cardContainer: {
        width: '100%',
        display: 'grid',
        gridTemplateColumns: '1fr',
        gap: 20,
        margin: '30px 0',
        [theme.breakpoints.up('md')]: {
            display: 'none',
        },
    },
}));

type Props = {
    transactions: Array<CashRegisterTransactionVm>;
};
