import * as React from 'react';

export function ChemistryIcon({ color }: Props): React.ReactElement {
    return (
        <svg width='41' height='37' viewBox='0 0 41 37' fill='none' xmlns='http://www.w3.org/2000/svg'>
            <path
                fillRule='evenodd'
                clipRule='evenodd'
                d='M17.6875 3.12126V12.5338C17.6872 14.0255 17.0943 15.456 16.0394 16.5106L11.5825 20.9675C14.877 20.7353 18.1735 21.3912 21.1281 22.8669C24.4434 24.5252 28.2412 24.948 31.84 24.0594L32.3744 23.9263L24.9606 16.5106C23.9057 15.456 23.3128 14.0255 23.3125 12.5338V3.12126C21.4394 3.00204 19.5606 3.00204 17.6875 3.12126ZM26.125 3.38001C26.485 3.38328 26.8326 3.24833 27.0961 3.00295C27.3596 2.75758 27.5189 2.42049 27.5413 2.06113C27.5637 1.70177 27.4473 1.34753 27.2163 1.07139C26.9852 0.795258 26.657 0.618266 26.2994 0.576888C24.3756 0.336915 22.4387 0.217307 20.5 0.218763C18.5613 0.218328 16.6245 0.337932 14.7006 0.576888C14.343 0.618266 14.0148 0.795258 13.7837 1.07139C13.5527 1.34753 13.4363 1.70177 13.4587 2.06113C13.4811 2.42049 13.6404 2.75758 13.9039 3.00295C14.1674 3.24833 14.515 3.38328 14.875 3.38001V12.5338C14.8743 13.2794 14.5776 13.9943 14.05 14.5213L2.25063 26.3225C-0.786873 29.3619 0.557502 34.7131 5.00875 35.4725C10.045 36.335 15.2219 36.7813 20.5 36.7813C25.78 36.7813 30.955 36.3331 35.9913 35.4744C40.4406 34.7131 41.7888 29.3619 38.7475 26.3225L26.95 14.5231C26.6885 14.262 26.4811 13.9519 26.3395 13.6105C26.198 13.2692 26.1251 12.9033 26.125 12.5338V3.38001Z'
                fill={color ?? 'currentColor'}
            />
        </svg>
    );
}

type Props = {
    color?: string;
};
