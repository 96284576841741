import * as React from 'react';
import { Page } from 'src/components/Page';
import { Tabs } from 'src/components/Tabs';
import { translate } from 'src/i18n/translate';
import { DeviceGroups } from 'src/scenes/letseatmanager/device/deviceGroups/DeviceGroups';
import { Devices } from 'src/scenes/letseatmanager/device/devices/Devices';
import { PaymentTerminals } from 'src/scenes/letseatmanager/device/paymentTerminals/PaymentTerminals';
import { PrintersPage } from 'src/scenes/letseatmanager/PrintersPage';

export function DevicesPage(): React.ReactElement {
    const tabs = [
        {
            label: translate('Devices'),
            value: '1',
        },
        {
            label: translate('Payment Terminals'),
            value: '2',
        },
        {
            label: translate('Printers'),
            value: '3',
        },
        {
            label: translate('Device groups'),
            value: '4',
        },
    ];

    return (
        <Page title={translate('Devices')}>
            <Tabs tabs={tabs}>
                <Devices />
                <PaymentTerminals />
                <PrintersPage />
                <DeviceGroups />
            </Tabs>
        </Page>
    );
}
