import { setPrintersInLocalStorage } from 'src/localStorage/setPrintersInLocalStorage';
import type { PrinterVm } from 'src/types/PrinterVm';

export const KEY_NAME = 'printers';

export function getPrintersInLocalStorage(): Array<PrinterVm> {
    const item = window.localStorage.getItem(KEY_NAME);
    let printers = item ? JSON.parse(item) : [];

    if (!printers.length) {
        const localStoragePrinter = localStorage.getItem('printer');
        if (localStoragePrinter) {
            const printer = JSON.parse(localStoragePrinter);
            printers = [printer];
            setPrintersInLocalStorage(printers);
        }
    }
    return printers;
}
