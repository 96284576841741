import { Grid, makeStyles, Typography } from '@material-ui/core';
import * as React from 'react';
import { Button } from 'src/components/Button';
import { Dialog } from 'src/components/Dialog';
import { DialogActions } from 'src/components/DialogActions';
import { translate } from 'src/i18n/translate';
import { useFormatAsRestaurantCurrencyNumber } from 'src/services/restaurant/useFormatAsRestaurantCurrencyNumber';

export function ProductionSummaryDialog({ open, onClose, suppliesProductionSummary, onSubmit, disabled }: Props): React.ReactElement | null {
    const classes = useStyles();
    const formatAsCurrencyNumber = useFormatAsRestaurantCurrencyNumber();

    if (!suppliesProductionSummary) return null;

    const total = suppliesProductionSummary.reduce((acc, supply) => acc + Number(supply.totalAmount), 0);

    return (
        <Dialog open={open} onClose={onClose} classes={{ dialog: classes.dialogContainer }}>
            <h1 className={classes.title}>{translate('Resumen de producción')}</h1>

            <Grid container spacing={2} direction='column'>
                <Grid item>
                    <h1 className={classes.subTitle}>{translate('Insumos compuestos producidos')}</h1>
                </Grid>

                <Grid item className={classes.recipeSuppliesContainer}>
                    <Grid container>
                        <Grid item xs={12}>
                            <table className={classes.table}>
                                <tbody>
                                    {suppliesProductionSummary.map((supply, index) => (
                                        <tr key={index}>
                                            <td>{`${supply.supplyName}`}</td>
                                            <td>{`${supply.quantity} ${translate(supply.sellUnit)}`}</td>
                                            <td>{formatAsCurrencyNumber(supply.totalAmount)}</td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            <Grid container spacing={2} className={classes.buyOrderTotalContainer}>
                <Grid item md={6} className={classes.buyOrderTotal}>
                    <Typography variant='h6'>{translate('Supplies: @articles', { articles: suppliesProductionSummary.length })}</Typography>
                    <hr className={classes.verticalSeparator} />
                    <Typography variant='h6'>{translate('Total: @total', { total: formatAsCurrencyNumber(total) })}</Typography>
                </Grid>
            </Grid>

            <DialogActions className={classes.dialogActions}>
                <Button classes={{ button: classes.secondaryButton }} onClick={onClose}>
                    {translate('Cancel')}
                </Button>
                <Button classes={{ button: classes.acceptButton }} onClick={onSubmit} disabled={disabled}>
                    {translate('Confirm')}
                </Button>
            </DialogActions>
        </Dialog>
    );
}

const useStyles = makeStyles((theme) => ({
    dialogContainer: {
        width: '80%',
        maxHeight: '95%',
        height: 'fit-content',
        position: 'relative',
        maxWidth: 800,
    },
    title: {
        fontFamily: theme.typography.medium,
        fontSize: 26,
        fontWeight: 600,
        marginBottom: 50,
        color: '#0FA489',
        textAlign: 'center',
        marginTop: 50,
    },
    subTitle: {
        fontFamily: theme.typography.medium,
        fontSize: 20,
        fontWeight: 400,
        textAlign: 'left',
    },
    acceptButton: {
        backgroundColor: theme.palette.primary.dark,
        color: 'white',
        fontFamily: theme.typography.semiBold,
        fontSize: 16,
        borderRadius: 5,
        height: 40,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        textTransform: 'none',
        marginTop: 30,
        transition: 'background-color 0.3s ease',
        '&:hover': {
            backgroundColor: theme.palette.primary.main,
        },
        border: 'none',
        cursor: 'pointer',
    },
    secondaryButton: {
        backgroundColor: 'white',
        color: theme.palette.primary.main,
        fontFamily: theme.typography.semiBold,
        fontSize: 16,
        borderRadius: 5,
        height: 40,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        textTransform: 'none',
        marginTop: 30,
        transition: 'background-color 0.3s ease',
        '&:hover': {
            backgroundColor: theme.palette.secondary.light,
            color: 'white',
        },
        border: `1px solid ${theme.palette.primary.main}`,
    },
    addSupplyButton: {
        backgroundColor: 'white',
        color: theme.palette.primary.main,
        border: `1px solid ${theme.palette.primary.main}`,
        fontFamily: theme.typography.semiBold,
        fontSize: 15,
        borderRadius: 5,
        height: 30,
        padding: '5px 15px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        textTransform: 'none',
    },
    supplyContainer: {
        width: '100%',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        padding: '5px 15px',
        borderBottom: `1px solid ${theme.palette.secondary.dark}`,
    },
    recipeSupplyName: {
        fontFamily: theme.typography.regular,
        fontSize: 15,
        width: '50%',
    },
    recipeSupplyPrice: {
        fontFamily: theme.typography.regular,
        fontSize: 15,
        width: '20%',
    },
    recipeSupplyButton: {
        fontFamily: theme.typography.regular,
        fontSize: 15,
        width: '10%',
    },
    icon: {
        width: 100,
        height: 100,
        color: theme.palette.primary.main,
    },
    formContainer: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'right',
    },
    dialogActions: {
        width: '100%',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        padding: '20px 10px 20px 10px',
        marginTop: 50,
        justifyContent: 'flex-end',
    },
    buyOrderTotalContainer: {
        marginTop: 20,
        display: 'flex',
        justifyContent: 'flex-end',
    },
    buyOrderTotal: {
        display: 'flex',
        justifyContent: 'space-around',
        alignItems: 'center',
        border: `0.1px solid ${theme.palette.secondary.dark}`,
        borderRadius: 8,
        padding: '5 !important',
    },
    buyOrderTotalButtons: {
        marginTop: 20,
        justifyContent: 'flex-end',
        alignItems: 'baseline',
    },
    recipeSuppliesContainer: {
        maxHeight: 200,
        overflowY: 'scroll',
    },
    table: {
        width: '100%',
        borderCollapse: 'collapse',
        '& td': {
            border: `0.1px solid ${theme.palette.secondary.dark}`,
            padding: 10,
        },
        '& th': {
            border: `0.1px solid ${theme.palette.secondary.dark}`,
            padding: 10,
        },
    },
    verticalSeparator: {
        border: `0.1px solid ${theme.palette.secondary.dark}`,
        borderLeft: `1px solid ${theme.palette.secondary.dark}`,
        height: 20,
    },
}));

type SuppliesProductionSummary = {
    supplyName: string;
    quantity: string;
    supplyId: string;
    sellUnit: string;
    totalAmount: number;
    hasEnoughStock: undefined;
};

type Props = {
    open: boolean;
    onClose: any;
    suppliesProductionSummary: Array<SuppliesProductionSummary>;
    onSubmit: any;
    disabled?: boolean;
};
