import * as React from 'react';

export function ClosedBoxIcon(): React.ReactElement {
    return (
        <svg width='35' height='36' viewBox='0 0 35 36' fill='none' xmlns='http://www.w3.org/2000/svg'>
            <path
                d='M3.96094 2.57031L0 10.5H16.25V0.5H7.32031C5.89844 0.5 4.60156 1.30469 3.96094 2.57031ZM18.75 10.5H35L31.0391 2.57031C30.3984 1.30469 29.1016 0.5 27.6797 0.5H18.75V10.5ZM35 13H0V30.5C0 33.2578 2.24219 35.5 5 35.5H30C32.7578 35.5 35 33.2578 35 30.5V13Z'
                fill='#4ABC99'
            />
        </svg>
    );
}
