import { letseatmanagerApiMethod } from 'src/api/letseatmanagerApi';
import type { ApiSauceResponse } from 'src/api/types/ApiSauceResponse';
import type { PaymentTerminalId } from 'src/types/Id';
import type { PaymentTerminalVm } from 'src/types/PaymentTerminalVm';

export async function getPaymentTerminalApi(request: GetPaymentTerminalApiRequest): ApiSauceResponse<PaymentTerminalVm> {
    return letseatmanagerApiMethod('paymentTerminal/getPaymentTerminalApi', request);
}

export type GetPaymentTerminalApiRequest = {
    paymentTerminalId: PaymentTerminalId;
};
