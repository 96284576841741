import * as React from 'react';
import { useEffect, useState } from 'react';
import { FormSelect } from 'src/components/form/FormSelect';
import type { Variant } from 'src/components/form/FormTypes';
import TheMenuActions from 'src/scenes/letseatmanager/deprecatedMenu/TheMenuReducer';
import { useMenus } from 'src/services/menu/useMenus';
import { useMenuCategories } from 'src/services/menuCategory/useMenuCategories';
import type { MenuCategoryVm as DeprecatedMenuCategoryVm } from 'src/types/DeprecatedMenuCategoryVm';
import { MenuCategoryId, type MenuId } from 'src/types/Id';
import type { MenuCategoryVm } from 'src/types/MenuCategoryVm';
import { useAction } from 'src/utils/react/useAction';
import { useSelector } from 'src/utils/react/useSelector';

export function FormCategorySelect({ name, label, placeholder, helperText, defaultValue, disabled, required, variant, menuId }: Props): React.ReactElement {
    const { menus, deprecatedMenus } = useMenus();
    const { menuCategories, deprecatedMenuCategories } = useMenuCategories();

    const menuCategoryOptionsInitialState = [...menuCategories, ...deprecatedMenuCategories].map((menuCategory) => ({ label: menuCategory.name, value: menuCategory.menuCategoryId }));

    const [menuCategoryOptions, setMenuCategoryOptions] = useState<Array<{ label: string; value: MenuCategoryId | null }>>(menuCategoryOptionsInitialState);

    const newMenuManagementEnabled = useSelector((state) => state.app.restaurant.newMenuManagementEnabled);

    const fetch = useAction(TheMenuActions.fetch);

    useEffect(() => {
        handleMenuCategoryOptions();
    }, [menuId, menus, menuCategories, deprecatedMenuCategories, required]);

    useEffect(() => {
        if (!newMenuManagementEnabled && deprecatedMenus.length === 0) fetch();
    }, [newMenuManagementEnabled, deprecatedMenus]);

    const handleMenuCategoryOptions = () => {
        let newMenuCategoryOptions: Array<{ label: string; value: MenuCategoryId | null }> = [...menuCategories, ...deprecatedMenuCategories].map((menuCategory) => ({
            label: menuCategory.name,
            value: menuCategory.menuCategoryId,
        }));
        if (menuId) {
            //TODO: Remove condition when its safe
            if (newMenuManagementEnabled) {
                const menuCategoriesFiltered = filterMenuCategoriesByMenuId(menuId);
                newMenuCategoryOptions = menuCategoriesFiltered.map((menuCategory) => ({ label: menuCategory.name, value: menuCategory.menuCategoryId }));
            } else {
                const menuCategoriesFiltered = filterDeprecatedMenuCategoriesByMenuId(menuId);
                newMenuCategoryOptions = menuCategoriesFiltered.map((menuCategory) => ({ label: menuCategory.name, value: menuCategory.menuCategoryId }));
            }
        }
        if (!required) {
            newMenuCategoryOptions.unshift({ label: '', value: null });
        }
        setMenuCategoryOptions(newMenuCategoryOptions);
    };

    const filterMenuCategoriesByMenuId = (menuId: MenuId) => {
        const menu = menus.find((menu) => menu.menuId === menuId);
        if (!menu) return menuCategories;

        const menuCategoriesFiltered = menuCategories.filter((menuCategory) => menu?.menuCategoryIds.includes(menuCategory.menuCategoryId));
        return menuCategoriesFiltered;
    };

    const filterDeprecatedMenuCategoriesByMenuId = (menuId: MenuId) => {
        const menu = deprecatedMenus.find((menu) => menu.menuId === menuId);
        if (!menu) return menuCategories;

        const menuCategoriesFiltered = deprecatedMenuCategories.filter((menuCategory) =>
            menu?.categories.some((category: MenuCategoryVm | DeprecatedMenuCategoryVm) => category.menuCategoryId === menuCategory.menuCategoryId),
        );
        return menuCategoriesFiltered;
    };

    return (
        <FormSelect
            name={name}
            label={label}
            placeholder={placeholder}
            helperText={helperText}
            defaultValue={defaultValue}
            options={menuCategoryOptions}
            disabled={disabled}
            required={required}
            variant={variant}
        />
    );
}

type Props = {
    name: string;
    label?: string;
    placeholder?: string;
    helperText?: string;
    defaultValue?: string | null | undefined;
    disabled?: boolean;
    required?:
        | boolean
        | {
              value: number;
              message: string;
          };
    variant?: Variant;
    menuId?: MenuId;
};
