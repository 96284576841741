import { letseatmanagerApiMethod } from 'src/api/letseatmanagerApi';
import type { ApiSauceResponse } from 'src/api/types/ApiSauceResponse';
import type { RestaurantId } from 'src/types/Id';
import type { MenuCategoryVm } from 'src/types/MenuCategoryVm';

export async function findMenuCategoriesApi(request: FindMenuCategoriesApiRequest): ApiSauceResponse<Array<MenuCategoryVm>> {
    return letseatmanagerApiMethod('menuCategory/findMenuCategoriesApi', request);
}

export type FindMenuCategoriesApiRequest = {
    restaurantId: RestaurantId;
};
