import { letseatadminApiMethod } from 'src/api/letseatadminApi';
import type { ApiSauceResponse } from 'src/api/types/ApiSauceResponse';
import type { EmailTemplateId } from 'src/types/Id';

export async function getEmailTemplateOptionsApi(): ApiSauceResponse<GetEmailTemplateOptionsApiResponse> {
    return letseatadminApiMethod('options/getEmailTemplateOptionsApi');
}

export type GetEmailTemplateOptionsApiResponse = Array<EmailTemplateOptionsVm>;

export type EmailTemplateOptionsVm = {
    label: string;
    value: EmailTemplateId;
};
