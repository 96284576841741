import * as React from 'react';
import { blockDeviceApi } from 'src/api/letseatmanager/device/blockDeviceApi';
import { RemoveDialog } from 'src/components/dialog/RemoveDialog';
import { translate } from 'src/i18n/translate';
import type { DeviceVm } from 'src/types/DeviceVm';
import { alertKnownErrorOrSomethingWentWrong } from 'src/utils/alert/alertKnownErrorOrSomethingWentWrong';

export function BlockDeviceDialog({ open, device, onClose, onSuccess }: Props): React.ReactElement {
    const blockDevice = async () => {
        if (!device) return;

        const response = await blockDeviceApi({ deviceId: device?.deviceId });
        if (!response.ok) {
            return alertKnownErrorOrSomethingWentWrong(response);
        }
        onSuccess?.();
    };

    return (
        <RemoveDialog
            open={open}
            title={translate('Block device @deviceName', { deviceName: device?.deviceName })}
            subtitle={translate('Are you sure you want to continue?')}
            cancelButtonText={translate('Cancel')}
            removeButtonText={translate('Yes, block')}
            onSubmit={blockDevice}
            onClose={onClose}
        />
    );
}

type Props = {
    open: boolean;
    device?: DeviceVm;
    onClose: any;
    onSuccess?: any;
};
