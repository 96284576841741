import { makeStyles } from '@material-ui/core/styles';
import { LockIcon } from '@pidedirecto/ui/icons';
import { BigNumber } from 'bignumber.js';
import * as React from 'react';
import { memo, useCallback, useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { RolePermissions } from 'src/constants/RolePermission';
import { RoutePaths } from 'src/constants/RoutePath';
import { translate } from 'src/i18n/translate';
import { authReducer } from 'src/reducers/authReducer';
import { PosLockScreenNumericPad } from 'src/scenes/letseatmanager/pos/posUserLockscreen/PosLockScreenNumericPad';
import { PosUserLockscreenEmployeeClockInAndClockOut } from 'src/scenes/letseatmanager/pos/posUserLockscreen/PosUserLockscreenEmployeeClockInAndClockOut';
import { PosUserLockScreenPinCodeDisplay } from 'src/scenes/letseatmanager/pos/posUserLockscreen/PosUserLockScreenPinCodeDisplay';
import { useFindOpenedUserCashRegisterPosBusinessDay } from 'src/services/cashRegister/useFindOpenedUserCashRegisterPosBusinessDay';
import { createUserSignedInToPideDirectoPosFailedLogEvent } from 'src/services/logEvent/createUserSignedInToPideDirectoPosFailedLogEvent';
import { createUserSignedInToPideDirectoPosSuccessfulLogEvent } from 'src/services/logEvent/createUserSignedInToPideDirectoPosSuccessfulLogEvent';
import { useNotification } from 'src/services/notification/useNotification';
import { usePosUserLockScreenHasClockedIn } from 'src/services/pos/PosUserLockScreen/usePosUserLockScreenHasClockedIn';
import { usePosUserLockScreenTryingClockIn } from 'src/services/pos/PosUserLockScreen/usePosUserLockScreenTryingClockIn';
import { usePosUserLockScreenTryingClockOut } from 'src/services/pos/PosUserLockScreen/usePosUserLockScreenTryingClockOut';
import { useSetHasClockedIn } from 'src/services/pos/PosUserLockScreen/useSetHasClockedIn';
import { useSetPosUserLockScreenIsWrongPassword } from 'src/services/pos/PosUserLockScreen/useSetPosUserLockScreenIsWrongPassword';
import { useSetTryingClockIn } from 'src/services/pos/PosUserLockScreen/useSetTryingClockIn';
import { useSetTryingClockOut } from 'src/services/pos/PosUserLockScreen/useSetTryingClockOut';
import { ManagerUserId } from 'src/types/Id';
import { PinCodeUser } from 'src/types/PinCodeUser';
import { useAction } from 'src/utils/react/useAction';
import { useSelector } from 'src/utils/react/useSelector';
import { useIsSmallScreen } from 'src/utils/react/window/useIsSmallScreen';
import { setShouldPlayAlertSoundMobileApp } from 'src/utils/reactNative/setShouldPlayAlertSoundMobileApp';

export const PosUserLockscreenNumpad: React.ComponentType<Props> = memo<Props>(({ onClockIn }: Props) => {
    const classes = useStyles();

    const isSmallScreen = useIsSmallScreen();

    const pinCodeNumericPadRef = useRef<HTMLDivElement | null>(null);

    const { findOpenedUserCashRegisterPosBusinessDay } = useFindOpenedUserCashRegisterPosBusinessDay();

    const history = useHistory();

    const notification = useNotification();

    const tryingClockIn = usePosUserLockScreenTryingClockIn();
    const tryingClockOut = usePosUserLockScreenTryingClockOut();
    const hasClockedIn = usePosUserLockScreenHasClockedIn();
    const [password, setPassword] = useState('');

    const pinCodeUsers = useSelector((state) => state.authentication.pinCodeUsers);
    const managerUserId = useSelector((state) => state.authentication.managerUserId);
    const clockInEmployeeDayShiftEnabled = useSelector((state) => state.app.restaurant?.clockInEmployeeDayShiftEnabled);
    const currentEmployeesClockedIn = useSelector((state) => state.app2.currentEmployeesClockedIn);
    const restaurant = useSelector((state) => state.app.restaurant);
    const posTablesTabAsDefaultEnabled = useSelector((state) => state.app.restaurant.posTablesTabAsDefaultEnabled);
    const posMultipleCashRegistersEnabled = useSelector((state) => state.app.restaurant.posMultipleCashRegistersEnabled);

    const pinCodeUserSignIn = useAction(authReducer.actions.pinCodeUserSignIn);
    const setTryingClockIn = useSetTryingClockIn();
    const setTryingClockOut = useSetTryingClockOut();
    const setHasClockedIn = useSetHasClockedIn();
    const setPosUserLockScreenIsWrongPassword = useSetPosUserLockScreenIsWrongPassword();

    const PIN_CODE_LENGTH = 6;

    useEffect(() => {
        if (pinCodeNumericPadRef.current && isSmallScreen) {
            pinCodeNumericPadRef.current?.scrollIntoView({ behavior: 'smooth' });
        }
    }, [isSmallScreen]);

    useEffect(() => {
        document.addEventListener('keypress', handleKeyboard);
        return () => {
            document.removeEventListener('keypress', handleKeyboard);
        };
    }, []);

    useEffect(() => {
        if (password.length >= PIN_CODE_LENGTH) tryToSignInPinCodeUser();
    }, [password]);

    const handleKeyboard = useCallback((e: KeyboardEvent) => {
        const { key } = e;
        if (BigNumber(key).isNaN()) return;
        handleClickNumber(key);
    }, []);

    const handleClickNumber = useCallback(
        (number: string) => {
            if (hasClockedIn) setHasClockedIn(false);
            setPassword((prev) => prev + number);
        },
        [hasClockedIn],
    );

    const handleDeleteNumber = useCallback(() => {
        setPassword((prev) => prev?.slice(0, -1));
    }, []);

    const tryToSignInPinCodeUser = useCallback(() => {
        const pinCodeUser = pinCodeUsers?.find((pinCodeUser) => pinCodeUser.pinCode === password);
        if (!pinCodeUser) return userFailedPassword();

        if (!tryingClockIn && !tryingClockOut) {
            handleLogin(pinCodeUser);
            return;
        }

        if (tryingClockIn) {
            handleClockIn(pinCodeUser);
            return;
        }

        handleClockOut(pinCodeUser);
    }, [password, pinCodeUsers, tryingClockIn, tryingClockOut]);

    const handleLogin = async (pinCodeUser: PinCodeUser) => {
        const employeeHasClockedIn = currentEmployeesClockedIn?.some(
            (employeeManagerUser: { clockedInAt: Date; managerUserId: ManagerUserId }) => employeeManagerUser.managerUserId === pinCodeUser.managerUserId,
        );

        if (!pinCodeUser?.userRolePermissions?.includes(RolePermissions.VIEW_INCOMING_ORDERS_ALERT) && restaurant?.userManagementEnabled) {
            setShouldPlayAlertSoundMobileApp(false, restaurant?.alertSound);
        }

        if (clockInEmployeeDayShiftEnabled && !employeeHasClockedIn) {
            notification({ message: translate('Remember to clock in and out every day') });
            setPassword('');
            return;
        }

        createUserSignedInToPideDirectoPosSuccessfulLogEvent({ pinCodeEntered: password, managerUser: pinCodeUser });
        await pinCodeUserSignIn(pinCodeUser);

        if (!pinCodeUser?.initialPage) return;

        history.replace({
            pathname: posTablesTabAsDefaultEnabled ? RoutePaths.POS_TABLES : RoutePaths[pinCodeUser?.initialPage],
            search: history.location.search,
        });
    };

    const handleClockIn = (pinCodeUser: PinCodeUser) => {
        setTryingClockIn(false);

        const employeeHasClockedIn = currentEmployeesClockedIn?.some(
            (employeeManagerUser: { clockedInAt: Date; managerUserId: ManagerUserId }) => employeeManagerUser.managerUserId === pinCodeUser.managerUserId,
        );
        if (employeeHasClockedIn) {
            setHasClockedIn(employeeHasClockedIn);
            setPassword('');
            return;
        }

        if (!pinCodeUser.userRolePermissions?.includes(RolePermissions.VIEW_INCOMING_ORDERS_ALERT) && restaurant?.userManagementEnabled) {
            setShouldPlayAlertSoundMobileApp(false, restaurant?.alertSound);
        }
        onClockIn(pinCodeUser);
    };

    const handleClockOut = (pinCodeUser: PinCodeUser) => {
        if (posMultipleCashRegistersEnabled && !!findOpenedUserCashRegisterPosBusinessDay(pinCodeUser)) {
            setPassword('');
            notification({ message: translate('Unable to clock out because your cash register is open') });
            return;
        }

        const employeeHasClockedIn = currentEmployeesClockedIn?.some(
            (employeeManagerUser: { clockedInAt: Date; managerUserId: ManagerUserId }) => employeeManagerUser.managerUserId === pinCodeUser.managerUserId,
        );

        if (!employeeHasClockedIn) {
            setPassword('');
            notification({ message: translate('Unable to clock out because you do not have a open session') });
            return;
        }

        setTryingClockOut(false);
        pinCodeUserSignIn(pinCodeUser);

        history.replace({
            pathname: RoutePaths.EMPLOYEE_RESUME_DAY,
            search: history.location.search,
        });
    };

    const userFailedPassword = () => {
        createUserSignedInToPideDirectoPosFailedLogEvent({ pinCodeEntered: password, managerUserId });
        setPosUserLockScreenIsWrongPassword(true);
        setPassword('');
        setInterval(() => setPosUserLockScreenIsWrongPassword(false), 1000);
    };

    return (
        <div className={classes.container} ref={pinCodeNumericPadRef}>
            <LockIcon />
            <PosUserLockScreenPinCodeDisplay password={password} />
            <PosLockScreenNumericPad onClickNumber={handleClickNumber} onDeleteNumber={handleDeleteNumber} />
            <PosUserLockscreenEmployeeClockInAndClockOut />
        </div>
    );
});

const useStyles = makeStyles((theme) => ({
    container: {
        gridArea: 'numericPad',
        margin: 'auto',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        justifySelf: 'start',
        color: theme.palette.text.brand,
    },
}));

type Props = {
    onClockIn: any;
};
