import { withTheme } from '@material-ui/core/styles';
import * as React from 'react';

export const Spacing: React.ComponentType<any> = withTheme(
    class Spacing extends React.PureComponent<Props> {
        static defaultProps = {
            units: 1,
            dense: false,
        };

        render() {
            const height = this.props.theme.spacing(this.props.units) / (this.props.denseGrid ? 2 : 1);
            return <div style={{ height }} />;
        }
    },
);

type Props = {
    theme: any;
    units: number;
    denseGrid?: boolean;
};
