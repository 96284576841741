import * as React from 'react';
import { useEffect, useRef } from 'react';
import { getJwtToken } from 'src/api/utils/getJwtToken';
import { Apps } from 'src/constants/App';
import { HOUR, MINUTES, SECONDS } from 'src/constants/TimeUnit';
import { WebSocketEventTypes } from 'src/constants/WebSocketEventType';
import { envENVIRONMENT } from 'src/env/envENVIRONMENT';
import { envWEBSOCKET_API_URL } from 'src/env/envWEBSOCKET_API_URL';
import { getDeviceId } from 'src/services/device/getDeviceId';
import type { WebSocketEvent } from 'src/types/WebSocketEvent';
import { formatAsDetailedDuration } from 'src/utils/date/formatAsDetailedDuration';
import { isOffline } from 'src/utils/html/isOffline';
import { dateReviver } from 'src/utils/json/dateReviver';
import { useSelector } from 'src/utils/react/useSelector';
import { isMobileApp } from 'src/utils/reactNative/isMobileApp';
import { requireValue } from 'src/utils/require/requireValue';
import { toQueryString } from 'src/utils/url/toQueryString';
import { wait } from 'src/utils/wait';
import { WebSocketEvents } from 'src/utils/webSocket/WebSocketEvents';

/**
 * Class handles the connection to aws websockets and makes sure that connection will
 * be alive and tries to reconnect with a custom exponential backoff logic.
 * - It sends heartbeat events to server every 2 minute just to make sure
 *   aws does not close the websocket connection
 *   see aws limitation: Idle Connection Timeout: 10 min
 *   see https://docs.aws.amazon.com/apigateway/latest/developerguide/limits.html#apigateway-execution-service-websocket-limits-table
 * - It handles the limitation that aws will disconnect the connection after 2 hours
 *   see Connection duration for WebSocket API: 2 hours
 *   see https://docs.aws.amazon.com/apigateway/latest/developerguide/limits.html#apigateway-execution-service-websocket-limits-table
 *   this is handled by connecting another connection after 1 hour and 30 min and then wait 5 seconds to disconnect the old connection
 *   this creates an interval where we have duplicate connections and can receive duplicate messages
 *   to solve that problem we use a cache to keep track of the messages received in the last 5 seconds
 *   and discard a message if it already have been received.
 *   The reason why we have a 5 seconds overlap of connection is that a slowness in sending the websocket events
 *   from the server can cause messages to get lost in case the old connection disconnects in the for loop sending
 *   to all device connection. 5 seconds is more than enough probably but better safe than sorry.
 */
export function ConnectDeviceToWebSocket(): React.ReactElement | null {
    const webSocket = useRef<WebSocket | undefined>(undefined);
    const previousWebSocket = useRef<WebSocket | undefined>(undefined);
    let cancelled = false;
    let numberOfReconnectionTries = 0;
    let heartbeatInterval: NodeJS.Timer;
    let webSocketReconnectTimeout: NodeJS.Timer;

    const restaurantIds = useSelector((state) => state.app.restaurantIds);

    useEffect(() => {
        connect();
        return () => {
            cancelled = true;
            webSocket.current?.close();
        };
    }, []);

    useEffect(() => {
        if (webSocket.current?.readyState !== WebSocket.OPEN) return;
        console.log(`sending websocket SUBSCRIBE_TO_RESTAURANT_EVENTS`);
        try {
            webSocket.current?.send(JSON.stringify({ webSocketEventType: WebSocketEventTypes.SUBSCRIBE_TO_RESTAURANT_EVENTS, deviceId: getDeviceId(), restaurantIds }));
        } catch (e: any) {
            console.log(`sending websocket SUBSCRIBE_TO_RESTAURANT_EVENTS error`, e);
        }
    }, [restaurantIds]);

    const connect = async () => {
        webSocket.current = new WebSocket(
            `${envWEBSOCKET_API_URL()}?${toQueryString({
                deviceId: getDeviceId(),
                environment: envENVIRONMENT(),
                app: isMobileApp() ? Apps.PIDEDIRECTOADMINAPP : Apps.PIDEDIRECTOADMIN,
                jwtToken: (await getJwtToken()).jwtToken,
            })}`,
        );

        webSocket.current.onopen = handleOpen;
        webSocket.current.onmessage = handleOnMessage;
        webSocket.current.onclose = handleOnClose;
        webSocket.current.onerror = handleOnError;
    };

    const handleOpen = async (event: any) => {
        console.log(`websocket connection opened`, event);
        closePreviousWebSocket();

        numberOfReconnectionTries = 0;

        configureHeartbeatMessagesToBeSentEvery2Minute();
        configureWebSocketToReconnectAfter1HourAnd30Minutes();
    };

    const closePreviousWebSocket = async () => {
        try {
            clearInterval(heartbeatInterval);
            clearTimeout(webSocketReconnectTimeout);

            if (!previousWebSocket.current) return;

            console.debug(`waiting 5 seconds for closing previous websocket connection`);
            await wait(5 * SECONDS);

            // avoid overlapping events with new connection
            requireValue(previousWebSocket.current).onmessage = (event: MessageEvent) => {
                console.debug(`previous websocket connection overlapping message ignored`, event);
            };
            // avoid calling reconnect on disconnect logic
            requireValue(previousWebSocket.current).onclose = (event: CloseEvent) => {
                console.debug(`previous websocket connection closed`);
            };
            requireValue(previousWebSocket.current).close();
            previousWebSocket.current = undefined;
        } catch (e: any) {
            console.debug('closePreviousWebSocket error', e);
        }
    };

    const configureHeartbeatMessagesToBeSentEvery2Minute = () => {
        heartbeatInterval = setInterval(() => {
            if (isOffline()) {
                console.log(`sending websocket HEARTBEAT skipped since internet is not available`);
                return;
            }

            console.log(`sending websocket HEARTBEAT`);
            try {
                webSocket.current?.send(JSON.stringify({ webSocketEventType: WebSocketEventTypes.HEARTBEAT, deviceId: getDeviceId() }));
            } catch (e: any) {
                console.log(`sending websocket HEARTBEAT error`, e);
            }
        }, 2 * MINUTES);
    };

    const configureWebSocketToReconnectAfter1HourAnd30Minutes = () => {
        webSocketReconnectTimeout = setTimeout(
            async () => {
                previousWebSocket.current = webSocket.current;
                await connect();
            },
            1 * HOUR + 30 * MINUTES,
        );
    };

    const handleOnMessage = async (messageEvent: MessageEvent) => {
        console.debug(`websockets message received`, messageEvent);
        const webSocketEvent: WebSocketEvent<any> = JSON.parse((messageEvent as any).data, dateReviver);
        if (RecentlyReceivedWebSocketEventIds.isReceived(webSocketEvent.webSocketEventId)) {
            console.debug(`skipping calling WebSocketEvents.handleWebSocketEvent since websockets message already received`, webSocketEvent);
            return;
        }
        RecentlyReceivedWebSocketEventIds.add(webSocketEvent.webSocketEventId);
        try {
            await WebSocketEvents.handleWebSocketEvent(webSocketEvent);
        } catch (error: any) {
            console.log(`WebSocketEvents.handleWebSocketEvent failed`, error);
        }
    };

    const handleOnClose = (event: CloseEvent) => {
        clearInterval(heartbeatInterval);
        clearTimeout(webSocketReconnectTimeout);
        if (cancelled) {
            // dont reconnect on unmount, only when websocket server triggers onclose
            console.log(`websocket connection closed`, event);
            return;
        }
        numberOfReconnectionTries++;
        const reconnectionTime = getReconnectionTime(numberOfReconnectionTries);
        console.log(`websocket connection closed, will reconnect in ${formatAsDetailedDuration(reconnectionTime)}`, event);
        setTimeout(function () {
            console.log(`websocket reconnect attempt ${numberOfReconnectionTries}`, event);
            connect();
        }, reconnectionTime);
    };

    const handleOnError = (error: any) => {
        console.log(`websocket error received`, error);
        webSocket.current?.close();
    };

    return null;
}

class RecentlyReceivedWebSocketEventIds {
    static #cache: Record<string, Date> = {};

    static add(webSocketEventId: string) {
        RecentlyReceivedWebSocketEventIds.#cache[webSocketEventId] = new Date();
        setTimeout(() => {
            delete RecentlyReceivedWebSocketEventIds.#cache[webSocketEventId];
        }, 10 * SECONDS);
    }

    static isReceived(webSocketEventId: string): boolean {
        return !!RecentlyReceivedWebSocketEventIds.#cache[webSocketEventId];
    }
}

/**
 * Returns a retry time with some sort of exponential backoff
 */
function getReconnectionTime(connectionTries: number) {
    if (connectionTries === 1) {
        // try directly on first try
        return 0;
    }
    if (connectionTries <= 60) {
        // try every 10 second for 10 minutes
        return 10 * SECONDS;
    }
    if (connectionTries <= 120) {
        // try every minute for 1 hour
        return 1 * MINUTES;
    }
    // try every 10 minute after 1 hour and 10 minutes
    return 10 * MINUTES;
}
