import { IconButton, makeStyles } from '@material-ui/core';
import KeyboardBackspaceIcon from '@material-ui/icons/KeyboardBackspace';
import * as React from 'react';
import { PosOrderSteps } from 'src/constants/PosOrderStep';
import { translate } from 'src/i18n/translate';
import { posReducer } from 'src/reducers/posReducer';
import { PosRouter } from 'src/scenes/letseatmanager/pos/PosRouter';
import { PosRestaurantZones } from 'src/scenes/letseatmanager/posRestaurantZones/PosRestaurantZones';
import { useSendOrderUpdateToCustomerDisplayScreen } from 'src/services/pos/useSendOrderUpdateToCustomerDisplayScreen';
import { useAction } from 'src/utils/react/useAction';
import { useSelector } from 'src/utils/react/useSelector';

export function PosTableSelect(): React.ReactElement {
    const classes = useStyles();
    const sendOrderUpdateToCustomerDisplayScreen = useSendOrderUpdateToCustomerDisplayScreen();

    const posSimpleView = useSelector((state) => state.pos.context?.posSimpleView);

    const setPosOrderStep = useAction(posReducer.actions.setPosOrderStep);

    const goBack = () => {
        setPosOrderStep(PosOrderSteps.SELECT_ORDER_TYPE);
        sendOrderUpdateToCustomerDisplayScreen();
    };

    return (
        <div className={classes.container}>
            <PosRouter />
            <div className={classes.titleContainer}>
                {!posSimpleView && (
                    <div className={classes.backButtonContainer}>
                        <IconButton onClick={goBack}>
                            <KeyboardBackspaceIcon />
                        </IconButton>
                        <span className={classes.text}>{translate('Go Back')}</span>
                    </div>
                )}
                <h1 className={classes.title}>{translate('Choose table')}</h1>
            </div>
            <PosRestaurantZones editDisabled />
        </div>
    );
}

const useStyles = makeStyles((theme) => ({
    container: {
        width: '100%',
        height: '70vh',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'flex-start',
        paddingTop: 0,
        [theme.breakpoints.down('sm')]: {
            height: '90vh',
            padding: 24,
            paddingTop: 0,
        },
    },
    title: {
        color: '#2C374A',
        fontFamily: theme.typography.regular,
        fontSize: 30,
        marginTop: 0,
        [theme.breakpoints.down('sm')]: {
            fontSize: 20,
        },
    },
    buttonContainer: {
        backgroundColor: 'transparent',
        border: '1px solid #D7D7D7',
        fontFamily: theme.typography.regular,
        fontSize: 15,
        borderRadius: 12,
        width: 250,
        height: 250,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        margin: 12,
        cursor: 'pointer',
        '&:hover': {
            border: `1px solid ${theme.palette.primary.main}`,
            backgroundColor: `${theme.palette.primary.main}20`,
        },
        [theme.breakpoints.down('sm')]: {
            width: 300,
            height: 150,
        },
    },
    buttonsContainer: {
        display: 'flex',
        [theme.breakpoints.down('sm')]: {
            flexDirection: 'column',
        },
    },
    buttonText: {
        color: '#2C374A',
        fontSize: 20,
        marginTop: 16,
    },
    text: {
        fontFamily: theme.typography.regular,
        fontSize: 15,
        marginLeft: 5,
    },
    backButtonContainer: {
        position: 'absolute',
        left: 0,
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        [theme.breakpoints.down('sm')]: {
            top: -50,
        },
    },
    titleContainer: {
        width: '100%',
        position: 'relative',
        alignItems: 'center',
        justifyContent: 'center',
        display: 'flex',
    },
}));
