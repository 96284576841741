import { makeStyles } from '@material-ui/core';
import * as React from 'react';
import { AppBarCitySelect } from 'src/components/app/appBar/AppBarCitySelect';
import { DeprecatedAskForDriverButton } from 'src/components/app/appBar/DeprecatedAskForDriverButton';
import { NetworkConnectivityIndicator } from 'src/components/app/appBar/NetworkConnectivityIndicator';
import { RightToolBarContextualMenu } from 'src/components/app/appBar/RightToolBarContextualMenu';
import { SignOutPinCodeUserButton } from 'src/components/app/appBar/SignOutPinCodeUserButton';
import { ToggleSoundButton } from 'src/components/app/appBar/ToggleSoundButton';
import { UserName } from 'src/components/app/appBar/UserName';
import { useSelector } from 'src/utils/react/useSelector';

export function RightToolBar(): React.ReactElement {
    const classes = useStyles();

    const signedIn = useSelector((state) => state.authentication.signedIn);

    if (!signedIn) return <div></div>;

    return (
        <div className={classes.container} role='toolbar'>
            <DeprecatedAskForDriverButton />
            <div className={classes.desktopContainer}>
                <NetworkConnectivityIndicator />
                <AppBarCitySelect />
                <ToggleSoundButton />
                <SignOutPinCodeUserButton />
                <UserName />
            </div>

            <RightToolBarContextualMenu />
        </div>
    );
}

const useStyles = makeStyles((theme) => ({
    container: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        color: theme.palette.text.brand,
        gap: 12,
    },
    desktopContainer: {
        display: 'flex',
        alignItems: 'center',
        gap: 12,
        [theme.breakpoints.down('sm')]: {
            display: 'none',
        },
    },
}));
