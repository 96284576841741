import { letseatadminApiMethod } from 'src/api/letseatadminApi';
import type { ApiSauceResponse } from 'src/api/types/ApiSauceResponse';
import type { RestaurantReferralId } from 'src/types/Id';

export async function removeRestaurantReferralApi(request: RemoveRestaurantReferralApiRequest): ApiSauceResponse<void> {
    return letseatadminApiMethod('restaurantReferral/removeRestaurantReferralApi', request);
}

export type RemoveRestaurantReferralApiRequest = {
    restaurantReferralId: RestaurantReferralId;
};
