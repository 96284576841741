import { makeStyles } from '@material-ui/core';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { PosItemQuantitySpinner } from 'src/scenes/letseatmanager/pos/posAddMenuItem/PosItemQuantitySpinner';
import { useSelector } from 'src/utils/react/useSelector';

export function MoneyBillsSelector({ value, onChange, image }: Props): React.ReactElement {
    const classes = useStyles();
    const quantitySpinnerClasses = useStylesForQuantitySpinner();

    const [quantity, setQuantity] = useState(0);

    const totalPaid = useSelector((state) => state.pos.totalPaid);

    useEffect(() => {
        setQuantity(0);
    }, [totalPaid]);

    const handleRemoveOneMoneyBill = () => {
        if (quantity > 0) {
            setQuantity(quantity - 1);
            const negativeValue = -Math.abs(value);
            onChange(negativeValue);
        }
    };

    const handleAddOneMoneyBill = () => {
        setQuantity(quantity + 1);
        onChange(value);
    };

    return (
        <Card className={classes.container}>
            <CardMedia component='img' image={image} title={`image of ${value}`} className={classes.media} />
            <CardContent className={classes.content}>
                <PosItemQuantitySpinner quantity={quantity} title={value} onSubtract={handleRemoveOneMoneyBill} onAdd={handleAddOneMoneyBill} classes={quantitySpinnerClasses} />
            </CardContent>
        </Card>
    );
}

const useStyles = makeStyles((theme) => ({
    container: {
        margin: '2px 10px',
        width: '45%',
        borderRadius: '0px',
        border: 'none',
        boxShadow: 'none',
        backgroundColor: 'transparent',
        position: 'relative',
        [theme.breakpoints.down('sm')]: {
            width: '40%',
        },
    },
    content: {
        display: 'flex',
        justifyContent: 'center',
        margin: '0',
        padding: '4',
        position: 'absolute',
        bottom: '-8%',
        left: '50%',
        transform: 'translate(-50%, 0)',
    },
    media: {
        height: 'auto',
        objectFit: 'contain',
    },
}));

const useStylesForQuantitySpinner = makeStyles((theme) => ({
    container: {
        backgroundColor: 'rgba(255, 255, 255, 0.8)',
        borderRadius: 4,
        minWidth: 74,
    },
    icon: {
        color: 'white',
        fontSize: 12,
    },
    substract: {
        backgroundColor: 'rgba(242, 131, 158, 1)',
        borderRadius: 2,
        minWidth: 20,
    },
    add: {
        borderRadius: 2,
        minWidth: 20,
    },
    quantity: {
        fontWeight: 600,
    },
}));

type Props = {
    value: number;
    onChange: any;
    image?: string;
};
