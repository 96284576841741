import { MUIDataTableColumn } from 'mui-datatables';
import * as React from 'react';
import { Table } from 'src/components/Table';
import { translate } from 'src/i18n/translate';
import { useFormatAsRestaurantCurrencyNumber } from 'src/services/restaurant/useFormatAsRestaurantCurrencyNumber';
import type { CashRegisterPosBusinessDayReportVm } from 'src/types/CashRegisterPosBusinessDayReportVm';
import { PromoCodeReport, type PosBusinessDayReportVm } from 'src/types/PosBusinessDayReportVm';

export function PosBusinessDayPromoCodesTable({ posBusinessDayReport }: Props): React.ReactElement {
    const formatAsCurrencyNumber = useFormatAsRestaurantCurrencyNumber();

    const columns: Array<MUIDataTableColumn> = [
        {
            name: 'promoCodeId',
            options: {
                display: 'excluded',
                filter: false,
            },
        },
        {
            name: 'code',
            label: translate('Code'),
            options: {
                filter: true,
            },
        },
        {
            name: 'discount',
            label: translate('Discount'),
            options: {
                filter: false,
            },
        },
        {
            name: 'credits',
            label: translate('Credits'),
            options: {
                filter: false,
            },
        },
        {
            name: 'usage',
            label: translate('Usage'),
            options: {
                filter: true,
            },
        },
        {
            name: 'percentage',
            label: translate('Percentage'),
            options: {
                filter: true,
                customBodyRender: (percentageOfSales: number) => `${Math.round(percentageOfSales * 100) / 100}%`,
            },
        },
        {
            name: 'amount',
            label: translate('Amount'),
            options: {
                filter: false,
            },
        },
    ];

    return (
        <div>
            <Table
                data={
                    posBusinessDayReport.promoCodeReport?.map((promoCodeReport: PromoCodeReport) => ({
                        ...promoCodeReport,
                        discount: formatAsCurrencyNumber(promoCodeReport.discount),
                        amount: formatAsCurrencyNumber(promoCodeReport.amount),
                    })) ?? []
                }
                columns={columns}
                options={{
                    responsive: 'standard',
                    selectableRows: 'none',
                    viewColumns: false,
                    print: false,
                    download: true,
                }}
            />
        </div>
    );
}

type Props = {
    posBusinessDayReport: PosBusinessDayReportVm | CashRegisterPosBusinessDayReportVm;
};
