import { makeStyles } from '@material-ui/core';
import * as React from 'react';
import { Button } from 'src/components/Button';
import { translate } from 'src/i18n/translate';
import { CircleCloseIcon } from 'src/icons/CircleCloseIcon';
import { isPickedUpDelivery } from 'src/utils/delivery/isPickedUpDelivery';
import { isDeliveryOrder } from 'src/utils/order/isDeliveryOrder';
import { useSelector } from 'src/utils/react/useSelector';

export function DeliveryOrdersMapInfo({ onClose }: Props): React.ReactElement {
    const classes = useStyles();

    const currentOrders = useSelector((state) => state.app2.currentOrders);

    const deliveryOrders = currentOrders.filter((order) => isDeliveryOrder(order.orderType));
    const pickedUpDeliveryOrders = deliveryOrders.filter((order) => isPickedUpDelivery(order.deliveryStatus));
    const notPickedUpDeliveryOrders = deliveryOrders.filter((order) => !isPickedUpDelivery(order.deliveryStatus));

    return (
        <div className={classes.container}>
            <div className={classes.infoContainer}>
                <h1 className={classes.title}>{translate('My live orders')}</h1>
                <div className={classes.indicators}>
                    <div className={classes.indicator} style={{ paddingLeft: 0 }}>
                        <div className={classes.circle} style={{ backgroundColor: '#06B7A2' }}>
                            {deliveryOrders.length}
                        </div>
                        {translate('Actives')}
                    </div>
                    <div className={classes.indicator}>
                        <div className={classes.circle} style={{ backgroundColor: '#0662B7' }}>
                            {pickedUpDeliveryOrders.length}
                        </div>
                        {translate('On the way')}
                    </div>
                    <div className={classes.indicator} style={{ borderRight: 0 }}>
                        <div className={classes.circle} style={{ backgroundColor: '#F3691B' }}>
                            {notPickedUpDeliveryOrders.length}
                        </div>
                        {translate('At restaurant')}
                    </div>
                </div>
            </div>
            <Button icon onClick={onClose}>
                <CircleCloseIcon title={translate('Close map')} />
            </Button>
        </div>
    );
}

const useStyles = makeStyles((theme) => ({
    container: {
        display: 'flex',
        position: 'absolute',
        top: 20,
        left: 20,
        right: 20,
        backgroundColor: 'white',
        borderRadius: 10,
        padding: 20,
        zIndex: 2,
        boxShadow: '0px 4px 4px 0px rgba(0,0,0,0.15)',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        gap: 10,
    },
    infoContainer: {
        gap: 10,
        display: 'flex',
        flexDirection: 'column',
    },
    title: {
        fontFamily: theme.typography.semiBold,
        color: '#133547',
        fontSize: 22,
        margin: 0,
    },
    indicators: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
    },
    indicator: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        padding: '2px 10px',
        borderRight: '1px solid #D9D9D9',
        gap: 10,
        color: '#133547',
        fontFamily: theme.typography.regular,
    },
    circle: {
        width: 20,
        height: 20,
        color: 'white',
        borderRadius: 20,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        fontSize: 12,
        fontFamily: theme.typography.regular,
    },
}));

type Props = {
    onClose: any;
};
