import { makeStyles } from '@material-ui/core/styles';
import EqualizerIcon from '@material-ui/icons/Equalizer';
import * as React from 'react';
import { translate } from 'src/i18n/translate';

export function GraphEmptyState({ title }: Props): React.ReactElement {
    const classes = useStyles();

    return (
        <article className={classes.container}>
            <h2 className={classes.title}>{title}</h2>
            <div className={classes.emptyReport}>
                <EqualizerIcon fontSize={'large'} />
                <p className={classes.text}>{translate('There is no data for this report')}</p>
            </div>
        </article>
    );
}

const useStyles = makeStyles((theme) => ({
    container: {
        height: '70vh',
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
    },
    title: {
        fontFamily: theme.typography.medium,
        color: '#104353',
    },
    emptyReport: {
        width: '100%',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
    },
    text: {
        fontFamily: theme.typography.regular,
    },
}));

type Props = {
    title: string;
};
