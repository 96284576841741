import { makeStyles } from '@material-ui/core';
import { useEffect, useState } from 'react';
import * as React from 'react';
import { Button } from 'src/components/Button';
import { Dialog } from 'src/components/Dialog';
import { DialogActions } from 'src/components/DialogActions';
import { translate } from 'src/i18n/translate';
import { posReducer } from 'src/reducers/posReducer';
import { CashRegisterPosBusinessDayOption } from 'src/scenes/letseatmanager/pos/CashRegisterPosBusinessDayOption';
import { useNotification } from 'src/services/notification/useNotification';
import { CashRegisterPosBusinessDayId } from 'src/types/Id';
import { OpenedCashRegisterPosBusinessDayVm } from 'src/types/OpenedCashRegisterPosBusinessDayVm';
import { CashRegisterPosBusinessDayVm } from 'src/types/PosBusinessDayVm';
import { alertKnownErrorOrSomethingWentWrongError } from 'src/utils/alert/alertKnownErrorOrSomethingWentWrongError';
import { useAction } from 'src/utils/react/useAction';
import { useSelector } from 'src/utils/react/useSelector';

export function SelectOpenedCashRegisterDialog(): React.ReactElement | null {
    const classes = useStyles();

    const notification = useNotification();

    const [cashRegisterPosBusinessDayIdSelected, setCashRegisterPosBusinessDayIdSelected] = useState<CashRegisterPosBusinessDayId | undefined>(undefined);

    const openedPosBusinessDay = useSelector((state) => state.pos.openedPosBusinessDay);
    const open = useSelector((state) => state.pos.selectOpenedCashRegisterDialogState.open);
    const openedCashRegistersPosBusinessDay = useSelector((state) => state.pos.openedCashRegistersPosBusinessDay);

    const setOpenedCashRegister = useAction(posReducer.actions.setOpenedCashRegister);
    const setCashRegisterOpen = useAction(posReducer.actions.setCashRegisterOpen);
    const setSelectOpenedCashRegisterDialogState = useAction(posReducer.actions.setSelectOpenedCashRegisterDialogState);
    const setOpenedCashRegisterPosBusinessDay = useAction(posReducer.actions.setOpenedCashRegisterPosBusinessDay);

    const hasOpenedCashRegister = openedPosBusinessDay?.cashRegisterPosBusinessDays?.some((cashRegisterPosBusinessDay: CashRegisterPosBusinessDayVm) => cashRegisterPosBusinessDay?.opened);

    useEffect(() => {
        if (open && (!hasOpenedCashRegister || !openedCashRegistersPosBusinessDay?.length || !openedPosBusinessDay)) {
            notification({ message: translate('In order to create a new order, you must first open the cash register') });
        }

        return () => {
            setCashRegisterPosBusinessDayIdSelected(undefined);
        };
    }, [open]);

    const handleSelectCashRegisterToUse = async () => {
        if (!cashRegisterPosBusinessDayIdSelected) return;

        const cashRegisterPosBusinessDaySelected = openedCashRegistersPosBusinessDay?.find(
            (cashRegisterPosBusinessDaySummary: OpenedCashRegisterPosBusinessDayVm) => cashRegisterPosBusinessDaySummary.cashRegisterPosBusinessDayId === cashRegisterPosBusinessDayIdSelected,
        );

        if (!cashRegisterPosBusinessDaySelected || !cashRegisterPosBusinessDaySelected?.cashRegister) {
            return alertKnownErrorOrSomethingWentWrongError(translate('Select a valid cash register'));
        }

        onClose();
        setOpenedCashRegister(cashRegisterPosBusinessDaySelected?.cashRegister);
        setCashRegisterOpen(true);
        setOpenedCashRegisterPosBusinessDay(cashRegisterPosBusinessDaySelected);
    };

    const onClose = () => setSelectOpenedCashRegisterDialogState({ open: false });

    if (!openedCashRegistersPosBusinessDay?.length || !openedPosBusinessDay) return null;

    return (
        <Dialog open={open} classes={{ dialog: classes.dialog }} title={translate('Assign cash register')} onClose={onClose}>
            <div className={classes.cardsContainer}>
                {openedCashRegistersPosBusinessDay?.map((cashRegisterPosBusinessDay: OpenedCashRegisterPosBusinessDayVm) => {
                    const isSelected = cashRegisterPosBusinessDayIdSelected === cashRegisterPosBusinessDay.cashRegisterPosBusinessDayId;
                    return (
                        <CashRegisterPosBusinessDayOption
                            isSelected={isSelected}
                            cashRegisterPosBusinessDay={cashRegisterPosBusinessDay}
                            onClick={(cashRegisterPosBusinessDay: OpenedCashRegisterPosBusinessDayVm) =>
                                setCashRegisterPosBusinessDayIdSelected(cashRegisterPosBusinessDay.cashRegisterPosBusinessDayId)
                            }
                        />
                    );
                })}
            </div>
            <DialogActions>
                <Button classes={{ button: (classes as any).button }} secondary onClick={onClose}>
                    {translate('Cancel')}
                </Button>
                <Button classes={{ button: (classes as any).button }} onClick={handleSelectCashRegisterToUse}>
                    {translate('Assign')}
                </Button>
            </DialogActions>
        </Dialog>
    );
}

const useStyles = makeStyles((theme) => ({
    alert: {
        fontSize: 14,
        color: '#E27919',
    },
    dialog: {
        padding: 34,
        maxWidth: 780,
    },
    cardsContainer: {
        margin: '30px 0',
        display: 'grid',
        gap: 20,
        gridTemplateColumns: '1fr',
        [theme.breakpoints.up('sm')]: {
            gridTemplateColumns: 'repeat(2, 1fr)',
        },
    },
    cardOption: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
        gap: 12,
        padding: 24,
        borderRadius: 8,
        textAlign: 'center',
        color: '#2E3748',
        border: '1px solid #D9D9D9',
    },
    cardOptionSelected: {
        backgroundColor: '#E6FBF1',
        border: '1px solid #06B7A2',
    },
}));
