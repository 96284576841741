import { IconButton } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import TableCell from '@material-ui/core/TableCell';
import TableFooter from '@material-ui/core/TableFooter';
import TableRow from '@material-ui/core/TableRow';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import { DatePicker } from '@pidedirecto/ui';
import { useLoadApi } from '@pidedirecto/ui/hooks';
import { BigNumber } from 'bignumber.js';
import moment from 'moment';
import { MUIDataTableColumn } from 'mui-datatables';
import { useState } from 'react';
import * as React from 'react';
import { downloadOrderInvoiceApi } from 'src/api/letseatmanager/invoice/downloadOrderInvoiceApi';
import { findOrderInvoicesApi } from 'src/api/letseatmanager/invoice/findOrderInvoicesApi';
import { FilterState } from 'src/components/input/FromToFilter';
import { SecuredAndSubscribedPage } from 'src/components/page/SecuredAndSubscribedPage';
import { Table } from 'src/components/Table';
import { UpdatingContentProgress } from 'src/components/UpdatingContentProgress';
import { RolePermissions } from 'src/constants/RolePermission';
import { translate } from 'src/i18n/translate';
import { useFormatAsRestaurantCurrencyNumber } from 'src/services/restaurant/useFormatAsRestaurantCurrencyNumber';
import { InvoiceId } from 'src/types/Id';
import { alertKnownErrorOrSomethingWentWrong } from 'src/utils/alert/alertKnownErrorOrSomethingWentWrong';
import { formatDateTimeString } from 'src/utils/date/formatDateTimeString';
import { useSelector } from 'src/utils/react/useSelector';
import { sum } from 'src/utils/reduce/sum';

export function OrderInvoicesPage(): React.ReactElement {
    const classes = useStyles();
    const formatAsCurrencyNumber = useFormatAsRestaurantCurrencyNumber();

    const [downloading, setDownloading] = useState(false);
    const [filter, setFilter] = useState<FilterState>({
        from: moment().startOf('day').toDate(),
        to: moment().endOf('day').toDate(),
    });

    const restaurantId = useSelector((state) => state.app.restaurantId);

    const [loading, orderInvoices] = useLoadApi(
        findOrderInvoicesApi,
        {
            restaurantId,
            from: filter.from,
            to: filter.to,
        },
        { requiredValues: [restaurantId], dependencies: [restaurantId, filter], initialValue: [] },
    );

    const totalAmount = orderInvoices.map((orderInvoice) => orderInvoice.amount).reduce(sum, BigNumber(0));

    const downloadOrderInvoice = async ({ invoiceId, shortOrderId }: { invoiceId: InvoiceId; shortOrderId: string }) => {
        setDownloading(true);
        const responsePdf = await downloadOrderInvoiceApi({
            restaurantId,
            invoiceId,
            documentType: 'pdf',
        });
        if (!responsePdf.ok) return alertKnownErrorOrSomethingWentWrong(responsePdf);
        downloadFile(responsePdf.data.uri, shortOrderId);

        const responseXml = await downloadOrderInvoiceApi({
            restaurantId,
            invoiceId,
            documentType: 'xml',
        });
        setDownloading(false);
        if (!responseXml.ok) return alertKnownErrorOrSomethingWentWrong(responseXml);

        downloadFile(responseXml.data.uri, shortOrderId);
    };

    const downloadFile = (uri: string, shortOrderId: string) => {
        const downloadAction = document.createElement('a');

        downloadAction.href = uri;
        downloadAction.download = `${translate('Invoice')} ${shortOrderId}`;

        document.body?.appendChild(downloadAction);
        downloadAction.click();
        document.body?.removeChild(downloadAction);
    };

    const handleChangeFromFilter = (from: Date | undefined) => setFilter({ ...filter, from: from ?? moment().startOf('day').toDate() });

    const handleChangeToFilter = (to: Date | undefined) => setFilter({ ...filter, to: to ?? moment().endOf('day').toDate() });

    return (
        <SecuredAndSubscribedPage title={translate('Order Invoices')} rolePermission={RolePermissions.ORDER_INVOICES_PAGE}>
            <Grid container spacing={5}>
                <Grid item xs={12}>
                    <div className={classes.datesContainer}>
                        <DatePicker name='from' label={translate('From')} onChange={handleChangeFromFilter} value={filter.from} />
                        <DatePicker name='to' label={translate('To')} onChange={handleChangeToFilter} value={filter.to} />
                    </div>
                </Grid>
                <Grid item xs={12}>
                    <div style={{ position: 'relative', paddingBottom: 3 }}>
                        <UpdatingContentProgress loading={loading || downloading} bottom />
                        <Table
                            data={orderInvoices.map((invoice) => ({
                                invoiceId: invoice.invoiceId,
                                shortOrderId: invoice.shortOrderId,
                                businessName: invoice.businessName,
                                amount: formatAsCurrencyNumber(invoice.amount),
                                createdAt: formatDateTimeString(invoice.createdAt),
                                downloadInvoice: { invoiceId: invoice.invoiceId, shortOrderId: invoice.shortOrderId, downloadAvailable: !!invoice.facturamaInvoiceId || !!invoice.uruwareInvoiceId },
                            }))}
                            columns={
                                [
                                    {
                                        name: 'invoiceId',
                                        label: '',
                                        options: {
                                            display: 'excluded',
                                            filter: false,
                                        },
                                    },
                                    {
                                        name: 'shortOrderId',
                                        label: '',
                                        options: {
                                            label: translate('Order Id'),
                                            filter: false,
                                        },
                                    },
                                    {
                                        name: 'businessName',
                                        label: translate('Business Name'),
                                        options: {
                                            filter: false,
                                        },
                                    },
                                    {
                                        name: 'amount',
                                        label: translate('Amount'),
                                        options: {
                                            filter: false,
                                            searchable: false,
                                        },
                                    },
                                    {
                                        name: 'createdAt',
                                        label: translate('Created At'),
                                        options: {
                                            filter: false,
                                            searchable: false,
                                        },
                                    },
                                    {
                                        name: 'downloadInvoice',
                                        label: translate('Download'),
                                        options: {
                                            filter: false,
                                            searchable: false,
                                            customBodyRender: (invoice) =>
                                                invoice.downloadAvailable && (
                                                    <IconButton onClick={() => downloadOrderInvoice(invoice)} disabled={loading}>
                                                        <CloudDownloadIcon />
                                                    </IconButton>
                                                ),
                                        },
                                    },
                                ] as Array<MUIDataTableColumn>
                            }
                            options={{
                                responsive: 'standard',
                                tableBodyMaxHeight: '500px',
                                selectableRows: 'none',
                                filterType: 'checkbox',
                                rowsPerPage: 100,
                                customTableBodyFooterRender: () => (
                                    <TableFooter>
                                        {BigNumber(totalAmount).toNumber() > 0 && (
                                            <TableRow className={classes.footerTableContainer}>
                                                <TableCell></TableCell>
                                                <TableCell></TableCell>
                                                <TableCell className={classes.footerCellContainer}>
                                                    {translate('Total: @value', {
                                                        value: formatAsCurrencyNumber(BigNumber(totalAmount).toNumber()),
                                                    })}
                                                </TableCell>
                                            </TableRow>
                                        )}
                                    </TableFooter>
                                ),
                                // filterList: [[], [], [], [], ['MEXICO']],
                            }}
                        />
                    </div>
                </Grid>
            </Grid>
        </SecuredAndSubscribedPage>
    );
}

const useStyles = makeStyles((theme) => ({
    datesContainer: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        gap: 10,
        border: `1px solid ${theme.palette.border.primary}`,
        padding: 24,
        maxWidth: '40%',
        borderRadius: 5,
        [theme.breakpoints.down('sm')]: {
            maxWidth: '100%',
            margin: '0 10px',
        },
    },
    footerTableContainer: {
        backgroundColor: '#ececec',
        [theme.breakpoints.up('sm')]: {
            backgroundColor: 'white',
        },
    },
    footerCellContainer: {
        fontFamily: theme.typography.regular,
        color: theme.palette.text.primary,
        fontWeight: 'bold',
    },
}));
