import { useState } from 'react';
import * as React from 'react';
import { Button } from 'src/components/Button';
import { translate } from 'src/i18n/translate';
import { AssignDriverDialog } from 'src/scenes/letseatadmin/driver/AssignDriverDialog';
import type { OrderVm } from 'src/types/OrderVm';
import { isPedidosYaApp } from 'src/utils/app/isPedidosYaApp';
import { isDeliveryOrder } from 'src/utils/order/isDeliveryOrder';
import { isPideDirectoDelivery } from 'src/utils/order/isPideDirectoDelivery';
import { useSelector } from 'src/utils/react/useSelector';

export function AssignDriverToOrderButton({ order, disabled, onSuccess, primary, outlined, classes: classesProp }: Props): React.ReactElement | null {
    const [assignDriverDialogState, setAssignDriverDialogState] = useState({ open: false });

    const internalUser = useSelector((state) => state.authentication.internalUser);
    const assignDriverEnabled = useSelector((state) => state.app.restaurant?.assignDriverEnabled);
    const externalOwnFleetEnabled = useSelector((state) => state.app.restaurant.externalOwnFleetEnabled);
    const externalDeliveryEnabled = useSelector((state) => state.app.restaurant?.externalDelivery);

    const isDeliveryOrderWithOutDriver = isDeliveryOrder(order.orderType) && !order.driverId;
    const shouldRenderButton =
        (isDeliveryOrderWithOutDriver && !externalDeliveryEnabled && (internalUser || assignDriverEnabled) && isPideDirectoDelivery(order) && !isPedidosYaApp(order.app)) ||
        (externalOwnFleetEnabled && isDeliveryOrderWithOutDriver);

    const closeAssignDriverDialog = () => setAssignDriverDialogState({ open: false });

    const openAssignDriverDialog = () => setAssignDriverDialogState({ open: true });

    if (!shouldRenderButton) return null;

    return (
        <div>
            <AssignDriverDialog open={assignDriverDialogState.open} onClose={closeAssignDriverDialog} orderId={order.orderId} onSuccess={onSuccess} disabled={order.demoOrder} />
            <Button primary={primary} outlined={outlined} onClick={openAssignDriverDialog} disabled={disabled} classes={{ button: classesProp?.button }}>
                {translate('Assign a Driver')}
            </Button>
        </div>
    );
}

type Props = {
    order: OrderVm;
    onSuccess?: any;
    disabled?: boolean;
    primary?: boolean;
    outlined?: boolean;
    classes?: {
        button?: string;
    };
};
