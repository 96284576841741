import { pidedirectoReportsMethod } from 'src/api/pidedirectoReportsApi';
import type { ApiSauceResponse } from 'src/api/types/ApiSauceResponse';
import type { SalesType } from 'src/constants/SalesTypes';
import type { RestaurantId } from 'src/types/Id';
import type { SalesByManagerUserByItemReportVm } from 'src/types/SalesByManagerUserByItemReportVm';

export async function getSalesByManagerUserByItemReportApi(request: GetKardexReportApiRequest): ApiSauceResponse<SalesByManagerUserByItemReportVm> {
    return pidedirectoReportsMethod('/getSalesByManagerUserByItemReportApi', request);
}

type GetKardexReportApiRequest = {
    restaurantIds: Array<RestaurantId>;
    fromDate: string;
    toDate: string;
    salesType: SalesType;
};
