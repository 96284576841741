import { makeStyles } from '@material-ui/core';
import * as React from 'react';

export function Kpi({ value, title }: Props): React.ReactElement {
    const classes = useStyles();

    return (
        <div className={classes.container}>
            <span className={classes.value}>{value}</span>
            <span className={classes.title}>{title}</span>
        </div>
    );
}

const useStyles = makeStyles((theme) => ({
    container: {
        display: 'flex',
        flexDirection: 'column',
        flexShrink: 0,
    },
    value: {
        fontFamily: theme.typography.regular,
        fontSize: 30,
    },
    title: {
        fontFamily: theme.typography.regular,
        fontSize: 15,
    },
}));

type Props = {
    value: string;
    title: string;
};
