export function getSelectedRowsData(
    selectedRows: {
        data: Array<{
            dataIndex: number;
        }>;
    },
    displayData: Array<{
        dataIndex: number;
        data: any;
    }>,
): any {
    const selectedRowsDataIndexes = selectedRows.data.map((d) => d.dataIndex);
    return displayData.filter((d) => selectedRowsDataIndexes.includes(d.dataIndex)).map((d) => d.data);
}
