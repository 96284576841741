import { letseatmanagerApiMethod } from 'src/api/letseatmanagerApi';
import type { ApiSauceResponse } from 'src/api/types/ApiSauceResponse';
import type { DriverId, RestaurantId } from 'src/types/Id';

export async function getDriverMobileNumberOptionsApi(request: GetDriverMobileNumberOptionsApiRequest): ApiSauceResponse<GetDriverMobileNumberOptionsApiResponse> {
    return letseatmanagerApiMethod('options/getDriverMobileNumberOptionsApi', request);
}

type GetDriverMobileNumberOptionsApiRequest = {
    restaurantId: RestaurantId;
};

export type GetDriverMobileNumberOptionsApiResponse = Array<DriverMobileNumberOptionVm>;

export type DriverMobileNumberOptionVm = {
    label: string;
    value: DriverId;
    banned?: boolean;
};
