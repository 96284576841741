import { DriverAvailabilities, DriverAvailability } from 'src/constants/DriverAvailability';
import { translate } from 'src/i18n/translate';

export function translateDriverAvailability(driverAvailability: DriverAvailability): string {
    if (!driverAvailability) return '';
    switch (driverAvailability) {
        case DriverAvailabilities.NOT_REGISTERED:
            return translate('Not Registered');
        case DriverAvailabilities.BANNED:
            return translate('Banned');
        case DriverAvailabilities.REMOVED:
            return translate('Removed');
        case DriverAvailabilities.NOT_VERIFIED:
            return translate('Not Verified');
        case DriverAvailabilities.SIGNED_OUT:
            return translate('Signed Out');
        case DriverAvailabilities.OFFLINE:
            return translate('Offline');
        case DriverAvailabilities.ONLINE:
            return translate('Online');
        default:
            return translate('');
    }
}
