import { PaymentRejectReason, PaymentRejectReasons } from 'src/constants/PaymentRejectReason';
import { translate } from 'src/i18n/translate';

export function translatePaymentRejectReason(paymentRejectReason?: PaymentRejectReason | null): string {
    if (!paymentRejectReason) {
        return '';
    }
    switch (paymentRejectReason) {
        case PaymentRejectReasons.INSUFFICIENT_FUNDS: {
            return translate('This card has insufficient funds to complete the purchase. If this is not correct please contact the Card Payments Division of your bank.');
        }
        case PaymentRejectReasons.COMMUNICATION_ERROR: {
            return translate('We’re sorry, we’re having difficulty communicating with your bank. At the moment we can not process your request. Please try again later.');
        }
        case PaymentRejectReasons.RESTRICTED_CARD: {
            return translate('Your request was declined by your bank. Please contact the Card Payments Division of your bank or try with another card.');
        }
        case PaymentRejectReasons.INVALID_SECURITY_CODE: {
            return translate('Sorry, you entered an invalid security code (the last 3 digits on the backside of your card). Please remove your card and try again.');
        }
        case PaymentRejectReasons.UNCATEGORIZED:
        case PaymentRejectReasons.UNKNOWN:
        default: {
            return translate('Your request was declined by your bank. Please contact the Card Payments Division of your bank or try with another card.');
        }
    }
}
