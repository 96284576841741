import { letseatmanagerApiMethod } from 'src/api/letseatmanagerApi';
import type { ApiSauceResponse } from 'src/api/types/ApiSauceResponse';
import type { DeliveryZoneId, RestaurantId } from 'src/types/Id';

export async function removeDeliveryZoneApi(request: RemoveDeliveryZonesRequest): ApiSauceResponse<void> {
    return letseatmanagerApiMethod('deliveryZone/removeDeliveryZoneApi', request);
}

export type RemoveDeliveryZonesRequest = {
    restaurantId: RestaurantId;
    deliveryZoneId: DeliveryZoneId;
};
