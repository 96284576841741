import { letseatmanagerApiMethod } from 'src/api/letseatmanagerApi';
import type { ApiSauceResponse } from 'src/api/types/ApiSauceResponse';
import { DeviceType } from 'src/constants/DeviceType';
import type { DeviceGroupId, DeviceId, PaymentTerminalId, PrinterId, RestaurantId } from 'src/types/Id';

export async function findDeviceGroupsApi(request: FindDeviceGroupsApiRequest): ApiSauceResponse<Array<DeviceGroupDetailsVm>> {
    return letseatmanagerApiMethod('deviceGroup/findDeviceGroupsApi', request);
}

export type FindDeviceGroupsApiRequest = {
    restaurantId: RestaurantId;
};

export type DeviceGroupDetailsVm = {
    deviceGroupId: DeviceGroupId;
    deviceGroupName: string;
    devices?: Array<{
        deviceId: DeviceId;
        deviceName: string;
        deviceType?: DeviceType;
    }>;
    paymentTerminals?: Array<{
        paymentTerminalId: PaymentTerminalId;
        deviceName: string;
    }>;
    printers?: Array<{
        printerId: PrinterId;
        deviceName: string;
    }>;
    createdAt: Date;
    modifiedAt: Date;
};
