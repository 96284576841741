import { useEffect } from 'react';
import * as React from 'react';
import { useLocation } from 'react-router-dom';
import { RoutePaths } from 'src/constants/RoutePath';
import { useFetchKitchenOrdersUpdates } from 'src/utils/react/useFetchKitchenOrdersUpdates';
import { useSelector } from 'src/utils/react/useSelector';

export function FetchKitchenOrdersUpdates(): React.ReactElement | null {
    const { fetchKitchenOrdersUpdatesContinuously, fetchKitchenOrdersUpdates } = useFetchKitchenOrdersUpdates();
    const location = useLocation();

    const restaurantId = useSelector((state) => state.app.restaurantId);

    const isKitchenLocation = location.pathname === RoutePaths.KITCHEN_DISPLAY_SCREEN;

    useEffect(() => {
        if (restaurantId && isKitchenLocation) fetchKitchenOrdersUpdatesContinuously();
    }, [restaurantId, location]);

    useEffect(() => {
        if (restaurantId) fetchKitchenOrdersUpdates();
    }, [restaurantId]);

    return null;
}
