export const DeviceModels = {
    FALCON: 'FALCON',
    I2000: 'I2000',
    I9000S: 'I9000S',
    I22T01: 'I22T01', // FALCON
    K: 'K',
    K1: 'K1',
    SM_A515F: 'SM_A515F',
    D1: 'D1',
    D2: 'D2',
    D4: 'D4',
    SWAN: 'SWAN',
    IPHONE: 'IPHONE',
    IPAD: 'IPAD',
    MACINTOSH: 'MACINTOSH',
} as const;

export type DeviceModel = (typeof DeviceModels)[keyof typeof DeviceModels] | string;
