import { letseatadminApiMethod } from 'src/api/letseatadminApi';
import type { ApiSauceResponse } from 'src/api/types/ApiSauceResponse';
import type { BannerVm } from 'src/types/BannerVm';
import type { BannerId } from 'src/types/Id';

export async function getBannerApi(request: GetBannerApiRequest): ApiSauceResponse<BannerVm> {
    return letseatadminApiMethod('banner/getBannerApi', request);
}

type GetBannerApiRequest = {
    bannerId?: BannerId;
};
