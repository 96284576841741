import { makeStyles } from '@material-ui/core';
import { useEffect, useState } from 'react';
import * as React from 'react';
import { SECONDS } from 'src/constants/TimeUnit';
import { classNames } from 'src/utils/react/classNames';

// The alert should be only hidden and not removed in the DOM to keep the best accessibility practices
// https://developer.mozilla.org/en-US/docs/Web/Accessibility/ARIA/Roles/alert_role
export function Notification({ open, children, secondsOpened, classes: classesProp }: Props): React.ReactElement {
    const classes = useStyles();
    let timeout: NodeJS.Timeout | null = null;

    const [isOpened, setIsOpened] = useState(false);

    useEffect(() => {
        if (open) {
            setIsOpened(true);
            if (timeout) clearTimeout(timeout);
            timeout = setTimeout(
                () => {
                    setIsOpened(false);
                    timeout = null;
                },
                (secondsOpened || 5) * SECONDS,
            );
        }
    }, [open]);

    const getClassNames = () => {
        if (!isOpened) return '';
        return classNames(classes.primaryAlert, classesProp?.container);
    };

    return (
        <div role='alert' className={classNames(classes.alertHidden, getClassNames())}>
            {children}
        </div>
    );
}

const useStyles = makeStyles((theme) => ({
    alertHidden: {
        opacity: 0,
        position: 'absolute',
        width: '30vw',
        bottom: '-1vh',
        right: 0,
        left: 0,
        margin: '0px auto',
        padding: '15px 24px',
        backgroundColor: 'black',
        fontFamily: theme.typography.medium,
        borderRadius: 8,
        color: 'white',
        transition: 'bottom 0.15s linear, opacity 0.15s linear',
        [theme.breakpoints.down('sm')]: {
            width: '80vw',
        },
    },
    primaryAlert: {
        zIndex: 9999,
        bottom: '5vh',
        opacity: 1,
    },
}));

type Props = {
    secondsOpened?: number;
    open?: boolean;
    children: React.ReactNode;
    classes?: {
        container?: string;
    };
};
