import { letseatmanagerApiMethod } from 'src/api/letseatmanagerApi';
import type { ApiSauceResponse } from 'src/api/types/ApiSauceResponse';
import type { GeoJsonPolygonDeprecated } from 'src/types/GeoJsonPolygonDeprecated';
import type { RestaurantId } from 'src/types/Id';

export async function createDeliveryZoneApi(request: CreateDeliveryZonesRequest): ApiSauceResponse<void> {
    return letseatmanagerApiMethod('deliveryZone/createDeliveryZoneApi', request);
}

export type CreateDeliveryZonesRequest = {
    restaurantId: RestaurantId;
    name?: string;
    price: string;
    color: string;
    enabled: boolean;
    area: GeoJsonPolygonDeprecated;
};
