import { letseatmanagerApiMethod } from 'src/api/letseatmanagerApi';
import type { ApiSauceResponse } from 'src/api/types/ApiSauceResponse';
import type { SalesType } from 'src/constants/SalesTypes';
import type { CashRegisterPosBusinessDayId, RestaurantId } from 'src/types/Id';
import type { OrdersAndReportsVm } from 'src/types/OrdersAndReportsVm';

export async function getOrdersApi(request: GetOrdersApiRequest): ApiSauceResponse<OrdersAndReportsVm> {
    return letseatmanagerApiMethod('order/getOrdersApi', request);
}

export type GetOrdersApiRequest = {
    restaurantIds: Array<RestaurantId>;
    cashRegisterPosBusinessDayId?: CashRegisterPosBusinessDayId;
    from: Date;
    to: Date;
    maxOrders?: number;
    page?: number;
    salesType?: SalesType;
};
