import { makeStyles } from '@material-ui/core';
import { ResponsiveBar } from '@nivo/bar';
import * as React from 'react';
import { classNames } from 'src/utils/react/classNames';

export function BarChartCanvas({ data, keys, layout, labelTextColor, groupMode, margin, indexBy, labelY, labelX, tickRotation, tooltip, showLegends, classes }: Props): React.ReactElement {
    const defaultClasses = useStyles();
    const colors = ['#6256E9', '#339EDA', '#4ACAC2', '#53C7D7', '#A0D8F8', '#5DE3BC', '#24A8C2', '#13647D', '#A7A0F8'];

    return (
        <div className={classNames(defaultClasses.container, classes?.container)}>
            <ResponsiveBar
                layout={layout ?? 'vertical'}
                data={data}
                keys={keys}
                indexBy={indexBy}
                margin={{ top: margin?.top ?? 50, right: margin?.right ?? 30, bottom: margin?.bottom ?? 50, left: margin?.left ?? 40 }}
                padding={0.5}
                valueScale={{ type: 'linear' }}
                indexScale={{ type: 'band', round: true }}
                colors={colors}
                borderColor={{
                    from: 'color',
                    modifiers: [['darker', 1.6]],
                }}
                axisTop={null}
                axisRight={null}
                axisBottom={{
                    tickSize: 0,
                    tickPadding: 5,
                    tickRotation: tickRotation ?? 45,
                    legend: labelX,
                    legendPosition: 'middle',
                    legendOffset: 45,
                }}
                axisLeft={{
                    tickSize: 5,
                    tickPadding: 5,
                    tickRotation: 0,
                    legend: labelY,
                    legendPosition: 'middle',
                    legendOffset: -40,
                }}
                labelSkipWidth={12}
                labelSkipHeight={12}
                labelTextColor={
                    labelTextColor ?? {
                        from: 'color',
                        modifiers: [['darker', 1.6]],
                    }
                }
                animate={true}
                motionConfig={'molasses'}
                /* @ts-ignore */
                motionStiffness={90}
                motionDamping={15}
                tooltip={tooltip}
                groupMode={groupMode}
                legends={
                    showLegends
                        ? [
                              {
                                  dataFrom: 'keys',
                                  anchor: 'bottom-right',
                                  direction: 'column',
                                  justify: false,
                                  translateX: 120,
                                  translateY: 0,
                                  itemsSpacing: 2,
                                  itemWidth: 100,
                                  itemHeight: 20,
                                  itemDirection: 'left-to-right',
                                  itemOpacity: 0.85,
                                  symbolSize: 20,
                                  effects: [
                                      {
                                          on: 'hover',
                                          style: {
                                              itemOpacity: 1,
                                          },
                                      },
                                  ],
                              },
                          ]
                        : undefined
                }
            />
        </div>
    );
}

const useStyles = makeStyles((theme) => ({
    container: {
        width: '100%',
        height: 400,
    },
}));

type Props = {
    data: Array<any>;
    keys?: Array<string>;
    layout?: 'horizontal' | 'vertical';
    margin?: {
        top?: number;
        right?: number;
        bottom?: number;
        left?: number;
    };
    groupMode?: 'grouped' | 'stacked';
    labelTextColor?: string;
    indexBy?: string;
    labelY?: string;
    labelX?: string;
    style?: any;
    tooltip?: any;
    showLegends?: boolean;
    tickRotation?: number;
    classes?: {
        container?: string;
    };
};
