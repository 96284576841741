import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Grid from '@material-ui/core/Grid';
import RadioGroup from '@material-ui/core/RadioGroup';
import { withStyles } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import * as React from 'react';
import { withRouter } from 'react-router-dom';
import { letseatmanagerApiDeprecated } from 'src/api/letseatmanagerApiDeprecated';
import { Field, Form } from 'src/components/deprecated/react-final-form/Form';
import Radio from 'src/components/deprecated/react-final-form/Radio';
import TextField from 'src/components/deprecated/react-final-form/TextField';
import { Spacing } from 'src/components/spacing/Spacing';
import { UpdatingContentProgress } from 'src/components/UpdatingContentProgress';
import { history } from 'src/config/history';
import { translate } from 'src/i18n/translate';
import { appColors } from 'src/theme/AppColors';
import { appLayout } from 'src/theme/AppLayout';
import type { BenefitPlanId, CompanyId, CustomerId, EmployeeId, EmployeeIdAtCompany, EmployeeNumberAtCompany } from 'src/types/Id';
import { alertKnownErrorOrSomethingWentWrong } from 'src/utils/alert/alertKnownErrorOrSomethingWentWrong';
import { deleteUrlQueryParameter } from 'src/utils/history/deleteUrlQueryParameter';
import { getUrlQueryParameter } from 'src/utils/history/getUrlQueryParameter';
import { isValidEmployeePassword } from 'src/utils/is/isValidEmployeePassword';
import { required } from 'src/utils/Validator';

export default withRouter<any, any>(
    withStyles((theme) => ({
        linearProgress: {
            position: 'absolute',
            width: '100%',
            bottom: 0,
        },
        deleteButton: {
            color: appColors.text.dangerLight,
        },
        leftActions: {
            justifyContent: 'space-between',
        },
    }))(
        class ChangeEmployeeDialog extends React.PureComponent<Props, State> {
            state: any = INITIAL_STATE;

            async componentDidMount(): Promise<void> {
                const employeeId: EmployeeId = getUrlQueryParameter(CHANGE_EMPLOYEE, this.props.location);
                if (employeeId) {
                    await this.openDialog(employeeId);
                }
            }

            async componentDidUpdate(prevProps: Props) {
                const prevEmployeeId: EmployeeId = getUrlQueryParameter(CHANGE_EMPLOYEE, prevProps.location);
                const employeeId: EmployeeId = getUrlQueryParameter(CHANGE_EMPLOYEE, this.props.location);
                if (employeeId && !prevEmployeeId && !this.state.open) {
                    await this.openDialog(employeeId);
                } else if (!employeeId && prevEmployeeId && this.state.open) {
                    this.closeDialog();
                }
            }

            handleClose = () => {
                if (this.state.submitting) {
                    return;
                }
                if (history.length <= 2) {
                    history.replace({
                        path: this.props.location.pathname,
                        search: deleteUrlQueryParameter(CHANGE_EMPLOYEE),
                    });
                    return;
                }
                history.goBack();
            };

            handleExited = () => {
                this.setState(INITIAL_STATE as any);
            };

            openDialog = async (employeeId: EmployeeId) => {
                this.setState({ open: true });
                await this.load(employeeId);
            };

            closeDialog = () => {
                this.setState({ open: false });
            };

            load = async (employeeId: EmployeeId) => {
                this.setState({ loading: true });
                const response = await letseatmanagerApiDeprecated.admin.getEmployee({ employeeId });
                this.setState({ loading: false });
                if (!response.ok) {
                    alertKnownErrorOrSomethingWentWrong(response);
                    this.setState({ open: false });
                    return;
                }
                const employee: EmployeeVm = response.data;
                this.setState({
                    employee: {
                        employeeId: employee.employeeId,
                        benefitPlanId: employee.benefitPlanId,
                        customerId: employee.customerId,
                        email: employee.email,
                        password: employee.password,
                        employeeIdAtCompany: employee.employeeIdAtCompany,
                        employeeNumberAtCompany: employee.employeeNumberAtCompany,
                        firstName: employee.firstName,
                        lastName: employee.lastName,
                        paused: employee.paused ? 'true' : 'false',
                    },
                });
            };

            onSubmit = async (form: any) => {
                if (!!form.employee.password && !isValidEmployeePassword(form.employee.password)) {
                    this.setState({ errorMessage: translate('Password must be numeric and 4 characters long') });
                    return;
                }

                this.setState({ submitting: true });
                const response = await letseatmanagerApiDeprecated.admin.changeEmployee({
                    employeeId: form.employee.employeeId,
                    benefitPlanId: form.employee.benefitPlanId,
                    customerId: form.employee.customerId,
                    email: form.employee.email,
                    password: form.employee.password,
                    employeeIdAtCompany: form.employee.employeeIdAtCompany ?? null,
                    employeeNumberAtCompany: form.employee.employeeNumberAtCompany ?? null,
                    firstName: form.employee.firstName,
                    lastName: form.employee.lastName,
                    paused: form.employee.paused === 'true',
                } as ChangeEmployeeRequest);
                this.setState({ submitting: false });
                if (!response.ok) {
                    alertKnownErrorOrSomethingWentWrong(response);
                    return;
                }
                this.handleClose();
                this.props.onEmployeeChanged();
            };

            handleClickRemove = async () => {
                const remove = window.confirm('Are you sure you want to remove this employee?');
                if (remove) {
                    await this.removeEmployee();
                }
            };

            removeEmployee = async () => {
                const employeeId: EmployeeId = getUrlQueryParameter(CHANGE_EMPLOYEE, this.props.location);
                this.setState({ submitting: true });
                const response = await letseatmanagerApiDeprecated.admin.removeEmployee({ employeeId });
                this.setState({ submitting: false });
                if (!response.ok) {
                    alertKnownErrorOrSomethingWentWrong(response);
                    return;
                }
                this.props.onEmployeeChanged(true);
                if (history.length <= 2) {
                    history.replace({
                        path: this.props.location.pathname,
                        search: deleteUrlQueryParameter(CHANGE_EMPLOYEE),
                    });
                    return;
                }
                history.go(-2);
            };

            render() {
                const { classes } = this.props;
                return (
                    <Dialog aria-labelledby='change-employee-dialog-title' fullWidth scroll='body' open={this.state.open} onClose={this.handleClose} onExited={this.handleExited}>
                        <Form
                            onSubmit={this.onSubmit}
                            initialValues={{
                                employee: this.state.employee,
                            }}
                            render={({ handleSubmit, submitting, pristine, values }) => (
                                <form onSubmit={handleSubmit} noValidate style={appLayout.dialog.form}>
                                    <DialogTitle id='change-employee-dialog-title'>{translate('Change Employee').toUpperCase()}</DialogTitle>
                                    <DialogContent>{this.renderDialogContent()}</DialogContent>
                                    <DialogActions className={classes.leftActions}>
                                        <div>
                                            <Tooltip title='Remove Employee'>
                                                <Button onClick={this.handleClickRemove} disabled={this.state.submitting} tabIndex='-1' className={classes.deleteButton}>
                                                    {translate('Remove Employee').toUpperCase()}
                                                </Button>
                                            </Tooltip>
                                        </div>
                                        <div>
                                            <Button onClick={this.handleClose} disabled={this.state.submitting}>
                                                {translate('Cancel').toUpperCase()}
                                            </Button>
                                            <Button color='primary' type='submit' disabled={this.state.submitting}>
                                                {this.state.submitting ? translate('Changing').toUpperCase() : translate('Change').toUpperCase()}
                                            </Button>
                                        </div>
                                    </DialogActions>
                                    <UpdatingContentProgress loading={this.state.loading || this.state.submitting} top />
                                </form>
                            )}
                        />
                    </Dialog>
                );
            }

            renderDialogContent() {
                return (
                    <>
                        <Grid container spacing={3}>
                            <Grid item xs={12}>
                                <Field
                                    name='employee.firstName'
                                    component={TextField}
                                    label={translate('First Name')}
                                    fullWidth
                                    disabled={this.state.submitting}
                                    required
                                    validate={required}
                                    inputProps={{ autoComplete: 'no' }}
                                />
                            </Grid>

                            <Grid item xs={12}>
                                <Field
                                    name='employee.lastName'
                                    component={TextField}
                                    label={translate('Last Name')}
                                    fullWidth
                                    disabled={this.state.submitting}
                                    required
                                    validate={required}
                                    inputProps={{ autoComplete: 'no' }}
                                />
                            </Grid>

                            <Grid item xs={12}>
                                <Field
                                    name='employee.email'
                                    component={TextField}
                                    label={translate('Email')}
                                    fullWidth
                                    disabled={this.state.submitting}
                                    required
                                    validate={required}
                                    inputProps={{ autoComplete: 'no' }}
                                />
                            </Grid>

                            <Grid item xs={12}>
                                <Field
                                    name='employee.employeeIdAtCompany'
                                    component={TextField}
                                    label={translate('Employee Id')}
                                    fullWidth
                                    disabled={this.state.submitting}
                                    inputProps={{ autoComplete: 'no' }}
                                />
                            </Grid>

                            <Grid item xs={12}>
                                <Field
                                    name='employee.employeeNumberAtCompany'
                                    component={TextField}
                                    label={translate('Employee Number')}
                                    fullWidth
                                    disabled={this.state.submitting}
                                    inputProps={{ autoComplete: 'no' }}
                                />
                            </Grid>
                            {/* @ts-ignore */}
                            <Tooltip text={translate('Password used for letseat kiosk customers')}>
                                <Grid item xs={12}>
                                    <Field name='employee.password' component={TextField} label={translate('Password')} fullWidth inputProps={{ autoComplete: 'no' }} />
                                </Grid>
                            </Tooltip>
                            <Grid item xs={12}>
                                <RadioGroup row>
                                    <FormControlLabel
                                        label='Active'
                                        control={<Field name='employee.paused' component={Radio} type='radio' color='primary' value='false' disabled={this.state.submitting} />}
                                    />
                                    <FormControlLabel
                                        label='Paused'
                                        control={<Field name='employee.paused' component={Radio} type='radio' color='primary' value='true' disabled={this.state.submitting} />}
                                    />
                                </RadioGroup>
                            </Grid>
                        </Grid>

                        <Grid container justify='space-around'>
                            <Grid item>
                                <Spacing units={8} />
                                {this.state.errorMessage && (
                                    <Typography display='block' color='error'>
                                        {this.state.errorMessage}
                                    </Typography>
                                )}
                            </Grid>
                        </Grid>
                    </>
                );
            }
        },
    ),
) as React.ComponentType<any>;

export const CHANGE_EMPLOYEE = 'change-employee';
const INITIAL_STATE = {
    open: false,
    employee: {
        employeeId: '' as any,
        benefitPlanId: '' as any,
        customerId: '' as any,
        email: '',
        password: '',
        employeeIdAtCompany: '',
        employeeNumberAtCompany: '',
        firstName: '',
        lastName: '',
        paused: 'false',
    },
    loading: false,
    submitting: false,
} as const;

type Props = {
    classes: any;
    location: {
        pathname: string;
    };
    onEmployeeChanged: any;
};

type State = {
    open: boolean;
    loading: boolean;
    submitting: boolean;
    employee: {
        employeeId: EmployeeId;
        benefitPlanId: BenefitPlanId;
        customerId: CustomerId;
        email: string;
        password?: string;
        employeeIdAtCompany: string;
        employeeNumberAtCompany: string;
        firstName: string;
        lastName: string;
        paused: string;
    };
    errorMessage?: string;
};

type EmployeeVm = {
    employeeId: EmployeeId;
    companyId: CompanyId;
    benefitPlanId: BenefitPlanId;
    customerId: CustomerId;
    email: string;
    password?: string;
    employeeIdAtCompany: EmployeeIdAtCompany;
    employeeNumberAtCompany: EmployeeNumberAtCompany;
    firstName: string;
    lastName: string;
    paused?: boolean;
    connectedAt?: Date;
    createdAt: Date;
    modifiedAt: Date;
};

type ChangeEmployeeRequest = {
    employeeId: EmployeeId;
    benefitPlanId?: BenefitPlanId | null | undefined;
    customerId: CustomerId;
    email?: string;
    password?: string;
    employeeIdAtCompany?: EmployeeIdAtCompany | null | undefined;
    employeeNumberAtCompany?: EmployeeNumberAtCompany | null | undefined;
    firstName?: string | null | undefined;
    lastName?: string | null | undefined;
    paused?: boolean | null | undefined;
};
