import { makeStyles } from '@material-ui/core';
import * as React from 'react';
import { useHistory } from 'react-router-dom';
import { SecuredAndSubscribedPage } from 'src/components/page/SecuredAndSubscribedPage';
import { Tabs } from 'src/components/Tabs';
import { RolePermissions } from 'src/constants/RolePermission';
import { RoutePaths } from 'src/constants/RoutePath';
import { translate } from 'src/i18n/translate';
import { Inventory } from 'src/scenes/letseatmanager/inventory/inventory/Inventory';
import { InventoryEmptyState } from 'src/scenes/letseatmanager/inventory/InventoryEmptyState';
import { useSelector } from 'src/utils/react/useSelector';

export function InventoryPage(): React.ReactElement {
    const classes = useStyles();
    const history = useHistory();

    const inventoryEnabled = useSelector((state) => state.app.restaurant.inventoryEnabled);
    const buyOrdersEnabled = useSelector((state) => state.app.restaurant.buyOrdersEnabled);

    const tabs = [
        {
            value: '1',
            label: translate('Inventory'),
            rolePermission: RolePermissions.INVENTORY_PAGE,
            onClick: () => {
                history.push({
                    pathname: RoutePaths.INVENTORY,
                    search: history.location.search,
                });
            },
        },
        {
            value: '2',
            label: translate('Purchases'),
            rolePermission: RolePermissions.BUY_ORDERS_PAGE,
            hidden: !buyOrdersEnabled,
            onClick: () => {
                history.push({
                    pathname: RoutePaths.INVENTORY_BUY_ORDER,
                    search: history.location.search,
                });
            },
        },
        {
            value: '3',
            label: translate('Suppliers'),
            rolePermission: RolePermissions.SUPPLIERS_PAGE,
            onClick: () => {
                history.push({
                    pathname: RoutePaths.INVENTORY_SUPPLIER,
                    search: history.location.search,
                });
            },
        },
        {
            value: '4',
            label: translate('Supplies'),
            rolePermission: RolePermissions.SUPPLIES_PAGE,
            onClick: () => {
                history.push({
                    pathname: RoutePaths.INVENTORY_SUPPLY,
                    search: history.location.search,
                });
            },
        },
        {
            value: '5',
            label: translate('Recipes'),
            rolePermission: RolePermissions.RECIPES_PAGE,
            onClick: () => {
                history.push({
                    pathname: RoutePaths.INVENTORY_RECIPE,
                    search: history.location.search,
                });
            },
        },
        {
            value: '6',
            label: translate('Production'),
            rolePermission: RolePermissions.INVENTORY_PRODUCTION,
            onClick: () => {
                history.push({
                    pathname: RoutePaths.INVENTORY_PRODUCTION,
                    search: history.location.search,
                });
            },
        },
    ];

    if (!inventoryEnabled) {
        return (
            <SecuredAndSubscribedPage rolePermission={RolePermissions.INVENTORY_PAGE} title={translate('Inventory')}>
                <InventoryEmptyState />
            </SecuredAndSubscribedPage>
        );
    }

    return (
        <SecuredAndSubscribedPage rolePermission={RolePermissions.INVENTORY_PAGE} title={translate('Inventory')} className={classes.container}>
            <Tabs tabs={tabs} defaultValue={'1'}>
                <Inventory />
            </Tabs>
        </SecuredAndSubscribedPage>
    );
}

const useStyles = makeStyles((theme) => ({
    container: {
        width: '100%',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
    },
    tab: {
        fontFamily: theme.typography.medium,
        width: 'fit-content',
        minHeight: 'fit-content',
        minWidth: 100,
        textTransform: 'none',
        borderRadius: 5,
        margin: '0 10px',
        padding: '5px 10px',
    },
    tabSelected: {
        backgroundColor: theme.palette.primary.main,
        color: 'white',
    },
}));
