import { letseatadminApiMethod } from 'src/api/letseatadminApi';
import type { ApiSauceResponse } from 'src/api/types/ApiSauceResponse';
import type { UserType } from 'src/constants/UserType';
import type { RestaurantId, UserRoleId } from 'src/types/Id';
import type { ManagerUserVm } from 'src/types/ManagerUserVm';

export async function createManagerUserApi(request: CreateManagerUserApiRequest): ApiSauceResponse<ManagerUserVm> {
    return letseatadminApiMethod('managerUser/createManagerUserApi', request);
}

export type CreateManagerUserApiRequest = {
    userRole?: UserRoleId;
    username: string;
    userType: UserType;
    restaurantIds?: Array<RestaurantId>;
};
