import { makeStyles } from '@material-ui/core';
import { BigNumber } from 'bignumber.js';
import * as React from 'react';
import { translate } from 'src/i18n/translate';
import { useFormatAsRestaurantCurrencyNumber } from 'src/services/restaurant/useFormatAsRestaurantCurrencyNumber';
import { useSelector } from 'src/utils/react/useSelector';

export function PosCartSummary(): React.ReactElement {
    const classes = useStyles();
    const formatAsCurrencyNumber = useFormatAsRestaurantCurrencyNumber();

    const subtotal = useSelector((state) => state.pos.payment?.subtotal);
    const code = useSelector((state) => state.pos.payment?.promoCode);
    const promoCodeCredits = useSelector((state) => state.pos.payment?.promoCodeCredits);
    const promoCodeDiscount = useSelector((state) => state.pos.payment?.promoCodeDiscount);
    const promotionsDiscount = useSelector((state) => state.pos.payment?.promotionsDiscount);
    const total = useSelector((state) => state.pos.payment?.total);
    const driverRequest = useSelector((state) => state.pos.driverRequest);
    const pendingOrder = useSelector((state) => state.pos.pendingOrder);
    const usedCustomerCredits = useSelector((state) => state.pos.usedCustomerCredits);

    return (
        <>
            <div>
                {driverRequest && !driverRequest?.deliveryPaidByRestaurant && (
                    <div className={classes.containerResume}>
                        <p className={classes.resumeText}>{translate('Delivery')}</p>
                        <span className={classes.resumeText}>{formatAsCurrencyNumber(driverRequest?.deliveryCost)}</span>
                    </div>
                )}
                <div className={classes.containerResume}>
                    <p className={classes.resumeText}>{translate('Articles Subtotal')}</p>
                    <span className={classes.resumeText}>{formatAsCurrencyNumber(subtotal ?? 0)}</span>
                </div>
                {!BigNumber(promotionsDiscount ?? 0).isZero() && (
                    <div className={classes.containerResume}>
                        <p className={classes.resumeText}>{translate('Promotions discount')}</p>
                        <span className={classes.resumeText}>-{formatAsCurrencyNumber(promotionsDiscount ?? 0)}</span>
                    </div>
                )}
                <div className={classes.line}></div>
                <div className={classes.totalContainer}>
                    <span className={classes.totalText}>{translate('Total')}</span>
                    <span className={classes.totalText}>{formatAsCurrencyNumber(total)}</span>
                </div>
            </div>

            {(code || pendingOrder?.promoCode) && (
                <div style={{ width: '100%' }}>
                    <div className={classes.containerResume}>
                        <span className={classes.resumeText}>{code ?? pendingOrder.promoCode}</span>
                        <span className={classes.resumeText}>{promoCodeCredits ?? `- ${formatAsCurrencyNumber(promoCodeDiscount)}`}</span>
                    </div>
                    {promoCodeCredits && (
                        <div className={classes.resumeText} style={{ fontSize: 12 }}>
                            {translate('Credits')}
                        </div>
                    )}
                </div>
            )}
            {usedCustomerCredits && usedCustomerCredits && (
                <div className={classes.containerResume}>
                    <p className={classes.resumeText}>{translate('Credits')}</p>
                    <span className={classes.resumeText}>{`- ${usedCustomerCredits}`}</span>
                </div>
            )}
        </>
    );
}

const useStyles = makeStyles((theme) => ({
    containerResume: {
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
    },
    resumeText: {
        fontFamily: theme.typography.regular,
        fontSize: 14,
        margin: 0,
        color: '#87888B',
    },
    totalContainer: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        [theme.breakpoints.down('sm')]: {
            marginBottom: 12,
        },
    },
    totalText: {
        fontFamily: theme.typography.semiBold,
        fontSize: 14,
        margin: 0,
        color: '#2E3748',
    },
    line: {
        width: '100%',
        height: 1,
        borderTop: '1px dashed #87888B',
        margin: '12px 0',
    },
}));
