import { letseatadminApiMethod } from 'src/api/letseatadminApi';
import type { ApiSauceResponse } from 'src/api/types/ApiSauceResponse';
import type { AwsCognitoUsername, ManagerUserId } from 'src/types/Id';

export async function findSupportUserDetailsApi(request: FindSupportUserDetailsApiRequest): ApiSauceResponse<Array<SupportUserDetailVm>> {
    return letseatadminApiMethod('supportUserDetails/findSupportUserDetailsApi', request);
}

type FindSupportUserDetailsApiRequest = {
    from: string; // YYYY-MM-DD,
    to: string; // YYYY-MM-DD
};

export type SupportUserDetailVm = {
    managerUserId: ManagerUserId;
    username?: AwsCognitoUsername;
    cancellations: number;
    manualEarnings: number;
    manualAssignments: number;
};
