import { useEffect } from 'react';
import { useLoadModifierGroups } from 'src/services/modifierGroup/useLoadModifierGroups';
import type { ModifierGroupVm } from 'src/types/ModifierGroupVm';
import { useSelector } from 'src/utils/react/useSelector';

export function useModifierGroups(): ModifierGroups {
    const modifierGroups = useSelector((state) => state.menu.modifierGroups);
    const modifierGroupsLoaded = useSelector((state) => state.menu.modifierGroupsLoaded);
    const newMenuManagementEnabled = useSelector((state) => state.app.restaurant.newMenuManagementEnabled);
    const restaurantId = useSelector((state) => state.app.restaurantId);

    const loadModifierGroups = useLoadModifierGroups();

    useEffect(() => {
        if (newMenuManagementEnabled && !modifierGroups?.length && !modifierGroupsLoaded && restaurantId) {
            loadModifierGroups();
        }
    }, [newMenuManagementEnabled, modifierGroups, modifierGroupsLoaded, restaurantId]);

    return {
        modifierGroups,
    };
}

type ModifierGroups = {
    modifierGroups: Array<ModifierGroupVm>;
};
