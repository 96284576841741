import { letseatmanagerApiMethod } from 'src/api/letseatmanagerApi';
import type { ApiSauceResponse } from 'src/api/types/ApiSauceResponse';
import type { CashRegisterPosBusinessDayId } from 'src/types/Id';
import { CashRegisterPosBusinessDayVm } from 'src/types/PosBusinessDayVm';

export async function assignCashRegisterPosBusinessDayForExternalMissingOrdersApi(
    request: AssignCashRegisterForExternalMissingOrdersApiRequest,
): ApiSauceResponse<AssignCashRegisterForExternalMissingOrdersApiResponse> {
    return letseatmanagerApiMethod('pos/assignCashRegisterPosBusinessDayForExternalMissingOrdersApi', request);
}

export type AssignCashRegisterForExternalMissingOrdersApiRequest = {
    fromCashRegisterPosBusinessDayId?: CashRegisterPosBusinessDayId;
    toCashRegisterPosBusinessDayId: CashRegisterPosBusinessDayId;
};

type AssignCashRegisterForExternalMissingOrdersApiResponse = {
    cashRegisterPosBusinessDay: CashRegisterPosBusinessDayVm;
};
