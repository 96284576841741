import { useState } from 'react';
import { findPosBusinessDayApi } from 'src/api/letseatmanager/pos/restaurantPosBusinessDay/findPosBusinessDayApi';
import { posReducer } from 'src/reducers/posReducer';
import { useAction } from 'src/utils/react/useAction';
import { useSelector } from 'src/utils/react/useSelector';

export function useLoadPosBusinessDay(): any {
    const [loading, setLoading] = useState(false);

    const restaurantId = useSelector((state) => state.app.restaurantId);

    const setOpenedPosBusinessDay = useAction(posReducer.actions.setOpenedPosBusinessDay);

    const loadPosBusinessDay = async () => {
        if (!restaurantId || loading) return;
        setLoading(true);
        const response = await findPosBusinessDayApi({ restaurantId });
        setLoading(false);
        if (!response.ok) {
            return;
        }

        setOpenedPosBusinessDay(response.data);
    };

    return loadPosBusinessDay;
}
