import Grid from '@material-ui/core/Grid';
import { Button, Dialog, DialogActions, DialogContent } from '@pidedirecto/ui';
import * as React from 'react';
import { useForm } from 'react-hook-form';
import { cloneRestaurantwithSettingsAndMenuWithDemoModeEnabledOnAndNewNameApi } from 'src/api/letseatadmin/demoModeRestaurants/cloneRestaurantwithSettingsAndMenuWithDemoModeEnabledOnAndNewNameApi';
import { Form } from 'src/components/form/Form';
import { FormFixErrorHint } from 'src/components/form/FormFixErrorHint';
import { FormRestaurantAutocomplete } from 'src/components/form/FormRestaurantAutocomplete';
import { FormTextField } from 'src/components/form/FormTextField';
import { translate } from 'src/i18n/translate';
import { appLayout } from 'src/theme/AppLayout';
import { alertKnownErrorOrSomethingWentWrong } from 'src/utils/alert/alertKnownErrorOrSomethingWentWrong';

export function CloneRestaurantToDemoRestaurantDialog({ open, onClose, loading, onSuccess }: Props): React.ReactElement {
    const form = useForm();
    const {
        formState: { isSubmitting },
    } = form;

    const handleClose = () => {
        if (isSubmitting) return;
        onClose();
    };

    const onSubmit = async (form: { originalRestaurantId: any; newRestaurantName: any }) => {
        const response = await cloneRestaurantwithSettingsAndMenuWithDemoModeEnabledOnAndNewNameApi({
            restaurantId: form.originalRestaurantId,
            newRestaurantName: form.newRestaurantName,
        });
        if (!response.ok) {
            alertKnownErrorOrSomethingWentWrong(response);
            return;
        }

        onSuccess();
        onClose();
    };

    return (
        <Dialog title={translate('Clone A Restaurant to Demo Mode').toUpperCase()} loading={loading} open={open} onClose={handleClose}>
            <Form form={form} onSubmit={onSubmit} style={appLayout.dialog.form}>
                <DialogContent>
                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                            <FormRestaurantAutocomplete name='originalRestaurantId' label={translate('Main Restaurant')} required />
                        </Grid>
                        <Grid item xs={12}>
                            <FormTextField name='newRestaurantName' label={translate('New Restaurant Name')} required />
                        </Grid>
                    </Grid>
                    <FormFixErrorHint />
                </DialogContent>
                <DialogActions>
                    <Button variant='secondary' onClick={handleClose} disabled={isSubmitting}>
                        {translate('Cancel')}
                    </Button>
                    <Button type='submit' disabled={isSubmitting}>
                        {isSubmitting ? translate('Clonning') : translate('Clone')}
                    </Button>
                </DialogActions>
            </Form>
        </Dialog>
    );
}

type Props = {
    open: boolean;
    onClose: () => void;
    loading: boolean;
    onSuccess: () => void;
};
