import { BigNumber } from 'bignumber.js';
import moment from 'moment';
import { savePosOrderApi, SavePosOrderApiRequest } from 'src/api/letseatmanager/pos/savePosOrderApi';
import type { ApiResponse } from 'src/api/types/ApiSauceResponse';
import { OrderStatuses } from 'src/constants/OrderStatus';
import { OrderTypes } from 'src/constants/OrderType';
import { PickupTimeTypes } from 'src/constants/PickupTimeType';
import { posReducer } from 'src/reducers/posReducer';
import { getCashAmountPaid } from 'src/services/pos/getCashAmountPaid';
import { useOrderPaymentMethod } from 'src/services/pos/useOrderPaymentMethod';
import { usePreparationTime } from 'src/services/restaurant/usePreparationTime';
import { CartItemVm } from 'src/types/CartItemVm';
import type { OrderVm } from 'src/types/OrderVm';
import { PosPayment } from 'src/types/PosPayment';
import { removeDuplicates } from 'src/utils/array/removeDuplicates';
import { isPossiblePhoneNumber } from 'src/utils/is/isPossiblePhoneNumber';
import { isDeliveryOrder } from 'src/utils/order/isDeliveryOrder';
import { mapCustomerToOrderCustomer } from 'src/utils/order/mapCustomerToOrderCustomer';
import { isCardPayment } from 'src/utils/paymentMethod/isCardPayment';
import { isCashPayment } from 'src/utils/paymentMethod/isCashPayment';
import { mapCartItemToOrderItem } from 'src/utils/pos/mapCartItemToOrderItem';
import { mapPosCourseToOrderCourse } from 'src/utils/pos/mapPosCourseToOrderCourse';
import { useAction } from 'src/utils/react/useAction';
import { useMappedOrderPaymentsVm } from 'src/utils/react/useMappedOrderPaymentsVm';
import { useMapPosPayments } from 'src/utils/react/useMapPosPayments';
import { useMapPosTips } from 'src/utils/react/useMapPosTips';
import { useSelector } from 'src/utils/react/useSelector';

export function useSaveNewPosOrder(): {
    saveNewPosOrder: (arg1: SaveNewOrderParams) => Promise<SavePendingOrderResult>;
} {
    const mappedPosPayments = useMapPosPayments();
    const mappedPayments = useMappedOrderPaymentsVm();
    const mappedPosTips = useMapPosTips();
    const orderPaymentMethod = useOrderPaymentMethod();
    const preparationTime = usePreparationTime();

    const total = useSelector((state) => state.pos.payment?.total);
    const restaurantId = useSelector((state) => state.app.restaurantId);
    const orderType = useSelector((state) => state.pos.orderType);
    const driverRequest = useSelector((state) => state.pos.driverRequest);
    const items = useSelector((state) => state.pos.items);
    const cancelledItems = useSelector((state) => state.pos.cancelledItems);
    const discount = useSelector((state) => state.pos.discount);
    const payments = useSelector((state) => state.pos.payments);
    const totalPaid = useSelector((state) => state.pos.totalPaid);
    const notes = useSelector((state) => state.pos.notes);
    const pickupTimeType = useSelector((state) => state.pos.pickupTimeType);
    const pickupTime = useSelector((state) => state.pos.pickupTime);
    const promoCode = useSelector((state) => state.pos.promoCode);
    const customerId = useSelector((state) => state.pos.customerId);
    const table = useSelector((state) => state.pos.table);
    const pinCodeUserManagerUserId = useSelector((state) => state.authentication.pinCodeUserSignedIn?.managerUserId);
    const useLetsEatCredits = useSelector((state) => state.pos.useLetsEatCredits);
    const usedCustomerCredits = useSelector((state) => state.pos.usedCustomerCredits);
    const customerName = useSelector((state) => state.pos.customerName);
    const customers = useSelector((state) => state.pos.customers);
    const openedCashRegister = useSelector((state) => state.pos.openedCashRegister);
    const openedPosBusinessDay = useSelector((state) => state.pos.openedPosBusinessDay);
    const openedCashRegisterPosBusinessDay = useSelector((state) => state.pos.openedCashRegisterPosBusinessDay);
    const courses = useSelector((state) => state.pos.ongoingCourses);
    const usedPromotions = useSelector((state) => state.pos.payment?.usedPromotions);

    const setDisabledPosPaymentActions = useAction(posReducer.actions.setDisabledPosPaymentActions);

    const hasPaymentMissing = BigNumber(totalPaid).isLessThan(total);
    const isOnlyPaidInCash = payments?.every((payment: PosPayment) => isCashPayment(payment.paymentMethod) && !payment.customPaymentMethod);
    const isOnlyPaidInCard = payments?.every((payment: PosPayment) => isCardPayment(payment.paymentMethod) && !payment.customPaymentMethod);

    const getOrderStatus = () => {
        if (hasPaymentMissing) return OrderStatuses.PENDING;
        if (orderType === OrderTypes.DELIVERY_ORDER) return OrderStatuses.NEW;
        return OrderStatuses.COMPLETE;
    };

    const saveNewPosOrder = async ({
        firstName,
        lastName,
        mobileNumber,
        email,
        customerNote,
        numberOfCustomers,
        orderStatus,
        uruguayanRucInvoice,
    }: SaveNewOrderParams): Promise<SavePendingOrderResult> => {
        setDisabledPosPaymentActions(true);

        const now = new Date();

        const request: SavePosOrderApiRequest = {
            cashRegisterId: openedCashRegister?.cashRegisterId,
            posBusinessDayId: openedPosBusinessDay?.posBusinessDayId,
            cashRegisterPosBusinessDayId: openedCashRegisterPosBusinessDay?.cashRegisterPosBusinessDayId,
            restaurantId,
            customerId,
            promoCodeId: promoCode?.promoCodeId,
            restaurantZoneId: table?.restaurantZoneId,
            waiterId: pinCodeUserManagerUserId,
            promotionIds: removeDuplicates(usedPromotions?.map((promotion) => promotion.promotionId)),
            orderType,
            orderStatus: orderStatus ?? getOrderStatus(),
            firstName: firstName ?? customerName?.firstName,
            lastName: lastName ?? customerName?.lastName,
            mobileNumber: isPossiblePhoneNumber(mobileNumber) ? mobileNumber : undefined,
            email,
            customerNote,
            address: driverRequest?.address,
            deliveryEstimateId: driverRequest?.deliveryEstimateId,
            deliveryPaidByRestaurant: driverRequest?.deliveryPaidByRestaurant,
            deliveryPaidByPideDirecto: driverRequest?.deliveryPaidByPideDirecto,
            deliveryCost: driverRequest?.deliveryCost,
            paymentMethod: orderPaymentMethod,
            posPayments: mappedPosPayments,
            payments: mappedPayments,
            table: table?.table,
            numberOfCustomers: parseFloat(numberOfCustomers as any) ?? parseFloat(table?.numberOfCustomers as any),
            pickupTimeType: getPickupTimeType(),
            pickupTime: getPickupTime(),
            orderItems: items?.map((item: CartItemVm) => ({ ...mapCartItemToOrderItem(item), addedAt: item?.addedAt ?? now })),
            cancelledItems: cancelledItems?.map((item: CartItemVm) => ({ ...mapCartItemToOrderItem(item), removedAt: item?.removedAt ?? now })),
            posDiscount: discount,
            posDiscountPercentage: discount?.discount,
            tips: mappedPosTips,
            useLetsEatCredits,
            usedCustomerCredits,
            notes,
            customers: customers?.map(mapCustomerToOrderCustomer),
            courses: courses?.map(mapPosCourseToOrderCourse),
            uruguayanRucInvoice,
        };

        if ((!isOnlyPaidInCash || !isOnlyPaidInCard) && !!payments.length) {
            request.partiallyPaidByCash = true;
            request.partiallyPaidByCashAmount = getCashAmountPaid(payments);
        }
        const response = await savePosOrderApi(request);
        setDisabledPosPaymentActions(false);

        return { response, request };
    };

    const getPickupTime = () => {
        if (isDeliveryOrder(orderType)) {
            if (driverRequest.pickupTime) return driverRequest.pickupTime;
            return moment().add(preparationTime, 'minute').toDate();
        }
        return moment(pickupTime).toDate();
    };

    const getPickupTimeType = () => {
        if (isDeliveryOrder(orderType)) {
            return driverRequest.pickupTimeType;
        }
        return pickupTimeType ?? PickupTimeTypes.ASAP;
    };

    return { saveNewPosOrder };
}

type SavePendingOrderResult = {
    response: ApiResponse<OrderVm>;
    request: SavePosOrderApiRequest;
};

type SaveNewOrderParams = {
    firstName?: string;
    lastName?: string;
    mobileNumber?: string;
    email?: string;
    customerNote?: string;
    numberOfCustomers?: number;
    orderStatus?: 'COMPLETE' | 'PENDING' | 'NEW';
    uruguayanRucInvoice?: boolean;
};
