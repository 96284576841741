import { Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { changeMenuItemApi } from 'src/api/letseatmanager/menuItem/changeMenuItemApi';
import { getMenuItemApi } from 'src/api/letseatmanager/menuItem/getMenuItemApi';
import { Button } from 'src/components/Button';
import { Form } from 'src/components/form/Form';
import { FormCheckbox } from 'src/components/form/FormCheckbox';
import { FormColorButtons } from 'src/components/form/FormColorButtons';
import { FormCurrencyNumberStringField } from 'src/components/form/FormCurrencyNumberStringField';
import { FormCurrencySelect } from 'src/components/form/FormCurrencySelect';
import { FormImageUpload } from 'src/components/form/FormImageUpload';
import { FormNumberField } from 'src/components/form/FormNumberField';
import { FormNumberStringField } from 'src/components/form/FormNumberStringField';
import { FormPercentNumberField } from 'src/components/form/FormPercentNumberField';
import { FormPrinterNamesAutocomplete } from 'src/components/form/FormPrinterNamesAutocomplete';
import { FormRecipeAutocomplete } from 'src/components/form/FormRecipeAutocomplete';
import { FormRestaurantTaxAutocomplete } from 'src/components/form/FormRestaurantTaxAutocomplete';
import { FormRestaurantTaxAutocompleteMultiple } from 'src/components/form/FormRestaurantTaxAutocompleteMultiple';
import { FormSupplyCategorySelect } from 'src/components/form/FormSupplyCategorySelect';
import { FormTextField } from 'src/components/form/FormTextField';
import { FormUnitOfMeasurementSelect } from 'src/components/form/FormUnitOfMeasurementSelect';
import { SecuredContent } from 'src/components/SecuredContent';
import { Tooltip } from 'src/components/Tooltip';
import { MenuItemSizes } from 'src/constants/MenuItemSize';
import { RolePermissions } from 'src/constants/RolePermission';
import { UnitsOfMeasurement } from 'src/constants/UnitOfMeasurement';
import { WebSocketEventTypes } from 'src/constants/WebSocketEventType';
import { translate } from 'src/i18n/translate';
import { AddRoundedIcon } from 'src/icons/AddRoundedIcon';
import { DeleteRoundedIcon } from 'src/icons/DeleteRoundedIcon';
import { SelectMenuCategoriesDialog } from 'src/scenes/letseatmanager/menu/menu/SelectMenuCategoriesDialog';
import { SelectModifierGroupsDialog } from 'src/scenes/letseatmanager/menu/menuItem/SelectModifierGroupsDialog';
import { CreateRestaurantTaxDialog } from 'src/scenes/letseatmanager/taxes/CreateRestaurantTaxDialog';
import { useLoadMenus } from 'src/services/menu/useLoadMenus';
import { useMenuCategories } from 'src/services/menuCategory/useMenuCategories';
import { useModifierGroups } from 'src/services/modifierGroup/useModifierGroups';
import { MenuCategoryId, ModifierGroupId, type MenuItemId } from 'src/types/Id';
import { MenuCategoryVm } from 'src/types/MenuCategoryVm';
import type { WebSocketEvent } from 'src/types/WebSocketEvent';
import { alertKnownErrorOrSomethingWentWrong } from 'src/utils/alert/alertKnownErrorOrSomethingWentWrong';
import { isUruguay } from 'src/utils/country/isUruguay';
import { useLoadApi } from 'src/utils/react/useLoadApi';
import { useSelector } from 'src/utils/react/useSelector';
import { WebSocketEvents } from 'src/utils/webSocket/WebSocketEvents';

export function ChangeMenuItem({ menuItemId, onClose, onSuccess }: Props): React.ReactElement | null {
    const classes = useStyles();
    const form = useForm();

    const refreshMenus = useLoadMenus();

    const [loading, setLoading] = useState(false);
    const [selectedModifierGroupIds, setSelectedModifierGroupIds] = useState<Array<ModifierGroupId>>([]);
    const [selectedMenuCategoryIds, setSelectedMenuCategoryIds] = useState<Array<MenuCategoryId>>([]);
    const [addModifierGroupsDialogState, setAddModifierGroupsDialogState] = useState({ open: false });
    const [selectMenuCategoriesDialogState, setSelectMenuCategoriesDialogState] = useState({ open: false });
    const [openAddTaxDialog, setOpenAddTaxDialog] = useState(false);

    const [loadingMenuItem, menuItem, load] = useLoadApi(getMenuItemApi, { menuItemId: menuItemId! }, { requiredValues: [menuItemId], dependencies: [menuItemId] });

    const adminUser = useSelector((state) => state.authentication.adminUser);
    const supportUser = useSelector((state) => state.authentication.supportUser);
    const operationsUser = useSelector((state) => state.authentication.operationsUser);
    const newDeviceManagementEnabled = useSelector((state) => state.app.restaurant?.newDeviceManagementEnabled);
    const inventoryEnabled = useSelector((state) => state.app.restaurant?.inventoryEnabled);
    const limitProductsInOrder = useSelector((state) => state.app.restaurant?.limitProductsInOrder);
    const restaurantTaxManagementEnabled = useSelector((state) => state.app.restaurant?.restaurantTaxManagementEnabled);
    const restaurantCountry = useSelector((state) => state.app.restaurant?.country);

    const { modifierGroups } = useModifierGroups();
    const { menuCategories } = useMenuCategories();

    const menuCategoryPrinterNames = form.watch('menuCategoryPrinterNames');
    const printerNames = form.watch('printerNames');
    const isSupply = form.watch('isSupply');
    const isCommissionEnabled = form.watch('commissionEnabled');
    const isProduct = form.watch('isProduct');
    const isModifier = form.watch('isModifier');
    const isSoldByWeight = form.watch('isSoldByWeight');
    const restaurantTaxIds = form.watch('restaurantTaxIds');
    const salesUnit = form.watch('salesUnit');

    const salesUnitByWeight = [UnitsOfMeasurement.GRAM, UnitsOfMeasurement.KILOGRAM, UnitsOfMeasurement.MILLILITER, UnitsOfMeasurement.LITRE, UnitsOfMeasurement.CURRENCY];

    const showSupplyCategorySelect = isSupply && inventoryEnabled && !menuItem?.isSupply;
    const hasModifierGroupIds = selectedModifierGroupIds.length > 0;
    const hasMenuCategories = selectedMenuCategoryIds.length > 0;
    const menuCategoryIds =
        menuCategories?.filter((menuCategory: MenuCategoryVm) => menuCategory.menuItemIds.includes(menuItemId!)).map((menuCategory: MenuCategoryVm) => menuCategory.menuCategoryId) ?? [];
    const hasProductoOrModifierSelected = isProduct || isModifier;

    useEffect(() => {
        const menuItemChangedSyncWebSocketEvent = WebSocketEvents.addEventListener(WebSocketEventTypes.MENU_ITEM_CHANGED, syncMenuItemExternalChanges);
        const menuItemRemovedSyncWebSocketEvent = WebSocketEvents.addEventListener(WebSocketEventTypes.MENU_ITEM_REMOVED, syncMenuItemExternalChanges);

        return () => {
            menuItemChangedSyncWebSocketEvent.remove();
            menuItemRemovedSyncWebSocketEvent.remove();
        };
    }, []);

    useEffect(() => {
        if (menuItem) {
            form.reset({
                externalProductId: menuItem.externalProductId ?? null,
                recipeId: menuItem.recipeId ?? null,
                name: menuItem.name,
                description: menuItem.description ?? null,
                contains: menuItem.contains ?? null,
                price: menuItem.price,
                modifierPrice: menuItem.modifierPrice ?? null,
                promoText: menuItem.promoText ?? null,
                promoPrice: menuItem.promoPrice ?? null,
                kitchenPrice: menuItem.kitchenPrice ?? null,
                size: menuItem.size ?? null,
                imageUrl: menuItem.imageUrl ?? null,
                color: menuItem.color ?? null,
                printerNames: menuItem.printerNames,
                isProduct: menuItem.isProduct ?? false,
                isModifier: menuItem.isModifier ?? false,
                isSoldByWeight: menuItem.isSoldByWeight ?? false,
                salesUnit: menuItem.salesUnit,
                minimumSalesQuantity: menuItem.minimumSalesQuantity,
                isSupply: menuItem.isSupply ?? false,
                currency: menuItem.currency ?? null,
                supplyCategoryId: menuItem.supplyCategoryId,
                menuCategoryPrinterNames: menuItem.menuCategoryPrinterNames,
                orderItemsMaximumQuantity: menuItem.orderItemsMaximumQuantity,
                commissionEnabled: menuItem.commissionEnabled,
                restaurantTaxIds: isUruguay(restaurantCountry) ? (menuItem.restaurantTaxIds ? menuItem.restaurantTaxIds[0] : null) : menuItem.restaurantTaxIds,
                commissionPercentage: menuItem.commissionPercentage,
            });
            setSelectedModifierGroupIds(menuItem.modifierGroupIds ?? []);
            setSelectedMenuCategoryIds(menuCategoryIds ?? []);
        }
    }, [menuItem]);

    const handleSubmit = async (form: any) => {
        if (!menuItemId) return;
        setLoading(true);
        const response = await changeMenuItemApi({
            menuItemId,
            modifierGroupIds: selectedModifierGroupIds,
            menuCategoryIds: form.isProduct ? selectedMenuCategoryIds : [],
            recipeId: form.recipeId ?? null,
            externalProductId: form.externalProductId,
            name: form.name,
            description: form.description,
            contains: form.contains,
            price: form.price,
            modifierPrice: form.modifierPrice,
            promoText: form.promoText,
            promoPrice: form.promoPrice,
            kitchenPrice: form.kitchenPrice,
            size: MenuItemSizes.MEDIUM,
            imageUrl: form.imageUrl,
            color: form.color,
            printerNames: form.printerNames,
            isProduct: form.isProduct,
            isModifier: form.isModifier,
            orderItemsMaximumQuantity: form.orderItemsMaximumQuantity?.toString(),
            isSoldByWeight: form.isSoldByWeight,
            salesUnit: form.salesUnit,
            currency: form.currency,
            minimumSalesQuantity: form.minimumSalesQuantity,
            isSupply: form.isSupply,
            supplyCategoryId: form.supplyCategoryId,
            commissionEnabled: form.commissionEnabled,
            commissionPercentage: form.commissionPercentage,
            restaurantTaxIds: isUruguay(restaurantCountry) ? (form.restaurantTaxIds ? [form.restaurantTaxIds] : []) : form.restaurantTaxIds,
        });
        setLoading(false);
        if (!response.ok) {
            alertKnownErrorOrSomethingWentWrong(response);
            return;
        }
        onSuccess?.();
    };

    const handleRemoveModifierGroup = (modifierGroupId: ModifierGroupId) => {
        const newModifierGroups = selectedModifierGroupIds.filter((selectedModifierGroupId) => selectedModifierGroupId !== modifierGroupId);
        setSelectedModifierGroupIds(newModifierGroups);
    };

    const handleDeleteMenuCategory = (menuCategoryId: MenuCategoryId) => {
        const newMenuCategoryIds = selectedMenuCategoryIds.filter((selectedMenuCategoryId) => selectedMenuCategoryId !== menuCategoryId);
        setSelectedMenuCategoryIds(newMenuCategoryIds);
    };

    const openAddModifierGroupDialog = () => setAddModifierGroupsDialogState({ open: true });

    const closeAddModifierGroupDialog = () => setAddModifierGroupsDialogState({ open: false });

    const openSelectMenuCategoriesDialog = () => setSelectMenuCategoriesDialogState({ open: true });

    const closeSelectMenuCategoriesDialog = () => setSelectMenuCategoriesDialogState({ open: false });

    const handleSelectModifierGroups = (modifierGroupIds: any) => setSelectedModifierGroupIds(modifierGroupIds);

    const handleSelectMenuCategories = (menuCategoryIds: any) => setSelectedMenuCategoryIds(menuCategoryIds);

    const syncMenuItemExternalChanges = (
        event: WebSocketEvent<{
            menuItemId: MenuItemId;
        }>,
    ) => {
        if (event.data?.menuItemId === menuItemId && event.webSocketEventType === WebSocketEventTypes.MENU_ITEM_REMOVED) onClose?.();
        if (event.data?.menuItemId === menuItemId && event.webSocketEventType === WebSocketEventTypes.MENU_ITEM_CHANGED) load();

        refreshMenus();
    };

    return (
        <>
            <SelectModifierGroupsDialog
                open={addModifierGroupsDialogState.open}
                defaultSelectedModifierGroups={selectedModifierGroupIds}
                onClose={closeAddModifierGroupDialog}
                onSuccess={handleSelectModifierGroups}
                excludeModifierGroupsByMenuItemId={menuItem?.menuItemId}
            />
            <SelectMenuCategoriesDialog
                open={selectMenuCategoriesDialogState.open}
                defaultSelectedMenuCategories={selectedMenuCategoryIds}
                onClose={closeSelectMenuCategoriesDialog}
                onSuccess={handleSelectMenuCategories}
            />
            <CreateRestaurantTaxDialog open={openAddTaxDialog} onClose={() => setOpenAddTaxDialog(false)} onSuccess={() => setOpenAddTaxDialog(false)} />
            <Form onSubmit={handleSubmit} form={form}>
                <h2 className={classes.title}>{menuItem?.name}</h2>
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <FormImageUpload name={'imageUrl'} label={translate('Image')} disabled={loading || loadingMenuItem} />
                    </Grid>
                    <Grid item xs={12}>
                        <FormColorButtons name='color' label={translate('Color')} disabled={loading || loadingMenuItem} />
                    </Grid>
                    <Grid item xs={12}>
                        <FormTextField
                            name='name'
                            label={translate('Name')}
                            tooltip={translate('This is the name of the product')}
                            required
                            disabled={loading || loadingMenuItem}
                            minLength={3}
                            maxLength={40}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <FormCheckbox name='isProduct' label={translate('Is it a product?')} defaultValue={true} disabled={loading || loadingMenuItem} required={hasProductoOrModifierSelected} />
                    </Grid>
                    <Grid item xs={6}>
                        <FormCheckbox name='isModifier' label={translate('Is it a modifier?')} disabled={loading || loadingMenuItem} required={hasProductoOrModifierSelected} />
                    </Grid>
                    {isProduct && (
                        <Grid item xs={12} md={isModifier ? 6 : 12}>
                            <FormCurrencyNumberStringField name='price' label={translate('Price')} required disabled={loading || loadingMenuItem} classes={{ input: classes.inputSpacing }} />
                        </Grid>
                    )}
                    {isModifier && (
                        <Grid item xs={12} md={6}>
                            <FormCurrencyNumberStringField name='modifierPrice' label={translate('Modifier price')} required disabled={loading} />
                        </Grid>
                    )}
                    <Grid item xs={12} md={6}>
                        <FormCheckbox name='isSoldByWeight' label={translate('Is it sold by weight?')} required disabled={loading} minLength={3} maxLength={40} />
                        {isSoldByWeight && (
                            <Grid item xs={12} md={6} className={classes.inputSpacing}>
                                <FormUnitOfMeasurementSelect filterByUnits={salesUnitByWeight} name='salesUnit' label={translate('Unit of sale')} required={isSoldByWeight} disabled={loading} />
                            </Grid>
                        )}
                        {salesUnit === UnitsOfMeasurement.CURRENCY && (
                            <Grid item xs={12} md={6} className={classes.inputSpacing}>
                                <FormCurrencySelect name={'currency'} label={translate('Currency')} required />
                            </Grid>
                        )}
                        {isSoldByWeight && (
                            <Grid item xs={12} md={6} className={classes.inputSpacing}>
                                <FormNumberStringField name='minimumSalesQuantity' label={translate('Minimum quantity to sell')} disabled={loading} />
                            </Grid>
                        )}
                        {inventoryEnabled && (
                            <Grid item xs={12}>
                                <FormCheckbox name='isSupply' label={translate('Is it also a supply?')} disabled={loading} />
                            </Grid>
                        )}
                        {showSupplyCategorySelect && (
                            <Grid item xs={12}>
                                <FormSupplyCategorySelect name='supplyCategoryId' label={translate('Category')} required={showSupplyCategorySelect} />
                            </Grid>
                        )}
                    </Grid>
                    <Grid item xs={12}>
                        <FormCurrencyNumberStringField name='promoPrice' label={translate('Promo Price')} disabled={loading || loadingMenuItem} />
                    </Grid>
                    <Grid item xs={12}>
                        <FormTextField name='promoText' label={translate('Promo Text')} disabled={loading || loadingMenuItem} />
                    </Grid>
                    {(adminUser || supportUser || operationsUser) && (
                        <Grid item xs={12}>
                            <FormTextField name='realKitchenPrice' label={translate('Real Kitchen Price')} disabled={loading || loadingMenuItem} />
                        </Grid>
                    )}
                    <Grid item xs={12}>
                        <FormTextField name='description' label={translate('Description')} classes={{ input: classes.inputDescription }} multiline disabled={loading || loadingMenuItem} />
                    </Grid>
                    <Grid item xs={12}>
                        <div className={classes.label}>
                            {translate('Modifier Groups')} <Tooltip text={translate('This menuItem will contain this modifier groups')} />
                        </div>
                        {hasModifierGroupIds && (
                            <div style={{ display: 'flex', width: '100%', justifyContent: 'space-between' }}>
                                <div className={classes.modifierGroups}>
                                    {selectedModifierGroupIds.map((modifierGroupId) => {
                                        const modifierGroup = modifierGroups.find((modifierGroup) => modifierGroup.modifierGroupId === modifierGroupId);
                                        if (!modifierGroup) return null;

                                        return (
                                            <div className={classes.modifierGroup} key={modifierGroupId}>
                                                <p style={{ margin: 0 }}>{modifierGroup.name}</p>
                                                <div onClick={() => handleRemoveModifierGroup(modifierGroupId)} style={{ display: 'grid', placeItems: 'center', cursor: 'pointer' }}>
                                                    <DeleteRoundedIcon />
                                                </div>
                                            </div>
                                        );
                                    })}
                                </div>
                                <Button icon onClick={openAddModifierGroupDialog}>
                                    <AddRoundedIcon />
                                </Button>
                            </div>
                        )}
                        {!hasModifierGroupIds && (
                            <Button outlined onClick={openAddModifierGroupDialog}>
                                {translate('Select modifier groups')}
                            </Button>
                        )}
                    </Grid>
                    {isProduct && (
                        <Grid item xs={12}>
                            <div className={classes.label}>
                                {translate('Categories')} <Tooltip text={translate('This item will be added to the next categories')} />
                            </div>
                            {hasMenuCategories && (
                                <div style={{ display: 'flex', width: '100%', justifyContent: 'space-between' }}>
                                    <div className={classes.modifierGroups}>
                                        {selectedMenuCategoryIds.map((menuCategoryId) => {
                                            const menuCategory = menuCategories.find((menuCategory) => menuCategory.menuCategoryId === menuCategoryId);
                                            if (!menuCategory) return null;

                                            return (
                                                <div className={classes.modifierGroup} key={menuCategoryId}>
                                                    <p style={{ margin: 0 }}>{menuCategory.name}</p>
                                                    <div onClick={() => handleDeleteMenuCategory(menuCategoryId)} style={{ display: 'grid', placeItems: 'center', cursor: 'pointer' }}>
                                                        <DeleteRoundedIcon />
                                                    </div>
                                                </div>
                                            );
                                        })}
                                    </div>
                                    <Button icon onClick={openSelectMenuCategoriesDialog}>
                                        <AddRoundedIcon />
                                    </Button>
                                </div>
                            )}
                            {!hasMenuCategories && (
                                <Button outlined onClick={openSelectMenuCategoriesDialog}>
                                    {translate('Select categories')}
                                </Button>
                            )}
                        </Grid>
                    )}
                    {isSupply && !menuItem.recipeId && (
                        <Grid item xs={12}>
                            <FormRecipeAutocomplete name='recipeId' label={translate('Recipe')} disabled={loading || loadingMenuItem} />
                        </Grid>
                    )}
                    <Grid item xs={12}>
                        <FormTextField name='externalProductId' label={translate('External Product Id')} disabled={loading || loadingMenuItem} />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <FormCheckbox name='commissionEnabled' label={translate('Enable comissions')} defaultValue={false} disabled={loading} />
                    </Grid>
                    {isCommissionEnabled && (
                        <Grid item xs={12} md={6}>
                            <FormPercentNumberField name='commissionPercentage' disabled={loading} placeholder={translate('Commission')} />
                        </Grid>
                    )}
                    {restaurantTaxManagementEnabled && (
                        <SecuredContent rolePermission={RolePermissions.TAXES_PAGE}>
                            <div className={classes.taxesContainer}>
                                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' }}>
                                    <h2>{translate('Taxes')}</h2>
                                    <Button onClick={() => setOpenAddTaxDialog(true)}>{translate('Add')}</Button>
                                </div>
                                {isUruguay(restaurantCountry) ? (
                                    <FormRestaurantTaxAutocomplete name='restaurantTaxIds' label={translate('Taxes')} defaultValue={restaurantTaxIds} disabled={loading} />
                                ) : (
                                    <FormRestaurantTaxAutocompleteMultiple name='restaurantTaxIds' label={translate('Taxes')} defaultValue={restaurantTaxIds} disabled={loading} />
                                )}
                            </div>
                        </SecuredContent>
                    )}
                    {newDeviceManagementEnabled && (
                        <Grid item xs={12} md={12}>
                            <FormPrinterNamesAutocomplete name='printerNames' label={translate('Select a Printer')} placeholder={menuCategoryPrinterNames} />
                            {menuCategoryPrinterNames && !printerNames && <span>{translate('Default printers used from category printers')}</span>}
                        </Grid>
                    )}
                    {limitProductsInOrder && (
                        <Grid item xs={12}>
                            <FormNumberField name='orderItemsMaximumQuantity' label={translate('Product Limit')} disabled={loading} />
                        </Grid>
                    )}
                    <div className={classes.buttonsContainer}>
                        {onClose && (
                            <Button secondary onClick={onClose} disabled={loading}>
                                {translate('Cancel')}
                            </Button>
                        )}
                        <Button type={'submit'} disabled={loadingMenuItem || loading || (!isModifier && !isProduct)}>
                            {loading ? translate('Saving') : translate('Save')}
                        </Button>
                    </div>
                </Grid>
            </Form>
        </>
    );
}

const useStyles = makeStyles((theme) => ({
    title: {
        textAlign: 'center',
        color: theme.palette.text.brand,
        fontFamily: theme.typography.bold,
    },
    editMenuItemSection: {
        display: 'flex',
        flexDirection: 'column',
        maxWidth: '100%',
        margin: '0 auto',
    },
    form: {
        display: 'flex',
        flexDirection: 'column',
        gap: 16,
    },
    label: {
        fontSize: 14,
        color: theme.palette.text.primary,
        fontFamily: theme.typography.regular,
        whiteSpace: 'nowrap',
        margin: 0,
        marginBottom: 6,
        display: 'flex',
        alignItems: 'center',
        gap: 6,
    },
    buttonsContainer: {
        marginTop: 30,
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'flex-end',
        gap: 20,
        width: '100%',
        '& button': {
            width: 200,
        },
    },
    displayNone: {
        display: 'none',
    },
    modifierGroups: {
        display: 'flex',
        gap: 12,
        width: '100%',
        marginTop: 8,
    },
    modifierGroup: {
        backgroundColor: theme.palette.surface.secondary,
        padding: '4px 12px',
        color: theme.palette.text.secondary,
        borderRadius: 4,
        fontFamily: theme.typography.regular,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        gap: 8,
        flexWrap: 'wrap',
    },
    inputDescription: {
        height: '15vh',
        maxHeight: 150,
        alignItems: 'flex-start',
        overflowY: 'scroll',
        '&::-webkit-scrollbar': {
            display: 'none',
        },
    },
    taxesContainer: {
        display: 'flex',
        flexDirection: 'column',
        gap: 16,
        width: '100%',
        border: `1px solid #D9D9D9`,
        padding: 16,
        borderRadius: 4,
    },
    inputSpacing: {
        marginBottom: 12,
    },
}));

type Props = {
    menuItemId?: MenuItemId;
    onClose?: any;
    onSuccess?: any;
};
