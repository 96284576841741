import Grid from '@material-ui/core/Grid';
import * as React from 'react';
import { useEffect, useState } from 'react';
import type { SurveyVm } from 'src/api/letseatadmin/survey/getSurveyApi';
import type { TipVm } from 'src/api/letseatadmin/tip/getTipApi';
import { app2 } from 'src/app2';
import { TimeZones } from 'src/constants/TimeZone';
import { translate } from 'src/i18n/translate';
import CustomerDetails, { CustomerVm } from 'src/scenes/letseatadmin/customer/CustomerDetails';
import OrderDetails from 'src/scenes/letseatadmin/order/OrderDetails';
import { PaymentDetails } from 'src/scenes/letseatadmin/payment/PaymentDetails';
import RestaurantDetails, { RestaurantVm } from 'src/scenes/letseatadmin/restaurant/RestaurantDetails';
import SurveyDetails from 'src/scenes/letseatadmin/surveys/SurveyDetails';
import TipDetails from 'src/scenes/letseatadmin/tip/TipDetails';
import type { SurveyId } from 'src/types/Id';
import { fullName } from 'src/utils/customer/fullName';
import { formatDateTimeStringReadable } from 'src/utils/date/formatDateTimeStringReadable';
import { useAction } from 'src/utils/react/useAction';
import { useParams } from 'src/utils/react/useParams';

export function SurveyPage(): React.ReactElement {
    const { surveyId } = useParams<{
        surveyId: SurveyId;
    }>();
    const [survey, setSurvey] = useState<SurveyVm | undefined>(undefined);
    const [tip, setTip] = useState<TipVm | undefined>(undefined);
    const [customer, setCustomer] = useState(undefined as CustomerVm | undefined);
    const [restaurant, setRestaurant] = useState(undefined as RestaurantVm | undefined);
    const setTitle = useAction(app2.actions.setTitle);

    useEffect(() => {
        if (!survey || !restaurant || !customer) {
            setTitle(`${translate('Surveys')} > ${surveyId.substring(0, 8)}`);
            return;
        }
        setTitle(`${translate('Surveys')} > ${restaurant.name} - ${formatDateTimeStringReadable(survey.createdAt, TimeZones.AMERICA_MONTERREY)} - ${fullName(customer as any)}`);
    }, [survey, customer, restaurant]);

    return (
        <Grid container spacing={5} style={{ paddingBottom: 40 }}>
            <Grid item xs={12}>
                <SurveyDetails surveyId={surveyId} onChangeSurvey={(survey) => setSurvey(survey)} />
            </Grid>
            <Grid item xs={12}>
                {survey && <OrderDetails orderId={survey.orderId} />}
            </Grid>
            {survey?.tipId && (
                <Grid item xs={12}>
                    <TipDetails tipId={survey?.tipId} onChangeTip={(tip) => setTip(tip)} />
                </Grid>
            )}
            {tip?.paymentId && (
                <Grid item xs={12}>
                    <PaymentDetails paymentId={tip?.paymentId} />
                </Grid>
            )}
            <Grid item xs={12}>
                <RestaurantDetails restaurantId={survey?.restaurantId} onChangeRestaurant={(restaurant) => setRestaurant(restaurant)} />
            </Grid>
            <Grid item xs={12}>
                <CustomerDetails customerId={survey?.customerId} onChangeCustomer={(customer) => setCustomer(customer)} />
            </Grid>
        </Grid>
    );
}
