import { BigNumber } from 'bignumber.js';
import { posReducer } from 'src/reducers/posReducer';
import { useSetPosPaymentMethod } from 'src/services/pos/posPaymentMethods/useSetPosPaymentMethod';
import { PosCustomerOrderDetails } from 'src/types/PosCustomerOrderDetails';
import type { PosPayment } from 'src/types/PosPayment';
import { useAction } from 'src/utils/react/useAction';
import { useSelector } from 'src/utils/react/useSelector';

export function useRemovePosPayment(): Result {
    const setPosPaymentMethod = useSetPosPaymentMethod();

    const payments = useSelector((state) => state.pos.payments);
    const totalPaid = useSelector((state) => state.pos.totalPaid);
    const tips = useSelector((state) => state.pos.tips);
    const items = useSelector((state) => state.pos.items);
    const customers = useSelector((state) => state.pos.customers);

    const setPayments = useAction(posReducer.actions.setPayments);
    const setTotalPaid = useAction(posReducer.actions.setTotalPaid);
    const setItems = useAction(posReducer.actions.setItems);
    const refreshPosPaymentCustomer = useAction(posReducer.actions.refreshPosPaymentCustomer);
    const removePosTip = useAction(posReducer.actions.removePosTip);

    const removePosPayment = (payment: PosPayment) => {
        if (!payments?.length) return;

        const newPosPayments = payments.filter((posPayment) => posPayment.id !== payment.id);

        if (payment.isTipPayment) {
            removePosTip({ paymentMethod: payment.paymentMethod, customPaymentMethod: payment.customPaymentMethod, paymentId: payment.id });
        }

        if (!payment.isTipPayment || !isTipPaymentFromChange(payment)) {
            setTotalPaid(
                BigNumber(totalPaid ?? 0)
                    .minus(payment.amount)
                    .toString(),
            );
        }

        if (!payment.isTipPayment) {
            setPosPaymentMethod({ paymentMethod: undefined, customPaymentMethod: undefined });
        }

        setPayments(newPosPayments);

        if (!payment.customerNumber) return;

        updateCustomerState(payment.customerNumber);
    };

    const isTipPaymentFromChange = (payment: PosPayment) => {
        if (!payment.isTipPayment) return false;
        const tip = tips.find((tip) => tip.paymentId === payment.id);
        return !!tip?.isTipFromChange;
    };

    const updateCustomerState = (customerNumber: number) => {
        removeCustomerItems(customerNumber);

        const customer = customers?.find((customer: PosCustomerOrderDetails) => customer.customerNumber === customerNumber);
        if (!customer) return;

        refreshPosPaymentCustomer(customer);
    };

    const removeCustomerItems = (customerNumber: number) => {
        const itemsFiltered = items.map((item) => {
            const customerPayment = item.itemsByCustomer?.find((payment: { customerNumber: number; itemsQuantity: number }) => payment.customerNumber === customerNumber);

            if (!customerPayment) return item;

            return {
                ...item,
                itemsByCustomer: item.itemsByCustomer?.filter((payment: { customerNumber: number; itemsQuantity: number }) => payment.customerNumber !== customerNumber),
            };
        });
        setItems(itemsFiltered);
    };

    return { removePosPayment };
}

type Result = {
    removePosPayment: (arg1: PosPayment) => void;
};
