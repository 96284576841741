import * as React from 'react';
import type { RolePermission } from 'src/constants/RolePermission';
import { useSelector } from 'src/utils/react/useSelector';

export function SecuredContent({ rolePermission, children }: Props): React.ReactElement | null {
    const userRolePermissions = useSelector((state) => state.authentication.userRolePermissions);
    const pinCodeUserRolePermissions = useSelector((state) => state.authentication.pinCodeUserSignedIn?.userRolePermissions);

    const currentUserRolePermissions = pinCodeUserRolePermissions || userRolePermissions;

    if (currentUserRolePermissions && !currentUserRolePermissions.includes(rolePermission)) {
        return null;
    }

    return children as React.ReactElement;
}

type Props = {
    rolePermission: RolePermission;
    children: React.ReactNode;
};
