import { makeStyles } from '@material-ui/core';
import { BigNumber } from 'bignumber.js';
import * as React from 'react';
import { translate } from 'src/i18n/translate';
import { PosBusinessKpisSection } from 'src/scenes/letseatmanager/restaurantDashboard/posBusinessDay/PosBusinessKpisSection';
import type { CashRegisterPosBusinessDayReportVm } from 'src/types/CashRegisterPosBusinessDayReportVm';
import { PosBusinessDaySalesByApp, PosBusinessDaySalesByOrderType, type PosBusinessDayReportVm } from 'src/types/PosBusinessDayReportVm';
import { isCardPayment } from 'src/utils/paymentMethod/isCardPayment';
import { isIntegrationApp } from 'src/utils/restaurant/isIntegrationApp';

export function PosBusinessDaySalesResume({ posBusinessDayReport }: Props): React.ReactElement {
    const classes = useStyles();

    const channelSalesSummary = posBusinessDayReport.salesByAppReport?.salesByApp?.map((saleByApp: PosBusinessDaySalesByApp) => ({
        amount: saleByApp.total,
        label: translate(`Apps.${saleByApp.app}`),
        percentage: saleByApp.salesPercentage,
    }));

    const paymentMethodSalesSummary = posBusinessDayReport.paymentMethodsBreakdown?.posBusinessDayPaymentMethodBreakdown?.reduce<PaymentMethodSalesSummary>((paymentMethodSalesSummary, kpi) => {
        const label = isCardPayment(kpi.paymentMethod) && isIntegrationApp(kpi.app) ? `${translate(kpi.paymentMethod)} ${translate(kpi.app)}` : translate(kpi.paymentMethod);
        const paymentMethodKpi = paymentMethodSalesSummary?.find((paymentMethodKpi) => paymentMethodKpi.label === label);
        if (!paymentMethodKpi) {
            return [
                ...paymentMethodSalesSummary,
                {
                    amount: kpi.total,
                    label,
                    percentage: kpi.salesPercentage,
                },
            ];
        }
        paymentMethodKpi.amount = BigNumber(paymentMethodKpi.amount).plus(kpi.total).toString();
        paymentMethodKpi.percentage = BigNumber(paymentMethodKpi.percentage ?? 0)
            .plus(kpi.salesPercentage)
            .toString();
        return paymentMethodSalesSummary;
    }, []);

    const orderTypeSalesSummary = posBusinessDayReport.salesByOrderTypeReport?.salesByOrderType
        ?.map((kpi: PosBusinessDaySalesByOrderType) => ({
            amount: kpi.total,
            label: translate(kpi.orderType),
            percentage: kpi.salesPercentage,
        }))
        ?.sort((a, b) => Number(b.amount) - Number(a.amount));

    return (
        <div className={classes.container}>
            <PosBusinessKpisSection summary={channelSalesSummary} title={translate('By sales channel')} />
            <PosBusinessKpisSection summary={paymentMethodSalesSummary} title={translate('By method of payment')} />
            {orderTypeSalesSummary && <PosBusinessKpisSection summary={orderTypeSalesSummary} title={translate('By order type')} />}
        </div>
    );
}

const useStyles = makeStyles((theme) => ({
    container: {
        width: '100%',
        display: 'grid',
        gridTemplateColumns: 'repeat(auto-fill, minmax(320px, 1fr))',
        gap: 24,
        fontFamily: theme.typography.regular,
        marginBottom: 42,
    },
    icon: {
        margin: '0 20px 20px 20px',
    },
    helpIcon: {
        marginLeft: 5,
        '&.MuiSvgIcon-colorPrimary': {
            color: '#0FA489',
        },
    },
    resumeSection: {
        display: 'flex',
        marginBottom: 20,
        alignItems: 'center',
        [theme.breakpoints.down('sm')]: {
            gap: 24,
        },
    },
    howReadReportButton: {
        textDecoration: 'underline',
        color: '#232933',
    },
    infoContainer: {
        display: 'flex',
        margin: '20px 0',
        flexDirection: 'column',
        alignItems: 'start',
        justifyContent: 'center',
        [theme.breakpoints.up('sm')]: {
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center',
        },
    },
    button: {
        marginLeft: 0,
        paddingLeft: 0,
        '&:hover': {
            backgroundColor: 'transparent',
        },
        '&:focus': {
            border: 'unset',
        },
    },
    leftPosition: {
        left: 0,
        alignContent: 'left',
        alignItems: 'left',
    },
}));

type PaymentMethodSalesSummary = Array<{
    amount: string;
    label: string;
    percentage?: string;
}>;

type Props = {
    posBusinessDayReport: PosBusinessDayReportVm | CashRegisterPosBusinessDayReportVm;
};
