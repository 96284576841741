import { makeStyles } from '@material-ui/core/styles';
import MUIDataTable from 'mui-datatables';
import * as React from 'react';
import { useEffect, useRef, useState } from 'react';
import { EmailTemplateVm, getEmailTemplatesApi } from 'src/api/letseatadmin/emailTemplate/getEmailTemplatesApi';
import { removeEmailTemplateApi } from 'src/api/letseatadmin/emailTemplate/removeEmailTemplateApi';
import { app2 } from 'src/app2';
import { CreateToolbarButton } from 'src/components/mui-datatables/CreateToolbarButton';
import { RefreshToolbarButton } from 'src/components/mui-datatables/RefreshToolbarButton';
import { RemoveToolbarButton } from 'src/components/mui-datatables/RemoveToolbarButton';
import { UpdatingContentProgress } from 'src/components/UpdatingContentProgress';
import { TimeZones } from 'src/constants/TimeZone';
import { translate } from 'src/i18n/translate';
import type { EmailTemplateId } from 'src/types/Id';
import { alertKnownErrorOrSomethingWentWrong } from 'src/utils/alert/alertKnownErrorOrSomethingWentWrong';
import { formatDateTimeStringReadable } from 'src/utils/date/formatDateTimeStringReadable';
import { createFilteredOnDownloadFunctional } from 'src/utils/mui-datatables/createFilteredOnDownloadFunctional';
import { getSelectedRowsData } from 'src/utils/mui-datatables/getSelectedRowsData';
import { useAction } from 'src/utils/react/useAction';
import { useSelector } from 'src/utils/react/useSelector';

export default function EmailTemplatesTable({ title, onClickRefresh }: Props): React.ReactElement {
    const classes = useStyles();
    const viewUser = useSelector((state) => state.authentication.viewUser);
    const table = useRef(null);
    const [loading, setLoading] = useState(false);
    const [vm, setVm] = useState<{ emailTemplates: Array<EmailTemplateVm> }>({ emailTemplates: [] });
    const openCreateEmailTemplateDialog = useAction(app2.actions.openCreateEmailTemplateDialog);
    const openChangeEmailTemplateDialog = useAction(app2.actions.openChangeEmailTemplateDialog);

    useEffect(() => {
        load();
    }, []);

    const load = async () => {
        setLoading(true);
        const response = await getEmailTemplatesApi();
        if (!response.ok) {
            setLoading(false);
            alertKnownErrorOrSomethingWentWrong(response);
            return;
        }
        setLoading(false);
        setVm(response.data);
    };

    const removeEmailTemplates = async (emailTemplateIds: Array<EmailTemplateId>) => {
        setLoading(true);
        for (const emailTemplateId of emailTemplateIds) {
            const response = await removeEmailTemplateApi({ emailTemplateId });
            if (!response.ok) {
                setLoading(false);
                alertKnownErrorOrSomethingWentWrong(response);
                await load();
                return;
            }
        }
        await load();
    };

    const columns = [
        {
            name: 'emailTemplateId',
            label: ' ',
            options: {
                display: 'excluded' as any,
                filter: false,
                searchable: true,
            },
        },
        {
            name: 'name',
            label: translate('Name'),
            options: {
                filter: false,
                searchable: true,
            },
        },
        {
            name: 'subject',
            label: translate('Subject'),
            options: {
                filter: false,
                searchable: false,
            },
        },
        {
            name: 'bodyHtml',
            label: translate('Body Html'),
            options: {
                filter: false,
                searchable: false,
            },
        },
        {
            name: 'bodyText',
            label: translate('Body Text'),
            options: {
                filter: true,
                searchable: false,
            },
        },
        {
            name: 'createdAt',
            label: translate('Created'),
            options: {
                filter: true,
                searchable: false,
            },
        },
    ];

    return (
        <div style={{ position: 'relative' }}>
            <UpdatingContentProgress loading={loading} />
            <MUIDataTable
                // @ts-ignore
                ref={table}
                className={classes.table}
                title={title || translate('Email Templates')}
                data={vm.emailTemplates.map((emailTemplate) => ({
                    emailTemplateId: emailTemplate.emailTemplateId,
                    name: emailTemplate.name,
                    subject: emailTemplate.subject ?? '',
                    bodyHtml: emailTemplate.bodyHtml?.substring(0, 20) ?? '',
                    bodyText: emailTemplate.bodyText?.substring(0, 20) ?? '',
                    createdAt: formatDateTimeStringReadable(emailTemplate.createdAt, TimeZones.AMERICA_MONTERREY),
                }))}
                columns={columns}
                options={{
                    responsive: 'standard',
                    tableBodyMaxHeight: '500px',
                    selectableRows: !viewUser ? 'multiple' : 'none',
                    onRowClick: (
                        rowData: Array<string>,
                        rowMeta: {
                            dataIndex: number;
                            rowIndex: number;
                        },
                    ) => {
                        const emailTemplateId = rowData[0];
                        openChangeEmailTemplateDialog &&
                            openChangeEmailTemplateDialog({
                                emailTemplateId,
                                onSuccess: () => load(),
                            });
                    },
                    // customToolbarSelect: (selectedRows, displayData) => {
                    //     return (
                    //         <CustomToolbarSelect>
                    //             <ChangeToolbarButton onClick={() => {
                    //                 // const selectedEmailTemplateIds = selectedRowsData.map(d => d[0]);
                    //                 // openChangeEmailTemplatesDialog({
                    //                 //     emailTemplateIds: selectedEmailTemplateIds,
                    //                 //     onChange: load,
                    //                 // });
                    //             }} />
                    //         </CustomToolbarSelect>
                    //     );
                    // },
                    filterType: 'checkbox',
                    rowsPerPage: 100,
                    customToolbarSelect: (selectedRows, displayData) => {
                        const selectedRowsData = getSelectedRowsData(selectedRows, displayData);
                        const selectedEmailTemplateIds = selectedRowsData.map((d: any) => d[0]);
                        return (
                            <div className={classes.toolbar}>
                                <RemoveToolbarButton
                                    onClick={async () => {
                                        const remove = window.confirm(
                                            selectedEmailTemplateIds.length === 1
                                                ? 'Are you sure you want to remove the selected emailTemplates'
                                                : `Are you sure you want to remove the selected ${selectedEmailTemplateIds.length} emailTemplates`,
                                        );
                                        if (remove) {
                                            await removeEmailTemplates(selectedEmailTemplateIds);
                                        }
                                    }}
                                />
                            </div>
                        );
                    },
                    customToolbar: () => {
                        return (
                            <>
                                <RefreshToolbarButton
                                    onClick={() => {
                                        load();
                                        onClickRefresh && onClickRefresh();
                                    }}
                                />
                                <CreateToolbarButton
                                    onClick={() => {
                                        openCreateEmailTemplateDialog &&
                                            openCreateEmailTemplateDialog({
                                                onSuccess: () => load(),
                                            });
                                    }}
                                />
                            </>
                        );
                    },
                    onDownload: createFilteredOnDownloadFunctional(table),
                }}
            />
        </div>
    );
}

type Props = {
    title?: string;
    onClickRefresh?: any;
};

const useStyles = makeStyles((theme) => ({
    table: {
        whiteSpace: 'nowrap',
        '& tr': { cursor: 'pointer' },
        '& tr:hover': { backgroundColor: 'rgba(0, 0, 0, 0.07) !important' },
    },
    toolbar: {
        paddingRight: theme.spacing(3),
    },
}));
