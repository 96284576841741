import { isClosedOrder } from 'src/utils/order/isClosedOrder';
import { isEatHereOrder } from 'src/utils/order/isEatHereOrder';
import { useSelector } from 'src/utils/react/useSelector';

export function useShouldClosePendingOrder(): boolean {
    const closeOrderEnabled = useSelector((state) => state.app.restaurant?.closeOrdersEnabled);
    const orderType = useSelector((state) => state.pos.orderType);
    const pendingOrder = useSelector((state) => state.pos.pendingOrder);
    const promoCode = useSelector((state) => state.pos.promoCode);
    const usedPromotions = useSelector((state) => state.pos.payment?.usedPromotions);
    const discount = useSelector((state) => state.pos.discount);
    const payments = useSelector((state) => state.pos.payments);

    const shouldCloseOrder = closeOrderEnabled && pendingOrder && isEatHereOrder(orderType) && !isClosedOrder(pendingOrder.orderStatus) && !payments?.length;
    const hasSomeDiscountOrPromotion = promoCode?.promoCodeId || usedPromotions?.length || discount?.discount;
    const shouldClosePendingOrder = shouldCloseOrder && hasSomeDiscountOrPromotion;

    return shouldClosePendingOrder;
}
