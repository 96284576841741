import Grid from '@material-ui/core/Grid';
import Snackbar from '@material-ui/core/Snackbar';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import Alert from '@material-ui/lab/Alert';
import moment from 'moment-timezone';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { letseatmanagerApiDeprecated } from 'src/api/letseatmanagerApiDeprecated';
import { app2 } from 'src/app2';
import SelectRestaurantKitchensAndDeliveryStationsTypeahead from 'src/components/deprecated/react-final-form/SelectRestaurantKitchensAndDeliveryStationsTypeahead';
import { RefreshToolbarButton } from 'src/components/mui-datatables/RefreshToolbarButton';
import { MINUTES } from 'src/constants/TimeUnit';
import { TimeZones } from 'src/constants/TimeZone';
import { translate } from 'src/i18n/translate';
import OfficeDeliveryStats from 'src/scenes/letseatadmin/officeDelivery/OfficeDeliveryStats';
import type { OfficeDeliveryItemVm } from 'src/scenes/letseatadmin/OfficeDeliveryPage';
import DeliveryItemsTable from 'src/scenes/letseatdriver/deliveryItems/DeliveryItemsTable';
import { RestaurantId } from 'src/types/Id';
import { alertKnownErrorOrSomethingWentWrong } from 'src/utils/alert/alertKnownErrorOrSomethingWentWrong';
import { formatDateTimeStringReadable } from 'src/utils/date/formatDateTimeStringReadable';
import { isoWeekdayToName } from 'src/utils/day/isoWeekdayToName';
import { useAction } from 'src/utils/react/useAction';
import { useInterval } from 'src/utils/react/useInterval';
import { useSelector } from 'src/utils/react/useSelector';

export function DeliveryItemsPage(): React.ReactElement {
    const restaurants = useSelector((state) => state.app.restaurants) ?? [];

    const [loading, setLoading] = useState(false);
    const [lastUpdate, setLastUpdate] = useState<Date>();
    const [year, setYear] = useState<number | undefined>(moment.tz(TimeZones.AMERICA_MONTERREY).year());
    const [week, setWeek] = useState<number | undefined>(moment.tz(TimeZones.AMERICA_MONTERREY).week());
    const [restaurantIds, setRestaurantIds] = useState<Array<RestaurantId>>([]);
    const [today, setToday] = useState<string>();
    const [unknown, setUnknown] = useState<DayType>({
        officeDeliveryItems: [],
        stats: {},
    });
    const [monday, setMonday] = useState<DayType>({
        officeDeliveryItems: [],
        stats: {},
    });
    const [tuesday, setTuesday] = useState<DayType>({
        officeDeliveryItems: [],
        stats: {},
    });
    const [wednesday, setWednesday] = useState<DayType>({
        officeDeliveryItems: [],
        stats: {},
    });
    const [thursday, setThursday] = useState<DayType>({
        officeDeliveryItems: [],
        stats: {},
    });
    const [friday, setFriday] = useState<DayType>({
        officeDeliveryItems: [],
        stats: {},
    });

    const setTitle = useAction(app2.actions.setTitle);

    useEffect(() => {
        setTitle(translate('Office Delivery'));
    }, []);

    useEffect(() => {
        load();
    }, [year, week, restaurantIds]);

    useInterval(
        () => {
            load();
        },
        2 * MINUTES,
        [year, week, restaurantIds],
    );

    const load = async (skipAlert?: boolean) => {
        if (!year || !week || loading) return;

        setLoading(true);
        const response = await letseatmanagerApiDeprecated.driver.getDeliveryItems({ year, week, restaurantIds: restaurantIds });
        setLoading(false);

        if (!response.ok) {
            if (!skipAlert) alertKnownErrorOrSomethingWentWrong(response);
            return;
        }

        const currentYear = moment.tz(TimeZones.AMERICA_MONTERREY).year();
        const currentWeek = moment.tz(TimeZones.AMERICA_MONTERREY).week();

        setLastUpdate(new Date());
        setToday(currentYear === year && currentWeek === week ? isoWeekdayToName(moment.tz(TimeZones.AMERICA_MONTERREY).isoWeekday()) : undefined);
        setUnknown(response.data.unknown);
        setMonday(response.data.monday);
        setTuesday(response.data.tuesday);
        setWednesday(response.data.wednesday);
        setThursday(response.data.thursday);
        setFriday(response.data.friday);
    };

    const getValuesDay = (day: string) => {
        if (day === 'monday') return monday;
        else if (day === 'tuesday') return tuesday;
        else if (day === 'wednesday') return wednesday;
        else if (day === 'thursday') return thursday;
        else if (day === 'friday') return friday;
        else return unknown;
    };

    return (
        <>
            <Grid container spacing={5}>
                <Grid item xs={12}>
                    <Grid item xs={12}>
                        <TextField
                            label={translate('Year')}
                            value={year}
                            onChange={({ target: { value } }) => setYear(parseInt(value) || undefined)}
                            onKeyPress={({ key }) => {
                                if (key === 'Enter') load();
                            }}
                            style={{ width: 280 }}
                        />
                        <TextField
                            label={translate('Week')}
                            value={week}
                            onChange={({ target: { value } }) => setWeek(parseInt(value) || undefined)}
                            onKeyPress={({ key }) => {
                                if (key === 'Enter') load();
                            }}
                            style={{ width: 280 }}
                        />
                        <RefreshToolbarButton onClick={load} disabled={!year || !week} />
                        <Typography component='span' variant='body1' color='textSecondary'>
                            Autoupdating enabled
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <div style={{ width: '100%', display: 'inline-block', zIndex: 9999 }}>
                            <SelectRestaurantKitchensAndDeliveryStationsTypeahead
                                input={{
                                    onChange: (value: Array<RestaurantId>) => setRestaurantIds(value),
                                    value: restaurantIds,
                                }}
                                placeholder={'Filter by Kitchen or Station'}
                                selectMultiple
                                fullWidth
                                restaurantIds={restaurants.map((r) => r.restaurantId)}
                            />
                        </div>
                    </Grid>
                </Grid>
                {today && getValuesDay(today.toLowerCase())?.officeDeliveryItems && (
                    <>
                        <Grid item xs={12}>
                            <DeliveryItemsTable
                                title={`${translate('Today')} (${translate(today)})`}
                                officeDeliveryItems={getValuesDay(today.toLowerCase()).officeDeliveryItems}
                                loading={loading}
                                onClickRefresh={load}
                            />
                            <OfficeDeliveryStats stats={getValuesDay(today.toLowerCase()).stats} officeDeliveryItems={getValuesDay(today.toLowerCase()).officeDeliveryItems} />
                            <br />
                        </Grid>
                    </>
                )}
                <Grid item xs={12}>
                    <DeliveryItemsTable title={translate('Monday')} officeDeliveryItems={monday.officeDeliveryItems} loading={loading} onClickRefresh={load} />
                    <OfficeDeliveryStats stats={monday.stats} officeDeliveryItems={monday.officeDeliveryItems} />
                    <br />
                </Grid>
                <Grid item xs={12}>
                    <DeliveryItemsTable title={translate('Tuesday')} officeDeliveryItems={tuesday.officeDeliveryItems} loading={loading} onClickRefresh={load} />
                    <OfficeDeliveryStats stats={tuesday.stats} officeDeliveryItems={tuesday.officeDeliveryItems} />
                    <br />
                </Grid>
                <Grid item xs={12}>
                    <DeliveryItemsTable title={translate('Wednesday')} officeDeliveryItems={wednesday.officeDeliveryItems} loading={loading} onClickRefresh={load} />
                    <OfficeDeliveryStats stats={wednesday.stats} officeDeliveryItems={wednesday.officeDeliveryItems} />
                    <br />
                </Grid>
                <Grid item xs={12}>
                    <DeliveryItemsTable title={translate('Thursday')} officeDeliveryItems={thursday.officeDeliveryItems} loading={loading} onClickRefresh={load} />
                    <OfficeDeliveryStats stats={thursday.stats} officeDeliveryItems={thursday.officeDeliveryItems} />
                    <br />
                </Grid>
                <Grid item xs={12}>
                    <DeliveryItemsTable title={translate('Friday')} officeDeliveryItems={friday.officeDeliveryItems} loading={loading} onClickRefresh={load} />
                    <OfficeDeliveryStats stats={friday.stats} officeDeliveryItems={friday.officeDeliveryItems} />
                    <br />
                </Grid>
            </Grid>
            <Snackbar open={lastUpdate && moment(lastUpdate).isBefore(moment().subtract(5, 'minutes'))}>
                <Alert variant='filled' severity='error'>{`Failed to auto update orders since ${formatDateTimeStringReadable(lastUpdate)}. Check your connection!`}</Alert>
            </Snackbar>
        </>
    );
}

type DayType = {
    officeDeliveryItems: Array<OfficeDeliveryItemVm>;
    stats: Stats;
};

export type Stats = {
    [key: string]: {
        pickupStationStats: {
            [key: string]: number;
        };
        officeDeliveryItemStats: {
            [key: string]: number;
        };
    };
};
