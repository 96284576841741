import { InputBase, InputLabel, makeStyles } from '@material-ui/core';
import FormHelperText from '@material-ui/core/FormHelperText';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { Tooltip } from 'src/components/Tooltip';
import { classNames } from 'src/utils/react/classNames';
import { trim as _trim } from 'src/utils/string/trim';
import { upperCase as _upperCase } from 'src/utils/string/upperCase';

export const TransformedTextField: React.ComponentType<any> = React.forwardRef<any, any>(function TransformedTextField(
    { name, value, onChange, trim, upperCase, label, error, helperText, classesProp, InputProps, InputLabelProps, required, type, transformValue, ...props },
    ref,
) {
    const classes = useStyles();

    const [inputValue, setInputValue] = useState(transformToInput(value)); // Separate form value from input value

    useEffect(() => {
        const oldValue = transformToOutput(inputValue, trim, upperCase);
        if (value !== oldValue) setInputValue(transformToInput(value));
    }, [value, trim, upperCase]);

    const numberInputOnWheelPreventChange = (e: any) => {
        e.target.blur();
        e.stopPropagation();
        setTimeout(() => {
            e.target.focus();
        }, 0);
    };

    return (
        <div className={classesProp?.container}>
            <InputLabel htmlFor={`${name}-input`} classes={{ root: classNames(classes.label, classesProp?.label), error: classes.labelError }} error={error} {...InputLabelProps} required={required}>
                {label}
                {props.tooltip && <Tooltip text={props.tooltip} />}
            </InputLabel>
            <InputBase
                {...props}
                {...InputProps}
                id={`${name}-input`}
                name={name}
                value={inputValue}
                onChange={(e) => {
                    const valueTransformed = transformValue?.(e.target.value);

                    setInputValue(valueTransformed ?? e.target.value);
                    onChange?.(transformToOutput(valueTransformed ?? e.target.value, trim, upperCase));
                }}
                onWheel={numberInputOnWheelPreventChange}
                classes={{ root: classNames(classes.input, (classes as any).inputBorder, classesProp?.input), error: classNames(classes.inputError, classesProp?.error) }}
                error={error}
                required={required}
                type={type ?? 'text'}
            />
            {!!helperText && <FormHelperText classes={{ root: classNames(error ? classes.helperTextError : classes.helperText, classesProp?.helperText) }}>{helperText}</FormHelperText>}
        </div>
    );
});

function transformToInput(value?: string | null): string {
    return value ?? '';
}

export function transformToOutput(value?: string | null, trim?: boolean, upperCase?: boolean): string | null | undefined {
    let outputValue = value;
    if (trim) outputValue = _trim(outputValue);
    if (upperCase) outputValue = _upperCase(outputValue);
    return !outputValue ? null : outputValue;
}

const useStyles = makeStyles((theme) => ({
    input: {
        height: '42px',
        padding: 15,
        width: '100%',
        outline: '1px solid #D9D9D9',
        backgroundColor: 'transparent',
        borderRadius: '4px',
        position: 'relative',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        gap: 10,
        boxSizing: 'border-box',
        color: '#191919',
        fontFamily: theme.typography.light,
        border: 0,
    },
    inputError: {
        border: '2px solid red',
    },
    label: {
        fontFamily: theme.typography.light,
        color: '#2E3748',
        fontSize: 14,
        paddingBottom: 5,
        display: 'flex',
        gap: 4,
    },
    labelError: {
        color: 'red',
    },
    helperText: {
        fontFamily: theme.typography.light,
        color: theme.palette.secondary.contrastText,
        wordBreak: 'break-all',
    },
    helperTextError: {
        fontFamily: theme.typography.light,
        color: 'red',
    },
}));
