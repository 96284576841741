import { letseatadminApiMethod } from 'src/api/letseatadminApi';
import type { ApiSauceResponse } from 'src/api/types/ApiSauceResponse';
import type { SupportContactId } from 'src/types/Id';

export async function getSupportContactApi(request: GetSupportContactApiRequest): ApiSauceResponse<SupportContactVm> {
    return letseatadminApiMethod('supportContact/getSupportContactApi', request);
}

export type GetSupportContactApiRequest = {
    supportContactId: SupportContactId;
};

export type SupportContactVm = {
    supportContactId: SupportContactId;
    name?: string;
    phoneNumber?: string;
    available?: boolean;
    createdAt: Date;
    modifiedAt: Date;
};
