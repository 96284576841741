import { letseatadminApiMethod } from 'src/api/letseatadminApi';
import type { ApiSauceResponse } from 'src/api/types/ApiSauceResponse';
import type { PaymentId, RestaurantId, SubscriptionId } from 'src/types/Id';

export async function findSubscriptionPayPerUsePaymentsApi(request: FindSubscriptionPayPerUsePaymentsApiRequest): ApiSauceResponse<SubscriptionPayPerUsePaymentsVm> {
    return letseatadminApiMethod('subscriptionPayPerUsePayments/findSubscriptionPayPerUsePaymentsApi', request);
}

export type FindSubscriptionPayPerUsePaymentsApiRequest = {
    from: string;
    to: string;
};

export type SubscriptionPayPerUsePaymentsVm = {
    payments: Array<{
        paymentId: PaymentId;
        subscriptionId: SubscriptionId;
        restaurantId: RestaurantId;
        restaurantName: string;
        amount: string;
        facturamaInvoiceError?: string;
        createdAt: Date;
        invoicedAt?: Date;
    }>;
    totalAmountLeftToInvoice: string;
    totalAmountInvoiced: string;
};
