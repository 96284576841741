import { letseatmanagerApiMethod } from 'src/api/letseatmanagerApi';
import type { ApiSauceResponse } from 'src/api/types/ApiSauceResponse';
import type { PosBusinessDaySection } from 'src/constants/PosBusinessDaySections';
import type { RestaurantId } from 'src/types/Id';

export async function changePosBusinessDaySectionsApi(request: ChangePosBusinessDaySectionsRequest): ApiSauceResponse<void> {
    return letseatmanagerApiMethod('restaurant/changePosBusinessDaySectionsApi', request);
}

type ChangePosBusinessDaySectionsRequest = {
    restaurantId: RestaurantId;
    posBusinessDaySections: Array<PosBusinessDaySection>;
};
