import { Grid } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { makeStyles } from '@material-ui/core/styles';
import { BigNumber } from 'bignumber.js';
import { useState } from 'react';
import * as React from 'react';
import { useForm, useWatch } from 'react-hook-form';
import { updateCashOrderSubTotalApi } from 'src/api/letseatmanager/order/updateCashOrderSubTotalApi';
import { Form } from 'src/components/form/Form';
import { FormNumberStringField } from 'src/components/form/FormNumberStringField';
import { translate } from 'src/i18n/translate';
import { useFormatAsRestaurantCurrencyNumber } from 'src/services/restaurant/useFormatAsRestaurantCurrencyNumber';
import type { OrderId } from 'src/types/Id';
import { alertKnownErrorOrSomethingWentWrong } from 'src/utils/alert/alertKnownErrorOrSomethingWentWrong';
import { classNames } from 'src/utils/react/classNames';

export function ChangeCashOrderTotalDialog({ open, onClose, orderId, total }: Props): React.ReactElement {
    const classes = useStyles();
    const formatAsCurrencyNumber = useFormatAsRestaurantCurrencyNumber();
    const form = useForm();
    const { control, setValue } = form;

    const [error, setError] = useState(false);
    const [loading, setLoading] = useState(false);
    const newSubTotal = useWatch({ name: 'newSubTotal', control, defaultValue: '0' });

    const handleSubmit = async () => {
        setError(false);
        if (loading) return;
        if (!orderId) return;

        if (!Boolean(newSubTotal) || BigNumber(newSubTotal).toNumber() === 0) {
            return setError(true);
        }

        setLoading(true);
        const response = await updateCashOrderSubTotalApi({
            orderId,
            newSubTotal: newSubTotal,
        });
        setLoading(false);

        if (!response.ok) {
            return alertKnownErrorOrSomethingWentWrong(response);
        }

        onClose();
    };

    return (
        <Dialog open={open}>
            <Form form={form} onSubmit={handleSubmit}>
                <DialogTitle classes={{ root: classes.dialogTitle }}>
                    <div>{translate('Change order cost')}</div>
                </DialogTitle>
                <DialogContent style={{ margin: '0 auto 0 auto' }}>
                    <Grid item xs={12} key={'1'}>
                        <FormNumberStringField name={`newSubTotal`} label={translate('New order cost')} required min={1} />
                    </Grid>
                    <p>
                        {translate('Old Cost:')}
                        {formatAsCurrencyNumber(total)}
                    </p>
                    {error && <p className={classNames(classes.bodyText, classes.errorText)}>{translate('Value is empty')}</p>}
                </DialogContent>
                <DialogActions className={classes.buttonsContainer}>
                    <Button className={classes.cancelButton} variant={'outlined'} onClick={onClose} disabled={loading}>
                        {translate('Cancel')}
                    </Button>
                    <Button className={classes.updateButton} variant={'outlined'} onClick={handleSubmit} disabled={loading}>
                        {translate('Update')}
                    </Button>
                </DialogActions>
            </Form>
        </Dialog>
    );
}

const useStyles = makeStyles((theme) => ({
    dialogTitle: {
        textAlign: 'center',
        fontSize: 41,
        color: theme.palette.primary.main,
        paddingTop: theme.spacing(4),
        paddingBottom: theme.spacing(3),
        '& h2': {
            fontFamily: theme.typography.medium,
        },
    },
    bodyText: {
        fontFamily: theme.typography.medium,
        textAlign: 'center',
        color: theme.palette.secondary.contrastText,
    },

    errorText: {
        color: 'red',
    },

    buttonsContainer: {
        marginTop: 20,
        marginBottom: 20,
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
    },

    updateButton: {
        color: theme.palette.primary.main,
        border: `1px solid ${theme.palette.primary.main}`,
        fontFamily: theme.typography.semiBold,
        fontSize: 15,
        borderRadius: 5,
        height: 40,
        width: 150,
        textTransform: 'none',
    },
    cancelButton: {
        fontFamily: theme.typography.semiBold,
        fontSize: 15,
        borderRadius: 5,
        height: 40,
        width: 150,
        textTransform: 'none',
    },
}));

type Props = {
    open: boolean;
    onClose: any;
    orderId: OrderId;
    total: string;
};
