import * as React from 'react';
import { useEffect, useState } from 'react';
import { FormAutocompleteMultiple } from 'src/components/form/FormAutocompleteMultiple';
import type { Variant } from 'src/components/form/FormTypes';
import { App, Apps } from 'src/constants/App';
import TheMenuActions from 'src/scenes/letseatmanager/deprecatedMenu/TheMenuReducer';
import { useMenus } from 'src/services/menu/useMenus';
import { useMenuCategories } from 'src/services/menuCategory/useMenuCategories';
import type { MenuCategoryVm as DeprecatedMenuCategoryVm } from 'src/types/DeprecatedMenuCategoryVm';
import type { MenuCategoryId, MenuId } from 'src/types/Id';
import type { MenuCategoryVm } from 'src/types/MenuCategoryVm';
import { useAction } from 'src/utils/react/useAction';
import { useSelector } from 'src/utils/react/useSelector';

export function FormCategoryAutocompleteMultiple({ name, label, placeholder, helperText, defaultValue, disabled, required, variant, menuId, menuChannels, onChange }: Props): React.ReactElement {
    const { menus, deprecatedMenus } = useMenus();
    const { menuCategories, deprecatedMenuCategories } = useMenuCategories();

    const menuCategoryOptionsInitialState = [...menuCategories, ...deprecatedMenuCategories].map((menuCategory) => ({
        label: menuCategory.name,
        value: menuCategory.menuCategoryId,
    }));
    const [menuCategoryOptions, setMenuCategoryOptions] = useState<Array<{ label: string; value: MenuCategoryId | null }>>(menuCategoryOptionsInitialState);

    const newMenuManagementEnabled = useSelector((state) => state.app.restaurant.newMenuManagementEnabled);
    const restaurantId = useSelector((state) => state.app.restaurantId);

    const fetch = useAction(TheMenuActions.fetch);

    useEffect(() => {
        handleMenuCategoryOptions();
    }, [JSON.stringify({ menus, menuChannels, required, menuCategories, deprecatedMenuCategories })]);

    useEffect(() => {
        if (!newMenuManagementEnabled && deprecatedMenus.length === 0) fetch(restaurantId);
    }, [newMenuManagementEnabled, deprecatedMenus, restaurantId]);

    const handleMenuCategoryOptions = () => {
        let menuCategoriesToFilter = [...menuCategories, ...deprecatedMenuCategories];
        if (menuId) {
            menuCategoriesToFilter = filterMenuCategoriesByMenuId(menuCategoriesToFilter, menuId);
        }
        if (menuChannels) {
            menuCategoriesToFilter = filterMenuCategoriesByChannels(menuCategoriesToFilter, menuChannels);
        }

        const newMenuCategoryOptions: Array<{ label: string; value: MenuCategoryId | null }> = menuCategoriesToFilter.map((menuCategory) => ({
            label: menuCategory.name,
            value: menuCategory.menuCategoryId,
        }));
        if (required) {
            newMenuCategoryOptions.unshift({ label: '', value: null });
        }

        setMenuCategoryOptions(newMenuCategoryOptions);
    };

    const filterMenuCategoriesByMenuId = (menuCategories: Array<MenuCategoryVm | DeprecatedMenuCategoryVm>, menuId: MenuId) => {
        if (newMenuManagementEnabled) {
            const menu = menus.find((menu) => menu.menuId === menuId);
            if (!menu) return menuCategories;

            const menuCategoriesFiltered = menuCategories.filter((menuCategory) => menu?.menuCategoryIds.includes(menuCategory.menuCategoryId));
            return menuCategoriesFiltered as any;
        }

        const menu = deprecatedMenus.find((menu) => menu.menuId === menuId);
        if (!menu) return menuCategories;

        const menuCategoriesFiltered = deprecatedMenuCategories.filter((menuCategory) => menu?.categories.some((category) => category.menuCategoryId === menuCategory.menuCategoryId));
        return menuCategoriesFiltered as any;
    };

    const filterMenuCategoriesByChannels = (menuCategories: Array<MenuCategoryVm | DeprecatedMenuCategoryVm>, menuChannels: Array<App>) => {
        if (newMenuManagementEnabled) {
            const menusFiltered = menus.filter((menu) => {
                const channelsInMenu = menu.channels ?? [Apps.PIDEDIRECTO];
                return channelsInMenu.some((menuChannel) => menuChannels.includes(menuChannel));
            });
            if (menusFiltered.length === 0) return [];

            const menuCategoriesFiltered = menuCategories.filter((menuCategory) => menusFiltered.some((menu) => menu?.menuCategoryIds.includes(menuCategory.menuCategoryId)));
            return menuCategoriesFiltered as any;
        }

        const menusFiltered = deprecatedMenus.filter((menu) => {
            const channelsInMenu = menu.channels ?? [Apps.PIDEDIRECTO];
            return channelsInMenu.some((menuChannel) => menuChannels.includes(menuChannel));
        });
        if (menusFiltered.length === 0) return [];

        const menuCategoriesFiltered = menuCategories.filter((menuCategory) =>
            menusFiltered.some((menu) => menu?.categories.some((category) => category.menuCategoryId === menuCategory.menuCategoryId)),
        );
        return menuCategoriesFiltered as any;
    };

    return (
        <FormAutocompleteMultiple
            name={name}
            label={label}
            placeholder={placeholder}
            helperText={helperText}
            defaultValue={defaultValue as any}
            options={menuCategoryOptions}
            disabled={disabled}
            required={required}
            variant={variant}
            onChange={onChange}
        />
    );
}

type Props = {
    name: string;
    label?: string;
    placeholder?: string;
    helperText?: string;
    defaultValue?: Array<MenuCategoryId>;
    disabled?: boolean;
    required?:
        | boolean
        | {
              value: number;
              message: string;
          };
    variant?: Variant;
    menuId?: MenuId;
    menuChannels?: Array<App>;
    onChange?: any;
};
