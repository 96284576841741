import { makeStyles } from '@material-ui/core';
import Tooltip from '@material-ui/core/Tooltip';
import * as React from 'react';
import { Button } from 'src/components/Button';
import { translate } from 'src/i18n/translate';
import { AddCircleOutlineIcon } from 'src/icons/AddCircleOutlineIcon';
import { useSelector } from 'src/utils/react/useSelector';

export function CreateToolbarButton({ tooltip, titleAccess, onClick, enabledForUserTypeView = false }: Props): React.ReactElement {
    const classes = useStyles();

    const viewUser = useSelector((state) => state.authentication.viewUser);

    return (
        <Tooltip title={tooltip || translate('Create')}>
            <Button classes={{ button: classes.button }} icon onClick={onClick} disabled={viewUser && !enabledForUserTypeView}>
                <AddCircleOutlineIcon title={titleAccess || 'create toolbar button'} />
            </Button>
        </Tooltip>
    );
}

const useStyles = makeStyles((theme) => ({
    button: {
        verticalAlign: 'center',
        display: 'inline-flex',
        width: 50,
        height: 50,
    },
}));

type Props = {
    tooltip?: string;
    titleAccess?: string;
    onClick: any;
    enabledForUserTypeView?: boolean;
};
