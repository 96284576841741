import { makeStyles } from '@material-ui/core';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { useContext } from 'react';
import { Button } from 'src/components/Button';
import { Channels } from 'src/components/Channels';
import { App } from 'src/constants/App';
import { CreatePrinterSteps } from 'src/constants/CreatePrinterStep';
import { translate } from 'src/i18n/translate';
import { PrinterWizardContext, PrinterWizardProviderValues } from 'src/providers/PrinterWizardProvider';

export function ChannelsSelection(): React.ReactElement | null {
    const classes = useStyles();

    const { formValues, goNextWizardStep, goBackWizardStep, setCreatePrinterWizardFormValue, activeCreatePrinterStep } = useContext<PrinterWizardProviderValues>(PrinterWizardContext);

    const [channels, setChannels] = useState<Array<App> | undefined>(formValues.channels);

    const handleChangeChannels = (newChannels: any) => setChannels(newChannels);

    useEffect(() => {
        document.addEventListener('keydown', handleKeyDown);
        return () => {
            document.removeEventListener('keydown', handleKeyDown);
        };
    }, []);

    const handleChangeChannelsSelection = (channels?: Array<App>) => {
        setCreatePrinterWizardFormValue('channels', channels);
        goNextWizardStep();
    };

    const handleKeyDown = (event: KeyboardEvent) => {
        if (event.key === 'Enter') {
            handleChangeChannelsSelection(channels);
        }
    };

    if (activeCreatePrinterStep !== CreatePrinterSteps.CHANNELS) return null;

    return (
        <div className={classes.form}>
            <p className={classes.label}>{translate('Select the channels in which your printer will work on')}</p>
            <Channels onChange={(newChannels: Array<App>) => handleChangeChannels(newChannels)} />
            <div className={classes.actionsContainer}>
                <Button secondary onClick={goBackWizardStep} type={'button'}>
                    {translate('Back')}
                </Button>
                <Button primary onClick={() => handleChangeChannelsSelection(channels)}>
                    {translate('Next')}
                </Button>
                <p className={classes.enterText}>{translate('hit Enter ↩')}</p>
            </div>
        </div>
    );
}

const useStyles = makeStyles((theme) => ({
    form: {
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        animation: '$appear 700ms ease',
    },
    helperText: {
        color: '#565B69',
        fontSize: 12,
        fontFamily: theme.typography.regular,
    },
    secondaryButton: {
        marginTop: 12,
        alignSelf: 'end',
    },
    label: {
        fontSize: 16,
        fontFamily: theme.typography.regular,
        color: '#0D3037',
        padding: 0,
        margin: 0,
        marginBottom: 12,
    },
    actionsContainer: {
        display: 'flex',
        gap: 12,
        marginTop: 24,
        width: '100%',
        justifyContent: 'flex-end',
    },
    enterText: {
        fontFamily: theme.typography.regular,
        color: '#0D3037',
    },
    '@keyframes appear': {
        '0%': {
            opacity: 0,
            transform: 'translateY(100px)',
        },
        '100%': {
            opacity: 1,
            transform: 'translateY(0px)',
        },
    },
}));
