import { makeStyles } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Grid from '@material-ui/core/Grid';
import * as React from 'react';
import { useForm, useWatch } from 'react-hook-form';
import { scheduleNotificationToCustomerApi } from 'src/api/letseatmanager/marketing/scheduleNotificationToCustomerApi';
import { DeprecatedFormDiscountTypeSelect } from 'src/components/form/DeprecatedFormDiscountTypeSelect';
import { DeprecatedFormPercentNumberField } from 'src/components/form/DeprecatedFormPercentNumberField';
import { Form } from 'src/components/form/Form';
import { FormCheckbox } from 'src/components/form/FormCheckbox';
import { FormCurrencyNumberStringField } from 'src/components/form/FormCurrencyNumberStringField';
import { FormCustomerActivitySelect } from 'src/components/form/FormCustomerActivitySelect';
import { FormDatePicker } from 'src/components/form/FormDatePicker';
import { FormDaySelect } from 'src/components/form/FormDaySelect';
import { FormFixErrorHint } from 'src/components/form/FormFixErrorHint';
import { FormNotificationMessageTypeAutocomplete } from 'src/components/form/FormNotificationMessageTypeAutocomplete';
import { FormNumberField } from 'src/components/form/FormNumberField';
import { FormScheduleTaskIntervalSelect } from 'src/components/form/FormScheduleTaskIntervalSelect';
import { FormTextField } from 'src/components/form/FormTextField';
import { FormTimePicker } from 'src/components/form/FormTimePicker';
import { DiscountTypes } from 'src/constants/DiscountType';
import { NotificationMessageType, NotificationMessageTypes } from 'src/constants/NotificationMessageType';
import { ScheduleTaskIntervals } from 'src/constants/ScheduleTaskInterval';
import { translate } from 'src/i18n/translate';
import { appLayout } from 'src/theme/AppLayout';
import { type CustomerId, type RestaurantCustomerId } from 'src/types/Id';
import { alertKnownErrorOrSomethingWentWrong } from 'src/utils/alert/alertKnownErrorOrSomethingWentWrong';
import { useSelector } from 'src/utils/react/useSelector';

export function ScheduleNotificationToCustomerDialog({ open, restaurantCustomerMobileNumbers, customerIds, onClose, onSuccess }: Props): React.ReactElement {
    const form = useForm();
    const {
        formState: { isSubmitting },
        control,
    } = form;
    const classes = useStyles();

    const restaurantIds = useSelector((state) => state.app.restaurantIds);

    const notificationMessageType: Array<NotificationMessageType> | undefined = useWatch({ name: 'notificationMessageType', control });
    const interval = useWatch({ name: 'interval', control });
    const addPromoCode = useWatch({ name: 'addPromoCode', control });
    const discountType = useWatch({ name: 'discountType', control });

    const handleClose = () => {
        if (isSubmitting) return;
        onClose();
    };

    const onSubmit = async (form: any) => {
        const response = await scheduleNotificationToCustomerApi({
            restaurantIds,
            interval: form.interval,
            name: form.name,
            day: form.day,
            dayNumber: form.dayNumber,
            hour: form.hour,
            customerActivity: form.customerActivity,
            restaurantCustomerMobileNumbers,
            customerIds,
            notificationMessageType: form.notificationMessageType,
            message: form.message,
            title: form.title,
            addPromoCode: form.addPromoCode,
            discountType: form.discountType,
            discount: form.discount,
            maxDiscountAmount: form.maxDiscountAmount,
            date: form.date,
        });
        if (!response.ok) {
            alertKnownErrorOrSomethingWentWrong(response);
            return;
        }
        onSuccess();
    };

    return (
        <Dialog fullWidth scroll='paper' open={open} onClose={handleClose} classes={{ paper: classes.paper }}>
            <Form form={form} onSubmit={onSubmit} style={appLayout.dialog.form} className={classes.form}>
                <DialogTitle classes={{ root: classes.dialogTitle }}>{translate('Create Schedule Notification')}</DialogTitle>
                <DialogContent classes={{ root: classes.dialogContent }}>
                    {/* @ts-ignore */}
                    <Grid container spacing={3}>
                        <Grid item xs={12} md={6}>
                            <FormTextField name='name' label={translate('Name')} required />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <FormScheduleTaskIntervalSelect name='interval' label={translate('Time Interval')} required />
                        </Grid>
                        {interval === ScheduleTaskIntervals.EACH_MONTH && (
                            <Grid item xs={12} md={6}>
                                <FormNumberField name='dayNumber' label={translate('Day Number Of Month')} required />
                            </Grid>
                        )}
                        {interval === ScheduleTaskIntervals.EACH_WEEK && (
                            <Grid item xs={12} md={6}>
                                <FormDaySelect name='day' label={translate('Day')} required />
                            </Grid>
                        )}
                        {interval === ScheduleTaskIntervals.NO_REPEAT && (
                            <Grid item xs={12} md={6}>
                                <FormDatePicker name='date' label={translate('Date')} required />
                            </Grid>
                        )}
                        <Grid item xs={12} md={6}>
                            <FormTimePicker name='hour' label={translate('Hour')} required />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <FormCustomerActivitySelect name='customerActivity' label={translate('Customer Activity')} />
                        </Grid>
                        <Grid item xs={12}>
                            <FormNotificationMessageTypeAutocomplete name='notificationMessageType' label={translate('Notification Message Type')} required />
                        </Grid>
                        {(notificationMessageType?.includes(NotificationMessageTypes.EMAIL) || notificationMessageType?.includes(NotificationMessageTypes.PUSH)) && (
                            <Grid item xs={12}>
                                <FormTextField name='title' label={translate('Title')} required />
                            </Grid>
                        )}
                        <Grid item xs={12}>
                            <FormTextField
                                name='message'
                                label={translate('Message')}
                                helperText={addPromoCode ? translate('Write @promoCode and it will be replaced by the promo code when the message has been sent') : ''}
                                required
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <FormCheckbox name='addPromoCode' label={translate('Add Promo Code')} required />
                        </Grid>
                        {addPromoCode && (
                            <>
                                <Grid item xs={12} md={6}>
                                    <DeprecatedFormDiscountTypeSelect name='discountType' label={translate('Discount Type')} required />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    {discountType === DiscountTypes.AMOUNT && <FormCurrencyNumberStringField name='discount' label={translate('Discount')} required />}
                                    {discountType === DiscountTypes.PERCENT && <DeprecatedFormPercentNumberField name='discount' label={translate('Discount')} required />}
                                </Grid>
                                {discountType === DiscountTypes.PERCENT && (
                                    <Grid item xs={12} md={6}>
                                        <FormCurrencyNumberStringField name='maxDiscountAmount' label={translate('Max Discount Amount')} min={0} />
                                    </Grid>
                                )}
                            </>
                        )}
                        <FormFixErrorHint />
                    </Grid>
                </DialogContent>
                <DialogActions className={classes.buttonsContainer}>
                    <Button onClick={handleClose} disabled={isSubmitting}>
                        <div className={classes.cancelButton}>{translate('Cancel')}</div>
                    </Button>
                    <Button color='primary' type='submit' disabled={isSubmitting} classes={{ root: classes.noMargin }}>
                        <div className={classes.acceptButton}>{isSubmitting ? translate('Creating') : translate('Create')}</div>
                    </Button>
                </DialogActions>
            </Form>
        </Dialog>
    );
}

const useStyles = makeStyles((theme) => ({
    form: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        width: '100%',
    },
    dialogTitle: {
        textAlign: 'center',
        fontSize: 41,
        color: theme.palette.secondary.contrastText,
        paddingTop: theme.spacing(4),
        paddingBottom: theme.spacing(3),
        '& h2': {
            fontFamily: theme.typography.medium,
        },
    },
    paper: {
        [theme.breakpoints.down('md')]: {
            width: '80%',
            maxWidth: '80%',
        },
    },
    dialogContent: {
        width: '80%',
        overflowY: 'hidden',
        [theme.breakpoints.down('md')]: {
            width: '70%',
        },
        [theme.breakpoints.down('sm')]: {
            width: '100%',
        },
    },
    buttonsContainer: {
        marginTop: 20,
        [theme.breakpoints.down('sm')]: {
            flexDirection: 'column',
            marginBottom: 20,
        },
    },
    noMargin: {
        margin: '0 !important',
    },
    acceptButton: {
        backgroundColor: theme.palette.primary.main,
        color: 'white',
        fontFamily: theme.typography.semiBold,
        fontSize: 15,
        borderRadius: 5,
        height: 40,
        width: 200,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        textTransform: 'none',
        boxShadow: `3px 3px 10px ${theme.palette.primary.main}aa`,
    },
    cancelButton: {
        backgroundColor: theme.palette.secondary.dark,
        color: theme.palette.secondary.contrastText,
        fontFamily: theme.typography.semiBold,
        fontSize: 15,
        borderRadius: 5,
        height: 40,
        width: 200,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        textTransform: 'none',
    },
}));

type Props = {
    open: boolean;
    restaurantCustomerMobileNumbers?: Array<{ restaurantCustomerId: RestaurantCustomerId; mobileNumber: string }>;
    customerIds?: Array<CustomerId>;
    onClose: any;
    onSuccess: any;
};
