import { posReducer } from 'src/reducers/posReducer';
import { SetPosPaymentMethodParams, usePosPaymentMethodsActions } from 'src/services/pos/posPaymentMethods/posPaymentMethodsStore';
import { useAction } from 'src/utils/react/useAction';

export function useSetPosPaymentMethod(): (paymentMethod: SetPosPaymentMethodParams) => void {
    const setPosPaymentMethod = usePosPaymentMethodsActions((actions) => actions.setPosPaymentMethod);

    const setPaymentMethod = useAction(posReducer.actions.setPaymentMethod);

    const handleSetPosPaymentMethod = (paymentMethod: SetPosPaymentMethodParams) => {
        setPosPaymentMethod(paymentMethod);
        setPaymentMethod(paymentMethod);
    };

    return handleSetPosPaymentMethod;
}
