import { makeStyles } from '@material-ui/core';
import TableCell from '@material-ui/core/TableCell';
import TableFooter from '@material-ui/core/TableFooter';
import TableRow from '@material-ui/core/TableRow';
import moment from 'moment-timezone';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { getRestaurantsBillingUyApi, type RestaurantBillingUyVm, type RestaurantsBillingUyVm } from 'src/api/letseatadmin/restaurantBillingUy/getRestaurantsBillingUyApi';
import { tribalExportFormatUyApi } from 'src/api/letseatadmin/restaurantBillingUy/tribalExportFormatUyApi';
import { getRestaurantReportBillingDataApi } from 'src/api/letseatmanager/restaurant/getRestaurantReportBillingDataApi';
import { app2 } from 'src/app2';
import { CustomToolbarSelect } from 'src/components/mui-datatables/CustomToolbarSelect';
import { DownloadPdfToolbarButton } from 'src/components/mui-datatables/DownloadPdfToolbarButton';
import { ExportToolbarButton } from 'src/components/mui-datatables/ExportToolbarButton';
import { RefreshToolbarButton } from 'src/components/mui-datatables/RefreshToolbarButton';
import { Table } from 'src/components/Table';
import { UpdatingContentProgress } from 'src/components/UpdatingContentProgress';
import { CountryCodes } from 'src/constants/CountryCode';
import { MomentFormats } from 'src/constants/MomentFormat';
import { RoutePaths } from 'src/constants/RoutePath';
import { translate } from 'src/i18n/translate';
import { RestaurantId, type BrandId, type CountryCode } from 'src/types/Id';
import { alertKnownErrorOrSomethingWentWrong } from 'src/utils/alert/alertKnownErrorOrSomethingWentWrong';
import { downloadTextInFile } from 'src/utils/html/downloadTextInFile';
import { getSelectedRowsData } from 'src/utils/mui-datatables/getSelectedRowsData';
import { useAction } from 'src/utils/react/useAction';
import { useSelector } from 'src/utils/react/useSelector';
import { RegExps } from 'src/utils/RegExps';
import { createAndSaveBilling } from 'src/utils/restaurant/createAndSaveBilling';

export function RestaurantsBillingUy({ filter, refresh }: Props): React.ReactElement {
    const classes = useStyles();
    const viewUser = useSelector((state) => state.authentication.viewUser);

    const [loading, setLoading] = useState(false);
    const [vm, setVm] = useState<RestaurantsBillingUyVm | undefined>();

    const setTitle = useAction(app2.actions.setTitle);

    useEffect(() => {
        setTitle(translate('Restaurant Billing Uruguay'));
        load();
    }, [refresh]);

    const load = async () => {
        setLoading(true);
        const response = await getRestaurantsBillingUyApi({
            from: moment(filter.from).startOf('day').toDate(),
            to: moment(filter.to).endOf('day').toDate(),
            countryCode: filter.countryCode,
            brandId: filter.brandId,
        });
        setLoading(false);
        if (!response.ok) {
            alertKnownErrorOrSomethingWentWrong(response);
            return;
        }
        setVm(response.data);
    };

    return (
        <div className={classes.container}>
            <UpdatingContentProgress loading={loading} />
            <Table
                className={classes.table}
                data={
                    vm?.restaurantsBillingUy?.map((restaurantBilling: RestaurantBillingUyVm) => {
                        return {
                            restaurantId: restaurantBilling.restaurantId,
                            restaurantName: restaurantBilling.restaurantName,
                            brandName: restaurantBilling.brandName,
                            restaurantZone: restaurantBilling.restaurantZone,
                            completed: restaurantBilling.completed,
                            commissionWebCard: restaurantBilling.commissionWebCard,
                            commissionWebCardDebit: restaurantBilling.commissionWebCardDebit,
                            commissionWebCardCredit: restaurantBilling.commissionWebCardCredit,
                            commissionWebCash: restaurantBilling.commissionWebCash,
                            commissionWebCardOnDelivery: restaurantBilling.commissionWebCardOnDelivery,
                            commissionAppCard: restaurantBilling.commissionAppCard,
                            commissionAppCardDebit: restaurantBilling.commissionAppCardDebit,
                            commissionAppCardCredit: restaurantBilling.commissionAppCardCredit,
                            commissionAppCash: restaurantBilling.commissionAppCash,
                            commissionAppCardOnDelivery: restaurantBilling.commissionAppCardOnDelivery,
                            commissionManualOrders: restaurantBilling.commissionManualOrders,
                            commissionRappiCargoWebCard: restaurantBilling.commissionRappiCargoWebCard,
                            commissionRappiCargoWebCardDebit: restaurantBilling.commissionRappiCargoWebCardDebit,
                            commissionRappiCargoWebCardCredit: restaurantBilling.commissionRappiCargoWebCardCredit,
                            commissionRappiCargoWebCash: restaurantBilling.commissionRappiCargoWebCash,
                            commissionRappiCargoAppCard: restaurantBilling.commissionRappiCargoAppCard,
                            commissionRappiCargoAppCardDebit: restaurantBilling.commissionRappiCargoAppCardDebit,
                            commissionRappiCargoAppCardCredit: restaurantBilling.commissionRappiCargoAppCardCredit,
                            commissionRappiCargoAppCash: restaurantBilling.commissionRappiCargoAppCash,
                            commissionRappiCargoManualOrder: restaurantBilling.commissionRappiCargoManualOrder,
                            commissionRappiCargoPosCard: restaurantBilling.commissionRappiCargoPosCard,
                            commissionRappiCargoPosCash: restaurantBilling.commissionRappiCargoPosCash,
                            commissionUberDaasWebCard: restaurantBilling.commissionUberDaasWebCard,
                            commissionUberDaasWebCardDebit: restaurantBilling.commissionUberDaasWebCardDebit,
                            commissionUberDaasWebCardCredit: restaurantBilling.commissionUberDaasWebCardCredit,
                            commissionUberDaasWebCash: restaurantBilling.commissionUberDaasWebCash,
                            commissionUberDaasAppCard: restaurantBilling.commissionUberDaasAppCard,
                            commissionUberDaasAppCardDebit: restaurantBilling.commissionUberDaasAppCardDebit,
                            commissionUberDaasAppCardCredit: restaurantBilling.commissionUberDaasAppCardCredit,
                            commissionUberDaasAppCash: restaurantBilling.commissionUberDaasAppCash,
                            commissionUberDaasManualOrder: restaurantBilling.commissionUberDaasManualOrder,
                            commissionUberDaasPosCard: restaurantBilling.commissionUberDaasPosCard,
                            commissionUberDaasPosCash: restaurantBilling.commissionUberDaasPosCash,
                            webCard: restaurantBilling.webCard,
                            webCardDebit: restaurantBilling.webCardDebit,
                            webCardCredit: restaurantBilling.webCardCredit,
                            webCash: restaurantBilling.webCash,
                            webCardOnDelivery: restaurantBilling.webCardOnDelivery,
                            whiteLabelAppCard: restaurantBilling.whiteLabelAppCard,
                            whiteLabelAppCardDebit: restaurantBilling.whiteLabelAppCardDebit,
                            whiteLabelAppCardCredit: restaurantBilling.whiteLabelAppCardCredit,
                            whiteLabelAppCash: restaurantBilling.whiteLabelAppCash,
                            whiteLabelAppCardOnDelivery: restaurantBilling.whiteLabelAppCardOnDelivery,
                            marketplaceAppCard: restaurantBilling.marketplaceAppCard,
                            marketplaceAppCardDebit: restaurantBilling.marketplaceAppCardDebit,
                            marketplaceAppCardCredit: restaurantBilling.marketplaceAppCardCredit,
                            marketplaceAppCash: restaurantBilling.marketplaceAppCash,
                            marketplaceAppCardOnDelivery: restaurantBilling.marketplaceAppCardOnDelivery,
                            posCard: restaurantBilling.posCard,
                            posCash: restaurantBilling.posCash,
                            posCardOnDelivery: restaurantBilling.posCardOnDelivery,
                            marketplaceServiceFeeCash: restaurantBilling.marketplaceServiceFeeCash,
                            marketplaceServiceFeeCard: restaurantBilling.marketplaceServiceFeeCard,
                            marketplaceServiceFeeCardOnDelivery: restaurantBilling.marketplaceServiceFeeCardOnDelivery,
                            cashback: restaurantBilling.cashback,
                            manualOrders: restaurantBilling.manualOrders,
                            manualOrdersProtected: restaurantBilling.manualOrdersProtected,
                            directoProtectionAmount: restaurantBilling.directoProtectionAmount,
                            invoicedRestaurantDeliveryCost: restaurantBilling.invoicedRestaurantDeliveryCost,
                            restaurantPromoCodeCostApp: restaurantBilling.restaurantPromoCodeCostApp,
                            restaurantPromoCodeBalance: restaurantBilling.restaurantPromoCodeBalance,
                            rappiCargoWebCash: restaurantBilling.rappiCargoWebCash,
                            rappiCargoWebCard: restaurantBilling.rappiCargoWebCard,
                            rappiCargoWebCardDebit: restaurantBilling.rappiCargoWebCardDebit,
                            rappiCargoWebCardCredit: restaurantBilling.rappiCargoWebCardCredit,
                            rappiCargoAppCash: restaurantBilling.rappiCargoAppCash,
                            rappiCargoAppCard: restaurantBilling.rappiCargoAppCard,
                            rappiCargoAppCardDebit: restaurantBilling.rappiCargoAppCardDebit,
                            rappiCargoAppCardCredit: restaurantBilling.rappiCargoAppCardCredit,
                            rappiCargoManualOrder: restaurantBilling.rappiCargoManualOrder,
                            rappiCargoPosCard: restaurantBilling.rappiCargoPosCard,
                            rappiCargoPosCash: restaurantBilling.rappiCargoPosCash,
                            uberDaasWebCash: restaurantBilling.uberDaasWebCash,
                            uberDaasWebCard: restaurantBilling.uberDaasWebCard,
                            uberDaasWebCardDebit: restaurantBilling.uberDaasWebCardDebit,
                            uberDaasWebCardCredit: restaurantBilling.uberDaasWebCardCredit,
                            uberDaasAppCash: restaurantBilling.uberDaasAppCash,
                            uberDaasAppCard: restaurantBilling.uberDaasAppCard,
                            uberDaasAppCardDebit: restaurantBilling.uberDaasAppCardDebit,
                            uberDaasAppCardCredit: restaurantBilling.uberDaasAppCardCredit,
                            uberDaasManualOrder: restaurantBilling.uberDaasManualOrder,
                            uberDaasPosCard: restaurantBilling.uberDaasPosCard,
                            uberDaasPosCash: restaurantBilling.uberDaasPosCash,
                            total: restaurantBilling.total,
                            commission: restaurantBilling.commission,
                            iva: restaurantBilling.iva,
                            subscriptionPayPerUsePayment: restaurantBilling.subscriptionPayPerUsePayment,
                            manualEarning: restaurantBilling.manualEarning,
                            debtSubscriptionPayPerUsePayment: restaurantBilling.debtSubscriptionPayPerUsePayment,
                            debtManualEarning: restaurantBilling.debtManualEarning,
                            subscriptionPayPerUsePaymentInvoice: restaurantBilling.subscriptionPayPerUsePaymentInvoice,
                            manualEarningInvoice: restaurantBilling.manualEarningInvoice,
                            deposit: restaurantBilling.deposit,
                            paymentLink: restaurantBilling.paymentLink,
                            paymentLinkDebit: restaurantBilling.paymentLinkDebit,
                            paymentLinkCredit: restaurantBilling.paymentLinkCredit,
                        };
                    }) ?? []
                }
                columns={[
                    {
                        name: 'restaurantId',
                        label: ' ',
                        options: {
                            display: 'excluded',
                            filter: false,
                        },
                    },
                    {
                        name: 'restaurantName',
                        label: translate('Restaurant'),
                        options: {},
                    },
                    {
                        name: 'brandName',
                        label: translate('Brand'),
                        options: {},
                    },
                    {
                        name: 'restaurantZone',
                        label: translate('Zone'),
                        options: {},
                    },
                    {
                        name: 'commissionWebCard',
                        label: translate('Commission Web Card %'),
                        options: {
                            filter: false,
                            searchable: false,
                        },
                    },
                    {
                        name: 'commissionWebCardDebit',
                        label: translate('Commission Web Card % Debit'),
                        options: {
                            filter: false,
                            searchable: false,
                        },
                    },
                    {
                        name: 'commissionWebCardCredit',
                        label: translate('Commission Web Card % Credit'),
                        options: {
                            filter: false,
                            searchable: false,
                        },
                    },
                    {
                        name: 'commissionWebCash',
                        label: translate('Commission PD Web Cash %'),
                        options: {
                            filter: false,
                            searchable: false,
                        },
                    },
                    {
                        name: 'commissionWebCardOnDelivery',
                        label: translate('Commission PD Web Card on delivery %'),
                        options: {
                            filter: false,
                            searchable: false,
                        },
                    },
                    {
                        name: 'commissionAppCard',
                        label: translate('Commission PD App Card %'),
                        options: {
                            filter: false,
                            searchable: false,
                        },
                    },
                    {
                        name: 'commissionAppCardDebit',
                        label: translate('Commission PD App Card % Debit'),
                        options: {
                            filter: false,
                            searchable: false,
                        },
                    },
                    {
                        name: 'commissionAppCardCredit',
                        label: translate('Commission PD App Card % Credit'),
                        options: {
                            filter: false,
                            searchable: false,
                        },
                    },
                    {
                        name: 'commissionAppCash',
                        label: translate('Commission PD App Cash %'),
                        options: {
                            filter: false,
                            searchable: false,
                        },
                    },
                    {
                        name: 'commissionAppCardOnDelivery',
                        label: translate('Commission PD App CardOnDelivery %'),
                        options: {
                            filter: false,
                            searchable: false,
                        },
                    },
                    {
                        name: 'commissionManualOrders',
                        label: translate('Manual Orders Commission % (Always Cash)'),
                        options: {
                            filter: false,
                            searchable: false,
                        },
                    },
                    {
                        name: 'commissionRappiCargoWebCard',
                        label: translate('Commission Rappi Cargo Web Card'),
                        options: {
                            filter: false,
                            searchable: false,
                        },
                    },
                    {
                        name: 'commissionRappiCargoWebCardDebit',
                        label: translate('Commission Rappi Cargo Web Card Debit'),
                        options: {
                            filter: false,
                            searchable: false,
                        },
                    },
                    {
                        name: 'commissionRappiCargoWebCardCredit',
                        label: translate('Commission Rappi Cargo Web Card Credit'),
                        options: {
                            filter: false,
                            searchable: false,
                        },
                    },
                    {
                        name: 'commissionRappiCargoWebCash',
                        label: translate('Commission Rappi Cargo Web Cash'),
                        options: {
                            filter: false,
                            searchable: false,
                        },
                    },
                    {
                        name: 'commissionRappiCargoAppCard',
                        label: translate('Commission Rappi Cargo App Card'),
                        options: {
                            filter: false,
                            searchable: false,
                        },
                    },
                    {
                        name: 'commissionRappiCargoAppCardDebit',
                        label: translate('Commission Rappi Cargo App Card Debit'),
                        options: {
                            filter: false,
                            searchable: false,
                        },
                    },
                    {
                        name: 'commissionRappiCargoAppCardCredit',
                        label: translate('Commission Rappi Cargo App Card Credit'),
                        options: {
                            filter: false,
                            searchable: false,
                        },
                    },
                    {
                        name: 'commissionRappiCargoAppCash',
                        label: translate('Commission Rappi Cargo App Cash'),
                        options: {
                            filter: false,
                            searchable: false,
                        },
                    },
                    {
                        name: 'commissionRappiCargoManualOrder',
                        label: translate('Commission Rappi Cargo Manual Order'),
                        options: {
                            filter: false,
                            searchable: false,
                        },
                    },
                    {
                        name: 'commissionRappiCargoPosCard',
                        label: translate('Commission Rappi Cargo Pos Card'),
                        options: {
                            filter: false,
                            searchable: false,
                        },
                    },
                    {
                        name: 'commissionRappiCargoPosCash',
                        label: translate('Commission Rappi Cargo Pos Cash'),
                        options: {
                            filter: false,
                            searchable: false,
                        },
                    },
                    {
                        name: 'commissionUberDaasWebCard',
                        label: translate('Commission Uber Daas Web Card'),
                        options: {
                            filter: false,
                            searchable: false,
                        },
                    },
                    {
                        name: 'commissionUberDaasWebCardDebit',
                        label: translate('Commission Uber Daas Web Card Debit'),
                        options: {
                            filter: false,
                            searchable: false,
                        },
                    },
                    {
                        name: 'commissionUberDaasWebCardCredit',
                        label: translate('Commission Uber Daas Web Card Credit'),
                        options: {
                            filter: false,
                            searchable: false,
                        },
                    },
                    {
                        name: 'commissionUberDaasWebCash',
                        label: translate('Commission Uber Daas Web Cash'),
                        options: {
                            filter: false,
                            searchable: false,
                        },
                    },
                    {
                        name: 'commissionUberDaasAppCard',
                        label: translate('Commission Uber Daas App Card'),
                        options: {
                            filter: false,
                            searchable: false,
                        },
                    },
                    {
                        name: 'commissionUberDaasAppCardDebit',
                        label: translate('Commission Uber Daas App Card Debit'),
                        options: {
                            filter: false,
                            searchable: false,
                        },
                    },
                    {
                        name: 'commissionUberDaasAppCardCredit',
                        label: translate('Commission Uber Daas App Card Credit'),
                        options: {
                            filter: false,
                            searchable: false,
                        },
                    },
                    {
                        name: 'commissionUberDaasAppCash',
                        label: translate('Commission Uber Daas App Cash'),
                        options: {
                            filter: false,
                            searchable: false,
                        },
                    },
                    {
                        name: 'commissionUberDaasManualOrder',
                        label: translate('Commission Uber Daas Manual Order'),
                        options: {
                            filter: false,
                            searchable: false,
                        },
                    },
                    {
                        name: 'commissionUberDaasPosCard',
                        label: translate('Commission Uber Daas Pos Card'),
                        options: {
                            filter: false,
                            searchable: false,
                        },
                    },
                    {
                        name: 'commissionUberDaasPosCash',
                        label: translate('Commission Uber Daas Pos Cash'),
                        options: {
                            filter: false,
                            searchable: false,
                        },
                    },
                    {
                        name: 'webCard',
                        label: translate('Web Card Amount'),
                        options: {
                            filter: false,
                            searchable: false,
                        },
                    },
                    {
                        name: 'webCardDebit',
                        label: translate('Web Card Amount Debit'),
                        options: {
                            filter: false,
                            searchable: false,
                        },
                    },
                    {
                        name: 'webCardCredit',
                        label: translate('Web Card Amount Credit'),
                        options: {
                            filter: false,
                            searchable: false,
                        },
                    },
                    {
                        name: 'webCash',
                        label: translate('Web Cash Amount'),
                        options: {
                            filter: false,
                            searchable: false,
                        },
                    },
                    {
                        name: 'webCardOnDelivery',
                        label: translate('Web Card on delivery Amount'),
                        options: {
                            filter: false,
                            searchable: false,
                        },
                    },
                    {
                        name: 'whiteLabelAppCard',
                        label: translate('App Card Amount'),
                        options: {
                            filter: false,
                            searchable: false,
                        },
                    },
                    {
                        name: 'whiteLabelAppCardDebit',
                        label: translate('App Card Amount Debit'),
                        options: {
                            filter: false,
                            searchable: false,
                        },
                    },
                    {
                        name: 'whiteLabelAppCardCredit',
                        label: translate('App Card Amount Credit'),
                        options: {
                            filter: false,
                            searchable: false,
                        },
                    },
                    {
                        name: 'whiteLabelAppCash',
                        label: translate('App Cash Amount'),
                        options: {
                            filter: false,
                            searchable: false,
                        },
                    },
                    {
                        name: 'whiteLabelAppCardOnDelivery',
                        label: translate('App Card on delivery Amount'),
                        options: {
                            filter: false,
                            searchable: false,
                        },
                    },
                    {
                        name: 'posCard',
                        label: translate('POS Card Amount'),
                        options: {
                            filter: false,
                            searchable: false,
                        },
                    },
                    {
                        name: 'posCash',
                        label: translate('POS Cash Amount'),
                        options: {
                            filter: false,
                            searchable: false,
                        },
                    },
                    {
                        name: 'posCardOnDelivery',
                        label: translate('POS Card on delivery Amount'),
                        options: {
                            filter: false,
                            searchable: false,
                        },
                    },
                    {
                        name: 'marketplaceServiceFeeCard',
                        label: translate('PD Marketplace Card'),
                        options: {
                            filter: false,
                            searchable: false,
                        },
                    },
                    {
                        name: 'marketplaceServiceFeeCash',
                        label: translate('PD Marketplace Cash'),
                        options: {
                            filter: false,
                            searchable: false,
                        },
                    },
                    {
                        name: 'marketplaceServiceFeeCardOnDelivery',
                        label: translate('PD Marketplace Card on delivery'),
                        options: {
                            filter: false,
                            searchable: false,
                        },
                    },
                    {
                        name: 'cashback',
                        label: translate('Cashback'),
                        options: {
                            filter: false,
                            searchable: false,
                        },
                    },
                    {
                        name: 'manualOrders',
                        label: translate('Manual Orders Amount'),
                        options: {
                            filter: false,
                            searchable: false,
                        },
                    },
                    {
                        name: 'manualOrdersProtected',
                        label: translate('Number of Manual Orders Protected'),
                        options: {
                            filter: false,
                            searchable: false,
                        },
                    },
                    {
                        name: 'directoProtectionAmount',
                        label: translate('Directo Protection Amount'),
                        options: {
                            filter: false,
                            searchable: false,
                        },
                    },
                    {
                        name: 'invoicedRestaurantDeliveryCost',
                        label: translate('Invoiced Deliveries'),
                        options: {
                            filter: false,
                            searchable: false,
                        },
                    },
                    {
                        name: 'paymentLink',
                        label: translate('Payment Links'),
                        options: {
                            filter: false,
                            searchable: false,
                        },
                    },
                    {
                        name: 'paymentLinkDebit',
                        label: translate('Payment Links Debit'),
                        options: {
                            filter: false,
                            searchable: false,
                        },
                    },
                    {
                        name: 'paymentLinkCredit',
                        label: translate('Payment Links Credit'),
                        options: {
                            filter: false,
                            searchable: false,
                        },
                    },
                    {
                        name: 'rappiCargoWebCard',
                        label: translate('Rappi Cargo Web Card'),
                        options: {
                            filter: false,
                            searchable: false,
                        },
                    },
                    {
                        name: 'rappiCargoWebCardDebit',
                        label: translate('Rappi Cargo Web Card Debit'),
                        options: {
                            filter: false,
                            searchable: false,
                        },
                    },
                    {
                        name: 'rappiCargoWebCardCredit',
                        label: translate('Rappi Cargo Web Card Credit'),
                        options: {
                            filter: false,
                            searchable: false,
                        },
                    },
                    {
                        name: 'rappiCargoWebCash',
                        label: translate('Rappi Cargo Web Cash'),
                        options: {
                            filter: false,
                            searchable: false,
                        },
                    },
                    {
                        name: 'rappiCargoAppCard',
                        label: translate('Rappi Cargo App Card'),
                        options: {
                            filter: false,
                            searchable: false,
                        },
                    },
                    {
                        name: 'rappiCargoAppCardDebit',
                        label: translate('Rappi Cargo App Card Debit'),
                        options: {
                            filter: false,
                            searchable: false,
                        },
                    },
                    {
                        name: 'rappiCargoAppCardCredit',
                        label: translate('Rappi Cargo App Card Credit'),
                        options: {
                            filter: false,
                            searchable: false,
                        },
                    },
                    {
                        name: 'rappiCargoAppCash',
                        label: translate('Rappi Cargo App Cash'),
                        options: {
                            filter: false,
                            searchable: false,
                        },
                    },
                    {
                        name: 'rappiCargoManualOrder',
                        label: translate('Rappi Cargo Manual Order'),
                        options: {
                            filter: false,
                            searchable: false,
                        },
                    },
                    {
                        name: 'rappiCargoPosCard',
                        label: translate('Rappi Cargo Pos Card'),
                        options: {
                            filter: false,
                            searchable: false,
                        },
                    },
                    {
                        name: 'rappiCargoPosCash',
                        label: translate('Rappi Cargo Pos Cash'),
                        options: {
                            filter: false,
                            searchable: false,
                        },
                    },
                    {
                        name: 'uberDaasWebCard',
                        label: translate('Uber Daas Web Card'),
                        options: {
                            filter: false,
                            searchable: false,
                        },
                    },
                    {
                        name: 'uberDaasWebCardDebit',
                        label: translate('Uber Daas Web Card Debit'),
                        options: {
                            filter: false,
                            searchable: false,
                        },
                    },
                    {
                        name: 'uberDaasWebCardCredit',
                        label: translate('Uber Daas Web Card Credit'),
                        options: {
                            filter: false,
                            searchable: false,
                        },
                    },
                    {
                        name: 'uberDaasWebCash',
                        label: translate('Uber Daas Web Cash'),
                        options: {
                            filter: false,
                            searchable: false,
                        },
                    },
                    {
                        name: 'uberDaasAppCard',
                        label: translate('Uber Daas App Card'),
                        options: {
                            filter: false,
                            searchable: false,
                        },
                    },
                    {
                        name: 'uberDaasAppCardDebit',
                        label: translate('Uber Daas App Card Debit'),
                        options: {
                            filter: false,
                            searchable: false,
                        },
                    },
                    {
                        name: 'uberDaasAppCardCredit',
                        label: translate('Uber Daas App Card Credit'),
                        options: {
                            filter: false,
                            searchable: false,
                        },
                    },
                    {
                        name: 'uberDaasAppCash',
                        label: translate('Uber Daas App Cash'),
                        options: {
                            filter: false,
                            searchable: false,
                        },
                    },
                    {
                        name: 'uberDaasManualOrder',
                        label: translate('Uber Daas Manual Order'),
                        options: {
                            filter: false,
                            searchable: false,
                        },
                    },
                    {
                        name: 'uberDaasPosCard',
                        label: translate('Uber Daas Pos Card'),
                        options: {
                            filter: false,
                            searchable: false,
                        },
                    },
                    {
                        name: 'uberDaasPosCash',
                        label: translate('Uber Daas Pos Cash'),
                        options: {
                            filter: false,
                            searchable: false,
                        },
                    },
                    {
                        name: 'total',
                        label: translate('Total Sold'),
                        options: {
                            filter: false,
                            searchable: false,
                        },
                    },
                    {
                        name: 'commission',
                        label: translate('Commission'),
                        options: {
                            filter: false,
                            searchable: false,
                        },
                    },
                    {
                        name: 'iva',
                        label: translate('IVA'),
                        options: {
                            filter: false,
                            searchable: false,
                        },
                    },
                    {
                        name: 'restaurantPromoCodeCostApp',
                        label: translate('Restaurant Promo Code Cost App'),
                        options: {
                            filter: false,
                            searchable: false,
                        },
                    },
                    {
                        name: 'restaurantPromoCodeBalance',
                        label: translate('Restaurant Promo Code Balance'),
                        options: {
                            filter: false,
                            searchable: false,
                        },
                    },
                    {
                        name: 'subscriptionPayPerUsePayment',
                        label: translate('Subscription Pay Per Use'),
                        options: {
                            filter: false,
                            searchable: false,
                        },
                    },
                    {
                        name: 'manualEarning',
                        label: translate('Manual Earning'),
                        options: {
                            filter: false,
                            searchable: false,
                        },
                    },
                    {
                        name: 'debtSubscriptionPayPerUsePayment',
                        label: translate('Debt Subscription Pay Per Use'),
                        options: {
                            filter: false,
                            searchable: false,
                        },
                    },
                    {
                        name: 'debtManualEarning',
                        label: translate('Debt Manual Earning'),
                        options: {
                            filter: false,
                            searchable: false,
                        },
                    },
                    {
                        name: 'subscriptionPayPerUsePaymentInvoice',
                        label: translate('Subscription Pay Per Use Invoice'),
                        options: {
                            filter: false,
                            searchable: false,
                        },
                    },
                    {
                        name: 'manualEarningInvoice',
                        label: translate('Manual Earning Invoice'),
                        options: {
                            filter: false,
                            searchable: false,
                        },
                    },
                    {
                        name: 'deposit',
                        label: translate('To Deposit'),
                        options: {
                            filter: false,
                            searchable: false,
                        },
                    },
                    {
                        name: 'orders',
                        label: translate('Orders'),
                        options: {
                            filter: false,
                            searchable: false,
                        },
                    },
                ]}
                options={{
                    responsive: 'standard',
                    tableBodyMaxHeight: '500px',
                    selectableRows: !viewUser ? 'multiple' : 'none',
                    filterType: 'checkbox',
                    rowsPerPage: 100,
                    customToolbarSelect: (selectedRows, displayData, restaurantBilling) => {
                        const handleClickExportTribalExportFormat = async () => {
                            const selectedRestaurantIds = getSelectedRowsData(selectedRows, displayData).map((d: any) => d[0]);
                            const response = await tribalExportFormatUyApi({
                                from: filter.from,
                                to: filter.to,
                                restaurantIds: selectedRestaurantIds,
                            });
                            if (!response.ok) {
                                alertKnownErrorOrSomethingWentWrong(response);
                                return;
                            }
                            downloadTextInFile(
                                response.data.tribalExportFormat,
                                `tribal-export-format-${moment(filter.from).format(MomentFormats.ISO_DATE)}--${moment(filter.to).format(MomentFormats.ISO_DATE)}.txt`,
                            );
                            alert([`exported: ${response.data.exported}`, `removed: ${response.data.removed}`, `nothingToTransfer: ${response.data.nothingToTransfer}`].join('\n'));
                        };

                        const getRestaurantData = async (restaurantId: RestaurantId, from: any, to: any) => {
                            const response = await getRestaurantReportBillingDataApi({ restaurantId, from: moment(from).toDate(), to: moment(to).toDate() });
                            if (!response.ok) {
                                alertKnownErrorOrSomethingWentWrong(response);
                                return;
                            }

                            const { addressOfEstablishment, businessName, rfc } = response.data;
                            const restaurantBilling = response.data;
                            createAndSaveBilling({
                                businessName,
                                addressOfEstablishment,
                                rfc,
                                restaurantBilling,
                                from: moment(from).toDate(),
                                to: moment(to).toDate(),
                                country: CountryCodes.UY,
                            });
                        };

                        const handleClickDownloadPdf = () => {
                            const selectedRestaurantIds = getSelectedRowsData(selectedRows, displayData).map((d: any) => d[0]);
                            if (!vm?.restaurantsBillingUy) return;

                            setLoading(true);
                            vm?.restaurantsBillingUy.forEach(async (billing: RestaurantBillingUyVm) => {
                                if (!selectedRestaurantIds.includes(billing.restaurantId)) return;

                                const restaurantId = billing.restaurantId;
                                try {
                                    await getRestaurantData(restaurantId, moment(filter.from).toDate(), moment(filter.to).toDate());
                                } catch (error: any) {
                                    alertKnownErrorOrSomethingWentWrong(error.message);
                                }
                            });
                            setLoading(false);
                        };

                        return (
                            <CustomToolbarSelect>
                                <ExportToolbarButton onClick={handleClickExportTribalExportFormat} tooltip={translate('Export As Tribal Export Format')} />
                                <DownloadPdfToolbarButton onClick={handleClickDownloadPdf} tooltip={translate('Download PDF')} />
                            </CustomToolbarSelect>
                        );
                    },
                    customToolbar: () => (
                        <>
                            <RefreshToolbarButton onClick={load} />
                        </>
                    ),
                    onRowClick: (
                        rowData: Array<string>,
                        rowMeta: {
                            dataIndex: number;
                            rowIndex: number;
                        },
                    ) => {
                        window.open(RoutePaths.ADMIN_RESTAURANT_BILLING_FOR_RESTAURANT_UY.replace(`:restaurantId(${RegExps.uuid})`, rowData[0]), '_blank');
                    },
                    customFooter: (count, page, rowsPerPage, changeRowsPerPage, changePage) => (
                        <TableFooter>
                            <TableRow>
                                <TableCell size='small'>
                                    {translate('Web Card Amount')}: {vm?.webCard}
                                </TableCell>
                                <TableCell size='small'>
                                    {translate('Web Card Amount Debit')}: {vm?.webCardDebit}
                                </TableCell>
                                <TableCell size='small'>
                                    {translate('Web Card Amount Credit')}: {vm?.webCardCredit}
                                </TableCell>
                                <TableCell size='small'>
                                    {translate('Web Cash Amount')}: {vm?.webCash}
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell size='small'>
                                    {translate('App Card Amount')}: {vm?.whiteLabelAppCard}
                                </TableCell>
                                <TableCell size='small'>
                                    {translate('App Card Amount Debit')}: {vm?.whiteLabelAppCardDebit}
                                </TableCell>
                                <TableCell size='small'>
                                    {translate('App Card Amount Credit')}: {vm?.whiteLabelAppCardCredit}
                                </TableCell>
                                <TableCell size='small'>
                                    {translate('App Cash Amount')}: {vm?.whiteLabelAppCash}
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell size='small'>
                                    {translate('POS Card Amount')}: {vm?.posCard}
                                </TableCell>
                                <TableCell size='small'>
                                    {translate('POS Cash Amount')}: {vm?.posCash}
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell size='small'>
                                    {translate('PD Marketplace Card')}: {vm?.marketplaceServiceFeeCard}
                                </TableCell>
                                <TableCell size='small'>
                                    {translate('PD Marketplace Cash')}: {vm?.marketplaceServiceFeeCash}
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell size='small'>
                                    {translate('Manual Orders Amount')}: {vm?.manualOrders}
                                </TableCell>
                                <TableCell size='small'>
                                    {translate('manualOrdersProtected')}: {vm?.manualOrdersProtected}
                                </TableCell>
                                <TableCell size='small'>
                                    {translate('directoProtectionAmount')}: {vm?.directoProtectionAmount}
                                </TableCell>
                                <TableCell size='small'>
                                    {translate('Invoiced')}: {vm?.invoicedRestaurantDeliveryCost}
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell size='small'>
                                    {translate('Total Sold')}: {vm?.total}
                                </TableCell>
                                <TableCell size='small'>
                                    {translate('Commission')}: {vm?.commission}
                                </TableCell>
                                <TableCell size='small'>
                                    {translate('IVA')}: {vm?.iva}
                                </TableCell>
                                <TableCell size='small'>
                                    {translate('Subscription Pay Per Use')}: {vm?.subscriptionPayPerUsePayment}
                                </TableCell>
                                <TableCell size='small'>
                                    {translate('Manual Earning')}: {vm?.manualEarning}
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell size='small'>
                                    {translate('Debt Subscription Pay Per Use')}: {vm?.debtSubscriptionPayPerUsePayment}
                                </TableCell>
                                <TableCell size='small'>
                                    {translate('Debt Manual Earning')}: {vm?.debtManualEarning}
                                </TableCell>
                                <TableCell size='small'>
                                    {translate('Subscription Pay Per Use Invoice')}: {vm?.subscriptionPayPerUsePaymentInvoice}
                                </TableCell>
                                <TableCell size='small'>
                                    {translate('Manual Earning Invoice')}: {vm?.manualEarningInvoice}
                                </TableCell>
                                <TableCell size='small'>
                                    {translate('To Deposit')}: {vm?.deposit}
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell size='small'>
                                    {translate('Completed')}: {vm?.completed} COMPLETED
                                </TableCell>
                            </TableRow>
                        </TableFooter>
                    ),
                }}
            />
        </div>
    );
}

const useStyles = makeStyles((theme) => ({
    container: {
        position: 'relative',
    },
    table: {
        whiteSpace: 'nowrap',
        '& tr': { cursor: 'pointer' },
        '& tr:hover': { backgroundColor: 'rgba(0, 0, 0, 0.07) !important' },
    },
    toolbar: {
        paddingRight: theme.spacing(3),
    },
}));

type Props = {
    filter: {
        from: Date;
        to: Date;
        countryCode?: CountryCode;
        brandId?: BrandId;
    };
    refresh: number;
};
