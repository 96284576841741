import { letseatmanagerApiMethod } from 'src/api/letseatmanagerApi';
import type { ApiSauceResponse } from 'src/api/types/ApiSauceResponse';
import type { RestaurantId } from 'src/types/Id';
import type { RestaurantZoneVm } from 'src/types/RestaurantZoneVm';

export async function findRestaurantZonesApi(request: FindRestaurantZonesApiRequest): ApiSauceResponse<Array<RestaurantZoneVm>> {
    return letseatmanagerApiMethod('pos/findRestaurantZonesApi', request);
}

export type FindRestaurantZonesApiRequest = {
    restaurantId: RestaurantId;
};
