import IconButton from '@material-ui/core/IconButton';
import { withStyles } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';
import RefreshIcon from '@material-ui/icons/Refresh';
import MUIDataTable from 'mui-datatables';
import * as React from 'react';
import { UpdatingContentProgress } from 'src/components/UpdatingContentProgress';
import { RoutePaths } from 'src/constants/RoutePath';
import { translate } from 'src/i18n/translate';
import type { CreditsPaymentVm } from 'src/scenes/letseatadmin/companyCreditsBilling/CompanyCreditsBillingDataForBenefitPlan';
import { formatDateTimeString } from 'src/utils/date/formatDateTimeString';
import { createFilteredOnDownload } from 'src/utils/mui-datatables/createFilteredOnDownload';
import { RegExps } from 'src/utils/RegExps';

export default withStyles((theme) => ({
    table: {
        whiteSpace: 'nowrap',
        '& tr': { cursor: 'pointer' },
        '& tr:hover': { backgroundColor: 'rgba(0, 0, 0, 0.07) !important' },
    },
    toolbar: {
        paddingRight: theme.spacing(3),
    },
}))(
    class CreditsPaymentsTable extends React.PureComponent<Props> {
        table: any; // TODO: fix correct type

        render() {
            const { classes } = this.props;
            return (
                <div style={{ position: 'relative' }}>
                    <UpdatingContentProgress loading={this.props.loading} />
                    <MUIDataTable
                        /* @ts-ignore */
                        ref={(ref) => (this.table = ref)}
                        className={classes.table}
                        title={this.props.title || translate('Credits Payments')}
                        data={this.props.creditsPayments.map((creditsPayment) => ({
                            orderId: creditsPayment.orderId,
                            customerId: creditsPayment.customerId,
                            createdAt: formatDateTimeString(creditsPayment.createdAt),
                            companyCredits: creditsPayment.companyCredits,
                            customerName: creditsPayment.customerName,
                            mobileNumber: creditsPayment.mobileNumber,
                            email: creditsPayment.email,
                            employeeIdAtCompany: creditsPayment.employeeIdAtCompany ?? '',
                            employeeNumberAtCompany: creditsPayment.employeeNumberAtCompany ?? '',
                        }))}
                        columns={[
                            {
                                name: 'orderId',
                                label: ' ',
                                options: {
                                    display: 'excluded',
                                    filter: false,
                                },
                            },
                            {
                                name: 'customerId',
                                label: ' ',
                                options: {
                                    display: 'excluded',
                                    filter: false,
                                },
                            },
                            {
                                name: 'createdAt',
                                label: translate('Paid At'),
                                options: {
                                    filter: false,
                                    searchable: false,
                                },
                            },
                            {
                                name: 'companyCredits',
                                label: translate('Used Company Credits'),
                                options: {
                                    filter: false,
                                    searchable: false,
                                },
                            },
                            {
                                name: 'customerName',
                                label: translate('Name'),
                                options: {
                                    filter: false,
                                    searchable: true,
                                },
                            },
                            {
                                name: 'mobileNumber',
                                label: translate('Mobile Number'),
                                options: {
                                    filter: false,
                                    searchable: true,
                                },
                            },
                            {
                                name: 'email',
                                label: translate('Email'),
                                options: {
                                    filter: false,
                                    searchable: true,
                                },
                            },
                            {
                                name: 'employeeIdAtCompany',
                                label: translate('Employee Id'),
                                options: {
                                    filter: false,
                                    searchable: true,
                                },
                            },
                            {
                                name: 'employeeNumberAtCompany',
                                label: translate('Employee Number'),
                                options: {
                                    filter: false,
                                    searchable: true,
                                },
                            },
                        ]}
                        options={{
                            responsive: 'standard',
                            tableBodyMaxHeight: '500px',
                            selectableRows: 'none',
                            // setRowProps: (row) => {
                            //     return {
                            //         style: { backgroundColor: getOrderStatusColor(row[33], row[35]) },
                            //     };
                            // },
                            onRowClick: (
                                rowData: Array<string>,
                                rowMeta: {
                                    dataIndex: number;
                                    rowIndex: number;
                                },
                            ) => {
                                const orderId = rowData[0];
                                window.open(RoutePaths.ADMIN_ORDER.replace(`:orderId(${RegExps.uuid})`, orderId), '_blank');
                            },
                            filterType: 'multiselect',
                            rowsPerPage: 100,
                            customToolbar: () => {
                                return (
                                    <>
                                        <Tooltip title='Refresh'>
                                            <IconButton onClick={this.props.onClickRefresh}>
                                                <RefreshIcon />
                                            </IconButton>
                                        </Tooltip>
                                    </>
                                );
                            },
                            onDownload: createFilteredOnDownload(this),
                            // filterList: [[], [], [], [], ['MEXICO']],
                        }}
                    />
                </div>
            );
        }
    },
) as React.ComponentType<any>;

type Props = {
    loading: boolean;
    title?: string;
    creditsPayments: Array<CreditsPaymentVm>;
    onClickRefresh: any;
    classes: any;
};
