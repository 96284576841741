import { LogEventTypes } from 'src/constants/LogEventType';
import { createUiInteractionLogEvent } from 'src/services/logEvent/createUiInteractionLogEvent';
import { ManagerUserId } from 'src/types/Id';

export function createUserSignedInToPideDirectoPosFailedLogEvent({ pinCodeEntered, managerUserId }: Params): void {
    createUiInteractionLogEvent({
        logEventType: LogEventTypes.USER_SIGNED_IN_TO_PIDE_DIRECTO_POS_FAILED,
        message: `Login failed ${managerUserId}`,
        details: `PinCode entered: ${pinCodeEntered}`,
        data: {
            managerUserId,
            pinCodeEntered,
        },
    });
}

type Params = {
    managerUserId: ManagerUserId | undefined;
    pinCodeEntered: string;
};
