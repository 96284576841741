import { makeStyles } from '@material-ui/core';
import { Loader } from '@pidedirecto/ui';
import * as React from 'react';
import { getRestaurantSelfRegistrationKpisApi } from 'src/api/letseatmanager/restaurantSelfRegistration/getRestaurantSelfRegistrationKpisApi';
import { translate } from 'src/i18n/translate';
import { Kpi } from 'src/scenes/letseatmanager/freeSubscriptionPlanEnd/Kpi';
import { SubscriptionPlanCard } from 'src/scenes/letseatmanager/freeSubscriptionPlanEnd/SubscriptionPlanCard';
import { useFormatAsRestaurantCurrencyNumber } from 'src/services/restaurant/useFormatAsRestaurantCurrencyNumber';
import { useLoadApi } from 'src/utils/react/useLoadApi';
import { useSelector } from 'src/utils/react/useSelector';

export function FreeSubscriptionPlanEndPage(): React.ReactElement {
    const classes = useStyles();
    const formatAsCurrencyNumber = useFormatAsRestaurantCurrencyNumber();

    const restaurantId = useSelector((state) => state.app.restaurantId);

    const [loading, restaurantSelfRegistrationKpis, refreshData] = useLoadApi(getRestaurantSelfRegistrationKpisApi, { restaurantId }, { initialValue: {} });

    if (loading) {
        return (
            <div className={classes.loadingContainer}>
                <Loader loading={true} size={40} />
            </div>
        );
    }

    return (
        <div className={classes.container}>
            <h1 className={classes.title}>{translate('Your free 7 days trial version has ended')}</h1>
            <h2 className={classes.subtitle}>{translate('The perfect plan to grow your business')}</h2>
            <div className={classes.infoContainer}>
                <div className={classes.kpisContainer}>
                    <h3 className={classes.text}>{translate('On this 7 days...')}</h3>
                    <div className={classes.kpis}>
                        <Kpi value={`${restaurantSelfRegistrationKpis.stats?.ordersCompleted ?? 0}`} title={translate('Completed orders')} />
                        <Kpi value={`${restaurantSelfRegistrationKpis.stats?.averageDeliveryTimeInMinutes ?? 0} mins`} title={translate('Minutes average per order')} />
                        <Kpi value={formatAsCurrencyNumber(restaurantSelfRegistrationKpis.stats?.earnings ?? 0)} title={translate('Earn')} />
                        <Kpi value={formatAsCurrencyNumber(restaurantSelfRegistrationKpis.stats?.savings ?? 0)} title={translate('Saved in commissions')} />
                    </div>
                </div>
                <div className={classes.subscriptionPlanContainer}>
                    {restaurantSelfRegistrationKpis.detailSubscriptionPlans?.map((detailSubscriptionPlan: any) => <SubscriptionPlanCard detailSubscriptionPlan={detailSubscriptionPlan} />)}
                </div>
            </div>
            <a className={classes.link}>{translate('Interested in e-commerce')}</a>
        </div>
    );
}

const useStyles = makeStyles((theme) => ({
    container: {
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        marginBottom: 50,
    },
    title: {
        fontFamily: theme.typography.regular,
        fontSize: 18,
        textAlign: 'center',
    },
    subtitle: {
        fontFamily: theme.typography.medium,
        fontSize: 30,
        textAlign: 'center',
        marginTop: 0,
    },
    infoContainer: {
        width: '100%',
        display: 'flex',
        flexDirection: 'row',
        marginTop: 20,
        marginBottom: 50,
        justifyContent: 'space-between',
    },
    kpisContainer: {
        display: 'flex',
        flexDirection: 'column',
        width: '50%',
    },
    text: {
        fontFamily: theme.typography.regular,
        fontSize: 15,
    },
    kpis: {
        display: 'grid',
        gridTemplateColumns: 'repeat(2, 1fr)',
        rowGap: 20,
        marginTop: 20,
    },
    subscriptionPlanContainer: {
        width: '45%',
    },
    link: {
        fontFamily: theme.typography.regular,
        color: '#a5a5a5',
        margin: '0 auto',
        marginBottom: 20,
    },
    loadingContainer: {
        width: '100',
        height: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
}));
