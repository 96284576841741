import { makeStyles } from '@material-ui/core/styles';
import { useState } from 'react';
import * as React from 'react';
import { AppFullVersionInfo } from 'src/components/app/AppFullVersionInfo';
import { APP_VERSION } from 'src/config/appVersion';
import { translate } from 'src/i18n/translate';
import { appReducer } from 'src/reducers/appReducer';
import { useNotification } from 'src/services/notification/useNotification';
import { isProductionEnvironment } from 'src/utils/environment/isProductionEnvironment';
import { useAction } from 'src/utils/react/useAction';
import { useSelector } from 'src/utils/react/useSelector';

export function AppVersionInfo(): React.ReactElement {
    const classes = useStyles();

    const [clicks, setClicks] = useState(0);

    const developerMode = useSelector((state) => state.app.developerMode);

    const setDeveloperMode = useAction(appReducer.actions.setDeveloperMode);

    const notification = useNotification();

    function handleClickVersion() {
        if (developerMode) {
            return;
        }
        const newClicksCount = clicks + 1;

        if (newClicksCount >= 10) {
            setDeveloperMode(true);
            notification({ message: translate('Developer mode enabled!') });
            setClicks(0);
            return;
        }

        if (newClicksCount >= 5) {
            notification({
                message: translate(`You are ${10 - newClicksCount} away from enabling developer mode`),
            });
        }

        setClicks(newClicksCount);
    }

    const showAppFullVersionInfo = () => {
        if (isProductionEnvironment()) return developerMode ? true : false;
        // if (isStagingEnvironment()) return developerMode ? true : false;
        return true; // if any dev environment
    };

    if (showAppFullVersionInfo()) return <AppFullVersionInfo onClickVersion={handleClickVersion} />;

    return (
        <div className={classes.version} onClick={handleClickVersion}>
            <div>{APP_VERSION}</div>
        </div>
    );
}

const useStyles = makeStyles((theme) => ({
    version: {
        fontSize: '8px',
        position: 'fixed',
        bottom: 0,
        right: 8,
        textAlign: 'right',
        color: 'rgba(0, 0, 0, 0.1)',
    },
    text: {
        textDecoration: 'underline',
        marginLeft: 15,
        fontFamily: theme.typography.medium,
        color: 'black',
    },
}));
