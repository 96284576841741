import * as React from 'react';
import { Dialog } from 'src/components/Dialog';
import { FromToPaymentDateFilter } from 'src/components/input/FromToPaymentDateFilter';

export function FiltersSubscriptionDialog({ open, onClose, filter, onChangeFilter }: Props): React.ReactElement {
    return (
        <Dialog open={open} onClose={onClose}>
            <FromToPaymentDateFilter filter={filter} onChangeFilter={onChangeFilter} />
        </Dialog>
    );
}

type Props = {
    open: boolean;
    filter: FilterState;
    onChangeFilter: any;
    onClose: any;
};

export type FilterState = {
    from: Date;
    to: Date;
    paymentDate: Date;
};
