import { makeStyles } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import * as React from 'react';
import { useState } from 'react';
import { Accordion } from 'src/components/Accordion';
import { Button } from 'src/components/Button';
import { translate } from 'src/i18n/translate';
import { ExpandLess } from 'src/icons/ExpandLess';
import { ExpandMore } from 'src/icons/ExpandMore';

export function PosUserLockScreenMessages({ section }: Props): React.ReactElement {
    const classes = useStyles();

    const theme = useTheme();
    const mdScreen = useMediaQuery(theme.breakpoints.up('md'));

    const [showMessage, setShowMessage] = useState(mdScreen);

    return (
        <div>
            <div className={classes.sectionName}>
                {section.name}
                {!showMessage && (
                    <Button onClick={() => setShowMessage(!showMessage)} icon>
                        <ExpandMore title={translate('Expand more')} />
                    </Button>
                )}
                {showMessage && (
                    <Button onClick={() => setShowMessage(!showMessage)} icon>
                        <ExpandLess title={translate('Expand less')} />
                    </Button>
                )}
            </div>
            <Accordion open={showMessage}>
                {section.messages?.map((message: string) => (
                    <p className={classes.message} id={message}>
                        {message}
                    </p>
                ))}
            </Accordion>
        </div>
    );
}

const useStyles = makeStyles((theme) => ({
    sectionName: {
        color: 'white',
        fontSize: 24,
        fontFamily: theme.typography.medium,
        margin: 0,
        marginBottom: 16,
    },
    message: {
        width: 300,
        padding: '16px 20px',
        fontFamily: theme.typography.regular,
        fontSize: 12,
        color: 'white',
        backgroundColor: `${theme.palette.primary.main}15`,
        border: `1px solid ${theme.palette.primary.main}`,
        borderRadius: 12,
        [theme.breakpoints.up('md')]: {
            fontSize: 16,
        },
    },
    expandIcon: {
        marginLeft: 10,
        paddingTop: 5,
    },
}));

type Props = {
    section: {
        name: string;
        messages: Array<string>;
    };
};
