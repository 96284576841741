import { useEffect, useState } from 'react';
import * as React from 'react';
import { findMenusApi } from 'src/api/letseatmanager/menu/findMenusApi';
import { unzip } from 'src/api/utils/unzip';
import { FormSelect } from 'src/components/form/FormSelect';
import type { Variant } from 'src/components/form/FormTypes';
import { useMenus } from 'src/services/menu/useMenus';
import type { RestaurantId } from 'src/types/Id';
import { alertKnownErrorOrSomethingWentWrong } from 'src/utils/alert/alertKnownErrorOrSomethingWentWrong';
import { useSelector } from 'src/utils/react/useSelector';

export function FormMenuSelect({ name, label, placeholder, helperText, defaultValue, disabled, required, variant, restaurantId }: Props): React.ReactElement {
    const [loading, setLoading] = useState(false);
    const [menus, setMenus] = useState([]);

    const { menus: currentMenus, deprecatedMenus } = useMenus();
    const currentRestaurantId = useSelector((state) => state.app.restaurantId);

    const isCurrentRestaurant = currentRestaurantId === restaurantId;
    const menuOptions = isCurrentRestaurant || !restaurantId ? [...currentMenus, ...deprecatedMenus] : menus;

    useEffect(() => {
        if (!isCurrentRestaurant && restaurantId) getMenus();
    }, [isCurrentRestaurant, restaurantId]);

    const getMenus = async () => {
        setLoading(true);
        const response = await findMenusApi({ restaurantId: restaurantId || currentRestaurantId });
        setLoading(false);
        if (!response.ok) {
            alertKnownErrorOrSomethingWentWrong(response);
            return;
        }
        setMenus(unzip(response.data));
    };

    const options = required
        ? menuOptions?.map((menu) => ({ label: menu.name, value: menu.menuId }))
        : [{ label: '', value: null }, ...menuOptions?.map((menu) => ({ label: menu.name, value: menu.menuId }))];

    return (
        <FormSelect
            name={name}
            label={label}
            placeholder={placeholder}
            helperText={helperText}
            defaultValue={defaultValue}
            options={options}
            disabled={disabled || loading}
            required={required}
            variant={variant}
        />
    );
}

type Props = {
    name: string;
    label?: string;
    placeholder?: string;
    helperText?: string;
    defaultValue?: string | null | undefined;
    disabled?: boolean;
    required?:
        | boolean
        | {
              value: number;
              message: string;
          };
    variant?: Variant;
    restaurantId?: RestaurantId;
};
