import * as React from 'react';
import { UpdatingContentProgress } from 'src/components/UpdatingContentProgress';
import OfficeDeliveryItemsMiuDataTable from 'src/scenes/letseatadmin/officeDelivery/OfficeDeliveryItemsMiuDataTable';
import type { OfficeDeliveryItemVm } from 'src/scenes/letseatadmin/OfficeDeliveryPage';

export default class OfficeDeliveryItemsTable extends React.PureComponent<Props, State> {
    render(): React.ReactElement | null {
        if (!this.props.officeDeliveryItems) {
            return null;
        }
        return (
            <div style={{ position: 'relative' }}>
                <UpdatingContentProgress loading={this.props.loading} />
                <OfficeDeliveryItemsMiuDataTable
                    title={this.props.title}
                    officeDeliveryItems={this.props.officeDeliveryItems}
                    onClickRefresh={this.props.onClickRefresh}
                    disable={this.props.disable}
                />
            </div>
        );
    }
}

type Props = {
    loading: boolean;
    disable?: boolean;
    title?: string;
    officeDeliveryItems: Array<OfficeDeliveryItemVm>;
    onClickRefresh: any;
};

type State = Record<any, any>;
