import { makeStyles } from '@material-ui/core/styles';
import { useState } from 'react';
import * as React from 'react';
import { Button } from 'src/components/Button';
import { Dialog } from 'src/components/Dialog';
import { DialogActions } from 'src/components/DialogActions';
import { translate } from 'src/i18n/translate';
import { CircularCheckedIcon } from 'src/icons/CircularCheckedIcon';
import { CircularUnCheckedIcon } from 'src/icons/CircularUnCheckedIcon';
import { classNames } from 'src/utils/react/classNames';

export function ConfirmUpdateInventoryDialog({ open, onSuccess, onClose, loading }: Props): React.ReactElement {
    const classes = useStyles();

    const [updateInventory, setUpdateInventory] = useState(false);

    const onSubmit = () => {
        onSuccess?.(updateInventory);
    };

    return (
        <Dialog open={open} onClose={onClose} title={translate('Update Inventory')}>
            <div className={classes.bodyText}>{translate('What do you want to do with this inventory?')}</div>
            <section className={classes.checkboxContainer}>
                <div className={classNames(classes.item, { [classes.itemActive]: !updateInventory })} onClick={() => setUpdateInventory(false)}>
                    {updateInventory && <CircularUnCheckedIcon width={'18'} height={'18'} />}
                    {!updateInventory && <CircularCheckedIcon width={'18'} height={'18'} />}
                    <span>{translate('Save count log')}</span>
                </div>
                <div className={classNames(classes.item, { [classes.itemActive]: updateInventory })} onClick={() => setUpdateInventory(true)}>
                    {updateInventory && <CircularCheckedIcon width={'18'} height={'18'} />}
                    {!updateInventory && <CircularUnCheckedIcon width={'18'} height={'18'} />}
                    <span>{translate('Update and overwrite inventory')}</span>
                </div>
            </section>
            <DialogActions className={classes.buttonsContainer}>
                <Button secondary onClick={onClose}>
                    {translate('Cancel')}
                </Button>
                <Button onClick={onSubmit} disabled={loading}>
                    {loading ? translate('Loading...') : translate('Confirm')}
                </Button>
            </DialogActions>
        </Dialog>
    );
}

const useStyles = makeStyles((theme) => ({
    bodyText: {
        color: '#2E3748',
        fontSize: 12,
        fontFamily: theme.typography.medium,
        margin: '8px 0',
    },
    dialog: {
        [theme.breakpoints.up('sm')]: {
            padding: 34,
        },
    },
    buttonsContainer: {
        marginTop: 20,
        marginBottom: 20,
        flexDirection: 'column',
        justifyContent: 'center',
        [theme.breakpoints.up('md')]: {
            flexDirection: 'row',
        },
    },
    item: {
        padding: '16px 10px',
        display: 'flex',
        gap: 10,
        borderBottom: '0.5px solid #D9D9D9',
        alignItems: 'center',
    },
    itemActive: {
        backgroundColor: '#EBFFF9',
    },
    checkboxContainer: {
        color: '#2E3748',
        overflow: 'hidden',
        fontFamily: theme.typography.regular,
        fontSize: 16,
        fontWeight: 400,
        borderRadius: 8,
        border: '1px solid #D9D9D9',
    },
}));

type Props = {
    open: boolean;
    onSuccess?: any;
    onClose: any;
    loading?: boolean;
};
