import { letseatmanagerApiMethod } from 'src/api/letseatmanagerApi';
import type { ApiSauceResponse } from 'src/api/types/ApiSauceResponse';
import type { RestaurantId } from 'src/types/Id';
import type { RestaurantCurrency } from 'src/types/RestaurantCurrency';

export async function updateCurrencyApi(request: UpdateCurrencyApiRequest): ApiSauceResponse<void> {
    return letseatmanagerApiMethod('restaurant/updateCurrencyApi', request);
}

export type UpdateCurrencyApiRequest = {
    restaurantId: RestaurantId;
    restaurantCurrency: RestaurantCurrency;
};
