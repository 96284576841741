export function getErrorDescriptionFromResponse(response: any): string {
    let description = response.problem;
    if (response.status || (response.data && response.data.message)) {
        description += '(';
    }
    if (response.status) {
        description += `${response.status}`;
    }
    if (response.data && response.data.message) {
        description += ` ${response.data.message}`;
    }
    if (response.status || (response.data && response.data.message)) {
        description += ')';
    }
    return description ?? '';
}
