import { letseatmanagerApiMethod } from 'src/api/letseatmanagerApi';
import type { ApiSauceResponse } from 'src/api/types/ApiSauceResponse';
import type { GeoJsonPoint } from 'src/types/GeoJsonPoint';
import type { AddressId, CustomerId, EmailAddress, GooglePlaceId, RestaurantCustomerId, RestaurantId } from 'src/types/Id';

export async function findRestaurantCustomersAddressOptionsApi(request: FetchCustomersByPhoneNumberApiRequest): ApiSauceResponse<Array<RestaurantCustomerVm>> {
    return letseatmanagerApiMethod('restaurantCustomer/findRestaurantCustomersAddressOptionsApi', request);
}

export type FetchCustomersByPhoneNumberApiRequest = {
    restaurantId: RestaurantId;
    mobileNumber: string;
};

export type RestaurantCustomerVm = {
    restaurantCustomerId: RestaurantCustomerId;
    customerId?: CustomerId;
    restaurantId: RestaurantId;
    mobileNumber: string;
    name: string;
    firstName?: string;
    lastName?: string;
    email?: EmailAddress;
    note?: string;
    orders: number;
    address: RestaurantCustomerAddress;
    addresses?: Array<RestaurantCustomerAddress>;
};

export type RestaurantCustomerAddress = {
    addressId?: AddressId;
    location: GeoJsonPoint;
    street: string;
    other?: string;
    number?: string;
    instructions?: string;
    googlePlaceId?: GooglePlaceId;
    formattedAddress?: string;
};
