import { useEffect, useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { getKitchenOrdersApi } from 'src/api/letseatmanager/kitchen/getKitchenOrdersApi';
import { app2 } from 'src/app2';
import { Environments } from 'src/constants/Environment';
import { RoutePaths } from 'src/constants/RoutePath';
import { SECONDS } from 'src/constants/TimeUnit';
import { envENVIRONMENT } from 'src/env/envENVIRONMENT';
import { OrderId } from 'src/types/Id';
import { OrderVm } from 'src/types/OrderVm';
import { useAction } from 'src/utils/react/useAction';
import { useSelector } from 'src/utils/react/useSelector';
import { wait } from 'src/utils/wait';

export function useFetchKitchenOrdersUpdates(): UseFetchUpdateKitchenOrdersResult {
    const secondsToFetchUpdateKitchenOrders = (envENVIRONMENT() === Environments.LOCALHOST ? 20 : 15) * SECONDS;

    const location = useLocation();

    const isKitchenLocationRef = useRef(false);
    const currentKitchenOrderIdsRef = useRef<Array<OrderId>>();
    const restaurantIdRef = useRef();

    const [isFetching, setIsFetching] = useState(false);
    const [isFetchingContinuously, setIsFetchingContinuously] = useState(false);

    const restaurantId = useSelector((state) => state.app.restaurant?.restaurantId);
    const restaurant = useSelector((state) => state.app.restaurant);
    const preparingKitchenOrders = useSelector((state) => state.app2.preparingKitchenOrders);
    const preparedKitchenOrders = useSelector((state) => state.app2.preparedKitchenOrders);

    const setPreparingKitchenOrders = useAction(app2.actions.setPreparingKitchenOrders);
    const setPreparedKitchenOrders = useAction(app2.actions.setPreparedKitchenOrders);
    const setKitchenOrdersSummary = useAction(app2.actions.setKitchenOrdersSummary);
    const setHasNewKitchenOrders = useAction(app2.actions.setHasNewKitchenOrders);

    useEffect(() => {
        if (preparedKitchenOrders?.length || preparingKitchenOrders?.length) {
            currentKitchenOrderIdsRef.current = [
                ...(preparedKitchenOrders?.map((kitchenOrder: OrderVm) => kitchenOrder.orderId) ?? []),
                ...(preparingKitchenOrders?.map((kitchenOrder: OrderVm) => kitchenOrder.orderId) ?? []),
            ];
        }
    }, [preparedKitchenOrders, preparingKitchenOrders]);

    useEffect(() => {
        isKitchenLocationRef.current = location.pathname === RoutePaths.KITCHEN_DISPLAY_SCREEN;
    }, [location]);

    useEffect(() => {
        if (restaurantId) restaurantIdRef.current = restaurantId;
    }, [restaurantId]);

    const fetchKitchenOrdersUpdatesContinuously = async () => {
        if (!restaurant?.kitchenDisplayScreenEnabled) return;
        if (isFetchingContinuously) return waitToFetchAgain();

        if (!isKitchenLocationRef.current) return waitToFetchAgain();

        setIsFetchingContinuously(true);
        const response = await getKitchenOrdersApi({ restaurantId: restaurantIdRef?.current ?? restaurantId });
        if (!response.ok) {
            setIsFetchingContinuously(false);
            waitToFetchAgain();
            return;
        }

        const newKitchenOrdersId = [
            ...(response?.data?.currentPreparingKitchenOrders?.map((kitchenOrder: OrderVm) => kitchenOrder.orderId) ?? []),
            ...(response?.data?.currentPreparedKitchenOrders?.map((kitchenOrder: OrderVm) => kitchenOrder.orderId) ?? []),
        ];

        const hasNewKitchenOrder = newKitchenOrdersId.some((newKitchenOrderId) => !currentKitchenOrderIdsRef?.current?.includes(newKitchenOrderId));

        setHasNewKitchenOrders(hasNewKitchenOrder);
        console.log('response', response);
        // console.log('hasNewKitchenOrder', response?.data?.currentPreparingKitchenOrders);
        setPreparingKitchenOrders(response?.data?.currentPreparingKitchenOrders);
        setPreparedKitchenOrders(response?.data?.currentPreparedKitchenOrders);
        setKitchenOrdersSummary(response?.data?.kitchenOrdersSummary);
        setIsFetchingContinuously(false);

        waitToFetchAgain();
    };

    const fetchKitchenOrdersUpdates = async () => {
        if (!restaurant?.kitchenDisplayScreenEnabled) return;
        if (isFetching) return;

        setIsFetching(true);
        const response = await getKitchenOrdersApi({ restaurantId: restaurantIdRef?.current ?? restaurantId });
        setIsFetching(false);
        if (!response.ok) {
            return;
        }

        setPreparingKitchenOrders(response.data?.currentPreparingKitchenOrders);
        setPreparedKitchenOrders(response.data?.currentPreparedKitchenOrders);
        setKitchenOrdersSummary(response.data?.kitchenOrdersSummary);
    };

    const waitToFetchAgain = async () => {
        await wait(secondsToFetchUpdateKitchenOrders);

        fetchKitchenOrdersUpdatesContinuously();
    };

    return { fetchKitchenOrdersUpdates, fetchKitchenOrdersUpdatesContinuously };
}

type UseFetchUpdateKitchenOrdersResult = {
    fetchKitchenOrdersUpdates: any;
    fetchKitchenOrdersUpdatesContinuously: any;
};
