import { letseatmanagerApiMethod } from 'src/api/letseatmanagerApi';
import type { ApiSauceResponse } from 'src/api/types/ApiSauceResponse';
import type { UserVm } from 'src/types/UserVm';

export async function getUserApi(request: Request): ApiSauceResponse<UserVm> {
    return letseatmanagerApiMethod('authentication/getUserApi', request);
}

type Request = {
    languageTag: string;
    screenWidth: number;
    screenHeight: number;
    webSoundPermissionAllowed?: boolean;
};
