export const PrintTypes = {
    COMMAND: 'COMMAND',
    TICKET: 'TICKET',
    POS_BUSINESS_DAY_REPORT: 'POS_BUSINESS_DAY_REPORT',
    MANAGER_USER_TIP_SALES_REPORT: 'MANAGER_USER_TIP_SALES_REPORT',
    SHIFT_CASH_REGISTER_REPORT: 'SHIFT_CASH_REGISTER_REPORT',
    PARTIAL_SHIFT_CASH_REGISTER_REPORT: 'PARTIAL_SHIFT_CASH_REGISTER_REPORT',
    PRINTER_INFO: 'PRINTER_INFO',
    CASH_REGISTER_TRANSACTION_TICKET: 'CASH_REGISTER_TRANSACTION_TICKET',
} as const;

export type PrintType = (typeof PrintTypes)[keyof typeof PrintTypes];
