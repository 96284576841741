import { makeStyles } from '@material-ui/core';
import { Loader, Select } from '@pidedirecto/ui';
import moment from 'moment';
import * as React from 'react';
import { useState } from 'react';
import { getDeliveryRestaurantStatisticsApi } from 'src/api/letseatmanager/restaurantDashboard/getDeliveryRestaurantStatisticsApi';
import { UpdatingContentProgress } from 'src/components/UpdatingContentProgress';
import { DeliveryTimeCategories } from 'src/constants/DeliveryTimeCategory';
import { translate } from 'src/i18n/translate';
import { DailyDeliveriesByCategoryReport } from 'src/scenes/letseatmanager/restaurantDashboard/deliveryReport/DailyDeliveriesByCategoryReport';
import { DeliveryByCategoriesReport } from 'src/scenes/letseatmanager/restaurantDashboard/deliveryReport/DeliveryByCategoriesReport';
import { DeliveryByProductReport } from 'src/scenes/letseatmanager/restaurantDashboard/deliveryReport/DeliveryByProductReport';
import { DeliveryByTimeCategoryKpis } from 'src/scenes/letseatmanager/restaurantDashboard/deliveryReport/DeliveryByTimeCategoryKpis';
import { DeliveryOrdersTable } from 'src/scenes/letseatmanager/restaurantDashboard/deliveryReport/DeliveryOrdersTable';
import { HeatmapGraph } from 'src/scenes/letseatmanager/restaurantDashboard/deliveryReport/HeatmapGraph';
import { FilterReports } from 'src/scenes/letseatmanager/restaurantDashboard/FilterReports';
import { ReportSectionEmptyState } from 'src/scenes/letseatmanager/restaurantDashboard/ReportSectionEmptyState';
import { useFormatDateToRestaurantTimeZone } from 'src/utils/react/useFormatDateToRestaurantTimeZone';
import { useLoadApi } from 'src/utils/react/useLoadApi';
import { useSelector } from 'src/utils/react/useSelector';

export function DeliveryReport(): React.ReactElement {
    const classes = useStyles();

    const [currentDeliveryTimeCategory, setCurrentDeliveryTimeCategory] = useState<string>('ALL');

    const restaurantId = useSelector((state) => state.app.restaurantId);
    const restaurantIds = useSelector((state) => state.app.restaurantIds);
    const brandOpened = useSelector((state) => state.app.brandOpened);
    const period = useSelector((state) => state.app2.filterReportsState.period);
    const salesType = useSelector((state) => state.app.salesTypeFilter);

    const formatDateToRestaurantTimeZone = useFormatDateToRestaurantTimeZone();

    const [loading, reports] = useLoadApi(
        getDeliveryRestaurantStatisticsApi,
        {
            report: 'perRestaurant',
            restaurantIds: brandOpened ? restaurantIds : [restaurantId],
            fromDate: moment(formatDateToRestaurantTimeZone(period?.from)).toISOString(),
            toDate: moment(formatDateToRestaurantTimeZone(period.to)).toISOString(),
            salesType: salesType,
        },
        {
            dependencies: [period, brandOpened, restaurantId, salesType],
        },
    );

    if (!reports?.[0]?.totalOrdersByDeliveryTimeCategory)
        return (
            <div className={classes.container}>
                <FilterReports />
                <ReportSectionEmptyState />
            </div>
        );

    const deliveryTimeCategories = reports?.[0].totalOrdersByDeliveryTimeCategory
        ? reports?.[0].totalOrdersByDeliveryTimeCategory?.map((report) => ({
              label: translate(`DeliveryTimeCategories.${report.deliveryTimeCategory}`),
              value: report.deliveryTimeCategory,
          }))
        : [
              {
                  label: translate(DeliveryTimeCategories.ALL),
                  value: DeliveryTimeCategories.ALL,
              },
          ];
    const kpisByDeliveryTimeCategory = reports?.[0].kpisByDeliveryTimeCategory?.find((report) => report.deliveryTimeCategory === currentDeliveryTimeCategory);
    const totalOrdersInDeliveryTimeCategoryGroupedByApp =
        reports?.[0].totalOrdersInDeliveryTimeCategoryGroupedByApp?.filter((report) => report.deliveryTimeCategory === currentDeliveryTimeCategory) ?? [];
    const totalOrdersInDeliveryTimeCategoryGroupedByDay =
        reports?.[0].totalOrdersInDeliveryTimeCategoryGroupedByDay?.filter((report) => report.deliveryTimeCategory !== DeliveryTimeCategories.ALL) ?? [];
    const totalOrdersByDeliveryTimeCategory = reports?.[0]?.totalOrdersByDeliveryTimeCategory?.filter((report) => report.deliveryTimeCategory !== DeliveryTimeCategories.ALL) ?? [];
    const deliveryOrders = reports?.[0].deliveryOrders ?? [];

    const onSelectDeliveryTimeCategory = (value: string) => setCurrentDeliveryTimeCategory(value);

    if (loading)
        return (
            <div className={classes.loadingContainer}>
                <Loader loading={loading} text={translate('Loading...')} />
            </div>
        );

    return (
        <div className={classes.container}>
            <FilterReports />
            {loading && <UpdatingContentProgress />}
            {currentDeliveryTimeCategory && (
                <div className={classes.channelsContainer}>
                    <Select
                        name={'deliveryTimeCategories'}
                        onChange={onSelectDeliveryTimeCategory}
                        value={currentDeliveryTimeCategory}
                        options={deliveryTimeCategories}
                        placeholder={translate('Select a value')}
                    />
                </div>
            )}
            <div className={classes.subContainer}>
                {kpisByDeliveryTimeCategory && <DeliveryByTimeCategoryKpis kpisByDeliveryTimeCategory={kpisByDeliveryTimeCategory} />}
                <div className={classes.pieChartSection}>
                    {totalOrdersInDeliveryTimeCategoryGroupedByApp && <DeliveryByProductReport totalOrdersInDeliveryTimeCategoryGroupedByApp={totalOrdersInDeliveryTimeCategoryGroupedByApp} />}
                    {totalOrdersByDeliveryTimeCategory && <DeliveryByCategoriesReport totalOrdersByDeliveryTimeCategory={totalOrdersByDeliveryTimeCategory} />}
                    {totalOrdersInDeliveryTimeCategoryGroupedByDay && <DailyDeliveriesByCategoryReport totalOrdersInDeliveryTimeCategoryGroupedByDay={totalOrdersInDeliveryTimeCategoryGroupedByDay} />}
                </div>

                {deliveryOrders && <DeliveryOrdersTable deliveryOrders={deliveryOrders} />}
                <HeatmapGraph />
            </div>
        </div>
    );
}

const useStyles = makeStyles((theme) => ({
    container: {
        width: '100%',
        marginBottom: 25,
    },
    subContainer: {
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        gap: 24,
    },
    channelsContainer: {
        width: '100%',
        display: 'flex',
        flexDirection: 'row-reverse',
    },
    pieChartSection: {
        width: '100%',
        display: 'flex',
        flexWrap: 'wrap',
        gap: 12,
        justifyItems: 'center',
        justifyContent: 'center',
        alignItems: 'center',
    },
    loadingContainer: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100%',
    },
}));
