import { makeStyles } from '@material-ui/core';
import { Input } from '@pidedirecto/ui';
import * as React from 'react';
import { translate } from 'src/i18n/translate';
import { usePosCustomerActions, usePosCustomerStore } from 'src/modules/pos/customer/posCustomerStore';
import { isEatHereOrder } from 'src/utils/order/isEatHereOrder';
import { useSelector } from 'src/utils/react/useSelector';

export function PosNumberOfCustomersInput(): React.ReactElement | null {
    const classes = useStyles();

    const numberOfCustomers = usePosCustomerStore((state) => state.numberOfCustomers.value);

    const setError = usePosCustomerActions((actions) => actions.setError);
    const setNumberOfCustomers = usePosCustomerActions((actions) => actions.setNumberOfCustomers);

    const table = useSelector((state) => state.pos.table);
    const posNumberOfCustomers = useSelector((state) => state.pos.numberOfCustomers);
    const orderType = useSelector((state) => state.pos.orderType);
    const posCustomersRequiredEnabled = useSelector((state) => state.pos.context?.posCustomersRequiredEnabled);

    const numberOfCustomerRequired = isEatHereOrder(orderType) && posCustomersRequiredEnabled;

    const onChangeNumberOfCustomers = (value: string) => {
        setNumberOfCustomers(parseInt(value));
        setError('numberOfCustomers', numberOfCustomerRequired && !value ? translate('This field is required') : undefined);
    };

    if (!isEatHereOrder(orderType)) return null;

    return (
        <Input
            classes={{ container: classes.input, input: classes.numericInput }}
            label={translate('Number of Customers')}
            type='number'
            name={'numberOfCustomers'}
            required={numberOfCustomerRequired}
            value={numberOfCustomers?.toString()}
            defaultValue={table?.numberOfCustomers || posNumberOfCustomers}
            onChange={onChangeNumberOfCustomers}
        />
    );
}

const useStyles = makeStyles((theme) => ({
    input: {
        width: '100%',
    },
    numericInput: {
        '&::-webkit-inner-spin-button, &::-webkit-outer-spin-button': {
            '-webkit-appearance': 'none',
            margin: 0,
        },
    },
}));
