import { makeStyles } from '@material-ui/core';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useContext } from 'react';
import * as React from 'react';
import { CompactPicker, TwitterPicker } from 'react-color';
import { Controller, useFormContext } from 'react-hook-form';
import { FormContext } from 'src/components/form/Form';
import { Tooltip } from 'src/components/Tooltip';
import { translate } from 'src/i18n/translate';

export function FormRestaurantColors({ name, defaultValue = ['#1a9aa0', '#68CCCA'], disabled }: Props): React.ReactElement {
    const classes = useStyles();
    const theme = useTheme();
    const matchMediaQuery = useMediaQuery(theme.breakpoints.down('sm'));

    const {
        errors,
        control,
        formState: { isSubmitting },
    } = useFormContext();
    const formContext = useContext(FormContext);

    return (
        <>
            <FormControlLabel
                label={undefined}
                control={
                    <Controller
                        control={control}
                        name={name}
                        render={({ onChange, value, name }) => (
                            <div className={classes.container}>
                                <div className={classes.colorsContainer}>
                                    <div className={classes.labelContainer}>
                                        <p className={classes.label}>{translate('Primary color')}</p>
                                        <Tooltip text={translate('Main color for ecommerce site theme')} />
                                    </div>
                                    {!matchMediaQuery && (
                                        <CompactPicker
                                            /* @ts-ignore */
                                            name={name}
                                            color={value[0]}
                                            onChange={(e) => onChange([e.hex, value[1]])}
                                        />
                                    )}
                                    {matchMediaQuery && (
                                        <TwitterPicker
                                            /* @ts-ignore */
                                            name={name}
                                            color={value[0]}
                                            onChange={(e) => onChange([e.hex, value[1]])}
                                            width='100%'
                                        />
                                    )}
                                </div>
                                <div className={classes.colorsContainer}>
                                    <div className={classes.labelContainer}>
                                        <p className={classes.label}>{translate('Secondary color')}</p>
                                        <Tooltip text={translate('Secondary color for ecommerce site theme')} />
                                    </div>
                                    {!matchMediaQuery && (
                                        <CompactPicker
                                            /* @ts-ignore */
                                            name={name}
                                            color={value[1]}
                                            onChange={(e) => onChange([value[0], e.hex])}
                                        />
                                    )}
                                    {matchMediaQuery && (
                                        <TwitterPicker
                                            /* @ts-ignore */
                                            name={name}
                                            color={value[1]}
                                            onChange={(e) => onChange([value[0], e.hex])}
                                            width='100%'
                                        />
                                    )}
                                </div>
                            </div>
                        )}
                        defaultValue={defaultValue}
                    />
                }
                disabled={isSubmitting || disabled || formContext.disabled}
            />
        </>
    );
}

const useStyles = makeStyles((theme) => ({
    container: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-around',
        width: '80vw',
        marginBottom: 20,
        [theme.breakpoints.down('sm')]: {
            width: '85vw',
        },
    },
    colorsContainer: {
        display: 'flex',
        flexDirection: 'column',
        [theme.breakpoints.down('sm')]: {
            marginRight: 20,
        },
    },
    labelContainer: {
        display: 'flex',
    },
    label: {
        fontFamily: theme.typography.medium,
    },
}));

export type Props = {
    name: string;
    defaultValue?: [string, string] | null | undefined;
    disabled?: boolean;
};
