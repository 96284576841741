import { Collapse, List, makeStyles, useTheme } from '@material-ui/core';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import { useState } from 'react';
import * as React from 'react';
import { AppMenuLink } from 'src/components/app/appMenu/AppMenuLink';
import { SecuredContent } from 'src/components/SecuredContent';
import { RolePermissions } from 'src/constants/RolePermission';
import { RoutePaths } from 'src/constants/RoutePath';
import { translate } from 'src/i18n/translate';
import { MarketingIcon } from 'src/icons/MarketingIcon';
import { useSelector } from 'src/utils/react/useSelector';

export function RestaurantMarketingAppMenu(): React.ReactElement {
    const classes = useStyles();
    const theme = useTheme();

    const [showRestaurantMarketingAppMenu, setShowRestaurantMarketingAppMenu] = useState(false);

    const newOrdersPageEnabled = useSelector((state) => state.app.restaurant.newOrdersPageEnabled);

    if (!newOrdersPageEnabled) {
        return (
            <SecuredContent rolePermission={RolePermissions.MARKETING_PAGE}>
                <AppMenuLink routePath={RoutePaths.MARKETING} text={translate('Marketing')} data-testid='restaurantAppMenu.marketingLink'>
                    <ListItemIcon>
                        <MarketingIcon title={'market'} />
                    </ListItemIcon>
                </AppMenuLink>
            </SecuredContent>
        );
    }

    return (
        <SecuredContent rolePermission={RolePermissions.MARKETING_PAGE}>
            <ListItem button onClick={() => setShowRestaurantMarketingAppMenu(!showRestaurantMarketingAppMenu)} className={classes.listItem}>
                <ListItemIcon>
                    <MarketingIcon title={'market'} color={theme.palette.icons.brand} />
                </ListItemIcon>
                <ListItemText classes={{ root: classes.listItemText }} primary={translate('Marketing')} />
                <ListItemIcon>{showRestaurantMarketingAppMenu ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}</ListItemIcon>
            </ListItem>
            <Collapse in={showRestaurantMarketingAppMenu} data-testid='restaurantAppMenu.marketingLink'>
                <List>
                    <AppMenuLink className={classes.menuLinkOption} routePath={RoutePaths.MARKETING_CUSTOMERS} text={translate('Customers')} data-testid='restaurantAppMenu.marketing.customers' />
                    <AppMenuLink className={classes.menuLinkOption} routePath={RoutePaths.MARKETING} text={translate('Campaigns')} data-testid='restaurantAppMenu.marketing.campaign' />
                </List>
            </Collapse>
        </SecuredContent>
    );
}

const useStyles = makeStyles((theme) => ({
    menuLinkOption: {
        paddingLeft: 30,
    },
    listItem: {
        borderRadius: 6,
        '&:hover': {
            backgroundColor: theme.palette.surface.tertiary,
        },
    },
    listItemText: {
        '& span': {
            fontFamily: theme.typography.regular,
        },
    },
    icon: {
        color: theme.palette.primary.main,
        width: 30,
        height: 30,
    },
}));
