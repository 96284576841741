import { createContext, useEffect, useState } from 'react';
import * as React from 'react';
import type { App } from 'src/constants/App';
import { CreatePrinterStep, CreatePrinterSteps } from 'src/constants/CreatePrinterStep';
import type { PrinterBrand } from 'src/constants/PrinterBrand';
import { PrinterContent, PrinterContents } from 'src/constants/PrinterContent';
import { PrinterPaperSize, PrinterPaperSizes } from 'src/constants/PrinterPaperSize';
import { PrinterType, PrinterTypes } from 'src/constants/PrinterType';
import { isBluetoothPrinter } from 'src/utils/printer/isBluetoothPrinter';
import { isPaymentTerminalPrinter } from 'src/utils/printer/isPaymentTerminalPrinter';
import { isSdkPrinter } from 'src/utils/printer/isSdkPrinter';
import { isUsbPrinter } from 'src/utils/printer/isUsbPrinter';
import { isWifiPrinter } from 'src/utils/printer/isWifiPrinter';
import { isMobileApp } from 'src/utils/reactNative/isMobileApp';

export function PrinterWizardProvider({ children, openManagePrintersSection }: Props): React.ReactElement {
    const [createPrinterSteps, setCreatePrinterSteps] = useState<Array<CreatePrinterStep>>([]);
    const [activeCreatePrinterStep, setActiveCreatePrinterStep] = useState<CreatePrinterStep>(CreatePrinterSteps.PRINTER_TYPE);

    const [formValues, setFormValues] = useState({
        printerType: PrinterTypes.WIFI,
        printerBrand: undefined,
        deviceName: '',
        serialNumber: '',
        ipAddress: '',
        port: '',
        channels: [],
        printerPaperSize: PrinterPaperSizes['80_MM'],
        externalPrinterId: '',
        printerContent: PrinterContents.TICKET,
    });

    useEffect(() => {
        if (formValues?.printerType) {
            setCreatePrinterStepByPrinterType(formValues?.printerType);
        }
    }, [formValues?.printerType]);

    const setCreatePrinterStepByPrinterType = (printerType: PrinterType) => {
        setCreatePrinterSteps(getCreatePrinterSteps(printerType) ?? []);
    };

    const setCreatePrinterWizardFormValue = (name: string, newValue?: string | PrinterType | Array<App> | PrinterContent | PrinterPaperSize | PrinterBrand) => {
        setFormValues((prevFormData) => ({
            ...prevFormData,
            [name]: newValue,
        }));
    };

    const goBackWizardStep = () => {
        const activeCreatePrinterStepIdx = createPrinterSteps.findIndex((currentStep) => activeCreatePrinterStep === currentStep);

        activeCreatePrinterStepIdx === 0 ? openManagePrintersSection() : setActiveCreatePrinterStep(createPrinterSteps[activeCreatePrinterStepIdx - 1]);
    };

    const goNextWizardStep = () => {
        const activeCreatePrinterStepIdx = createPrinterSteps.findIndex((currentStep) => activeCreatePrinterStep === currentStep);

        if (activeCreatePrinterStepIdx < createPrinterSteps?.length - 1) {
            setActiveCreatePrinterStep(createPrinterSteps[activeCreatePrinterStepIdx + 1]);
        }
    };

    return (
        <PrinterWizardContext.Provider
            value={{
                setCreatePrinterWizardFormValue,
                formValues,
                resetFormValues: setFormValues,
                activeCreatePrinterStep,
                createPrinterSteps,
                setActiveStep: setActiveCreatePrinterStep,
                goBackWizardStep,
                goNextWizardStep,
            }}
        >
            {children}
        </PrinterWizardContext.Provider>
    );
}

function createWebPrinterPrinterStep(printerType: PrinterType): Array<CreatePrinterStep> | undefined {
    if (isWifiPrinter(printerType) || isBluetoothPrinter(printerType)) {
        return [
            CreatePrinterSteps.PRINTER_TYPE,
            CreatePrinterSteps.PRINTER_NAME,
            CreatePrinterSteps.PRINTER_BRAND,
            CreatePrinterSteps.DOWNLOAD_DRIVERS,
            CreatePrinterSteps.CHANNELS,
            CreatePrinterSteps.PRINTER_PAPER_SIZE,
            CreatePrinterSteps.PRINTER_CONTENT,
            CreatePrinterSteps.CONFIRMATION,
        ];
    }
}

function createMobileAppPrinterSteps(printerType: PrinterType): Array<CreatePrinterStep> | undefined {
    if (isWifiPrinter(printerType)) {
        return [
            CreatePrinterSteps.PRINTER_TYPE,
            CreatePrinterSteps.PRINTER_NAME,
            CreatePrinterSteps.PRINTER_BRAND,
            CreatePrinterSteps.IP_ADDRESS,
            CreatePrinterSteps.PORT,
            CreatePrinterSteps.CHANNELS,
            CreatePrinterSteps.PRINTER_PAPER_SIZE,
            CreatePrinterSteps.PRINTER_CONTENT,
            CreatePrinterSteps.CONFIRMATION,
        ];
    }
    if (isBluetoothPrinter(printerType)) {
        return [
            CreatePrinterSteps.PRINTER_TYPE,
            CreatePrinterSteps.PRINTER_NAME,
            CreatePrinterSteps.PRINTER_BRAND,
            CreatePrinterSteps.CHANNELS,
            CreatePrinterSteps.PRINTER_PAPER_SIZE,
            CreatePrinterSteps.PRINTER_CONTENT,
            CreatePrinterSteps.CONFIRMATION,
        ];
    }
}

function getCreatePrinterSteps(printerType: PrinterType) {
    if (isUsbPrinter(printerType)) {
        return [
            CreatePrinterSteps.PRINTER_TYPE,
            CreatePrinterSteps.PRINTER_NAME,
            CreatePrinterSteps.PRINTER_BRAND,
            CreatePrinterSteps.SERIAL_NUMBER,
            CreatePrinterSteps.CHANNELS,
            CreatePrinterSteps.PRINTER_PAPER_SIZE,
            CreatePrinterSteps.PRINTER_CONTENT,
            CreatePrinterSteps.CONFIRMATION,
        ];
    }

    if (isPaymentTerminalPrinter(printerType)) {
        return [
            CreatePrinterSteps.PRINTER_TYPE,
            CreatePrinterSteps.PRINTER_NAME,
            CreatePrinterSteps.SERIAL_NUMBER,
            CreatePrinterSteps.CHANNELS,
            CreatePrinterSteps.PRINTER_CONTENT,
            CreatePrinterSteps.CONFIRMATION,
        ];
    }

    if (isSdkPrinter(printerType)) {
        return [CreatePrinterSteps.PRINTER_TYPE, CreatePrinterSteps.PRINTER_NAME, CreatePrinterSteps.CHANNELS, CreatePrinterSteps.PRINTER_CONTENT, CreatePrinterSteps.CONFIRMATION];
    }

    if (isMobileApp()) {
        return createMobileAppPrinterSteps(printerType);
    }

    return createWebPrinterPrinterStep(printerType);
}

export const PrinterWizardContext = createContext<PrinterWizardProviderValues>({
    setCreatePrinterWizardFormValue: () => {},
    resetFormValues: () => {},
    createPrinterSteps: [],
    activeCreatePrinterStep: CreatePrinterSteps.PRINTER_TYPE,
    formValues: {
        printerType: PrinterTypes.WIFI,
        printerBrand: undefined,
        deviceName: '',
        serialNumber: '',
        ipAddress: '',
        port: '',
        channels: [],
        printerPaperSize: PrinterPaperSizes['80_MM'],
        externalPrinterId: '',
        printerContent: PrinterContents.TICKET,
    },
    setActiveStep: () => {},
    goBackWizardStep: () => {},
    goNextWizardStep: () => {},
});

export type FormValues = {
    printerType: PrinterType;
    printerBrand?: PrinterBrand;
    externalPrinterId?: string;
    deviceName?: string;
    serialNumber?: string;
    ipAddress?: string;
    port?: string;
    channels?: Array<App>;
    printerPaperSize?: PrinterPaperSize;
    printerContent: PrinterContent;
    disabled?: boolean;
};

export type PrinterWizardProviderValues = {
    resetFormValues: any;
    setActiveStep: any;
    goBackWizardStep: any;
    goNextWizardStep: any;
    formValues: FormValues;
    createPrinterSteps: Array<CreatePrinterStep>;
    activeCreatePrinterStep: CreatePrinterStep;
    setCreatePrinterWizardFormValue: (name: string, newValue: undefined | string | PrinterType | Array<App> | PrinterContent | PrinterPaperSize | PrinterBrand) => void;
};

type Props = {
    children: React.ReactNode;
    openManagePrintersSection: any;
};
