import * as React from 'react';
import { Tabs } from 'src/components/Tabs';
import { translate } from 'src/i18n/translate';
import { useSelector } from 'src/utils/react/useSelector';

export function MenuTabs({ children }: Props): React.ReactElement {
    const loadingMenus = useSelector((state) => state.menu.loadingMenus);
    const loadingMenuCategories = useSelector((state) => state.menu.loadingMenuCategories);
    const loadingMenuItems = useSelector((state) => state.menu.loadingMenuItems);
    const loadingModifierGroups = useSelector((state) => state.menu.loadingModifierGroups);

    const loadingMenusElements = loadingMenus || loadingMenuCategories || loadingMenuItems || loadingModifierGroups;

    const tabs = [
        {
            value: '1',
            label: translate('Menus'),
        },
        {
            value: '2',
            label: translate('Categories'),
        },
        {
            value: '3',
            label: translate('Products'),
        },
        {
            value: '4',
            label: translate('Modifiers'),
        },
        {
            value: '5',
            label: translate('Event history'),
        },
    ];

    return (
        <div>
            <div style={{ position: 'relative', marginBottom: '30vh' }}>
                <Tabs tabs={tabs} loading={loadingMenusElements}>
                    {children}
                </Tabs>
            </div>
        </div>
    );
}

type Props = {
    children: React.ReactNode;
};
