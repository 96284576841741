import { letseatmanagerApiMethod } from 'src/api/letseatmanagerApi';
import type { ApiSauceResponse } from 'src/api/types/ApiSauceResponse';
import type { InitialRoutePath } from 'src/constants/InitialRoutePaths';
import type { RolePermission } from 'src/constants/RolePermission';
import type { RestaurantId } from 'src/types/Id';
import type { UserRoleVm } from 'src/types/UserRoleVm';

export function createUserRoleApi(request: CreateUserRoleApiRequest): ApiSauceResponse<UserRoleVm> {
    return letseatmanagerApiMethod('userRole/createUserRoleApi', request);
}

type CreateUserRoleApiRequest = {
    restaurantId: RestaurantId;
    roleName: string;
    permissions: Array<RolePermission>;
    initialPage: InitialRoutePath;
};
