import { letseatmanagerApiMethod } from 'src/api/letseatmanagerApi';
import type { ApiSauceResponse } from 'src/api/types/ApiSauceResponse';
import type { RestaurantId } from 'src/types/Id';
import type { MenuVm } from 'src/types/MenuVm';

export async function findMenusApi(request: FindMenusApiRequest): ApiSauceResponse<Array<MenuVm>> {
    return letseatmanagerApiMethod('menu/findMenusApi', request);
}

export type FindMenusApiRequest = {
    restaurantId: RestaurantId;
};
