import { isArray } from 'src/utils/array/isArray';
import { isBlankString } from 'src/utils/string/isBlankString';

export function removeBlanks<T extends Array<string> | null | undefined>(value: T): T {
    if (!value || !isArray(value)) {
        return value;
    }

    return value.reduce<Array<any>>((result, next) => {
        if (isBlankString(next)) return result;
        return [...result, next];
    }, []) as any;
}
