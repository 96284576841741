import { makeStyles } from '@material-ui/core/styles';
import { BigNumber } from 'bignumber.js';
import * as React from 'react';
import { BarChartCanvas } from 'src/components/charts/BarChartCanvas';
import { translate } from 'src/i18n/translate';
import { GraphEmptyState } from 'src/scenes/letseatmanager/posReports/GraphEmptyState';
import { useFormatAsRestaurantCurrencyNumber } from 'src/services/restaurant/useFormatAsRestaurantCurrencyNumber';
import type { CashRegisterTransactionsReportVm } from 'src/types/CashRegisterTransactionsReportVm';
import { removeDuplicates } from 'src/utils/array/removeDuplicates';
import { sum } from 'src/utils/reduce/sum';
import { toShortId } from 'src/utils/uuid/toShortId';

export function CashRegisterTransactionsCashFlowReport({ cashRegisterTransactionsReport }: Props): React.ReactElement {
    const classes = useStyles();
    const formatAsCurrencyNumber = useFormatAsRestaurantCurrencyNumber();

    const getCashRegisterTransactionsBarChartData = () => {
        return [
            {
                id: translate('Deposits'),
                ...getCashRegisterDepositsByCashRegisterId(),
            },
            {
                id: translate('Withdraws'),
                ...getCashRegisterWithdrawsByCashRegisterId(),
            },
            {
                id: translate('Total'),
                ...getCashRegisterTotalTransactionsByCashRegisterId(),
            },
        ];
    };

    const getCashRegisterDepositsByCashRegisterId = () => {
        const cashRegisterDeposits: Record<string, any> = {};
        cashRegisterTransactionsReport.totalCashInflow.forEach((cashRegisterDeposit) => {
            cashRegisterDeposits[cashRegisterDeposit.cashRegisterId as string] = BigNumber(cashRegisterDeposit.amount).toString();
        });
        return cashRegisterDeposits;
    };

    const getCashRegisterWithdrawsByCashRegisterId = () => {
        const cashRegisterDeposits: Record<string, any> = {};
        cashRegisterTransactionsReport.totalCashOut.forEach((cashRegisterWithdraw) => {
            cashRegisterDeposits[cashRegisterWithdraw.cashRegisterId as string] = BigNumber(cashRegisterWithdraw.amount).toString();
        });
        return cashRegisterDeposits;
    };

    const getCashRegisterTotalTransactionsByCashRegisterId = () => {
        const cashRegisterTransactions: Record<string, any> = {};
        cashRegisterTransactionsReport.totalCashInflow.forEach((cashRegisterDeposit) => {
            cashRegisterTransactions[cashRegisterDeposit.cashRegisterId as string] = BigNumber(cashRegisterDeposit.amount).toString();
        });
        cashRegisterTransactionsReport.totalCashOut.forEach((cashRegisterWithdraw) => {
            if (!cashRegisterTransactions[cashRegisterWithdraw.cashRegisterId as string]) {
                cashRegisterTransactions[cashRegisterWithdraw.cashRegisterId as string] = BigNumber(cashRegisterWithdraw.amount).toString();
                return;
            }
            const cashRegisterWithdrawAmount = BigNumber(cashRegisterWithdraw.amount).absoluteValue().toString();
            cashRegisterTransactions[cashRegisterWithdraw.cashRegisterId as string] = BigNumber(cashRegisterTransactions[cashRegisterWithdraw.cashRegisterId as string])
                .minus(cashRegisterWithdrawAmount)
                .toString();
        });
        return cashRegisterTransactions;
    };

    const getCashRegisterIds = () => {
        const cashRegisterIdsWithDeposits = cashRegisterTransactionsReport.totalCashInflow.map((cashRegisterDeposit) => cashRegisterDeposit.cashRegisterId);
        const cashRegisterIdsWithWithdraws = cashRegisterTransactionsReport.totalCashOut.map((cashRegisterWithdraw) => cashRegisterWithdraw.cashRegisterId);
        return removeDuplicates([...cashRegisterIdsWithDeposits, ...cashRegisterIdsWithWithdraws]) ?? [];
    };

    const isEmptyReport = () => {
        const cashRegisterWithdraws = cashRegisterTransactionsReport.totalCashOut.map((cashRegisterWithdraw) => cashRegisterWithdraw.amount);
        const cashRegisterDeposits = cashRegisterTransactionsReport.totalCashInflow.map((cashRegisterDeposit) => cashRegisterDeposit.amount);

        const totalWithdraws = cashRegisterWithdraws.reduce(sum, BigNumber(0));
        const totalDeposits = cashRegisterDeposits.reduce(sum, BigNumber(0));

        return totalWithdraws.isZero() && totalDeposits.isZero();
    };

    if (isEmptyReport()) {
        return <GraphEmptyState title={translate('Cash flow')} />;
    }

    return (
        <article className={classes.container}>
            <h2 className={classes.title}>{translate('Cash flow')}</h2>
            <BarChartCanvas
                data={getCashRegisterTransactionsBarChartData()}
                keys={getCashRegisterIds()}
                tooltip={({ id, value }: any) => {
                    return (
                        <div className={classes.tooltip}>
                            <span className={classes.tooltipTitle}>{translate('Cash register #@shortCashRegisterId', { shortCashRegisterId: toShortId(id) })}</span>
                            <span className={classes.tooltipText}>{formatAsCurrencyNumber(value)}</span>
                        </div>
                    );
                }}
            />
        </article>
    );
}

const useStyles = makeStyles((theme) => ({
    container: {
        height: '70vh',
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
    },
    title: {
        fontFamily: theme.typography.medium,
        color: theme.palette.text.primary,
    },
    tooltip: {
        padding: 10,
        borderRadius: 3,
        backgroundColor: 'white',
        boxShadow: '1px 1px 1px rgb(0,0,0,0.2)',
        display: 'flex',
        flexDirection: 'column',
    },
    tooltipTitle: {
        fontFamily: theme.typography.bold,
    },
    tooltipText: {
        fontFamily: theme.typography.regular,
    },
}));

type Props = {
    cashRegisterTransactionsReport: CashRegisterTransactionsReportVm;
};
