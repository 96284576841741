import { MenuItem } from '@material-ui/core';
import * as React from 'react';
import { RestaurantContext } from 'src/api/letseatmanager/getAppContextApi';
import { app2 } from 'src/app2';
import { translate } from 'src/i18n/translate';
import { usePrintOrderTicket } from 'src/services/printer/usePrintOrderTicket';
import type { OrderVm } from 'src/types/OrderVm';
import { isCompletedOrder } from 'src/utils/order/isCompletedOrder';
import { useAction } from 'src/utils/react/useAction';
import { useSelector } from 'src/utils/react/useSelector';

export function PrintTicketContextualMenuItem({ order }: Props): React.ReactElement {
    const authorizationToReprintCompletedOrderEnabled = useSelector((state) => state.app.restaurant.authorizationToReprintCompletedOrderEnabled);
    const posEnabled = useSelector((state) => state.app.restaurant.posEnabled);
    const restaurants = useSelector((state) => state.app.restaurants);

    const [printOrderTicket] = usePrintOrderTicket();

    const openVerifyPosPasswordDialog = useAction(app2.actions.openVerifyPosPasswordDialog);

    const handlePrintTicket = async () => {
        const restaurantFromOrder = restaurants?.find((restaurant: RestaurantContext) => restaurant.restaurantId === order.restaurant?.restaurantId);

        if (isCompletedOrder(order.orderStatus) && authorizationToReprintCompletedOrderEnabled && posEnabled) {
            return openVerifyPosPasswordDialog({
                onAuthorized: async () => {
                    await printOrderTicket(order, { forceToPrint: true, openCashBox: !restaurantFromOrder?.avoidOpenCashBoxOnReprintTicketEnabled });
                },
                onUnauthorized: () => alert(translate('Unauthorized to access.')),
            });
        }

        await printOrderTicket(order, { forceToPrint: true, openCashBox: !restaurantFromOrder?.avoidOpenCashBoxOnReprintTicketEnabled });
    };

    return (
        <MenuItem onClick={handlePrintTicket} style={{ textTransform: 'none' }}>
            {isCompletedOrder(order.orderStatus) ? translate('Reprint Bill') : translate('Print Bill')}
        </MenuItem>
    );
}

type Props = {
    order: OrderVm;
};
