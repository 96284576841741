import { makeStyles } from '@material-ui/core';
import { CircleCrossIcon } from '@pidedirecto/ui/icons';
import * as React from 'react';
import { translate } from 'src/i18n/translate';

export function UrugayanReturnedOrderStatus(): React.ReactElement {
    const classes = useStyles();

    return (
        <div className={classes.returned}>
            <CircleCrossIcon />
            <div className={classes.returnedText}>
                <div>{translate('Order invoiced and returned')}</div>
                <div className={classes.returnedCaption}>{translate('A credit note was generated')}</div>
            </div>
        </div>
    );
}

const useStyles = makeStyles((theme) => ({
    returnedText: {
        display: 'flex',
        flexDirection: 'column',
    },
    returnedCaption: {
        fontFamily: theme.typography.regular,
        fontSize: 12,
    },
    returned: {
        display: 'flex',
        fontFamily: theme.typography.bold,
        fontSize: 14,
        gap: 10,
        alignItems: 'center',
        border: `1px solid  ${theme.palette.border.danger}`,
        backgroundColor: theme.palette.surface.danger,
        color: theme.palette.text.danger,
        borderRadius: 4,
        padding: '4px 8px',
    },
}));
