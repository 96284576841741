import { makeStyles, useTheme } from '@material-ui/core';
import { DropDown, DropDownItem } from '@pidedirecto/ui';
import { BeakerIcon, ClosedEyeIcon, EllipsisVerticalIcon, EyeIcon } from '@pidedirecto/ui/icons';
import * as React from 'react';
import { useState } from 'react';
import { translate } from 'src/i18n/translate';
import { EditIcon } from 'src/icons/EditIcon';
import { TrashcanIcon } from 'src/icons/TrashcanIcon';
import { ChangeMenuProductDialog } from 'src/scenes/letseatmanager/menu/menuCategory/ChangeMenuProductDialog';
import { RemoveProductFromMenuCategoryDialog } from 'src/scenes/letseatmanager/menu/menuCategory/RemoveProductFromMenuCategoryDialog';
import { useIsMenuEditionDisabled } from 'src/services/menu/useIsMenuEditionDisabled';
import { useLoadMenus } from 'src/services/menu/useLoadMenus';
import { useLoadMenuCategories } from 'src/services/menuCategory/useLoadMenuCategories';
import { useLoadMenuItems } from 'src/services/menuItem/useLoadMenuItems';
import { useFormatAsRestaurantCurrencyNumber } from 'src/services/restaurant/useFormatAsRestaurantCurrencyNumber';
import type { MenuCategoryId, MenuId } from 'src/types/Id';
import type { ManagerMenuItemVm } from 'src/types/ManagerMenuItemVm';
import { transformImageUrl } from 'src/utils/image/transformImageUrl';

export function MenuItemInMenu({ menuItem, menuCategoryId, menuId }: Props): React.ReactElement {
    const classes = useStyles();
    const isMenuEditionDisabled = useIsMenuEditionDisabled();
    const theme = useTheme();
    const formatAsCurrencyNumber = useFormatAsRestaurantCurrencyNumber();

    const refreshMenuCategories = useLoadMenuCategories();
    const refreshMenuItems = useLoadMenuItems();
    const refreshMenus = useLoadMenus();

    const [updateMenuItemDialogState, setUpdateMenuItemDialogState] = useState({ open: false });
    const [removeMenuItemDialogState, setRemoveMenuItemDialogState] = useState({ open: false });

    const menuProductInfo = menuItem?.menusProductInfo?.find((menu: { hidden?: boolean; menuId: MenuId; price: string; promoPrice?: string }) => menu.menuId === menuId);
    const isHidden = menuProductInfo?.hidden || menuItem.hidden;

    const openRemoveMenuItemDialog = () => setRemoveMenuItemDialogState({ open: true });

    const closeRemoveMenuItemDialog = () => setRemoveMenuItemDialogState({ open: false });

    const openUpdateMenuItemDialog = () => setUpdateMenuItemDialogState({ open: true });

    const closeUpdateMenuItemDialog = () => setUpdateMenuItemDialogState({ open: false });

    const handleUpdate = () => {
        refreshMenus();
        refreshMenuItems();
    };

    return (
        <div className={classes.container}>
            <RemoveProductFromMenuCategoryDialog
                open={removeMenuItemDialogState.open}
                menuItem={menuItem}
                menuCategoryId={menuCategoryId}
                onClose={closeRemoveMenuItemDialog}
                onSuccess={refreshMenuCategories}
            />
            <ChangeMenuProductDialog open={updateMenuItemDialogState.open} onClose={closeUpdateMenuItemDialog} menuItem={menuItem} menuId={menuId} onSuccess={handleUpdate} />
            <div className={classes.nameContainer}>
                {menuItem.imageUrl && <img className={classes.image} src={transformImageUrl(menuItem?.imageUrl, 320, 320)} alt={`${menuItem?.name} image`} />}
                {!menuItem.imageUrl && <div className={classes.noImage}></div>}
                <span className={classes.text}>{menuItem.name}</span>
            </div>
            <div className={classes.priceContainer}>
                {menuItem.modifierGroupIds && <BeakerIcon color={theme.palette.icons.brand} title={translate('This product has modifiers different from those of the category')} size={20} />}
                {isHidden && !isMenuEditionDisabled && <ClosedEyeIcon title={translate('Hidden')} color={'#6C7076'} size={20} />}
                {!isHidden && !isMenuEditionDisabled && <EyeIcon title={translate('Visible')} color={'#6C7076'} size={20} />}
                <span className={classes.text}>{formatAsCurrencyNumber(menuProductInfo?.price ?? menuItem.price)}</span>
                <DropDown variant='icon' content={<EllipsisVerticalIcon size={20} title='options' />} disabled={isMenuEditionDisabled} position='right' classes={{ dropdown: classes.dropdown }}>
                    <DropDownItem onClick={openUpdateMenuItemDialog} classes={{ container: classes.dropdownItem }}>
                        <EditIcon color={'#6C7076'} />
                        {translate('Edit')}
                    </DropDownItem>
                    <DropDownItem onClick={openRemoveMenuItemDialog} classes={{ container: classes.dropdownItem }}>
                        <TrashcanIcon color={'#E37878'} title={'trashcan icon'} />
                        {translate('Remove')}
                    </DropDownItem>
                </DropDown>
            </div>
        </div>
    );
}

const useStyles = makeStyles((theme) => ({
    container: {
        width: '100%',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
    },
    nameContainer: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        gap: 10,
        paddingRight: 10,
    },
    priceContainer: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        gap: 20,
    },
    image: {
        width: 40,
        height: 40,
        borderRadius: 10,
        objectFit: 'cover',
        flexShrink: 0,
        [theme.breakpoints.down('sm')]: {
            display: 'none',
        },
    },
    noImage: {
        width: 40,
        height: 40,
        borderRadius: 10,
        backgroundColor: '#E5E8ED',
        flexShrink: 0,
        [theme.breakpoints.down('sm')]: {
            display: 'none',
        },
    },
    text: {
        fontFamily: theme.typography.regular,
        fontSize: 15,
        marginRight: -10,
    },
    dropdownItem: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        gap: 6,
    },
    dropdown: {
        minWidth: 'unset',
    },
}));

type Props = {
    menuItem: ManagerMenuItemVm;
    menuCategoryId: MenuCategoryId;
    menuId?: MenuId;
};
