import { PosBusinessDaySection, PosBusinessDaySections } from 'src/constants/PosBusinessDaySections';

export function mapPosBusinessDaySectionsToFormPosBusinessDaySections(sections: Array<PosBusinessDaySection>): FormActivePosBusinessDaySections {
    const pusBusinessDaySections: Array<PosBusinessDaySection> = Object.values(PosBusinessDaySections);
    const mappedSections: Record<string, any> = {};

    for (const posBusinessKey of pusBusinessDaySections) {
        if (sections?.includes(posBusinessKey)) {
            mappedSections[posBusinessKey] = true;
            continue;
        }
        mappedSections[posBusinessKey] = false;
    }

    return mappedSections;
}

type FormActivePosBusinessDaySections = Partial<Record<PosBusinessDaySection, boolean>>;
