import { usePosPaymentMethods } from 'src/services/pos/posPaymentMethods/usePosPaymentMethods';
import { usePosPaymentMethodsDeprecated } from 'src/services/pos/posPaymentMethods/usePosPaymentMethodsDeprecated';
import { DeprecatedRestaurantPaymentMethodVm } from 'src/types/DeprecatedRestaurantPaymentMethodVm';
import type { PosPayment } from 'src/types/PosPayment';
import type { PosPaymentVm } from 'src/types/PosPaymentVm';
import { RestaurantPaymentMethodVm } from 'src/types/RestaurantPaymentMethodVm';
import { useSelector } from 'src/utils/react/useSelector';
import { newUuid } from 'src/utils/uuid/newUuid';

export function useMapPosPaymentToPayment(): (posPayment: PosPaymentVm) => PosPayment {
    const posPaymentMethodsDeprecated = usePosPaymentMethodsDeprecated();
    const posPaymentMethods = usePosPaymentMethods();

    const currency = useSelector((state) => state.app.restaurant?.currencyFormat?.currency);
    const restaurantPaymentMethodsEnabled = useSelector((state) => state.pos.context?.restaurantPaymentMethodsEnabled);

    const mapPosPaymentToPayment = (posPayment: PosPaymentVm): PosPayment => {
        const isMainCurrency = posPayment.restaurantCurrency?.currency === currency;
        const isCustomPaymentMethodDeprecated = posPaymentMethodsDeprecated?.find((payment: DeprecatedRestaurantPaymentMethodVm) => posPayment?.paymentMethod === payment.name);
        const isCustomPaymentMethod = posPaymentMethods?.find((payment: RestaurantPaymentMethodVm) => posPayment?.paymentMethod === payment.name);

        if (restaurantPaymentMethodsEnabled) {
            return {
                id: newUuid() as string,
                amount: posPayment.amount,
                paymentMethod: isCustomPaymentMethod ? isCustomPaymentMethod.paymentMethod : (posPayment.paymentMethod as any),
                customPaymentMethod: isCustomPaymentMethod?.name as any,
                customerNumber: posPayment.customerNumber,
                isTipPayment: posPayment.isTipPayment,
                restaurantCurrencyAmount: !isMainCurrency ? posPayment.restaurantCurrencyAmount : undefined,
                restaurantCurrency: !isMainCurrency ? posPayment.restaurantCurrency : undefined,
            };
        }

        return {
            id: newUuid() as string,
            amount: posPayment.amount,
            paymentMethod: isCustomPaymentMethodDeprecated ? isCustomPaymentMethodDeprecated.paymentMethod : (posPayment.paymentMethod as any),
            customPaymentMethod: isCustomPaymentMethodDeprecated?.name as any,
            customerNumber: posPayment.customerNumber,
            isTipPayment: posPayment.isTipPayment,
            restaurantCurrencyAmount: !isMainCurrency ? posPayment.restaurantCurrencyAmount : undefined,
            restaurantCurrency: !isMainCurrency ? posPayment.restaurantCurrency : undefined,
        };
    };

    return mapPosPaymentToPayment;
}
