import { makeStyles } from '@material-ui/core/styles';
import { useContext, useEffect, useRef, useState } from 'react';
import * as React from 'react';
import { PageContext } from 'src/components/Page';
import { UpdatingContentProgress } from 'src/components/UpdatingContentProgress';
import { SECONDS } from 'src/constants/TimeUnit';
import { createUserOpenedDialogLogEvent } from 'src/services/logEvent/createUserOpenedDialogLogEvent';
import { normalizeUiStackTrace } from 'src/services/logEvent/normalizeUiStackTrace';
import { classNames } from 'src/utils/react/classNames';

/**
 * @callback onClose
 */

/**
 *
 * @param {boolean} open - Opens the dialog
 * @param {boolean} [loading] - Shows a loading indicator at the bottom of the dialog
 * @param {boolean} [keepMounted] - Prevents the dialog of
 * @param {onClose} [onClose] - Function to be called when the user wants to close the dialog
 * @param {string} [title] - Title of the dialog
 * @param {string} [position] - Dialog position, by default it is centered
 * @param {React.Node} [children] - Content of the dialog
 * @param {Object} [classes] - Classes object to override dialog style
 * @param {string} [classes.dialogContainer] - CSS class to override dialog container style
 * @param {string} [classes.dialog] - CSS class object to override dialog card style
 * @param {string} [classes.title] - CSS class object to override title card style
 * @returns {JSX.Element|null}
 * @constructor
 */
export function Dialog({ open, loading, keepMounted, onClose, title, position, children, classes: classesProp }: Props): React.ReactElement | null {
    const classes = useStyles({ position });
    const pageContext = useContext(PageContext);
    const closeDialogTimeout = useRef<NodeJS.Timer>();

    const [isDialogOpen, setIsDialogOpen] = useState(false);

    useEffect(() => {
        if (open) {
            if (closeDialogTimeout.current) clearTimeout(closeDialogTimeout.current);
            setIsDialogOpen(true);
        }
        if (!open) {
            closeDialogTimeout.current = setTimeout(() => {
                setIsDialogOpen(false);
                closeDialogTimeout.current = undefined;
            }, CLOSE_DIALOG_ANIMATION_DURATION);
        }
    }, [open]);

    useEffect(() => {
        if (isDialogOpen) {
            window.addEventListener('keydown', handleKeyDown);
            createUserOpenedDialogLogEvent({ title, pageContext });
        } else {
            window.removeEventListener('keydown', handleKeyDown);
        }
        return () => {
            window.removeEventListener('keydown', handleKeyDown);
        };
    }, [isDialogOpen]);

    const addDialogToStackTrace = () => {
        pageContext.addElementToStackTrace(normalizeUiStackTrace(`dialog_${title ?? ''}`));
    };

    const handleKeyDown = (e: any) => {
        if (e.key !== 'Escape') return;
        onClose?.();
    };

    if (!isDialogOpen && !keepMounted) return null;

    return (
        <div
            className={classNames(classes.dialogContainer, classesProp?.dialogContainer, { [classes.openDialogContainer]: isDialogOpen, [classes.closeDialogContainer]: !open && isDialogOpen })}
            onClickCapture={addDialogToStackTrace}
            style={!open && !isDialogOpen ? { visibility: 'hidden', top: '100vh' } : undefined}
        >
            <div className={classes.background} onClick={onClose}></div>
            <div aria-modal={true} role={'dialog'} aria-labelledby='dialog-title' className={classNames(classes.dialogContent, classesProp?.dialog)}>
                <div className={classes.loadingContainer}>
                    <UpdatingContentProgress loading={loading || false} bottom left />
                </div>
                {title && (
                    <h2 id={'dialog-title'} className={classNames(classes.title, classesProp?.title)}>
                        {title}
                    </h2>
                )}
                {children}
            </div>
        </div>
    );
}

const CLOSE_DIALOG_ANIMATION_DURATION = 0.1 * SECONDS;

const useStyles = makeStyles((theme) => ({
    dialogContainer: {
        position: 'fixed',
        left: 0,
        top: 0,
        width: '100%',
        height: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: ({ position }: any) => {
            if (position === 'right') return 'flex-end';
            return 'center';
        },
        flexDirection: 'column',
        zIndex: 1000,
        animation: '$modal-open 0.2s ease-out',
    },
    openDialogContainer: {
        animation: '$modal-open 0.2s ease-out',
        animationFillMode: 'forwards',
    },
    closeDialogContainer: {
        animation: `$modal-close ${CLOSE_DIALOG_ANIMATION_DURATION}ms ease-out`,
        animationFillMode: 'forwards',
    },
    background: {
        position: 'absolute',
        top: 0,
        left: 0,
        backgroundColor: 'rgba(0, 0, 0, 0.4)',
        width: '100%',
        height: '100%',
        zIndex: 110,
    },
    dialogContent: {
        backgroundColor: '#fefefe',
        border: '1px solid #888',
        padding: '24px',
        maxHeight: '90vh',
        maxWidth: '90vw',
        borderRadius: 10,
        borderTopRightRadius: ({ position }: any) => (position === 'right' ? 0 : 10),
        borderBottomRightRadius: ({ position }: any) => (position === 'right' ? 0 : 10),
        borderTopLeftRadius: 10,
        borderBottomLeftRadius: 10,
        zIndex: 120,
        position: 'relative',
        overflowY: 'auto',
    },
    title: {
        color: theme.palette.text.primary,
        fontWeight: 600,
        textAlign: 'center',
        fontSize: '20px',
        fontFamily: theme.typography.semiBold,
    },
    '@keyframes modal-open': {
        from: {
            opacity: 0,
        },
        to: {
            opacity: 1,
        },
    },
    '@keyframes modal-close': {
        from: {
            opacity: 1,
        },
        to: {
            opacity: 0,
        },
    },
    loadingContainer: {
        position: 'absolute',
        width: '100%',
        left: 0,
        bottom: 0,
        overflow: 'hidden',
        borderBottomRightRadius: 10,
        borderBottomLeftRadius: 10,
        height: 10,
    },
}));

type Props = {
    open: boolean;
    loading?: boolean;
    keepMounted?: boolean;
    onClose?: any;
    title?: string;
    position?: 'right';
    children?: React.ReactNode;
    classes?: {
        dialogContainer?: string;
        dialog?: string;
        title?: string;
    };
};
