import type { InvoicesLegalInfoVm } from 'src/api/letseatmanager/types/InvoicesLegalInfoVm';
import { letseatmanagerApiMethod } from 'src/api/letseatmanagerApi';
import type { ApiSauceResponse } from 'src/api/types/ApiSauceResponse';
import type { RestaurantId } from 'src/types/Id';

export async function findRestaurantInvoicesLegalInfoByBusinessNameApi(request: FindRestaurantInvoiceLegalInfosRequest): ApiSauceResponse<Array<InvoicesLegalInfoVm>> {
    return letseatmanagerApiMethod('invoice/findRestaurantInvoicesLegalInfoByBusinessNameApi', request);
}

type FindRestaurantInvoiceLegalInfosRequest = {
    restaurantId: RestaurantId;
    businessName: string;
};
