import { useNotification } from '@pidedirecto/ui/hooks';
import * as React from 'react';
import { useHistory } from 'react-router-dom';
import { Button } from 'src/components/Button';
import { SecuredContent } from 'src/components/SecuredContent';
import { RolePermissions } from 'src/constants/RolePermission';
import { RoutePaths } from 'src/constants/RoutePath';
import { translate } from 'src/i18n/translate';
import type { OrderVm } from 'src/types/OrderVm';
import { isPendingOrder } from 'src/utils/order/isPendingOrder';
import { useEditPendingOrder } from 'src/utils/react/useEditPendingOrder';
import { useSelector } from 'src/utils/react/useSelector';

export function EditOrderButton({ order, disabled }: Props): React.ReactElement | null {
    const history = useHistory();
    const notification = useNotification();
    const editPendingOrder = useEditPendingOrder();

    const brandOpened = useSelector((state) => state.app.brandOpened);

    const handleEditPendingOrder = () => {
        if (brandOpened) {
            notification({ message: translate('Select a restaurant to edit orders') });
            return;
        }
        editPendingOrder({ order });
        history.push({
            pathname: RoutePaths.POS,
            search: history.location.search,
        });
    };

    if (!isPendingOrder(order.orderStatus)) return null;

    return (
        <SecuredContent rolePermission={RolePermissions.EDIT_ORDERS}>
            <Button secondary onClick={handleEditPendingOrder} disabled={disabled}>
                {translate('Edit order')}
            </Button>
        </SecuredContent>
    );
}

type Props = {
    order: OrderVm;
    disabled?: boolean;
};
