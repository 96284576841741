import { letseatmanagerApiMethod } from 'src/api/letseatmanagerApi';
import type { ApiSauceResponse } from 'src/api/types/ApiSauceResponse';
import type { RestaurantId } from 'src/types/Id';

export async function enableRestaurantPaymentsApi(request: EnableRestaurantPaymentsRequest): ApiSauceResponse<void> {
    return letseatmanagerApiMethod('restaurant/enableRestaurantPaymentsApi', request);
}

type EnableRestaurantPaymentsRequest = {
    restaurantId: RestaurantId;
    cashPaymentsEnabled: boolean;
    creditCardPaymentsEnabled: boolean;
    paymentLinksEnabled: boolean;
};
