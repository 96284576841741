import { makeStyles } from '@material-ui/core';
import { Loader } from '@pidedirecto/ui';
import { useEffect, useRef, useState } from 'react';
import * as React from 'react';
import { changeRestaurantZoneApi } from 'src/api/letseatmanager/pos/changeRestaurantZoneApi';
import { findRestaurantZonesApi } from 'src/api/letseatmanager/pos/findRestaurantZonesApi';
import { ApiErrorResponse } from 'src/api/types/ApiSauceResponse';
import { Button } from 'src/components/Button';
import { SecuredContent } from 'src/components/SecuredContent';
import { Tab, Tabs } from 'src/components/Tabs';
import { UpdatingContentProgress } from 'src/components/UpdatingContentProgress';
import { RolePermissions } from 'src/constants/RolePermission';
import { translate } from 'src/i18n/translate';
import { EditIcon } from 'src/icons/EditIcon';
import { FlappyDiskIcon } from 'src/icons/FlappyDiskIcon';
import { getPosRestaurantZonesOfflineInLocalStorage } from 'src/localStorage/getPosRestaurantZonesOfflineInLocalStorage';
import { setPosRestaurantZonesOfflineInLocalStorage } from 'src/localStorage/setPosRestaurantZonesOfflineInLocalStorage';
import { posReducer } from 'src/reducers/posReducer';
import { EditRestaurantZonesMenu } from 'src/scenes/letseatmanager/posRestaurantZones/EditRestaurantZonesMenu';
import { PosTables } from 'src/scenes/letseatmanager/posRestaurantZones/PosTables';
import { PosTableStates } from 'src/scenes/letseatmanager/posRestaurantZones/PosTableStates';
import { RestaurantZoneId } from 'src/types/Id';
import { RestaurantZoneVm, TableVm } from 'src/types/RestaurantZoneVm';
import { alertKnownErrorOrSomethingWentWrong } from 'src/utils/alert/alertKnownErrorOrSomethingWentWrong';
import { useAction } from 'src/utils/react/useAction';
import { useLoadApi } from 'src/utils/react/useLoadApi';
import { useSelector } from 'src/utils/react/useSelector';

export function PosRestaurantZones({ editDisabled }: Props): React.ReactElement | null {
    const classes = useStyles();
    const tablesPosition = useRef<Array<TableVm>>([]);

    const [currentRestaurantZoneId, setCurrentRestaurantZoneId] = useState<RestaurantZoneId>();
    const [editing, setEditing] = useState(false);
    const [loading, setLoading] = useState(false);
    const [tables, setTables] = useState<Array<TableVm>>([]);

    const restaurantId = useSelector((state) => state.app.restaurantId);
    const restaurantZone = useSelector((state) => state.pos.restaurantZone);
    const restaurantTablesHasChanged = useSelector((state) => state.pos.restaurantTablesHasChanged);

    const setRestaurantZone = useAction(posReducer.actions.setRestaurantZone);
    const openCreateRestaurantZoneDialog = useAction(posReducer.actions.openCreateRestaurantZoneDialog);
    const closeCreateRestaurantZoneDialog = useAction(posReducer.actions.closeCreateRestaurantZoneDialog);
    const changeRestaurantTablesAndZones = useAction(posReducer.actions.changeRestaurantTablesAndZones);
    const setRefreshRestaurantZones = useAction(posReducer.actions.setRefreshRestaurantZones);

    const [loadingRestaurantZones, restaurantZones, refreshRestaurantZones] = useLoadApi(findRestaurantZonesApi, { restaurantId }, { initialValue: [], onError: handleApiError });

    useEffect(() => {
        if (currentRestaurantZoneId && restaurantZones.length) {
            const currentRestaurantZone = restaurantZones.find((restaurantZone) => restaurantZone.restaurantZoneId === currentRestaurantZoneId) ?? restaurantZones[0];
            setRestaurantZone(currentRestaurantZone);
            if (currentRestaurantZone) {
                tablesPosition.current = [];
                setTables(currentRestaurantZone?.tables ?? []);
            }
        }
    }, [currentRestaurantZoneId, restaurantZones]);

    useEffect(() => {
        if (!currentRestaurantZoneId && restaurantZones.length) {
            setCurrentRestaurantZoneId(restaurantZones[0].restaurantZoneId);

            setPosRestaurantZonesOfflineInLocalStorage(
                restaurantZones.map((restaurantZone) => ({
                    ...restaurantZone,
                    tables: restaurantZone.tables?.map((table: TableVm) => ({
                        tableNumber: table.tableNumber,
                        tableSize: table.tableSize,
                        tableShape: table.tableShape,
                        top: table.top,
                        left: table.left,
                        rotation: table.rotation,
                        order: table.order,
                    })),
                })),
            );
        }
    }, [restaurantZones, currentRestaurantZoneId]);

    useEffect(() => {
        if (tables.length) {
            tablesPosition.current = [];
            tables?.forEach((table) => tablesPosition.current.push(table));
        }
    }, [tables]);

    useEffect(() => {
        setRefreshRestaurantZones(refreshRestaurantZones);
    }, []);

    const tabs: Array<Tab> = [];
    restaurantZones.forEach((restaurantZone) => {
        tabs.push({
            label: restaurantZone.name,
            value: restaurantZone.restaurantZoneId,
            onClick: () => onChangeTabZone(restaurantZone.restaurantZoneId),
        });
    });

    const cancelEditRestaurantZones = () => {
        setEditing(false);
        changeRestaurantTablesAndZones(false);
        refreshRestaurantZones();
    };

    const saveChanges = async () => {
        setLoading(true);
        const response = await changeRestaurantZoneApi({
            restaurantZoneId: restaurantZone.restaurantZoneId,
            name: restaurantZone.name,
            tables: tables.map((table) => {
                const tablePosition = tablesPosition.current.find((_table) => _table.tableNumber === table.tableNumber);
                return {
                    tableNumber: table.tableNumber,
                    tableSize: table.tableSize,
                    tableShape: table.tableShape,
                    top: tablePosition?.top,
                    left: tablePosition?.left,
                    rotation: tablePosition?.rotation,
                };
            }),
        });
        setLoading(false);
        if (!response.ok) return alertKnownErrorOrSomethingWentWrong(response);
        setEditing(false);
        refreshRestaurantZones();
        changeRestaurantTablesAndZones(false);
    };

    const addTable = (table: any) => {
        setTables((currentTables) => [...currentTables, table]);
        tablesPosition.current.push(table);
        changeRestaurantTablesAndZones(true);
    };

    const changeTables = (newTables: any) => setTables(newTables);

    const createRestaurantZoneDialogSucceeded = () => {
        closeCreateRestaurantZoneDialog();
        refreshRestaurantZones();
        setEditing(false);
    };

    const onSuccessEditRestaurantZoneMenu = () => {
        refreshRestaurantZones();
        setEditing(false);
    };

    const onChangeTabZone = (tab: RestaurantZoneId) => {
        setCurrentRestaurantZoneId(tab);
        changeRestaurantTablesAndZones(false);
    };

    if (loadingRestaurantZones) {
        return (
            <div className={classes.loadingContainer}>
                <Loader loading={true} size={40} />
            </div>
        );
    }

    if (!loadingRestaurantZones && !restaurantZones.length) {
        return (
            <div className={classes.container}>
                <div className={classes.noRestaurantZonesContainer}>
                    <h1 className={classes.noRestaurantZonesTitle}>{translate('There is no restaurant zones created')}</h1>
                    {!editDisabled && (
                        <Button onClick={() => openCreateRestaurantZoneDialog({ onSuccess: createRestaurantZoneDialogSucceeded })} classes={{ button: (classes as any).button }}>
                            {translate('Create Restaurant Zone')}
                        </Button>
                    )}
                </div>
            </div>
        );
    }

    return (
        <div className={classes.content}>
            <div className={classes.topContainer}>
                <PosTableStates />
                <div className={(classes as any).buttonsContainer}>
                    {!editing && !!restaurantZones.length && !editDisabled && (
                        <SecuredContent rolePermission={RolePermissions.EDIT_RESTAURANT_ZONES}>
                            <Button icon onClick={() => setEditing(true)}>
                                <EditIcon title={translate('Edit')} />
                            </Button>
                        </SecuredContent>
                    )}
                    {restaurantTablesHasChanged && (
                        <Button icon onClick={saveChanges}>
                            <FlappyDiskIcon title={translate('Save')} />
                        </Button>
                    )}
                    {editing && <EditRestaurantZonesMenu onSave={saveChanges} onSuccess={onSuccessEditRestaurantZoneMenu} onAddTable={addTable} onCancel={cancelEditRestaurantZones} />}
                </div>
            </div>
            <div className={classes.tabsContainer}>
                <UpdatingContentProgress loading={loading} top />
                {!!restaurantZones.length && !!currentRestaurantZoneId && (
                    <Tabs tabs={tabs} classes={{ tabs: classes.tabs, tab: classes.tab, selectedTab: classes.chip, tabContent: classes.tabContent }}>
                        <div className={classes.container}>
                            <PosTables
                                tables={tables}
                                restaurantZoneId={currentRestaurantZoneId}
                                tablesPosition={tablesPosition}
                                editing={editing}
                                changeTables={changeTables}
                                refreshRestaurantZones={refreshRestaurantZones}
                            />
                        </div>
                        {tabs?.map((tab) => {
                            const restaurantZone = restaurantZones?.find((restaurantZone: RestaurantZoneVm) => restaurantZone.restaurantZoneId === tab.value);
                            if (!restaurantZone) return null;

                            return (
                                <div className={classes.container} key={restaurantZone.restaurantZoneId}>
                                    <PosTables
                                        tables={tables}
                                        restaurantZoneId={currentRestaurantZoneId}
                                        tablesPosition={tablesPosition}
                                        editing={editing}
                                        changeTables={changeTables}
                                        refreshRestaurantZones={refreshRestaurantZones}
                                    />
                                </div>
                            );
                        })}
                    </Tabs>
                )}
            </div>
        </div>
    );
}

function handleApiError(response: ApiErrorResponse, setData: any) {
    if (response.problem === 'NETWORK_ERROR') {
        const restaurantZonesOffline = getPosRestaurantZonesOfflineInLocalStorage();
        setData(restaurantZonesOffline);
        return;
    }
    return alertKnownErrorOrSomethingWentWrong(response);
}

const useStyles = makeStyles((theme) => ({
    container: {
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        alignItems: 'center',
        backgroundColor: 'transparent',
        borderRadius: 15,
        paddingBottom: 20,
        flexShrink: 0,
        [theme.breakpoints.down('sm')]: {
            backgroundColor: 'transparent',
            border: 0,
            minHeight: '0',
            height: 'fit-content',
        },
    },
    loadingContainer: {
        width: '100',
        height: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    tabContent: {
        height: '65vh',
    },
    tabsContainer: {
        width: '100%',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        position: 'relative',
        padding: '12px 0',
    },
    tab: {
        alignSelf: 'center',
        borderRadius: 4,
        height: 30,
        width: 'fit-content',
        padding: '4px 24px',
        fontFamily: theme.typography.medium,
        fontWeight: 500,
        fontSize: 14,
        textAlign: 'center',
        borderBottom: 'unset',
        '&:hover': {
            backgroundColor: theme.palette.surface.brandContrast,
            color: theme.palette.text.invert,
        },
    },
    chip: {
        backgroundColor: theme.palette.surface.brandContrast,
        color: theme.palette.text.invert,
        '&:hover': {
            backgroundColor: theme.palette.surface.brandContrast,
            color: theme.palette.text.invert,
        },
    },
    tabs: {
        width: 'fit-content',
        maxWidth: '60%',
        alignSelf: 'center',
        position: 'fixed',
        bottom: 0,
        padding: 12,
        gap: 12,
        backgroundColor: '#E2E7EB',
        zIndex: 9999,
        borderRadius: '8px 8px 0 0',
        [theme.breakpoints.down('sm')]: {
            width: '100vw',
            maxWidth: '100vw',
            borderRadius: '0',
        },
    },
    content: {
        width: '100%',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
    },
    noRestaurantZonesContainer: {
        width: '100%',
        height: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
    },
    noRestaurantZonesTitle: {
        fontFamily: theme.typography.medium,
        fontStyle: 'italic',
        fontSize: 40,
        marginBottom: 40,
        [theme.breakpoints.down('sm')]: {
            fontSize: 28,
        },
    },
    saveChangesButton: {
        [theme.breakpoints.up('sm')]: {
            position: 'absolute',
            top: 16,
            right: 50,
        },
    },
    topContainer: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        [theme.breakpoints.down('sm')]: {
            padding: '0 24px',
        },
    },
}));

type Props = {
    editDisabled?: boolean;
};
