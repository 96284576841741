import { IconButton, makeStyles } from '@material-ui/core';
import { useState } from 'react';
import * as React from 'react';
import { Button } from 'src/components/Button';
import { translate } from 'src/i18n/translate';
import { BugIcon } from 'src/icons/BugIcon';
import { EditIcon } from 'src/icons/EditIcon';
import { SimpleTerminalIcon } from 'src/icons/SimpleTerminalIcon';
import { TrashcanIcon } from 'src/icons/TrashcanIcon';
import { ChangePaymentTerminalDialog } from 'src/scenes/letseatmanager/device/paymentTerminals/ChangePaymentTerminalDialog';
import { RemovePaymentTerminalDialog } from 'src/scenes/letseatmanager/device/paymentTerminals/RemovePaymentTerminalDialog';
import { PaymentTerminalId } from 'src/types/Id';
import type { PaymentTerminalVm } from 'src/types/PaymentTerminalVm';
import { toShortId } from 'src/utils/uuid/toShortId';

export function PaymentTerminalListItem({ paymentTerminal, onChange, onClickTest }: Props): React.ReactElement {
    const classes = useStyles();

    const [changePaymentTerminalDialogState, setChangePaymentTerminalDialogState] = useState<{ open: boolean; paymentTerminalId?: PaymentTerminalId }>({ open: false, paymentTerminalId: undefined });
    const [removePaymentTerminalDialogState, setRemovePaymentTerminalDialogState] = useState<{ open: boolean; paymentTerminalId?: PaymentTerminalId }>({ open: false, paymentTerminalId: undefined });

    const openChangePaymentTerminalDialog = () => setChangePaymentTerminalDialogState({ open: true, paymentTerminalId: paymentTerminal.paymentTerminalId });

    const closeChangePaymentTerminalDialog = () => setChangePaymentTerminalDialogState({ open: false, paymentTerminalId: undefined });

    const openRemovePaymentTerminalDialog = () => setRemovePaymentTerminalDialogState({ open: true, paymentTerminalId: paymentTerminal.paymentTerminalId });

    const closeRemovePaymentTerminalDialog = () => setRemovePaymentTerminalDialogState({ open: false, paymentTerminalId: undefined });

    return (
        <div className={classes.container}>
            <ChangePaymentTerminalDialog
                open={changePaymentTerminalDialogState.open}
                paymentTerminalId={changePaymentTerminalDialogState.paymentTerminalId}
                onClose={closeChangePaymentTerminalDialog}
                onSuccess={onChange}
            />
            <RemovePaymentTerminalDialog open={removePaymentTerminalDialogState.open} paymentTerminal={paymentTerminal} onClose={closeRemovePaymentTerminalDialog} onSuccess={onChange} />
            <div className={classes.paymentTerminalInfo}>
                <div className={classes.paymentTerminalNameContainer}>
                    <SimpleTerminalIcon />
                    <span className={classes.paymentTerminalName}>{paymentTerminal.deviceName || toShortId(paymentTerminal.paymentTerminalId)}</span>
                </div>
            </div>
            <div className={classes.actions}>
                {/* TODO: remove useOcaPaymentTerminalEnabled && when qpay payment terminal provider uses new code */}
                <IconButton onClick={onClickTest} style={{ width: 45, height: 45 }}>
                    <BugIcon color={'#6C7076'} />
                </IconButton>
                <Button icon classes={{ button: classes.button }} onClick={openChangePaymentTerminalDialog}>
                    <EditIcon title={translate('Change info')} />
                </Button>
                <Button icon onClick={openRemovePaymentTerminalDialog}>
                    <TrashcanIcon title={translate('Remove paymentTerminal')} />
                </Button>
            </div>
        </div>
    );
}

const useStyles = makeStyles((theme) => ({
    container: {
        display: 'flex',
        flexDirection: 'row',
        width: '100%',
        alignItems: 'center',
        justifyContent: 'space-between',
    },
    paymentTerminalInfo: {
        display: 'flex',
        flexDirection: 'column',
        gap: 10,
    },
    paymentTerminalName: {
        fontFamily: theme.typography.regular,
        color: '#0D3037',
        fontSize: 16,
    },
    lastConnection: {
        color: '#8A95A5',
        fontFamily: theme.typography.regular,
        fontSize: 10,
    },
    actions: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        gap: 10,
    },
    button: {
        color: '#616B79',
    },
    paymentTerminalNameContainer: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        gap: 10,
    },
}));

type Props = {
    paymentTerminal: PaymentTerminalVm;
    onChange?: any;
    onClickTest?: any;
};
