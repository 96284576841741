import { makeStyles } from '@material-ui/core';
import MUIDataTable from 'mui-datatables';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { findSupportContactsApi, SupportContactsVm } from 'src/api/letseatadmin/supportContact/findSupportContactsApi';
import { removeSupportContactApi } from 'src/api/letseatadmin/supportContact/removeSupportContactApi';
import { app2 } from 'src/app2';
import { ChangeToolbarButton } from 'src/components/mui-datatables/ChangeToolbarButton';
import { CreateToolbarButton } from 'src/components/mui-datatables/CreateToolbarButton';
import { RefreshToolbarButton } from 'src/components/mui-datatables/RefreshToolbarButton';
import { RemoveToolbarButton } from 'src/components/mui-datatables/RemoveToolbarButton';
import { UpdatingContentProgress } from 'src/components/UpdatingContentProgress';
import { translate } from 'src/i18n/translate';
import { ChangeSupportContactDialog } from 'src/scenes/letseatadmin/supportContact/ChangeSupportContactDialog';
import { CreateSupportContactDialog } from 'src/scenes/letseatadmin/supportContact/CreateSupportContactDialog';
import type { SupportContactId } from 'src/types/Id';
import { alertKnownErrorOrSomethingWentWrong } from 'src/utils/alert/alertKnownErrorOrSomethingWentWrong';
import { getSelectedRowsData } from 'src/utils/mui-datatables/getSelectedRowsData';
import { useAction } from 'src/utils/react/useAction';
import { useSelector } from 'src/utils/react/useSelector';

export function SupportPhoneNumbersPage(): React.ReactElement {
    const classes = useStyles();
    const viewUser = useSelector((state) => state.authentication.viewUser);

    const [loading, setLoading] = useState(false);
    const [vm, setVm] = useState({ supportContacts: [] } as SupportContactsVm);
    const [createSupportContactDialogState, setCreateSupportContactDialogState] = useState({ open: false });
    const [changeSupportContactDialogState, setChangeSupportContactDialogState] = useState({ open: false, supportContactId: undefined });

    const setTitle = useAction(app2.actions.setTitle);

    useEffect(() => {
        setTitle(translate('Support Phone Numbers'));
        load();
    }, []);

    const load = async () => {
        setLoading(true);
        const response = await findSupportContactsApi();
        setLoading(false);
        if (!response.ok) {
            alertKnownErrorOrSomethingWentWrong(response);
            return;
        }
        setVm(response.data);
    };

    const removeSupportContacts = async (supportContactIds: Array<SupportContactId>) => {
        setLoading(true);
        for (const supportContactId of supportContactIds) {
            const response = await removeSupportContactApi({ supportContactId });
            if (!response.ok) {
                setLoading(false);
                alertKnownErrorOrSomethingWentWrong(response);
                await load();
                return;
            }
        }
        await load();
    };

    const handleClickNew = () => {
        setCreateSupportContactDialogState({ open: true });
    };

    return (
        <div className={classes.container}>
            <UpdatingContentProgress loading={loading} />
            <CreateSupportContactDialog open={createSupportContactDialogState.open} onClose={() => setCreateSupportContactDialogState({ open: false })} onSupportContactCreated={load} />
            <ChangeSupportContactDialog
                open={changeSupportContactDialogState.open}
                supportContactId={changeSupportContactDialogState.supportContactId}
                onClose={() => setChangeSupportContactDialogState({ open: false, supportContactId: undefined })}
                onChangeSupportContact={load}
            />

            <MUIDataTable
                /* @ts-ignore */
                className={classes.table}
                data={
                    vm.supportContacts &&
                    vm.supportContacts.map((supportContact) => {
                        return {
                            supportContactId: supportContact.supportContactId,
                            name: supportContact.name,
                            phoneNumber: supportContact.phoneNumber,
                            available: supportContact.available ? translate('Available') : translate('Not Available'),
                        };
                    })
                }
                columns={[
                    {
                        name: 'supportContactId',
                        label: '',
                        options: {
                            display: 'excluded',
                            filter: false,
                        },
                    },
                    {
                        name: 'name',
                        label: translate('Name'),
                        options: {
                            filter: false,
                        },
                    },
                    {
                        name: 'phoneNumber',
                        label: translate('Phone Number'),
                        options: {
                            filter: false,
                        },
                    },
                    {
                        name: 'available',
                        label: translate('Available'),
                        options: {
                            filter: false,
                        },
                    },
                ]}
                options={{
                    responsive: 'standard',
                    tableBodyMaxHeight: '500px',
                    selectableRows: !viewUser ? 'multiple' : 'none',
                    filterType: 'checkbox',
                    rowsPerPage: 100,
                    customToolbar: () => (
                        <>
                            <RefreshToolbarButton onClick={load} />
                            <CreateToolbarButton onClick={handleClickNew} />
                        </>
                    ),
                    customToolbarSelect: (selectedRows, displayData) => {
                        const selectedRowsData = getSelectedRowsData(selectedRows, displayData);
                        const selectedSupportContactIds = selectedRowsData.map((d: any) => d[0]);
                        return (
                            <div className={classes.toolbar}>
                                {selectedSupportContactIds.length === 1 && (
                                    <ChangeToolbarButton
                                        onClick={() => {
                                            if (selectedSupportContactIds.length === 1) setChangeSupportContactDialogState({ open: true, supportContactId: selectedSupportContactIds[0] });
                                        }}
                                    />
                                )}
                                <RemoveToolbarButton
                                    onClick={async () => {
                                        const remove = window.confirm(
                                            selectedSupportContactIds.length === 1
                                                ? 'Are you sure you want to remove the selected supportContacts'
                                                : `Are you sure you want to remove the selected ${selectedSupportContactIds.length} supportContacts`,
                                        );
                                        if (remove) {
                                            await removeSupportContacts(selectedSupportContactIds);
                                        }
                                    }}
                                />
                            </div>
                        );
                    },
                    // filterList: [[], [], [], [], ['MEXICO']],
                }}
            />
        </div>
    );
}

const useStyles = makeStyles((theme) => ({
    container: {
        position: 'relative',
    },
    table: {
        whiteSpace: 'nowrap',
        '& tr': { cursor: 'pointer' },
        '& tr:hover': { backgroundColor: 'rgba(0, 0, 0, 0.07) !important' },
    },
    toolbar: {
        paddingRight: theme.spacing(3),
    },
}));
