import * as React from 'react';
import { useFormContext } from 'react-hook-form';

export function FormHiddenField({ name, ...props }: Props): React.ReactElement {
    const { register } = useFormContext();
    return <input type='hidden' ref={register()} name={name} {...props} />;
}

export type Props = {
    name: string;
    defaultValue?: string;
};
