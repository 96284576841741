import { Day, Days } from 'src/constants/Day';

export function sortDays(values: Array<Day>): Array<Day> {
    return [...values].sort((a: Day, b: Day) => {
        switch (a) {
            case Days.MONDAY:
                return -1;
            case Days.TUESDAY:
                switch (b) {
                    case Days.MONDAY:
                        return 1;
                    default:
                        return -1;
                }
            case Days.WEDNESDAY:
                switch (b) {
                    case Days.MONDAY:
                    case Days.TUESDAY:
                        return 1;
                    default:
                        return -1;
                }
            case Days.THURSDAY:
                switch (b) {
                    case Days.MONDAY:
                    case Days.TUESDAY:
                    case Days.WEDNESDAY:
                        return 1;
                    default:
                        return -1;
                }
            case Days.FRIDAY:
                switch (b) {
                    case Days.MONDAY:
                    case Days.TUESDAY:
                    case Days.WEDNESDAY:
                    case Days.THURSDAY:
                        return 1;
                    default:
                        return -1;
                }
            case Days.SATURDAY:
                switch (b) {
                    case Days.MONDAY:
                    case Days.TUESDAY:
                    case Days.WEDNESDAY:
                    case Days.THURSDAY:
                    case Days.FRIDAY:
                        return 1;
                    default:
                        return -1;
                }
            default:
                return 1;
        }
    });
}
