import { letseatmanagerApiMethod } from 'src/api/letseatmanagerApi';
import type { ApiSauceResponse } from 'src/api/types/ApiSauceResponse';
import { CustomerDisplayScreenOrderStep } from 'src/constants/CustomerDisplayScreenOrderStep';
import { CustomerDisplayScreenCartItemVm } from 'src/types/CustomerDisplayScreenCartItemVm';
import { RestaurantId } from 'src/types/Id';
import { PosTip } from 'src/types/PosTip';

export async function sendOrderUpdateToCustomerDisplayScreenApi(request: SendOrderUpdateToCustomerDisplayScreenApiRequest): ApiSauceResponse<void> {
    return letseatmanagerApiMethod('pos/sendOrderUpdateToCustomerDisplayScreenApi', request);
}

export type SendOrderUpdateToCustomerDisplayScreenApiRequest = {
    restaurantId: RestaurantId;
    orderItems: Array<CustomerDisplayScreenCartItemVm>;
    tips?: Array<PosTip>;
    orderStep: CustomerDisplayScreenOrderStep | undefined;
    subtotal?: string;
    total: string;
    productDiscount?: string;
    posDiscount?: string;
    promoCodeSubtotal?: string;
    promoCodeDiscount?: string;
    promoCodeCredits?: string;
    promoCode?: string;
    usedCustomerCredits?: string;
};
