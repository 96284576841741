import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import { Form, FormPercentNumberField, FormSwitch, FormTextField } from '@pidedirecto/ui/form';
import { useForm } from '@pidedirecto/ui/hooks';
import { BigNumber } from 'bignumber.js';
import { useEffect, useState } from 'react';
import * as React from 'react';
import { addPaymentMethodApi } from 'src/api/letseatmanager/restaurant/addPaymentMethodApi';
import { Button } from 'src/components/Button';
import { Channels } from 'src/components/Channels';
import { Dialog } from 'src/components/Dialog';
import { DialogActions } from 'src/components/DialogActions';
import { FormMexicanPaymentMethodCodeSelect } from 'src/components/form/FormMexicanPaymentMethodCodeSelect';
import { FormPaymentMethodSelect } from 'src/components/form/FormPaymentMethodSelect';
import { App, Apps } from 'src/constants/App';
import { CustomPaymentMethodIcon, CustomPaymentMethodIcons } from 'src/constants/CustomPaymentMethodIcons';
import { MexicanPaymentMethodCode, MexicanPaymentMethodCodes } from 'src/constants/MexicanPaymentMethodCode';
import { PaymentMethods } from 'src/constants/PaymentMethod';
import { translate } from 'src/i18n/translate';
import { PaymentMethodIcon } from 'src/scenes/letseatmanager/theRestaurant/PaymentMethodIcon';
import { alertKnownErrorOrSomethingWentWrong } from 'src/utils/alert/alertKnownErrorOrSomethingWentWrong';
import { isMexico } from 'src/utils/country/isMexico';
import { isCashMexicanPaymentMethodCode } from 'src/utils/mexicanPaymentMethodCode/isCashMexicanPaymentMethodCode';
import { classNames } from 'src/utils/react/classNames';
import { useSelector } from 'src/utils/react/useSelector';

export function CreateCustomPaymentMethodDialog({ open, onClose, onSuccess }: Props): React.ReactElement {
    const form = useForm();
    const classes = useStyles();

    const [loading, setLoading] = useState(false);
    const [channels, setChannels] = useState<Array<App>>([]);
    const [iconSelected, setIconSelected] = useState<CustomPaymentMethodIcon>();
    const [showNameErrorMessage, setShowNameErrorMessage] = useState(false);
    const [showIconErrorMessage, setShowIconErrorMessage] = useState(false);

    const restaurantId = useSelector((state) => state.app.restaurantId);
    const restaurantCountry = useSelector((state) => state.app.restaurant.country);

    const name = form.watch('name');
    const commission = form.watch('commission');
    const paymentReferenceEnabled = form.watch('paymentReferenceEnabled');
    const mexicanPaymentMethodCode = form.watch('mexicanPaymentMethodCode');
    const paymentMethod = form.watch('paymentMethod');

    const validChannels: Array<App> = [Apps.PIDEDIRECTO, Apps.PIDEDIRECTOPOS];
    const excludedChannels = Object.values(Apps).filter((app) => !validChannels.includes(app));

    useEffect(() => {
        if (paymentReferenceEnabled) {
            handleSelectChannels([Apps.PIDEDIRECTOPOS]);
        }
    }, [paymentReferenceEnabled]);

    const handleSubmit = async () => {
        if (name?.length >= 15) {
            form.setError('name', { message: translate('The name must be less than 15 characters') });
            return;
        }

        setShowIconErrorMessage(!iconSelected);

        if (showIconErrorMessage || showNameErrorMessage) return;

        setLoading(true);
        const response = await addPaymentMethodApi({
            restaurantId,
            name,
            paymentMethod: isMexico(restaurantCountry) ? getPaymentMethodByMexicanPaymentMethodCode(mexicanPaymentMethodCode) : paymentMethod,
            mexicanPaymentMethodCode: isMexico(restaurantCountry) ? mexicanPaymentMethodCode : undefined,
            paymentReferenceEnabled,
            commission: BigNumber(commission).multipliedBy(100).toString(),
            channels: channels,
            icon: iconSelected,
        });

        setLoading(false);
        if (!response.ok) {
            alertKnownErrorOrSomethingWentWrong(response);
            return;
        }
        onSuccess();
        onClose();
    };

    const getPaymentMethodByMexicanPaymentMethodCode = (mexicanPaymentMethodCode: MexicanPaymentMethodCode) => {
        if (isCashMexicanPaymentMethodCode(mexicanPaymentMethodCode)) {
            return PaymentMethods.CASH;
        }
        return PaymentMethods.CREDIT_CARD;
    };

    const handleSelectChannels = (channels: Array<App>) => setChannels(channels);

    const onSelectCustomIcon = (customIcon: CustomPaymentMethodIcon) => {
        if (iconSelected === customIcon) {
            setIconSelected(undefined);
            return;
        }
        setIconSelected(customIcon);
    };

    return (
        <Dialog open={open} title={translate('Add payment method')} onClose={onClose} loading={loading}>
            <Form form={form} onSubmit={handleSubmit}>
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <FormTextField
                            disabled={loading}
                            name='name'
                            label={translate('Payment Method Name')}
                            required
                            rules={{ maxLength: 15, message: translate('The name must be less than 15 characters') }}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        {!isMexico(restaurantCountry) && <FormPaymentMethodSelect disabled={loading} name='paymentMethod' label={translate('Payment Method')} required />}
                        {isMexico(restaurantCountry) && (
                            <FormMexicanPaymentMethodCodeSelect
                                name={'mexicanPaymentMethodCode'}
                                label={translate('Payment method for billing')}
                                defaultValue={MexicanPaymentMethodCodes.CASH}
                                required
                            />
                        )}
                    </Grid>
                    <Grid item xs={12}>
                        <FormSwitch disabled={loading} name='paymentReferenceEnabled' label={translate('Request payment reference')} required />
                    </Grid>
                    <Grid item xs={12}>
                        <FormPercentNumberField disabled={loading} name='commission' label={translate('Commission')} />
                    </Grid>
                    <Grid item xs={12}>
                        <Channels onChange={handleSelectChannels} excludedChannels={excludedChannels} disabled={!!paymentReferenceEnabled} defaultSelectedChannels={channels} />
                    </Grid>
                </Grid>
                <div className={classes.selectIconContainer}>
                    <p className={classes.label}>{translate('Icon')}</p>
                    <div className={classes.iconsContainer}>
                        {Object.values(CustomPaymentMethodIcons).map((customPaymentMethodIcon) => (
                            <Button
                                secondary
                                classes={{ button: classNames(iconSelected === customPaymentMethodIcon ? classes.iconButtonSelected : classes.iconButton) }}
                                onClick={() => onSelectCustomIcon(customPaymentMethodIcon)}
                                key={`${customPaymentMethodIcon}-key`}
                            >
                                <PaymentMethodIcon icon={customPaymentMethodIcon} />
                            </Button>
                        ))}
                    </div>
                    {showIconErrorMessage && <p className={classes.warning}>{translate('Select an icon')}</p>}
                </div>
                <DialogActions>
                    <Button onClick={onClose} disabled={loading} secondary>
                        {translate('Cancel')}
                    </Button>
                    <Button onClick={handleSubmit} disabled={loading}>
                        {loading ? translate('Adding') : translate('Add')}
                    </Button>
                </DialogActions>
            </Form>
        </Dialog>
    );
}

const useStyles = makeStyles((theme) => ({
    form: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    selectIconContainer: {
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        marginTop: 12,
    },
    label: {
        fontFamily: theme.typography.regular,
        color: '#2E3748',
        fontSize: 14,
    },
    iconsContainer: {
        width: '100%',
        display: 'flex',
        flexDirection: 'row',
        gap: 12,
        padding: 4,
        overflowY: 'scroll',
        '&::-webkit-scrollbar': {
            display: 'none',
        },
    },
    iconButton: {
        display: 'flex',
        borderRadius: '100%',
        width: 50,
        maxHeight: 50,
        color: '#616B79',
    },
    iconButtonSelected: {
        borderStyle: 'solid',
        borderWidth: '2px',
        borderColor: '#06B7A2',
        borderRadius: '100%',
        width: 50,
        maxHeight: 50,

        '&:hover': {
            borderStyle: 'solid',
            borderWidth: '2px',
            borderColor: '#06B7A2',
        },
    },
    warning: {
        color: '#E32F45',
        fontFamily: theme.typography.regular,
        fontSize: 12,
    },
}));

type Props = {
    open: boolean;
    onClose: any;
    onSuccess: any;
};
