import { makeStyles } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import * as React from 'react';
import { useState } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import { Button } from 'src/components/Button';
import { translate } from 'src/i18n/translate';
import { CloseIcon } from 'src/icons/CloseIcon';
import { AddProductCancellationReasonDialog } from 'src/scenes/letseatmanager/theRestaurant/pos/AddProductCancellationReasonDialog';
import { RemoveProductCancellationReasonDialog } from 'src/scenes/letseatmanager/theRestaurant/pos/RemoveProductCancellationReasonDialog';
import type { CustomProductCancellationReason } from 'src/types/Id';

export function ProductCancellationReasonsSettings({ onSuccess }: Props): React.ReactElement {
    const classes = useStyles();

    const { control } = useFormContext();

    const [addProductCancellationReasonDialogState, setAddProductCancellationReasonDialogState] = useState({ open: false });
    const [removeProductCancellationReasonDialogState, setRemoveProductCancellationReasonDialogState] = useState<{ open: boolean; reason: CustomProductCancellationReason | undefined }>({
        open: false,
        reason: undefined,
    });

    const productCancellationReasons = useWatch<Array<{ reason: CustomProductCancellationReason }>>({ name: 'productCancellationReasons', control });

    const openAddProductCancellationReasonDialog = () => setAddProductCancellationReasonDialogState({ open: true });

    const closeAddProductCancellationReasonDialog = () => setAddProductCancellationReasonDialogState({ open: false });

    const openRemoveProductCancellationReasonDialog = (reason: CustomProductCancellationReason) => setRemoveProductCancellationReasonDialogState({ open: true, reason });

    const closeRemoveProductCancellationReasonDialog = () => setRemoveProductCancellationReasonDialogState({ open: false, reason: undefined });

    return (
        <>
            <AddProductCancellationReasonDialog open={addProductCancellationReasonDialogState.open} onClose={closeAddProductCancellationReasonDialog} onSuccess={onSuccess} />
            <RemoveProductCancellationReasonDialog
                open={removeProductCancellationReasonDialogState.open}
                reason={removeProductCancellationReasonDialogState.reason}
                onSuccess={onSuccess}
                onClose={closeRemoveProductCancellationReasonDialog}
            />
            <div className={classes.section}>
                <h2 className={classes.subtitle}>{translate('Cancellation Product Reasons')}</h2>
                <div className={classes.itemsContainer}>
                    {productCancellationReasons?.map(
                        (
                            productCancellationReason: {
                                reason: CustomProductCancellationReason;
                            },
                            idx: number,
                        ) => (
                            <div className={classes.cashRegisterCustomReasonItem} key={idx}>
                                {productCancellationReason.reason}
                                <Button icon onClick={() => openRemoveProductCancellationReasonDialog(productCancellationReason.reason)} classes={{ button: classes.iconButton }}>
                                    <CloseIcon size={14} />
                                </Button>
                            </div>
                        ),
                    )}
                </div>
                <Button onClick={openAddProductCancellationReasonDialog} outlined>
                    <AddIcon />
                    {translate('Add Cancellation Product Reason')}
                </Button>
            </div>
        </>
    );
}

const useStyles = makeStyles((theme) => ({
    subtitle: {
        fontFamily: theme.typography.medium,
        fontSize: 18,
        color: theme.palette.secondary.contrastText,
        margin: 0,
        display: 'flex',
        flexDirection: 'row',
    },
    section: {
        width: '100%',
        borderRadius: 5,
        border: `1px solid ${theme.palette.secondary.dark}`,
        display: 'flex',
        flexDirection: 'column',
        padding: 15,
        gap: 20,
    },
    itemsContainer: {
        display: 'flex',
        width: '100%',
        flexWrap: 'wrap',
        flexDirection: 'row',
        alignItems: 'center',
        gap: 10,
    },
    cashRegisterCustomReasonItem: {
        fontFamily: theme.typography.medium,
        fontSize: 15,
        minWidth: 140,
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        border: '1px solid #676060B2',
        borderRadius: 6,
        padding: '5px 10px',
        gap: 5,
        height: '100%',
    },
    iconButton: {
        width: 'fit-content',
        height: 'fit-content',
        borderRadius: '50%',
        minHeight: 26,
        padding: 0,
    },
}));

type Props = {
    onSuccess: any;
};
