import { BigNumber } from 'bignumber.js';
import { MexicanPaymentMethodCode } from 'src/constants/MexicanPaymentMethodCode';
import type { PaymentMethod } from 'src/constants/PaymentMethod';
import type { PaymentTerminalProvider } from 'src/constants/PaymentTerminalProvider';
import { posReducer } from 'src/reducers/posReducer';
import { type CustomPaymentMethod, type PaymentTerminalId, type PaymentTerminalPaymentId } from 'src/types/Id';
import type { RestaurantCurrency } from 'src/types/RestaurantCurrency';
import { convertAmountToMainCurrency } from 'src/utils/order/convertAmountToMainCurrency';
import { useAction } from 'src/utils/react/useAction';
import { useSelector } from 'src/utils/react/useSelector';
import { newUuid } from 'src/utils/uuid/newUuid';

export function useAddPosPayment(): Result {
    const payments = useSelector((state) => state.pos.payments);
    const totalPaid = useSelector((state) => state.pos.totalPaid);
    const selectedCurrency = useSelector((state) => state.pos.selectedCurrency);

    const setPayments = useAction(posReducer.actions.setPayments);
    const setTotalPaid = useAction(posReducer.actions.setTotalPaid);

    const addPosPayment: Result['addPosPayment'] = (payment) => {
        const amount = convertAmountToMainCurrency(payment.amount, selectedCurrency);
        const restaurantCurrencyAmount = selectedCurrency ? payment.amount : undefined;
        setPayments([
            ...payments,
            {
                ...payment,
                amount,
                restaurantCurrencyAmount,
                id: payment.id ?? newUuid(),
                restaurantCurrency: selectedCurrency,
                paymentReference: payment?.paymentReference,
                mexicanPaymentMethodCode: payment.mexicanPaymentMethodCode,
            },
        ]);
        setTotalPaid(
            BigNumber(totalPaid ?? 0)
                .plus(amount)
                .toString(),
        );
    };

    return { addPosPayment };
}

type Result = {
    addPosPayment: (arg1: {
        paymentTerminalPaymentId?: PaymentTerminalPaymentId;
        paymentTerminalId?: PaymentTerminalId;
        paymentTerminalProvider?: PaymentTerminalProvider;
        amount: string;
        id?: string;
        paymentMethod: PaymentMethod;
        mexicanPaymentMethodCode?: MexicanPaymentMethodCode;
        paymentReference?: string;
        customPaymentMethod?: CustomPaymentMethod;
        customerNumber?: number;
        restaurantCurrency?: RestaurantCurrency;
    }) => void;
};
