import { letseatmanagerApiMethod } from 'src/api/letseatmanagerApi';
import type { ApiSauceResponse } from 'src/api/types/ApiSauceResponse';
import type { RestaurantId } from 'src/types/Id';

export async function getRestaurantOpeningHoursApi(request: GetOpeningHoursApiRequest): ApiSauceResponse<RestaurantOpeningHoursApiResponse> {
    return letseatmanagerApiMethod('restaurant/getRestaurantOpeningHoursApi', request);
}

export type GetOpeningHoursApiRequest = {
    restaurantId: RestaurantId;
};

export type RestaurantOpeningHoursApiResponse = {
    hours?: string;
    sameOpeningHoursForAllDays?: boolean;
    pickupStationClosingTime?: string;
    pickupStationDeliveryTime?: string;
};
