import { makeStyles } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import * as React from 'react';
import { useEffect } from 'react';
import { useForm, useWatch } from 'react-hook-form';
import { changeTheRestaurantApi } from 'src/api/letseatmanager/theRestaurant/changeTheRestaurantApi';
import type { TheRestaurantVm } from 'src/api/letseatmanager/theRestaurant/getTheRestaurantApi';
import { DeprecatedFormPercentNumberField } from 'src/components/form/DeprecatedFormPercentNumberField';
import { Form } from 'src/components/form/Form';
import { FormSubmitButton } from 'src/components/form/FormSubmitButton';
import { FormSwitch } from 'src/components/form/FormSwitch';
import { UpdatingContentProgress } from 'src/components/UpdatingContentProgress';
import { translate } from 'src/i18n/translate';
import { appReducer } from 'src/reducers/appReducer';
import { useReloadRestaurant } from 'src/services/restaurant/useReloadRestaurant';
import { SentryService } from 'src/services/SentryService';
import { alertKnownErrorOrSomethingWentWrong } from 'src/utils/alert/alertKnownErrorOrSomethingWentWrong';
import { useAction } from 'src/utils/react/useAction';
import { useSelector } from 'src/utils/react/useSelector';

export function RestaurantAppsSettings({ loading, restaurant, onChangeRestaurant, setIsDirtyForm }: Props): React.ReactElement {
    const classes = useStyles();
    const form = useForm();
    const {
        control,
        formState,
        formState: { dirtyFields },
    } = form;

    const appOrdersCashbackEnabled = useWatch<boolean>({ name: 'appOrdersCashbackEnabled', control });

    const restaurantId = useSelector((state) => state.app.restaurantId);
    const appOrdersEnabled = useSelector((state) => state.app.restaurant?.appOrdersEnabled);
    const appLoading = useSelector((state) => state.app.loading);

    const showSnackbar = useAction(appReducer.actions.showSnackbar);

    const { reloadRestaurant } = useReloadRestaurant();

    useEffect(() => {
        if (!loading) {
            form.reset({
                restaurantId: restaurant.restaurantId ?? null,
                appOrdersCashbackEnabled: restaurant.appOrdersCashbackEnabled ?? null,
                appOrdersCashback: restaurant.appOrdersCashback ?? null,
            });
        }
    }, [loading]);

    useEffect(() => {
        setIsDirtyForm(Object.keys(dirtyFields).length !== 0);
    }, [formState]);

    const onSubmit = async (form: any) => {
        const response = await changeTheRestaurantApi({
            restaurantId,
            ...form,
        });

        if (!response.ok) {
            SentryService.logError('Failed to save the restaurant', { response });
            alertKnownErrorOrSomethingWentWrong(response);
            return;
        }

        await reloadRestaurant(restaurantId);
        await onChangeRestaurant();
        showSnackbar({ message: translate('Saved changes') });
    };

    return (
        <Paper className={classes.paper}>
            <UpdatingContentProgress loading={loading || appLoading} />
            <Form form={form} onSubmit={onSubmit}>
                <div className={classes.formContent}>
                    {appOrdersEnabled && (
                        <>
                            <Grid container spacing={3} className={(classes as any).section} style={{ padding: '20px 40px' }}>
                                <Grid item xs={12} md={6}>
                                    <FormSwitch name='appOrdersCashbackEnabled' label={translate('App Orders Cashback Enabled')} />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <DeprecatedFormPercentNumberField name='appOrdersCashback' label={translate('App Orders Cashback %')} disabled={!appOrdersCashbackEnabled} />
                                </Grid>
                            </Grid>
                        </>
                    )}
                </div>

                <Grid container justify='center' spacing={3}>
                    <Grid item>
                        <FormSubmitButton text={translate('Change')} disabled={loading || appLoading} classes={{ container: (classes as any).submitButton }} />
                    </Grid>
                </Grid>
            </Form>
        </Paper>
    );
}

const useStyles = makeStyles((theme) => ({
    paper: {
        position: 'relative',
    },
    formContent: {
        padding: theme.spacing(3),
    },
    subtitle: {
        fontFamily: theme.typography.medium,
        fontSize: 18,
        color: theme.palette.secondary.contrastText,
        margin: 0,
    },
    text: {
        fontFamily: theme.typography.regular,
        fontSize: 15,
    },
    zIndex: {
        zIndex: 1000,
    },
}));

type Props = {
    loading: boolean;
    restaurant: TheRestaurantVm;
    onChangeRestaurant: any;
    setIsDirtyForm: any;
};
