import { letseatmanagerApiMethod } from 'src/api/letseatmanagerApi';
import type { ApiSauceResponse } from 'src/api/types/ApiSauceResponse';
import type { DeviceVm } from 'src/types/DeviceVm';
import type { RestaurantId } from 'src/types/Id';

export async function findDevicesApi(request: FindDevicesApiRequest): ApiSauceResponse<Array<DeviceVm>> {
    return letseatmanagerApiMethod('device/findDevicesApi', request);
}

export type FindDevicesApiRequest = {
    restaurantId: RestaurantId;
};
