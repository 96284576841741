import { MUIDataTableColumn } from 'mui-datatables';
import * as React from 'react';
import { Table } from 'src/components/Table';
import { translate } from 'src/i18n/translate';
import { useFormatAsRestaurantCurrencyNumber } from 'src/services/restaurant/useFormatAsRestaurantCurrencyNumber';
import type { CashRegisterPosBusinessDayReportVm } from 'src/types/CashRegisterPosBusinessDayReportVm';
import { PosBusinessDaySalesByItem, type PosBusinessDayReportVm } from 'src/types/PosBusinessDayReportVm';

export function PosBusinessDayItemsTable({ posBusinessDayReport }: Props): React.ReactElement {
    const formatAsCurrencyNumber = useFormatAsRestaurantCurrencyNumber();

    const columns: Array<MUIDataTableColumn> = [
        {
            name: 'menuItemId',
            options: {
                display: 'excluded',
                filter: false,
            },
        },
        {
            name: 'name',
            label: translate('Name'),
            options: {
                filter: true,
            },
        },
        {
            name: 'quantity',
            label: translate('Amount'),
            options: {
                filter: false,
            },
        },
        {
            name: 'unitPrice',
            label: translate('Cost'),
            options: {
                filter: true,
            },
        },
        {
            name: 'total',
            label: translate('Total'),
            options: {
                filter: true,
            },
        },
        {
            name: 'percentageOfSales',
            label: translate('Percent of sales'),
            options: {
                filter: false,
                customBodyRender: (percentageOfSales: number) => `${Math.round(percentageOfSales * 100) / 100}%`,
            },
        },
    ];

    return (
        <div>
            <Table
                data={
                    posBusinessDayReport.salesByItemReport?.salesByItem?.map((item: PosBusinessDaySalesByItem) => {
                        return {
                            ...item,
                            name: item.name,
                            quantity: parseInt(item.orders),
                            unitPrice: formatAsCurrencyNumber(item.itemPrice),
                            total: formatAsCurrencyNumber(item.total),
                            percentageOfSales: item.percentageOfSales,
                        };
                    }) ?? []
                }
                columns={columns}
                options={{
                    responsive: 'standard',
                    selectableRows: 'none',
                    viewColumns: false,
                    print: false,
                    download: true,
                }}
            />
        </div>
    );
}

type Props = {
    posBusinessDayReport: PosBusinessDayReportVm | CashRegisterPosBusinessDayReportVm;
};
