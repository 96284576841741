import { letseatmanagerApiMethodPublic } from 'src/api/letseatmanagerApi';
import type { ApiSauceResponse } from 'src/api/types/ApiSauceResponse';
import { AwsCognitoUsername } from 'src/types/Id';

export async function ensureManagerCognitoUserExistsInDevEnvironmentApi(request: Request): ApiSauceResponse<void> {
    return letseatmanagerApiMethodPublic('authentication/ensureManagerCognitoUserExistsInDevEnvironmentApi', request);
}

type Request = {
    username: AwsCognitoUsername;
};
