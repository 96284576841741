import { makeStyles } from '@material-ui/core';
import * as React from 'react';
import { Tooltip } from 'src/components/Tooltip';

export function OrderPriceInfoRow({ title, orderInfo, tooltip }: Props): React.ReactElement {
    const classes = useStyles();

    if (tooltip) {
        return (
            <Tooltip text={tooltip}>
                <div className={classes.spaceBetween}>
                    <span className={classes.text} style={{ fontSize: 15 }}>
                        {title}
                    </span>
                    <span className={classes.text} style={{ fontSize: 15 }}>
                        {orderInfo}
                    </span>
                </div>
            </Tooltip>
        );
    }

    return (
        <div className={classes.spaceBetween}>
            <span className={classes.text} style={{ fontSize: 15 }}>
                {title}
            </span>
            <span className={classes.text} style={{ fontSize: 15 }}>
                {orderInfo}
            </span>
        </div>
    );
}

const useStyles = makeStyles((theme) => ({
    spaceBetween: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        width: '100%',
    },
    flexEnd: {
        width: '100%',
        display: 'flex',
        justifyContent: 'flex-end',
        fontFamily: theme.typography.regular,
        fontSize: 14,
        color: '#9fa1a1',
    },
    text: {
        fontFamily: theme.typography.regular,
        fontSize: 14,
        color: '#454A4A',
    },
}));

type Props = {
    title: string;
    tooltip?: string;
    orderInfo?: string | number;
};
