import { makeStyles } from '@material-ui/core';
import * as React from 'react';
import { memo } from 'react';
import { app2 } from 'src/app2';
import { Button } from 'src/components/Button';
import { translate } from 'src/i18n/translate';
import { AddIcon } from 'src/icons/AddIcon';
import type { MenuId } from 'src/types/Id';
import { useAction } from 'src/utils/react/useAction';

export const AddMenuCategory: React.ComponentType<Props> = memo<Props>(function AddMenuCategory({ menuId, loading, onSuccess }): React.ReactElement {
    const classes = useStyles();
    const openCreateMenuCategoryDialog = useAction(app2.actions.openCreateMenuCategoryDialog);

    return (
        <Button
            onClick={(event: any) => {
                if (loading) {
                    return;
                }
                openCreateMenuCategoryDialog({ menuId, position: 1, onSuccess });
                event.stopPropagation();
            }}
            classes={{ button: classes.button }}
        >
            <AddIcon />
            {translate('Add Category')}
        </Button>
    );
});

const useStyles = makeStyles((theme) => ({
    button: {
        fontSize: 18,
        borderRadius: 5,
        padding: '10px 15px',
    },
}));

export type Props = {
    menuId: MenuId;
    loading: boolean;
    onSuccess: () => any;
};
