import { letseatadminApiMethod } from 'src/api/letseatadminApi';
import type { ApiSauceResponse } from 'src/api/types/ApiSauceResponse';
import type { SupportContactId } from 'src/types/Id';

export async function findSupportContactsApi(): ApiSauceResponse<SupportContactsVm> {
    return letseatadminApiMethod('supportContact/findSupportContactsApi');
}

export type SupportContactsVm = {
    supportContacts: Array<SupportContactVm>;
};

type SupportContactVm = {
    supportContactId: SupportContactId;
    name?: string;
    phoneNumber?: string;
    available?: boolean;
    createdAt: Date;
    modifiedAt: Date;
};
