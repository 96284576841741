import { letseatmanagerApiMethod } from 'src/api/letseatmanagerApi';
import type { ApiSauceResponse } from 'src/api/types/ApiSauceResponse';
import type { RestaurantId, SupplyId } from 'src/types/Id';
import type { SupplierVm } from 'src/types/SupplierVm';

export async function createSupplierApi(request: CreateSupplierApiRequest): ApiSauceResponse<SupplierVm> {
    return letseatmanagerApiMethod('supplier/createSupplierApi', request);
}

export type CreateSupplierApiRequest = {
    restaurantId: RestaurantId;
    supplyIds: Array<SupplyId>;
    businessName: string;
    rfc: string;
    email: string;
    phoneNumber: string;
    pendingBalance: string;
    note?: string;
};
