import { makeStyles } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import UndoIcon from '@material-ui/icons/Undo';
import { BigNumber } from 'bignumber.js';
import { useState } from 'react';
import * as React from 'react';
import { GoogleMapsTrackDriverPosition } from 'src/components/googlemaps/GoogleMapsTrackDriverPosition';
import { OrderCardCustomerInfo } from 'src/components/orderCard/OrderCardCustomerInfo';
import { OrderCardDeliveryInfo } from 'src/components/orderCard/OrderCardDeliveryInfo';
import { OrderStatuses } from 'src/constants/OrderStatus';
import { translate } from 'src/i18n/translate';
import { MapIcon } from 'src/icons/MapIcon';
import { RouteIcon } from 'src/icons/RouteIcon';
import { PartialRefundOngoingOrderDialog } from 'src/scenes/letseatadmin/refundOngoingOrder/PartialRefundOngoingOrderDialog';
import type { OrderVm } from 'src/types/OrderVm';
import { isPosApp } from 'src/utils/app/isPosApp';
import { openDirections } from 'src/utils/linking/openDirections';
import { orderIsInternalDeliveryOrder } from 'src/utils/order/orderIsInternalDeliveryOrder';
import { isCashPayment } from 'src/utils/paymentMethod/isCashPayment';
import { classNames } from 'src/utils/react/classNames';
import { useSelector } from 'src/utils/react/useSelector';
import { requireValue } from 'src/utils/require/requireValue';

/** @deprecated */
export function DeprecatedOrderCardAdditionalInfo({ order }: Props): React.ReactElement {
    const classes = useStyles();

    const [showMore, setShowMore] = useState(false);
    const [showMap, setShowMap] = useState(false);
    const [refundOngoingOrderDialogState, setRefundOngoingOrderDialogState] = useState({ open: false });

    const viewUser = useSelector((state) => state.authentication.viewUser);
    const restaurant = useSelector((state) => state.app.restaurant);

    const hasCustomerInfo = !!order.customerName || !!order.customerMobileNumber || !!order.cancelledItems;
    const shouldShowInfo = !isPosApp(order.app) || (isPosApp(order.app) && hasCustomerInfo);
    const chargeAmount = BigNumber(order.amountWithPromoPriceDiscount)
        .plus(isCashPayment(order.paymentMethod) ? (order.marketplaceServiceFee ?? 0) : 0)
        .minus((isCashPayment(order.paymentMethod) ? order.promoCodeDiscount : order.restaurantPromoCodeCost) ?? 0)
        .minus(order.restaurantDeliveryCost ?? 0)
        .toString();
    const isNewOrAcceptedOrder = [OrderStatuses.NEW, OrderStatuses.ACCEPTED].includes(order.orderStatus as any);
    const shouldShowMapButton = orderIsInternalDeliveryOrder(order) && isNewOrAcceptedOrder && !!order.driverId && !!order?.restaurant?.location;
    const showRefundButton = restaurant?.refundOngoingOrdersEnabled && isNewOrAcceptedOrder;

    const openRefundOngoingOrderDialog = () => setRefundOngoingOrderDialogState({ open: true });

    const closeRefundOngoingOrderDialog = () => setRefundOngoingOrderDialogState({ open: false });

    return (
        <>
            <PartialRefundOngoingOrderDialog open={refundOngoingOrderDialogState.open} orderId={order.orderId} maximumAmountToRefund={chargeAmount} handleClose={closeRefundOngoingOrderDialog} />
            {showMore && shouldShowInfo && (
                <div style={{ width: '100%' }}>
                    <OrderCardCustomerInfo order={order} />
                    <div className={classes.separator}></div>
                    <OrderCardDeliveryInfo order={order} />
                    {order.driverId && <div className={classes.separator}></div>}
                </div>
            )}

            <div className={classes.buttonContainer}>
                {shouldShowMapButton && (
                    <Button style={{ textTransform: 'none' }} onClick={() => setShowMap(!showMap)}>
                        <div className={classNames(classes.text, classes.spaceBetween)} style={{ width: '100%', gap: 8 }}>
                            <MapIcon />
                            {showMap ? translate('Hide Map') : translate('Show Map')}
                        </div>
                    </Button>
                )}
                {!!order.address && (
                    <Button
                        size='medium'
                        style={{ textTransform: 'none' }}
                        onClick={() => openDirections(order?.restaurant?.location, requireValue(order.address).location, requireValue(order.address).googlePlaceId)}
                    >
                        <div className={classNames(classes.text, classes.spaceBetween)} style={{ width: '100%', gap: 8 }}>
                            <RouteIcon />
                            {translate('Show directions')}
                        </div>
                    </Button>
                )}
                {showRefundButton && (
                    <Button style={{ textTransform: 'none' }} onClick={openRefundOngoingOrderDialog} disabled={order.partiallyRefunded || viewUser}>
                        <UndoIcon className={classes.mapIcon} />
                        <span className={classes.text}>{translate('Refund')}</span>
                    </Button>
                )}
            </div>
            {showMap && order.driverId && (
                <Grid item xs={12}>
                    {!!order?.restaurant?.location && (
                        <GoogleMapsTrackDriverPosition
                            restaurantLocation={order?.restaurant?.location}
                            customerLocation={order.address?.location}
                            driverId={order.driverId}
                            orderId={order.orderId}
                            hidden={!showMap}
                        />
                    )}
                </Grid>
            )}
            {shouldShowInfo && (
                <Grid item xs={12}>
                    {(showRefundButton || shouldShowMapButton || !!order.address) && <div className={classes.separator}></div>}
                    <div className={classes.readMoreContainer}>
                        <Button className={classes.readMore} onClick={() => setShowMore(!showMore)}>
                            {showMore && (
                                <>
                                    {translate('Close')}
                                    <KeyboardArrowUpIcon />
                                </>
                            )}
                            {!showMore && (
                                <>
                                    {translate('Read more')}
                                    <KeyboardArrowDownIcon />
                                </>
                            )}
                        </Button>
                    </div>
                </Grid>
            )}
        </>
    );
}

const useStyles = makeStyles((theme) => ({
    text: {
        fontFamily: theme.typography.regular,
        fontSize: 14,
        color: '#454A4A',
    },
    buttonContainer: {
        display: 'flex',
        alignItems: 'center',
        margin: '0 auto',
        gap: 12,
    },
    spaceBetween: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        width: '100%',
    },
    readMore: {
        textTransform: 'none',
        fontFamily: theme.typography.regular,
        fontSize: 13,
        color: theme.palette.secondary.contrastText,
    },
    readMoreContainer: {
        width: '100%',
        textAlign: 'center',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    mapIcon: {
        color: theme.palette.primary.main,
        fontSize: 17,
        marginRight: 5,
    },
    separator: {
        height: '0px',
        width: '100%',
        border: '1px solid #F3F3F3',
        margin: '12px 0px',
    },
}));

type Props = {
    order: OrderVm;
};
