import * as React from 'react';

export function PidedirectoAppIcon({ title }: Props): React.ReactElement {
    return (
        <svg width='20' height='8' viewBox='0 0 20 8' fill='none' xmlns='http://www.w3.org/2000/svg'>
            {title && <title>{title}</title>}
            <path
                d='M4.07324 1.48633L2.39209 7.91016H0.732422L3.17627 0.0898438H4.21289L4.07324 1.48633ZM5.3999 7.91016L3.70801 1.48633L3.55225 0.0898438H4.60498L7.07031 7.91016H5.3999ZM5.45898 4.99365V6.29883H1.93018V4.99365H5.45898Z'
                fill='#616B79'
            />
            <path
                d='M10.481 5.15479H8.88037V3.84961H10.481C10.703 3.84961 10.8838 3.79948 11.0234 3.69922C11.1667 3.59896 11.2705 3.4611 11.335 3.28564C11.3994 3.10661 11.4316 2.90609 11.4316 2.68408C11.4316 2.4585 11.3976 2.24902 11.3296 2.05566C11.2651 1.85872 11.1631 1.69938 11.0234 1.57764C10.8874 1.45589 10.7065 1.39502 10.481 1.39502H9.38525V7.91016H7.81689V0.0898438H10.481C11.0073 0.0898438 11.4603 0.200846 11.8398 0.422852C12.2194 0.644857 12.5094 0.951009 12.71 1.34131C12.9141 1.72803 13.0161 2.17204 13.0161 2.67334C13.0161 3.17822 12.9141 3.61686 12.71 3.98926C12.5094 4.35807 12.2194 4.64453 11.8398 4.84863C11.4603 5.05273 11.0073 5.15479 10.481 5.15479Z'
                fill='#616B79'
            />
            <path
                d='M16.7329 5.15479H15.1323V3.84961H16.7329C16.9549 3.84961 17.1357 3.79948 17.2754 3.69922C17.4186 3.59896 17.5225 3.4611 17.5869 3.28564C17.6514 3.10661 17.6836 2.90609 17.6836 2.68408C17.6836 2.4585 17.6496 2.24902 17.5815 2.05566C17.5171 1.85872 17.415 1.69938 17.2754 1.57764C17.1393 1.45589 16.9585 1.39502 16.7329 1.39502H15.6372V7.91016H14.0688V0.0898438H16.7329C17.2593 0.0898438 17.7122 0.200846 18.0918 0.422852C18.4714 0.644857 18.7614 0.951009 18.9619 1.34131C19.166 1.72803 19.2681 2.17204 19.2681 2.67334C19.2681 3.17822 19.166 3.61686 18.9619 3.98926C18.7614 4.35807 18.4714 4.64453 18.0918 4.84863C17.7122 5.05273 17.2593 5.15479 16.7329 5.15479Z'
                fill='#616B79'
            />
        </svg>
    );
}

type Props = {
    title?: string;
};
