import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Grid from '@material-ui/core/Grid';
import LinearProgress from '@material-ui/core/LinearProgress';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import * as React from 'react';
import { withRouter } from 'react-router-dom';
import { letseatmanagerApiDeprecated } from 'src/api/letseatmanagerApiDeprecated';
import { Field, Form } from 'src/components/deprecated/react-final-form/Form';
import TextField from 'src/components/deprecated/react-final-form/TextField';
import { Spacing } from 'src/components/spacing/Spacing';
import { history } from 'src/config/history';
import { RoutePaths } from 'src/constants/RoutePath';
import { translate } from 'src/i18n/translate';
import { appLayout } from 'src/theme/AppLayout';
import type { DriverId } from 'src/types/Id';
import { alertKnownErrorOrSomethingWentWrong } from 'src/utils/alert/alertKnownErrorOrSomethingWentWrong';
import { pretty } from 'src/utils/string/pretty';
import { required } from 'src/utils/Validator';

export const DriversSendDialog: React.ComponentType<any> = withRouter<any, any>(
    withStyles((theme) => ({
        linearProgress: {
            position: 'absolute',
            width: '100%',
            bottom: 0,
        },
    }))(
        class DriversSendDialog extends React.PureComponent<Props, State> {
            state: any = INITIAL_STATE;

            async componentDidMount(): Promise<void> {
                await this.openOrCloseDialog();
            }

            async componentDidUpdate(prevProps: Props) {
                if (this.props.location.pathname === prevProps.location.pathname) {
                    return;
                }
                await this.openOrCloseDialog();
            }

            handleClose = () => {
                if (this.state.submitting) {
                    return;
                }
                history.goBack();
            };

            handleExited = () => {
                this.setState(INITIAL_STATE as any);
            };

            openOrCloseDialog = async () => {
                if (new RegExp(`^${this.props.path}`).test(this.props.location.pathname)) {
                    if (!this.props.location.state) {
                        history.push({
                            pathname: RoutePaths.ADMIN_DRIVERS,
                            search: history.location.search,
                        });
                        return;
                    }
                    if (!this.state.open) {
                        this.setState({
                            open: true,
                            drivers: this.props.location.state.drivers,
                        });
                    }
                } else {
                    if (this.state.open) this.setState({ open: false });
                }
            };

            onSubmit = async (form: any) => {
                this.setState({ submitting: true });
                const response = await letseatmanagerApiDeprecated.admin.sendNotificationToDrivers({
                    driverIds: this.state.drivers.map((driver: any) => driver.driverId),
                    title: form.title,
                    body: form.body,
                });
                this.setState({ submitting: false });
                if (!response.ok) {
                    alertKnownErrorOrSomethingWentWrong(response);
                    return;
                }
                if (response.data.failed.length > 0) {
                    alert(`Failed to send messages to ${response.data.failed.length} users (${pretty(response.data.failed)})`);
                }
                history.goBack();
            };

            render() {
                const { classes } = this.props;
                return (
                    <Dialog aria-labelledby='drivers-send-dialog-title' fullWidth scroll='paper' open={this.state.open} onClose={this.handleClose} onExited={this.handleExited}>
                        <Form
                            onSubmit={this.onSubmit}
                            initialValues={{
                                title: this.props.template ? this.props.template.title || '' : '',
                                body: this.props.template ? this.props.template.body || '' : '',
                            }}
                            render={({ handleSubmit, submitting, pristine, values }) => (
                                <form onSubmit={handleSubmit} noValidate style={appLayout.dialog.form}>
                                    <DialogTitle id='drivers-send-dialog-title'>{translate('Bulk Send Notification To Drivers').toUpperCase()}</DialogTitle>
                                    <DialogContent>{this.renderDialogContent()}</DialogContent>
                                    <DialogActions>
                                        <Button onClick={this.handleClose} disabled={this.state.submitting}>
                                            {translate('Cancel').toUpperCase()}
                                        </Button>
                                        <Button color='primary' type='submit' disabled={this.state.submitting}>
                                            {this.state.submitting ? translate('Sending').toUpperCase() : translate('Send').toUpperCase()}
                                        </Button>
                                    </DialogActions>
                                    {this.state.submitting && <LinearProgress className={classes.linearProgress} />}
                                </form>
                            )}
                        />
                    </Dialog>
                );
            }

            renderDialogContent() {
                return (
                    <>
                        <Grid container spacing={3}>
                            <Grid item xs={12}>
                                <Field
                                    name='title'
                                    component={TextField}
                                    label={translate('Title')}
                                    helperText={`${translate('@name will be replaced with drivers first name or Socio if it does not exist')}`}
                                    fullWidth
                                    disabled={this.state.submitting}
                                    required
                                    validate={required}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <Field
                                    name='body'
                                    component={TextField}
                                    multiline
                                    label={translate('Body')}
                                    helperText={`${translate('@name will be replaced with drivers first name or Socio if it does not exist')}`}
                                    fullWidth
                                    disabled={this.state.submitting}
                                    required
                                    validate={required}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                {this.renderReceivers()}
                            </Grid>
                        </Grid>

                        <Grid container justify='space-around'>
                            <Grid item>
                                <Spacing units={8} />
                                {this.state.errorMessage && (
                                    <Typography display='block' color='error'>
                                        {this.state.errorMessage}
                                    </Typography>
                                )}
                            </Grid>
                        </Grid>
                    </>
                );
            }

            renderReceivers() {
                if (this.state.drivers.length === 0) {
                    return '';
                }
                if (this.state.drivers.length <= 20) {
                    return `This message will be sent to ${this.state.drivers.map((driver: any) => driver.name || driver.driverId.substring(0, 8) + '...').join(', ')}`;
                }
                return `This message will be sent to ${this.state.drivers
                    .slice(0, 20)
                    .map((driver: any) => driver.name || driver.driverId.substring(0, 8) + '...')
                    .join(', ')} + ${this.state.drivers.length - 5} more`;
            }
        },
    ),
);

const INITIAL_STATE = {
    open: false,
    drivers: [],
    submitting: false,
} as const;

type Props = {
    classes: any;
    location: {
        pathname: string;
        state: {
            drivers: Array<{
                driverId: DriverId;
                name: string;
            }>;
        };
    };
    path: string;
    template?: {
        title: string;
        body: string;
    };
};

type State = {
    open: boolean;
    submitting: boolean;
    drivers: Array<{
        driverId: DriverId;
        name: string;
    }>;
    errorMessage?: string;
};
