import * as React from 'react';
import { useEffect } from 'react';
import { appReducer } from 'src/reducers/appReducer';
import { useAction } from 'src/utils/react/useAction';
import { useSelector } from 'src/utils/react/useSelector';
import { isMobileApp } from 'src/utils/reactNative/isMobileApp';
import { configureAllSounds } from 'src/utils/sound/configureAllSounds';

export function ConfigureSoundOnFirstClick(): React.ReactElement | null {
    const webSoundPermissionAllowed = useSelector((state) => state.app2.webSoundPermissionAllowed);

    const setSoundMuted = useAction(appReducer.actions.setSoundMuted);

    useEffect(() => {
        if (isMobileApp()) return;
        if (webSoundPermissionAllowed) return;

        const handleDocumentFirstClick = () => {
            configureAllSounds();
            setSoundMuted(false);
            document.removeEventListener('mousedown', handleDocumentFirstClick);
        };
        document.addEventListener('mousedown', handleDocumentFirstClick);

        return () => {
            document.removeEventListener('mousedown', handleDocumentFirstClick);
        };
    }, []);

    return null;
}
