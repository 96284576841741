import { makeStyles } from '@material-ui/core';
import { ScreenSizes } from '@pidedirecto/ui/constants';
import { useIsScreenSize, useNotification } from '@pidedirecto/ui/hooks';
import { useContext } from 'react';
import * as React from 'react';
import { Button } from 'src/components/Button';
import { MenuItemTypes } from 'src/constants/DeprecatedMenuItemType';
import { translate } from 'src/i18n/translate';
import { getPosInterfaceLargerEnabledRestaurantSettingInLocalStorage } from 'src/localStorage/getPosInterfaceLargerEnabledRestaurantSettingInLocalStorage';
import { posReducer } from 'src/reducers/posReducer';
import { PosContext } from 'src/scenes/letseatmanager/pos/PosProvider';
import { usePosCartModifierGroups } from 'src/services/pos/posModifiersCart/usePosCartModifierGroups';
import { usePosCartModifiers } from 'src/services/pos/posModifiersCart/usePosCartModifiers';
import { usePosCartModifiersQuantity } from 'src/services/pos/posModifiersCart/usePosCartModifiersQuantity';
import { useSetMissingSectionName } from 'src/services/pos/posModifiersCart/useSetMissingSectionName';
import { useSetPosCartModifiers } from 'src/services/pos/posModifiersCart/useSetPosCartModifiers';
import { useAddPosItem } from 'src/services/pos/useAddPosItem';
import { DeprecatedModifierGroupVm, DeprecatedModifierVm, ModifierGroupVm, ModifierVm } from 'src/types/PosMenuVm';
import { useAction } from 'src/utils/react/useAction';
import { useSelector } from 'src/utils/react/useSelector';

export function AddPosCartModifiersButton(): React.ReactElement {
    const classes = useStyles();
    const posContext = useContext(PosContext);
    const posCartModifierGroups = usePosCartModifierGroups();
    const quantity = usePosCartModifiersQuantity();
    const setPosCartModifiers = useSetPosCartModifiers();
    const notification = useNotification();
    const isSmallScreen = useIsScreenSize(ScreenSizes.SMALL_SCREEN);

    const setMissingSectionName = useSetMissingSectionName();
    const posCartModifiers = usePosCartModifiers();

    const posInterfaceLargerEnabled = getPosInterfaceLargerEnabledRestaurantSettingInLocalStorage();

    const { addItem } = useAddPosItem();

    const item = useSelector((state) => state.pos.itemSelected);
    const selectedMenuCategory = useSelector((state) => state.pos.selectedMenuCategory);
    const selectedMenu = useSelector((state) => state.pos.selectedMenu);

    const closeAddMenuItem = useAction(posReducer.actions.closeAddMenuItem);

    const showMissingSection = () => {
        for (const modifierGroup of posCartModifierGroups) {
            const modifiersInGroup = getTotalModifiers(modifierGroup);
            if (modifierGroup.requiredMin && modifiersInGroup < modifierGroup.requiredMin) {
                setMissingSectionName(modifierGroup.name);
                break;
            }
            if (modifierGroup.requiredMax && modifiersInGroup > modifierGroup.requiredMax) {
                setMissingSectionName(modifierGroup.name);
                break;
            }
        }
    };

    const handleAddPosItem = () => {
        if (hasMissingModifierGroups()) {
            showMissingSection();
            return;
        }

        addItem({
            menuItemId: item.menuItemId,
            menuCategoryId: selectedMenuCategory?.menuCategoryId,
            menuId: item.menuId,
            menuItemType: MenuItemTypes.ITEM,
            isProduct: true,
            name: item.name,
            unitPrice: getMenuItemPrice(),
            promoUnitPrice: getMenuItemPromoPrice(),
            promoText: getMenuItemPromoText(),
            size: item.size,
            imageUrl: item.imageUrl,
            quantity: quantity!,
            isSoldByWeight: item.isSoldByWeight,
            salesUnit: item.salesUnit,
            modifierGroups: posCartModifierGroups.reduce<Array<any>>((selectedModifierGroups: Array<any>, modifierGroup: DeprecatedModifierGroupVm | ModifierGroupVm) => {
                const modifiers: Array<DeprecatedModifierVm | ModifierVm> = modifierGroup.modifiers;
                const selectedModifierGroup: any = {
                    name: modifierGroup.name,
                    showModifierGroupNameInCommand: modifierGroup.showModifierGroupNameInCommand,
                    freeModifiersQuantity: modifierGroup.freeModifiersQuantity,
                    modifiers: modifiers?.reduce<Array<any>>((selectedModifiers: Array<any>, modifier: any) => {
                        const modifierAdded = posCartModifiers?.[modifierGroup.name]?.find((_modifier: any) => _modifier.name === modifier.name);
                        if (modifierAdded) {
                            const selectedModifier = {
                                modifierId: modifier.menuItemId,
                                name: modifier.name,
                                price: modifier.price,
                                quantity: modifierAdded.quantity,
                            };
                            selectedModifiers.push(selectedModifier);
                        }
                        return selectedModifiers;
                    }, []),
                };
                if (selectedModifierGroup.modifiers.length > 0) {
                    selectedModifierGroups.push(selectedModifierGroup);
                }
                return selectedModifierGroups;
            }, []),
            addedAt: posContext.menuOpenedAt,
        });

        closeAddMenuItem();

        if (isSmallScreen) {
            notification({ message: translate('Product added') });
        }
        setPosCartModifiers({});
    };

    const hasMissingModifierGroups = () => {
        return posCartModifierGroups?.reduce((disabled, modifierGroup) => {
            const modifiersInGroup = getTotalModifiers(modifierGroup);
            if (modifierGroup.requiredMin && modifiersInGroup < modifierGroup.requiredMin) {
                disabled = true;
            }
            if (modifierGroup.requiredMax && modifiersInGroup > modifierGroup.requiredMax) {
                disabled = true;
            }
            return disabled;
        }, false);
    };

    const getTotalModifiers = (modifierGroup: ModifierGroupVm | DeprecatedModifierGroupVm) => {
        if (!modifierGroup.requiredMax) return 0;
        return (
            posCartModifiers[modifierGroup.name]?.reduce((totalItems, modifier) => {
                totalItems += modifier.quantity;
                return totalItems;
            }, 0) ?? 0
        );
    };

    const getMenuItemPrice = () => {
        return item.menusProductInfo?.find((menuProductInfo) => menuProductInfo.menuId === selectedMenu?.menuId)?.price || item.price;
    };

    const getMenuItemPromoPrice = () => {
        return item.menusProductInfo?.find((menuProductInfo) => menuProductInfo.menuId === selectedMenu?.menuId)?.promoPrice || item.promoPrice;
    };

    const getMenuItemPromoText = () => {
        return item.menusProductInfo?.find((menuProductInfo) => menuProductInfo.menuId === selectedMenu?.menuId)?.promoText || item.promoText;
    };

    return (
        <Button larger={posInterfaceLargerEnabled} classes={{ button: classes.button }} onClick={handleAddPosItem}>
            {translate('Add')}
        </Button>
    );
}

const useStyles = makeStyles((theme) => ({
    button: {
        marginTop: 'auto',
        width: '100%',
    },
}));
