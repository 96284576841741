export const PaymentRejectReasons = {
    UNKNOWN: 'UNKNOWN',
    UNCATEGORIZED: 'UNCATEGORIZED',
    INSUFFICIENT_FUNDS: 'INSUFFICIENT_FUNDS',
    COMMUNICATION_ERROR: 'COMMUNICATION_ERROR',
    RESTRICTED_CARD: 'RESTRICTED_CARD',
    INVALID_SECURITY_CODE: 'INVALID_SECURITY_CODE',
} as const;

export type PaymentRejectReason = (typeof PaymentRejectReasons)[keyof typeof PaymentRejectReasons];
