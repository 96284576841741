import { createActions, createReducer } from 'reduxsauce';

/* ------------- Types and Action Creators ------------- */
const { Types, Creators }: any = createActions(
    {
        reset: [],
        fetch: ['restaurantId'],
        fetchSuccess: ['vm', 'admin'],
        fetchFailure: ['errorMessage'],
        switchTab: ['tab'],
        moveMenuLeft: ['moveMenuLeftRequest'],
        moveMenuLeftSuccess: [],
        moveMenuLeftFailure: ['errorMessage'],
        moveMenuRight: ['moveMenuRightRequest'],
        moveMenuRightSuccess: [],
        moveMenuRightFailure: ['errorMessage'],
        moveMenuCategoryUp: ['moveMenuCategoryUpRequest'],
        moveMenuCategoryUpSuccess: [],
        moveMenuCategoryUpFailure: ['errorMessage'],
        moveMenuCategoryDown: ['moveMenuCategoryDownRequest'],
        moveMenuCategoryDownSuccess: [],
        moveMenuCategoryDownFailure: ['errorMessage'],
        moveMenuItemUp: ['moveMenuItemUpRequest'],
        moveMenuItemUpSuccess: [],
        moveMenuItemUpFailure: ['errorMessage'],
        moveMenuItemDown: ['moveMenuItemDownRequest'],
        moveMenuItemDownSuccess: [],
        moveMenuItemDownFailure: ['errorMessage'],
        hideMenuItem: ['menuItemId', 'hiddenUntil'],
        hideMenuItemSuccess: [],
        hideMenuItemFailure: [],
        unHideMenuItem: ['menuItemId'],
        unHideMenuItemSuccess: [],
        unHideMenuItemFailure: [],
    },
    { prefix: 'TheMenuReducer/' },
);

export const TheMenuTypes = Types;
export default Creators;

/* ------------- Initial State ------------- */
export const INITIAL_STATE: any = {
    loading: false,
    tab: null,
    id: '',
    menus: [],
    menuCategories: undefined,
    errorMessage: null,
};

/* ------------- Reducers ------------- */
export const reset = (): any => {
    return INITIAL_STATE;
};

export const fetch = (state: any): any => {
    return {
        ...state,
        loading: true,
    };
};

export const fetchSuccess = (state: any, { vm, admin }: any): any => {
    const newState = {
        ...state,
        ...vm,
        menus: admin ? vm.menus : vm.menus.filter((menu: any) => !menu.companyName),
        menuCategories: admin ? vm.menus.flatMap((menu: any) => menu.categories) : vm.menus.filter((menu: any) => !menu.companyName).flatMap((menu: any) => menu.categories),
        errorMessage: null,
        loading: false,
    } as const;

    const menu = newState.menus.find((menu: any) => menu.menuId === state.tab);
    if (!menu && newState.menus[0]) {
        newState.tab = newState.menus[0].menuId;
    }
    return newState;
};

export const fetchFailure = (state: any, { errorMessage }: any): any => {
    return {
        ...state,
        errorMessage,
        loading: false,
    };
};

export const switchTab = (state: any, { tab }: any): any => {
    return {
        ...state,
        tab,
    };
};

export const moveMenuLeft = (state: any): any => {
    return {
        ...state,
        loading: true,
    };
};

export const moveMenuLeftSuccess = (state: any): any => {
    return {
        ...state,
        errorMessage: null,
        // loading: false,
    };
};

export const moveMenuLeftFailure = (state: any, { errorMessage }: any): any => {
    return {
        ...state,
        errorMessage,
        loading: false,
    };
};
export const moveMenuRight = (state: any): any => {
    return {
        ...state,
        loading: true,
    };
};

export const moveMenuRightSuccess = (state: any): any => {
    return {
        ...state,
        // ...vm,
        errorMessage: null,
        loading: false,
    };
};

export const moveMenuRightFailure = (state: any, { errorMessage }: any): any => {
    return {
        ...state,
        errorMessage,
        loading: false,
    };
};

export const moveMenuCategoryUp = (state: any): any => {
    return {
        ...state,
        loading: true,
    };
};

export const moveMenuCategoryUpSuccess = (state: any): any => {
    return {
        ...state,
        errorMessage: null,
        // loading: false,
    };
};

export const moveMenuCategoryUpFailure = (state: any, { errorMessage }: any): any => {
    return {
        ...state,
        errorMessage,
        loading: false,
    };
};
export const moveMenuCategoryDown = (state: any): any => {
    return {
        ...state,
        loading: true,
    };
};

export const moveMenuCategoryDownSuccess = (state: any): any => {
    return {
        ...state,
        // ...vm,
        errorMessage: null,
        loading: false,
    };
};

export const moveMenuCategoryDownFailure = (state: any, { errorMessage }: any): any => {
    return {
        ...state,
        errorMessage,
        loading: false,
    };
};

export const moveMenuItemUp = (state: any): any => {
    return {
        ...state,
        loading: true,
    };
};

export const moveMenuItemUpSuccess = (state: any): any => {
    return {
        ...state,
        errorMessage: null,
        // loading: false,
    };
};

export const moveMenuItemUpFailure = (state: any, { errorMessage }: any): any => {
    return {
        ...state,
        errorMessage,
        loading: false,
    };
};
export const moveMenuItemDown = (state: any): any => {
    return {
        ...state,
        loading: true,
    };
};

export const moveMenuItemDownSuccess = (state: any): any => {
    return {
        ...state,
        // ...vm,
        errorMessage: null,
        loading: false,
    };
};

export const moveMenuItemDownFailure = (state: any, { errorMessage }: any): any => {
    return {
        ...state,
        errorMessage,
        loading: false,
    };
};

export const hideMenuItem = (state: any): any => {
    return {
        ...state,
        loading: true,
    };
};

export const hideMenuItemSuccess = (state: any): any => {
    return {
        ...state,
        // ...vm,
        errorMessage: null,
        loading: false,
    };
};

export const hideMenuItemFailure = (state: any, { errorMessage }: any): any => {
    return {
        ...state,
        errorMessage,
        loading: false,
    };
};

export const unHideMenuItem = (state: any): any => {
    return {
        ...state,
        loading: true,
    };
};

export const unHideMenuItemSuccess = (state: any): any => {
    return {
        ...state,
        // ...vm,
        errorMessage: null,
        loading: false,
    };
};

export const unHideMenuItemFailure = (state: any, { errorMessage }: any): any => {
    return {
        ...state,
        errorMessage,
        loading: false,
    };
};

/* ------------- Hookup Reducers To Types ------------- */
export const reducer: any = createReducer(INITIAL_STATE, {
    [Types.RESET]: reset,
    [Types.FETCH]: fetch,
    [Types.FETCH_SUCCESS]: fetchSuccess,
    [Types.FETCH_FAILURE]: fetchFailure,
    [Types.SWITCH_TAB]: switchTab,
    [Types.MOVE_MENU_LEFT]: moveMenuLeft,
    [Types.MOVE_MENU_LEFT_SUCCESS]: moveMenuLeftSuccess,
    [Types.MOVE_MENU_LEFT_FAILURE]: moveMenuLeftFailure,
    [Types.MOVE_MENU_RIGHT]: moveMenuRight,
    [Types.MOVE_MENU_RIGHT_SUCCESS]: moveMenuRightSuccess,
    [Types.MOVE_MENU_RIGHT_FAILURE]: moveMenuRightFailure,
    [Types.MOVE_MENU_CATEGORY_UP]: moveMenuCategoryUp,
    [Types.MOVE_MENU_CATEGORY_UP_SUCCESS]: moveMenuCategoryUpSuccess,
    [Types.MOVE_MENU_CATEGORY_UP_FAILURE]: moveMenuCategoryUpFailure,
    [Types.MOVE_MENU_CATEGORY_DOWN]: moveMenuCategoryDown,
    [Types.MOVE_MENU_CATEGORY_DOWN_SUCCESS]: moveMenuCategoryDownSuccess,
    [Types.MOVE_MENU_CATEGORY_DOWN_FAILURE]: moveMenuCategoryDownFailure,
    [Types.MOVE_MENU_ITEM_UP]: moveMenuItemUp,
    [Types.MOVE_MENU_ITEM_UP_SUCCESS]: moveMenuItemUpSuccess,
    [Types.MOVE_MENU_ITEM_UP_FAILURE]: moveMenuItemUpFailure,
    [Types.MOVE_MENU_ITEM_DOWN]: moveMenuItemDown,
    [Types.MOVE_MENU_ITEM_DOWN_SUCCESS]: moveMenuItemDownSuccess,
    [Types.MOVE_MENU_ITEM_DOWN_FAILURE]: moveMenuItemDownFailure,
    [Types.HIDE_MENU_ITEM]: hideMenuItem,
    [Types.HIDE_MENU_ITEM_SUCCESS]: hideMenuItemSuccess,
    [Types.HIDE_MENU_ITEM_FAILURE]: hideMenuItemFailure,
    [Types.UN_HIDE_MENU_ITEM]: unHideMenuItem,
    [Types.UN_HIDE_MENU_ITEM_SUCCESS]: unHideMenuItemSuccess,
    [Types.UN_HIDE_MENU_ITEM_FAILURE]: unHideMenuItemFailure,
});
