import * as React from 'react';

export function IncomingIcon({ title, width, height, color }: Props): React.ReactElement {
    return (
        <svg width={width || 64} height={height || 63} viewBox='0 0 64 63' fill='none' xmlns='http://www.w3.org/2000/svg'>
            {title && <title>{title}</title>}
            <path
                d='M22.9636 43.6428V35.1598H18.2223C17.546 35.1598 17 35.7253 17 36.4258V42.3768C17 43.0773 17.546 43.6428 18.2223 43.6428H22.9636ZM22.9636 43.6428L32.0163 43.6429L40.347 41.4902L45.3771 39.2463C45.9336 38.9836 46.388 38.4509 46.6839 37.8999C46.9657 37.3745 47.1418 36.6667 46.8495 35.8202C46.5571 34.9737 45.9865 34.5578 45.4405 34.3571C44.8945 34.1564 44.2604 34.1528 43.6828 34.317L35.1372 36.7251H30.8504H34.2355C35.7748 36.7251 37.0182 35.4334 37.0182 33.8426C37.0182 32.2482 35.7713 30.9602 34.2355 30.9602H28.9905C27.585 30.9602 26.243 31.5622 25.2778 32.6203L22.96 35.1671M41.7066 22.4151C41.3368 20.4704 39.6812 19 37.6945 19C35.4366 19 33.6049 20.8973 33.6049 23.2361C33.6049 25.5748 35.4366 27.4721 37.6945 27.4721C38.1031 27.4721 38.4941 27.4101 38.8675 27.2933C38.8182 27.027 38.79 26.7533 38.79 26.4724C38.79 24.5532 40.0229 22.9369 41.7102 22.4151H41.7066ZM41.7066 22.4151C40.0194 22.9369 38.7865 24.5569 38.7865 26.4724C38.7865 26.7533 38.8147 27.027 38.864 27.2933M38.864 27.297C39.2338 29.2417 40.8894 30.7121 42.8761 30.7121C45.134 30.7121 46.9657 28.8148 46.9657 26.476C46.9657 24.1373 45.134 22.24 42.8761 22.24C42.4675 22.24 42.0765 22.302 41.7031 22.4188'
                stroke={color ?? 'currentColor'}
                strokeWidth='1.5'
                strokeLinecap='round'
                strokeLinejoin='round'
            />
        </svg>
    );
}

type Props = {
    title?: string;
    color?: string;
    width?: number;
    height?: number;
};
