import { makeStyles } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close'; // TODO replace with our icon
import * as React from 'react';
import { useState } from 'react';
import { useWatch } from 'react-hook-form';
import { changeCourseApi } from 'src/api/letseatmanager/course/changeCourseApi';
import { removeCourseApi } from 'src/api/letseatmanager/course/removeCourseApi';
import { Button } from 'src/components/Button';
import { FormTextField } from 'src/components/form/FormTextField';
import { translate } from 'src/i18n/translate';
import { EditIcon } from 'src/icons/EditIcon';
import { FlappyDiskIcon } from 'src/icons/FlappyDiskIcon';
import type { CourseId } from 'src/types/Id';
import { alertKnownErrorOrSomethingWentWrong } from 'src/utils/alert/alertKnownErrorOrSomethingWentWrong';
import { useSelector } from 'src/utils/react/useSelector';

export function CourseItem({ course, onRemove, onSuccess }: Props): React.ReactElement {
    const classes = useStyles();

    const [isEditing, setIsEditing] = useState(false);
    const [loading, setLoading] = useState(false);

    const restaurantId = useSelector((state) => state.app.restaurantId);

    const newName = useWatch({ name: 'courseName', defaultValue: course.name });

    const removeCourse = async () => {
        setLoading(true);
        const response = await removeCourseApi({
            courseId: course.courseId,
        });
        setLoading(false);
        if (!response.ok) {
            alertKnownErrorOrSomethingWentWrong(response);
            return;
        }
        onRemove();
    };

    const saveChanges = async () => {
        setLoading(true);
        const response = await changeCourseApi({ courseId: course.courseId, name: newName, restaurantId: restaurantId });
        setLoading(false);
        if (!response.ok) {
            alertKnownErrorOrSomethingWentWrong(response);
            return;
        }
        setIsEditing(false);
        onSuccess();
    };

    const handleEditClick = () => {
        setIsEditing(true);
    };

    return (
        <div className={classes.container}>
            <div className={classes.nameContainer}>
                {isEditing ? (
                    <FormTextField disabled={loading} name='courseName' placeholder={translate('New Name')} classes={{ input: (classes as any).input }} defaultValue={course.name} />
                ) : (
                    <span className={classes.text}>{course.name}</span>
                )}
            </div>
            <div className={classes.priceContainer}>
                {isEditing ? (
                    <Button icon onClick={() => saveChanges()} type='button' disabled={loading}>
                        <FlappyDiskIcon />
                    </Button>
                ) : (
                    <Button icon onClick={handleEditClick}>
                        <EditIcon title={translate('Edit')} />
                    </Button>
                )}

                <Button icon onClick={!isEditing ? removeCourse : () => setIsEditing(false)} disabled={loading}>
                    {/* @ts-ignore */}
                    <CloseIcon title={translate('Remove')} />
                </Button>
            </div>
        </div>
    );
}

const useStyles = makeStyles((theme) => ({
    container: {
        width: '100%',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
    },
    nameContainer: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        gap: 10,
    },
    priceContainer: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
    },
    image: {
        width: 40,
        height: 40,
        borderRadius: 10,
        objectFit: 'cover',
        flexShrink: 0,
        [theme.breakpoints.down('sm')]: {
            display: 'none',
        },
    },
    noImage: {
        width: 40,
        height: 40,
        borderRadius: 10,
        backgroundColor: '#E5E8ED',
        flexShrink: 0,
        [theme.breakpoints.down('sm')]: {
            display: 'none',
        },
    },
    text: {
        fontFamily: theme.typography.regular,
        fontSize: 15,
        margin: '0 20px',
    },
}));

type Props = {
    course: {
        courseId: CourseId;
        name: string;
    };
    onRemove: any;
    onSuccess: any;
};
