import { makeStyles } from '@material-ui/core';
import * as React from 'react';
import { Text } from 'src/components/Text';
import { classNames } from 'src/utils/react/classNames';

export function PosBusinessDayReportIndicator({ value, label, outlined, Icon, classes: classesProp }: Props): React.ReactElement {
    const classes = useStyles();

    return (
        <div className={classNames(outlined ? classes.containerOutlined : classes.container, classesProp?.container)}>
            <div className={classes.icon}>{Icon}</div>
            <div className={classes.textContainer}>
                <Text variant='semiBold' className={classes.value}>
                    {value}
                </Text>
                <Text className={classes.label}>{label}</Text>
            </div>
        </div>
    );
}

const useStyles = makeStyles((theme) => ({
    container: {
        display: 'flex',
        boxShadow: '4px 4px 7px 0px rgba(0, 0, 0, 0.25)',
        backgroundColor: 'white',
        borderRadius: 8,
        padding: '12px 16px',
        gap: 12,
        width: '100%',
    },
    containerOutlined: {
        gap: 12,
        padding: '12px 16px',
        borderRadius: 8,
        border: `1px solid #BAC0C2`,
    },
    value: {
        fontSize: 20,
        margin: 0,
        color: '#232933',
        [theme.breakpoints.down('sm')]: {
            fontSize: 14,
        },
    },
    label: {
        color: '#6D7B7F',
        [theme.breakpoints.down('sm')]: {
            fontSize: 12,
            lineHeight: 1.2,
        },
    },
    textContainer: {
        display: 'flex',
        flexDirection: 'column',
        [theme.breakpoints.down('sm')]: {
            gap: 2,
        },
    },
    icon: {
        minWidth: 20,
    },
}));

type Props = {
    value: string | number;
    label: string;
    outlined?: boolean;
    Icon?: React.ReactNode;
    classes?: {
        container?: string;
    };
};
