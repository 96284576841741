import { letseatmanagerApiMethod } from 'src/api/letseatmanagerApi';
import type { ApiSauceResponse } from 'src/api/types/ApiSauceResponse';
import type { RestaurantId, UberEatsStoreId } from 'src/types/Id';

export async function integrateUberEatsApi(request: IntegrateUberEatsRequest): ApiSauceResponse<void> {
    return letseatmanagerApiMethod('restaurantIntegration/integrateUberEatsApi', request);
}

type IntegrateUberEatsRequest = {
    restaurantId: RestaurantId;
    uberEatsStoreId: UberEatsStoreId;
};
