import { Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Button } from '@pidedirecto/ui';
import { TrashCanIcon } from '@pidedirecto/ui/icons';
import * as React from 'react';
import { useState } from 'react';
import { changeMenuCategoryApi } from 'src/api/letseatmanager/menuCategory/changeMenuCategoryApi';
import { useMenuCategories } from 'src/services/menuCategory/useMenuCategories';
import { ModifierGroupId, type MenuCategoryId } from 'src/types/Id';
import { MenuCategoryVm } from 'src/types/MenuCategoryVm';
import type { ModifierGroupVm } from 'src/types/ModifierGroupVm';
import { alertKnownErrorOrSomethingWentWrong } from 'src/utils/alert/alertKnownErrorOrSomethingWentWrong';
import { classNames } from 'src/utils/react/classNames';

export function ModifierGroupInMenuCategory({ modifierGroup, menuCategoryId, onSuccess }: Props): React.ReactElement | null {
    const classes = useStyles();

    const [loading, setLoading] = useState(false);

    const { menuCategories } = useMenuCategories();

    const removeModifierGroupFromMenuCategory = async () => {
        const menuCategory = menuCategories?.find((menuCategory: MenuCategoryVm) => menuCategory.menuCategoryId === menuCategoryId);
        if (!menuCategory) return null;

        setLoading(true);
        const response = await changeMenuCategoryApi({
            ...menuCategory,
            modifierGroupIds: menuCategory?.modifierGroupIds?.filter((modifierGroupId: ModifierGroupId) => modifierGroupId !== modifierGroup.modifierGroupId) ?? [],
        });
        setLoading(false);
        if (!response.ok) {
            alertKnownErrorOrSomethingWentWrong(response);
            return;
        }
        onSuccess?.();
    };

    return (
        <Grid className={classNames(classes.modifierGroup, classes.isFullWidth, classes.spaceBetween)}>
            <div className={classes.rightContainer}>
                <p className={classes.semiBold}>{modifierGroup.name}</p>
            </div>
            <div style={{ display: 'flex' }}>
                <Button variant='icon' disabled={loading} onClick={removeModifierGroupFromMenuCategory}>
                    <TrashCanIcon color={'#E37878'} title={'trashcan'} size={20} />
                </Button>
            </div>
        </Grid>
    );
}

const useStyles = makeStyles((theme) => ({
    modifierGroup: {
        listStyleType: 'none',
        display: 'flex',
        alignItems: 'center',
    },
    rightContainer: {
        display: 'flex',
        alignItems: 'center',
        gap: 16,
    },
    semiBold: {
        fontFamily: theme.typography.semiBold,
        color: '#0D3037',
        fontSize: 16,
        margin: 0,
    },
    isFullWidth: {
        width: '100%',
    },
    spaceBetween: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
    },
}));

type Props = {
    modifierGroup: ModifierGroupVm;
    menuCategoryId: MenuCategoryId;
    onSuccess?: any;
};
