import { letseatmanagerApiMethod } from 'src/api/letseatmanagerApi';
import type { ApiSauceResponse } from 'src/api/types/ApiSauceResponse';
import type { RestaurantId } from 'src/types/Id';
import type { SupplyVm } from 'src/types/SupplyVm';

export async function findSuppliesByRestaurantIdApi(request: FindSuppliesByRestaurantIdRequest): ApiSauceResponse<FindSuppliesByRestaurantIdResponse> {
    return letseatmanagerApiMethod('supply/findSuppliesByRestaurantIdApi', request);
}

type FindSuppliesByRestaurantIdRequest = {
    restaurantId: RestaurantId;
};

export type FindSuppliesByRestaurantIdResponse = {
    supplies: Array<SupplyVm>;
};
