import { letseatadminApiMethod } from 'src/api/letseatadminApi';
import type { ApiSauceResponse } from 'src/api/types/ApiSauceResponse';
import type { DriverManualEarningVm } from 'src/types/DriverManualEarningVm';
import type { ManagerUserId } from 'src/types/Id';
import type { OrderVm } from 'src/types/OrderVm';

export async function findManagerUserActionLogsApi(request: FindManagerUserActionLogsApiRequest): ApiSauceResponse<FindManagerUserActionLogsApiResponse> {
    return letseatadminApiMethod('managerUser/findManagerUserActionLogsApi', request);
}

type FindManagerUserActionLogsApiRequest = {
    managerUserId: ManagerUserId;
    from: string;
    to: string;
};

type FindManagerUserActionLogsApiResponse = {
    ordersCancelled: Array<OrderVm>;
    manualAssignments: Array<OrderVm>;
    driverManualEarnings: Array<DriverManualEarningVm>;
    username: string;
};
