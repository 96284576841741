import { letseatmanagerApiMethod } from 'src/api/letseatmanagerApi';
import type { ApiSauceResponse } from 'src/api/types/ApiSauceResponse';
import type { CardId, SubscriptionId } from 'src/types/Id';
import type { SubscriptionVm } from 'src/types/SubscriptionVm';

export async function removeSubscriptionCardApi(request: RemoveSubscriptionCardApiRequest): ApiSauceResponse<SubscriptionVm> {
    return letseatmanagerApiMethod('subscription/removeSubscriptionCardApi', request);
}

export type RemoveSubscriptionCardApiRequest = {
    subscriptionId: SubscriptionId;
    cardId: CardId;
};
