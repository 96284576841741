import { history } from 'src/config/history';

export function setUrlQueryParameter(name: string, value: string, location?: any): string {
    if (!location) {
        location = history.location;
    }
    const params = new URLSearchParams(location.search);
    params.set(name, value);
    return params.toString().replace(/=$/, '').replace(/=&/, '&');
}
