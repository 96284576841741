import { makeStyles } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import PhoneIcon from '@material-ui/icons/Phone';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { AppIcon } from 'src/components/icon/AppIcon';
import { DeprecatedOrderCardAdditionalInfo } from 'src/components/orderCard/DeprecatedOrderCardAdditionalInfo';
import { DeprecatedOrderCardDetailsInfo } from 'src/components/orderCard/DeprecatedOrderCardDetailsInfo';
import { DeprecatedOrderCardGeneralInfo } from 'src/components/orderCard/DeprecatedOrderCardGeneralInfo';
import { DeprecatedOrderCardStatusInfo } from 'src/components/orderCard/DeprecatedOrderCardStatusInfo';
import { OrderCardActions } from 'src/components/orderCard/orderCardActions/OrderCardActions';
import { OrderCardContextualMenu } from 'src/components/orderCard/OrderCardContextualMenu';
import { OrderCardIdsInfo } from 'src/components/orderCard/OrderCardIdsInfo';
import { OrderCardPaymentInfo } from 'src/components/orderCard/OrderCardPaymentInfo';
import { OrderPriceInfo } from 'src/components/orderCard/OrderPriceInfo';
import { OrderStatusHighlight } from 'src/components/orderCard/OrderStatusHighlight';
import type { OrderVm } from 'src/types/OrderVm';
import { isPosApp } from 'src/utils/app/isPosApp';
import { useHasPrintersToPrint } from 'src/utils/printer/useHasPrintersToPrint';

/** @deprecated */
export function OrderCardDeprecated({ order, disabled, onError, onChange, hideOptions }: Props): React.ReactElement {
    const classes = useStyles();
    const { hasPrintersToPrint } = useHasPrintersToPrint();

    const [loading, setLoading] = useState(false);
    const [existPrintersConfigured, setExistPrintersConfigured] = useState(false);

    useEffect(() => {
        setExistPrintersConfigured(hasPrintersToPrint);
    }, []);

    const updateLoadingOrder = (status: any) => setLoading(status);

    return (
        <OrderStatusHighlight order={order}>
            <Grid container spacing={1}>
                <Grid item xs={12} className={classes.spaceBetween}>
                    <div className={classes.customerContainer}>
                        <div className={classes.logoContainer}>
                            <AppIcon app={order.app} />
                        </div>
                        <h1 className={classes.customerName}>
                            {order.manualOrder && <PhoneIcon style={{ fontSize: 17, marginRight: 5 }} />}
                            {order.customerName || (isPosApp(order.app) ? 'POS' : '')}
                            {order.didiFoodOrderIndex && ` #${order.didiFoodOrderIndex}`}
                        </h1>
                    </div>
                    {!hideOptions && <OrderCardContextualMenu order={order} onChange={onChange} updateLoadingOrder={updateLoadingOrder} disabled={disabled || loading} />}
                </Grid>
                <DeprecatedOrderCardGeneralInfo order={order} />
                <DeprecatedOrderCardStatusInfo order={order} disabled={disabled || loading} updateLoadingOrder={updateLoadingOrder} onChange={onChange} onError={onError} />
                <OrderCardIdsInfo order={order} />
                <DeprecatedOrderCardDetailsInfo order={order} onChange={onChange} />
                <OrderPriceInfo order={order} />
                <div className={classes.separator} style={{ borderStyle: 'dashed', borderColor: '#ABAEB5' }}></div>
                <OrderCardPaymentInfo order={order} />
                <DeprecatedOrderCardAdditionalInfo order={order} />
            </Grid>
            <OrderCardActions order={order} disabled={disabled || loading} updateLoadingOrder={updateLoadingOrder} onChange={onChange} onError={onError} />
        </OrderStatusHighlight>
    );
}

const useStyles = makeStyles((theme) => ({
    separator: {
        height: '0px',
        width: '100%',
        border: '1px solid #F3F3F3',
        margin: '12px 0px',
    },
    restaurantName: {
        fontFamily: theme.typography.medium,
        color: theme.palette.secondary.contrastText,
        fontSize: 17,
        margin: 0,
        width: '100%',
        height: 'fit-content',
    },
    customerContainer: {
        display: 'flex',
        width: '100%',
        flexDirection: 'row',
        alignItems: 'center',
        gap: 12,
    },
    logoContainer: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexShrink: 0,
    },
    customerName: {
        fontFamily: theme.typography.semiBold,
        color: '#0D3037',
        fontSize: 16,
        width: '100%',
        padding: 0,
        margin: 0,
        lineHeight: 1.2,
    },
    spaceBetween: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        width: '100%',
    },
}));

type Props = {
    order: OrderVm;
    disabled?: boolean;
    onError?: (arg1: string) => any;
    onChange?: () => any;
    hideOptions?: boolean;
};
