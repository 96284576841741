import { letseatadminApiMethod } from 'src/api/letseatadminApi';
import type { ApiSauceResponse } from 'src/api/types/ApiSauceResponse';
import type { QuickActionId } from 'src/types/Id';

export async function getSmsQuantityForQuickActionApi(request: GetSmsQuantityForQuickActionApiRequest): ApiSauceResponse<number> {
    return letseatadminApiMethod('quickAction/getSmsQuantityForQuickActionApi', request);
}

type GetSmsQuantityForQuickActionApiRequest = {
    quickActionId: QuickActionId;
};
