import { makeStyles } from '@material-ui/core';
import * as React from 'react';
import { SecuredPage } from 'src/components/page/SecuredPage';
import { RolePermissions } from 'src/constants/RolePermission';
import { translate } from 'src/i18n/translate';
import { UploadBuyOrder } from 'src/scenes/letseatmanager/uploadBuyOrder/UploadBuyOrder';

export function UploadBuyOrderPage(): React.ReactElement {
    const classes = useStyles();

    return (
        <SecuredPage rolePermission={RolePermissions.INVENTORY_PAGE} title={translate('Buy Order')} className={classes.container}>
            <UploadBuyOrder />
        </SecuredPage>
    );
}

const useStyles = makeStyles((theme) => ({
    container: {
        width: '100vw',
        boxSizing: 'border-box',
        position: 'relative',
        left: '-5vw',
        top: -20,
        height: 'calc(100% + 20px)',
    },
}));
