import { createContext, useRef, useState } from 'react';
import * as React from 'react';
import { PasswordDialog } from 'src/components/dialog/PasswordDialog';

export function PasswordDialogProvider({ children }: Props): React.ReactElement {
    const resolvePromiseRef = useRef<(result: Promise<boolean> | boolean) => void>(() => {});

    const [passwordDialogState, setPasswordDialogState] = useState<{ open: boolean; password: string | undefined }>({ open: false, password: undefined });

    const askForPassword = (options: Options) => {
        setPasswordDialogState({ open: true, password: options?.password });
        return new Promise((resolve: (result: Promise<boolean> | boolean) => void, reject: (error?: any) => void) => {
            resolvePromiseRef.current = resolve;
        });
    };

    const closePasswordDialog = () => {
        setPasswordDialogState({ open: false, password: undefined });
    };

    const handleWrongPassword = () => {
        resolvePromiseRef.current?.(false);
    };

    const handleRightPassword = () => resolvePromiseRef.current?.(true);

    return (
        <PasswordDialogContext.Provider value={{ askForPassword }}>
            {children}
            <PasswordDialog
                open={passwordDialogState.open}
                customPassword={passwordDialogState.password}
                onClose={closePasswordDialog}
                onFailed={handleWrongPassword}
                onSuccess={handleRightPassword}
            />
        </PasswordDialogContext.Provider>
    );
}

export const PasswordDialogContext = createContext<{ askForPassword: any }>({ askForPassword: () => {} });

type Props = {
    children: React.ReactNode;
};

type Options = {
    password?: string;
};
