import { makeStyles } from '@material-ui/core';
import Card from '@material-ui/core/Card';
import Grid from '@material-ui/core/Grid';
import Link from '@material-ui/core/Link';
import moment from 'moment';
import * as React from 'react';
import { useState } from 'react';
import { useForm, useWatch } from 'react-hook-form';
import { Route, Switch } from 'react-router-dom';
import { Form } from 'src/components/form/Form';
import { FormBrandAutocomplete } from 'src/components/form/FormBrandAutocomplete';
import { FormDatePicker } from 'src/components/form/FormDatePicker';
import { FormSubmitButton } from 'src/components/form/FormSubmitButton';
import { RoutePaths } from 'src/constants/RoutePath';
import { translate } from 'src/i18n/translate';
import { RestaurantBilling } from 'src/scenes/letseatadmin/restaurantBilling/RestaurantBilling';
import { RestaurantsBilling } from 'src/scenes/letseatadmin/restaurantBilling/RestaurantsBilling';
import { appLayout } from 'src/theme/AppLayout';
import { BrandId } from 'src/types/Id';

export function RestaurantBillingPage(): React.ReactElement {
    const classes = useStyles();

    const form = useForm();
    const { control } = form;

    const defaultFrom = moment().startOf('isoWeek').subtract(1, 'week').toDate();
    const from = useWatch({ name: 'from', control, defaultValue: defaultFrom });
    const defaultTo = moment().endOf('isoWeek').subtract(1, 'week').toDate();
    const to = useWatch({ name: 'to', control, defaultValue: defaultTo });
    const brandId = useWatch<BrandId>({ name: 'brandId', control });

    const [refresh, setRefresh] = useState(0);

    const handlePressLastWeek = () => {
        form.setValue('from', moment(from).subtract(1, 'week').toDate());
        form.setValue('to', moment(to).subtract(1, 'week').toDate());
        setRefresh(refresh + 1);
    };

    const handlePressNextWeek = () => {
        form.setValue('from', moment(from).add(1, 'week').toDate());
        form.setValue('to', moment(to).add(1, 'week').toDate());
        setRefresh(refresh + 1);
    };

    return (
        <Grid container spacing={5}>
            <Grid item xs={12}>
                <Form form={form} onSubmit={() => setRefresh(refresh + 1)} enabledForUserTypeView={true} style={appLayout.dialog.form}>
                    <Card style={{ padding: '24px' }}>
                        <Grid container direction='row' spacing={3} alignItems='flex-end'>
                            <Grid item>
                                <FormDatePicker classes={{ inputContainer: classes.inputContainer }} name='from' label={translate('From')} defaultValue={defaultFrom} />
                            </Grid>
                            <Grid item>
                                <FormDatePicker classes={{ inputContainer: classes.inputContainer }} name='to' label={translate('To')} defaultValue={defaultTo} />
                            </Grid>
                            <Grid item>
                                <div style={{ width: 300 }}>
                                    <FormBrandAutocomplete name='brandId' label={translate('Brand')} />
                                </div>
                            </Grid>
                            <Grid item>
                                <FormSubmitButton text={translate('REFRESH')} />
                            </Grid>
                        </Grid>

                        <Grid container direction='row' spacing={3}>
                            <Grid item>
                                <Link onClick={handlePressLastWeek}>Last Week</Link>
                            </Grid>
                            <Grid item>
                                <Link onClick={handlePressNextWeek}>Next Week</Link>
                            </Grid>
                        </Grid>
                    </Card>
                </Form>
            </Grid>
            <Grid item xs={12}>
                <Switch>
                    <Route
                        path={RoutePaths.ADMIN_RESTAURANT_BILLING_FOR_RESTAURANT}
                        render={(props) => <RestaurantBilling filter={{ from, to }} restaurantId={props.match.params.restaurantId as any} refresh={refresh} />}
                    />
                    <Route path={RoutePaths.ADMIN_RESTAURANT_BILLING} render={() => <RestaurantsBilling filter={{ from, to, brandId }} refresh={refresh} />} />
                </Switch>
            </Grid>
        </Grid>
    );
}

const useStyles = makeStyles((theme) => ({
    inputContainer: {
        marginBottom: 0,
    },
}));
