import { DialogTitle, makeStyles } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import Grid from '@material-ui/core/Grid';
import { useState } from 'react';
import * as React from 'react';
import { useForm, useWatch } from 'react-hook-form';
import { createRestaurantUserApi } from 'src/api/letseatmanager/userManagement/createRestaurantUserApi';
import { Form } from 'src/components/form/Form';
import { FormBrandRestaurantsAutocomplete } from 'src/components/form/FormBrandRestaurantsAutocomplete';
import { FormEmailField } from 'src/components/form/FormEmailField';
import { FormPinCode } from 'src/components/form/FormPinCode';
import { FormTextField } from 'src/components/form/FormTextField';
import { FormUserRoleSelect } from 'src/components/form/FormUserRoleSelect';
import { RolePermissions } from 'src/constants/RolePermission';
import { translate } from 'src/i18n/translate';
import { useReloadRestaurant } from 'src/services/restaurant/useReloadRestaurant';
import { alertKnownErrorOrSomethingWentWrong } from 'src/utils/alert/alertKnownErrorOrSomethingWentWrong';
import { useSelector } from 'src/utils/react/useSelector';

export function CreateRestaurantUserDialog({ open, onClose, onSuccess }: Props): React.ReactElement {
    const classes = useStyles();
    const form = useForm();

    const { reloadRestaurant } = useReloadRestaurant();

    const restaurantId = useSelector((state) => state.app.restaurantId);

    const {
        formState: { isSubmitting },
        control,
    } = form;

    const pinCode = useWatch({ name: 'pinCode', control });

    const [isUserWithPinCodeSignIn, setIsUserWithPinCodeSignIn] = useState(false);
    const [loading, setLoading] = useState(false);

    const handleClose = () => {
        if (loading) return;
        setIsUserWithPinCodeSignIn(false);
        onClose();
    };

    const onSubmit = async (form: any) => {
        setLoading(true);
        const response = await createRestaurantUserApi({
            name: form.name,
            username: form.email.toLowerCase(),
            email: form.email.toLowerCase(),
            userRoleId: form.userRoleId,
            pinCode: form.pinCode,
            restaurantIds: form.restaurantIds,
        });
        setLoading(false);
        if (!response.ok) {
            alertKnownErrorOrSomethingWentWrong(response);
            return;
        }
        reloadRestaurant(restaurantId);
        onSuccess();
        handleClose();
    };

    const handleUserRoleChange = (userRole: any) => {
        const isUserWithPinCodeSignIn = userRole.permissions.includes(RolePermissions.PIN_CODE_SIGN_IN);
        setIsUserWithPinCodeSignIn(isUserWithPinCodeSignIn);
    };

    return (
        <Dialog fullWidth scroll='paper' open={open} onClose={handleClose} classes={{ paper: classes.dialogContainer }}>
            <Form form={form} onSubmit={onSubmit}>
                <DialogTitle>
                    <div className={classes.title}> {translate('Create User')}</div>
                </DialogTitle>
                <DialogContent style={{ overflowY: 'hidden' }}>
                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                            <FormTextField name='name' label={translate('Name')} autoComplete={'off'} required disabled={loading} />
                        </Grid>
                        <Grid item xs={12}>
                            <FormEmailField name='email' label={translate('Email')} required disabled={loading} />
                        </Grid>
                        <Grid item xs={12}>
                            <FormUserRoleSelect name='userRoleId' label={translate('Role')} required onChange={handleUserRoleChange} disabled={loading} />
                        </Grid>
                        {isUserWithPinCodeSignIn && (
                            <>
                                <Grid item xs={12} md={6}>
                                    <FormPinCode name='pinCode' label={translate('PIN code')} required maxLength={6} minLength={6} disabled={loading} />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <FormPinCode
                                        name='confirmPinCode'
                                        label={translate('Confirm PIN code')}
                                        required
                                        minLength={6}
                                        maxLength={6}
                                        validate={{
                                            value: (value: string) => value === pinCode,
                                        }}
                                        disabled={loading}
                                    />
                                </Grid>
                            </>
                        )}
                        <Grid item xs={12}>
                            <FormBrandRestaurantsAutocomplete name='restaurantIds' label={translate('Restaurants')} required disabled={loading} />
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions className={classes.buttonsContainer}>
                    <Button className={classes.cancelButton} onClick={handleClose} disabled={isSubmitting} variant={'outlined'}>
                        {translate('Cancel')}
                    </Button>
                    <Button className={classes.acceptButton} color='primary' variant={'contained'} type={'submit'} disabled={isSubmitting}>
                        {translate('Create')}
                    </Button>
                </DialogActions>
            </Form>
        </Dialog>
    );
}

const useStyles = makeStyles((theme) => ({
    dialogContainer: {
        maxWidth: 500,
    },
    title: {
        fontFamily: theme.typography.medium,
        fontSize: 20,
    },
    buttonsContainer: {
        marginTop: 20,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        [theme.breakpoints.down('sm')]: {
            flexDirection: 'column',
            marginBottom: 20,
        },
    },
    acceptButton: {
        backgroundColor: theme.palette.primary.main,
        color: 'white',
        fontFamily: theme.typography.semiBold,
        fontSize: 16,
        borderRadius: 8,
        width: 150,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        textTransform: 'none',
        marginLeft: '20px !important',
    },
    cancelButton: {
        backgroundColor: 'white',
        color: theme.palette.primary.main,
        border: `1px solid ${theme.palette.primary.main}`,
        fontFamily: theme.typography.semiBold,
        fontSize: 15,
        borderRadius: 5,
        width: 150,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        textTransform: 'none',
        padding: '5px 10px',
    },
}));

type Props = {
    open: boolean;
    onClose: any;
    onSuccess: any;
};
