import { makeStyles } from '@material-ui/core';
import { useEffect } from 'react';
import * as React from 'react';
import { useHistory } from 'react-router-dom';
import { DateSelectDialog } from 'src/components/dialog/DateSelectDialog';
import { SecuredAndSubscribedPage } from 'src/components/page/SecuredAndSubscribedPage';
import { Tabs } from 'src/components/Tabs';
import { RolePermissions } from 'src/constants/RolePermission';
import { translate } from 'src/i18n/translate';
import { CashRegisterReport } from 'src/scenes/letseatmanager/posReports/CashRegisterReport';
import { CashRegisterTransactionsReport } from 'src/scenes/letseatmanager/posReports/CashRegisterTransactionsReport';
import { EmployeesDayShiftReport } from 'src/scenes/letseatmanager/posReports/EmployeesDayShiftReport';
import { ManagerUserSalesReport } from 'src/scenes/letseatmanager/posReports/ManagerUserSalesReport';
import { SalesByUserReport } from 'src/scenes/letseatmanager/posReports/SalesByUserReport';
import { FilterReports } from 'src/scenes/letseatmanager/restaurantDashboard/FilterReports';
import { PosBusinessDayReports } from 'src/scenes/letseatmanager/restaurantDashboard/PosBusinessDayReports';
import { useSelector } from 'src/utils/react/useSelector';
import { useUserHasRolePermission } from 'src/utils/react/useUserHasRolePermissions';

export function RestaurantPosReportPage(): React.ReactElement | null {
    const classes = useStyles();
    const history = useHistory();

    const [userHasRolePermission] = useUserHasRolePermission();

    const posEnabled = useSelector((state) => state.app.restaurant?.posEnabled);
    const userManagementEnabled = useSelector((state) => state.app.restaurant.userManagementEnabled);
    const clockInEmployeeDayShiftEnabled = useSelector((state) => state.app.restaurant?.clockInEmployeeDayShiftEnabled);

    const showEmployeeReportTab = clockInEmployeeDayShiftEnabled && userManagementEnabled;

    useEffect(() => {
        if (!posEnabled && !userManagementEnabled) history.goBack();
    }, [posEnabled, userManagementEnabled]);

    const tabs = [
        {
            value: '1',
            label: translate('Pos business days'),
            rolePermission: RolePermissions.POS_BUSINESS_DAY_REPORT,
        },
        {
            value: '2',
            label: translate('Cash registers'),
            hidden: !posEnabled,
            rolePermission: RolePermissions.CASH_REGISTER_REPORT,
        },
        {
            value: '3',
            label: translate('Cash register transactions report'),
            hidden: !posEnabled,
            rolePermission: RolePermissions.TRANSACTIONS_REPORT,
        },
        {
            value: '4',
            label: translate('Manager user reports'),
            hidden: !userManagementEnabled,
            rolePermission: RolePermissions.POS_USERS_REPORT,
        },
        {
            value: '5',
            label: translate('Sales by User'),
            hidden: !userManagementEnabled,
            rolePermission: RolePermissions.POS_USER_SALES_REPORT,
        },
        {
            value: '6',
            label: translate('Employees'),
            hidden: !showEmployeeReportTab,
            rolePermission: RolePermissions.EMPLOYEE_REPORT,
        },
    ];

    if (!posEnabled && !userManagementEnabled) return null;

    return (
        <SecuredAndSubscribedPage rolePermission={RolePermissions.REPORTS_PAGE} title={translate('POS reports')} className={classes.container}>
            <DateSelectDialog />
            <Tabs
                tabs={tabs}
                tabsHeader={
                    <div className={classes.filterContainer}>
                        <FilterReports />
                    </div>
                }
            >
                {userHasRolePermission(RolePermissions.POS_BUSINESS_DAY_REPORT) && posEnabled && <PosBusinessDayReports />}
                {userHasRolePermission(RolePermissions.CASH_REGISTER_REPORT) && posEnabled && <CashRegisterReport />}
                {userHasRolePermission(RolePermissions.TRANSACTIONS_REPORT) && posEnabled && <CashRegisterTransactionsReport />}
                {userHasRolePermission(RolePermissions.POS_USERS_REPORT) && <ManagerUserSalesReport />}
                {userHasRolePermission(RolePermissions.POS_USER_SALES_REPORT) && <SalesByUserReport />}
                {showEmployeeReportTab && userHasRolePermission(RolePermissions.EMPLOYEE_REPORT) && <EmployeesDayShiftReport />}
            </Tabs>
        </SecuredAndSubscribedPage>
    );
}

const useStyles = makeStyles((theme) => ({
    container: {
        width: '100%',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        [theme.breakpoints.down('sm')]: {
            padding: 12,
        },
    },
    filterContainer: {
        paddingTop: 20,
    },
}));
