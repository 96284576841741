import type { RestaurantManualEarningVm } from 'src/api/letseatadmin/restaurantManualEarning/getRestaurantManualEarningApi';
import { letseatadminApiMethod } from 'src/api/letseatadminApi';
import type { ApiSauceResponse } from 'src/api/types/ApiSauceResponse';
import type { RestaurantId } from 'src/types/Id';

export async function createRestaurantManualEarningApi(request: CreateRestaurantManualEarningApiRequest): ApiSauceResponse<RestaurantManualEarningVm> {
    return letseatadminApiMethod('restaurantManualEarning/createRestaurantManualEarningApi', request);
}

export type CreateRestaurantManualEarningApiRequest = {
    restaurantId: RestaurantId;
    title: string;
    body?: string;
    comment?: string;
    amount: string;
};
