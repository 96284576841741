import { Divider, Grid, makeStyles, Typography } from '@material-ui/core';
import { Loader } from '@pidedirecto/ui';
import { useConfirmDialog } from '@pidedirecto/ui/hooks';
import toInteger from 'lodash/toInteger';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { useMemo } from 'react';
import { useFieldArray, useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import { changeBuyOrderApi } from 'src/api/letseatmanager/buyOrder/changeBuyOrderApi';
import { completeBuyOrderApi } from 'src/api/letseatmanager/buyOrder/completeBuyOrderApi';
import { getBuyOrderApi } from 'src/api/letseatmanager/buyOrder/getBuyOrderApi';
import { findSuppliersByRestaurantIdApi } from 'src/api/letseatmanager/supplier/findSuppliersByRestaurantIdApi';
import { findSuppliesByRestaurantIdApi } from 'src/api/letseatmanager/supply/findSuppliesByRestaurantIdApi';
import { Alert } from 'src/components/Alert';
import { Button } from 'src/components/Button';
import { GoBackButton } from 'src/components/button/GoBackButton';
import { ConfirmUpdateSupplyCostDialog } from 'src/components/dialog/inventory/buyOrder/ConfirmUpdateSupplyCostDialog';
import { Form } from 'src/components/form/Form';
import { FormSupplierAutocomplete } from 'src/components/form/FormSupplierAutocomplete';
import { SecuredAndSubscribedPage } from 'src/components/page/SecuredAndSubscribedPage';
import { SecuredContent } from 'src/components/SecuredContent';
import { Tooltip } from 'src/components/Tooltip';
import { BuyOrderStatuses } from 'src/constants/BuyOrderStatus';
import { RolePermissions } from 'src/constants/RolePermission';
import { RoutePaths } from 'src/constants/RoutePath';
import { translate } from 'src/i18n/translate';
import { EnvelopeIcon } from 'src/icons/EnvelopeIcon';
import { FlappyDiskIcon } from 'src/icons/FlappyDiskIcon';
import { UploadIcon } from 'src/icons/UploadIcon';
import { appReducer } from 'src/reducers/appReducer';
import { AddBuyOrderSupply } from 'src/scenes/letseatmanager/inventory/buyOrder/AddBuyOrderSupply';
import { BuyOrderSummaryDialog } from 'src/scenes/letseatmanager/inventory/buyOrder/BuyOrderSummaryDialog';
import { BuyOrderSupply } from 'src/scenes/letseatmanager/inventory/buyOrder/BuyOrderSupply';
import { SendBuyOrderDialog } from 'src/scenes/letseatmanager/inventory/buyOrder/SendBuyOrderDialog';
import { usePrintBuyOrderTicket } from 'src/services/printer/usePrintBuyOrderTicket';
import { useFormatAsRestaurantCurrencyNumber } from 'src/services/restaurant/useFormatAsRestaurantCurrencyNumber';
import { BuyOrderSummary } from 'src/types/BuyOrderSummary';
import { SupplyId, type BuyOrderId } from 'src/types/Id';
import { SupplyVm } from 'src/types/SupplyVm';
import { alertKnownErrorOrSomethingWentWrong } from 'src/utils/alert/alertKnownErrorOrSomethingWentWrong';
import { isBuyOrderCancelled } from 'src/utils/buyOrder/isBuyOrderCancelled';
import { isBuyOrderCompleted } from 'src/utils/buyOrder/isBuyOrderCompleted';
import { useAction } from 'src/utils/react/useAction';
import { useLoadApi } from 'src/utils/react/useLoadApi';
import { useParams } from 'src/utils/react/useParams';
import { useSelector } from 'src/utils/react/useSelector';

export function ChangeBuyOrderPage(): React.ReactElement {
    const form = useForm();
    const confirmDialog = useConfirmDialog();
    const classes = useStyles();
    const history = useHistory();
    const formatAsCurrencyNumber = useFormatAsRestaurantCurrencyNumber();

    const { buyOrderId } = useParams<{
        buyOrderId: BuyOrderId;
    }>();

    const {
        formState: { isSubmitting },
        control,
    } = form;

    const { fields, append, remove } = useFieldArray({
        control,
        name: 'supplies',
    });

    const [sendBuyOrderDialogState, setSendBuyOrderDialogState] = useState<{ open: boolean; supplierEmail: string | undefined }>({ open: false, supplierEmail: undefined });
    const [openBuyOrderSummaryDialog, setOpenBuyOrderSummaryDialog] = useState(false);
    const [buyOrderSummary, setBuyOrderSummary] = useState<BuyOrderSummary>();
    const [loading, setLoading] = useState(false);
    const [total, setTotal] = useState(0);
    const [sendOrder, setSendOrder] = useState(false);
    const [completeOrder, setCompleteOrder] = useState(false);
    const [showAlert, setShowAlert] = useState(false);
    const [confirmUpdateSupplyCostsDialogState, setConfirmUpdateSupplyCostsDialogState] = useState({ open: false });

    const restaurantId = useSelector((state) => state.app.restaurantId);
    const pinCodeUserManagerUserId = useSelector((state) => state.authentication.pinCodeUserSignedIn?.managerUserId);
    const inventoryLinkedToRestaurantId = useSelector((state) => state.app.restaurant?.inventoryLinkedToRestaurantId);

    const showSnackbar = useAction(appReducer.actions.showSnackbar);

    const [loadingBuyOrder, buyOrder, load] = useLoadApi(getBuyOrderApi, { buyOrderId }, { requiredValues: [buyOrderId], dependencies: [buyOrderId] });
    const [loadingSupplies, { supplies }] = useLoadApi(findSuppliesByRestaurantIdApi, { restaurantId }, { initialValue: { supplies: [] }, requiredValues: [restaurantId] });
    const [loadingSupplier, { suppliers }] = useLoadApi(findSuppliersByRestaurantIdApi, { restaurantId }, { initialValue: { suppliers: [] }, requiredValues: [restaurantId] });

    const [printBuyOrderTicket] = usePrintBuyOrderTicket();

    const handleTotalBuyOrder = () => {
        const supplies: Array<{ quantity: string; price: string }> = form.getValues().supplies;
        if (!supplies) {
            setTotal(0);
            return;
        }
        let total = 0;
        supplies.forEach((supply) => {
            total += toInteger(supply.quantity) * toInteger(supply.price);
        });
        setTotal(total);
    };

    const handleAddNewField = (data: any) => {
        append({ supplyId: data.supply, buyUnit: data.unitOfMeasurement, quantity: data.quantity, price: data.price });
    };

    useEffect(() => {
        if (!!supplies?.length && buyOrder) {
            initializeFormWithBuyOrder(form, buyOrder, append);
        }
        setBuyOrderSummary(buyOrder as any); // TODO: dont use any and simplify types
    }, [buyOrder, supplies]);

    const onSubmit = async () => {
        if (completeOrder) {
            setConfirmUpdateSupplyCostsDialogState({ open: true });
            return;
        }

        await updateBuyOrder();

        if (sendOrder) return;

        history.push({
            pathname: RoutePaths.INVENTORY_BUY_ORDER,
            search: history.location.search,
        });
    };

    const updateBuyOrder = async () => {
        if (loading) return;

        const formData = form.getValues();

        setLoading(true);
        const response = await changeBuyOrderApi({
            ...formData,
            buyOrderId,
            supplies: formData.supplies,
            buyOrderStatus: BuyOrderStatuses.PENDING,
            restaurantId,
            managerUserId: pinCodeUserManagerUserId,
        } as any); // TODO: any added since buyOrderStatus: BuyOrderStatuses.PENDING is not in request, remove buyOrderStatus: BuyOrderStatuses.PENDING if safe
        setLoading(true);

        if (!response?.ok) {
            alertKnownErrorOrSomethingWentWrong(response);
            return;
        }

        setOpenBuyOrderSummaryDialog(false);
        showSnackbar({ message: translate('Buy order changed successfully') });

        if (sendOrder) {
            const supplierEmail = suppliers.find((supplier) => supplier.supplierId === formData.supplierId)?.email;
            setSendBuyOrderDialogState({ open: true, supplierEmail });
        }
    };

    const handleSubmit = async (form: any) => {
        mapSuppliesForSummary(form);
        setOpenBuyOrderSummaryDialog(true);
    };

    const handleSubmitAndSend = async () => {
        mapSuppliesForSummary(form.getValues());
        setSendOrder(true);
        setOpenBuyOrderSummaryDialog(true);
    };

    const handleSubmitAndComplete = async () => {
        mapSuppliesForSummary(form.getValues());
        setSendOrder(false);
        setCompleteOrder(true);
        setOpenBuyOrderSummaryDialog(true);
    };

    const handleCloseSendBuyOrderDialog = () => {
        setSendBuyOrderDialogState({ open: false, supplierEmail: undefined });
        history.push({
            pathname: RoutePaths.INVENTORY_BUY_ORDER,
            search: history.location.search,
        });
    };

    const mapSuppliesForSummary = (form: any) => {
        const buyOrderSupplies = form.supplies.map((buyOrderSupply: any) => {
            const supply = supplies.find((supplyForm) => supplyForm.supplyId === buyOrderSupply.supplyId);
            return {
                name: supply!.name,
                sku: supply!.sku,
                quantity: buyOrderSupply.quantity,
                unitOfMeasurement: buyOrderSupply.unitOfMeasurement,
                price: buyOrderSupply.price,
                total: buyOrderSupply.total,
            };
        });

        const supplier = suppliers?.find((supplier: any) => supplier.supplierId === form.supplierId);

        setBuyOrderSummary({
            supplies: buyOrderSupplies,
            supplierBusinessName: supplier?.businessName!,
        });
    };

    const handleRemoveSupply = (index: any) => {
        remove(index);
        handleTotalBuyOrder();
    };

    const getOrderStatusClassName = (buyOrderStatus: any) => {
        if (!buyOrderStatus) {
            return '';
        }
        const status = buyOrderStatus?.toLowerCase();
        const statusClassName = `${classes.status} ${classes[status as keyof typeof classes]}`;
        return statusClassName;
    };

    const getSupply = useMemo(
        () => (supplyId: SupplyId) => {
            return supplies.find((supp) => supp.supplyId === supplyId);
        },
        [supplies],
    );

    const handlePrintBuyOrder = () => {
        printBuyOrderTicket(buyOrder, true).then(() => {
            history.push({
                pathname: RoutePaths.INVENTORY_BUY_ORDER,
                search: history.location.search,
            });
        });
    };

    const completeBuyOrder = async (updateSupplyPrices: any) => {
        await updateBuyOrder();

        setLoading(true);
        const response = await completeBuyOrderApi({
            buyOrderId,
            managerUserId: pinCodeUserManagerUserId,
            updateSupplyPrices,
        });
        setLoading(false);
        closeConfirmUpdateSupplyCostsDialog();

        if (!response.ok) {
            alertKnownErrorOrSomethingWentWrong(response);
            return;
        }

        load();
        setShowAlert(true);
    };

    const initializeFormWithBuyOrder = async (form: any, buyOrder: any, append: any) => {
        const filteredSupplies = buyOrder?.supplies?.filter((orderSupply: SupplyVm) => supplies.find((supply) => orderSupply.supplyId === supply.supplyId));

        if (filteredSupplies?.length < buyOrder?.supplies?.length) {
            const confirmWarning = await confirmDialog({
                title: translate('Error with supplies'),
                content: translate('The buy order includes items that are no longer available and will be omitted'),
                acceptButtonText: translate('Accept'),
                variant: 'warning',
            });
            if (!confirmWarning) return;
        }
        form.reset({
            supplierId: buyOrder.supplierId,
        });
        filteredSupplies.forEach((buyOrderSupply: any) => {
            append({
                supplyId: buyOrderSupply.supplyId,
                quantity: buyOrderSupply.quantity,
                price: buyOrderSupply.price,
                buyUnit: buyOrderSupply.unitOfMeasurement,
            });
        });
    };

    const closeConfirmUpdateSupplyCostsDialog = () => setConfirmUpdateSupplyCostsDialogState({ open: false });

    const disabledSaveAndSendButton = isSubmitting || isBuyOrderCancelled(buyOrder) || isBuyOrderCompleted(buyOrder) || fields.length === 0;

    const disabledForm = loading || loadingSupplies || loadingBuyOrder || loadingSupplier || isBuyOrderCancelled(buyOrder) || isBuyOrderCompleted(buyOrder);

    if (loadingBuyOrder || loadingSupplies || loadingSupplier) {
        return (
            <div className={classes.loaderContainer}>
                <Loader loading={loadingBuyOrder || loadingSupplies || loadingSupplier} text={translate('Loading...')} />
            </div>
        );
    }

    return (
        <SecuredAndSubscribedPage rolePermission={RolePermissions.BUY_ORDERS_PAGE} title={translate('Purchases')} className={classes.container}>
            <ConfirmUpdateSupplyCostDialog open={confirmUpdateSupplyCostsDialogState.open} onClose={closeConfirmUpdateSupplyCostsDialog} onSuccess={completeBuyOrder} />
            {showAlert && (
                <Alert
                    title={translate('Buy order completed successfully')}
                    onClose={() =>
                        history.push({
                            pathname: RoutePaths.INVENTORY_BUY_ORDER,
                            search: history.location.search,
                        })
                    }
                    onActionClick={() => handlePrintBuyOrder()}
                    actionText={translate('Print')}
                />
            )}

            {buyOrder && (
                <SendBuyOrderDialog
                    open={sendBuyOrderDialogState.open}
                    supplierEmail={sendBuyOrderDialogState.supplierEmail}
                    onClose={handleCloseSendBuyOrderDialog}
                    buyOrderId={buyOrder.buyOrderId}
                />
            )}
            {buyOrderSummary && (
                <BuyOrderSummaryDialog open={openBuyOrderSummaryDialog} onClose={() => setOpenBuyOrderSummaryDialog(false)} buyOrder={buyOrderSummary} onSubmit={onSubmit} disabled={loading} />
            )}
            <GoBackButton />
            <div className={classes.titleContainer}>
                <h1 className={classes.title}>{translate('Purchase Order #@buyOrderId', { buyOrderId: buyOrder?.shortBuyOrderId })}</h1>
                <span className={`${getOrderStatusClassName(buyOrder?.buyOrderStatus)}`}>{translate(buyOrder?.buyOrderStatus.toLowerCase())}</span>
            </div>
            <p className={classes.text}>{translate('Complete your order and send it')}</p>
            <Form form={form} onSubmit={handleSubmit} disabled={disabledForm}>
                <Grid item xs={12} md={12}>
                    {!inventoryLinkedToRestaurantId && (
                        <Grid container spacing={2} className={classes.topBarButtons}>
                            <Grid item xs={3} className={classes.submitButtons}>
                                <Tooltip text={translate('Save')}>
                                    <Button ariaLabel={translate('Save')} type='submit' disabled={disabledSaveAndSendButton} icon classes={{ button: classes.iconButton }}>
                                        <FlappyDiskIcon color={disabledSaveAndSendButton ? 'grey' : ''} title={translate('Save button')} />
                                    </Button>
                                </Tooltip>
                                <SecuredContent rolePermission={RolePermissions.SEND_BUY_ORDERS}>
                                    <Tooltip text={translate('Send by Email')}>
                                        <Button
                                            ariaLabel={translate('Save and send')}
                                            type='button'
                                            disabled={disabledSaveAndSendButton}
                                            onClick={handleSubmitAndSend}
                                            icon
                                            classes={{ button: classes.iconButton }}
                                        >
                                            <EnvelopeIcon color={disabledSaveAndSendButton ? 'grey' : ''} title='saveAndSendBuyOrder' />
                                        </Button>
                                    </Tooltip>
                                </SecuredContent>

                                <SecuredContent rolePermission={RolePermissions.CREATE_MERCHANDISE_ENTRY}>
                                    <Tooltip text={translate('Upload Inventory')}>
                                        <Button ariaLabel={translate('Upload Inventory')} type='button' onClick={handleSubmitAndComplete} icon classes={{ button: classes.iconButton }}>
                                            <UploadIcon title='uploadInventory' />
                                        </Button>
                                    </Tooltip>
                                </SecuredContent>
                            </Grid>
                        </Grid>
                    )}
                    <Grid container spacing={2} className={classes.topBar}>
                        <Grid item xs={4}>
                            <FormSupplierAutocomplete name='supplierId' label={translate('Supplier')} required defaultValue={buyOrder?.supplierId} />
                        </Grid>

                        <Grid item xs={3} className={classes.topBarTotal}>
                            <Typography variant='h6'>{translate('Articles: @articles', { articles: fields.length })}</Typography>
                            <hr className={classes.verticalSeparator} />
                            <Typography variant='h6'>{translate('Total: @total', { total: formatAsCurrencyNumber(total) })}</Typography>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12} md={12}>
                    {/* @ts-ignore */}
                    <Grid container spacing={12} margin={4} className={classes.topBar}>
                        <AddBuyOrderSupply getSupply={getSupply} onSubmit={handleAddNewField} />
                        <Divider className={classes.horizontalSeparator} />
                        {fields.length === 0 && (
                            <Grid item xs={12} md={12} className={classes.emptyBuyOrder}>
                                <h5 className={classes.emptyBuyOrderText}>{translate('Add at least 1 supply')}</h5>
                            </Grid>
                        )}
                        {fields.length !== 0 &&
                            fields.map((fieldSupply: any, idx) => {
                                return (
                                    <BuyOrderSupply
                                        key={fieldSupply.id}
                                        idx={idx}
                                        supply={fieldSupply}
                                        onRemove={handleRemoveSupply}
                                        getSupply={getSupply}
                                        onChange={handleTotalBuyOrder}
                                        disabled={disabledForm}
                                    />
                                );
                            })}
                    </Grid>
                </Grid>
            </Form>
        </SecuredAndSubscribedPage>
    );
}

const useStyles = makeStyles((theme) => ({
    loaderContainer: {
        height: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    container: {
        padding: 20,
        position: 'relative',
        paddingTop: 60,
    },
    title: {
        fontFamily: theme.typography.medium,
        fontSize: 25,
        width: 'auto',
    },
    titleContainer: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-start',
        alignItems: 'center',
        marginTop: 20,

        [theme.breakpoints.down('sm')]: {
            flexDirection: 'column',
            alignItems: 'flex-start',
        },
    },

    text: {
        fontFamily: theme.typography.regular,
        fontSize: 15,
    },
    removeSupplyButton: {
        marginTop: 20,
        color: theme.palette.error.main,
        borderColor: theme.palette.error.main,
        borderRadius: 8,
        '&:hover': {
            color: theme.palette.error.dark,
            borderColor: theme.palette.error.dark,
        },
    },
    addSupplierButton: {
        marginTop: 20,
        backgroundColor: theme.palette.primary.dark,
        borderColor: theme.palette.primary.dark,
        borderRadius: 8,
        '&:hover': {
            backgroundColor: theme.palette.primary.main,
            borderColor: theme.palette.primary.main,
        },

        color: theme.palette.primary.contrastText,
    },
    item: {
        marginTop: 'auto',
    },
    separator: {
        border: `0.1px solid ${theme.palette.secondary.dark}`,
        width: '100%',
        margin: '20px 0',
    },
    topBar: {
        marginTop: 20,
        justifyContent: 'space-between',
    },
    topBarButtons: {
        marginTop: 20,
        justifyContent: 'flex-end',
        alignItems: 'baseline',
    },
    topBarTotal: {
        display: 'flex',
        justifyContent: 'space-around',
        alignItems: 'center',
        border: `0.1px solid ${theme.palette.secondary.dark}`,
        borderRadius: 8,
        padding: '5 !important',
    },
    submitButtons: {
        display: 'flex',
        justifyContent: 'flex-end',
    },
    verticalSeparator: {
        border: `0.1px solid ${theme.palette.secondary.dark}`,
        borderLeft: `1px solid ${theme.palette.secondary.dark}`,
        height: 20,
    },
    horizontalSeparator: {
        border: `0.1px solid ${theme.palette.secondary.dark}`,
        width: '100%',
        margin: '20px 0',
    },
    status: {
        fontFamily: theme.typography.regular,
        fontSize: 12,
        padding: '5px 10px',
        borderRadius: 5,
        marginLeft: 10,
    },
    pending: {
        backgroundColor: '#F6E3A7',
        color: '#5E4400',
    },
    completed: {
        backgroundColor: '#CBF7E9',
        color: '#024B49',
    },
    rejected: {
        backgroundColor: '#EB5757',
        color: '#5E0000',
    },
    sent: {
        backgroundColor: '#C9E2FF',
        color: '#124289',
    },
    cancelled: {
        backgroundColor: '#EB5757',
        color: '#5E0000',
    },
    emptyBuyOrder: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        height: 150,
    },
    emptyBuyOrderText: {
        fontFamily: theme.typography.regular,
        fontSize: 15,
        color: '#EB5757',
    },
    iconButton: {
        backgroundColor: 'transparent',
        border: `1.5px solid ${theme.palette.secondary.dark}`,
        borderRadius: 8,
        padding: 10,
        marginLeft: 10,
    },
}));
