import { letseatadminApiMethod } from 'src/api/letseatadminApi';
import type { ApiSauceResponse } from 'src/api/types/ApiSauceResponse';
import type { BusinessId } from 'src/types/Id';

export async function removeBusinessApi(request: RemoveBusinessApiRequest): ApiSauceResponse<void> {
    return letseatadminApiMethod('business/removeBusinessApi', request);
}

type RemoveBusinessApiRequest = {
    businessId: BusinessId;
};
