import { letseatadminApiMethod } from 'src/api/letseatadminApi';
import type { ApiSauceResponse } from 'src/api/types/ApiSauceResponse';
import type { App } from 'src/constants/App';
import type { BannerImageType } from 'src/constants/BannerImageType';
import type { City } from 'src/constants/City';
import type { ConsecutiveOrderIdRange } from 'src/constants/ConsecutiveOrderIdRange';
import type { Country } from 'src/constants/Country';
import type { CustomDiscountComment } from 'src/constants/CustomDiscountComment';
import { DeliveryManagerType } from 'src/constants/DeliveryManagerType';
import { DeliveryProvider } from 'src/constants/DeliveryProviders';
import type { ProductLayout } from 'src/constants/ProductLayouts';
import type { RegimeFiscal } from 'src/constants/RegimeFiscal';
import type { RestaurantPsp } from 'src/constants/RestaurantPsp';
import type { RestaurantType } from 'src/constants/RestaurantType';
import type { SalesforceRestaurantType } from 'src/constants/SalesforceRestaurantType';
import type { SelfRegistrationStatus } from 'src/constants/SelfRegistrationStatus';
import type { ServiceType } from 'src/constants/ServiceType';
import type { SmsServiceProvider } from 'src/constants/SmsServiceProvider';
import type { Sound } from 'src/constants/Sound';
import { State } from 'src/constants/States';
import type { TimeZone } from 'src/constants/TimeZone';
import { DeprecatedRestaurantPaymentMethodVm } from 'src/types/DeprecatedRestaurantPaymentMethodVm';
import type { GeoJsonPoint } from 'src/types/GeoJsonPoint';
import type {
    AwsCognitoUsername,
    CashRegisterCustomReason,
    CompanyId,
    CustomProductCancellationReason,
    DeliveryCategory,
    DidiFoodStoreId,
    EmailAddress,
    EmailTemplateId,
    FinanceReference,
    PideDirectoApiKey,
    PlexoStoreId,
    QpayTerminalDeviceId,
    RappiStoreId,
    RestaurantId,
    SupplyCategoryId,
    UberEatsStoreId,
    WansoftStoreId,
    Zone,
} from 'src/types/Id';
import type { RestaurantAddress } from 'src/types/RestaurantAddress';

export async function getTheRestaurantApi(request: GetTheRestaurantApiRequest): ApiSauceResponse<TheRestaurantVm> {
    return letseatadminApiMethod('theRestaurant/getTheRestaurantApi', request);
}

export type GetTheRestaurantApiRequest = {
    restaurantId: RestaurantId;
};

export type TheRestaurantVm = {
    restaurantId: RestaurantId;
    restaurantType: RestaurantType;
    deliveryCategories?: Array<DeliveryCategory>;
    settingDeliveryCategories: Array<DeliveryCategory>;
    statisticsReportEmailRecipients?: Array<EmailAddress>;
    name?: string;
    street?: string;
    address?: RestaurantAddress;
    location?: GeoJsonPoint;
    phoneNumber?: string;
    contactInformationEnabled?: boolean;
    contactPhoneNumber?: string;
    contactWhatsAppPhoneNumber?: string;
    secondaryPhoneNumber?: string;
    imageUrl?: string;
    imageUrlMobile?: string;
    bannerImageType?: BannerImageType;
    kioskBackgroundImageUrl?: string;
    backgroundImageUrl?: string;
    logoImageUrl?: string;
    ticketLogoImage?: string;
    menuAnalyticsUrl?: string;
    customersAnalyticsUrl?: string;
    promoCodesAnalyticsUrl?: string;
    pickupStationClosingTime?: string;
    kitchenPickupStationClosingTime?: string;
    pickupStationDeliveryTime?: string;
    hours: string;
    info?: string;
    infoUrl?: string;
    ownFleetEnabled?: boolean;
    externalOwnFleetEnabled?: boolean;
    requestDeliveryToPideDirectoDriversForOwnFleetEnabled?: boolean;
    hideOrderItemNotesEnabled?: boolean;
    hideFollowUpBannerEnabled?: boolean;
    openingHours?: OpeningHoursVm;
    promoPrices: PromoPricesVm;
    activated: boolean;
    comingSoon: boolean;
    thanksForOrderingEmail: boolean;
    sendThanksForOrderingEmailMinutesAfter?: number;
    thanksForOrderingEmailTemplateId?: EmailTemplateId;
    welcomeEmail: boolean;
    sendWelcomeEmailMinutesAfter?: number;
    welcomeEmailTemplateId?: EmailTemplateId;
    menuHighlightEmail: boolean;
    sendMenuHighlightEmailDaysAfter?: number;
    menuHighlightEmailTemplateId?: EmailTemplateId;
    promoCodeEmail: boolean;
    sendPromoCodeEmailDaysAfter?: number;
    promoCodeEmailTemplateId?: EmailTemplateId;
    customerAuthenticationSmsServiceProvider?: SmsServiceProvider;
    temporarilyOffline: boolean;
    temporarilyWithoutDelivery?: boolean;
    companyNames?: Array<string>;
    companyIds?: Array<CompanyId>;
    public?: boolean;
    country: Country;
    city?: City;
    state?: State;
    timeZone: TimeZone;
    eatHereEnabled: boolean;
    serviceType: ServiceType;
    takeAwayEnabled: boolean;
    deliveryEnabled: boolean;
    autoCallRestaurantEnabled?: boolean;
    deliverySecurityCodeEnabled?: boolean;
    pideDirectoDeliveryEnabled?: boolean;
    uberDaasDeliveryEnabled?: boolean;
    deliveryManagerType?: DeliveryManagerType;
    uberDaasDeliveryVerificationPictureEnabled?: boolean;
    uberDaasOrdersWithCashEnabled?: boolean;
    returnCancelledOrdersToRestaurantEnabled?: boolean;
    sendOrderCompletedWebhookForReturnedOrders?: boolean;
    uberEatsDeliveryEnabled?: boolean;
    rappiCargoDeliveryEnabled?: boolean;
    rappiCargoOrdersWithCreditCardEnabled?: boolean;
    deliveryManagerMaxRuntime?: number;
    uberEatsIntegrationEnabled?: boolean;
    uberEatsStoreOnline?: boolean;
    uberEatsMenuShouldUseMenuHoursEnabled?: boolean;
    autoOpenClosedUberEatsStoreEnabled?: boolean;
    didiFoodStoreOnline?: boolean;
    plannedOrdersEnabled: boolean;
    cashPaymentsEnabled: boolean;
    ecommerceCashPaymentsToEatHereEnabled?: boolean;
    ecommerceCashPaymentsToTakeAwayEnabled?: boolean;
    creditCardPaymentsEnabled: boolean;
    paymentLinksEnabled: boolean;
    cardOnDeliveryPaymentsEnabled: boolean;
    maximumOrderAmountWithoutCardValidation?: string;
    automaticInvoiceEnabled?: boolean;
    cashPaymentsEnabledIn?: Array<App>;
    creditCartPaymentsEnabledIn?: Array<App>;
    paymentLinkPaymentsEnabledIn?: Array<App>;
    restaurantPromoCodesEnabled?: boolean;
    promoCodeBannerEnabled?: boolean;
    showDriverArriveAtStoreStatusOnEcommerceEnabled?: boolean;
    hideEcommerceDriverTipEnabled?: boolean;
    hideEcommerceRestaurantPhoneNumberEnabled?: boolean;
    preparationTimeInMinutes?: number;
    defaultPreparationTimeInMinutes: number;
    addTemporaryPreparationTimeEnabled?: boolean;
    acceptManuallyAllOrdersEnabled?: boolean;
    hidePlannedOrders: boolean;
    alertSound?: Sound;
    cancelledOrdersAlertSound?: Sound;
    cancelledOrdersAlertSoundEnabled?: boolean;
    addManuallyOrderPreparationTimeEnabled?: boolean;
    username: AwsCognitoUsername;
    zone?: Zone;
    contact?: string;
    contactNumber?: string;
    establishmentsPhoneNumber?: string;
    fiscalAddress?: string;
    addressOfEstablishment?: string;
    email?: EmailAddress;
    bank?: string;
    account?: string;
    clabe?: string;
    rfc?: string;
    regimeFiscal?: RegimeFiscal;
    businessName?: string;
    contactName?: string;
    emailInvoices?: string;
    paymentInEstablishment?: string;
    commissionWebCard?: string;
    commissionWebCash?: string;
    commissionWebCardOnDelivery?: string;
    commissionAppCard?: string;
    commissionAppCash?: string;
    commissionAppCardOnDelivery?: string;
    commissionManualOrders?: string;
    commissionRappiCargoWebCard?: string;
    commissionRappiCargoWebCash?: string;
    commissionRappiCargoAppCard?: string;
    commissionRappiCargoAppCash?: string;
    commissionRappiCargoManualOrder?: string;
    commissionRappiCargoPosCard?: string;
    commissionRappiCargoPosCash?: string;
    commissionUberDaasWebCard?: string;
    commissionUberDaasoWebCash?: string;
    commissionUberDaasAppCard?: string;
    commissionUberDaasAppCash?: string;
    commissionUberDaasManualOrder?: string;
    commissionUberDaasPosCard?: string;
    commissionUberDaasPosCash?: string;
    commissionOfficeDeliveryFixed?: string;
    device?: string;
    responsible?: string;
    bill?: string;
    sam?: string;
    uberEatsStoreId?: UberEatsStoreId;
    rappiStoreId?: RappiStoreId;
    rappiStoreCheckInCode?: string;
    rappiStoreCheckInCodeEnabled?: boolean;
    didiFoodStoreId?: DidiFoodStoreId;
    wansoftIntegrationEnabled?: boolean;
    wansoftStoreId?: WansoftStoreId;
    wansoftPassword?: string;
    restaurantPsps?: Array<RestaurantPsp>;
    restaurantPspsEnabled?: boolean;
    plexoIntegrationEnabled?: boolean;
    plexoStoreId?: PlexoStoreId;
    mercadoPagoPublicKey?: string;
    mercadoPagoSecretKey?: string;
    externalDelivery?: boolean;
    externalDeliveryEstimatedTime?: string;
    useNonGeoLocatedDeliveryAddressesEnabled?: boolean;
    manualOrdersEnabled?: boolean;
    externalCardPaymentsForManualOrdersEnabled?: boolean;
    externalCardPaymentsForManualOrdersAsDefaultEnabled?: boolean;
    orderPriceLimitForManualOrder?: string;
    pideDirectoProtectionForManualOrderAmount?: string;
    deliveryRadiusInKm?: number;
    customerDeliveryCostFixed?: number;
    customerDeliveryCostPerKm?: number;
    dynamicEarningsMinimumMinutes?: number;
    dynamicEarningsAmountToIncrementByMinute?: number;
    dynamicEarningsRoof?: number;
    longDistanceExtraDeliveryCostStartsFromKm?: number;
    longDistanceExtraDeliveryCostFixed?: number;
    longDistanceExtraDeliveryCostPerKm?: number;
    deliveryCommissionRange1?: string;
    deliveryCommissionRange2?: string;
    rappiCargoDefaultTip?: number;
    deliveryPaidByAndInvoicedToRestaurant?: boolean;
    invoiceOnlyDeliveryCostEnabled?: boolean;
    dynamicDeliveryManager?: boolean;
    deliveryOrchestratorEnabled?: boolean;
    cashDeliveryProviders?: Array<string>;
    waitTimePerDeliveryCashProviders?: {
        uberDaasWaitTime?: number;
        rappiCargoWaitTime?: number;
        pidedirectoWaitTime?: number;
    };
    cardDeliveryProviders?: Array<string>;
    waitTimePerDeliveryCardProviders?: {
        uberDaasWaitTime?: number;
        rappiCargoWaitTime?: number;
        pidedirectoWaitTime?: number;
    };
    deliveryProvidersInfo?: Array<DeliveryProvidersInfoVm>;
    defaultDeliveryRadiusInKm?: number;
    defaultCustomerDeliveryCostFixed?: number;
    defaultCustomerDeliveryCostPerKm?: number;
    defaultDynamicEarningsMinimumMinutes?: number;
    defaultDynamicEarningsAmountToIncrementByMinute?: number;
    defaultDynamicEarningsRoof?: number;
    defaultLongDistanceExtraDeliveryCostStartsFromKm?: number;
    defaultLongDistanceExtraDeliveryCostFixed?: number;
    defaultLongDistanceExtraDeliveryCostPerKm?: number;
    defaultSendThanksForOrderingEmailMinutesAfter?: number;
    defaultSendWelcomeEmailMinutesAfter?: number;
    defaultSendMenuHighlightEmailDaysAfter?: number;
    defaultSendPromoCodeEmailDaysAfter?: number;
    deliveryZonesEnabled?: boolean;
    webOrdersEnabled?: boolean;
    appOrdersEnabled?: boolean;
    whiteLabelOrdersEnabled?: boolean;
    brandMenuEnabled?: boolean;
    menuCategoryFamiliesEnabled?: boolean;
    limitProductsInOrder?: boolean;
    pideDirectoCustomTipPercentage?: number;
    pideDirectoDefaultTipPercentage?: boolean;
    pideDirectoTipAsPercentageEnabled?: boolean;
    pideDirectoTipEnabled?: number;
    restaurantServiceFeeEnabled?: boolean;
    restaurantServiceFeeRate?: number;
    connectWithMasterMenu?: RestaurantId;
    marketingEnabled?: boolean;
    addCreditsToCustomersEnabled?: boolean;
    posEnabled?: boolean;
    posSimpleView?: boolean;
    posPendingOrdersEnabled?: boolean;
    requestCustomerNameOnCreateOrderEnabled?: boolean;
    posUseMenuItemColorEnabled?: boolean;
    posRestaurantZonesEnabled?: boolean;
    posShowTablesEnabled?: boolean;
    posTableRequiredEnabled?: boolean;
    posCustomersRequiredEnabled?: boolean;
    posCustomDiscountCommentsEnabled?: boolean;
    posMultipleCashRegistersEnabled?: boolean;
    customerDisplayScreenEnabled?: boolean;
    customerDisplayScreenTouchEnabled?: boolean;
    customerDisplayScreenCustomTipEnabled?: boolean;
    customerDisplayScreenCustomTipPercentage?: number;
    customerDisplayScreenPromotionBannerImageUrl?: string;
    customerDisplayScreenSecondaryPromotionBannerImageUrl?: string;
    assignCashRegisterForExternalMissingOrdersEnabled?: boolean;
    avoidOpenCashBoxOnReprintTicketEnabled?: boolean;
    openCashRegisterForTransactionsEnabled?: boolean;
    openCashRegisterButtonEnabled?: boolean;
    printCashRegisterTransactionsEnabled?: boolean;
    maximumCashAllowedInCashRegisterEnabled?: boolean;
    maximumCashAllowedInCashRegister?: string;
    posCustomerNameRequiredOnTakeAwayOrdersEnabled?: boolean;
    posOnlyCashInCashRegisterReportEnabled?: boolean;
    showOngoingOrdersInOrdersSalesReportEnabled?: boolean;
    posPassword?: string;
    posTakeOrderByCourseEnabled?: boolean;
    authorizationToReprintCompletedOrderEnabled?: boolean;
    requestPasswordForClosePosBusinessDayEnabled?: boolean;
    preventClosePosBusinessDayWithOrdersNotInvoicedEnabled?: boolean;
    useOcaPaymentTerminalEnabled?: boolean;
    useQpayTerminalEnabled?: boolean;
    qpayTerminalCommission?: string;
    qpayTerminalDeviceId?: QpayTerminalDeviceId;
    acceptMultipleCurrenciesPaymentEnabled?: boolean;
    kioskQpayTerminalDeviceId?: QpayTerminalDeviceId;
    inventoryEnabled?: boolean;
    updateInventoryForCountsEnabled?: boolean;
    inventoryShowNegative?: boolean;
    inventoryLinkedToRestaurantId?: RestaurantId;
    inventoryLinkedToRestaurantName?: string;
    restaurantsLinkedToInventory?: Array<RestaurantLinkedToInventory>;
    buyOrdersEnabled?: boolean;
    moneyBillsPadEnabled?: boolean;
    demoModeEnabled?: boolean;
    userManagementEnabled?: boolean;
    clockInEmployeeDayShiftEnabled?: boolean;
    clockInMessage?: string;
    clockOutMessage?: string;
    supplyCategoryIds?: Array<SupplyCategoryId>;
    supplyLimitAlertEnabled?: boolean;
    appOrdersCashbackEnabled?: boolean;
    appOrdersCashback?: string;
    kioskEnabled?: boolean;
    kioskPayWithCreditCardInCounterEnabled?: boolean;
    kitchenDisplayScreenEnabled?: boolean;
    analyticsEnabled?: boolean;
    hideCartEnabled?: boolean;
    addTaxToTotalEnabled?: boolean;
    taxRate?: string;
    changeManualOrderCashCostEnabled?: boolean;
    // addTaxToTotalEnabled?: boolean,
    // taxRate?: string,
    idsInMenuEnabled?: boolean;
    hideMenuEditionEnabled?: boolean;
    fakedProductDiscountPercentage?: string;
    maximumOrderAmount?: string;
    maximumCashOrderAmount?: string;
    subModifiersEnabled?: boolean;
    promotionsEnabled?: boolean;
    refundOngoingOrdersEnabled?: boolean;
    markReadyForPickupButtonEnabled?: boolean;
    hideOpeningHoursEditionEnabled?: boolean;
    assignDriverEnabled?: boolean;
    urlSubdomain?: string;
    urlPathname?: string;
    whatsAppOrdersNumber?: string;
    facebookPixelId?: string;
    googleAnalyticsId?: string;
    cashRegisterWithdrawReasons?: CashRegisterCustomReasons;
    cashRegisterDepositReasons?: CashRegisterCustomReasons;
    customDiscountComments?: CustomDiscountComments;
    productCancellationReasons?: CustomProductCancellationReasons;
    pwa?: {
        name?: string;
        short_name?: string;
        favicon?: string;
        appicon?: string;
        splashicon?: string;
        theme_color?: string;
        background_color?: string;
    };
    restaurantPaymentMethodsEnabled?: boolean;
    paymentMethods?: Array<DeprecatedRestaurantPaymentMethodVm>;
    pwaDefaults: {
        name: string;
        short_name: string;
        favicon: string;
        appicon: string;
        splashicon: string;
        theme_color: string;
        background_color: string;
    };
    restaurantColors?: Array<string>;
    autoAcceptOrdersTime?: number;
    autoAcceptRappiOrdersTime?: number;
    autoAcceptUberEatsOrdersTime?: number;
    autoAcceptDidiFoodsOrdersTime?: number;
    printAutoAcceptedOrders?: boolean;
    hideDeliveryCostPaidByRestaurantOnTicketEnabled?: boolean;
    hideDeliveryEstimatedTimeEnabled?: boolean;
    pideDirectoApiKey?: PideDirectoApiKey;
    registrationStatus?: SelfRegistrationStatus;
    sendAppTrackingUrl?: boolean;
    askToTrackInApp?: boolean;
    ordersWithoutIvaEnabled?: boolean;
    restaurantTaxManagementEnabled?: boolean;
    sendOrderCreatedWebhookAtPlannedPickupTimeEnabled?: boolean;
    sendWebhookEventsForPosOrdersEnabled?: boolean;
    newOrdersPageEnabled?: boolean;
    heatMapDeliveryOrdersReportEnabled?: boolean;
    consecutiveOrderIdEnabled?: boolean;
    consecutiveOrderIdRange?: ConsecutiveOrderIdRange;
    restartConsecutiveOrderIdAt?: Date;
    consecutiveOrderId?: string;
    createdAt: Date;
    cutServiceDate?: Date;
    showOrderItemsToDriverEnabled: boolean;
    startDeliveryUntilPaymentLinkIsPaidEnabled?: boolean;
    lokiEnabled?: boolean;
    integrationsEnabled?: string;
    productLayout?: ProductLayout;
    roomServiceEnabled?: boolean;
    roomServiceCustomLabel?: string;
    roomServiceFixedDeliveryCost?: string;
    deliveryProviderSupportEnabled?: boolean;
    salesforceRestaurantType?: SalesforceRestaurantType;
    displayPeriodByDateAndTimeEnabled?: boolean;
    csdCertificateUploaded?: boolean;
    posTablesTabAsDefaultEnabled?: boolean;
    channelsContemplatedAtTheEndOfDayReport?: Array<App>;
    csdCertificateRestaurantId?: RestaurantId;
    useRappiQrCodeEnabled?: boolean;
    financeReference?: FinanceReference;
    financeReferenceEnabled?: boolean;
    includeBreakdownIntegrationsInPosBusinessDayEnabled?: boolean;
    closeOrdersEnabled?: boolean;
    printRappiQrCodeOnTicketEnabled?: boolean;
};

export type OpeningHoursVm = {
    monday: OpeningClosingTimeVm;
    tuesday: OpeningClosingTimeVm;
    wednesday: OpeningClosingTimeVm;
    thursday: OpeningClosingTimeVm;
    friday: OpeningClosingTimeVm;
    saturday: OpeningClosingTimeVm;
    sunday: OpeningClosingTimeVm;
};

export type DeliveryProvidersInfoVm = {
    deliveryProvider: DeliveryProvider;
    averageCost: string;
};

export type OpeningClosingTimeVm = {
    openingTime?: string;
    closingTime?: string;
};

export type PromoPricesVm = {
    enabled: boolean;
    hours: string;
};

export type RestaurantLinkedToInventory = {
    restaurantId: RestaurantId;
    restaurantName: string;
};

type CashRegisterCustomReasons = Array<{
    reason: CashRegisterCustomReason;
}>;

type CustomProductCancellationReasons = Array<{
    reason: CustomProductCancellationReason;
}>;

type CustomDiscountComments = Array<{
    comment: CustomDiscountComment;
}>;
