import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import LabelIcon from '@material-ui/icons/Label';
import * as React from 'react';
import type { OfficeDeliveryItemVm, Stats } from 'src/scenes/letseatadmin/OfficeDeliveryPage';
import { createAndSaveLabels } from 'src/utils/office-delivery/createAndSaveLabels';
import { isMobileApp } from 'src/utils/reactNative/isMobileApp';
import { sendDeliveryItemLabelsMessageToMobileApp } from 'src/utils/reactNative/sendDeliveryItemLabelsMessageToMobileApp';

export default function OfficeDeliveryStats({ stats, officeDeliveryItems }: Props): React.ReactElement {
    return (
        <>
            {Object.entries(stats)
                .sort((a, b) => {
                    return a[0] < b[0] ? -1 : 1;
                })
                .map(([key, stats]: [any, any]) => {
                    return (
                        <Grid key={key} container spacing={1}>
                            <Grid item xs={12}>
                                <Typography variant='h6'>
                                    {key}
                                    <Tooltip title='Labels'>
                                        <IconButton
                                            onClick={() => {
                                                const officeDeliveryItemsFiltered = officeDeliveryItems.filter((item) => item.pickupStationClosingTime === key);
                                                if (!isMobileApp()) {
                                                    createAndSaveLabels(officeDeliveryItemsFiltered, `labels-${key}`);
                                                    return;
                                                }

                                                sendDeliveryItemLabelsMessageToMobileApp(officeDeliveryItemsFiltered, `labels-${key}`);
                                            }}
                                        >
                                            <LabelIcon />
                                        </IconButton>
                                    </Tooltip>
                                </Typography>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <PickupStationStats stats={stats.pickupStationStats} />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <OfficeDeliveryItemStats stats={stats.officeDeliveryItemStats} />
                            </Grid>
                        </Grid>
                    );
                })}
        </>
    );
}

function PickupStationStats({ stats }: { stats: any }) {
    const data = Object.entries(stats);
    if (!data.length) {
        return null;
    }
    return (
        <table>
            <tbody>
                {data.map((entries, i) => {
                    const [pickupStation, numberOf] = entries as any;
                    return (
                        <tr key={i}>
                            <td>{numberOf}</td>
                            <td>{pickupStation}</td>
                        </tr>
                    );
                })}
            </tbody>
        </table>
    );
}

function OfficeDeliveryItemStats({ stats }: { stats: any }) {
    const data = Object.entries(stats);
    if (!data.length) {
        return null;
    }
    return (
        <table>
            <tbody>
                {data.map((entries, i) => {
                    const [deliveryItem, numberOf] = entries as any;
                    return (
                        <tr key={i}>
                            <td>{numberOf}</td>
                            <td>{deliveryItem}</td>
                        </tr>
                    );
                })}
            </tbody>
        </table>
    );
}

type Props = {
    stats: Stats;
    officeDeliveryItems: Array<OfficeDeliveryItemVm>;
};
