import { makeStyles } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import MUIDataTable from 'mui-datatables';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { DriverReferralsVm, findDriverReferralsApi } from 'src/api/letseatadmin/driverReferral/findDriverReferralsApi';
import { app2 } from 'src/app2';
import { RefreshToolbarButton } from 'src/components/mui-datatables/RefreshToolbarButton';
import { UpdatingContentProgress } from 'src/components/UpdatingContentProgress';
import { translate } from 'src/i18n/translate';
import { alertKnownErrorOrSomethingWentWrong } from 'src/utils/alert/alertKnownErrorOrSomethingWentWrong';
import { formatDateTimeString } from 'src/utils/date/formatDateTimeString';
import { useAction } from 'src/utils/react/useAction';
import { useSelector } from 'src/utils/react/useSelector';
import { openDriverPageInNewTab } from 'src/utils/window/openDriverPageInNewTab';

export function DriverReferralsPage(): React.ReactElement {
    const classes = useStyles();
    const viewUser = useSelector((state) => state.authentication.viewUser);

    const [loading, setLoading] = useState(false);
    const [vm, setVm] = useState({ driverReferrals: [] } as DriverReferralsVm);

    const setTitle = useAction(app2.actions.setTitle);

    useEffect(() => {
        setTitle(translate('Drive Referral Page'));
        load();
    }, []);

    const load = async () => {
        setLoading(true);
        const response = await findDriverReferralsApi();
        setLoading(false);
        if (!response.ok) {
            alertKnownErrorOrSomethingWentWrong(response);
            return;
        }
        setVm(response.data);
    };

    return (
        <div className={classes.container}>
            <UpdatingContentProgress loading={loading} />
            <MUIDataTable
                /* @ts-ignore */
                className={classes.table}
                data={
                    vm.driverReferrals &&
                    vm.driverReferrals.map((driverReferral) => {
                        return {
                            driverReferralId: driverReferral.driverReferralId,
                            referredDriverId: driverReferral.referredDriverId,
                            referredByDriverId: driverReferral.referredByDriverId,
                            deliveriesDelivered: driverReferral.deliveriesDelivered,
                            referredDriverEarnings: driverReferral.referredDriverEarnings,
                            referredByDriverEarnings: driverReferral.referredByDriverEarnings,
                            deliveriesToDeliver: driverReferral.deliveriesToDeliver,
                            expiresAt: formatDateTimeString(driverReferral.expiresAt),
                            expiredAt: formatDateTimeString(driverReferral.expiredAt),
                            completedAt: formatDateTimeString(driverReferral.completedAt),
                            startedAt: formatDateTimeString(driverReferral.startedAt),
                            createdAt: formatDateTimeString(driverReferral.createdAt),
                        };
                    })
                }
                columns={[
                    {
                        name: 'driverReferralId',
                        label: '',
                        options: {
                            display: 'excluded',
                            filter: false,
                        },
                    },
                    {
                        name: 'referredDriverId',
                        label: translate('referredDriverId'),
                        options: {
                            filter: false,
                            customBodyRender: (referredDriverId) => (
                                <Button size='small' color='primary' onClick={() => openDriverPageInNewTab(referredDriverId)}>
                                    {`${translate('Open Driver')} ${referredDriverId.substring(0, 4)}`}
                                </Button>
                            ),
                        },
                    },
                    {
                        name: 'referredByDriverId',
                        label: translate('referredByDriverId'),
                        options: {
                            filter: false,
                            customBodyRender: (referredByDriverId) => (
                                <Button size='small' color='primary' onClick={() => openDriverPageInNewTab(referredByDriverId)}>
                                    {`${translate('Open Driver')} ${referredByDriverId.substring(0, 4)}`}
                                </Button>
                            ),
                        },
                    },
                    {
                        name: 'deliveriesDelivered',
                        label: translate('deliveriesDelivered'),
                        options: {
                            filter: false,
                        },
                    },
                    {
                        name: 'referredDriverEarnings',
                        label: translate('referredDriverEarnings'),
                        options: {
                            filter: false,
                        },
                    },
                    {
                        name: 'referredByDriverEarnings',
                        label: translate('referredByDriverEarnings'),
                        options: {
                            filter: false,
                        },
                    },
                    {
                        name: 'deliveriesToDeliver',
                        label: translate('deliveriesToDeliver'),
                        options: {
                            filter: false,
                        },
                    },
                    {
                        name: 'startedAt',
                        label: translate('startedAt'),
                        options: {
                            filter: false,
                        },
                    },
                    {
                        name: 'expiresAt',
                        label: translate('expiresAt'),
                        options: {
                            filter: false,
                        },
                    },
                    {
                        name: 'expiredAt',
                        label: translate('expiredAt'),
                        options: {
                            filter: false,
                        },
                    },
                    {
                        name: 'completedAt',
                        label: translate('completedAt'),
                        options: {
                            filter: false,
                        },
                    },
                    {
                        name: 'createdAt',
                        label: translate('createdAt'),
                        options: {
                            filter: false,
                        },
                    },
                ]}
                options={{
                    responsive: 'standard',
                    tableBodyMaxHeight: '500px',
                    selectableRows: !viewUser ? 'multiple' : 'none',
                    filterType: 'checkbox',
                    rowsPerPage: 100,
                    customToolbar: () => (
                        <>
                            <RefreshToolbarButton onClick={load} />
                        </>
                    ),
                }}
            />
        </div>
    );
}

const useStyles = makeStyles((theme) => ({
    container: {
        position: 'relative',
    },
    table: {
        whiteSpace: 'nowrap',
        '& tr': { cursor: 'pointer' },
        '& tr:hover': { backgroundColor: 'rgba(0, 0, 0, 0.07) !important' },
    },
    toolbar: {
        paddingRight: theme.spacing(3),
    },
}));
