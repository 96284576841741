import { makeStyles } from '@material-ui/core';
import FormHelperText from '@material-ui/core/FormHelperText';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { changeTheRestaurantApi } from 'src/api/letseatmanager/theRestaurant/changeTheRestaurantApi';
import type { TheRestaurantVm } from 'src/api/letseatmanager/theRestaurant/getTheRestaurantApi';
import { Form } from 'src/components/form/Form';
import { FormIntegerField } from 'src/components/form/FormIntegerField';
import { FormNumberFieldWithIncrementalButtons } from 'src/components/form/FormNumberFieldWithIncrementalButtons';
import { FormSoundSelect } from 'src/components/form/FormSoundSelect';
import { FormSubmitButton } from 'src/components/form/FormSubmitButton';
import { FormSwitch } from 'src/components/form/FormSwitch';
import { getDirtyFormValues } from 'src/components/form/utils/getDirtyFormValues';
import { UpdatingContentProgress } from 'src/components/UpdatingContentProgress';
import { translate } from 'src/i18n/translate';
import { getIsPrintAutoAcceptedOrdersDeviceInLocalStorage } from 'src/localStorage/getIsPrintAutoAcceptedOrdersDeviceInLocalStorage';
import { setIsPrintAutoAcceptedOrdersDeviceInLocalStorage } from 'src/localStorage/setIsPrintAutoAcceptedOrdersDeviceInLocalStorage';
import { appReducer } from 'src/reducers/appReducer';
import { useReloadRestaurant } from 'src/services/restaurant/useReloadRestaurant';
import { SentryService } from 'src/services/SentryService';
import { alertKnownErrorOrSomethingWentWrong } from 'src/utils/alert/alertKnownErrorOrSomethingWentWrong';
import { useAction } from 'src/utils/react/useAction';
import { useSelector } from 'src/utils/react/useSelector';
import { playAlertSound } from 'src/utils/sound/playAlertSound';

export function RestaurantOrdersSettings({ loading, restaurant, onChangeRestaurant, setIsDirtyForm }: Props): React.ReactElement {
    const classes = useStyles();
    const form = useForm();
    const {
        watch,
        formState,
        formState: { dirtyFields },
    } = form;

    const defaultPreparationTimeInMinutes = watch('defaultPreparationTimeInMinutes');

    const uberEatsStoreId = watch('uberEatsStoreId');
    const rappiStoreId = watch('rappiStoreId');
    const didiFoodStoreId = watch('didiFoodStoreId');

    const restaurantId = useSelector((state) => state.app.restaurantId);
    const appLoading = useSelector((state) => state.app.loading);

    const [printAutoAcceptedOrders, setPrintAutoAcceptedOrders] = useState(getIsPrintAutoAcceptedOrdersDeviceInLocalStorage());

    const showSnackbar = useAction(appReducer.actions.showSnackbar);

    const { reloadRestaurant } = useReloadRestaurant();

    useEffect(() => {
        if (!loading) {
            form.reset({
                restaurantId: restaurant.restaurantId ?? null,
                autoAcceptOrdersTime: restaurant.autoAcceptOrdersTime ?? 0,
                alertSound: restaurant.alertSound ?? null,
                cancelledOrdersAlertSound: restaurant.cancelledOrdersAlertSound ?? null,
                cancelledOrdersAlertSoundEnabled: restaurant.cancelledOrdersAlertSoundEnabled ?? null,
                autoAcceptRappiOrdersTime: restaurant.autoAcceptRappiOrdersTime ?? 0,
                autoAcceptUberEatsOrdersTime: restaurant.autoAcceptUberEatsOrdersTime ?? 0,
                autoAcceptDidiFoodsOrdersTime: restaurant.autoAcceptDidiFoodsOrdersTime ?? 0,
                printAutoAcceptedOrders: printAutoAcceptedOrders ?? null,
                driverLinkedCode: restaurant.driverLinkedCode ?? null,
                externalDeliveryEstimatedTime: restaurant.externalDeliveryEstimatedTime ?? null,
                preparationTimeInMinutes: restaurant.preparationTimeInMinutes ?? null,
                hideDeliveryCostPaidByRestaurantOnTicketEnabled: restaurant.hideDeliveryCostPaidByRestaurantOnTicketEnabled ?? null,
                uberEatsStoreId: restaurant.uberEatsStoreId ?? null,
                rappiStoreId: restaurant.rappiStoreId ?? null,
                didiFoodStoreId: restaurant.didiFoodStoreId ?? null,
                hidePlannedOrders: restaurant.hidePlannedOrders ?? null,
                autoCallRestaurantEnabled: restaurant.autoCallRestaurantEnabled ?? null,
            });
        }
    }, [loading]);

    useEffect(() => {
        setIsDirtyForm(Object.keys(dirtyFields).length !== 0);
    }, [formState]);

    const onSubmit = async (form: any) => {
        const dirtyFormValues = getDirtyFormValues({ form, dirtyFields });
        setIsPrintAutoAcceptedOrdersDeviceInLocalStorage(form.printAutoAcceptedOrders);
        setPrintAutoAcceptedOrders(form.printAutoAcceptedOrders);
        const response = await changeTheRestaurantApi({
            restaurantId,
            ...dirtyFormValues,
        });

        if (!response.ok) {
            SentryService.logError('Failed to save the restaurant', { response });
            alertKnownErrorOrSomethingWentWrong(response);
            return;
        }

        await reloadRestaurant(restaurantId);
        await onChangeRestaurant();
        showSnackbar({ message: translate('Saved changes') });
    };

    return (
        <Paper className={classes.paper}>
            <UpdatingContentProgress loading={loading || appLoading} />
            <Form form={form} onSubmit={onSubmit}>
                <div className={classes.formContent}>
                    <Grid container spacing={3}>
                        <Grid item xs={12} md={6}>
                            <FormNumberFieldWithIncrementalButtons
                                helperText={`${translate('If the value is set to 0, the functionality will be disabled')}`}
                                label={translate('PideDirecto Auto Accept')}
                                text='min'
                                name='autoAcceptOrdersTime'
                                min={0}
                                max={10}
                            />
                        </Grid>
                    </Grid>
                    {rappiStoreId && (
                        <Grid container spacing={3}>
                            <Grid item xs={12} md={6}>
                                <FormNumberFieldWithIncrementalButtons label={translate('Rappi Auto Accept')} text='min' name='autoAcceptRappiOrdersTime' min={0} max={2.5} />
                            </Grid>
                        </Grid>
                    )}
                    {uberEatsStoreId && (
                        <Grid container spacing={3}>
                            <Grid item xs={12} md={6}>
                                <FormNumberFieldWithIncrementalButtons label={translate('Uber Eats Auto Accept')} text='min' name='autoAcceptUberEatsOrdersTime' min={0} max={10} />
                            </Grid>
                        </Grid>
                    )}
                    {didiFoodStoreId && (
                        <Grid container spacing={3}>
                            <Grid item xs={12} md={6}>
                                <FormNumberFieldWithIncrementalButtons label={translate('Didi Foods Auto Accept')} text='min' name='autoAcceptDidiFoodsOrdersTime' min={0} max={4} />
                            </Grid>
                        </Grid>
                    )}
                    <Grid item xs={12} md={6}>
                        <FormSwitch
                            label={translate('Print Auto Accepted Orders')}
                            name='printAutoAcceptedOrders'
                            tooltip={translate('Enable this option if you want dropped orders to be printed, includes all platforms')}
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <FormSwitch
                            name='hideDeliveryCostPaidByRestaurantOnTicketEnabled'
                            label={translate('Do not print on ticket delivery cost when is paid by restaurant')}
                            helperText={translate('When enabled, delivery cost is not printed if it has been paid by the restaurant')}
                        />
                    </Grid>

                    <Grid container spacing={3}>
                        <Grid item xs={12} sm={6}>
                            <FormIntegerField
                                name='preparationTimeInMinutes'
                                label={translate('Preparation Time (in minutes)')}
                                helperText={`${translate('If the value is set to 0, the functionality will be disabled.')}`}
                                placeholder={defaultPreparationTimeInMinutes ? `${defaultPreparationTimeInMinutes}` : '15'}
                                min={0}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <FormSoundSelect name='alertSound' label={translate('Alert Sound')} onChange={(sound) => playAlertSound(sound)} />
                        </Grid>
                    </Grid>
                    <Grid container spacing={3}>
                        <Grid item xs={12} sm={6}>
                            <FormSwitch
                                name='cancelledOrdersAlertSoundEnabled'
                                label={translate('Alert sound for cancelled orders')}
                                helperText={translate('If enabled orders when cancelled will sound and display a message.')}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <FormSoundSelect name='cancelledOrdersAlertSound' label={translate('Alert sound for cancelled orders')} onChange={(sound) => playAlertSound(sound)} />
                        </Grid>
                    </Grid>
                    <Grid container spacing={3}>
                        <Grid item xs={12} sm={6}>
                            <FormSwitch name='hidePlannedOrders' label={translate('Show orders at preparation time')} />
                            <FormHelperText>
                                {translate(
                                    'Orders will be only shown when it is time to start preparing them (pickup time - preparation time). Does not apply neither for integrations nor planned manual orders.',
                                )}
                            </FormHelperText>
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <FormSwitch
                            name='autoCallRestaurantEnabled'
                            label={translate('Auto call restaurant')}
                            tooltip={translate('Auto call restaurant and notify when a pending order its not accepted in time.')}
                        />
                    </Grid>
                </div>

                <Grid container justify='center' spacing={3}>
                    <Grid item>
                        <FormSubmitButton text={translate('Change')} disabled={loading || appLoading} classes={{ container: classes.submitButton }} />
                    </Grid>
                </Grid>
            </Form>
        </Paper>
    );
}

const useStyles = makeStyles((theme) => ({
    paper: {
        position: 'relative',
    },
    formContent: {
        padding: theme.spacing(3),
    },
    section: {
        width: '100%',
        borderRadius: 5,
        margin: '20px auto',
        border: `1px solid ${theme.palette.secondary.dark}`,
    },
    sectionTitleContainer: {
        backgroundColor: theme.palette.secondary.light,
        marginTop: 20,
    },
    sectionTitle: {
        fontFamily: theme.typography.semiBold,
        fontSize: 14,
    },
    title: {
        fontFamily: theme.typography.semiBold,
        fontSize: 20,
        color: theme.palette.secondary.contrastText,
        marginLeft: 10,
    },
    subtitle: {
        fontFamily: theme.typography.medium,
        fontSize: 18,
        color: theme.palette.secondary.contrastText,
        margin: 0,
    },
    text: {
        fontFamily: theme.typography.regular,
        fontSize: 15,
    },
    integrationStoreStatusClosed: {
        fontFamily: theme.typography.semiBold,
        color: '#fa6684',
        fontSize: 13,
    },
    integrationStoreStatusOpen: {
        fontFamily: theme.typography.semiBold,
        color: theme.palette.primary.main,
        fontSize: 13,
    },
    submitButton: {
        zIndex: 1000,
    },
}));

type Props = {
    loading: boolean;
    restaurant: TheRestaurantVm;
    onChangeRestaurant: any;
    setIsDirtyForm: any;
};
