import { Grid, makeStyles, useTheme } from '@material-ui/core';
import Chip from '@material-ui/core/Chip';
import { ScreenIcon } from '@pidedirecto/ui/icons';
import { useEffect, useState } from 'react';
import * as React from 'react';
import { useForm } from 'react-hook-form';
import { findDevicesApi } from 'src/api/letseatmanager/device/findDevicesApi';
import { changeDeviceGroupApi } from 'src/api/letseatmanager/deviceGroup/changeDeviceGroupApi';
import { getDeviceGroupApi } from 'src/api/letseatmanager/deviceGroup/getDeviceGroupApi';
import { findPaymentTerminalsApi } from 'src/api/letseatmanager/paymentTerminal/findPaymentTerminalsApi';
import { findPrintersApi } from 'src/api/letseatmanager/printer/findPrintersApi';
import { Button } from 'src/components/Button';
import { Dialog } from 'src/components/Dialog';
import { DialogActions } from 'src/components/DialogActions';
import { Form } from 'src/components/form/Form';
import { SelectableList } from 'src/components/SelectableList';
import { Text } from 'src/components/Text';
import { translate } from 'src/i18n/translate';
import { DeviceIcon } from 'src/icons/DeviceIcon';
import { PrinterIcon } from 'src/icons/PrinterIcon';
import { SimpleTerminalIcon } from 'src/icons/SimpleTerminalIcon';
import { getDeviceId } from 'src/services/device/getDeviceId';
import { useReloadRestaurant } from 'src/services/restaurant/useReloadRestaurant';
import type { DeviceGroupId, DeviceId, PaymentTerminalId, PrinterId } from 'src/types/Id';
import type { PrinterVm } from 'src/types/PrinterVm';
import { alertKnownErrorOrSomethingWentWrong } from 'src/utils/alert/alertKnownErrorOrSomethingWentWrong';
import { isCustomerDisplayScreenDevice } from 'src/utils/device/isCustomerDisplayScreenDevice';
import { useLoadApi } from 'src/utils/react/useLoadApi';
import { useSelector } from 'src/utils/react/useSelector';
import { translateDeviceBrand } from 'src/utils/translate/translateDeviceBrand';
import { translateDeviceModel } from 'src/utils/translate/translateDeviceModel';
import { translateDeviceOs } from 'src/utils/translate/translateDeviceOs';
import { translateDeviceType } from 'src/utils/translate/translateDeviceType';
import { toShortId } from 'src/utils/uuid/toShortId';

export function AddDeviceDialog({ open, deviceGroupId, onClose, onSuccess }: Props): React.ReactElement | null {
    const classes = useStyles();
    const theme = useTheme();
    const form = useForm();

    const [selectedDeviceIds, setSelectedDeviceIds] = useState<Array<any>>([]);
    const [changingDeviceGroup, setChangingDeviceGroup] = useState(false);

    const restaurantId = useSelector((state) => state.app.restaurantId);

    const [loadingDevices, devices] = useLoadApi(findDevicesApi, { restaurantId }, { initialValue: [], requiredValues: [open ? true : null] });
    const [loadingPrinters, printers] = useLoadApi(findPrintersApi, { restaurantId }, { initialValue: [], requiredValues: [open ? true : null] });
    const [loadingPaymentTerminals, paymentTerminals] = useLoadApi(findPaymentTerminalsApi, { restaurantId }, { initialValue: [], requiredValues: [open ? true : null] });
    const [loadingDeviceGroup, deviceGroup] = useLoadApi(getDeviceGroupApi, { deviceGroupId: deviceGroupId! }, { initialValue: [], requiredValues: [deviceGroupId, open ? true : null] });

    const { reloadRestaurant } = useReloadRestaurant();

    const loading = loadingDevices || loadingPrinters || loadingPaymentTerminals || loadingDeviceGroup || changingDeviceGroup;

    useEffect(() => {
        if (deviceGroup) {
            form.reset({
                deviceGroupName: deviceGroup?.deviceGroupName,
            });
            const printerIds = deviceGroup.printerIds ?? [];
            const deviceIds = deviceGroup.deviceIds ?? [];
            const paymentTerminalIds = deviceGroup.paymentTerminalIds ?? [];
            setSelectedDeviceIds([...printerIds, ...deviceIds, ...paymentTerminalIds]);
        }
    }, [deviceGroup]);

    const changeDeviceGroup = async (form: any) => {
        if (!deviceGroupId) return;
        if (!deviceGroup) return;
        setChangingDeviceGroup(true);
        const { deviceIds, printerIds, paymentTerminalIds } = separateSelectedDeviceIds();
        const response = await changeDeviceGroupApi({
            deviceGroupId,
            deviceGroupName: deviceGroup.deviceGroupName,
            deviceIds,
            printerIds,
            paymentTerminalIds,
        });
        setChangingDeviceGroup(false);
        if (!response.ok) {
            alertKnownErrorOrSomethingWentWrong(response);
            return;
        }
        reloadRestaurant(restaurantId);
        onSuccess?.();
        onClose();
    };

    const separateSelectedDeviceIds = () => {
        const deviceIds: Array<DeviceId> = selectedDeviceIds.filter((selectedDeviceId) => devices.some((device) => device.deviceId === selectedDeviceId));
        const printerIds: Array<PrinterId> = selectedDeviceIds.filter((selectedDeviceId) => printers.some((printer) => printer.printerId === selectedDeviceId));
        const paymentTerminalIds: Array<PaymentTerminalId> = selectedDeviceIds.filter((selectedDeviceId) =>
            paymentTerminals.some((paymentTerminal) => paymentTerminal.paymentTerminalId === selectedDeviceId),
        );
        return {
            deviceIds,
            printerIds,
            paymentTerminalIds,
        };
    };

    const handleSelectDeviceIds = (deviceIds: Array<any>) => {
        const currentDeviceIds = deviceGroup?.deviceIds || [];
        const currentPrintersIds = deviceGroup?.printerIds || [];
        const currentPaymentTerminalIds = deviceGroup?.paymentTerminalIds || [];
        setSelectedDeviceIds([...currentDeviceIds, ...currentPrintersIds, ...currentPaymentTerminalIds, deviceIds[0]]);
    };

    const filterDeviceList = () => {
        const currentDevices = devices.filter((device) => !deviceGroup?.deviceIds?.includes(device.deviceId));
        const currentPrinters = printers.filter((printer) => !deviceGroup?.printerIds?.includes(printer.printerId));
        const currentPaymentTerminals = paymentTerminals.filter((paymentTerminal) => !deviceGroup?.paymentTerminalIds?.includes(paymentTerminal.paymentTerminalId));

        return [
            ...currentDevices.map((device) => ({
                value: device.deviceId,
                content: (
                    <div className={classes.content}>
                        {isCustomerDisplayScreenDevice(device.deviceType) ? <ScreenIcon color={theme.palette.icons.primary} /> : <DeviceIcon color={theme.palette.icons.primary} />}
                        <Text>{device.deviceName || toShortId(device.deviceId)}</Text>
                        {getDeviceId() === device.deviceId ? (
                            <Chip label={translate('This device')} color='primary' size='small' />
                        ) : (
                            <>
                                <Text className={classes.deviceLabel}>{translateDeviceOs(device.deviceOs)}</Text>
                                {device.deviceBrand && <Text className={classes.deviceLabel}>{translateDeviceBrand(device.deviceBrand)}</Text>}
                                <Text className={classes.deviceLabel}>{translateDeviceModel(device.deviceModel) || translateDeviceType(device.deviceType)}</Text>
                            </>
                        )}
                    </div>
                ),
            })),
            ...currentPaymentTerminals.map((paymentTerminal) => ({
                value: paymentTerminal.paymentTerminalId,
                content: (
                    <div className={classes.content}>
                        <SimpleTerminalIcon color={theme.palette.icons.primary} />
                        <Text>{paymentTerminal.deviceName}</Text>
                    </div>
                ),
            })),
            ...currentPrinters?.map((printer: PrinterVm) => ({
                value: printer.printerId,
                content: (
                    <div className={classes.content}>
                        <PrinterIcon color={theme.palette.icons.primary} />
                        <Text>{printer.deviceName || toShortId(printer.printerId)}</Text>
                    </div>
                ),
            })),
        ];
    };

    const handleClose = () => {
        if (loading) return;
        onClose();
    };

    useEffect(() => {
        if (deviceGroup) {
            const printerIds = deviceGroup.printerIds ?? [];
            const deviceIds = deviceGroup.deviceIds ?? [];
            const paymentTerminalIds = deviceGroup.paymentTerminalIds ?? [];
            setSelectedDeviceIds([...printerIds, ...deviceIds, ...paymentTerminalIds]);
        }
    }, [deviceGroup]);

    if (!open) return null;

    return (
        <Dialog
            open={open}
            onClose={handleClose}
            title={deviceGroup?.deviceGroupName ? translate('Add device to @deviceGroupName', { deviceGroupName: deviceGroup?.deviceGroupName }) : translate('Add device to this group')}
            loading={loading}
            classes={{ dialog: classes.dialog }}
        >
            <Form form={form} onSubmit={changeDeviceGroup}>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <SelectableList
                            title={translate('Select the device to add')}
                            onChange={(values) => {
                                handleSelectDeviceIds(values);
                            }}
                            options={filterDeviceList()}
                            classes={{ listContainer: classes.deviceListContainer }}
                        />
                    </Grid>
                </Grid>
                <DialogActions>
                    <Button secondary onClick={handleClose} disabled={loading}>
                        {translate('Cancel')}
                    </Button>
                    <Button type='submit' disabled={loading}>
                        {translate('Save')}
                    </Button>
                </DialogActions>
            </Form>
        </Dialog>
    );
}

const useStyles = makeStyles((theme) => ({
    dialog: {
        width: '50vw',
        [theme.breakpoints.down('sm')]: {
            width: '90vw',
        },
    },
    content: {
        display: 'flex',
        alignItems: 'center',
        gap: 8,
        width: '100%',
    },
    dialogActions: {
        justifyContent: 'space-between',
    },
    buttonsContainer: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        gap: 10,
    },
    icon: {
        display: 'grid',
        placeContent: 'center',
        width: 24,
    },
    deviceListContainer: {
        maxHeight: '360px',
        padding: '12px 0',
        overflow: 'scroll',
    },
    deviceLabel: {
        color: '#999999',
        fontSize: 12,
    },
}));

type Props = {
    open: boolean;
    deviceGroupId: DeviceGroupId | undefined;
    onClose: any;
    onSuccess?: any;
};
