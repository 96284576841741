import * as React from 'react';

export function QuestionIcon({ color }: Props): React.ReactElement {
    return (
        <svg width='12' height='12' viewBox='0 0 10 10' fill='none' xmlns='http://www.w3.org/2000/svg'>
            <path
                fillRule='evenodd'
                clipRule='evenodd'
                d='M0 5C0 2.23846 2.23846 0 5 0C7.76154 0 10 2.23846 10 5C10 7.76154 7.76154 10 5 10C2.23846 10 0 7.76154 0 5ZM5.83487 2.99128C5.37846 2.59282 4.62154 2.59282 4.16564 2.99128C4.08886 3.05847 3.98854 3.09241 3.88674 3.08563C3.78494 3.07885 3.69001 3.0319 3.62282 2.95513C3.55563 2.87835 3.5217 2.77803 3.52848 2.67623C3.53526 2.57443 3.5822 2.4795 3.65897 2.41231C4.40462 1.76 5.59538 1.76 6.34103 2.41231C7.11692 3.09128 7.11692 4.21641 6.34103 4.89538C6.21127 5.00853 6.0669 5.10374 5.9118 5.17846C5.56513 5.34667 5.38462 5.57539 5.38462 5.76923V6.15385C5.38462 6.25585 5.34409 6.35368 5.27196 6.42581C5.19983 6.49794 5.10201 6.53846 5 6.53846C4.89799 6.53846 4.80017 6.49794 4.72804 6.42581C4.65591 6.35368 4.61539 6.25585 4.61539 6.15385V5.76923C4.61539 5.11333 5.15897 4.68872 5.57692 4.48615C5.67026 4.44103 5.75692 4.3841 5.83487 4.31641C6.26051 3.94359 6.26051 3.3641 5.83487 2.99128ZM5 8.07692C5.10201 8.07692 5.19983 8.0364 5.27196 7.96427C5.34409 7.89214 5.38462 7.79431 5.38462 7.69231C5.38462 7.5903 5.34409 7.49247 5.27196 7.42034C5.19983 7.34821 5.10201 7.30769 5 7.30769C4.89799 7.30769 4.80017 7.34821 4.72804 7.42034C4.65591 7.49247 4.61539 7.5903 4.61539 7.69231C4.61539 7.79431 4.65591 7.89214 4.72804 7.96427C4.80017 8.0364 4.89799 8.07692 5 8.07692Z'
                fill={color}
            />
        </svg>
    );
}

type Props = {
    color: string;
};
