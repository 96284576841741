import { createStore } from '@pidedirecto/ui/hooks';
import { MexicanPaymentMethodCode } from 'src/constants/MexicanPaymentMethodCode';
import type { PaymentMethod } from 'src/constants/PaymentMethod';
import type { DeprecatedRestaurantPaymentMethodVm } from 'src/types/DeprecatedRestaurantPaymentMethodVm';
import type { CustomPaymentMethod } from 'src/types/Id';
import { RestaurantPaymentMethodVm } from 'src/types/RestaurantPaymentMethodVm';

export const [usePosPaymentMethodsStore, usePosPaymentMethodsActions] = createStore<State, Actions>({
    initialState: {
        posPaymentMethodsDeprecated: [],
        posPaymentMethods: [],
        paymentMethod: undefined,
        customPaymentMethod: undefined,
        paymentReferenceEnabled: false,
        mexicanPaymentMethodCode: undefined,
    },
    actions: {
        setPosPaymentMethodsDeprecated: (state, posPaymentMethodsDeprecated) => {
            state.posPaymentMethodsDeprecated = posPaymentMethodsDeprecated;
        },
        setPosPaymentMethods: (state, posPaymentMethods) => {
            state.posPaymentMethods = posPaymentMethods;
        },
        setPosPaymentMethod: (state, { paymentMethod, customPaymentMethod, paymentReferenceEnabled, mexicanPaymentMethodCode }: SetPosPaymentMethodParams) => {
            state.paymentMethod = paymentMethod;
            state.customPaymentMethod = customPaymentMethod;
            state.paymentReferenceEnabled = !!paymentReferenceEnabled;
            state.mexicanPaymentMethodCode = mexicanPaymentMethodCode;
        },
        clearPosPaymentMethod: (state) => {
            state.paymentMethod = undefined;
            state.customPaymentMethod = undefined;
        },
    },
});

type State = {
    posPaymentMethodsDeprecated: Array<DeprecatedRestaurantPaymentMethodVm>;
    posPaymentMethods: Array<RestaurantPaymentMethodVm>;
    paymentMethod: PaymentMethod | undefined;
    customPaymentMethod: CustomPaymentMethod | undefined;
    paymentReferenceEnabled: boolean;
    mexicanPaymentMethodCode: MexicanPaymentMethodCode | undefined;
};

export type Actions = {
    setPosPaymentMethodsDeprecated: (posPaymentMethodsDeprecated: Array<DeprecatedRestaurantPaymentMethodVm>) => void;
    setPosPaymentMethods: (posPaymentMethods: Array<RestaurantPaymentMethodVm>) => void;
    setPosPaymentMethod: (arg1: SetPosPaymentMethodParams) => void;
    clearPosPaymentMethod: () => void;
};

export type SetPosPaymentMethodParams = {
    paymentMethod: PaymentMethod | undefined;
    customPaymentMethod: CustomPaymentMethod | undefined;
    paymentReferenceEnabled?: boolean;
    mexicanPaymentMethodCode?: MexicanPaymentMethodCode;
};
