export const LogEventTypes = {
    PIDE_DIRECTO_ADMIN_USER_CREATED: 'PIDE_DIRECTO_ADMIN_USER_CREATED',
    PIDE_DIRECTO_ADMIN_USER_CHANGED: 'PIDE_DIRECTO_ADMIN_USER_CHANGED',
    PIDE_DIRECTO_ADMIN_USER_PASSWORD_CHANGED: 'PIDE_DIRECTO_ADMIN_USER_PASSWORD_CHANGED',
    PIDE_DIRECTO_ADMIN_USER_REMOVED: 'PIDE_DIRECTO_ADMIN_USER_REMOVED',
    PIDE_DIRECTO_SETTINGS_CHANGED: 'PIDE_DIRECTO_SETTINGS_CHANGED',
    ORDER_CREATED: 'ORDER_CREATED',
    ORDER_ACCEPTED: 'ORDER_ACCEPTED',
    ORDER_COMPLETED: 'ORDER_COMPLETED',
    ORDER_REJECTED: 'ORDER_REJECTED',
    ORDER_RETURNED: 'ORDER_RETURNED',
    ORDER_CANCELLED: 'ORDER_CANCELLED',
    RAPPI_INTEGRATED: 'RAPPI_INTEGRATED',
    RAPPI_INTEGRATION_FAILED: 'RAPPI_INTEGRATION_FAILED',
    RAPPI_DISINTEGRATED: 'RAPPI_DISINTEGRATED',
    RAPPI_DISINTEGRATION_FAILED: 'RAPPI_DISINTEGRATION_FAILED',
    RAPPI_STORE_OPENED: 'RAPPI_STORE_OPENED',
    RAPPI_STORE_CLOSED: 'RAPPI_STORE_CLOSED',
    DELIVER_ORDERS_FROM_RAPPI_ENABLED: 'DELIVER_ORDERS_FROM_RAPPI_ENABLED',
    DELIVER_ORDERS_FROM_RAPPI_DISABLED: 'DELIVER_ORDERS_FROM_RAPPI_DISABLED',
    DIDI_FOOD_INTEGRATED: 'DIDI_FOOD_INTEGRATED',
    DIDI_FOOD_INTEGRATION_FAILED: 'DIDI_FOOD_INTEGRATION_FAILED',
    DIDI_FOOD_DISINTEGRATED: 'DIDI_FOOD_DISINTEGRATED',
    DIDI_FOOD_DISINTEGRATION_FAILED: 'DIDI_FOOD_DISINTEGRATION_FAILED',
    DIDI_FOOD_STORE_OPENED: 'DIDI_FOOD_STORE_OPENED',
    DIDI_FOOD_STORE_CLOSED: 'DIDI_FOOD_STORE_CLOSED',
    DIDI_FOOD_STORE_IS_CLOSED_WHEN_IT_SHOULD_BE_OPEN: 'DIDI_FOOD_STORE_IS_CLOSED_WHEN_IT_SHOULD_BE_OPEN',
    DELIVER_ORDERS_FROM_DIDI_FOOD_ENABLED: 'DELIVER_ORDERS_FROM_DIDI_FOOD_ENABLED',
    DELIVER_ORDERS_FROM_DIDI_FOOD_DISABLED: 'DELIVER_ORDERS_FROM_DIDI_FOOD_DISABLED',
    UBER_EATS_INTEGRATED: 'UBER_EATS_INTEGRATED',
    UBER_EATS_INTEGRATION_FAILED: 'UBER_EATS_INTEGRATION_FAILED',
    UBER_EATS_DISINTEGRATED: 'UBER_EATS_DISINTEGRATED',
    UBER_EATS_DISINTEGRATION_FAILED: 'UBER_EATS_DISINTEGRATION_FAILED',
    UBER_EATS_INTEGRATION_ENABLED: 'UBER_EATS_INTEGRATION_ENABLED',
    UBER_EATS_INTEGRATION_DISABLED: 'UBER_EATS_INTEGRATION_DISABLED',
    UBER_EATS_STORE_OPENED: 'UBER_EATS_STORE_OPENED',
    UBER_EATS_STORE_CLOSED: 'UBER_EATS_STORE_CLOSED',
    DELIVER_ORDERS_FROM_UBER_EATS_ENABLED: 'DELIVER_ORDERS_FROM_UBER_EATS_ENABLED',
    DELIVER_ORDERS_FROM_UBER_EATS_DISABLED: 'DELIVER_ORDERS_FROM_UBER_EATS_DISABLED',
    CARD_PAYMENT_PRE_AUTHORIZED: 'CARD_PAYMENT_PRE_AUTHORIZED',
    CARD_PAYMENT_PAID: 'CARD_PAYMENT_PAID',
    CARD_PAYMENT_FAILED: 'CARD_PAYMENT_FAILED',
    CARD_PAYMENT_REFUNDED: 'CARD_PAYMENT_REFUNDED',
    REJECTED_ORDER_REFUNDED: 'REJECTED_ORDER_REFUNDED',
    CARD_ADDED: 'CARD_ADDED',
    ADDING_CARD_FAILED: 'ADDING_CARD_FAILED',
    CARD_REMOVED: 'CARD_REMOVED',
    CARD_VALIDATED: 'CARD_VALIDATED',
    CARD_VALIDATION_FAILED: 'CARD_VALIDATION_FAILED',
    CARD_BLOCKED: 'CARD_BLOCKED',
    MERCADO_PAGO_WEBHOOK_EVENT_RECEIVED: 'MERCADO_PAGO_WEBHOOK_EVENT_RECEIVED',
    QPAY_TERMINAL_INSTRUCTION_RESPONSE_RECEIVED: 'QPAY_TERMINAL_INSTRUCTION_RESPONSE_RECEIVED',
    SUBSCRIPTION_CREATED: 'SUBSCRIPTION_CREATED',
    SUBSCRIPTION_CHANGED: 'SUBSCRIPTION_CHANGED',
    SUBSCRIPTION_REMOVED: 'SUBSCRIPTION_REMOVED',
    SUBSCRIPTION_CARD_ADDED: 'SUBSCRIPTION_CARD_ADDED',
    SUBSCRIPTION_CARD_REMOVED: 'SUBSCRIPTION_CARD_REMOVED',
    RESTAURANT_SETTINGS_CHANGED: 'RESTAURANT_SETTINGS_CHANGED',
    RESTAURANT_OPENING_HOURS_CHANGED: 'RESTAURANT_OPENING_HOURS_CHANGED',
    MENU_CHANGED: 'MENU_CHANGED',
    SYNCED_MENU_WITH_INTEGRATIONS: 'SYNCED_MENU_WITH_INTEGRATIONS',
    FAILED_TO_SYNC_MENU_WITH_INTEGRATIONS: 'FAILED_TO_SYNC_MENU_WITH_INTEGRATIONS',
    SYNCED_MENU_WITH_UBER_EATS: 'SYNCED_MENU_WITH_UBER_EATS',
    FAILED_TO_SYNC_MENU_WITH_UBER_EATS: 'FAILED_TO_SYNC_MENU_WITH_UBER_EATS',
    SYNCED_MENU_WITH_DIDI_FOOD: 'SYNCED_MENU_WITH_DIDI_FOOD',
    FAILED_TO_SYNC_MENU_WITH_DIDI_FOOD: 'FAILED_TO_SYNC_MENU_WITH_DIDI_FOOD',
    SYNCED_MENU_WITH_RAPPI: 'SYNCED_MENU_WITH_RAPPI',
    FAILED_TO_SYNC_MENU_WITH_RAPPI: 'FAILED_TO_SYNC_MENU_WITH_RAPPI',
    FIREBASE_NOTIFICATION_SENT_TO_CUSTOMER: 'FIREBASE_NOTIFICATION_SENT_TO_CUSTOMER',
    FAILED_TO_SEND_FIREBASE_NOTIFICATION_TO_CUSTOMER: 'FAILED_TO_SEND_FIREBASE_NOTIFICATION_TO_CUSTOMER',
    FIREBASE_NOTIFICATION_SENT_TO_DRIVER: 'FIREBASE_NOTIFICATION_SENT_TO_DRIVER',
    FAILED_TO_SEND_FIREBASE_NOTIFICATION_TO_DRIVER: 'FAILED_TO_SEND_FIREBASE_NOTIFICATION_TO_DRIVER',
    SMS_SENT: 'SMS_SENT',
    FAILED_TO_SEND_SMS: 'FAILED_TO_SEND_SMS',
    WHATSAPP_MESSAGE_SENT: 'WHATSAPP_MESSAGE_SENT',
    FAILED_TO_SEND_WHATSAPP_MESSAGE: 'FAILED_TO_SEND_WHATSAPP_MESSAGE',
    MADE_AWS_CONNECT_PHONE_CALL: 'MADE_AWS_CONNECT_PHONE_CALL',
    FAILED_TO_MAKE_AWS_CONNECT_PHONE_CALL: 'FAILED_TO_MAKE_AWS_CONNECT_PHONE_CALL',
    EMAIL_SENT: 'EMAIL_SENT',
    DELIVERY_MANUALLY_COMPLETED_BY_SUPPORT: 'DELIVERY_MANUALLY_COMPLETED_BY_SUPPORT',
    DELIVERY_REQUEST_SENT_TO_DRIVER: 'DELIVERY_REQUEST_SENT_TO_DRIVER',
    MANUAL_DYNAMIC_DELIVERY_EARNINGS_CHANGED: 'MANUAL_DYNAMIC_DELIVERY_EARNINGS_CHANGED',
    AUTOMATIC_DYNAMIC_DELIVERY_EARNINGS_CHANGED: 'AUTOMATIC_DYNAMIC_DELIVERY_EARNINGS_CHANGED',
    RESTAURANT_MANUAL_EARNING_CREATED: 'RESTAURANT_MANUAL_EARNING_CREATED',
    RESTAURANT_MANUAL_EARNING_CHANGED: 'RESTAURANT_MANUAL_EARNING_CHANGED',
    RESTAURANT_MANUAL_EARNING_FOR_SUBSCRIPTION_PAY_PER_USE_CREATED: 'RESTAURANT_MANUAL_EARNING_FOR_SUBSCRIPTION_PAY_PER_USE_CREATED',
    DRIVER_MANUAL_EARNING_CREATED: 'DRIVER_MANUAL_EARNING_CREATED',
    DRIVER_MANUAL_EARNING_CHANGED: 'DRIVER_MANUAL_EARNING_CHANGED',
    DRIVER_REQUESTED: 'DRIVER_REQUESTED',
    DRIVER_ARRIVED_AT_STORE: 'DRIVER_ARRIVED_AT_STORE',
    DRIVER_ARRIVED_AT_CLIENT: 'DRIVER_ARRIVED_AT_CLIENT',
    DRIVER_MANUALLY_ASSIGNED_TO_ORDER: 'DRIVER_MANUALLY_ASSIGNED_TO_ORDER',
    DELIVERY_PROVIDER_SWITCHED_TO_UBER_DIRECT: 'DELIVERY_PROVIDER_SWITCHED_TO_UBER_DIRECT',
    DELIVERY_PROVIDER_SWITCHED_TO_UBER_DAAS: 'DELIVERY_PROVIDER_SWITCHED_TO_UBER_DAAS',
    DELIVERY_PROVIDER_SWITCHED_TO_PIDE_DIRECTO: 'DELIVERY_PROVIDER_SWITCHED_TO_PIDE_DIRECTO',
    DELIVERY_PROVIDER_SWITCHED_TO_RAPPI_CARGO: 'DELIVERY_PROVIDER_SWITCHED_TO_RAPPI_CARGO',
    DELIVERY_REQUESTED_IN_UBER_DAAS: 'DELIVERY_REQUESTED_IN_UBER_DAAS',
    DELIVERY_ACCEPTED_IN_UBER_DAAS: 'DELIVERY_ACCEPTED_IN_UBER_DAAS',
    DELIVERY_PICKED_UP_IN_UBER_DAAS: 'DELIVERY_PICKED_UP_IN_UBER_DAAS',
    DELIVERY_CANCELLED_IN_UBER_DAAS: 'DELIVERY_CANCELLED_IN_UBER_DAAS',
    DELIVERY_DELIVERED_IN_UBER_DAAS: 'DELIVERY_DELIVERED_IN_UBER_DAAS',
    DELIVERY_REQUESTED_IN_RAPPI_CARGO: 'DELIVERY_REQUESTED_IN_RAPPI_CARGO',
    DELIVERY_ACCEPTED_IN_RAPPI_CARGO: 'DELIVERY_ACCEPTED_IN_RAPPI_CARGO',
    DELIVERY_PICKED_UP_IN_RAPPI_CARGO: 'DELIVERY_PICKED_UP_IN_RAPPI_CARGO',
    DELIVERY_CANCELLED_IN_RAPPI_CARGO: 'DELIVERY_CANCELLED_IN_RAPPI_CARGO',
    DELIVERY_DELIVERED_IN_RAPPI_CARGO: 'DELIVERY_DELIVERED_IN_RAPPI_CARGO',
    DELIVERY_REQUESTED_IN_UBER_DIRECT: 'DELIVERY_REQUESTED_IN_UBER_DIRECT',
    DELIVERY_CANCELLED_IN_UBER_DIRECT: 'DELIVERY_CANCELLED_IN_UBER_DIRECT',
    DELIVERY_REQUESTED_IN_PIDE_DIRECTO: 'DELIVERY_REQUESTED_IN_PIDE_DIRECTO',
    DELIVERY_ACCEPTED_IN_PIDE_DIRECTO: 'DELIVERY_ACCEPTED_IN_PIDE_DIRECTO',
    DELIVERY_PICKED_UP_IN_PIDE_DIRECTO: 'DELIVERY_PICKED_UP_IN_PIDE_DIRECTO',
    DELIVERY_CANCELLED_IN_PIDE_DIRECTO: 'DELIVERY_CANCELLED_IN_PIDE_DIRECTO',
    DELIVERY_DELIVERED_IN_PIDE_DIRECTO: 'DELIVERY_DELIVERED_IN_PIDE_DIRECTO',
    CYCLICAL_DELIVERY_MANAGER_NOT_LOOPED: 'CYCLICAL_DELIVERY_MANAGER_NOT_LOOPED',
    RAPPI_CARGO_DRIVER_ACCEPTED_DELIVERY: 'RAPPI_CARGO_DRIVER_ACCEPTED_DELIVERY',
    RAPPI_CARGO_DELIVERY_REQUEST_FAILED: 'RAPPI_CARGO_DELIVERY_REQUEST_FAILED',
    UBER_DAAS_DRIVER_ACCEPTED_DELIVERY: 'UBER_DAAS_DRIVER_ACCEPTED_DELIVERY',
    UBER_DAAS_DELIVERY_REQUEST_FAILED: 'UBER_DAAS_DELIVERY_REQUEST_FAILED',
    UBER_DIRECT_DRIVER_ACCEPTED_DELIVERY: 'UBER_DIRECT_DRIVER_ACCEPTED_DELIVERY',
    QUICK_ACTION_ACTIVATED: 'QUICK_ACTION_ACTIVATED',
    QUICK_ACTION_DEACTIVATED: 'QUICK_ACTION_DEACTIVATED',
    QUICK_ACTION_CREATED: 'QUICK_ACTION_CREATED',
    QUICK_ACTION_CHANGED: 'QUICK_ACTION_CHANGED',
    QUICK_ACTION_REMOVED: 'QUICK_ACTION_REMOVED',
    CUSTOMER_CREDITS_ADDED: 'CUSTOMER_CREDITS_ADDED',
    CUSTOMER_CREDITS_REMOVED: 'CUSTOMER_CREDITS_REMOVED',
    PROMO_CODE_CREATED: 'PROMO_CODE_CREATED',
    PROMO_CODE_CHANGED: 'PROMO_CODE_CHANGED',
    PROMO_CODE_REMOVED: 'PROMO_CODE_REMOVED',
    SUPPLY_CATEGORY_CREATED: 'SUPPLY_CATEGORY_CREATED',
    SUPPLY_CATEGORY_CHANGED: 'SUPPLY_CATEGORY_CHANGED',
    SUPPLY_CATEGORY_REMOVED: 'SUPPLY_REMOVED',
    SUPPLY_CREATED: 'SUPPLY_CREATED',
    SUPPLY_CHANGED: 'SUPPLY_CHANGED',
    SUPPLY_REMOVED: 'SUPPLY_REMOVED',
    SUPPLIER_CREATED: 'SUPPLIER_CREATED',
    SUPPLIER_CHANGED: 'SUPPLIER_CHANGED',
    SUPPLIER_REMOVED: 'SUPPLIER_REMOVED',
    RECIPE_CREATED: 'RECIPE_CREATED',
    RECIPE_CHANGED: 'RECIPE_CHANGED',
    RECIPE_REMOVED: 'RECIPE_REMOVED',
    BUY_ORDER_CREATED: 'BUY_ORDER_CREATED',
    BUY_ORDER_CHANGED: 'BUY_ORDER_CHANGED',
    BUY_ORDER_CANCELLED: 'BUY_ORDER_CANCELLED',
    RESTAURANT_INVENTORY_COPIED: 'RESTAURANT_INVENTORY_COPIED',
    FAILED_TO_COPY_RESTAURANT_INVENTORY: 'FAILED_TO_COPY_RESTAURANT_INVENTORY',
    SUPPLY_TRANSFERRED_BETWEEN_RESTAURANTS: 'SUPPLY_TRANSFERRED_BETWEEN_RESTAURANTS',
    FAILED_TO_TRANSFER_SUPPLY_BETWEEN_RESTAURANTS: 'FAILED_TO_TRANSFER_SUPPLY_BETWEEN_RESTAURANTS',
    INVENTORY_LINKED_BETWEEN_RESTAURANTS: 'INVENTORY_LINKED_BETWEEN_RESTAURANTS',
    FAILED_TO_LINK_INVENTORY_BETWEEN_RESTAURANTS: 'FAILED_TO_LINK_INVENTORY_BETWEEN_RESTAURANTS',
    INVENTORY_UNLINKED_FROM_RESTAURANT: 'INVENTORY_UNLINKED_FROM_RESTAURANT',
    FAILED_TO_UNLINK_INVENTORY_BETWEEN_RESTAURANTS: 'FAILED_TO_UNLINK_INVENTORY_BETWEEN_RESTAURANTS',
    INVENTORY_UPDATED: 'INVENTORY_UPDATED',
    FAILED_TO_UPDATE_INVENTORY: 'FAILED_TO_UPDATE_INVENTORY',
    SCRIPT_RUN_STARTED: 'SCRIPT_RUN_STARTED',
    SCRIPT_RUN_ENDED: 'SCRIPT_RUN_ENDED',
    PATCH_RUN_STARTED: 'PATCH_RUN_STARTED',
    PATCH_RUN_ENDED: 'PATCH_RUN_ENDED',
    FAILED_TO_RUN_SCRIPT: 'FAILED_TO_RUN_SCRIPT',
    FAILED_TO_RUN_PATCH: 'FAILED_TO_RUN_PATCH',
    RESTAURANT_EMPLOYEE_CLOCKED_IN: 'RESTAURANT_EMPLOYEE_CLOCKED_IN',
    RESTAURANT_EMPLOYEE_CLOCKED_OUT: 'RESTAURANT_EMPLOYEE_CLOCKED_OUT',
    USER_OPENED_REQUEST_DRIVER_DIALOG: 'USER_OPENED_REQUEST_DRIVER_DIALOG',
    USER_OPENED_CONFIRM_DIRECTION_DIALOG: 'USER_OPENED_CONFIRM_DIRECTION_DIALOG',
    USER_SIGNED_IN_TO_PIDE_DIRECTO_ADMIN: 'USER_SIGNED_IN_TO_PIDE_DIRECTO_ADMIN',
    USER_SIGNED_IN_TO_PIDE_DIRECTO_KIOSK: 'USER_SIGNED_IN_TO_PIDE_DIRECTO_KIOSK',
    USER_SIGNED_IN_TO_PIDE_DIRECTO: 'USER_SIGNED_IN_TO_PIDE_DIRECTO',
    USER_SIGNED_UP_TO_PIDE_DIRECTO: 'USER_SIGNED_UP_TO_PIDE_DIRECTO',
    USER_SIGNED_IN_TO_PIDE_DIRECTO_APP: 'USER_SIGNED_IN_TO_PIDE_DIRECTO_APP',
    USER_SIGNED_UP_TO_PIDE_DIRECTO_APP: 'USER_SIGNED_UP_TO_PIDE_DIRECTO_APP',
    USER_SIGNED_IN_TO_DRIVER_APP: 'USER_SIGNED_IN_TO_DRIVER_APP',
    USER_SIGNED_IN_TO_LETSEAT_APP: 'USER_SIGNED_IN_TO_LETSEAT_APP',
    USER_SIGNED_IN_TO_LOKI: 'USER_SIGNED_IN_TO_LOKI',
    USER_OPENED_PAGE: 'USER_OPENED_PAGE',
    USER_CLICKED_BUTTON: 'USER_CLICKED_BUTTON',
    USER_CLICKED_TAB: 'USER_CLICKED_TAB',
    USER_OPENED_DIALOG: 'USER_OPENED_DIALOG',
    USER_OPENED_CONTEXTUAL_MENU: 'USER_OPENED_CONTEXTUAL_MENU',
    USER_CLICKED_CONTEXTUAL_MENU_ITEM: 'USER_CLICKED_CONTEXTUAL_MENU_ITEM',
    USER_TOGGLED_ACCORDION: 'USER_TOGGLED_ACCORDION',
    USER_OPENED_ALERT_DIALOG: 'USER_OPENED_ALERT_DIALOG',
    USER_TYPED_INPUT: 'USER_TYPED_INPUT',
    USER_CLICKED_SWITCH: 'USER_CLICKED_SWITCH',
    USER_CLICKED_CHECKBOX: 'USER_CLICKED_CHECKBOX',
    USER_CLICKED_SELECT_OPTION: 'USER_CLICKED_SELECT_OPTION',
    MERCADO_PAGO_NOTIFICATION: 'MERCADO_PAGO_NOTIFICATION',
    USER_OPENED_PIDEDIRECTO: 'USER_OPENED_PIDEDIRECTO',
    USER_SELECTED_ORDER_TYPE: 'USER_SELECTED_ORDER_TYPE',
    USER_OPENED_RESTAURANT_OPENING_HOURS_DIALOG: 'USER_OPENED_RESTAURANT_OPENING_HOURS_DIALOG',
    USER_SELECTED_RESTAURANT_FROM_SELECT_RESTAURANT_DIALOG: 'USER_SELECTED_RESTAURANT_FROM_SELECT_RESTAURANT_DIALOG',
    USER_SELECTED_RESTAURANT_FROM_CHAIN_RESTAURANTS: 'USER_SELECTED_RESTAURANT_FROM_CHAIN_RESTAURANTS',
    USER_OPENED_CART_DIALOG: 'USER_OPENED_CART_DIALOG',
    USER_OPENED_CART_DETAILS: 'USER_OPENED_CART_DETAILS',
    USER_ADDED_ITEM_TO_CART: 'USER_ADDED_ITEM_TO_CART',
    USER_REMOVED_ITEM_FROM_CART: 'USER_REMOVED_ITEM_FROM_CART',
    USER_SELECTED_ADDRESS: 'USER_SELECTED_ADDRESS',
    USER_ADDED_INVALID_PROMO_CODE: 'USER_ADDED_INVALID_PROMO_CODE',
    USER_ADDED_VALID_PROMO_CODE: 'USER_ADDED_VALID_PROMO_CODE',
    USER_SELECTED_PAYMENT_METHOD: 'USER_SELECTED_PAYMENT_METHOD',
    USER_PRINT_SUCCESSFUL: 'USER_PRINT_SUCCESSFUL',
    USER_PRINT_FAILED: 'USER_PRINT_FAILED',
    USER_SIGNED_IN_TO_PIDE_DIRECTO_POS: 'USER_SIGNED_IN_TO_PIDE_DIRECTO_POS',
    USER_SIGNED_IN_TO_PIDE_DIRECTO_POS_FAILED: 'USER_SIGNED_IN_TO_PIDE_DIRECTO_POS_FAILED',
    LOKI_JOB_CREATED: 'LOKI_JOB_CREATED',
    LOKI_JOB_SIMULATION_STARTED: 'LOKI_JOB_SIMULATION_STARTED',
    LOKI_JOB_SIMULATION_FAILED: 'LOKI_JOB_SIMULATION_FAILED',
    LOKI_JOB_SIMULATION_SUCCEEDED: 'LOKI_JOB_SIMULATION_SUCCEEDED',
    LOKI_JOB_RUN_STARTED: 'LOKI_JOB_RUN_STARTED',
    LOKI_JOB_RUN_FAILED: 'LOKI_JOB_RUN_FAILED',
    LOKI_JOB_RUN_SUCCEEDED: 'LOKI_JOB_RUN_SUCCEEDED',
} as const;

export type LogEventType = (typeof LogEventTypes)[keyof typeof LogEventTypes];
