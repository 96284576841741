import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { makeStyles } from '@material-ui/core/styles';
import { useState } from 'react';
import * as React from 'react';
import { removePaymentLinkApi } from 'src/api/letseatmanager/paymentLink/removePaymentLinkApi';
import { translate } from 'src/i18n/translate';
import type { PaymentLinkId } from 'src/types/Id';
import { alertKnownErrorOrSomethingWentWrong } from 'src/utils/alert/alertKnownErrorOrSomethingWentWrong';
import { useSelector } from 'src/utils/react/useSelector';

export function RemovePaymentLinkDialog({ open, onClose, onSuccess, paymentLinkIds }: Props): React.ReactElement | null {
    const classes = useStyles();

    const [loading, setLoading] = useState(false);

    const restaurantId = useSelector((state) => state.app.restaurantId);

    const removeMultiplePaymentLink = async (paymentLinkIds: Array<PaymentLinkId>) => {
        setLoading(true);
        for (const paymentLinkId of paymentLinkIds) {
            const response = await removePaymentLinkApi({ paymentLinkId, restaurantId });
            if (!response.ok) {
                alertKnownErrorOrSomethingWentWrong(response);
                setLoading(false);
                onClose();
                return;
            }
        }
        setLoading(false);
        onSuccess();
        onClose();
    };

    const onAccept = async () => {
        if (!paymentLinkIds?.length) return onClose();

        await removeMultiplePaymentLink(paymentLinkIds);
    };

    if (!paymentLinkIds?.length) return null;

    return (
        <Dialog scroll='paper' open={open} onClose={onClose}>
            <DialogTitle classes={{ root: classes.dialogTitle }}>{translate('Remove Payment Link')}</DialogTitle>
            <DialogContent className={classes.bodyText}>
                {paymentLinkIds?.length === 1 && <div>{translate('Are you sure you want to remove this paymentLink?')}</div>}
                {paymentLinkIds?.length > 1 && <div>{translate('Are you sure you want to remove these paymentLinks?')}</div>}
            </DialogContent>
            <DialogActions className={classes.buttonsContainer}>
                <Button className={classes.cancelButton} onClick={onClose} disabled={loading} variant={'outlined'}>
                    {translate('Cancel')}
                </Button>
                <Button className={classes.removeButton} color='primary' variant={'contained'} onClick={onAccept} disabled={loading}>
                    {translate('Remove')}
                </Button>
            </DialogActions>
        </Dialog>
    );
}

const useStyles = makeStyles((theme) => ({
    dialogTitle: {
        textAlign: 'center',
        fontSize: 41,
        color: theme.palette.secondary.contrastText,
        paddingTop: theme.spacing(4),
        paddingBottom: theme.spacing(3),
        '& h2': {
            fontFamily: theme.typography.medium,
        },
    },
    bodyText: {
        fontFamily: theme.typography.medium,
        textAlign: 'center',
        color: theme.palette.secondary.contrastText,
    },
    buttonsContainer: {
        marginTop: 20,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        [theme.breakpoints.down('sm')]: {
            flexDirection: 'column',
            marginBottom: 20,
        },
    },
    removeButton: {
        fontFamily: theme.typography.semiBold,
        fontSize: 15,
        borderRadius: 15,
        height: 40,
        minWidth: 150,
        textTransform: 'none',
        marginTop: 15,
        [theme.breakpoints.up('md')]: {
            margin: 0,
        },
    },
    cancelButton: {
        color: theme.palette.primary.main,
        border: `1px solid ${theme.palette.primary.main}`,
        fontFamily: theme.typography.semiBold,
        fontSize: 15,
        borderRadius: 15,
        height: 40,
        width: 150,
        textTransform: 'none',
    },
}));

type Props = {
    open: boolean;
    onSuccess: any;
    paymentLinkIds?: Array<PaymentLinkId>;
    onClose: any;
};
