import { makeStyles } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import DeleteIcon from '@material-ui/icons/Delete';
import MUIDataTable from 'mui-datatables';
import * as React from 'react';
import { useRef, useState } from 'react';
import { changeSubscriptionHistoryPaymentStatusApi } from 'src/api/letseatadmin/subscriptionHistory/changeSubscriptionHistoryPaymentStatusApi';
import { deleteSubscriptionHistoryApi } from 'src/api/letseatadmin/subscriptionHistory/deleteSubscriptionHistoryApi';
import type { SubscriptionHistoryVm } from 'src/api/letseatadmin/subscriptionHistory/findSubscriptionHistoryBetweenTwoDatesApi';
import { ChangeToolbarButton } from 'src/components/mui-datatables/ChangeToolbarButton';
import { CreatePaymentToolbarButton } from 'src/components/mui-datatables/CreatePaymentToolbarButton';
import { DeletePaymentToolbarButton } from 'src/components/mui-datatables/DeletePaymentToolbarButton';
import { RefreshToolbarButton } from 'src/components/mui-datatables/RefreshToolbarButton';
import { UpdatingContentProgress } from 'src/components/UpdatingContentProgress';
import { locale } from 'src/config/configureI18n';
import type { PaymentRejectReason } from 'src/constants/PaymentRejectReason';
import { SubscriptionPaymentErrorType, SubscriptionPaymentErrorTypes } from 'src/constants/SubscriptionPaymentErrorType';
import { SubscriptionPaymentMethod, SubscriptionPaymentMethods } from 'src/constants/SubscriptionPaymentMethod';
import type { SubscriptionPlan } from 'src/constants/SubscriptionPlan';
import type { SubscriptionStatus } from 'src/constants/SubscriptionStatus';
import { TimeZones } from 'src/constants/TimeZone';
import { translate } from 'src/i18n/translate';
import { ChangeSubscriptionHistoryDialog } from 'src/scenes/letseatadmin/subscriptionHistory/ChangeSubscriptionHistoryDialog';
import type { BrandId, CardId, FacturamaInvoiceFolio, PaymentId, RestaurantId, SubscriptionHistoryId, SubscriptionId } from 'src/types/Id';
import { alertKnownErrorOrSomethingWentWrong } from 'src/utils/alert/alertKnownErrorOrSomethingWentWrong';
import { countdownDays } from 'src/utils/date/countdownDays';
import { formatDateString } from 'src/utils/date/formatDateString';
import { formatDateTimeString } from 'src/utils/date/formatDateTimeString';
import { overdueDays } from 'src/utils/date/overdueDays';
import { translatePaymentRejectReasonCompact } from 'src/utils/translate/translatePaymentRejectReasonCompact';
import { translateSubscriptionPaymentErrorType } from 'src/utils/translate/translateSubscriptionPaymentErrorType';
import { openSubscriptionHistoryPage } from 'src/utils/window/openSubscriptionHistoryPage';

export function SubscriptionHistoryTable({ subscriptionHistories = [], loading, title, onClickRefresh }: Props): React.ReactElement {
    const classes = useStyles();
    const table = useRef(null);
    const [changeSubscriptionHistoryDialogState, setChangeSubscriptionHistoryDialogState] = useState<{ open: boolean; subscriptionHistoryId: undefined | SubscriptionHistoryId }>({
        open: false,
        subscriptionHistoryId: undefined,
    });

    const translateSubcriptionPaymentMethod = (subscriptionHistory: SubscriptionHistory) => {
        if (subscriptionHistory.subscriptionPaymentMethod === SubscriptionPaymentMethods.CARD && subscriptionHistory.cardIds) {
            return 'Domiciliado';
        } else {
            return translate(subscriptionHistory.subscriptionPaymentMethod);
        }
    };

    const markSubscriptionHistoryAsPaid = async (subscriptionHistoryIds: Array<SubscriptionHistoryId>, payed?: boolean) => {
        const result: Record<string, any> = {};
        let counter = 0;
        for (const subscriptionHistoryId of subscriptionHistoryIds) {
            counter++;
            const response = await changeSubscriptionHistoryPaymentStatusApi({ subscriptionHistoryId, payedSubscription: payed as any });
            if (!response.ok) {
                alertKnownErrorOrSomethingWentWrong(response);
                result['Skipped'] = subscriptionHistoryIds.length - counter;
                break;
            }
            result['Success'] = (result['Success'] ?? 0) + 1;
        }
        alert(
            'Result:\n' +
                Object.entries(result)
                    .map(([error, count]: [any, any]) => `${error as any}: ${String(count)}`)
                    .join('\n'),
        );
        onClickRefresh();
    };

    const deleteSubscriptionHistory = async (subscriptionHistoryIds: Array<SubscriptionHistoryId>, payed?: boolean) => {
        const result: Record<string, any> = {};
        let counter = 0;
        for (const subscriptionHistoryId of subscriptionHistoryIds) {
            counter++;
            const response = await deleteSubscriptionHistoryApi({ subscriptionHistoryId });
            if (!response.ok) {
                alertKnownErrorOrSomethingWentWrong(response);
                result['Skipped'] = subscriptionHistoryIds.length - counter;
                break;
            }
            result['Success'] = (result['Success'] ?? 0) + 1;
        }
        alert(
            'Result:\n' +
                Object.entries(result)
                    .map(([error, count]: [any, any]) => `${error as any}: ${String(count)}`)
                    .join('\n'),
        );
        onClickRefresh();
    };

    return (
        <div className={classes.container}>
            <UpdatingContentProgress loading={loading} />
            <ChangeSubscriptionHistoryDialog
                open={changeSubscriptionHistoryDialogState.open}
                subscriptionHistoryId={changeSubscriptionHistoryDialogState.subscriptionHistoryId}
                onClose={() => setChangeSubscriptionHistoryDialogState({ open: false, subscriptionHistoryId: undefined })}
                onChangeSubscriptionHistory={onClickRefresh}
            />
            <MUIDataTable
                /* @ts-ignore */
                ref={table}
                className={classes.table}
                title={title || translate('Subscription History')}
                data={subscriptionHistories.map((subscriptionHistory) => {
                    const countdown = countdownDays(subscriptionHistory.nextPaymentDate, new Date(), TimeZones.AMERICA_MONTERREY);
                    const overdue = overdueDays(subscriptionHistory.nextPaymentDate, new Date(), TimeZones.AMERICA_MONTERREY);

                    return {
                        subscriptionHistoryId: subscriptionHistory.subscriptionHistoryId,
                        subscriptionId: subscriptionHistory.subscriptionId,
                        paymentId: subscriptionHistory.paymentId,
                        payedSubscription: subscriptionHistory.payedSubscription ? 'Payed' : 'Not Payed',
                        cities: subscriptionHistory.cities,
                        brandName: subscriptionHistory.brandName,
                        restaurantNames: subscriptionHistory.restaurantNames,
                        branchOffice: subscriptionHistory.restaurantNames.length,
                        businessName: subscriptionHistory.businessName,
                        rfc: subscriptionHistory.rfc,
                        subscriptionPaymentMethod: translateSubcriptionPaymentMethod(subscriptionHistory),
                        manualInvoiceDate: formatDateString(subscriptionHistory.manualInvoiceDate),
                        manualInvoiceFacturamaInvoiceFolio: subscriptionHistory.manualInvoiceFacturamaInvoiceFolio,
                        manualInvoiceError: subscriptionHistory.manualInvoiceError,
                        status: subscriptionHistory.status,
                        webOrders: subscriptionHistory.webOrders ? 'Yes' : 'No',
                        manualOrders: subscriptionHistory.manualOrders ? 'Yes' : 'No',
                        plan: subscriptionHistory.plan,
                        paymentAmount: subscriptionHistory.paymentAmount,
                        previousPaymentDate: formatDateString(subscriptionHistory.previousPaymentDate, TimeZones.AMERICA_MONTERREY),
                        previousPaymentAmount: subscriptionHistory.previousPaymentAmount,
                        nextPaymentDate: formatDateString(subscriptionHistory.nextPaymentDate, TimeZones.AMERICA_MONTERREY),
                        nextPaymentAmount: subscriptionHistory.paymentAmount,
                        // @ts-ignore
                        currency: locale === 'es' ? 'MXN' : '$',
                        cutServiceDate: formatDateString(subscriptionHistory.cutServiceDate, TimeZones.AMERICA_MONTERREY),
                        startServiceDate: formatDateString(subscriptionHistory.startServiceDate, TimeZones.AMERICA_MONTERREY),
                        endServiceDate: formatDateString(subscriptionHistory.endServiceDate, TimeZones.AMERICA_MONTERREY),
                        countdown: subscriptionHistory.nextPaymentDate ? countdown : '?',
                        overdue: subscriptionHistory.nextPaymentDate ? overdue : '?',
                        paymentErrorType: translateSubscriptionPaymentErrorType(subscriptionHistory.paymentErrorType, [SubscriptionPaymentErrorTypes.FUTURE_PAYMENT_DATE]),
                        paymentRejectReason: translatePaymentRejectReasonCompact(subscriptionHistory.paymentRejectReason),
                        contactName: subscriptionHistory.contactName,
                        contactPhone: subscriptionHistory.contactPhone,
                        contactEmail: subscriptionHistory.contactEmail,
                        subscriptionCreatedAt: formatDateTimeString(subscriptionHistory.subscriptionCreatedAt),
                        createdAt: formatDateTimeString(subscriptionHistory.createdAt),
                    };
                })}
                columns={[
                    {
                        name: 'subscriptionHistoryId',
                        label: '',
                        options: {
                            display: 'excluded',
                            filter: false,
                        },
                    },
                    {
                        name: 'paymentId',
                        label: '',
                        options: {
                            display: 'excluded',
                            filter: false,
                        },
                    },
                    {
                        name: 'subscriptionId',
                        label: '',
                        options: {
                            display: 'excluded',
                            filter: false,
                        },
                    },
                    {
                        name: 'payedSubscription',
                        label: 'Payed Subscription',
                        options: {},
                    },
                    {
                        name: 'createdAt',
                        label: translate('Payed At'),
                        options: {
                            filter: false,
                            searchable: false,
                        },
                    },
                    {
                        name: 'startServiceDate',
                        label: translate('Start Service Date'),
                        options: {
                            filter: false,
                            searchable: false,
                        },
                    },
                    {
                        name: 'endServiceDate',
                        label: translate('End Service Date'),
                        options: {
                            filter: false,
                            searchable: false,
                        },
                    },
                    {
                        name: 'restaurantId',
                        label: '',
                        options: {
                            display: 'excluded',
                            filter: false,
                        },
                    },
                    {
                        name: 'restaurantIds',
                        label: '',
                        options: {
                            display: 'excluded',
                            filter: false,
                        },
                    },
                    {
                        name: 'cities',
                        label: translate('City'),
                        options: {
                            filter: true,
                            customBodyRender: (value, tableMeta, updateValue) => <div>{value.join(',')}</div>,
                        },
                    },
                    {
                        name: 'brandName',
                        label: translate('Brand'),
                        options: {
                            filter: false,
                        },
                    },
                    {
                        name: 'restaurantNames',
                        label: translate('Restaurants'),
                        options: {
                            filter: false,
                            customBodyRender: (value, tableMeta, updateValue) => <div>{value.join(',')}</div>,
                        },
                    },
                    {
                        name: 'branchOffice',
                        label: translate('# of Branch Office'),
                        options: {
                            filter: false,
                        },
                    },
                    {
                        name: 'businessName',
                        label: translate('Business Name'),
                        options: {
                            filter: false,
                            searchable: true,
                        },
                    },
                    {
                        name: 'rfc',
                        label: translate('RFC'),
                        options: {
                            filter: false,
                        },
                    },
                    {
                        name: 'subscriptionPaymentMethod',
                        label: translate('Payment Method'),
                        options: {
                            filter: true,
                        },
                    },
                    {
                        name: 'manualInvoiceDate',
                        label: translate('Last Invoice Created'),
                        options: {
                            filter: false,
                        },
                    },
                    {
                        name: 'manualInvoiceFacturamaInvoiceFolio',
                        label: translate('Last Invoice Folio'),
                        options: {
                            filter: false,
                        },
                    },
                    {
                        name: 'manualInvoiceError',
                        label: translate('Invoice Error'),
                        options: {
                            filter: false,
                        },
                    },
                    {
                        name: 'status',
                        label: translate('Status'),
                        options: {
                            filter: true,
                            searchable: false,
                        },
                    },
                    {
                        name: 'manualOrders',
                        label: translate('Manual Orders'),
                        options: {
                            filter: true,
                            searchable: false,
                        },
                    },
                    {
                        name: 'webOrders',
                        label: translate('Web Orders'),
                        options: {
                            filter: true,
                            searchable: false,
                        },
                    },
                    {
                        name: 'plan',
                        label: translate('Plan'),
                        options: {
                            filter: true,
                        },
                    },
                    {
                        name: 'paymentAmount',
                        label: translate('Amount'),
                        options: {
                            filter: false,
                        },
                    },
                    {
                        name: 'previousPaymentDate',
                        label: translate('Last Payment Date'),
                        options: {
                            filter: true,
                            searchable: false,
                        },
                    },
                    {
                        name: 'previousPaymentAmount',
                        label: translate('Last Payment Amount'),
                        options: {
                            filter: false,
                            searchable: false,
                        },
                    },
                    {
                        name: 'nextPaymentDate',
                        label: translate('Next Payment Date'),
                        options: {
                            filter: true,
                            searchable: false,
                        },
                    },
                    {
                        name: 'nextPaymentAmount',
                        label: translate('Next Payment Amount'),
                        options: {
                            filter: true,
                            searchable: false,
                        },
                    },
                    {
                        name: 'currency',
                        label: translate('Currency'),
                        options: {
                            filter: false,
                            searchable: false,
                        },
                    },
                    {
                        name: 'cutServiceDate',
                        label: translate('Cutoff Date'),
                        options: {
                            filter: true,
                            searchable: false,
                        },
                    },
                    {
                        name: 'customerType',
                        label: translate('Customer Type'),
                        options: {
                            filter: true,
                            searchable: false,
                        },
                    },
                    {
                        name: 'countdown',
                        label: translate('Countdown'),
                        options: {
                            filter: false,
                            searchable: false,
                        },
                    },
                    {
                        name: 'overdue',
                        label: translate('Overdue'),
                        options: {
                            filter: false,
                            searchable: false,
                        },
                    },
                    {
                        name: 'paymentErrorType',
                        label: translate('Payment Error'),
                        options: {
                            filter: true,
                            searchable: false,
                        },
                    },
                    {
                        name: 'paymentRejectReason',
                        label: translate('Payment Reject Reason'),
                        options: {
                            filter: true,
                            searchable: false,
                        },
                    },
                    {
                        name: 'contactName',
                        label: translate('Contact Name'),
                        options: {
                            filter: false,
                            searchable: false,
                        },
                    },
                    {
                        name: 'contactPhone',
                        label: translate('Contact Phone'),
                        options: {
                            filter: false,
                            searchable: false,
                        },
                    },
                    {
                        name: 'contactEmail',
                        label: translate('Contact Email'),
                        options: {
                            filter: false,
                            searchable: false,
                        },
                    },
                    {
                        name: 'subscriptionCreatedAt',
                        label: translate('Subscription Created At'),
                        options: {
                            filter: false,
                            searchable: false,
                        },
                    },
                ]}
                options={{
                    responsive: 'standard',
                    tableBodyMaxHeight: '500px',
                    selectableRows: 'multiple',
                    filterType: 'checkbox',
                    rowsPerPage: 100,
                    customToolbar: () => (
                        <>
                            <RefreshToolbarButton onClick={onClickRefresh} />
                        </>
                    ),
                    customSort: (data, dataIndex, rowIndex) => {
                        if (dataIndex === 4 || dataIndex === 5 || dataIndex === 6 || dataIndex === 16 || dataIndex === 24 || dataIndex === 26 || dataIndex === 28) {
                            return data.sort((a, b) => {
                                const dateA = new Date(a.data[dataIndex]).getTime();
                                const dateB = new Date(b.data[dataIndex]).getTime();
                                console.log(dateA);
                                console.log(dateB);
                                return (dateA < dateB ? -1 : 1) * (rowIndex === 'desc' ? 1 : -1);
                            });
                        } else {
                            return data.sort((a, b) => {
                                return (a.data[dataIndex].length < b.data[dataIndex].length ? -1 : 1) * (rowIndex === 'desc' ? 1 : -1);
                            });
                        }
                    },
                    customToolbarSelect: (selectedRows) => {
                        const selectedSubscriptionsHistories: Array<SubscriptionHistoryVm> = selectedRows.data.map((row) => subscriptionHistories[row.dataIndex]);
                        const selectedSubscriptionHistoriesIds = selectedSubscriptionsHistories.map((s) => s.subscriptionHistoryId);
                        return (
                            <div className={classes.toolbar}>
                                <CreatePaymentToolbarButton
                                    tooltip={translate('Mark Subscription History as Paid')}
                                    onClick={async () => {
                                        const markSupbscriptionAsPayed = window.confirm(
                                            selectedSubscriptionHistoriesIds.length === 1
                                                ? 'Are you sure you want to mark as pay the selected subscriptions histories'
                                                : `Are you sure you want to pay the selected ${selectedSubscriptionHistoriesIds.length} subscriptions histories`,
                                        );
                                        if (markSupbscriptionAsPayed) {
                                            await markSubscriptionHistoryAsPaid(selectedSubscriptionHistoriesIds, true);
                                        }
                                    }}
                                />

                                <DeletePaymentToolbarButton
                                    tooltip={translate('Mark Subscription History as UnPaid')}
                                    onClick={async () => {
                                        const markSupbscriptionAsUnPayed = window.confirm(
                                            selectedSubscriptionHistoriesIds.length === 1
                                                ? 'Are you sure you want to mark as unpaid the selected subscriptions histories'
                                                : `Are you sure you want to unpay the selected ${selectedSubscriptionHistoriesIds.length} subscriptions histories`,
                                        );
                                        if (markSupbscriptionAsUnPayed) {
                                            await markSubscriptionHistoryAsPaid(selectedSubscriptionHistoriesIds, false);
                                        }
                                    }}
                                />
                                {/* @ts-ignore */}
                                <Tooltip tooltip={translate('Delete Subscription History')}>
                                    <IconButton
                                        onClick={async () => {
                                            const deleteSubscriptions = window.confirm(
                                                selectedSubscriptionHistoriesIds.length === 1
                                                    ? 'Are you sure you want to delete selected subscriptions histories? (only spei internal histories will be delete)'
                                                    : `Are you sure you want to delete the selected ${selectedSubscriptionHistoriesIds.length} subscriptions histories  (only spei internal histories will be delete)`,
                                            );
                                            if (deleteSubscriptions) {
                                                await deleteSubscriptionHistory(selectedSubscriptionHistoriesIds, false);
                                            }
                                        }}
                                    >
                                        <DeleteIcon />
                                    </IconButton>
                                </Tooltip>
                                {selectedSubscriptionHistoriesIds.length === 1 && (
                                    <ChangeToolbarButton
                                        onClick={() => {
                                            if (selectedSubscriptionHistoriesIds.length === 1)
                                                setChangeSubscriptionHistoryDialogState({ open: true, subscriptionHistoryId: selectedSubscriptionHistoriesIds[0] });
                                        }}
                                    />
                                )}
                            </div>
                        );
                    },
                    onRowClick: (
                        rowData: Array<string>,
                        rowMeta: {
                            dataIndex: number;
                        },
                    ) => {
                        const subscriptionHistoryId = subscriptionHistories[rowMeta.dataIndex].subscriptionHistoryId;
                        openSubscriptionHistoryPage(subscriptionHistoryId);
                    },
                }}
            />
        </div>
    );
}

const useStyles = makeStyles((theme) => ({
    container: {
        position: 'relative',
    },
    table: {
        whiteSpace: 'nowrap',
    },
    toolbar: {
        paddingRight: theme.spacing(3),
    },
}));

type Props = {
    subscriptionHistories: Array<SubscriptionHistory>;
    loading: boolean;
    title?: string;
    onClickRefresh: any;
};

type SubscriptionHistory = {
    subscriptionHistoryId: SubscriptionHistoryId;
    paymentId: PaymentId;
    subscriptionId: SubscriptionId;
    restaurantId: RestaurantId;
    restaurantIds: Array<RestaurantId>;
    payedSubscription?: boolean;
    cities: Array<string>;
    brandId?: BrandId;
    brandName?: string;
    restaurantNames: Array<string>;
    businessName?: string;
    rfc?: string;
    subscriptionPaymentMethod: SubscriptionPaymentMethod;
    cardIds?: Array<CardId>;
    manualInvoiceDate?: Date;
    manualInvoiceFacturamaInvoiceFolio?: FacturamaInvoiceFolio;
    manualInvoiceError?: string;
    status?: SubscriptionStatus;
    webOrders?: boolean;
    manualOrders?: boolean;
    plan?: SubscriptionPlan;
    paymentAmount: string;
    previousPaymentDate?: Date;
    previousPaymentAmount?: string;
    previousPaymentPaidAt?: Date;
    nextPaymentAmount?: string;
    nextPaymentDate?: Date;
    startServiceDate?: Date;
    endServiceDate?: Date;
    cutServiceDate?: Date;
    paymentErrorType?: SubscriptionPaymentErrorType;
    paymentRejectReason?: PaymentRejectReason;
    contactName?: string;
    contactPhone?: string;
    contactEmail?: string;
    subscriptionCreatedAt: Date;
    createdAt: Date;
};
