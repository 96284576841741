import { FormControlLabel, FormGroup, makeStyles, Switch } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import EditIcon from '@material-ui/icons/Edit';
import RefreshIcon from '@material-ui/icons/Refresh';
import VisibilityIcon from '@material-ui/icons/Visibility';
import MUIDataTable from 'mui-datatables';
import { useEffect, useState } from 'react';
import * as React from 'react';
import { exportRestaurantDetailsUberDirectApi } from 'src/api/letseatadmin/restaurantDetails/exportRestaurantDetailsUberDirectApi';
import { importRestaurantDetailsUberDirectApi } from 'src/api/letseatadmin/restaurantDetails/importRestaurantDetailsUberDirectApi';
import { letseatmanagerApiDeprecated } from 'src/api/letseatmanagerApiDeprecated';
import { app2 } from 'src/app2';
import { CustomToolbarSelect } from 'src/components/mui-datatables/CustomToolbarSelect';
import { DownloadCsvToolbarButton } from 'src/components/mui-datatables/DownloadCsvToolbarButton';
import { ExportToolbarButton } from 'src/components/mui-datatables/ExportToolbarButton';
import { UpdatingContentProgress } from 'src/components/UpdatingContentProgress';
import { translate } from 'src/i18n/translate';
import ChangeRestaurantDetailsDialogActions from 'src/scenes/letseatadmin/restaurantsDetails/change-restaurant-details-dialog/ChangeRestaurantDetailsDialogReducer';
import styles from 'src/scenes/letseatadmin/restaurantsDetails/RestaurantsDetailsStyles';
import { alertKnownErrorOrSomethingWentWrong } from 'src/utils/alert/alertKnownErrorOrSomethingWentWrong';
import { convertCsvToImportRestaurantDetailsUberDirect } from 'src/utils/csv/convertCsvToImportRestaurantDetailsUberDirect';
import { formatDateTimeString } from 'src/utils/date/formatDateTimeString';
import { downloadTextInFile } from 'src/utils/html/downloadTextInFile';
import { createFilteredOnDownload } from 'src/utils/mui-datatables/createFilteredOnDownload';
import { createHandleClickDownloadCsv } from 'src/utils/mui-datatables/createHandleClickDownloadCsv';
import { getSelectedRows } from 'src/utils/mui-datatables/getSelectedRows';
import { getSelectedRowsData } from 'src/utils/mui-datatables/getSelectedRowsData';
import { useAction } from 'src/utils/react/useAction';
import { useLoadApi } from 'src/utils/react/useLoadApi';
import { useSelector } from 'src/utils/react/useSelector';
import { formatAsInternationalPhoneNumber } from 'src/utils/string/formatAsInternationalPhoneNumber';

export function RestaurantsDetails(): React.ReactElement {
    const table = React.useRef(); // TODO: fix correct type

    const classes = useStyles();

    const setTitle = useAction(app2.actions.setTitle);
    const openChangeRestaurantDetailsDialog = useAction(ChangeRestaurantDetailsDialogActions.openDialog);

    const [exporting, setExporting] = useState(false);
    const [showPrivateInfo, setShowPrivateInfo] = useState(false);

    const viewUser = useSelector((state) => state.authentication.viewUser);

    const cities = useSelector((state) => state.app2.cities);
    const [loading, { restaurantsDetails }] = useLoadApi(letseatmanagerApiDeprecated.admin.restaurantsDetails, { cities }, { initialValue: { restaurantsDetails: [] } });

    useEffect(() => {
        setTitle(translate('Restaurants Details'));
    }, []);

    const changePrivateInfo = () => {
        if (!showPrivateInfo) {
            const password = prompt('Password');
            if (password !== '1212') {
                alert('Wrong Password');
            } else {
                setShowPrivateInfo(true);
            }
        } else {
            setShowPrivateInfo(false);
        }
    };

    return (
        <div className={(classes as any).container}>
            <Grid container direction='row' justify='flex-end'>
                <FormGroup>
                    <FormControlLabel control={<Switch onChange={changePrivateInfo} checked={showPrivateInfo} />} label='Show Private Info' />
                </FormGroup>
            </Grid>
            <UpdatingContentProgress loading={loading} />
            <MUIDataTable
                ref={table}
                data={restaurantsDetails.map((restaurantDetails: any) => [
                    restaurantDetails.restaurantId,
                    restaurantDetails.restaurantId,
                    restaurantDetails.uberDirectStoreId,
                    restaurantDetails.restaurantName,
                    restaurantDetails.zone,
                    restaurantDetails.username,
                    restaurantDetails.password,
                    showPrivateInfo ? restaurantDetails.contact : 'Private Info',
                    showPrivateInfo ? formatAsInternationalPhoneNumber(restaurantDetails.contactNumber) : 'Private Info',
                    formatAsInternationalPhoneNumber(restaurantDetails.establishmentsPhoneNumber),
                    restaurantDetails.fiscalAddress,
                    restaurantDetails.addressOfEstablishment,
                    restaurantDetails.email,
                    restaurantDetails.bank ?? '',
                    restaurantDetails.claveId || '',
                    restaurantDetails.exportError || '',
                    formatDateTimeString(restaurantDetails.exportedAt) || '',
                    restaurantDetails.account,
                    restaurantDetails.clabe,
                    restaurantDetails.rfc,
                    restaurantDetails.businessName,
                    restaurantDetails.contactName,
                    restaurantDetails.emailInvoices,
                    restaurantDetails.paymentInEstablishment,
                    restaurantDetails.commissionWebCard,
                    restaurantDetails.commissionWebCash,
                    restaurantDetails.commissionWebCardOnDelivery,
                    restaurantDetails.commissionAppCard,
                    restaurantDetails.commissionAppCash,
                    restaurantDetails.commissionAppCardOnDelivery,
                    restaurantDetails.commissionManualOrders,
                    restaurantDetails.device,
                    restaurantDetails.responsible,
                    restaurantDetails.bill,
                    translate(restaurantDetails.country),
                ])}
                columns={[
                    {
                        name: 'restaurantId',
                        label: ' ',
                        options: {
                            display: 'excluded',
                            filter: false,
                        },
                    },
                    {
                        name: '',
                        options: {
                            filter: false,
                            sort: false,
                            customBodyRender: (restaurantId) => {
                                const handleClick = () => {
                                    const rowDetails = restaurantsDetails.find((restaurantDetail: any) => restaurantDetail.restaurantId === restaurantId);
                                    return openChangeRestaurantDetailsDialog(rowDetails, showPrivateInfo);
                                };
                                return (
                                    <IconButton color='primary' onClick={handleClick} disabled={viewUser}>
                                        <EditIcon fontSize='small' />
                                    </IconButton>
                                );
                            },
                            setCellProps: () => {
                                return {
                                    style: { paddingLeft: 8, paddingRight: 0 },
                                };
                            },
                        },
                    },
                    {
                        name: 'Uber Direct Store Id',
                    },
                    {
                        name: translate('Restaurant'),
                        options: {
                            filter: false,
                        },
                    },
                    {
                        name: translate('Zone'),
                    },
                    {
                        name: translate('Username'),
                        options: {
                            filter: false,
                        },
                    },
                    {
                        name: translate('Password'),
                        options: {
                            filter: false,
                            sort: false,
                            customBodyRender: (password, { rowData }, updateValue) => {
                                const username = rowData[3];
                                const handleClickTogglePassword = () => {
                                    letseatmanagerApiDeprecated.admin.userPassword.fetch({ username }).then((response: any) => {
                                        if (response.ok) {
                                            updateValue(response.data.password);
                                        }
                                    });
                                };
                                return password === '**********' ? (
                                    <IconButton color='primary' onClick={handleClickTogglePassword} disabled={viewUser}>
                                        <VisibilityIcon />
                                    </IconButton>
                                ) : (
                                    <div>{password}</div>
                                );
                            },
                        },
                    },
                    {
                        name: translate('Contact'),
                        options: {
                            filter: false,
                        },
                    },
                    {
                        name: translate('Contact Number'),
                        options: {
                            filter: false,
                        },
                    },
                    {
                        name: translate('Establishments Phone Number'),
                        options: {
                            filter: false,
                        },
                    },
                    {
                        name: translate('Fiscal Address'),
                        options: {
                            filter: false,
                        },
                    },
                    {
                        name: translate('Address of Establishment'),
                        options: {
                            filter: false,
                        },
                    },
                    {
                        name: translate('Email'),
                        options: {
                            filter: false,
                        },
                    },
                    {
                        name: translate('Bank'),
                    },
                    {
                        name: translate('Clave Id'),
                        options: {
                            filter: false,
                        },
                    },
                    {
                        name: translate('Export Error'),
                        options: {
                            filter: false,
                        },
                    },
                    {
                        name: translate('Exported At'),
                        options: {
                            filter: false,
                            // @ts-ignore
                            search: false,
                        },
                    },
                    {
                        name: translate('Account'),
                        options: {
                            filter: false,
                        },
                    },
                    {
                        name: translate('Account Number'),
                        options: {
                            filter: false,
                        },
                    },
                    {
                        name: translate('RFC'),
                        options: {
                            filter: false,
                        },
                    },
                    {
                        name: translate('Business Name'),
                        options: {
                            filter: false,
                        },
                    },
                    {
                        name: translate('Contact Name'),
                        options: {
                            filter: false,
                        },
                    },
                    {
                        name: translate('Email Invoices'),
                        options: {
                            filter: false,
                        },
                    },
                    {
                        name: translate('Payment in Establishment'),
                        options: {
                            filter: false,
                        },
                    },
                    {
                        name: translate('Commission Web Card'),
                    },
                    {
                        name: translate('Commission Web Cash'),
                    },
                    {
                        name: translate('Commission Web Card on delivery'),
                    },
                    {
                        name: translate('Commission App Card'),
                    },
                    {
                        name: translate('Commission App Cash'),
                    },
                    {
                        name: translate('Commission App CardOnDelivery'),
                    },
                    {
                        name: translate('Commission Manual Orders'),
                    },
                    {
                        name: translate('Device'),
                    },
                    {
                        name: translate('Responsible'),
                    },
                    {
                        name: translate('Bill'),
                    },
                    {
                        name: translate('Country'),
                    },
                ]}
                options={{
                    responsive: 'standard',
                    tableBodyMaxHeight: '500px',
                    selectableRows: !viewUser ? 'multiple' : 'none',
                    customToolbarSelect: (selectedRows, displayData) => {
                        const selectedRowsData = getSelectedRowsData(selectedRows, displayData);
                        const selectedRestaurantIds = selectedRowsData.map((d: any) => d[0]);
                        const handleClickExportBankAccounts = async () => {
                            const response = await letseatmanagerApiDeprecated.admin.exportRestaurantAccounts({ restaurantIds: selectedRestaurantIds });
                            if (!response.ok) {
                                alertKnownErrorOrSomethingWentWrong(response);
                                return;
                            }
                            const result = response.data;
                            downloadTextInFile(result.accountExport, 'accounts-export.txt');
                            alert([`exported: ${result.exported}`, `unregistered: ${result.unregistered}`, `removed: ${result.removed}`, `errors: ${result.errors}`].join('\n'));
                        };
                        return (
                            <CustomToolbarSelect>
                                <DownloadCsvToolbarButton onClick={createHandleClickDownloadCsv({ table: table.current }, getSelectedRows(selectedRows, displayData))} />
                                <ExportToolbarButton onClick={handleClickExportBankAccounts} tooltip={translate('Export As Bank Accounts')} />
                            </CustomToolbarSelect>
                        );
                    },
                    filterType: 'checkbox',
                    rowsPerPage: 100,
                    customToolbar: () => {
                        const handleClickExportUberDirect = async () => {
                            setExporting(true);
                            const response = await exportRestaurantDetailsUberDirectApi();
                            if (!response.ok) {
                                alertKnownErrorOrSomethingWentWrong(response);
                                setExporting(false);
                                return;
                            }
                            const result = response.data;
                            downloadTextInFile(result.uberDirectExport, 'UberDirect-export.csv');
                            setExporting(false);
                            alert([`exported: ${result.exported}`, `removed: ${result.removed}`, `errors: ${result.errors}`].join('\n'));
                        };

                        const handleUploadExcel = async (event: any) => {
                            let fileParsed;
                            if (event?.target?.files.length > 0) {
                                fileParsed = await convertCsvToImportRestaurantDetailsUberDirect(event.target.files[0]);
                                setExporting(true);
                                const response = await importRestaurantDetailsUberDirectApi(fileParsed);
                                if (!response.ok) {
                                    alertKnownErrorOrSomethingWentWrong(response);
                                    setExporting(false);
                                    return;
                                }
                                const result = response.data;
                                setExporting(false);
                                alert([`imported: ${result.imported}`].join('\n'));
                            }
                        };
                        return (
                            <>
                                <Button color='primary' onClick={handleClickExportUberDirect} disabled={exporting}>
                                    {translate('export-NonUberDirectStoreIdRestaurants')}
                                </Button>
                                <Button color='primary' disabled={exporting} component='label'>
                                    {translate('Import-UberDirectDetails')}
                                    <input type='file' hidden onChange={handleUploadExcel} />
                                </Button>
                                <Tooltip title='Refresh'>
                                    <IconButton onClick={fetch as any}>
                                        <RefreshIcon />
                                    </IconButton>
                                </Tooltip>
                            </>
                        );
                    },
                    onDownload: createFilteredOnDownload({ table: table.current }),
                    // filterList: [[], [], [], [], ['MEXICO']],
                }}
            />
        </div>
    );
}

const useStyles = makeStyles(styles);
