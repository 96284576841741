import Grid from '@material-ui/core/Grid';
import * as React from 'react';
import { useEffect } from 'react';
import { app2 } from 'src/app2';
import { translate } from 'src/i18n/translate';
import { BrandDetails } from 'src/scenes/letseatadmin/brand/BrandDetails';
import { BrandVm } from 'src/types/BrandVm';
import type { BrandId } from 'src/types/Id';
import { useAction } from 'src/utils/react/useAction';
import { useParams } from 'src/utils/react/useParams';

export function BrandPage(): React.ReactElement {
    const setTitle = useAction(app2.actions.setTitle);
    const { brandId } = useParams<{
        brandId: BrandId;
    }>();
    useEffect(() => {
        handleChangeBrand();
    }, []);

    const handleChangeBrand = (brand?: BrandVm) => {
        if (!brand || !brand.brandName) {
            setTitle(`${translate('Brands')} > ${brandId.substring(0, 8)}`);
            return;
        }
        setTitle(`${translate('Brands')} > ${brand.brandName ?? ''}`);
    };

    return (
        <Grid container spacing={5} style={{ paddingBottom: 40 }}>
            <Grid item xs={12}>
                <BrandDetails brandId={brandId} onChangeBrand={handleChangeBrand} />
            </Grid>
        </Grid>
    );
}
