import { makeStyles } from '@material-ui/core';
import * as React from 'react';
import { useState } from 'react';
import { changeMenuApi } from 'src/api/letseatmanager/menu/changeMenuApi';
import { Button } from 'src/components/Button';
import { DraggableList } from 'src/components/DraggableList';
import { DraggableListItem } from 'src/components/DraggableListItem';
import { translate } from 'src/i18n/translate';
import { MenuCategoriesInMenuEmptyEstate } from 'src/scenes/letseatmanager/menu/menu/MenuCategoriesInMenuEmptyEstate';
import { MenuCategoryInMenu } from 'src/scenes/letseatmanager/menu/menu/MenuCategoryInMenu';
import { SelectMenuCategoriesDialog } from 'src/scenes/letseatmanager/menu/menu/SelectMenuCategoriesDialog';
import { useIsMenuEditionDisabled } from 'src/services/menu/useIsMenuEditionDisabled';
import { useLoadMenus } from 'src/services/menu/useLoadMenus';
import { useMenuCategories } from 'src/services/menuCategory/useMenuCategories';
import { MenuCategoryId } from 'src/types/Id';
import { MenuCategoryVm } from 'src/types/MenuCategoryVm';
import type { MenuVm } from 'src/types/MenuVm';
import { alertKnownErrorOrSomethingWentWrong } from 'src/utils/alert/alertKnownErrorOrSomethingWentWrong';

export function Menu({ menu, onClose }: Props): React.ReactElement | null {
    const classes = useStyles();
    const isMenuEditionDisabled = useIsMenuEditionDisabled();

    const [addMenuCategoriesDialogState, setAddMenuCategoriesDialogState] = useState({ open: false });

    const { menuCategories } = useMenuCategories();
    const refreshMenus = useLoadMenus();

    const selectedMenuHasMenuCategories = menu && menu.menuCategoryIds.length > 0;

    const openAddMenuCategoriesDialog = () => setAddMenuCategoriesDialogState({ open: true });

    const closeAddMenuCategoriesDialog = () => setAddMenuCategoriesDialogState({ open: false });

    const changeMenu = async (menuCategoryIds: any) => {
        const response = await changeMenuApi({
            menuId: menu.menuId,
            menuCategoryIds: menuCategoryIds,
            name: menu.name,
            hidden: menu.hidden,
            hours: menu.hours,
            companyName: menu.companyName,
            channels: menu.channels,
        });
        if (!response.ok) {
            alertKnownErrorOrSomethingWentWrong(response);
            return;
        }
        await refreshMenus();
    };

    return (
        <div className={classes.menuContainer}>
            <SelectMenuCategoriesDialog open={addMenuCategoriesDialogState.open} defaultSelectedMenuCategories={menu.menuCategoryIds} onClose={closeAddMenuCategoriesDialog} onSuccess={changeMenu} />
            <div className={classes.menuNameContainer}>
                <h3 className={classes.menuName}>{menu.name}</h3>
                <div className={classes.menuButtonsContainer}>
                    <Button outlined onClick={openAddMenuCategoriesDialog} disabled={isMenuEditionDisabled}>
                        {translate('Add categories +')}
                    </Button>
                </div>
            </div>
            {!selectedMenuHasMenuCategories && <MenuCategoriesInMenuEmptyEstate />}
            {selectedMenuHasMenuCategories && (
                <DraggableList onDragEnd={changeMenu}>
                    {menu.menuCategoryIds?.map((menuCategoryId: MenuCategoryId) => {
                        const menuCategory = menuCategories?.find((menuCategory: MenuCategoryVm) => menuCategory.menuCategoryId === menuCategoryId);
                        if (!menuCategory) return null;

                        return (
                            <DraggableListItem key={menuCategoryId} value={menuCategoryId} iconColor={'#6C7076'} classes={{ iconContainer: classes.draggableItemIconContainer }}>
                                <MenuCategoryInMenu menuId={menu.menuId} menuCategory={menuCategory} />
                            </DraggableListItem>
                        );
                    })}
                </DraggableList>
            )}
        </div>
    );
}

const useStyles = makeStyles((theme) => ({
    menuContainer: {
        display: 'flex',
        width: '100%',
        flexDirection: 'column',
    },
    menuNameContainer: {
        display: 'flex',
        width: '100%',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        marginBottom: 20,
    },
    menuButtonsContainer: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        gap: 10,
    },
    menuName: {
        fontFamily: theme.typography.medium,
        fontSize: 17,
        margin: 0,
    },
    draggableItemIconContainer: {
        marginTop: 10,
    },
    button: {
        color: '#06B7A2',
    },
}));

type Props = {
    menu: MenuVm;
    onClose: any;
};
