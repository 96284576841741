import { makeStyles } from '@material-ui/core';
import { Button, CircleIconContainer, Switch, Text } from '@pidedirecto/ui';
import { CashIcon, ChainIcon, CreditCardIcon, TerminalIcon } from '@pidedirecto/ui/icons';
import { BigNumber } from 'bignumber.js';
import { useState } from 'react';
import * as React from 'react';
import { useHistory } from 'react-router-dom';
import { changeRestaurantPaymentMethodApi } from 'src/api/letseatmanager/restaurantPaymentMethod/changeRestaurantPaymentMethodApi';
import { Chip } from 'src/components/Chip';
import { PaymentMethod } from 'src/constants/PaymentMethod';
import { PaymentTerminalProviders } from 'src/constants/PaymentTerminalProvider';
import { RestaurantPaymentMethodApp } from 'src/constants/RestaurantPaymentMethodApps';
import { RestaurantPaymentMethodTypes } from 'src/constants/RestaurantPaymentMethodType';
import { RoutePaths } from 'src/constants/RoutePath';
import { translate } from 'src/i18n/translate';
import { EditIcon } from 'src/icons/EditIcon';
import { PaymentMethodIcon } from 'src/scenes/letseatmanager/theRestaurant/PaymentMethodIcon';
import type { RestaurantPaymentMethodVm } from 'src/types/RestaurantPaymentMethodVm';
import { alertKnownErrorOrSomethingWentWrong } from 'src/utils/alert/alertKnownErrorOrSomethingWentWrong';
import { isArgentina } from 'src/utils/country/isArgentina';
import { isMexico } from 'src/utils/country/isMexico';
import { isUruguay } from 'src/utils/country/isUruguay';
import { isCardPayment } from 'src/utils/paymentMethod/isCardPayment';
import { isCashPayment } from 'src/utils/paymentMethod/isCashPayment';
import { isPaymentLinkPayment } from 'src/utils/paymentMethod/isPaymentLinkPayment';
import { isTerminalPayment } from 'src/utils/paymentMethod/isTerminalPayment';
import { classNames } from 'src/utils/react/classNames';
import { useSelector } from 'src/utils/react/useSelector';
import { isPidedirectoPayment } from 'src/utils/restaurantPaymentMethod/isPidedirectoPayment';

export function PaymentMethodSettings({ paymentMethod, onEdit }: Props): React.ReactElement {
    const classes = useStyles();
    const history = useHistory();

    const country = useSelector((state) => state.app.restaurant.country);

    const [enabledPaymentMethod, setEnabledPaymentMethod] = useState(paymentMethod.enabled);
    const [loading, setLoading] = useState(false);

    const handleSwitchEnabledPaymentMethod = async () => {
        setLoading(true);
        const response = await changeRestaurantPaymentMethodApi({
            ...paymentMethod,
            enabled: !enabledPaymentMethod,
            paymentTerminalProvider: isTerminalPayment(paymentMethod.paymentMethod) ? getTerminalProvider() : undefined,
        });
        setLoading(false);
        if (!response.ok) {
            setEnabledPaymentMethod(enabledPaymentMethod);
            alertKnownErrorOrSomethingWentWrong(response);
            return;
        }
        setEnabledPaymentMethod(!enabledPaymentMethod);
    };

    const getTerminalProvider = () => {
        if (isMexico(country)) return PaymentTerminalProviders.QPAY;
        return PaymentTerminalProviders.QPAY;
    };

    const getPaymentMethodIcon = (paymentMethod: PaymentMethod) => {
        if (isCashPayment(paymentMethod)) return <CashIcon size={28} title={translate('Cash')} />;
        if (isCardPayment(paymentMethod)) return <CreditCardIcon title={translate('Card')} />;
        if (isTerminalPayment(paymentMethod)) return <TerminalIcon size={28} title={translate('Terminal')} />;
        if (isPaymentLinkPayment(paymentMethod)) return <ChainIcon title={translate('Chain')} />;
    };

    const getPaymentTitle = (paymentMethod: PaymentMethod) => {
        if (isTerminalPayment(paymentMethod)) {
            if (isMexico(country)) {
                return translate('Terminal @payment', { payment: PaymentTerminalProviders.QPAY });
            }
            if (isArgentina(country) || isUruguay(country)) {
                return translate('Terminal @payment', { payment: PaymentTerminalProviders.OCA });
            }
        }
        return translate(paymentMethod);
    };

    const handleRedirectToDevicesPage = () => {
        history.push({
            pathname: RoutePaths.DEVICES,
            search: history.location.search,
        });
    };

    return (
        <div className={classes.paymentMethodSettingsContainer}>
            <div className={classes.paymentMethodSettingsHeader}>
                <div className={classes.paymentMethodInfoContainer}>
                    {isPidedirectoPayment(paymentMethod.restaurantPaymentMethodType) && (
                        <CircleIconContainer classes={{ container: classes.iconContainer }}>{getPaymentMethodIcon(paymentMethod.paymentMethod)}</CircleIconContainer>
                    )}
                    {!isPidedirectoPayment(paymentMethod.restaurantPaymentMethodType) && (
                        <CircleIconContainer classes={{ container: classes.iconContainer }}>
                            <PaymentMethodIcon icon={paymentMethod.icon} />
                        </CircleIconContainer>
                    )}
                    <div className={classes.paymentMethodInfo}>
                        <Text className={classNames({ [classes.paymentDisabledContainer]: !enabledPaymentMethod })}>
                            {paymentMethod.restaurantPaymentMethodType === RestaurantPaymentMethodTypes.PIDE_DIRECTO ? getPaymentTitle(paymentMethod.paymentMethod) : translate(paymentMethod?.name)}
                        </Text>
                        <Text className={classNames({ [classes.paymentDisabledContainer]: !enabledPaymentMethod })} size={'small'}>
                            {translate(`PaymentMethods.${paymentMethod.paymentMethod}`)}
                        </Text>
                    </div>
                    <Switch
                        name={'enabledPaymentMethod'}
                        aria-label={translate('Switch enable payment method')}
                        checked={enabledPaymentMethod}
                        onClick={handleSwitchEnabledPaymentMethod}
                        disabled={loading}
                    />
                </div>
                {!isTerminalPayment(paymentMethod.paymentMethod) && (
                    <Button
                        classes={{ button: enabledPaymentMethod ? classes.editButton : classes.editButtonDisabled }}
                        variant={'outline'}
                        onClick={() => onEdit(paymentMethod)}
                        disabled={!enabledPaymentMethod}
                    >
                        <EditIcon title={translate('Pencil')} />
                    </Button>
                )}
                {isTerminalPayment(paymentMethod.paymentMethod) && (
                    <Button
                        classes={{ button: enabledPaymentMethod ? classes.editButton : classes.editButtonDisabled }}
                        variant={'outline'}
                        onClick={handleRedirectToDevicesPage}
                        disabled={!enabledPaymentMethod}
                    >
                        <EditIcon title={translate('Pencil')} />
                    </Button>
                )}
            </div>
            <div className={classes.footContainer}>
                <div className={classes.channelsInfo}>
                    {!!paymentMethod?.numberOfPaymentTerminals && (
                        <div className={classes.numberOfPaymentTerminalsContainer}>
                            <div className={classes.numberOfPaymentTerminals}>{paymentMethod?.numberOfPaymentTerminals}</div>
                            <Text weight={'bold'}>{translate('Linked devices')}</Text>
                        </div>
                    )}
                    {enabledPaymentMethod && paymentMethod.channels && <Text className={classNames({ [classes.paymentDisabledContainer]: !enabledPaymentMethod })}>{translate('Available on')}</Text>}
                    {!enabledPaymentMethod && <Text className={classNames({ [classes.paymentDisabledContainer]: !enabledPaymentMethod })}>{translate('Enable this method')}</Text>}
                    <div className={classes.channelsContainer}>
                        {paymentMethod.channels?.map((channel: RestaurantPaymentMethodApp) => {
                            return <Chip classes={{ chip: enabledPaymentMethod ? classes.chipContainer : classes.chipDisabledContainer }}>{translate(`RestaurantPaymentMethodApps.${channel}`)}</Chip>;
                        })}
                    </div>
                </div>
                {!!paymentMethod?.commission && (
                    <div className={classes.commissionContainer}>
                        <Text className={classNames(classes.commissionContainer, { [classes.paymentDisabledContainer]: !enabledPaymentMethod })}>
                            {translate('@commission% Commission', {
                                commission: BigNumber(paymentMethod?.commission ?? 0)
                                    .multipliedBy(100)
                                    .toNumber(),
                            })}
                        </Text>
                    </div>
                )}
            </div>
        </div>
    );
}

const useStyles = makeStyles((theme) => ({
    paymentMethodSettingsContainer: {
        width: 479,
        minHeight: 170,
        border: `1px solid ${theme.palette.secondary.dark}`,
        padding: 12,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
    },
    paymentMethodSettingsHeader: {
        width: '100%',
        display: 'flex',
        justifyContent: 'space-between',
    },
    paymentMethodInfoContainer: {
        display: 'flex',
        gap: 12,
        alignItems: 'center',
    },
    paymentMethodInfo: {
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
    },
    paymentDisabledContainer: {
        color: '#D9D9D9',
    },
    footContainer: {
        width: '100%',
        display: 'flex',
        alignItems: 'flex-end',
        justifyContent: 'space-between',
    },
    channelsInfo: {
        display: 'flex',
        flexDirection: 'column',
        gap: 6,
        width: '100%',
    },
    commissionContainer: {
        width: '50%',
        textAlign: 'right',
    },
    commissionText: {
        fontSize: 14,
    },
    chipContainer: {
        backgroundColor: theme.palette.surface.contrast,
        color: 'white',
    },
    channelsContainer: {
        width: '80%',
        height: 75,
        overflowY: 'scroll',
        '&::-webkit-scrollbar': {
            display: 'none',
        },
        display: 'flex',
        flexWrap: 'wrap',
        gap: 6,
    },
    chipDisabledContainer: {
        backgroundColor: '#D9D9D9',
        color: 'white',
    },
    editButton: {
        border: 'none',
    },
    editButtonDisabled: {
        backgroundColor: 'transparent !important',
    },
    iconContainer: {
        width: 100,
        height: 50,
        backgroundColor: '#f1f1f1',
    },
    numberOfPaymentTerminalsContainer: {
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        gap: 6,
        marginTop: 12,
    },
    numberOfPaymentTerminals: {
        border: `1px solid ${theme.palette.surface.brand}`,
        padding: '2px 10px',
        borderRadius: '100%',
        backgroundColor: theme.palette.surface.brandContrast,
        fontFamily: theme.typography.regular,
        color: 'white',

        '&:hover': {
            cursor: 'pointer',
        },
    },
}));

type Props = {
    paymentMethod: RestaurantPaymentMethodVm;
    onEdit: (paymentMethod: RestaurantPaymentMethodVm) => void;
};
