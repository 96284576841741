import { makeStyles } from '@material-ui/core';
import * as React from 'react';
import { useEffect } from 'react';
import { useContext } from 'react';
import { useForm, useWatch } from 'react-hook-form';
import { Button } from 'src/components/Button';
import { Form } from 'src/components/form/Form';
import { FormSelect } from 'src/components/form/FormSelect';
import { CreatePrinterSteps } from 'src/constants/CreatePrinterStep';
import { PrinterPaperSizes } from 'src/constants/PrinterPaperSize';
import { translate } from 'src/i18n/translate';
import { PrinterWizardContext, PrinterWizardProviderValues } from 'src/providers/PrinterWizardProvider';

export function PrinterPaperSizeSelection(): React.ReactElement | null {
    const classes = useStyles();

    const { formValues, goNextWizardStep, goBackWizardStep, setCreatePrinterWizardFormValue, activeCreatePrinterStep } = useContext<PrinterWizardProviderValues>(PrinterWizardContext);

    const form = useForm();
    const { control } = form;

    const printerPaperSize = useWatch({ name: 'printerPaperSize', control });

    useEffect(() => {
        document.addEventListener('keydown', handleKeyDown);
        return () => {
            document.removeEventListener('keydown', handleKeyDown);
        };
    }, []);

    const handleChangePrinterPaperSize = (printerPaperSize: any) => {
        setCreatePrinterWizardFormValue('printerPaperSize', printerPaperSize);
        goNextWizardStep();
    };

    const handleKeyDown = (event: KeyboardEvent) => {
        if (event.key === 'Enter') {
            handleChangePrinterPaperSize(printerPaperSize);
        }
    };

    const handleSubmit = async (form: any) => {
        handleChangePrinterPaperSize(form.printerPaperSize);
    };

    if (activeCreatePrinterStep !== CreatePrinterSteps.PRINTER_PAPER_SIZE) return null;

    return (
        <Form form={form} onSubmit={handleSubmit} className={classes.form}>
            <FormSelect
                name='printerPaperSize'
                label={translate('Printer Paper Size')}
                options={Object.values(PrinterPaperSizes).map((value: any) => ({ label: translate(`PrinterPaperSizes.${value}`), value: value }))}
                defaultValue={formValues.printerPaperSize}
                classesProp={{ label: classes.label }}
                required={true}
            />
            <div className={classes.actionsContainer}>
                <Button secondary onClick={goBackWizardStep} type={'button'}>
                    {translate('Back')}
                </Button>
                <Button primary type={'submit'}>
                    {translate('Next')}
                </Button>
                <p className={classes.enterText}>{translate('hit Enter ↩')}</p>
            </div>
        </Form>
    );
}

const useStyles = makeStyles((theme) => ({
    form: {
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        animation: '$appear 700ms ease',
    },
    label: {
        fontSize: 16,
        fontFamily: theme.typography.regular,
        color: '#0D3037',
        padding: 0,
    },
    actionsContainer: {
        display: 'flex',
        gap: 12,
        marginTop: 24,
        width: '100%',
        justifyContent: 'flex-end',
    },
    enterText: {
        fontFamily: theme.typography.regular,
        color: '#0D3037',
    },
    '@keyframes appear': {
        '0%': {
            opacity: 0,
            transform: 'translateY(100px)',
        },
        '100%': {
            opacity: 1,
            transform: 'translateY(0px)',
        },
    },
}));
