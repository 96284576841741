import { makeStyles } from '@material-ui/core';
import { Button } from '@pidedirecto/ui';
import { CrossIcon } from '@pidedirecto/ui/icons';
import { BigNumber } from 'bignumber.js';
import * as React from 'react';
import { memo, useCallback } from 'react';
import { translate } from 'src/i18n/translate';
import { posReducer } from 'src/reducers/posReducer';
import { PosItemQuantitySpinner } from 'src/scenes/letseatmanager/pos/posAddMenuItem/PosItemQuantitySpinner';
import { usePosCartModifiersQuantity } from 'src/services/pos/posModifiersCart/usePosCartModifiersQuantity';
import { useSetPosCartModifiers } from 'src/services/pos/posModifiersCart/useSetPosCartModifiers';
import { useSetPosCartModifiersQuantity } from 'src/services/pos/posModifiersCart/useSetPosCartModifiersQuantity';
import { isItemSoldByWeight } from 'src/utils/orderItem/isItemSoldByWeight';
import { useAction } from 'src/utils/react/useAction';
import { useSelector } from 'src/utils/react/useSelector';

export const PosCartModifiersQuantitySpinner: React.ComponentType<any> = memo<any>(() => {
    const classes = useStyles();

    const posCartModifiersQuantity = usePosCartModifiersQuantity();
    const setPosCartModifiersQuantity = useSetPosCartModifiersQuantity();
    const setPosCartModifiers = useSetPosCartModifiers();

    const item = useSelector((state) => state.pos.itemSelected);

    const closeAddMenuItem = useAction(posReducer.actions.closeAddMenuItem);

    const quantityLabel = React.useMemo(() => {
        return !!item?.salesUnit ? `${posCartModifiersQuantity}${translate(`UnitsOfMeasurement.${item?.salesUnit}`)}` : posCartModifiersQuantity;
    }, [posCartModifiersQuantity, item]);

    const addOneItem = useCallback(() => {
        const updatedQuantity = BigNumber(posCartModifiersQuantity).plus(1).toNumber();
        setPosCartModifiersQuantity(updatedQuantity);
    }, [posCartModifiersQuantity, item]);

    const removeOneItem = useCallback(() => {
        if (posCartModifiersQuantity <= 1) return;
        const updatedQuantity = BigNumber(posCartModifiersQuantity).minus(1).toNumber();
        setPosCartModifiersQuantity(updatedQuantity);
    }, [posCartModifiersQuantity, item]);

    const onCloseAddMenuItem = () => {
        closeAddMenuItem();
        setPosCartModifiers({});
    };

    return (
        <div className={classes.itemInfo}>
            <div>
                <h1 className={classes.itemName}>{translate('Customize your @itemName', { itemName: item.name })}</h1>
            </div>
            {isItemSoldByWeight(item) && <span className={classes.sectionName}>{quantityLabel}</span>}
            {!isItemSoldByWeight(item) && <PosItemQuantitySpinner quantity={posCartModifiersQuantity} onSubtract={removeOneItem} onAdd={addOneItem} />}
            <Button classes={{ button: classes.closeIconButton }} variant={'icon'} onClick={onCloseAddMenuItem}>
                <CrossIcon height={16} width={16} />
            </Button>
        </div>
    );
});
const useStyles = makeStyles((theme) => ({
    itemInfo: {
        display: 'flex',
        alignItems: 'center',
        width: '100%',
        gap: 30,
    },
    itemName: {
        fontSize: 20,
        marginRight: 20,
        fontFamily: theme.typography.medium,
        color: theme.palette.text.primary,
    },
    sectionName: {
        fontFamily: theme.typography.regular,
        fontSize: 15,
        color: theme.palette.text.secondary,
        margin: 0,
    },
    closeIconButton: {
        display: 'none',
        [theme.breakpoints.down('sm')]: {
            display: 'flex',
            color: theme.palette.icons.primary,
            alignSelf: 'end',
        },
    },
}));
