import { usePrintOrderCommandDeprecated } from 'src/services/deprecatedPrinter/usePrintOrderCommandDeprecated';
import { usePrintOrderCommandDeviceManagement } from 'src/services/printer/usePrintOrderCommandDeviceManagement';
import type { OrderVm } from 'src/types/OrderVm';
import { useSelector } from 'src/utils/react/useSelector';

export function usePrintOrderCommand(): [(order: OrderVm, forceToPrint?: boolean) => Promise<void>] {
    const newDeviceManagementEnabled = useSelector((state) => state.app.restaurant?.newDeviceManagementEnabled);

    const [printCommandDeprecated] = usePrintOrderCommandDeprecated();
    const [printCommand] = usePrintOrderCommandDeviceManagement();

    if (newDeviceManagementEnabled) {
        return [printCommand];
    } else {
        return [printCommandDeprecated];
    }
}
