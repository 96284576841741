import { Select } from '@pidedirecto/ui';
import * as React from 'react';
import { RestaurantCustomerAddress } from 'src/api/letseatmanager/restaurantCustomer/findRestaurantCustomersAddressOptionsApi';
import { translate } from 'src/i18n/translate';

export function RestaurantCustomerAddressSelect({ restaurantCustomerAddresses, value, onChange }: Props): React.ReactElement {
    const options = restaurantCustomerAddresses.map((restaurantCustomerAddress) => ({
        label: restaurantCustomerAddress.formattedAddress ?? restaurantCustomerAddress.street,
        value: restaurantCustomerAddress.street,
    }));

    return (
        <Select
            name={'restaurantCustomerAddresses'}
            label={translate('Select a location')}
            placeholder={translate('Select a location')}
            value={value}
            options={options}
            onChange={onChange}
            searchable
        />
    );
}

type Props = {
    restaurantCustomerAddresses: Array<RestaurantCustomerAddress>;
    value?: string;
    onChange: (street: string) => void;
};
