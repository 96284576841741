import { letseatmanagerApiMethod } from 'src/api/letseatmanagerApi';
import type { ApiSauceResponse } from 'src/api/types/ApiSauceResponse';
import type { SupplierId, SupplyId } from 'src/types/Id';
import type { SupplierVm } from 'src/types/SupplierVm';

export async function changeSupplierApi(request: ChangeSupplierApiRequest): ApiSauceResponse<SupplierVm> {
    return letseatmanagerApiMethod('supplier/changeSupplierApi', request);
}

export type ChangeSupplierApiRequest = {
    supplierId: SupplierId;
    supplyIds: Array<SupplyId>;
    businessName: string;
    rfc: string;
    email: string;
    phoneNumber: string;
    pendingBalance: string;
    note?: string;
};
