import { InputBase, InputLabel, makeStyles } from '@material-ui/core';
import FormHelperText from '@material-ui/core/FormHelperText';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import Autocomplete from '@material-ui/lab/Autocomplete';
import * as React from 'react';
import { useContext, useRef } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { FormContext } from 'src/components/form/Form';
import type { Variant } from 'src/components/form/FormTypes';
import { getError } from 'src/components/form/utils/getError';
import { Tooltip } from 'src/components/Tooltip';
import { translate } from 'src/i18n/translate';

export function FormAutocompleteMultiple({
    name,
    label,
    placeholder,
    helperText,
    defaultValue = [],
    options,
    disabled,
    required,
    variant,
    InputProps,
    onChange: onChangeProp,
    tooltip,
}: Props): React.ReactElement {
    const {
        errors,
        control,
        formState: { isSubmitting },
    } = useFormContext();
    const formContext = useContext(FormContext);
    const inputRef = useRef<HTMLInputElement>();
    const classes = useStyles();

    return (
        <Controller
            render={({ onChange, ...props }) => (
                <Autocomplete
                    renderInput={({ InputProps: ParamsInputProps, InputLabelProps: ParamsInputLabelProps, ...params }) => (
                        <div>
                            <InputLabel
                                htmlFor={`${name}-autocomplete-input`}
                                classes={{ root: classes.label, error: classes.labelError }}
                                error={!!getError(errors, name)}
                                {...ParamsInputLabelProps}
                                required={required as any}
                            >
                                {label}
                                {tooltip && <Tooltip text={tooltip} />}
                            </InputLabel>
                            <InputBase
                                {...props}
                                {...InputProps}
                                {...ParamsInputProps}
                                /* @ts-ignore */
                                id={`${name}-autocomplete-input`}
                                type='search'
                                error={!!getError(errors, name)}
                                classes={{ root: classes.input, error: classes.inputError }}
                                {...params}
                                label={label}
                                placeholder={!props.value?.length ? placeholder : undefined}
                                variant={variant}
                                inputRef={inputRef}
                            />
                            {!!(getError(errors, name)?.message ?? helperText) && (
                                <FormHelperText classes={{ root: getError(errors, name) ? classes.helperText : classes.helperText }}>{getError(errors, name)?.message ?? helperText}</FormHelperText>
                            )}
                        </div>
                    )}
                    // freeSolo={true}
                    // autoSelect={true}
                    multiple
                    options={options}
                    getOptionLabel={(data) => {
                        // console.log('getOptionLabel data = ', data);
                        if (data?.label) {
                            return data.label;
                        }
                        return options.find((option) => option.value === data)?.label ?? '';
                    }}
                    getOptionSelected={(option, value) => {
                        // console.log('getOptionSelected option = ', option);
                        // console.log('getOptionSelected value = ', value);
                        return option.value === value;
                    }}
                    onChange={(e, data) => {
                        // console.log('onChange data = ', data);
                        onChange(data.map((option) => option.value ?? option) ?? []);
                        onChangeProp?.(data.map((option) => option.value ?? option) ?? []);
                    }}
                    /* @ts-ignore */
                    required={required}
                    disabled={isSubmitting || disabled || formContext.disabled}
                    fullWidth
                    popupIcon={<KeyboardArrowDownIcon />}
                    classes={{ popupIndicator: classes.icon }}
                    {...props}
                />
            )}
            control={control}
            name={name}
            defaultValue={defaultValue}
            rules={
                required
                    ? {
                          validate: {
                              required: (value) => (value.length > 0 ? true : ((required as any)?.message ?? translate('This field is required'))),
                          },
                      }
                    : {}
            }
            onFocus={() => {
                // make focus on error work when disabled={isSubmitting || disabled}
                if (inputRef.current) {
                    inputRef.current.disabled = false;
                    inputRef.current.focus();
                }
            }}
        />
    );
}

const useStyles = makeStyles((theme) => ({
    label: {
        fontFamily: theme.typography.light,
        color: theme.palette.text.primary,
        fontSize: 14,
        paddingBottom: 5,
    },
    labelError: {
        color: 'red',
    },
    input: {
        minHeight: '42px',
        width: '100%',
        outline: `1px solid ${theme.palette.border.primary}`,
        backgroundColor: 'transparent',
        borderRadius: '4px',
        position: 'relative',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        gap: 10,
        boxSizing: 'border-box',
        color: theme.palette.text.primary,
        fontFamily: theme.typography.light,
        border: 0,
        overflowY: 'scroll',
    },
    inputError: {
        border: '2px solid red',
    },
    helperTextError: {
        fontFamily: theme.typography.light,
        color: 'red',
    },
    icon: {
        color: theme.palette.icons.primary,
        marginRight: theme.spacing(1),
    },
    helperText: {
        fontFamily: theme.typography.light,
        color: theme.palette.text.secondary,
    },
}));

export type Props = {
    name: string;
    label?: string;
    placeholder?: string;
    helperText?: string;
    defaultValue?: Array<string>;
    options: Array<{
        label: string;
        value: any;
    }>;
    disabled?: boolean;
    required?:
        | boolean
        | {
              value: number;
              message: string;
          };
    variant?: Variant;
    InputProps?: any;
    onChange?: any;
    tooltip?: string;
};
