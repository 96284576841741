import { makeStyles } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { ShieldIcon } from '@pidedirecto/ui/icons';
import * as React from 'react';
import { GroupedOrderItems } from 'src/components/orderCard/orderItems/GroupedOrderItems';
import { GroupedOrderItemsByCustomer } from 'src/components/orderCard/orderItems/GroupedOrderItemsByCustomer';
import { OrderItems } from 'src/components/orderCard/orderItems/OrderItems';
import { ReturnedOrderItems } from 'src/components/orderCard/orderItems/ReturnedOrderItems';
import { OrderTypeSelect } from 'src/components/orderCard/OrderTypeSelect';
import { Apps } from 'src/constants/App';
import { translate } from 'src/i18n/translate';
import { LocalizationService } from 'src/services/LocalizationService';
import type { OrderVm } from 'src/types/OrderVm';
import { classNames } from 'src/utils/react/classNames';
import { useUserIsAnAvailableWaiter } from 'src/utils/react/useUserIsAnAvailableWaiter';

/** @deprecated */
export function DeprecatedOrderCardDetailsInfo({ order, onChange }: Props): React.ReactElement {
    const classes = useStyles();

    const [userIsAnAvailableWaiter] = useUserIsAnAvailableWaiter();

    const showOrderItems = order.app !== Apps.PIDEDIRECTOADMIN && order.app !== Apps.LETSEATMANAGER && order.app !== Apps.PIDEDIRECTO_API; // remove && order.app !== Apps.LETSEATMANAGER when Apps.PIDEDIRECTOADMIN is used instead of Apps.LETSEATMANAGER everywhere
    const isPosOrder = order.app === Apps.PIDEDIRECTOPOS;
    const shouldShowWaiterName = order.waiterName && userIsAnAvailableWaiter(order.servedBy);

    return (
        <div style={{ width: '100%' }}>
            <div className={classes.spaceBetween}>
                <Typography className={classes.text}>
                    <span>{translate('Order type')}:</span>
                </Typography>
                <OrderTypeSelect order={order} onChangeOrderType={onChange} />
            </div>

            {order.isManualOrderProtected && (
                <div className={classes.directoProtectionContainer}>
                    <ShieldIcon color={'#8d25ff'} />
                    <span className={classes.text}>{translate('Directo Protection')}</span>
                </div>
            )}

            <div className={classes.separator}></div>

            {!!order.table && (
                <>
                    <div className={classes.tableOrderInfo}>
                        {!!order.restaurantZone && <span className={classes.text}>{order.restaurantZone || 'restaurantZone'}</span>}
                        {!!order.table && (
                            <div className={classes.separatedText}>
                                <span className={classes.text}>{translate('Table')}</span>
                                <span className={classNames(classes.text, classes.tableName)}>{order.table}</span>
                            </div>
                        )}
                        {!!order.numberOfCustomers && (
                            <div className={classes.separatedText}>
                                <span className={classes.text}>{translate('Customers')}</span>
                                <span className={classes.text}>{order.numberOfCustomers}</span>
                            </div>
                        )}
                        <div className={classes.separatedText}>
                            <span className={classes.text}>{translate('Modified at')}</span>
                            <span className={classes.text}>{LocalizationService.formatDateTimeAsTime(order.modifiedAt, order.timeZone)}</span>
                        </div>
                    </div>
                    <div className={classes.separator}></div>
                </>
            )}
            {!!order.cashRegisterPosBusinessDayNumber && (
                <div className={classes.separatedText}>
                    <span className={classes.text}>{translate('Cash Register')}</span>
                    <span className={classes.text}>{order.cashRegisterPosBusinessDayNumber}</span>
                </div>
            )}
            {shouldShowWaiterName && (
                <div className={classes.separatedText}>
                    <span className={classes.text}>{translate('Waiter')}</span>
                    <span className={classes.text}>{order.waiterName}</span>
                </div>
            )}
            <Grid container spacing={1}>
                <Grid item xs={12}>
                    <Typography variant='h6' className={classNames(classes.text, classes.spaceBetween)}>
                        <div>{translate('Cooking time')}</div>
                        <div style={{ fontWeight: '600' }}>{order?.preparationTime || 15} min</div>
                    </Typography>
                </Grid>
                <div className={classes.separator}></div>
            </Grid>
            {showOrderItems && (
                <Grid container>
                    <Grid item xs={12} style={{ padding: 0 }}>
                        {isPosOrder && !order.customers?.length && <GroupedOrderItems orderItems={order.orderItems} cancelledItems={order.cancelledItems} />}
                        {isPosOrder && !!order.customers?.length && <GroupedOrderItemsByCustomer customers={order.customers} />}
                        {!isPosOrder && <OrderItems orderItems={order.orderItems} />}
                        {!!order.returnedOrderItems && <ReturnedOrderItems returnedOrderItems={order.returnedOrderItems} />}
                    </Grid>
                    <div className={classes.separator} style={{ borderStyle: 'dashed', borderColor: '#ABAEB5' }}></div>
                </Grid>
            )}
        </div>
    );
}

const useStyles = makeStyles((theme) => ({
    separator: {
        height: '0px',
        width: '100%',
        border: '1px solid #F3F3F3',
        margin: '12px 0px',
    },

    text: {
        fontFamily: theme.typography.regular,
        fontSize: 14,
        color: '#454A4A',
    },
    tableOrderInfo: {
        display: 'flex',
        flexDirection: 'column',
    },
    spaceBetween: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        width: '100%',
    },
    separatedText: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
    },
    tableName: {
        width: '100%',
        textAlign: 'right',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        maxWidth: 200,
    },
    directoProtectionContainer: {
        display: 'flex',
        justifyContent: 'right',
        alignItems: 'center',
    },
}));

type Props = {
    order: OrderVm;
    onChange?: any;
};
