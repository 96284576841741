import { letseatmanagerApiMethod } from 'src/api/letseatmanagerApi';
import type { ApiSauceResponse } from 'src/api/types/ApiSauceResponse';
import type { ManagerUserId, RestaurantId, SupplyId } from 'src/types/Id';
import type { ProductionVm } from 'src/types/ProductionVm';

export async function produceCompoundSuppliesApi(request: ProduceCompoundSuppliesApiRequest): ApiSauceResponse<ProductionVm> {
    return letseatmanagerApiMethod('supply/produceCompoundSuppliesApi', request);
}

export type ProduceCompoundSuppliesApiRequest = {
    compoundSupplies: Array<{
        supplyId: SupplyId;
        quantity: string;
    }>;
    restaurantId?: RestaurantId;
    managerUserId?: ManagerUserId;
};
