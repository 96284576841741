import { letseatmanagerApiMethod } from 'src/api/letseatmanagerApi';
import type { ApiSauceResponse } from 'src/api/types/ApiSauceResponse';
import type { RestaurantId } from 'src/types/Id';
import type { TheMenuVm } from 'src/types/TheMenuVm';

export async function deprecatedGetMenuApi(request: GetMenuApiRequest): ApiSauceResponse<TheMenuVm> {
    return letseatmanagerApiMethod('menu/deprecatedGetMenuApi', request);
}

type GetMenuApiRequest = {
    restaurantId: RestaurantId;
};
