import { letseatmanagerApiMethod } from 'src/api/letseatmanagerApi';
import type { ApiSauceResponse } from 'src/api/types/ApiSauceResponse';
import { PromotionId } from 'src/types/Id';
import { PromotionVm } from 'src/types/PromotionVm';

export async function removePromotionApi(request: RemovePromotionApiRequest): ApiSauceResponse<PromotionVm> {
    return letseatmanagerApiMethod('promotion/removePromotionApi', request);
}

export type RemovePromotionApiRequest = {
    promotionId: PromotionId;
};
