import { letseatadminApiMethod } from 'src/api/letseatadminApi';
import type { ApiSauceResponse } from 'src/api/types/ApiSauceResponse';
import type { RestaurantId } from 'src/types/Id';

export async function changeRappiTurboEnabledApi(request: ChangeRappiTurboEnabledApiRequest): ApiSauceResponse<void> {
    return letseatadminApiMethod('rappiIntegration/changeRappiTurboEnabledApi', request);
}

export type ChangeRappiTurboEnabledApiRequest = {
    restaurantId: RestaurantId;
    rappiTurboEnabled: boolean;
};
