import { letseatadminApiMethod } from 'src/api/letseatadminApi';
import type { ApiSauceResponse } from 'src/api/types/ApiSauceResponse';
import type { City } from 'src/constants/City';
import type { LiveOrdersOverviewVm } from 'src/types/LiveOrdersOverviewVm';

export async function findOrdersOverviewLivePositionApi(request: FindOrdersOverviewLivePositionApiRequest): ApiSauceResponse<LiveOrdersOverviewVm> {
    return letseatadminApiMethod('findOrdersOverviewLivePositionApi', request);
}

type FindOrdersOverviewLivePositionApiRequest = {
    city: City;
};
