import { IconButton } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { BigNumber } from 'bignumber.js';
import { useState } from 'react';
import * as React from 'react';
import { BarChartCanvas } from 'src/components/charts/BarChartCanvas';
import { translate } from 'src/i18n/translate';
import { GraphIcon } from 'src/icons/GraphIcon';
import { TableIcon } from 'src/icons/TableIcon';
import { CashRegisterTransactionsByManagerUserTableReport } from 'src/scenes/letseatmanager/posReports/cashRegisterTransactionsReport/CashRegisterTransactionsByManagerUserTableReport';
import { GraphEmptyState } from 'src/scenes/letseatmanager/posReports/GraphEmptyState';
import { useFormatAsRestaurantCurrencyNumber } from 'src/services/restaurant/useFormatAsRestaurantCurrencyNumber';
import type { CashRegisterTransactionsReportVm } from 'src/types/CashRegisterTransactionsReportVm';
import { removeDuplicates } from 'src/utils/array/removeDuplicates';
import { sum } from 'src/utils/reduce/sum';

export function CashRegisterTransactionsByMangerUserReport({ cashRegisterTransactionsReport }: Props): React.ReactElement {
    const classes = useStyles();
    const formatAsCurrencyNumber = useFormatAsRestaurantCurrencyNumber();

    const [showTable, setShowTable] = useState(false);

    const getCashRegisterTransactionsBarChartData = () => {
        return [
            {
                id: translate('Deposits'),
                ...getCashRegisterDepositsByManagerUser(),
            },
            {
                id: translate('Withdraws'),
                ...getCashRegisterWithdrawsByManagerUser(),
            },
        ];
    };

    const getCashRegisterDepositsByManagerUser = () => {
        const cashRegisterDeposits: Record<string, any> = {};
        cashRegisterTransactionsReport.totalCashInflow.forEach((cashRegisterTransaction) => {
            if (!cashRegisterDeposits[cashRegisterTransaction.managerUser]) {
                cashRegisterDeposits[cashRegisterTransaction.managerUser] = BigNumber(cashRegisterTransaction.amount).toString();
                return;
            }
            cashRegisterDeposits[cashRegisterTransaction.managerUser] = BigNumber(cashRegisterDeposits[cashRegisterTransaction.managerUser]).plus(cashRegisterTransaction.amount).toString();
            return;
        });
        return cashRegisterDeposits;
    };

    const getCashRegisterWithdrawsByManagerUser = () => {
        const cashRegisterWithdraws: Record<string, any> = {};
        cashRegisterTransactionsReport.totalCashOut.forEach((cashRegisterTransaction) => {
            if (!cashRegisterWithdraws[cashRegisterTransaction.managerUser]) {
                cashRegisterWithdraws[cashRegisterTransaction.managerUser] = BigNumber(cashRegisterTransaction.amount).toString();
                return;
            }
            cashRegisterWithdraws[cashRegisterTransaction.managerUser] = BigNumber(cashRegisterWithdraws[cashRegisterTransaction.managerUser]).plus(cashRegisterTransaction.amount).toString();
            return;
        });
        return cashRegisterWithdraws;
    };

    const getManagerUsers = () => {
        const managerUsers = cashRegisterTransactionsReport.totalSummaryTransactionsByManagerUser.map((cashRegisterTransaction) => cashRegisterTransaction.managerUser);
        return removeDuplicates(managerUsers) ?? [];
    };

    const isEmptyReport = () => {
        const cashRegisterWithdraws = cashRegisterTransactionsReport.totalCashOut.map((cashRegisterTransaction) => cashRegisterTransaction.amount);
        const cashRegisterDeposits = cashRegisterTransactionsReport.totalCashInflow.map((cashRegisterTransaction) => cashRegisterTransaction.amount);

        const totalWithdraws = cashRegisterWithdraws.reduce(sum, BigNumber(0));
        const totalDeposits = cashRegisterDeposits.reduce(sum, BigNumber(0));

        return totalWithdraws.isZero() && totalDeposits.isZero();
    };

    if (isEmptyReport()) {
        return <GraphEmptyState title={translate('Transaction by user')} />;
    }

    return (
        <article className={classes.container}>
            <div className={classes.titleContainer}>
                <h2 className={classes.title}>{translate('Transaction by user')}</h2>
                <IconButton onClick={() => setShowTable(!showTable)} className={(classes as any).iconButton}>
                    {showTable && <GraphIcon />}
                    {!showTable && <TableIcon />}
                </IconButton>
            </div>
            {!showTable && (
                <BarChartCanvas
                    data={getCashRegisterTransactionsBarChartData()}
                    keys={getManagerUsers()}
                    tooltip={({ id, value }: any) => {
                        return (
                            <div className={classes.tooltip}>
                                <span className={classes.tooltipTitle}>{id}</span>
                                <span className={classes.tooltipText}>{translate('Total: @total', { total: formatAsCurrencyNumber(value) })}</span>
                            </div>
                        );
                    }}
                />
            )}
            {showTable && <CashRegisterTransactionsByManagerUserTableReport cashRegisterTransactionsByManagerUser={cashRegisterTransactionsReport.totalSummaryTransactionsByManagerUser} />}
        </article>
    );
}

const useStyles = makeStyles((theme) => ({
    container: {
        height: '70vh',
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
    },
    titleContainer: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        width: '100%',
        justifyContent: 'space-between',
    },
    title: {
        fontFamily: theme.typography.medium,
        color: theme.palette.text.primary,
    },
    tooltip: {
        padding: 10,
        borderRadius: 3,
        backgroundColor: 'white',
        boxShadow: '1px 1px 1px rgb(0,0,0,0.2)',
        display: 'flex',
        flexDirection: 'column',
    },
    tooltipTitle: {
        fontFamily: theme.typography.bold,
    },
    tooltipText: {
        fontFamily: theme.typography.regular,
    },
}));

type Props = {
    cashRegisterTransactionsReport: CashRegisterTransactionsReportVm;
};
