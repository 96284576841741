import { letseatmanagerApiMethod } from 'src/api/letseatmanagerApi';
import type { ApiSauceResponse } from 'src/api/types/ApiSauceResponse';
import type { OrderId } from 'src/types/Id';

export async function updateCashOrderSubTotalApi(request: UpdateCashOrderSubTotalApiRequest): ApiSauceResponse<void> {
    return letseatmanagerApiMethod('order/updateCashOrderSubTotalApi', request);
}

export type UpdateCashOrderSubTotalApiRequest = {
    orderId: OrderId;
    newSubTotal: string;
};
