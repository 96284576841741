import { makeStyles } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import * as React from 'react';
import { useForm } from 'react-hook-form';
import { requestPedidosYaIntegrationApi } from 'src/api/letseatmanager/restaurantIntegration/requestPedidosYaiIntegrationApi';
import { Button } from 'src/components/Button';
import { Dialog } from 'src/components/Dialog';
import { DialogActions } from 'src/components/DialogActions';
import { Form } from 'src/components/form/Form';
import { FormFixErrorHint } from 'src/components/form/FormFixErrorHint';
import { FormTextField } from 'src/components/form/FormTextField';
import { translate } from 'src/i18n/translate';
import { appLayout } from 'src/theme/AppLayout';
import { alertKnownErrorOrSomethingWentWrong } from 'src/utils/alert/alertKnownErrorOrSomethingWentWrong';
import { useSelector } from 'src/utils/react/useSelector';

export function IntegratePedidosYaDialog({ open, onClose }: Props): React.ReactElement {
    const form = useForm();
    const classes = useStyles();
    const {
        formState: { isSubmitting },
    } = form;

    const restaurantId = useSelector((state) => state.app.restaurantId);

    const handleClose = () => {
        if (isSubmitting) return;
        onClose();
    };

    const onSubmit = async (form: any) => {
        const response = await requestPedidosYaIntegrationApi({
            restaurantId: restaurantId,
            pedidosYaRestaurantId: form.pedidosYaRestaurantId,
            pedidosYaStoreId: form.pedidosYaStoreId,
        });
        if (!response.ok) {
            alertKnownErrorOrSomethingWentWrong(response);
            return;
        }
        onClose();
    };

    return (
        <Dialog open={open} onClose={handleClose} title={translate('Pedidos Ya Integration').toUpperCase()}>
            <Form form={form} onSubmit={onSubmit} style={appLayout.dialog.form}>
                <div className={classes.dialogContent}>
                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                            <FormTextField name={`pedidosYaRestaurantId`} label={translate('Insert your Pedidos Ya Restaurant Id')} required={true} />
                        </Grid>
                        <Grid item xs={12}>
                            <FormTextField name={`pedidosYaStoreId`} label={translate('Insert your Pedidos Ya pos vendor Id')} required={true} />
                        </Grid>
                    </Grid>
                    <FormFixErrorHint />
                </div>
                <DialogActions>
                    <Button outlined onClick={handleClose} disabled={isSubmitting}>
                        {translate('Cancel').toUpperCase()}
                    </Button>
                    <Button primary type='submit' disabled={isSubmitting}>
                        {isSubmitting ? translate('Changing').toUpperCase() : translate('Change').toUpperCase()}
                    </Button>
                </DialogActions>
            </Form>
        </Dialog>
    );
}

const useStyles = makeStyles((theme) => ({
    dialogContent: {
        overflow: 'hidden',
    },
}));

type Props = {
    open: boolean;
    onClose: any;
};
