import { makeStyles } from '@material-ui/core';
import { useConfirmDialog } from '@pidedirecto/ui/hooks';
import { BigNumber } from 'bignumber.js';
import moment from 'moment';
import { MUIDataTableColumn } from 'mui-datatables';
import * as React from 'react';
import { useEffect, useRef, useState } from 'react';
import { BrandCustomerVm, findBrandCustomersApi } from 'src/api/letseatmanager/brandCustomer/findBrandCustomersApi';
import { findRestaurantCustomersApi, RestaurantCustomerVm } from 'src/api/letseatmanager/restaurantCustomer/findRestaurantCustomersApi';
import { AddCreditsToCustomersDialog } from 'src/components/dialog/customer/AddCreditsToCustomersDialog';
import { RefreshToolbarButton } from 'src/components/mui-datatables/RefreshToolbarButton';
import { ScheduleToolbarButton } from 'src/components/mui-datatables/ScheduleToolbarButton';
import { SendEmailToolbarButton } from 'src/components/mui-datatables/SendEmailToolbarButton';
import { SendNotificationToolbarButton } from 'src/components/mui-datatables/SendNotificationToolbarButton';
import { SendSmsToolbarButton } from 'src/components/mui-datatables/SendSmsToolbarButton';
import { UploadCsvToolbarButton } from 'src/components/mui-datatables/UploadCsvToolbarButton';
import { SecuredContent } from 'src/components/SecuredContent';
import { Table } from 'src/components/Table';
import { history } from 'src/config/history';
import { App } from 'src/constants/App';
import { PaymentStatuses } from 'src/constants/PaymentStatus';
import { RolePermissions } from 'src/constants/RolePermission';
import { RoutePaths } from 'src/constants/RoutePath';
import { translate } from 'src/i18n/translate';
import { ImportCustomersDialog } from 'src/scenes/letseatmanager/marketing/ImportCustomersDialog';
import { ScheduleNotificationToCustomerDialog } from 'src/scenes/letseatmanager/marketing/ScheduleNotificationToCustomerDialog';
import { SendEmailToCustomersDialog } from 'src/scenes/letseatmanager/marketing/SendEmailToCustomersDialog';
import { SendNotificationToCustomersDialog } from 'src/scenes/letseatmanager/marketing/SendNotificationToCustomersDialog';
import { SendSmsToCustomersDialog } from 'src/scenes/letseatmanager/marketing/SendSmsToCustomersDialog';
import { useFormatAsRestaurantCurrencyNumber } from 'src/services/restaurant/useFormatAsRestaurantCurrencyNumber';
import { appColors } from 'src/theme/AppColors';
import { CustomerId, RestaurantCustomerId } from 'src/types/Id';
import { alertKnownErrorOrSomethingWentWrong } from 'src/utils/alert/alertKnownErrorOrSomethingWentWrong';
import { getClientFrequency } from 'src/utils/customer/getClientFrequency';
import { formatDateTimeString } from 'src/utils/date/formatDateTimeString';
import { debounce } from 'src/utils/function/debounce';
import { getSelectedRowsData } from 'src/utils/mui-datatables/getSelectedRowsData';
import { useSelector } from 'src/utils/react/useSelector';
import { removeNulls } from 'src/utils/removeNulls';

export function CustomersTable(): React.ReactElement {
    const classes = useStyles();
    const confirmDialog = useConfirmDialog();
    const formatAsCurrencyNumber = useFormatAsRestaurantCurrencyNumber();

    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(50);
    const [searchText, setSearchText] = useState('');
    const [totalItems, setTotalItems] = useState(0);
    const [loading, setLoading] = useState(false);
    const [restaurantCustomers, setRestaurantCustomers] = useState<Array<RestaurantCustomerVm>>([]);
    const [brandCustomers, setBrandCustomers] = useState<Array<BrandCustomerVm>>([]);
    const [sendNotificationDialogState, setSendNotificationDialogState] = useState<{ open: boolean; customerIds: Array<CustomerId> | undefined }>({ open: false, customerIds: undefined });
    const [addCreditsDialogState, setAddCreditsDialogState] = useState<{ open: boolean; customerIds: Array<CustomerId> | undefined }>({ open: false, customerIds: undefined });
    const [sendEmailDialogState, setSendEmailDialogState] = useState<{ open: boolean; customerIds: Array<CustomerId> | undefined }>({ open: false, customerIds: undefined });
    const [sendSmsDialogState, setSendSmsDialogState] = useState<{
        open: boolean;
        restaurantCustomerMobileNumbers: Array<RestaurantCustomerMobileNumber> | undefined;
    }>({ open: false, restaurantCustomerMobileNumbers: undefined });
    const [scheduleNotificationToCustomerDialogState, setScheduleNotificationToCustomerDialogState] = useState<{
        open: boolean;
        restaurantCustomerMobileNumbers: Array<RestaurantCustomerMobileNumber> | undefined;
        customerIds: Array<CustomerId> | undefined;
    }>({ open: false, restaurantCustomerMobileNumbers: undefined, customerIds: undefined });
    const [importCustomersDialogState, setImportCustomersDialogState] = useState({ open: false });

    const restaurantId = useSelector((state) => state.app.restaurantId);
    const internalUser = useSelector((state) => state.authentication.internalUser);
    const restaurantIds = useSelector((state) => state.app.restaurantIds);
    const brandOpened = useSelector((state) => state.app.brandOpened);
    const marketingActionsForRestaurantEnabled = useSelector((state) => state.app.restaurant.marketingActionsForRestaurantEnabled);

    useEffect(() => {
        if (!brandOpened || !page) return;
        load();
    }, [restaurantId, brandOpened, restaurantIds, page]);

    useEffect(() => {
        if (page && !searchText) return;
        changeRowsPerPage();
        load();
    }, [rowsPerPage, searchText]);

    const load = () => {
        if (brandOpened) loadBrandCustomers();
        else loadRestaurantCustomers();
    };

    const loadRestaurantCustomers = async () => {
        setLoading(true);
        const response = await findRestaurantCustomersApi({
            restaurantId,
        });
        if (!response.ok) {
            setLoading(false);
            alertKnownErrorOrSomethingWentWrong(response);
            return;
        }
        setLoading(false);
        setRestaurantCustomers(response.data);
        setTotalItems(0);
        setBrandCustomers([]);
        return;
    };

    const loadBrandCustomers = async () => {
        setLoading(true);
        const response = await findBrandCustomersApi({
            restaurantIds,
            page,
            rowsPerPage,
            searchText,
        });
        if (!response.ok) {
            setLoading(false);
            alertKnownErrorOrSomethingWentWrong(response);
            return;
        }
        setLoading(false);
        setBrandCustomers(response.data?.brandCustomers);
        setTotalItems(response.data?.total ?? 0);
        setRestaurantCustomers([]);
        return;
    };

    const changePage = (page: number) => {
        setPage(page);
    };

    const changeRowsPerPage = (rowsPerPage?: number) => {
        if (rowsPerPage) setRowsPerPage(rowsPerPage);
        changePage(0);
    };

    const debouncedSearch = useRef(
        debounce((searchText: string) => {
            setSearchText(searchText);
        }, 700),
    );

    const closeSendNotificationDialog = () => setSendNotificationDialogState({ open: false, customerIds: undefined });

    const closeAddCreditsDialog = () => setAddCreditsDialogState({ open: false, customerIds: undefined });

    const closeSendEmailDialog = () => setSendEmailDialogState({ open: false, customerIds: undefined });

    const closeSendSmsDialog = () => setSendSmsDialogState({ open: false, restaurantCustomerMobileNumbers: undefined });

    const closeScheduleNotificationDialog = () => setScheduleNotificationToCustomerDialogState({ open: false, restaurantCustomerMobileNumbers: undefined, customerIds: undefined });

    const closeImportCustomersDialog = () => setImportCustomersDialogState({ open: false });

    const handleClickSendPushNotification = (selectedCustomerIds: any) => {
        setSendNotificationDialogState({ open: true, customerIds: selectedCustomerIds });
    };

    const handleClickSendEmail = (selectedCustomerIds: any) => {
        setSendEmailDialogState({ open: true, customerIds: selectedCustomerIds });
    };

    const handleClickScheduleNotification = (selectedRestaurantCustomerMobileNumbers: Array<RestaurantCustomerMobileNumber>, selectedCustomerIds: Array<CustomerId>) => {
        setScheduleNotificationToCustomerDialogState({ open: true, restaurantCustomerMobileNumbers: selectedRestaurantCustomerMobileNumbers, customerIds: selectedCustomerIds });
    };

    const scheduleNotificationOnSuccess = () => {
        closeScheduleNotificationDialog();
        load();
    };

    const openSendSmsToCustomersDialog = async (selectedRestaurantCustomerMobileNumbers: Array<RestaurantCustomerMobileNumber>) => {
        if (selectedRestaurantCustomerMobileNumbers.length > SmsQuantityLimit && internalUser) {
            const smsCost = BigNumber(selectedRestaurantCustomerMobileNumbers.length).multipliedBy(0.1).toString();
            const shouldSendSms = await confirmDialog({
                title: translate('Send @smsNumber SMS will cost @smsCost', { smsNumber: selectedRestaurantCustomerMobileNumbers.length, smsCost: formatAsCurrencyNumber(smsCost) }),
                content: translate('Do you want to continue?'),
                acceptButtonText: translate('Yes, continue'),
                cancelButtonText: translate('No, cancel'),
                variant: 'warning',
            });
            if (!shouldSendSms) return;
        }
        setSendSmsDialogState({ open: true, restaurantCustomerMobileNumbers: selectedRestaurantCustomerMobileNumbers });
    };

    const createTableData = () => {
        const customers = brandOpened ? brandCustomers : restaurantCustomers;

        return customers?.map((customer) => {
            const clientFrequency =
                customer.clientFrequency ??
                getClientFrequency({
                    orders: customer.orders ?? 0,
                    lastOrder: customer.lastOrder,
                    secondLastOrder: customer.secondLastOrder,
                }) ??
                '';

            const data = removeNulls({
                customerId: customer.customerId,
                name: customer.name,
                mobileNumber: customer.mobileNumber,
                email: customer.email,
                amount: formatAsCurrencyNumber(customer.amount),
                cashback: formatAsCurrencyNumber(customer.cashback),
                apps: customer.apps,
                orders: customer.orders,
                lastOrder: customer.lastOrder && formatDateTimeString(moment(customer.lastOrder).toString()),
                clientFrequency: clientFrequency ? translate(`ClientFrequency.${clientFrequency}`) : '',
                orderedIn: undefined,
                brandCustomerId: undefined,
                restaurantCustomerId: undefined as RestaurantCustomerId | undefined,
            });

            if ((customer as any).orderedIn) data.orderedIn = (customer as any).orderedIn;
            if ((customer as any).brandCustomerId) data.brandCustomerId = (customer as any).brandCustomerId;
            if ((customer as any).restaurantCustomerId) data.restaurantCustomerId = (customer as any).restaurantCustomerId;

            return data;
        });
    };

    const tableColumns: Array<MUIDataTableColumn> = [
        {
            name: 'customerId',
            label: '',
            options: {
                display: 'excluded',
                filter: false,
            },
        },
        {
            name: 'restaurantCustomerId',
            label: '',
            options: {
                display: 'excluded',
                filter: false,
            },
        },
        {
            name: 'brandCustomerId',
            label: '',
            options: {
                display: 'excluded',
                filter: false,
            },
        },
        {
            name: 'name',
            label: translate('Name'),
            options: {
                filter: false,
            },
        },
        {
            name: 'mobileNumber',
            label: translate('Mobile Number'),
            options: {
                filter: false,
            },
        },
        {
            name: 'email',
            label: translate('Email'),
            options: {
                filter: false,
            },
        },
        {
            name: 'amount',
            label: translate('Total'),
            options: {
                filter: false,
                customBodyRender: (status: string) => <div style={{ color: status === PaymentStatuses.PAYED ? appColors.text.success : '' }}>{status}</div>,
            },
        },
        {
            name: 'cashback',
            label: translate('Cashback'),
            options: {
                filter: false,
            },
        },
        {
            name: 'apps',
            label: translate('App'),
            options: {
                filter: false,
                customBodyRender: (apps: Array<App>) => <div style={{ display: 'flex', flexDirection: 'column' }}>{apps?.map((app: any) => <span key={app}>{translate(app)}</span>)}</div>,
            },
        },
        {
            name: 'orders',
            label: translate('Orders'),
            options: {
                filter: false,
            },
        },
        {
            name: 'lastOrder',
            label: translate('Last order'),
            options: {
                filter: false,
            },
        },
        {
            name: 'clientFrequency',
            label: translate('Client Frequency'),
            options: {
                filter: false,
            },
        },
    ];

    if (brandOpened) {
        tableColumns.push({
            name: 'orderedIn',
            label: translate('Ordered In'),
            options: {
                filter: false,
                customBodyRender: (orderedIn: Array<string>) => (
                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                        {orderedIn.map((restaurant) => (
                            <span key={restaurant}>{restaurant}</span>
                        ))}
                    </div>
                ),
            },
        });
    }

    return (
        <>
            <SendNotificationToCustomersDialog open={sendNotificationDialogState.open} customerIds={sendNotificationDialogState.customerIds} onClose={closeSendNotificationDialog} />
            <SendEmailToCustomersDialog open={sendEmailDialogState.open} customerIds={sendEmailDialogState.customerIds} onClose={closeSendEmailDialog} />
            <SendSmsToCustomersDialog open={sendSmsDialogState.open} restaurantCustomerMobileNumbers={sendSmsDialogState.restaurantCustomerMobileNumbers} onClose={closeSendSmsDialog} />
            <AddCreditsToCustomersDialog open={addCreditsDialogState.open} customerIds={addCreditsDialogState.customerIds} onClose={closeAddCreditsDialog} />
            <ScheduleNotificationToCustomerDialog
                open={scheduleNotificationToCustomerDialogState.open}
                restaurantCustomerMobileNumbers={scheduleNotificationToCustomerDialogState.restaurantCustomerMobileNumbers}
                customerIds={scheduleNotificationToCustomerDialogState.customerIds}
                onClose={closeScheduleNotificationDialog}
                onSuccess={scheduleNotificationOnSuccess}
            />
            <ImportCustomersDialog open={importCustomersDialogState.open} onClose={closeImportCustomersDialog} onSuccess={load} />
            <h2 className={classes.title}>{translate('Customers')}</h2>
            <Table
                className={classes.table}
                data={createTableData()}
                columns={tableColumns}
                loading={loading}
                options={{
                    responsive: 'standard',
                    tableBodyMaxHeight: '500px',
                    selectableRows: marketingActionsForRestaurantEnabled ? 'multiple' : 'none',
                    filterType: 'checkbox',
                    rowsPerPageOptions: [10, 20, 50, 100],
                    rowsPerPage: rowsPerPage || 100,
                    count: totalItems || restaurantCustomers.length,
                    serverSide: brandOpened,
                    page: page,
                    onSearchChange: debouncedSearch.current,
                    onTableChange: (action, tableState) => {
                        switch (action) {
                            case 'changePage':
                                changePage(tableState.page);
                                break;
                            case 'changeRowsPerPage':
                                changeRowsPerPage(tableState.rowsPerPage);
                                break;
                            default:
                        }
                    },
                    customToolbar: () => {
                        return (
                            <>
                                <SecuredContent rolePermission={RolePermissions.UPLOAD_MARKETING_DATA}>
                                    <UploadCsvToolbarButton onClick={() => setImportCustomersDialogState({ open: true })} />
                                </SecuredContent>
                                <RefreshToolbarButton onClick={load} />
                            </>
                        );
                    },
                    onRowClick: (rowData, rowMeta) => {
                        const mobileNumber = brandOpened ? brandCustomers[rowMeta.dataIndex].mobileNumber : restaurantCustomers[rowMeta.dataIndex].mobileNumber;

                        if (!mobileNumber) return;

                        history.push({
                            pathname: RoutePaths.CUSTOMERS.replace(':mobileNumber', mobileNumber),
                            search: history.location.search,
                        });
                    },
                    customToolbarSelect: (selectedRows, displayData) => {
                        const selectedRowsData = getSelectedRowsData(selectedRows, displayData);
                        const selectedCustomerIds = selectedRowsData.map((selectedRowData: Array<CustomerId>) => selectedRowData[0]);
                        const selectedRestaurantCustomerIds: Array<RestaurantCustomerId> = selectedRowsData.map((selectedRowData: Array<RestaurantCustomerId>) => selectedRowData[1]);
                        const selectedMobileNumbers = selectedRowsData.map((selectedRowData: Array<string>) => selectedRowData[4]);

                        const selectedRestaurantCustomerMobileNumbers: Array<RestaurantCustomerMobileNumber> = selectedRestaurantCustomerIds.map((restaurantCustomerId, idx) => ({
                            restaurantCustomerId,
                            mobileNumber: selectedMobileNumbers[idx],
                        }));

                        return (
                            <div className={classes.toolbar}>
                                <ScheduleToolbarButton onClick={() => handleClickScheduleNotification(selectedRestaurantCustomerMobileNumbers, selectedCustomerIds)} />
                                <SendNotificationToolbarButton onClick={() => handleClickSendPushNotification(selectedCustomerIds)} />
                                <SendEmailToolbarButton onClick={() => handleClickSendEmail(selectedCustomerIds)} />
                                <SendSmsToolbarButton onClick={() => openSendSmsToCustomersDialog(selectedRestaurantCustomerMobileNumbers)} />
                            </div>
                        );
                    },
                    // filterList: [[], [], [], [], ['MEXICO']],
                }}
            />
        </>
    );
}

const SmsQuantityLimit = 500;

const useStyles = makeStyles((theme) => ({
    table: {
        whiteSpace: 'nowrap',
        '& tr': { cursor: 'pointer' },
        '& tr:hover': { backgroundColor: 'rgba(0, 0, 0, 0.07) !important' },
    },
    toolbar: {
        padding: '8px 6px',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
    },
    title: {
        fontFamily: theme.typography.medium,
        fontSize: 25,
    },
    text: {
        fontFamily: theme.typography.medium,
        fontSize: 15,
        marginRight: 10,
    },
    containerImportingLoading: {
        display: 'flex',
        flexDirection: 'row',
        width: '100%',
        justifyContent: 'center',
    },
}));

type RestaurantCustomerMobileNumber = {
    restaurantCustomerId: RestaurantCustomerId;
    mobileNumber: string;
};
