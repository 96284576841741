import { formatAsCurrencyNumber } from '@pidedirecto/ui/utils';
import { translate } from 'src/i18n/translate';
import { printerInstructionsBuilder } from 'src/services/printer/prints/utils/printerInstructionsBuilder';
import type { PrinterInstruction } from 'src/services/printer/types/PrinterInstruction';
import { ManagerUserTipSalesReportVm } from 'src/types/ManagerUserTipSalesReportVm';
import type { RestaurantVm } from 'src/types/RestaurantVm';
import { formatDateTimeString } from 'src/utils/date/formatDateTimeString';
import { upperCase } from 'src/utils/string/upperCase';

export function createManagerUserTipSalesReportPrint({ managerUserTipSalesReport, restaurant }: Params): Array<PrinterInstruction> {
    const builder = printerInstructionsBuilder();

    addTipSalesReportGeneralInfo();
    addTipSalesReportSummaryInfo();
    addTipSalesReportByPaymentMethod();
    addTipSalesReportByOrder();
    addSignatureInfo();

    return builder.build();

    function addTipSalesReportGeneralInfo(): void {
        builder.addCenteredBoldText(upperCase(translate('Tips by waiter')));
        builder.addCenteredText(restaurant.name);
        builder.addSeparatedTexts(translate('Waiter'), managerUserTipSalesReport.managerUserName);
    }

    function addTipSalesReportSummaryInfo(): void {
        builder.addCenteredBoldText(upperCase(translate('Total Sales')));

        builder.addSeparatedTexts(translate('Period'), `${managerUserTipSalesReport.managerUserTipSalesSummary?.fromDate} - ${managerUserTipSalesReport.managerUserTipSalesSummary?.toDate}`);
        builder.addSeparatedTexts(translate('Total'), formatAsCurrencyNumber(managerUserTipSalesReport.managerUserTipSalesSummary?.totalAmount, { country: restaurant.country as any }));
        builder.addSeparatedTexts(translate('Orders'), managerUserTipSalesReport.managerUserTipSalesSummary.totalOrders.toString());
        builder.addSeparatedTexts(translate('Tip'), formatAsCurrencyNumber(managerUserTipSalesReport.managerUserTipSalesSummary.totalTip, { country: restaurant.country as any }));
    }

    function addTipSalesReportByPaymentMethod(): void {
        builder.addCenteredBoldText(upperCase(translate('By Payment Method')));

        managerUserTipSalesReport.managerUserTipSalesByPaymentMethod?.forEach((tipSalesByPaymentMethod) => {
            builder.addSeparatedTexts(translate(tipSalesByPaymentMethod.paymentMethod), formatAsCurrencyNumber(tipSalesByPaymentMethod.total, { country: restaurant.country as any }));
            builder.addSeparatedTexts(translate('Orders'), tipSalesByPaymentMethod.orders.toString());
            builder.addSeparatedTexts(translate('Tip'), formatAsCurrencyNumber(tipSalesByPaymentMethod.posTipAmount, { country: restaurant.country as any }));
            builder.addLineSeparator();
        });
    }

    function addTipSalesReportByOrder(): void {
        builder.addCenteredBoldText(upperCase(translate('Summary of tips')));

        addTipSalesByOrderHeaderInfo({
            orderId: translate('Order'),
            amount: translate('Sale'),
            tip: translate('Tip'),
        });

        managerUserTipSalesReport.managerUserTipSalesByOrder?.forEach((tipSalesByOrder) => {
            addCashRegisterReportInfo({
                orderId: tipSalesByOrder.orderId,
                amount: formatAsCurrencyNumber(tipSalesByOrder.total, { country: restaurant.country as any }),
                tip: formatAsCurrencyNumber(tipSalesByOrder.posTipAmount, { country: restaurant.country as any }),
            });
        });

        addTipSalesByOrderHeaderInfo({
            orderId: translate('Total'),
            amount: formatAsCurrencyNumber(managerUserTipSalesReport.managerUserTipSalesSummary?.totalAmount, { country: restaurant.country as any }),
            tip: formatAsCurrencyNumber(managerUserTipSalesReport.managerUserTipSalesSummary.totalTip, { country: restaurant.country as any }),
        });
    }

    function addSignatureInfo(): void {
        builder.addNewLine();
        builder.addNewLine();
        builder.addCenteredUnderLine();
        builder.addCenteredText(managerUserTipSalesReport.managerUserName ?? translate('Signature'));
        builder.addNewLine();
        builder.addNewLine();
        builder.addSeparatedTexts(translate('Print date'), formatDateTimeString(new Date(), restaurant.timeZone));
    }

    function addTipSalesByOrderHeaderInfo(tipSalesByOrderRow: TipSalesByOrderRow): void {
        builder.addBoldColumns([
            {
                percentageWidth: 0.33,
                text: tipSalesByOrderRow.orderId,
                textAlign: 'left',
            },
            {
                percentageWidth: 0.34,
                text: tipSalesByOrderRow.amount,
                textAlign: 'right',
            },
            {
                percentageWidth: 0.33,
                text: tipSalesByOrderRow.tip,
                textAlign: 'right',
            },
        ]);
    }

    function addCashRegisterReportInfo(tipSalesByOrderRow: TipSalesByOrderRow): void {
        builder.addColumns([
            {
                percentageWidth: 0.33,
                text: tipSalesByOrderRow.orderId,
                textAlign: 'left',
            },
            {
                percentageWidth: 0.34,
                text: tipSalesByOrderRow.amount,
                textAlign: 'right',
            },
            {
                percentageWidth: 0.33,
                text: tipSalesByOrderRow.tip,
                textAlign: 'right',
            },
        ]);
    }
}

type Params = {
    managerUserTipSalesReport: ManagerUserTipSalesReportVm;
    restaurant: RestaurantVm;
};

type TipSalesByOrderRow = {
    orderId: string;
    amount: string;
    tip: string;
};
