import * as React from 'react';

export function GearIcon({ color, title, className }: Props): React.ReactElement {
    return (
        <svg className={className} width='26' height='20' viewBox='0 0 26 20' fill='none' xmlns='http://www.w3.org/2000/svg'>
            {title && <title>{title}</title>}
            <path
                d='M7.59372 2.44C7.68372 1.898 8.15372 1.5 8.70372 1.5H11.2967C11.8467 1.5 12.3167 1.898 12.4067 2.44L12.6197 3.721C12.6827 4.095 12.9327 4.407 13.2647 4.591C13.3387 4.631 13.4117 4.674 13.4847 4.718C13.8087 4.914 14.2047 4.975 14.5597 4.842L15.7767 4.386C16.0261 4.29221 16.3007 4.28998 16.5516 4.37971C16.8025 4.46945 17.0134 4.64531 17.1467 4.876L18.4427 7.123C18.5758 7.3537 18.6227 7.62413 18.5751 7.88617C18.5275 8.1482 18.3884 8.38485 18.1827 8.554L17.1797 9.381C16.8867 9.621 16.7417 9.994 16.7487 10.373C16.7503 10.458 16.7503 10.543 16.7487 10.628C16.7417 11.006 16.8867 11.378 17.1787 11.618L18.1837 12.446C18.6077 12.796 18.7177 13.4 18.4437 13.876L17.1457 16.123C17.0126 16.3536 16.8019 16.5296 16.5512 16.6195C16.3006 16.7094 16.0261 16.7074 15.7767 16.614L14.5597 16.158C14.2047 16.025 13.8097 16.086 13.4837 16.282C13.4112 16.3261 13.3379 16.3688 13.2637 16.41C12.9327 16.593 12.6827 16.905 12.6197 17.279L12.4067 18.559C12.3167 19.102 11.8467 19.5 11.2967 19.5H8.70272C8.15272 19.5 7.68272 19.102 7.59272 18.56L7.37972 17.279C7.31772 16.905 7.06772 16.593 6.73572 16.409C6.66157 16.3681 6.58822 16.3258 6.51572 16.282C6.19072 16.086 5.79572 16.025 5.43972 16.158L4.22272 16.614C3.97345 16.7075 3.69908 16.7096 3.44842 16.6199C3.19775 16.5302 2.98703 16.3545 2.85372 16.124L1.55672 13.877C1.42366 13.6463 1.37676 13.3759 1.42437 13.1138C1.47198 12.8518 1.61101 12.6152 1.81672 12.446L2.82072 11.619C3.11272 11.379 3.25772 11.006 3.25072 10.627C3.24915 10.542 3.24915 10.457 3.25072 10.372C3.25772 9.994 3.11272 9.622 2.82072 9.382L1.81672 8.554C1.61126 8.38489 1.47239 8.14843 1.42479 7.88662C1.37719 7.62481 1.42393 7.3546 1.55672 7.124L2.85372 4.877C2.9869 4.64614 3.19773 4.47006 3.44863 4.38014C3.69953 4.29021 3.97421 4.29229 4.22372 4.386L5.43972 4.842C5.79572 4.975 6.19072 4.914 6.51572 4.718C6.58772 4.674 6.66172 4.631 6.73572 4.59C7.06772 4.407 7.31772 4.095 7.37972 3.721L7.59372 2.44Z'
                stroke={color || 'currentColor'}
                strokeWidth='1.5'
            />
            <path
                d='M12.9997 10.5C12.9997 11.2956 12.6837 12.0587 12.121 12.6213C11.5584 13.1839 10.7954 13.5 9.99973 13.5C9.20408 13.5 8.44101 13.1839 7.8784 12.6213C7.3158 12.0587 6.99973 11.2956 6.99973 10.5C6.99973 9.70435 7.3158 8.94129 7.8784 8.37868C8.44101 7.81607 9.20408 7.5 9.99973 7.5C10.7954 7.5 11.5584 7.81607 12.121 8.37868C12.6837 8.94129 12.9997 9.70435 12.9997 10.5Z'
                stroke={color || 'currentColor'}
                strokeWidth='1.5'
            />
        </svg>
    );
}

type Props = {
    color?: string;
    title?: string;
    className?: string;
};
