import { makeStyles } from '@material-ui/core';
import * as React from 'react';
import { translate } from 'src/i18n/translate';
import { ClutteryIcon } from 'src/icons/ClutteryIcon';
import { CreateMenuSection } from 'src/scenes/letseatmanager/menu/menu/CreateMenuSection';
import { useLoadMenus } from 'src/services/menu/useLoadMenus';

export function MenusEmptyState(): React.ReactElement {
    const classes = useStyles();

    const refreshMenus = useLoadMenus();

    return (
        <div className={classes.container}>
            <div className={classes.emptyStateContainer}>
                <div className={classes.iconContainer}>
                    <ClutteryIcon />
                </div>
                <h2 className={classes.subtitle}>{translate('Welcome to AmbitOne!')}</h2>
                <p className={classes.text}>{translate('On this section you will be able to create and manage your menus. Do we start?')}</p>
            </div>
            <CreateMenuSection onSuccess={refreshMenus} />
        </div>
    );
}

const useStyles = makeStyles((theme) => ({
    container: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-around',
        width: '100%',
        padding: '20px 0',
    },
    emptyStateContainer: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        width: '45%',
    },
    iconContainer: {
        width: 120,
        height: 120,
        borderRadius: 120,
        backgroundColor: `${theme.palette.surface.brand}63`,
        color: theme.palette.text.brand,
        marginBottom: 50,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    subtitle: {
        fontFamily: theme.typography.medium,
        fontSize: 15,
        width: '50%',
        textAlign: 'center',
        margin: 0,
        [theme.breakpoints.down('sm')]: {
            width: '90%',
        },
    },
    text: {
        fontFamily: theme.typography.regular,
        fontSize: 15,
        width: '50%',
        textAlign: 'center',
        margin: '20px 0',
        [theme.breakpoints.down('sm')]: {
            width: '90%',
        },
    },
}));
