import { makeStyles } from '@material-ui/core';
import FormHelperText from '@material-ui/core/FormHelperText';
import Grid from '@material-ui/core/Grid';
import { Card } from '@pidedirecto/ui';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { useForm, useWatch } from 'react-hook-form';
import { checkIfRappiCargoIsAvailableApi } from 'src/api/letseatadmin/settings/checkIfRappiCargoIsAvailableApi';
import { changeTheRestaurantApi } from 'src/api/letseatadmin/theRestaurant/changeTheRestaurantApi';
import { Button } from 'src/components/Button';
import { DeprecatedFormPercentNumberField } from 'src/components/form/DeprecatedFormPercentNumberField';
import { Form } from 'src/components/form/Form';
import { FormCurrencyNumberField } from 'src/components/form/FormCurrencyNumberField';
import { FormCurrencyNumberStringField } from 'src/components/form/FormCurrencyNumberStringField';
import { FormDeliveryCategoriesAutocomplete } from 'src/components/form/FormDeliveryCategoriesAutocomplete';
import { FormDeliveryManagerTypeSelect } from 'src/components/form/FormDeliveryManagerTypeSelect';
import { FormFixErrorHint } from 'src/components/form/FormFixErrorHint';
import { FormNumberField } from 'src/components/form/FormNumberField';
import { FormSubmitButton } from 'src/components/form/FormSubmitButton';
import { FormSwitch } from 'src/components/form/FormSwitch';
import { FormTextField } from 'src/components/form/FormTextField';
import { getDirtyFormValues } from 'src/components/form/utils/getDirtyFormValues';
import { UpdatingContentProgress } from 'src/components/UpdatingContentProgress';
import { DeliveryProvider, DeliveryProviders } from 'src/constants/DeliveryProviders';
import { translate } from 'src/i18n/translate';
import { appReducer } from 'src/reducers/appReducer';
import { DeliveryManagerCyclicalSettings } from 'src/scenes/letseatadmin/theRestaurant/restaurantDeliverySettings/DeliveryManagerCyclicalSettings';
import { DeliveryManagerLinealSettings } from 'src/scenes/letseatadmin/theRestaurant/restaurantDeliverySettings/DeliveryManagerLinealSettings';
import { DeliveryManagerOrchestratorSettings } from 'src/scenes/letseatadmin/theRestaurant/restaurantDeliverySettings/DeliveryManagerOrchestratorSettings';
import { useReloadRestaurant } from 'src/services/restaurant/useReloadRestaurant';
import { alertKnownErrorOrSomethingWentWrong } from 'src/utils/alert/alertKnownErrorOrSomethingWentWrong';
import { isDeliveryManagerCyclical } from 'src/utils/deliveryManager/isDeliveryManagerCyclical';
import { isDeliveryManagerLineal } from 'src/utils/deliveryManager/isDeliveryManagerLineal';
import { isDeliveryManagerOrchestrator } from 'src/utils/deliveryManager/isDeliveryManagerOrchestrator';
import { deepEqual } from 'src/utils/object/deepEqual';
import { useAction } from 'src/utils/react/useAction';
import { useLoadApi } from 'src/utils/react/useLoadApi';
import { useSelector } from 'src/utils/react/useSelector';

export function RestaurantDeliverySettings({ loading, restaurant, onChangeRestaurant, setIsDirtyForm }: Props): React.ReactElement {
    const classes = useStyles();
    const form = useForm();
    const {
        formState: { dirtyFields },
        watch,
        control,
        formState,
        setValue,
    } = form;

    const manualOrdersEnabled = useWatch<boolean>({ name: 'manualOrdersEnabled', control });
    const externalCardPaymentsForManualOrdersEnabled = useWatch<boolean>({ name: 'externalCardPaymentsForManualOrdersEnabled', control });
    const defaultDeliveryRadiusInKm = watch('defaultDeliveryRadiusInKm');
    const defaultCustomerDeliveryCostFixed = watch('defaultCustomerDeliveryCostFixed');
    const defaultCustomerDeliveryCostPerKm = watch('defaultCustomerDeliveryCostPerKm');
    const defaultDynamicEarningsMinimumMinutes = watch('defaultDynamicEarningsMinimumMinutes');
    const defaultDynamicEarningsAmountToIncrementByMinute = watch('defaultDynamicEarningsAmountToIncrementByMinute');
    const defaultDynamicEarningsRoof = watch('defaultDynamicEarningsRoof');
    const defaultLongDistanceExtraDeliveryCostStartsFromKm = watch('defaultLongDistanceExtraDeliveryCostStartsFromKm');
    const defaultLongDistanceExtraDeliveryCostFixed = watch('defaultLongDistanceExtraDeliveryCostFixed');
    const defaultLongDistanceExtraDeliveryCostPerKm = watch('defaultLongDistanceExtraDeliveryCostPerKm');
    const uberEatsDeliveryEnabled = watch('uberEatsDeliveryEnabled');
    const rappiCargoDeliveryEnabled = watch('rappiCargoDeliveryEnabled');
    const pideDirectoDeliveryEnabled = watch('pideDirectoDeliveryEnabled');
    const uberDaasDeliveryEnabled = watch('uberDaasDeliveryEnabled');
    const externalDelivery = useWatch<boolean>({ name: 'externalDelivery', control });
    const externalOwnFleetEnabled = useWatch<boolean>({ name: 'externalOwnFleetEnabled', control });
    const deliveryPaidByAndInvoicedToRestaurant = watch('deliveryPaidByAndInvoicedToRestaurant');
    const cyclicalDeliveryManagerEnabled = useWatch<boolean>({ name: 'cyclicalDeliveryManager', control });
    const deliveryOrchestratorEnabled = useWatch<boolean>({ name: 'deliveryOrchestratorEnabled', control });
    const oneCycleSearchEnabled = useWatch<boolean>({ name: 'oneCycleSearchEnabled', control });
    const ownFleetEnabled = watch('ownFleetEnabled');
    const hideDeliveryEarningsFromDriverEnabled = useWatch<boolean>({ name: 'hideDeliveryEarningsFromDriverEnabled', control });
    const waitTimePerDeliveryCashProviders = watch('waitTimePerDeliveryCashProviders');
    const waitTimePerDeliveryCardProviders = watch('waitTimePerDeliveryCardProviders');
    const deliveryProvidersInfo = watch('deliveryProvidersInfo');
    const returnCancelledOrdersToRestaurantEnabled = watch('returnCancelledOrdersToRestaurantEnabled');
    const deliveryManagerType = watch('deliveryManagerType');

    const [restaurantDeliveryPaidControls, setRestaurantDeliveryPaidControls] = useState(false);
    const [cashDeliveryProvidersEnabled, setCashDeliveryProvidersEnabled] = useState<Array<DeliveryProvider>>(restaurant.cashDeliveryProviders ?? []);
    const [cardDeliveryProvidersEnabled, setCardDeliveryProvidersEnabled] = useState<Array<DeliveryProvider>>(restaurant.cardDeliveryProviders ?? []);

    useEffect(() => {
        if (externalDelivery && deliveryPaidByAndInvoicedToRestaurant) {
            setValue('deliveryPaidByAndInvoicedToRestaurant', false);
            showSnackbar({ message: 'No puedes utilizar Envíos Facturados con Entrega Externa' });
        }
    }, [externalDelivery]);

    useEffect(() => {
        if (externalDelivery && deliveryPaidByAndInvoicedToRestaurant) {
            setValue('externalDelivery', false);
            showSnackbar({ message: 'No puedes utilizar Entrega Externa con Envíos Facturados' });
        }
    }, [deliveryPaidByAndInvoicedToRestaurant]);

    const appLoading = useSelector((state) => state.app.loading);
    const restaurantId = useSelector((state) => state.app.restaurantId);
    const invoiceRestaurantDeliveryCost = useSelector((state) => state.app.restaurant?.invoiceRestaurantDeliveryCost);
    const [, isRappiCargoAvailable] = useLoadApi(checkIfRappiCargoIsAvailableApi, { restaurantId }, { dependencies: [rappiCargoDeliveryEnabled, restaurantId] });

    const showSnackbar = useAction(appReducer.actions.showSnackbar);
    const { reloadRestaurant } = useReloadRestaurant();

    useEffect(() => {
        if (!loading) formReset();
    }, [loading]);

    useEffect(() => {
        setIsDirtyForm(Object.keys(dirtyFields).length !== 0);
    }, [formState]);

    useEffect(() => {
        if (!ownFleetEnabled && hideDeliveryEarningsFromDriverEnabled) {
            setValue('hideDeliveryEarningsFromDriverEnabled', false);
        }
    }, [ownFleetEnabled]);

    useEffect(() => {
        if (!cyclicalDeliveryManagerEnabled && oneCycleSearchEnabled) {
            setValue('oneCycleSearchEnabled', false);
        }
    }, [cyclicalDeliveryManagerEnabled]);

    const formReset = () => {
        form.reset({
            restaurantId: restaurant.restaurantId ?? null,
            deliveryCategories: restaurant.deliveryCategories ?? null,
            ownFleetEnabled: restaurant.ownFleetEnabled ?? null,
            hideDeliveryEarningsFromDriverEnabled: restaurant.hideDeliveryEarningsFromDriverEnabled ?? null,
            externalOwnFleetEnabled: restaurant.externalOwnFleetEnabled ?? null,
            requestDeliveryToPideDirectoDriversForOwnFleetEnabled: restaurant.requestDeliveryToPideDirectoDriversForOwnFleetEnabled ?? null,
            showOrderItemsToDriverEnabled: restaurant.showOrderItemsToDriverEnabled ?? null,
            uberEatsDeliveryEnabled: restaurant.uberEatsDeliveryEnabled ?? null,
            autoOpenClosedUberEatsStoreEnabled: restaurant.autoOpenClosedUberEatsStoreEnabled ?? null,
            rappiCargoDeliveryEnabled: restaurant.rappiCargoDeliveryEnabled ?? null,
            rappiCargoOrdersWithCreditCardEnabled: restaurant.rappiCargoOrdersWithCreditCardEnabled ?? null,
            deliveryManagerMaxRuntime: restaurant.deliveryManagerMaxRuntime ?? null,
            externalDelivery: restaurant.externalDelivery ?? null,
            customerPhoneNumberRequiredForExternalDelivery: restaurant.customerPhoneNumberRequiredForExternalDelivery ?? null,
            cyclicalDeliveryManager: restaurant.cyclicalDeliveryManager,
            deliveryOrchestratorEnabled: restaurant.deliveryOrchestratorEnabled,
            oneCycleSearchEnabled: restaurant.oneCycleSearchEnabled,
            waitTimePerDeliveryProvider: restaurant.waitTimePerDeliveryProvider,
            externalDeliveryEstimatedTime: restaurant.externalDeliveryEstimatedTime ?? '45-60',
            useNonGeoLocatedDeliveryAddressesEnabled: restaurant.useNonGeoLocatedDeliveryAddressesEnabled ?? null,
            deliverySecurityCodeEnabled: restaurant.deliverySecurityCodeEnabled ?? null,
            manualOrdersEnabled: restaurant.manualOrdersEnabled ?? null,
            externalCardPaymentsForManualOrdersEnabled: restaurant.externalCardPaymentsForManualOrdersEnabled ?? null,
            externalCardPaymentsForManualOrdersAsDefaultEnabled: restaurant.externalCardPaymentsForManualOrdersAsDefaultEnabled ?? null,
            deliveryRadiusInKm: restaurant.deliveryRadiusInKm ?? null,
            customerDeliveryCostFixed: restaurant.customerDeliveryCostFixed ?? null,
            customerDeliveryCostPerKm: restaurant.customerDeliveryCostPerKm ?? null,
            dynamicEarningsMinimumMinutes: restaurant.dynamicEarningsMinimumMinutes ?? null,
            dynamicEarningsAmountToIncrementByMinute: restaurant.dynamicEarningsAmountToIncrementByMinute ?? null,
            dynamicEarningsRoof: restaurant.dynamicEarningsRoof ?? null,
            longDistanceExtraDeliveryCostStartsFromKm: restaurant.longDistanceExtraDeliveryCostStartsFromKm ?? null,
            longDistanceExtraDeliveryCostFixed: restaurant.longDistanceExtraDeliveryCostFixed ?? null,
            longDistanceExtraDeliveryCostPerKm: restaurant.longDistanceExtraDeliveryCostPerKm ?? null,
            deliveryCommissionRange1: restaurant.deliveryCommissionRange1 ?? null,
            deliveryCommissionRange2: restaurant.deliveryCommissionRange2 ?? null,
            rappiCargoDefaultTip: restaurant.rappiCargoDefaultTip ?? null,
            deliveryPaidByAndInvoicedToRestaurant: restaurant.deliveryPaidByAndInvoicedToRestaurant ?? null,
            invoiceOnlyDeliveryCostEnabled: restaurant.invoiceOnlyDeliveryCostEnabled ?? null,
            deliveryZonesEnabled: restaurant.deliveryZonesEnabled ?? null,
            defaultPreparationTimeInMinutes: restaurant.defaultPreparationTimeInMinutes ?? null,
            defaultDeliveryRadiusInKm: restaurant.defaultDeliveryRadiusInKm ?? null,
            defaultCustomerDeliveryCostFixed: restaurant.defaultCustomerDeliveryCostFixed ?? null,
            defaultCustomerDeliveryCostPerKm: restaurant.defaultCustomerDeliveryCostPerKm ?? null,
            defaultDynamicEarningsMinimumMinutes: restaurant.defaultDynamicEarningsMinimumMinutes ?? null,
            defaultDynamicEarningsAmountToIncrementByMinute: restaurant.defaultDynamicEarningsAmountToIncrementByMinute ?? null,
            defaultDynamicEarningsRoof: restaurant.defaultDynamicEarningsRoof ?? null,
            defaultLongDistanceExtraDeliveryCostStartsFromKm: restaurant.defaultLongDistanceExtraDeliveryCostStartsFromKm ?? null,
            defaultLongDistanceExtraDeliveryCostFixed: restaurant.defaultLongDistanceExtraDeliveryCostFixed ?? null,
            defaultLongDistanceExtraDeliveryCostPerKm: restaurant.defaultLongDistanceExtraDeliveryCostPerKm ?? null,
            driverLinkedCode: restaurant.driverLinkedCode ?? null,
            assignDriverEnabled: restaurant.assignDriverEnabled ?? null,
            pideDirectoDeliveryEnabled: restaurant.pideDirectoDeliveryEnabled ?? null,
            deliveryManagerType: restaurant.deliveryManagerType ?? null,
            uberDaasDeliveryEnabled: restaurant.uberDaasDeliveryEnabled ?? null,
            uberDaasDeliveryVerificationPictureEnabled: restaurant.uberDaasDeliveryVerificationPictureEnabled ?? null,
            uberDaasOrdersWithCashEnabled: restaurant.uberDaasOrdersWithCashEnabled ?? null,
            returnCancelledOrdersToRestaurantEnabled: restaurant.returnCancelledOrdersToRestaurantEnabled ?? null,
            sendOrderCompletedWebhookForReturnedOrders: restaurant.sendOrderCompletedWebhookForReturnedOrders ?? null,
            orderPriceLimitForManualOrder: restaurant.orderPriceLimitForManualOrder ?? null,
            pideDirectoProtectionForManualOrderAmount: restaurant.pideDirectoProtectionForManualOrderAmount ?? '0',
            waitTimePerDeliveryCashProviders: restaurant.waitTimePerDeliveryCashProviders ?? null,
            cashDeliveryProviders: restaurant.cashDeliveryProviders ?? [],
            waitTimePerDeliveryCardProviders: restaurant.waitTimePerDeliveryCardProviders ?? null,
            cardDeliveryProviders: restaurant.cardDeliveryProviders ?? [],
            requestCustomerNameOnCreateOrderEnabled: restaurant.requestCustomerNameOnCreateOrderEnabled ?? null,
            commissionUberDaasWebCard: restaurant.commissionUberDaasWebCard ?? null,
            commissionUberDaasWebCash: restaurant.commissionUberDaasWebCash ?? null,
            commissionUberDaasAppCard: restaurant.commissionUberDaasAppCard ?? null,
            commissionUberDaasAppCash: restaurant.commissionUberDaasAppCash ?? null,
            commissionUberDaasManualOrder: restaurant.commissionUberDaasManualOrder ?? null,
            commissionUberDaasPosCard: restaurant.commissionUberDaasPosCard ?? null,
            commissionUberDaasPosCash: restaurant.commissionUberDaasPosCash ?? null,
            commissionRappiCargoWebCard: restaurant.commissionRappiCargoWebCard ?? null,
            commissionRappiCargoWebCash: restaurant.commissionRappiCargoWebCash ?? null,
            commissionRappiCargoAppCard: restaurant.commissionRappiCargoAppCard ?? null,
            commissionRappiCargoAppCash: restaurant.commissionRappiCargoAppCash ?? null,
            commissionRappiCargoManualOrder: restaurant.commissionRappiCargoManualOrder ?? null,
            commissionRappiCargoPosCard: restaurant.commissionRappiCargoPosCard ?? null,
            commissionRappiCargoPosCash: restaurant.commissionRappiCargoPosCash ?? null,
            deliveryProvidersInfo: restaurant.deliveryProvidersInfo ?? null,
        });
    };

    const handleDeliveryPaidControls = () => {
        const password = prompt('Password');
        if (password !== '1212') {
            alert('Wrong Password');
        } else {
            setRestaurantDeliveryPaidControls(true);
        }
    };

    const onSubmit = async (form: any) => {
        const dirtyFormValues = getDirtyFormValues({ form, dirtyFields });
        const restaurantHasAtLeastOneDeliveryProvider =
            uberEatsDeliveryEnabled || uberDaasDeliveryEnabled || rappiCargoDeliveryEnabled || pideDirectoDeliveryEnabled || externalDelivery || cyclicalDeliveryManagerEnabled;

        if (!deepEqual(cashDeliveryProvidersEnabled, restaurant.cashDeliveryProviders ?? [])) {
            dirtyFormValues.cashDeliveryProviders = cashDeliveryProvidersEnabled;
        }

        if (!deepEqual(cardDeliveryProvidersEnabled, restaurant.cardDeliveryProviders ?? [])) {
            dirtyFormValues.cardDeliveryProviders = cardDeliveryProvidersEnabled;
        }

        if (!restaurantHasAtLeastOneDeliveryProvider) {
            alertKnownErrorOrSomethingWentWrong({}, translate('Restaurant should have at least 1 enabled delivery provider'));
            formReset();
            return;
        }

        if (ownFleetEnabled && !pideDirectoDeliveryEnabled) {
            alertKnownErrorOrSomethingWentWrong({}, translate('Pide Directo Delivery should be enabled when own fleet is enabled'));
            formReset();
            return;
        }

        const response = await changeTheRestaurantApi({
            restaurantId,
            ...dirtyFormValues,
        });
        if (!response.ok) {
            alertKnownErrorOrSomethingWentWrong(response);
            formReset();
            return;
        }

        await reloadRestaurant(restaurantId);
        await onChangeRestaurant();
        showSnackbar({ message: translate('Saved changes') });
    };

    const getAvailableDeliveryProviders = () => {
        const deliveryProviders: Array<DeliveryProvider> = [];
        if (pideDirectoDeliveryEnabled) deliveryProviders.push(DeliveryProviders.PIDEDIRECTO);
        if (uberDaasDeliveryEnabled) deliveryProviders.push(DeliveryProviders.UBER_DAAS);
        if (rappiCargoDeliveryEnabled) deliveryProviders.push(DeliveryProviders.RAPPI_CARGO);
        return deliveryProviders;
    };

    const updateCashDeliveryProviders = (deliveryProvider: DeliveryProvider) => {
        if (cashDeliveryProvidersEnabled && cashDeliveryProvidersEnabled.includes(deliveryProvider)) {
            const newCashDeliveryProviders = cashDeliveryProvidersEnabled.filter((currentDeliveryProvider: DeliveryProvider) => currentDeliveryProvider !== deliveryProvider);
            setCashDeliveryProvidersEnabled(newCashDeliveryProviders);
            return;
        }
        setCashDeliveryProvidersEnabled([...cashDeliveryProvidersEnabled, deliveryProvider]);
    };

    const updateCardDeliveryProviders = (deliveryProvider: DeliveryProvider) => {
        if (cardDeliveryProvidersEnabled && cardDeliveryProvidersEnabled.includes(deliveryProvider)) {
            const newCardDeliveryProviders = cardDeliveryProvidersEnabled.filter((currentDeliveryProvider: DeliveryProvider) => currentDeliveryProvider !== deliveryProvider);
            setCardDeliveryProvidersEnabled(newCardDeliveryProviders);
            return;
        }
        setCardDeliveryProvidersEnabled([...cardDeliveryProvidersEnabled, deliveryProvider]);
    };

    return (
        <div className={classes.paper}>
            <UpdatingContentProgress loading={loading || appLoading} />
            <Form form={form} onSubmit={onSubmit} disabled={loading || appLoading}>
                <div className={classes.formContent}>
                    <Card title={translate('Manual Orders')} classes={{ cardContent: classes.cardContent, cardTitle: classes.cardTitle }}>
                        <div className={classes.containerInfo}>
                            <FormSwitch name='manualOrdersEnabled' label={translate('Manual OrdersPage')} />
                            {manualOrdersEnabled && (
                                <>
                                    <FormSwitch
                                        name='externalCardPaymentsForManualOrdersEnabled'
                                        label={translate('Manual orders already paid by the customer enabled')}
                                        tooltip={translate('Enable this option to mark if a manual order has already been paid by the customer')}
                                    />

                                    {externalCardPaymentsForManualOrdersEnabled && (
                                        <FormSwitch
                                            name='externalCardPaymentsForManualOrdersAsDefaultEnabled'
                                            label={translate('Order already paid by the customer checkbox as checked by default enabled')}
                                            tooltip={translate('Enable to mark Order already paid by the customer checkbox as checked by default in manual orders modal')}
                                        />
                                    )}

                                    <FormCurrencyNumberStringField
                                        name='orderPriceLimitForManualOrder'
                                        label={translate('Custom price limit for manual orders')}
                                        tooltipText={translate('Set a price limit for orders that are created manually from the administrator')}
                                    />

                                    <FormCurrencyNumberStringField
                                        name='pideDirectoProtectionForManualOrderAmount'
                                        label={translate('Protection cost for manual orders')}
                                        tooltipText={translate('Set a protection cost for orders created manually from the admin')}
                                    />
                                </>
                            )}
                        </div>
                    </Card>

                    <div className={classes.cardsContainer}>
                        <Card title={translate('Own Drivers')} classes={{ cardContent: classes.cardContent, cardTitle: classes.cardTitle }}>
                            <div>
                                {externalDelivery && (
                                    <FormSwitch
                                        name='ownFleetEnabled'
                                        label={translate('Own Fleet Enabled')}
                                        tooltip={translate('Activate this option so that the restaurant can accept drivers from its own fleet')}
                                    />
                                )}
                                {ownFleetEnabled && (
                                    <FormSwitch
                                        name='showOrderItemsToDriverEnabled'
                                        label={translate('Show order products for my own fleet')}
                                        tooltip={translate('Activate this option if you want to show the items of each order to the fleet distributors')}
                                    />
                                )}
                                <FormSwitch
                                    name='externalOwnFleetEnabled'
                                    disabled={invoiceRestaurantDeliveryCost}
                                    label={translate('My External Own Fleet')}
                                    tooltip={translate('Enable this option to allow the restaurant to use external drivers. This option cannot be activated if you have delivery invoiced feature')}
                                />
                                <FormSwitch
                                    name='showOrderItemsToDriverEnabled'
                                    label={translate('Show order products for my own fleet')}
                                    tooltip={translate('Activate this option if you want to show the items of each order to the fleet distributors')}
                                />
                                {ownFleetEnabled && deliveryPaidByAndInvoicedToRestaurant && (
                                    <FormSwitch
                                        name='hideDeliveryEarningsFromDriverEnabled'
                                        label={translate('Hide Delivery Earnings From Driver')}
                                        tooltip={translate('Activate this option if you want to hide the delivery earnings from delivery requests to drivers')}
                                    />
                                )}
                                <FormSwitch
                                    name='externalOwnFleetEnabled'
                                    disabled={invoiceRestaurantDeliveryCost}
                                    label={translate('My External Own Fleet')}
                                    tooltip={translate('Enable this option to allow the restaurant to use external drivers. This option cannot be activated if you have delivery invoiced feature')}
                                />
                                {ownFleetEnabled && (
                                    <FormTextField
                                        name='driverLinkedCode'
                                        label={translate('Driver OwnFleet code')}
                                        helperText={translate('Delivery drivers will be able to link to the restaurant with this code')}
                                        disabled={ownFleetEnabled}
                                        tooltip={translate('It is the code that the delivery men must enter in the delivery application when they want to belong to the restaurants fleet')}
                                    />
                                )}
                            </div>
                        </Card>

                        <Card title={translate('External Delivery')} classes={{ cardContent: classes.cardContent, cardTitle: classes.cardTitle }}>
                            <FormSwitch
                                name='externalDelivery'
                                label={translate('External Delivery')}
                                helperText={translate('Enable this if orders are delivered using external drivers')}
                                disabled={deliveryPaidByAndInvoicedToRestaurant}
                            />
                            {externalDelivery && (
                                <>
                                    <FormSwitch
                                        name='customerPhoneNumberRequiredForExternalDelivery'
                                        label={translate('Customer Phone Number Required')}
                                        helperText={translate('Make customer phone number required when external delivery is on')}
                                    />
                                    <FormSwitch
                                        name='useNonGeoLocatedDeliveryAddressesEnabled'
                                        label={translate('Custom delivery address enabled')}
                                        helperText={translate('Do not use geo location for addresses')}
                                    />
                                </>
                            )}
                            <FormSwitch name='assignDriverEnabled' label={translate('Assign Drivers By Restaurant Enabled')} disabled={externalDelivery} />
                        </Card>
                    </div>
                    <Card title={translate('Supplier')} classes={{ cardContent: classes.cardContent, cardTitle: classes.cardTitle }}>
                        <div className={classes.containerInfo}>
                            {uberDaasDeliveryEnabled && (
                                <FormSwitch
                                    disabled
                                    name='uberDaasDeliveryVerificationPictureEnabled'
                                    label={translate('Enable verification photos for Uber Daas')}
                                    tooltip={translate('Obtain verification photos of the delivery process')}
                                    defaultValue={true}
                                />
                            )}
                            <FormSwitch
                                name='deliverySecurityCodeEnabled'
                                label={translate('Delivery Security Code Enabled')}
                                disabled={externalDelivery}
                                helperText={translate('Enable this to create a code for each order')}
                            />
                            <FormSwitch
                                name='requestCustomerNameOnCreateOrderEnabled'
                                label={translate('Request customer name on create order')}
                                tooltip={translate('Activate this option if you want the name of the customer to be required when creating an order')}
                            />
                            <FormSwitch
                                name='returnCancelledOrdersToRestaurantEnabled'
                                label={translate('Return Cancelled Orders To Restaurant Enabled')}
                                helperText={translate('Enable this to indicate to the driver to return the delivery to the restaurant in case of cancellation')}
                            />
                        </div>
                        <div className={classes.deliveryTypeSelect}>
                            <FormDeliveryManagerTypeSelect name='deliveryManagerType' label={translate('Delivery type')} />
                        </div>
                        <>
                            {isDeliveryManagerLineal(deliveryManagerType) && <DeliveryManagerLinealSettings />}
                            {isDeliveryManagerCyclical(deliveryManagerType) && <DeliveryManagerCyclicalSettings />}
                            {isDeliveryManagerOrchestrator(deliveryManagerType) && (
                                <DeliveryManagerOrchestratorSettings
                                    cashDeliveryProvidersEnabled={cashDeliveryProvidersEnabled}
                                    cardDeliveryProvidersEnabled={cardDeliveryProvidersEnabled}
                                    updateCashDeliveryProviders={updateCashDeliveryProviders}
                                    updateCardDeliveryProviders={updateCardDeliveryProviders}
                                />
                            )}
                        </>
                    </Card>

                    <Card title={translate('Commissions')} classes={{ cardContent: classes.cardContent, cardTitle: classes.cardTitle }}>
                        <div className={classes.containerInfo}>
                            {uberDaasDeliveryEnabled && (
                                <React.Fragment>
                                    <Grid item xs={12} sm={6}>
                                        <DeprecatedFormPercentNumberField
                                            name='commissionUberDaasWebCard'
                                            label={translate('Uber Daas Web Card Commission %')}
                                            tooltip={translate('Define the commissions for the uber Daas billing restaurant')}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <DeprecatedFormPercentNumberField
                                            name='commissionUberDaasWebCash'
                                            label={translate('Uber Daas Web Cash Commission %')}
                                            tooltip={translate('Define the commissions for the uber Daas billing restaurant')}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <DeprecatedFormPercentNumberField
                                            name='commissionUberDaasAppCard'
                                            label={translate('Uber Daas App Card Commission %')}
                                            tooltip={translate('Define the commissions for the uber Daas billing restaurant')}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <DeprecatedFormPercentNumberField
                                            name='commissionUberDaasAppCash'
                                            label={translate('Uber Daas App Cash Commission %')}
                                            tooltip={translate('Define the commissions for the uber Daas billing restaurant')}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <DeprecatedFormPercentNumberField
                                            name='commissionUberDaasManualOrder'
                                            label={translate('Uber Daas Manual Order Commission %')}
                                            tooltip={translate('Define the commissions for the uber Daas billing restaurant')}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <DeprecatedFormPercentNumberField
                                            name='commissionUberDaasPosCard'
                                            label={translate('Uber Daas Pos Card Commission %')}
                                            tooltip={translate('Define the commissions for the uber Daas billing restaurant')}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <DeprecatedFormPercentNumberField
                                            name='commissionUberDaasPosCash'
                                            label={translate('Uber Daas Pos Cash Commission %')}
                                            tooltip={translate('Define the commissions for the uber Daas billing restaurant')}
                                        />
                                    </Grid>
                                </React.Fragment>
                            )}
                            {rappiCargoDeliveryEnabled && (
                                <React.Fragment>
                                    <Grid item xs={12} sm={6}>
                                        <DeprecatedFormPercentNumberField name='commissionRappiCargoWebCard' label={translate('Rappi Cargo Web Card Commission %')} />
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <DeprecatedFormPercentNumberField name='commissionRappiCargoWebCash' label={translate('Rappi Cargo Web Cash Commission %')} />
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <DeprecatedFormPercentNumberField name='commissionRappiCargoAppCard' label={translate('Rappi Cargo App Card Commission %')} />
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <DeprecatedFormPercentNumberField name='commissionRappiCargoAppCash' label={translate('Rappi Cargo App Cash Commission %')} />
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <DeprecatedFormPercentNumberField name='commissionRappiCargoManualOrder' label={translate('Rappi Cargo Manual Order Commission %')} />
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <DeprecatedFormPercentNumberField name='commissionRappiCargoPosCard' label={translate('Rappi Cargo Pos Card Commission %')} />
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <DeprecatedFormPercentNumberField name='commissionRappiCargoPosCash' label={translate('Rappi Cargo Pos Cash Commission %')} />
                                    </Grid>
                                </React.Fragment>
                            )}
                        </div>
                    </Card>

                    <Card title={translate('Delivery Zones')} classes={{ cardContent: classes.cardContent, cardTitle: classes.cardTitle }}>
                        <div className={classes.containerInfo}>
                            <Grid item xs={12}>
                                <FormSwitch name='deliveryZonesEnabled' label={translate('Enable custom delivery zones')} />
                                <FormHelperText>{translate('Enable this to let restaurant create their own delivery zones')}</FormHelperText>
                            </Grid>
                            <FormNumberField
                                name='deliveryRadiusInKm'
                                label={translate('Delivery Radius in Km')}
                                helperText={translate('If not set the default value used is configured in Settings > Delivery Radius in Km')}
                                placeholder={defaultDeliveryRadiusInKm ? `${defaultDeliveryRadiusInKm}` : undefined}
                                max={{ value: 8, message: translate('The distance must be equal to or less than 8 km') }}
                                min={0}
                            />
                            <FormNumberField
                                name='customerDeliveryCostFixed'
                                label={translate('Customer Delivery Cost Fixed')}
                                helperText={translate('If not set the default value used is configured in Settings > Customer Delivery Cost Fixed')}
                                placeholder={defaultCustomerDeliveryCostFixed ? `${defaultCustomerDeliveryCostFixed}` : undefined}
                                min={0}
                            />
                            <FormNumberField
                                name='customerDeliveryCostPerKm'
                                label={translate('Customer Delivery Cost Per Km')}
                                helperText={translate('If not set the default value used is configured in Settings > Customer Delivery Cost Per Km')}
                                placeholder={defaultCustomerDeliveryCostPerKm ? `${defaultCustomerDeliveryCostPerKm}` : undefined}
                                min={0}
                            />
                            <FormDeliveryCategoriesAutocomplete
                                name='deliveryCategories'
                                label={translate('Delivery Categories')}
                                helperText={translate(
                                    `This will set how the restaurant will be categorised in app. Visible categories in app is set in Settings > Delivery Categories. If the restaurants delivery category does not exist in Settings > Delivery Categories it will be hidden in app.`,
                                )}
                            />
                        </div>
                    </Card>

                    <Card title={translate('Billing')} classes={{ cardContent: classes.cardContent, cardTitle: classes.cardTitle }}>
                        <div className={classes.containerInfo}>
                            <Button outlined onClick={handleDeliveryPaidControls} disabled={loading || appLoading || externalOwnFleetEnabled}>
                                {translate('Enable Delivery Paid and Invoice Controls').toUpperCase()}
                            </Button>
                            <FormSwitch name='invoiceOnlyDeliveryCostEnabled' label={translate('Invoice just the delivery cost, not the total order')} />
                            <FormSwitch
                                name='deliveryPaidByAndInvoicedToRestaurant'
                                label={translate('Deliveries Will Be Invoiced To The Client')}
                                disabled={!restaurantDeliveryPaidControls || externalDelivery || externalOwnFleetEnabled}
                            />
                            <FormHelperText>
                                {translate('Enable this if deliveries should be paid by and invoiced to the restaurant. This option cannot be activated if you have external own fleet')}
                            </FormHelperText>
                        </div>
                    </Card>

                    <Card title={translate('Dynamic Earning')} classes={{ cardContent: classes.cardContent, cardTitle: classes.cardTitle }}>
                        <div className={classes.containerInfo}>
                            <FormNumberField
                                name='dynamicEarningsMinimumMinutes'
                                label={translate('Dynamic Earnings Minimum Minutes')}
                                placeholder={defaultDynamicEarningsMinimumMinutes ? `${defaultDynamicEarningsMinimumMinutes}` : undefined}
                                min={0}
                                tooltip={translate('Define the minimum minutes for dynamic earnings')}
                            />
                            <FormNumberField
                                name='longDistanceExtraDeliveryCostFixed'
                                label={translate('Driver Long Distance Delivery Extra Earnings Fixed')}
                                helperText={translate(
                                    'When the delivery distance is longer than in the setting Driver is additional long-distance delivery earnings start from kilometer. If not set, the default value used is set in Settings.',
                                )}
                                placeholder={defaultLongDistanceExtraDeliveryCostFixed ? `${defaultLongDistanceExtraDeliveryCostFixed}` : undefined}
                                min={0}
                            />
                            <FormCurrencyNumberField
                                name='dynamicEarningsAmountToIncrementByMinute'
                                label={translate('Dynamic Earnings Amount To Increment By Minute')}
                                placeholder={defaultDynamicEarningsAmountToIncrementByMinute ? `${defaultDynamicEarningsAmountToIncrementByMinute}` : undefined}
                                min={0}
                                tooltip={translate('Define the Amount of money that will increase the dynamic delivery earnings per minute')}
                            />
                            <FormNumberField
                                name='longDistanceExtraDeliveryCostStartsFromKm'
                                label={translate('Driver Long Distance Delivery Extra Earnings Starts From Km')}
                                helperText={translate(
                                    'Deliveries with drive distance longer than this distance will have additional extra earnings added for drivers. The extra earnings are set in setting "Driver Long Distance Delivery Extra Earnings Fixed" and "Driver Long Distance Delivery Extra Earnings Per Km". If not set the default value used is configured in Settings.',
                                )}
                                placeholder={defaultLongDistanceExtraDeliveryCostStartsFromKm ? `${defaultLongDistanceExtraDeliveryCostStartsFromKm}` : undefined}
                                min={0}
                            />
                            <FormCurrencyNumberField
                                name='dynamicEarningsRoof'
                                label={translate('Dynamic Earnings Roof')}
                                placeholder={defaultDynamicEarningsRoof ? `${defaultDynamicEarningsRoof}` : undefined}
                                min={0}
                                tooltip={translate('Define the Limit amount for Dynamic earnings')}
                            />
                            <FormNumberField
                                name='longDistanceExtraDeliveryCostPerKm'
                                label={translate('Driver Long Distance Delivery Extra Earnings Per Km')}
                                helperText={translate(
                                    'Add this extra earnings per km (above setting limit) for drivers when delivery distance for a delivery is longer than in setting "Driver Long Distance Delivery Extra Earnings Starts From Km". If not set the default value used is configured in Settings.',
                                )}
                                placeholder={defaultLongDistanceExtraDeliveryCostPerKm ? `${defaultLongDistanceExtraDeliveryCostPerKm}` : undefined}
                                min={0}
                            />
                            <FormCurrencyNumberStringField name='deliveryCommissionRange1' label={translate('Delivery Comission Range 1')} helperText={translate('')} min={0} />
                            <FormCurrencyNumberStringField name='deliveryCommissionRange2' label={translate('Delivery Comission Range 2')} helperText={translate('')} min={0} />
                        </div>
                    </Card>
                    <FormFixErrorHint />
                </div>
                <Grid container justify='center' spacing={3}>
                    <Grid item>
                        <FormSubmitButton text={translate('Change')} disabled={loading || appLoading} classes={{ container: classes.submitButton }} />
                    </Grid>
                </Grid>
            </Form>
        </div>
    );
}

const useStyles = makeStyles((theme) => ({
    paper: {
        position: 'relative',
    },
    formContent: {
        display: 'flex',
        flexDirection: 'column',
        gap: 12,
    },
    contentItem: {
        display: 'flex',
        width: '100%',
        alignItems: 'center',
        justifyContent: 'space-between',
        gap: 20,
        fontFamily: theme.typography.regular,
    },
    submitButton: {
        zIndex: 1000,
        marginTop: 20,
    },
    configurationSection: {
        width: '100%',
        borderRadius: 5,
        padding: '20px',
        display: 'flex',
        flexDirection: 'column',
        border: `1px solid ${theme.palette.secondary.dark}`,
    },
    configurationSectionTitle: {
        fontSize: 14,
        fontWeight: 600,
        fontFamily: theme.typography.regular,
        color: theme.palette.text.primary,
        marginBottom: 8,
    },
    containerInfo: {
        width: '100%',
        display: 'grid',
        marginLeft: 2,
        gap: 10,
        gridTemplateColumns: '1fr',
        [theme.breakpoints.up('md')]: {
            gridTemplateColumns: '1fr 1fr',
        },
    },
    deliveryTypeSelect: {
        width: '50%',
        marginTop: '10px',
        marginBottom: '10px',
        [theme.breakpoints.down('md')]: {
            width: '100%',
        },
    },
    cardContent: {
        padding: 12,
    },
    cardTitle: {
        marginLeft: 12,
    },
    cardsContainer: {
        width: '100%',
        display: 'grid',
        marginLeft: 2,
        gap: 10,
        gridTemplateColumns: '1fr',
        [theme.breakpoints.up('md')]: {
            gridTemplateColumns: '1fr 1fr',
        },
    },
    inputSpacing: {
        borderBottom: '6px',
    },
}));

type Props = {
    loading: boolean;
    restaurant: any;
    onChangeRestaurant: () => void;
    setIsDirtyForm: any;
};
