import { useEffect } from 'react';

/**
 * @callback onClick
 * @param {boolean} hasClickedOutside - Boolean value to know if the user has clicked outside the provided element, if true the user has clicked outside
 */

/**
 * Hook to know if the user has clicked outside an element
 * @param {HTMLElement} [element] - Element to track if the user has clicked outside of it
 * @param {onClick} onClick - Function to be called on each click
 */
export function useHasClickedOutside({ element, onClick }: Params): void {
    useEffect(() => {
        window.addEventListener('click', clickHandler);
        return () => window.removeEventListener('click', clickHandler);
    }, [element]);

    const clickHandler = (e: any) => {
        if (!element) return false;
        onClick({ hasClickedOutside: !element.contains(e.target), elementClicked: e.target });
    };
}

type Params = {
    element: HTMLElement | undefined | null;
    onClick: (event: { hasClickedOutside: boolean; elementClicked: HTMLElement }) => void | Promise<void>;
};
