import { Countries } from 'src/constants/Country';
import type { PosState } from 'src/reducers/posReducer';
import { PosTip } from 'src/types/PosTip';
import { createPaymentDistribution } from 'src/utils/pos/createPaymentDistribution';

export function refreshPosPaymentState(state: PosState): void {
    if (!state.items.length) {
        delete state.payment;
        return;
    }

    const payment = createPaymentDistribution({
        country: state.context?.country ?? Countries.MX,
        payments: state.payments,
        paymentMethod: state.paymentMethod,
        customPaymentMethod: state.customPaymentMethod,
        orderItems: state.items,
        discount: state.discount,
        promotions: state.promotions,
        tips: state.tips?.map((tip: PosTip) => ({
            tipType: tip.tipType,
            tipPercentage: tip.tipPercentage,
            paymentMethod: tip.paymentMethod,
            tipAmount: tip.tipAmount ?? '0',
            customerNumber: tip.customerNumber,
            paymentId: tip.paymentId,
        })),
        promoCode: state.promoCode,
        orderType: state.orderType,
        driverRequest: state.driverRequest,
        usedCustomerCredits: state.usedCustomerCredits ?? '0',
    });

    state.payment = {
        subtotal: payment.subtotal,
        productDiscount: payment.productDiscount,
        posDiscount: payment.posDiscount,
        tips: payment.tips?.map((tip: PosTip) => ({
            tipType: tip.tipType,
            paymentMethod: tip.paymentMethod,
            tipAmount: tip.tipAmount ?? '0',
            restaurantCurrencyAmount: tip.restaurantCurrencyAmount,
            restaurantCurrency: tip.restaurantCurrency,
        })),
        usedPromotions: payment.usedPromotions,
        promotionsDiscount: payment.promotionsDiscount,
        total: payment.total,
        deliveryCashHandlingFee: payment.deliveryCashHandlingFee,
        promoCodeSubtotal: payment.promoCodeSubtotal,
        promoCodeDiscount: payment.promoCodeDiscount,
        promoCodeCredits: payment.promoCodeCredits,
        promoCode: payment.promoCode,
    };
}
