import { letseatmanagerApiMethod, letseatmanagerApiMethodPublic } from 'src/api/letseatmanagerApi';
import type { ApiSauceResponse } from 'src/api/types/ApiSauceResponse';
import { getJwtToken } from 'src/api/utils/getJwtToken';
import type { UiLogEvent } from 'src/services/logEvent/types/UiLogEvent';

export async function createOfflineUiErrorLogEvent(request: UiLogEvent): ApiSauceResponse<void> {
    const offlineRequest = { ...request, offline: true } as const;

    if ((await getJwtToken()).jwtToken) {
        return await letseatmanagerApiMethod('logEvent/createUiErrorLogEventApi', offlineRequest);
    } else {
        return await letseatmanagerApiMethodPublic('logEvent/createUiErrorLogEventApi', offlineRequest);
    }
}
