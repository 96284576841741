import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { formatAsCurrencyNumber } from '@pidedirecto/ui/utils';
import moment from 'moment';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { useForm, useFormContext, useWatch } from 'react-hook-form';
import { createNextSubscriptionPaymentApi } from 'src/api/letseatadmin/subscription/createNextSubscriptionPaymentApi';
import { getSubscriptionApi, SubscriptionVm } from 'src/api/letseatadmin/subscription/getSubscriptionApi';
import { removeSubscriptionApi } from 'src/api/letseatadmin/subscription/removeSubscriptionApi';
import { updateSubscriptionAfterPaymentApi } from 'src/api/letseatadmin/subscription/updateSubscriptionAfterPaymentApi';
import { Form } from 'src/components/form/Form';
import { FormCurrencyNumberStringField } from 'src/components/form/FormCurrencyNumberStringField';
import { FormDatePicker } from 'src/components/form/FormDatePicker';
import { FormDateTimePicker } from 'src/components/form/FormDateTimePicker';
import { FormSubscriptionPaymentMethodSelect } from 'src/components/form/FormSubscriptionPaymentMethodSelect';
import { FormSubscriptionPlansSelect } from 'src/components/form/FormSubscriptionPlansSelect';
import { FormSubscriptionStatusesSelect } from 'src/components/form/FormSubscriptionStatusesSelect';
import { FormTextField } from 'src/components/form/FormTextField';
import { ChangeToolbarButton } from 'src/components/mui-datatables/ChangeToolbarButton';
import { CreatePaymentToolbarButton } from 'src/components/mui-datatables/CreatePaymentToolbarButton';
import { OpenInNewTabToolbarButton } from 'src/components/mui-datatables/OpenInNewTabToolbarButton';
import { RefreshToolbarButton } from 'src/components/mui-datatables/RefreshToolbarButton';
import { UpdatingContentProgress } from 'src/components/UpdatingContentProgress';
import { CardPriorities } from 'src/constants/CardPriority';
import { MomentFormats } from 'src/constants/MomentFormat';
import type { PaymentRejectReason } from 'src/constants/PaymentRejectReason';
import type { SubscriptionPaymentErrorType } from 'src/constants/SubscriptionPaymentErrorType';
import { SubscriptionPaymentMethods } from 'src/constants/SubscriptionPaymentMethod';
import { SubscriptionPlan, SubscriptionPlans } from 'src/constants/SubscriptionPlan';
import { TimeZones } from 'src/constants/TimeZone';
import { translate } from 'src/i18n/translate';
import { ChangeSubscriptionDialog } from 'src/scenes/letseatadmin/subscription/ChangeSubscriptionDialog';
import { SubscriptionCard } from 'src/scenes/letseatmanager/subscription/SubscriptionCard';
import type { RestaurantId, SubscriptionId } from 'src/types/Id';
import { alertKnownErrorOrSomethingWentWrong } from 'src/utils/alert/alertKnownErrorOrSomethingWentWrong';
import { overdueDays } from 'src/utils/date/overdueDays';
import { withoutIva } from 'src/utils/number/withoutIva';
import { useSelector } from 'src/utils/react/useSelector';
import { requireValue } from 'src/utils/require/requireValue';
import { translatePaymentRejectReason } from 'src/utils/translate/translatePaymentRejectReason';
import { translateSubscriptionPaymentErrorType } from 'src/utils/translate/translateSubscriptionPaymentErrorType';
import { openRestaurantPageInNewTab } from 'src/utils/window/openRestaurantPageInNewTab';

export function SubscriptionDetails({ subscriptionId, onChangeSubscription }: Props): React.ReactElement {
    const classes = useStyles();
    const form = useForm();
    const { control } = form;
    const restaurantId = useWatch<RestaurantId>({ name: 'restaurantId', control });
    const [loading, setLoading] = useState(false);
    const [speiInternalSubscription, setSpeiInternalSubscription] = useState(false);
    const [changeSubscriptionDialogState, setChangeSubscriptionDialogState] = useState<{ open: boolean; subscriptionId: SubscriptionId | undefined }>({ open: false, subscriptionId: undefined });

    useEffect(() => {
        if (subscriptionId) load();
    }, [subscriptionId]);

    const load = async () => {
        setLoading(true);
        const response = await getSubscriptionApi({ subscriptionId: requireValue(subscriptionId) });
        setLoading(false);
        if (!response.ok) {
            alertKnownErrorOrSomethingWentWrong(response);
            return;
        }
        const subscription = response.data;
        if (subscription.subscriptionPaymentMethod === SubscriptionPaymentMethods.SPEI_INTERNAL) {
            setSpeiInternalSubscription(true);
        }
        form.reset(subscription);
        onChangeSubscription && onChangeSubscription(subscription);
    };

    const createNextSubscriptionPayment = async (subscriptionId: SubscriptionId) => {
        setLoading(true);
        const response = await createNextSubscriptionPaymentApi({ subscriptionId });
        if (!response.ok) {
            setLoading(false);
            alertKnownErrorOrSomethingWentWrong(response);
            await load();
            return;
        }
        if (response.data.paymentErrorType) {
            alert(translateSubscriptionPaymentErrorType(response.data.paymentErrorType));
            await load();
            return;
        }
        alert(translate('Success'));
        await load();
    };

    const removeSubscription = async (subscriptionId: SubscriptionId) => {
        setLoading(true);
        const response = await removeSubscriptionApi({ subscriptionId });
        if (!response.ok) {
            setLoading(false);
            alertKnownErrorOrSomethingWentWrong(response);
            await load();
            return;
        }
        await load();
    };

    const updateSubscriptionAfterPayment = async (subscriptionId: SubscriptionId) => {
        setLoading(true);
        const response = await updateSubscriptionAfterPaymentApi({ subscriptionId });
        if (!response.ok) {
            setLoading(false);
            alertKnownErrorOrSomethingWentWrong(response);
            return;
        }
        await load();
    };

    return (
        <div className={classes.container}>
            <UpdatingContentProgress loading={loading} />
            <ChangeSubscriptionDialog
                open={changeSubscriptionDialogState.open}
                subscriptionId={changeSubscriptionDialogState.subscriptionId}
                onClose={() => setChangeSubscriptionDialogState({ open: false, subscriptionId: undefined })}
                onChangeSubscription={load}
            />
            <Card classes={{ root: classes.root }}>
                <CardHeader
                    title={
                        <Typography display='block' gutterBottom variant='h6'>
                            {translate('Subscription Details')}
                        </Typography>
                    }
                    action={
                        <div>
                            {!speiInternalSubscription && <CreatePaymentToolbarButton onClick={() => createNextSubscriptionPayment(subscriptionId)} />}
                            {speiInternalSubscription && (
                                <CreatePaymentToolbarButton
                                    tooltip={translate('UpdateSubscription Payment')}
                                    onClick={async () => {
                                        const updateSubscription = window.confirm('Are you sure you want to update the  Subscription');
                                        if (updateSubscription) {
                                            await updateSubscriptionAfterPayment(subscriptionId);
                                        }
                                    }}
                                />
                            )}
                            <ChangeToolbarButton onClick={() => setChangeSubscriptionDialogState({ open: true, subscriptionId })} tooltip={translate('Change')} />
                            <OpenInNewTabToolbarButton onClick={() => openRestaurantPageInNewTab(restaurantId)} tooltip={translate('Open Restaurant')} />
                            {/*<RemoveToolbarButton onClick={async () => {*/}
                            {/*    const remove = window.confirm('Are you sure you want to remove the subscription');*/}
                            {/*    if (remove) await removeSubscription(subscriptionId);*/}
                            {/*}} />*/}
                            <RefreshToolbarButton onClick={load} />
                        </div>
                    }
                />
                <CardContent>
                    <Form form={form} onSubmit={() => {}}>
                        <FormContent />
                    </Form>
                </CardContent>
            </Card>
        </div>
    );
}

function FormContent() {
    const { control } = useFormContext();
    const cards: Array<any> | undefined = useWatch({ name: 'cards', control });
    const previousPaymentDate: Date | undefined = useWatch({ name: 'previousPaymentDate', control });
    const previousPaymentPaidAt: Date | undefined = useWatch({ name: 'previousPaymentPaidAt', control });
    const nextPaymentDate: Date | undefined = useWatch({ name: 'nextPaymentDate', control });
    const previousPaymentOverdue = overdueDays(previousPaymentDate, previousPaymentPaidAt, TimeZones.AMERICA_MONTERREY);
    const nextPaymentOverdue = overdueDays(nextPaymentDate, new Date(), TimeZones.AMERICA_MONTERREY);
    const paymentErrorType: SubscriptionPaymentErrorType | undefined = useWatch({ name: 'paymentErrorType', control });
    const paymentRejectReason: PaymentRejectReason | undefined = useWatch({ name: 'paymentRejectReason', control });
    const plan: SubscriptionPlan | undefined = useWatch({ name: 'plan', control });
    const payPerUseRange1PaymentAmount: string | undefined = useWatch({ name: 'payPerUseRange1PaymentAmount', control });
    const payPerUseRange2PaymentAmount: string | undefined = useWatch({ name: 'payPerUseRange2PaymentAmount', control });
    const payPerUseRangeMaxPaymentAmount: string | undefined = useWatch({ name: 'payPerUseRangeMaxPaymentAmount', control });

    const settingPayPerUseRange1OrdersLimit = useSelector((state) => state.app2.settings.payPerUseRange1OrdersLimit);
    const settingPayPerUseRange2OrdersLimit = useSelector((state) => state.app2.settings.payPerUseRange2OrdersLimit);
    const settingPayPerUseRangeMaxOrdersLimit = useSelector((state) => state.app2.settings.payPerUseRangeMaxOrdersLimit);

    const primaryCard = cards?.find((card: any) => card.cardPriority === CardPriorities.PRIMARY);
    const backupCard = cards?.find((card: any) => card.cardPriority === CardPriorities.BACKUP);
    const isPayPerUse = plan === SubscriptionPlans.PAY_PER_USE;

    return (
        <Grid container spacing={3}>
            <Grid item xs={12} sm={6}>
                <FormTextField name='restaurantNames' label={translate('Restaurant Name')} disabled />
            </Grid>
            <Grid item xs={12} sm={6}>
                <FormTextField name='businessName' label={translate('Business Name')} disabled />
            </Grid>
            <Grid item xs={12} sm={6}>
                <FormTextField name='managerMessage' label={translate('Manager Message')} disabled />
            </Grid>
            <Grid item xs={12} sm={6}>
                <FormTextField name='comment' label={translate('Comment')} disabled />
            </Grid>
            <Grid item xs={12} sm={6}>
                <FormTextField name='invoiceDescription' label={translate('Invoice Description')} disabled />
            </Grid>
            <Grid item xs={12} sm={6}>
                <FormSubscriptionStatusesSelect name={'status'} label={translate('Status')} disabled />
            </Grid>
            <Grid item xs={12} sm={6}>
                <FormSubscriptionPlansSelect name={'plan'} label={translate('Plan')} disabled />
            </Grid>
            <Grid item xs={12} sm={4}>
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <FormCurrencyNumberStringField name='paymentAmount' label={translate('Amount')} disabled />
                    </Grid>
                    <Grid item xs={12}>
                        <FormSubscriptionPaymentMethodSelect name='subscriptionPaymentMethod' label={translate('Payment Method')} disabled />
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs={12}>
                <Grid container spacing={3}>
                    {plan === SubscriptionPlans.PAY_PER_USE ? (
                        <>
                            <Grid item xs={12}>
                                <FormCurrencyNumberStringField
                                    name='payPerUseRange1PaymentAmount'
                                    label={translate('Range 1 Payment Amount')}
                                    helperText={translate(
                                        `A manual earning of -@amount (-@amountWithoutIva without IVA) will be created once any restaurant in this subscription completed @orders delivery orders (rappi, didi, ubereats orders not counted) within the month period.`,
                                        {
                                            amount: formatAsCurrencyNumber(payPerUseRange1PaymentAmount),
                                            amountWithoutIva: formatAsCurrencyNumber(withoutIva(payPerUseRange1PaymentAmount)),
                                            orders: settingPayPerUseRange1OrdersLimit,
                                        },
                                    )}
                                    disabled
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <FormCurrencyNumberStringField
                                    name='payPerUseRange2PaymentAmount'
                                    label={translate('Range 2 Payment Amount')}
                                    helperText={translate(
                                        `A manual earning of -@amount (-@amountWithoutIva without IVA) will be created once any restaurant in this subscription completed @orders delivery orders (rappi, didi, ubereats orders not counted) within the month period.`,
                                        {
                                            amount: formatAsCurrencyNumber(payPerUseRange2PaymentAmount),
                                            amountWithoutIva: formatAsCurrencyNumber(withoutIva(payPerUseRange2PaymentAmount)),
                                            orders: settingPayPerUseRange2OrdersLimit,
                                        },
                                    )}
                                    disabled
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <FormCurrencyNumberStringField
                                    name='payPerUseRangeMaxPaymentAmount'
                                    label={translate('Range Max Payment Amount')}
                                    helperText={translate(
                                        `A manual earning of -@amount (-@amountWithoutIva without IVA) will be created once any restaurant in this subscription completed @orders delivery orders (rappi, didi, ubereats orders not counted) within the month period.`,
                                        {
                                            amount: formatAsCurrencyNumber(payPerUseRangeMaxPaymentAmount),
                                            amountWithoutIva: formatAsCurrencyNumber(withoutIva(payPerUseRangeMaxPaymentAmount)),
                                            orders: settingPayPerUseRangeMaxOrdersLimit,
                                        },
                                    )}
                                    disabled
                                />
                            </Grid>
                        </>
                    ) : (
                        <Grid item xs={12}>
                            <FormCurrencyNumberStringField name='nextPaymentAmount' label={translate('Next Payment Amount')} disabled />
                        </Grid>
                    )}
                    <Grid item xs={12}>
                        <FormDatePicker
                            name='nextPaymentDate'
                            label={translate('Next Payment Date')}
                            helperText={
                                isPayPerUse && nextPaymentDate
                                    ? `Current payment period ${moment(nextPaymentDate).subtract(1, 'month').format(MomentFormats.ISO_DATE)} to ${moment(nextPaymentDate).format(
                                          MomentFormats.ISO_DATE,
                                      )}`
                                    : nextPaymentOverdue !== undefined
                                      ? translate(`@overdue days late`, {
                                            overdue: nextPaymentOverdue,
                                        })
                                      : nextPaymentDate
                                        ? ''
                                        : '?'
                            }
                            disabled
                        />
                    </Grid>
                </Grid>
            </Grid>
            {!cards?.length && (
                <>
                    <Grid item xs={12}>
                        <Typography>{translate('No card added')}</Typography>
                    </Grid>
                </>
            )}
            {!!cards?.length && (
                <Grid item xs={12}>
                    <Grid container spacing={3}>
                        {primaryCard && (
                            <Grid item xs={12} md={6}>
                                <SubscriptionCard card={primaryCard} cardPriority={primaryCard.cardPriority} admin />
                            </Grid>
                        )}
                        {backupCard && (
                            <Grid item xs={12} md={6}>
                                <SubscriptionCard card={backupCard} cardPriority={backupCard.cardPriority} admin />
                            </Grid>
                        )}
                    </Grid>
                </Grid>
            )}
            <Grid item xs={12} sm={6}>
                <FormDateTimePicker name='createdAt' label={translate('Created')} disabled />
            </Grid>
            <Grid item xs={12} sm={6}>
                <FormDateTimePicker name='modifiedAt' label={translate('Modified')} disabled />
            </Grid>
            <Grid item xs={12}>
                <FormTextField name='paymentErrorType' label={translate('Payment Error')} helperText={translateSubscriptionPaymentErrorType(paymentErrorType)} disabled />
            </Grid>
            <Grid item xs={12}>
                <FormTextField name='paymentRejectReason' label={translate('Payment Reject Reason')} helperText={translatePaymentRejectReason(paymentRejectReason)} disabled />
            </Grid>
        </Grid>
    );
}

const useStyles = makeStyles((theme) => ({
    container: {
        position: 'relative',
    },
    root: {
        paddingLeft: 8,
        paddingRight: 8,
    },
}));

type Props = {
    subscriptionId: SubscriptionId;
    onChangeSubscription?: (arg1: SubscriptionVm) => any;
};
