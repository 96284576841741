import { makeStyles } from '@material-ui/core';
import { Loader } from '@pidedirecto/ui';
import * as React from 'react';
import { classNames } from 'src/utils/react/classNames';

export function OnEndReached({ children, onEndReached, className, loading }: Props): React.ReactElement {
    const classes = useStyles();
    const handleScroll = (event: any) => {
        const bottom = event.target.clientHeight + event.target.scrollTop >= event.target.scrollHeight - 1;
        if (!bottom) return;
        onEndReached();
    };

    return (
        <div className={classNames(classes.scrollContainer, className)} onScroll={handleScroll}>
            {children}
            {loading && (
                <div className={classes.circularLoader}>
                    <Loader size={40} loading={true} />
                </div>
            )}
        </div>
    );
}

const useStyles = makeStyles((theme) => ({
    scrollContainer: {
        position: 'relative',
        width: '100%',
        height: '100%',
        overflowY: 'scroll',
        '&::-webkit-scrollbar': {
            display: 'none',
        },
    },
    circularLoader: {
        position: 'fixed',
        bottom: '20%',
        left: '40%',
    },
}));

type Props = {
    children: React.ReactNode;
    onEndReached: any;
    className?: string;
    loading: boolean;
};
