import { letseatadminApiMethod } from 'src/api/letseatadminApi';
import type { ApiSauceResponse } from 'src/api/types/ApiSauceResponse';
import type { BannerVisibility } from 'src/constants/BannerVisibility';
import type { Country } from 'src/constants/Country';
import type { BannerVm } from 'src/types/BannerVm';
import type { BannerId, DeliveryCategory, RestaurantId } from 'src/types/Id';

export async function changeBannerApi(request: ChangeBannerApiRequest): ApiSauceResponse<BannerVm> {
    return letseatadminApiMethod('banner/changeBannerApi', request);
}

type ChangeBannerApiRequest = {
    bannerId: BannerId;
    bannerVisibility?: BannerVisibility;
    deliveryCategories?: Array<DeliveryCategory>;
    enabled: boolean;
    hours?: string;
    country: Country;
    imageUrl: string;
    restaurantIds?: Array<RestaurantId>;
    urlSubdomain?: string;
    companyNames?: Array<string>;
    pinned: boolean;
};
