import Grid from '@material-ui/core/Grid';
import { useEffect, useState } from 'react';
import * as React from 'react';
import { Button } from 'src/components/Button';
import { UnlinkRestaurantToInventoryDialog } from 'src/components/dialog/UnlinkRestaurantToInventoryDialog';
import { FormSubheading } from 'src/components/form/FormSubheading';
import { FormSwitch } from 'src/components/form/FormSwitch';
import { Text } from 'src/components/Text';
import { WebSocketEventTypes } from 'src/constants/WebSocketEventType';
import { translate } from 'src/i18n/translate';
import { appReducer } from 'src/reducers/appReducer';
import { LinkInventoryFromRestaurantDialog } from 'src/scenes/letseatmanager/inventory/inventory/LinkInventoryFromRestaurantDialog';
import { RestaurantsLinkedToInventorySection } from 'src/scenes/letseatmanager/inventory/inventory/RestaurantsLinkedToInventorySection';
import { useNotification } from 'src/services/notification/useNotification';
import { useReloadRestaurant } from 'src/services/restaurant/useReloadRestaurant';
import type { RestaurantId } from 'src/types/Id';
import type { WebSocketEvent } from 'src/types/WebSocketEvent';
import { useAction } from 'src/utils/react/useAction';
import { useSelector } from 'src/utils/react/useSelector';
import { WebSocketEvents } from 'src/utils/webSocket/WebSocketEvents';

export function RestaurantInventorySettings({ onChangeRestaurant }: Props): React.ReactElement {
    const notification = useNotification();

    const restaurantId = useSelector((state) => state.app.restaurantId);

    const [linkRestaurantToInventoryDialogIsOpen, setLinkRestaurantToInventoryDialogIsOpen] = useState(false);
    const [unLinkRestaurantToInventoryDialogIsOpen, setUnLinkRestaurantToInventoryDialogIsOpen] = useState(false);

    const { reloadRestaurant } = useReloadRestaurant();
    const showSnackbar = useAction(appReducer.actions.showSnackbar);

    const inventoryLinkedToRestaurantId = useSelector((state) => state.app.restaurant?.inventoryLinkedToRestaurantId);
    const inventoryLinkedToRestaurantName = useSelector((state) => state.app.restaurant?.inventoryLinkedToRestaurantName);
    const restaurantsLinkedToInventory = useSelector((state) => state.app.restaurant?.restaurantsLinkedToInventory);

    useEffect(() => {
        const linkedInventoryBetweenRestaurantsWebSocketEvent = WebSocketEvents.addEventListener(WebSocketEventTypes.RESTAURANT_INVENTORY_LINKED, handleUpdateRestaurantSetting);
        const unlinkedInventoryBetweenRestaurantsWebSocketEvent = WebSocketEvents.addEventListener(WebSocketEventTypes.RESTAURANT_INVENTORY_UNLINKED, handleUpdateRestaurantSetting);

        return () => {
            linkedInventoryBetweenRestaurantsWebSocketEvent.remove();
            unlinkedInventoryBetweenRestaurantsWebSocketEvent.remove();
        };
    }, []);

    const onSuccess = async () => {
        onChangeRestaurant();
        await reloadRestaurant(restaurantId);
        showSnackbar({ message: translate('Saved changes') });
    };

    const handleOpenLinkRestaurantToInventoryDialog = () => setLinkRestaurantToInventoryDialogIsOpen(true);
    const handleCloseLinkRestaurantToInventoryDialog = () => setLinkRestaurantToInventoryDialogIsOpen(false);

    const onSuccessLinkRestaurantToInventory = () => {
        onSuccess();
        handleCloseLinkRestaurantToInventoryDialog();
    };

    const handleCloseUnlinkInventoryDialog = () => setUnLinkRestaurantToInventoryDialogIsOpen(false);

    const handleOpenUnlinkInventoryDialog = () => setUnLinkRestaurantToInventoryDialogIsOpen(true);

    const handleUpdateRestaurantSetting = async (
        webSocketEventType: WebSocketEvent<{
            restaurantId: RestaurantId;
            restaurantName: string;
        }>,
    ) => {
        notification({ message: translate('Restaurant @restaurantName changed', { restaurantName: webSocketEventType.data?.restaurantName }) });
        await reloadRestaurant(restaurantId);
    };

    return (
        <div>
            <UnlinkRestaurantToInventoryDialog open={unLinkRestaurantToInventoryDialogIsOpen} onSuccess={onSuccess} onClose={handleCloseUnlinkInventoryDialog} />
            <LinkInventoryFromRestaurantDialog open={linkRestaurantToInventoryDialogIsOpen} onSuccess={onSuccessLinkRestaurantToInventory} onClose={handleCloseLinkRestaurantToInventoryDialog} />
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <FormSubheading align={'start' as any}>{translate('Inventory Settings')}</FormSubheading>
                </Grid>
            </Grid>
            <Grid container spacing={3}>
                <Grid item xs={12} md={6}>
                    <FormSwitch name='inventoryEnabled' label={translate('Inventory Enabled')} tooltip={translate('Enable to view inventory')} />
                </Grid>

                <Grid item xs={12} md={6}>
                    <FormSwitch name='updateInventoryForCountsEnabled' label={translate('Update Inventory For Counts Enabled')} tooltip={translate('Enable to update the inventory on counts')} />
                </Grid>

                <Grid item xs={12} md={6}>
                    <FormSwitch name='inventoryShowNegative' label={translate('Show Inventory Negative')} tooltip={translate('Check if you want to discount inventory even with negative values')} />
                </Grid>

                <Grid item xs={12} md={6}>
                    <FormSwitch name='buyOrdersEnabled' label={translate('Buy Orders Enabled')} tooltip={translate('Enable to view buy Orders')} />
                </Grid>
                {!restaurantsLinkedToInventory && !inventoryLinkedToRestaurantId && (
                    <Grid item xs={12} md={6}>
                        <Button outlined onClick={handleOpenLinkRestaurantToInventoryDialog}>
                            {translate('Link inventory from other restaurant')}
                        </Button>
                    </Grid>
                )}
                {inventoryLinkedToRestaurantId && (
                    <Grid item sm={12} md={6} style={{ display: 'flex', gap: 15, alignItems: 'center' }}>
                        <Text>{translate('Your restaurant is linked to inventory of restaurant: @restaurantName', { restaurantName: inventoryLinkedToRestaurantName })}</Text>
                        <Button secondary onClick={handleOpenUnlinkInventoryDialog}>
                            {translate('Unlink')}
                        </Button>
                    </Grid>
                )}
            </Grid>

            <RestaurantsLinkedToInventorySection />
        </div>
    );
}

type Props = {
    onChangeRestaurant: any;
};
