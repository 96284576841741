import moment from 'moment';

export function groupOrderItemsAndCancelledItemsByAddedDate<T>(orderItems: Array<T>, cancelledItems: Array<T>): Array<Array<T>> {
    const items = [...orderItems, ...cancelledItems];

    const now = new Date();
    const orderItemsSorted = items.sort((orderItemA, orderItemB) => {
        const dateToCompareA = (orderItemA as any)?.addedAt ?? now;
        const dateToCompareB = (orderItemB as any)?.addedAt ?? now;

        return (new Date(dateToCompareA) as any) - (new Date(dateToCompareB) as any);
    });

    return orderItemsSorted?.reduce((orderItems: Array<Array<T>>, orderItem: any) => {
        if (orderItems.length === 0) {
            orderItems.push([orderItem]);
            return orderItems;
        }

        const lastGroupIndex = orderItems.length - 1;
        const lasOrderItemInTheGroup: any = orderItems[lastGroupIndex][0];
        const dateToCompare = lasOrderItemInTheGroup.addedAt ?? now;

        const orderItemHasTheSameAddedDate = moment(dateToCompare).isSame(orderItem.addedAt ?? now);

        if (orderItemHasTheSameAddedDate) {
            orderItems[lastGroupIndex].push(orderItem);
        } else {
            orderItems.push([orderItem]);
        }

        return orderItems;
    }, []);
}
