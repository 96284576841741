import { makeStyles } from '@material-ui/core';
import { Button, Tooltip } from '@pidedirecto/ui';
import { KeyIcon } from '@pidedirecto/ui/icons';
import moment from 'moment-timezone';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { app2 } from 'src/app2';
import { AssignCashRegisterForExternalMissingOrdersDialog } from 'src/components/pos/cashRegister/AssignCashRegisterForExternalMissingOrdersDialog';
import { Switch } from 'src/components/Switch';
import { history } from 'src/config/history';
import { RolePermissions } from 'src/constants/RolePermission';
import { RoutePaths } from 'src/constants/RoutePath';
import { translate } from 'src/i18n/translate';
import { useOpenCashRegister } from 'src/services/printer/utils/useOpenCashRegister';
import { useFormatAsRestaurantCurrencyNumber } from 'src/services/restaurant/useFormatAsRestaurantCurrencyNumber';
import { ManagerUserId } from 'src/types/Id';
import { CashRegisterPosBusinessDayVm } from 'src/types/PosBusinessDayVm';
import { useAction } from 'src/utils/react/useAction';
import { useSelector } from 'src/utils/react/useSelector';
import { useUserHasRolePermission } from 'src/utils/react/useUserHasRolePermissions';

export function PosOpenedCashRegisterSummary(): React.ReactElement | null {
    const classes = useStyles();
    const openCashRegister = useOpenCashRegister();
    const [userHasRolePermission] = useUserHasRolePermission();
    const formatAsCurrencyNumber = useFormatAsRestaurantCurrencyNumber();

    const currencyFormat = useSelector((state) => state.app.restaurant.currencyFormat);
    const openCashRegisterButtonEnabled = useSelector((state) => state.app.restaurant.openCashRegisterButtonEnabled);

    const assignCashRegisterForExternalMissingOrdersEnabled = useSelector((state) => state.app.restaurant.assignCashRegisterForExternalMissingOrdersEnabled);
    const openedCashRegister = useSelector((state) => state.pos.openedCashRegister);
    const openedPosBusinessDay = useSelector((state) => state.pos.openedPosBusinessDay);
    const openedCashRegisterPosBusinessDay = useSelector((state) => state.pos.openedCashRegisterPosBusinessDay);
    const pinCodeUserSignedIn = useSelector((state) => state.authentication.pinCodeUserSignedIn);
    const currentEmployeesClockedIn = useSelector((state) => state.app2.currentEmployeesClockedIn);

    const openVerifyPosPasswordDialog = useAction(app2.actions.openVerifyPosPasswordDialog);

    const [isForExternalMissingOrders, setIsForExternalMissingOrders] = useState(openedCashRegisterPosBusinessDay?.isForExternalMissingOrders ?? false);
    const [assignCashRegisterForMissingOrdersDialogState, setAssignCashRegisterForMissingOrdersDialogState] = useState<{ open: boolean; onSuccess: (() => void) | undefined }>({
        open: false,
        onSuccess: undefined,
    });

    const displaySalesInfo = userHasRolePermission(RolePermissions.MANAGE_CASH_REGISTERS);
    const hasSomeCashRegisterPosBusinessDayOpened = openedPosBusinessDay?.cashRegisterPosBusinessDays?.some(
        (cashRegisterPosBusinessDay: CashRegisterPosBusinessDayVm) =>
            !!cashRegisterPosBusinessDay.opened && cashRegisterPosBusinessDay.cashRegisterPosBusinessDayId !== openedCashRegisterPosBusinessDay?.cashRegisterPosBusinessDayId,
    );
    const isSameUserManagerId = openedCashRegister?.openedByManagerUserId === pinCodeUserSignedIn?.managerUserId;
    const hasDifferentOpeningHours = !!moment(openedCashRegisterPosBusinessDay?.openedAt ?? openedPosBusinessDay?.openedAt).diff(openedCashRegister?.openedAt, 'hours');
    const hasDifferentOpeningMinutes = !!moment(openedCashRegisterPosBusinessDay?.openedAt ?? openedPosBusinessDay?.openedAt).diff(openedCashRegister?.openedAt, 'minutes');
    const hasCashRegisterShift = hasDifferentOpeningHours || hasDifferentOpeningMinutes;
    const employeeClockedIn = isSameUserManagerId
        ? currentEmployeesClockedIn?.find((employee: { clockedInAt: Date; managerUserId: ManagerUserId }) => employee.managerUserId === pinCodeUserSignedIn?.managerUserId)
        : undefined;

    useEffect(() => {
        if (openedCashRegisterPosBusinessDay?.isForExternalMissingOrders) {
            setIsForExternalMissingOrders(openedCashRegisterPosBusinessDay?.isForExternalMissingOrders);
        }
    }, [openedCashRegisterPosBusinessDay]);

    const handleChangeCashRegisterForExternalMissingOrders = (isForExternalMissingOrders: any) => {
        if (isForExternalMissingOrders || !openedCashRegisterPosBusinessDay?.isForExternalMissingOrders) return;

        setAssignCashRegisterForMissingOrdersDialogState({
            open: true,
            onSuccess: () => {
                setIsForExternalMissingOrders(isForExternalMissingOrders);
                setAssignCashRegisterForMissingOrdersDialogState({ open: false, onSuccess: undefined });
            },
        });
    };

    const handleOpenCashRegister = () => {
        openVerifyPosPasswordDialog({
            onAuthorized: () => {
                openCashRegister();
            },
            onUnauthorized: () => alert(translate('Unauthorized to access.')),
        });
    };

    const handleOpenPosBusinessDayReports = () => {
        history.push({
            pathname: RoutePaths.RESTAURANT_POS_REPORT,
            search: history.location.search,
        });
    };

    if (!openedCashRegister) return null;

    return (
        <div className={classes.container}>
            <AssignCashRegisterForExternalMissingOrdersDialog
                open={assignCashRegisterForMissingOrdersDialogState.open}
                onClose={() => setAssignCashRegisterForMissingOrdersDialogState({ open: false, onSuccess: undefined })}
                onSuccess={assignCashRegisterForMissingOrdersDialogState.onSuccess}
            />
            <div className={classes.cashContainer}>
                <span className={classes.text}>{translate('Initial Amount')}</span>
                <span className={classes.cash}>{formatAsCurrencyNumber(openedCashRegister?.initialAmount)}</span>
            </div>
            <div className={classes.cashContainer}>
                <span className={classes.text}>{translate('Opening of the day')}</span>
                <span className={classes.cash}>{moment(openedPosBusinessDay?.openedAt).format('lll')}</span>
            </div>
            {hasCashRegisterShift && (
                <div className={classes.cashContainer}>
                    <span className={classes.text}>{translate('Last cash register shift')}</span>
                    <span className={classes.cash}>{moment(openedCashRegister?.openedAt).format('lll')}</span>
                </div>
            )}
            {openedCashRegister?.orders && (
                <div className={classes.cashContainer}>
                    <span className={classes.text}>{translate('Orders')}</span>
                    <span className={classes.cash}>{openedCashRegister.orders}</span>
                </div>
            )}
            {openedCashRegister?.totalSold && displaySalesInfo && (
                <div className={classes.cashContainer}>
                    <span className={classes.text}>{translate('Sales')}</span>
                    <span className={classes.cash}>{openedCashRegister.totalSold}</span>
                </div>
            )}
            {employeeClockedIn && (
                <div className={classes.cashContainer}>
                    <span className={classes.text}>{translate('Clock in time')}</span>
                    <span className={classes.cash}>{moment(employeeClockedIn?.clockedInAt).format('lll')}</span>
                </div>
            )}
            <div className={classes.endContent}>
                <a className={classes.link} onClick={handleOpenPosBusinessDayReports}>
                    {translate('Day End Reports')}
                </a>
            </div>
            {assignCashRegisterForExternalMissingOrdersEnabled && openedCashRegisterPosBusinessDay?.isForExternalMissingOrders && (
                <div className={classes.switchContainer}>
                    <Switch
                        disabled={!hasSomeCashRegisterPosBusinessDayOpened}
                        name={'isForExternalMissingOrders'}
                        value={isForExternalMissingOrders}
                        label={translate('Cash registers for external or auto accepted orders')}
                        onChange={handleChangeCashRegisterForExternalMissingOrders}
                    />
                    {<Tooltip text={translate('This cash register will be used to receive external or auto accepted orders')} />}
                </div>
            )}
            {openCashRegisterButtonEnabled && (
                <div className={classes.cashRegisterButtonContainer}>
                    <Tooltip text={translate('Open cash register')}>
                        <Button variant={'icon'} onClick={handleOpenCashRegister}>
                            <KeyIcon title={translate('Key')} />
                        </Button>
                    </Tooltip>
                </div>
            )}

            <div className={classes.line}></div>
        </div>
    );
}

const useStyles = makeStyles((theme) => ({
    switchContainer: {
        display: 'flex',
        gap: 20,
    },
    text: {
        fontSize: 12,
        color: theme.palette.text.primary,
        fontWeight: 400,
        [theme.breakpoints.up('sm')]: {
            fontSize: 14,
        },
    },
    cash: {
        fontSize: 12,
        fontWeight: 600,
        color: theme.palette.text.primary,
        [theme.breakpoints.up('sm')]: {
            fontSize: 14,
        },
    },
    cashContainer: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        margin: '10px 0px',
    },
    endContent: {
        display: 'flex',
        justifyContent: 'end',
        alignItems: 'center',
        margin: '10px 0px',
    },
    link: {
        fontSize: 14,
        textDecoration: 'underline',
        marginLeft: 'auto',
        fontFamily: theme.typography.regular,
        color: theme.palette.text.brand,
        cursor: 'pointer',
    },
    line: {
        width: '100%',
        height: 1,
        margin: '24px 0',
        borderTop: `1px solid ${theme.palette.border.primary}`,
    },
    container: {
        margin: '20px 0',
    },
    cashRegisterButtonContainer: {
        width: '100%',
        display: 'flex',
        justifyContent: 'flex-end',
    },
}));
