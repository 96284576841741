export const OrderIncidentTypes = {
    ORDER_NOT_RECEIVED: 'ORDER_NOT_RECEIVED',
    ORDER_NOT_RETURNED_TO_RESTAURANT: 'ORDER_NOT_RETURNED_TO_RESTAURANT',
    INCORRECT_ORDER: 'INCORRECT_ORDER',
    INCOMPLETE_ORDER: 'INCOMPLETE_ORDER',
    ORDER_CANCELLED_AND_DELIVERED: 'ORDER_CANCELLED_AND_DELIVERED',
    ORDER_CHANGE_STOLEN: 'ORDER_CHANGE_STOLEN',
    DAMAGED_ORDER_ITEM_RETURNED_TO_RESTAURANT: 'DAMAGED_ORDER_ITEM_RETURNED_TO_RESTAURANT',
    DAMAGED_ORDER_ITEMS: 'DAMAGED_ORDER_ITEMS',
    COURTESY: 'COURTESY',
    RESTAURANT_WASTE: 'RESTAURANT_WASTE',
    ORDER_DELIVERY_DELAYED: 'ORDER_DELIVERY_DELAYED',
    ORDER_REJECTED_BY_CUSTOMER: 'ORDER_REJECTED_BY_CUSTOMER',
    BUG: 'BUG',
    ADMIN_APPROVAL: 'ADMIN_APPROVAL',
    MONEY_RECOVERED_FROMM_DRIVER: 'MONEY_RECOVERED_FROMM_DRIVER',
} as const;

export type OrderIncidentType = (typeof OrderIncidentTypes)[keyof typeof OrderIncidentTypes];
