import { makeStyles } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import ClearIcon from '@material-ui/icons/Clear';
import * as React from 'react';
import { useEffect } from 'react';
import { useFieldArray, useFormContext } from 'react-hook-form';
import { FormCurrencyNumberStringField } from 'src/components/form/FormCurrencyNumberStringField';
import { FormHiddenField } from 'src/components/form/FormHiddenField';
import { FormModifiersAutocomplete } from 'src/components/form/FormModifiersAutocomplete';
import { FormModifierTypeSelect } from 'src/components/form/FormOptionItemTypeSelect';
import { FormTextField } from 'src/components/form/FormTextField';
import { ModifierTypes } from 'src/constants/ModifierType';
import { translate } from 'src/i18n/translate';
import { SubModifierGroup } from 'src/scenes/letseatmanager/deprecatedMenu/SubModifierGroup';
import type { DeprecatedModifierVm, DeprecatedSubModifierGroupVm } from 'src/types/DeprecatedManagerMenuItemVm';
import { ManagerMenuItemVm } from 'src/types/ManagerMenuItemVm';
import { useSelector } from 'src/utils/react/useSelector';

export function Modifier({ showHiddenSwitch, modifier, loading, modifierGroupIndex, modifierIndex, showRemoveButton, removeModifier, control, prevSubModifierGroups }: Props): React.ReactElement {
    const classes = useStyles();
    const theme = useTheme();
    const matchMediaQuery = useMediaQuery(theme.breakpoints.down('sm'));

    const { fields, append, remove } = useFieldArray({
        control,
        name: `modifierGroups.${modifierGroupIndex}.modifiers.${modifierIndex}.subModifierGroups`,
    });

    const { setValue, watch } = useFormContext();

    const subModifiersEnabled = useSelector((state) => state.app.restaurant.subModifiersEnabled);
    const modifiers = useSelector((state) => state.theMenu.modifiers);

    const modifierId = watch(`modifierGroups.${modifierGroupIndex}.modifiers.${modifierIndex}.modifierId`);
    const modifierName = watch(`modifierGroups.${modifierGroupIndex}.modifiers.${modifierIndex}.name`);

    const existingModifier = modifiers?.find((existingModifier: ManagerMenuItemVm) => existingModifier.menuItemId === modifierId);

    useEffect(() => {
        if (prevSubModifierGroups && fields.length !== prevSubModifierGroups?.length) {
            for (const modifierGroup of prevSubModifierGroups) {
                append(modifierGroup);
            }
        }
    }, [prevSubModifierGroups]);

    useEffect(() => {
        if (existingModifier) {
            setValue(`modifierGroups.${modifierGroupIndex}.modifiers.${modifierIndex}.name`, existingModifier?.name);
        }
    }, [existingModifier]);

    const renderSubModifierGroups = () => {
        const addNewSubModifierGroup = () => append({ name: '', requiredMin: null, requiredMax: null, subModifiers: [{ name: '', price: '', hidden: false }] });
        return (
            <div style={{ width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                {fields.map((subModifierGroup, subModifierGroupIndex) => (
                    <Grid container spacing={3} key={subModifierGroup.id}>
                        <Grid item xs={12}>
                            <SubModifierGroup
                                loading={loading}
                                modifierGroupIndex={modifierGroupIndex}
                                modifierIndex={modifierIndex}
                                subModifierGroupIndex={subModifierGroupIndex}
                                subModifierGroup={subModifierGroup as any}
                                removeSubModifierGroup={() => remove(subModifierGroupIndex)}
                                showHiddenSwitch={false}
                                control={control}
                                prevSubModifiers={prevSubModifierGroups?.[subModifierGroupIndex]?.subModifiers}
                            />
                        </Grid>
                    </Grid>
                ))}
                <div style={{ marginTop: 40 }}>
                    <Button onClick={addNewSubModifierGroup} disabled={loading}>
                        <div className={(classes as any).secondaryButton}>{translate('ADD SUB MODIFIER GROUP')}</div>
                    </Button>
                </div>
            </div>
        );
    };

    return (
        <Grid container alignItems='flex-start' spacing={3} style={{ marginBottom: 10 }}>
            <Grid item xs={12} className={classes.titleContainer}>
                <h3 className={classes.title}>{modifierName || translate('Select a modifier')}</h3>
                {!matchMediaQuery && (
                    <Grid item>
                        {showRemoveButton && (
                            <IconButton disabled={loading} aria-label='Remove' onClick={removeModifier}>
                                <ClearIcon />
                            </IconButton>
                        )}
                    </Grid>
                )}
            </Grid>
            <Grid item xs={6}>
                <FormModifiersAutocomplete
                    name={`modifierGroups.${modifierGroupIndex}.modifiers.${modifierIndex}.modifierId`}
                    label={translate('Modifier')}
                    required
                    disabled={loading}
                    defaultValue={modifier.modifierId}
                />
                <FormHiddenField name={`modifierGroups.${modifierGroupIndex}.modifiers.${modifierIndex}.name`} />
            </Grid>
            <Grid item xs={6}>
                <FormModifierTypeSelect
                    name={`modifierGroups.${modifierGroupIndex}.modifiers.${modifierIndex}.type`}
                    label={translate('Type')}
                    required
                    disabled={loading}
                    defaultValue={modifier.type ?? ModifierTypes.SINGLE}
                />
            </Grid>
            <Grid item xs={6}>
                <FormTextField
                    name={`modifierGroups.${modifierGroupIndex}.modifiers.${modifierIndex}.externalModifierId`}
                    label={translate('External Modifier Id')}
                    disabled={loading}
                    defaultValue={modifier.externalModifierId}
                />
            </Grid>
            {matchMediaQuery && showRemoveButton && (
                <Grid item xs={4}>
                    <IconButton disabled={loading} aria-label='Remove' onClick={removeModifier}>
                        <ClearIcon />
                    </IconButton>
                </Grid>
            )}
            <Grid item xs={6}>
                <FormCurrencyNumberStringField
                    name={`modifierGroups.${modifierGroupIndex}.modifiers.${modifierIndex}.price`}
                    label={translate('Price')}
                    disabled={loading}
                    defaultValue={modifier.price}
                    placeholder={modifier?.price}
                    helperText={translate('If you leave it empty will be used the price of the modifier')}
                />
            </Grid>
            {subModifiersEnabled && (
                <Grid item xs={12}>
                    {renderSubModifierGroups()}
                </Grid>
            )}
        </Grid>
    );
}

const useStyles = makeStyles((theme) => ({
    titleContainer: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        backgroundColor: '#F7F7F7',
        borderRadius: 8,
    },
    title: {
        margin: 0,
    },
    container: {
        [theme.breakpoints.down('sm')]: {
            flexDirection: 'column',
        },
    },
}));

type Props = {
    modifierIndex: number;
    modifier: DeprecatedModifierVm;
    loading: boolean;
    modifierGroupIndex: number;
    showRemoveButton?: boolean;
    removeModifier: any;
    showHiddenSwitch?: boolean;
    control: any;
    prevSubModifierGroups?: Array<DeprecatedSubModifierGroupVm>;
};
