import { MenuItemType, MenuItemTypes } from 'src/constants/DeprecatedMenuItemType';
import type { ExternalId } from 'src/types/Id';
import { csvToObjects } from 'src/utils/csv/csvToObjects';
import { removeNulls } from 'src/utils/removeNulls';
import { emptyStringToUndefined } from 'src/utils/string/emptyStringToUndefined';

export async function convertCsvToImportedMenu(file: string): Promise<Array<CsvMenuItem>> {
    const rows = await csvToObjects<CsvRow>(file);

    rows.shift();

    return rows.filter(hasRequiredColumns).map((row) => {
        const type = row['Tipo'].toLowerCase() === 'producto' ? MenuItemTypes.ITEM : MenuItemTypes.MODIFIER;
        const groups = row['Grupo'].split('-');
        const menuItemModifierGroups = row['Grupos de Modificadores'].split('-');
        return removeNulls({
            menuName: row['Nombre Menu'],
            menuItemName: row['Nombre Item'],
            type: type,
            categories: type === MenuItemTypes.ITEM ? groups : undefined,
            menuItemModifierGroups: type === MenuItemTypes.ITEM ? menuItemModifierGroups : undefined,
            modifierModifierGroups: type === MenuItemTypes.MODIFIER ? groups : undefined,
            description: emptyStringToUndefined(row['Descripcion']),
            price: emptyStringToUndefined(row['Precio']) ?? '0',
            promoPrice: emptyStringToUndefined(row['Precio Promocion']),
            kitchenPrice: emptyStringToUndefined(row['Precio Cocina']),
            imageUrl: emptyStringToUndefined(row['Imagen']),
            externalId: row['ID Producto Externo'],
            requiredMin: emptyStringToUndefined(row['Grupo Modificador Min']),
            requiredMax: emptyStringToUndefined(row['Grupo Modificador Max']),
        });
    });
}

function hasRequiredColumns(row: CsvRow): boolean {
    const requiredColumns = ['Nombre Menu', 'Nombre Item', 'Tipo', 'Grupo'];
    return requiredColumns.every((column) => !!row[column as keyof typeof row]);
}

type CsvRow = {
    ['Nombre Menu']: string;
    ['Nombre Item']: string;
    Tipo: string;
    Grupo: string;
    ['Grupos de Modificadores']: string;
    Descripcion: string;
    Precio: string;
    ['Precio Promocion']: string;
    ['Precio Cocina']: string;
    Imagen: string;
    ['ID Producto Externo']: ExternalId;
    ['Grupo Modificador Min']: string;
    ['Grupo Modificador Max']: string;
};

export type CsvMenuItem = {
    menuName: string;
    menuItemName: string;
    type: MenuItemType;
    categories?: Array<string>;
    menuItemModifierGroups?: Array<string>;
    modifierModifierGroups?: Array<string>;
    description?: string;
    price: string;
    promoPrice?: string;
    kitchenPrice?: string;
    imageUrl?: string;
    externalId?: ExternalId;
    requiredMin?: string;
    requiredMax?: string;
};
