import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
    emptyDescriptionContainer: {
        width: '70%',
        height: '80%',
        backgroundColor: 'white',
        borderRadius: 15,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
        border: `1px solid ${theme.palette.secondary.dark}`,
        [theme.breakpoints.down('sm')]: {
            width: '90%',
            height: '100%',
        },
    },
    orderMaxWidth: {
        maxWidth: '87vw',
    },
    orderSpacing: {
        marginBottom: 16,
    },
    container: {
        width: '100%',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    alertsContainer: {
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        alignItems: 'center',
    },
    alert: {
        width: '90%',
        marginBottom: theme.spacing(3),
    },
    alertTitle: {
        fontFamily: theme.typography.semiBold,
    },
    alertMessage: {
        fontFamily: theme.typography.medium,
        width: '100%',
    },
    alertMessageIntegration: {
        fontFamily: theme.typography.medium,
        width: 'fit-content',
        border: '1px solid #ddd',
        boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.25)',
        position: 'fixed',
        right: 70,
        bottom: 40,
        zIndex: 10,
    },
    emptyDescription: {
        fontFamily: theme.typography.medium,
        fontSize: 41,
        fontStyle: 'italic',
        marginBottom: 120,
        [theme.breakpoints.down('sm')]: {
            width: '80%',
            fontSize: 25,
            marginBottom: 30,
        },
    },
    button: {
        width: 250,
        height: 50,
        backgroundColor: theme.palette.primary.main,
        fontFamily: theme.typography.semiBold,
        color: 'white',
        fontSize: 13,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        borderRadius: 5,
    },
    ordersContainer: {
        width: '100%',
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap',
    },
    orderContainer: {
        display: 'flex',
        justifyContent: 'center',
        flexShrink: 0,
        margin: '0 10px',
        height: 'fit-content',
        width: 'calc(32% - 20px)',

        [theme.breakpoints.down('md')]: {
            width: 'calc(50% - 20px)',
        },
        [theme.breakpoints.down('sm')]: {
            width: 'calc(100% - 20px)',
            minWidth: 'calc(100% - 20px)',
        },
    },
    tableContainer: {
        position: 'relative',
        width: '100%',
        '& .MuiPaper-root': {
            boxShadow: '0px 0px',
            '& .MuiToolbar-root': {
                backgroundColor: theme.palette.secondary.light,
                paddingRight: 0,
                '& .MuiButtonBase-root': {
                    backgroundColor: 'white',
                    marginLeft: 5,
                    borderRadius: 5,
                    border: `1px solid ${theme.palette.secondary.dark}`,
                    '& svg': {
                        fill: theme.palette.primary.main,
                    },
                },
                '& .MuiButtonBase-root:hover': {
                    backgroundColor: theme.palette.primary.main,
                    '& svg': {
                        fill: 'white',
                    },
                },
            },
            '& .MuiTableFooter-root': {
                borderBottomLeftRadius: 5,
                borderBottomRightRadius: 5,
                border: `1px solid ${theme.palette.secondary.dark}`,
                borderTop: 0,
                borderCollapse: 'separate',
                '& p, div': {
                    fontFamily: theme.typography.semiBold,
                    fontSize: 13,
                },
                '& .MuiToolbar-root': {
                    backgroundColor: 'white',
                    '& .MuiButtonBase-root': {
                        backgroundColor: 'white',
                        marginRight: 5,
                        borderRadius: 5,
                        border: `0px`,
                        '& svg': {
                            fill: 'black',
                        },
                    },
                    '& .MuiButtonBase-root:hover': {
                        backgroundColor: 'white',
                        '& svg': {
                            fill: 'black',
                        },
                    },
                },
                '& td': {
                    border: 0,
                },
                '& td:hover': {
                    backgroundColor: 'white',
                },
            },
        },
        '& .MuiPaper-root > div:nth-child(3)': {
            borderTopLeftRadius: 5,
            borderTopRightRadius: 5,
            paddingTop: 10,
            border: `1px solid ${theme.palette.secondary.dark}`,
            borderBottom: 0,
            maxHeight: '90vh !important',
            '& th': {
                fontFamily: theme.typography.semiBold,
                backgroundColor: theme.palette.secondary.light,
                border: 0,
                fontSize: 13,
                color: 'gray',
            },
            '& td, .MuiTypography-root': {
                fontFamily: theme.typography.semiBold,
                border: 0,
                fontSize: 15,
            },
            '& tr': {
                borderBottom: `1px solid rgba(0,0,0,0.1)`,
            },
        },
        [theme.breakpoints.down('sm')]: {
            '& .MuiPaper-root': {
                backgroundColor: (theme.palette.background as any).light,
                '& .MuiToolbar-root': {
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    padding: '20px 0',
                    '& .MuiButtonBase-root': {
                        width: 'calc(85vw / 7)',
                        height: 'calc(85vw / 7)',
                    },

                    '& div:first-child': {
                        display: 'none',
                    },

                    '& div:nth-child(2)': {
                        '& > .MuiButtonBase-root:first-child': {
                            marginLeft: '0 !important',
                        },
                    },
                },
                '& .MuiTable-root': {
                    width: '90%',
                    margin: '0 auto',
                    '& .MuiTableFooter-root': {
                        backgroundColor: 'white',
                        '& .MuiToolbar-root > p:nth-child(2)': {
                            display: 'none',
                        },
                    },
                },
            },
            '& .MuiPaper-root > div:nth-child(3)': {
                width: '90%',
                margin: '0 auto',
                backgroundColor: 'white ',
            },
        },
    },
    table: {
        whiteSpace: 'nowrap',
        '& tr': { cursor: 'pointer' },
        '& tr:hover': { backgroundColor: 'rgba(0, 0, 0, 0.07) !important' },
    },
    icon: {
        color: theme.palette.primary.main,
        marginRight: 10,
    },
    openOrderButton: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        '& span': {
            fontFamily: theme.typography.medium,
            textTransform: 'none',
        },
    },
    highlighted: {
        color: theme.palette.primary.main,
    },
    red: {
        color: 'red',
    },
    orange: {
        color: 'rgb(245, 135, 66)',
    },
    yellow: {
        color: 'rgb(245, 212, 66)',
    },
    managerMessageContainer: {
        width: '100%',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
    },
    managerMessageUrl: {
        textDecoration: 'none',
        color: 'inherit',
    },
    restaurantNameContainer: {
        display: 'flex',
        flexDirection: 'column',
    },
    deliveryProviderText: {
        fontFamily: theme.typography.regular,
        fontSize: 12,
    },
}));
