import { letseatadminApiMethod } from 'src/api/letseatadminApi';
import type { ApiSauceResponse } from 'src/api/types/ApiSauceResponse';
import type { RestaurantReferralVm } from 'src/types/RestaurantReferralVm';

export async function changeRestaurantReferralMessagesApi(request: ChangeRestaurantReferralMessagesApiRequest): ApiSauceResponse<RestaurantReferralVm> {
    return letseatadminApiMethod('restaurantReferral/changeRestaurantReferralMessagesApi', request);
}

export type ChangeRestaurantReferralMessagesApiRequest = {
    restaurantReferralMessageTitle?: string;
    restaurantReferralMessageBody?: string;
};
