import { history } from 'src/config/history';
import { RoutePaths } from 'src/constants/RoutePath';
import type { SubscriptionHistoryId } from 'src/types/Id';
import { RegExps } from 'src/utils/RegExps';

export function openSubscriptionHistoryPage(subscriptionHsitoryId?: SubscriptionHistoryId): void {
    if (!subscriptionHsitoryId) return;
    history.push({
        pathname: RoutePaths.ADMIN_SUBSCRIPTION_HISTORY.replace(`:subscriptionHistoryId(${RegExps.uuid})`, subscriptionHsitoryId),
        search: history.location.search,
    });
}
