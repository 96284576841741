import { Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { BeakerIcon } from '@pidedirecto/ui/icons';
import * as React from 'react';
import { translate } from 'src/i18n/translate';

export function MenuItemModifierGroupsEmptyState(): React.ReactElement {
    const classes = useStyles();

    return (
        <Grid className={classes.menuItemModifierGroupsEmptyState}>
            <div className={classes.circularContainer}>
                <BeakerIcon />
            </div>
            <p className={classes.text}>{translate('In this section you can manage the different modifiers of this menuItem')}</p>
        </Grid>
    );
}

const useStyles = makeStyles((theme) => ({
    menuItemModifierGroupsEmptyState: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: '100%',
        height: 380,
        flexDirection: 'column',
    },
    circularContainer: {
        display: 'grid',
        placeItems: 'center',
        width: 100,
        height: 100,
        borderRadius: '50%',
        backgroundColor: `${theme.palette.surface.brand}63`,
        color: theme.palette.icons.brand,
    },
    text: {
        fontFamily: theme.typography.regular,
        fontSize: 14,
        lineHeight: 1.6,
        textAlign: 'center',
        color: '#586262',
        marginTop: 24,
        maxWidth: 320,
    },
}));
