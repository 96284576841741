import { createContext, useEffect, useState } from 'react';
import * as React from 'react';
import { Notification } from 'src/components/Notification';

export function NotificationsProvider({ children }: Props): React.ReactElement {
    const [notifications, setNotifications] = useState<Array<{ open: boolean; message: string }>>([]);

    useEffect(() => {
        openClosedNotifications();
    }, [notifications]);

    const openClosedNotifications = () => {
        const existsClosedNotifications = notifications.some((notification) => !notification.open);
        if (!existsClosedNotifications) return;

        setNotifications(
            notifications.map((notification) => {
                if (notification.open) return notification;
                return {
                    ...notification,
                    open: true,
                };
            }),
        );
    };

    const addNotification = (newNotification: any) => {
        setNotifications([{ ...newNotification, open: false }]);
    };

    return (
        <NotificationContext.Provider value={{ notification: addNotification }}>
            {notifications.map((notification, idx) => (
                <Notification key={notification.message + Date.now().toString()} open={notification.open}>
                    {notification.message}
                </Notification>
            ))}
            {children}
        </NotificationContext.Provider>
    );
}

export const NotificationContext = createContext<{ notification: any }>({ notification: () => {} });

type Props = {
    children: React.ReactNode;
};
