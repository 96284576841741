import { makeStyles } from '@material-ui/core/styles';
import moment from 'moment';
import { useState } from 'react';
import * as React from 'react';
import { findCurrentRestaurantEmployeesClockedInApi } from 'src/api/letseatmanager/employeeDayShift/findCurrentRestaurantEmployeesClockedInApi';
import { Button } from 'src/components/Button';
import { GoBackButton } from 'src/components/button/GoBackButton';
import { SimpleTable } from 'src/components/SimpleTable';
import { Tooltip } from 'src/components/Tooltip';
import { translate } from 'src/i18n/translate';
import { posReducer } from 'src/reducers/posReducer';
import { ClockOutEmployeeButton } from 'src/scenes/letseatmanager/posCashRegister/ClockOutEmployeeButton';
import { EmployeeClockedInCard } from 'src/scenes/letseatmanager/posCashRegister/EmployeeClockedInCard';
import { LocalizationService } from 'src/services/LocalizationService';
import type { RestaurantEmployeeDayShiftVm } from 'src/types/RestaurantEmployeeDayShiftVm';
import { useAction } from 'src/utils/react/useAction';
import { useClock } from 'src/utils/react/useClock';
import { useLoadApi } from 'src/utils/react/useLoadApi';
import { useSelector } from 'src/utils/react/useSelector';

export function EmployeesClockedInReport({ onContinue }: Props): React.ReactElement {
    const classes = useStyles();
    const [, date] = useClock();

    const [clockOutAtDate, setClockOutAtDate] = useState();

    const restaurantId = useSelector((state) => state.app.restaurantId);
    const timeZone = useSelector((state) => state.app.restaurant.timeZone);
    const pinCodeUserSignedIn = useSelector((state) => state.authentication.pinCodeUserSignedIn);

    const closeCashRegisterReport = useAction(posReducer.actions.closeCashRegisterReport);

    const [, employeesClockedIn] = useLoadApi(findCurrentRestaurantEmployeesClockedInApi, { restaurantId }, { dependencies: [restaurantId], initialValue: [] });

    const employeesClockedInFiltered = employeesClockedIn?.filter((employee: RestaurantEmployeeDayShiftVm) => employee.managerUserId !== pinCodeUserSignedIn.managerUserId);

    const columns = [
        {
            id: 'name',
            content: translate('Name'),
        },
        {
            id: 'roleName',
            content: translate('Role'),
        },
        {
            id: 'hours',
            content: translate('Hours'),
        },
        {
            id: 'clockedInAt',
            content: translate('Clock in at'),
        },
        {
            id: 'clockedOutAt',
            content: translate('Clock out at'),
        },
    ];

    return (
        <section className={classes.container}>
            <GoBackButton classes={{ button: classes.button }} goBack={closeCashRegisterReport} />
            <section className={classes.content}>
                <section className={classes.textContainer}>
                    {!!employeesClockedInFiltered?.length && (
                        <div>
                            <h2 className={classes.mainText}>{translate('You have employees who have not clocked out')}</h2>
                            <p className={classes.subText}>{translate('Confirm their clock out time and continue with your closing day')}</p>
                        </div>
                    )}
                    {!employeesClockedInFiltered?.length && <h2 className={classes.mainText}>{translate('All of your employees have clocked out')}</h2>}
                    <Button onClick={onContinue} classes={{ button: classes.primaryButton }}>
                        {translate('Continue')}
                    </Button>
                </section>
                <div className={classes.tableContainer}>
                    {!!employeesClockedInFiltered?.length && (
                        <div>
                            {employeesClockedInFiltered?.map((employee: RestaurantEmployeeDayShiftVm) => <EmployeeClockedInCard employeeDayShift={employee} />)}
                            <SimpleTable
                                classes={{ table: classes.table }}
                                columns={columns}
                                rows={
                                    employeesClockedInFiltered?.map((employee: RestaurantEmployeeDayShiftVm) => {
                                        const workedHours = Math.abs(moment(employee?.clockedInAt).diff(clockOutAtDate ?? date, 'hours')).toString();
                                        return {
                                            name: employee.username,
                                            roleName: employee.roleName ?? '',
                                            hours: <Tooltip text={translate('Hours considering the current time')}>{workedHours}</Tooltip>,
                                            clockedInAt: LocalizationService.formatDateTimeAsTime(employee.clockedInAt, timeZone),
                                            clockedOutAt: <ClockOutEmployeeButton employeeDayShift={employee} onChange={(date: any) => setClockOutAtDate(date)} />,
                                        };
                                    }) ?? []
                                }
                            />
                        </div>
                    )}
                </div>
            </section>
        </section>
    );
}

const useStyles = makeStyles((theme) => ({
    tableContainer: {
        width: '100%%',
        [theme.breakpoints.up('md')]: {
            width: '75%',
        },
    },
    table: {
        display: 'none',
        [theme.breakpoints.up('md')]: {
            display: 'flex',
        },
    },
    container: {
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        margin: '100px 0',
        [theme.breakpoints.up('md')]: {
            margin: '40px 0',
        },
    },
    textContainer: {
        width: '100%%',
        [theme.breakpoints.up('md')]: {
            width: '25%',
        },
    },
    content: {
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        padding: '0px 10px',
        [theme.breakpoints.up('md')]: {
            flexDirection: 'row',
        },
        gap: 24,
        marginTop: 40,
    },
    inputContainer: {
        display: 'flex',
        alignItems: 'center',
    },
    mainText: {
        marginTop: 0,
        color: theme.palette.text.brand,
        fontSize: 24,
        fontFamily: theme.typography.semiBold,
    },
    subText: {
        fontFamily: theme.typography.regular,
        color: theme.palette.text.secondary,
        fontSize: 16,
    },
    button: {
        color: theme.palette.text.primary,
        top: 100,
        left: 30,
    },
    primaryButton: {
        width: '100%',
        height: 25,
        padding: '18px 10px',
        fontSize: 14,
        color: theme.palette.text.invert,
    },
    iconButton: {
        margin: 0,
        padding: 0,
    },
}));

type Props = {
    onContinue: any;
};
