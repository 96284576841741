import type { BannerVm } from 'src/api/letseatmanager/banner/findBannersApi';
import { letseatmanagerApiMethod } from 'src/api/letseatmanagerApi';
import type { ApiSauceResponse } from 'src/api/types/ApiSauceResponse';
import type { BannerId, RestaurantId } from 'src/types/Id';

export async function getBannerApi(request: GetBannerApiRequest): ApiSauceResponse<BannerVm> {
    return letseatmanagerApiMethod('banner/getBannerApi', request);
}

export type GetBannerApiRequest = {
    restaurantId: RestaurantId;
    bannerId?: BannerId;
};
