import { letseatmanagerApiMethod } from 'src/api/letseatmanagerApi';
import type { ApiSauceResponse } from 'src/api/types/ApiSauceResponse';
import type { ManagerUserId, RestaurantId } from 'src/types/Id';

export async function removeRestaurantUserApi(request: RemoveRestaurantUserApiRequest): ApiSauceResponse<void> {
    return letseatmanagerApiMethod('userManagement/removeRestaurantUserApi', request);
}

type RemoveRestaurantUserApiRequest = {
    restaurantId: RestaurantId;
    managerUserId?: ManagerUserId;
};
