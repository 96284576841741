import { makeStyles } from '@material-ui/core';
import { Loader } from '@pidedirecto/ui';
import moment from 'moment/moment';
import * as React from 'react';
import { getHeatMapDeliveryOrdersReportApi } from 'src/api/letseatmanager/restaurantDashboard/getHeatMapDeliveryOrdersReportApi';
import { translate } from 'src/i18n/translate';
import { useFormatDateToRestaurantTimeZone } from 'src/utils/react/useFormatDateToRestaurantTimeZone';
import { useLoadApi } from 'src/utils/react/useLoadApi';
import { useSelector } from 'src/utils/react/useSelector';

export function HeatmapGraph(): React.ReactElement | null {
    const classes = useStyles();

    const restaurantIds = useSelector((state) => state.app.restaurantIds);
    const period = useSelector((state) => state.app2.filterReportsState.period);

    const formatDateToRestaurantTimeZone = useFormatDateToRestaurantTimeZone();

    const [heatMapLoading, heatMapReport] = useLoadApi(
        getHeatMapDeliveryOrdersReportApi,
        {
            restaurantIds,
            fromDate: moment(formatDateToRestaurantTimeZone(period?.from)).toISOString(),
            toDate: moment(formatDateToRestaurantTimeZone(period?.to)).toISOString(),
        },
        { dependencies: [restaurantIds, period] },
    );

    const getHeatmap = () => {
        if (!heatMapReport?.orderHeatmapIframe) return '';
        const blob = new Blob([heatMapReport?.orderHeatmapIframe], { type: 'text/html' });
        const url = URL.createObjectURL(blob);
        return url;
    };

    if (heatMapLoading) return <Loader loading={heatMapLoading} text={translate('Loading...')} />;

    return (
        <div className={classes.chartsContainer}>
            <div className={classes.titleContainer}>
                <h2 className={classes.title}>{translate('Order Heatmap')}</h2>
            </div>
            <iframe className={classes.heatmapContainer} src={getHeatmap()} />
        </div>
    );
}

const useStyles = makeStyles((theme) => ({
    chartsContainer: {
        display: 'flex',
        flexDirection: 'column',
    },
    titleContainer: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        width: '100%',
        justifyContent: 'space-between',
    },
    title: {
        fontFamily: theme.typography.medium,
        color: theme.palette.text.primary,
    },
    heatmapContainer: {
        width: '100%',
        minHeight: '70vh',
    },
}));
