import { letseatadminApiMethod } from 'src/api/letseatadminApi';
import type { ApiSauceResponse } from 'src/api/types/ApiSauceResponse';
import type { DriverId } from 'src/types/Id';
import { isProductionEnvironment } from 'src/utils/environment/isProductionEnvironment';

export async function hardDeleteDriverApi(request: HardDeleteDriverApiRequest): ApiSauceResponse<void> {
    if (isProductionEnvironment()) throw Error('This is an api only for development and testing environments');
    return letseatadminApiMethod('development/hardDeleteDriverApi', request);
}

export type HardDeleteDriverApiRequest = {
    driverId: DriverId;
};
