import { makeStyles } from '@material-ui/core';
import LinearProgress from '@material-ui/core/LinearProgress';
import { useTheme } from '@material-ui/core/styles';
import * as React from 'react';
import { useLocation } from 'react-router-dom';
import { app2 } from 'src/app2';
import { AppBarTitle } from 'src/components/app/appBar/AppBarTitle';
import { RightToolBar } from 'src/components/app/appBar/RightToolBar';
import { SubscriptionBanner } from 'src/components/app/appBar/SubscriptionBanner';
import { SubscriptionPayPerUseLimitReachedBanner } from 'src/components/app/appBar/SubscriptionPayPerUseLimitReachedBanner';
import { Button } from 'src/components/Button';
import { RoutePaths } from 'src/constants/RoutePath';
import { MenuBurgerIcon } from 'src/icons/MenuBurgerIcon';
import { classNames } from 'src/utils/react/classNames';
import { useAction } from 'src/utils/react/useAction';
import { useSelector } from 'src/utils/react/useSelector';
import { isRestaurantUser } from 'src/utils/user/isRestaurantUser';

export function AppBar(): React.ReactElement | null {
    const classes = useStyles();
    const location = useLocation();
    const theme = useTheme();

    const kitchensUser = useSelector((state) => state.authentication.kitchensUser);
    const internalUser = useSelector((state) => state.authentication.internalUser);
    const kioskUser = useSelector((state) => state.authentication.kioskUser);
    const signedIn = useSelector((state) => state.authentication.signedIn);
    const progressBar = useSelector((state) => state.app.progressBar);
    const pinCodeUserSignedIn = useSelector((state) => state.authentication.pinCodeUserSignedIn);
    const remainingDays = useSelector((state) => state.app2.remainingDays);
    const userManagementEnabled = useSelector((state) => state.app.restaurant?.userManagementEnabled);
    const subscriptionAccess = useSelector((state) => state.app2.subscriptionAccess);
    const payPerUsePaymentAmountLimitReached = useSelector((state) => state.app2.payPerUsePaymentAmountLimitReached);
    const userType = useSelector((state) => state.authentication.userType);

    const openAppMenu = useAction(app2.actions.openAppMenu);

    const posLocation = location.pathname === RoutePaths.POS || location.pathname === RoutePaths.PIN_CODE_USER_LOGIN;

    const userToShowTheAppBar = internalUser || kitchensUser;
    const hideAppBarForPosLockScreen = userManagementEnabled && posLocation && !pinCodeUserSignedIn && !userToShowTheAppBar && isRestaurantUser(userType);
    const showSubscriptionBanner = signedIn && !kioskUser && subscriptionAccess && remainingDays > 0 && remainingDays <= 15;
    const showSubscriptionPayPerUseLimitReachedBanner = signedIn && !kioskUser && subscriptionAccess && payPerUsePaymentAmountLimitReached;

    if (!signedIn || kioskUser || hideAppBarForPosLockScreen) return null;

    if (location.pathname === RoutePaths.KITCHEN_DISPLAY_SCREEN) {
        return (
            <>
                {signedIn && (
                    <Button classes={{ button: classNames(classes.kitchenMenuIcon, classes.fixedMenu) }} onClick={openAppMenu} icon data-testid='appBar.appMenuButton'>
                        <MenuBurgerIcon />
                    </Button>
                )}
            </>
        );
    }

    return (
        <nav className={classes.appBarNav}>
            <div className={classNames(classes.appBar)}>
                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: 12 }}>
                    {signedIn && (
                        <Button onClick={openAppMenu} classes={{ button: classes.menuIcon }} icon data-testid='appBar.appMenuButton'>
                            <MenuBurgerIcon title='menu icon' color={theme.palette.icons.brand} />
                        </Button>
                    )}
                    <AppBarTitle />
                </div>
                <RightToolBar />
            </div>
            {showSubscriptionBanner && <SubscriptionBanner />}
            {showSubscriptionPayPerUseLimitReachedBanner && <SubscriptionPayPerUseLimitReachedBanner />}
            {progressBar?.visible && (
                <LinearProgress
                    variant={progressBar.variant as any}
                    style={{
                        position: 'absolute',
                        bottom: 0,
                        left: 0,
                        right: 0,
                    }}
                />
            )}
        </nav>
    );
}

const useStyles = makeStyles((theme) => ({
    kitchenMenuIcon: {
        position: 'fixed',
        top: 12,
        left: 26,
        height: 'unset',
        width: 'unset',
        padding: 0,
        zIndex: 1200,
    },
    menuIcon: {
        height: 'unset',
        width: 'unset',
        padding: 0,
    },
    fixedMenu: {
        filter: 'drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25))',
    },
    appBarNav: {
        position: 'absolute',
        zIndex: 1000,
    },
    content: {
        width: '100%',
        overflowX: 'hidden',
        overflowY: 'auto',
    },
    appBar: {
        color: theme.palette.text.primary,
        backgroundColor: theme.palette.surface.primary,
        width: '100vw',
        height: '100%',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        padding: `${theme.spacing(1.5)}px ${theme.spacing(5)}px`,
        boxShadow: '0 4px 8px rgba(0, 0, 0, 0.12)',
        [theme.breakpoints.down('sm')]: {
            paddingLeft: theme.spacing(2.5),
            paddingRight: theme.spacing(2.5),
        },
    },
    title: {
        fontFamily: theme.typography.medium,
        color: theme.palette.secondary.contrastText,
        fontSize: 20,
        marginRight: 10,
        [theme.breakpoints.down('sm')]: {
            display: 'none',
        },
    },
    pinCodeInfo: {
        [theme.breakpoints.up('sm')]: {
            marginLeft: 30,
        },
    },
    posRestaurantName: {
        [theme.breakpoints.down('sm')]: {
            fontSize: 18,
            maxWidth: 160,
        },
        fontFamily: theme.typography.semiBold,
        fontSize: 20,
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
    },
    posUserName: {
        display: 'flex',
        alignContent: 'center',
        gap: 10,
        fontFamily: theme.typography.medium,
        fontSize: 14,
    },
    navIconHide: {
        marginRight: 0,
        [theme.breakpoints.up('sm')]: {
            marginRight: 16,
        },
        [theme.breakpoints.up('md')]: {
            backgroundColor: theme.palette.primary.main,
        },
    },
    posNavIconHide: {
        marginRight: 0,
        [theme.breakpoints.up('sm')]: {
            marginRight: 20,
        },
        [theme.breakpoints.up('md')]: {
            backgroundColor: 'white',
        },
    },
    navIcon: {
        color: theme.palette.primary.main,
        [theme.breakpoints.up('md')]: {
            color: 'white',
        },
    },
    posNavIcon: {
        color: 'white',
        [theme.breakpoints.up('md')]: {
            color: theme.palette.primary.main,
        },
    },
    icon: {
        color: theme.palette.text.brand,
    },
    posIcon: {
        color: 'white',
    },
}));
