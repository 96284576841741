import { useEffect } from 'react';
import { findRestaurantPaymentMethodsApi } from 'src/api/letseatmanager/restaurantPaymentMethod/findRestaurantPaymentMethodsApi';
import { Apps } from 'src/constants/App';
import { WebSocketEventTypes } from 'src/constants/WebSocketEventType';
import { translate } from 'src/i18n/translate';
import { useNotification } from 'src/services/notification/useNotification';
import { usePosPaymentMethodsActions, usePosPaymentMethodsStore } from 'src/services/pos/posPaymentMethods/posPaymentMethodsStore';
import type { RestaurantId } from 'src/types/Id';
import { RestaurantPaymentMethodVm } from 'src/types/RestaurantPaymentMethodVm';
import type { WebSocketEvent } from 'src/types/WebSocketEvent';
import { alertKnownErrorOrSomethingWentWrong } from 'src/utils/alert/alertKnownErrorOrSomethingWentWrong';
import { deepEqual } from 'src/utils/object/deepEqual';
import { useSelector } from 'src/utils/react/useSelector';
import { WebSocketEvents } from 'src/utils/webSocket/WebSocketEvents';

export function usePosPaymentsSubscriptionUpdates() {
    const notification = useNotification();

    const restaurantId = useSelector((state) => state.app.restaurantId);
    const restaurantPaymentMethodsEnabled = useSelector((state) => state.pos.context?.restaurantPaymentMethodsEnabled);

    const posPaymentMethods = usePosPaymentMethodsStore((state) => state.posPaymentMethods);
    const setPosPaymentMethods = usePosPaymentMethodsActions((actions) => actions.setPosPaymentMethods);

    useEffect(() => {
        if (!restaurantPaymentMethodsEnabled) return;
        const paymentMethodAddedSyncWebSocketEvent = WebSocketEvents.addEventListener(WebSocketEventTypes.RESTAURANT_PAYMENT_METHOD_REMOVED, handlePaymentMethodsWebSocketEvents);
        const paymentMethodRemovedSyncWebSocketEvent = WebSocketEvents.addEventListener(WebSocketEventTypes.RESTAURANT_PAYMENT_METHOD_REMOVED, handlePaymentMethodsWebSocketEvents);

        return () => {
            paymentMethodAddedSyncWebSocketEvent.remove();
            paymentMethodRemovedSyncWebSocketEvent.remove();
        };
    }, [restaurantId]);

    const loadPosPaymentRestaurants = async () => {
        const response = await findRestaurantPaymentMethodsApi({ restaurantId });
        if (!response.ok) {
            alertKnownErrorOrSomethingWentWrong(response);
            return;
        }
        setPosPaymentMethods(response.data);
    };

    const handlePaymentMethodsWebSocketEvents = async (event: WebSocketEvent<PaymentMethodsWebSocketEventResponse>) => {
        if (restaurantId !== event.data?.restaurantId) return;
        if (!!event.data?.paymentMethods && hasPosPaymentMethodsChanges(event.data?.paymentMethods, posPaymentMethods)) {
            notification({ message: translate('Synchronizing payment methods') });
            await loadPosPaymentRestaurants();
        }
    };

    const hasPosPaymentMethodsChanges = (newPaymentMethods: Array<RestaurantPaymentMethodVm> | null | undefined, currentPaymentMethods: Array<RestaurantPaymentMethodVm>) => {
        const newPosPaymentMethods = newPaymentMethods?.filter((payment: RestaurantPaymentMethodVm) => payment.channels?.includes(Apps.PIDEDIRECTOPOS));

        if (!newPosPaymentMethods?.length && !!currentPaymentMethods?.length) return true;

        return !deepEqual(currentPaymentMethods, newPosPaymentMethods);
    };
}

type PaymentMethodsWebSocketEventResponse = {
    restaurantId: RestaurantId;
    paymentMethods?: Array<RestaurantPaymentMethodVm>;
};
