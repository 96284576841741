import { makeStyles } from '@material-ui/core';
import { BigNumber } from 'bignumber.js';
import * as React from 'react';
import { BarChartCanvas } from 'src/components/charts/BarChartCanvas';
import { Text } from 'src/components/Text';
import { translate } from 'src/i18n/translate';
import type { FunnelResults } from 'src/types/GeneralEcommerceReportVm';

export function ConversionPercentageReport({ conversionPercentageReport }: Props): React.ReactElement {
    const classes = useStyles();

    const { userOpenedPidedirectoWeb, userAddedItemToCart, userSignedInToPidedirecto, userOpenedCart, userCreatedOrder } = conversionPercentageReport;

    const formatAsNumber = (value: string) => BigNumber(value).toNumber();

    const percentagesFromPreviousStep = [
        0,
        userAddedItemToCart.percentageFromPreviousStep,
        userSignedInToPidedirecto.percentageFromPreviousStep,
        userOpenedCart.percentageFromPreviousStep,
        userCreatedOrder.percentageFromPreviousStep,
    ];

    const totalPercentages = [0, userAddedItemToCart.totalPercentage, userSignedInToPidedirecto.totalPercentage, userOpenedCart.totalPercentage, userCreatedOrder.totalPercentage];

    const data = [
        {
            id: translate('User opened Pidedirecto Web'),
            totalUsers: formatAsNumber(userOpenedPidedirectoWeb),
        },
        {
            id: translate('User added item to cart'),
            totalUsers: formatAsNumber(userAddedItemToCart.totalUsers),
        },
        {
            id: translate('User signed in to Pidedirecto'),
            totalUsers: formatAsNumber(conversionPercentageReport.userSignedInToPidedirecto.totalUsers),
        },
        {
            id: translate('User opened cart'),
            totalUsers: formatAsNumber(userOpenedCart.totalUsers),
        },
        {
            id: translate('User created order'),
            totalUsers: formatAsNumber(userCreatedOrder.totalUsers),
        },
    ];

    return (
        <div className={classes.container}>
            <Text style={{ fontSize: 16 }} variant='semiBold'>
                {translate('Conversion Funnel')}
            </Text>
            <div className={classes.chartContainer}>
                <BarChartCanvas
                    data={data}
                    indexBy={'id'}
                    keys={['totalUsers']}
                    classes={{ container: classes.barChart }}
                    margin={{ top: 20, left: 50, bottom: 95 }}
                    tickRotation={-60}
                    labelTextColor={'#FFFFFF'}
                    tooltip={({ id, value, index }: { id: never; value: string; index: number }) => {
                        return (
                            <div className={classes.tooltip}>
                                <Text>
                                    {translate('Total users')}: <strong>{value}</strong>
                                </Text>
                                {index !== 0 && (
                                    <Text>
                                        {translate('Percentage from previous step')}: <strong>{percentagesFromPreviousStep[index]}%</strong>
                                    </Text>
                                )}
                                {index !== 0 && (
                                    <Text>
                                        {translate('Total percentage')}: <strong>{totalPercentages[index]}%</strong>
                                    </Text>
                                )}
                                <Text></Text>
                            </div>
                        );
                    }}
                />
            </div>
        </div>
    );
}

const useStyles = makeStyles((theme) => ({
    container: {
        display: 'flex',
        flexDirection: 'column',
        gap: 20,
        backgroundColor: 'white',
        borderRadius: 12,
        padding: 24,
        border: `1px solid ${theme.palette.border.primary}`,
        width: '100%',
    },
    chartContainer: {
        height: 450,
    },
    barChart: {
        height: '100%',
    },
    tooltip: {
        padding: 10,
        borderRadius: 4,
        backgroundColor: 'white',
        boxShadow: '1px 1px 1px rgb(0,0,0,0.2)',
    },
    tooltipTitle: {
        fontFamily: theme.typography.bold,
    },
    tooltipText: {
        fontFamily: theme.typography.regular,
    },
}));

type Props = {
    conversionPercentageReport: {
        userOpenedPidedirectoWeb: string;
        userAddedItemToCart: FunnelResults;
        userSignedInToPidedirecto: FunnelResults;
        userOpenedCart: FunnelResults;
        userCreatedOrder: FunnelResults;
    };
};
