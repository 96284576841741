import { makeStyles } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import CloseIcon from '@material-ui/icons/Close';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { useFieldArray, useForm, useWatch } from 'react-hook-form';
import { changeSupplyApi } from 'src/api/letseatmanager/supply/changeSupplyApi';
import { getSupplyApi } from 'src/api/letseatmanager/supply/getSupplyApi';
import { Button } from 'src/components/Button';
import { Dialog } from 'src/components/Dialog';
import { DialogActions } from 'src/components/DialogActions';
import { Form } from 'src/components/form/Form';
import { FormCheckbox } from 'src/components/form/FormCheckbox';
import { FormCurrencyNumberStringField } from 'src/components/form/FormCurrencyNumberStringField';
import { FormNumberField } from 'src/components/form/FormNumberField';
import { FormNumberStringField } from 'src/components/form/FormNumberStringField';
import { FormSupplierAutocomplete } from 'src/components/form/FormSupplierAutocomplete';
import { FormSupplyCategorySelect } from 'src/components/form/FormSupplyCategorySelect';
import { FormSupplyTypeSelect } from 'src/components/form/FormSupplyTypeSelect';
import { FormTextField } from 'src/components/form/FormTextField';
import { FormUnitOfMeasurementSelect } from 'src/components/form/FormUnitOfMeasurementSelect';
import { translate } from 'src/i18n/translate';
import { CompoundSupplyIngredients } from 'src/scenes/letseatmanager/inventory/supply/CompoundSupplyIngredients';
import { useNotification } from 'src/services/notification/useNotification';
import { SupplierId, type SupplyId } from 'src/types/Id';
import { Ingredient } from 'src/types/RecipeVm';
import { SupplierVm } from 'src/types/SupplyVm';
import { alertKnownErrorOrSomethingWentWrong } from 'src/utils/alert/alertKnownErrorOrSomethingWentWrong';
import { calculateProductionFixedCost } from 'src/utils/inventory/supply/calculateProductionFixedCost';
import { isCompoundSupply } from 'src/utils/inventory/supply/isCompoundSupply';
import { isNormalSupply } from 'src/utils/inventory/supply/isNormalSupply';
import { useLoadApi } from 'src/utils/react/useLoadApi';
import { useSelector } from 'src/utils/react/useSelector';
import { requireValue } from 'src/utils/require/requireValue';

export function ChangeSupplyDialog({ open, supplyId, onClose, onChangeSupply }: Props): React.ReactElement | null {
    const form = useForm();
    const notification = useNotification();

    const {
        formState: { isSubmitting },
        control,
    } = form;
    const classes = useStyles();
    const { fields, append, remove } = useFieldArray({
        control,
        name: 'buyUnits',
    });

    const supplies = useSelector((state) => state.inventory.supplies);

    const [suppliers, setSuppliers] = useState<Array<SupplierVm>>([]);
    const [currentSupplier, setCurrentSupplier] = useState<SupplierVm | undefined>(undefined);
    const [ingredients, setIngredients] = useState<Array<Ingredient>>([]);

    const supplyLimitAlertEnabled = useWatch<boolean>({ name: 'supplyLimitAlertEnabled', control });
    const isConsumable = useWatch<boolean>({ name: 'isConsumable', control });
    const buyUnits = form.watch('buyUnits');
    const produceAfterSave = form.watch('produceAfterSave');

    const [loading, supply] = useLoadApi(getSupplyApi, { supplyId: supplyId! }, { requiredValues: [supplyId], dependencies: [supplyId] });

    const mainUnit = buyUnits?.find((buyUnit: any) => buyUnit.isMainUnit);
    const productionFixedCost = calculateProductionFixedCost(ingredients ?? []);

    useEffect(() => {
        if (!supply) return;
        setIngredients(supply.ingredients!);
        setSuppliers(supply.suppliers!);
        form.reset({
            name: supply.name,
            type: supply.type,
            quantity: supply.quantity,
            sellUnit: supply.sellUnit,
            buyUnits: supply.buyUnits,
            supplyCategoryId: supply.supplyCategoryId,
            sku: supply.sku,
            fixedCost: supply.fixedCost,
            minimumQuantity: supply.minimumQuantity,
            supplyLimitAlertEnabled: supply.minimumQuantity ? true : false,
        });

        supply.buyUnits.forEach((buyUnit, index) => {
            form.setValue(`buyUnits.${index}.unit`, buyUnit.unit);
            form.setValue(`buyUnits.${index}.conversionFactor`, buyUnit.conversionFactor);
            form.setValue(`buyUnits.${index}.isMainUnit`, buyUnit.isMainUnit);
        });
    }, [supply]);

    useEffect(() => {
        form.setValue('productionFixedCost', productionFixedCost);
    }, [ingredients]);

    const handleClose = () => {
        if (isSubmitting) return;
        setCurrentSupplier(undefined);
        setSuppliers([]);
        setIngredients([]);
        onClose();
    };

    const onSubmit = async (form: any) => {
        const response = await changeSupplyApi({
            supplyId: requireValue(supplyId),
            supplierIds: suppliers.map((supplier) => supplier.supplierId),
            name: form.name,
            type: form.type,
            quantity: form.quantity,
            sellUnit: form.sellUnit,
            buyUnits: form.buyUnits,
            supplyCategoryId: form.supplyCategoryId,
            sku: form.sku,
            fixedCost: form.fixedCost ?? productionFixedCost,
            minimumQuantity: form.minimumQuantity,
            ingredients: ingredients?.map((ingredient) => ({
                supplyId: ingredient.supply.supplyId,
                quantity: ingredient.quantity,
            })),
        });
        if (!response.ok) {
            alertKnownErrorOrSomethingWentWrong(response);
            return;
        }
        onClose();
        notification({ message: translate('The supply was successfully updated') });
        onChangeSupply(produceAfterSave, response.data.supplyId);
    };

    const addSupplier = () => {
        if (!currentSupplier || suppliers.some((supplier) => supplier.supplierId === currentSupplier?.supplierId)) return;
        setSuppliers([...suppliers, currentSupplier]);
    };

    const removeSupplier = (supplierId: SupplierId) => {
        setSuppliers(suppliers.filter((supplier) => supplier.supplierId !== supplierId));
    };

    const removeBuyUnit = (index: any) => {
        if (fields.length === 1) return;
        remove(index);
    };

    const handleChangeCheckbox = (idx: any) => {
        const newBuyUnits = [...buyUnits].map((buyUnit, index) => ({
            ...buyUnit,
            isMainUnit: index === idx,
        }));
        form.setValue('buyUnits', newBuyUnits);
    };

    const validateUniqueSkuOnChanged = (sku: string, supplyId: SupplyId | undefined) => {
        return supplies.some((supplyItem) => {
            if (supplyItem.supplyId === supplyId) return false;
            return supplyItem.sku === sku?.trim();
        });
    };

    const onChangeSupplyIngredients = (ingredients: any) => {
        setIngredients(ingredients);
    };

    if (!supply) return null;

    return (
        <Dialog open={open} onClose={handleClose} classes={{ dialog: classes.dialogContainer }}>
            <Form form={form} onSubmit={onSubmit}>
                <Grid container spacing={6}>
                    <Grid item xs={12} md={12} className={(classes as any).dialogHeader}>
                        <h1 className={classes.title}>{translate('Change Supply')}</h1>
                    </Grid>
                    <Grid item xs={12} md={5}>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <FormTextField name='name' label={translate('Name')} required disabled={loading} />
                            </Grid>
                            <Grid item xs={6}>
                                <FormSupplyCategorySelect name='supplyCategoryId' label={translate('Category')} required disabled={loading} />
                            </Grid>

                            <Grid item xs={6}>
                                <FormSupplyTypeSelect name='type' label={translate('Type')} required disabled={loading} />
                            </Grid>

                            <Grid item xs={isNormalSupply(supply?.type) ? 6 : 12}>
                                <FormTextField
                                    name='sku'
                                    label={translate('SKU')}
                                    disabled={loading}
                                    validate={{
                                        sku: (value: string) => (!validateUniqueSkuOnChanged(value, supplyId) ? true : translate('This field must be unique')),
                                    }}
                                />
                            </Grid>
                            {isNormalSupply(supply?.type) && (
                                <Grid item xs={6}>
                                    <FormCurrencyNumberStringField
                                        name='fixedCost'
                                        label={translate('Price @unit', { unit: mainUnit?.unit ? translate(`UnitOfMeasurements.${mainUnit?.unit}`) : translate('Main Unit') })}
                                    />
                                </Grid>
                            )}

                            <Grid item xs={6}>
                                <FormCheckbox name='supplyLimitAlertEnabled' label={translate('Minimum inventory warning')} disabled={loading} />
                            </Grid>
                            {supplyLimitAlertEnabled && (
                                <Grid item xs={6}>
                                    <FormNumberStringField name='minimumQuantity' label={translate('Minimum inventory limit')} disabled={loading} />
                                </Grid>
                            )}
                        </Grid>
                        {isNormalSupply(supply?.type) && (
                            <Grid container spacing={2}>
                                <Grid item xs={8}>
                                    <FormSupplierAutocomplete name='supplierId' label={translate('Supplier')} onChange={setCurrentSupplier} disabled={loading} defaultValue={null} />
                                </Grid>
                                <Grid item xs={4} spacing={2}>
                                    <Button onClick={addSupplier} classes={{ button: classes.addButton }}>
                                        {translate('Add')}
                                    </Button>
                                </Grid>
                                <Grid item xs={12}>
                                    {suppliers.map((suppliers) => {
                                        return (
                                            <div className={classes.supplierContainer} key={suppliers.supplierId}>
                                                <span className={classes.supplier}>{suppliers.businessName}</span>
                                                <Button icon onClick={() => removeSupplier(suppliers.supplierId)}>
                                                    <CloseIcon />
                                                </Button>
                                            </div>
                                        );
                                    })}
                                </Grid>
                            </Grid>
                        )}
                        {isCompoundSupply(supply?.type) && (
                            <Grid item xs={12}>
                                <CompoundSupplyIngredients onChangeSupplyIngredients={onChangeSupplyIngredients} ingredients={supply?.ingredients} supplyId={supply.supplyId} />

                                <FormCurrencyNumberStringField
                                    name='productionFixedCost'
                                    label={translate('Estimated cost per unit of production', {
                                        unit: mainUnit?.unit ? translate(`UnitOfMeasurements.${mainUnit?.unit}`) : translate('Main Unit'),
                                    })}
                                    disabled={loading || isConsumable}
                                />
                            </Grid>
                        )}
                    </Grid>

                    <Grid item xs={1} md={1} spacing={2}>
                        <hr className={classes.verticalSeparator} />
                    </Grid>

                    <Grid item xs={12} md={6}>
                        <h1 className={classes.title}>{translate('Units')}</h1>
                        <Grid item xs={12}>
                            <FormUnitOfMeasurementSelect
                                name='sellUnit'
                                label={translate('Recipe unit')}
                                disabled={loading || isConsumable}
                                required
                                helperText={translate('This is the unit that will be used to sell the supply')}
                            />
                        </Grid>
                        <Grid container spacing={2}>
                            {fields.map((buyUnit, idx) => (
                                <React.Fragment key={buyUnit.id}>
                                    <Grid item xs={4}>
                                        <FormUnitOfMeasurementSelect name={`buyUnits.${idx}.unit`} required disabled={loading || isConsumable} defaultValue={buyUnit.unit} />
                                    </Grid>
                                    <Grid item xs={4}>
                                        <FormNumberField
                                            name={`buyUnits.${idx}.conversionFactor`}
                                            label={translate('Conversion Factor')}
                                            required
                                            disabled={loading || isConsumable}
                                            defaultValue={buyUnit.conversionFactor}
                                        />
                                    </Grid>
                                    <Grid item xs={3}>
                                        <FormCheckbox name={`buyUnits.${idx}.isMainUnit`} label={translate('Main Unit')} onChange={handleChangeCheckbox} idx={idx} defaultValue={buyUnit.isMainUnit} />
                                    </Grid>
                                    <Grid item xs={1}>
                                        <Button icon onClick={() => removeBuyUnit(idx)} disabled={loading || isConsumable}>
                                            <CloseIcon />
                                        </Button>
                                    </Grid>
                                </React.Fragment>
                            ))}

                            {isNormalSupply(supply?.type) && (
                                <Button onClick={() => append({ buyUnit: undefined, conversionFactor: undefined })} classes={{ button: classes.addButton }}>
                                    {translate('Add')}
                                </Button>
                            )}
                        </Grid>

                        {isCompoundSupply(supply?.type) && (
                            <div className={classes.produceAfterSave}>
                                <FormCheckbox name='produceAfterSave' label={translate('Create and Produce')} helperText={translate('Create and produce this supply after saving')} />
                            </div>
                        )}
                    </Grid>
                </Grid>

                <DialogActions>
                    <Button secondary onClick={handleClose} disabled={isSubmitting}>
                        {translate('Cancel')}
                    </Button>
                    <Button type='submit' disabled={isSubmitting}>
                        {isSubmitting ? translate('Changing') : translate('Change')}
                    </Button>
                </DialogActions>
            </Form>
        </Dialog>
    );
}

const useStyles = makeStyles((theme) => ({
    dialogContainer: {
        width: '80%',
        maxWidth: '80%',
        height: 'fit-content',
        position: 'relative',
    },
    title: {
        fontFamily: theme.typography.medium,
        fontSize: 20,
    },
    cancelButton: {
        fontFamily: theme.typography.semiBold,
        fontSize: 15,
        color: theme.palette.primary.main,
        textTransform: 'none',
        backgroundColor: 'white',
        borderRadius: 6,
        border: `1.5px solid ${theme.palette.primary.main}`,
        width: 225.5,
        height: 50,
        padding: '16px 32px 16px 32px',
        gap: 10,
    },
    acceptButton: {
        fontFamily: theme.typography.semiBold,
        fontSize: 15,
        color: 'white',
        textTransform: 'none',
        backgroundColor: theme.palette.primary.main,
        borderRadius: 6,
        width: 225.5,
        height: 50,
        padding: '16px 32px 16px 32px',
        gap: 10,
    },
    addButton: {
        width: 78,
        height: 34,
        padding: 8,
        minHeight: 34,
        borderRadius: 8,
        gap: 10,
        marginLeft: 10,
        marginTop: 20,
    },
    addSupplierButton: {
        backgroundColor: 'white',
        color: theme.palette.primary.main,
        border: `1px solid ${theme.palette.primary.main}`,
        fontFamily: theme.typography.semiBold,
        fontSize: 15,
        borderRadius: 5,
        height: 30,
        padding: '5px 15px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        textTransform: 'none',
    },
    supplierContainer: {
        width: '100%',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        padding: '5px 15px',
        borderBottom: `1px solid ${theme.palette.secondary.dark}`,
    },
    supplier: {
        fontFamily: theme.typography.regular,
        fontSize: 15,
    },
    separator: {
        border: `0.1px solid ${theme.palette.secondary.dark}`,
        width: '100%',
        margin: '20px 0',
    },
    button: {},
    dialog: {
        overflowY: 'scroll',
        '&::-webkit-scrollbar': {
            display: 'none',
        },
    },
    verticalSeparator: {
        borderLeft: `1px solid ${theme.palette.secondary.dark}`,
        height: '100%',
        width: '1px',
        margin: '0 20px',
    },

    addSupplierContainer: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'flex-end',
    },
    addSupplierButtonContainer: {
        display: 'flex',
        alignItems: 'flex-end',
        justifyContent: 'flex-end',
    },
    unitsContainer: {
        marginTop: 20,
    },
    produceAfterSave: {
        marginTop: 40,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        width: '100%',
    },
}));

type Props = {
    open: boolean;
    supplyId: SupplyId | undefined;
    onClose: any;
    onChangeSupply: any;
};
