import { makeStyles } from '@material-ui/core/styles';
import { Button, Chip, Text } from '@pidedirecto/ui';
import { UserBadgeIcon, UserIcon } from '@pidedirecto/ui/icons';
import * as React from 'react';
import { translate } from 'src/i18n/translate';
import { useMoveOrderItemsBetweenOrdersActions, useMoveOrderItemsBetweenOrdersStore } from 'src/modules/pos/order/moveOrderItemsBetweenOrdersStore';
import { OrderVm } from 'src/types/OrderVm';
import { classNames } from 'src/utils/react/classNames';
import { toShortId } from 'src/utils/uuid/toShortId';

export function OngoingEatHereOrderOption({ order }: Props): React.ReactElement {
    const classes = useStyles();

    const toOrderId = useMoveOrderItemsBetweenOrdersStore((state) => state.toOrderId);

    const setToOrderId = useMoveOrderItemsBetweenOrdersActions((actions) => actions.setToOrderId);

    const isSelected = order.orderId === toOrderId;
    const tableNumber = order.table ? translate('Table @number', { number: order.table }) : undefined;
    const orderId = order.consecutiveOrderId ?? `#${order.shortOrderId ?? toShortId(order.orderId)}`;

    return (
        <Button variant={'secondary'} classes={{ button: classNames(classes.card, isSelected ? classes.selected : '') }} onClick={() => setToOrderId(order.orderId)}>
            <div className={classes.separatedText}>
                <Text variant={'subtitle'}>{order.customerName || translate(`OrderTypes.${order.orderType}`)}</Text>
                {tableNumber && <Chip classes={{ chip: classes.chip }}>{tableNumber}</Chip>}
            </div>
            <div className={classes.separatedText}>
                <div className={classes.inlineText}>
                    <UserBadgeIcon size={20} />
                    <Text>{orderId}</Text>
                </div>
            </div>
            {order.waiterName && (
                <div className={classes.inlineText}>
                    <UserIcon size={20} />
                    <Text>{order.waiterName}</Text>
                </div>
            )}
        </Button>
    );
}

const useStyles = makeStyles((theme) => ({
    card: {
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        padding: 8,
        gap: 12,
        borderRadius: 8,
        fontSize: 14,
        color: theme.palette.text.primary,
        '&:focus': {
            outline: `2px solid transparent`,
        },
    },
    inlineText: {
        display: 'flex',
        color: theme.palette.text.secondary,
        alignSelf: 'start',
        gap: 10,
    },
    chip: {
        borderRadius: 6,
        height: 28,
        fontSize: 14,
        padding: '4px 6px',
        color: theme.palette.surface.brandHeavyContrast,
        backgroundColor: theme.palette.surface.brand,
    },
    separatedText: {
        display: 'flex',
        width: '100%',
        justifyContent: 'space-between',
    },
    subtitle: {
        color: theme.palette.text.secondary,
    },
    selected: {
        backgroundColor: theme.palette.surface.brand,
    },
}));

type Props = {
    order: OrderVm;
};
