import { makeStyles } from '@material-ui/core';
import { Checkbox } from '@pidedirecto/ui';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Button } from 'src/components/Button';
import { Dialog } from 'src/components/Dialog';
import { DialogActions } from 'src/components/DialogActions';
import { Form } from 'src/components/form/Form';
import { Input } from 'src/components/Input';
import { VirtualizedList } from 'src/components/VirtualizedList';
import { translate } from 'src/i18n/translate';
import { isArray } from 'src/utils/array/isArray';
import { debounce } from 'src/utils/function/debounce';

export function SelectItemsDialog({ open, title, items, defaultSelectedItems, renderItem, onClose, onSuccess }: Props): React.ReactElement {
    const classes = useStyles();
    const form = useForm();

    const [selectedItems, setSelectedItems] = useState<Array<any>>([]);
    const [filter, setFilter] = useState('');

    useEffect(() => {
        if (isArray(defaultSelectedItems)) {
            setSelectedItems(defaultSelectedItems);
            const formReset: Record<string, any> = {};
            defaultSelectedItems.forEach((selectedItem) => (formReset[selectedItem] = true));
            form.reset(formReset);
        }
    }, [defaultSelectedItems]);

    const itemsFiltered = items.filter((item) => {
        if (!filter) return true;

        const regExp = new RegExp(filter, 'gi');
        return item.searchableBy.match(regExp);
    });

    const handleSelect = (itemIndex: number) => {
        const itemValue = itemsFiltered[itemIndex].value;
        if (selectedItems.includes(itemValue)) return setSelectedItems(selectedItems.filter((item) => item !== itemValue));

        setSelectedItems([...selectedItems, itemValue]);
    };

    const handleClose = () => {
        setSelectedItems(defaultSelectedItems ?? []);
        onClose();
    };

    const handleSubmit = () => {
        onSuccess?.(selectedItems);
        handleClose();
    };

    const handleInputChange = (searchValue: any) => setFilter(searchValue);

    const debouncedHandleInputChange = debounce(handleInputChange, 300);

    return (
        <Dialog open={open} title={title} onClose={onClose}>
            <Form form={form} onSubmit={handleSubmit}>
                <Input type={'search'} name='filter' onChange={debouncedHandleInputChange} classes={{ container: classes.filterInput }} placeholder={translate('Search...')} />
                <div className={classes.itemsContainer}>
                    <VirtualizedList itemSize={80} height={400}>
                        {itemsFiltered.map((item, idx) => {
                            const isSelected = selectedItems.includes(item.value);
                            return (
                                <div className={classes.item} key={item.value}>
                                    <Checkbox aria-label={`${item.value}`} name={`${item.value}`} onClick={() => handleSelect(idx)} checked={isSelected} id={item.value} />
                                    {renderItem(item)}
                                </div>
                            );
                        })}
                    </VirtualizedList>
                </div>
                <DialogActions>
                    <Button onClick={handleClose} secondary>
                        {translate('Cancel')}
                    </Button>
                    <Button type='submit'>{translate('Save')}</Button>
                </DialogActions>
            </Form>
        </Dialog>
    );
}

const useStyles = makeStyles((theme) => ({
    itemsContainer: {
        marginTop: 20,
        maxHeight: 450,
        overflowY: 'scroll',
        '&::-webkit-scrollbar': {
            display: 'none',
        },
    },
    item: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        padding: '10px 5px',
        gap: 12,
        width: '100%',
        borderBottom: `1px solid ${theme.palette.secondary.dark}`,
    },
    filterInput: {
        width: '100%',
    },
}));

type Props = {
    open: boolean;
    title: string;
    items: Array<{
        value: any;
        item: any;
        searchableBy: string;
    }>;
    defaultSelectedItems?: Array<any>;
    renderItem: any;
    onClose: any;
    onSuccess?: any;
};
