import InputAdornment from '@material-ui/core/InputAdornment';
import AlternateEmailIcon from '@material-ui/icons/AlternateEmail';
import * as React from 'react';
import { useContext, useRef } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { FormContext } from 'src/components/form/Form';
import type { AutoCompleteInputProp, Variant } from 'src/components/form/FormTypes';
import { TransformedTextField } from 'src/components/form/material-ui/TransformedTextField';
import { getError } from 'src/components/form/utils/getError';
import { translate } from 'src/i18n/translate';

export function FormEmailField({
    name,
    label,
    placeholder,
    helperText,
    defaultValue = null,
    autoComplete = 'off',
    disabled,
    required,
    variant,
    InputProps,
    inputProps,
    classes,
    tooltip,
}: Props): React.ReactElement {
    const {
        errors,
        control,
        formState: { isSubmitting },
    } = useFormContext();
    const formContext = useContext(FormContext);
    const inputRef = useRef<HTMLInputElement>();

    return (
        <Controller
            render={({ value, onChange, ...props }) => (
                <TransformedTextField
                    {...props}
                    value={value?.toLowerCase()}
                    onChange={onChange}
                    label={label}
                    placeholder={placeholder}
                    helperText={getError(errors, name)?.message ?? helperText}
                    trim
                    disabled={isSubmitting || disabled || formContext.disabled}
                    required={required}
                    error={!!getError(errors, name)}
                    variant={variant}
                    inputRef={inputRef}
                    InputProps={{
                        ...InputProps,
                        endAdornment: (
                            <InputAdornment position='end'>
                                <AlternateEmailIcon color='action' className={classes?.icon} />
                            </InputAdornment>
                        ),
                    }}
                    inputProps={{ autoComplete, ...inputProps }}
                    InputLabelProps={{ shrink: placeholder ? true : undefined }}
                    fullWidth
                    classesProp={classes}
                    tooltip={tooltip}
                />
            )}
            control={control}
            name={name}
            defaultValue={defaultValue}
            rules={{
                required: {
                    value: (required as any)?.value ?? required,
                    message: (required as any)?.message ?? translate('This field is required'),
                },
                pattern: {
                    value: /(?:(^\S+@\S+\.\S+$)|(^\s*$))/,
                    message: translate('Invalid email'),
                },
            }}
            onFocus={() => {
                // make focus on error work when disabled={isSubmitting || disabled}
                if (inputRef.current) {
                    inputRef.current.disabled = false;
                    inputRef.current.focus();
                }
            }}
        />
    );
}

export type Props = {
    name: string;
    label?: string;
    placeholder?: string;
    helperText?: string;
    defaultValue?: string | null | undefined;
    autoComplete?: AutoCompleteInputProp;
    disabled?: boolean;
    required?:
        | boolean
        | {
              value: number;
              message: string;
          };
    variant?: Variant;
    InputProps?: any;
    inputProps?: any;
    classes?: any;
    tooltip?: string;
};
