import { makeStyles } from '@material-ui/core';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { getRestaurantBillingUyApi, RestaurantBillingUyVm } from 'src/api/letseatadmin/restaurantBillingUy/getRestaurantBillingUyApi';
import { app2 } from 'src/app2';
import { OpenInNewTabToolbarButton } from 'src/components/mui-datatables/OpenInNewTabToolbarButton';
import { RefreshToolbarButton } from 'src/components/mui-datatables/RefreshToolbarButton';
import { UpdatingContentProgress } from 'src/components/UpdatingContentProgress';
import { RoutePaths } from 'src/constants/RoutePath';
import { translate } from 'src/i18n/translate';
import { OrdersTable } from 'src/scenes/letseatadmin/order/OrdersTable';
import { CommissionCalculationUy } from 'src/scenes/letseatadmin/restaurantBillingUy/CommissionCalculationUy';
import { ManualEarningDebtCalculationUy } from 'src/scenes/letseatadmin/restaurantBillingUy/ManualEarningDebtCalculationUy';
import { SubscriptionPayPerUseDebtCalculationUy } from 'src/scenes/letseatadmin/restaurantBillingUy/SubscriptionPayPerUseDebtCalculationUy';
import { ToDepositCalculationUy } from 'src/scenes/letseatadmin/restaurantBillingUy/ToDepositCalculationUy';
import { RestaurantManualEarnings } from 'src/scenes/letseatadmin/restaurantManualEarning/RestaurantManualEarnings';
import type { RestaurantId } from 'src/types/Id';
import { alertKnownErrorOrSomethingWentWrong } from 'src/utils/alert/alertKnownErrorOrSomethingWentWrong';
import { useAction } from 'src/utils/react/useAction';

export function RestaurantBillingUy({ filter, refresh, restaurantId }: Props): React.ReactElement {
    const classes = useStyles();

    const [loading, setLoading] = useState(false);
    const [restaurantBillingUyVm, setRestaurantBillingVm] = useState<RestaurantBillingUyVm | undefined>();

    const setTitle = useAction(app2.actions.setTitle);

    useEffect(() => {
        setTitle(translate('Restaurant Billing Uruguay'));
        load();
    }, [refresh]);

    const load = async () => {
        setLoading(true);
        const response = await getRestaurantBillingUyApi({
            restaurantId,
            from: filter.from,
            to: filter.to,
        });
        setLoading(false);
        if (!response.ok) {
            alertKnownErrorOrSomethingWentWrong(response);
            return;
        }
        setRestaurantBillingVm(response.data);
    };

    return (
        <Grid container spacing={5}>
            <Grid item xs={12}>
                <UpdatingContentProgress loading={loading} />

                <Card classes={{ root: classes.rootTransparent }} variant='outlined'>
                    <CardHeader
                        title={
                            <Typography display='block' gutterBottom variant='h6'>
                                {restaurantBillingUyVm?.restaurantName} {restaurantBillingUyVm?.restaurantZone}
                            </Typography>
                        }
                        action={
                            <div>
                                {restaurantId && (
                                    <>
                                        <OpenInNewTabToolbarButton onClick={() => window.open(`${RoutePaths.THE_RESTAURANT}?restaurant=${restaurantId}`, '_blank')} />
                                    </>
                                )}
                                <RefreshToolbarButton onClick={load} />
                            </div>
                        }
                    />

                    <CardContent>
                        {restaurantBillingUyVm && (
                            <Grid container spacing={3}>
                                <Grid item md={3} xs={0 as any} />
                                <Grid item md={6} xs={12}>
                                    <CommissionCalculationUy restaurantBillingUyVm={restaurantBillingUyVm} />
                                </Grid>
                                <Grid item md={3} xs={0 as any} />
                                <Grid item md={3} xs={0 as any} />
                                <Grid item md={6} xs={12}>
                                    <SubscriptionPayPerUseDebtCalculationUy restaurantBillingUyVm={restaurantBillingUyVm} />
                                </Grid>
                                <Grid item md={3} xs={0 as any} />
                                <Grid item md={3} xs={0 as any} />
                                <Grid item md={6} xs={12}>
                                    <ManualEarningDebtCalculationUy restaurantBillingUyVm={restaurantBillingUyVm} />
                                </Grid>
                                <Grid item md={3} xs={0 as any} />
                                <Grid item md={3} xs={0 as any} />
                                <Grid item md={6} xs={12}>
                                    <ToDepositCalculationUy restaurantBillingUyVm={restaurantBillingUyVm} />
                                </Grid>
                                <Grid item md={3} xs={0 as any} />
                                <Grid item md={12} xs={0 as any} />
                            </Grid>
                        )}
                    </CardContent>
                </Card>

                <Card classes={{ root: classes.root }}>
                    <CardHeader
                        title={
                            <Typography display='block' gutterBottom variant='h6'>
                                {restaurantBillingUyVm?.restaurantName} {restaurantBillingUyVm?.restaurantZone}
                            </Typography>
                        }
                        action={
                            <div>
                                {restaurantId && (
                                    <>
                                        <OpenInNewTabToolbarButton onClick={() => window.open(`${RoutePaths.THE_RESTAURANT}?restaurant=${restaurantId}`, '_blank')} />
                                    </>
                                )}
                                <RefreshToolbarButton onClick={load} />
                            </div>
                        }
                    />

                    <CardContent>
                        <Grid container spacing={3}>
                            <Grid item xs={6} sm={3}>
                                <Typography variant='caption' display='block' gutterBottom color='textSecondary'>
                                    Web Card Orders
                                </Typography>
                                <Typography variant='body1' gutterBottom>
                                    {restaurantBillingUyVm?.webCard}
                                </Typography>
                            </Grid>
                            <Grid item xs={6} sm={3}>
                                <Typography variant='caption' display='block' gutterBottom color='textSecondary'>
                                    Web Card Rappi Cargo Orders
                                </Typography>
                                <Typography variant='body1' gutterBottom>
                                    {restaurantBillingUyVm?.rappiCargoWebCard}
                                </Typography>
                            </Grid>
                            <Grid item xs={6} sm={3}>
                                <Typography variant='caption' display='block' gutterBottom color='textSecondary'>
                                    Web Card Debit Rappi Cargo Orders
                                </Typography>
                                <Typography variant='body1' gutterBottom>
                                    {restaurantBillingUyVm?.rappiCargoWebCardDebit}
                                </Typography>
                            </Grid>
                            <Grid item xs={6} sm={3}>
                                <Typography variant='caption' display='block' gutterBottom color='textSecondary'>
                                    Web Card Credit Rappi Cargo Orders
                                </Typography>
                                <Typography variant='body1' gutterBottom>
                                    {restaurantBillingUyVm?.rappiCargoWebCardCredit}
                                </Typography>
                            </Grid>
                            <Grid item xs={6} sm={3}>
                                <Typography variant='caption' display='block' gutterBottom color='textSecondary'>
                                    Web Card Uber Daas Orders
                                </Typography>
                                <Typography variant='body1' gutterBottom>
                                    {restaurantBillingUyVm?.uberDaasWebCard}
                                </Typography>
                            </Grid>
                            <Grid item xs={6} sm={3}>
                                <Typography variant='caption' display='block' gutterBottom color='textSecondary'>
                                    Web Card Debit Uber Daas Orders
                                </Typography>
                                <Typography variant='body1' gutterBottom>
                                    {restaurantBillingUyVm?.uberDaasWebCardDebit}
                                </Typography>
                            </Grid>
                            <Grid item xs={6} sm={3}>
                                <Typography variant='caption' display='block' gutterBottom color='textSecondary'>
                                    Web Card Credit Uber Daas Orders
                                </Typography>
                                <Typography variant='body1' gutterBottom>
                                    {restaurantBillingUyVm?.uberDaasWebCardCredit}
                                </Typography>
                            </Grid>
                            <Grid item xs={6} sm={3}>
                                <Typography variant='caption' display='block' gutterBottom color='textSecondary'>
                                    Web Cash Orders
                                </Typography>
                                <Typography variant='body1' gutterBottom>
                                    {restaurantBillingUyVm?.webCash}
                                </Typography>
                            </Grid>
                            <Grid item xs={6} sm={3}>
                                <Typography variant='caption' display='block' gutterBottom color='textSecondary'>
                                    Web Rappi Cargo Cash Orders
                                </Typography>
                                <Typography variant='body1' gutterBottom>
                                    {restaurantBillingUyVm?.rappiCargoWebCash}
                                </Typography>
                            </Grid>
                            <Grid item xs={6} sm={3}>
                                <Typography variant='caption' display='block' gutterBottom color='textSecondary'>
                                    Web Uber Daas Cash Orders
                                </Typography>
                                <Typography variant='body1' gutterBottom>
                                    {restaurantBillingUyVm?.uberDaasWebCash}
                                </Typography>
                            </Grid>
                            <Grid item xs={6} sm={3}>
                                <Typography variant='caption' display='block' gutterBottom color='textSecondary'>
                                    Web Cash Orders
                                </Typography>
                                <Typography variant='body1' gutterBottom>
                                    {restaurantBillingUyVm?.webCardOnDelivery}
                                </Typography>
                            </Grid>
                            <Grid item xs={6} sm={3}>
                                <Typography variant='caption' display='block' gutterBottom color='textSecondary'>
                                    App Card Orders
                                </Typography>
                                <Typography variant='body1' gutterBottom>
                                    {restaurantBillingUyVm?.whiteLabelAppCard}
                                </Typography>
                            </Grid>
                            <Grid item xs={6} sm={3}>
                                <Typography variant='caption' display='block' gutterBottom color='textSecondary'>
                                    App Card Debit Orders
                                </Typography>
                                <Typography variant='body1' gutterBottom>
                                    {restaurantBillingUyVm?.whiteLabelAppCardDebit}
                                </Typography>
                            </Grid>
                            <Grid item xs={6} sm={3}>
                                <Typography variant='caption' display='block' gutterBottom color='textSecondary'>
                                    App Card Credit Orders
                                </Typography>
                                <Typography variant='body1' gutterBottom>
                                    {restaurantBillingUyVm?.whiteLabelAppCardCredit}
                                </Typography>
                            </Grid>
                            <Grid item xs={6} sm={3}>
                                <Typography variant='caption' display='block' gutterBottom color='textSecondary'>
                                    App Cash Orders
                                </Typography>
                                <Typography variant='body1' gutterBottom>
                                    {restaurantBillingUyVm?.whiteLabelAppCash}
                                </Typography>
                            </Grid>
                            <Grid item xs={6} sm={3}>
                                <Typography variant='caption' display='block' gutterBottom color='textSecondary'>
                                    App Cash Orders
                                </Typography>
                                <Typography variant='body1' gutterBottom>
                                    {restaurantBillingUyVm?.whiteLabelAppCardOnDelivery}
                                </Typography>
                            </Grid>
                            <Grid item xs={6} sm={3}>
                                <Typography variant='caption' display='block' gutterBottom color='textSecondary'>
                                    POS Card Orders
                                </Typography>
                                <Typography variant='body1' gutterBottom>
                                    {restaurantBillingUyVm?.posCard}
                                </Typography>
                            </Grid>
                            <Grid item xs={6} sm={3}>
                                <Typography variant='caption' display='block' gutterBottom color='textSecondary'>
                                    POS Rappi Cargo Card Orders
                                </Typography>
                                <Typography variant='body1' gutterBottom>
                                    {restaurantBillingUyVm?.rappiCargoPosCard}
                                </Typography>
                            </Grid>
                            <Grid item xs={6} sm={3}>
                                <Typography variant='caption' display='block' gutterBottom color='textSecondary'>
                                    POS Rappi Uber Daas Orders
                                </Typography>
                                <Typography variant='body1' gutterBottom>
                                    {restaurantBillingUyVm?.uberDaasPosCard}
                                </Typography>
                            </Grid>
                            <Grid item xs={6} sm={3}>
                                <Typography variant='caption' display='block' gutterBottom color='textSecondary'>
                                    POS Cash Orders
                                </Typography>
                                <Typography variant='body1' gutterBottom>
                                    {restaurantBillingUyVm?.posCash}
                                </Typography>
                            </Grid>
                            <Grid item xs={6} sm={3}>
                                <Typography variant='caption' display='block' gutterBottom color='textSecondary'>
                                    POS Cash Rappi Cargo Orders
                                </Typography>
                                <Typography variant='body1' gutterBottom>
                                    {restaurantBillingUyVm?.rappiCargoPosCash}
                                </Typography>
                            </Grid>
                            <Grid item xs={6} sm={3}>
                                <Typography variant='caption' display='block' gutterBottom color='textSecondary'>
                                    POS Cash Uber Daas Orders
                                </Typography>
                                <Typography variant='body1' gutterBottom>
                                    {restaurantBillingUyVm?.uberDaasPosCash}
                                </Typography>
                            </Grid>
                            <Grid item xs={6} sm={3}>
                                <Typography variant='caption' display='block' gutterBottom color='textSecondary'>
                                    POS Cash Orders
                                </Typography>
                                <Typography variant='body1' gutterBottom>
                                    {restaurantBillingUyVm?.posCardOnDelivery}
                                </Typography>
                            </Grid>
                            <Grid item xs={6} sm={3}>
                                <Typography variant='caption' display='block' gutterBottom color='textSecondary'>
                                    Manual Orders
                                </Typography>
                                <Typography variant='body1' gutterBottom>
                                    {restaurantBillingUyVm?.manualOrders}
                                </Typography>
                            </Grid>
                            <Grid item xs={6} sm={3}>
                                <Typography variant='caption' display='block' gutterBottom color='textSecondary'>
                                    {translate('Number of Manual Orders Protected')}
                                </Typography>
                                <Typography variant='body1' gutterBottom>
                                    {restaurantBillingUyVm?.manualOrdersProtected}
                                </Typography>
                            </Grid>
                            <Grid item xs={6} sm={3}>
                                <Typography variant='caption' display='block' gutterBottom color='textSecondary'>
                                    {translate('Directo Protection Amount')}
                                </Typography>
                                <Typography variant='body1' gutterBottom>
                                    {restaurantBillingUyVm?.directoProtectionAmount}
                                </Typography>
                            </Grid>
                            <Grid item xs={6} sm={3}>
                                <Typography variant='caption' display='block' gutterBottom color='textSecondary'>
                                    Invoiced Deliveries
                                </Typography>
                                <Typography variant='body1' gutterBottom>
                                    {restaurantBillingUyVm?.invoicedRestaurantDeliveryCost}
                                </Typography>
                            </Grid>
                            <Grid item xs={6} sm={3}>
                                <Typography variant='caption' display='block' gutterBottom color='textSecondary'>
                                    Payment Links
                                </Typography>
                                <Typography variant='body1' gutterBottom>
                                    {restaurantBillingUyVm?.paymentLink}
                                </Typography>
                            </Grid>
                            <Grid item xs={6} sm={3}>
                                <Typography variant='caption' display='block' gutterBottom color='textSecondary'>
                                    Total Sold
                                </Typography>
                                <Typography variant='body1' gutterBottom>
                                    {restaurantBillingUyVm?.total}
                                </Typography>
                            </Grid>
                            <Grid item xs={6} sm={3}>
                                <Typography variant='caption' display='block' gutterBottom color='textSecondary'>
                                    Commission
                                </Typography>
                                <Typography variant='body1' gutterBottom>
                                    {restaurantBillingUyVm?.commission}
                                </Typography>
                            </Grid>
                            <Grid item xs={6} sm={3}>
                                <Typography variant='caption' display='block' gutterBottom color='textSecondary'>
                                    IVA
                                </Typography>
                                <Typography variant='body1' gutterBottom>
                                    {restaurantBillingUyVm?.iva}
                                </Typography>
                            </Grid>
                            <Grid item xs={6} sm={3}>
                                <Typography variant='caption' display='block' gutterBottom color='textSecondary'>
                                    {translate('Restaurant Promo Code Cost App')}
                                </Typography>
                                <Typography variant='body1' gutterBottom>
                                    {restaurantBillingUyVm?.restaurantPromoCodeCostApp}
                                </Typography>
                            </Grid>
                            <Grid item xs={6} sm={3}>
                                <Typography variant='caption' display='block' gutterBottom color='textSecondary'>
                                    {translate('Restaurant Promo Code Balance')}
                                </Typography>
                                <Typography variant='body1' gutterBottom>
                                    {restaurantBillingUyVm?.restaurantPromoCodeBalance}
                                </Typography>
                            </Grid>
                            <Grid item xs={6} sm={3}>
                                <Typography variant='caption' display='block' gutterBottom color='textSecondary'>
                                    Subscription Pay Per Use
                                </Typography>
                                <Typography variant='body1' gutterBottom>
                                    {restaurantBillingUyVm?.subscriptionPayPerUsePayment}
                                </Typography>
                            </Grid>
                            <Grid item xs={6} sm={3}>
                                <Typography variant='caption' display='block' gutterBottom color='textSecondary'>
                                    Manual Earning
                                </Typography>
                                <Typography variant='body1' gutterBottom>
                                    {restaurantBillingUyVm?.manualEarning}
                                </Typography>
                            </Grid>
                            <Grid item xs={6} sm={3}>
                                <Typography variant='caption' display='block' gutterBottom color='textSecondary'>
                                    Debt Subscription Pay Per Use
                                </Typography>
                                <Typography variant='body1' gutterBottom>
                                    {restaurantBillingUyVm?.debtSubscriptionPayPerUsePayment}
                                </Typography>
                            </Grid>
                            <Grid item xs={6} sm={3}>
                                <Typography variant='caption' display='block' gutterBottom color='textSecondary'>
                                    Debt Manual Earning
                                </Typography>
                                <Typography variant='body1' gutterBottom>
                                    {restaurantBillingUyVm?.debtManualEarning}
                                </Typography>
                            </Grid>
                            <Grid item xs={6} sm={3}>
                                <Typography variant='caption' display='block' gutterBottom color='textSecondary'>
                                    Subscription Pay Per Use Invoice
                                </Typography>
                                <Typography variant='body1' gutterBottom>
                                    {restaurantBillingUyVm?.subscriptionPayPerUsePaymentInvoice}
                                </Typography>
                            </Grid>
                            <Grid item xs={6} sm={3}>
                                <Typography variant='caption' display='block' gutterBottom color='textSecondary'>
                                    Manual Earning Invoice
                                </Typography>
                                <Typography variant='body1' gutterBottom>
                                    {restaurantBillingUyVm?.manualEarningInvoice}
                                </Typography>
                            </Grid>
                            <Grid item xs={6} sm={3}>
                                <Typography variant='caption' display='block' gutterBottom color='textSecondary'>
                                    To Deposit
                                </Typography>
                                <Typography variant='body1' gutterBottom>
                                    {restaurantBillingUyVm?.deposit}
                                </Typography>
                            </Grid>
                            <Grid item xs={6} sm={3}>
                                <Typography variant='caption' display='block' gutterBottom color='textSecondary'>
                                    OrdersPage
                                </Typography>
                                <Typography variant='body1' gutterBottom>
                                    {restaurantBillingUyVm?.orders?.length}
                                </Typography>
                            </Grid>
                        </Grid>
                    </CardContent>
                </Card>
            </Grid>

            {restaurantBillingUyVm?.orders && (
                <Grid item xs={12}>
                    <OrdersTable title='OrdersPage' orders={restaurantBillingUyVm?.orders} loading={loading} onClickRefresh={load} />
                </Grid>
            )}
            <Grid item xs={12}>
                <RestaurantManualEarnings
                    restaurantId={restaurantId}
                    filter={{
                        from: filter.from,
                        to: filter.to,
                    }}
                />
            </Grid>
            {/*            {restaurantBillingUyVm?.notAccepted && (
                <Grid item xs={12}>
                    <OrdersTable title='WARNING ORDERS NOT ACCEPTED YET' orders={restaurantBillingUyVm?.notAccepted} loading={restaurantBillingUyVm?.loading} onClickRefresh={load} />
                </Grid>
            )}*/}
        </Grid>
    );
}

const useStyles = makeStyles((theme) => ({
    cardContainer: {
        position: 'relative',
    },
    root: {
        paddingLeft: 8,
        paddingRight: 8,
    },
    rootTransparent: {
        paddingLeft: 8,
        paddingRight: 8,
        backgroundColor: 'transparent',
        border: 0,
    },
}));

type Props = {
    filter: {
        from: Date;
        to: Date;
    };
    refresh: number;
    restaurantId: RestaurantId;
};
