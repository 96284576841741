import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import DownloadIcon from '@material-ui/icons/SaveAltRounded';
import * as React from 'react';
import { translate } from 'src/i18n/translate';

export function DownloadPdfToolbarButton({ tooltip, onClick }: Props): React.ReactElement {
    return (
        <Tooltip title={tooltip || translate('Download PDF')}>
            <IconButton onClick={onClick}>
                <DownloadIcon />
            </IconButton>
        </Tooltip>
    );
}

type Props = {
    tooltip?: string;
    onClick: any;
};
