import { makeStyles } from '@material-ui/core/styles';
import { Button, Checkbox, Dialog, DialogActions, DialogContent, Text } from '@pidedirecto/ui';
import { useNotification } from '@pidedirecto/ui/hooks';
import { BigNumber } from 'bignumber.js';
import * as React from 'react';
import { translate } from 'src/i18n/translate';
import { useMoveOrderItemsBetweenOrdersActions, useMoveOrderItemsBetweenOrdersStore } from 'src/modules/pos/order/moveOrderItemsBetweenOrdersStore';
import { CartItemVm } from 'src/types/CartItemVm';
import { splitGroupedItems } from 'src/utils/order/splitGroupedItems';
import { isItemSoldByWeight } from 'src/utils/orderItem/isItemSoldByWeight';
import { useSelector } from 'src/utils/react/useSelector';
import { removeNulls } from 'src/utils/removeNulls';

export function MoveOrderItemsBetweenOrdersDialog(): React.ReactElement {
    const classes = useStyles();
    const notification = useNotification();

    const orderItemsToMove = useMoveOrderItemsBetweenOrdersStore((state) => state.orderItemsToMove);
    const moveOrderItemsBetweenOrdersDialogState = useMoveOrderItemsBetweenOrdersStore((state) => state.moveOrderItemsBetweenOrdersDialogState);

    const setOrderItemsToMove = useMoveOrderItemsBetweenOrdersActions((actions) => actions.setOrderItemsToMove);
    const serRemainingOrderItems = useMoveOrderItemsBetweenOrdersActions((actions) => actions.serRemainingOrderItems);
    const clearMoveOrderItemsBetweenOrdersStore = useMoveOrderItemsBetweenOrdersActions((actions) => actions.clearMoveOrderItemsBetweenOrdersStore);
    const closeMoveOrderItemsBetweenOrdersDialog = useMoveOrderItemsBetweenOrdersActions((actions) => actions.closeMoveOrderItemsBetweenOrdersDialog);
    const openSelectOrderToMoveOrderItemsDialog = useMoveOrderItemsBetweenOrdersActions((actions) => actions.openSelectOrderToMoveOrderItemsDialog);

    const items = useSelector((state) => state.pos.items);

    const handleOpenSelectOrderToMoveOrderItemsDialog = () => {
        openSelectOrderToMoveOrderItemsDialog();
        serRemainingOrderItems(getRemainingOrderItems() as any);
        closeMoveOrderItemsBetweenOrdersDialog();
    };

    const getRemainingOrderItems = () => {
        const updatedOrderItems = removeNulls(
            items.map((item) => {
                const separatedOrderItems = orderItemsToMove.filter((selectedItem) => selectedItem.key === item.key);
                const newQuantity = isItemSoldByWeight(item) && separatedOrderItems.length === 1 ? 0 : BigNumber(item.quantity).minus(separatedOrderItems.length).toNumber();
                if (BigNumber(newQuantity).isZero()) return null;
                return {
                    ...item,
                    quantity: newQuantity,
                };
            }),
        );
        return updatedOrderItems;
    };

    const selectOrderItem = (orderItem: CartItemVm, position: number) => {
        if (BigNumber(orderItem.timesMoved ?? 0).isGreaterThanOrEqualTo(2)) {
            notification({ message: translate('A product cannot be moved more than 2 times') });
            return;
        }
        const isOrderItemSelected = orderItemsToMove.some((selectedOrderItem) => selectedOrderItem.key === orderItem.key && selectedOrderItem.position === position);
        if (isOrderItemSelected) {
            setOrderItemsToMove(orderItemsToMove.filter((selectedOrderItem) => selectedOrderItem.key !== orderItem.key || selectedOrderItem.position !== position));
            return;
        }

        setOrderItemsToMove([...orderItemsToMove, { ...orderItem, position }]);
    };

    const hasMoreThanOneRemainingOrderItem = splitGroupedItems(getRemainingOrderItems() as any)?.length > 1;

    return (
        <Dialog open={moveOrderItemsBetweenOrdersDialogState.open} onClose={clearMoveOrderItemsBetweenOrdersStore} title={translate('Select the products to move')}>
            <DialogContent>
                <Text size='small' variant='subtitle' className={classes.subtitle}>
                    {translate('Step @step', { step: '1' })}
                </Text>
                {splitGroupedItems(items).map((orderItem: CartItemVm, idx) => {
                    const isChecked = orderItemsToMove.some((selectedOrderItem) => selectedOrderItem.key === orderItem.key && selectedOrderItem.position === idx);
                    return (
                        <div key={`${orderItem.key}-${idx}`}>
                            <Checkbox
                                disabled={!hasMoreThanOneRemainingOrderItem && !isChecked}
                                checked={isChecked}
                                name={`${orderItem.key}-${idx}`}
                                label={orderItem.name}
                                onChange={(e) => selectOrderItem(orderItem, idx)}
                            />
                            <div className={classes.modifiersContainer}>
                                {orderItem.modifierGroups.map((modifierGroup) => {
                                    return modifierGroup.modifiers.map((modifier) => {
                                        if (modifier.quantity === 1) {
                                            return (
                                                <Text size='small' className={classes.modifier}>
                                                    {modifier.name}
                                                </Text>
                                            );
                                        }
                                        return (
                                            <Text size='small' className={classes.modifier}>
                                                {modifier.name} x{modifier.quantity}
                                            </Text>
                                        );
                                    });
                                })}
                            </div>
                        </div>
                    );
                })}
            </DialogContent>
            <DialogActions>
                <Button variant='secondary' onClick={clearMoveOrderItemsBetweenOrdersStore}>
                    {translate('Cancel')}
                </Button>
                <Button onClick={handleOpenSelectOrderToMoveOrderItemsDialog} disabled={!orderItemsToMove?.length}>
                    {translate('Next')}
                </Button>
            </DialogActions>
        </Dialog>
    );
}

const useStyles = makeStyles((theme) => ({
    subtitle: {
        textAlign: 'center',
        marginBottom: 10,
    },
    modifier: {
        color: theme.palette.text.contrast,
    },
    modifiersContainer: {
        display: 'flex',
        flexDirection: 'column',
        paddingLeft: 35,
    },
}));
