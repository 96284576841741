import { makeStyles } from '@material-ui/core/styles';
import { BigNumber } from 'bignumber.js';
import * as React from 'react';
import { ResponsiveLineCanvas } from 'src/components/charts/ResponsiveLineCanvas';
import { translate } from 'src/i18n/translate';
import { GraphEmptyState } from 'src/scenes/letseatmanager/posReports/GraphEmptyState';
import { useFormatAsRestaurantCurrencyNumber } from 'src/services/restaurant/useFormatAsRestaurantCurrencyNumber';
import type { ManagerUserSalesReportVm } from 'src/types/ManagerUserSalesReportVm';
import { removeDuplicates } from 'src/utils/array/removeDuplicates';
import { sum } from 'src/utils/reduce/sum';

export function ManagerUserHistoricalSalesReport({ managerUserSalesReport }: Props): React.ReactElement {
    const classes = useStyles();
    const formatAsCurrencyNumber = useFormatAsRestaurantCurrencyNumber();

    const getHistoricalSalesLineChartData = () => {
        const managerUsers = getManagerUsers();
        return managerUsers.map((managerUser) => {
            const managerUsersSalesByManagerUser = getManagerUsersSalesByManagerUser(managerUser);
            return {
                id: managerUser,
                data: managerUsersSalesByManagerUser.map((managerUserSalesByManagerUser) => ({
                    y: managerUserSalesByManagerUser.total,
                    x: managerUserSalesByManagerUser.createdAt,
                })),
            };
        });
    };

    const getManagerUsers = () => {
        const managerUsers = managerUserSalesReport.managerUserHistoricalSales.map((managerUserHistoricalSales) => managerUserHistoricalSales.servedBy);
        return removeDuplicates(managerUsers) ?? [];
    };

    const getManagerUsersSalesByManagerUser = (managerUser: string) => {
        const managerUserSalesByManagerUser = managerUserSalesReport.managerUserHistoricalSales.filter((managerUserHistoricalSales) => managerUserHistoricalSales.servedBy === managerUser);
        return managerUserSalesByManagerUser;
    };

    const isEmptyReport = () => {
        const managerUserSales = managerUserSalesReport.managerUserHistoricalSales.map((managerUserHistoricalSales) => managerUserHistoricalSales.total);
        const totalSales = managerUserSales.reduce(sum, BigNumber(0));
        return totalSales.isZero();
    };

    if (isEmptyReport()) {
        return (
            <article className={classes.container}>
                <GraphEmptyState title={translate('Historical sales by waiter')} />
            </article>
        );
    }

    return (
        <article className={classes.container}>
            <h2 className={classes.title}>{translate('Historical sales by waiter')}</h2>
            <ResponsiveLineCanvas
                data={getHistoricalSalesLineChartData()}
                margin={{ top: 50, right: 170, bottom: 50, left: 60 }}
                tooltip={({ point }: { point: { data: { x: string; y: string } } }) => (
                    <div className={classes.tooltip}>
                        <span className={classes.tooltipText}>{translate(`Date: @date`, { date: point.data.x })}</span>
                        <span className={classes.tooltipText}>{translate(`Total: @total`, { total: formatAsCurrencyNumber(point.data.y) })}</span>
                    </div>
                )}
                legends={[
                    {
                        anchor: 'bottom-right',
                        direction: 'column',
                        justify: false,
                        translateX: 100,
                        translateY: 0,
                        itemsSpacing: 2,
                        itemDirection: 'left-to-right',
                        itemWidth: 80,
                        itemHeight: 12,
                        itemOpacity: 0.75,
                        symbolSize: 12,
                        symbolShape: 'circle',
                        symbolBorderColor: 'rgba(0, 0, 0, .5)',
                    },
                ]}
            />
        </article>
    );
}

const useStyles = makeStyles((theme) => ({
    container: {
        height: '70vh',
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
    },
    title: {
        fontFamily: theme.typography.medium,
        color: theme.palette.text.primary,
    },
    tooltip: {
        padding: 10,
        borderRadius: 3,
        backgroundColor: 'white',
        boxShadow: '1px 1px 1px rgb(0,0,0,0.2)',
        display: 'flex',
        flexDirection: 'column',
    },
    tooltipTitle: {
        fontFamily: theme.typography.bold,
    },
    tooltipText: {
        fontFamily: theme.typography.regular,
    },
}));

type Props = {
    managerUserSalesReport: ManagerUserSalesReportVm;
};
