import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, makeStyles } from '@material-ui/core';
import { Button, Switch } from '@pidedirecto/ui';
import { useState } from 'react';
import * as React from 'react';
import { Transition } from 'react-transition-group';
import { closeRappiStoreApi } from 'src/api/letseatmanager/restaurantIntegration/closeRappiStoreApi';
import { openRappiStoreApi } from 'src/api/letseatmanager/restaurantIntegration/openRappiStoreApi';
import { toggleDeliverOrdersFromRappiApi } from 'src/api/letseatmanager/restaurantIntegration/toggleDeliverOrdersFromRappiApi';
import { app2 } from 'src/app2';
import { RappiIntegrationStatus, RappiIntegrationStatuses } from 'src/constants/RappiIntegrationStatus';
import { translate } from 'src/i18n/translate';
import rappiLogo from 'src/images/rappi-logo.png';
import { IntegrateRappiDialog } from 'src/scenes/letseatadmin/rappiIntegration/IntegrateRappiDialog';
import { ChangeRappiIntegrationPercentageDialog } from 'src/scenes/letseatmanager/integrations/ChangeRappiIntegrationPercentageDialog';
import { EnableRappiTurboSetting } from 'src/scenes/letseatmanager/integrations/EnableRappiTurboSetting';
import { RestaurantId } from 'src/types/Id';
import type { RestaurantIntegrationStatusVm } from 'src/types/RestaurantIntegrationStatusVm';
import { alertKnownErrorOrSomethingWentWrong } from 'src/utils/alert/alertKnownErrorOrSomethingWentWrong';
import { formatAsPercentage } from 'src/utils/number/formatAsPercentage';
import { useAction } from 'src/utils/react/useAction';
import { useSelector } from 'src/utils/react/useSelector';

export function RappiIntegration({ restaurantIntegrationsStatus, load, isIntegrationLoaded }: Props): React.ReactElement {
    const classes = useStyles();
    const [loading, setLoading] = useState(false);

    const rappiStoreOnline = useSelector((state) => state.app2.rappiStoreOnline);
    const adminUser = useSelector((state) => state.authentication.adminUser);
    const restaurantId = useSelector((state) => state.app.restaurantId);
    const viewUser = useSelector((state) => state.authentication.viewUser);

    const setRappiStoreOnline = useAction(app2.actions.setRappiStoreOnline);
    const [integrateRappiDialogState, setIntegrateRappiDialogState] = useState<{ open: boolean; restaurantId: RestaurantId | undefined; rappiIntegrationStatus?: RappiIntegrationStatus }>({
        open: false,
        restaurantId: undefined,
        rappiIntegrationStatus: undefined,
    });

    const [confirmDeliverOrdersFromRappiDialogState, setConfirmDeliverOrdersFromRappiDialogState] = useState(false);
    const [desintagrateRappiMessage, setDesintagrateRappiMessage] = useState(false);
    const [rappiChangePercentageDialogState, setRappiChangePercentageDialogState] = useState({ open: false });

    const closeRappiStore = async () => {
        setLoading(true);
        const response = await closeRappiStoreApi({ restaurantId });
        if (!response.ok) {
            alertKnownErrorOrSomethingWentWrong(response);
            setLoading(false);
            return;
        }
        setRappiStoreOnline(false);
        setLoading(false);
        load();
    };

    const openRappiStore = async () => {
        setLoading(true);
        const response = await openRappiStoreApi({ restaurantId });
        if (!response.ok) {
            alertKnownErrorOrSomethingWentWrong(response);
            setLoading(false);
            return;
        }
        setRappiStoreOnline(true);
        setLoading(false);
        load();
    };

    const ConfirmDeliverOrdersFromRappiDialog = () => {
        return (
            // @ts-ignore
            <Dialog open={confirmDeliverOrdersFromRappiDialogState} TransitionComponent={Transition} keepMounted aria-describedby='alert-dialog-slide-description'>
                <DialogTitle classes={{ root: classes.dialogTitle }}>{translate('Confirm')}</DialogTitle>
                <DialogContent>
                    <DialogContentText id='alert-dialog-slide-description' classes={{ root: classes.dialogText }}>
                        {restaurantIntegrationsStatus.deliverOrdersFromRappi
                            ? translate('Do you wish to stop delivering Rappi Orders by PideDirecto?')
                            : translate('Do you wish to start delivering Rappi Orders by PideDirecto?')}
                    </DialogContentText>
                </DialogContent>
                <DialogActions className={classes.buttonsContainer}>
                    <Button disabled={loading || isIntegrationLoaded || viewUser} onClick={() => handleConfirmDeliverOrdersFromRappi(false)} variant='secondary'>
                        {translate('Cancel')}
                    </Button>
                    <Button disabled={loading || isIntegrationLoaded || viewUser} onClick={() => handleConfirmDeliverOrdersFromRappi(true)}>
                        {translate('Agree')}
                    </Button>
                </DialogActions>
            </Dialog>
        );
    };

    const ConfirmDisintegrateFromRappiDialog = () => {
        return (
            // @ts-ignore
            <Dialog open={desintagrateRappiMessage} TransitionComponent={Transition} keepMounted aria-describedby='alert-dialog-slide-description'>
                <DialogTitle classes={{ root: classes.dialogTitle }}>{translate('Confirm')}</DialogTitle>
                <DialogContent>
                    <DialogContentText id='alert-dialog-slide-description' classes={{ root: classes.dialogText }}>
                        {translate('Contact your vendor or support in order do desintegrate Rappi')}
                    </DialogContentText>
                </DialogContent>
                <DialogActions className={classes.buttonsContainer}>
                    <Button disabled={loading || isIntegrationLoaded || viewUser} onClick={() => setDesintagrateRappiMessage(false)}>
                        {translate('Ok')}
                    </Button>
                </DialogActions>
            </Dialog>
        );
    };

    const handleClickIntegrateRappi = async () => {
        if (restaurantIntegrationsStatus.rappiStoreId) {
            setDesintagrateRappiMessage(true);
            return;
        }
        return setIntegrateRappiDialogState({ open: true, restaurantId: restaurantId });
    };

    const handleConfirmDeliverOrdersFromRappi = async (confirmed: boolean) => {
        if (!confirmed) {
            setConfirmDeliverOrdersFromRappiDialogState(false);
            return;
        }
        setLoading(true);
        await toggleDeliverOrdersFromRappiApi({ restaurantId });
        await load();
        setLoading(false);
        setConfirmDeliverOrdersFromRappiDialogState(false);
    };

    const handleChangeIntegrationPercentage = async () => {
        setRappiChangePercentageDialogState({ open: true });
    };

    const handleOnSuccessChangePercentage = () => {
        setRappiChangePercentageDialogState({ open: false });
        load();
    };

    return (
        <>
            <ChangeRappiIntegrationPercentageDialog
                open={rappiChangePercentageDialogState.open}
                onClose={() => setRappiChangePercentageDialogState({ open: false })}
                onSuccess={handleOnSuccessChangePercentage}
            />
            <IntegrateRappiDialog
                open={integrateRappiDialogState.open}
                restaurantId={restaurantId}
                onClose={() => {
                    setIntegrateRappiDialogState({ open: false, restaurantId: undefined });
                    load();
                }}
            />
            <div className={classes.integrationContainer}>
                <div className={classes.integrationInfoContainer}>
                    <div className={classes.integrationImageContainer}>
                        <img src={rappiLogo} />
                    </div>
                    <div className={classes.integrationInfo}>
                        <div className={classes.integrationNameContainer}>
                            <span className={classes.integrationName}>Rappi</span>
                            {/* TODO: Fix classes.rappiStoreId condition */}
                            {/* @ts-ignore */}
                            <span className={classes.rappiStoreId ? classes.integrated : classes.notIntengrated}>
                                {restaurantIntegrationsStatus.rappiStoreId
                                    ? restaurantIntegrationsStatus.rappiIntegrationStatus === RappiIntegrationStatuses.PENDING
                                        ? translate('Pending')
                                        : translate('Integrated')
                                    : translate('Not integrated')}
                            </span>
                        </div>
                        <div className={classes.integrationPercentageInfoContainer}>
                            <span className={classes.integrationPercentageInfo}>
                                {restaurantIntegrationsStatus.rappiSalesCommissionPercentage ? translate('Commission of') : translate('Without commission')}
                                &nbsp;
                                <strong>{restaurantIntegrationsStatus.rappiSalesCommissionPercentage ? formatAsPercentage(restaurantIntegrationsStatus.rappiSalesCommissionPercentage) : ''}</strong>
                            </span>
                            <Button onClick={handleChangeIntegrationPercentage} disabled={loading || isIntegrationLoaded || viewUser} variant='text'>
                                {translate('Edit')}
                            </Button>
                        </div>
                        {restaurantIntegrationsStatus.rappiStoreId &&
                            restaurantIntegrationsStatus.rappiIntegrationStatus === RappiIntegrationStatuses.ACTIVE &&
                            (rappiStoreOnline ? (
                                <span className={classes.integrationStoreStatusOpen}>{translate('Opened')}</span>
                            ) : (
                                <span className={classes.integrationStoreStatusClosed}>{translate('Closed')}</span>
                            ))}
                    </div>
                </div>

                <div className={classes.actionsContainer}>
                    <div className={classes.buttonGroup}>
                        <Button
                            classes={{ button: !!restaurantIntegrationsStatus.rappiStoreId ? classes.buttonDisintegrate : undefined }}
                            onClick={() => handleClickIntegrateRappi()}
                            disabled={loading || isIntegrationLoaded || viewUser}
                        >
                            {restaurantIntegrationsStatus.rappiStoreId ? translate('Disintegrate ') : translate('Integrate')}
                        </Button>
                        <Button classes={{ button: classes.buttonCloseStore }} onClick={() => closeRappiStore()} disabled={loading} variant='secondary'>
                            {translate('Close')}
                        </Button>
                        <Button classes={{ button: classes.buttonCloseStore }} onClick={() => openRappiStore()} disabled={loading} variant='secondary'>
                            {translate('Open')}
                        </Button>
                    </div>
                    {adminUser && (
                        <div className={classes.integrationInfoContainer}>
                            <span className={classes.integrationLabel}>{translate('Deliver orders')}</span>
                            <Switch
                                checked={restaurantIntegrationsStatus.deliverOrdersFromRappi}
                                name='deliverOrdersFromRappi'
                                onChange={() => {
                                    if (restaurantIntegrationsStatus.deliverOrdersFromRappi) {
                                        const password = window.prompt('Please enter password to remove Restaurant', 'Password');
                                        if (password === '9999') {
                                            const remove = window.confirm('Are you sure you want to stop delivering this restaurant with Pd?');
                                            if (remove) {
                                                setConfirmDeliverOrdersFromRappiDialogState(true);
                                            }
                                        } else {
                                            alert('Ask your seller for the password to desintegrate');
                                        }
                                    } else {
                                        setConfirmDeliverOrdersFromRappiDialogState(true);
                                    }
                                }}
                                disabled={viewUser}
                            />
                        </div>
                    )}
                    <EnableRappiTurboSetting onChange={load} rappiTurboEnabled={!!restaurantIntegrationsStatus.rappiTurboEnabled} />
                    <ConfirmDisintegrateFromRappiDialog />
                    <ConfirmDeliverOrdersFromRappiDialog />
                </div>
            </div>
        </>
    );
}

const useStyles = makeStyles((theme) => ({
    integrationContainer: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        width: '100%',
        backgroundColor: theme.palette.secondary.light,
        minHeight: 120,
        marginBottom: 20,
        [theme.breakpoints.down('sm')]: {
            flexDirection: 'column',
            height: 'fit-content',
            paddingTop: 20,
            paddingBottom: 20,
        },
        borderRadius: 8,
        border: '1px solid #D9D9D9',
        background: '#FAFBFB',
        padding: '12px',
    },
    integrationInfoContainer: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        [theme.breakpoints.down('sm')]: {
            width: '100%',
            marginBottom: 10,
            justifyContent: 'space-between',
        },
        gap: '1rem',
    },
    integrationImageContainer: {
        backgroundColor: 'white',
        width: 80,
        height: 80,
        borderRadius: 5,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        marginRight: 20,
    },
    integrationInfo: {
        display: 'flex',
        flexDirection: 'column',
    },
    integrationName: {
        fontFamily: theme.typography.semiBold,
        fontSize: 15,
    },
    integrationStatus: {
        fontFamily: theme.typography.medium,
        fontSize: 13,
    },
    notIntengrated: {
        fontFamily: theme.typography.regular,
        color: '#fa6684',
        fontSize: 14,
    },
    integrationStoreStatusClosed: {
        fontFamily: theme.typography.semiBold,
        color: '#fa6684',
        fontSize: 13,
    },
    integrationStoreStatusOpen: {
        fontFamily: theme.typography.semiBold,
        color: theme.palette.primary.main,
        fontSize: 13,
    },
    buttonDisintegrate: {
        backgroundColor: '#fa6684',
        color: 'white',
        fontFamily: theme.typography.semiBold,
        fontSize: 15,
        borderRadius: 5,
        height: 40,
        width: 200,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        textTransform: 'none',
    },
    buttonGroup: {
        flexDirection: 'row',
        gap: '1rem',
        display: 'flex',
    },
    buttonCloseStore: {
        padding: '4px 16px',
    },
    dialogTitle: {
        textAlign: 'center',
        fontSize: 41,
        color: theme.palette.secondary.contrastText,
        paddingTop: theme.spacing(4),
        paddingBottom: theme.spacing(3),
        '& h2': {
            fontFamily: theme.typography.medium,
        },
    },
    dialogText: {
        fontFamily: theme.typography.regular,
        fontSize: 18,
        textAlign: 'center',
    },
    buttonsContainer: {
        marginTop: 20,
        marginBottom: 20,
        justifyContent: 'center',
        [theme.breakpoints.down('sm')]: {
            flexDirection: 'column',
        },
    },
    acceptButton: {
        backgroundColor: theme.palette.primary.main,
        color: 'white',
        fontFamily: theme.typography.semiBold,
        fontSize: 15,
        borderRadius: 5,
        height: 40,
        width: 200,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        textTransform: 'none',
        boxShadow: `3px 3px 10px ${theme.palette.primary.main}aa`,
    },
    noMargin: {
        margin: '0 !important',
    },
    actionsContainer: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'end',
        [theme.breakpoints.down('sm')]: {
            width: '100%',
            marginBottom: 10,
            justifyContent: 'space-between',
        },
        gap: '1rem',
    },
    integrationLabel: {
        fontFamily: theme.typography.regular,
        color: '#2E3748',
        fontSize: 14,
        fontStyle: 'normal',
        fontWeight: 400,
        lineHeight: 'normal',
    },
    integrationNameContainer: {
        display: 'flex',
        flexDirection: 'column',
    },
    integrationPercentageInfoContainer: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        gap: '1rem',
    },
    integrationPercentageInfo: {
        fontFamily: theme.typography.regular,
        color: '#2E3748',
        fontSize: 14,
        fontStyle: 'normal',
        fontWeight: 400,
        lineHeight: 'normal',
        whiteSpace: 'nowrap',
    },
    link: {
        fontFamily: theme.typography.regular,
        color: '#06B7A2',
        fontSize: 14,
        lineHeight: '31px',
        textDecorationLine: 'underline',
        border: 'none',
        '&:hover': {
            backgroundColor: 'transparent',
            color: '#06B7A2',
            textDecorationLine: 'underline',
        },
    },
    integrated: {
        fontFamily: theme.typography.semiBold,
        color: theme.palette.primary.main,
        fontSize: 14,
    },
}));

type Props = {
    restaurantIntegrationsStatus: RestaurantIntegrationStatusVm;
    load: () => Promise<void>;
    isIntegrationLoaded: boolean;
};
