import { getAppContextApi } from 'src/api/letseatmanager/getAppContextApi';
import { getPosContextApi } from 'src/api/letseatmanager/getPosContextApi';
import { app2 } from 'src/app2';
import { appReducer } from 'src/reducers/appReducer';
import { authReducer } from 'src/reducers/authReducer';
import { menuReducer } from 'src/reducers/menuReducer';
import { posReducer } from 'src/reducers/posReducer';
import { useSetPosPaymentMethods } from 'src/services/pos/posPaymentMethods/useSetPosPaymentMethods';
import { useSetPosPaymentMethodsDeprecated } from 'src/services/pos/posPaymentMethods/useSetPosPaymentMethodsDeprecated';
import type { RestaurantId } from 'src/types/Id';
import { PinCodeUser } from 'src/types/PinCodeUser';
import { CashRegisterPosBusinessDayVm } from 'src/types/PosBusinessDayVm';
import { alertKnownErrorOrSomethingWentWrong } from 'src/utils/alert/alertKnownErrorOrSomethingWentWrong';
import { getErrorDescriptionFromResponse } from 'src/utils/error/getErrorDescriptionFromResponse';
import { useAction } from 'src/utils/react/useAction';
import { useSelector } from 'src/utils/react/useSelector';

export function useReloadRestaurant(): any {
    const posPaymentMethodsDeprecated = useSetPosPaymentMethodsDeprecated();
    const posPaymentMethods = useSetPosPaymentMethods();

    const restaurant = useSelector((state) => state.app.restaurant);

    const reloadRestaurantSuccess = useAction(appReducer.actions.reloadRestaurantSuccess);
    const setDeviceGroup = useAction(appReducer.actions.setDeviceGroup);
    const setDeviceGroups = useAction(appReducer.actions.setDeviceGroups);
    const setPrinters = useAction(appReducer.actions.setPrinters);
    const setDevices = useAction(appReducer.actions.setDevices);
    const setPaymentTerminals = useAction(appReducer.actions.setPaymentTerminals);
    const setPinCodeUsers = useAction(authReducer.actions.setPinCodeUsers);
    const setCashRegisterOpen = useAction(posReducer.actions.setCashRegisterOpen);
    const reloadRestaurantFailure = useAction(appReducer.actions.reloadRestaurantFailure);
    const setPosContext = useAction(posReducer.actions.setPosContext);
    const pinCodeUserSignedIn = useSelector((state) => state.authentication.pinCodeUserSignedIn);
    const setOpenedPosBusinessDay = useAction(posReducer.actions.setOpenedPosBusinessDay);
    const setCurrentEmployeesClockedIn = useAction(app2.actions.setCurrentEmployeesClockedIn);

    const pinCodeUserSignIn = useAction(authReducer.actions.pinCodeUserSignIn);
    const resetMenus = useAction(menuReducer.actions.resetMenus);
    const resetPosMenu = useAction(posReducer.actions.resetPosMenu);
    const clearPos = useAction(posReducer.actions.clearPos);

    const reloadRestaurant = async (restaurantId: RestaurantId) => {
        const request = { restaurantId } as const;
        const response = await getAppContextApi(request);

        if (!response.ok) {
            const errorMessage = `Failed to reload app view model cause of ${getErrorDescriptionFromResponse(response)}`;
            reloadRestaurantFailure(errorMessage);
            return;
        }

        const appContext = response.data;
        resetMenus();
        resetPosMenu();
        clearPos();

        reloadRestaurantSuccess({ appContext });
        setPinCodeUsers(appContext.pinCodeUsers);
        setCurrentEmployeesClockedIn(appContext.currentEmployeesClockedIn);
        if (pinCodeUserSignedIn && appContext.pinCodeUsers?.flatMap((user: PinCodeUser) => user.managerUserId).includes(pinCodeUserSignedIn?.managerUserId)) {
            const currentPinCodeUserSignedIn = appContext.pinCodeUsers?.find((user: PinCodeUser) => user.managerUserId === pinCodeUserSignedIn?.managerUserId);
            if (currentPinCodeUserSignedIn) {
                pinCodeUserSignIn(currentPinCodeUserSignedIn);
            }
        }

        setCashRegisterOpen(appContext.restaurant.cashRegisterOpened);
        setPrinters(appContext.printers);
        setDevices(appContext.devices);
        setDeviceGroup(appContext.deviceGroup);
        setDeviceGroups(appContext.deviceGroups);
        setPaymentTerminals(appContext.paymentTerminals);

        if (appContext.restaurant.posEnabled) {
            reloadPosContext(request);
        }
    };

    const reloadPosContext = async (request: { restaurantId: RestaurantId }) => {
        const posResponse = await getPosContextApi(request);

        if (!posResponse.ok) {
            alertKnownErrorOrSomethingWentWrong(posResponse);
            setCashRegisterOpen(undefined);
            return;
        }

        setCashRegisterOpen(posResponse.data.cashRegisterOpened);

        if (!!posResponse.data.posBusinessDay && !!restaurant?.posMultipleCashRegistersEnabled) {
            const openedPosBusinessDay = posResponse.data.posBusinessDay;
            const hasCashRegisterOpen = openedPosBusinessDay?.cashRegisterPosBusinessDays?.some((cashRegisterPosBusinessDay: CashRegisterPosBusinessDayVm) => cashRegisterPosBusinessDay.opened);

            setOpenedPosBusinessDay(posResponse.data.posBusinessDay);
            setCashRegisterOpen(hasCashRegisterOpen);
        }

        if (posResponse?.data?.posPaymentMethods) {
            posPaymentMethodsDeprecated(posResponse?.data?.posPaymentMethods);
        }
        if (posResponse?.data?.restaurantPaymentMethods) {
            posPaymentMethods(posResponse?.data?.restaurantPaymentMethods);
        }

        setPosContext(posResponse.data);
    };

    return { reloadRestaurant };
}
