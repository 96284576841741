import { ClientFrequencies, ClientFrequency } from 'src/constants/ClientFrequency';
import { TimeZones } from 'src/constants/TimeZone';
import { daysBetween } from 'src/utils/date/daysBetween';

export function getClientFrequency({ orders, lastOrder, secondLastOrder }: Props): ClientFrequency | undefined {
    const numberOfOrders = orders;
    const daysInBetweenLastOrderAndToday = daysBetween(new Date(), lastOrder, TimeZones.AMERICA_MONTERREY) ?? 0;
    const daysInBetweenSecondLastOrderAndLastOrder = daysBetween(lastOrder, secondLastOrder, TimeZones.AMERICA_MONTERREY) ?? 0;

    if (daysInBetweenSecondLastOrderAndLastOrder > 30 && daysInBetweenLastOrderAndToday <= 30) return ClientFrequencies.RECOVERED;

    if (numberOfOrders === 1 && daysInBetweenLastOrderAndToday <= 30) return ClientFrequencies.NEW;

    if (numberOfOrders > 1 && daysInBetweenLastOrderAndToday <= 30) return ClientFrequencies.RECURRENT;

    if (daysInBetweenLastOrderAndToday > 30) return ClientFrequencies.LOST;
}

type Props = {
    orders: number;
    lastOrder?: Date;
    secondLastOrder?: Date;
};
