import { letseatmanagerApiMethod } from 'src/api/letseatmanagerApi';
import type { ApiSauceResponse } from 'src/api/types/ApiSauceResponse';
import { CashRegisterPosBusinessDayVm } from 'src/types/CashRegisterPosBusinessDayVm';
import type { CashRegisterVm } from 'src/types/CashRegisterVm';
import type { ManagerUserId, PosBusinessDayId, RestaurantId } from 'src/types/Id';

export async function openCashRegisterPosBusinessDayApi(request: OpenCashRegisterPosBusinessDayApiRequest): ApiSauceResponse<OpenCashRegisterPosBusinessDayApiResponse | undefined> {
    return letseatmanagerApiMethod('pos/openCashRegisterPosBusinessDayApi', request);
}

type OpenCashRegisterPosBusinessDayApiRequest = {
    restaurantId: RestaurantId;
    posBusinessDayId?: PosBusinessDayId;
    managerUserId: ManagerUserId;
    initialAmount: string;
};

export type OpenCashRegisterPosBusinessDayApiResponse = {
    cashRegisterPosBusinessDay: CashRegisterPosBusinessDayVm;
    cashRegister: CashRegisterVm;
};
