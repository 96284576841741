import { letseatadminApiMethod } from 'src/api/letseatadminApi';
import type { ApiSauceResponse } from 'src/api/types/ApiSauceResponse';
import type { BannerVm } from 'src/types/BannerVm';

export async function findBannersApi(): ApiSauceResponse<BannersVm> {
    return letseatadminApiMethod('banner/findBannersApi');
}

export type BannersVm = {
    banners: Array<BannerVm>;
};
