import { makeStyles } from '@material-ui/core/styles';
import * as React from 'react';
import { CashRegisterPosBusinessDayCard } from 'src/components/pos/cashRegister/CashRegisterPosBusinessDayCard';
import { CashRegisterPosBusinessDaySummary } from 'src/types/PosBusinessDaySummaryVm';

export function CashRegisterPosBusinessDays({ cashRegisterPosBusinessDaysSummary }: Props): React.ReactElement | null {
    const classes = useStyles();

    if (!cashRegisterPosBusinessDaysSummary?.length) return null;

    return (
        <div className={classes.container}>
            {cashRegisterPosBusinessDaysSummary.map((cashRegister) => (
                <CashRegisterPosBusinessDayCard cashRegisterPosBusinessDaySummary={cashRegister} />
            ))}
        </div>
    );
}

const useStyles = makeStyles((theme) => ({
    container: {
        display: 'grid',
        gridTemplateColumns: '1fr',
        [theme.breakpoints.up('sm')]: {
            gridTemplateColumns: 'repeat(2, 1fr)',
        },
        [theme.breakpoints.up('md')]: {
            gridTemplateColumns: 'repeat(3, 1fr)',
        },
        gap: 20,
    },
}));

type Props = {
    cashRegisterPosBusinessDaysSummary: Array<CashRegisterPosBusinessDaySummary> | undefined;
};
