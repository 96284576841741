import { useNotification } from '@pidedirecto/ui/hooks';
import { app2 } from 'src/app2';
import { RolePermissions } from 'src/constants/RolePermission';
import { translate } from 'src/i18n/translate';
import { posReducer } from 'src/reducers/posReducer';
import { OrderVm } from 'src/types/OrderVm';
import { isDeliveryOrder } from 'src/utils/order/isDeliveryOrder';
import { useAction } from 'src/utils/react/useAction';
import { useSelector } from 'src/utils/react/useSelector';
import { useUserHasRolePermission } from 'src/utils/react/useUserHasRolePermissions';

export function useChargeOrder(): Result {
    const orderType = useSelector((state) => state.pos.orderType);
    const openedCashRegister = useSelector((state) => state.pos.openedCashRegister);
    const driverRequest = useSelector((state) => state.pos.driverRequest);
    const [userHasRolePermission] = useUserHasRolePermission();
    const notification = useNotification();

    const payOrder = useAction(posReducer.actions.payOrder);
    const openCreateManualOrderDeliveryDialog = useAction(app2.actions.openCreateManualOrderDeliveryDialog);

    const handlePay = (order?: OrderVm) => {
        if (!userHasRolePermission(RolePermissions.CHARGE_ORDERS)) {
            notification({ message: translate('Not authorized to charge orders') });
            return;
        }
        if (!openedCashRegister) return alert(translate('The cash register is not opened. Please go to the cash register section and open it.'));
        if (!isDeliveryOrder(orderType)) return payOrder(order);
        if (!driverRequest) return openCreateManualOrderDeliveryDialog({ isForPos: true });

        payOrder(order);
    };

    return { handlePay };
}

type Result = {
    handlePay: (order?: OrderVm) => void;
};
