import Grid from '@material-ui/core/Grid';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import Typography from '@material-ui/core/Typography';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';
import { Loader } from '@pidedirecto/ui';
import { ClosedEyeIcon } from '@pidedirecto/ui/icons';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { app2 } from 'src/app2';
import { Button } from 'src/components/Button';
import { Channels } from 'src/components/Channels';
import { SearchBar } from 'src/components/input/SearchBar';
import { SecuredAndSubscribedPage } from 'src/components/page/SecuredAndSubscribedPage';
import { SecuredContent } from 'src/components/SecuredContent';
import { UpdatingContentProgress } from 'src/components/UpdatingContentProgress';
import { Apps } from 'src/constants/App';
import { MenuItemTypes } from 'src/constants/DeprecatedMenuItemType';
import { RestaurantTypes } from 'src/constants/RestaurantType';
import { RolePermissions } from 'src/constants/RolePermission';
import { translate } from 'src/i18n/translate';
import { CampaignIcon } from 'src/icons/CampaignIcon';
import { AddMenuCategory } from 'src/scenes/letseatmanager/deprecatedMenu/AddMenuCategory';
import { AddMenuItem } from 'src/scenes/letseatmanager/deprecatedMenu/AddMenuItem';
import { ManageMenu } from 'src/scenes/letseatmanager/deprecatedMenu/ManageMenu';
import { ManageMenuCategory } from 'src/scenes/letseatmanager/deprecatedMenu/ManageMenuCategory';
import { Category } from 'src/scenes/letseatmanager/deprecatedMenu/MenuCategory';
import { MenuEmptyState } from 'src/scenes/letseatmanager/deprecatedMenu/MenuEmptyState';
import { MenuItem } from 'src/scenes/letseatmanager/deprecatedMenu/MenuItem';
import TheMenuActions from 'src/scenes/letseatmanager/deprecatedMenu/TheMenuReducer';
import { MenuAlerts } from 'src/scenes/letseatmanager/menu/MenuAlerts';
import { classNames } from 'src/utils/react/classNames';
import { useAction } from 'src/utils/react/useAction';
import { useSelector } from 'src/utils/react/useSelector';

export function DeprecatedMenuPage(): React.ReactElement | null {
    const classes = useStyles();
    const theme = useTheme();

    const smallScreen = useMediaQuery(theme.breakpoints.down('sm'));
    const smallestScreen = useMediaQuery(theme.breakpoints.down('xs'));

    const [manageModifiers, setManageModifiers] = useState(false);

    const viewUser = useSelector((state) => state.authentication.viewUser);
    const restaurantId = useSelector((state) => state.app.restaurantId);
    const restaurant = useSelector((state) => state.app.restaurant);
    const internalUser = useSelector((state) => state.authentication.internalUser);
    const appLoading = useSelector((state) => state.app.loading);
    const loading = useSelector((state) => state.theMenu.loading);
    const tab = useSelector((state) => state.theMenu.tab);
    const restaurantType = useSelector((state) => state.theMenu.restaurantType);
    const kitchenIds = useSelector((state) => state.theMenu.kitchenIds);
    const kitchenNames = useSelector((state) => state.theMenu.kitchenNames);
    const menus = useSelector((state) => state.theMenu.menus);
    const modifiers = useSelector((state) => state.theMenu.modifiers);
    const menuCategoryIdSelected = useSelector((state) => state.app2.theMenuCategoryIdSelected);
    const posEnabled = useSelector((state) => state.app.restaurant.posEnabled);
    const webOrdersEnabled = useSelector((state) => state.app.restaurant.webOrdersEnabled);
    const appOrdersEnabled = useSelector((state) => state.app.restaurant.appOrdersEnabled);
    const uberEatsStoreId = useSelector((state) => state.app.restaurant.uberEatsStoreId);
    const rappiStoreId = useSelector((state) => state.app.restaurant.rappiStoreId);
    const didiFoodStoreId = useSelector((state) => state.app.restaurant.didiFoodStoreId);

    const fetch = useAction(TheMenuActions.fetch);
    // eslint-disable-next-line import/no-named-as-default-member
    const switchTab = useAction(TheMenuActions.switchTab);
    const moveMenuLeft = useAction(TheMenuActions.moveMenuLeft);
    const moveMenuRight = useAction(TheMenuActions.moveMenuRight);
    const openCopyRestaurantMenuDialog = useAction(app2.actions.openCopyRestaurantMenuDialog);
    const openImportRestaurantMenuDialog = useAction(app2.actions.openImportRestaurantMenuDialog);
    const openCreateRestaurantMenuPromotionDialog = useAction(app2.actions.openCreateRestaurantMenuPromotionDialog);
    const openCreateMenuDialog = useAction(app2.actions.openCreateMenuDialog);
    const openRemoveMenuDialog = useAction(app2.actions.openRemoveMenuDialog);
    const openChangeMenuDialog = useAction(app2.actions.openChangeMenuDialog);
    const theMenuSelectCategory = useAction(app2.actions.theMenuSelectCategory);

    const isKitchen = restaurantType === RestaurantTypes.KITCHEN;
    const menu = menus?.find((menu: any) => menu.menuId === tab);
    const categories = menu?.categories || [];
    const showMasterMenuRestaurantName = !!restaurant.masterMenuRestaurantName && !!rappiStoreId;
    const disableMenuEditing = restaurant.wansoftIntegrationEnabled || restaurant.isConnectedWithMasterMenu || (restaurant.hideMenuEditionEnabled && !internalUser) || showMasterMenuRestaurantName;
    const idsInMenuEnabled = restaurant.idsInMenuEnabled;
    const isExternalMenu = menu?.channels?.some((channel: any) => channel === Apps.UBER_EATS || channel === Apps.DIDI_FOOD || channel === Apps.RAPPI);
    const menuCategorySelected = categories.find((category: any) => category.menuCategoryId === menuCategoryIdSelected);
    const menuItems = (manageModifiers ? modifiers : menuCategorySelected?.menuItems) ?? [];
    const menuPageEnabled = isKitchen || posEnabled || webOrdersEnabled || appOrdersEnabled || rappiStoreId || uberEatsStoreId || didiFoodStoreId;

    const handleMoveLeft = () => {
        if (loading || appLoading) return;
        const request = { menuId: tab } as const;
        moveMenuLeft(request);
    };

    const handleMoveRight = () => {
        if (loading || appLoading) return;
        const request = { menuId: tab } as const;
        moveMenuRight(request);
    };

    const handleClickRemove = () => {
        if (loading || appLoading) return;
        if (menus.length === 1) return alert(translate('Cannot remove last menu'));
        const menu = menus.find((menu) => menu.menuId === tab);
        openRemoveMenuDialog({ menu, onSuccess: () => fetch(restaurantId) });
    };

    const handleClickChange = () => {
        if (loading || appLoading) return;
        const menu = menus.find((menu) => menu.menuId === tab);
        if (!menu) return null;
        openChangeMenuDialog({ menu, onSuccess: () => fetch(restaurantId) });
    };

    const handleClickCreate = () => {
        if (loading || appLoading) return;
        const position = menus.findIndex((menu) => menu.menuId === tab) + 2;
        openCreateMenuDialog({ position, onSuccess: () => fetch(restaurantId) });
    };

    const handleChangeTab = (event: any, tab: any) => switchTab(tab);

    const selectCategory = (menuCategoryId: any) => {
        setManageModifiers(false);
        theMenuSelectCategory({ menuCategoryId });
    };

    const handleSeeModifiers = () => {
        setManageModifiers(true);
        theMenuSelectCategory({ menuCategoryId: undefined });
    };

    const renderCategories = (menu: any) => {
        if (!menu) return null;
        const categories = menu.categories;
        if (categories.length === 0) {
            return (
                <>
                    <div className={classes.noCategoriesContainer}>
                        <h1 className={classes.noCategoriesText}>{translate('No categories')}</h1>
                        <AddMenuCategory menuId={menu.menuId} loading={loading || appLoading} onSuccess={() => fetch(restaurantId)} />
                    </div>
                </>
            );
        }
        return (
            <div className={classes.leftSideContainer}>
                <div className={classes.categoriesContainer}>
                    {categories.map((category: any, index: number) => (
                        <Category
                            key={category.menuCategoryId}
                            menuId={menu.menuId}
                            category={category}
                            position={index + 1}
                            loading={loading || appLoading}
                            onClick={selectCategory}
                            categorySelected={menuCategoryIdSelected}
                        />
                    ))}
                </div>
                <div className={classes.categoriesContainer} style={{ marginTop: 20 }}>
                    <div className={classNames(classes.containerCategory, manageModifiers ? classes.categorySelected : '')} onClick={handleSeeModifiers}>
                        <div className={classes.name}>{translate('Modifiers')}</div>
                        {!manageModifiers && <KeyboardArrowRightIcon className={classes.icon} />}
                    </div>
                </div>
            </div>
        );
    };

    const [searchTerm, setSearchTerm] = useState('');

    const query = (e: any) => {
        setSearchTerm(e.target.value.toLowerCase());
    };

    useEffect(() => {
        fetch(restaurantId);
    }, [restaurantId]);

    useEffect(() => {
        if (!menuCategoryIdSelected && !manageModifiers) theMenuSelectCategory({ menuCategoryId: categories[0]?.menuCategoryId });
    }, [categories, menuCategoryIdSelected, manageModifiers]);

    useEffect(() => {
        if (menu?.menuId) theMenuSelectCategory({ menuCategoryId: categories[0]?.menuCategoryId });
    }, [menu?.menuId]);

    if ((loading || appLoading) && !menus?.length) {
        return (
            <div className={classes.loadingContainer}>
                <Loader size={40} loading={true} />
            </div>
        );
    }

    if (!menuPageEnabled) return <MenuEmptyState />;

    return (
        <SecuredAndSubscribedPage title={translate('The Menu')} rolePermission={RolePermissions.MENU_EDIT_PAGE}>
            <MenuAlerts />
            <Grid container>
                <Grid item xs={12}>
                    <div className={classes.container} style={{ padding: 10 }}>
                        <div className={classes.tabsContainer}>
                            {!!menus.length && (
                                <div className={classes.menusContainer}>
                                    <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                                        {!smallestScreen && <h1 className={classes.title}>{translate('Menus')}</h1>}
                                        {/* @ts-ignore */}
                                        <Tabs value={tab} varaint='fullWidth' variant='scrollable' scrollButtons='on' onChange={handleChangeTab} className={classes.tabs}>
                                            {menus.map((menu) => (
                                                <Tab
                                                    className={classes.menuTab}
                                                    key={menu.menuId}
                                                    label={
                                                        <div className={classes.menuTabText}>
                                                            {menu.name}
                                                            {menu.hidden && (
                                                                <span className={classes.menuIcon}>
                                                                    <ClosedEyeIcon />
                                                                </span>
                                                            )}
                                                        </div>
                                                    }
                                                    value={menu.menuId}
                                                />
                                            ))}
                                        </Tabs>
                                    </div>
                                    {!disableMenuEditing && (
                                        <SecuredContent rolePermission={RolePermissions.MENU_EDIT}>
                                            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                                                {!smallScreen && !viewUser && <Button onClick={handleClickCreate}>{`+ ${translate('Create Menu')}`}</Button>}
                                                {!viewUser && (
                                                    <ManageMenu
                                                        onClickMoveLeft={handleMoveLeft}
                                                        onClickMoveRight={handleMoveRight}
                                                        onClickChange={handleClickChange}
                                                        onClickCreate={handleClickCreate}
                                                        onClickRemove={handleClickRemove}
                                                    />
                                                )}
                                            </div>
                                        </SecuredContent>
                                    )}
                                    {disableMenuEditing && <div style={{ width: 48 }}></div>}
                                </div>
                            )}
                        </div>
                    </div>
                </Grid>
            </Grid>
            <Grid container>
                <Grid item xs={12}>
                    <div className={classes.container} style={{ padding: 10 }}>
                        <div className={classes.menusContainer}>
                            <h1 className={classes.title}>{translate('Channels')}</h1>
                            <Channels defaultSelectedChannels={menu?.channels ?? [Apps.PIDEDIRECTO]} disabled />
                        </div>
                    </div>
                </Grid>
            </Grid>
            {showMasterMenuRestaurantName && (
                <div className={classes.alertContainer}>
                    <div className={classes.alertTitle}>
                        <div className={classes.icon}>
                            <CampaignIcon title={'campaign'} />
                        </div>
                        {translate(`This menu is managed on the branch @branchName`, { branchName: restaurant.masterMenuRestaurantName })}
                    </div>
                </div>
            )}
            <Grid container justify='space-around'>
                <Grid item xs={12}>
                    {(loading || appLoading) && <UpdatingContentProgress />}
                    <div className={classes.container} style={{ minHeight: '80vh' }}>
                        <div className={classes.tabsContainer}>
                            {internalUser && (
                                <div className={classes.adminTabsContainer}>
                                    {restaurantType === RestaurantTypes.PICKUP_STATION && (
                                        <>
                                            <Typography variant='subtitle2' align='center'>
                                                Connected Kitchens
                                            </Typography>
                                            {kitchenNames?.map((kitchenName: string) => (
                                                <Typography variant='subtitle1' align='center'>
                                                    {kitchenName}
                                                </Typography>
                                            ))}
                                            {!kitchenNames && (
                                                <Typography variant='subtitle1' align='center'>
                                                    Not connected to any kitchen
                                                </Typography>
                                            )}
                                        </>
                                    )}
                                    {!kitchenIds && (
                                        <div className={classes.menuTabsContainer}>
                                            <Button
                                                classes={{ button: classes.tabButton }}
                                                outlined
                                                disabled={viewUser}
                                                onClick={() =>
                                                    openCopyRestaurantMenuDialog({
                                                        onSuccess: () => {
                                                            fetch(restaurantId);
                                                        },
                                                    })
                                                }
                                            >
                                                {translate('Copy Menu from other Restaurant')}
                                            </Button>
                                            <Button
                                                classes={{ button: classes.tabButton }}
                                                disabled={viewUser}
                                                onClick={() =>
                                                    openImportRestaurantMenuDialog({
                                                        onSuccess: () => {
                                                            fetch(restaurantId);
                                                        },
                                                    })
                                                }
                                                outlined
                                            >
                                                {translate('Import Menu from external URL')}
                                            </Button>
                                            <Button
                                                classes={{ button: classes.tabButton }}
                                                disabled={viewUser}
                                                onClick={() =>
                                                    openCreateRestaurantMenuPromotionDialog({
                                                        onSuccess: () => {
                                                            fetch(restaurantId);
                                                        },
                                                    })
                                                }
                                                outlined
                                            >
                                                {translate('Create Restaurant Menu Promotion')}
                                            </Button>
                                        </div>
                                    )}
                                </div>
                            )}
                        </div>
                        <SearchBar query={query} placeholder={'Search by product...'} />
                        <div className={classes.menu}>
                            {searchTerm === '' && renderCategories(menu)}
                            {categories.length !== 0 && searchTerm !== '' && (
                                <div className={classes.productsContainer}>
                                    {!disableMenuEditing && (
                                        <SecuredContent rolePermission={RolePermissions.MENU_EDIT}>
                                            <ManageMenuCategory
                                                menuId={menu?.menuId}
                                                category={menuCategorySelected}
                                                position={categories?.findIndex((category: any) => category.menuCategoryId === menuCategoryIdSelected) + 2}
                                                loading={false}
                                                onSuccess={() => fetch(restaurantId)}
                                            >
                                                <h1 className={classes.titleCategory}>{translate('Menu Products')}</h1>
                                            </ManageMenuCategory>
                                        </SecuredContent>
                                    )}
                                    {disableMenuEditing && <h1 className={classes.titleCategory}>{menuCategorySelected?.name}</h1>}

                                    {categories.map((category: any) => [
                                        category.menuItems.length === 0 ? (
                                            <AddMenuItem
                                                key={`${category.menuCategoryId}-add-menu-item`}
                                                onSuccess={() => fetch(restaurantId)}
                                                menuCategoryId={category.menuCategoryId}
                                                loading={loading || appLoading}
                                                menuItemType={manageModifiers ? MenuItemTypes.MODIFIER : MenuItemTypes.ITEM}
                                            />
                                        ) : (
                                            category.menuItems
                                                .filter((menuItem: any) => {
                                                    if (searchTerm === '') {
                                                        return menuItem;
                                                    } else if (menuItem.name.toLowerCase().includes(searchTerm.toLowerCase())) {
                                                        return menuItem;
                                                    }
                                                })
                                                .map((menuItem: any, index: number) => (
                                                    <MenuItem
                                                        key={menuItem.menuItemId}
                                                        menuItem={menuItem}
                                                        menuCategoryId={category.menuCategoryId}
                                                        position={index + 2}
                                                        loading={loading || appLoading}
                                                        disableMenuEditing={disableMenuEditing}
                                                        idsInMenuEnabled={idsInMenuEnabled}
                                                        isExternalMenu={isExternalMenu}
                                                        onSuccess={() => fetch(restaurantId)}
                                                    />
                                                ))
                                        ),
                                    ])}
                                </div>
                            )}
                            {categories.length !== 0 && searchTerm === '' && (
                                <div className={classes.productsContainer}>
                                    {!disableMenuEditing && (
                                        <ManageMenuCategory
                                            menuId={menu?.menuId}
                                            category={menuCategorySelected}
                                            position={categories?.findIndex((category: any) => category.menuCategoryId === menuCategoryIdSelected) + 2}
                                            loading={false}
                                            onSuccess={() => fetch(restaurantId)}
                                        >
                                            <h1 className={classes.titleCategory}>{menuCategorySelected?.name}</h1>
                                        </ManageMenuCategory>
                                    )}
                                    {disableMenuEditing && <h1 className={classes.titleCategory}>{menuCategorySelected?.name}</h1>}

                                    {menuItems.length === 0 ? (
                                        <AddMenuItem
                                            onSuccess={() => fetch(restaurantId)}
                                            menuCategoryId={menuCategoryIdSelected}
                                            loading={loading || appLoading}
                                            menuItemType={manageModifiers ? MenuItemTypes.MODIFIER : MenuItemTypes.ITEM}
                                        />
                                    ) : (
                                        menuItems.map((menuItem: any, index: number) => (
                                            <MenuItem
                                                key={menuItem.menuItemId}
                                                menuItem={menuItem}
                                                menuCategoryId={menuCategoryIdSelected}
                                                position={index + 2}
                                                loading={loading || appLoading}
                                                disableMenuEditing={disableMenuEditing}
                                                idsInMenuEnabled={idsInMenuEnabled}
                                                isExternalMenu={isExternalMenu}
                                                onSuccess={() => fetch(restaurantId)}
                                            />
                                        ))
                                    )}
                                </div>
                            )}
                        </div>
                    </div>
                </Grid>
            </Grid>
        </SecuredAndSubscribedPage>
    );
}

const useStyles = makeStyles((theme) => ({
    tabsContainer: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        width: '100%',
        height: '100% !important',
    },
    menuTabsContainer: {
        display: 'flex',
        justifyContent: 'space-between',
        width: '100%',
        gap: 10,
        [theme.breakpoints.down('sm')]: {
            flexDirection: 'column',
        },
    },
    tabButton: {
        width: '100%',
    },
    menusContainer: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        width: '100%',
    },
    container: {
        borderRadius: 10,
        backgroundColor: 'white',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        border: `1px solid ${theme.palette.border.primary}`,
        marginBottom: 20,
        [theme.breakpoints.down('sm')]: {
            width: '90%',
            margin: '0 auto',
            marginTop: 20,
        },
        padding: 20,
    },
    noCategoriesContainer: {
        height: '65vh',
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
    },
    noCategoriesText: {
        fontFamily: theme.typography.medium,
        fontStyle: 'italic',
        fontSize: 40,
        marginBottom: 40,
        [theme.breakpoints.down('sm')]: {
            fontSize: 28,
        },
    },
    menuTabText: {
        fontFamily: theme.typography.medium,
    },
    menuIcon: {
        margin: 'auto 10px',
    },
    menu: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        height: '100%',
        width: '100%',
        marginTop: 20,
        [theme.breakpoints.down('sm')]: {
            flexDirection: 'column',
            alignItems: 'center',
        },
    },
    categoriesContainer: {
        flexShrink: 0,
        border: `1px solid ${theme.palette.border.primary}`,
        display: 'flex',
        flexDirection: 'column',
        borderRadius: 5,
        width: '100%',
        height: 'fit-content',
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(2),
        [theme.breakpoints.down('sm')]: {
            width: '100%',
            marginBottom: 20,
            marginRight: 0,
        },
    },
    productsContainer: {
        width: '100%',
        border: `1px solid ${theme.palette.border.primary}`,
        display: 'flex',
        flexDirection: 'column',
        borderRadius: 5,
        minHeight: '65vh',
        marginBottom: 30,
        padding: 20,
    },
    titleCategory: {
        fontFamily: theme.typography.semiBold,
        fontSize: 22,
        marginLeft: 20,
    },
    title: {
        fontFamily: theme.typography.semiBold,
        fontSize: 18,
        marginLeft: 20,
    },
    adminTabsContainer: {
        display: 'flex',
        flexDirection: 'row',
        height: '5vh',
        marginTop: 5,
    },
    tabs: {
        maxWidth: '55vw',
        '& .MuiTabs-indicator': {
            backgroundColor: theme.palette.surface.brandContrast,
        },
        [theme.breakpoints.down('sm')]: {
            maxWidth: '60vw',
        },
        [theme.breakpoints.down('xs')]: {
            maxWidth: '75vw',
        },
    },
    leftSideContainer: {
        display: 'flex',
        flexDirection: 'column',
        width: '20%',
        marginRight: 20,
    },
    containerCategory: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
        width: '100%',
        height: 50,
        fontFamily: theme.typography.semiBold,
        color: theme.palette.text.primary,
        cursor: 'pointer',
    },
    categorySelected: {
        backgroundColor: theme.palette.surface.brand,
        color: theme.palette.text.brand,
    },
    name: {
        width: '60%',
    },
    alertContainer: {
        display: 'flex',
        width: '100%',
        justifyContent: 'space-between',
        alignItems: 'center',
        backgroundColor: '#EAF7FD',
        borderStyle: 'solid',
        borderColor: '#B4CFE8',
        borderRadius: 5,
        padding: '10px',
        marginBottom: '5px',
    },
    alertTitle: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        color: '#154A61',
        fontWeight: 600,
    },
    icon: {
        color: theme.palette.icons.primary,
        width: '20px',
        height: '20px',
        marginRight: 10,
        marginLeft: 10,
    },
    menuTab: {
        '&.Mui-selected': {
            color: theme.palette.text.brand,
        },
    },
    loadingContainer: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100%',
    },
}));
