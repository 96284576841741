import { makeStyles } from '@material-ui/core';
import { useState } from 'react';
import * as React from 'react';
import { DeviceGroupDetailsVm, findDeviceGroupsApi } from 'src/api/letseatmanager/deviceGroup/findDeviceGroupsApi';
import { Button } from 'src/components/Button';
import { Text } from 'src/components/Text';
import { UpdatingContentProgress } from 'src/components/UpdatingContentProgress';
import { translate } from 'src/i18n/translate';
import cards from 'src/images/cards.png';
import { CreateDeviceGroupDialog } from 'src/scenes/letseatmanager/device/deviceGroups/CreateDeviceGroupDialog';
import { DeviceGroupCard } from 'src/scenes/letseatmanager/device/deviceGroups/DeviceGroupCard';
import { useLoadApi } from 'src/utils/react/useLoadApi';
import { useSelector } from 'src/utils/react/useSelector';

export function DeviceGroups(): React.ReactElement {
    // TODO Change device name dialog?
    const classes = useStyles();

    const [createDeviceGroupDialogState, setCreateDeviceGroupDialogState] = useState({ open: false });

    const restaurantId = useSelector((state) => state.app.restaurantId);

    const [loading, deviceGroups, refreshDeviceGroups] = useLoadApi(findDeviceGroupsApi, { restaurantId });

    const openCreateDeviceGroupDialog = () => setCreateDeviceGroupDialogState({ open: true });

    const closeCreateDeviceGroupDialog = () => setCreateDeviceGroupDialogState({ open: false });

    if (deviceGroups && deviceGroups.length === 0) {
        return (
            <div className={classes.emptyState}>
                <CreateDeviceGroupDialog open={createDeviceGroupDialogState.open} onClose={closeCreateDeviceGroupDialog} onSuccess={refreshDeviceGroups} />
                <img src={cards} alt={translate('Group of cards')} className={classes.image} />
                <Text size={'large'} variant={'semiBold'}>
                    {translate('Start organizing your business')}
                </Text>
                <Text>{translate('Group your devices')}</Text>
                <Button onClick={openCreateDeviceGroupDialog} classes={{ button: classes.emptyStateButton }}>
                    {translate('New group')}
                </Button>
            </div>
        );
    }

    return (
        <div className={classes.container}>
            <UpdatingContentProgress loading={loading} top left />
            <CreateDeviceGroupDialog open={createDeviceGroupDialogState.open} onClose={closeCreateDeviceGroupDialog} onSuccess={refreshDeviceGroups} />
            <div className={classes.titleContainer}>
                <h2 className={classes.title}>{translate('Groups')}</h2>
                <Button onClick={openCreateDeviceGroupDialog}>{translate('New group')}</Button>
            </div>
            <div className={classes.groupsContainer}>
                {deviceGroups?.map((deviceGroup: DeviceGroupDetailsVm) => <DeviceGroupCard key={deviceGroup.deviceGroupId} deviceGroup={deviceGroup} onChange={refreshDeviceGroups} />)}
            </div>
        </div>
    );
}

const useStyles = makeStyles((theme) => ({
    container: {
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        gap: 20,
        position: 'relative',
    },
    title: {
        color: theme.palette.text.primary,
        fontFamily: theme.typography.semiBold,
        fontSize: 20,
    },
    titleContainer: {
        width: '100%',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
    },
    groupsContainer: {
        width: '100%',
        display: 'flex',
        flexDirection: 'row',
        gap: 20,
        flexWrap: 'wrap',
    },
    emptyState: {
        width: '100%',
        height: '70vh',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        gap: 5,
    },
    image: {
        marginBottom: 20,
    },
    emptyStateButton: {
        marginTop: 20,
    },
}));
