import { posReducer } from 'src/reducers/posReducer';
import type { PosCourseOrderDetails } from 'src/types/PosCourseOrderDetails';
import { useAction } from 'src/utils/react/useAction';
import { useSelector } from 'src/utils/react/useSelector';

export function useAddCourse(): Result {
    const ongoingCourses = useSelector((state) => state.pos.ongoingCourses);

    const setOngoingCourses = useAction(posReducer.actions.setOngoingCourses);

    const addCourse = (course: PosCourseOrderDetails) => {
        const isInOngoingCourses = ongoingCourses?.some((ongoingCourse: PosCourseOrderDetails) => ongoingCourse.courseId === course.courseId);
        if (isInOngoingCourses) return;

        const newOngoingCourses = [
            ...(ongoingCourses ?? []),
            {
                ...course,
                orderItems: [],
                cancelledItems: [],
            },
        ];
        setOngoingCourses(newOngoingCourses);
    };

    return { addCourse };
}

export type Result = {
    addCourse: (course: PosCourseOrderDetails) => void;
};
