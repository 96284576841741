import { letseatmanagerApiMethod } from 'src/api/letseatmanagerApi';
import type { ApiSauceResponse } from 'src/api/types/ApiSauceResponse';
import type { PrinterId } from 'src/types/Id';

export async function removePrinterApi(request: RemovePrinterApiRequest): ApiSauceResponse<void> {
    return letseatmanagerApiMethod('printer/removePrinterApi', request);
}

export type RemovePrinterApiRequest = {
    printerId: PrinterId;
};
