export const DeviceTypes = {
    MACINTOSH: 'MACINTOSH',
    PC: 'PC',
    TABLET: 'TABLET',
    MOBILE: 'MOBILE',
    UNKNOWN: 'UNKNOWN',
    CUSTOMER_DISPLAY_SCREEN: 'CUSTOMER_DISPLAY_SCREEN',
} as const;

export type DeviceType = (typeof DeviceTypes)[keyof typeof DeviceTypes] | string;
