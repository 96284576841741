import { PaymentMethods } from 'src/constants/PaymentMethod';
import { OrderVm } from 'src/types/OrderVm';
import { translateOrderPaymentMethod } from 'src/utils/translate/translateOrderPaymentMethod';

export function translateOrderPaymentMethodWithEmoji(order: OrderVm): string {
    const translation = translateOrderPaymentMethod(order);

    switch (order.paymentMethod) {
        case PaymentMethods.CASH: {
            return `${translation} 💵`;
        }
        case PaymentMethods.CREDIT_CARD:
        case PaymentMethods.PAYROLL: {
            return `${translation} 💳`;
        }
        default: {
            return translation;
        }
    }
}
